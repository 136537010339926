import {
  WorkflowSignableDocumentItemType2,
  WorkflowWithSectionsFragment,
} from 'gql/graphql';

export const getSignableDocumentsFromWorkflow = (
  workflow: WorkflowWithSectionsFragment,
): WorkflowSignableDocumentItemType2[] | [] => {
  const signedDocuments =
    workflow?.sections?.length &&
    workflow?.sections
      .flatMap((section) => section.items2)
      .filter((item) => item?.type === 'WfiSignableDocument');

  return (signedDocuments as WorkflowSignableDocumentItemType2[]) || [];
};
