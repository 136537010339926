export const FILE_TYPE_HELP_DESK_LINK = "https://help.smartvault.com/hc/en-us/articles/360050527834-Troubleshooting-Resolving-the-Unsupported-File-Extension-Upload-Failure-Error-Message#troubleshooting-resolving-the-unsupported-file-extension-upload-failure-error-message-0-0"
export const UNSUPPORTED_FILE_TYPES = [
  "ade",
  "adp",
  "app",
  "asa",
  "ashx",
  "asmx",
  "asp",
  "bas",
  "bat",
  "cdx",
  "cer",
  "chm",
  "class",
  "cmd",
  "com",
  "config",
  "cpl",
  "crt",
  "csh",
  "dll",
  "exe",
  "fxp",
  "hlp",
  "hta",
  "htr",
  "htw",
  "ida",
  "idc",
  "idq",
  "ins",
  "isp",
  "its",
  "jse",
  "ksh",
  "mad",
  "maf",
  "mag",
  "mam",
  "maq",
  "mar",
  "mas",
  "mat",
  "mau",
  "mav",
  "maw",
  "mda",
  "mdb",
  "mde",
  "mdt",
  "mdw",
  "mdz",
  "msc",
  "msh",
  "msh1",
  "msh1xml",
  "msh2",
  "msh2xml",
  "mshxml",
  "msi",
  "msp",
  "mst",
  "ops",
  "pcd",
  "pif",
  "prf",
  "prg",
  "printer",
  "pst",
  "qbw",
  "reg",
  "rem",
  "scf",
  "scr",
  "sct",
  "shb",
  "shs",
  "shtm",
  "shtml",
  "soap",
  "stm",
  "url",
  "vb",
  "vbe",
  "vbs",
  "ws",
  "wsc",
  "wsf",
  "wsh"
]
