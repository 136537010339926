/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-script-url */
/* eslint-disable react/jsx-no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReactTooltip from 'react-tooltip';
import moment from 'moment-timezone';
import * as Utilities from 'utilities/utils';
import * as UploadFilesActions from 'actions/UploadFilesActions';
import ConfirmationModal from 'common/ConfirmationModal';
import PdfViewer from 'components/PdfViewer/PdfViewer';
import MultipleSourceModal from 'components/Files/MultipleSourceModal';
import ManualSourceModal from 'components/Files/ManualSourceModal';
import {
  Label,
  Input,
  ButtonGroup,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import { withRouter } from 'utilities/withRouterWrapper';
import DocumentShow from './DocumentShow';

const noneStyle = { display: 'none' };
const blockStyle = { display: 'block' };
const timeZone =
  Intl.DateTimeFormat().resolvedOptions().timeZone || moment.tz.guess(true);
const filesHeaders = [
  {
    value: 'aws_url_file_name',
    label: 'File Name',
    className: 'col-3 thCol',
  },
  {
    value: 'tags',
    label: 'Tags',
    className: 'col-3 thCol',
  },
  {
    value: 'upload_file_month',
    label: 'Month',
    className: 'col-2 thCol',
  },
  {
    value: 'upload_file_year',
    label: 'Year',
    className: 'col-2 thCol',
  },
  {
    value: 'created_at',
    label: 'Date Added',
    className: 'col thCol',
  },
];
const isIE = /* @cc_on!@ */ false || !!document.documentMode;
class UploadsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDocPop: false,
      selectedItem: null,
      showAlert: '',
    };
  }

  UNSAFE_componentWillMount() {
    const { match, location, previewFileDetail, previewFileModal } = this.props;
    if (
      match &&
      (match.path === '/contactdetails/:id' ||
        match.path === '/accountdetails/:id' ||
        match.path === '/files') &&
      location.hash &&
      location.hash.indexOf('#multiple') > -1 &&
      previewFileModal
    ) {
      this.showDocument(previewFileDetail);
    }
  }

  onButtonClose = (updateFilesData) => {
    this.setState({ showDocPop: false, selectedItem: null });
    this.props.files.previewFile({}, false);
    // this.props.component.dataAfterEditDocument();
    if (updateFilesData) {
      this.props.component.dataAfterEditDocument();
    }
  };

  listOfFilesState = (status = '') => {
    this.props.component.listOfFilesState(status);
  };

  showDocument = (item) => {
    const removeDuplicateTags = Utilities.removeDuplicateTags(item.tags);
    const selected = item;
    selected.tags = removeDuplicateTags;
    this.setState({ showDocPop: true, selectedItem: selected });
  };

  editFileName = (e, item) => {
    e.stopPropagation();
    this.props.component.fileExtention(item);
  };

  returnClassName = (name, cls = '') => {
    const { uploadFilesList, data } = this.props;
    if (name === 'associated_with' && cls === 'click') {
      return false;
    }
    let clas = '';
    if (cls === 'click') {
      const secondcls = uploadFilesList.length
        ? data.editFileNameId
          ? null
          : () => this.props.handleSorting(name)
        : null;
      clas = secondcls;
    } else if (cls === 'icon') {
      clas = uploadFilesList.length
        ? Utilities.sortingIcon(data.field, name, data.sort)
        : null;
    } else {
      const secondcls = uploadFilesList.length
        ? Utilities.sortingIcon(data.field, name, data.sort, 'className')
        : '';
      clas = `${secondcls} ${cls}`;
    }
    return clas;
  };

  closeModal = (source, index) => {
    this.setState({ [source]: false }, () => {
      const { location } = this.props;
      if (index && index === true) {
        const { hash } = location;
        if (hash && hash.indexOf('#multiple') > -1) {
          this.props.history.push(`${location.pathname}#files`);
        }
        this.props.component.uploadFile('close');
      } else {
        const { hash } = location;
        if (hash && hash.indexOf('#files') > -1) {
          this.props.history.push(`${location.pathname}#files`);
        } else {
          this.props.history.push('/files');
        }
      }
    });
  };

  render() {
    // TODO: __HOMEDATA-AUDIT
    const homeDataObj = Utilities.getStorageData();
    const delete_button =
      homeDataObj !== null &&
      Object.keys(homeDataObj).length &&
      homeDataObj.is_employee
        ? ''
        : 'hidden';
    const { data, uploadFilesList, liscioVault } = this.props;
    const { showDocPop, selectedItem, showAlert, fileDetails } = this.state;
    const setArray = (array, tag, index) => {
      const tagList = array.map((item) => (
        <span key={array.indexOf(item)} className="tag tag--fill">
          {Utilities.tagLabel(item)}
        </span>
      ));
      return (
        <div
          key={index + 1}
          className="drophover drophover--rightTop d-inline-block"
        >
          <span className="tag tag--fillWhite my-1 drophover-toggle">
            +{index}
          </span>
          <div className="drophover-menu drophover-menu--custom">{tagList}</div>
        </div>
      );
    };
    let masterarray = [];
    if (data.masterarray) {
      masterarray = data.masterarray;
    }
    const list = uploadFilesList.map((item) => {
      const tagsArray = [];
      let constTag = 0;
      if (item.tags === undefined) {
        return null;
      }
      const removeDuplicateTags = Utilities.removeDuplicateTags(item.tags);
      const tags = removeDuplicateTags.map((tag, index) => {
        let s;
        if (removeDuplicateTags.indexOf(tag) <= 1) {
          s = (
            <span
              key={removeDuplicateTags.indexOf(tag)}
              className="tag tag--fill my-1"
            >
              {Utilities.tagLabel(tag)}
            </span>
          );
        } else if (removeDuplicateTags.indexOf(tag) >= 2) {
          if (removeDuplicateTags.length - 1 - index !== 0) {
            constTag += 1;
            tagsArray.push(removeDuplicateTags[index]);
            return null;
          }
          constTag += 1;
          tagsArray.push(removeDuplicateTags[index]);
          s = setArray(tagsArray, tag, constTag);
        }
        return s;
      });
      const date = moment.tz(item.date_created, timeZone).format('MM/DD/YYYY');
      let ischecked = false;
      masterarray.map((m) => {
        if (m.id === item.id && m.doc_name === item.doc_name) {
          ischecked = true;
        }
        return ischecked;
      });
      let m;
      if (item.doc_name.includes('.')) {
        m = item.doc_name.substring(0, item.doc_name.lastIndexOf('.'));
        // m = item.doc_name.split('.')[0];
      } else {
        m = item.doc_name;
      }
      const logo = Utilities.fileType(item);
      return (
        <div className="row" key={item.id}>
          <div className="col-auto tdCol pr-0 flex-grow-0">
            <div className="checkbox checkbox-primary check-container">
              <Label htmlFor={item.id}>
                <Input
                  key={item.id}
                  type="checkbox"
                  id={item.id}
                  checked={ischecked}
                  value={item.id}
                  className={`chkbx ${item.id}`}
                  name="isChecked"
                  onChange={() =>
                    this.props.component.handleCheckbox(
                      item,
                      uploadFilesList.indexOf(item),
                      `${item.id}_${item.doc_name ? item.doc_name : ''}`,
                    )
                  }
                />
                <i className="checkmark" />
              </Label>
            </div>
          </div>
          <div className="col-3 tdCol">
            <div className="tdata d-flex">
              <span className="fileTtype align-self-center">
                <i className={logo} aria-hidden="true" />
              </span>
              <div
                className={
                  data.editFileNameId === item.id
                    ? 'tdata--detail tdata--icon d-none'
                    : 'tdata--detail tdata--icon'
                }
              >
                <h5
                  onClick={
                    data.errors.docName ? null : () => this.showDocument(item)
                  }
                  className="fontSize fileName mb-0"
                >
                  {item.doc_name}
                  {item.is_private && (
                    <i className="icon-Lock text-dark ml-1" />
                  )}
                </h5>
                {liscioVault ? (
                  <a
                    href="javascript:;"
                    onClick={() =>
                      this.props.component.handleCheckbox(
                        item,
                        uploadFilesList.indexOf(item),
                        `${item.id}_${item.doc_name ? item.doc_name : ''}`,
                      )
                    }
                  >
                    <span className="attachBy text-dark">
                      by {item.uploaded_by}
                    </span>
                  </a>
                ) : (
                  <a href="javascript:;">
                    <span className="attachBy text-dark">
                      by {item.uploaded_by}
                    </span>
                  </a>
                )}
                {!liscioVault && (
                  <a
                    href="javascript:;"
                    className="editTd"
                    ref={(node) => {
                      window.editButton = node;
                    }}
                    onClick={
                      data.errors.docName
                        ? null
                        : (e) => {
                            e.stopPropagation();
                            this.editFileName(e, item);
                          }
                    }
                  >
                    <i className="icon-edit" />
                  </a>
                )}
              </div>
              <div
                className={
                  data.editFileNameId === item.id
                    ? data.errors.docName
                      ? 'tdata--detail tdata--edit d-block form-group form-error mb-0'
                      : 'tdata--detail tdata--edit d-block form-group'
                    : 'tdata--detail tdata--edit d-none'
                }
              >
                <input
                  type="text"
                  className="form-control"
                  value={m}
                  ref={(node) => {
                    window.currInput = node;
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    this.editedFileChange(e, item);
                  }}
                  onChange={(e) => {
                    this.props.component.handleChangeFileName(e, item);
                  }}
                  onKeyPress={(e) => {
                    this.props.component.handleKeyPress(e, item);
                  }}
                  onBlur={(e) => {
                    this.props.component.handleKeyPress(e, item, 'blurevent');
                  }}
                />
                <span className="error_label">{data.errors.docName}</span>
              </div>
            </div>
          </div>
          <div className="col-3 tdCol">{tags.length > 0 ? tags : '-'}</div>
          {/* <div className="col-3 tdCol hidden">
                      {this.renderRecipients(item, uploadFilesList.indexOf(item))}
                    </div> */}
          <div className="col-2 tdCol">
            <span>{item.upload_file_month ? item.upload_file_month : '-'}</span>
          </div>
          <div className="col-2 tdCol">
            <span>{item.upload_file_year ? item.upload_file_year : '-'}</span>
          </div>
          <div className="col tdCol">
            <span>{date}</span>
          </div>
        </div>
      );
    });
    let pagination = 0;
    let selectall = false;
    let selectedlistLength = 0;
    if (data.pagination) {
      pagination = data.pagination;
    }
    if (data.selectall) {
      selectall = data.selectall;
    }
    if (data.selectedlist) {
      selectedlistLength = data.selectedlist.length;
    }

    return (
      <>
        <div>
          <div id="loading" style={data.loading ? blockStyle : noneStyle} />
          <div className="tableWrap">
            <div className="tableHead">
              <div className="tableFilter d-flex justify-content-end">
                <span className="align-self-center mr-2" />
                <div className="select-custom-class" />
              </div>
            </div>
            <div className="tRow tRow--head">
              <div className="row">
                <div className="col-auto thCol pr-0 flex-grow-0">
                  <div className="checkbox checkbox-primary check-container">
                    <Label htmlFor="selectallcheckboxid">
                      <Input
                        type="checkbox"
                        name="selectallcheckboxid"
                        id="selectallcheckboxid"
                        key={pagination}
                        defaultChecked={selectall}
                        onClick={
                          uploadFilesList.length
                            ? this.props.component.handleSelectAll
                            : null
                        }
                      />
                      <i className="checkmark" />
                    </Label>
                  </div>
                </div>
                {filesHeaders.map((each) => (
                  <div
                    key={each.value}
                    className={this.returnClassName(each.value, each.className)}
                    onClick={this.returnClassName(each.value, 'click')}
                  >
                    <span className="posRel">
                      {each.label}
                      <i className={this.returnClassName(each.value, 'icon')} />
                    </span>
                  </div>
                ))}
              </div>
            </div>
            <div className="tRow tRow--body">
              {uploadFilesList.length ? (
                list
              ) : (
                <div className="row emptyTr">
                  <div className="emptyBox">
                    <div className="onlyText">
                      <p>No Records Found</p>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="d-flex mb-3">
              {uploadFilesList.length && !liscioVault ? (
                <div>
                  <ButtonGroup className="btn-group-transparent mr-3">
                    <Button
                      className={`btn-sm btn-transparent btn--onlyicon ${delete_button}`}
                      onClick={() =>
                        this.props.component.selectedFilesAction('delete')
                      }
                      data-tip
                      data-for="delete_icon"
                      data-testid="files__delete_icon"
                    >
                      <i className="icon-delete-small" />
                      <ReactTooltip id="delete_icon" effect="solid">
                        <span className="contactcard-team-member">Delete</span>
                      </ReactTooltip>
                    </Button>
                    <Dropdown
                      isOpen={this.state.showTagsDropDown}
                      toggle={() =>
                        this.setState({
                          showTagsDropDown: !this.state.showTagsDropDown,
                        })
                      }
                      className="border-left"
                      direction="up"
                    >
                      <DropdownToggle
                        className="btn-sm btn-transparent btn--onlyicon"
                        data-tip
                        data-for="tag_icon"
                        data-testid="files__tag_icon"
                      >
                        <i className="icon-tag" />
                        <ReactTooltip id="tag_icon" effect="solid">
                          <span className="contactcard-team-member">Tag</span>
                        </ReactTooltip>
                      </DropdownToggle>
                      <DropdownMenu className="mb-1">
                        <DropdownItem header className="pl-2 pb-0">
                          <h5>Tags</h5>
                        </DropdownItem>
                        <DropdownItem
                          onClick={() =>
                            this.props.component.selectedFilesAction(
                              'addmultitag',
                            )
                          }
                          data-testid="files__add_remove_tag"
                        >
                          Add & Remove Tags
                        </DropdownItem>
                        <DropdownItem
                          onClick={() =>
                            this.props.component.selectedFilesAction(
                              'replace_tags',
                            )
                          }
                          data-testid="files__find_replace_tag"
                        >
                          Find & Replace Tags
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                    <Button
                      className="btn-sm btn-transparent btn--onlyicon"
                      onClick={() =>
                        this.props.component.selectedFilesAction('download')
                      }
                      data-tip
                      data-for="download_icon"
                      data-testid="files__download_icon"
                    >
                      <i className="icon-download2" />
                      <ReactTooltip id="download_icon" effect="solid">
                        <span className="contactcard-team-member">
                          Download
                        </span>
                      </ReactTooltip>
                    </Button>
                    <Button
                      className="btn-sm btn-transparent btn--onlyicon"
                      onClick={() =>
                        this.props.component.selectedFilesAction('status')
                      }
                      data-tip
                      data-for="archive_unarchive_icon"
                      data-testid="files__unarchive_icon"
                    >
                      <i
                        className={
                          data.filterStatus === 'active'
                            ? 'icon-archive-line'
                            : 'icon-unarchive'
                        }
                      />
                      <ReactTooltip id="archive_unarchive_icon" effect="solid">
                        <span className="contactcard-team-member">
                          {data.filterStatus === 'active'
                            ? 'Archive'
                            : 'Unarchive'}
                        </span>
                      </ReactTooltip>
                    </Button>
                    <Button
                      className="btn-sm btn-transparent btn--onlyicon"
                      onClick={() =>
                        this.props.component.selectedFilesAction('share')
                      }
                      data-tip
                      data-for="share_icon"
                      data-testid="files__share_icon"
                    >
                      <i className="icon-Share" />
                      <ReactTooltip id="share_icon" effect="solid">
                        <span className="contactcard-team-member">
                          Share File
                        </span>
                      </ReactTooltip>
                    </Button>
                    <Button
                      className="btn-sm btn-transparent btn--onlyicon"
                      onClick={() =>
                        this.props.component.selectedFilesAction('export')
                      }
                      data-tip
                      data-for="export_icon"
                      data-testid="files__export_icon"
                    >
                      <i className="icon-File-Upload" />
                      <ReactTooltip id="export_icon" effect="solid">
                        <span className="contactcard-team-member">Export</span>
                      </ReactTooltip>
                    </Button>
                  </ButtonGroup>
                  <span className="align-self-center text-dark">
                    File Selected ({selectedlistLength})
                  </span>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        {liscioVault && showDocPop && (
          <Modal
            isOpen={showDocPop}
            className="customModal customModal--w990 customModal--nopadding modalMxHeight"
          >
            <ModalHeader className="ModalHeader">
              <button
                type="button"
                onClick={() => this.setState({ showDocPop: false })}
                className="close"
                aria-label="Close"
              >
                <i className="icon-close2" aria-hidden="true" />
              </button>
            </ModalHeader>
            <ModalBody className="modalContent">
              <div className="modalContent--inner">
                <div
                  className={
                    isIE ? 'left-inner-content' : 'sample-letter-preview'
                  }
                >
                  <div className="letter-logo" />
                  {isIE ? (
                    <PdfViewer
                      file={this.state.selectedItem.aws_url_origional}
                    />
                  ) : (
                    <iframe
                      width="100%"
                      height="600"
                      src={this.state.selectedItem.aws_url_origional}
                      title="selected attachment"
                    />
                  )}
                </div>
              </div>
            </ModalBody>
          </Modal>
        )}
        {!liscioVault && showDocPop && (
          <Modal isOpen={showDocPop} className="Md-full-width modal-FullH">
            <DocumentShow
              data={data}
              years={data.years}
              months={data.months}
              tags={data.uploadTags}
              onButtonClose={this.onButtonClose}
              selectedItem={selectedItem}
              listOfFilesState={this.listOfFilesState}
              editFileName={this.editFileName}
              editedFileChange={this.editedFileChange}
              component={this.props.component}
            />
          </Modal>
        )}
        <ConfirmationModal
          isOpen={showAlert && showAlert.length}
          noBtnClick={() => this.setState({ showAlert: '' })}
          yesBtnClick={() => this.setState({ showAlert: '' })}
          messageText={showAlert}
          yesBtnText="OK"
        />
        {this.state.showMultipleSource && (
          <MultipleSourceModal closeModal={this.closeModal} />
        )}
        {this.state.showManualSource && (
          <ManualSourceModal
            closeModal={this.closeModal}
            fileDetails={fileDetails}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  previewFileDetail: state.uploadFilesReducer.previewFileDetail,
  previewFileModal: state.uploadFilesReducer.previewFileModal,
});

const mapDispatchToProps = (dispatch) => ({
  files: bindActionCreators(UploadFilesActions, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UploadsList),
);
