/* eslint-disable */
import React, { Component } from 'react';
import { getStorageData, getCpaPreferences } from 'utilities/utils';
import Adobe from './Adobe';
import Payments from './Payments_updated';
import Karbon from './Karbon';
import KarbonManualSync from './KarbonManualSync';
import Quickbooks from './Quickbooks_updated';

/**
 * @deprecated We're moving towards using the new integration modules
 * at src/components/Integrations/IntegrationsLandingPage.tsx
 */
class Integrations extends Component {
  constructor(props) {
    super(props);
    const parsedUrl = new URL(window.location.href);
    const tab = parsedUrl.searchParams.get('tab');
    let header = 'Integrations';
    let syncTab = '';
    if (
      tab &&
      (tab === 'liscio_karbon_accounts' || tab === 'liscio_karbon_contacts')
    ) {
      header = 'Liscio <=> Karbonhq';
      syncTab = tab;
    }
    this.state = {
      loading: true,
      header,
      syncTab,
      preferences: getCpaPreferences(),
    };
  }

  componentDidMount() {
    document.title = 'Integrations';
    // TODO: __HOMEDATA-AUDIT
    if (getStorageData() && getStorageData().role !== 'firm_admin') {
      this.handleRoute();
    } else {
      const { header, syncTab } = this.state;
      if (header && header === 'Liscio <=> Karbonhq' && syncTab) {
        this.props.navigate('/integrations?tab=' + syncTab);
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    const { match, history } = this.props;
    // TODO: history.action needs to be converted to react router v6

    // if (match && match.url === '/integrations' && history.action === 'POP') {
    //   this.setState({ loading: true }, () => {
    //     this.syncData('');
    //   });
    // }
  }

  syncData = (data) => {
    this.setState({
      header: data ? 'Liscio <=> Karbonhq' : 'Integrations',
      syncTab: data,
    });
    const tb = data === '' ? '' : `?tab=${data}`;
    this.props.navigate('/integrations' + tb);
  };

  handleRoute = () => {
    this.props.navigate('/');
  };

  handleIntegrationApps = (each) => {
    let page = null;
    if (each.key === 'adobe') {
      page = <Adobe integrationAppsPref={each} />;
    }
    if (each.key === 'stripe') {
      page = <Payments integrationAppsPref={each} />;
    }
    if (each.key === 'karbon') {
      page = <Karbon syncData={this.syncData} integrationAppsPref={each} />;
    }
    if (each.key === 'quickbooks') {
      page = <Quickbooks integrationAppsPref={each} />;
    }
    return page;
  };

  render() {
    const { header, preferences } = this.state;
    const karbonHeader = (
      <div className="d-block">
        <ul className="breadcrumb">
          <li className="breadcrumb-item">
            <a onClick={() => this.syncData('')}>
              <i className="icon-left-arrow label-small" aria-hidden="true" />
              {'Integrations'.toUpperCase()} /
            </a>{' '}
            {'Liscio <=> Karbonhq'}
          </li>
        </ul>
      </div>
    );
    return (
      <div id="content">
        <header>
          {header === 'Liscio <=> Karbonhq' ? (
            karbonHeader
          ) : (
            <h1 className="pageHeading">{header}</h1>
          )}
        </header>
        {header === 'Liscio <=> Karbonhq' ? (
          <KarbonManualSync
            syncData={this.syncData}
            data={this.state}
            handleRoute={this.handleRoute}
          />
        ) : (
          <div className="center-wrapper col m-auto">
            {preferences &&
            preferences.integration_app &&
            preferences.integration_app.length ? (
              <div className="row align-items-stretch">
                {preferences.integration_app.map((each) =>
                  this.handleIntegrationApps(each),
                )}
              </div>
            ) : null}
          </div>
        )}
      </div>
    );
  }
}

export default Integrations;
