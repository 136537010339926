/* eslint-disable */
/**
 * @class ReactPdfJs
 */
import PdfJsLib from 'pdfjs-dist';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
// import * as worker from 'pdfjs-dist/build/pdf.worker.js';

export default class ReactPdfJs extends Component {
  static propTypes = {
    file: PropTypes.string.isRequired,
    page: PropTypes.number,
    onDocumentComplete: PropTypes.func,
  };

  static defaultProps = {
    page: 1,
    onDocumentComplete: null,
  };

  state = {
    pdf: null,
  };

  componentDidMount() {
    PdfJsLib.GlobalWorkerOptions.workerSrc =
      'build/webpack/pdf.worker.bundle.js';
    PdfJsLib.getDocument(this.props.file).then((pdf) => {
      this.setState({ pdf });
      if (this.props.onDocumentComplete) {
        this.props.onDocumentComplete(pdf._pdfInfo.numPages);
      }
      pdf.getPage(this.props.page).then((page) => {
        const { scale } = this.props;
        const viewport = page.getViewport(scale);
        const { canvas } = this;
        const canvasContext = canvas.getContext('2d');
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        const renderContext = {
          canvasContext,
          viewport,
        };
        page.render(renderContext);
      });
    });
  }

  componentWillReceiveProps(newProps) {
    if (
      newProps.page !== this.props.page ||
      newProps.scale !== this.props.scale
    ) {
      this.state.pdf.getPage(newProps.page).then((page) => {
        const { scale } = this.props;
        const viewport = page.getViewport(scale);
        const { canvas } = this;
        const canvasContext = canvas.getContext('2d');
        canvas.height = viewport.height;
        canvas.width = viewport.width;
        canvas.style.transform = `scale(${this.props.scale})`;

        const renderContext = {
          canvasContext,
          viewport,
        };
        page.render(renderContext);
      });
    }
  }

  render() {
    return (
      <canvas
        ref={(canvas) => {
          this.canvas = canvas;
        }}
      />
    );
  }
}
