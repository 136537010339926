import { UseFormReturn } from 'react-hook-form';
import { BuilderHeaderForm } from 'components/Requests/components/RequestsBuilder/RequestsBuilder';
import { useSaveWorkflowMutation } from 'hooks/workflow-hooks';
import { useCallback, useRef } from 'react';
import { SaveWorkflowMutationVariables } from 'gql/graphql';
import { AlertMessage } from 'utilities/utils';
import { TOAST_FAILURE } from 'constants/constants';

export const useFormSubmission = (
  workflowId: string,
  formMethods: UseFormReturn<BuilderHeaderForm>,
  setSubmitted: (value: boolean) => void,
) => {
  const { mutate: saveWorkflowMutation } = useSaveWorkflowMutation();
  const latestFormValues = useRef<BuilderHeaderForm>({
    title: '',
    description: '',
  });

  const submitForm = useCallback(
    ({ title, description }: BuilderHeaderForm) => {
      const variables: SaveWorkflowMutationVariables = {
        id: workflowId,
      };

      if (formMethods.formState.dirtyFields.title) {
        variables.title = title;
      }

      if (formMethods.formState.dirtyFields.description) {
        variables.description = description;
      }

      saveWorkflowMutation(
        {
          id: workflowId,
          title,
          description,
        },
        {
          onSuccess: (response) => {
            if (!response) {
              AlertMessage(
                TOAST_FAILURE,
                'Failed to update title and description',
              );
              return;
            }

            if (
              title === latestFormValues.current.title &&
              description === latestFormValues.current.description
            ) {
              formMethods.reset({
                title,
                description,
              });
            }
            setSubmitted(true);
          },
        },
      );
    },
    [workflowId, formMethods, saveWorkflowMutation, setSubmitted],
  );

  return { submitForm, latestFormValues };
};
