/* eslint-disable no-nested-ternary */
import React from 'react';
import { Label, Input } from 'reactstrap';
import { sortingIcon, generateUniqueKey } from 'utilities/utils';
import TooltipItem from 'common/TooltipItem';

const SearchList = (props) => {
  const {
    listData,
    tableHeaders,
    sortBy,
    sortOrder,
    pagination,
    selectedAll,
    showCheckbox,
    showDeleteIcon,
    showPreviewIcon,
    showExpiredIcon,
    showActionDiv,
    showExpiredLabel,
  } = props;
  return (
    <div className="tableWrap table-responsive">
      <div className="tRow tRow--head">
        <div className="row">
          {showCheckbox ? (
            <div className="col-auto thCol pr-0 flex-3 ie-px-1">
              <div className="checkbox checkbox-primary check-container">
                <Label htmlFor="selectallcheckbox">
                  <Input
                    type="checkbox"
                    name="selectallcheckbox"
                    id="selectallcheckbox"
                    key={pagination}
                    checked={selectedAll}
                    onChange={props.handleSelectAll}
                  />
                  <i className="checkmark" />
                </Label>
              </div>
            </div>
          ) : null}
          {tableHeaders.map((each) => (
            <div
              key={each.value}
              className={`${
                sortingIcon(sortBy, each.value, sortOrder, 'className') +
                each.className
              } thCol${!each.isSortable ? ' cursor-default' : ''}`}
              onClick={() =>
                each.isSortable ? props.handleSortBy(each.value) : null
              }
            >
              <span className="posRel">
                {each.label}
                <i className={sortingIcon(sortBy, each.value, sortOrder)} />
              </span>
            </div>
          ))}
          {showActionDiv ? (
            <div
              className={
                showCheckbox
                  ? 'col-auto thCol ie-px-1 flex-14 text-right'
                  : 'col-auto thCol ie-px-1 flex-18 text-right'
              }
            >
              {showExpiredLabel ? (
                <span
                  className="posRel"
                  onClick={() => props.handleSortBy('status')}
                >
                  Expired
                  <i className={sortingIcon(sortBy, 'status', sortOrder)} />
                </span>
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
      <div className="tRow tRow--body">
        {listData.map((eachone, key) => (
          <div
            key={
              props.uniquekey
                ? props.uniquekey(eachone)
                : generateUniqueKey(key)
            }
            className="tdBtn row"
          >
            {showCheckbox ? (
              <div className="col-auto tdCol pr-0 flex-3 ie-px-1">
                <div className="checkbox checkbox-primary check-container">
                  <Label htmlFor={eachone.id}>
                    <Input
                      key={eachone.id}
                      type="checkbox"
                      id={eachone.id}
                      checked={eachone.checked ? eachone.checked : false}
                      className={`chkbx ${eachone.id}`}
                      name="isChecked"
                      onChange={() => props.handleCheckbox(key)}
                    />
                    <i className="checkmark" />
                  </Label>
                </div>
              </div>
            ) : null}
            {tableHeaders.map((each) => (
              <div
                key={each.value}
                className={`${each.className} tdCol`}
                onClick={() => props.handleCheckbox(key)}
              >
                <span>
                  {each.formatFn
                    ? each.formatFn(eachone)
                    : eachone[each.value] && eachone[each.value] !== null
                    ? eachone[each.value]
                    : '-'}
                </span>
              </div>
            ))}
            {/* <td onClick={() => props.handleCheckbox(key)}>{eachone.contact.last_name + ', ' + eachone.contact.first_name + ' ' + (eachone.contact.middle_name !== null ? eachone.contact.middle_name : '')}</td>
            <td onClick={() => props.handleCheckbox(key)}>{eachone.contact.primary_email && eachone.contact.primary_email !== null && eachone.contact.primary_email.length > 0 ? eachone.contact.primary_email : '-'}</td>
            <td onClick={() => props.handleCheckbox(key)}>{eachone.last_invite_sent && eachone.last_invite_sent !== null ? eachone.last_invite_sent : '-'}</td> */}
            {showActionDiv ? (
              <div
                className={
                  showCheckbox
                    ? 'col-auto tdCol text-right ie-px-1 flex-14'
                    : 'col-auto tdCol text-right ie-px-1 flex-18'
                }
              >
                {!showCheckbox && showPreviewIcon ? (
                  <TooltipItem
                    position="top"
                    tooltipType="button"
                    id={`preview${generateUniqueKey(key)}`}
                    text="Preview Letter"
                    btnClass="btn-sm btn--onlyicon btn-link"
                    clickAction={() => props.handleCheckbox(key)}
                    iconn="icon-open-eye"
                  />
                ) : null}
                {showDeleteIcon ? (
                  <TooltipItem
                    position="top"
                    tooltipType="button"
                    id={`delete${generateUniqueKey(key)}`}
                    text="Delete"
                    btnClass="btn-sm btn--onlyicon btn-link"
                    clickAction={() => props.handleDelete(key, eachone)}
                    iconn="icon-delete-small"
                  />
                ) : null}
                {showExpiredIcon ? (
                  <TooltipItem
                    position="top"
                    tooltipType="button"
                    id={`Inviteexpired${generateUniqueKey(key)}`}
                    text="Invite Expired"
                    btnClass={
                      eachone.is_invitation_expired
                        ? 'btn-sm btn--onlyicon btn-link cursor-default btn btn-secondary visible'
                        : 'btn-sm btn--onlyicon btn-link cursor-default invisible'
                    }
                    clickAction={() => {}}
                    iconn="icon-expired text-danger"
                  />
                ) : null}
              </div>
            ) : null}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SearchList;
