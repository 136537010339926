import React, { Component } from 'react';
import Axios from 'axios';
import moment from 'moment';
import { sortingIcon, AlertMessage, sortList } from 'utilities/utils';
import TooltipItem from 'common/TooltipItem';
import { GET_TEMPLATES } from 'constants/constants';
import ConfirmationModal from 'common/ConfirmationModal';
import NoRecords from 'common/NoRecords';

const tableHeaders = [
  {
    value: 'name',
    label: 'Name',
    isSortable: true,
    className: ' col thCol',
  },
  {
    value: 'modifiedDate',
    label: 'Modified Date',
    isSortable: true,
    className: ' col-3 thCol',
  },
  {
    value: 'actions',
    label: '',
    isSortable: false,
    className: ' col-2 thCol text-right',
  },
];

export default class EsignTemplatesList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      documentsList: [],
      sortBy: 'name',
      sortOrder: 'desc',
    };
  }

  componentDidMount() {
    this.getTemplates();
  }

  getTemplates = () => {
    Axios.get(GET_TEMPLATES)
      .then((res) => {
        if (
          res.status &&
          res.status === 200 &&
          res.data &&
          res.data.data &&
          res.data.data.libraryDocumentList
        ) {
          const documentsList = res.data.data.libraryDocumentList.filter(
            (e) => e.sharingMode && e.sharingMode.toLowerCase() !== 'global',
          );
          this.setState({
            documentsList,
            loading: false,
          });
        } else {
          this.setState({ loading: false });
          AlertMessage('error', res.data.message, 6000);
        }
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  };

  handleSortBy = (sortBy) => {
    let { sortOrder } = this.state;
    const { documentsList } = this.state;
    if (this.state.sortBy === sortBy) {
      if (sortOrder === 'asc') {
        sortOrder = 'desc';
      } else {
        sortOrder = 'asc';
      }
    } else {
      sortOrder = 'asc';
    }
    this.setState({
      sortOrder,
      sortBy,
      documentsList: sortList(documentsList, sortBy, sortOrder),
    });
  };

  deleteTemplate = () => {
    const { deleteTemplateId } = this.state;
    this.setState({
      deleteTemplateId: undefined,
      loading: true,
    });
    Axios.delete(`${GET_TEMPLATES}/${deleteTemplateId}`)
      .then((res) => {
        if (res.status === 200 && res.data && res.data.success) {
          AlertMessage('success', res.data.message, 6000);
          this.getTemplates();
        } else {
          this.setState({ loading: false });
          AlertMessage('error', res.data.message, 6000);
        }
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  };

  showDeleteTemplateAlert = (input) => {
    const { id } = input;
    if (this.state.deleteTemplateId === undefined) {
      this.setState({ deleteTemplateId: id });
    } else {
      this.setState({ deleteTemplateId: undefined });
    }
  };

  render() {
    const { documentsList, deleteTemplateId } = this.state;
    let tableHeadersArray = [...tableHeaders];
    if (this.props.handleEditBtn === undefined) {
      tableHeadersArray = [...tableHeadersArray.slice(0, 2)];
    }
    if (
      this.state.documentsList &&
      this.state.documentsList.length === 0 &&
      !this.state.loading
    ) {
      return <NoRecords title="No Templates Found" description=" " />;
    }
    return (
      <div>
        {this.state.loading ? <div id="loading" /> : null}
        <div className="tab-content">
          <div className="tab-pane active" id="review">
            <div className="tableWrap">
              <div className="tRow tRow--head">
                <div className="row">
                  {tableHeadersArray.map((each) => (
                    <div
                      key={each.value}
                      className={
                        sortingIcon(
                          this.state.sortBy,
                          each.value,
                          this.state.sortOrder,
                          'className',
                        ) +
                        each.className +
                        (!each.isSortable ? ' cursor-default' : '')
                      }
                      onClick={() =>
                        each.isSortable ? this.handleSortBy(each.value) : null
                      }
                    >
                      <span
                        className={
                          each.isSortable ? 'posRel' : 'posRel cursor-default'
                        }
                      >
                        {each.label}
                        {each.isSortable && (
                          <i
                            className={sortingIcon(
                              this.state.sortBy,
                              each.value,
                              this.state.sortOrder,
                            )}
                          />
                        )}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
              <div className="tRow tRow--body">
                {documentsList.map((each) => (
                  <div key={each.id} className="row tdBtn">
                    <div
                      className="col tdCol"
                      onClick={() => this.props.assignSelectedTemplateId(each)}
                    >
                      <span>{each.name}</span>
                    </div>
                    <div
                      className="col-3 tdCol"
                      onClick={() => this.props.assignSelectedTemplateId(each)}
                    >
                      <span>
                        {each.modifiedDate &&
                          moment(each.modifiedDate).format('MM/DD/YYYY')}
                      </span>
                    </div>
                    {this.props.handleEditBtn && (
                      <div className="col-2 tdCol text-right">
                        <TooltipItem
                          position="top"
                          tooltipType="button"
                          id={`edittemplate${each.id}`}
                          text="Edit"
                          btnClass="btn-sm btn--onlyicon btn-link tdBtn__hover"
                          clickAction={() => this.props.handleEditBtn(each)}
                          iconn="icon-edit"
                        />
                        <TooltipItem
                          position="top"
                          tooltipType="button"
                          id={`deletetemplate${each.id}`}
                          text="Delete"
                          btnClass="btn-sm btn--onlyicon btn-link tdBtn__hover"
                          clickAction={() => this.showDeleteTemplateAlert(each)}
                          iconn="icon-delete-small"
                        />
                      </div>
                    )}
                  </div>
                ))}
                {documentsList &&
                  documentsList.length === 0 &&
                  !this.state.loading && (
                    <div className="row emptyTr">
                      <div className="emptyBox">
                        <div className="onlyText">
                          <p>No Records Found</p>
                        </div>
                      </div>
                    </div>
                  )}
              </div>
              {deleteTemplateId && (
                <ConfirmationModal
                  isOpen
                  headerText="Delete template?"
                  yesBtnText="Yes"
                  noBtnText="No"
                  yesBtnClick={this.deleteTemplate}
                  noBtnClick={this.showDeleteTemplateAlert}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
