/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// TODO: Keep the comments for future use
import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import {
  MENU_ITEMS_API_PATH,
  LOGGED_IN_TYPE,
  SWITCHED_USER,
  BULK_ACTIONS_SUB_MENU,
  ADD_NEW_SUB_MENU,
  PROFILE_SETTINGS_MENU,
  TASK_DETAIL,
  ACCOUNT_DETAILS,
  CONTACT_DETAILS,
  INBOX_URL,
  ALERT_URL,
  NEW_INVOICE_SUB_MENU,
  DEFAULT,
  RECURRING,
  RECURRING_CHARGE_SUB_MENU,
  FILE,
  GET_COUNT_INVOICES_THAT_NEED_ATTENTION,
  GET_CPA_INVOICES_SETTINGS,
  ACTIVE_STRIPE_ACCOUNT,
  NO_STRIPE_ACCOUNT,
  SENT_URL,
  FIRM_ADMIN,
} from 'constants/constants';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';
import Avatar from '@mui/material/Avatar';
import liscioLogo from 'images/liscio_logo.png';
import { isClient } from 'utilities/utils';
import {
  Link,
  Location,
  useLocation,
  useNavigate,
} from 'react-router-dom-latest';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import * as HomeActions from 'actions/HomeActions';
import * as UploadFilesActions from 'actions/UploadFilesActions';
import * as PaginationActions from 'actions/PaginationActions';
import MultipleCpa from 'common/MultipleCpa';
import InvoiceModal from 'components/Billing/elements/InvoiceModal/InvoiceModal';
import FilePopup from 'components/Files/FilePopup';
import { useWebsocket } from 'websocket';
import { clearAuthSession, getAuthSession } from 'auth/session';
import SidebarLoadingScreen from './SidebarLoadingScreen';
import StripeAccountSetupModal from './elements/StripeAccountSetupModal';
import SidebarMenuList from './elements/SidebarMenuList';
import StarMenuList from './elements/StarMenuList';
import StyledDiv from './sidebarStyles';

const Sidebar = ({ unread_thread_count }: { unread_thread_count: number }) => {
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const location = useLocation() as Location & { state: { from: string } };

  const [menus, setMenus] = useState([]);
  const [userInfo, setUserInfo] = useState({
    uname: '',
    avatar: null,
    isFirmAdmin: false,
  });
  const [activeSidebarMenuItem, setActiveSidebarMenuItem] = useState('');
  const [showModalFor, setShowModalFor] = useState('');
  const [invoiceMode, setInvoiceMode] = useState(DEFAULT);
  const [billingMenuItemCount, setBillingMenuItemCount] = useState(0);
  const [stripeAccountStatus, setStripeAccountStatus] = useState(undefined);
  const [isStripeAccountStatusModalOpen, openStripeAccountStatusModal] =
    useState(false);
  const userSwitched =
    localStorage.getItem(LOGGED_IN_TYPE) &&
    localStorage.getItem(LOGGED_IN_TYPE) === SWITCHED_USER;

  const isStripeAccountDisabled = Boolean(
    stripeAccountStatus &&
      stripeAccountStatus === NO_STRIPE_ACCOUNT &&
      !isClient(),
  );
  const websocket = useWebsocket();

  useEffect(() => {
    getMenuItems();
    displayInvoiceModal();
    if (isClient()) {
      getBillingMenuItemCount();
    }
  }, []);

  async function getBillingMenuItemCount() {
    try {
      const response = await Axios.get(GET_COUNT_INVOICES_THAT_NEED_ATTENTION);
      if (response.status === 200) {
        setBillingMenuItemCount(response.data.data.invoices_count);
      }
    } catch (error) {
      console.error(error);
    }
  }

  // Keeping it for reference
  async function getMenuItems() {
    const authSession = getAuthSession();
    if (authSession) {
      const authToken = authSession.auth_token;
      // @ts-ignore
      websocket.authenticate(authToken);
      try {
        const response = await Axios.get(MENU_ITEMS_API_PATH);
        if (response.status === 200) {
          const { data } = response;
          setMenus(data?.data || []);
          setUserInfo({
            uname: data?.uname || '',
            avatar: data?.avatar || '',
            isFirmAdmin:
              (data?.is_employee && data?.role === FIRM_ADMIN) || false,
          });
        }
      } catch (error) {
        clearAuthSession();
        localStorage.clear();
        window.location.href = '/';
        console.error(error);
      }
    }
  }

  async function displayInvoiceModal(modalMode?: string) {
    try {
      const response = await Axios.get(GET_CPA_INVOICES_SETTINGS);
      if (response.status === 200) {
        const {
          data: { stripe_account_status },
        } = response.data;
        if (stripe_account_status === ACTIVE_STRIPE_ACCOUNT && modalMode) {
          setInvoiceMode(modalMode);
          setShowModalFor('invoice');
        } else {
          setStripeAccountStatus(stripe_account_status);
          if (modalMode) openStripeAccountStatusModal(true);
        }
      }
    } catch (err) {
      console.error(err);
    }
  }

  function closeStripeAccountStatusModal() {
    openStripeAccountStatusModal(false);
  }

  /**
   * Click handler for sidebar menu item click
   * @param {Object} event
   * @param {Object} item
   */
  function handleSubMenuClick(
    event: React.MouseEvent,
    item: Partial<MenuItem>,
  ) {
    const element = event.currentTarget as DatasetElement;
    const { selected } = element.dataset;
    setActiveSidebarMenuItem(String(element.dataset.selected));

    const billingModalMode =
      item.label === NEW_INVOICE_SUB_MENU ? DEFAULT : RECURRING;

    if (
      selected === ADD_NEW_SUB_MENU ||
      selected === BULK_ACTIONS_SUB_MENU ||
      selected === PROFILE_SETTINGS_MENU
    ) {
      /**
       *  This check is required to open the modal on Billing page
       *  when clicking on invoice sub menu
       */
      if (
        item.label === NEW_INVOICE_SUB_MENU ||
        item.label === RECURRING_CHARGE_SUB_MENU
      ) {
        displayInvoiceModal(billingModalMode);
      } else if (item.label === FILE) {
        setShowModalFor('file');
      } else {
        navigate(item.url || '', {
          state: { from: location.pathname, page: 'sidebar' },
        });
      }
    }
  }

  /**
   * This function returns active sidebar menu item
   * @param {Object} item
   * @returns {Boolean}
   */
  function getSelectedMenuItem(item: Partial<MenuItem>) {
    const { state } = location;

    if (
      activeSidebarMenuItem &&
      item.url !== activeSidebarMenuItem &&
      ((state?.from !== 'uploadlist' &&
        location.pathname.includes(TASK_DETAIL) &&
        item.url?.includes('task')) ||
        (location.pathname.includes(ACCOUNT_DETAILS) &&
          item.url?.includes('account')) ||
        (location.pathname.includes(CONTACT_DETAILS) &&
          item.url?.includes('contact')) ||
        ((location.pathname.includes(INBOX_URL) ||
          location.pathname.includes(ALERT_URL) ||
          location.pathname.includes(SENT_URL)) &&
          item.url?.includes('message')) ||
        item.url === location.pathname)
    ) {
      return true;
    }

    if (item.url === activeSidebarMenuItem) {
      return true;
    }

    if (item.url === location.pathname && !activeSidebarMenuItem) {
      return true;
    }

    return false;
  }

  function handleLogoClick() {
    navigate('/');
  }

  return (
    <StyledDiv className="Sidebar" data-testid="sidebar">
      <Drawer variant="permanent" anchor="left">
        <div className="Sidebar__Logo">
          <img src={liscioLogo} alt="logo" onClick={handleLogoClick} />
        </div>
        <List className="Sidebar__MenuList">
          {menus.length === 0 ? (
            <SidebarLoadingScreen />
          ) : (
            <>
              <SidebarMenuList
                unread_thread_count={unread_thread_count}
                billingMenuItemCount={billingMenuItemCount}
                getSelectedMenuItem={getSelectedMenuItem}
                handleSubMenuClick={handleSubMenuClick}
                menus={menus}
                isStripeAccountDisabled={isStripeAccountDisabled}
              />
              <Link to="/profile_settings">
                <ListItemButton
                  onClick={(e) =>
                    handleSubMenuClick(e, { url: PROFILE_SETTINGS_MENU })
                  }
                  data-selected="/profile_settings"
                  selected={getSelectedMenuItem({ url: PROFILE_SETTINGS_MENU })}
                  data-testid="sidebar__profile_settings"
                >
                  <ListItemIcon>
                    <Avatar
                      alt="avatar"
                      sx={{ width: 32, height: 32 }}
                      src={userInfo.avatar || ''}
                    />
                  </ListItemIcon>
                  <ListItemText
                    className="ProfileSidebarText"
                    primary={userInfo.uname.toUpperCase() || ''}
                  />
                </ListItemButton>
              </Link>
              <MultipleCpa />
              <StarMenuList
                starMenu={menus[menus.length - 1]}
                navigate={navigate}
                userSwitched={userSwitched}
                isFirmAdmin={userInfo.isFirmAdmin || false}
                getSelectedMenuItem={getSelectedMenuItem}
                setActiveSidebarMenuItem={setActiveSidebarMenuItem}
              />
            </>
          )}
        </List>
      </Drawer>
      {showModalFor === 'invoice' && (
        <InvoiceModal
          editState={{}}
          isOpen={showModalFor === 'invoice'}
          handleModalClose={() => setShowModalFor('')}
          getAllInvoices={() => setShowModalFor('')}
          mode={invoiceMode}
        />
      )}
      {showModalFor === 'file' && (
        <FilePopup
          closeModal={() => setShowModalFor('')}
          viewContext="addnew"
        />
      )}
      {isStripeAccountStatusModalOpen && (
        <StripeAccountSetupModal
          isModalOpen={isStripeAccountStatusModalOpen}
          closeStripeAccountStatusModal={closeStripeAccountStatusModal}
          stripeAccountStatus={stripeAccountStatus || ''}
        />
      )}
    </StyledDiv>
  );
};

interface IState {
  messageReducer: { unread_thread_count: number };
  homeReducer: { data: any; avatar: string; username: string };
  notificationReducer: {
    isMultipleCpa: boolean;
    multipleCpaData: any;
    notiCount: number;
  };
}

const mapStateToProps = (state: IState) => ({
  unread_thread_count: state.messageReducer.unread_thread_count,
  homedata: state.homeReducer.data,
  userAvatar: state.homeReducer.avatar,
  userName: state.homeReducer.username,
  isMultipleCpa: state.notificationReducer.isMultipleCpa,
  multipleCpaData: state.notificationReducer.multipleCpaData,
  notificationCount: state.notificationReducer.notiCount,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  HomeActions: bindActionCreators(HomeActions, dispatch),
  UploadFilesActions: bindActionCreators(UploadFilesActions, dispatch),
  PaginationActions: bindActionCreators(PaginationActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
