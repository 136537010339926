/* eslint-disable */
import React, { Component, useState } from 'react';
import { withRouter } from 'utilities/withRouterWrapper';
import { Label, Input } from 'reactstrap';
import moment from 'moment-timezone';
import axios from 'axios';
import Interweave from 'interweave';
import * as Config from 'constants/constants';
import DashboardLoadingScreen from 'components/Dashboard/DashboardLoadingScreen';
import MessageActionOptions from './MessageActionOptions';

const timeZone =
  Intl.DateTimeFormat().resolvedOptions().timeZone || moment.tz.guess(true);
class MessageList extends Component {
  rowClick(item, key) {
    const { navigate, match, name } = this.props;

    let domain =
      match?.pathname === '/' ? 'Dashboard Messages Tab' : 'Core Inbox';

    if (match.pathname.includes('accountdetails'))
      domain = 'Account Messages Tab';
    if (match.pathname.includes('contactdetails'))
      domain = 'Contact Messages Tab';

    const userJourney = `${domain} => Message Thread Detail`;

    let id;
    let threadId;
    if (name === 'userlist') {
      this.props.showDetail(item);
    } else {
      // ************************************************************************
      if (this.props.filter === 'draft') {
        id = encodeURIComponent(item.thread_id);
        threadId = encodeURIComponent(item.id);
        navigate(`/draft/${id}`, {
          state: {
            from: '/draft/:id',
            filter: this.props.filter,
            activeTab: this.props.activeTab,
            draftId: threadId,
            path: match.pathname,
            url: match.pathname,
            paramId: id,
          },
        });
      }
      if (this.props.filter === 'inbox') {
        id = encodeURIComponent(item.thread_id);
        navigate(`/inbox/${id}`, {
          state: {
            filter: this.props.filter,
            activeTab: this.props.activeTab,
            path: match.pathname,
            url: match.pathname,
            from: '/inbox/:id',
            paramId: id,
          },
        });
      }
      if (this.props.filter === 'alert') {
        id = encodeURIComponent(item.thread_id);
        navigate(`/alert/${id}`, {
          state: {
            filter: this.props.filter,
            activeTab: this.props.activeTab,
            path: match.pathname,
            url: match.pathname,
            from: '/alert/:id',
            paramId: id,
          },
        });
      }
      if (this.props.filter === 'sent') {
        id = encodeURIComponent(item.thread_id);
        navigate(`/sent/${id}`, {
          state: {
            from: '/sent/:id',
            filter: this.props.filter,
            activeTab: this.props.activeTab,
            path: match.pathname,
            url: match.pathname,
            paramId: id,
          },
        });
        // history.push('/sent/' + id);
      }
      if (this.props.filter === 'archive') {
        id = encodeURIComponent(item.thread_id);
        navigate(`/archive/${id}`, {
          state: {
            from: '/archive/:id',
            filter: this.props.filter,
            activeTab: this.props.activeTab,
            path: match.pathname,
            url: match.pathname,
            paramId: id,
          },
        });
        // history.push('/sent/' + id);
      }
      if (this.props.filter === 'pinned') {
        console.log('item', item);
        if (item.is_draft) {
          id = encodeURIComponent(item.thread_id);
          threadId = encodeURIComponent(item.id);
          navigate(`/draft/${id}`, {
            state: {
              from: '/draft/:id',
              draftId: threadId,
              path: match.pathname,
              url: match.pathname,
              paramId: id,
            },
          });
        } else {
          id = encodeURIComponent(item.thread_id);
          navigate(`/pinned/${id}`, {
            state: {
              from: '/pinned/:id',
              filter: this.props.filter,
              activeTab: this.props.activeTab,
              path: match.pathname,
              url: match.pathname,
              paramId: id,
            },
          });
        }
      }
      if (this.props.filter === 'all_messages') {
        id = encodeURIComponent(item.thread_id);
        navigate(`/all_messages/${id}`, {
          state: {
            from: '/all_messages/:id',
            filter: this.props.filter,
            activeTab: this.props.activeTab,
            path: match.pathname,
            url: match.pathname,
            paramId: id,
          },
        });
      }
    }
    // ********************************************************
    this.props.readMessageThread(item, key);
  }

  pinMessageThread = (item, key) => {
    this.props.togglePinUnpin(item, key);
    const component = this;
    const msgId = encodeURIComponent(item.thread_id);
    const request = axios.put(`${Config.PIN_UNPIN_THREAD_MESSAGE}/${msgId}`);
    request
      .then((response) => {
        if (response.data.status === 200) {
          if (this.props.filter === 'pinned') {
            this.props.togglePinUnpin(item, key, 'noloader');
          }
          // this.props.togglePinUnpin(item, key);
        } else {
          this.props.togglePinUnpin(item, key);
        }
      })
      .catch((err) => {
        this.props.togglePinUnpin(item, key);
        console.log('Some error occurred. Please try later.');
        return null;
      });
  };

  render() {
    const { filter, match, getFilteredRecords } = this.props;
    const RenderMessageItem = ({ item, componentKey: key }) => {
      const onUpdateMsgToUnread = () => {
        if (getFilteredRecords) getFilteredRecords();
      };
      let senderArray = '';
      if (item.senders_in_html_format && item.senders_in_html_format.length) {
        senderArray = `<p>${item.senders_in_html_format}</p>`;
      } else if (item.all_senders && item.all_senders.length) {
        if (
          filter &&
          (filter === 'inbox' || filter === 'alert' || filter === 'pinned') &&
          !item.read
        ) {
          senderArray = `<strong>${item.all_senders
            .map((s) => s)
            .join(', ')}</strong>`;
        } else {
          senderArray = item.all_senders.map((s) => s).join(', ');
        }
      }
      let documentName = '';
      if (item.documents && item.documents.length) {
        let index = 0;
        if (item.read) {
          index = item.documents.length - 1;
        }
        if (item.documents[index].display_name) {
          documentName = item.documents[index].display_name.substring(0, 15);
        } else if (item.documents[index].aws_url_file_name) {
          documentName = item.documents[index].aws_url_file_name.substring(
            0,
            15,
          );
        }
      }
      let time = item.tym;
      if (item.tym.indexOf('ago') === -1) {
        time = moment.tz(time, timeZone).format('MM/DD/YY hh:mm a');
      }
      const initials = item.sender
        ? (item.sender.split(' ')[0]
            ? item.sender.split(' ')[0].charAt(0)
            : '') +
          (item.sender.split(' ')[1] ? item.sender.split(' ')[1].charAt(0) : '')
        : '';
      return (
        <div className="row" id={item.id}>
          <div
            className="col-2 col-sm-2 col-md-2 col-lg-2 tdCol px-0"
            style={{
              minWidth: '82px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <span
              className={
                !item.read &&
                (filter === 'inbox' ||
                  filter === 'alert' ||
                  filter === 'archive' ||
                  filter === 'pinned' ||
                  filter === 'all_messages')
                  ? 'rounded status visible mr-1'
                  : 'rounded status invisible mr-1'
              }
            />
            <span
              className={`checkbox checkbox-primary check-container mx-2  ${
                match.pathname !== '/all_messages' ||
                filter === 'sent' ||
                filter === 'draft' ||
                filter === 'archive' ||
                filter === 'all_messages'
                  ? 'invisible'
                  : ''
              }`}
              style={{ top: '2px' }}
            >
              <Label htmlFor={`chck ${item.id}`}>
                <Input
                  name="msg1"
                  id={`chck ${item.id}`}
                  key={`chck ${item.id}`}
                  checked={item.checked ? item.checked : false}
                  type="checkbox"
                  onChange={() => this.props.checkboxAction(item, key)}
                />
                <i className="checkmark" />
              </Label>
            </span>
            <span
              className={
                item.is_pinned ? 'px-3 text-danger' : 'px-3 text-light'
              }
            >
              <i
                className="icon-Pinned"
                onClick={
                  this.props.name === 'userlist'
                    ? () => this.props.togglePinUnpin(item, key)
                    : () => this.pinMessageThread(item, key)
                }
              />
            </span>
            <span
              onClick={() => this.rowClick(item, key)}
              className={`avatar avatar--w40 ${
                item.user_type === 'employee'
                  ? 'avatar--border avatar--borderPrimary'
                  : ''
              }`}
            >
              {item.avatar ? (
                <img
                  src={item.avatar}
                  alt=""
                  className="user-photo rounded-circle mr-0"
                />
              ) : (
                <span className="uName mr-0">{initials.toUpperCase()}</span>
              )}
            </span>
          </div>
          <div
            className="col-3 tdCol truncate"
            onClick={() => this.rowClick(item, key)}
          >
            <Interweave
              content={
                filter === 'inbox' ||
                filter === 'sent' ||
                filter === 'alert' ||
                filter === 'pinned'
                  ? senderArray
                  : item.sender
              }
            />
          </div>
          <div className="col tdCol" onClick={() => this.rowClick(item, key)}>
            <span
              className={
                !item.read &&
                (filter === 'inbox' ||
                  filter === 'archive' ||
                  filter === 'alert' ||
                  filter === 'pinned' ||
                  filter === 'all_messages')
                  ? 'w-100 pull-left font-weight-bold'
                  : 'w-100 pull-left'
              }
            >
              {(filter === 'inbox' ||
                filter === 'archive' ||
                filter === 'pinned' ||
                filter === 'all_messages') &&
              item.unread_count > 1 ? (
                <span className="tag tag--fill text-uppercase mr-2">
                  {item.unread_count} NEW
                </span>
              ) : null}
              {item.documents &&
              item.documents.length > 0 &&
              item.documents_count > 1 ? (
                <span className="tag tag--fill text-uppercase pull-right">
                  + {item.documents_count - 1}
                </span>
              ) : null}
              {item.documents && item.documents.length > 0 ? (
                <span className="tag tag--fill text-uppercase pull-right mx-2">
                  <img src={require('images/pdfText_img.png')} alt="" />
                  {documentName}
                </span>
              ) : null}
              {item.title}
            </span>
            <br />
            <span className="text-dark tdMsg noAnchor">
              <Interweave
                allowAttributes
                content={
                  item.content_limit && item.content !== null
                    ? item.content_limit
                        .replace(/html/gi, 'div')
                        .replace(/!doctype/gi, 'div></div')
                    : ''
                }
              />
              {/* <span dangerouslySetInnerHTML={{ __html: item.content_limit }} /> */}
            </span>
          </div>
          {/* *************************************************** */}
          <div
            className="col-2 tdCol text-right vertical-top"
            onClick={() => this.rowClick(item, key)}
          >
            <span className="text-dark vertical-top">{time}</span>
          </div>
          <MessageActionOptions
            message={item}
            onUpdateMsgToUnread={onUpdateMsgToUnread}
            selectedTab={filter}
            contact_data={this.props.contact_data}
          />
        </div>
      );
    };

    return (
      <div id="inbox-list" className="tableWrap">
        <div className="tRow tRow--head">
          <div className="row">
            <div
              className="col-2 col-sm-2 col-md-2 col-lg-2 thCol px-0"
              style={{
                minWidth: '82px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <span className="rounded status invisible mr-1" />
              <span
                className={`checkbox checkbox-primary check-container mx-2 ${
                  match.pathname !== '/all_messages' ||
                  filter === 'sent' ||
                  filter === 'draft' ||
                  filter === 'archive' ||
                  filter === 'all_messages'
                    ? 'invisible'
                    : ''
                } `}
              >
                <Label htmlFor="msg2">
                  <Input
                    name="msg2"
                    id="msg2"
                    type="checkbox"
                    checked={this.props.selectedAll}
                    key={this.props.pagination}
                    onChange={this.props.handleSelectAll}
                  />
                  <i className="checkmark" />
                </Label>
              </span>
            </div>
            <div className="col-3 thCol">
              <span className="posRel">
                {this.props.filter !== 'sent' ? 'Senders' : 'Recipients'}
              </span>
            </div>
            <div className="col thCol">
              <span className="posRel">Message Details</span>
            </div>
            <div className="col-2 thCol text-right vertical-top">
              <span className="posRel">Last Activity At</span>
            </div>
          </div>
        </div>
        <div className="tRow tRow--body">
          {!this.props.dataLoaded ? (
            <DashboardLoadingScreen />
          ) : this.props.list.length === 0 ? (
            <div className="row emptyTr">
              <div className="emptyBox">
                <div className="onlyText">
                  <p>No Records Found</p>
                </div>
              </div>
            </div>
          ) : (
            this.props.list.map((item, key) => (
              <RenderMessageItem item={item} key={item.id} componentKey={key} />
            ))
          )}
        </div>
      </div>
    );
  }
}
export default withRouter(MessageList);
