import React from 'react';
import { ModalBody } from 'reactstrap';

const SelectPaymentMethod = ({
  invoiceAmount,
  invoiceNumber,
  paymentMethod,
  handlePaymentMethod,
  noBtnClick,
  gettingCustomerData,
  gettingPlaidPublicToken,
  loading,
  setUpACHandCard,
  customerSources,
  handlePayButton,
  mode,
}) => (
  <ModalBody className="modalContent">
    <div className="modalContent--inner">
      <h4>Select your preferred payment method: </h4>
      <div className="row">
        {mode === 'payment' ? (
          <div className="form-group form-inline-label col-12">
            <label htmlFor="account">Invoice No</label>
            <div className="labelValue">
              <p>{invoiceNumber}</p>
            </div>
          </div>
        ) : (
          ''
        )}
        <div className="form-group form-inline-label col-12">
          <label htmlFor="account">Amount</label>
          <div className="labelValue">
            <p>
              {Number(invoiceAmount).toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              })}
            </p>
          </div>
        </div>
      </div>
      <div className="row pl-3">
        <div className="form-check mr-3">
          <input
            className="form-check-input"
            type="radio"
            name="paymentmethod"
            id="ach"
            value="ach"
            checked={paymentMethod === 'ach'}
            onChange={handlePaymentMethod}
          />
          <label className="form-check-label" htmlFor="ach">
            ACH
          </label>
        </div>
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            name="paymentmethod"
            id="checkout"
            value="checkout"
            checked={paymentMethod === 'checkout'}
            onChange={handlePaymentMethod}
          />
          <label className="form-check-label" htmlFor="checkout">
            Credit
          </label>
        </div>
      </div>
      <div className="row no-gutters mt-5">
        <button type="submit" className="btn mr-4" onClick={noBtnClick}>
          Cancel
        </button>
        {paymentMethod === 'ach' && !customerSources.length ? (
          <button
            type="submit"
            className="btn btn-primary"
            onClick={setUpACHandCard}
            disabled={loading || gettingCustomerData || gettingPlaidPublicToken}
          >
            {gettingCustomerData || loading || gettingPlaidPublicToken
              ? 'Loading'
              : 'Set ACH'}
          </button>
        ) : null}
        {paymentMethod === 'ach' && customerSources.length ? (
          <button
            type="submit"
            className="btn btn-primary"
            onClick={handlePayButton}
            disabled={loading}
          >
            Continue
          </button>
        ) : null}
        {paymentMethod === 'checkout' ? (
          <button
            type="submit"
            className="btn btn-primary"
            onClick={handlePayButton}
            disabled={loading}
          >
            {loading ? 'Loading' : 'Continue'}
          </button>
        ) : null}
      </div>
      {/* <CreditCardForm taskId={data.id} onSuccess={() => updateTaskStatus('Review', 'Paid')} /> */}
    </div>
  </ModalBody>
);

export default SelectPaymentMethod;
