import React from 'react';
import { KarbonPageLayout } from 'components/Integrations/integration-modules/karbon/components';
import { KarbonRefactor } from './KarbonRefactor';

/**
 *  Simple wrapper for karbon settings page
 */
export const KarbonLandingView = () => (
  <KarbonPageLayout>
    <KarbonRefactor />
  </KarbonPageLayout>
);
