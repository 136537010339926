import { useGetThreads } from 'hooks/sms-hooks';
import { formatSMSPhone } from 'components/SMS/utils/formatSMSPhone';

type ResponseType = {
  unreadSMSCount: number;
  firstId: string | number;
  firstContactName: string;
  lastUpdatedAt: string;
};

export function useUnReadSMSCount() {
  const { data } = useGetThreads();

  const results = data?.threads?.filter((thread) => thread.hasUnreadMessages);

  const response: ResponseType = {
    unreadSMSCount: results?.length || 0,
    firstId: 0,
    firstContactName: '',
    lastUpdatedAt: '',
  };

  // if there is one thread get the id and contact name
  if (results && results.length === 1) {
    const participantsList = results[0].participants;
    const { firstName, lastName, phoneNumber } = participantsList[0];

    let contactName =
      lastName && lastName.length > 0
        ? `${firstName} ${lastName}`
        : formatSMSPhone(phoneNumber as string);

    if (participantsList.length > 1)
      contactName = contactName.concat(` +${participantsList.length - 1}`);

    response.firstId = results[0].id;
    response.firstContactName = contactName;
  }
  // Always return lastUpdatedAt when available
  if (results && results.length) {
    response.lastUpdatedAt = results[0].lastUpdatedAt;
  }

  return response;
}
