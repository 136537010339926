import React from 'react';
import { Link } from 'react-router-dom-latest';
import TooltipItem from 'common/TooltipItem_updated';
import { sortingIcon } from 'utilities/utils';
import NoRecords from 'common/NoRecords';

const clientAccountPageTableHeaders = [
  {
    value: 'name',
    label: 'Account Name',
    isSortable: true,
    className: ' col-3 thCol',
  },
  {
    value: 'entity_type',
    label: 'Entity Type',
    isSortable: true,
    className: ' col-3 thCol',
  },
  {
    value: 'status',
    label: 'Open Tasks',
    isSortable: false,
    className: ' col-1 thCol',
  },
  {
    value: 'account_owner',
    label: 'Account Owner',
    isSortable: false,
    className: ' col-2 thCol text-center',
  },
  {
    value: 'billing_contact',
    label: 'Billing Contact',
    isSortable: false,
    className: ' col-2 thCol text-center',
  },
  {
    value: '',
    label: '',
    isSortable: false,
    className: ' col-1 thCol',
  },
];

const ClientAccounts = ({
  showDetails,
  listData,
  handleSortBy,
  state: { sortBy, sortOrder },
}) => (
  <div>
    <header>
      <ul className="breadcrumb">
        <li className="breadcrumb-item">All accounts</li>
      </ul>
    </header>
    <div className="center-wrapper">
      {listData === null ? <NoRecords /> : ''}
      {listData !== null && listData.length ? (
        <section>
          <div id="clientList" className="tableWrap">
            <div className="tRow tRow--head">
              <div className="row">
                {clientAccountPageTableHeaders.map((each) => (
                  <div
                    type="button"
                    key={each.value}
                    className={
                      sortingIcon(
                        sortBy,
                        each.value,
                        sortOrder,
                        'className btn btn-link',
                      ) + each.className
                    }
                    onClick={() =>
                      each.isSortable ? handleSortBy(each.value) : null
                    }
                  >
                    <span className="posRel">
                      {each.label}
                      <i
                        className={sortingIcon(sortBy, each.value, sortOrder)}
                      />
                    </span>
                  </div>
                ))}
              </div>
            </div>
            <div className="tRow tRow--body">
              {listData.map((each) => (
                <div className="tdBtn row" key={each.id}>
                  <Link
                    className="col-3 tdCol text-body account-name-wrapper"
                    to={showDetails(each.id)}
                  >
                    <span
                      className={
                        !each.has_invoices_that_need_attention ? 'in' : ''
                      }
                    />
                    <div>{each.name}</div>
                  </Link>
                  <Link
                    className="col-3 tdCol text-body"
                    to={showDetails(each.id)}
                  >
                    <span>
                      {each.entity_type !== null ? each.entity_type : '-'}
                    </span>
                  </Link>
                  <Link
                    className="col-1 tdCol text-body"
                    to={showDetails(each.id)}
                  >
                    <span>-</span>
                  </Link>
                  <Link
                    className="col-2 tdCol text-center text-body"
                    to={showDetails(each.id)}
                  >
                    <span>
                      {each.is_account_owner ? (
                        <i className="icon-tickmark" />
                      ) : null}
                    </span>{' '}
                  </Link>
                  <Link
                    className="col-2 tdCol text-center text-body"
                    to={showDetails(each.id)}
                  >
                    <span>
                      {each.is_billing_contact ? (
                        <i className="icon-tickmark" />
                      ) : null}
                    </span>{' '}
                  </Link>
                  <div className="col-1 tdCol text-right">
                    {each.is_account_owner ? (
                      <TooltipItem
                        position="top"
                        tooltipType="button"
                        key={`edit${each.id}`}
                        text="Edit"
                        btnClass="btn-sm btn--onlyicon btn-link tdBtn__hover"
                        style={{ lineHeight: 0 }}
                        clickAction={() => showDetails(each.id, 'edit')}
                        id={`edit${each.id}`}
                        iconn="icon-edit"
                      />
                    ) : null}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      ) : null}
    </div>
  </div>
);

export default ClientAccounts;
