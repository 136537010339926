import styled from '@emotion/styled';

const IconBase = styled('i')(() => ({
  fontFamily: "'LiscioFonts' !important",
  fontStyle: 'normal',
  fontSize: 'inherit',
  fontWeight: 'normal',
  fontVariant: 'normal',
  textTransform: 'none',
  letterSpacing: 0,

  fontFeatureSettings: 'liga',
  WebkitFontFeatureSettings: 'liga',
  MozFontFeatureSettings: 'liga=1',
  fontVariantLigatures: 'discretionary-ligatures',
  WebkitFontVariantLigatures: 'discretionary-ligatures',
  WebkitFontSmoothing: 'antialiased',
  MozOsxFontSmoothing: 'grayscale',
}));

export default IconBase;
