import React from 'react';
import ContentLoader from 'react-content-loader';
import styled from '@emotion/styled';

const StyledDiv = styled.div`
  width: 100%;
`;

const SidebarLoadingScreen = () => (
  <StyledDiv
    className="SidebarLoadingContainer"
    data-testid="sidebarLoadingContainer"
  >
    <ContentLoader
      height={650}
      width={200}
      viewBox="0 0 200 650"
      backgroundColor="#717493"
      foregroundColor="#A0A2BA"
    >
      <circle cx="40" cy="28" r="15" />
      <rect x="65" y="18" rx="2" ry="2" width="110" height="20" />
      <circle cx="40" cy="108" r="15" />
      <rect x="65" y="98" rx="2" ry="2" width="110" height="20" />
      <circle cx="40" cy="188" r="15" />
      <rect x="65" y="178" rx="2" ry="2" width="110" height="20" />
      <circle cx="40" cy="268" r="15" />
      <rect x="65" y="258" rx="2" ry="2" width="110" height="20" />
      <circle cx="40" cy="348" r="15" />
      <rect x="65" y="338" rx="2" ry="2" width="110" height="20" />
      <circle cx="40" cy="428" r="15" />
      <rect x="65" y="418" rx="2" ry="2" width="110" height="20" />
      <circle cx="40" cy="508" r="15" />
      <rect x="65" y="498" rx="2" ry="2" width="110" height="20" />
      <circle cx="40" cy="588" r="15" />
      <rect x="65" y="578" rx="2" ry="2" width="110" height="20" />
      <circle cx="40" cy="668" r="15" />
      <rect x="65" y="658" rx="2" ry="2" width="110" height="20" />
    </ContentLoader>
  </StyledDiv>
);

export default SidebarLoadingScreen;
