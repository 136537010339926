// const PSPDFKitExportIcon = require('images/pspdfkit-export-icon.png');

const rotateClockwiseSVG = `
  <span
    className="PSPDFKit-Tool-Button-Icon PSPDFKit-Toolbar-Button-Icon PSPDFKit-Icon PSPDFKit-Icon-RotateRight"
    role="presentation"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="currentColor"
      viewBox="0 0 24 24"
      focusable="false"
    >
      <path d="M11.006 2.564a.75.75 0 0 1 .988-1.128l4 3.5a.75.75 0 0 1 0 1.128l-4 3.5a.75.75 0 0 1-.988-1.128l2.558-2.239a6.25 6.25 0 1 0 4.686 6.053.75.75 0 0 1 1.5 0 7.75 7.75 0 1 1-6.397-7.632l-2.347-2.054Z" />
    </svg>
  </span>
`;
export const RotateClockwiseButton = {
  type: 'custom',
  id: 'liscio-rotate-clockwise-button',
  icon: rotateClockwiseSVG,
  title: 'Rotate Clockwise',
  onPress: () => {
    const instance = window?.PSPDFKIT_INSTANCE;
    instance?.applyOperations([
      {
        type: 'rotatePages',
        pageIndexes: [instance?.viewState.currentPageIndex],
        rotateBy: 90,
      },
    ]);
  },
};
