/* eslint-disable no-script-url */
/* eslint-disable react/jsx-no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
// TODO: look into dependency cycle https://liscio.atlassian.net/browse/IN-168
import React from 'react';
// eslint-disable-next-line import/no-cycle
import UploadFilesPopUp from 'components/UploadedFiles/UploadFilesPopUp';
import 'components/Notes/Notes.scss';
import { defaultAllowedUploadFileTypeExtensions } from 'constants/file-extensions';

const Attachment = ({
  setAttachmentNull,
  uploadDocument,
  uploadPopUp,
  name,
  data,
  handleFileDrop,
  uploadFiles,
  documents,
  cancelUpload,
  openAttachment,
  delAttachmentModal,
  showModal,
  component,
  dataProps,
  notedetail,
  from,
  testid,
  component: { openPopUp },
  data: { popUp, taskStatus },
}) => {
  const customClass =
    taskStatus === 'Closed'
      ? 'btn px-1 py-0 btn--onlyicon bg-transparent border-0 disabled'
      : '';

  if (uploadPopUp && !popUp) {
    return (
      <div className="col">
        <span>,&nbsp;</span>
        <label htmlFor="upload_doc" className="mb-0">
          <a
            href="javascript:void(0);"
            onMouseDown={openPopUp}
            className={`fileContainer text-capitalize ${customClass}`}
            data-testid={testid}
          >
            browse
          </a>
        </label>
      </div>
    );
  }

  if (uploadPopUp && popUp) {
    return (
      <UploadFilesPopUp
        name={name}
        data={data}
        attachmentComponent={this}
        setAttachmentNull={() => setAttachmentNull()}
        uploadDocument={() => uploadDocument()}
        uploadPopUp={uploadPopUp}
        handleFileDrop={handleFileDrop}
        uploadFiles={uploadFiles}
        documents={documents}
        cancelUpload={cancelUpload}
        openAttachment={openAttachment}
        delAttachmentModal={delAttachmentModal}
        showModal={showModal}
        component={component}
        dataProps={dataProps}
        notedetail={notedetail}
        from={from}
      />
    );
  }

  const allowedFileExtensions =
    defaultAllowedUploadFileTypeExtensions.join(',');

  return (
    <div className="col">
      <span>,&nbsp;</span>
      <label className="fileContainer text-capitalize mb-0">
        browse
        <input
          type="file"
          id="upload_doc"
          name="aws_url"
          onClick={() => setAttachmentNull()}
          onChange={() => uploadDocument()}
          className="hide"
          data-testid={testid}
          accept={allowedFileExtensions}
          multiple
        />
      </label>
    </div>
  );
};

export default Attachment;
