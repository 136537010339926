// Pending to use this dropdown from common Dropdown component

import { useState, useEffect } from 'react';
import { debounce } from 'lodash';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import {
  Button,
  List,
  ListItem,
  Popover,
  Stack,
  Typography,
} from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';
import { palettePrimary } from 'theme/light';
import IconTextField from 'common/IconTextField';

const SPopover = styled(Popover)(() => ({ marginTop: '5px' }));

const SList = styled(List)(() => ({
  width: '100%',
  maxWidth: 250,
  maxHeight: 200,
}));

const SListItem = styled(ListItem)(() => ({
  cursor: 'pointer',
}));

const SButton = styled(Button)(() => ({
  height: 40,
  fontWeight: 600,
  fontSize: '0.87rem',
}));

const SIconTextField = styled(IconTextField)(() => ({
  width: 170,
  border: 0,
  padding: '7px 5px',
}));

function DropDown(props) {
  const {
    withSearch = true,
    title,
    options = [],
    value,
    onSelectionChange,
  } = props;
  const [internalValue, setInternalValue] = useState(value);
  const [searchText, setSearchText] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [hoverId, setHoverId] = useState(undefined);
  const open = Boolean(anchorEl);
  useEffect(() => {
    if (JSON.stringify(value) !== JSON.stringify(internalValue)) {
      setInternalValue(value);
    }
  }, [value]);
  const filteredOptions = options.filter((option) => {
    const lowCasedHeadline = option.label.toLowerCase();
    const lowCasedSearchText = searchText.toLowerCase();
    return lowCasedHeadline.includes(lowCasedSearchText);
  });
  const handleButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setSearchText('');
    setAnchorEl(null);
  };
  const handleOptionClick = (event, option) => {
    setSearchText('');
    setAnchorEl(null);
    setInternalValue(option);
    if (onSelectionChange) onSelectionChange(option);
  };

  const popoverId = open ? 'dropdown-popover' : undefined;

  return (
    <div>
      <SButton
        id="title-button"
        aria-controls={open ? 'lock-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant={
          open ||
          (internalValue &&
            options.some(
              (option) =>
                option.id === internalValue.id ||
                option.value === internalValue.value,
            ))
            ? 'contained'
            : 'outlined'
        }
        onClick={handleButtonClick}
      >
        {title}
      </SButton>
      <SPopover
        id={popoverId}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transitionDuration={50}
        elevation={0}
        open={open}
        PaperProps={{ sx: { border: `2px solid ${palettePrimary.gray}` } }}
        onClose={handleClose}
      >
        <SList dense disablePadding>
          {withSearch && (
            <SIconTextField
              type="search"
              icon={<SearchIcon />}
              fontSize="0.95rem"
              value={searchText}
              isOpen={open}
              onChange={debounce((e) => {
                setSearchText(e.target.value);
              }, 300)}
            />
          )}
          {filteredOptions.length > 0 ? (
            filteredOptions.map((option, index) => (
              <SListItem
                key={option.id}
                onMouseEnter={() => setHoverId(index)}
                onMouseLeave={() => setHoverId(undefined)}
                onClick={(event) => handleOptionClick(event, option)}
              >
                <Stack direction="column">
                  <Typography
                    variant="gray_body2"
                    sx={{
                      color:
                        hoverId === index
                          ? palettePrimary.textColor
                          : palettePrimary.gray,
                      transition: 'all ease-in-out 200ms',
                    }}
                  >
                    {option.label}
                  </Typography>
                </Stack>
              </SListItem>
            ))
          ) : (
            <ListItem>
              <Typography variant="gray_body2">Not Found</Typography>
            </ListItem>
          )}
        </SList>
      </SPopover>
    </div>
  );
}
const OptionPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  headline: PropTypes.string,
  subHeadline: PropTypes.string,
});
DropDown.propTypes = {
  withSearch: PropTypes.bool,
  title: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(OptionPropType),
  value: OptionPropType,
  onSelectionChange: PropTypes.func,
};
export default DropDown;
