import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom-latest';
import styled from '@emotion/styled';
import EmailActionsDropdown from './EmailActionsDropdown';
import LinkedAccounts from './LinkedAccounts';

const EmRight = styled.div`
  .EmRight__Bio {
    padding: 0 0px 15px;
    &:first-child {
      padding-top: 15px;
    }
  }
`;

const EmailLinedWith = ({
  refreshPage,
  data,
  emailId,
  accountEmailLinkedBox,
  contactEmailLinkedBox,
  emailLinkedBox,
  contactAccountLinked,
  accountContactLinked,
}) => {
  const [emailLinkedWith, setEmailLinkedWith] = useState(data || []);
  const [enbleAccountBox, setEnableAccountBox] = useState(
    accountEmailLinkedBox || false,
  );
  const [enbleContactBox, setEnableContactBox] = useState(
    contactEmailLinkedBox || false,
  );
  useEffect(() => {
    setEnableAccountBox(accountEmailLinkedBox);
    setEnableContactBox(contactEmailLinkedBox);
  }, [accountEmailLinkedBox, contactEmailLinkedBox]);

  useEffect(() => {
    setEmailLinkedWith(data);
  }, [data]);
  return (
    <EmRight>
      {emailLinkedWith?.tasks?.length > 0 &&
        emailLinkedWith.tasks.map((task) => (
          <div className="EmRight__Bio" key={task.id}>
            <LinkedWith
              id={task.id}
              linkedIcon="icon-Tasks"
              linkedType="task"
              title={task.subject}
              refreshPage={refreshPage}
              date={task.created_at}
              to={`/task/detail/${task.id}`}
              liscio_email_thread_link_id={task.liscio_email_thread_link_id}
            />
          </div>
        ))}
      {emailLinkedWith?.messages?.length > 0 &&
        emailLinkedWith.messages.map((message) => (
          <div className="EmRight__Bio" key={message.id}>
            <LinkedWith
              id={message.id}
              linkedIcon="icon-message"
              linkedType="message"
              title={message.subject}
              refreshPage={refreshPage}
              date={message.created_at}
              to={`/sent/${message.id}`}
              liscio_email_thread_link_id={message.liscio_email_thread_link_id}
            />
          </div>
        ))}
      {emailLinkedWith?.accounts?.length > 0 || enbleAccountBox ? (
        <div className="EmRight__Bio">
          <LinkedAccounts
            initialValue={
              emailLinkedWith?.accounts?.length > 0
                ? emailLinkedWith.accounts
                : []
            }
            emailId={emailId}
            entityType="account"
            refreshPage={refreshPage}
            enableMode={enbleAccountBox ? 'edit' : 'view'}
            emailLinkedBox={emailLinkedBox}
            accountContactLinked={accountContactLinked}
          />
        </div>
      ) : null}
      {emailLinkedWith?.contacts?.length > 0 || enbleContactBox ? (
        <div className="EmRight__Bio">
          <LinkedAccounts
            initialValue={
              emailLinkedWith?.contacts?.length > 0
                ? emailLinkedWith.contacts
                : []
            }
            emailId={emailId}
            entityType="contact"
            refreshPage={refreshPage}
            enableMode={enbleContactBox ? 'edit' : 'view'}
            emailLinkedBox={emailLinkedBox}
            contactAccountLinked={contactAccountLinked}
          />
        </div>
      ) : null}
    </EmRight>
  );
};

const LinkedWith = ({
  id,
  linkedIcon,
  linkedType,
  refreshPage,
  title,
  // date,
  to,
  liscio_email_thread_link_id,
}) => (
  <div className="em_right_block py-3 px-4" key={id}>
    <div className="d-flex">
      <div className="messageDetails flex-grow-1">
        <div className="row w-100 no-gutters">
          <div className="col-sm-9">
            <div className="messageBlock">
              <div className="d-block">
                <span className="label-submedium text-body mb-1">
                  <span className="text-uppercase font-weight-bold">
                    <i
                      className={`${linkedIcon} mr-1 vertical-middle text-dark`}
                    />
                    {linkedType}
                  </span>
                  <i className="divOption divOption--dark" />
                  <Link to={to}>{title}</Link>
                </span>
              </div>
            </div>
          </div>
          <div className="col-sm-3 pr-0 text-right pt-0">
            <div className="text-right">
              <span className="pl-2 text-primary">
                <EmailActionsDropdown
                  IconClass="text-primary"
                  emailDetails={{ liscio_email_thread_link_id }}
                  linkedType={linkedType}
                  refreshPage={refreshPage}
                  viewContextFrom="linkedwith"
                />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default EmailLinedWith;
