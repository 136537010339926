import React from 'react';
import styled from '@emotion/styled';
import { ReactComponent as IconMenu } from 'images/icons/add-circle.svg';
import { extractContactFromEmailString } from 'components/Email/EmailUtilities';

const StyledIcons = styled.span`
  display: inline-block;
  margin-left: 4px;
`;

const AddContactAccount = ({ handleContactAccount, actionObj, labelFor }) => {
  const handleClick = (e) => {
    e.stopPropagation();

    const contact = extractContactFromEmailString(actionObj.email);

    handleContactAccount(contact.email, labelFor);
  };
  return (
    <StyledIcons className="button-icon" onClick={handleClick}>
      <IconMenu width="1rem" />
    </StyledIcons>
  );
};

export default AddContactAccount;
