/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import { Label, Input } from 'reactstrap';
import moment from 'moment-timezone';
import TooltipItem from 'common/TooltipItem_updated';
import * as Utilities from 'utilities/utils';
import ContactCard from 'common/ContactCard';
import AccountCard from 'common/AccountCard';
import RecurringTaskCard from 'common/RecurringTaskCard';

const bulkstyles = [
  ' col-3',
  ' col-2',
  ' col-2',
  ' col-2',
  ' col-2',
  ' text-right col-auto flex-grow-1',
];
const bulkstyleswithcheckbox = [
  ' col-2',
  ' col-2',
  ' col-2',
  ' col-2',
  ' col-auto flex-grow-1',
  ' text-right col-2',
];
const list = [
  ' col-2',
  ' col-2',
  ' col-2',
  ' col-2',
  ' col-1',
  ' text-right col-auto flex-grow-1',
];
const timeZone =
  Intl.DateTimeFormat().resolvedOptions().timeZone || moment.tz.guess(true);
class List extends Component {
  constructor(props) {
    super(props);
    this.showDetail = this.showDetail.bind(this);
    this.state = {
      contactCardID: null,
      accountCardID: null,
      recurringText: '',
      isEmployee: Utilities.isUserEmployee(),
    };
  }

  showDetail(item, key) {
    const { match, name, showCheckbox } = this.props;
    if (name && name === 'bulk' && showCheckbox) {
      this.props.handleCheckbox(key);
    } else if (name && name === 'bulk') {
      // do nothing
    } else {
      this.props.navigate(`/task/detail/${item.id}`, {
        state: { from: match.url },
      });
    }
  }

  contactCardHandler(input) {
    if (this.state.isEmployee) {
      this.setState(
        {
          contactCardID: null,
        },
        () => {
          if (input && input.task_type_key !== 'to_do') {
            const { contact_id } = input;
            this.setState({
              contactCardID: contact_id,
            });
          }
        },
      );
    }
  }

  accountCardHandler(input) {
    if (this.state.isEmployee) {
      this.setState(
        {
          accountCardID: null,
        },
        () => {
          if (input && input.account_id) {
            const { account_id } = input;
            this.setState({
              accountCardID: account_id,
            });
          }
        },
      );
    }
  }

  handleReccurTaskInfo(input) {
    // console.log(`Handel Recurring Info`, input)
    this.setState({
      recurringText: input.recurring_text,
    });
  }

  render() {
    const { totalRecords, name, selectedAll, showCheckbox, pagination, data } =
      this.props;
    const { contactCardID, isEmployee, accountCardID } = this.state;
    const styles =
      name && name === 'bulk' && showCheckbox
        ? bulkstyleswithcheckbox
        : name && name === 'bulk'
        ? bulkstyles
        : list;
    const tasks = this.props.tasks.map((item, key) => {
      const dueDateColor =
        item.task_type_key === 'virtual_meeting' && item.raw_due_date
          ? Utilities.dueDateColor(item.raw_due_date, item.status)
          : Utilities.dueDateColor(item.full_due_date, item.status);
      const color = this.props.pref.map((ele) => {
        if (item.task_type_key === ele.key) {
          return ele.label;
        }
        return null;
      });
      let unread = item.comment_count;
      if (name && name === 'bulk') {
        unread = 0;
      }
      const badge = color.find((el) => el !== null);
      let time = item.updated_at;
      if (item.updated_at.indexOf('ago') === -1) {
        time = moment.tz(time, timeZone).format('MM/DD/YY hh:mm a');
      }

      return (
        <div className="row" key={Utilities.generateUniqueKey(key)}>
          {name && name === 'bulk' ? null : (
            <div className="col tdCol pl-2 pr-2 flex-grow-0">
              <span
                className={
                  unread > 0
                    ? 'rounded status visible'
                    : 'rounded status invisible'
                }
                data-testid="status-badge"
              />
            </div>
          )}
          {name && name === 'bulk' && showCheckbox ? (
            <div className="col tdCol pl-2 pr-2">
              <div className="posRel checkbox checkbox-primary check-container mx-2 pl-2">
                <Label htmlFor={item.id}>
                  <Input
                    key={item.id}
                    type="checkbox"
                    id={item.id}
                    checked={item.checked ? item.checked : false}
                    className={`chkbx ${item.id}`}
                    name="isChecked"
                    onChange={() => this.props.handleCheckbox(key)}
                  />
                  <i className="checkmark" />
                </Label>
              </div>
            </div>
          ) : name && name === 'bulk' ? null : (
            <div className="col tdCol pl-2 pr-2">
              {isEmployee &&
              name &&
              name === 'tasklist' &&
              data.taskStatus !== 'Archive' ? (
                <span
                  className="posRel checkbox checkbox-primary check-container mx-2 pl-2"
                  style={{ top: '2px' }}
                >
                  <Label htmlFor={`chck ${item.id}`}>
                    <Input
                      name="msg1"
                      id={`chck ${item.id}`}
                      key={`chck ${item.id}`}
                      checked={item.checked ? item.checked : false}
                      type="checkbox"
                      onChange={() => this.props.checkboxAction(item, key)}
                    />
                    <i className="checkmark" />
                  </Label>
                </span>
              ) : (
                <i className="iconCircle iconCircle--green icon-tickmark_trans_o" />
              )}
            </div>
          )}
          <div
            className={`${styles[0]} tdCol`}
            style={{ transition: 'none' }}
            onClick={() => this.showDetail(item, key)}
          >
            <span
              className={`badge ${
                badge !== undefined ? badge : 'badge-onboard'
              }`}
            >
              {item.task_type_value}
            </span>
            <br />
            {unread > 0 ? (
                <strong data-testid="subject-bold">{item?.subject}</strong>
            ) : (
                <span data-testid="subject">{item?.subject}</span>
            )}
            <br />
            <span className="badge badge-comment">
              {unread > 0 ? (
                  <span data-testid="comment-count">
                  <i className="icon-callout-small vertical-middle" />
                  {` ${item.comment_count}${
                    item.comment_count === 1 ? ' New Comment' : ' New Comments'
                  }`}
                </span>
              ) : (
                ' '
              )}
            </span>
          </div>
          <div
            className={`${styles[1]} tdCol`}
            data-testid="contact-name"
            onClick={() => this.showDetail(item, key)}
          >
            <a
              data-tip
              data-for={`${item.contact_id}global`}
              onMouseEnter={() => this.contactCardHandler(item)}
            >
              {unread > 0 ? (
                <strong>{item.name}</strong>
              ) : (
                <span>{item.name}</span>
              )}
            </a>
          </div>
          <div
            className={`${styles[2]} tdCol`}
            data-testid="account-name"
            onClick={() => this.showDetail(item, key)}
          >
            <a
              data-tip
              data-for={`${item.account_id}global_account`}
              onMouseEnter={() => this.accountCardHandler(item)}
            >
              {unread > 0 ? (
                <strong>
                  {item.task_type_key === 'to_do'
                    ? item.todo_account_name
                      ? item.todo_account_name
                      : '-'
                    : item.account_name
                    ? item.account_name
                    : '-'}
                </strong>
              ) : (
                <span>
                  {item.task_type_key === 'to_do'
                    ? item.todo_account_name
                      ? item.todo_account_name
                      : '-'
                    : item.account_name
                    ? item.account_name
                    : '-'}
                </span>
              )}
            </a>
          </div>
          <div
            className={`${styles[3]} tdCol`}
            onClick={() => this.showDetail(item, key)}
            data-testid="owner-name"
          >
            {unread > 0 ? (
              <strong>{item.owner}</strong>
            ) : (
              <span>{item.owner}</span>
            )}
            <br />
          </div>
          <div
            style={{ transition: 'none' }}
            onClick={() => this.showDetail(item, key)}
            className={`${styles[4]} tdCol${dueDateColor}`}
            data-testid="due-date"
          >
            {unread > 0 ? (
              <strong>
                {item.task_type_key === 'virtual_meeting' && item.raw_due_date
                  ? moment(item.raw_due_date).format('MM/DD/YY')
                  : item.due_date}
              </strong>
            ) : (
              <span
                data-tip
                data-for="recurringTask"
                onMouseEnter={() => this.handleReccurTaskInfo(item)}
                className="posRel"
              >
                {item.task_type_key === 'virtual_meeting' && item.raw_due_date
                  ? moment(item.raw_due_date).format('MM/DD/YY')
                  : item.due_date}
                {item.is_recurring ? (
                  <a className="icnRcur icon-Recurring vertical-middle text-primary px-1 py-0" />
                ) : null}
              </span>
            )}
          </div>
          {name && name === 'bulk' && !showCheckbox ? (
            <div
              className={`${styles[5]} tdCol`}
              style={{ transition: 'none' }}
              data-testid={`delete-button-${Utilities?.generateUniqueKey(key)}`}
            >
              <TooltipItem
                position="top"
                tooltipType="button"
                id={`delete${Utilities.generateUniqueKey(key)}`}
                text="Delete"
                btnClass="btn-sm btn--onlyicon btn-link"
                clickAction={() => this.props.handleDelete(key, item)}
                iconn="icon-delete-small"
              />
            </div>
          ) : (
            <div
              className="col-2 tdCol text-right"
              onClick={() => this.showDetail(item, key)}
              style={{ transition: 'none' }}
              data-testid="time-element"
            >
              {unread > 0 ? <strong>{time}</strong> : <span>{time}</span>}
            </div>
          )}
        </div>
      );
    });
    return (
      <div>
        <div className="tableWrap">
          <div className="tRow tRow--head">
            <div className="row">
              {name && name === 'bulk' ? null : (
                <div className="col thCol  pl-2 pr-2 flex-grow-0">
                  <span className="rounded status invisible px-0 py-2" />
                </div>
              )}
              {(name && name === 'bulk' && showCheckbox) ||
              (isEmployee &&
                name &&
                name === 'tasklist' &&
                data.taskStatus !== 'Archive') ? (
                <div className="col tdCol pl-2 pr-2">
                  <span className="checkbox checkbox-primary check-container  mx-2 pl-2">
                    <Label htmlFor="selectallcheckbox">
                      <Input
                        type="checkbox"
                        name="selectallcheckbox"
                        id="selectallcheckbox"
                        checked={selectedAll}
                        key={name === 'tasklist' ? pagination : ''}
                        onChange={() => this.props.handleSelectAll()}
                        data-testid="selectallcheckbox"
                      />
                      <i className="checkmark" />
                    </Label>
                  </span>
                </div>
              ) : name && name === 'bulk' ? null : (
                <div className="col thCol" />
              )}
              <div
                className={
                  totalRecords !== 0
                    ? `${
                        Utilities.sortingIcon(
                          this.props.data.field,
                          'subject',
                          this.props.data.sort,
                          'className',
                        ) + styles[0]
                      } thCol`
                    : `${styles[0]} thCol`
                }
                onClick={
                  totalRecords !== 0
                    ? () => this.props.handleSorting('subject')
                    : null
                }
                data-testid="task-name-column"
                style={{ transition: 'none' }}
              >
                <span className="posRel">
                  Task Name{' '}
                  <i
                    className={
                      totalRecords !== 0
                        ? Utilities.sortingIcon(
                            this.props.data.field,
                            'subject',
                            this.props.data.sort,
                          )
                        : null
                    }
                  />
                </span>
              </div>
              <div
                className={
                  totalRecords !== 0
                    ? `${
                        Utilities.sortingIcon(
                          this.props.data.field,
                          'assignee',
                          this.props.data.sort,
                          'className',
                        ) + styles[1]
                      } thCol`
                    : `${styles[1]} thCol`
                }
                onClick={
                  totalRecords !== 0
                    ? () => this.props.handleSorting('assignee')
                    : null
                }
                data-testid="assigned-to-column"
              >
                <span className="posRel">
                  Assigned To{' '}
                  <i
                    className={
                      totalRecords !== 0
                        ? Utilities.sortingIcon(
                            this.props.data.field,
                            'assignee',
                            this.props.data.sort,
                          )
                        : null
                    }
                  />
                </span>
              </div>
              <div
                className={
                  totalRecords !== 0
                    ? `${
                        Utilities.sortingIcon(
                          this.props.data.field,
                          'name',
                          this.props.data.sort,
                          'className',
                        ) + styles[2]
                      } thCol`
                    : `${styles[2]} thCol`
                }
                onClick={
                  totalRecords !== 0
                    ? () => this.props.handleSorting('name')
                    : null
                }
                data-testid="account-column"
              >
                <span className="posRel">
                  Account{' '}
                  <i
                    className={
                      totalRecords !== 0
                        ? Utilities.sortingIcon(
                            this.props.data.field,
                            'name',
                            this.props.data.sort,
                          )
                        : null
                    }
                  />
                </span>
              </div>
              <div
                className={
                  totalRecords !== 0
                    ? `${
                        Utilities.sortingIcon(
                          this.props.data.field,
                          'owner',
                          this.props.data.sort,
                          'className',
                        ) + styles[3]
                      } thCol`
                    : `${styles[3]} thCol`
                }
                onClick={
                  totalRecords !== 0
                    ? () => this.props.handleSorting('owner')
                    : null
                }
                data-testid="owner-column"
              >
                <span className="posRel">
                  Owner{' '}
                  <i
                    className={
                      totalRecords !== 0
                        ? Utilities.sortingIcon(
                            this.props.data.field,
                            'owner',
                            this.props.data.sort,
                          )
                        : null
                    }
                  />
                </span>
              </div>
              <div
                className={
                  totalRecords !== 0
                    ? `${
                        Utilities.sortingIcon(
                          this.props.data.field,
                          'due_by',
                          this.props.data.sort,
                          'className',
                        ) + styles[4]
                      } thCol`
                    : `${styles[4]} thCol`
                }
                onClick={
                  totalRecords !== 0
                    ? () => this.props.handleSorting('due_by')
                    : null
                }
                data-testid="due-date-column"
                style={{ transition: 'none' }}
              >
                <span className="posRel">
                  Due Date{' '}
                  <i
                    className={
                      totalRecords !== 0
                        ? Utilities.sortingIcon(
                            this.props.data.field,
                            'due_by',
                            this.props.data.sort,
                          )
                        : null
                    }
                  />
                </span>
              </div>
              {name && name === 'bulk' && !showCheckbox ? (
                <div
                  className={`${styles[5]} thCol`}
                  style={{ transition: 'none' }}
                />
              ) : (
                <div
                  className={
                    totalRecords !== 0
                      ? `${Utilities.sortingIcon(
                          this.props.data.field,
                          'updated_at',
                          this.props.data.sort,
                          'className',
                        )} text-right col-2 thCol`
                      : 'text-right col-2 thCol'
                  }
                  onClick={
                    totalRecords !== 0
                      ? () => this.props.handleSorting('updated_at')
                      : null
                  }
                  data-testid="last-activity-column"
                  style={{ transition: 'none' }}
                >
                  <span className="posRel">
                    Last Activity AT
                    <i
                      className={
                        totalRecords !== 0
                          ? Utilities.sortingIcon(
                              this.props.data.field,
                              'updated_at',
                              this.props.data.sort,
                            )
                          : null
                      }
                    />
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className="tRow tRow--body">
            {totalRecords === 0 ? (
              <div className="row emptyTr">
                <div className="emptyBox">
                  <div className="onlyText">
                    <p>No Records Found</p>
                  </div>
                </div>
              </div>
            ) : (
              tasks
            )}
          </div>
          {contactCardID && contactCardID !== null ? (
            <ContactCard id={contactCardID} />
          ) : null}
          {accountCardID && accountCardID !== null ? (
            <AccountCard id={accountCardID} />
          ) : null}
          {this.state.recurringText &&
          this.state.recurringText !== null &&
          this.setState.recurringText !== '' ? (
            <RecurringTaskCard
              id="recurringTask"
              recurringText={this.state.recurringText}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

export default List;
