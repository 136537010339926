import axios from 'axios';
import { TWO_FACTOR_BACKUP, TWO_FACTOR } from 'constants/constants';
import { AlertMessage } from 'utilities/utils';

class TwoFactorAuthentication {
  static twoFactor(component) {
    const request = axios.get(TWO_FACTOR);
    component.setState({ loading: true });
    request
      .then((response) => {
        if (response.status === 200) {
          const { data } = response;
          component.setState({
            loading: false,
            mfaManageNext: true,
            barCodeSecretKey: data.key,
            qrCode: data.qr,
          });
        }
      })
      .catch(() => {
        component.setState({ loading: false });
        AlertMessage('error', 'Some error occurred. Please try later.');
      });
  }

  static twoFactorAuthentication(component) {
    const mydata = { user: { otp_attempt: component.state.verificationCode } };
    const request = axios.post(TWO_FACTOR, mydata);
    component.setState({ loading: true });
    request
      .then((response) => {
        if (response.status === 200) {
          const { data } = response;
          if (data.status === 201) {
            const err = {};
            err.verificationCode = data.message;
            component.setState({ loading: false, errors: err });
          } else {
            component.setState({
              loading: false,
              mfaNextVerify: false,
              mfaVerificationDone: true,
              mfaEnabled: true,
            });
            AlertMessage('success', data.message, 3000);
          }
        }
      })
      .catch(() => {
        component.setState({ loading: false });
        AlertMessage('error', 'Some error occurred. Please try later.');
      });
  }

  static disableTwoFactorAuthentication(component) {
    const request = axios.delete(TWO_FACTOR);
    component.setState({ loading: true });
    request
      .then((response) => {
        if (response.status === 200) {
          component.setState({
            loading: false,
            mfaVerificationDone: false,
            mfaEnabled: false,
          });
          AlertMessage('success', 'Multi-Factor Authentication disabled', 3000);
        }
      })
      .catch(() => {
        component.setState({ loading: false });
        AlertMessage('error', 'Some error occurred. Please try later.');
      });
  }

  static generateTwoFactorRecoveryCodes(component, value) {
    const request = axios.get(TWO_FACTOR_BACKUP);
    component.setState({ loading: true });
    request
      .then((response) => {
        if (response.status === 200) {
          const { data } = response;
          const recoveycode = [];
          data.backup_code.map((item) => {
            const code = {};
            code.id = item;
            code.value = item;
            code.label = item;
            return recoveycode.push(code);
          });
          component.setState({
            loading: false,
            mfaVerificationDone: !value,
            generateRecoveryCodes: value,
            recoveryCodes: recoveycode,
          });
        }
      })
      .catch(() => {
        component.setState({ loading: false });
        AlertMessage('error', 'Some error occurred. Please try later.');
      });
  }
}
export default TwoFactorAuthentication;
