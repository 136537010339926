import { WorkflowItemType2, WorkflowResponseItem } from 'gql/graphql';

// Only works for conditionType 'equals' until more condition types are added
export const matchValues = (
  conditionType: any,
  matchValue: any,
  currentValue: any,
) => {
  if (conditionType === 'equals') {
    if (matchValue === 'true') {
      return currentValue === true;
    }

    if (matchValue === 'false') {
      return currentValue === false;
    }
  }
  return false;
};

export const getCurrentParentValue = (
  parentResponseItem: WorkflowResponseItem,
) =>
  parentResponseItem.type === 'WfriAnswerBoolean'
    ? parentResponseItem.booleanValue
    : parentResponseItem?.textValue;

export const showConditionalQuestion = (
  conditionType: string,
  matchValue: string | null | undefined,
  parentItemCondition: WorkflowItemType2,
  parentResponseItem: WorkflowResponseItem,
) => {
  const showQuestion = matchValues(
    conditionType,
    matchValue,
    getCurrentParentValue(parentResponseItem),
  );
  return showQuestion;
};
