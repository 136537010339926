export const fetchNotification = (data) => ({
  type: 'FETCH_NOTIFICATION',
  data,
});

export const notificationCount = (data) => ({
  type: 'NOTIFICATION_COUNT',
  data,
});

export const notificationFilter = (data) => ({
  type: 'NOTIFICATION_FILTER',
  data,
});

export const notificationSort = (data) => ({
  type: 'NOTIFICATION_SORT',
  data,
});

export const isMultipleCpa = (data) => ({
  type: 'IS_MULTIPLE_CPA',
  data,
});
export const multipleCpaData = (data) => ({
  type: 'MULTIPLE_CPA_DATA',
  data,
});

// TODO: create a outofoffice action file and moves the action to that file.
export const isAway = (data) => ({
  type: 'IS_AWAY',
  data,
});

export const updateAwaySetting = (data) => ({
  type: 'UPDATE_AWAY_SETTING',
  data,
});

export const awayStartDate = (data) => ({
  type: 'AWAY_START_DATE',
  data,
});

export const awayEndDate = (data) => ({
  type: 'AWAY_END_DATE',
  data,
});

export const multipleCpaDataModified = (index, count) => ({
  type: 'MULTIPLE_CPA_DATA_MODIFIED',
  index,
  count,
});
