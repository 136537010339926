import React from 'react';
import styled from '@emotion/styled';
import {
  BUTTON_ICON_LOCATION_FRONT,
  BUTTON_ICON_LOCATION_BACK,
} from 'constants/constants';

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: 2px solid transparent;
  color: #212b36;
  border-radius: 2px;
  transition: all ease-in-out 350ms;
  :disabled,
  [disabled] {
    color: #c0c1c2;
  }
  :active,
  :focus {
    border: 2px solid #f0f0f0;
    outline: 2px solid #f0f0f0;
  }
`;

const GhostButton = ({
  label,
  onClick,
  icon,
  disabled = false,
  size,
  iconLocation,
}) => (
  <StyledButton
    type="button"
    id={label}
    onClick={onClick}
    disabled={disabled}
    size={size}
  >
    {icon && iconLocation === BUTTON_ICON_LOCATION_FRONT && (
      <i className={`icon icon-${icon}`} />
    )}
    {label}
    {icon && iconLocation === BUTTON_ICON_LOCATION_BACK && (
      <i className={`icon icon-${icon}`} />
    )}
  </StyledButton>
);

export default GhostButton;
