/* eslint-disable*/
import React, { Component } from 'react';
// import UltimatePagination from 'pagination.js';

export default class Pagination extends Component {
  constructor(props) {
    super(props);
    this.state = { currentPage: this.props.currentPage === undefined || this.props.currentPage === null || this.props.currentPage === 0 ? 1 : this.props.currentPage };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentPage !== this.props.currentPage) {
      this.updateCurrentValue();
    }
  }

  updateCurrentValue = () => {
    this.setState({ currentPage: this.props.currentPage });
  };

  handleChange = (input) => {
    if (input && input.target && input.target.value !== undefined) {
      let { value } = input.target;
      // console.log(value, typeof input.target.value);
      if (value.length) {
        value = parseInt(value);
        if (value < 0) {
          value = 1;
        }
        value = value.toFixed(0);
      }
      this.setState({ currentPage: value > this.props.totalPages ? this.props.totalPages : value });
    }
  };

  handleKeyPress = (event) => {
    let { currentPage } = this.state;
    if (currentPage && currentPage !== null && currentPage.length && typeof currentPage === 'string') {
      currentPage = parseInt(currentPage);
    }
    if (event.key === 'Enter') {
      if (currentPage === undefined || currentPage === null || currentPage.length === 0 || currentPage <= 0) {
        this.props.handlePageChange(1);
        this.setState({ currentPage: 1 });
      } else {
        this.props.handlePageChange(currentPage);
      }
    }
  };

  render() {
    const {
      offset,
      off,
      totalRecords,
      totalPages,
      handlePageChange,
      pageName,
    } = this.props;
    let { currentPage } = this.state;
    if (currentPage && currentPage !== null) {
      currentPage = parseInt(currentPage);
    }
    if (totalPages === undefined || totalPages === null || totalPages === 0) {
      return null;
    }
    return (
      <div className="col-container d-flex flex-wrap Pagination">
        {this.props.fromPage && this.props.fromPage === 'emailsList' ? null
          : (
            <div className="text-dark col">
              Showing
              {' '}
              {offset}
              {' '}
              to
              {' '}
              {off > totalRecords ? totalRecords : off }
              {' '}
              of
              {' '}
              {totalRecords}
              {' '}
              entries
            </div>
          )}
        <div className="col">
          <nav aria-label="Page navigation example" className="Pagi-Responsive pull-right">
            {/* <UltimatePagination
              currentPage={currentPage > totalPages ? 1 : currentPage}
              totalPages={totalPages}
              onChange={handlePageChange}
            /> */}
            <ul className="pagination">
              <li className="page-item">
                <button
                  className="page-link"
                  disabled={currentPage === 1}
                  onClick={() => handlePageChange(1)}
                  data-testid={`${pageName}__first_page_btn`}

                >
                  {this.props.fromPage && this.props.fromPage === 'emailsList'
                    ? (
                      <span aria-hidden="true" style={{ whiteSpace: 'nowrap' }}>
                        <span className="icon-left-arrow2" />
                        <span className="icon-left-arrow2" />
                      </span>
                    )
                    : 'First'}
                </button>
              </li>
              <li className="page-item">
                <button
                  className="page-link"
                  disabled={currentPage === 1}
                  onClick={() => handlePageChange(currentPage - 1)}
                  data-testid={`${pageName}__page_left_arrow`}
                >
                  <span aria-hidden="true">
                    <span className="icon-left-arrow2" />
                  </span>
                </button>
              </li>
              <li className="d-flex align-items-center pr-2 pl-2">
                <span className="text-dark">Page</span>
              </li>
              <li className="page-item" style={this.props.fromPage && this.props.fromPage === 'emailsList' ? { maxWidth: '40px', textAlign: 'center' } : { maxWidth: '60px' }}>
                <input
                  type="number"
                  className="form-control"
                  value={currentPage}
                  onChange={this.handleChange}
                  onKeyPress={this.handleKeyPress}
                  min="1"
                  data-testid={`${pageName}__page_input`}
                />
              </li>
              <li className="d-flex align-items-center pr-2 pl-2">
                <span className="text-dark">
                  {' '}
                  of
                  {' '}
                  {totalPages}
                </span>
              </li>
              <li className="page-item">
                <button
                  className="page-link"
                  disabled={currentPage === totalPages}
                  onClick={() => totalPages !== currentPage && handlePageChange(currentPage + 1)}
                  data-testid={`${pageName}__page_right_arrow`}
                >
                  <span aria-hidden="true">
                    <span className="icon-right-arrow2" />
                  </span>
                </button>
              </li>
              <li className="page-item">
                <button
                  className="page-link"
                  disabled={currentPage === totalPages}
                  onClick={() => handlePageChange(totalPages)}
                  data-testid={`${pageName}__last_page_btn`}
                >
                  {this.props.fromPage && this.props.fromPage === 'emailsList'
                    ? (
                      <span aria-hidden="true" style={{ whiteSpace: 'nowrap' }}>
                        <span className="icon-right-arrow2" />
                        <span className="icon-right-arrow2" />
                      </span>
                    )
                    : 'Last'}
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    );
  }
}
