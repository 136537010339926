import React from 'react';

import { Box, Typography } from '@mui/material';

export interface RequestItemWrapperProps {
  children: React.ReactNode;
  number: string | number;
  isConditional?: boolean;
  visible?: boolean;
}

export const RequestItemWrapper = ({
  children,
  number,
  isConditional = false,
  visible = true,
}: RequestItemWrapperProps) => {
  const conditionalSX = {
    backgroundColor: '#f3f3f3', // TODO: add to common colors if Product approves design
    borderRadius: '5px',
    marginLeft: '60px',
    marginBottom: '10px',
  };

  return (
    <Box
      role="listitem"
      display={visible ? 'flex' : 'none'}
      padding="10px"
      sx={isConditional ? conditionalSX : {}}
      data-print-id="workflowItem"
    >
      <Box
        width={isConditional ? '60px' : '52px'}
        display="flex"
        justifyContent="start"
        sx={{ paddingRight: '8px' }}
      >
        <Typography variant="h4" fontWeight="bolder">
          {number}
        </Typography>
      </Box>
      {children}
    </Box>
  );
};

export default RequestItemWrapper;
