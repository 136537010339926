import React from 'react';

const PrepareDocView = (props) => (
  <div id="content">
    <header>
      <h1 className="pageHeading">
        {props.pageTitle ? props.pageTitle : 'Prepare Doc For Signing'}
      </h1>
    </header>
    <div className="center-wrapper col-12 m-auto">
      <iframe
        src={props.authoringUrl}
        title="Authoring Page"
        style={{ height: '85vh', width: '100%' }}
      />
    </div>
  </div>
);

export default PrepareDocView;
