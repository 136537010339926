import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import axios from 'axios';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom-latest';
import { ToastContainer } from 'react-toastify';
import Modal from 'react-modal';

import CssBaseline from '@mui/material/CssBaseline';
import ThemeProvider from '@mui/material/styles/ThemeProvider';

import {
  ACT_AS_KEY,
  LOGOUT,
  REACT_ENVIRONMENT,
  SENTRY_DSN,
} from 'constants/constants';
// import App from './components/App';
import App from 'components/App_updated';
import configureStore from 'store/store';
// import { store as newStore, storePersistor } from 'store1';
import { setCookie, AlertMessage, systemData } from 'utilities/utils';

import LightTheme from 'theme/light';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import * as Sentry from '@sentry/react';
import { MobileSiteRedirect } from 'components/MobileSiteRedirect';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { WebsocketProvider } from 'websocket/WebsocketContext';
import { websocket } from 'websocket';
import { getAsyncLaunchDarklyProvider } from 'LaunchDarklyProvider';
import {
  clearAuthSession,
  destroyCurrentSessionAndGoToLogin,
  getAuthSession,
} from 'auth/session';
import { apiClientV5 } from 'api/apiClientV5';

// TODO: When we are ready open for Prod and/or Dev
if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    // Performance Monitoring
    // Capture 100% of the transactions, reduce in production!
    tracesSampleRate: REACT_ENVIRONMENT === 'prod' ? 0.1 : 1.0,
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

    // Called before a breadcrumb is sent, optionally filters keys from breadcrumb get request urls
    beforeBreadcrumb: (breadcrumb) => {
      const awsLinkQueryParams = [
        'response-content-disposition',
        'Expires',
        'Signature',
        'Key-Pair-Id',
      ];

      const breadcrumbUrl = breadcrumb?.data?.url
        ? new URL(breadcrumb.data.url)
        : undefined;
      if (!breadcrumbUrl) {
        return breadcrumb;
      }

      const isAWSLink = awsLinkQueryParams.every((param) =>
        breadcrumbUrl.search.includes(param),
      );

      /* eslint-disable no-param-reassign */
      if (isAWSLink && breadcrumb?.data?.url) {
        breadcrumbUrl.search = '';
        breadcrumb.data.url = breadcrumbUrl.href;
      }

      return breadcrumb;
    },
  });
}

const locationHref = window.location.href;

Modal.setAppElement('#root');
if (locationHref.includes('document?token=')) {
  // The last of a dying breed nearing an impending extinction event...
  setCookie(
    locationHref,
    { path: '/', secure: true, expires: 1 },
    false,
    'redirect_url',
  );
}

// Copy the existing interceptor logic to the new axios instance
// This needs to be done here or it wont set until a page refresh
apiClientV5.interceptors.request.use((config) => {
  // Add auth session token to outgoing requests
  const session = getAuthSession();
  if (session?.auth_token) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization =
      config.headers.Authorization || session.auth_token;
  }

  // Add act_as user param to outgoing requests
  const actingAsUserId = localStorage.getItem(ACT_AS_KEY);
  if (actingAsUserId) {
    // eslint-disable-next-line no-param-reassign
    config.params = {
      ...(config.params || {}),
      act_as: actingAsUserId,
    };
  }

  return config;
});

axios.interceptors.request.use((config) => {
  // Add auth session token to outgoing requests
  const session = getAuthSession();
  if (session?.auth_token) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization =
      config.headers.Authorization || session.auth_token;
  }

  // Add act_as user param to outgoing requests
  const actingAsUserId = localStorage.getItem(ACT_AS_KEY);
  if (actingAsUserId) {
    // eslint-disable-next-line no-param-reassign
    config.params = {
      ...(config.params || {}),
      act_as: actingAsUserId,
    };
  }

  return config;
});

// Hooray for global variables... :thisisfine:
let isLoggingOutUserDueToExpiredSession = false;
axios.interceptors.response.use(
  (response) => {
    if (
      response?.data?.status === 601 &&
      !isLoggingOutUserDueToExpiredSession
    ) {
      AlertMessage('error', response.data.errors.unauthorized[0], 5000);
      if (
        response.data.errors.unauthorized[0] &&
        response.data.errors.unauthorized[0] ===
          'Password Changed Successfully. Please Login in.'
      ) {
        setTimeout(destroyCurrentSessionAndGoToLogin, 2000);
      } else {
        const sysData = systemData();
        const deviceMake = sysData.manufacturer ? sysData.manufacturer : '';
        const deviceModel = sysData.model ? sysData.model : '';
        const config = {
          headers: {
            DeviceMake: deviceMake,
            DeviceModel: deviceModel,
          },
        };
        // Set global flag to prevent looping session expired errors prior to getting logged out
        isLoggingOutUserDueToExpiredSession = true;
        const request = axios.post(LOGOUT, {}, config);
        request.then((res) => {
          if (res.data.status === 200 || res.data.status === 601) {
            clearAuthSession();

            websocket.consumer.disconnect();
            localStorage.clear();
            window.location.href = '/';
            window.location.reload(true);
          }
        });
      }
    }

    return response;
  },
  (err) =>
    // switch (err.message) {
    //   case 'Network Error':
    //     window.location.href = '/maintanance';
    //     break;
    //   default:
    //     break;
    // }
    Promise.reject(err),
);

const store = configureStore();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  },
});

// This prevents race conditions where components may (re)render before/after LaunchDarkly is ready.
// https://docs.launchdarkly.com/sdk/client-side/react/react-web#initializing-using-asyncwithldprovider
(async () => {
  const LaunchDarklyProvider = await getAsyncLaunchDarklyProvider();

  ReactDOM.render(
    <Provider store={store}>
      <WebsocketProvider>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <ThemeProvider theme={LightTheme}>
              <LaunchDarklyProvider>
                <MobileSiteRedirect>
                  <CssBaseline />
                  <ToastContainer />
                  <App />
                  <ReactQueryDevtools initialIsOpen={false} />
                </MobileSiteRedirect>
              </LaunchDarklyProvider>
            </ThemeProvider>
          </BrowserRouter>
        </QueryClientProvider>
      </WebsocketProvider>
    </Provider>,
    document.getElementById('root'),
  );
})();
