import React, { Component } from 'react';
import Axios from 'axios';
import { CONTACT, ACCOUNT } from 'constants/constants';
import { generateAddress, generateFullName } from 'utilities/utils';
import { contactAddressFieldNames } from '../ContactCreation/ContactUtilities';
import { accountAddressFieldNames } from '../../Accounts/AccountCreation/AccountUtilities';

class ShowSelectedRelativeInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: undefined,
      data: undefined,
      id: this.props.id,
    };
  }

  componentDidMount() {
    if (this.props.id !== null) {
      this.setState({ id: this.props.id }, () => {
        if (this.props.show === 'account') {
          this.getAccountData();
        }
        if (this.props.show === 'contact') {
          this.getContactData();
        }
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.id !== nextProps.id && nextProps.id !== null) {
      this.setState(
        {
          id: nextProps.id,
          data: undefined,
          name: undefined,
          address: undefined,
        },
        () => {
          if (this.props.show === 'account') {
            this.getAccountData();
          }
          if (this.props.show === 'contact') {
            this.getContactData();
          }
        },
      );
    }
  }

  getAccountData = () => {
    Axios.get(`${ACCOUNT}/${this.state.id}`).then((res) => {
      if (res.status === 200 && res.data.status === 200 && res.data.data) {
        this.setState({
          data: res.data.data.account,
          name: res.data.data.account.name,
          address: generateAddress(
            res.data.data.account,
            accountAddressFieldNames,
          ),
        });
      }
    });
  };

  getContactData = () => {
    Axios.get(`${CONTACT}/${this.state.id}`).then((res) => {
      if (
        res.status === 200 &&
        res.data.status === 200 &&
        res.data.data &&
        res.data.data.length
      ) {
        const contactDetails = res.data.data[0];
        this.setState({
          data: contactDetails.contact,
          name: generateFullName(contactDetails.contact),
          address:
            contactDetails.contact_address !== null
              ? generateAddress(
                  contactDetails.contact_address[0],
                  contactAddressFieldNames,
                )
              : undefined,
        });
      }
    });
  };

  render() {
    const { address, data } = this.state;
    const {
      addressLine1Name,
      addressLine2Name,
      cityName,
      stateName,
      countryName,
      zipName,
    } =
      this.props.show === 'account'
        ? accountAddressFieldNames
        : contactAddressFieldNames;
    if (!this.state.data) {
      return null;
    }
    return (
      <div
        className="row rounded p-3 no-gutters"
        style={{ border: '2px solid #dfe3e8' }}
      >
        <div className="col section-title">
          <div className="d-flex">
            <div className="infoAccount">
              {data.status && this.props.show === 'account' ? (
                <h5 className="subHeadline text-dark text-uppercase mb-0">
                  {data.status}
                </h5>
              ) : null}
              {data.job_title ? (
                <h5 className="subHeadline text-dark text-uppercase mb-0">
                  {data.job_title}
                </h5>
              ) : null}
              <h2 className="text-medium onlineStatus section-title mb-1">
                {this.state.name}
              </h2>
              {data.primary_email ? (
                <h4 className="text-small text-dark mb-1">
                  {data.primary_email}
                </h4>
              ) : null}
              {address ? (
                <h5 className="text-small mb-0 text-dark text-capitalize">
                  {address[addressLine1Name]}
                  {address[addressLine2Name]}
                  {address[cityName]}
                  {address[stateName]}
                  {address[zipName]}
                  {address[countryName]}
                </h5>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ShowSelectedRelativeInfo;
