import {
  CLIENT_ANDROID_URL,
  CLIENT_IOS_URL,
  FIRM_ANDROID_URL,
  FIRM_IOS_URL,
} from 'constants/constants';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom-latest';

const MobileRedirect = () => {
  const location = useLocation();

  useEffect(() => {
    let url = '/';
    const agent =
      window.navigator === 'undefined' ? '' : navigator.userAgent.toLowerCase();
    const isAndroid = agent.indexOf('android') > -1;
    const isIos = agent.indexOf('iphone') > -1;
    const isClient = location.pathname.indexOf('lisciopro') > -1;
    const isIpad =
      agent.indexOf('ipad') > -1 /* iOS pre 13 */ ||
      (navigator.platform === 'MacIntel' &&
        navigator.maxTouchPoints > 1); /* iPad OS 13 */
    if (isAndroid) {
      url = isClient ? CLIENT_ANDROID_URL : FIRM_ANDROID_URL;
    } else if (isIos || isIpad) {
      url = isClient ? CLIENT_IOS_URL : FIRM_IOS_URL;
    }
    window.location.href = url;
  }, []);

  return <div />;
};

export default MobileRedirect;
