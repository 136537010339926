import React from 'react';
import axios from 'axios';
import { LOGIN_WITH_MAGIC_LINK_API_PATH } from 'constants/constants';
import { withRouter } from 'utilities/withRouterWrapper';
import { setAuthSession } from 'auth/session';
import { getSiteHostname } from 'utilities/utils';

const noneStyle = { display: 'none' };
const blockStyle = { display: 'block' };
class RemoteLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loading: false };
  }

  componentDidMount() {
    const component = this;
    const currentLocation = getSiteHostname();
    const {
      params: { id },
      navigate,
    } = this.props;

    if (id) {
      const data = {
        link: id,
        company_name: currentLocation,
      };
      component.setState({ loading: true });
      const request = axios.post(LOGIN_WITH_MAGIC_LINK_API_PATH, data);
      request.then((response) => {
        if (response.data.status === 200) {
          if (response.data.two_factor_enabled) {
            data.email = response.data.email;
            navigate({
              pathname: '/',
              component: 'passCode',
              remoteData: data,
            });
          } else {
            const authToken = response.data.auth_token;
            setAuthSession({ auth_token: authToken });
            navigate('/dashboard');
          }
        } else {
          navigate('/');
        }
      });
    } else {
      navigate('/');
    }
  }

  render() {
    return (
      <div>
        <div id="loading" style={this.state.loading ? blockStyle : noneStyle} />
      </div>
    );
  }
}
export default withRouter(RemoteLogin);
