/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-use-before-define */
import React, { useState, useEffect, Fragment } from 'react';
import { useLocation } from 'react-router-dom-latest';
import Select from 'react-select';
import styled from '@emotion/styled';
import DatePicker from 'react-datepicker';
import Axios from 'axios';
import {
  DEFAULT,
  EDIT,
  EDIT_RECURRING,
  END_DATE,
  NEVER,
  RECURRING,
  GET_ACCOUNTS_FOR_INVOICE,
} from 'constants/constants';
import { getDay } from 'utilities/utils';
import addDays from 'date-fns/addDays';
import format from 'date-fns/format';
import isBefore from 'date-fns/isBefore';
import getDate from 'date-fns/getDate';
import WarningIcon from '@mui/icons-material/Warning';
import TinymceEditor from 'components/Emails/components/TinymceEditior';
import TaskFileUpload from 'components/Files/TaskFileUpload';
import ConfirmationModal from 'common/ConfirmationModal';
import RecurringDropdown from 'components/Billing/elements/Recurring/RecurringDropdown';
import 'react-datepicker/dist/react-datepicker.css';
import AccountSelect from './AccountSelect';
import ContactSelect from './ContactSelect';
import AllowedPaymentMethodsSelect from './AllowedPaymentMethodsSelect';
import OwnerSelect from './OwnersSelect';
import AmountField from './AmountField';
import ItemField from './ItemField';
import DescriptionSelect from './DescriptionSelect';

const StyledDiv = styled.div`
  label {
    font-weight: 600;
    font-size: 12px;
    color: #919eab;
    letter-spacing: 1px;
  }

  .InvoiceForm__Input {
    color: #212b36;
    border: ${({ invalidAmount }) =>
      invalidAmount ? '2px solid red' : '2px solid #dfe3e8'};
    background-color: transparent;
    border-radius: 2px;
    height: 36px;
    width: 262px;
  }

  .InvoiceForm__InvoiceNumberInput {
    color: ${({ isInvoiceNumberDisabled }) =>
      isInvoiceNumberDisabled ? 'hsl(0, 0%, 50%)' : '#212b36'};
    border: ${({ invalidInvoiceNumber }) =>
      invalidInvoiceNumber ? '2px solid red' : '2px solid #dfe3e8'};
    background-color: ${({ isInvoiceNumberDisabled }) =>
      isInvoiceNumberDisabled ? 'hsl(0, 0%, 95%);' : 'transparent'};
    border-radius: 2px;
    height: 36px;
    width: 262px;
  }

  .InvoiceForm__ItemInput {
    color: #212b36;
    border: ${({ invalidItem }) =>
      invalidItem ? '2px solid red' : '2px solid #dfe3e8'};
    background-color: transparent;
    border-radius: 2px;
    height: 36px;
    width: 262px;
  }

  .InvoiceForm__Row {
    display: flex;
    align-items: baseline;
    margin-bottom: 22px;
    width: 100%;
  }

  .InvoiceForm__Details {
    margin-top: 20px;
    display: flex;
    align-items: baseline;
    flex-direction: column;

    .InvoiceForm__Column {
      display: flex;
      align-items: baseline;
      flex: 1 0 50%;

      label {
        flex: 1;
      }

      .InvoiceForm__Field {
        flex: 2;
      }
    }

    .HiddenInput {
      label,
      div {
        display: none;
        visibility: hidden;
      }
    }
  }

  .InvoiceForm__FileUpload,
  .InvoiceForm__Attachments {
    margin: 30px 0;
  }

  .InvoiceForm__TextEditor {
    margin-bottom: ${({ mode }) =>
      mode === EDIT || mode === EDIT_RECURRING ? '20px' : '0px'};
  }

  .InvoiceForm__Description {
    margin: 0px 0 12px 0;
    input {
      color: #212b36;
      border: ${({ invalidDescription }) =>
        invalidDescription ? '2px solid red' : '2px solid #dfe3e8'};
      background-color: transparent;
      border-radius: 2px;
      height: 36px;
      width: 100%;
    }
  }

  .Select {
    max-width: 261px;
  }

  .InvoiceForm__DatePicker {
    width: 238px;
    border: unset;
  }

  .icon-calendar {
    margin-right: 8px;
  }

  .InvoiceForm__Error {
    width: 262px;
    overflow-wrap: break-word;
    color: red;
    margin-top: 2px;
  }

  .InvoiceForm__InvoiceNumberInput,
  .InvoiceForm__Input,
  .InvoiceForm__ItemInput {
    outline: none;
    &:hover {
      border: 2px solid hsl(0, 0%, 70%);
    }

    &:focus {
      border: 2px solid #919eab;
    }
  }

  .file-drop-upload {
    padding: 14px 0 0 0;
  }

  #invoiceDate,
  #dueDate,
  #recurringStartDate {
    cursor: pointer;
  }

  .AmountInput__Disabled {
    background-color: hsl(0, 0%, 95%);
    color: hsl(0, 0%, 50%);
  }

  .DueDate__QBMode {
    width: 240px;
    color: hsl(0, 0%, 50%);
    cursor: not-allowed;
  }

  .react-datepicker-wrapper {
    width: 26%;
  }

  /* RECURRING */
  .InvoiceForm__StartDate {
    border: unset;
    width: 84px;
  }

  .Invoice__RecurringSection {
    display: flex;
    width: 238px;

    .RecurringSection {
      .MuiTypography-root {
        margin-right: 16px;
      }
    }
  }
`;

const SelectStyles = {
  control: (styles, { isFocused, selectProps: { invalidValue } }) => ({
    ...styles,
    borderRadius: '2px',
    // eslint-disable-next-line no-nested-ternary
    border: invalidValue
      ? '2px solid red'
      : isFocused
      ? '2px solid #919eab'
      : '2px solid #dfe3e8',
    boxShadow: 'none',
    '&:hover': {
      borderColor: 'hsl(0, 0%, 70%)',
      boxShadow: 'none',
    },
  }),
  indicatorContainer: (styles) => ({ ...styles, padding: '6px' }),
  input: (styles) => ({ ...styles, width: '200px', cursor: 'pointer' }),
  menu: (provided) => ({
    ...provided,
    marginTop: '0px',
    border: '2px solid #919eab',
    outlineStyle: 'none',
    boxShadow: 'none',
    zIndex: 100,
  }),
};

const paymentMethodsOptions = [
  {
    label: 'Credit Card',
    value: ['card'],
  },
  {
    label: 'ACH Only',
    value: ['ach'],
  },
  {
    label: 'Card and ACH',
    value: ['card', 'ach'],
  },
];

const InvoiceForm = ({
  allAccounts,
  allContacts,
  owners,
  items,
  paymentTerms,
  cpaInvoiceSettings,
  billingTemplates,
  formState,
  handleInputChange,
  handleDateChange,
  isFormValid,
  getContactsForAccount,
  getAccountsForContact,
  checked,
  mode,
  dispatch,
  setDisableButton,
  setCheckboxDisabled,
}) => {
  const [calculatedDueDate, setDueDate] = useState(null);
  const [isWarningModalOpen, setWarningModal] = useState(false);
  const [showSubjectClearModal, setShowSubjectClearModal] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(undefined);
  const [updatedRecurringEndDate, setUpdatedRecurringEndDate] =
    useState(undefined);

  const invalidAmount =
    !isFormValid && (!formState.amount || formState.amount <= 0);
  const invalidDescription = !isFormValid && !formState.customTemplateInput;
  const invalidAccount = !isFormValid && !formState.account?.value;
  const invalidContact = !isFormValid && !formState.contact?.value;
  const invalidOwner = !isFormValid && !formState.owner;
  const invalidItem = !isFormValid && !formState.item;
  const invalidPaymentMethod = !isFormValid && !formState.paymentMethods;

  const location = useLocation();

  const { is_qb_setup_done, currency } = cpaInvoiceSettings;
  const isInvoiceNumberDisabled =
    mode === EDIT || (is_qb_setup_done && checked);

  const invalidInvoiceNumber =
    !isFormValid && !formState.invoiceNumber && !isInvoiceNumberDisabled;

  const allowUserToType = !checked && items.length === 0;

  const currentDate = new Date();

  async function getAccount(accountId) {
    try {
      const response = await Axios.get(
        `${GET_ACCOUNTS_FOR_INVOICE}?account_id=${accountId}`,
      );
      if (response.status === 200) {
        handleAccountChange(response.data.data[0]);
      }
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    if (mode === EDIT || mode === EDIT_RECURRING) {
      getContactsForAccount(formState.account.value);
    }
  }, [mode]);

  // when user comes from accounts page we will autofill account details
  // we make a call to get account qb_customer_id
  useEffect(() => {
    if (
      location.pathname === '/billing/invoices' &&
      location.state &&
      location.state.accountID
    ) {
      const { accountID } = location.state;
      getAccount(accountID);
    }
  }, [location.state]);

  function handleDocumentUpload(documents) {
    handleInputChange('documents', documents);
  }

  function handleAccountChange(selectedOption) {
    if (selectedOption) {
      const accountId = selectedOption.value;
      getContactsForAccount(accountId);

      if (!selectedOption.qb_customer_id) {
        setCheckboxDisabled(true);
      } else {
        setCheckboxDisabled(false);
        handleInputChange('invoiceNumber', '');
      }
    }
    handleInputChange('account', selectedOption);
  }

  function handleContactChange(selectedOption) {
    if (mode === EDIT) {
      setDisableButton(false);
    }

    if (selectedOption) {
      const contactId = selectedOption && selectedOption.value;
      getAccountsForContact(contactId);
    }
    handleInputChange('contact', selectedOption);
  }

  function handleOwnerChange(selectedOption) {
    if (mode === EDIT) {
      setDisableButton(false);
    }

    handleInputChange('owner', selectedOption);
  }

  function handleItemChange(selectedOption) {
    if (mode === EDIT) {
      setDisableButton(false);
    }

    handleInputChange('item', selectedOption);
  }

  function handleItemInputChange(event) {
    handleInputChange('item', event.target.value);
  }

  function handlePaymentTermsChange(selectedOption) {
    if (mode === EDIT) {
      setDisableButton(false);
    }

    if (selectedOption) {
      const dueDate = addDays(
        formState.invoiceDate,
        selectedOption.due_days || 0, // sometimes due_days can be null
      );
      setDueDate(dueDate);
      handleInputChange('dueDate', dueDate);
    } else {
      setDueDate(null);
      handleInputChange('dueDate', currentDate);
    }
    handleInputChange('paymentTerms', selectedOption);
  }

  function handlePaymentMethodsChange(selectedOption) {
    handleInputChange('paymentMethods', selectedOption);
  }

  function handleInvoiceDateChange(date) {
    if (mode === EDIT) {
      setDisableButton(false);
    }

    if (date && formState.paymentTerms) {
      const dueDate = addDays(date, formState.paymentTerms.due_days);
      setDueDate(dueDate);
    }
    handleDateChange('invoiceDate', date);
  }

  function handleDueDateChange(date) {
    if (mode === EDIT) {
      setDisableButton(false);
    }

    setDueDate(null);
    handleDateChange('dueDate', date);
  }

  function handleChange(event) {
    const { name, value } = event.target;
    if (name === 'description' && mode === EDIT) {
      setDisableButton(false);
    }
    handleInputChange(name, value);
  }

  function handleAmountChange(value) {
    if (mode === EDIT) {
      setDisableButton(false);
    }

    handleInputChange('amount', value);
  }

  function handleMessageChange(data) {
    if (mode === EDIT) {
      setDisableButton(false);
    }
    handleInputChange('message', data);
  }

  function filterDueDate(date) {
    return date > formState.invoiceDate;
  }

  /** RECURRING CODE */
  function handleStartDateChange(nextChargeDate, delRecurring = false) {
    const currentRecurringDate = addDays(new Date(), 5);
    const day = getDay(currentRecurringDate);

    if (delRecurring) {
      const updatedState = {
        ...formState,
        recurringStartDate: currentRecurringDate,
        nextChargeDate: currentRecurringDate,
        recurringEndDate: 'Never',
        onSelectedDay: { label: day, value: day },
      };
      dispatch({ type: 'UPDATE_ALL', updatedState });
    } else if (
      mode === RECURRING ||
      (mode === EDIT_RECURRING && formState.childInvoicesCount === 0)
    ) {
      handleDateChange('nextChargeDate', new Date(nextChargeDate));
      handleDateChange('recurringStartDate', new Date(nextChargeDate));
    }
  }

  function changeRecurringDay(day) {
    handleInputChange('onSelectedDay', day);
  }

  // get all the values from recurring setup
  function getSelectedRadioValue({
    radioValue,
    recurringEndDate,
    frequency,
    onSelectedMonth,
    recurringInterval,
  }) {
    const formattedDate = recurringEndDate
      ? format(recurringEndDate, 'MM/dd/yyyy')
      : NEVER;
    if (mode === RECURRING) {
      const updatedState = {
        ...formState,
        frequency,
        onSelectedDay: formState.onSelectedDay,
        onSelectedMonth,
        recurringInterval,
        ...(radioValue === END_DATE
          ? { recurringEndDate: formattedDate }
          : { recurringEndDate: radioValue }),
      };

      dispatch({ type: 'UPDATE_ALL', updatedState });
    }

    if (mode === EDIT_RECURRING) {
      // check if the parent invoice has a child invoice
      if (
        isBefore(recurringEndDate, formState.nextChargeDate) &&
        formState.childInvoicesCount > 0
      ) {
        setWarningModal(true);
        setUpdatedRecurringEndDate(formattedDate);
      } else {
        handleDateChange('recurringEndDate', formattedDate);
      }

      // check if parent invoice has no child invoice
      if (formState.childInvoicesCount === 0) {
        const updatedState = {
          ...formState,
          onSelectedDay: formState.onSelectedDay,
          recurringInterval,
          ...(radioValue === END_DATE
            ? { recurringEndDate: formattedDate }
            : { recurringEndDate: radioValue }),
        };

        dispatch({ type: 'UPDATE_ALL', updatedState });
      }
    }
  }

  function updateRecurringEndDate() {
    handleDateChange('recurringEndDate', updatedRecurringEndDate);
  }

  // start date datepicker
  function handleRecurringStartDateChange(date) {
    const day = getDate(date);

    const updatedState = {
      ...formState,
      recurringStartDate: date,
      nextChargeDate: date,
      onSelectedDay: { label: day, value: day },
    };

    dispatch({ type: 'UPDATE_ALL', updatedState });
  }

  function filterRecurringStartDate(date) {
    return date >= currentDate;
  }

  function handleYesButtonclick() {
    updateRecurringEndDate();
    setWarningModal(false);
  }

  function handleNoButtonclick() {
    setWarningModal(false);
  }
  /** END */

  /** BILLING TEMPLATES CODE  */
  function handleTemplateChange(selectedOption) {
    handleInputChange('customTemplateInput', selectedOption.label);
    handleInputChange('descriptionInput', selectedOption.label);
    handleMessageChange(selectedOption.content);
    handleDocumentUpload(selectedOption.documents);
    setSelectedTemplate(undefined);
  }

  function handleDescriptionChange(selectedOption) {
    const { customTemplateInputValue, message, documents } = formState;
    if (
      (customTemplateInputValue || message || documents.length) &&
      !showSubjectClearModal &&
      (!selectedOption || (selectedOption && !customTemplateInputValue))
    ) {
      handleCustomTemplateClear();
      setSelectedTemplate(selectedOption);
    } else if (selectedOption && selectedOption !== null) {
      handleTemplateChange(selectedOption);
    }
  }

  function handleDescriptionInputChange(value, action) {
    // check if it's an input change
    if (action.action === 'input-change') {
      handleInputChange('descriptionInput', value);
    }
  }

  function handleInputBlur() {
    if (formState.descriptionInput) {
      handleInputChange('customTemplateInput', formState.descriptionInput);
    }
  }

  function customTemplateInputChange(event) {
    handleInputChange('customTemplateInput', event.target.value);
  }

  function handleCustomTemplateClear() {
    setShowSubjectClearModal(true);
  }

  function handleCustomTemplateCancel() {
    setShowSubjectClearModal(false);
  }
  function clearCustomTemplate() {
    if (selectedTemplate) {
      handleTemplateChange(selectedTemplate);
    } else {
      const updatedState = {
        ...formState,
        customTemplateInput: '',
        descriptionInput: '',
        message: '',
        documents: [],
      };

      dispatch({ type: 'UPDATE_ALL', updatedState });
    }
    setShowSubjectClearModal(false);
  }
  /** END */

  return (
    <StyledDiv
      className="InvoiceForm"
      invalidAmount={invalidAmount}
      invalidDescription={invalidDescription}
      isInvoiceNumberDisabled={isInvoiceNumberDisabled}
      invalidInvoiceNumber={invalidInvoiceNumber}
      invalidItem={invalidItem}
      mode={mode}
    >
      <div className="InvoiceForm__Details">
        {(mode === DEFAULT || mode === EDIT) && (
          <>
            <div className="InvoiceForm__Row">
              <div className="InvoiceForm__Column AccountSelect">
                <label htmlFor="account">FOR ACCOUNT:</label>
                <AccountSelect
                  account={formState.account}
                  isQBMode={formState.isQBMode}
                  allAccounts={allAccounts}
                  handleAccountChange={handleAccountChange}
                  invalidAccount={invalidAccount}
                  selectStyles={SelectStyles}
                  mode={mode}
                />
              </div>
              <div className="InvoiceForm__Column InvoiceDate">
                <label htmlFor="invoiceDate">INVOICE DATE:</label>
                <div className="InvoiceForm__Field">
                  <i className="icon-calendar" aria-hidden="true" />
                  <DatePicker
                    id="invoiceDate"
                    className="InvoiceForm__DatePicker"
                    selected={formState.invoiceDate}
                    onChange={handleInvoiceDateChange}
                  />
                  {isFormValid && !formState.invoiceDate && (
                    <div className="InvoiceForm__Error">
                      This is a required field
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="InvoiceForm__Row">
              <div className="InvoiceForm__Column ContactSelect">
                <label htmlFor="contact">FOR CONTACT:</label>
                <ContactSelect
                  allContacts={allContacts}
                  handleContactChange={handleContactChange}
                  contact={formState.contact}
                  invalidContact={invalidContact}
                  selectStyles={SelectStyles}
                />
              </div>
              <div className="InvoiceForm__Column PaymentTermsSelect">
                <label htmlFor="paymentTerms">PAYMENT TERMS:</label>
                <div className="InvoiceForm__Field">
                  <Select
                    name="paymentTerms"
                    id="paymentTerms"
                    placeholder="Select payment terms"
                    className="Select"
                    styles={SelectStyles}
                    options={paymentTerms}
                    onChange={handlePaymentTermsChange}
                    value={formState.paymentTerms}
                    isSearchable
                    isClearable
                    isDisabled={formState.isQBMode}
                  />
                </div>
              </div>
            </div>
            <div className="InvoiceForm__Row">
              <div className="InvoiceForm__Column OwnerSelect">
                <label htmlFor="owner">OWNER:</label>
                <OwnerSelect
                  owners={owners}
                  handleOwnerChange={handleOwnerChange}
                  owner={formState.owner}
                  invalidOwner={invalidOwner}
                  selectStyles={SelectStyles}
                />
              </div>
              <div className="InvoiceForm__Column AmountInput">
                <label htmlFor="amount">AMOUNT:</label>
                <AmountField
                  currency={currency}
                  isQBMode={formState.isQBMode}
                  amount={formState.amount}
                  isPaymentAuthorized={formState.isPaymentAuthorized}
                  handleAmountChange={handleAmountChange}
                  invalidAmount={invalidAmount}
                />
              </div>
            </div>
            <div className="InvoiceForm__Row">
              <div className="InvoiceForm__Column InvoiceNumberInput">
                <label htmlFor="invoiceNumber">INVOICE NO:</label>
                <div className="InvoiceForm__Field">
                  <input
                    className="InvoiceForm__InvoiceNumberInput"
                    name="invoiceNumber"
                    id="invoiceNumber"
                    data-testid="invoiceNumber"
                    onChange={handleChange}
                    value={formState.invoiceNumber}
                    disabled={isInvoiceNumberDisabled}
                  />
                  {invalidInvoiceNumber && (
                    <div className="InvoiceForm__Error">
                      This is a required field
                    </div>
                  )}
                </div>
              </div>
              <div className="InvoiceForm__Column DueDate">
                <label htmlFor="dueDate">DUE DATE:</label>
                <div className="InvoiceForm__Field">
                  <i className="icon-calendar" aria-hidden="true" />
                  {formState.isQBMode ? (
                    <div className="DueDate__QBMode">
                      {format(formState.dueDate, 'MM/dd/yyyy')}
                    </div>
                  ) : (
                    <>
                      <DatePicker
                        id="dueDate"
                        className="InvoiceForm__DatePicker"
                        selected={calculatedDueDate || formState.dueDate}
                        onChange={handleDueDateChange}
                        filterDate={filterDueDate}
                        disabled={formState.isQBMode}
                      />
                      {!isFormValid && !formState.dueDate && (
                        <div className="InvoiceForm__Error">
                          This is a required field
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="InvoiceForm__Row">
              <div className="InvoiceForm__Column ItemSelect">
                <label htmlFor="item">ITEM:</label>
                <ItemField
                  item={formState.item}
                  handleItemInputChange={handleItemInputChange}
                  selectStyles={SelectStyles}
                  items={items}
                  handleItemChange={handleItemChange}
                  invalidItem={invalidItem}
                  isQBMode={formState.isQBMode}
                  allowUserToType={allowUserToType}
                />
              </div>
              <div className="InvoiceForm__Column PaymentMethodsSelect">
                <label htmlFor="paymentMethods">ALLOWED PAYMENT METHODS:</label>
                <AllowedPaymentMethodsSelect
                  paymentMethodsOptions={paymentMethodsOptions}
                  selectStyles={SelectStyles}
                  handlePaymentMethodsChange={handlePaymentMethodsChange}
                  paymentMethods={formState.paymentMethods}
                  invalidPaymentMethod={invalidPaymentMethod}
                />
              </div>
            </div>
          </>
        )}
        {(mode === RECURRING || mode === EDIT_RECURRING) && (
          <>
            <div className="InvoiceForm__Row">
              <div className="InvoiceForm__Column AccountSelect">
                <label htmlFor="account">FOR ACCOUNT:</label>
                <AccountSelect
                  account={formState.account}
                  isQBMode={formState.isQBMode}
                  allAccounts={allAccounts}
                  handleAccountChange={handleAccountChange}
                  invalidAccount={invalidAccount}
                  selectStyles={SelectStyles}
                  mode={mode}
                />
              </div>
              <div className="InvoiceForm__Column RecurringStartDate">
                <label htmlFor="recurringStartDate">START DATE:</label>
                <div className="Invoice__RecurringSection InvoiceForm__Field">
                  <i className="icon-calendar" aria-hidden="true" />
                  {formState.childInvoicesCount > 0 ? (
                    <div>
                      {formState.recurringStartDate
                        ? format(formState.recurringStartDate, 'MM/dd/yyy')
                        : ''}
                    </div>
                  ) : (
                    <DatePicker
                      id="recurringStartDate"
                      className="InvoiceForm__StartDate"
                      selected={formState.recurringStartDate}
                      onChange={handleRecurringStartDateChange}
                      filterDate={filterRecurringStartDate}
                    />
                  )}
                  <RecurringDropdown
                    className="RecurringSection"
                    recurringStartDate={formState.recurringStartDate}
                    handleDateChange={handleStartDateChange}
                    getSelectedRadioValue={getSelectedRadioValue}
                    changeRecurringDay={changeRecurringDay}
                    onSelectedDay={formState.onSelectedDay}
                    isRecurring={
                      mode === EDIT_RECURRING ? formState.isRecurring : false
                    }
                    nextChargeDate={formState.nextChargeDate}
                    recurringEndDate={formState.recurringEndDate}
                    childInvoicesCount={formState.childInvoicesCount}
                    recurringInterval={formState.recurringInterval}
                    mode={mode}
                  />
                </div>
              </div>
            </div>
            <div className="InvoiceForm__Row">
              <div className="InvoiceForm__Column ContactSelect">
                <label htmlFor="contact">FOR CONTACT:</label>
                <ContactSelect
                  allContacts={allContacts}
                  handleContactChange={handleContactChange}
                  contact={formState.contact}
                  invalidContact={invalidContact}
                  selectStyles={SelectStyles}
                />
              </div>
              <div className="InvoiceForm__Column RecurringEndDate">
                <label htmlFor="recurringEndDate">END DATE:</label>
                <div className="InvoiceForm__Field">
                  {formState.recurringEndDate}
                </div>
              </div>
            </div>
            <div className="InvoiceForm__Row">
              <div className="InvoiceForm__Column OwnerSelect">
                <label htmlFor="owner">OWNER:</label>
                <OwnerSelect
                  owners={owners}
                  handleOwnerChange={handleOwnerChange}
                  owner={formState.owner}
                  invalidOwner={invalidOwner}
                  selectStyles={SelectStyles}
                  hasRecurred={formState.hasRecurred}
                />
              </div>
              <div className="InvoiceForm__Column RecurringNextCharge">
                <label htmlFor="recurringNextCharge">NEXT CHARGE:</label>
                <div className="InvoiceForm__Field">
                  {formState.nextChargeDate
                    ? format(formState.nextChargeDate, 'MM/dd/yyyy')
                    : ''}
                </div>
              </div>
            </div>
            <div className="InvoiceForm__Row">
              <div className="InvoiceForm__Column ItemSelect">
                <label htmlFor="item">ITEM:</label>
                <ItemField
                  item={formState.item}
                  handleItemInputChange={handleItemInputChange}
                  selectStyles={SelectStyles}
                  items={items}
                  handleItemChange={handleItemChange}
                  invalidItem={invalidItem}
                  isQBMode={formState.isQBMode}
                  allowUserToType={allowUserToType}
                />
              </div>
              <div className="InvoiceForm__Column PaymentMethodsSelect">
                <label htmlFor="paymentMethods">ALLOWED PAYMENT METHODS:</label>
                <AllowedPaymentMethodsSelect
                  paymentMethodsOptions={paymentMethodsOptions}
                  selectStyles={SelectStyles}
                  handlePaymentMethodsChange={handlePaymentMethodsChange}
                  paymentMethods={formState.paymentMethods}
                  invalidPaymentMethod={invalidPaymentMethod}
                  isPaymentAuthorized={formState.isPaymentAuthorized}
                />
              </div>
            </div>
            <div className="InvoiceForm__Row">
              <div className="InvoiceForm__Column AmountInput">
                <label htmlFor="amount">AMOUNT:</label>
                <AmountField
                  currency={currency}
                  amount={formState.amount}
                  handleAmountChange={handleAmountChange}
                  invalidAmount={invalidAmount}
                  isPaymentAuthorized={formState.isPaymentAuthorized}
                  childInvoicesCount={formState.childInvoicesCount}
                />
              </div>
              <div className="InvoiceForm__Column HiddenInput">
                <label htmlFor="hidden">Hidden label</label>
                <div className="InvoiceForm__Field">Hidden Field</div>
              </div>
            </div>
          </>
        )}
      </div>
      <div className="InvoiceForm__Description">
        <label htmlFor="description">DESCRIPTION</label>
        <DescriptionSelect
          selectStyles={SelectStyles}
          billingTemplates={billingTemplates}
          handleDescriptionChange={handleDescriptionChange}
          handleDescriptionInputChange={handleDescriptionInputChange}
          handleCustomTemplateClear={handleCustomTemplateClear}
          handleInputBlur={handleInputBlur}
          descriptionInput={formState.descriptionInput}
          customTemplateInputChange={customTemplateInputChange}
          customTemplateInputValue={formState.customTemplateInput}
          invalidDescription={invalidDescription}
        />
      </div>
      <div className="InvoiceForm__TextEditor">
        <label htmlFor="message">MESSAGE</label>
        <TinymceEditor
          id="message"
          name="message"
          handleChange={handleMessageChange}
          value={formState.message}
          height="150px"
        />
      </div>
      {(mode === DEFAULT || mode === RECURRING) && (
        <div className="InvoiceForm__Attachments">
          <label htmlFor="attachments">ATTACHMENTS</label>
          <TaskFileUpload
            className="InvoiceForm__FileUpload"
            data={{
              attachments: [],
              documents: formState.documents,
              stateValues: {
                contactName: '',
                accountName: '',
              },
            }}
            updateDocumentState={handleDocumentUpload}
            id="attachments"
            messageComp="threadDetail"
            uploadType="task"
          />
        </div>
      )}
      {isWarningModalOpen && (
        <ConfirmationModal
          isOpen={isWarningModalOpen}
          icon={<WarningIcon />}
          className="WarningModal"
          headerText="Terminate Recurring Process?"
          messageText="This will terminate the recurring process. After Save this action cannot be undone. Proceed?"
          yesBtnText="Yes"
          noBtnText="No"
          yesBtnClick={handleYesButtonclick}
          noBtnClick={handleNoButtonclick}
        />
      )}

      {showSubjectClearModal && (
        <ConfirmationModal
          isOpen={showSubjectClearModal}
          className="customModal customModal--w500 customModal--nopadding"
          messageText="You are about to delete the existing message. This action cannot be undone. Proceed?"
          yesBtnText="Yes"
          noBtnText="No"
          yesBtnClick={clearCustomTemplate}
          noBtnClick={handleCustomTemplateCancel}
        />
      )}
    </StyledDiv>
  );
};

export default InvoiceForm;
