import React from 'react';
import { IntegrationsPageLayout } from 'components/Integrations/components/IntegrationsPageLayout';

export const QuickbooksIntegrationConfig = () => {
  console.log('TODO: Determine activation status');
  return (
    <IntegrationsPageLayout
      pageTitle="Quickbooks Integration"
      breadcrumbs={[{ label: 'Quickbooks', path: '/quickbooks' }]}
    >
      TODO: Quickbooks Integration Module
    </IntegrationsPageLayout>
  );
};
