/* eslint-disable jsx-a11y/anchor-is-valid */
import { useFormContext } from 'react-hook-form';
import { Alert } from 'reactstrap';
import {
  FormUseContext,
  LoginFormInterface,
} from 'components/Login/LoginV5/LoginV5Form';
import { useCallback } from 'react';

export interface ForgotPasswordFormProps {
  backToLogin: () => void;
  switchFormUseContext: (newState: FormUseContext) => void;
}

export const ForgotPasswordForm = ({
  backToLogin,
  switchFormUseContext,
}: ForgotPasswordFormProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<LoginFormInterface>();

  const serverErrorMessage = errors?.root?.serverError.message;
  const hasServerError = Boolean(serverErrorMessage);
  const emailError = errors.email?.message;

  const onSendMagicLink = useCallback(() => {
    switchFormUseContext('magicLink');
  }, [switchFormUseContext]);

  const onResetPassword = useCallback(() => {
    switchFormUseContext('passwordReset');
  }, [switchFormUseContext]);

  return (
    <>
      <h1>Forgot your password?</h1>
      {hasServerError && <Alert color="danger">{serverErrorMessage}</Alert>}
      <div>
        <div className="form-group">
          <div className={`form-group ${emailError && 'form-error'}`}>
            <input
              type="email"
              className="form-control"
              placeholder="Email"
              autoComplete="new-password"
              data-testid="email"
              {...register('email')}
            />

            <div className="text-danger error_label" id="mail">
              {emailError}
            </div>
          </div>
        </div>
        <div className="form-group">
          <button
            type="submit"
            className="btn btn-primary pull-left w-100"
            onClick={onResetPassword}
            data-testid="submit"
          >
            Submit
          </button>
          <div className="d-inline-block w-100 mt-3 mb-3 text-center">
            <span className="text-light">Or</span>
          </div>
          <button
            type="submit"
            className="btn send-magic-link btn-outline-light w-100"
            onClick={onSendMagicLink}
            data-testid="send_magic_link"
          >
            Send Magic Link
          </button>
          <div className="crPage--bott text-center mt-3">
            <span className="txtInfo">Back to </span>
            <a
              href="#"
              onClick={backToLogin}
              className="btn tptBtn"
              data-testid="back_to_login"
            >
              <b>Login</b>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPasswordForm;
