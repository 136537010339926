/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import Axios from 'axios';
import { SelectField } from 'common/CustomFields';
import { getCpaPreferences, selectedIds, AlertMessage } from 'utilities/utils';
import { REPLACE_TAGS } from 'constants/constants';

export default class ReplaceTags extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDocPop: false,
      hovered: false,
      selectedItem: null,
      showAlert: '',
      tagsList: [],
      errors: {},
    };
  }

  componentDidMount() {
    const preferences = getCpaPreferences();
    this.setState({ tagsList: preferences.upload_tags });
  }

  handleTagToReplace = (inputvalue) => {
    this.setState({ tagToReplace: inputvalue });
  };

  handleReplaceTag = (inputvalue) => {
    this.setState({
      replaceTag: inputvalue,
      errors: {},
    });
  };

  handleReplaceBtn = () => {
    const errors = {};
    const { tagToReplace, replaceTag } = this.state;
    if (tagToReplace === undefined || tagToReplace === null) {
      errors.tagToReplace = 'Please select tag to replace';
    }
    if (replaceTag === undefined || replaceTag === null) {
      errors.replaceTag = 'Please select tag to replace with';
    }
    if (
      tagToReplace &&
      replaceTag &&
      tagToReplace.value &&
      replaceTag.value &&
      tagToReplace.value.toLowerCase() === replaceTag.value.toLowerCase()
    ) {
      errors.replaceTag = 'Please select different tag';
    }
    if (!Object.keys(errors).length) {
      const { selectedrecords } = this.props;
      Axios.post(REPLACE_TAGS, {
        document_ids: selectedIds(selectedrecords, 'id'),
        tag_to_replace: tagToReplace.key,
        replace_tag: replaceTag.key,
      }).then((res) => {
        if (res.data && res.data.status === 200) {
          const refreshData = true;
          this.props.closeModal(refreshData);
          AlertMessage('success', res.data.message, 3000);
        } else {
          AlertMessage('error', res.data.message, 3000);
        }
      });
      // handle replace api logic
    } else {
      this.setState({ errors });
    }
  };

  render() {
    const { tagsList, tagToReplace, replaceTag, errors } = this.state;
    return (
      <div>
        <Modal
          className="customModal customModal--w700 customModal--nopadding ReplaceTagsModal"
          isOpen="true"
        >
          {this.state.loading && <div id="loading" />}
          <div className="ModalHeader">
            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={() => this.props.closeModal()}
            >
              <i className="icon-close2" aria-hidden="true" />
            </button>
          </div>
          <ModalBody className="modalContent ReplaceTagsModal__Body">
            <div className="modalContent--inner">
              <div className="modalContent--header mb-2">
                <h5 className="modalContent--header__heading">
                  Find and Replace Tag Multiple Files
                </h5>
              </div>
              <div className="mt-4">
                <div className="form-group row">
                  <SelectField
                    options={tagsList}
                    id="find_tag"
                    name="find tag"
                    label="Find"
                    className="col-sm-12 col-lg-6 col-md-6"
                    handleChange={this.handleTagToReplace}
                    value={tagToReplace || { value: '', label: '' }}
                    error={errors.tagToReplace}
                  />
                  <SelectField
                    options={tagsList}
                    id="replace_tag"
                    name="replace tag"
                    label="Replace"
                    className="col-sm-12 col-lg-6 col-md-6"
                    handleChange={this.handleReplaceTag}
                    value={replaceTag || { value: '', label: '' }}
                    error={errors.replaceTag}
                  />
                </div>
                <div className="row">
                  <div className="col-12 justify-content-end formbtn justify-content-end mt-2">
                    <div className="btn-wrap btn--leftspace">
                      <button
                        type="button"
                        className="btn"
                        onClick={() => this.props.closeModal()}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={this.handleReplaceBtn}
                      >
                        Replace Tag
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
