/* eslint-disable */
import moment from 'moment';
import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import Axios from 'axios';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import {
  GET_RECURRING_EXPECTED_DUE_DATE, NEVER, END_DATE, EDIT_RECURRING, RECURRING
} from 'constants/constants';
import { recurringFrequencyStr } from '../../billingUtils';
import Select from 'react-select';
import { selectTheme } from 'common/CustomFields';

const SelectStyles = {
  control: (styles, { isFocused }) => ({
    ...styles,
    borderRadius: '2px',
    width: '50px',
    height: "30px",
    minHeight: "0",
    border: isFocused ? '2px solid #919eab' : '2px solid #dfe3e8',
    boxShadow: 'none',
    '&:hover': {
      borderColor: 'hsl(0, 0%, 70%)',
      boxShadow: 'none',
    },
  }),
  indicatorContainer: (styles) => ({ ...styles, display: 'none'}),
  indicatorsContainer: (styles) => ({ ...styles, display: 'none' }),
  valueContainer: (styles) => ({ ...styles, alignItems: 'flex-start' }),
  input: (styles) => ({ ...styles, cursor: 'pointer' }),
  menu: (provided) => ({
    ...provided,
    marginTop: '0px',
    border: '2px solid #919eab',
    outlineStyle: 'none',
    boxShadow: 'none',
    width: '50px',
    height: '150px',
    cursor: 'pointer',
  }),
  menuList: (provided) => ({
    ...provided,
    height: '150px',
    "::-webkit-scrollbar": {
      display: 'none',
    },
  }),
}

const due_on_weekdays = [
  'due_on_mon',
  'due_on_tue',
  'due_on_wed',
  'due_on_thu',
  'due_on_fri',
  'due_on_sat',
  'due_on_sun',
];
const generateOptions = (range) => {
  const options = [];
  for (let i = 1; i <= range; i += 1) {
    options.push({ value: i, label: i });
  }
  return options;
};

class InvoiceRecurringSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false,
      weekdayIntervalOptions: generateOptions(12),
      monthIntervalOptions: generateOptions(12),
      daysOptions: generateOptions(31),
      is_recurring: this.props.is_recurring,
      frequency: 'Monthly',
      recurring_interval: this.props.recurringInterval,
      recurring_end_date: null,
      due_on_day_of_month: null,
      due_on_month: null,
      due_on_mon: false,
      due_on_tue: false,
      due_on_wed: true,
      due_on_thu: false,
      due_on_fri: false,
      due_on_sat: false,
      due_on_sun: false,
      parent_task_id: null,
      on_schedule: false,
      errors: {},
      radioValue: NEVER,
    };
    this.initialState = this.state;
    this.resetState = this.state;
  }

  showRecurringOptions = () => {
    const { mode, recurringEndDate } = this.props;
    // get the intial radio value and end date
    if (mode === EDIT_RECURRING) {
      if (recurringEndDate === NEVER) {
        this.setState({
          ...this.initialState,
          showMenu: !this.state.showMenu,
          errors: {},
          recurring_end_date: null,
          radioValue: NEVER,
        });
      } else {
        this.setState({
          ...this.initialState,
          showMenu: !this.state.showMenu,
          errors: {},
          recurring_end_date: new Date(recurringEndDate),
          radioValue: END_DATE,
        });
      }
    }

    if (mode === RECURRING) {
      this.setState(
        {
          ...this.initialState,
          showMenu: !this.state.showMenu,
          errors: {},
        },
        () => {
          this.props.stateChange(
            { recurring_properties: { ...this.state, recurringDropdown: this.state.showMenu } },
            true,
          );
        },
      );
    }
  };

  handleFrequency = (input) => {
    if (input !== this.state.frequency) {
      let {
        recurring_interval,
        due_on_day_of_month,
        due_on_month,
        due_on_mon,
        due_on_tue,
        due_on_wed,
        due_on_thu,
        due_on_fri,
        due_on_sat,
        due_on_sun,
      } = this.state;
      recurring_interval = 1;
      if (input === 'Monthly') {
        due_on_mon = false;
        due_on_tue = false;
        due_on_wed = false;
        due_on_thu = false;
        due_on_fri = false;
        due_on_sat = false;
        due_on_sun = false;
        due_on_month = null;
        due_on_day_of_month = 1;
      } else if (input === 'Yearly') {
        due_on_day_of_month = 1;
        due_on_month = 1;
      }
      this.setState({
        frequency: input,
        recurring_interval,
        due_on_day_of_month,
        due_on_month,
        due_on_mon,
        due_on_tue,
        due_on_wed,
        due_on_thu,
        due_on_fri,
        due_on_sat,
        due_on_sun,
        errors: {},
      });
    }
  };

  handleDay = (input) => {
    this.setState({
      [input]: !this.state[input],
      errors: {},
    });
  };

  handleDayofMonth = (input) => {
    this.props.changeRecurringDay(input);
    // this.setState({
    //   due_on_day_of_month: input,
    // });
  };

  handleMonth = (input) => {
    const { due_on_day_of_month } = this.state;
    this.setState({ due_on_month: input });
    if ((input < 8 && input % 2 !== 0) || (input > 7 && input % 2 === 0)) {
      this.setState({ daysOptions: generateOptions(31) });
    } else if (input === 2) {
      this.setState({
        daysOptions: generateOptions(28),
        due_on_day_of_month: due_on_day_of_month > 28 ? 1 : due_on_day_of_month,
      });
    } else {
      this.setState({
        daysOptions: generateOptions(30),
        due_on_day_of_month: due_on_day_of_month === 31 ? 1 : due_on_day_of_month,
      });
    }
  };

  handleInterval = (input) => {
    this.setState({ recurring_interval: input });
  };

  handleEndDate = (input) => {
    this.setState({
      recurring_end_date: input,
      radioValue: END_DATE,
    });
  };

  handleReccurSchedule = (input) => {
    this.props.updateIsRecurring(input);
    console.log('Handle Recurring state', input);
    this.setState({ on_schedule: input });
  };

  handleDoneBtn = () => {
    const { getSelectedRadioValue, nextChargeDate } = this.props;
    let { recurring_end_date } = this.state;

    console.log(this.state);

    getSelectedRadioValue({
      radioValue: this.state.radioValue,
      recurringEndDate: recurring_end_date,
      frequency: this.state.frequency,
      onSelectedMonth: this.state.due_on_month,
      recurringInterval: this.state.recurring_interval,
    });
    const { parent_task_id, expected_due_date: due_date } = this.state;
    recurring_end_date = recurring_end_date && recurring_end_date !== null ? moment(recurring_end_date).format() : null;

    this.setState(
      {
        is_recurring: true,
        dataSaved: true,
        showMenu: false,
        errors: {},
      },
      () => {
        this.initialState = this.state;
        this.props.stateChange(
          {
            recurring_properties: {
              ...this.state,
              recurring_end_date,
              recurringDropdown: false,
              recurring_interval: this.state.recurring_interval,
            },
          },
          true,
        );
      },
    );

    const data = {
      ...this.state,
      recurring_interval: this.state.recurring_interval.label,
      user_local_time: moment().format('DD/MM/YYYY'),
      current_expected_due_date:
        parent_task_id && parent_task_id !== null ? moment().format('DD/MM/YYYY') : moment().format('DD/MM/YYYY'),
      due_on_day_of_month: this.props.onSelectedDay.label,
    };

    console.log(data);

    Axios.post(GET_RECURRING_EXPECTED_DUE_DATE, data).then((res) => {
      if (res.data.status === 200) {
        let { expected_due_date } = res.data;
        expected_due_date = moment(expected_due_date);
        this.props.handleDateChange(expected_due_date);
      }
    });
  };

  handleCancelBtn = () => {
    // reset to default state
    if (this.props.mode === RECURRING) {
      this.props.handleDateChange(this.props.initialDate, true); // reset date to initially set date.
    }
    this.setState(
      {
        ...this.initialState,
        showMenu: false,
        errors: {},
      },
      () => {
        this.props.stateChange(
          { recurring_properties: { ...this.state, recurringDropdown: false } },
          true,
        );
      },
    );
  };

  handleDelRecurring = () => {
    this.props.updateIsRecurring(false);
    this.initialState = this.resetState;
    this.setState(this.resetState);
    this.props.handleDateChange(this.props.initialDate, true); // reset date to initially set date.
    this.props.stateChange(
      { recurring_properties: { ...this.resetState, recurringDropdown: false } },
      true,
    );
  };

  validateWeekday() {
    let weekdaySelected = false;
    due_on_weekdays.map((e) => {
      if (this.state[e] === true) {
        weekdaySelected = true;
      }
      return weekdaySelected;
    });
    return weekdaySelected;
  }

  handleRadioValue = (event) => {
    if (this.props.mode === EDIT_RECURRING) {
      this.setState({ radioValue: NEVER });
    }

    if (event.target.value === NEVER) {
      this.setState({ radioValue: NEVER, recurring_end_date: null });
    } else {
      this.setState({ radioValue: event.target.value });
    }
  };

  filterDate = (date) => date >= this.props.recurringStartDate;

  render() {
    const {
      frequency,
      showMenu,
      weekdayIntervalOptions,
      monthIntervalOptions,
      recurring_interval,
      due_on_day_of_month,
      due_on_month,
      daysOptions,
      recurring_end_date,
      is_recurring,
      errors,
      radioValue,
    } = this.state;

    const {
      onSelectedDay, childInvoicesCount, mode,
    } = this.props;

    const viewContext = 'dropdown-menu-left';
    return (
      <div className={showMenu ? 'dropdown show' : 'dropdown'} id="recurringDropdown">
        <a
          href="javascript:;"
          className="text-body"
          id="taskRecurringDropdown"
          onClick={() => this.showRecurringOptions()}
          aria-haspopup="true"
          aria-expanded={showMenu ? 'true' : 'false'}
        >
          <i className={`icon-Recurring pr-1 vertical-middle ${is_recurring ? 'text-primary' : 'text-light'}`} />
          {is_recurring ? `Recurs ${recurringFrequencyStr(recurring_interval.label)}` : 'Set Recurring'}
        </a>
        <div
          className={
            showMenu
              ? `dropdown-menu ${viewContext} recurring-dropdown show`
              : `dropdown-menu ${viewContext} recurring-dropdown`
          }
          aria-labelledby="taskRecurringDropdown"
        >
          <div className="dropdown-item">
            <div className="btn-group segmentBtn" role="group" aria-label="recurring type">
              <h5>Monthly Setup</h5>
            </div>
            {frequency && frequency.toLowerCase() === 'monthly' && mode === EDIT_RECURRING && (
              <div className="MonthContainer">
                {childInvoicesCount > 0 && (
                <div>
                  On:
                  {' '}
                  {onSelectedDay.label}
                  th day of the Month
                </div>
                )}
                {childInvoicesCount === 0 && (
                  <span className="mt-3 d-flex text-body" aria-label="selected">
                    On:&nbsp;&nbsp;
                    <Select
                      options={daysOptions}
                      onChange={this.handleDayofMonth}
                      styles={SelectStyles}
                      value={onSelectedDay}
                      theme={selectTheme}
                    />
                    {' '}
                    &nbsp;&nbsp;day of the month
                  </span>
                )}
              </div>
            )}
            {frequency && frequency.toLowerCase() === 'monthly' && mode === RECURRING && (
              <span className="mt-3 d-flex text-body" aria-label="selected">
                On:&nbsp;&nbsp;
                <Select
                  options={daysOptions}
                  onChange={this.handleDayofMonth}
                  styles={SelectStyles}
                  value={onSelectedDay}
                  theme={selectTheme}
                />
                {' '}
                &nbsp;&nbsp;day of the month
              </span>
            )}
            {frequency && frequency.toLowerCase() === 'monthly' && mode === EDIT_RECURRING && (
              <div className="MonthContainer">
                {childInvoicesCount > 0 && (
                <div>
                  Interval:
                  {' '}
                  {recurring_interval.label}
                  {' '}
                  of Month
                </div>
                )}
                {childInvoicesCount === 0 && (
                  <span className="mt-3 d-flex text-body">
                    Interval: every&nbsp;&nbsp;
                    <Select
                      options={monthIntervalOptions}
                      onChange={this.handleInterval}
                      styles={SelectStyles}
                      value={recurring_interval}
                      theme={selectTheme}
                    />
                    {' '}
                    &nbsp;&nbsp;
                    month
                  </span>
                )}
              </div>
            )}
            {frequency && frequency.toLowerCase() === 'monthly' && mode === RECURRING && (
              <span className="mt-3 d-flex text-body">
                Interval: every&nbsp;&nbsp;
                <Select
                  options={monthIntervalOptions}
                  onChange={this.handleInterval}
                  styles={SelectStyles}
                  value={recurring_interval}
                  theme={selectTheme}
                />
                {' '}
                &nbsp;&nbsp;
                month
              </span>
            )}
            <div className="RecurringSection">
              <label className="RecurringSection_EndLabel" htmlFor="End">
                End:
              </label>
              <RadioGroup
                className="RecurringSection__RadioGroup"
                aria-label="recurringEndDate"
                name="recurringEndDate"
              >
                <FormControlLabel
                  control={<Radio onChange={this.handleRadioValue} value={NEVER} checked={radioValue === NEVER} />}
                  label="Never"
                />
                <div className="RecurringEndDate__On">
                  <FormControlLabel
                    control={
                      <Radio onChange={this.handleRadioValue} value={END_DATE} checked={radioValue === END_DATE} />
                    }
                    label="On"
                  />
                  <div className="date-text date-calender date-calender-icon d-inline-block">
                    <i className="icon-calendar vertical-middle text-dark" aria-hidden="true" />
                    <DatePicker
                      selected={recurring_end_date}
                      popoverTargetAttachment="right center"
                      onChange={(input) => this.handleEndDate(input)}
                      monthsShown={1}
                      filterDate={this.filterDate}
                      //   minDate={this.props.recurringStartDate}
                      dateForm="MM/DD/YYYY"
                    />
                  </div>
                </div>
              </RadioGroup>
            </div>
            <div className="my-3">
              <button type="button" className="btn-sm btn btn-primary mr-2" onClick={this.handleDoneBtn}>
                Done
              </button>
              <button type="button" onClick={this.handleCancelBtn} className="btn-sm btn btn-primary">
                Cancel
              </button>
              {errors && errors.weekly ? <span className="pl-2 text-danger">{errors.weekly}</span> : null}
            </div>
            {is_recurring && mode !== EDIT_RECURRING && (
              <div>
                <span onClick={this.handleDelRecurring} className="pl-2 mb-1 text-danger pointer">
                  Delete Recurring
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default InvoiceRecurringSection;
