import { IconButton, Paper, Snackbar, Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';

/**
 * Component that displays a banner at the top
 * of the screen indicating a Firm has upgraded
 * to one of the Pro tiers.
 */
export default function UpgradeBanner() {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <Snackbar
      open={isOpen}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Paper
        elevation={4}
        sx={{
          backgroundColor: 'common.contrastBackground',
          width: '327px',
          padding: '1rem 2rem',
        }}
      >
        <Stack gap={2} alignItems="center">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            <Typography variant="body1" fontWeight={700}>
              Your plan was upgraded!
            </Typography>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setIsOpen(false)}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Stack>
          <Typography variant="body1">
            You’ll see the upgrade on your next billing cycle. Annual payments
            will be pro-rated. Thank you.
          </Typography>
        </Stack>
      </Paper>
    </Snackbar>
  );
}
