/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// TODO: Keep the comments for future use
import React, { Fragment } from 'react';
import { ADD_NEW_SUB_MENU } from 'constants/constants';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';
import MultipleLevelMenu from './MultipleLevelMenu';
import { MenuItemIcon } from './MenuItemIcon';

export default function AddNewMenuList({
  menuItem,
  isStripeAccountDisabled,
  getSelectedMenuItem,
  handleSubMenuClick,
}: {
  menuItem: MenuItem;
  isStripeAccountDisabled: boolean;
  getSelectedMenuItem: TGetSelectedMenuItem;
  handleSubMenuClick: THandleSubMenuClick;
}) {
  const menuItemsWithoutInvoiceMenu = menuItem.children.filter(
    (item) => item.label !== 'Invoice',
  );

  // If stripe account is disabled, filter out the invoice submenu item
  const filteredMenuItem = isStripeAccountDisabled
    ? { ...menuItem, children: menuItemsWithoutInvoiceMenu }
    : menuItem;

  return (
    <Fragment key={filteredMenuItem.url}>
      <MultipleLevelMenu
        menuItems={filteredMenuItem}
        onSelect={handleSubMenuClick}
        subMenuType={ADD_NEW_SUB_MENU}
        component={
          <ListItemButton
            className="AddMenuList"
            selected={getSelectedMenuItem(filteredMenuItem)}
            data-testid={`sidebar__${filteredMenuItem?.label
              ?.toLowerCase()
              ?.split(' ')
              ?.join('_')}`}
          >
            <ListItemIcon>
              <MenuItemIcon iconClass={filteredMenuItem.icon_class} />
            </ListItemIcon>
            <ListItemText primary={filteredMenuItem.label.toUpperCase()} />
          </ListItemButton>
        }
      />
    </Fragment>
  );
}
