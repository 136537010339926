/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// TODO: Keep the comments for future use
import React from 'react';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';

export default function HelpButton({
  menuItem,
  getSelectedMenuItem,
  handleSubMenuClick,
}: {
  menuItem: MenuItem;
  getSelectedMenuItem: TGetSelectedMenuItem;
  handleSubMenuClick: THandleSubMenuClick;
}) {
  return (
    <a
      rel="external noreferrer"
      target="_blank"
      href={menuItem.url}
      className="Sidebar__MenuListItem"
    >
      <ListItemButton
        key={menuItem.id}
        onClick={(e) => handleSubMenuClick(e, menuItem)}
        data-selected={menuItem.url}
        selected={getSelectedMenuItem(menuItem)}
        data-testid={`sidebar__${menuItem?.label
          ?.toLowerCase()
          ?.split(' ')
          ?.join('_')}`}
      >
        <ListItemIcon className={menuItem.icon_class} />
        <ListItemText primary={menuItem.label.toUpperCase()} />
      </ListItemButton>
    </a>
  );
}
