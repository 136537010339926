/* eslint-disable no-script-url */
/* eslint-disable react/jsx-no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import Axios from 'axios';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import TooltipItem from 'common/TooltipItem';
import { CONTACT_RELATIONSHIPS, SAVE_RELATIONSHIP } from 'constants/constants';
import * as Utilities from 'utilities/utils';
import ConfirmationModal from 'common/ConfirmationModal';
import NoRecords from 'common/NoRecords';
import RatingComponent from 'common/RatingComponent';
import ContactCardParent from 'common/ContactCardParent';
import NewAccount from '../../Accounts/AccountCreation/NewAccount';
import EditRealtionShip from './EditRelationship';

const contactRelationPageTableHeaders = [
  {
    value: 'name',
    label: 'Account Name',
    isSortable: true,
    className: ' col-3 thCol',
  },
  {
    value: 'relation',
    label: 'Relationship',
    isSortable: true,
    className: ' col-1 thCol',
  },
  {
    value: 'entity_type',
    label: 'Entity type',
    isSortable: true,
    className: ' col-2 thCol',
  },
  {
    value: 'rating',
    label: 'Rating',
    isSortable: true,
    className: ' col-2 thCol',
  },
  {
    value: 'is_account_owner',
    label: 'Account Owner',
    isSortable: false,
    className: ' col-1 thCol text-center',
  },
  {
    value: 'is_account_owner',
    label: 'Billing Contact',
    isSortable: false,
    className: ' col-1 thCol text-center',
  },
];

class ContactRelationshipPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      relationships: [],
      norelations: false,
      contacttodelete: null,
      showModal: false,
      sortBy: 'name',
      sortOrder: 'asc',
      pagination: 1,
      loading: true,
      relationshiptype: null,
      showDeleteConfirmation: false,
      relationToDelete: {},
      role: Utilities.getRole(),
    };
  }

  componentDidMount() {
    this.getRelationShips();
  }

  getRelationShips = () => {
    Axios.get(`${CONTACT_RELATIONSHIPS}/${this.props.contactId}`).then(
      (res) => {
        this.setState({ loading: false });
        if (res.status === 200) {
          if (res.data.status === 200) {
            const { sortBy, sortOrder } = this.state;
            this.setState({
              relationships: Utilities.sortList(
                res.data.data,
                sortBy,
                sortOrder,
              ),
            });
          } else {
            this.setState({ relationships: null });
          }
        }
      },
    );
  };

  handleOpenModal = (newRelationShip) => {
    const {
      account_id,
      contact_id,
      relationship_type,
      id: relationship_id,
      is_account_owner,
      is_billing_contact,
      relationshipWith,
    } = {};
    this.setState({
      account_id,
      contact_id,
      relationship_type,
      relationship_id,
      is_account_owner,
      is_billing_contact,
      showModal: true,
      relationshipWith,
      newRelationShip,
    });
  };

  handleCloseModal = (newRelationShip = false) => {
    this.setState({
      showModal: false,
      relationToDelete: {},
      showDeleteConfirmation: false,
      newRelationShip,
    });
    this.getRelationShips();
  };

  editRelationShip = (input) => {
    if (input && input.relation_data) {
      const {
        account_id,
        contact_id,
        relationship_type,
        id: relationship_id,
        is_account_owner,
        is_billing_contact,
      } = input.relation_data;
      this.setState({
        account_id,
        contact_id,
        relationship_type,
        relationship_id,
        is_account_owner,
        is_billing_contact,
        showModal: true,
        relationshipWith: input.name,
        newRelationShip: false,
      });
    }
  };

  handleSortBy = (sortBy) => {
    let { sortOrder } = this.state;
    const { relationships } = this.state;
    if (this.state.sortBy === sortBy) {
      if (sortOrder === 'asc') {
        sortOrder = 'desc';
      } else {
        sortOrder = 'asc';
      }
    } else {
      sortOrder = 'asc';
    }
    this.setState(
      {
        sortOrder,
        sortBy,
        loading: true,
      },
      () => {
        this.setState({
          loading: false,
          relationships: Utilities.sortList(relationships, sortBy, sortOrder),
        });
      },
    );
  };

  updateRelationship = (input) => {
    this.setState({
      relationToDelete: input,
      showDeleteConfirmation: true,
    });
  };

  deleteRelationship = () => {
    Axios.delete(
      `${SAVE_RELATIONSHIP}/${this.state.relationToDelete.relation_data.id}`,
    )
      .then((res) => {
        this.getRelationShips();
        this.setState({
          relationToDelete: {},
          showDeleteConfirmation: false,
        });
        Utilities.AlertMessage('success', res.data.message, 3000);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const { relationships } = this.state;
    const { data } = this.props;
    return (
      <div className="center-wrapper">
        {this.state.loading && <div id="loading" />}
        {data &&
          data.contact &&
          data.contact.status &&
          data.contact.status.toLowerCase() !== 'inactive' && (
            <div className="mb-3 section-title">
              <div className="col-container">
                <div className="col col-6 text-right">
                  <div className="dropdown dropupWrap dropupWrap--right">
                    {this.state.role &&
                    this.state.role === 'specialist' ? null : (
                      <button
                        type="button"
                        className="btn dropdown-toggle btn-primary"
                        data-toggle="dropdown"
                      >
                        <i>+</i> Account
                        <span className="dropupWrap--caret" />
                      </button>
                    )}
                    <div className="dropdown-menu">
                      <a
                        href="javascript:"
                        onClick={() => this.handleOpenModal(false)}
                        className="dropdown-item"
                      >
                        Existing Account
                      </a>
                      <a
                        href="javascript:"
                        onClick={() => this.handleOpenModal(true)}
                        className="dropdown-item"
                      >
                        New Account
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        {/* <div key="new_relationship1"><a href="javascript:void(0);" className="text-right btn btn-primary mb-2" onClick={() => this.handleOpenModal(false)}><i>+</i> Exisiting Account</a></div>
        <div key="new_relationship2"><a href="javascript:void(0);" className="text-right btn btn-primary" onClick={() => this.handleOpenModal(true)}><i>+</i> New Account</a></div> */}
        {relationships === null ? <NoRecords /> : null}
        {relationships !== null && relationships.length
          ? [
              <section key="contact_relationship">
                <div id="inbox-list" className="tableWrap">
                  <div className="tRow tRow--head">
                    <div className="row">
                      {contactRelationPageTableHeaders.map((each) => (
                        <div
                          key={each.label}
                          width={each.width}
                          className={
                            Utilities.sortingIcon(
                              this.state.sortBy,
                              each.value,
                              this.state.sortOrder,
                              'className',
                            ) +
                            (each.className ? each.className : '') +
                            (!each.isSortable ? ' cursor-default' : '')
                          }
                          onClick={() =>
                            each.isSortable
                              ? this.handleSortBy(each.value)
                              : null
                          }
                        >
                          <span className="posRel">
                            {each.label}
                            <i
                              className={Utilities.sortingIcon(
                                this.state.sortBy,
                                each.value,
                                this.state.sortOrder,
                              )}
                            />
                          </span>
                        </div>
                      ))}
                      <div className="col-2" />
                    </div>
                  </div>
                  <div className="tRow tRow--body">
                    {relationships.map((each) => (
                      <div key={each.name} className="row tdBtn">
                        <div
                          className="col-3 tdCol"
                          onClick={() => this.editRelationShip(each)}
                        >
                          <ContactCardParent data={each} forWhat="account" />
                        </div>
                        <div
                          className="col-1 tdCol"
                          onClick={() => this.editRelationShip(each)}
                        >
                          <span>
                            {each.relation !== null ? each.relation : '-'}
                          </span>
                        </div>
                        <div
                          className="col-2 tdCol"
                          onClick={() => this.editRelationShip(each)}
                        >
                          <span>
                            {each.entity_type ? each.entity_type : '-'}
                          </span>
                          <br />
                        </div>
                        <div
                          className="col-2 tdCol"
                          onClick={() => this.editRelationShip(each)}
                        >
                          <span>
                            {each.rating !== null ? (
                              <RatingComponent rating={each.rating} />
                            ) : (
                              '-'
                            )}
                          </span>{' '}
                        </div>
                        <div
                          className="col-1 tdCol text-center"
                          onClick={() => this.editRelationShip(each)}
                        >
                          <span>
                            {each.is_account_owner ? (
                              <i className="icon-tickmark" />
                            ) : null}
                          </span>{' '}
                        </div>
                        <div
                          className="col-1 tdCol text-center"
                          onClick={() => this.editRelationShip(each)}
                        >
                          <span>
                            {each.is_billing_contact ? (
                              <i className="icon-tickmark" />
                            ) : null}
                          </span>{' '}
                        </div>
                        <div className="col-2 tdCol text-right">
                          <TooltipItem
                            position="top"
                            tooltipType="button"
                            id={`edit${each.relation_data.id}`}
                            text="Edit"
                            btnClass="btn-sm btn--onlyicon btn-link tdBtn__hover"
                            clickAction={() => this.editRelationShip(each)}
                            iconn="icon-edit"
                          />
                          <TooltipItem
                            position="top"
                            tooltipType="button"
                            id={`delete${each.relation_data.id}`}
                            text="Delete"
                            btnClass="btn-sm btn--onlyicon btn-link tdBtn__hover"
                            clickAction={() => this.updateRelationship(each)}
                            iconn="icon-delete-small"
                          />
                        </div>
                        {/* <td width="10%"></td> */}
                      </div>
                    ))}
                  </div>
                </div>
                {/* <Pagination
                currentPage={this.state.pagination}
                totalPages={this.state.totalPages}
                totalRecords={this.state.totalRecords}
                handlePageChange={this.handlePageChange}
                offsets={offset[0] + ' to ' + offset[1]}
              /> */}
              </section>,
            ]
          : null}
        {this.state.showModal && !this.state.newRelationShip ? (
          <EditRealtionShip
            with="account"
            handleCloseModal={this.handleCloseModal}
            contact_id={this.props.contactId}
            account_id={this.state.account_id}
            relationship_type={this.state.relationship_type}
            relationship_id={this.state.relationship_id}
            is_account_owner={this.state.is_account_owner}
            is_billing_contact={this.state.is_billing_contact}
            relationshipWith={this.state.relationshipWith}
            existingrelationships={relationships}
          />
        ) : null}
        {this.state.showModal && this.state.newRelationShip ? (
          <Modal
            isOpen
            className="customModal customModal--w990 customModal--nopadding"
          >
            <ModalHeader className="ModalHeader">
              <button
                type="button"
                onClick={this.handleCloseModal}
                className="close"
                aria-label="Close"
              >
                <i className="icon-close2" aria-hidden="true" />
              </button>
            </ModalHeader>
            <ModalBody>
              <div className="modalContent--wrap">
                <div className="modalContent--inner">
                  <NewAccount
                    createRelationship
                    contactId={this.props.contactId}
                    handleCloseModal={this.handleCloseModal}
                  />
                </div>
              </div>
            </ModalBody>
          </Modal>
        ) : null}
        {this.state.showDeleteConfirmation ? (
          <ConfirmationModal
            isOpen
            messageText="Attention: The relationship will be deleted. Proceed?"
            yesBtnText="Proceed"
            noBtnText="Cancel"
            yesBtnClick={this.deleteRelationship}
            noBtnClick={this.handleCloseModal}
          />
        ) : null}
      </div>
    );
  }
}

export default ContactRelationshipPage;
