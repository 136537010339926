/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Skeleton, styled } from '@mui/material';
import { useEffect, useRef } from 'react';
import { MessagesMungedByDay, mungeMessagesByDay } from './message-utils';
import DayOfMessages from './DayOfMessages';
import { MessageBoxContainer } from './SMSMessage/SMSMessageStyles';

interface Props {
  multipleParticipants?: boolean;
  messages?: Message[];
  isInitialLoading?: boolean;
}

const HistoryBox = styled(Box)({
  width: '100%',
  height: '100%',
  display: 'flex',
  paddingTop: '10px',
  flexDirection: 'column',
});

export default function SMSMessageHistory({
  multipleParticipants = false,
  messages,
  isInitialLoading,
}: Props) {
  const messagesRef = useRef<HTMLDivElement>(null);
  const scrollRef = useRef<HTMLDivElement>(null);

  let mungedMessages: MessagesMungedByDay | undefined;
  if (messages) mungedMessages = mungeMessagesByDay(messages);

  useEffect(() => {
    if (mungedMessages) scrollToBottom();

    let images: NodeListOf<HTMLImageElement>;

    if (messagesRef.current) {
      // Add event listener for images to wait for them to load
      images = messagesRef.current.querySelectorAll('.sms-image');

      images.forEach((img) => {
        img.addEventListener('load', handleImageLoad);
      });
    }

    // Cleanup the event listeners when component unmounts
    return () => {
      if (images)
        images.forEach((img) => {
          img.removeEventListener('load', handleImageLoad);
        });
    };
  }, [mungedMessages]);

  const scrollToBottom = () => {
    if (scrollRef.current) scrollRef.current.scrollIntoView();
  };

  const handleImageLoad = () => {
    scrollToBottom();
  };

  return (
    <HistoryBox aria-label="HistoryBox">
      {isInitialLoading ? (
        <Box
          marginTop="auto"
          width="100%"
          display="flex"
          flexDirection="column"
        >
          <Box alignSelf="center">
            <Skeleton width={100} height="1.5rem" variant="text" />
          </Box>

          <MessageBoxContainer isIncoming alignStartOrEnd="flex-start">
            <Skeleton width={40} height={40} variant="circular" />
            <Skeleton
              width={370}
              height={70}
              variant="rounded"
              sx={{ borderRadius: 20 }}
            />
          </MessageBoxContainer>
          <MessageBoxContainer isIncoming={false} alignStartOrEnd="flex-end">
            <Skeleton width={40} height={40} variant="circular" />
            <Skeleton
              width={380}
              height={70}
              variant="rounded"
              sx={{ borderRadius: 20 }}
            />
          </MessageBoxContainer>
        </Box>
      ) : (
        <Box ref={messagesRef}>
          {mungedMessages &&
            Object.entries(mungedMessages).map(
              ([date, messagesForDate], index) => (
                <DayOfMessages
                  index={index}
                  key={date}
                  date={date}
                  messages={messagesForDate}
                  multipleParticipants={multipleParticipants}
                />
              ),
            )}
          <div ref={scrollRef} />
        </Box>
      )}
    </HistoryBox>
  );
}
