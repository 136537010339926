// TO-DO: Replace this wwith redux toolkit
import create from 'zustand';
import Axios from 'axios';
import {
  GET_ACCOUNTS_FOR_INVOICE,
  GET_CONTACTS_FOR_INVOICE,
} from 'constants/constants';

const useAccountsandContactsStore = create((set) => ({
  accounts: [],
  contacts: [],
  fetchAccounts: async () => {
    const response = await Axios.get(GET_ACCOUNTS_FOR_INVOICE);
    set({ accounts: response.data.data });
  },
  fetchContacts: async () => {
    const response = await Axios.get(GET_CONTACTS_FOR_INVOICE);
    set({ contacts: response.data.data });
  },
}));

export default useAccountsandContactsStore;
