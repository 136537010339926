/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import styled from '@emotion/styled';
import EmailActionsDropdown from 'components/Email/components/EmailActionsDropdown';
import DisplaySnippet from 'common/DisplaySnippet';
import Checkbox from 'common/Checkbox';
import { timeAgo } from '../EmailUtilities';
import useWhichSection from './useWhichSection';

const EmailBlockList = styled.div`
  background-color: ${({ isActive }) => (isActive ? '#f6f7f9' : '#ffffff')};
  padding-right: 15px;
  .EmOne__Border {
    cursor: pointer;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #dee2e6;
    &:hover {
      .tglDrop {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  .EmOn__Flex {
    display: flex;
    &--ContentEnd {
      justify-content: end;
    }
  }
  .EmOne__Status {
    background: #eb2aac;
    min-width: 8px;
    max-width: 8px;
    height: 8px;
    display: inline-block;
    margin-top: 0.5rem;
    border-radius: 0.25rem;
    visibility: ${({ isRead }) => (isRead ? 'visible' : 'hidden')};
  }
  .EmOne__Checkbox {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    margin-left: 0;
  }
  .EmOne__RightBlk {
    padding-left: 0.5rem;
    padding-right: 0;
    flex-grow: 1;
    position: relative;
  }
  .EmOne__Flow {
    display: flex;
    &__Email {
      span {
        color: #212b36;
        font-weight: 600;
        word-break: break-all;
      }
    }
    &__Right {
      text-align: right;
    }
  }
  .EmOne__Flow__Right {
    flex-grow: 1;
    max-width: 100%;
  }
  .EmOne__Actions {
    .Emdate {
      color: #919eab;
      font-size: 0.95rem;
    }
    .tglDrop {
      position: absolute !important;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      background-color: #fff;
      z-index: 9;
      padding: 15px 0 15px 15px;
      opacity: 0;
      visibility: hidden;
      -webkit-transition: all ease-in-out 350ms;
      -moz-transition: all ease-in-out 350ms;
      transition: all ease-in-out 350ms;
    }
    .ctaClass {
      padding: 0;
      margin-left: 7px;
      .button-icon {
        border: 1px solid #e0e1e2;
        cursor: pointer;
        padding: 0;
        line-height: 1;
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          width: 12px;
        }
      }
    }
  }
`;

const DraftLabel = styled.span`
  color: #ff7094 !important;
  margin-left: 0.25em;
`;

const EmailBlock = ({
  email,
  index,
  id,
  from,
  toggleSelect,
  composeEmail,
  filter,
  connectedApp,
  refreshPage,
  unreadEmail,
  nextThread,
  viewMode,
  emailDetailMode,
  handleEmailDetailMode,
  setLoading,
}) => {
  const isActive = id && id.toString() === email.id.toString() ? 'active' : '';
  const isRead = !!(email.is_read !== undefined && !email.is_read);
  const { inLinkedSection } = useWhichSection();
  const fromLinked =
    (from && from === 'linkedEmailAccConTask') || inLinkedSection;
  const showUnreadOption = !fromLinked;
  const isInInbox = email.inbound_status === 'primary';

  const contacts = isInInbox ? [email.fromContact] : email.toContacts;
  return (
    <EmailBlockList
      key={email.msg_id}
      isActive={isActive}
      isRead={isRead}
      className="EmOne"
      onClick={() => composeEmail('', email, index)}
      data-testid={`email_${+index}`}
    >
      <div className="EmOne__Border">
        <div className="EmOn__Flex">
          <span className="EmOne__Status" />
          {!fromLinked ? (
            <div
              className="EmOne__Checkbox"
              onClick={(e) => e.stopPropagation()}
            >
              <Checkbox
                checked={email.isSelected || false}
                onChange={() => toggleSelect(index)}
                color="info"
              />
            </div>
          ) : null}
          <div className="EmOne__RightBlk">
            <div className="EmOne__Flow">
              <div className="EmOne__Flow__Email">
                <span>
                  {contacts
                    .map((contact) => contact.name || contact.email)
                    .join(', ')}
                  {email.is_draft && `,`}
                </span>
                {email.is_draft && <DraftLabel>Draft</DraftLabel>}
              </div>
              <div className="EmOne__Flow__Right">
                <div className="EmOn__Flex EmOn__Flex--ContentEnd EmOne__Actions">
                  <span className="Emdate">{timeAgo(email.updatedAt)}</span>
                  {filter !== 'draft' ? (
                    <div onClick={(e) => e.stopPropagation()}>
                      <EmailActionsDropdown
                        emailDetails={email}
                        drpdwnClass="em_left_dropdown"
                        connectedApp={connectedApp}
                        refreshPage={refreshPage}
                        viewContextFrom="listemails"
                        composeEmail={composeEmail}
                        unreadEmail={unreadEmail}
                        showUnreadOption={showUnreadOption}
                        nextThread={nextThread}
                        viewMode={viewMode}
                        from={from}
                        selectedEmailId={id}
                        emailDetailMode={emailDetailMode}
                        handleEmailDetailMode={handleEmailDetailMode}
                        setLoading={setLoading}
                      />
                    </div>
                  ) : (
                    <div style={{ width: '22px' }} />
                  )}
                </div>
              </div>
            </div>
            {/* TO DO find better solution for this */}
            <div className="em_sub my-0 posRel">
              <span
                className={`em_subText text-body${
                  !email.is_read ? ' font-weight-bold' : ''
                }`}
              >
                <DisplaySnippet snippet={email.subject} type="primary" />
              </span>
              {email.attachments && email.attachments.length ? (
                <span className="em_attach text-dark ml-auto">
                  <i className="icon-attachment" />
                </span>
              ) : null}
            </div>
            <div className="em_sContent">
              <DisplaySnippet snippet={email.snippet} />
            </div>
          </div>
        </div>
      </div>
    </EmailBlockList>
  );
};
export default EmailBlock;
