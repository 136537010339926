/* eslint-disable import/prefer-default-export */
export const getButtonSize = (size) => {
  let height = '48px';
  if (size === 'small') {
    height = '38px';
  }
  if (size === 'xsmall') {
    height = '0';
  }
  return { height };
};
