import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import Axios from 'axios';
import { AlertMessage } from 'utilities/utils';
import { CREATE_NEW_EDOC_REQUEST } from 'constants/constants';

export default class EsignCancellationForm extends Component {
  constructor(props) {
    super(props);

    this.state = { cancellationReason: '' };
  }

  handleInput = (input) => {
    this.setState({ cancellationReason: input });
  };

  deleteAgreement = () => {
    const { agreementId } = this.props;
    const { cancellationReason } = this.state;
    this.setState({ loading: true });
    let url = `${CREATE_NEW_EDOC_REQUEST}/${agreementId}`;
    if (
      cancellationReason &&
      cancellationReason !== null &&
      cancellationReason.length
    ) {
      url = `${CREATE_NEW_EDOC_REQUEST}/${agreementId}?reason=${cancellationReason}`;
    }
    Axios.delete(url)
      .then((res) => {
        if (res.data && res.data.message) {
          AlertMessage('success', res.data.message, 3000);
        } else {
          AlertMessage('error', 'Something went wrong', 3000);
        }
        this.props.noBtnClick(true);
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  };

  render() {
    const { noBtnClick, hideReasonInput } = this.props;
    const { cancellationReason } = this.state;
    return (
      <Modal
        isOpen
        className="customModal customModal--center customModal--alert customModal--w600 customModal--nopadding"
      >
        <ModalHeader className="ModalHeader">
          <button
            type="button"
            onClick={() => noBtnClick()}
            className="close"
            aria-label="Close"
          >
            <i className="icon-close2" aria-hidden="true" />
          </button>
        </ModalHeader>
        <ModalBody className="modalContent">
          {this.state.loading ? <div id="loading" /> : null}
          <div className="modalContent--inner">
            <div className="modalContent--header text-large">
              <h4 className="modalContent--header__heading">
                Cancel Agreement
              </h4>
            </div>
            {!hideReasonInput && (
              <div className="form-group w-100">
                <label htmlFor="tagslabel">
                  Enter the reason for cancelling agreement
                </label>
                <div className="labelValue">
                  <textarea
                    rows="3"
                    cols="50"
                    name="edoc cancellation reason"
                    value={cancellationReason}
                    onChange={(e) => this.handleInput(e.target.value)}
                    className="form-control"
                  />
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-12 justify-content-end formbtn justify-content-end">
                <div className="btn-wrap btn--leftspace">
                  <Button
                    className="btn btn-outline-light"
                    onClick={() => noBtnClick()}
                  >
                    No
                  </Button>
                  <Button
                    className="btn btn-primary"
                    onClick={this.deleteAgreement}
                  >
                    Yes
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}
