const initialState = {
  accountsFilterData: [],
  contactsFilterData: [],
  employeeFilterData: [],
  employeeandcontactFilterData: [],
};

// eslint-disable-next-line default-param-last
export default function filterDataReducer(state = initialState, action) {
  switch (action.type) {
    case 'STORE_ACCOUNT_FILTER_DATA':
      return {
        ...state,
        accountsFilterData: action.data,
      };

    case 'STORE_CONTACT_FILTER_DATA':
      return {
        ...state,
        contactsFilterData: action.data,
      };

    case 'STORE_EMPLOYEE_FILTER_DATA':
      return {
        ...state,
        employeeFilterData: action.data,
      };

    case 'STORE_EMPLOYEE_AND_CONTACT_FILTER_DATA':
      return {
        ...state,
        employeeandcontactFilterData: action.data,
      };

    default:
      return state;
  }
}
