import axios from 'axios';
import { updateListData } from 'utilities/utils';
import * as Config from 'constants/constants';

export const fetchAll = (data, page) => ({
  type: 'FETCH_SUCCESS',
  data,
  page,
});

export const fetchDetail = (data) => ({
  type: 'FETCH_TASK_DETAIL',
  data,
});

export const handleApiUrl = (data) => ({
  type: 'TASK_URL',
  data,
});

export const appendNewTask = (data) => ({
  type: 'APPEND_NEW_TASK',
  data,
});

export const receivedNotification = (data) => ({
  type: 'RECEIVED_TASKNOTIFICATION',
  data,
});

export const reassignCheckbox = (data) => ({
  type: 'BULK_REASSIGN_CHECKBOX',
  data,
});

export const handleTaskStatus = (data) => ({
  type: 'TASK_STATUS',
  data,
});

export const handleFilters = (data) => ({
  type: 'HANDLE_TASKS_FILTERS',
  data,
});

export const updateData = (
  listData,
  selectedData,
  taskStatus,
  from = 'tasks',
) => {
  const templates = from === 'account' ? 'data' : 'templates';
  if (selectedData.length) {
    listData[templates][0][taskStatus].map((each) => {
      const eachone = each;
      eachone.checked = false;
      selectedData.map((e) => {
        if (e.id === each.id) {
          eachone.checked = true;
        }
        return e;
      });
      return eachone;
    });
  }
  return listData;
};

export function fetchTasks(
  component,
  page,
  sort,
  field,
  api = '',
  noload = false,
  searchKey = '',
  from = '',
) {
  return (dispatch) => {
    let requestUrl;
    const isBulkReassignment = from === 'bulk' ? '&bulk_reassignment=true' : '';

    requestUrl = `${Config.TASK_API_PATH}?page=${page}&sort_by=${sort}&field=${field}${api}${isBulkReassignment}`;

    if (searchKey && searchKey !== '') {
      requestUrl = `${requestUrl}&keyword=${searchKey}`;
    }
    const request = axios.get(requestUrl);

    if (!noload) {
      component.setState({ loading: true });
    }

    request.then((response) => {
      if (response.data.status === 200) {
        component.setState({ loading: false, mount: true });
        if (from === 'bulk') {
          const listData = response.data.templates[0].Open;
          const { selectedData } = component.state;
          updateListData(listData, selectedData);
          dispatch(fetchAll(response.data, page));
          component.setState({ selectedAll: false }, () => {
            component.areAllContactsSelected();
          });
        } else {
          let list = response.data;
          const { selectedCheckbox } = component.state;
          const { taskStatus } = component.props;
          list = updateData(list, selectedCheckbox, taskStatus);
          dispatch(fetchAll(list, page));
          setTimeout(() => {
            component.areAllSelected();
          }, 100); // written here again as need to be executed after dispatch.
        }
      }
    });
  };
}

export function fetchDashboardTasks(component, page, sort, field) {
  return (dispatch) => {
    component.setState({ taskLoading: true });
    const request = axios.get(
      `${Config.TASK_API_PATH}?page=${page}&sort_by=${sort}&field=${field}&dashboard=true`,
    );
    request.then((response) => {
      component.setState({ taskLoading: false });
      if (response.data.status === 200) {
        dispatch(fetchAll(response.data, page));
      }
    });
  };
}

export function fetchContactTasks(
  component,
  id,
  searchKey = '',
  page = 1,
  noload = false,
) {
  return (dispatch) => {
    const request = axios.get(
      `${Config.GET_TASKS_OF_CONTACT}/${id}?keyword=${searchKey}`,
    );
    if (!noload) {
      component.setState({ loading: true });
    }
    request.then((response) => {
      component.setState({ loading: false });
      if (response.data.status === 200) {
        component.setState({ pagination: page - 1 });
        let list = response.data;
        const { selectedCheckbox } = component.state;
        const { taskStatus } = component.props;
        list = updateData(list, selectedCheckbox, taskStatus);
        dispatch(fetchAll(list, page));
        setTimeout(() => {
          component.areAllSelected();
        }, 100); // written here again as need to be executed after dispatch.
      }
    });
  };
}

export function fetchAccountTasks(
  component,
  id,
  searchKey = '',
  page = 1,
  noload = false,
) {
  return (dispatch) => {
    const request = axios.get(
      `${Config.GET_TASKS_OF_ACCOUNT}/${id}?keyword=${searchKey}`,
    );
    if (!noload) {
      component.setState({ loading: true });
    }
    request.then((response) => {
      component.setState({ loading: false });
      if (response.data.status === 200) {
        component.setState({ pagination: page - 1 });
        let list = response.data;
        const { selectedCheckbox } = component.state;
        const { taskStatus } = component.props;
        list = updateData(list, selectedCheckbox, taskStatus, 'account');
        dispatch(fetchAll(list, page));
        setTimeout(() => {
          component.areAllSelected();
        }, 100); // written here again as need to be executed after dispatch.
      }
    });
  };
}

export function fetchtaskDetail(id, component, load = true, fro = '') {
  return (dispatch) => {
    const request = axios.get(`${Config.TASK_API_PATH}/${id}`);
    component.setState({ loading: load });
    request.then((response) => {
      component.setState({ loading: false });
      if (
        response.data.status === 422 &&
        response.data.message === 'No Task found'
      ) {
        component.setState({ noTask: true });
      }
      if (response.status === 200 && response.data.status !== 422) {
        const resData = response.data;
        dispatch(fetchDetail(resData));
        if (resData.task_type_key === 'edoc' && component.state.isEdit) {
          const preff = [{ key: 'edoc', value: 'edoc', label: 'eDoc' }];
          component.setState({ taskTypes: preff });
        }
        if (fro === 'newtask') {
          component.changeTaskType(resData.task_type_key);
          component.setState({ on_schedule: resData.on_schedule });
        }
        if (
          resData.documents &&
          resData.documents.length > 0 &&
          resData.documents !== undefined
        ) {
          component.setState({ documents: resData.documents });
        } else {
          component.setState({ documents: [] });
        }
        document.title = `Task - ${resData.subject}`;
        component.setState({
          comments: resData.comments,
          commentsActivity: resData.my_activity,
          commentsHistory: resData.history,
          taskStatus: resData.status,
        });
        if (fro === 'newtask') {
          component.setState({ changedOnce: true });
        }
      }
    });
  };
}
