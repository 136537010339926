import HeroImage from 'images/OrganizerTemplates/organizer-templates_hero_simple.png';
import { Box, Divider, Stack, SvgIcon, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { iconMap } from 'components/Sidebar/elements/MenuItemIcon/icons';
import { OrganizerTemplatesHeader } from 'components/OrganizerTemplates/components/OrganizerTemplatesHeader/OrganizerTemplatesHeader';

export type SimpleOrganizerProps = {
  current: boolean;
};

export const SimpleOrganizer = ({ current }: SimpleOrganizerProps) => (
  <Stack gap="1rem">
    <OrganizerTemplatesHeader current={current}>
      2024 Simple Tax Organizer
    </OrganizerTemplatesHeader>

    <img src={HeroImage} alt="Standard Organizer" />
    <Typography>
      Designed as a streamlined, document-only request list, this Simple Tax
      Organizer Template gathers just the essential documents based on proforma
      details from prior returns. It’s a quick, no-fuss way for clients to know
      exactly what they need to submit—nothing more, nothing less.
    </Typography>
    <Typography>This template is ideal for:</Typography>
    <Box>
      <Typography variant="h6" fontWeight={600}>
        Clients whose returns rely exclusively on IRS documents
      </Typography>
      <Typography>like W-2s, 1099s, and other standard forms.</Typography>
    </Box>
    <Box>
      <Typography variant="h6" fontWeight={600}>
        Clients who are likely to use the Standard Deduction
      </Typography>
      <Typography>instead of Itemizing Deductions.</Typography>
    </Box>
    <Box>
      <Typography variant="h6" fontWeight={600}>
        Tech-capable clients
      </Typography>
      <Typography>
        who may find complex tax documentation overwhelming.
      </Typography>
    </Box>
    <Box>
      <Typography variant="h6" fontWeight={600}>
        Busy clients
      </Typography>
      <Typography>
        who are time-constrained or prone to procrastination, requiring a clear
        and efficient process.
      </Typography>
    </Box>
    <Box>
      <Typography variant="h6" fontWeight={600}>
        Clients who would benefit from a separate, customized Questionnaire
      </Typography>
      <Typography>from the Firm.</Typography>
    </Box>
    <Box>
      <Typography variant="h6" fontWeight={600}>
        Clients with typical Credits & Deductions
      </Typography>
      <Typography>
        documented on forms such as a childcare expenses statement from a care
        provider, Education Expenses (1098-T), or Student Loan Interest
        (1098-E).
      </Typography>
    </Box>
    <Typography>
      Focusing on simplicity, the organizer skips a Questionnaire, so firms can
      send a tailored Questionnaire separately if needed. Many firms find this
      approach useful, as it enables them to collect key client information
      earlier (e.g., November/December) and send the Simple Organizer with
      document upload prompts closer to tax season (e.g., mid-January).
    </Typography>
    <Divider />
    <Typography variant="h4">
      <SvgIcon>
        <FontAwesomeIcon icon={iconMap['check-list']} />
      </SvgIcon>{' '}
      Key Sections in the Standard Organizer
    </Typography>
    <Box>
      <Typography variant="h6" fontWeight={600}>
        Documents
      </Typography>
      <Typography>
        Requests recurring source documents used to prepare the prior year
        return
      </Typography>
    </Box>
  </Stack>
);
