import React, { Component } from 'react';
import Axios from 'axios';
import { connect } from 'react-redux';
import { withRouter } from 'utilities/withRouterWrapper';
import {
  GET_TASK_ACCOUNTS,
  SEARCH_TASK_ACCOUNTS,
  GET_ACCOUNTS_FOR_LOGINED_CONTACT,
} from 'constants/constants';
import { FilterComponent } from 'common/MessageFilters';
import { saveAccountFilterData } from 'actions/FilterDataAction';
import { isUserEmployee } from 'utilities/utils';

class AccountFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
    };
  }

  componentDidMount() {
    const { contactId, saveAccountFilterData: propsSaveAccountFilterData } =
      this.props;

    if (isUserEmployee()) {
      const getTaskAccountsURL = contactId
        ? `${GET_TASK_ACCOUNTS}?contact_id=${contactId}`
        : GET_TASK_ACCOUNTS;
      Axios.get(getTaskAccountsURL).then((res) => {
        if (res.data && res.data.accounts) {
          this.setState({
            data: res.data.accounts,
            initialData: res.data.accounts,
          });
          propsSaveAccountFilterData(res.data.accounts);
        }
      });
    } else {
      Axios.get(GET_ACCOUNTS_FOR_LOGINED_CONTACT).then((res) => {
        if (res.data && res.data.data) {
          this.setState({
            data: res.data.data,
            initialData: res.data.data,
          });
          propsSaveAccountFilterData(res.data);
        }
      });
    }
  }

  handleSearch = (input) => {
    const { contactId } = this.props;
    this.setState(
      {
        searchValue: input ? input.target.value : '',
      },
      () => {
        clearTimeout(this.typingTime);
        const { searchValue } = this.state;
        if (searchValue && searchValue !== null && searchValue.length) {
          const searchTaskAccountsURL = contactId
            ? `${SEARCH_TASK_ACCOUNTS}/?keyword=${searchValue}&contact_id=${contactId}`
            : `${SEARCH_TASK_ACCOUNTS}/?keyword=${searchValue}`;
          this.typingTime = setTimeout(() => {
            Axios.get(searchTaskAccountsURL).then((response) => {
              if (response.status === 200) {
                this.setState({
                  data: response.data,
                });
              } else {
                this.setState({});
              }
            });
          }, 500);
        } else {
          const { initialData } = this.state;
          this.setState({
            data: initialData,
          });
        }
      },
    );
  };

  render() {
    const {
      filterName,
      filterSelected,
      isActive,
      showFilterOptions,
      setFilterInFocus,
      testid,
    } = this.props;
    const { data, searchValue } = this.state;
    return (
      <FilterComponent
        filterList={data}
        filterName={filterName || 'ACCOUNT'}
        recipientSelected={filterSelected}
        isActive={isActive}
        recipientInputChange={this.handleSearch}
        searchValue={searchValue}
        clearSearch={() => this.handleSearch('')}
        showFilterOptions={showFilterOptions}
        setFilterInFocus={setFilterInFocus}
        testid={testid}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  accountsFilterData: state.filterDataReducer.accountsFilterData,
});

const mapDispatchToProps = (dispatch) => ({
  saveAccountFilterData: (data) => dispatch(saveAccountFilterData(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AccountFilter),
);
