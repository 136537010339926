import React from 'react';
import styled from '@emotion/styled';

import NotesButton from 'common/Button/Button';
import { BUTTON_ICON_LOCATION_BACK } from 'constants/constants';
import DiscardEmail from 'components/Email/components/icons/DiscardEmail';

const StyledButtonGroup = styled.div`
  display: flex;
  .Button__Spacing {
    margin-left: 5px;
    margin-right: 5px;
  }
`;

export function CreateNotesFromEmailsFooter({
  handleSubmit,
  gettingEmailAttachments,
  handleCancel,
}) {
  return (
    <div className="col-12 form-group formbtn justify-content-between">
      <NotesButton
        size="small"
        onClick={handleSubmit}
        label="Create Note"
        icon="plus"
        iconLocation={BUTTON_ICON_LOCATION_BACK}
        disabled={gettingEmailAttachments}
      />
      <DiscardEmail handleDiscardButton={handleCancel} />
    </div>
  );
}

export function CreateNotesFooterButton({
  handleCancel,
  gettingEmailAttachments,
  isEdit,
  handleSubmit,
}) {
  return (
    <StyledButtonGroup>
      <div className="Button__Spacing">
        <NotesButton
          variant="secondary"
          label="Cancel"
          onClick={handleCancel}
          testid="notes__cancel_note"
        />
      </div>
      <div className="Button__Spacing">
        <NotesButton
          disabled={gettingEmailAttachments}
          label={isEdit ? 'Update Note' : 'Create Note'}
          onClick={handleSubmit}
          testid="notes__save_note"
        />
      </div>
    </StyledButtonGroup>
  );
}
