/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// TODO: Keep the comments for future use
import React from 'react';
import ListItemIcon from '@mui/material/ListItemIcon';
import Badge from '@mui/material/Badge';
import { MenuItemIcon } from './MenuItemIcon';

export default function InboxMenuItemCount({
  menuItem,
  messageCount,
}: {
  menuItem: MenuItem;
  messageCount: number;
}) {
  return (
    <Badge badgeContent={messageCount}>
      <ListItemIcon>
        <MenuItemIcon iconClass={menuItem.icon_class} />
      </ListItemIcon>
    </Badge>
  );
}
