import { debounce } from 'lodash';
import React, { useState } from 'react';
import styled from '@emotion/styled';
import ClearButton from 'common/ClearButton';

const StyledDiv = styled.div`
  margin-right: 20px;
`;

const BillingFilterSearch = (props) => {
  const { onTextSearch, textSearch, clearSearch, activeTab } = props;
  const [textInput, setTextInput] = useState(clearSearch ? '' : textSearch);

  const delayedSearch = React.useCallback(
    debounce((delayedText) => onTextSearch(delayedText), 300),
    [activeTab],
  );
  const handleSearch = (e) => {
    e.stopPropagation();
    setTextInput(e.target.value);
    delayedSearch(e.target.value);
  };

  const onClearSearch = () => {
    setTextInput('');
    onTextSearch('');
  };

  return (
    <StyledDiv className="BillingFilterSearch">
      <div className="form-group has-search">
        <span className="icon-zoom2 form-control-feedback" />
        <span className="clearable-search">
          <input
            type="text"
            className="form-control text-search"
            placeholder="Search"
            id="message-search"
            value={textInput}
            onChange={handleSearch}
            autoComplete="off"
          />
          <ClearButton
            onClickClear={onClearSearch}
            className="icon-small"
            hidden={!textSearch}
          />
        </span>
      </div>
    </StyledDiv>
  );
};

export default BillingFilterSearch;
