/* eslint-disable */
import React, { Component } from 'react';
import Axios from 'axios';
import { connect } from 'react-redux';
import { GET_OWNERS, GET_EMP_AND_CONTACT_API_PATH, SEARCH_TASK_CONTACTS } from 'constants/constants';
import { FilterComponent } from 'common/MessageFilters';
import {
  saveContactFilterData,
  saveEmployeeFilterData,
  saveEmployeeAndContactFilterData,
} from 'actions/FilterDataAction';

class EmployeeAndContactFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
    };
  }

  componentDidMount() {
    const { filterName } = this.props;
    if (filterName && (filterName.toLowerCase() === 'requestor' || filterName.toLowerCase() === 'owner')) {
      this.getEmployeeData();
    } else if (filterName && (filterName.toLowerCase() === 'signers' || filterName.toLowerCase() === 'assigned to')) {
      this.getEmployeeandContactData();
    } else if (filterName && filterName.toLowerCase() === 'contact') {
      this.getContactsData();
    }
  }

  getEmployeeData = () => {
    const { employeeFilterData } = this.props;
    if (employeeFilterData && employeeFilterData.length) {
      this.setState({
        data: employeeFilterData,
        initialData: employeeFilterData,
      });
    } else {
      Axios.get(GET_OWNERS).then((res) => {
        if (res.data && res.data.data) {
          this.setState({
            data: res.data.data,
            initialData: res.data.data,
          });
          this.props.saveEmployeeFilterData(res.data.data);
        }
      });
    }
  };

  getEmployeeandContactData = () => {
    const { employeeandcontactFilterData } = this.props;
    if (employeeandcontactFilterData && employeeandcontactFilterData.length) {
      this.setState({
        data: employeeandcontactFilterData,
        initialData: employeeandcontactFilterData,
      });
    } else {
      Axios.post(GET_EMP_AND_CONTACT_API_PATH, { limit: true }).then((res) => {
        if (res.data && res.data.length) {
          this.setState({
            data: res.data,
            initialData: res.data,
          });
          this.props.saveEmployeeAndContactFilterData(res.data);
        }
      });
    }
  };

  getContactsData = () => {
    const { contactsFilterData } = this.props;
    if (contactsFilterData && contactsFilterData.length) {
      this.setState({
        data: contactsFilterData,
        initialData: contactsFilterData,
      });
    } else {
      Axios.post(GET_EMP_AND_CONTACT_API_PATH, {
        limit: true,
        type: 'request_info',
      }).then((res) => {
        if (res.data && res.data.length) {
          this.setState({
            data: res.data,
            initialData: res.data,
          });
          this.props.saveContactFilterData(res.data);
        }
      });
    }
  };

  handleSearch = (input) => {
    this.setState(
      {
        searchValue: input ? input.target.value : '',
        loading: true,
      },
      () => {
        clearTimeout(this.typingTime);
        const { searchValue } = this.state;
        const { filterName } = this.props;
        if (searchValue && searchValue !== null && searchValue.length) {
          if (filterName && (filterName.toLowerCase() === 'requestor' || filterName.toLowerCase() === 'owner')) {
            this.handleEmployeeSearch();
          } else if (
            filterName &&
            (filterName.toLowerCase() === 'signers' || filterName.toLowerCase() === 'assigned to')
          ) {
            this.handelEmployeeContactSearch();
          }
        } else {
          this.setState({
            data: this.state.initialData,
            loading: false,
          });
        }
      },
    );
  };

  handleEmployeeSearch = () => {
    const { searchValue, initialData } = this.state;
    const filterData = initialData.filter(
      (e) => e.email.includes(searchValue.toLowerCase()) || e.label.toLowerCase().includes(searchValue.toLowerCase()),
    );
    this.setState({
      data: filterData,
    });
  };

  handelEmployeeContactSearch = () => {
    const { searchValue } = this.state;
    const { type } = this.props;
    clearTimeout(this.typingTime);
    this.typingTime = setTimeout(() => {
      Axios.get(
        SEARCH_TASK_CONTACTS +
          '/?keyword=' +
          searchValue +
          '&message=' +
          (type && type.toLowerCase() === 'employeeandcontact'),
      ).then((res) => {
        if (res.data && res.data.length) {
          this.setState({
            data: res.data,
          });
        }
      });
    }, 500);
  };

  render() {
    const { data, searchValue } = this.state;
    return (
      <FilterComponent
        filterList={data}
        filterName={this.props.filterName || 'REQUESTOR'}
        recipientSelected={this.props.filterSelected}
        isActive={this.props.isActive}
        recipientInputChange={this.handleSearch}
        searchValue={searchValue}
        disabled={this.props.disabled}
        clearSearch={() => this.handleSearch('')}
        showFilterOptions={this.props.showFilterOptions}
        setFilterInFocus={this.props.setFilterInFocus}
      />
    );
  }
}

const mapStateToProps = (state) => {
  const { contactsFilterData, employeeFilterData, employeeandcontactFilterData } = state.filterDataReducer;
  return {
    contactsFilterData,
    employeeFilterData,
    employeeandcontactFilterData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  saveEmployeeFilterData: (data) => dispatch(saveEmployeeFilterData(data)),
  saveContactFilterData: (data) => dispatch(saveContactFilterData(data)),
  saveEmployeeAndContactFilterData: (data) => dispatch(saveEmployeeAndContactFilterData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeAndContactFilter);
