import moment from 'moment';
import * as RegExp from 'utilities/regularexpressions';
import {
  captalizeText,
  labelNamebyValue,
  getFirmType,
  FORMATPHONENUMBER,
} from 'utilities/utils';
import { today } from './TempDateHelpers';

const isValidDate = (inputDate) => inputDate instanceof Date;
const currentTime = new Date().getTime();

export const accountAddressFieldNames = {
  addressLine1Name: 'address_line_1',
  addressLine2Name: 'address_line_2',
  cityName: 'address_city',
  stateName: 'address_state',
  countryName: 'address_country',
  zipName: 'address_zip',
};

export const spouseAddressFieldNames = {
  addressLine1Name: 'in_sps_address_line_1',
  addressLine2Name: 'in_sps_address_line_2',
  cityName: 'in_sps_address_city',
  stateName: 'in_sps_address_state',
  countryName: 'in_sps_address_country',
  zipName: 'in_sps_address_zip',
};

export const individualBankAccount = {
  bankName: 'in_bank_name',
  routingNumber: 'in_bank_routing_number',
  accountNumber: 'in_bank_account_number',
  accountType: 'in_bank_account_type',
  bankWebsite: 'in_bank_website',
  bankNotes: 'in_notes',
  autoPayment: 'in_ach_is_autopay_confirmed',
};

export const coBankAccount = {
  bankName: 'bank_name',
  routingNumber: 'routing_number',
  accountNumber: 'encryptedAccountNumber',
  accountType: 'account_type',
  bankWebsite: 'web_site',
  bankNotes: 'notes',
  autoPayment: 'is_ach_autopay',
};

export const inviteAddressFieldNames = {
  addressLine1Name: 'address_line1',
  addressLine2Name: 'address_line2',
  cityName: 'city',
  stateName: 'state',
  countryName: 'country',
  zipName: 'zip',
};

export const months = [
  {
    value: 0,
    label: 'january',
  },
  {
    value: 1,
    label: 'february',
  },
  {
    value: 2,
    label: 'march',
  },
  {
    value: 3,
    label: 'april',
  },
  {
    value: 4,
    label: 'may',
  },
  {
    value: 5,
    label: 'june',
  },
  {
    value: 6,
    label: 'july',
  },
  {
    value: 7,
    label: 'august',
  },
  {
    value: 8,
    label: 'september',
  },
  {
    value: 9,
    label: 'october',
  },
  {
    value: 10,
    label: 'november',
  },
  {
    value: 11,
    label: 'december',
  },
];

export const monthsWithShortNames = [
  {
    value: 1,
    label: 'Jan',
  },
  {
    value: 2,
    label: 'Feb',
  },
  {
    value: 3,
    label: 'Mar',
  },
  {
    value: 4,
    label: 'Apr',
  },
  {
    value: 5,
    label: 'May',
  },
  {
    value: 6,
    label: 'Jun',
  },
  {
    value: 7,
    label: 'Jul',
  },
  {
    value: 8,
    label: 'Aug',
  },
  {
    value: 9,
    label: 'Sep',
  },
  {
    value: 10,
    label: 'Oct',
  },
  {
    value: 11,
    label: 'Nov',
  },
  {
    value: 12,
    label: 'Dec',
  },
];

export const validateIndividualAccountOnBlur = (values) => {
  const errors = {};
  if (
    values.taxpayer_first_name !== undefined &&
    values.taxpayer_first_name !== null &&
    values.taxpayer_first_name.length &&
    !RegExp.NAME.test(values.taxpayer_first_name)
  ) {
    errors.taxpayer_first_name = 'Invalid name';
  }
  if (
    values.taxpayer_last_name !== undefined &&
    values.taxpayer_last_name !== null &&
    values.taxpayer_last_name.length &&
    !RegExp.NAME.test(values.taxpayer_last_name)
  ) {
    errors.taxpayer_last_name = 'Invalid name';
  }
  if (
    values.taxpayer_middle_name !== undefined &&
    values.taxpayer_middle_name !== null &&
    values.taxpayer_middle_name.length &&
    !RegExp.NAME.test(values.taxpayer_middle_name)
  ) {
    errors.taxpayer_middle_name = 'Invalid name';
  }
  if (
    values.encryptedInSelfSsn !== undefined &&
    values.encryptedInSelfSsn !== null &&
    values.encryptedInSelfSsn.length &&
    RegExp.REMOVESPECIALCHAR(values.encryptedInSelfSsn).length !== 9
  ) {
    errors.encryptedInSelfSsn = 'Invalid SSN';
  }
  if (
    values.in_sps_first_name !== undefined &&
    values.in_sps_first_name !== null &&
    values.in_sps_first_name.length &&
    !RegExp.NAME.test(values.in_sps_first_name)
  ) {
    errors.in_sps_first_name = 'Invalid name';
  }
  if (
    values.in_sps_middle_name !== undefined &&
    values.in_sps_middle_name !== null &&
    values.in_sps_middle_name.length &&
    !RegExp.NAME.test(values.in_sps_middle_name)
  ) {
    errors.in_sps_middle_name = 'Invalid name';
  }
  if (
    values.in_sps_last_name !== undefined &&
    values.in_sps_last_name !== null &&
    values.in_sps_last_name.length &&
    !RegExp.NAME.test(values.in_sps_last_name)
  ) {
    errors.in_sps_last_name = 'Invalid name';
  }
  if (
    values.encryptedInSpsSsn !== undefined &&
    values.encryptedInSpsSsn !== null &&
    values.encryptedInSpsSsn.length &&
    RegExp.REMOVESPECIALCHAR(values.encryptedInSpsSsn).length !== 9
  ) {
    errors.encryptedInSpsSsn = 'Invalid SSN';
  }
  if (
    values.in_bank_routing_number !== undefined &&
    values.in_bank_routing_number !== null &&
    values.in_bank_routing_number.length &&
    !RegExp.BankRoutingNumber.test(values.in_bank_routing_number)
  ) {
    errors.in_bank_routing_number = 'Invalid number';
  }
  if (
    values.in_bank_account_number !== undefined &&
    values.in_bank_account_number !== null &&
    values.in_bank_account_number.length &&
    !RegExp.NUMBER.test(values.in_bank_account_number)
  ) {
    errors.in_bank_account_number = 'Invalid number';
  }
  if (values.in_self_birthdate && !isValidDate(values.in_self_birthdate)) {
    errors.in_self_birthdate = 'Enter valid date';
  } else if (
    isValidDate(values.in_self_birthdate) &&
    currentTime <= values.in_self_birthdate.getTime()
  ) {
    errors.in_self_birthdate = 'Date should be less than today';
  }

  if (
    values.in_self_license_issue_date &&
    !isValidDate(values.in_self_license_issue_date)
  ) {
    errors.in_self_license_issue_date = 'Enter valid date';
  } else if (
    isValidDate(values.in_self_license_issue_date) &&
    currentTime <= values.in_self_license_issue_date.getTime()
  ) {
    errors.in_self_license_issue_date = 'Date should be less than today';
  }

  if (
    values.in_self_license_expiry_date &&
    !isValidDate(values.in_self_license_expiry_date)
  ) {
    errors.in_self_license_expiry_date = 'Enter valid date';
  } else if (
    values.in_self_license_issue_date &&
    isValidDate(values.in_self_license_expiry_date) &&
    isValidDate(values.in_self_license_issue_date) &&
    values.in_self_license_expiry_date.getTime() <
      values.in_self_license_issue_date.getTime()
  ) {
    errors.in_self_license_expiry_date = 'Expiry should be after issue date';
  }

  if (values.in_sps_birthdate && !isValidDate(values.in_sps_birthdate)) {
    errors.in_sps_birthdate = 'Enter valid date';
  } else if (
    isValidDate(values.in_sps_birthdate) &&
    currentTime <= values.in_sps_birthdate.getTime()
  ) {
    errors.in_sps_birthdate = 'Date should be less than today';
  }

  if (
    values.in_sps_license_issue_date &&
    !isValidDate(values.in_sps_license_issue_date)
  ) {
    errors.in_sps_license_issue_date = 'Enter valid date';
  }
  if (
    isValidDate(values.in_sps_license_issue_date) &&
    currentTime <= values.in_sps_license_issue_date.getTime()
  ) {
    errors.in_sps_license_issue_date = 'Date should be less than today';
  }

  if (
    values.in_sps_license_expiry_date &&
    !isValidDate(values.in_sps_license_expiry_date)
  ) {
    errors.in_sps_license_expiry_date = 'Enter valid date';
  } else if (
    isValidDate(values.in_sps_license_issue_date) &&
    isValidDate(values.in_sps_license_expiry_date) &&
    values.in_sps_license_expiry_date.getTime() <
      values.in_sps_license_issue_date.getTime()
  ) {
    errors.in_sps_license_expiry_date = 'Expiry should be after issue date';
  }
  return errors;
};

export const validateIndividualAccount = (values) => {
  const errors = {};
  if (
    values.taxpayer_first_name !== undefined &&
    values.taxpayer_first_name !== null &&
    values.taxpayer_first_name.length &&
    !RegExp.NAME.test(values.taxpayer_first_name)
  ) {
    errors.taxpayer_first_name = 'Invalid name';
  }
  if (
    values.taxpayer_last_name !== undefined &&
    values.taxpayer_last_name !== null &&
    values.taxpayer_last_name.length &&
    !RegExp.NAME.test(values.taxpayer_last_name)
  ) {
    errors.taxpayer_last_name = 'Invalid name';
  }
  if (
    values.taxpayer_middle_name !== undefined &&
    values.taxpayer_middle_name !== null &&
    values.taxpayer_middle_name.length &&
    !RegExp.NAME.test(values.taxpayer_middle_name)
  ) {
    errors.taxpayer_middle_name = 'Invalid name';
  }
  if (
    values.taxpayer_middle_name !== undefined &&
    values.taxpayer_middle_name !== null &&
    values.taxpayer_middle_name.length
  ) {
    if (
      values.taxpayer_first_name === undefined ||
      values.taxpayer_first_name === null ||
      !values.taxpayer_first_name.length
    ) {
      errors.taxpayer_first_name =
        getFirmType() !== 'cpa'
          ? 'Enter matter first name'
          : 'Enter taxpayer first name';
    }
    if (
      values.taxpayer_last_name === undefined ||
      values.taxpayer_last_name === null ||
      !values.taxpayer_last_name.length
    ) {
      errors.taxpayer_last_name =
        getFirmType() !== 'cpa'
          ? 'Enter matter last name'
          : 'Enter taxpayer last name';
    }
  }
  if (
    values.taxpayer_first_name !== undefined &&
    values.taxpayer_first_name !== null &&
    values.taxpayer_first_name.length &&
    (values.taxpayer_last_name === undefined ||
      values.taxpayer_last_name === null ||
      !values.taxpayer_last_name.length)
  ) {
    errors.taxpayer_last_name =
      getFirmType() !== 'cpa'
        ? 'Enter matter last name'
        : 'Enter taxpayer last name';
  }
  if (
    values.taxpayer_last_name !== undefined &&
    values.taxpayer_last_name !== null &&
    values.taxpayer_last_name.length &&
    (values.taxpayer_first_name === undefined ||
      values.taxpayer_first_name === null ||
      !values.taxpayer_first_name.length)
  ) {
    errors.taxpayer_first_name =
      getFirmType() !== 'cpa'
        ? 'Enter matter first name'
        : 'Enter taxpayer first name';
  }
  if (
    values.encryptedInSelfSsn !== undefined &&
    values.encryptedInSelfSsn !== null &&
    values.encryptedInSelfSsn.length &&
    RegExp.REMOVESPECIALCHAR(values.encryptedInSelfSsn).length !== 9
  ) {
    errors.encryptedInSelfSsn = 'Invalid SSN';
  }
  if (
    values.in_sps_first_name !== undefined &&
    values.in_sps_first_name !== null &&
    values.in_sps_first_name.length &&
    !RegExp.NAME.test(values.in_sps_first_name)
  ) {
    errors.in_sps_first_name = 'Invalid name';
  }
  if (
    values.in_sps_last_name !== undefined &&
    values.in_sps_last_name !== null &&
    values.in_sps_last_name.length &&
    !RegExp.NAME.test(values.in_sps_last_name)
  ) {
    errors.in_sps_last_name = 'Invalid name';
  }
  if (
    values.in_sps_middle_name !== undefined &&
    values.in_sps_middle_name !== null &&
    values.in_sps_middle_name.length &&
    !RegExp.NAME.test(values.in_sps_middle_name)
  ) {
    errors.in_sps_middle_name = 'Invalid name';
  }
  if (
    values.in_sps_middle_name !== undefined &&
    values.in_sps_middle_name !== null &&
    values.in_sps_middle_name.length
  ) {
    if (
      values.in_sps_first_name === undefined ||
      values.in_sps_first_name === null ||
      !values.in_sps_first_name.length
    ) {
      errors.in_sps_first_name = 'Enter spouse first name';
    }
    if (
      values.in_sps_last_name === undefined ||
      values.in_sps_last_name === null ||
      !values.in_sps_last_name.length
    ) {
      errors.in_sps_last_name = 'Enter spouse last name';
    }
  }
  if (
    values.in_sps_first_name !== undefined &&
    values.in_sps_first_name !== null &&
    values.in_sps_first_name.length &&
    (values.in_sps_last_name === undefined ||
      values.in_sps_last_name === null ||
      !values.in_sps_last_name.length)
  ) {
    errors.in_sps_last_name = 'Enter spouse last name';
  }
  if (
    values.in_sps_last_name !== undefined &&
    values.in_sps_last_name !== null &&
    values.in_sps_last_name.length &&
    (values.in_sps_first_name === undefined ||
      values.in_sps_first_name === null ||
      !values.in_sps_first_name.length)
  ) {
    errors.in_sps_first_name = 'Enter spouse first name';
  }
  if (
    values.encryptedInSpsSsn !== undefined &&
    values.encryptedInSpsSsn !== null &&
    values.encryptedInSpsSsn.length &&
    RegExp.REMOVESPECIALCHAR(values.encryptedInSpsSsn).length !== 9
  ) {
    errors.encryptedInSpsSsn = 'Invalid SSN';
  }
  if (
    values.in_bank_routing_number !== undefined &&
    values.in_bank_routing_number !== null &&
    values.in_bank_routing_number.length &&
    !RegExp.BankRoutingNumber.test(values.in_bank_routing_number)
  ) {
    errors.in_bank_routing_number = 'Invalid number';
  }
  if (
    values.in_bank_account_number !== undefined &&
    values.in_bank_account_number !== null &&
    values.in_bank_account_number.length &&
    !RegExp.NUMBER.test(values.in_bank_account_number)
  ) {
    errors.in_bank_account_number = 'Invalid number';
  }
  if (values.in_self_birthdate && !isValidDate(values.in_self_birthdate)) {
    errors.in_self_birthdate = 'Enter valid date';
  } else if (
    isValidDate(values.in_self_birthdate) &&
    today.getTime() <= values.in_self_birthdate.getTime()
  ) {
    errors.in_self_birthdate = 'Date should be less than today';
  }

  if (
    values.in_self_license_issue_date &&
    !moment(values.in_self_license_issue_date).isValid()
  ) {
    errors.in_self_license_issue_date = 'Enter valid date';
  } else if (
    values.in_self_license_issue_date &&
    moment().isSameOrBefore(values.in_self_license_issue_date)
  ) {
    errors.in_self_license_issue_date = 'Date should be less than today';
  }

  if (
    values.in_self_license_expiry_date &&
    !moment(values.in_self_license_expiry_date).isValid()
  ) {
    errors.in_self_license_expiry_date = 'Enter valid date';
  } else if (
    values.in_self_license_issue_date &&
    moment(values.in_self_license_issue_date).isValid() &&
    moment(values.in_self_license_expiry_date).isBefore(
      values.in_self_license_issue_date,
    )
  ) {
    errors.in_self_license_expiry_date = 'Expiry should be after issue date';
  }

  if (values.in_sps_birthdate && !isValidDate(values.in_sps_birthdate)) {
    errors.in_sps_birthdate = 'Enter valid date';
  } else if (
    isValidDate(values.in_sps_birthdate) &&
    today.getTime() <= values.in_sps_birthdate.getTime()
  ) {
    errors.in_sps_birthdate = 'Date should be less than today';
  }

  if (
    values.in_sps_license_issue_date &&
    !moment(values.in_sps_license_issue_date).isValid()
  ) {
    errors.in_sps_license_issue_date = 'Enter valid date';
  }
  if (
    values.in_sps_license_issue_date &&
    moment().isSameOrBefore(values.in_sps_license_issue_date)
  ) {
    errors.in_sps_license_issue_date = 'Date should be less than today';
  }

  if (
    values.in_sps_license_expiry_date &&
    !moment(values.in_sps_license_expiry_date).isValid()
  ) {
    errors.in_sps_license_expiry_date = 'Enter valid date';
  } else if (
    values.in_sps_license_issue_date &&
    moment(values.in_sps_license_issue_date).isValid() &&
    moment(values.in_sps_license_expiry_date).isBefore(
      values.in_sps_license_issue_date,
    )
  ) {
    errors.in_sps_license_expiry_date = 'Expiry should be after issue date';
  }
  return errors;
};

export const validateDependents = (input) => {
  let output = {};
  input.map((values, key) => {
    const errors = {};
    const { encryptedSsn } = values;
    if (
      values.first_name ||
      values.last_name ||
      values.middle_name ||
      values.ssn ||
      values.birthdate ||
      values.dependant_relationship_type
    ) {
      if (!values.first_name) {
        errors.first_name = 'Required';
      } else if (!RegExp.NAME.test(values.first_name)) {
        errors.first_name = 'Invalid name';
      }

      if (!values.last_name) {
        errors.last_name = 'Required';
      } else if (!RegExp.NAME.test(values.last_name)) {
        errors.last_name = 'Invalid name';
      }

      if (values.middle_name && !RegExp.NAME.test(values.middle_name)) {
        errors.middle_name = 'Invalid name';
      }

      if (encryptedSsn && RegExp.REMOVESPECIALCHAR(encryptedSsn).length !== 9) {
        errors.encryptedSsn = 'Invalid SSN';
      }

      if (values.birthdate && !isValidDate(values.birthdate)) {
        errors.birthdate = 'Enter valid date';
      } else if (
        isValidDate(values.birthdate) &&
        today.getTime() <= values.birthdate.getTime()
      ) {
        errors.birthdate = 'Date should be less than today';
      }

      if (Object.keys(errors).length) {
        output = { ...output, [key]: errors };
      }
    }
    return errors;
  });
  return output;
};

export const validateDependentsOnBlur = (input) => {
  let output = {};
  input.map((values, key) => {
    const errors = {};
    const { encryptedSsn } = values;
    if (!RegExp.NAME.test(values.first_name)) {
      errors.first_name = 'Invalid name';
    }

    if (!RegExp.NAME.test(values.last_name)) {
      errors.last_name = 'Invalid name';
    }

    if (values.middle_name && !RegExp.NAME.test(values.middle_name)) {
      errors.middle_name = 'Invalid name';
    }

    if (encryptedSsn && RegExp.REMOVESPECIALCHAR(encryptedSsn).length !== 9) {
      errors.encryptedSsn = 'Invalid SSN';
    }

    if (values.birthdate && !isValidDate(values.birthdate)) {
      errors.birthdate = 'Enter valid date';
    } else if (
      isValidDate(values.birthdate) &&
      today.getTime() <= values.birthdate.getTime()
    ) {
      errors.birthdate = 'Date should be less than today';
    }
    if (Object.keys(errors).length) {
      output = { ...output, [key]: errors };
    }
    return errors;
  });
  return output;
};

export const validateBankAccountDetails = (values, fieldNames) => {
  const errors = {};
  const { routingNumber, accountNumber, accountType, autoPayment } = fieldNames;
  if (values[autoPayment] && !values[routingNumber]) {
    errors[routingNumber] = 'Required';
  }
  if (
    values[autoPayment] &&
    !values[accountNumber] &&
    !values?.encrypted_account_number?.populated
  ) {
    errors[accountNumber] = 'Required';
  }
  if (values[autoPayment] && !values[accountType]) {
    errors[accountType] = 'Required';
  }
  if (
    values[routingNumber] &&
    !RegExp.BankRoutingNumber.test(values[routingNumber])
  ) {
    errors[routingNumber] = 'Enter 9 digits valid routing number';
  }
  if (values[routingNumber] && !RegExp.NUMBER.test(values[routingNumber])) {
    errors[routingNumber] = 'Enter valid numeric characters';
  }
  if (values[accountNumber] && !RegExp.NUMBER.test(values[accountNumber])) {
    errors[accountNumber] = 'Enter valid numeric characters';
  }
  return errors;
};

export const formatAccountDates = (input) => {
  const output = input;
  if (
    output.in_self_birthdate !== null &&
    moment(output.in_self_birthdate).isValid
  ) {
    output.in_self_birthdate = moment(
      output.in_self_birthdate,
      'MM/DD/YYYY',
    ).format('YYYY-MM-DD');
  }
  if (
    output.in_self_license_expiry_date !== null &&
    moment(output.in_self_license_expiry_date).isValid
  ) {
    output.in_self_license_expiry_date = moment(
      output.in_self_license_expiry_date,
      'MM/DD/YYYY',
    ).format('YYYY-MM-DD');
  }
  if (
    output.in_self_license_issue_date !== null &&
    moment(output.in_self_license_issue_date).isValid
  ) {
    output.in_self_license_issue_date = moment(
      output.in_self_license_issue_date,
      'MM/DD/YYYY',
    ).format('YYYY-MM-DD');
  }
  if (
    output.in_sps_birthdate !== null &&
    moment(output.in_sps_birthdate).isValid
  ) {
    output.in_sps_birthdate = moment(
      output.in_sps_birthdate,
      'MM/DD/YYYY',
    ).format('YYYY-MM-DD');
  }
  if (
    output.in_sps_license_issue_date !== null &&
    moment(output.in_sps_license_issue_date).isValid
  ) {
    output.in_sps_license_issue_date = moment(
      output.in_sps_license_issue_date,
      'MM/DD/YYYY',
    ).format('YYYY-MM-DD');
  }
  if (
    output.in_sps_license_expiry_date !== null &&
    moment(output.in_sps_license_expiry_date).isValid
  ) {
    output.in_sps_license_expiry_date = moment(
      output.in_sps_license_expiry_date,
      'MM/DD/YYYY',
    ).format('YYYY-MM-DD');
  }
  if (
    output.co_fiscal_yearend !== undefined &&
    output.co_fiscal_yearend !== null
  ) {
    output.co_fiscal_yearend = moment()
      .month(output.co_fiscal_yearend)
      .utc()
      .startOf('month')
      .add(14, 'days')
      .format();
  }
  return output;
};

/**
 * This is stupid. Why would we only allow adding additional
 * dependents if the previous one's are valid?!? Dumb...
 * We should remove this completely...
 */
export const enableDependantAddButton = (input) => {
  const output = input.every((values) => {
    const errors = {};
    const { encryptedSsn } = values;
    if (
      values.first_name ||
      values.last_name ||
      values.middle_name ||
      values.encryptedSsn ||
      values.birthdate ||
      values.dependant_relationship_type
    ) {
      if (!values.first_name) {
        errors.first_name = 'Required';
      } else if (!RegExp.NAME.test(values.first_name)) {
        errors.first_name = 'Invalid name';
      }

      if (!values.last_name) {
        errors.last_name = 'Required';
      } else if (!RegExp.NAME.test(values.last_name)) {
        errors.last_name = 'Invalid name';
      }

      if (values.middle_name && !RegExp.NAME.test(values.middle_name)) {
        errors.middle_name = 'Invalid name';
      }

      if (encryptedSsn && RegExp.REMOVESPECIALCHAR(encryptedSsn).length !== 9) {
        errors.ssn = 'Invalid SSN';
      }

      if (values.birthdate && !isValidDate(values.birthdate)) {
        errors.birthdate = 'Enter valid date';
      } else if (
        isValidDate(values.birthdate) &&
        today.getTime() <= values.birthdate
      ) {
        errors.birthdate = 'Date should be less than today';
      }

      return !Object.keys(errors).length;
    }
    return false;
  });
  return !output;
};

export const enableDependantDeleteButton = (input) => {
  const {
    first_name,
    last_name,
    middle_name,
    ssn,
    birthdate,
    dependant_relationship_type,
  } = input;
  if (
    first_name ||
    last_name ||
    middle_name ||
    ssn ||
    birthdate ||
    dependant_relationship_type
  ) {
    return true;
  }
  return false;
};

export const encryptDependentData = (data) => {
  const output = [];
  data.map((each) => {
    const eachDependent = each;
    if (eachDependent.birthdate && eachDependent.birthdate !== null) {
      eachDependent.birthdate = moment(
        eachDependent.birthdate,
        'MM/DD/YYYY',
      ).format('YYYY-MM-DD');
    }
    if (Object.keys(eachDependent) && Object.keys(eachDependent).length) {
      output.push(eachDependent);
    }
    return null;
  });
  return output;
};

export const decryptDependentData = (data) => {
  const output = [];
  JSON.parse(JSON.stringify(data)).map((each) => {
    const eachDependent = each;
    if (eachDependent.birthdate && eachDependent.birthdate !== null) {
      eachDependent.birthdate = moment(
        eachDependent.birthdate,
        'YYYY-MM-DD',
      ).toDate();
    }
    if (Object.keys(eachDependent) && Object.keys(eachDependent).length) {
      output.push(eachDependent);
    }
    return null;
  });
  return output.length ? output : [{}];
};

export const showIndividualAdditionInformationForm = (
  input = {},
  entitytypes = [],
) => {
  let output = false;
  if (input && input.entity_type) {
    output = entitytypes.some(
      (each) =>
        each.value &&
        input.entity_type.toLowerCase() === each.value.toLowerCase(),
    );
  }
  return output;
};

export const generateEntityTypeOptions = (
  allpreferences,
  isAccountIndividual,
) => {
  const output = [];
  const individualEntityTypes = [];
  allpreferences.individual_entity_type.map((each) => {
    individualEntityTypes.push(each.value.toLowerCase());
    return each;
  });
  allpreferences.entity_type.map((each) => {
    if (
      isAccountIndividual &&
      individualEntityTypes.indexOf(each.value.toLowerCase()) > -1
    ) {
      output.push(each);
    } else if (
      !isAccountIndividual &&
      individualEntityTypes.indexOf(each.value.toLowerCase()) === -1
    ) {
      output.push(each);
    }
    return each;
  });
  return output;
};

export const validateSecurityQuestions = (input) => {
  let output = {};
  input.map((values, key) => {
    const errors = {};

    const isEncryptedResponsePopulated = Boolean(
      values?.encrypted_response?.populated,
    );
    const hasEncryptedResponseValue =
      isEncryptedResponsePopulated || values.encryptedResponse;

    if (values.question || values.response) {
      if (!values.question) {
        errors.question = 'Required';
      }
      if (!values.response && !hasEncryptedResponseValue) {
        errors.response = 'Required';
        errors.encryptedResponse = 'Required';
      }
      if (Object.keys(errors).length) {
        output = { ...output, [key]: errors };
      }
    }
    return errors;
  });
  return output;
};

export const encryptSecurityQuestions = (input) => {
  const output = [];
  input.map((each) => {
    const eachQuestion = { ...each };
    if (Object.keys(eachQuestion) && Object.keys(eachQuestion).length) {
      output.push(eachQuestion);
    }
    return null;
  });
  return output;
};

export const decryptSecurityQuestions = (input) => {
  const output = [];
  input.map((each) => {
    const eachQuestion = { ...each };
    if (Object.keys(eachQuestion) && Object.keys(eachQuestion).length) {
      output.push(eachQuestion);
    }
    return null;
  });
  return output;
};

export const enableAddQuestionsBtn = (input) =>
  !input.every(
    (each) =>
      each.question &&
      (each.response ||
        each.encryptedResponse ||
        each.encrypted_response?.populated),
  );

export const resetDataonEntityChange = (input) => {
  const {
    name,
    entity_type,
    status,
    stage,
    website,
    phone_number,
    external_account_id,
    referral_source,
    rating,
  } = input;
  const output = {
    name,
    entity_type,
    status,
    stage,
    website,
    phone_number,
    external_account_id,
    referral_source,
    rating,
  };
  return { ...output };
};

export const enableAddBankAccountButton = (input) => {
  const output = input.every((each) => {
    const {
      bankName,
      routingNumber,
      accountNumber,
      accountType,
      bankWebsite,
      bankNotes,
    } = coBankAccount;
    return (
      each[bankName] ||
      each[routingNumber] ||
      each[accountNumber] ||
      each[accountType] ||
      each[bankWebsite] ||
      each[bankNotes]
    );
  });
  return output;
};

export const enableDeleteAccountButton = (input) => {
  const {
    bankName,
    routingNumber,
    accountNumber,
    accountType,
    bankWebsite,
    bankNotes,
  } = coBankAccount;
  return (
    input[bankName] ||
    input[routingNumber] ||
    input[accountNumber] ||
    input[accountType] ||
    input[bankWebsite] ||
    input[bankNotes]
  );
};

export const encryptBankAccounts = (input) => {
  const output = [];
  input.map((each) => {
    const eachBankAccount = { ...each };
    if (Object.keys(eachBankAccount) && Object.keys(eachBankAccount).length) {
      output.push(eachBankAccount);
    }
    return null;
  });
  return output;
};

export const decryptBankAccounts = (input) => {
  const output = [];
  input.map((each) => {
    const eachBankAccount = { ...each };
    if (Object.keys(eachBankAccount) && Object.keys(eachBankAccount).length) {
      output.push(eachBankAccount);
    }
    return null;
  });
  return output.length ? output : [{}];
};

export const formatDate = (input) =>
  moment(input, 'YYYY-MM-DD').format('MM/DD/YYYY');

export const topSection = [
  {
    value: 'phone_number',
    label: 'phone',
    formatValue: FORMATPHONENUMBER,
  },
  {
    value: 'external_account_id',
    label: 'External ID',
  },
  {
    value: 'referral_source',
    label: 'referral Source',
  },
  {
    value: 'website',
    label: 'website',
  },
  {
    value: 'status',
    label: 'Account Status',
    onlyForEmployee: true,
  },
  {
    value: 'stage',
    label: 'Stage',
    onlyForEmployee: true,
  },
  {
    value: 'fax_number',
    label: 'Fax Number',
    formatValue: FORMATPHONENUMBER,
  },
  {
    value: 'address',
    label: 'Address',
  },
  {
    value: 'is_restricted_account',
    label: 'Resticted Account',
  },
];

export const accountDetails = [
  {
    value: 'co_ein',
    label: 'Taxpayer ID',
    formatValue: RegExp.FORMATEIN,
    sensitive: true,
    encrypted: true,
    section: 'Account Details',
    recordType: 'Account',
  },
  {
    value: 'co_industry',
    label: 'Industry',
    section: 'Account Details',
  },
  // {
  //   value: 'rating',
  //   label: 'Account Rating',
  //   onlyForEmployee: true,
  // },
  {
    value: 'co_number_of_employees',
    label: '# of employees',
    section: 'Account Details',
  },
  {
    value: 'co_fiscal_yearend',
    label: 'Fiscal Year End',
    formatValue: (input) =>
      captalizeText(
        labelNamebyValue(moment(input).utc().format('MM') - 1, months),
      ),
    section: 'Account Details',
  },
  {
    value: 'co_prior_accountant',
    label: 'Prior Accountant',
    section: 'Account Details',
  },
  {
    value: '',
    label: '',
  },
  {
    value: 'co_biggest_challenges',
    label: 'What are your biggest challenges?',
    notInline: true,
    section: 'Account Details',
  },
  {
    value: 'co_biz_decision_tools_used',
    label: 'What do you use to make business decisions?',
    notInline: true,
    section: 'Account Details',
  },
  {
    value: 'co_expectations',
    label:
      getFirmType() !== 'cpa'
        ? 'What would you like most from an legal firm?'
        : 'What would you like most from an accounting firm?',
    notInline: true,
    section: 'Account Details',
  },
];

export const coBankSection = [
  {
    value: 'bank_name',
    label: 'Bank Name',
  },
  {
    value: 'routing_number',
    label: 'routing Number',
  },
  {
    value: 'account_number',
    label: 'account Number',
    sensitive: true,
    encrypted: true,
    recordType: 'BankAccount',
  },
  {
    value: 'account_type',
    label: 'account Type',
  },
  {
    value: 'web_site',
    label: 'Website',
  },
  {
    value: '',
    label: '',
  },
  {
    value: 'notes',
    label: 'Notes',
  },
];

export const technologySolutionSection = [
  {
    value: 'co_tech_accounting',
    label: 'Client Accounting',
    section: 'Technology Solutions',
  },
  {
    value: 'co_tech_expenses',
    label: 'Expense Reimbursement',
    section: 'Technology Solutions',
  },
  {
    value: 'co_tech_time_trackings',
    label: 'Time Tracking',
    section: 'Technology Solutions',
  },
  {
    value: 'co_tech_payroll',
    label: 'Payroll',
    section: 'Technology Solutions',
  },
  {
    value: 'co_tech_billpay',
    label: 'Bill pay',
    section: 'Technology Solutions',
  },
];

export const individualInformation = (labelPrefix) => [
  {
    value: 'taxpayer_first_name',
    label: `${labelPrefix}first name`,
    section: 'Taxpayer Details',
  },
  {
    value: 'taxpayer_middle_name',
    label: `${labelPrefix}middle name`,
    section: 'Taxpayer Details',
  },
  {
    value: 'taxpayer_last_name',
    label: `${labelPrefix}last name`,
    section: 'Taxpayer Details',
  },
  {
    value: 'in_curr_filling_status',
    label: 'current filing status?',
    section: 'Taxpayer Details',
  },
  {
    value: 'in_self_ssn',
    label: 'Taxpayer ID',
    formatValue: RegExp.FORMATSSN,
    sensitive: true,
    encrypted: true,
    section: 'Taxpayer Details',
    recordType: 'Account',
  },
  {
    value: 'in_self_birthdate',
    label: 'Birthdate',
    formatValue: formatDate,
    section: 'Taxpayer Details',
  },
  {
    value: 'in_self_driver_license',
    label: 'Drivers License #',
    section: 'Taxpayer Details',
  },
  {
    value: 'in_self_license_issue_date',
    label: 'Date Issued',
    formatValue: formatDate,
    section: 'Taxpayer Details',
  },
  {
    value: 'in_self_license_expiry_date',
    label: 'Expiration Date',
    formatValue: formatDate,
    section: 'Taxpayer Details',
  },
  {
    value: 'in_self_license_state',
    label: 'State of issue',
    section: 'Taxpayer Details',
  },
];

export const inBankDetails = [
  {
    value: 'in_bank_name',
    label: 'Bank Name',
  },
  {
    value: 'in_bank_routing_number',
    label: 'routing Number',
  },
  {
    value: 'in_bank_account_number',
    label: 'account Number',
    sensitive: true,
    encrypted: true,
  },
  {
    value: 'in_bank_account_type',
    label: 'account Type',
  },
  {
    value: 'in_bank_website',
    label: 'Website',
  },
  {
    value: '',
    label: '',
  },
  {
    value: 'in_notes',
    label: 'Notes',
  },
];

export const spouseDetails = (labelPrefix) => [
  {
    value: 'in_sps_first_name',
    label: `${labelPrefix}first name`,
    section: 'Spouse Details',
  },
  {
    value: 'in_sps_middle_name',
    label: `${labelPrefix}middle name`,
    section: 'Spouse Details',
  },
  {
    value: 'in_sps_last_name',
    label: `${labelPrefix}last name`,
    section: 'Spouse Details',
  },
  {
    value: 'in_sps_ssn',
    label: 'Taxpayer ID',
    formatValue: RegExp.FORMATSSN,
    sensitive: true,
    encrypted: true,
    section: 'Spouse Details',
    recordType: 'Account',
  },
  {
    value: 'in_sps_birthdate',
    label: 'Birthdate',
    formatValue: formatDate,
    section: 'Spouse Details',
  },
  {
    value: 'in_sps_driver_license',
    label: 'Drivers License #',
    section: 'Spouse Details',
  },
  {
    value: 'in_sps_license_issue_date',
    label: 'Date Issued',
    formatValue: formatDate,
    section: 'Spouse Details',
  },
  {
    value: 'in_sps_license_expiry_date',
    label: 'Expiration Date',
    formatValue: formatDate,
    section: 'Spouse Details',
  },
  {
    value: 'in_sps_license_state',
    label: 'State of issue',
    section: 'Spouse Details',
  },
  {
    value: 'address',
    label: 'Address',
    section: 'Spouse Details',
  },
];

export const pay_items = [
  {
    value: 'co_is_pay_hourly',
    label: 'Hourly',
    section: 'Payroll',
  },
  {
    value: 'co_is_pay_salary',
    label: 'Salary',
    section: 'Payroll',
  },
  {
    value: 'co_is_pay_reimbursement',
    label: 'Reimbursement',
    section: 'Payroll',
  },
  {
    value: 'co_is_pay_bonus',
    label: 'Bonuses',
    section: 'Payroll',
  },
];

export const deductions = [
  {
    value: 'co_is_ded_401k',
    label: '401(K)',
    section: 'Payroll',
  },
  {
    value: 'co_is_ded_401k_roth',
    label: '401(K) Roth',
    section: 'Payroll',
  },
  {
    value: 'co_is_ded_sample_ira',
    label: 'Simple IRA',
    section: 'Payroll',
  },
  {
    value: 'co_is_ded_health',
    label: 'Health',
    section: 'Payroll',
  },
  {
    value: 'co_is_ded_garnishments',
    label: 'Garnishments',
    section: 'Payroll',
  },
];

export const accrual_tracking = [
  {
    value: 'co_is_accrual_vataion',
    label: 'Vacation',
    section: 'Payroll',
  },
  {
    value: 'co_is_accrual_sick',
    label: 'Sick',
    section: 'Payroll',
  },
];

export const payrollTaxes = [
  {
    value: 'co_fed_eftps_pin',
    label: 'Federal EFTPS PIN',
    sensitive: true,
    encrypted: true,
    customClassName: 'col-sm-12 col-md-6 col-lg-4',
    section: 'Payroll',
    recordType: 'Account',
  },
  {
    value: 'co_fed_eftps_pwd',
    label: 'Federal EFTPS Password',
    sensitive: true,
    encrypted: true,
    customClassName: 'col-sm-12 col-md-6 col-lg-4',
    section: 'Payroll',
    recordType: 'Account',
  },
  {
    value: 'co_payroll_state',
    label: 'Select State',
    customClassName: 'col-sm-12 col-md-6 col-lg-4',
    section: 'Payroll',
  },
  {
    value: 'co_state_withholding_id',
    label: 'State Withholding ID',
    encrypted: true,
    customClassName: 'col-sm-12 col-md-6 col-lg-4',
    section: 'Payroll',
    recordType: 'Account',
  },
  {
    value: 'co_state_unemployement_id',
    label: 'State Unemployment ID',
    encrypted: true,
    customClassName: 'col-sm-12 col-md-6 col-lg-4',
    section: 'Payroll',
    recordType: 'Account',
  },
  {
    value: 'co_state_ui_base_rate',
    label: 'State UI Base Rate',
    customClassName: 'col-sm-12 col-md-6 col-lg-4',
    section: 'Payroll',
  },
];

export const payroll_frequency = [
  {
    value: 'co_pay_freq',
    label: 'Pay Frequency',
    section: 'Payroll',
  },
];

export const accountLabelNames = (labelPrefix) => [
  ...topSection,
  ...accountDetails,
  ...coBankSection,
  ...technologySolutionSection,
  ...individualInformation(labelPrefix),
  ...spouseDetails(labelPrefix),
  ...inBankDetails,
];

export const business_account_AdditionalInformation_FieldNames = [
  ...accountDetails,
  ...technologySolutionSection,
  ...payroll_frequency,
  ...pay_items,
  ...deductions,
  ...accrual_tracking,
  ...payrollTaxes,
];

export const individual_Account_AdditionalInformation_FieldNames = (
  labelPrefix,
) => [...individualInformation(labelPrefix), ...spouseDetails(labelPrefix)];
