import React from 'react';
import { Button, ButtonGroup } from 'reactstrap';
import { Stack } from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';

import IconTextField from 'common/IconTextField';

const FilterSegment = (props) => {
  const {
    data: propsData,
    taskStatus,
    records,
    accountDetail,
    contactDetail,
    handleSearchChange,
    handleStatus,
    massArchive,
    segmentTaskClick,
  } = props;

  const data = propsData.homedata;

  let activeTab = 'Open';
  if (taskStatus) {
    activeTab = taskStatus;
  }

  return (
    <div className="mb-3 section-title" data-testid="section-title">
      <div className="col-container">
        <div className="col col-6">
          <div className="mailFilter mailFilter--primary">
            <ButtonGroup className="btn-group-transparent">
              <Button
                className={`btn-sm btn-transparent ${
                  activeTab.toLowerCase() === 'open' ? 'active' : null
                }`}
                onClick={() => handleStatus('Open')}
                data-testid="button-open"
              >
                Open {records !== undefined ? `(${records.Open_total})` : ''}
              </Button>
              <Button
                className={`btn-sm btn-transparent ${
                  activeTab.toLowerCase() === 'review' ? 'active' : null
                }`}
                onClick={() => handleStatus('Review')}
                data-testid="button-review"
              >
                Review{' '}
                {records !== undefined ? `(${records.Review_total})` : ''}
              </Button>
              <Button
                className={`btn-sm btn-transparent ${
                  activeTab.toLowerCase() === 'archive' ? 'active' : null
                }`}
                onClick={() => handleStatus('Archive')}
                data-testid="button-archive"
              >
                Archived{' '}
                {records !== undefined ? `(${records.Archive_total})` : ''}
              </Button>
              <Button
                className={`btn-sm btn-transparent ${
                  activeTab.toLowerCase() === 'draft' ? 'active' : null
                }`}
                onClick={() => handleStatus('Draft')}
                data-testid="button-draft"
              >
                Draft{' '}
                {records !== undefined && records.Draft_total
                  ? `(${records.Draft_total})`
                  : `(${0})`}
              </Button>
            </ButtonGroup>
          </div>
        </div>
        {data &&
        data.is_employee &&
        ((contactDetail && contactDetail.is_active) || !contactDetail) &&
        ((accountDetail &&
          accountDetail.account &&
          accountDetail.account.status !== 'Inactive') ||
          !accountDetail) ? (
          <div className="col col-6 text-right" style={{ minWidth: '270px' }} data-testid="col-right">
            <Stack direction="row" alignItems="center" justifyContent="right">
              <Stack marginRight={2}>
                <IconTextField
                  type="search"
                  icon={<SearchIcon />}
                  placeholder="search tasks"
                  value={propsData.searchKey}
                  onChange={handleSearchChange}
                  data-testid="search-input"
                />
              </Stack>
              {activeTab !== 'Archive' ? (
                <button
                  type="button"
                  className="btn btn-primary mr-3"
                  onClick={() => massArchive(false)}
                  disabled={!propsData.selectedCheckbox.length}
                  data-testid="archive-button"
                >
                  <i className="icon-archive" /> Archive
                </button>
              ) : null}
              <button
                type="button"
                onClick={() => segmentTaskClick()}
                className="btn btn-primary"
                data-testid="task-button"
              >
                <i>+</i> Task
              </button>
            </Stack>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default FilterSegment;
