import ActionsModal from 'common/ActionsModal';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom-latest';
import Menu from 'common/Menu';
import {
  CREATE_NOTE,
  CREATE_TASK,
  MARK_UNREAD,
  UNREAD_MESSAGE,
  MARK_UNREAD_HERE,
} from 'constants/constants';
import {
  isClient,
  inAccOrClientView,
  inInboxOrAlertView,
} from 'utilities/utils';
import { markItemUnread, getCpaUserId } from 'common/CommonActions';
import {
  getMessageThread,
  SelectedOptionForm,
  tabNames,
} from '../../../common/MessageUtils';

const {
  INBOX,
  // SENT,
  ARCHIVE,
  ALERT,
  DRAFT,
  // PINNED,
  // ALL_MSGS,
} = tabNames;

const getMenuItemsList = (selectedTab) => {
  const menuList = [];
  const isUserClient = isClient();
  const isInInboxOrAlertView = inInboxOrAlertView();

  if ([ARCHIVE, DRAFT].includes(selectedTab)) {
    return menuList;
  }

  if (
    // isMessageRead &&
    ([INBOX, ALERT].includes(selectedTab) || isInInboxOrAlertView) &&
    !inAccOrClientView()
  ) {
    menuList.push({
      label: isInInboxOrAlertView ? MARK_UNREAD_HERE : MARK_UNREAD,
      value: MARK_UNREAD,
    });
  }

  if (!isUserClient && ![DRAFT, ARCHIVE].includes(selectedTab)) {
    menuList.push(
      { label: CREATE_TASK, value: CREATE_TASK },
      { label: CREATE_NOTE, value: CREATE_NOTE },
    );
  }

  return menuList;
};

export default function MessageActionOptions({
  message = {},
  onUpdateMsgToUnread = null,
  selectedTab = '',
  account_data,
  contact_data,
}) {
  const menuItems = getMenuItemsList(selectedTab.toLowerCase(), message.read);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState({});
  const [selectedMessage, setSelectedMessage] = useState(message);
  const handleOptionClick = async (optionName = {}) => {
    if ([INBOX, ALERT].includes(selectedTab)) {
      setSelectedMessage(await getMessageThread(message));
    }
    if ([MARK_UNREAD, MARK_UNREAD_HERE].includes(optionName.label)) {
      markItemUnread({
        url: UNREAD_MESSAGE,
        params: {
          cpa_user_id: getCpaUserId(),
          message_id: selectedMessage.id,
        },
        postUpdate: () => {
          if (onUpdateMsgToUnread) onUpdateMsgToUnread();
          if (optionName.label === MARK_UNREAD_HERE) {
            navigate(-1);
          }
        },
      });
    } else {
      setSelectedOption(optionName);
      setShowModal(true);
    }
  };
  const closeActionsModel = () => setShowModal(false);
  if (!menuItems || !menuItems.length) {
    return null;
  }

  if (process.env.REACT_APP_MESSAGE_ACTIONS_FLAG) {
    return (
      <div key={message.id}>
        <Menu menuItems={menuItems} handleMenuItemClick={handleOptionClick} />
        <ActionsModal
          isOpen={showModal}
          onCancel={closeActionsModel}
          style={{ overflow: 'auto', maxHeight: '85vh' }}
          showModalFooter={false}
        >
          <SelectedOptionForm
            selectedOption={selectedOption}
            closeActionsModel={closeActionsModel}
            message={selectedMessage}
            account_data={account_data}
            contact_data={contact_data}
          />
        </ActionsModal>
      </div>
    );
  }

  return null;
}
