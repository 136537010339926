/* eslint-disable no-script-url */
/* eslint-disable react/jsx-no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-prototype-builtins */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import axios from 'axios';
import ReactTooltip from 'react-tooltip';
import DatePicker from 'react-datepicker';
import {
  SEARCH_TASK_CONTACTS,
  SEARCH_TO_DO_CONTACTS,
  GET_TO_DO_CONTACTS_OF_ACCOUNT_API_PATH,
  SEARCH_TASK_ACCOUNTS,
  SEARCH_TO_DO_ACCOUNTS,
  DUE_DATE,
} from 'constants/constants';
import {
  SelectFieldWithAvatar,
  SelectField,
  DatePickerHTML,
} from 'common/CustomFields';
import {
  getCurrentPath,
  getStorageData,
  getUserTimeZoneAbbr,
} from 'utilities/utils';
import * as TaskApi from 'api/TaskApi';
import { withRouter } from 'utilities/withRouterWrapper';
import CustomDatePicker from 'wrappers/DatePickerUtil';
import { add, isSameDay, isAfter } from 'date-fns';
import RecurringSection from './RecurringSection';
import CreateInvoiceModal from './task_type/CreateInvoiceModal';
import SearchInvoiceModal from './task_type/SearchInvoiceModal';

const noneStyle = { display: 'none' };
const blockStyle = { display: 'block' };
class CommonFields extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_recurring: false,
      allContacts: [], // also used for employee in to do task
      allAccounts: [],
      contacts: [],
      accounts: [],
      allRefContacts: [],
      allRefAccounts: [],
      ref_contacts: [], // used for client in to do task
      ref_accounts: [],
      refcontactId: '',
      refaccountId: '',
      refAccountName: '',
      refContactName: '',
      owners: [],
      contactId: '',
      accountId: '',
      userId: '',
      cpaId: '',
      ownerId: '',
      ownerName: '',
      assigneeType: '',
      startDate: new Date(),
      errors: {},
      error: {},
      load: false,
      ownerChanged: false,
      isChanged: false,
      changedOnce: false, // working .....
      contactDetails: '',
      accountDetails: '',
      clientTitle: '',
      contactName: '',
      durationMinutes: 30,
      durationHours: 0,
      qbCustomerId: null,
    };
    this.initialDate = this.state.startDate;
  }

  componentDidMount() {
    const { data, params, location } = this.props;
    const path = getCurrentPath(location);
    // TODO: __HOMEDATA-AUDIT
    const homeData = getStorageData();
    if (!this.props.data.isEdit) {
      const taskDueDays = homeData.pref_type_options.task_due_days
        ? homeData.pref_type_options.task_due_days[0].label
        : 7;
      this.setState(
        {
          startDate: add(new Date(), { days: taskDueDays }),
          ownerId: homeData.cpa_user_id,
          ownerName: homeData.uname,
        },
        () => {
          this.props.stateChange(this.state);
          this.initialDate = this.state.startDate;
        },
      );
      const mydata = {
        task_type_key: this.props.data.taskTypeKey,
        limit: true,
      };
      if (path === '/task/new' && !this.props.data.taskTypeKey) {
        const results = this.props.location.search.split('type=');
        let taskType = results[1]; // ?type=to_do
        if (!taskType) {
          taskType = 'request_info';
        }
        mydata.task_type_key = taskType;
      }
      if (
        path === '/contactdetails/task/:id' &&
        this.props.data.taskTypeKey !== 'to_do'
      ) {
        TaskApi.getContactDetails(this, params.id, 'commonfields');
        TaskApi.AccountsOfContact(this, params.id, 'commonfields');
      } else if (
        path === '/accountdetails/task/:id' &&
        this.props.data.taskTypeKey !== 'to_do'
      ) {
        TaskApi.getAccountDetails(this, params.id, 'commonfields');
        TaskApi.ContactsOfAccount(this, params.id, 'commonfields');
      } else if (
        path === '/task/new/modal/:id' &&
        this.props.data.taskTypeKey !== 'to_do'
      ) {
        TaskApi.getAccountDetails(this, params.id, 'commonfields');
        TaskApi.ContactsOfAccount(this, params.id, 'commonfields');
      } else if (
        this.props.data.taskTypeKey === 'to_do' &&
        (path === '/accountdetails/task/:id' ||
          path === '/task/new/modal/:id') &&
        !this.props.taskDetail?.account_data
      ) {
        const dataa = { account_id: params.id };
        TaskApi.getContactsOfTask(this, mydata, 'notes');
        TaskApi.getToDoTaskData(this, dataa);
        // TaskApi.getAccountDetails(this, params.id, 'commonfields');
        TaskApi.ContactsOfAccount(this, params.id, 'to_do');
      } else if (
        this.props.data.taskTypeKey === 'to_do' &&
        path === '/contactdetails/task/:id' &&
        !this.props.taskDetail?.account_data
      ) {
        const dataa = { contact_id: params.id };
        TaskApi.getContactsOfTask(this, mydata, 'notes');
        TaskApi.getToDoTaskData(this, dataa);
        // TaskApi.getContactDetails(this, params.id, 'to_do');
        TaskApi.AccountsOfContact(this, params.id, 'to_do');
      } else if (
        this.props.data.taskTypeKey === 'to_do' &&
        !this.props.taskDetail?.account_data
      ) {
        TaskApi.getContactsOfTask(this, mydata, 'notes');
        TaskApi.getToDoTaskData(this);
      } else if (data.viewContext === 'message') {
        const id = this.props?.taskDetail?.account_data.value;
        TaskApi.getContactsOfTask(this, mydata, 'notes');
        if (data.taskTypeKey === 'to_do') {
          TaskApi.getToDoTaskData(
            this,
            location.hash,
            this.props.taskDetail,
            'fromMessages',
          );
        }
        if (data.taskTypeKey !== 'to_do') {
          if (Object.keys(this.props.taskDetail.account_data).length) {
            this.handleAccountChange(this.props.taskDetail.account_data);
          }
          TaskApi.getAccounts(this, 'notes');
        }
      } else {
        TaskApi.getContactsOfTask(this, mydata, 'notes');
        TaskApi.getAccounts(this, 'notes');
      }
    }
    if (
      data.viewContext &&
      data.viewContext === 'email' &&
      data.taskTypeKey === 'to_do' &&
      homeData &&
      homeData.uname
    ) {
      this.handleContactInput(homeData.uname);
    }
    TaskApi.getOwners(this);
    if (this.props.taskDetail?.account_data) {
      if (data.taskTypeKey === 'to_do') {
        this.setState(
          {
            refContactIdtoSend: '',
          },
          () => this.handleRefAccountChange(this.props.taskDetail.account_data),
        );
      } else {
        this.setState({
          accountName: this.props?.taskDetail?.account_data?.label || '',
          accountId: this.props?.taskDetail?.account_data?.value || '',
        });
      }
    }
  }

  UNSAFE_componentWillReceiveProps() {
    const { data } = this.props;
    let { durationHours, durationMinutes } = this.state;
    if (
      Object.keys(this.props.taskDetail).length &&
      this.props.data.isEdit &&
      !this.state.changedOnce
    ) {
      const detail = this.props.taskDetail;
      if (data.taskTypeKey === 'to_do') {
        const dataa = {
          contact_id: detail.todo_for_contact_id,
          account_id: detail.todo_for_account_id,
        };
        TaskApi.getToDoTaskData(this, dataa, detail);
      } else {
        this.setState(
          {
            accountId: detail.account_id,
            accounts:
              detail.account &&
              detail.account !== 'N/A' &&
              detail.account_id !== null
                ? [{ label: detail.account, value: detail.account_id }]
                : null,
            accountName: detail.account,
          },
          () => {
            this.props.stateChange(this.state);
          },
        );
      }
      if (detail.meeting_duration) {
        durationHours = Math.floor(detail.meeting_duration / 60);
        durationMinutes = Math.floor(detail.meeting_duration % 60);
      }
      this.setState(
        {
          clientTitle: detail.client_title ? detail.client_title : '',
          contactId: detail.assigne_cpa_user_id,
          cpaId: detail.cpa_id,
          startDate: new Date(detail.full_due_date),
          ownerId: detail.owner_id,
          contacts: [
            {
              label: detail.assigne,
              value: detail.assigne_cpa_user_id,
              avatar: detail.assigne_avatar,
            },
          ],
          contactName: detail.assigne,
          changedOnce: true,
          meetingDuration: detail.meeting_duration,
          durationHours,
          durationMinutes,
        },
        () => {
          this.initialDate = this.state.startDate;
          this.props.stateChange(this.state);
        },
      );
    }
  }

  setTaskTitle(assignedTo, refCon = '', refAcc = '') {
    const { taskTypeKey, titleTitle } = this.props.data;
    const newTitle = '';
    // if (taskTypeKey === 'to_do') {
    //   newTitle = 'To Do for ' + assignedTo;
    //   if (refCon !== ' ' && refAcc !== ' ' && refCon !== '' && refAcc !== '') {
    //     newTitle = 'To Do for ' + assignedTo + ' - for ' + refCon + ', ' + refAcc;
    //   }
    //   if (refCon !== ' ' && refCon !== '' && (refAcc === '' || refAcc === ' ')) {
    //     newTitle = 'To Do for ' + assignedTo + ' - for ' + refCon;
    //   }
    //   if (refAcc !== ' ' && refAcc !== '' && (refCon === '' || refCon === ' ')) {
    //     newTitle = 'To Do for ' + assignedTo + ' - for ' + refAcc;
    //   }
    //   this.props.setTasktitle(newTitle);
    // }
  }

  // handle account change
  handleAccountChange = (val) => {
    const path = getCurrentPath(this.props.location);
    if (!val && !this.state.contactId) {
      this.resetContactsAndAccounts();
    } else if (!val) {
      this.setState(
        {
          accountId: '',
          accountName: '',
          qbCustomerId: null,
        },
        () => {
          this.props.stateChange(this.state, true, 'account');
        },
      );
    } else {
      this.setState(
        {
          accountId: val.value,
          accountName: val.label,
          qbCustomerId: val.qb_customer_id,
          selectedAccount: val,
        },
        () => {
          this.props.stateChange(this.state, true, 'account');
        },
      );
      this.props.emptyError();
      this.setState({ isChanged: true });
      const { match } = this.props;
      if (path === '/contactdetails/task/:id') {
        return false;
      }
      const mydata = {
        accountId: val.value,
        accountName: val.label,
      };
      TaskApi.getContactsOfAccount(this, mydata);
    }
    if (this.props.getQbInvoiceDetails) {
      this.props.getQbInvoiceDetails();
    }
  };

  handleRefAccountChange = (val) => {
    const component = this;
    if (!val && !this.state.refcontactId) {
      this.resetContactsAndAccounts('ref_accounts');
      this.resetContactsAndAccounts('ref_contacts');
    }
    if (val && val.value) {
      this.setState(
        {
          refaccountId: val.value,
          refAccountName: val.label,
        },
        () => {
          if (!this.props.data.isEdit) {
            // don't change task title in edit case
            component.setTaskTitle(
              this.state.contactName,
              this.state.refContactName,
              this.state.refAccountName,
            );
          }
          component.props.stateChange(this.state, true, 'ref_contacts');
        },
      );
      const request = axios.get(
        `${GET_TO_DO_CONTACTS_OF_ACCOUNT_API_PATH}/${val.value}`,
      );
      component.setState({ load: true });
      request.then((response) => {
        component.setState({ load: false });
        let existing_contact = false;
        response.data.data.forEach((data) => {
          if (data.id === component.state.refContactIdtoSend) {
            existing_contact = true;
          }
        });
        if (!existing_contact) {
          component.setState(
            {
              refcontactId: '',
              refContactIdtoSend: '',
              refContactName: '',
              RefContactNameIntials: ' ',
              refContactAvatar: '',
              ref_contacts: response.data.data,
            },
            () => {
              component.props.stateChange(this.state, true, 'ref_contacts');
              if (!this.props.data.isEdit) {
                component.setTaskTitle(
                  this.state.contactName,
                  this.state.refContactName,
                  this.state.refAccountName,
                );
              }
            },
          );
        } else {
          component.setState({ ref_contacts: response.data.data });
        }
      });
    } else {
      // for handle contact selction
      if (!val && this.state.refcontactId) {
        this.setState({
          ref_accounts: this.state.allRefAccounts,
        });
      }
      if (!this.state.refcontactId) {
        component.setState({ ref_accounts: component.state.allRefAccounts });
      }
      component.setState(
        {
          refaccountId: '',
          refAccountName: '',
        },
        () => {
          if (!this.props.data.isEdit) {
            component.setTaskTitle(
              this.state.contactName,
              this.state.refContactName,
              this.state.refAccountName,
            );
          }
          component.props.stateChange(this.state, true);
        },
      );
    }
    if (this.props.data.isEdit) {
      this.setState({ refAccountChanged: true });
    }
  };

  handleRefContactChange = (val) => {
    const component = this;
    if (!val && !this.state.refaccountId) {
      this.resetContactsAndAccounts('ref_contacts');
      this.resetContactsAndAccounts('ref_accounts');
    } else if (!val) {
      this.setState(
        {
          refcontactId: '',
          refContactName: '',
          refContactIdtoSend: '',
        },
        () => {
          if (!this.props.data.isEdit) {
            component.setTaskTitle(
              this.state.contactName,
              this.state.refContactName,
              this.state.refAccountName,
            );
          }
          this.props.stateChange(this.state, true);
        },
      );
      // eslint-disable-next-line no-dupe-else-if
    }
    if (val) {
      if (val.type === 'emp' || !val.hasOwnProperty('accounts')) {
        this.setState(
          {
            refcontactId: val.value,
            refContactIdtoSend: val.id,
            refContactName: val.label,
            refaccountId: '',
            ref_accounts: [],
          },
          () => {
            if (!this.props.data.isEdit) {
              component.setTaskTitle(
                this.state.contactName,
                this.state.refContactName,
                this.state.refAccountName,
              );
            }
            this.props.stateChange(this.state, true);
          },
        );
      } else {
        if (val.accounts.length >= 1) {
          const valueArr = val.accounts.map((item) => item.value);
          if (valueArr.indexOf(component.state.refaccountId) > -1) {
            // console.log('do nothing');
          } else {
            component.setState({ refaccountId: '' });
          }
        }
        component.setState(
          {
            ref_accounts: val.accounts,
            refcontactId: val.value,
            refContactName: val.label,
            refContactIdtoSend: val.id,
          },
          () => {
            if (!this.props.data.isEdit) {
              component.setTaskTitle(
                this.state.contactName,
                this.state.refContactName,
                this.state.refAccountName,
              );
            }
            this.props.stateChange(this.state, true);
          },
        );
      }
    }
    this.props.emptyError();
    if (this.props.data.isEdit) {
      this.setState({ refContactChanged: true });
    }
  };

  handleAccountInput = (val) => {
    if (val.length >= 2 && !this.state.contactId) {
      clearTimeout(this.accountSearchTime);
      this.accountSearchTime = setTimeout(() => {
        const request = axios.get(
          `${SEARCH_TASK_ACCOUNTS}/?keyword=${val}&task_type_key=${this.props.data.taskTypeKey}`,
        );
        request.then((response) => {
          if (response.status === 200) {
            this.setState({ accounts: response.data });
          }
        });
      }, 500);
    }
  };

  handleRefAccountInput = (val) => {
    if (val.length >= 2) {
      const component = this;
      const request = axios.get(`${SEARCH_TO_DO_ACCOUNTS}/?keyword=${val}`);
      request.then((response) => {
        if (response.status === 200) {
          component.setState({ ref_accounts: response.data });
        }
      });
    }
  };

  handleRefContactInput = (val) => {
    if (!this.state.refaccountId && val) {
      if (val.length < 2) {
        this.resetContactsAndAccounts('ref_contacts');
      } else {
        const component = this;
        const request = axios.get(`${SEARCH_TO_DO_CONTACTS}/?keyword=${val}`);
        request.then((response) => {
          if (response.status === 200) {
            component.setState({ ref_contacts: response.data });
          }
        });
      }
    }
  };

  employeeChange(val) {
    if (val && val.value) {
      this.setState(
        {
          contactId: val.value,
          contactName: val.label,
          isChanged: true,
        },
        () => {
          if (!this.props.data.isEdit) {
            this.setTaskTitle(
              this.state.contactName,
              this.state.refContactName,
              this.state.refAccountName,
            );
          }
        },
      );

      const data = {
        task_id: this.props.params.id,
        cpa_user_id: val.value,
      };

      TaskApi.changeAssignee(this, data);
    }
  }

  handleContactChange = (val) => {
    if (this.props.data.isEdit) {
      this.employeeChange(val);
      return false;
    }
    const { taskTypeKey } = this.props.data;
    if (!val && !this.state.accountId) {
      this.resetContactsAndAccounts(taskTypeKey === 'to_do' ? 'contacts' : '');
    } else if (!val) {
      this.setState({
        contactId: '',
        contactName: '',
        userId: '',
        assigneeType: '',
      });
    } else if (val.type === 'emp' || !val.hasOwnProperty('accounts')) {
      // disable account field
      this.setState({
        accounts: [],
        contactId: val.value,
        userId: val.id,
        accountId: '',
        accountName: '',
        qbCustomerId: null,
        cpaId: val.cpa_id,
        assigneeType: val.assigne_type,
        clientTitle: val.client_title,
        contactName: val.label,
      });
    } else if (val.accounts.length === 1) {
      // autopopulate account if only one account
      this.setState({
        accountId: val.accounts[0].value,
        accountName: val.accounts[0].label,
        qbCustomerId: val.accounts[0].qb_customer_id,
        accounts: val.accounts,
        contactId: val.value,
        userId: val.id,
        cpaId: val.cpa_id,
        assigneeType: val.assigne_type,
        clientTitle: val.client_title,
        contactName: val.label,
      });
    } else {
      const eleArray = this.state.accounts.map((item) => item.value);
      if (eleArray.indexOf(this.state.accountId) > -1) {
        this.setState({
          accounts: val.accounts,
          contactId: val.value,
          userId: val.id,
          selectedContact: val,
          cpaId: val.cpa_id,
          assigneeType: val.assigne_type,
          clientTitle: val.client_title,
          contactName: val.label,
        });
      } else {
        this.setState({
          accountId: '',
          accountName: '',
          accounts: val.accounts,
          contactId: val.value,
          userId: val.id,
          selectedContact: val,
          cpaId: val.cpa_id,
          assigneeType: val.assigne_type,
          clientTitle: val.client_title,
          contactName: val.label,
        });
      }
    }
    if (val && val.hasOwnProperty('label')) {
      if (
        taskTypeKey !== 'manage_to_go_items' &&
        taskTypeKey !== 'get_signature'
      ) {
        this.setTaskTitle(val.label);
      } else {
        this.setTaskTitle(
          val.label,
          this.state.refContactName,
          this.state.refAccountName,
        );
      }
      // if (taskTypeKey === 'to_do') {
      //   this.setTaskTitle(val.label, this.state.refContactName, this.state.refAccountName);
      // }
    }
    this.props.emptyError();
    this.setState({ isChanged: true }, () => {
      this.props.stateChange(this.state, true, 'contact');
    });
    if (this.props.data.isEdit) {
      this.setState({ ContactChanged: true });
    }
  };

  handleContactInput = (val) => {
    if (!this.state.accountId && val) {
      if (val.length < 2) {
        this.resetContactsAndAccounts('contacts');
      } else {
        clearTimeout(this.contactSearchTime);
        this.contactSearchTime = setTimeout(() => {
          const request = axios.get(
            `${SEARCH_TASK_CONTACTS}/?keyword=${val}&task_type_key=${this.props.data.taskTypeKey}`,
          );
          request.then((response) => {
            if (response.status === 200) {
              this.setState({ contacts: response.data }, () => {
                if (this.props.data.viewContext === 'email') {
                  this.handleContactChange(response.data[0]);
                }
              });
            }
          });
        }, 500);
      }
    }
  };

  handleDateChange = (date, action = '') => {
    const dateObj = new Date(date);
    if (
      action === 'edit' ||
      (!action &&
        date &&
        (isAfter(dateObj, new Date()) || isSameDay(dateObj, new Date())))
    ) {
      this.setState(
        {
          startDate: dateObj,
          isChanged: true,
          error: {},
        },
        () => {
          this.props.stateChange(this.state, true);
        },
      );
    } else if (action === 'delete') {
      this.setState(
        {
          startDate: dateObj,
          isChanged: true,
          error: {},
        },
        () => {
          this.props.stateChange(this.state, true);
        },
      );
    } else {
      const { error } = this.state;
      error.due_date = 'Date should not be less than current date';
      this.setState(
        {
          startDate: null,
          error,
          isChanged: true,
        },
        () => {
          this.props.stateChange(this.state, true);
        },
      );
    }
  };

  handleOwnerChange = (val) => {
    if (val) {
      if (val.value === this.state.ownerId) {
        return false;
      }
      this.setState({ ownerId: val.value, ownerName: val.label });
    } else {
      this.setState({ ownerId: '' });
    }
    if (this.props.data.isEdit) {
      this.setState({ ownerChanged: true });
    }
    this.props.emptyError();
    this.setState({ isChanged: true }, () => {
      this.props.stateChange(this.state, true);
    });
  };

  resetContactsAndAccounts(changeOnly = 'both') {
    if (changeOnly === 'accounts') {
      this.setState(
        {
          accounts: this.state.allAccounts,
          accountId: '',
          accountName: '',
          qbCustomerId: null,
        },
        () => {
          this.props.stateChange(this.state, true);
        },
      );
    } else if (changeOnly === 'contacts') {
      this.setState(
        {
          contacts: this.state.allContacts,
          contactId: '',
          userId: '',
          clientTitle: '',
          contactName: '',
        },
        () => {
          this.props.stateChange(this.state, true);
        },
      );
    } else if (changeOnly === 'ref_contacts') {
      if (!this.state.allRefContacts.length) {
        TaskApi.getToDoTaskData(this);
      }
      this.setState(
        {
          refcontactId: '',
          refContactIdtoSend: '',
          refContactName: '',
          ref_contacts: this.state.allRefContacts,
        },
        () => {
          if (!this.props.data.isEdit) {
            this.setTaskTitle(
              this.state.contactName,
              this.state.refContactName,
              this.state.refAccountName,
            );
          }
          this.props.stateChange(this.state, true);
        },
      );
    } else if (changeOnly === 'ref_accounts') {
      this.setState(
        {
          refaccountId: '',
          refAccountName: '',
          ref_accounts: this.state.allRefAccounts,
        },
        () => {
          if (!this.props.data.isEdit) {
            this.setTaskTitle(
              this.state.contactName,
              this.state.refContactName,
              this.state.refAccountName,
            );
          }
          this.props.stateChange(this.state, true);
        },
      );
    } else {
      this.setState(
        {
          contacts: this.state.allContacts,
          accounts: this.state.allAccounts,
          ref_contacts: this.state.allRefContacts,
          ref_accounts: this.state.allRefAccounts,
          contactId: '',
          userId: '',
          accountId: '',
          accountName: '',
          clientTitle: '',
          contactName: '',
          qbCustomerId: null,
        },
        () => {
          this.props.stateChange(this.state, true);
        },
      );
    }
  }

  toggleCreateInvoiceModal = () => {
    this.setState({
      showCreateInvoiceModal: !this.state.showCreateInvoiceModal,
    });
  };

  toggleSearchInvoiceModal = () => {
    this.setState({
      showSearchInvoiceModal: !this.state.showSearchInvoiceModal,
    });
  };

  handleInvoice = (input) => {
    const { account_details, invoice_details } = input;
    // if (account_details) {
    //   this.handleAccountChange(input.account_details);
    // }
    if (invoice_details) {
      this.props.getQbInvoiceDetails(invoice_details);
    }
  };

  updateIsRecurring = (input) => {
    this.setState({ is_recurring: input });
  };

  render() {
    const {
      match,
      data,
      taskDetail,
      meetingLocation,
      statedata: { durationHours, durationMinutes },
    } = this.props;
    const {
      taskStatus,
      showCreateInvoiceModal,
      showSearchInvoiceModal,
      qbCustomerId,
      accountName,
      selectedAccount,
      error,
      is_recurring,
    } = this.state;

    const path = getCurrentPath(this.props.location);

    const { errors } = this.props.statedata;
    return (
      <div>
        {data.taskTypeKey === 'invoice' ? (
          <div
            className="row"
            style={{
              position: 'absolute',
              right: '52px',
              top: '50px',
            }}
          >
            <button
              type="button"
              className="btn btn-primary mb-4 mr-2"
              onClick={this.toggleCreateInvoiceModal}
              disabled={qbCustomerId === undefined || qbCustomerId === null}
            >
              Create Invoice
            </button>
            <button
              type="button"
              className="btn btn-primary mb-4"
              onClick={this.toggleSearchInvoiceModal}
              disabled={qbCustomerId === undefined || qbCustomerId === null}
            >
              Search Invoice
            </button>
          </div>
        ) : null}
        <div id="loading" style={this.state.load ? blockStyle : noneStyle} />
        <div className="row">
          {data.taskTypeKey === 'to_do' ? (
            <SelectFieldWithAvatar
              name="searchEmployee"
              label="For Employee:"
              labelClass="mt-1 pt-2"
              options={data.isEdit ? this.state.owners : this.state.contacts}
              handleChange={this.handleContactChange}
              handleInputChange={
                data.isEdit ? () => {} : this.handleContactInput
              }
              value={{
                value: this.state.contactId,
                label: this.state.contactName,
              }}
              error={errors.contact_error}
              clearable
            />
          ) : (
            <SelectField
              name="seachAccount"
              label="For Account:"
              labelClass="mt-1 pt-2"
              options={this.state.accounts}
              handleChange={this.handleAccountChange}
              handleInputChange={this.handleAccountInput}
              value={
                this.state.accountId && this.state.accountName
                  ? {
                      value: this.state.accountId,
                      label: this.state.accountName,
                    }
                  : null
              }
              disabled={data.isEdit || path === '/accountdetails/task/:id'}
              error={errors.account_error}
              clearable
            />
          )}
          <div className="form-group form-inline-label align-items-stretch col-md-12 col-lg-6">
            <label className="mt-1 pt-2" htmlFor="date-input">
              {`${DUE_DATE}:`}
            </label>
            <div className="date-text date-calender d-flex">
              <CustomDatePicker
                toolTipLabel={DUE_DATE}
                label={`${DUE_DATE}:`}
                disabled={taskStatus === 'Closed' || is_recurring}
                selected={this.state.startDate}
                onChange={this.handleDateChange}
                minDate={new Date()}
              />
              {(data.isEdit &&
                (taskDetail.has_recurred === true ||
                  taskDetail.task_type_key === 'edoc')) ||
              (data && data.taskTypeKey === 'virtual_meeting') ||
              (data && data.taskTypeKey === 'invoice') ? null : (
                <RecurringSection
                  is_recurring={this.state.is_recurring}
                  updateIsRecurring={this.updateIsRecurring}
                  stateChange={this.props.stateChange}
                  propData={this.props.data}
                  handleDateChange={this.handleDateChange}
                  initialDate={this.initialDate}
                  taskDetail={
                    data.taskTypeKey && data.taskTypeKey.length && data.isEdit
                      ? taskDetail
                      : null
                  }
                />
              )}
              {data &&
              data.taskTypeKey === 'virtual_meeting' &&
              !meetingLocation.includes('calendly') ? (
                <div className="date-input-wrapper time-block">
                  <a
                    href="javascript:void(0);"
                    className="liscio-calendar3 cal"
                    style={{ cursor: 'default', padding: '0' }}
                  >
                    <i
                      className="icon-Clock icon-calendar vertical-middle pr-2"
                      data-tip
                      data-for="due_time_icon"
                      aria-hidden="true"
                    />
                  </a>
                  <ReactTooltip id="due_time_icon" effect="solid">
                    <span className="contactcard-team-member">
                      Meeting Time
                    </span>
                  </ReactTooltip>
                  <DatePicker
                    selected={this.state.startDate}
                    popoverTargetAttachment="right center"
                    onChange={this.handleDateChange}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={5}
                    disabled={taskStatus === 'Closed'}
                    dateFormat="h:mm a"
                    customInput={<DatePickerHTML />}
                  />
                  {getUserTimeZoneAbbr()}
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className="row">
          {data.taskTypeKey === 'to_do' ? (
            <SelectField
              name="seachAccount"
              label="For Account:"
              labelClass="mt-1 pt-2"
              options={this.state.ref_accounts}
              handleChange={this.handleRefAccountChange}
              handleInputChange={this.handleRefAccountInput}
              value={
                this.state.refaccountId && this.state.refAccountName
                  ? {
                      value: this.state.refaccountId,
                      label: this.state.refAccountName,
                    }
                  : null
              }
              disabled={path === '/accountdetails/task/:id'}
              error={errors.ref_account_error}
              clearable
            />
          ) : (
            <SelectFieldWithAvatar
              name="seachContact"
              label={`For ${
                taskDetail.task_type_key === 'edoc' ? 'Signer' : 'Contact'
              }:`}
              labelClass="mt-1 pt-2"
              options={this.state.contacts}
              handleChange={this.handleContactChange}
              handleInputChange={this.handleContactInput}
              value={{
                value: this.state.contactId,
                label: this.state.contactName,
              }}
              // value={(this.state.contactId && this.state.contactName) ? { value: this.state.contactId, label: this.state.contactName } : ''}
              disabled={data.isEdit || path === '/contactdetails/task/:id'}
              error={errors.contact_error}
              clearable
            />
          )}
          {data && data.taskTypeKey === 'virtual_meeting' ? (
            <div className="form-group form-inline-label align-items-stretch dropdown col-md-12 col-lg-6">
              <label htmlFor="zoom_meeting_id" className="mt-1 pt-2">
                Duration:
              </label>
              <input
                type="number"
                min="0"
                className="form-control col-md-2 col-sm-3"
                id="duration_hour_field"
                value={durationHours}
                onChange={(e) =>
                  this.props.handleMeetingDuration(e.target.value, 'hours')
                }
                onBlur={this.props.handleDurationOnBlur}
                disabled={
                  meetingLocation && meetingLocation.includes('calendly')
                }
              />
              <div className="d-flex align-items-center pr-2 pl-1">hr</div>
              <input
                type="number"
                min="0"
                className="form-control col-md-2 col-sm-3"
                id="duration_minute_field"
                value={durationMinutes}
                onChange={(e) =>
                  this.props.handleMeetingDuration(e.target.value, 'minuites')
                }
                onBlur={this.props.handleDurationOnBlur}
                disabled={
                  meetingLocation && meetingLocation.includes('calendly')
                }
              />
              <div className="d-flex align-items-center pl-1">min</div>
            </div>
          ) : null}
          <SelectFieldWithAvatar
            name="taskOwner"
            label="Owner:"
            labelClass="mt-1 pt-2"
            options={this.state.owners}
            handleChange={this.handleOwnerChange}
            value={{ value: this.state.ownerId, label: this.state.ownerName }}
            // value={(this.state.ownerId && this.state.ownerName) ? { value: this.state.ownerId, label: this.state.ownerName } : ''}
            error={errors.owner_error}
            clearable={false}
            disabled={
              taskStatus === 'Closed' ||
              (data && data.taskTypeKey === 'virtual_meeting')
            }
          />
          {data && data.taskTypeKey === 'virtual_meeting' ? (
            <div className="form-group form-inline-label align-items-stretch dropdown col-md-12 col-lg-6">
              <label htmlFor="zoom_meeting_id" className="mt-1 pt-2">
                Location:
              </label>
              <input
                type="text"
                className="form-control"
                id="zoom_meeting_id"
                value={meetingLocation}
              />
            </div>
          ) : null}
        </div>
        {data.taskTypeKey === 'to_do' ? (
          <div className="row">
            <SelectFieldWithAvatar
              name="seachContact"
              label="For Contact:"
              labelClass="mt-1 pt-2"
              options={this.state.ref_contacts}
              handleChange={this.handleRefContactChange}
              handleInputChange={this.handleRefContactInput}
              value={{
                value: this.state.refcontactId,
                label: this.state.refContactName,
              }}
              // value={(this.state.refcontactId && this.state.refContactName) ? { value: this.state.refcontactId, label: this.state.refContactName } : ''}
              disabled={path === '/contactdetails/task/:id'}
              error={errors.ref_contact_error}
              clearable
            />
          </div>
        ) : null}
        {showCreateInvoiceModal && (
          <CreateInvoiceModal
            toggleModal={this.toggleCreateInvoiceModal}
            onCreate={this.handleInvoice}
            selectedQbCustomerId={qbCustomerId}
            selectedQbCustomerName={accountName}
            selectedAccount={selectedAccount}
          />
        )}
        {showSearchInvoiceModal && (
          <SearchInvoiceModal
            toggleModal={this.toggleSearchInvoiceModal}
            onSelect={this.handleInvoice}
            selectedQbCustomerId={qbCustomerId}
            selectedQbCustomerName={accountName}
          />
        )}
      </div>
    );
  }
}

export default withRouter(CommonFields);
