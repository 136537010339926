import React, { useState } from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';

export default function ActionsModal(props) {
  const { modalFor, selectedRecords } = props;
  const [buttonClicked, setButtonClicked] = useState(false);

  const handleButtonClick = () => {
    props.handleSuccessBtn();
    setButtonClicked(true);
  };
  return (
    <Modal
      isOpen={props.isOpen}
      toggle={props.onCancel}
      className="customModal customModal--center customModal--w1150 customModal--nopadding"
    >
      <div className="ModalHeader">
        <button
          type="button"
          className="close"
          aria-label="Close"
          onClick={props.onCancel}
        >
          <i className="icon-close2" aria-hidden="true" />
        </button>
      </div>
      <ModalBody className="modalContent" style={props.style || ''}>
        {props.isOpen ? (
          <div className="modalContent--inner p-0">{props.children}</div>
        ) : (
          ''
        )}
      </ModalBody>
      {props.showModalFooter === undefined || props.showModalFooter ? (
        <ModalFooter className="modalContent" style={props.style || ''}>
          <button
            type="button"
            className="text-right btn"
            aria-label="close"
            onClick={props.onCancel}
          >
            Cancel
          </button>
          <button
            type="button"
            aria-label="add"
            className="text-right btn btn-primary"
            onClick={handleButtonClick}
            disabled={buttonClicked || (modalFor && !selectedRecords.length)}
          >
            Add
          </button>
        </ModalFooter>
      ) : null}
    </Modal>
  );
}
