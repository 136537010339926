import React from 'react';
import { Modal, ModalBody, Input } from 'reactstrap';

const MultiTagsModal = (props) => (
  <Modal
    className="customModal customModal--w700 customModal--nopadding MultiTagsModal"
    isOpen={props.isOpen}
  >
    <div className="ModalHeader">
      <button
        type="button"
        className="close"
        aria-label="Close"
        onClick={props.component.hideMultiTagModal}
      >
        <i className="icon-close2" aria-hidden="true" />
      </button>
    </div>
    <ModalBody className="modalContent" style={{ overflowY: 'visible' }}>
      <div className="modalContent--inner">
        <div className="modalContent--header">
          <h5 className="modalContent--header__heading">Tag Multiple Files</h5>
        </div>
        <div className="modalForm">
          <form onSubmit={props.component.handleMultiTagSubmit}>
            <div className="row">
              <div className="form-group col-12">
                <label htmlFor="multitags">Tags</label>
                <div className="labelValue customTags mailDrodown customTagsDropDown">
                  <div className="customTags__input form-control">
                    {props.component.renderMultiTags()}
                    <label htmlFor="multitags" className="inputHelper" />
                    <Input
                      type="text"
                      name="multitags"
                      id="multitags"
                      onFocus={() => props.component.onMultiTagFocus()}
                      onChange={() => false}
                      className="dropdown-toggle"
                      placeholder=""
                      value={props.data.inputMultiTagVal}
                      autoComplete="off"
                    />
                  </div>
                  {props.data.mutiltagdropdownOpen ? (
                    <div className="dropdown-menu show tagsDropDown">
                      <div className="input-group">
                        <input
                          type="text"
                          value={
                            props.data.tagId
                              ? {
                                  value: props.data.tagId,
                                  label: props.data.tagName,
                                }
                              : undefined
                          }
                          className="form-control border-0"
                          name="owner"
                          onChange={(e) =>
                            props.component.filterMultiTagSearch(e.target.value)
                          }
                          id="myInput"
                        />
                        <span className="input-group-addon border-left-0">
                          <i className="icon-zoom2" />
                        </span>
                      </div>
                      {props.multiTags}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 justify-content-end formbtn justify-content-end">
                <div className="btn-wrap btn--leftspace">
                  <button
                    type="button"
                    className="btn btn-outline-light"
                    onClick={props.component.hideMultiTagModal}
                  >
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Add Tag
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </ModalBody>
  </Modal>
);

export default MultiTagsModal;
