import { EmployeeOrContact } from 'api/employees-and-contacts';
import {
  RpaDocumentStatus,
  WfRequestSortOption,
  WorkflowRequestStatus,
} from 'gql/graphql';
import create from 'zustand';

export type TabsKey =
  | RpaDocumentStatus.Processing
  | WorkflowRequestStatus.Draft
  | WorkflowRequestStatus.Closed
  | WorkflowRequestStatus.Submitted
  | WorkflowRequestStatus.Requested
  | 'Workflow';

export type FilterKey = Exclude<
  TabsKey,
  RpaDocumentStatus.Processing | 'Workflow'
>;

type SortStatus = {
  sortField: WfRequestSortOption;
  sortDirection: 'asc' | 'desc';
};

type FilterData = {
  assignedTo: { uuid: string; type: EmployeeOrContact['type'] }[];
  owners: string[];
};

export type RequestsStateProps = {
  requestsCounts: Record<TabsKey, number | undefined>;
  sortStatus?: Partial<Record<TabsKey, SortStatus>>;
  filters: Record<FilterKey, FilterData>;
};

export interface RequestsState extends RequestsStateProps {
  setRequestsCount(requestCounts: Record<TabsKey, number | undefined>): void;
  setSortStatus(key: TabsKey, status: SortStatus): void;
  setFilters(key: TabsKey, filters: FilterData): void;
}

export const DEFAULT_STATE: RequestsStateProps = {
  requestsCounts: {
    Workflow: undefined,
    [RpaDocumentStatus.Processing]: undefined,
    [WorkflowRequestStatus.Draft]: undefined,
    [WorkflowRequestStatus.Closed]: undefined,
    [WorkflowRequestStatus.Requested]: undefined,
    [WorkflowRequestStatus.Submitted]: undefined,
  },
  sortStatus: {
    [WorkflowRequestStatus.Draft]: {
      sortField: WfRequestSortOption.LastActivity,
      sortDirection: 'asc',
    },
    [WorkflowRequestStatus.Closed]: {
      sortField: WfRequestSortOption.LastActivity,
      sortDirection: 'desc',
    },
    [WorkflowRequestStatus.Requested]: {
      sortField: WfRequestSortOption.DueDate,
      sortDirection: 'asc',
    },
    [WorkflowRequestStatus.Submitted]: {
      sortField: WfRequestSortOption.LastActivity,
      sortDirection: 'desc',
    },
  },
  filters: {
    [WorkflowRequestStatus.Requested]: { assignedTo: [], owners: [] },
    [WorkflowRequestStatus.Submitted]: { assignedTo: [], owners: [] },
    [WorkflowRequestStatus.Closed]: { assignedTo: [], owners: [] },
    [WorkflowRequestStatus.Draft]: { assignedTo: [], owners: [] },
  },
};

export const useRequestsState = create<RequestsState>()((set) => ({
  ...DEFAULT_STATE,
  setRequestsCount: (requestsCounts) => set({ requestsCounts }),
  setSortStatus: (key, status: SortStatus) =>
    set((state) => ({
      sortStatus: {
        ...state.sortStatus,
        [key]: status,
      },
    })),
  setFilters: (key, filters) =>
    set((state) => ({
      filters: {
        ...state.filters,
        [key]: filters,
      },
    })),
}));
