/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { styled } from '@stitches/react';
import { mauve } from '@radix-ui/colors';
import { ChevronRightIcon } from '@radix-ui/react-icons';
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import Avatar from '@mui/material/Avatar';
import { MenuItemIcon } from './MenuItemIcon';

// TO DO: Replaced @stitches/react with emation/styled
const contentStyles = {
  minWidth: 220,
  backgroundColor: 'white',
  borderRadius: 6,
  padding: 10,
  boxShadow: '0 10px 40px 0 rgb(0 0 0 / 10%)',
  zIndex: 1052,
};

const StyledContent = styled(DropdownMenuPrimitive.Content, {
  ...contentStyles,
});

const StyledArrow = styled(DropdownMenuPrimitive.Arrow, { fill: 'white' });

function Content({ children, ...props }) {
  return (
    <DropdownMenuPrimitive.Portal>
      <StyledContent {...props}>
        {children}
        <StyledArrow />
      </StyledContent>
    </DropdownMenuPrimitive.Portal>
  );
}

const StyledSubContent = styled(DropdownMenuPrimitive.SubContent, {
  ...contentStyles,
});

function SubContent(props) {
  return (
    <DropdownMenuPrimitive.Portal>
      <StyledSubContent {...props} />
    </DropdownMenuPrimitive.Portal>
  );
}

const itemStyles = {
  all: 'unset',
  fontSize: '0.94em',
  lineHeight: 1,
  borderRadius: '6px',
  display: 'flex',
  alignItems: 'center',
  padding: '15px 20px',
  position: 'relative',
  paddingLeft: 25,
  userSelect: 'none',
  color: '#212b36',

  '&[data-disabled]': {
    color: mauve.mauve8,
    pointerEvents: 'none',
  },

  '&[data-highlighted]': { backgroundColor: '#dfe3e8' },
};

const StyledItem = styled(DropdownMenuPrimitive.Item, {
  ...itemStyles,
  cursor: 'pointer',
});
const StyledSubTrigger = styled(DropdownMenuPrimitive.SubTrigger, {
  '&[data-state="open"]': { backgroundColor: '#dfe3e8' },
  ...itemStyles,
  cursor: 'pointer',
});

const StyledLabel = styled(DropdownMenuPrimitive.Label, {
  paddingLeft: 25,
  fontSize: 12,
  lineHeight: '25px',
  color: mauve.mauve11,
});

const StyledSeparator = styled(DropdownMenuPrimitive.Separator, {
  height: 1,
  margin: 5,
});

const StyledItemIndicator = styled(DropdownMenuPrimitive.ItemIndicator, {
  position: 'absolute',
  left: 0,
  width: 25,
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const ItemIcon = styled('div', {
  paddingRight: 20,
  width: '36px',
  color: 'rgba(0, 0, 0, 0.54)',
  'font-size': '0.94rem',
});

// Exports
export const DropdownMenu = DropdownMenuPrimitive.Root;
export const DropdownMenuTrigger = DropdownMenuPrimitive.Trigger;
export const DropdownMenuContent = Content;
export const DropdownMenuItem = StyledItem;
export const DropdownMenuItemIndicator = StyledItemIndicator;
export const DropdownMenuLabel = StyledLabel;
export const DropdownMenuSeparator = StyledSeparator;
export const DropdownMenuSub = DropdownMenuPrimitive.Sub;
export const DropdownMenuSubTrigger = StyledSubTrigger;
export const DropdownMenuSubContent = SubContent;

// Your app...
const Box = styled('div', {});

const RightSlot = styled('div', {
  marginLeft: 'auto',
  paddingLeft: 20,
  color: mauve.mauve11,
  '[data-highlighted] > &': { color: 'white' },
  '[data-disabled] &': { color: mauve.mauve8 },
});

const SubMenuItem = ({ items, onSelect, subMenuType }) => (
  <DropdownMenuSub key={items.id}>
    <DropdownMenuSubTrigger>
      {items.icon_class && (
        <ItemIcon>
          <MenuItemIcon iconClass={items.icon_class} />
        </ItemIcon>
      )}
      {items.label}
      <RightSlot>
        <ChevronRightIcon />
      </RightSlot>
    </DropdownMenuSubTrigger>
    <DropdownMenuSubContent
      sideOffset={2}
      alignOffset={-5}
      side="right"
      data-testid={`${items?.label?.toLowerCase()}__dropdown`}
    >
      {items.sub_child.map((item) => {
        if (item.sub_child?.length) {
          return <SubMenuItem item={item} onSelect={onSelect} key={item.id} />;
        }
        return (
          <DropdownMenuItem
            key={item.id}
            onSelect={(e) => onSelect(e, item, subMenuType)}
            data-selected={subMenuType}
            data-testid={item?.label?.toLowerCase()?.split(' ')?.join('_')}
          >
            {item.icon_class && (
              <ItemIcon>
                <MenuItemIcon iconClass={item.icon_class} />
              </ItemIcon>
            )}
            {item.image && (
              <Avatar
                alt={item.label}
                src={item.image}
                sx={{ width: 32, height: 32, marginRight: '4px' }}
              />
            )}
            {item.label}
          </DropdownMenuItem>
        );
      })}
    </DropdownMenuSubContent>
  </DropdownMenuSub>
);

const MultipleLevelMenu = ({ menuItems, component, onSelect, subMenuType }) => (
  <Box>
    <DropdownMenu dir={['right']}>
      <DropdownMenuTrigger asChild>{component}</DropdownMenuTrigger>

      <DropdownMenuContent
        side="right"
        align="start"
        data-testid={`${menuItems?.label
          ?.toLowerCase()
          ?.split(' ')
          ?.join('_')}__dropdown`}
      >
        {menuItems.children.map((item) => {
          if (item.sub_child?.length > 0) {
            return (
              <SubMenuItem
                items={item}
                onSelect={onSelect}
                subMenuType={subMenuType}
                key={item.id}
              />
            );
          }
          return (
            <DropdownMenuItem
              key={item.id}
              data-selected={subMenuType}
              onSelect={(e) => onSelect(e, item, subMenuType)}
              data-testid={item?.label?.toLowerCase()?.split(' ')?.join('_')}
            >
              {item.icon_class && (
                <ItemIcon>
                  <MenuItemIcon iconClass={item.icon_class} />
                </ItemIcon>
              )}
              {item.image && (
                <Avatar
                  alt={item.label}
                  src={item.image}
                  sx={{ width: 20, height: 20, marginRight: '8px' }}
                />
              )}
              {item.label}
            </DropdownMenuItem>
          );
        })}
      </DropdownMenuContent>
    </DropdownMenu>
  </Box>
);

export default MultipleLevelMenu;
