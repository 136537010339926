import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import Interweave from 'interweave';
import { withRouter } from 'utilities/withRouterWrapper';
import { Scrollbars } from 'react-custom-scrollbars-2';
import axios from 'axios';
import * as Config from 'constants/constants';
import PrintPdfButton from './PrintPdfButton';

class TermsOfService extends React.Component {
  constructor(props) {
    super(props);
    this.state = { termsData: {} };
  }

  UNSAFE_componentWillMount() {
    const component = this;
    console.log(component.props);
    let url = Config.GET_TERM_CONDITIONS;
    if (component.props.match.pathname === '/client_terms') {
      url = `${Config.GET_TERM_CONDITIONS}?for_client=true`;
    }
    const request = axios.get(url);
    request.then((response) => {
      if (
        response.data.status === 200 &&
        Object.keys(response.data.data).length
      ) {
        component.setState({ termsData: response.data });
      }
    });
  }

  componentDidUpdate() {
    const clientTerm = document.getElementById('ClientTerm');
    const clientPrivacy = document.getElementById('ClientPrivacy');
    if (clientTerm) {
      clientTerm.addEventListener('click', () => {
        window.open('/client_terms', '_blank');
      });
    }
    if (clientPrivacy) {
      clientPrivacy.addEventListener('click', () => {
        window.open('/privacy_policy', '_blank');
      });
    }
  }

  render() {
    const { termsData } = this.state;
    if (Object.keys(termsData).length) {
      return (
        <div>
          <Modal
            className="customModal customModal--w990 customModal--nopadding"
            isOpen={this.props.modalClick}
          >
            <div className="ModalHeader">
              <button
                type="button"
                onClick={this.props.closeModal}
                className="close"
                aria-label="Close"
              >
                <i className="icon-close2" aria-hidden="true" />
              </button>
            </div>
            <ModalBody className="modalContent" style={{ maxHeight: 'none' }}>
              <div className="modalContent--inner pt-0">
                <div className="col-container col-containerIE">
                  <div className="text-right mt-3 mb-3">
                    <PrintPdfButton
                      content_id="termandconditions"
                      iframe_id="ifmcontentstoprint"
                    />
                  </div>
                </div>
                <div className="docWrap">
                  <Scrollbars
                    style={{ width: '100%', height: '100%' }}
                    autoHide
                    autoHideTimeout={1000}
                  >
                    <div
                      id="termandconditions"
                      className="term-wrapper inviteTerms"
                    >
                      <Interweave
                        allowAttributes
                        content={termsData.data.content}
                      />
                    </div>
                  </Scrollbars>
                </div>
              </div>
            </ModalBody>
          </Modal>
        </div>
      );
    }
    return null;
  }
}

export default withRouter(TermsOfService);
