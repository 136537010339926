import React from 'react';
import styled from '@emotion/styled';

const StyledSortSelect = styled.select`
  height: 40px;
  background-color: transparent;
  cursor: pointer;
  line-height: 1.75;
  box-shadow: none;
  text-transform: none;
  padding: 2px;
  box-sizing: border-box;
  transition: all ease-in-out 200ms;
  min-width: 12px;
  border: 2px solid #dfe3e8;
  border-radius: 2px;
  padding-left: 5px;
  color: #919eab;
  font-weight: 400;
  font-size: 0.99rem;
  option {
    padding: 4px;
  }
`;

function FilterBySorting({ sortBy, setSortBy }) {
  return (
    <StyledSortSelect
      id="AccountActivitySort"
      defaultValue={sortBy}
      onChange={(e) => setSortBy(e.target.value)}
    >
      <option value="desc">Descending</option>
      <option value="asc">Ascending</option>
    </StyledSortSelect>
  );
}

export default FilterBySorting;
