import { useQuery } from '@tanstack/react-query';
import {
  getEmpAndContactApiPath,
  getEmpAndContactOfAccount,
  getSearchTaskContacts,
} from 'api/contacts';
import { useEffect, useState } from 'react';

type ContactsUnion = 'all' | 'none';

const getFilteredContacts = (contactList: LegacyContact[]) =>
  contactList.filter((item) => item.type === 'contact' && item.email);

function useEmpAndContactApiPath() {
  return useQuery({
    queryKey: ['empAndContactApiPath'],
    queryFn: getEmpAndContactApiPath,
  });
}

export function useEmpAndContactOfAccount(accountId?: number | string) {
  return useQuery({
    queryKey: ['empAndContactOfAccount', accountId],
    queryFn: () => getEmpAndContactOfAccount(accountId),
    enabled: Boolean(accountId),
  });
}

function useSearchTaskContacts(keyword: string) {
  return useQuery({
    queryKey: ['searchTaskContacts', keyword],
    queryFn: () => getSearchTaskContacts(keyword),
    enabled: keyword.length > 0,
  });
}

/**
 * This hook is used to access either the full list of contacts or
 * a filtered list based on the keyword passed into the setQueryKeyword method.
 *
 * @returns {Object} result The returnd object of this hook
 * @returns {LegacyContact[]} result.contacts A filtered list of contacts
 * @returns {function} result.setContacts Accepts either 'all' to get the full list or 'none' for an empty array
 * @returns {function} result.setQueryKeyword Sets the keyword used to filter the list
 */
export function useSearchTaskContactData() {
  const [keyword, setKeyword] = useState('');
  const [contactList, setContactList] = useState<LegacyContact[]>([]);
  const { data: empContacts } = useEmpAndContactApiPath();
  const { data: searchContacts } = useSearchTaskContacts(keyword);

  // for the inital load of contacts
  useEffect(() => {
    if (empContacts && empContacts.length > 0)
      setContactList(getFilteredContacts(empContacts));
  }, [empContacts]);

  // for contact query with keyword lookup
  useEffect(() => {
    if (searchContacts && searchContacts.length > 0)
      setContactList(getFilteredContacts(searchContacts));
  }, [searchContacts]);

  const setContacts = (union: ContactsUnion) => {
    setContactList(getFilteredContacts(empContacts as LegacyContact[]));
    if (union === 'none') setContactList([]);
  };

  const setQueryKeyword = (queryKeyword: string) => {
    setKeyword(queryKeyword);
  };

  return { contacts: contactList, setContacts, setQueryKeyword };
}
