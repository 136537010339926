/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-cycle */
/* eslint-disable no-undef */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-shadow */

import React, { useCallback, useEffect, useState } from 'react';
import { withRouter } from 'utilities/withRouterWrapper';
import Axios from 'axios';
import {
  USER_EMAILS,
  LINK_EMAILS,
  GET_CONTACTS_OF_ACCOUNT_API_PATH,
  GET_ACCOUNTS_OF_CONTACT_API_PATH,
  ENTITY_EMAIL_THREADS,
} from 'constants/constants';
import { getCurrentPath } from 'utilities/utils';
import styled from '@emotion/styled';
import { Scrollbars } from 'react-custom-scrollbars-2';
import NoRecords from 'common/NoRecords';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useLinkedEmailsUrl } from 'hooks/use-linked-emails-url';
import EachMessage from './EachMessage';
import EmailActionsDropdown from './EmailActionsDropdown';
import LinkedWith from './LinkedWith';
import useWhichSection from './useWhichSection';

const EmRight = styled.div`
  padding-left: 1.5rem;
  height: 100%;
  position: relative;
  .emailTaskList {
    position: absolute;
    width: 100%;
    max-width: 1130px;
    left: 26px;
    bottom: 0px;
    padding: 15px;
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
    z-index: 99;
    & > div {
      margin: 0;
      & + div {
        margin-top: 0.5rem;
      }
    }
  }
  .EmRight {
    &__Head {
      padding-top: 0.25rem;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      .EmHead__Leftcol {
        flex-grow: 1;
        max-width: 100%;
        padding: 0;
      }
    }
    &__Row {
      display: flex;
      justify-content: space-between;
    }
  }
  .EmRight__Row--LRSpace {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .EmRight__EmWrap {
    height: calc(100% - 45px);
  }
`;

const EmailThreadDetails = (props) => {
  const { linkedEmailsRefactor } = useFlags();
  const { slideReply, cancelActionsModel, noRecordsWithSearch, from } = props;
  const [email, setEmail] = useState();
  const [emailLinkedTo, setEmailLinkedTo] = useState();
  const [composeEmailFor, setComposeEmailFor] = useState('');
  const [composeEmailData, setComposeEmailData] = useState();
  const [connectedAppDetails, setConnectedAppDetails] = useState();
  const [accountEmailLinkedBox, setAccountEmailLinkedBox] = useState(false);
  const [contactEmailLinkedBox, setContactEmailLinkedBox] = useState(false);
  const [contactAccountLinked, setContactAccountLinked] = useState();
  const [accountContactLinked, setAccountContactLinked] = useState();
  const { inLinkedSection } = useWhichSection();

  const linkedEmailsUrl = useLinkedEmailsUrl();

  const actionType = props.location.state?.actionType;

  const getShowUnreadOption = useCallback(
    (isLastMessage) => {
      if ((from && from === 'linkedEmailAccConTask') || inLinkedSection) {
        return false;
      }
      return isLastMessage;
    },
    [from, inLinkedSection],
  );

  const emailAssociationBlock =
    document.getElementsByClassName('emailTaskList')[0];
  const emailAssociationHeight = emailAssociationBlock
    ? emailAssociationBlock.clientHeight
    : 0;

  // eslint-disable-next-line no-shadow
  const composeEmailAction = (
    // eslint-disable-next-line no-shadow
    actionType,
    email_data,
    send_data = [],
    deleteDraft = '',
    discardData = '',
  ) => {
    const doNotComposeActions = ['add_to_account', 'add_to_contact'];
    const shouldShowTextEditor = !doNotComposeActions.includes(actionType);

    if (shouldShowTextEditor) {
      setComposeEmailFor(actionType);
      setComposeEmailData(email_data);
    }

    let newArr = [];
    if (send_data.length) {
      // this is when new email which is draft is sent
      if (email.length === 1 && email[0].draft_id) {
        newArr = [];
      } else if (deleteDraft) {
        newArr = email.map((each) => {
          if (
            each.draft_data &&
            each.draft_data.draft_id === deleteDraft.draftId
          ) {
            const delDrft = { ...each };
            delete delDrft.draft_data;
            return { ...delDrft, arrow_position: 'down' };
          }
          return { ...each, arrow_position: 'down' };
        });
      } else {
        newArr = email.map((v) => ({ ...v, arrow_position: 'down' }));
      }
      const sendArr = send_data.map((v, index) =>
        index === send_data.length - 1
          ? { ...v, arrow_position: 'up' }
          : { ...v, arrow_position: 'down' },
      );

      setEmail([...newArr, ...sendArr]);
      if (slideReply) {
        cancelActionsModel();
      }
    }
    if (deleteDraft && deleteDraft === 'goBack') {
      props.navigate(-1);
    } else if (deleteDraft && deleteDraft === 'discard' && discardData) {
      const draftId =
        (discardData.draft_data
          ? discardData.draft_data.draft_id
          : discardData.draft_id) || discardData.draftId;
      if (draftId) {
        Axios.delete(`${USER_EMAILS}/${draftId}`);
      }
      if (discardData.draft_data && typeof discardData === 'object') {
        const indx = email.findIndex(
          (e) => e.email_id === discardData.email_id,
        );
        newArr = email.map((v, index) => {
          if (index === indx) {
            const delDrft = { ...v };
            delete delDrft.draft_data;
            return { ...delDrft, arrow_position: 'down' };
          }
          return { ...v };
        });
        if (newArr?.length > 0) {
          setEmail([...newArr]);
        }
      } else if (
        !discardData.draft_data &&
        discardData.draft_id &&
        typeof discardData === 'object'
      ) {
        if (email.length === 1) {
          setEmail([]);
          props.refreshPage('discardData');
        }
      } else {
        // props.navigate(-1);
      }
    }
  };
  useEffect(() => {
    if (emailAssociationHeight) {
      const EmRightBlk = document.getElementsByClassName('EmRight')[0];
      if (EmRightBlk) {
        EmRightBlk.style.paddingBottom = `${emailAssociationHeight + 20}px`;
      }
    }
  }, [emailAssociationHeight]);

  // for getting thread details
  const getThreadDetails = async () => {
    const { contactId, accountId } = props;
    try {
      props.setLoading(true);
      const inContactSection =
        window.location.href.includes('/contactdetails/');
      const inAccountSection =
        window.location.href.includes('/accountdetails/');
      const inLinkedSection = inAccountSection || inContactSection;
      const apiUrl =
        window.location.href.includes('#emails?id=') || inLinkedSection
          ? linkedEmailsUrl
          : USER_EMAILS;

      const accountType = inAccountSection && 'Account';
      const contactType = inContactSection && 'Contact';

      const threadDetailUrl = `${apiUrl}/${props.selectedEmailId}${
        linkedEmailsRefactor && inLinkedSection
          ? `?entity_id=${
              inContactSection ? contactId : accountId
            }&entity_type=${accountType || contactType}`
          : ''
      }`;
      const { data: response, app_token } = await Axios.get(
        threadDetailUrl,
      ).then((res) => res.data);
      const firstEmail = response[0];

      const linkedDataUrl = linkedEmailsRefactor
        ? `${ENTITY_EMAIL_THREADS}/${firstEmail.entity_email_thread_id}/linked_to`
        : `${LINK_EMAILS}/${firstEmail.liscio_email_thread_id}/thread_links`;

      const { data: linkedData } = await Axios.get(linkedDataUrl);

      if (response) {
        props.setLoading(false);
      }
      document.title = response?.[0]?.subject;
      const emailInThread = response.filter((e) => e.draft_id === null);
      const emailInDraft = response.filter((e) => e.draft_id !== null);

      setConnectedAppDetails(app_token);
      if (emailInDraft.length && emailInThread.length) {
        const emails = emailInThread;
        emailInDraft.map((each) => {
          const indx = emails.findIndex(
            (e) => e.msg_id === each.reply_to_msg_id,
          );
          if (indx !== -1) {
            // emails.splice(indx + 1, 0, each);
            emails[indx].draft_data = each;
          }
          return emails;
        });
        setEmail(emails);
      } else if (!emailInDraft.length && emailInThread.length) {
        setEmail(emailInThread);
      } else if (emailInDraft.length) {
        setEmail(emailInDraft);
      }
      setEmailLinkedTo(linkedData);
      if (actionType && actionType !== 'linked') {
        composeEmailAction(actionType, response[response.length - 1]);
      }
      const linkedAccountId = linkedData?.accounts?.map((item) => item.id);
      if (linkedAccountId !== '') {
        const { data } = await Axios.get(
          `${GET_CONTACTS_OF_ACCOUNT_API_PATH}/${linkedAccountId}`,
        );
        setContactAccountLinked(data.data);
      }
      const linkedContactId = linkedData?.contacts?.map((item) => item.id);
      if (linkedContactId !== '') {
        const { data } = await Axios.get(
          `${GET_ACCOUNTS_OF_CONTACT_API_PATH}/${linkedContactId}`,
        );
        setAccountContactLinked(data.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const toggleArrow = (item) => {
    const threadArray = email.map((ele) => {
      if (ele.email_id === item.email_id) {
        if (ele.arrow_position === 'up') {
          ele.arrow_position = 'down';
        } else {
          ele.arrow_position = 'up';
        }
      }
      return ele;
    });
    setEmail(threadArray);
  };

  useEffect(() => {
    if (props.selectedEmailId) {
      setEmail([]);
      setEmailLinkedTo();
      setComposeEmailFor('');
      setComposeEmailData();
      getThreadDetails();
      setAccountEmailLinkedBox(false);
      setContactEmailLinkedBox(false);
      if (gapi) {
        gapi.load('client');
      }
    }
  }, [props.selectedEmailId, actionType]);

  const unlinkEmail = (refreshthread = '', unlink = []) => {
    const path = getCurrentPath(props.location);
    const { accountId, contactId } = props;
    if (
      ((window.location.href.includes('#emails?id=') ||
        path === '/accountdetails/:id/emails/:email_id' ||
        path === '/contactdetails/:id/emails/:email_id') &&
        !refreshthread) ||
      ((path === '/accountdetails/:id/emails/:email_id' ||
        path === '/contactdetails/:id/emails/:email_id') &&
        (accountId || contactId) &&
        unlink.includes(parseInt(accountId || contactId, 10)) &&
        refreshthread &&
        unlink.length > 0)
    ) {
      props.newPage();
    } else {
      getThreadDetails();
    }
  };

  const redirectLink = (path, id) => {
    const idd = encodeURIComponent(id);
    props.navigate('/redirect');
    setTimeout(() => {
      props.navigate(`${path}${idd}`, { state: { from: 'uploadlist' } });
    }, 50);
  };

  const emailLinkedBox = (type, value) => {
    if (type === 'add_to_account') {
      setAccountEmailLinkedBox(value);
    }
    if (type === 'add_to_contact') {
      setContactEmailLinkedBox(value);
    }
  };

  useEffect(() => {
    emailLinkedBox(actionType, true);
  }, [props.selectedEmailId, actionType]);

  const association =
    emailLinkedTo?.tasks?.length > 0 ||
    emailLinkedTo?.messages?.length > 0 ||
    emailLinkedTo?.notes?.length > 0;

  if (props.selectedEmailId && email?.length > 0) {
    return (
      <EmRight className="EmRight">
        <div className="EmRight__Head">
          <div className="EmRight__Row EmRight__Row--LRSpace">
            <div className="EmHead__Leftcol">
              <h4>{email[0].subject}</h4>
            </div>
            <div className="EmRight__Head__Right">
              {email &&
              email.length &&
              (email.length > 1 ||
                (email.length === 1 &&
                  (email[0].draft_data || !email[0].draft_id))) ? (
                <EmailActionsDropdown
                  IconClass="text-primary"
                  emailDetails={email[0]}
                  emailThread={email}
                  connectedApp={connectedAppDetails.app_key}
                  refreshPage={props.refreshPage ? props.refreshPage : null}
                  nextThread={props.nextThread ? props.nextThread : null}
                  unreadEmail={props.unreadEmail}
                  viewContextFrom="thread"
                  emailLinkedBox={emailLinkedBox}
                  getThreadDetails={getThreadDetails}
                  emailDetailMode={props.emailDetailMode}
                  handleEmailDetailMode={
                    props.handleEmailDetailMode
                      ? props.handleEmailDetailMode
                      : null
                  }
                  composeEmailAction={composeEmailAction}
                  refreshNotes={getThreadDetails}
                />
              ) : null}
            </div>
          </div>
        </div>
        <div className="EmRight__EmWrap">
          <Scrollbars
            style={{
              width: '100%',
              height: '100%',
              minHeight: '100%',
              maxHeight: '100%',
            }}
            autoHide
            autoHeightMin={0}
            autoHideTimeout={1000}
          >
            {email
              ? email.map((message, index) => {
                  const { setLoading, unreadEmail, refreshPage } = props;
                  const { app_key, access_token } = connectedAppDetails;

                  const isLastMessage = index === email.length - 1;

                  const itemShouldExpand =
                    (message.draft_id === null ||
                      accountEmailLinkedBox ||
                      contactEmailLinkedBox) &&
                    (isLastMessage || email.length === 1);

                  const showUnreadOption = getShowUnreadOption(isLastMessage);

                  return (
                    <div key={message.msg_id} className="emailsData">
                      <EachMessage
                        getThreadDetails={getThreadDetails}
                        setLoading={setLoading}
                        data={message}
                        connectedApp={app_key}
                        connectedAppAccessToken={access_token}
                        toggleArrow={toggleArrow}
                        refreshPage={refreshPage}
                        composeEmailAction={composeEmailAction}
                        composeEmailFor={composeEmailFor}
                        composeEmailData={composeEmailData}
                        expandMail={itemShouldExpand}
                        refreshForVault={unlinkEmail}
                        unreadEmail={unreadEmail}
                        showUnreadOption={showUnreadOption}
                        emailLinkedTo={emailLinkedTo}
                        unlinkEmail={unlinkEmail}
                        redirectLink={redirectLink}
                        emailId={email[0].email_id}
                        accountEmailLinkedBox={accountEmailLinkedBox}
                        contactEmailLinkedBox={contactEmailLinkedBox}
                        emailLinkedBox={emailLinkedBox}
                        emailLinkedData={emailLinkedTo}
                        contactAccountLinked={contactAccountLinked}
                        accountContactLinked={accountContactLinked}
                      />
                    </div>
                  );
                })
              : ''}
          </Scrollbars>
        </div>
        {association && (
          <div className="emailTaskList">
            {emailLinkedTo?.tasks?.length > 0 &&
              emailLinkedTo.tasks.map((task) => (
                <LinkedWith
                  key={task.id}
                  id={task.id}
                  linkedIcon="icon-Tasks"
                  linkedType="task"
                  title={task.subject}
                  refreshPage={unlinkEmail}
                  date={task.created_at}
                  to={`/task/detail/${task.id}`}
                  liscio_email_thread_link_id={task.liscio_email_thread_link_id}
                />
              ))}
            {emailLinkedTo?.messages?.length > 0 &&
              emailLinkedTo.messages.map((message) => (
                <LinkedWith
                  id={message.id}
                  linkedIcon="icon-message"
                  linkedType="message"
                  title={message.subject}
                  refreshPage={unlinkEmail}
                  date={message.created_at}
                  to={`/sent/${encodeURIComponent(message.id)}`}
                  liscio_email_thread_link_id={
                    message.liscio_email_thread_link_id
                  }
                />
              ))}
            {emailLinkedTo?.notes?.length > 0 &&
              emailLinkedTo.notes.map((notes) => (
                <LinkedWith
                  id={notes.id}
                  linkedIcon="icon-notes"
                  linkedType="note"
                  title={notes.subject}
                  to={`/notedetails/${notes.id}`}
                  refreshNotes={getThreadDetails}
                  refreshPage={unlinkEmail}
                  liscio_email_thread_link_id={
                    notes.liscio_email_thread_link_id
                  }
                />
              ))}
          </div>
        )}
      </EmRight>
    );
  }
  if (noRecordsWithSearch) {
    return <NoRecords description="No records match your search." />;
  }
  return (
    <div className="em_right pl-4 h-100">
      <div className="em_right_head my-2 py-1">
        {/* <div className="em_right_head_links text-right">
          Links
        </div> */}
      </div>
      <div className="p-3">
        <div className="m-5 p-5 text-center">
          <i className="text-large icon-notification-message mb-2 text-dark" />
          <h3 className="text-medium">Select an item to read</h3>
          <h6 className="text-dark">Nothing is selected</h6>
        </div>
      </div>
    </div>
  );
};

export default withRouter(React.memo(EmailThreadDetails));
