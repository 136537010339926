/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import { VIEW_NOTIFICATION } from 'constants/constants';
import Pagination from 'common/Pagination';
import Interweave from 'interweave';
import * as NotificationActions from 'actions/NotificationActions';
import * as paginationActions from 'actions/PaginationActions';
import NoRecords from 'common/NoRecords';
import * as Notification from 'api/Notification';
import { selectStyles, selectTheme } from 'common/CustomFields';

const noneStyle = { display: 'none' };
const blockStyle = { display: 'block' };
class NotificationList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      notifications: [],
      sort: 'Descending',
      filter: 'Unread',
      paginationOffset: '',
      paginationOff: '',
      totalRecords: '',
      sortBy: 'desc',
    };
  }

  componentDidMount() {
    document.title = 'Notifications';
    const { match } = this.props;
    if (
      match &&
      match.pathname === '/notifications' &&
      this.props.action === 'POP'
    ) {
      this.props.pagination.notificationPagination(
        this.props.notification_page,
      );
      this.setState(
        {
          sort:
            this.props.notification_sort === 'Ascending'
              ? 'Ascending'
              : 'Descending',
          sortBy: this.props.notification_sort === 'Ascending' ? 'asc' : 'desc',
          filter: this.props.notification_filter === 'All' ? 'All' : 'Unread',
        },
        () => {
          Notification.getNotification(
            this,
            this.state.filter,
            true,
            this.props.notification_page,
            this.state.sortBy,
          );
        },
      );
    } else {
      this.props.pagination.notificationPagination(1);
      this.props.NotificationActions.notificationSort('Descending');
      this.props.NotificationActions.notificationFilter('Unread');
      Notification.getNotification(
        this,
        this.state.filter,
        true,
        1,
        this.state.sortBy,
      );
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { notiCount, match } = this.props;
    if (
      match &&
      match.pathname === '/notifications' &&
      nextProps.notiCount === 0 &&
      notiCount !== nextProps.notiCount
    ) {
      this.props.pagination.notificationPagination(
        this.props.notification_page,
      );
      this.setState(
        {
          sort:
            this.props.notification_sort === 'Ascending'
              ? 'Ascending'
              : 'Descending',
          sortBy: this.props.notification_sort === 'Ascending' ? 'asc' : 'desc',
          filter: this.props.notification_filter === 'All' ? 'All' : 'Unread',
        },
        () => {
          Notification.getNotification(
            this,
            this.state.filter,
            true,
            this.props.notification_page,
            this.state.sortBy,
          );
        },
      );
    }
  }

  handlePageChange = (index) => {
    if (index === this.props.notification_page) {
      return false;
    }
    this.props.pagination.notificationPagination(index);
    Notification.getNotification(
      this,
      this.state.filter,
      true,
      index,
      this.state.sortBy,
    );
    // scrolls to top
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
    return true;
  };

  rowClick(item) {
    const { navigate } = this.props;
    let notiType = item.type && item.type.toLowerCase();
    if (notiType === 'wfrequest') notiType = 'request';
    if (
      ['request', 'task reminder summary'].includes(notiType) &&
      !item.is_read_in_app
    ) {
      axios.put(`${VIEW_NOTIFICATION}/${item.primary_id}`);
    }
    const messageUrl = item.url;
    // if (isUserEmployee() && notiType === 'message' && title && (title.includes(substringOne) || title.includes(substringTwo) || title.includes(substringThree))) {
    //   const messageId = item.url.split('/')[1];
    //   messageUrl = 'alert/' + messageId;
    // }

    navigate(
      `/${notiType === 'task' ? `task/detail/${item.id}` : messageUrl}`,
      { state: { from: 'notify' } },
    );
  }

  filteredList = (val, fro) => {
    if (fro === 'filter') {
      this.setState({ filter: val.value }, () => {
        this.props.pagination.notificationPagination(1);
        this.props.NotificationActions.notificationFilter(this.state.filter);
        Notification.getNotification(
          this,
          this.state.filter,
          true,
          1,
          this.state.sortBy,
        );
      });
    } else if (fro === 'sort') {
      this.setState(
        {
          sortBy: val.value === 'Descending' ? 'desc' : 'asc',
          sort: val.value,
        },
        () => {
          this.props.pagination.notificationPagination(1);
          this.props.NotificationActions.notificationSort(this.state.sort);
          Notification.getNotification(
            this,
            this.state.filter,
            true,
            1,
            this.state.sortBy,
          );
        },
      );
    }
  };

  render() {
    const { notifications } = this.state;
    let totalpages =
      this.state.totalRecords !== 0
        ? Math.ceil(this.state.totalRecords / 20)
        : 0;
    totalpages = parseInt(totalpages, 10);
    const totalrecords = this.state.totalRecords;
    let offset = 0;
    let off = 0;
    if (this.state.notifications !== undefined) {
      offset = this.state.paginationOffset;
      off = this.state.paginationOff;
    }
    let notificationList = [];
    if (notifications && notifications.length) {
      notificationList = notifications.map((item, index) => {
        const notiType = item.type.toLowerCase();
        const alertUrl = item.url.split('/')[0];
        const initials = item.sender
          ? (item.sender.split(' ')[0]
              ? item.sender.split(' ')[0].charAt(0)
              : '') +
            (item.sender.split(' ')[1]
              ? item.sender.split(' ')[1].charAt(0)
              : '')
          : '';
        return (
          <div key={index} className="listRow__list innerList">
            <div className="avatar avatar--w20 mr-1">
              {item.is_read_in_app ? null : (
                <span className="rounded status visible" />
              )}
              {item.avatar ? (
                <img src={item.avatar} alt="User Name" />
              ) : (
                <span className="uName">{initials}</span>
              )}
            </div>
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => this.rowClick(item)}
              className="listRow__list__detail"
            >
              <div className="d-flex justify-content-between">
                <label className="subHeadline text-dark text-uppercase mb-0">
                  <i
                    className={`icon-${
                      notiType === 'task' ? 'Tasks' : 'message2'
                    } mr-1 vertical-middle`}
                  />
                  {alertUrl === 'alert' ? 'Alert' : item.type}
                </label>
                <span className="text-dark">{item.tym}</span>
              </div>
              <h6 className="text-body text-left mt-0 mb-0 font-weight-bold">
                <b>{item.sender ? item.sender : ''}</b>
              </h6>
              {item.title ? (
                <h6 className="text-left mt-0 mb-0 font-weight-bold text-dark">
                  {item.title}
                </h6>
              ) : null}
              <p className="mb-0 text-dark">
                <Interweave allowAttributes content={item.content} />
              </p>
            </div>
          </div>
        );
      });
    }
    return (
      <div id="content" className="">
        <header>
          <h1 className="pageHeading">Notifications</h1>
        </header>
        <div id="loading" style={this.state.loading ? blockStyle : noneStyle} />
        <div className="center-wrapper">
          <div className="row justify-content-end pb-3 notifyList">
            <div className="form-group has-search mr-auto d-none">
              <span className="icon-zoom2 form-control-feedback" />
              <input
                type="text"
                className="form-control"
                placeholder="Search"
              />
            </div>
            <div className="form-group form-inline-label filterBy align-items-stretch mb-0 pr-1">
              <label className="mt-1 pt-1 text-body noWrap">Filter by</label>
              <Select
                searchable={false}
                onChange={(e) => this.filteredList(e, 'filter')}
                clearable={false}
                className="select-custom-class"
                value={{ value: this.state.filter, label: this.state.filter }}
                options={[
                  { value: 'Unread', label: 'Unread' },
                  { value: 'All', label: 'All' },
                ]}
                styles={selectStyles}
                theme={selectTheme}
              />
            </div>
            <div className="form-group form-inline-label sortBy align-items-stretch mb-0">
              <label className="mt-1 pt-1 text-body noWrap">Sort by</label>
              <Select
                searchable={false}
                onChange={(e) => this.filteredList(e, 'sort')}
                clearable={false}
                className="select-custom-class"
                value={{ value: this.state.sort, label: this.state.sort }}
                options={[
                  { value: 'Ascending', label: 'Ascending' },
                  { value: 'Descending', label: 'Descending' },
                ]}
                styles={selectStyles}
                theme={selectTheme}
              />
            </div>
          </div>
          <div className="notifyRow">
            <div className="listRow">
              {notifications && notifications.length ? (
                notificationList
              ) : this.state.loading ? (
                <div className="center-wrapper" />
              ) : (
                <div className="center-wrapper">
                  <NoRecords />
                </div>
              )}
            </div>
          </div>
          {notifications.length ? (
            <div className="pt-3">
              <Pagination
                currentPage={
                  notificationList.length >= 1
                    ? this.props.notification_page
                    : 0
                }
                totalPages={totalpages}
                totalRecords={totalrecords}
                offset={totalrecords === 0 ? offset - 1 : offset}
                off={off}
                handlePageChange={this.handlePageChange}
              />
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  notification_page: state.paginationReducer.notification_page,
  notification_filter: state.notificationReducer.notificationFilter,
  notification_sort: state.notificationReducer.notificationSort,
  notiCount: state.notificationReducer.notiCount,
});

const mapDispatchToProps = (dispatch) => ({
  NotificationActions: bindActionCreators(NotificationActions, dispatch),
  pagination: bindActionCreators(paginationActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationList);
