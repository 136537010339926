/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-script-url */
/* eslint-disable react/jsx-no-script-url */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unused-class-component-methods */
/* eslint-disable react/no-unused-state */
/* eslint-disable class-methods-use-this */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
// TODO: check react-rangeslider
import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import Switch from 'react-switch';
import { Modal, ModalBody, Button, Label, Media } from 'reactstrap';
// import Slider from 'react-rangeslider';
// import 'react-rangeslider/lib/index.css';
import { FileDrop } from 'react-file-drop';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import $ from 'jquery';
import { bindActionCreators } from 'redux';
import loadImage from 'blueimp-load-image';
import { isValidPhoneNumber } from 'react-phone-number-input';
import InputField from 'common/InputField';
import { NAME, PASSWORD } from 'utilities/regularexpressions';
import * as Config from 'constants/constants';
import { CheckboxField } from 'common/CustomFields';
import ConfirmationModal from 'common/ConfirmationModal';
import AccountDateField from 'common/AccountDateField';
import * as HomeActions from 'actions/HomeActions';
import * as NotificationActions from 'actions/NotificationActions';
import {
  format,
  isBefore,
  isSameDay,
  differenceInBusinessDays,
} from 'date-fns';
import { PhoneNumberField } from 'common/PhoneNumberField';
import UserProfileDetail from 'api/UserProfileDetail';
import {
  FORMATPHONENUMBER,
  AlertMessage,
  getStorageData,
  setUserInLocalStorage,
} from 'utilities/utils';
import { withRouter } from 'utilities/withRouterWrapper';
import { withStorageData } from 'utilities/withStorageData';
import FileUpload from './FileUpload';
import './profile.scss';
import { UserProfileChangePassword } from './UserProfileChangePassword/UserProfileChangePassword';

class UserProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      firstName: '',
      lastName: '',
      middleName: '',
      email: '',
      phone: '',
      is_texting_allowedDefault: false,
      phoneDefault: '',
      firstNameDefault: '',
      middleNameDefault: '',
      is_texting_allowed: false,
      lastNameDefault: '',
      role: '',
      avatar: '',
      title: '',
      titleDefault: '',
      isSigningAuthority: false,
      errors: {},
      formdata: {},
      editMode: false,
      changePasswordMode: false,
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
      isPasswordValid: false,
      oldPass: 'password',
      newPass: 'password',
      confirmPass: 'password',
      imageUploaded: false,
      img: null,
      isChanged: false,
      filetype: '',
      err: {},
      uploadpicModal: false,
      clearpicModal: false,
      cropperOpen: false,
      croppedImg: '',
      range: 0,
      replaceAvatarDiv: false,
      existingPictureChange: false,
      isSubmit: false,
      isAway: this.props.isAway,
      awayModal: false,
      awayDatesModal: false,
      awayStartDate: null,
      awayEndDate: null,
      updateEditAwaySetting: false,
      editIsAway: false,
      disabUpdateButton: false,
      editedAwayStartDate: null,
      editedAwayEndDate: null,
    };
    this.handleEditMode = this.handleEditMode.bind(this);
    this.handleInputFieldChange = this.handleInputFieldChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handlePasswordSubmit = this.handlePasswordSubmit.bind(this);
    this.uploadprofilepic = this.uploadprofilepic.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleProfileSubmit = this.handleProfileSubmit.bind(this);
    this.enableChangePasswordMode = this.enableChangePasswordMode.bind(this);
    this.cropImage = this.cropImage.bind(this);
    this.fileDrop = this.fileDrop.bind(this);
    this.showHide = this.showHide.bind(this);
    this.clearprofilepic = this.clearprofilepic.bind(this);
    this.hideclearpicModal = this.hideclearpicModal.bind(this);
    this.zoom = this.zoom.bind(this);
    this.changePicture = this.changePicture.bind(this);
    this.changeExistingPicture = this.changeExistingPicture.bind(this);
    this.uploadpicModalCancel = this.uploadpicModalCancel.bind(this);
    this.profilePicRemoveConfirmed = this.profilePicRemoveConfirmed.bind(this);
    this.cropperRef = React.createRef();
  }

  componentDidMount() {
    const component = this;
    UserProfileDetail.getUserProfile(component);
    if (this.props.changePasswordMode) {
      this.enableChangePasswordMode();
    }
    if (this.props.updateAwaySetting && this.props.updateAwaySetting === true) {
      this.props.NotificationActions.updateAwaySetting(false);
      this.showEditPopup();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.props.updateAwaySetting && nextProps.updateAwaySetting) {
      this.props.NotificationActions.updateAwaySetting(false);
      this.showEditPopup();
    }
    if (nextProps.isAway) {
      this.setState({ isAway: nextProps.isAway });
    }
  }

  showEditPopup = () => {
    const awayStartDate = this.props.awayStartDate
      ? new Date(this.props.awayStartDate)
      : null;
    const awayEndDate = this.props.awayEndDate
      ? new Date(this.props.awayEndDate)
      : null;
    this.setState({ updateEditAwaySetting: true }, () => {
      this.setState({
        awayStartDate,
        awayEndDate,
        editIsAway: this.props.isAway,
        disabUpdateButton: true,
      });
    });
  };

  showHide(index) {
    if (index === 'oldPass') {
      this.setState({
        oldPass: this.state.oldPass === 'password' ? 'text' : 'password',
      });
    }
    if (index === 'newPass') {
      this.setState({
        newPass: this.state.newPass === 'password' ? 'text' : 'password',
      });
    }
    if (index === 'confirmPass') {
      this.setState({
        confirmPass:
          this.state.confirmPass === 'password' ? 'text' : 'password',
      });
    }
  }

  handleEditMode() {
    this.setState({ editMode: true, isSubmit: false });
  }

  enableChangePasswordMode() {
    this.setState({ changePasswordMode: true });
  }

  handleInputFieldChange(inputvalue, fieldname, index) {
    let value =
      inputvalue && inputvalue !== null && inputvalue.length
        ? inputvalue
        : undefined;
    if (fieldname === 'is_texting_allowed') {
      const inputfieldvalue =
        inputvalue && inputvalue.target && inputvalue.target.value
          ? inputvalue.target.value
          : undefined;
      value = !(inputfieldvalue === 'true');
    } else if (value && value.length && fieldname === 'phone') {
      value = value.length ? value : undefined;
    }
    if (index === 'checkbox') {
      this.setState(
        {
          formdata: {
            ...this.state.formdata,
            [fieldname]: !this.state.formdata[fieldname],
          },
        },
        () =>
          this.state.errors[fieldname]
            ? this.handleBlurChange(fieldname)
            : null,
      );
    } else {
      this.setState({ [fieldname]: value, isChanged: true });
      this.setState(
        { formdata: { ...this.state.formdata, [fieldname]: value } },
        () =>
          this.state.errors[fieldname]
            ? this.handleBlurChange(fieldname)
            : null,
      );
    }
  }

  handleBlurChange = () => {
    const { formdata, newPassword, firstName, lastName, oldPassword } =
      this.state;
    const errors = {};
    if (this.state.oldPassword) {
      const data = { current_password: this.state.oldPassword };
      UserProfileDetail.checkCurrentPassword(data, this);
    }
    if (this.state.errors.oldPassword) {
      errors.oldPassword = this.state.errors.oldPassword;
    }
    if (newPassword && !PASSWORD.test(newPassword)) {
      errors.newPassword =
        'Password must contain at least 12 characters, including 1 upper case letter, 1 lower case letter, 1 numeric and 1 special character.';
    } else if (newPassword) {
      const array = [firstName, lastName];
      const value = newPassword.toLowerCase();
      let usernameExists = false;
      array.map((item) => {
        if (value.indexOf(item.toLowerCase()) > -1) {
          usernameExists = true;
        }
        return usernameExists;
      });
      if (newPassword.toLowerCase() === this.state.email.toLowerCase()) {
        errors.newPassword = 'Password should not contain primary email id';
      } else if (usernameExists === true) {
        errors.newPassword =
          'Password should not contain first name/ last name';
      } else if (this.state.isPasswordValid && newPassword === oldPassword) {
        errors.newPassword =
          'New password should different from the current password';
      }
    }
    if (
      this.state.confirmPassword &&
      !PASSWORD.test(this.state.confirmPassword)
    ) {
      errors.confirmPassword =
        'Password must contain at least 12 characters, including 1 upper case letter, 1 lower case letter, 1 numeric and 1 special character.';
    }
    if (
      newPassword &&
      this.state.confirmPassword &&
      newPassword !== this.state.confirmPassword
    ) {
      errors.confirmPassword = 'Passwords do not match.';
    }
    if (!this.state.firstName && !formdata.firstName) {
      errors.firstName = 'Required';
    } else if (this.state.firstName && !NAME.test(this.state.firstName)) {
      errors.firstName = 'Enter valid name';
    }
    if (!this.state.lastName && !formdata.lastName) {
      errors.lastName = 'Required';
    } else if (this.state.lastName && !NAME.test(this.state.lastName)) {
      errors.lastName = 'Enter valid name';
    }
    if (this.state.middleName && !NAME.test(this.state.middleName)) {
      errors.middleName = 'Enter valid name';
    }
    if (formdata.phone && !isValidPhoneNumber(formdata.phone)) {
      errors.phone = 'Enter valid number';
    }

    if (formdata.is_texting_allowed && !formdata.phone) {
      errors.phone = 'Enter phone number to receive text messages';
    }
    this.setState({ errors });
  };

  handleCancel() {
    this.setState({
      editMode: false,
      loading: false,
      errors: {},
      formdata: {},
      changePasswordMode: false,
      isChanged: false,
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
      isPasswordValid: false,
      imageUploaded: false,
      img: null,
      filetype: '',
      uploadpicModal: false,
      clearpicModal: false,
      replaceAvatarDiv: false,
      range: 0,
    });
    if (this.state.isSubmit) {
      this.setState({
        phone: this.state.updatedPhone,
        title: this.state.updatedTitle,
        firstName: this.state.updatedFirstName,
        middleName: this.state.updatedMiddleName,
        lastName: this.state.updatedLastName,
      });
    } else {
      this.setState({
        phone: this.state.phoneDefault,
        title: this.state.titleDefault,
        firstName: this.state.firstNameDefault,
        middleName: this.state.middleNameDefault,
        lastName: this.state.lastNameDefault,
        is_texting_allowed: this.state.is_texting_allowedDefault,
      });
    }
  }

  uploadpicModalCancel() {
    this.setState({
      loading: false,
      changePasswordMode: false,
      imageUploaded: false,
      img: null,
      isChanged: false,
      filetype: '',
      uploadpicModal: false,
      cropperOpen: false,
      croppedImg: '',
      range: 0,
      replaceAvatarDiv: false,
      existingPictureChange: false,
    });
  }

  handlePasswordSubmit() {
    const { formdata, firstName, lastName } = this.state;
    const errors = {};
    if (this.state.oldPassword) {
      const data = { current_password: this.state.oldPassword };
      UserProfileDetail.checkCurrentPassword(data, this);
    } else if (!formdata.oldPassword && !this.state.oldPassword) {
      errors.oldPassword = 'Required';
    }
    if (this.state.newPassword && !PASSWORD.test(this.state.newPassword)) {
      errors.newPassword =
        'Password must contain at least 12 characters, including 1 upper case letter, 1 lower case letter, 1 numeric and 1 special character.';
    } else if (!formdata.newPassword && !this.state.newPassword) {
      errors.newPassword = 'Required';
    }
    if (this.state.newPassword) {
      const array = [firstName, lastName];
      let usernameExists = false;
      array.map((item) => {
        const value = this.state.newPassword;
        if (value.toLowerCase().indexOf(item.toLowerCase()) > -1) {
          usernameExists = true;
        }
        return usernameExists;
      });
      if (
        usernameExists === true ||
        this.state.newPassword.toLowerCase() === this.state.email.toLowerCase()
      ) {
        errors.newPassword = 'Password should not contain primary email id';
        // eslint-disable-next-line no-dupe-else-if
      } else if (usernameExists === true) {
        errors.newPassword =
          'Password should not contain first name/ last name';
      } else if (
        this.state.isPasswordValid &&
        this.state.newPassword === this.state.oldPassword
      ) {
        errors.newPassword = 'Last 3 passwords should not be used';
      }
    }
    if (
      this.state.confirmPassword &&
      !PASSWORD.test(this.state.confirmPassword)
    ) {
      errors.confirmPassword =
        'Password must contain at least 12 characters, including 1 upper case letter, 1 lower case letter, 1 numeric and 1 special character.';
    } else if (!formdata.confirmPassword && !this.state.confirmPassword) {
      errors.confirmPassword = 'Required';
    }
    if (
      this.state.newPassword &&
      this.state.confirmPassword &&
      this.state.newPassword !== this.state.confirmPassword
    ) {
      errors.confirmPassword = 'Passwords do not match';
    }
    this.setState({ errors });
    if (!Object.keys(errors).length) {
      if (this.state.isPasswordValid) {
        const data = {
          user: {
            password: this.state.newPassword,
            password_confirmation: this.state.confirmPassword,
          },
        };
        UserProfileDetail.profileChangePassword(data, this);
      }
    }
  }

  handleSubmit() {
    // TODO: __HOMEDATA-AUDIT
    // eslint-disable-next-line no-unused-vars
    const homeDataObj = getStorageData();
    const { formdata } = this.state;
    const errors = {};
    if (!formdata.firstName && !this.state.firstName) {
      errors.firstName = 'Required';
    } else if (this.state.firstName && !NAME.test(this.state.firstName)) {
      errors.firstName = 'Enter valid name';
    }
    if (!formdata.lastName && !this.state.lastName) {
      errors.lastName = 'Required';
    } else if (this.state.lastName && !NAME.test(this.state.lastName)) {
      errors.lastName = 'Enter valid name';
    }
    if (this.state.middleName && !NAME.test(this.state.middleName)) {
      errors.middleName = 'Enter valid name';
    }
    if (formdata.phone && !isValidPhoneNumber(formdata.phone)) {
      errors.phone = 'Enter valid number';
    }
    if (this.state.is_texting_allowed && !this.state.phone) {
      errors.phone = 'Enter phone number to receive text messages';
    }
    this.setState({ errors });
    if (!Object.keys(errors).length) {
      const mydata = {
        first_name: this.state.firstName,
        middle_name: this.state.middleName ? this.state.middleName : '',
        last_name: this.state.lastName,
        phone: this.state.phone ? this.state.phone : '',
        title: this.state.title ? this.state.title : '',
        is_texting_allowed: this.state.is_texting_allowed,
      };
      UserProfileDetail.updateUserProfile(mydata, this);
    }
  }

  uploadprofilepic() {
    this.setState({ uploadpicModal: true });
  }

  clearprofilepic() {
    this.setState({ clearpicModal: true });
  }

  hideclearpicModal() {
    this.setState({ clearpicModal: false });
  }

  profilePicRemoveConfirmed() {
    if (this.state.avatar) {
      const mydata = { avatar: this.state.avatar };
      UserProfileDetail.removeUserAvatar(mydata, this);
    }
  }

  handleFileChange(dataURI, filetyp, cropped = false) {
    if (!cropped) {
      this.setState({
        imageUploaded: true,
        img: dataURI,
        croppedImg: dataURI,
        cropperOpen: true,
        isChanged: true,
        filetype: filetyp,
        replaceAvatarDiv: true,
      });
    } else {
      this.setState({ croppedImg: dataURI });
    }
  }

  fileDrop(files) {
    const file = files[0];
    const options = { canvas: true };
    if (!file) return;
    const component = this;
    loadImage.parseMetaData(file, (data) => {
      if (data.exif) {
        options.orientation = data.exif.get('Orientation');
      }
      loadImage(
        file,
        (img) => {
          const base64 = img.toDataURL(file.type);
          const re = this.myRef;
          component.handleFileChange(base64, file.type);
        },
        options,
      );
    });
  }

  cropImage() {
    const node = this.cropperRef.current;
    if (node) {
      const dataURI = node.cropper.getCroppedCanvas().toDataURL();
      if (dataURI) {
        $('img.cropper-hidden').attr('src', dataURI);
        this.handleFileChange(dataURI, '', true);
      }
    }
  }

  zoom(e, newValue) {
    const node = this.cropperRef.current;
    console.log(node);
    if (node) {
      node.cropper.zoom(e - this.state.range);
      const dataURI = node.cropper.getCroppedCanvas().toDataURL();
      console.log(dataURI);
      if (dataURI) {
        $('img.cropper-hidden').attr('src', dataURI);
        this.handleFileChange(dataURI, '', true);
      }
    }
    this.setState({ range: newValue });
  }

  changePicture() {
    this.setState({
      imageUploaded: false,
      img: null,
      croppedImg: '',
      range: 0,
    });
  }

  changeExistingPicture() {
    // eslint-disable-next-line react/no-unused-state
    this.setState({ existingPictureChange: true });
  }

  handleProfileSubmit() {
    let mydata = '';
    if (this.state.imageUploaded) {
      const uploadFile = this.state.croppedImg;
      mydata = new FormData();
      const uploadedFile = uploadFile.split('base64,').pop();
      mydata.append('avatar', uploadedFile);
      mydata.append('plateform', true);
      UserProfileDetail.updateUserAvatar(mydata, this);
    }
  }

  handleIsAway = (val, away = '') => {
    const component = this;
    const {
      awayStartDate,
      awayEndDate,
      awayModal,
      firstName,
      lastName,
      editIsAway,
    } = this.state;
    if (
      val === 'no' ||
      val === 'save' ||
      val === 'update' ||
      (val === 'toggle' && away)
    ) {
      const data = {
        employee: {
          is_away: val === 'update' ? editIsAway : !away,
          away_start_date:
            awayStartDate && awayStartDate !== undefined
              ? format(awayStartDate, 'MM/dd/yyyy')
              : null,
          away_end_date:
            awayEndDate && awayEndDate !== undefined
              ? format(awayEndDate, 'MM/dd/yyyy')
              : null,
        },
      };
      component.setState({ loading: true });
      let url = '';
      url = Config.AWAY_FOR_OUT_OF_OFFICE;
      const request = axios.put(url, data);
      request
        .then((response) => {
          if (response.data.status === 200) {
            const awayOnOff =
              (val === 'save' || val === 'update') &&
              awayStartDate &&
              differenceInBusinessDays(awayStartDate, new Date()) !== 0
                ? false
                : val === 'update'
                ? editIsAway
                : !away;
            // TODO: __HOMEDATA
            const homedata = getStorageData();
            homedata.is_away = val === 'update' ? editIsAway : !away;
            homedata.uname = `${firstName} ${lastName}${
              awayOnOff ? ' (away)' : ''
            }`;
            component.props.NotificationActions.isAway(
              val === 'update' ? editIsAway : !away,
            );
            component.props.NotificationActions.awayStartDate(
              awayStartDate ? format(awayStartDate, 'MM/dd/yyyy') : null,
            );
            component.props.NotificationActions.awayEndDate(
              awayEndDate ? format(awayEndDate, 'MM/dd/yyyy') : null,
            );
            setUserInLocalStorage(homedata);
            component.setState(
              {
                isAway: awayOnOff,
                loading: false,
                awayDatesModal: false,
                awayModal: false,
                updateEditAwaySetting: false,
              },
              () => {
                component.setState({ awayStartDate: null, awayEndDate: null });
              },
            );
            if (val === 'update' ? editIsAway : !away) {
              AlertMessage(
                'success',
                'Your status is currently set to away.',
                3000,
              );
            } else {
              AlertMessage(
                'success',
                'You have successfully turned off the Away status.',
                3000,
              );
            }
          } else if (response.data.status === 422) {
            const errors = {};
            if (val === 'update') {
              errors._date = response.data.message;
            } else {
              errors.date = response.data.message;
            }
            this.setState({ errors, loading: false });
          } else {
            this.setState({
              awayModal: !this.state.awayModal,
              loading: false,
              awayDatesModal: false,
            });
            AlertMessage('error', response.data.message, 3000);
          }
        })
        .catch((error) => {
          this.setState({
            awayModal: !this.state.awayModal,
            loading: false,
            awayDatesModal: false,
            awayStartDate: null,
            awayEndDate: null,
          });
          AlertMessage('error', 'Some error occurred. Please try later.', 3000);
          return console.error(error);
        });
    } else if (val === 'toggle' && !away) {
      this.setState({ awayModal: !awayModal });
    } else if (val === 'yes') {
      this.setState({ awayDatesModal: true });
    } else if (val === 'cancel') {
      this.setState({
        errors: {},
        awayDatesModal: false,
        awayStartDate: null,
        awayEndDate: null,
      });
    } else if (val === 'cancelEdit') {
      this.setState({
        updateEditAwaySetting: false,
        awayStartDate: null,
        awayEndDate: null,
        disabUpdateButton: false,
        errors: {},
        editedAwayStartDate: null,
        editedAwayEndDate: null,
      });
      NotificationActions.updateAwaySetting(false);
    }
  };

  handleDateChange = (e, field = '', editField = '') => {
    this.setState(
      {
        [field]: e,
        [editField]: e,
        errors: {},
        disabUpdateButton: false,
      },
      () => {
        const { awayEndDate, awayStartDate, updateEditAwaySetting } =
          this.state;
        const errors = {};
        if (
          awayEndDate &&
          awayStartDate &&
          (isBefore(awayEndDate, awayStartDate) ||
            isSameDay(awayStartDate, awayEndDate))
        ) {
          errors.date = 'End date should be greater than start date';
          this.setState({ errors });
        } else if (
          updateEditAwaySetting &&
          awayEndDate &&
          awayStartDate &&
          (isSameDay(awayStartDate, new Date()) ||
            isBefore(awayStartDate, new Date()))
        ) {
          errors.date =
            'Start date must be greater than or equal to today and end date must be greater than start date.';
          this.setState({ errors });
        }
      },
    );
  };

  updateAwaySetting = () => {
    const {
      editIsAway,
      disabUpdateButton,
      awayEndDate,
      awayStartDate,
      editedAwayStartDate,
      editedAwayEndDate,
    } = this.state;
    const _awayStartDate =
      this.props.awayStartDate !== null ? this.props.awayStartDate : null;
    const _awayEndDate =
      this.props.awayEndDate !== null ? this.props.awayEndDate : null;
    if (editIsAway) {
      this.setState({
        // isAway: this.state.isAway && false,
        awayStartDate: null,
        awayEndDate: null,
        editIsAway: !editIsAway,
        errors: {},
        disabUpdateButton: !disabUpdateButton,
      });
    } else if (!editIsAway) {
      this.setState({
        awayStartDate:
          editedAwayStartDate !== null ? editedAwayStartDate : _awayStartDate,
        awayEndDate:
          editedAwayEndDate !== null ? editedAwayEndDate : _awayEndDate,
        editIsAway: !editIsAway,
        errors: {},
        disabUpdateButton: !disabUpdateButton,
      });
    }
  };

  render() {
    // TODO: __HOMEDATA-AUDIT
    const homeDataObj = getStorageData();
    const {
      firstName,
      lastName,
      is_texting_allowed,
      has_user_unsubscribed,
      clearpicModal,
      isAway,
      awayModal,
      awayDatesModal,
      awayStartDate,
      awayEndDate,
      updateEditAwaySetting,
      editIsAway,
    } = this.state;
    const Name = `${firstName} ${lastName}`;
    const initials =
      (Name.split(' ')[0] ? Name.split(' ')[0].charAt(0) : '') +
      (Name.split(' ')[1] ? Name.split(' ')[1].charAt(0) : '');
    const noneStyle = { display: 'none' };
    const blockStyle = { display: 'block' };

    return (
      <div id="content">
        {/* <header>
          <h1 className="pageHeading">MY PROFILE SETTINGS</h1>
        </header> */}
        <div id="loading" style={this.state.loading ? blockStyle : noneStyle} />
        <div className="center-wrapper">
          <div>
            {!this.state.editMode && !this.state.changePasswordMode ? (
              <div className="Edit-btn-right float-right mt-5">
                <Button
                  className="btn-link btn--onlyicon btn btn-secondary py-0 mb-2"
                  onClick={this.handleEditMode}
                >
                  <i className="icon-edit mx-2" aria-hidden="true" />
                  Edit Profile
                </Button>
                {homeDataObj.is_employee ? (
                  <div className="d-flex align-items-center justify-content-end">
                    <label className="fontSize font-weight-normal">
                      <span>Away</span>
                      <Switch
                        onChange={() => this.handleIsAway('toggle', isAway)}
                        checked={isAway}
                        className="react-switch"
                        onColor="#00aecc"
                        offColor="#dfe3e8"
                        handleDiameter={16}
                        width={48}
                        height={20}
                        boxShadow="0 0 2px 2px rgb(0,0,0,0.1)"
                        activeBoxShadow="0 0 2px 2px rgb(0,0,0,0.1)"
                        uncheckedIcon={
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              height: '100%',
                              fontSize: 12,
                              color: '#919eab',
                              paddingRight: 2,
                            }}
                          >
                            OFF
                          </div>
                        }
                        checkedIcon={
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              height: '100%',
                              fontSize: 12,
                              color: 'white',
                              paddingRight: 2,
                            }}
                          >
                            ON
                          </div>
                        }
                      />
                    </label>
                  </div>
                ) : null}
              </div>
            ) : (
              ''
            )}
            <div className="center-wrapper col-lg-10 col-xs-12 m-auto">
              <Media className="bottomspace upperspace align-items-center">
                <Media
                  left
                  href="#"
                  className={`avatar ${
                    homeDataObj.is_employee
                      ? 'avatar--border avatar--borderPrimary'
                      : ''
                  } avatar--w100 mr-2`}
                >
                  {this.state.avatar ? (
                    <img
                      src={this.state.avatar}
                      alt="Avatar"
                      className="avatar rounded-circle Img-P"
                    />
                  ) : (
                    <span className="uName">{initials.toUpperCase()}</span>
                  )}
                </Media>
                <Media body className="user-Media-P">
                  {this.state.editMode ? (
                    <div className="form-group row">
                      <InputField
                        name="firstName"
                        label="first name"
                        className="col-sm-12 col-lg-3 col-md-4"
                        onChange={this.handleInputFieldChange}
                        value={this.state.firstName}
                        error={this.state.errors.firstName}
                        onBlur={this.handleBlurChange}
                      />
                      <InputField
                        name="middleName"
                        label="middle name"
                        className="col-sm-12 col-lg-3 col-md-4"
                        onChange={this.handleInputFieldChange}
                        value={this.state.middleName}
                        error={this.state.errors.middleName}
                        onBlur={this.handleBlurChange}
                      />
                      <InputField
                        name="lastName"
                        label="last name"
                        className="col-sm-12 col-lg-3 col-md-4"
                        onChange={this.handleInputFieldChange}
                        value={this.state.lastName}
                        error={this.state.errors.lastName}
                        onBlur={this.handleBlurChange}
                      />
                    </div>
                  ) : (
                    <Media heading className="text-large">
                      {Name}
                    </Media>
                  )}
                  {this.state.editMode && this.state.avatar ? (
                    <div className="btn--leftspace">
                      <Button
                        className="btn btn-outline-light btn-Radius"
                        color="primary"
                        onClick={this.uploadprofilepic}
                      >
                        Change Profile Picture
                      </Button>
                      <Button
                        className="btn btn-outline-light btn-Radius"
                        color="primary"
                        onClick={this.clearprofilepic}
                      >
                        Clear Picture
                      </Button>
                    </div>
                  ) : this.state.editMode && !this.state.avatar ? (
                    <Button
                      className="btn btn-outline-light btn-Radius"
                      color="primary"
                      onClick={this.uploadprofilepic}
                    >
                      Upload Profile Picture
                    </Button>
                  ) : (
                    ''
                  )}
                </Media>
              </Media>
              <h2 className="text-small form-group">
                {!this.state.changePasswordMode
                  ? 'User Information'
                  : 'Change Password'}
              </h2>
              {!this.state.editMode && !this.state.changePasswordMode ? (
                <div className="row">
                  <div className="col-sm-12 col-lg-5 col-md-5 form-group">
                    <div className="form-inline-label">
                      <Label for="forContact" className="subHeadline">
                        username:
                      </Label>
                      <div className="lblValue">
                        <div className="fontSize">
                          <span className="userName">{this.state.email}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-lg-5 col-md-5 form-group">
                    <div className="form-inline-label">
                      <Label for="forContact">Role:</Label>
                      <div className="lblValue">
                        <div className="fontSize">
                          <span className="userName">{this.state.role}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-lg-5 col-md-5 form-group">
                    <div className="form-inline-label">
                      <Label for="forContact">Phone:</Label>
                      <div className="lblValue">
                        <div className="fontSize">
                          <span className="userName">
                            {FORMATPHONENUMBER(this.state.phone)}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-lg-5 col-md-5 form-group">
                    <div className="form-inline-label">
                      <Label for="forContact">Title:</Label>
                      <div className="lblValue">
                        <div className="fontSize">
                          <span className="userName">{this.state.title}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-lg-12 col-md-12 form-group d-flex">
                    {homeDataObj.is_employee ? (
                      <div className="text-left mt-2">
                        <CheckboxField
                          id="isSigningAuthority"
                          name="isSigningAuthority"
                          label="Signing Authority"
                          value={this.state.isSigningAuthority}
                          onChange={() => {}}
                        />
                      </div>
                    ) : (
                      [
                        <div
                          className="d-flex mt-1 mr-4"
                          key="client_allow_texting"
                        >
                          <label className="text-uppercase vertical-middle mr-2">
                            Allow Texting?
                          </label>
                          <div className="checkbox checkbox-primary check-container">
                            <label
                              htmlFor="is_texting_allowed"
                              className="remove-min-width"
                            >
                              <input
                                name="is_texting_allowed"
                                id="is_texting_allowed"
                                type="checkbox"
                                value={is_texting_allowed}
                                checked={is_texting_allowed}
                              />
                              <i className="checkmark" />
                            </label>
                          </div>
                        </div>,
                        is_texting_allowed && has_user_unsubscribed && (
                          <div
                            key="has_user_unsubscribed"
                            className="d-flex s-alert-box-inner p-2 rounded alert-success"
                            style={{ marginTop: '-6px', marginBottom: '2px' }}
                          >
                            <i className="icon-expired text-light pr-1" />
                            <span className="text-body">
                              You have unsubscribed from texting
                            </span>
                          </div>
                        ),
                      ]
                    )}
                  </div>
                  <div className="col-sm-12 col-lg-12 col-md-12 form-group">
                    <a
                      href="javascript:;"
                      className="link px-0 py-2"
                      onClick={this.enableChangePasswordMode}
                    >
                      Change Password
                    </a>
                  </div>
                </div>
              ) : this.state.editMode && !this.state.changePasswordMode ? (
                <div>
                  <div className="form-group row">
                    <InputField
                      name="email"
                      label="email"
                      className="col-sm-12 col-lg-5 col-md-5"
                      onChange={this.handleInputFieldChange}
                      value={this.state.email}
                      error={this.state.errors.email}
                      onBlur={this.handleBlurChange}
                      disabled
                    />
                    <InputField
                      name="role"
                      label="role"
                      className="col-sm-12 col-lg-5 col-md-5"
                      onChange={this.handleInputFieldChange}
                      value={this.state.role}
                      error={this.state.errors.role}
                      onBlur={this.handleBlurChange}
                      disabled
                    />
                  </div>
                  <div className="form-group row">
                    <PhoneNumberField
                      name="phone"
                      label="phone"
                      className="col-sm-12 col-lg-5 col-md-5"
                      onChange={this.handleInputFieldChange}
                      value={this.state.phone}
                      error={this.state.errors.phone}
                      onBlur={this.handleBlurChange}
                    />
                    <InputField
                      name="title"
                      label="title"
                      className="col-sm-12 col-lg-5 col-md-5"
                      onChange={this.handleInputFieldChange}
                      value={this.state.title}
                      error={this.state.errors.title}
                      onBlur={this.handleBlurChange}
                    />
                  </div>
                  <div className="form-group row">
                    {homeDataObj.is_employee ? (
                      <div className="mt-2 w-100">
                        <CheckboxField
                          id="isSigningAuthority"
                          name="isSigningAuthority"
                          label="Signing Authority"
                          value={this.state.isSigningAuthority}
                          disabled
                        />
                      </div>
                    ) : (
                      <div className="mt-2 w-100">
                        <CheckboxField
                          id="is_texting_allowed"
                          name="is_texting_allowed"
                          label="Allow Texting"
                          className="col-sm-12"
                          value={is_texting_allowed}
                          onChange={this.handleInputFieldChange}
                        />
                      </div>
                    )}
                  </div>
                  <div className="upperspace">
                    <Button
                      className="btn  btn-primary"
                      color="primary"
                      onClick={this.handleSubmit}
                    >
                      Update
                    </Button>
                    <Button
                      className="btn btn-outline-light btn--leftspace-edit"
                      color="primary"
                      onClick={this.handleCancel}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              ) : (
                <UserProfileChangePassword
                  state={this.state}
                  handleInputFieldChange={this.handleInputFieldChange}
                  handleBlurChange={this.handleBlurChange}
                  showHide={this.showHide}
                  handlePasswordSubmit={this.handlePasswordSubmit}
                  handleCancel={this.handleCancel}
                />
              )}
            </div>
          </div>
          <Modal
            className="customModal customModal--w500 customModal--center customModal--nopadding"
            isOpen={awayModal}
          >
            <div className="ModalHeader">
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={() => this.setState({ awayModal: !awayModal })}
              >
                <i className="icon-close2" />
              </button>
            </div>
            <ModalBody className="modalContent">
              <div className="modalContent--inner">
                <div className="modalContent mb-3 text-center">
                  <h5 className="modalContent--header__heading">
                    Would you like to select a Start or End date?
                    <span className="label-submedium text-light ml-1 font-weight-normal">
                      (Optional)
                    </span>
                  </h5>
                </div>
                <div className="modalContent--footer btn--leftspace justify-content-center">
                  <Button
                    className="btn btn-primary"
                    color="primary"
                    onClick={() => this.handleIsAway('no', isAway)}
                  >
                    No
                  </Button>
                  <Button
                    className="btn btn-primary"
                    color="primary"
                    onClick={() => this.handleIsAway('yes')}
                  >
                    Yes
                  </Button>
                </div>
              </div>
            </ModalBody>
          </Modal>
          <Modal
            className="customModal customModal--w500 customModal--center customModal--nopadding"
            isOpen={awayDatesModal || updateEditAwaySetting}
          >
            <div className="ModalHeader">
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={() =>
                  this.handleIsAway(
                    updateEditAwaySetting ? 'cancelEdit' : 'cancel',
                  )
                }
              >
                <i className="icon-close2" />
              </button>
            </div>
            <ModalBody className="modalContent" style={{ overflow: 'visible' }}>
              <div className="modalContent--inner">
                <div className="modalContent text-large mb-3">
                  <h5 className="modalContent--header__heading">
                    {updateEditAwaySetting
                      ? 'Edit Away Setting'
                      : 'Select Date Range'}
                  </h5>
                </div>
                <div className="row">
                  <div className="col-5 pr-2">
                    <AccountDateField
                      disabled={this.state.updateEditAwaySetting && !editIsAway}
                      className={`InputGroupFocus form-group inpopup mb-2 ${
                        this.state.errors.date ? 'form-error' : ''
                      }`}
                      name="start_date"
                      label="Start Date"
                      labelClass="d-block date-text date-calender"
                      style={{ minWidth: '35px' }}
                      onChange={(e) =>
                        this.handleDateChange(
                          e,
                          'awayStartDate',
                          'editedAwayStartDate',
                        )
                      }
                      toolTip="START DATE"
                      value={awayStartDate}
                      minDate={new Date()}
                    />
                  </div>
                  <div className="col-5 px-2">
                    <AccountDateField
                      disabled={this.state.updateEditAwaySetting && !editIsAway}
                      className={`InputGroupFocus form-group inpopup mb-2 ${
                        this.state.errors.date || this.state.errors._date
                          ? 'form-error'
                          : ''
                      }`}
                      name="end_date"
                      label="End Date"
                      labelClass="d-block date-text date-calender"
                      style={{ minWidth: '15px' }}
                      onChange={(e) =>
                        this.handleDateChange(
                          e,
                          'awayEndDate',
                          'editedAwayEndDate',
                        )
                      }
                      value={awayEndDate}
                      minDate={awayStartDate || new Date()}
                    />
                  </div>
                </div>
                <div className="row">
                  <span className="col-10 px-3 error_label small">
                    {this.state.errors.date || this.state.errors._date}
                  </span>
                </div>
                <div className="mt-3 btn--leftspace formbtn justify-content-end">
                  {updateEditAwaySetting ? (
                    <p className="mr-0 mt-2">Away</p>
                  ) : null}
                  {updateEditAwaySetting ? (
                    <Switch
                      onChange={this.updateAwaySetting}
                      checked={editIsAway}
                      className="react-switch mr-auto mt-2"
                      onColor="#00aecc"
                      offColor="#dfe3e8"
                      handleDiameter={16}
                      width={48}
                      height={20}
                      boxShadow="0 0 2px 2px rgb(0,0,0,0.1)"
                      activeBoxShadow="0 0 2px 2px rgb(0,0,0,0.1)"
                      uncheckedIcon={
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                            fontSize: 12,
                            color: '#919eab',
                            paddingRight: 2,
                          }}
                        >
                          OFF
                        </div>
                      }
                      checkedIcon={
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                            fontSize: 12,
                            color: 'white',
                            paddingRight: 2,
                          }}
                        >
                          ON
                        </div>
                      }
                    />
                  ) : null}
                  {/* `${updateEditAwaySetting ? 'disabled' = ${this.state.disabUpdateButton}} : ''` */}
                  <Button
                    className="btn btn-outline-light"
                    onClick={() =>
                      this.handleIsAway(
                        updateEditAwaySetting ? 'cancelEdit' : 'cancel',
                      )
                    }
                  >
                    Cancel
                  </Button>
                  <Button
                    className="btn btn-primary"
                    disabled={this.state.disabUpdateButton}
                    onClick={() =>
                      this.handleIsAway(
                        updateEditAwaySetting ? 'update' : 'save',
                        isAway,
                      )
                    }
                  >
                    {updateEditAwaySetting ? 'Update' : 'Save'}
                  </Button>
                </div>
              </div>
            </ModalBody>
          </Modal>
          <Modal
            className="customModal customModal--w700 customModal--nopadding"
            isOpen={this.state.uploadpicModal}
          >
            <div className="ModalHeader">
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={this.uploadpicModalCancel}
              >
                <i className="icon-close2" />
              </button>
            </div>
            <ModalBody className="modalContent">
              <div className="modalContent--innerP">
                <div className="modalContent--header">
                  <h5 className="modalContent--header__heading">
                    Upload profile picture
                  </h5>
                </div>
                <div>
                  <div className="imageUploadWrap">
                    {this.state.img ? (
                      <div className="cropWrap">
                        <button
                          type="button"
                          onClick={this.changePicture}
                          className="removeImage"
                        >
                          <i className="icon-close2 remove" />
                        </button>
                        <Cropper
                          className="cropWrap--image"
                          ref={this.cropperRef}
                          src={this.state.img}
                          crop={this.cropImage}
                          aspectRatio={350 / 350}
                          guides={false}
                          background={false}
                        />
                        <div className="customRange">
                          {/* <Slider
                              className="rangeSlider"
                              id="typeinp"
                              min={0}
                              max={5}
                              step={0.01}
                              tooltip={false}
                              value={this.state.range}
                              orientation="horizontal"
                              onChange={this.zoom}
                            /> */}
                        </div>
                      </div>
                    ) : (
                      <div className=" file-W-auto">
                        <FileDrop
                          frame={window}
                          onDrop={this.fileDrop}
                          className="dragBrowse"
                          id="Attachments"
                          dropEffect="copy"
                        >
                          <div className="DragDrop d-flex justify-content-center align-items-center">
                            <div className="dropFile icon-upload" />
                            <div className="dropFile">
                              Drop files to attach, or
                            </div>
                            <div className="btn-Upload-Image Img-Browse-btn">
                              <div className="Choose-text-Hid">
                                <FileUpload
                                  id="uploadingAvatar"
                                  handleFileChange={this.handleFileChange}
                                />
                                <Label
                                  htmlFor="uploadingAvatar"
                                  className="fontSize browse-Up-btn"
                                >
                                  browse
                                </Label>
                              </div>
                            </div>
                          </div>
                        </FileDrop>
                      </div>
                    )}
                  </div>
                </div>
                <div className="modalContent--footer btn--leftspace justify-content-center">
                  <Button
                    className="btn btn-outline-light"
                    color="primary"
                    onClick={this.uploadpicModalCancel}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="btn btn-primary"
                    color="primary"
                    onClick={this.handleProfileSubmit}
                  >
                    Done
                  </Button>
                </div>
              </div>
            </ModalBody>
          </Modal>
          <ConfirmationModal
            isOpen={clearpicModal}
            headerText="Confirm removing profile picture"
            messageText="Are you sure you want to remove your profile picture?"
            noBtnClick={this.hideclearpicModal}
            yesBtnClick={this.profilePicRemoveConfirmed}
            yesBtnText="Remove profile picture"
            noBtnText="Cancel"
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  awayStartDate: state.notificationReducer.awayStartDate,
  awayEndDate: state.notificationReducer.awayEndDate,
  isAway: state.notificationReducer.isAway,
  updateAwaySetting: state.notificationReducer.updateAwaySetting,
});
const mapDispatchToProps = (dispatch) => ({
  HomeActions: bindActionCreators(HomeActions, dispatch),
  NotificationActions: bindActionCreators(NotificationActions, dispatch),
});

export default withStorageData(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(UserProfile)),
);
