import React, { useState } from 'react';
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import { Button } from 'reactstrap';
import moment from 'moment';
import 'react-dates/lib/css/_datepicker.css';

const START_DATE = 'startDate';

const Calender = ({ onSelect, className, selectedDates }) => {
  const [dates, setDates] = useState(
    selectedDates[0]
      ? [moment(selectedDates[0]), moment(selectedDates[1])]
      : [],
  );
  const [focusedInput, setFocusedInput] = useState(START_DATE);
  const [show, setShow] = useState(false);

  const handleChange = ({ startDate, endDate }) => {
    setDates([startDate, endDate]);
  };

  const handleClose = (input) => {
    setShow(false);
    if (input.startDate && input.endDate && input.endDate !== null) {
      onSelect([input.startDate, input.endDate]);
    }
  };

  return (
    <div className="position-relative">
      <Button
        type="button"
        className={className}
        onClick={() => {
          setFocusedInput(START_DATE);
          setShow(!show);
        }}
      >
        {selectedDates && selectedDates[0]
          ? `${moment(selectedDates[0]).format('MM/DD/YYYY')} - ${moment(
              selectedDates[1],
            ).format('MM/DD/YYYY')}`
          : 'Custom Dates'}
      </Button>
      {show ? (
        <div
          className="position-absolute"
          style={{
            height: '400px',
            backgroundColor: 'white',
            width: '320px',
            zIndex: '1',
          }}
        >
          <div
            style={{
              position: 'relative',
              width: '100%',
              height: '410px',
            }}
          >
            <DateRangePicker
              startDate={dates[0]}
              endDate={dates[1]}
              minDate={moment('01/01/2021')}
              maxDate={moment()}
              startDateId="your_unique_start_date_id"
              endDateId="your_unique_end_date_id"
              numberOfMonths={1}
              onDatesChange={handleChange}
              focusedInput={focusedInput}
              onFocusChange={(input) => setFocusedInput(input)}
              minimumNights={0}
              isOutsideRange={() => false}
              displayFormat="MM/DD/YYYY"
              hideKeyboardShortcutsPanel
              appendToBody
              onClose={handleClose}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Calender;
