import { Stack } from '@mui/material';
import HeaderTabs from 'common/HeaderTabs';

interface TabItem {
  label: string;
  value: string;
}

const tabs: TabItem[] = [
  {
    label: 'Phone Lines',
    value: 'phoneLines',
  },
];

export function SMSAdminHeader() {
  return (
    <Stack direction="row" paddingLeft="2rem" justifyContent="start">
      <HeaderTabs
        data={tabs}
        activeTab={0}
        customClasses=""
        handleClick={() => {}} // no-op, but required
      />
    </Stack>
  );
}
