/* eslint-disable react/no-unused-state */
/* eslint-disable consistent-return */
/* eslint-disable react/no-access-state-in-setstate */
import React, { Component } from 'react';
import Axios from 'axios';
import { EditorState, convertFromRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import {
  getOffSetsAndLimit,
  getCpaPreferences,
  isUserEmployee,
  AlertMessage,
  getBulkInviteMaxCount,
  generateFullName,
  sortSelectedList,
} from 'utilities/utils';
import Pagination from 'common/Pagination';
import {
  SEARCH_CONTACTS_FOR_BULKMESSAGE,
  SEND_BULKMESSAGE,
} from 'constants/constants';
import { sendMixpanelEvent } from 'utilities/mixpanelfn';
import ConfirmationModal from 'common/ConfirmationModal';
import NoRecords from 'common/NoRecords';
import { options, removeBreakElementinList } from 'common/NotesUtilities';
import { withRouter } from 'utilities/withRouterWrapper';
import LoadingOverlay from 'common/LoadingOverlay';
import { withStorageData } from 'utilities/withStorageData';
import SearchSection from './SearchSection';
import SearchList from './SearchList';
import MessageSection from './MessageSection';

const removeContact = (array, element) =>
  array.filter(
    (e) =>
      !(
        e.contact.id === element.contact.id &&
        e.contact.account_id === element.contact.account_id
      ) && !element.checked,
  );

const updateListData = (listData, selectedData) => {
  if (selectedData.length) {
    listData.map((each) => {
      const eachone = each;
      eachone.checked = false;
      selectedData.map((e) => {
        if (
          e.contact.id === each.contact.id &&
          e.contact.account_id === each.contact.account_id
        ) {
          eachone.checked = true;
        }
        return e;
      });
      return eachone;
    });
  }
  return listData;
};

const statusTypesObj = [
  { key: 'New', value: 'New', label: 'New' },
  { key: 'Invited', value: 'Invited', label: 'Invited' },
  { key: 'Expired', value: 'Expired', label: 'Expired' },
  { key: 'Active', value: 'Active', label: 'Active' },
];

const updatedSelectedFilterValues = (
  filterValues,
  index,
  areAllFiltersSelected,
  input,
  crossclick,
) => {
  const modifiedIndex = index - 1;
  const filterValuesArray = filterValues;
  const selectedFilterValuesArray = [];
  if (modifiedIndex > -1 || crossclick) {
    if (crossclick) {
      const object = filterValuesArray.find((t) => t.key === input);
      const indexx = filterValuesArray.indexOf(object);
      filterValuesArray[indexx] = {
        ...filterValuesArray[indexx],
        checked: !filterValuesArray[indexx].checked,
      };
    } else {
      filterValuesArray[modifiedIndex] = {
        ...filterValuesArray[modifiedIndex],
        checked: !filterValuesArray[modifiedIndex].checked,
      };
    }
    filterValuesArray.map((e) => {
      if (e.checked) {
        selectedFilterValuesArray.push(e.key);
      }
      return e.key;
    });
  } else {
    filterValuesArray.map((e) => {
      const each = e;
      if (!areAllFiltersSelected) {
        each.checked = true;
        selectedFilterValuesArray.push(e.key);
      } else {
        each.checked = false;
      }
      return each;
    });
  }
  return {
    filterValuesArray,
    selectedFilterValuesArray,
    areAllFiltersSelected:
      filterValuesArray.length === selectedFilterValuesArray.length,
  };
};

const bulkMessageTableHeaders = [
  {
    value: 'last_name',
    label: 'Contact Name',
    isSortable: true,
    sortValue: 'last_name',
    className: ' col-auto flex-20',
    // flex: '0 0 18%',
    formatFn: (input) => generateFullName(input.contact, true),
  },
  {
    value: 'account_name',
    label: 'Account Name',
    isSortable: true,
    className: ' col-auto flex-20',
    sortValue: 'account_name',
    // flex: '0 0 18%',
    formatFn: (input) =>
      input.contact.account_name &&
      input.contact.account_name !== null &&
      input.contact.account_name.length > 0
        ? input.contact.account_name
        : '-',
  },
  {
    value: 'relationship_type',
    label: 'Relationship',
    isSortable: true,
    className: ' col-auto flex-10',
    sortValue: 'relationship_type',
    // flex: '0 0 18%',
    formatFn: (input) => input.relationship_type || '-',
  },
  {
    value: 'primary_email',
    label: 'Email',
    isSortable: true,
    className: ' col-auto flex-20',
    sortValue: 'primary_email',
    // flex: '0 0 20%',
    formatFn: (input) =>
      input.contact.primary_email &&
      input.contact.primary_email !== null &&
      input.contact.primary_email.length > 0
        ? input.contact.primary_email
        : '-',
  },
  {
    value: 'task_date',
    label: 'Last Message Sent Date',
    isSortable: true,
    className: ' col-auto flex-10',
    sortValue: '"created_at"',
    // flex: '0 0 20%',
    formatFn: undefined,
  },
  {
    value: 'title',
    label: 'Last Message Sent Title',
    isSortable: true,
    className: ' col-auto flex-15',
    sortValue: 'title',
    // flex: '0 0 20%',
    formatFn: undefined,
  },
];

const bulkLastPageMessageTableHeaders = [
  {
    value: 'last_name',
    label: 'Contact Name',
    isSortable: true,
    sortValue: 'last_name',
    className: ' col-auto flex-30',
    // flex: '0 0 30%',
    formatFn: (input) => generateFullName(input.contact, true),
  },
  {
    value: 'account_name',
    label: 'Account Name',
    isSortable: true,
    className: ' col-auto flex-30',
    sortValue: 'account_name',
    // flex: '0 0 30%',
    formatFn: (input) =>
      input.contact.account_name &&
      input.contact.account_name !== null &&
      input.contact.account_name.length > 0
        ? input.contact.account_name
        : '-',
  },
  {
    value: 'task_date',
    label: 'Last Message Sent Date',
    isSortable: false,
    className: ' col-auto flex-22',
    sortValue: 'last_message_sent',
    // flex: '0 0 22%',
    formatFn: undefined,
  },
];

class BulkMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      entityTypes: [...getCpaPreferences().entity_type],
      relationshipTypes: [...getCpaPreferences().contact_type],
      statusTypes: [...statusTypesObj],
      searchFieldText: '',
      selectedEntityTypes: [],
      selectedData: [],
      listData: [],
      pagination: 1,
      showOnlySelectedData: false,
      selectedAll: false,
      showDocumentSection: false,
      totalRecords: 0,
      totalPages: 0,
      sortBy: 'last_name',
      sortOrder: 'asc',
      maxCount: getBulkInviteMaxCount(
        getCpaPreferences().bulk_action_limit,
        'max_send_messages',
      ),
      onlyAccountOwners: false,
      selectedAccountId: '',
      selectedAccountName: '',
      selectedCsTeam: undefined,
      filterInFocus: '',
      loading: false,
    };
  }

  UNSAFE_componentWillMount() {
    document.title = 'Send Message';
    if (!isUserEmployee()) {
      this.props.navigate('/');
    }
  }

  componentDidMount() {
    document.addEventListener('click', (e) => {
      if (e && e.target) {
        this.hideOpenFilters();
      }
    });
  }

  getRecords = () => {
    const {
      pagination,
      sortBy,
      selectedEntityTypes,
      sortOrder,
      searchFieldText,
      selectedStatusTypes,
      selectedRelationshipTypes,
      onlyAccountOwners,
      selectedAccountId,
      selectedCsTeam,
    } = this.state;
    this.setState({ loading: true });
    Axios.post(SEARCH_CONTACTS_FOR_BULKMESSAGE, {
      page: pagination,
      sort_by: sortOrder,
      field: sortBy,
      entity_type: selectedEntityTypes,
      status: selectedStatusTypes,
      relationship_type: selectedRelationshipTypes,
      keyword: searchFieldText.toLowerCase(),
      only_account_owners: onlyAccountOwners,
      account_id: selectedAccountId,
      employee_id: selectedCsTeam ? selectedCsTeam.id : '',
    }).then((res) => {
      if (res.data.status === 200) {
        this.setState({ loading: false });
        const listData = res.data.data.data;
        const { selectedData } = this.state;
        updateListData(listData, selectedData);
        this.setState(
          {
            listData: res.data.data.data,
            selectedAll: false,
            offset: getOffSetsAndLimit(res.data.data.total_entries, 25)[
              this.state.pagination - 1
            ],
            totalPages: Number(Math.ceil(res.data.data.total_entries / 25)),
            totalRecords: res.data.data.total_entries,
          },
          () => {
            this.areAllContactsSelected();
            // scrolls to top
            document.documentElement.scrollTop = 0;
            document.body.scrollTop = 0;
            return true;
          },
        );
      } else {
        this.setState({
          listData: null,
          totalRecords: 0,
        });
      }
    });
  };

  areAllContactsSelected() {
    let x = 0;
    const { listData } = this.state;
    listData.map((e) => {
      if (e.checked) x += 1;
      return e;
    });
    const checkbox = document.getElementById('selectallcheckbox');
    if (x === listData.length) {
      this.setState({ selectedAll: true });
      if (checkbox) checkbox.indeterminate = false;
    } else {
      this.setState({ selectedAll: false });
      if (checkbox) checkbox.indeterminate = true;
    }

    if (x === 0) {
      if (checkbox) checkbox.indeterminate = '';
    }
  }

  clearAll = () => {
    this.setState({
      entityTypes: [...getCpaPreferences().entity_type],
      relationshipTypes: [...getCpaPreferences().contact_type],
      statusTypes: [...statusTypesObj],
      searchFieldText: '',
      areEntityTypesSelected: false,
      selectedEntityTypes: [],
      selectedStatusTypes: [],
      selectedRelationshipTypes: [],
      onlyAccountOwners: false,
      selectedAccountId: '',
      selectedAccountName: '',
      selectedCsTeam: undefined,
    });
  };

  handleInputField = (input) => {
    this.setState({ searchFieldText: input.target.value });
  };

  handleCancelBtn = () => {
    this.setState({
      entityTypes: [...getCpaPreferences().entity_type],
      relationshipTypes: [...getCpaPreferences().contact_type],
      statusTypes: [...statusTypesObj],
      selectedEntityTypes: [],
      selectedRelationshipTypes: [],
      selectedStatusTypes: [],
      searchFieldText: '',
      sortBy: 'last_name',
      sortOrder: 'asc',
      pagination: 1,
      listData: [],
      selectedData: [],
      totalPages: 0,
      totalRecords: 0,
      showOnlySelectedData: false,
      selectedAll: false,
      areEntityTypesSelected: false,
      disableSendBtn: false,
      onlyAccountOwners: false,
      sendMessageAfterImageUpload: false,
      selectedAccountId: '',
      selectedAccountName: '',
      selectedCsTeam: undefined,
    });
  };

  handleFilterCheckbox = (input, index, crossclick = false) => {
    const modifiedIndex = index - 1;
    const { entityTypes, areEntityTypesSelected } = this.state;
    const selectedEntityTypes = [];
    if (modifiedIndex > -1 || crossclick) {
      if (crossclick) {
        const object = entityTypes.find((t) => t.key === input);
        const indexx = entityTypes.indexOf(object);
        entityTypes[indexx] = {
          ...entityTypes[indexx],
          checked: !entityTypes[indexx].checked,
        };
      } else {
        entityTypes[modifiedIndex] = {
          ...entityTypes[modifiedIndex],
          checked: !entityTypes[modifiedIndex].checked,
        };
      }
      entityTypes.map((e) => {
        if (e.checked) {
          selectedEntityTypes.push(e.key);
        }
        return e.key;
      });
    } else {
      entityTypes.map((e) => {
        const each = e;
        if (!areEntityTypesSelected) {
          each.checked = true;
          selectedEntityTypes.push(e.key);
        } else {
          each.checked = false;
        }
        return each;
      });
    }
    this.setState({
      entityTypes,
      selectedEntityTypes,
      areEntityTypesSelected: selectedEntityTypes.length === entityTypes.length,
    });
  };

  handleSelectAll = () => {
    const { listData, selectedAll } = this.state;
    let { selectedData } = this.state;
    const maxCount =
      this.state.maxCount === 0 ? 1000000 : parseInt(this.state.maxCount, 10);
    let isAllChecked = 0;
    let checked = false;
    listData.map((each) => {
      if (each.checked) {
        isAllChecked += 1;
      }
      return each;
    });
    if (!selectedAll && isAllChecked === 0) {
      checked = true;
    }
    if (selectedData.length === maxCount && checked) {
      this.setState({ showModal: true });
    } else {
      let count = selectedData.length;
      listData.map((each) => {
        const eachone = each;
        count += 1;
        if (count <= maxCount && checked) {
          eachone.checked = checked;
          selectedData.push(eachone);
        } else if (!checked) {
          eachone.checked = checked;
          selectedData = removeContact(selectedData, eachone);
        }
        return eachone;
      });
      this.setState(
        {
          listData,
          selectedData,
        },
        () => this.areAllContactsSelected(),
      );
    }
  };

  handleSortBy = (sortBy) => {
    let { sortOrder } = this.state;
    const { selectedData, showOnlySelectedData } = this.state;
    if (this.state.sortBy === sortBy) {
      if (sortOrder === 'asc') {
        sortOrder = 'desc';
      } else {
        sortOrder = 'asc';
      }
    } else {
      sortOrder = 'asc';
    }

    if (showOnlySelectedData) {
      this.setState({
        sortOrder,
        sortBy,
        selectedData: sortSelectedList(selectedData, sortBy, sortOrder),
      });
    } else {
      this.setState(
        {
          sortOrder,
          sortBy,
        },
        () => {
          this.getRecords();
        },
      );
    }
  };

  handleDelete = (key, data) => {
    let { selectedData, listData } = this.state;
    const eachOne = data;
    eachOne.checked = false;
    selectedData = removeContact(selectedData, eachOne);
    listData = updateListData(listData, selectedData);
    this.setState(
      {
        selectedData,
        showOnlySelectedData: selectedData.length,
        listData,
      },
      () => {
        this.updatePaginationDetails(
          selectedData.length < 26 ? 1 : this.state.pagination,
        );
        if (!selectedData.length) {
          this.getRecords();
        }
      },
    );
  };

  handleCheckbox = (index) => {
    const { listData } = this.state;
    let { selectedData } = this.state;
    const maxCount =
      this.state.maxCount === 0 ? 1000000 : parseInt(this.state.maxCount, 10);
    if (
      selectedData.length >= maxCount &&
      (listData[index].checked === undefined ||
        listData[index].checked === false)
    ) {
      this.setState({ showModal: true });
    } else {
      if (listData[index].checked) {
        listData[index].checked = false;
      } else {
        listData[index].checked = true;
      }
      if (listData[index].checked) {
        selectedData.push(listData[index]);
      } else {
        selectedData = removeContact(selectedData, listData[index]);
      }
      this.setState(
        {
          listData,
          selectedData,
        },
        () => this.areAllContactsSelected(),
      );
      if (
        (this.state.showerrormessgaefornextbtn ||
          this.state.showOnlySelectedData) &&
        !this.isAtleastOneContactSelected()
      ) {
        this.setState({ showOnlySelectedData: false });
      } else {
        this.setState({ showerrormessgaefornextbtn: false });
      }
    }
  };

  handlePageChange = (input) => {
    if (this.state.showOnlySelectedData) {
      this.updatePaginationDetails(input);
    } else {
      this.setState({ pagination: input }, () => this.getRecords());
    }
  };

  handleStatusFilter = (input, index, crossclick = false) => {
    const {
      filterValuesArray: statusTypes,
      selectedFilterValuesArray: selectedStatusTypes,
    } = updatedSelectedFilterValues(
      this.state.statusTypes,
      index + 1,
      this.state.areEntityTypesSelected,
      input,
      crossclick,
    );
    this.setState({
      statusTypes,
      selectedStatusTypes,
    });
  };

  sendBulkMessages = () => {
    if (this.state.uploadingImages) {
      this.setState({
        loading: true,
        sendMessageAfterImageUpload: true,
      });
      return false;
    }
    const { datatosend, selectedData } = this.state;
    const { comment } = datatosend;
    this.setState({ disableSendBtn: true });
    const data = JSON.parse(JSON.stringify(datatosend));
    const draftState = JSON.parse(JSON.stringify(comment));
    if (
      typeof draftState === 'object' &&
      draftState !== null &&
      EditorState.createWithContent(convertFromRaw(draftState))
        .getCurrentContent()
        .getPlainText().length
    ) {
      data.description = removeBreakElementinList(
        stateToHTML(
          EditorState.createWithContent(
            convertFromRaw(draftState),
          ).getCurrentContent(),
          options,
        ),
      );
    } else if (typeof draftState === 'string' && draftState.length) {
      data.description = draftState;
    } else {
      data.description = '';
    }
    // if (data.description && data.description.length > 0) {
    //   data.description = data.description.replace(/\n/g, '<br>');
    // }
    // data.content_json = JSON.stringify(draftState);
    const ids = [];
    selectedData.map((each) => {
      const tmpHash = {};
      const key = each.contact.id ? each.contact.id : '';
      const val = each.contact.account_id ? each.contact.account_id : '';
      tmpHash[key] = val;
      ids.push(tmpHash);
      return each;
    });
    data.ids = ids;
    const request = Axios.post(SEND_BULKMESSAGE, data);
    request.then((res) => {
      if (res.status === 200 && res.data.status === 200) {
        AlertMessage('success', res.data.message, 2000);
        this.handleCancelBtn();
      } else {
        AlertMessage('error', res.data.message, 2000);
        this.setState({
          loading: false,
          sendMessageAfterImageUpload: false,
        });
      }
      sendMixpanelEvent('SendBulkMessage', {
        message: res.data && res.data.message ? res.data.message : null,
      });
    });
  };

  showDocumentSectionFn = (input) => {
    if (this.state.selectedData.length) {
      this.setState(
        {
          showOnlySelectedData: false,
          showDocumentSection: input,
        },
        () => this.areAllContactsSelected(),
      );
    } else {
      AlertMessage(
        'error',
        'Please select atleast one contact to continue',
        3000,
      );
    }
  };

  showSelectedData = (input, datatosend) => {
    if (this.state.selectedData.length) {
      this.setState(
        {
          showOnlySelectedData: input,
          showDocumentSection: !input,
          datatosend,
        },
        () => this.updatePaginationDetails(1),
      );
    } else {
      AlertMessage(
        'error',
        'Please select atleast one contact to continue',
        3000,
      );
    }
    if (!input) {
      this.setState(
        {
          sortBy: 'last_name',
          sortOrder: 'asc',
        },
        () => this.getRecords(),
      );
    }
  };

  updatePaginationDetails = (pagination) => {
    let pageNumber = pagination;
    const { showOnlySelectedData, listData, selectedData } = this.state;
    const total_entries = showOnlySelectedData
      ? selectedData.length
      : listData.length;
    if (Number(Math.ceil(total_entries / 25)) < pagination) {
      pageNumber -= 1;
    }
    this.setState({
      offset: getOffSetsAndLimit(total_entries, 25)[pageNumber - 1],
      totalPages: Number(Math.ceil(total_entries / 25)),
      totalRecords: total_entries,
      pagination: pageNumber,
    });
  };

  searchKeyPress = (e) => {
    if (e.charCode === 13) {
      this.hanldeSearchBtn();
    }
  };

  hanldeSearchBtn = () => {
    this.setState(
      {
        // selectedData: [],
        pagination: 1,
      },
      () => {
        this.getRecords();
      },
    );
  };

  handleRelationship = (input, index, crossclick = false) => {
    const modifiedIndex = index;
    const { relationshipTypes, arerelationshipTypesSelected } = this.state;
    const selectedRelationshipTypes = [];
    if (modifiedIndex > -1 || crossclick) {
      if (crossclick) {
        const object = relationshipTypes.find((t) => t.key === input);
        const indexx = relationshipTypes.indexOf(object);
        relationshipTypes[indexx] = {
          ...relationshipTypes[indexx],
          checked: !relationshipTypes[indexx].checked,
        };
      } else {
        relationshipTypes[modifiedIndex] = {
          ...relationshipTypes[modifiedIndex],
          checked: !relationshipTypes[modifiedIndex].checked,
        };
      }
      relationshipTypes.map((e) => {
        if (e.checked) {
          selectedRelationshipTypes.push(e.key);
        }
        return e.key;
      });
    } else {
      relationshipTypes.map((e) => {
        const each = e;
        if (!arerelationshipTypesSelected) {
          each.checked = true;
          selectedRelationshipTypes.push(e.key);
        } else {
          each.checked = false;
        }
        return each;
      });
    }
    this.setState({
      relationshipTypes,
      selectedRelationshipTypes,
      arerelationshipTypesSelected:
        selectedRelationshipTypes.length === relationshipTypes.length,
    });
  };

  handleAccountOwnerCheckbox = () => {
    this.setState({ onlyAccountOwners: !this.state.onlyAccountOwners });
  };

  handleDropdown = (item) => {
    this.setState(
      {
        selectedAccountId: item ? item.value : '',
        selectedAccountName: item ? item.label : '',
      },
      () => {
        // this.getRecords();
      },
    );
  };

  handleCstTeam = (value) => {
    this.setState(
      {
        selectedCsTeam: value,
      },
      () => {
        // this.getRecords();
      },
    );
  };

  handleFilterInFocus = (filterName = '', e = {}) => {
    if (e.stopPropagation) {
      e.stopPropagation();
    }
    this.setState({
      filterInFocus: this.state.filterInFocus === filterName ? '' : filterName,
    });
  };

  hideOpenFilters = () => {
    this.setState({ filterInFocus: '' });
  };

  render() {
    const {
      entityTypes,
      searchFieldText,
      listData,
      selectedEntityTypes,
      sortBy,
      sortOrder,
      offset,
      selectedAll,
      showModal,
      showOnlySelectedData,
      selectedData,
      maxCount,
      areEntityTypesSelected,
      showDocumentSection,
      disableSendBtn,
      statusTypes,
      selectedStatusTypes,
      relationshipTypes,
      selectedRelationshipTypes,
      onlyAccountOwners,
      sendMessageAfterImageUpload,
      totalRecords,
      pagination,
      totalPages,
      selectedAccountId,
      selectedAccountName,
      selectedCsTeam,
      filterInFocus,
    } = this.state;
    return (
      <div className="BulkMessage">
        {this.state.loading || sendMessageAfterImageUpload ? (
          <LoadingOverlay />
        ) : null}
        <header>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">Bulk Action</li>
            <li className="breadcrumb-item">Send Message</li>
          </ul>
        </header>
        <div className="center-wrapper" style={{ overflow: 'inherit' }}>
          <section>
            {!showOnlySelectedData && !showDocumentSection ? (
              <SearchSection
                maxCount={maxCount}
                selectedEntityTypes={selectedEntityTypes}
                selectedRelationshipTypes={selectedRelationshipTypes}
                searchFieldText={searchFieldText}
                entityTypes={[
                  {
                    value: 'Select All',
                    label: 'Select All',
                    checked: areEntityTypesSelected,
                  },
                ].concat(entityTypes)}
                relationshipTypes={relationshipTypes}
                selectedData={selectedData}
                handleInputField={this.handleInputField}
                hanldeSearchBtn={this.hanldeSearchBtn}
                handleFilterCheckbox={this.handleFilterCheckbox}
                handleRelationship={this.handleRelationship}
                uniquekey={(input) => input.contact.id}
                clearAll={this.clearAll}
                searchKeyPress={this.searchKeyPress}
                placeHolder="Search Contact"
                statusTypes={statusTypes}
                handleStatusFilter={this.handleStatusFilter}
                selectedStatusTypes={selectedStatusTypes}
                handleAccountOwnerCheckbox={this.handleAccountOwnerCheckbox}
                onlyAccountOwners={onlyAccountOwners}
                handleAccountDropdown={this.handleDropdown}
                selectedAccountId={selectedAccountId}
                selectedAccountName={selectedAccountName}
                handleCstTeam={this.handleCstTeam}
                selectedCsTeam={selectedCsTeam}
                handleFilterInFocus={this.handleFilterInFocus}
                filterInFocus={filterInFocus}
              />
            ) : null}
            {showOnlySelectedData ? (
              <div className="pb-4 section-title section-title--fullwidth">
                <div className="col-container">
                  <div className="col">
                    <h5 className="text-small">
                      Message would be sent to the following contacts:
                    </h5>
                  </div>
                </div>
              </div>
            ) : null}
            {listData === undefined || listData === null ? <NoRecords /> : null}
            {listData &&
            listData !== null &&
            totalRecords &&
            !showDocumentSection ? (
              <SearchList
                tableHeaders={
                  showOnlySelectedData
                    ? bulkLastPageMessageTableHeaders
                    : bulkMessageTableHeaders
                }
                listData={
                  showOnlySelectedData
                    ? [...selectedData.slice(offset[0] - 1, offset[1])]
                    : listData
                }
                sortBy={sortBy}
                sortOrder={sortOrder}
                handleSortBy={this.handleSortBy}
                handleCheckbox={
                  !showOnlySelectedData ? this.handleCheckbox : () => {}
                }
                handleSelectAll={this.handleSelectAll}
                selectedAll={selectedAll}
                showCheckbox={!showOnlySelectedData}
                showDeleteIcon={showOnlySelectedData}
                handleDelete={this.handleDelete}
                showActionDiv={showOnlySelectedData}
              />
            ) : null}
            {listData &&
            listData !== null &&
            totalRecords &&
            !showDocumentSection ? (
              <Pagination
                currentPage={pagination}
                totalPages={totalPages}
                totalRecords={totalRecords}
                handlePageChange={this.handlePageChange}
                handleSelectAll={this.handleSelectAll}
                selectedAll={selectedAll}
                offset={offset[0]}
                off={offset[1]}
              />
            ) : null}
            <MessageSection
              showThis={showDocumentSection}
              showDocumentSectionFn={this.showDocumentSectionFn}
              showSelectedData={this.showSelectedData}
            />
            {listData &&
            listData !== null &&
            totalRecords &&
            !showOnlySelectedData &&
            !showDocumentSection ? (
              <div className="formbtn BulkMessageButtonGroup">
                <div className="btn-wrap btn--leftspace">
                  <button
                    type="button"
                    className="btn btn-outline-light"
                    onClick={this.handleCancelBtn}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => this.showDocumentSectionFn(true)}
                  >
                    Next Step
                  </button>
                </div>
              </div>
            ) : null}
            {showOnlySelectedData ? (
              <div className="formbtn BulkMessageButtonGroup">
                <div className="btn-wrap btn--leftspace">
                  <button
                    type="button"
                    className="btn btn-outline-light"
                    onClick={() => this.showSelectedData(false)}
                  >
                    Back
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={this.sendBulkMessages}
                    disabled={disableSendBtn}
                  >
                    Send Message
                  </button>
                </div>
              </div>
            ) : null}
          </section>
        </div>
        {showModal ? (
          <ConfirmationModal
            isOpen={showModal}
            messageText={`You can select up to ${maxCount} contacts`}
            noBtnClick={() => {
              this.setState({ showModal: false });
            }}
            noBtnText="Close"
          />
        ) : null}
      </div>
    );
  }
}

export default withStorageData(withRouter(BulkMessage));
