/* eslint-disable */
import React, { Component } from 'react';
import { withRouter } from 'utilities/withRouterWrapper';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getStorageData, selectedIds } from 'utilities/utils';
import ConfirmationModal from 'common/ConfirmationModal';
import Pagination from 'common/Pagination';
import * as UploadFilesActions from 'actions/UploadFilesActions';
import UploadFiles from 'api/UploadFiles';
import UploadFilesFilterTabs from './UploadFilesFilterTabs';
import MultiTagsYearMonthModal from './MultiTagsYearMonthModal';
import UploadsList from './UploadsList';
import FilePopup from '../Files/FilePopup';

const noneStyle = { display: 'none' };
const blockStyle = { display: 'block' };
class ClientLiscioVault extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uploadFilesList: [],
      showFileUploadModal: false,
      contacts: [],
      accounts: [],
      years: [],
      tags: [],
      uploadTags: [],
      accountId: '',
      contactId: '',
      contactName: '',
      accountName: '',
      loading: false,
      errors: {},
      contactUploadFile: false,
      accountUploadFile: false,
      currOpenedInput: '',
      createdBy: '',
      sort: 'desc',
      field: 'created_at',
      page: 1,
      pagination: 0,
      paginationOffset: '',
      paginationOff: '',
      totalDocuments: '',
      api: '',
      selectall: false,
      currentEditIndex: '',
      isChecked: false,
      selectedlist: [],
      selectedrecords: [],
      masterarray: [],
      maxcount: 0,
      showAlert: false,
      showerrormessgaefornextbtn: false,
      showonlyselectedcontacts: false,
      multiTagModal: false,
      clientUploadFile: true,
      selectedFilesDeleteModal: false,
      fileExt: '',
      editFileNameId: '',
      currOpenedId: '',
      oldFileName: '',
      listOfFiles: null,
      months: [],
      filterStatus: 'active',
      filterStatusModal: false,
      fileRemovedFromList: false,
      filterState: {},
      shareFile: false,
      filterBGClicked: 0,
    };
  }

  onClickFilterBG = () => {
    this.setState((prevState) => ({
      filterBGClicked: prevState.filterBGClicked + 1,
    }));
  };

  filterList = (apiPath, status, com) => {
    this.setState({
      api: apiPath,
      filterStatus: status,
      filterState: com,
    });
    UploadFiles.sortedData(
      this.state.sort,
      this.state.field,
      this.state.page,
      apiPath,
      this,
    );
  };

  UNSAFE_componentWillMount() {
    // TODO: __HOMEDATA-AUDIT
    const storageData = getStorageData();
    const homeDataObj = storageData.pref_type_options;
    this.setState({
      years: homeDataObj.upload_file_year,
      months: homeDataObj.upload_file_month
        ? homeDataObj.upload_file_month
        : [],
      createdBy: storageData.uname,
      tags: homeDataObj.filter_tags,
      uploadTags: homeDataObj.upload_tags,
    });
  }

  componentDidMount() {
    const { selecteddocuments: selectedrecords } = this.props;
    let selecteddocuments = [];
    if (selectedrecords && selectedrecords.length) {
      selecteddocuments = selectedrecords.filter((e) => e.is_active);
    }
    this.setState(
      {
        selectedlist: selecteddocuments !== undefined ? selecteddocuments : [],
      },
      () => {
        UploadFiles.getClientUploadFiles(this);
      },
    );
  }

  componentWillUnmount() {
    document.addEventListener('click', () => {});
  }

  uploadFile = (fro = '') => {
    if (fro) {
      this.setState(
        {
          showFileUploadModal: false,
          multiTagModal: false,
        },
        () => {
          const { api, sort, field, pagination } = this.state;
          const apiPath = !api ? '' : api;
          UploadFiles.sortedData(sort, field, pagination + 1, apiPath, this);
          this.hideSelectedFilesDeleteModal();
        },
      );
    } else {
      this.setState({ showFileUploadModal: true });
    }
  };

  closeModal = (ishared = '', uniqueRecords = '') => {
    this.setState({ shareFile: false });
    if (ishared === 'shared' && uniqueRecords.length > 0) {
      const { api, sort, field, pagination } = this.state;
      const apiPath = !api ? '' : api;
      UploadFiles.sortedData(sort, field, pagination + 1, apiPath, this);
      this.hideSelectedFilesDeleteModal();
    }
  };

  handlePageChange = (index) => {
    const component = this;
    if (index === component.state.pagination + 1) {
      return false;
    }
    let apiPath = '';
    if (component.state.api) {
      apiPath = component.state.api;
    }
    UploadFiles.sortedData(
      component.state.sort,
      component.state.field,
      index,
      apiPath,
      component,
    );
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
    return true;
  };

  handleSorting = (fieldName) => {
    let stateSort = this.state.sort;
    if (this.state.field === fieldName) {
      if (stateSort === 'asc') {
        stateSort = 'desc';
      } else {
        stateSort = 'asc';
      }
    } else {
      stateSort = 'desc';
    }
    let apiPath = '';
    if (this.state.api) {
      apiPath = this.state.api;
    }
    this.setState({ sort: stateSort, field: fieldName }, () => {
      UploadFiles.sortedData(
        this.state.sort,
        this.state.field,
        this.state.page,
        apiPath,
        this,
      );
    });
  };

  areAllContactsSelected = () => {
    const pagefiles = this.state.uploadFilesList;
    let count = 0;
    const masterarrayy = this.state.masterarray;
    pagefiles.map((eachone) => {
      masterarrayy.map((m) => {
        if (m.id === eachone.id && m.doc_name === eachone.doc_name) {
          count += 1;
        }
        return null;
      });
      return null;
    });
    const checkbox = document.getElementById('selectallcheckbox');
    if (pagefiles.length === count && count !== 0) {
      this.setState({ selectall: true });
      if (checkbox) {
        checkbox.checked = true;
      }
    } else if (pagefiles.length === count && count === 0) {
      this.setState({ selectall: false });
      if (checkbox) {
        checkbox.checked = false;
      }
    } else if (count > 0) {
      if (checkbox) {
        checkbox.indeterminate = true;
      }
    } else {
      this.setState({ selectall: false });
      if (checkbox) {
        checkbox.indeterminate = false;
        checkbox.checked = false;
      }
    }
  };

  hideSelectedFilesDeleteModal = () => {
    this.setState(
      {
        selectedlist: [],
        masterarray: [],
        selectedrecords: [],
        selectedFilesDeleteModal: false,
      },
      () => this.areAllContactsSelected(),
    );
  };

  selectedFilesDeleteConfirmed = (act = '') => {
    if (act === 'delete') {
      UploadFiles.deleteSelectedData(this);
    } else if (act === 'filterstatus') {
      const { filterStatus, selectedlist } = this.state;
      const data = {
        id: selectedIds(selectedlist, 'id'),
      };
      if (filterStatus === 'active') {
        data.archive = 'true';
      }
      UploadFiles.archiveFile(this, data);
    }
  };

  // to update list of attachments in task or message or notes attached by liscio vault
  updateAttachments() {
    const { selectedlist } = this.state;
    if (selectedlist && selectedlist.length) {
      selectedlist.map((e) => {
        e.fromlisciovault = true;
        return e;
      });
    }
    this.props.updateDocuments({ documents: selectedlist });
  }

  handleCheckbox = (input, key, selectedrecord) => {
    const component = this;
    let checkbox;
    let selectallcheckbox;
    let masterarrayy = [];
    let selectedrecordss = [];
    const files = component.state.uploadFilesList;
    masterarrayy = component.state.selectedlist;
    selectedrecordss = component.state.selectedrecords;
    files.map((item) => {
      let index = -1;
      let masterindex = -1;
      if (item.id === input.id) {
        masterarrayy.map((m, k) => {
          if (m.id === item.id && m.doc_name === item.doc_name) {
            masterindex = k;
          }
          return null;
        });
        if (masterindex !== -1) {
          index = selectedrecordss.indexOf(selectedrecord);
          if (index !== -1) {
            if (component.state.selectall) {
              component.setState({
                selectall: false,
              });
              checkbox = document.getElementById('selectallcheckbox');
              if (checkbox) {
                checkbox.indeterminate = true;
                checkbox.checked = false;
              }
            }
            selectedrecordss.splice(index, 1);
            masterarrayy.splice(masterindex, 1);
          }
        } else if (
          component.state.maxcount !== 0 &&
          component.state.selectedrecords.length >= component.state.maxcount &&
          masterindex === -1
        ) {
          component.setState({
            showAlert: true,
          });
        } else {
          index = selectedrecordss.indexOf(selectedrecord);
          if (index === -1) {
            selectedrecordss.push(selectedrecord);
            masterarrayy.push(item);
            checkbox = document.getElementById('selectallcheckbox');
            if (checkbox) {
              checkbox.indeterminate = true;
            }
          }
        }
      }
      return null;
    });
    component.setState({
      selectedrecords: selectedrecordss,
      masterarray: masterarrayy,
    });
    if (
      (this.state.showerrormessgaefornextbtn ||
        this.state.showonlyselectedcontacts) &&
      !this.isAtleastOneContactSelected()
    ) {
      this.setState({
        showonlyselectedcontacts: false,
      });
    } else {
      this.setState({
        showerrormessgaefornextbtn: false,
      });
    }
    let selectedfiles = 0;
    files.map((eachone) => {
      masterarrayy.map((m) => {
        if (m.id === eachone.id && m.doc_name === eachone.doc_name) {
          selectedfiles += 1;
        }
        return null;
      });
      return null;
    });
    const unselectedfiles = files.length - selectedfiles;
    if (selectedfiles === 0) {
      selectallcheckbox = document.getElementById('selectallcheckbox');
      if (selectallcheckbox) {
        selectallcheckbox.indeterminate = false;
      }
    } else if (unselectedfiles === 0) {
      checkbox = document.getElementById('selectallcheckbox');
      if (checkbox) {
        checkbox.indeterminate = false;
        checkbox.checked = true;
      }
      this.setState({
        selectall: true,
      });
    }
  };

  render() {
    const list = this.state.uploadFilesList;
    let totalpages =
      this.state.totalDocuments !== 0
        ? Math.ceil(this.state.totalDocuments / 20)
        : 0;
    totalpages = parseInt(totalpages, 10);
    const totalrecords = this.state.totalDocuments;
    let offset = 0;
    let off = 0;
    if (this.state.uploadFilesList !== undefined) {
      offset = this.state.paginationOffset;
      off = this.state.paginationOff;
    }
    const {
      listOfFiles,
      filterStatus,
      filterStatusModal,
      selectedlist,
      showFileUploadModal,
      multiTagModal,
    } = this.state;
    return (
      <div id="content" onClick={this.onClickFilterBG} role="presentation">
        <header>
          <h1 className="pageHeading">Liscio Vault</h1>
        </header>
        <div
          className="center-wrapper"
          style={{ height: '75vh', overflow: 'scroll' }}
        >
          <div
            id="loading"
            style={this.state.loading ? blockStyle : noneStyle}
          />
          {listOfFiles === null || listOfFiles === undefined ? (
            <h3 className="text-light text-small">Fetching Files ...</h3>
          ) : (
            <div>
              <UploadFilesFilterTabs
                data={this.state}
                component={this}
                uploadFile={() => this.uploadFile()}
                liscioVault
                filterBGClicked={this.state.filterBGClicked}
              />
              <div className="tab-content">
                <div className="tab-pane active" id="review">
                  <div id="inbox-list" className="table tableWrap">
                    <UploadsList
                      data={this.state}
                      component={this}
                      uploadFilesList={list}
                      handleSorting={this.handleSorting}
                      navigate={this.props.navigate}
                      liscioVault
                    />
                    <Pagination
                      currentPage={
                        list.length >= 1 ? this.state.pagination + 1 : 0
                      }
                      totalPages={totalpages}
                      totalRecords={totalrecords}
                      offset={totalrecords === 0 ? offset - 1 : offset}
                      off={off}
                      handlePageChange={this.handlePageChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          {this.state.shareFile && (
            <FilePopup
              shareFile
              shareType="multiple"
              sharedFileDetails={this.state.masterarray}
              closeModal={this.closeModal}
            />
          )}
          {multiTagModal && (
            <MultiTagsYearMonthModal
              closeModal={() => this.uploadFile('close')}
              selectedrecords={selectedlist}
            />
          )}
          <ConfirmationModal
            isOpen={this.state.selectedFilesDeleteModal}
            headerText="Confirm delete these files?"
            messageText={this.state.alertText || ''}
            noBtnClick={this.hideSelectedFilesDeleteModal}
            yesBtnClick={() => this.selectedFilesDeleteConfirmed('delete')}
            yesBtnText="Delete Files"
            noBtnText="Cancel"
          />
          <ConfirmationModal
            isOpen={filterStatusModal}
            headerText={`Confirm ${
              filterStatus === 'active' ? 'archive' : 'unarchive'
            } ${selectedlist.length > 1 ? 'these files' : 'this file'}`}
            messageText={`Are you sure you want to ${
              filterStatus === 'active' ? 'archive' : 'unarchive'
            } ${selectedlist.length > 1 ? 'these files' : 'this file'}?`}
            noBtnClick={() => this.setState({ filterStatusModal: false })}
            yesBtnClick={() =>
              this.selectedFilesDeleteConfirmed('filterstatus')
            }
            yesBtnText="Yes"
            noBtnText="No"
          />
          {showFileUploadModal ? (
            <FilePopup closeModal={() => this.uploadFile('close')} />
          ) : null}
        </div>
        <div className="center-wrapper row no-gutters justify-content-between">
          <div className="col" />
          <div className="align-self-end">
            <button
              type="button"
              className="btn btn-outline-light mr-3"
              onClick={() => this.props.openVault(false)}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() =>
                this.updateAttachments({ documents: selectedlist })
              }
              disabled={
                selectedlist === undefined ||
                selectedlist === null ||
                selectedlist.length === 0
              }
            >
              Attach Files
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  filesData: state.uploadFilesReducer.filesData,
});

const mapDispatchToProps = (dispatch) => ({
  UploadFilesActions: bindActionCreators(UploadFilesActions, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ClientLiscioVault),
);
