import React, { Component } from 'react';
import { connect } from 'react-redux';
import HeaderTabs from 'common/HeaderTabs_updated';
import { isUserEmployee } from 'utilities/utils';
import { withRouter } from 'utilities/withRouterWrapper';
import UserProfile from './UserProfile';
import SecuritySettings from './SecuritySettings';
import ThirdPartyApps from './ThirdPartyApps';
import LiscioApps from './LiscioApps';

const ProfileSettingsTabs = [
  {
    label: 'User Information',
    value: 'user_information',
  },
  {
    value: 'security_settings',
    label: 'Security Settings',
  },
  {
    value: 'third_party_apps',
    label: 'Third-party Apps',
  }
];
class ProfileSettings extends Component {
  constructor(props) {
    super(props);
    // console.log(window.location.href);
    const parsedUrl = new URL(window.location.href);
    const tab = parsedUrl.searchParams.get('tab');
    let tabIndex = 0;
    if (window.location.href.includes('integration')) {
      tabIndex = 2;
    } else if (tab) {
      tabIndex = ProfileSettingsTabs.findIndex((each) => each.value === tab);
    }
    let changePassword = false;
    if (
      props.location &&
      props.location.state &&
      props.location.state.changePassword
    ) {
      changePassword = true;
    }
    this.state = {
      activeTab: tabIndex === undefined ? 0 : tabIndex,
      changePasswordMode: changePassword,
    };
  }

  componentDidMount() {
    document.title = 'My Profile Settings';
    const { activeTab } = this.state;
    if (
      activeTab &&
      ProfileSettingsTabs[activeTab] &&
      !window.location.href.includes('integration')
    ) {
      this.props.navigate(
        `/profile_settings?tab=${ProfileSettingsTabs[activeTab].value}`,
      );
    }
  }

  UNSAFE_componentWillReceiveProps() {
    console.log('this props', this.props);
    if (
      this.props.updateAwaySetting &&
      this.props.updateAwaySetting === true &&
      this.state.activeTab > 0
    ) {
      this.changeActiveTab(0, ProfileSettingsTabs[0]);
    }
  }

  changeActiveTab = (key) => {
    this.setState({
      activeTab: key,
      changePasswordMode: false,
    });
    this.props.navigate(
      `/profile_settings?tab=${ProfileSettingsTabs[key].value}`,
    );
  };

  changePassword = () => {
    this.setState({ activeTab: 0, changePasswordMode: true });
  };

  render() {
    const { activeTab, changePasswordMode } = this.state;
    let currentPageHeader = '';
    let currentPage = null;
    if (activeTab === 0 || changePasswordMode) {
      currentPageHeader = 'MY PROFILE SETTINGS';
      currentPage = <UserProfile changePasswordMode={changePasswordMode} />;
    } else if (activeTab === 1) {
      currentPageHeader = 'MANAGE DEVICES';
      currentPage = (
        <SecuritySettings
          navigate={this.props.navigate}
          changePassword={this.changePassword}
        />
      );
    } else if (activeTab === 2) {
      currentPageHeader = 'THIRD-PARTY APPS';
      currentPage = <ThirdPartyApps navigate={this.props.navigate} />;
    } else if (activeTab === 3) {
      currentPageHeader = 'LISCIO DOWNLOAD APPS';
      currentPage = <LiscioApps />;
    }
    let tabs = ProfileSettingsTabs;
    if (
      isUserEmployee() !== undefined &&
      isUserEmployee() !== null &&
      !isUserEmployee()
    ) {
      tabs = ProfileSettingsTabs.slice(0, 2);
    }
    return (
      <div id="content">
        <header>
          <h1 className="pageHeading">{currentPageHeader}</h1>
        </header>
        <div>
          <HeaderTabs
            data={tabs}
            activeTab={activeTab}
            handleClick={this.changeActiveTab}
            customClasses="navtabs-bgColor navtabs-small justify-content-center"
          />
        </div>
        <div>{currentPage}</div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  updateAwaySetting: state.notificationReducer.updateAwaySetting,
});

export default withRouter(connect(mapStateToProps, null)(ProfileSettings));
