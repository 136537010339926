/* eslint-disable no-case-declarations */
const initialState = {
  tasks: {},
  taskStatus: 'Open',
  apiUrl: '',
  taskFilters: {},
  notificationAvailable: false,
  taskDetail: [],
};

// eslint-disable-next-line default-param-last
export default function taskReducer(state = initialState, action) {
  switch (action.type) {
    case 'FETCH_SUCCESS':
      return { ...state, tasks: action.data };
    case 'TASK_URL':
      return { ...state, apiUrl: action.data };
    case 'TASK_STATUS':
      return { ...state, taskStatus: action.data };
    case 'HANDLE_TASKS_FILTERS':
      return { ...state, taskFilters: action.data };
    case 'FETCH_TASK_DETAIL':
      return { ...state, taskDetail: action.data };
    case 'RECEIVED_TASKNOTIFICATION': {
      return {
        ...state,
        notificationAvailable: action.data,
      };
    }
    case 'APPEND_NEW_TASK':
      const prevState = { ...state.tasks };
      const receivedData = action.data[0].templates[0];
      let { status } = receivedData;
      // let status = action.data[0].templates[0].status; // status from receiving data
      // check if cable object is already there is list n replace it with new one
      if (status === 'Closed') {
        status = 'Archive';
      }
      const ObjectName1 = prevState.templates[0].Open.find(
        (t) => t.id === receivedData.id,
      );
      const ObjectName2 = prevState.templates[0].Review.find(
        (t) => t.id === receivedData.id,
      );
      const ObjectName3 = prevState.templates[0].Archive.find(
        (t) => t.id === receivedData.id,
      );

      if (ObjectName1) {
        const index1 = prevState.templates[0].Open.indexOf(ObjectName1);
        if (index1 > -1) {
          prevState.templates[0].Open.splice(index1, 1);
        }
      }
      if (ObjectName2) {
        const index2 = prevState.templates[0].Review.indexOf(ObjectName2);
        if (index2 > -1) {
          prevState.templates[0].Review.splice(index2, 1);
        }
      }
      if (ObjectName3) {
        const index3 = prevState.templates[0].Archive.indexOf(ObjectName3);
        if (index3 > -1) {
          prevState.templates[0].Archive.splice(index3, 1);
        }
      }
      if (receivedData.task_type_key === 'to_do' && status === 'Open') {
        if (receivedData.owner_id === receivedData.assigne_cpa_user_id) {
          // if assigned to user and owner are same show task in both tabs (open n review)
          prevState.templates[0].Open = [
            receivedData,
            ...prevState.templates[0].Open,
          ];
        }
        prevState.templates[0].Review = [
          receivedData,
          ...prevState.templates[0].Review,
        ];
      } else {
        prevState.templates[0][status] = [
          receivedData,
          ...prevState.templates[0][status],
        ];
      }
      prevState.Archive_total = action.data[1].Archive_total;
      prevState.Open_total = action.data[1].Open_total;
      prevState.Review_total = action.data[1].Review_total;
      prevState.res_data = action.data[2].res_data;
      return { ...state, tasks: prevState };
    case 'BULK_REASSIGN_CHECKBOX':
      const prev = { ...state.tasks };
      prev.templates[0].Open = action.data;
      return { ...state, tasks: prev };
    default:
      return state;
  }
}
