// TODO: check react-s-alert
import React, { Component } from 'react';
import { withRouter } from 'utilities/withRouterWrapper';
import axios from 'axios';
// import Alert from 'react-s-alert';
import { AlertMessage, getSiteHostname } from 'utilities/utils';
import { LOGIN_WITH_MAGIC_LINK_API_PATH } from 'constants/constants';
import { setAuthSession } from 'auth/session';

const noneStyle = { display: 'none' };
const blockStyle = { display: 'block' };

class MagicLink extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: false };
  }

  componentDidMount() {
    const location = getSiteHostname();
    const component = this;
    const {
      params: { id },
    } = this.props;
    if (id) {
      const data = {
        link: id,
        company_name: location,
      };
      this.setState({ loading: true });
      const request = axios.post(LOGIN_WITH_MAGIC_LINK_API_PATH, data);
      request.then((response) => {
        component.setState({ loading: false });
        if (response.data.status === 200) {
          if (response.data.two_factor_enabled) {
            data.email = response.data.email;
            component.props.navigate('/');
          } else {
            const authToken = response.data.auth_token;
            setAuthSession({ auth_token: authToken });
            component.props.navigate('/dashboard');
          }
        } else {
          AlertMessage('error', response.data.message, 5000000000000);
        }
      });
    } else {
      component.props.navigate('/');
    }
  }

  render() {
    return (
      <div>
        <div id="loading" style={this.state.loading ? blockStyle : noneStyle} />
        {/* <Alert html /> */}
      </div>
    );
  }
}
export default withRouter(MagicLink);
