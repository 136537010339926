import { useMutation, useQuery } from '@tanstack/react-query';
import {
  createPaymentEstimates,
  deletePaymentEstimates,
  getPaymentEstimates,
  updatePaymentEstimates,
} from 'api/tax-delivery';

export function useGetPaymentEstimates(id: number | string) {
  return useQuery({
    queryKey: ['thread-detail', id],
    queryFn: getPaymentEstimates,
  });
}

export function useCreatePaymentEstimates() {
  return useMutation({
    mutationFn: createPaymentEstimates,
  });
}

export function useUpdatePaymentEstimates() {
  return useMutation({
    mutationFn: updatePaymentEstimates,
  });
}

export function useDeletePaymentEstimates() {
  return useMutation({
    mutationFn: deletePaymentEstimates,
  });
}
