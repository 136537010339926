import Label from 'common/Label/Label';
import theme from 'theme';
import { WorkflowRequestStatus, WorkflowStatus } from 'gql/graphql';

export type RequestTypeLabelProps = {
  status: WorkflowRequestStatus | WorkflowStatus;
};

const requestStatusTextMap = {
  [WorkflowRequestStatus.Requested]: 'OPEN',
  [WorkflowRequestStatus.Open]: 'OPEN',
  [WorkflowRequestStatus.Submitted]: 'PENDING',
  [WorkflowRequestStatus.Closed]: 'ARCHIVED',
  [WorkflowRequestStatus.Recalled]: 'RECALLED',
  [WorkflowRequestStatus.Draft]: 'DRAFT',
};

export const RequestTypeLabel = ({ status }: RequestTypeLabelProps) => {
  const text =
    requestStatusTextMap[status as keyof typeof requestStatusTextMap];

  if (status === WorkflowRequestStatus.Recalled) {
    const archivedText = requestStatusTextMap[WorkflowRequestStatus.Closed];
    const recalledText = requestStatusTextMap[WorkflowRequestStatus.Recalled];
    return (
      <>
        <Label
          text={archivedText}
          color={theme.palette.primary.main}
          sx={{ marginRight: '8px' }}
        />
        <Label text={recalledText} color={theme.palette.error.main} />
      </>
    );
  }

  return text ? <Label text={text} color={theme.palette.primary.main} /> : null;
};
