import React, { useEffect, useState } from 'react';
// import Axios from 'axios';
// import { USER_EMAIL_SETTINGS } from 'constants/constants';
// import { getInitialState } from 'components/Emails/EmailUtilities';
import styled from '@emotion/styled';
import Clear from '@mui/icons-material/Clear';
// import ComposeEmailFromThread from './ComposeEmailFromThread';

const Aside = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 992;
  .Aside__Sec {
    width: 100%;
    max-width: 820px;
    height: 100vh;
    overflow-y: auto;
    background-color: #fff;
    margin-left: auto;
    position: absolute;
    right: -100%;
    transition: all ease-in-out 350ms;
    &--active {
      right: 0;
    }
  }
  .Aside__Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
    border-bottom: 1px solid #ececec;
  }
  .Aside__Header__Text {
    font-size: 20px;
    color: #353c42;
    margin-bottom: 0;
  }
  &.ReplyModal {
    text-align: left;
    .Aside__form {
      height: calC(100% - 45px);
    }
    .modalContent--inner {
      height: 100%;
      .EmRight__Head__Right {
        position: relative;
      }
    }
    .ReplyAction {
      .tglDrop {
        position: static !important;
        transform: none !important;
        padding: 0;
      }
    }
  }
`;

const ActionsModal = ({
  title,
  isOpen,
  onCancel,
  showModalFooter,
  handleSuccessBtn,
  children,
  slideReply,
}) => {
  const [firstUpdate, setFirstUpdate] = useState(false);

  useEffect(() => {
    if (!firstUpdate) {
      setTimeout(() => {
        setFirstUpdate(!firstUpdate);
      }, 10);
    }
  }, []);

  return (
    <Aside className={`Aside ${slideReply}`}>
      <div className={`Aside__Sec${firstUpdate ? ' Aside__Sec--active' : ''}`}>
        <div className="Aside__Header">
          <h5 className="Aside__Header__Text"> {title} </h5>
          <button
            type="button"
            className="close Button__Close"
            aria-label="Close"
            onClick={onCancel}
          >
            <Clear />
          </button>
        </div>
        <div className="Aside__form">
          {isOpen && <div className="modalContent--inner p-0">{children}</div>}
        </div>
        {showModalFooter && (
          <div>
            <button
              type="button"
              className="text-right btn"
              aria-label="close"
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              type="button"
              aria-label="add"
              className="text-right btn btn-primary"
              onClick={handleSuccessBtn}
            >
              Add
            </button>
          </div>
        )}
      </div>
    </Aside>
  );
};
export default ActionsModal;
