import { RequestsDataGridStyled } from 'components/Requests/components/Styles/Styles';
import {
  WfRequestSortOption,
  WorkflowRequestFragment,
  WorkflowRequestStatus,
  WorkflowsQuery,
} from 'gql/graphql';
import { UseQueryResult } from '@tanstack/react-query';
import {
  GridColDef,
  GridPaginationModel,
  GridSortModel,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom-latest';
import {
  IN_APP,
  REQUESTS_PAGE_ROUTES,
} from 'components/Requests/requests.constants';
import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import {
  TabsKey,
  useRequestsState,
} from 'components/Requests/state/request-state';
import { useGetWorkflowCounts } from 'components/Requests/hooks/get-workflow-counts';
import { DraftRequestOptionsMenu } from 'components/Requests/components/RequestOptionsMenu/DraftRequestOptionsMenu';
import { LoadingOverlay } from './LoadingOverlay';
import { CustomPagination } from './CustomPagination';
import { NoResultsOverlay } from './NoResultsOverlay';

type DraftWorkflowsTableProps = {
  workflowsQuery: UseQueryResult<WorkflowsQuery>;
  paginationModel: GridPaginationModel;
  setPaginationModel: (model: GridPaginationModel) => void;
};

const columns: GridColDef[] = [
  {
    field: 'title',
    headerName: 'Name',
    flex: 1.5,
    sortable: false,
    renderCell: (params) => (
      <Typography textOverflow="ellipsis" noWrap overflow="hidden">
        {params?.value || ''}
      </Typography>
    ),
  },
  {
    field: '_assignedContact',
    headerName: 'Assigned To',
    flex: 1,
    sortable: false,
    valueGetter: (params: GridValueGetterParams) =>
      `${
        params.row.assignedContact?.firstName ||
        params.row.assignedEmployee?.firstName ||
        ''
      } ${
        params.row.assignedContact?.lastName ||
        params.row.assignedEmployee?.lastName ||
        ''
      }`,
  },
  {
    field: '_assignedAccount',
    headerName: 'Account',
    sortable: false,
    valueGetter: (params: GridValueGetterParams) =>
      `${params.row.assignedAccount?.name || ''}`,
    flex: 1,
  },
  {
    field: '_owner',
    headerName: 'Owner',
    flex: 1,
    sortable: false,
    valueGetter: (params: GridValueGetterParams<WorkflowRequestFragment>) =>
      params.row?.owner?.contact &&
      `${params.row.owner?.contact.firstName} ${params.row.owner?.contact.lastName}`,
  },
  {
    field: 'dueDate',
    headerName: 'Due Date',
    flex: 1,
    sortable: false,
    renderCell: (params) =>
      params.value && (
        <>{format(new Date(params.value as string), 'MM/dd/yyyy')}</>
      ),
  },
  {
    field: '_lastActivity',
    headerName: 'Last Activity',
    flex: 1,
    sortable: true,
    valueGetter: (
      params: GridValueGetterParams<{ updatedAt: string; createdAt: string }>,
    ) => params.row?.updatedAt || params.row?.createdAt,
    renderCell: (params) =>
      params.value &&
      format(new Date(params.value as string), "MM/dd/yyyy hh:mm aaaaa'm'"),
  },
  {
    field: '_menuOptions',
    headerName: ' ',
    width: 100,
    sortable: false,
    renderCell: ({ row }) => (
      <DraftRequestOptionsMenu id={row.id} type="workflow" />
    ),
  },
];

export const DraftWorkflowsTable = ({
  workflowsQuery,
  paginationModel,
  setPaginationModel,
}: DraftWorkflowsTableProps) => {
  const { requestsCounts, isLoadingOrError } = useGetWorkflowCounts();
  const { setSortStatus } = useRequestsState();

  const [totalCount, setTotalCount] = useState(-1);

  const navigate = useNavigate();

  const workflowRows =
    workflowsQuery?.data && workflowsQuery?.data?.workflows?.collection;

  useEffect(() => {
    const requestCount = requestsCounts[WorkflowRequestStatus.Draft as TabsKey];
    if (isLoadingOrError || !requestCount) {
      return;
    }
    setTotalCount(requestCount);
  }, [isLoadingOrError, requestsCounts]);

  useEffect(() => {
    if (workflowsQuery.isLoading || workflowsQuery.fetchStatus !== 'idle') {
      return;
    }

    setTotalCount(workflowsQuery?.data?.workflows?.totalCount || 0);
  }, [
    workflowsQuery?.data?.workflows?.totalCount,
    workflowsQuery.fetchStatus,
    workflowsQuery.isLoading,
  ]);

  const isLoading = workflowsQuery?.isLoading && workflowsQuery?.isFetching;

  const refetchAll = () => {
    workflowsQuery.refetch();
  };

  // wait for query to finish before populating the table row array
  // avoids a partial render of the table data
  const tableRows = workflowRows && [...workflowRows];

  const handleRowClick = (row: any) => {
    navigate(`${REQUESTS_PAGE_ROUTES.builder}/${row.id}`, {
      state: { from: IN_APP },
    });
  };

  const handleSortModelChange = (model: GridSortModel) => {
    if (model.length === 0 || !model[0].sort) {
      return;
    }
    const sortField =
      model[0].field === 'dueDate'
        ? WfRequestSortOption.DueDate
        : WfRequestSortOption.LastActivity;
    setSortStatus(WorkflowRequestStatus.Draft, {
      sortField,
      sortDirection: model[0].sort,
    });
  };

  if (totalCount === -1) {
    return <LoadingOverlay />;
  }

  return (
    <RequestsDataGridStyled
      initialState={{
        sorting: {
          sortModel: [{ field: '_lastActivity', sort: 'asc' }],
        },
        pagination: {
          paginationModel,
        },
      }}
      rows={tableRows || []}
      columns={columns}
      sortingMode="server"
      loading={isLoading}
      onRowClick={(params) => {
        handleRowClick(params.row);
      }}
      slots={{
        loadingOverlay: LoadingOverlay,
        noResultsOverlay: () =>
          NoResultsOverlay({
            message: 'No Draft Requests',
            refetch: refetchAll,
          }),
        noRowsOverlay: () =>
          NoResultsOverlay({
            message: 'No Draft Requests',
            refetch: refetchAll,
          }),
        pagination: CustomPagination,
      }}
      disableColumnMenu
      paginationMode="server"
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
      rowCount={totalCount}
      rowHeight={72}
      columnHeaderHeight={36}
      pageSizeOptions={[25, 50]}
      onSortModelChange={handleSortModelChange}
    />
  );
};
