import { getAuthSession } from 'auth/session';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom-latest';

export type AuthenticatedRedirectProps = {
  /**
   * The path to redirect authenticated users to.
   * default: '/'
   */
  redirectTo?: string;
  /**
   * Child component(s) will always get rendered
   */
  children: JSX.Element;
};

/**
 * Wrapper component to help better detect an active auth and redirect
 * authenticated users accordingly. This is specifically used on our
 * login pages that use deprecated React lifecycle methods to detect
 * active auth sessions that don't consistenly fire due to race
 * conditions surrounding how an active session is detected.
 */
export const AuthenticatedRedirect = ({
  children,
  redirectTo = '/',
}: AuthenticatedRedirectProps) => {
  const { universalCookieAuthFeature } = useFlags();
  const navigate = useNavigate();

  useEffect(() => {
    const session = getAuthSession();
    if (session?.auth_token) {
      navigate(redirectTo, { replace: true });
    }
  }, [universalCookieAuthFeature, navigate, redirectTo]);

  return children;
};
