import { PersonAddAlt1Outlined } from '@mui/icons-material';
import { Avatar, Box, ButtonBase, Chip, Stack, useTheme } from '@mui/material';

export type AsigneeListContact = Pick<
  LegacyContact,
  | 'avatar'
  | 'initials'
  | 'label'
  | 'type'
  | 'uuid'
  | 'assigne_type'
  | 'value'
  | 'cpa_id'
> & {
  cpa_user_uuid?: string;
  accountId?: string | null;
};

export type AsigneeListProps = {
  addContactClicked: (event: React.MouseEvent<HTMLDivElement>) => void;
  removeContactClicked: (value: AsigneeListContact) => void;
  contacts: AsigneeListContact[];
  disableAddContact?: boolean;
};

export const AsigneeList = ({
  addContactClicked: handleAddContactClicked,
  removeContactClicked: handleRemoveContactClicked,
  contacts,
  disableAddContact = false,
}: AsigneeListProps) => {
  const theme = useTheme();
  return (
    <Stack
      direction="row"
      flexWrap="wrap"
      alignItems="center"
      sx={{ cursor: 'pointer' }}
      pt={3}
      gap="10px"
    >
      {contacts.map((contact) => (
        <Chip
          label={contact?.label}
          avatar={
            <Avatar
              src={contact?.avatar}
              sx={{ height: '24px', width: '24px', fontSize: '12px' }}
            >
              {contact?.initials}
            </Avatar>
          }
          sx={{ borderRadius: '4px' }}
          onDelete={() => handleRemoveContactClicked(contact)}
          key={contact.uuid}
        />
      ))}
      {!disableAddContact && (
        <ButtonBase
          component="div"
          sx={{ cursor: 'pointer', width: 'fit-content' }}
          onClick={handleAddContactClicked}
        >
          <Box
            sx={{
              display: 'flex',
              borderRadius: '50%',
              backgroundColor: theme.palette.secondary.light,
              width: '40px',
              height: '40px',
              alignItems: 'center',
              justifyContent: 'center',
              marginRight: '10px',
            }}
          >
            <Box
              sx={{
                border: `1px dashed ${theme.palette.text.secondary}`,
                borderRadius: '50%',
                width: '25px',
                height: '25px',
              }}
              alignItems="center"
              justifyContent="center"
              display="flex"
            >
              <PersonAddAlt1Outlined
                sx={{
                  color: theme.palette.text.secondary,
                  width: '19px',
                  height: '18px',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              />
            </Box>
          </Box>
          {contacts.length === 0 ? 'select...' : 'add another...'}
        </ButtonBase>
      )}
    </Stack>
  );
};
