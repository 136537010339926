import { styled, tablePaginationClasses } from '@mui/material';
import { DataGrid, gridClasses } from '@mui/x-data-grid';

export const TableHeaderStyled = styled('div')(({ theme }) => ({
  marginTop: '30px',
  minWidth: '800px',
  padding: '0.5rem 1rem 0.5rem 1.5rem',
  backgroundColor: theme.palette.grey[100],
  borderColor: 'rgba(224, 224, 224, 1)',
  borderStyle: 'solid',
  borderWidth: '1px',
  borderBottomWidth: '0px',
  borderTopLeftRadius: '3px',
  borderTopRightRadius: '3px',
  ...theme.typography.h6,
  fontWeight: 700,
}));

export const DataGridStyled = styled(DataGrid)(({ theme }) => ({
  textTransform: 'none',
  fontWeight: 400,
  ...theme.typography.body1,
  letterSpacing: 'inherit',
  color: theme.palette.text.primary,
  p: {
    maxWidth: '15rem',
  },
  minHeight: '150px',
  maxHeight: '300px',
  minWidth: '800px',
  borderRadius: '0px 0px 3px 3px',
  [`& .${gridClasses.virtualScroller}`]: {
    overflowX: 'hidden',
  },
  [`& .${gridClasses.overlayWrapper}`]: {
    height: '300px',
  },
  [`& .${gridClasses.columnHeaders}`]: {
    borderTopLeftRadius: '0px',
    borderTopRightRadius: '0px',
    backgroundColor: theme.palette.grey[50],
  },
  [`& .${gridClasses.columnHeaderTitle}`]: {
    ...theme.typography.body2,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    color: 'rgb(138, 147, 154)',
  },
  [`& .${gridClasses.row}`]: {
    maxHeight: '42px',
    cursor: 'pointer',
    [`> .${gridClasses.cell}:first-child`]: {
      paddingRight: '0px',
    },
  },
  [`& .${gridClasses.footerContainer}`]: {
    backgroundColor: theme.palette.grey[50],
  },
  [`& .${tablePaginationClasses.displayedRows}`]: {
    marginTop: '1rem',
  },
  [`& .${tablePaginationClasses.selectLabel}`]: {
    marginTop: '1rem',
  },
}));

export const Indicator = styled('div')(({ theme }) => ({
  width: '10px',
  height: '10px',
  borderRadius: '10px',
  backgroundColor: theme.palette.info.main,
}));
