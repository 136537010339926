import React from 'react';
import './MaintananceStyle.scss';

const Maintanance = () => (
  <div id="level">
    <div id="ctWrapContent">
      <div id="gears">
        <div id="gears-static" />
        <div id="gear-system-1">
          <div id="gear15" />
          <div id="gear14" />
          <div id="gear13" />
        </div>
        <div id="gear-system-2">
          <div id="gear10" />
          <div id="gear3" />
        </div>
        <div id="gear-system-3">
          <div id="gear9" />
          <div id="gear7" />
        </div>
        <div id="gear-system-4">
          <div id="gear6" />
          <div id="gear4" />
        </div>
        <div id="gear-system-5">
          <div id="gear12" />
          <div id="gear11" />
          <div id="gear8" />
        </div>
        <div id="gear1" />
        <div id="gear-system-6">
          <div id="gear5" />
          <div id="gear2" />
        </div>
        <div id="chain-circle" />
        <div id="chain" />
        <div id="weight" />
      </div>
      <div id="title">
        <img
          src="../images/Liscio_300px.png"
          className="homeLogo"
          alt="Lisico_300px"
          title="Liscio"
        />
        <h1>
          We are working hard to make Liscio run even faster!!
          <br /> Please come back by 4am PT.
        </h1>
        <p>©{new Date().getFullYear()} LISCIO. ALL RIGHTS RESERVED.</p>
      </div>
    </div>
  </div>
);
export default Maintanance;
