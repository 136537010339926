import React from 'react';
import { Route, Routes } from 'react-router-dom-latest';
import {
  KarbonAccountsView,
  KarbonContactsView,
  KarbonLandingView,
} from './views';

export const KarbonIntegrationConfig = () => (
  <Routes>
    <Route path="/" element={<KarbonLandingView />} />
    <Route path="/accounts" element={<KarbonAccountsView />} />
    <Route path="/contacts" element={<KarbonContactsView />} />
  </Routes>
);
