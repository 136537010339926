import React from 'react';
import InputField from 'common/InputField';
import Button from 'common/Button/Button';
import { SelectField, CheckboxField } from 'common/CustomFields';
import { PhoneNumberField } from 'common/PhoneNumberField';
import styled from '@emotion/styled';
import { selectComponentColors } from './ContactUtilities';

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 0.59;
  justify-content: space-between;
  .PhoneExt__InputContainer {
    max-width: 50%;
  }
`;

const MutilpleFieldComponent = (props) => {
  const { data, format, errors, disabled } = props;
  return (
    <div>
      {data.map((each, key) => {
        let value = each.value ? each.value : '';
        const unique = key;
        value = format ? format(each.value) : each.value;
        return (
          <div className="form-group row" key={unique}>
            <SelectField
              label={props.label}
              name={props.name + key}
              options={props.options}
              styles={selectComponentColors()}
              handleChange={(input) => props.onChange(key, 'type', input)}
              value={{ value: each.type, label: each.type }}
              defaultValue={{ value: each.type, label: each.type }}
              clearable={false}
              disabled={disabled}
              className="col-sm-2"
              error={errors && errors[key] ? errors[key].type : undefined}
            />
            {props.name === 'contact_phone' ? (
              <PhoneNumberField
                onChange={(input) => props.onChange(key, 'value', input)}
                onBlur={props.onBlur}
                name={props.name + key}
                label="&ensp;"
                value={value}
                disabled={disabled}
                error={errors && errors[key] ? errors[key].value : undefined}
                className="col-sm-5 "
              />
            ) : (
              <InputField
                onChange={(input) => props.onChange(key, 'value', input)}
                onBlur={props.onBlur}
                name={props.name + key}
                type="text"
                label="&ensp;"
                value={value}
                disabled={disabled}
                error={errors && errors[key] ? errors[key].value : undefined}
                className="col-sm-5 "
              />
            )}
            {each.type === 'Work' && props.name === 'contact_phone' && (
              <StyledDiv className="PhoneExt__Container">
                <InputField
                  onChange={(input) =>
                    props.onChange(key, 'phone_extension', input)
                  }
                  onBlur={props.onBlur}
                  name={props.name + key}
                  type="tel"
                  placeholder="Ext"
                  maxLength="10"
                  label="&ensp;"
                  value={each.phone_extension}
                  disabled={disabled}
                  className="PhoneExt__InputContainer"
                />
                <div className={errors && errors[key] ? ' mt-2 mb-1' : ' mt-3'}>
                  {((data.length > 1 && data.length - 1 !== key) ||
                    each.value) &&
                  !disabled ? (
                    <div>
                      <button
                        type="button"
                        className="btn-link btn--onlyicon btn btn-secondary"
                        onClick={() => props.deleteField(key)}
                        disabled={disabled}
                      >
                        <i className="icon-delete-small" />
                      </button>
                    </div>
                  ) : (
                    <button
                      type="button"
                      className="btn-link btn--onlyicon btn btn-secondary"
                    >
                      <i className="icon" />
                    </button>
                  )}
                </div>
              </StyledDiv>
            )}
            {each.type !== 'Work' && (
              <div
                className={`col-sm-6 col-md-6 col-lg-4 row no-gutters justify-content-between align-items-center${
                  errors && errors[key] ? ' mt-2 mb-1' : ' mt-3'
                }`}
              >
                {each && each.type && each.type.toLowerCase() === 'mobile' ? (
                  <CheckboxField
                    name={`${props.name + key}checkbox`}
                    label="Text Notifications"
                    onChange={(input) =>
                      props.onChange(key, 'is_texting_allowed', input)
                    }
                    value={each.is_texting_allowed}
                  />
                ) : null}
                {((data.length > 1 && data.length - 1 !== key) || each.value) &&
                !disabled ? (
                  <div>
                    <button
                      type="button"
                      className="btn-link btn--onlyicon btn btn-secondary"
                      onClick={() => props.deleteField(key)}
                      disabled={disabled}
                    >
                      <i className="icon-delete-small" />
                    </button>
                  </div>
                ) : (
                  <button
                    type="button"
                    className="btn-link btn--onlyicon btn btn-secondary"
                  >
                    <i className="icon" />
                  </button>
                )}
              </div>
            )}
            {data.length - 1 === key ? (
              <div className="col-12 pt-2">
                <Button
                  variant="ghost"
                  label={props.addButtonLabel ? props.addButtonLabel : 'add'}
                  onClick={() => props.addNewField()}
                  disabled={props.enableAddButton || disabled}
                  size="small"
                />
              </div>
            ) : null}
          </div>
        );
      })}
    </div>
  );
};

export default MutilpleFieldComponent;
