import { useState } from 'react';
import { CircularProgress } from '@mui/material';
import { useEncryptedField } from 'hooks/encrypted-fields-hooks';

export interface EncryptedFieldProps {
  recordType: string;
  recordId: string | number;
  fieldName: string;
  hasEncryptedValue?: boolean;
  label?: string;
  wrapperClassName?: string;
}

const EMPTY_VALUE_LABEL = '-';
const MASK = '********';

export const EncryptedFieldPreview = ({
  recordType,
  recordId,
  fieldName,
  label,
  hasEncryptedValue,
  wrapperClassName = 'col-sm-12 col-md-6 col-lg-4',
}: EncryptedFieldProps) => {
  const [isVisible, setIsVisible] = useState(false);

  const { data, isFetching, remove } = useEncryptedField({
    recordType,
    recordId,
    fieldName,
    enabled: isVisible,
  });

  const decryptedValue = data?.enc_value;
  const iconClass = isVisible ? 'icon-open-eye' : 'icon-close-eye';

  const getValueToDisplay = () => {
    if (!hasEncryptedValue) {
      return EMPTY_VALUE_LABEL;
    }
    if (isVisible && !isFetching) {
      return decryptedValue;
    }
    return MASK;
  };

  return (
    <div className={wrapperClassName}>
      <div className="form-group form-inline-label">
        {label && (
          <label className="align-self-start mr-2">{`${label}: `}</label>
        )}
        <div className="inputGroup inputGroup--value">
          <span className="wordBreak" data-testid={`${fieldName}_value`}>
            {getValueToDisplay()}
          </span>
          {hasEncryptedValue && (
            <span
              className="inputHelpIcon"
              aria-label={`change ${fieldName} visibility`}
              onClick={(e) => {
                e.stopPropagation();
                setIsVisible((currentIsVisible) => {
                  const newIsVisible = !currentIsVisible;
                  if (!newIsVisible) {
                    remove();
                  }
                  return newIsVisible;
                });
              }}
            >
              {!isFetching && <i className={iconClass} aria-hidden="true" />}
              {isFetching && <CircularProgress size={12} />}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default EncryptedFieldPreview;
