/* eslint-disable no-param-reassign */
import axios from 'axios';
import moment from 'moment';
import { AlertMessage } from 'utilities/utils';
import {
  DOCUMENT_API_PATH,
  NOTE_API_PATH,
  ARCHIVE_NOTE,
} from 'constants/constants';

class Notes {
  static addNoteDocument(mydata, component, id, config, filename) {
    let request = null;
    if (id) {
      request = axios.post(
        `${DOCUMENT_API_PATH}?doc_type=Note&note_id=${id}`,
        mydata,
        config,
      );
    } else {
      request = axios.post(
        `${DOCUMENT_API_PATH}?doc_type=Note`,
        mydata,
        config,
      );
    }
    request
      .then((response) => {
        const index = component.state.uploadFiles.indexOf(filename);
        const uploadedFiles = component.state.uploadFiles;
        uploadedFiles.splice(index, 1);
        component.setState({ uploadFiles: uploadedFiles });
        if (response.data.status === 200) {
          const newDocuments = component.state.documents;
          const newDocumnts = component.state.newDocs;
          newDocuments.unshift(response.data.data);
          newDocumnts.unshift(response.data.data);
          component.setState({ documents: newDocuments, newDocs: newDocumnts });
        } else if (response.data.message) {
          AlertMessage('error', response.data.message, 8000);
        } else {
          AlertMessage('error', 'Some error occurred. Please try later.', 3000);
        }
        component.state.uploadedFilesCount += 1;
        if (
          component.state.uploadedFilesCount ===
          component.state.uploadedFilesLength
        ) {
          // COUNT = 0;
          component.setState({ isDocumentUpload: false });
        }
      })
      .catch((error) => {
        component.state.uploadedFilesCount += 1;
        if (
          component.state.uploadedFilesCount ===
          component.state.uploadedFilesLength
        ) {
          component.setState({ isDocumentUpload: false });
          // COUNT = 0;
        }
        const index = component.state.uploadFiles.indexOf(filename);
        const uploadedFiles = component.state.uploadFiles;
        uploadedFiles.splice(index, 1);
        component.setState({ uploadFiles: uploadedFiles });
        if (axios.isCancel(error)) {
          // console.log('Request Cancelled');
        } else {
          AlertMessage('error', 'Some error occurred. Please try later.');
        }
      });
  }

  static deleteAttachment(id, component, noteid) {
    component.setState({ loading: true });
    let url = '';
    url = `${DOCUMENT_API_PATH}/${id}?doc_type=Note&note_id=${noteid}`;
    const request = axios.delete(url);
    request
      .then((response) => {
        if (response.data.status === 200) {
          const documnts = [];
          component.state.documents.map((doc) => {
            if (doc.id === id) {
              doc.is_active = false;
            }
            documnts.push(doc);
            return documnts;
          });
          const indx = component.state.newDocs.findIndex((y) => y.id === id);
          if (indx !== -1) {
            const newDocumnts = component.state.newDocs;
            newDocumnts.splice(indx, 1);
            component.setState({ newDocs: newDocumnts });
          }
          component.setState({
            loading: false,
            documents: documnts,
            previewScreen: false,
          });
          AlertMessage('success', response.data.message, 3000);
        } else {
          component.setState({ loading: true });
          AlertMessage('error', response.data.message, 3000);
        }
      })
      .catch((error) => {
        console.log(error);
        component.setState({ loading: false });
        AlertMessage('error', 'Some error occurred. Please try later.', 3000);
        return null;
      });
  }

  static async createNote(mydata, component, noteid) {
    component.setState({ loading: true });
    try {
      const response = noteid
        ? await axios.put(`${NOTE_API_PATH}/${noteid}`, mydata)
        : await axios.post(NOTE_API_PATH, mydata);
      if (response.status === 200) {
        component.setState({ loading: false, documents: [] });
        // need this for when note is created from email
        component.goBack();
        if (component.state.isEdit) {
          AlertMessage('success', 'Notes Updated Successfully.', 3000);
        } else {
          AlertMessage('success', 'Notes Created Successfully.', 3000);
        }
      }
    } catch (error) {
      component.setState({ loading: false });
      AlertMessage('error', 'Some error occurred. Please try later.', 3000);
    }
  }

  static getNoteDetail(id, component) {
    component.setState({ loading: true });
    const request = axios.get(`${NOTE_API_PATH}/${id}`);
    request
      .then((response) => {
        component.setState({ loading: false, comment: '' });
        if (response.status === 200) {
          const {
            comment,
            account_id,
            contact_id,
            contact,
            documents,
            subject,
            reference_date,
            avatar,
            account,
          } = response.data.data;
          component.setState({
            comment,
            accountId: account_id,
            accountName: account,
            contactId: contact_id,
            contactName: contact,
            documents,
            initialDocuments: documents,
            subject,
            referenceDate: moment(reference_date, 'MM/DD/YYYY'),
          });
          // console.log('response.data.data');
          // console.log(response.data.data);
          if (contact_id) {
            const j = {
              value: contact_id,
              label: contact,
              avatar,
            };
            component.handleContactChange({ value: j, label: contact });
          }
          if (account_id) {
            component.handleAccountChange({
              value: account_id,
              label: account,
            });
          }
        }
      })
      .catch(() => {
        component.setState({ loading: false });
        return null;
      });
  }

  static archiveNote(id, component) {
    component.setState({ loading: true });
    const request = axios.get(`${ARCHIVE_NOTE}/${id}`);
    request
      .then((response) => {
        // console.log('response');
        // console.log(response);
        component.setState({ loading: false });
        if (response.data.status === 200) {
          component.props.navigate(-1);
          AlertMessage('success', response.data.message, 3000);
        } else {
          component.setState({ loading: false });
          AlertMessage('error', response.data.message, 3000);
        }
      })
      .catch((error) => {
        if (
          error.response.data.success === false &&
          error.response.status === 422
        ) {
          component.setState({ loading: false });
          AlertMessage('error', error.response.data.message, 3000);
        } else {
          component.setState({ loading: false });
          AlertMessage('error', 'Some error occurred. Please try later.', 3000);
        }
      });
  }
}
export default Notes;
