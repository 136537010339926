/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/prefer-es6-class */
/* eslint-disable func-names */
import React from 'react';
import DatePicker from 'react-datepicker';

// eslint-disable-next-line no-extend-native
String.prototype.splice = function (idx, rem, str) {
  return this.slice(0, idx) + str + this.slice(idx + Math.abs(rem));
};

const AccountDateField = ({
  className,
  style,
  name,
  onBlur,
  maxDate = {},
  minDate = {},
  popperPlacement,
  error,
  labelClass,
  label,
  value,
  onChange,
}) => {
  const onInputChange = (input) => {
    onChange(input, name);
  };

  const onAllBlur = (e) => {
    if (Number.isNaN(Date.parse(e?.target?.value))) {
      onChange(null, name);
    }

    onBlur(e);
  };

  return (
    <div
      className={className ? className + (error ? ' form-error' : '') : 'col'}
    >
      <label style={style || {}} htmlFor={name} className={labelClass || ''}>
        {label}
      </label>
      <div className="labelValue">
        <DatePicker
          dateFormat="MM/dd/yyyy"
          id={name}
          name={name}
          onSelect={onInputChange}
          onChange={onInputChange}
          selected={value}
          className="pl-2 form-control"
          popperPlacement={popperPlacement || 'bottom-start'}
          onBlur={onAllBlur}
          maxDate={maxDate}
          minDate={minDate}
        />
        <span className="error_label">{error}</span>
      </div>
    </div>
  );
};

export default AccountDateField;
