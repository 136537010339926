/* eslint-disable react/jsx-props-no-spreading */
import {
  Autocomplete,
  FormControl,
  FormLabel,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import usaStates from 'components/Contacts/ContactCreation/usastates.json';
import { useFormContext } from 'react-hook-form';
import { SMSSettingsType } from './FormValidation';

export default function Address() {
  const {
    register,
    trigger,
    formState: { errors },
  } = useFormContext<SMSSettingsType>();

  return (
    <Stack marginTop={4}>
      <Typography fontWeight="bolder" variant="h3">
        Address
      </Typography>
      <Grid container paddingTop={1} spacing={4} rowSpacing={2}>
        <Grid xs={10}>
          <FormControl fullWidth>
            <FormLabel htmlFor="addressLine1">STREET ADDRESS</FormLabel>
            <TextField
              id="addressLine1"
              {...register('addressLine1')}
              error={Boolean(errors.addressLine1)}
              helperText={errors.addressLine1?.message}
              variant="standard"
              type="text"
            />
          </FormControl>
        </Grid>

        <Grid xs={4}>
          <FormControl fullWidth>
            <FormLabel htmlFor="addressCity">CITY</FormLabel>
            <TextField
              id="addressCity"
              {...register('addressCity')}
              error={Boolean(errors.addressCity)}
              helperText={errors.addressCity?.message}
              variant="standard"
              type="text"
            />
          </FormControl>
        </Grid>

        <Grid xs={6}>
          <FormControl fullWidth>
            <FormLabel htmlFor="addressState">STATE/PROVINCE</FormLabel>
            <Autocomplete
              id="addressState"
              options={usaStates}
              onBlur={() => trigger('addressState')}
              renderInput={(params) => (
                <TextField
                  {...register('addressState')}
                  error={Boolean(errors.addressState)}
                  helperText={errors.addressState?.message}
                  variant="standard"
                  {...params}
                />
              )}
            />
          </FormControl>
        </Grid>

        <Grid xs={6}>
          <FormControl fullWidth>
            <FormLabel htmlFor="addressZip">ZIP CODE</FormLabel>
            <TextField
              id="addressZip"
              {...register('addressZip')}
              error={Boolean(errors.addressZip)}
              helperText={errors.addressZip?.message}
              variant="standard"
              type="text"
            />
          </FormControl>
        </Grid>
      </Grid>
    </Stack>
  );
}
