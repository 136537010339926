/* eslint-disable no-debugger */
const initialState = {
  notifications: [],
  notiCount: '',
  notificationFilter: 'Unread',
  notificationSort: 'Descending',
  isMultipleCpa: false,
  multipleCpaData: [],
  isAway: false,
  awayStartDate: '',
  awayEndDate: '',
  updateAwaySetting: false,
};

// eslint-disable-next-line default-param-last
export default function notificationReducer(state = initialState, action) {
  switch (action.type) {
    case 'FETCH_NOTIFICATION': {
      return {
        ...state,
        notiCount: action.data.count,
        notifications: action.data.data,
      };
    }
    case 'NOTIFICATION_COUNT': {
      return { ...state, notiCount: action.data };
    }
    case 'NOTIFICATION_FILTER': {
      return { ...state, notificationFilter: action.data };
    }
    case 'NOTIFICATION_SORT': {
      return { ...state, notificationSort: action.data };
    }
    case 'IS_MULTIPLE_CPA': {
      return { ...state, isMultipleCpa: action.data };
    }
    case 'MULTIPLE_CPA_DATA': {
      return { ...state, multipleCpaData: action.data };
    }
    case 'IS_AWAY': {
      return { ...state, isAway: action.data };
    }
    case 'AWAY_START_DATE': {
      return { ...state, awayStartDate: action.data };
    }
    case 'AWAY_END_DATE': {
      return { ...state, awayEndDate: action.data };
    }
    case 'UPDATE_AWAY_SETTING': {
      return { ...state, updateAwaySetting: action.data };
    }
    case 'MULTIPLE_CPA_DATA_MODIFIED': {
      const newArray = [...state.multipleCpaData];
      newArray[action.index].notification_count = action.count;
      return {
        ...state,
        multipleCpaData: newArray,
      };
    }
    default:
      return state;
  }
}
