import axios from 'axios';
import { ML_API_URI } from 'constants/constants';

// // storage.remove('at_access_token', console.error)
export const requestAutoTagApi = (loginApi) => {
  // const token = await getAccessToken()
  // console.log({ token })
  const config = {
    url: `${ML_API_URI}/${loginApi.url}`,
    method: loginApi.method,
    data: JSON.stringify(loginApi.body),
    headers: { 'Content-Type': 'application/json' },
  };
  // if (request.authRequired) {
  //     config.headers['Authorization'] = `Token ${token}`
  // }
  // console.log(config)
  return axios(config)
    .then((response) => response.data)
    .catch((error) => {
      // console.error(error)
      if (error.response.status === 401) {
        // await removeAutotagAccessToken();
      }
      return { status: error.response.status, error };
    });
};

export const autoTagApi = (api, mydata, authRequired = false) => {
  const config = { headers: { 'Content-Type': 'application/json' } };
  if (
    authRequired &&
    localStorage.getItem('auto_tag_token') !== undefined &&
    localStorage.getItem('auto_tag_token')
  ) {
    const autoTagToken = JSON.parse(localStorage.getItem('auto_tag_token'));
    config.headers.Authorization = `Token ${autoTagToken}`;
  } else if (
    !authRequired &&
    localStorage.getItem('auto_tag_token') !== undefined &&
    localStorage.getItem('auto_tag_token')
  ) {
    const autoTagToken = JSON.parse(localStorage.getItem('auto_tag_token'));
    const res = { token: autoTagToken };
    return res;
  }
  const request = axios.post(`${ML_API_URI}/${api}`, mydata, config);
  return request
    .then((response) => {
      const res = response.data;
      if (!authRequired) {
        localStorage.setItem('auto_tag_token', JSON.stringify(res.token));
        return res;
      }
      return res;
    })
    .catch((error) => {
      if (
        error.response &&
        error.response.status &&
        error.response.status === 401
      ) {
        localStorage.removeItem('auto_tag_token');
      }
      return {
        status:
          error.response && error.response.status ? error.response.status : '',
        error,
      };
    });
};
