/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { styled } from '@stitches/react';
import { violet } from '@radix-ui/colors';
import * as PopoverPrimitive from '@radix-ui/react-popover';

const StyledContent = styled(PopoverPrimitive.Content, {
  borderRadius: 4,
  padding: 0,
  width: 450,
  backgroundColor: 'white',
  boxShadow:
    'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
  '&:focus': {
    boxShadow: `hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px, 0 0 0 2px ${violet.violet7}`,
  },
});

const StyledArrow = styled(PopoverPrimitive.Arrow, { fill: 'white' });

function Content({ children, ...props }) {
  return (
    <PopoverPrimitive.Portal>
      <StyledContent {...props}>
        {children}
        <StyledArrow />
      </StyledContent>
    </PopoverPrimitive.Portal>
  );
}

const StyledClose = styled(PopoverPrimitive.Close, {
  all: 'unset',
  fontFamily: 'inherit',
  borderRadius: '100%',
  height: 25,
  width: 25,
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: violet.violet11,
  position: 'absolute',
  top: 5,
  right: 5,

  '&:hover': { backgroundColor: violet.violet4 },
  '&:focus': { boxShadow: `0 0 0 2px ${violet.violet7}` },
});

// Exports
export const Popover = PopoverPrimitive.Root;
export const PopoverTrigger = PopoverPrimitive.Trigger;
export const PopoverContent = Content;
export const PopoverClose = StyledClose;

const NotificationPopover = ({
  isOpen,
  triggerComponent,
  contentComponent,
  onOpenChange,
}) => (
  <Popover onOpenChange={onOpenChange} open={isOpen}>
    <PopoverTrigger asChild>{triggerComponent}</PopoverTrigger>
    <PopoverContent sideOffset={5} side="right" align="end">
      {contentComponent}
    </PopoverContent>
  </Popover>
);

export default NotificationPopover;
