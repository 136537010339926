import React from 'react';
import { Grid } from '@mui/material';

const InFocusCountComponent = ({ label, inFocusCount, value }) => {
  function navigateTo() {
    document.getElementById(value).scrollIntoView();
  }

  return (
    <Grid
      className="InFocusCountComponent"
      item
      xs="auto"
      sx={{
        py: 0,
        px: 6,
        textAlign: 'center',
        cursor: 'pointer',
      }}
      onClick={navigateTo}
    >
      <h6 className="subHeadline font-weight-bold text-dark">{label}</h6>
      <span className="text-large text-body">{inFocusCount[value]}</span>
    </Grid>
  );
};

export default InFocusCountComponent;
