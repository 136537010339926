import React from 'react';
import styled from '@emotion/styled';
import { ReactComponent as IconMenu } from 'images/icons/email-list-split-view.svg';

const StyledIcons = styled.div`
  &.button-icon {
    cursor: pointer;
    &:hover,
    &:focus {
      background: #fff;
      box-shadow: 0 5px 25px rgb(0 0 0 / 10%);
      color: #212b36;
    }
    svg {
      fill: #919eab;
    }
  }
`;

const EmailListSplitViewButton = ({ handleEmailListMode, title }) => {
  const handleClick = () => handleEmailListMode(title);
  return (
    <StyledIcons
      className="button-icon"
      title={title}
      onClick={handleClick}
      data-testid="fullView"
    >
      <IconMenu width="1rem" />
    </StyledIcons>
  );
};

export default EmailListSplitViewButton;
