import React from 'react';
import styled from '@emotion/styled';
import noRecordsImg from 'images/email-no-records.png';
import Button from './Button/Button';

const StyledDiv = styled.div`
  background: #ffffff;
  min-height: calc(100vh - 35px);
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1.3;
  .Content__Wrapper {
    max-width: 450px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    .Content__Heading {
      margin-bottom: 30px;
      h4 {
        margin-top: 25px;
        margin-bottom: 25px;
      }
      p {
        margin: 5px;
      }
    }
    img {
      width: 335px;
      padding-bottom: 20px;
    }
  }
  h4 {
    font-weight: 600;
  }
  p {
    font-size: 16px;
    font-weight: 400;
  }
  a {
    span {
      display: inline-block;
      padding: 9px 15px;
    }
    text-decoration: underline;
  }
`;

const NoRecordInEmails = ({ label = 'Enable Now' }) => (
  <StyledDiv>
    <div className="Content__Wrapper">
      <img src={noRecordsImg} alt="Embedded Email" />
      <div className="Content__Heading">
        <h4>All your Client Communication in One Place</h4>
        <p>See client emails inside Liscio.</p>
        <p>
          Automatically and manually add client emails to 
          Contacts and Accounts, creating visibility across 
          the firm.
        </p>
        <p>Non-client emails stay private.</p>
      </div>
      <p>
        <Button
          label={label}
          size="small"
          variant="link"
          to="/profile_settings?tab=third_party_apps"
        />
      </p>
      <p>
        <a href="https://www.liscio.me/email-integration" target='_blank' rel="noreferrer">
          Learn more about this feature
        </a>
      </p>
    </div>
  </StyledDiv>
);

export default NoRecordInEmails;
