import React from 'react';
import styled from '@emotion/styled';
import { ReactComponent as IconMenu } from 'images/icons/liscio-logo-small.svg';

const StyledIcons = styled.span``;

const LiscioLogo = () => (
  <StyledIcons className="button-icon">
    <IconMenu width="1rem" />
  </StyledIcons>
);

export default LiscioLogo;
