import React from 'react';
import { Grid, Box } from '@mui/material';

import {
  INFOCUS_CLIENT_TASK,
  INFOCUS_FIRM_TASK,
  INFOCUS_FILES,
  INFOCUS_INVOICES,
} from 'constants/constants';
import { getAvatarName, getDocName } from 'components/Infocus/inFocusUtils';
import InfocusSectionHeader from './InFocusSectionHeader';
import ActivityCard from './ActivityCard';

function InFocusRenderSections({
  inFocusCountTabs,
  inFocusCount,
  redirectUrl,
  infocusClientTask,
  infocusFirmTask,
  infocusFileData,
  infocusInvoice,
  accountName,
  accountID,
  refreshData,
}) {
  // eslint-disable-next-line consistent-return
  function inFocusList(tab) {
    switch (tab) {
      case INFOCUS_CLIENT_TASK:
        return renderListSection(inFocusCount.clientTask, infocusClientTask);
      case INFOCUS_FIRM_TASK:
        return renderListSection(inFocusCount.firmTask, infocusFirmTask);
      case INFOCUS_FILES:
        return renderListSection(inFocusCount.files, infocusFileData);
      case INFOCUS_INVOICES:
        return renderListSection(inFocusCount.invoices, infocusInvoice);
      default:
      // no-op
    }
  }

  function renderListSection(count, data) {
    return (
      count !== 0 &&
      data &&
      data.map((activity) => (
        <ActivityCard
          activityType={activity.type}
          key={activity.id}
          id={activity.id}
          status={activity.status}
          avatarName={getAvatarName(activity.user)}
          avatar={activity.avatar}
          contactName={activity.user}
          user={activity.user}
          description={activity.description}
          subject={activity.subject}
          title={activity.title}
          percentComplete={activity.percent_complete}
          documents={getDocName(activity.documents, true)}
          lastActivityAt={activity.last_activity_at}
          // for task and invoice
          taskType={activity.task_type}
          invoiceNumber={activity.invoice_number}
          invoiceAmount={Number(activity.invoice_amount).toFixed(2)}
          dueBy={activity.due_by}
          uuid={activity.uuid}
          isRecurring
          refreshData={refreshData}
        />
      ))
    );
  }

  return (
    <div className="InFocusRenderSections">
      {inFocusCountTabs.map((listitem) => (
        <Grid
          container
          sx={{ mb: 3, borderBottom: '1px solid', borderColor: 'divider' }}
        >
          <Grid
            lg={9}
            xs={12}
            sx={{ mx: 'auto', pb: 0 }}
            className="center-wrapper"
          >
            <InfocusSectionHeader
              key={listitem.url}
              fileIcon={listitem.icon}
              title={listitem.title}
              value={listitem.value}
              inFocusCount={inFocusCount}
              redirectUrl={redirectUrl}
              accountID={accountID}
              accountName={accountName}
              listitemButton={listitem.button}
              linkto={listitem.linkto}
              listitemUrl={listitem.url}
              filterSelected={listitem.filterSelected}
            />
            {inFocusCount[listitem.value] === 0 ? (
              <Box component="div" sx={{ mb: 2 }}>
                No Records Found
              </Box>
            ) : (
              inFocusList(listitem.value)
            )}
          </Grid>
        </Grid>
      ))}
    </div>
  );
}

export default InFocusRenderSections;
