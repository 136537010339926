import {
  Box,
  Divider,
  Stack,
  Table,
  TableContainer,
  debounce,
} from '@mui/material';
import SearchMessage from 'components/Email/components/SearchMessage';
import { useSMSPhoneLineList } from 'hooks/sms-hooks';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';
import Pagination from 'common/Pagination';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { SMSAdminTableBody } from './components/SMSAdmin/SMSAdminTableBody';
import { SMSAdminHeader } from './components/SMSAdmin/SMSAdminHeader';
import { smsTableColumns } from './utils/generateAdminTableColumns';
import { SMSAdminTableHeader } from './components/SMSAdmin/SMSAdminTableHeader';

export default function SMSAdminPage() {
  const [page, setPage] = useState(1);
  const [searchFieldText, setSearchFieldText] = useState('');
  const [smsPhoneLineList, setSmsPhoneLineList] = useState<SMSLine[]>([]);
  const { data: tableData } = useSMSPhoneLineList(page, searchFieldText);
  const { smsLineMgmtFlag } = useFlags();

  const { getHeaderGroups, getRowModel } = useReactTable({
    data: smsPhoneLineList,
    columns: smsTableColumns,
    getCoreRowModel: getCoreRowModel(),
  });

  useEffect(() => {
    if (tableData && tableData.length > 0) setSmsPhoneLineList(tableData);
  }, [tableData]);

  const handlePageChange = (pageInput = 1) => {
    setPage(pageInput);
  };

  const handleSearchField = useMemo(
    () =>
      debounce((e) => {
        const input = e.target.value;
        setSearchFieldText(input);
        setPage(1);
      }, 500),
    [],
  );

  return (
    <Stack
      aria-label="SMS Admin Page"
      overflow="hidden"
      height="calc(100% - 32px)"
    >
      <SMSAdminHeader />
      <Divider orientation="horizontal" />
      <Stack gap={4} padding={4} maxHeight="calc(100% - 42px)">
        {smsLineMgmtFlag && (
          <Box maxWidth={25}>
            <SearchMessage
              searchInput={searchFieldText}
              onChange={handleSearchField}
            />
          </Box>
        )}
        <TableContainer component={Box}>
          <Table>
            <SMSAdminTableHeader getHeaderGroups={getHeaderGroups} />
            <SMSAdminTableBody getRowModel={getRowModel} />
          </Table>
        </TableContainer>
        {smsLineMgmtFlag && (
          <Box>
            <Pagination
              currentPage={page}
              totalPages={100}
              totalRecords={1000}
              handlePageChange={handlePageChange}
              fromPage="emailsList"
            />
          </Box>
        )}
      </Stack>
    </Stack>
  );
}
