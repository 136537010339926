import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from '@mui/material';
import React from 'react';
import { Button } from 'reactstrap';

type LostAccessDialogProps = {
  open: boolean;
  handleClose: () => void;
};

export default function LostAccessDialog({
  open,
  handleClose,
}: LostAccessDialogProps) {
  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>Login Help</DialogTitle>
      <Divider />
      <DialogContent>
        <Typography>
          If you’ve lost access to your account, please{' '}
          <a href="https://liscio.me/contact/" target="_blank" rel="noreferrer">
            contact us
          </a>
          .
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Dismiss
        </Button>
      </DialogActions>
    </Dialog>
  );
}
