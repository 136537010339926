export const taskPagination = (payload) => ({
  type: 'SET_TASK_PAGINATION',
  payload,
});

export const messagePagination = (payload) => ({
  type: 'SET_MESSAGE_PAGINATION',
  payload,
});

export const accountMsgPagination = (payload) => ({
  type: 'ACCOUNT_MESSAGE_PAGINATION',
  payload,
});

export const notificationPagination = (payload) => ({
  type: 'SET_NOTIFICATION_PAGINATION',
  payload,
});
