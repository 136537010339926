/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import DisplayEmailAttachments from './DisplayEmailAttachments';

const EmailAttachments = ({ documents, buttons, handleCheckbox, data }) => (
  <div className="DocsInfo">
    <div className="DragWrap">
      <div className="DragWrap__Inner">
        <label htmlFor="attachments">Attachments:</label>
        <div>
          <span className="SelectedFile">
            Selected ({documents.filter((e) => e.isChecked === true).length})
          </span>
          {buttons('forlist')}
        </div>
      </div>
      <div className="FileList">
        {documents
          .filter(
            (e) =>
              e.content_disposition !== null &&
              !e.content_disposition.includes('inline'),
          )
          .map((e) => (
            <DisplayEmailAttachments
              btnCstClass="w-100 m-0"
              attachment={e}
              buttons={buttons}
              handleCheckbox={handleCheckbox}
              emailId={data.email_id}
            />
          ))}
      </div>
    </div>
  </div>
);

export default EmailAttachments;
