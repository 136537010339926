import React, { useEffect } from 'react';
import { printPdf } from 'utilities/utils';
import logo from 'images/liscioLogo.png';
import moment from 'moment';
import Interweave from 'interweave';
import { htmlContent, styleElement } from '../EmailUtilities';

const EmailPdfLayout = ({ data, handleEmailActions }) => {
  const emailData = data.map((e) => e);
  const emailThreadId = emailData[0].thread_id;

  useEffect(() => {
    printPdf(
      `email_to_print${emailThreadId}`,
      `email_iframe${emailThreadId}`,
      styleElement,
    );
    setTimeout(() => handleEmailActions('print'), 1000);
  }, [emailThreadId]);

  const handleContent = (item) => {
    const htmlCon = htmlContent(item);
    if (htmlCon) {
      return (
        <div>
          <p className="messageDesc pt-2">
            <Interweave allowAttributes content={htmlCon} />
          </p>
        </div>
      );
    }
    return null;
  };

  return (
    <div
      style={{ height: 1, overflow: 'auto', display: 'none' }}
      data-testid="printLayout"
    >
      <iframe
        title="print terms condition"
        id={`email_iframe${emailThreadId}`}
        style={{
          height: '200px',
          width: '100%',
        }}
      />
      <div className="pdf-html" id={`email_to_print${emailThreadId}`}>
        <div id="pdf-logo">
          <img src={logo} alt="liscio logo" />
        </div>
        <br />
        <div className="bottomBorder col pt-2" />
        <br />
        <div>
          <h5>
            <span className="text-dark1">Email Subject</span>
            {`    ${emailData[0].subject}`}
          </h5>
        </div>
        {emailData.map((item) => (
          <div key={item.id}>
            <div
              className="each_thread print-comment-sections"
              key={emailThreadId}
            >
              <div>
                <div style={{ padding: '0 0 10px' }}>
                  <span>
                    <span
                      className="messageRecipient"
                      style={{ fontWeight: 600 }}
                    >{`    ${item.from_email}`}</span>
                  </span>
                  <br />
                  <br />
                  <span>
                    <span
                      className="messageRecipient"
                      style={{ fontWeight: 600 }}
                    >
                      To:
                    </span>
                    {`    ${item.to_email}`}
                  </span>
                  <br />
                  <br />
                  <span
                    className="messageDate text-right"
                    style={{ fontSize: '14px' }}
                  >
                    <span className="text-dark">
                      {item.email_date
                        ? moment(item.email_date).format('MM/DD/YYYY, h:mm a')
                        : ''}
                    </span>
                  </span>
                </div>
              </div>
              {item.content && item.content !== null && handleContent(item)}
            </div>
            <div>
              {item.documents && item.documents.length ? (
                <div>
                  <p style={{ fontWeight: 600 }}>Attachments:</p>
                  {item.documents.map((each) => (
                    <div key={each.id} style={{ marginTop: '8px' }}>
                      <p>
                        <span>{each.file_name}</span>
                      </p>
                    </div>
                  ))}
                </div>
              ) : null}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default EmailPdfLayout;
