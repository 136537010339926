import { Typography } from '@mui/material';
import { useRef } from 'react';
import { TooltipStyled } from 'ui/TooltipStyled/TooltipStyled';

type TextCuttoffProps = {
  text: string[];
};

export const TextCuttoff = ({ text }: TextCuttoffProps) => {
  const spanRef = useRef<HTMLSpanElement>(null);

  const list = (
    <>
      {text.map((t) => (
        <div key={t}>{t}</div>
      ))}
    </>
  );

  return (
    <TooltipStyled title={list}>
      <span>
        <Typography component="span" ref={spanRef}>
          {text.length > 0 && text[0]}
          {text.length > 1 && ','}
        </Typography>
        <Typography
          component="span"
          ref={spanRef}
          fontWeight="bold"
          color="primary"
        >
          {text.length > 1 && `+${text.length - 1}`}
        </Typography>
      </span>
    </TooltipStyled>
  );
};
