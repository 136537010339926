/* eslint-disable no-use-before-define */
import React from 'react';
import PropTypes from 'prop-types';
import { RECURRING_TAB } from 'constants/constants';
import {
  oneTimeInvoiceColumns,
  recurringInvoiceColumns,
} from 'components/Billing/billingUtils';
import TableColumns from './TableColumns';

const TableHeader = ({
  currentTab,
  handleColumnSorting,
  sortOrder,
  sortBy,
}) => {
  function renderTableHeader() {
    switch (currentTab) {
      case RECURRING_TAB:
        return recurringTableHeader();
      default:
        return invoicesTableHeader();
    }
  }

  function invoicesTableHeader() {
    return (
      <thead>
        <tr>
          {oneTimeInvoiceColumns.map((col) => (
            <TableColumns
              key={col.id}
              colName={col.name}
              align={col.align}
              sortOrder={sortOrder}
              sortBy={sortBy}
              colId={col.id}
              handleColumnSorting={handleColumnSorting}
              width={col.width}
            />
          ))}
        </tr>
      </thead>
    );
  }

  function recurringTableHeader() {
    return (
      <thead>
        <tr>
          {recurringInvoiceColumns.map((col) => (
            <TableColumns
              key={col.id}
              colName={col.name}
              align={col.align}
              sortOrder={sortOrder}
              sortBy={sortBy}
              colId={col.id}
              handleColumnSorting={handleColumnSorting}
            />
          ))}
        </tr>
      </thead>
    );
  }

  return renderTableHeader();
};

export default TableHeader;

TableHeader.propTypes = {
  allInvoices: PropTypes.arrayOf(PropTypes.shape({})),
  handleColumnSorting: PropTypes.func,
  sortOrder: PropTypes.string,
  sortBy: PropTypes.string,
  isInvoiceHistoryTab: PropTypes.bool,
};
