import React from 'react';
import ArchiveBulkAction from 'components/Email/components/EmailBulkActions/ArchiveBulkAction';
import ReplyRowAction from 'components/Email/components/EmailRowActions/ReplyRowAction';
import { makeDoneActionFilterFunction } from 'components/Email/EmailUtilities';
import CreateTaskThreadBtn from './CreateTaskButton';
import MarkUnReadBulkAction from '../EmailBulkActions/MarkUnReadBulkAction';
import NewMessageButton from './NewMessageButton';
import PrintEmailButton from './PrintEmailButton';
import EmailDetailFullViewButton from './EmailDetailFullViewButton';
import useWhichSection from '../useWhichSection';

const EmailToolBar = ({ menuItems = [], handleEmailActions, email }) => {
  const { inLinkedSection } = useWhichSection();
  const filteredActionNameList = menuItems.filter(
    makeDoneActionFilterFunction(email, inLinkedSection),
  );

  return (
    <div className="email-tool-bar" data-testid="emailToolBarActions">
      {filteredActionNameList.map((obj) => {
        const actionObj = obj;
        const actionName = obj.value;
        switch (actionName) {
          case 'done':
            return (
              <ArchiveBulkAction
                key={obj.label}
                handleEmailActions={handleEmailActions}
                actionObj={actionObj}
                actionFromValue="true"
              />
            );
          case 'reply':
            return (
              <ReplyRowAction
                key={obj.label}
                handleEmailActions={handleEmailActions}
                actionObj={actionObj}
                actionFromValue="true"
              />
            );
          case 'reply_with_liscio_message':
            return (
              <NewMessageButton
                key={obj.label}
                handleEmailActions={handleEmailActions}
              />
            );
          case 'create_task':
            return (
              <CreateTaskThreadBtn
                actionObj={actionObj}
                key={obj.label}
                handleEmailActions={handleEmailActions}
              />
            );
          case 'mark_as_unread':
            return (
              <MarkUnReadBulkAction
                actionObj={actionObj}
                key={obj.label}
                handleEmailActions={handleEmailActions}
                actionFromValue="true"
              />
            );
          case 'print':
            return (
              <PrintEmailButton
                actionObj={actionObj}
                key={obj.label}
                handleEmailActions={handleEmailActions}
              />
            );
          case 'full_view':
            return (
              <EmailDetailFullViewButton
                key={obj.label}
                handleEmailActions={handleEmailActions}
                actionObj={actionObj}
              />
            );
          default:
            return null;
        }
      })}
    </div>
  );
};
export default EmailToolBar;
