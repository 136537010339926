import React from 'react';
import Select from 'react-select';

const ItemField = ({
  allowUserToType,
  handleItemInputChange,
  item,
  selectStyles,
  items,
  handleItemChange,
  invalidItem,
  isQBMode,
}) => (
  <div className="InvoiceForm__Field">
    {allowUserToType ? (
      <input
        type="text"
        name="item"
        className="InvoiceForm__ItemInput"
        onChange={handleItemInputChange}
        value={item}
      />
    ) : (
      <Select
        name="item"
        id="item"
        placeholder="Select Item"
        className="Select"
        styles={selectStyles}
        options={items}
        onChange={handleItemChange}
        value={item}
        isSearchable
        isClearable
        invalidValue={invalidItem}
        isDisabled={isQBMode}
      />
    )}
    {invalidItem && (
      <div className="InvoiceForm__Error">This is a required field</div>
    )}
  </div>
);

export default ItemField;
