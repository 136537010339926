import React from 'react';
import InfoIcon from '@mui/icons-material/Info';
import { styled, Stack } from '@mui/material';
import { TooltipStyled } from 'ui/TooltipStyled/TooltipStyled';
import { SUPPORT_URL } from './PrivacyPolicyErrorMessage';

const Icon = styled(InfoIcon)(({ theme }) => ({
  width: '.6em',
  height: '.6em',
  color: theme.palette.common.midGray,
}));

export const PrivacyPolicyInfo = () => (
  <TooltipStyled
    sx={{ ml: 1 }}
    title={
      <Stack gap={2}>
        <span>
          To enable this feature, each firm must go through a compliance review.
          Per 5.2.1 of the CTIA Guidelines, message senders are to maintain and
          conspicuously display a clear, easy-to-understand Privacy Policy that
          is easily accessed by the consumer.
        </span>
        <strong>
          Please provide a link to the privacy policy on your website.
        </strong>
        <span>
          For further guidance on this, please view our help article{' '}
          <a href={SUPPORT_URL} target="_blank" rel="noreferrer">
            SMS Privacy Policy Compliance.
          </a>
        </span>
      </Stack>
    }
  >
    <Icon />
  </TooltipStyled>
);

export default PrivacyPolicyInfo;
