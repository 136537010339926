/* eslint-disable react-hooks/exhaustive-deps */
import { Autocomplete, Box, debounce, TextField } from '@mui/material';
import { GridRenderCellParams, GridTreeNodeWithRender } from '@mui/x-data-grid';
import { TaskAccountType } from 'api/accounts';
import { useUpdateDraftWorkflowRequest } from 'components/Requests/hooks/useUpdateDraftWorkflowRequest';
import { WorkflowRequestsWorkflowRequestFragment } from 'gql/graphql';
import { useAccountSearchByKeyword } from 'hooks/accounts-hooks';
import { useEffect, useMemo, useState } from 'react';
import { TooltipStyled } from 'ui/TooltipStyled/TooltipStyled';

type AccountCellProps = {
  params: GridRenderCellParams<
    WorkflowRequestsWorkflowRequestFragment,
    any,
    any,
    GridTreeNodeWithRender
  >;
};

export const AccountCell = ({ params }: AccountCellProps) => {
  const {
    assignedAccount,
    assignedContacts,
    id: workflowRequestId,
  } = params.row;
  const [searchKeyword, setSearchKeyword] = useState('');
  const [selectedAccount, setSelectedAccount] =
    useState<TaskAccountType | null>(null);
  const { data: accountList } = useAccountSearchByKeyword(searchKeyword);
  const updateWorkflowRequest = useUpdateDraftWorkflowRequest();

  useEffect(() => {
    if (assignedAccount) {
      handleSearchField(assignedAccount.name);
      setSelectedAccount({
        label: assignedAccount.name,
        uuid: (assignedAccount.id || 0) as number,
      });
    }
  }, [assignedAccount]);

  const handleSearchField = useMemo(
    () =>
      debounce((searchText: string) => {
        setSearchKeyword(searchText);
      }, 500),
    [],
  );

  const handleOptionSelected = (newOption: TaskAccountType | null) => {
    setSelectedAccount(newOption);

    const updatedRow = {
      ...params.row,
      assignedAccount: {
        id: newOption?.uuid,
        legacyId: (newOption?.value || '') as string,
        name: newOption?.label,
      },
    };

    params.api.updateRows([updatedRow]);

    updateWorkflowRequest(workflowRequestId, {
      assignedAccountId: newOption?.uuid,
    });
  };

  const toolTipText =
    assignedContacts && assignedContacts?.length > 0
      ? 'The "Assigned To" field must be blank in order to change the "Account"'
      : '';

  return (
    <Box
      display="flex"
      gap="5px"
      flexDirection="column"
      alignItems="start"
      width="100%"
      onClick={(event) => event.stopPropagation()}
    >
      <TooltipStyled title={toolTipText}>
        <Autocomplete
          key={`account-${workflowRequestId}`}
          sx={{ width: '100%' }}
          disabled={toolTipText.length > 0}
          disableClearable={accountList && accountList?.length > 0}
          options={accountList || []}
          getOptionLabel={(option) => option.label}
          isOptionEqualToValue={(option, value) => option.label === value.label}
          renderInput={(inputParams) => (
            <TextField
              {...inputParams}
              variant="standard"
              fullWidth
              onChange={(e) => handleSearchField(e.target.value)}
            />
          )}
          onChange={(_e, newOption) => handleOptionSelected(newOption)}
          filterOptions={(x) => x}
          value={selectedAccount}
          blurOnSelect
        />
      </TooltipStyled>
    </Box>
  );
};
