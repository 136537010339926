/* eslint-disable global-require */
import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import axios from 'axios';
import moment from 'moment-timezone';
import {
  KARBON_INTEGRATION_STATUS,
  KARBON_CONNECTION,
  KARBON_IMPORT_DATA,
  DISCONNECT_KARBON,
  ABANDON_KARBON,
} from 'constants/constants';
import ConfirmationModal from 'common/ConfirmationModal';
import { useWebsocket } from 'websocket';
import { getAuthSession } from 'auth/session';
import { getSiteHostname } from 'utilities/utils';

let tokenInterval = null;
// eslint-disable-next-line no-unused-vars
let karbonSubscription;
const initialData = {
  apiKey: '',
  loading: true,
  karbonData: {},
  apiKeyModal: false,
  apiKeySaveModal: false,
  errors: {},
};

const Karbon = (props) => {
  const [data, setData] = useState(initialData);

  const websocket = useWebsocket();

  const karbonIntegrationStatus = async () => {
    try {
      const response = await axios
        .get(KARBON_INTEGRATION_STATUS)
        .then((res) => res.data);
      setData({ ...data, loading: false, karbonData: response });
    } catch (err) {
      setData({ ...data, loading: false });
      console.log(err);
    }
  };

  useEffect(() => {
    if (data.loading) {
      karbonIntegrationStatus();
    }
    if (getAuthSession()) {
      tokenInterval = setInterval(() => {
        // TODO: __HOMEDATA-AUDIT
        const local_data = JSON.parse(
          localStorage.getItem(`${getSiteHostname().split(':')[0]} data`),
        );
        if (local_data) {
          clearInterval(tokenInterval);
          karbonSubscription = websocket.consumer.subscriptions.create(
            {
              channel: 'KarbonAutoSyncProgressChannel',
              cpa_id: local_data.cpa_id,
            },
            {
              connected: () => {
                console.log('connected to karbon integration');
              },
              received: (dataReceived) => {
                console.log('Data received karbon - ', dataReceived);
                if (dataReceived) {
                  karbonIntegrationStatus();
                }
              },
              disconnected: () => {
                console.log('disconnected from karbon integration channel');
              },
            },
          );
        }
      }, 200);
    }
  }, []);

  const handleValidation = () => {
    const err = {};
    let isValid = true;
    if (!data.apiKey) {
      isValid = false;
      err.apiKey = 'Enter karbon key';
    }
    setData({ ...data, errors: err });
    return isValid;
  };

  const handleChange = (e, connect = '') => {
    if (e === 'apiKeyModal') {
      if (connect) {
        try {
          let request = null;
          if (connect === 'apiKeySaved') {
            const validate = handleValidation();
            if (validate) {
              setData({ ...data, loading: true });
              const karbondata = {
                karbon_key: data.apiKey,
              };
              request = axios.post(KARBON_CONNECTION, karbondata);
            }
          } else {
            setData({ ...data, loading: true });
            request = axios.post(KARBON_CONNECTION);
          }
          request.then((response) => {
            if (
              response.data.status === 200 &&
              response.data.message.statusCode === 401
            ) {
              const err = {};
              err.apiKey = response.data.message.message;
              setData({ ...data, errors: err, loading: false });
            } else if (
              response.data.status === 200 &&
              response.data.message &&
              typeof response.data.message === 'string' &&
              !response.data.data
            ) {
              const err = {};
              err.apiKey = response.data.message;
              setData({ ...data, errors: err, loading: false });
            } else if (
              response.data.status === 200 &&
              response.data.message.statusCode !== 401
            ) {
              setData({
                ...data,
                karbonData: response.data,
                loading: false,
                apiKeyModal: connect ? !data.apiKeyModal : data.apiKeyModal,
                apiKey: connect === 'connect' ? '' : data.apiKey,
                errors: connect === 'connect' ? {} : data.errors,
              });
            }
          });
        } catch (err) {
          console.log(err);
        }
      } else {
        setData({
          ...data,
          apiKeyModal: !data.apiKeyModal,
          apiKey: '',
          errors: {},
        });
      }
    } else {
      setData({ ...data, [e.target.name]: e.target.value, errors: {} });
    }
  };

  const handleAutoSync = () => {
    setData({ ...data, loading: true });
    try {
      const request = axios.post(KARBON_IMPORT_DATA);
      request.then((response) => {
        if (
          response.data.status === 200 &&
          response.data.message === 'Import in progress,'
        ) {
          karbonIntegrationStatus();
        }
      });
    } catch (err) {
      setData({ ...data, loading: false });
      // console.log(err);
    }
  };

  const handleApiKey = (value = '') => {
    try {
      const url = value === 'abandon' ? ABANDON_KARBON : DISCONNECT_KARBON;
      axios.delete(url).then((res) => res.data);
      setData({
        ...data,
        loading: false,
        apiKey: false,
        apiKeySaveModal: false,
        karbonData: { message: 'You are not connected to karbon' },
      });
      // karbonIntegrationStatus();
    } catch (err) {
      setData({ ...data, loading: false });
      console.log(err);
    }
  };

  const handleManualSync = () => {
    props.syncData('liscio_karbon_accounts');
  };

  const karbonIntData = data.karbonData;
  const karbonIntegrationData = karbonIntData.data;
  return (
    <div className="col-lg-4 col-sm-12 col-md-6">
      {data.loading ? <div id="loading" /> : null}
      <div
        className="card mb-4 shadow-s"
        style={{ height: 'calc(100% - 16px)' }}
      >
        <div
          className="card-img-top d-flex justify-content-center"
          style={{ height: '150px', alignItems: 'center' }}
        >
          <img
            src={require('images/karbon.png')}
            alt="karbon_icon"
            style={{ width: '100%' }}
          />
        </div>
        <div className="card-body">
          <h5 className="card-title">{props.integrationAppsPref.label}</h5>
          <div className="h-100">
            {karbonIntData.message === 'You are not connected to karbon' &&
            !karbonIntegrationData ? (
              <div className="d-flex h-100 flex-column justify-content-end">
                <button
                  type="button"
                  className="btn btn-primary mb-5"
                  onClick={() => handleChange('apiKeyModal', 'connect')}
                >
                  Connect
                </button>
              </div>
            ) : null}
            {karbonIntegrationData &&
            karbonIntegrationData.status === 'New' &&
            karbonIntData.logged_in_user_name &&
            karbonIntData.logged_in_user_name ===
              karbonIntegrationData.created_by_name ? (
              <div className="d-flex h-100 flex-column justify-content-end">
                <button
                  type="button"
                  className="btn btn-primary mb-3"
                  onClick={() => handleChange('apiKeyModal', '')}
                >
                  Continue Setup
                </button>
                <button
                  type="button"
                  className="btn btn-primary mb-5"
                  onClick={() => setData({ ...data, apiKeySaveModal: true })}
                >
                  Abandon Setup
                </button>
              </div>
            ) : null}
            {karbonIntegrationData &&
            karbonIntegrationData.status === 'New' &&
            karbonIntData.logged_in_user_name &&
            karbonIntData.logged_in_user_name !==
              karbonIntegrationData.created_by_name ? (
              <div className="d-flex h-100 flex-column justify-content-end">
                <button type="button" className="btn btn-primary mt-3" disabled>
                  Setup in Progress
                </button>
                {karbonIntegrationData.created_by_name && (
                  <p className="text-info mt-2">
                    Initiated by {karbonIntegrationData.created_by_name}
                  </p>
                )}
              </div>
            ) : null}
            {karbonIntegrationData &&
            karbonIntegrationData.status === 'Active' ? (
              <div className="d-flex h-100 flex-column justify-content-end">
                <button
                  type="button"
                  className="btn btn-primary mb-3 pr-1 pl-1"
                  disabled={
                    karbonIntegrationData.is_acct_auto_sync_in_progress ||
                    karbonIntegrationData.is_contact_auto_sync_in_progress
                  }
                  onClick={handleAutoSync}
                >
                  Sync Now{' '}
                  {karbonIntegrationData.is_acct_auto_sync_in_progress
                    ? `(started at: ${
                        karbonIntegrationData.acct_auto_sync_started_at
                          ? moment(
                              karbonIntegrationData.acct_auto_sync_started_at,
                            ).format('MM/DD/YY h:mm A')
                          : ''
                      })`
                    : null}
                </button>
                <button
                  type="button"
                  className="btn btn-primary mb-3"
                  onClick={handleManualSync}
                  disabled={
                    karbonIntegrationData.is_acct_auto_sync_in_progress ===
                      true ||
                    (karbonIntegrationData.is_acct_auto_sync_in_progress ===
                      false &&
                      !karbonIntData.liscio_karbon_accounts)
                  }
                >
                  View Details
                </button>
                <button
                  type="button"
                  className="btn btn-primary mb-5"
                  onClick={() => setData({ ...data, apiKeySaveModal: true })}
                  disabled={karbonIntegrationData.is_acct_auto_sync_in_progress}
                >
                  Disconnect
                </button>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      {data.apiKeyModal ? (
        <Modal
          isOpen
          className="customModal customModal--center customModal--alert customModal--w500 customModal--nopadding"
        >
          <ModalHeader className="ModalHeader">
            <button
              name="apiKeyModal"
              type="button"
              className="close"
              aria-label="Close"
              onClick={() => handleChange('apiKeyModal', '')}
            >
              <i className="icon-close2" aria-hidden="true" />
            </button>
          </ModalHeader>
          <ModalBody className="modalContent">
            <div className="modalContent--inner">
              <div className="frmMxWidth">
                <div className="row">
                  <div className="col-12">
                    <div className="d-block">
                      <h3 className="fontSize text-center">
                        Please copy your API Key from KarbonHQ
                      </h3>
                      <div
                        className={`form-group${
                          data.errors.apiKey ? ' form-error' : ''
                        }`}
                      >
                        <div className="labelValue">
                          <input
                            type="password"
                            name="apiKey"
                            id="apiKey"
                            className="form-control"
                            onChange={(e) => handleChange(e, '')}
                            autoComplete="off"
                            value={data.apiKey}
                          />
                        </div>
                        {data.errors.apiKey ? (
                          <div className="labelValue">
                            <span className="error_label">
                              {data.errors.apiKey}
                            </span>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 justify-content-end formbtn justify-content-end mt-0">
                    <div className="btn-wrap d-flex btn--leftspace">
                      <button
                        type="button"
                        className="btn btn-sm w-50 btn-primary"
                        onClick={() =>
                          handleChange('apiKeyModal', 'apiKeySaved')
                        }
                      >
                        Save
                      </button>
                      <button
                        type="button"
                        className="btn btn-sm w-50 btn-outline-light"
                        onClick={() => handleChange('apiKeyModal', '')}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      ) : null}
      <ConfirmationModal
        isOpen={data.apiKeySaveModal}
        isHtml
        messageText={
          "<div><h5>Remove access?</h5><p>Liscio will no longer have access to your Karbon Account. You'll need to grant access if you want to use this app or service again.</p></div>"
        }
        noBtnClick={() => setData({ ...data, apiKeySaveModal: false })}
        yesBtnClick={() =>
          karbonIntegrationData &&
          karbonIntegrationData.status === 'New' &&
          karbonIntData.logged_in_user_name &&
          karbonIntData.logged_in_user_name ===
            karbonIntegrationData.created_by_name
            ? handleApiKey('abandon')
            : handleApiKey('disconnect')
        }
        yesBtnText="Ok"
        noBtnText="Cancel"
        btnClassName="justify-content-start"
      />
    </div>
  );
};

export default Karbon;
