import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  TextField,
  Typography,
  Stack,
} from '@mui/material';
import { PaymentEstimateObject } from 'api/tax-delivery';
import {
  useCreatePaymentEstimates,
  useUpdatePaymentEstimates,
} from 'hooks/payment-hooks';
import { useEffect } from 'react';
import {
  Controller,
  FieldValues,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { NumberFieldStyled } from 'ui/Form/NumberFieldStyled';
import usaStates from 'components/Contacts/ContactCreation/usastates.json';
import Grid from '@mui/material/Unstable_Grid2';
import { FormLabelStyled } from 'ui/Form/FormLabelStyled';
import { DateFieldStyled } from 'ui/Form/DateFieldStyled';
import { parseISO } from 'date-fns';

interface Props {
  accountId: string;
  estimate?: PaymentEstimateObject;
  onSuccess: () => void;
  onCancel: () => void;
}

export default function TaxDetailsDialog({
  accountId,
  estimate,
  onSuccess,
  onCancel,
}: Props) {
  const { mutate: createPaymentMutation } = useCreatePaymentEstimates();
  const { mutate: updatePaymentMutation } = useUpdatePaymentEstimates();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    trigger,
    setError,
    control,
  } = useForm<PaymentEstimateObject>();
  const handleOnSubmit: SubmitHandler<FieldValues> = (
    formData: PaymentEstimateObject,
  ) => {
    if (estimate?.uuid) {
      updatePaymentMutation(
        {
          account_id: accountId,
          uuid: estimate.uuid,
          ...formData,
        },
        {
          onSuccess: () => {
            onSuccess();
          },
        },
      );
      return;
    }
    createPaymentMutation(
      {
        account_id: accountId,
        ...formData,
      },
      {
        onSuccess: () => {
          onSuccess();
        },
        onError: (error: any) => {
          const errData = error.response.data;
          Object.keys(errData).forEach((key: any) => {
            setError(key, {
              type: 'custom',
              message: 'Please check your input.',
            });
          });
        },
      },
    );
  };

  const handleReset = () => {
    reset(estimate);
  };

  useEffect(() => {
    reset(estimate);
  }, [reset, estimate]);

  const currentYear = new Date().getFullYear();
  return (
    <form onReset={handleReset} onSubmit={handleSubmit(handleOnSubmit)}>
      <Stack gap={2} padding={2}>
        <Typography variant="h3">
          Add/Edit Tax Jurisdiction Estimated Tax Payments
        </Typography>
        <Stack>
          <Typography>
            This Account already contains Estimated Tax Payments for the
            following jurisdictions:
          </Typography>
          <Typography>
            (and will therefore <u>not</u> appear in the jurisdiction dropdown,
            below)
          </Typography>
          <Typography fontWeight={700}>
            <li>Federal</li>
          </Typography>
        </Stack>
        <Grid container paddingTop={1} spacing={4} rowSpacing={2} width="742px">
          <Grid xs={3}>
            <FormControl fullWidth>
              <FormLabelStyled htmlFor="jurisdiction">
                JURISDICTION
              </FormLabelStyled>
              <Autocomplete
                id="jurisdiction"
                options={usaStates}
                defaultValue={{
                  label: estimate?.jurisdiction || '',
                  value: estimate?.jurisdiction || '',
                }}
                onBlur={() => trigger('jurisdiction')}
                renderInput={(params) => (
                  <TextField
                    {...register('jurisdiction')}
                    error={Boolean(errors.jurisdiction)}
                    helperText={errors.jurisdiction?.message}
                    variant="standard"
                    {...params}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid xs={3}>
            <FormControl fullWidth>
              <FormLabelStyled htmlFor="tax_year">Tax Year</FormLabelStyled>
              <Autocomplete
                id="tax-year"
                options={[...Array(50).keys()].map((i) => currentYear - i)}
                defaultValue={estimate?.tax_year || currentYear}
                onBlur={() => trigger('tax_year')}
                getOptionLabel={(option) => option.toString()}
                renderInput={(params) => (
                  <TextField
                    {...register('tax_year')}
                    error={Boolean(errors.tax_year)}
                    helperText={errors.tax_year?.message}
                    variant="standard"
                    {...params}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid xs={6} />
          <Grid xs={3}>
            <Controller
              control={control}
              name="q1_due_date"
              render={({ field, fieldState }) => (
                <DateFieldStyled
                  error={Boolean(fieldState.error)}
                  onChange={field.onChange}
                  value={field.value ? new Date(field.value) : null}
                  InputBaseProps={{}}
                  label="1ST QUARTER DUE DATE"
                />
              )}
            />
          </Grid>
          <Grid xs={3}>
            <Controller
              control={control}
              name="q2_due_date"
              render={({ field, fieldState }) => (
                <DateFieldStyled
                  error={Boolean(fieldState.error)}
                  onChange={field.onChange}
                  value={field.value ? parseISO(field.value) : null}
                  InputBaseProps={{}}
                  label="2ND QUARTER DUE DATE"
                />
              )}
            />
          </Grid>
          <Grid xs={3}>
            <Controller
              control={control}
              name="q3_due_date"
              render={({ field, fieldState }) => (
                <DateFieldStyled
                  error={Boolean(fieldState.error)}
                  onChange={field.onChange}
                  value={field.value ? parseISO(field.value) : null}
                  InputBaseProps={{}}
                  label="3RD QUARTER DUE DATE"
                />
              )}
            />
          </Grid>
          <Grid xs={3}>
            <Controller
              control={control}
              name="q4_due_date"
              render={({ field, fieldState }) => (
                <DateFieldStyled
                  error={Boolean(fieldState.error)}
                  onChange={field.onChange}
                  value={field.value ? parseISO(field.value) : null}
                  InputBaseProps={{}}
                  label="4TH QUARTER DUE DATE"
                />
              )}
            />
          </Grid>
          <Grid xs={3}>
            <Controller
              control={control}
              name="q1_payment_estimate"
              render={({ field, fieldState }) => (
                <NumberFieldStyled
                  error={Boolean(fieldState.error)}
                  onChange={field.onChange}
                  value={field.value}
                  label="1ST QUARTER AMOUNT"
                />
              )}
            />
          </Grid>
          <Grid xs={3}>
            <Controller
              control={control}
              name="q2_payment_estimate"
              render={({ field, fieldState }) => (
                <NumberFieldStyled
                  error={Boolean(fieldState.error)}
                  onChange={field.onChange}
                  value={field.value}
                  label="2ND QUARTER AMOUNT"
                />
              )}
            />
          </Grid>
          <Grid xs={3}>
            <Controller
              control={control}
              name="q3_payment_estimate"
              render={({ field, fieldState }) => (
                <NumberFieldStyled
                  error={Boolean(fieldState.error)}
                  onChange={field.onChange}
                  value={field.value}
                  label="3RD QUARTER AMOUNT"
                />
              )}
            />
          </Grid>
          <Grid xs={3}>
            <Controller
              control={control}
              name="q4_payment_estimate"
              render={({ field, fieldState }) => (
                <NumberFieldStyled
                  error={Boolean(fieldState.error)}
                  onChange={field.onChange}
                  value={field.value}
                  label="4TH QUARTER AMOUNT"
                />
              )}
            />
          </Grid>
          <Grid xs={12}>
            <Box display="flex" gap="20px">
              <Button
                variant="contained"
                color="secondary"
                sx={{ marginLeft: 'auto' }}
                type="reset"
                size="large"
                onClick={onCancel}
              >
                Cancel
              </Button>
              <Button variant="contained" type="submit" size="large">
                Save
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Stack>
    </form>
  );
}
