export interface KarbonConnection {
  id: number;
  is_active: boolean;
  created_by: string;
  updated_by: string;
  created_at: string;
  updated_at: string;
  cpa_id: number;
  status: string;
  karbon_key: string;
  created_by_name: string;
  is_acct_auto_sync_in_progress: boolean;
  acct_auto_sync_started_by: string;
  acct_auto_sync_started_at: string;
  acct_auto_sync_ended_at: string;
  is_contact_auto_sync_in_progress: boolean;
  contact_auto_sync_started_by: string;
  contact_auto_sync_started_at: string;
  contact_auto_sync_ended_at: string;
}

export interface KarbonIntegrationStatusResponse {
  success: boolean;
  status: number;
  message: string;
  data?: KarbonConnection;
  logged_in_user_name?: string;
  liscio_karbon_accounts?: number;
}

export enum KarbonStatus {
  ACTIVE = 'Active',
  NEW = 'New',
  // NOTE: There may be others, but we don't seem to consume them here :shrug:
}

export enum KarbonActivationState {
  NOT_ACTIVE = 'NOT_ACTIVE',
  IN_PROGRESS = 'IN_PROGRESS',
  ACTIVE = 'ACTIVE',
}
