import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Stack, Typography } from '@mui/material';
import styled from '@emotion/styled';

import { DownloadIcon } from 'common/Icons';

const SIconButton = styled(IconButton)(() => ({
  width: 30,
  height: 30,
  border: '2px solid #dfe3e8',
  borderRadius: 0,
  '&:hover': { border: '2px solid white' },
}));

function UtilityButtons(props) {
  const { componentContext, numberOfSelected, onDownloadButtonClick } = props;

  return (
    <Stack direction="row" alignItems="center" gap={3} height={40}>
      <Typography variant="gray_body2">
        {`Selected (${numberOfSelected})`}
      </Typography>

      {!componentContext && (
        <SIconButton
          disableRipple
          aria-label="csv-download"
          onClick={onDownloadButtonClick}
        >
          <DownloadIcon />
        </SIconButton>
      )}
    </Stack>
  );
}

UtilityButtons.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  componentContext: PropTypes.any,
  numberOfSelected: PropTypes.number,
  onDownloadButtonClick: () => {},
};

UtilityButtons.defaultProps = { numberOfSelected: 0 };

export default UtilityButtons;
