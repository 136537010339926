import { OpenInNew } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { REQUESTS_PAGE_ROUTES } from 'components/Requests/requests.constants';

export const PreviewBulkSendDraftCell = ({ row }: { row: any }) => {
  const handlePreview = () => {
    window.open(`${REQUESTS_PAGE_ROUTES.dispatch}/${row.id}`, '_blank');
  };

  return (
    <Tooltip title="Preview Draft in new tab" placement="top">
      <OpenInNew
        fontSize="small"
        color="primary"
        onClick={(e: any) => {
          e.stopPropagation();
          handlePreview();
        }}
      />
    </Tooltip>
  );
};
