/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';

const FilterTag = ({ name, onClick, id }) => (
  <span className="tag tag--fill" style={{ zIndex: 2, position: 'relative' }}>
    {name}
    {onClick && (
      <button
        key={id}
        type="button"
        style={{
          border: 'none',
          background: 'transparent',
        }}
        onClick={() => {
          onClick();
        }}
      >
        <i className="icon-close2 remove" role="button" style={{ margin: 0 }} />
      </button>
    )}
  </span>
);

export default FilterTag;
