import axios from 'axios';
import {
  CREATE_DOC_FROM_TEMPLATE_IDS,
  GET_DOCUMENT_DETAILS,
  REPLACE_DOCUMENT_URL,
} from 'constants/constants';

export async function getDocumentDownload({ queryKey }: QueryCallParams) {
  const [, id] = queryKey;
  const { data } = await axios.get(`${REPLACE_DOCUMENT_URL}/${id}`);
  return data;
}

export async function getDocumentInfo(ids: string[]) {
  const payload = { template_doc_ids: ids, template_type: 'task_type' };
  const { data } = await axios.post(`${CREATE_DOC_FROM_TEMPLATE_IDS}`, payload);
  return data;
}

export async function getDocumentDetails(id: string) {
  const { data } = await axios.get(`${GET_DOCUMENT_DETAILS}/${id}`);
  return data;
}
