/* eslint-disable  jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-access-state-in-setstate */
import React, { Component } from 'react';
import Axios from 'axios';
import { isNil } from 'lodash';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom-latest';
import { withRouter } from 'utilities/withRouterWrapper';
import { isValidPhoneNumber } from 'react-phone-number-input';
import ConfirmationModal from 'common/ConfirmationModal';
import {
  AlertMessage,
  getCpaPreferences,
  updateCountry,
  labelNamebyValue,
  isUserEmployee,
  getFirmType,
  replaceUndefinedWithNull,
  getRole,
} from 'utilities/utils';
import {
  REMOVESPECIALCHAR,
  FORMATEIN,
  NUMBER,
} from 'utilities/regularexpressions';
import {
  ACCOUNT,
  SAVE_RELATIONSHIP,
  ADD_DEPENDENT,
  ACCOUNT_BANK_ACCOUNTS,
  GENERATE_ONBOARD_UPDATE_MESSAGE,
  MIXPANEL_EDIT_ACCOUNT_EVENT,
  MIXPANEL_CREATE_ACCOUNT_EVENT,
} from 'constants/constants';
import InputField from 'common/InputField';
import { PhoneNumberField } from 'common/PhoneNumberField';
import { SelectField, CheckboxField } from 'common/CustomFields';
import { resetAccountListData } from 'actions/AccountandContactListActions';
import { trackMixpanelEvent } from 'utilities/mixpanelfn';
import AddressComponent from 'components/Contacts/ContactCreation/AddressComponent';
import { updateEncryptedField } from 'api/encrypted-fields';
import { withQueryClient } from 'utilities/withQueryClient';
import NewRelationShipHeader from './NewRelationShipHeader';
import BussinessAccountDetails from './BussinessAccountDetails';
import IndividualAccountDetails from './IndividualAccountDetails';
import { SpouseDetails } from './SpouseDetails';
import DependentsDetails from './DependentsDetails';
import BankAccountDetails from './BankAccountDetails';
import {
  accountAddressFieldNames,
  spouseAddressFieldNames,
  validateIndividualAccount,
  validateDependents,
  validateBankAccountDetails,
  coBankAccount,
  formatAccountDates,
  encryptDependentData,
  showIndividualAdditionInformationForm,
  validateDependentsOnBlur,
  enableDependantAddButton,
  resetDataonEntityChange,
  encryptBankAccounts,
  business_account_AdditionalInformation_FieldNames,
  individual_Account_AdditionalInformation_FieldNames,
  validateIndividualAccountOnBlur,
} from './AccountUtilities';

class NewAccount extends Component {
  constructor(props) {
    super(props);

    this.state = {
      initialValues: props.account?.formdata,
      formdata: props.account?.formdata || { is_restricted_account: false },
      dependentsData: props.account?.dependentsData || [{}],
      bankAccountData: props.account?.bankAccountData || [{}],
      errors: {},
      deleteDependents: [],
      deleteBankAccounts: [],
      isAccountIndividual: props.account?.isAccountIndividual,
      preferences: {},
      noLoginData: true,
      modalText: [],
      role: getRole(),
      accountId: props.account?.accountId,
    };
  }

  UNSAFE_componentWillMount() {
    document.title = 'Create New Account';
    const { account_status } = getCpaPreferences();
    const accountId = window.location.href.split('edit/')[1];
    if (account_status && (isUserEmployee() || accountId)) {
      const accountStatus = account_status.filter(
        (each) => each.value.toLowerCase() !== 'inactive',
      );
      this.setState({
        preferences: { ...getCpaPreferences(), account_status: accountStatus },
      });
    } else {
      this.props.navigate('/accounts');
    }
  }

  checkPreferences = (fieldname, inputvalue) => {
    const {
      accountId,
      dependentsData,
      noLoginData,
      preferences: { individual_entity_type },
    } = this.state;
    let dependentsArray = [];
    dependentsData.map((e) => {
      dependentsArray.push({ ...e });
      return e;
    });
    dependentsArray = encryptDependentData(dependentsArray);
    let { formdata, isAccountIndividual } = this.state;
    let accountAdditionalInformationFieldNames =
      business_account_AdditionalInformation_FieldNames;
    const { value } = inputvalue && inputvalue !== null ? inputvalue : {};
    if (isAccountIndividual) {
      accountAdditionalInformationFieldNames =
        individual_Account_AdditionalInformation_FieldNames('');
    }
    formdata = { ...formdata, [fieldname]: value };
    isAccountIndividual = showIndividualAdditionInformationForm(
      formdata,
      individual_entity_type,
    );
    let changePreferences = [];
    accountAdditionalInformationFieldNames.map((e) => {
      if (
        formdata[e.value] &&
        formdata[e.value] !== null &&
        (formdata[e.value].length || formdata[e.value] === true) &&
        changePreferences.indexOf(e.section) === -1
      ) {
        changePreferences.push(e.section);
      }
      return e;
    });
    if (this.state.isAccountIndividual === isAccountIndividual) {
      this.setState({
        formdata,
        isAccountIndividual,
      });
    } else if (
      accountId &&
      noLoginData &&
      !dependentsArray.length &&
      !changePreferences.length
    ) {
      this.setState({
        formdata,
        isAccountIndividual,
      });
    } else {
      changePreferences = dependentsArray.length
        ? [...changePreferences, ...['Dependents Details']]
        : changePreferences;
      this.setState({
        showModal: true,
        modalText: !noLoginData
          ? [...changePreferences, ...['Login']]
          : changePreferences,
      });
    }
  };

  handleInputFieldChange = (inputvalue, fieldname, index) => {
    let value =
      inputvalue && inputvalue !== null && inputvalue.length
        ? inputvalue
        : undefined;
    if (
      value &&
      value.length &&
      (fieldname === 'phone_number' || fieldname === 'fax_number')
    ) {
      value = value.length ? value : undefined;
    }
    if (value && value.length && fieldname === 'co_ein') {
      value = value.length ? REMOVESPECIALCHAR(value) : undefined;
      value = value.length ? value : undefined;
    }
    if (
      value &&
      value.length &&
      (fieldname === 'in_self_ssn' ||
        fieldname === 'in_sps_ssn' ||
        fieldname === 'ssn')
    ) {
      value = value.length > 9 ? value.substring(0, 9) : value;
    }
    if (index === 'checkbox') {
      this.setState(
        {
          formdata: {
            ...this.state.formdata,
            [fieldname]: !this.state.formdata[fieldname],
          },
        },
        () =>
          this.state.errors[fieldname]
            ? this.handleBlurChange(fieldname)
            : null,
      );
    } else if (index !== undefined) {
      const array = this.state.dependentsData;
      array[index][fieldname] = value;
      this.setState({ dependentsData: array }, () =>
        this.state.errors.dependentsDataErrors &&
        this.state.errors.dependentsDataErrors[index] &&
        this.state.errors.dependentsDataErrors[index][fieldname]
          ? this.handleBlurChange(fieldname)
          : null,
      );
    } else {
      this.setState(
        { formdata: { ...this.state.formdata, [fieldname]: value } },
        () =>
          this.state.errors[fieldname]
            ? this.handleBlurChange(fieldname)
            : null,
      );
    }
  };

  handleEncryptedFieldChange = (inputvalue, fieldname) => {
    const [name, index] = fieldname.split('-');

    if (name === 'encryptedAccountNumber') {
      this.setState((currentState) => {
        const bankAccounts = structuredClone(currentState.bankAccountData);
        bankAccounts[index][name] = inputvalue;
        return {
          bankAccountData: bankAccounts,
        };
      });
    }

    if (name === 'encryptedSsn') {
      this.setState((currentState) => {
        const dependents = structuredClone(currentState.dependentsData);
        dependents[index][name] = inputvalue;
        return {
          dependentsData: dependents,
        };
      });
    }

    this.setState((currentState) => ({
      formdata: { ...currentState.formdata, [name]: inputvalue },
    }));
  };

  handleSelect = (fieldname, inputvalue, index) => {
    const {
      errors,
      formdata,
      preferences: { individual_entity_type },
      accountId,
    } = this.state;
    const { value } = inputvalue && inputvalue !== null ? inputvalue : {};
    if (index !== undefined) {
      const array = this.state.dependentsData;
      array[index][fieldname] = value;
      this.setState({ dependentsData: array }, () =>
        errors.dependentsDataErrors &&
        errors.dependentsDataErrors[index] &&
        errors.dependentsDataErrors[index][fieldname]
          ? this.handleBlurChange(fieldname)
          : null,
      );
    } else {
      this.setState({ formdata: { ...formdata, [fieldname]: value } }, () => {
        if (
          fieldname === 'status' &&
          value &&
          value.toLowerCase() === 'client'
        ) {
          this.setState({ formdata: { ...this.state.formdata, stage: null } });
        }
        if (errors[fieldname]) {
          this.handleBlurChange(fieldname);
        }
        if (fieldname === 'entity_type') {
          const isAccountIndividual = showIndividualAdditionInformationForm(
            this.state.formdata,
            individual_entity_type,
          );
          if (
            this.state.isAccountIndividual !== isAccountIndividual &&
            !accountId &&
            !this.props.createRelationship
          ) {
            // while creating new account we reset addition information forms data,
            // when user changes entity type from business to individual and vice versa
            this.setState({
              formdata: resetDataonEntityChange(this.state.formdata),
              isAccountIndividual,
            });
          } else {
            this.setState({ isAccountIndividual });
          }
        }
      });
    }
  };

  handleAddressSelect = (formmattedaddress) => {
    this.setState(
      { formdata: { ...this.state.formdata, ...formmattedaddress } },
      () => this.handleBlurChange(),
    );
  };

  handleAddressFieldChanges = (inputvalue, fieldname, addressFor) => {
    if (typeof inputvalue === 'object') {
      const { value } = inputvalue && inputvalue !== null ? inputvalue : {};
      this.setState(
        {
          formdata: updateCountry(
            { ...this.state.formdata, [fieldname]: value },
            addressFor ? spouseAddressFieldNames : accountAddressFieldNames,
            fieldname,
          ),
        },
        () =>
          this.state.errors[fieldname]
            ? this.handleBlurChange(fieldname)
            : null,
      );
    } else {
      const value =
        inputvalue && inputvalue !== null && inputvalue.length
          ? inputvalue
          : undefined;
      this.setState(
        {
          formdata: updateCountry(
            { ...this.state.formdata, [fieldname]: value },
            addressFor ? spouseAddressFieldNames : accountAddressFieldNames,
            fieldname,
          ),
        },
        () =>
          this.state.errors[fieldname]
            ? this.handleBlurChange(fieldname)
            : null,
      );
    }
  };

  handleCheckbox = (fieldname) => {
    this.setState({
      formdata: {
        ...this.state.formdata,
        [fieldname]: !this.state.formdata[fieldname],
      },
    });
  };

  handleDateField = (inputvalue, fieldname, index) => {
    if (index !== undefined) {
      const array = this.state.dependentsData;
      array[index][fieldname] = inputvalue;
      this.setState({ dependentsData: array }, () =>
        this.state.errors.dependentsDataErrors &&
        this.state.errors.dependentsDataErrors[index] &&
        this.state.errors.dependentsDataErrors[index][fieldname]
          ? this.handleBlurChange(fieldname)
          : null,
      );
    } else {
      this.setState(
        { formdata: { ...this.state.formdata, [fieldname]: inputvalue } },
        () => this.handleBlurChange(),
      );
    }
  };

  handleBankAccountSection = (inputvalue, fieldname, index, type) => {
    const value = inputvalue && inputvalue !== null ? inputvalue : undefined;
    const array = this.state.bankAccountData;
    const { errors } = this.state;
    if (type === 'checkbox') {
      array[index][fieldname] = !array[index][fieldname];
      if (!array[index][fieldname]) {
        delete array[index][fieldname];
      }
    } else {
      array[index][fieldname] = value;
    }
    this.setState({ bankAccountData: array }, () =>
      errors.bankAccountErrors &&
      errors.bankAccountErrors[index] &&
      errors.bankAccountErrors[index][fieldname]
        ? this.handleBlurChange(fieldname)
        : null,
    );
  };

  handleBlurChange = () => {
    const { formdata, bankAccountData, dependentsData } = this.state;
    let errors = {};
    if (this.state.errors.name && !formdata.name) {
      errors.name = 'Required';
    }

    if (this.state.errors.entity_type && !formdata.entity_type) {
      errors.entity_type = 'Required';
    }

    if (this.state.errors.status && !formdata.status) {
      errors.status = 'Required';
    }

    if (
      this.state.errors.stage &&
      ((formdata.status &&
        formdata.status.toLowerCase() !== 'client' &&
        !formdata.stage) ||
        (!formdata.status && !formdata.stage))
    ) {
      errors.stage = 'Required';
    }

    if (
      formdata.phone_number &&
      formdata.phone_number !== null &&
      !isValidPhoneNumber(formdata.phone_number)
    ) {
      errors.phone_number = 'Enter vaild number';
    }

    if (
      formdata.fax_number &&
      formdata.fax_number !== null &&
      !isValidPhoneNumber(formdata.fax_number)
    ) {
      errors.fax_number = 'Enter vaild number';
    }

    const bankAccountErrors = {};
    bankAccountData.map((e, key) => {
      const result = validateBankAccountDetails(e, coBankAccount);
      if (Object.keys(result).length) {
        bankAccountErrors[key] = result;
      }
      return e;
    });

    if (Object.keys(bankAccountErrors).length) {
      errors = { ...errors, bankAccountErrors };
    }

    const isAccountIndividual = showIndividualAdditionInformationForm(
      formdata,
      this.state.preferences.individual_entity_type,
    );

    if (isAccountIndividual) {
      errors = {
        ...errors,
        ...validateIndividualAccountOnBlur(formdata),
      };
      const dependentsDataErrors = validateDependentsOnBlur(dependentsData);
      if (Object.keys(dependentsDataErrors).length) {
        errors = { ...errors, dependentsDataErrors };
      }
    } else {
      if (
        formdata.encryptedCoEin &&
        REMOVESPECIALCHAR(FORMATEIN(formdata.encryptedCoEin)).length !== 9
      ) {
        errors.encryptedCoEin = 'enter valid EIN';
      }
      if (
        formdata.co_number_of_employees &&
        !NUMBER.test(formdata.co_number_of_employees)
      ) {
        errors.co_number_of_employees = 'enter valid number';
      }
    }

    this.setState({ errors });
  };

  handleCancelBtn = () => {
    const { accountId } = this.state;
    trackMixpanelEvent(
      accountId ? MIXPANEL_EDIT_ACCOUNT_EVENT : MIXPANEL_CREATE_ACCOUNT_EVENT,
      { state: 'cancelled' },
    );
    if (this.props.createRelationship && this.props.contactId) {
      this.props.handleCloseModal();
    } else {
      this.props.navigate(-1);
    }
  };

  addNewField = () => {
    const dependentsDataErrors = validateDependents(this.state.dependentsData);
    let { errors } = this.state;
    if (Object.keys(dependentsDataErrors).length) {
      errors = { ...errors, dependentsDataErrors };
      this.setState({ errors });
    } else if (!enableDependantAddButton(this.state.dependentsData)) {
      const { dependentsData } = this.state;
      dependentsData.push({});
      this.setState({ dependentsData });
    }
  };

  deleteField = (index) => {
    let { dependentsData } = this.state;
    const { deleteDependents } = this.state;
    deleteDependents.push({ ...dependentsData[index], deleteDependent: true });
    dependentsData = dependentsData.filter((e, key) => index !== key);
    if (!dependentsData.length) {
      dependentsData = [{}];
    }
    this.setState(
      {
        dependentsData,
        deleteDependents,
      },
      () => {
        const dependentsDataErrors = validateDependents(
          this.state.dependentsData,
        );
        this.setState({
          errors: { ...this.state.errors, dependentsDataErrors },
        });
      },
    );
  };

  addNewBankAccount = () => {
    const { bankAccountData } = this.state;
    bankAccountData.push({});
    this.setState({ bankAccountData });
  };

  deleteBankAccount = (index) => {
    let { bankAccountData } = this.state;
    const { deleteBankAccounts } = this.state;
    deleteBankAccounts.push({
      ...bankAccountData[index],
      deleteBankAccount: true,
    });
    bankAccountData = bankAccountData.filter((e, key) => index !== key);
    if (!bankAccountData.length) {
      bankAccountData = [{}];
    }
    this.setState(
      {
        bankAccountData,
        deleteBankAccounts,
      },
      () => {
        this.handleBlurChange();
      },
    );
  };

  getEncryptedFieldsPromises = ({
    formDataId,
    encryptedCoEin,
    encryptedInSelfSsn,
    encryptedInSpsSsn,
  }) => {
    const encryptedFieldsPromises = [];

    if (!isNil(encryptedCoEin) && formDataId) {
      encryptedFieldsPromises.push(
        updateEncryptedField({
          fieldName: 'co_ein',
          recordType: 'Account',
          recordId: formDataId,
          newValue: encryptedCoEin,
        }),
      );
    }

    if (!isNil(encryptedInSelfSsn) && formDataId) {
      encryptedFieldsPromises.push(
        updateEncryptedField({
          fieldName: 'in_self_ssn',
          recordType: 'Account',
          recordId: formDataId,
          newValue: encryptedInSelfSsn,
        }),
      );
    }

    if (!isNil(encryptedInSpsSsn) && formDataId) {
      encryptedFieldsPromises.push(
        updateEncryptedField({
          fieldName: 'in_sps_ssn',
          recordType: 'Account',
          recordId: formDataId,
          newValue: encryptedInSpsSsn,
        }),
      );
    }

    return encryptedFieldsPromises;
  };

  handleSubmit = () => {
    let { formdata } = this.state;
    const { isAccountIndividual, bankAccountData } = this.state;
    let errors = {};

    if (!formdata.name) {
      errors.name = 'Required';
    }

    if (!formdata.entity_type) {
      errors.entity_type = 'Required';
    }

    if (!formdata.status) {
      errors.status = 'Required';
    }

    if (
      (formdata.status &&
        formdata.status.toLowerCase() !== 'client' &&
        !formdata.stage) ||
      (!formdata.status && !formdata.stage)
    ) {
      errors.stage = 'Required';
    }

    if (
      formdata.phone_number &&
      formdata.phone_number !== null &&
      !isValidPhoneNumber(formdata.phone_number)
    ) {
      errors.phone_number = 'Enter vaild number';
    }

    if (
      formdata.fax_number &&
      formdata.fax_number !== null &&
      !isValidPhoneNumber(formdata.fax_number)
    ) {
      errors.fax_number = 'Enter vaild number';
    }

    if (isAccountIndividual) {
      errors = { ...errors, ...validateIndividualAccount(formdata) };
      const dependentsDataErrors = validateDependents(
        this.state.dependentsData,
      );
      if (Object.keys(dependentsDataErrors).length) {
        errors = { ...errors, dependentsDataErrors };
      }
    } else {
      if (
        formdata.encryptedCoEin &&
        REMOVESPECIALCHAR(FORMATEIN(formdata.encryptedCoEin)).length !== 9
      ) {
        errors.encryptedCoEin = 'enter valid EIN';
      }
      if (
        formdata.co_number_of_employees &&
        !NUMBER.test(formdata.co_number_of_employees)
      ) {
        errors.co_number_of_employees = 'enter valid number';
      }
    }

    if (this.props.createRelationship && !formdata.relationship_type) {
      errors.relationship_type = 'Required';
    }

    const bankAccountErrors = {};
    bankAccountData.map((e, key) => {
      const result = validateBankAccountDetails(e, coBankAccount);
      if (Object.keys(result).length) {
        bankAccountErrors[key] = result;
      }
      return e;
    });

    if (Object.keys(bankAccountErrors).length) {
      errors = { ...errors, bankAccountErrors };
    }

    this.setState({ errors });
    if (!Object.keys(errors).length) {
      formdata = { ...formdata };
      formdata = replaceUndefinedWithNull({
        ...formdata,
        ...formatAccountDates(formdata),
      });
      if (this.props.createRelationship && this.props.contactId) {
        this.handleRelationshipCreation();
      } else {
        let response = null;

        if (this.state.accountId) {
          response = Axios.put(`${ACCOUNT}/${this.state.accountId}`, {
            ...formdata,
            encryptedCoEin: undefined,
            encryptedInSpsSsn: undefined,
            encryptedInSelfSsn: undefined,
          });
        } else {
          response = Axios.post(ACCOUNT, {
            ...formdata,
            encryptedCoEin: undefined,
            encryptedInSpsSsn: undefined,
            encryptedInSelfSsn: undefined,
          });
        }
        this.saveAccount(response);
      }
    }
  };

  handleRelationshipCreation = () => {
    const { formdata } = this.state;
    Axios.post(ACCOUNT, formdata)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.status === 200) {
            if (res.data.data) {
              this.saveRelationShip(res.data.data.id);
              AlertMessage('success', 'Account is created', 3000);
            } else {
              this.saveRelationShip(res.data.id);
            }
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  saveRelationShip = (accountId) => {
    const relationShipData = {
      account_id: accountId,
      contact_id: this.props.contactId,
      relationship_type: this.state.formdata.relationship_type,
      is_account_owner: this.state.formdata.is_account_owner,
      is_billing_contact: this.state.formdata.is_billing_contact,
    };
    Axios.post(SAVE_RELATIONSHIP, relationShipData)
      .then((res) => {
        AlertMessage('success', res.data.message, 3000);
        this.props.handleCloseModal();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  saveAccount = (input) => {
    const urlAccountId = this.state.accountId;
    input
      .then((res) => {
        // Different API shape for creating and editing accounts
        const responseFormDataId = res?.data?.id || res?.data?.data?.id;
        const encryptedFieldsPromises = this.getEncryptedFieldsPromises({
          formDataId: responseFormDataId,
          encryptedCoEin: this.state?.formdata?.encryptedCoEin,
          encryptedInSelfSsn: this.state?.formdata?.encryptedInSelfSsn,
          encryptedInSpsSsn: this.state?.formdata?.encryptedInSpsSsn,
        });
        return Promise.all(encryptedFieldsPromises).then(() => res);
      })
      .then((res) => {
        if (res.status === 200) {
          if (res.data.status === 200) {
            trackMixpanelEvent(
              urlAccountId
                ? MIXPANEL_EDIT_ACCOUNT_EVENT
                : MIXPANEL_CREATE_ACCOUNT_EVENT,
              { state: urlAccountId ? 'edited' : 'created' },
            );
            AlertMessage('success', res.data.message, 3000);
            const { deleteDependents, bankAccountData, deleteBankAccounts } =
              this.state;
            const dependentsData = encryptDependentData(
              this.state.dependentsData,
            );
            let accountId = res.data.id;
            if (res.data.data) {
              accountId = res.data.data.id;
            }
            if (dependentsData.length || deleteDependents.length) {
              this.saveDependent(dependentsData, accountId);
            } else if (bankAccountData.length || deleteBankAccounts.length) {
              this.saveBankAccounts(accountId);
            } else {
              this.sendMessagetoEmployee();
              this.props.navigate(
                `/accountdetails/${accountId || this.state.accountId}/details`,
              );
            }
            if (!this.state.accountId) {
              this.props.resetAccountListData();
            }
          } else {
            trackMixpanelEvent(
              urlAccountId
                ? MIXPANEL_EDIT_ACCOUNT_EVENT
                : MIXPANEL_CREATE_ACCOUNT_EVENT,
              {
                state: 'error',
                messgae: res.data.message,
              },
            );
            AlertMessage('error', res.data.message, 3000);
          }
        }
      })
      .then(() => {
        // Clear cache
        this.props.queryClient?.removeQueries({
          queryKey: ['encryptedField'],
        });
      })
      .catch((err) => {
        trackMixpanelEvent(
          urlAccountId
            ? MIXPANEL_EDIT_ACCOUNT_EVENT
            : MIXPANEL_CREATE_ACCOUNT_EVENT,
          { state: 'error' },
        );
        console.log(err);
      });
  };

  saveDependent = (input, account) => {
    const { deleteDependents } = this.state;
    let dependants = input;
    if (deleteDependents.length) {
      dependants = dependants.concat(deleteDependents);
    }
    dependants.map((each, key) => {
      const eachone = { ...each, account_id: account };
      let response = null;
      const redirect = key + 1 === dependants.length;
      Object.keys(eachone).forEach((e) => {
        if (eachone[e] === undefined) {
          eachone[e] = null;
        }
      });
      const {
        dependant_relationship_type,
        first_name,
        last_name,
        middle_name,
        ssn,
        birthdate,
      } = eachone;
      if (
        each.id &&
        (each.deleteDependent ||
          (dependant_relationship_type === null &&
            first_name === null &&
            last_name === null &&
            middle_name === null &&
            birthdate === null &&
            ssn === null))
      ) {
        response = Axios.delete(`${ADD_DEPENDENT}/${each.id}`);
      } else if (each.id) {
        response = Axios.put(`${ADD_DEPENDENT}/${each.id}`, {
          ...eachone,
          encryptedSsn: null,
        });
      } else {
        response = Axios.post(ADD_DEPENDENT, {
          ...eachone,
          encryptedSsn: null,
        });
      }
      response
        .then((res) => {
          const dependantId = each.id || res?.data.dependant?.id;
          if (
            dependantId &&
            !isNil(each.encryptedSsn) &&
            !each.deleteDependent
          ) {
            return updateEncryptedField({
              fieldName: 'ssn',
              recordType: 'Dependant',
              recordId: dependantId,
              newValue: each.encryptedSsn,
            });
          }
          return res;
        })
        .then(() => {
          if (redirect) {
            const { bankAccountData, deleteBankAccounts } = this.state;
            if (bankAccountData.length || deleteBankAccounts.length) {
              this.saveBankAccounts(account);
            } else {
              this.sendMessagetoEmployee();
              this.props.navigate(`/accountdetails/${account}/details`);
            }
          }
        });
      return null;
    });
  };

  saveBankAccounts = (account_id) => {
    let { bankAccountData } = this.state;
    const { deleteBankAccounts } = this.state;
    bankAccountData = encryptBankAccounts(bankAccountData);
    if (deleteBankAccounts.length) {
      bankAccountData = bankAccountData.concat(deleteBankAccounts);
    }
    if (bankAccountData.length) {
      bankAccountData.map((each, key) => {
        const redirect = key + 1 === bankAccountData.length;
        const e = replaceUndefinedWithNull({ ...each, account_id });
        let response = null;
        if (e.id && e.deleteBankAccount) {
          response = Axios.delete(`${ACCOUNT_BANK_ACCOUNTS}/${e.id}`);
        } else if (e.id) {
          response = Axios.put(`${ACCOUNT_BANK_ACCOUNTS}/${e.id}`, {
            ...e,
            encryptedAccountNumber: null,
          });
        } else {
          response = Axios.post(ACCOUNT_BANK_ACCOUNTS, {
            ...e,
            encryptedAccountNumber: null,
          });
        }
        response
          .then((res) => {
            const bankAccountId = res?.data?.data?.id;
            if (!isNil(each.encryptedAccountNumber) && bankAccountId) {
              return updateEncryptedField({
                fieldName: 'account_number',
                recordType: 'BankAccount',
                recordId: bankAccountId,
                newValue: each.encryptedAccountNumber,
              });
            }
            return res;
          })
          .then(() => {
            if (redirect) {
              this.sendMessagetoEmployee();
              this.props.navigate(`/accountdetails/${account_id}/details`);
            }
          });
        return null;
      });
    } else {
      this.sendMessagetoEmployee();
      this.props.navigate(`/accountdetails/${account_id}/details`);
    }
  };

  sendMessagetoEmployee = () => {
    if (!isUserEmployee()) {
      const { accountId: account_id } = this.state;
      Axios.post(GENERATE_ONBOARD_UPDATE_MESSAGE, { account_id }).then(
        (res) => {
          console.log(res.status);
        },
      );
    }
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };

  handleRestrictedAccount = () => {
    const { formdata } = this.state;
    this.setState({
      formdata: {
        ...formdata,
        is_restricted_account: !formdata.is_restricted_account,
      },
    });
  };

  render() {
    const {
      formdata,
      errors,
      preferences,
      initialValues,
      accountId,
      isAccountIndividual,
      bankAccountData,
      showModal,
      modalText,
      role,
    } = this.state;
    const isEmployee = isUserEmployee();
    return (
      <div
        className="NewAccount"
        id={this.props.createRelationship ? '' : ''}
        data-testid="accountForm"
      >
        {this.props.createRelationship ? null : (
          <header>
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <Link
                  to={
                    initialValues
                      ? `/accountdetails/${accountId}/details`
                      : '/accounts'
                  }
                >
                  {initialValues ? initialValues.name : 'all accounts'}
                </Link>
              </li>
              <li className="breadcrumb-item">
                {accountId ? 'update account' : 'create new account'}
              </li>
            </ul>
          </header>
        )}
        <div
          className={
            this.props.createRelationship
              ? ''
              : 'center-wrapper col-lg-10 col-sm-12 col-md-12 m-auto'
          }
        >
          {this.props.createRelationship ? (
            <NewRelationShipHeader
              preferences
              relationFor="account"
              data={this.state}
              handleSelect={this.handleSelect}
              handleCheckbox={this.handleCheckbox}
            />
          ) : null}
          <section>
            {!this.props.createRelationship ? (
              <div className="row align-items-center no-gutters mb-3 pb-3">
                <div className="section-title col">
                  <h6 role="presentation" className="stepBacklink">
                    <a
                      onClick={this.handleCancelBtn}
                      data-testid="account__back_btn"
                    >
                      <i className="icon-left-arrow" /> Back
                    </a>
                  </h6>
                </div>
                {this.state.accountId ? (
                  <div className="formbtn justify-content-end m-0">
                    <div className="btn-wrap btn--leftspace">
                      <button
                        type="button"
                        className="btn btn-outline-light"
                        onClick={this.handleCancelBtn}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={this.handleSubmit}
                      >
                        {this.props.createRelationship
                          ? 'Add Account'
                          : this.state.accountId
                          ? 'Update Account'
                          : 'Create Account'}
                      </button>
                    </div>
                  </div>
                ) : null}
              </div>
            ) : null}
            <div className="pb-3 col-container section-title">
              <h5>
                Account Information{' '}
                <span className="text-light small font-weight-normal">
                  Required
                </span>
              </h5>
            </div>
            <form action="" autoComplete="off">
              <div className="form-group row">
                {/* <input type="password" name="password123123123123" autoComplete="off" className="hidden" /> */}
                <InputField
                  name="name"
                  label="Account Name *"
                  className="col-sm-12 col-lg-8 col-md-8"
                  onChange={this.handleInputFieldChange}
                  value={formdata.name}
                  error={errors.name}
                  onBlur={this.handleBlurChange}
                  autoComplete="accountname"
                  testid="account__account_name"
                />
                <SelectField
                  id="entity_type"
                  name="entity_type"
                  testid="account__entity_type"
                  options={preferences.entity_type}
                  handleChange={(input) =>
                    accountId && isUserEmployee()
                      ? this.checkPreferences('entity_type', input)
                      : this.handleSelect('entity_type', input)
                  }
                  value={{
                    value: formdata.entity_type,
                    label: formdata.entity_type,
                  }}
                  onBlur={this.handleBlurChange}
                  className="col-sm-12 col-lg-4 col-md-4"
                  error={errors.entity_type}
                  label="Entity Type *"
                />
              </div>
              <div className="form-group row">
                {isEmployee ? (
                  <SelectField
                    id="status"
                    name="status"
                    options={preferences.account_status}
                    handleChange={(input) => this.handleSelect('status', input)}
                    value={{ value: formdata.status, label: formdata.status }}
                    className="col-sm-12 col-lg-4 col-md-4"
                    error={errors.status}
                    onBlur={this.handleBlurChange}
                    label="Account Status *"
                  />
                ) : null}
                {isEmployee ? (
                  <SelectField
                    id="stage"
                    name="stage"
                    options={preferences.account_stage}
                    handleChange={(input) => this.handleSelect('stage', input)}
                    value={{ value: formdata.stage, label: formdata.stage }}
                    className="col-sm-12 col-lg-4 col-md-4"
                    error={errors.stage}
                    disabled={
                      formdata.status &&
                      formdata.status.toLowerCase() === 'client'
                    }
                    label="Stage *"
                    onBlur={this.handleBlurChange}
                  />
                ) : null}
                <div className="col-sm-12 col-lg-4 col-md-4">
                  <div className="d-flex pt-3 mt-3">
                    <span className="text-body mr-3">Restricted Account?</span>
                    <CheckboxField
                      id="restrictaccount"
                      name="restrictaccount"
                      value={formdata.is_restricted_account}
                      onChange={this.handleRestrictedAccount}
                      disabled={role && role !== 'firm_admin'}
                    />
                  </div>
                </div>
              </div>
              <div className="section-title pt-3 pb-3">
                <h5>
                  Additional Details{' '}
                  <span className="text-light small font-weight-normal">
                    Optional
                  </span>
                </h5>
              </div>
              <div className="form-group row">
                <InputField
                  name="website"
                  label="website"
                  className="col-sm-12 col-lg-4 col-md-4"
                  onChange={this.handleInputFieldChange}
                  value={formdata.website}
                  error={errors.website}
                  onBlur={this.handleBlurChange}
                />
                <PhoneNumberField
                  country="US"
                  placeholder="Phone Number"
                  value={formdata.phone_number}
                  onBlur={this.handleBlurChange}
                  onChange={this.handleInputFieldChange}
                  name="phone_number"
                  label="Phone Number"
                  error={errors.phone_number}
                  className="col-sm-12 col-lg-4 col-md-4"
                />
                <PhoneNumberField
                  country="US"
                  placeholder="Fax Number"
                  value={formdata.fax_number}
                  onBlur={this.handleBlurChange}
                  onChange={this.handleInputFieldChange}
                  name="fax_number"
                  label="Fax Number"
                  error={errors.fax_number}
                  className="col-sm-12 col-lg-4 col-md-4"
                />
              </div>
              <div className="form-group row">
                <InputField
                  name="external_account_id"
                  label="External ID"
                  className="col-sm-12 col-lg-4 col-md-4"
                  onChange={this.handleInputFieldChange}
                  value={formdata.external_account_id}
                  onBlur={this.handleBlurChange}
                  autoComplete="external_account_id"
                  disabled={!isEmployee}
                />
                <SelectField
                  id="referral_source"
                  name="referral_source"
                  options={preferences.referral_source}
                  handleChange={(input) =>
                    this.handleSelect('referral_source', input)
                  }
                  value={{
                    value: formdata.referral_source,
                    label: formdata.referral_source,
                  }}
                  className="col-sm-12 col-lg-4 col-md-4"
                  onBlur={this.handleBlurChange}
                  label="Referral Source"
                  clearable
                />
                {isEmployee ? (
                  <SelectField
                    id="rating"
                    name="rating"
                    options={preferences.account_rating}
                    handleChange={(input) => this.handleSelect('rating', input)}
                    value={{
                      value: formdata.rating,
                      label: labelNamebyValue(
                        formdata.rating,
                        preferences.account_rating,
                      ),
                    }}
                    className="col-sm-12 col-lg-4 col-md-4"
                    onBlur={this.handleBlurChange}
                    label="Account Rating"
                    clearable
                  />
                ) : null}
              </div>
              <AddressComponent
                name="account_address"
                data={this.state.formdata}
                errors={this.state.errors}
                fieldNames={accountAddressFieldNames}
                handleAddressSelect={(input) => this.handleAddressSelect(input)}
                onChange={(fieldname, input) =>
                  this.handleAddressFieldChanges(input, fieldname)
                }
              />
              {!this.props.createRelationship &&
              getFirmType() !== 'liscio-lite' ? (
                <div>
                  {!isAccountIndividual ? (
                    <BussinessAccountDetails
                      data={this.state}
                      onChange={this.handleInputFieldChange}
                      handleEncryptedFieldChange={
                        this.handleEncryptedFieldChange
                      }
                      preferences={this.state.preferences}
                      handleSelect={this.handleSelect}
                      onBlur={this.handleBlurChange}
                    />
                  ) : null}
                  {isAccountIndividual ? (
                    <IndividualAccountDetails
                      data={this.state}
                      onChange={this.handleInputFieldChange}
                      handleEncryptedFieldChange={
                        this.handleEncryptedFieldChange
                      }
                      handleSelect={this.handleSelect}
                      handleDateField={this.handleDateField}
                      preferences={this.state.preferences}
                      onBlur={this.handleBlurChange}
                    />
                  ) : null}
                  {isAccountIndividual ? (
                    <SpouseDetails
                      data={this.state}
                      onChange={this.handleInputFieldChange}
                      handleEncryptedFieldChange={
                        this.handleEncryptedFieldChange
                      }
                      handleAddressFieldChanges={this.handleAddressFieldChanges}
                      handleSelect={this.handleSelect}
                      handleDateField={this.handleDateField}
                      preferences={this.state.preferences}
                      handleAddressSelect={(input) =>
                        this.handleAddressSelect(input)
                      }
                      onBlur={this.handleBlurChange}
                    />
                  ) : null}
                  {isAccountIndividual ? (
                    <DependentsDetails
                      data={this.state}
                      onChange={this.handleInputFieldChange}
                      handleEncryptedFieldChange={
                        this.handleEncryptedFieldChange
                      }
                      handleSelect={this.handleSelect}
                      handleDateField={this.handleDateField}
                      preferences={this.state.preferences}
                      addNewField={this.addNewField}
                      deleteField={this.deleteField}
                      onBlur={this.handleBlurChange}
                    />
                  ) : null}
                  {getFirmType() !== 'cpa' ? (
                    ''
                  ) : (
                    <BankAccountDetails
                      data={this.state}
                      bankAccountData={bankAccountData}
                      onChange={this.handleBankAccountSection}
                      handleEncryptedFieldChange={
                        this.handleEncryptedFieldChange
                      }
                      preferences={this.state.preferences}
                      fieldNames={coBankAccount}
                      onBlur={this.handleBlurChange}
                      addNewField={this.addNewBankAccount}
                      deleteField={this.deleteBankAccount}
                    />
                  )}
                </div>
              ) : null}
              {/* for Bussiness account details */}
              <div className="formbtn justify-content-end AccountButtonGroup">
                <div className="btn-wrap btn--leftspace">
                  <button
                    type="button"
                    className="btn btn-outline-light"
                    onClick={this.handleCancelBtn}
                    data-testid="account__cancel_btn"
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={this.handleSubmit}
                    data-testid="account__submit_btn"
                  >
                    {this.props.createRelationship
                      ? 'Add Account'
                      : this.state.accountId
                      ? 'Update Account'
                      : 'Create Account'}
                  </button>
                </div>
              </div>
            </form>
            {showModal ? (
              <ConfirmationModal
                isOpen
                messageText={`Please delete data in ${modalText.join(
                  ' and ',
                )} sections`}
                noBtnText="Ok"
                noBtnClick={this.closeModal}
              />
            ) : null}
          </section>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  resetAccountListData: () => dispatch(resetAccountListData()),
});

export default withQueryClient(
  withRouter(connect(null, mapDispatchToProps)(NewAccount)),
);
