import React from 'react';
import ClearIcon from '@mui/icons-material/Clear';

const ClearButton = (props) => {
  const {
    onClickClear,
    children,
    className = '',
    hidden,
    iconHidden = false,
  } = props;
  return hidden ? null : (
    <button
      type="button"
      className="filter-clear-button"
      onClick={onClickClear}
    >
      {!iconHidden && <ClearIcon className={`${className}`} />}
      {children}
    </button>
  );
};

export default ClearButton;
