import { Stack } from '@mui/material';
import { useNavigate, useParams, useLocation } from 'react-router-dom-latest';
import { RequestsBuilder } from 'components/Requests/components/RequestsBuilder/RequestsBuilder';
import { useEffect } from 'react';
import { REQUESTS_PAGE_TITLES } from 'components/Requests/requests.constants';
import LoadingIndicator from 'common/LoadingIndicator';
import { useCreateWorkflow } from 'hooks/workflow-hooks';
import { DASHBOARD_ROUTE } from 'constants/constants';

export const BuilderRequestView = () => {
  useEffect(() => {
    document.title = REQUESTS_PAGE_TITLES.builder;
  }, []);

  const { mutate: createWorkflowMutation } = useCreateWorkflow();

  const { workflow_id } = useParams();

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!workflow_id) {
      createWorkflowMutation(
        { title: 'Untitled' },
        {
          onSuccess: (response) => {
            navigate(`${response?.createWorkflow?.workflow?.id}`, {
              replace: true,
            });
          },
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGoBack = () => {
    if (location.state) {
      navigate(-1);
    } else {
      navigate(DASHBOARD_ROUTE);
    }
  };

  if (!workflow_id) {
    return <LoadingIndicator loading />;
  }

  return (
    <Stack
      direction="column"
      sx={{ overflow: 'hidden', height: 'calc(100vh - 32px)' }}
    >
      <RequestsBuilder onBack={handleGoBack} workflowId={workflow_id} />
    </Stack>
  );
};
