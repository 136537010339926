const rotateCounterClockwiseSVG = `
  <span
    className="PSPDFKit-Tool-Button-Icon PSPDFKit-Toolbar-Button-Icon PSPDFKit-Icon PSPDFKit-Icon-RotateLeft"
    role="presentation"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="currentColor"
      viewBox="0 0 24 24"
      focusable="false"
    >
      <path d="M12.994 2.564a.75.75 0 0 0-.988-1.128l-4 3.5a.75.75 0 0 0 0 1.128l4 3.5a.75.75 0 0 0 .988-1.128l-2.558-2.239A6.25 6.25 0 1 1 5.75 12.25a.75.75 0 0 0-1.5 0 7.75 7.75 0 1 0 6.397-7.632l2.347-2.054Z" />
    </svg>
  </span>
`;

export const RotateCounterClockwiseButton = {
  type: 'custom',
  id: 'liscio-rotate-counter-clockwise-button',
  icon: rotateCounterClockwiseSVG,
  title: 'Rotate Counter Clockwise',
  onPress: () => {
    const instance = window?.PSPDFKIT_INSTANCE;
    instance?.applyOperations([
      {
        type: 'rotatePages',
        pageIndexes: [instance?.viewState.currentPageIndex],
        rotateBy: 270,
      },
    ]);
  },
};
