import {
  RpaDocumentProcessingCountDocument,
  RpaDocumentProcessingCountQueryVariables,
  RpaDocumentsQueryVariables,
} from 'gql/graphql';
import { rpaDocumentsQueryDocument } from 'api/rpa-documents';
import { UseQueryOptions } from '@tanstack/react-query';
import { useGraphQLWithOptions } from './useGraphQL';

export function useRpaDocumentProcessingCountQuery(
  variables: RpaDocumentProcessingCountQueryVariables,
  options?: {
    enabled?: boolean;
    staleTime?: number;
  },
) {
  return useGraphQLWithOptions(
    RpaDocumentProcessingCountDocument,
    {
      refetchInterval: 5000,
      ...options,
    },

    variables,
  );
}

export function useRpaDocumentsQuery(
  variables: RpaDocumentsQueryVariables,
  options?: Pick<
    UseQueryOptions<RpaDocumentsQueryVariables>,
    'enabled' | 'staleTime'
  >,
) {
  return useGraphQLWithOptions(
    rpaDocumentsQueryDocument,
    { retry: false, ...options },
    variables,
  );
}
