import { useQueries, useQuery } from '@tanstack/react-query';
import {
  getDocumentDownload as getDocumentUrl,
  getDocumentInfo,
  getDocumentDetails,
} from 'api/documents';
import { useEffect, useState } from 'react';

export function useDocumentUrl(id: string) {
  return useQuery({
    queryKey: ['workflow-document-url', id],
    queryFn: getDocumentUrl,
    enabled: !!id,
  });
}

export function useDocumentInfo(ids: string[]) {
  return useQuery({
    queryKey: ['workflow-document-info', ...ids],
    queryFn: () => getDocumentInfo(ids),
    enabled: ids.length > 0 && ids[0] !== '',
  });
}

// This is the preferred way to get a documents details.
// It does not create a temporary document in S3 unlike getDocumentInfo.
export function useDocumentDetail(id: string | null | undefined) {
  return useQuery({
    queryKey: ['workflow-document-detail', id],
    queryFn: () => getDocumentDetails(id as string),
    enabled: !!id,
  });
}

// Like above but allows for an array of document ids to be passed in.
// Replace the useDocumentInfo hook with this one when possible
export function useDocumentDetails(ids: string[] | null | undefined) {
  const [data, setData] = useState<{ documents: any[] }>({ documents: [] });
  const createQueries = ids?.length
    ? ids.map((id) => ({
        queryKey: ['workflow-document-details', id],
        queryFn: () => getDocumentDetails(id),
        enabled: !!id,
      }))
    : [];

  const rawDocDetails = useQueries({ queries: createQueries });

  useEffect(() => {
    const tData = rawDocDetails.reduce(
      (acc, doc) => {
        if (doc.data) {
          // if no doc.data.data then it's an error
          // we want to include in the final object so the consumer can decide what to do with it
          acc.documents.push(doc.data?.data || doc.data);
        }
        return acc;
      },
      { documents: [] } as { documents: any[] },
    );

    // the useQueries hook always returns a new array so we need to compare the contents
    // https://github.com/TanStack/query/discussions/5123
    const isEqual =
      tData.documents.length === data.documents.length &&
      tData.documents.every((doc, i) => doc === data.documents[i]);
    if (isEqual) {
      return;
    }

    setData(tData);
  }, [data.documents, rawDocDetails]);

  const isLoading = rawDocDetails.some((doc) => doc.isLoading);
  const isError = rawDocDetails.some(
    (doc) => doc.isError || doc?.data?.success === false,
  );
  const fetchStatus = isLoading ? 'loading' : 'idle';
  // this is react-query telling us it completed a request(s)
  // whether the actual request returned document details is determined by the doc.data.success property
  const isSuccess = rawDocDetails.every((doc) => doc.isSuccess);

  return {
    data,
    isLoading,
    isError,
    isSuccess,
    fetchStatus,
  };
}
