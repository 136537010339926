/* eslint-disable import/prefer-default-export */
import { useMutation, useQuery } from '@tanstack/react-query';
import { addToContact, getContacts } from 'api/contacts';

/**
 * This hook is used by the MuiContactAccountModal
 * to fetch the contacts and thier associated
 * accounts.
 *
 * @returns Array
 */
export function useContacts() {
  return useQuery({
    queryKey: ['contacts'],
    queryFn: getContacts,
  });
}

export function useAddToContact() {
  return useMutation({ mutationFn: addToContact });
}
