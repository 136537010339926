/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { Component } from 'react';
import Axios from 'axios';
import { isUserEmployee } from 'utilities/utils';
import { ACCOUNT, SEARCH_ACCOUNTS_WITH_FILTERS } from 'constants/constants';
import MulitpleSelectDropdown from './MulitpleSelectDropdown';
import RenderSelectedRecords from './RenderSelectedRecords';

const filterListData = (allRecords, selectedRecords) => {
  const selectedRecordsIds = [];
  selectedRecords.map((record) => {
    selectedRecordsIds.push(record.id);
    return record;
  });
  const filteredData = [];
  allRecords.map((record) => {
    if (!selectedRecordsIds.includes(record.id)) {
      filteredData.push(record);
    }
    return record;
  });
  return filteredData;
};

export default class AccountsDropDown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sortBy: 'name',
      sortOrder: 'asc',
      pagination: 1,
      listData: [],
      loading: true,
      searchFieldText: '',
      hasMore: true,
      dropdownOpen: false,
      selectedRecords: this.props.selectedRecords,
      recordsViewHeight: '40px',
    };
  }

  componentDidMount() {}

  shouldComponentUpdate(nextProps) {
    if (nextProps.selectedRecords.length > this.props.selectedRecords.length) {
      this.updateSelectedRecords(nextProps.selectedRecords);
    }
    return true;
  }

  getRecords = () => {
    const { pagination, sortBy, sortOrder, searchFieldText } = this.state;
    let url = `${ACCOUNT}?page=${pagination}&field=${sortBy}&sort_order=${sortOrder}`;
    if (searchFieldText && searchFieldText !== null && searchFieldText.length) {
      if (isUserEmployee()) {
        url = `${url}&keyboard=${searchFieldText}`;
        Axios.post(SEARCH_ACCOUNTS_WITH_FILTERS, {
          keyword: searchFieldText.toLowerCase(),
          page: pagination,
          per_page: 15,
          sort_by: sortOrder,
          field: sortBy,
        }).then((res) => {
          if (res.data.status === 200) {
            const {
              count_data: { all: total_entries },
            } = res.data;
            const { listData } = this.state;
            this.setState({
              listData: listData.concat(res.data.data),
              loading: false,
              pagination: pagination + 1,
              hasMore: isUserEmployee()
                ? listData.length !== total_entries
                : false,
            });
          } else {
            this.setState({
              loading: false,
              hasMore: false,
            });
          }
        });
      } else {
        const k = [];
        const value = searchFieldText;
        const filterData = value.toUpperCase();
        const listData = this.state.allAccountsData;
        listData.map((item) => {
          for (let i = 0; i < searchFieldText.length; i += 1) {
            if (item.name.toUpperCase().indexOf(filterData) > -1) {
              if (k.indexOf(item) === -1) {
                k.push(item);
              }
            }
          }
          return k;
        });
        this.setState({
          listData: k,
          loading: false,
          hasMore: false,
        });
      }
    } else {
      Axios.get(url).then((res) => {
        if (res.data && res.data.data && res.data.data.length) {
          const {
            count_data: { all: total_entries },
          } = res.data;
          const { listData } = this.state;
          this.setState({
            listData: listData.concat(res.data.data),
            loading: false,
            pagination: pagination + 1,
            hasMore: isUserEmployee()
              ? listData.length !== total_entries
              : false,
          });
          if (!isUserEmployee()) {
            this.setState({ allAccountsData: listData.concat(res.data.data) });
          }
        } else {
          this.setState({
            loading: false,
            hasMore: false,
          });
        }
      });
    }
  };

  handleSearchField = (input) => {
    const { searchFieldText } = this.state;
    this.setState({
      searchFieldText: input === null ? '' : input,
      loading: input === null ? false : input.length > 2,
      pagination: 1,
      listData: [],
    });
    clearTimeout(this.typingTime);
    this.typingTime = setTimeout(() => {
      if (input !== null) {
        this.getRecords(input, searchFieldText.length);
      }
    }, 500);
  };

  assignAccount = (input) => {
    const { selectedRecords } = this.state;
    this.setState({ selectedRecords: selectedRecords.concat([input]) }, () => {
      this.props.handleChange(this.state.selectedRecords);
    });
  };

  toggleDropdown = (input) => {
    this.setState(
      { dropdownOpen: input },
      () => this.state.dropdownOpen && this.getRecords(),
    );
  };

  changedSelectedViewHeight = (input) => {
    this.setState({ recordsViewHeight: input });
  };

  removeSelectedRecord = (input) => {
    const { selectedRecords } = this.state;
    const filterRecords = selectedRecords.filter((e) => e.id !== input.id);
    this.setState({ selectedRecords: filterRecords }, () => {
      this.props.handleChange(this.state.selectedRecords);
    });
  };

  updateSelectedRecords = (data) => {
    this.setState({ selectedRecords: data });
  };

  render() {
    const {
      listData,
      hasMore,
      selectedRecords,
      recordsViewHeight,
      dropdownOpen,
      loading,
    } = this.state;
    const { dropdownFor } = this.props;
    const filterData = filterListData(listData, selectedRecords);
    return (
      <div
        className="cstPopupShow"
        style={{ width: '100%', position: 'relative' }}
      >
        <RenderSelectedRecords
          dropdownFor={dropdownFor}
          data={selectedRecords}
          changedSelectedViewHeight={this.changedSelectedViewHeight}
          handleRemove={this.removeSelectedRecord}
          isFocused={dropdownOpen}
        />
        <MulitpleSelectDropdown
          dropdownFor={dropdownFor}
          data={filterData}
          // eslint-disable-next-line no-unneeded-ternary
          hasMore={loading && hasMore ? true : false}
          recordsViewHeight={recordsViewHeight}
          onClick={this.toggleDropdown}
          getMoreData={this.getRecords}
          handleSearch={this.handleSearchField}
          onSelect={this.assignAccount}
        />
      </div>
    );
  }
}
