import { Box, Typography } from '@mui/material';

type OrganizerTemplatesHeaderProps = {
  current?: boolean;
};

export function OrganizerTemplatesHeader({
  children,
  current,
}: React.PropsWithChildren<OrganizerTemplatesHeaderProps>) {
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Typography variant="h3">{children}</Typography>
      {current && (
        <Box>
          <Box padding="6px" borderRadius="2px" bgcolor="#FDE5F5">
            <Typography
              color="#B6107F"
              fontWeight={700}
              sx={{
                leadingTrim: 'both',
                textEdge: 'cap',
                fontFeatureSettings: '"clig" off, "liga" off',
                lineHeight: '100%',
              }}
            >
              CURRENT
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
}
