/* eslint-disable global-require */
/* eslint-disable func-names */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-arrow-callback */
import axios from 'axios';
import { MARK_ALL_READ, GET_NOTIFICATIONS } from 'constants/constants';

export const getNotification = (
  component,
  fro = '',
  load = true,
  page = '',
  sort = '',
) => {
  if (fro === 'popup' && load) {
    component.setState({ notificationload: true });
  } else if ((fro === 'Unread' || fro === 'All') && load) {
    component.setState({ loading: true });
  }
  let param;
  if (fro === 'popup') {
    param = `?popup=true${page ? `&page=${page}` : ''}`;
  } else if (fro === 'All') {
    param = `?all=true&sort_by=${sort}&field=id${page ? `&page=${page}` : ''}`;
  } else {
    param = `?sort_by=${sort}&field=id${page ? `&page=${page}` : ''}`;
  }
  const request = axios.get(GET_NOTIFICATIONS + param);
  request
    .then((response) => {
      component.setState({ notificationload: false, loading: false });
      if (response.data.status === 200) {
        if (fro === 'popup') {
          component.props.NotificationActions.fetchNotification(response.data);
        }
        if (fro === 'Unread' || fro === 'All') {
          component.setState({
            notifications: response.data.data,
            paginationOffset: response.data.res_data.offset,
            paginationOff: response.data.res_data.off,
            totalRecords: response.data.count,
          });
        }
      }
    })
    .catch((error) => {
      console.log(error);
      component.setState({ notificationload: false, loading: false });
      return null;
    });
};
export const markAllRead = (component) => {
  const request = axios.post(MARK_ALL_READ);
  request
    .then((response) => {
      if (response.status === 200) {
        component.props.NotificationActions.notificationCount(0);
        component.props.Messageactions.receiveThreadCount(0);
      }
    })
    .catch((error) => {
      console.log(error);
      return null;
    });
};
