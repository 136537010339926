import { Box, Paper, Stack, styled } from '@mui/material';
import TierSlot from './TierSlot';

interface Props {
  seatCount?: number | null | undefined;
  billingCycle?: string | null | undefined;
  licenseTier?: string | null | undefined;
}

const TierPanelStyle = styled(Paper)(({ theme }) => ({
  padding: `${theme.spacing(3)} ${theme.spacing(3)}`,
  width: '96%',
  alignSelf: 'center',
  backgroundColor: theme.palette.common.secondary[50], //'#EEF0F1',
  borderRadius: '0.5rem',
  marginTop: '1rem',
}));

/**
 * This component renders the Panel with the current
 * tier information.
 */
export default function TierPanel({
  seatCount,
  billingCycle,
  licenseTier,
}: Props) {
  return (
    <TierPanelStyle elevation={0}>
      <Stack direction="row" justifyContent="space-between">
        <TierSlot
          label="Current Plan"
          value={licenseTier === 'CORE' ? 'Core Plan' : 'Pro Plan'}
          current
        />
        <Stack direction="row" gap={12}>
          <TierSlot label="Users" value={seatCount?.toString() || '0'} />
          <TierSlot label="Billing Cycle" value={billingCycle || ''} />
          <TierSlot label="License Tier" value={licenseTier || ''} />
          <Box width="1rem" />
        </Stack>
      </Stack>
    </TierPanelStyle>
  );
}
