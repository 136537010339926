/* eslint-disable */
// TODO: will come back again
import React, { Component } from 'react';
import {
  Form,
  FormGroup,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
  Button,
} from 'reactstrap';
import {
  OptionBoxSelectFieldWithAvatar,
  SelectField,
} from 'common/CustomFields';
import { isUserEmployee } from 'utilities/utils';

const dropdownToggle = (dropdownName, className) => {
  const addClass = className;
  const removeClass = className === 'show' ? 'hide' : 'show';
  const dropdown = document.getElementById(dropdownName);
  if (dropdown) {
    dropdown.classList.remove(removeClass);
    dropdown.classList.add(addClass);
  }
};
class FileRecipientCreation extends Component {
  constructor(props) {
    super(props);
    this.dropdownRecipent = React.createRef();
    this.dropdownRecipentSibling = React.createRef();
    this.dropdownAccounts = React.createRef();
    this.dropdownAccountsSibling = React.createRef();
    this.state = {
      dropdownOpenRecipent: false,
      dropdownOpenAccounts: false,
    };
  }

  toggleRecipent = (e) => {
    if (
      this.state.dropdownOpenRecipent &&
      (e.target === this.dropdownRecipent.current ||
        (!this.dropdownRecipent.current.contains(e.target) &&
          (e.target === !this.dropdownRecipentSibling.current ||
            this.dropdownRecipentSibling.current.contains(e.target))))
    ) {
      return;
    }
    this.setState((prevState) => ({
      dropdownOpenRecipent: !prevState.dropdownOpenRecipent,
    }));
  };

  toggleAccounts = (e) => {
    if (
      this.state.dropdownOpenAccounts &&
      (e.target === this.dropdownAccounts.current ||
        (!this.dropdownAccounts.current.contains(e.target) &&
          (e.target === !this.dropdownAccountsSibling.current ||
            this.dropdownAccountsSibling.current.contains(e.target))))
    ) {
      return;
    }
    this.setState((prevState) => ({
      dropdownOpenAccounts: !prevState.dropdownOpenAccounts,
    }));
  };

  inputRenderer = (inputProps, block) => {
    const { data } = this.props;
    delete inputProps.value;
    return (
      <div className="Select-input">
        <input {...inputProps} value={data[block]} />
      </div>
    );
  };

  rendertags() {
    const { uniqueRecords } = this.props.data;
    const { disableToField } = this.props;
    if (uniqueRecords.length) {
      const tag = uniqueRecords.map((each, index) => {
        const contactName = each.contact
          ? each.contact.label ||
            `${each.contact.firstname} ${each.contact.lastname}`
          : '';
        const accountName = each.account
          ? each.account.label || each.account.accountname
          : '';
        return (
          <span key={index} className="tag tag--fill receipt-account-tag">
            {contactName}
            <i className={`divOption ${!accountName ? 'hidden' : ''}`} />{' '}
            {accountName}
            {disableToField || each.flag ? null : (
              <i
                className="icon-close2 remove"
                onClick={(e) => this.props.removeSelectedRecord(e, each, index)}
              />
            )}
          </span>
        );
      });
      return tag;
    }
    return null;
  }

  render() {
    const { data, formdata, preferences, disableToField } = this.props;
    const { contacts } = data;
    const employeeArray = contacts.filter((contact) => contact.type === 'emp');
    const contactArray = contacts.filter(
      (contact) => contact.type === 'contact',
    );
    const groupedOptions = isUserEmployee()
      ? [
          { label: 'Contacts', options: contactArray },
          { label: 'Employees', options: employeeArray },
        ]
      : [
          { label: 'Employees', options: employeeArray },
          { label: 'Contacts', options: contactArray },
        ];
    if (formdata.contactId) {
      dropdownToggle('recipientDropdown', 'hide');
    }
    if (formdata.accountId) {
      dropdownToggle('fileAccountDropdown', 'hide');
    }
    const accountName = data.savedRecords?.account
      ? data.savedRecords.account.accountname
      : '';
    const contactName = data.savedRecords?.contact
      ? `${data.savedRecords.contact.firstname} ${data.savedRecords.contact.lastname}`
      : '';
    const selectedContactName =
      formdata.contactId && formdata.contactId.label
        ? formdata.contactId.label
        : '';
    const selectedAccountName =
      formdata.accountId && formdata.accountId.label
        ? formdata.accountId.label
        : '';
    const recipientPlusIcon =
      (isUserEmployee() && !data.fileUploadContactSide) ||
      this.props.enablePlusIcon
        ? formdata.contactId ||
          !data.contactInput ||
          (data.contactInput && !data.contactInput.length) ||
          data.savedRecords?.contact
          ? 'disabled'
          : ''
        : 'hidden';
    const accountPlusIcon =
      isUserEmployee() && !data.fileUploadAccountSide
        ? formdata.accountId ||
          !data.accountInput ||
          (data.accountInput && !data.accountInput.length) ||
          data.savedRecords?.account ||
          (formdata.contactId && formdata.contactId.type === 'emp')
          ? 'disabled'
          : ''
        : 'hidden';
    let dataToggle = 'dropdown';
    // if (this.props.enablePlusIcon && recipientPlusIcon === '' && data.contactInput && !formdata.contactId && formdata.email && (formdata.firstname || formdata.lastname)) {
    //   dataToggle = '';
    // }
    if (
      this.props.enablePlusIcon &&
      recipientPlusIcon === '' &&
      !formdata.contactId &&
      data.contactInput &&
      (formdata.firstname || formdata.lastname) &&
      formdata.email
    ) {
      dataToggle = '';
    } else if (
      this.props.enablePlusIcon &&
      recipientPlusIcon === '' &&
      !formdata.contactId &&
      data.contactInput &&
      (formdata.firstname || formdata.lastname) &&
      !data.contactInput &&
      (!formdata.firstname || !formdata.lastname)
    ) {
      dataToggle = 'dropdown';
    }
    return (
      <FormGroup
        className={`col-12 ${data.errors.toField ? 'form-error' : ''}`}
      >
        <Label htmlFor="forEmail">To</Label>
        <div className="labelValue customTags mailDrodown">
          <div className="customTags__input customTags__hauto form-control">
            <div
              onClick={this.props.recipientDropdownToggle}
              className="cstRtags"
            >
              {this.rendertags()}
            </div>
            {/* <a onClick={this.props.recipientDropdownToggle} href="javascript:void(0)" className={`${disableToField || 'inputHelper dropdown-toggle noCaret'}`} /> */}
          </div>
          {data.recipientDropdownToggle ? (
            <DropdownMenu
              ref={this.accountDropdownRef}
              id={data.recipientDropdownToggle ? 'showRecipientDropdown' : ''}
              className="customTags__option option_box cstmLiteupload"
            >
              <div id="_recepientDropdown" className="inItem pos-rel dropdown">
                {data.savedRecords?.contact ? (
                  <div className="dropdown-item">
                    <label htmlFor="seachContact" className="label-medium">
                      Recipient
                    </label>
                    <span className="tag tag--fill mt-1">
                      {contactName.length > 18
                        ? `${contactName.substring(0, 18)}...`
                        : contactName}
                      <i
                        className="icon-close2 remove"
                        onClick={() =>
                          this.props.removeSavedRecord('contactInput')
                        }
                      />
                    </span>
                  </div>
                ) : formdata.contactId ? (
                  <div className="dropdown-item">
                    <label htmlFor="seachContact" className="label-medium">
                      Recipient
                    </label>
                    <span className="tag tag--fill mt-1">
                      {selectedContactName.length > 18
                        ? `${selectedContactName.substring(0, 18)}...`
                        : selectedContactName}
                      <i
                        className={`icon-close2 remove ${
                          data.fileUploadContactSide ? 'hidden' : ''
                        }`}
                        onClick={() =>
                          this.props.handleContactChange('contactId', null)
                        }
                      />
                    </span>
                  </div>
                ) : (
                  <div ref={this.dropdownRecipentSibling}>
                    <OptionBoxSelectFieldWithAvatar
                      name="seachContact"
                      label="Recipient"
                      options={groupedOptions}
                      value={formdata.contactId || { value: '', label: '' }}
                      inputValue={data.contactInput}
                      handleChange={(input) =>
                        this.props.handleContactChange('contactId', input)
                      }
                      handleInputChange={this.props.handleContactInput || null}
                      inputRenderer={(input) =>
                        this.inputRenderer(input, 'contactInput')
                      }
                      onBlurResetsInput={false}
                      onCloseResetsInput={false}
                      onSelectResetsInput={
                        data.contactInput && data.contactInput.length
                      } // false shows placeholder
                      placeholder="Select a recipient"
                      autofocus
                    />{' '}
                  </div>
                )}
                <div ref={this.dropdownRecipent}>
                  <Dropdown
                    isOpen={this.state.dropdownOpenRecipent}
                    toggle={this.toggleRecipent}
                  >
                    <DropdownToggle
                      color="primary"
                      className={`rcpClick dropdown-toggle p-0 text-center ${recipientPlusIcon}`}
                    >
                      <i className="pr-0 icon-plus" />
                    </DropdownToggle>
                    <DropdownMenu
                      id="recipientDropdown"
                      className="w-100 border-0"
                    >
                      <div className="dropdown-item py-0">
                        <Form id="form1" className="rcpForm w-100">
                          <FormGroup
                            className={`form-inline-label align-items-stretch ${
                              data.errors.firstname ? 'form-error' : ''
                            }`}
                          >
                            <Label
                              htmlFor="forFstName"
                              className="mt-1 pt-2 label-medium"
                            >
                              First Name
                            </Label>
                            <div className="labelValue">
                              <Input
                                id="fileFirstName"
                                name="firstname"
                                value={formdata.firstname || ''}
                                onChange={(e) =>
                                  this.props.updateForm(
                                    'firstname',
                                    e.target.value,
                                    'contact',
                                  )
                                }
                                onBlur={() =>
                                  this.props.handleInputBlur('', 'contact')
                                }
                                placeholder="First Name"
                                type="text"
                                autoComplete="off"
                              />
                              {data.errors.firstname ? (
                                <span className="error_label d-block">
                                  {data.errors.firstname}
                                </span>
                              ) : null}
                            </div>
                          </FormGroup>
                          <FormGroup
                            className={`form-inline-label align-items-stretch ${
                              data.errors.lastname ? 'form-error' : ''
                            }`}
                          >
                            <Label
                              htmlFor="forLstName"
                              className="mt-1 pt-2 label-medium"
                            >
                              Last Name
                            </Label>
                            <div className="labelValue">
                              <Input
                                name="lastname"
                                value={formdata.lastname || ''}
                                onChange={(e) =>
                                  this.props.updateForm(
                                    'lastname',
                                    e.target.value,
                                    'contact',
                                  )
                                }
                                onBlur={() =>
                                  this.props.handleInputBlur('', 'contact')
                                }
                                placeholder="Last Name"
                                type="text"
                                autoComplete="off"
                              />
                              {data.errors.lastname ? (
                                <span className="error_label d-block">
                                  {data.errors.lastname}
                                </span>
                              ) : null}
                            </div>
                          </FormGroup>
                          <FormGroup
                            className={`form-inline-label align-items-stretch mb-1 ${
                              data.errors.email ? 'form-error' : ''
                            }`}
                          >
                            <Label
                              htmlFor="forEmail"
                              className="mt-1 pt-2 label-medium"
                            >
                              Email
                            </Label>
                            <div className="labelValue">
                              <Input
                                name="email"
                                value={formdata.email || ''}
                                onChange={(e) =>
                                  this.props.updateForm(
                                    'email',
                                    e.target.value,
                                    'contact',
                                  )
                                }
                                onBlur={() =>
                                  this.props.handleInputBlur(
                                    '',
                                    'contact',
                                    'email',
                                  )
                                }
                                placeholder="Email"
                                type="email"
                                autoComplete="off"
                              />
                              {data.errors.email ? (
                                <span className="error_label d-block">
                                  {data.errors.email}
                                </span>
                              ) : null}
                            </div>
                          </FormGroup>
                          {this.props.enablePlusIcon ? null : (
                            <div className="btn-wrap btn--leftspace p-0">
                              <Button
                                onClick={(e) => {
                                  this.props.handleCancel('contact', e);
                                  this.setState({
                                    dropdownOpenRecipent: false,
                                  });
                                }}
                                className="btn-link btn-sm label-medium px-0 btn btn-secondary mx-0"
                              >
                                <i className="icon-close2 label-small pr-1" />{' '}
                                Cancel
                              </Button>
                              <Button
                                onClick={(e) => {
                                  this.props.handleSave('contact', e);
                                  this.setState({
                                    dropdownOpenRecipent:
                                      Object.keys(data.errors).length >= 1
                                        ? true
                                        : false,
                                  });
                                }}
                                className="btn-link btn-sm label-medium px-0 btn btn-secondary mx-0"
                              >
                                <i className="icon-save label-small pr-1" />{' '}
                                {data.savedRecords && data.savedRecords.contact
                                  ? 'Saved'
                                  : 'Save'}
                              </Button>
                            </div>
                          )}
                        </Form>
                      </div>
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </div>
              <div className="inItem pos-rel dropdown">
                {data.savedRecords?.account ? (
                  <div className="dropdown-item">
                    <label htmlFor="seachAccount" className="label-medium">
                      Account
                    </label>
                    <span className="tag tag--fill mt-1">
                      {accountName.length > 18
                        ? `${accountName.substring(0, 18)}...`
                        : accountName}
                      <i
                        className="icon-close2 remove"
                        onClick={() =>
                          this.props.removeSavedRecord('accountInput')
                        }
                      />
                    </span>
                  </div>
                ) : formdata.accountId ? (
                  <div className="dropdown-item">
                    <label htmlFor="seachAccount" className="label-medium">
                      Account
                    </label>
                    <span className="tag tag--fill mt-1">
                      {selectedAccountName.length > 18
                        ? `${selectedAccountName.substring(0, 18)}...`
                        : selectedAccountName}
                      <i
                        className={`icon-close2 remove ${
                          data.fileUploadAccountSide ? 'hidden' : ''
                        }`}
                        onClick={() =>
                          this.props.handleAccountChange('accountId', null)
                        }
                      />
                    </span>
                  </div>
                ) : (
                  <div ref={this.dropdownAccountsSibling}>
                    <OptionBoxSelectFieldWithAvatar
                      name="seachAccount"
                      label="Account"
                      options={data.accounts}
                      value={formdata.accountId || { value: '', label: '' }}
                      inputValue={data.accountInput}
                      handleChange={(input) =>
                        this.props.handleAccountChange('accountId', input)
                      }
                      handleInputChange={this.props.handleAccountInput || null}
                      inputRenderer={(input) =>
                        this.inputRenderer(input, 'accountInput')
                      }
                      onBlurResetsInput={false}
                      onCloseResetsInput={false}
                      onSelectResetsInput={
                        data.accountInput && data.accountInput.length
                      }
                      placeholder="Select an account"
                    />{' '}
                  </div>
                )}
                <div ref={this.dropdownAccounts}>
                  <Dropdown
                    isOpen={this.state.dropdownOpenAccounts}
                    toggle={this.toggleAccounts}
                  >
                    <DropdownToggle
                      color="primary"
                      className={`rcpClick dropdown-toggle p-0 text-center ${accountPlusIcon}`}
                    >
                      <i className="pr-0 icon-plus" />
                    </DropdownToggle>
                    <DropdownMenu
                      id="fileAccountDropdown"
                      className="w-100 border-0"
                    >
                      <div className="dropdown-item py-0">
                        <Form id="form2" className="rcpForm w-100">
                          <FormGroup
                            className={`form-inline-label align-items-stretch ${
                              data.errors.accountname ? 'form-error' : ''
                            }`}
                          >
                            <Label
                              htmlFor="forActName"
                              className="mt-1 pt-2 label-medium"
                            >
                              Name
                            </Label>
                            <div className="labelValue">
                              <Input
                                name="accountname"
                                value={formdata.accountname || ''}
                                onChange={(e) =>
                                  this.props.updateForm(
                                    'accountname',
                                    e.target.value,
                                    'account',
                                  )
                                }
                                onBlur={() =>
                                  this.props.handleInputBlur('', 'account')
                                }
                                className=""
                                placeholder="Account Name"
                              />
                              {data.errors.accountname ? (
                                <span className="error_label d-block">
                                  {data.errors.accountname}
                                </span>
                              ) : null}
                            </div>
                          </FormGroup>
                          <SelectField
                            name="entity_type"
                            id="entity_type"
                            label="Entity Type"
                            className="form-group form-inline-label align-items-stretch mb-1 selectBorder"
                            labelClass="mt-1 pt-2 label-medium"
                            options={preferences.entity_type}
                            value={
                              formdata.entityType || { value: '', label: '' }
                            }
                            handleChange={(input) =>
                              this.props.updateForm(
                                'entityType',
                                input,
                                'account',
                              )
                            }
                            error={data.errors.entityType}
                            errorClass="error_label d-block"
                            placeholder="Select Entity Type"
                            onBlurResetsInput={false}
                            onCloseResetsInput={false}
                          />
                          <div className="btn-wrap btn--leftspace p-0">
                            <Button
                              onClick={(e) => {
                                this.props.handleCancel('account', e);
                                this.setState({ dropdownOpenAccounts: false });
                              }}
                              className="btn-link btn-sm label-medium px-0 btn btn-secondary mx-0"
                            >
                              <i className="icon-close2 label-small pr-1" />{' '}
                              Cancel
                            </Button>
                            <Button
                              onClick={(e) => {
                                this.props.handleSave('account', e);
                                this.setState({
                                  dropdownOpenAccounts:
                                    Object.keys(data.errors).length >= 1
                                      ? true
                                      : false,
                                });
                              }}
                              className="btn-link btn-sm label-medium px-0 btn btn-secondary mx-0"
                            >
                              <i className="icon-save label-small pr-1" />{' '}
                              {data.savedRecords && data.savedRecords.account
                                ? 'Saved'
                                : 'Save'}
                            </Button>
                          </div>
                        </Form>
                      </div>
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </div>
              <div className="btn-wrap btn--leftspace">
                <Button
                  onClick={(e) => this.props.handleCancel('all', e)}
                  className="btn-link btn-sm btn--onlyicon"
                >
                  Close
                </Button>
                <Button
                  onClick={(e) => this.props.handleSubmit(e)}
                  className="btn btn-sm btn-primary"
                >
                  Ok
                </Button>
              </div>
            </DropdownMenu>
          ) : null}
        </div>
        <span className="error_label">
          {data.errors.toField ? data.errors.toField : ''}
        </span>
      </FormGroup>
    );
  }
}

export default FileRecipientCreation;
