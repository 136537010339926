// eslint-disable-next-line default-param-last
export default function accountCardReducer(state = {}, action) {
  switch (action.type) {
    case 'STORE_ACCOUNT_CARD_DATA':
      return { ...state, [action.data.id]: action.data };

    default:
      return state;
  }
}
