import React from 'react';
import { Divider, List, ListItem } from '@mui/material';
import { Link } from 'react-router-dom-latest';
import { IntegrationModuleWithStatus } from 'components/Integrations/integration-modules/integration-module.types';

export interface IntegrationModuleListProps {
  modules: IntegrationModuleWithStatus[];
}

export const IntegrationModuleList = ({
  modules,
}: IntegrationModuleListProps) => (
  <List className="tRow tRow--body">
    <Divider />
    {modules.map((module) => (
      <ListItem key={module.id} className="row">
        <Link
          to={`${module.path}`}
          style={{ display: 'block', width: '100%', padding: 0 }}
        >
          {module.listComponent}
        </Link>
      </ListItem>
    ))}
  </List>
);
