import { Box, Typography } from '@mui/material';

interface LabelProps {
  text: string;
  color: string;
  children?: React.ReactNode;
  sx?: any;
}

export default function Label({ text, color, children, sx }: LabelProps) {
  return (
    <Box
      padding="6px"
      borderRadius="2px"
      border={`1px solid ${color}`}
      display="inline-block"
      bgcolor="transparent"
      sx={sx}
    >
      <Typography
        color={color}
        variant="subtitle2"
        sx={{
          leadingTrim: 'both',
          textEdge: 'cap',
          fontFeatureSettings: '"clig" off, "liga" off',
          lineHeight: '100%',
        }}
      >
        {text}
        {children}
      </Typography>
    </Box>
  );
}
