import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip';
import styled from '@emotion/styled';

const SArrowButtons = styled.div`
  display: inline-block;
  margin-right: 10px;
`;

const SArrowLeftRight = styled('button')(() => ({
  fontSize: '28px',
  padding: 0,
  background: 'transparent',
  borderColor: 'transparent',
  verticalAlign: 'middle',

  i: { color: '#dfe3e8' },
  '&:hover': { i: { color: '#00aecc' } },
}));

function FilePreviewNextPrevious({
  previewAllDocuments,
  selectedDocument,
  handleNextPrevPreview,
}) {
  const selelctedDocumentIndex = previewAllDocuments.findIndex(
    (item) => item.id === selectedDocument.id,
  );
  const [activeIndex, setActiveIndex] = useState(selelctedDocumentIndex);
  const activeIndexNo = activeIndex + 1;

  function nextFilePreview(index) {
    setActiveIndex(index);
    handleNextPrevPreview(index);
  }

  function next() {
    const nextIndex =
      activeIndex === previewAllDocuments.length - 1 ? 0 : activeIndex + 1;
    nextFilePreview(nextIndex);
  }

  function previous() {
    const nextIndex =
      activeIndex === 0 ? previewAllDocuments.length - 1 : activeIndex - 1;
    nextFilePreview(nextIndex);
  }

  return (
    <SArrowButtons>
      <SArrowLeftRight
        type="button"
        onClick={previous}
        data-tip
        data-for="icon-leftarrow"
      >
        <i className="icon-leftarrow" aria-hidden="true" />
        <ReactTooltip id="icon-leftarrow" effect="solid">
          <span>Previous</span>
        </ReactTooltip>
      </SArrowLeftRight>
      <span>
        {activeIndexNo}/{previewAllDocuments.length}
      </span>
      <SArrowLeftRight
        type="button"
        onClick={next}
        data-tip
        data-for="icon-rightarrow"
      >
        <i className="icon-rightarrow" aria-hidden="true" />
        <ReactTooltip id="icon-rightarrow" effect="solid">
          <span>Next</span>
        </ReactTooltip>
      </SArrowLeftRight>
    </SArrowButtons>
  );
}

export default FilePreviewNextPrevious;
