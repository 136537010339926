import {
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { HeaderGroup, flexRender } from '@tanstack/react-table';

interface Props {
  getHeaderGroups: () => HeaderGroup<SMSLine>[];
}

export function SMSAdminTableHeader({ getHeaderGroups }: Props) {
  const theme = useTheme();

  return (
    <TableHead>
      {getHeaderGroups().map((headerGroup) => (
        <TableRow key={headerGroup.id}>
          {headerGroup.headers.map((header) => (
            <TableCell key={header.id}>
              <Typography
                variant="body2"
                fontWeight={700}
                color={theme.palette.common.midGray}
              >
                {flexRender(
                  header.column.columnDef.header,
                  header.getContext(),
                )}
              </Typography>
            </TableCell>
          ))}
        </TableRow>
      ))}
    </TableHead>
  );
}
