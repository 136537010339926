import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import Axios from 'axios';
import { sortingIcon, sortList, AlertMessage } from 'utilities/utils';
import { GET_ALL_EMPLOYEES, CREATE_ADOBE_ACCOUNT } from 'constants/constants';

const tableHeaders = [
  {
    value: 'last_name',
    label: 'Employee Name',
    isSortable: true,
    className: ' col-4 thCol',
  },
  {
    value: 'email',
    label: 'Email',
    isSortable: true,
    className: ' col thCol',
  },
];

export default class AdminList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      sortBy: 'last_name',
      sortOrder: 'desc',
    };
  }

  componentDidMount() {
    Axios.get(`${GET_ALL_EMPLOYEES}?role=firm_admin`).then((res) => {
      if (res.status === 200 && res.data && res.data.data) {
        const employees = res.data.data.filter(
          (e) => !e.adobe_user_record_exists,
        );
        const { sortBy } = this.state;
        this.setState({
          listData: sortList(employees, sortBy, 'asc'),
          loading: false,
        });
      } else {
        this.setState({ loading: false });
      }
    });
  }

  handleSelect = (input) => {
    this.setState({ selectedEmployeeId: input });
  };

  createAdobeAccount = () => {
    const { selectedEmployeeId } = this.state;
    if (selectedEmployeeId) {
      this.setState({ loading: true });
      Axios.post(`${CREATE_ADOBE_ACCOUNT}/${selectedEmployeeId}`).then(
        (res) => {
          if (res.status === 200 && res.data.status) {
            this.setState({
              status: res.data.status,
              message: res.data.message,
              loading: false,
            });
          } else {
            this.setState({ loading: false });
          }
        },
      );
    } else {
      AlertMessage('error', 'Please select employee', 3000);
    }
  };

  handleSortBy = (sortBy) => {
    let { sortOrder } = this.state;
    const { listData } = this.state;
    if (this.state.sortBy === sortBy) {
      if (sortOrder === 'asc') {
        sortOrder = 'desc';
      } else {
        sortOrder = 'asc';
      }
    } else {
      sortOrder = 'asc';
    }
    this.setState({
      sortOrder,
      sortBy,
      listData: sortList(listData, sortBy, sortOrder),
    });
  };

  render() {
    const { handleCloseBtn } = this.props;
    const { listData, status, message } = this.state;
    if (status) {
      return (
        <Modal
          isOpen
          className="customModal customModal--center customModal--alert customModal--w600 customModal--nopadding"
        >
          <ModalHeader className="ModalHeader">
            <button
              type="button"
              onClick={() => handleCloseBtn()}
              className="close"
              aria-label="Close"
            >
              <i className="icon-close2" aria-hidden="true" />
            </button>
          </ModalHeader>
          <ModalBody className="modalContent">
            <div className="modalContent--inner pb-0">
              <div className="modalContent--header text-large">
                <h4 className="modalContent--header__heading">{message}</h4>
              </div>
            </div>
            <div className="row pt-0">
              <div className="col-12 justify-content-end formbtn justify-content-center mb-4">
                <div className="btn-wrap">
                  <Button
                    className="btn btn-primary"
                    onClick={() => handleCloseBtn(status === 200)}
                  >
                    Ok
                  </Button>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      );
    }
    return (
      <Modal
        isOpen
        className="customModal customModal--center customModal--alert customModal--w600 customModal--nopadding"
      >
        <ModalHeader className="ModalHeader">
          <button
            type="button"
            onClick={() => handleCloseBtn()}
            className="close"
            aria-label="Close"
          >
            <i className="icon-close2" aria-hidden="true" />
          </button>
        </ModalHeader>
        <ModalBody className="modalContent">
          {this.state.loading ? <div id="loading" /> : null}
          <div className="modalContent--inner">
            <div className="modalContent--header text-large">
              <h4 className="modalContent--header__heading">Employee List</h4>
            </div>
            <div className="tab-content">
              <div className="tab-pane active" id="review">
                <div id="inbox-list" className="tableWrap">
                  <div className="tRow tRow--head">
                    <div className="row">
                      {tableHeaders.map((each) => (
                        <div
                          key={each.value}
                          className={
                            sortingIcon(
                              this.state.sortBy,
                              each.value,
                              this.state.sortOrder,
                              'className',
                            ) +
                            each.className +
                            (!each.isSortable ? ' cursor-default' : '')
                          }
                          onClick={() =>
                            each.isSortable
                              ? this.handleSortBy(each.value)
                              : null
                          }
                        >
                          <span
                            className={
                              each.isSortable
                                ? 'posRel'
                                : 'posRel cursor-default'
                            }
                          >
                            {each.label}
                            {each.isSortable && (
                              <i
                                className={sortingIcon(
                                  this.state.sortBy,
                                  each.value,
                                  this.state.sortOrder,
                                )}
                              />
                            )}
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div
                    className="tRow tRow--body"
                    style={{ height: '60vh', overflow: 'scroll' }}
                  >
                    {listData &&
                      listData.length &&
                      listData.map((each) => (
                        <div key={each.id + each.role} className="tdBtn row">
                          <div className="col-4 tdCol">
                            <span>
                              <input
                                type="radio"
                                className="mr-1"
                                name="adobe_admin_employee"
                                id={each.id}
                                onChange={() => this.handleSelect(each.id)}
                              />
                              <label
                                htmlFor={each.id}
                                className="font-weight-normal"
                              >
                                {`${each.last_name}, ${each.first_name} ${
                                  each.middle_name && each.middle_name !== null
                                    ? each.middle_name
                                    : ''
                                }`}
                              </label>
                            </span>
                          </div>
                          <div className="col tdCol">
                            <span>
                              <label
                                htmlFor={each.id}
                                className="font-weight-normal"
                              >
                                {each.email ? each.email : '-'}
                              </label>
                            </span>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 justify-content-end formbtn justify-content-end">
                <div className="btn-wrap btn--leftspace">
                  <Button
                    className="btn btn-outline-light"
                    onClick={() => handleCloseBtn()}
                  >
                    Close
                  </Button>
                  <Button
                    className="btn btn-primary"
                    onClick={this.createAdobeAccount}
                  >
                    Create User
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}
