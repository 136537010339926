import axios from 'axios';
import { SEARCH_TASK_ACCOUNTS } from 'constants/constants';

export interface EncryptedFieldInfo {
  populated?: boolean;
}

export interface AccountData {
  id: number;
  is_active: boolean;
  cpa_id: number;
  name: string;
  status: string;
  stage: string;
  entity_type: string;
  rating?: string;
  email?: string;
  phone_number: string;
  fax_number?: string;
  website: string;
  referral_source?: string | null;
  address_line_1: string;
  address_line_2?: string;
  address_city: string;
  address_state: string | null;
  address_zip: string;
  address_country: string;
  in_curr_filling_status?: string | null;
  in_taxpayer_self_or_spouse?: string;
  in_self_ssn?: string | null;
  in_self_birthdate?: string;
  in_self_driver_license?: string;
  in_self_license_issue_date?: string;
  in_self_license_expiry_date?: string;
  in_self_license_state?: string | null;
  in_sps_driver_license?: string;
  in_sps_license_issue_date?: string;
  in_sps_license_expiry_date?: string;
  in_sps_license_state?: string | null;
  in_sps_ssn?: string | null;
  in_sps_first_name?: string;
  in_sps_middle_name?: string;
  in_sps_last_name?: string;
  in_sps_birthdate?: string;
  in_sps_address_line_1?: string;
  in_sps_address_line_2?: string;
  in_sps_address_city?: string;
  in_sps_address_state?: string | null;
  in_sps_address_zip?: string;
  in_sps_address_country?: string;
  in_bank_name?: string;
  in_bank_account_name?: string;
  in_bank_account_number?: string;
  in_bank_account_type?: string;
  in_bank_routing_number?: string;
  co_ein: string;
  co_industry: string | null;
  co_number_of_employees: number;
  co_fiscal_yearend: string | null;
  co_start_date?: string;
  co_prior_accountant: string;
  co_biggest_challenges: string;
  co_expectations: string;
  co_biz_decision_tools_used: string;
  co_tech_accounting: string;
  co_tech_expenses: string;
  co_tech_time_trackings: string;
  co_tech_payroll: string;
  co_tech_billpay: string;
  co_ach_account_name?: string;
  co_ach_account_number: string;
  co_ach_routing_number?: string;
  co_ach_account_type?: string;
  co_payroll_bank_name?: string;
  co_payroll_account_name?: string;
  co_payroll_account_number?: string;
  co_payroll_routing_number?: string;
  co_payroll_account_type?: string;
  co_bank_name?: string;
  co_bank_username?: string;
  co_bank_password?: string;
  co_cred_bank_name?: string;
  co_cred_username?: string;
  co_cred_password?: string;
  co_pay_freq?: string | null;
  co_fed_eftps_pin: string;
  co_fed_eftps_pwd: string;
  co_payroll_state: string | null;
  co_state_withholding_id?: string;
  co_state_unemployement_id?: string;
  created_at: string;
  updated_at: string;
  co_ach_bank_name?: string;
  co_is_pay_hourly: boolean;
  co_is_pay_salary: boolean;
  co_is_pay_reimbursement: boolean;
  co_is_pay_bonus: boolean;
  co_is_ded_401k: boolean;
  co_is_ded_401k_roth: boolean;
  co_is_ded_sample_ira: boolean;
  co_is_ded_health: boolean;
  co_is_ded_garnishments: boolean;
  co_is_accrual_vataion: boolean;
  co_is_accrual_sick: boolean;
  co_ach_is_autopay_confirmed: boolean;
  co_state_ui_base_rate: number;
  external_account_id?: string;
  external_source?: string;
  created_by: string;
  updated_by: string;
  taxpayer_first_name?: string;
  taxpayer_middle_name?: string;
  taxpayer_last_name?: string;
  in_ach_is_autopay_confirmed?: string;
  in_bank_website?: string;
  in_notes?: string;
  co_ach_bank_website?: string;
  co_ach_notes?: string;
  is_restricted_account: boolean;
  is_ach_payment_allowed: boolean;
  is_cc_payment_allowed: boolean;
  encrypted_co_ein?: EncryptedFieldInfo;
  encrypted_in_sps_ssn?: EncryptedFieldInfo;
  encrypted_in_self_ssn?: EncryptedFieldInfo;
  encrypted_co_fed_eftps_pin?: EncryptedFieldInfo;
  encrypted_co_fed_eftps_pwd?: EncryptedFieldInfo;
  encrypted_co_state_withholding_id?: EncryptedFieldInfo;
  encrypted_co_state_unemployement_id?: EncryptedFieldInfo;
}

export type TaskAccountType = {
  label: string;
  uuid: number;
  value?: number;
};

export async function accountSearchByKeyword(keyword: string) {
  const { data } = await axios.get(
    `${SEARCH_TASK_ACCOUNTS}/?keyword=${keyword}`,
  );

  return (data.length > 0 ? data : []) as TaskAccountType[];
}
