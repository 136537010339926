/* eslint-disable no-script-url */
/* eslint-disable react/jsx-no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable consistent-return */
/* eslint-disable react/no-access-state-in-setstate */
import moment from 'moment';
import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import Axios from 'axios';
import { GET_RECURRING_EXPECTED_DUE_DATE } from 'constants/constants';
import { labelNamebyValue } from 'utilities/utils';
import Select from 'react-select';
import { selectTheme } from 'common/CustomFields';
import { monthsWithShortNames } from '../../Accounts/AccountCreation/AccountUtilities';

const SelectStyles = {
  control: (styles, { isFocused }) => ({
    ...styles,
    borderRadius: '2px',
    width: '50px',
    height: '30px',
    minHeight: '0',
    border: isFocused ? '2px solid #919eab' : '2px solid #dfe3e8',
    boxShadow: 'none',
    '&:hover': {
      borderColor: 'hsl(0, 0%, 70%)',
      boxShadow: 'none',
    },
  }),
  indicatorContainer: (styles) => ({ ...styles, display: 'none' }),
  indicatorsContainer: (styles) => ({ ...styles, display: 'none' }),
  valueContainer: (styles) => ({ ...styles, alignItems: 'flex-start' }),
  input: (styles) => ({ ...styles, cursor: 'pointer' }),
  menu: (provided) => ({
    ...provided,
    marginTop: '0px',
    border: '2px solid #919eab',
    outlineStyle: 'none',
    boxShadow: 'none',
    width: '50px',
    height: '150px',
    cursor: 'pointer',
  }),
  menuList: (provided) => ({
    ...provided,
    height: '150px',
    '::-webkit-scrollbar': {
      display: 'none',
    },
  }),
};

const recurring_types = ['Weekly', 'Monthly', 'Yearly'];
const week_days = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
const due_on_weekdays = [
  'due_on_mon',
  'due_on_tue',
  'due_on_wed',
  'due_on_thu',
  'due_on_fri',
  'due_on_sat',
  'due_on_sun',
];
const generateOptions = (range) => {
  const options = [];
  for (let i = 1; i <= range; i += 1) {
    options.push({ value: i, label: i });
  }
  return options;
};

export default class RecurringSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false,
      weekdayIntervalOptions: generateOptions(12),
      monthIntervalOptions: generateOptions(12),
      daysOptions: generateOptions(31),
      is_recurring: this.props.is_recurring,
      frequency: 'Weekly',
      recurring_interval: 1,
      recurring_end_date: null,
      due_on_day_of_month: 1,
      due_on_month: 1,
      due_on_mon: false,
      due_on_tue: false,
      due_on_wed: true,
      due_on_thu: false,
      due_on_fri: false,
      due_on_sat: false,
      due_on_sun: false,
      parent_task_id: null,
      on_schedule: false,
      errors: {},
    };
    this.initialState = this.state;
    this.resetState = this.state;
  }

  componentDidMount() {
    this.updateStateData();
  }

  UNSAFE_componentWillReceiveProps() {
    if (!this.state.received_data) {
      this.updateStateData();
    }
  }

  updateStateData = () => {
    if (this.props.taskDetail && this.props.taskDetail.is_recurring) {
      const {
        is_recurring,
        frequency,
        recurring_interval,
        recurring_end_date,
        due_on_day_of_month,
        due_on_month,
        due_on_mon,
        due_on_tue,
        due_on_wed,
        due_on_thu,
        due_on_fri,
        due_on_sat,
        due_on_sun,
        parent_task_id,
        expected_due_date,
        on_schedule,
      } = this.props.taskDetail;
      this.setState(
        {
          is_recurring,
          frequency,
          recurring_interval,
          recurring_end_date:
            recurring_end_date && recurring_end_date !== null
              ? new Date(moment(recurring_end_date))
              : null,
          due_on_day_of_month,
          due_on_month,
          due_on_mon,
          due_on_tue,
          due_on_wed,
          due_on_thu,
          due_on_fri,
          due_on_sat,
          due_on_sun,
          parent_task_id,
          expected_due_date,
          received_data: true,
          on_schedule,
        },
        () => {
          this.initialState = this.state;
        },
      );
    }
  };

  showRecurringOptions = () => {
    this.setState(
      {
        ...this.initialState,
        showMenu: !this.state.showMenu,
        errors: {},
      },
      () => {
        this.props.stateChange(
          {
            recurring_properties: {
              ...this.state,
              recurringDropdown: this.state.showMenu,
            },
          },
          true,
        );
      },
    );
  };

  handleFrequency = (input) => {
    if (input !== this.state.frequency) {
      let {
        recurring_interval,
        due_on_day_of_month,
        due_on_month,
        due_on_mon,
        due_on_tue,
        due_on_wed,
        due_on_thu,
        due_on_fri,
        due_on_sat,
        due_on_sun,
      } = this.state;
      recurring_interval = 1;
      if (input === 'Weekly') {
        due_on_wed = true;
        due_on_month = null;
        due_on_day_of_month = null;
      }
      if (input === 'Monthly') {
        due_on_mon = false;
        due_on_tue = false;
        due_on_wed = false;
        due_on_thu = false;
        due_on_fri = false;
        due_on_sat = false;
        due_on_sun = false;
        due_on_month = null;
        due_on_day_of_month = 1;
      } else if (input === 'Yearly') {
        due_on_day_of_month = 1;
        due_on_month = 1;
      }
      this.setState({
        frequency: input,
        recurring_interval,
        due_on_day_of_month,
        due_on_month,
        due_on_mon,
        due_on_tue,
        due_on_wed,
        due_on_thu,
        due_on_fri,
        due_on_sat,
        due_on_sun,
        errors: {},
      });
    }
  };

  handleDay = (input) => {
    this.setState({
      [input]: !this.state[input],
      errors: {},
    });
  };

  handleDayofMonth = ({ value: input }) => {
    this.setState({ due_on_day_of_month: input });
  };

  handleMonth = ({ value: input }) => {
    const { due_on_day_of_month } = this.state;
    this.setState({ due_on_month: input });
    if ((input < 8 && input % 2 !== 0) || (input > 7 && input % 2 === 0)) {
      this.setState({ daysOptions: generateOptions(31) });
    } else if (input === 2) {
      this.setState({
        daysOptions: generateOptions(28),
        due_on_day_of_month: due_on_day_of_month > 28 ? 1 : due_on_day_of_month,
      });
    } else {
      this.setState({
        daysOptions: generateOptions(30),
        due_on_day_of_month:
          due_on_day_of_month === 31 ? 1 : due_on_day_of_month,
      });
    }
  };

  handleInterval = ({ value: input }) => {
    this.setState({ recurring_interval: input });
  };

  handleEndDate = (input) => {
    this.setState({ recurring_end_date: input });
  };

  handleReccurSchedule = (input) => {
    this.props.updateIsRecurring(input);
    console.log('Handle Recurring state', input);
    this.setState({ on_schedule: input });
  };

  handleDoneBtn = () => {
    let { recurring_end_date } = this.state;
    const {
      frequency,
      errors,
      parent_task_id,
      expected_due_date: due_date,
    } = this.state;
    const weekdaySelected = this.validateWeekday();
    if (frequency.toLowerCase() === 'weekly' && !weekdaySelected) {
      errors.weekly = 'Please select a weekday';
      this.setState({ errors });
      return false;
    }
    recurring_end_date =
      recurring_end_date && recurring_end_date !== null
        ? moment(recurring_end_date).format()
        : null;
    this.setState(
      {
        is_recurring: true,
        dataSaved: true,
        showMenu: false,
        errors: {},
      },
      () => {
        this.initialState = this.state;
        this.props.stateChange(
          {
            recurring_properties: {
              ...this.state,
              recurring_end_date,
              recurringDropdown: false,
            },
          },
          true,
        );
      },
    );
    const data = {
      ...this.state, // recurring_end_date,
      user_local_time: moment().format('DD/MM/YYYY'),
      current_expected_due_date:
        parent_task_id && parent_task_id !== null
          ? moment(due_date, 'YYYY-MM-DD').format('DD/MM/YYYY')
          : moment().format('DD/MM/YYYY'),
    };
    Axios.post(GET_RECURRING_EXPECTED_DUE_DATE, data).then((res) => {
      if (res.data.status === 200) {
        let { expected_due_date } = res.data;
        expected_due_date = moment(expected_due_date);
        this.props.handleDateChange(expected_due_date);
      }
    });
  };

  handleCancelBtn = () => {
    this.setState(
      {
        ...this.initialState,
        showMenu: false,
        errors: {},
      },
      () => {
        this.props.stateChange(
          { recurring_properties: { ...this.state, recurringDropdown: false } },
          true,
        );
      },
    );
  };

  handleDelRecurring = () => {
    this.props.updateIsRecurring(false);
    this.initialState = this.resetState;
    this.setState(this.resetState);
    this.props.handleDateChange(this.props.initialDate, 'delete'); // reset date to initially set date.
    this.props.stateChange(
      {
        recurring_properties: { ...this.resetState, recurringDropdown: false },
      },
      true,
    );
  };

  validateWeekday() {
    let weekdaySelected = false;
    due_on_weekdays.map((e) => {
      if (this.state[e] === true) {
        weekdaySelected = true;
      }
      return weekdaySelected;
    });
    return weekdaySelected;
  }

  render() {
    const {
      frequency,
      showMenu,
      weekdayIntervalOptions,
      monthIntervalOptions,
      recurring_interval,
      due_on_day_of_month,
      due_on_month,
      daysOptions,
      recurring_end_date,
      is_recurring,
      errors,
    } = this.state;
    const { propData } = this.props;
    const viewContext = propData.viewContext ? 'dropdown-menu-right' : '';
    return (
      <div
        className={`dropdown d-flex align-items-center ${
          showMenu ? 'open' : ''
        }`}
        id="recurringDropdown"
        data-testid="recurringDropdown"
      >
        <button
          type="button"
          className="text-body btn btn-link p-0"
          id="taskRecurringDropdown"
          onClick={() => this.showRecurringOptions()}
          aria-haspopup="true"
          aria-expanded={showMenu ? 'true' : 'false'}
        >
          <i
            className={`icon-Recurring pr-1 vertical-middle ${
              is_recurring ? 'text-primary' : 'text-light'
            }`}
          />
          {is_recurring ? `Recurs ${frequency}` : 'Set Recurring'}
        </button>
        <div
          className={
            showMenu
              ? `dropdown-menu ${viewContext} recurring-dropdown show`
              : `dropdown-menu ${viewContext} recurring-dropdown`
          }
          aria-labelledby="taskRecurringDropdown"
          style={{ width: '350px', right: 0, left: 'inherit' }}
        >
          <div className="dropdown-item">
            <div
              className="btn-group segmentBtn"
              role="group"
              aria-label="recurring type"
            >
              {recurring_types.map((e) => (
                <button
                  key={e}
                  type="button"
                  className={`btn-sm btn text-uppercase ${
                    frequency === e
                      ? 'active btn-primary text-white'
                      : 'btn-outline-light'
                  }`}
                  onClick={() => this.handleFrequency(e)}
                  data-testid={`recurringOption-${e}`}
                >
                  {e}
                </button>
              ))}
            </div>
            {frequency && frequency.toLowerCase() === 'weekly' && (
              <div
                className="btn-group segmentBtn mt-3"
                role="group"
                aria-label="selected weekdays"
              >
                {week_days.map((e) => (
                  <button
                    key={e}
                    type="button"
                    className={`btn-sm btn text-uppercase ${
                      this.state[`due_on_${e}`]
                        ? 'active btn-primary text-white'
                        : 'btn-outline-light'
                    }`}
                    onClick={() => this.handleDay(`due_on_${e}`)}
                    data-testid={`weeklyDayButton-${e}`}
                  >
                    {e.slice(0, 2)}
                  </button>
                ))}
              </div>
            )}
            {frequency && frequency.toLowerCase() === 'monthly' && (
              <span className="mt-3 d-flex text-body">
                On:&nbsp;&nbsp;
                <Select
                  id="due_on_day_of_month"
                  options={daysOptions}
                  value={{
                    value: due_on_day_of_month,
                    label: due_on_day_of_month,
                  }}
                  onChange={this.handleDayofMonth}
                  styles={SelectStyles}
                  theme={selectTheme}
                />
                &nbsp;&nbsp;day of the month
              </span>
            )}
            {frequency &&
              (frequency.toLowerCase() === 'weekly' ||
                frequency.toLowerCase() === 'monthly') && (
                <span className="mt-3 d-flex text-body">
                  Interval: every&nbsp;&nbsp;
                  <Select
                    id="monthdays"
                    options={
                      frequency === 'weekly'
                        ? weekdayIntervalOptions
                        : monthIntervalOptions
                    }
                    value={{
                      value: recurring_interval,
                      label: recurring_interval,
                    }}
                    onChange={this.handleInterval}
                    styles={SelectStyles}
                    theme={selectTheme}
                  />{' '}
                  &nbsp;&nbsp;
                  {frequency && frequency.toLowerCase() === 'weekly'
                    ? 'week'
                    : 'month'}
                </span>
              )}
            {frequency && frequency.toLowerCase() === 'yearly' && (
              <span className="mt-3 d-flex text-body">
                On:&nbsp;&nbsp;
                <Select
                  options={daysOptions}
                  id="monthdays"
                  value={{
                    value: due_on_day_of_month,
                    label: due_on_day_of_month,
                  }}
                  onChange={this.handleDayofMonth}
                  styles={SelectStyles}
                  theme={selectTheme}
                />
                &nbsp;&nbsp;day of the month&nbsp;&nbsp;
                <Select
                  options={monthsWithShortNames}
                  id="month"
                  value={{
                    value: due_on_month,
                    label: labelNamebyValue(due_on_month, monthsWithShortNames),
                  }}
                  onChange={this.handleMonth}
                  styles={SelectStyles}
                  theme={selectTheme}
                />
              </span>
            )}
            <div className="mt-4 text-body">
              <span>End:</span>
            </div>
            <div className="radio-container text-body pt-2">
              <label htmlFor="exampleRadios1">
                <input
                  type="radio"
                  name="exampleRadios"
                  id="exampleRadios1"
                  value="option1"
                  onChange={() => this.handleEndDate(null)}
                  checked={recurring_end_date === null}
                  data-testid="radioNever"
                />
                <i className="checkmark" />
                <span className="pl-2 text-body text-transform-none">
                  Never
                </span>
              </label>
            </div>
            <div className="pt-2">
              <span className="radio-container text-body">
                <label htmlFor="exampleRadios2" style={{ minWidth: '0' }}>
                  <input
                    type="radio"
                    name="exampleRadios"
                    id="exampleRadios2"
                    value="option2"
                    checked={recurring_end_date}
                    data-testid="radioEndDate"
                  />
                  <i className="checkmark" />
                  <span className="pl-2 text-body text-transform-none">On</span>
                </label>
              </span>
              <div className="date-text date-calender date-calender-icon d-inline-block">
                <i
                  className="icon-calendar vertical-middle text-dark"
                  aria-hidden="true"
                />
                <DatePicker
                  selected={recurring_end_date}
                  popoverTargetAttachment="right center"
                  onChange={(input) => this.handleEndDate(input)}
                  monthsShown={1}
                  minDate={new Date()}
                  dateForm="MM/DD/YYYY"
                  data-testid="endDatePicker"
                />
              </div>
            </div>
            <div className="mt-4 text-body">
              <span>Recur:</span>
            </div>
            <div className="p-2 border">
              <span className="radio-container text-body">
                <label htmlFor="exampleRadios3">
                  <input
                    type="radio"
                    name="recurringSchecule"
                    id="exampleRadios3"
                    value="option3"
                    onChange={() => this.handleReccurSchedule(true)}
                    checked={this.state.on_schedule}
                    data-testid="radioOnSchedule"
                  />
                  <i className="checkmark" />
                  <span className="pl-2 text-body text-transform-none">
                    On Schedule
                  </span>
                </label>
              </span>
              <span className="radio-container text-body">
                <label htmlFor="exampleRadios4">
                  <input
                    type="radio"
                    name="recurringSchecule"
                    id="exampleRadios4"
                    value="option4"
                    onChange={() => this.handleReccurSchedule(false)}
                    defaultChecked
                    checked={!this.state.on_schedule}
                    data-testid="radioWhenCompleted"
                  />
                  <i className="checkmark" />
                  <span className="pl-2 text-body text-transform-none">
                    When Completed
                  </span>
                </label>
              </span>
            </div>

            <div className="my-3">
              <button
                type="button"
                className="btn-sm btn btn-primary mr-2"
                onClick={this.handleDoneBtn}
                data-testid="btnDone"
              >
                Done
              </button>
              <button
                type="button"
                onClick={this.handleCancelBtn}
                className="btn-sm btn btn-primary"
                data-testid="btnCancel"
              >
                Cancel
              </button>
              {errors && errors.weekly ? (
                <span className="pl-2 text-danger">{errors.weekly}</span>
              ) : null}
            </div>
            {is_recurring ? (
              <div>
                <span
                  onClick={this.handleDelRecurring}
                  className="pl-2 mb-1 text-danger pointer"
                  data-testid="btnDeleteRecurring"
                >
                  Delete Recurring
                </span>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}
