import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { getEncryptedField, updateEncryptedField } from 'api/encrypted-fields';

export function useEncryptedField({
  recordType,
  recordId,
  fieldName,
  enabled,
}: {
  recordType: string;
  recordId: string | number;
  fieldName: string;
  enabled: boolean;
}) {
  return useQuery({
    queryKey: ['encryptedField', recordType, String(recordId), fieldName],
    queryFn: () => getEncryptedField({ recordType, recordId, fieldName }),
    cacheTime: 0,
    staleTime: 0,
    enabled,
  });
}

export function useUpdateEncryptedField(props?: { onSuccess?: () => void }) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateEncryptedField,
    onSuccess: () => {
      queryClient.removeQueries({ queryKey: ['encryptedField'] });
      if (props?.onSuccess) {
        props.onSuccess();
      }
    },
  });
}
