import React from 'react';
import styled from '@emotion/styled';
import NoRecords from 'common/NoRecords';

const StyledDiv = styled.div`
  margin-top: '120px';
`;

const EmailsAccessPermission = () => {
  document.title = 'EmailHasNoAccessPermission';

  return (
    <StyledDiv className="EmailHasNoAccessPermission">
      <NoRecords description="You do not have permission to view embedded email when logged in as another user." />
    </StyledDiv>
  );
};

export default EmailsAccessPermission;
