import React from 'react';
import Select from 'react-select';

const AllowedPaymentMethodsSelect = ({
  paymentMethodsOptions,
  selectStyles,
  handlePaymentMethodsChange,
  paymentMethods,
  invalidPaymentMethod,
  isPaymentAuthorized,
}) => (
  <div className="InvoiceForm__Field">
    <Select
      name="paymentMethods"
      id="paymentMethods"
      placeholder="Select payment method"
      className="Select"
      styles={selectStyles}
      options={paymentMethodsOptions}
      onChange={handlePaymentMethodsChange}
      value={paymentMethods}
      invalidValue={invalidPaymentMethod}
      isDisabled={isPaymentAuthorized}
      isSearchable
      isClearable
    />
    {invalidPaymentMethod && (
      <div className="InvoiceForm__Error">This is a required field</div>
    )}
  </div>
);

export default AllowedPaymentMethodsSelect;
