import { Box, LinearProgress } from '@mui/material';
import { GridRenderCellParams, GridTreeNodeWithRender } from '@mui/x-data-grid';
import { WorkflowRequestsWorkflowRequestFragment } from 'gql/graphql';
import { TooltipStyled } from 'ui/TooltipStyled/TooltipStyled';

type PercentCompleteCellProps = {
  params: GridRenderCellParams<
    WorkflowRequestsWorkflowRequestFragment,
    any,
    any,
    GridTreeNodeWithRender
  >;
};
export const PercentCompleteCell = ({ params }: PercentCompleteCellProps) => (
  <Box display="flex" alignItems="center" width="100%">
    <Box flexGrow={1}>
      <TooltipStyled title={`${params.value}%`}>
        <LinearProgress value={params.value as number} variant="determinate" />
      </TooltipStyled>
    </Box>
  </Box>
);
