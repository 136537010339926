import { convertFromHTML } from 'draft-convert';

export const colorStyleMap = {
  white: { color: '#FFFFFF' },
  black: { color: '#010101' },
  red: { color: '#DE3616' },
  orange: { color: '#FF7F00' },
  yellow: { color: '#EDC200' },
  green: { color: '#1A9C46' },
  blue: { color: '#00AECC' },
  indigo: { color: '#4B0082' },
  violet: { color: '#7F00FF' },
};

export const bgColorStyleMap = {
  whiteBackground: { backgroundColor: '#FFFFFF' },
  blackBackground: { backgroundColor: '#010101' },
  redBackground: { backgroundColor: '#DE3618' },
  orangeBackground: { backgroundColor: '#FF7F00' },
  yellowBackground: { backgroundColor: '#EDC200' },
  greenBackground: { backgroundColor: '#20D193' },
  blueBackground: { backgroundColor: '#00AECC' },
  indigoBackground: { backgroundColor: '#4B0082' },
  violetBackground: { backgroundColor: '#7F00FF' },
};

export const heightMap = {
  1: { lineHeight: '1' },
  1.15: { lineHeight: '1.15' },
  1.5: { lineHeight: '1.5' },
  2: { lineHeight: '2' },
  first: {
    width: '100%',
    color: '#fff',
    textAlign: 'center',
    height: '20px',
  },
  second: {
    width: '100%',
    color: '#fff',
    textAlign: 'center',
    height: '20px',
  },
  third: {
    width: '100%',
    color: '#fff',
    textAlign: 'center',
    height: '20px',
  },
  fourth: {
    width: '100%',
    color: '#fff',
    textAlign: 'center',
    height: '20px',
  },
  Center: {
    textAlign: 'center',
    display: 'inline-block',
    width: '100%',
  },
  Left: {
    textAlign: 'left',
    display: 'inline-block',
    width: '100%',
  },
  Right: {
    textAlign: 'right',
    display: 'inline-block',
    width: '100%',
  },
};

export const options = {
  inlineStyles: {
    white: { style: { color: '#FFFFFF' } },
    orange: { style: { color: '#FF7F00' } },
    yellow: { style: { color: '#EDC200' } },
    green: { style: { color: '#1A9C46' } },
    blue: { style: { color: '#00AECC' } },
    indigo: { style: { color: '#4B0082' } },
    violet: { style: { color: '#7F00FF' } },
    red: { style: { color: '#DE3616' } },
    black: { style: { color: '#010101' } },
    whiteBackground: { style: { 'background-color': '#FFFFFF' } },
    blackBackground: { style: { 'background-color': '#010101' } },
    redBackground: { style: { 'background-color': '#DE3618' } },
    orangeBackground: { style: { 'background-color': '#FF7F00' } },
    yellowBackground: { style: { 'background-color': '#EDC200' } },
    greenBackground: { style: { 'background-color': '#20D193' } },
    blueBackground: { style: { 'background-color': '#00AECC' } },
    indigoBackground: { style: { 'background-color': '#4B0082' } },
    violetBackground: { style: { 'background-color': '#7F00FF' } },
    Center: {
      style: { 'text-align': 'center', display: 'inline-block', width: '100%' },
    },
    Left: {
      style: { 'text-align': 'left', display: 'inline-block', width: '100%' },
    },
    Right: { style: { 'text-align': 'right', display: 'inline-block' } },
  },
  defaultBlockTag: 'div',

  blockStyleFn: (block) => {
    const style = {};
    if (block.getType() === 'header-one') {
      return {
        style: {
          fontSize: '2em',
          margin: '0',
        },
      };
    }
    if (block.getType() === 'header-two') {
      return {
        style: {
          fontSize: '1.5em',
          margin: '0',
        },
      };
    }
    if (block.getType() === 'header-three') {
      return {
        style: {
          fontSize: '1.17em',
          margin: '0',
        },
      };
    }
    if (block.getType() === 'BOLD') {
      return { style: { fontWeight: 'bold' } };
    }
    if (block.getType() === 'ITALIC') {
      return { style: { fontStyle: 'italic' } };
    }
    if (block.getType() === 'UNDERLINE') {
      return { style: { textDecoration: 'underline' } };
    }
    if (block.getType() === 'align-center') {
      return {
        style: {
          textAlign: 'center',
          width: '100%',
        },
      };
    }
    if (block.getType() === 'align-left') {
      return {
        style: {
          textAlign: 'left',
          width: '100%',
        },
      };
    }
    if (block.getType() === 'align-right') {
      return {
        style: {
          textAlign: 'right',
          width: '100%',
        },
      };
    }
    if (block.getType() === 'unstyled') {
      return { style: { width: '100%' } };
    }
    if (block.getType() === 'image') {
      return { style: { width: '40%' } };
    }
    return style;
  },
  entityStyleFn: (entity) => {
    const entityType = entity.get('type').toLowerCase();
    const data = entity.getData();
    switch (entityType) {
      case 'link':
        return {
          element: 'a',
          attributes: { href: data.url, target: '_blank' },
        };
      case 'image':
        return {
          element: 'img',
          attributes: {
            src: data.src,
            'image-id': data.id,
            alt: data.alt,
          },
          style: {
            float: data.alignment ? data.alignment : 'left',
            width: data.width ? `${data.width}%` : '40%',
          },
        };
      default:
        return entity;
    }
  },
};

export const removeBreakElement = (input = '') => {
  let output = '';
  if (input) {
    output = input
      .replace(/<\/li><\/br><li>/g, '</li><li>')
      .replace(/<ul><\/br>/g, '<ul>')
      .replace(/<\/br><\/ul><\/br>/g, '</ul>')
      .replace(/<ol><\/br>/g, '<ol>')
      .replace(/<\/br><\/ol><\/br>/g, '</ol>');
  }
  return output;
};

export const removeBreakElementinList = (input = '') => {
  // console.log('removeBreakElementinList', input.replace(/<figure>/g, '<figure style="display:flex">'));
  let output = '';
  if (input) {
    output = input
      .replace(/<\/li>\n/g, '</li>')
      .replace(/<\/ul>\n/g, '</ul>')
      .replace(/<\/ol>\n/g, '</ol>')
      .replace(/<br><\/li>/g, '</li>')
      .replace(/<ol>\n/g, '<ol>')
      .replace(/<ul>\n/g, '<ul>')
      .replace(/<figure>/g, '<figure style="display:flex">');
  }
  return output;
};

export const position = (input = '') => {
  if (input === 'center') {
    return 'Center';
  }
  if (input === 'left') {
    return 'Left';
  }
  if (input === 'right') {
    return 'Right';
  }
  return input;
};

export const DraftEditorText = (input, convertFromRaw, alignNode = true) => {
  let output = '';
  try {
    const inp = JSON.parse(input);
    output = convertFromRaw(inp);
  } catch (err) {
    const comment = input
      .replace(/<\/span><\/br>/g, '</br></span>')
      .replace(/<span style="width: 100%"> <br> {2}<\/span>/g, '<p></p>')
      .replace(/<span style="width: 100%"><br><\/span>/g, '<p></p>')
      .replace(/<div style="width: 100%"> <br> {2}<\/div>/g, '<p></p>')
      .replace(/<div style="width: 100%"><br><\/div>/g, '<p></p>')
      .replace(/<\/span><\/br>/g, '</div>')
      .replace(/<span><\/br>/g, '<div>');
    output = convertFromHTML({
      htmlToStyle: (nodeName, node, currentStyle) => {
        let current = currentStyle;
        if (
          nodeName === 'span' &&
          node.style['background-color'] === 'rgba(255, 255, 255, 0.7)'
        ) {
          current = current.add('whiteBackground');
        }
        if (
          nodeName === 'span' &&
          node.style['background-color'] === 'rgba(1, 1, 1, 0.7)'
        ) {
          current = current.add('blackBackground');
        }
        if (
          nodeName === 'span' &&
          node.style['background-color'] === 'rgba(255, 0, 0, 0.7)'
        ) {
          current = current.add('redBackground');
        }
        if (
          nodeName === 'span' &&
          node.style['background-color'] === 'rgba(255, 127, 0, 0.7)'
        ) {
          current = current.add('orangeBackground');
        }
        if (
          nodeName === 'span' &&
          (node.style['background-color'] === 'rgba(255, 255, 0, 0.7)' ||
            node.style['background-color'] === 'rgb(237, 194, 0)')
        ) {
          current = current.add('yellowBackground');
        }
        if (
          nodeName === 'span' &&
          node.style['background-color'] === 'rgba(0, 180, 0, 0.7)'
        ) {
          current = current.add('greenBackground');
        }
        if (
          nodeName === 'span' &&
          node.style['background-color'] === 'rgba(0, 0, 255, 0.7)'
        ) {
          current = current.add('blueBackground');
        }
        if (
          nodeName === 'span' &&
          node.style['background-color'] === 'rgba(75, 0, 130, 0.7)'
        ) {
          current = current.add('indigoBackground');
        }
        if (
          nodeName === 'span' &&
          (node.style.color === 'rgb(255, 255, 255)' ||
            node.style.color === '#FFFFFF')
        ) {
          current = current.add('white');
        }
        if (nodeName === 'span' && node.style.color === 'rgb(1, 1, 1)') {
          current = current.add('black');
        }
        if (
          nodeName === 'span' &&
          (node.style.color === 'rgb(255, 0, 0)' ||
            node.style.color === 'rgb(222, 54, 22)')
        ) {
          current = current.add('red');
        }
        if (
          nodeName === 'span' &&
          (node.style.color === 'rgb(255, 127, 0)' ||
            node.style.color === '#FF7F00')
        ) {
          current = current.add('orange');
        }
        if (
          nodeName === 'span' &&
          (node.style.color === 'rgb(255, 255, 0)' ||
            node.style.color === 'rgb(237, 194, 0)')
        ) {
          current = current.add('yellow');
        }
        if (
          nodeName === 'span' &&
          (node.style.color === 'rgb(0, 180, 0)' ||
            node.style.color === 'rgb(26, 156, 70)')
        ) {
          current = current.add('green');
        }
        if (
          nodeName === 'span' &&
          (node.style.color === 'rgb(0, 0, 255)' ||
            node.style.color === 'rgb(0, 174, 204)')
        ) {
          current = current.add('blue');
        }
        if (nodeName === 'span' && node.style.color === 'rgb(75, 0, 130)') {
          current = current.add('indigo');
        }
        if (nodeName === 'span' && node.style.color === 'rgb(127, 0, 255)') {
          current = current.add('violet');
        }
        if (node.style && node.style['text-align'] && alignNode) {
          current = current.add(position(node.style['text-align']));
        }
        return current;
      },
      htmlToEntity: (nodeName, node, createEntity) => {
        // console.log(nodeName);
        if (nodeName === 'a') {
          return createEntity('LINK', 'MUTABLE', { url: node.href });
        }
        if (nodeName === 'img') {
          return createEntity('IMAGE', 'MUTABLE', { src: node.src });
        }
        return null;
      },
    })(comment, { flat: false });
    return output;
  }
  return output;
};

export const generateUniqueHash = (length) => {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};
