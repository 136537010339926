import create from 'zustand';
import { SectionItemTuple } from 'components/Requests/components/RequestsBuilder/components/QuestionsBuilder';
import { WorkflowItemType } from 'components/Requests/requests.types';

export interface EditorInputs {
  sectionIndex: number;
  prompt: string;
  type: WorkflowItemType | null;
}

export interface RequestBuilderContextProps {
  workflowId: string;
  isErrored: boolean;
  errors: Record<string, string>;
  isSubmitting: boolean;
  activeQuestionIndex: SectionItemTuple;
  newItemInEdit: EditorInputs | null;
  /* Are there any changes that have not been saved to the server */
  isDirty: boolean;
  /* Have we successfully made any changes to the workflow in this session */
  isSubmitted: boolean;
  /* A boolean value to let you know the `isDirty` state has changed to `true` for the first time */
  isTouched: boolean;
}

interface RequestBuilderState extends RequestBuilderContextProps {
  setWorkflowId(workflowId: string): void;
  setErrored(key: string, error: string): void;
  setSubmitting(isSubmitting: boolean): void;
  setActiveQuestionIndex(index: SectionItemTuple): void;
  setNewItemInEdit(newVal: EditorInputs | null): void;
  setDirty(newVal: boolean): void;
  setSubmitted(newVal?: boolean): void;
  clear(): void;
}

const DEFAULT_STATE: RequestBuilderContextProps = {
  errors: {},
  isErrored: false,
  workflowId: '',
  isSubmitting: false,
  activeQuestionIndex: [null, null],
  isDirty: false,
  newItemInEdit: null,
  isSubmitted: false,
  isTouched: false,
};

export const useRequestBuilderContext = create<RequestBuilderState>()(
  (set) => ({
    ...DEFAULT_STATE,
    setWorkflowId: (workflowId: string) => {
      set({ workflowId });
    },
    setErrored: (key: string, error: string) => {
      set((state) => ({
        errors: {
          ...state.errors,
          [key]: error,
        },
        isErrored: true,
      }));
    },
    clearError: (key: string) => {
      set((state) => {
        const errors = { ...state.errors };
        errors[key] = '';
        return { errors, isErrored: Object.keys(errors).length > 0 };
      });
    },
    setSubmitting: (newVal: boolean) => {
      const setState: Partial<RequestBuilderState> = {
        isSubmitting: newVal,
      };
      if (newVal) {
        setState.errors = {};
        setState.isErrored = false;
      }
      set(setState);
    },
    setActiveQuestionIndex: (index: SectionItemTuple) => {
      set({ activeQuestionIndex: index });
    },
    setNewItemInEdit: (newVal: EditorInputs | null) => {
      set({ newItemInEdit: newVal });
    },
    setDirty: (newVal: boolean) => {
      if (newVal === true) {
        set({ isTouched: true });
      }
      set({ isDirty: newVal });
    },
    setSubmitted: () => {
      set({ isSubmitted: true, isDirty: false, isSubmitting: false });
    },
    clear: () => {
      set(DEFAULT_STATE);
    },
  }),
);
