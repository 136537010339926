/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable global-require */
import { useCallback, useEffect } from 'react';

import { SignInCpaData } from 'api/login-v5';
import { useCpaAuthorization } from 'hooks/login-v5-hooks';
import { setAuthSession } from 'auth/session';
import { useNavigate } from 'react-router-dom-latest';

import { CPAButton } from './components';

const CLIENT_ROLE = 'client';

export type LoginTypeV5 = 'password' | 'magic_link' | 'google' | 'outlook';

export interface CpaSelectorProps {
  availableCpas: Array<SignInCpaData>;
  temporaryAuthToken?: string;
  backToLogin?: () => void;
  loginType?: LoginTypeV5;
}

export const CpaSelector = ({
  availableCpas,
  temporaryAuthToken,
  backToLogin,
  loginType,
}: CpaSelectorProps) => {
  const navigate = useNavigate();
  const { mutateAsync: cpaSwitcher, isLoading } = useCpaAuthorization({
    onSuccess: (result) => {
      setAuthSession({ auth_token: result?.auth_token });
      // Redirect Client
      if (result?.cpa_data?.cpa_user_role === CLIENT_ROLE) {
        window.location.href = result?.cpa_data?.company_url;
      } else {
        navigate('/dashboard');
      }
    },
  });

  const hasOnlyOneCpa = availableCpas?.length === 1;

  const selectCpa = useCallback(
    (cpaUUID: string) => {
      cpaSwitcher({
        cpa_uuid: cpaUUID,
        auth_token: temporaryAuthToken,
        login_type: loginType,
      });
    },
    [cpaSwitcher, loginType, temporaryAuthToken],
  );

  // Automatically select CPA if only one is available
  useEffect(() => {
    if (hasOnlyOneCpa) {
      selectCpa(availableCpas[0].cpa_uuid);
    }
  }, [availableCpas, hasOnlyOneCpa, selectCpa]);

  return (
    <>
      {isLoading && <div id="loading" />}
      <div className="border-bottom pb-4">
        <div className="text-center border-bottom py-2 mt-4 mb-2 text-center">
          <h3 className="fontSize font-weight-bold">
            {hasOnlyOneCpa ? 'Loading...' : 'Select WorkSpace'}
          </h3>
        </div>
        {!hasOnlyOneCpa && (
          <div className="row">
            {availableCpas.map((cpa) => (
              <CPAButton cpa={cpa} key={cpa.cpa_uuid} onClick={selectCpa} />
            ))}
          </div>
        )}
      </div>
      {backToLogin && (
        <div className="crPage--bott text-center mt-3">
          <span className="txtInfo">Back to </span>
          <a href="#" onClick={backToLogin} className="btn tptBtn">
            <b>Login</b>
          </a>
        </div>
      )}
    </>
  );
};

export default CpaSelector;
