/* eslint-disable no-nested-ternary */
/* eslint-disable no-script-url */
/* eslint-disable react/jsx-no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import axios from 'axios';
import { SelectField } from 'common/CustomFields';
import { getCpaPreferences, selectedIds, AlertMessage } from 'utilities/utils';
import { UPDATE_DOCUMENTS } from 'constants/constants';
import Select from 'react-select';

const SelectStyles = {
  control: (styles, { isFocused, selectProps: { invalidValue } }) => ({
    ...styles,
    borderRadius: '2px',
    border: invalidValue
      ? '2px solid red'
      : isFocused
      ? '2px solid #919eab'
      : '2px solid #dfe3e8',
    boxShadow: 'none',
    width: '432px',
    '&:hover': {
      borderColor: 'hsl(0, 0%, 70%)',
      boxShadow: 'none',
    },
  }),
  input: (styles) => ({ ...styles, cursor: 'pointer' }),
  menu: (provided) => ({
    ...provided,
    marginTop: '0px',
    border: '2px solid #919eab',
    outlineStyle: 'none',
    boxShadow: 'none',
    width: '432px',
  }),
};

const noneStyle = { display: 'none' };
const blockStyle = { display: 'block' };
const radioButtons = [
  {
    htmlFor: 'Tags',
    value: 'tagsForm',
  },
  {
    htmlFor: 'Year',
    value: 'yearForm',
  },
  {
    htmlFor: 'Month',
    value: 'monthForm',
  },
];

class MultiTagsYearMonthModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedForm: 'tagsForm',
      preferences: {},
      formdata: {},
      errors: {},
      loading: false,
      clientUploadTag: false,
    };
  }

  componentDidMount() {
    const preferences = getCpaPreferences();
    const blankValue = {
      key: 'blank',
      is_descending: false,
      value: 'blank',
      label: 'Blank',
    };
    if (
      Object.keys(preferences).length &&
      preferences.upload_file_year &&
      preferences.upload_file_month
    ) {
      preferences.upload_file_year.unshift(blankValue);
      preferences.upload_file_month.unshift(blankValue);
    }
    this.setState({
      preferences,
      formdata: {
        ...this.state.formdata,
        tagsList: preferences.upload_tags,
        newTagsList: preferences.upload_tags,
      },
    });
    const result = this.props.selectedrecords.map(({ tags }) => tags);
    const resultnew = result.map(
      (item) => item.includes('client_upload') && item.length === 1,
    );
    this.setState({ clientUploadTag: resultnew.includes(true) });
  }

  handleFormChange = (changeForm) => {
    const { tagsList } = this.state.formdata;
    this.setState({
      selectedForm: changeForm.target.value,
      formdata: {
        ...this.state.formdata,
        dropdownOpen: false,
        newTagsList: tagsList,
        tagSeached: '',
        selectedTags: [],
        year: {},
        month: {},
      },
      errors: {},
    });
  };

  handleChange = (action, data) => {
    const { tagsList, selectedTags } = this.state.formdata;
    const selectedRecordsArr = selectedTags || [];
    const value = data || {};
    const index = selectedRecordsArr.findIndex((x) => x.key === value.key);
    if (action === 'addTag' || action === 'deleteTag') {
      if (action === 'addTag' && index === -1) {
        selectedRecordsArr.push(value);
      } else if (action === 'deleteTag' && index > -1) {
        selectedRecordsArr.splice(index, 1);
      }
      this.setState({
        formdata: {
          ...this.state.formdata,
          dropdownOpen: false,
          newTagsList: tagsList,
          selectedTags: selectedRecordsArr,
          tagSeached: '',
        },
      });
    } else if (action === 'year' || action === 'month') {
      this.setState({
        formdata: { ...this.state.formdata, [action]: value },
        errors: {},
      });
    }
  };

  handleValidation = () => {
    const { selectedForm, formdata } = this.state;
    const error = {};
    let formIsValid = true;
    if (
      selectedForm === 'tagsForm' &&
      (!formdata.selectedTags ||
        formdata.selectedTags === undefined ||
        !formdata.selectedTags.length)
    ) {
      formIsValid = false;
      error.selectedTags = 'Please select tags';
    } else if (
      selectedForm === 'yearForm' &&
      (!formdata.year ||
        formdata.year === undefined ||
        !Object.keys(formdata.year).length)
    ) {
      formIsValid = false;
      error.year = 'Please select year';
    } else if (
      selectedForm === 'monthForm' &&
      (!formdata.month ||
        formdata.month === undefined ||
        !Object.keys(formdata.month).length)
    ) {
      formIsValid = false;
      error.month = 'Please select month';
    }
    this.setState({ errors: error });
    return formIsValid;
  };

  handleSubmit = (e, action = '') => {
    if (e) {
      e.preventDefault();
    }
    const { selectedForm, formdata, clientUploadTag } = this.state;
    const { selectedrecords } = this.props;
    if (this.handleValidation()) {
      const data = { document_ids: selectedIds(selectedrecords, 'id') };
      if (action === 'removeTag') {
        data.action_type = 'Remove';
        data.multi_tags = formdata.selectedTags;
      } else if (selectedForm === 'tagsForm') {
        data.action_type = 'Add';
        data.multi_tags = formdata.selectedTags;
      } else if (selectedForm === 'yearForm') {
        data.year =
          formdata.year.key === 'blank'
            ? null
            : parseInt(formdata.year.key, 10);
      } else if (selectedForm === 'monthForm') {
        data.month = formdata.month.key === 'blank' ? null : formdata.month.key;
      }
      this.setState({ loading: true });
      const request = axios.put(UPDATE_DOCUMENTS, data);
      request
        .then((response) => {
          this.setState({ loading: false });
          const resData = response.data;
          if (resData.status === 200) {
            const refreshData = true;
            this.props.closeModal(refreshData);
            if (clientUploadTag && action === 'removeTag') {
              AlertMessage(
                'success',
                'The file(s) must have at least one active tag.',
                3000,
              );
            } else {
              AlertMessage('success', resData.message, 3000);
            }
          } else {
            AlertMessage('error', resData.message, 3000);
          }
        })
        .catch(() => {
          this.setState({ loading: false });
          AlertMessage('error', 'Some error occurred. Please try later.', 3000);
          return null;
        });
    }
  };

  /* TAGS MULTI SELECT */
  tagOptions = () => {
    const { newTagsList } = this.state.formdata;
    return newTagsList;
  };

  // eslint-disable-next-line class-methods-use-this
  handleTagsChange = (data) => {
    const { formdata } = this.state;
    const getLabel = data.reduce((acc, next) => {
      acc.push(next.key);
      return acc;
    }, []);

    this.setState({
      formdata: { ...formdata, selectedTags: getLabel },
      errors: {},
    });
  };

  render() {
    const { selectedForm, formdata, preferences, errors } = this.state;

    const years = preferences.upload_file_year || [];
    const months = preferences.upload_file_month || [];

    return (
      <div>
        <Modal
          className="customModal customModal--w500 customModal--nopadding MultiTagsYearMonthModal"
          isOpen="true"
        >
          <div
            id="loading"
            style={this.state.loading ? blockStyle : noneStyle}
          />
          <div className="ModalHeader">
            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={() => this.props.closeModal()}
            >
              <i className="icon-close2" aria-hidden="true" />
            </button>
          </div>
          <ModalBody className="modalContent MultiTagsYearMonthModal__ModalBody">
            <div className="modalContent--inner">
              <div className="modalContent--header mb-2">
                <h5 className="modalContent--header__heading">
                  Tag Multiple Files
                </h5>
              </div>
              <div className="d-flex">
                {radioButtons.map((each) => (
                  <div
                    className="radio-container text-body py-2 pr-4"
                    key={radioButtons.indexOf(each)}
                  >
                    <label htmlFor={each.htmlFor}>
                      <input
                        type="radio"
                        name="radios"
                        id={each.htmlFor}
                        value={each.value}
                        onChange={this.handleFormChange}
                        checked={selectedForm === each.value}
                      />
                      <i className="checkmark" />
                      <span className="pl-2 text-body text-transform-none font-weight-normal">
                        {each.htmlFor}
                      </span>
                    </label>
                  </div>
                ))}
              </div>
              <div className="modalForm">
                <form onSubmit={this.handleSubmit}>
                  <div className="row mr-0 ml-0 mt-2 mb-2">
                    {selectedForm === 'tagsForm' && (
                      <div className="MultiTagsSelect">
                        <Select
                          isMulti
                          name="tagsMultiSelect"
                          options={this.tagOptions()}
                          onChange={this.handleTagsChange}
                          styles={SelectStyles}
                          className="TagsMultiSelect"
                          classNamePrefix="select"
                          invalidValue={errors.selectedTags}
                        />
                        {errors.selectedTags && (
                          <div className="MultiTagsSelect__Error error_label mt-1">
                            {errors.selectedTags}
                          </div>
                        )}
                      </div>
                    )}
                    {selectedForm === 'yearForm' && (
                      <SelectField
                        name="year"
                        label="Year"
                        className="form-group dropdown col-md-6 col-12"
                        value={formdata.year}
                        handleChange={(input) =>
                          this.handleChange('year', input)
                        }
                        options={years}
                        error={errors.year}
                      />
                    )}
                    {selectedForm === 'monthForm' && (
                      <SelectField
                        name="month"
                        label="Month"
                        className="form-group dropdown col-md-6 col-12"
                        value={formdata.month}
                        handleChange={(input) =>
                          this.handleChange('month', input)
                        }
                        options={months}
                        error={errors.month}
                      />
                    )}
                  </div>
                  <div className="row">
                    <div className="col-12 justify-content-end formbtn justify-content-end mt-2 pl-0 pr-0">
                      <div className="btn-wrap btn--leftspace">
                        <button
                          type="button"
                          className="btn"
                          onClick={() => this.props.closeModal()}
                        >
                          Cancel
                        </button>
                        <button type="submit" className="btn btn-primary">
                          {selectedForm === 'tagsForm' ? 'Add Tag' : 'Replace'}
                        </button>
                        {selectedForm === 'tagsForm' && (
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => this.handleSubmit(null, 'removeTag')}
                          >
                            Remove Tag
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default MultiTagsYearMonthModal;
