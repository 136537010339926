import { useParams, useNavigate } from 'react-router-dom-latest';

import { Alert } from 'reactstrap';
import { parseErrorMessage } from 'utilities/parseErrorMessage';
import {
  LoginPageLayout,
  MFAForm,
  NextStepsMessage,
  ResetPasswordForm,
} from './components';

import { useV5AuthResetPassword } from './hooks/useV5AuthResetPassword';

export const LoginV5ResetPassword = () => {
  const navigate = useNavigate();
  const { id: resetToken } = useParams<{ id: string }>();
  const resetPasswordHook = useV5AuthResetPassword(resetToken);
  const {
    state,
    isLoading,
    error,
    submitMfa,
    submitPasswordReset,
    confirmationMessage,
  } = resetPasswordHook;

  return (
    <div id="page-wrapper">
      {(isLoading || resetPasswordHook.isLoading) && <div id="loading" />}
      <LoginPageLayout>
        {state === 'VERIFYING' && error && (
          <Alert color="danger">
            {parseErrorMessage(error, 'Error verifying reset token')}
          </Alert>
        )}
        {state === 'MFA_REQUIRED' && (
          <MFAForm
            onSubmit={submitMfa}
            error={parseErrorMessage(error, 'Error verifying code')}
          />
        )}
        {state === 'RESET_PASSWORD' && (
          <ResetPasswordForm
            onSubmit={submitPasswordReset}
            error={parseErrorMessage(error, 'Error resetting password')}
          />
        )}
        {state === 'COMPLETE' && (
          <NextStepsMessage
            message={confirmationMessage}
            backToLogin={() => {
              navigate('/');
            }}
          />
        )}
      </LoginPageLayout>
    </div>
  );
};

export default LoginV5ResetPassword;
