import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Input } from 'reactstrap';
import Button from 'common/Button/Button';
import styled from '@emotion/styled';
import Axios from 'axios';
import {
  USER_EMAILS,
  EMAIL_ATTACHMENTS,
  MIXPANEL_EMAIL_EVENT,
  // GET_TASK_ACCOUNTS,
} from 'constants/constants';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { generateUniqueHash } from 'common/NotesUtilities';
import { AlertMessage } from 'utilities/utils';
import TaskFileUpload from 'components/Files/TaskFileUpload';
import { trackMixpanelEvent } from 'utilities/mixpanelfn';
import useMountedState from 'components/Emails/components/useMountedState';
import TinymceEditor from 'components/Emails/components/TinymceEditior';
import {
  getFormattedData,
  validateSaveEmailFormattedData,
} from 'components/Email/EmailUtilities';
import getInlineImages from 'components/Email/inline-image-utils/get-inline-images';
import DisplayEmailAttachments from './DisplayEmailAttachments';
import EmailInputField from './EmailInputField';
import 'components/Emails/components/TinymceEditior.scss';
import DiscardEmail from './icons/DiscardEmail';

const NewEmail = styled.div`
  .EmailAside__Block {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
  }
  .FormBlock {
    margin-bottom: 1rem;
    position: relative;
    label {
      font-size: 12px;
      color: #919eab;
      text-transform: uppercase;
      letter-spacing: 1px;
      text-align: left;
      font-weight: 600;
      padding-top: 0;
    }
    .FormError {
      display: none;
      font-size: 14.4px;
      color: #de3618;
    }
    .css-3kzsy4-placeholder {
      color: #cdd1d4;
    }
    &__InputIcon {
      position: relative;
      .ccWrap {
        position: absolute;
        top: 6px;
        right: 6px;
        button {
          height: auto;
          background-color: #f9fafb;
          border: 1px solid #dfe3e8;
          border-radius: 2px;
          padding: 3px 5px;
          color: #353c42;
          line-height: 1;
        }
        button + button {
          margin-left: 2px;
        }
      }
    }
    &__BtnGroup {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 15px;
      .BtnDiscard {
        background-color: #f9fafb;
        border-color: #f9fafb;
      }
    }
    .customEditor {
      .tox-tinymce {
        border-width: 1px !important;
      }
    }
  }
  .DragDrop {
    background-color: #f9fafb;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
  }
  .ReplyToEmail {
    .EmailAside__Block {
      padding-left: 0;
      padding-right: 0;
      padding-top: 0;
    }
    .EmailAside__mailto {
      border: 1px solid #dee2e6;
      padding: 10px;
      margin-bottom: 15px;
      .FormBlock {
        margin-bottom: 0;
        & + .FormBlock {
          margin-top: 15px;
        }
      }
      .tox-tinymce {
        border: none !important;
      }
      .tox .tox-toolbar__group {
        padding: 0 !important;
      }
    }
  }
`;

function useStateCallback(initialState) {
  const [state, setState] = useState(initialState);
  const cbRef = useRef(null); // mutable ref to store current callback

  const setStateCallback = useCallback((newState, cb) => {
    cbRef.current = cb; // store passed callback to ref
    setState(newState);
  }, []);

  useEffect(() => {
    // cb.current is `null` on initial render, so we only execute cb on state *updates*
    if (cbRef.current) {
      cbRef.current(state);
      cbRef.current = null; // reset callback after execution
    }
  }, [state]);

  return [state, setStateCallback];
}

const ComposeEmailFromThread = ({
  initialState,
  connectedAppKey,
  composeEmailAction,
  viewContext,
  emailDetails,
  msgInrClass,
  connectedAppAccessToken,
  isNew,
  setLoading,
}) => {
  const { inlineImagesEmailFeature } = useFlags();
  const [state, setState] = useStateCallback({
    initialBody: initialState.body,
    subject: initialState.subject,
    body: initialState.body,
    toRecipients: initialState.toRecipients,
    ccRecipients: initialState.ccRecipients,
    bccRecipients: initialState.bccRecipients,
    showCCInput: initialState.ccRecipients.length,
    showBCCInput: initialState.bccRecipients.length,
    dropdownOpen: false,
    errors: {},
    outlookToken: '',
    emailStatus: '',
    draftId: initialState.draftId,
    sendApiHitting: false,
    replyToMsgId: initialState.replyToMsgId,
    isDraft: true,
    viewContext: viewContext || '',
    documents: [],
    gettingInlineImages:
      initialState.attachments &&
      initialState.attachments.length &&
      initialState.attachments.filter(
        (e) =>
          e.content_disposition !== null &&
          e.content_disposition.includes('inline'),
      ).length,
    attachments: initialState.attachments,
    emailId: initialState.emailId,
  });
  const [saveData, setSaveData] = useState(false);
  const [description, setDescription] = useState(initialState.body);
  const isDraft = useRef(true);
  const isInitialLoad = useRef(true);
  const isMounted = useMountedState();

  /**
   * @param {object} file
   * @param {function} callback
   */
  async function imageUploadHandler(file, callback) {
    const content_id = generateUniqueHash(25);
    const formData = new FormData();
    formData.append('attachment', file.blob());
    formData.append('attachment_name', file.name());
    formData.append('content_disposition', 'inline');
    formData.append('content_id', content_id);

    // api call to get s3 url
    try {
      const response = await Axios.post(EMAIL_ATTACHMENTS, formData);
      const attachmentData = response?.data?.data;

      if (attachmentData?.id) {
        setState((pastState) => ({
          ...pastState,
          attachments: [...pastState.attachments, attachmentData],
        }));
      }

      callback(response.data.url);
    } catch (e) {
      console.error(e);
      callback('');
    }
  }

  // to toggle cc and bcc fields
  const toggleRecipientInputField = (type) => {
    const recipient = type === 'CC' ? 'cc' : 'bcc';
    if (
      state[`${recipient}Recipients`] &&
      state[`${recipient}Recipients`].length
    ) {
      setState((ps) => ({
        ...ps,
        [`${recipient}Recipients`]: [],
      }));
    } else if (viewContext !== 'forward') {
      const newState = {};
      const recipientsType = type === 'CC' ? 'cc_email' : 'bcc_email';
      if (initialState[recipientsType]) {
        newState[`${recipient}Recipients`] = initialState[recipientsType];
      }
      setState((ps) => ({ ...ps, ...newState }));
    }
    setState((ps) => {
      const errors = { ...ps.errors };
      if (ps[`show${type}Input`]) delete errors[`${type}Recipients`];
      return {
        ...ps,
        [`show${type}Input`]: !ps[`show${type}Input`],
      };
    });
  };

  // need these tokens for getting contacts from outlook and gmail
  const initializeGoogleClient = async () => {
    try {
      if (connectedAppKey && connectedAppKey === 'outlookemail') {
        setState((ps) => ({
          ...ps,
          outlookToken: connectedAppAccessToken,
        }));
      } else {
        // await gapi.client.init({ apiKey: 'AIzaSyB8PwThztg96sboSdQbD47Zb3O7K0tOSNU' });
        await gapi.client.setToken({ access_token: connectedAppAccessToken });
      }
    } catch (error) {
      console.error(error, 'error');
    }
  };

  useEffect(() => {
    if (gapi) {
      gapi.load('client', initializeGoogleClient);
    }
  }, [viewContext, emailDetails]);

  useEffect(() => {
    // for getting inline images blob data when we show draft for first time
    // we only use this if inline images are present in the draft email
    if (isInitialLoad.current) {
      getInlineImages({
        initialEmailBody: initialState.body,
        emailId: state.emailId,
        attachments: state.attachments,
      }).then(({ newEmailBody, inlineAttachments }) => {
        setState((ps) => ({
          ...ps,
          initialBody: newEmailBody,
          gettingInlineImages: false,
          attachments: inlineAttachments,
        }));

        isInitialLoad.current = false;
      });
    }
  }, [initialState.body, setState, state.attachments, state.emailId]);

  // this is for creating, updating draft and send new email
  const saveEmail = async (status) => {
    const data = getFormattedData(state);
    const validationErrors = validateSaveEmailFormattedData(status, data);

    if (validationErrors.length) {
      validationErrors.forEach((errorMessage) => {
        AlertMessage('error', errorMessage, 3000);
      });
      return;
    }

    const isDraftStatus = status === 'Draft';
    if (!isDraftStatus) setLoading(true);

    data.message = description;
    data.is_draft = isDraftStatus;
    if (status === 'Sent') {
      setState((ps) => ({
        ...ps,
        sendApiHitting: true,
      }));
    }

    try {
      const url = USER_EMAILS;
      const response = await Axios.post(url, data).then((res) => res.data);
      if (status === 'Sent' && response.status === 201) {
        setState((ps) => ({
          ...ps,
          sendApiHitting: false,
        }));
        AlertMessage('error', response.message, 3000);
        trackMixpanelEvent(MIXPANEL_EMAIL_EVENT, {
          state: 'error',
          message: response.message,
        });
      } else if (status === 'Sent') {
        isDraft.current = false;
        composeEmailAction('', {}, response.data, initialState);
        AlertMessage('success', 'Email sent successfully', 2000);
        trackMixpanelEvent(MIXPANEL_EMAIL_EVENT, { state: 'sent' });
      } else if (status === 'Draft') {
        if (response.status === 200) {
          trackMixpanelEvent(MIXPANEL_EMAIL_EVENT, { state: 'draft' });
        }
      }
    } catch (error) {
      setState((ps) => ({
        ...ps,
        sendApiHitting: false,
      }));
      AlertMessage('error', error.response.data.message, 3000);
      trackMixpanelEvent(MIXPANEL_EMAIL_EVENT, {
        state: 'error',
        message: error.response.data.message,
      });
      console.log(error);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (!saveData) {
      setSaveData(true);
    }

    return () => {
      if (
        !isMounted() &&
        isDraft.current &&
        saveData &&
        (!emailDetails ||
          (emailDetails && !emailDetails.draft_id && !emailDetails.draft_data))
      ) {
        saveEmail('Draft');
      } else if (!isMounted() && state.deleteDraft && state.draftId) {
        console.log('delete draft');
        composeEmailAction(
          '',
          {},
          [],
          'discard',
          viewContext === 'draft' ? emailDetails : state.draftId,
        );
      } else if (!isMounted() && !isDraft.current) {
        console.log('send mail and go back');
        // composeEmailAction('', {}, [], 'goBack');
        // Email.sendOutlookNewDraftMail(state, setState, 'Draft', props);
      }
    };
  }, [
    state.ccRecipients,
    state.bccRecipients,
    state.toRecipients,
    state.subject,
    description,
    state.attachments,
    isDraft,
  ]);

  function handleBodyChange(value) {
    setDescription(value);
    return value;
  }

  // for tracking to,cc, bcc field changes
  const handleChange = (fieldName) => (value) => {
    setState((ps) => {
      const newError = { ...ps.errors };
      delete newError[fieldName];
      return {
        ...ps,
        errors: { ...newError },
        [fieldName]: value,
        emailStatus: 'Draft',
      };
    });
  };

  // for subject change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState((ps) => {
      const newError = { ...ps.errors };
      if (value) delete newError[name];
      return {
        ...ps,
        errors: { ...newError },
        [name]: value,
        emailStatus: 'Draft',
      };
    });
  };

  const handleSubmit = (e) => {
    try {
      if (e) {
        e.preventDefault();
      }
      saveEmail('Sent');
    } catch (error) {
      const error_response = JSON.parse(error.body);
      AlertMessage('error', error_response.error.message, 3000);
    }
  };

  // this is for handling liscio dispatch attachments
  const handleDocuments = (e) => {
    const files = e.map((file) => {
      // eslint-disable-next-line no-param-reassign
      file.file_name = file.doc_name;
      // eslint-disable-next-line no-param-reassign
      file.content_disposition = 'attachment';
      return file;
    });
    setState((ps) => ({
      ...ps,
      attachments: [...(ps.attachments || []), ...files],
    }));
  };

  const handleDiscardButton = async () => {
    isDraft.current = false;
    setState((ps) => ({
      ...ps,
      emailStatus: '',
      sendApiHitting: false,
      isDraft: false,
      deleteDraft: true,
    }));
    await composeEmailAction(
      '',
      {},
      [],
      'discard',
      viewContext === 'draft' ? emailDetails : state,
    );
  };

  const deleteEmailAttachment = async (attachment) => {
    setState((ps) => ({
      ...ps,
      attachments: ps.attachments.filter((file) => file.id !== attachment.id),
    }));
    if (!attachment.fromlisciovault) {
      try {
        await Axios.delete(`${EMAIL_ATTACHMENTS}/${attachment.id}`).then(
          (res) => res.data,
        );
      } catch (error) {
        console.log(error);
      }
    }
  };

  // this for handling new attachments uploaded from system
  // TODO: show error message when file size is more than 3MB
  const handleEmailAttachments = async (input) => {
    const files = [];
    const batch = [];
    const onUploadProgress = (uploadedFile) => (progress) => {
      const percentage = Math.floor((progress.loaded * 100) / progress.total);
      const key = `percentCompleted${uploadedFile}`;
      setState((ps) => ({
        ...ps,
        [key]: percentage,
      }));
    };
    Array.from(input).forEach((file) => {
      const content_id = generateUniqueHash(25);
      files.push({
        file_name: file.name,
        content_type: file.type,
        content_disposition: 'attachment',
        content_id,
        file_size: file.size,
        file_data: file,
      });
      const formData = new FormData();
      formData.append('attachment', file);
      formData.append('attachment_name', file.name);
      formData.append('content_disposition', 'attachment');
      formData.append('content_type', file.type);
      formData.append('content_id', content_id);
      const { CancelToken } = Axios;
      const source = CancelToken.source();
      const config = {
        headers: { 'content-type': 'multipart/form-data' },
        cancelToken: source.token,
        onUploadProgress: onUploadProgress(file.name),
      };
      batch.push(Axios.post(EMAIL_ATTACHMENTS, formData, config));
    });
    const stateAttachments = state.attachments || [];
    const allattachments = [...stateAttachments, ...files];
    setState((ps) => ({
      ...ps,
      attachments: allattachments,
      sendApiHitting: true,
    }));
    const response = await Promise.allSettled(batch).then((res) => res);
    const attachments = [...stateAttachments];
    response.map((e) => {
      allattachments.forEach((each) => {
        const attachmentItem = each;
        let attachment = {};
        if (e.status !== 'rejected') {
          const { file_name, id } = e.value.data.data;
          if (
            e.status === 'fulfilled' &&
            file_name === attachmentItem.file_name &&
            !attachmentItem.id &&
            id !== null
          ) {
            attachment = e.value.data.data;
          }
          if (
            attachment.id &&
            (attachment.content_type.includes('text/csv') ||
              attachment.content_type.includes('sheet')) &&
            attachment.attachment_content_type?.includes('text/plain')
          ) {
            attachments.push({
              ...attachment,
              file_data: attachmentItem.file_data,
            });
          } else if (attachment.id) {
            attachments.push(attachment);
          }
        }
        return attachment;
      });
      return null;
    });
    setState((ps) => ({
      ...ps,
      attachments,
      sendApiHitting: false,
    }));
  };

  // TODO: This feature would be introduce in future
  // const handleAccountInputChange = (e) => {
  //   clearTimeout(typingTime);
  //   typingTime = setTimeout(() => {
  //     if (e.length) {
  //       handleAccountInput(e);
  //     } else {
  //       setAccounts(allAccounts);
  //     }
  //   }, 500);
  // };

  // const handleAccountInput = (val) => {
  //   if (val) {
  //     clearTimeout(typingTime);
  //     typingTime = setTimeout(() => {
  //       const request = Axios.get(
  //         `${SEARCH_TASK_ACCOUNTS}/?keyword=${val}&message=true`,
  //       );
  //       request.then((response) => {
  //         if (response.status === 200 && response.data) {
  //           setAccounts(response.data);
  //         }
  //       });
  //     }, 500);
  //   }
  // };

  // const handleAccountChange = (value) => {
  //   setState((ps) => {
  //     const newError = { ...ps.errors };
  //     delete newError.accounts;
  //     return {
  //       ...ps,
  //       errors: { ...newError },
  //       accounts: value,
  //       emailStatus: 'Draft',
  //     };
  //   });
  // };

  const {
    initialBody,
    showCCInput,
    showBCCInput,
    toRecipients,
    ccRecipients,
    bccRecipients,
    errors = {},
    outlookToken,
    subject,
    gettingInlineImages,
  } = state;
  if (gettingInlineImages) {
    return null;
  }

  const contentDisposition = (content) => {
    if (
      content.content_disposition !== null &&
      !content.content_disposition.includes('inline')
    ) {
      return true;
    }
    return false;
  };

  return (
    <NewEmail
      className={`EmailAside ${msgInrClass || ''}`}
      data-testid="composeEmailfromThread"
    >
      <div className="EmailAside__Block">
        <div className="EmailAside__mailto">
          <div
            className={`FormBlock ${
              errors.toRecipients ? 'FormBlock__Error' : ''
            }`}
          >
            <label htmlFor="invoiceNumber">To</label>
            <div className="FormBlock__Input FormBlock__InputIcon">
              <EmailInputField
                placeholder="To"
                token={outlookToken}
                connectedAppKey={connectedAppKey}
                className="select-custom-class"
                name="toRecipients"
                id="emailTo"
                value={toRecipients}
                handleChange={handleChange('toRecipients')}
                isNew={isNew}
              />
              <span className="FormError">{errors.toRecipients}</span>
              <div className="ccWrap">
                <button
                  type="button"
                  onClick={() => toggleRecipientInputField('CC')}
                >
                  cc
                </button>{' '}
                <button
                  type="button"
                  onClick={() => toggleRecipientInputField('BCC')}
                >
                  bcc
                </button>
              </div>
            </div>
          </div>
          {showCCInput ? (
            <div
              className={`FormBlock ${
                errors.ccRecipients ? 'FormBlock__Error' : ''
              }`}
            >
              <label htmlFor="emailCC">CC</label>
              <div className="FormBlock__Input">
                <EmailInputField
                  placeholder="CC"
                  token={outlookToken}
                  connectedAppKey={connectedAppKey}
                  className="select-custom-class"
                  name="ccRecipients"
                  id="emailCC"
                  value={ccRecipients}
                  handleChange={handleChange('ccRecipients')}
                  isNew={isNew}
                />
                <span className="FormError">{errors.ccRecipients}</span>
              </div>
            </div>
          ) : null}
          {showBCCInput ? (
            <div
              className={`FormBlock ${
                errors.bccRecipients ? 'FormBlock__Error' : ''
              }`}
            >
              <label htmlFor="emailCC">BCC</label>
              <div className="FormBlock__Input">
                <EmailInputField
                  placeholder="BCC"
                  token={outlookToken}
                  connectedAppKey={connectedAppKey}
                  className="select-custom-class"
                  name="bccRecipients"
                  id="emailBCC"
                  value={bccRecipients}
                  handleChange={handleChange('bccRecipients')}
                  isNew={isNew}
                />
                <span className="FormError">{errors.bccRecipients}</span>
              </div>
            </div>
          ) : null}
          {isNew ? (
            <div
              className={`FormBlock ${
                errors.subject ? 'FormBlock__Error' : ''
              }`}
            >
              {/* TODO: for future introduce */}
              {/* <label htmlFor="emailSubject">Subject</label> */}
              <div className="FormBlock__Input">
                <Input
                  placeholder="Subject"
                  onChange={handleInputChange}
                  value={subject}
                  id="emailSubject"
                  name="subject"
                />
                <span className="FormError">{errors.subject}</span>
              </div>
            </div>
          ) : null}
          <div className="FormBlock">
            {/* TODO: for future introduce */}
            {/* {isNew ? <label htmlFor="emailBody">Description</label> : null} */}
            <div className="FormBlock__Input">
              <div
                id={emailDetails && emailDetails.email_id}
                className="customEditor boxResize"
              >
                <TinymceEditor
                  initialValue={initialBody}
                  imageUploadHandler={imageUploadHandler}
                  handleChange={handleBodyChange}
                  autofocus={!isNew}
                  scrollIntoView={!isNew}
                  disableInlineImages={!inlineImagesEmailFeature}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="FormBlock">
          {/* TODO: for future introduce */}
          {/* <label htmlFor="attachments">Attachments</label> */}
          <TaskFileUpload
            data={state}
            uploadType="email"
            type="email"
            messageComp="threadDetail"
            updateDocumentState={handleDocuments}
            handleEmailAttachments={handleEmailAttachments}
          />
        </div>
        {state.attachments &&
          state.attachments.map(
            (e) =>
              contentDisposition(e) && (
                <DisplayEmailAttachments
                  attachment={e}
                  emailId={state.emailId}
                  deleteAttachment={deleteEmailAttachment}
                  state={state}
                />
              ),
          )}
        <div className="FormBlock">
          <div className="FormBlock__BtnGroup">
            <Button
              label="Send"
              type="button"
              variant="contained"
              onClick={handleSubmit}
              size="small"
              disabled={state.sendApiHitting}
            />
            <DiscardEmail handleDiscardButton={handleDiscardButton} />
          </div>
        </div>
      </div>
    </NewEmail>
  );
};

export default React.memo(ComposeEmailFromThread);
