// Link to the figma:https://www.figma.com/file/iZpYzMTTI9hXlpwQAreX1K/03-%5BComponents%5D-Design-System?node-id=23%3A3000&t=BboZbLmEMc1SHIUr-0
import React from 'react';
import PropTypes from 'prop-types';
import {
  BUTTON_ICON_LOCATION_FRONT,
  BUTTON_ICON_LOCATION_BACK,
} from 'constants/constants';
import PrimaryButton from './PrimaryButton';
import SecondaryButton from './SecondaryButton';
import GhostButton from './GhostButton';
import LinkButton from './LinkButton';

const Button = ({
  label,
  onClick,
  icon,
  to,
  disabled = false,
  variant = 'primary',
  size = 'large',
  iconLocation = { BUTTON_ICON_LOCATION_FRONT },
  testid,
}) => {
  if (variant === 'secondary') {
    return (
      <SecondaryButton
        label={label}
        onClick={onClick}
        icon={icon}
        disabled={disabled}
        size={size}
        iconLocation={iconLocation}
        testid={testid}
      />
    );
  }
  if (variant === 'ghost') {
    return (
      <GhostButton
        label={label}
        onClick={onClick}
        icon={icon}
        disabled={disabled}
        size={size}
        iconLocation={iconLocation}
      />
    );
  }
  if (variant === 'link') {
    return (
      <LinkButton
        label={label}
        to={to}
        icon={icon}
        disabled={disabled}
        size={size}
        iconLocation={iconLocation}
      />
    );
  }
  return (
    <PrimaryButton
      label={label}
      onClick={onClick}
      icon={icon}
      disabled={disabled}
      size={size}
      iconLocation={iconLocation}
      testid={testid}
    />
  );
};

Button.propTypes = {
  variant: PropTypes.oneOf(['primary', 'secondary', 'ghost', 'link']),
  label: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.string,
  to: PropTypes.string,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['large', 'small', 'xsmall']),
  iconLocation: PropTypes.oneOf([
    { BUTTON_ICON_LOCATION_FRONT },
    { BUTTON_ICON_LOCATION_BACK },
  ]),
};

export default Button;
