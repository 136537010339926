import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

export const resetPasswordFormValidationResolver = yupResolver(
  yup.object().shape({
    password: yup.string().required('Password is required'),
    password_confirmation: yup
      .string()
      .required('Password confirmation is required')
      .oneOf([yup.ref('password'), ''], 'Passwords must match'),
  }),
);
