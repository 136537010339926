import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom-latest';

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  background: #dfe3e8;
  border-radius: 2px;
  margin: 2px 5px;
  line-height: 1.5;

  a {
    padding: 3px 0.5rem;
    color: #00aecc;
  }

  span {
    padding: 3px 0.5rem;
    color: #919eab;
  }

  button {
    display: flex;
    align-items: center;
    background: none;
    border: none;
    height: inherit;
    text-align: center;
    padding: 0;
    margin-right: 6px;

    i {
      background: none;
      color: #919eab;
      margin: 0;
      font-size: 10px;

      :hover {
        color: #000;
      }
    }
  }
`;

const PrimaryTag = ({ label, onClick, to }) => (
  <StyledDiv>
    {to ? <Link to={to}>{label}</Link> : <span>{label}</span>}
    {onClick && (
      <button type="button" onClick={onClick}>
        <i className="icon icon-close2" />
      </button>
    )}
  </StyledDiv>
);

export default PrimaryTag;
