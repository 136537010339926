import React, { useEffect, useState } from 'react';
import { STATUS } from 'constants/constants';

const RenderStatusOption = ({
  isStatusSelected,
  onClearFilter,
  onSelectFilter,
  optionName,
}) => {
  const handleSelection = (e) => {
    e.stopPropagation();
    if (isStatusSelected) {
      onClearFilter({
        type: STATUS,
        value: optionName,
      });
    } else {
      onSelectFilter({
        type: STATUS,
        value: optionName,
      });
    }
  };
  const itemkey = `status_option_${optionName}`;
  return (
    <span className="dropdown-item">
      <div className="checkbox checkbox-primary check-container">
        <label htmlFor={itemkey} className="check-container">
          <span className="pl-2 text-capitalize">{optionName}</span>
          <input
            checked={isStatusSelected}
            onChange={handleSelection}
            type="checkbox"
            id={itemkey}
            name={itemkey}
          />
          <span className="checkmark" />
        </label>
      </div>
    </span>
  );
};

const StatusTypeFilter = ({
  statusOptions,
  onSelectFilter,
  selected = [],
  onClearFilter,
  showFilterOptions = false,
  setFilterInFocus,
}) => {
  const isSelected =
    Array.isArray(selected) && selected.length > 0 ? 'active' : '';
  const [filterActive, setFilterActive] = useState(showFilterOptions);

  const toggleFilterVisible = (e = {}) => {
    if (e.stopPropagation) e.stopPropagation();
    setFilterActive(!filterActive);
    if (setFilterInFocus) setFilterInFocus(STATUS);
  };

  useEffect(() => {
    setFilterActive(showFilterOptions);
  }, [showFilterOptions]);

  return (
    <div className="BillingFilter__Status dropdown">
      <button
        className={`btn btn-sm btn-outline-light dropdown-toggle ${isSelected} ${
          filterActive ? 'show' : ''
        }`}
        type="button"
        id="statusFilter"
        onClick={toggleFilterVisible}
        aria-haspopup="true"
        aria-expanded="false"
      >
        {STATUS}
      </button>
      {!Array.isArray(statusOptions) ? null : (
        <div
          className={`dropdown-menu ${filterActive ? 'show' : ''}`}
          aria-labelledby="statusFilter"
          role="presentation"
        >
          {statusOptions.map((optionName) => (
            <RenderStatusOption
              isStatusSelected={selected.includes(optionName)}
              onClearFilter={onClearFilter}
              onSelectFilter={onSelectFilter}
              optionName={optionName}
              key={`status_option_${optionName}`}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default StatusTypeFilter;
