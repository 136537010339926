/* eslint-disable react-hooks/exhaustive-deps */
import { Paper, Snackbar, Stack, Typography } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import liscioLogo from 'images/liscio_logo.png';
import { Link } from 'react-router-dom-latest';
import { useSMSNotificationBar } from 'hooks/sms-hooks';

/**
 * This component is used to display a Snackbar message
 * when there are SMS Notifications.
 */
export default function SMSNotificationBar() {
  const { isOpen, dismissNotification, message, url } = useSMSNotificationBar();

  return (
    <Snackbar
      open={isOpen}
      message={message}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Paper
        elevation={4}
        sx={{
          backgroundColor: 'common.contrastBackground',
          width: '100%',
          padding: '1rem 2rem',
        }}
      >
        <Stack
          direction="row"
          gap={10}
          display="flex"
          justifyContent="space-between"
        >
          <Stack direction="row" gap={2}>
            <img
              src={liscioLogo}
              alt="Liscio Logo"
              style={{
                height: '15px',
                width: '20px',
                marginTop: '0.2rem',
              }}
            />
            <Typography variant="body1" fontWeight={600}>
              {message}
            </Typography>
          </Stack>
          <Stack direction="row" gap={2}>
            <Link to={url} style={{ display: 'block', padding: '0px' }}>
              <Typography variant="body1" fontWeight={500}>
                Show
              </Typography>
            </Link>
            <CloseOutlinedIcon
              onClick={dismissNotification}
              fontSize="medium"
              aria-label="close icon"
              sx={{ fontWeight: 700, marginTop: '0.2rem' }}
            />
          </Stack>
        </Stack>
      </Paper>
    </Snackbar>
  );
}
