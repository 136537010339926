import { Box, Stack, Typography } from '@mui/material';
import { useDraftsBulkSend } from 'components/Requests/state/drafts-bulk-send-state';
import CustomDatePicker from 'wrappers/DatePickerUtil';

export const DraftBulkSendDatePicker = () => {
  const { dueDate, setDueDate } = useDraftsBulkSend();

  const handleDateChange = (date: Date) => {
    if (!date) {
      return;
    }
    setDueDate(date.toISOString());
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      gap={2}
      height="70px"
    >
      <Stack direction="row" alignItems="center" gap={2} height="70px">
        <Box height="36px" width="430px" p={1} pl={3}>
          <Typography variant="subtitle1" component="span" mr={2}>
            Select a Due Date
          </Typography>
          <Typography variant="subtitle2" component="span">
            This will be applied to all Drafts without a Due Date
          </Typography>
        </Box>
        <CustomDatePicker
          toolTipLabel="Select a Due Date"
          label="Select a Due Date"
          selected={dueDate ? new Date(dueDate) : null}
          onChange={handleDateChange}
          minDate={new Date()}
          ariaLabelledBy="send-due-date"
        />
      </Stack>
    </Stack>
  );
};
