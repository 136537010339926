/* eslint-disable import/prefer-default-export */
import { EditorState, convertFromRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { options, removeBreakElementinList } from './NotesUtilities';

export const validDraftText = (comment) => {
  let output = comment ? comment.toString() : '';
  let haveImages = false;
  if (comment && comment.entityMap && Object.keys(comment.entityMap).length) {
    haveImages = true;
  }
  if (comment && typeof comment === 'object') {
    const content = EditorState.createWithContent(
      convertFromRaw(comment),
    ).getCurrentContent();
    const isEditorEmpty = !content.hasText();
    const currentPlainText = content.getPlainText();
    const lengthOfEditorContent = currentPlainText.length;
    const lengthOfTrimmedContent = currentPlainText.trim().length;
    const isContainOnlySpaces = !isEditorEmpty && !lengthOfTrimmedContent;
    if ((lengthOfEditorContent && !isContainOnlySpaces) || haveImages) {
      output = removeBreakElementinList(
        stateToHTML(
          EditorState.createWithContent(
            convertFromRaw(comment),
          ).getCurrentContent(),
          options,
        ),
      );
    } else if (isContainOnlySpaces) {
      output = '';
    }
  }

  if (typeof output === 'object' || output === '[object Object]') {
    // for empty object
    output = '';
  }
  if (output && output.length > 0) {
    // Comment = Comment.replace(/\n/g, ' <br> ');
  }
  return output;
};
