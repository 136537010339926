import React from 'react';

export default class RedirectTaskDetail extends React.Component {
  componentDidMount() {
    /* Push to the normal Task detail route, when someone follows the link sent to their
    email's inbox, i.e /tasks/__id__ */
    const id = window.location.pathname.split('/').pop();
    /* Push user to the real task-detail page with the alternate base64 encoded string id. */
    this.props.navigate(`/task/detail/${atob(id)}`);
  }

  render() {
    /* We're using this component to redirect to other route, so no logic needed here. */
    return <div />;
  }
}
