import { useState } from 'react';
import { getSiteHostname } from 'utilities/utils';

export const getStorageDataSnapshot: () => Object | null = () => {
  const dataString = window.localStorage.getItem(`${getSiteHostname()} data`);
  try {
    if (!dataString) {
      return null;
    }
    return JSON.parse(dataString);
  } catch {
    // @TODO consider Sentry error here...
    return null;
  }
};

export const useStorageData = () => {
  const [storageData] = useState<Object | null>(getStorageDataSnapshot());

  // removed the storage eventlistener as it was only being fired in
  // different tabs which caused memory leaks/crashes.
  // window.addEventListener('storage', onStorageChange); <--doesn't affect current tab
  // https://developer.mozilla.org/en-US/docs/Web/API/Window/storage_event

  return {
    storageData,
    hasStorageData: Boolean(storageData),
  };
};
