import { useCallback } from 'react';
import { useLDClient } from 'launchdarkly-react-client-sdk';

export const mapHomeDataToContext = (data: Home['data']) => ({
  kind: 'multi',
  app: {
    key: 'liscio-refactor',
  },
  firm: {
    key: data.company_url,
    url: data.company_url,
    name: data.cpa_name,
    id: data.cpa_id,
  },
  role: {
    key: data.role,
  },
});

/**
 * Helper hook for updating launchdarkly context from home data
 */
export const useLaunchDarklyIdentify = () => {
  const ldClient = useLDClient();

  const identify = useCallback(
    async (data: Home['data']) => {
      if (ldClient) {
        const context = mapHomeDataToContext(data);
        await ldClient.identify(context);
      }
    },
    [ldClient],
  );

  return { identify };
};
