/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * API V5
 * OpenAPI spec version: v5
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  AuthorizedCpaUser,
  CpaSwitcher,
  EmployeeSchema,
  ErrorObjectArray,
  ForbiddenErrorObject,
  GetApiV5FirmsEmployees400,
  GetApiV5FirmsEmployeesCheckPrimaryEmail200,
  GetApiV5FirmsEmployeesCheckPrimaryEmailParams,
  GetApiV5FirmsEmployeesParams,
  PostApiV5FirmsCpaSwitcher400,
  PostApiV5FirmsCpaSwitcher401,
  PostApiV5FirmsEmployees200,
  PostApiV5FirmsEmployees400,
  PostApiV5FirmsEmployeesBody,
  PutApiV5FirmsEmployeesIdBody,
  Uuid
} from '.././schemas'
import postApiV5AuthCpaSwitcherMutator from '../../configOrvalAPIV5';
import type { ErrorType as PostApiV5AuthCpaSwitcherErrorType } from '../../configOrvalAPIV5';
import postApiV5FirmsCpaSwitcherMutator from '../../configOrvalAPIV5';
import type { ErrorType as PostApiV5FirmsCpaSwitcherErrorType } from '../../configOrvalAPIV5';
import getApiV5FirmsEmployeesMutator from '../../configOrvalAPIV5';
import type { ErrorType as GetApiV5FirmsEmployeesErrorType } from '../../configOrvalAPIV5';
import postApiV5FirmsEmployeesMutator from '../../configOrvalAPIV5';
import type { ErrorType as PostApiV5FirmsEmployeesErrorType } from '../../configOrvalAPIV5';
import putApiV5FirmsEmployeesIdDeactivateMutator from '../../configOrvalAPIV5';
import type { ErrorType as PutApiV5FirmsEmployeesIdDeactivateErrorType } from '../../configOrvalAPIV5';
import putApiV5FirmsEmployeesIdReactivateMutator from '../../configOrvalAPIV5';
import type { ErrorType as PutApiV5FirmsEmployeesIdReactivateErrorType } from '../../configOrvalAPIV5';
import getApiV5FirmsEmployeesCheckPrimaryEmailMutator from '../../configOrvalAPIV5';
import type { ErrorType as GetApiV5FirmsEmployeesCheckPrimaryEmailErrorType } from '../../configOrvalAPIV5';
import putApiV5FirmsEmployeesIdMutator from '../../configOrvalAPIV5';
import type { ErrorType as PutApiV5FirmsEmployeesIdErrorType } from '../../configOrvalAPIV5';



/**
 * @summary switches to new cpa
 */
export const postApiV5AuthCpaSwitcher = (
    cpaSwitcher: CpaSwitcher,
 ) => {
      
      
      return postApiV5AuthCpaSwitcherMutator<AuthorizedCpaUser>(
      {url: `/api/v5/auth/cpa_switcher`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: cpaSwitcher
    },
      );
    }
  


export const getPostApiV5AuthCpaSwitcherMutationOptions = <TError = PostApiV5AuthCpaSwitcherErrorType<ForbiddenErrorObject>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV5AuthCpaSwitcher>>, TError,{data: CpaSwitcher}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postApiV5AuthCpaSwitcher>>, TError,{data: CpaSwitcher}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV5AuthCpaSwitcher>>, {data: CpaSwitcher}> = (props) => {
          const {data} = props ?? {};

          return  postApiV5AuthCpaSwitcher(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiV5AuthCpaSwitcherMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV5AuthCpaSwitcher>>>
    export type PostApiV5AuthCpaSwitcherMutationBody = CpaSwitcher
    export type PostApiV5AuthCpaSwitcherMutationError = PostApiV5AuthCpaSwitcherErrorType<ForbiddenErrorObject>

    /**
 * @summary switches to new cpa
 */
export const usePostApiV5AuthCpaSwitcher = <TError = PostApiV5AuthCpaSwitcherErrorType<ForbiddenErrorObject>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV5AuthCpaSwitcher>>, TError,{data: CpaSwitcher}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postApiV5AuthCpaSwitcher>>,
        TError,
        {data: CpaSwitcher},
        TContext
      > => {

      const mutationOptions = getPostApiV5AuthCpaSwitcherMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary switches to new cpa
 */
export const postApiV5FirmsCpaSwitcher = (
    uuid: Uuid,
 ) => {
      
      
      return postApiV5FirmsCpaSwitcherMutator<AuthorizedCpaUser>(
      {url: `/api/v5/firms/cpa_switcher`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: uuid
    },
      );
    }
  


export const getPostApiV5FirmsCpaSwitcherMutationOptions = <TError = PostApiV5FirmsCpaSwitcherErrorType<PostApiV5FirmsCpaSwitcher400 | PostApiV5FirmsCpaSwitcher401>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV5FirmsCpaSwitcher>>, TError,{data: Uuid}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postApiV5FirmsCpaSwitcher>>, TError,{data: Uuid}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV5FirmsCpaSwitcher>>, {data: Uuid}> = (props) => {
          const {data} = props ?? {};

          return  postApiV5FirmsCpaSwitcher(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiV5FirmsCpaSwitcherMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV5FirmsCpaSwitcher>>>
    export type PostApiV5FirmsCpaSwitcherMutationBody = Uuid
    export type PostApiV5FirmsCpaSwitcherMutationError = PostApiV5FirmsCpaSwitcherErrorType<PostApiV5FirmsCpaSwitcher400 | PostApiV5FirmsCpaSwitcher401>

    /**
 * @summary switches to new cpa
 */
export const usePostApiV5FirmsCpaSwitcher = <TError = PostApiV5FirmsCpaSwitcherErrorType<PostApiV5FirmsCpaSwitcher400 | PostApiV5FirmsCpaSwitcher401>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV5FirmsCpaSwitcher>>, TError,{data: Uuid}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postApiV5FirmsCpaSwitcher>>,
        TError,
        {data: Uuid},
        TContext
      > => {

      const mutationOptions = getPostApiV5FirmsCpaSwitcherMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Returns employees for the Cpa of the currently logged in user
 */
export const getApiV5FirmsEmployees = (
    params?: GetApiV5FirmsEmployeesParams,
 signal?: AbortSignal
) => {
      
      
      return getApiV5FirmsEmployeesMutator<EmployeeSchema>(
      {url: `/api/v5/firms/employees`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getGetApiV5FirmsEmployeesQueryKey = (params?: GetApiV5FirmsEmployeesParams,) => {
    return [`/api/v5/firms/employees`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiV5FirmsEmployeesQueryOptions = <TData = Awaited<ReturnType<typeof getApiV5FirmsEmployees>>, TError = GetApiV5FirmsEmployeesErrorType<GetApiV5FirmsEmployees400 | ForbiddenErrorObject>>(params?: GetApiV5FirmsEmployeesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV5FirmsEmployees>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV5FirmsEmployeesQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV5FirmsEmployees>>> = ({ signal }) => getApiV5FirmsEmployees(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiV5FirmsEmployees>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiV5FirmsEmployeesQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV5FirmsEmployees>>>
export type GetApiV5FirmsEmployeesQueryError = GetApiV5FirmsEmployeesErrorType<GetApiV5FirmsEmployees400 | ForbiddenErrorObject>


/**
 * @summary Returns employees for the Cpa of the currently logged in user
 */

export function useGetApiV5FirmsEmployees<TData = Awaited<ReturnType<typeof getApiV5FirmsEmployees>>, TError = GetApiV5FirmsEmployeesErrorType<GetApiV5FirmsEmployees400 | ForbiddenErrorObject>>(
 params?: GetApiV5FirmsEmployeesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV5FirmsEmployees>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiV5FirmsEmployeesQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Creates a new employee for the Cpa of the currently logged in user
 */
export const postApiV5FirmsEmployees = (
    postApiV5FirmsEmployeesBody: PostApiV5FirmsEmployeesBody,
 ) => {
      
      
      return postApiV5FirmsEmployeesMutator<PostApiV5FirmsEmployees200>(
      {url: `/api/v5/firms/employees`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postApiV5FirmsEmployeesBody
    },
      );
    }
  


export const getPostApiV5FirmsEmployeesMutationOptions = <TError = PostApiV5FirmsEmployeesErrorType<PostApiV5FirmsEmployees400 | ForbiddenErrorObject>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV5FirmsEmployees>>, TError,{data: PostApiV5FirmsEmployeesBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postApiV5FirmsEmployees>>, TError,{data: PostApiV5FirmsEmployeesBody}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV5FirmsEmployees>>, {data: PostApiV5FirmsEmployeesBody}> = (props) => {
          const {data} = props ?? {};

          return  postApiV5FirmsEmployees(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiV5FirmsEmployeesMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV5FirmsEmployees>>>
    export type PostApiV5FirmsEmployeesMutationBody = PostApiV5FirmsEmployeesBody
    export type PostApiV5FirmsEmployeesMutationError = PostApiV5FirmsEmployeesErrorType<PostApiV5FirmsEmployees400 | ForbiddenErrorObject>

    /**
 * @summary Creates a new employee for the Cpa of the currently logged in user
 */
export const usePostApiV5FirmsEmployees = <TError = PostApiV5FirmsEmployeesErrorType<PostApiV5FirmsEmployees400 | ForbiddenErrorObject>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV5FirmsEmployees>>, TError,{data: PostApiV5FirmsEmployeesBody}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postApiV5FirmsEmployees>>,
        TError,
        {data: PostApiV5FirmsEmployeesBody},
        TContext
      > => {

      const mutationOptions = getPostApiV5FirmsEmployeesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Deactivates an employee for the Cpa of the currently logged in user
 */
export const putApiV5FirmsEmployeesIdDeactivate = (
    id: Uuid,
 ) => {
      
      
      return putApiV5FirmsEmployeesIdDeactivateMutator<EmployeeSchema>(
      {url: `/api/v5/firms/employees/${id}/deactivate`, method: 'PUT'
    },
      );
    }
  


export const getPutApiV5FirmsEmployeesIdDeactivateMutationOptions = <TError = PutApiV5FirmsEmployeesIdDeactivateErrorType<ForbiddenErrorObject | ErrorObjectArray>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV5FirmsEmployeesIdDeactivate>>, TError,{id: Uuid}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof putApiV5FirmsEmployeesIdDeactivate>>, TError,{id: Uuid}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiV5FirmsEmployeesIdDeactivate>>, {id: Uuid}> = (props) => {
          const {id} = props ?? {};

          return  putApiV5FirmsEmployeesIdDeactivate(id,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutApiV5FirmsEmployeesIdDeactivateMutationResult = NonNullable<Awaited<ReturnType<typeof putApiV5FirmsEmployeesIdDeactivate>>>
    
    export type PutApiV5FirmsEmployeesIdDeactivateMutationError = PutApiV5FirmsEmployeesIdDeactivateErrorType<ForbiddenErrorObject | ErrorObjectArray>

    /**
 * @summary Deactivates an employee for the Cpa of the currently logged in user
 */
export const usePutApiV5FirmsEmployeesIdDeactivate = <TError = PutApiV5FirmsEmployeesIdDeactivateErrorType<ForbiddenErrorObject | ErrorObjectArray>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV5FirmsEmployeesIdDeactivate>>, TError,{id: Uuid}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof putApiV5FirmsEmployeesIdDeactivate>>,
        TError,
        {id: Uuid},
        TContext
      > => {

      const mutationOptions = getPutApiV5FirmsEmployeesIdDeactivateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Reactivates an employee
 */
export const putApiV5FirmsEmployeesIdReactivate = (
    id: Uuid,
 ) => {
      
      
      return putApiV5FirmsEmployeesIdReactivateMutator<EmployeeSchema>(
      {url: `/api/v5/firms/employees/${id}/reactivate`, method: 'PUT'
    },
      );
    }
  


export const getPutApiV5FirmsEmployeesIdReactivateMutationOptions = <TError = PutApiV5FirmsEmployeesIdReactivateErrorType<ErrorObjectArray | ForbiddenErrorObject>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV5FirmsEmployeesIdReactivate>>, TError,{id: Uuid}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof putApiV5FirmsEmployeesIdReactivate>>, TError,{id: Uuid}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiV5FirmsEmployeesIdReactivate>>, {id: Uuid}> = (props) => {
          const {id} = props ?? {};

          return  putApiV5FirmsEmployeesIdReactivate(id,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutApiV5FirmsEmployeesIdReactivateMutationResult = NonNullable<Awaited<ReturnType<typeof putApiV5FirmsEmployeesIdReactivate>>>
    
    export type PutApiV5FirmsEmployeesIdReactivateMutationError = PutApiV5FirmsEmployeesIdReactivateErrorType<ErrorObjectArray | ForbiddenErrorObject>

    /**
 * @summary Reactivates an employee
 */
export const usePutApiV5FirmsEmployeesIdReactivate = <TError = PutApiV5FirmsEmployeesIdReactivateErrorType<ErrorObjectArray | ForbiddenErrorObject>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV5FirmsEmployeesIdReactivate>>, TError,{id: Uuid}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof putApiV5FirmsEmployeesIdReactivate>>,
        TError,
        {id: Uuid},
        TContext
      > => {

      const mutationOptions = getPutApiV5FirmsEmployeesIdReactivateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Checks if an email is already in use by an employee or contact
 */
export const getApiV5FirmsEmployeesCheckPrimaryEmail = (
    params: GetApiV5FirmsEmployeesCheckPrimaryEmailParams,
 signal?: AbortSignal
) => {
      
      
      return getApiV5FirmsEmployeesCheckPrimaryEmailMutator<GetApiV5FirmsEmployeesCheckPrimaryEmail200>(
      {url: `/api/v5/firms/employees/check_primary_email`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getGetApiV5FirmsEmployeesCheckPrimaryEmailQueryKey = (params: GetApiV5FirmsEmployeesCheckPrimaryEmailParams,) => {
    return [`/api/v5/firms/employees/check_primary_email`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiV5FirmsEmployeesCheckPrimaryEmailQueryOptions = <TData = Awaited<ReturnType<typeof getApiV5FirmsEmployeesCheckPrimaryEmail>>, TError = GetApiV5FirmsEmployeesCheckPrimaryEmailErrorType<ErrorObjectArray | ForbiddenErrorObject>>(params: GetApiV5FirmsEmployeesCheckPrimaryEmailParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV5FirmsEmployeesCheckPrimaryEmail>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV5FirmsEmployeesCheckPrimaryEmailQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV5FirmsEmployeesCheckPrimaryEmail>>> = ({ signal }) => getApiV5FirmsEmployeesCheckPrimaryEmail(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiV5FirmsEmployeesCheckPrimaryEmail>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiV5FirmsEmployeesCheckPrimaryEmailQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV5FirmsEmployeesCheckPrimaryEmail>>>
export type GetApiV5FirmsEmployeesCheckPrimaryEmailQueryError = GetApiV5FirmsEmployeesCheckPrimaryEmailErrorType<ErrorObjectArray | ForbiddenErrorObject>


/**
 * @summary Checks if an email is already in use by an employee or contact
 */

export function useGetApiV5FirmsEmployeesCheckPrimaryEmail<TData = Awaited<ReturnType<typeof getApiV5FirmsEmployeesCheckPrimaryEmail>>, TError = GetApiV5FirmsEmployeesCheckPrimaryEmailErrorType<ErrorObjectArray | ForbiddenErrorObject>>(
 params: GetApiV5FirmsEmployeesCheckPrimaryEmailParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV5FirmsEmployeesCheckPrimaryEmail>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiV5FirmsEmployeesCheckPrimaryEmailQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Updates an employee
 */
export const putApiV5FirmsEmployeesId = (
    id: Uuid,
    putApiV5FirmsEmployeesIdBody: PutApiV5FirmsEmployeesIdBody,
 ) => {
      
      
      return putApiV5FirmsEmployeesIdMutator<EmployeeSchema>(
      {url: `/api/v5/firms/employees/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: putApiV5FirmsEmployeesIdBody
    },
      );
    }
  


export const getPutApiV5FirmsEmployeesIdMutationOptions = <TError = PutApiV5FirmsEmployeesIdErrorType<ForbiddenErrorObject | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV5FirmsEmployeesId>>, TError,{id: Uuid;data: PutApiV5FirmsEmployeesIdBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof putApiV5FirmsEmployeesId>>, TError,{id: Uuid;data: PutApiV5FirmsEmployeesIdBody}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiV5FirmsEmployeesId>>, {id: Uuid;data: PutApiV5FirmsEmployeesIdBody}> = (props) => {
          const {id,data} = props ?? {};

          return  putApiV5FirmsEmployeesId(id,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutApiV5FirmsEmployeesIdMutationResult = NonNullable<Awaited<ReturnType<typeof putApiV5FirmsEmployeesId>>>
    export type PutApiV5FirmsEmployeesIdMutationBody = PutApiV5FirmsEmployeesIdBody
    export type PutApiV5FirmsEmployeesIdMutationError = PutApiV5FirmsEmployeesIdErrorType<ForbiddenErrorObject | void>

    /**
 * @summary Updates an employee
 */
export const usePutApiV5FirmsEmployeesId = <TError = PutApiV5FirmsEmployeesIdErrorType<ForbiddenErrorObject | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV5FirmsEmployeesId>>, TError,{id: Uuid;data: PutApiV5FirmsEmployeesIdBody}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof putApiV5FirmsEmployeesId>>,
        TError,
        {id: Uuid;data: PutApiV5FirmsEmployeesIdBody},
        TContext
      > => {

      const mutationOptions = getPutApiV5FirmsEmployeesIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    