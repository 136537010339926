import { Edit, Delete } from '@mui/icons-material';
import { ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import {
  IN_APP,
  REQUESTS_PAGE_ROUTES,
} from 'components/Requests/requests.constants';
import {
  useCreateWorkflowFromWorkflowTemplateMutation,
  useDeleteWorkflowTemplateMutation,
} from 'hooks/workflow-hooks';
import { useNavigate } from 'react-router-dom-latest';
import { TooltipStyled } from 'ui/TooltipStyled/TooltipStyled';
import { AlertMessage, getFeatureGates } from 'utilities/utils';

interface WorkflowTemplateListItemMenuProps {
  isSystemTemplate: boolean;
  workflowTemplateId: string;
  workflowTemplateOptionsAnchorEl: Element | null;
  handleWorkflowTemplateOptionsClose: (
    event: React.MouseEvent<Element>,
  ) => void;
  handleTemplatesRefetch: () => void;
}

export function WorkflowTemplateListItemMenu({
  isSystemTemplate,
  workflowTemplateId,
  workflowTemplateOptionsAnchorEl,
  handleWorkflowTemplateOptionsClose,
  handleTemplatesRefetch,
}: WorkflowTemplateListItemMenuProps) {
  const navigate = useNavigate();

  const { mutate: createWorkflowTemplate } =
    useCreateWorkflowFromWorkflowTemplateMutation();

  const { mutate: deleteWorkflowTemplate } =
    useDeleteWorkflowTemplateMutation();

  const handleEditClicked = () => {
    createWorkflowTemplate(
      { id: workflowTemplateId! },

      {
        onSuccess: (response) => {
          const id =
            response?.createWorkflowsFromWorkflowTemplate?.workflows?.[0]?.id;

          if (id) {
            navigate(
              `${REQUESTS_PAGE_ROUTES.builder}/${id}?template=${workflowTemplateId}`,
              { state: { from: IN_APP } },
            );
            return;
          }

          AlertMessage('Unable to edit template');
        },
      },
    );
  };

  const handleDeleteClicked = (event: React.MouseEvent<Element>) => {
    deleteWorkflowTemplate(
      { workflowTemplateId: workflowTemplateId! },
      {
        onSuccess: (response) => {
          if (response?.deleteWorkflowTemplate?.errors?.length) {
            AlertMessage('Unable to delete template');
            return;
          }
          handleWorkflowTemplateOptionsClose(event);
          handleTemplatesRefetch();
        },
      },
    );
  };

  if (!workflowTemplateId) {
    return null;
  }

  const featureGates = getFeatureGates();

  const disabledText = isSystemTemplate
    ? 'System Templates cannot be edited.'
    : 'Upgrade to Pro Tier to create custom templates.';

  return (
    <Menu
      open={Boolean(workflowTemplateId)}
      onClose={handleWorkflowTemplateOptionsClose}
      anchorEl={workflowTemplateOptionsAnchorEl}
      aria-label="workflow options menu"
    >
      {featureGates.custom_templates && !isSystemTemplate ? (
        <>
          <MenuItem onClick={handleEditClicked}>
            <ListItemIcon>
              <Edit />
            </ListItemIcon>
            <ListItemText>Edit Template</ListItemText>
          </MenuItem>
          <MenuItem onClick={(e) => handleDeleteClicked(e)}>
            <ListItemIcon>
              <Delete />
            </ListItemIcon>
            <ListItemText>Delete Template</ListItemText>
          </MenuItem>
        </>
      ) : (
        <TooltipStyled title={disabledText}>
          <div>
            <MenuItem disabled>
              <ListItemIcon>
                <Edit />
              </ListItemIcon>
              <ListItemText>Edit Template</ListItemText>
            </MenuItem>
            <MenuItem disabled>
              <ListItemIcon>
                <Delete />
              </ListItemIcon>
              <ListItemText>Delete Template</ListItemText>
            </MenuItem>
          </div>
        </TooltipStyled>
      )}
    </Menu>
  );
}
