import axios from 'axios';
import {
  GET_THREAD_MESSAGES,
  CREATE_TASK,
  CREATE_NOTE,
} from 'constants/constants';
import React from 'react';
import CreateNotes from 'components/Notes/CreateNotes';
import NewTask from 'components/Tasks/Create/NewTask';

export const tabNames = {
  INBOX: 'inbox',
  SENT: 'sent',
  ALERT: 'alert',
  DRAFT: 'draft',
  ARCHIVE: 'archive',
  PINNED: 'pinned',
  ALL_MSGS: 'all_messages',
};

export const getMessageThread = async (message = { thread_id: '' }) => {
  try {
    const resp = await axios.get(
      `${GET_THREAD_MESSAGES}/${encodeURIComponent(message.thread_id)}`,
    );
    const threads = resp?.data?.message;
    if (Array.isArray(threads) && threads.length > 0) {
      return {
        ...gatherThreadDetails(threads),
        account_data: resp?.data?.account_data,
      };
    }
    return message;
  } catch (error) {
    console.log('api error', error);
    return message;
  }
};

export const gatherThreadDetails = (threads = [], account_data = {}) => {
  const combinedMessage = {
    content: ``,
    documents: [],
    account_data,
  };
  threads.forEach((message = {}) => {
    const recipients = (
      Array.isArray(message.is_recipient) ? message.is_recipient : []
    ).join(', ');
    combinedMessage.title = combinedMessage.title || message.title;
    combinedMessage.content += `<b>${message.sender}</b><br/>
        <small><b>Recipients:</b> ${recipients}</small>
        `;
    combinedMessage.content +=
      message.content || message.content_for_web_display;
    combinedMessage.documents.push(...message.documents);
  });
  return combinedMessage;
};

export const SelectedOptionForm = (props = {}) => {
  const {
    selectedOption = {},
    closeActionsModel,
    message: {
      title = '',
      content = '',
      content_for_web_display = '',
      documents = [],
      account_data = {},
      contact_data = {},
    },
  } = props;
  const documentsAttached = documents.map((doc = {}) => ({
    ...doc,
    doc_name: doc.display_name || doc.file_name,
    isAttachmentFromEmailOrMessage: true,
  }));
  switch (selectedOption.value) {
    case CREATE_TASK:
      return (
        <NewTask
          closeModalInEmailPage={closeActionsModel}
          defaultType="to_do"
          taskTitle={title}
          taskDescription={content_for_web_display || content}
          taskAttachments={documentsAttached}
          viewContext="message"
          account_data={props?.account_data ? props.account_data : account_data}
          contact_data={props?.contact_data ? props.contact_data : contact_data}
        />
      );
    case CREATE_NOTE:
      return (
        <CreateNotes
          closeModalInEmailPage={closeActionsModel}
          noteTitle={title}
          noteDescription={content_for_web_display || content}
          notesAttachments={documentsAttached}
          viewContext="message"
          account_data={props?.account_data ? props.account_data : account_data}
          contact_data={props?.contact_data ? props.contact_data : contact_data}
        />
      );
    default:
      return null;
  }
};
