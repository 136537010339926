/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import NewTaskEmail from 'components/Tasks/Create/NewTaskEmail';
import ActionsModal from './ActionsModal';

function CreateTaskFromEmail(props) {
  const {
    showModal,
    cancelActionsModel,
    showModalFor,
    getModelTitle,
    getModelViewContext,
    emailDetails,
    addEmails,
    closeActionsModel,
    email,
    handleEmailActions,
  } = props;
  if (showModal) {
    return (
      <ActionsModal
        isOpen={showModal}
        onCancel={cancelActionsModel}
        title={getModelTitle(showModalFor)}
        style={{ overflow: 'auto', maxHeight: '85vh' }}
        showModalFooter={getModelViewContext(showModalFor) !== ''}
        handleSuccessBtn={() =>
          addEmails(
            emailDetails.email_id,
            getModelViewContext(showModalFor),
            emailDetails.entity_email_thread_id,
          )
        }
      >
        <NewTaskEmail
          {...props}
          closeModalInEmailPage={closeActionsModel}
          emailDetails={email}
          refreshEmailListPage={() => handleEmailActions('done', email)}
          viewContext="email"
          defaultType="to_do"
          cancelActionsModel={cancelActionsModel}
        />
      </ActionsModal>
    );
  }
  return null;
}

export default CreateTaskFromEmail;
