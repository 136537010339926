import { MoreHoriz } from '@mui/icons-material';
import questionnaireImage from 'images/questionnaire.svg';
import { WorkflowTemplateFragment } from 'gql/graphql';
import { ButtonBase, Box, Typography, useTheme } from '@mui/material';

interface WorkflowTemplateListItemProps {
  onClick: (workflowTemplate: WorkflowTemplateFragment) => void;
  workflowTemplate: WorkflowTemplateFragment;
  onWorkflowTemplateOptionsClick?: (event: React.MouseEvent<Element>) => void;
}

export function WorkflowTemplateListItem({
  workflowTemplate,
  onClick,
  onWorkflowTemplateOptionsClick,
}: WorkflowTemplateListItemProps) {
  const theme = useTheme();

  const handleWorkflowTemplateOptionsClick = (
    event: React.MouseEvent<Element>,
  ) => {
    event.stopPropagation();
    onWorkflowTemplateOptionsClick?.(event);
  };

  if (!workflowTemplate) {
    return null;
  }

  return (
    <ButtonBase
      onClick={() => onClick(workflowTemplate)}
      sx={{ width: '279px' }}
      aria-label={workflowTemplate.displayName}
    >
      <Box width="100%">
        <Box
          borderRadius="4px 4px 4px 4px"
          sx={() => ({
            border: `1px solid ${theme.palette.common.secondary[200]}`,
          })}
        >
          <Box
            bgcolor="common.primary.200"
            width="277px"
            height="130px"
            position="relative"
            borderRadius="4px 4px 0px 0px"
          >
            <Box
              component="img"
              loading="lazy"
              src={questionnaireImage}
              alt=""
            />
            <Box
              position="absolute"
              right="0"
              top="0"
              left="0"
              display="flex"
              justifyContent="end"
              alignItems="center"
              padding="15px"
            >
              <ButtonBase
                aria-label={`${workflowTemplate.displayName} Options`}
                data-workflowTemplate-id={workflowTemplate.id}
                onClick={handleWorkflowTemplateOptionsClick}
              >
                <MoreHoriz />
              </ButtonBase>
            </Box>
          </Box>
          <Box
            padding="15px"
            bgcolor="common.white"
            borderRadius="0 0 4px 4px"
            position="relative"
          >
            <Typography variant="subtitle1" noWrap>
              {workflowTemplate.displayName}
            </Typography>
          </Box>
        </Box>
      </Box>
    </ButtonBase>
  );
}
