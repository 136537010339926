import {
  WorkflowForConditionIdQueryQuery,
  WorkflowItem2Fragment,
  WorkflowItemCondition2Fragment,
  WorkflowQueryQuery,
  WorkflowSection,
} from 'gql/graphql';
import { SectionItemTuple } from 'components/Requests/components/RequestsBuilder/components/QuestionsBuilder';
import { QueryClient } from '@tanstack/react-query';
import { workflowQueryDocument } from 'api/workflows';

export function getParentItemNumberFromIndex(
  index: SectionItemTuple,
  sections: WorkflowSection[],
) {
  const [sectionIndex, itemIndex] = index;
  if (sectionIndex === null || itemIndex === null) {
    return undefined;
  }
  let count = 0;
  sections?.some((section, sIdx) => {
    if (sIdx === sectionIndex) {
      if ((section.items2?.length ?? -1) <= itemIndex) {
        count = -1;
      }
      return true;
    }
    count += section.items2?.length ?? 0;
    return false;
  });
  return count === -1 ? -1 : count + itemIndex + 1;
}

export function getWorkflowItemWithIndexFromCache(
  queryClient: QueryClient,
  activeIndex: SectionItemTuple,
  workflowId: string,
): [WorkflowItem2Fragment | null, WorkflowItemCondition2Fragment['items']] {
  const [sectionIndex, itemIndex] = activeIndex;
  if (sectionIndex === null || itemIndex === null) {
    return [null, []];
  }
  const key = [
    (workflowQueryDocument.definitions[0] as any).name.value,
    { id: workflowId },
  ];

  const oldWorkflows = queryClient.getQueryData<WorkflowQueryQuery>(key);

  if (!oldWorkflows) {
    return [null, []];
  }

  const parentItem =
    oldWorkflows?.workflow?.sections?.[sectionIndex]?.items2?.[itemIndex];

  if (parentItem === undefined) {
    return [null, []];
  }

  const conditionalItems = (
    parentItem.conditions?.map((condition) => condition.items ?? []) ?? []
  ).flat();

  return [parentItem, conditionalItems];
}

export function getWorkflowItemWithIdFromCache(
  queryClient: QueryClient,
  activeId: string,
  workflowId: string,
): [WorkflowItem2Fragment | null, WorkflowItemCondition2Fragment['items']] {
  const key = [
    (workflowQueryDocument.definitions[0] as any).name.value,
    { id: workflowId },
  ];

  const oldWorkflows = queryClient.getQueryData<WorkflowQueryQuery>(key);

  if (!oldWorkflows) {
    return [null, []];
  }

  let sectionIndex = -1;
  let itemIndex = -1;
  let parentItem: WorkflowItem2Fragment | undefined;
  oldWorkflows?.workflow?.sections?.find((section, sIdx) => {
    parentItem = section.items2?.find((item, idx) => {
      if (item.id === activeId) {
        sectionIndex = sIdx;
        itemIndex = idx;
        return true;
      }
      return false;
    });
    return parentItem !== undefined;
  });

  if (!parentItem || sectionIndex === -1 || itemIndex === -1) {
    return [null, []];
  }

  const conds = (
    parentItem.conditions?.map((condition) => condition.items ?? []) ?? []
  ).flat();

  return [parentItem as WorkflowItem2Fragment, conds];
}

export const getItem2Conditional = (
  workflowData?: WorkflowForConditionIdQueryQuery,
  itemId?: string,
) =>
  workflowData?.workflow?.items2?.find((item) => item.id === itemId)
    ?.conditions?.[0];
