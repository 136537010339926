import { Box } from '@mui/material';
import { ItemListItem } from 'components/Requests/components/WorkflowView/components/ItemListItem/ItemListItem';
import {
  getCurrentParentValue,
  matchValues,
  showConditionalQuestion,
} from 'components/Requests/utils/conditionalUtils';
import {
  WorkflowItem2Fragment,
  WorkflowItemType2,
  WorkflowRequestFragment,
  WorkflowResponseItem,
} from 'gql/graphql';
import WorkflowSectionWrapper from 'components/Requests/components/WorkflowView/components/WorkflowSectionWrapper/WorkflowSectionWrapper';

type WorkflowSectionsProps = {
  disableEdit?: boolean;
  showAllQuestions?: boolean;
  workflowRequestData?: WorkflowRequestFragment;
  alwaysShowConditionals?: boolean;
};

export const WorkflowSections = ({
  disableEdit,
  showAllQuestions, // this is the "show unanswered questions" switch - default is true
  workflowRequestData,
  alwaysShowConditionals, // is true if the workflow is in dispatch mode
}: WorkflowSectionsProps) => {
  let questionCount = 0;

  return (
    <Box width="100%">
      <Box
        padding="0px 20px 20px 20px"
        mb="80px"
        sx={{ width: '100%', maxWidth: '700px' }}
      >
        {workflowRequestData?.workflow?.sections?.map((section) => (
          <WorkflowSectionWrapper title={section.title} key={section.id}>
            {section?.items2?.map((parentItem) => {
              const requestItem = parentItem?.requestItem;
              const responseItem = parentItem?.requestItem?.responseItem;
              const parentItemConditions = parentItem.conditions || [];
              const conditionalChildItems = parentItemConditions.flatMap(
                (x) => x.items,
              );

              // if showAllQuestions is false only show questions that have not been answered
              const showQuestion = (item: WorkflowItemType2) =>
                showAllQuestions
                  ? true
                  : !item?.requestItem?.responseItem?.value &&
                    !item?.requestItem?.responseItem?.notApplicable;

              const showParentQuestion = showQuestion(
                parentItem as WorkflowItemType2,
              );

              const showParentWhenConditionalIsUnanswered =
                conditionalChildItems.length > 0 &&
                conditionalChildItems.some(
                  (item) =>
                    !item?.requestItem?.responseItem?.value &&
                    !item?.requestItem?.responseItem?.notApplicable,
                ) &&
                matchValues(
                  parentItemConditions[0].conditionType,
                  parentItemConditions[0].matchValue,
                  getCurrentParentValue(responseItem as WorkflowResponseItem),
                );

              questionCount += 1;

              return workflowRequestData?.requestSections ? (
                <>
                  <ItemListItem
                    disableEdit={disableEdit}
                    workflowItem={parentItem}
                    workflowResponseItem={responseItem}
                    workflowRequestItem={requestItem}
                    workflowRequest={workflowRequestData}
                    position={questionCount}
                    subPosition={0}
                    key={`${parentItem.id} ${responseItem?.updatedAt}`}
                    visible={
                      showParentQuestion ||
                      showParentWhenConditionalIsUnanswered
                    }
                  />
                  {parentItemConditions.length > 0 &&
                    conditionalChildItems.map((childItem, childItemIndex) => {
                      const showChildQuestion = showQuestion(
                        childItem as WorkflowItemType2,
                      );

                      const showUnansweredConditionalQuestion =
                        showChildQuestion &&
                        showConditionalQuestion(
                          parentItemConditions[0].conditionType,
                          parentItemConditions[0].matchValue,
                          childItem as WorkflowItemType2,
                          responseItem as WorkflowResponseItem,
                        );

                      return (
                        <ItemListItem
                          disableEdit={disableEdit}
                          workflowItem={
                            childItem as unknown as WorkflowItem2Fragment
                          }
                          workflowResponseItem={
                            childItem?.requestItem?.responseItem
                          }
                          workflowRequestItem={childItem?.requestItem}
                          workflowRequest={workflowRequestData}
                          position={questionCount}
                          subPosition={childItemIndex + 1}
                          key={`${childItem?.id} ${childItem?.requestItem?.responseItem?.updatedAt}`}
                          visible={
                            alwaysShowConditionals ||
                            showUnansweredConditionalQuestion
                          }
                        />
                      );
                    })}
                </>
              ) : null;
            })}
          </WorkflowSectionWrapper>
        ))}
      </Box>
    </Box>
  );
};
