/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-script-url */
/* eslint-disable react/jsx-no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-access-state-in-setstate */
import React, { Component } from 'react';
import { Link } from 'react-router-dom-latest';
import { withRouter } from 'utilities/withRouterWrapper';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import Pagination from 'common/Pagination';
import PageNotFound from 'common/PageNotFound';
import * as Utilities from 'utilities/utils';
import { DateFilterComponent, FilterComponent } from 'common/MessageFilters';
import * as messageActions from 'actions/MessageActions';
import * as paginationActions from 'actions/PaginationActions';
import * as Config from 'constants/constants';
import * as TaskApi from 'api/TaskApi';
import { format } from 'date-fns';
import MessageList from './MessageList';
import ThreadDetails from './ThreadDetails';

const noneStyle = { display: 'none' };
const blockStyle = { display: 'block' };
const perPage = 15;
class UserMessageList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLoaded: false,
      loading: false,
      showThreadDetail: false,
      threadId: '',
      filter: 'messages',
      allContacts: [],
      selectedFilterList: {},
      searchField: '',
      recipientValue: '',
      apiUrl: '',
      contacts: [],
      from: null,
      to: null,
      attachmentCheckBox: false,
      filterBGClicked: 0,
      filterInFocus: '',
    };
    this.typingTime = null;
  }

  onClickFilterBG = () => {
    if (this.state.filterInFocus) {
      this.setState((prevState) => ({
        filterBGClicked: prevState.filterBGClicked + 1,
      }));
    }
  };

  setFilterInFocus = (filterName = '', e = {}) => {
    if (e.stopPropagation) e.stopPropagation();
    this.setState({
      filterInFocus: this.state.filterInFocus === filterName ? '' : filterName,
    });
  };

  componentDidMount() {
    const { accountId, contactId, userMsgFilters } = this.props;
    let apiUrl = '';
    if (!Utilities.isUserEmployee()) {
      this.getRelContactsAndCstOfAccounts();
    }
    if (userMsgFilters.apiUrl && userMsgFilters.apiUrl.length) {
      ({ apiUrl } = userMsgFilters);
      this.setState(userMsgFilters);
    }
    if (accountId) {
      this.props.messageActions.fetchAccountMessages(
        this,
        accountId,
        perPage,
        this.props.account_msg_page || 1,
        apiUrl,
      );
    } else if (contactId) {
      this.props.messageActions.fetchContactMessages(
        this,
        contactId,
        perPage,
        this.props.account_msg_page || 1,
        apiUrl,
      );
    }
    if (Utilities.isUserEmployee()) {
      TaskApi.getContactsOfTask(this, { limit: true, message: true }, 'notes');
    }
  }

  async getRelContactsAndCstOfAccounts() {
    const { accountId } = this.props;
    try {
      const request = await axios.get(
        `${Config.GET_REL_CONTACTS_AND_CST_OF_ACCOUNTS}?account_id=${accountId}`,
      );
      if (request.data) {
        this.setState({
          contacts: request.data,
          allContacts: request.data,
        });
      }
    } catch (error) {
      console.log('Error in getting CST member', error);
    }
  }

  getFilteredRecords() {
    const { selectedFilterList, searchField, attachmentCheckBox } = this.state;
    const apiPath =
      (selectedFilterList.RECIPIENT
        ? `&recipient_id=${selectedFilterList.RECIPIENT[0].id}`
        : '') +
      (selectedFilterList['DATE RANGE']
        ? (selectedFilterList['DATE RANGE'][0].from
            ? `&from_date=${format(
                selectedFilterList['DATE RANGE'][0].from,
                'MM-dd-yyyy',
              )}`
            : '') +
          (selectedFilterList['DATE RANGE'][0].to
            ? `&to_date=${format(
                selectedFilterList['DATE RANGE'][0].to,
                'MM-dd-yyyy',
              )}`
            : '')
        : '') +
      (searchField ? `&keyword=${searchField}` : '') +
      (attachmentCheckBox ? '&attachment=true' : '');
    this.setState({ apiUrl: apiPath }, () => {
      this.props.messageActions.userMessageFilters(this.state);
    });
    this.props.pagination.accountMsgPagination(1);
    const { accountId, contactId } = this.props;
    if (accountId) {
      this.props.messageActions.fetchAccountMessages(
        this,
        accountId,
        perPage,
        1,
        apiPath,
      );
    } else if (contactId) {
      this.props.messageActions.fetchContactMessages(
        this,
        contactId,
        perPage,
        1,
        apiPath,
      );
    }
  }

  handlePageChange = (index) => {
    const component = this;
    const { accountId, contactId } = this.props;
    if (index === this.props.account_msg_page) {
      return false;
    }
    if (accountId) {
      this.props.messageActions.fetchAccountMessages(
        component,
        accountId,
        perPage,
        index,
        this.state.apiUrl,
      );
    } else if (contactId) {
      this.props.messageActions.fetchContactMessages(
        component,
        contactId,
        perPage,
        index,
        this.state.apiUrl,
      );
    }
    this.props.pagination.accountMsgPagination(index);
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
    return true;
  };

  handleFilters = (e, input, filterType) => {
    let { selectedFilterList } = this.state;
    if (filterType === 'RECIPIENT') {
      selectedFilterList = {
        ...selectedFilterList,
        [filterType]: [{ name: input.label, id: input.value }],
      };
    }
    if (filterType === 'DATE RANGE') {
      if (selectedFilterList[filterType]) {
        selectedFilterList[filterType][0][input] = e;
      } else {
        selectedFilterList = {
          ...selectedFilterList,
          [filterType]: [{ [input]: e }],
        };
      }
      this.setState({ [input]: e });
    }
    this.setState({ selectedFilterList }, () => {
      this.getFilteredRecords();
    });
  };

  handleSearch = (e) => {
    this.setState({ searchField: e.target.value });
    clearTimeout(this.typingTime);
    this.typingTime = setTimeout(() => {
      this.getFilteredRecords();
    }, 500);
  };

  attachmentCheckBox = (e) => {
    this.setState({ attachmentCheckBox: e.target.checked }, () => {
      this.getFilteredRecords();
    });
  };

  clearFilters = () => {
    this.setState(
      {
        to: null,
        from: null,
        selectedFilterList: {},
        searchField: '',
        recipientValue: '',
        attachmentCheckBox: false,
        contacts: this.state.allContacts,
      },
      () => {
        this.getFilteredRecords();
      },
    );
  };

  recipientInputChange = (e) => {
    const value = e ? e.target.value : '';
    const component = this;
    this.setState({ recipientValue: value });
    if (value.length < 2) {
      this.setState({ contacts: this.state.allContacts });
    } else {
      clearTimeout(this.typingTime);
      this.typingTime = setTimeout(() => {
        const request = axios.get(
          `${Config.SEARCH_TASK_CONTACTS}/?keyword=${value}&message=true`,
        );
        request.then((response) => {
          if (response.status === 200) {
            component.setState({ contacts: response.data });
          }
        });
      }, 500);
    }
  };

  removeFilter = (item, filter) => {
    const { selectedFilterList, allContacts } = this.state;
    if (filter === 'RECIPIENT') {
      delete selectedFilterList[filter];
    } else {
      delete selectedFilterList[filter][0][item];
      if (!Object.keys(selectedFilterList[filter][0]).length) {
        delete selectedFilterList[filter];
      }
      this.setState({ [item]: '' });
    }
    this.setState(
      {
        selectedFilterList,
        contacts: allContacts,
        recipientValue: '',
      },
      () => {
        this.getFilteredRecords();
      },
    );
  };

  showDetail = (item) => {
    const id = item.thread_id;
    this.setState({ showThreadDetail: true, threadId: id });
  };

  togglePinUnpin = (item, key) => {
    if (item.all_senders.includes('me')) {
      const msgArr = this.props.messages;
      msgArr.inbox[key].is_pinned = !msgArr.inbox[key].is_pinned;
      this.props.messageActions.fetchAllMessages(msgArr);
      const msgId = encodeURIComponent(item.thread_id);
      const request = axios.put(`${Config.PIN_UNPIN_THREAD_MESSAGE}/${msgId}`);
      request
        .then((response) => {
          if (response.data.status === 200) {
            // const msgArr = this.props.messages;
            // msgArr.inbox[key].is_pinned = !msgArr.inbox[key].is_pinned;
            // this.props.messageActions.fetchAllMessages(msgArr);
          } else {
            msgArr.inbox[key].is_pinned = !msgArr.inbox[key].is_pinned;
            this.props.messageActions.fetchAllMessages(msgArr);
          }
        })
        .catch(() => {
          msgArr.inbox[key].is_pinned = !msgArr.inbox[key].is_pinned;
          this.props.messageActions.fetchAllMessages(msgArr);
          console.log('Some error occurred. Please try later.');
          return null;
        });
    } else {
      Utilities.AlertMessage('error', 'You are not message participant', 3000);
    }
  };

  readMessageThread = (item, key) => {
    const msgArr = this.props.messages;
    // const { filter } = this.state;
    msgArr.inbox[key].read = true;
    this.props.messageActions.fetchAllMessages(msgArr);
  };

  render() {
    let count;
    let totalpages = 1;
    let totalrecords = 0;
    let offset = 0;
    let off = 0;
    const pathName = this.props.location.pathname.split('/')[1];
    const newMessagePath = this.props.contactId
      ? this.props.contactId
      : this.props.accountId;
    const { messages, contactData, accountData } = this.props;
    const contact_data = {
      id: contactData?.id,
      label: `${contactData?.first_name} ${contactData?.middle_name} ${contactData?.last_name}`,
    };
    const {
      dataLoaded,
      loading,
      threadId,
      selectedFilterList,
      searchField,
      attachmentCheckBox,
    } = this.state;
    const anyFiltersSelected =
      (selectedFilterList && Object.keys(selectedFilterList).length) ||
      attachmentCheckBox ||
      searchField;
    if (messages && messages.inbox !== undefined && this.state.dataLoaded) {
      count = messages.inbox_count;
      totalpages =
        messages.inbox_count !== 0
          ? Math.ceil(messages.inbox_count / perPage)
          : 0;
      totalrecords = messages.inbox_count;
      offset = messages.inbox_offset;
      off = messages.inbox_off;
    }
    if (
      !loading &&
      messages &&
      messages.inbox_count === 0 &&
      !anyFiltersSelected
    ) {
      return (
        <div className="center-wrapper">
          <section>
            {(!contactData || (contactData && contactData.is_active)) &&
              (!accountData ||
                (accountData &&
                  accountData.account &&
                  accountData.account.status !== 'Inactive')) && (
                <div className="d-flex flex-md-row-reverse">
                  <Link
                    to={`/${pathName}/message/${newMessagePath}`}
                    state={{
                      name: this.props.name,
                      accountId: this.props.accountId,
                    }}
                    className="btn btn-primary"
                  >
                    <i>+</i> Message
                  </Link>
                </div>
              )}
          </section>
          <PageNotFound
            name="message"
            message="There are no records to show you right now."
          />
        </div>
      );
    }
    return (
      <div
        className="center-wrapper"
        onClick={this.onClickFilterBG}
        role="presentation"
      >
        <div id="loading" style={loading ? blockStyle : noneStyle} />
        {this.state.showThreadDetail ? (
          <ThreadDetails
            data={this.state}
            threadItem={threadId}
            name="userlist"
            contact_data={contact_data}
          />
        ) : (
          // Else Show Message List
          <section>
            <div className="filter-wrapper d-flex justify-content-between align-items-center section-title--bottomborder pb-3 mb-0">
              <div className="filterPart d-flex">
                <div className="section-title col-1 pl-0 pr-2 pt-2">
                  <h5>All {count !== undefined ? `| ${count}` : ''}</h5>
                </div>
                <div className="form-group has-search">
                  <span className="icon-zoom2 form-control-feedback" />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search"
                    id="message-search"
                    value={this.state.searchField}
                    onChange={this.handleSearch}
                  />
                </div>
                <div className="filterGroup d-flex flex-wrap bd-highlight has-search">
                  <label htmlFor="filter" className="mt-2 mr-1 m-0">
                    Filter by
                  </label>
                  <FilterComponent
                    filterName="RECIPIENT"
                    filterList={this.state.contacts}
                    recipientInputChange={this.recipientInputChange}
                    recipientSelected={this.handleFilters}
                    data={this.state}
                    isActive={
                      selectedFilterList && selectedFilterList.RECIPIENT
                    }
                    clearSearch={() => this.recipientInputChange('')}
                    setFilterInFocus={this.setFilterInFocus}
                    showFilterOptions={this.state.filterInFocus === 'RECIPIENT'}
                  />
                  <DateFilterComponent
                    filterName="DATE RANGE"
                    filterList={[]}
                    handleDateField={this.handleFilters}
                    data={this.state}
                    isActive={
                      selectedFilterList && selectedFilterList['DATE RANGE']
                    }
                    filterBGClicked={this.state.filterBGClicked}
                  />
                  <div className="pt-2 pl-3 checkbox checkbox-primary check-container">
                    <label htmlFor="filtercheckbox">
                      <input
                        name="filtercheckbox"
                        id="filtercheckbox"
                        type="checkbox"
                        className="form-check-input"
                        checked={this.state.attachmentCheckBox}
                        onChange={(e) => this.attachmentCheckBox(e)}
                      />
                      <i className="checkmark" />
                      <span className="text-body pl-2">
                        Include Attachments
                      </span>
                    </label>
                  </div>
                  {anyFiltersSelected ? (
                    <div className="align-self-center ml-2 clearFilter">
                      <a href="javascript:void(0)" onClick={this.clearFilters}>
                        <i className="icon-close2 label-small" />
                        Clear All
                      </a>
                    </div>
                  ) : null}
                  <div className="select-filter-list w-100">
                    <ShowFiterTags
                      filterName="RECIPIENT"
                      selectedTags={selectedFilterList}
                      handleClick={this.removeFilter}
                    />
                    <ShowFiterTags
                      filterName="DATE RANGE"
                      selectedTags={selectedFilterList}
                      handleClick={this.removeFilter}
                    />
                  </div>
                </div>
              </div>
              {(!contactData || (contactData && contactData.is_active)) &&
                (!accountData ||
                  (accountData &&
                    accountData.account &&
                    accountData.account.status !== 'Inactive')) && (
                  <div className="FilterHeader--Action align-self-start">
                    <Link
                      to={`/${pathName}/message/${newMessagePath}`}
                      state={{
                        name: this.props.name,
                        accountId: this.props.accountId,
                      }}
                      className="btn btn-primary"
                    >
                      <i>+</i> Message
                    </Link>
                  </div>
                )}
            </div>
            <MessageList
              dataLoaded={dataLoaded}
              list={
                messages && messages.inbox !== undefined ? messages.inbox : []
              }
              showDetail={this.showDetail}
              filter="inbox"
              name="userlist"
              togglePinUnpin={this.togglePinUnpin}
              contact_data={contact_data}
              readMessageThread={this.readMessageThread}
            />
            {!loading ? (
              <Pagination
                currentPage={
                  messages && messages.inbox && messages.inbox.length >= 1
                    ? this.props.account_msg_page
                    : 0
                }
                totalPages={totalpages}
                totalRecords={totalrecords}
                offset={totalrecords === 0 ? offset - 1 : offset}
                off={off}
                handlePageChange={this.handlePageChange}
              />
            ) : null}
          </section>
        )}
      </div>
    );
  }
}

const ShowFiterTags = (props) => {
  const { selectedTags, handleClick, filterName } = props;
  if (Object.keys(selectedTags).length) {
    if (
      filterName === 'DATE RANGE' &&
      selectedTags.hasOwnProperty('DATE RANGE')
    ) {
      return Object.keys(selectedTags['DATE RANGE'][0]).map((item) => (
        <div key={item} className="badge-filter">
          {`${item.toUpperCase()}: ${format(
            selectedTags['DATE RANGE'][0][item],
            'MM-dd-yyyy',
          )}`}
          <a href="javascript:void(0)">
            <i
              tabIndex={0}
              role="button"
              className="icon-close2"
              onMouseDown={() => handleClick(item, filterName)}
            />
          </a>
        </div>
      ));
    }
    if (
      filterName === 'RECIPIENT' &&
      selectedTags.hasOwnProperty('RECIPIENT')
    ) {
      return (
        <div className="badge-filter">
          {`RECIPIENT: ${selectedTags.RECIPIENT[0].name}`}
          <a href="javascript:void(0)">
            <i
              tabIndex={0}
              role="button"
              className="icon-close2"
              onMouseDown={() => handleClick('RECIPIENT', filterName)}
            />
          </a>
        </div>
      );
    }
  }
  return null;
};

const mapStateToProps = (state) => ({
  messages: state.messageReducer.allMessages,
  account_msg_page: state.paginationReducer.account_msg_page,
  userMsgFilters: state.messageReducer.userMsgFilters,
});

const mapDispatchToProps = (dispatch) => ({
  messageActions: bindActionCreators(messageActions, dispatch),
  pagination: bindActionCreators(paginationActions, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserMessageList),
);
