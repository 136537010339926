import React from 'react';
import styled from '@emotion/styled';
import { ReactComponent as IconMenu } from 'images/icons/close-icon.svg';

const StyledIcons = styled.span`
  opacity: 1;
`;

const CloseIcon = ({ handleClose, classname }) => {
  const handleClick = () => {
    handleClose();
  };
  return (
    <StyledIcons className={classname} onClick={handleClick}>
      <IconMenu width="1.5rem" />
    </StyledIcons>
  );
};

export default CloseIcon;
