/* eslint-disable no-param-reassign */
import React from 'react';
import { Editor } from '@tinymce/tinymce-react';
import './TinymceEditior.scss';

const TinymceEditor = ({
  initialValue,
  imageUploadHandler,
  handleChange,
  value,
  height = '300px',
  disabled = false,
  notDisaplayTools = '',
  autofocus,
  onBlur,
  scrollIntoView,
  disableInlineImages,
  testid,
}) => {
  function handleInit(evt, editor) {
    // scroll to make the toolbar visible
    if (scrollIntoView) {
      setTimeout(() => {
        editor
          ?.getContainer()
          ?.querySelector('.tox-editor-container')
          ?.scrollIntoView({ behavior: 'smooth' });
      }, 500)
    }
  }

  return (
    <Editor
      disabled={disabled}
      data-testid={testid}
      onInit={handleInit}
      tinymceScriptSrc="https://turmericp.liscio.me/tinymce/js/tinymce/tinymce.min.js"
      initialValue={initialValue}
      value={value}
      onBlur={onBlur}
      init={{
        images_replace_blob_uris: false,
        convert_urls: false,
        paste_data_images: !disableInlineImages,
        auto_focus: autofocus,
        height,
        menubar: false,
        paste_retain_style_properties: 'all',
        paste_webkit_styles: 'all',
        paste_convert_word_fake_lists: false,
        // open embedded link in new tab
        link_target_list: [{ title: 'New window', value: '_blank' }],
        contextmenu: false,
        paste_postprocess(plugin, args) {
          args.node.querySelectorAll('a').forEach((anchorTag) => {
            if (!anchorTag.innerHTML) {
              anchorTag.remove();
            }
          });
        },
        plugins: [
          'link',
          'autolink',
          'hr',
          'lists',
          'media',
          'advlist',
          'image',
          'quickbars',
        ],
        browser_spellcheck: true,
        toolbar: notDisaplayTools
          ? false
          : 'h1 h2 h3 bold italic underline forecolor backcolor bullist numlist ' +
            `alignleft aligncenter alignright alignjustify lineheight link ${!disableInlineImages ? 'quickimage' : ''}`,
        content_style: `@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700');
        body {
          font-family:Source Sans Pro, sans-serif;
          font-size:0.94rem;
          color: rgb(33, 43, 54);
          margin:0;
          padding: 1px 0.5rem;
        }

        p {
          color: rgb(33, 43, 54);
          margin: 0px;
        }

        #tinymce * {
          text-indent: 0 !important;
        }

        .mce-item-table, .mce-item-table td, .mce-item-table tr {
          border: inherit !important;
          outline: inherit !important;
        }

        .mce-content-body {
          min-height:210px;
        }`,
        quickbars_selection_toolbar: false,
        link_assume_external_targets: true,
        toolbar_location: 'bottom',
        quickbars_insert_toolbar: false,
        quickbars_image_toolbar: false,
        elementpath: false,
        branding: false,
        resize: true,
        autoresize: true,
        images_file_types: 'jpg,png,jpeg',
        image_description: false,
        image_dimensions: false,
        image_title: false,
        a11y_advanced_options: true,
        images_upload_handler: imageUploadHandler || undefined,
        object_resizing: true,
        forced_root_block: 'div',
        init_instance_callback: (editor) => {
          editor.on('GetContent', (e) => {
            if (e.content.includes('a')) {
              const editImage = e.content;
              const newString = editImage.replace(
                /<a/g,
                '<a style="text-decoration: underline"',
              );
              e.content = newString;
            }
          });
          editor.on('ObjectResized', (ev) => {
            ev.target.dataset.mceStyle =
              'style="max-width: none; max-height: none"';
          });
        },
        setup(editor) {
          editor.ui.registry.addContextMenu('useBrowserSpellcheck', {
            update() {
              return editor.selection.isCollapsed()
                ? ['useBrowserSpellcheck']
                : [];
            },
          });
        },
      }}
      onEditorChange={handleChange}
    />
  );
};

export default TinymceEditor;