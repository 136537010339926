import { styled } from '@mui/material';

export const SUPPORT_URL =
  'https://support.liscio.me/portal/en/kb/articles/implementing-a-privacy-policy-for-sms-texting';
const SUPPORT_MAIL = 'support@liscio.me';

const Link = styled('a')({
  padding: 0,
});

export const PrivacyPolicyErrorMessage = ({
  errorMessage,
}: {
  errorMessage?: string;
}) => {
  if (errorMessage === 'url') {
    return <span>Privacy policy URL must be a valid https URL address</span>;
  }

  if (errorMessage === 'required') {
    return (
      <span>
        Privacy Policy is required. For further guidance on this, please view
        our{' '}
        <Link href={SUPPORT_URL} target="_blank" rel="noreferrer">
          help article SMS Privacy Policy Compliance
        </Link>{' '}
        or reach out to our team at{' '}
        <Link href={`mailto:${SUPPORT_MAIL}`}>{SUPPORT_MAIL}</Link>
      </span>
    );
  }

  return null;
};

export default PrivacyPolicyErrorMessage;
