import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import {
  AlertMessage
} from 'utilities/utils';
import moment from 'moment';
import styled from '@emotion/styled';
import { TRUSTED_DOMAINS } from 'constants/constants';
import AddTrustedDomainModal from './AddTrustedDomainModal';

type DomainProps = {
  id: string
  domain: string
  created_at: string
}[]

export default function TrustedDomains () {
  const [domainData, setDomainData] = useState<DomainProps>([]);
  const [showAddDomainModal, setShowAddDomainModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const fetchDomains = () => {
    const request = Axios.get(TRUSTED_DOMAINS);
    request
      .then(response => {
        setDomainData(response.data.data);
        setIsLoading(false);
      })
      .catch(error => {
        AlertMessage('error', error.message, 3000);
        setIsLoading(false);
      })
  };

  useEffect(() => {
    document.title = 'Domains';
    fetchDomains();
  }, []);

  const Info = styled.div`
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 10px;
    background-color: #e6f7fa;
  `

  return (
    <div id="content">
      {isLoading ? <div id="loading" /> : null}

      <div className="center-wrapper">
        <Info>
          Trusted domains are a security feature used to authorize the accounts users have access to when connecting to third-party services
        </Info>

        <div className="filter-wrapper d-flex">
          <div style={{ marginLeft: 'auto' }}>
            <button
              type="button"
              onClick={() => setShowAddDomainModal(true)}
              className="btn btn-primary"
            >
              <i>+</i> Domain
            </button>
          </div>
        </div>

        <div className="tableWrap">
          <div className="tRow tRow--head">
            <div className="row">
              <div className="col thCol">
                Domain
              </div>
               <div className="col thCol">
                Date Added
              </div>
            </div>
          </div>

          <div className="tRow tRow--body">
            {domainData.map((each) => (
              <div className="row tdBtn" key={each.id} >
                <div className="col tdCol">
                  {each.domain}
                </div>
                <div className="col tdCol">
                  {moment(each.created_at).format('MM/DD/YYYY')}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {showAddDomainModal &&
        <AddTrustedDomainModal
          domainData={domainData}
          closeModal={() => setShowAddDomainModal(false)}
        />
      }
    </div>
  )
}
