import React, { useEffect } from 'react';
import { AlertMessage, accessCookie } from 'utilities/utils';

const OauthRedirect = () => {
  useEffect(() => {
    const parsedUrl = new URL(window.location.href);
    const state_value = parsedUrl.searchParams.get('state');
    const code = parsedUrl.searchParams.get('code');
    const error = parsedUrl.searchParams.get('error');
    const error_description = parsedUrl.searchParams.get('error_description');
    if (state_value !== null) {
      const url = accessCookie(state_value);
      let redirect_url = url;
      const sessionTimeout = redirect_url.includes('session_timeout');
      const sign = sessionTimeout ? '&' : '?';
      if (
        code !== null &&
        url !== undefined &&
        url.includes('third_party_apps')
      ) {
        redirect_url += `&code=${code}`;
      } else if (code !== null && parsedUrl.pathname === '/outlook') {
        redirect_url += `${sign}outlookcode=${code}`;
      } else if (code !== null && parsedUrl.pathname === '/google') {
        redirect_url += `${sign}googlecode=${code}`;
      } else if (code !== null && parsedUrl.pathname === '/adobe') {
        const api_access_point = parsedUrl.searchParams.get('api_access_point');
        const web_access_point = parsedUrl.searchParams.get('web_access_point');
        redirect_url += `&code=${code}&api_access_point=${api_access_point}&
        web_access_point=${web_access_point}`;
      } else if (code !== null && parsedUrl.pathname === '/qb') {
        const realmId = parsedUrl.searchParams.get('realmId');
        redirect_url += `&code=${code}&realmId=${realmId}`;
      } else {
        redirect_url += `${sign}code=${code}`;
      }
      window.location.replace(redirect_url);
      if (error !== null) {
        redirect_url += `${sign}error=${error}&error_description=${error_description}`;
        window.location.replace(redirect_url);
      }
      if (url === undefined) {
        AlertMessage('error', 'Timeout. Please try again', 9000);
      }
    }
  }, []);

  return (
    <div id="content" className="pt-4">
      <div className="center-wrapper">
        <div className="emptyBox text-center">
          <div className="emptyBox-icon">
            <h3 className="text-large">Redirecting to Integration page</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OauthRedirect;
