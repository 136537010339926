import { createTheme } from '@mui/material/styles';

import AlwaysWoff2 from 'scss/fonts/always-webfont.woff2';
import AlwaysWoff from 'scss/fonts/always-webfont.woff';
import IcoMoonEot from 'scss/fonts/icomoon.eot';
import IcoMoonTtf from 'scss/fonts/icomoon.ttf';
import IcoMoonWoff from 'scss/fonts/icomoon.woff';
import IcoMoonSvg from 'scss/fonts/icomoon.svg';
import DotsfontWoff2 from 'scss/fonts/dotsfont-webfont.woff2';
import DotsfontWoff from 'scss/fonts/dotsfont-webfont.woff';
import { liscioTextDark, liscioWhite } from './colors';

// these variable names match those in variables.scss
const sky = '#dfe3e8';
const midGrey = '#919eab';

// TODO bring these names into alignment with the variables above
// when in doubt use the variables above over these
export const palettePrimary = {
  light: liscioWhite,
  main: '#00aecc',
  dark: '#00aecc',
  gray: midGrey,
  lightGray: sky,
  contrastText: '#fff',
  textColor: liscioTextDark,
  disabledColor: '#f3f3f3',
};

const spacing = 5;

const lightThemeOptions = {
  spacing,
  palette: {
    mode: 'light',
    common: {
      midGrey,
      sky,
    },
    primary: palettePrimary,
    info: {
      main: '#00aecc',
    },
    text: {
      primary: '#212b36',
      secondary: '#8A939A',
    },
  },
  typography: {
    fontSize: 13.125,
    lineHeight: 1.3,
    color: palettePrimary.dark,
    fontFamily: "'Source Sans Pro', Sans-serif",
    marginBottom: 0,
  },
  components: {
    MuiTypography: {
      variants: [
        {
          props: { variant: 'gray_subtitle1' },
          style: {
            textTransform: 'uppercase',
            color: palettePrimary.gray,
            fontSize: 15,
            fontWeight: 600,
          },
        },
        {
          props: { variant: 'gray_body1' },
          style: {
            color: palettePrimary.gray,
            fontSize: 17,
          },
        },
        {
          props: { variant: 'gray_body2' },
          style: {
            color: palettePrimary.gray,
            fontSize: 15,
          },
        },
      ],
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.MuiInput-underline:before': {
            borderBottomColor: sky,
          },
          '&.MuiInput-underline:hover:not(.Mui-disabled):before': {
            borderBottomColor: sky, // disable hover changing color
            borderBottomWidth: 1,
          },
          '&.MuiInput-underline:after': {
            borderBottomColor: sky, //disable the animation and coloration applied on focus
          },
        },
      },
    },
    MuiButtonGroup: {
      styleOverrides: {
        root: {
          border: `2px solid ${palettePrimary.lightGray}`,
          borderRadius: 2,
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        disableElevation: true,
      },
      styleOverrides: {
        root: ({ ownerState }) => {
          let rootCSS = {};
          if (ownerState.variant === 'text') {
            rootCSS = {
              textTransform: 'none',
              padding: '6px 16px',
              boxSizing: 'border-box',
              transition: 'all ease-in-out 200ms',
              color: palettePrimary.gray,
              '&:hover': {
                color: palettePrimary.main,
                boxShadow: 'none',
                backgroundColor: 'white',
              },
            };
          }

          const common = {
            textTransform: 'none',
            padding: '6px 16px',
            boxSizing: 'border-box',
            transition: 'all ease-in-out 200ms',
            border: `2px solid ${
              ownerState.disabled
                ? palettePrimary.lightGray
                : palettePrimary.main
            }`,
            borderRadius: 2,
            '&:hover': { boxShadow: '0 5px 25px rgba(0, 0, 0, 0.1)' },
          };
          if (ownerState.variant === 'contained') rootCSS = common;
          if (ownerState.variant === 'outlined') {
            rootCSS = {
              ...common,
              color: palettePrimary.gray,
              border: `2px solid ${palettePrimary.lightGray}`,
              '&:hover': {
                ...common['&:hover'],
                backgroundColor: 'white',
                border: '2px solid white',
              },
            };
          }

          if (ownerState.disabled) {
            rootCSS['&.Mui-disabled'] = {
              backgroundColor: sky,
              color: midGrey,
            };
          }

          return rootCSS;
        },
        /* Styles applied to the icon element if supplied and `size="small"`. */
        sizeSmall: { padding: '3px 10px', fontSize: 14 },
        /* Styles applied to the icon element if supplied and `size="medium"`. */
        sizeMedium: { fontSize: 14 },
        /* Styles applied to the icon element if supplied and `size="large"`. */
        sizeLarge: { fontSize: 18 },

        /* Styles applied to the icon element if supplied and `size="medium"`. */
        iconSizeMedium: {
          '& > *:first-of-type': { fontSize: 14 },
          '& > *:last-child': { fontSize: 14 },
        },
        /* Styles applied to the icon element if supplied and `size="large"`. */
        iconSizeLarge: {
          '& > *:first-of-type': { fontSize: 18 },
          '& > *:last-child': { fontSize: 18 },
        },
      },
      variants: [
        {
          props: { variant: 'grayOutlined' },
          style: {
            fontWeight: 500,
            fontSize: 15,
            paddingLeft: spacing * 2,
            paddingRight: spacing * 2,
            border: `2px solid ${palettePrimary.lightGray}`,
            color: palettePrimary.gray,
          },
        },
      ],
    },
    MuiIconButton: {
      styleOverrides: {
        root: { '&:hover': { boxShadow: '0 5px 25px rgba(0, 0, 0, 0.1)' } },
        /* Styles applied to the icon element if supplied and `size="small"`. */
        sizeSmall: {
          '& > *:first-of-type': { fontSize: 10 },
          '& > *:last-child': { fontSize: 10 },
        },
        /* Styles applied to the icon element if supplied and `size="medium"`. */
        sizeMedium: {
          '& > *:first-of-type': { fontSize: 14 },
          '& > *:last-child': { fontSize: 14 },
        },
        /* Styles applied to the icon element if supplied and `size="large"`. */
        sizeLarge: {
          '& > *:first-of-type': { fontSize: 18 },
          '& > *:last-child': { fontSize: 18 },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          transition: 'all ease-in-out 200ms',
          minHeight: 60,
          '&:hover': { boxShadow: '0 5px 25px rgba(0, 0, 0, 0.1)' },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        fontSizeSmall: { fontSize: 12 },
        fontSizeMedium: { fontSize: 17 },
        fontSizeLarge: { fontSize: 22 },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: ({ theme }) => ({
          paddingLeft: theme.spacing(1),
          paddingRight: theme.spacing(1),
          paddingTop: theme.spacing(0.25),
          paddingBottom: theme.spacing(0.25),
          borderRadius: 1,
        }),
        label: { padding: 'initial' },
        deleteIcon: { margin: 5 },
        deleteIconSmall: { fontSize: 13 },
        deleteIconMedium: { fontSize: 18 },
        deleteIconLarge: { fontSize: 23 },
      },
    },
    MuiFormControlLabel: { styleOverrides: { root: { margin: 0 } } },
    MuiListItemIcon: { styleOverrides: { root: { minWidth: '30px' } } },
    MuiAccordionSummary: {
      styleOverrides: {
        root: { borderBottom: `1px solid ${palettePrimary.gray}` },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'always';
          src: url(${AlwaysWoff2}) format('woff2'), url(${AlwaysWoff}) format('woff');
          font-weight: normal;
          font-style: normal;
        }

        @font-face {
          font-family: 'LiscioFonts';
          src: url(${IcoMoonEot}) format('embedded-opentype'),
            url(${IcoMoonTtf}) format('truetype'),
            url(${IcoMoonWoff}) format('woff'),
            url(${IcoMoonSvg}) format('svg');
          font-weight: normal;
          font-style: normal;
          font-display: block;
        }

        @font-face {
          font-family: 'dotsfont';
          src: url(${DotsfontWoff2}) format('woff2'),
            url(${DotsfontWoff}) format('woff');
          font-weight: normal;
          font-style: normal;
        }
      `,
    },
    MuiListItemButton: { defaultProps: { disableRipple: true } },
  },
};

const lightTheme = createTheme(lightThemeOptions);

export default lightTheme;
