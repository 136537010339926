import React from 'react';
import { Navigate } from 'react-router-dom-latest';
import { getAuthSession } from 'auth/session';
import { AuthUserDispatcher } from 'auth/AuthUserDispatcher';
import Main from './Main_updated';

const PrivateRoute = () => {
  const isAuthenticated = getAuthSession();

  if (!isAuthenticated) return <Navigate to="/login" replace />;
  return (
    <AuthUserDispatcher>
      <Main />
    </AuthUserDispatcher>
  );
};

export default PrivateRoute;
