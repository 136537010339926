/* eslint-disable default-param-last */
import axios from 'axios';
import {
  ACCOUNT,
  CONTACT,
  LISCIO_VAULT_FILES,
  DELETE_DOCUMENTS,
} from 'constants/constants';
import {
  AlertMessage,
  getPageIndex,
  getStorageData,
  isUserEmployee,
} from 'utilities/utils';

class Files {
  static getFilesList(
    component,
    api = '',
    index = '',
    forlisciovault,
    onlyprivatefiles,
  ) {
    component.setState({ loading: true });
    const { sort, field, page, selectedrecords } = component.state;
    const pag = !index ? page : index;
    console.log(api);
    let commonEndpoint = `sort_by=${sort}&field=${field}&page=${pag}${
      !api ? '' : api
    }`;
    if (forlisciovault && onlyprivatefiles) {
      commonEndpoint += '&private=true&forlisciovault=true';
    } else if (forlisciovault) {
      commonEndpoint += '&forlisciovault=true';
    }
    const url = `${LISCIO_VAULT_FILES}?${commonEndpoint}`;
    const request = axios.get(url);
    request
      .then((response) => {
        const resData = response.data;
        if (resData.status === 200) {
          const resHash = resData.response_hash;
          let list = resHash.total_documents;
          if (component.state.listOfFiles !== null) {
            list = component.state.listOfFiles;
          }
          if (selectedrecords.length) {
            for (let k = 0; k < selectedrecords.length; k += 1) {
              const selectedIndex = resData.documents.findIndex(
                (e) => e.id === selectedrecords[k].id,
              );
              if (selectedIndex > -1) {
                resData.documents[selectedIndex].checked = true;
              }
            }
          }
          component.setState(
            {
              loading: false,
              pagination: pag - 1,
              uploadFilesList: resData.documents,
              paginationOffset: resHash.offset,
              paginationOff: resHash.off,
              totalDocuments:
                resHash.total_documents &&
                typeof resHash.total_documents === 'number'
                  ? resHash.total_documents
                  : 0,
              listOfFiles: list,
              liteUpload: true,
              fileRemovedFromList: false,
            },
            () => {
              component.areAllSelected();
              if (!forlisciovault) {
                component.props.UploadFilesActions.handleFilesData(
                  component.state,
                );
              }
            },
          );
        } else if (resData.message) {
          component.setState({ loading: false });
          AlertMessage('error', resData.message, 3000);
        } else {
          component.setState({ loading: false });
          AlertMessage('error', 'Some error occurred. Please try later.', 3000);
        }
      })
      .catch(() => {
        component.setState({ loading: false });
        AlertMessage('error', 'Some error occurred. Please try later.', 3000);
      });
  }

  static deleteSelectedFile(component, data) {
    component.setState({ loading: true });
    const { listOfFiles, api, pagination, uploadFilesList } = component.state;
    const request = axios.put(DELETE_DOCUMENTS, data);
    request
      .then((response) => {
        const resData = response.data;
        if (resData.status === 200) {
          let list = resData.document_ids.length;
          if (listOfFiles !== null) {
            list = listOfFiles - list;
          }
          component.setState({
            loading: false,
            listOfFiles: list,
            selectedrecords: [],
            deleteFiles: false,
          });
          const index = getPageIndex(
            uploadFilesList,
            pagination,
            data.document_ids,
          );
          this.getFilesList(component, api, index);
          AlertMessage('success', resData.message, 3000);
        } else {
          component.setState({ loading: false });
          AlertMessage('error', resData.message, 3000);
        }
      })
      .catch(() => {
        component.setState({ loading: false });
        AlertMessage('error', 'Some error occurred. Please try later.', 3000);
        return null;
      });
  }

  static showSelectedContact(component, id) {
    const request = axios.get(`${CONTACT}/${id}`);
    request
      .then((res) => {
        if (res.data.status === 200) {
          const details = res.data.data[0];
          const { formdata } = component.state;
          const name = `${details.contact.first_name} ${details.contact.last_name}`;
          formdata.contactId = {};
          formdata.contactId.label = name;
          formdata.contactId.id = details.contact.id;
          component.setState({
            formdata,
            contactDetails: formdata.contactId,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  static showSelectedAccount(component, id) {
    const request = axios.get(`${ACCOUNT}/${id}`);
    request
      .then((res) => {
        if (res.data.status === 200) {
          const { account, cst_member } = res.data.data;
          const { formdata, uniqueRecords } = component.state;
          // TODO: __HOMEDATA-AUDIT
          const storageData = getStorageData();
          formdata.accountId = {};
          formdata.accountId.label = account.name;
          formdata.accountId.value = account.id;
          // start here
          if (!isUserEmployee()) {
            if (cst_member && cst_member.length > 0) {
              // populate with all cst members
              cst_member.map((each) => {
                const object = {};
                if (each.label && each.value) {
                  object.contact = {};
                  object.contact.label = each.label;
                  object.contact.value = each.value;
                }
                if (account && account.name && account.id) {
                  object.account = {};
                  object.account.label = account.name;
                  object.account.value = account.id;
                }
                // if (mounted) {
                //   object.flag = true;
                // }
                uniqueRecords.push(object);
                return object;
              });
              component.setState({ cstMembers: cst_member });
            } else {
              // populate with default employee
              const object = {};
              object.contact = {};
              object.account = {};
              object.contact.label = storageData.default_recipient
                ? storageData.default_recipient.label
                : '';
              object.contact.value = storageData.default_recipient
                ? storageData.default_recipient.value
                : 0;
              object.account.label = account.name;
              object.account.value = account.id;
              // if (mounted) {
              //   object.flag = true;
              // }
              uniqueRecords.push(object);
            }
            // end here
          }
          component.setState({
            uniqueRecords,
            formdata,
            accountDetails: formdata.accountId,
            contactHasAccount: 1,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
}
export default Files;
