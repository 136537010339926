/* eslint-disable global-require */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import { mfaFormValidationResolver } from './mfaFormValidationResolver';
import { MFAFormContent } from './components/MFAFormContent';

export type MFAFormType = {
  two_factor_code: string;
};

export interface MFAFormProps {
  /**
   * MFA Form submit handler
   */
  onSubmit?: SubmitHandler<MFAFormType>;
  /**
   * Form error
   */
  error?: string;
  /**
   * Back to login handler
   */
  backToLogin?: () => void;
}

/**
 * This MFAForm encapsulates it's own form behavior. It uses a
 * shared MFAFormContent controller due to a similar form requiring
 * a shared FormContext. We should prefer encapsulated forms like
 * this over a shared FormContext for multiple forms going forward.
 */
export const MFAForm = ({
  onSubmit = () => {},
  error,
  backToLogin,
}: MFAFormProps) => {
  const formCtx = useForm<MFAFormType>({
    resolver: mfaFormValidationResolver,
  });
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = formCtx;

  const submitHandler: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.stopPropagation();
    event.preventDefault();
    handleSubmit(onSubmit)(event);
  };

  return (
    <FormProvider {...formCtx}>
      <form onSubmit={submitHandler}>
        <MFAFormContent
          register={register}
          error={error}
          errors={errors}
          backToLogin={backToLogin}
        />
      </form>
    </FormProvider>
  );
};

export default MFAForm;
