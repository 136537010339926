import React, { Component } from 'react';
import Axios from 'axios';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import {
  isUserEmployee,
  getStorageData,
  sortingIcon,
  getCpaPreferences,
  getBulkInviteMaxCount,
  FORMATPHONENUMBER,
} from 'utilities/utils';
import { TEXT_LOGS } from 'constants/constants';
import NoRecords from 'common/NoRecords';
import Pagination from 'common/Pagination';

const preferencePageTableHeaders = [
  {
    value: 'updated_at',
    label: 'Sent Date',
    isSortable: true,
    className: ' col thCol',
  },
  {
    value: 'sender_first_name',
    label: 'From',
    isSortable: true,
    className: ' col thCol',
  },
  {
    value: 'recipient_first_name',
    label: 'To',
    isSortable: true,
    className: ' col thCol',
  },
  {
    value: 'to_phone_number',
    label: 'To (Phone #)',
    isSortable: true,
    className: ' col thCol',
  },
  {
    value: 'content',
    label: 'Message #',
    isSortable: true,
    className: ' col thCol',
  },
  {
    value: 'status',
    label: 'status',
    isSortable: true,
    className: ' col thCol',
  },
];

class TextLogs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sortOrder: 'desc',
      sortBy: 'updated_at',
      pagination: 1,
      totalPages: 1,
      totalRecords: 0,
      loading: true,
      listData: [],
      offset: [0, 20],
      text_limit: getBulkInviteMaxCount(
        getCpaPreferences().text_limit,
        'max_text_messages',
      ),
      is_texting_allowed: true,
    };
  }

  componentDidMount() {
    document.title = 'Text Logs';
    if (
      // TODO: __HOMEDATA-AUDIT
      !isUserEmployee() ||
      (getStorageData() && getStorageData().role !== 'firm_admin')
    ) {
      this.props.navigate('/');
    } else {
      this.getRecords();
    }
  }

  getRecords = () => {
    const { pagination, sortBy, sortOrder } = this.state;

    Axios.get(
      `${TEXT_LOGS}?page=${pagination}&field=${sortBy}&sort_by=${sortOrder}`,
    ).then((res) => {
      if (res.status === 200 && res.data.status === 200 && res.data.data) {
        const { res_data, data, count } = res.data;
        this.setState({
          loading: false,
          listData: data,
          offset: [res_data.offset, res_data.off],
          totalRecords: count,
          totalPages: Number(Math.ceil(count / 20)),
        });
      } else {
        this.setState({
          loading: false,
          listData: null,
          is_texting_allowed: false,
        });
      }
    });
  };

  handleSortBy = (sortBy) => {
    let { sortOrder } = this.state;
    if (this.state.sortBy === sortBy) {
      if (sortOrder === 'asc') {
        sortOrder = 'desc';
      } else {
        sortOrder = 'asc';
      }
    } else {
      sortOrder = 'asc';
    }

    this.setState(
      {
        sortOrder,
        sortBy,
        loading: true,
      },
      () => {
        this.getRecords();
      },
    );
  };

  handlePageChange = (input) => {
    this.setState(
      {
        pagination: input,
        loading: true,
      },
      () => this.getRecords(),
    );
  };

  render() {
    const {
      listData,
      loading,
      sortBy,
      sortOrder,
      offset,
      totalRecords,
      totalPages,
      pagination,
      text_limit,
      is_texting_allowed,
    } = this.state;
    if (!is_texting_allowed) {
      return (
        <div id="content" className="pt-4">
          <div className="center-wrapper">
            <div className="emptyBox text-center">
              <div className="emptyBox-icon">
                <h3 className="text-large">
                  <i className="icon-expired" />
                  Your firm has not enabled text messages. Please contact your
                  Liscio firm administrator.
                </h3>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div id="content">
        {loading ? <div id="loading" /> : null}
        <div
          className={
            listData && listData !== null && listData.length
              ? 'center-wrapper'
              : 'hidden'
          }
        >
          <div className="col-container">
            <div className="col col-6">
              <h5>Texting Preference</h5>
            </div>
          </div>
          <div className="row no-gutters mt-3 mb-3 bottom-border">
            <div className="col-12">
              <div className="form-group form-inline-label">
                <label className="align-self-start"># of Texts Allowed:</label>
                <div className="labelValue">
                  <span>{text_limit}</span>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="form-group form-inline-label">
                <label className="align-self-start">
                  # of Texts Sent to Date:
                </label>
                <div className="labelValue">
                  <span>{totalRecords}</span>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className="col-container mb-2">
            <div className="col col-6">
              <h5>Text Logs</h5>
            </div>
          </div>
          {listData === undefined || listData === null ? <NoRecords /> : ''}
          {listData && listData !== null && listData.length ? (
            <div className="tableWrap">
              <div className="tRow tRow--head">
                <div className="row">
                  {preferencePageTableHeaders.map((each) => (
                    <div
                      key={each.value}
                      width={each.width}
                      className={
                        sortingIcon(
                          sortBy,
                          each.value,
                          sortOrder,
                          'className',
                        ) +
                        each.className +
                        (!each.isSortable ? ' cursor-default' : '')
                      }
                      onClick={() =>
                        each.isSortable ? this.handleSortBy(each.value) : null
                      }
                    >
                      <span className="posRel">
                        {each.label}
                        <i
                          className={sortingIcon(sortBy, each.value, sortOrder)}
                        />
                      </span>
                    </div>
                  ))}
                </div>
              </div>
              <div className="tRow tRow--body">
                {listData.map((each) => (
                  <div
                    className="row tdBtn"
                    key={each.sent_at + each.sender_name + each.id}
                  >
                    <div className="col tdCol">
                      <span>
                        {each.sent_at
                          ? moment(each.sent_at).format('MM/DD/YYYY')
                          : '-'}
                      </span>
                    </div>
                    <div className="col tdCol">
                      <span>{each.sender_name}</span>
                    </div>
                    <div className="col tdCol">
                      <span>{each.recipient_name}</span>
                    </div>
                    <div className="col tdCol">
                      <span>
                        {each.to_phone_number &&
                          FORMATPHONENUMBER(each.to_phone_number)}
                      </span>
                    </div>
                    <div className="col tdCol">
                      <span
                        data-tip
                        data-for={each.sent_at + each.sender_name + each.id}
                      >
                        {`${each.content.slice(0, 20)}...`}
                      </span>
                      <ReactTooltip
                        aria-haspopup="true"
                        place="top"
                        effect="solid"
                        type="light"
                        className="contactCardClass"
                        id={each.sent_at + each.sender_name + each.id}
                      >
                        <span style={{ padding: 12 }}>{each.content}</span>
                      </ReactTooltip>
                    </div>
                    <div className="col tdCol">
                      <span>{each.status}</span>
                    </div>
                  </div>
                ))}
              </div>
              <Pagination
                currentPage={pagination}
                totalPages={totalPages}
                totalRecords={totalRecords}
                handlePageChange={this.handlePageChange}
                offset={offset[0]}
                off={offset[1]}
              />
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default TextLogs;
