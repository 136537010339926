import { Box, Button, ButtonGroup } from '@mui/material';
import { REQUESTS_PAGE_ROUTES } from 'components/Requests/requests.constants';
import { useDraftsBulkSend } from 'components/Requests/state/drafts-bulk-send-state';
import { useNavigate } from 'react-router-dom-latest';

export const DraftsBulkSendFooter = () => {
  const navigate = useNavigate();

  const { getSelectedDraftsCount, removeAllSelectedDrafts, setDueDate } =
    useDraftsBulkSend();

  const selectedFileCount = getSelectedDraftsCount();

  const handleBulkSendClick = () => {
    navigate(REQUESTS_PAGE_ROUTES.requestsDraftsBulkSend);
  };

  const handleClearSelectedClick = () => {
    removeAllSelectedDrafts();
    setDueDate('');
  };
  return (
    <>
      <Box height="100%">selected files ({selectedFileCount}):</Box>
      <ButtonGroup
        variant="contained"
        disableElevation
        disabled={selectedFileCount < 1}
      >
        <Button onClick={handleBulkSendClick}>Bulk Send Preview</Button>
        <Button onClick={handleClearSelectedClick} color="secondary">
          Clear Selected
        </Button>
      </ButtonGroup>
    </>
  );
};
