const initialState = {
  notes: {},
  noteDetail: [],
};

// eslint-disable-next-line default-param-last
export default function noteReducer(state = initialState, action) {
  switch (action.type) {
    case 'NOTE_SUCCESS':
      return { ...state, notes: action.data };
    case 'FETCH_NOTE_DETAIL':
      return { ...state, noteDetail: action.data };
    default:
      return state;
  }
}
