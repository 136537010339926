/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-undef */
/* eslint-disable require-await */
/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-class-component-methods */
/* eslint-disable react/no-unused-state */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-param-reassign */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-prototype-builtins */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
/* eslint-disable max-classes-per-file */
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import debounce from 'lodash/debounce';
import some from 'lodash/some';
import uniqBy from 'lodash/uniqBy';
import { EditorState, convertFromRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { withRouter } from 'utilities/withRouterWrapper';
import {
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  DropdownMenu,
  Label,
  Button,
} from 'reactstrap';
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import FileSaver from 'file-saver';
import mime from 'mime';
import Axios from 'axios';
import { options, removeBreakElementinList } from 'common/NotesUtilities';
import { OptionBoxSelectFieldWithAvatar } from 'common/CustomFields';
import {
  SEARCH_TASK_CONTACTS,
  SEARCH_TASK_ACCOUNTS,
  MESSAGES_API_PATH,
  DOCUMENT_API_PATH,
  DELETE_MESSAGE,
  IMAGES_URLS,
} from 'constants/constants';
import * as TaskApi from 'api/TaskApi';
import * as MessageApi from 'api/MessageApi';
import ConfirmationModal from 'common/ConfirmationModal';
import { AlertMessage, getCurrentPath, getStorageData } from 'utilities/utils';
import TaskFileUpload from 'components/Files/TaskFileUpload';
import * as actions from 'actions/MessageActions';
import * as NotificationActions from 'actions/NotificationActions';
import ImagePreview from 'common/ImagePreview';
import TinymceEditor from 'components/Emails/components/TinymceEditior';
import NewMessageModal from 'components/Messages/NewMessageModal';
import RecipientAndAccountDropdowns from 'components/Messages/RecipientAndAccountDropdowns';
import MessagePdfLayout from './MessagePdfLayout';
import {
  MarkAsUnreadThreadBtn,
  CreateTaskThreadBtn,
  CreateNoteThreadBtn,
} from './MessageToolBar';
import EachMessage from './elements/EachMessage';

const noneStyle = { display: 'none' };
const blockStyle = { display: 'block' };
const isIE = /* @cc_on!@ */ false || !!document.documentMode;
const timeZone =
  Intl.DateTimeFormat().resolvedOptions().timeZone || moment.tz.guess(true);

class ThreadDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      threadArray: [],
      loading: false,
      showEditor: false,
      messageId: '',
      messagesListId: '', // id not used for draft purpose, but other filters
      messageThreadId: '',
      comment: '',
      dropdownOpen: false,
      inputVal: '',
      allContacts: [],
      allAccounts: [],
      accountId: '',
      contactId: '',
      contactName: '',
      accountName: '',
      recipientType: '',
      assigneeType: '',
      threadTitle: '',
      contacts: [],
      accounts: [],
      documents: [],
      selectedRecords: [],
      allSelectedRecords: [],
      hasDraft: false,
      draftModel: false,
      errors: {},
      contatIdPopup: '',
      type: 'message',
      renderOnce: false,
      expMsg: false,
      draftStatus: '',
      mounted: false,
      confirmMessageDel: false,
      messageDelItem: '',
      apiHitting: false,
      previousDocument: false,
      showAttachmentModal: false,
      selectedAttachment: '',
      previewScreen: false,
      previewDocument: null,
      uploadingImages: false,
      sendMessageAfterImageUpload: false,
      isLoading: true,
      isUserTyping: false,
      // imageUrls: [],
      // noOfImages: 0,
    };
    this.switchEditor = this.switchEditor.bind(this);
    this.handleCommentChange = this.handleCommentChange.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.addSelectedInput = this.addSelectedInput.bind(this);
    this.handleAccountChange = this.handleAccountChange.bind(this);
    this.handleContactChange = this.handleContactChange.bind(this);
    this.handleContactInput = this.handleContactInput.bind(this);
    this.handleAccountInput = this.handleAccountInput.bind(this);
    this.previewOpenAttachmenr = this.previewOpenAttachmenr.bind(this);
    this.fetchThreadDetail = this.fetchThreadDetail.bind(this);
    this.goBack = this.goBack.bind(this);
    this.scrollbars = React.createRef();
    this.taskfileUpload = React.createRef();
    window.fileLink = (data) => {
      const doc = {};
      doc.file_share_url = data;
      // this.openAttachment(doc);
      this.previewOpenAttachmenr(doc);
    };
    this.myRef = React.createRef();
  }

  componentDidMount() {
    document.title = 'Message';
    this.fetchThreadDetail(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { location } = nextProps;
    if (
      location &&
      location.state !== null &&
      location.state !== undefined &&
      location.pathname !== this.props.location.pathname &&
      location.state.from === 'notify'
    ) {
      this.fetchThreadDetail(nextProps);
    }
    const {
      location: { hash },
    } = nextProps;
    const {
      location: { hash: currentHash },
    } = this.props;
    if (currentHash === '#image_preview' && hash === '') {
      this.setState({ showImagePreview: false });
    }
    // TODO: __HOMEDATA-AUDIT
    const homeDataObj = getStorageData();
    if (!this.state.mounted) {
      if (homeDataObj !== null && Object.keys(homeDataObj).length) {
        if (homeDataObj.is_employee) {
          TaskApi.getAccounts(this, 'notes');
          this.setState({ mounted: true });
        } else {
          TaskApi.AccountsOfContact(this, homeDataObj.contact_id, 'message');
          this.setState({ mounted: true });
        }
      }
    }
  }

  componentWillUnmount() {
    document.title = 'Inbox';
    // const { comment, imageUrls, initialComment } = this.state;
    // if (comment && comment.entityMap && Object.keys(comment.entityMap).length && imageUrls && imageUrls.length) {
    //   Object.keys(comment.entityMap).forEach((e) => {
    //     const { hash } = comment.entityMap[e].data;
    //     imageUrls.forEach((eachImage) => {
    //       if (comment.entityMap[e].data.src && !comment.entityMap[e].data.src.includes('https://')) {
    //         comment.entityMap[e].data.src = '';
    //       }
    //       if (eachImage.hash === hash) {
    //         comment.entityMap[e].data.src = eachImage.url;
    //         comment.entityMap[e].data.id = eachImage.id;
    //       }
    //     });
    //   });
    // }
    // if (comment && comment.entityMap && imageUrls && imageUrls.length) {
    //   const unusedNewImages = imageUrls.filter((e) => {
    //     let notUsed = true;
    //     Object.keys(comment.entityMap).forEach((each) => {
    //       const { hash, id } = comment.entityMap[each].data;
    //       if (hash === e.hash && id) {
    //         notUsed = false;
    //       }
    //     });
    //     return notUsed;
    //   });
    //   if (unusedNewImages.length) {
    //     unusedNewImages.forEach((e) => {
    //       if (e.url) {
    //         Axios.delete(IMAGES + '/' + e.id);
    //       }
    //     });
    //   }
    // }
    // if (comment && comment.entityMap && initialComment && initialComment.entityMap) {
    //   const deletedImages = Object.values(initialComment.entityMap).filter((e) => {
    //     let notUsed = true;
    //     Object.keys(comment.entityMap).forEach((each) => {
    //       const { hash, id } = comment.entityMap[each].data;
    //       if (id && id === e.data.id) {
    //         notUsed = false;
    //       }
    //     });
    //     return notUsed;
    //   });
    //   if (deletedImages.length) {
    //     deletedImages.forEach((e) => {
    //       if (e.data && e.data.id) {
    //         Axios.delete(IMAGES + '/' + e.data.id);
    //       }
    //     });
    //   }
    // }
    if (this.messageListContainer && this.messageListContainer !== null) {
      this.messageListContainer.removeEventListener('click', () => {
        console.log('removed event listeners');
      });
    }
  }

  fetchThreadDetail(props) {
    // TODO: __HOMEDATA-AUDIT
    const homeDataObj = getStorageData();
    const { state } = props.location;
    let id;
    if (props.name && props.name === 'userlist') {
      id = props.threadItem;
    } else {
      // eslint-disable-next-line prefer-destructuring
      id = props.params.id;
    }
    if (id) {
      MessageApi.getThreads(this, encodeURIComponent(id), state);
      TaskApi.getContactsOfTask(this, { limit: true, message: true }, 'notes');
      if (homeDataObj !== null && Object.keys(homeDataObj).length) {
        if (homeDataObj.is_employee) {
          TaskApi.getAccounts(this, 'notes');
          this.setState({ mounted: true });
        } else {
          TaskApi.AccountsOfContact(this, homeDataObj.contact_id, 'message');
          this.setState({ mounted: true });
        }
      }
    } else {
      this.backClick();
    }
    this.timer = null;
  }

  onFocus() {
    const { errors } = this.state;
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
      contacts: prevState.allContacts,
      accounts: prevState.allAccounts,
      selectedContact: '',
      contactId: '',
      accountId: '',
      contactName: '',
      accountName: '',
    }));
    if (!errors?.participantError) {
      this.setState({ errors: {} });
    }
  }

  handleEventListeners = () => {
    if (
      this.messageListContainer === undefined ||
      this.messageListContainer === null
    ) {
      this.messageListContainer = document.getElementById('messageList');
      // Detect all clicks on the document
      this.messageListContainer.addEventListener(
        'click',
        (event) => {
          const { imagesArray } = this.state;
          if (event?.target?.getAttribute('data-image-id')) {
            const selectedImageId = parseInt(
              event.target.getAttribute('data-image-id'),
              10,
            );
            const selectedThreadImagesIds = [];
            if (imagesArray?.length > 0) {
              let selectedThreadImages = [];
              imagesArray.forEach((eachSection) => {
                eachSection.forEach((e) => {
                  if (e.id === selectedImageId) {
                    selectedThreadImages = eachSection;
                  }
                });
              });
              selectedThreadImages.forEach((e) => {
                selectedThreadImagesIds.push(e.id);
              });
              if (selectedThreadImages.length) {
                this.toggleImagePreview(selectedThreadImages, selectedImageId);
              } else {
                selectedThreadImagesIds.push(selectedImageId);
                this.setState({ loading: true });
                Axios.get(
                  `${IMAGES_URLS}?ids=${selectedThreadImagesIds.toString()}`,
                ).then((res) => {
                  if (res.data && res.data.success && res.data.data) {
                    this.toggleImagePreview(res.data.data, selectedImageId);
                  }
                });
              }
            }
          }
        },
        false,
      );
    }
  };

  // handleImageUrls = (input) => {
  //   this.setState({
  //     imageUrls: input,
  //   });
  // }

  handleImageUploadStatus = (status) => {
    this.setState({ uploadingImages: status }, () => {
      if (
        !this.state.uploadingImages &&
        this.state.sendMessageAfterImageUpload
      ) {
        this.saveMessage('Sent');
      } else {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.saveMessage('Draft');
        }, 1000);
      }
    });
  };

  resetForm() {
    this.setState({
      dropdownOpen: false,
      showEditor: this.state.expMsg,
      comment: '',
      accountId: '',
      contactId: '',
      contatIdPopup: '',
      contactName: '',
      accountName: '',
      assigneeType: '',
      selectedRecords: [],
      documents: [],
    });
    // Discard Draft
    if (this.state.messageId) {
      const msgId = encodeURIComponent(this.state.messageId);
      MessageApi.discardDraft(
        this,
        msgId,
        this.props.actions.discardDraft,
        'thread',
      );
    }
    // if (this.scrollbars && this.scrollbars.current) {
    //   this.scrollbars.current.scrollToBottom();
    // }
  }

  switchEditor() {
    this.setState({ showEditor: true }, () => {
      if (this.myRef && this.myRef.current) {
        // window.scrollTo(0, this.myRef.current.offsetTop);
      }
    });
  }

  handleCommentChange(value) {
    this.setState({ comment: value });

    if (!this.state.errors?.participantError) {
      this.setState({ errors: {} });
    }
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.saveMessage('Draft');
    }, 500);
  }

  goBack() {
    const { match, navigate, location } = this.props;
    const path = getCurrentPath(location);
    navigate('/redirect');
    if (path === '/accountdetails/:id' || path === '/contactdetails/:id') {
      setTimeout(() => {
        navigate(match.pathname, { state: { from: match.pathname } });
      }, 50);
    } else {
      navigate(-1);
    }
  }

  removeSelectedRecord = (contactId, accountId) => {
    const { selectedRecords, allSelectedRecords } = this.state;
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.saveMessage('Draft');
    }, 500);
    const selectedRecordsArr = selectedRecords;
    const allSelectedRecordsArr = allSelectedRecords;
    const index = selectedRecordsArr.findIndex(
      (x) => x.contactId === contactId && x.accountId === accountId,
    );
    const allRecIndex = allSelectedRecordsArr.findIndex(
      (x) => x.contactId === contactId && x.accountId === accountId,
    );
    if (index > -1) {
      selectedRecordsArr.splice(index, 1);
    }
    if (allRecIndex > -1) {
      allSelectedRecordsArr.splice(allRecIndex, 1);
    }
    this.setState({
      selectedRecords: selectedRecordsArr,
      allSelectedRecords: allSelectedRecordsArr,
    });
  };

  toggleArrow = (item) => {
    const { threadArray } = this.state;
    this.setState({
      threadArray: threadArray.map((ele) => {
        if (ele.id === item.id) {
          if (ele.arrow_position === 'up') {
            // eslint-disable-next-line no-param-reassign
            ele.arrow_position = 'down';
          } else {
            // eslint-disable-next-line no-param-reassign
            ele.arrow_position = 'up';
          }
        }
        return ele;
      }),
    });
  };

  handleContactInput(val) {
    if (!this.state.accountId && val) {
      if (val.length < 2) {
        clearTimeout(this.contactSearchTime);
        this.getContAcc('contacts');
      } else {
        this.setState({ contacts: [] });
        clearTimeout(this.contactSearchTime);
        this.contactSearchTime = setTimeout(() => {
          const request = Axios.get(
            `${SEARCH_TASK_CONTACTS}/?keyword=${val}&message=true`,
          );
          request.then((response) => {
            if (response.status === 200) {
              this.setState({ contacts: response.data });
            }
          });
        }, 500);
      }
    }
  }

  handleAccountInput(val) {
    // TODO: __HOMEDATA-AUDIT
    const homeDataObj = getStorageData();
    const { contactId, recipientType } = this.state;
    if (
      val.length >= 2 &&
      homeDataObj.is_employee &&
      ((contactId && recipientType === 'emp') || !contactId)
    ) {
      this.setState({ accounts: [] });
      clearTimeout(this.accountSearchTime);
      this.accountSearchTime = setTimeout(() => {
        const request = Axios.get(
          `${SEARCH_TASK_ACCOUNTS}/?keyword=${val}&message=true`,
        );
        request.then((response) => {
          if (response.status === 200) {
            this.setState({ accounts: response.data });
          }
        });
      }, 500);
    }
  }

  addSelectedInput() {
    const { allAccounts, allContacts, recipientType, errors } = this.state;
    if (!this.state.contactId) return false;
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.saveMessage('Draft');
    }, 500);
    const selectedInput = {
      contactId: this.state.contactId,
      accountId: this.state.accountId,
      contactName: this.state.contactName,
      accountName: this.state.accountName,
      assigneeType: this.state.assigneeType,
      recipientType: this.state.recipientType,
    };
    const selectedRecordsArr = this.state.selectedRecords;
    const index = selectedRecordsArr.findIndex(
      (x) =>
        x.contactId === selectedInput.contactId &&
        x.accountId === selectedInput.accountId,
    );
    const allSelectedRecordsArr = this.state.allSelectedRecords;
    const allRecIndex = allSelectedRecordsArr.findIndex(
      (x) =>
        x.contactId === selectedInput.contactId &&
        x.accountId === selectedInput.accountId,
    );
    if (index === -1) {
      selectedRecordsArr.push(selectedInput);
      this.setState({
        selectedRecords: selectedRecordsArr,
        selectedContact: '',
      });
    }
    if (allRecIndex === -1) {
      this.setState({ allSelectedRecords: allSelectedRecordsArr });
    }
    this.setState({
      dropdownOpen: false,
      contacts: allContacts,
      accounts: allAccounts,
      contactId: '',
      contatIdPopup: '',
      accountId: '',
      contactName: '',
      accountName: '',
      assigneeType: '',
      selectedContact: '',
    });
    if (recipientType === 'emp' && errors?.participantError) {
      this.setState({ errors: {} });
    }
  }

  validateDraftMessage(comment) {
    let output = comment ? comment.toString() : '';
    let haveImages = false;
    if (comment && comment.entityMap && Object.keys(comment.entityMap).length) {
      haveImages = true;
    }
    if (comment && typeof comment === 'object') {
      const content = EditorState.createWithContent(
        convertFromRaw(comment),
      ).getCurrentContent();
      const isEditorEmpty = !content.hasText();
      const currentPlainText = content.getPlainText();
      const lengthOfEditorContent = currentPlainText.length;
      const lengthOfTrimmedContent = currentPlainText.trim().length;
      const isContainOnlySpaces = !isEditorEmpty && !lengthOfTrimmedContent;
      if ((lengthOfEditorContent && !isContainOnlySpaces) || haveImages) {
        output = removeBreakElementinList(
          stateToHTML(
            EditorState.createWithContent(
              convertFromRaw(comment),
            ).getCurrentContent(),
            options,
          ),
        );
      } else if (isContainOnlySpaces) {
        output = '';
      }
    }

    if (typeof output === 'object' || output === '[object Object]') {
      // for empty object
      output = '';
    }
    if (output && output.length > 0) {
      // Comment = Comment.replace(/\n/g, ' <br> ');
    }
    return output;
  }

  // eslint-disable-next-line consistent-return
  saveMessage(newStatus) {
    const path = getCurrentPath(this.props.location);
    if (this.state.apiHitting && newStatus !== 'Sent') {
      return false;
    }
    if (this.state.uploadingImages && newStatus === 'Sent') {
      this.setState({
        loading: true,
        sendMessageAfterImageUpload: true,
      });
      return false;
    }
    if (this.state.uploadingImages) {
      return false;
    }
    if (newStatus === 'Draft') {
      this.setState({ draftStatus: 'Saving', apiHitting: true });
    }
    const { navigate } = this.props;
    clearTimeout(this.timer);
    const component = this;
    const errors = {};
    const { comment } = this.state;
    const draftState = JSON.parse(JSON.stringify(comment));
    const Comment = this.validateDraftMessage(draftState);
    let CommentInJSON = '';
    if (Comment && Comment.length) {
      CommentInJSON = JSON.stringify(draftState);
    }
    const allSelectedRecordsArr = this.state.allSelectedRecords;
    const selectedRecordsArr = this.state.selectedRecords;
    const { selectedRecords, allSelectedRecords, threadArray } = this.state;
    // TODO: __HOMEDATA-AUDIT
    const homeDataObj = getStorageData();
    const hasValue = some(allSelectedRecords, [
      'contactId',
      homeDataObj.cpa_user_id,
    ]);
    if (!selectedRecords.length && homeDataObj.is_employee && !hasValue) {
      const hash = {
        accountId: '',
        accountName: '',
        assigneeType: 'user',
        contactId: homeDataObj.cpa_user_id,
        contactName: homeDataObj.uname,
      };
      allSelectedRecords.push(hash);
    }
    if (
      Comment.trim() === '' &&
      selectedRecordsArr.length < 1 &&
      this.state.documents.length < 1
    ) {
      console.log('empty draft');
      if (this.state.messageId) {
        const msgId = encodeURIComponent(this.state.messageId);
        MessageApi.discardDraft(
          this,
          msgId,
          this.props.actions.discardDraft,
          '',
          false,
        );
      } else {
        this.setState({
          draftStatus: '',
          apiHitting: false,
        });
        return false;
      }
    }
    const participantsArr = [];
    const arrMerged = [...allSelectedRecordsArr, ...selectedRecordsArr];
    const filteredArr = uniqBy(arrMerged, (elem) =>
      [elem.accountId, elem.contactId].join(),
    );
    filteredArr.forEach((mergedItem) => {
      let isRecipient = false;
      selectedRecordsArr.forEach((item) => {
        if (
          item.contactId === mergedItem.contactId &&
          item.accountId === mergedItem.accountId
        ) {
          isRecipient = true;
        }
      });
      const tmpHash = {
        id: mergedItem.contactId,
        account_id: mergedItem.accountId,
        type: mergedItem.assigneeType,
        is_recipient: isRecipient,
      };
      participantsArr.push(tmpHash);
    });
    const mydata = {
      messages: {
        status: newStatus,
        message_thread_id: this.state.messageThreadId,
        content: Comment,
        // content_json: CommentInJSON,
      },
      id: this.state.messageId,
      participants: participantsArr,
    };

    if (this.state.documents.length) {
      mydata.doc_ids = [];
      let x;
      for (x = 0; x < this.state.documents.length; x += 1) {
        mydata.doc_ids.push(this.state.documents[x].id);
      }
    }
    if (newStatus === 'Draft') {
      mydata.id = this.state.messageId;
      const request = Axios.post(MESSAGES_API_PATH, mydata);
      request
        .then((response) => {
          if (response.data.status === 422 && response.data.message === '') {
            this.setState({
              messageId: '',
              apiHitting: false,
              draftStatus: '',
            });
          } else if (response.data.status === 400 && response.data.message) {
            AlertMessage('error', response.data.message, 1500);
            this.setState({
              apiHitting: false,
              draftStatus: '',
            });
          } else {
            component.setState(
              { messageId: response.data.message_id, draftStatus: 'Saved' },
              () => {
                component.setState({ draftStatus: '', apiHitting: false });
              },
            );
          }
        })
        .catch(() => {
          component.setState({
            apiHitting: false,
            draftStatus: '',
          });
        });
    } else {
      if (!Comment && !this.state.documents.length) {
        errors.commentDocument = 'Enter description or select document';
      }
      const messageParticipants =
        threadArray[threadArray.length - 1].msg_participants; // msg par or recip
      if (!selectedRecords.length && !messageParticipants.length) {
        errors.participantError =
          'The message has no active firm recipients. Please add another firm recipient.';
      }
      // return false;
      this.setState({ errors }, () => {});
      if (!Object.keys(errors).length) {
        mydata.id = this.state.messageId;
        component.setState({ loading: true, hasDraft: false });
        const request = Axios.post(MESSAGES_API_PATH, mydata);
        request
          .then((response) => {
            component.setState({ loading: false });
            if (response.data.status === 200) {
              if (
                response.data.messages &&
                response.data.messages !== undefined &&
                response.data.messages.includes('away')
              ) {
                AlertMessage('success', response.data.messages, 3000);
              }
              const array = [];
              const data = response.data.message;
              data[0].participants.map((item) => {
                const tmpHash = {};
                tmpHash.accountId = item.account_id;
                tmpHash.contactId = item.id;
                tmpHash.contactName = item.contact_name;
                tmpHash.assigneeType = item.assignee_type;
                tmpHash.accountName = item.account_name;
                array.push(tmpHash);
                return true;
              });
              component.setState({
                threadArray: data,
                allSelectedRecords: array,
                messageId: '',
              });
              component.resetForm();
              component.setAttachmentNull();
            } else {
              AlertMessage(
                'error',
                response.data.message || 'Some error occurred',
                1500,
              );
            }
          })
          .catch((error) => {
            component.setState({ loading: false });
            return console.error(error);
          });
        // document.documentElement.scrollTop = 0;
        // document.body.scrollTop = 0;
        if (path === '/draft/:id') {
          navigate('/all_messages');
        }
      }
    }
  }

  archiveThread() {
    const { messageThreadId } = this.state;
    MessageApi.archiveThread(this, messageThreadId, 'archive');
  }

  backClick() {
    const { match, navigate, location } = this.props;
    const path = getCurrentPath(location);
    if (
      path === '/accountdetails/:id' ||
      path === '/accountdetails/:id/messages' ||
      path === '/contactdetails/:id'
    ) {
      navigate('/redirect');
      setTimeout(() => {
        navigate(-1);
      }, 50);
    } else if (
      location.state &&
      (location.state.from === 'uploadlist' ||
        location.state.from === 'notify' ||
        location.state.from === 'account')
    ) {
      navigate(-1);
    } else {
      navigate('/redirect');
      setTimeout(() => {
        navigate('/all_messages', {
          state: {
            from: match.path,
            filter: this.props.data.filter,
            activeTab: this.props.data.activeTab,
          },
        });
      }, 50);
    }
  }

  handlePrintPdf = () => {
    const { renderPdfLayout } = this.state;
    this.setState({ renderPdfLayout: !renderPdfLayout });
  };

  errorDoc = () => {
    const err = {};
    err.documents = 'Please select document';
    this.setState({ errors: err });
  };

  expMsg = () => {
    const { expMsg } = this.state;
    this.setState({ expMsg: !expMsg });
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
  };

  confirmDeleteMessage = (bool, item = '') => {
    this.setState({ confirmMessageDel: bool, messageDelItem: item });
    const component = this;
    if (!bool && item) {
      const id = encodeURIComponent(item.id);
      const request = Axios.delete(`${DELETE_MESSAGE}/${id}`);
      request
        .then((response) => {
          if (response.data.status === 200) {
            component.setState({
              threadArray: response.data.data,
              messageDelItem: '',
            });
            AlertMessage('success', response.data.message, 3000);
          } else {
            AlertMessage('error', response.data.message, 3000);
          }
        })
        .catch((error) => {
          AlertMessage('error', 'Some error occurred. Please try later.', 3000);
          return console.error(error);
        });
    }
  };

  downloadAttachments = (documents) => {
    const files = documents;
    const zip = new JSZip();
    let count = 0;
    const file_names = {};
    files.map((item) => {
      const urlPath = item.aws_url_origional;
      let fileName = item.doc_name;
      const temp_doc_name = fileName.split('.');
      if (file_names[item.doc_name]) {
        temp_doc_name[0] = `${temp_doc_name[0]}(${file_names[item.doc_name]})`;
        file_names[item.doc_name] += 1;
      } else {
        file_names[item.doc_name] = 1;
      }
      if (temp_doc_name.length === 1 && item.content_type) {
        temp_doc_name[1] = mime.getExtension(item.content_type);
      }
      fileName = temp_doc_name.join('.');
      // return false;
      JSZipUtils.getBinaryContent(urlPath, (err, data) => {
        if (err) {
          throw err;
        }
        const convertTime = moment()
          .add(moment().utcOffset(), 'minutes')
          .format('YYYY-MM-DD HH:mm:ss');
        zip.file(fileName, data, {
          binary: true,
          createFolders: false,
          date: new Date(convertTime),
        });
        count += 1;
        if (count === files.length) {
          zip.generateAsync({ type: 'blob' }).then((content) => {
            FileSaver.saveAs(content, 'all_docs.zip');
            this.setState({ loading: false });
          });
        }
      });
      return true;
    });
  };

  updateDocumentState = (documents) => {
    this.setState({ documents }, () => {
      this.saveMessage('Draft');
    });
  };

  openAttachment = async (c) => {
    UploadFiles.showOpenAttachment(c, this);
  };

  attachmentModalClose = () => {
    this.setState({
      showAttachmentModal: false,
      selectedAttachment: '',
    });
  };

  previewOpenAttachmenr(doc, event = '', msgId = '') {
    if (event) {
      this.setState({ previousDocument: true });
    }
    this.setState({
      previewScreen: true,
      previewDocument: doc,
      messageDelId: msgId,
    });
  }

  previewCloseAttachment = (data) => {
    if (data.editedFileName) {
      if (this.state.previousDocument) {
        if (this.state.threadArray.length) {
          let y;
          for (y = 0; y < this.state.threadArray.length; y += 1) {
            const array = this.state.threadArray;
            if (array[y].documents.length) {
              const docIds = [];
              let x;
              for (x = 0; x < array[y].documents.length; x += 1) {
                const d = array[y].documents;
                const { id } = d[x];
                if (id === data.selectedItem.id) {
                  const filename = data.editedFileName;
                  d[x].doc_name = filename;
                  this.setState({ threadArray: array });
                }
              }
            }
          }
        }
        this.setState({ previousDocument: false });
      }
    }
    this.setState({
      previewScreen: false,
      previewDocument: null,
      messageDelId: '',
    });
  };

  delAttachmentModal = (doc, msgId) => {
    const { messageDelId } = this.state;
    this.setState({
      delAttachmentModal: true,
      attachmentDetails: doc.id,
      messageDelId: msgId || messageDelId,
    });
  };

  deleteAttachment = () => {
    const { attachmentDetails, messageDelId } = this.state;
    this.setState({ delAttachmentModal: false });
    const request = Axios.delete(
      `${DOCUMENT_API_PATH}/${attachmentDetails}?doc_type=message&message_id=${encodeURIComponent(
        messageDelId,
      )}`,
    );
    request.then((res) => {
      if (res.data.status === 200) {
        let { threadArray } = this.state;
        // const newArray = [];
        threadArray = threadArray.map((each) => {
          if (each.id === messageDelId) {
            each.documents.map((doc) => {
              if (doc.id === attachmentDetails) {
                doc.is_active = false;
              }
              return doc;
            });
          }
          // newArray.push(each);
          return each;
        });
        // console.log('newArray', newArray);
        this.setState({
          threadArray,
          previewScreen: false,
          messageDelId: '',
        });
      }
    });
  };

  togglePinUnpin = (item, index) => {
    const { threadArray } = this.state;
    const threadArr = threadArray;
    threadArr[index].is_pinned = !threadArr[index].is_pinned;
    this.setState({ threadArray: threadArr });
    if (this.props.name === 'inboxlist') {
      this.props.updateTabs();
    }
  };

  toggleImagePreview = (selectedThreadImages, selectedImageId) => {
    const { showImagePreview } = this.state;
    const {
      location: { pathname },
    } = this.props;
    if (this.state.showImagePreview) {
      this.props.navigate(-1);
    } else {
      this.props.navigate(`${pathname}#image_preview`);
    }
    this.setState({
      selectedThreadImages,
      selectedImageId,
      showImagePreview: !showImagePreview,
      loading: false,
    });
  };

  renderThreads(threads) {
    const list = threads.map((item, index) => (
      <EachMessage
        key={item.id}
        item={item}
        indexx={index}
        data={this.state}
        toggleArrow={this.toggleArrow}
        confirmDeleteMessage={this.confirmDeleteMessage}
        downloadAttachments={this.downloadAttachments}
        previewOpenAttachmenr={this.previewOpenAttachmenr}
        delAttachmentModal={this.delAttachmentModal}
        togglePinUnpin={() => this.togglePinUnpin(item, index)}
        account_data={this.state.account_data}
        contact_data={this.props.contact_data}
      />
    ));
    return list;
  }

  renderTags() {
    const selectedRecords = this.state.selectedRecords.map((item) => {
      if (item.accountId) {
        return (
          <span className="tag tag--fill" key={item.contactId + item.accountId}>
            {item.contactName} <i className="divOption" /> {item.accountName}{' '}
            <i
              className="icon-close2 remove"
              onClick={() =>
                this.removeSelectedRecord(item.contactId, item.accountId)
              }
            />
          </span>
        );
      }
      return (
        <span className="tag tag--fill" key={item.contactId + item.accountId}>
          {item.contactName}{' '}
          <i
            className="icon-close2 remove"
            onClick={() =>
              this.removeSelectedRecord(item.contactId, item.accountId)
            }
          />
        </span>
      );
    });
    return selectedRecords;
  }

  /** **************** NEW CODE ******** */

  /**
   * This method is used to reset the recipient and account selects
   * This method gets called when clearing a select value
   * @param {*} changeOnly
   */
  getContAcc(changeOnly = 'both') {
    const { allContacts, allAccounts } = this.state;
    if (changeOnly === 'contacts') {
      this.setState({
        contacts: allContacts,
        contactId: '',
        contatIdPopup: '',
        contactName: '',
        recipientType: '',
        isLoading: false,
        selectedContact: null,
      });
    } else if (changeOnly === 'accounts') {
      this.setState({
        accounts: allAccounts,
        accountId: '',
        accountName: '',
        selectedContact: null,
      });
    } else {
      this.setState({
        contacts: allContacts,
        accounts: allAccounts,
        contactId: '',
        contatIdPopup: '',
        accountId: '',
        contactName: '',
        accountName: '',
        recipientType: '',
        selectedContact: null,
      });
    }
  }

  handleContactChange(val) {
    this.setState({ isUserTyping: false });
    if (!val && !this.state.accountId) {
      this.getContAcc();
    } else if (!val) {
      this.setState({
        contactId: '',
        contatIdPopup: '',
        contactName: '',
        assigneeType: '',
        recipientType: '',
      });
    } else if (val.type === 'contact' && !val.hasOwnProperty('accounts')) {
      // if contact has no account associated with it
      this.setState({
        accounts: [],
        contactId: val.value,
        contatIdPopup: val.id,
        contactName: val.label,
        accountId: '',
        accountName: '',
        cpaId: val.cpa_id,
        assigneeType: val.assigne_type,
        recipientType: val.type,
        selectedContact: val,
      });
      // when an employee is selected from recipient dropdown
    } else if (val.type === 'emp' && !this.state.accountId) {
      this.getContAcc('accounts'); // reset accounts data
      this.setState({
        contactId: val.value,
        contactName: val.label,
        selectedContact: val,
        cpaId: val.cpa_id,
        assigneeType: val.assigne_type,
        recipientType: val.type,
      });
    } else if (val.type === 'emp' && this.state.accountId) {
      this.setState({
        contactId: val.value,
        contactName: val.label,
        selectedContact: val,
        cpaId: val.cpa_id,
        assigneeType: val.assigne_type,
        recipientType: val.type,
      });
    } else if (val.accounts.length === 1) {
      // autopopulate account if only one account
      this.setState({
        accountId: val.accounts[0].value,
        accountName: val.accounts[0].label,
        accounts: val.accounts,
        contactId: val.value,
        contatIdPopup: val.id,
        contactName: val.label,
        cpaId: val.cpa_id,
        assigneeType: val.assigne_type,
        recipientType: val.type,
        selectedContact: val,
      });
    } else {
      const eleArray = this.state.accounts.map((item) => item.value);
      if (eleArray.indexOf(this.state.accountId) > -1) {
        this.setState({
          accounts: val.accounts,
          contactId: val.value,
          contatIdPopup: val.id,
          contactName: val.label,
          selectedContact: val,
          cpaId: val.cpa_id,
          assigneeType: val.assigne_type,
          recipientType: val.type,
        });
      } else {
        this.setState({
          accounts: val.accounts,
          contactId: val.value,
          contatIdPopup: val.id,
          contactName: val.label,
          selectedContact: val,
          cpaId: val.cpa_id,
          assigneeType: val.assigne_type,
          recipientType: val.type,
        });
      }
    }
  }

  handleAccountChange(val, typeOfAction) {
    const { action, removedValues } = typeOfAction;
    if (action === 'clear' && removedValues.length === 0) {
      return;
    }
    // TODO: __HOMEDATA-AUDIT
    const homeDataObj = getStorageData();

    this.setState({ isUserTyping: false });

    if (!val && !this.state.contactId) {
      this.getContAcc();
    } else if (!val) {
      // reset account select when there is no value
      if (this.state.recipientType === 'emp') {
        this.setState({
          accounts: this.state.allAccounts,
          accountId: null,
          accountName: null,
          contacts: this.state.allContacts,
        });
      } else {
        this.setState({
          accounts: this.state.accounts,
          accountId: null,
          accountName: null,
        });
      }
    } else {
      this.setState({
        accountId: val.value,
        accountName: val.label,
      });
      const mydata = {
        accountId: val.value,
        accountName: val.label,
      };
      if (homeDataObj && homeDataObj.is_employee) {
        this.setState({ isLoading: true });
        TaskApi.getContEmpOfAccount(this, mydata);
      } else {
        TaskApi.getRelConAndCstOfAccounts(this, val.value);
      }
    }
  }

  // get filtered results when performing a search in contact select
  getContactFilteredResults = async (searchTerm, from = '') => {
    try {
      const response = await Axios.get(
        `${SEARCH_TASK_CONTACTS}/?keyword=${searchTerm}&message=true`,
      );

      if (response.status === 200) {
        if (from === 'addToRecepient') {
          const contactDetails = response.data[0];
          this.handleContactChange(contactDetails);
          this.addSelectedInput();
        } else {
          this.setState({
            contacts: response.data,
          });
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  // get filtered results when performing a search in account select
  getAccountFilteredResults = async (searchTerm) => {
    try {
      const response = await Axios.get(
        `${SEARCH_TASK_ACCOUNTS}/?keyword=${searchTerm}&message=true`,
      );

      if (response.status === 200) {
        this.setState({ accounts: response.data, allAccounts: response.data });
      }
    } catch (err) {
      console.error(err);
    }
  };

  // handle contact select input change
  handleContactInputChange = debounce((inputValue, typeOfAction) => {
    const { action } = typeOfAction;
    const { accountId, isUserTyping } = this.state;

    /**
     * execute this block when the user clears the select input while hitting backspace or
     * closes the menu without selecting an option
     */
    if (action === 'menu-close' && isUserTyping) {
      this.getContAcc('contacts');
    }

    if (action === 'input-change' && !accountId) {
      if (inputValue) {
        this.getContactFilteredResults(inputValue);
        this.setState({ isUserTyping: true });
      } else {
        this.getContAcc('contacts');
      }
    }
  }, 300);

  // handle account select input change
  handleAccountInputChange = debounce((inputValue, typeOfAction) => {
    const { action, prevInputValue } = typeOfAction;
    const { isUserTyping, contactId, recipientType } = this.state;
    // TODO: __HOMEDATA-AUDIT
    const homeDataObj = getStorageData();

    /**
     * execute this block when the user clears the select input while hitting backspace or
     * closes the menu without selecting an option
     */

    if (
      (action === 'menu-close' || prevInputValue.length === 1) &&
      isUserTyping
    ) {
      this.setState((prevState) => ({
        accounts: prevState.allAccounts,
        accountId: '',
        accountName: '',
      }));
    }

    if (inputValue) {
      if (
        action === 'input-change' &&
        homeDataObj.is_employee &&
        ((contactId && recipientType === 'emp') || !contactId)
      ) {
        this.getAccountFilteredResults(inputValue);
        this.setState({ isUserTyping: true });
      } else if (Array.isArray(this.state.ref_accounts)) {
        this.setState({ allAccounts: this.state.ref_accounts });
      }
    }
  }, 300);

  // reset select dropdowns
  resetSelectDropdowns = () => {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
      contacts: prevState.allContacts,
      accounts: prevState.allAccounts,
      selectedContact: '',
      contactId: '',
      contatIdPopup: '',
      accountId: '',
      contactName: '',
      accountName: '',
      inputVal: '',
      errors: {},
    }));
  };

  render() {
    const { state } = this.props.location;

    if (this.state.draftModel && state) {
      return (
        <NewMessageModal
          draftIds={state.draftId}
          location={this.props.location}
          navigate={this.props.navigate}
        />
      );
    }
    const { threadArray: threads, renderPdfLayout, isLoading } = this.state;
    const showReplyBox = some(threads, { is_active: true });
    if (threads !== undefined && threads.length) {
      // get recep from last ele as it contains every participant
      const recipientArray = threads[threads.length - 1].msg_participants.map(
        (item, index) => {
          if (item.account_name) {
            return (
              <span key={index} className="tag tag--fill">
                {item.contact_name} <i className="divOption" />{' '}
                {item.account_name}
              </span>
            );
          }
          return (
            <span key={index} className="tag tag--fill">
              {item.contact_name}
            </span>
          );
        },
      );
      const isInInboxOrAlertView =
        this.props.data.filter === 'inbox' ||
        this.props.data.filter === 'alert';

      const groupedOptions = TaskApi.groupEmpAndContacts(this.state.contacts);

      return (
        <section
          className={`bottomSpacing${this.state.expMsg ? ' fullWidthMsg' : ''}`}
          data-testid="messageThread"
        >
          <div className="pb-3 section-title section-title--fullwidth section-title--bottomborder">
            <div className="col-container col-containerIE">
              <div className="col col-11 pr-3">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a onClick={() => this.backClick()} href="javascript:;">
                      <i
                        className="icon-left-arrow label-small"
                        aria-hidden="true"
                      />
                      BACK /
                    </a>{' '}
                    {this.state.threadTitle}
                  </li>
                </ul>
              </div>
              <div className="col col-1 text-right">
                <div className="d-flex flex-nowrap btn--leftspace justify-content-end">
                  {/* <a href="javascript:;" className="btn btn-sm btn-outline-light btn--onlyicon"><i className="icon-print" aria-hidden="true" /></a> */}
                  <MarkAsUnreadThreadBtn
                    message={threads[0]}
                    hidden={!isInInboxOrAlertView}
                  />{' '}
                  <CreateTaskThreadBtn
                    threads={threads}
                    hidden={!isInInboxOrAlertView}
                    account_data={this.state.account_data}
                  />
                  <CreateNoteThreadBtn
                    threads={threads}
                    hidden={!isInInboxOrAlertView}
                    account_data={this.state.account_data}
                  />
                  {this.props.data.filter === 'inbox' ||
                  this.props.data.filter === 'alert' ? (
                    <a
                      href="javascript:;"
                      className="btn btn-sm btn-outline-light btn--onlyicon"
                      title="Archive Thread"
                      onClick={() => this.archiveThread()}
                    >
                      <i className="icon-archive" aria-hidden="true" />
                    </a>
                  ) : null}
                  <a
                    href="javascript:;"
                    className="btn btn-sm btn-outline-light btn--onlyicon"
                    title="Print Thread"
                    onClick={() => this.handlePrintPdf()}
                  >
                    <i className="icon-print" aria-hidden="true" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="messageWrap">
            <div
              id="loading"
              style={this.state.loading ? blockStyle : noneStyle}
            />
            <div ref={this.taskfileUpload} className="message__inner">
              <div
                className="messageList"
                id="messageList"
                onLoad={this.handleEventListeners}
              >
                {this.renderThreads(threads)}
              </div>
              {showReplyBox ? (
                <div
                  className="messageReply messageReply--fullwidth pt-4 mt-0"
                  ref={this.myRef}
                >
                  <div className="recipients mb-3">
                    <label className="fontSize font-weight-normal mr-2">
                      Recipients:
                    </label>
                    <span className="email_rcpt">{recipientArray}</span>
                  </div>
                  {this.state.showEditor || this.state.hasDraft ? (
                    <>
                      <FormGroup
                        className={
                          this.state.errors.commentDocument ||
                          this.state.errors.participantError
                            ? ' form-error'
                            : ''
                        }
                      >
                        <div className="labelValue replyInput">
                          <div className="customTags mailDrodown">
                            <div className="customTags__input form-control ">
                              <span className="mr-2 pull-left msgTo">
                                Additional Recipients:
                              </span>
                              {this.renderTags()}
                              <label
                                htmlFor="messageto"
                                onClick={this.onFocus}
                                className="inputHelper dropdown-toggle noCaret"
                              />
                              <a
                                href="javascript:;"
                                className="expand text-dark"
                              >
                                <i
                                  className="icon-expand expMsg"
                                  onClick={this.expMsg}
                                />
                                <i
                                  className="icon-collapse colpsMsg"
                                  onClick={this.expMsg}
                                />
                              </a>
                            </div>
                            <div className="labelValue customTags mailDrodown">
                              {this.state.dropdownOpen ? (
                                <DropdownMenu className="customTags__option option_box">
                                  <RecipientAndAccountDropdowns
                                    selectedContact={this.state.selectedContact}
                                    recipientOptions={groupedOptions}
                                    isLoading={isLoading}
                                    accountOptions={this.state.allAccounts}
                                    handleContactChange={
                                      this.handleContactChange
                                    }
                                    handleAccountChange={
                                      this.handleAccountChange
                                    }
                                    recipientValue={{
                                      label: this.state.contactName,
                                      value: this.state.contactId,
                                    }}
                                    accountValue={{
                                      label: this.state.accountName,
                                      value: this.state.accountId,
                                    }}
                                    handleContactInputChange={
                                      this.handleContactInputChange
                                    }
                                    handleAccountInputChange={
                                      this.handleAccountInputChange
                                    }
                                  />
                                  {/* <OptionBoxSelectFieldWithAvatar
                                    name="seachContact"
                                    label="Recipient"
                                    options={this.state.contacts}
                                    handleChange={this.handleContactChange}
                                    handleInputChange={this.handleContactInput}
                                    value={{
                                      value: this.state.contactId,
                                      label: this.state.contactName,
                                    }}
                                    isLoading={isLoading}
                                    placeholder="Select a Recipient"
                                    autofocus
                                    clearable
                                  />
                                  <OptionBoxSelectFieldWithAvatar
                                    name="seachAccount"
                                    label="Account"
                                    options={this.state.accounts}
                                    handleChange={this.handleAccountChange}
                                    handleInputChange={this.handleAccountInput}
                                    value={{
                                      value: this.state.accountId,
                                      label: this.state.accountName,
                                    }}
                                    placeholder="Select an account"
                                    clearable
                                  /> */}
                                  <div className="btn-wrap btn--leftspace">
                                    <Button
                                      className="btn-link btn-sm btn--onlyicon"
                                      onClick={this.resetSelectDropdowns}
                                    >
                                      Close
                                    </Button>
                                    <Button
                                      className="btn btn-sm btn-primary"
                                      onClick={this.addSelectedInput}
                                    >
                                      Ok
                                    </Button>
                                  </div>
                                </DropdownMenu>
                              ) : null}
                            </div>
                          </div>
                          <div className="customEditor RTE_Editor boxResize">
                            <TinymceEditor
                              autofocus
                              value={this.state.comment}
                              handleChange={this.handleCommentChange}
                            />
                          </div>
                          <span className="error_label">
                            {this.state.errors.participantError}
                          </span>
                        </div>
                      </FormGroup>
                      <FormGroup
                        className={`dragWrap${
                          this.state.errors.commentDocument ? ' form-error' : ''
                        }`}
                      >
                        <Label htmlFor="attachments">Attachments</Label>
                        <TaskFileUpload
                          data={this.state}
                          uploadType="message"
                          messageComp="threadDetail"
                          updateDocumentState={this.updateDocumentState}
                        />
                        <span className="error_label">
                          {this.state.errors.commentDocument}
                        </span>
                      </FormGroup>
                      <FormGroup className="d-flex justify-content-end btn-wrap btn--leftspace mt-4">
                        <span className="text-dark pr-2 mt-2">
                          {this.state.draftStatus}
                        </span>
                        <Button
                          className="btn-link btn--onlyicon"
                          data-toggle="tooltip"
                          data-placement="right"
                          title="Discard Message"
                          onClick={() => this.resetForm()}
                        >
                          <i className="icon-delete-small" aria-hidden="true" />
                        </Button>
                        <Button
                          color="primary"
                          onClick={() => this.saveMessage('Sent')}
                          disabled={
                            this.state.draftStatus === 'Saving' ||
                            Boolean(this.state.errors.participantError)
                          }
                        >
                          Send
                        </Button>
                      </FormGroup>
                    </>
                  ) : (
                    <FormGroup>
                      <div className="chatMsg--inputchat w-100 customEditor customEditor--bottombtn">
                        <textarea
                          name="description"
                          className="form-control"
                          placeholder="Reply"
                          onFocus={this.switchEditor}
                        />
                      </div>
                    </FormGroup>
                  )}
                </div>
              ) : null}
            </div>
          </div>
          <Modal
            isOpen={this.state.showAttachmentModal}
            className="customModal customModal--w990 customModal--nopadding modalMxHeight"
          >
            <ModalHeader className="ModalHeader">
              <button
                type="button"
                onClick={this.attachmentModalClose}
                className="close"
                aria-label="Close"
              >
                <i className="icon-close2" aria-hidden="true" />
              </button>
            </ModalHeader>
            <ModalBody className="modalContent">
              <div className="modalContent--inner">
                <div
                  className={
                    isIE ? 'left-inner-content' : 'sample-letter-preview'
                  }
                >
                  <div className="letter-logo" />

                  <iframe
                    width="100%"
                    height="600"
                    src={this.state.selectedAttachment}
                    title="selected attachment"
                  />
                </div>
              </div>
            </ModalBody>
          </Modal>
          <ConfirmationModal
            isOpen={this.state.confirmMessageDel}
            messageText="Are you sure want to delete this message?"
            noBtnClick={() => this.confirmDeleteMessage(false, '')}
            yesBtnClick={() =>
              this.confirmDeleteMessage(false, this.state.messageDelItem)
            }
            yesBtnText="Yes"
            noBtnText="No"
          />
          <ConfirmationModal
            isOpen={this.state.delAttachmentModal}
            yesBtnClick={this.deleteAttachment}
            noBtnClick={() =>
              this.setState({ delAttachmentModal: false, messageDelId: '' })
            }
            headerText="You are about to delete the attached file."
            messageText="This action cannot be undone. Proceed?"
            yesBtnText="Yes"
            noBtnText="No"
          />
          {renderPdfLayout ? (
            <MessagePdfLayout
              data={this.state}
              handlePrintPdf={this.handlePrintPdf}
            />
          ) : null}
          {this.state.showImagePreview &&
            this.state.selectedThreadImages &&
            this.state.selectedThreadImages.length &&
            this.state.selectedImageId && (
              <ImagePreview
                toggle={this.toggleImagePreview}
                items={this.state.selectedThreadImages}
                selectedImageId={this.state.selectedImageId}
              />
            )}
        </section>
      );
    }
    return (
      <div id="loading" style={this.state.loading ? blockStyle : noneStyle} />
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
  NotificationActions: bindActionCreators(NotificationActions, dispatch),
});

export default withRouter(connect(null, mapDispatchToProps)(ThreadDetails));
