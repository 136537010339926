import React from 'react';
import TruncateMarkup from 'react-truncate-markup';
import styled from '@emotion/styled';
import { formatText } from 'utilities/utils';

const StyledDiv = styled.div`
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: ${(props) => props.weight || 400};
  font-size: 15px;
  line-height: 22px;
  color: ${(props) => (props.type === 'primary' ? '#212B36' : '#919eab')};
`;

const Snippet = ({ snippet, lines = 1, type = 'secondary', weight }) => (
  <StyledDiv type={type} weight={weight}>
    <TruncateMarkup lines={lines}>
      <span>{formatText(snippet)}</span>
    </TruncateMarkup>
  </StyledDiv>
);

export default Snippet;
