/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable no-promise-executor-return */
import {
  SMS_MESSAGES,
  SMS_THREADS,
  HOME_API_PATH,
  SMS_STATUS,
  SMS_ORDER,
  SMS_PHONE_LINES,
  SMS_ADD_TO_VAULT,
  SMS_ARCHIVE_THREAD,
  SMS_PROVISION_STATUS,
  SMS_BRAND_REGISTRATION,
} from 'constants/constants';
import axios from 'axios';
import camelcaseKeysDeep from 'camelcase-keys-deep';
import { SMSVaultDialogType } from 'components/SMS/components/SMSVaultDialog/FormValidation';
import { SMSSettingsType } from 'components/SMS/components/SMSSettingsForm/FormValidation';
import { mapSmsSettings } from './utils/mapSmsSettings';

export async function getThreads(): Promise<ThreadsListResponse> {
  const { data: threads } = await axios.get(SMS_THREADS);

  return {
    threads,
  };
}

// TODO: this is hopefully extremely temporary and imo not worth testing.
// This logic should go away when we get endpoint responses in the correct shape
function makeTypedMessageFromDemoMessage(
  {
    id,
    conversation_id,
    text_message_thread_id,
    body,
    created_at,
    direction,
    attachments,
    delivery_status,
    from,
    participants: messageParticipants,
  }: DemoSMSRawMessage,
  participants: Participant[],
): Message {
  return {
    id,
    textMessageThreadId: text_message_thread_id,
    threadId: conversation_id,
    messageText: body,
    createdAt: created_at,
    direction,
    sender: participants,
    attachments,
    status: delivery_status,
    from,
    messageParticipants,
  };
}

export async function getThreadDetail({
  queryKey,
}: QueryCallParams): Promise<ThreadDetail> {
  const [, id] = queryKey;
  const { data } = await axios.get(`${SMS_THREADS}/${id}`);

  const threadDetail: ThreadDetail = {
    ...data,
    messages: camelcaseKeysDeep(
      data.messages.map((message: DemoSMSRawMessage) =>
        makeTypedMessageFromDemoMessage(message, data.participants),
      ),
    ) as Message[],
  };

  return threadDetail;
}

export async function getThreadDetailPreview({
  queryKey,
}: QueryCallParams): Promise<ThreadDetail> {
  const [, id] = queryKey;
  const { data } = await axios.get(`${SMS_THREADS}/${id}/preview`);

  const threadDetail: ThreadDetail = {
    ...data,
    messages: camelcaseKeysDeep(
      data.messages.map((message: DemoSMSRawMessage) =>
        makeTypedMessageFromDemoMessage(message, data.participants),
      ),
    ) as Message[],
  };

  return threadDetail;
}

export async function getFirmUserSMSStatus(): Promise<FirmUserSMSStatus> {
  const { data } = await axios.get(`${SMS_STATUS}`);

  return camelcaseKeysDeep(data) as FirmUserSMSStatus;
}

export async function getSMSProvisionedStatus(): Promise<SMSProvisionStatus> {
  const { data } = await axios.get(SMS_PROVISION_STATUS);

  return data;
}

export function createSMSMessage(message: SMSMessagePostBody) {
  return axios.post(SMS_MESSAGES, message);
}

export async function getHome(): Promise<Home> {
  // TODO: __HOMEDATA-AUDIT --should this be in the sms domain?
  const { data } = await axios.get(HOME_API_PATH);

  return data;
}

// creates a phone line
export function createPhoneLine() {
  return axios.post(SMS_ORDER);
}

// TODO: unmock and replace with API when available
export function getSMSPhoneLineList(page: number, search: string) {
  if (search.length > 0)
    return axios.get(`${SMS_PHONE_LINES}?page=${page}&searchFilter=${search}`);

  return axios.get(`${SMS_PHONE_LINES}?page=${page}`);
}

// add an SMS document to the vault
export function addSmsDocumentToVault(smsDocument: SMSVaultDialogType) {
  return axios.post(SMS_ADD_TO_VAULT, smsDocument);
}

export function archiveSMSThread(smsThreadId: string | number) {
  return axios.post(SMS_ARCHIVE_THREAD, { smsThreadId });
}

export function createSmsBrandRegistrationV5(settings: SMSSettingsType) {
  return axios.post(SMS_BRAND_REGISTRATION, mapSmsSettings(settings));
}
