import React from 'react';
import { isValidPhoneNumber } from 'react-phone-number-input';
import {
  REMOVESPECIALCHAR,
  EMAIL,
  FORMATPHONENUMBER,
} from 'utilities/regularexpressions';
import { captalizeText, validateAddress } from 'utilities/utils';

export const SpaceDiv = () => (
  <div style={{ display: 'flex', width: '20px' }} />
);

export const selectComponentColors = (error) => {
  const colourStyles = {
    control: (styles, { isFocused }) => {
      let borderColor = isFocused ? '#7B8897' : '#dfe3e8';
      let backgroundColor = 'white';
      let borderBottom = isFocused ? '2px solid #7B8897' : '2px solid #dfe3e8';
      if (error) {
        borderColor = 'red';
        backgroundColor = '#F9E3DD';
        borderBottom = '2px solid red';
      }
      return {
        ...styles,
        backgroundColor,
        borderStyle: 'solid',
        borderColor,
        borderWidth: '2px',
        '&:hover': {
          borderColor,
          borderBottom,
        },
        borderRadius: '2px',
        boxShadow: null,
        minHeight: '25px',
        height: '36px',
        fontSize: '1rem',
        color: '#495057',
      };
    },
    menu: (styles) => {
      const allstyles = styles;
      return {
        ...allstyles,
        marginTop: '-4px',
        borderStyle: 'solid',
        borderColor: error ? 'red' : '#7B8897',
        borderWidth: '2px',
        borderTop: 'transparent',
        borderRadius: '0 0 2px 2px',
        boxShadow: 'none',
        backgroundColor: error ? '#F9E3DD' : 'white',
        textTransform: 'capitalize',
      };
    },
    singleValue: (styles) => {
      const allstyles = styles;
      return {
        ...allstyles,
        lineHeight: 1,
        fontSize: '15px',
        color: '#495057',
        textTransform: 'capitalize',
      };
    },
    input: (styles) => {
      const allstyles = styles;
      return {
        ...allstyles,
        color: '#495057',
      };
    },
  };
  return colourStyles;
};

export const getDefaultPreference = (cpapreferences, prefType) => {
  if (cpapreferences[prefType] && cpapreferences[prefType].length) {
    return cpapreferences[prefType][0].value;
  }
  return undefined;
};

export const contactAddressFieldNames = {
  addressLine1Name: 'address_line1',
  addressLine2Name: 'address_line2',
  cityName: 'city',
  stateName: 'state',
  countryName: 'country',
  zipName: 'zip',
};

export const enableAddPhoneNumberBtn = (contactPhone = []) =>
  !contactPhone.every(
    (each) =>
      each.value &&
      each.value.length &&
      REMOVESPECIALCHAR(FORMATPHONENUMBER(each.value)).length === 10,
  );

export const enableAddEmailBtn = (contactEmail = []) =>
  !contactEmail.every((each) => each.value && EMAIL.test(each.value));

export const enableAddAddressBtn = (contactAddresses = []) => {
  const { addressLine1Name, cityName, stateName, countryName, zipName } =
    contactAddressFieldNames;
  const isValid = contactAddresses.every((each) => {
    let output = false;
    if (
      each[countryName] &&
      each[countryName].toLowerCase() === 'us' &&
      each[addressLine1Name] &&
      each[cityName] &&
      each[stateName] &&
      each[zipName] &&
      (each[zipName].length === 5 ||
        REMOVESPECIALCHAR(each[zipName]).length === 9)
    ) {
      output = true;
    } else if (
      each[countryName] &&
      each[countryName].toLowerCase() !== 'us' &&
      each[addressLine1Name]
    ) {
      output = true;
    } else {
      output = false;
    }
    return output;
  });
  return !isValid;
};

export const validatePhoneNumbers = (phoneNumbers) => {
  const contactPhoneErrors = {};
  phoneNumbers.map((each, key) => {
    if (
      (each.value && each.value.length && !isValidPhoneNumber(each.value)) ||
      (each.checkbox && (each.value === undefined || each.value.length === 0))
    ) {
      contactPhoneErrors[key] = {};
      contactPhoneErrors[key].value = 'Invalid phone number';
    }
    if (each.value && each.value.length && !each.type) {
      contactPhoneErrors[key] = contactPhoneErrors[key]
        ? contactPhoneErrors[key]
        : {};
      contactPhoneErrors[key].type = 'Select type';
    }
    return each;
  });
  return contactPhoneErrors;
};

export const validatEmailIds = (emails) => {
  const contactEmailErrors = {};
  emails.map((each, key) => {
    if (each.value && each.value.length && !EMAIL.test(each.value)) {
      const fieldName = 'value';
      contactEmailErrors[key] = {};
      contactEmailErrors[key][fieldName] = 'Invalid Email';
    }

    if (each.value && each.value.length && !each.type) {
      const fieldName = 'type';
      contactEmailErrors[key] = contactEmailErrors[key]
        ? contactEmailErrors[key]
        : {};
      contactEmailErrors[key][fieldName] = 'Select type';
    }
    return each;
  });
  return contactEmailErrors;
};

export const validateAddresses = (addresses, addressFieldNames) => {
  const {
    addressLine1Name,
    addressLine2Name,
    cityName,
    stateName,
    countryName,
    zipName,
  } = addressFieldNames;
  const contactAddressesError = {};
  addresses.map((each, index) => {
    if (
      each[addressLine1Name] ||
      each[addressLine2Name] ||
      each[cityName] ||
      each[stateName] ||
      each[zipName] ||
      each[countryName]
    ) {
      const addressErrors = validateAddress(each, addressFieldNames);
      if (Object.keys(addressErrors).length) {
        contactAddressesError[index] = addressErrors;
      }
    }
    return null;
  });
  return contactAddressesError;
};

export const filterContactStatusOptions = (
  preferences,
  currentStatus,
  newContact,
) => {
  const allpreferences = { ...preferences };
  const contactStatus = [];
  if (currentStatus.toLowerCase() === 'new' && !newContact) {
    contactStatus.push({ value: 'New', label: 'New' });
  } else if (currentStatus.toLowerCase() === 'inactive') {
    contactStatus.push({
      value: captalizeText(currentStatus),
      label: captalizeText(currentStatus),
    });
    contactStatus.push({ value: 'New', label: 'New' });
  } else {
    contactStatus.push({
      value: captalizeText(currentStatus),
      label: captalizeText(currentStatus),
    });
    contactStatus.push({ value: 'Inactive', label: 'Inactive' });
  }
  allpreferences.contact_status = contactStatus;
  return allpreferences;
};

export const sortContactList = (list, sortby, desc) => {
  const output = list;
  output.sort((a, b) => {
    if (sortby === 'last_name') {
      if (a.contact.last_name.toLowerCase() < b.contact.last_name.toLowerCase())
        return -1;
      if (a.contact.last_name.toLowerCase() > b.contact.last_name.toLowerCase())
        return 1;
      return 0;
    }
    if (sortby === 'account_name') {
      if (a.account.label.toLowerCase() < b.account.label.toLowerCase())
        return -1;
      if (a.account.label.toLowerCase() > b.account.label.toLowerCase())
        return 1;
      return 0;
    }
    if (sortby === 'primary_email') {
      if (
        a.contact.primary_email !== null &&
        b.contact.primary_email !== null
      ) {
        if (a.contact.primary_email < b.contact.primary_email) return -1;
        if (a.contact.primary_email > b.contact.primary_email) return 1;
        return 0;
      }
      if (
        a.contact.primary_email === null &&
        b.contact.primary_email !== null
      ) {
        return 1;
      }
      if (
        a.contact.primary_email !== null &&
        b.contact.primary_email === null
      ) {
        return -1;
      }
      if (
        a.contact.primary_email === null &&
        b.contact.primary_email === null
      ) {
        return 1;
      }
    }
    if (sortby === 'phone_number') {
      if (a.contact_phone !== null && b.contact_phone !== null) {
        if (a.contact_phone[0].phone_number < b.contact_phone[0].phone_number)
          return -1;
        if (a.contact_phone[0].phone_number > b.contact_phone[0].phone_number)
          return 1;
        return 0;
      }
      if (a.contact_phone === null && b.contact_phone !== null) {
        return 1;
      }
      if (a.contact_phone !== null && b.contact_phone === null) {
        return -1;
      }
      if (a.contact_phone === null && b.contact_phone === null) {
        return 1;
      }
    }
    if (sortby === 'communication_pref_type') {
      if (
        a.contact.communication_pref_type !== null &&
        b.contact.communication_pref_type !== null
      ) {
        if (
          a.contact.communication_pref_type.toLowerCase() <
          b.contact.communication_pref_type.toLowerCase()
        )
          return -1;
        if (
          a.contact.communication_pref_type.toLowerCase() >
          b.contact.communication_pref_type.toLowerCase()
        )
          return 1;
        return 0;
      }
      if (
        a.contact.communication_pref_type === null &&
        b.contact.communication_pref_type !== null
      ) {
        return 1;
      }
      if (
        a.contact.communication_pref_type !== null &&
        b.contact.communication_pref_type === null
      ) {
        return -1;
      }
      if (
        a.contact.communication_pref_type === null &&
        b.contact.communication_pref_type === null
      ) {
        return 1;
      }
    }
    if (typeof a[sortby] === 'number') {
      if (a[sortby] < b[sortby]) return -1;
      if (a[sortby] > b[sortby]) return 1;
      return 0;
    }
    return 0;
  });
  if (desc === 'desc') {
    return output.reverse();
  }
  return output;
};
