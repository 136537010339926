import React from 'react';
import PropTypes from 'prop-types';
import { INVOICES_TAB } from 'constants/constants';
import TableHeader from './BillingTable/TableHeader';
import TableRow from './BillingTable/TableRow';

const Invoices = ({
  allInvoices,
  handleColumnSorting,
  sortOrder,
  sortBy,
  isInvoiceHistoryTab = false,
}) => (
  <table className="Invoices table caption-top">
    <TableHeader
      currentTab={INVOICES_TAB}
      handleColumnSorting={handleColumnSorting}
      sortOrder={sortOrder}
      sortBy={sortBy}
    />
    <tbody>
      {allInvoices && allInvoices.length === 0 && (
        <tr>
          <td className="NoRecordsFound">
            <div>No Records Found</div>
          </td>
        </tr>
      )}
      {allInvoices &&
        allInvoices.length > 0 &&
        allInvoices.map((row) => (
          <TableRow
            key={row.id}
            row={row}
            currentTab={INVOICES_TAB}
            isInvoiceHistoryTab={isInvoiceHistoryTab}
          />
        ))}
    </tbody>
  </table>
);

export default Invoices;

Invoices.propTypes = {
  allInvoices: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleColumnSorting: PropTypes.func.isRequired,
  sortOrder: PropTypes.string.isRequired,
  sortBy: PropTypes.string.isRequired,
  isInvoiceHistoryTab: PropTypes.bool,
};
