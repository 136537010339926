import { useState } from 'react';
import { useSMSProvisionedStatus } from 'hooks/sms-hooks';
import { useNavigate } from 'react-router-dom-latest';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { formatSMSPhone } from 'components/SMS/utils/formatSMSPhone';
import {
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Typography,
  Menu,
} from '@mui/material';
import { SMSAvatar } from 'components/SMS/components/SMSAvatar/SMSAvatar';
import { getRole } from 'utilities/utils';
import SMSReminderButton from './SMSReminderButton';
import SMSReminderIconButton from './SMSReminderIconButton';
import { makeSMSReminderTooltip } from './make-sms-reminder-tooltip';

type SMSReminderProps = {
  variant?: 'button' | 'iconbutton';
  reminder: string;
  smsContacts: SMSContact[];
};

export default function SMSReminder({
  variant = 'button',
  smsContacts = [],
  reminder,
}: SMSReminderProps) {
  const { smsReminderFeature } = useFlags();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const role = getRole();

  const { data: provisionStatus } = useSMSProvisionedStatus();
  const navigate = useNavigate();

  const isFirmAdmin = role === 'firm_admin';

  const firmSMSEnabled =
    provisionStatus?.current_integration_step === 'completed';
  const contactIsValidSMSRecipient = Boolean(
    smsContacts?.[0]?.contact_sms_enabled &&
      smsContacts?.[0]?.contact_sms_phone_number?.length !== 0,
  );
  const smsReadyToGo =
    provisionStatus?.current_integration_step === 'completed' &&
    provisionStatus.current_user_step === 'completed';

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>,
  ) => {
    event.preventDefault();
    event.stopPropagation();

    if (!smsReminderFeature) return null;

    if (!firmSMSEnabled) {
      navigate('/sms');

      return null;
    }

    if (smsContacts.length > 1) {
      setAnchorEl(event.currentTarget);
    } else if (smsContacts.length === 1) {
      navigateToSMS(smsContacts[0]);
    }

    return null;
  };

  const navigateToSMS = (SMSContact: SMSContact) => {
    const { contact_sms_phone_number, contact_sms_thread_id } = SMSContact;
    const phone = formatSMSPhone(
      (contact_sms_phone_number as string).replaceAll(' ', ''),
    );

    const stateObj: SMSReminderPayload = {
      phone: phone.replaceAll(' ', ''),
      reminder,
    };

    // either send to the existing thread or create new message
    if (contact_sms_thread_id) {
      navigate(`/sms/${contact_sms_thread_id}`, { state: stateObj });
    } else {
      navigate('/sms/new', { state: stateObj });
    }

    return null;
  };

  const tooltip = !smsReminderFeature
    ? 'Coming Soon!'
    : makeSMSReminderTooltip({
        provisionStatus,
        user: smsContacts?.[0],
        isFirmAdmin,
      });

  return (
    <>
      {variant === 'button' ? (
        <SMSReminderButton
          enabled={contactIsValidSMSRecipient || !smsReadyToGo}
          sx={{
            color: 'black',
            background: 'white',
            border: 'solid 2px #dfe3e8',
            '&:hover': {
              background: 'white',
            },
          }}
          handleClick={handleClick}
          tooltip={tooltip}
        />
      ) : (
        <SMSReminderIconButton
          enabled={contactIsValidSMSRecipient || !smsReadyToGo}
          sx={{
            padding: '0px',
            marginLeft: '8px',
          }}
          handleClick={handleClick}
          tooltip={tooltip}
        />
      )}
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        sx={{ maxHeight: '200px' }}
      >
        {smsContacts.map((item: SMSContact) => {
          const displayName =
            item.contact_sms_last_name && item.contact_sms_last_name.length > 0
              ? `${item.contact_sms_first_name} ${item.contact_sms_last_name}`
              : formatSMSPhone(item.contact_sms_phone_number as string);
          return (
            <MenuItem
              key={item.contact_sms_phone_number}
              onClick={() => navigateToSMS(item)}
              sx={{ cursor: 'pointer' }}
            >
              <ListItemAvatar>
                <SMSAvatar
                  participant={{
                    firstName: item.contact_sms_first_name || '',
                    lastName: item.contact_sms_last_name || '',
                    phoneNumber: item.contact_sms_phone_number!,
                  }}
                  width={32}
                  height={32}
                />
              </ListItemAvatar>
              <ListItemText>
                <Typography flexGrow={1} sx={{ fontWeight: 700 }} noWrap>
                  {displayName}
                </Typography>
              </ListItemText>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
}
