// eslint-disable-next-line import/prefer-default-export
export function mungeResponsesForInputField(responses) {
  return responses.reduce(
    (acc, response) =>
      response?.result?.results && Array.isArray(response?.result?.results)
        ? [...acc, ...mungeResults(response.result.results)]
        : acc,
    [],
  );
}

export function mungeResults(results) {
  return results.reduce(
    (acc, { person: { emailAddresses, names, photos } }) =>
      !emailAddresses
        ? acc
        : [
            ...acc,
            {
              value: emailAddresses[0].value,
              label: names ? names[0].displayName : emailAddresses[0].value,
              avatar: photos[0].url,
            },
          ],
    [],
  );
}
