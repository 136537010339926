import React, { Component } from 'react';
import loadImage from 'blueimp-load-image';

class FileUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleFile = this.handleFile.bind(this);
  }

  handleFile(e) {
    const file = e.target.files[0];
    const options = { canvas: true };
    if (!file) return;
    const component = this;
    loadImage.parseMetaData(file, (data) => {
      if (data.exif) {
        options.orientation = data.exif.get('Orientation');
      }
      loadImage(
        file,
        (img) => {
          const base64 = img.toDataURL(file.type);
          // eslint-disable-next-line no-unused-vars
          const re = this.myRef;
          // ReactDom.findDOMNode(component.refs.myRef).value = '';
          component.props.handleFileChange(base64, file.type);
        },
        options,
      );
    });
  }

  render() {
    return (
      <div className="Input-Chosse-btn">
        <input
          ref={(ref) => {
            this.myRef = ref;
          }}
          type="file"
          id={this.props.id}
          accept="image/*"
          onChange={this.handleFile}
        />
      </div>
    );
  }
}

export default FileUpload;
