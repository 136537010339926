/* eslint-disable jsx-a11y/anchor-is-valid */

import { SignInCpaData } from 'api/login-v5';

export interface CPAButtonProps {
  cpa: SignInCpaData;
  onClick: (cpaUUID: string) => void;
}

export const CPAButton = ({ cpa, onClick }: CPAButtonProps) => {
  const firstLetter = cpa.cpa_name[0];
  return (
    <div className="col-md-4 col-12 pt-2">
      <a
        onClick={() => onClick(cpa.cpa_uuid)}
        href="#"
        className="activeUser text-center d-inline-block w-100 link linkDark"
      >
        {cpa.cpa_logo_url ? (
          <div className="cpaImg rounded-circle mb-2 mx-auto">
            <img
              src={cpa.cpa_logo_url}
              className="name-initials"
              alt={cpa.cpa_name}
            />
          </div>
        ) : (
          <span className="avatar avatar--w40 mb-2">
            <span className="uName mr-0">{firstLetter.toUpperCase()}</span>
          </span>
        )}
        <h4 className="label-large mb-0">{cpa.cpa_name}</h4>
      </a>
    </div>
  );
};

export default CPAButton;
