import styled from '@emotion/styled';

export const StyledDiv = styled.div`
  background-color: #f9fafb;
  max-width: 550px;
  min-height: 220px;
  margin: auto;
  box-shadow: 0 10px 40px 0 rgb(0 0 0 / 10%);
  position: relative;
  top: 100px;
  left: 0;
  padding: 20px;
  .Input__Container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #919eab;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 600;
    margin-bottom: 15px;
    & > form {
      flex: 0 0 100%;
      .form-inline-label {
        flex-direction: column;
        flex: 0 0 100%;
        max-width: 100%;
        label {
          margin-bottom: 5px;
        }
        .select-custom-class {
          max-width: 100%;
        }
      }
    }
    .InputInr {
      flex: 0 0 100%;
      max-width: 100%100%;
      label {
        margin-bottom: 0.3rem;
      }
    }
    .error_label {
      display: none;
    }
  }
  .RowWrap {
    display: flex;
    align-items: flex-start;
    .Input__Container {
      flex: 0 0 50%;
      padding-right: 10px;
      & + .Input__Container {
        padding-left: 10px;
        padding-right: 0;
      }
    }
  }
  .Input__Radio {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    label {
      font-size: 0.75rem;
      color: #919eab;
      text-transform: uppercase;
      letter-spacing: 1px;
      text-align: left;
      font-weight: 600;
      padding-top: 0;
    }
    .RadioWrap {
      label {
        text-transform: none;
      }
      input[type='radio'] {
        margin-right: 5px;
        vertical-align: middle;
      }
      & > div {
        display: inline-block;
        margin-right: 10px;
      }
    }
  }
  .Button__Container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 10px;
    button {
      margin-left: 12px;
    }
  }
  .Header__Container {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
  }
  .Header__Text {
    font-weight: 500;
    color: #212b36;
    font-size: 18px;
  }
  .Button__Close {
    transform: translate(30px, -39px);
  }
  .MuiSvgIcon-root {
    font-size: 30px;
  }
`;
