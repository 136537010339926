import {
  Autocomplete,
  FormControl,
  InputLabel,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { getCpaPreferences } from 'utilities/utils';
import { SMSVaultDialogType } from './FormValidation';

type Props = {
  monthOrYear: 'MONTH' | 'YEAR';
};

export default function MonthYearElement({ monthOrYear }: Props) {
  const theme = useTheme();
  const {
    register,
    formState: { errors },
  } = useFormContext<SMSVaultDialogType>();

  const preferences = getCpaPreferences();
  const { upload_file_month, upload_file_year } = preferences;
  const uploadFileMonth: LegacyUpload[] = upload_file_month;
  const uploadFileYear: LegacyUpload[] = upload_file_year;

  return (
    <Stack>
      <InputLabel htmlFor={monthOrYear}>
        <Typography variant="h5" sx={{ color: theme.palette.text.secondary }}>
          {monthOrYear}
        </Typography>
      </InputLabel>
      <FormControl
        sx={{
          paddingBottom: '1rem',
          width: '16rem',
        }}
      >
        <Autocomplete
          id={monthOrYear}
          options={monthOrYear === 'MONTH' ? uploadFileMonth : uploadFileYear}
          getOptionLabel={(opt) => opt.label}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              {...register(
                monthOrYear === 'MONTH' ? 'uploadFileMonth' : 'uploadFileYear',
              )}
              error={Boolean(
                monthOrYear === 'MONTH'
                  ? errors.uploadFileMonth
                  : errors.uploadFileYear,
              )}
              helperText={
                monthOrYear === 'MONTH'
                  ? errors.uploadFileMonth?.message
                  : errors.uploadFileYear?.message
              }
            />
          )}
          ListboxProps={{
            style: { maxHeight: '15rem' },
          }}
        />
      </FormControl>
    </Stack>
  );
}
