/* eslint-disable */
import React from 'react';
import PDF from './pdf';

class PdfViewer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scale: 1.0,
    };
  }

  onDocumentComplete = (pages) => {
    this.setState({ page: 1, pages });
  };

  handlePrevious = () => {
    this.setState({ page: this.state.page - 1 });
  };

  handleNext = () => {
    this.setState({ page: this.state.page + 1 });
  };

  zoomIn(e) {
    this.setState({
      scale: this.state.scale * 1.1,
    });
  }

  zoomOut(e) {
    this.setState({
      scale: this.state.scale / 1.1,
    });
  }

  renderPagination = (page, pages) => {
    let previousButton = (
      <li className="previous" onClick={this.handlePrevious}>
        <a href="#">
          <i className="icon-leftarrow" /> Previous
        </a>
      </li>
    );
    if (page === 1) {
      previousButton = (
        <li className="previous disabled">
          <a href="#">
            <i className="icon-leftarrow" /> Previous
          </a>
        </li>
      );
    }
    let nextButton = (
      <li className="next" onClick={this.handleNext}>
        <a href="#">
          Next <i className="icon-rightarrow" />
        </a>
      </li>
    );
    if (page === pages) {
      nextButton = (
        <li className="next disabled">
          <a href="#">
            Next <i className="icon-rightarrow" />
          </a>
        </li>
      );
    }
    return (
      <nav>
        <ul className="pager">
          {previousButton}
          {nextButton}
        </ul>
      </nav>
    );
  };

  render() {
    let pagination = null;
    if (this.state.pages) {
      pagination = this.renderPagination(this.state.page, this.state.pages);
    }
    const { scale } = this.state;
    console.log('state', scale);
    return (
      <div className="pdfWrap">
        <div className="pdfWrap__inner">
          <div className="zoom">
            <button
              className="ZoomIn icon-minus"
              onClick={(e) => this.zoomOut(e)}
            />
            <button
              className="ZoomOut icon-plus"
              onClick={(e) => this.zoomIn(e)}
            />
          </div>
          <PDF
            file={this.props.file}
            onDocumentComplete={this.onDocumentComplete}
            page={this.state.page}
            scale={scale}
          />
        </div>
        {this.state.pages > 1 ? pagination : null}
      </div>
    );
  }
}

export default PdfViewer;
