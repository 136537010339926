import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import { USER_EMAIL_SETTINGS } from 'constants/constants';
import { getInitialState } from 'components/Emails/EmailUtilities';
import styled from '@emotion/styled';
import Clear from '@mui/icons-material/Clear';
import ComposeEmailFromThread from './ComposeEmailFromThread';

const Aside = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 992;
  .Aside__Sec {
    width: 100%;
    max-width: 820px;
    height: 100vh;
    overflow-y: auto;
    background-color: #fff;
    margin-left: auto;
    position: absolute;
    right: -100%;
    transition: all ease-in-out 350ms;
    &--active {
      right: 0;
    }
  }
  .Aside__Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #ececec;
  }
  .Aside__Header__Text {
    font-size: 20px;
    color: #353c42;
    margin-bottom: 0;
  }
`;

const NewEmailModal = ({ handleNewEmailModal, setLoading }) => {
  const [connectedAppDetails, setConnectedAppDetails] = useState({});
  const [firstUpdate, setFirstUpdate] = useState(false);

  useEffect(() => {
    const request = Axios.get(USER_EMAIL_SETTINGS);
    request.then((response) => {
      if (response.status === 200) {
        setConnectedAppDetails(response.data.app_token);
      }
    });
    if (!firstUpdate) {
      setTimeout(() => {
        setFirstUpdate(!firstUpdate);
      }, 10);
    }
  }, []);

  function handleClose() {
    handleNewEmailModal(false);
  }

  function composeEmailAction() {
    // navigate('/emails');
    handleClose();
  }

  return (
    <Aside className="Aside">
      <div className={`Aside__Sec${firstUpdate ? ' Aside__Sec--active' : ''}`}>
        <div className="Aside__Header">
          <h5 className="Aside__Header__Text"> New Email </h5>
          <button
            type="button"
            className="close Button__Close"
            aria-label="Close"
            onClick={handleClose}
          >
            <Clear />
          </button>
        </div>
        <div className="Aside__form">
          {connectedAppDetails.app_key ? (
            <ComposeEmailFromThread
              setLoading={setLoading}
              initialState={getInitialState()}
              connectedAppKey={connectedAppDetails.app_key}
              connectedAppAccessToken={
                connectedAppDetails?.access_token
                  ? connectedAppDetails.access_token
                  : ''
              }
              composeEmailAction={composeEmailAction}
              isNew
            />
          ) : null}
        </div>
      </div>
    </Aside>
  );
};

export default NewEmailModal;
