import axios from 'axios';
import {
  GET_NOTES_OF_ACCOUNT,
  GET_NOTES_OF_CONTACT,
  NOTE_API_PATH,
} from 'constants/constants';

export const fetchAll = (data, page) => ({
  type: 'NOTE_SUCCESS',
  data,
  page,
});

export const fetchDetail = (data) => ({
  type: 'FETCH_NOTE_DETAIL',
  data,
});

export const fetchNotes =
  (component, notesOf, page, sort, field, noload = false, id = 421) =>
  (dispatch) => {
    const CommonEndpoint = `/${id}?page=${page}&sort_by=${sort}&field=${field}`;
    let URL = '';
    if (notesOf === 'contact') {
      URL = `${GET_NOTES_OF_CONTACT}${CommonEndpoint}`;
    } else if (notesOf === 'account') {
      URL = `${GET_NOTES_OF_ACCOUNT}${CommonEndpoint}`;
    }
    if (!noload) {
      component.setState({ loading: true });
    }
    axios.get(URL).then((response) => {
      if (response.data.status === 200) {
        component.setState({ loading: false });
        component.setState({ pagination: page - 1 });
        dispatch(fetchAll(response.data, page));
      }
    });
  };
export const fetchnoteDetail =
  (id, component, load = true) =>
  (dispatch) => {
    const request = axios.get(`${NOTE_API_PATH}/${id}`);
    component.setState({ loading: load });
    request.then((response) => {
      component.setState({ loading: false });
      const { status: _status, message } = response.data;
      if (response.data.status === 401) {
        component.setState({ unauthorized: true });
      }
      if (response.data.status === 422 && message === 'No notes found') {
        component.setState({ noNote: true });
      }
      if (
        response.status === 200 &&
        _status !== 422 &&
        response.data.status !== 401
      ) {
        const {
          account,
          account_id,
          comment,
          contact,
          contact_id,
          avatar,
          initials,
          created_by,
          created_by_avatar,
          date,
          documents,
          id: _id,
          is_active,
          reference_date,
          subject,
          status,
        } = response.data.data;
        component.setState({
          account,
          accountId: account_id,
          comment,
          contact,
          contactId: contact_id,
          contactAvatar: avatar,
          contactInitials: initials,
          createdBy: created_by,
          createdByAvatar: created_by_avatar,
          date,
          documents,
          noteId: _id,
          noteActive: is_active,
          referenceDate: reference_date,
          subject,
          noteStatus: status,
        });
        dispatch(fetchDetail(response.data.data));
      }
    });
  };
