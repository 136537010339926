import { useRef, useEffect } from 'react';

export default () => {
  const mountedRef = useRef(false);
  const isMounted = () => mountedRef.current;

  useEffect(() => {
    mountedRef.current = true;

    return () => {
      mountedRef.current = false;
    };
  }, []);

  return isMounted;
};
