import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { format } from 'date-fns';
import { Box, LinearProgress, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom-latest';
import { RequestType, WorkflowRequestStatus } from 'gql/graphql';
import { LoadingOverlay } from 'ui/Tables/Overlays/LoadingOverlay';
import { NoResultsOverlay } from 'ui/Tables/Overlays/NoResultsOverlay';
import { useWorkflowRequestsQuery } from 'hooks/workflow-request-hooks';
import { TooltipStyled } from 'ui/TooltipStyled/TooltipStyled';
import { DataGridStyled, TableHeaderStyled } from './MyRequests.styles';

export const MyRequestsTable = () => {
  const navigate = useNavigate();

  const handleRowClick = (row: any) => {
    navigate(
      `/requests/${row.id}?type=${row.requestType || RequestType.Request}`,
    );
  };

  const { data, isLoading } = useWorkflowRequestsQuery({
    statuses: [WorkflowRequestStatus.Requested, WorkflowRequestStatus.Open],
    filter: 'my_requests',
  });

  const columns: GridColDef[] = [
    {
      field: 'title',
      headerName: 'Name',
      flex: 1.5,
      sortable: false,
      renderCell: (params) => (
        <Box display="flex" gap="5px" flexDirection="column" alignItems="start">
          <Typography textOverflow="ellipsis" noWrap overflow="hidden">
            {params?.value || ''}
          </Typography>
        </Box>
      ),
    },
    {
      field: '_assignedContact',
      headerName: 'Assigned To',
      flex: 1,
      sortable: false,
      valueGetter: (params: GridValueGetterParams) =>
        `${
          params.row.assignedContact?.firstName ||
          params.row.assignedEmployee?.firstName ||
          ''
        } ${
          params.row.assignedContact?.lastName ||
          params.row.assignedEmployee?.lastName ||
          ''
        }`,
    },
    {
      field: '_assignedAccount',
      headerName: 'Account',
      sortable: false,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.assignedAccount?.name || ''}`,
      flex: 1,
    },
    {
      field: 'dueDate',
      headerName: 'Due Date',
      flex: 1,
      sortable: false,
      renderCell: (params) =>
        params.value && (
          <>{format(new Date(params.value as string), 'MM/dd/yyyy')}</>
        ),
    },
    {
      field: 'percentComplete',
      headerName: 'Progress',
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <Box display="flex" alignItems="center" width="100%">
          <Box flexGrow={1}>
            <TooltipStyled title={`${params.value}%`}>
              <LinearProgress
                value={params.value as number}
                variant="determinate"
              />
            </TooltipStyled>
          </Box>
        </Box>
      ),
    },
    {
      field: 'updatedAt',
      headerName: 'Last Activity',
      flex: 1,
      sortable: false,
      renderCell: (params) =>
        format(new Date(params.value as string), "MM/dd/yyyy hh:mm aaaaa'm'"),
    },
  ];

  return (
    <>
      <TableHeaderStyled>My Requests</TableHeaderStyled>
      <DataGridStyled
        initialState={{
          sorting: {
            sortModel: [{ field: 'dueDate', sort: 'desc' }],
          },
          pagination: {
            paginationModel: { pageSize: 100, page: 0 },
          },
        }}
        rows={data?.workflowRequests?.collection || []}
        columns={columns}
        loading={isLoading}
        onRowClick={({ row }) => {
          handleRowClick(row);
        }}
        slots={{
          loadingOverlay: LoadingOverlay,
          noResultsOverlay: () =>
            NoResultsOverlay({
              message: 'No Assigned Requests',
            }),
          noRowsOverlay: () =>
            NoResultsOverlay({
              message: 'No Assigned Requests',
            }),
        }}
        disableColumnMenu
        pageSizeOptions={[100]}
        rowHeight={42}
        columnHeaderHeight={36}
      />
    </>
  );
};
