import styled from '@emotion/styled';

import IconBase from './IconBase';

export const CustomViewIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e9c5"' },
}));

export const SyncingIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e9c0"' },
}));

export const CreateKarbanhqIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e9c1"' },
}));

export const CreateLiscioIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e9c2"' },
}));

export const UnlinkIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e9c3"' },
}));

export const AlphabetSortingIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e9bc"' },
}));

export const GoogleDriveIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e9bd"' },
}));

export const ListViewIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e9be"' },
}));

export const OneDriveIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e9bf"' },
}));

export const WarningIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e9b9"' },
}));

export const NotificationBellIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e9ba"' },
}));

export const SwitchAccountIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e9bb"' },
}));

export const FilesIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e9b8"' },
}));

export const StorageIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e9b7"' },
}));

export const MoveIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e9b6"' },
}));

export const SortIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e9b5"' },
}));

export const CSTeamIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e9b4"' },
}));

export const ComputerScreenIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e9b3"' },
}));

export const LinuxIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e9b2"' },
}));

export const FirefoxIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e9af"' },
}));

export const InternetExplorerIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e9b0"' },
}));

export const SafariIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e9b1"' },
}));

export const ChromeIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e9a6"' },
}));

export const CheckListIcon = styled(IconBase)(() => ({
  path1: {
    '&:before': {
      content: '"\\e9a7"',
      color: 'rgb(0, 0, 0)',
    },
  },
  path2: {
    '&:before': {
      content: '"\\e9a8"',
      marginLeft: '-1rem',
      color: 'rgb(255, 255, 255)',
    },
  },
}));

export const ClockIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e9a9"' },
}));

export const QuestionIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e9aa"' },
}));

export const SignOutIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e9ab"' },
}));

export const IPhoneIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e9ac"' },
}));

export const WindowsIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e9ad"' },
}));

export const AppleIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e9ae"' },
}));

export const PinnedIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e9a5"' },
}));

export const InsertLinkIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e9a3"' },
}));

export const EDocNewIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e9a4"' },
}));

export const ViewDetailsIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e9a2"' },
}));

export const AddUserIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e9a1"' },
}));

export const File01Icon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e9a0"' },
}));

export const AuditReportIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e998"' },
}));

export const CancelAgreementIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e99e"' },
}));

export const EDocIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e99f"' },
}));

export const ShareIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e99a"' },
}));

export const Message1Icon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e99b"' },
}));

export const FileUploadIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e99c"' },
}));

export const Download31Icon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e99d"' },
}));

export const DocumentIcon = styled(IconBase)(() => ({
  path1: { '&:before': { content: '"\\e994"' } },
  path2: {
    '&:before': {
      content: '"\\e995"',
      marginLeft: '-1rem',
    },
  },
  path3: {
    '&:before': {
      content: '"\\e996"',
      marginLeft: '-1rem',
    },
  },
  path4: {
    '&:before': {
      content: '"\\e997"',
      marginLeft: '-1rem',
    },
  },
}));

export const LockIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e999"' },
}));

export const UnArchiveIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e992"' },
}));

export const ArchiveLineIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e993"' },
}));

export const AlertIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e990"' },
}));

export const RecurringIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e991"' },
}));

export const PaperClicpIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e98f"' },
}));

export const SaveIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e98e"' },
}));

export const FileIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e98d"' },
}));

export const FingerPrintIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e98c"' },
}));

export const FaceIdIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e98b"' },
}));

export const WorkspaceIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e98a"' },
}));

export const LoginIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e988"' },
}));

export const SwitchUserIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e989"' },
}));

export const NoWifiIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e985"' },
}));

export const SelectedIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e986"' },
}));

export const DisabledIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e986"' },
}));

export const UnSelectedIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e987"' },
}));

export const RoundMoreIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e9c4"' },
}));

export const PenIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e984"' },
}));

export const BulkTaskIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e983"' },
}));

export const TaskUnreadIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e982"' },
}));

export const BackArrowIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e981"' },
}));

export const MultiInviteIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e97f"' },
}));

export const SignatureIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e980"' },
}));

export const InviteIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e97e"' },
}));

export const AddImageIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e97d"' },
}));

export const CameraIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e97c"' },
}));

export const StarIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e97b"' },
}));

export const ExpiredIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e97a"' },
}));

export const FilterNewIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e979"' },
}));

export const ExcelIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e973"' },
}));

export const PowerPointFileIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e974"' },
}));

export const PowerPointIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e975"' },
}));

export const TxtIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e976"' },
}));

export const WordIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e977"' },
}));

export const ZipIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e978"' },
}));

export const AdminIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e972"' },
}));

export const ReInviteIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e96b"' },
}));

export const AdminNewIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e970"' },
}));

export const ResetPasswordIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e96a"' },
}));

export const RotateToRightButtonIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e96c"' },
}));

export const RotateToLeftButtonIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e96d"' },
}));

export const CropRotateIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e96e"' },
}));

export const Crop11Icon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e96f"' },
}));

export const LogoutIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e971"' },
}));

export const UserIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e968"' },
}));

export const ImagePreviewIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e969"' },
}));

export const NotificationMessageIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e94c"' },
}));

export const FilterIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e967"' },
}));

export const MenuIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e94b"' },
}));

export const BurgerIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e932"' },
}));

export const NewMessageIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e927"' },
}));

export const UploadFileIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e928"' },
}));

export const HelpIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e900"' },
}));

export const FileTypePdfIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e906"' },
}));

export const CloseEyeIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e908"' },
}));

export const ChangeAccountIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e915"' },
}));

export const RightArrow2Icon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e916"' },
}));

export const LeftArrow2Icon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e917"' },
}));

export const UpArrow2Icon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e918"' },
}));

export const DownArrow2Icon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e919"' },
}));

export const CloneIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e91a"' },
}));

export const NoteThinIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e91b"' },
}));

export const ContactThinIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e91c"' },
}));

export const EditIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e91d"' },
}));

export const Close2Icon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e91e"' },
}));

export const Account2Icon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e91f"' },
}));

export const PrintIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e920"' },
}));

export const DeleteSmallIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e921"' },
}));

export const TagIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e922"' },
}));

export const ArchiveIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e923"' },
}));

export const ExpandIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e924"' },
}));

export const CollapseIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e925"' },
}));

export const CloseIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e926"' },
}));

export const RightArrow22Icon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e929"' },
}));

export const LeftArrow22Icon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e92a"' },
}));

export const UpArrow22Icon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e92b"' },
}));

export const DownArrow2Icon2 = styled(IconBase)(() => ({
  '&:before': { content: '"\\e92c"' },
}));

export const DownloadIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e92d"' },
}));

export const PlusIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e92e"' },
}));

export const MinusIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e92f"' },
}));

export const CheckboxDefaultIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e930"' },
}));

export const CheckboxCheckedIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e931"' },
}));

export const TickMarkIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e933"' },
}));

export const UpArrowIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e934"' },
}));

export const DownArrowIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e935"' },
}));

export const RightArrowIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e936"' },
}));

export const LeftArrowIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e937"' },
}));

export const DoubleArrowIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e938"' },
}));

export const UpArrow1Icon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e939"' },
}));

export const DownArrow1Icon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e93a"' },
}));

export const RightArrow1Icon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e93b"' },
}));

export const LeftArrow1Icon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e93c"' },
}));

export const Zoom2Icon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e93d"' },
}));

export const ZoomIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e93e"' },
}));

export const UploadIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e93f"' },
}));

export const OpenEyeIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e940"' },
}));

export const NotesIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e941"' },
}));

export const Message2Icon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e942"' },
}));

export const HistoryIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e943"' },
}));

export const CreateNewIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e944"' },
}));

export const CancelIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e945"' },
}));

export const CalloutSmallIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e946"' },
}));

export const CalendarIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e947"' },
}));

export const BellIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e948"' },
}));

export const AttachmentIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e949"' },
}));

export const MessageIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e94a"' },
}));

export const TickMarkTransOIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e901"' },
}));

export const TickMarkOIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e902"' },
}));

export const File1Icon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e903"' },
}));

export const AccountsIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e904"' },
}));

export const MoreIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e905"' },
}));

export const ContactsIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e907"' },
}));

export const HomeIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e909"' },
}));

export const TasksIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e90a"' },
}));

export const Download1Icon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e865"' },
}));

export const Upload2Icon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e866"' },
}));

export const ChevronUp3Icon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e873"' },
}));

export const ChevronDown3Icon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e874"' },
}));

export const ChevronLeft2Icon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e875"' },
}));

export const ChevronRight3Icon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e876"' },
}));

export const FlashOffIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e3e6"' },
}));

export const FlashOnIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e3e7"' },
}));

export const FontDownloadIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e167"' },
}));

export const FormatAlignCenterIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e234"' },
}));

export const FormatAlignJustifyIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e235"' },
}));

export const FormatAlignLeftIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e236"' },
}));

export const FormatAlignRightIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e237"' },
}));

export const FormatBoldIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e238"' },
}));

export const FormatClearIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e239"' },
}));

export const FormatColorFillIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e23a"' },
}));

export const FormatColorTextIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e23c"' },
}));

export const FormatIndentDecreaseIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e23d"' },
}));

export const FormatIndentIncreaseIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e23e"' },
}));

export const FromatItalicIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e23f"' },
}));

export const FormatLineSpacingIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e240"' },
}));

export const FormatListBulletedIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e241"' },
}));

export const FormatListNumberedIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e242"' },
}));

export const FormatQuoteIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e244"' },
}));

export const FormatSizeIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e245"' },
}));

export const FormatStrikeThroughIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e246"' },
}));

export const FormatTextDirectionLToRIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e247"' },
}));

export const FormatTextDirectionRToLIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e248"' },
}));

export const FormatUnderlinedIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e249"' },
}));

export const Trash1Icon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e94d"' },
}));

export const Upload3Icon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e94e"' },
}));

export const Upload4Icon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e94f"' },
}));

export const Download3Icon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e950"' },
}));

export const Download4Icon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e951"' },
}));

export const SquareChevronLeftIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e952"' },
}));

export const SquareChevronRightIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e953"' },
}));

export const SquareChevronUpIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e954"' },
}));

export const SquareChevronDownIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e955"' },
}));

export const SquareArrowLeftIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e956"' },
}));

export const SquareArrowRightIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e957"' },
}));

export const SquareArrowUpIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e958"' },
}));

export const SquareArrowDownIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e959"' },
}));

export const SquarePlusIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e95a"' },
}));

export const SquareMinusIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e95b"' },
}));

export const SquareCancelIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e95c"' },
}));

export const SquareCheckMarkIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e95d"' },
}));

export const CirclePlusIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e95e"' },
}));

export const CircleMinusIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e95f"' },
}));

export const CircleCancelIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e960"' },
}));

export const CircleCheckMarkIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e961"' },
}));

export const MagnifierIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e962"' },
}));

export const DeleteIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e963"' },
}));

export const LabelIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e964"' },
}));

export const Undo2Icon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e965"' },
}));

export const UndoIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e90b"' },
}));

export const RedoIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e90c"' },
}));

export const Redo2Icon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e90d"' },
}));

export const ChevronLeft3Icon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e966"' },
}));

export const ChevronLeftIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e90e"' },
}));

export const ChevronRightIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e90f"' },
}));

export const ChevronRight2Icon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e910"' },
}));

export const ChevronUpIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e911"' },
}));

export const ChevronUp2Icon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e912"' },
}));

export const ChevronDownIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e913"' },
}));

export const ChevronDown2Icon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e914"' },
}));

export const ClientServiceIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e9b4"' },
}));

export const CreateInQuickbookIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e9c7"' },
}));

export const DropDownArrowIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e938"' },
}));

export const BillingIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e9c9"' },
}));

export const EmailIcon = styled(IconBase)(() => ({
  '&:before': {
    content: '"\\e9c6"',
    fontSize: '1.1rem',
  },
}));

export const RightChevronArrowIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e90f"' },
}));

export const LeftChevronArrowIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e966"' },
}));

export const CloseNewIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e91e"', fontSize: '1.1rem' },
}));

export const SMSIcon = styled(IconBase)(() => ({
  '&:before': { content: '"\\e9ca"', fontSize: '1.1rem' },
}));

export default IconBase;
