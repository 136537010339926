/* eslint-disable default-param-last */
/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';
import { AlertMessage, isUserEmployee } from 'utilities/utils';
import {
  USER_VIEWS,
  GET_EMP_AND_CONTACT_API_PATH,
  GET_TASK_ACCOUNTS,
  GET_ACCOUNTS_OF_CONTACT_API_PATH,
  GET_CONTACTS_OF_ACCOUNT_API_PATH,
  CONTACT,
  ACCOUNT,
  GET_COLLEAGUE,
  GET_ACCOUNTS_API_PATH,
  GET_ACCOUNTS_FOR_LOGINED_CONTACT,
  UPDATE_TASK_API_PATH,
  GET_OWNERS,
  GET_CONT_EMP_OF_ACCOUNT_API_PATH,
  DOCUMENT_API_PATH,
  SEARCH_TASK_CONTACTS,
  SEARCH_TASK_ACCOUNTS,
  UPDATE_TODO_TASK,
  TASK_API_PATH,
  ADD_COMMENT_API_PATH,
  CHANGE_ASSIGNEE,
  CHANGE_OWNER_API_PATH,
  UPDATE_TASK_FIELD_API_PATH,
  UPDATE_TASK_STATUS_API_PATH,
  CANCEL_NOTES_DOCUMENTS,
  REOPEN_TASK_API_PATH,
  GET_BULK_TEMPLATE_AND_REPLACE_VARS_API_PATH,
  GET_ALL_MESSAGES_VIEW,
  GET_REL_CONTACTS_AND_CST_OF_ACCOUNTS,
  GET_TO_DO_TASK_DATA,
  CANCEL_TASKS_DOCUMENTS,
  SIGN_DOC_API_PATH,
  GET_NEW_CONTACTS,
  GET_TEMPLATE_AND_REPLACE_VARS_API_PATH,
  GET_ENGAGEMENT_LETTER_API_PATH,
  GET_STAFF_PERSON_API_PATH,
  MIXPANEL_TASK_EVENT,
  GET_EMPLOYEES_API_PATH,
} from 'constants/constants';
import { trackMixpanelEvent } from 'utilities/mixpanelfn';

const noneValue = {
  key: 'none',
  id: 'none',
  value: 'none',
  label: 'None',
};

// group employees and contacts into their own buckets
export const groupEmpAndContacts = (allContacts) => {
  if (allContacts && allContacts.length > 0) {
    const groupedEmpAndContacts = allContacts.reduce(
      (acc, item) => {
        if (item.type === 'emp') {
          acc.employeesArray.push(item);
        } else {
          acc.contactsArray.push(item);
        }

        return acc;
      },
      { employeesArray: [], contactsArray: [] },
    );

    const groupedOptions = isUserEmployee()
      ? [
          { label: 'Contacts', options: groupedEmpAndContacts.contactsArray },
          { label: 'Employees', options: groupedEmpAndContacts.employeesArray },
        ]
      : [
          { label: 'Employees', options: groupedEmpAndContacts.employeesArray },
          { label: 'Contacts', options: groupedEmpAndContacts.contactsArray },
        ];

    return groupedOptions;
  }

  return [];
};

export const getContactsOfTask = (component, mydata = {}, source = 'tasks') => {
  const request = axios.post(GET_EMP_AND_CONTACT_API_PATH, mydata);
  request
    .then((response) => {
      if (response.status === 200) {
        if (source === 'notes') {
          if (
            component.props?.data?.viewContext === 'message' &&
            component.props?.data?.taskTypeKey !== 'to_do' &&
            component.props?.taskDetail?.account_data.value
          ) {
            component.setState({
              allContacts: response.data,
            });
          } else {
            const groupedOptions = groupEmpAndContacts(response.data);
            component.setState({
              allContacts: response.data,
              contacts: response.data,
              groupedOptions,
              isLoading: false,
            });
          }
          if (component.props?.taskDetail?.length) {
            component.setState({
              ref_contacts: response.data,
              allRefContacts: response.data,
            });
          } else if (
            !component.props.taskDetail?.account_data?.label &&
            source !== 'notes'
          ) {
            component.setState({
              ref_contacts: response.data,
              allRefContacts: response.data,
            });
          }
        } else if (source === 'fileslist') {
          const contactsList = response.data;
          contactsList.unshift(noneValue);
          component.setState({
            allContacts: contactsList,
            contacts: contactsList,
          });
        } else if (source === 'filter') {
          component.setState({
            allContacts: response.data,
            initialContacts: response.data,
            groupedOptions: groupEmpAndContacts(response.data),
          });
        } else if (source === 'uploadsPopUp') {
          component.setState({ allContacts: response.data });
        } else if (source === 'edoc') {
          component.setState({
            allContacts: response.data,
            contacts: response.data,
            groupedOptions: groupEmpAndContacts(response.data),
          });
        } else {
          component.setState({
            allContacts: response.data,
            groupedOptions: groupEmpAndContacts(response.data),
          });
        }
      }
    })
    .catch((error) => {
      console.log('Error - ', error);
      component.setState({ loading: false });
    });
};

export const getAccounts = (component, source = 'tasks', id = '') => {
  // id is used to get selected account also in list with other response data
  const url = GET_TASK_ACCOUNTS;
  const request = id ? axios.get(`${url}/${id}`) : axios.get(url);
  request
    .then((response) => {
      if (source === 'notes') {
        component.setState({
          allRefAccounts: response.data.accounts,
          ref_accounts: response.data.accounts,
          allAccounts: response.data.accounts,
          accounts: response.data.accounts,
          accountDropdownMessage: 'No Records Found',
          gettingAccounts: false,
        });
      } else if (source === 'fileslist') {
        const accountsList = response.data.accounts;
        accountsList.unshift(noneValue);
        component.setState({
          allAccounts: accountsList,
          accounts: accountsList,
        });
      } else if (source === 'filter') {
        component.setState({
          accounts: response.data.accounts,
          initialAccounts: response.data.accounts,
        });
      } else if (source === 'uploadsPopUp') {
        component.setState({ allAccounts: response.data.accounts });
      } else {
        component.setState({ allAccounts: response.data });
      }
    })
    .catch(() => {
      component.setState({
        loading: false,
        accountDropdownMessage: 'No Records Found',
        gettingAccounts: false,
      });
    });
};

export const AccountsOfContact = (component, id, fro = '') => {
  const request = axios.get(`${GET_ACCOUNTS_OF_CONTACT_API_PATH}/${id}`);
  request
    .then((response) => {
      if (response.status === 200) {
        if (fro === 'commonfields') {
          if (!response.data.data.length) {
            component.props.removeparticularTaskType('get_signature');
          }
          component.setState(
            { accounts: response.data.data, allAccounts: response.data.data },
            () => {
              component.props.stateChange(component.state);
              if (
                response.data.data &&
                response.data.data.length &&
                response.data.data.length === 1
              ) {
                component.handleAccountChange(response.data.data[0]);
              }
            },
          );
        } else if (
          fro === 'filepopup' ||
          fro === 'messagePopUp' ||
          fro === 'uploadfile'
        ) {
          component.setState({ accounts: response.data.data });
          if (fro === 'uploadfile' && response.data.data.length === 0) {
            getAccounts(component, 'notes');
          }
        } else if (fro === 'clientmessage' && response.data.data.length === 1) {
          const accounts = response.data.data;
          component.setState({
            accountId: accounts[0].value,
            accountName: accounts[0].label,
          });
        } else if (fro === 'to_do') {
          const accountsAll = response.data.data;
          component.setState(
            {
              ref_accounts: accountsAll,
              allRefAccounts: accountsAll,
            },
            () => {
              component.props.stateChange(component.state);
            },
          );
        }
        component.setState({
          allAccounts: response.data.data,
          accounts: response.data.data,
        });
      }
    })
    .catch(() => {});
};

export const ContactsOfAccount = (component, id, fro = '') => {
  const request = axios.get(`${GET_CONTACTS_OF_ACCOUNT_API_PATH}/${id}`);
  request
    .then((response) => {
      if (response.status === 200) {
        if (fro === 'commonfields') {
          // don't show contact if primary email is not available
          const result = _.filter(
            response.data.data,
            (value) => value.email !== 'n/a' && value.email !== '',
          );
          component.setState({ contacts: result, allContacts: result }, () => {
            component.props.stateChange(component.state);
            if (result && result.length && result.length === 1) {
              component.handleContactChange(result[0]);
            }
          });
        } else if (fro === 'to_do') {
          // don't show contact if primary email is not available
          const result = _.filter(
            response.data.data,
            (value) => value.email !== 'n/a' && value.email !== '',
          );
          component.setState(
            { allRefContacts: result, ref_contacts: result },
            () => {
              component.props.stateChange(component.state);
              /* we autopopulate contact if account have relationship
               with only one contact */
              if (result?.length === 1) {
                component.handleRefContactChange(result[0]);
              }
            },
          );
        } else if (fro === 'filepopup') {
          component.setState({ contacts: response.data.data });
        } else if (fro === 'uploadfile') {
          component.setState({
            contacts: response.data.data,
            allContacts: response.data.data,
          });
        } else if (fro === 'messages') {
          component.setState({
            contacts: response.data.data,
          });
          if (response.data.data.length === 1) {
            const { label, value } = response.data.data[0];
            component.setState({
              contactName: label,
              contactId: value,
            });
          }
        } else {
          component.setState({ allContacts: response.data.data });
        }
      }
    })
    .catch(() => {});
};

export const getContactsOfAccount = (component, mydata = {}) => {
  component.setState({ load: true, loading: true });
  const request = axios.get(
    `${GET_CONTACTS_OF_ACCOUNT_API_PATH}/${mydata.accountId}`,
  );
  request
    .then((response) => {
      component.setState({ load: false });
      if (response.status === 200) {
        const result = _.filter(
          response.data.data,
          (value) => value.email !== 'n/a' && value.email !== '',
        );
        component.setState({ contacts: result });
        const eleArray = result.map((item) => item.id);
        if (eleArray.indexOf(component.state.userId) === -1) {
          component.setState({
            contactId: '',
            userId: '',
            contactAvatar: '',
            contactName: '',
          });
        }
        if (result && result.length && result.length === 1) {
          component.handleContactChange(result[0]);
        }
      }
    })
    .catch(() => {
      component.setState({ load: false, loading: false });
    });
};

export const getEmployeeDetails = (component, id) => {
  component.setState({ modalDataLoading: true });
  const request = axios.get(`${GET_EMPLOYEES_API_PATH}/${id}`);
  request.then((response) => {
    if (response.status === 200) {
      component.setState({
        employeeDetails: response.data.data[0],
        modalDataLoading: false,
      });
    }
  });
};

export const getContactDetails = (component, id, fro = '') => {
  component.setState({ modalDataLoading: true });
  const request = axios.get(`${CONTACT}/${id}`);
  request.then((response) => {
    if (response.status === 200) {
      component.setState({
        contactDetails: response.data.data[0],
        modalDataLoading: false,
      });
      if (fro === 'message') {
        const contactDetails = response.data.data[0];
        component.setState(
          {
            contactId:
              contactDetails.contact && contactDetails.contact.cpa_user_id
                ? contactDetails.contact.cpa_user_id
                : contactDetails.contact.id,

            contactName: `${contactDetails.contact.first_name} ${contactDetails.contact.last_name}`,
            assigneeType: contactDetails.contact.cpa_user_id
              ? 'user'
              : 'contact',
            cpaId: contactDetails.contact.cpa_id,
          },
          () => {
            const isInitialLoad = true;

            component.addSelectedInput(isInitialLoad);
          },
        );
      }
      if (fro === 'commonfields') {
        const contactDetails = response.data.data[0];
        component.setState(
          {
            cpaId: contactDetails.contact.cpa_id,
            contactId: contactDetails.contact.id,
            userId: contactDetails.contact.id,
            contactName: `${contactDetails.contact.first_name} ${contactDetails.contact.last_name}`,
            contacts: [
              {
                label: `${contactDetails.contact.first_name} ${contactDetails.contact.last_name}`,
                value: contactDetails.contact.id,
                avatar: contactDetails.avatar,
              },
            ],
            clientTitle: contactDetails.contact.job_title,
          },
          () => {
            component.props.stateChange(component.state);
          },
        );
      }
    }
  });
};

export const getAccountDetails = (component, id, fro = '') => {
  component.setState({ loading: true });
  const request = axios.get(`${ACCOUNT}/${id}`);
  request.then((response) => {
    component.setState({ loading: false });
    if (response.status === 200) {
      component.setState({ accountDetails: response.data.data });
      if (fro === 'commonfields') {
        const accountDetails = response.data.data;
        component.setState(
          {
            accountId: accountDetails.account.id,
            accounts: [
              {
                label: accountDetails.account.name,
                value: accountDetails.account.id,
              },
            ],
            accountName: accountDetails.account.name,
          },
          () => {
            component.props.stateChange(component.state);
          },
        );
      }
    }
  });
};

export const getColleague = (component) => {
  const request = axios.get(GET_COLLEAGUE);
  request
    .then((response) => {
      component.setState({ colleagues: response.data.data });
    })
    .catch(() => {});
};

export const getCurrentCpaAccounts = (component) => {
  const request = axios.get(GET_ACCOUNTS_API_PATH);
  request
    .then((response) => {
      if (response.data.status === 200) {
        component.setState({ accounts: response.data.data });
      }
    })
    .catch((error) => {
      console.log(error);
    });
};
// get accounts of contact based on id
export const getAccountsOfContact = (component, fro = '') => {
  const request = axios.get(GET_ACCOUNTS_FOR_LOGINED_CONTACT);
  request
    .then((response) => {
      if (response.data.status === 200) {
        component.setState({ accounts: response.data.data });
        if (fro === 'filter') {
          component.setState({ initialAccounts: response.data.data });
        }
        if (fro === 'message') {
          component.setState({ allAccounts: response.data.data });
        }
      }
    })
    .catch(() => {});
};

export const getOwners = (component, fro = '') => {
  const request = axios.get(GET_OWNERS);
  request
    .then((response) => {
      if (response.data.status === 200) {
        if (fro === 'clientfilepopup') {
          component.setState({
            allContacts: response.data.data,
            contacts: response.data.data,
          });
        } else {
          component.setState({ owners: response.data.data });
        }
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

// get contacts and employees related to selected account
export const getContEmpOfAccount = (
  component,
  mydata = {},
  source = '',
  fromMessageModal,
) => {
  if (fromMessageModal) component.setState({ modalDataLoading: true });
  const request = axios.get(
    `${GET_CONT_EMP_OF_ACCOUNT_API_PATH}/${mydata.accountId}`,
  );
  request
    .then((response) => {
      if (response.data && response.data && response.data.data) {
        // destructure the state from the related component
        const { state } = component;
        component.setState({ modalDataLoading: false });
        let { data } = response.data;

        const groupedOptions = groupEmpAndContacts(data);
        // get the contacts for selected account
        const singleContact = groupedOptions.filter(
          (option) => option.label === 'Contacts',
        );

        if (source === 'edoc') {
          data = response.data.data.filter(
            (e) =>
              e.status.toLowerCase() === 'active' ||
              e.status.toLowerCase() === 'activated',
          );
        }

        if (source === 'account') {
          if (singleContact[0].options.length === 1) {
            component.autopopulateToField(singleContact[0].options[0], mydata);
          }
        }

        component.setState({ isLoading: false });
        if (!state.selectedContact && !fromMessageModal) {
          if (singleContact[0].options.length === 1) {
            component.handleContactChange(singleContact[0].options[0]);
          } else {
            component.handleContactChange(null);
          }
        }

        component.setState({ contacts: data, groupedOptions, loading: false });
      }
    })
    .catch(() => {});
};

export const searchTaskAccounts = (component, mydata = {}) => {
  const request = axios.get(
    `${SEARCH_TASK_ACCOUNTS}/?keyword=${mydata.val}&task_type_key=${mydata.task_type_id}`,
  );
  component.setState({ loading: true });
  request
    .then((response) => {
      component.setState({ loading: false });
      component.setState({ accounts: response.data });
    })
    .catch(() => {
      component.setState({ loading: false });
    });
};

export const searchContactsOfTask = (component, mydata = {}) => {
  const request = axios.get(
    `${SEARCH_TASK_CONTACTS}/?keyword=${mydata.val}&task_type_key=${mydata.task_type_id}`,
  );
  request
    .then((response) => {
      if (response.status === 200) {
        component.setState({ contacts: response.data });
      }
    })
    .catch(() => {
      component.setState({ loading: false });
    });
};

export const createTask = (component, data, id = null, type = '') => {
  const { location } = component.props;
  component.setState({ loading: true });
  let request;
  if (id && type !== 'to_do') {
    request = axios.put(`${UPDATE_TASK_API_PATH}/${id}`, data);
  } else if (id && type === 'to_do') {
    request = axios.put(`${UPDATE_TODO_TASK}/${id}`, data);
  } else {
    request = axios.post(TASK_API_PATH, data);
  }
  // const request = axios.post(Config.TASK_API_PATH, data);

  const from_email =
    component.props.data.viewContext &&
    component.props.data.viewContext === 'email';
  request
    .then((response) => {
      if (response.data.status === 200) {
        // component.props.actions.createTask(response.data.data);
        trackMixpanelEvent(MIXPANEL_TASK_EVENT, {
          state: id ? 'edited' : 'created',
          taskType: id ? type : data.task_type_key,
          from_email,
        });
        AlertMessage('success', response.data.message, 3000);
        component.setState({
          documents: [],
          showUnsavedChangesAlert: false,
          loading: false,
        });
        if (
          component.props.data.viewContext ||
          component.props.match.path === '/contactdetails/task/:id' ||
          component.props.match.path === '/accountdetails/task/:id'
        ) {
          component.props.goBack(true);
        } else if (
          location.pathname.includes('/accountdetails/task') ||
          location.pathname.includes('/contactdetails/task') ||
          location.state.from.includes('accountdetails') ||
          location.state.from.includes('contactdetails')
        ) {
          const { state } = location;
          if (state.page === 'inFocus') {
            component.props.navigate(location.state.from);
          } else if (state.page === 'account_tasks') {
            component.props.navigate(`/accountdetails/${state.id}/tasks`);
          } else if (state.page === 'contact_tasks') {
            component.props.navigate(`/contactdetails/${state.id}#tasks`);
          } else {
            component.props.navigate(-1);
          }
        } else if (
          location.state?.accountID &&
          location.state?.from === 'inFocus'
        ) {
          const { accountID, from } = location.state;
          component.props.navigate(`/accountdetails/${accountID}/${from}`);
        } else if (
          location.state.page === 'dashboard' ||
          location.state.from === '/'
        ) {
          component.props.navigate('/');
        } else if (location.state.page === 'sidebar') {
          component.props.navigate(-1);
        } else if (location.state.page === 'all_tasks') {
          component.props.navigate('/all_tasks');
        } else {
          component.props.navigate('/all_tasks');
        }
      } else {
        trackMixpanelEvent(MIXPANEL_TASK_EVENT, {
          state: 'error',
          taskType: id ? type : data.task_type_key,
          from_email,
        });
        component.setState({ loading: false });
        // AlertMessage('error', response.data.message, 3000);
        component.nextPage();
      }
    })
    .catch(() => {
      component.setState({ loading: false });
      // AlertMessage('error', error.response.data.message, 3000);
      console.log('Some error occurred. Please try later.');
      return null;
    });
};
export const addDocument = (
  mydata,
  component,
  config,
  filename,
  page = '',
  thirdpartyapp = null,
) => {
  let request = null;
  const thirdparty = `?third_party_app=${thirdpartyapp}`
  request = axios.post(DOCUMENT_API_PATH + thirdparty, mydata, config);
  request
    .then((response) => {
      component.setState({ loading: false });
      const index = component.state.uploadFiles.indexOf(filename);
      const files = component.state.uploadFiles;
      files.splice(index, 1);
      component.setState({ uploadFiles: files });
      if (response.data.status === 200) {
        const newDocuments = component.state.documents;
        const newDocumnts = component.state.newDocs;
        newDocuments.unshift(response.data.data);
        newDocumnts.unshift(response.data.data);
        component.setState({ documents: newDocuments, newDocs: newDocumnts });
        if (page === 'taskDetail') {
          const tmpHash = {};
          const hash = component.state.commentIdHash;
          tmpHash.attachment_id = response.data.data.id;

          hash.push(tmpHash);
          component.setState({ commentIdHash: hash });
        } else if (page === 'Draft') {
          component.saveMessage('Draft');
        }
      } else if (response.data.message) {
        AlertMessage('error', response.data.message, 8000);
      } else {
        AlertMessage('error', 'Some error occurred. Please try later.', 3000);
      }
      component.state.uploadedFilesCount += 1;
      if (
        component.state.uploadedFilesCount ===
        component.state.uploadedFilesLength
      ) {
        // COUNT = 0;
        component.setState({ isDocumentUpload: false });
        if (component.updateAutoTagData) {
          component.updateAutoTagData();
        }
      }
    })
    .catch((error) => {
      component.state.uploadedFilesCount += 1;
      if (
        component.state.uploadedFilesCount ===
        component.state.uploadedFilesLength
      ) {
        component.setState({ isDocumentUpload: false });
        // COUNT = 0;
      }
      const files = component.state.uploadFiles;
      const index = component.state.uploadFiles.indexOf(filename);
      files.splice(index, 1);
      component.setState({ uploadFiles: files, loading: false });
      if (axios.isCancel(error)) {
        console.log('in cancel');
      }
    });
};

// don't remove commentId from parameters
export const deleteTaskAttachment = (
  id,
  component,
  // eslint-disable-next-line no-unused-vars
  commentId = '',
  type = '',
  addedUrl = '',
) => {
  component.setState({ loading: true });
  const url = `${DOCUMENT_API_PATH}/${id}${addedUrl}`;
  const request = axios.delete(url);
  request
    .then((response) => {
      component.setState({ loading: false });
      if (response.data.status === 200) {
        const documnts = [];
        component.state.documents.map((doc) => {
          if (addedUrl && addedUrl.indexOf('message') < 0) {
            // message na ho
            if (doc.id === id) {
              doc.is_active = false;
            }
            documnts.push(doc);
          } else if (
            !addedUrl ||
            (addedUrl && addedUrl.indexOf('message') > -1)
          ) {
            if (doc.id !== id) {
              documnts.push(doc);
            }
          }
          return documnts;
        });
        if (component.state.newDocs) {
          const indx = component.state.newDocs.findIndex((y) => y.id === id);
          if (indx !== -1) {
            const newDocumnts = component.state.newDocs;
            newDocumnts.splice(indx, 1);
            component.setState({ newDocs: newDocumnts });
          }
        }
        const array = component.state.uploadFilesIds;
        if (array !== undefined) {
          const index = array.indexOf(id);
          if (index > -1) {
            array.splice(index, 1);
            component.setState({ uploadFilesIds: array });
          }
        }
        component.setState(
          { documents: documnts, previewScreen: false },
          () => {
            if (component.props.updateDocumentState) {
              component.props.updateDocumentState(documnts);
            }
          },
        );
        if (type === 'Draft') {
          component.saveMessage('Draft');
        }
        AlertMessage('success', response.data.message, 8000);
      } else {
        AlertMessage('error', response.data.message, 8000);
      }
    })
    .catch(() => {
      component.setState({ loading: false });

      AlertMessage('error', 'Some error occurred. Please try later.', 3000);
      return null;
    });
};

export const addComment = (component, data, fro = '') => {
  const request = axios.post(ADD_COMMENT_API_PATH, data);
  component.setState({ loading: true });
  request
    .then((response) => {
      component.setState({ loading: false });
      if (response.status === 200) {
        if (fro === 'taskdetail') {
          component.setState({ showEditor: false });
        }
        component.setState({ addComments: '' });
        component.props.updateTaskDetail(response.data.data[0]);
      }
    })
    .catch(() => {
      component.setState({ loading: false });
    });
};

export const updateOwner = (component, data) => {
  component.setState({ loading: true });
  const request = axios.post(CHANGE_OWNER_API_PATH, data);
  request
    .then((response) => {
      component.setState({ loading: false });
      if (response.status === 200) {
        AlertMessage('success', response.data.message, 3000);
      }
    })
    .catch(() => {
      component.setState({ loading: false });
      // console.log(error);
    });
};

export const updateTaskField = (component, data, taskId) => {
  component.setState({ loading: true });
  const request = axios.put(`${UPDATE_TASK_FIELD_API_PATH}/${taskId}`, data);
  request
    .then((response) => {
      component.setState({ loading: false });
      if (response.status === 200) {
        AlertMessage('success', response.data.message, 3000);
      }
    })
    .catch(() => {
      component.setState({ loading: false });
      // console.log(error);
    });
};

export const changeAssignee = (component, mydata) => {
  const request = axios.post(CHANGE_ASSIGNEE, mydata);
  request.then((response) => {
    if (response.status === 200) {
      AlertMessage('success', 'Task Reassigned Successfully.', 3000);
    } else {
      AlertMessage('error', response.data.message, 3000);
    }
  });
};

export const deleteTaskEditAttachment = (
  id,
  component,
  taskId,
  commentId = '',
) => {
  component.setState({ load: true });
  let url = '';
  url = `${DOCUMENT_API_PATH}/${id}?task_id=${taskId}&comment_id=${commentId}&doc_type=task`;
  const request = axios.delete(url);
  request
    .then((response) => {
      component.setState({ load: false });
      if (response.data.status === 200) {
        const documnts = [];
        component.state.documents.map((doc) => {
          if (doc.id === id) {
            doc.is_active = false;
          }
          documnts.push(doc);
          return documnts;
        });
        component.setState({ documents: documnts });
        const commntActivity = component.state.commentsActivity;
        const commntHistory = component.state.commentsHistory;
        if (commentId === '') {
          commntActivity.unshift(response.data.comment_hash);
          commntHistory.unshift(response.data.comment_hash);
        }
        if (commentId) {
          component.state.commentsActivity.map((com) => {
            if (commentId === com.id) {
              const index = commntActivity.indexOf(com);
              commntActivity.splice(index, 1);
            }
            return commntActivity;
          });
          component.state.commentsHistory.map((com) => {
            if (commentId === com.id) {
              const index = commntHistory.indexOf(com);
              commntHistory.splice(index, 1);
            }
            return commntHistory;
          });
        }
        if (component.state.newDocs.length > 0) {
          const indx = component.state.newDocs.findIndex((y) => y.id === id);
          if (indx !== -1) {
            const uploadedFiles = component.state.newDocs;
            uploadedFiles.splice(indx, 1);
            component.setState({ newDocs: uploadedFiles });
          }
        }
        component.setState(
          {
            commentsActivity: commntActivity,
            commentsHistory: commntHistory,
            previewScreen: false,
            previewDocument: null,
          },
          () => {
            AlertMessage('success', response.data.message, 8000);
          },
        );
      } else {
        AlertMessage('error', response.data.message, 8000);
      }
    })
    .catch(() => {
      component.setState({ load: false });
      AlertMessage('error', 'Some error occurred. Please try later.', 3000);
      return null;
    });
};

export const markComplete = (component, data, markButton = false) => {
  component.setState({ load: true });
  const request = axios.post(UPDATE_TASK_STATUS_API_PATH, data);
  request
    .then((response) => {
      component.setState({ load: false });
      if (response.data.status === 200) {
        component.updateTaskDetail(response.data.data[0]);
        AlertMessage('success', response.data.message, 3000);
        if (markButton) {
          component.setState({ confirmationModal: false, markDiv: false });
        }
        const { task_page, tasks, taskStatus } = component.props;
        if (task_page > 1) {
          const total = tasks[`${taskStatus}_total`];
          if (total % 15 === 1) {
            component.props.pagination.taskPagination(task_page - 1);
          }
        }
        component.props.navigate(-1);
      } else {
        AlertMessage('error', response.data.message, 3000);
      }
    })
    .catch(() => {
      component.setState({ load: false });
    });
};

export const reOpen = (component, tId) => {
  component.setState({ load: true });
  const request = axios.put(`${REOPEN_TASK_API_PATH}/${tId}`);
  request
    .then((response) => {
      component.setState({ load: false });
      if (response.data.status === 200) {
        component.updateTaskDetail(response.data.data[0]);
        const { task_page, tasks, taskStatus } = component.props;
        if (task_page > 1) {
          const total = tasks[`${taskStatus}_total`];
          if (total % 15 === 1) {
            component.props.pagination.taskPagination(task_page - 1);
          }
        }
        AlertMessage('success', response.data.message, 3000);
      } else {
        AlertMessage('error', response.data.message, 3000);
      }
    })
    .catch(() => {
      component.setState({ load: false });
    });
};

export const getStafPerson = (component) => {
  const request = axios.get(GET_STAFF_PERSON_API_PATH);
  request.then((response) => {
    if (response.status === 200) {
      component.setState({ stafPerson: response.data.data });
    }
  });
};

export const getEngagementLetters = (component, type = '') => {
  component.setState({ loading: true });
  const request = axios.get(
    `${GET_ENGAGEMENT_LETTER_API_PATH}/?template_type=${type}`,
  );
  request.then((response) => {
    component.setState({ loading: false });
    if (response.status === 200) {
      component.setState({ templates: response.data.data });
    }
  });
};

export const getTemplateAndReplaceVars = (component, data) => {
  component.setState({ loading: true });
  const request = axios.post(GET_TEMPLATE_AND_REPLACE_VARS_API_PATH, data);
  request.then((response) => {
    component.setState({ loading: false });
    if (response.data.status === 200) {
      component.setState({
        content: response.data.data,
        contentAfterChange: response.data.data,
      });
    }
  });
};

export const getBulkTemplateAndReplaceVars = (component, data) => {
  component.setState({ loading: true });
  const request = axios.post(GET_BULK_TEMPLATE_AND_REPLACE_VARS_API_PATH, data);
  request.then((response) => {
    component.setState({ loading: false });
    if (response.data.status === 200) {
      component.setState({ content: response.data.data });
      if (component.props.data.showSignatureModal) {
        component.props.showSignatureModal(
          true,
          true,
          response.data.data,
          component.state.doctype,
        );
      } else {
        component.props.showSignatureModal(
          false,
          false,
          response.data.data,
          component.state.doctype,
        );
      }
    }
  });
};

export const signDocument = (component, data) => {
  component.setState({ loading: true });
  const request = axios.post(SIGN_DOC_API_PATH, data);
  request.then((response) => {
    component.setState({ loading: false });
    if (response.data.status === 200) {
      if (component.props.name === 'taskdetail') {
        component.props.updateTaskDetail(response.data.data[0]);
      }
      AlertMessage('success', response.data.message, 3000);
    }
  });
};

export const cancelDocuments = (component, data, type = '') => {
  let request;
  if (type === 'tasks') {
    request = axios.post(CANCEL_TASKS_DOCUMENTS, data);
  } else if (type === 'notes') {
    // eslint-disable-next-line no-unused-vars
    request = axios.post(CANCEL_NOTES_DOCUMENTS, data);
  }
};

export const getToDoTaskData = (
  component,
  hash = null,
  detail = null,
  from = '',
) => {
  let extra = '';
  if (hash) {
    const account_id = hash.account_id ? hash.account_id : null;
    const contact_id = hash.contact_id ? hash.contact_id : null;
    if (account_id) {
      extra = `/?aid=${hash.account_id}`;
    }
    if (contact_id) {
      extra = `/?cid=${hash.contact_id}`;
    }
    if (account_id && contact_id) {
      extra = `/?cid=${hash.contact_id}&aid=${hash.account_id}`;
    }
  }
  const request = axios.get(GET_TO_DO_TASK_DATA + extra);
  request.then((response) => {
    if (response.status === 200) {
      if (hash && hash.contact_id && !hash.account_id) {
        component.setState(
          {
            allRefContacts: response.data.contacts,
            ref_contacts: response.data.contacts,
            refcontactId: response.data.contacts[0]
              ? response.data.contacts[0].value
              : '',
            refContactIdtoSend: response.data.contacts[0]
              ? response.data.contacts[0].id
              : '',
            refContactName: response.data.contacts[0]
              ? response.data.contacts[0].label
              : '',
          },
          () => {
            component.props.stateChange(component.state);
          },
        );
      } else if (hash && hash.account_id && !hash.contact_id) {
        component.setState(
          {
            ref_accounts: response.data.accounts,
            allRefAccounts: response.data.accounts,
            refaccountId: response.data.accounts[0]
              ? response.data.accounts[0].value
              : '',
            refAccountName: response.data.accounts[0]
              ? response.data.accounts[0].label
              : '',
          },
          () => {
            component.props.stateChange(component.state);
          },
        );
      } else if (from === 'fromMessages') {
        component.setState(
          {
            allRefContacts: response.data.contacts,
            allRefAccounts: response.data.accounts,
            ref_accounts: response.data.accounts,
            ref_contacts: !Object.keys(detail?.account_data).length
              ? response.data.contacts
              : component.state.ref_contacts,
          },
          () => {
            component.props.stateChange(component.state);
          },
        );
      } else {
        component.setState(
          {
            allRefContacts: response.data.contacts,
            ref_contacts: response.data.contacts,
            ref_accounts: response.data.accounts,
            allRefAccounts: response.data.accounts,
          },
          () => {
            if (detail) {
              component.setState(
                {
                  refcontactId: detail.todo_for_cpa_user_id
                    ? detail.todo_for_cpa_user_id
                    : '',
                  refaccountId: detail.todo_for_account_id
                    ? detail.todo_for_account_id
                    : '',
                  refContactIdtoSend: detail.todo_for_contact_id
                    ? detail.todo_for_contact_id
                    : '',
                  refContactName: detail.todo_contact_name,
                  refAccountName: detail.todo_account_name,
                },
                () => {
                  component.props.stateChange(component.state);
                },
              );
            }
          },
        );
      }
    }
  });
};

export const getNewContacts = (component) => {
  const request = axios.get(GET_NEW_CONTACTS);
  request
    .then((response) => {
      if (response.status === 200) {
        const account = [];
        const con = [];
        response.data.map((item) => {
          if (item.accounts && item.accounts.length) {
            item.accounts.map((data) => {
              account.push(data);
              return true;
            });
          } else if (item.all_accounts && item.all_accounts.length) {
            item.all_accounts.map((data) => {
              account.push(data);
              return true;
            });
          }
          if (
            item.type === 'contact' &&
            !item.all_accounts &&
            item.all_accounts === undefined
          ) {
            con.push(item);
          }
          return null;
        });
        const output = _.uniqWith(account, _.isEqual);
        component.setState({
          allContacts: con,
          contacts: con,
          allAccounts: output,
          accounts: output,
        });
      }
    })
    .catch(() => {
      component.setState({ loading: false });
    });
};

export const getRelConAndCstOfAccounts = (component, accountId) => {
  const request = axios.get(
    `${GET_REL_CONTACTS_AND_CST_OF_ACCOUNTS}?account_id=${accountId}`,
  );

  request
    .then((response) => {
      if (response.status === 200 && response.data) {
        const { data } = response;
        const groupedOptions = groupEmpAndContacts(data);

        component.setState({ contacts: data, groupedOptions });
      }
    })
    .catch(() => {});
};

export const getAllMessagesView = (
  component,
  viewId = '',
  loadtime = '',
  message_page = 1,
  apiUrl = '',
) => {
  const request = axios.get(GET_ALL_MESSAGES_VIEW);
  request
    .then((response) => {
      const defaultView = [];
      if (
        loadtime === 'dontShowDefaultView' ||
        (loadtime === 'firsttime' && defaultView && !defaultView.length)
      ) {
        component.getUserView({}, component.state.filter, message_page, apiUrl);
      } else if (
        loadtime === 'firsttime' &&
        defaultView &&
        defaultView.length &&
        defaultView[0] &&
        defaultView[0].is_default_view &&
        defaultView[0].is_default_view === true &&
        defaultView[0].value
      ) {
        component.getUserView(defaultView[0], '');
      } else if (viewId) {
        let selectedView = [];
        selectedView = response.data.data.filter(
          (each) => each.value === viewId,
        );
        if (selectedView && !selectedView.length && !selectedView[0]) {
          selectedView = response.data.shared_views.filter(
            (each) => each.value === viewId,
          );
        }
        component.getUserView(selectedView[0], '');
      }
      component.setState({
        allMessagesView: response.data.data,
        allMessagesSharedView: response.data.shared_views,
      });
    })
    .catch(() => {
      component.setState({});
    });
};

export const getUserView = (component, id) => {
  let { selectedFilterList } = component.state;
  component.setState({ loading: true });
  const request = axios.get(`${USER_VIEWS}/${encodeURIComponent(id)}`);
  request
    .then((response) => {
      const {
        user_view_name,
        is_shared,
        account_ids,
        recipient_ids,
        date_range,
        has_attachment,
        is_default_view,
      } = response.data.data;
      const dates = date_range.split(', ');
      selectedFilterList = {
        ...selectedFilterList,
        'USER VIEW NAME': user_view_name,
        'DATE RANGE': [
          {
            from: dates[0] ? moment(dates[0]).toDate() : '',
            to: dates[1] ? moment(dates[1]).toDate() : '',
          },
        ],
        restictVisibilty:
          is_shared === false
            ? 'RESTRICT VISIBILITY TO ME'
            : 'VISIBILITY TO ALL USERS',
        ACCOUNTS: account_ids,
        RECIPIENT: recipient_ids,
        ACCOUNTS_DATA: response.data.data.accounts_data
          ? response.data.data.accounts_data
          : '',
        RECIPIENT_DATA:
          response.data.data.recipient_data &&
          response.data.data.recipient_data.length
            ? response.data.data.recipient_data
            : '',
        viewFor: 'Edit',
        'DEFAULT VIEW': is_default_view,
      };
      component.setState(
        {
          selectedFilterList,
          to: dates[1] ? moment(dates[1]).toDate() : '',
          from: dates[0] ? moment(dates[0]).toDate() : '',
          attachmentCheckBox: has_attachment || false,
        },
        () => {
          component.setState({
            customeView: true,
            loading: false,
          });
        },
      );
    })
    .catch(() => {
      component.setState({ loading: false });
    });
};

export const deleteUserView = (component, id) => {
  const { filter } = component.state;
  component.setState({ loading: true });
  const request = axios.delete(`${USER_VIEWS}/${encodeURIComponent(id)}`);
  request
    .then((response) => {
      if (response.data.status === 200) {
        getAllMessagesView(component, '');
        component.getUserView({}, filter);
        component.setState({
          selectedFilterList: {},
          to: null,
          from: null,
          filteredUserViewId: '',
          recipientValue: '',
          attachmentCheckBox: false,
          contacts: component.state.allContacts,
          customeView: false,
          deleteViewModal: false,
        });
        AlertMessage('success', response.data.message, 3000);
      } else if (response.data.message) {
        component.setState({ loading: false });
        AlertMessage('error', response.data.message, 3000);
      } else {
        component.setState({ loading: false });
        AlertMessage('error', 'Some error occurred. Please try later.', 3000);
      }
    })
    .catch(() => {
      component.setState({ loading: false });
      AlertMessage('error', 'Some error occurred. Please try later.', 3000);
    });
};
