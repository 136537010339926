import { Stack, Typography } from '@mui/material';

interface Props {
  label: string;
  value: string;
  current?: boolean;
}

/**
 * Simple helper component for rendering the various slots
 * in the Tier panel.
 */
export default function TierSlot({ label, value, current = false }: Props) {
  return (
    <Stack gap={2}>
      <Typography
        variant="h4"
        sx={({ palette }) => ({
          fontWeight: 'bold',
          color: `${current ? 'inherit' : palette.common.midGray}`,
        })}
      >
        {label.toLocaleUpperCase()}
      </Typography>
      <Typography
        variant={current ? 'h1' : 'h3'}
        sx={{ fontWeight: `${current && 'bold'}` }}
      >
        {value}
      </Typography>
    </Stack>
  );
}
