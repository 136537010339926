/* eslint-disable react-hooks/exhaustive-deps */
import {
  Autocomplete,
  Chip,
  FormControl,
  InputLabel,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { getCpaPreferences } from 'utilities/utils';
import { SMSVaultDialogType } from './FormValidation';

export default function TagsElement() {
  const theme = useTheme();
  const [tagList, setTagList] = useState<LegacyUpload[]>([]);
  const {
    setValue,
    formState: { errors },
  } = useFormContext<SMSVaultDialogType>();

  useEffect(() => {
    setValue(
      'tags',
      tagList.map((tag) => tag.label),
    );
  }, [tagList]);

  const { upload_tags } = getCpaPreferences();
  const uploadTags: LegacyUpload[] = upload_tags;

  return (
    <Stack>
      <InputLabel htmlFor="tags">
        <Typography variant="h5" sx={{ color: theme.palette.text.secondary }}>
          TAGS
        </Typography>
      </InputLabel>
      <FormControl sx={{ paddingBottom: '1rem' }}>
        <Autocomplete
          id="tags"
          options={uploadTags}
          multiple
          freeSolo
          disableClearable
          value={tagList}
          onChange={(_event, value) => {
            setTagList(value as LegacyUpload[]);
          }}
          getOptionLabel={(opt) => (opt as LegacyUpload).label}
          ListboxProps={{
            style: { maxHeight: '15rem' },
          }}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip {...getTagProps({ index })} label={option.label} />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              error={Boolean(errors.tags)}
              helperText={errors.tags?.message}
            />
          )}
        />
      </FormControl>
    </Stack>
  );
}
