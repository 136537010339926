import { AccountTreeOutlined, BackupOutlined } from '@mui/icons-material';
import { ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { WorkflowItemType } from 'components/Requests/requests.types';
import theme from 'theme';
import yn from 'images/icons/yn.svg';
import squiggly from 'images/icons/squiggle.svg';

interface AddItemMenuProps {
  onSelect: (itemType: WorkflowItemType | null) => void;
  anchorEl: HTMLButtonElement | null;
  onClose: () => void;
  open: boolean;
}

export function AddItemMenu({
  onSelect,
  anchorEl,
  open,
  onClose,
}: AddItemMenuProps) {
  return (
    <Menu open={open} anchorEl={anchorEl} onClose={onClose}>
      <MenuItem onClick={() => onSelect(null)}>
        <ListItemIcon>
          <AccountTreeOutlined />
        </ListItemIcon>
        <ListItemText>Subsection</ListItemText>
      </MenuItem>
      <MenuItem onClick={() => onSelect(WorkflowItemType.Boolean)}>
        <ListItemIcon>
          <img alt="" src={yn} />
        </ListItemIcon>
        <ListItemText>Y/N</ListItemText>
      </MenuItem>
      <MenuItem
        value={WorkflowItemType.Text}
        onClick={() => onSelect(WorkflowItemType.Text)}
      >
        <ListItemIcon>
          <img alt="" src={squiggly} />
        </ListItemIcon>
        <ListItemText>Short answer</ListItemText>
      </MenuItem>
      <MenuItem
        value={WorkflowItemType.Upload}
        onClick={() => onSelect(WorkflowItemType.Upload)}
      >
        <ListItemIcon sx={{ color: theme.palette.common.black }}>
          <BackupOutlined />
        </ListItemIcon>
        <ListItemText>File upload</ListItemText>
      </MenuItem>
    </Menu>
  );
}
