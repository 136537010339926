export const REQUESTS_PAGE_TITLES = {
  requests: 'Requests',
  builder: 'Request Builder',
  dispatch: 'Review & Send',
  draftsWorkflows: 'Drafts - In Progress',
  draftsRequests: 'Drafts - Ready to Send',
  draftsRequestsBulkSend: 'Drafts - Bulk Send Preview',
  new: 'New Request',
  open: 'Open Requests',
  archived: 'Archived Requests',
  pending: 'Pending Requests',
  response: 'Response Preview',
  uploadRPA: 'Upload Tax Files',
  processing: 'Processing',
};

export const REQUESTS_PAGE_ROUTES = {
  requests: '/requests',
  dispatch: '/requests/dispatch',
  builder: '/requests/builder',
  requestsDrafts: '/requests/drafts/requests',
  requestsDraftsBulkSend: '/requests/drafts/requests/bulk-send',
  workflowsDrafts: '/requests/drafts/workflows',
  pending: '/requests/pending',
  archived: '/requests/archived',
  processing: '/requests/processing',
  import: '/requests/import',
  new: '/requests/new',
};

export const REQUESTS_QUERY_PARAMS = {
  import: 'import',
  newRequest: 'newRequest',
};

// used to detect whether the request
// was from within the app or external
export const IN_APP = 'IN_APP';
