import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { QB_GET_BANK_DATA, QB } from 'constants/constants.js';
import { SelectField } from 'common/CustomFields';
import { AlertMessage } from 'utilities/utils';

const LedgerSetupModal = ({
  isModalOpen,
  closeLedgerSetupModal,
  qbId,
  bankChargeSelectedValue,
  bankDepositSelectedValue,
  setBankChargeSelectedValue,
  setBankDepositSelectedValue,
  isQBSetupDone,
  setQBSetup,
}) => {
  const [chargeOptions, setChargeSelectOptions] = useState(undefined);
  const [depositOptions, setDepositSelectOptions] = useState(undefined);
  const [bankChargeOption, setBankChargeOption] = useState(
    bankChargeSelectedValue,
  );
  const [bankDepositOption, setBankDepositOption] = useState(
    bankDepositSelectedValue,
  );
  const [isLoading, setLoading] = useState(true);

  const btnLabel = !isQBSetupDone ? 'Save' : 'Update';
  const isBtnDisabled =
    !bankChargeOption ||
    !bankDepositOption ||
    bankChargeOption.id === bankDepositOption.id;
  const errorMessage =
    bankChargeOption &&
    bankDepositOption &&
    bankChargeOption.id === bankDepositOption.id
      ? 'ERROR: Bank Charge Account and Bank Deposit Account cannot be same.'
      : '';

  async function getBankData() {
    try {
      const response = await Axios.get(QB_GET_BANK_DATA);
      if (response.status === 200) {
        const { charge_accounts, deposit_accounts } = response.data;
        setLoading(false);
        setChargeSelectOptions(charge_accounts);
        setDepositSelectOptions(deposit_accounts);
      }
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    getBankData();
  }, []);

  async function handleSave() {
    try {
      const response = await Axios.put(`${QB}/${qbId}`, {
        bank_charge_id: bankChargeOption.id,
        bank_deposit_id: bankDepositOption.id,
      });

      if (response.status === 200) {
        setBankChargeSelectedValue(bankChargeOption);
        setBankDepositSelectedValue(bankDepositOption);
        if (!isQBSetupDone) setQBSetup(true);
        AlertMessage('success', response.data.message);
      }
    } catch (err) {
      console.error(err);
    }

    closeLedgerSetupModal();
  }

  function handleBankChargeChange(selectedOption) {
    setBankChargeOption(selectedOption);
  }

  function handleBankDepositChange(selectedOption) {
    setBankDepositOption(selectedOption);
  }

  return (
    <Modal
      isOpen={isModalOpen}
      className="LedgerSetup__Modal customModal customModal--center"
    >
      <ModalHeader className="ModalHeader">
        Ledger Account Mapping
        <button
          type="button"
          onClick={closeLedgerSetupModal}
          className="close"
          aria-label="Close"
        >
          <i className="icon-close2" aria-hidden="true" />
        </button>
      </ModalHeader>
      <ModalBody
        className="LedgerSetup__ModalBody"
        style={{ overflow: 'visible' }}
      >
        <div className="container">
          <div className="row">
            <div className="col-sm">
              <SelectField
                name="bankChargeAccount"
                label="Bank Charge Account"
                placeholder="Select"
                options={chargeOptions}
                handleChange={handleBankChargeChange}
                value={bankChargeOption || ''}
                className="col-8 form-group"
                isLoading={isLoading}
                searchable
                clearable
              />
            </div>
            <div className="col-sm">
              <SelectField
                name="bankDepositAccount"
                label="Bank Deposit Account"
                placeholder="Select"
                options={depositOptions}
                handleChange={handleBankDepositChange}
                value={bankDepositOption || ''}
                className="col-8 form-group"
                isLoading={isLoading}
                searchable
                clearable
              />
            </div>
          </div>
          <div className="LedgerSetup__ErrorMessage text-danger">
            {errorMessage}
          </div>
        </div>
      </ModalBody>
      <ModalFooter className="modalContent">
        <button className="btn" type="button" onClick={closeLedgerSetupModal}>
          Cancel
        </button>
        <button
          className="btn btn-primary"
          type="button"
          disabled={isBtnDisabled}
          onClick={handleSave}
        >
          {btnLabel}
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default LedgerSetupModal;
