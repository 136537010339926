import { createContext, useContext, useMemo } from 'react';
import { WebsocketServiceInstance, websocket } from './WebsocketService';

export interface WebsocketContextType {
  websocket?: WebsocketServiceInstance;
}

export const WebsocketContext = createContext<WebsocketContextType>({});

/**
 * Note: This context is kind of moot since our websocket service
 * now only exposes a single instance, but this will till likely
 * prove useful for keeping things contained within React.
 */
export const WebsocketProvider = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const ctx = useMemo(() => ({ websocket }), []);
  return (
    <WebsocketContext.Provider value={ctx}>
      {children}
    </WebsocketContext.Provider>
  );
};

export const useWebsocket = () => {
  const ctx = useContext(WebsocketContext);
  return ctx.websocket!;
};
