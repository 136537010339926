const initialState = {
  data: {},
  avatar: '',
  username: '',
};

// eslint-disable-next-line default-param-last
export default function homeReducer(state = initialState, action) {
  switch (action.type) {
    case 'FETCH_HOME_DATA':
      return { ...state, data: action.data };
    case 'FETCH_AVATAR':
      return { ...state, avatar: action.data };
    case 'FETCH_USER_NAME':
      return { ...state, username: action.data };
    default:
      return state;
  }
}
