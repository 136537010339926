import React from 'react';
import styled from '@emotion/styled';
import {
  rowActions,
  makeDoneActionFilterFunction,
} from 'components/Email/EmailUtilities';
import ArchiveBulkAction from 'components/Email/components/EmailBulkActions/ArchiveBulkAction';
import useWhichSection from 'components/Email/components/useWhichSection';
import ReplyRowAction from './ReplyRowAction';
import ReplyWithLiscioMessageAction from './ReplyWithLiscioMessageAction';

const EmailRowActionsWrap = styled.div`
  &.Email__RowActions {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .button-icon {
      border: 1px solid #e0e1e2;
      cursor: pointer;
      padding: 0;
      line-height: 1;
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        width: 14px;
      }
      & + .button-icon {
        margin-left: 7px;
      }
    }
  }
`;

const EmailRowActions = ({ handleRowActions, email }) => {
  const { inLinkedSection } = useWhichSection();
  const filteredActions = rowActions.filter(
    makeDoneActionFilterFunction(email, inLinkedSection),
  );

  return (
    <EmailRowActionsWrap className="Email__RowActions" data-testid="rowActions">
      {filteredActions.map((obj) => {
        const actionObj = obj;
        const actionName = obj.value;
        switch (actionName) {
          case 'done':
            return (
              <ArchiveBulkAction
                key={obj.label}
                handleEmailActions={handleRowActions}
                actionObj={actionObj}
                actionFromValue="true"
              />
            );
          case 'reply':
            return (
              <ReplyRowAction
                key={obj.label}
                handleEmailActions={handleRowActions}
                actionObj={actionObj}
                actionFromValue="true"
              />
            );
          case 'respond_with_message':
            return (
              <ReplyWithLiscioMessageAction
                key={obj.label}
                handleEmailActions={handleRowActions}
                actionObj={actionObj}
              />
            );
          default:
            return null;
        }
      })}
    </EmailRowActionsWrap>
  );
};
export default EmailRowActions;
