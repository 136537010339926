/* eslint-disable class-methods-use-this */
import React, { Component } from 'react';
import { Link } from 'react-router-dom-latest';
import { withRouter } from 'utilities/withRouterWrapper';
import Axios from 'axios';
import Pagination from 'common/Pagination';
import * as Utilities from 'utilities/utils';
import NoRecords from 'common/NoRecords';
import TooltipItem from 'common/TooltipItem';
import {
  GET_NOTES_OF_CONTACT,
  GET_NOTES_OF_ACCOUNT,
} from 'constants/constants';
import NoteDetail from './NoteDetail';

class NoteList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pagination: 1,
      sortOrder: 'desc',
      sortBy: 'reference_date',
      loading: true,
      searchFieldText: '',
      offset: [0, 20],
      totalRecords: 0,
      totalPages: 0,
      listData: [],
    };
  }

  componentDidMount() {
    this.getRecords();
    if (this.props.location.hash && this.props.location.hash.includes('id')) {
      const noteId = this.props.location.hash.split('=');
      this.showNoteDetails(noteId[1]);
    }
  }

  getRecords = () => {
    const { pagination, sortBy, sortOrder, searchFieldText } = this.state;
    let url = GET_NOTES_OF_ACCOUNT;
    if (window.location.href.includes('contact')) {
      url = GET_NOTES_OF_CONTACT;
    }
    url = `${url}/${this.props.id}?page=${pagination}&sort_by=${sortOrder}&field=${sortBy}`;
    if (searchFieldText && searchFieldText !== null && searchFieldText.length) {
      url = `${url}&search=${searchFieldText}`;
    }
    Axios.get(url).then((res) => {
      if (
        res.data &&
        res.data.status === 200 &&
        res.data.data &&
        res.data.data.notes &&
        res.data.data.notes.length
      ) {
        const {
          res_data: { total_entries, off, offset },
          data,
        } = res.data;
        this.setState({
          listData: data.notes,
          loading: false,
          totalPages: total_entries ? Math.ceil(total_entries / 20) : 0,
          totalRecords: total_entries,
          offset: [offset, off],
        });
      } else {
        this.setState({
          loading: false,
          totalRecords: 0,
          totalPages: 0,
          listData: undefined,
        });
      }
    });
  };

  showNoteDetails = (noteId) => {
    this.setState({ noteId });
    this.props.navigate(`#notes?id=${noteId}`);
  };

  editNote = (id, page) => {
    if (page === 'edit') {
      return this.props.navigate(`/note/edit/${id}`);
    }
    return `/notedetails/${id}`;
  };

  handleSortBy = (sortBy) => {
    let { sortOrder } = this.state;
    if (this.state.sortBy === sortBy) {
      if (sortOrder === 'asc') {
        sortOrder = 'desc';
      } else {
        sortOrder = 'asc';
      }
    } else {
      sortOrder = 'asc';
    }
    this.setState(
      {
        sortOrder,
        sortBy,
        loading: true,
      },
      () => {
        this.getRecords();
      },
    );
  };

  handlePageChange = (input) => {
    this.setState(
      {
        pagination: input,
        loading: true,
      },
      () => this.getRecords(),
    );
  };

  handleSearchField = (input) => {
    const inputvalue = input && input.target.value ? input.target.value : '';
    const { searchFieldText } = this.state;
    this.setState({
      searchFieldText: inputvalue,
      pagination: 1,
    });
    clearTimeout(this.typingTime);
    this.typingTime = setTimeout(() => {
      this.getRecords(inputvalue, searchFieldText.length);
    }, 500);
  };

  closeNoteDetailsModal = () => {
    this.setState({ noteId: undefined });
    this.props.navigate('#notes', { replace: true });
  };

  render() {
    const {
      loading,
      listData,
      pagination,
      totalRecords,
      totalPages,
      offset,
      sortBy,
      sortOrder,
      searchFieldText,
      noteId,
    } = this.state;
    const { contactData, accountData } = this.props;
    return (
      <div className="center-wrapper">
        {loading && <div id="loading" />}
        <div className="mb-3 section-title">
          <div className="col-container">
            <div className="col col-6">
              <div className="form-group has-search">
                <span className="icon-zoom2 form-control-feedback" />
                <input
                  id="notes-search"
                  type="text"
                  className="form-control"
                  placeholder="Search Notes"
                  onChange={this.handleSearchField}
                  value={searchFieldText}
                  autoComplete="off"
                />
              </div>
            </div>
            <div className="col col-6 text-right">
              {(!contactData || (contactData && contactData.is_active)) &&
                (!accountData ||
                  (accountData &&
                    accountData.account &&
                    accountData.account.status !== 'Inactive')) && (
                  <Link
                    to={`/note/new?${
                      this.props.notesof === 'account'
                        ? `account_id=${this.props.id}`
                        : `contact_id=${this.props.id}`
                    }`}
                    className="btn btn-primary"
                  >
                    <i>+</i> Note
                  </Link>
                )}
            </div>
          </div>
        </div>
        <div className="clearfix" />
        <section>
          {listData === undefined || listData === null ? <NoRecords /> : ''}
          {listData && listData.length > 0 ? (
            <div className="tab-content">
              <div className="tab-pane active" id="review">
                <div id="inbox-list" className="tableWrap">
                  <div className="tRow tRow--head">
                    <div className="row">
                      {this.props.notesof === 'account' ? (
                        <div
                          className={`${Utilities.sortingIcon(
                            sortBy,
                            'first_name',
                            sortOrder,
                            'className',
                          )} col-3 thCol`}
                          onClick={() => this.handleSortBy('first_name')}
                        >
                          <span className="posRel">
                            Contact{' '}
                            <i
                              className={Utilities.sortingIcon(
                                sortBy,
                                'first_name',
                                sortOrder,
                              )}
                            />
                          </span>
                        </div>
                      ) : (
                        <div
                          className={`${Utilities.sortingIcon(
                            sortBy,
                            'name',
                            sortOrder,
                            'className',
                          )} col-3 thCol`}
                          onClick={() => this.handleSortBy('name')}
                        >
                          <span className="posrel">
                            ACCOUNT{' '}
                            <i
                              className={Utilities.sortingIcon(
                                sortBy,
                                'name',
                                sortOrder,
                              )}
                            />
                          </span>
                        </div>
                      )}
                      <div
                        className={`${Utilities.sortingIcon(
                          sortBy,
                          'subject',
                          sortOrder,
                          'className',
                        )} col-3 thCol`}
                        onClick={() => this.handleSortBy('subject')}
                      >
                        <span className="posRel">
                          TITLE{' '}
                          <i
                            className={Utilities.sortingIcon(
                              sortBy,
                              'subject',
                              sortOrder,
                            )}
                          />
                        </span>
                      </div>
                      <div
                        className={`${Utilities.sortingIcon(
                          sortBy,
                          'created_by',
                          sortOrder,
                          'className',
                        )} col-3 thCol`}
                        onClick={() => this.handleSortBy('created_by')}
                      >
                        <span className="posRel">
                          EDITED BY{' '}
                          <i
                            className={Utilities.sortingIcon(
                              sortBy,
                              'created_by',
                              sortOrder,
                            )}
                          />
                        </span>
                      </div>
                      <div
                        className={`${Utilities.sortingIcon(
                          sortBy,
                          'reference_date',
                          sortOrder,
                          'className',
                        )} col-1 thCol`}
                        onClick={() => this.handleSortBy('reference_date')}
                      >
                        <span className="posRel">
                          DATE{' '}
                          <i
                            className={Utilities.sortingIcon(
                              sortBy,
                              'reference_date',
                              sortOrder,
                            )}
                          />
                        </span>
                      </div>
                      <div className="col-2 thCol text-right">
                        <span className="posRel" />
                      </div>
                    </div>
                  </div>
                  <div className="tRow tRow--body">
                    {listData.map((item) => {
                      const accConNam =
                        this.props.notesof === 'account'
                          ? item.contact
                          : item.account;
                      return (
                        <div key={item.id} className="row tdBtn">
                          <div
                            className="col-3 tdCol text-body"
                            onClick={() => this.showNoteDetails(item.id)}
                          >
                            <span>{!accConNam ? '-' : accConNam}</span>
                          </div>
                          <div
                            className="col-3 tdCol text-body"
                            onClick={() => this.showNoteDetails(item.id)}
                          >
                            <span>{item.subject ? item.subject : '-'}</span>
                          </div>
                          <div
                            className="col-3 tdCol text-body"
                            onClick={() => this.showNoteDetails(item.id)}
                          >
                            <span>{item.created_by}</span>
                            <br />
                          </div>
                          <div
                            className="col-1 tdCol text-body"
                            onClick={() => this.showNoteDetails(item.id)}
                          >
                            <span className="text-dark">
                              {item.reference_date}
                            </span>{' '}
                          </div>
                          <div className="col-2 tdCol text-right">
                            <TooltipItem
                              position="top"
                              tooltipType="button"
                              id={`editnote${item.id}`}
                              text="Edit"
                              btnClass="btn-sm btn--onlyicon btn-link tdBtn__hover"
                              clickAction={() => this.editNote(item.id, 'edit')}
                              iconn="icon-edit"
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </section>
        <Pagination
          currentPage={pagination}
          totalPages={totalPages}
          totalRecords={totalRecords}
          offset={offset[0]}
          off={offset[1]}
          handlePageChange={this.handlePageChange}
        />
        {noteId && (
          <NoteDetail
            id={noteId}
            closeNoteDetailsModal={this.closeNoteDetailsModal}
          />
        )}
      </div>
    );
  }
}

export default withRouter(NoteList);
