import { Box, Divider, Stack, ThemeProvider, Typography } from '@mui/material';
import liscioLightTheme from 'theme/liscioLight';
import SMSSettingsFormBody from './SMSSettingsFormBody';

export default function SMSSettingsForm() {
  return (
    <ThemeProvider theme={liscioLightTheme}>
      <Stack
        aria-label="SMS Admin Page"
        overflow="hidden"
        height="calc(100% - 32px)"
      >
        <Box padding="1.6rem 2.3rem">
          <Typography fontWeight="bolder" variant="h5">
            SMS SETUP
          </Typography>
        </Box>
        <Divider orientation="horizontal" />
        <SMSSettingsFormBody />
      </Stack>
    </ThemeProvider>
  );
}
