import React from 'react';
import { IntegrationsPageLayout } from 'components/Integrations/components/IntegrationsPageLayout';

export const StripeIntegrationConfig = () => {
  console.log('TODO: Determine activation status');
  return (
    <IntegrationsPageLayout
      pageTitle="Stripe Integration"
      breadcrumbs={[{ label: 'Stripe', path: '/stripe' }]}
    >
      TODO: Stripe Integration Module
    </IntegrationsPageLayout>
  );
};
