import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom-latest';
import Axios from 'axios';
import { CUSTOM_TEMPLATES } from 'constants/constants';
import { AlertMessage } from 'utilities/utils';
import TaskFileUpload from 'components/Files/TaskFileUpload';
import TinymceEditor from 'components/Emails/components/TinymceEditior';

const NewTemplate = ({ navigate }) => {
  const parsedUrl = new URL(window.location.href);
  const getTemplateType = parsedUrl.searchParams.get('type');
  const templateType = getTemplateType || 'message_template';

  const [errors, setErrors] = useState({});
  const [title, setTitle] = useState('');
  const [comment, setComment] = useState('');
  const [allDocuments, setDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [typeOfTemplate, setTemplateType] = useState(templateType);

  const { id: templateId } = useParams();
  const mode = templateId && templateId !== 'new' ? 'EDIT' : 'CREATE';

  useEffect(() => {
    document.title = 'Create Message Template';
    if (templateId && mode === 'EDIT') {
      setLoading(true);
      Axios.get(`${CUSTOM_TEMPLATES}/${templateId}`).then((res) => {
        if (res.data && res.data.success && res.data.data) {
          const { name, content, template_type } = res.data.data;
          const { documents } = res.data;

          setTitle(name);
          setComment(content);
          setDocuments(documents);
          setTemplateType(template_type);
          setLoading(false);
        }
      });
    }
  }, []);

  function handleChange(e) {
    setErrors({});
    setTitle(e.target.value);
  }

  function handleCommentChange(value) {
    setComment(value);
  }

  function showSelectedData() {
    if (isFormValid()) {
      setLoading(true);

      const data = { subject: title };

      data.description = comment;
      data.template_type = typeOfTemplate;
      const docs = [];
      allDocuments.forEach((e) => {
        docs.push(e.id);
      });

      data.documents = docs;

      let request = '';
      if (mode === 'EDIT') {
        request = Axios.put(`${CUSTOM_TEMPLATES}/${templateId}`, data);
      } else {
        request = Axios.post(CUSTOM_TEMPLATES, data);
      }

      request
        .then((res) => {
          if (res.data && res.data.status === 200) {
            AlertMessage('success', res.data.message, 3000);
            navigate(`/templates?tab=${typeOfTemplate}`);
          }
        })
        .catch((error) => {
          setLoading(false);
          AlertMessage('error', error.response.data.message, 3000);
        });
    }
  }

  function isFormValid() {
    const err = {};
    let formIsValid = true;

    if (title === '' || !title) {
      formIsValid = false;
      err.title_error = 'Enter task title';
    }

    if (comment && !comment.length) {
      formIsValid = false;
      err.comment_error = 'Enter Description';
    }

    setErrors(err);

    return formIsValid;
  }

  function navigateBack() {
    navigate(-1);
  }

  function updateDocumentState(documents) {
    delete errors.document;
    setDocuments(documents);
    setErrors(errors);
  }

  return (
    <div id="content" data-testid="newTemplate">
      <header>
        <ul className="breadcrumb">
          <li className="breadcrumb-item">
            {
              {
                message_template: 'Message Template',
                task_template: 'Task Template',
                edoc_template: 'Edoc Template',
              }[typeOfTemplate]
            }
          </li>
        </ul>
      </header>
      <div className="center-wrapper col-lg-10 col-sm-12 col-md-12 m-auto with-disabled-inputs">
        <div className="row">
          <div
            className={`form-group col-12${
              errors.title_error ? ' form-error' : ''
            }`}
          >
            <label htmlFor="title">Subject</label>
            <input
              autoComplete="off"
              name="title"
              className="form-control"
              id="title"
              data-testid="title"
              type="text"
              value={title}
              maxLength="200"
              onChange={handleChange}
            />
            <span className="error_label">{errors.title_error}</span>
          </div>
        </div>
        <div className="row">
          <div
            className={
              errors.comment_error
                ? 'form-group col-lg-12 form-error'
                : 'form-group col-lg-12'
            }
          >
            <div className="has-float-label customEditor boxResize">
              <label htmlFor="taskDescription">Description</label>
              <TinymceEditor
                value={comment}
                handleChange={handleCommentChange}
              />
              <span className="error_label">{errors.comment_error}</span>
            </div>
          </div>
        </div>
        {typeOfTemplate && typeOfTemplate !== null && (
          <div className="row">
            <div className="form-group dragWrap col-lg-12">
              <label htmlFor="attachments">Attachments</label>
              <TaskFileUpload
                data={{ documents: allDocuments, templateId }}
                uploadType={typeOfTemplate}
                type={typeOfTemplate}
                updateDocumentState={updateDocumentState}
              />
            </div>
          </div>
        )}
        <div className="formbtn justify-content-end">
          <div className="btn-wrap btn--leftspace">
            <button
              type="button"
              className="btn btn-outline-light"
              onClick={navigateBack}
            >
              Cancel
            </button>
            <button
              type="button"
              data-testid="createTemplateBtn"
              className="btn btn-primary"
              onClick={showSelectedData}
              disabled={loading}
            >
              {loading
                ? 'Loading'
                : `${mode === 'EDIT' ? 'Update' : 'Create'} Template`}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewTemplate;
