import { VisibilityOutlined } from '@mui/icons-material';
import { Skeleton, Typography, Button } from '@mui/material';
import FileDisplayFromUrl from 'common/FileDisplayFromUrl/FileDisplayFromUrl';
import { useDocumentDetail } from 'hooks/document-hooks';
import { useState } from 'react';

export type RequestItemDependencyDocumentProps = {
  document: Document;
};
export function RequestItemDependencyDocument({
  document,
}: RequestItemDependencyDocumentProps) {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  // juggle the union type to get the document

  const legacyId =
    document && 'legacyId' in document ? (document?.legacyId as string) : '';

  // get document metadata (by legacyId) for existing documents in the response item
  const {
    data: docDataResponse,
    isLoading: docDataIsLoading,
    fetchStatus: docDataFetchStatus, // https://github.com/TanStack/query/issues/3975
  } = useDocumentDetail(legacyId);

  const documentsAreLoading = docDataIsLoading && docDataFetchStatus !== 'idle';

  const docData = docDataResponse?.data;

  return (
    <>
      {documentsAreLoading && (
        <Skeleton height={60} width="100%" role="progressbar" />
      )}
      {Boolean(docData) && (
        <Button
          variant="text"
          key={docData.id}
          onClick={() => setModalOpen(true)}
          startIcon={<VisibilityOutlined />}
          disabled={documentsAreLoading}
          sx={{
            minWidth: '150px',
            paddingTop: '0px',
            paddingLeft: '2px',
            justifyContent: 'flex-start',
          }}
        >
          <Typography variant="h4">{docData.doc_name}</Typography>
        </Button>
      )}

      {modalOpen && (
        <FileDisplayFromUrl
          open={modalOpen}
          fileName={docData.doc_name || ''}
          headerTitle="File Upload"
          url={docData.aws_url_original || ''}
          onClose={() => setModalOpen(false)}
        />
      )}
    </>
  );
}
