import { Edit } from '@mui/icons-material';
import { Button, FormControlLabel, Switch } from '@mui/material';
import { WorkflowRequestFragment } from 'gql/graphql';
import { ChangeEvent, useContext } from 'react';
import { useChangeRequestDueDate } from 'components/Requests/hooks/change-request-due-date/change-request-due-date';
import { useHeaderMenuHook } from 'components/Requests/hooks/header-menu-hook';
import { WorkflowViewInfoBar } from 'components/Requests/components/WorkflowView/components/WorkflowViewInfoBar/WorkflowViewInfoBar';
import { WorkflowViewTitlePresentation } from 'components/Requests/components/WorkflowView/components/WorkflowViewTitleWrapper/WorkflowViewTitleWrapper';
import { WorkflowViewContext } from 'components/Requests/components/WorkflowView/context/WorkflowViewContext';

type WorkflowViewTitleProps = {
  workflowRequest?: WorkflowRequestFragment;
  allowResponseEdit: boolean;
  onEditWorkflowClick?: () => void;
  onShowUnansweredSwitchChange?: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const WorkflowViewTitle = ({
  workflowRequest,
  allowResponseEdit,
  onEditWorkflowClick,
  onShowUnansweredSwitchChange: onShowSwitchChange,
}: WorkflowViewTitleProps) => {
  const workflow = workflowRequest?.workflow;

  const { PrintPDF } = useHeaderMenuHook({
    title: workflowRequest?.title || '',
    workflowRequestId: workflowRequest?.id,
  });

  const status = workflowRequest?.status || workflow?.status;
  const { ChangeDueDateDialog } = useChangeRequestDueDate(
    workflowRequest as WorkflowRequestFragment,
  );
  const { isDirty } = useContext(WorkflowViewContext);

  return (
    <>
      <WorkflowViewTitlePresentation
        title={workflowRequest?.title || ''}
        description={workflow?.description || ''}
        sourceDocuments={workflowRequest?.rpaDocuments || []}
        workflow={workflowRequest?.workflow}
        slots={{
          infoBar: <WorkflowViewInfoBar isDirty={isDirty} status={status} />,
          controls: (
            <>
              {allowResponseEdit && (
                <FormControlLabel
                  className="hide-form-control-label"
                  sx={{ marginBottom: '0px', alignSelf: 'end' }}
                  control={<Switch onChange={onShowSwitchChange} />}
                  label="Show unanswered items only"
                />
              )}
              {onEditWorkflowClick && (
                <Button
                  startIcon={<Edit />}
                  variant="contained"
                  onClick={onEditWorkflowClick}
                  data-print-id="editWorkflowButton"
                >
                  Edit Request
                </Button>
              )}
            </>
          ),
        }}
      />
      <ChangeDueDateDialog />
      <PrintPDF />
    </>
  );
};
