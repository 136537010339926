import React, { useState } from 'react';
import axios from 'axios';
import Modal from '@mui/material/Modal';
import { TOAST_SUCCESS, TOAST_FAILURE, PAYMENTS } from 'constants/constants';
import styled from '@emotion/styled';
import CustomDatePicker from 'wrappers/DatePickerUtil';
import { AlertMessage } from 'utilities/utils';
import CancelIcon from '@mui/icons-material/Cancel';

const StyledDiv = styled.div`
  background-color: #f9fafb;
  max-width: 990px;
  min-height: 220px;
  box-shadow: 0 10px 40px 0 rgb(0 0 0 / 10%);
  position: relative;
  top: 200px;
  left: 20%;
  padding: 40px 80px;
  .Input__Container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #919eab;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 600;
  }
  .MemoID__Container {
    color: #919eab;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 600;
    display: flex;
    align-items: baseline;
    justify-content: flex-end;
  }
  .Button__Container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 10px;
  }
  .Header__Container {
    border-bottom: 1px solid #dfe3e8;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
  }
  .Header__Text {
    font-weight: 500;
    color: #212b36;
    font-size: 18px;
  }
  .Save__Button {
    background-color: #00aecc;
    border-color: #00aecc;
    color: #fff;
    box-shadow: none;
    padding: 10px 24px;
    border-width: 2px;
    border-style: solid;
    border: 1px solid transparent;
  }
  .Cancel__Button {
    background: #ffffff;
    border-color: #dfe3e8;
    color: #212b36;
    margin-right: 16px;
    padding: 10px 24px;
    border-width: 2px;
    border-style: solid;
  }
  .MemoID_Input {
    width: 250px;
    height: 40px;
    &:hover {
      border: 2px solid hsl(0, 0%, 70%);
    }
    border: ${({ isMemoValid }) =>
      isMemoValid ? '2px solid #dfe3e8' : '2px solid red'};
  }
  .Button__Close {
    transform: translate(105px, -62px);
  }
  .Date__Container {
    display: flex;
    align-items: baseline;
    justify-content: flex-end;
  }
  .Date__Label {
    margin-right: 20px;
    font-size: 15px;
  }
  .MemoID__Label {
    font-size: 15px;
    margin-right: 15px;
  }
  .MuiSvgIcon-root {
    font-size: 30px;
  }
  .InputError__Text {
    color: red;
    font-size: small;
  }
  .MemoInput__Container,
  .DateInput__Container {
    display: flex;
    flex-direction: column;
  }
`;

const getMaxDateInvoice = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth();
  const day = today.getDate();
  return new Date(year + 2, month, day);
};

function getFormattedDate(date) {
  const year = date.getFullYear();

  let month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : `0${month}`;

  let day = date.getDate().toString();
  day = day.length > 1 ? day : `0${day}`;

  return `${month}/${day}/${year}`;
}
const initialBorderStyle = { border: '2px solid #dfe3e8' };
const errorBorderStyle = { border: '2px solid red' };

const MarkAsPaidModal = ({
  openModal,
  handleClose,
  fetchtaskDetail,
  paymentID,
}) => {
  const [memoID, setMemoID] = useState('');
  const [paymentDate, setPaymentDate] = useState('');
  const [isFormValid, setIsFormValid] = useState(true);
  const [isMemoValid, setIsMemoValid] = useState(true);
  const [borderStyle, setBorderStyle] = useState(initialBorderStyle);
  const maxDate = getMaxDateInvoice();

  async function setPaymentStatus() {
    const apiUrl = `${PAYMENTS}/${paymentID}`;
    const date = new Date(paymentDate);
    const dateCommentString = getFormattedDate(date);
    if (paymentDate && memoID) {
      setIsFormValid(true);
      setIsMemoValid(true);
      try {
        const response = await axios.put(apiUrl, {
          payment_date: dateCommentString,
          payment_memo: memoID,
        });
        if (response.status === 200) {
          AlertMessage(TOAST_SUCCESS, 'Marked invoice as paid!');
          fetchtaskDetail(true);
          handleClose();
        }
      } catch (err) {
        AlertMessage(TOAST_FAILURE, 'An error occurred!');
        console.log(err);
        handleClose();
      }
    } else if (!paymentDate && memoID) {
      setIsFormValid(false);
      setBorderStyle(errorBorderStyle);
    } else if (!memoID && paymentDate) {
      setIsMemoValid(false);
    } else if (!memoID && !paymentDate) {
      setIsFormValid(false);
      setIsMemoValid(false);
      setBorderStyle(errorBorderStyle);
    }
  }

  function handlePaymentDateInput(e) {
    setPaymentDate(e);
    setIsFormValid(true);
    setBorderStyle(initialBorderStyle);
  }
  function handleMemoIDInput(e) {
    setMemoID(e.target.value);
    setIsMemoValid(true);
  }

  return (
    <Modal open={openModal} onClose={handleClose}>
      <div className="Modal__Wrap">
        <StyledDiv className="Modal__Body" isMemoValid={isMemoValid}>
          <div className="Header__Container">
            <h4 className="Header__Text"> Payment Details </h4>
            <button
              type="button"
              className="close Button__Close"
              aria-label="Close"
              onClick={handleClose}
            >
              <CancelIcon />
            </button>
          </div>
          <div className="Input__Container">
            <div className="Date__Container">
              <label htmlFor="payment_date" className="Date__Label">
                {' '}
                Payment Date{' '}
              </label>
              <div className="DateInput__Container">
                <CustomDatePicker
                  toolTipLabel="Payment Date"
                  label="Payment Date"
                  selected={paymentDate}
                  onChange={handlePaymentDateInput}
                  minDate={0}
                  maxDate={maxDate}
                  customStyle={borderStyle}
                  onHover={{}}
                />
                {!isFormValid && (
                  <div className="InputError__Text">This field is required</div>
                )}
              </div>
            </div>
            <div className="MemoID__Container">
              <label className="MemoID__Label" htmlFor="MemoID">
                Payment Memo{' '}
              </label>
              <div className="MemoInput__Container">
                <input
                  type="text"
                  className="MemoID_Input"
                  value={memoID}
                  onChange={handleMemoIDInput}
                  name="MemoID"
                />
                {!isMemoValid && (
                  <div className="InputError__Text">This field is required</div>
                )}
              </div>
            </div>
          </div>
          <div className="Button__Container">
            <button
              className="Cancel__Button"
              type="button"
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
              className="Save__Button"
              type="button"
              onClick={setPaymentStatus}
            >
              Save
            </button>
          </div>
        </StyledDiv>
      </div>
    </Modal>
  );
};

export default MarkAsPaidModal;
