import React from 'react';

const TabLoader = () => (
  <>
    <rect x="10" y="0" rx="3" ry="3" width="100" height="25" />
    <rect x="150" y="0" rx="3" ry="3" width="100" height="25" />
  </>
);

export default TabLoader;
