import {
  FormControl,
  inputClasses,
  inputLabelClasses,
  textFieldClasses,
  autocompleteClasses,
  styled,
} from '@mui/material';

export const FormControlStyled = styled(FormControl)(({ theme }) => ({
  [`.${inputLabelClasses.root}`]: {
    color: theme.palette.text.primary,
    fontSize: '1rem',
    fontWeight: 'normal',
    top: '-13px',
    left: '10px',
    zIndex: 10,
  },
  [`.${inputClasses.root}`]: {
    marginTop: '0px',
    zIndex: 9,
    backgroundColor: theme.palette.background.paper,
  },
  [`.${textFieldClasses.root} .${inputClasses.underline}`]: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '5px',
  },
  [`.${autocompleteClasses.root} .${autocompleteClasses.tag}`]: {
    height: '24px',
    maxWidth: 'calc(100% - 61px)',
  },
}));

export const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '8px 10px',
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.background.paper,
  textTransform: 'uppercase',
  width: '100%',
}));
