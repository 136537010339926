import { Box } from '@mui/material';
import LiscioSpinner from 'ui/Loading/LiscioSpinner';

export const LoadingOverlay = () => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    height="100%"
    width="100%"
  >
    <LiscioSpinner />
  </Box>
);
