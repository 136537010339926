export const saveAccountFilterData = (data) => ({
  type: 'STORE_ACCOUNT_FILTER_DATA',
  data,
});

export const saveContactFilterData = (data) => ({
  type: 'STORE_CONTACT_FILTER_DATA',
  data,
});

export const saveEmployeeFilterData = (data) => ({
  type: 'STORE_EMPLOYEE_FILTER_DATA',
  data,
});

export const saveEmployeeAndContactFilterData = (data) => ({
  type: 'STORE_EMPLOYEE_AND_CONTACT_FILTER_DATA',
  data,
});
