import dayjs from 'dayjs';
import format from 'date-fns/format';
import { GLOBAL_EMAIL_REGEX } from 'utilities/regularexpressions';
import { getStorageData } from 'utilities/utils';

export const emailTabs = [
  {
    value: 'primary',
    label: 'Primary',
  },
  {
    value: 'low_priority',
    label: 'Low Priority',
  },
  {
    value: 'sent',
    label: 'Sent',
  },
  {
    value: 'draft',
    label: 'Drafts',
  },
  {
    value: 'pinned',
    label: 'Pinned',
  },
  {
    value: 'done',
    label: 'Archived',
  },
];

export const emailsTimelineCategories = [
  {
    key: 'today',
    label: 'Today',
  },
  {
    key: 'yesterday',
    label: 'Yesterday',
  },
  {
    key: 'thisWeek',
    label: 'Past 7 days',
  },
  {
    key: 'lastWeek',
    label: 'Past 14 days',
  },
  {
    key: 'thisMonth',
    label: 'This month',
  },
  {
    key: 'lastMonth',
    label: 'Last month',
  },
  {
    key: 'thisYear',
    label: 'This year',
  },
  {
    key: 'lastYear',
    label: 'Last year',
  },
  {
    key: 'pastYear',
    label: 'Past year',
  },
];

export const rowActions = [
  {
    label: 'Archive',
    value: 'done',
  },
  {
    label: 'Reply',
    value: 'reply',
  },
  {
    label: 'Reply With Liscio Message',
    value: 'respond_with_message',
  },
];

export const extractContactFromEmailString = (emailString) => {
  if (!emailString) {
    return {
      name: null,
      email: null,
    };
  }

  const sanitizedEmailString = emailString.replace(/[[\]]+/g, '').trim();

  const emails = sanitizedEmailString.match(GLOBAL_EMAIL_REGEX);
  const email = emails ? emails[0] : null;
  const index = sanitizedEmailString.indexOf('<');

  const name =
    index > 0
      ? sanitizedEmailString.substring(0, index).trim()
      : sanitizedEmailString;

  return { name: name !== email ? name : null, email };
};

export const extractContactsMultipleEmailsString = (emailsString) => {
  if (!emailsString) {
    return [];
  }
  return emailsString.split(',').map(extractContactFromEmailString);
};

export const formatEmailAddresses = (data, defaultNameValue) => {
  // TODO: __HOMEDATA-AUDIT
  const logginedUserEmail = getStorageData().email;

  const contacts = extractContactsMultipleEmailsString(data);

  return contacts
    .filter((contact) => contact.email !== logginedUserEmail)
    .map((contact) => {
      // We can only use default name for single contact
      const defaultName = contacts.length === 1 ? defaultNameValue : null;
      const name = contact.name || defaultName;

      return {
        label: contact.email,
        value: name ? `${name} <${contact.email}>` : contact.email,
      };
    });
};

export const formatEmail = (data) => {
  let emails = data;
  emails = data.replace(/[\\[\]']+/g, '');
  emails = emails.split(', ');
  return emails;
};

// this is getting initialstate for email
// TODO: need to add emails to cc when user clicks reply all
export const getInitialState = (data, actionType) => {
  let toRecipients = [];
  let ccRecipients = [];
  let bccRecipients = [];
  const subject = data?.subject || '';
  let body = '';
  let replyToMsgId = null;
  let draftId = null;
  let attachments = [];
  let emailId = null;
  let pretext = '';
  if (data && data.draft_id && data.draft_id !== null) {
    // for draft email
    toRecipients = formatEmailAddresses(data.to_email);

    ccRecipients = formatEmailAddresses(data.cc_email);
    bccRecipients = formatEmailAddresses(data.bcc_email);
    replyToMsgId = data.reply_to_msg_id;
    body = data.content;
    emailId = data.email_id;
    draftId = data.draft_id;
    attachments = data.documents;
  } else if (data) {
    // for new email
    const fromEmail = formatEmailAddresses(
      data.from_email,
      data.from_email_display_name,
    );
    const toEmail = formatEmailAddresses(data.to_email);
    let recp = [...fromEmail, ...toEmail];
    if (actionType === 'reply') {
      recp =
        data.inbound_status === 'sent' || !data.inbound_status
          ? [...toEmail]
          : [...fromEmail];
    }
    const ids = recp.map((rec) => rec.value);
    const filtered = recp.filter(
      ({ value }, index) => !ids.includes(value, index + 1),
    );
    toRecipients = filtered;
    ccRecipients =
      actionType === 'reply' ? [] : formatEmailAddresses(data.cc_email);
    bccRecipients =
      actionType === 'reply' ? [] : formatEmailAddresses(data.bcc_email);
    // ccRecipients = filtered;
    replyToMsgId = data.msg_id;
    emailId = data.email_id;
    if (actionType === 'forward') {
      toRecipients = [];
      ccRecipients = [];
      bccRecipients = [];
      attachments = data.documents;
      pretext = `<div style="color: grey;">
      ---------- Forwarded message ---------<br>
      From: <span dir="auto">${data.from_email}</span>
      <br>
      Date: ${data.date}
      <br>
      Subject: ${subject}
      <br>
      To:  ${data.to_email}<br>
    </div>`;
    } else if (actionType === 'reply') {
      const date =
        data.date || data.email_date
          ? format(new Date(data.email_date), 'PPpp')
          : '';
      pretext = `<div style="color: grey;">On ${date} ${data.from_email} wrote:</div>`;
    }
    body = `<div></div>${pretext}<blockquote style="color: grey; border-left: 2px solid #f1f1f1; padding-left: 20px">${data.content}</blockquote>`;
  }
  return {
    toRecipients,
    ccRecipients,
    bccRecipients,
    subject,
    body,
    replyToMsgId,
    draftId,
    attachments,
    emailId,
  };
};

// this is for sending data in user_emails api
export const getFormattedData = (state) => {
  let toRecipients = '';
  let ccRecipients = '';
  let bccRecipients = '';
  state.toRecipients.map((each) => {
    if (toRecipients.length) {
      toRecipients += `,${each.value}`;
    } else {
      toRecipients = each.value;
    }
    return each;
  });
  state.ccRecipients.map((each) => {
    if (ccRecipients.length) {
      ccRecipients += `,${each.value}`;
    } else {
      ccRecipients = each.value;
    }
    return each;
  });
  state.bccRecipients.map((each) => {
    if (bccRecipients.length) {
      bccRecipients += `,${each.value}`;
    } else {
      bccRecipients = each.value;
    }
    return each;
  });
  const toField = 'to_recipient';
  // if (state.connectedApp && state.connectedApp === 'outlookemail' && state.viewContext === 'forward') {
  //   toField = 'frwd_to';
  // }
  return {
    [toField]: toRecipients,
    cc_recipient: ccRecipients,
    bcc_recipient: bccRecipients,
    subject: state.subject,
    reply_to_msg_id: state.replyToMsgId,
    draft_id: state.draftId,
    attachments: state.attachments,
    email_id: state.emailId,
  };
};

export const validateSaveEmailFormattedData = (saveStatus, formattedData) => {
  const errors = [];

  if (saveStatus === 'Sent') {
    const hasAtLeastOneRecipient = [
      formattedData.to_recipient,
      formattedData.bcc_recipient,
      formattedData.cc_recipient,
    ].some(Boolean);

    if (!hasAtLeastOneRecipient) {
      errors.push('Recipient address required');
    }
  }

  return errors;
};

export const validateFormData = (ps) => {
  const {
    subject,
    toRecipients,
    ccRecipients,
    bccRecipients,
    showCCInput,
    showBCCInput,
    errors: previousErrors,
  } = ps;
  const errors = { ...previousErrors };
  const allRecipients = [...toRecipients, ...ccRecipients, ...bccRecipients];
  if (!subject) {
    errors.subject = 'Please enter a subject';
  } else delete errors.subject;
  if (!allRecipients.length) {
    errors.toRecipients = 'Please add atleast one recipient';
  } else delete errors.toRecipients;
  if (showCCInput && !ccRecipients.length && !toRecipients.length) {
    errors.ccRecipients = 'Please add atleast one recipient';
  } else delete errors.ccRecipients;
  if (showBCCInput && !bccRecipients.length && !toRecipients.length) {
    errors.bccRecipients = 'Please add atleast one recipient';
  } else delete errors.bccRecipients;
  return errors;
};

export const download = (attachment, textInput) => {
  const element = document.createElement('a');
  element.setAttribute(
    'href',
    `data:${attachment.content_type};base64,${textInput}`,
  );
  element.setAttribute('download', attachment.file_name);
  document.body.appendChild(element);
  element.click();
  // document.body.removeChild(element);
};

export const timeAgo = (emailDate) => {
  const diff = (new Date() - new Date(emailDate)) / 1000;
  const hours = Math.floor(diff / 60 / 60);
  if (hours > 24 || new Date().getDate() > new Date(emailDate).getDate())
    return dayjs(emailDate).format('MM/DD/YY');
  return dayjs(emailDate).format('h:mm a');
};

export const emailGetSize = (attachment) =>
  attachment.file_size ? attachment.file_size / 1024 : null;

export const emailTrimSize = (getSize) => (getSize ? getSize.toFixed(2) : null);

export const emailFileSize = (trimSize) => (trimSize ? `${trimSize} KB` : null);
export const styleElement =
  '<style>body{font-family:"Source Sans Pro",sans-serif;-webkit-font-smoothing:antialiased;color:#212b36;line-height:1.3}#pdf-logo{height:32px}#pdf-logo img{height:32px}p{font-size:15px;margin:0;line-height:18px;color:#212b36}b,strong{font-weight:600}h5{font-size:20px;font-weight:500;margin:0}.text-dark{color:#919eab!important}p b{color:#000}.div-top{margin-top:20px}.due-date{text-align:right;float:right}.left-content{float:left}.div-top b{margin-right:10px;display:inline-block}.Date{float:right;margin-top:18px}hr{border:none;height:1px;background:#d2d2d2;color:#d2d2d2;margin-top:10px}.highlight{color:#299ac4}.bottom{margin:20px 0 0}li::before{content:"•"}.no-padding{padding:0;border:0}.bottomBorder{width:100%;height:1px;border-bottom:1px solid #dfe3e8}.each_thread{border-bottom:1px solid #dfe3e8;padding:.9rem 0;opacity:.9}.each_thread:last-child{border:0}.print-comment-sections{padding:10px 0;break-inside:auto}.print-comment-sections .comment_content{color:#212b36;font-size:15px}.text-right{float:right}.right-panel-top{border-bottom:solid 1px #e8ebf0;margin-bottom:10px}.visibility{visibility:hidden;width:1px;height:1px;position:absolute}.task-action-icons{margin-top:10px}</style>';

export const getFormattedEditorDescription = (
  actionType,
  emailData,
  originalContent,
  emailType = 'reply',
) => {
  const emailBody = getInitialState(
    { ...emailData, content: originalContent },
    emailType,
  );
  const formattedContent =
    actionType === 'respond_with_message' ||
    actionType === 'create_task' ||
    actionType === 'create_note'
      ? emailBody.body
      : originalContent;

  return formattedContent;
};

export const getEmailToolbarActions = (emailDetailMode, inLinkedSection) => {
  const emailToolBarActions = [
    {
      label: 'Archive',
      value: 'done',
    },
    {
      label: 'Reply',
      value: 'reply',
    },
    {
      label: 'Reply With Liscio Message',
      value: 'reply_with_liscio_message',
    },
    {
      label: 'Create Task',
      value: 'create_task',
    },
    {
      label: 'Mark As Unread',
      value: 'mark_as_unread',
    },
    {
      label: 'Print',
      value: 'print',
    },
  ];

  const emailToolBarFullScreenAction = [
    {
      label: 'Full View',
      value: 'full_view',
    },
  ];

  const baseActions =
    emailDetailMode === 'split'
      ? [...emailToolBarActions, ...emailToolBarFullScreenAction]
      : emailToolBarActions;

  const filteredActions = inLinkedSection
    ? baseActions.filter((action) => action.value !== 'mark_as_unread')
    : baseActions;

  return filteredActions;
};

export const htmlContent = (item) => {
  let htmlCon = '';
  if (item.content.includes('html')) {
    htmlCon = item.content
      .replace(/^.*>/, '')
      .replace(/&nbsp;/g, ' ')
      .replace(/&quot;/g, '"')
      .replace(/&amp;/g, '&');
  } else {
    htmlCon = item.content
      .replace(/&nbsp;/g, ' ')
      .replace(/&quot;/g, '"')
      .replace(/&amp;/g, '&');
  }
  return htmlCon;
};

export const getContactByFromEmailValues = (
  fromEmail,
  fromEmailDisplayName,
) => {
  const contact = extractContactFromEmailString(fromEmail);
  return {
    name: fromEmailDisplayName || contact?.name,
    email: contact?.email,
  };
};

export const makeDoneActionFilterFunction =
  (email, inLinkedSection = false) =>
  (action) => {
    if (email?.user_thread_status === 'done' || inLinkedSection) {
      return action?.value !== 'done';
    }
    return true;
  };

export function mungeEmail(email) {
  const fromContact = getContactByFromEmailValues(
    email.from_email,
    email.from_email_display_name,
  );
  const toContacts = extractContactsMultipleEmailsString(email.to_email);

  return {
    ...email,
    _id: email.id,
    recipients:
      email.from_email !== null
        ? [
            {
              name: email.from_email.split(' <')[0].replace(/"/gi, ''),
              email: email.from_email.split(' <')[1],
            },
          ]
        : [],
    isPinned: false,
    userThumbnail: '',
    subject: email.subject,
    attachments: [],
    labels: email.labels,
    updatedAt: email.email_date,
    thread_id: email.thread_id,
    fromContact,
    toContacts,
  };
}
