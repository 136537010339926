import axios from 'axios';
import { KARBON_INTEGRATION_STATUS } from 'constants/constants';
import { KarbonStatus, KarbonIntegrationStatusResponse } from './karbon.types';

export const getKarbonIntegrationStatus = async () => {
  const result = await axios.get<KarbonIntegrationStatusResponse>(
    KARBON_INTEGRATION_STATUS,
  );
  return result.data;
};

export const getKarbonActivationStatus = async () => {
  const result = await getKarbonIntegrationStatus();
  // Note: In-Progress setups are not considered "Active"
  return result?.data?.status === KarbonStatus.ACTIVE;
};
