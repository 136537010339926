import React, { useCallback, useMemo, useState } from 'react';
import MenuUnstyled from '@mui/base/MenuUnstyled';
import MenuItemUnstyled, {
  menuItemUnstyledClasses,
} from '@mui/base/MenuItemUnstyled';
import { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled';
import Divider from '@mui/material/Divider';
import PopperUnstyled from '@mui/base/PopperUnstyled';
import styled from '@emotion/styled';
import CustomImage from './CustomImage';

const blue = {
  100: '#DAECFF',
  200: '#99CCF3',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  900: '#003A75',
};

const grey = {
  100: '#E7EBF0',
  200: '#E0E3E7',
  300: '#CDD2D7',
  400: '#B2BAC2',
  500: '#A0AAB4',
  600: '#6F7E8C',
  700: '#3E5060',
  800: '#2D3843',
  900: '#1A2027',
};

const StyledListbox = styled.ul`
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 5px 0;
  margin: 10px 8px;
  min-width: 200px;
  background-color: #ffffff;
  border: 2px solid #dfe3e8;
  border-radius: 0.25em;
  color: ${grey[600]};
  overflow: auto;
  outline: 0px;
`;

const StyledMenuItem = styled(MenuItemUnstyled)`
  list-style: none;
  padding: 5px 8px;
  border-radius: 0;
  cursor: pointer;
  user-select: none;

  &:last-of-type {
    border-bottom: none;
  }

  &.${menuItemUnstyledClasses.focusVisible} {
    outline: 3px solid ${blue[200]};
    background-color: ${grey[100]};
    color: ${grey[900]};
  }

  &.${menuItemUnstyledClasses.disabled} {
    color: ${grey[400]};
  }

  &:hover:not(.${menuItemUnstyledClasses.disabled}) {
    background-color: ${grey[100]};
    color: ${grey[900]};
  }
  &.MenuTag {
    text-align: center;
    background-color: #e7ebf0;
    color: #919eab;
    text-transform: uppercase;
    display: inline-block;
    padding: 3px 7px;
    font-size: 12px;
    font-weight: 600;
    margin-left: 8px;
    margin-top: 4px;
    margin-bottom: 2px;
    &.disabled {
      pointer-events: none;
    }
  }
`;

const TriggerButton = styled.button`
  font-size: 0.875rem;
  box-sizing: border-box;
  background: transparent;
  border: 1px solid transparent;
  border-radius: 0.75em;
  margin: 0;
  padding: 1px;
  color: ${grey[900]};

  &:hover {
    background: transparent;
    border: 1px solid transparent;
  }

  &.${buttonUnstyledClasses.focusVisible} {
    outline: 3px solid ${grey[300]};
  }
  &.ctaClass {
    padding: 0 0 0 2px;
    border: none;
  }
`;

const Popper = styled(PopperUnstyled)`
  z-index: 1052; /* because modal z-index is 1050 */
`;

const menuUnstyledComponents = { Root: Popper, Listbox: StyledListbox };
const menuUnstyledComponentsProps = { listbox: { id: 'simple-menu' } };

export default function Menu({
  menuItems,
  handleMenuItemClick,
  menuComponent,
  menuComponentBtnCls,
  menuComponentId,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const buttonRef = React.useRef(null);
  const menuActions = React.useRef(null);
  const elementId = menuComponentId
    ? `menuButton__tigger-${menuComponentId}`
    : null;

  const handleButtonClick = useCallback((event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setIsOpen((currentOpenState) => !currentOpenState);
  }, []);

  const handleButtonKeyDown = useCallback((event) => {
    if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
      event.preventDefault();
      if (event.key === 'ArrowUp') {
        menuActions.current?.highlightLastItem();
      }
    }
  }, []);

  const close = useCallback(() => {
    setIsOpen(false);
    buttonRef.current.focus();
  }, []);

  const createHandleMenuClick = useCallback(
    (menuItem) =>
      (e = {}) => {
        handleMenuItemClick(menuItem);
        close();
        if (e?.stopPropagation) {
          e.stopPropagation();
        }
      },
    [],
  );

  const menuContent = useMemo(
    () =>
      menuItems.flat().map((e, index) => {
        if (e && e.label && !e.header) {
          return (
            <StyledMenuItem onClick={createHandleMenuClick(e)} key={e.value}>
              {e.thumbnail &&
                <CustomImage
                  className="mr-2"
                  src={e.thumbnail}
                  style={{ height: '15px' }}
                />}
              {e.label}
            </StyledMenuItem>
          );
        }
        if (e && !e.label) {
          // eslint-disable-next-line react/no-array-index-key
          return <Divider key={`divider${index}`} />;
        }
        if (e && e.label && e.header) {
          return (
            <StyledMenuItem className="MenuTag disabled" key={e.value}>
              {e.label}
            </StyledMenuItem>
          );
        }
        return null;
      }),
    [createHandleMenuClick, menuItems],
  );

  const getAnchorEl = () => {
    if (!isOpen) {
      return null;
    }
    if (menuComponentId) {
      return document.getElementById(elementId);
    }
    return anchorEl;
  };

  return (
    <>
      <TriggerButton
        type="button"
        className={menuComponentBtnCls || ''}
        onClick={handleButtonClick}
        onKeyDown={handleButtonKeyDown}
        ref={buttonRef}
        aria-controls={isOpen ? 'simple-menu' : undefined}
        aria-expanded={isOpen || undefined}
        aria-haspopup="menu"
        id={elementId}
      >
        {menuComponent || <i className="icon-more" />}
      </TriggerButton>

      <MenuUnstyled
        actions={menuActions}
        open={isOpen}
        onClose={close}
        anchorEl={getAnchorEl}
        components={menuUnstyledComponents}
        componentsProps={menuUnstyledComponentsProps}
      >
        {menuContent}
      </MenuUnstyled>
    </>
  );
}
