/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { clearAuthSession, getAuthSession, setAuthSession } from 'auth/session';
import Axios from 'axios';
import {
  LOGOUT,
  SWITCH_BACK,
  ACT_AS_KEY,
  LOGOUT_V5,
} from 'constants/constants';
import { removeCookie, AlertMessage } from 'utilities/utils';

export async function handleLogout(fro = '', useV5 = false) {
  try {
    if (useV5) {
      await Axios.delete(LOGOUT_V5);
    } else {
      await Axios.post(LOGOUT);
    }
  } catch {
    // Prevent api errors from clearing the user session and "glitch looping"
  }
  const logoutInterval = setInterval(() => {
    clearAuthSession();
    removeCookie({}, 'redirect_url');
    localStorage.clear();
    if (!getAuthSession() && localStorage.length === 0) {
      if (fro === 'session_timeout') {
        window.location.href = '/login?session_timeout=true';
      } else {
        window.location.href = '/login';
      }
      clearInterval(logoutInterval);
    }
  }, 50);
}

export async function switchBack(useV5 = false) {
  const switchFrom = localStorage.getItem('switch_from') || '';
  const data = { switch_from: switchFrom };
  try {
    const request = await Axios.post(SWITCH_BACK, data).then((res) => res.data);
    if (request.status === 200) {
      localStorage.clear();
      setAuthSession({ auth_token: request.auth_token });
      localStorage.removeItem('loggedInType');
      localStorage.removeItem('switch_from');
      localStorage.removeItem(ACT_AS_KEY);
      window.location.href = '/';
    } else if (request.status === 201) {
      AlertMessage(
        'error',
        'Your session has been expired, Please login again',
        3000,
      );
      handleLogout('', useV5);
    }
  } catch (error) {
    AlertMessage('error', 'Something went wrong', 3000);
  }
}
