import { fetchFirmLicense, updateFirmLicense } from 'api/admin-billing';
import { useGraphQL, useMutationGraphQL } from './useGraphQL';

export function useAdminBillingQuery() {
  return useGraphQL(fetchFirmLicense);
}

export function useUpdateFirmLicense() {
  return useMutationGraphQL(updateFirmLicense);
}
