import SMSReminderLink from 'components/SMS/components/SMSReminderLink/SMSReminderLink';
import { WorkflowRequestsWorkflowRequestFragment } from 'gql/graphql';
import { generateRequestReminder } from 'components/SMS/utils/generateSMSReminderMessages';

interface RequestsReminderProps {
  requestId: string;
  contacts: WorkflowRequestsWorkflowRequestFragment['assignedContacts'];
  variant?: 'button' | 'iconbutton';
}

export const RequestsReminder = ({
  requestId,
  contacts,
  variant = 'button',
}: RequestsReminderProps) => {
  if (Array.isArray(contacts) && contacts?.length > 0) {
    return (
      <SMSReminderLink
        variant={variant}
        smsContacts={
          contacts?.map((contact) => ({
            contact_sms_enabled: contact.smsOptIn || false,
            contact_sms_phone_number: contact.smsNumber!,
            contact_sms_first_name: contact.firstName,
            contact_sms_last_name: contact.lastName,
            ...contact,
          })) || []
        }
        reminder={generateRequestReminder(requestId)}
      />
    );
  }

  return null;
};
