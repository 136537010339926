/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-script-url */
/* eslint-disable react/jsx-no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-access-state-in-setstate */
import React, { Component } from 'react';
import axios from 'axios';
import Interweave from 'interweave';
import Select, { components } from 'react-select';
import { Button } from 'reactstrap';
import { generateUniqueHash } from 'common/NotesUtilities';
import { Scrollbars } from 'react-custom-scrollbars-2';
import * as TaskApi from 'api/TaskApi';
import {
  SelectFieldWithAvatar,
  SelectField,
  SelectTemplateFieldOption,
  selectStyles,
  selectTheme,
} from 'common/CustomFields';
import ConfirmationModal from 'common/ConfirmationModal';
import { AlertMessage, getCurrentPath, getStorageData } from 'utilities/utils';
import TaskFileUpload from 'components/Files/TaskFileUpload';
import {
  REPLACE_AGREEMENT_URL,
  GET_TEMPLATES_FOR_CREATION,
  CREATE_DOC_FROM_TEMPLATE_IDS,
} from 'constants/constants';
import { withRouter } from 'utilities/withRouterWrapper';
import TinymceEditor from 'components/Emails/components/TinymceEditior';
import { format } from 'date-fns';
import CommonFields from 'components/Tasks/Create/CommonFields';
import transformInputFieldinInterweave from 'components/Tasks/InterweaveUtilities';
import CommonFieldsEmail from 'components/Tasks/Create/CommonFieldsEmail';
import SignatureModal from './SignatureModal';
import TaskFooterButtons from './TaskFooterButtons';

const noneStyle = { display: 'none' };
const blockStyle = { display: 'block' };
class GetSignature extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stateValues: {},
      errors: {},
      taskTitle: '',
      taskStatus: '',
      documents: [],
      comment: '',
      stafId: '',
      stafName: '',
      partnerTitle: '',
      doctype: '',
      template: '',
      content: '',
      contentAfterChange: '',
      stafPerson: [],
      templates: [],
      modalState: 'Edit',
      fees: 0,
      loading: false,
      templateEdited: false,
      templateChecked: false,
      contentChangedInEdit: false,
      dataUpdated: false,
      uniqueId: generateUniqueHash(5),
      selectedOption: null,
    };
    this.selectInputRef = React.createRef();
  }

  componentDidMount() {
    const { data, taskDetail, location } = this.props;

    const path = getCurrentPath(location);

    TaskApi.getStafPerson(this);

    if (data.viewContext && path !== '/task/detail/:id') {
      this.setState({
        taskTitle: taskDetail && taskDetail.subject ? taskDetail.subject : '',
        documents:
          taskDetail && taskDetail.documents ? taskDetail.documents : [],
        comment:
          taskDetail && taskDetail.description ? taskDetail.description : '',
      });
    } else if (data.viewContext && path === '/task/detail/:id') {
      this.setState({
        taskTitle:
          taskDetail && taskDetail.emailSubject ? taskDetail.emailSubject : '',
        documents:
          taskDetail && taskDetail.emailDocuments
            ? taskDetail.emailDocuments
            : [],
        comment:
          taskDetail && taskDetail.emailDescription
            ? taskDetail.emailDescription
            : '',
      });
    }
    this.getTemplates();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.taskDetail.description !== this.props.taskDetail.description
    ) {
      this.setState({ comment: this.props.taskDetail.description });
    }
  }

  UNSAFE_componentWillReceiveProps() {
    const { data } = this.props;
    if (
      Object.keys(this.props.taskDetail).length &&
      data.isEdit &&
      !data.changedOnce
    ) {
      const detail = this.props.taskDetail;
      this.setState({
        taskTitle: detail.subject,
        comment: detail.description,
        documents: detail.documents,
        taskStatus: detail.status,
        doctype: detail.template_type,
        template: detail.template_id,
        content: detail.agreement,
        fees: detail.fee,
        stafId: detail.partner ? detail.partner : '',
        stafName: detail.partner_name,
        templateChecked: true,
      });
      TaskApi.getEngagementLetters(this, detail.template_type);
    }
  }

  componentWillUnmount() {
    const {
      data: { externalDocs },
    } = this.props;
    let { documents } = this.state;
    if (externalDocs && externalDocs.length) {
      documents = [...documents, ...externalDocs];
    }
    if (documents.length) {
      const docIds = [];
      let x;
      for (x = 0; x < documents.length; x += 1) {
        docIds.push(documents[x].id);
      }
      const data = { doc_ids: docIds };
      TaskApi.cancelDocuments(this, data, 'tasks');
    }
  }

  // show template after selection in selected document
  getselecteddoc() {
    const {
      stateValues,
      doctype,
      template,
      stafName,
      partnerTitle,
      fees,
      content,
      templateEdited,
    } = this.state;
    const { data, taskDetail, params } = this.props;
    if (stateValues.accountId && stateValues.userId && doctype && template) {
      const tempdata = {
        // id: template,
        client_name: stateValues.contactName,
        client_title: stateValues.clientTitle,
        account_id: stateValues.accountId,
        contact_id: stateValues.userId,
      };
      if (doctype === 'engagement_letter') {
        tempdata.staf_person = stafName;
        tempdata.partner_title = partnerTitle;
        tempdata.client_fee = fees;
      }
      // if template is edited send content else send id of template
      if (templateEdited) {
        tempdata.content = content;
      } else {
        tempdata.id = template;
      }
      // if in edit mode old template id is equal to new template send task id
      if (data.isEdit && template === taskDetail.template_id) {
        const { id } = params;
        tempdata.task_id = id;
      }
      TaskApi.getTemplateAndReplaceVars(this, tempdata);
    }
  }

  setTasktitle = (value) => {
    this.setState({
      taskTitle: value,
      dataUpdated: true,
    });
  };

  getTemplateDocsId = () => {
    const mydata = {};
    if (this.state.templateDocuments.length) {
      mydata.template_doc_ids = [];
      let x;
      for (x = 0; x < this.state.templateDocuments.length; x += 1) {
        mydata.template_doc_ids.push(this.state.templateDocuments[x].id);
      }
    }
    mydata.template_type = 'task_template';
    if (this.state.templateDocuments.length) {
      this.setState({ loading: true });
      const request = axios.post(CREATE_DOC_FROM_TEMPLATE_IDS, mydata);
      request
        .then((response) => {
          this.setState({ loading: false });
          if (response.data.status === 200) {
            const documents = [...this.state.documents];
            if (documents.length) {
              this.setState({
                documents: response.data.documents,
                templateDocuments: [],
                dataUpdated: true,
              });
              // let x;
              // for (x = 0; x < documents.length; x += 1) {
              //   const documnt = { ...documents[x] };
              //   documnt.id = response.data.doc_ids[x];
              //   documents[x] = documnt;
              //   this.setState({ documents });
              //   if (x + 1 === documents.length) {
              //     this.setState({ templateDocuments: [], dataUpdated: true });
              //   }
              // }
            }
          } else {
            AlertMessage('error', response.data.message, 1500);
          }
        })
        .catch(() => {
          this.setState({ loading: false });
        });
    }
  };

  getTemplates() {
    this.setState({ loading: true });
    axios
      .get(`${GET_TEMPLATES_FOR_CREATION}?template_type=task_template`)
      .then((res) => {
        this.setState({ loading: false });
        if (res.data && res.data.data && res.data.data.length) {
          const { data } = res.data;
          this.setState({
            templateOptions: data,
            loading: false,
          });
        } else {
          this.setState({
            templateOptions: [],
            loading: false,
          });
        }
      })
      .catch(() => {
        this.setState({
          templateOptions: [],
          loading: false,
        });
      });
  }

  inputRenderer = (inputProps) => (
    <components.Input
      {...inputProps}
      value={this.state.titleChange}
      className="Select-input"
    />
  );

  templateDropdown = (selectedOption) => {
    const { taskTitle, comment, documents, warningMsgLost } = this.state;
    let commentExist = false;
    if (comment && comment.length) {
      commentExist = true;
    }
    if (
      (taskTitle || commentExist || documents.length) &&
      !warningMsgLost &&
      (!selectedOption || (selectedOption && !taskTitle))
    ) {
      this.setState({ warningMsgLost: true, selectedTemplate: selectedOption });
    } else if (selectedOption && selectedOption !== null) {
      const { content, value } = selectedOption;
      const coment = content;
      this.setState({
        selectedOption,
        taskTitle: value,
        titleChange: '',
        comment: coment,
        uniqueId: generateUniqueHash(5),
        warningMsgLost: false,
        selectedTemplate: null,
        templateDocuments:
          selectedOption.documents && selectedOption.documents.length
            ? selectedOption.documents
            : [],
        documents:
          selectedOption.documents && selectedOption.documents.length
            ? selectedOption.documents
            : [],
      });
    } else {
      this.setState(
        {
          selectedOption: null,
          taskTitle: '',
          titleChange: '',
          comment: '',
          uniqueId: generateUniqueHash(5),
          warningMsgLost: false,
          selectedTemplate: null,
          documents: [],
          templateDocuments: [],
        },
        () => {
          const node = this.selectInputRef.current;
          if (node) {
            node.state.inputValue = '';
          }
        },
      );
    }
  };

  templateChange = (input, action) => {
    const val =
      action.action === 'input-change' ? input : action.prevInputValue;
    if (val || (!val && this.state.titleChange)) {
      this.setState({
        taskTitle: val,
        titleChange: val,
      });
    }
  };

  // handle task title change
  handleChange = (e) => {
    this.setState({
      errors: {},
      taskTitle: e.target.value,
      dataUpdated: true,
    });
  };

  // handle partner change (staf person)
  handleStaf = (val) => {
    this.setState(
      {
        stafId: val.value,
        stafName: val.label,
        partnerTitle: val.partner_title,
        errors: {},
        contentChangedInEdit: true, // in edit mode if partner changed
        dataUpdated: true,
      },
      () => {
        this.getselecteddoc();
      },
    );
  };

  // handle document type change
  handleDoctype = (val) => {
    this.setState(
      {
        doctype: val.value,
        doctypeName: val.label,
        template: '',
        templates: '',
        stafId: '',
        fees: '',
        content: '',
        errors: {},
        dataUpdated: true,
      },
      () => {
        TaskApi.getEngagementLetters(this, val.value);
      },
    );
  };

  // handle document change
  handleTemplate = (val) => {
    this.setState(
      {
        template: val.value,
        templateName: val.label,
        errors: {},
        templateChecked: this.props.data.isEdit,
        templateEdited: false,
        contentChangedInEdit: true, // in edit mode if template changed (send param get sign true)
        dataUpdated: true,
      },
      () => {
        this.getselecteddoc();
      },
    );
  };

  // draft editor description change
  handleCommentChange = (value) => {
    this.setState({
      comment: value,
      dataUpdated: true,
    });
    if (this.state.templateDocuments && this.state.templateDocuments.length) {
      this.getTemplateDocsId();
    }
  };

  feeChange = (e) => {
    e.target.value = this.formatFee(e.target.value);
    this.setState({
      fees: e.target.value,
      errors: {},
      contentChangedInEdit: true,
      dataUpdated: true,
    });
  };

  formatFee(input) {
    if (input === undefined) {
      return input;
    }
    if (input === null) {
      return undefined;
    }
    let val = input.replace(/\D/g, '');
    let newVal = '';
    if (val.length > 3) {
      const lastThree = val.substr(val.length - 3);
      val = val.substring(0, val.length - 3);
      if (val.length > 3) {
        val = this.formatFee(val);
      }
      val = `${val},${lastThree}`;
    }
    newVal += val;
    return newVal;
  }

  // update current component state with commonfields comp state
  stateChange = (value, dataUpdated = false, changetype = '') => {
    const stateValues = { ...this.state.stateValues, ...value };
    this.setState({
      stateValues,
      dataUpdated,
    });
    if (changetype === 'contact' || changetype === 'account') {
      this.resetform();
    }
  };

  resetform() {
    this.setState({
      doctype: '',
      template: '',
      templates: '',
      stafId: '',
      stafName: '',
      partnerTitle: '',
      fees: '',
      content: '',
      templateChecked: false,
      templateEdited: false,
    });
  }

  emptyError = () => {
    this.setState({ errors: {} });
  };

  handleCancel = () => {
    const { stateValues, dataUpdated } = this.state;
    if (stateValues.isChanged || dataUpdated) {
      this.setState({ viewModal: true });
    } else {
      this.props.goBack();
    }
  };

  hideViewModal = () => {
    this.setState({ viewModal: false });
  };

  nextPage = () => {
    const { state } = this.props.location;
    if (
      this.props.data.isEdit &&
      state !== undefined &&
      state.from === '/task/detail'
    ) {
      const { params } = this.props;
      this.props.navigate(`/task/detail/${params.id}`);
    } else if (state && state.from === '/task/new') {
      this.props.navigate(-2);
    } else {
      // need this for when task is created from email
      this.props.goBack();
    }
  };

  handleContentChange = (newContent) => {
    this.setState({
      contentAfterChange: newContent,
      templateEdited: true,
      contentChangedInEdit: true,
    });
  };

  // save or update document/ template and updating it
  SaveDoc = () => {
    this.setState({
      content: this.state.contentAfterChange,
      showTemplate: false,
      modalState: 'Edit',
      dataUpdated: true,
    });
    // destroy CKEDITOR instances .......
    if (window.CKEDITOR && window.CKEDITOR.instances) {
      Object.keys(window.CKEDITOR.instances).map((item) => {
        window.CKEDITOR.instances[item].destroy();
        return true;
      });
    }
  };

  handleValidation() {
    const { stateValues, taskTitle, doctype, template, stafId, fees } =
      this.state;
    const err = {};
    let formIsValid = true;
    if (
      stateValues.accounts &&
      stateValues.accounts.length &&
      (!stateValues.accountId || stateValues.accountId === '')
    ) {
      formIsValid = false;
      err.account_error = 'Please select account';
    }
    if (stateValues.contactId === '' || !stateValues.contactId) {
      formIsValid = false;
      err.contact_error = 'Please select contact';
    }
    if (stateValues.ownerId === '' || !stateValues.ownerId) {
      formIsValid = false;
      err.owner_error = 'Please select task owner';
    }
    if (taskTitle === '' || !taskTitle) {
      formIsValid = false;
      err.taskTitle_error = 'Enter task title';
    }
    if (doctype === '' || !doctype) {
      formIsValid = false;
      err.doctype_error = 'Please select document type';
    }
    if (template === '' || !template) {
      formIsValid = false;
      err.document_error = 'Please select document';
    }
    if (doctype === 'engagement_letter' && (!stafId || stafId === '')) {
      formIsValid = false;
      err.partner_error = 'Please select partner';
    }
    if (doctype === 'engagement_letter' && (!fees || fees === '')) {
      formIsValid = false;
      err.fees_error = 'Required';
    }
    this.setState({ errors: err });
    return formIsValid;
  }

  // show template modal
  previewTemplate = () => {
    const component = this;
    const { params } = this.props;
    const taskId = params.id;
    if (this.props.data.isEdit && this.props.taskDetail.is_signed) {
      const request = axios.get(`${REPLACE_AGREEMENT_URL}/${taskId}`);
      request.then((response) => {
        component.setState({ templatePdf: response.data.url }, () => {
          component.setState({ showTemplate: true });
        });
      });
    } else {
      component.setState({
        showTemplate: true,
        contentAfterChange: this.state.content,
      });
    }
    this.setState({ templateChecked: true });
  };

  toggleModal = () => {
    this.setState({
      modalState: this.state.modalState === 'Edit' ? 'Preview' : 'Edit',
    });
  };

  closeTemplate = () => {
    this.setState({ showTemplate: false, modalState: 'Edit' });
    // destroy CKEDITOR instances ....
    if (window.CKEDITOR && window.CKEDITOR.instances) {
      Object.keys(window.CKEDITOR.instances).map((item) => {
        window.CKEDITOR.instances[item].destroy();
        return true;
      });
    }
  };

  submitForm = (e) => {
    e.preventDefault();
    const {
      location,
      data: { emailId },
    } = this.props;
    const path = getCurrentPath(location);
    const { stateValues, doctype, taskTitle, comment, contentChangedInEdit } =
      this.state;
    if (this.handleValidation()) {
      let mydata = {};
      // if component is in edit state --------------------------------------
      if (this.props.data.isEdit) {
        mydata.cpa_id = stateValues.cpaId;
        mydata.subject = taskTitle;
        mydata.due_by = format(stateValues.startDate, 'dd/MM/yyyy');
        if (contentChangedInEdit) {
          mydata.get_signature = true;
        }
        if (stateValues.ownerChanged) {
          mydata.owner_id = stateValues.ownerId;
        } // edit part ends here ----------------------------------------------
      } else {
        if (path === '/contactdetails/task/:id') {
          mydata.assigne_type = stateValues.contactDetails.contact.cpa_user_id
            ? 'user'
            : 'contact';
          mydata.assigned_to_user = stateValues.contactDetails.contact
            .cpa_user_id
            ? stateValues.contactDetails.contact.cpa_user_id
            : stateValues.contactDetails.contact.id;
        } else {
          mydata.assigne_type = stateValues.assigneeType;
          mydata.assigned_to_user = stateValues.contactId;
        }
        mydata.cpa_id = stateValues.cpaId;
        mydata.owner_id = stateValues.ownerId;
        mydata.for_account = stateValues.accountId;
        mydata.subject = this.state.taskTitle;
        mydata.due_by = format(stateValues.startDate, 'MM/dd/yyyy');
        mydata.task_type_key = this.props.data.taskTypeKey;
        mydata.task_type_value = this.props.data.taskTypeValue;
      }
      if (stateValues.recurring_properties) {
        mydata = { ...mydata, ...stateValues.recurring_properties };
      }
      // get signature params  ------------------------------------------------
      if (doctype === 'consent_to_release') {
        mydata.content = this.state.content;
        mydata.template_id = this.state.template;
      } else {
        mydata.content = this.state.content;
        mydata.partner = this.state.stafId;
        mydata.fee = this.state.fees;
        mydata.template_id = this.state.template;
      }
      if (typeof comment === 'string' && comment.length) {
        mydata.description = comment;
      } else {
        mydata.description = '';
      }

      if (emailId) {
        mydata.email_id = emailId;
      }
      if (this.state.documents.length && !this.props.data.isEdit) {
        mydata.doc_ids = [];
        let x;
        for (x = 0; x < this.state.documents.length; x += 1) {
          mydata.doc_ids.push(this.state.documents[x].id);
        }
      } // ------------------------------------------------------------------------
      if (this.props.data.isEdit) {
        const { id } = this.props.params;
        TaskApi.createTask(this, mydata, id, this.props.data.taskTypeKey); // updates task
      } else {
        TaskApi.createTask(this, mydata);
      }
    }
    return false;
  };

  updateDocumentState = (documents) => {
    this.setState({ documents });
  };

  render() {
    let doctype = [];
    // TODO: __HOMEDATA-AUDIT
    const localStorageData = getStorageData();
    if (localStorageData.pref_type_options) {
      doctype = localStorageData.pref_type_options.document_type;
    }
    const cpaLogo = localStorageData ? localStorageData.cpa_logo : '';
    const { taskDetail } = this.props;
    const {
      errors,
      taskTitle,
      taskStatus,
      loading,
      stateValues,
      dataUpdated,
      selectedOption,
      selectedTemplate,
      templateOptions,
    } = this.state;
    const { data } = this.props;

    const recurringDisable =
      stateValues.recurring_properties &&
      stateValues.recurring_properties.recurringDropdown;
    return (
      <div>
        <div id="loading" style={loading ? blockStyle : noneStyle} />
        <div className="miscellaneous" data-testid="getSignatureTestId">
          {this.props?.data?.viewContext === 'email' ? (
            <CommonFieldsEmail
              data={this.props.data}
              statedata={this.state}
              taskDetail={this.props.taskDetail}
              stateChange={this.stateChange}
              emptyError={this.emptyError}
              setTasktitle={this.setTasktitle}
              removeparticularTaskType={this.props.removeparticularTaskType}
              match={this.props.match}
              location={this.props.location}
              params={this.props.params}
              handleTaskTypeChange={this.props.handleTaskTypeChange}
            />
          ) : (
            <CommonFields
              data={this.props.data}
              statedata={this.state}
              taskDetail={this.props.taskDetail}
              stateChange={this.stateChange}
              emptyError={this.emptyError}
              setTasktitle={this.setTasktitle}
              removeparticularTaskType={this.props.removeparticularTaskType}
              location={this.props.location}
              match={this.props.match}
              params={this.props.parama}
            />
          )}
          <div className="row">
            <SelectField
              from={data.viewContext === 'email' && 'email'}
              className={`form-group col-md-${
                data.viewContext === 'email' ? 4 : 6
              } col-12 docsWidth`}
              name="doctype"
              label="Select Document Type *"
              options={doctype}
              handleChange={this.handleDoctype}
              value={
                doctype
                  ? doctype.filter(
                      (option) => option.value === this.state.doctype,
                    )
                  : null
              }
              disabled={this.props.data.isEdit}
              error={errors.doctype_error}
              clearable={false}
              searchable={false}
            />
            <SelectField
              from={data.viewContext === 'email' && 'email'}
              className={`form-group col-md-${
                data.viewContext === 'email' ? 4 : 6
              } col-12 docsWidth`}
              name="template"
              label="Select Document *"
              options={this.state.templates}
              handleChange={this.handleTemplate}
              value={
                this.state.templates
                  ? this.state.templates.filter(
                      (option) => option.value === this.state.template,
                    )
                  : null
              }
              disabled={this.props.data.isEdit && taskDetail.is_signed}
              error={errors.document_error}
              clearable={false}
            />
          </div>
          {this.state.doctype === 'engagement_letter' ? (
            <div className="row">
              <SelectFieldWithAvatar
                name="partner"
                label="Select Partner"
                labelClass="mt-1 pt-2"
                options={this.state.stafPerson}
                handleChange={this.handleStaf}
                value={{ value: this.state.stafId, label: this.state.stafName }}
                disabled={this.props.data.isEdit && taskDetail.is_signed}
                error={errors.partner_error}
                clearable={false}
                searchable={false}
                from={data.viewContext === 'email' && 'email'}
              />
              <div
                className={`col-md-6 col-12 docsWidth form-group form-inline-label align-items-stretch ${
                  errors.fees_error ? 'form-error' : ''
                }`}
              >
                <label htmlFor="taskTitle" className="mt-1 pt-2">
                  Fees
                </label>
                <div className="labelValue">
                  <input
                    value={this.state.fees}
                    name="fees"
                    onChange={(e) => this.feeChange(e)}
                    onFocus={(e) => e.target.select()}
                    onBlur={() => this.getselecteddoc()}
                    onKeyUp={() => this.getselecteddoc()}
                    disabled={this.props.data.isEdit && taskDetail.is_signed}
                    className="form-control"
                  />
                  <span className="error_label">{errors.fees_error}</span>
                </div>
              </div>
            </div>
          ) : null}
          <div className="row">
            <div
              className={`form-group col-12${
                errors.taskTitle_error
                  ? ' form-error cstmClr msgCustmClr'
                  : ' cstmClr msgCustmClr'
              }`}
            >
              <label htmlFor="taskTitle">Subject</label>
              <div className="labelValue customEditor boxResize flWdth">
                {(selectedOption && selectedOption !== null) ||
                (!selectedOption &&
                  selectedOption === null &&
                  taskTitle &&
                  !this.state.titleChange) ||
                data.isEdit ? (
                  <input
                    autoComplete="off"
                    disabled={taskStatus === 'Closed'}
                    name="taskTitle"
                    className="form-control"
                    id="taskTitle"
                    type="text"
                    value={taskTitle}
                    maxLength="200"
                    onChange={this.handleChange}
                  />
                ) : (
                  <Select
                    name="taskTitle"
                    placeholder=""
                    ref={this.selectInputRef}
                    className="select-custom-class slctTemp"
                    value={selectedOption}
                    onChange={this.templateDropdown}
                    options={templateOptions}
                    optionComponent={SelectTemplateFieldOption}
                    inputValue={this.state.titleChange}
                    onInputChange={this.templateChange}
                    tabSelectsValue={false}
                    onBlurResetsInput={false}
                    onCloseResetsInput={false}
                    onSelectResetsInput={this.state.titleChange} // false shows
                    noResultsText=""
                    isDisabled={data.isEdit}
                    styles={selectStyles}
                    theme={selectTheme}
                    classNamePrefix="custom-react-select"
                    components={{
                      Option: SelectTemplateFieldOption,
                      Input: this.inputRenderer,
                    }}
                  />
                )}
              </div>
              {taskTitle && !data.isEdit ? (
                <Button
                  className="btn btn-sm btn-primary"
                  onClick={() => this.templateDropdown(null)}
                >
                  Clear
                </Button>
              ) : null}
              <span className="error_label">{errors.taskTitle_error}</span>
            </div>
          </div>
          {this.state.content ? (
            <div className="row" id="templateData">
              <div className="form-group col-lg-12">
                <div className="has-float-label customEditor">
                  <label htmlFor="taskDescription">Selected Document</label>
                  <div className="labelValue">
                    <div className="form-control">
                      <a
                        href="javascript:void(0)"
                        onMouseDown={this.previewTemplate}
                        className="expand text-dark pull-right mr-1 mt-1"
                      >
                        <i className="icon-expand" />
                      </a>
                      <Scrollbars
                        style={{ width: '100%' }}
                        autoHide
                        autoHideTimeout={1000}
                        renderTrackHorizontal={(props) => (
                          <div {...props} className="thumb-vertical" />
                        )}
                        autoHeight
                        autoHeightMin={0}
                        autoHeightMax={300}
                      >
                        <div className="cpaLogo pt-3 pb-3 pl-2 pr-2">
                          <img src={cpaLogo} alt="CPA Logo" />
                        </div>
                        <div className="pt-1 pb-1 pl-2 pr-2">
                          <Interweave
                            allowAttributes
                            content={this.state.content}
                            transform={transformInputFieldinInterweave}
                          />
                        </div>
                      </Scrollbars>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          <div className="row">
            <div className="form-group col-lg-12">
              <div className="has-float-label customEditor boxResize">
                <label htmlFor="taskDescription">Description</label>
                <TinymceEditor
                  value={this.state.comment}
                  handleChange={this.handleCommentChange}
                  disabled={taskStatus === 'Closed'}
                  testid="task__description"
                />
              </div>
            </div>
          </div>
          {(data.isEdit && this.state.documents.length) || !data.isEdit ? (
            <div className="row">
              <div className="form-group dragWrap col-lg-12">
                <label htmlFor="attachments">Attachments</label>
                <TaskFileUpload
                  data={this.state}
                  uploadType="task"
                  isEdit={data.isEdit}
                  propsData={data}
                  externalDocs={data.externalDocs}
                  updateDocumentState={this.updateDocumentState}
                  deleteEmailAttachment={this.props.deleteEmailAttachment}
                />
              </div>
            </div>
          ) : null}
          <div className="row">
            <div className="col-12 form-group formbtn justify-content-end btn--leftspace">
              <TaskFooterButtons
                data={data}
                handleCancel={this.handleCancel}
                taskStatus={taskStatus}
                recurringDisable={recurringDisable}
                dataUpdated={dataUpdated}
                stateValues={stateValues}
                submitForm={this.submitForm}
              />
            </div>
          </div>
          {/* <div className="row">
            <div className="col-12 form-group formbtn justify-content-end btn--leftspace TaskButtonGroup">
              <div className="btn-wrap btn--leftspace">
                <button
                  type="button"
                  className="btn btn-outline-light"
                  onClick={this.handleCancel}
                  disabled={taskStatus === 'Closed' || recurringDisable}
                >
                  Cancel
                </button>
                {this.state.templateChecked ? (
                  <button
                    type="submit"
                    className="btn btn-primary task-form-submit"
                    onMouseDown={this.submitForm}
                    disabled={
                      taskStatus === 'Closed' ||
                      recurringDisable ||
                      (this.props.data.isEdit &&
                        !dataUpdated &&
                        !stateValues.isChanged) ||
                      (this.props.data &&
                        this.props.data.gettingEmailAttachments)
                    }
                  >
                    {!this.props.data.isEdit ? 'Create Task' : 'Update Task'}
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-primary task-form-submit"
                    onMouseDown={this.previewTemplate}
                    disabled={!this.state.content}
                  >
                    Preview Task
                  </button>
                )}
              </div>
            </div>
          </div> */}
        </div>
        <ConfirmationModal
          isOpen={this.state.viewModal}
          headerText="Are you sure want to cancel."
          messageText="This action cannot be undone. Proceed?"
          noBtnClick={this.hideViewModal}
          yesBtnClick={this.nextPage}
          yesBtnText="Yes"
          noBtnText="No"
        />
        {/* --------------------------------Template Modal start here -------------------------------------------------------  */}
        <SignatureModal
          name="newtask"
          data={this.state}
          cpaLogo={cpaLogo}
          taskDetail={taskDetail}
          doctype={this.state.doctype}
          toggleModal={
            this.props.data.isEdit && taskDetail.is_signed
              ? false
              : this.toggleModal
          }
          closeTemplate={this.closeTemplate}
          handleContentChange={this.handleContentChange}
          SaveDoc={this.SaveDoc}
        />
        <ConfirmationModal
          isOpen={this.state.warningMsgLost}
          className="customModal customModal--w500 customModal--nopadding"
          messageText="You are about to delete the existing message. This action cannot be undone. Proceed?"
          noBtnClick={() => this.setState({ warningMsgLost: false })}
          yesBtnClick={() => this.templateDropdown(selectedTemplate)}
          yesBtnText="Yes"
          noBtnText="No"
        />
      </div>
    );
  }
}

export default withRouter(GetSignature);
