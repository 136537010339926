import { graphql } from 'gql';

export const todoFeedsQueryDocument = graphql(/* GraphQL */ `
  query todoFeeds {
    todoFeeds {
      id
      feedItemType
      createdAt
      updatedAt
      isRead
      item {
        ... on WorkflowRequest {
          id
          dueDate
          status
          title
        }
        ... on Invoice {
          itemId: id
          dueDate: dueBy
          legacyId
          subject
          isRecurring
        }
        ... on Task {
          itemId: id
          dueDate: dueBy
          legacyId
          subject
        }
      }
    }
  }
`);
