/* eslint-disable no-script-url */
/* eslint-disable react/jsx-no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-param-reassign */
/* eslint-disable class-methods-use-this */
import React, { Component } from 'react';
import Axios from 'axios';
import { Form, FormGroup, Input, Label, Button } from 'reactstrap';
import TaskFileUpload from 'components/Files/TaskFileUpload';
import {
  CREATE_ADOBE_TEMPLATE,
  ADOBE_WEB_ACCESS_URL,
  REPLACE_DOCUMENT_URL,
} from 'constants/constants';
import { AlertMessage } from 'utilities/utils';
import PrepareDocView from './PrepareDocView';

export default class NewEsignTemplate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      documents: [],
      errors: {},
    };
  }

  componentDidMount() {
    window.addEventListener('keypress', (e) => {
      if (e && e.which === 13) {
        e.preventDefault();
      }
    });
    if (!window.addEventListener) {
      window.attachEvent('onmessage', this.eventHandler);
    } else {
      window.addEventListener('message', this.eventHandler, false);
    }
    this.getAdobeWebAccessUrl();
  }

  componentWillUnmount() {
    window.addEventListener('keypress', () => true);
    window.removeEventListener('message', this.eventHandler, false);
  }

  getAdobeWebAccessUrl = () => {
    Axios.get(ADOBE_WEB_ACCESS_URL).then((res) => {
      if (res.status === 200 && res.data && res.data.web_access_url) {
        this.setState({ adobeWebAccessUrl: res.data.web_access_url });
      }
    });
  };

  getDocumentUrl = () => {
    const { documents } = this.state;
    const requestUrls = [];
    documents.map((e) => {
      requestUrls.push(Axios.get(`${REPLACE_DOCUMENT_URL}/${e.id}`));
      return e;
    });
    Axios.all(requestUrls).then((res) => {
      res.map((e, key) => {
        if (e.data) {
          documents[key].aws_url_origional = e.data.url;
        }
        return e;
      });
      this.setState({
        documents,
        gettingDocumentUrls: false,
      });
    });
  };

  eventHandler = (e) => {
    const { adobeWebAccessUrl } = this.state;
    if (
      e.origin === 'https://lisciodev1.na2.echosign.com' ||
      e.origin === 'https://lisciouat.na2.echosign.com' ||
      e.origin === adobeWebAccessUrl
    ) {
      const logdata = JSON.parse(e.data);
      if (logdata.data.pageName === 'POST_CREATE') {
        this.navigateBack();
      }
    }
  };

  handleChange = (e) => {
    const { errors } = this.state;
    if (errors.template_name) {
      delete errors.template_name;
    }
    this.setState({
      [e.target.name]: e.target.value,
      errors,
    });
  };

  keyPress(e) {
    if (e && Number(e.keyCode) === 13) {
      e.preventDefault();
    }
  }

  navigateBack = () => {
    this.props.navigate('/esign_templates');
  };

  updateDocumentState = (documents) => {
    const { errors } = this.state;
    delete errors.document;
    this.setState(
      {
        documents,
        errors,
        gettingDocumentUrls: true,
      },
      () => this.getDocumentUrl(),
    );
  };

  // this is called when attachments are uploaded successfully
  updateForm = (type, documents) => {
    const { errors } = this.state;
    if (errors && errors.document) {
      delete errors.document;
      this.setState(
        {
          errors,
          documents,
          gettingDocumentUrls: true,
        },
        () => this.getDocumentUrl(),
      );
    } else {
      this.setState(
        {
          documents,
          gettingDocumentUrls: true,
        },
        () => this.getDocumentUrl(),
      );
    }
  };

  saveMessage = () => {
    const { template_name, documents } = this.state;
    if (template_name && documents && documents.length) {
      const fileInfos = [];
      const doc_ids = [];
      documents.map((each) => {
        const eachDocumentInfo = {
          urlFileInfo: {
            mimeType: each.content_type,
            name: each.doc_name,
            url: each.aws_url_origional,
          },
        };
        doc_ids.push(each.id);
        fileInfos.push(eachDocumentInfo);
        each.is_template = false;
        return each;
      });
      const dataToSend = {
        fileInfos,
        name: template_name,
        state: 'AUTHORING',
        sharingMode: 'ACCOUNT',
        templateTypes: ['DOCUMENT'],
      };
      const dataForLiscio = {
        data_to_send_adobe: JSON.stringify(dataToSend),
        doc_ids,
      };
      this.setState({ loading: true });
      Axios.post(CREATE_ADOBE_TEMPLATE, dataForLiscio)
        .then((res) => {
          if (
            res.status === 200 &&
            res.data &&
            res.data.success &&
            res.data.data
          ) {
            this.setState({
              loading: false,
              authoringUrl: res.data.data.url,
            });
            AlertMessage('success', res.data.message, 6000);
            // this.navigateBack();
          } else {
            this.setState({ loading: false });
            AlertMessage('error', res.data.message, 6000);
          }
        })
        .catch((error) => {
          AlertMessage('error', error.response.data.message, 6000);
          this.setState({ loading: false });
        });
    } else {
      const errors = {};
      if (template_name === undefined || template_name.length === 0) {
        errors.template_name = 'Please Enter Template Name';
      }
      if (documents === undefined || documents.length === 0) {
        errors.document = 'Please attach document';
      }
      this.setState({ errors });
    }
  };

  render() {
    const { authoringUrl, loading, gettingDocumentUrls } = this.state;
    if (authoringUrl) {
      return (
        <PrepareDocView
          authoringUrl={authoringUrl}
          pageTitle="Template Preview"
        />
      );
    }
    return (
      <div id="content">
        <header>
          <h1 className="pageHeading">Create Library</h1>
        </header>
        {loading ? <div id="loading" /> : null}
        <div className="center-wrapper col-lg-10 col-sm-12 col-md-12 m-auto">
          <div className="pb-3 col-container section-title">
            <h6 className="stepBacklink" role="presentation">
              <a href="javascript:;" onClick={this.navigateBack}>
                <i className="icon-left-arrow" /> Back
              </a>
            </h6>
          </div>
          <Form>
            <FormGroup
              className={this.state.errors.template_name ? ' form-error' : ''}
            >
              <Label htmlFor="subject">Template Name</Label>
              <div className="labelValue">
                <Input
                  autoComplete="off"
                  onKeyPress={(e) => this.keyPress(e)}
                  type="text"
                  name="template_name"
                  id="fortemplatetitle"
                  value={this.state.template_name}
                  onChange={this.handleChange}
                  placeholder=""
                />
                <span className="error_label">
                  {this.state.errors.template_name}
                </span>
              </div>
            </FormGroup>
            <FormGroup className="dragWrap">
              <Label htmlFor="attachments">Add Files:</Label>
              <TaskFileUpload
                data={this.state}
                uploadType="edoc"
                type="edoc"
                updateDocumentState={this.updateDocumentState}
                updateForm={this.updateForm}
              />
            </FormGroup>
            {this.state.errors.document && (
              <div className="error_label">{this.state.errors.document}</div>
            )}
            <div className="form-group formbtn formbtn--border justify-content-end">
              <div className="btn-wrap btn--leftspace">
                <Button
                  className="btn btn-outline-light"
                  onClick={this.navigateBack}
                  type="button"
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  onClick={() => this.saveMessage('Sent')}
                  disabled={gettingDocumentUrls}
                >
                  Preview and Add Fields
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}
