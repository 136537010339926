import { graphql } from 'gql';

export const WorkflowConditionalItemFragment = graphql(/*GraphQL */ `
  fragment WorkflowConditionalItem on WorkflowItemInterface2 {
    id
    prompt
    responseType
    type
    section {
      id
    }
    requestItem {
      ...WorkflowRequestItem2
    }
  }
`);

export const WorkflowItemConditionFragment2 = graphql(/* GraphQL */ `
  fragment WorkflowItemCondition2 on WorkflowItemConditionType2 {
    id
    conditionType
    matchValue
    rangeLowerBound
    rangeUpperBound
    workflowItem {
      id
    }
    items {
      ...WorkflowConditionalItem
    }
  }
`);

export const WorkflowItemInterfaceFragment2 = graphql(/* GraphQL */ `
  fragment WorkflowItemInterface2 on WorkflowItemInterface2 {
    id
    prompt
    hint
    type
    updatedAt
    position
    section {
      id
    }
    requestItem {
      ...WorkflowRequestItem2
    }
    conditions {
      ...WorkflowItemCondition2
    }
  }
`);
export const WorkflowItem2Fragment2 = graphql(/* GraphQL */ `
  fragment WorkflowItem2 on WorkflowItemInterface2 {
    ...DefaultWorkflowItem2
    ...WorkflowSignableDocumentItem2
  }
`);

export const DefaultWorkflowItem2Fragment2 = graphql(/* GraphQL */ `
  fragment DefaultWorkflowItem2 on WorkflowItemType2 {
    ... on WorkflowItemType2 {
      ...WorkflowItemInterface2
    }
  }
`);

export const WorkflowSignableDocumentItem2Fragment2 = graphql(/* GraphQL */ `
  fragment WorkflowSignableDocumentItem2 on WorkflowSignableDocumentItemType2 {
    ... on WorkflowSignableDocumentItemType2 {
      ...WorkflowItemInterface2
      esignAgreement {
        id
        documentToSign {
          id
          uuid
          legacyId
          awsUrlFileName
          __typename
        }
        signedDocument {
          id
        }
        signatories {
          id
          label
          status
          signingStatusUrl
          contact {
            id
            lastName
            legacyId
          }
          kbaRequired
          signOnSameDevice
          esignSignatureBlocks {
            id
            pageNumber
            top
            left
            width
            height
          }
        }
        currentUserSignatory {
          id
          contact {
            id
            firstName
            lastName
          }
          firstName
          lastName
          label
          status
          email
          kbaRequired
          signOnSameDevice
          signingUrl
          signingStatusUrl
          esignSignatureBlocks {
            pageNumber
            top
            left
            width
            height
          }
        }
        sameDeviceSignatories {
          id
          contact {
            id
            firstName
            lastName
          }
          firstName
          lastName
          label
          status
          email
          kbaRequired
          signOnSameDevice
          signingUrl
          signingStatusUrl
          esignSignatureBlocks {
            pageNumber
            top
            left
            width
            height
          }
        }
        startSigningUrl
        signingAvailableUrl
      }
    }
  }
`);

// export const WorkflowSignableDocumentItem2Fragment = graphql(/* GraphQL */ `
//   fragment WorkflowSignableDocumentItem on WorkflowSignableDocumentItem {
//     ... on WorkflowSignableDocumentItem {
//       ...WorkflowItemInterface
//       esignAgreement {
//         documentToSign {
//           ...WorkflowRequestDependencyDocument
//         }
//         signedDocument {
//           id
//         }
//         signatories {
//           ...Signers
//         }
//         signingAvailableUrl
//         startSigningUrl
//       }
//     }
//   }
// `);

export const deleteWorkflowItemDocument2 = graphql(/* GraphQL */ `
  mutation deleteWorkflowItem($workflowItemId: Uuid!) {
    deleteWorkflowItem(workflowItemId: $workflowItemId) {
      errors {
        message
      }
    }
  }
`);

export const updateWorkflowItemDocument = graphql(/* GraphQL */ `
  mutation updateWorkflowItem(
    $prompt: String
    $type: String
    $workflowItemId: Uuid!
  ) {
    updateWorkflowItem(
      prompt: $prompt
      type: $type
      workflowItemId: $workflowItemId
    ) {
      errors {
        message
      }
      workflowItem {
        id
        prompt
        hint
        type
        updatedAt
        position
        section {
          id
        }
      }
    }
  }
`);

export const createWorkflowItemMutationDocument2 = graphql(/* GraphQL */ `
  mutation createWorkflowItem2(
    $workflowSectionId: Uuid
    $workflowId: Uuid
    $workflowItemType: String!
    $prompt: String!
  ) {
    createWorkflowItem2(
      workflowSectionId: $workflowSectionId
      workflowId: $workflowId
      workflowItemType: $workflowItemType
      prompt: $prompt
    ) {
      errors {
        message
      }
      workflowItem {
        ...WorkflowItem2
      }
    }
  }
`);

export const createWorkflowConditionalItemMutationDocument2 =
  graphql(/* GraphQL */ `
    mutation createWorkflowConditionalItem2(
      $workflowSectionId: Uuid
      $workflowItemType: String!
      $prompt: String!
      $conditionId: Uuid
    ) {
      createWorkflowItem2(
        workflowSectionId: $workflowSectionId
        workflowItemType: $workflowItemType
        conditionId: $conditionId
        prompt: $prompt
      ) {
        errors {
          message
        }
        workflowItem {
          ...WorkflowConditionalItem
        }
      }
    }
  `);

export const reorderWorkflowItemsMutationDocument = graphql(/* GraphQL */ `
  mutation reorderWorkflowItems(
    $workflowSectionId: Uuid!
    $workflowItemIds: [Uuid!]!
  ) {
    reorderWorkflowItems(
      workflowItemIds: $workflowItemIds
      workflowSectionId: $workflowSectionId
    ) {
      errors {
        message
      }
      workflowSection {
        ...WorkflowSection
      }
    }
  }
`);

export const reorderWorkflowSectionsMutationDocument = graphql(/* GraphQL */ `
  mutation reorderWorkflowSections(
    $workflowId: Uuid!
    $workflowSectionIds: [Uuid!]!
  ) {
    reorderWorkflowSections(
      workflowId: $workflowId
      workflowSectionIds: $workflowSectionIds
    ) {
      errors {
        message
      }
      workflowSections {
        ...WorkflowSection
      }
    }
  }
`);

export const createWorkflowItemConditionDocument2 = graphql(/* GraphQL */ `
  mutation createWorkflowItemCondition2(
    $workflowItemId: Uuid!
    $conditionType: String!
    $matchValue: String
    $rangeLowerBound: String
    $rangeUpperBound: String
  ) {
    createWorkflowItemCondition2(
      workflowItemId: $workflowItemId
      conditionType: $conditionType
      matchValue: $matchValue
      rangeLowerBound: $rangeLowerBound
      rangeUpperBound: $rangeUpperBound
    ) {
      errors {
        message
      }
      workflowItemCondition {
        ...WorkflowItemCondition2
      }
    }
  }
`);

export const updateWorkflowItemConditionDocument2 = graphql(/* GraphQL */ `
  mutation updateWorkflowItemCondition2(
    $workflowItemConditionId: Uuid!
    $conditionType: String
    $matchValue: String
    $rangeLowerBound: String
    $rangeUpperBound: String
  ) {
    updateWorkflowItemCondition2(
      workflowItemConditionId: $workflowItemConditionId
      conditionType: $conditionType
      matchValue: $matchValue
      rangeLowerBound: $rangeLowerBound
      rangeUpperBound: $rangeUpperBound
    ) {
      errors {
        message
      }
      workflowItemCondition {
        ...WorkflowItemCondition2
      }
    }
  }
`);

export const deleteWorkflowItemConditionDocument = graphql(/* GraphQL */ `
  mutation deleteWorkflowItemCondition($workflowItemConditionId: Uuid!) {
    deleteWorkflowItemCondition(
      workflowItemConditionId: $workflowItemConditionId
    ) {
      errors {
        message
      }
    }
  }
`);

export const workflowForConditionIdQueryDocument = graphql(/* GraphQL */ `
  query workflowForConditionIdQuery($id: Uuid!) {
    workflow(id: $id) {
      id
      items2 {
        id
        conditions {
          ...WorkflowItemCondition2
        }
      }
    }
  }
`);
