/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * API V5
 * OpenAPI spec version: v5
 */
import {
  useQuery
} from '@tanstack/react-query'
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  ForbiddenErrorObject,
  GetApiV5FirmsWorkflowTemplates200Item,
  GetApiV5FirmsWorkflowTemplatesId200,
  Uuid
} from '.././schemas'
import getApiV5FirmsWorkflowTemplatesMutator from '../../configOrvalAPIV5';
import type { ErrorType as GetApiV5FirmsWorkflowTemplatesErrorType } from '../../configOrvalAPIV5';
import getApiV5FirmsWorkflowTemplatesIdMutator from '../../configOrvalAPIV5';
import type { ErrorType as GetApiV5FirmsWorkflowTemplatesIdErrorType } from '../../configOrvalAPIV5';



/**
 * @summary Retrieves Workflow Templates
 */
export const getApiV5FirmsWorkflowTemplates = (
    
 signal?: AbortSignal
) => {
      
      
      return getApiV5FirmsWorkflowTemplatesMutator<GetApiV5FirmsWorkflowTemplates200Item[]>(
      {url: `/api/v5/firms/workflow_templates`, method: 'GET', signal
    },
      );
    }
  

export const getGetApiV5FirmsWorkflowTemplatesQueryKey = () => {
    return [`/api/v5/firms/workflow_templates`] as const;
    }

    
export const getGetApiV5FirmsWorkflowTemplatesQueryOptions = <TData = Awaited<ReturnType<typeof getApiV5FirmsWorkflowTemplates>>, TError = GetApiV5FirmsWorkflowTemplatesErrorType<ForbiddenErrorObject>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV5FirmsWorkflowTemplates>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV5FirmsWorkflowTemplatesQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV5FirmsWorkflowTemplates>>> = ({ signal }) => getApiV5FirmsWorkflowTemplates(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiV5FirmsWorkflowTemplates>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiV5FirmsWorkflowTemplatesQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV5FirmsWorkflowTemplates>>>
export type GetApiV5FirmsWorkflowTemplatesQueryError = GetApiV5FirmsWorkflowTemplatesErrorType<ForbiddenErrorObject>


/**
 * @summary Retrieves Workflow Templates
 */

export function useGetApiV5FirmsWorkflowTemplates<TData = Awaited<ReturnType<typeof getApiV5FirmsWorkflowTemplates>>, TError = GetApiV5FirmsWorkflowTemplatesErrorType<ForbiddenErrorObject>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV5FirmsWorkflowTemplates>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiV5FirmsWorkflowTemplatesQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Retrieves A Workflow Template
 */
export const getApiV5FirmsWorkflowTemplatesId = (
    id: Uuid,
 signal?: AbortSignal
) => {
      
      
      return getApiV5FirmsWorkflowTemplatesIdMutator<GetApiV5FirmsWorkflowTemplatesId200>(
      {url: `/api/v5/firms/workflow_templates/${id}`, method: 'GET', signal
    },
      );
    }
  

export const getGetApiV5FirmsWorkflowTemplatesIdQueryKey = (id: Uuid,) => {
    return [`/api/v5/firms/workflow_templates/${id}`] as const;
    }

    
export const getGetApiV5FirmsWorkflowTemplatesIdQueryOptions = <TData = Awaited<ReturnType<typeof getApiV5FirmsWorkflowTemplatesId>>, TError = GetApiV5FirmsWorkflowTemplatesIdErrorType<ForbiddenErrorObject>>(id: Uuid, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV5FirmsWorkflowTemplatesId>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV5FirmsWorkflowTemplatesIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV5FirmsWorkflowTemplatesId>>> = ({ signal }) => getApiV5FirmsWorkflowTemplatesId(id, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiV5FirmsWorkflowTemplatesId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiV5FirmsWorkflowTemplatesIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV5FirmsWorkflowTemplatesId>>>
export type GetApiV5FirmsWorkflowTemplatesIdQueryError = GetApiV5FirmsWorkflowTemplatesIdErrorType<ForbiddenErrorObject>


/**
 * @summary Retrieves A Workflow Template
 */

export function useGetApiV5FirmsWorkflowTemplatesId<TData = Awaited<ReturnType<typeof getApiV5FirmsWorkflowTemplatesId>>, TError = GetApiV5FirmsWorkflowTemplatesIdErrorType<ForbiddenErrorObject>>(
 id: Uuid, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV5FirmsWorkflowTemplatesId>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiV5FirmsWorkflowTemplatesIdQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



