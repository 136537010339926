import { graphql } from 'gql';

export const createSmsBrandRegistration = graphql(/* GraphQL */ `
  mutation CreateSmsBrandRegistration($params: SmsBrandRegistrationInput!) {
    createSmsBrandRegistration(params: $params) {
      errors {
        message
      }
      smsBrandRegistration {
        id
      }
    }
  }
`);
