import React from 'react';
import Select from 'react-select';
import { selectStylesEmail, selectTheme } from 'common/CustomFields';

function TaskSelector(props) {
  const { taskTypes, selectedOption, handleTaskTypeChange, isEdit } = props;

  return (
    <>
      <label className="pt-2 mt-1" htmlFor="selectTaskType">
        Task:
      </label>
      <Select
        name="taskType"
        className="select-custom-class"
        isClearable={false}
        options={taskTypes}
        value={selectedOption}
        onChange={handleTaskTypeChange}
        backspaceRemoves={false}
        deleteRemoves={false}
        isSearchable={false}
        isDisabled={isEdit}
        styles={selectStylesEmail}
        theme={selectTheme}
        components={{
          IndicatorSeparator: () => null,
        }}
      />
    </>
  );
}

export default TaskSelector;
