/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * API V5
 * OpenAPI spec version: v5
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  AuthenticatedUser,
  AuthorizedCpaUser,
  ErrorObjectArray,
  PatchApiV5AuthPasswordResetsResetToken200,
  PatchApiV5AuthPasswordResetsResetTokenBody,
  PostApiV5AuthCpaAuthorizationBody,
  PostApiV5AuthMagicLinks200,
  PostApiV5AuthMagicLinksBody,
  PostApiV5AuthMfaBody,
  PostApiV5AuthPasswordResetTokensBody,
  PostApiV5AuthPasswordResets200,
  PostApiV5AuthPasswordResetsBody,
  PostApiV5AuthSignInBody,
  PostApiV5AuthSignInSsoBody,
  PutApiV5AuthPasswordsBody
} from '.././schemas'
import postApiV5AuthCpaAuthorizationMutator from '../../configOrvalAPIV5';
import type { ErrorType as PostApiV5AuthCpaAuthorizationErrorType } from '../../configOrvalAPIV5';
import postApiV5AuthSignInMutator from '../../configOrvalAPIV5';
import type { ErrorType as PostApiV5AuthSignInErrorType } from '../../configOrvalAPIV5';
import deleteApiV5AuthSignOutMutator from '../../configOrvalAPIV5';
import type { ErrorType as DeleteApiV5AuthSignOutErrorType } from '../../configOrvalAPIV5';
import postApiV5AuthMagicLinksMutator from '../../configOrvalAPIV5';
import type { ErrorType as PostApiV5AuthMagicLinksErrorType } from '../../configOrvalAPIV5';
import patchApiV5AuthMagicLinksIdMutator from '../../configOrvalAPIV5';
import type { ErrorType as PatchApiV5AuthMagicLinksIdErrorType } from '../../configOrvalAPIV5';
import postApiV5AuthMfaMutator from '../../configOrvalAPIV5';
import type { ErrorType as PostApiV5AuthMfaErrorType } from '../../configOrvalAPIV5';
import postApiV5AuthPasswordResetTokensMutator from '../../configOrvalAPIV5';
import type { ErrorType as PostApiV5AuthPasswordResetTokensErrorType } from '../../configOrvalAPIV5';
import postApiV5AuthPasswordResetsMutator from '../../configOrvalAPIV5';
import type { ErrorType as PostApiV5AuthPasswordResetsErrorType } from '../../configOrvalAPIV5';
import patchApiV5AuthPasswordResetsResetTokenMutator from '../../configOrvalAPIV5';
import type { ErrorType as PatchApiV5AuthPasswordResetsResetTokenErrorType } from '../../configOrvalAPIV5';
import putApiV5AuthPasswordsMutator from '../../configOrvalAPIV5';
import type { ErrorType as PutApiV5AuthPasswordsErrorType } from '../../configOrvalAPIV5';
import postApiV5AuthSignInSsoMutator from '../../configOrvalAPIV5';
import type { ErrorType as PostApiV5AuthSignInSsoErrorType } from '../../configOrvalAPIV5';
import getApiV5MeMutator from '../../configOrvalAPIV5';
import type { ErrorType as GetApiV5MeErrorType } from '../../configOrvalAPIV5';



/**
 * @summary Switch from one authorized cpa_user session to another
 */
export const postApiV5AuthCpaAuthorization = (
    postApiV5AuthCpaAuthorizationBody: PostApiV5AuthCpaAuthorizationBody,
 ) => {
      
      
      return postApiV5AuthCpaAuthorizationMutator<AuthorizedCpaUser>(
      {url: `/api/v5/auth/cpa_authorization`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postApiV5AuthCpaAuthorizationBody
    },
      );
    }
  


export const getPostApiV5AuthCpaAuthorizationMutationOptions = <TError = PostApiV5AuthCpaAuthorizationErrorType<ErrorObjectArray>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV5AuthCpaAuthorization>>, TError,{data: PostApiV5AuthCpaAuthorizationBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postApiV5AuthCpaAuthorization>>, TError,{data: PostApiV5AuthCpaAuthorizationBody}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV5AuthCpaAuthorization>>, {data: PostApiV5AuthCpaAuthorizationBody}> = (props) => {
          const {data} = props ?? {};

          return  postApiV5AuthCpaAuthorization(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiV5AuthCpaAuthorizationMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV5AuthCpaAuthorization>>>
    export type PostApiV5AuthCpaAuthorizationMutationBody = PostApiV5AuthCpaAuthorizationBody
    export type PostApiV5AuthCpaAuthorizationMutationError = PostApiV5AuthCpaAuthorizationErrorType<ErrorObjectArray>

    /**
 * @summary Switch from one authorized cpa_user session to another
 */
export const usePostApiV5AuthCpaAuthorization = <TError = PostApiV5AuthCpaAuthorizationErrorType<ErrorObjectArray>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV5AuthCpaAuthorization>>, TError,{data: PostApiV5AuthCpaAuthorizationBody}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postApiV5AuthCpaAuthorization>>,
        TError,
        {data: PostApiV5AuthCpaAuthorizationBody},
        TContext
      > => {

      const mutationOptions = getPostApiV5AuthCpaAuthorizationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Login with email and password
 */
export const postApiV5AuthSignIn = (
    postApiV5AuthSignInBody: PostApiV5AuthSignInBody,
 ) => {
      
      
      return postApiV5AuthSignInMutator<AuthenticatedUser>(
      {url: `/api/v5/auth/sign_in`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postApiV5AuthSignInBody
    },
      );
    }
  


export const getPostApiV5AuthSignInMutationOptions = <TError = PostApiV5AuthSignInErrorType<ErrorObjectArray>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV5AuthSignIn>>, TError,{data: PostApiV5AuthSignInBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postApiV5AuthSignIn>>, TError,{data: PostApiV5AuthSignInBody}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV5AuthSignIn>>, {data: PostApiV5AuthSignInBody}> = (props) => {
          const {data} = props ?? {};

          return  postApiV5AuthSignIn(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiV5AuthSignInMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV5AuthSignIn>>>
    export type PostApiV5AuthSignInMutationBody = PostApiV5AuthSignInBody
    export type PostApiV5AuthSignInMutationError = PostApiV5AuthSignInErrorType<ErrorObjectArray>

    /**
 * @summary Login with email and password
 */
export const usePostApiV5AuthSignIn = <TError = PostApiV5AuthSignInErrorType<ErrorObjectArray>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV5AuthSignIn>>, TError,{data: PostApiV5AuthSignInBody}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postApiV5AuthSignIn>>,
        TError,
        {data: PostApiV5AuthSignInBody},
        TContext
      > => {

      const mutationOptions = getPostApiV5AuthSignInMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Logout
 */
export const deleteApiV5AuthSignOut = (
    
 ) => {
      
      
      return deleteApiV5AuthSignOutMutator<void>(
      {url: `/api/v5/auth/sign_out`, method: 'DELETE'
    },
      );
    }
  


export const getDeleteApiV5AuthSignOutMutationOptions = <TError = DeleteApiV5AuthSignOutErrorType<ErrorObjectArray>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiV5AuthSignOut>>, TError,void, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deleteApiV5AuthSignOut>>, TError,void, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiV5AuthSignOut>>, void> = () => {
          

          return  deleteApiV5AuthSignOut()
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteApiV5AuthSignOutMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiV5AuthSignOut>>>
    
    export type DeleteApiV5AuthSignOutMutationError = DeleteApiV5AuthSignOutErrorType<ErrorObjectArray>

    /**
 * @summary Logout
 */
export const useDeleteApiV5AuthSignOut = <TError = DeleteApiV5AuthSignOutErrorType<ErrorObjectArray>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiV5AuthSignOut>>, TError,void, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof deleteApiV5AuthSignOut>>,
        TError,
        void,
        TContext
      > => {

      const mutationOptions = getDeleteApiV5AuthSignOutMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Generate magic link
 */
export const postApiV5AuthMagicLinks = (
    postApiV5AuthMagicLinksBody: PostApiV5AuthMagicLinksBody,
 ) => {
      
      
      return postApiV5AuthMagicLinksMutator<PostApiV5AuthMagicLinks200>(
      {url: `/api/v5/auth/magic_links`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postApiV5AuthMagicLinksBody
    },
      );
    }
  


export const getPostApiV5AuthMagicLinksMutationOptions = <TError = PostApiV5AuthMagicLinksErrorType<ErrorObjectArray>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV5AuthMagicLinks>>, TError,{data: PostApiV5AuthMagicLinksBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postApiV5AuthMagicLinks>>, TError,{data: PostApiV5AuthMagicLinksBody}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV5AuthMagicLinks>>, {data: PostApiV5AuthMagicLinksBody}> = (props) => {
          const {data} = props ?? {};

          return  postApiV5AuthMagicLinks(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiV5AuthMagicLinksMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV5AuthMagicLinks>>>
    export type PostApiV5AuthMagicLinksMutationBody = PostApiV5AuthMagicLinksBody
    export type PostApiV5AuthMagicLinksMutationError = PostApiV5AuthMagicLinksErrorType<ErrorObjectArray>

    /**
 * @summary Generate magic link
 */
export const usePostApiV5AuthMagicLinks = <TError = PostApiV5AuthMagicLinksErrorType<ErrorObjectArray>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV5AuthMagicLinks>>, TError,{data: PostApiV5AuthMagicLinksBody}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postApiV5AuthMagicLinks>>,
        TError,
        {data: PostApiV5AuthMagicLinksBody},
        TContext
      > => {

      const mutationOptions = getPostApiV5AuthMagicLinksMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Exchange Magic Link for Token
 */
export const patchApiV5AuthMagicLinksId = (
    id: string,
 ) => {
      
      
      return patchApiV5AuthMagicLinksIdMutator<AuthenticatedUser>(
      {url: `/api/v5/auth/magic_links/${id}`, method: 'PATCH'
    },
      );
    }
  


export const getPatchApiV5AuthMagicLinksIdMutationOptions = <TError = PatchApiV5AuthMagicLinksIdErrorType<ErrorObjectArray>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiV5AuthMagicLinksId>>, TError,{id: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof patchApiV5AuthMagicLinksId>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchApiV5AuthMagicLinksId>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  patchApiV5AuthMagicLinksId(id,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchApiV5AuthMagicLinksIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchApiV5AuthMagicLinksId>>>
    
    export type PatchApiV5AuthMagicLinksIdMutationError = PatchApiV5AuthMagicLinksIdErrorType<ErrorObjectArray>

    /**
 * @summary Exchange Magic Link for Token
 */
export const usePatchApiV5AuthMagicLinksId = <TError = PatchApiV5AuthMagicLinksIdErrorType<ErrorObjectArray>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiV5AuthMagicLinksId>>, TError,{id: string}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof patchApiV5AuthMagicLinksId>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getPatchApiV5AuthMagicLinksIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Validate MFA code
 */
export const postApiV5AuthMfa = (
    postApiV5AuthMfaBody: PostApiV5AuthMfaBody,
 ) => {
      
      
      return postApiV5AuthMfaMutator<AuthenticatedUser>(
      {url: `/api/v5/auth/mfa`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postApiV5AuthMfaBody
    },
      );
    }
  


export const getPostApiV5AuthMfaMutationOptions = <TError = PostApiV5AuthMfaErrorType<ErrorObjectArray>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV5AuthMfa>>, TError,{data: PostApiV5AuthMfaBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postApiV5AuthMfa>>, TError,{data: PostApiV5AuthMfaBody}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV5AuthMfa>>, {data: PostApiV5AuthMfaBody}> = (props) => {
          const {data} = props ?? {};

          return  postApiV5AuthMfa(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiV5AuthMfaMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV5AuthMfa>>>
    export type PostApiV5AuthMfaMutationBody = PostApiV5AuthMfaBody
    export type PostApiV5AuthMfaMutationError = PostApiV5AuthMfaErrorType<ErrorObjectArray>

    /**
 * @summary Validate MFA code
 */
export const usePostApiV5AuthMfa = <TError = PostApiV5AuthMfaErrorType<ErrorObjectArray>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV5AuthMfa>>, TError,{data: PostApiV5AuthMfaBody}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postApiV5AuthMfa>>,
        TError,
        {data: PostApiV5AuthMfaBody},
        TContext
      > => {

      const mutationOptions = getPostApiV5AuthMfaMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Exchange Password Reset Token
 */
export const postApiV5AuthPasswordResetTokens = (
    postApiV5AuthPasswordResetTokensBody: PostApiV5AuthPasswordResetTokensBody,
 ) => {
      
      
      return postApiV5AuthPasswordResetTokensMutator<AuthenticatedUser>(
      {url: `/api/v5/auth/password_reset_tokens`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postApiV5AuthPasswordResetTokensBody
    },
      );
    }
  


export const getPostApiV5AuthPasswordResetTokensMutationOptions = <TError = PostApiV5AuthPasswordResetTokensErrorType<ErrorObjectArray>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV5AuthPasswordResetTokens>>, TError,{data: PostApiV5AuthPasswordResetTokensBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postApiV5AuthPasswordResetTokens>>, TError,{data: PostApiV5AuthPasswordResetTokensBody}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV5AuthPasswordResetTokens>>, {data: PostApiV5AuthPasswordResetTokensBody}> = (props) => {
          const {data} = props ?? {};

          return  postApiV5AuthPasswordResetTokens(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiV5AuthPasswordResetTokensMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV5AuthPasswordResetTokens>>>
    export type PostApiV5AuthPasswordResetTokensMutationBody = PostApiV5AuthPasswordResetTokensBody
    export type PostApiV5AuthPasswordResetTokensMutationError = PostApiV5AuthPasswordResetTokensErrorType<ErrorObjectArray>

    /**
 * @summary Exchange Password Reset Token
 */
export const usePostApiV5AuthPasswordResetTokens = <TError = PostApiV5AuthPasswordResetTokensErrorType<ErrorObjectArray>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV5AuthPasswordResetTokens>>, TError,{data: PostApiV5AuthPasswordResetTokensBody}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postApiV5AuthPasswordResetTokens>>,
        TError,
        {data: PostApiV5AuthPasswordResetTokensBody},
        TContext
      > => {

      const mutationOptions = getPostApiV5AuthPasswordResetTokensMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Create Reset Password Token and send email
 */
export const postApiV5AuthPasswordResets = (
    postApiV5AuthPasswordResetsBody: PostApiV5AuthPasswordResetsBody,
 ) => {
      
      
      return postApiV5AuthPasswordResetsMutator<PostApiV5AuthPasswordResets200>(
      {url: `/api/v5/auth/password_resets`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postApiV5AuthPasswordResetsBody
    },
      );
    }
  


export const getPostApiV5AuthPasswordResetsMutationOptions = <TError = PostApiV5AuthPasswordResetsErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV5AuthPasswordResets>>, TError,{data: PostApiV5AuthPasswordResetsBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postApiV5AuthPasswordResets>>, TError,{data: PostApiV5AuthPasswordResetsBody}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV5AuthPasswordResets>>, {data: PostApiV5AuthPasswordResetsBody}> = (props) => {
          const {data} = props ?? {};

          return  postApiV5AuthPasswordResets(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiV5AuthPasswordResetsMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV5AuthPasswordResets>>>
    export type PostApiV5AuthPasswordResetsMutationBody = PostApiV5AuthPasswordResetsBody
    export type PostApiV5AuthPasswordResetsMutationError = PostApiV5AuthPasswordResetsErrorType<unknown>

    /**
 * @summary Create Reset Password Token and send email
 */
export const usePostApiV5AuthPasswordResets = <TError = PostApiV5AuthPasswordResetsErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV5AuthPasswordResets>>, TError,{data: PostApiV5AuthPasswordResetsBody}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postApiV5AuthPasswordResets>>,
        TError,
        {data: PostApiV5AuthPasswordResetsBody},
        TContext
      > => {

      const mutationOptions = getPostApiV5AuthPasswordResetsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Reset password with Token
 */
export const patchApiV5AuthPasswordResetsResetToken = (
    resetToken: string,
    patchApiV5AuthPasswordResetsResetTokenBody: PatchApiV5AuthPasswordResetsResetTokenBody,
 ) => {
      
      
      return patchApiV5AuthPasswordResetsResetTokenMutator<PatchApiV5AuthPasswordResetsResetToken200>(
      {url: `/api/v5/auth/password_resets/${resetToken}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: patchApiV5AuthPasswordResetsResetTokenBody
    },
      );
    }
  


export const getPatchApiV5AuthPasswordResetsResetTokenMutationOptions = <TError = PatchApiV5AuthPasswordResetsResetTokenErrorType<ErrorObjectArray>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiV5AuthPasswordResetsResetToken>>, TError,{resetToken: string;data: PatchApiV5AuthPasswordResetsResetTokenBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof patchApiV5AuthPasswordResetsResetToken>>, TError,{resetToken: string;data: PatchApiV5AuthPasswordResetsResetTokenBody}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchApiV5AuthPasswordResetsResetToken>>, {resetToken: string;data: PatchApiV5AuthPasswordResetsResetTokenBody}> = (props) => {
          const {resetToken,data} = props ?? {};

          return  patchApiV5AuthPasswordResetsResetToken(resetToken,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchApiV5AuthPasswordResetsResetTokenMutationResult = NonNullable<Awaited<ReturnType<typeof patchApiV5AuthPasswordResetsResetToken>>>
    export type PatchApiV5AuthPasswordResetsResetTokenMutationBody = PatchApiV5AuthPasswordResetsResetTokenBody
    export type PatchApiV5AuthPasswordResetsResetTokenMutationError = PatchApiV5AuthPasswordResetsResetTokenErrorType<ErrorObjectArray>

    /**
 * @summary Reset password with Token
 */
export const usePatchApiV5AuthPasswordResetsResetToken = <TError = PatchApiV5AuthPasswordResetsResetTokenErrorType<ErrorObjectArray>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiV5AuthPasswordResetsResetToken>>, TError,{resetToken: string;data: PatchApiV5AuthPasswordResetsResetTokenBody}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof patchApiV5AuthPasswordResetsResetToken>>,
        TError,
        {resetToken: string;data: PatchApiV5AuthPasswordResetsResetTokenBody},
        TContext
      > => {

      const mutationOptions = getPatchApiV5AuthPasswordResetsResetTokenMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Change password from logged in state
 */
export const putApiV5AuthPasswords = (
    putApiV5AuthPasswordsBody: PutApiV5AuthPasswordsBody,
 ) => {
      
      
      return putApiV5AuthPasswordsMutator<void>(
      {url: `/api/v5/auth/passwords`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: putApiV5AuthPasswordsBody
    },
      );
    }
  


export const getPutApiV5AuthPasswordsMutationOptions = <TError = PutApiV5AuthPasswordsErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV5AuthPasswords>>, TError,{data: PutApiV5AuthPasswordsBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof putApiV5AuthPasswords>>, TError,{data: PutApiV5AuthPasswordsBody}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiV5AuthPasswords>>, {data: PutApiV5AuthPasswordsBody}> = (props) => {
          const {data} = props ?? {};

          return  putApiV5AuthPasswords(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutApiV5AuthPasswordsMutationResult = NonNullable<Awaited<ReturnType<typeof putApiV5AuthPasswords>>>
    export type PutApiV5AuthPasswordsMutationBody = PutApiV5AuthPasswordsBody
    export type PutApiV5AuthPasswordsMutationError = PutApiV5AuthPasswordsErrorType<void>

    /**
 * @summary Change password from logged in state
 */
export const usePutApiV5AuthPasswords = <TError = PutApiV5AuthPasswordsErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV5AuthPasswords>>, TError,{data: PutApiV5AuthPasswordsBody}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof putApiV5AuthPasswords>>,
        TError,
        {data: PutApiV5AuthPasswordsBody},
        TContext
      > => {

      const mutationOptions = getPutApiV5AuthPasswordsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Login with SSO Service
 */
export const postApiV5AuthSignInSso = (
    postApiV5AuthSignInSsoBody: PostApiV5AuthSignInSsoBody,
 ) => {
      
      
      return postApiV5AuthSignInSsoMutator<AuthenticatedUser>(
      {url: `/api/v5/auth/sign_in_sso`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postApiV5AuthSignInSsoBody
    },
      );
    }
  


export const getPostApiV5AuthSignInSsoMutationOptions = <TError = PostApiV5AuthSignInSsoErrorType<ErrorObjectArray>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV5AuthSignInSso>>, TError,{data: PostApiV5AuthSignInSsoBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postApiV5AuthSignInSso>>, TError,{data: PostApiV5AuthSignInSsoBody}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV5AuthSignInSso>>, {data: PostApiV5AuthSignInSsoBody}> = (props) => {
          const {data} = props ?? {};

          return  postApiV5AuthSignInSso(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiV5AuthSignInSsoMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV5AuthSignInSso>>>
    export type PostApiV5AuthSignInSsoMutationBody = PostApiV5AuthSignInSsoBody
    export type PostApiV5AuthSignInSsoMutationError = PostApiV5AuthSignInSsoErrorType<ErrorObjectArray>

    /**
 * @summary Login with SSO Service
 */
export const usePostApiV5AuthSignInSso = <TError = PostApiV5AuthSignInSsoErrorType<ErrorObjectArray>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV5AuthSignInSso>>, TError,{data: PostApiV5AuthSignInSsoBody}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postApiV5AuthSignInSso>>,
        TError,
        {data: PostApiV5AuthSignInSsoBody},
        TContext
      > => {

      const mutationOptions = getPostApiV5AuthSignInSsoMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Get me data
 */
export const getApiV5Me = (
    
 signal?: AbortSignal
) => {
      
      
      return getApiV5MeMutator<AuthenticatedUser>(
      {url: `/api/v5/me`, method: 'GET', signal
    },
      );
    }
  

export const getGetApiV5MeQueryKey = () => {
    return [`/api/v5/me`] as const;
    }

    
export const getGetApiV5MeQueryOptions = <TData = Awaited<ReturnType<typeof getApiV5Me>>, TError = GetApiV5MeErrorType<ErrorObjectArray>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV5Me>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV5MeQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV5Me>>> = ({ signal }) => getApiV5Me(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiV5Me>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiV5MeQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV5Me>>>
export type GetApiV5MeQueryError = GetApiV5MeErrorType<ErrorObjectArray>


/**
 * @summary Get me data
 */

export function useGetApiV5Me<TData = Awaited<ReturnType<typeof getApiV5Me>>, TError = GetApiV5MeErrorType<ErrorObjectArray>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV5Me>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiV5MeQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



