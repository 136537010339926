import { useRequestBuilderContext } from 'components/Requests/components/RequestsBuilder/context/RequestsBuilderContext';
import { getParentItemNumberFromIndex } from 'components/Requests/components/RequestsBuilder/utils/item-utils';
import {
  BuilderSection,
  WorkflowItemType,
} from 'components/Requests/requests.types';
import {
  UpdateWorkflowItemMutationVariables,
  WorkflowSection,
} from 'gql/graphql';
import {
  useCreateWorkflowItem,
  useUpdateWorkflowItem,
  useDeleteWorkflowItem,
} from 'hooks/workflow-item-hooks';
import { useEffect, useState } from 'react';
import { SectionItemTuple } from 'components/Requests/components/RequestsBuilder/components/QuestionsBuilder';
import { QuestionEditor } from 'components/Requests/components/RequestsBuilder/components/QuestionEditor/QuestionEditor';
import { TOAST_FAILURE } from 'constants/constants';
import { AlertMessage } from 'utilities/utils';

export interface WorkflowItemEditorProps {
  sections: BuilderSection[];
  setSections: React.Dispatch<React.SetStateAction<BuilderSection[]>>;
}

export const WorkflowItemEditor = ({
  sections,
  setSections,
}: WorkflowItemEditorProps) => {
  const { mutate: createWorkflowItemMutation } = useCreateWorkflowItem();
  const { mutate: updateWorkflowItemMutation } = useUpdateWorkflowItem();
  const { mutate: deleteWorkflowItemMutation } = useDeleteWorkflowItem();

  const {
    workflowId,
    activeQuestionIndex,
    setActiveQuestionIndex,
    newItemInEdit,
    setNewItemInEdit,
  } = useRequestBuilderContext();
  const [sectionIndex, itemIndex] = activeQuestionIndex;
  const activeQuestion = getActiveQuestion(activeQuestionIndex, sections);

  const [editorType, setEditorType] = useState<WorkflowItemType>(
    newItemInEdit?.type ||
      (activeQuestion?.type as WorkflowItemType) ||
      WorkflowItemType.Boolean,
  );

  useEffect(() => {
    if (newItemInEdit?.type) {
      setEditorType(newItemInEdit.type as WorkflowItemType);
    }
  }, [newItemInEdit]);

  useEffect(() => {
    if (newItemInEdit) {
      return;
    }

    if (activeQuestion?.type !== editorType) {
      setEditorType(activeQuestion?.type as WorkflowItemType);
    }
  }, [activeQuestion, editorType, newItemInEdit]);

  const handleDeleteClicked = ([sectionIdx, itemIdx]: SectionItemTuple) => {
    if (!workflowId || sectionIdx === null || itemIdx === null) {
      AlertMessage(TOAST_FAILURE, 'Unable to delete question');
      return;
    }
    const workflowItem = sections[sectionIdx]?.items2?.[itemIdx];

    if (!workflowItem) {
      return;
    }

    const workflowItemId = workflowItem?.id;

    setSections((prevSections) => {
      const newSections = structuredClone(prevSections);
      // filter out the parent workflow item
      const sectionItems = newSections[sectionIdx].items2?.filter(
        (item) => item.id !== workflowItemId,
      );
      newSections[sectionIdx].items2 = sectionItems;
      return newSections;
    });

    deleteWorkflowItemMutation(
      { workflowItemId },
      {
        onSuccess: (response) => {
          if (response?.deleteWorkflowItem?.errors?.length) {
            // restore deleted questions
            setSections((prevSections) => {
              const newSections = structuredClone(prevSections);
              newSections[sectionIdx].items2?.splice(
                workflowItem?.position as number,
                0,
                workflowItem,
              );
              return newSections;
            });
            return;
          }

          if (newItemInEdit) {
            setNewItemInEdit(null);
            return;
          }

          setActiveQuestionIndex([sectionIdx, null]);
        },
      },
    );
  };

  function createItem(questionText: string, overrideType?: WorkflowItemType) {
    const type = overrideType || editorType;
    const [sectionIdx, itemIdx] = activeQuestionIndex;
    if (!workflowId) {
      return;
    }

    if (!newItemInEdit && itemIdx !== null && sectionIdx !== null) {
      const pPrompt = sections[sectionIdx]?.items2?.[itemIdx].prompt;
      const pType = sections[sectionIdx]?.items2?.[itemIdx].type;
      if (pPrompt === questionText && pType === type) {
        return;
      }

      const uVariables: UpdateWorkflowItemMutationVariables = {
        workflowItemId: sections[sectionIdx]?.items2?.[itemIdx].id,
      };
      if (pPrompt !== questionText) {
        uVariables.prompt = questionText;
      }
      if (pType !== type) {
        uVariables.type = type;
      }

      updateItem(uVariables);

      return;
    }

    if (!newItemInEdit || !questionText) {
      return;
    }

    createWorkflowItemMutation(
      {
        workflowSectionId: sections[newItemInEdit.sectionIndex].id,
        workflowId,
        prompt: questionText,
        workflowItemType: type,
      },
      {
        onSuccess: (response) => {
          if (response?.createWorkflowItem2?.errors?.length) {
            return;
          }
          setSections((prevSections) => {
            const newSections = structuredClone(prevSections);
            newSections[newItemInEdit.sectionIndex].items2?.push(
              response?.createWorkflowItem2?.workflowItem!,
            );
            return newSections;
          });
          setNewItemInEdit(null);
          setActiveQuestionIndex([
            newItemInEdit.sectionIndex,
            sections[newItemInEdit.sectionIndex].items2!.length,
          ]);
        },
      },
    );
  }

  const updateItem = (
    variables: Omit<UpdateWorkflowItemMutationVariables, 'workflowItemId'>,
  ) => {
    if (newItemInEdit || itemIndex === null || sectionIndex === null) {
      return;
    }

    const uVariables: UpdateWorkflowItemMutationVariables = {
      workflowItemId: sections[sectionIndex]?.items2?.[itemIndex].id,
      ...variables,
    };

    updateWorkflowItemMutation(uVariables, {
      onSuccess: (response) => {
        if (response?.updateWorkflowItem?.errors?.length) {
          return;
        }
        // TODO - come back to this after talking to justin
        setSections((prevSections) => {
          const newSections = structuredClone(prevSections);
          newSections[sectionIndex].items2![itemIndex] = {
            ...newSections[sectionIndex].items2![itemIndex],
            ...response?.updateWorkflowItem?.workflowItem,
          } as any;
          return newSections;
        });
      },
    });
  };

  const handleTypeChange = (type: WorkflowItemType) => {
    setEditorType(type);
    if (newItemInEdit) {
      setNewItemInEdit({
        ...newItemInEdit,
        type,
      });
    }
    updateItem({ type });
  };

  // set the question number or section number
  const questionNumber = getParentItemNumberFromIndex(
    activeQuestionIndex,
    sections as unknown as WorkflowSection[],
  );

  if (
    sectionIndex !== null &&
    itemIndex !== null &&
    !sections[sectionIndex]?.items2?.[itemIndex]
  ) {
    return null;
  }

  return (
    <QuestionEditor
      key={
        newItemInEdit ? 'NEW' : sections[sectionIndex!]?.items2?.[itemIndex!].id
      }
      id={
        newItemInEdit ? 'NEW' : sections[sectionIndex!]?.items2?.[itemIndex!].id
      }
      questionNumber={questionNumber!}
      defaultValue={
        newItemInEdit
          ? newItemInEdit.prompt
          : sections[sectionIndex!]?.items2?.[itemIndex!].prompt || ''
      }
      type={editorType}
      onInputChange={createItem}
      onTypeChange={handleTypeChange}
      onDelete={handleDeleteClicked}
      deleteDisabled={sections.length < 1 && itemIndex === null}
    />
  );
};

const getActiveQuestion = (
  activeQuestionIndex: SectionItemTuple,
  sections: BuilderSection[],
) => {
  const [sectionIndex, itemIndex] = activeQuestionIndex;
  if (
    sectionIndex !== null &&
    itemIndex !== null &&
    !sections[sectionIndex]?.items2?.[itemIndex]
  ) {
    return null;
  }
  return sections[sectionIndex!]?.items2?.[itemIndex!];
};
