// eslint-disable-next-line import/prefer-default-export
export function searchGmailContacts(input) {
  const url = 'https://people.googleapis.com/v1/';
  // there is no single input that searches all gmail contact--we must minge together two responses
  const subPaths = ['people:searchContacts', 'otherContacts:search'];
  const params = `?pageSize=30&prettyPrint=true&readMask=names,emailAddresses,photos,metadata&query=${input}`;

  const promises = subPaths.map((subPath) =>
    gapi.client.request({
      path: `${url}${subPath}${params}`,
    }),
  );

  try {
    return Promise.all(promises);
  } catch (e) {
    return console.error(e);
  }
}
