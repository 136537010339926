/* eslint-disable jsx-a11y/anchor-is-valid */
import { Divider, Link, Stack, Typography, useTheme } from '@mui/material';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';

interface Props {
  currentPlan: string;
}

/**
 * This component renders the re-usable bottom section
 * for the tiered offerings.
 */
export default function TieredBottom({ currentPlan }: Props) {
  const { palette } = useTheme();

  return (
    <>
      <Stack direction="row" justifyContent="space-between" marginTop="2rem">
        <Link
          href="https://liscio.me/pricing"
          target="_blank"
          variant="body1"
          color={palette.common.primary[600]}
        >
          See full details on tiered offerings ›
        </Link>
        <Link
          href="mailto:support@liscio.me"
          variant="body1"
          color={palette.common.primary[600]}
        >
          <SmsOutlinedIcon fontSize="small" /> Contact support
        </Link>
      </Stack>
      <Divider sx={{ mt: '2rem', mb: '1rem' }} />
      <Stack width="504px">
        <Typography variant="h4">Billing Details</Typography>
        {currentPlan.toLocaleLowerCase().includes('core') ? (
          <Typography color={palette.common.secondary[500]}>
            Upgrade today to get immediate access to PRO tier features. Your
            upgrade will be reflected in your next billing cycle. Annual
            payments will be pro-rated
          </Typography>
        ) : (
          <Typography color={palette.common.secondary[500]}>
            {`We're working on automated billing. Once complete you'll be able to see statements and update payment details on this page. We'll let you know when it's ready!`}
          </Typography>
        )}
      </Stack>
    </>
  );
}
