import styled from '@emotion/styled';

import SmallLoaderGif from 'images/small-loader.gif';

// Liscio "Butterfly" Spinner that will center itself in the parent container
const Spinner = styled('div')({
  width: '100%',
  height: '100%',
  backgroundImage: `url(${SmallLoaderGif})`,
  backgroundSize: '100px',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundColor: 'rgba(0, 0, 0, 0)',
});

function LiscioSpinner() {
  return <Spinner />;
}

export default LiscioSpinner;
