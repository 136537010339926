import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '@mui/material';
import styled from '@emotion/styled';

import { palettePrimary } from 'theme/light';

import { CheckboxDefaultIcon, CheckboxCheckedIcon } from 'common/Icons';

export const SCheckboxDefaultIcon = styled(CheckboxDefaultIcon)((props) => ({
  backgroundColor: '#ffffff',
  color: palettePrimary.lightGray,
  fontSize: props.size,
  fontWeight: 'bold',
  lineHeight: `${props.size}px`,
}));

export const SCheckboxCheckedIcon = styled(CheckboxCheckedIcon)((props) => ({
  backgroundColor: '#ffffff',
  fontSize: props.size,
  fontWeight: 'bold',
  lineHeight: `${props.size}px`,
}));

function CustomCheckbox(props) {
  const { size, ...rest } = props;

  return (
    <span className="checkbox checkbox-primary check-container mx-2">
      <Checkbox
        icon={<i className="checkmark" style={{ zIndex: 0 }} />}
        checkedIcon={<i className="checkmark" style={{ zIndex: 0 }} />}
        indeterminateIcon={<i className="checkmark" style={{ zIndex: 0 }} />}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
      />
    </span>
    // <Checkbox
    //   icon={<SCheckboxDefaultIcon size={size} />}
    //   checkedIcon={<SCheckboxCheckedIcon size={size} />}
    //   // indeterminateIcon={<IndeterminateCheckBox sx={{ fontSize: '20px' }} />}
    //   // eslint-disable-next-line react/jsx-props-no-spreading
    //   {...rest}
    // />
  );
}

CustomCheckbox.propTypes = { size: PropTypes.number };

CustomCheckbox.defaultProps = { size: 16 };

export default CustomCheckbox;
