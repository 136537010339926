import React from 'react';
import { AccountData, EncryptedFieldInfo } from 'api/accounts';
import { DisplayData } from 'common/CustomFields';
import EncryptedFieldPreview from 'common/EncryptedFieldPreview/EncryptedFieldPreview';

export interface AccountAttributeProps {
  formData: AccountData;
  propertyValues: {
    value: string;
    label: string;
    sensitive?: boolean;
    encrypted?: boolean;
    customClassName?: string;
    section?: string;
    recordType?: string;
  };
  recordId: string;
  isEmployee?: boolean;
}

export const AccountAttribute = ({
  formData,
  propertyValues,
  recordId,
  isEmployee,
}: AccountAttributeProps) => {
  const shouldDisplayEncryptedField =
    propertyValues.encrypted && Boolean(propertyValues.recordType);

  const encryptedFieldInfo = formData[
    `encrypted_${propertyValues.value}` as keyof AccountData
  ] as EncryptedFieldInfo | undefined;
  const hasEncryptedValue = encryptedFieldInfo?.populated;

  return shouldDisplayEncryptedField ? (
    <EncryptedFieldPreview
      recordType={propertyValues.recordType!}
      fieldName={propertyValues.value}
      label={propertyValues.label}
      recordId={recordId}
      hasEncryptedValue={hasEncryptedValue}
    />
  ) : (
    <DisplayData
      data={formData}
      propertyValues={propertyValues}
      isEmployee={isEmployee}
    />
  );
};

export default AccountAttribute;
