export const isValidUrl: (
  urlString: string,
  acceptedProtocols?: Array<string>,
) => boolean = (urlString, acceptedProtocols = ['https:', 'http:']) => {
  try {
    const url = new URL(urlString);
    return acceptedProtocols.includes(url.protocol);
  } catch {
    return false;
  }
};
