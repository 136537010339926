// Simple function use to format the SMS phone numbers
export const formatSMSPhone = (phone: string) => {
  if (!phone) return '';

  if (phone.startsWith('+1'))
    return phone.replace(/^([+]1)(\d{3})(\d{3})(\d{4})$/g, '$1 $2 $3 $4');

  return phone.replace(/^(\d{3})(\d{3})(\d{4})$/g, '+1 $1 $2 $3');
};

// Simple function use to format the SMS phone numbers in a pleasant way
export const formatSMSPhonePretty = (phone: string) => {
  if (!phone) return '';

  // right now we are assuming american phone numbers and sometimes the server doesn't return the plus atm
  if (phone.startsWith('+1'))
    return phone.replace(/^([+]1)(\d{3})(\d{3})(\d{4})$/g, '$1 ($2) $3-$4');
  if (phone.length > 10)
    return phone.replace(/^(1)(\d{3})(\d{3})(\d{4})$/g, '+$1 ($2) $3-$4');

  return phone.replace(/^(\d{3})(\d{3})(\d{4})$/g, '+1 ($1) $2-$3');
};
