import { REQUESTS_PAGE_TITLES } from 'components/Requests/requests.constants';
import { useEffect } from 'react';
import { WorkflowView } from 'components/Requests/components/WorkflowView/WorkflowView';
import { WorkflowViewProvider } from 'components/Requests/components/WorkflowView/context/WorkflowViewContext';

export function ResponseView() {
  useEffect(() => {
    document.title = REQUESTS_PAGE_TITLES.response;
  }, []);

  return (
    <WorkflowViewProvider>
      <WorkflowView title={REQUESTS_PAGE_TITLES.response} />
    </WorkflowViewProvider>
  );
}
