import React from 'react';
import PropTypes from 'prop-types';
import { RECURRING_TAB } from 'constants/constants';
import TableHeader from './BillingTable/TableHeader';
import TableRow from './BillingTable/TableRow';

const Recurring = ({ allInvoices, handleColumnSorting, sortOrder, sortBy }) => (
  <table className="Recurring table caption-top">
    <TableHeader
      currentTab={RECURRING_TAB}
      handleColumnSorting={handleColumnSorting}
      sortOrder={sortOrder}
      sortBy={sortBy}
    />
    <tbody>
      {allInvoices && allInvoices.length === 0 && (
        <tr>
          <td className="NoRecordsFound">
            <div>No Records Found</div>
          </td>
        </tr>
      )}
      {allInvoices &&
        allInvoices.length > 0 &&
        allInvoices.map((row) => (
          <TableRow key={row.id} row={row} currentTab={RECURRING_TAB} />
        ))}
    </tbody>
  </table>
);

export default Recurring;

Recurring.propTypes = {
  allInvoices: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleColumnSorting: PropTypes.func.isRequired,
  sortOrder: PropTypes.string.isRequired,
  sortBy: PropTypes.string.isRequired,
};
