import React from 'react';
import styled from '@emotion/styled';
import { ReactComponent as IconMenu } from 'images/icons/expand_more.svg';

const StyledIcons = styled.span`
  &.button-icon {
    background: #ffffff;
    padding: 0.282rem 0.54rem;
    border: 2px solid #dfe3e8;
    border-radius: 2px;
    cursor: pointer;
    svg {
      fill: #919eab;
    }
  }
`;

const AssociateButton = ({ handleArrowToggle }) => (
  <StyledIcons className="button-icon" onClick={(e) => handleArrowToggle(e)}>
    <IconMenu width="1rem" />
  </StyledIcons>
);

export default AssociateButton;
