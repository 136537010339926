import React from 'react';
import { Modal, ModalBody, Button } from 'reactstrap';
import Interweave from 'interweave';

const blockStyle = { display: 'block' };
const ConfirmationModal = (props) => (
  <Modal
    isOpen={props.isOpen}
    className={`${props.className} customModal customModal--center customModal--alert customModal--w500 customModal--nopadding`}
  >
    {props.loading && <div id="loading" style={blockStyle} />}
    <div className="ModalHeader">
      <button
        type="button"
        className="close"
        aria-label="Close"
        onClick={props.noBtnClick}
        data-testid="close_confirmation_modal"
      >
        <i className="icon-close2" aria-hidden="true" />
      </button>
    </div>
    <ModalBody className="modalContent">
      <div className="modalContent--inner">
        {props.headerText && (
          <div className="modalContent--header text-large">
            {props.icon ? props.icon : ''}
            <h5 className="modalContent--header__heading">
              {props.headerText}
            </h5>
          </div>
        )}

        {props.isHtml ? (
          <Interweave allowAttributes content={props.messageText} />
        ) : (
          <p>{props.messageText}</p>
        )}

        <div className="row">
          <div
            className={`col-12 justify-content-end formbtn ${props.btnClassName}`}
          >
            <div className="btn-wrap btn--leftspace">
              {props.noBtnText && (
                <Button
                  className="btn btn-outline-light"
                  disabled={props.disableNoBtn}
                  onClick={props.noBtnClick}
                  data-testid={`inbox__cancel_${props?.testid}`}
                >
                  {props.noBtnText}
                </Button>
              )}

              {props.yesBtnText && (
                <Button
                  className="btn btn-primary"
                  disabled={props.disableYesBtn}
                  onClick={props.yesBtnClick}
                  data-testid={`inbox__confirm_${props?.testid}`}
                >
                  {props.yesBtnText}
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </ModalBody>
  </Modal>
);

export default ConfirmationModal;
