import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  IconButton,
  Stack,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
  Typography,
} from '@mui/material';
import { tooltipClasses } from '@mui/material/Tooltip';
import styled from '@emotion/styled';

import Checkbox from 'common/Checkbox';
import RatingComponent from 'common/RatingComponent';
import { EditIcon, CancelAgreementIcon } from 'common/Icons';

const SAccountListTableCell = styled(TableCell)(() => ({
  paddingTop: 20,
  paddingBottom: 20,
  cursor: 'pointer',
}));

const STooltip = styled(({ className, visible, ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
    fontSize: 16,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontSize: 16,
  },
}));

const SIconButton = styled(IconButton)((props) => ({
  visibility: props.visible,
  opacity: props.visible === 'visible' ? 1 : 0,
  transition: 'all ease-in-out 150ms',
}));

function AccountListTableBody(props) {
  const {
    componentContext,
    filterSelected,
    dense,
    page,
    rowsPerPage,
    items,
    selectedItems,
    onSelectedItemsChange,
    onItemClick,
  } = props;

  const [iSelectedItems, setISelectedItems] = useState(selectedItems);
  const [hoverItem, setHoverItem] = useState(undefined);

  const emptyRows =
    page > 1 ? Math.max(0, rowsPerPage - (items ? items.length : 0)) : 0;

  useEffect(() => {
    if (JSON.stringify(iSelectedItems) !== JSON.stringify(selectedItems)) {
      setISelectedItems(selectedItems);
    }
  }, [selectedItems]);

  const isAccountSelected = (item) =>
    iSelectedItems.some((value) => value.id === item.id);

  const onSelectionChange = (newSelectedItems) => {
    setISelectedItems(newSelectedItems);

    if (onSelectedItemsChange) onSelectedItemsChange(newSelectedItems);
  };

  const onItemCheckboxClick = (event, item) => {
    if (isAccountSelected(item)) {
      const itemIndex = iSelectedItems.findIndex(
        (value) => value.id === item.id,
      );
      const newSelectedItems = [...iSelectedItems];
      newSelectedItems.splice(itemIndex, 1);

      onSelectionChange(newSelectedItems);
    } else {
      onSelectionChange([...iSelectedItems, item]);
    }
  };

  return (
    <TableBody>
      {items &&
        items.map((row, index) => {
          const isSelected = isAccountSelected(row);
          const labelId = `account-table-checkbox-${index}`;

          return (
            <TableRow
              hover={false}
              aria-checked={isSelected}
              tabIndex={-1}
              key={row.name}
              selected={isSelected}
              onMouseEnter={() => setHoverItem(row)}
              onMouseLeave={() => setHoverItem(undefined)}
            >
              <SAccountListTableCell padding="checkbox">
                <Checkbox
                  disableRipple
                  color="primary"
                  checked={isSelected}
                  onChange={(event) => onItemCheckboxClick(event, row)}
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </SAccountListTableCell>
              <SAccountListTableCell
                component="th"
                id={labelId}
                scope="row"
                padding="none"
                onClick={() => onItemClick(row.id)}
              >
                <Typography variant="body2">{row.name}</Typography>
              </SAccountListTableCell>
              <SAccountListTableCell
                align="left"
                padding="none"
                onClick={() => onItemClick(row.id)}
              >
                <Typography variant="body2">
                  {row.external_account_id || '-'}
                </Typography>
              </SAccountListTableCell>
              <SAccountListTableCell
                align="left"
                padding="none"
                onClick={() => onItemClick(row.id)}
              >
                <Typography variant="body2">
                  {row.entity_type || '-'}
                </Typography>
              </SAccountListTableCell>
              <SAccountListTableCell
                align="left"
                padding="none"
                onClick={() => onItemClick(row.id)}
              >
                <Typography variant="body2">{row.status || '-'}</Typography>
              </SAccountListTableCell>
              <SAccountListTableCell align="left" padding="none">
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Stack onClick={() => onItemClick(row.id)}>
                    <RatingComponent rating={row.rating} />
                  </Stack>
                  <Stack direction="column" mr={3}>
                    {row.status !== null &&
                    row.status.toLowerCase() !== 'inactive' &&
                    !componentContext ? (
                      <STooltip title="Edit" arrow placement="top">
                        <SIconButton
                          aria-label="edit-account"
                          sx={{ width: 35, height: 35 }}
                          disableRipple
                          visible={
                            hoverItem && hoverItem.id === row.id
                              ? 'visible'
                              : 'hidden'
                          }
                          onClick={() => onItemClick(row.id, 'edit')}
                        >
                          <EditIcon />
                        </SIconButton>
                      </STooltip>
                    ) : null}
                    {row.is_restricted_account && (
                      <STooltip title="Restricted" arrow placement="top">
                        <IconButton
                          aria-label="restricted-account"
                          sx={{
                            width: 35,
                            height: 35,
                            color: 'red',
                          }}
                          disableRipple
                        >
                          <CancelAgreementIcon />
                        </IconButton>
                      </STooltip>
                    )}
                  </Stack>
                </Stack>
              </SAccountListTableCell>
            </TableRow>
          );
        })}
      {emptyRows > 0 && (
        <TableRow style={{ height: (dense ? 50 : 60) * emptyRows }}>
          <TableCell colSpan={6} />
        </TableRow>
      )}
      {(items === undefined || items === null || items.length === 0) &&
      filterSelected ? (
        <TableRow style={{ height: dense ? 50 : 60 }}>
          <TableCell align="center" colSpan={6}>
            <Typography>No Records Found</Typography>
          </TableCell>
        </TableRow>
      ) : null}
    </TableBody>
  );
}

AccountListTableBody.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  componentContext: PropTypes.any,
  filterSelected: PropTypes.bool,
  dense: PropTypes.bool,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  items: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  selectedItems: PropTypes.array,
  onSelectedItemsChange: () => {},
  onItemClick: () => {},
};

AccountListTableBody.defaultProps = {
  filterSelected: false,
  dense: false,
  page: 1,
  rowsPerPage: 15,
  items: [],
  selectedItems: [],
};

export default AccountListTableBody;
