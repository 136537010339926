import React, { useState, useEffect } from 'react';
import { Checkbox } from '@mui/material';
import styled from '@emotion/styled';
import { DEFAULT, INVOICE } from 'constants/constants';
import InvoiceForm from './InvoiceForm';
import NewInvoicePreview from './NewInvoicePreview';
import SearchInvoiceModal from '../../../Tasks/Create/task_type/SearchInvoiceModal';

const StyledDiv = styled.div`
  .NewInvoice__QuickbooksInvoice span {
    padding: 0 2px 2px 0;
  }
  .NewInvoice__QuickbooksCheckbox.Mui-checked,
  .NewInvoice__QuickbooksLink {
    color: #00aecc;
    cursor: pointer;
  }

  .NewInvoice__QuickbooksCheckbox.Mui-checked.Mui-disabled {
    color: rgba(0, 0, 0, 0.26);
  }
`;

const NewInvoice = ({
  allAccounts,
  allContacts,
  owners,
  items,
  paymentTerms,
  billingTemplates,
  formState,
  cpaInvoiceSettings,
  handleInputChange,
  handleDateChange,
  showInvoicePreview,
  isFormValid,
  getContactsForAccount,
  getAccountsForContact,
  onRowSelect,
  mode,
  dispatch,
  setDisableButton,
  checked,
  setChecked,
}) => {
  const { does_qb_generate_invoice_number, qb_account_status } =
    cpaInvoiceSettings;

  const isQBCustomerId = formState.account && formState.account.qb_customer_id;

  const [isOpen, setModalOpen] = useState(false);
  const [isCheckboxDisabled, setCheckboxDisabled] = useState(false);

  useEffect(() => {
    setChecked(isQBCustomerId && does_qb_generate_invoice_number);
  }, [isQBCustomerId]);

  function handleCheckboxChange() {
    setChecked(!checked);
  }

  function handleModalOpen() {
    setModalOpen(true);
  }

  function handleModalClose() {
    setModalOpen(false);
  }

  return (
    <StyledDiv className="NewInvoice" data-testid="newInvoice">
      {showInvoicePreview ? (
        <>
          <div className="NewInvoice__Header">
            <h5>{formState.customTemplateInput.toUpperCase()}</h5>
          </div>
          <NewInvoicePreview formState={formState} mode={mode} />
        </>
      ) : (
        <>
          <div className="NewInvoice__Header">
            {qb_account_status && mode === DEFAULT && (
              <>
                <h5>QuickBooks Link</h5>
                <h6>
                  Have an existing QuickBooks invoice? Liscio syncs data with
                  QuickBooks.
                  <span
                    className="NewInvoice__QuickbooksLink"
                    onClick={handleModalOpen}
                  >
                    &nbsp;Search and Link existing invoice.
                  </span>
                </h6>
                {!formState.isQBMode && (
                  <div className="NewInvoice__QuickbooksInvoice">
                    <Checkbox
                      className="NewInvoice__QuickbooksCheckbox"
                      checked={checked}
                      onChange={handleCheckboxChange}
                      disabled={formState.isQBMode || isCheckboxDisabled}
                      data-testid="checkbox"
                    />
                    <span>Automatically generate QuickBooks invoice</span>
                  </div>
                )}
              </>
            )}
          </div>
          <InvoiceForm
            allAccounts={allAccounts}
            allContacts={allContacts}
            owners={owners}
            items={items}
            paymentTerms={paymentTerms}
            cpaInvoiceSettings={cpaInvoiceSettings}
            billingTemplates={billingTemplates}
            formState={formState}
            handleInputChange={handleInputChange}
            handleDateChange={handleDateChange}
            isFormValid={isFormValid}
            getContactsForAccount={getContactsForAccount}
            getAccountsForContact={getAccountsForContact}
            checked={checked}
            onRowSelect={onRowSelect}
            mode={mode}
            dispatch={dispatch}
            setDisableButton={setDisableButton}
            setCheckboxDisabled={setCheckboxDisabled}
          />
          {isOpen && (
            <SearchInvoiceModal
              toggleModal={handleModalClose}
              onRowSelect={onRowSelect}
              accountName={formState.account}
              taskType={INVOICE}
            />
          )}
        </>
      )}
    </StyledDiv>
  );
};

export default NewInvoice;
