import { Tooltip, TooltipProps, styled, tooltipClasses } from '@mui/material';

export const TooltipStyled = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip arrow {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#333',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#333',
    fontSize: 13,
    padding: 10,
  },
});
