import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { CalendarIcon } from 'common/Icons';

type Props = {
  isValidStatus: () => boolean;
  toggleDisplay: () => void;
  changeDueDateText: string;
  handleClose: () => void;
};

export const RequestOptionsMenuItem = ({
  isValidStatus,
  toggleDisplay,
  changeDueDateText,
  handleClose,
}: Props) => {
  if (isValidStatus())
    return (
      <MenuItem
        onClick={() => {
          toggleDisplay();
          handleClose();
        }}
      >
        <ListItemIcon>
          <CalendarIcon />
        </ListItemIcon>
        <ListItemText>{changeDueDateText}</ListItemText>
      </MenuItem>
    );

  return null;
};
