import React, { useState, useEffect } from 'react';
import MultiSelectDropDown from 'common/MultiSelectDropDown';

function FilterByType(props) {
  const { selectedType, types, selectedTypeChange } = props;

  const [iSelectedType, setISelectedType] = useState(selectedType);

  useEffect(() => {
    if (iSelectedType !== JSON.stringify(selectedType)) {
      setISelectedType(selectedType);
    }
  }, [selectedType]);

  const onSelectedTypeChange = (value) => {
    setISelectedType(value);
    if (selectedTypeChange) selectedTypeChange(value);
  };

  return (
    <MultiSelectDropDown
      title="TYPE"
      options={types}
      value={iSelectedType}
      onSelectionChange={onSelectedTypeChange}
    />
  );
}

export default FilterByType;
