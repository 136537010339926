import React, { useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { AlertMessage } from 'utilities/utils';
import { ACT_AS_KEY, SWITCH_CPA } from 'constants/constants';
import MultipleLevelMenu from 'components/Sidebar/elements/MultipleLevelMenu';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import { useWebsocket } from 'websocket';
import { setAuthSession } from 'auth/session';
import { MenuItemIcon } from 'components/Sidebar/elements/MenuItemIcon';
import { useGetApiV5Me } from 'api/v5/auth/auth';
import { usePostApiV5AuthCpaSwitcher } from 'api/v5/firms-users/firms-users';
import { useFlags } from 'launchdarkly-react-client-sdk';

const MultipleCpa = ({ isMultipleCpa, multipleCpaData, homedata }) => {
  const { v5Login } = useFlags();
  const websocket = useWebsocket();
  const isActingAsAnEmployee = localStorage.getItem(ACT_AS_KEY);
  const { data: meData } = useGetApiV5Me();
  const cpaSwitcher = usePostApiV5AuthCpaSwitcher();

  /**
   * START V3.
   * @deprecated V3 Auth methods that are deprecated for removal
   */
  let currentCpaV3 = {};
  /** @deprecated */
  let otherCpasV3 = [];
  if (
    isMultipleCpa &&
    isMultipleCpa !== undefined &&
    multipleCpaData &&
    multipleCpaData.length
  ) {
    otherCpasV3 = multipleCpaData.filter((x) => !x.is_current_cpa);
    currentCpaV3 = multipleCpaData.filter((x) => x.is_current_cpa)[0];
  }
  /** @deprecated */
  const itemsV3 = {
    label: currentCpaV3.name,
    id: currentCpaV3.id,
    children: otherCpasV3.map((e) => {
      e.label = e.name;
      e.image = e.logo;
      return e;
    }),
  };
  /** @deprecated */
  function switchCpaV3(e, item) {
    if (!item.is_current_cpa) {
      const parsedUrl = new URL(item.custom_liscio_url);
      const data = { cpa_id: item.id };
      const request = axios.post(SWITCH_CPA, data);
      request.then((res) => {
        /**
         * TODO: How can we optimize this with the removed legacy auth cookies?
         * Can we just reload the page after setting the new auth cookie?
         * That may be the easiest?
         * We should no longer need `setLegacyRootDomainAuthSession`
         * NOTE: DO we need to udpate the user in local storage?
         */
        if (res.data.status === 200) {
          websocket.consumer.disconnect();
          localStorage.clear();
          // Update auth session and redirect
          setAuthSession({ auth_token: res.data.auth_token });
          window.location.replace(parsedUrl);
        } else {
          AlertMessage('error', 'Something went wrong', 3000);
        }
      });
    }
  }
  /**
   * END V3. You can safely delete all of the above when we fully move off of V3
   */

  /**
   * V5 CPA Switcher
   */
  const itemsV5 = useMemo(() => {
    if (meData?.cpa_data && homedata?.cpa_uuid) {
      const currentCpa = meData.cpa_data.find(
        (cpa) => cpa.cpa_uuid === homedata.cpa_uuid,
      );
      const otherCpas = meData.cpa_data.filter(
        (cpa) => cpa.cpa_uuid !== homedata.cpa_uuid,
      );

      return {
        id: currentCpa.cpa_uuid,
        label: currentCpa.cpa_name,
        image: currentCpa.cpa_logo_url || currentCpa.cpa_name,
        children: otherCpas.map((otherCpa) => ({
          ...otherCpa,
          id: otherCpa.cpa_uuid,
          label: otherCpa.cpa_name,
          image: otherCpa.cpa_logo_url || otherCpa.cpa_name,
        })),
      };
    }
    return undefined;
  }, [homedata, meData]);
  const switchCpaV5 = useCallback(
    async (e, item) => {
      const cpaRedirectUrl = item.company_url;
      try {
        const result = await cpaSwitcher.mutateAsync({
          data: { cpa_uuid: item.cpa_uuid },
        });

        if (result.auth_token) {
          websocket.consumer.disconnect();
          localStorage.clear();
          // Update auth session and redirect
          setAuthSession({ auth_token: result.auth_token });
          window.location.replace(cpaRedirectUrl);
        } else {
          AlertMessage('error', 'Something went wrong', 3000);
        }
      } catch {
        AlertMessage('error', 'Something went wrong', 3000);
      }
    },
    [cpaSwitcher, websocket.consumer],
  );

  /**
   * Resolve flagged API version data
   * NOTE: Remove this resolver when we remove V3 and rename the V5 constants above
   */
  const { items, switchCpa } = useMemo(
    () => ({
      items: v5Login ? itemsV5 : itemsV3,
      switchCpa: v5Login ? switchCpaV5 : switchCpaV3,
    }),
    [v5Login, itemsV3, switchCpaV3, itemsV5, switchCpaV5],
  );

  if (items?.id && items?.children?.length && !isActingAsAnEmployee) {
    return (
      <MultipleLevelMenu
        onSelect={switchCpa}
        component={
          <ListItemButton key={items.id}>
            <ListItemIcon>
              <MenuItemIcon iconClass="icon-switch-account" />
            </ListItemIcon>
            <ListItemText primary={items.label.toUpperCase()} />
          </ListItemButton>
        }
        menuItems={items}
      />
    );
  }

  return null;
};

const mapStateToProps = (state) => ({
  homedata: state.homeReducer.data,
  isMultipleCpa: state.notificationReducer.isMultipleCpa,
  multipleCpaData: state.notificationReducer.multipleCpaData,
});

export default connect(mapStateToProps)(MultipleCpa);
