import dayjs from 'dayjs';

export interface MessagesMungedByDay {
  [key: string]: Array<Message>;
}

export function mungeMessagesByDay(messages: Message[]) {
  return messages.reduce((acc, message) => {
    const formattedDate = dayjs(message.createdAt).format('MMM D YYYY');

    if (acc[formattedDate]) {
      acc[formattedDate].push(message);
    } else {
      acc[formattedDate] = [message];
    }

    return acc;
  }, {} as MessagesMungedByDay);
}
