/* eslint-disable class-methods-use-this */
/* eslint-disable no-script-url */
/* eslint-disable react/jsx-no-script-url */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment-timezone';
import { Modal, ModalBody } from 'reactstrap';
import Interweave from 'interweave';
import {
  getCpaPreferences,
  dueDateColor,
  removeDuplicateTags,
  tagLabel,
  isUserEmployee,
  getInitials,
} from 'utilities/utils';
import ReactTooltip from 'react-tooltip';
import ManualSourceModal from 'components/Files/ManualSourceModal';
import { GET_FILE_SOURCES_DATA } from 'constants/constants';
import pdfTextImage from 'images/pdfText_img.png';
import { withRouter } from 'utilities/withRouterWrapper';

const timeZone =
  Intl.DateTimeFormat().resolvedOptions().timeZone || moment.tz.guess(true);
const noneStyle = { display: 'none' };
const blockStyle = { display: 'block' };
class MultipleSourceModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      preferences: {},
      fileDetails: {},
      loading: false,
      operationPerformed: false,
    };
  }

  componentDidMount() {
    const preferences = getCpaPreferences();
    this.setState({ preferences });
    this.getdata(true);
  }

  getdata = (load) => {
    const { location } = this.props;
    if (location && location.hash && location.hash.indexOf('#multiple') > -1) {
      const id = location.hash.split('-')[1];
      if (load) {
        this.setState({ loading: true });
      }
      const request = axios.get(`${GET_FILE_SOURCES_DATA}/${id}`);
      request
        .then((res) => {
          this.setState({ loading: false });
          if (res.data.status === 200) {
            const keys = Object.keys(res.data.data[0]);
            if (keys.length === 1 && keys[0] === 'manuals') {
              this.setState({
                isManual: true,
                fileDetails: res.data.data[0].manuals[0],
                showManualSource: true,
              });
            } else {
              this.setState({
                list: res.data.data[0],
                fileName: res.data.file_name,
              });
            }
          }
        })
        .catch((err) => {
          this.setState({ loading: false });
          console.log('err', err);
        });
    }
  };

  rowClick = (item, type) => {
    const { navigate } = this.props;
    let url = '';
    if (type === 'manual') {
      this.setState({
        fileDetails: item,
        showManualSource: true,
      });
    } else {
      if (type === 'task') {
        url = `task/detail/${item.id}`;
      } else if (type === 'message') {
        url = `inbox/${encodeURIComponent(item.thread_id)}`;
      }
      navigate(`/${url}`, { state: { from: 'notify' } });
    }
  };

  updateRecipient = (index) => {
    if (index === 'deleteall') {
      this.props.closeModal('showMultipleSource', true);
    } else {
      this.getdata();
    }
    this.setState({ operationPerformed: true });
  };

  closeManualModal = (type, index) => {
    this.setState(
      {
        showManualSource: false,
        operationPerformed: index,
        // list: undefined,
      },
      () => {
        const { location, navigate } = this.props;
        const { hash, pathname } = location;
        console.log('path', pathname);
        if (
          pathname.includes('accountdetails') ||
          pathname.includes('contactdetails')
        ) {
          if (hash && hash.indexOf('#files') > -1) {
            navigate(`${location.pathname}#files`);
          }
          if (hash && hash.indexOf('#multiple') > -1) {
            navigate(location.pathname);
          } else {
            navigate('/files');
          }
        } else if (pathname.includes('files')) {
          if (hash && hash.indexOf('#multiple') > -1) {
            navigate(location.pathname);
          }
        }
      },
    );
  };

  renderMessages() {
    const { list } = this.state;
    let messageList = '';
    if (list && list.messages && list.messages.length) {
      const { messages } = list;
      messageList = messages.map((each) => {
        let senderArray = '';
        if (each.all_senders && each.all_senders.length) {
          senderArray = each.all_senders.map((s) => s).join(', ');
        }
        const initials = each.sender
          ? (each.sender.split(' ')[0]
              ? each.sender.split(' ')[0].charAt(0)
              : '') +
            (each.sender.split(' ')[1]
              ? each.sender.split(' ')[1].charAt(0)
              : '')
          : '';
        let documentName = '';
        if (each.documents && each.documents.length) {
          let index = 0;
          if (each.read) {
            index = each.documents.length - 1;
          }
          if (each.documents[index].display_name) {
            documentName = each.documents[index].display_name.substring(0, 15);
          } else if (each.documents[index].aws_url_file_name) {
            documentName = each.documents[index].aws_url_file_name.substring(
              0,
              15,
            );
          }
        }
        let time = each.tym;
        if (each.tym.indexOf('ago') === -1) {
          time = moment.tz(time, timeZone).format('MM/DD/YYYY');
        }
        return (
          <div
            className="row"
            onClick={() => this.rowClick(each, 'message')}
            key={each.id}
          >
            <div className="tdCol col-auto pl-1 pr-0">
              <i className="icon-message mr-1 vertical-middle text-medium text-dark" />
            </div>
            <div className="tdCol col-auto pr-1">
              <span
                className={`avatar avatar--w30 pr-2 ${
                  each.user_type === 'employee'
                    ? 'avatar--border avatar--borderPrimary'
                    : ''
                }`}
              >
                {each.avatar ? (
                  <img
                    src={each.avatar}
                    alt=""
                    className="user-photo rounded-circle mr-0"
                  />
                ) : (
                  <span className="uName mr-0">{initials.toUpperCase()}</span>
                )}
              </span>
            </div>
            <div className="tdCol col-auto">
              <span>
                {senderArray.substring(0, 20) +
                  (senderArray.indexOf(',') > -1 ? '...' : '')}
              </span>
            </div>
            <div className="tdCol col-8">
              <span className="w-100 pull-left font-weight-bold">
                {each.documents && each.documents.length > 0 ? (
                  <span className="tag tag--fill text-uppercase pull-right mx-2">
                    <img src={pdfTextImage} alt="" />
                    {documentName}
                  </span>
                ) : null}
                {each.title}
              </span>
              <br />
              <span className="text-dark tdMsg noAnchor">
                {/* <span dangerouslySetInnerHTML={{ __html: each.content_limit }} /> */}
                <Interweave allowAttributes content={each.content_limit} />
              </span>
            </div>
            <div className="col-auto tdCol text-right ml-auto pr-3">
              <span className="text-dark label-large text-uppercase">Date</span>
              <br />
              <span>{time}</span>
            </div>
          </div>
        );
      });
    }
    return messageList;
  }

  renderTasks() {
    const { list, preferences } = this.state;
    let tasksList = '';
    if (list && list.tasks && list.tasks.length) {
      const { tasks } = list;
      tasksList = tasks.map((each) => {
        const dateColor = dueDateColor(each.full_due_date, each.status);
        const color = preferences.task_type_color.map((ele) => {
          if (each.task_type_key === ele.key) {
            return ele.label;
          }
          return null;
        });
        const badge = color.find((el) => el !== null);
        let time = each.updated_at;
        if (each.updated_at.indexOf('ago') === -1) {
          time = moment.tz(time, timeZone).format('MM/DD/YY');
        }
        return (
          <div
            className="row"
            onClick={() => this.rowClick(each, 'task')}
            key={each.id}
          >
            <div className="tdCol col-auto pl-1 pr-0">
              <i className="icon-Tasks mr-1 vertical-middle text-medium text-dark" />
            </div>
            <div className=" col-2 tdCol">
              <span
                className={`badge ${
                  badge !== undefined ? badge : 'badge-onboard'
                }`}
              >
                {each.task_type_value}
              </span>
              <br />
              <span>{each.subject}</span>
              <br />
            </div>
            <div className="col-2 tdCol">
              <span className="text-dark label-large text-uppercase">
                Assigned to
              </span>
              <br />
              <a data-tip="true" data-for="3728global" currentitem="false">
                <span>{each.name}</span>
              </a>
            </div>
            <div className=" col-2 tdCol">
              <span className="text-dark label-large text-uppercase">
                Account
              </span>
              <br />
              <a data-tip="true" data-for="undefinedglobal_account">
                <span>
                  {each.task_type_key === 'to_do'
                    ? each.todo_account_name
                      ? each.todo_account_name
                      : '-'
                    : each.account_name
                    ? each.account_name
                    : '-'}
                </span>
              </a>
            </div>
            <div className=" col-2 tdCol">
              <span className="text-dark label-large text-uppercase">
                Owner
              </span>
              <br />
              <span>{each.owner}</span>
            </div>
            <div className={` col-1 tdCol ${dateColor}`}>
              <span className="text-dark label-large text-uppercase">
                Due Date
              </span>
              <br />
              <span className="posRel">{each.due_date}</span>
            </div>
            <div className="col-auto tdCol text-right ml-auto pr-3">
              <span className="text-dark label-large text-uppercase">Date</span>
              <br />
              <span>
                {time}
                {each.is_recurring ? (
                  <a
                    href="javascript:;"
                    className="icnRcur icon-Recurring vertical-middle text-primary px-1 py-0"
                  />
                ) : null}
              </span>
            </div>
          </div>
        );
      });
    }
    return tasksList;
  }

  renderManual() {
    const { list } = this.state;
    let manualList = '';
    if (list && list.manuals && list.manuals.length) {
      const { manuals } = list;
      const setArray = (array, tag, index) => {
        const tagList = array.map((item) => (
          <span key={array.indexOf(item)} className="tag tag--fill">
            {tagLabel(item)}
          </span>
        ));
        return (
          <div
            key={index + 1}
            className="drophover drophover--rightTop d-inline-block"
          >
            <span className="tag tag--fillWhite my-1 drophover-toggle">
              +{index}
            </span>
            <div className="drophover-menu drophover-menu--custom">
              {tagList}
            </div>
          </div>
        );
      };
      manualList = manuals.map((each) => {
        const tagsArray = [];
        let constTag = 0;
        if (each.tags === undefined) {
          return null;
        }
        const removeDuplicateTag = removeDuplicateTags(each.tags);
        const tags = removeDuplicateTag.map((tag, index) => {
          let tagIndex;
          if (removeDuplicateTag.indexOf(tag) <= 1) {
            tagIndex = (
              <span
                key={removeDuplicateTag.indexOf(tag)}
                className="tag tag--fill my-1"
              >
                {tagLabel(tag)}
              </span>
            );
          } else if (removeDuplicateTag.indexOf(tag) >= 2) {
            if (removeDuplicateTag.length - 1 - index !== 0) {
              constTag += 1;
              tagsArray.push(removeDuplicateTag[index]);
              return null;
            }
            constTag += 1;
            tagsArray.push(removeDuplicateTag[index]);
            tagIndex = setArray(tagsArray, tag, constTag);
          }
          return tagIndex;
        });
        const date = moment
          .tz(each.date_created, timeZone)
          .format('MM/DD/YYYY');
        return (
          <div className="row">
            <div className="tdCol col-auto pl-1 pr-0">
              <i className="icon-File mr-1 vertical-middle text-medium text-dark" />
            </div>
            <div
              onClick={() => this.rowClick(each, 'manual')}
              className="col-3 tdCol"
            >
              <div className="tdata d-flex">
                <div className="tdata--detail tdata--icon ">
                  <h5 className="fontSize fileName mb-0">
                    {each.doc_name}
                    {each.is_private && (
                      <i className="icon-Lock text-dark ml-1" />
                    )}
                  </h5>
                  <a href="javascript:;">
                    <span className="attachBy text-dark">{`by ${each.uploaded_by} • Manual`}</span>
                  </a>
                </div>
              </div>
            </div>
            <div
              onClick={() => this.rowClick(each, 'manual')}
              className={isUserEmployee() ? 'col-2 tdCol' : 'col-3 tdCol'}
            >
              <span className="text-dark label-large text-uppercase">Tags</span>
              <br />
              {tags.length > 0 ? tags : '-'}
            </div>
            {isUserEmployee() && (
              <div className="col-3 tdCol">
                <span className="text-dark label-large text-uppercase">
                  Associated with
                </span>
                <br />
                {this.renderRecipients(each)}
              </div>
            )}
            <div
              onClick={() => this.rowClick(each, 'manual')}
              className={isUserEmployee() ? 'col-1 tdCol' : 'col-2 tdCol'}
            >
              <span className="text-dark label-large text-uppercase">
                Month
              </span>
              <br />
              <span>
                {each.upload_file_month ? each.upload_file_month : '-'}
              </span>
            </div>
            <div
              onClick={() => this.rowClick(each, 'manual')}
              className={isUserEmployee() ? 'col-1 tdCol' : 'col-2 tdCol'}
            >
              <span className="text-dark label-large text-uppercase">Year</span>
              <br />
              <span>{each.upload_file_year ? each.upload_file_year : '-'}</span>
            </div>
            <div
              onClick={() => this.rowClick(each, 'manual')}
              className="col-auto tdCol text-right ml-auto pr-3"
            >
              <span className="text-dark label-large text-uppercase">Date</span>
              <br />
              <span>{date}</span>
            </div>
          </div>
        );
      });
    }
    return manualList;
  }

  renderRecipients(item) {
    let sharedWith = '';
    if (item.recipient && item.recipient.length) {
      sharedWith = item.recipient.map((each, index) => {
        if (index < 4) {
          return (
            <div
              key={index}
              data-tip
              data-for={`${each.initials}_${index}`}
              className="avatar avatar--w30 d-inline-block"
            >
              {each.name ? (
                each.avatar ? (
                  <img
                    src={each.avatar}
                    alt="I"
                    width="30"
                    height="30"
                    className="rounded-circle"
                  />
                ) : (
                  <span className="uName">{each.initials}</span>
                )
              ) : each.account_name ? (
                <span className="uName">{getInitials(each.account_name)}</span>
              ) : (
                ''
              )}
              <ReactTooltip id={`${each.initials}_${index}`} effect="solid">
                <span className="contactcard-team-member">
                  {each.name +
                    (each.account_name ? ` . ${each.account_name}` : '')}
                </span>
              </ReactTooltip>
            </div>
          );
        }
        if (index === 4) {
          const list = item.recipient.map((e, i) => {
            if (i >= 4) {
              return (
                <a
                  key={item.recipient.indexOf(e)}
                  href="javascript:"
                  className="dropdown-item py-1"
                >
                  <div className="d-flex">
                    <span className="avatar avatar--w30 align-self-start">
                      <span className="uName">
                        {e.initials || getInitials(e.account_name)}
                      </span>
                    </span>
                    <div className="">
                      <h5 className="fontSize letterSpace-1 mb-0">{e.name}</h5>
                      <h4 className="subHeadline text-light letterSpace-1 mb-0">
                        {e.account_name}
                      </h4>
                    </div>
                  </div>
                </a>
              );
            }
            return null;
          });
          return (
            <div
              key={index}
              className="dropdown dropdown--small d-inline-block ml-0"
            >
              <a
                href="javascript:"
                className="btn p-0 dropdown-toggle border-0"
                data-toggle="dropdown"
                style={{ lineHeight: '20px' }}
              >
                <span className="avatar avatar--w30 align-self-center">
                  <span className="uName">+{item.recipient.length - 4}</span>
                </span>
                <span className="dropupWrap--caret" />
              </a>
              <div className="dropdown-menu dropdown-menu-right">{list}</div>
            </div>
          );
        }
        return null;
      });
    }
    return sharedWith;
  }

  render() {
    const { fileDetails, fileName, operationPerformed, isManual, list } =
      this.state;
    // this is show only when file have source is manual
    if (isManual) {
      return (
        <ManualSourceModal
          updateRecipient={this.updateRecipient}
          closeModal={() => {
            this.props.closeModal('showMultipleSource', operationPerformed);
            this.closeManualModal();
          }}
          fileDetails={fileDetails}
        />
      );
    }
    return (
      <div id="content">
        <div className="center-wrapper">
          <div
            id="loading"
            style={this.state.loading ? blockStyle : noneStyle}
          />
          {list && (
            <Modal
              className="customModal customModal--w1150 customModal--nopadding"
              isOpen
            >
              <div className="ModalHeader">
                <button
                  onClick={() =>
                    this.props.closeModal(
                      'showMultipleSource',
                      operationPerformed,
                    )
                  }
                  type="button"
                  className="close"
                  aria-label="Close"
                >
                  <i className="icon-close2" aria-hidden="true" />
                </button>
              </div>
              <ModalBody className="modalContent">
                <div className="modalContent--inner">
                  <div className="modalContent--header mb-0">
                    <h5 className="modalContent--header__heading">
                      {fileName || ''}
                    </h5>
                  </div>
                  <div className="modalForm">
                    <div className="tRow tRow--body">
                      {this.renderMessages()}
                      {this.renderTasks()}
                      {this.renderManual()}
                    </div>
                  </div>
                </div>
              </ModalBody>
            </Modal>
          )}
          {this.state.showManualSource && (
            <ManualSourceModal
              updateRecipient={this.updateRecipient}
              closeModal={this.closeManualModal}
              fileDetails={fileDetails}
            />
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(MultipleSourceModal);
