import React from 'react';
import styled from '@emotion/styled';
import { getAvatarName, getDocName } from 'components/Infocus/inFocusUtils';
import ActivityCard from 'components/Infocus/elements/ActivityCard';

const StyledDiv = styled.div`
  margin-bottom: 1em;
`;

const RenderActivityList = ({ activityList }) => {
  function renderInvoiceComponent(activity) {
    return (
      <ActivityCard
        activityType={activity.type}
        key={activity.id}
        id={activity.id}
        entityEmailThreadId={activity.entity_email_thread_id}
        status={activity.status}
        avatarName={getAvatarName(
          activity.users?.length ? activity.users[0].full_name : activity.user,
        )}
        avatar={
          activity.users?.length ? activity.users[0].activity : activity.avatar
        }
        contactName={activity.user}
        user={
          activity.users?.length ? activity.users[0].full_name : activity.user
        }
        description={activity.description}
        subject={activity.subject}
        title={activity.title}
        percentComplete={activity.percent_complete}
        documents={getDocName(activity.documents, true)}
        lastActivityAt={activity.last_activity_at}
        contactSmsPhoneNumber={
          activity.users?.length
            ? activity.users[0].contact_sms_phone_number
            : activity.contact_sms_phone_number
        }
        contactSmsEnabled={
          activity.users?.length
            ? activity.users[0].contact_sms_enabled
            : activity.contact_sms_enabled
        }
        constactSmsThreadId={
          activity.users?.length
            ? activity.users[0].contact_sms_thread_id
            : activity.contact_sms_thread_id
        }
        // for task and invoice
        taskType={activity.task_type}
        invoiceNumber={activity.invoice_number}
        invoiceAmount={Number(activity.invoice_amount).toFixed(2)}
        dueBy={activity.due_by}
        uuid={activity.uuid}
        isRecurring
      />
    );
  }

  return (
    <div className="ActivityList">
      {activityList?.length &&
        activityList.map((item) => (
          <StyledDiv>{renderInvoiceComponent(item)}</StyledDiv>
        ))}
    </div>
  );
};

export default RenderActivityList;
