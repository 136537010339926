import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';

type Props = {
  open: boolean;
  hideDialog: () => void;
  markComplete: () => void;
};

export const MarkCompleteDialog = ({
  open,
  hideDialog,
  markComplete,
}: Props) => (
  <Dialog
    open={open}
    onClose={() => hideDialog}
    aria-labelledby="Mark Workflow Item Complete"
  >
    <DialogContent>
      <Typography variant="body1">
        Are you sure you want to submit this Request? Your client will{' '}
        <span style={{ fontWeight: 'bold', textDecoration: 'italic' }}>
          not
        </span>{' '}
        be able to make further edits after submission.
      </Typography>
    </DialogContent>
    <DialogActions>
      <Button variant="contained" color="secondary" onClick={hideDialog}>
        Cancel
      </Button>
      <Button variant="contained" onClick={markComplete}>
        Mark Complete
      </Button>
    </DialogActions>
  </Dialog>
);
