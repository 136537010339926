import Axios from 'axios';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Chip, FormControlLabel, Stack, Typography } from '@mui/material';
import styled from '@emotion/styled';

import { GET_ALL_EMPLOYEES } from 'constants/constants';

import Checkbox from 'common/Checkbox';
import DropDown from 'common/DropDown';
import MultiSelectDropDown from 'common/MultiSelectDropDown';

import { getRole, getCpaPreferences, generateFullName } from 'utilities/utils';

const SFilterChip = styled(Chip)(() => ({ marginTop: '5px !important' }));

function DropdownFilters(props) {
  const {
    filterSelected,
    values: {
      selectedEntityType,
      selectedAccountStatus,
      selectedAccountStage,
      selectedCsTeam,
      selectedRestrictedAccount,
    },
    selectedEntityTypeChange,
    selectedAccountStatusChange,
    selectedAccountStageChange,
    selectedCsTeamChange,
    selectedRestrictedAccountChange,
  } = props;

  const [iSelectedEntityType, setISelectedEntityType] =
    useState(selectedEntityType);
  const [iSelectedAccountStatus, setISelectedAccountStatus] = useState(
    selectedAccountStatus,
  );
  const [iSelectedAccountStage, setISelectedAccountStage] =
    useState(selectedAccountStage);
  const [iSelectedCsTeam, setISelectedCsTeam] = useState(selectedCsTeam);
  const [iSelectedRestrictedAccount, setISelectedRestrictedAccount] = useState(
    selectedRestrictedAccount,
  );

  // TODO: Use Selector
  const [csTeams, setCsTeams] = useState([]);

  const getRoleOfCst = (cst) => {
    const { user_role } = preferences;

    let tRole = cst.role.replace(/_/g, ' ');
    user_role.map((item) => {
      if (cst.role === item.key) {
        tRole = item.label;
      }

      return tRole;
    });

    return tRole;
  };
  const getCSTeams = async () => {
    try {
      const res = await Axios.get(GET_ALL_EMPLOYEES);

      let tCsTeams = res.data.data;
      tCsTeams = tCsTeams.map((each) => ({
        ...each,
        headline: generateFullName(each),
        subHeadline: getRoleOfCst(each),
      }));

      setCsTeams(tCsTeams);

      return tCsTeams;
    } catch (e) {
      return [];
    }
  };

  useEffect(() => {
    if (iSelectedEntityType !== JSON.stringify(selectedEntityType)) {
      setISelectedEntityType(selectedEntityType);
    }
    if (iSelectedAccountStatus !== JSON.stringify(selectedAccountStatus)) {
      setISelectedAccountStatus(selectedAccountStatus);
    }
    if (iSelectedAccountStage !== JSON.stringify(selectedAccountStage)) {
      setISelectedAccountStage(selectedAccountStage);
    }
    if (iSelectedCsTeam !== JSON.stringify(selectedCsTeam)) {
      setISelectedCsTeam(selectedCsTeam);
    }
    if (iSelectedRestrictedAccount !== selectedRestrictedAccount) {
      setISelectedRestrictedAccount(selectedRestrictedAccount);
    }
  }, [
    selectedEntityType,
    selectedAccountStatus,
    selectedAccountStage,
    selectedCsTeam,
    selectedRestrictedAccount,
  ]);

  useEffect(() => {
    // TODO: Implement this with Thunk, Dispatch
    getCSTeams();
  }, []);

  const role = getRole();
  const preferences = getCpaPreferences();

  const onSelectedEntityTypeChange = (value) => {
    setISelectedEntityType(value);

    if (selectedEntityTypeChange) selectedEntityTypeChange(value);
  };
  const onSelectedAccountStatusChange = (value) => {
    setISelectedAccountStatus(value);

    if (selectedAccountStatusChange) selectedAccountStatusChange(value);
  };
  const onSelectedAccountStageChange = (value) => {
    setISelectedAccountStage(value);

    if (selectedAccountStageChange) selectedAccountStageChange(value);
  };
  const onSelectedCsTeamChange = (value) => {
    setISelectedCsTeam(value);

    if (selectedCsTeamChange) selectedCsTeamChange(value);
  };
  const onToggleRestrictedAccount = (value) => {
    setISelectedRestrictedAccount(value);

    if (selectedRestrictedAccountChange) selectedRestrictedAccountChange(value);
  };

  const removeEntityTypeFilter = (option) => {
    const newSelectedEntityType = [...iSelectedEntityType];
    const index = newSelectedEntityType.findIndex(
      (value) => value.key === option.key,
    );
    newSelectedEntityType.splice(index, 1);

    onSelectedEntityTypeChange(newSelectedEntityType);
  };

  const removeAccountStatusFilter = (option) => {
    const newSelectedAccountStatus = [...iSelectedAccountStatus];
    const index = newSelectedAccountStatus.findIndex(
      (value) => value.key === option.key,
    );
    newSelectedAccountStatus.splice(index, 1);

    onSelectedAccountStatusChange(newSelectedAccountStatus);
  };

  const removeAccountStageFilter = (option) => {
    const newSelectedAccountStage = [...iSelectedAccountStage];
    const index = newSelectedAccountStage.findIndex(
      (value) => value.key === option.key,
    );
    newSelectedAccountStage.splice(index, 1);

    onSelectedAccountStageChange(newSelectedAccountStage);
  };

  const clearCSTeamFilter = () => {
    onSelectedCsTeamChange(undefined);
  };

  const clearRestrictedAccountFilter = () => {
    onToggleRestrictedAccount(false);
  };

  const clearFilters = () => {
    onSelectedEntityTypeChange([]);
    onSelectedAccountStatusChange([]);
    onSelectedAccountStageChange([]);
    onSelectedCsTeamChange(undefined);
    onToggleRestrictedAccount(false);
  };

  const { entity_type, account_status, account_stage } = preferences;

  return (
    <Stack flexWrap="wrap" maxWidth={850} gap={1}>
      <Stack direction="row" alignItems="center" flexWrap="wrap" gap={1}>
        <Typography variant="body2" padding={1}>
          Filter by
        </Typography>
        <Stack direction="row" alignItems="center" flexWrap="wrap" gap={1}>
          <MultiSelectDropDown
            title="ENTITY TYPE"
            options={entity_type}
            value={iSelectedEntityType}
            onSelectionChange={onSelectedEntityTypeChange}
          />
          <MultiSelectDropDown
            title="ACCOUNT STATUS"
            options={account_status}
            value={iSelectedAccountStatus}
            onSelectionChange={onSelectedAccountStatusChange}
          />
          <MultiSelectDropDown
            title="ACCOUNT STAGE"
            options={account_stage}
            value={iSelectedAccountStage}
            onSelectionChange={onSelectedAccountStageChange}
          />

          {role && role === 'specialist' ? null : (
            <DropDown
              withSearch
              title="CS TEAM"
              placeHolder="Getting Employees"
              options={csTeams}
              value={iSelectedCsTeam}
              onSelectionChange={onSelectedCsTeamChange}
            />
          )}
        </Stack>

        <Stack direction="row" alignItems="center" gap={2}>
          {role && role === 'firm_admin' && (
            <FormControlLabel
              label={
                <Typography
                  variant="body2"
                  sx={{ fontWeight: 'bold', fontSize: '12.8px' }}
                >
                  Restricted Account?
                </Typography>
              }
              control={
                <Checkbox
                  id="restrictaccount"
                  name="restrictaccount"
                  checked={iSelectedRestrictedAccount}
                  onChange={() =>
                    onToggleRestrictedAccount(!iSelectedRestrictedAccount)
                  }
                />
              }
            />
          )}

          {filterSelected && (
            <Chip size="small" label="Clear All" onDelete={clearFilters} />
          )}
        </Stack>
      </Stack>

      <Stack direction="row" gap={1} flexWrap="wrap">
        {iSelectedEntityType.map((option) => (
          <SFilterChip
            key={option.key}
            size="small"
            variant="outlined"
            label={option.label}
            onDelete={() => {
              removeEntityTypeFilter(option);
            }}
          />
        ))}
        {iSelectedAccountStatus.map((option) => (
          <SFilterChip
            key={option.key}
            size="small"
            variant="outlined"
            label={option.label}
            onDelete={() => {
              removeAccountStatusFilter(option);
            }}
          />
        ))}
        {iSelectedAccountStage.map((option) => (
          <SFilterChip
            key={option.key}
            size="small"
            variant="outlined"
            label={option.label}
            onDelete={() => {
              removeAccountStageFilter(option);
            }}
          />
        ))}
        {role && role === 'specialist'
          ? null
          : iSelectedCsTeam && (
              <SFilterChip
                size="small"
                variant="outlined"
                label={iSelectedCsTeam.headline}
                onDelete={clearCSTeamFilter}
              />
            )}
        {role && role === 'firm_admin' && iSelectedRestrictedAccount && (
          <SFilterChip
            size="small"
            variant="outlined"
            label="Restricted Account"
            onDelete={clearRestrictedAccountFilter}
          />
        )}
      </Stack>
    </Stack>
  );
}

DropdownFilters.propTypes = {
  filterSelected: PropTypes.bool,
  values: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    selectedEntityType: PropTypes.array,
    // eslint-disable-next-line react/forbid-prop-types
    selectedAccountStatus: PropTypes.array,
    // eslint-disable-next-line react/forbid-prop-types
    selectedAccountStage: PropTypes.array,
    // eslint-disable-next-line react/forbid-prop-types
    selectedCsTeam: PropTypes.any,
    selectedRestrictedAccount: PropTypes.bool,
  }),
  selectedEntityTypeChange: () => {},
  selectedAccountStatusChange: () => {},
  selectedAccountStageChange: () => {},
  selectedCsTeamChange: () => {},
  selectedRestrictedAccountChange: () => {},
};

DropdownFilters.defaultProps = {
  filterSelected: false,
  values: {
    selectedEntityType: [],
    selectedAccountStatus: [],
    selectedAccountStage: [],
    selectedCsTeam: undefined,
  },
};

export default DropdownFilters;
