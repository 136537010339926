import { Typography } from '@mui/material';
import { GridRenderCellParams, GridTreeNodeWithRender } from '@mui/x-data-grid';
import { RequestsReminder } from 'components/Requests/components/RequestsReminder/RequestsReminder';
import { format } from 'date-fns';
import { WorkflowRequestsWorkflowRequestFragment } from 'gql/graphql';
import theme from 'theme';

type DueDateCellProps = {
  params: GridRenderCellParams<
    WorkflowRequestsWorkflowRequestFragment,
    any,
    any,
    GridTreeNodeWithRender
  >;
  highlightPastDue?: boolean;
  showReminder?: boolean;
};
export const DueDateCell = ({
  params,
  highlightPastDue,
  showReminder,
}: DueDateCellProps) => {
  if (!params.value) return null;

  const dueDate = new Date(params.value as string);
  let textColor = theme.palette.text.primary;

  if (highlightPastDue && new Date() > dueDate) {
    textColor = theme.palette.error.main;
  }

  return (
    <>
      <Typography color={textColor}>{format(dueDate, 'MM/dd/yyyy')}</Typography>
      {showReminder && (
        <RequestsReminder
          variant="iconbutton"
          requestId={params.row.id}
          contacts={params.row.assignedContacts}
        />
      )}
    </>
  );
};
