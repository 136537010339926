/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-script-url */
/* eslint-disable react/jsx-no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable class-methods-use-this */
/* eslint-disable consistent-return */
/* eslint-disable no-prototype-builtins */
/* eslint-disable react/no-unused-class-component-methods */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-state */
// TODO: check react-s-alert/
import React from 'react';
import {
  Form,
  FormGroup,
  DropdownMenu,
  Input,
  Label,
  Button,
} from 'reactstrap';
import Select, { components } from 'react-select';
import { EditorState, convertFromRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import Axios from 'axios';
// import Alert from 'react-s-alert';
import TooltipItem from 'common/TooltipItem_updated';
import {
  options,
  removeBreakElementinList,
  generateUniqueHash,
} from 'common/NotesUtilities';
import { AlertMessage, getStorageData } from 'utilities/utils';
import {
  SEARCH_TASK_CONTACTS,
  SEARCH_TASK_ACCOUNTS,
  MESSAGES_API_PATH,
  GET_TEMPLATES_FOR_CREATION,
  DISCARD_DRAFT,
  CREATE_DOC_FROM_TEMPLATE_IDS,
  MIXPANEL_MESSAGE_EVENT,
  USER_EMAILS,
} from 'constants/constants';
import debounce from 'lodash/debounce';
import * as MessageApi from 'api/MessageApi';
import ConfirmationModal from 'common/ConfirmationModal';
import {
  // OptionBoxSelectFieldWithAvatar,
  CheckboxField,
  SelectTemplateFieldOption,
  selectStyles,
  selectTheme,
} from 'common/CustomFields';
import TaskFileUpload from 'components/Files/TaskFileUpload';
import TinymceEditor from 'components/Emails/components/TinymceEditior';
import { trackMixpanelEvent } from 'utilities/mixpanelfn';
import { formatEmailAddresses } from 'components/Emails/EmailUtilities';
import {
  groupEmpAndContacts,
  getContactsOfTask,
  getAccounts,
  AccountsOfContact,
  getContEmpOfAccount,
  getRelConAndCstOfAccounts,
} from 'api/TaskApi';
import { withStorageData } from 'utilities/withStorageData';
import RecipientAndAccountDropdowns from './RecipientAndAccountDropdowns';
import './message.scss';

class NewMessage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      comment: this.props.emailDetails ? this.props.emailDetails.content : '',
      documents: [],
      contacts: [],
      accounts: [],
      allContacts: [],
      allAccounts: [],
      subject: this.props.emailDetails ? this.props.emailDetails.subject : '',
      accountId: '',
      contactId: '',
      contactName: '',
      accountName: '',
      recipientType: '',
      selectedRecords: [],
      dropdownOpen: false,
      assigneeType: '',
      messageId: '',
      messageThreadId: '',
      errors: {},
      draftStatus: '',
      contatIdPopup: '',
      type: 'message',
      mounted: false,
      notifyViaText: false,
      apiHitting: false,
      uploadingImages: false,
      sendMessageAfterImageUpload: false,
      initialComment: {},
      selectedOption: null,
      uniqueId: generateUniqueHash(5),
      templateOptions: [],
      templateDocuments: [],
      warningMsgLost: false,
      selectedTemplate: null,
      titleChange: '',
      viewContext: this.props.viewContext,
      emailId: this.props.viewContext
        ? this.props.emailDetails.email_id
        : undefined,
      // imageUrls: [],
      // noOfImages: 0,
      groupedOptions: [],
      isLoading: true,
      isUserTyping: false,
    };
    this.handleCommentChange = this.handleCommentChange.bind(this);
    this.templateDropdown = this.templateDropdown.bind(this);
    this.addSelectedInput = this.addSelectedInput.bind(this);
    this.handleAccountChange = this.handleAccountChange.bind(this);
    this.handleContactChange = this.handleContactChange.bind(this);
    this.handleContactInput = this.handleContactInput.bind(this);
    this.handleAccountInput = this.handleAccountInput.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.discardDraft = this.discardDraft.bind(this);
    this.handleValidation = this.handleValidation.bind(this);
    this.selectInputRef = React.createRef();
  }

  UNSAFE_componentWillMount() {
    this.timer = null;
  }

  componentDidMount() {
    document.title = 'Create Message';
    // TODO: __HOMEDATA-AUDIT
    const homeDataObj = getStorageData();
    getContactsOfTask(this, { limit: true, message: true }, 'notes');
    if (homeDataObj !== null && Object.keys(homeDataObj).length) {
      if (homeDataObj.is_employee) {
        getAccounts(this, 'notes');
        this.setState({ mounted: true });
      } else {
        AccountsOfContact(this, homeDataObj.contact_id, 'clientmessage');
        this.setState({ mounted: true });
      }
    }
    if (homeDataObj.is_employee) {
      this.getTemplates();
    }
    window.addEventListener('keypress', (e) => {
      if (e && e.which === 13) {
        e.preventDefault();
      }
    });
    if (this.props.viewContext) {
      this.getEmailAttachments();
      const fromEmail = formatEmailAddresses(
        this.props.emailDetails.from_email,
      );
      if (fromEmail && fromEmail.length) {
        this.handleContactInput(
          encodeURIComponent(fromEmail[0].label),
          'addToRecepient',
        );
      }
    }
  }

  UNSAFE_componentWillReceiveProps() {
    // TODO: __HOMEDATA-AUDIT
    const homeDataObj = getStorageData();
    if (!this.state.mounted) {
      if (homeDataObj !== null && Object.keys(homeDataObj).length) {
        if (homeDataObj.is_employee) {
          getAccounts(this, 'notes');
          this.setState({ mounted: true });
        } else {
          AccountsOfContact(this, homeDataObj.contact_id, 'clientmessage');
          this.setState({ mounted: true });
        }
      }
    }
  }

  componentWillUnmount() {
    window.addEventListener('keypress', () => true);
  }

  onFocus() {
    this.setState(
      (prevState) => ({
        dropdownOpen: !prevState.dropdownOpen,
        contacts: prevState.allContacts,
        accounts: prevState.allAccounts,
        groupedOptions: groupEmpAndContacts(prevState.allContacts),
        contactId: '',
        contatIdPopup: '',
        accountId: '',
        contactName: '',
        accountName: '',
        inputVal: '',
        errors: {},
      }),
      () => {
        if (this.state.accounts?.length === 1) {
          this.setState({
            accountId: this.state.accounts[0].value,
            accountName: this.state.accounts[0].label,
          });
        }
      },
    );
  }

  getEmailAttachments = async () => {
    if (
      this.props.emailDetails &&
      this.props.emailDetails.documents &&
      this.props.emailDetails.documents.length
    ) {
      this.setState({
        gettingEmailAttachments:
          (this.props.emailDetails.documents &&
            this.props.emailDetails.documents.length) ||
          false,
      });
      try {
        const url = `${USER_EMAILS}/${this.props.emailDetails.email_id}/attachments?for_message_creation=true`;
        const response = await Axios.get(url).then((res) => res.data.data);
        if (response && response.length) {
          response.map((e) => {
            e.isAttachmentFromEmailOrMessage = true;
            return e;
          });
        }
        this.setState({
          documents: response,
          gettingEmailAttachments: false,
        });
      } catch (error) {
        this.setState({ gettingEmailAttachments: false });
        console.log(error);
      }
    }
  };

  getTemplates() {
    // this.setState({ loading: true });
    Axios.get(`${GET_TEMPLATES_FOR_CREATION}?template_type=message_template`)
      .then((res) => {
        this.setState({ loading: false });
        if (res.data && res.data.data && res.data.data.length) {
          const { data, default_preferences } = res.data;
          this.setState({
            templateOptions: data,
            loading: false,
            defaultPreferences: default_preferences,
          });
        } else {
          this.setState({
            templateOptions: [],
            loading: false,
          });
        }
      })
      .catch(() => {
        this.setState({
          templateOptions: [],
          loading: false,
        });
      });
  }

  getTemplateDocsId = () => {
    const mydata = {};
    if (this.state.templateDocuments.length) {
      mydata.template_doc_ids = [];
      let x;
      for (x = 0; x < this.state.templateDocuments.length; x += 1) {
        mydata.template_doc_ids.push(this.state.templateDocuments[x].id);
      }
    }
    if (this.state.templateDocuments.length) {
      this.setState({ loading: true, gettingEmailAttachments: true });
      const request = Axios.post(CREATE_DOC_FROM_TEMPLATE_IDS, mydata);
      request
        .then((response) => {
          this.setState({ loading: false });
          if (response.data.status === 200) {
            const documents = [...this.state.documents];
            const doc = documents.length
              ? documents
              : [...this.state.templateDocuments];
            if (doc.length) {
              this.setState(
                { documents: response.data.documents, templateDocuments: [] },
                () => {
                  clearTimeout(this.timer);
                  this.timer = setTimeout(() => {
                    this.saveMessage('Draft');
                  }, 1000);
                },
              );
            }
          } else {
            AlertMessage('error', response.data.message, 1500);
          }
        })
        .catch(() => {
          this.setState({ loading: false });
        });
    }
  };

  inputRenderer = (inputProps) => (
    // delete inputProps.value;
    <components.Input
      {...inputProps}
      value={this.state.titleChange}
      className="Select-input"
    />
  );

  templateDropdown(selectedOption) {
    const { subject, documents, comment, warningMsgLost } = this.state;
    const Comment = this.validateDraftMessage(comment);
    // let CommentInJSON = '';
    // if (Comment && Comment.length) {
    //   CommentInJSON = JSON.stringify(this.state.comment);
    // }
    if (
      (subject || Comment || documents.length) &&
      !warningMsgLost &&
      (!selectedOption || (selectedOption && !subject))
    ) {
      this.setState({ warningMsgLost: true, selectedTemplate: selectedOption });
    } else if (selectedOption && selectedOption !== null) {
      // this.discardDraft();
      // const msgId = encodeURIComponent(this.state.messageId);
      // MessageApi.discardDraft(this, msgId, '', '', false, true);
      if (this.state.messageId) {
        this.deleteMsg(this.state.messageId, selectedOption);
      } else {
        const { content, value } = selectedOption;
        // let coment = '';
        const coment = content;
        this.setState(
          {
            selectedOption,
            subject: value,
            titleChange: '',
            comment: coment,
            uniqueId: generateUniqueHash(5),
            warningMsgLost: false,
            selectedTemplate: null,
            templateDocuments:
              selectedOption.documents && selectedOption.documents.length
                ? selectedOption.documents
                : [],
            documents:
              selectedOption.documents && selectedOption.documents.length
                ? selectedOption.documents
                : [],
          },
          () => {
            // console.log('selectedOption', selectedOption, this.state.templateDocuments);
            // if (selectedOption.documents && selectedOption.documents.length) {
            //   // console.log('1');
            //   this.getTemplateDocsId();
            // } else {
            //   console.log('2');
            //   clearTimeout(this.timer);
            //   this.timer = setTimeout(() => { this.saveMessage('Draft'); }, 500);
            //   // this.saveMessage('Draft');
            // }
          },
        );
      }
    } else {
      // const { templateDocuments } = this.state;
      // const docs = this.state.documents;
      // if (docs && docs.length && templateDocuments && templateDocuments.length) {
      //   const documnts = [];
      //   docs.map((doc) => {
      //     if (doc.id && !_.find(templateDocuments, { id: doc.id })) {
      //       documnts.push(doc);
      //     }
      //     return documnts;
      //   });
      //   this.setState({ documents: documnts, templateDocuments: [] });
      // }
      this.setState(
        {
          selectedOption: null,
          subject: '',
          titleChange: '',
          comment: '',
          uniqueId: generateUniqueHash(5),
          warningMsgLost: false,
          selectedTemplate: null,
          documents: [],
          templateDocuments: [],
        },
        () => {
          const node = this.selectInputRef.current;
          if (node) {
            node.state.inputValue = '';
          }
          clearTimeout(this.timer);
          this.timer = setTimeout(() => {
            this.saveMessage('Draft');
          }, 1000);
          // this.saveMessage('Draft');
        },
      );
    }
  }

  deleteMsg = (messageId, selectedOption) => {
    const msgId = encodeURIComponent(messageId);
    this.setState({ loading: true, apiHitting: true });
    const request = Axios.delete(`${DISCARD_DRAFT}/${msgId}`);
    request.then((response) => {
      if (response.status === 200) {
        const { content, value } = selectedOption;
        // let coment = '';
        const coment = content;
        // if (content_json && content_json !== null) {
        //   coment = JSON.parse(content_json);
        // } else {
        //   coment = removeBreakElement(content);
        // }
        // if (selectedOption.documents && selectedOption.documents.length) {
        //   // const docs = this.state.documents;
        //   // let x;
        //   // this.setState({
        //   //   templateDocuments: selectedOption.documents,
        //   // });
        //   // for (x = 0; x < selectedOption.documents.length; x += 1) {
        //   //   docs.push(selectedOption.documents[x]);
        //   // }
        //   this.setState({
        //     templateDocuments: selectedOption.documents,
        //     documents: selectedOption.documents,
        //   }, () => {
        //     this.getTemplateDocsId();
        //   });
        // } else {
        //   this.setState({
        //     templateDocuments: [],
        //     documents: [],
        //   });
        // }
        this.setState(
          {
            selectedOption,
            subject: value,
            titleChange: '',
            comment: coment,
            uniqueId: generateUniqueHash(5),
            warningMsgLost: false,
            selectedTemplate: null,
            contactId: '',
            contactName: '',
            accountId: '',
            accountName: '',
            uploadFiles: [],
            inputVal: '',
            messageId: '',
            messageThreadId: '',
            templateDocuments:
              selectedOption.documents && selectedOption.documents.length
                ? selectedOption.documents
                : [],
            documents:
              selectedOption.documents && selectedOption.documents.length
                ? selectedOption.documents
                : [],
          },
          () => {
            this.setState({ loading: false, apiHitting: false });
            // if (this.state.templateDocuments && this.state.templateDocuments.length) {
            //   this.getTemplateDocsId();
            // } else {
            //   this.saveMessage('Draft');
            // }
            // this.saveMessage('Draft');
            if (
              this.state.templateDocuments &&
              !this.state.templateDocuments.length
            ) {
              clearTimeout(this.timer);
              this.timer = setTimeout(() => {
                this.saveMessage('Draft');
              }, 1000);
            }
          },
        );
      } else {
        this.setState({
          apiHitting: false,
          loading: false,
        });
      }
    });
  };

  templateChange = (input, action) => {
    const val =
      action.action === 'input-change' ? input : action.prevInputValue;
    if (val || (!val && this.state.titleChange)) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.saveMessage('Draft');
      }, 500);
      this.setState({
        subject: val,
        titleChange: val,
      });
    }
  };

  handleChange(e) {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.saveMessage('Draft');
    }, 500);
    this.setState({ [e.target.name]: e.target.value });
  }

  removeSelectedRecord(contactId, accountId) {
    const selectedRecordsArr = this.state.selectedRecords;
    const index = selectedRecordsArr.findIndex(
      (x) => x.contactId === contactId && x.accountId === accountId,
    );
    if (index > -1) {
      selectedRecordsArr.splice(index, 1);
      this.setState({ selectedRecords: selectedRecordsArr });
    }
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.saveMessage('Draft');
    }, 500);
  }

  handleCommentChange(value) {
    this.setState({ comment: value, errors: {} });
    if (this.state.templateDocuments && this.state.templateDocuments.length) {
      this.getTemplateDocsId();
    } else {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.saveMessage('Draft');
      }, 1000);
    }
    // clearTimeout(this.timer);
    // this.timer = setTimeout(() => { this.saveMessage('Draft'); }, 1000);
  }

  handleCheckboxChange = (inputvalue, fieldname) => {
    const inputfieldvalue =
      inputvalue && inputvalue.target && inputvalue.target.value
        ? inputvalue.target.value
        : undefined;
    const value = !(inputfieldvalue === 'true');
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.saveMessage('Draft');
    }, 500);
    this.setState({ [fieldname]: value });
  };

  handleImageUploadStatus = (status) => {
    this.setState({ uploadingImages: status }, () => {
      if (
        !this.state.uploadingImages &&
        this.state.sendMessageAfterImageUpload
      ) {
        this.saveMessage('Sent');
      } else {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.saveMessage('Draft');
        }, 1000);
      }
    });
  };

  closeModal(discard = '') {
    const { viewContext } = this.state;
    if (viewContext) {
      // we will refresh the email list page when task is created from email
      // if (createdNewNote) {
      //   this.props.refreshEmailListPage();
      // }
      this.props.closeModalInEmailPage(
        '',
        discard
          ? 'Draft Discarded'
          : 'Liscio message sent. Email moved to Done.',
      );
    } else {
      this.props.navigate(-1);
    }
  }

  handleContactInput(val, from = '') {
    if (!this.state.accountId && val) {
      if (val.length < 2) {
        this.getContAcc('contacts');
      } else {
        this.setState({ contacts: [] });
        clearTimeout(this.contactSearchTime);
        this.contactSearchTime = setTimeout(() => {
          const request = Axios.get(
            `${SEARCH_TASK_CONTACTS}/?keyword=${val}&message=true`,
          );
          request.then((response) => {
            if (response.status === 200) {
              if (from === 'addToRecepient') {
                const contactDetails = response.data[0];
                this.handleContactChange(contactDetails);
                this.addSelectedInput();
              } else {
                this.setState({
                  contacts: response.data,
                  groupedOptions: groupEmpAndContacts(response.data),
                });
              }
            }
          });
        }, 500);
      }
    }
  }

  handleAccountInput(val) {
    // TODO: __HOMEDATA-AUDIT
    const homeDataObj = getStorageData();
    const { contactId, recipientType } = this.state;
    if (
      val.length >= 2 &&
      homeDataObj.is_employee &&
      ((contactId && recipientType === 'emp') || !contactId)
    ) {
      this.setState({ accounts: [] });
      clearTimeout(this.accountSearchTime);
      this.accountSearchTime = setTimeout(() => {
        const request = Axios.get(
          `${SEARCH_TASK_ACCOUNTS}/?keyword=${val}&message=true`,
        );
        request.then((response) => {
          if (response.status === 200) {
            this.setState({ accounts: response.data });
          }
        });
      }, 500);
    }
    if (this.state.errors.accont) {
      const err = {};
      err.accont = '';
      this.setState({ errors: err });
    }
  }

  addSelectedInput() {
    if (!this.state.contactId) return false;
    const selectedInput = {
      contactId: this.state.contactId,
      accountId: this.state.accountId,
      contactName: this.state.contactName,
      accountName: this.state.accountName,
      assigneeType: this.state.assigneeType,
    };
    const selectedRecordsArr = this.state.selectedRecords;
    const index = selectedRecordsArr.findIndex(
      (x) =>
        x.contactId === selectedInput.contactId &&
        x.accountId === selectedInput.accountId,
    );
    // If To field is empty, push into the array and finally display the value in To field
    if (index === -1) {
      selectedRecordsArr.push(selectedInput);
      this.setState({
        selectedRecords: selectedRecordsArr,
        dropdownOpen: false,
        contacts: this.state.allContacts,
        accounts: this.state.allAccounts,
        groupedOptions: groupEmpAndContacts(this.state.allContacts),
        contactId: '',
        contatIdPopup: '',
        accountId: '',
        contactName: '',
        accountName: '',
        assigneeType: '',
        selectedContact: '',
      });
    } else {
      this.setState({
        dropdownOpen: false,
        contacts: this.state.allContacts,
        accounts: this.state.allAccounts,
        groupedOptions: groupEmpAndContacts(this.state.allContacts),
        contactId: '',
        contatIdPopup: '',
        accountId: '',
        contactName: '',
        accountName: '',
        assigneeType: '',
        selectedContact: '',
      });
    }
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.saveMessage('Draft');
    }, 500);
  }

  handleValidation(comment) {
    const err = {};
    let formIsValid = true;
    if (!comment) {
      formIsValid = false;
      err.comment = 'Enter Description';
    }
    const selectedRecordsArr = this.state.selectedRecords;

    if (selectedRecordsArr.length < 1) {
      formIsValid = false;
      err.recipient = 'Please select a recipient';
    }

    this.setState({ errors: err });
    return formIsValid;
  }

  validateDraftMessage(comment) {
    let output = comment ? comment.toString() : '';
    let haveImages = false;
    if (comment && comment.entityMap && Object.keys(comment.entityMap).length) {
      haveImages = true;
    }
    if (comment && typeof comment === 'object') {
      const content = EditorState.createWithContent(
        convertFromRaw(comment),
      ).getCurrentContent();
      const isEditorEmpty = !content.hasText();
      const currentPlainText = content.getPlainText();
      const lengthOfEditorContent = currentPlainText.length;
      const lengthOfTrimmedContent = currentPlainText.trim().length;
      const isContainOnlySpaces = !isEditorEmpty && !lengthOfTrimmedContent;
      if ((lengthOfEditorContent && !isContainOnlySpaces) || haveImages) {
        output = removeBreakElementinList(
          stateToHTML(
            EditorState.createWithContent(
              convertFromRaw(comment),
            ).getCurrentContent(),
            options,
          ),
        );
      } else if (isContainOnlySpaces) {
        output = '';
      }
    } else if (typeof comment === 'string' && comment.length) {
      output = comment;
    }

    if (typeof output === 'object' || output === '[object Object]') {
      // for empty object
      output = '';
    }
    if (output && output.length > 0) {
      // Comment = Comment.replace(/\n/g, ' <br> ');
    }
    return output;
  }

  saveMessage(newStatus) {
    if (this.state.apiHitting && newStatus !== 'Sent') {
      return false;
    }
    const { subject, notifyViaText, comment, viewContext } = this.state;
    const draftState = comment;
    clearTimeout(this.timer);
    const Comment = this.validateDraftMessage(draftState);
    let CommentInJSON = '';
    if (draftState.length) {
      CommentInJSON = JSON.stringify(draftState);
    }
    if (newStatus === 'Sent') {
      const validate = this.handleValidation(Comment);
      if (!validate) {
        return false;
      }
      if (this.state.uploadingImages) {
        this.setState({
          loading: true,
          sendMessageAfterImageUpload: true,
        });
        return false;
      }
    } else if (this.state.uploadingImages) {
      return false;
    }
    this.setState({ apiHitting: true });
    const selectedRecordsArr = this.state.selectedRecords;
    if (
      Comment.trim() === '' &&
      selectedRecordsArr.length < 1 &&
      this.state.documents.length < 1 &&
      (!subject || (subject && subject.trim() === '')) &&
      !notifyViaText
    ) {
      console.log('empty draft');
      if (this.state.messageId) {
        const msgId = encodeURIComponent(this.state.messageId);
        MessageApi.discardDraft(this, msgId, '', '', false, true);
      } else {
        this.setState({ apiHitting: false });
        return false;
      }
      // return false;
    }
    // return false;
    const participantsArr = [];
    selectedRecordsArr.forEach((item) => {
      const tmpHash = {
        id: item.contactId,
        account_id: item.accountId,
        is_recipient: true,
        type: item.assigneeType,
      };
      participantsArr.push(tmpHash);
    });
    const mydata = {
      thread: {
        title: this.state.subject,
        is_notify_via_text: this.state.notifyViaText,
        status: newStatus,
      },
      messages: {
        status: newStatus,
        message_thread_id: this.state.messageThreadId,
        content: Comment,
      },
      id: this.state.messageId,
      participants: participantsArr,
    };

    if (this.state.documents.length) {
      mydata.doc_ids = [];
      let x;
      for (x = 0; x < this.state.documents.length; x += 1) {
        mydata.doc_ids.push(this.state.documents[x].id);
      }
    }
    if (this.state.emailId) {
      mydata.email_id = this.state.emailId;
    }

    if (newStatus === 'Draft') {
      this.setState({ draftStatus: 'Saving' });
    }
    if (newStatus === 'Sent') {
      this.setState({ loading: true });
    }
    const request = Axios.post(MESSAGES_API_PATH, mydata);
    request
      .then((response) => {
        this.setState({ loading: false });

        const from_email = viewContext === 'email';
        trackMixpanelEvent(MIXPANEL_MESSAGE_EVENT, {
          state: response.data.status === 200 ? newStatus : 'error',
          from_email,
        });

        if (newStatus === 'Draft') {
          if (response.data.status === 422 && response.data.message === '') {
            this.setState({
              messageId: '',
              messageThreadId: '',
              apiHitting: false,
              gettingEmailAttachments: false,
            });
          } else if (response.data.status === 400 && response.data.message) {
            AlertMessage('error', response.data.message, 1500);
            this.setState({
              apiHitting: false,
              draftStatus: '',
              gettingEmailAttachments: false,
            });
          } else {
            this.setState(
              {
                messageThreadId: response.data.message_thread_id,
                messageId: response.data.message_id,
                draftStatus: 'Saved',
              },
              () => {
                this.setState({
                  apiHitting: false,
                  gettingEmailAttachments: false,
                });
              },
            );
            setTimeout(() => {
              this.setState({ draftStatus: '' });
            }, 100);
          }
        } else {
          if (response.data.status === 200) {
            if (
              response.data.messages &&
              response.data.messages !== undefined &&
              response.data.messages.includes('away')
            ) {
              AlertMessage('success', response.data.messages, 3000);
            }
            this.closeModal();
          } else {
            AlertMessage('error', response.data.message, 1500);
          }
          this.setState({ apiHitting: false, gettingEmailAttachments: false });
        }
      })
      .catch((errors) => {
        this.setState({
          apiHitting: false,
          loading: false,
          gettingEmailAttachments: false,
        });
      });

    return false;
  }

  discardDraft() {
    if (this.state.messageId) {
      const msgId = encodeURIComponent(this.state.messageId);
      MessageApi.discardDraft(this, msgId, '', 'newMsg');
    } else {
      this.closeModal();
    }
  }

  keyPress(e) {
    if (e && Number(e.keyCode) === 13) {
      e.preventDefault();
    }
  }

  errorDoc = (field = '') => {
    if (field === 'account') {
      const err = {};
      err.accont = 'Please select account';
      this.setState({ errors: err });
    } else {
      const err = {};
      err.documents = 'Please select document';
      this.setState({ errors: err });
    }
  };

  updateDocumentState = (documents) => {
    this.setState({ documents }, () => {
      this.saveMessage('Draft');
    });
  };

  renderTags() {
    const allSelectedRecords = this.state.selectedRecords;
    if (!allSelectedRecords || allSelectedRecords.length < 1) {
      return null;
    }
    const selectedRecords = allSelectedRecords.map((item) => {
      if (item.accountId) {
        return (
          <span
            className="tag tag--fill receipt-account-tag"
            data-testid="toField"
            key={item.contactId + item.accountId}
          >
            {item.contactName} <i className="divOption" /> {item.accountName}{' '}
            <i
              className="icon-close2 remove"
              onClick={() =>
                this.removeSelectedRecord(item.contactId, item.accountId)
              }
            />
          </span>
        );
      }
      return (
        <span
          className="tag tag--fill receipt-account-tag"
          key={item.contactId + item.accountId}
        >
          {item.contactName}{' '}
          <i
            className="icon-close2 remove"
            onClick={() =>
              this.removeSelectedRecord(item.contactId, item.accountId)
            }
          />
        </span>
      );
    });
    return selectedRecords;
  }

  /** *********** NEW CODE **************** */

  /**
   * This method is used to reset the recipient and account selects
   * This method gets called when clearing a select value
   * @param {*} changeOnly
   */
  getContAcc(changeOnly = 'both') {
    if (changeOnly === 'contacts') {
      this.setState({
        contacts: this.state.allContacts,
        groupedOptions: groupEmpAndContacts(this.state.allContacts),
        contactId: '',
        contatIdPopup: '',
        contactName: '',
        recipientType: '',
        isLoading: false,
        selectedContact: null,
      });
    } else if (changeOnly === 'accounts') {
      this.setState({
        accounts: this.state.allAccounts,
        accountId: null,
        accountName: null,
        selectedContact: null,
      });
    } else {
      this.setState({
        contacts: this.state.allContacts,
        accounts: this.state.allAccounts,
        groupedOptions: groupEmpAndContacts(this.state.allContacts),
        contactId: '',
        contatIdPopup: '',
        accountId: '',
        contactName: '',
        accountName: '',
        recipientType: '',
        selectedContact: null,
      });
    }
  }

  handleContactChange(val) {
    this.setState({ isUserTyping: false });
    if (!val && !this.state.accountId) {
      this.getContAcc();
    } else if (!val) {
      this.setState({
        contactId: '',
        recipientType: '',
        contatIdPopup: '',
        contactName: '',
        assigneeType: '',
      });
    } else if (val.type === 'contact' && !val.hasOwnProperty('accounts')) {
      // if contact has no account associated with it
      this.setState({
        accounts: [],
        contactId: val.value,
        contatIdPopup: val.id,
        contactName: val.label,
        accountId: '',
        accountName: '',
        cpaId: val.cpa_id,
        assigneeType: val.assigne_type,
        recipientType: val.type,
        selectedContact: val,
      });
      // when an employee is selected from recipient dropdown
    } else if (val.type === 'emp' && !this.state.accountId) {
      this.getContAcc('accounts'); // reset accounts data
      this.setState({
        contactId: val.value,
        contactName: val.label,
        selectedContact: val,
        cpaId: val.cpa_id,
        assigneeType: val.assigne_type,
        recipientType: val.type,
      });
    } else if (val.type === 'emp' && this.state.accountId) {
      this.setState({
        contactId: val.value,
        contactName: val.label,
        selectedContact: val,
        cpaId: val.cpa_id,
        assigneeType: val.assigne_type,
        recipientType: val.type,
      });
    } else if (val.accounts.length === 1) {
      // autopopulate account if only one account
      this.setState(
        {
          accountId: val.accounts[0].value,
          accountName: val.accounts[0].label,
          accounts: val.accounts,
          contactId: val.value,
          contatIdPopup: val.id,
          contactName: val.label,
          cpaId: val.cpa_id,
          assigneeType: val.assigne_type,
          recipientType: val.type,
          selectedContact: val,
        },
        () => {
          this.setState({ isLoading: true });
          getContEmpOfAccount(this, {
            accountId: val.accounts[0].value,
            accountName: val.accounts[0].label,
          });
        },
      );
    } else {
      const eleArray = this.state.accounts.map((item) => item.value);
      if (eleArray.indexOf(this.state.accountId) > -1) {
        this.setState({
          accounts: val.accounts,
          contactId: val.value,
          contatIdPopup: val.id,
          contactName: val.label,
          selectedContact: val,
          cpaId: val.cpa_id,
          assigneeType: val.assigne_type,
          recipientType: val.type,
        });
      } else {
        // set the accounts related to that contact
        this.setState({
          accounts: val.accounts,
          contactId: val.value,
          contatIdPopup: val.id,
          contactName: val.label,
          selectedContact: val,
          cpaId: val.cpa_id,
          assigneeType: val.assigne_type,
          recipientType: val.type,
        });
      }
    }
  }

  handleAccountChange(val, typeOfAction) {
    const { action, removedValues } = typeOfAction;
    if (action === 'clear' && removedValues.length === 0) {
      return;
    }
    // TODO: __HOMEDATA-AUDIT
    const homeDataObj = getStorageData();

    this.setState({ isUserTyping: false });

    if (!val && !this.state.contactId) {
      this.getContAcc();
    } else if (!val) {
      // reset account select when there is no value
      if (this.state.recipientType === 'emp') {
        this.setState({
          accounts: this.state.allAccounts,
          accountId: null,
          accountName: null,
          groupedOptions: groupEmpAndContacts(this.state.allContacts),
        });
      } else {
        this.setState({
          accounts: this.state.accounts,
          accountId: null,
          accountName: null,
        });
      }
    } else {
      this.setState({
        accountId: val.value,
        accountName: val.label,
      });
      const mydata = {
        accountId: val.value,
        accountName: val.label,
      };
      // Will enter this if condition when you select an account with no recipient
      if (homeDataObj && homeDataObj.is_employee) {
        this.setState({ isLoading: true });
        getContEmpOfAccount(this, mydata);
      } else {
        getRelConAndCstOfAccounts(this, val.value);
      }
    }
    if (this.state.errors.accont) {
      const err = {};
      err.accont = '';
      this.setState({ errors: err });
    }
  }

  // get filtered results when performing a search in contact select
  getContactFilteredResults = async (searchTerm, from = '') => {
    try {
      const response = await Axios.get(
        `${SEARCH_TASK_CONTACTS}/?keyword=${searchTerm}&message=true`,
      );

      if (response.status === 200) {
        const groupedOptions = groupEmpAndContacts(response.data);
        if (from === 'addToRecepient') {
          const contactDetails = response.data[0];
          this.handleContactChange(contactDetails);
          this.addSelectedInput();
        } else {
          this.setState({
            contacts: response.data,
            groupedOptions,
          });
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  // get filtered results when performing a search in account select
  getAccountFilteredResults = async (searchTerm) => {
    try {
      const response = await Axios.get(
        `${SEARCH_TASK_ACCOUNTS}/?keyword=${searchTerm}&message=true`,
      );

      if (response.status === 200) {
        this.setState({ accounts: response.data, allAccounts: response.data });
      }
    } catch (err) {
      console.error(err);
    }
  };

  // handle contact select input change
  handleContactInputChange = debounce((inputValue, typeOfAction) => {
    const { action } = typeOfAction;
    const { accountId, isUserTyping } = this.state;

    /**
     * execute this block when the user clears the select input while hitting backspace or
     * closes the menu without selecting an option
     */
    if (action === 'menu-close' && isUserTyping) {
      this.getContAcc('contacts');
    }

    if (action === 'input-change' && !accountId) {
      if (inputValue) {
        this.getContactFilteredResults(inputValue);
        this.setState({ isUserTyping: true });
      } else {
        this.getContAcc('contacts');
      }
    }
  }, 300);

  // handle account select input change
  handleAccountInputChange = debounce((inputValue, typeOfAction) => {
    const { action, prevInputValue } = typeOfAction;
    const { isUserTyping, contactId, recipientType } = this.state;
    // TODO: __HOMEDATA-AUDIT
    const homeDataObj = getStorageData();

    /**
     * execute this block when the user clears the select input while hitting backspace or
     * closes the menu without selecting an option
     */

    if (
      (action === 'menu-close' || prevInputValue.length === 1) &&
      isUserTyping
    ) {
      this.setState((prevState) => ({
        accounts: prevState.allAccounts,
        accountId: '',
        accountName: '',
      }));
    }

    if (inputValue) {
      if (
        action === 'input-change' &&
        homeDataObj.is_employee &&
        ((contactId && recipientType === 'emp') || !contactId)
      ) {
        this.getAccountFilteredResults(inputValue);
        this.setState({ isUserTyping: true });
      } else if (Array.isArray(this.state.ref_accounts)) {
        this.setState({ allAccounts: this.state.ref_accounts });
      }
    }
  }, 300);

  // reset select dropdowns
  resetSelectDropdowns = () => {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
      contacts: prevState.allContacts,
      accounts: prevState.allAccounts,
      groupedOptions: groupEmpAndContacts(prevState.allContacts),
      selectedContact: '',
      contactId: '',
      contatIdPopup: '',
      accountId: '',
      contactName: '',
      accountName: '',
      inputVal: '',
      errors: {},
    }));
  };

  render() {
    const {
      selectedOption,
      templateOptions,
      selectedTemplate,
      subject,
      comment,
      viewContext,
      groupedOptions,
      isLoading,
    } = this.state;
    // TODO: __HOMEDATA-AUDIT
    const homeDataObj = getStorageData();
    const initials = homeDataObj
      ? homeDataObj.uname
        ? (homeDataObj.uname.split(' ')[0]
            ? homeDataObj.uname.split(' ')[0].charAt(0)
            : '') +
          (homeDataObj.uname.split(' ')[1]
            ? homeDataObj.uname.split(' ')[1].charAt(0)
            : '')
        : ''
      : '';
    const noneStyle = { display: 'none' };
    const blockStyle = { display: 'block' };

    return (
      <div id="content" data-testid="newMessage">
        <div id="loading" style={this.state.loading ? blockStyle : noneStyle} />
        <header>
          <h1 className="pageHeading">
            {this.state.isEdit ? 'Message' : 'Create Message'}
          </h1>
        </header>
        <div className="center-wrapper col-lg-10 col-sm-12 col-md-12 m-auto">
          <div className="pb-3 col-container section-title">
            <h6 className="stepBacklink" role="presentation">
              <a
                href="javascript:;"
                onClick={this.closeModal}
                data-testid="message__go_back"
              >
                <i className="icon-left-arrow" /> Back
              </a>
            </h6>
          </div>
          <Form>
            <FormGroup
              className={this.state.errors.recipient ? 'form-error' : ''}
            >
              <Label htmlFor="forEmail">To</Label>
              {homeDataObj && homeDataObj.is_employee ? (
                <div className="mb-2 float-right">
                  <CheckboxField
                    id="notifyViaText"
                    name="notifyViaText"
                    label="Notify Via Text"
                    labelclassName="text-body text-transform-none font-weight-normal"
                    value={this.state.notifyViaText}
                    onChange={this.handleCheckboxChange}
                    testid="message__notify_via_text"
                  />
                </div>
              ) : null}
              <div className="labelValue customTags mailDrodown">
                <div className="customTags__input form-control">
                  {this.renderTags()}
                  <button
                    type="button"
                    onClick={this.onFocus}
                    data-testid="message__to_field"
                    className="inputHelper dropdown-toggle noCaret"
                  />
                  {/* <Input type="text" autopopulate="off" id="messageto" name="message_to" onFocus={this.onFocus} autoComplete="off" className="dropdown-toggle" placeholder="" value={this.state.inputVal} /> */}
                </div>
                {this.state.dropdownOpen ? (
                  <DropdownMenu className="customTags__option option_box">
                    <RecipientAndAccountDropdowns
                      selectedContact={this.state.selectedContact}
                      recipientOptions={groupedOptions}
                      isLoading={isLoading}
                      accountOptions={this.state.allAccounts}
                      handleContactChange={this.handleContactChange}
                      handleAccountChange={this.handleAccountChange}
                      recipientValue={{
                        label: this.state.contactName,
                        value: this.state.contactId,
                      }}
                      accountValue={{
                        label: this.state.accountName,
                        value: this.state.accountId,
                      }}
                      handleContactInputChange={this.handleContactInputChange}
                      handleAccountInputChange={this.handleAccountInputChange}
                    />
                    {/* Keeping this code for future reference 
                    <OptionBoxSelectFieldWithAvatar
                      name="seachContact"
                      label="Recipient"
                      options={groupedOptions}
                      handleChange={this.handleContactChange}
                      handleInputChange={this.handleContactInput}
                      // value={this.state.contactId}
                      value={{
                        value: this.state.contactId,
                        label: this.state.contactName,
                      }}
                      placeholder="Select a Recipient"
                      autofocus
                      clearable
                    />
                    <OptionBoxSelectFieldWithAvatar
                      name="seachAccount"
                      label="Account"
                      options={this.state.accounts}
                      handleChange={this.handleAccountChange}
                      handleInputChange={this.handleAccountInput}
                      // value={this.state.accountId}
                      value={{
                        value: this.state.accountId,
                        label: this.state.accountName,
                      }}
                      placeholder="Select an account"
                      clearable
                    /> */}
                    <div className="btn-wrap btn--leftspace">
                      <Button
                        className="btn-link btn-sm btn--onlyicon"
                        onClick={this.resetSelectDropdowns}
                        data-testid="message__close_button"
                      >
                        Close
                      </Button>
                      <Button
                        className="btn btn-sm btn-primary"
                        data-testid="message__ok_button"
                        onClick={this.addSelectedInput}
                      >
                        Ok
                      </Button>
                    </div>
                  </DropdownMenu>
                ) : null}
              </div>
              <span className="error_label">{this.state.errors.recipient}</span>
            </FormGroup>
            <FormGroup
              className={
                this.state.errors.comment
                  ? 'form-error cstmClr position-relative'
                  : ' cstmClr position-relative'
              }
            >
              <Label htmlFor="subject">Subject</Label>
              <div className="labelValue customEditor boxResize flWdth">
                {(selectedOption && selectedOption !== null) ||
                (!selectedOption &&
                  selectedOption === null &&
                  !homeDataObj.is_employee) ||
                (!selectedOption &&
                  selectedOption === null &&
                  subject &&
                  !this.state.titleChange) ? (
                  <Input
                    autoComplete="off"
                    onKeyPress={(e) => this.keyPress(e)}
                    type="text"
                    name="subject"
                    id="forSubject"
                    value={this.state.subject}
                    onChange={this.handleChange}
                    placeholder=""
                  />
                ) : (
                  <Select
                    name="subject"
                    placeholder=""
                    inputId="NewMessage__SelectTemplate"
                    ref={this.selectInputRef}
                    className="select-custom-class slctTemp"
                    value={selectedOption}
                    onChange={this.templateDropdown}
                    options={templateOptions}
                    inputValue={this.state.titleChange}
                    // optionComponent={SelectTemplateFieldOption}
                    onInputChange={this.templateChange}
                    // inputRenderer={input => this.inputRenderer(input)}
                    tabSelectsValue={false}
                    onBlurResetsInput={false}
                    onCloseResetsInput={false}
                    onSelectResetsInput={this.state.titleChange} // false shows
                    noResultsText=""
                    styles={selectStyles}
                    theme={selectTheme}
                    classNamePrefix="custom-react-select"
                    components={{
                      Option: SelectTemplateFieldOption,
                      Input: this.inputRenderer,
                    }}
                    id="message__subject"
                  />
                )}
              </div>
              {this.state.subject && homeDataObj.is_employee ? (
                <Button
                  data-testid="clearBtn"
                  className="btn btn-sm btn-primary"
                  onClick={() => this.templateDropdown(null)}
                >
                  Clear
                </Button>
              ) : null}
            </FormGroup>
            <FormGroup
              className={this.state.errors.comment ? ' form-error' : ''}
            >
              <Label htmlFor="forDescription">Description</Label>
              <div className="labelValue customEditor boxResize">
                <TinymceEditor
                  initialValue=""
                  value={this.state.comment}
                  handleChange={this.handleCommentChange}
                />
                <span className="error_label">{this.state.errors.comment}</span>
              </div>
            </FormGroup>
            <FormGroup className="dragWrap">
              <Label htmlFor="attachments">Attachments</Label>
              <TaskFileUpload
                data={this.state}
                uploadType="message"
                propsData={this.state}
                updateDocumentState={this.updateDocumentState}
              />
            </FormGroup>
            <div className="form-group formbtn formbtn--border justify-content-between">
              <div className="senderInfo">
                <Label htmlFor="senderInfo">Send As</Label>
                <div
                  className={`avatar avatar--w20 ${
                    homeDataObj && homeDataObj.is_employee
                      ? 'avatar--border avatar--borderPrimary '
                      : ''
                  }d-inline-block`}
                >
                  {homeDataObj && homeDataObj.avatar ? (
                    <img
                      src={homeDataObj.avatar}
                      alt="Person"
                      width="20"
                      height="20"
                      className="rounded-circle"
                    />
                  ) : (
                    <span className="uName">{initials.toUpperCase()}</span>
                  )}
                  <span>{homeDataObj ? homeDataObj.uname : ''}</span>
                </div>
              </div>
              <div
                className="btn-wrap btn--leftspace"
                id="draftStatus"
                data-testid="draftStatus"
              >
                <span className="text-dark pr-4">{this.state.draftStatus}</span>
                <TooltipItem
                  position="top"
                  tooltipType="button"
                  key="draft"
                  text="Discard Draft"
                  btnClass="btn-link btn--onlyicon"
                  clickAction={() => this.discardDraft()}
                  id="draft"
                  iconn="icon-delete-small"
                />
                <Button
                  color="primary"
                  onClick={() => this.saveMessage('Sent')}
                  disabled={
                    this.state.draftStatus === 'Saving' ||
                    this.state.gettingEmailAttachments
                  }
                  data-testid="sendButton"
                >
                  Send
                </Button>
              </div>
            </div>
          </Form>
        </div>
        <ConfirmationModal
          isOpen={this.state.warningMsgLost}
          className="customModal customModal--w500 customModal--nopadding"
          messageText="You are about to delete the existing message. This action cannot be undone. Proceed?"
          noBtnClick={() => this.setState({ warningMsgLost: false })}
          yesBtnClick={() => this.templateDropdown(selectedTemplate)}
          yesBtnText="Yes"
          noBtnText="No"
        />
      </div>
    );
  }
}

export default withStorageData(NewMessage);
