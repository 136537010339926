import { Alert, Button, ButtonGroup, Tooltip } from '@mui/material';
import { useDraftsBulkSend } from 'components/Requests/state/drafts-bulk-send-state';
import { usePostApiV5FirmsWorkflowRequestsBulkDispatch } from 'api/v5/requests/requests';
import { AlertMessage } from 'utilities/utils';
import { TOAST_FAILURE, TOAST_SUCCESS } from 'constants/constants';

type DraftsBulkSendPreviewFooterProps = {
  message?: string | null;
  requiresDueDate?: boolean;
  disabled?: boolean;
};

export const DraftsBulkSendPreviewFooter = ({
  message,
  requiresDueDate = true,
  disabled = false,
}: DraftsBulkSendPreviewFooterProps) => {
  const {
    selectedDraftIds,
    getSelectedDraftsCount,
    removeAllSelectedDrafts,
    dueDate,
    setDueDate,
  } = useDraftsBulkSend();

  const selectedFileCount = getSelectedDraftsCount();

  const { mutate: bulkSendMutate, isLoading } =
    usePostApiV5FirmsWorkflowRequestsBulkDispatch({
      mutation: {
        onSuccess: () => {
          AlertMessage(TOAST_SUCCESS, 'Drafts sent successfully');
          removeAllSelectedDrafts();
          setDueDate('');
        },
        onError: () => {
          AlertMessage(TOAST_FAILURE, 'Failed to send drafts');
        },
      },
    });

  const handleBulkSendClick = () => {
    bulkSendMutate({
      data: {
        request: {
          ids: selectedDraftIds,
          due_date: dueDate,
        },
      },
    });
  };

  const handleClearSelectedClick = () => {
    removeAllSelectedDrafts();
  };

  const isSendDisabled =
    selectedFileCount === 0 || (requiresDueDate && !dueDate);

  return (
    <>
      <ButtonGroup
        variant="contained"
        disableElevation
        disabled={disabled || isLoading}
      >
        <Tooltip
          title={
            dueDate || !requiresDueDate
              ? ''
              : 'Please select a due date to enable Bulk Send'
          }
        >
          <span>
            <Button onClick={handleBulkSendClick} disabled={isSendDisabled}>
              Bulk Send {selectedFileCount} items
            </Button>
          </span>
        </Tooltip>
        <Button onClick={handleClearSelectedClick} color="secondary">
          Clear Selected
        </Button>
      </ButtonGroup>
      {message && (
        <Alert severity="info" style={{ padding: '0px 0px', lineHeight: 1.7 }}>
          {message}
        </Alert>
      )}
    </>
  );
};
