import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { InputBase } from '@mui/material';

const SContainer = styled('div')((props) => ({
  display: 'flex',
  alignItems: 'center',
  flex: 1,
  position: 'relative',
  backgroundColor: '#ffffff',
  border: props.focused
    ? `2px solid ${props.theme.palette.primary.gray}`
    : `2px solid ${props.theme.palette.primary.lightGray}`,
  transition: 'all ease-in-out 350ms',
  width: 'auto',
  maxWidth: '100%',
  maxHeight: '35px',
}));

const SInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1),
    transition: theme.transitions.create('width'),
    width: '100%',
  },
}));

function TextField(props) {
  const {
    className,
    type = 'text',
    placeHolder = 'Placeholder',
    value = '',
    onChange,
  } = props;

  const [internal, setInternal] = useState(value);
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    if (value !== internal) setInternal(value);
  }, [value]);

  const handleChange = (e) => {
    setInternal(e.target.value);
    if (onChange) onChange(e);
  };

  return (
    <SContainer className={className} focused={focused}>
      <SInputBase
        type={type}
        placeholder={placeHolder}
        value={internal}
        onChange={handleChange}
        onFocus={() => {
          setFocused(true);
        }}
        onBlur={() => {
          setFocused(false);
        }}
      />
    </SContainer>
  );
}

export default TextField;
