import { Header } from 'ui/Header/Header';
import { useEffect } from 'react';
import {
  REQUESTS_PAGE_ROUTES,
  REQUESTS_PAGE_TITLES,
} from 'components/Requests/requests.constants';
import { Page } from 'ui/Page/Page';
import WorkflowTemplateList from 'components/Requests/components/WorkflowTemplateList/WorkflowTemplateList';
import { useNavigate } from 'react-router-dom-latest';

type NewRequestViewProps = {
  onClose?: () => void;
};

export const NewRequestView = ({ onClose }: NewRequestViewProps) => {
  const navigate = useNavigate();
  useEffect(() => {
    document.title = REQUESTS_PAGE_TITLES.new;
  }, []);

  const handleOnClose = () => {
    if (onClose) {
      onClose();
    } else {
      navigate(REQUESTS_PAGE_ROUTES.requests);
    }
  };

  return (
    <Page
      header={<Header onClose={handleOnClose} title="Create a New Request" />}
    >
      <WorkflowTemplateList />
    </Page>
  );
};
