import { IntegrationModule } from 'components/Integrations/integration-modules/integration-module.types';
import { AdobeIntegrationListItem } from './AdobeIntegrationListItem';
import { AdobeIntegrationConfig } from './AdobeIntegrationConfig';

export const adobeIntegrationModule: IntegrationModule = {
  id: 'adobe',
  path: 'adobe',
  title: 'Adobe',
  getActivationStatus: async () => {
    await console.log('TODO: getAdobeActivationStatus');
    return false;
  },
  listComponent: <AdobeIntegrationListItem />,
  configComponent: <AdobeIntegrationConfig />,
};
