import { graphql } from 'gql';

export const rpaDocumentsQueryDocument = graphql(/* GraphQL */ `
  query rpaDocuments(
    $status: RpaDocumentStatus
    $page: Int
    $perPage: Int
    $sortField: RpaDocumentSortOption
    $sortDirection: String
  ) {
    rpaDocuments(
      status: $status
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortDirection: $sortDirection
    ) {
      totalCount
      collection {
        id
        title
        lastUpdated
      }
    }
  }
`);
