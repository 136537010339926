/* eslint-disable */
import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { Link, useNavigate } from 'react-router-dom-latest';
import dayjs from 'dayjs';
import { Button, ButtonGroup } from 'reactstrap';
import Pagination from 'common/Pagination';
import { STRIPE_PAYOUTS, STRIPE_PAYOUTS_CSV } from 'constants/constants';
import { AlertMessage, sortingIcon, getCpaName } from 'utilities/utils';
import { withRouter } from 'utilities/withRouterWrapper';
import DateRangePickerButton from './DateRangePickerButton';

const tabs = [
  {
    value: 'paid',
    label: 'Paid',
  },
];

const payoutsHeaders = [
  {
    value: 'date',
    label: 'Date',
    isSortable: false,
    className: ' col-2 thCol',
  },
  {
    value: 'amount',
    label: 'Amount',
    isSortable: true,
    className: ' col-2 thCol',
  },
  {
    value: 'status',
    label: 'Status',
    isSortable: false,
    className: ' col-2 thCol',
  },
  {
    value: 'bank',
    label: 'Bank Account',
    isSortable: false,
    className: ' col thCol',
  },
];

const getDataOfUrl = (props, {
  activeTab, filter, searchFieldText, sortBy, sortOrder,
}) => {
  const searchParams = new URLSearchParams(props.location.search);
  let startDate;
  let endDate;
  let page = 1;
  searchParams.forEach((value, key) => {
    if (key === 'filter') {
      activeTab = tabs.findIndex((each) => each.value.toLowerCase() === value.toLowerCase());
      if (activeTab < 0) {
        activeTab = 0;
      }
      filter = value;
    } else if (key === 'page') {
      page = parseInt(value, 10);
    } else if (key === 'sort_by') {
      sortBy = value;
    } else if (key === 'sort_order') {
      sortOrder = value;
    } else if (key === 'search') {
      searchFieldText = value;
    } else if (key === 'start_date') {
      startDate = value;
    } else if (key === 'end_date') {
      endDate = value;
    }
  });
  return {
    activeTab,
    filter,
    page,
    searchFieldText,
    sortBy,
    sortOrder,
    startDate,
    endDate,
  };
};

const Payouts = (props) => {
  document.title = 'Payouts';
  const [state, setState] = useState({
    totalPages: 0,
    totalRecords: 0,
    data: [],
    tabs,
    offset: [0, 25],
    ...getDataOfUrl(props, {
      filter: 'paid',
      page: 1,
      sortBy: 'date',
      sortOrder: 'desc',
      searchFieldText: '',
      startDate: undefined,
      endDate: undefined,
    }),
    selectedData: [],
    selectedAll: false,
  });
  const [payouts, setPayouts] = useState();
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const getPaymentOuts = async () => {
    try {
      let apiUrl = STRIPE_PAYOUTS;
      const {
        page, sortBy, sortOrder, filter, startDate, endDate,
      } = getDataOfUrl(props, state);
      apiUrl += `?filter=${state.filter}&page=${page}&sort_by=${sortBy}&sort_order=${sortOrder}${
        startDate && endDate ? `&start_date=${startDate}&end_date=${endDate}` : ''
      }`;
      const response = await Axios.get(apiUrl).then((res) => res.data.data);
      const { stats } = response;
      setState({
        ...state,
        data: response.list,
        totalPages: stats.pages,
        totalRecords: stats.count,
        offset: [stats.from, stats.to],
        page,
        sortBy,
        sortOrder,
        filter,
        startDate,
        endDate,
      });
      setActiveTab(startDate ? 1 : 0);
      setPayouts(response);
      setLoading(false);
    } catch (err) {
      AlertMessage('error', err.response.data.message, 3000);
      setLoading(false);
    }
  };

  useEffect(() => {
    getPaymentOuts();

    return () => {
      // this will used for payouts buttons in payouts details page
      localStorage.setItem('allPayoutsButtonUrl', props.location.pathname + props.location.search);
    };
  }, [props.location.search]);

  const handleSorting = () => {};

  const getPayoutsInCsv = async () => {
    setLoading(true);
    try {
      let apiUrl = STRIPE_PAYOUTS_CSV;
      const { startDate, endDate } = getDataOfUrl(props, state);
      apiUrl += (startDate && endDate ? '?start_date=' + startDate + '&end_date=' + endDate : '');
      const response = await Axios.get(apiUrl).then((res) => res.data);
      const blob = new Blob([response.replace(/&#39;/g, "'").replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&amp;/g, '&')
      .replace(/&quot;/g, '"')], {
        type: 'text/csv;charset=utf-8;'
      });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', `${getCpaName()} Payouts.csv`);
      document.body.appendChild(link);
      link.click();
      setLoading(false);
    } catch (err) {
      setLoading(false);
      AlertMessage('error', err.response.data.message, 3000);
    }
  };

  const handleDateChange = (value) => {
    let url = `/payouts?filter=${state.filter}&page=${1}&sort_by=${state.sortBy}&sort_order=${state.sortOrder}`;
    if (value && value.length > 0) {
      url += `&start_date=${dayjs(value[0]).format('YYYY-MM-DD')}&end_date=${dayjs(value[1]).format('YYYY-MM-DD')}`;
    }
    navigate(url);
  };

  const handlePageChange = (input) => {
    let url = `/payouts?filter=${state.filter}&page=${input}&sort_by=${state.sortBy}&sort_order=${state.sortOrder}`;
    if (state.startDate && state.endDate) {
      url += `&start_date=${state.startDate}&end_date=${state.endDate}`;
    }
    navigate(url);
  };

  const { sortBy, sortOrder } = state;
  return (
    <div id="content">
      <header>
        <div className="d-block">
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/integrations">
                <i className="icon-left-arrow label-small" aria-hidden="true" />
                {'Integrations'.toUpperCase()}
                {' '}
                /
              </Link>
              {' '}
              Payouts
            </li>
          </ul>
        </div>
      </header>
      {loading && <div id="loading" />}
      <div className="center-wrapper py-0">
        <div className="d-flex mt-3 justify-content-between">
          <div className="flex">
            <div className="mailFilter mailFilter--primary">
              <ButtonGroup className="btn-group-transparent">
                <Button
                  className={`btn-sm btn-transparent ${activeTab === 0 ? 'active' : null}`}
                  onClick={handleDateChange}
                >
                  All
                </Button>
                <DateRangePickerButton
                  className={`btn-sm btn-transparent rounded-0 ${activeTab === 1 ? 'active' : null}`}
                  selectedDates={[state.startDate, state.endDate]}
                  onSelect={handleDateChange}
                />
              </ButtonGroup>
            </div>
          </div>
          <div>
            <button type="button" className="btn btn-sm btn-outline-light mb-3" onClick={getPayoutsInCsv}>
              <i className="icon-download2 p-0" aria-hidden="true" />
            </button>
          </div>
        </div>
        <div className="tab-content">
          <div className="tab-pane active">
            <table className="tableWrap col">
              <thead className="tRow tRow--head">
                <tr className="row">
                  {payoutsHeaders.map((each) => (
                    <th
                      key={each.value}
                      className={sortingIcon(sortBy, each.value, sortOrder, 'className') + each.className}
                      onClick={() => (each.isSortable ? handleSorting(each.value) : null)}
                    >
                      <span className="posRel">
                        {each.label}
                        <i className={sortingIcon(sortBy, each.value, sortOrder)} />
                      </span>
                    </th>
                  ))}
                  <td className="col thCol" />
                </tr>
              </thead>
              <tbody className="tRow tRow--body ">
                {payouts
                  && payouts.list
                  && payouts.list.map((each) => (
                    <tr className="tdBtn row" key={each.id}>
                      <td className="col-2 tdCol text-body">
                        <Link className="text-body" to={`/payout/${each.id}`}>
                          <span>
                            {each.payout_date}
                          </span>
                        </Link>
                      </td>
                      <td className="col-2 tdCol text-body">
                        <Link className="text-body" to={`/payout/${each.id}`}>
                          <span>
                            {((each.payout_amount * 100) / 100).toLocaleString('en-US', {
                              style: 'currency',
                              currency: 'USD',
                            })}
                          </span>
                        </Link>
                      </td>
                      <td className="col-2 tdCol text-body">
                        <Link className="text-body" to={`/payout/${each.id}`}>
                          <span className=" text-capitalize">{each.stripe_payout_status}</span>
                        </Link>
                      </td>
                      <td className="col tdCol text-body">
                        <Link className="text-body" to={`/payout/${each.id}`}>
                          <span className=" text-capitalize">{each.payout_bank}</span>
                        </Link>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="pagination-block" id="pagination-block">
          <Pagination
            currentPage={state.page}
            totalPages={state.totalPages}
            totalRecords={state.totalRecords}
            handlePageChange={handlePageChange}
            offset={state.offset[0]}
            off={state.offset[1]}
          />
        </div>
      </div>
    </div>
  );
};

export default withRouter(Payouts);
