/* eslint-disable react/jsx-props-no-spreading */
import { Button, Divider, Stack, Typography, styled } from '@mui/material';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom-latest';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  useCreateSmsBrandRegistration,
  useCreateSmsBrandRegistrationV5,
} from 'hooks/sms-hooks';
import { AlertMessage } from 'utilities/utils';
import { TOAST_FAILURE, TOAST_SUCCESS } from 'constants/constants';
import { useState } from 'react';
import CompanyDetails from './CompanyDetails';
import Address from './Address';
import {
  SMSSettingsType,
  getFormSchemaBasedOnFeatureFlag,
} from './FormValidation';

const SettingsForm = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  width: '742px',
});

export default function SMSSettingsFormBody() {
  const [isDisabled, setIsDisabled] = useState(false);
  const navigate = useNavigate();
  const { smsPrivacyPolicyUpdate } = useFlags();
  const formSchema = getFormSchemaBasedOnFeatureFlag(smsPrivacyPolicyUpdate);

  const { mutate: createSmsBrandRegistration } =
    useCreateSmsBrandRegistration();
  const { mutate: createSmsBrandRegistrationV5 } =
    useCreateSmsBrandRegistrationV5();

  const formMethods = useForm<SMSSettingsType>({
    resolver: yupResolver(formSchema),
  });

  const { handleSubmit } = formMethods;

  const onSubmitV5: SubmitHandler<SMSSettingsType> = (formValues) => {
    createSmsBrandRegistrationV5(formValues, {
      onError: (networkError) => {
        const error = networkError as { response: { data: { error: string } } };
        const errorMessage =
          error?.response?.data?.error ||
          'Something went wrong. Please contact support';
        setIsDisabled(false);
        AlertMessage(TOAST_FAILURE, errorMessage);
      },
      onSuccess: () => {
        AlertMessage(
          TOAST_SUCCESS,
          'Your request has been submitted. It will take a moment to confirm your registration. We’ll notify you when your team is able to get phone lines and start texting!',
        );
        setTimeout(() => {
          navigate('/sms');
        }, 6000);
      },
    });
  };

  const onSubmit: SubmitHandler<SMSSettingsType> = (params) => {
    setIsDisabled(true);
    createSmsBrandRegistration(
      { params },
      {
        onError: (errorResponse) => {
          setIsDisabled(false);
          AlertMessage(
            TOAST_FAILURE,
            errorResponse[0]?.message ||
              'Something went wrong. Please contact support',
          );
        },
        onSuccess: (response) => {
          if (
            response?.createSmsBrandRegistration?.errors &&
            response?.createSmsBrandRegistration?.errors.length > 0
          ) {
            setIsDisabled(false);
            AlertMessage(
              TOAST_FAILURE,
              response.createSmsBrandRegistration.errors[0].message,
            );
          } else {
            AlertMessage(
              TOAST_SUCCESS,
              'Your request has been submitted. It will take a moment to confirm your registration. We’ll notify you when your team is able to get phone lines and start texting!',
            );
            setTimeout(() => {
              navigate('/sms');
            }, 6000);
          }
        },
      },
    );
  };

  return (
    <Stack
      maxHeight="calc(100% - 42px)"
      alignItems="center"
      paddingBottom={2}
      overflow="auto"
    >
      <SettingsForm
        onSubmit={handleSubmit(smsPrivacyPolicyUpdate ? onSubmitV5 : onSubmit)}
      >
        <Stack>
          <Typography fontWeight="bolder" variant="h1">
            ENABLE TEXT MESSAGING
          </Typography>
          <Typography variant="h4" fontWeight="400" color="text.secondary">
            All fields are required and will be used for registration with the
            carrier. Please note the details below must be an EXACT MATCH to
            your SS-4 letter from the IRS.
          </Typography>
          <br />
          <Typography variant="h4" fontWeight="400" color="text.secondary">
            Once your registration is approved, your team will be able to
            provision phone lines and start texting. All provisioned lines will
            have a monthly fee. See more{' '}
            <a
              href="https://liscio.me/pricing"
              target="_blank"
              color="primary"
              rel="noreferrer"
            >
              pricing details here.
            </a>
          </Typography>
        </Stack>
        <FormProvider {...formMethods}>
          <CompanyDetails />
          <Address />
        </FormProvider>
        <Stack />
        <Divider sx={{ marginY: '1rem' }} />
        <Stack direction="row" gap={2}>
          <Button variant="outlined" onClick={() => navigate(-1)}>
            Cancel
          </Button>
          <Button variant="contained" type="submit" disabled={isDisabled}>
            Submit Request
          </Button>
        </Stack>
      </SettingsForm>
    </Stack>
  );
}
