import React from 'react';
import EachApp from './EachApp/EachApp';

const EmailIntegration = ({
  thirdPartyApps,
  handleConnect,
  handleDisconnect,
}) =>
  thirdPartyApps &&
  thirdPartyApps.map((app) => (
    <EachApp
      key={app.id}
      appDetails={app}
      emailButton
      handleConnect={handleConnect}
      handleDisconnect={handleDisconnect}
    />
  ));

export default EmailIntegration;
