import { Box, useTheme } from '@mui/material';

interface FooterProps {
  children?: React.ReactNode;
}

export const Footer = ({ children }: FooterProps) => {
  const theme = useTheme();
  return (
    <Box
      component="footer"
      flexGrow={0}
      display="flex"
      justifyContent="end"
      padding="10px 20px"
      marginTop="auto"
      gap="10px"
      borderTop={`1px solid ${theme.palette.common.secondary[200]}`}
    >
      {children}
    </Box>
  );
};
