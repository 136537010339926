import { createConsumer, Consumer } from '@rails/actioncable';
import { SERVER_URL } from 'constants/constants';

class WebsocketService {
  public consumer: Consumer;

  constructor() {
    const socketType = window.location.origin.includes('localhost') ? 'ws' : 'wss';
    const hostname = (SERVER_URL || '').replace(/(^\w+:|^)\/\//, '');
    const websocketUrl = `${socketType}://${hostname}/cable`;
    this.consumer = createConsumer(websocketUrl);
  }

  public authenticate(authToken: string) {
    try {
      if (!this.consumer?.subprotocols.includes(authToken)) {
        this.consumer.addSubProtocol(authToken);
      }
    } catch (error) {
      console.log('Unable to authenticate ws consumer');
    }
  }
}
export type WebsocketServiceInstance = InstanceType<typeof WebsocketService>;
export const websocket = new WebsocketService();
