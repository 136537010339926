import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { Link } from 'react-router-dom-latest';
import { withRouter } from 'utilities/withRouterWrapper';
import dayjs from 'dayjs';
import { STRIPE_PAYOUTS } from 'constants/constants';
import { AlertMessage, generateFullName, sortingIcon } from 'utilities/utils';

const payoutHeaders = [
  {
    value: 'task_name',
    label: 'Task Name',
    isSortable: false,
    className: ' col-2 thCol',
  },
  {
    value: 'account_name',
    label: 'Account Name',
    isSortable: false,
    className: ' col-2 thCol',
  },
  {
    value: 'assigned_to',
    label: 'Assigned To',
    isSortable: false,
    className: ' col-2 thCol',
  },
  {
    value: 'invoice_number',
    label: 'Invoice Number',
    isSortable: false,
    className: ' col-1 thCol',
  },
  {
    value: 'invoice_amount',
    label: 'Invoice Amount',
    isSortable: false,
    className: ' col-1 thCol',
  },
  {
    value: 'amount_collected',
    label: 'Amount Collected',
    isSortable: false,
    className: ' col-1 thCol',
  },
  {
    value: 'fee',
    label: 'Fee',
    isSortable: false,
    className: ' col-1 thCol',
  },
  {
    value: 'net',
    label: 'Balance',
    isSortable: false,
    className: ' col thCol',
  },
];

const PayoutDetails = (props) => {
  const [payout, setPayout] = useState();
  const [loading, setLoading] = useState(true);
  const sortOrder = 'desc';
  const sortBy = 'date';

  const getPayoutDetails = async () => {
    try {
      const payoutID = props.params.id;
      const response = await Axios.get(`${STRIPE_PAYOUTS}/${payoutID}`).then(
        (res) => res.data.data,
      );
      let total_amount = 0;
      let fee = 0;
      const bank_details = '';
      response.list.forEach((e) => {
        total_amount += e.invoice_amount;
        fee += e.application_fee_amount;
      });
      response.summary = {
        ...response.summary,
        total_amount,
        fee,
        bank_details,
      };
      setPayout(response);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      AlertMessage('error', err.response.data.message, 3000);
    }
  };

  useEffect(() => {
    getPayoutDetails();
  }, []);

  const handlePayoutsBtn = () => {
    let url = localStorage.getItem('allPayoutsButtonUrl');
    if (!url && url === null) {
      url = '/payouts';
    }
    return url;
  };

  return (
    <div id="content">
      <header>
        <div className="d-block">
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={handlePayoutsBtn()}>
                <i className="icon-left-arrow label-small" aria-hidden="true" />
                {'Payouts'.toUpperCase()} /
              </Link>{' '}
              Payout Details
            </li>
          </ul>
        </div>
      </header>
      {loading && <div id="loading" />}
      <div className="center-wrapper">
        {payout && payout.summary ? (
          <div className="row">
            <div className="form-group form-inline-label col-lg-6 col-sm-12">
              <label htmlFor="account">Payout Amount:</label>
              <div className="labelValue">
                {((payout.summary.payout_amount * 100) / 100).toLocaleString(
                  'en-US',
                  {
                    style: 'currency',
                    currency: 'USD',
                  },
                )}
              </div>
            </div>
            <div className="form-group form-inline-label col-lg-6 col-sm-12">
              <label htmlFor="account">Status:</label>
              <div className="labelValue text-capitalize">
                {payout.summary.stripe_payout_status}
              </div>
            </div>
            <div className="form-group form-inline-label col-lg-6 col-sm-12">
              <label htmlFor="account">Date:</label>
              <div className="labelValue">
                {dayjs(payout.summary.payout_at).format('MM-DD-YYYY')}
              </div>
            </div>
            <div className="form-group form-inline-label col-lg-6 col-sm-12">
              <label htmlFor="account">No of Invoices:</label>
              <div className="labelValue">{payout.list.length}</div>
            </div>
            <div className="form-group form-inline-label col-lg-6 col-sm-12">
              <label htmlFor="account">Bank Account:</label>
              <div className="labelValue">{payout.summary.payout_bank}</div>
            </div>
          </div>
        ) : null}
        <div className="tableWrap">
          <div className="tRow tRow--head">
            <div className="row">
              {payoutHeaders.map((each) => (
                <div
                  key={each.value}
                  className={
                    sortingIcon(sortBy, each.value, sortOrder, 'className') +
                    each.className +
                    (!each.isSortable ? ' cursor-default' : '')
                  }
                >
                  <span className="posRel">
                    {each.label}
                    <i className={sortingIcon(sortBy, each.value, sortOrder)} />
                  </span>
                </div>
              ))}
            </div>
          </div>
          <div>
            <div className="tRow tRow--body">
              {payout && payout.list.length && !loading
                ? payout.list.map((item) => (
                    <div className="tdBtn row" key={item.id}>
                      <div className=" col-2 tdCol">
                        <Link
                          className="text-body"
                          to={`/task/detail/${item.id}`}
                          target="_blank"
                        >
                          <span>{item.subject || '-'}</span>
                        </Link>
                      </div>
                      <div className=" col-2 tdCol">
                        <Link
                          className="text-body"
                          to={`/task/detail/${item.id}`}
                          target="_blank"
                        >
                          <span>{item.account_name || '-'}</span>
                        </Link>
                      </div>
                      <div className=" col-2 tdCol">
                        <Link
                          className="text-body"
                          to={`/task/detail/${item.id}`}
                          target="_blank"
                        >
                          <span>{generateFullName(item)}</span>
                        </Link>
                      </div>
                      <div className=" col-1 tdCol">
                        <Link
                          className="text-body"
                          to={`/task/detail/${item.id}`}
                          target="_blank"
                        >
                          <span>{item.invoice_number || '-'}</span>
                        </Link>
                      </div>
                      <div className=" col-1 tdCol">
                        <Link
                          className="text-body"
                          to={`/task/detail/${item.id}`}
                          target="_blank"
                        >
                          <span>
                            {((item.invoice_amount * 100) / 100).toLocaleString(
                              'en-US',
                              {
                                style: 'currency',
                                currency: 'USD',
                              },
                            )}
                          </span>
                        </Link>
                      </div>
                      <div className=" col-1 tdCol">
                        <Link
                          className="text-body"
                          to={`/task/detail/${item.id}`}
                          target="_blank"
                        >
                          <span>
                            {((item.invoice_amount * 100) / 100).toLocaleString(
                              'en-US',
                              {
                                style: 'currency',
                                currency: 'USD',
                              },
                            )}
                          </span>
                        </Link>
                      </div>
                      <div className=" col-1 tdCol">
                        <Link
                          className="text-body"
                          to={`/task/detail/${item.id}`}
                          target="_blank"
                        >
                          <span>
                            {(
                              (item.application_fee_amount * 100) /
                              100
                            ).toLocaleString('en-US', {
                              style: 'currency',
                              currency: 'USD',
                            })}
                          </span>
                        </Link>
                      </div>
                      <div className=" col-1 tdCol">
                        <Link
                          className="text-body"
                          to={`/task/detail/${item.id}`}
                          target="_blank"
                        >
                          <span>
                            {(
                              ((item.invoice_amount -
                                item.application_fee_amount) *
                                100) /
                              100
                            ).toLocaleString('en-US', {
                              style: 'currency',
                              currency: 'USD',
                            })}
                          </span>
                        </Link>
                      </div>
                    </div>
                  ))
                : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(PayoutDetails);
