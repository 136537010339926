import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom-latest';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { messageObj } from 'components/Billing/billingUtils';
import WarningIcon from '@mui/icons-material/Warning';
import styled from '@emotion/styled';

const StyledModal = styled(Modal)`
  width: 400px;
  .ModalHeader__Title {
    display: flex;
    align-items: center;
  }

  .ModalHeader__Icon {
    margin-right: 4px;
  }

  .StripeAccountSetup__ModalBody,
  .ModalHeader {
    padding: 16px !important;
  }
`;

const StripeAccountSetupModal = ({
  isModalOpen,
  closeStripeAccountStatusModal,
  stripeAccountStatus,
}) => (
  <StyledModal
    isOpen={isModalOpen}
    className="StripeAccountSetup__Modal customModal customModal--center"
  >
    <ModalHeader className="ModalHeader">
      <div className="ModalHeader__Title">
        <WarningIcon className="ModalHeader__Icon" fontSize="large" />
        <div> Stripe Account Not Setup</div>
      </div>
      <button
        type="button"
        onClick={closeStripeAccountStatusModal}
        className="close"
        aria-label="Close"
      >
        <i className="icon-close2" aria-hidden="true" />
      </button>
    </ModalHeader>
    <ModalBody className="StripeAccountSetup__ModalBody">
      <div>
        {messageObj[stripeAccountStatus]}.
        <Link to="/integrations">Go to Integrations</Link>
      </div>
    </ModalBody>
    <ModalFooter className="modalContent">
      <button
        className="btn"
        type="button"
        onClick={closeStripeAccountStatusModal}
      >
        Cancel
      </button>
    </ModalFooter>
  </StyledModal>
);

StripeAccountSetupModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  closeStripeAccountStatusModal: PropTypes.func.isRequired,
  stripeAccountStatus: PropTypes.string.isRequired,
};

export default StripeAccountSetupModal;
