import React from 'react';
import { getCanViewPreferences, getLoggedInType } from 'utilities/utils';
import EmailsSection from './components/EmailsSection';
import EmailsAccessPermission from './components/EmailsAccessPermission';

const Emails = ({ navigate, location }) => {
  document.title = 'Emails';

  const switchedUser = getLoggedInType();
  const canViewData = getCanViewPreferences();

  if (!switchedUser || (canViewData && canViewData[0].key === 'email')) {
    return <EmailsSection navigate={navigate} location={location} />;
  }
  return <EmailsAccessPermission />;
};

export default Emails;
