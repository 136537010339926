import { TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { RowModel, flexRender } from '@tanstack/react-table';
import React from 'react';

interface Props {
  getRowModel: () => RowModel<SMSLine>;
}

export function SMSAdminTableBody({ getRowModel }: Props) {
  return (
    <TableBody>
      {getRowModel().rows.map((row) => (
        <TableRow key={row.id}>
          {row.getVisibleCells().map((cell) => (
            <TableCell key={cell.id}>
              <Typography variant="body1">
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </Typography>
            </TableCell>
          ))}
        </TableRow>
      ))}
    </TableBody>
  );
}
