/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import Interweave from 'interweave';
import {
  VIEW_NOTIFICATION,
  MARK_ALL_READ,
  GET_NOTIFICATIONS,
} from 'constants/constants';
import styled from '@emotion/styled';
import { Link, useNavigate } from 'react-router-dom-latest';
import { Check } from '@mui/icons-material';
import { ListItemIcon } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import Badge from '@mui/material/Badge';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as NotificationActions from 'actions/NotificationActions';
import * as MessageActions from 'actions/MessageActions';
import { getStorageData } from 'utilities/utils';
import { useWebsocket } from 'websocket';
import NotificationPopover from './NotificationPopover';
import { MenuItemIcon } from './MenuItemIcon';

const StyledDiv = styled.div`
  top: 488px;
  left: 228px;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.1);

  .NotificationsContainer {
    max-height: 550px;
    overflow-y: auto;
  }

  .Notification {
    padding: 16px;
    border-bottom: 1px solid #dfe3e8;

    .Notification__Header,
    .Notification__Sender,
    .Notification__Title {
      margin-bottom: 4px;
    }

    .Notification__Header {
      display: flex;
      justify-content: space-between;
      color: #919eab;
      margin-bottom: 4px;
    }

    .Notification__Type {
      i {
        margin-right: 4px;
        font-size: 12px;
      }
      display: flex;
      align-items: baseline;
    }

    .Notification__Content {
      p {
        margin-bottom: 0;
      }
    }
  }

  .Notifications__Loading,
  .Notifications__NotFound {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 10vh;

    p {
      color: #919eab;
      font-weight: bold;
      font-size: 20px;
    }
  }

  .NotificationItemSubText {
    display: flex;
    align-items: center;
    .MuiListItemIcon-root {
      min-width: 10px;
      margin-right: 2px;
    }
    color: #919eab;
    font-size: 12px;
    font-weight: 600;
  }

  .NotificationTitleText {
    font-size: 12px;
    font-weight: 600;
    span:nth-of-type(1) {
      margin-right: 2px;
    }
  }

  .NotificationHeader {
    border-bottom: 1px solid #dfe3e8;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px;
    text-transform: uppercase;
  }

  .NotificationFooter {
    padding: 10px;
    background-color: #f9fafb;
    text-decoration: none;
    text-align: center;
  }

  .NotificationFooterText {
    text-decoration: none;
    border-radius: 0 0 4px 4px;
    font-weight: 600;
    letter-spacing: 1px;
    color: #919eab;
    padding: 15px 20px;
    text-transform: uppercase;
  }

  .CheckIcon {
    font-size: 15px;
  }
`;

const iconMap = {
  Task: 'icon-Tasks',
  Message: 'icon-message2',
};

const SidebarNotifications = ({
  listItem,
  notifications,
  notiCount,
  unread_thread_count,
  notificationActions,
  messageActions,
  getSelectedMenuItem,
  handleClick,
}) => {
  const [isOpen, setNotificationsModal] = useState(false);
  const [responseData, setResponseData] = useState(null);
  const [isLoading, setIsNotificationLoad] = useState(true);
  const websocket = useWebsocket();

  useEffect(() => {
    fetchNotifications();
    registerNotificationListener();
  }, []);

  // TO-DO have to find better way to do this
  function registerNotificationListener() {
    const tokenInterval = setInterval(() => {
      // TODO: __HOMEDATA-AUDIT
      const local_data = getStorageData();
      if (local_data) {
        clearInterval(tokenInterval);
        // eslint-disable-next-line no-unused-vars
        websocket.consumer.subscriptions.create(
          {
            channel: 'MessageChannel',
            cpa_user_id: local_data.cpa_user_id,
          },
          {
            connected: () => {
              console.log('connected to MessageChannel');
            },
            // eslint-disable-next-line consistent-return
            received: (data) => {
              const url = window.location.pathname;
              if (
                !url.includes('accountdetails') &&
                !url.includes('contactdetails')
              ) {
                const url_thread_id = decodeURIComponent(
                  url.split('/inbox/')[1],
                );
                if (
                  data.inbox[0].thread_id === url_thread_id &&
                  !data.read_status
                ) {
                  messageActions.receivedNotification(true);
                } else {
                  messageActions.receivedNotification(false);
                }

                return messageActions.addNewMessage(data);
              }
            },
            disconnected: () => {
              console.log('disconnected from message channel');
            },
          },
        );
        websocket.consumer.subscriptions.create(
          {
            channel: 'OlympusNotificationChannel',
            cpa_user_id: local_data.cpa_user_id,
          },
          {
            connected: () => {
              console.log('connecteNotificationCable');
            },
            received: (data) => {
              // this.props.NotificationActions.notificationCount(data);
              if (data.release_version) {
                if (
                  Number(data.release_version) >
                  Number(JSON.parse(localStorage.getItem('release')))
                ) {
                  localStorage.setItem(
                    'release',
                    JSON.stringify(data.release_version),
                  );
                  window.location.reload(true);
                }
                notificationActions.notificationCount(data.count);
              } else {
                notificationActions.notificationCount(data);
              }
            },
            disconnected: () => {
              console.log('disconnected from notification channel');
            },
          },
        );
      }
    }, 200);
  }

  async function fetchNotifications() {
    try {
      const response = await Axios.get(GET_NOTIFICATIONS);
      if (response.status === 200) {
        setResponseData(response.data);
        setIsNotificationLoad(false);
        notificationActions.fetchNotification(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function markAllAsRead() {
    try {
      const response = await Axios.post(MARK_ALL_READ);
      if (response.data.success) {
        fetchNotifications();
        messageActions.receiveThreadCount(0);
        closeNotificationsModal();
      }
    } catch (error) {
      console.log(error);
    }
  }

  function toggleNotificationModal() {
    if (!isOpen) {
      fetchNotifications();
    }
    setNotificationsModal(!isOpen);
  }

  function closeNotificationsModal() {
    setNotificationsModal(false);
  }

  return (
    <NotificationPopover
      isOpen={isOpen}
      onOpenChange={toggleNotificationModal}
      triggerComponent={
        <ListItemButton
          className="NotificationsMenuList"
          onClick={(e) => handleClick(e, listItem)}
          selected={getSelectedMenuItem(listItem)}
          data-testid={`sidebar__${listItem?.label?.toLowerCase()}`}
        >
          <Badge badgeContent={notiCount}>
            <ListItemIcon>
              <MenuItemIcon iconClass={listItem.icon_class} />
            </ListItemIcon>
          </Badge>
          <ListItemText primary={listItem.label.toUpperCase()} />
        </ListItemButton>
      }
      contentComponent={
        <NotificationsModal
          data={{
            notifications,
            notiCount,
            unread_thread_count,
          }}
          notificationActions={notificationActions}
          messageActions={messageActions}
          notifications={responseData}
          isLoading={isLoading}
          closeNotificationsModal={toggleNotificationModal}
          handleMarkAllAsRead={markAllAsRead}
        />
      }
    />
  );
};

const NotificationsModal = ({
  handleMarkAllAsRead,
  notifications,
  isLoading,
  closeNotificationsModal,
}) => {
  const navigate = useNavigate();

  function notificationClick(notification) {
    let notiType = notification.type.toLowerCase();
    if (notiType === 'wfrequest') notiType = 'request';
    if (
      ['request', 'task reminder summary'].includes(notiType) &&
      !notification.is_read_in_app
    ) {
      Axios.put(`${VIEW_NOTIFICATION}/${notification.primary_id}`);
    }

    closeNotificationsModal();
    navigate(
      `/${notification.url}`,
      { state: { from: 'notify' } },
    );
  }

  function renderNotifications(notification) {
    const notiUrl = notification.url.split('/')[0];
    return (
      <div
        className="Notification"
        key={notification.primary_id}
        role="button"
        tabIndex={0}
        onClick={() => notificationClick(notification)}
      >
        <div className="Notification__Header">
          <div className="Notification__Type">
            <i className={iconMap[notification.type] || 'icon-message2'} />
            <div>{notiUrl === 'alert' ? 'Alert' : notification.type}</div>
          </div>
          <div>{notification.tym}</div>
        </div>
        <div className="Notification__Sender">
          <b>{notification.sender}</b>
        </div>
        <div className="Notification__Title">{notification.title}</div>
        <Interweave
          className="Notification__Content"
          content={notification.content_limit}
        />
      </div>
    );
  }

  return (
    <StyledDiv className="NotificationsModal">
      <div className="NotificationHeader">
        <div className="NotificationTitleText">
          <span>Notification</span>
          <span>
            {notifications && notifications.count
              ? `(${notifications.count})`
              : ''}
          </span>
        </div>
        <div className="NotificationItemSubText" data-testid="mark_all_as_read">
          <ListItemIcon>
            <Check className="CheckIcon" />
          </ListItemIcon>
          <div role="button" tabIndex={0} onClick={handleMarkAllAsRead}>
            Mark all as read
          </div>
        </div>
      </div>

      {isLoading && (
        <div className="Notifications__Loading">
          <p>FETCHING NOTIFICATIONS...</p>
        </div>
      )}

      {!isLoading && notifications && notifications.data.length === 0 && (
        <div className="Notifications__NotFound" data-testid="no_notifications">
          <p>NO NOTIFICATIONS FOUND</p>
        </div>
      )}

      {notifications && notifications.data.length > 0 && (
        <div className="NotificationsContainer">
          {notifications.data.map((notification) =>
            renderNotifications(notification),
          )}
        </div>
      )}

      <div className="NotificationFooter">
        <Link
          className="NotificationFooterText"
          onClick={closeNotificationsModal}
          to="/notifications"
          data-testid="view_all_notifications"
        >
          View all notifications
        </Link>
      </div>
    </StyledDiv>
  );
};

const mapStateToProps = (state) => ({
  notifications: state.notificationReducer.notifications,
  notiCount: state.notificationReducer.notiCount,
  unread_thread_count: state.messageReducer.unread_thread_count,
});

const mapDispatchToProps = (dispatch) => ({
  notificationActions: bindActionCreators(NotificationActions, dispatch),
  messageActions: bindActionCreators(MessageActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SidebarNotifications);
