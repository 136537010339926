import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  useMemo,
} from 'react';
import { useEncryptedField } from 'hooks/encrypted-fields-hooks';
import { isNil } from 'lodash';

export interface EncryptedFieldProps {
  recordType: string;
  recordId: string | number;
  fieldName: string;
  formFieldName: string;
  label: string;
  hasEncryptedValue?: boolean;
  value: string | null;
  onChange: (newValue: string, fieldName: string) => void;
  error?: string;
  className?: string;
  disabled?: boolean;
}

const MASK = '**********';

export const EncryptedField = ({
  recordType,
  recordId,
  fieldName,
  formFieldName,
  label,
  hasEncryptedValue,
  value,
  onChange,
  error,
  className,
  disabled,
}: EncryptedFieldProps) => {
  const [shouldLoadData, setShouldLoadData] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const { data, isFetching, remove } = useEncryptedField({
    recordType,
    recordId,
    fieldName,
    enabled: shouldLoadData && Boolean(recordId),
  });

  const onFocus = useCallback(() => {
    if (value === null || value === undefined) {
      setShouldLoadData(true);
    }
  }, [value]);

  const onVisibleClick = useCallback(() => {
    onFocus();
    setIsVisible((currentState) => !currentState);
  }, [onFocus]);

  const valueToDisplay = useMemo(() => {
    if (!isNil(value)) {
      return value;
    }
    if (hasEncryptedValue && !isFetching && !data) {
      return MASK;
    }
    return '';
  }, [data, hasEncryptedValue, isFetching, value]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(e?.target?.value, formFieldName);
    },
    [formFieldName, onChange],
  );

  useEffect(() => {
    if (data?.enc_value) {
      onChange(data?.enc_value, formFieldName);

      inputRef.current?.focus();
    }
  }, [data, formFieldName, onChange, remove]);

  useEffect(() => {
    if (isVisible) {
      inputRef.current?.focus();
    }
  }, [isVisible]);

  return (
    <div
      className={className ? className + (error ? ' form-error' : '') : 'col'}
    >
      <label htmlFor={formFieldName}>{label}</label>
      <div className="labelValue inputGroup">
        <input
          type={isVisible ? 'text' : 'password'}
          className={`form-control ${!isVisible ? 'SensitiveField' : null}`}
          id={formFieldName}
          placeholder={isFetching ? 'Loading...' : ''}
          value={valueToDisplay}
          disabled={disabled || isFetching}
          autoComplete="off"
          onChange={handleChange}
          onFocus={onFocus}
          ref={inputRef}
        />
        <span
          className="inputHelpIcon"
          onClick={onVisibleClick}
          aria-label={`change ${formFieldName} visibility`}
        >
          <i
            className={isVisible ? 'icon-close-eye' : 'icon-open-eye'}
            aria-hidden="true"
          />
        </span>
        <span className="error_label">{error}</span>
      </div>
    </div>
  );
};

export default EncryptedField;
