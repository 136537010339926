/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/button-has-type */
/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import Axios from 'axios';
import { Media } from 'reactstrap';
import { CheckboxField } from 'common/CustomFields';
import { Link } from 'react-router-dom-latest';
import {
  EMPLOYEE,
  DEACTIVATE_EMPLOYEE,
  REACTIVATE_EMPLOYEE,
} from 'constants/constants';
import ConfirmationModal from 'common/ConfirmationModal';
import {
  AlertMessage,
  generateInitials,
  generateFullName,
  labelNamebyValue,
  getCpaPreferences,
  getEmployeeRolesForCpa,
  isUserEmployee,
  getFirmType,
  FORMATPHONENUMBER,
  getStorageData,
} from 'utilities/utils';
import { withStorageData } from 'utilities/withStorageData';

const employeeSection = [
  {
    value: 'first_name',
    label: 'first Name',
  },
  {
    value: 'middle_name',
    label: 'middle name',
  },
  {
    value: 'last_name',
    label: 'last name',
  },
  {
    value: 'title',
    label: 'Job Title',
  },
  {
    value: 'status',
    label: 'status',
  },
  {
    value: 'email',
    label: 'email',
  },
  {
    value: 'phone',
    label: 'phone',
    formatValue: FORMATPHONENUMBER,
  },
  {
    value: 'role',
    label: 'role',
    formatValue: (input) =>
      labelNamebyValue(input, getCpaPreferences().user_role),
  },
];

const DisplayProperty = (props) => {
  const { data, propertyValues } = props;
  return (
    <div className="col-sm-12 col-md-6 col-lg-4">
      <div
        className={`form-group ${
          propertyValues.notInline ? '' : 'form-inline-label'
        }`}
      >
        <label className="align-self-start">
          {propertyValues.label ? `${propertyValues.label}: ` : null}
        </label>
        <div className="labelValue">
          <span>
            {data[propertyValues.value] !== null
              ? propertyValues.formatValue
                ? propertyValues.formatValue(data[propertyValues.value])
                : data[propertyValues.value]
              : '-'}
          </span>
        </div>
      </div>
    </div>
  );
};

class UserInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {},
      // TODO: __HOMEDATA-AUDIT
      isEmployee: getStorageData().is_employee,
      preferences: getEmployeeRolesForCpa(getCpaPreferences()),
      showDeleteConfirmation: false,
      loading: true,
    };
  }

  UNSAFE_componentWillMount() {
    if (!isUserEmployee()) {
      this.props.navigate('/');
    }
  }

  componentDidMount() {
    const userId = window.location.pathname.split('/userdetails/')[1];
    if (userId) {
      Axios.get(`${EMPLOYEE}/${userId}`)
        .then((res) => {
          if (res.status === 200 && res.data.status === 200) {
            this.setState({
              data: res.data.data[0],
              userId,
              loading: false,
            });
          }
        })
        .catch(() => AlertMessage('error', 'Something went wrong', 3000));
    }
  }

  deactivateEmployee = () => {
    if (this.state.showDeleteConfirmation) {
      Axios.put(`${DEACTIVATE_EMPLOYEE}/${this.state.userId}`)
        .then((res) => {
          if (res.status === 200 && res.data && res.data.success) {
            AlertMessage('success', res.data.message, 1000);
            this.setState({
              showDeleteConfirmation: false,
              data: { ...this.state.data, status: 'Inactive' },
            });
          } else {
            AlertMessage('error', res.data.message, 1000);
          }
        })
        .catch(() =>
          AlertMessage('error', 'Something went wrong. Please try again', 1000),
        );
    } else {
      this.setState({ showDeleteConfirmation: true });
    }
  };

  handleCloseModal = () => {
    this.setState({ showDeleteConfirmation: false });
  };

  reactivateEmployee = () => {
    let { data } = this.state;
    data = { ...this.state.data, status: 'New' };
    Axios.put(`${REACTIVATE_EMPLOYEE}/${this.state.userId}`, data)
      .then((res) => {
        if (res.status === 200 && res.data && res.data.success) {
          AlertMessage('success', res.data.message, 1000);
          this.setState({ data });
        } else {
          AlertMessage('error', res.data.message, 1000);
        }
      })
      .catch(() =>
        AlertMessage('error', 'Something went wrong. Please try again', 1000),
      );
  };

  render() {
    const { data, userId } = this.state;
    return (
      <div id="content">
        <header>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/admin">All users</Link>
            </li>
            <li className="breadcrumb-item">{generateFullName(data)}</li>
          </ul>
        </header>
        {this.state.loading ? <div id="loading" /> : null}
        {data.first_name ? (
          <div className="center-wrapper">
            {data.status && data.status.toLowerCase() !== 'inactive' ? (
              <div className="Edit-btn-right">
                {/* <Link className="btn-link btn--onlyicon btn btn-secondary" to={'/user/edit/' + userId}>
                  <i className="icon-edit mx-2" aria-hidden="true" />
                  Edit Employee
                </Link> */}
                <Link
                  className="btn-link btn--onlyicon btn btn-secondary"
                  to={`/user/edit/${userId}`}
                >
                  <i className="icon-edit mx-2" aria-hidden="true" />
                  Edit Employee
                </Link>
                <button
                  type="button"
                  className="btn-link btn--onlyicon btn btn-secondary"
                  onClick={this.deactivateEmployee}
                >
                  <i className="icon-delete-small mx-2" aria-hidden="true" />
                  Archive Employee
                </button>
              </div>
            ) : (
              <div className="Edit-btn-right">
                <button
                  className="btn-link btn--onlyicon btn btn-secondary"
                  onClick={this.reactivateEmployee}
                >
                  Reactivate Employee
                </button>
              </div>
            )}
            <div className="center-wrapper col-10 m-auto">
              <Media className="bottomspace align-items-center">
                <Media left href="#" className="avatar avatar--w100 mr-2">
                  {data.avatar && data.avatar !== null && data.avatar.length ? (
                    <img
                      src={data.avatar}
                      alt="Avatar"
                      className="avatar rounded-circle Img-P"
                    />
                  ) : (
                    <span className="uName">{generateInitials(data)}</span>
                  )}
                </Media>
                <Media body className="user-Media-P">
                  <Media heading className="text-large">
                    {generateFullName(data)}
                  </Media>
                </Media>
              </Media>
              <div className="text-small form-group">User Information</div>
              <div className="row">
                {employeeSection.map((each) => (
                  <DisplayProperty
                    data={data}
                    propertyValues={each}
                    key={each.value}
                  />
                ))}
              </div>
              <div className="row">
                {getFirmType() !== 'liscio-lite' && (
                  <div className="col-sm-12 col-md-6 col-lg-4 form-group">
                    <div className="text-left">
                      <CheckboxField
                        id="is_signing_authority"
                        name="is_signing_authority"
                        label="Signing Authority"
                        value={data.is_signing_authority}
                        disabled
                      />
                    </div>
                  </div>
                )}
                <div className="col-sm-12 col-md-6 col-lg-4 form-group">
                  <div className="text-left">
                    <CheckboxField
                      id="is_default_message_recipient"
                      name="is_default_message_recipient"
                      label="Default Message Recipient"
                      value={data.is_default_message_recipient}
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {this.state.showDeleteConfirmation ? (
          <ConfirmationModal
            isOpen
            messageText="Attention: The employee will deactivated. Proceed?"
            yesBtnText="Proceed"
            noBtnText="Cancel"
            yesBtnClick={this.deactivateEmployee}
            noBtnClick={this.handleCloseModal}
          />
        ) : null}
      </div>
    );
  }
}

export default withStorageData(UserInfo);
