import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import InputField from 'common/InputField';
import { PasswordInputField } from 'common/PasswordInputField';
import { useChangePassword } from 'hooks/login-v5-hooks';
import { useController, useForm } from 'react-hook-form';
import { Button } from 'reactstrap';
import { PASSWORD } from 'utilities/regularexpressions';
import { AlertMessage } from 'utilities/utils';
import { destroyCurrentSessionAndGoToLogin } from 'auth/session';

export type UserProfileChangePasswordV5Props = {
  onCancel: () => void;
  mfaEnabled?: boolean;
};

export type UserProfileChangePasswordFormData = {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
  mfaCode?: string;
  mfaEnabled?: boolean;
};

export const changePasswordValidation = yupResolver(
  yup.object().shape({
    oldPassword: yup.string().required('Required'),
    newPassword: yup
      .string()
      .required('Required')
      .matches(
        PASSWORD,
        'Password must contain at least 12 characters, including 1 upper case letter, 1 lower case letter, 1 numeric and 1 special character.',
      ),
    confirmPassword: yup
      .string()
      .required('Required')
      .oneOf([yup.ref('newPassword')], 'Passwords must match'),
    mfaCode: yup.string().when('mfaEnabled', {
      is: true,
      then: (schema) => schema.required('Required'),
    }),
  }),
);
/**
 * TODO: We'll likely need to hoist this to a global util
 * once the v5 error shapes have been changed
 */
export const parseChangePasswordError = (error: any) => {
  if (error.response) {
    const { data } = error.response;
    if (data.error && typeof data.error === 'string') {
      return data.error;
    }

    if (
      data.errors &&
      data.errors.length > 0 &&
      typeof data.errors[0].message === 'string'
    ) {
      return data.errors[0].message;
    }
  }
  return undefined;
};

/**
 * Refactored v5 auth change password w/ MFA code
 */
export const UserProfileChangePasswordV5 = ({
  onCancel,
  mfaEnabled,
}: UserProfileChangePasswordV5Props) => {
  const { mutateAsync: changePassword } = useChangePassword();
  const { handleSubmit, control, formState } =
    useForm<UserProfileChangePasswordFormData>({
      resolver: changePasswordValidation,
      defaultValues: {
        mfaEnabled,
      },
    });
  const oldPassword = useController({ name: 'oldPassword', control });
  const newPassword = useController({ name: 'newPassword', control });
  const confirmPassword = useController({ name: 'confirmPassword', control });
  const mfaCode = useController({ name: 'mfaCode', control });

  const onSubmit = handleSubmit(async (data) => {
    try {
      await changePassword({
        old_password: data.oldPassword,
        new_password: data.newPassword,
        new_password_confirmation: data.confirmPassword,
        two_factor_code: data.mfaCode,
      });
      AlertMessage(
        'success',
        'Password Changed Successfully. Please Login in.',
        5000,
      );
      // Destroy auth session
      setTimeout(destroyCurrentSessionAndGoToLogin, 2000);
    } catch (error) {
      const message =
        parseChangePasswordError(error) ||
        'There was an error updating your password';
      AlertMessage('error', message, 3000);
    }
  });

  return (
    <>
      <div
        id="loading"
        style={{ display: formState.isSubmitting ? 'block' : 'none' }}
      />
      <form onSubmit={onSubmit}>
        <div className="row">
          <PasswordInputField
            name="oldPassword"
            label="Old Password"
            autocomplete="new-password"
            className="col-sm-12 col-lg-5 col-md-5 form-group"
            onChange={oldPassword.field.onChange}
            value={oldPassword.field.value}
            error={oldPassword.fieldState.error?.message}
            onBlur={oldPassword.field.onBlur}
          />
        </div>
        <div className="row">
          <PasswordInputField
            name="newPassword"
            label="New Password"
            autocomplete="new-password"
            className="col-sm-12 col-lg-5 col-md-5 form-group"
            onChange={newPassword.field.onChange}
            value={newPassword.field.value}
            error={newPassword.fieldState.error?.message}
            onBlur={newPassword.field.onBlur}
          />
          <PasswordInputField
            name="confirmPassword"
            label="Confirm Password"
            className="col-sm-12 col-lg-5 col-md-5 form-group"
            onChange={confirmPassword.field.onChange}
            value={confirmPassword.field.value}
            error={confirmPassword.fieldState.error?.message}
            onBlur={confirmPassword.field.onBlur}
          />
        </div>
        {mfaEnabled && (
          <div className="row">
            <InputField
              name="mfaCode"
              label="Multi-Factor Code"
              className="col-sm-12 col-lg-5 col-md-5 form-group"
              onChange={mfaCode.field.onChange}
              value={mfaCode.field.value}
              error={mfaCode.fieldState.error?.message}
              onBlur={mfaCode.field.onBlur}
            />
          </div>
        )}
        <div className="formbtn justify-content-start mt-3">
          <div className="btn-wrap btn--leftspace">
            <Button className="btn  btn-primary" type="submit">
              Update
            </Button>
            <Button
              className="btn btn-outline-light btn--leftspace-edit"
              color="primary"
              onClick={onCancel}
            >
              Cancel
            </Button>
          </div>
        </div>
      </form>
    </>
  );
};
