/* eslint-disable*/
import React from 'react';
import { Modal, ModalBody, Button } from 'reactstrap';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { Divider, Stack } from '@mui/material';
import { MfaIcon } from './MfaIcon/MfaIcon';

const MultiFactorAuthenticationModal = (props) => {
  let doneVerification = false;
  let recoveryModal = false;
  let verifyModal = false;
  if (props.className === 'DoneVerification') {
    doneVerification = true;
  } else if (props.className === 'RecoveryModal') {
    recoveryModal = true;
  } else if (props.className === 'VerifyModal') {
    verifyModal = true;
  }

  const RecoveryCodes = props.data?.recoveryCodes?.length
    ? props.data.recoveryCodes.map((item, index) => (
        <div key={index}>{item.value}</div>
      ))
    : [];

  return (
    <Modal
      isOpen={props.isOpen}
      className="customModal customModal--alert customModal--w700 customModal--nopadding"
    >
      <div className="ModalHeader">
        <button
          type="button"
          className="close"
          aria-label="Close"
          onClick={props.noBtnClick}
        >
          <i className="icon-close2" aria-hidden="true" />
        </button>
      </div>
      <ModalBody className="modalContent">
        <div className="modalContent--inner">
          <div className="modalContent--header text-large">
            <h5 className="modalContent--header text-medium mb-2">
              {props.headerText}
            </h5>
          </div>
          {doneVerification && !recoveryModal && !verifyModal ? (
            <div className="d-block">
              <div className="formGroup mb-3">
                <label className="text-body text-small mb-0">Status</label>
                <div className="lblValue">
                  {props.data.mfaEnabled ? (
                    <div>
                      <span className="fontSize">Enabled</span>
                      <a
                        onClick={props.mfaHandleDisable}
                        className="fontSize font-weight-bold ml-2"
                      >
                        Disable
                      </a>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="formGroup mb-3">
                <label className="text-body text-small mb-0">Access</label>
                <div className="lblValue">
                  <a
                    className="fontSize font-weight-bold link px-0"
                    onClick={props.generateRecoveryCodes}
                  >
                    Generate Recovery Codes… <i className="iconCode">?</i>
                  </a>
                </div>
              </div>
            </div>
          ) : recoveryModal && !doneVerification && !verifyModal ? (
            <div className="row">
              <div className="col-12 pb-3">
                <label className="text-body font-weight-normal mb-0 mt-3">
                  Make sure to{' '}
                  <a onClick={props.copyCodes} className="text-primary">
                    save your codes
                  </a>{' '}
                  in a safe place.
                </label>
              </div>
              <div className="col-12">
                <div className="row" id="templateData">
                  <div className="labelValue col-12">
                    <div className="form-control">
                      <Scrollbars
                        style={{ width: '100%' }}
                        autoHide
                        autoHideTimeout={1000}
                        autoHeight
                        autoHeightMin={0}
                        autoHeightMax={300}
                      >
                        <div id="copyCodes">{RecoveryCodes}</div>
                      </Scrollbars>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : verifyModal && !recoveryModal && !doneVerification ? (
            <div>
              <p>
                Open your preferred authenticator app and use your phone camera
                to scan the barcode below.
              </p>
              <div className="d-block text-center">
                <img src={props.data.qrCode} alt="barcode" />
              </div>
              <Stack mt={4} mb={6}>
                <Divider>Or enter the code manually</Divider>
              </Stack>
              <div className="form-group mb-4">
                <input
                  type="text"
                  className="form-control"
                  value={props.data.barCodeSecretKey}
                  readOnly
                />
              </div>
              <p className="mb-1">
                The authenthicator app will generate a verification code. Enter
                the verification code below.
              </p>
              <div className="form-group">
                <label htmlFor="codeverify">Verification Code</label>
                <div
                  className={
                    props.data.errors.verificationCode
                      ? 'lblValue form-error'
                      : 'lblValue'
                  }
                >
                  <input
                    className={
                      props.data.errors.verificationCode
                        ? 'form-control error'
                        : 'form-control'
                    }
                    type="text"
                    value={props.data.verificationCode}
                    onChange={props.handleVerificationCode}
                  />
                  {props.data.errors.verificationCode ? (
                    <span className="error_label">
                      {props.data.errors.verificationCode}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
          ) : (
            <div>
              <p className="text-dark mb-1">
                Enabling multi-factor authentication is a critical step in
                improving your cybersecurity. Liscio supports Google
                Authenticator, Microsoft Authenticator, Authy, and others.
              </p>
              <Stack width="100%" alignItems="center">
                <Stack direction="row" gap={2} my={6}>
                  <MfaIcon type="google" />
                  <MfaIcon type="microsoft" />
                  <MfaIcon type="authy" />
                </Stack>
              </Stack>
            </div>
          )}
          <div className="row">
            <div className="col-12 justify-content-end formbtn justify-content-end">
              <div className="btn-wrap btn--leftspace">
                {props.noBtnText ? (
                  <Button
                    className="btn btn-outline-light"
                    onClick={props.noBtnClick}
                  >
                    {props.noBtnText}
                  </Button>
                ) : null}
                {props.yesBtnText ? (
                  <Button
                    className="btn btn-primary"
                    onClick={props.yesBtnClick}
                  >
                    {props.yesBtnText}
                  </Button>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default MultiFactorAuthenticationModal;
