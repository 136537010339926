import {
  Inventory,
  MoreVert,
  Undo,
  RestorePageOutlined,
  FolderZip,
} from '@mui/icons-material';
import {
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { workflowRequestsCountQueryDocument } from 'api/workflow-requests';
import { workflowsQueryDocument } from 'api/workflows';
import { TOAST_FAILURE, TOAST_SUCCESS } from 'constants/constants';
import {
  WorkflowRequest,
  WorkflowRequestFragment,
  WorkflowRequestStatus,
} from 'gql/graphql';
import {
  useGetZipPackage,
  useUpdateWorklowRequest,
} from 'hooks/workflow-request-hooks';
import { useState } from 'react';
import { AlertMessage } from 'utilities/utils';
import { useChangeRequestDueDate } from 'components/Requests/hooks/change-request-due-date/change-request-due-date';
import { DialogContentRenderer } from './DialogContentRenderer';
import { ReOpenDialog } from './ReOpenDialog';

export type MenuItemType =
  | 'archive'
  | 'recall'
  | 'reopen'
  | 'zip'
  | 'changeDueDate';

type RequestOptionsMenuProps = {
  workflowRequest: WorkflowRequestFragment;
  menuItems: MenuItemType[];
  assignedContacts?: WorkflowRequest['assignedContacts'];
};

export const RequestOptionsMenu = ({
  workflowRequest,
  menuItems,
  assignedContacts,
}: RequestOptionsMenuProps) => {
  const [workflowOptionsOpen, setWorkflowOptionsOpen] = useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [reOpenDialogOpen, setReOpenDialogOpen] = useState(false);
  const [currentActionType, setCurrentActionType] =
    useState<WorkflowRequestStatus | null>(null);
  const queryClient = useQueryClient();
  const { RequestOptionsMenuItem, ChangeDueDateDialog } =
    useChangeRequestDueDate(workflowRequest);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const { mutate: updateWorkflowRequestMutation } = useUpdateWorklowRequest();

  const { mutate: zipPackageMutation } = useGetZipPackage(workflowRequest.id);

  const handleZipClicked = () => {
    zipPackageMutation(undefined, {
      onSuccess: () => {
        AlertMessage(
          TOAST_SUCCESS,
          `ZIP export successfully initiated. Depending on the size of this request and any uploaded files, it may take some time to process. Once complete it will become available for download from the Liscio Vault.`,
        );
        setWorkflowOptionsOpen(false);
      },
      onError: () => {
        setWorkflowOptionsOpen(false);
        AlertMessage(
          TOAST_FAILURE,
          `Liscio encountered an error when attempting to create a ZIP file for the following request: ${
            workflowRequest.title || ''
          }. Please try again or contact us if this error persists. We apologize for any inconvenience.`,
        );
      },
    });
  };

  const successToastMessage = (status: WorkflowRequestStatus) => {
    switch (status) {
      case WorkflowRequestStatus.Closed:
        return 'Request archived successfully';
      case WorkflowRequestStatus.Recalled:
        return 'Request recalled successfully';
      case WorkflowRequestStatus.Requested:
        return 'Request re-opened successfully';
      default:
        return '';
    }
  };

  const errorToastMessage = (status: WorkflowRequestStatus) => {
    switch (status) {
      case WorkflowRequestStatus.Closed:
        return 'Failed to archive request';
      case WorkflowRequestStatus.Recalled:
        return 'Failed to recall request';
      case WorkflowRequestStatus.Requested:
        return 'Failed to re-open request';
      default:
        return '';
    }
  };

  const displayActionButtonText = () => {
    switch (currentActionType) {
      case WorkflowRequestStatus.Closed:
        return 'Yes, Archive Request';
      case WorkflowRequestStatus.Recalled:
        return 'Yes, Recall Request';
      case WorkflowRequestStatus.Requested:
        return 'Yes, Re-Open Request';
      default:
        return null;
    }
  };

  const handleStatusChangeClick = (
    event: React.MouseEvent<Element>,
    status: WorkflowRequestStatus,
  ) => {
    event.stopPropagation();
    setCurrentActionType(status);
    if (status === WorkflowRequestStatus.Requested) {
      setReOpenDialogOpen(true);
    } else {
      setConfirmationDialogOpen(true);
    }
  };

  const handleConfirmStatusChangeClick = (
    event?: React.MouseEvent<Element>,
  ) => {
    if (event) {
      event.stopPropagation();
    }
    updateWorkflowRequestMutation(
      {
        status: currentActionType,
        workflowRequestId: workflowRequest.id,
      },
      {
        onError: () => {
          AlertMessage(
            TOAST_FAILURE,
            errorToastMessage(currentActionType as WorkflowRequestStatus),
          );
        },
        onSuccess: () => {
          AlertMessage(
            TOAST_SUCCESS,
            successToastMessage(currentActionType as WorkflowRequestStatus),
          );
          queryClient.invalidateQueries(
            (workflowsQueryDocument.definitions[0] as any).name.value,
          );
          queryClient.invalidateQueries(
            (workflowRequestsCountQueryDocument.definitions[0] as any).name
              .value,
          );
          setWorkflowOptionsOpen(false);
          setConfirmationDialogOpen(false);
        },
      },
    );
    setWorkflowOptionsOpen(false);
  };

  const handleClick = (event: React.MouseEvent<Element>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setWorkflowOptionsOpen(true);
  };

  const handleClose = (event: React.MouseEvent<Element>) => {
    event?.stopPropagation?.();
    setAnchorEl(null);
    setWorkflowOptionsOpen(false);
    setConfirmationDialogOpen(false);
    setReOpenDialogOpen(false);
    setCurrentActionType(null);
  };

  const handleMessageSentError = () => {
    AlertMessage(
      TOAST_FAILURE,
      'Failed to send message. Please try again or contact us if this error persists. We apologize for any inconvenience.',
    );
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVert />
      </IconButton>
      {workflowOptionsOpen && (
        <Menu
          open={workflowOptionsOpen}
          anchorEl={anchorEl}
          onClose={handleClose}
        >
          {menuItems.includes('changeDueDate') && (
            <RequestOptionsMenuItem
              handleClose={() => setWorkflowOptionsOpen(false)}
            />
          )}

          {menuItems.includes('archive') && (
            <MenuItem
              onClick={(e) =>
                handleStatusChangeClick(e, WorkflowRequestStatus.Closed)
              }
            >
              <ListItemIcon>
                <Inventory />
              </ListItemIcon>
              <ListItemText>Archive Request</ListItemText>
            </MenuItem>
          )}
          {menuItems.includes('recall') && (
            <MenuItem
              onClick={(e) =>
                handleStatusChangeClick(e, WorkflowRequestStatus.Recalled)
              }
            >
              <ListItemIcon>
                <Undo />
              </ListItemIcon>
              <ListItemText>Recall Request</ListItemText>
            </MenuItem>
          )}

          {menuItems.includes('reopen') && (
            <MenuItem
              onClick={(e) =>
                handleStatusChangeClick(e, WorkflowRequestStatus.Requested)
              }
            >
              <ListItemIcon>
                <RestorePageOutlined />
              </ListItemIcon>
              <ListItemText>Re-Open Request</ListItemText>
            </MenuItem>
          )}
          {menuItems.includes('zip') && (
            <MenuItem onClick={handleZipClicked}>
              <ListItemIcon>
                <FolderZip />
              </ListItemIcon>
              <ListItemText>Export Request Package ZIP</ListItemText>
            </MenuItem>
          )}
        </Menu>
      )}
      <Dialog open={confirmationDialogOpen} onClose={handleClose} maxWidth="xs">
        <DialogContent>
          <DialogContentRenderer currentActionType={currentActionType} />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="secondary" onClick={handleClose}>
            cancel
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={handleConfirmStatusChangeClick}
          >
            {displayActionButtonText()}
          </Button>
        </DialogActions>
      </Dialog>
      <ReOpenDialog
        open={reOpenDialogOpen}
        onClose={handleClose}
        requestId={workflowRequest.id}
        requestTitle={(workflowRequest.title as string) || ''}
        assignedContacts={assignedContacts}
        onMessageSentSuccess={handleConfirmStatusChangeClick}
        onMessageSentError={handleMessageSentError}
      />
      <ChangeDueDateDialog />
    </>
  );
};
