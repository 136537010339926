/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/jsx-no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-plusplus */
import React, { Component } from 'react';
import axios from 'axios';
import { Modal, ModalBody, Button } from 'reactstrap';
import AccountDateField from 'common/AccountDateField';
import * as Utilities from 'utilities/utils';
import { USER_VIEWS } from 'constants/constants';
import AccountsDropDown from 'common/AccountsDropdown';
import RecipientsDropDown from 'common/RecipientsDropDown';
import { format } from 'date-fns';

class MessageFilterCustomView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedAccounts: [],
      selectedReceipient: [],
    };
  }

  componentDidMount() {
    const { data } = this.props;
    this.setState({
      selectedAccounts: data.selectedFilterList.ACCOUNTS_DATA
        ? data.selectedFilterList.ACCOUNTS_DATA
        : [],
      selectedReceipient: data.selectedFilterList.RECIPIENT_DATA
        ? data.selectedFilterList.RECIPIENT_DATA
        : [],
    });
  }

  updateAccountsArray = (input) => {
    this.setState({ selectedAccounts: input }, () => {
      const accountIds = [];
      this.state.selectedAccounts.map((each) => {
        accountIds.push(each.value ? each.value : each.id);
        return accountIds;
      });
      this.props.handleDateField('', accountIds.join(), 'ACCOUNTS');
    });
  };

  updateReceipientsArray = (input) => {
    this.setState({ selectedReceipient: input }, () => {
      const accountIds = [];
      this.state.selectedReceipient.map((each) => {
        accountIds.push(each.value ? each.value : each.id);
        return accountIds;
      });
      this.props.handleDateField('', accountIds.join(), 'RECIPIENT');
    });
  };

  cancelcustomView = (viewId = '') => {
    this.setState(
      {
        selectedAccounts: [],
        selectedReceipient: [],
      },
      () => {
        const id = viewId || '';
        this.props.noBtnClick(id);
      },
    );
  };

  saveCustomView = () => {
    const component = this;
    const {
      data: { selectedFilterList, attachmentCheckBox, filteredUserViewId },
    } = this.props;
    const validate = this.props.handleCustomViewValidation();
    if (validate) {
      console.log('validate', selectedFilterList);
      let date = '';
      if (
        selectedFilterList['DATE RANGE'] &&
        selectedFilterList['DATE RANGE'][0].from
      ) {
        date = format(selectedFilterList['DATE RANGE'][0].from, 'MM/dd/yyyy');
      }
      if (
        selectedFilterList['DATE RANGE'] &&
        selectedFilterList['DATE RANGE'][0].from &&
        selectedFilterList['DATE RANGE'][0].to
      ) {
        date += `, ${format(
          selectedFilterList['DATE RANGE'][0].to,
          'MM/dd/yyyy',
        )}`;
      }
      const mydata = {
        user_view: {
          user_view_name: selectedFilterList['USER VIEW NAME']
            ? selectedFilterList['USER VIEW NAME']
            : '',
          description: 'test',
          is_shared:
            selectedFilterList.restictVisibilty === 'VISIBILITY TO ALL USERS' ||
            false,
          is_default_view: selectedFilterList['DEFAULT VIEW'] || false,
        },
        account_ids: selectedFilterList.ACCOUNTS
          ? selectedFilterList.ACCOUNTS
          : '',
        recipient_ids: selectedFilterList.RECIPIENT
          ? selectedFilterList.RECIPIENT
          : '',
        has_attachment: attachmentCheckBox,
        date_range: date,
      };
      const request =
        selectedFilterList.viewFor === 'Create'
          ? axios.post(USER_VIEWS, mydata)
          : axios.put(
              `${USER_VIEWS}/${encodeURIComponent(filteredUserViewId)}`,
              mydata,
            );
      request
        .then((response) => {
          if (response.data.status === 200) {
            const viewId = response.data.view_id ? response.data.view_id : '';
            component.cancelcustomView(viewId);
            Utilities.AlertMessage('success', response.data.message, 3000);
            // component.setState({ customeView: false }, () => {
            //   component.props.handleDateField('', '', 'CLEAR');
            //   Utilities.AlertMessage('success', response.data.message, 3000);
            // });
          } else if (response.data.message) {
            Utilities.AlertMessage('error', response.data.message, 3000);
          }
        })
        .catch(() => {
          component.cancelcustomView();
          Utilities.AlertMessage(
            'error',
            'Some error occurred. Please try later.',
            3000,
          );
          // console.log(error);
        });
    }
  };

  render() {
    const { selectedAccounts, selectedReceipient } = this.state;
    const { data, isUserEmployee } = this.props;
    return (
      <Modal
        isOpen={this.props.isOpen}
        className="customModal customModal--center customModal--alert customModal--w500 customModal--nopadding"
      >
        <div className="ModalHeader">
          {data.errors.otherFilter
            ? Utilities.AlertMessage('error', data.errors.otherFilter, 3000)
            : null}
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={() => this.cancelcustomView('')}
          >
            <i className="icon-close2" aria-hidden="true" />
          </button>
        </div>
        <ModalBody className="modalContent overflow-y-scroll">
          <div className="modalContent--inner p-4">
            <div className="modalContent--header text-large">
              <div className="row">
                <div className="col-md-10">
                  <h5 className="mb-1">
                    {data.selectedFilterList.viewFor} View
                  </h5>
                  <p className="mb-1">Please enter view name and save.</p>
                </div>
                {data.selectedFilterList.viewFor === 'Edit' ? (
                  <div className="col-md-2">
                    <a
                      href="javascript:void(0)"
                      onClick={() => this.props.handleEdit('delete')}
                      className="btn btn-outline-light py-2 px-3 text-center"
                    >
                      <i className="icon-delete-small px-0 text-small" />
                    </a>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="modalForm">
              <form>
                <div className="row">
                  <div className="col-12">
                    <div className="row">
                      <div
                        className={`form-group col-12${
                          data.errors.user_view_name ? ' form-error' : ''
                        }`}
                      >
                        <label htmlFor="name">Name *</label>
                        <input
                          autoComplete="off"
                          value={
                            data.selectedFilterList['USER VIEW NAME']
                              ? data.selectedFilterList['USER VIEW NAME']
                              : ''
                          }
                          name="name"
                          onChange={(e) =>
                            this.props.handleDateField(
                              e,
                              e.target.value,
                              'USER VIEW NAME',
                            )
                          }
                          className="form-control"
                          id="name"
                          type="text"
                        />
                        <span className="error_label">
                          {data.errors.user_view_name}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-12">
                    <label htmlFor="tagslabel">Account</label>
                    <AccountsDropDown
                      selectedRecords={selectedAccounts}
                      dropdownFor="Account"
                      handleChange={this.updateAccountsArray}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-12">
                    <label htmlFor="tagslabel">Recipient</label>
                    <RecipientsDropDown
                      selectedRecords={selectedReceipient}
                      dropdownFor="Recipient"
                      receipientList={this.props.receipientList}
                      handleChange={this.updateReceipientsArray}
                      recipientInputChange={this.props.recipientInputChange}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 pt-2 pb-4">
                    <div className="row align-items-center mb-3 date-picker-arrow-remove">
                      <div className="col-auto px-1">
                        <label
                          className="subHeadline text-dark mb-0"
                          style={{ position: 'relative', left: '10px' }}
                        >
                          DATE RANGE
                        </label>
                      </div>
                      <div className="col-5 pr-2">
                        <AccountDateField
                          className="InputGroupFocus form-group mb-0 form-inline-label inpopup"
                          name="date-input1"
                          label="From"
                          labelClass="date-text date-calender"
                          style={{ minWidth: '35px' }}
                          onChange={(e) =>
                            this.props.handleDateField(e, 'from', 'DATE RANGE')
                          }
                          maxDate={data.to ? data.to : new Date()}
                          value={data.from ? data.from : null}
                          clearDate={(e) =>
                            this.props.handleDateField(
                              e,
                              'from',
                              'CLEAR DATE RANGE',
                            )
                          }
                        />
                      </div>
                      <div className="col-5 px-2">
                        <AccountDateField
                          className="InputGroupFocus form-group mb-0 form-inline-label inpopup"
                          name="date-input2"
                          label="To"
                          labelClass="date-text date-calender"
                          style={{ minWidth: '15px' }}
                          onChange={(e) =>
                            this.props.handleDateField(e, 'to', 'DATE RANGE')
                          }
                          maxDate={new Date()}
                          minDate={data.from ? data.from : new Date()}
                          value={data.to ? data.to : null}
                          clearDate={(e) =>
                            this.props.handleDateField(
                              e,
                              'to',
                              'CLEAR DATE RANGE',
                            )
                          }
                          popperPlacement="bottom-end"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="checkbox checkbox-primary check-container">
                      <label htmlFor="selectallcheckbox">
                        <input
                          name="selectallcheckbox"
                          id="selectallcheckbox"
                          type="checkbox"
                          className="form-check-input"
                          checked={data.attachmentCheckBox}
                          onChange={(e) => this.props.attachmentCheckBox(e)}
                        />
                        <i className="checkmark" />
                        <span className="text-body pl-2 fontSize">
                          Only Include Messages with Attachments
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
                {isUserEmployee ? (
                  <div className="row pt-2">
                    <div className="col-12">
                      <div className="row">
                        <div className="col-12">
                          <h5 className="my-3">Restrict Visibility</h5>
                        </div>
                        <div className="form-group col-12">
                          <div className="row pl-3">
                            <div className="radio-container">
                              <label htmlFor="visibleToMe">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="visibleToMe"
                                  id="visibleToMe"
                                  value="RESTRICT VISIBILITY TO ME"
                                  checked={
                                    data.selectedFilterList.restictVisibilty ===
                                    'RESTRICT VISIBILITY TO ME'
                                  }
                                  onClick={(e) =>
                                    this.props.handleDateField(
                                      e,
                                      'RESTRICT VISIBILITY TO ME',
                                      'restictVisibilty',
                                    )
                                  }
                                />
                                <i className="checkmark" />
                                <span
                                  className="form-check-label text-capitalize text-body pl-2"
                                  style={{ fontSize: '0.9rem' }}
                                >
                                  Visible only to me
                                </span>
                              </label>
                            </div>
                            <div className="radio-container pl-4">
                              <label htmlFor="visibleToAll">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="visibleToAll"
                                  id="visibleToAll"
                                  value="VISIBILITY TO ALL USERS"
                                  checked={
                                    data.selectedFilterList.restictVisibilty ===
                                    'VISIBILITY TO ALL USERS'
                                  }
                                  onClick={(e) =>
                                    this.props.handleDateField(
                                      e,
                                      'VISIBILITY TO ALL USERS',
                                      'restictVisibilty',
                                    )
                                  }
                                />
                                <i className="checkmark" />
                                <span
                                  className="form-check-label text-capitalize text-body pl-2"
                                  style={{ fontSize: '0.9rem' }}
                                >
                                  Visible to all users
                                </span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                <div className="row">
                  <div className="col-12 justify-content-end formbtn">
                    <div className="btn-wrap btn--leftspace">
                      <Button
                        className="btn btn-outline-light"
                        onClick={() => this.cancelcustomView('')}
                      >
                        Cancel
                      </Button>
                      <Button
                        className="btn btn-primary"
                        onClick={this.saveCustomView}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

export default MessageFilterCustomView;
