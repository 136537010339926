/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import FileSaver from 'file-saver';
import _, { filter } from 'lodash';
import mime from 'mime';
import Axios from 'axios';
import moment from 'moment';
import Pagination from 'common/Pagination';
import * as Utilities from 'utilities/utils';
import ConfirmationModal from 'common/ConfirmationModal';
import { FILENAME } from 'utilities/regularexpressions';
import { GET_DOWNLOAD_URL } from 'constants/constants';
import * as UploadFilesActions from 'actions/UploadFilesActions';
import Files from 'api/Files';
import * as TaskApi from 'api/TaskApi';
import UploadFiles from 'api/UploadFiles';
import List from './List_updated';
import UploadFilesFilterTabs from '../UploadedFiles/UploadFilesFilterTabs';
import MultiTagsYearMonthModal from '../UploadedFiles/MultiTagsYearMonthModal';
import FilePopup from './FilePopup';
import ReplaceTags from './ReplaceTags';
import ExportModal from './ExportModal';

const noneStyle = { display: 'none' };
const blockStyle = { display: 'block' };
const removeItem = (array, element) =>
  array.filter((e) => !(e.id === element.id) && !element.checked);
const selectedIds = (array, key) => {
  const selectedlist = [];
  array.map((item) => selectedlist.push(item[key]));
  return selectedlist;
};

class LiteFilesList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      storageData: {},
      loading: false,
      uploadFilesList: [],
      page: 1,
      pagination: 0,
      paginationOffset: '',
      paginationOff: '',
      totalDocuments: '',
      listOfFiles: null,
      liteUpload: false,
      field: 'created_at',
      sort: 'desc',
      selectall: false,
      errors: {},
      selectedrecords: [],
      // deleteOtherFiles: false,
      deleteFiles: false,
      multiTagModal: false,
      // mutiltagdropdownOpen: false,
      tags: [],
      uploadTags: [],
      years: [],
      months: [],
      // tagId: '',
      // tagName: '',
      // inputMultiTagVal: '',
      // newMultiTagList: [],
      // newTagList: [],
      // selectedMultiTagRecords: [],
      fileExt: '',
      editFileNameId: '',
      currOpenedId: '',
      currOpenedInput: '',
      oldFileName: '',
      api: '',
      accounts: [],
      contacts: [],
      allAccounts: [],
      allContacts: [],
      uploadLiteFilesModal: false,
      filterStatus: 'active',
      filterStatusModal: false,
      exportModal: false,
      fileRemovedFromList: false,
      filterState: {},
      shareFile: false,
      useNewApi:
        window.location.href.includes('beta') ||
        window.location.href.includes('filesvault') ||
        window.location.href.includes('localhost'),
      showBackBtn: false,
      filterBGClicked: 0,
    };
  }

  onClickFilterBG = () => {
    this.setState((prevState) => ({
      filterBGClicked: prevState.filterBGClicked + 1,
    }));
  };

  componentDidMount() {
    // TODO: __HOMEDATA-AUDIT
    const storageData = Utilities.getStorageData();
    const homeDataObj = storageData.pref_type_options;
    const upload_tags = homeDataObj.upload_tags ? homeDataObj.upload_tags : [];
    const filter_tags = homeDataObj.filter_tags ? homeDataObj.filter_tags : [];
    const upload_file_year = homeDataObj.upload_file_year
      ? homeDataObj.upload_file_year
      : [];
    const upload_file_month = homeDataObj.upload_file_month
      ? homeDataObj.upload_file_month
      : [];
    let api = '';
    const { filterState } = this.state;
    let showBackBtn = false;
    if (this.props.location && this.props.location.search) {
      const searchParams = new URLSearchParams(this.props.location.search);
      searchParams.forEach((value, key) => {
        if (key === 'tags') {
          api = '&tags=' + encodeURIComponent(value);
          const tagscheckbox = [{}];
          const tagscheckboxArray = [];
          filter_tags.forEach((each_tag, tag_key) => {
            if (value.split(',').includes(each_tag.key)) {
              tagscheckbox[0][each_tag.value] = true;
              // tagscheckbox = Object.assign({}, tagscheckbox, { [each_tag.key]: true });
              tagscheckboxArray.push(each_tag);
            }
          });
          filterState.tagscheckbox = tagscheckbox;
          filterState.tagscheckboxArray = tagscheckboxArray;
          filterState.selectedStatusFilter = 'active';
          filterState.selectedStatusFilterId = 'active';
          filterState.filterlistdiv = true;
          showBackBtn = true;
        }
      });
    }
    this.setState({
      storageData,
      tags: filter_tags,
      uploadTags: upload_tags,
      years: upload_file_year,
      months: upload_file_month,
      filterState,
      api,
      showBackBtn,
      // newTagList: upload_tags,
      // newMultiTagList: upload_tags,
    });
    const { match } = this.props;
    if (
      this.props.filesData &&
      !_.isEmpty(this.props.filesData) &&
      api === ''
    ) {
      this.setState(this.props.filesData);
    } else {
      Files.getFilesList(this, api);
      const mydata = {
        task_type_key: 'request_info',
        limit: true,
      };
      TaskApi.getAccounts(this, 'fileslist');
      TaskApi.getContactsOfTask(this, mydata, 'fileslist');
    }
    document.addEventListener('click', (e) => {
      if (
        e.target.nodeName === 'INPUT' ||
        e.target.classList[0] === 'icon-edit'
      ) {
        // console.log('Yes is the current input.');
      } else if (
        this.state.currOpenedInput.length > 0 &&
        this.state.currOpenedId
      ) {
        let j = {};
        if (this.state.errors.docName === 'Enter Vaild document name') {
          return;
        }
        let idx = null;
        this.state.uploadFilesList.map((file, index) => {
          if (file.id === this.state.currOpenedId) {
            const f = file;
            f.doc_name = this.state.currOpenedInput;
            j = f;
            idx = index;
          }
          return null;
        });
        const uploadedFiles = this.state.uploadFilesList;
        if (Object.keys(j).length === 0) {
          return;
        }
        uploadedFiles.splice(idx, 1, j);
        this.setState({
          uploadFilesList: uploadedFiles,
          editFileNameId: '',
          errors: {},
          currOpenedInput: '',
          currOpenedId: '',
        });
      }
    });
  }

  // onMultiTagFocus = () => {
  //   this.setState(prevState => ({
  //     mutiltagdropdownOpen: true,
  //     tags: prevState.tags,
  //     tagId: '',
  //     tagName: '',
  //   }));
  // }
  areAllSelected = () => {
    let x = 0;
    const { uploadFilesList } = this.state;
    if (uploadFilesList) {
      uploadFilesList.map((e) => {
        if (e.checked) x += 1;
        return e;
      });
      const checkbox = document.getElementById('selectallcheckbox');
      if (x === uploadFilesList.length && (x && uploadFilesList.length) !== 0) {
        this.setState({ selectall: true });
        if (checkbox) checkbox.indeterminate = false;
      } else {
        this.setState({ selectall: false });
        if (checkbox) checkbox.indeterminate = true;
      }
      if (x === 0) {
        if (checkbox) checkbox.indeterminate = '';
      }
    }
  };

  handleCheckbox = (item, index) => {
    let { selectedrecords } = this.state;
    const { uploadFilesList } = this.state;
    if (uploadFilesList[index].checked) {
      uploadFilesList[index].checked = false;
    } else {
      uploadFilesList[index].checked = true;
    }
    if (uploadFilesList[index].checked) {
      selectedrecords.push(uploadFilesList[index]);
    } else {
      selectedrecords = removeItem(selectedrecords, uploadFilesList[index]);
    }
    this.setState(
      {
        uploadFilesList,
        selectedrecords,
      },
      () => {
        this.areAllSelected();
      },
    );
  };

  handleSelectAll = () => {
    let { selectedrecords } = this.state;
    const { uploadFilesList, selectedAll } = this.state;
    let isAllChecked = 0;
    let checked = false;
    uploadFilesList.map((item) => {
      if (item.checked) {
        isAllChecked += 1;
      }
      return item;
    });
    if (!selectedAll && isAllChecked === 0) {
      checked = true;
    }
    uploadFilesList.map((item) => {
      const eachone = item;
      eachone.checked = checked;
      if (selectedrecords.length) {
        if (checked) {
          selectedrecords.push(eachone);
        } else {
          selectedrecords = removeItem(selectedrecords, eachone);
        }
      }
      return eachone;
    });
    if (!selectedrecords.length && checked) {
      selectedrecords = uploadFilesList;
    }
    this.setState(
      {
        uploadFilesList,
        selectedrecords,
      },
      () => {
        this.areAllSelected();
      },
    );
  };

  selectedFilesAction = (act = '') => {
    const { uploadFilesList, selectedrecords: url } = this.state;
    if (!url.length && act) {
      Utilities.AlertMessage('error', 'No Selected File.', 3000);
    } else if (act === 'delete') {
      // let sourc = false;
      const selectedlist = selectedIds(url, 'source');
      // if (selectedlist.includes('Note') || selectedlist.includes('Task') || selectedlist.includes('Message')) {
      //   sourc = true;
      // }
      // if (sourc === true) {
      //   this.setState({ deleteOtherFiles: true });
      // } else {
      //   this.setState({ deleteManualFiles: true });
      // }
      let alertText = 'Are you sure want to delete these files?';
      if (selectedlist.includes('Multiple')) {
        alertText =
          'Warning: The selected files will be deleted from all sources i.e Messages, Tasks etc.';
      }
      this.setState({ alertText, deleteFiles: true });
    } else if (act === 'addmultitag') {
      this.setState({ multiTagModal: true });
    } else if (act === 'download') {
      this.downloadFiles();
    } else if (act === 'status') {
      this.setState({ filterStatusModal: true });
    } else if (act === 'export') {
      this.setState({ exportModal: true });
    } else if (act === 'share') {
      this.setState({ shareFile: true });
    } else if (act === 'replace_tags') {
      this.setState({
        showReplaceTagModal: true,
      });
    }
  };

  closeModal = (ishared = '', uniqueRecords = '') => {
    this.setState({ shareFile: false });
    if (ishared === 'shared' && uniqueRecords.length > 0) {
      const { api, pagination } = this.state;
      this.hideSelectedFilesDeleteModal();
      const apiPath = !api ? '' : api;
      Files.getFilesList(this, apiPath, pagination + 1);
    }
  };

  selectedFilesDeleteConfirmed = (act = '') => {
    const { selectedrecords, filterStatus } = this.state;
    const key = act === 'delete' ? 'document_ids' : 'id';
    const data = {
      [key]: selectedIds(selectedrecords, 'id'),
    };
    if (act === 'delete') {
      Files.deleteSelectedFile(this, data);
    } else if (act === 'filterstatus') {
      if (filterStatus === 'active') {
        data.archive = 'true';
      }
      UploadFiles.archiveFile(this, data, 'filesTab');
    }
  };

  hideSelectedFilesDeleteModal = () => {
    this.setState(
      {
        selectedrecords: [],
      },
      () => this.areAllSelected(),
    );
  };

  downloadFiles = () => {
    const { selectedrecords } = this.state;
    const selectedDocumentIds = selectedrecords.map((e) => e.id);
    this.setState({
      loading: true,
    });
    Axios.get(GET_DOWNLOAD_URL + '?ids=' + selectedDocumentIds.join(',')).then(
      (res) => {
        if (res.data && res.data.urls) {
          const files = res.data.urls;
          const zip = new JSZip();
          let count = 0;
          const file_names = {};
          files.map((item) => {
            const urlPath = item.aws_url_origional;
            let fileName = item.doc_name;
            const temp_doc_name = fileName.split('.');
            if (file_names[item.doc_name]) {
              temp_doc_name[0] =
                temp_doc_name[0] + '(' + file_names[item.doc_name] + ')';
              file_names[item.doc_name] += 1;
            } else {
              file_names[item.doc_name] = 1;
            }
            if (temp_doc_name.length === 1 && item.content_type) {
              temp_doc_name[1] = mime.getExtension(item.content_type);
            }
            fileName = temp_doc_name.join('.');
            // return false;
            JSZipUtils.getBinaryContent(urlPath, (err, data) => {
              if (err) {
                throw err;
              }
              const convertTime = moment()
                .add(moment().utcOffset(), 'minutes')
                .format('YYYY-MM-DD HH:mm:ss');
              zip.file(fileName, data, {
                binary: true,
                createFolders: false,
                date: new Date(convertTime),
              });
              count += 1;
              if (count === files.length) {
                zip.generateAsync({ type: 'blob' }).then((content) => {
                  FileSaver.saveAs(content, 'all_docs.zip');
                  this.setState({ loading: false });
                });
              }
            });
            return true;
          });
        } else {
          Utilities.AlertMessage('error', res.data.message, 3000);
        }
      },
    );
  };

  // handleMultiTagSubmit = (e) => {
  //   e.preventDefault();
  //   const { selectedMultiTagRecords, selectedrecords } = this.state;
  //   if (selectedMultiTagRecords.length > 0) {
  //     const data = {
  //       multi_tags: selectedIds(selectedMultiTagRecords, 'tagId'),
  //       document_ids: selectedIds(selectedrecords, 'id'),
  //     };
  //     Files.updateUploadedFile(this, data);
  //   } else {
  //     Utilities.AlertMessage('error', 'Please select tags.', 3000);
  //   }
  // }
  // hideMultiTagModal = () => {
  //   this.setState({
  //     multiTagModal: false,
  //     // tagId: '',
  //     // tagName: '',
  //     // loading: false,
  //     // mutiltagdropdownOpen: false,
  //     // selectedMultiTagRecords: [],
  //     // newMultiTagList: this.state.tags,
  //   });
  // }
  // filterMultiTagSearch = (target) => {
  //   const { tags } = this.state;
  //   if (target) {
  //     const k = [];
  //     const value = target;
  //     const filter = value.toUpperCase();
  //     tags.map((tag) => {
  //       for (let i = 0; i < target.length; i += 1) {
  //         if (tag.label.toUpperCase().indexOf(filter) > -1) {
  //           if (k.indexOf(tag) === -1) {
  //             k.push(tag);
  //           }
  //         }
  //       }
  //       return k;
  //     });
  //     this.setState({ newMultiTagList: k });
  //   } else {
  //     this.setState({ newMultiTagList: tags });
  //   }
  // }
  // handleMultiTagChange(val) {
  //   const { tags, selectedMultiTagRecords } = this.state;
  //   const tagsArr = tags;
  //   const component = this;
  //   if (val) {
  //     component.setState({ tagId: val.key, tagName: val.label });
  //   } else {
  //     component.setState({ tagId: '', tagName: '' });
  //   }
  //   const selectedInput = {
  //     tagId: val.key,
  //     tagName: val.label,
  //   };
  //   const selectedRecordsArr = selectedMultiTagRecords;
  //   const index = selectedRecordsArr.findIndex(x => x.tagId === selectedInput.tagId);
  //   if (index === -1) {
  //     selectedRecordsArr.push(selectedInput);
  //     this.setState({ selectedMultiTagRecords: selectedRecordsArr });
  //   }
  //   this.setState({
  //     mutiltagdropdownOpen: false,
  //     tags: tagsArr,
  //     newMultiTagList: tagsArr,
  //     tagId: '',
  //     tagName: '',
  //   });
  // }
  // removeSelectedMultiTagRecord(tagId) {
  //   const { selectedMultiTagRecords } = this.state;
  //   const selectedRecordsArr = selectedMultiTagRecords;
  //   const index = selectedRecordsArr.findIndex(x => x.tagId === tagId);
  //   if (index > -1) {
  //     selectedRecordsArr.splice(index, 1);
  //     this.setState({ selectedMultiTagRecords: selectedRecordsArr });
  //   }
  // }
  dataAfterEditDocument = () => {
    let index = 1;
    if (
      this.state.fileRemovedFromList &&
      this.state.uploadFilesList.length === 1
    ) {
      index =
        this.state.pagination === 0
          ? this.state.pagination + 1
          : this.state.pagination;
    } else if (
      (!this.state.fileRemovedFromList &&
        this.state.uploadFilesList.length !== 1) ||
      (this.state.fileRemovedFromList &&
        this.state.uploadFilesList.length !== 1) ||
      (!this.state.fileRemovedFromList &&
        this.state.uploadFilesList.length === 1)
    ) {
      index = this.state.pagination + 1;
    } else {
      index = this.state.pagination - 1;
    }
    const { api } = this.state;
    const apiPath = !api ? '' : api;
    Files.getFilesList(this, apiPath, index);
  };

  listOfFilesState = (status) => {
    if (
      (status === 'archive' && this.state.filterStatus === 'active') ||
      (status === 'active' && this.state.filterStatus === 'archived')
    ) {
      this.setState({ fileRemovedFromList: true });
    } else if (
      (status === 'active' && this.state.filterStatus === 'active') ||
      (status === 'archive' && this.state.filterStatus === 'archived')
    ) {
      this.setState({ fileRemovedFromList: false });
    } else {
      this.setState({
        fileRemovedFromList: true,
        listOfFiles: this.state.listOfFiles - 1,
      });
    }
  };

  fileExtention = (item) => {
    let m;
    if (item.doc_name.includes('.')) {
      const n = item.doc_name.split('.').pop();
      m = '.' + n;
    } else {
      m = '';
    }
    this.setState({
      fileExt: m,
      editFileNameId: item.id,
      currOpenedInput: item.doc_name,
      currOpenedId: item.id,
      oldFileName: item.doc_name,
    });
  };

  handleChangeFileName = (e, val) => {
    let idx = null;
    let j = {};
    this.state.uploadFilesList.map((file, index) => {
      if (file.id === val.id) {
        const f = file;
        f.doc_name = e.target.value;
        idx = index;
        j = f;
      }
      return null;
    });
    const uploadedFiles = this.state.uploadFilesList;
    uploadedFiles.splice(idx, 1, j);
    this.setState({
      uploadFilesList: uploadedFiles,
      errors: {},
      currentEditIndex: idx,
    });
  };

  handleKeyPress = (e, item, fro = '') => {
    e.stopPropagation();
    if (e.key === 'Enter' || fro === 'blurevent') {
      let file_name;
      if (item.doc_name.includes('.')) {
        file_name = item.doc_name.substring(0, item.doc_name.lastIndexOf('.'));
        // file_name = item.doc_name.split('.')[0];
      } else {
        file_name = item.doc_name;
      }
      const errors = {};
      if (file_name && (!FILENAME.test(file_name) || file_name.includes('.'))) {
        errors.docName = 'Enter Vaild document name';
      } else if (!file_name || file_name.length === 0 || !file_name) {
        errors.docName = 'Please enter document name';
      }
      this.setState({
        errors,
      });
      if (!Object.keys(errors).length) {
        const docId = item.id;
        const doc = file_name + this.state.fileExt;
        const mydata = {
          display_name: doc,
        };
        UploadFiles.ChangeFileName(mydata, docId, this, fro);
      }
    }
  };

  handleSorting = (fieldName) => {
    let stateSort = this.state.sort;
    if (this.state.field === fieldName) {
      if (stateSort === 'asc') {
        stateSort = 'desc';
      } else {
        stateSort = 'asc';
      }
    } else {
      stateSort = 'desc';
    }
    let apiPath = '';
    if (this.state.api) {
      apiPath = this.state.api;
    }
    this.setState({ sort: stateSort, field: fieldName }, () => {
      Files.getFilesList(this, apiPath);
    });
  };

  handlePageChange = (index) => {
    console.log(index);
    let checkbox;
    const { pagination, api, sort, field } = this.state;
    console.log(pagination);
    if (index === pagination + 1) {
      return false;
    }
    const apiPath = !api ? '' : api;
    Files.getFilesList(this, apiPath, index);
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
    return true;
  };

  filterList = (apiPath, status, com) => {
    this.setState({ api: apiPath, filterStatus: status, filterState: com });
    console.log(this.state.filterState, apiPath);
    Files.getFilesList(this, apiPath);
  };

  uploadFile = (fro = '') => {
    console.log('called');
    if (fro) {
      this.setState(
        {
          uploadLiteFilesModal: false,
          multiTagModal: false,
          selectedrecords: [],
        },
        () => {
          const { api, pagination } = this.state;
          const apiPath = !api ? '' : api;
          Files.getFilesList(this, apiPath, pagination + 1);
        },
      );
    } else {
      this.setState({ uploadLiteFilesModal: true });
    }
  };

  closeTagModals = (refreshData) => {
    this.setState(
      {
        multiTagModal: false,
        showReplaceTagModal: false,
      },
      () => {
        if (refreshData) {
          const { api, pagination } = this.state;
          const apiPath = !api ? '' : api;
          Files.getFilesList(this, apiPath, pagination + 1);
        }
      },
    );
  };

  filteredSearch = (fieldName, dat, dropdownName) => {
    const component = this;
    let data = dat;
    if (!data && dropdownName === 'contactDropdown') {
      data = component.state.allContacts;
    } else if (!data && dropdownName === 'accountDropdown') {
      data = component.state.allAccounts;
    }
    component.setState({ [fieldName]: data });
  };

  // renderMultiTags = () => {
  //   const selectedMultiTagRecords = this.state.selectedMultiTagRecords.map((item) => {
  //     return (<span className="tag tag--fill" key={item.tagId}>{item.tagName} <i className="icon-close2 remove" onClick={() => this.removeSelectedMultiTagRecord(item.tagId)} /></span>);
  //   });
  //   return selectedMultiTagRecords;
  // }
  render() {
    const {
      loading,
      listOfFiles,
      uploadFilesList,
      totalDocuments,
      paginationOffset,
      paginationOff,
      pagination,
      selectedrecords,
      multiTagModal,
      mutiltagdropdownOpen,
      tagId,
      tagName,
      newMultiTagList,
      inputMultiTagVal,
      uploadLiteFilesModal,
      filterStatus,
      filterStatusModal,
      exportModal,
      deleteFiles,
      showReplaceTagModal,
      showBackBtn,
    } = this.state;
    let totalpages = totalDocuments !== 0 ? Math.ceil(totalDocuments / 15) : 0;
    totalpages = parseInt(totalpages, 10);
    let offset = 0;
    let off = 0;
    if (uploadFilesList !== undefined) {
      offset = paginationOffset;
      off = paginationOff;
    }
    // let multiTags = '';
    // if (newMultiTagList !== undefined) {
    //   multiTags = newMultiTagList.map((item) => {
    //     return (
    //       <a
    //         key={item.key}
    //         onClick={() => this.handleMultiTagChange(item)}
    //
    //         className="dropdown-item">{item.label}
    //       </a>
    //     );
    //   });
    // }
    return (
      <div
        className="FilesList"
        role="presentation"
        onClick={this.onClickFilterBG}
      >
        <header>
          <h1 className="pageHeading">All Files</h1>
        </header>
        <div className="center-wrapper">
          {showBackBtn ? (
            <div className="row align-items-center no-gutters mb-3 pb-3">
              <div className="section-title col">
                <h6 role="presentation" className="stepBacklink">
                  <a onClick={this.props.navigate(-1)}>
                    <i className="icon-left-arrow" /> Back
                  </a>
                </h6>
              </div>
            </div>
          ) : null}
          <div id="loading" style={loading ? blockStyle : noneStyle} />
          {listOfFiles === null || listOfFiles === undefined ? (
            <h3 className="text-light text-small">Fetching Files ...</h3>
          ) : (
            <div>
              <UploadFilesFilterTabs
                data={this.state}
                component={this}
                uploadFile={() => this.uploadFile()}
                filterBGClicked={this.state.filterBGClicked}
              />
              <div className="tab-content">
                <div className="tab-pane active" id="review">
                  <div id="inbox-list" className="table tableWrap">
                    <List
                      navigate={this.props.navigate}
                      action={this.props.action}
                      data={this.state}
                      component={this}
                    />
                    <Pagination
                      currentPage={
                        uploadFilesList.length >= 1 ? pagination + 1 : 0
                      }
                      totalPages={totalpages}
                      totalRecords={totalDocuments}
                      offset={totalDocuments === 0 ? offset - 1 : offset}
                      off={off}
                      handlePageChange={this.handlePageChange}
                      pageName="files"
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          {this.state.shareFile && (
            <FilePopup
              shareFile
              shareType="multiple"
              sharedFileDetails={this.state.selectedrecords}
              closeModal={this.closeModal}
              match={this.props.match}
            />
          )}
          {this.state.exportModal && (
            <ExportModal
              selectedRecords={this.state.selectedrecords}
              closeModal={() => this.setState({ exportModal: false })}
            />
          )}
          {multiTagModal && (
            <MultiTagsYearMonthModal
              closeModal={this.closeTagModals}
              selectedrecords={selectedrecords}
            />
          )}
          {showReplaceTagModal && (
            <ReplaceTags
              closeModal={this.closeTagModals}
              selectedrecords={selectedrecords}
            />
          )}
          <ConfirmationModal
            isOpen={deleteFiles}
            headerText="Confirm delete these files?"
            messageText={this.state.alertText || ''}
            noBtnClick={() => this.setState({ deleteFiles: false })}
            yesBtnClick={() => this.selectedFilesDeleteConfirmed('delete')}
            yesBtnText="Delete Files"
            noBtnText="Cancel"
          />
          <ConfirmationModal
            isOpen={filterStatusModal}
            headerText={`Confirm ${
              filterStatus === 'active' ? 'archive' : 'unarchive'
            } ${selectedrecords.length > 1 ? 'these files' : 'this file'}`}
            messageText={`Are you sure you want to ${
              filterStatus === 'active' ? 'archive' : 'unarchive'
            } ${selectedrecords.length > 1 ? 'these files' : 'this file'}?`}
            noBtnClick={() => this.setState({ filterStatusModal: false })}
            yesBtnClick={() =>
              this.selectedFilesDeleteConfirmed('filterstatus')
            }
            yesBtnText="Yes"
            noBtnText="No"
          />
          {uploadLiteFilesModal ? (
            <FilePopup
              match={this.props.match}
              closeModal={() => this.uploadFile('close')}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  filesData: state.uploadFilesReducer.filesData,
});

const mapDispatchToProps = (dispatch) => ({
  UploadFilesActions: bindActionCreators(UploadFilesActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(LiteFilesList);
