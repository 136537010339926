/**
 * Util to parse error messages from error objects
 */
export const parseErrorMessage = (
  error?: any,
  fallbackMessage = 'Unknown Error occured',
) => {
  if (error) {
    // Check for new errors object shape
    if (typeof error?.errors?.[0]?.message === 'string') {
      return error.errors[0].message;
    }

    // Check for non-array error object
    if (typeof error?.errors?.message === 'string') {
      return error.errors.message;
    }

    return fallbackMessage;
  }

  return undefined;
};
