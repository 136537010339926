import { Button } from '@mui/material';
import { CalendarIcon } from 'common/Icons';

type Props = {
  isValidStatus: () => boolean;
  toggleDisplay: () => void;
  changeDueDateText: string;
};

export const ChangeDueDateButton = ({
  isValidStatus,
  toggleDisplay,
  changeDueDateText,
}: Props) => {
  if (isValidStatus())
    return (
      <Button
        startIcon={<CalendarIcon />}
        variant="contained"
        onClick={toggleDisplay}
        data-print-id="editWorkflowButton"
      >
        {changeDueDateText}
      </Button>
    );

  return null;
};
