import { FormControl, inputBaseClasses, styled } from '@mui/material';

export const FormControlStyled = styled(FormControl)(({ theme }) => ({
  '& label + *': {
    marginTop: 23,
  },
  [`& .${inputBaseClasses.input}`]: {
    borderStyle: 'solid',
    backgroundColor: theme.palette.common.white,
  },
}));
