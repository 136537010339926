import React, { useMemo } from 'react';

import { Box, Paper } from '@mui/material';

import { ReactComponent as GoogleAuthenticatorIcon } from './google-authenticator-logo.svg';
import { ReactComponent as MicrosoftAuthenticatorIcon } from './microsoft-authenticator-logo.svg';
import { ReactComponent as AuthyAuthenticatorIcon } from './authy-logo.svg';

export type MfaType = 'google' | 'microsoft' | 'authy';
export type MfaIconProps = {
  type: MfaType;
};

export const MfaIcon = ({ type }: MfaIconProps) => {
  const IconSvg = useMemo(() => {
    switch (type) {
      case 'authy':
        return AuthyAuthenticatorIcon;
      case 'microsoft':
        return MicrosoftAuthenticatorIcon;
      case 'google':
      default:
        return GoogleAuthenticatorIcon;
    }
  }, [type]);
  return (
    <Paper elevation={8} sx={{ borderRadius: '12px' }}>
      <Box
        aria-label={`${type} authenticator logo`}
        p={1}
        width={56}
        height={56}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <IconSvg />
      </Box>
    </Paper>
  );
};
