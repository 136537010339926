/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import React from 'react';
// import { formatEmail } from '../EmailUtilities';
import styled from '@emotion/styled';
import LiscioLogo from './icons/LiscioLogo';
import AddContactAccount from './icons/AddContactAccount';

const Recipients = styled.div`
  display: block;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  .RecipientsSection {
    padding-top: ${({ withoutLabel }) => (withoutLabel ? '0' : '10px')};
    padding-bottom: ${({ withoutLabel }) => (withoutLabel ? '0' : '10px')};
    border-bottom: ${({ withoutLabel }) =>
      withoutLabel ? 'none' : '1px solid #e0e1e2'};
  }
  .EmId {
    background-color: ${({ withoutLabel }) =>
      withoutLabel ? 'transparent' : '#eef0f1'};
    border-radius: ${({ withoutLabel }) => (withoutLabel ? '0' : '4px')};
    padding: ${({ withoutLabel }) => (withoutLabel ? '0' : '2px 8px')};
    font-weight: ${({ withoutLabel }) => (withoutLabel ? '600' : 'none')};
    font-size: ${({ withoutLabel }) => (withoutLabel ? '1rem' : 'none')};
    & + .EmId {
      margin-left: 3px;
    }
  }
`;

const RecipientsSection = ({
  showMore,
  dataFor,
  labelFor,
  contactCard,
  contactCardLeave,
  handleContactAccount,
  withoutLabel,
}) => {
  if (showMore && dataFor?.length > 0) {
    const recipients = dataFor;
    return (
      <Recipients withoutLabel={withoutLabel}>
        <div className="RecipientsSection">
          {!withoutLabel && <strong>{labelFor}: &nbsp;</strong>}
          {recipients?.length > 0
            ? recipients.map((data, idx) =>
                data.contact_id && data.is_liscio ? (
                  <span
                    className="EmId"
                    data-tip
                    data-for={`${data.contact_id}global${recipients.indexOf(
                      data,
                    )}_${idx}`}
                    key={`${recipients.indexOf(data)}_${idx}${data.email}`}
                    onMouseEnter={() =>
                      contactCard(
                        data.contact_id,
                        `${recipients.indexOf(data)}_${idx}`,
                      )
                    }
                    onClick={contactCardLeave}
                  >
                    {data.email}
                    <LiscioLogo />
                  </span>
                ) : !data.contact_id && data.is_liscio ? (
                  <span className="EmId">
                    {data.email}
                    <LiscioLogo />
                  </span>
                ) : (
                  <span className="EmId">
                    {data.email}
                    <AddContactAccount
                      actionObj={data}
                      labelFor={labelFor}
                      handleContactAccount={handleContactAccount}
                    />
                  </span>
                ),
              )
            : null}
          {/* TODO: for future use */}
          {/* {formatEmail(dataFor).length > 1 && !recipientOf ? (
            <span className="d-inline">
              {formatEmail(dataFor)[0]}
              ...
              <span
                className="text-body font-weight-bold pl-2"
                onClick={(e) => {
                  e.stopPropagation();
                  showRecipients(labelFor);
                }}
              >
                +{formatEmail(dataFor).length - 1} other
              </span>
            </span>
          ) : (
            <span className="d-inline">
              {recipients}
              <LiscioLogo />
              <AddContactAccount />
            </span>
          )} */}
        </div>
      </Recipients>
    );
  }
  return null;
};

export default RecipientsSection;
