export const handleFilesData = (data) => ({
  type: 'HANDLE_FILES_DATA',
  data,
});

export const previewFile = (data, previewmodal) => ({
  type: 'PREVIEW_FILE',
  data,
  previewmodal,
});
