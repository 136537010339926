/* eslint-disable no-script-url */
/* eslint-disable react/jsx-no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-access-state-in-setstate */
import React from 'react';
import { Link } from 'react-router-dom-latest';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from 'reactstrap';
import axios from 'axios';
import { withRouter } from 'utilities/withRouterWrapper';
import Pagination from 'common/Pagination';
import ConfirmationModal from 'common/ConfirmationModal';
import {
  AlertMessage,
  isUserEmployee,
  getInitials,
  getCurrentPath,
  getSiteHostname,
} from 'utilities/utils';
import MessageFilterCustomView from 'components/Messages/Inbox/MessageFilterCustomView';
import * as TaskApi from 'api/TaskApi';
import * as Config from 'constants/constants';
import HeaderTabs from 'common/HeaderTabs';
import PageNotFound from 'common/PageNotFound';
import * as paginationActions from 'actions/PaginationActions';
import * as actions from 'actions/MessageActions';
import liscioSmallest from 'images/lisciosmallest.png';
import AccountFilter from 'components/Filters/AccountFilter';
import Tag from 'common/Tag/Tag';
import { websocket } from 'websocket';
import { getAuthSession } from 'auth/session';
import { withStorageData } from 'utilities/withStorageData';
import ThreadDetails from './ThreadDetails';
import MessageList from './MessageList';
import './chatbox.scss';

const noneStyle = { display: 'none' };
const blockStyle = { display: 'block' };
const removeItem = (array, element) =>
  array.filter((e) => !(e.thread_id === element.thread_id) && !element.checked);

class InboxList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      pagination: 0,
      paginationOffset: '',
      paginationOff: '',
      filter: 'inbox',
      activeTab: 0,
      allMessages: [],
      allContacts: [],
      allAccounts: [],
      accounts: [],
      selectedCheckbox: [],
      selectedFilterList: {},
      searchField: '',
      recipientValue: '',
      apiUrl: '',
      contacts: [],
      allMessagesView: [],
      allMessagesSharedView: [],
      filteredUserView: 'My Messages',
      filteredUserViewId: '',
      customeView: false,
      deleteViewModal: false,
      dontShowDefaultView: false,
      errors: {},
      loading: false,
      dataLoaded: false,
      unauthorisedAccess: false,
      unauthorisedMessage: '',
      from: null,
      to: null,
      attachmentCheckBox: false,
      selectedAll: false,
      confirmArchive: false,
      tabs: [
        {
          value: 'inbox',
          label: 'Inbox',
        },
        {
          value: 'sent',
          label: 'Sent',
        },
        {
          value: 'draft',
          label: 'Drafts',
        },
        {
          value: 'archive',
          label: 'Archived',
        },
        {
          value: 'pinned',
          label: 'Pinned',
        },
      ],
    };
    this.changeActiveTab = this.changeActiveTab.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.typingTime = null;
  }

  UNSAFE_componentWillMount() {
    const {
      location,
      location: { state },
    } = this.props;
    const path = getCurrentPath(location);
    document.title = 'Inbox';

    const { tabs } = this.state;
    const alertTab = {
      value: 'alert',
      label: 'Alerts',
    };
    const allTab = {
      value: 'all_messages',
      label: 'All',
    };
    if (state?.filter) {
      this.setState({
        filter: state.filter,
        activeTab: state.activeTab,
      });
    }
    if (isUserEmployee()) {
      this.setState({ tabs: tabs.splice(1, 0, alertTab) });
      this.setState({ tabs: tabs.splice(6, 0, allTab) });
      if (path === '/alert/:id') {
        this.setState({
          filter: 'alert',
          activeTab: 1,
        });
      }
      if (path === '/all_messages/:id') {
        this.setState({
          filter: 'all_messages',
          activeTab: 6,
        });
      }
    }
    if (path === '/inbox/:id') {
      this.setState({
        filter: 'inbox',
        activeTab: 0,
      });
    }
    if (path === '/sent/:id') {
      this.setState({
        filter: 'sent',
        activeTab: isUserEmployee() ? 2 : 1,
      });
    }
    if (path === '/draft/:id') {
      this.setState({
        filter: 'draft',
        activeTab: isUserEmployee() ? 3 : 2,
      });
    }
    if (path === '/archive/:id') {
      this.setState({
        filter: 'archive',
        activeTab: isUserEmployee() ? 4 : 3,
      });
    }
    if (path === '/pinned/:id') {
      this.setState({
        filter: 'pinned',
        activeTab: isUserEmployee() ? 5 : 4,
      });
    }
  }

  componentDidMount() {
    const { msgFilters } = this.props;

    let apiUrl = '';
    const data = { limit: true };
    const component = this;
    if (isUserEmployee()) {
      data.message = true;
    }
    if (msgFilters.apiUrl && msgFilters.apiUrl.length) {
      this.setState({
        selectedCheckbox: msgFilters.selectedCheckbox,
        selectedFilterList: msgFilters.selectedFilterList,
        searchField: msgFilters.searchField,
        recipientValue: msgFilters.recipientValue,
        apiUrl: msgFilters.apiUrl,
        contacts: msgFilters.contacts,
        attachmentCheckBox: msgFilters.attachmentCheckBox,
        filteredUserView: msgFilters.filteredUserView,
        filteredUserViewId: msgFilters.filteredUserViewId,
        dontShowDefaultView: msgFilters.dontShowDefaultView,
      });
      ({ apiUrl } = msgFilters);
    }
    // this.props.actions.fetchMessages(this, '', 15, this.props.message_page || 1, apiUrl, filter);
    this.props.actions.receivedNotification(false);
    TaskApi.getContactsOfTask(this, data, 'notes');
    TaskApi.getAccounts(this, 'notes');
    if (
      msgFilters.apiUrl &&
      msgFilters.apiUrl.length &&
      msgFilters.filteredUserView &&
      msgFilters.filteredUserViewId
    ) {
      TaskApi.getAllMessagesView(this, msgFilters.filteredUserViewId);
    } else {
      TaskApi.getAllMessagesView(
        this,
        '',
        'firsttime',
        this.props.message_page || 1,
        apiUrl,
      );
    }
    if (getAuthSession()) {
      component.tokenInterval = setInterval(() => {
        console.log('Checking for token');
        // TODO: __HOMEDATA-AUDIT
        const local_data = JSON.parse(
          localStorage.getItem(`${getSiteHostname().split(':')[0]} data`),
        );
        if (local_data) {
          clearInterval(component.tokenInterval);
          console.log('trying to connect to inbox messages channel');
          component.MessageCable = websocket.consumer;
          component.myTaskSubscription =
            component.MessageCable.subscriptions.create(
              {
                channel: 'MessageCountChannel',
                cpa_user_id: local_data.cpa_user_id,
              },
              {
                connected: () => {
                  console.log('connected to inbox message channel');
                },
                received: (cabledata) => {
                  console.log('Data received - ', cabledata);
                  this.props.actions.updatePinnedCount(cabledata);
                },
                disconnected: () => {
                  console.log('disconnected from inbox message channel');
                },
              },
            );
        }
      }, 200);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { location, navigate } = this.props;
    const path = getCurrentPath(location);
    const {
      inbox_count,
      alert_count,
      archive_count,
      pinned_count,
      draft_count,
      sent_count,
      all_messages_count,
    } = nextProps.messages;
    // eslint-disable-next-line react/no-access-state-in-setstate
    const StateObject = { ...this.state };
    const inboxLabel = inbox_count >= 0 ? `Inbox | ${inbox_count}` : 'Inbox';
    const alertLabel = alert_count >= 0 ? `Alerts | ${alert_count}` : 'Alerts';
    const sentLabel = sent_count >= 0 ? `Sent | ${sent_count}` : 'Sent';
    const draftLabel = draft_count >= 0 ? `Drafts | ${draft_count}` : 'Drafts';
    const archiveLabel =
      archive_count >= 0 ? `Archived | ${archive_count}` : 'Archived';
    const pinnedLabel =
      pinned_count >= 0 ? `Pinned | ${pinned_count}` : 'Pinned';
    const allMessagesLabel =
      all_messages_count >= 0 ? `All | ${all_messages_count}` : 'All';

    StateObject.tabs = [
      {
        value: 'inbox',
        label: inboxLabel,
      },
      {
        value: 'sent',
        label: sentLabel,
      },
      {
        value: 'draft',
        label: draftLabel,
      },
      {
        value: 'archive',
        label: archiveLabel,
      },
      {
        value: 'pinned',
        label: pinnedLabel,
      },
    ];

    const alertTab = {
      value: 'alert',
      label: alertLabel,
    };
    const allTab = {
      value: 'all_messages',
      label: allMessagesLabel,
    };
    if (isUserEmployee()) {
      StateObject.tabs.splice(1, 0, alertTab);
      StateObject.tabs.splice(6, 0, allTab);
    }
    if (path === '/draft/:id') {
      this.setState({
        filter: 'draft',
        activeTab: isUserEmployee() ? 3 : 2,
      });
    }
    this.setState({
      allMessages: nextProps.messages,
      tabs: StateObject.tabs,
    });
    if (
      path &&
      location.state &&
      location.state.path &&
      location.state.from &&
      location.state.path === '/all_messages' &&
      location.state.path !== location.state.from
    ) {
      if (
        path === '/accountdetails/:id' ||
        path === '/accountdetails/:id/messages' ||
        path === '/contactdetails/:id'
      ) {
        navigate('/redirect');
        setTimeout(() => {
          navigate(-1);
        }, 50);
      } else if (
        location.state &&
        (location.state.from === 'uploadlist' ||
          location.state.from === 'notify')
      ) {
        navigate(-1);
      }
    }
  }

  componentWillUnmount() {
    if (this.MessageCable && this.myTaskSubscription) {
      this.MessageCable.subscriptions.remove(this.myTaskSubscription);
    }
  }

  getFilteredRecords = () => {
    const {
      selectedFilterList,
      searchField,
      attachmentCheckBox,
      filter,
      filteredUserViewId,
    } = this.state;
    const apiPath =
      (selectedFilterList.RECIPIENT
        ? `&recipient_id=${selectedFilterList.RECIPIENT[0].id}`
        : '') +
      (selectedFilterList.ACCOUNT
        ? `&account_id=${selectedFilterList.ACCOUNT.value}`
        : '') +
      (selectedFilterList['DATE RANGE']
        ? (selectedFilterList['DATE RANGE'][0].from
            ? `&from_date=${selectedFilterList['DATE RANGE'][0].from.replace(
                /\//g,
                '-',
              )}`
            : '') +
          (selectedFilterList['DATE RANGE'][0].to
            ? `&to_date=${selectedFilterList['DATE RANGE'][0].to.replace(
                /\//g,
                '-',
              )}`
            : '')
        : '') +
      (searchField ? `&keyword=${searchField}` : '') +
      (attachmentCheckBox ? '&attachment=true' : '') +
      (filteredUserViewId
        ? `&user_view_id=${encodeURIComponent(filteredUserViewId)}`
        : '');
    this.setState({ apiUrl: apiPath }, () => {
      this.props.actions.handleMessageFilters(this.state);
    });
    this.props.pagination.messagePagination(1);
    this.props.actions.fetchMessages(this, '', 15, 1, apiPath, filter);
  };

  getUserView = (data = null, filtr = '', page = 1, apiUrl = '') => {
    const { filter, searchField } = this.state;
    let apiPath = searchField ? `&keyword=${searchField}` : '';
    let filteredUserView =
      filtr === 'all_messages' ? 'All Messages' : 'My Messages';
    let filteredUserViewId = '';
    if (data?.value && filtr === '') {
      apiPath += `&user_view_id=${encodeURIComponent(data.value)}`;
      filteredUserView = data.label;
      filteredUserViewId = data.value;
    }
    this.setState(
      {
        filteredUserView,
        filteredUserViewId,
        apiUrl: apiPath,
      },
      () => {
        this.props.actions.handleMessageFilters(this.state);
      },
    );
    this.props.pagination.messagePagination(1);
    this.props.actions.fetchMessages(
      this,
      '',
      15,
      page,
      apiUrl || apiPath,
      filter,
    );
  };

  handleCancel = (viewId = '') => {
    this.setState({ customeView: false }, () => {
      this.handleFilters('', viewId, 'CLEAR');
    });
  };

  handleCustomView = () => {
    this.setState({ customeView: true }, () => {
      this.handleFilters('', '', 'OPEN');
    });
  };

  handleEdit = (action = '') => {
    const component = this;
    if (action === 'edit') {
      TaskApi.getUserView(component, component.state.filteredUserViewId);
    } else if (action === 'delete') {
      this.setState({ deleteViewModal: true });
    } else {
      TaskApi.deleteUserView(component, component.state.filteredUserViewId);
    }
  };

  updateTabs = () => {
    const { pinned_count } = this.props.messages;
    const StateObject = { ...this.state };
    const pinnedLabel =
      pinned_count >= 0 ? `Pinned | ${pinned_count}` : 'Pinned';
    const indx = StateObject.tabs.findIndex((y) => y.value === 'pinned');
    StateObject.tabs[indx].label = pinnedLabel;
    this.setState({ tabs: StateObject.tabs });
  };

  handlePageChange(index) {
    const component = this;
    if (index === this.props.message_page) {
      return false;
    }
    this.props.actions.fetchMessages(
      component,
      '',
      15,
      index,
      this.state.apiUrl,
      this.state.filter,
    );
    this.props.pagination.messagePagination(index);
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
    return true;
  }

  handleForceUpdate = () => {
    const { pathname } = window.location;
    this.props.navigate('/');
    setTimeout(() => {
      this.props.navigate(pathname);
    }, 20);
  };

  handleSearch = (e) => {
    this.setState({ searchField: e.target.value });
    clearTimeout(this.typingTime);
    this.typingTime = setTimeout(() => {
      this.getFilteredRecords();
    }, 500);
  };

  changeActiveTab(key, each) {
    const { filteredUserViewId, filteredUserView } = this.state;
    this.setState({
      activeTab: key,
      filter: each.value,
      selectedCheckbox: [],
      selectedAll: false,
      filteredUserView: filteredUserViewId
        ? filteredUserView
        : each.value === 'all_messages'
        ? 'All Messages'
        : 'My Messages',
    });

    const tab = each.value;
    this.props.pagination.messagePagination(1);
    this.props.actions.fetchMessages(this, '', 15, 1, this.state.apiUrl, tab);
    this.props.navigate('/all_messages');
  }

  unauthorised = (message = '') => {
    this.setState({
      unauthorisedAccess: true,
      unauthorisedMessage: message,
    });
  };

  attachmentCheckBox = (e) => {
    this.setState({ attachmentCheckBox: e.target.checked });
  };

  recipientInputChange = (e) => {
    const value = e ? e.target.value : '';
    const component = this;
    this.setState({ recipientValue: value });
    if (value.length < 2) {
      this.setState({ contacts: this.state.allContacts });
    } else {
      const message = isUserEmployee() ? '&message=true' : '';
      clearTimeout(this.typingTime);
      this.typingTime = setTimeout(() => {
        const request = axios.get(
          `${Config.SEARCH_TASK_CONTACTS}/?keyword=${value}${message}`,
        );
        request.then((response) => {
          if (response.status === 200) {
            component.setState({ contacts: response.data });
          }
        });
      }, 500);
    }
  };

  handleCustomViewValidation = () => {
    const err = {};
    let formIsValid = true;
    const { selectedFilterList, attachmentCheckBox } = this.state;
    if (selectedFilterList && !selectedFilterList['USER VIEW NAME']) {
      formIsValid = false;
      err.user_view_name = 'Enter View Name';
    }
    if (
      selectedFilterList &&
      (!selectedFilterList.ACCOUNTS || selectedFilterList.ACCOUNTS === '') &&
      (!selectedFilterList.RECIPIENT || selectedFilterList.RECIPIENT === '') &&
      (!selectedFilterList['DATE RANGE'] ||
        (!selectedFilterList['DATE RANGE'][0].from &&
          !selectedFilterList['DATE RANGE'][0].to) ||
        (selectedFilterList['DATE RANGE'][0].from === '' &&
          selectedFilterList['DATE RANGE'][0].to === '')) &&
      !attachmentCheckBox
    ) {
      formIsValid = false;
      err.otherFilter = 'Select atleast one filter';
    }
    this.setState({ errors: err });
    setTimeout(() => {
      err.otherFilter = '';
      this.setState({ errors: err });
    }, 100);
    return formIsValid;
  };

  handleFilters = (e, input, filterType) => {
    let { selectedFilterList } = this.state;
    if (filterType === 'ACCOUNT') {
      selectedFilterList = {
        ...selectedFilterList,
        [filterType]: input,
      };
      this.setState(
        {
          selectedFilterList,
        },
        () => this.getFilteredRecords(),
      );
    }
    if (filterType === 'OPEN') {
      selectedFilterList = {
        ...selectedFilterList,
        viewFor: 'Create',
        restictVisibilty: 'RESTRICT VISIBILITY TO ME',
      };
    }
    if (filterType === 'CLEAR' && !input) {
      TaskApi.getAllMessagesView(this, '');
      selectedFilterList = {};
      this.setState({
        to: null,
        from: null,
        recipientValue: '',
        attachmentCheckBox: false,
        contacts: this.state.allContacts,
      });
    }
    if (filterType === 'CLEAR' && input) {
      TaskApi.getAllMessagesView(this, input);
      selectedFilterList = {};
      this.setState({
        to: null,
        from: null,
        recipientValue: '',
        attachmentCheckBox: false,
        contacts: this.state.allContacts,
      });
    }
    if (filterType === 'DEFAULT VIEW') {
      selectedFilterList = {
        ...selectedFilterList,
        [filterType]: e.target.checked,
      };
    }
    if (
      filterType === 'restictVisibilty' ||
      filterType === 'USER VIEW NAME' ||
      filterType === 'ACCOUNTS' ||
      filterType === 'RECIPIENT'
    ) {
      selectedFilterList = {
        ...selectedFilterList,
        [filterType]: input,
      };
    }
    if (filterType === 'CLEAR DATE RANGE') {
      if (selectedFilterList['DATE RANGE']) {
        selectedFilterList['DATE RANGE'][0][input] = null;
      } else {
        selectedFilterList = {
          ...selectedFilterList,
          'DATE RANGE': [{ [input]: null }],
        };
      }
      this.setState({ [input]: null });
    }
    if (filterType === 'DATE RANGE') {
      if (selectedFilterList[filterType]) {
        selectedFilterList[filterType][0][input] = e;
      } else {
        selectedFilterList = {
          ...selectedFilterList,
          [filterType]: [{ [input]: e }],
        };
      }
      this.setState({ [input]: e });
    }
    this.setState({
      selectedFilterList,
      recipientValue: '',
      contacts: this.state.allContacts,
      errors: {},
    });
  };

  removeFilter = (item, filter) => {
    const { selectedFilterList, allContacts } = this.state;
    if (filter === 'RECIPIENT') {
      delete selectedFilterList[filter];
    }
    if (filter === 'ACCOUNT') {
      delete selectedFilterList[filter];
      this.getFilteredRecords();
    } else {
      delete selectedFilterList[filter][0][item];
      if (!Object.keys(selectedFilterList[filter][0]).length) {
        delete selectedFilterList[filter];
      }
      this.setState({ [item]: '' });
    }
    this.setState({
      selectedFilterList,
      contacts: allContacts,
      recipientValue: '',
    });
  };

  areAllSelected() {
    let x = 0;
    const { messages } = this.props;
    const { filter } = this.state;
    const msg = messages[filter];
    msg.map((e) => {
      if (e.checked) x += 1;
      return e;
    });
    const checkbox = document.getElementById('msg2');
    if (x === msg.length && (x && msg.length) !== 0) {
      this.setState({ selectedAll: true });
      if (checkbox) checkbox.indeterminate = false;
    } else {
      this.setState({ selectedAll: false });
      if (checkbox) checkbox.indeterminate = true;
    }
    if (x === 0) {
      if (checkbox) checkbox.indeterminate = '';
    }
  }

  checkboxAction = (item, index) => {
    let { selectedCheckbox } = this.state;
    const { filter } = this.state;
    const { messages } = this.props;
    const msg = messages[filter];
    if (msg[index].checked) {
      msg[index].checked = false;
    } else {
      msg[index].checked = true;
    }
    if (msg[index].checked) {
      selectedCheckbox.push(msg[index]);
    } else {
      selectedCheckbox = removeItem(selectedCheckbox, msg[index]);
    }
    this.props.actions.fetchAllMessages(messages);
    this.setState({ selectedCheckbox }, () => {
      this.areAllSelected();
    });
  };

  handleSelectAll = () => {
    let { selectedCheckbox } = this.state;
    const { messages } = this.props;
    const { selectedAll, filter } = this.state;
    let isAllChecked = 0;
    let checked = false;
    const msg = messages[filter];
    msg.map((item) => {
      if (item.checked) {
        isAllChecked += 1;
      }
      return item;
    });
    if (!selectedAll && isAllChecked === 0) {
      checked = true;
    }
    msg.map((item) => {
      const eachone = item;
      eachone.checked = checked;
      if (selectedCheckbox.length) {
        if (checked) {
          selectedCheckbox.push(eachone);
        } else {
          selectedCheckbox = removeItem(selectedCheckbox, eachone);
        }
      }
      return eachone;
    });
    if (!selectedCheckbox.length && checked) {
      selectedCheckbox = msg;
    }
    this.props.actions.fetchAllMessages(messages);
    this.setState({ selectedCheckbox }, () => {
      this.areAllSelected();
    });
  };

  massArchive = (confirmed) => {
    const { selectedCheckbox } = this.state;
    const { message_page } = this.props;
    const allSelected = this.state.selectedAll;
    if (!confirmed) {
      this.setState({ confirmArchive: true });
    } else {
      // do mass archive
      const array = { ids: [] };
      selectedCheckbox.map((each) => {
        array.ids.push(each.thread_id);
        return array;
      });
      this.setState({
        confirmArchive: false,
        loading: true,
      });
      const request = axios.post(Config.MESSAGE_MASS_ARCHIVE, array);
      this.setState({ loading: false });
      request
        .then((response) => {
          if (response.data.status === 200) {
            AlertMessage('success', response.data.message, 3000);
            this.setState(
              {
                selectedCheckbox: [],
                selectedAll: false,
              },
              () => {
                const messagePage =
                  message_page > 1 && allSelected
                    ? message_page - 1
                    : message_page;
                this.props.pagination.messagePagination(messagePage);
                this.props.actions.fetchMessages(
                  this,
                  '',
                  15,
                  messagePage,
                  this.state.apiUrl,
                  this.state.filter,
                );
              },
            );
          } else {
            AlertMessage('error', response.data.message, 3000);
          }
        })
        .catch(() => {
          AlertMessage('error', 'Some Error Occured', 3000);
        });
    }
  };

  togglePinUnpin = (item, key, loader = '') => {
    const { filter } = this.state;
    if (loader === 'noloader') {
      const { message_page } = this.props;
      const allSelected = this.state.selectedAll;
      const messagePage =
        message_page > 1 && allSelected ? message_page - 1 : message_page;
      this.props.pagination.messagePagination(messagePage);
      this.props.actions.fetchMessages(
        this,
        '',
        15,
        messagePage,
        this.state.apiUrl,
        this.state.filter,
        'noloader',
      );
    } else {
      const msgArr = this.props.messages;
      msgArr[filter][key].is_pinned =
        !this.props.messages[filter][key].is_pinned;
      this.props.actions.fetchAllMessages(msgArr);
    }
  };

  readMessageThread = (item, key) => {
    const msgArr = this.props.messages;
    const { filter } = this.state;
    msgArr[filter][key].read = true;
    this.props.actions.fetchAllMessages(msgArr);
  };

  render() {
    const { messages, match, location } = this.props;
    const {
      dataLoaded,
      selectedFilterList,
      selectedCheckbox,
      confirmArchive,
      filter,
      accounts,
      contacts,
      allMessagesView,
      allMessagesSharedView,
      filteredUserView,
      customeView,
      filteredUserViewId,
      deleteViewModal,
    } = this.state;
    let totalpages = 1;
    let totalrecords = 0;
    let offset = 0;
    let off = 0;

    const lst =
      messages && messages[filter] !== undefined ? messages[filter] : [];
    const path = getCurrentPath(location);

    if (messages[filter] !== undefined) {
      totalpages =
        messages[`${filter}_count`] !== 0
          ? Math.ceil(messages[`${filter}_count`] / 15)
          : 0;
      totalrecords = messages[`${filter}_count`];
      offset = messages[`${filter}_offset`];
      off = messages[`${filter}_off`];
      if (filter === 'inbox' && messages.page) {
        this.handlePageChange(messages.page);
      }
    }
    let activeTb = 'My Messages';

    if (filter === 'all_messages') {
      activeTb = 'All Messages';
    }
    if (this.state.unauthorisedAccess) {
      return (
        <PageNotFound name="message" message={this.state.unauthorisedMessage} />
      );
    }
    return (
      <div className="InboxList">
        <header className="pb-0 pt-0">
          <HeaderTabs
            data={this.state.tabs}
            activeTab={this.state.activeTab}
            handleClick={this.changeActiveTab}
          />
        </header>
        <div id="loading" style={this.state.loading ? blockStyle : noneStyle} />
        <div className="center-wrapper chat-align">
          <div className="tab-content">
            <div className="tab-pane active">
              {path === '/inbox/:id' ||
              path === '/alert/:id' ||
              path === '/sent/:id' ||
              path === '/draft/:id' ||
              path === '/archive/:id' ||
              path === '/pinned/:id' ||
              path === '/all_messages/:id' ? (
                <ThreadDetails
                  data={this.state}
                  propsData={this.props}
                  name="inboxlist"
                  unauthorised={this.unauthorised}
                  handlePageChange={this.handlePageChange}
                  updateTabs={this.updateTabs}
                />
              ) : (
                <section className="">
                  {this.state.loading && !Object.keys(messages).length ? (
                    <MessageList
                      dataLoaded={dataLoaded}
                      list={
                        messages && messages.inbox !== undefined
                          ? messages.inbox.slice(0, 4)
                          : []
                      }
                      selectedAll={this.state.selectedAll}
                      checkboxAction={this.checkboxAction}
                      handleSelectAll={this.handleSelectAll}
                      pagination={this.props.message_page}
                      filter="inbox"
                      name="inboxlist"
                      togglePinUnpin={this.togglePinUnpin}
                      readMessageThread={this.readMessageThread}
                    />
                  ) : (
                    <div>
                      {filter !== 'draft' ? (
                        <div className="filter-wrapper d-flex justify-content-between">
                          <div className="flex-wrap d-flex filterPart--archive">
                            <div className="filterGroup d-flex flex-wrap">
                              {!isUserEmployee() ? (
                                <div className="d-flex flex-wrap bd-highlight">
                                  <label
                                    htmlFor="filter"
                                    className="mt-2 mr-1 m-0"
                                    style={{ paddingLeft: '10px' }}
                                  >
                                    Filter by
                                  </label>
                                  <AccountFilter
                                    filterSelected={this.handleFilters}
                                    isActive={
                                      selectedFilterList &&
                                      selectedFilterList.ACCOUNT
                                    }
                                    setFilterInFocus={this.setFilterInFocus}
                                    showFilterOptions={
                                      this.state.filterInFocus === 'ACCOUNT'
                                    }
                                  />
                                </div>
                              ) : null}
                              {selectedFilterList &&
                              Object.keys(selectedFilterList).length ? (
                                <div className="select-filter-list w-100">
                                  <Tag
                                    label={selectedFilterList.ACCOUNT?.label}
                                    onClick={() =>
                                      this.removeFilter('ACCOUNT', 'ACCOUNT')
                                    }
                                    variant="secondary"
                                  />
                                </div>
                              ) : null}
                            </div>
                            {/* isUserEmployee() ?
                              null
                              :
                              <div className="form-group has-search">
                                <span className="icon-zoom2 form-control-feedback" />
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Search messages"
                                  id="message-search"
                                  value={this.state.searchField}
                                  onChange={this.handleSearch}
                                  autoComplete="off"
                                />
                              </div>
                            */}
                            {isUserEmployee() ? (
                              <div className="filterGroup d-flex flex-wrap bd-highlight has-search">
                                <div className="d-flex">
                                  <div className="dropdown">
                                    <button
                                      type="button"
                                      className={
                                        selectedFilterList &&
                                        selectedFilterList['MY MESSAGES']
                                          ? 'btn btn-primary btn-sm dropdown-toggle active'
                                          : 'btn btn-primary btn-sm dropdown-toggle'
                                      }
                                      data-toggle="dropdown"
                                      onMouseDown={null}
                                      data-testid="inbox__filter_button"
                                    >
                                      <i className="icon-chevron-down2" />
                                      <span>{filteredUserView}</span>
                                      <span className="dropupWrap--caret" />
                                    </button>
                                    <div
                                      className="dropdown-menu dropdown-menu-left"
                                      style={{ overflowX: 'hidden' }}
                                    >
                                      <a
                                        href="javascript:"
                                        onClick={() =>
                                          this.getUserView({}, filter)
                                        }
                                        className="dropdown-item"
                                        key="my_inbox"
                                        data-testid={`inbox__${activeTb
                                          ?.toLowerCase()
                                          ?.split(' ')
                                          ?.join('_')}`}
                                      >
                                        <h5 className="fontSize">{activeTb}</h5>
                                      </a>
                                      {allMessagesView &&
                                      allMessagesView.length !== undefined &&
                                      allMessagesView.length !== 0
                                        ? allMessagesView.map((each) => (
                                            <a
                                              href="javascript:"
                                              onClick={() =>
                                                this.getUserView(each, '')
                                              }
                                              className="dropdown-item d-flex align-items-center"
                                              key={each.value}
                                              data-testid="okay__one"
                                            >
                                              <span className="fontSize mr-3">
                                                {each.label}
                                              </span>
                                              <div className="ml-auto d-flex align-items-center">
                                                <span className="avatar avatar--w20 tipWrap tipWrap--left mr-0">
                                                  {each.name ? (
                                                    each.avatar ? (
                                                      <img
                                                        src={each.avatar}
                                                        alt={each.name}
                                                        className="user-photo rounded-circle mr-0"
                                                      />
                                                    ) : (
                                                      <span className="uName">
                                                        {getInitials(each.name)}
                                                      </span>
                                                    )
                                                  ) : null}
                                                  <span className="tipContent">
                                                    {each.name ? each.name : ''}
                                                  </span>
                                                </span>
                                              </div>
                                            </a>
                                          ))
                                        : null}
                                      {allMessagesSharedView &&
                                      allMessagesSharedView.length !==
                                        undefined &&
                                      allMessagesSharedView.length !== 0 ? (
                                        <div>
                                          <div
                                            className="d-inline-block w-100"
                                            style={{
                                              marginTop: '10px',
                                              marginBottom: '10px',
                                              borderTop: '1px dashed #dfe3e8',
                                            }}
                                          />
                                          {allMessagesSharedView.map((each) => (
                                            <a
                                              href="javascript:"
                                              onClick={() =>
                                                this.getUserView(each, '')
                                              }
                                              className="dropdown-item d-flex align-items-center"
                                              key={each.value}
                                              data-testid={each?.label
                                                ?.toLowerCase()
                                                ?.split(' ')
                                                ?.join('_')
                                                ?.replace("'", '')}
                                            >
                                              <span className="fontSize mr-3">
                                                {each.label}
                                              </span>
                                              <div className="ml-auto d-flex align-items-center">
                                                <span className="avatar avatar--w20 tipWrap tipWrap--left mr-0">
                                                  {each.name ? (
                                                    each.avatar ? (
                                                      <img
                                                        src={each.avatar}
                                                        alt={each.name}
                                                        className="user-photo rounded-circle mr-0"
                                                      />
                                                    ) : (
                                                      <span className="uName">
                                                        {getInitials(each.name)}
                                                      </span>
                                                    )
                                                  ) : null}
                                                  <span className="tipContent">
                                                    {each.name ? each.name : ''}
                                                  </span>
                                                </span>
                                              </div>
                                            </a>
                                          ))}
                                        </div>
                                      ) : null}
                                      <a
                                        href="javascript:"
                                        onClick={this.handleCustomView}
                                        className="dropdown-item"
                                        key="custom_view"
                                        data-testid="inbox__create_custom_view"
                                      >
                                        <h5 className="fontSize">
                                          <i className="icon-plus label-medium mr-2" />
                                          <span className="text-primary">
                                            Create Custom View
                                          </span>
                                        </h5>
                                      </a>
                                    </div>
                                    {customeView ? (
                                      <MessageFilterCustomView
                                        isOpen={customeView}
                                        isUserEmployee={isUserEmployee()}
                                        accountList={accounts}
                                        receipientList={contacts}
                                        handleDateField={this.handleFilters}
                                        removeFilter={this.removeFilter}
                                        attachmentCheckBox={
                                          this.attachmentCheckBox
                                        }
                                        recipientInputChange={
                                          this.recipientInputChange
                                        }
                                        handleCustomViewValidation={
                                          this.handleCustomViewValidation
                                        }
                                        data={this.state}
                                        noBtnClick={this.handleCancel}
                                        handleEdit={this.handleEdit}
                                      />
                                    ) : null}
                                  </div>
                                  {filteredUserViewId ? (
                                    <div className="ml-3">
                                      <a
                                        href="javascript:void(0)"
                                        className="btn btn-outline-light h-auto"
                                        style={{ padding: '0.313rem 0.6rem' }}
                                        onClick={() => this.handleEdit('edit')}
                                        data-testid="inbox_filter_edit"
                                      >
                                        <i
                                          className="icon-pen p-0 text-light"
                                          aria-hidden="true"
                                        />
                                      </a>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            ) : null}
                          </div>
                          <div className="FilterHeader--Action align-self-start d-flex">
                            <div className="form-group has-search">
                              <span className="icon-zoom2 form-control-feedback" />
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Search messages"
                                id="message-search"
                                value={this.state.searchField}
                                onChange={this.handleSearch}
                                autoComplete="off"
                                data-testid="inbox__message_search"
                              />
                            </div>
                            {filter === 'inbox' ||
                            filter === 'alert' ||
                            filter === 'pinned' ? (
                              <button
                                type="button"
                                className="btn btn-primary mr-3"
                                onClick={() => this.massArchive(false)}
                                disabled={!selectedCheckbox.length}
                                data-testid="inbox__archive"
                              >
                                <i className="icon-archive" /> Archive
                              </button>
                            ) : null}
                            <Link
                              to={{
                                pathname: '/message/new/modal',
                                state: {
                                  filter: this.state.filter,
                                  activeTab: this.state.activeTab,
                                  fro: 'inboxlist',
                                },
                              }}
                              className="btn btn-primary"
                              data-testid="inbox__create_message"
                            >
                              <i>+</i> Message
                            </Link>
                          </div>
                        </div>
                      ) : (
                        <div className="FilterHeader--Action align-self-start text-right section-title--bottomborder pb-4 mb-0">
                          <Link
                            to={{
                              pathname: '/message/new/modal',
                              state: {
                                filter: this.state.filter,
                                activeTab: this.state.activeTab,
                                fro: 'inboxlist',
                              },
                            }}
                            className="btn btn-primary"
                          >
                            <i>+</i> Message
                          </Link>
                        </div>
                      )}
                      <MessageList
                        list={
                          messages && messages[filter] !== undefined
                            ? messages[filter]
                            : []
                        }
                        dataLoaded={dataLoaded}
                        filter={this.state.filter}
                        activeTab={this.state.activeTab}
                        selectedAll={this.state.selectedAll}
                        checkboxAction={this.checkboxAction}
                        handleSelectAll={this.handleSelectAll}
                        pagination={this.props.message_page}
                        name="inboxlist"
                        togglePinUnpin={this.togglePinUnpin}
                        readMessageThread={this.readMessageThread}
                        getFilteredRecords={this.getFilteredRecords}
                      />
                      <Pagination
                        currentPage={
                          lst.length >= 1 ? this.props.message_page : 0
                        }
                        totalPages={totalpages}
                        totalRecords={totalrecords}
                        offset={totalrecords === 0 ? offset - 1 : offset}
                        off={off}
                        handlePageChange={this.handlePageChange}
                      />
                    </div>
                  )}
                </section>
              )}
            </div>
          </div>
          {match.pathname.includes('/inbox') &&
            this.props.notificationAvailable && (
              <div className="chat-Message">
                <div className="Chat-Txt">
                  <img
                    src={liscioSmallest}
                    alt="Avatar"
                    className="avatar chat-Img"
                  />
                  <h2>New Message on this thread.</h2>
                </div>
                <div className="Chat-show">
                  <Button
                    onClick={this.handleForceUpdate}
                    className="btn-link btn--onlyicon btn btn-secondary cross-btn"
                  >
                    Show
                  </Button>
                  <span
                    onClick={() => {
                      this.props.actions.receivedNotification(false);
                    }}
                  >
                    <i className="icon-close2" aria-hidden="true" />
                  </span>
                </div>
              </div>
            )}
        </div>
        <ConfirmationModal
          isOpen={confirmArchive}
          noBtnClick={() => this.setState({ confirmArchive: false })}
          headerText="You are about to archive these threads."
          messageText="This action cannot be undone. Proceed?"
          yesBtnClick={() => this.massArchive(true)}
          yesBtnText="Yes"
          noBtnText="No"
          testid="archive"
        />
        <ConfirmationModal
          isOpen={deleteViewModal}
          className="customModal customModal--w350 customModal--nopadding"
          noBtnClick={() => this.setState({ deleteViewModal: false })}
          headerText="You are about to delete this selected view."
          messageText="This action cannot be undone. Proceed?"
          yesBtnClick={() => this.handleEdit('delete_confirmed')}
          yesBtnText="Yes"
          noBtnText="No"
          testid="delete"
        />
        {/*
        <ConfirmationModal
          isOpen={confirmArchive && !selectedCheckbox.length}
          noBtnClick={() => this.setState({ confirmArchive: false })}
          headerText="No Records Selected"
          yesBtnClick={() => this.setState({ confirmArchive: false })}
          yesBtnText="OK"
        /> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  messages: state.messageReducer.allMessages,
  notificationAvailable: state.messageReducer.notificationAvailable,
  message_page: state.paginationReducer.message_page,
  msgFilters: state.messageReducer.msgFilters,
});

const mapDispatch = (dispatch) => ({
  pagination: bindActionCreators(paginationActions, dispatch),
  actions: bindActionCreators(actions, dispatch),
});

export default withStorageData(
  withRouter(connect(mapStateToProps, mapDispatch)(InboxList)),
);
