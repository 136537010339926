import { useQuery } from '@tanstack/react-query';
import { accountSearchByKeyword } from 'api/accounts';

export function useAccountSearchByKeyword(keyword: string) {
  return useQuery({
    queryKey: ['accountSearch', keyword],
    queryFn: () => accountSearchByKeyword(keyword),
    enabled: keyword.length > 0,
  });
}
