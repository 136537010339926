import React, { Component } from 'react';
import { isUserEmployee, getCpaPreferences } from 'utilities/utils';
import LoaderDefault from 'common/LoaderDefault';
import { withStorageData } from 'utilities/withStorageData';
import EmpFilePopup from './EmpFilePopup';
import ClientFilePopup from './ClientFilePopup';

class FilePopup extends Component {
  constructor(props) {
    super(props);
    this.state = { preferences: {} };
  }

  UNSAFE_componentWillMount() {
    const preferences = getCpaPreferences();
    this.setState({ preferences });
  }

  render() {
    if (isUserEmployee()) {
      return (
        <EmpFilePopup
          preferences={this.state.preferences}
          closeModal={this.props.closeModal}
          shareFile={this.props.shareFile}
          sharedFileDetails={
            this.props.shareFile ? this.props.sharedFileDetails : null
          }
          shareType={this.props.shareType}
          popupType={this.props.popupType}
          saveRecipient={this.props.saveRecipient}
          uploadType="manual"
          location={this.props.location}
          match={this.props.match}
          params={this.props.params}
          navigate={this.props.navigate}
          addToVaultFileDetails={
            this.props.addToVaultFileDetails
              ? this.props.addToVaultFileDetails
              : null
          }
          addToVault={this.props.addToVault}
          viewContext={this.props.viewContext}
        />
      );
    }
    return (
      <ClientFilePopup
        preferences={this.state.preferences}
        closeModal={this.props.closeModal}
        shareFile={this.props.shareFile}
        sharedFileDetails={
          this.props.shareFile ? this.props.sharedFileDetails : null
        }
        shareType={this.props.shareType}
        popupType={this.props.popupType}
        openModal
        uploadType="manual"
        params={this.props.params}
        navigate={this.props.navigate}
        viewContext={this.props.viewContext}
      />
    );
  }
}

export default withStorageData(FilePopup, <LoaderDefault />);
