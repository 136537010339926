/* eslint-disable react/no-string-refs */
/* eslint-disable */
import React from 'react';
import {
  EditorState,
  convertToRaw,
  convertFromRaw,
  CompositeDecorator,
  RichUtils,
  Modifier,
  AtomicBlockUtils,
} from 'draft-js';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import Editor, { composeDecorators } from '@draft-js-plugins/editor';
import createImagePlugin from '@draft-js-plugins/image';
import createAlignmentPlugin from '@draft-js-plugins/alignment';
import createFocusPlugin from '@draft-js-plugins/focus';
import createResizeablePlugin from '@draft-js-plugins/resizeable';
import createBlockDndPlugin from '@draft-js-plugins/drag-n-drop';
import { FileDrop } from 'react-file-drop';
import Axios from 'axios';
import '@draft-js-plugins/alignment/lib/plugin.css';
import { IMAGES } from 'constants/constants';
import {
  colorStyleMap,
  bgColorStyleMap,
  heightMap,
  DraftEditorText,
  generateUniqueHash,
} from './NotesUtilities';
import InputField from './InputField';
import StyleButton from './StyleButton';
import './DraftEditor.scss';

const docsSoap = require('docs-soap').default;

const focusPlugin = createFocusPlugin();
const resizeablePlugin = createResizeablePlugin();
const blockDndPlugin = createBlockDndPlugin();
const alignmentPlugin = createAlignmentPlugin();
const { AlignmentTool } = alignmentPlugin;

const imagedecorator = composeDecorators(
  resizeablePlugin.decorator,
  alignmentPlugin.decorator,
  focusPlugin.decorator,
  blockDndPlugin.decorator,
);

const imagePlugin = createImagePlugin({
  decorator: imagedecorator,
});

const plugins = [
  blockDndPlugin,
  focusPlugin,
  alignmentPlugin,
  resizeablePlugin,
  imagePlugin,
];

const COLORS = [
  {
    id: 1,
    label: 'White',
    style: 'white',
    BgColor: 'whiteBackground',
  },
  {
    id: 2,
    label: 'Black',
    style: 'black',
    BgColor: 'blackBackground',
  },
  {
    id: 3,
    label: 'Red',
    style: 'red',
    BgColor: 'redBackground',
  },
  {
    id: 4,
    label: 'Orange',
    style: 'orange',
    BgColor: 'orangeBackground',
  },
  {
    id: 5,
    label: 'Yellow',
    style: 'yellow',
    BgColor: 'yellowBackground',
  },
  {
    id: 6,
    label: 'Green',
    style: 'green',
    BgColor: 'greenBackground',
  },
  {
    id: 7,
    label: 'Blue',
    style: 'blue',
    BgColor: 'blueBackground',
  },
  {
    id: 8,
    label: 'Indigo',
    style: 'indigo',
    BgColor: 'indigoBackground',
  },
  {
    id: 9,
    label: 'Violet',
    style: 'violet',
    BgColor: 'violetBackground',
  },
];

const HEIGHTS = [
  {
    id: 1,
    label: '1',
    style: '1',
    Height: 'first',
  },
  {
    id: 2,
    label: '1.15',
    style: '1.15',
    Height: 'second',
  },
  {
    id: 3,
    label: '1.5',
    style: '1.5',
    Height: 'third',
  },
  {
    id: 4,
    label: '2',
    style: '2',
    Height: 'fourth',
  },
];

const getBlockStyle = (block) => {
  if (block.getType() === 'align-left') {
    return 'align-left';
  }
  if (block.getType() === 'align-right') {
    return 'align-right';
  }
  if (block.getType() === 'align-center') {
    return 'align-center';
  }
  if (block.getType() === 'align-justify') {
    return 'align-justify';
  }
};

const findLinkEntities = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === 'LINK'
    );
  }, callback);
};

const Link = (props) => {
  const { url } = props.contentState.getEntity(props.entityKey).getData();
  return (
    <a href={url} target="_blank" rel="noreferrer">
      {props.children}
    </a>
  );
};

const decorator = new CompositeDecorator([
  {
    strategy: findLinkEntities,
    component: Link,
  },
]);

const getSelectedText = (editorState) => {
  const selection = editorState.getSelection();
  const anchorKey = selection.getAnchorKey();
  const currentContent = editorState.getCurrentContent();
  const currentContentBlock = currentContent.getBlockForKey(anchorKey);
  const start = selection.getStartOffset();
  const end = selection.getEndOffset();
  const selectedText = currentContentBlock.getText().slice(start, end);
  return selectedText;
};
class DraftEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: EditorState.createEmpty(decorator),
      frame: undefined,
      showImageUrl: false,
      imageUrls: [],
      noOfImages: 0,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyCommand = this.handleKeyCommand.bind(this);
    this.toggleBlockType = this.toggleBlockType.bind(this);
    this.toggleInlineStyle = this.toggleInlineStyle.bind(this);
    this.toggleColor = this.toggleColor.bind(this);
    this.toggleHeight = this.toggleHeight.bind(this);
    this.showDropDown = this.showDropDown.bind(this);
    this.showLineDropDown = this.showLineDropDown.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.toggleAlignments = this.toggleAlignments.bind(this);
    this.onURLChange = (value) =>
      this.setState({
        urlValue: value,
      });
    this.onSelectedTextChange = (value) =>
      this.setState({
        selectedText: value,
      });
    this.focus = () => this.refs.editor.focus();
  }

  componentDidMount() {
    const frame = document.getElementById('DraftEditorImageDropDown');
    this.setState({
      frame,
    });
    const { comment } = this.props;
    if (comment && (comment.length || comment.blocks)) {
      if (typeof comment !== 'object') {
        this.setState({
          editorState: EditorState.createWithContent(
            DraftEditorText(comment, convertFromRaw),
            decorator,
          ),
          // editorState: EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(this.props.comment))),
        });
        if (this.props.emailContent) {
          this.handlePastedText('', this.props.emailContent);
        }
      } else if (typeof comment === 'object') {
        this.setState({
          editorState: EditorState.createWithContent(convertFromRaw(comment)),
        });
      }
    } else {
      this.setState({
        editorState: EditorState.createEmpty(decorator),
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      (this.props.comment === '' && nextProps.comment !== '') ||
      (this.props.uniqueId && this.props.uniqueId !== nextProps.uniqueId)
    ) {
      if (nextProps.comment) {
        if (typeof nextProps.comment === 'object') {
          this.setState({
            editorState: EditorState.createWithContent(
              convertFromRaw(nextProps.comment),
            ),
          });
        } else if (typeof nextProps.comment !== 'object') {
          this.setState({
            editorState: EditorState.createWithContent(
              DraftEditorText(nextProps.comment, convertFromRaw),
              decorator,
            ),
            // editorState: EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(nextProps.comment))),
          });
        }
      } else if (!nextProps.comment) {
        this.setState({
          editorState: EditorState.createEmpty(decorator),
        });
      }
    } else if (
      (this.props.comment &&
        typeof this.props.comment === 'object' &&
        nextProps.comment !== '' &&
        typeof nextProps.comment !== 'object') ||
      (this.props.comment &&
        nextProps.comment &&
        typeof this.props.comment !== 'object' &&
        typeof nextProps.comment !== 'object')
    ) {
      this.setState({
        editorState: EditorState.createWithContent(
          DraftEditorText(nextProps.comment, convertFromRaw),
          decorator,
        ),
      });
    } else if (this.props.comment && !nextProps.comment) {
      this.setState({
        editorState: EditorState.createEmpty(decorator),
      });
    }
  }

  onLinkInputKeyDown = (e) => {
    if (e.which === 13) {
      this.confirmLink(e);
    }
  };

  handleKeyCommand(command) {
    const newState = RichUtils.handleKeyCommand(
      this.state.editorState,
      command,
    );
    if (newState) {
      this.handleChange(newState);
      return 'handled';
    }
    return 'not-handled';
  }

  toggleBlockType(blockType) {
    this.showDropDown(false);
    this.showLineDropDown(false);
    this.handleChange(
      RichUtils.toggleBlockType(this.state.editorState, blockType),
    );
  }

  toggleInlineStyle(inlineStyle) {
    this.showDropDown(false);
    this.showLineDropDown(false);
    this.handleChange(
      RichUtils.toggleInlineStyle(this.state.editorState, inlineStyle),
    );
  }

  toggleAlignments(align) {
    this.showDropDown(false);
    this.showLineDropDown(false);
    this.handleChange(RichUtils.toggleBlockType(this.state.editorState, align));
  }

  handleFocus() {
    this.showDropDown(false);
    this.showLineDropDown(false);
    const selectionState = this.state.editorState.getSelection();
    let start = selectionState.getStartOffset();
    const end = selectionState.getEndOffset();
    start = end;
  }

  handleBlur() {
    this.showDropDown(false);
    // this.showLineDropDown(false);
    if (this.props.handleBlurDraftValidation) {
      this.props.handleBlurDraftValidation();
    }
  }

  showDropDown(input) {
    this.setState({
      showDropDown: input,
    });
  }

  showLineDropDown(input) {
    this.setState({
      showLineDropDown: input,
    });
  }

  toggleColor(type, toggledColor, e) {
    e.preventDefault();
    const { editorState } = this.state;
    const selection = this.state.editorState.getSelection();
    let nextContentState = false;
    if (type === 'color') {
      nextContentState = Object.keys(colorStyleMap).reduce(
        (contentState, color) =>
          Modifier.removeInlineStyle(contentState, selection, color),
        editorState.getCurrentContent(),
      );
    } else {
      nextContentState = Object.keys(bgColorStyleMap).reduce(
        (contentState, color) =>
          Modifier.removeInlineStyle(contentState, selection, color),
        editorState.getCurrentContent(),
      );
    }
    let nextEditorState = EditorState.push(
      editorState,
      nextContentState,
      'change-inline-style',
    );
    const currentStyle = editorState.getCurrentInlineStyle();
    if (selection.isCollapsed()) {
      nextEditorState = currentStyle.reduce(
        (state, color) => RichUtils.toggleInlineStyle(state, color),
        nextEditorState,
      );
    }
    if (!currentStyle.has(toggledColor)) {
      nextEditorState = RichUtils.toggleInlineStyle(
        nextEditorState,
        toggledColor,
      );
    }
    this.setState({
      editorState: nextEditorState,
      showDropDown: false,
      showLineDropDown: false,
    });
  }

  focus = () => {
    this.editor.focus();
  };

  toggleHeight(type, toggledHeight) {
    const { editorState } = this.state;
    const selection = this.state.editorState.getSelection();
    let nextContentState = false;
    if (type === 'height') {
      nextContentState = Object.keys(heightMap).reduce(
        (contentState, height) =>
          Modifier.removeInlineStyle(contentState, selection, height),
        editorState.getCurrentContent(),
      );
    }
    let nextEditorState = EditorState.push(
      editorState,
      nextContentState,
      'change-inline-style',
    );
    const currentStyle = editorState.getCurrentInlineStyle();
    if (selection.isCollapsed()) {
      nextEditorState = currentStyle.reduce(
        (state, height) => RichUtils.toggleInlineStyle(state, height),
        nextEditorState,
      );
    }
    if (!currentStyle.has(toggledHeight)) {
      nextEditorState = RichUtils.toggleInlineStyle(
        nextEditorState,
        toggledHeight,
      );
    }
    this.setState({
      editorState: nextEditorState,
      showDropDown: false,
      showLineDropDown: false,
    });
  }

  // handleChange(value) {
  //   // console.log('draft change');
  //   this.setState({ editorState: value, loading: false }, () => {
  //     const text = this.state.editorState.getCurrentContent();
  //     if (this.props.handleCommentChange) {
  //       this.props.handleCommentChange(convertToRaw(text));
  //     }
  //   });
  // }
  handleChange(value) {
    // console.log('draft change');
    const { editorState } = this.state;
    const currentContentTextLength = editorState
      .getCurrentContent()
      .getPlainText().length;
    const newContentTextLength = value
      .getCurrentContent()
      .getPlainText().length;
    const rawData = convertToRaw(value.getCurrentContent());
    this.setState(
      {
        editorState:
          currentContentTextLength === 0 && newContentTextLength === 1
            ? EditorState.moveFocusToEnd(value)
            : value,
        loading: false,
      },
      () => {
        const text = this.state.editorState.getCurrentContent();
        if (
          this.props.handleCommentChange &&
          currentContentTextLength !== newContentTextLength
        ) {
          this.props.handleCommentChange(convertToRaw(text));
        }
      },
    );
  }

  handlePastedText = (text, html) => {
    if (html) {
      const convert = docsSoap(html);
      const { editorState } = this.state;
      const { blockMap } = DraftEditorText(convert, convertFromRaw, false);
      const newState = Modifier.replaceWithFragment(
        editorState.getCurrentContent(),
        editorState.getSelection(),
        blockMap,
      );
      this.setState(
        {
          editorState: EditorState.push(
            editorState,
            newState,
            'insert-fragment',
          ),
        },
        () => {
          const content = this.state.editorState.getCurrentContent();
          this.props.handleCommentChange(convertToRaw(content));
        },
      );
      return 'handled';
    }
  };

  handlePastedFiles = async (files) => {
    this.handleFileDrop(files);
  };

  promptForLink = (e) => {
    e.preventDefault();
    const { editorState } = this.state;
    const contentState = editorState.getCurrentContent();
    const startKey = editorState.getSelection().getStartKey();
    const startOffset = editorState.getSelection().getStartOffset();
    const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
    const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);
    let url = '';
    if (linkKey) {
      const linkInstance = contentState.getEntity(linkKey);
      url = linkInstance.getData().url;
    }
    this.setState({
      showURLInput: true,
      urlValue: url,
      selectedText: getSelectedText(editorState),
    });
  };

  confirmLink = (e) => {
    e.preventDefault();
    let { editorState, selectedText } = this.state;
    let { urlValue } = this.state;
    let contentState = editorState.getCurrentContent();
    const selection = editorState.getSelection();
    const start = selection.getStartOffset();
    if ((selectedText === undefined || !selectedText.length) && urlValue) {
      selectedText = urlValue;
    }
    const newContentState = Modifier.replaceText(
      contentState,
      selection,
      selectedText,
    );
    editorState = EditorState.push(
      editorState,
      newContentState,
      'insert-characters',
    );
    // we update selection incase user changes the value selected text(display text)
    let updatedSelection = selection.set(
      'focusOffset',
      start + selectedText.length,
    );
    updatedSelection = updatedSelection.set('anchorOffset', start);
    updatedSelection = updatedSelection.set('isBackward', false);
    updatedSelection = updatedSelection.set('hasFocus', true);
    if (
      urlValue &&
      urlValue !== null &&
      urlValue.length &&
      urlValue.indexOf('http') !== 0
    ) {
      urlValue = `http://${urlValue}`;
    }
    contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      'LINK',
      'MUTABLE',
      {
        url: urlValue,
      },
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity,
    });
    const updatedEditorState = RichUtils.toggleLink(
      newEditorState,
      updatedSelection,
      urlValue !== undefined && undefined !== null && urlValue.length
        ? entityKey
        : null,
    );
    this.handleChange(
      EditorState.forceSelection(
        updatedEditorState,
        contentState.getSelectionAfter(),
      ),
    );
    this.setState({
      showURLInput: false,
      urlValue: '',
      selectedText,
    });
  };

  removeLink = (e) => {
    e.preventDefault();
    const { editorState } = this.state;
    const selection = editorState.getSelection();
    if (!selection.isCollapsed()) {
      this.setState({
        editorState: RichUtils.toggleLink(editorState, selection, null),
      });
    }
  };

  closeurlLinkModal = () => {
    this.setState({
      showURLInput: false,
    });
  };

  handleFileDrop = (input) => {
    if (
      input &&
      Object.keys(input).length &&
      this.props.handleImageUploadStatus
    ) {
      const files = [];
      Object.keys(input).forEach((key) => {
        if (
          input[key].type === 'image/png' ||
          input[key].type === 'image/jpeg'
        ) {
          files.push(input[key]);
        }
      });
      if (files.length) {
        this.props.handleImageUploadStatus(true);
        this.setState({
          // loading: true,
          showImageUrl: false,
        });
        this.setState({
          noOfImages: this.state.noOfImages + files.length,
        });
        for (let i = 0; i < files.length; i += 1) {
          this.uploadImage(files[i]);
        }
      }
    }
  };

  uploadImage = async (input) => {
    try {
      const hash = generateUniqueHash(10);
      const reader = new FileReader();
      reader.onloadend = () => {
        const imageBaseUrl = reader.result;
        const newEditorState = this.insertImage(
          this.state.editorState,
          imageBaseUrl,
          hash,
          input.name,
        );
        this.handleChange(newEditorState);
        // console.log('RESULT', imageBaseUrl);
      };
      reader.readAsDataURL(input);
      const data = new FormData();
      data.append('image', input);
      data.append('record_type', 'Message');
      const imageData = await Axios.post(IMAGES, data)
        .then((res) => res.data)
        .catch((err) => err.response);
      if (imageData && imageData.data && imageData.data.url) {
        const { imageUrls, noOfImages } = this.state;
        imageUrls.push({
          hash,
          url: imageData.data.url,
          id: imageData.data.id,
        });
        this.setState(
          {
            imageUrls,
          },
          () => {
            if (noOfImages === imageUrls.length) {
              this.props.handleImageUrls(this.state.imageUrls);
              this.props.handleImageUploadStatus(false);
            }
          },
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  insertImage = (editorState, base64, hash, alt) => {
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      'image',
      'IMMUTABLE',
      {
        src: base64,
        hash,
        alt,
      },
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity,
    });
    return AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' ');
  };

  promptForImage = () => {
    this.setState({
      showImageUrl: !this.state.showImageUrl,
    });
  };

  render() {
    const { showImageUrl, loading, frame, imageUrls, noOfImages } = this.state;
    return (
      <FileDrop
        frame={frame}
        onDrop={this.handleFileDrop}
        className="dragBrowse"
        id="Attachments"
      >
        <div
          className={`DraftEditor DragDropEditor ${
            this.props.notDisaplayTools ? 'noborder scrlOption' : ''
          } ${this.props.disabled ? 'disabled' : ''}`}
          id="DraftEditorImageDropDown"
          style={{
            overflow: 'hidden',
          }}
        >
          {loading ? <div id="loading" /> : null}
          {this.state.showURLInput && (
            <UrlInputModal
              onURLChange={this.onURLChange}
              urlValue={this.state.urlValue}
              onKeyDown={this.onLinkInputKeyDown}
              confirmLink={this.confirmLink}
              noBtnClick={this.closeurlLinkModal}
              selectedText={this.state.selectedText}
              onSelectedTextChange={this.onSelectedTextChange}
            />
          )}
          {showImageUrl && (
            <ImageInputModal
              handleFileDrop={this.handleFileDrop}
              confirmLink={this.confirmLink}
              noBtnClick={this.promptForImage}
            />
          )}
          <Editor
            customStyleMap={{
              ...colorStyleMap,
              ...bgColorStyleMap,
              ...heightMap,
            }}
            plugins={plugins}
            editorState={this.state.editorState}
            onChange={this.handleChange}
            handleKeyCommand={this.handleKeyCommand}
            handlePastedText={this.handlePastedText}
            handlePastedFiles={this.handlePastedFiles}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            spellCheck="true"
            blockStyleFn={getBlockStyle}
            readOnly={this.props.readOnly}
            ref="editor"
          />
          <AlignmentTool />
          {this.props.notDisaplayTools ? null : (
            <div className="Editor-controls">
              <InlineStyleControls
                editorState={this.state.editorState}
                onToggle={this.toggleInlineStyle}
              />
              <div className="highlight-btn">
                <StyleButton
                  role="presentation"
                  styleName="RichEditor-styleButton icon-format_color_text"
                  onToggle={() => this.showDropDown('color')}
                />
                <div className="highlight-btn-dropdown right">
                  <div
                    className={
                      this.state.showDropDown === 'color'
                        ? 'colorbox'
                        : 'hidden'
                    }
                  >
                    {COLORS.map((each) => (
                      <span
                        key={each.id}
                        role="presentation"
                        style={bgColorStyleMap[each.BgColor]}
                        onMouseDown={(e) =>
                          this.toggleColor('color', each.style, e)
                        }
                      />
                    ))}
                  </div>
                </div>
              </div>
              <BlockStyleControls
                editorState={this.state.editorState}
                onToggle={this.toggleBlockType}
              />
              <AlignmentsControls
                editorState={this.state.editorState}
                onToggle={this.toggleAlignments}
              />
              <div className="highlight-btn">
                <span
                  role="presentation"
                  className="RichEditor-styleButton font-hightlight icon-format_line_spacing"
                  onMouseDown={() => this.showLineDropDown('height')}
                />
                <div className="highlight-btn-dropdown right">
                  <div
                    className={
                      this.state.showLineDropDown === 'height'
                        ? 'heightbox'
                        : 'hidden'
                    }
                  >
                    {HEIGHTS.map((e) => (
                      <span
                        key={e.id}
                        role="presentation"
                        style={heightMap[e.Height]}
                        onMouseDown={() => this.toggleHeight('height', e.style)}
                      >
                        {e.label}
                      </span>
                    ))}
                  </div>
                </div>
              </div>
              <div className="highlight-btn">
                <span
                  role="presentation"
                  className="RichEditor-styleButton icon-font_download"
                  onMouseDown={(e) =>
                    this.toggleColor('backgroundColor', 'yellowBackground', e)
                  }
                />
                <span
                  role="presentation"
                  className="RichEditor-styleButton icon-insert_link"
                  onMouseDown={this.promptForLink}
                />
              </div>
              {this.props.handleImageUploadStatus && (
                <div className="highlight-btn">
                  <input
                    type="file"
                    className="hidden"
                    id="inputGroupFile01"
                    onChange={(input) =>
                      this.handleFileDrop(input.target.files)
                    }
                    multiple
                    accept="image/*"
                    aria-describedby="inputGroupFileAddon01"
                  />
                  <label htmlFor="inputGroupFile01" className="m-0">
                    <span
                      role="presentation"
                      className="RichEditor-styleButton icon-image-preview"
                    />
                  </label>
                </div>
              )}
            </div>
          )}
          <input id="draftInput" className="form-control" />
        </div>
      </FileDrop>
    );
  }
}

const BLOCK_TYPES = [
  {
    id: 1,
    label: ' ',
    style: 'unordered-list-item',
    className: '',
  },
  {
    id: 2,
    label: '',
    style: 'ordered-list-item',
    className: '',
  },
];

const BlockStyleControls = (props) => {
  const { editorState } = props;
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  return (
    <div className="RichEditor-controls">
      {BLOCK_TYPES.map((type) => (
        <StyleButton
          key={type.id}
          active={type.style === blockType}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
          styleName={type.className}
        />
      ))}
    </div>
  );
};

const INLINE_STYLES = [
  {
    id: 1,
    label: <span className="icon-format_bold" />,
    style: 'BOLD',
    className: '',
  },
  {
    id: 2,
    label: <span className="icon-format_italic" />,
    style: 'ITALIC',
    className: '',
  },
  {
    id: 3,
    label: <span className="icon-format_underlined" />,
    style: 'UNDERLINE',
    className: '',
  },
];

const InlineStyleControls = (props) => {
  const currentStyle = props.editorState.getCurrentInlineStyle();
  return (
    <div className="RichEditor-controls">
      {INLINE_STYLES.map((type) => (
        <StyleButton
          key={type.id}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
          styleName={type.className}
        />
      ))}
    </div>
  );
};

const ALIGNMENTS = [
  {
    id: 1,
    label: '',
    className: 'DraftEditor-alignLeft',
    style: 'align-left',
  },
  {
    id: 2,
    label: '',
    className: 'DraftEditor-alignLeft',
    style: 'align-center',
  },
  {
    id: 3,
    label: '',
    className: 'DraftEditor-alignLeft',
    style: 'align-right',
  },
  {
    id: 4,
    label: '',
    className: 'DraftEditor-alignLeft',
    style: 'align-justify',
  },
];

const AlignmentsControls = (props) => {
  const { editorState } = props;
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();
  return (
    <div className="RichEditor-controls">
      {ALIGNMENTS.map((type) => (
        <StyleButton
          key={type.id}
          active={type.style === blockType}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
          styleName={type.className}
        />
      ))}
    </div>
  );
};

const UrlInputModal = (props) => (
  <Modal
    isOpen
    className={
      props.className
        ? props.className
        : 'customModal customModal--center customModal--alert customModal--w500 customModal--nopadding'
    }
  >
    <div className="ModalHeader">
      <button
        type="button"
        className="close"
        aria-label="Close"
        onClick={props.noBtnClick}
      >
        <i className="icon-close2" aria-hidden="true" />
      </button>
    </div>
    <ModalHeader className="modalHeader modalContent--inner">
      <div className="modalContent--header text-large pl-4 pb-0">
        <h5 className="modalContent--header__heading">Add Link</h5>
      </div>
    </ModalHeader>
    <ModalBody className="modalContent">
      <div className="modalContent--inner">
        <InputField
          className=" "
          name="displayas"
          label="Display Text"
          onChange={props.onSelectedTextChange}
          value={props.selectedText}
          onKeyDown={props.onKeyDown}
        />
        <InputField
          className=" "
          name="urlvalue"
          label="URL"
          onChange={props.onURLChange}
          value={props.urlValue}
          onKeyDown={props.onKeyDown}
        />
        <div className="form-group formbtn justify-content-end btn--leftspace mt-2 mb-1">
          <div className="btn-wrap btn--leftspace">
            <button
              onClick={props.confirmLink}
              className="btn btn-primary task-form-submit"
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </ModalBody>
  </Modal>
);

const ImageInputModal = (props) => (
  <Modal
    isOpen
    className={
      props.className
        ? props.className
        : 'customModal customModal--center customModal--alert customModal--w500 customModal--nopadding'
    }
  >
    <div className="ModalHeader">
      <button
        type="button"
        className="close"
        aria-label="Close"
        onClick={props.noBtnClick}
      >
        <i className="icon-close2" aria-hidden="true" />
      </button>
    </div>
    <ModalHeader className="modalHeader modalContent--inner">
      <div className="modalContent--header text-large pl-4 pb-0">
        <h5 className="modalContent--header__heading">Add Image</h5>
      </div>
    </ModalHeader>
    <ModalBody className="modalContent">
      <div className="modalContent--inner">
        <input
          className=" "
          type="file"
          name="imageupload"
          onChange={(input) => props.handleFileDrop(input.target.files)}
        />
      </div>
    </ModalBody>
  </Modal>
);

export default DraftEditor;
