/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-script-url */
/* eslint-disable react/jsx-no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable class-methods-use-this */
/* eslint-disable consistent-return */
/* eslint-disable no-prototype-builtins */
/* eslint-disable react/no-unused-class-component-methods */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-state */
// TODO: check react-s-alert/
import React from 'react';
import {
  Form,
  FormGroup,
  DropdownMenu,
  Input,
  Label,
  Button,
} from 'reactstrap';
import ReactTooltip from 'react-tooltip';
import styled from '@emotion/styled';
import EmailSendButton from 'common/Button/Button';
import Select, { components } from 'react-select';
import { EditorState, convertFromRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import Axios from 'axios';
// import Alert from 'react-s-alert';
import TooltipItem from 'common/TooltipItem_updated';
import {
  options,
  removeBreakElementinList,
  generateUniqueHash,
} from 'common/NotesUtilities';
import {
  AlertMessage,
  generateInitials,
  getStorageData,
} from 'utilities/utils';
import {
  SEARCH_TASK_CONTACTS,
  SEARCH_TASK_ACCOUNTS,
  MESSAGES_API_PATH,
  GET_TEMPLATES_FOR_CREATION,
  DISCARD_DRAFT,
  CREATE_DOC_FROM_TEMPLATE_IDS,
  MIXPANEL_MESSAGE_EVENT,
  USER_EMAILS,
  BUTTON_ICON_LOCATION_BACK,
} from 'constants/constants';
import debounce from 'lodash/debounce';
import * as MessageApi from 'api/MessageApi';
import ConfirmationModal from 'common/ConfirmationModal';
import {
  // OptionBoxSelectFieldWithAvatar,
  CheckboxField,
  SelectTemplateFieldOption,
  selectStyles,
  selectTheme,
} from 'common/CustomFields';
import TaskFileUpload from 'components/Files/TaskFileUpload';
import TinymceEditor from 'components/Emails/components/TinymceEditior';
import { trackMixpanelEvent } from 'utilities/mixpanelfn';
import { formatEmailAddresses } from 'components/Emails/EmailUtilities';
import {
  groupEmpAndContacts,
  getContactsOfTask,
  getAccounts,
  AccountsOfContact,
  getContEmpOfAccount,
  getRelConAndCstOfAccounts,
} from 'api/TaskApi';
import { getFormattedEditorDescription } from 'components/Email/EmailUtilities';
import getInlineImages from 'components/Email/inline-image-utils/get-inline-images';
import DiscardEmail from 'components/Email/components/icons/DiscardEmail';
import NotifyViaTextIcon from 'components/Email/components/icons/NotifyViaTextIcon';
import RecipientAndAccountDropdowns from './RecipientAndAccountDropdowns';
import './message.scss';
import RenderTooltip from './MessageUtilities/RenderTooltip';
import { getInitialComment } from './MessageUtilities/getInitialComment';

const StyledTooltip = styled.div`
  /* background-color: #919eab; */
  display: block;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-transform: none;
  text-align: center;
  .Tooltip__Name {
    color: white;
  }
`;

class NewMessage extends React.Component {
  constructor(props) {
    super(props);
    this.initialComment = getInitialComment(this.props?.emailDetails?.content);
    this.state = {
      comment: this.initialComment,
      documents: [],
      contacts: [],
      accounts: [],
      allContacts: [],
      allAccounts: [],
      subject: this.props.emailDetails ? this.props.emailDetails.subject : '',
      accountId: '',
      contactId: '',
      contactName: '',
      accountName: '',
      recipientType: '',
      selectedRecords: [],
      dropdownOpen: false,
      assigneeType: '',
      messageId: '',
      messageThreadId: '',
      errors: {},
      draftStatus: '',
      contatIdPopup: '',
      type: 'message',
      mounted: false,
      notifyViaText: false,
      apiHitting: false,
      uploadingImages: false,
      sendMessageAfterImageUpload: false,
      initialComment: {},
      selectedOption: null,
      uniqueId: generateUniqueHash(5),
      templateOptions: [],
      templateDocuments: [],
      warningMsgLost: false,
      selectedTemplate: null,
      titleChange: '',
      viewContext: this.props.viewContext,
      emailId: this.props.viewContext
        ? this.props.emailDetails.email_id
        : undefined,
      // imageUrls: [],
      // noOfImages: 0,
      groupedOptions: [],
      isLoading: true,
      isUserTyping: false,
      hasMobile: false,
      // onHoverShowMobileStatus: '',
    };
    this.handleCommentChange = this.handleCommentChange.bind(this);
    this.templateDropdown = this.templateDropdown.bind(this);
    this.addSelectedInput = this.addSelectedInput.bind(this);
    this.handleAccountChange = this.handleAccountChange.bind(this);
    this.handleContactChange = this.handleContactChange.bind(this);
    this.handleContactInput = this.handleContactInput.bind(this);
    this.handleAccountInput = this.handleAccountInput.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.discardDraft = this.discardDraft.bind(this);
    this.handleValidation = this.handleValidation.bind(this);
    this.selectInputRef = React.createRef();
  }

  UNSAFE_componentWillMount() {
    this.timer = null;
  }

  componentDidMount() {
    document.title = 'Create Message';
    // TODO: __HOMEDATA-AUDIT
    const homeDataObj = getStorageData();
    getContactsOfTask(this, { limit: true, message: true }, 'notes');
    if (homeDataObj !== null && Object.keys(homeDataObj).length) {
      if (homeDataObj.is_employee) {
        getAccounts(this, 'notes');
        this.setState({ mounted: true });
      } else {
        AccountsOfContact(this, homeDataObj.contact_id, 'clientmessage');
        this.setState({ mounted: true });
      }
    }
    if (homeDataObj.is_employee) {
      this.getTemplates();
    }
    window.addEventListener('keypress', (e) => {
      if (e && e.which === 13) {
        e.preventDefault();
      }
    });
    if (this.props.viewContext) {
      this.getEmailAttachments();
      const fromEmail = formatEmailAddresses(
        this.props.emailDetails.from_email,
      );
      if (fromEmail && fromEmail.length) {
        this.handleContactInput(
          encodeURIComponent(fromEmail[0].label),
          'addToRecepient',
        );
      }
    }
    this.setState({ loading: true });
    getInlineImages({
      initialEmailBody: this.state.comment,
      emailId: this.state.emailId,
    }).then(({ newEmailBody }) =>
      this.setState({
        comment: newEmailBody,
        loading: false,
      }),
    );
  }

  UNSAFE_componentWillReceiveProps() {
    // TODO: __HOMEDATA-AUDIT
    const homeDataObj = getStorageData();
    if (!this.state.mounted) {
      if (homeDataObj !== null && Object.keys(homeDataObj).length) {
        if (homeDataObj.is_employee) {
          getAccounts(this, 'notes');
          this.setState({ mounted: true });
        } else {
          AccountsOfContact(this, homeDataObj.contact_id, 'clientmessage');
          this.setState({ mounted: true });
        }
      }
    }
  }

  componentWillUnmount() {
    window.addEventListener('keypress', () => true);
  }

  onFocus() {
    this.setState(
      (prevState) => ({
        dropdownOpen: !prevState.dropdownOpen,
        contacts: prevState.allContacts,
        accounts: prevState.allAccounts,
        groupedOptions: groupEmpAndContacts(prevState.allContacts),
        contactId: '',
        contatIdPopup: '',
        accountId: '',
        contactName: '',
        accountName: '',
        inputVal: '',
        errors: {},
      }),
      () => {
        if (this.state.accounts.length === 1) {
          this.setState({
            accountId: this.state.accounts[0].value,
            accountName: this.state.accounts[0].label,
          });
        }
      },
    );
  }

  getEmailAttachments = async () => {
    if (
      this.props.emailDetails &&
      this.props.emailDetails.documents &&
      this.props.emailDetails.documents.length
    ) {
      this.setState({
        gettingEmailAttachments:
          (this.props.emailDetails.documents &&
            this.props.emailDetails.documents.length) ||
          false,
      });
      try {
        const url = `${USER_EMAILS}/${this.props.emailDetails.email_id}/attachments?for_message_creation=true`;
        const response = await Axios.get(url).then((res) => res.data.data);
        if (response && response.length) {
          response.map((e) => {
            e.isAttachmentFromEmailOrMessage = true;
            return e;
          });
        }
        this.setState({
          documents: response,
          gettingEmailAttachments: false,
        });
      } catch (error) {
        this.setState({ gettingEmailAttachments: false });
        console.log(error);
      }
    }
  };

  getTemplates() {
    // this.setState({ loading: true });
    Axios.get(`${GET_TEMPLATES_FOR_CREATION}?template_type=message_template`)
      .then((res) => {
        this.setState({ loading: false });
        if (res.data && res.data.data && res.data.data.length) {
          const { data, default_preferences } = res.data;
          this.setState({
            templateOptions: data,
            loading: false,
            defaultPreferences: default_preferences,
          });
        } else {
          this.setState({
            templateOptions: [],
            loading: false,
          });
        }
      })
      .catch(() => {
        this.setState({
          templateOptions: [],
          loading: false,
        });
      });
  }

  getTemplateDocsId = () => {
    const mydata = {};
    if (this.state.templateDocuments.length) {
      mydata.template_doc_ids = [];
      let x;
      for (x = 0; x < this.state.templateDocuments.length; x += 1) {
        mydata.template_doc_ids.push(this.state.templateDocuments[x].id);
      }
    }
    if (this.state.templateDocuments.length) {
      this.setState({ loading: true });
      const request = Axios.post(CREATE_DOC_FROM_TEMPLATE_IDS, mydata);
      request
        .then((response) => {
          this.setState({ loading: false });
          if (response.data.status === 200) {
            const documents = [...this.state.documents];
            const doc = documents.length
              ? documents
              : [...this.state.templateDocuments];
            if (doc.length) {
              this.setState(
                { documents: response.data.documents, templateDocuments: [] },
                () => {
                  clearTimeout(this.timer);
                  this.timer = setTimeout(() => {
                    this.saveMessage('Draft');
                  }, 1000);
                },
              );
            }
          } else {
            AlertMessage('error', response.data.message, 1500);
          }
        })
        .catch(() => {
          this.setState({ loading: false });
        });
    }
  };

  inputRenderer = (inputProps) => (
    // delete inputProps.value;
    <components.Input
      {...inputProps}
      value={this.state.titleChange}
      className="Select-input"
    />
  );

  templateDropdown(selectedOption) {
    const { subject, documents, comment, warningMsgLost } = this.state;
    const Comment = this.validateDraftMessage(comment);
    // let CommentInJSON = '';
    // if (Comment && Comment.length) {
    //   CommentInJSON = JSON.stringify(this.state.comment);
    // }
    if (
      (subject || Comment || documents.length) &&
      !warningMsgLost &&
      (!selectedOption || (selectedOption && !subject))
    ) {
      this.setState({ warningMsgLost: true, selectedTemplate: selectedOption });
    } else if (selectedOption && selectedOption !== null) {
      // this.discardDraft();
      // const msgId = encodeURIComponent(this.state.messageId);
      // MessageApi.discardDraft(this, msgId, '', '', false, true);
      if (this.state.messageId) {
        this.deleteMsg(this.state.messageId, selectedOption);
      } else {
        const { content, value } = selectedOption;
        // let coment = '';
        const coment = content;
        this.setState(
          {
            selectedOption,
            subject: value,
            titleChange: '',
            comment: coment,
            uniqueId: generateUniqueHash(5),
            warningMsgLost: false,
            selectedTemplate: null,
            templateDocuments:
              selectedOption.documents && selectedOption.documents.length
                ? selectedOption.documents
                : [],
            documents:
              selectedOption.documents && selectedOption.documents.length
                ? selectedOption.documents
                : [],
          },
          () => {
            // console.log('selectedOption', selectedOption, this.state.templateDocuments);
            // if (selectedOption.documents && selectedOption.documents.length) {
            //   // console.log('1');
            //   this.getTemplateDocsId();
            // } else {
            //   console.log('2');
            //   clearTimeout(this.timer);
            //   this.timer = setTimeout(() => { this.saveMessage('Draft'); }, 500);
            //   // this.saveMessage('Draft');
            // }
          },
        );
      }
    } else {
      // const { templateDocuments } = this.state;
      // const docs = this.state.documents;
      // if (docs && docs.length && templateDocuments && templateDocuments.length) {
      //   const documnts = [];
      //   docs.map((doc) => {
      //     if (doc.id && !_.find(templateDocuments, { id: doc.id })) {
      //       documnts.push(doc);
      //     }
      //     return documnts;
      //   });
      //   this.setState({ documents: documnts, templateDocuments: [] });
      // }
      this.setState(
        {
          selectedOption: null,
          subject: '',
          titleChange: '',
          comment: '',
          uniqueId: generateUniqueHash(5),
          warningMsgLost: false,
          selectedTemplate: null,
          documents: [],
          templateDocuments: [],
        },
        () => {
          const node = this.selectInputRef.current;
          if (node) {
            node.state.inputValue = '';
          }
          clearTimeout(this.timer);
          this.timer = setTimeout(() => {
            this.saveMessage('Draft');
          }, 1000);
          // this.saveMessage('Draft');
        },
      );
    }
  }

  deleteMsg = (messageId, selectedOption) => {
    const msgId = encodeURIComponent(messageId);
    this.setState({ loading: true, apiHitting: true });
    const request = Axios.delete(`${DISCARD_DRAFT}/${msgId}`);
    request.then((response) => {
      if (response.status === 200) {
        const { content, value } = selectedOption;
        // let coment = '';
        const coment = content;
        // if (content_json && content_json !== null) {
        //   coment = JSON.parse(content_json);
        // } else {
        //   coment = removeBreakElement(content);
        // }
        // if (selectedOption.documents && selectedOption.documents.length) {
        //   // const docs = this.state.documents;
        //   // let x;
        //   // this.setState({
        //   //   templateDocuments: selectedOption.documents,
        //   // });
        //   // for (x = 0; x < selectedOption.documents.length; x += 1) {
        //   //   docs.push(selectedOption.documents[x]);
        //   // }
        //   this.setState({
        //     templateDocuments: selectedOption.documents,
        //     documents: selectedOption.documents,
        //   }, () => {
        //     this.getTemplateDocsId();
        //   });
        // } else {
        //   this.setState({
        //     templateDocuments: [],
        //     documents: [],
        //   });
        // }
        this.setState(
          {
            selectedOption,
            subject: value,
            titleChange: '',
            comment: coment,
            uniqueId: generateUniqueHash(5),
            warningMsgLost: false,
            selectedTemplate: null,
            contactId: '',
            contactName: '',
            accountId: '',
            accountName: '',
            uploadFiles: [],
            inputVal: '',
            messageId: '',
            messageThreadId: '',
            templateDocuments:
              selectedOption.documents && selectedOption.documents.length
                ? selectedOption.documents
                : [],
            documents:
              selectedOption.documents && selectedOption.documents.length
                ? selectedOption.documents
                : [],
          },
          () => {
            this.setState({ loading: false, apiHitting: false });
            // if (this.state.templateDocuments && this.state.templateDocuments.length) {
            //   this.getTemplateDocsId();
            // } else {
            //   this.saveMessage('Draft');
            // }
            // this.saveMessage('Draft');
            if (
              this.state.templateDocuments &&
              !this.state.templateDocuments.length
            ) {
              clearTimeout(this.timer);
              this.timer = setTimeout(() => {
                this.saveMessage('Draft');
              }, 1000);
            }
          },
        );
      } else {
        this.setState({
          apiHitting: false,
          loading: false,
        });
      }
    });
  };

  templateChange = (input, action) => {
    const val =
      action.action === 'input-change' ? input : action.prevInputValue;
    if (val || (!val && this.state.titleChange)) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.saveMessage('Draft');
      }, 500);
      this.setState({ subject: val, titleChange: val });
    }
  };

  handleChange(e) {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.saveMessage('Draft');
    }, 500);
    this.setState({ [e.target.name]: e.target.value });
  }

  removeSelectedRecord(contactId, accountId) {
    const selectedRecordsArr = this.state.selectedRecords;
    const index = selectedRecordsArr.findIndex(
      (x) => x.contactId === contactId && x.accountId === accountId,
    );
    if (index > -1) {
      selectedRecordsArr.splice(index, 1);
      this.setState({ selectedRecords: selectedRecordsArr });
    }
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.saveMessage('Draft');
    }, 500);
  }

  handleCommentChange(value) {
    this.setState({ comment: value, errors: {} });
    if (this.state.templateDocuments && this.state.templateDocuments.length) {
      this.getTemplateDocsId();
    } else {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.saveMessage('Draft');
      }, 1000);
    }
    // clearTimeout(this.timer);
    // this.timer = setTimeout(() => { this.saveMessage('Draft'); }, 1000);
  }

  handleCheckboxChange = (inputvalue, fieldname) => {
    const inputfieldvalue =
      inputvalue && inputvalue.target && inputvalue.target.value
        ? inputvalue.target.value
        : undefined;
    const value = !(inputfieldvalue === 'true');
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.saveMessage('Draft');
    }, 500);
    this.setState({ [fieldname]: value });
  };

  handleImageUploadStatus = (status) => {
    this.setState({ uploadingImages: status }, () => {
      if (
        !this.state.uploadingImages &&
        this.state.sendMessageAfterImageUpload
      ) {
        this.saveMessage('Sent');
      } else {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.saveMessage('Draft');
        }, 1000);
      }
    });
  };

  closeModal(discard = '') {
    const { viewContext, messageId, messageThreadId } = this.state;
    if (viewContext) {
      // we will refresh the email list page when task is created from email
      // if (createdNewNote) {
      //   this.props.refreshEmailListPage();
      // }
      let closeModalMessage;
      if (!discard && !messageId && !messageThreadId) {
        closeModalMessage = 'Draft Discarded';
      }
      if (discard === 'Message discarded successfully' && messageId) {
        closeModalMessage = 'Draft Discarded';
      }
      if (!discard && messageId) {
        closeModalMessage = 'Message sent.';
      }
      // const closeModalMessage =
      //   (!discard || discard === 'Message discarded successfully') &&
      //   !messageId &&
      //   !messageThreadId
      //     ? 'Draft Discarded'
      //     : 'Liscio message sent. Email moved to Archived.';
      this.props.closeModalInEmailPage('emailMessage', closeModalMessage);
      if (!discard && messageId && messageThreadId) {
        this.props.refreshEmailListPage('done');
      }
    } else {
      this.props.navigate(-1);
    }
  }

  handleContactInput(val, from = '') {
    const { viewContext } = this.state;
    if (!this.state.accountId && val) {
      if (val.length < 2) {
        this.getContAcc('contacts');
      } else {
        this.setState({ contacts: [] });
        clearTimeout(this.contactSearchTime);
        this.contactSearchTime = setTimeout(() => {
          const request = Axios.get(
            `${SEARCH_TASK_CONTACTS}/?keyword=${val}&message=true`,
          );
          request.then((response) => {
            if (response.status === 200) {
              if (from === 'addToRecepient') {
                if (
                  viewContext === 'email' &&
                  response.data[0].type === 'emp'
                ) {
                  // console.log('Nothing to do');
                } else {
                  const contactDetails = response.data[0];
                  this.handleContactChange(contactDetails);
                  this.addSelectedInput();
                }
              } else {
                this.setState({
                  contacts: response.data,
                  groupedOptions: groupEmpAndContacts(response.data),
                });
              }
            }
          });
        }, 500);
      }
    }
  }

  handleAccountInput(val) {
    // TODO: __HOMEDATA-AUDIT
    const homeDataObj = getStorageData();
    const { contactId, recipientType } = this.state;
    if (
      val.length >= 2 &&
      homeDataObj.is_employee &&
      ((contactId && recipientType === 'emp') || !contactId)
    ) {
      this.setState({ accounts: [] });
      clearTimeout(this.accountSearchTime);
      this.accountSearchTime = setTimeout(() => {
        const request = Axios.get(
          `${SEARCH_TASK_ACCOUNTS}/?keyword=${val}&message=true`,
        );
        request.then((response) => {
          if (response.status === 200) {
            this.setState({ accounts: response.data });
          }
        });
      }, 500);
    }
    if (this.state.errors.accont) {
      const err = {};
      err.accont = '';
      this.setState({ errors: err });
    }
  }

  addSelectedInput() {
    if (!this.state.contactId) return false;
    const selectedInput = {
      contactId: this.state.contactId,
      accountId: this.state.accountId,
      contactName: this.state.contactName,
      accountName: this.state.accountName,
      assigneeType: this.state.assigneeType,
      hasMobile: this.state.hasMobile,
    };
    const selectedRecordsArr = this.state.selectedRecords;
    const index = selectedRecordsArr.findIndex(
      (x) =>
        x.contactId === selectedInput.contactId &&
        x.accountId === selectedInput.accountId,
    );
    // If To field is empty, push into the array and finally display the value in To field
    if (index === -1) {
      selectedRecordsArr.push(selectedInput);
      this.setState({
        selectedRecords: selectedRecordsArr,
        dropdownOpen: false,
        contacts: this.state.allContacts,
        accounts: this.state.allAccounts,
        groupedOptions: groupEmpAndContacts(this.state.allContacts),
        contactId: '',
        contatIdPopup: '',
        accountId: '',
        contactName: '',
        accountName: '',
        assigneeType: '',
        selectedContact: '',
      });
    } else {
      this.setState({
        dropdownOpen: false,
        contacts: this.state.allContacts,
        accounts: this.state.allAccounts,
        groupedOptions: groupEmpAndContacts(this.state.allContacts),
        contactId: '',
        contatIdPopup: '',
        accountId: '',
        contactName: '',
        accountName: '',
        assigneeType: '',
        selectedContact: '',
      });
    }
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.saveMessage('Draft');
    }, 500);
  }

  handleValidation(comment) {
    const err = {};
    let formIsValid = true;
    if (!comment) {
      formIsValid = false;
      err.comment = 'Enter Description';
    }
    const selectedRecordsArr = this.state.selectedRecords;

    if (selectedRecordsArr.length < 1) {
      formIsValid = false;
      err.recipient = 'Please select a recipient';
    }

    this.setState({ errors: err });
    return formIsValid;
  }

  validateDraftMessage(comment) {
    let output = comment ? comment.toString() : '';
    let haveImages = false;
    if (comment && comment.entityMap && Object.keys(comment.entityMap).length) {
      haveImages = true;
    }
    if (comment && typeof comment === 'object') {
      const content = EditorState.createWithContent(
        convertFromRaw(comment),
      ).getCurrentContent();
      const isEditorEmpty = !content.hasText();
      const currentPlainText = content.getPlainText();
      const lengthOfEditorContent = currentPlainText.length;
      const lengthOfTrimmedContent = currentPlainText.trim().length;
      const isContainOnlySpaces = !isEditorEmpty && !lengthOfTrimmedContent;
      if ((lengthOfEditorContent && !isContainOnlySpaces) || haveImages) {
        output = removeBreakElementinList(
          stateToHTML(
            EditorState.createWithContent(
              convertFromRaw(comment),
            ).getCurrentContent(),
            options,
          ),
        );
      } else if (isContainOnlySpaces) {
        output = '';
      }
    } else if (typeof comment === 'string' && comment.length) {
      output = comment;
    }

    if (typeof output === 'object' || output === '[object Object]') {
      // for empty object
      output = '';
    }
    if (output && output.length > 0) {
      // Comment = Comment.replace(/\n/g, ' <br> ');
    }
    return output;
  }

  saveMessage(newStatus) {
    if (this.state.apiHitting && newStatus !== 'Sent') {
      return false;
    }
    const { subject, notifyViaText, comment, viewContext } = this.state;
    const draftState = comment;
    clearTimeout(this.timer);
    const Comment = this.validateDraftMessage(draftState);
    let CommentInJSON = '';
    if (draftState.length) {
      CommentInJSON = JSON.stringify(draftState);
    }
    if (newStatus === 'Sent') {
      const validate = this.handleValidation(Comment);
      if (!validate) {
        return false;
      }
      if (this.state.uploadingImages) {
        this.setState({
          loading: true,
          sendMessageAfterImageUpload: true,
        });
        return false;
      }
    } else if (this.state.uploadingImages) {
      return false;
    }
    this.setState({ apiHitting: true });
    const selectedRecordsArr = this.state.selectedRecords;
    if (
      Comment.trim() === '' &&
      selectedRecordsArr.length < 1 &&
      this.state.documents.length < 1 &&
      (!subject || (subject && subject.trim() === '')) &&
      !notifyViaText
    ) {
      console.log('empty draft');
      if (this.state.messageId) {
        const msgId = encodeURIComponent(this.state.messageId);
        MessageApi.discardDraft(this, msgId, '', '', false, true);
      } else {
        this.setState({ apiHitting: false });
        return false;
      }
      // return false;
    }
    // return false;
    const participantsArr = [];
    selectedRecordsArr.forEach((item) => {
      const tmpHash = {
        id: item.contactId,
        account_id: item.accountId,
        is_recipient: true,
        type: item.assigneeType,
      };
      participantsArr.push(tmpHash);
    });
    const mydata = {
      thread: {
        title: this.state.subject,
        is_notify_via_text: this.state.notifyViaText,
        status: newStatus,
      },
      messages: {
        status: newStatus,
        message_thread_id: this.state.messageThreadId,
        content: Comment,
      },
      id: this.state.messageId,
      participants: participantsArr,
    };

    if (this.state.documents.length) {
      mydata.doc_ids = [];
      let x;
      for (x = 0; x < this.state.documents.length; x += 1) {
        mydata.doc_ids.push(this.state.documents[x].id);
      }
    }
    if (this.state.emailId) {
      mydata.email_id = this.state.emailId;
    }

    if (newStatus === 'Draft') {
      this.setState({ draftStatus: 'Saving' });
    }
    if (newStatus === 'Sent') {
      this.setState({ loading: true });
    }
    const request = Axios.post(MESSAGES_API_PATH, mydata);
    request
      .then((response) => {
        this.setState({ loading: false });

        const from_email = viewContext === 'email';
        trackMixpanelEvent(MIXPANEL_MESSAGE_EVENT, {
          state: response.data.status === 200 ? newStatus : 'error',
          from_email,
        });

        if (newStatus === 'Draft') {
          if (response.data.status === 422 && response.data.message === '') {
            this.setState({
              messageId: '',
              messageThreadId: '',
              apiHitting: false,
            });
          } else if (response.data.status === 400 && response.data.message) {
            AlertMessage('error', response.data.message, 1500);
            this.setState({
              apiHitting: false,
              draftStatus: '',
            });
          } else {
            this.setState(
              {
                messageThreadId: response.data.message_thread_id,
                messageId: response.data.message_id,
                draftStatus: 'Saved',
              },
              () => {
                this.setState({ apiHitting: false });
              },
            );
            setTimeout(() => {
              this.setState({ draftStatus: '' });
            }, 100);
          }
        } else {
          if (response.data.status === 200) {
            if (
              response.data.messages &&
              response.data.messages !== undefined &&
              response.data.messages.includes('away')
            ) {
              AlertMessage('success', response.data.messages, 3000);
            }
            this.closeModal();
          } else {
            AlertMessage('error', response.data.message, 1500);
          }
          this.setState({ apiHitting: false });
        }
      })
      .catch((errors) => {
        this.setState({ apiHitting: false, loading: false });
      });

    return false;
  }

  discardDraft() {
    if (this.state.messageId) {
      const msgId = encodeURIComponent(this.state.messageId);
      MessageApi.discardDraft(this, msgId, '', 'replyWithLiscio');
      this.props.cancelActionsModel();
    } else {
      this.closeModal('');
    }
  }

  keyPress(e) {
    if (e && Number(e.keyCode) === 13) {
      e.preventDefault();
    }
  }

  errorDoc = (field = '') => {
    if (field === 'account') {
      const err = {};
      err.accont = 'Please select account';
      this.setState({ errors: err });
    } else {
      const err = {};
      err.documents = 'Please select document';
      this.setState({ errors: err });
    }
  };

  updateDocumentState = (documents) => {
    this.setState({ documents }, () => {
      this.saveMessage('Draft');
    });
  };

  renderTooltip = () => (
    <ReactTooltip
      id="numberDontExist"
      className="numberDontExist"
      aria-haspopup="true"
      place="top"
      data-background-color="black"
      effect="solid"
      delayHide={1000}
    >
      <span>This contact does not have a phone number.</span>
      {/* <br />
      <u>Add a phone number?</u> */}
      {/* TODO With FD-711 */}
    </ReactTooltip>
  );

  renderTags() {
    const allSelectedRecords = this.state.selectedRecords;
    if (!allSelectedRecords || allSelectedRecords.length < 1) {
      return null;
    }
    const selectedRecords = allSelectedRecords.map((item) => {
      if (item.accountId) {
        return (
          <span
            className="tag tag--fill d-flex align-items-center email--tag receipt-account-tag"
            data-testid="toField"
            key={item.contactId + item.accountId}
          >
            <span
              data-for="clientserviceteam"
              data-tip
              className="avatar avatar--w20 align-self-center"
            >
              {item?.avatar?.length ? (
                <img
                  src={item.avatar}
                  alt="avatar"
                  className="rounded-circle mr-0"
                />
              ) : (
                <span className="uName mr-0">
                  {generateInitials({
                    first_name: item.contactName,
                    last_name: item.contactName,
                  })}
                </span>
              )}
            </span>
            {item.contactName} <i className="divOption" /> {item.accountName}{' '}
            {this.state.notifyViaText && !item.hasMobile && (
              <span
                onFocus={this.onFocus}
                id="numberDontExist"
                data-for="numberDontExist"
                data-tip
              >
                <StyledTooltip>
                  <RenderTooltip
                    id="numberDontExist"
                    className="numberDontExist"
                    place="top"
                    messageDisplayDelay={1000}
                  />
                </StyledTooltip>
                <NotifyViaTextIcon />
              </span>
            )}
            <i
              className="icon-close2 remove"
              onClick={() =>
                this.removeSelectedRecord(item.contactId, item.accountId)
              }
            />
          </span>
        );
      }
      return (
        <span
          className="tag tag--fill d-flex align-items-center email--tag receipt-account-tag"
          key={item.contactId + item.accountId}
        >
          <span
            data-for="clientserviceteam"
            data-tip
            className="avatar avatar--w20 align-self-center"
          >
            {item?.avatar?.length ? (
              <img
                src={item.avatar}
                alt="avatar"
                className="rounded-circle mr-0"
              />
            ) : (
              <span className="uName mr-0">
                {generateInitials({
                  first_name: item.contactName,
                  last_name: item.contactName,
                })}
              </span>
            )}
          </span>
          {item.contactName}{' '}
          {this.state.notifyViaText && !item.hasMobile && (
            <span
              onFocus={this.onFocus}
              id="numberDontExist"
              data-for="numberDontExist"
              data-tip
            >
              <StyledTooltip>{this.renderTooltip()}</StyledTooltip>
              <NotifyViaTextIcon />
            </span>
          )}
          <i
            className="icon-close2 remove"
            onClick={() =>
              this.removeSelectedRecord(item.contactId, item.accountId)
            }
          />
        </span>
      );
    });
    return selectedRecords;
  }

  /** *********** NEW CODE **************** */

  /**
   * This method is used to reset the recipient and account selects
   * This method gets called when clearing a select value
   * @param {*} changeOnly
   */
  getContAcc(changeOnly = 'both') {
    if (changeOnly === 'contacts') {
      this.setState({
        contacts: this.state.allContacts,
        groupedOptions: groupEmpAndContacts(this.state.allContacts),
        contactId: '',
        contatIdPopup: '',
        contactName: '',
        recipientType: '',
        isLoading: false,
        selectedContact: null,
      });
    } else if (changeOnly === 'accounts') {
      this.setState({
        accounts: this.state.allAccounts,
        accountId: null,
        accountName: null,
        selectedContact: null,
      });
    } else {
      this.setState({
        contacts: this.state.allContacts,
        accounts: this.state.allAccounts,
        groupedOptions: groupEmpAndContacts(this.state.allContacts),
        contactId: '',
        contatIdPopup: '',
        accountId: '',
        contactName: '',
        accountName: '',
        recipientType: '',
        selectedContact: null,
      });
    }
  }

  handleContactChange(val) {
    this.setState({ isUserTyping: false });
    if (!val && !this.state.accountId) {
      this.getContAcc();
    } else if (!val) {
      this.setState({
        contactId: '',
        recipientType: '',
        contatIdPopup: '',
        contactName: '',
        assigneeType: '',
      });
    } else if (val.type === 'contact' && !val.hasOwnProperty('accounts')) {
      // if contact has no account associated with it
      this.setState({
        accounts: [],
        contactId: val.value,
        contatIdPopup: val.id,
        contactName: val.label,
        accountId: '',
        accountName: '',
        cpaId: val.cpa_id,
        assigneeType: val.assigne_type,
        recipientType: val.type,
        hasMobile: val.has_mobile,
        selectedContact: val,
      });
      // when an employee is selected from recipient dropdown
    } else if (val.type === 'emp' && !this.state.accountId) {
      this.getContAcc('accounts'); // reset accounts data
      this.setState({
        contactId: val.value,
        contactName: val.label,
        selectedContact: val,
        cpaId: val.cpa_id,
        assigneeType: val.assigne_type,
        recipientType: val.type,
        hasMobile: val.has_mobile,
      });
    } else if (val.type === 'emp' && this.state.accountId) {
      this.setState({
        contactId: val.value,
        contactName: val.label,
        selectedContact: val,
        cpaId: val.cpa_id,
        assigneeType: val.assigne_type,
        recipientType: val.type,
        hasMobile: val.has_mobile,
      });
    } else if (val.accounts.length === 1) {
      // autopopulate account if only one account
      this.setState(
        {
          accountId: val.accounts[0].value,
          accountName: val.accounts[0].label,
          accounts: val.accounts,
          contactId: val.value,
          contatIdPopup: val.id,
          contactName: val.label,
          cpaId: val.cpa_id,
          assigneeType: val.assigne_type,
          recipientType: val.type,
          selectedContact: val,
          hasMobile: val.has_mobile,
        },
        () => {
          this.setState({ isLoading: true });
          getContEmpOfAccount(this, {
            accountId: val.accounts[0].value,
            accountName: val.accounts[0].label,
          });
        },
      );
    } else {
      const eleArray = this.state.accounts.map((item) => item.value);
      if (eleArray.indexOf(this.state.accountId) > -1) {
        this.setState({
          accounts: val.accounts,
          contactId: val.value,
          contatIdPopup: val.id,
          contactName: val.label,
          selectedContact: val,
          cpaId: val.cpa_id,
          assigneeType: val.assigne_type,
          recipientType: val.type,
          hasMobile: val.has_mobile,
        });
      } else {
        // set the accounts related to that contact
        this.setState({
          accounts: val.accounts,
          contactId: val.value,
          contatIdPopup: val.id,
          contactName: val.label,
          selectedContact: val,
          cpaId: val.cpa_id,
          assigneeType: val.assigne_type,
          recipientType: val.type,
          hasMobile: val.has_mobile,
        });
      }
    }
  }

  handleAccountChange(val, typeOfAction) {
    const { action, removedValues } = typeOfAction;
    if (action === 'clear' && removedValues.length === 0) {
      return;
    }

    // TODO: __HOMEDATA-AUDIT
    const homeDataObj = getStorageData();

    this.setState({ isUserTyping: false });

    if (!val && !this.state.contactId) {
      this.getContAcc();
    } else if (!val) {
      // reset account select when there is no value
      if (this.state.recipientType === 'emp') {
        this.setState({
          accounts: this.state.allAccounts,
          accountId: null,
          accountName: null,
          groupedOptions: groupEmpAndContacts(this.state.allContacts),
        });
      } else {
        this.setState({
          accounts: this.state.accounts,
          accountId: null,
          accountName: null,
        });
      }
    } else {
      this.setState({
        accountId: val.value,
        accountName: val.label,
      });
      const mydata = {
        accountId: val.value,
        accountName: val.label,
      };
      // Will enter this if condition when you select an account with no recipient
      if (homeDataObj && homeDataObj.is_employee) {
        this.setState({ isLoading: true });
        getContEmpOfAccount(this, mydata);
      } else {
        getRelConAndCstOfAccounts(this, val.value);
      }
    }
    if (this.state.errors.accont) {
      const err = {};
      err.accont = '';
      this.setState({ errors: err });
    }
  }

  // get filtered results when performing a search in contact select
  getContactFilteredResults = async (searchTerm, from = '') => {
    try {
      const response = await Axios.get(
        `${SEARCH_TASK_CONTACTS}/?keyword=${searchTerm}&message=true`,
      );

      if (response.status === 200) {
        const groupedOptions = groupEmpAndContacts(response.data);
        if (from === 'addToRecepient') {
          const contactDetails = response.data[0];
          this.handleContactChange(contactDetails);
          this.addSelectedInput();
        } else {
          this.setState({
            contacts: response.data,
            groupedOptions,
          });
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  // get filtered results when performing a search in account select
  getAccountFilteredResults = async (searchTerm) => {
    try {
      const response = await Axios.get(
        `${SEARCH_TASK_ACCOUNTS}/?keyword=${searchTerm}&message=true`,
      );

      if (response.status === 200) {
        this.setState({ accounts: response.data, allAccounts: response.data });
      }
    } catch (err) {
      console.error(err);
    }
  };

  // handle contact select input change
  handleContactInputChange = debounce((inputValue, typeOfAction) => {
    const { action } = typeOfAction;
    const { accountId, isUserTyping } = this.state;

    /**
     * execute this block when the user clears the select input while hitting backspace or
     * closes the menu without selecting an option
     */
    if (action === 'menu-close' && isUserTyping) {
      this.getContAcc('contacts');
    }

    if (action === 'input-change' && !accountId) {
      if (inputValue) {
        this.getContactFilteredResults(inputValue);
        this.setState({ isUserTyping: true });
      } else {
        this.getContAcc('contacts');
      }
    }
  }, 300);

  // handle account select input change
  handleAccountInputChange = debounce((inputValue, typeOfAction) => {
    const { action, prevInputValue } = typeOfAction;
    const { isUserTyping, contactId, recipientType } = this.state;

    // TODO: __HOMEDATA-AUDIT
    const homeDataObj = getStorageData();

    /**
     * execute this block when the user clears the select input while hitting backspace or
     * closes the menu without selecting an option
     */

    if (
      (action === 'menu-close' || prevInputValue.length === 1) &&
      isUserTyping
    ) {
      this.setState((prevState) => ({
        accounts: prevState.allAccounts,
        accountId: '',
        accountName: '',
      }));
    }

    if (inputValue) {
      if (
        action === 'input-change' &&
        homeDataObj.is_employee &&
        ((contactId && recipientType === 'emp') || !contactId)
      ) {
        this.getAccountFilteredResults(inputValue);
        this.setState({ isUserTyping: true });
      } else if (Array.isArray(this.state.ref_accounts)) {
        this.setState({ allAccounts: this.state.ref_accounts });
      }
    }
  }, 300);

  // reset select dropdowns
  resetSelectDropdowns = () => {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
      contacts: prevState.allContacts,
      accounts: prevState.allAccounts,
      groupedOptions: groupEmpAndContacts(prevState.allContacts),
      selectedContact: '',
      contactId: '',
      contatIdPopup: '',
      accountId: '',
      contactName: '',
      accountName: '',
      inputVal: '',
      errors: {},
    }));
  };

  deleteEmailAttachment = (input) => {
    const { documents } = this.state;
    this.setState({
      documents: documents.filter((e) => e.id !== input),
    });
  };

  render() {
    const {
      selectedOption,
      templateOptions,
      selectedTemplate,
      subject,
      comment,
      viewContext,
      groupedOptions,
      isLoading,
      messageId,
      messageThreadId,
    } = this.state;

    const { showModalFor, email } = this.props;

    const formattedContent = getFormattedEditorDescription(
      showModalFor,
      email,
      comment,
    );
    // TODO: __HOMEDATA-AUDIT
    const homeDataObj = getStorageData();
    const initials = homeDataObj
      ? homeDataObj.uname
        ? (homeDataObj.uname.split(' ')[0]
            ? homeDataObj.uname.split(' ')[0].charAt(0)
            : '') +
          (homeDataObj.uname.split(' ')[1]
            ? homeDataObj.uname.split(' ')[1].charAt(0)
            : '')
        : ''
      : '';
    const noneStyle = { display: 'none' };
    const blockStyle = { display: 'block' };
    const inLiscioMessageRespondModal = showModalFor === 'respond_with_message';

    return (
      <div id="content" className="text-left" data-testid="newMessageEmail">
        <div id="loading" style={this.state.loading ? blockStyle : noneStyle} />
        <div className="center-wrapper col-12 m-auto">
          <div className="pb-3 col-container section-title" />
          <Form className="form-email">
            <div className="checkingEmail position-relative">
              <FormGroup
                className={this.state.errors.recipient ? 'form-error' : ''}
              >
                <Label className="float-left" htmlFor="forEmail">
                  To
                </Label>
                <div className="labelValue customTags mailDrodown">
                  <div className="customTags__input form-control pl-0 pr-7 position-relative border-top-0 border-left-0 border-right-0 border-bottom">
                    {this.renderTags()}
                    <button
                      type="button"
                      onClick={this.onFocus}
                      data-testid="onFocusButton"
                      className="inputHelper dropdown-toggle noCaret"
                    />
                    {/* <Input type="text" autopopulate="off" id="messageto" name="message_to" onFocus={this.onFocus} autoComplete="off" className="dropdown-toggle" placeholder="" value={this.state.inputVal} /> */}
                  </div>
                  {this.state.dropdownOpen ? (
                    <DropdownMenu className="customTags__option option_box">
                      <RecipientAndAccountDropdowns
                        selectedContact={this.state.selectedContact}
                        recipientOptions={groupedOptions}
                        isLoading={isLoading}
                        accountOptions={this.state.allAccounts}
                        handleContactChange={this.handleContactChange}
                        handleAccountChange={this.handleAccountChange}
                        recipientValue={{
                          label: this.state.contactName,
                          value: this.state.contactId,
                        }}
                        accountValue={{
                          label: this.state.accountName,
                          value: this.state.accountId,
                        }}
                        handleContactInputChange={this.handleContactInputChange}
                        handleAccountInputChange={this.handleAccountInputChange}
                      />
                      <div className="btn-wrap btn--leftspace">
                        <Button
                          className="btn-link btn-sm btn--onlyicon"
                          onClick={this.resetSelectDropdowns}
                        >
                          Close
                        </Button>
                        <Button
                          className="btn btn-sm btn-primary"
                          data-testid="okButton"
                          onClick={this.addSelectedInput}
                        >
                          Ok
                        </Button>
                      </div>
                    </DropdownMenu>
                  ) : null}
                </div>
                <span className="error_label">
                  {this.state.errors.recipient}
                </span>
              </FormGroup>
              {homeDataObj && homeDataObj.is_employee ? (
                <div
                  className={`float-right position-absolute bottom-4 right-0 notify--border notify--fill rounded--2 notify-padding ${
                    Object.keys(this.state.errors).length
                      ? 'email-msg-error'
                      : ''
                  } `}
                >
                  <CheckboxField
                    id="notifyViaText"
                    name="notifyViaText"
                    label="Notify Via Text"
                    labelclassName="text-body text-transform-none font-weight-normal"
                    value={this.state.notifyViaText}
                    onChange={this.handleCheckboxChange}
                  />
                </div>
              ) : null}
            </div>
            <FormGroup
              className={
                this.state.errors.comment
                  ? 'form-error cstmClr position-relative'
                  : ' cstmClr position-relative'
              }
            >
              <Label htmlFor="subject">Subject</Label>
              <div className="labelValue customEditor boxResize flWdth">
                {(selectedOption && selectedOption !== null) ||
                (!selectedOption &&
                  selectedOption === null &&
                  !homeDataObj.is_employee) ||
                (!selectedOption &&
                  selectedOption === null &&
                  subject &&
                  !this.state.titleChange) ? (
                  <Input
                    className="border-top-0 border-left-0 border-right-0 px-0 border-bottom"
                    autoComplete="off"
                    onKeyPress={(e) => this.keyPress(e)}
                    type="text"
                    name="subject"
                    id="forSubject"
                    value={this.state.subject}
                    onChange={this.handleChange}
                    placeholder=""
                  />
                ) : (
                  <Select
                    name="subject"
                    placeholder=""
                    ref={this.selectInputRef}
                    className="select-custom-class slctTemp"
                    value={selectedOption}
                    onChange={this.templateDropdown}
                    options={templateOptions}
                    inputValue={this.state.titleChange}
                    // optionComponent={SelectTemplateFieldOption}
                    onInputChange={this.templateChange}
                    // inputRenderer={input => this.inputRenderer(input)}
                    tabSelectsValue={false}
                    onBlurResetsInput={false}
                    onCloseResetsInput={false}
                    onSelectResetsInput={this.state.titleChange} // false shows
                    noResultsText=""
                    styles={selectStyles}
                    theme={selectTheme}
                    classNamePrefix="custom-react-select"
                    components={{
                      Option: SelectTemplateFieldOption,
                      Input: this.inputRenderer,
                    }}
                  />
                )}
              </div>
              {this.state.subject && homeDataObj.is_employee ? (
                <Button
                  className="btn btn-sm btn-primary email-sub-button"
                  onClick={() => this.templateDropdown(null)}
                >
                  Clear
                </Button>
              ) : null}
            </FormGroup>
            <FormGroup
              className={this.state.errors.comment ? ' form-error' : ''}
            >
              {/* <Label htmlFor="forDescription">Description</Label> */}
              <div className="labelValue customEditor boxResize RTE__EmailDescription">
                <TinymceEditor
                  height="400px"
                  initialValue={this.initialComment}
                  value={this.state.comment}
                  handleChange={this.handleCommentChange}
                  scrollIntoView={inLiscioMessageRespondModal}
                  autofocus={inLiscioMessageRespondModal}
                />
                <span className="error_label">{this.state.errors.comment}</span>
              </div>
            </FormGroup>
            <FormGroup className="dragWrap">
              <Label htmlFor="attachments">Attachments</Label>
              <div className="notify--fill">
                <TaskFileUpload
                  data={this.state}
                  uploadType="message"
                  propsData={this.state}
                  updateDocumentState={this.updateDocumentState}
                  deleteEmailAttachment={this.deleteEmailAttachment}
                />
              </div>
            </FormGroup>
            <div className="form-group formbtn formbtn--border justify-content-between">
              <div className="btn-wrap btn--leftspace">
                <EmailSendButton
                  size="small"
                  color="primary"
                  onClick={() => this.saveMessage('Sent')}
                  disabled={
                    this.state.draftStatus === 'Saving' ||
                    this.state.gettingEmailAttachments
                  }
                  label="Send"
                  iconLocation={BUTTON_ICON_LOCATION_BACK}
                  icon="right-arrow2"
                />
                <span className="text-dark">{this.state.draftStatus}</span>
              </div>
              <div>
                <DiscardEmail
                  handleDiscardButton={() => this.discardDraft()}
                  iconCustomClass="notify--fill p-1 msg-icon-width"
                  disabled={
                    this.state.draftStatus === 'Saving' ||
                    this.state.gettingEmailAttachments
                  }
                />
              </div>
            </div>
          </Form>
        </div>
        <ConfirmationModal
          isOpen={this.state.warningMsgLost}
          className="customModal customModal--w500 customModal--nopadding"
          messageText="You are about to delete the existing message. This action cannot be undone. Proceed?"
          noBtnClick={() => this.setState({ warningMsgLost: false })}
          yesBtnClick={() => this.templateDropdown(selectedTemplate)}
          yesBtnText="Yes"
          noBtnText="No"
        />
      </div>
    );
  }
}

export default NewMessage;
