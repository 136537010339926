import { TOAST_FAILURE } from 'constants/constants';
import { Error } from 'gql/graphql';
import { AlertMessage } from 'utilities/utils';

//TODO: get backend to return error codes instead of just strings
const errorMap: { [key: string]: string } = {
  'Prompt must be unique for this section':
    'prompt must be unique for this section',
};

export const getErrorMessage = (errors?: Array<Error> | null) =>
  errorMap[errors?.[0]?.message || ''];

export const alertGqlError = (
  errors?: Array<Error> | null,
  fallback?: string,
) => {
  const message = getErrorMessage(errors) || fallback;
  if (!message) {
    return false;
  }
  AlertMessage(TOAST_FAILURE, message);
  return true;
};
