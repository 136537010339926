import {
  Box,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  Typography,
  debounce,
  useTheme,
} from '@mui/material';
import {
  WorkflowItem2Fragment,
  WorkflowResponseItem2Fragment,
} from 'gql/graphql';
import { graphql } from 'gql';
import { useMutationGraphQL } from 'hooks/useGraphQL';
import { ChangeEvent, useContext, useMemo, useState } from 'react';
import { WorkflowViewContext } from 'components/Requests/components/WorkflowView/context/WorkflowViewContext';
import useEffectOnce from 'hooks/useEffectOnce';
import { useQueryClient } from '@tanstack/react-query';
import { invalidateWorkflowRequestsQuery } from 'components/Requests/components/RequestsBuilder/utils/mutation-utils';
import { TOAST_FAILURE } from 'constants/constants';
import { AlertMessage } from 'utilities/utils';

export const updateResponseItemBoolDocument = graphql(/* GraphQL */ `
  mutation updateResponseItemBool(
    $notApplicable: Boolean
    $booleanValue: Boolean
    $workflowResponseItemId: Uuid!
  ) {
    updateResponseItemBool(
      notApplicable: $notApplicable
      booleanValue: $booleanValue
      workflowResponseItemId: $workflowResponseItemId
    ) {
      errors {
        message
      }
      workflowResponseItem {
        id
      }
    }
  }
`);

export const responseBooleanValueFragment = graphql(/* GraphQL */ `
  fragment responseBooleanValue on BooleanValue {
    booleanValue: value
  }
`);

interface BooleanItemProps {
  workflowItem: WorkflowItem2Fragment;
  workflowResponseItem?: WorkflowResponseItem2Fragment | null;
  disabledEdit?: boolean;
}

export function BooleanItem({
  workflowItem,
  workflowResponseItem,
  disabledEdit,
}: BooleanItemProps) {
  const theme = useTheme();
  const { checkValid, setDirtyField } = useContext(WorkflowViewContext);

  const [notApplicable, setNotApplicable] = useState(
    workflowResponseItem?.notApplicable || false,
  );

  const queryClient = useQueryClient();

  const { mutate: updateResponseItemBooleanValue, isLoading: isMutating } =
    useMutationGraphQL(updateResponseItemBoolDocument);

  const value = notApplicable ? undefined : workflowResponseItem?.booleanValue;

  useEffectOnce(() => {
    checkValid(value);
  });

  const debounceMutation = useMemo(
    () =>
      debounce((newValue?, na?) => {
        updateResponseItemBooleanValue(
          {
            booleanValue: newValue ? newValue === 'true' : undefined,
            notApplicable: na,
            workflowResponseItemId: workflowResponseItem?.id || '',
          },
          {
            onSuccess: () => {
              checkValid(newValue || na);
              setDirtyField(workflowResponseItem?.id, false);
              invalidateWorkflowRequestsQuery(
                workflowResponseItem?.id,
                queryClient,
              );
            },
            onError: () => {
              AlertMessage(TOAST_FAILURE, 'Failed to update response item');
              if (na !== undefined) {
                setNotApplicable(!na);
              }
            },
          },
        );
      }, 1000),
    [
      checkValid,
      queryClient,
      setDirtyField,
      updateResponseItemBooleanValue,
      workflowResponseItem?.id,
    ],
  );

  const handleInput = ({
    currentTarget: { value: newValue },
  }: ChangeEvent<HTMLInputElement>) => {
    debounceMutation(newValue);
    setDirtyField(workflowResponseItem?.id, true);
  };

  const handleNotApplicable = ({
    target: { checked },
  }: ChangeEvent<HTMLInputElement>) => {
    setNotApplicable(checked);
    debounceMutation(undefined, checked);
  };

  return (
    <Box width="100%">
      <Stack direction="row">
        <Typography variant="h4">{workflowItem.prompt}</Typography>
        <RadioGroup
          row
          defaultValue={value}
          sx={{
            margin: '2px 0px 0px auto',
            flexWrap: 'nowrap',
            justifyContent: 'flex-end',
            alignItems: 'flex-start',
            minWidth: '120px',
          }}
          onChange={handleInput}
          aria-label={`${workflowItem.prompt} input`}
        >
          <FormControlLabel
            value="true"
            control={
              <Radio
                disabled={disabledEdit || notApplicable}
                size="small"
                sx={{ padding: '0px 8px 0px 0px' }}
              />
            }
            label="Yes"
            labelPlacement="end"
            sx={{
              marginBottom: '4px',
            }}
          />
          <FormControlLabel
            value="false"
            control={
              <Radio
                disabled={disabledEdit || notApplicable}
                size="small"
                sx={{ padding: '0px 8px 0px 0px' }}
              />
            }
            label="No"
            labelPlacement="end"
            sx={{
              marginBottom: '4px',
            }}
          />
        </RadioGroup>
      </Stack>
      {workflowItem.hint && workflowItem.hint.length > 0 ? (
        <Typography variant="body1" color={theme.palette.grey[500]}>
          Prior Year Value: {workflowItem.hint}
        </Typography>
      ) : null}
      <FormGroup>
        <FormControlLabel
          label="This item does not apply to me"
          control={
            <Switch
              disabled={isMutating || disabledEdit}
              onChange={handleNotApplicable}
              checked={notApplicable}
            />
          }
        />
      </FormGroup>
    </Box>
  );
}
