/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable no-script-url */
/* eslint-disable react/jsx-no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-curly-brace-presence */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import axios from 'axios';
// import Alert from 'react-s-alert';
// import 'react-s-alert/dist/s-alert-default.css';
import moment from 'moment';
import { Modal, ModalBody, Button } from 'reactstrap';
import { withRouter } from 'utilities/withRouterWrapper';
import { stateToHTML } from 'draft-js-export-html';
import { EditorState, convertFromRaw } from 'draft-js';
import { options, removeBreakElementinList } from 'common/NotesUtilities';
import PageNotFound from 'common/PageNotFound';
import {
  ACCOUNT,
  CONTACT,
  GET_CONTACTS_OF_ACCOUNT_API_PATH,
  GET_CONTACTS_API_PATH,
  GET_ACCOUNTS_API_PATH,
  GET_ACCOUNTS_OF_CONTACT_API_PATH,
  USER_EMAILS,
  SEARCH_TASK_CONTACTS,
  SEARCH_TASK_ACCOUNTS,
} from 'constants/constants';
import Notes from 'api/Notes';
import * as Utilities from 'utilities/utils';
import TaskFileUpload from 'components/Files/TaskFileUpload';
import * as TaskApi from 'api/TaskApi';
import { withStorageData } from 'utilities/withStorageData';
import {
  SelectFieldWithAvatar,
  SelectField,
  DateField,
} from 'common/CustomFields';
import TinymceEditor from 'components/Emails/components/TinymceEditior';
import 'components/Emails/components/TinymceEditior.scss';
import './Notes.scss';
import { getFormattedEditorDescription } from 'components/Email/EmailUtilities';
import {
  CreateNotesFooterButton,
  CreateNotesFromEmailsFooter,
} from './CreateNotesFooterButton';

class CreateNotes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      referenceDate: moment(),
      minDate: moment('01/01/1980'),
      maxDate: moment(),
      contacts: [],
      accounts: [],
      allContacts: [],
      allAccounts: [],
      accountId: '',
      contactId: '',
      contactName: '',
      accountName: '',
      loading: false,
      subject: props.emailDetails?.subject || props.noteTitle || '',
      // uploadFiles: [],
      documents: [],
      comment: props.emailDetails?.content || props.noteDescription || '',
      // showModal: false,
      viewModal: false,
      // attachmentId: undefined,
      errors: {},
      isEdit: false,
      editNoteId: '',
      assigneeType: '',
      unauthorisedAccess: false,
      accountNote: false,
      contactNote: false,
      editInEditMode: false,
      viewContext: this.props.viewContext,
      initialData: {
        comment: this.props.emailDetails ? this.props.emailDetails.content : '',
      },
      emailId:
        this.props.viewContext && this.props.emailDetails
          ? this.props.emailDetails.email_id
          : undefined,
    };
    this.handleDateChange = this.handleDateChange.bind(this);
    this.getContAcc = this.getContAcc.bind(this);
    this.handleAccountChange = this.handleAccountChange.bind(this);
    this.handleContactChange = this.handleContactChange.bind(this);
    this.handleContactInput = this.handleContactInput.bind(this);
    this.handleAccountInput = this.handleAccountInput.bind(this);
    this.handleChange = this.handleChange.bind(this);
    // this.handleFileDrop = this.handleFileDrop.bind(this);
    // this.startUpload = this.startUpload.bind(this);
    // this.cancelUpload = this.cancelUpload.bind(this);
    // this.setAttachmentNull = this.setAttachmentNull.bind(this);
    // this.uploadDocument = this.uploadDocument.bind(this);
    // this.showModal = this.showModal.bind(this);
    this.viewModal = this.viewModal.bind(this);
    // this.hideModal = this.hideModal.bind(this);
    this.hideViewModal = this.hideViewModal.bind(this);
    this.handleCommentChange = this.handleCommentChange.bind(this);
    // this.resetContactsAndAccounts = this.resetContactsAndAccounts.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.resetAllStates = this.resetAllStates.bind(this);
    this.handleValidation = this.handleValidation.bind(this);
    this.handleBlurValidation = this.handleBlurValidation.bind(this);
    this.handleBlurDraftValidation = this.handleBlurDraftValidation.bind(this);
    this.goBack = this.goBack.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    // this.openAttachment = this.openAttachment.bind(this);
    // this.attachmentModalClose = this.attachmentModalClose.bind(this);
    // this.cancelAllUpload = this.cancelAllUpload.bind(this);
  }

  componentDidMount() {
    const { showModalFor, email } = this.state;
    const { comment } = this.state;

    this.initialValue = getFormattedEditorDescription(
      showModalFor,
      email,
      comment,
    );

    document.title = 'Create Note';
    if (Utilities.getFirmType() === 'liscio-lite') {
      this.props.navigate('/');
    }
    // TODO: __HOMEDATA-AUDIT
    const homeDataObj = Utilities.getStorageData();
    if (homeDataObj.is_employee) {
      if (
        this.props.location &&
        this.props.location.search.split('=')[0] === '?contact_id'
      ) {
        const dataa = {
          value: this.props.location.search.split('=').pop(),
          label: '',
          avatar: '',
        };
        this.setState({ contactId: dataa });
        axios
          .get(`${CONTACT}/${this.props.location.search.split('=').pop()}`)
          .then((res) => {
            if (
              res.status === 200 &&
              res.data.status === 200 &&
              res.data.data &&
              res.data.data.length
            ) {
              const contactDetails = res.data.data[0];
              const firstName = contactDetails.contact.first_name;
              const middleName = contactDetails.contact.middle_name
                ? contactDetails.contact.middle_name
                : '';
              const lastName = contactDetails.contact.last_name;
              const con = [];
              const data = {
                value: contactDetails.contact.id,
                label: `${firstName} ${middleName} ${lastName}`,
                avatar: contactDetails.avatar,
              };
              data.initials =
                data && data.label
                  ? (data.label.split(' ')[0]
                      ? data.label.split(' ')[0].charAt(0)
                      : '') +
                    (data.label.split(' ')[1]
                      ? data.label.split(' ')[1].charAt(0)
                      : '') +
                    (data.label.split(' ')[2]
                      ? data.label.split(' ')[2].charAt(0)
                      : '')
                  : '';
              con.push(data);
              this.setState({
                contactNote: true,
                contactId: data,
                contactName: `${firstName} ${middleName} ${lastName}`,
                contacts: con,
                allContacts: con,
              });
            }
          });
        const request = axios.get(
          `${GET_ACCOUNTS_OF_CONTACT_API_PATH}/${this.props.location.search
            .split('=')
            .pop()}`,
        );
        request
          .then((response) => {
            this.setState({ accounts: response.data.data });
          })
          .catch(() => {
            this.setState({ loading: false });
          });
      } else if (
        this.props.location &&
        this.props.location.search.split('=')[0] === '?account_id'
      ) {
        axios
          .get(`${ACCOUNT}/${this.props.location.search.split('=').pop()}`)
          .then((res) => {
            if (res.status === 200 && res.data.status === 200) {
              const accountDetails = res.data.data;
              const details = accountDetails.account;
              const acc = [];
              acc.push({ value: details.id, label: details.name });
              this.setState({
                accountNote: true,
                accountId: details.id,
                accountName: details.name,
                accounts: acc,
                allAccounts: acc,
              });
            }
          });
        const requestCon = axios.get(
          `${GET_CONTACTS_OF_ACCOUNT_API_PATH}/${this.props.location.search
            .split('=')
            .pop()}/?note=true`,
        );
        requestCon
          .then((response) => {
            this.setState({ contacts: response.data.data });
          })
          .catch(() => {
            this.setState({ loading: false });
          });
      } else if (this.props.account_data?.value) {
        const accountRequest = axios.get(GET_ACCOUNTS_API_PATH);
        accountRequest.then((response) => {
          this.setState({
            accounts: response.data.data,
            allAccounts: response.data.data,
          });
        });
        this.handleAccountChange(this.props.account_data);
      } else {
        const request = axios.get(GET_CONTACTS_API_PATH);
        request.then((response) => {
          this.setState({
            contacts: response.data.data,
            allContacts: response.data.data,
          });
        });
        const accountRequest = axios.get(GET_ACCOUNTS_API_PATH);
        accountRequest.then((response) => {
          this.setState({
            accounts: response.data.data,
            allAccounts: response.data.data,
          });
        });
      }
      // for editing notes
      const url =
        this.props.location && this.props.location.pathname.split('/');
      if (url) {
        const id = url[url.length - 1];
        const edit = url[url.length - 2];
        if (id !== '' && edit === 'edit') {
          const request = axios.get(GET_CONTACTS_API_PATH);
          request.then((response) => {
            this.setState({
              contacts: response.data.data,
              allContacts: response.data.data,
            });
          });
          const accountRequest = axios.get(GET_ACCOUNTS_API_PATH);
          accountRequest.then((response) => {
            this.setState({
              accounts: response.data.data,
              allAccounts: response.data.data,
            });
          });
          this.setState({ isEdit: true, editNoteId: id });
          Notes.getNoteDetail(id, this);
        }
      }

      if (this.props.viewContext) {
        this.getEmailAttachments();
      }
    }
    if (this.props.notesAttachments) {
      this.setState({
        documents: this.props.notesAttachments,
      });
    }
  }

  componentWillUnmount() {
    if (this.state.documents.length) {
      const docIds = [];
      let x;
      for (x = 0; x < this.state.documents.length; x += 1) {
        docIds.push(this.state.documents[x].id);
      }
      const data = { doc_ids: docIds };
      TaskApi.cancelDocuments(this, data, 'notes');
    }
  }

  getEmailAttachments = async () => {
    if (
      this.props.emailDetails &&
      this.props.emailDetails.documents &&
      this.props.emailDetails.documents.length
    ) {
      this.setState({
        gettingEmailAttachments:
          (this.props.emailDetails.documents &&
            this.props.emailDetails.documents.length) ||
          false,
      });
      try {
        this.setState({ loading: true });
        const url = `${USER_EMAILS}/${this.props.emailDetails.email_id}/attachments?for_note_creation=true`;
        const inlineImgUrl = `${USER_EMAILS}/${this.props.emailDetails.email_id}/attachments?content_disposition=inline`;
        const apis = [axios.get(inlineImgUrl), axios.get(url)];
        await Promise.all(apis).then(
          axios.spread((...responses) => {
            const responseInline = responses[0] ? responses[0].data.data : [];
            const response = responses[1] ? responses[1].data.data : [];
            if (response && response.length) {
              response.map((e) => {
                e.isAttachmentFromEmailOrMessage = true;
                return e;
              });
              this.setState({ documents: response });
            }
            if (responseInline && responseInline.length) {
              let emailBody = this.props.emailDetails.content;
              responseInline.forEach((doc) => {
                if (doc.content_type.includes('image') && doc.attachment_body) {
                  emailBody = emailBody.replace(
                    `cid:${doc.content_id.replace('<', '').replace('>', '')}`,
                    `data:${doc.content_type};base64,${doc.attachment_body}`,
                  );
                }
              });
              const data = this.state.initialData;
              data.comment = emailBody;
              this.setState({ initialData: data, comment: emailBody });
            }
            this.setState({ gettingEmailAttachments: false });
          }),
        );
      } catch (error) {
        this.setState({ gettingEmailAttachments: false });
        console.log(error);
      } finally {
        this.setState({ loading: false });
      }
    }
  };

  getContAcc(changeOnly = 'both') {
    if (changeOnly === 'contacts') {
      this.setState({
        contacts: this.state.allContacts,
        contactId: '',
      });
    } else if (changeOnly === 'accounts') {
      this.setState({
        accounts: this.state.allAccounts,
        accountId: '',
      });
    } else {
      this.setState({
        contacts: this.state.allContacts,
        accounts: this.state.allAccounts,
        contactId: '',
        accountId: '',
      });
    }
  }

  goBack() {
    const { viewContext } = this.state;
    const { closeModalInEmailPage, navigate } = this.props;
    if (viewContext && typeof closeModalInEmailPage === 'function') {
      // we will refresh the email list page when task is created from email
      // if (createdNewNote) {
      //   this.props.refreshEmailListPage();
      // }
      closeModalInEmailPage();
    } else {
      navigate(-1);
    }
  }

  handleBlurValidation() {
    const values = this.state;
    const err = {};
    let formIsValid = true;
    if (!values.accountId && !values.contactId) {
      formIsValid = false;
      err.account_contact = 'Please select contact or account';
    }
    if (values.errors.comment) {
      formIsValid = false;
      err.comment = 'Enter Description';
    }
    this.setState({ errors: err });
    return formIsValid;
  }

  handleBlurDraftValidation() {
    const values = this.state;
    const err = {};
    let formIsValid = true;
    if (!values.comment) {
      formIsValid = false;
      err.comment = 'Enter Description';
    } else if (typeof values.comment === 'object') {
      if (
        !EditorState.createWithContent(convertFromRaw(values.comment))
          .getCurrentContent()
          .getPlainText().length
      ) {
        formIsValid = false;
        err.comment = 'Enter Description';
      }
    }
    if (values.errors.account_contact) {
      formIsValid = false;
      err.account_contact = 'Please select contact or account';
    }
    this.setState({ errors: err });
    return formIsValid;
    // return true;
  }

  handleValidation() {
    const values = this.state;
    const err = {};
    let formIsValid = true;
    if (!values.accountId && !values.contactId) {
      formIsValid = false;
      err.account_contact = 'Please select contact or account';
    }
    if (!values.comment) {
      formIsValid = false;
      err.comment = 'Enter Description';
    } else if (typeof values.comment === 'object') {
      if (
        !EditorState.createWithContent(convertFromRaw(values.comment))
          .getCurrentContent()
          .getPlainText().length
      ) {
        formIsValid = false;
        err.comment = 'Enter Description';
      }
    }
    this.setState({ errors: err });
    return formIsValid;
  }

  handleChange(input) {
    const component = this;
    if (this.state.isEdit) {
      component.setState({ subject: input.target.value, editInEditMode: true });
    } else {
      component.setState({ subject: input.target.value });
    }
  }

  handleContactChange(val) {
    const component = this;
    if (val) {
      let s;
      let contactid;
      if (val.id) {
        s = {
          value: val.id,
          label: val.label,
          avatar: val.avatar,
        };
        contactid = val.id;
      } else if (val.value.value) {
        s = val.value;
        contactid = val.value.value;
      } else {
        s = val;
        contactid = val.value;
      }
      component.setState({ contactId: s, contactName: val.label });
      component.setState({ loading: true });
      // const contactid = val.id ? val.id : val.value.value;
      const request = axios.get(
        `${GET_ACCOUNTS_OF_CONTACT_API_PATH}/${contactid}`,
      );
      request
        .then((response) => {
          component.setState({ loading: false });
          component.setState({ accounts: response.data.data });
          if (response.data.data.length === 1) {
            this.setState({
              accountId: response.data.data[0].value,
              accountName: response.data.data[0].label,
            });
          }
        })
        .catch(() => {
          component.setState({ loading: false });
        });
    } else if (!val && component.state.accountId) {
      this.getContacts();
    } else if (component.state.accountId) {
      component.setState({ contactId: '', contactName: ' ' });
      this.handleAccountChange({
        value: component.state.accountId,
        label: component.state.accountName,
      });
    } else if (val === null) {
      component.setState({ contactId: '', contactName: ' ' });
      this.getContacts();
      this.getContAcc();
    } else {
      component.setState({ contactId: '', contactName: ' ' });
      this.getContAcc();
    }
  }

  getContacts() {
    const request = axios.get(GET_CONTACTS_API_PATH);
    request.then((response) => {
      this.setState({
        contactName: '',
        contactId: '',
        contacts: response.data.data,
        allContacts: response.data.data,
      });
    });
  }

  handleContactInput(val) {
    if (!this.state.accountId && val) {
      if (val.length < 2) {
        this.getContAcc('contacts');
      } else {
        const component = this;
        const request = axios.get(
          `${SEARCH_TASK_CONTACTS}/?keyword=${val}&task_type_key=get_signature`,
        );
        request.then((response) => {
          if (response.status === 200) {
            component.setState({ contacts: response.data });
          }
        });
      }
    }
  }

  handleAccountInput(val) {
    if (val.length >= 2 && !this.state.contactId) {
      const component = this;
      const request = axios.get(`${SEARCH_TASK_ACCOUNTS}/?keyword=${val}`);
      request.then((response) => {
        if (response.status === 200) {
          component.setState({ accounts: response.data });
        }
      });
    }
  }

  handleAccountChange(val) {
    const component = this;
    if (val) {
      component.setState({ accountId: val.value, accountName: val.label });
      component.setState({ loading: true });
      const request = axios.get(
        `${GET_CONTACTS_OF_ACCOUNT_API_PATH}/${val.value}/?note=true`,
      );
      request
        .then((response) => {
          component.setState({ loading: false });
          component.setState({
            contacts: response.data.data,
            allContacts: response.data.data,
          });
        })
        .catch(() => {
          component.setState({ loading: false });
        });
    } else if (component.state.contactId && !val) {
      this.getAccounts();
    } else if (component.state.contactId) {
      component.setState({ accountId: '', accountName: '' });
      this.handleContactChange({
        value: component.state.contactId,
        label: component.state.contactName,
      });
    } else if (val === null) {
      this.getAccounts();
      component.setState({ accountId: '', accountName: '' });
      this.getContAcc();
    } else {
      component.setState({
        accountId: '',
        accountName: '',
      });
      this.getContAcc();
    }
  }

  getAccounts() {
    const accountRequest = axios.get(GET_ACCOUNTS_API_PATH);
    accountRequest.then((response) => {
      this.setState({
        accounts: response.data.data,
        allAccounts: response.data.data,
        accountId: '',
        accountName: '',
      });
    });
  }

  handleDateChange(input) {
    if (this.state.isEdit) {
      this.setState({
        referenceDate: moment(input),
        editInEditMode: true,
      });
    } else {
      this.setState({ referenceDate: moment(input) });
    }
  }

  viewModal() {
    this.setState({ viewModal: true });
  }

  hideViewModal() {
    this.setState({ viewModal: false });
  }

  handleCommentChange(value) {
    const { errors } = this.state;
    errors.comment = '';
    if (this.state.isEdit) {
      this.setState({ comment: value, editInEditMode: true });
    } else {
      this.setState({ comment: value });
    }
    this.setState({ errors });
  }

  handleSubmit(e) {
    e.preventDefault();
    if (this.handleValidation()) {
      const mydata = {
        note: {
          reference_date: this.state.referenceDate.format('YYYY-MM-DD'),
          account_id: this.state.accountId,
          contact_id:
            this.state.contactId && this.state.contactId.value
              ? this.state.contactId.value
              : null,
          subject: this.state.subject,
        },
      };

      mydata.note.comment = this.state.comment;
      if (this.state.emailId) {
        mydata.email_id = this.state.emailId;
      }

      if (this.state.documents.length && !this.state.isEdit) {
        mydata.doc_ids = [];
        let x;
        for (x = 0; x < this.state.documents.length; x += 1) {
          mydata.doc_ids.push(this.state.documents[x].id);
        }
      } else if (this.state.isEdit) {
        const { initialDocuments, documents } = this.state;
        mydata.doc_ids = [];
        if (documents && documents.length) {
          documents.map((new_doc) => {
            let isNew = true;
            initialDocuments.map((ext_doc) => {
              if (ext_doc.id === new_doc.id) {
                isNew = false;
              }
              return ext_doc;
            });
            if (isNew) {
              mydata.doc_ids.push(new_doc.id);
            }
            return new_doc;
          });
        }
      }
      if (this.state.isEdit) {
        Notes.createNote(mydata, this, this.state.editNoteId);
      } else {
        const { closeModalInEmailPage } = this.props;

        if (typeof closeModalInEmailPage === 'function') {
          closeModalInEmailPage('noteCreated');
        }
        Notes.createNote(mydata, this, undefined);
      }
    }
  }

  handleCancel() {
    const values = this.state;
    let Comment = this.state.comment;
    if (
      typeof Comment === 'object' &&
      EditorState.createWithContent(convertFromRaw(Comment))
        .getCurrentContent()
        .getPlainText().length
    ) {
      Comment = removeBreakElementinList(
        stateToHTML(
          EditorState.createWithContent(
            convertFromRaw(Comment),
          ).getCurrentContent(),
          options,
        ),
      );
    } else {
      Comment = '';
    }
    if (
      !values.isEdit &&
      !values.accountId &&
      !Comment &&
      !values.contactId &&
      !values.documents.length &&
      !values.subject
    ) {
      this.goBack();
    } else if (values.isEdit && values.editInEditMode) {
      this.setState({ viewModal: true });
    } else if (
      values.isEdit &&
      (!values.accountId ||
        !values.contactId ||
        (!values.accountId && !values.contactId))
    ) {
      this.setState({ viewModal: true });
    } else if (values.isEdit && !values.editInEditMode) {
      this.goBack();
    } else if (
      values.contactNote &&
      !values.accountId &&
      !Comment &&
      !values.documents.length &&
      !values.subject
    ) {
      this.goBack();
    } else if (
      values.accountNote &&
      !values.contactId &&
      !Comment &&
      !values.documents.length &&
      !values.subject
    ) {
      this.goBack();
    } else {
      this.setState({ viewModal: true });
    }
  }

  resetAllStates() {
    this.state = {
      accountId: '',
      contactId: '',
      comment: '',
      subject: '',
      loading: false,
      contacts: [],
      accounts: [],
      allAccounts: [],
      allContacts: [],
      notes: [],
      // uploadFiles: [],
    };
  }

  updateDocumentState = (documents) => {
    this.setState({ documents });
  };

  render() {
    const { viewContext } = this.props;
    // TODO: __HOMEDATA-AUDIT
    const homeDataObj = Utilities.getStorageData();
    const dataInitials =
      homeDataObj && homeDataObj.uname
        ? (homeDataObj.uname.split(' ')[0]
            ? homeDataObj.uname.split(' ')[0].charAt(0)
            : '') +
          (homeDataObj.uname.split(' ')[1]
            ? homeDataObj.uname.split(' ')[1].charAt(0)
            : '')
        : '';
    const noneStyle = { display: 'none' };
    const blockStyle = { display: 'block' };

    if (this.state.unauthorisedAccess) {
      return <PageNotFound name="message" message="(Unauthorized Access)" />;
    }

    return (
      <div
        className={`NewNote ${
          viewContext === 'email' ? 'text-left NewNoteEmail' : ''
        }`}
        data-testid="createTaskTestId"
      >
        {viewContext !== 'email' && (
          <header>
            <h1 className="pageHeading">
              {this.state.isEdit ? 'Edit Note' : 'Create Note'}
            </h1>
          </header>
        )}
        <div id="loading" style={this.state.loading ? blockStyle : noneStyle} />
        {/* <Alert html /> */}
        <div
          className={`center-wrapper ${
            viewContext === 'email' ? 'col-12' : 'col-lg-10 col-sm-12 col-md-12'
          } m-auto`}
        >
          {viewContext !== 'email' && (
            <div className="pb-3 mb-4 col-container section-title section-title--bottomborder">
              <h6 role="presentation" className="stepBacklink pointer">
                <span
                  onMouseDown={this.handleCancel}
                  data-testid="notes__go_back"
                >
                  <i className="icon-left-arrow" /> Back
                </span>
              </h6>
            </div>
          )}
          <form onSubmit={this.handleSubmit}>
            <div className="row">
              <SelectField
                name="seachAccount"
                label="For Account:"
                labelClass="mt-1 pt-2"
                error={this.state.errors.account_contact}
                options={this.state.accounts}
                handleChange={this.handleAccountChange}
                handleInputChange={this.handleAccountInput}
                onBlur={this.handleBlurValidation}
                disabled={this.state.accountNote}
                value={
                  this?.state?.accountName && {
                    value: this.state.accountId,
                    label: this.state.accountName,
                  }
                }
                clearable
                from={viewContext === 'email' ? 'emailNotes' : 'notes'}
                testid="notes__for_account"
              />
              <DateField
                label="Date:"
                labelClass="mt-1 pt-2"
                startDate={this.state.referenceDate}
                handleChange={this.handleDateChange}
                minDate={this.state.minDate ? this.state.minDate : moment()}
                maxDate={this.state.maxDate ? this.state.maxDate : undefined}
                testid="notes__date_field"
              />
            </div>
            <div className="row">
              <SelectFieldWithAvatar
                name="seachContact"
                label="For Contact:"
                labelClass="mt-1 pt-2"
                from={viewContext === 'email' ? 'emailNotes' : 'notes'}
                error={this.state.errors.account_contact}
                options={this.state.contacts}
                onBlur={this.handleBlurValidation}
                handleChange={this.handleContactChange}
                handleInputChange={this.handleContactInput}
                disabled={this.state.contactNote}
                value={
                  this.state.contactId
                    ? this.state.contactId
                    : { value: '', label: '' }
                }
                clearable
              />
              <div className="form-group form-inline-label align-items-stretch dropdown col-md-6 col-12">
                <label htmlFor="ownername" className="mt-2 pt-1">
                  {this.state.isEdit ? 'Last Edited By:' : 'Last Modified By:'}
                </label>
                <div className="pt-2 ">
                  <div className="chip selectedUser" id="ownername">
                    {homeDataObj.avatar ? (
                      <>
                        <img
                          src={homeDataObj.avatar}
                          alt="Person"
                          width="20"
                          height="20"
                          className="rounded-circle"
                        />
                        <span className="avatar Notes--uname avatar--w20">
                          <span>{homeDataObj.uname}</span>
                        </span>
                      </>
                    ) : (
                      <span className="avatar Notes--uname avatar--w20">
                        <span className="uName mr-1">{dataInitials}</span>
                        <span>{homeDataObj.uname}</span>
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="taskTitle">Title</label>
              <input
                autoComplete="off"
                name="taskTitle"
                className={`form-control ${
                  viewContext === 'email'
                    ? 'border-right-0 border-top-0 border-left-0'
                    : ''
                }`}
                id="taskTitle"
                type="text"
                value={this.state.subject}
                maxLength="200"
                onChange={this.handleChange}
                data-testid="notes__title"
              />
            </div>
            <div className="row">
              <div
                className={`form-group col-lg-12${
                  this.state.errors.comment ? ' form-error' : ''
                }`}
              >
                <div className="customEditor boxResize">
                  <label htmlFor="Description">Description</label>
                  <TinymceEditor
                    initialValue={this.initialValue}
                    value={this.state.comment}
                    handleChange={this.handleCommentChange}
                  />
                  <span className="error_label">
                    {this.state.errors.comment}
                  </span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="form-group dragWrap col-lg-12">
                <label htmlFor="attachments">Attachments</label>
                <TaskFileUpload
                  data={{
                    ...this.state,
                    stateValues: this.state,
                  }}
                  uploadType="note"
                  isEdit={this.state.isEdit}
                  propsData={this.state}
                  updateDocumentState={this.updateDocumentState}
                  getOnlyPrivateFilesinLiscioVault
                />
              </div>
            </div>
            {/* <div className="row">
              <div className="form-group col-lg-12 dragWrap">
                <label htmlFor="attachments">Attachments</label>
                <AttachementPortion name="notes" handleFileDrop={this.handleFileDrop} data={this.state} setAttachmentNull={this.setAttachmentNull} uploadPopUp="true" uploadDocument={this.uploadDocument} uploadFiles={this.state.uploadFiles} documents={this.state.documents} cancelUpload={this.cancelUpload} openAttachment={this.openAttachment} showModal={this.showModal} component={this} />
              </div>
            </div> */}
            <div className="row">
              {viewContext === 'email' ? (
                <div className="col-12 form-group formbtn justify-content-between">
                  <CreateNotesFromEmailsFooter
                    handleCancel={this.handleCancel}
                    handleSubmit={this.handleSubmit}
                    gettingEmailAttachments={this.state.gettingEmailAttachments}
                  />
                </div>
              ) : (
                <div className="col-12 form-group formbtn justify-content-end">
                  <div className="btn-wrap btn--leftspace">
                    <CreateNotesFooterButton
                      gettingEmailAttachments={
                        this.state.gettingEmailAttachments
                      }
                      handleCancel={this.handleCancel}
                      isEdit={this.state.isEdit}
                      handleSubmit={this.handleSubmit}
                    />
                  </div>
                </div>
              )}
            </div>
            <div>
              <div className="center-wrapper">
                <Modal
                  isOpen={this.state.viewModal}
                  className="customModal customModal--center customModal--alert customModal--w500 customModal--nopadding"
                >
                  <div className="ModalHeader">
                    <button
                      type="button"
                      className="close"
                      aria-label="Close"
                      onClick={this.hideViewModal}
                    >
                      <i className="icon-close2" aria-hidden="true" />
                    </button>
                  </div>
                  <ModalBody className="modalContent">
                    <div className="modalContent--inner">
                      <div className="modalContent--header text-large">
                        <h5 className="modalContent--header__heading">
                          Are you sure want to cancel.
                        </h5>
                      </div>
                      <p>This action cannot be undone. Proceed?</p>
                      <div className="row">
                        <div className="col-12 justify-content-end formbtn justify-content-end">
                          <div className="btn-wrap btn--leftspace">
                            <Button
                              color="secondary"
                              className="btn btn-outline-light"
                              onClick={this.hideViewModal}
                            >
                              No
                            </Button>{' '}
                            <Button
                              color="primary"
                              className="btn btn-primary"
                              onClick={this.goBack}
                            >
                              Yes
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ModalBody>
                </Modal>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ homedata: state.homeReducer.data });

const newForm = reduxForm({ form: 'createNotes' })(CreateNotes);

export default withStorageData(withRouter(connect(mapStateToProps)(newForm)));
