/* eslint-disable import/prefer-default-export */

import Axios from 'axios';
import { AlertMessage, getStorageData } from 'utilities/utils';

const markItemUnread = async ({ url, params, postUpdate }) => {
  try {
    const response = await Axios.post(url, params).then((res) => res.data);
    if (response && response.status && response.status === 200) {
      AlertMessage('success', response.message, 3000);
      if (postUpdate) {
        postUpdate();
      }
    } else {
      AlertMessage('error', response.message, 3000);
    }
  } catch (error) {
    console.log(error);
    AlertMessage('error', 'Some error occured', 3000);
  }
};

const getCpaUserId = () => {
  // TODO: __HOMEDATA-AUDIT
  const local_data = getStorageData();
  return local_data.cpa_user_id;
};

export { markItemUnread, getCpaUserId };
