import HeroImage from 'images/OrganizerTemplates/standard-organizer.png';
import { Box, Divider, Stack, SvgIcon, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { iconMap } from 'components/Sidebar/elements/MenuItemIcon/icons';
import { OrganizerTemplatesHeader } from 'components/OrganizerTemplates/components/OrganizerTemplatesHeader/OrganizerTemplatesHeader';

export type StandardOrganizerProps = {
  current: boolean;
};

export const StandardOrganizer = ({ current }: StandardOrganizerProps) => (
  <Stack gap="1rem">
    <OrganizerTemplatesHeader current={current}>
      2024 Standard Tax Organizer
    </OrganizerTemplatesHeader>
    <img src={HeroImage} alt="Standard Organizer" />
    <Typography>
      The Standard Tax Organizer Template is suited for most clients, covering
      all common tax topics while remaining efficient. This organizer goes
      beyond document uploads, allowing clients to complete a detailed
      Questionnaire and data entry for schedules, itemized deductions, and other
      relevant sections.
    </Typography>
    <Typography>This template is ideal for:</Typography>
    <Box>
      <Typography variant="h6" fontWeight={600}>
        Clients with standard IRS documents
      </Typography>
      <Typography>
        who could benefit from a Questionnaire to identify new filing needs, tax
        strategies, or often-missed deductions.
      </Typography>
    </Box>
    <Box>
      <Typography variant="h6" fontWeight={600}>
        Clients with dependents
      </Typography>
      <Typography>
        who may need to provide information updates for any changes.
      </Typography>
    </Box>
    <Box>
      <Typography variant="h6" fontWeight={600}>
        Clients with income not subject to withholding
      </Typography>
      <Typography>
        who may decide to make an Estimated Tax Payment, or those who were
        instructed to make payments, but only to one or two jurisdictions.
      </Typography>
    </Box>
    <Box>
      <Typography variant="h6" fontWeight={600}>
        Clients who may qualify for Itemized Deductions
      </Typography>
      <Typography>
        even if they don’t consistently meet the threshold. If they itemized in
        the prior year, it will prompt the client to provide full Schedule A
        details.
      </Typography>
    </Box>
    <Box>
      <Typography variant="h6" fontWeight={600}>
        Clients with business schedules
      </Typography>
      <Typography>
        like Schedule C (Sole Proprietorship), Schedule E (Rent/Royalty Income),
        Schedule F (Farming Income), or Form 4835 (Farm Rental).
      </Typography>
    </Box>
    <Box>
      <Typography variant="h6" fontWeight={600}>
        Clients with typical Credits & Deductions
      </Typography>
      <Typography>
        documented on forms such as a childcare expenses statement from a care
        provider, Education Expenses (1098-T), or Student Loan Interest
        (1098-E).
      </Typography>
    </Box>
    <Box>
      <Typography variant="h6" fontWeight={600}>
        Clients needing State-specific questions
      </Typography>
      <Typography>for comprehensive due diligence.</Typography>
    </Box>
    <Divider />
    <Typography variant="h4">
      <SvgIcon>
        <FontAwesomeIcon icon={iconMap['check-list']} />
      </SvgIcon>{' '}
      Key Sections in the Standard Organizer
    </Typography>
    <Box>
      <Typography variant="h6" fontWeight={600}>
        Personal Details
      </Typography>
      <Typography>
        Asks the client to review and update their demographic details
      </Typography>
    </Box>
    <Box>
      <Typography variant="h6" fontWeight={600}>
        Contact Information
      </Typography>
      <Typography>
        Asks the client to review and update their contact details
      </Typography>
    </Box>
    <Box>
      <Typography variant="h6" fontWeight={600}>
        Questionnaire
      </Typography>
      <Typography>
        Asks all necessary IRS due diligence questions and identifies life
        changes
      </Typography>
    </Box>
    <Box>
      <Typography variant="h6" fontWeight={600}>
        Documents
      </Typography>
      <Typography>
        Requests recurring source documents used to prepare the prior year
        return
      </Typography>
    </Box>
    <Box>
      <Typography variant="h6" fontWeight={600}>
        Anything New?
      </Typography>
      <Typography>
        Identifies and requests any new documents required for this year’s
        return
      </Typography>
    </Box>
    <Box>
      <Typography variant="h6" fontWeight={600}>
        Schedule C, Schedule E, Schedule F, Form 4835
      </Typography>
      <Typography fontStyle="italic">
        If filed as part of the prior year return
      </Typography>
    </Box>
    <Box>
      <Typography variant="h6" fontWeight={600}>
        Itemized Deductions
      </Typography>
      <Typography fontStyle="italic">
        If the client itemized deductions on the prior year return
      </Typography>
    </Box>
    <Box>
      <Typography variant="h6" fontWeight={600}>
        State Questions
      </Typography>
      <Typography fontStyle="italic">
        For any State(s) filed in the prior year
      </Typography>
    </Box>
    <Divider />
    <Typography>
      This organizer helps gather the right details for clients who may not need
      a full comprehensive template but still benefit from structured guidance
      on tax filing essentials.
    </Typography>
  </Stack>
);
