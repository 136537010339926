import axios from 'axios';
import { HOME_API_PATH } from 'constants/constants';

export const fetchAll = (data) => ({ type: 'FETCH_HOME_DATA', data });

export const fetchHomeData = (component) => (dispatch) => {
  // TODO: __HOMEDATA-AUDIT
  const request = axios.get(HOME_API_PATH);
  component.setState({ loading: true });
  request.then((response) => {
    component.setState({ loading: false });
    if (response.data.status === 200) {
      dispatch(fetchAll(response.data.data));
    }
  });
};

export const fetchAvatar = (data) => ({
  type: 'FETCH_AVATAR',
  data,
});

export const fetchUserName = (data) => ({
  type: 'FETCH_USER_NAME',
  data,
});
