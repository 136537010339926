/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from 'reactstrap';

const MulitpleSelectDropdown = ({
  dropdownFor,
  data,
  recordsViewHeight,
  hasMore,
  onClick,
  getMoreData,
  onSelect,
  handleSearch,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchFieldText, setSearchFieldText] = useState('');

  const handleSearchField = (input) => {
    const inputvalue = input && input.target.value ? input.target.value : '';
    setSearchFieldText(inputvalue);
    if (input === null) {
      handleSearch(null);
    } else {
      handleSearch(inputvalue);
    }
  };

  const toggleDropdown = () => {
    const updateDropdown = !dropdownOpen;
    setDropdownOpen(updateDropdown);
    onClick(updateDropdown);
    handleSearchField(null);
  };

  const handleKeyPress = (e, index = 0) => {
    if (e.key === 'Enter' && data && data !== undefined && data[index]) {
      onSelect(data[index], index);
      // toggleDropdown();
      // handleSearchField();
    }
  };
  return (
    <Dropdown
      isOpen={dropdownOpen}
      toggle={toggleDropdown}
      style={{
        margin: 0,
        padding: 0,
        width: '100%',
        position: 'absolute',
        top: 0,
        height: 'auto',
        zIndex: 1,
        minHeight: recordsViewHeight,
      }}
    >
      <DropdownToggle
        style={{
          width: '100%',
          minHeight: recordsViewHeight,
          backgroundColor: 'transparent',
          border: 'none',
        }}
      />
      <DropdownMenu
        id="unassigned_accounts"
        style={{ width: '275px', maxHeight: '300px', zIndex: 2 }}
      >
        <div style={{ position: 'relative' }}>
          <div
            className="input-group mb-2 pb-1"
            style={{
              borderBottom: '2px solid #919eab',
              backgroundColor: 'white',
              position: 'sticky',
              top: 0,
              zIndex: 3,
            }}
          >
            <input
              autoFocus
              type="text"
              className="pl-2 form-control mt-1 border-0"
              name="recipientValue"
              id="Accounts"
              value={searchFieldText}
              onChange={handleSearchField}
              autoComplete="off"
              onKeyPress={(e) => handleKeyPress(e)}
            />
            <span className="input-group-addon border-left-0 pt-1">
              <i className="icon-zoom2" />
            </span>
          </div>
          <InfiniteScroll
            dataLength={data.length}
            next={getMoreData}
            hasMore={hasMore}
            loader={<span className="pl-3 pb-2">Getting more data...</span>}
            scrollableTarget="unassigned_accounts"
          >
            {data && data
              ? data.map((e, index) => (
                  <DropdownItem
                    className="pt-1 pb-1"
                    key={e.id}
                    onClick={() => onSelect(e, index)}
                    onKeyUp={(event) => handleKeyPress(event, index)}
                    toggle={false}
                  >
                    <div className="row no-gutters align-items-center">
                      <div className="col pl-1">
                        <h5 className="fontSize mb-1">
                          {dropdownFor === 'Account' ? e.name : e.label}
                        </h5>
                      </div>
                    </div>
                  </DropdownItem>
                ))
              : null}
          </InfiniteScroll>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default MulitpleSelectDropdown;
