// eslint-disable-next-line no-useless-constructor
/* eslint-disable */
import React, { Component } from 'react';
import Axios from 'axios';
import {
  OAUTH_ACCESS_TOKENS,
  GOOGLE_FILE_PICKER_API_KEY,
  GOOGLE_FILE_PICKER_APP_ID, DMS_FILE_EXPORT,
} from 'constants/constants';
import {
  getCpaPreferences,
  AlertMessage,
  processedFilesLength
} from 'utilities/utils.js';

class GooglePicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pickerApiLoaded: false,
      exportMode: props.mode === 'export',
      selectedRecordIds: (props.selectedRecords || []).map(a => a.id),
      developerKey: GOOGLE_FILE_PICKER_API_KEY,
      appId: GOOGLE_FILE_PICKER_APP_ID
    };
  }

  componentDidMount() {
    const request = Axios.get(`${OAUTH_ACCESS_TOKENS}/googledrive`);
    request.then((response) => {
      if (response.status === 200) {
        if (response.data.access_token) {
          this.setState({ accessToken: response.data.access_token })
          window.gapi.load('picker', { callback: this.onPickerApiLoad });
        }
      }
    });
  }

  onPickerApiLoad = () => {
    this.setState({ pickerApiLoaded: true })
    return this.state.exportMode ? this.exportPicker() : this.importPicker();
  }

  base64ArrayBuffer = (arrayBuffer) => {
    let base64 = '';
    const encodings = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';
    const bytes = new Uint8Array(arrayBuffer);
    const byteLength = bytes.byteLength;
    const byteRemainder = byteLength % 3;
    const mainLength = byteLength - byteRemainder;
    let a, b, c, d;
    let chunk;
    // Main loop deals with bytes in chunks of 3
    for (let i = 0; i < mainLength; i += 3) {
      // Combine the three bytes into a single integer
      chunk = (bytes[i] << 16) | (bytes[i + 1] << 8) | bytes[i + 2];
      // Use bitmasks to extract 6-bit segments from the triplet
      a = (chunk & 16515072) >> 18; // 16515072 = (2^6 - 1) << 18
      b = (chunk & 258048) >> 12; // 258048   = (2^6 - 1) << 12
      c = (chunk & 4032) >> 6; // 4032     = (2^6 - 1) << 6
      d = chunk & 63; // 63       = 2^6 - 1
      // Convert the raw binary segments to the appropriate ASCII encoding
      base64 += encodings[a] + encodings[b] + encodings[c] + encodings[d];
    }
    // Deal with the remaining bytes and padding
    if (byteRemainder === 1) {
      chunk = bytes[mainLength];
      a = (chunk & 252) >> 2; // 252 = (2^6 - 1) << 2
      // Set the 4 least significant bits to zero
      b = (chunk & 3) << 4; // 3   = 2^2 - 1
      base64 += encodings[a] + encodings[b] + '==';
    } else if (byteRemainder === 2) {
      chunk = (bytes[mainLength] << 8) | bytes[mainLength + 1];
      a = (chunk & 64512) >> 10; // 64512 = (2^6 - 1) << 10
      b = (chunk & 1008) >> 4; // 1008  = (2^6 - 1) << 4
      // Set the 2 least significant bits to zero
      c = (chunk & 15) << 2; // 15    = 2^4 - 1
      base64 += encodings[a] + encodings[b] + encodings[c] + '=';
    }
    return base64;
  }

  exportPicker = () => {
    if (this.state.pickerApiLoaded && this.state.accessToken) {
      const enabledDrives = new google.picker.DocsView()
        .setMode(window.google.picker.DocsViewMode.LIST)
        .setIncludeFolders(true)
        .setSelectFolderEnabled(true)
        .setEnableDrives(true)

      const sharedDrives = new google.picker.DocsView()
        .setMode(window.google.picker.DocsViewMode.LIST)
        .setIncludeFolders(true)
        .setSelectFolderEnabled(true)
        .setOwnedByMe(false)

      const picker = new window.google.picker.PickerBuilder()
        .setTitle('Select Folder')
        .addView(enabledDrives)
        .addView(sharedDrives)
        .setSelectableMimeTypes('application/vnd.google-apps.folder')
        .setAppId(this.state.appId)
        .setOAuthToken(this.state.accessToken)
        .setCallback(this.exportCallback)
        .build();
      picker.setVisible(true);
    }
  }

  exportCallback = (data) => {
    if (data.action === window.google.picker.Action.PICKED) {
      const folder = data.docs[0]
      if (!folder.isShared) {
        AlertMessage('warning', 'Must select a shared folder!', 3000);
      } else {
        this.uploadSelectedFiles(folder.id)
      }
    } else {
      this.props.cancelWidget()
    }
  }

  uploadSelectedFiles = async (folderId) => {
    const data = {
      app_key: 'googledrive',
      remote_directory: folderId,
      document_ids: this.state.selectedRecordIds
    }
    const request = Axios.post(DMS_FILE_EXPORT, data);
    request
      .then(response => {
        AlertMessage('success', response.data.message, 3000);
        this.props.cancelWidget();
      })
      .catch(error => {
        AlertMessage('error', 'Upload failed!', 3000);
      })
  }

  importPicker = () => {
    if (this.state.pickerApiLoaded && this.state.accessToken) {
      const enabledDrives = new google.picker.DocsView()
        .setMode(window.google.picker.DocsViewMode.LIST)
        .setIncludeFolders(true)
        .setSelectFolderEnabled(true)
        .setEnableDrives(true)

      const sharedDrives = new google.picker.DocsView()
        .setMode(window.google.picker.DocsViewMode.LIST)
        .setIncludeFolders(true)
        .setSelectFolderEnabled(true)
        .setOwnedByMe(false)

      const picker = new window.google.picker.PickerBuilder()
        .addView(new google.picker.DocsView().setIncludeFolders(true).setOwnedByMe(true))
        .addView(enabledDrives)
        .addView(sharedDrives)
        .enableFeature(window.google.picker.Feature.MULTISELECT_ENABLED)
        .setAppId(this.state.appId)
        .setOAuthToken(this.state.accessToken)
        .setCallback(this.importCallback)
        .build();
      picker.setVisible(true);
    }
  }

  importCallback = (data) => {
    if (data.action === window.google.picker.Action.PICKED) {
      let len = 0;
      const docLen = data.docs.length;
      data.docs.forEach((doc) => {
        const validType = getGoogleFilesMimetype(doc.mimeType);
        if (doc.mimeType && validType) {
          const sizeInMB = doc.sizeBytes ? (doc.sizeBytes / (1024 * 1024)).toFixed(2) : '';
          if (getCpaPreferences() && getCpaPreferences().external_storage_file_size) {
            if (
              doc.sizeBytes &&
              sizeInMB &&
              parseInt(sizeInMB, 10) > parseInt(getCpaPreferences().external_storage_file_size[0].label, 10)
            ) {
              len += 1;
              const msg =
                `Liscio does not store files larger than ${getCpaPreferences().external_storage_file_size[0].label} MB`
              AlertMessage('error', msg, 3000);
              processedFilesLength(len, docLen, this);
            } else {
              const head = {
                headers: {
                  Authorization: `Bearer ${this.state.accessToken}`,
                }
              };
              this.props.googleDriveLoader('true');
              Axios.get(`https://www.googleapis.com/drive/v2/files/${doc.id}?supportsAllDrives=true`, head).then((res) => {
                if (res.data.downloadUrl) {
                  const header = {
                    headers: {
                      Authorization: `Bearer ${this.state.accessToken}`,
                    },
                    responseType: 'blob',
                  };
                  Axios.get(res.data.downloadUrl, header).then((resp) => {
                    const file = new Blob([resp.data], { type: doc.mimeType });
                    let arrayBuffer;
                    const fileReader = new FileReader();
                    fileReader.readAsArrayBuffer(file);
                    fileReader.onload = (event) => {
                      arrayBuffer = event.target.result;
                      const bufferdata = this.base64ArrayBuffer(arrayBuffer);
                      const fileData = {
                        name: getFileExtensionForGoogleMimetype({ name: doc.name, mimetype: doc.mimeType }),
                        size: doc.sizeBytes,
                        file: bufferdata,
                        type: doc.mimeType,
                      };
                      const selectedfile = [];
                      selectedfile.push(fileData);
                      len += 1;
                      this.props.startUpload(selectedfile, 'google_drive');
                      processedFilesLength(len, docLen, this);
                    };
                  });
                } else if (res.data.exportLinks) {
                  const url = Object.values(res.data.exportLinks)[
                    Object.values(res.data.exportLinks).length - 1
                  ];
                  const downloadUrl = `${url}&access_token=${this.state.accessToken}`;
                  const fileData = {
                    name: getFileExtensionForGoogleMimetype({ name: doc.name, mimetype: doc.mimeType }),
                    size: doc.sizeBytes,
                    '@microsoft.graph.downloadUrl': downloadUrl,
                    file: { mimeType: doc.mimeType },
                  };
                  const selectedfile = [];
                  selectedfile.push(fileData);
                  len += 1;
                  this.props.startUpload(selectedfile, 'onedrive', len, docLen);
                  processedFilesLength(len, docLen, this);
                } else {
                  len += 1;
                  processedFilesLength(len, docLen, this);
                }
              });
            }
          }
        } else {
          len += 1;
          AlertMessage(
            'error',
            'Liscio does not support this file type. Web-only documents such as Google Docs/Spreadsheets do not sync. If you would like to see support for a particular file type, please let us know at support@liscio.me',
            3000
          );
          processedFilesLength(len, docLen, this);
        }
      });
    }
  }

  render() {
    return <div id="result" />;
  }
}

export default GooglePicker;

const getFileExtensionForGoogleMimetype = ({ mimetype, name }) => {
  switch (mimetype) {
    case 'application/vnd.google-apps.spreadsheet':
      return `${name}.ods`;
    case 'application/vnd.google-apps.document':
      return `${name}.docx`;
    case 'application/vnd.google-apps.presentation':
      return `${name}.docx`;
    default:
      return name;
  }
};

const getGoogleFilesMimetype = (mimetype = '') => {
  let validMimeType = true;
  if (
    mimetype === 'application/vnd.google-apps.spreadsheet' ||
    mimetype === 'application/vnd.google-apps.document' ||
    mimetype === 'application/vnd.google-apps.presentation' ||
    mimetype === 'application/vnd.google-apps.form'
  ) {
    validMimeType = false;
  }
  return validMimeType;
};
