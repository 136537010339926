// @ts-ignore
import { faArrowsLeftRight, faArrowsRotate } from '@awesome.me/kit-7c5308637e/icons/classic/regular';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom-latest";
import Select from 'react-select';
import moment from 'moment';
import Axios from 'axios';
import './styles.scss'
import { debounce } from 'lodash';
import Pagination from 'common/Pagination';
import { useWebsocket } from 'websocket';
import {
  PaginationData,
  ServiceAlias,
  ServiceAliases,
  defaultPagination,
  defaultServiceAlias,
  SelectOption,
} from './types';
import {
  SERVICE_ALIASES,
  SMART_VAULT_ACCOUNT_SYNC,
} from '../../../constants/constants';
import { AlertMessage, getCpaUserId } from '../../../utilities/utils';
import { selectStyles } from '../../../common/CustomFields';
import EditLinkingModal from './EditLinkingModal';

export default function SmartVaultConfig () {
  const SYNC_MESSAGE = "Syncing accounts from SmartVault. This page will refresh when complete."
  const navigate = useNavigate();
  const [serviceAliasData, setServiceAliasData] = useState<ServiceAliases>([]);
  const [paginationData, setPaginationData] = useState<PaginationData>(defaultPagination);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [isSyncing, setIsSyncing]= useState(false);
  const [showOrphans, setShowOrphans] = useState(false);
  const [serviceAliasSearchResults, setServiceAliasSearchResults] = useState([]);
  const [serviceAliasesQuery, setServiceAliasesQuery] = useState(null);
  const [selectedServiceAlias, setSelectedServiceAlias] = useState<SelectOption | null>(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editableServiceAlias, setEditableServiceAlias] = useState<ServiceAlias>(defaultServiceAlias);
  const websocket = useWebsocket();

  const activateActionCable = () => {
    const subscription = websocket.consumer.subscriptions.create(
      { channel: 'SmartVaultAccountSyncChannel', cpa_user_id: getCpaUserId() },
      {
        received: () => {
          setIsSyncing(false)
          fetchServiceAliases()
          AlertMessage('success', 'Synchronization complete!', 3000);
        }
      }
    )

    return subscription;
  }

  const queryParams = () => {
    const defaultQueryParams = {
      aliasable_type: 'Account',
      service_id: 'smartvault',
      external_id: selectedServiceAlias?.value,
      filter_orphaned: showOrphans,
      sort: 'external_name'
    }

    return { ...defaultQueryParams, page }
  }

  const fetchServiceAliases = () => {
    setIsLoading(true);
    Axios.get(SERVICE_ALIASES, { params: queryParams() })
      .then(res => {
        setServiceAliasData(res.data.data)
        setPaginationData(res.data.meta);
        setIsLoading(false);
      })
      .catch(error => {
        AlertMessage('error', error.message, 3000);
        setIsLoading(false);
      })
  };

  const fetchServiceAliasSearch = () => {
    Axios.get(SERVICE_ALIASES, { params: { ...queryParams(), query: serviceAliasesQuery } })
      .then(res => {
        setServiceAliasSearchResults(res.data.data.map((serviceAlias: ServiceAlias) => (
          { label: serviceAlias.external_name, value: serviceAlias.external_id }
        )))
      })
      .catch(error => {
        AlertMessage('error', error.message, 3000);
      })
  }

  const runSync = () => {
    setIsSyncing(true)
    Axios.post(SMART_VAULT_ACCOUNT_SYNC)
    AlertMessage('success', SYNC_MESSAGE, 3000)
  }

  const paginationOffset = () => {
    if (paginationData.total_entries === 0) { return 0 }

    return (paginationData.current_page * paginationData.per_page) - paginationData.per_page + 1
  }

  const paginationTo = () => paginationOffset() + serviceAliasData.length - 1

  useEffect(() => {
    fetchServiceAliases();
  }, [showOrphans, selectedServiceAlias, page]);

  useEffect(() => {
    if (serviceAliasesQuery) fetchServiceAliasSearch();
  }, [serviceAliasesQuery]);

  useEffect(() => {
    document.title = 'SmartVault Account Links';
    fetchServiceAliasSearch()
  }, []);

  useEffect(() => {
    const subscription = activateActionCable();

    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, []);

  const handleEdit = (serviceAlias: ServiceAlias) => {
    setEditableServiceAlias(serviceAlias)
    setShowEditModal(true)
  }

  function handlePageChange(index: number) {
    setPage(index);
  }

  const handleSelectedServiceAliasChange = (value: SelectOption | null) => {
    setPage(1)
    setSelectedServiceAlias(value)
  }

  const handleShowOrphansChange = (value: boolean) => {
    setPage(1)
    setShowOrphans(value)
  }

  return(
    <div id="content">
      {isLoading ? <div id="loading" /> : null}
      <header>
        <div className="d-block">
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a onClick={() => navigate("/profile_settings?tab=third_party_apps")} className="mr-1">
                <i className="icon-left-arrow label-small" aria-hidden="true" />
                THIRD PARTY APPS /
              </a>
              <span>
                Liscio <FontAwesomeIcon icon={faArrowsLeftRight} /> SmartVault
              </span>
            </li>
          </ul>
        </div>
      </header>
      <div className="center-wrapper">
        <div className="page-title mb-4">
          SmartVault Account Links
        </div>
        <div className="filter-wrapper d-flex">
          <div className="select-wrapper">
            <Select
              placeholder="Search SmartVault Accounts"
              className="AccountSelect"
              styles={selectStyles}
              options={serviceAliasSearchResults}
              onInputChange={debounce((e) => {setServiceAliasesQuery(e)}, 400)}
              onChange={(e) => handleSelectedServiceAliasChange(e)}
              value={selectedServiceAlias}
              isSearchable
              isClearable
            />
          </div>
          <div className="checkbox checkbox-primary check-container mt-2">
            <label htmlFor="selectallcheckbox">
              <input
                type="checkbox"
                className="form-check-input"
                checked={showOrphans}
                onChange={() => handleShowOrphansChange(!showOrphans)}
              />
              <i className="checkmark"/>
              <span className="text-body pl-2">Show Unmatched Accounts</span>
            </label>
          </div>
          <div style={{ marginLeft: 'auto' }}>
            <button
              type="button"
              style={{ height: '36px', padding: "0" }}
              onClick={() => runSync()}
              className="btn btn-primary px-3"
            >
              <span className="mr-2">
                <FontAwesomeIcon icon={faArrowsRotate} className={isSyncing ? "fa-spin" : ""} />
              </span>
              Sync Accounts
            </button>
          </div>
        </div>

        <div className="tableWrap">
          <div className="tRow tRow--head">
            <div className="row">
              <div className="col thCol">SmartVault Account</div>
              <div className="col thCol">Liscio Account</div>
              <div className="col thCol">Date Created</div>
              <div className="col-1"/>
            </div>
          </div>

          <div className="tRow tRow--body">
            {serviceAliasData.map((each) => (
              <div className="row tdBtn" key={each.id} >
                <div className="col tdCol">{each.external_name}</div>
                <div className="col tdCol">{each.aliasable?.name || '-'}</div>
                <div className="col tdCol">{moment(each.created_at).format('MM/DD/YYYY')}</div>
                <div className="col-1">
                  <div className="btn--onlyicon btn-link tdBtn__hover px-1">
                    <i className="icon-edit" onClick={() => handleEdit(each)} />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <Pagination
          currentPage={paginationData.current_page}
          totalPages={paginationData.total_pages}
          totalRecords={paginationData.total_entries}
          offset={paginationOffset()}
          off={paginationTo()}
          handlePageChange={handlePageChange}
        />
      </div>
      {showEditModal &&
        <EditLinkingModal
          serviceAlias={editableServiceAlias}
          serviceAliasData={serviceAliasData}
          setServiceAliasData={setServiceAliasData}
          closeModal={() => setShowEditModal(false)}
        />
      }
    </div>
  )
}
