/* eslint-disable consistent-return */
import Clear from '@mui/icons-material/Clear';
import {
  CSSInterpolation,
  Color,
  ThemeOptions,
  chipClasses,
  formControlLabelClasses,
  outlinedInputClasses,
} from '@mui/material';
import { createTheme } from '@mui/material/styles';

const midGray = '#919EAB';
// these variables are from the new palette https://www.figma.com/file/aeBX5Q4JVM8lRqS1ZhZGCC/Liscio-Design-System?type=design&node-id=1-3207&t=UJF09SQ8eLm4Gk3h-0
// to convert from the figma palette
// if (50) figma === material shade
// else figma*2 === material shade
// ie a 400 in figma is 800 for material
const cyan = {
  50: '#e6f7fa',
  200: '#b3e7f0',
  300: '#80d7e6',
  500: '#00aecc', // primary color https://github.com/Liscio-Inc/liscio-refactor/blob/main/src/scss/variables.scss#L2
  600: '#008ba3',
  700: '#00687a',
};

// TODO copy over the rest of the shades from the pallete on figma
const red = {
  500: '#de3618',
};

const liscioPink = '#eb2aac';

// the figma has 9 values instead of the 10 expected by Mui, using their suggested tool to generate colors to fill the gap https://m2.material.io/inline-tools/color/
const gray: Color = {
  50: '#f9fafb', // light sky https://github.com/Liscio-Inc/liscio-refactor/blob/main/src/scss/variables.scss#L8
  100: '#f1f2f3', // generated by https://m2.material.io/inline-tools/color/
  200: '#e0e1e2',
  300: '#c7c8c9',
  400: '#aeafb0',
  500: '#959697',
  600: '#7d7d7e',
  700: '#646464',
  800: '#4b4b4b',
  900: '#1919191',
  // analogous colors generated from https://m2.material.io/inline-tools/color/
  A100: '#f1f3f3',
  A200: '#e8eaea',
  A400: '#b5b7b7',
  A700: '#595b5b',
};

const blueGray = {
  50: '#eef0f1',
  100: '#ecf0f5', // theme.palette.secondary.main; sky https://github.com/Liscio-Inc/liscio-refactor/blob/main/src/scss/variables.scss#L7
  200: '#dfe3e8',
  300: '#cdd1d4',
  400: '#acb2b7',
  500: '#8a939a',
  600: '#58646E',
  800: '#353c42',
};

const green = {
  500: '#00ac4f',
};

const spacing = 5;

const lightThemeOptions: ThemeOptions = {
  spacing,
  shape: {
    borderRadius: 2,
  },
  palette: {
    mode: 'light',
    // primary secondary and error are the full shade array that corresponds to the
    // corresponding Palette object one level up
    common: {
      primary: cyan,
      secondary: blueGray,
      error: red,
      contrastBackground: blueGray[200],
      darkGray: blueGray[600],
      midGray, // there is no matching color for this in the figma palettes
    },
    primary: {
      ...cyan,
      main: cyan[500],
      dark: cyan[600],
      light: cyan[300],
      contrastText: 'white',
    },
    secondary: {
      ...blueGray,
      light: blueGray[50],
      main: blueGray[100],
      dark: blueGray[400],
      contrastText: blueGray[800],
    },
    error: {
      main: red[500],
    },
    success: {
      main: green[500],
      contrastText: 'white',
    },
    info: {
      dark: cyan[500],
      main: liscioPink,
      light: cyan[50],
    },
    text: {
      primary: blueGray[800],
      secondary: blueGray[500],
    },
    grey: gray,
  },
  typography: (palette) => ({
    // lineHeight: 1.3,
    fontFamily: "'Source Sans Pro', Sans-serif",
    // marginBottom: 0,
    h1: {
      fontSize: '2rem',
      fontWeight: 400,
    },
    h2: {
      fontSize: '1.625rem',
      fontWeight: 400,
    },
    h3: {
      fontSize: '1.25rem',
      fontWeight: 400,
    },
    h4: {
      fontSize: '1.125rem',
      fontWeight: 400,
    },
    h5: {
      fontSize: '.9rem',
      letterSpacing: '.0625rem',
      textTransform: 'uppercase',
      fontWeight: 400,
    },
    subtitle1: {
      fontSize: '.9375rem',
      fontWeight: 'bold',
    },
    subtitle2: {
      fontSize: '0.75rem',
      color: palette.text.secondary,
      fontWeight: 'bold',
    },
    body1: {
      fontSize: '.9375rem',
      lineHeight: '135%',
      fontWeight: 400,
      textTransform: 'inherit',
      letterSpacing: 'inherit',
    },
    body2: {
      fontSize: '.75rem',
      fontWeight: 'bold',
      lineHeight: '120%',
      letterSpacing: '.0625rem',
      textTransform: 'uppercase',
      color: palette.text.secondary,
    },
  }),
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          [`& .${outlinedInputClasses.notchedOutline}`]: {
            padding: '.375rem .625rem',
            borderRadius: '4px',
            borderColor:
              ownerState.color && ownerState.color !== 'primary'
                ? theme.palette[ownerState.color].main
                : blueGray[500],
            borderWidth: '2px',
          },
        }),
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          [`& .${formControlLabelClasses.label}.${formControlLabelClasses.disabled}`]:
            {
              color: 'inherit',
            },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: ({ ownerState, theme }) => ({
          padding: '.375rem .625rem',
          borderRadius: '4px',
          borderWidth: '2px',
          borderColor:
            ownerState.color && ownerState.color !== 'primary'
              ? theme.palette[ownerState.color].main
              : blueGray[300],
        }),
        root: ({ theme }) => ({
          '&.MuiInput-underline:before': {
            borderBottomColor: theme.palette.secondary.main,
          },
          '&.MuiInput-underline:hover:not(.Mui-disabled):before': {
            borderBottomColor: theme.palette.secondary.main, // disable hover changing color
            borderBottomWidth: 1,
          },
          '&.MuiInput-underline:after': {
            borderBottomColor: theme.palette.secondary.main, //disable the animation and coloration applied on focus
          },
        }),
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: blueGray[800],
          fontWeight: 700,
          fontSize: '12px',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: { color: red[500] },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: ({ ownerState, theme }) => {
          if (ownerState.variant === 'standard') {
            return {
              '& .MuiInput-underline': {
                padding: '0.1rem 0.5rem',
                fontSize: '0.94rem',
                color: '#212b36',
                height: 'auto',
                border: `${
                  ownerState.error
                    ? `2px solid ${red[500]}`
                    : '2px solid #dfe3e8'
                }`,
                backgroundColor: 'transparent',
                borderRadius: '2px',
              },
              '& .Mui-focused': {
                borderColor: `${ownerState.error ? red[500] : '#919eab'}`,
              },
              '& .MuiInput-underline:before': {
                borderBottom: '0px',
              },
              '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                borderBottom: '0px',
              },
              '& .MuiInput-underline:after': {
                borderBottom: '0px',
              },
            };
          }
          return {
            [`& .${outlinedInputClasses.input}`]: {
              padding: '.375rem',
              borderRadius: '2px',
              border: `2px solid ${theme.palette.common.secondary[200]}`,
            },
          };
        },
      },
    },
    MuiChip: {
      defaultProps: {
        deleteIcon: <Clear />,
      },
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            // https://mui.com/system/getting-started/the-sx-prop/#spacing
            px: 1,
            py: 0.25,
            // https://mui.com/system/borders/#border-radius
            borderRadius: 1, // 4px as default.
            backgroundColor: theme.palette.secondary.light,
            [`& .${chipClasses.deleteIcon}`]: {
              color: theme.palette.grey[800],
              fontSize: '1rem',
            },
          }),
      },
    },
    MuiIconButton: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        disableElevation: true,
      },
      styleOverrides: {
        root: ({ ownerState, theme }) => {
          let rootCSS: CSSInterpolation = {};

          const boxShadow = { boxShadow: '0 5px 25px rgba(0, 0, 0, 0.1)' };
          const common: CSSInterpolation = {
            textTransform: 'none',
            boxSizing: 'border-box',
            transition: 'all ease-in-out 200ms',
            '&:hover': { boxShadow },
            gap: '2px',
          };
          if (ownerState.variant === 'contained') rootCSS = common;
          if (ownerState.variant === 'outlined') {
            rootCSS = {
              ...common,
              color: ownerState.color || theme.palette.text.primary,
              border: `2px solid ${
                ownerState.color || theme.palette.common.secondary[200]
              }`,
              '&:hover': {
                boxShadow,
                backgroundColor: 'white',
                border: `2px solid ${
                  ownerState.color || theme.palette.common.secondary[300]
                }`,
              },
            };
          }

          if (ownerState.variant === 'text') {
            rootCSS = {
              '&:hover': {
                backgroundColor: 'transparent',
              },
            };
          }

          if (ownerState.disabled) {
            let color;
            let backgroundColor;
            if (ownerState.variant === 'outlined') {
              color = theme.palette.common.secondary[300];
              backgroundColor = 'white';
            } else if (ownerState.variant === 'text') {
              color = ownerState.color || theme.palette.common.secondary[300];
              backgroundColor = 'white';
            } else {
              switch (ownerState.color) {
                case 'primary':
                  color = theme.palette.common.secondary[300];
                  backgroundColor = theme.palette.common.secondary[500];
                  break;
                case 'secondary':
                  color = theme.palette.common.secondary[300];
                  backgroundColor = theme.palette.secondary.light;
                  break;

                default:
                  color = theme.palette.common.secondary[300];
                  backgroundColor = theme.palette.common.secondary[500];
                  break;
              }
            }
            rootCSS['&.Mui-disabled'] = {
              backgroundColor,
              color,
            };
          }
          return rootCSS;
        },
        startIcon: {
          '& >*:nth-of-type(1)': {
            fontSize: 'inherit',
          },
        },
        endIcon: {
          '& >*:nth-of-type(1)': {
            fontSize: 'inherit',
          },
        },
        sizeSmall: { padding: '0px 8px', fontSize: '.75rem' },
        sizeMedium: { padding: '6px 12px', fontSize: '.875rem' },
        sizeLarge: { padding: '12px 20px', fontSize: '1rem' },
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: 'span',
          h2: 'span',
          h3: 'span',
          h4: 'span',
          h5: 'span',
          h6: 'span',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          padding: '.375rem .625rem',
        },
      },
    },
  },
};

const liscioLightTheme = createTheme(lightThemeOptions);

export default liscioLightTheme;
