import React from 'react';
import { SelectField } from 'common/CustomFields';
import Button from 'common/Button/Button';
import { BUTTON_ICON_LOCATION_BACK } from 'constants/constants';
import AddressComponent from './AddressComponent';

const generateRandom = (input) => input;
const AddressSection = (props) => {
  const { data, errors, disabled } = props;
  return data.map((each, key) => (
    <div key={generateRandom(key)}>
      <div className="row no-gutters align-items-center mt-2 mb-2">
        <h5 className="mb-0">{`${key + 1}. Address`}</h5>
        {(data.length > 1 && data.length - 1 !== key) ||
        Object.keys(each).length > 1 ? (
          <Button
            variant="ghost"
            icon="delete-small"
            onClick={() => props.deleteField(key)}
            disabled={disabled}
            size="xsmall"
            iconLocation={BUTTON_ICON_LOCATION_BACK}
          />
        ) : null}
      </div>
      <div className="row">
        <SelectField
          name={`addresstype${key}`}
          label="Use for"
          options={props.options}
          handleChange={(input) => props.onChange(key, 'type', input)}
          value={{ value: each.type, label: each.type }}
          clearable={false}
          className="col-5 form-group"
          disabled={disabled}
          error={errors && errors[key] ? errors[key].type : undefined}
        />
      </div>
      <AddressComponent
        name={`addresstype${key}`}
        data={each}
        fieldNames={props.fieldNames}
        errors={errors[key]}
        disabled={disabled}
        onChange={(fieldname, input) => props.onChange(key, fieldname, input)}
        handleAddressSelect={(input) => props.handleAddressSelect(key, input)}
      />
      {data.length - 1 === key ? (
        <Button
          variant="ghost"
          label="+ Add another address"
          onClick={() => props.addNewField()}
          disabled={props.enableAddButton || disabled}
          size="xsmall"
        />
      ) : null}
    </div>
  ));
};

export default AddressSection;
