/* eslint-disable import/no-cycle */
import React from 'react';
import { Link } from 'react-router-dom-latest';
import styled from '@emotion/styled';
import { ReactComponent as IconTask } from 'images/icons/create-task.svg';
import { ReactComponent as IconMessage } from 'images/icons/message-reply.svg';
import EmailActionsDropdown from './EmailActionsDropdown';

const StyledDiv = styled.div`
  background-color: #fff;
  border: 1px solid #ececec;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  margin: 0.6rem 0 0.6rem 0;

  .TM_row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  .TM_col {
    flex-grow: 1;
    max-width: 75%;
    position: relative;
    width: 100%;
  }

  .TM_text {
    font-weight: 600;
    text-transform: uppercase;
    text-align: right;
  }

  .TM_icon {
    flex: 0 0 25%;
    max-width: 25%;
    position: relative;
    width: 100%;

    .TM_action {
      color: #00aecc;
      padding-left: 0.5rem;
    }
  }
  .messageDetailsLinked {
    .button-icon {
      border: 0;
      background: #eee;
      width: 22px;
      .icon-more {
        transform: rotate(90deg);
      }
    }
    .icon-notes {
      color: #8a939a;
      margin-right: 3px;
    }
    .messageBlock svg {
      margin-top: -2px;
      margin-right: 5px;
    }
  }
`;

const linkedTypeIcon = (type) => {
  switch (type) {
    case 'task':
      return <IconTask width="1rem" />;
    case 'message':
      return <IconMessage width="1rem" />;
    case 'note':
      return <i className="icon-notes" aria-hidden="true" />;
    default:
      return <IconMessage width="1rem" />;
  }
};

function LinkedWith({
  id,
  linkedType,
  refreshPage,
  title,
  to,
  liscio_email_thread_link_id,
}) {
  return (
    <StyledDiv key={id} data-testid="linkedEmail">
      <div className="messageDetails messageDetailsLinked">
        <div className="TM_row">
          <div className="TM_col">
            <div className="messageBlock">
              <span className="label-submedium ">
                <span className="TM_text">
                  {linkedTypeIcon(linkedType)}
                  {'   '}
                  {linkedType}
                </span>
                <i className="divOption divOption--dark" />
                <Link to={to}>{title}</Link>
              </span>
            </div>
          </div>
          <div className="TM_icon">
            <div className="TM_text">
              <span className="TM_action">
                <EmailActionsDropdown
                  IconClass="text-primary"
                  emailDetails={{ liscio_email_thread_link_id }}
                  linkedType={linkedType}
                  refreshPage={refreshPage}
                  viewContextFrom="linkedwith"
                />
              </span>
            </div>
          </div>
        </div>
      </div>
    </StyledDiv>
  );
}

export default LinkedWith;
