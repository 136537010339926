/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import axios from 'axios';
// import Select from 'react-select';
import Select, { components } from 'react-select';
import { stateToHTML } from 'draft-js-export-html';
import { Button } from 'reactstrap';
import { EditorState, convertFromRaw } from 'draft-js';
import {
  options,
  removeBreakElementinList,
  generateUniqueHash,
} from 'common/NotesUtilities';
import { AlertMessage, getCurrentPath, getStorageData } from 'utilities/utils';
import * as TaskApi from 'api/TaskApi';
import {
  SelectField,
  SelectTemplateFieldOption,
  selectStyles,
  selectTheme,
} from 'common/CustomFields';
import ConfirmationModal from 'common/ConfirmationModal';
import TaskFileUpload from 'components/Files/TaskFileUpload';
import {
  GET_TEMPLATES_FOR_CREATION,
  CREATE_DOC_FROM_TEMPLATE_IDS,
} from 'constants/constants';
import { withRouter } from 'utilities/withRouterWrapper';
import { format } from 'date-fns';
import TinymceEditor from 'components/Emails/components/TinymceEditior';
import CommonFields from 'components/Tasks/Create/CommonFields';
import CommonFieldsEmail from 'components/Tasks/Create/CommonFieldsEmail';
import TaskFooterButtons from './TaskFooterButtons';

const noneStyle = { display: 'none' };
const blockStyle = { display: 'block' };

class ManageToGoItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stateValues: {},
      errors: {},
      taskTitle: '',
      taskStatus: '',
      togoType: '',
      documentType: '',
      documents: [],
      comment: '',
      viewModal: false,
      loading: false,
      dataUpdated: false,
      selectedOption: null,
      uniqueId: generateUniqueHash(5),
      templateOptions: [],
      templateDocuments: [],
      warningMsgLost: false,
      selectedTemplate: null,
      titleChange: '',
    };
    this.templateDropdown = this.templateDropdown.bind(this);
    this.selectInputRef = React.createRef();
  }

  componentDidMount() {
    const { data, taskDetail } = this.props;
    if (data.viewContext) {
      this.setState({
        taskTitle: taskDetail && taskDetail.subject ? taskDetail.subject : '',
        documents:
          taskDetail && taskDetail.documents ? taskDetail.documents : [],
        comment:
          taskDetail && taskDetail.description ? taskDetail.description : '',
      });
    }
    this.getTemplates();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.taskDetail.description !== this.props.taskDetail.description
    ) {
      this.setState({ comment: this.props.taskDetail.description });
    }
  }

  UNSAFE_componentWillReceiveProps() {
    const { data, taskDetail } = this.props;
    if (Object.keys(taskDetail).length && data.isEdit && !data.changedOnce) {
      this.setState({
        taskTitle: taskDetail.subject,
        comment: taskDetail.description_for_web_display,
        documents: taskDetail.documents,
        taskStatus: taskDetail.status,
        togoType: taskDetail.manage_to_go_type,
        documentType: taskDetail.to_go_document_type,
      });
    }
  }

  componentWillUnmount() {
    if (this.state.documents.length) {
      const docIds = [];
      let x;
      for (x = 0; x < this.state.documents.length; x += 1) {
        docIds.push(this.state.documents[x].id);
      }
      const data = { doc_ids: docIds };
      TaskApi.cancelDocuments(this, data, 'tasks');
    }
  }

  setTasktitle = (value) => {
    this.setState({
      taskTitle: value,
      dataUpdated: true,
    });
  };

  getTemplateDocsId = () => {
    const mydata = {};
    if (this.state.templateDocuments.length) {
      mydata.template_doc_ids = [];
      let x;
      for (x = 0; x < this.state.templateDocuments.length; x += 1) {
        mydata.template_doc_ids.push(this.state.templateDocuments[x].id);
      }
    }
    mydata.template_type = 'task_template';
    if (this.state.templateDocuments.length) {
      this.setState({ loading: true });
      const request = axios.post(CREATE_DOC_FROM_TEMPLATE_IDS, mydata);
      request
        .then((response) => {
          this.setState({ loading: false });
          if (response.data.status === 200) {
            const documents = [...this.state.documents];
            if (documents.length) {
              this.setState({
                documents: response.data.documents,
                templateDocuments: [],
                dataUpdated: true,
              });
              // let x;
              // for (x = 0; x < documents.length; x += 1) {
              //   const documnt = { ...documents[x] };
              //   documnt.id = response.data.doc_ids[x];
              //   documents[x] = documnt;
              //   this.setState({ documents });
              //   if (x + 1 === documents.length) {
              //     this.setState({ templateDocuments: [], dataUpdated: true });
              //   }
              // }
            }
          } else {
            AlertMessage('error', response.data.message, 1500);
          }
        })
        .catch(() => {
          this.setState({ loading: false });
        });
    }
  };

  getTemplates() {
    this.setState({ loading: true });
    axios
      .get(`${GET_TEMPLATES_FOR_CREATION}?template_type=task_template`)
      .then((res) => {
        this.setState({ loading: false });
        if (res.data && res.data.data && res.data.data.length) {
          const { data } = res.data;
          this.setState({
            templateOptions: data,
            loading: false,
          });
        } else {
          this.setState({
            templateOptions: [],
            loading: false,
          });
        }
      })
      .catch(() => {
        this.setState({
          templateOptions: [],
          loading: false,
        });
      });
  }

  inputRenderer = (inputProps) => (
    <components.Input
      {...inputProps}
      value={this.state.titleChange}
      className="Select-input"
    />
  );

  templateDropdown(selectedOption) {
    const { taskTitle, comment, documents, warningMsgLost } = this.state;
    let commentExist = false;
    if (comment && comment.length) {
      commentExist = true;
    }
    if (
      (taskTitle || commentExist || documents.length) &&
      !warningMsgLost &&
      (!selectedOption || (selectedOption && !taskTitle))
    ) {
      this.setState({ warningMsgLost: true, selectedTemplate: selectedOption });
    } else if (selectedOption && selectedOption !== null) {
      const { content, value } = selectedOption;
      const coment = content;
      this.setState({
        selectedOption,
        taskTitle: value,
        titleChange: '',
        comment: coment,
        uniqueId: generateUniqueHash(5),
        warningMsgLost: false,
        selectedTemplate: null,
        templateDocuments:
          selectedOption.documents && selectedOption.documents.length
            ? selectedOption.documents
            : [],
        documents:
          selectedOption.documents && selectedOption.documents.length
            ? selectedOption.documents
            : [],
      });
    } else {
      this.setState(
        {
          selectedOption: null,
          taskTitle: '',
          titleChange: '',
          comment: '',
          uniqueId: generateUniqueHash(5),
          warningMsgLost: false,
          selectedTemplate: null,
          documents: [],
          templateDocuments: [],
        },
        () => {
          const node = this.selectInputRef.current;
          if (node) {
            node.state.inputValue = '';
          }
        },
      );
    }
  }

  templateChange = (input, action) => {
    const val =
      action.action === 'input-change' ? input : action.prevInputValue;
    if (val || (!val && this.state.titleChange)) {
      this.setState({
        taskTitle: val,
        titleChange: val,
      });
    }
  };

  stateChange = (value, dataUpdated = false) => {
    const stateValues = { ...this.state.stateValues, ...value };
    this.setState({
      stateValues,
      dataUpdated,
    });
  };

  handleCancel = () => {
    const { stateValues, dataUpdated } = this.state;
    if (stateValues.isChanged || dataUpdated) {
      this.setState({ viewModal: true });
    } else {
      this.props.goBack();
    }
  };

  hideViewModal = () => {
    this.setState({ viewModal: false });
  };

  handleCommentChange = (value) => {
    this.setState({
      comment: value,
      dataUpdated: true,
    });
    if (this.state.templateDocuments && this.state.templateDocuments.length) {
      this.getTemplateDocsId();
    }
  };

  handleChange = (e) => {
    this.setState({
      errors: {},
      taskTitle: e.target.value,
      dataUpdated: true,
    });
  };

  handleToGo = (val) => {
    this.setState(
      {
        togoType: val.value,
        errors: {},
        dataUpdated: true,
        togoTypeLabel: val.label,
      },
      () => {
        // this.autoPopulateTitle(this.state.documentType, val.value); // autopopulate title
      },
    );
  };

  handleDoc = (val) => {
    this.setState(
      {
        documentType: val.value,
        errors: {},
        dataUpdated: true.valueOf,
        documentTypeLabel: val.label,
      },
      () => {
        // this.autoPopulateTitle(val.value, this.state.togoType); // autopopulate title
      },
    );
  };

  nextPage = () => {
    const { state } = this.props.location;
    if (
      this.props.data.isEdit &&
      state !== undefined &&
      state.from === '/task/detail'
    ) {
      const { params } = this.props;
      this.props.navigate(`/task/detail/${params.id}`);
    } else if (state && state.from === '/task/new') {
      this.props.navigate(-2);
    } else {
      // need this for when task is created from email
      this.props.goBack();
    }
  };

  handleValidation = () => {
    const { stateValues, documentType, togoType, taskTitle } = this.state;
    const err = {};
    let formIsValid = true;
    if (
      stateValues.accounts &&
      stateValues.accounts.length &&
      (!stateValues.accountId || stateValues.accountId === '')
    ) {
      formIsValid = false;
      err.account_error = 'Please select account';
    }
    if (stateValues.contactId === '' || !stateValues.contactId) {
      formIsValid = false;
      err.contact_error = 'Please select contact';
    }
    if (stateValues.ownerId === '' || !stateValues.ownerId) {
      formIsValid = false;
      err.owner_error = 'Please select task owner';
    }
    if (taskTitle === '' || !taskTitle) {
      formIsValid = false;
      err.taskTitle_error = 'Enter task title';
    }
    if (documentType === '' || !documentType) {
      formIsValid = false;
      err.doctype_error = 'Please select document type';
    }
    if (togoType === '' || !togoType) {
      formIsValid = false;
      err.togotype_error = 'Please select to-go type';
    }
    this.setState({ errors: err });
    return formIsValid;
  };

  emptyError = () => {
    this.setState({ errors: {} });
  };

  submitForm = (e) => {
    e.preventDefault();
    const {
      location,
      data: { emailId },
    } = this.props;
    const { stateValues, togoType, documentType, comment, taskTitle } =
      this.state;

    const path = getCurrentPath(location);
    if (this.handleValidation()) {
      let mydata = {};
      if (this.props.data.isEdit) {
        mydata.cpa_id = stateValues.cpaId;
        mydata.subject = taskTitle;
        mydata.due_by = format(stateValues.startDate, 'dd/MM/yyyy');
        mydata.manage_to_go_type = togoType;
        mydata.to_go_document_type = documentType;
        if (stateValues.ownerChanged) {
          mydata.owner_id = stateValues.ownerId;
        }
      } else {
        if (path === '/contactdetails/task/:id') {
          mydata.assigne_type = stateValues.contactDetails.contact.cpa_user_id
            ? 'user'
            : 'contact';
          mydata.assigned_to_user = stateValues.contactDetails.contact
            .cpa_user_id
            ? stateValues.contactDetails.contact.cpa_user_id
            : stateValues.contactDetails.contact.id;
        } else {
          mydata.assigne_type = stateValues.assigneeType;
          mydata.assigned_to_user = stateValues.contactId;
        }
        mydata.cpa_id = stateValues.cpaId;
        mydata.owner_id = stateValues.ownerId;
        mydata.for_account = stateValues.accountId;
        mydata.subject = taskTitle;
        mydata.due_by = format(stateValues.startDate, 'MM/dd/yyyy');
        mydata.task_type_key = this.props.data.taskTypeKey;
        mydata.task_type_value = this.props.data.taskTypeValue;
        mydata.manage_to_go_type = togoType;
        mydata.to_go_document_type = documentType;
      }
      if (stateValues.recurring_properties) {
        mydata = { ...mydata, ...stateValues.recurring_properties };
      }
      if (
        typeof comment === 'object' &&
        comment !== null &&
        EditorState.createWithContent(convertFromRaw(comment))
          .getCurrentContent()
          .getPlainText().length
      ) {
        mydata.description = removeBreakElementinList(
          stateToHTML(
            EditorState.createWithContent(
              convertFromRaw(comment),
            ).getCurrentContent(),
            options,
          ),
        );
        mydata.description_in_json = JSON.stringify(comment);
      } else if (typeof comment === 'string' && comment.length) {
        mydata.description = comment;
      } else {
        mydata.description = '';
      }
      // if (mydata.description && mydata.description.length > 0) {
      //   mydata.description = mydata.description.replace(/\n/g, '<br>');
      // }
      if (emailId) {
        mydata.email_id = emailId;
      }
      if (this.state.documents.length && !this.props.data.isEdit) {
        mydata.doc_ids = [];
        let x;
        for (x = 0; x < this.state.documents.length; x += 1) {
          mydata.doc_ids.push(this.state.documents[x].id);
        }
      }
      if (this.props.data.isEdit) {
        const { id } = this.props.params;
        TaskApi.createTask(this, mydata, id, this.props.data.taskTypeKey); // updates task
      } else {
        TaskApi.createTask(this, mydata);
      }
    }
    return false;
  };

  updateDocumentState = (documents) => {
    this.setState({ documents });
  };

  render() {
    let togoTypeOptions = [];
    let docTypeOptions = [];
    // TODO: __HOMEDATA-AUDIT
    const localStorageData = getStorageData();
    if (localStorageData.pref_type_options) {
      togoTypeOptions = localStorageData.pref_type_options.manage_to_go_type;
      docTypeOptions = localStorageData.pref_type_options.to_go_document_type;
    }
    const {
      errors,
      taskTitle,
      taskStatus,
      stateValues,
      dataUpdated,
      selectedOption,
      templateOptions,
      selectedTemplate,
      togoType,
      documentType,
    } = this.state;
    const { data } = this.props;
    const recurringDisable =
      stateValues.recurring_properties &&
      stateValues.recurring_properties.recurringDropdown;
    return (
      <div>
        <div className="miscellaneous" data-testid="manageToGoTestId">
          <div
            id="loading"
            style={this.state.loading ? blockStyle : noneStyle}
          />
          {this.props?.data?.viewContext === 'email' ? (
            <CommonFieldsEmail
              data={this.props.data}
              statedata={this.state}
              taskDetail={this.props.taskDetail}
              stateChange={this.stateChange}
              emptyError={this.emptyError}
              setTasktitle={this.setTasktitle}
              removeparticularTaskType={this.props.removeparticularTaskType}
              match={this.props.match}
              location={this.props.location}
              params={this.props.params}
              handleTaskTypeChange={this.props.handleTaskTypeChange}
            />
          ) : (
            <CommonFields
              data={this.props.data}
              statedata={this.state}
              taskDetail={this.props.taskDetail}
              stateChange={this.stateChange}
              emptyError={this.emptyError}
              setTasktitle={this.setTasktitle}
              removeparticularTaskType={this.props.removeparticularTaskType}
              match={this.props.match}
              location={this.props.location}
              params={this.props.params}
            />
          )}
          <div className="row">
            <SelectField
              name="togoType"
              label="To-Go Type"
              className={`form-group col-md-${
                data.viewContext === 'email' ? 4 : 6
              } col-12 docsWidth`}
              options={togoTypeOptions}
              handleChange={this.handleToGo}
              value={
                togoTypeOptions
                  ? togoTypeOptions.filter(
                      (option) => option.value === togoType,
                    )
                  : null
              }
              error={errors.togotype_error}
              clearable={false}
              from={data.viewContext === 'email' && 'email'}
            />
            <SelectField
              name="documentType"
              label="Document Type"
              className={`form-group col-md-${
                data.viewContext === 'email' ? 4 : 6
              } col-12 docsWidth`}
              options={docTypeOptions}
              handleChange={this.handleDoc}
              value={
                docTypeOptions
                  ? docTypeOptions.filter(
                      (option) => option.value === documentType,
                    )
                  : null
              }
              error={errors.doctype_error}
              clearable={false}
              from={data.viewContext === 'email' && 'email'}
            />
          </div>
          <div className="row">
            <div
              className={`form-group col-12${
                errors.taskTitle_error
                  ? ' form-error cstmClr msgCustmClr'
                  : ' cstmClr msgCustmClr'
              }`}
            >
              <label htmlFor="taskTitle">Subject</label>
              <div className="labelValue customEditor boxResize flWdth">
                {(selectedOption && selectedOption !== null) ||
                (!selectedOption &&
                  selectedOption === null &&
                  taskTitle &&
                  !this.state.titleChange) ||
                this.props.data.isEdit ? (
                  <input
                    autoComplete="off"
                    disabled={taskStatus === 'Closed'}
                    name="taskTitle"
                    className="form-control"
                    id="taskTitle"
                    type="text"
                    value={taskTitle}
                    maxLength="200"
                    onChange={this.handleChange}
                  />
                ) : (
                  <Select
                    name="taskTitle"
                    placeholder=""
                    ref={this.selectInputRef}
                    className="select-custom-class slctTemp"
                    value={selectedOption}
                    onChange={this.templateDropdown}
                    options={templateOptions}
                    optionComponent={SelectTemplateFieldOption}
                    inputValue={this.state.titleChange}
                    onInputChange={this.templateChange}
                    tabSelectsValue={false}
                    onBlurResetsInput={false}
                    onCloseResetsInput={false}
                    onSelectResetsInput={this.state.titleChange} // false shows
                    noResultsText=""
                    isDisabled={data.isEdit}
                    styles={selectStyles}
                    theme={selectTheme}
                    classNamePrefix="custom-react-select"
                    components={{
                      Option: SelectTemplateFieldOption,
                      Input: this.inputRenderer,
                    }}
                  />
                )}
              </div>
              {taskTitle && !this.props.data.isEdit ? (
                <Button
                  className="btn btn-sm btn-primary"
                  onClick={() => this.templateDropdown(null)}
                >
                  Clear
                </Button>
              ) : null}
              <span className="error_label">{errors.taskTitle_error}</span>
            </div>
          </div>
          <div className="row">
            <div className="form-group col-lg-12">
              <div className="has-float-label customEditor boxResize">
                <label htmlFor="taskDescription">Description</label>
                <TinymceEditor
                  value={this.state.comment}
                  handleChange={this.handleCommentChange}
                  disabled={taskStatus === 'Closed'}
                  testid="task__description"
                />
              </div>
            </div>
          </div>
          {(data.isEdit && this.state.documents.length) || !data.isEdit ? (
            <div className="row">
              <div className="form-group dragWrap col-lg-12">
                <label htmlFor="attachments">Attachments</label>
                <TaskFileUpload
                  data={this.state}
                  uploadType="task"
                  isEdit={data.isEdit}
                  propsData={data}
                  externalDocs={data.externalDocs}
                  updateDocumentState={this.updateDocumentState}
                  deleteEmailAttachment={this.props.deleteEmailAttachment}
                />
              </div>
            </div>
          ) : null}
          <div className="row">
            <div className="col-12 form-group formbtn justify-content-end btn--leftspace">
              <TaskFooterButtons
                data={data}
                handleCancel={this.handleCancel}
                taskStatus={taskStatus}
                recurringDisable={recurringDisable}
                dataUpdated={dataUpdated}
                stateValues={stateValues}
                submitForm={this.submitForm}
              />
            </div>
          </div>
          {/* WIll be removed after checking functionality */}
          {/* <div className="row">
            <div className="col-12 form-group formbtn justify-content-end btn--leftspace">
              <div className="btn-wrap btn--leftspace">
                <button
                  type="button"
                  className="btn btn-outline-light"
                  onClick={this.handleCancel}
                  disabled={taskStatus === 'Closed' || recurringDisable}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary task-form-submit"
                  onClick={this.submitForm}
                  disabled={
                    taskStatus === 'Closed' ||
                    recurringDisable ||
                    (this.props.data.isEdit &&
                      !dataUpdated &&
                      !stateValues.isChanged) ||
                    (this.props.data && this.props.data.gettingEmailAttachments)
                  }
                >
                  {!this.props.data.isEdit ? 'Create Task' : 'Update Task'}
                </button>
              </div>
            </div>
          </div> */}
        </div>
        <ConfirmationModal
          isOpen={this.state.viewModal}
          headerText="Are you sure want to cancel."
          messageText="This action cannot be undone. Proceed?"
          noBtnClick={this.hideViewModal}
          yesBtnClick={this.nextPage}
          yesBtnText="Yes"
          noBtnText="No"
        />
        <ConfirmationModal
          isOpen={this.state.warningMsgLost}
          className="customModal customModal--w500 customModal--nopadding"
          messageText="You are about to delete the existing message. This action cannot be undone. Proceed?"
          noBtnClick={() => this.setState({ warningMsgLost: false })}
          yesBtnClick={() => this.templateDropdown(selectedTemplate)}
          yesBtnText="Yes"
          noBtnText="No"
        />
      </div>
    );
  }
}

export default withRouter(ManageToGoItems);
