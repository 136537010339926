import { Add } from '@mui/icons-material';
import { Box, Button, Typography, useTheme } from '@mui/material';

interface ActionBannerProps {
  onClick: () => void;
  header: string;
  body: string;
  buttonText: string;
}

export function ActionBanner({
  onClick,
  header,
  body,
  buttonText,
}: ActionBannerProps) {
  const theme = useTheme();
  return (
    <Box
      bgcolor="info.light"
      padding="10px 20px"
      display="flex"
      justifyContent="space-between"
      borderRadius={4}
      border={`2px solid ${theme.palette.common.primary[200]}`}
    >
      <Box>
        <Typography variant="subtitle1" component="h2">
          {header}
        </Typography>
        <Typography variant="body1">{body} </Typography>
      </Box>
      <Button
        variant="contained"
        startIcon={<Add />}
        size="large"
        onClick={onClick}
      >
        {buttonText}
      </Button>
    </Box>
  );
}
