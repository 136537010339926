import { RequestFiltersEmployeesAndContacts } from 'api/request-filters';
import { getStorageData } from 'utilities/utils';

export type Option = {
  value: string;
  label: string;
};

export type OptionWithType = {
  value: string;
  label: string;
  type: RequestFiltersEmployeesAndContacts['type'];
};

export const createAssignedToOptions = (
  data: RequestFiltersEmployeesAndContacts[],
) =>
  data.map((item) => ({
    value: item.uuid,
    label: item.label,
    type: item.type,
  }));

export const createOwnerOptions = (
  data: RequestFiltersEmployeesAndContacts[],
) =>
  data
    .filter((i) => i.type !== 'contact')
    .map((item) => ({
      value: item.uuid,
      label: item.label,
    }));

export const createOwnerOptionForCurrentUser = () => {
  const user = getStorageData();
  return {
    value: user?.current_cpa_user_uuid,
    label: user?.uname,
  };
};

export const createOwnersFiltersFromOptions = (data: Option[]) =>
  data.map((item) => item.value);

export const createAssignedToFiltersFromOptions = (data: OptionWithType[]) =>
  data.map((item) => ({ uuid: item.value, type: item.type }));

export const createAssignedToOptionsFromFilters = (
  filters: { uuid: string; type: string }[],
  data: RequestFiltersEmployeesAndContacts[],
) =>
  data
    .filter((item) => filters.find((x) => x.uuid === item.uuid))
    .map((item) => ({
      value: item.uuid,
      label: item.label,
      type: item.type,
    }));

export const createOwnersOptionsFromFilters = (
  filters: string[],
  data: Option[],
) =>
  data
    .filter((item) => filters.includes(item.value))
    .map((item) => ({
      value: item.value,
      label: item.label,
    }));

export const sortByType = (a: OptionWithType, b: OptionWithType) =>
  -b.type.localeCompare(a.type);
