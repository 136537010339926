import { IconButton, SxProps } from '@mui/material';
import SMS from 'images/icons/SMS.svg';
import { preventBubbling } from 'utilities/browserUtils/preventBubbling';
import SMSReminderTooltip from './SMSReminderTooltip';

interface Props {
  tooltip: string;
  enabled: boolean;
  sx?: SxProps;
  handleClick: (
    event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>,
  ) => void;
}

/**
 * Helper component for rendering the
 * SMS Reminder Icon Button (used by Workflow Request)
 */
export default function SMSReminderIconButton({
  tooltip,
  enabled,
  handleClick,
  sx,
}: Props) {
  return (
    <SMSReminderTooltip message={tooltip}>
      <span
        // span for tooltip when button is disabled
        data-testid="reminder-iconbutton-tooltip"
        style={{ display: 'flex' }}
      >
        <IconButton
          onClick={(e) => {
            preventBubbling(e);
            handleClick(e);
          }}
          disabled={!enabled}
          sx={sx}
        >
          <img src={SMS} alt="SMS" width={15} height={15} />
        </IconButton>
      </span>
    </SMSReminderTooltip>
  );
}
