import { Box, Stack } from '@mui/material';
import { Page, PageProps } from 'ui/Page/Page';

export interface WorkflowViewProps extends Omit<PageProps, 'children'> {
  children: React.ReactNode;
  slots?: {
    Header?: React.ReactNode;
    TopRegion?: React.ReactNode;
    SideBar?: React.ReactNode;
    Footer?: React.ReactNode;
  };
}

export const WorkflowViewWrapper = ({ children, slots }: WorkflowViewProps) => {
  const { Header, TopRegion, SideBar, Footer } = slots || {};

  return (
    <Page header={Header} contentSX={{ overflowY: 'hidden' }}>
      {TopRegion}
      {/* Define our row |--questions--|-send-| */}
      <Stack
        direction="row"
        justifyContent="space-between"
        width="100%"
        height="100%"
      >
        {/* Make the left side a scrollable column */}
        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          width="100%"
          padding=" 20px 100px"
          sx={{ overflowY: 'auto' }}
        >
          <Box display="flex" flexDirection="column" height="100vh">
            {children}
          </Box>
        </Box>

        {/* Make the right side not scrollabe, "fixed sidebar" */}
        {SideBar}
      </Stack>
      {Footer}
    </Page>
  );
};
