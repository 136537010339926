import { Stack } from '@mui/material';
import { DraftRequestsBulkSendTable } from 'components/Requests/components/Tables/DraftRequestsBulkSendTable';
import {
  REQUESTS_PAGE_ROUTES,
  REQUESTS_PAGE_TITLES,
} from 'components/Requests/requests.constants';
import { useNavigate } from 'react-router-dom-latest';
import { Header } from 'ui/Header/Header';
import { Page } from 'ui/Page/Page';

export const DraftsBulkSendView = () => {
  const navigate = useNavigate();

  const title = REQUESTS_PAGE_TITLES.draftsRequestsBulkSend;

  const onGoBack = () => {
    navigate(REQUESTS_PAGE_ROUTES.requestsDrafts);
  };

  const getHeader = <Header onBack={onGoBack} title={title} compact />;
  return (
    <Page header={getHeader} contentSX={{ overflowY: 'hidden' }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        width="100%"
        height="100%"
      >
        <DraftRequestsBulkSendTable />
      </Stack>
    </Page>
  );
};
