import { Chip } from '@mui/material';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';

interface Props {
  url: string;
}

export default function SMSDownloadAttachment({ url }: Props) {
  const { pathname: urlPathname } = new URL(url);

  const onDownload = () => {
    const link = document.createElement('a');
    link.download = urlPathname.split('/').pop() || '';
    link.href = url;
    link.click();
  };

  return (
    <Chip
      icon={<DownloadOutlinedIcon />}
      label={`Download ${
        urlPathname.split('.').pop()?.toLocaleUpperCase() || 'Attachment'
      }`}
      sx={{ borderRadius: '1rem', width: '100%' }}
      onClick={onDownload}
      key={url}
    />
  );
}
