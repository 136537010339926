import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { reducer as formReducer } from 'redux-form';
import thunk from 'redux-thunk';
import taskReducer from '../reducers/TaskReducer';
import noteReducer from '../reducers/NoteReducer';
import accountReducer from '../reducers/AccountReducer';
import homeReducer from '../reducers/HomeReducer';
import messageReducer from '../reducers/MessageReducer';
import notificationReducer from '../reducers/NotificationReducer';
import elasticReducer from '../reducers/ElasticReducer';
import paginationReducer from '../reducers/PaginationReducer';
import contactCardReducer from '../reducers/ContactCardReducer';
import accountCardReducer from '../reducers/AccountCardReducer';
import uploadFilesReducer from '../reducers/UploadFilesReducer';
import esignReducer from '../reducers/EsignReducer';
import filterDataReducer from '../reducers/FilterDataReducer';

const rootReducer = combineReducers({
  form: formReducer,
  taskReducer,
  accountReducer,
  homeReducer,
  noteReducer,
  messageReducer,
  notificationReducer,
  elasticReducer,
  paginationReducer,
  contactCardReducer,
  accountCardReducer,
  uploadFilesReducer,
  esignReducer,
  filterDataReducer,
});

// If development, use composeEnhancers(applyMiddleware(thunk)) as the third argument
// createStore();

// If production, use applyMiddleware(thunk) as the third argument in the createStore()
// function;

// This is for the redux dev tools.

/* *************************** Uncomment if development ****************************** */
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function configureStore(initialState = {}) {
  return createStore(
    rootReducer,
    initialState,
    // applyMiddleware(thunk),
    composeEnhancers(applyMiddleware(thunk)), // -- if development
  );
}
