import React from 'react';
import { useSearchParams } from 'react-router-dom-latest';
import { LoginV5Form } from './LoginV5Form';
import { LoginV5OAuth } from './LoginV5OAuth';

export interface LoginV5Props {}

export const LoginV5: React.FC<LoginV5Props> = () => {
  const [searchParams] = useSearchParams();
  const googleCode = searchParams.get('googlecode');
  const outlookCode = searchParams.get('outlookcode');

  if (googleCode) {
    return <LoginV5OAuth type="google" code={googleCode} />;
  }

  if (outlookCode) {
    return <LoginV5OAuth type="outlook" code={outlookCode} />;
  }

  return <LoginV5Form />;
};

export default LoginV5;
