import React from 'react';

const FiltersLoader = () => (
  <>
    <rect x="10" y="50" rx="3" ry="3" width="100" height="25" />
    <rect x="150" y="50" rx="3" ry="3" width="100" height="25" />
    <rect x="300" y="50" rx="3" ry="3" width="100" height="25" />
    <rect x="450" y="50" rx="3" ry="3" width="100" height="25" />
  </>
);

export default FiltersLoader;
