import axios from 'axios';
import { GET_EMPLOYEES_API_PATH } from 'constants/constants';

export interface Employee {
  id: number;
  cpa_user_id: number;
  first_name: string;
  last_name: string;
  middle_name?: string | null;
  title?: string | null;
  phone?: string | null;
  is_signing_authority?: boolean;
  avatar?: string;
  initials: string;
  email: string;
  role: string;
  is_default_message_recipient: boolean;
  status: string;
}

export interface GetEmployeesResponse {
  data: Employee[];
}

export const getEmployees = async () => {
  const result = await axios.get<GetEmployeesResponse>(GET_EMPLOYEES_API_PATH);
  return result.data.data;
};
