/* eslint-disable consistent-return */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-script-url */
/* eslint-disable react/jsx-no-script-url */
import React from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  DropdownMenu,
  Input,
  Label,
  Button,
} from 'reactstrap';
import { EditorState, convertFromRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import Axios from 'axios';
import Switch from 'react-switch';
import Interweave from 'interweave';
import styled from '@emotion/styled';
// import Select from 'react-select';
import Select, { components } from 'react-select';
import ConfirmationModal from 'common/ConfirmationModal';
import {
  options,
  removeBreakElementinList,
  generateUniqueHash,
  removeBreakElement,
} from 'common/NotesUtilities';
import TaskFileUpload from 'components/Files/TaskFileUpload';
import {
  CREATE_NEW_EDOC_REQUEST,
  SEARCH_TASK_CONTACTS,
  SEARCH_TASK_ACCOUNTS,
  GET_CONT_EMP_OF_ACCOUNT_API_PATH,
  GET_EMP_AND_CONTACT_API_PATH,
  DOCUMENT_API_PATH,
  ADOBE_INTEGRATION_STATUS,
  REPLACE_DOCUMENT_URL,
  GET_TEMPLATES_FOR_CREATION,
  CREATE_DOC_FROM_TEMPLATE_IDS,
} from 'constants/constants';
import * as TaskApi from 'api/TaskApi';
import {
  OptionBoxSelectFieldWithAvatar,
  CheckboxField,
  SelectTemplateFieldOption,
  selectStyles,
  selectTheme,
} from 'common/CustomFields';
import CustomDatePicker from 'wrappers/DatePickerUtil';
import { getDefaultDueDate } from 'utilities/dateUtil';
import SignersList from 'components/Esign/SignersList';
import EsignTemplatesList from 'components/Esign/EsignTemplatesList';
import PrepareDocView from 'components/Esign/PrepareDocView';
import { AlertMessage, getStorageData } from 'utilities/utils';
import TemplatePreview from 'components/Esign/TemplatePreview';
import { format } from 'date-fns/esm';
import TinymceEditor from 'components/Emails/components/TinymceEditior';
import { withStorageData } from 'utilities/withStorageData';

const StyledDiv = styled.div`
  .DueDate__ErrorText {
    color: red;
    font-size: small;
  }
  .Date__Label {
    color: #919eab;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 600;
  }
`;

const SigningOrder = styled.div`
  display: flex;
  align-items: baseline;
`;
class NewEsign extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subject: '',
      comment: '',
      documents: [],
      contacts: [],
      accounts: [],
      allContacts: [],
      allAccounts: [],
      accountId: '',
      contactId: '',
      contactName: '',
      accountName: '',
      recipientType: '',
      selectedRecords: [],
      dropdownOpen: false,
      assigneeType: '',
      messageId: '',
      errors: {},
      isKBAenabled: false,
      isSigningOrderenabled: true,
      selectedTemplates: [],
      contactDropdownMessage: 'Fetching Data',
      gettingContacts: true,
      gettingAccounts: true,
      accountDropdownMessage: 'Fetching Data',
      gettingDocumentUrls: false,
      uniqueId: generateUniqueHash(5),
      selectedOption: null,
      due_date: getDefaultDueDate(),
    };
    this.handleCommentChange = this.handleCommentChange.bind(this);
    this.addSelectedInput = this.addSelectedInput.bind(this);
    this.handleAccountChange = this.handleAccountChange.bind(this);
    this.handleContactChange = this.handleContactChange.bind(this);
    this.handleContactInput = this.handleContactInput.bind(this);
    this.handleAccountInput = this.handleAccountInput.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.discardDraft = this.discardDraft.bind(this);
    this.handleValidation = this.handleValidation.bind(this);
    this.selectInputRef = React.createRef();
    this.handleDate = this.handleDate.bind(this);
  }

  componentDidMount() {
    this.getInitialData();
  }

  componentWillUnmount() {
    window.addEventListener('keypress', () => true);
    window.removeEventListener('message', this.eventHandler, false);
  }

  onFocus() {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
      contacts: prevState.allContacts,
      accounts: prevState.allAccounts,
      contactId: '',
      accountId: '',
      contactName: '',
      accountName: '',
      errors: {},
    }));
  }

  onListChange = (input) => {
    this.setState(
      {
        selectedRecords: input,
        showSigners: false,
      },
      () => {
        let isKBAenabled = false;
        input.map((e) => {
          if (e.enable_kba) {
            isKBAenabled = true;
          }
          return e;
        });
        if (isKBAenabled !== this.state.isKBAenabled) {
          this.setState({ isKBAenabled });
        }
      },
    );
  };

  getAllContactsAndEmployees = () => {
    Axios.post(GET_EMP_AND_CONTACT_API_PATH, {
      limit: true,
      message: true,
    }).then((response) => {
      if (response.status === 200 && response.data) {
        const data = response.data.filter(
          (e) =>
            e.status &&
            (e.status.toLowerCase() === 'active' ||
              e.status.toLowerCase() === 'activated'),
        );
        this.setState({
          allContacts: data,
          contacts: data,
          contactDropdownMessage: 'Fetching Data',
          gettingContacts: false,
        });
      } else {
        this.setState({
          contactDropdownMessage: 'No Records Found',
          gettingContacts: false,
        });
      }
    });
  };

  getContAcc(changeOnly = 'both') {
    if (changeOnly === 'contacts') {
      this.setState({
        contacts: this.state.allContacts,
        contactId: '',
        contactName: '',
        recipientType: '',
      });
    } else if (changeOnly === 'accounts') {
      this.setState({
        accounts: this.state.allAccounts,
        accountId: '',
        accountName: '',
      });
    } else {
      this.setState({
        contacts: this.state.allContacts,
        accounts: this.state.allAccounts,
        contactId: '',
        accountId: '',
        contactName: '',
        accountName: '',
        recipientType: '',
      });
    }
  }

  getInitialData = () => {
    document.title = 'Request eDoc';
    const esign_request_id = window.location.href.split('/edit/')[1];
    if (esign_request_id) {
      this.setState({ loading: true });
      Axios.get(`${CREATE_NEW_EDOC_REQUEST}/${esign_request_id}`).then(
        (res) => {
          if (
            res &&
            res.data &&
            res.data &&
            res.data.data &&
            res.data.data.authoring_url &&
            res.data.data.authoring_url !== null
          ) {
            this.setState({
              authoringUrl: res.data.data.authoring_url,
              loading: false,
            });
          } else {
            AlertMessage('error', res.data.message, 1000);
          }
        },
      );
    } else {
      // TODO: __HOMEDATA-AUDIT
      const homeDataObj = getStorageData();
      // TaskApi.getContactsOfTask(this, { limit: true, message: true }, 'edoc');
      this.getAllContactsAndEmployees();
      if (homeDataObj !== null && Object.keys(homeDataObj).length) {
        if (homeDataObj.is_employee) {
          TaskApi.getAccounts(this, 'notes');
        } else {
          TaskApi.AccountsOfContact(this, homeDataObj.contact_id, 'message');
        }
      }
      this.getIntegrationStatus();
      this.getTemplates();
    }
    window.addEventListener('keypress', (e) => {
      if (e && e.which === 13) {
        e.preventDefault();
      }
    });
    if (!window.addEventListener) {
      window.attachEvent('onmessage', this.eventHandler);
    } else {
      window.addEventListener('message', this.eventHandler, false);
    }
  };

  getIntegrationStatus = () => {
    Axios.get(ADOBE_INTEGRATION_STATUS).then((res) => {
      if (res.status === 200 && res.data && res.data.data) {
        const {
          adobe_admin_email,
          adobe_token_exists,
          service_usages,
          default_esign_low_bal,
          default_kba_low_bal,
          default_esign_limit,
          default_kba_limit,
          default_esign_label,
          default_kba_label,
          web_access_url,
        } = res.data.data;
        let esign_balance;
        let kba_balance;
        if (service_usages) {
          service_usages.map((e) => {
            if (e.service_type && e.service_type.toLowerCase() === 'esign') {
              esign_balance =
                e.unit_purchased - (e.final_used + e.in_process_used);
            } else if (
              e.service_type &&
              e.service_type.toLowerCase() === 'kba'
            ) {
              kba_balance =
                e.unit_purchased - (e.final_used + e.in_process_used);
            }
            return e;
          });
        }
        let alert_message;
        let is_warning = false;
        let low_balance_string = 'low';
        if (esign_balance < 0 || kba_balance < 0) {
          low_balance_string = 'negative';
        }
        if (adobe_admin_email === undefined) {
          alert_message =
            'Error: Your firm has not been setup for creating eDocs. Please contact Liscio Support Team.';
        } else if (!adobe_token_exists) {
          alert_message =
            'Error: Your firm has not been setup for creating eDocs. Please contact Liscio Support Team.';
        } else if (
          service_usages === undefined ||
          service_usages.length === 0 ||
          esign_balance === undefined
        ) {
          alert_message =
            'Error: Your firm has not purchased eSignature services. Please contact Liscio Support Team.';
        } else if (
          esign_balance !== undefined &&
          esign_balance < default_esign_low_bal &&
          kba_balance !== undefined &&
          kba_balance < default_kba_low_bal
        ) {
          is_warning = true;
          if (
            esign_balance <= -default_esign_limit ||
            kba_balance <= -default_kba_limit
          ) {
            is_warning = false;
          }
          alert_message = `${
            is_warning ? 'Warning' : 'Error'
          }: You have a ${low_balance_string} balance. Please upgrade your ${default_esign_label} and ${default_kba_label} balance to avoid stoppage of these features. <br>${default_esign_label} Balance: ${esign_balance}<br> ${default_kba_label} Balance: ${kba_balance}`;
        } else if (esign_balance < default_esign_low_bal) {
          is_warning = true;
          alert_message = `Warning: You have a ${low_balance_string} balance. Please upgrade your ${default_esign_label} balance to avoid stoppage of this feature. <br>${default_esign_label} Balance: ${esign_balance}`;
          if (esign_balance <= -default_esign_limit) {
            is_warning = false;
            alert_message = `Error: You have exceeded your ${default_esign_label} balance.<br> You cannot proceed.<br> Please upgrade your ${default_esign_label} balance to continue this feature.<br>${default_esign_label} Balance: ${esign_balance}`;
          }
        } else if (kba_balance < default_kba_low_bal) {
          is_warning = true;
          alert_message = `Warning: You have a ${low_balance_string} balance. Please upgrade your ${default_kba_label} balance to avoid stoppage of this feature. <br>${default_kba_label} Balance: ${kba_balance}`;
          if (kba_balance <= -default_kba_limit) {
            is_warning = false;
            alert_message = `Error: You have exceeded your ${default_kba_label} balance.<br> You cannot proceed.<br> Please upgrade your ${default_kba_label} balance to continue this feature.<br>${default_kba_label} Balance: ${kba_balance}`;
          }
        }
        this.setState({
          is_warning,
          alert_message,
          web_access_url,
        });
      }
    });
  };

  getTemplates() {
    this.setState({ loading: true });
    Axios.get(`${GET_TEMPLATES_FOR_CREATION}?template_type=edoc_template`)
      .then((res) => {
        this.setState({ loading: false });
        if (res.data && res.data.data && res.data.data.length) {
          const { data } = res.data;
          this.setState({
            templateOptions: data,
            loading: false,
          });
        } else {
          this.setState({
            templateOptions: [],
            loading: false,
          });
        }
      })
      .catch(() => {
        this.setState({
          templateOptions: [],
          loading: false,
        });
      });
  }

  getAuthoringView = () => {
    Axios.get(`${CREATE_NEW_EDOC_REQUEST}/${this.state.edocId}`).then((res) => {
      if (
        res.data &&
        res.data.data &&
        res.data.data.authoring_url &&
        res.data.data.authoring_url !== null
      ) {
        this.setState({
          authoringUrl: res.data.data.authoring_url,
          loading: false,
        });
        this.props.navigate(`/esign/edit/${res.data.data.id}`);
      } else if (
        res.data.message &&
        res.data.message.toLowerCase().includes('view_not_available')
      ) {
        this.getAuthoringView();
      } else {
        this.setState({
          loading: false,
          alert_message: res.data.message,
        });
      }
    });
  };

  getDocumentUrl = () => {
    const { documents } = this.state;
    const requestUrls = [];
    documents.map((e) => {
      requestUrls.push(Axios.get(`${REPLACE_DOCUMENT_URL}/${e.id}`));
      return e;
    });
    Axios.all(requestUrls).then((res) => {
      res.map((e, key) => {
        if (e.data) {
          documents[key].aws_url_origional = e.data.url;
        }
        return e;
      });
      this.setState({
        documents,
        gettingDocumentUrls: false,
      });
    });
  };

  getTemplateDocsId = () => {
    const mydata = {};
    if (this.state.templateDocuments.length) {
      mydata.template_doc_ids = [];
      let x;
      for (x = 0; x < this.state.templateDocuments.length; x += 1) {
        mydata.template_doc_ids.push(this.state.templateDocuments[x].id);
      }
    }
    mydata.template_type = 'edoc_template';
    if (this.state.templateDocuments.length) {
      this.setState({ loading: true });
      const request = Axios.post(CREATE_DOC_FROM_TEMPLATE_IDS, mydata);
      request
        .then((response) => {
          this.setState({ loading: false });
          if (response.data.status === 200) {
            const { documents } = this.state;
            if (documents.length) {
              this.setState({
                documents: response.data.documents,
                templateDocuments: [],
              });
              // let x;
              // for (x = 0; x < documents.length; x += 1) {
              //   const documnt = documents[x];
              //   documnt.id = response.data.doc_ids[x];
              //   documnt.aws_url_origional = response.data.doc_urls[x];
              //   documents[x] = documnt;
              //   console.log(documents);
              //   this.setState({ documents });
              //   if (x + 1 === documents.length) {
              //     this.setState({ templateDocuments: [], dataUpdated: true });
              //   }
              // }
            }
          } else {
            AlertMessage('error', response.data.message, 1500);
          }
        })
        .catch(() => {
          this.setState({ loading: false });
        });
    }
  };

  templateChange = (input, action) => {
    const val =
      action.action === 'input-change' ? input : action.prevInputValue;
    if (val || (!val && this.state.titleChange)) {
      this.setState({
        subject: val,
        titleChange: val,
      });
    }
  };

  templateDropdown = (selectedOption) => {
    const { subject, comment, documents, warningMsgLost } = this.state;
    let commentExist = true;
    if (!comment) {
      commentExist = false;
    } else if (typeof comment === 'object') {
      if (
        !EditorState.createWithContent(convertFromRaw(comment))
          .getCurrentContent()
          .getPlainText().length
      ) {
        commentExist = false;
      }
    }
    if (
      (subject || commentExist || documents.length) &&
      !warningMsgLost &&
      (!selectedOption || (selectedOption && !subject))
    ) {
      this.setState({ warningMsgLost: true, selectedTemplate: selectedOption });
    } else if (selectedOption && selectedOption !== null) {
      const { content, value } = selectedOption;
      const coment = removeBreakElement(content);
      this.setState(
        {
          selectedOption,
          subject: value,
          titleChange: '',
          comment: coment,
          uniqueId: generateUniqueHash(5),
          warningMsgLost: false,
          selectedTemplate: null,
          templateDocuments:
            selectedOption.documents && selectedOption.documents.length
              ? selectedOption.documents
              : [],
          documents:
            selectedOption.documents && selectedOption.documents.length
              ? selectedOption.documents
              : [],
        },
        () => this.getTemplateDocsId(),
      );
    } else {
      this.setState(
        {
          selectedOption: null,
          subject: '',
          titleChange: '',
          comment: '',
          uniqueId: generateUniqueHash(5),
          warningMsgLost: false,
          selectedTemplate: null,
          documents: [],
          templateDocuments: [],
        },
        () => {
          const node = this.selectInputRef.current;
          if (node) {
            node.state.inputValue = '';
          }
        },
      );
    }
  };

  inputRenderer = (inputProps) => (
    <components.Input
      {...inputProps}
      value={this.state.subject}
      className="Select-input"
    />
  );

  eventHandler = (e) => {
    const { web_access_url } = this.state;
    if (
      e.origin === 'https://lisciodev1.na2.echosign.com' ||
      e.origin === 'https://lisciouat.na2.echosign.com' ||
      e.origin === web_access_url
    ) {
      const logdata = JSON.parse(e.data);
      if (
        logdata.data.pageName === 'POST_SEND' ||
        logdata.data.hasSignature ||
        logdata.data.pageName === 'LOGIN'
      ) {
        this.props.navigate('/esign_list');
      } else if (
        logdata.type === 'SESSION_TIMEOUT' &&
        logdata.data.message === 'POST_SESSION_TIMEOUT'
      ) {
        window.location.reload();
      }
    }
  };

  handleChange(e) {
    const { errors } = this.state;
    delete errors.subject;
    this.setState({
      [e.target.name]: e.target.value,
      errors,
    });
  }

  // this is called when attachments are uploaded successfully
  updateForm = (type, documents) => {
    const { errors } = this.state;
    if (errors && errors.document) {
      delete errors.document;
      this.setState(
        {
          errors,
          documents,
          gettingDocumentUrls: true,
        },
        () => {
          this.getDocumentUrl();
        },
      );
    } else {
      this.setState(
        {
          documents,
          gettingDocumentUrls: true,
        },
        () => {
          this.getDocumentUrl();
        },
      );
    }
  };

  removeSelectedRecord(contactId, accountId) {
    const { selectedRecords: selectedRecordsArr } = this.state;
    const index = selectedRecordsArr.findIndex(
      (x) => x.contactId === contactId && x.accountId === accountId,
    );
    if (index > -1) {
      selectedRecordsArr.splice(index, 1);
      this.setState({ selectedRecords: selectedRecordsArr });
    }
  }

  handleCommentChange(value) {
    this.setState({ comment: value, errors: {} });
  }

  closeModal() {
    this.props.navigate(-1);
  }

  handleContactChange(val) {
    const { errors } = this.state;
    delete errors.contact;
    if (!val && !this.state.accountId) {
      this.getContAcc();
    } else if (!val) {
      this.setState({
        contactId: '',
        recipientType: '',
        contactName: '',
        assigneeType: '',
        errors,
      });
    } else if (val.type === 'contact' && !val.hasOwnProperty('accounts')) {
      // disable account field
      this.setState({
        accounts: [],
        contactId: val.value,
        contactName: val.label,
        accountId: '',
        accountName: '',
        assigneeType: val.assigne_type,
        recipientType: val.type,
        selectedContact: val,
        errors,
      });
    } else if (val.type === 'emp' && !this.state.accountId) {
      this.getContAcc('accounts'); // reset accounts data
      this.setState({
        contactId: val.value,
        contactName: val.label,
        selectedContact: val,
        assigneeType: val.assigne_type,
        recipientType: val.type,
        errors,
      });
    } else if (val.type === 'emp' && this.state.accountId) {
      this.setState({
        contactId: val.value,
        contactName: val.label,
        selectedContact: val,
        assigneeType: val.assigne_type,
        recipientType: val.type,
        errors,
      });
    } else if (val.accounts.length === 1) {
      // autopopulate account if only one account
      this.setState({
        accountId: val.accounts[0].value,
        accountName: val.accounts[0].label,
        accounts: val.accounts,
        contactId: val.value,
        contactName: val.label,
        assigneeType: val.assigne_type,
        recipientType: val.type,
        selectedContact: val,
        errors,
      });
    } else {
      const eleArray = this.state.accounts.map((item) => item.value);
      if (eleArray.indexOf(this.state.accountId) > -1) {
        this.setState({
          accounts: val.accounts,
          contactId: val.value,
          contactName: val.label,
          selectedContact: val,
          assigneeType: val.assigne_type,
          recipientType: val.type,
          errors,
        });
      } else {
        this.setState({
          accountId: '',
          accountName: ' ',
          accounts: val.accounts,
          contactId: val.value,
          contactName: val.label,
          selectedContact: val,
          assigneeType: val.assigne_type,
          recipientType: val.type,
          errors,
        });
      }
    }
  }

  handleContactInput(val) {
    if (!this.state.accountId && val) {
      if (val.length < 2) {
        this.getContAcc('contacts');
      } else {
        this.setState({
          gettingContacts: true,
          contactDropdownMessage: 'Fetching Data',
        });
        clearTimeout(this.contactSearchTime);
        this.contactSearchTime = setTimeout(() => {
          const request = Axios.get(
            `${SEARCH_TASK_CONTACTS}/?keyword=${val}&message=false`,
          );
          request.then((response) => {
            if (response.status === 200 && response.data) {
              let { data } = response;
              data = data.filter(
                (e) => e.status && e.status.toLowerCase() !== 'inactive',
              );
              this.setState({
                contacts: data,
                gettingContacts: false,
                contactDropdownMessage: data.length === 0 && 'No Records Found',
              });
            } else {
              this.setState({
                contactDropdownMessage: 'No Records Found',
                gettingContacts: false,
              });
            }
          });
        }, 500);
      }
    }
  }

  handleAccountInput(val) {
    // TODO: __HOMEDATA-AUDIT
    const homeDataObj = getStorageData();
    const { contactId, recipientType } = this.state;
    if (
      val.length >= 2 &&
      homeDataObj.is_employee &&
      ((contactId && recipientType === 'emp') || !contactId)
    ) {
      this.setState({
        gettingAccounts: true,
        accountDropdownMessage: 'Fetching Accounts',
      });
      clearTimeout(this.accountSearchTime);
      this.accountSearchTime = setTimeout(() => {
        const request = Axios.get(
          `${SEARCH_TASK_ACCOUNTS}/?keyword=${val}&message=true`,
        );
        request.then((response) => {
          if (response.status === 200 && response.data) {
            this.setState({
              accounts: response.data,
              gettingAccounts: false,
              accountDropdownMessage:
                response.data.length === 0 && 'No Accounts Found',
            });
          } else {
            this.setState({
              gettingAccounts: false,
              accountDropdownMessage: 'No Accounts Found',
            });
          }
        });
      }, 500);
    }
    if (this.state.errors.account) {
      const err = {};
      err.account = '';
      this.setState({ errors: err });
    }
  }

  handleAccountChange(val) {
    const { errors } = this.state;
    delete errors.account;
    // TODO: __HOMEDATA-AUDIT
    const homeDataObj = getStorageData();
    const { contactId } = this.state;
    if (!val && !contactId) {
      this.getContAcc();
    } else if (!val) {
      this.setState({
        accountId: '',
        accountName: '',
        errors,
      });
    } else if (homeDataObj && homeDataObj.is_employee && !contactId) {
      // will get contacts related to selected account when account is first selected
      this.setState({
        accountId: val.value,
        accountName: val.label,
        contactDropdownMessage: 'Fetching Data',
        gettingContacts: true,
        contacts: [],
        errors,
      });
      const mydata = {
        accountId: val.value,
        accountName: val.label,
      };
      Axios.get(`${GET_CONT_EMP_OF_ACCOUNT_API_PATH}/${mydata.accountId}`).then(
        (response) => {
          if (response.data && response.data && response.data.data) {
            let { data } = response.data;
            data = response.data.data.filter(
              (e) =>
                e.status &&
                (e.status.toLowerCase() === 'active' ||
                  e.status.toLowerCase() === 'activated'),
            );
            this.setState({
              contacts: data,
              contactDropdownMessage: data.length === 0 && 'No Records Found',
              gettingContacts: false,
            });
          } else {
            this.setState({
              contacts: [],
              contactDropdownMessage: 'No Records Found',
              gettingContacts: false,
            });
          }
        },
      );
    } else {
      this.setState({
        accountId: val.value,
        accountName: val.label,
        errors,
      });
    }
    if (this.state.errors.account) {
      const err = {};
      err.account = '';
      this.setState({ errors: err });
    }
  }

  addSelectedInput() {
    const { errors } = this.state;
    if (!this.state.contactId) {
      errors.contact = 'Recipient is mandatory';
      this.setState({ errors });
      return false;
    }
    delete errors.contact;
    delete errors.account;
    const { selectedContact, isKBAenabled, accountId, accounts } = this.state;
    const selectedInput = {
      contactId: this.state.contactId,
      accountId: this.state.accountId,
      contactName: this.state.contactName,
      accountName: this.state.accountName,
      assigneeType: this.state.assigneeType,
      email: selectedContact && selectedContact.email,
      enable_kba: isKBAenabled,
      type: selectedContact && selectedContact.type,
    };
    const selectedRecordsArr = this.state.selectedRecords;
    const index = selectedRecordsArr.findIndex(
      (x) => x.contactId === selectedInput.contactId,
    );
    if (
      (accountId === undefined ||
        accountId === null ||
        accountId.length === 0) &&
      selectedContact.type !== 'emp' &&
      accounts.length
    ) {
      errors.account = 'Account is mandatory for contacts';
      this.setState({ errors });
    } else if (index === -1) {
      selectedRecordsArr.push(selectedInput);
      this.setState({
        selectedRecords: selectedRecordsArr,
        dropdownOpen: false,
        contacts: this.state.allContacts,
        accounts: this.state.allAccounts,
        contactId: '',
        accountId: '',
        contactName: '',
        accountName: '',
        assigneeType: '',
      });
    } else {
      this.setState({
        dropdownOpen: false,
        contacts: this.state.allContacts,
        accounts: this.state.allAccounts,
        contactId: '',
        accountId: '',
        contactName: '',
        accountName: '',
        assigneeType: '',
      });
    }
    // clearTimeout(this.timer);
    // this.timer = setTimeout(() => { this.saveMessage('Draft'); }, 500);
  }

  handleValidation(comment) {
    const err = {};
    let formIsValid = true;
    if (!comment) {
      formIsValid = false;
      err.comment = 'Enter Description';
    }
    const selectedRecordsArr = this.state.selectedRecords;

    if (selectedRecordsArr.length < 1) {
      formIsValid = false;
      err.recipient = 'Please select a recipient';
    }

    this.setState({ errors: err });
    return formIsValid;
  }

  saveMessage() {
    const {
      selectedTemplates,
      selectedRecords,
      subject: name,
      isSigningOrderenabled,
      documents,
      comment,
      due_date,
    } = this.state;
    let description = '';
    if (
      typeof comment === 'object' &&
      comment !== null &&
      EditorState.createWithContent(convertFromRaw(comment))
        .getCurrentContent()
        .getPlainText().length
    ) {
      description = removeBreakElementinList(
        stateToHTML(
          EditorState.createWithContent(
            convertFromRaw(comment),
          ).getCurrentContent(),
          options,
        ),
      );
    } else if (typeof comment === 'string' && comment.length) {
      description = comment;
    } else {
      description = '';
    }
    if (
      selectedRecords &&
      selectedRecords.length &&
      name &&
      name.length &&
      ((documents && documents.length) ||
        (selectedTemplates && selectedTemplates.length))
    ) {
      const memberInfos = [];
      selectedRecords.map((each, key) => {
        if (each.email) {
          const eachmemberInfos = {
            memberInfos: [{ email: each.email }],
            order: isSigningOrderenabled ? key + 1 : 1,
            role: 'SIGNER',
            postSignOption: { redirectUrl: window.location.host },
          };
          if (each.enable_kba) {
            eachmemberInfos.memberInfos[0].securityOption = {
              authenticationMethod: 'KBA',
            };
          }
          memberInfos.push(eachmemberInfos);
        }
        each.signatory_seq_number = isSigningOrderenabled ? key + 1 : 1;
        return each;
      });
      const fileInfos = [];
      documents.map((each) => {
        const eachDocumentInfo = {
          urlFileInfo: {
            mimeType: each.content_type,
            name: each.doc_name,
            url: each.aws_url_origional,
          },
        };
        fileInfos.push(eachDocumentInfo);
        each.is_template = false;
        return each;
      });
      selectedTemplates.map((each) => {
        const eachDocumentInfo = { libraryDocumentId: each.id };
        fileInfos.push(eachDocumentInfo);
        each.is_template = true;
        return each;
      });
      const dataToSend = {
        fileInfos,
        name,
        participantSetsInfo: memberInfos,
        emailOption: {
          sendOptions: {
            completionEmails: 'NONE',
            inFlightEmails: 'NONE',
            initEmails: 'NONE',
          },
        },
        signatureType: 'ESIGN',
        externalId: { id: 'NA2Account_{{$timestamp}}' },
        state: 'AUTHORING',
      };
      const dataForLiscio = {
        are_signatories_ordered:
          selectedRecords &&
          selectedRecords.length > 1 &&
          isSigningOrderenabled,
        is_template: selectedTemplates.length && true,
        signers: selectedRecords,
        edoc_requests: true,
        agreement_name: name,
        documents: [...documents, ...selectedTemplates],
        data_to_send_adobe: JSON.stringify(dataToSend),
        description,
        due_date: format(due_date, 'MM/dd/yyyy'),
      };
      this.setState({
        loading: true,
        alert_message: undefined,
        is_warning: false,
      });
      Axios.post(CREATE_NEW_EDOC_REQUEST, dataForLiscio)
        .then((res) => {
          if (
            res.data &&
            res.data.data &&
            res.data.data.authoring_url &&
            res.data.data.authoring_url !== null
          ) {
            this.setState({
              authoringUrl: res.data.data.authoring_url,
              loading: false,
            });
            this.props.navigate(`/esign/edit/${res.data.data.id}`, {
              replace: true,
            });
          } else if (
            res.data.message &&
            res.data.message.toLowerCase().includes('view_not_available') &&
            res.data.data.id
          ) {
            this.setState({ edocId: res.data.data.id }, () =>
              this.getAuthoringView(),
            );
            this.props.navigate(`/esign/edit/${res.data.data.id}`, {
              replace: true,
            });
          } else {
            this.setState({
              loading: false,
              alert_message: res.data.message,
            });
          }
        })
        .catch(() => {
          this.setState({ loading: false });
        });
    } else {
      const errors = {};
      if (name === undefined || name.length === 0) {
        errors.subject = 'Please enter agreement name';
      }
      if (selectedRecords === undefined || selectedRecords.length === 0) {
        errors.recipient = 'Please select a recipient';
      }
      if (
        (documents === undefined || documents.length === 0) &&
        (selectedTemplates === undefined || selectedTemplates.length === 0)
      ) {
        errors.document = 'Please attach document';
      }
      if (due_date === undefined || due_date === null) {
        errors.dueDate = 'Due date is mandatory';
      }
      this.setState({ errors });
    }
  }

  discardDraft() {
    encodeURIComponent(this.state.messageId);
    // console.log(msgId);
    // MessageApi.discardDraft(this, msgId, '', 'newMsg');
  }

  handleKBACheckbox = () => {
    this.setState({ isKBAenabled: !this.state.isKBAenabled }, () => {
      const { selectedRecords } = this.state;
      selectedRecords.map((e) => {
        e.enable_kba = this.state.isKBAenabled;
        return e;
      });
    });
  };

  handleSigningOrder = () => {
    this.setState({ isSigningOrderenabled: !this.state.isSigningOrderenabled });
  };

  updateDocumentState = (documents) => {
    const { errors } = this.state;
    delete errors.document;
    this.setState(
      {
        documents,
        errors,
        gettingDocumentUrls: true,
      },
      () => {
        // this.saveMessage('Draft');
        this.getDocumentUrl();
      },
    );
  };

  showSignersInPopUp = (input) => {
    this.setState({ showSigners: input });
  };

  handleTemplatesBtn = (input) => {
    this.setState({ showTemplates: input });
  };

  assignSelectedTemplateId = (input) => {
    const { errors } = this.state;
    delete errors.document;
    this.setState({
      selectedTemplates: [...this.state.selectedTemplates, input],
      showTemplates: false,
      errors,
    });
  };

  deleteTemplate = (input) => {
    const { selectedTemplates } = this.state;
    const updatedOnes = selectedTemplates.filter((e) => e.id !== input.id);
    this.setState({ selectedTemplates: updatedOnes });
  };

  handleCancel = () => {
    const { documents } = this.state;
    for (let i = 0; i < documents.length; i += 1) {
      if (!documents[i].fromlisciovault) {
        Axios.delete(`${DOCUMENT_API_PATH}/${documents[i].id}`);
      }
    }
    this.props.navigate('/esign_list');
  };

  showTemplatePreview = (templateId) => {
    if (typeof templateId === 'string') {
      this.setState({
        selectedTemplateId: templateId,
        loading: false,
      });
    } else {
      this.setState({ selectedTemplateId: undefined });
    }
  };

  renderTags = () => {
    const allSelectedRecords = this.state.selectedRecords;
    if (!allSelectedRecords || allSelectedRecords.length < 1) {
      return null;
    }
    const selectedRecords = allSelectedRecords.map((item) => {
      if (item.accountId) {
        return (
          <span
            className="tag tag--fill receipt-account-tag"
            key={item.contactId + item.accountId}
          >
            {item.contactName} <i className="divOption" /> {item.accountName}{' '}
            <i
              className="icon-close2 remove"
              onClick={() =>
                this.removeSelectedRecord(item.contactId, item.accountId)
              }
            />
          </span>
        );
      }
      return (
        <span
          className="tag tag--fill receipt-account-tag"
          key={item.contactId + item.accountId}
        >
          {item.contactName}{' '}
          <i
            className="icon-close2 remove"
            onClick={() =>
              this.removeSelectedRecord(item.contactId, item.accountId)
            }
          />
        </span>
      );
    });
    return selectedRecords;
  };

  handleDate(e) {
    this.setState({ due_date: e });
  }

  render() {
    const initialBorderStyle = { border: '2px solid #dfe3e8' };
    const errorBorderStyle = { border: '2px solid red' };
    const borderStyle = this.state.errors.dueDate
      ? errorBorderStyle
      : initialBorderStyle;
    const noneStyle = { display: 'none' };
    const blockStyle = { display: 'block' };
    const {
      isKBAenabled,
      isSigningOrderenabled,
      selectedRecords,
      errors,
      authoringUrl,
      selectedTemplates,
      gettingContacts,
      contactDropdownMessage,
      gettingAccounts,
      accountDropdownMessage,
      selectedTemplateId,
      alert_message,
      is_warning,
      gettingDocumentUrls,
      templateOptions,
      selectedOption,
      subject,
      selectedTemplate,
    } = this.state;
    if (authoringUrl) {
      return <PrepareDocView authoringUrl={authoringUrl} />;
    }
    return (
      <div id="content">
        <div id="loading" style={this.state.loading ? blockStyle : noneStyle} />
        <header>
          <h1 className="pageHeading">Request EDoc</h1>
        </header>
        <div className="center-wrapper col-lg-10 col-sm-12 col-md-12 m-auto">
          {alert_message && (
            <div
              className={
                is_warning
                  ? 'alert alert-warning alert-dismissible align-items-center pl-3 pr-1 pt-2 pb-2'
                  : 'alert alert-danger alert-dismissible align-items-center pl-3 pr-1 pt-2 pb-2'
              }
              role="alert"
            >
              <Interweave allowAttributes content={alert_message} />
              <button
                type="button"
                className="btn-sm btn--onlyicon btn-link"
                aria-label="Close Alert"
                onClick={() => this.setState({ alert_message: undefined })}
              >
                <i className="icon-close2" aria-hidden="true" />
              </button>
            </div>
          )}
          <div className="pb-3 col-container section-title">
            <h6 className="stepBacklink" role="presentation">
              <a href="javascript:;" onClick={this.closeModal}>
                <i className="icon-left-arrow" /> Back
              </a>
            </h6>
          </div>
          <Form>
            <FormGroup
              className={errors.recipient ? 'form-error mb-0' : 'mb-0'}
            >
              <Label htmlFor="forEmail">To *</Label>
              <div className="mb-2 float-right">
                <CheckboxField
                  id="notifyViaText"
                  name="kbacheckbox"
                  label="KBA"
                  labelclassName="text-body text-transform-none font-weight-normal"
                  value={isKBAenabled}
                  onChange={this.handleKBACheckbox}
                />
              </div>
              <div className="labelValue customTags mailDrodown">
                <div className="customTags__input form-control">
                  {this.renderTags()}
                  <a
                    hrfe="javascript:;"
                    onClick={this.onFocus}
                    className="inputHelper dropdown-toggle noCaret"
                  />
                  {/* <Input type="text" autopopulate="off" id="messageto" name="message_to" onFocus={this.onFocus} autoComplete="off" className="dropdown-toggle" placeholder="" value={this.state.inputVal} /> */}
                </div>
                {this.state.dropdownOpen ? (
                  <DropdownMenu className="customTags__option option_box">
                    <OptionBoxSelectFieldWithAvatar
                      name="seachContact"
                      label="Recipient"
                      options={this.state.contacts}
                      handleChange={this.handleContactChange}
                      handleInputChange={this.handleContactInput}
                      value={{
                        value: this.state.contactId,
                        label: this.state.contactName,
                      }}
                      placeholder="Select a Recipient"
                      noResultsText={contactDropdownMessage}
                      isLoading={gettingContacts}
                      error={errors.contact}
                      autofocus
                      clearable
                    />
                    <OptionBoxSelectFieldWithAvatar
                      name="seachAccount"
                      label="Account"
                      options={this.state.accounts}
                      handleChange={this.handleAccountChange}
                      handleInputChange={this.handleAccountInput}
                      value={{
                        value: this.state.accountId,
                        label: this.state.accountName,
                      }}
                      placeholder="Select an account"
                      noResultsText={accountDropdownMessage}
                      isLoading={gettingAccounts}
                      error={errors.account}
                      clearable
                    />
                    <div className="btn-wrap btn--leftspace">
                      <Button
                        className="btn-link btn-sm btn--onlyicon"
                        onClick={this.onFocus}
                      >
                        Close
                      </Button>
                      <Button
                        className="btn btn-sm btn-primary"
                        onClick={this.addSelectedInput}
                      >
                        Ok
                      </Button>
                    </div>
                  </DropdownMenu>
                ) : null}
              </div>
              <span className="error_label">{this.state.errors.recipient}</span>
            </FormGroup>
            <div className="mt-1 mb-1 d-flex justify-content-between align-items-end">
              <StyledDiv className="Date__Container">
                <label htmlFor="due_date" className="Date__Label">
                  {' '}
                  Due Date{' '}
                </label>
                <div className="DateInput__Container">
                  <CustomDatePicker
                    toolTipLabel="Due Date"
                    label="Due Date"
                    selected={this.state.due_date}
                    onChange={this.handleDate}
                    minDate={new Date()}
                    customStyle={borderStyle}
                  />

                  {this.state.errors.dueDate && (
                    <span className="DueDate__ErrorText">
                      {' '}
                      {this.state.errors.dueDate}{' '}
                    </span>
                  )}
                </div>
              </StyledDiv>
              {/* <div className="d-flex align-items-baseline"> */}
              <SigningOrder className="SigningOrder__Container">
                <div
                  className="d-flex align-items-center"
                  style={{ paddingRight: '10px' }}
                >
                  <label className="fontSize font-weight-normal mr-2">
                    <span>Signing Order</span>
                    <Switch
                      onChange={this.handleSigningOrder}
                      checked={isSigningOrderenabled}
                      className="react-switch"
                      onColor="#00aecc"
                      offColor="#dfe3e8"
                      handleDiameter={20}
                      uncheckedIcon={
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                            fontSize: 12,
                            color: '#919eab',
                            paddingRight: 2,
                          }}
                        >
                          OFF
                        </div>
                      }
                      checkedIcon={
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                            fontSize: 12,
                            color: 'white',
                            paddingRight: 2,
                          }}
                        >
                          ON
                        </div>
                      }
                    />
                  </label>
                </div>
                <Button
                  color="primary"
                  onClick={() => this.showSignersInPopUp(true)}
                  disabled={
                    selectedRecords === undefined ||
                    selectedRecords.length === 0
                  }
                >
                  Assign Order/KBA
                </Button>
                {/* </div> */}
              </SigningOrder>
            </div>
            <FormGroup
              className={
                this.state.errors.subject
                  ? ' form-error cstmClr position-relative'
                  : 'cstmClr position-relative'
              }
            >
              <Label htmlFor="subject">Agreement Title</Label>
              <div className="labelValue">
                {(selectedOption && selectedOption !== null) ||
                (!selectedOption &&
                  selectedOption === null &&
                  subject &&
                  !this.state.titleChange) ? (
                  <Input
                    autoComplete="off"
                    // onKeyPress={(e) => this.keyPress(e)}
                    type="text"
                    name="subject"
                    id="Subject"
                    value={this.state.subject}
                    onChange={this.handleChange}
                    // placeholder=""
                    maxLength="200"
                  />
                ) : (
                  <Select
                    name="subject"
                    placeholder=""
                    ref={this.selectInputRef}
                    className="select-custom-class slctTemp"
                    value={selectedOption}
                    inputValue={this.state.subject}
                    onChange={this.templateDropdown}
                    options={templateOptions}
                    optionComponent={SelectTemplateFieldOption}
                    onInputChange={this.templateChange}
                    inputRenderer={(input) => this.inputRenderer(input)}
                    tabSelectsValue={false}
                    onBlurResetsInput={false}
                    onCloseResetsInput={false}
                    onSelectResetsInput={this.state.subject} // false shows
                    noResultsText=""
                    styles={selectStyles}
                    theme={selectTheme}
                    classNamePrefix="custom-react-select"
                    components={{
                      Option: SelectTemplateFieldOption,
                      Input: this.inputRenderer,
                    }}
                  />
                )}
                {subject ? (
                  <Button
                    className="btn btn-sm btn-primary"
                    onClick={() => this.templateDropdown(null)}
                  >
                    Clear
                  </Button>
                ) : null}
                <span className="error_label">{this.state.errors.subject}</span>
              </div>
            </FormGroup>
            <FormGroup
              className={this.state.errors.comment ? ' form-error' : ''}
            >
              <Label htmlFor="forDescription">Description</Label>
              <div className="labelValue customEditor boxResize">
                <TinymceEditor
                  initialValue=""
                  value={this.state.comment}
                  handleChange={this.handleCommentChange}
                />
                <span className="error_label">{this.state.errors.comment}</span>
              </div>
            </FormGroup>
            <FormGroup className="dragWrap">
              <Label htmlFor="attachments">Attachments</Label>
              <TaskFileUpload
                data={this.state}
                uploadType="edoc"
                type="edoc"
                updateDocumentState={this.updateDocumentState}
                handleTemplatesBtn={this.handleTemplatesBtn}
                updateForm={this.updateForm}
              />
              <div className="attached-file-list">
                {selectedTemplates && selectedTemplates.length
                  ? selectedTemplates.map((doc) => (
                      <div key={`para${doc.id}`} className="file-drop-upload">
                        <div className="file-drop-info">
                          <div className="file-drop-detail">
                            <h6
                              href="#"
                              className="fileName"
                              key={`document${doc.id}`}
                            >
                              <span className="fileName pr-0">{doc.name}</span>
                            </h6>
                          </div>
                          <div>
                            <a
                              className="previewFile file-action"
                              key={`doc${doc.id}`}
                              href="javascript:void(0)"
                              onClick={() => this.showTemplatePreview(doc.id)}
                            >
                              <i className="icon-open-eye" /> Preview
                            </a>
                          </div>
                          <div>
                            <a
                              className="file-action"
                              key={`delete${doc.id}`}
                              onClick={() => this.deleteTemplate(doc, false)}
                              href="javascript:void(0)"
                            >
                              <i className="icon-close2" />
                            </a>
                          </div>
                        </div>
                      </div>
                    ))
                  : null}
              </div>
            </FormGroup>
            {this.state.errors.document && (
              <div className="error_label">{this.state.errors.document}</div>
            )}
            <div className="form-group formbtn formbtn--border justify-content-end">
              <div className="btn-wrap btn--leftspace">
                <Button
                  className="btn btn-outline-light"
                  onClick={() => this.handleCancel()}
                  type="button"
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  onClick={() => this.saveMessage('Sent')}
                  disabled={gettingDocumentUrls}
                >
                  Prepare Doc for Signing
                </Button>
              </div>
            </div>
          </Form>
          <Modal
            isOpen={
              this.state.showSigners &&
              selectedRecords &&
              selectedRecords.length
            }
            className="modal-dialog customModal customModal--w700 customModal--nopadding"
          >
            <ModalHeader className="ModalHeader">
              <button
                type="button"
                onClick={() => this.showSignersInPopUp(false)}
                className="close"
                aria-label="Close"
              >
                <i className="icon-close2" aria-hidden="true" />
              </button>
            </ModalHeader>
            <ModalBody className="modalContent">
              <div className="modalContent--inner">
                <div className="modalContent--header border-0 mb-1">
                  <h5 className="modalContent--header__heading">
                    {this.state.isSigningOrderenabled
                      ? 'Assign Signer Order'
                      : 'Signers'}
                  </h5>
                </div>
                <SignersList
                  selectedRecords={[...selectedRecords]}
                  updateList={this.onListChange}
                  handleCancelBtn={this.showSignersInPopUp}
                  isSigningOrderenabled={isSigningOrderenabled}
                />
              </div>
            </ModalBody>
          </Modal>
          <Modal
            isOpen={this.state.showTemplates}
            className="modal-dialog customModal customModal--w900 customModal--nopadding"
          >
            <ModalHeader className="ModalHeader">
              <button
                type="button"
                onClick={() => this.handleTemplatesBtn(false)}
                className="close"
                aria-label="Close"
              >
                <i className="icon-close2" aria-hidden="true" />
              </button>
            </ModalHeader>
            <ModalBody className="modalContent" style={{ maxHeight: 'none' }}>
              <div className="modalContent--inner">
                <div className="modalContent--header border-0 mb-1">
                  <h5 className="modalContent--header__heading">
                    Templates List
                  </h5>
                </div>
                <EsignTemplatesList
                  selectedRecords={[...selectedRecords]}
                  updateList={this.onListChange}
                  handleCancelBtn={this.showSignersInPopUp}
                  isSigningOrderenabled={isSigningOrderenabled}
                  assignSelectedTemplateId={this.assignSelectedTemplateId}
                />
              </div>
            </ModalBody>
          </Modal>
          {selectedTemplateId && (
            <TemplatePreview
              closeModal={this.showTemplatePreview}
              templateId={selectedTemplateId}
            />
          )}
          <ConfirmationModal
            isOpen={this.state.warningMsgLost}
            className="customModal customModal--w500 customModal--nopadding"
            messageText="You are about to delete the existing message. This action cannot be undone. Proceed?"
            noBtnClick={() => this.setState({ warningMsgLost: false })}
            yesBtnClick={() => this.templateDropdown(selectedTemplate)}
            yesBtnText="Yes"
            noBtnText="No"
          />
        </div>
      </div>
    );
  }
}

export default withStorageData(NewEsign);
