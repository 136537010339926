import {
  InputBase,
  FormHelperText,
  InputBaseProps,
  FormControlProps,
  FormHelperTextProps,
  InputLabelProps,
} from '@mui/material';
import { randomString } from 'utilities/utils';
import { FormControlStyled } from './FormControlStyled';
import { InputLabelStyled } from './InputLabelStyled';

export type TextFieldStyledProps = {
  InputBaseProps?: InputBaseProps;
  error?: boolean;
  InputLabelProps?: InputLabelProps;
  label?: string;
  helperText?: string;
  FormHelperTextProps?: FormHelperTextProps;
  sx?: FormControlProps['sx'];
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  fullWidth?: boolean;
} & FormControlProps;

export const TextFieldStyled = ({
  error,
  InputBaseProps: InputProps,
  InputLabelProps: LabelProps,
  label,
  helperText,
  FormHelperTextProps: HelperTextProps,
  sx,
  fullWidth,
  onChange,
  defaultValue,
}: TextFieldStyledProps) => {
  const inputLabelId = InputProps?.id ? InputProps.id : randomString(5);
  return (
    <FormControlStyled error={error} sx={sx}>
      {label && (
        <InputLabelStyled htmlFor={inputLabelId} {...LabelProps}>
          {label}
        </InputLabelStyled>
      )}
      <InputBase
        id={inputLabelId}
        type="text"
        onChange={onChange}
        fullWidth={fullWidth}
        defaultValue={defaultValue}
        {...InputProps}
      />
      {helperText && (
        <FormHelperText {...HelperTextProps}>{helperText}</FormHelperText>
      )}
    </FormControlStyled>
  );
};
