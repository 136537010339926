import React from 'react';
import Select from 'react-select';
import { EDIT, EDIT_RECURRING } from 'constants/constants';

const AccountSelect = ({
  allAccounts,
  handleAccountChange,
  account,
  invalidAccount,
  selectStyles,
  mode,
  isQBMode,
}) => (
  <div className="InvoiceForm__Field">
    <Select
      name="account"
      id="account"
      inputId="account"
      placeholder="Select Account"
      className="Select InvoiceFormField__AccountSelect"
      styles={selectStyles}
      options={allAccounts}
      onChange={handleAccountChange}
      value={account}
      isSearchable
      isClearable
      invalidValue={invalidAccount}
      isDisabled={mode === EDIT || mode === EDIT_RECURRING || isQBMode}
    />
    {invalidAccount && allAccounts.length === 0 && (
      <div className="InvoiceForm__Error">
        Please select a Contact associated with an Account
      </div>
    )}
    {invalidAccount && allAccounts.length !== 0 && (
      <div data-testid="accountSelectError" className="InvoiceForm__Error">
        This is a required field
      </div>
    )}
  </div>
);

export default AccountSelect;
