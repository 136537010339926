/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { Row, Col } from 'reactstrap';
import Pagination from 'common/Pagination';
import { useParams, useNavigate } from 'react-router-dom-latest';
import { trackMixpanelEvent } from 'utilities/mixpanelfn';
import { MIXPANEL_EMAIL_READ_EVENT } from 'constants/constants';
import Menu from 'common/Menu';
import DisplaySnippet from 'common/DisplaySnippet';
import EmailThreadDetails from 'components/Emails/EmailThreadDetails';
import EmailActionsDropdown from './EmailActionsDropdown';

function timeAgo(emailDate) {
  const diff = (new Date() - new Date(emailDate)) / 1000;
  const hours = Math.floor(diff / 60 / 60);
  if (hours > 24 || new Date().getDate() > new Date(emailDate).getDate())
    return dayjs(emailDate).format('MM/DD/YY');
  return dayjs(emailDate).format('h:mm a');
}

const emailsTimelineCategories = [
  {
    key: 'today',
    label: 'Today',
  },
  {
    key: 'yesterday',
    label: 'Yesterday',
  },
  {
    key: 'thisWeek',
    label: 'Past 7 days',
  },
  {
    key: 'lastWeek',
    label: 'Past 14 days',
  },
  {
    key: 'thisMonth',
    label: 'This month',
  },
  {
    key: 'lastMonth',
    label: 'Last month',
  },
  {
    key: 'thisYear',
    label: 'This year',
  },
  {
    key: 'lastYear',
    label: 'Last year',
  },
  {
    key: 'pastYear',
    label: 'Past year',
  },
];

export default function EmailsList(props) {
  const {
    hasSelectedAll,
    toggleSelectAll,
    toggleSelect,
    handleBultActions,
    filter,
    connectedApp,
    emails,
    accountId,
    contactId,
    taskId,
    refreshPage,
    from,
    unreadEmail,
    activeTab,
    idIndexx,
    nextIdd,
  } = props;
  const params = useParams();
  const navigate = useNavigate();

  let { id } = params;
  const [idIndex, setIdIndex] = useState(null);
  const [nextId, setNextId] = useState(null);

  if (accountId || contactId) {
    id = params.email_id;
  } else if (taskId) {
    id = parseInt(window.location.href.split('=')[1], 10);
  }

  const composeEmail = (actionType, email_details, index = null) => {
    if (index && index >= 0) {
      emails[index].is_read = true;
    }
    let url = '/emails/';
    if (accountId) {
      url = `/accountdetails/${accountId}/emails/`;
    } else if (contactId) {
      url = `/contactdetails/${contactId}/emails/`;
    } else if (taskId) {
      url = `/task/detail/${taskId}#emails?id=`;
    }
    trackMixpanelEvent(MIXPANEL_EMAIL_READ_EVENT);
    navigate(url + email_details.id, {
      state: {
        connectedApp,
        actionType,
      },
    });
  };

  useEffect(() => {
    setIdIndex(idIndexx);
    setNextId(nextIdd);
  }, [idIndexx, nextIdd]);

  useEffect(() => {
    setIdIndex(null);
    setNextId(null);
  }, [activeTab]);

  useEffect(() => {
    if (
      idIndex !== null &&
      nextId !== null &&
      emails?.length > 0 &&
      emails[idIndex] &&
      emails[idIndex].id === nextId
    ) {
      composeEmail('', emails[idIndex]);
    }
  }, [emails]);

  const nextThread = (markdoneid = '') => {
    if (id && markdoneid && id.toString() === markdoneid.toString()) {
      const indx = emails.findIndex(
        (each) => each.id.toString() === id.toString(),
      );
      setIdIndex(indx);
      setNextId(emails[indx + 1] ? emails[indx + 1].id : null);
      if (indx >= 0) {
        refreshPage();
      }
    } else {
      refreshPage();
    }
  };

  const newPage = () => {
    refreshPage();
    let url = '/emails/';
    if (accountId) {
      url = `/accountdetails/${accountId}/emails/`;
    } else if (contactId) {
      url = `/contactdetails/${contactId}/emails/`;
    } else if (taskId) {
      url = `/task/detail/${taskId}#emails?id=`;
    }
    navigate({
      pathname: url,
      connectedApp,
    });
  };

  const emailsFrom = emails.reduce(
    (acc, email, index) => {
      const htmlContent = (
        <div key={email.msg_id} testim_id={`testim_email_${+index}`}>
          <div
            className={`border-bottom py-2 ${
              id && id.toString() === email.id.toString()
                ? 'em_short-active'
                : 'em_short'
            }`}
          >
            <div className="em_inner d-flex">
              <span
                className={
                  email.is_read !== undefined && !email.is_read
                    ? 'rounded status visible mt-2'
                    : 'rounded status invisible mt-2'
                }
              />
              {props.from && props.from === 'linkedEmailAccConTask' ? null : (
                <div className="em_check pt-1 pr-1 ml-2">
                  <span className="checkbox checkbox-primary check-container">
                    <label htmlFor={`selectEmail${email.id}`}>
                      <input
                        name={`selectEmail${email.id}`}
                        id={`selectEmail${email.id}`}
                        type="checkbox"
                        className="form-check-input"
                        checked={email.isSelected || false}
                        onChange={() => toggleSelect(index)}
                      />
                      <i className="checkmark" />
                    </label>
                  </span>
                </div>
              )}
              <div className="em_rightBlk flex-grow-1 px-2">
                <Row className="no-gutters">
                  <Col
                    role="button"
                    onClick={() => composeEmail('', email, index)}
                  >
                    <span
                      className="text-body font-weight-bold"
                      style={{ wordBreak: 'break-all' }}
                    >
                      {email.recipients
                        .map((recipient) => recipient.name)
                        .join(', ')}
                    </span>
                  </Col>
                  <Col className="text-right">
                    <div className="d-flex justify-content-end">
                      <span className="text-dark emDate">
                        {timeAgo(email.updatedAt)}
                      </span>
                      {filter !== 'draft' ? (
                        <EmailActionsDropdown
                          emailDetails={email}
                          drpdwnClass=" em_left_dropdown"
                          btnClass="lineHeight-none label-large btn-link btn-lg btn--onlyicon p-0 tglDrop font-weight-normal text-light ml-2 dropdown-toggle"
                          connectedApp={connectedApp}
                          refreshPage={refreshPage}
                          viewContextFrom="listemails"
                          composeEmail={composeEmail}
                          unreadEmail={unreadEmail}
                          showUnreadOption={
                            !(from && from === 'linkedEmailAccConTask')
                          }
                          nextThread={nextThread}
                        />
                      ) : (
                        <div style={{ width: '22px' }} />
                      )}
                    </div>
                  </Col>
                </Row>
                {/* TO DO find better solution for this */}
                <div
                  className="em_sub my-0 posRel"
                  onClick={() => composeEmail('', email, index)}
                >
                  <span
                    className={`em_subText text-body${
                      !email.is_read ? ' font-weight-bold' : ''
                    }`}
                  >
                    <DisplaySnippet snippet={email.subject} type="primary" />
                  </span>
                  {email.attachments && email.attachments.length ? (
                    <span className="em_attach text-dark ml-auto">
                      <i className="icon-attachment" />
                    </span>
                  ) : null}
                </div>
                <div
                  className="em_sContent"
                  onClick={() => composeEmail('', email, index)}
                >
                  <DisplaySnippet snippet={email.snippet} />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
      const emailDateObject = new Date(email.updatedAt);
      const emailYear = emailDateObject.getFullYear();
      const emailMonth = emailDateObject.getMonth();
      const emailDay = emailDateObject.getDate();
      const currentDateObject = new Date();
      const currentYear = currentDateObject.getFullYear();
      const currentMonth = currentDateObject.getMonth();
      const currentDay = currentDateObject.getDate();

      if (emailYear === currentYear) {
        if (emailMonth === currentMonth) {
          if (emailDay === currentDay) {
            if (!acc.today) acc.today = [];
            acc.today.push(htmlContent);
          } else if (currentDay - emailDay === 1) {
            if (!acc.yesterday) acc.yesterday = [];
            acc.yesterday.push(htmlContent);
          } else if (currentDay - emailDay < 7) {
            if (!acc.thisWeek) acc.thisWeek = [];
            acc.thisWeek.push(htmlContent);
          } else if (currentDay - emailDay > 7 && currentDay - emailDay < 14) {
            if (!acc.lastWeek) acc.lastWeek = [];
            acc.lastWeek.push(htmlContent);
          } else {
            if (!acc.thisMonth) acc.thisMonth = [];
            acc.thisMonth.push(htmlContent);
          }
        } else if (currentMonth - emailMonth === 1) {
          if (!acc.lastMonth) acc.lastMonth = [];
          acc.lastMonth.push(htmlContent);
        } else {
          if (!acc.thisYear) acc.thisYear = [];
          acc.thisYear.push(htmlContent);
        }
      } else if (currentYear - emailYear === 1) {
        if (!acc.lastYear) acc.lastYear = [];
        acc.lastYear.push(htmlContent);
      } else if (currentYear - emailYear > 1) {
        if (!acc.pastYear) acc.pastYear = [];
        acc.pastYear.push(htmlContent);
      }
      return acc;
    },
    { pastYears: [] },
  );

  const bulkActions = [];
  if (filter !== 'primary' && filter !== 'sent') {
    bulkActions.push({
      label: 'Primary',
      value: 'primary',
    });
  }
  if (filter !== 'low_priority' && filter !== 'sent') {
    bulkActions.push({
      label: 'Low Priority',
      value: 'low_priority',
    });
  }
  if (filter === 'pinned') {
    bulkActions.push({
      label: 'Unpin',
      value: 'unpin_it',
    });
  } else if (filter !== 'pinned' && filter !== 'draft') {
    bulkActions.push({
      label: 'Pin it',
      value: 'pin_it',
    });
  }
  if (filter !== 'done' && filter !== 'sent') {
    bulkActions.push({
      label: 'Done',
      value: 'done',
    });
  }

  return (
    <div className="em_email border-top">
      <Row className="align-items-stretch no-gutters h-100">
        <Col sm="4">
          {props.emails && props.emails.length ? (
            <div className="em_left border-right h-100 EmailList__LeftColumn">
              <div className="em_left_head border-bottom">
                {props.from && props.from === 'linkedEmailAccConTask' ? (
                  <span className="pl-2 text-light font-weight-normal p-2">
                    {props.data.totalRecords} messages
                  </span>
                ) : (
                  <div className="d-flex justify-content-between p-2">
                    <div className="em_left_check">
                      <span className="checkbox checkbox-primary check-container">
                        <label htmlFor="selectallemailscheckbox">
                          <input
                            name="selectallemailscheckbox"
                            id="selectallemailscheckbox"
                            type="checkbox"
                            className="form-check-input"
                            checked={hasSelectedAll}
                            onChange={toggleSelectAll}
                          />
                          <i className="checkmark" />
                          <span className="pl-2 text-light font-weight-normal">
                            {props.data.totalRecords} messages
                          </span>
                        </label>
                      </span>
                    </div>
                    <Menu
                      menuItems={bulkActions}
                      handleMenuItemClick={handleBultActions}
                    />
                  </div>
                )}
              </div>
              <div className="em_wrap">
                <Scrollbars
                  style={{
                    width: '100%',
                    height: '100%',
                    minHeight: '100%',
                    maxHeight: '100%',
                  }}
                  autoHide
                  autoHeightMin={0}
                  autoHideTimeout={1000}
                >
                  {emailsTimelineCategories.reduce((acc, category) => {
                    if (emailsFrom[category.key]) {
                      acc.push(
                        <React.Fragment key={category.key}>
                          <div className="em_day">
                            <small className="d-block my-2 text-uppercase pr-2">
                              <strong>{category.label}</strong>
                            </small>
                          </div>
                          {emailsFrom[category.key]}
                        </React.Fragment>,
                      );
                    }
                    return acc;
                  }, [])}
                </Scrollbars>
              </div>
              <div className="em_pagination pr-2">
                <div className="pagination-block" id="pagination-block">
                  <Pagination
                    currentPage={props.data.pagination}
                    totalPages={props.data.totalPages}
                    totalRecords={props.data.totalRecords}
                    handlePageChange={props.handlePageChange}
                    offset={props.data.offset[0]}
                    off={props.data.offset[1]}
                    fromPage="emailsList"
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="em_left border-right h-100">
              <div className="em_right_head my-2 py-1">
                {/* <div className="em_right_head_links text-right">
                  Links
                </div> */}
              </div>
              <div className="p-3">
                <div className="mt-5 p-5 text-center">
                  <i className="text-large icon-notification-message mb-2 text-dark" />
                  {/* <h3 className="text-medium">There is no Email</h3> */}
                  <h6 className="text-dark">There is no email to display</h6>
                </div>
              </div>
            </div>
          )}
        </Col>
        <Col sm="8">
          <EmailThreadDetails
            refreshPage={refreshPage}
            nextThread={nextThread}
            newPage={newPage}
            unreadEmail={unreadEmail}
            from={from}
            selectedEmailId={id}
            accountId={accountId}
            contactId={contactId}
          />
        </Col>
      </Row>
    </div>
  );
}
