/* eslint-disable no-console */
/* eslint-disable global-require */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-loop-func */
import React, { Component } from 'react';
import Axios from 'axios';
import {
  SEND_ADOBE_CODE,
  ADOBE_INTEGRATION_STATUS,
  ADOBE_REDIRECT_URL,
  ADOBE_CLIENT_ID,
  DISCONNECT_ADOBE,
  TOAST_FAILURE,
  TOAST_SUCCESS,
} from 'constants/constants';
import { AlertMessage, createCookie } from 'utilities/utils';
import AdminList from 'components/Esign/AdminList';
import ServicePurchases from 'components/Esign/ServicePurchases';

const adobe_client_id = ADOBE_CLIENT_ID;
const redirect_uri = ADOBE_REDIRECT_URL;
const auth_url =
  'https://secure.echosign.com/public/oauth?' +
  `redirect_uri=${redirect_uri}&response_type=code&client_id=${adobe_client_id}&scope=agreement_read:account agreement_write:account` +
  ' library_read:account library_write:account' +
  ' user_login:account user_read:account user_write:account' +
  ' webhook_read:account webhook_retention:account webhook_write:account' +
  '&state=';

const randomString = (length) => {
  const charset =
    '0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz-._';
  let result = '';
  while (length > 0) {
    const bytes = new Uint8Array(16);
    const random = window.crypto.getRandomValues(bytes);

    random.forEach((c) => {
      if (length === 0) {
        return;
      }
      if (c < charset.length) {
        result += charset[c];
        // eslint-disable-next-line no-param-reassign
        length -= 1;
      }
    });
  }
  return result;
};

const tableHeaders = [
  {
    value: 'name',
    label: '',
    isSortable: false,
    className: ' col thCol',
  },
  {
    value: 'modifiedDate',
    label: 'Esign',
    isSortable: false,
    className: ' col-3 thCol',
  },
  {
    value: 'actions',
    label: 'Kba',
    isSortable: false,
    className: ' col-3 thCol',
  },
];

class Adobe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    this.getIntegrationStatus();
  }

  getIntegrationStatus = () => {
    Axios.get(`${ADOBE_INTEGRATION_STATUS}?purchases=true`).then((res) => {
      if (res.status === 200 && res.data && res.data.data) {
        const {
          adobe_admin_email,
          adobe_token_exists,
          service_purchases,
          service_usages,
        } = res.data.data;
        const stats = [
          {
            label: 'Purchased',
            esign: 0,
            kba: 0,
          },
          {
            label: 'Used',
            esign: 0,
            kba: 0,
          },
          {
            label: 'Balance',
            esign: 0,
            kba: 0,
          },
        ];
        if (res.data.data.service_usages) {
          res.data.data.service_usages.map((e) => {
            if (e.service_type && e.service_type.toLowerCase() === 'esign') {
              stats[0].esign = e.unit_purchased;
              stats[1].esign = e.final_used + e.in_process_used;
              stats[2].esign = e.unit_purchased - stats[1].esign;
            } else if (
              e.service_type &&
              e.service_type.toLowerCase() === 'kba'
            ) {
              stats[0].kba = e.unit_purchased;
              stats[1].kba = e.final_used + e.in_process_used;
              stats[2].kba = e.unit_purchased - stats[1].kba;
            }
            return e;
          });
        }
        this.setState({
          adobe_admin_email,
          adobe_token_exists,
          adobe_integration_details: res.data.data,
          stats,
          service_purchases,
          service_usages,
          loading: false,
        });
        if (!adobe_token_exists) {
          this.sendCodeToBackend();
        }
      } else {
        this.setState({
          loading: false,
        });
      }
    });
  };

  sendCodeToBackend = () => {
    const parsedUrl = new URL(window.location.href);
    const app_key = parsedUrl.searchParams.get('app');
    const code = parsedUrl.searchParams.get('code');
    const base_api_url = parsedUrl.searchParams.get('api_access_point');
    const web_access_point = parsedUrl.searchParams.get('web_access_point');
    const error = parsedUrl.searchParams.get('error_description');
    if (code && code !== null && app_key !== null && app_key === 'adobe') {
      Axios.post(SEND_ADOBE_CODE, {
        code,
        base_api_url,
        web_access_point,
      }).then((res) => {
        if (res.status === 200 && res.data && res.data.success) {
          this.setState({
            adobe_token_exists: true,
            loading: false,
          });
          AlertMessage('success', res.data.message, 3000);
        } else {
          this.setState({
            loading: false,
          });
          AlertMessage('error', res.data.message, 3000);
        }
      });
    } else if (error && error !== null) {
      AlertMessage('error', error, 10000);
    }
  };

  showEmployees = () => {
    this.setState({
      showEmployeesList: true,
    });
  };

  hideEmployeeListModal = (refreshList) => {
    this.setState({
      showEmployeesList: false,
    });
    if (refreshList) {
      this.getIntegrationStatus();
    }
  };

  showPurchases = (purchaseType) => {
    const { showPurchases } = this.state;
    this.setState({
      showPurchases: !showPurchases,
      purchaseType,
    });
  };

  handleConnectAdobeBtn = () => {
    const url = window.location.href.split('.liscio');
    if (url && url.length) {
      const cookieName = randomString(16);
      // TODO: consider applying the query param fix from the handleConnect function of Quickbooks_updated.tsx
      createCookie(cookieName, `${window.location.href}?app=adobe`, 30);
      const state = cookieName;
      window.location.href = auth_url + state;
    }
  };

  // used to disconnect from adobe integration
  handleDisconnectAdobeBtn = async () => {
    try {
      const { data } = await Axios.delete(DISCONNECT_ADOBE);

      if (data.success) {
        this.setState({ adobe_token_exists: false });
        AlertMessage(
          TOAST_SUCCESS,
          'Adobe integration successfully disconnected',
          3000,
        );
      } else {
        console.log(data.message);
        AlertMessage(TOAST_FAILURE, 'An error occurred', 3000);
      }
    } catch (error) {
      console.log(error);
      AlertMessage(TOAST_FAILURE, 'An error occurred', 3000);
    }
  };

  render() {
    const {
      adobe_admin_email,
      showEmployeesList,
      adobe_token_exists,
      adobe_integration_details,
      stats,
      showPurchases,
      service_purchases,
      purchaseType,
      service_usages,
    } = this.state;
    const { integrationAppsPref } = this.props;
    return (
      <div className="col-lg-4 col-sm-12 col-md-6">
        <div
          className="card mb-4 shadow-s"
          style={{ height: 'calc(100% - 16px)' }}
        >
          <div
            className="card-img-top d-flex justify-content-center"
            style={{ height: '150px', alignItems: 'center' }}
          >
            <img
              src={require('images/adobe_icon.png')}
              alt="adobe_icon"
              style={{ maxHeight: '100%' }}
            />
          </div>
          <div className="card-body">
            <h5 className="card-title">{integrationAppsPref.label}</h5>
            {adobe_admin_email &&
            adobe_integration_details &&
            adobe_integration_details.adobe_admin_first_name ? (
              <div className="form-group form-inline-label mb-0">
                <label className="align-self-start" style={{ minWidth: '0' }}>
                  Admin:
                </label>
                <div className="labelValue">
                  <span>
                    {`${adobe_integration_details.adobe_admin_first_name} ${adobe_integration_details.adobe_admin_last_name}`}
                  </span>
                </div>
              </div>
            ) : null}
            {adobe_admin_email ? (
              <div className="form-group form-inline-label">
                <label className="align-self-start" style={{ minWidth: '0' }}>
                  Email:
                </label>
                <div className="labelValue">
                  <span>{adobe_admin_email}</span>
                </div>
              </div>
            ) : null}
            {adobe_admin_email &&
            adobe_integration_details &&
            service_usages &&
            service_usages.length ? (
              <div className="tableWrap">
                <div className="tRow tRow--head">
                  <div className="row">
                    {tableHeaders.map((each) => (
                      <div
                        key={each.value}
                        className={
                          each.className +
                          (!each.isSortable ? ' cursor-default' : '')
                        }
                        onClick={() =>
                          each.isSortable ? this.handleSortBy(each.value) : null
                        }
                      >
                        <span
                          className={
                            each.isSortable ? 'posRel' : 'posRel cursor-default'
                          }
                        >
                          {each.label}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="tRow">
                  {stats &&
                    stats.map((e, key) => (
                      <div className="row" key={e.label}>
                        <div className="col thCol pt-2 pb-2">
                          <span>{e.label}</span>
                        </div>
                        <div className="col-3 tdCol pt-2 pb-2">
                          <span>
                            {e.esign}
                            {key === 0 && (
                              <i
                                className="icon-view-details pl-2"
                                onClick={() => this.showPurchases('esign')}
                              />
                            )}
                          </span>
                        </div>
                        <div className="col-3 tdCol pt-2 pb-2">
                          <span>
                            {e.kba}
                            {key === 0 && (
                              <i
                                className="icon-view-details pl-2"
                                onClick={() => this.showPurchases('kba')}
                              />
                            )}
                          </span>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            ) : null}
            {adobe_admin_email && service_usages && service_usages.length ? (
              <>
                <button
                  type="button"
                  className="btn btn-primary col mt-3"
                  onClick={this.handleConnectAdobeBtn}
                  disabled={adobe_token_exists}
                >
                  {adobe_token_exists ? 'Connected' : 'Connect'}
                </button>
                <button
                  type="button"
                  className="btn btn-primary col mt-3"
                  onClick={this.handleDisconnectAdobeBtn}
                  disabled={!adobe_token_exists}
                >
                  {adobe_token_exists ? 'Disconnect' : 'Disconnected'}
                </button>
              </>
            ) : null}
            {adobe_admin_email === undefined &&
            service_usages &&
            service_usages.length ? (
              <button
                type="button"
                className="btn btn-primary col mt-2"
                onClick={this.showEmployees}
                disabled={adobe_admin_email}
              >
                Create Adobe Account
              </button>
            ) : null}
            {(service_usages === undefined || service_usages.length === 0) &&
            !this.state.loading ? (
              <span>
                Please contact Liscio Support Team for purchasing eSignature
                service.
              </span>
            ) : null}
            {showEmployeesList && (
              <AdminList handleCloseBtn={this.hideEmployeeListModal} />
            )}
            {showPurchases && service_purchases && (
              <ServicePurchases
                listData={service_purchases}
                purchaseType={purchaseType}
                handleCloseBtn={() => this.setState({ showPurchases: false })}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Adobe;
