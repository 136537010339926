import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import { USER_EMAIL_SETTINGS } from 'constants/constants';
import ComposeEmailFromThread from './ComposeEmailFromThread';
import { getInitialState } from '../EmailUtilities';

const NewEmail = (props) => {
  const [connectedAppDetails, setConnectedAppDetails] = useState({});

  useEffect(() => {
    const request = Axios.get(USER_EMAIL_SETTINGS);
    request.then((response) => {
      if (response.status === 200) {
        setConnectedAppDetails(response.data.app_token);
      }
    });
  }, []);

  function composeEmailAction() {
    props.navigate('/emails');
  }

  return (
    <div id="content">
      <header>
        <ul className="breadcrumb">
          <li className="breadcrumb-item">New Email</li>
        </ul>
      </header>
      <div className="center-wrapper col-lg-10 col-sm-12 col-md-12 m-auto">
        {connectedAppDetails.app_key ? (
          <ComposeEmailFromThread
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
            initialState={getInitialState()}
            connectedAppKey={connectedAppDetails.app_key}
            connectedAppAccessToken={
              connectedAppDetails && connectedAppDetails.access_token
                ? connectedAppDetails.access_token
                : ''
            }
            composeEmailAction={composeEmailAction}
          />
        ) : null}
      </div>
    </div>
  );
};

export default NewEmail;
