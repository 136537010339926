import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import moment from 'moment';
import { sortingIcon, sortList } from 'utilities/utils';

const tableHeaders = (input) => [
  {
    value: 'created_at',
    label: 'Date',
    isSortable: true,
    className: ' col-3 thCol',
  },
  {
    value: 'units_purchased',
    label: input === 'kba' ? '# of kba' : '# of Esign',
    isSortable: true,
    className: ' col-3 thCol',
  },
  {
    value: 'note',
    label: 'Note',
    isSortable: false,
    className: ' col thCol text-right',
  },
];

const filterData = (data, filterBy = 'esign') => {
  if (data === undefined && data.length === 0) {
    return [];
  }
  return data.filter(
    (e) =>
      e.service_type && e.service_type.toLowerCase() === filterBy.toLowerCase(),
  );
};

export default class ServicePurchases extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      sortBy: 'created_at',
      sortOrder: 'desc',
      listData: sortList(
        filterData(this.props.listData, this.props.purchaseType),
        'created_at',
        'desc',
      ),
    };
  }

  handleSortBy = (sortBy) => {
    let { sortOrder } = this.state;
    if (this.state.sortBy === sortBy) {
      if (sortOrder === 'asc') {
        sortOrder = 'desc';
      } else {
        sortOrder = 'asc';
      }
    } else {
      sortOrder = 'asc';
    }
    const { listData } = this.state;
    this.setState({
      sortOrder,
      sortBy,
      listData: sortList(listData, sortBy, sortOrder),
    });
  };

  render() {
    const { handleCloseBtn, purchaseType } = this.props;
    const { listData } = this.state;
    return (
      <Modal
        isOpen
        className="customModal customModal--center customModal--alert customModal--w600 customModal--nopadding"
      >
        <ModalHeader className="ModalHeader">
          <button
            type="button"
            onClick={() => handleCloseBtn()}
            className="close"
            aria-label="Close"
          >
            <i className="icon-close2" aria-hidden="true" />
          </button>
        </ModalHeader>
        <ModalBody className="modalContent">
          {this.state.loading ? <div id="loading" /> : null}
          <div className="modalContent--inner">
            <div className="modalContent--header text-large">
              <h4 className="modalContent--header__heading">
                {purchaseType === 'kba' ? 'KBA Purchases' : 'eSign Purchases'}
              </h4>
            </div>
            <div className="tab-content">
              <div className="tab-pane active" id="review">
                <div id="inbox-list" className="tableWrap">
                  <div className="tRow tRow--head">
                    <div className="row">
                      {tableHeaders(purchaseType).map((each) => (
                        <div
                          key={each.value}
                          className={
                            sortingIcon(
                              this.state.sortBy,
                              each.value,
                              this.state.sortOrder,
                              'className',
                            ) +
                            each.className +
                            (!each.isSortable ? ' cursor-default' : '')
                          }
                          onClick={() =>
                            each.isSortable
                              ? this.handleSortBy(each.value)
                              : null
                          }
                        >
                          <span
                            className={
                              each.isSortable
                                ? 'posRel'
                                : 'posRel cursor-default'
                            }
                          >
                            {each.label}
                            {each.isSortable && (
                              <i
                                className={sortingIcon(
                                  this.state.sortBy,
                                  each.value,
                                  this.state.sortOrder,
                                )}
                              />
                            )}
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div
                    className="tRow tRow--body"
                    style={{ height: '60vh', overflow: 'scroll' }}
                  >
                    {listData && listData.length
                      ? listData.map((each) => (
                          <div key={each.id} className="tdBtn row">
                            <div className="col-3 tdCol">
                              <span>
                                {moment(each.created_at).format('MM/DD/YYYY')}
                              </span>
                            </div>
                            <div className="col-3 tdCol">
                              <span>{each.units_purchased}</span>
                            </div>
                            <div className="col tdCol text-right">
                              <span>{each.note}</span>
                            </div>
                          </div>
                        ))
                      : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}
