import React from 'react';
import ContentLoader from 'react-content-loader';
import styled from '@emotion/styled';
import TableLoader from 'common/LoadingScreens/TableLoader';
import TabLoader from 'common/LoadingScreens/TabLoader';
import FiltersLoader from 'common/LoadingScreens/FiltersLoader';
import PaginationLoader from 'common/LoadingScreens/PaginationLoader';

const StyledDiv = styled.div`
  width: '100%';
`;

const BillingLoadingScreen = () => (
  <StyledDiv className="BillingLoadingContainer">
    <ContentLoader
      viewBox="-20 -20 1000 640"
      backgroundColor="#F3F3F3"
      foregroundColor="#E3E4E6"
    >
      <TabLoader />
      <FiltersLoader />
      <TableLoader />
      <PaginationLoader />
    </ContentLoader>
  </StyledDiv>
);

export default BillingLoadingScreen;
