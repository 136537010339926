import { CalendarIcon } from 'common/Icons';
import { WorkflowRequestFragment, WorkflowRequestStatus } from 'gql/graphql';
import { useState } from 'react';
import { useUpdateWorklowRequest } from 'hooks/workflow-request-hooks';
import { TOAST_FAILURE, TOAST_SUCCESS } from 'constants/constants';
import { AlertMessage } from 'utilities/utils';
import { useQueryClient } from '@tanstack/react-query';
import { ChangeDueDateDialog } from './components/ChangeDueDateDialog';
import { ChangeDueDateButton } from './components/ChangeDueDateButton';
import { RequestOptionsMenuItem } from './components/RequestOptionsMenuItem';

/**
 * This hook willl return an object containing the following:
 * - ChangeDueDateButton for rendering a standard Button
 * - changeDueDateHeaderItem for rendering a Menu Item in the Header
 * - ChangeDueDateDialog for rendering the Dialog
 * - RequestOptionsMenuItem for rendering a Menu Item in the Table ellipses
 */
export const useChangeRequestDueDate = (
  workflowRequest: WorkflowRequestFragment | null | undefined,
) => {
  const queryClient = useQueryClient();
  const [displayDialog, setDisplayDialog] = useState(false);
  const [dueDate, setDueDate] = useState<Date>(
    new Date(workflowRequest?.dueDate),
  );
  const { mutate: updateWorkflowRequestMutation } = useUpdateWorklowRequest();
  const toggleDisplay = () => {
    if (!displayDialog) setDueDate(new Date(workflowRequest?.dueDate));
    setDisplayDialog((state) => !state);
  };

  const CHANGE_DUE_DATE = 'Change Due Date';

  const handleDateChange = (date: Date) => {
    setDueDate(date);
  };

  const isValidStatus = () =>
    [
      WorkflowRequestStatus.Open,
      WorkflowRequestStatus.Requested,
      WorkflowRequestStatus.Submitted,
    ].includes(workflowRequest?.status!);

  const sendDueDateChange = () => {
    updateWorkflowRequestMutation(
      {
        workflowRequestId: workflowRequest?.id,
        dueDate: dueDate.toISOString(),
      },
      {
        async onSuccess() {
          AlertMessage(
            TOAST_SUCCESS,
            'Request due date successfully updated',
            1000,
          );

          if (queryClient) await queryClient.refetchQueries();
        },
        onError() {
          AlertMessage(TOAST_FAILURE, 'Failed to change the due date', 1000);
        },
      },
    );
  };

  // Button to be returned
  const renderChangeDueDateButton = () => (
    <ChangeDueDateButton
      isValidStatus={isValidStatus}
      toggleDisplay={toggleDisplay}
      changeDueDateText={CHANGE_DUE_DATE}
    />
  );

  // the Change Due Date Header Item to be returned
  const changeDueDateHeaderItem = () => {
    if (isValidStatus())
      return {
        icon: <CalendarIcon />,
        label: CHANGE_DUE_DATE,
        props: {
          onClick: () => toggleDisplay(),
        },
      };

    return null;
  };

  // the Request Options Menu Item to be returned
  const renderRequestOptionsMenuItem = (handleClose: () => void) => (
    <RequestOptionsMenuItem
      isValidStatus={isValidStatus}
      toggleDisplay={toggleDisplay}
      changeDueDateText={CHANGE_DUE_DATE}
      handleClose={handleClose}
    />
  );

  // The primary Dialog
  const renderChangeDueDateDialog = () => (
    <ChangeDueDateDialog
      displayDialog={displayDialog}
      dueDate={dueDate}
      workflowRequest={workflowRequest}
      handleDateChange={handleDateChange}
      toggleDisplay={toggleDisplay}
      sendDueDateChange={sendDueDateChange}
    />
  );

  return {
    changeDueDateHeaderItem: () => changeDueDateHeaderItem(),
    RequestOptionsMenuItem: ({ handleClose }: { handleClose: () => void }) =>
      renderRequestOptionsMenuItem(handleClose),
    ChangeDueDateButton: () => renderChangeDueDateButton(),
    ChangeDueDateDialog: () => renderChangeDueDateDialog(),
  };
};
