import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import { OneDriveResult, OneDrivePicker, PickerProps, LiscioFile, PickerOptions } from "./types"
import { DMS_FILE_EXPORT, MICROSOFT_CLIENT_ID, OAUTH_ACCESS_TOKENS } from '../../constants/constants';
import { AlertMessage } from '../../utilities/utils';

declare const OneDrive: OneDrivePicker;

export default function Picker ({ startUpload = () => {}, cancelWidget, closeModal, selectedRecords, mode, appKey }: PickerProps) {
  const [accessToken, setAccessToken] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [siteUrl, setSiteUrl] = useState('')
  const exportMode = mode === 'export'
  const graphApiBaseUrl = 'https://graph.microsoft.com/v1.0'
  const graphEndpoint = appKey === 'onedrive' ? 'drives' : 'sites?search=*'
  const headers = {
    headers: {
      'Authorization': `Bearer ${accessToken}`,
      'Accept': 'application/json'
    }
  }

  const fetchAccessToken = () => {
    const request = Axios.get(`${OAUTH_ACCESS_TOKENS}/${appKey}`);
    request.then((response) => {
      if (response.status === 200) {
        setAccessToken(response.data.access_token)
      }
    });
  }

  useEffect(() => {
    fetchAccessToken()
  }, []);

  useEffect(() => {
    if (accessToken) { fetchSiteUrl() }
  }, [accessToken]);

  const fetchSiteUrl = () => {
    Axios.get(`${graphApiBaseUrl}/${graphEndpoint}`, headers)
      .then(response => {
        const site = response.data.value[0];
        setSiteUrl(appKey === 'onedrive' ? site.webUrl : `${site.webUrl}/Shared%20Documents`)
        setIsLoading(false)
      })
      .catch(error => {
        AlertMessage('error', error.message.data, 3000);
      });
  }

  const launchPicker = () => {
    const odOptions: PickerOptions = {
      clientId: MICROSOFT_CLIENT_ID,
      action: "query",
      viewType: exportMode ? 'folders' : 'files',
      multiSelect: false,
      advanced: {
        accessToken,
        endpointHint: siteUrl,
        scopes: ['Files.ReadWrite.All']
      },
      success: (response: OneDriveResult) => {
        const object = response.value[0]
        if (exportMode) {
          const data = {
            app_key: appKey,
            remote_directory: `drives/${object.parentReference.driveId}/items/${object.id}`,
            document_ids: selectedRecords.map(a => a.id),
          }
          const request = Axios.post(DMS_FILE_EXPORT, data);
          request
            .then(r => {
              AlertMessage('success', r.data.message, 3000);
            })
            .catch(() => {
              AlertMessage('error', 'Upload failed!', 3000);
            })
          cancelWidget('')
          if (exportMode) closeModal()
        } else {
          const downloadUrl = `${graphApiBaseUrl}/drives/${object.parentReference.driveId}/items/${object.id}`;
          Axios.get(downloadUrl, headers)
            .then(r => {
              const fileData: LiscioFile = {
                name: r.data.name,
                type: r.data.file.mimeType,
                '@microsoft.graph.downloadUrl': r.data["@microsoft.graph.downloadUrl"]
              }
              AlertMessage('success', 'File uploading', 3000);
              startUpload([fileData], appKey);
            })
            .catch(error => {
              AlertMessage('error', error.message.data, 3000);
            });
          cancelWidget('')
        }
      },
      cancel: () => {
        cancelWidget('')
        if (exportMode) closeModal()
      },
      error: (error: any) => {
        AlertMessage('error', error.message.data, 3000);
        cancelWidget('')
        if (exportMode) closeModal()
      }
    };

    return (
      OneDrive.open(odOptions)
    )
  };

  const loading = () => (
    <div className="col-sm-12">
      <div id="loading" className="d-block"/>
    </div>
  )

  return (
    <div>
      {isLoading && loading()}
      {accessToken && siteUrl && launchPicker()}
    </div>
  )
}
