import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom-latest';
import {
  IN_APP,
  REQUESTS_PAGE_TITLES,
} from 'components/Requests/requests.constants';
import { useEffect } from 'react';
import { RequestType, WorkflowRequestStatus } from 'gql/graphql';
import { OpenRequestsTable } from 'components/Requests/components/Tables/OpenRequestsTable';
import { useWorkflowRequestsQuery } from 'hooks/workflow-request-hooks';
import { usePagination } from 'components/Requests/components/RequestsBuilder/hooks/pagination';
import { useRequestsState } from 'components/Requests/state/request-state';

export const OpenRequestsView = () => {
  useEffect(() => {
    document.title = REQUESTS_PAGE_TITLES.open;
  }, []);
  const { paginationModel, setPaginationModel } = usePagination();
  const sortStatus = useRequestsState(
    (state) => state.sortStatus?.[WorkflowRequestStatus.Requested],
  );
  const filters = useRequestsState((state) => state.filters);

  const workflowRequestQuery = useWorkflowRequestsQuery({
    statuses: [WorkflowRequestStatus.Requested, WorkflowRequestStatus.Open],
    perPage: paginationModel.pageSize,
    page: paginationModel.page + 1,
    filterData: {
      assigneeIds: filters[WorkflowRequestStatus.Requested].assignedTo,
      ownerIds: filters[WorkflowRequestStatus.Requested].owners,
    },
    ...sortStatus,
  });

  const navigate = useNavigate();

  const handleOpenResponse = (id: string, type: RequestType) => {
    navigate(`/requests/${id}?type=${type || RequestType.Request}`, {
      state: { from: IN_APP },
    });
  };

  return (
    <Box>
      <OpenRequestsTable
        query={workflowRequestQuery}
        onRowClick={handleOpenResponse}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
      />
    </Box>
  );
};
