import { Box, Typography } from '@mui/material';
import { GridRenderCellParams, GridTreeNodeWithRender } from '@mui/x-data-grid';
import {
  WorkflowRequestStatus,
  WorkflowRequestsWorkflowRequestFragment,
} from 'gql/graphql';
import Label from 'common/Label/Label';
import theme from 'theme';

type NameCellProps = {
  params: GridRenderCellParams<
    WorkflowRequestsWorkflowRequestFragment,
    any,
    any,
    GridTreeNodeWithRender
  >;
  noLabel?: boolean;
  labelIsType?: boolean;
};

export const RequestTitleCell = ({
  params,
  noLabel,
  labelIsType,
}: NameCellProps) => {
  if (labelIsType) {
    const labelTextMap = {
      OrganizerWorkflow: {
        label: ' Organizer',
        color: theme.palette.primary.main,
      },
      TaxDeliveryWorkflow: {
        label: 'Tax Return',
        color: theme.palette.success.main,
      },
    };

    return (
      <Box display="flex" gap="5px" flexDirection="column" alignItems="start">
        <Label
          text={
            labelTextMap[
              params?.row?.workflow?.type as keyof typeof labelTextMap
            ]?.label || ''
          }
          color={
            labelTextMap[
              params?.row?.workflow?.type as keyof typeof labelTextMap
            ]?.color
          }
        />
        <Typography textOverflow="ellipsis" noWrap overflow="hidden">
          {params?.value || ''}
        </Typography>
      </Box>
    );
  }
  if (noLabel) {
    return (
      <Typography textOverflow="ellipsis" noWrap overflow="hidden">
        {params?.value || ''}
      </Typography>
    );
  }
  return (
    <Box display="flex" gap="5px" flexDirection="column" alignItems="start">
      {params?.row?.status === WorkflowRequestStatus.Recalled ? (
        <Label text="Recalled" color={theme.palette.error.main} />
      ) : (
        <Label text="Request" color={theme.palette.primary.main} />
      )}
      <Typography textOverflow="ellipsis" noWrap overflow="hidden">
        {params?.value || ''}
      </Typography>
    </Box>
  );
};
