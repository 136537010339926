import axios from 'axios';
import {
  CONTACT,
  GET_CONT_EMP_OF_ACCOUNT_API_PATH,
  GET_EMP_AND_CONTACT_API_PATH,
  GET_SMS_CONTACT_PHONES,
  SEARCH_TASK_CONTACTS,
} from 'constants/constants';

/* eslint-disable import/prefer-default-export */
export const getContacts = async () => {
  const result = await axios.get(GET_SMS_CONTACT_PHONES);

  if (result.status !== 200) return [];

  return result.data.data as Participant[];
};

export const getEmpAndContactApiPath = async () => {
  const result = await axios.post(GET_EMP_AND_CONTACT_API_PATH, {
    limit: true,
    message: true,
  });

  return result.data as LegacyContact[];
};

export const getEmpAndContactOfAccount = async (
  accountId?: number | string,
) => {
  const result = await axios.get(
    `${GET_CONT_EMP_OF_ACCOUNT_API_PATH}/${accountId}`,
  );
  return result.data.data as LegacyContact[];
};

export const getSearchTaskContacts = async (keyword: string) => {
  let param = `/?keyword=${keyword}&message=true`;

  if (keyword.length === 0) param = '/?message=true';

  const result = await axios.get(`${SEARCH_TASK_CONTACTS}${param}`);
  return result.data as LegacyContact[];
};

export const addToContact = async (data: AddContactData) => {
  const result = await axios.put(`${CONTACT}/${data.contactId}`, data.contact);
  return result.data as LegacyContact;
};
