import React, { Component } from 'react';
import { isEmpty } from 'lodash';
import * as Utilities from 'utilities/utils';
import IndexFileUpload from 'components/UploadedFiles/IndexFileUpload';
import { withStorageData } from 'utilities/withStorageData';
import LiteFilesList from './LiteFilesList_updated';

class FilesList extends Component {
  constructor(props) {
    super(props);
    this.state = { storageData: {} };
  }

  componentDidMount() {
    document.title = 'Files';
    // TODO: __HOMEDATA-AUDIT
    const storageData = Utilities.getStorageData();
    this.setState({ storageData });
  }

  render() {
    const { storageData } = this.state;
    if (!isEmpty(storageData) && storageData.is_employee) {
      return (
        <LiteFilesList
          navigate={this.props.navigate}
          action={this.props.action}
          match={this.props.match}
        />
      );
    }
    if (!isEmpty(storageData) && !storageData.is_employee) {
      return (
        <IndexFileUpload
          navigate={this.props.navigate}
          action={this.props.action}
          match={this.props.match}
        />
      );
    }
    return null;
  }
}

export default withStorageData(FilesList);
