import React from 'react';
import ReactTooltip from 'react-tooltip';

function RenderTooltip({ id, className, place, messageDisplayDelay }) {
  return (
    <ReactTooltip
      id={id}
      className={className}
      aria-haspopup="true"
      place={place}
      data-background-color="black"
      effect="solid"
      delayHide={messageDisplayDelay}
    >
      <span>This contact does not have a phone number.</span>
      {/* <br />
      <u>Add a phone number?</u> */}
      {/* TODO With FD-711 */}
    </ReactTooltip>
  );
}

export default RenderTooltip;
