/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
/* eslint-disable react/no-access-state-in-setstate */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import Axios from 'axios';
import {
  getOffSetsAndLimit,
  updateListData,
  getCpaPreferences,
  isUserEmployee,
  AlertMessage,
  getBulkInviteMaxCount,
  sortList,
  getFirmType,
  getStorageData,
} from 'utilities/utils';
import { SelectFieldWithAvatar } from 'common/CustomFields';
import FilterTab from 'components/Tasks/Tabs/FilterTab_updated';
import List from 'components/Tasks/List_updated';
import * as TaskApi from 'api/TaskApi';
import { sendMixpanelEvent } from 'utilities/mixpanelfn';
import { BULK_REASSIGN_OWNER } from 'constants/constants';
import Pagination from 'common/Pagination';
import ConfirmationModal from 'common/ConfirmationModal';
import NoRecords from 'common/NoRecords';
import { withStorageData } from 'utilities/withStorageData';
import * as paginationActions from 'actions/PaginationActions';
import * as actions from 'actions/TaskActions';

const noneStyle = { display: 'none' };
const blockStyle = { display: 'block' };
const removeContact = (array, element) =>
  array.filter((e) => !(e.id === element.id) && !element.checked);
class BulkReassign extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedData: [],
      pagination: 1,
      showOnlySelectedData: false,
      selectedAll: false,
      showDocumentSection: false,
      offSet: [1, 15],
      field: 'updated_at',
      sort: 'desc',
      maxCount: getBulkInviteMaxCount(
        getCpaPreferences().bulk_action_limit,
        'max_reassign_owners',
      ),
      loading: false,
      owners: [],
      selectedOwner: '',
      owner_error: '',
      filtered: false,
      filterBGClicked: 0,
    };
  }

  UNSAFE_componentWillMount() {
    if (!isUserEmployee() || getFirmType() === 'liscio-lite') {
      this.props.navigate('/');
    }
  }

  onClickFilterBG = () => {
    this.setState((prevState) => ({
      filterBGClicked: prevState.filterBGClicked + 1,
    }));
  };

  componentDidMount() {
    document.title = 'Reassign Task Owner';
    this.props.actions.fetchTasks(
      this,
      1,
      this.state.sort,
      this.state.field,
      this.props.api,
      false,
      null,
      'bulk',
    );

    this.props.actions.fetchAll([]);
    TaskApi.getOwners(this);
  }

  filtered = () => {
    this.setState({
      selectedData: [],
      filtered: true,
    });
    this.props.pagination.taskPagination(1);
  };

  areAllContactsSelected = () => {
    let x = 0;
    const listData = this.props.tasks.templates[0].Open;
    if (listData) {
      listData.map((e) => {
        if (e.checked) x += 1;
        return e;
      });
      const checkbox = document.getElementById('selectallcheckbox');
      if (x === listData.length) {
        this.setState({ selectedAll: true });
        if (checkbox) checkbox.indeterminate = false;
      } else {
        this.setState({ selectedAll: false });
        if (checkbox) checkbox.indeterminate = true;
      }

      if (x === 0) {
        if (checkbox) checkbox.indeterminate = '';
      }
    }
  };

  handleCheckbox = (index) => {
    const listData = this.props.tasks.templates[0].Open;
    let { selectedData } = this.state;
    const maxCount =
      this.state.maxCount === 0 ? 1000000 : parseInt(this.state.maxCount, 10);
    if (listData) {
      if (
        selectedData.length >= maxCount &&
        (listData[index].checked === undefined ||
          listData[index].checked === false)
      ) {
        this.setState({ showModal: true });
      } else {
        if (listData[index].checked) {
          listData[index].checked = false;
        } else {
          listData[index].checked = true;
        }
        if (listData[index].checked) {
          selectedData.push(listData[index]);
        } else {
          selectedData = removeContact(selectedData, listData[index]);
        }
        const uniqArray = _.uniq(selectedData);
        this.props.actions.reassignCheckbox(listData);
        this.setState({ selectedData: uniqArray }, () =>
          this.areAllContactsSelected(),
        );
        if (
          (this.state.showerrormessgaefornextbtn ||
            this.state.showOnlySelectedData) &&
          !this.isAtleastOneContactSelected()
        ) {
          this.setState({
            showOnlySelectedData: false,
            showDocumentSection: false,
          });
        } else {
          this.setState({ showerrormessgaefornextbtn: false });
        }
      }
    }
  };

  handleSelectAll = () => {
    const { selectedAll } = this.state;
    const listData = this.props.tasks.templates[0].Open;
    let { selectedData } = this.state;
    const maxCount =
      this.state.maxCount === 0 ? 1000000 : parseInt(this.state.maxCount, 10);
    let isAllChecked = 0;
    let checked = false;
    listData.map((each) => {
      if (each.checked) {
        isAllChecked += 1;
      }
      return each;
    });
    if (!selectedAll && isAllChecked === 0) {
      checked = true;
    }
    if (selectedData.length === maxCount && checked) {
      this.setState({ showModal: true });
    } else {
      let count = selectedData.length;
      listData.map((each) => {
        const eachone = each;
        count += 1;
        if (count <= maxCount && checked) {
          eachone.checked = checked;
          selectedData.push(eachone);
        } else if (!checked) {
          eachone.checked = checked;
          selectedData = removeContact(selectedData, eachone);
        }
        return eachone;
      });
      this.props.actions.reassignCheckbox(listData);
      this.setState({ selectedData }, () => this.areAllContactsSelected());
    }
  };

  handleSorting = (fieldName) => {
    const { showOnlySelectedData, selectedData } = this.state;
    let stateSort = this.state.sort;
    if (this.state.field === fieldName) {
      if (stateSort === 'asc') {
        stateSort = 'desc';
      } else {
        stateSort = 'asc';
      }
    } else {
      stateSort = 'desc';
    }
    this.setState({ sort: stateSort, field: fieldName }, () => {
      if (showOnlySelectedData) {
        let newfield = fieldName;
        if (fieldName === 'assignee') {
          newfield = 'name';
        } else if (fieldName === 'name') {
          newfield = 'account_name';
        } else if (fieldName === 'due_by') {
          newfield = 'due_date';
        }
        this.setState({
          selectedData: sortList(selectedData, newfield, stateSort),
        });
      } else {
        this.props.pagination.taskPagination(1);
        this.props.actions.fetchTasks(
          this,
          1,
          this.state.sort,
          this.state.field,
          this.props.api,
          false,
          null,
          'bulk',
        );
      }
    });
  };

  handlePageChange = (index) => {
    if (this.state.showOnlySelectedData) {
      this.updatePaginationDetails(index);
    } else {
      if (index === this.props.task_page) {
        return false;
      }
      this.props.pagination.taskPagination(index);
      this.props.actions.fetchTasks(
        this,
        index,
        this.state.sort,
        this.state.field,
        this.props.api,
        false,
        null,
        'bulk',
      );
      // scrolls to top
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;
      return true;
    }
  };

  updatePaginationDetails = (pagination) => {
    let pageNumber = pagination;
    const { selectedData } = this.state;
    const total_entries = selectedData.length;
    if (Number(Math.ceil(total_entries / 15)) < pagination) {
      pageNumber -= 1;
    }
    this.setState({
      offSet: getOffSetsAndLimit(total_entries, 15)[pageNumber - 1],
      pagination: pageNumber,
    });
  };

  handleCancelBtn = () => {
    // const { history } = this.props;
    this.props.pagination.taskPagination(1);
    // history.push('/redirect');
    this.props.navigate('/redirect');
    setTimeout(() => {
      //   history.push('/bulkreassign');
      this.props.navigate('/bulkreassign');
    }, 50);
  };

  showSelectedData = (input) => {
    if (this.state.selectedData.length) {
      this.setState(
        {
          showOnlySelectedData: input,
          showDocumentSection: input,
        },
        () => this.updatePaginationDetails(1),
      );
    } else {
      AlertMessage(
        'error',
        'Please select atleast one contact to continue',
        3000,
      );
    }
    this.setState({ owner_error: '' });
    if (!input) {
      this.setState({ offSet: [0, 15] });
      this.props.pagination.taskPagination(1);
      this.setState({ loading: true }, () =>
        this.props.actions.fetchTasks(
          this,
          1,
          this.state.sort,
          this.state.field,
          this.props.api,
          false,
          null,
          'bulk',
        ),
      );
    }
  };

  handleOwnerChange = (val) => {
    if (val) {
      if (val.value === this.state.selectedOwner) {
        return false;
      }
      this.setState({ selectedOwner: val });
    } else {
      this.setState({ selectedOwner: '' });
    }
    this.setState({ owner_error: '' });
  };

  handleDelete = (key, data) => {
    let { selectedData } = this.state;
    let listData = this.props.tasks.templates[0].Open;
    const eachOne = data;
    eachOne.checked = false;
    selectedData = removeContact(selectedData, eachOne);
    listData = updateListData(listData, selectedData);
    this.props.actions.reassignCheckbox(listData);
    this.setState(
      {
        selectedData,
        showOnlySelectedData: selectedData.length,
        showDocumentSection: selectedData.length,
      },
      () => {
        this.updatePaginationDetails(
          selectedData.length < 21 ? 1 : this.state.pagination,
        );
        if (!selectedData.length) {
          this.setState({ offSet: [0, 15] });
          this.props.pagination.taskPagination(1);
          this.props.actions.fetchTasks(
            this,
            1,
            this.state.sort,
            this.state.field,
            this.props.api,
            false,
            null,
            'bulk',
          );
        }
      },
    );
  };

  reassignOwner = () => {
    const { selectedData, selectedOwner } = this.state;
    if (!selectedOwner) {
      this.setState({ owner_error: 'Please select owner to proceed' });
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;
      return false;
    }
    const ids = [];
    selectedData.map((each) => {
      const key = each.id;
      ids.push(key);
      return each;
    });
    const data = {
      tasks_ids: ids,
      new_owner_id: this.state.selectedOwner.value,
    };
    this.setState({ loading: true });
    const request = Axios.post(BULK_REASSIGN_OWNER, data);
    this.setState({ loading: false });
    request.then((res) => {
      if (res.status === 200 && res.data.status === 200) {
        AlertMessage('success', res.data.message, 2000);
        this.handleCancelBtn();
      } else {
        AlertMessage('error', res.data.message, 2000);
      }
      sendMixpanelEvent('BulkReassignment', {
        message: res.data && res.data.message ? res.data.message : null,
      });
    });
  };

  render() {
    const {
      showDocumentSection,
      showOnlySelectedData,

      selectedAll,
      selectedData,
      loading,
      pagination,

      offSet,

      showModal,
      filtered,
    } = this.state;
    // TODO: __HOMEDATA-AUDIT
    const data = getStorageData();
    let prefTypes = [];
    if (data && data.pref_type_options) {
      prefTypes = data.pref_type_options.task_type_color;
    }
    let totalpages = 1;
    let totalrecords = 0;
    let offset = 0;
    let off = 0;
    let listData = this.props.tasks;
    totalpages = showOnlySelectedData
      ? Number(Math.ceil(selectedData.length / 15))
      : this.props.tasks.Open_total !== 0
      ? Math.ceil(this.props.tasks.Open_total / 15)
      : 0;
    totalrecords = showOnlySelectedData
      ? selectedData.length
      : this.props.tasks.Open_total;
    if (this.props.tasks.res_data) {
      offset = this.props.tasks.res_data.open_offset;
      off = this.props.tasks.res_data.open_off;
    }
    listData =
      listData && listData.templates ? this.props.tasks.templates[0].Open : [];
    return (
      <div className="BulkReassign" data-testid="BulkReassign">
        <header>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">Bulk Action</li>
            <li className="breadcrumb-item">Reassign Task Owner</li>
          </ul>
        </header>
        <div id="loading" style={loading ? blockStyle : noneStyle} />
        <div className="center-wrapper" style={{ overflow: 'inherit' }}>
          <section onClick={this.onClickFilterBG} role="presentation">
            <div style={{ display: !showOnlySelectedData ? 'block' : 'none' }}>
              <FilterTab
                sort={this.state.sort}
                field={this.state.field}
                component={this}
                filtered={this.filtered}
                bulkState={this.state}
                name="bulk"
                filterBGClicked={this.state.filterBGClicked}
              />
            </div>
            {showOnlySelectedData ? (
              <div className="row">
                <SelectFieldWithAvatar
                  name="taskOwner"
                  label="Reassign task to new owner:"
                  labelClass="text-body fontSize"
                  className="reassignhead col form-group form-inline-label"
                  options={this.state.owners}
                  handleChange={this.handleOwnerChange}
                  value={this.state.selectedOwner}
                  error={this.state.owner_error}
                  clearable={false}
                />
                <h5 className="col-12 text-small">
                  Following tasks will be reassigned to the new owner
                </h5>
              </div>
            ) : null}
            {listData === undefined ||
            listData === null ||
            (!loading && filtered && listData.length === 0) ? (
              <NoRecords />
            ) : null}
            {listData.length && listData !== null ? (
              <List
                tasks={
                  showOnlySelectedData
                    ? [...selectedData.slice(offSet[0] - 1, offSet[1])]
                    : listData
                }
                handleSorting={this.handleSorting}
                handleSelectAll={this.handleSelectAll}
                selectedAll={selectedAll}
                data={this.state}
                pref={prefTypes}
                totalRecords={totalrecords}
                handleCheckbox={this.handleCheckbox}
                handleDelete={this.handleDelete}
                showCheckbox={!showOnlySelectedData}
                name="bulk"
                navigate={this.props.navigate}
              />
            ) : null}
            {listData.length && listData !== null ? (
              <Pagination
                currentPage={
                  showOnlySelectedData
                    ? pagination
                    : listData.length >= 1
                    ? this.props.task_page
                    : 0
                }
                totalPages={totalpages}
                totalRecords={totalrecords}
                offset={
                  showOnlySelectedData
                    ? offSet[0]
                    : totalrecords === 0
                    ? offset - 1
                    : offset
                }
                off={showOnlySelectedData ? offSet[1] : off}
                handlePageChange={this.handlePageChange}
              />
            ) : null}
            {listData.length &&
            listData !== null &&
            !showOnlySelectedData &&
            !showDocumentSection ? (
              <div className="formbtn BulkReassignButtonGroup">
                <div className="btn-wrap btn--leftspace">
                  <button
                    type="button"
                    className="btn btn-outline-light"
                    onClick={this.handleCancelBtn}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => this.showSelectedData(true)}
                  >
                    Next Step
                  </button>
                </div>
              </div>
            ) : null}
            {showOnlySelectedData ? (
              <div className="formbtn BulkReassignButtonGroup">
                <div className="btn-wrap btn--leftspace">
                  <button
                    type="button"
                    className="btn btn-outline-light"
                    onClick={() => this.showSelectedData(false)}
                  >
                    Back
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={this.reassignOwner}
                  >
                    Reassign Task Owner
                  </button>
                </div>
              </div>
            ) : null}
          </section>
        </div>
        {showModal ? (
          <ConfirmationModal
            isOpen={showModal}
            messageText={`You can select up to ${this.state.maxCount} tasks`}
            noBtnClick={() => {
              this.setState({ showModal: false });
            }}
            noBtnText="Close"
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  tasks: state.taskReducer.tasks,
  api: state.taskReducer.apiUrl,
  task_page: state.paginationReducer.task_page,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
  pagination: bindActionCreators(paginationActions, dispatch),
});

export default withStorageData(
  connect(mapStateToProps, mapDispatchToProps)(BulkReassign),
);
