/* eslint-disable */
/* react/no-array-index-key */
import React, { Component } from 'react';
import Axios from 'axios';
import moment from 'moment-timezone';
import FileSaver from 'file-saver';
import { Modal, Button, Label } from 'reactstrap';
import ReactTooltip from 'react-tooltip';
import 'components/UploadedFiles/design.scss';
import { Link } from 'react-router-dom-latest';
import { GET_AGREEMENT_PREVIEW, GET_AUDIT_TRAIL, GET_AGREEMENT_FROM_ADOBE } from 'constants/constants';
import { getCpaPreferences } from 'utilities/utils';
import ReadOnlyTinyMCE from 'common/ReadOnlyTinyMCE/ReadOnlyTinyMCE';

const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone || moment.tz.guess(true);
const isIE = /* @cc_on!@ */ false || !!document.documentMode;
const noneStyle = { display: 'none' };
const blockStyle = { display: 'block' };

class PreviewEsign extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      esignData: {},
      error: '',
      cancelAgreementModal: false,
      preferences: {},
    };
  }
  UNSAFE_componentWillMount() {
    const preferences = getCpaPreferences();
    this.setState({ preferences });
  }
  componentDidMount() {
    const { data } = this.props;
    if (data.listDetail && Object.keys(data.listDetail).length) {
      this.setState({ loading: true });
      Axios.get(GET_AGREEMENT_PREVIEW + '/' + data.listDetail.id)
        .then((response) => {
          this.setState({
            loading: false,
            esignData: response.data.data[0],
            previewUrl: response.data.preview_url,
            error: response.data.error,
          });
        })
        .catch((error) => {
          this.setState({ loading: false });
          console.log(error);
        });
    }
  }
  componentWillReceiveProps(nextProps) {
    if (Object.keys(nextProps.data.listDetail) && nextProps.data.listDetail !== this.state.esignData) {
      this.setState({
        esignData: nextProps.data.listDetail,
      });
    }
  }
  navigateLink = (task) => {
    this.props.navigate({
      pathname: '/task/detail/' + task,
      state: { from: 'edocpreview' },
    });
  };
  downloadAuditTrail = () => {
    const { agreement_id, doc_name, agreement_name } = this.state.esignData;
    let file_name = doc_name.substring(0, doc_name.lastIndexOf('.'));
    const ext = doc_name.split('.').pop();
    if (file_name.includes('_signed')) {
      file_name = file_name.replace(/_signed/, '');
    }
    file_name = file_name ? file_name + '_audit_trail.' + ext : agreement_name + '_audit_trail.pdf';
    Axios(GET_AUDIT_TRAIL + '/' + agreement_id, {
      method: 'GET',
      responseType: 'blob',
    })
      .then((response) => {
        const pdf = response.data;
        const file = new Blob([pdf], { type: 'application/pdf' });
        FileSaver.saveAs(file, file_name);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  downloadEdoc = (id) => {
    Axios.get(GET_AGREEMENT_FROM_ADOBE + '/' + id)
      .then((response) => {
        const url = response.data.url;
        window.location.href = url;
      })
      .catch((error) => {
        console.log(error);
      });
  };
  cancelAgreement = () => {
    const { esignData } = this.state;
    this.props.cancelAgreement(esignData, 'cancelfrompreview');
  };
  render() {
    const { esignData, previewUrl, loading, error, cancelAgreementModal, preferences } = this.state;
    let status = esignData.status;
    if (preferences && preferences.edoc_request_status) {
      const signatoryStatus = preferences.edoc_request_status;
      signatoryStatus.map((ele) => {
        if (esignData.status === ele.key) {
          status = ele.label;
        }
      });
    }
    const dateAdded = moment.tz(esignData.created_at, timeZone).format('MM/DD/YYYY');
    let signersLength = '';
    let signersList = [];
    if (esignData.signers && esignData.signers.length) {
      signersLength = esignData.signers.length;
      signersList = esignData.signers.map((each, index) => {
        return (
          <div className="d-flex pb-2" key={esignData.signers.indexOf(each)}>
            <span className="avatar avatar--w20 align-self-center">
              {each.avatar ? <img src={each.avatar} alt="" /> : <span className="uName">{each.initials}</span>}
            </span>
            <span>
              <span className="label-large">
                {each.name}
                {each.account_name && each.account_name !== null && each.account_name !== undefined && (
                  <span>
                    <span className="divOption d-inline-block" style={{ backgroundColor: '#212b36' }} />
                    {each.account_name}
                  </span>
                )}
              </span>
              <br />
              <span className="text-uppercase label-small">
                <span className="text-dark">{each.status}</span>
                {each.task_id && each.task_id !== null && each.task_id !== undefined && (
                  <span>
                    <span className="divOption d-inline-block" style={{ backgroundColor: '#919eab' }} />
                    <Link to={`/task/detail/${each.task_id}`}>Task</Link>
                  </span>
                )}
              </span>
            </span>
          </div>
        );
      });
    }
    if (loading) {
      return <div id="loading" style={loading ? blockStyle : noneStyle} />;
    }
    return (
      <Modal className="Md-full-width modal-FullH" isOpen>
        <div className="eng-letter">
          <header>
            <div className="headerWrapper row">
              <div className="left-side col-md-8 col-12">
                <Button className="btn-link btn--onlyicon btn btn-secondary">
                  <i className="icon-back-arrow" aria-hidden="true" onClick={this.props.closeModal} />
                </Button>
                <div className="head-inner d-block">
                  <h2>{esignData.agreement_name}</h2>
                  <h5>Date on {moment.tz(esignData.created_at, timeZone).format('MMM Do YYYY')}</h5>
                </div>
              </div>
              <div className="right-side col-md-4 col-12">
                <div className="btn-wrap btn--leftspace">
                  {esignData.status === 'in_progress' && (
                    <Button
                      className="btn-link btn--onlyicon btn btn-secondary"
                      onClick={() => this.cancelAgreement()}
                      data-tip
                      data-for="cancel_agreement"
                    >
                      <i className="icon-cancel-agreement" aria-hidden="true" />
                      <ReactTooltip id="cancel_agreement" effect="solid">
                        <span className="contactcard-team-member">Cancel Agreement</span>
                      </ReactTooltip>
                    </Button>
                  )}
                  <Button
                    className="btn-link btn--onlyicon btn btn-secondary"
                    onClick={this.downloadAuditTrail}
                    data-tip
                    data-for="audit_report"
                  >
                    <i className="icon-Audit-report" aria-hidden="true" />
                    <ReactTooltip id="audit_report" effect="solid">
                      <span className="contactcard-team-member">Download Audit Report</span>
                    </ReactTooltip>
                  </Button>
                  <Button
                    className="btn-link btn--onlyicon btn btn-secondary"
                    onClick={() => this.downloadEdoc(esignData.agreement_id)}
                    data-tip
                    data-for="download_icon"
                  >
                    <i className="icon-download2" aria-hidden="true" />
                    <ReactTooltip id="download_icon" effect="solid">
                      <span className="contactcard-team-member">Download</span>
                    </ReactTooltip>
                  </Button>
                  <Button
                    className="btn-link btn--onlyicon btn btn-secondary line-right"
                    data-tip
                    data-for="close_icon"
                  >
                    <i className="icon-close2" aria-hidden="true" onClick={this.props.closeModal} />
                    <ReactTooltip id="close_icon" effect="solid">
                      <span className="contactcard-team-member">Close</span>
                    </ReactTooltip>
                  </Button>
                </div>
              </div>
            </div>
          </header>
          <div className="main-sec">
            <div className="left-side-inner-sec">
              <div className="left-inner-content">
                {error ? (
                  <div className="text-center">
                    <i className="icon-File_01 text-icon-large text-light" />
                    <h6 className="pt-1 text-small text-dark">Some Error Occurred</h6>
                  </div>
                ) : (
                  <img src={previewUrl} alt="previewesign" style={{ maxWidth: '100%' }} />
                )}
              </div>
            </div>
            <div className="right-sidebar-inner">
              <form>
                <div className="form-group form-inline-label">
                  <Label for="forFileName" className="align-self-start">
                    STATUS:
                  </Label>
                  <div className="labelValue">
                    <span>{status}</span>
                  </div>
                </div>
                <div className="form-group form-inline-label">
                  <Label for="forFileName" className="align-self-start">
                    AGREEMENT NAME:
                  </Label>
                  <div className="labelValue">
                    <span>{esignData.agreement_name}</span>
                  </div>
                </div>
                <div className="form-group form-inline-label">
                  <Label for="forContact">REQUESTOR:</Label>
                  <div className="labelValue">
                    <div className="avatar avatar--w20">
                      {esignData.requester ? (
                        esignData.requester.avatar ? (
                          <div>
                            <img src={esignData.requester.avatar} alt="" />
                            <span className="userName">{esignData.requester.name || '-'}</span>
                          </div>
                        ) : esignData.requester.initials ? (
                          <div>
                            <span className="uName">{esignData.requester.initials}</span>
                            <span className="userName">{esignData.requester.name || '-'}</span>
                          </div>
                        ) : (
                          '-'
                        )
                      ) : (
                        '-'
                      )}
                    </div>
                  </div>
                </div>
                <div className="form-group form-inline-label">
                  <Label for="forContact" className="align-self-start pt-2">
                    SIGNER(<span>{signersLength}</span>):
                  </Label>
                  <div className="labelValue">{signersList}</div>
                </div>
                <div className="form-group form-inline-label">
                  <Label for="forContact">Source:</Label>
                  <div className="labelValue">
                    <div className="avatar">
                      <span className="userName">eDoc</span>
                    </div>
                  </div>
                </div>
                <div className="form-group form-inline-label">
                  <Label for="forContact">Date Added:</Label>
                  <div className="labelValue">
                    <div className="avatar">
                      <span className="userName">{dateAdded}</span>
                    </div>
                  </div>
                </div>
                <div className="form-group form-inline-label border-top pt-2">
                  <Label for="forContact" className="align-self-start">
                    Description:
                  </Label>
                  <div className="labelValue">
                    <ReadOnlyTinyMCE content={esignData.description} />
                    {/* <span dangerouslySetInnerHTML={{ __html: esignData.description }} /> */}
                  </div>
                </div>
                {esignData.cancellation_reason !== null &&
                esignData.cancellation_reason === 'AGREEMENT_AUTO_CANCELLED_CONVERSION_PROBLEM' ? (
                  <div>
                    <p className="text-danger">
                      Auto cancelled the agreement. The document used for creating this eDoc is not properly formatted.
                    </p>
                  </div>
                ) : null}
              </form>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default PreviewEsign;
