import React from 'react';
import CurrencyInput from 'react-currency-input-field';

const AmountField = ({
  currency,
  isQBMode,
  amount,
  handleAmountChange,
  invalidAmount,
  childInvoicesCount,
  isPaymentAuthorized,
}) => (
  <div className="InvoiceForm__Field">
    <CurrencyInput
      id="amount"
      data-testid="amount"
      name="amount"
      decimalsLimit={2}
      allowNegativeValue={false}
      intlConfig={{ locale: 'en-US', currency }}
      maxLength={8}
      className={
        isQBMode || childInvoicesCount > 0
          ? 'AmountInput__Disabled InvoiceForm__Input'
          : 'InvoiceForm__Input'
      }
      value={amount}
      onValueChange={handleAmountChange}
      disabled={isQBMode || childInvoicesCount > 0 || isPaymentAuthorized}
    />
    {invalidAmount && (
      <div className="InvoiceForm__Error">
        This is a required field with amount greater than 0
      </div>
    )}
  </div>
);

export default AmountField;
