/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// TODO: Keep the comments for future use
import React, { Fragment } from 'react';
import { STAR_SUB_MENU } from 'constants/constants';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';
import { MoreHoriz } from '@mui/icons-material';
import { NavigateFunction } from 'react-router-dom-latest';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { handleLogout, switchBack } from './sidebar-utils';
import MultipleLevelMenu from './MultipleLevelMenu';

export default function StarMenuList({
  starMenu,
  userSwitched,
  isFirmAdmin,
  getSelectedMenuItem,
  setActiveSidebarMenuItem,
  navigate,
}: {
  navigate: NavigateFunction;
  setActiveSidebarMenuItem: (selected: string) => void;
  starMenu: MenuItem;
  userSwitched: null | string | boolean;
  isFirmAdmin: boolean;
  getSelectedMenuItem: TGetSelectedMenuItem;
}) {
  const { v5Login } = useFlags();

  // we will show admin menu items only if user is a firm admin
  const items = starMenu.children.filter((e) => {
    if (!userSwitched && e.url === '/logout') {
      return true;
    }

    if (isFirmAdmin && e.label === 'Admin') {
      return true;
    }

    if (userSwitched && e.label === 'Switch Back') {
      return true;
    }

    return false;
  });

  const itemsWithDefaultLogout =
    items.length === 0
      ? [
          {
            label: 'Log Out',
            id: 35,
            panel: 'left_bottom',
            sort_order: 2,
            url: '/logout',
            icon_class: 'icon-logout',
            icon: '',
            is_restricted: false,
            onselect_message: null,
            sub_child: [],
          },
        ]
      : items;

  function handleStarMenuClick(event: React.MouseEvent, item: MenuItem) {
    const element = event.currentTarget as DatasetElement;
    const { selected } = element.dataset;
    setActiveSidebarMenuItem(selected);

    if (item.url === '/logout') {
      handleLogout('', v5Login);
    } else if (item.url === '/switch') {
      switchBack(v5Login);
    } else if (item.url.includes('https://')) {
      window.open(item.url, '_blank');
    } else {
      navigate(item.url);
    }
  }

  return (
    <Fragment key={starMenu.url}>
      <MultipleLevelMenu
        menuItems={{ ...starMenu, children: itemsWithDefaultLogout }}
        onSelect={handleStarMenuClick}
        subMenuType={STAR_SUB_MENU}
        component={
          <ListItemButton
            className="StarMenuList"
            selected={getSelectedMenuItem({
              ...starMenu,
              children: items,
            })}
            data-testid="sidebar__more_horiz_icon"
          >
            <ListItemIcon>
              <MoreHoriz />
            </ListItemIcon>
          </ListItemButton>
        }
      />
    </Fragment>
  );
}
