const makeFindMatchingAccount = (accountId) => 
  (contact) => contact?.accounts?.find(({ value }) => Number(value) === Number(accountId))

export function filterOptions(groupedOptions, { 
  accountId, 
  allAccounts, 
  selectedContact,
}) {
  const filteredGroupedOptions = accountId 
    ? groupedOptions
      .map(({ label, options }) => ({
        label,
        options: (label === 'Contacts')
          ? options.filter(makeFindMatchingAccount(accountId))
          : options,
      }))
    : groupedOptions


  const filteredAccountOptions = selectedContact?.type === 'contact' 
    ? selectedContact.accounts || []
    : allAccounts;

  return { filteredGroupedOptions, filteredAccountOptions };
}