import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom-latest';
import {
  CONTACT_CLIENT,
  YOUR_PAYMENT_HAS_FAILED,
  INVOICE_RETRIEVAL_AND_CREATION,
  PAYMENT_FOR_THIS_ACCOUNT_HAS_FAILED,
  THIS_ACCOUNT_IS_OVERDUE,
  YOUR_ACCOUNT_IS_OVERDUE,
  AUTHORIZE_PAYMENT_FOR_NEW_RECURRING_INVOICES,
} from 'constants/constants';
import AlertBox from 'common/AlertBox';
import { isClient, truncateText } from 'utilities/utils';
import { pickInvoiceAlert } from './AlertMessages';
import 'scss/custom.scss';

function pickURL({ text, subText, accountId }) {
  let url = INVOICE_RETRIEVAL_AND_CREATION;
  switch (text) {
    case PAYMENT_FOR_THIS_ACCOUNT_HAS_FAILED:
    case YOUR_PAYMENT_HAS_FAILED:
      url += `?status=failed&account_id=${accountId}`;
      break;
    case THIS_ACCOUNT_IS_OVERDUE:
    case YOUR_ACCOUNT_IS_OVERDUE:
      url += `?status=overdue&account_id=${accountId}`;
      // pageRoute = '/billing/invoices/';
      break;
    default:
      break;
  }
  switch (subText) {
    case CONTACT_CLIENT:
      url += `&account_id=${accountId}`;
      break;
    case AUTHORIZE_PAYMENT_FOR_NEW_RECURRING_INVOICES:
      url += `?status=pending&is_parent_task=true&account_id=${accountId}`;
      break;
    default:
      break;
  }
  return url;
}
const InvoiceDesc = ({ invoiceNumber, description }) => {
  const desc = truncateText(description, 30);
  const inv = truncateText(invoiceNumber || '', 15);
  return invoiceNumber ? (
    <>
      Inv# <strong>{inv} </strong>
      {desc}
    </>
  ) : (
    desc
  );
};

const DropdownList = (props) => {
  const { options, pageLink, hideDropDown, displayList } = props;
  if (!displayList) {
    return null;
  }
  if (Array.isArray(options)) {
    return (
      <div className="alert-drop-down-bg" onClick={hideDropDown}>
        <div className="alert-drop-down">
          {options.map((option) => {
            const { invoice_number, description, id } = option;
            return (
              <Link to={`${pageLink}${id}`} key={id} className="alert-option">
                <InvoiceDesc
                  invoiceNumber={invoice_number}
                  description={description}
                />
              </Link>
            );
          })}
        </div>
      </div>
    );
  }
  return null;
};

const InvoiceAlertBox = (props) => {
  if (!props || !props.alertMessage) {
    return null;
  }

  const { accountId, alertMessage, isAccountOwner } = props;

  const { text, subText, type, pageLink } =
    pickInvoiceAlert(alertMessage, isAccountOwner) || {};
  if (!text && !subText) {
    return null;
  }
  const url = pickURL({ text, subText, accountId });
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [dropDownListOptions, setDropDownListOptions] = useState([]);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [displayList, SetDisplayList] = useState(false);
  const hideDropDown = (e) => {
    e.stopPropagation();
    if (displayList) {
      SetDisplayList(false);
    }
  };
  const handleClick = () => {
    if (!dropDownListOptions.length) {
      SetDisplayList(false);
      return;
    }
    SetDisplayList(!displayList);
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await Axios.get(url);
        setDropDownListOptions(data.data);
      } catch (error) {
        console.log(error);
      }
    };
    if (url) {
      fetchData();
    }
  }, [url]);

  const alertProps = { type };

  const isUserClient = isClient();

  if (pageLink) {
    if (isUserClient) {
      alertProps.text = text;
      alertProps.subText = subText;
      alertProps.onClickSubText = handleClick;
    } else {
      alertProps.onClickAll = handleClick;
    }
  }

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <AlertBox {...alertProps}>
      {!isUserClient && (
        <div className="custom-firm-alert">
          <strong>{text}</strong>
          {subText}
        </div>
      )}
      <DropdownList
        options={dropDownListOptions}
        pageLink={pageLink}
        hideDropDown={hideDropDown}
        displayList={displayList}
      />
    </AlertBox>
  );
};

export default InvoiceAlertBox;
