import React from 'react';
import ContentLoader from 'react-content-loader';

const MessageContentLoader = () => (
  <ContentLoader
    height={380}
    width={1600}
    speed={4}
    backgroundColor="#F3F3F3"
    foregroundColor="#E3E4E6"
    className="msgLoader"
  >
    <circle cx="30" cy="50" r="30" />
    <rect x="130" y="35" rx="2" ry="2" width="170" height="10" />
    <rect x="130" y="60" rx="2" ry="2" width="140" height="10" />
    <rect x="450" y="35" rx="2" ry="2" width="350" height="10" />
    <rect x="450" y="60" rx="2" ry="2" width="350" height="10" />
    <rect x="950" y="35" rx="2" ry="2" width="300" height="10" />
    <rect x="950" y="60" rx="2" ry="2" width="300" height="10" />
    <rect x="1400" y="35" rx="2" ry="2" width="200" height="10" />
    <rect x="1400" y="60" rx="2" ry="2" width="200" height="10" />
    <circle cx="30" cy="150" r="30" />
    <rect x="130" y="135" rx="2" ry="2" width="170" height="10" />
    <rect x="130" y="160" rx="2" ry="2" width="140" height="10" />
    <rect x="450" y="135" rx="2" ry="2" width="350" height="10" />
    <rect x="450" y="160" rx="2" ry="2" width="350" height="10" />
    <rect x="950" y="135" rx="2" ry="2" width="300" height="10" />
    <rect x="950" y="160" rx="2" ry="3" width="300" height="10" />
    <rect x="1400" y="135" rx="2" ry="3" width="200" height="10" />
    <rect x="1400" y="160" rx="2" ry="3" width="200" height="10" />
    <circle cx="30" cy="250" r="30" />
    <rect x="130" y="235" rx="2" ry="2" width="170" height="10" />
    <rect x="130" y="260" rx="2" ry="2" width="140" height="10" />
    <rect x="450" y="235" rx="2" ry="2" width="350" height="10" />
    <rect x="450" y="260" rx="2" ry="2" width="350" height="10" />
    <rect x="950" y="235" rx="2" ry="2" width="300" height="10" />
    <rect x="950" y="260" rx="2" ry="2" width="300" height="10" />
    <rect x="1400" y="235" rx="2" ry="2" width="200" height="10" />
    <rect x="1400" y="260" rx="2" ry="2" width="200" height="10" />
    <circle cx="30" cy="350" r="30" />
    <rect x="130" y="335" rx="2" ry="2" width="170" height="10" />
    <rect x="130" y="360" rx="2" ry="2" width="140" height="10" />
    <rect x="450" y="335" rx="2" ry="2" width="350" height="10" />
    <rect x="450" y="360" rx="2" ry="2" width="350" height="10" />
    <rect x="950" y="335" rx="2" ry="2" width="300" height="10" />
    <rect x="950" y="360" rx="2" ry="2" width="300" height="10" />
    <rect x="1400" y="335" rx="2" ry="2" width="200" height="10" />
    <rect x="1400" y="360" rx="2" ry="2" width="200" height="10" />
  </ContentLoader>
);

export default MessageContentLoader;
