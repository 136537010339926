import React from 'react';
import InputField from 'common/InputField';
import AccountDateField from 'common/AccountDateField';
import { SelectField } from 'common/CustomFields';
import { EncryptedField } from 'common/EncryptedField/EncryptedField';
import { enableDependantDeleteButton } from './AccountUtilities';
import { today } from './TempDateHelpers';

const DependentsDetails = (props) => {
  const { dependentsData, errors, preferences, disabled } = props.data;
  const { dependentsDataErrors } = errors;

  return dependentsData.map((each, key) => {
    const name = `Dependents${key}`;
    const depedentError =
      dependentsDataErrors && dependentsDataErrors[key]
        ? dependentsDataErrors[key]
        : {};
    return (
      <div key={name}>
        <div className="row no-gutters align-items-center mt-3 mb-3">
          <h5 className="mb-0">{`${key + 1}. Dependent Details`}</h5>
          {enableDependantDeleteButton(each) ? (
            <button
              type="button"
              className="btn-link btnOnlyLink ml-3"
              onClick={() => props.deleteField(key)}
              disabled={disabled}
            >
              <i className="icon-delete-small" />
            </button>
          ) : null}
        </div>
        <div className="row">
          <InputField
            name={`${name}first_name`}
            label="First Name"
            className="col-sm-12 col-lg-4 col-md-4 form-group"
            onChange={(input) => props.onChange(input, 'first_name', key)}
            value={each.first_name}
            error={depedentError.first_name}
            onBlur={props.onBlur}
          />
          <InputField
            name={`${name}middle_name`}
            label="middle Name"
            className="col-sm-12 col-lg-4 col-md-4 form-group"
            onChange={(input) => props.onChange(input, 'middle_name', key)}
            value={each.middle_name}
            error={depedentError.middle_name}
            onBlur={props.onBlur}
          />
          <InputField
            name={`${name}lasy_name`}
            label="last Name"
            className="col-sm-12 col-lg-4 col-md-4 form-group"
            onChange={(input) => props.onChange(input, 'last_name', key)}
            value={each.last_name}
            error={depedentError.last_name}
            onBlur={props.onBlur}
          />
        </div>
        <div className="row">
          <EncryptedField
            recordId={each.id}
            label="Taxpayer ID"
            recordType="Dependant"
            fieldName="ssn"
            formFieldName={`encryptedSsn-${key}`}
            className="col-sm-12 col-lg-4 col-md-4 form-group"
            value={each.encryptedSsn}
            error={depedentError.encryptedSsn}
            onChange={props.handleEncryptedFieldChange}
            hasEncryptedValue={each?.encrypted_ssn?.populated}
          />
          <AccountDateField
            label="Birthdate"
            name={`${name}birthdate`}
            onChange={(input) => props.handleDateField(input, 'birthdate', key)}
            value={each.birthdate}
            error={depedentError.birthdate}
            className="col-sm-12 col-lg-4 col-md-4 form-group"
            onBlur={props.onBlur}
            maxDate={today}
          />
          <SelectField
            id={`${name}dependant_relationship_type`}
            name={`${name}dependant_relationship_type`}
            options={preferences.dependent_relationship}
            handleChange={(input) =>
              props.handleSelect('dependant_relationship_type', input, key)
            }
            value={{
              value: each.dependant_relationship_type,
              label: each.dependant_relationship_type,
            }}
            className="col-sm-12 col-lg-4 col-md-4 form-group"
            error={depedentError.dependant_relationship_type}
            label="Relationship"
            onBlur={props.onBlur}
            clearable
          />
          {dependentsData.length - 1 === key ? (
            <div className="pt-2 pl-2">
              <button
                type="button"
                className="no-background"
                onClick={() => props.addNewField('dependents')}
                disabled={!enableDependantDeleteButton(each)}
              >
                + Add another dependent
              </button>
            </div>
          ) : null}
        </div>
      </div>
    );
  });
};

export default DependentsDetails;
