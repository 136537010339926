/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-class-assign */
/* eslint-disable object-shorthand */
import React, { Component } from 'react';
import axios from 'axios';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { NAME, PASSWORD } from 'utilities/regularexpressions';
import { PhoneNumberField } from 'common/PhoneNumberField';
import * as Config from 'constants/constants';
import * as InviteApi from 'api/InviteApi';
import { AlertMessage, getSiteHostname } from 'utilities/utils';
import liscioLogo from 'images/liscioLogo.png';
import downloadGoogleApp from 'images/download_google_app.png';
import { withRouter } from 'utilities/withRouterWrapper';

const noneStyle = { display: 'none' };
const blockStyle = { display: 'block' };

class Invite extends Component {
  constructor() {
    super();
    this.state = {
      activeStep: 'step1',
      inviteDetails: {},
      loading: false,
      heading: '',
      subHeading: 'Create a Password',
      step: 'Step 1 of 2',
      email: '',
      token: '',
      companyName: '',
      formerrors: {},
      phone: '',
      phoneId: '',
      is_texting_allowed: false,
      firstName: '',
      middleName: '',
      lastName: '',
      password: '',
      confirmPassword: '',
      showMobileModal: false,
      showAndroid: false,
    };
  }

  UNSAFE_componentWillMount() {
    // TODO: Keep this code for future reference
    // const { match } = this.props;
    // const component = this;
    // const params = match.params[0];
    // const url = params.split('/');
    // const urlToken = url[url.length - 1];
    const { params } = this.props;
    const component = this;
    const urlToken = params['*'];
    const company = getSiteHostname();
    if (urlToken !== '' && urlToken !== 'invite') {
      const email = { token: urlToken };
      const request = axios.post(Config.GET_INVITE_EMAIL, email);
      request.then((res) => {
        if (res.data.status === 200) {
          component.setState({
            email: res.data.data,
            firstName: res.data.first_name,
            lastName: res.data.last_name,
          });
          const data = {
            email: res.data.data,
            company_name: company,
          };
          InviteApi.getInviteData(this, data);
        } else {
          AlertMessage(
            'error',
            'Link expired. Please ask your accountant to re-invite you to Liscio.',
            800000000,
          );
        }
      });
      component.setState({ token: urlToken, companyName: company });
    }
  }

  handleChange(e) {
    let { value } = e.target;
    let name = '';
    if (e.target.name === 'phone') {
      name = e.target.name;
      value = e.target.value;
    } else if (e.target.name === 'is_texting_allowed') {
      value = !(e.target.value === 'true');
    }
    this.setState({ [e.target.name]: value, formerrors: {} }, () => {
      if (name && name === 'phone' && this.state.phone) {
        this.setState({ is_texting_allowed: true });
      } else if (name && name === 'phone' && !this.state.phone) {
        this.setState({ is_texting_allowed: false });
      }
    });
  }

  handleBlur = () => {
    const {
      password,
      confirmPassword,
      formerrors,
      email,
      firstName,
      lastName,
    } = this.state;
    const err = formerrors;
    if (!password && !confirmPassword) {
      err.password = 'Required';
      err.confirmPassword = 'Required';
    }
    if (!password) {
      err.password = 'Required';
    }
    if (password && !PASSWORD.test(password)) {
      err.password =
        'Password must contain at least 12 characters, including 1 upper case letter, 1 lower case letter, 1 numeric and 1 special character.';
    } else if (password) {
      const array = [firstName, lastName];
      let usernameExists = false;
      array.map((item) => {
        const passwordLowerCase = password.toLowerCase();
        if (passwordLowerCase.indexOf(item.toLowerCase()) > -1) {
          usernameExists = true;
        }
        return usernameExists;
      });
      if (password.toLowerCase() === email.toLowerCase()) {
        err.password = 'Password should not contain primary email id';
      } else if (usernameExists === true) {
        err.password = 'Password should not contain first name/ last name';
      }
    } else if (!confirmPassword) {
      err.confirmPassword = 'Required';
    }
    if (password && confirmPassword && password !== confirmPassword) {
      err.confirmPassword = 'Passwords do not match.';
    }
    this.setState({ formerrors: err });
    return err;
  };

  changePassword() {
    const { password, confirmPassword, token, companyName } = this.state;
    const errors = this.handleBlur();
    if (Object.keys(errors).length) {
      return false;
    }
    const data = {
      token: token,
      password: password,
      password_confirmation: confirmPassword,
      company_name: companyName,
    };
    InviteApi.changePassword(this, data);
  }

  submitContactDetail() {
    const {
      firstName,
      middleName,
      lastName,
      phone,
      phoneId,
      is_texting_allowed,
      formerrors,
    } = this.state;
    const err = formerrors;
    if (!firstName && !lastName) {
      err.firstName = 'Required';
      err.lastName = 'Required';
    }
    if (!firstName) {
      err.firstName = 'Required';
    }
    if (!lastName) {
      err.lastName = 'Required';
    }
    if (firstName && !NAME.test(firstName)) {
      err.firstName = 'Enter valid name';
    }
    if (lastName && !NAME.test(lastName)) {
      err.lastName = 'Enter valid name';
    }
    if (middleName && !NAME.test(middleName)) {
      err.middleName = 'Enter valid name';
    }
    if (phone && !isValidPhoneNumber(phone)) {
      err.phone = 'Invalid phone number';
    }
    if (is_texting_allowed && !phone) {
      err.phone = 'Enter phone number to receive text messages';
    }
    this.setState({ formerrors: err }, () => {
      if (Object.keys(formerrors).length) {
        return false;
      }
      const data = {
        contact: {
          first_name: firstName,
          middle_name: middleName,
          last_name: lastName,
        },
        contact_phone: [
          {
            type: 'Mobile',
            id: phoneId,
            value: phone,
            is_texting_allowed,
          },
        ],
      };
      InviteApi.submitContactDetail(this, data);
    });
  }

  handleEyeIcon(type) {
    const inputType = this.state[type];
    this.setState({ [type]: inputType === 'text' ? 'password' : 'text' });
  }

  render() {
    import('../Login/login.scss');
    const {
      activeStep,
      email,
      firstName,
      middleName,
      lastName,
      phone,
      formerrors,
      inviteDetails,
      heading,
      subHeading,
      step,
    } = this.state;
    return (
      <div id="page-wrapper">
        <div className="crPage">
          <div className="crPage--inner">
            <div className="crPage--innerForm w-550">
              <div className="crPage--innerForm--sec">
                {/* <Alert html /> */}
                <div className="crPage--head text-center">
                  <img src={liscioLogo} className="homeLogo" alt="Lisico" />
                </div>
                <div
                  id="loading"
                  style={this.state.loading ? blockStyle : noneStyle}
                />
                <div className="login-bx">
                  <form autoComplete="off">
                    <div className="text-center">
                      {subHeading ? (
                        <h3 className="text-medium mb-1">{subHeading}</h3>
                      ) : null}
                      {activeStep === 'step1' ? (
                        <h4 className="text-body mb-1 text-small">{heading}</h4>
                      ) : null}
                      {step ? <p className="text-dark">{step}</p> : null}
                    </div>
                    {activeStep === 'step1' ? (
                      <div>
                        <div className="form-group">
                          <label htmlFor="name">YOUR USERNAME</label>
                          <div className="labelValue">
                            <input
                              name="email"
                              value={email}
                              disabled
                              type="text"
                              placeholder="Email"
                              className="form-control"
                              autoComplete="new-password"
                            />
                          </div>
                        </div>
                        <div
                          className={
                            formerrors && formerrors.password
                              ? 'form-group form-error'
                              : 'form-group'
                          }
                        >
                          <label htmlFor="forpassword">PASSWORD</label>
                          <div className="labelValue inputGroup">
                            <input
                              onChange={(e) => this.handleChange(e)}
                              onBlur={this.handleBlur}
                              disabled={!inviteDetails.is_valid}
                              name="password"
                              type={this.state.passwordType || 'password'}
                              placeholder="Password"
                              className="form-control"
                              autoComplete="off"
                            />
                            <span
                              onClick={() => this.handleEyeIcon('passwordType')}
                              className="inputHelpIcon"
                            >
                              <i
                                className={`icon-${
                                  this.state.passwordType === 'text'
                                    ? 'open'
                                    : 'close'
                                }-eye`}
                                aria-hidden="true"
                              />
                            </span>
                            {formerrors && formerrors.password ? (
                              <span className="error_label">
                                {formerrors.password}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div
                          className={
                            formerrors && formerrors.confirmPassword
                              ? 'form-group form-error'
                              : 'form-group'
                          }
                        >
                          <label htmlFor="forpassword">CONFIRM PASSWORD</label>
                          <div className="labelValue inputGroup">
                            <input
                              onChange={(e) => this.handleChange(e)}
                              onBlur={this.handleBlur}
                              disabled={!inviteDetails.is_valid}
                              name="confirmPassword"
                              type={this.state.confirmType || 'password'}
                              placeholder="Confirm Password"
                              className="form-control"
                              autoComplete="off"
                            />
                            <span
                              onClick={() => this.handleEyeIcon('confirmType')}
                              className="inputHelpIcon"
                            >
                              <i
                                className={`icon-${
                                  this.state.confirmType === 'text'
                                    ? 'open'
                                    : 'close'
                                }-eye`}
                                aria-hidden="true"
                              />
                            </span>
                            {formerrors && formerrors.confirmPassword ? (
                              <span className="error_label">
                                {formerrors.confirmPassword}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="crNotification">
                            <h5 className="fontSize">
                              Please Create a password that meets the following
                              requirements:
                            </h5>
                            <ul className="fontSize">
                              <li>at least 1 Upper Case letter</li>
                              <li>at least 1 number</li>
                              <li>at least 1 special character</li>
                              <li>at least 12 characters in length</li>
                              <li>
                                Password and confirm Password should match
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="form-group">
                          <button
                            type="button"
                            onClick={() => this.changePassword()}
                            disabled={!inviteDetails.is_valid}
                            className="btn btn-primary w-100"
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    ) : null}
                    {activeStep === 'step2' &&
                    !this.state.showMobileModal &&
                    !this.state.showAndroid ? (
                      <div>
                        <div
                          className={
                            formerrors && formerrors.firstName
                              ? 'form-group form-error'
                              : 'form-group'
                          }
                        >
                          <label htmlFor="firstName">FIRST NAME</label>
                          <div className="labelValue">
                            <input
                              onChange={(e) => this.handleChange(e)}
                              value={firstName}
                              name="firstName"
                              type="text"
                              placeholder="First Name"
                              className="form-control"
                              autoComplete="new-password"
                            />
                            {formerrors && formerrors.firstName ? (
                              <span className="error_label">
                                {formerrors.firstName}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div
                          className={
                            formerrors && formerrors.middleName
                              ? 'form-group form-error'
                              : 'form-group'
                          }
                        >
                          <label htmlFor="middleName">MIDDLE NAME</label>
                          <div className="labelValue">
                            <input
                              onChange={(e) => this.handleChange(e)}
                              value={middleName}
                              name="middleName"
                              type="text"
                              placeholder="Middle Name"
                              className="form-control"
                              autoComplete="new-password"
                            />
                            {formerrors && formerrors.middleName ? (
                              <span className="error_label">
                                {formerrors.middleName}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div
                          className={
                            formerrors && formerrors.lastName
                              ? 'form-group form-error'
                              : 'form-group'
                          }
                        >
                          <label htmlFor="lastName">LAST NAME</label>
                          <div className="labelValue">
                            <input
                              onChange={(e) => this.handleChange(e)}
                              value={lastName}
                              name="lastName"
                              type="text"
                              placeholder="Last Name"
                              className="form-control"
                              autoComplete="new-password"
                            />
                            {formerrors && formerrors.lastName ? (
                              <span className="error_label">
                                {formerrors.lastName}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <PhoneNumberField
                          onChange={(e) =>
                            this.handleChange({
                              target: { value: e, name: 'phone' },
                            })
                          }
                          className="form-group"
                          value={phone}
                          onBlur={this.handleBlur}
                          error={formerrors && formerrors.phone}
                          name="phone"
                        />
                        {/* <div className={formerrors && formerrors.phone ? 'form-group form-error' : 'form-group'}>
                          <label htmlFor="phone">MOBILE PHONE</label>
                          <div className="labelValue">
                            <input onChange={e => this.handleChange(e)} value={phone === null ? '' : FORMATPHONENUMBER(phone)} name="phone" id="phone" type="text" placeholder="Phone" className="form-control" />
                            {formerrors && formerrors.phone ?
                              <span className="error_label">{formerrors.phone}</span>
                              :
                              null
                            }
                          </div>
                        </div> */}
                        <div className="form-group">
                          <label className="d-flex">
                            Allow Texting?
                            <div className="checkbox checkbox-primary check-container col">
                              <label htmlFor="is_texting_allowed">
                                <input
                                  name="is_texting_allowed"
                                  id="is_texting_allowed"
                                  type="checkbox"
                                  onChange={(e) => this.handleChange(e)}
                                  value={this.state.is_texting_allowed}
                                  checked={this.state.is_texting_allowed}
                                />
                                <i className="checkmark" />
                              </label>
                            </div>
                          </label>
                        </div>
                        <div className="form-group">
                          <button
                            type="button"
                            onClick={() => this.submitContactDetail()}
                            disabled={!inviteDetails.is_valid}
                            className="btn btn-primary w-100"
                          >
                            Finish
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div className="form-Three borderForm">
                        <div>
                          {this.state.showAndroid ? (
                            <div>
                              <div className="bgText">
                                <p>
                                  Please install Android App to get the best
                                  LISCIO experience
                                </p>
                              </div>
                              <br />
                              <br />
                              <br />
                              <div>
                                <center>
                                  <a
                                    href="https://play.google.com/store/apps/details?id=me.liscio.lisciopro&hl=en"
                                    className="text-center"
                                  >
                                    <img
                                      src={downloadGoogleApp}
                                      className="googleAppLogo"
                                      alt="GoogleSApp"
                                      style={{ maxWidth: '100%' }}
                                    />
                                  </a>
                                </center>
                              </div>
                            </div>
                          ) : this.state.showMobileModal ? (
                            <div>
                              <div className="bgText">
                                <p>
                                  Please install iOS App to get the best LISCIO
                                  experience
                                </p>
                              </div>
                              <br />
                              <br />
                              <br />
                              <div>
                                <center>
                                  <a
                                    href="https://itunes.apple.com/us/app/liscio-pro/id1411993078?ls=1&mt=8"
                                    className="text-center"
                                  >
                                    <img
                                      src={downloadGoogleApp}
                                      className="AppleAppLogo"
                                      alt="AppleApp"
                                      style={{ maxWidth: '100%' }}
                                    />
                                  </a>
                                </center>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Invite);
