/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { Component } from 'react';
import Axios from 'axios';
import { isNil } from 'lodash';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { withQueryClient } from 'utilities/withQueryClient';
import { ACCOUNTS_LOGINS, SECURITY_QUESTIONS } from 'constants/constants';
import InputField from 'common/InputField';
import { TextAreaField, InputFieldToTrickAutoFill } from 'common/CustomFields';
import { AlertMessage, replaceUndefinedWithNull } from 'utilities/utils';
import {
  validateSecurityQuestions,
  encryptSecurityQuestions,
  decryptSecurityQuestions,
  enableAddQuestionsBtn,
} from 'components/Accounts/AccountCreation/AccountUtilities';
import { updateEncryptedField } from 'api/encrypted-fields';
import { EncryptedField } from 'common/EncryptedField/EncryptedField';

class LoginCreation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      initFormData: {},
      formdata: {},
      errors: {},
      initialSecurityQuestions: [{}],
      securityQuestions: [{}],
      deleteQuestions: [],
      loading: true,
      disableSaveBtn: false,
    };
  }

  componentDidMount() {
    const { loginData } = this.props;
    if (loginData) {
      const data = { ...loginData };
      this.setState({
        initFormData: loginData,
        formdata: data,
      });
      this.getSecurityQuestions(data.id);
    } else {
      this.setState({
        loading: false,
      });
    }
  }

  getSecurityQuestions = (loginId) => {
    Axios.get(`${SECURITY_QUESTIONS}/${loginId}`).then((res) => {
      if (res.status === 200 && res.data.data && res.data.data.length) {
        const securityQuestions = decryptSecurityQuestions(res.data.data);
        this.setState({
          loading: false,
          initialSecurityQuestions: res.data.data,
          securityQuestions,
        });
      } else {
        this.setState({
          loading: false,
        });
      }
    });
  };

  handleInputFieldChange = (inputvalue, fieldname, index) => {
    const value =
      inputvalue && inputvalue !== null && inputvalue.length
        ? inputvalue
        : undefined;
    if (index !== undefined) {
      const array = this.state.securityQuestions;
      array[index][fieldname] = value;
      this.setState({
        securityQuestions: array,
      });
    } else {
      this.setState({
        formdata: { ...this.state.formdata, [fieldname]: value },
      });
    }
  };

  handleEncryptedFieldChange = (inputvalue, fieldname) => {
    const [name, index] = fieldname.split('-');
    if (index !== undefined) {
      this.setState((currentState) => {
        const array = currentState.securityQuestions;
        array[index][name] = inputvalue;
        return {
          securityQuestions: array,
        };
      });
    } else {
      this.setState((currentState) => ({
        formdata: { ...currentState.formdata, [name]: inputvalue },
      }));
    }
  };

  addNewField = () => {
    const { securityQuestions } = this.state;
    securityQuestions.push({});
    this.setState({
      securityQuestions,
    });
  };

  deleteField = (index) => {
    let { securityQuestions } = this.state;
    const { securityQuestionErrors } = this.state.errors;
    if (securityQuestionErrors) {
      delete securityQuestionErrors[index];
    }
    const deleteQuestions = [
      ...this.state.deleteQuestions,
      { ...securityQuestions[index], deleteQuestion: true },
    ];
    securityQuestions = securityQuestions.filter((e, key) => index !== key);
    this.setState(
      {
        securityQuestions,
        errors: { ...this.state.errors, securityQuestionErrors },
        deleteQuestions,
      },
      () => (!securityQuestions.length ? this.addNewField() : null),
    );
  };

  getEncryptedFieldsToUpdatePromises = () => {
    const { formdata, securityQuestions } = this.state;

    const encryptedFieldsPromises = [];

    // Password
    if (!isNil(formdata.encryptedPassword) && formdata.id) {
      encryptedFieldsPromises.push(
        updateEncryptedField({
          fieldName: 'password',
          recordType: 'AccountLogin',
          recordId: formdata.id,
          newValue: formdata.encryptedPassword,
        }),
      );
    }

    // Update Responses
    // Creating a new one is done when creating a security query
    securityQuestions.forEach((securityQuestion) => {
      if (!isNil(securityQuestion.encryptedResponse) && securityQuestion.id) {
        updateEncryptedField({
          fieldName: 'response',
          recordType: 'SecurityQuestion',
          recordId: securityQuestion.id,
          newValue: securityQuestion.encryptedResponse,
        });
      }
    });

    return encryptedFieldsPromises;
  };

  handleSubmit = () => {
    const {
      account_name,
      login_account_id,
      web_site,
      username,
      password,
      notes,
    } = this.state.formdata;
    let noErrors = false;
    let errors = {};
    if (
      account_name ||
      login_account_id ||
      web_site ||
      username ||
      password ||
      notes
    ) {
      noErrors = true;
    }
    const securityQuestionErrors = validateSecurityQuestions(
      this.state.securityQuestions,
    );
    if (Object.keys(securityQuestionErrors).length) {
      errors = { ...errors, securityQuestionErrors };
    }
    this.setState({
      errors,
      disableSaveBtn: noErrors && !Object.keys(errors).length,
    });
    if (noErrors && !Object.keys(errors).length) {
      let response = null;
      let { formdata } = this.state;
      const { initFormData } = this.state;
      const securityQuestionsData = [...this.state.securityQuestions];
      const securityQuestions = encryptSecurityQuestions(securityQuestionsData);
      if (formdata.password) {
        formdata = { ...formdata };
      }
      // NOTE: We need to inspect this value for empty string rather than null
      // to be able to delete it properly so we store it and add it back to
      // formdata after nulling everything else...
      const encyrptedPasswordValue = formdata.encryptedPassword;
      formdata = replaceUndefinedWithNull(formdata);
      // Replace non-nulled encryptedPassword value
      formdata.encryptedPassword = encyrptedPasswordValue;
      if (formdata.id) {
        if (
          JSON.stringify(initFormData) !== JSON.stringify(formdata) ||
          JSON.stringify(securityQuestions) !==
            JSON.stringify(this.state.initialSecurityQuestions)
        ) {
          response = Axios.put(`${ACCOUNTS_LOGINS}/${formdata.id}`, {
            account_login: { ...formdata, encryptedPassword: undefined },
          });
        }
      } else {
        formdata = { ...formdata, account_id: this.props.accountId };
        response = Axios.post(ACCOUNTS_LOGINS, {
          account_login: { ...formdata, encryptedPassword: undefined },
        }).then((createAccountLoginResponse) => {
          const accountLoginId = createAccountLoginResponse?.data?.data?.id;
          if (!isNil(formdata.encryptedPassword) && accountLoginId) {
            return updateEncryptedField({
              fieldName: 'password',
              recordType: 'AccountLogin',
              recordId: accountLoginId,
              newValue: formdata.encryptedPassword,
            }).then(() => createAccountLoginResponse);
          }
          return createAccountLoginResponse;
        });
      }

      if (response) {
        response
          .then(async (res) => {
            if (res.status === 200 && res.data.data && res.data.data.id) {
              AlertMessage('success', res.data.message, 1000);

              if (
                JSON.stringify(securityQuestions) !==
                JSON.stringify(this.state.initialSecurityQuestions)
              ) {
                await this.saveSecurityQuestions(
                  securityQuestions,
                  res.data.data.id,
                );
              }

              this.props.handleCloseModal(true);
            } else {
              this.setState({
                disableSaveBtn: false,
              });
            }
          })
          .then(() => Promise.all(this.getEncryptedFieldsToUpdatePromises()))
          .then(() => {
            // Clear cache
            this.props.queryClient?.removeQueries({
              queryKey: ['encryptedField'],
            });
          });
      } else {
        this.props.handleCloseModal(true);
      }
    } else if (!noErrors) {
      AlertMessage('error', 'Enter some data', 1000);
    }
  };

  saveSecurityQuestions = (input, account_login_id) => {
    let questions = input;
    const { initialSecurityQuestions, deleteQuestions } = this.state;
    if (deleteQuestions.length) {
      questions = questions.concat(deleteQuestions);
    }

    return Promise.all(
      questions.map((each, key) => {
        const security_question = { ...each, account_login_id };
        const security_question_without_encrypted_field = {
          ...security_question,
          encryptedResponse: undefined,
        };
        const redirect = key + 1 === questions.length;
        let response = null;

        // We need to support the old mechanism on this endpoint
        // we cannot use an empty response
        if (!security_question_without_encrypted_field.response) {
          security_question_without_encrypted_field.response = '--encrypted--';
        }

        if (security_question.deleteQuestion) {
          response = Axios.delete(`${SECURITY_QUESTIONS}/${each.id}`);
        } else if (security_question.id) {
          const existingQuestion = initialSecurityQuestions.find(
            (question) => security_question.id === question.id,
          );
          const questionChanged =
            security_question.question !== existingQuestion?.question;
          // .encryptedResponse is only present if the user typed in the encrypted input
          const encryptedInputTouched =
            security_question.encryptedResponse !== undefined &&
            security_question.encryptedResponse !== null;

          if (questionChanged || encryptedInputTouched) {
            response = Axios.put(`${SECURITY_QUESTIONS}/${each.id}`, {
              security_question: security_question_without_encrypted_field,
            });
          }
        } else {
          response = Axios.post(SECURITY_QUESTIONS, {
            security_question: security_question_without_encrypted_field,
          }).then((addedSecurityQuestionResponse) => {
            const securityQuestionId =
              addedSecurityQuestionResponse?.data?.data?.id;
            if (security_question.encryptedResponse && securityQuestionId) {
              return updateEncryptedField({
                fieldName: 'response',
                recordType: 'SecurityQuestion',
                recordId: securityQuestionId,
                newValue: security_question.encryptedResponse,
              }).then(() => addedSecurityQuestionResponse);
            }
            return addedSecurityQuestionResponse;
          });
        }

        return response?.then((res) => {
          AlertMessage('success', res.data.message, 1000);
          if (redirect) {
            this.props.handleCloseModal(true);
          }
        });
      }),
    );
  };

  render() {
    const { formdata, errors, securityQuestions, disableSaveBtn, loading } =
      this.state;
    const { securityQuestionErrors } = errors;
    const { loginData } = this.props;
    return (
      <div>
        {loading ? <div id="loading" /> : null}
        {loginData ? (
          <div className="section-title">
            <h4>Edit Login</h4>
            {/* <p>Edit login associated with <strong>{this.props.accountName}</strong></p> */}
          </div>
        ) : (
          <div className="section-title">
            <h4>Add Login</h4>
            <p>
              Create a new login associated with{' '}
              <strong>{this.props.accountName}</strong>
            </p>
          </div>
        )}
        <div>
          <form action="">
            <input
              type="password"
              name="password123123123123"
              autoComplete="off"
              className="hidden"
            />
            <div className="row">
              <InputField
                name="account_name"
                label="Account Name"
                onChange={this.handleInputFieldChange}
                value={formdata.account_name}
                error={errors.account_name}
                className="col-sm-12 col-md-6 col-lg-4 form-group"
              />
              <InputField
                name="login_account_id"
                label="Account ID"
                onChange={this.handleInputFieldChange}
                value={formdata.login_account_id}
                error={errors.login_account_id}
                className="col-sm-12 col-md-6 col-lg-4 form-group"
              />
              <InputField
                name="web_site"
                label="Website"
                onChange={this.handleInputFieldChange}
                value={formdata.web_site}
                error={errors.web_site}
                className="col-sm-12 col-md-6 col-lg-4 form-group"
              />
            </div>
            <div className="row">
              <InputFieldToTrickAutoFill
                name="namname"
                label="User name"
                onChange={(input) =>
                  this.handleInputFieldChange(input, 'username')
                }
                value={formdata.username}
                error={errors.username}
                className="col-sm-12 col-md-6 col-lg-4 form-group"
                autoComplete="randomfield"
              />
              <EncryptedField
                recordId={formdata?.id}
                label="Password"
                recordType="AccountLogin"
                fieldName="password"
                formFieldName="encryptedPassword"
                className="col-sm-12 col-md-6 col-lg-4 form-group"
                value={formdata?.encryptedPassword}
                error={errors?.encryptedPassword}
                onChange={this.handleEncryptedFieldChange}
                hasEncryptedValue={formdata?.encrypted_password?.populated}
              />
            </div>
            <div className="row">
              <TextAreaField
                name="notes"
                label="Notes"
                onChange={this.handleInputFieldChange}
                value={formdata.notes}
                error={errors.notes}
                className="col-sm-12 col-md-12 col-lg-12 form-group"
              />
            </div>
            {securityQuestions.map((each, key) => {
              const name = `securityQuestions${key}`;
              const securityQuestionError =
                securityQuestionErrors && securityQuestionErrors[key]
                  ? securityQuestionErrors[key]
                  : {};
              return (
                <div key={name}>
                  <div className="row no-gutters align-items-center mt-2 mb-2">
                    <h5 className="mb-0">{`${key + 1}. Security Questions`}</h5>
                    {(securityQuestions.length > 1 &&
                      securityQuestions.length - 1 !== key) ||
                    Object.keys(each).length ? (
                      // eslint-disable-next-line jsx-a11y/anchor-is-valid
                      <a
                        // eslint-disable-next-line react/jsx-no-script-url, no-script-url
                        href="javascript:void(0);"
                        className="icon-delete-small btnOnlyLink ml-3"
                        onClick={() => this.deleteField(key)}
                        alt="Delete"
                      />
                    ) : null}
                  </div>
                  <div className="row">
                    <InputField
                      name={`question${key}`}
                      label="question"
                      onChange={(input) =>
                        this.handleInputFieldChange(input, 'question', key)
                      }
                      value={each.question}
                      error={securityQuestionError.question}
                      className="col-sm-12 col-md-12 col-lg-12 form-group"
                    />
                    <EncryptedField
                      recordId={each?.id}
                      label="Response"
                      recordType="SecurityQuestion"
                      fieldName="response"
                      formFieldName={`encryptedResponse-${key}`}
                      className="col-sm-12 col-md-12 col-lg-12 form-group"
                      value={each?.encryptedResponse}
                      error={errors?.encryptedResponse}
                      onChange={this.handleEncryptedFieldChange}
                      hasEncryptedValue={each?.encrypted_response?.populated}
                    />
                  </div>
                  {securityQuestions.length - 1 === key ? (
                    <div className="">
                      <button
                        type="button"
                        className="no-background"
                        onClick={() => this.addNewField('dependents')}
                        disabled={enableAddQuestionsBtn(securityQuestions)}
                      >
                        + New Security Question
                      </button>
                    </div>
                  ) : null}
                </div>
              );
            })}
            <div className="row no-gutters justify-content-end">
              <button
                type="button"
                className="btn btn-outline-light mr-3"
                onClick={() => this.props.handleCloseModal(false)}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={this.handleSubmit}
                disabled={disableSaveBtn}
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default withLDConsumer()(withQueryClient(LoginCreation));
