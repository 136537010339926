/* eslint-disable no-use-before-define */
import React, { useState, useEffect, useReducer } from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import Axios from 'axios';
import {
  GET_OWNERS,
  GET_INVOVICE_TERMS_AND_ITEMS,
  GET_CPA_INVOICES_SETTINGS,
  GET_ACCOUNTS_FOR_INVOICE,
  GET_CONTACTS_FOR_INVOICE,
  INVOICE_RETRIEVAL_AND_CREATION,
  EDIT,
  DEFAULT,
  NEVER,
  RECURRING,
  EDIT_RECURRING,
  ACCOUNT_KEY,
  CONTACT_KEY,
  INVOICE_NUMBER_KEY,
  OWNER_KEY,
  ITEM_KEY,
  INVOICE_DATE_KEY,
  DUE_DATE_KEY,
  AMOUNT_KEY,
  DESCRIPTION_KEY,
  PAYMENT_METHODS_KEY,
  MIXPANEL_INVOICE_CREATE_EVENT,
  MIXPANEL_INVOICE_EDIT_EVENT,
  GET_TEMPLATES_FOR_CREATION,
} from 'constants/constants';
import LoaderDefault from 'common/LoaderDefault';
import { withStorageData } from 'utilities/withStorageData';
import { AlertMessage, getStorageData } from 'utilities/utils';
import addDays from 'date-fns/addDays';
import getMonth from 'date-fns/getMonth';
import getDate from 'date-fns/getDate';
import format from 'date-fns/format';
import { trackMixpanelEvent } from 'utilities/mixpanelfn';
import { formReducer } from 'components/Billing/billingUtils';
import NewInvoice from './NewInvoice';

const currentDate = new Date();
const currentMonth = getMonth(currentDate);

const currentRecurringDate = addDays(currentDate, 5);
const currentStartDay = getDate(currentRecurringDate);

const initialFormState = {
  invoiceNumber: '',
  amount: '',
  account: '',
  contact: '',
  item: '',
  paymentTerms: '',
  paymentMethods: {
    label: 'Card and ACH',
    value: ['card', 'ach'],
  },
  invoiceDate: currentDate,
  dueDate: currentDate,
  message: '',
  documents: [],
  recurringStartDate: currentRecurringDate,
  recurringEndDate: NEVER,
  nextChargeDate: currentRecurringDate,
  frequency: 'Monthly',
  onSelectedDay: { label: currentStartDay, value: currentStartDay },
  onSelectedMonth: currentMonth,
  recurringInterval: { label: 1, value: 1 },
  descriptionInput: '',
  customTemplateInput: '',
};
const InvoiceModal = ({
  isOpen,
  handleModalClose,
  getAllInvoices,
  initialData = {},
  editState,
  mode = DEFAULT,
}) => {
  // TODO: __HOMEDATA-AUDIT
  const item = getStorageData();

  const currentCpa = {
    id: item ? item.cpa_user_id : '',
    label: item ? item.uname : '',
  };

  const [allAccounts, setAllAccounts] = useState(undefined);
  const [allContacts, setAllContacts] = useState(undefined);
  const [owners, setOwners] = useState(undefined);
  const [items, setItems] = useState(undefined);
  const [cpaInvoiceSettings, setCPAInvoiceSettings] = useState(undefined);
  const [paymentTerms, setPaymentTerms] = useState(undefined);
  const [billingTemplates, setBillingTemplates] = useState(undefined);
  const [showInvoicePreview, setInvoicePreview] = useState(false);
  const [formState, dispatch] = useReducer(
    formReducer,
    initialFormState,
    initFunc,
  );
  const [isFormValid, setFormValidity] = useState(true);
  const [isBtnDisabled, setDisableButton] = useState(false);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (initialData?.contact?.value) {
      getAccountsForContact(initialData.contact.value);
    } else {
      getAllAccounts();
    }
    if (initialData?.account?.value) {
      getContactsForAccount(initialData.account.value);
    } else {
      getAllContacts();
    }
    getOwners();
    getInvoiceTermsAndItems();
    getCPAInvoiceSettings();
    getBillingTemplates();
  }, []);

  /**
   * This function is the init function for useReducer which helps to set
   * initial state based on props
   * @param {<object>} initialState
   * @returns {<object>}
   */
  function initFunc(initialState) {
    return mode === EDIT || mode === EDIT_RECURRING
      ? editState
      : { ...initialState, ...initialData, owner: currentCpa };
  }

  /**
   * Disable Save Changes button when editing a One Time Invoice
   */
  useEffect(() => {
    if (mode === EDIT && !validateForm()) {
      setDisableButton(true);
    }
  }, []);

  /**
   * When selecting a contact in the Invoice form, if there is only one account
   * related to the selected contact this useEffect will be triggered and auto
   * populate the select dropdown
   */
  useEffect(() => {
    if (allAccounts && allAccounts.length === 1) {
      handleInputChange('account', allAccounts[0]);
    }
  }, [allAccounts]);

  /**
   * When selecting a contact in the Invoice form, if there is only one contact
   * related to the selected account this useEffect will be triggered and auto
   * populate the select dropdown
   */
  useEffect(() => {
    if (allContacts && allContacts.length === 1) {
      handleInputChange('contact', allContacts[0]);
    }
  }, [allContacts]);

  /**
   * When no account or contact is selected, get all the accounts and contacts
   */
  useEffect(() => {
    if (!formState.account && !formState.contact) {
      getAllAccounts();
      getAllContacts();
    }
  }, [formState.account, formState.contact]);

  function handleInputChange(fieldName, fieldValue) {
    dispatch({
      type: 'INPUT',
      fieldName,
      fieldValue,
    });
  }

  function handleDateChange(fieldName, fieldValue) {
    dispatch({
      type: 'DATE',
      fieldName,
      fieldValue,
    });
  }

  function validateForm() {
    let isValid = true;
    Object.keys(formState).forEach((key) => {
      if (
        (key === ACCOUNT_KEY && allAccounts && allAccounts.length >= 0) ||
        (key === CONTACT_KEY && allContacts && allContacts.length >= 0) ||
        (mode === DEFAULT &&
          key === INVOICE_NUMBER_KEY &&
          (!formState.account.qb_customer_id || !checked)) ||
        key === OWNER_KEY ||
        key === ITEM_KEY ||
        key === INVOICE_DATE_KEY ||
        key === DUE_DATE_KEY ||
        key === AMOUNT_KEY ||
        key === DESCRIPTION_KEY ||
        key === PAYMENT_METHODS_KEY
      ) {
        const noValue = !formState[key];
        const noContactOrAccountValue =
          (key === ACCOUNT_KEY || key === CONTACT_KEY) &&
          !formState[key]?.value;

        if (noValue || noContactOrAccountValue) {
          isValid = false;
        }
      }
    });

    setFormValidity(isValid);

    return isValid;
  }

  function handleContinue() {
    if (validateForm()) {
      setInvoicePreview(true);
    }
  }

  async function getAllAccounts() {
    try {
      const response = await Axios.get(GET_ACCOUNTS_FOR_INVOICE);

      if (response.status === 200) {
        setAllAccounts(response.data.data);
      }
    } catch (err) {
      console.error(err);
    }
  }

  async function getAllContacts() {
    try {
      const response = await Axios.get(GET_CONTACTS_FOR_INVOICE);
      if (response.status === 200) {
        setAllContacts(response.data.data);
      }
    } catch (err) {
      console.error(err);
    }
  }

  async function getOwners() {
    try {
      const response = await Axios.get(GET_OWNERS);

      if (response.status === 200) {
        setOwners(response.data.data);
      }
    } catch (err) {
      console.error(err);
    }
  }

  async function getInvoiceTermsAndItems() {
    try {
      const response = await Axios.get(GET_INVOVICE_TERMS_AND_ITEMS);
      if (response.status === 200) {
        setPaymentTerms(response.data.data.invoice_terms);
        setItems(response.data.data.invoice_items);
      }
    } catch (err) {
      console.error(err);
    }
  }

  async function getCPAInvoiceSettings() {
    try {
      const response = await Axios.get(GET_CPA_INVOICES_SETTINGS);
      if (response.status === 200) {
        setCPAInvoiceSettings(response.data.data);
      }
    } catch (err) {
      console.error(err);
    }
  }

  // get all contacts for a specific account
  async function getContactsForAccount(accountId) {
    try {
      const response = await Axios.get(
        `${GET_CONTACTS_FOR_INVOICE}/?account_id=${accountId}`,
      );
      if (response.status === 200) {
        setAllContacts(response.data.data);
      }
    } catch (err) {
      console.error(err);
    }
  }

  // get all accounts for a specific contact
  async function getAccountsForContact(contactId) {
    try {
      const response = await Axios.get(
        `${GET_ACCOUNTS_FOR_INVOICE}?contact_id=${contactId}`,
      );
      if (response.status === 200) {
        setAllAccounts(response.data.data);
      }
    } catch (err) {
      console.error(err);
    }
  }

  async function getBillingTemplates() {
    try {
      const response = await Axios.get(
        `${GET_TEMPLATES_FOR_CREATION}?template_type=invoice_template`,
      );

      if (response.status === 200) {
        setBillingTemplates(response.data.data);
      }
    } catch (err) {
      console.error(err);
    }
  }

  function handleGoBack() {
    setInvoicePreview(false);
  }

  function handleClose() {
    setInvoicePreview(false);
    setFormValidity(true);

    handleModalClose();
  }

  function handleCancel() {
    const type = getInvoiceType();
    if (mode === DEFAULT || mode === RECURRING) {
      trackMixpanelEvent(MIXPANEL_INVOICE_CREATE_EVENT, {
        type,
        frequency: formState.frequency,
        recurring_interval: formState.recurringInterval,
        payment_methods: formState.paymentMethods.value,
        invoice_amount: formState.amount,
        state: 'cancelled',
      });
      getAllInvoices();
    } else {
      trackMixpanelEvent(MIXPANEL_INVOICE_EDIT_EVENT, {
        type,
        frequency: formState.frequency,
        recurring_interval: formState.recurringInterval,
        payment_methods: formState.paymentMethods.value,
        invoice_amount: formState.amount,
        state: 'cancelled',
      });
    }
    handleClose();
  }

  function getInvoiceType() {
    if (mode === EDIT_RECURRING || mode === RECURRING) {
      return 'recurring';
    }
    return 'onetime';
  }

  async function sendInvoice(invoiceData) {
    setDisableButton(true);
    const type = getInvoiceType();
    try {
      const response =
        mode === EDIT || mode === EDIT_RECURRING
          ? await Axios.put(
              `${INVOICE_RETRIEVAL_AND_CREATION}/${invoiceData.id}`,
              invoiceData,
            )
          : await Axios.post(INVOICE_RETRIEVAL_AND_CREATION, invoiceData);
      if (response.status === 200) {
        AlertMessage('success', response.data.message);
        if (mode === DEFAULT || mode === RECURRING) {
          trackMixpanelEvent(MIXPANEL_INVOICE_CREATE_EVENT, {
            type,
            recurring_interval: invoiceData.recurring_interval,
            frequency: invoiceData.frequency,
            payment_methods: invoiceData.payment_methods,
            invoice_amount: invoiceData.invoice_amount,
            state: 'created',
          });
          getAllInvoices();
        } else {
          trackMixpanelEvent(MIXPANEL_INVOICE_EDIT_EVENT, {
            type,
            recurring_interval: invoiceData.recurring_interval,
            frequency: invoiceData.frequency,
            payment_methods: invoiceData.payment_methods,
            invoice_amount: invoiceData.invoice_amount,
            state: 'edited',
          });
        }
        setDisableButton(false);
        handleClose();
      }
    } catch (err) {
      if (mode === DEFAULT || mode === RECURRING) {
        trackMixpanelEvent(MIXPANEL_INVOICE_CREATE_EVENT, {
          type,
          recurring_interval: invoiceData.recurring_interval,
          payment_methods: invoiceData.payment_methods,
          invoice_amount: invoiceData.invoice_amount,
          state: 'error',
        });
      } else {
        trackMixpanelEvent(MIXPANEL_INVOICE_EDIT_EVENT, {
          type,
          recurring_interval: invoiceData.recurring_interval,
          payment_methods: invoiceData.payment_methods,
          invoice_amount: invoiceData.invoice_amount,
          state: 'error',
        });
      }
      AlertMessage('error', err.response.data.message);
      setDisableButton(false);
      console.error(err);
    }
  }

  function getDocumentIds(documents) {
    const documentIds = documents.reduce((acc, doc) => {
      acc.push(doc.id);
      return acc;
    }, []);

    return documentIds;
  }

  function handleInvoiceSubmit() {
    const docIds = getDocumentIds(formState.documents);
    const commonFields = {
      assigne_type: 'contact',
      assigned_to_user: formState.contact,
      owner: formState.owner,
      for_account: formState.account,
      description: formState.customTemplateInput,
      task_type_key: 'bill',
      task_type_value: 'Bill',
      invoice_number: formState.invoiceNumber,
      invoice_amount: formState.amount,
      message: formState.message,
      doc_ids: docIds,
      payment_methods: formState.paymentMethods.value,
      payment_items: formState.item,
      ...(formState.qb_invoice_id
        ? { qb_invoice_id: formState.qb_invoice_id }
        : { qb_invoice_id: null }),
      id: formState.id,
    };

    const formStateForOneTimeInvoice = {
      ...commonFields,
      invoice_date: format(formState.invoiceDate, 'MM/dd/yyyy'),
      invoice_due_date: format(formState.dueDate, 'MM/dd/yyyy'),
      payment_terms: formState.paymentTerms,
    };

    const formStateForRecurringInvoice = {
      ...commonFields,
      start_date: formState.recurringStartDate
        ? format(formState.recurringStartDate, 'MM/dd/yyyy')
        : undefined,
      end_date:
        formState.recurringEndDate === NEVER
          ? null
          : formState.recurringEndDate,
      next_charge_date: formState.nextChargeDate
        ? format(formState.nextChargeDate, 'MM/dd/yyyy')
        : undefined,
      frequency: formState.frequency,
      is_recurring: true,
      due_on_day_of_month: formState.onSelectedDay
        ? formState.onSelectedDay.value
        : undefined,
      recurring_interval: formState.recurringInterval
        ? formState.recurringInterval.label
        : undefined,
      due_on_month:
        formState.frequency === 'Monthly' ? formState.onSelectedMonth : '',
    };

    switch (mode) {
      case RECURRING:
        sendInvoice(formStateForRecurringInvoice);
        break;
      case EDIT:
        sendInvoice(formStateForOneTimeInvoice);
        break;
      case EDIT_RECURRING:
        sendInvoice(formStateForRecurringInvoice);
        break;
      default:
        sendInvoice(formStateForOneTimeInvoice);
    }
  }

  /**
   * This method gets invoice data from QB
   * @param {<object>} invoiceDetails
   */
  // eslint-disable-next-line consistent-return
  function onRowSelect(invoiceDetails) {
    if (!invoiceDetails) return undefined;

    const {
      for_account,
      invoice_amount,
      invoice_date,
      invoice_due_date,
      invoice_number,
      payment_items,
      qb_invoice_id,
      description,
      payment_terms,
      message,
    } = invoiceDetails;

    const isDifferentAccount = formState.account.value !== for_account.value;

    /*
     * If a user searches and selects a different account other than the
     *  populated one then get all the contacts related to that account
     */
    if (isDifferentAccount) {
      getContactsForAccount(for_account.value);
    }

    const updatedState = {
      ...(formState.contact && !isDifferentAccount
        ? { contact: formState.contact }
        : { contact: '' }),
      ...(invoiceDetails.owner
        ? { owner: invoiceDetails.owner }
        : { owner: '' }),
      account: for_account,
      customTemplateInput: description,
      invoiceNumber: invoice_number,
      amount: invoice_amount,
      owner: currentCpa,
      invoiceDate: new Date(invoice_date),
      dueDate: new Date(invoice_due_date),
      message,
      ...(invoiceDetails.documents
        ? { documents: invoiceDetails.documents }
        : { documents: [] }),
      ...(invoiceDetails.payment_methods
        ? { paymentMethods: invoiceDetails.payment_methods }
        : { paymentMethods: formState.paymentMethods }),
      ...(invoiceDetails.payment_terms
        ? { paymentTerms: invoiceDetails.payment_terms }
        : { paymentTerms: '' }),
      item: payment_items,
      paymentTerms: payment_terms,
      qb_invoice_id,
      isQBMode: true,
    };

    dispatch({ type: 'UPDATE_ALL', updatedState });
  }

  function saveChangesAfterEdit() {
    if (validateForm()) {
      handleInvoiceSubmit();
    }
  }

  function renderModalButtons() {
    if (showInvoicePreview) {
      if (mode === DEFAULT) {
        return (
          <button
            className="btn btn-primary"
            type="button"
            onClick={handleInvoiceSubmit}
            disabled={isBtnDisabled}
          >
            Send Invoice
          </button>
        );
      }
      return (
        <button
          className="btn btn-primary"
          type="button"
          onClick={handleInvoiceSubmit}
          disabled={isBtnDisabled}
        >
          Send Charge
        </button>
      );
    }

    if (mode === EDIT || mode === EDIT_RECURRING) {
      return (
        <>
          <button className="btn" type="button" onClick={handleCancel}>
            Discard
          </button>
          <button
            className="btn btn-primary"
            type="button"
            onClick={saveChangesAfterEdit}
            disabled={isBtnDisabled}
          >
            Save Changes
          </button>
        </>
      );
    }

    return (
      <>
        <button className="btn" type="button" onClick={handleCancel}>
          Cancel
        </button>
        <button
          className="btn btn-primary"
          type="button"
          data-testid="continueButton"
          onClick={handleContinue}
        >
          Continue
        </button>
      </>
    );
  }

  function renderModalHeaderTitle() {
    if (showInvoicePreview) {
      if (mode === DEFAULT) {
        return (
          <>
            <div className="InvoiceSummary__Link" onClick={handleGoBack}>
              <i className="icon-left-arrow" />
              <h6>
                <span>New Invoice /</span> Invoice Summary
              </h6>
            </div>
            <button
              type="button"
              onClick={handleModalClose}
              className="close"
              aria-label="Close"
            >
              <i className="icon-close2" aria-hidden="true" />
            </button>
          </>
        );
      }
      return (
        <>
          <div className="InvoiceSummary__Link" onClick={handleGoBack}>
            <i className="icon-left-arrow" />
            <h6>
              <span>New Recurring Charge /</span> Recurring Charge Summary
            </h6>
          </div>
          <button
            type="button"
            onClick={handleModalClose}
            className="close"
            aria-label="Close"
          >
            <i className="icon-close2" aria-hidden="true" />
          </button>
        </>
      );
    }

    if (mode === EDIT) {
      return (
        <>
          Edit Invoice
          <button
            type="button"
            onClick={handleModalClose}
            className="close"
            aria-label="Close"
          >
            <i className="icon-close2" aria-hidden="true" />
          </button>
        </>
      );
    }

    if (mode === EDIT_RECURRING) {
      return (
        <>
          Edit Recurring Charge
          <button
            type="button"
            onClick={handleModalClose}
            className="close"
            aria-label="Close"
          >
            <i className="icon-close2" aria-hidden="true" />
          </button>
        </>
      );
    }

    if (mode === RECURRING) {
      return (
        <>
          New Recurring Charge
          <button
            type="button"
            onClick={handleModalClose}
            className="close"
            aria-label="Close"
          >
            <i className="icon-close2" aria-hidden="true" />
          </button>
        </>
      );
    }

    return (
      <>
        New Invoice
        <button
          type="button"
          onClick={handleModalClose}
          className="close"
          aria-label="Close"
        >
          <i className="icon-close2" aria-hidden="true" />
        </button>
      </>
    );
  }

  return (
    <Modal
      isOpen={isOpen}
      data-testid="invoiceModal"
      className="InvoiceModal customModal customModal--center"
    >
      <ModalHeader className="ModalHeader">
        {renderModalHeaderTitle()}
      </ModalHeader>
      <ModalBody className="InvoiceModal__ModalBody">
        {!cpaInvoiceSettings ||
        !allAccounts ||
        !allContacts ||
        !owners ||
        !paymentTerms ||
        !items ? (
          <div className="InvoiceModal__Loading">
            <p>LOADING...</p>
          </div>
        ) : (
          <NewInvoice
            allAccounts={allAccounts}
            allContacts={allContacts}
            owners={owners}
            paymentTerms={paymentTerms}
            items={items}
            cpaInvoiceSettings={cpaInvoiceSettings}
            billingTemplates={billingTemplates}
            handleInputChange={handleInputChange}
            handleDateChange={handleDateChange}
            formState={formState}
            showInvoicePreview={showInvoicePreview}
            isFormValid={isFormValid}
            getContactsForAccount={getContactsForAccount}
            getAccountsForContact={getAccountsForContact}
            getAllAccounts={getAllAccounts}
            getAllContacts={getAllContacts}
            onRowSelect={onRowSelect}
            mode={mode}
            dispatch={dispatch}
            setDisableButton={setDisableButton}
            checked={checked}
            setChecked={setChecked}
          />
        )}
      </ModalBody>
      <ModalFooter className="modalContent">{renderModalButtons()}</ModalFooter>
    </Modal>
  );
};

export default withStorageData(InvoiceModal, <LoaderDefault />);
