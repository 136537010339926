import { GridColDef } from '@mui/x-data-grid';
import { format } from 'date-fns';
import { Typography } from '@mui/material';
import { useTodoFeedsQuery } from 'hooks/todo-feeds-hooks';
import { useNavigate } from 'react-router-dom-latest';
import { TodoFeedItemItem } from 'gql/graphql';
import { LoadingOverlay } from 'ui/Tables/Overlays/LoadingOverlay';
import { NoResultsOverlay } from 'ui/Tables/Overlays/NoResultsOverlay';
import {
  DataGridStyled,
  Indicator,
  TableHeaderStyled,
} from './MyTodoList.styles';

export const MyTodoListTable = () => {
  const navigate = useNavigate();

  const { data, isLoading } = useTodoFeedsQuery();

  const columns: GridColDef[] = [
    {
      field: '_indicator',
      headerName: '',
      minWidth: 10,
      width: 20,
      sortable: false,
      valueGetter: ({ row }) => row.isRead,
      renderCell: ({ value }) => (value ? null : <Indicator />),
    },
    {
      field: '_title',
      headerName: 'Name',
      flex: 3,
      sortable: false,
      valueGetter: ({ row }) => row.item?.title || row.item?.subject,
      renderCell: ({ value, row }) => (
        <Typography fontWeight={row.isRead ? '' : 'bold'}>{value}</Typography>
      ),
    },
    {
      field: '_dueDate',
      headerName: 'Due Date',
      flex: 1,
      sortable: false,
      // setting the value to 'Z' if there is no due date so they get sorted to the bottom
      valueGetter: ({ row }) => row.item?.dueDate || 'Z',
      renderCell: ({ value }) =>
        value &&
        value !== 'Z' && <>{format(new Date(value as string), 'MM/dd/yyyy')}</>,
    },
  ];

  const onRowClick = (row: any) => {
    if (row.feedItemType === TodoFeedItemItem.WorkflowRequest) {
      navigate(`/requests/${row.item.id}?type=REQUEST`);
    }
    if (row.feedItemType === TodoFeedItemItem.Task) {
      navigate(`/task/detail/${row.item.legacyId}`);
    }
    if (row.feedItemType === TodoFeedItemItem.Invoice) {
      if (row.item.isRecurring) {
        navigate(`/billing/recurring/${row.item.legacyId}`);
      } else {
        navigate(`/billing/invoices/${row.item.legacyId}`);
      }
    }
  };

  return (
    <>
      <TableHeaderStyled>My To Do List</TableHeaderStyled>
      <DataGridStyled
        initialState={{
          sorting: {
            sortModel: [{ field: '_dueDate', sort: 'asc' }],
          },
          pagination: {
            paginationModel: { pageSize: 100, page: 0 },
          },
        }}
        rows={data?.todoFeeds || []}
        columns={columns}
        loading={isLoading}
        onRowClick={({ row }) => {
          onRowClick(row);
        }}
        slots={{
          loadingOverlay: LoadingOverlay,
          noResultsOverlay: () =>
            NoResultsOverlay({
              message: 'No To Dos',
            }),
          noRowsOverlay: () =>
            NoResultsOverlay({
              message: 'No To Dos',
            }),
        }}
        disableColumnMenu
        pageSizeOptions={[100]}
        rowHeight={42}
        columnHeaderHeight={36}
      />
    </>
  );
};
