/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable react/no-access-state-in-setstate */
import React, { Component } from 'react';
import Axios from 'axios';
import { Link } from 'react-router-dom-latest';
import { connect } from 'react-redux';
import { withRouter } from 'utilities/withRouterWrapper';
import moment from 'moment';
import { Button, Input, Label } from 'reactstrap';
import UnauthorisedFeature from 'common/UnauthorisedFeature_hooks_updated';
import {
  CONTACT,
  DEACTIVATE_CONTACT,
  SEND_INVITE_LINK,
  SEND_FORGOT_LINK,
  ACCOUNTS_ARCHIVE,
  ACCOUNTS_UNARCHIVE,
} from 'constants/constants';
import Pagination from 'common/Pagination';
import {
  isUserEmployee,
  getOffSetsAndLimit,
  AlertMessage,
  sortingIcon,
  generatingContactsCsvFile,
  getFirmType,
  FORMATPHONENUMBER,
  getRole,
} from 'utilities/utils';
import HeaderTabs from 'common/HeaderTabs_updated';
import ConfirmationModal from 'common/ConfirmationModal';
import TooltipItem from 'common/TooltipItem_updated';
import NoRecords from 'common/NoRecords';
import { withStorageData } from 'utilities/withStorageData';
import { saveContactListData } from 'actions/AccountandContactListActions';
import { sortContactList } from './ContactCreation/ContactUtilities';

const totalPages = (
  filterBy,
  tabsArray,
  tabProperty,
  noOfRecordsInEachPage,
) => {
  let output = 0;
  tabsArray.map((each) => {
    if (each[tabProperty].toLowerCase() === filterBy.toLowerCase()) {
      output = each.noofrecords / noOfRecordsInEachPage;
    }
    return null;
  });
  return Number(Math.ceil(output));
};

const totalRecords = (filterBy, tabsArray, tabProperty) => {
  let output = 0;
  tabsArray.map((each) => {
    if (each[tabProperty].toLowerCase() === filterBy.toLowerCase()) {
      output = each.noofrecords;
    }
    return null;
  });
  return output;
};

const contactsPageTabs = [
  {
    filterlabel: 'all',
    value: 'all',
    label: 'All',
  },
  {
    filterlabel: 'New',
    value: 'new',
    label: 'New',
  },
  {
    filterlabel: 'Invited',
    value: 'invited',
    label: 'Invited',
  },
  {
    filterlabel: 'Active',
    value: 'active',
    label: 'Active',
  },
  {
    filterlabel: 'expired',
    value: 'expired',
    label: 'Expired',
  },
  {
    filterlabel: 'inactive',
    value: 'inactive',
    label: 'Archived',
  },
];

const contactPageTableHeaders = [
  {
    value: 'last_name',
    label: 'Contact Name',
    isSortable: true,
    className: ' col-2 thCol',
  },
  {
    value: 'primary_email',
    label: 'Email',
    isSortable: true,
    className: ' col-3 thCol',
  },
  {
    value: 'phone_number',
    label: 'Mobile #',
    isSortable: true,
    className: ' col-2 thCol',
  },
  {
    value: 'open_task',
    label: 'Open Tasks',
    isSortable: true,
    className: ' col-1 thCol',
  },
];

const createdAt = {
  value: 'created_at',
  label: 'Created On',
  isSortable: true,
  className: ' col-2 thCol',
};

const deactivatedAt = {
  value: 'deactivated_at',
  label: 'Deactivated On',
  isSortable: true,
  className: ' col-2 thCol',
};

const lastInviteSentAt = {
  value: 'last_invite_sent_at',
  label: 'Invited On',
  isSortable: true,
  className: ' col-2 thCol',
};

const activatedAt = {
  value: 'activated_at',
  label: 'Activated On',
  isSortable: true,
  className: ' col-2 thCol',
};

const inviteExpiredAt = {
  value: 'invitation_expired_date',
  label: 'Expired On',
  isSortable: false,
  className: ' col-2 thCol',
};

const removeItem = (array, element) =>
  array.filter(
    (e) => !(e.contact.id === element.contact.id) && !element.checked,
  );

class AllContacts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: 0,
      tabs: contactsPageTabs,
      listData: [],
      sortBy: 'last_name',
      sortOrder: 'asc',
      pagination: 1,
      filter: 'all',
      totalPages: 1,
      totalRecords: 0,
      offset: [0, 15],
      loading: true,
      searchFieldText: '',
      showDeleteConfirmation: false,
      unauthorisedAccess: !isUserEmployee(),
      role: getRole(),
      selectedCheckbox: [],
      selectedAll: false,
      confirmArchive: false,
      componentContext: this.props.componentContext,
    };
  }

  componentDidMount() {
    document.title = 'Contacts';
    if (!this.state.unauthorisedAccess) {
      let { activeTab, pagination, sortBy, sortOrder, searchFieldText } =
        this.state;
      if (this.props.location && this.props.location.search) {
        const searchParams = new URLSearchParams(this.props.location.search);
        searchParams.forEach((value, key) => {
          if (key === 'filter') {
            activeTab = this.state.tabs.findIndex(
              (each) => each.filterlabel.toLowerCase() === value.toLowerCase(),
            );
            if (activeTab < 0) {
              activeTab = 0;
            }
          } else if (key === 'page') {
            // eslint-disable-next-line radix
            pagination = parseInt(value);
          } else if (key === 'sort_by') {
            sortBy = value;
          } else if (key === 'sort_order') {
            sortOrder = value;
          } else if (key === 'search') {
            searchFieldText = value;
          }
        });
        this.setState(
          {
            activeTab,
            filter: this.state.tabs[activeTab].filterlabel,
            pagination,
            sortBy,
            sortOrder,
            searchFieldText,
          },
          () => {
            this.getRecords(true);
          },
        );
      } else {
        this.getRecords(true);
      }
    } else {
      return false;
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.location.search === '' &&
      (this.props.location.search.includes('filter') ||
        this.props.location.search.includes('search'))
    ) {
      this.clearFilters();
    }
  }

  getRecords = () => {
    const {
      pagination,
      sortBy,
      sortOrder,
      filter,
      searchFieldText,
      componentContext,
    } = this.state;
    const { contactslistdata } = this.props;
    let allContactsButtonUrl = `/contacts?filter=${filter}&page=${pagination}&sort_by=${sortBy}&sort_order=${sortOrder}`;
    let url = `${CONTACT}?page=${pagination}&field=${sortBy}&sort_by=${sortOrder}`;
    if (filter.toLowerCase() !== 'all') {
      url = `${url}&status=${filter}`;
    }
    if (searchFieldText.length > 2) {
      url = `${url}&keyword=${encodeURIComponent(searchFieldText)}`;
      allContactsButtonUrl = `${allContactsButtonUrl}&search=${encodeURIComponent(
        searchFieldText,
      )}`;
    }
    const key = allContactsButtonUrl;
    this.setState({ loading: true });
    if (contactslistdata && contactslistdata[key]) {
      const stats = contactslistdata[key].data.count_data;
      const tabs = [];
      contactsPageTabs.map((each) => {
        const output = { ...each };
        output.label =
          stats[each.value] !== undefined
            ? `${output.label} | ${stats[each.value]}`
            : `${output.label} | 0`;
        output.noofrecords = stats[each.value];
        tabs.push(output);
        return each;
      });
      this.setState({
        tabs,
        listData: contactslistdata[key].data.data,
        totalPages: totalPages(filter, tabs, 'filterlabel', 15),
        totalRecords: totalRecords(filter, tabs, 'filterlabel'),
        offset: getOffSetsAndLimit(
          totalRecords(filter, tabs, 'filterlabel'),
          15,
        )[pagination - 1],
      });
    }
    Axios.get(url).then((res) => {
      if (
        res.status === 200 &&
        res.data.status &&
        res.data.status === 200 &&
        res.data.data &&
        res.data.count_data
      ) {
        const stats = res.data.count_data;
        const tabs = [];
        contactsPageTabs.map((each) => {
          const output = { ...each };
          output.label = stats[each.value]
            ? `${output.label} | ${stats[each.value]}`
            : `${output.label} | 0`;
          output.orginalLabel = each.label;
          output.noofrecords = stats[each.value];
          tabs.push(output);
          return each;
        });
        this.props.saveContactListData({
          key: allContactsButtonUrl,
          listData: res.data.data,
          data: res.data,
        });
        this.setState({
          tabs,
          listData: res.data.data?.length ? res.data.data : null,
          totalPages: totalPages(filter, tabs, 'value', 15),
          totalRecords: totalRecords(filter, tabs, 'value'),
          offset: getOffSetsAndLimit(totalRecords(filter, tabs, 'value'), 15)[
            pagination - 1
          ],
          loading: false,
        });
        this.handlepageChangeCheckbox();
        window.scrollTo(0, 0);
      } else {
        this.setState({
          listData: null,
          loading: false,
        });
      }
    });
    if (!componentContext) {
      this.props.navigate(allContactsButtonUrl, { replace: true });
      localStorage.setItem('allContactsButtonUrl', allContactsButtonUrl);
    }
  };

  clearFilters = () => {
    this.setState(
      {
        sortBy: 'last_name',
        sortOrder: 'asc',
        pagination: 1,
        filter: 'all',
        searchFieldText: '',
        activeTab: 0,
      },
      () => this.getRecords(),
    );
  };

  changeActiveTab = (tabIndex, tabDetails) => {
    this.setState(
      {
        selectedCheckbox: [],
        activeTab: tabIndex,
        filter: tabDetails.filterlabel,
        pagination: 1,
        loading: true,
      },
      () => this.getRecords(),
    );
  };

  handlePageChange = (input) => {
    this.setState(
      {
        pagination: input,
        loading: true,
      },
      () => this.getRecords(),
    );
  };

  handlepageChangeCheckbox = () => {
    const { selectedCheckbox } = this.state;
    const checkbox = document.getElementById('accSelectAll');
    let checkBoxCount = 0;
    const { listData } = this.state;
    listData.map((item) => {
      const checkedId = item.contact.id;
      selectedCheckbox.map((_item) => {
        const verifyChecked = _item.contact.id;
        if (checkedId === verifyChecked) {
          checkBoxCount += 1;
          item.checked = true;
        }
        return null;
      });
      return listData;
    });
    this.setState({});
    if (checkBoxCount === 0) {
      this.setState({ selectedAll: false });
      if (listData.length !== 0) {
        checkbox.indeterminate = false;
      }
    }
    if (checkBoxCount === listData.length) {
      this.setState({ selectedAll: true });
    }
    if (checkBoxCount > 0 && checkBoxCount < listData.length) {
      checkbox.indeterminate = true;
    }
  };

  handleSortBy = (sortBy) => {
    let { sortOrder } = this.state;
    if (this.state.sortBy === sortBy) {
      if (sortOrder === 'asc') {
        sortOrder = 'desc';
      } else {
        sortOrder = 'asc';
      }
    } else {
      sortOrder = 'asc';
    }

    this.setState(
      {
        sortOrder,
        sortBy,
        loading: true,
      },
      () => {
        if (this.state.searchFieldText.length > 2) {
          this.setState({
            loading: false,
            listData: sortContactList(
              this.state.listData,
              this.state.sortBy,
              this.state.sortOrder,
            ),
          });
        } else {
          this.getRecords();
        }
      },
    );
  };

  showContactDetails = (contactDetails, page) => {
    const { id, status } = contactDetails;
    if (page === 'edit' && status.toLowerCase() !== 'inactive') {
      return this.props.navigate(`/contact/edit/${id}`);
    }
    return `/contactdetails/${id}`;
  };

  handleSearchField = (input) => {
    const inputvalue = input && input.target.value ? input.target.value : '';
    this.setState({
      searchFieldText: inputvalue,
      loading: inputvalue.length > 2,
      totalRecords: 0,
      pagination: 1,
    });
    clearTimeout(this.typingTime);
    this.typingTime = setTimeout(() => {
      this.getRecords();
    }, 500);
  };

  showDeleteConfirmationModal = (input = {}) => {
    this.setState({
      recordToDelete: input,
      showDeleteConfirmation: !this.state.showDeleteConfirmation,
    });
  };

  deleteRecord = () => {
    Axios.put(`${DEACTIVATE_CONTACT}/${this.state.recordToDelete.id}`).then(
      (res) => {
        this.getRecords();
        this.setState({
          recordToDelete: {},
          showDeleteConfirmation: false,
          searchFieldText: '',
        });
        if (res.status === 204 || res.status === 200) {
          AlertMessage('success', res.data.message, 3000);
        } else {
          AlertMessage('error', res.data.message, 3000);
        }
      },
    );
  };

  sendInvite = (contact, index, sendForgotPassword) => {
    const { listData } = this.state;
    if (
      listData[index].inviterequestinprogress === undefined ||
      !listData[index].inviterequestinprogress
    ) {
      this.updateInviteRequestInprogress(true, index);
      let response = null;
      if (sendForgotPassword) {
        response = Axios.get(`${SEND_FORGOT_LINK}/${contact.cpa_user_id}`);
      } else {
        response = Axios.get(`${SEND_INVITE_LINK}/${contact.id}`);
      }
      response
        .then((res) => {
          this.updateInviteRequestInprogress(false, index);
          if (res.status === 200 && res.data.status === 200) {
            AlertMessage('success', res.data.message, 3000);
            if (sendForgotPassword) {
              this.getRecords();
            }
          } else {
            AlertMessage('error', res.data.message, 3000);
          }
        })
        .catch(() => {
          AlertMessage('error', 'Something went wrong', 3000);
          this.updateInviteRequestInprogress(false, index);
        });
    }
  };

  updateInviteRequestInprogress(value, index) {
    const { listData } = this.state;
    listData[index].inviterequestinprogress = value;
    this.setState({ listData });
  }

  generateCsvFile = () => {
    const { filter, totalRecords: noofrecords, searchFieldText } = this.state;
    let url = `${CONTACT}?page=1&field=last_name&sort_by=asc&noofrecords=${noofrecords}`;
    if (filter.toLowerCase() !== 'all') {
      url = `${url}&status=${filter}`;
    }
    if (searchFieldText && searchFieldText.length > 2) {
      generatingContactsCsvFile(
        this.state.listData,
        `${filter.toUpperCase()}_CONTACTS.csv`,
      );
    } else {
      this.setState({ loading: true });
      Axios.get(url).then((res) => {
        this.setState({ loading: false });
        if (res.data.status === 200 && res.data.data) {
          generatingContactsCsvFile(
            res.data.data,
            `${filter.toUpperCase()}_CONTACTS.csv`,
          );
        }
      });
    }
  };

  handleSelectAll = () => {
    let { selectedCheckbox } = this.state;
    const { selectedAll, listData } = this.state;
    let isAllChecked = 0;
    let checked = false;
    listData.map((item) => {
      if (item.checked) {
        isAllChecked += 1;
      }
      return item;
    });
    if (!selectedAll && isAllChecked === 0) {
      checked = true;
    }
    listData.map((item) => {
      const eachone = item;
      eachone.checked = checked;
      if (selectedCheckbox.length) {
        if (checked) {
          selectedCheckbox.push(eachone);
        } else {
          selectedCheckbox = removeItem(selectedCheckbox, eachone);
        }
      }
      return eachone;
    });
    if (!selectedCheckbox.length && checked) {
      selectedCheckbox = listData;
    }
    this.setState({ selectedCheckbox }, () => {
      this.areAllSelected();
    });
  };

  areAllSelected() {
    let x = 0;
    const { listData, componentContext, selectedCheckbox } = this.state;
    listData.map((e) => {
      if (e.checked) x += 1;
      return e;
    });
    const checkbox = document.getElementById('accSelectAll');
    if (x === listData.length && (x && listData.length) !== 0) {
      this.setState({ selectedAll: true });
      if (checkbox) checkbox.indeterminate = false;
    } else {
      this.setState({ selectedAll: false });
      if (checkbox) checkbox.indeterminate = true;
    }
    if (x === 0) {
      if (checkbox) checkbox.indeterminate = '';
    }

    // this for add to contact functionality in emails page
    if (componentContext) {
      this.props.getSelectedRecords(
        selectedCheckbox.map((item) => item.contact.id),
      );
    }
  }

  checkboxAction = (item, index) => {
    let { selectedCheckbox } = this.state;
    const { listData } = this.state;
    if (listData[index].checked) {
      listData[index].checked = false;
    } else {
      listData[index].checked = true;
    }
    if (listData[index].checked) {
      selectedCheckbox.push(listData[index]);
    } else {
      selectedCheckbox = removeItem(selectedCheckbox, listData[index]);
    }
    this.setState({ selectedCheckbox }, () => {
      this.areAllSelected();
    });
  };

  massArchive = (confirmed) => {
    const { selectedCheckbox, filter } = this.state;

    if (!confirmed) {
      this.setState({ confirmArchive: true });
    } else {
      // do mass archive
      const array = { ids: [] };
      selectedCheckbox.map((each) => {
        array.ids.push(each.contact.id);
        return array;
      });
      this.setState({
        confirmArchive: false,
        loading: true,
      });
      const archiveData = { ids: array.ids, type: 'contact' };
      const archiveAPI =
        filter === 'inactive' ? ACCOUNTS_UNARCHIVE : ACCOUNTS_ARCHIVE;
      const request = Axios.post(archiveAPI, archiveData);
      this.setState({ loading: true });
      request
        .then((response) => {
          if (response.data.status === 200) {
            AlertMessage('success', response.data.message, 3000);
            this.setState(
              {
                selectedCheckbox: [],
                selectedAll: false,
              },
              () => {
                this.getRecords();
              },
            );
          } else {
            AlertMessage('error', response.data.message, 3000);
          }
        })
        .catch(() => {
          AlertMessage('error', 'Some Error Occured', 3000);
        });
    }
  };

  render() {
    const { searchFieldText, componentContext } = this.state;
    if (this.state.unauthorisedAccess) {
      return <UnauthorisedFeature />;
    }
    const {
      activeTab,
      tabs,
      listData,
      offset,
      selectedCheckbox,
      filter,
      confirmArchive,
      selectedAll,
    } = this.state;
    const tableHeaders = [...contactPageTableHeaders];
    if (activeTab === 0 || activeTab === 3) {
      tableHeaders.splice(3, 0, activatedAt);
      tableHeaders.join();
    } else if (activeTab === 1) {
      tableHeaders.splice(3, 0, createdAt);
      tableHeaders.join();
    } else if (activeTab === 2) {
      tableHeaders.splice(3, 0, lastInviteSentAt);
      tableHeaders.join();
    } else if (activeTab === 5) {
      tableHeaders.splice(3, 0, deactivatedAt);
      tableHeaders.join();
    } else if (activeTab === 2 || activeTab === 4) {
      tableHeaders.splice(3, 0, inviteExpiredAt);
      tableHeaders.join();
    }
    if (getFirmType && getFirmType() === 'liscio-lite') {
      tableHeaders.pop();
    }
    return (
      <div className="AllContacts">
        <header className="pb-0 pt-0">
          <HeaderTabs
            data={tabs}
            activeTab={activeTab}
            handleClick={this.changeActiveTab}
            pageName="contact"
          />
        </header>
        {this.state.loading ? <div id="loading" /> : null}
        <div className="center-wrapper">
          <div className="filter-wrapper d-flex justify-content-between align-items-center">
            <div className="filterPart d-flex">
              <div className="form-group has-search">
                <span className="icon-zoom2 form-control-feedback" />
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  id="contact-search"
                  onChange={this.handleSearchField}
                  value={searchFieldText}
                  autoComplete="off"
                  data-testid="contact__search_input"
                />
              </div>
            </div>
            {!componentContext ? (
              <div className="FilterHeader--Action">
                <Button
                  className="btn btn-sm btn-outline-light mr-2"
                  onClick={this.generateCsvFile}
                  data-testid="contact__download_btn"
                >
                  <i className="icon-download2 p-0" aria-hidden="true" />
                </Button>
              </div>
            ) : null}
            {!componentContext ? (
              <div className="FilterHeader--Action align-self-start d-flex ml-2">
                {filter === 'all' ||
                filter === 'New' ||
                filter === 'Invited' ||
                filter === 'Active' ||
                filter === 'expired' ||
                filter === 'inactive' ? (
                  <button
                    type="button"
                    className="btn btn-primary mr-3"
                    onClick={() => this.massArchive(false)}
                    disabled={!selectedCheckbox.length}
                    data-testid="contact__archive_btn"
                  >
                    <i className="icon-archive" />{' '}
                    {filter === 'inactive' ? 'Unarchive' : 'Archive'}
                  </button>
                ) : null}
              </div>
            ) : null}
            <ConfirmationModal
              isOpen={confirmArchive}
              noBtnClick={() => this.setState({ confirmArchive: false })}
              headerText="Attention!"
              messageText={`${
                filter !== 'inactive'
                  ? 'The contact deactivation process will close all related open tasks and remove account relationships. Contacts will no longer have access to Liscio. Proceed?'
                  : `You are about to unarchive ${selectedCheckbox.length} ${
                      selectedCheckbox.length > 1 ? 'contacts.' : 'contact.'
                    } `
              } `}
              yesBtnClick={() => this.massArchive(true)}
              yesBtnText="Yes"
              noBtnText="No"
            />
            {(this.state.role && this.state.role === 'specialist') ||
            componentContext ? null : (
              <div className="FilterHeader--Action">
                <Link
                  to="/contact/new"
                  className="text-right btn btn-primary"
                  data-testid="contact__create_btn"
                >
                  <i>+</i> Contact
                </Link>
              </div>
            )}
          </div>
          {listData === undefined || listData === null ? <NoRecords /> : ''}
          {listData && listData !== null && listData.length ? (
            <section>
              <div className="tab-content">
                <div className="tab-pane active" id="review">
                  <div className="tableWrap">
                    <div className="tRow tRow--head">
                      <div className="row">
                        <span className="checkbox checkbox-primary check-container mx-2">
                          <Label htmlFor="accSelectAll">
                            <Input
                              name="accSelectAll"
                              id="accSelectAll"
                              type="checkbox"
                              checked={selectedAll}
                              key={this.state.pagination}
                              onChange={this.handleSelectAll}
                              data-testid="contact__all_checkbox"
                            />
                            <i className="checkmark" />
                          </Label>
                        </span>
                        {tableHeaders.map((each) => (
                          <div
                            key={each.value}
                            className={
                              sortingIcon(
                                this.state.sortBy,
                                each.value,
                                this.state.sortOrder,
                                'className',
                              ) +
                              each.className +
                              (!each.isSortable ? ' cursor-default' : '')
                            }
                            onClick={() =>
                              each.isSortable
                                ? this.handleSortBy(each.value)
                                : null
                            }
                          >
                            <span
                              className={
                                each.isSortable
                                  ? 'posRel'
                                  : 'posRel cursor-default'
                              }
                            >
                              {each.label}
                              <i
                                className={sortingIcon(
                                  this.state.sortBy,
                                  each.value,
                                  this.state.sortOrder,
                                )}
                              />
                            </span>
                          </div>
                        ))}
                        <div className="col thCol" />
                      </div>
                    </div>
                    <div className="tRow tRow--body">
                      {listData.map((each, key) => (
                        <div key={each.contact.id} className="row tdBtn">
                          <span
                            className="checkbox checkbox-primary check-container mx-2"
                            style={{ top: '2px' }}
                          >
                            <Label htmlFor={`chck ${each.contact.id}`}>
                              <Input
                                name="accSelectSingle"
                                id={`chck ${each.contact.id}`}
                                key={`chck ${each.contact.id}`}
                                checked={each.checked ? each.checked : false}
                                type="checkbox"
                                onChange={() => this.checkboxAction(each, key)}
                                data-testid={`contact__checkbox_${each?.contact?.id}`}
                              />
                              <i className="checkmark" />
                            </Label>
                          </span>
                          {!componentContext ? (
                            <>
                              <Link
                                className="col-2 tdCol text-body"
                                to={this.showContactDetails(each.contact)}
                              >
                                <span>
                                  {`${each.contact.last_name}, ${
                                    each.contact.first_name
                                  } ${
                                    each.contact.middle_name !== null
                                      ? each.contact.middle_name
                                      : ''
                                  }`}
                                </span>
                              </Link>
                              <Link
                                className="col-3 tdCol text-body"
                                to={this.showContactDetails(each.contact)}
                              >
                                <span>
                                  {each.contact.primary_email !== null &&
                                  each.contact.primary_email.length
                                    ? each.contact.primary_email
                                    : '-'}
                                </span>
                              </Link>
                              <Link
                                className="col-2 tdCol text-body"
                                to={this.showContactDetails(each.contact)}
                              >
                                <span>
                                  {each.contact_phone !== null
                                    ? FORMATPHONENUMBER(
                                        each.contact_phone[0].phone_number,
                                      )
                                    : '-'}
                                </span>
                              </Link>
                              {activeTab !== 4 && (
                                <Link
                                  className="col-2 tdCol text-body"
                                  to={this.showContactDetails(each.contact)}
                                >
                                  <span>
                                    {each.contact[tableHeaders[3].value] !==
                                    null
                                      ? moment(
                                          each.contact[tableHeaders[3].value],
                                        ).format('MM/DD/YYYY')
                                      : '-'}
                                  </span>
                                  <br />
                                </Link>
                              )}
                              {activeTab === 4 && (
                                <Link
                                  className="col-2 tdCol text-body"
                                  to={this.showContactDetails(each.contact)}
                                >
                                  <span>
                                    {each.invitation_expired_date
                                      ? moment(
                                          each.invitation_expired_date,
                                        ).format('MM/DD/YYYY')
                                      : '-'}
                                  </span>
                                  <br />
                                </Link>
                              )}
                              <Link
                                className="col-1 tdCol text-body"
                                to={this.showContactDetails(each.contact)}
                              >
                                <span>
                                  {getFirmType &&
                                  getFirmType() === 'liscio-lite'
                                    ? null
                                    : each.open_task}
                                </span>{' '}
                              </Link>
                            </>
                          ) : (
                            <>
                              <div
                                className="col-2 tdCol text-body"
                                onClick={() => this.checkboxAction(each, key)}
                              >
                                <span>
                                  {`${each.contact.last_name}, ${
                                    each.contact.first_name
                                  } ${
                                    each.contact.middle_name !== null
                                      ? each.contact.middle_name
                                      : ''
                                  }`}
                                </span>
                              </div>
                              <div
                                className="col-3 tdCol text-body"
                                onClick={() => this.checkboxAction(each, key)}
                              >
                                <span>
                                  {each.contact.primary_email !== null &&
                                  each.contact.primary_email.length
                                    ? each.contact.primary_email
                                    : '-'}
                                </span>
                              </div>
                              <div
                                className="col-2 tdCol text-body"
                                onClick={() => this.checkboxAction(each, key)}
                              >
                                <span>
                                  {each.contact_phone !== null
                                    ? FORMATPHONENUMBER(
                                        each.contact_phone[0].phone_number,
                                      )
                                    : '-'}
                                </span>
                              </div>
                              {activeTab !== 4 && (
                                <div
                                  className="col-2 tdCol text-body"
                                  onClick={() => this.checkboxAction(each, key)}
                                >
                                  <span>
                                    {each.contact[tableHeaders[3].value] !==
                                    null
                                      ? moment(
                                          each.contact[tableHeaders[3].value],
                                        ).format('MM/DD/YYYY')
                                      : '-'}
                                  </span>
                                  <br />
                                </div>
                              )}
                              {activeTab === 4 && (
                                <div
                                  className="col-2 tdCol text-body"
                                  onClick={() => this.checkboxAction(each, key)}
                                >
                                  <span>
                                    {each.invitation_expired_date
                                      ? moment(
                                          each.invitation_expired_date,
                                        ).format('MM/DD/YYYY')
                                      : '-'}
                                  </span>
                                  <br />
                                </div>
                              )}
                              <div
                                className="col-1 tdCol text-body"
                                onClick={() => this.checkboxAction(each, key)}
                              >
                                <span>
                                  {getFirmType &&
                                  getFirmType() === 'liscio-lite'
                                    ? null
                                    : each.open_task}
                                </span>{' '}
                              </div>
                            </>
                          )}
                          {!componentContext ? (
                            <div className="text-right col tdCol">
                              {this.state.role &&
                              this.state.role !== 'specialist' &&
                              each.contact.status !== null &&
                              each.contact.status.toLowerCase() !==
                                'inactive' ? (
                                <TooltipItem
                                  position="top"
                                  tooltipType="button"
                                  id={`editcontact${each.contact.id}`}
                                  text="Edit"
                                  btnClass="btn-sm btn--onlyicon btn-link tdBtn__hover"
                                  clickAction={() =>
                                    this.showContactDetails(
                                      each.contact,
                                      'edit',
                                    )
                                  }
                                  iconn="icon-edit"
                                />
                              ) : null}
                              {each.contact.status !== null &&
                              (each.contact.status.toLowerCase() === 'new' ||
                                each.contact.status.toLowerCase() ===
                                  'invited') &&
                              each.contact.primary_email !== null &&
                              each.contact.primary_email.length ? (
                                <TooltipItem
                                  position="top"
                                  tooltipType="button"
                                  id={`sendinvite${each.contact.id}`}
                                  text={
                                    each.contact.status.toLowerCase() === 'new'
                                      ? 'Send Invite'
                                      : 'Resend Invite'
                                  }
                                  btnClass="btn-sm btn--onlyicon btn-link tdBtn__hover"
                                  clickAction={() =>
                                    this.sendInvite(each.contact, key, false)
                                  }
                                  iconn="icon-reinvite"
                                />
                              ) : null}
                              {each.contact.status !== null &&
                              each.contact.status.toLowerCase() === 'active' &&
                              each.contact.primary_email !== null &&
                              each.contact.primary_email.length ? (
                                <TooltipItem
                                  position="top"
                                  tooltipType="button"
                                  id={`resetpassword${each.contact.id}`}
                                  text="Reset Password"
                                  btnClass="btn-sm btn--onlyicon btn-link tdBtn__hover"
                                  clickAction={() =>
                                    this.sendInvite(each.contact, key, true)
                                  }
                                  iconn="icon-reset-password"
                                />
                              ) : null}
                              {each.is_invitation_expired ? (
                                <TooltipItem
                                  position="top"
                                  tooltipType="button"
                                  id={`Inviteexpired${each.contact.id}`}
                                  text="Invite Expired"
                                  btnClass="btn-sm btn--onlyicon btn-link cursor-default"
                                  clickAction={() => {}}
                                  iconn="icon-expired text-danger"
                                />
                              ) : null}
                            </div>
                          ) : null}
                        </div>
                      ))}
                    </div>
                  </div>
                  {this.state.totalRecords ? (
                    <Pagination
                      currentPage={this.state.pagination}
                      totalPages={this.state.totalPages}
                      totalRecords={this.state.totalRecords}
                      handlePageChange={this.handlePageChange}
                      offset={offset[0]}
                      off={offset[1]}
                      pageName="contact"
                    />
                  ) : null}
                </div>
              </div>
            </section>
          ) : null}
        </div>
        {this.state.showDeleteConfirmation ? (
          <ConfirmationModal
            isOpen
            messageText="Attention: The contact deactivation process will close all open tasks related. Proceed?"
            yesBtnText="Proceed"
            noBtnText="Cancel"
            yesBtnClick={this.deleteRecord}
            noBtnClick={this.showDeleteConfirmationModal}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  contactslistdata: state.accountReducer.contactslistdata,
});

const mapDispatchToProps = (dispatch) => ({
  saveContactListData: (data) => dispatch(saveContactListData(data)),
});

export default withStorageData(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(AllContacts)),
);
