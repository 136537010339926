import {
  GOOGLE_OAUTH_REDIRECT_URL,
  MICROSOFT_OAUTH_REDIRECT_URL,
} from 'constants/constants.js';

export const getRedirectUri = (type: 'google' | 'outlook') => {
  if (type === 'google') {
    let redirectUriGoogle = GOOGLE_OAUTH_REDIRECT_URL;
    if (redirectUriGoogle && window.location.hostname.includes('localhost')) {
      redirectUriGoogle = 'https://localhost:3000/google';
    }
    return redirectUriGoogle;
  }

  if (type === 'outlook') {
    let redirectUriOutlook = MICROSOFT_OAUTH_REDIRECT_URL;
    if (redirectUriOutlook && window.location.hostname.includes('localhost')) {
      redirectUriOutlook = 'https://localhost:3000/outlook';
    }
    return redirectUriOutlook;
  }

  return '';
};

export default getRedirectUri;
