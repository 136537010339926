import React from 'react';

const TableLoader = () => (
  <>
    {/* Table Header */}
    <rect x="10" y="110" rx="3" ry="3" width="150" height="20" />
    <rect x="200" y="110" rx="3" ry="3" width="150" height="20" />
    <rect x="400" y="110" rx="3" ry="3" width="150" height="20" />
    <rect x="600" y="110" rx="3" ry="3" width="150" height="20" />
    <rect x="800" y="110" rx="3" ry="3" width="150" height="20" />

    {/* row 1 */}
    <rect x="10" y="160" rx="3" ry="3" width="90" height="10" />
    <rect x="200" y="160" rx="3" ry="3" width="90" height="10" />
    <rect x="400" y="160" rx="3" ry="3" width="90" height="10" />
    <rect x="600" y="160" rx="3" ry="3" width="90" height="10" />
    <rect x="800" y="160" rx="3" ry="3" width="90" height="10" />

    {/* row 2 */}
    <rect x="10" y="210" rx="3" ry="3" width="90" height="10" />
    <rect x="200" y="210" rx="3" ry="3" width="90" height="10" />
    <rect x="400" y="210" rx="3" ry="3" width="90" height="10" />
    <rect x="600" y="210" rx="3" ry="3" width="90" height="10" />
    <rect x="800" y="210" rx="3" ry="3" width="90" height="10" />

    {/* row 3 */}
    <rect x="10" y="260" rx="3" ry="3" width="90" height="10" />
    <rect x="200" y="260" rx="3" ry="3" width="90" height="10" />
    <rect x="400" y="260" rx="3" ry="3" width="90" height="10" />
    <rect x="600" y="260" rx="3" ry="3" width="90" height="10" />
    <rect x="800" y="260" rx="3" ry="3" width="90" height="10" />

    {/* row 4 */}
    <rect x="10" y="310" rx="3" ry="3" width="90" height="10" />
    <rect x="200" y="310" rx="3" ry="3" width="90" height="10" />
    <rect x="400" y="310" rx="3" ry="3" width="90" height="10" />
    <rect x="600" y="310" rx="3" ry="3" width="90" height="10" />
    <rect x="800" y="310" rx="3" ry="3" width="90" height="10" />

    {/* row 5 */}
    <rect x="10" y="360" rx="3" ry="3" width="90" height="10" />
    <rect x="200" y="360" rx="3" ry="3" width="90" height="10" />
    <rect x="400" y="360" rx="3" ry="3" width="90" height="10" />
    <rect x="600" y="360" rx="3" ry="3" width="90" height="10" />
    <rect x="800" y="360" rx="3" ry="3" width="90" height="10" />
  </>
);

export default TableLoader;
