import React from 'react';

const RatingComponent = (props) => {
  const rating = [5, 4, 3, 2, 1];
  return (
    <span className="starRating">
      {rating.map((e) => (
        <i
          key={e}
          className={`icon-Star ${
            e.toString() === props.rating ? 'active' : ''
          }`}
        />
      ))}
    </span>
  );
};

export default RatingComponent;
