import {
  AccountTreeOutlined,
  BackupOutlined,
  DeleteOutline,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  outlinedInputClasses,
  useTheme,
} from '@mui/material';
import { WorkflowItemType } from 'components/Requests/requests.types';
import yn from 'images/icons/yn.svg';
import squiggly from 'images/icons/squiggle.svg';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useWorkflowItemInput } from 'components/Requests/components/RequestsBuilder/hooks/workflow-item-input-hook';
import { SectionItemTuple } from 'components/Requests/components/RequestsBuilder/components/QuestionsBuilder';
import { ConditionsEditor } from 'components/Requests/components/RequestsBuilder/components/ConditionalEditor/ConditionsEditor';
import { useRequestBuilderContext } from 'components/Requests/components/RequestsBuilder/context/RequestsBuilderContext';
import { useState } from 'react';

export interface QuestionEditorProps {
  onInputChange: (value: string) => void;
  onTypeChange?: (type: WorkflowItemType) => void;
  id: string;
  onDelete: (index: SectionItemTuple) => void;
  questionNumber?: number;
  deleteDisabled?: boolean;
  defaultValue: string;
  type?: WorkflowItemType;
}

export const QuestionEditor = ({
  onInputChange,
  onTypeChange,
  id,
  questionNumber,
  onDelete,
  deleteDisabled,
  defaultValue,
  type,
}: QuestionEditorProps) => {
  const theme = useTheme();
  const { requestsPageFeatureConditionals } = useFlags();
  const inputProps = useWorkflowItemInput({
    onSubmit: onInputChange,
  });
  const {
    activeQuestionIndex: questionIndex,
    errors,
    isSubmitting,
    isErrored,
  } = useRequestBuilderContext();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  let svg = <AccountTreeOutlined />;
  switch (type) {
    case WorkflowItemType.Boolean:
      svg = <img src={yn} alt="" />;
      break;
    case WorkflowItemType.Text:
      svg = <img src={squiggly} alt="" />;
      break;
    case WorkflowItemType.Upload:
      svg = <BackupOutlined />;
      break;
    default:
      svg = <AccountTreeOutlined />;
      break;
  }

  const openDeleteDialog = () => setIsDeleteDialogOpen(true);
  const closeDeleteDialog = () => setIsDeleteDialogOpen(false);
  const handleDelete = () => {
    setIsDeleteDialogOpen(false);
    onDelete(questionIndex);
  };

  // used to set the limit of sections to 500
  const maxLength = type ? {} : { maxLength: 150 };

  return (
    <Box
      marginTop="10px"
      bgcolor="common.secondary.50"
      borderRadius="2px"
      padding="10px 20px"
      border={`1px solid ${theme.palette.common.secondary[200]}`}
      display="flex"
      flexGrow={0}
    >
      <Box width="100%">
        <Stack direction="row" alignItems="center">
          {type && (
            <Typography width="3rem" fontWeight={700}>
              {questionNumber}.
            </Typography>
          )}
          <Box width="100%" display="flex" alignItems="center" gap="20px">
            {svg}
            {type && (
              <FormControl>
                <Select
                  value={type}
                  onChange={({ target: { value } }) =>
                    onTypeChange?.(value as WorkflowItemType)
                  }
                  error={Boolean(errors[`${id}.type`])}
                  sx={{
                    [`& .${outlinedInputClasses.notchedOutline}`]: {
                      borderColor: errors[`${id}.type`]
                        ? theme.palette.error.main
                        : 'transparent',
                    },
                  }}
                >
                  <MenuItem value={WorkflowItemType.Boolean}>
                    <Typography fontWeight={700}>Yes/No</Typography>
                  </MenuItem>
                  <MenuItem value={WorkflowItemType.Text}>
                    <Typography fontWeight={700}>Short Answer</Typography>
                  </MenuItem>
                  <MenuItem value={WorkflowItemType.Upload}>
                    <Typography fontWeight={700}>File Upload</Typography>
                  </MenuItem>
                </Select>
              </FormControl>
            )}
            {!type && <Typography fontWeight={700}>New Section</Typography>}
            <IconButton
              aria-label="Delete"
              sx={{ marginLeft: 'auto' }}
              onClick={openDeleteDialog}
              disabled={deleteDisabled || isSubmitting || isErrored}
            >
              <DeleteOutline fontSize="small" />
            </IconButton>
          </Box>
        </Stack>
        <Typography variant="body2" fontWeight={700}>
          {id ? 'QUESTION' : 'NAME'}
        </Typography>
        <TextField
          defaultValue={defaultValue}
          autoFocus
          hiddenLabel
          variant="outlined"
          inputProps={{
            'aria-label': 'Question input',
            ...maxLength,
            ...inputProps,
          }}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          InputProps={{
            sx: { backgroundColor: 'common.white' },
          }}
          fullWidth
          sx={{ width: '35rem' }}
          error={Boolean(errors[id])}
          helperText={errors[id]}
        />
        {requestsPageFeatureConditionals &&
          type === WorkflowItemType.Boolean &&
          questionNumber && (
            <ConditionsEditor
              key={`conditionEditor ${questionIndex[0]} ${questionIndex[1]}`}
              activeUUID={id}
              number={questionNumber}
            />
          )}
      </Box>
      <Dialog
        open={isDeleteDialogOpen}
        onClose={closeDeleteDialog}
        aria-labelledby="Delete Workflow Item"
      >
        <DialogContent>
          {!type ? (
            <Typography variant="body1">
              Are you sure you want to delete this section? All questions inside
              will also be deleted.
            </Typography>
          ) : (
            <Typography variant="body1">
              Are you sure you want to delete this question?
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            onClick={closeDeleteDialog}
          >
            Cancel
          </Button>
          <Button variant="contained" onClick={handleDelete}>
            Yes, Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
