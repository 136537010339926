import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import LinkedAccounts from './LinkedAccounts';

const EmRight = styled.div`
  .EmRight__Bio {
    border-bottom: 1px solid #e0e1e2;
  }
`;

const EmailLinedWith = ({
  refreshPage,
  data,
  emailId,
  entityEmailThreadId,
  accountEmailLinkedBox,
  contactEmailLinkedBox,
  emailLinkedBox,
  contactAccountLinked,
  accountContactLinked,
}) => {
  const [emailLinkedWith, setEmailLinkedWith] = useState(data || []);
  const [enbleAccountBox, setEnableAccountBox] = useState(
    accountEmailLinkedBox || false,
  );
  const [enbleContactBox, setEnableContactBox] = useState(
    contactEmailLinkedBox || false,
  );
  useEffect(() => {
    setEnableAccountBox(accountEmailLinkedBox);
    setEnableContactBox(contactEmailLinkedBox);
  }, [accountEmailLinkedBox, contactEmailLinkedBox]);

  useEffect(() => {
    setEmailLinkedWith(data);
  }, [data]);

  return (
    <EmRight>
      {emailLinkedWith?.accounts?.length > 0 || enbleAccountBox ? (
        <div className="EmRight__Bio">
          <LinkedAccounts
            initialValue={
              emailLinkedWith?.accounts?.length > 0
                ? emailLinkedWith.accounts
                : []
            }
            emailId={emailId}
            entityEmailThreadId={entityEmailThreadId}
            entityType="account"
            refreshPage={refreshPage}
            enableMode={enbleAccountBox ? 'edit' : 'view'}
            emailLinkedBox={emailLinkedBox}
            accountContactLinked={accountContactLinked}
            emailLinkedWith={emailLinkedWith}
          />
        </div>
      ) : null}
      {emailLinkedWith?.contacts?.length > 0 || enbleContactBox ? (
        <div className="EmRight__Bio">
          <LinkedAccounts
            initialValue={
              emailLinkedWith?.contacts?.length > 0
                ? emailLinkedWith.contacts
                : []
            }
            emailId={emailId}
            entityEmailThreadId={entityEmailThreadId}
            entityType="contact"
            refreshPage={refreshPage}
            enableMode={enbleContactBox ? 'edit' : 'view'}
            emailLinkedBox={emailLinkedBox}
            contactAccountLinked={contactAccountLinked}
            emailLinkedWith={emailLinkedWith}
          />
        </div>
      ) : null}
    </EmRight>
  );
};

export default EmailLinedWith;
