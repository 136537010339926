/* eslint-disable class-methods-use-this */
/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-plusplus */
/* eslint-disable react/jsx-curly-brace-presence */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'utilities/withRouterWrapper';
import moment from 'moment';
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import * as actions from 'actions/NoteActions';
import axios from 'axios';
import { Line } from 'rc-progress';
import Notes from 'api/Notes';
import UploadFiles from 'api/UploadFiles';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { AttachementPortion } from 'common/CustomFields';
import {
  getCpaPreferences,
  getFirmType,
  fileType,
  getStorageData,
} from 'utilities/utils';
import { NUMBER } from 'utilities/regularexpressions';
import PageNotFound from 'common/PageNotFound';
import PdfViewer from 'components/PdfViewer/PdfViewer';
import Menu from 'common/Menu';
import ReadOnlyTinyMCE from 'common/ReadOnlyTinyMCE/ReadOnlyTinyMCE';
import NotesPdfLayout from './NotesPdfLayout';

class NoteDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      uploadFiles: [],
      commentIdHash: [],
      account: '',
      accountId: '',
      comment: '',
      contact: '',
      contactId: '',
      contactAvatar: '',
      contactInitials: '',
      createdBy: '',
      createdByAvatar: '',
      date: '',
      documents: [],
      noteId: '',
      noteActive: '',
      referenceDate: '',
      subject: '',
      noteStatus: '',
      noNote: false,
      showDetail: true,
      homedata: {},
      showAttachmentModal: false,
      selectedAttachment: '',
      delAttachmentModal: false,
      confirmationModal: false,
      attachmentDetails: '',
      copied: false,
      unauthorized: false,
      attachmentId: undefined,
      showModal: false,
      type: 'notesdetail',
      errors: {},
      yearId: '',
      yearValue: '',
      selectedTags: [],
      selectedDocuments: [],
      isFileModalSubmit: false,
      popUp: false,
      newDocs: [],
      previewPopUp: true,
      // previewScreen: false,
      // previewDocument: null,
      uploadedFilesLength: 0,
      uploadedFilesCount: 0,
      monthId: '',
      monthValue: '',
    };
    this.fetchnoteDetail = this.fetchnoteDetail.bind(this);
    this.showCopy = this.showCopy.bind(this);
    this.startUpload = this.startUpload.bind(this);
    this.archiveNote = this.archiveNote.bind(this);
  }

  componentDidMount() {
    if (getFirmType() === 'liscio-lite') {
      this.props.navigate('/');
    }
    this.fetchnoteDetail(true);
    // TODO: __HOMEDATA-AUDIT
    const homeDataObj = getStorageData();
    if (homeDataObj) {
      this.setState({ homedata: homeDataObj });
    }
  }

  componentWillUnmount() {
    this.props.actions.fetchDetail({});
  }

  setAttachmentNull = () => {
    const uploadFile = document.getElementById('upload_doc');
    uploadFile.value = null;
  };

  showCopy() {
    this.setState({ copied: true });
    setTimeout(() => {
      this.setState({ copied: false });
    }, 3000);
  }

  fetchnoteDetail(loading) {
    let noteId;
    if (this.props.id) {
      noteId = this.props.id;
    } else {
      const url = window.location.pathname.split('/');
      if (url) {
        noteId = url[url.length - 1];
      }
    }
    this.props.actions.fetchnoteDetail(noteId, this, loading);
  }

  handleFileDrop = (files, e) => {
    if (e) {
      const uploadFile = files;
      this.startUpload(uploadFile);
      this.setState({ popUp: true });
    }
  };

  uploadDocument = () => {
    const uploadFile = document.getElementById('upload_doc').files;
    this.startUpload(uploadFile);
  };

  showModal = (nid, type) => {
    if (type === 'attachment') {
      this.setState({ attachmentId: nid, showModal: true });
    }
  };

  // attachmentSelect = () => {
  //   this.setState({ popUp: true });
  // };

  cancelUpload(filename) {
    this.state[`${filename}sourceToken`].cancel();
  }

  startUpload(uploadFile) {
    let i;
    const component = this;
    let maxsizeInBytes = 629145600;
    let maxsizeInMb = 600;
    if (
      getCpaPreferences() &&
      getCpaPreferences().attachment &&
      getCpaPreferences().attachment[0].label &&
      NUMBER.test(getCpaPreferences().attachment[0].label)
    ) {
      maxsizeInBytes = getCpaPreferences().attachment[0].label * 1024 * 1024;
      maxsizeInMb = getCpaPreferences().attachment[0].label;
    }
    component.setState({
      isDocumentUpload: true,
      uploadedFilesLength: this.state.uploadedFilesLength + uploadFile.length,
    });
    const onUploadProgress = (uploadedFile) => (progress) => {
      const percentage = Math.floor((progress.loaded * 100) / progress.total);
      const key = `percentCompleted${uploadedFile}`;
      this.setState({ [key]: percentage });
    };
    for (i = 0; i < uploadFile.length; i += 1) {
      const data = new FormData();
      const { match } = this.props;
      const { id } = match.params;
      data.append('aws_url', uploadFile[i], uploadFile[i].name);
      data.append('note_id', id);
      if (uploadFile[i].size > maxsizeInBytes) {
        alert(`File exceeds limit of ${maxsizeInMb}MB`);
        return false;
      }
      const { CancelToken } = axios;
      const source = CancelToken.source();
      // const files = component.state.uploadFiles;
      const key = `${uploadFile[i].name}sourceToken`;
      // files.push(uploadFile[i].name);
      const fileSize = uploadFile[i].size;
      const fileSizeKey = `fileSize${uploadFile[i].name}`;
      this.setState({ [key]: source, [fileSizeKey]: fileSize });
      this.state.uploadFiles.push(uploadFile[i].name);
      const config = {
        headers: { 'content-type': 'multipart/form-data' },
        cancelToken: source.token,
        onUploadProgress: onUploadProgress(uploadFile[i].name),
      };
      const noteid = this.state.noteId;
      Notes.addNoteDocument(
        data,
        component,
        noteid,
        config,
        uploadFile[i].name,
      );
      if (component.state.errors.documents) {
        const err = {};
        err.documents = '';
        component.setState({ errors: err });
      }
    }
    return true;
  }

  editNote() {
    let noteId;
    if (this.props.id) {
      noteId = this.props.id;
    } else {
      const url = window.location.pathname.split('/');
      if (url) {
        noteId = url[url.length - 1];
      }
    }
    this.props.navigate(`/note/edit/${noteId}`, {
      state: { from: '/notedetail' },
    });
  }

  // downloadAttachments() {
  //   const url = this.state.documents;
  //   const zip = new JSZip();
  //   let count = 0;
  //   url.map((item) => {
  //     const urlPath = item.aws_url_origional;
  //     const { fileName } = url;
  //     JSZipUtils.getBinaryContent(urlPath, (err, data) => {
  //       if (err) {
  //         throw err;
  //       }
  //       const convertTime = moment()
  //         .add(moment().utcOffset(), 'minutes')
  //         .format('YYYY-MM-DD HH:mm:ss');
  //       zip.file(fileName, data, {
  //         binary: true,
  //         createFolders: false,
  //         date: new Date(convertTime),
  //       });
  //       count = count++;
  //       if (count === url.length) {
  //         zip.generateAsync({ type: 'blob' }).then((content) => {
  //           FileSaver.saveAs(content, 'all_docs.zip');
  //         });
  //       }
  //     });
  //     return true;
  //   });
  // }

  archiveNote() {
    let noteId;
    if (this.props.id) {
      noteId = this.props.id;
    } else {
      const url = window.location.pathname.split('/');
      if (url) {
        noteId = url[url.length - 1];
      }
    }
    Notes.archiveNote(noteId, this);
  }

  toggle = () => {
    this.setState({ showDetail: false });
    if (this.props.closeNoteDetailsModal) {
      this.props.closeNoteDetailsModal();
    } else {
      this.props.navigate(-1);
    }
  };

  showProgressBar() {
    const { uploadFiles } = this.state;
    let progressbar;
    if (uploadFiles !== undefined) {
      progressbar = uploadFiles.map((item) => {
        const returnValue = [];
        const percentCompleted = this.state[`percentCompleted${item}`];
        const getSize = this.state[`fileSize${item}`] / 1024;
        const trimSize = getSize.toFixed(2);
        const fileSize = this.state[`fileSize${item}`]
          ? `${trimSize} KB`
          : null;
        returnValue.push(
          <div key={item} className="file-drop-upload file-drop-inprogress">
            <div className="file-drop-type">
              <i className="icon-filetype-pdf" aria-hidden="true" />
            </div>
            <div className="file-drop-info">
              <div className="file-drop-detail">
                <span className="fileName">{`${item} (${percentCompleted}%)`}</span>
                <span className="fileweight">{fileSize}</span>
                <Line
                  percent={percentCompleted}
                  strokeWidth="4"
                  strokeColor="#767676"
                />
              </div>
              {percentCompleted !== 100 ? (
                <a
                  className="file-action"
                  onClick={() => this.cancelUpload(item)}
                >
                  <i className="icon-close2" />
                </a>
              ) : null}
            </div>
          </div>,
        );
        return returnValue;
      });
      return progressbar;
    }
  }

  deleteAttachment() {
    const id = this.state.attachmentDetails;
    const noteId = this.props.id;
    this.setState({ delAttachmentModal: false });
    Notes.deleteAttachment(id, this, noteId);
  }

  openAttachment = (c) => {
    UploadFiles.showOpenAttachment(c, this);
  };

  // previewOpenAttachmenr = (doc) => {
  //   this.setState({ previewScreen: true, previewDocument: doc });
  // }
  // previewCloseAttachment = (data) => {
  //   if (data.editedFileName) {
  //     if (this.state.documents.length) {
  //       const docIds = [];
  //       let x;
  //       for (x = 0; x < this.state.documents.length; x += 1) {
  //         if (this.state.documents[x].id === data.selectedItem.id) {
  //           const doc = this.state.documents;
  //           const filename = data.editedFileName;
  //           doc[x].doc_name = filename;
  //           this.setState({ documents: doc });
  //         }
  //       }
  //     }
  //   }
  //   this.setState({ previewScreen: false, previewDocument: null });
  // }
  attachmentModalClose() {
    this.setState({ showAttachmentModal: false, selectedAttachment: '' });
  }

  delAttachmentModal = (item) => {
    this.setState({ delAttachmentModal: true, attachmentDetails: item.id });
  };

  closeDelModal() {
    this.setState({ delAttachmentModal: false });
  }

  // errorDoc = () => {
  //   const err = {};
  //   err.documents = 'Please select document';
  //   this.setState({
  //     errors: err,
  //   });
  // };

  // openPopUp = () => {
  //   this.setState({ popUp: !this.state.popUp });
  // };

  // stateUpdate = (value, changetype = '', data = '') => {
  //   const stateUpdatedValues = { ...value };
  //   this.setState({
  //     stateUpdatedValues,
  //   });
  //   if (changetype === 'isFileModalTaskDetail' && data) {
  //     this.setState({
  //       newDocs: [],
  //       errors: {},
  //       yearId: value.yearId,
  //       yearValue: value.yearValue,
  //       monthId: value.monthId,
  //       monthValue: value.monthValue,
  //       selectedTags: data.tags,
  //       selectedDocuments: data.doc_ids,
  //       isFileModalSubmit: true,
  //     });
  //   }
  //   if (changetype === 'isFileModalSubmit' && data) {
  //     this.setState({
  //       yearId: value.yearId,
  //       yearValue: value.yearValue,
  //       monthId: value.monthId,
  //       monthValue: value.monthValue,
  //       selectedTags: data.tags,
  //       selectedDocuments: data.doc_ids,
  //       isFileModalSubmit: true,
  //     });
  //   }
  // };

  handlePrintPdf = () => {
    const { renderPdfLayout } = this.state;
    this.setState({ renderPdfLayout: !renderPdfLayout });
  };

  renderDocuments() {
    let documents = '';
    if (this.state.documents && this.state.documents.length) {
      documents = this.state.documents.map((item) => {
        const logo = fileType(item);
        const getSize = item.file_size / 1024;
        const trimSize = getSize.toFixed(2);
        return (
          <div key={`para${item.id}`} className="file-drop-upload pl-0">
            <div className="file-drop-type">
              <i className={logo} aria-hidden="true" />
            </div>
            <div className="file-drop-info">
              <div className="file-drop-detail">
                <h6 href="#" className="fileName" key={`document${item.id}`}>
                  {item.is_active ? (
                    <a
                      className="fileName pr-0"
                      key={`doc${item.id}`}
                      onClick={() => this.openAttachment(item)}
                    >
                      {item.doc_name}
                    </a>
                  ) : (
                    <span className="text-dark">{item.doc_name}</span>
                  )}
                </h6>
              </div>
              {item.is_active ? (
                <div>
                  <a
                    className="previewFile file-action"
                    key={`doc${item.id}`}
                    onClick={() => this.openAttachment(item)}
                  >
                    <i className="icon-open-eye" /> Preview
                  </a>
                  <span className="file-action">{trimSize} KB</span>
                </div>
              ) : null}
              {item.is_active ? (
                <div>
                  <a
                    className="file-action"
                    key={`delete${item.id}`}
                    onClick={() => this.delAttachmentModal(item)}
                  >
                    <i className="icon-close2" />
                  </a>
                </div>
              ) : (
                <span className="text-dark">Deleted</span>
              )}
            </div>
          </div>
        );
      });
    }
    return documents;
  }

  handleMenuItemClick = (e) => {
    if (e === 'edit' || e?.value === 'edit') {
      this.editNote();
    } else if (e === 'print' || e?.value === 'print') {
      this.handlePrintPdf();
    }
  };

  render() {
    const noneStyle = { display: 'none' };
    const blockStyle = { display: 'block' };
    const isIE = /* @cc_on!@ */ false || !!document.documentMode;

    const keys = Object.keys(this.props.noteDetail);
    const {
      account,
      comment_for_web_display,
      contact,
      initials,
      avatar,
      created_by,
      created_by_avatar,
      reference_date,
      subject,
    } = this.props.noteDetail;
    const created_by_initials =
      this.props.noteDetail && this.props.noteDetail.created_by
        ? (created_by.split(' ')[0] ? created_by.split(' ')[0].charAt(0) : '') +
          (created_by.split(' ')[1] ? created_by.split(' ')[1].charAt(0) : '')
        : '';
    const { noNote, renderPdfLayout, noteId } = this.state;
    if (this.state.unauthorized) {
      return <PageNotFound name="message" message="(Unauthorized Access)" />;
    }
    if (noNote) {
      return <PageNotFound name="message" message="(No Such Note Found)" />;
    }
    if (keys.length && !noNote) {
      return (
        <div>
          <div
            id="loading"
            style={this.state.loading ? blockStyle : noneStyle}
          />
          <Modal className="customModal" isOpen={this.state.showDetail}>
            <div>
              <div className="d-flex justify-content-between align-items-center ModalHeader">
                <div className="date infoHeader">
                  <i className="icon-calendar" aria-hidden="true" />
                  {moment(reference_date).format('MM/DD/YY')}
                </div>
                <div className="ModalHeader--links">
                  <div className="d-flex flex-nowrap btn--leftspace">
                    <CopyToClipboard
                      text={`${window.location.origin}/notedetails/${noteId}`}
                      onCopy={this.showCopy}
                    >
                      <a className="btn btn-sm btn-outline-light mr-2">
                        {this.state.copied ? 'Copied' : 'Copy Link'}
                      </a>
                    </CopyToClipboard>
                    <Menu
                      menuItems={[
                        {
                          label: 'Edit',
                          value: 'edit',
                        },
                        {
                          label: 'Print',
                          value: 'print',
                        },
                      ]}
                      handleMenuItemClick={this.handleMenuItemClick}
                    />
                  </div>
                </div>
                <button
                  type="button"
                  onClick={this.toggle}
                  className="close"
                  aria-label="Close"
                >
                  <i className="icon-close2" aria-hidden="true" />
                </button>
              </div>
            </div>
            <ModalBody>
              <div className="modalContent--wrap">
                <div className="modalContent--inner">
                  <div className="modalContent--Header contentHasicon">
                    <div className="modalContent--HeaderInner">
                      {subject ? (
                        <h2 className="mainHeading mb-0">{subject}</h2>
                      ) : null}
                    </div>
                  </div>
                  <div className="formsetValue">
                    <div className="form-group form-inline-label">
                      <label htmlFor="account">For Account</label>
                      <div className="labelValue">
                        <p>{account === 'N/A' ? '' : account}</p>
                      </div>
                    </div>
                  </div>
                  <div className="form-group form-inline-label">
                    <label htmlFor="contact">For Contact</label>
                    <div className="labelValue">
                      <div className="selectedUser avatar avatar--w20 avatar--border">
                        {avatar ? (
                          <img
                            src={avatar}
                            alt=" "
                            width="20"
                            height="20"
                            className="rounded-circle"
                          />
                        ) : !avatar && contact ? (
                          <span className="avatar avatar--w20">
                            <span className="uName">{initials}</span>
                          </span>
                        ) : (
                          ''
                        )}
                        <span>{contact === 'N/A' ? '' : contact}</span>
                      </div>
                    </div>
                  </div>
                  <div className="form-group form-inline-label">
                    <label htmlFor="created_by">Last Modified By</label>
                    <div className="labelValue">
                      <div className="avatar avatar--w20 avatar--border avatar--borderPrimary">
                        {created_by_avatar ? (
                          <img
                            src={created_by_avatar}
                            alt=" "
                            width="20"
                            height="20"
                            className="rounded-circle"
                          />
                        ) : (
                          <span className="avatar avatar--w20">
                            <span className="uName">{created_by_initials}</span>
                          </span>
                        )}
                        <span>{created_by === 'N/A' ? '' : created_by}</span>
                      </div>
                    </div>
                  </div>
                  <div className="form-group form-inline-label">
                    <label htmlFor="backdrop" className="align-self-start">
                      Description
                    </label>
                    <div className="labelValue">
                      {/* <p dangerouslySetInnerHTML={{ __html: comment }} /> */}
                      <div className="noteDescription">
                        <ReadOnlyTinyMCE
                          content={comment_for_web_display
                            .replace(
                              /<span style="width: 100%"> <br> {2}<\/span>/g,
                              '<p></p>',
                            )
                            .replace(
                              /<span style="width: 100%"><br><\/span>/g,
                              '<p></p>',
                            )
                            .replace(
                              /<div style="width: 100%"> <br> {2}<\/div>/g,
                              '<p></p>',
                            )
                            .replace(
                              /<div style="width: 100%"><br><\/div>/g,
                              '<p></p>',
                            )}
                        />
                      </div>
                    </div>
                  </div>
                  {this.showProgressBar()}
                  {this.state.documents.length > 0 ? (
                    <div className="form-group form-inline-label">
                      <label
                        htmlFor="backdrop"
                        className="align-self-start mt-3"
                      >
                        Attachments
                      </label>
                      <div className="labelValue w-100">
                        <div className="attached-file-list">
                          {this.renderDocuments()}
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </ModalBody>
          </Modal>
          <Modal
            isOpen={this.state.showAttachmentModal}
            className="customModal customModal--w990 customModal--nopadding"
          >
            <ModalHeader className="ModalHeader">
              <button
                type="button"
                onClick={() => this.attachmentModalClose()}
                className="close"
                aria-label="Close"
              >
                <i className="icon-close2" aria-hidden="true" />
              </button>
            </ModalHeader>
            <ModalBody className="modalContent">
              <div className="modalContent--inner">
                <div
                  className={
                    isIE ? 'left-inner-content' : 'sample-letter-preview'
                  }
                >
                  <div className="letter-logo" />
                  {isIE ? (
                    <PdfViewer file={this.state.selectedAttachment} />
                  ) : (
                    <iframe
                      width="100%"
                      height="600"
                      src={this.state.selectedAttachment}
                      title="selected attachment"
                    />
                  )}
                </div>
              </div>
            </ModalBody>
          </Modal>
          <Modal
            isOpen={this.state.delAttachmentModal}
            className="customModal customModal--center customModal--alert customModal--w500 customModal--nopadding"
          >
            <div className="ModalHeader">
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={() => this.closeDelModal()}
              >
                <i className="icon-close2" aria-hidden="true" />
              </button>
            </div>
            <ModalBody className="modalContent">
              <div className="modalContent--inner">
                <div className="modalContent--header text-large">
                  <h5 className="modalContent--header__heading">
                    You are about to delete the attached file.
                  </h5>
                </div>
                <p>This action cannot be undone. Proceed?</p>
                <div className="row">
                  <div className="col-12 justify-content-end formbtn justify-content-end">
                    <div className="btn-wrap btn--leftspace">
                      <Button
                        color="secondary"
                        className="btn btn-outline-light"
                        onClick={() => this.closeDelModal()}
                      >
                        No
                      </Button>{' '}
                      <Button
                        color="primary"
                        className="btn btn-primary"
                        onClick={() => this.deleteAttachment()}
                      >
                        Yes
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </ModalBody>
          </Modal>
          {this.state.popUp ? (
            <AttachementPortion
              name="notesdetail"
              handleFileDrop={this.handleFileDrop}
              data={this.state}
              setAttachmentNull={this.setAttachmentNull}
              uploadPopUp="true"
              uploadDocument={this.uploadDocument}
              uploadFiles={this.state.uploadFiles}
              documents={this.state.documents}
              cancelUpload={this.cancelUpload}
              openAttachment={this.openAttachment}
              delAttachmentModal={this.delAttachmentModal}
              showModal={this.showModal}
              component={this}
              notedetail={this.props.noteDetail}
            />
          ) : null}
          {renderPdfLayout ? (
            <NotesPdfLayout
              data={{ ...this.state, ...this.props.noteDetail }}
              handlePrintPdf={this.handlePrintPdf}
            />
          ) : null}
        </div>
      );
    }
    return (
      <div id="loading" style={this.state.loading ? blockStyle : noneStyle} />
    );
  }
}

const mapStateToProps = (state) => ({
  noteDetail: state.noteReducer.noteDetail,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NoteDetail),
);
