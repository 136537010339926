import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom-latest';
import { GET_CONT_EMP_OF_ACCOUNT_API_PATH } from 'constants/constants';
import DropDown from './Dropdown';

const FilterByRecipient = ({
  selectedRecepientData,
  setSelectedRecepientData,
}) => {
  const [recipientData, setRecipientData] = useState([]);
  const params = useParams();
  const { id } = params;

  useEffect(() => {
    getRecipientData();
  }, [id]);

  async function getRecipientData() {
    try {
      const response = await axios.get(
        `${GET_CONT_EMP_OF_ACCOUNT_API_PATH}/${id}`,
      );
      if (response) {
        setRecipientData(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <DropDown
      withSearch
      title="RECIPIENT"
      placeHolder="Getting Recipient"
      options={recipientData}
      value={selectedRecepientData}
      onSelectionChange={setSelectedRecepientData}
    />
  );
};

export default FilterByRecipient;
