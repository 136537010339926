/* eslint-disable global-require */
import { useFormContext } from 'react-hook-form';
import { LoginFormInterface } from 'components/Login/LoginV5/LoginV5Form';
import { useState } from 'react';
import { GOOGLE_CLIENT_ID, MICROSOFT_CLIENT_ID } from 'constants/constants.js';
import { randomString, createCookie } from 'utilities/utils';
import { getRedirectUri } from 'components/Login/LoginV5/helpers/getRedirectUri';
import { FormAlert } from 'components/Login/LoginV5/components/FormAlert/FormAlert';

export interface LoginFormProps {
  goToForgotPassword: () => void;
}

export const LoginForm = ({ goToForgotPassword }: LoginFormProps) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const {
    register,
    formState: { errors },
  } = useFormContext<LoginFormInterface>();

  const emailError = errors.email?.message;
  const passwordError = errors.password?.message;

  const googleLogin = () => {
    const clientId = GOOGLE_CLIENT_ID;
    const redirectUri = getRedirectUri('google');
    const auth_url = `https://accounts.google.com/o/oauth2/v2/auth?scope=https://www.googleapis.com/auth/userinfo.email&response_type=code&redirect_uri=${redirectUri}&client_id=${clientId}&state=`;
    const cookieName = randomString(16);
    createCookie(cookieName, window.location.href, 30);
    const state = cookieName;
    window.location.href = auth_url + state;
  };

  const outlookLogin = () => {
    const clientId = MICROSOFT_CLIENT_ID;
    const redirectUri = getRedirectUri('outlook');
    const auth_url =
      'https://login.microsoftonline.com/common/oauth2/v2.0/authorize?' +
      `client_id=${clientId}&response_type=code` +
      `&redirect_uri=${redirectUri}&response_mode=query` +
      `&scope=${['openid', 'profile', 'email', 'People.Read', 'User.read'].join(
        ' ',
      )}&state=`;
    const cookieName = randomString(16);
    createCookie(cookieName, window.location.href, 30);
    const state = cookieName;
    window.location.href = auth_url + state;
  };

  return (
    <>
      <h1>Sign In</h1>
      <FormAlert />
      <div>
        <div className="form-group">
          <div className={`form-group ${emailError && 'form-error'}`}>
            <input
              type="email"
              className="form-control"
              placeholder="Email"
              autoComplete="new-password"
              data-testid="email"
              {...register('email')}
            />

            <div className="text-danger error_label" id="mail">
              {emailError}
            </div>
          </div>
          <div className={`form-group ${passwordError && 'form-error'}`}>
            <div className="labelValue inputGroup">
              <input
                type={isPasswordVisible ? 'text' : 'password'}
                placeholder="Password"
                className="form-control"
                autoComplete="off"
                data-testid="password"
                {...register('password')}
              />
              <span
                onClick={() => {
                  setIsPasswordVisible((currentState) => !currentState);
                }}
                className="inputHelpIcon"
              >
                <i
                  className={
                    isPasswordVisible ? 'icon-close-eye' : 'icon-open-eye'
                  }
                  aria-hidden="true"
                />
              </span>
            </div>
            <div className="text-danger error_label" id="password">
              {passwordError}
            </div>
          </div>
        </div>
        <div className="form-group">
          <button
            type="submit"
            id="loginSubmit"
            className="btn btn-primary w-100"
            data-testid="sign_in"
          >
            Sign In
          </button>
        </div>
        <div className="clearfix" />
        <div className="form-group text-center">Or</div>
        <div className="form-group">
          <button
            type="button"
            className="cntBtn w-100 p-0 bg-white"
            onClick={googleLogin}
            data-testid="sign_in_with_google"
          >
            <div className="pr-2 pl-2">
              <img
                src={require('images/gLogo.png')}
                alt="Google"
                className="hIcon"
              />
            </div>
            <div className="csBtn text-center">Sign in with Google</div>
          </button>
        </div>
        <div className="form-group">
          <button
            type="button"
            className="cntBtnGgle w-100 p-0 bg-white"
            onClick={outlookLogin}
            data-testid="sign_in_with_office"
          >
            <div className="pr-2 pl-2">
              <img
                src={require('images/ofcLogo.png')}
                alt="Microsoft"
                className="hIcon"
              />
            </div>
            <div className="csBtnGgle text-center">Sign in with Office 365</div>
          </button>
        </div>
        <div className="form-group text-left">
          <div className="d-flex w-100 justify-content-between crPage--bott">
            <div className="check-container">
              <label htmlFor="user_remember_me">
                <input
                  value="1"
                  onChange={console.log}
                  checked={false}
                  name="user[remember_me]"
                  id="user_remember_me"
                  type="checkbox"
                  data-testid="remember_me"
                />
                <i className="checkmark" />
                <span className="pl-2">Remember me</span>
              </label>
            </div>
            <button
              type="button"
              onClick={goToForgotPassword}
              className="btn tptBtn"
              data-testid="forgot_password"
            >
              Forgot your password?
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginForm;
