import moment from 'moment';
import React from 'react';
import {
  ACCOUNT_LABEL,
  DATE_RANGE,
  INVOICE_TYPE,
  OWNER,
  STATUS,
  TEXT_SEARCH,
} from 'constants/constants';
import ClearButton from 'common/ClearButton';

const formatDate = (dateStr) => {
  if (dateStr && moment.isDate(dateStr)) {
    return moment.format('MM/DD/YY');
  }

  return dateStr ? moment(dateStr).format('MM/DD/YY') : dateStr;
};

const RenderFilterOption = ({ selection: { type, value }, onClearFilter }) => {
  const clearOptions = { type };
  const clearSelection = () => onClearFilter(clearOptions);
  if (!value) {
    return null;
  }
  switch (type) {
    case TEXT_SEARCH:
    case INVOICE_TYPE:
      return (
        <div className="badge-filter" id={`text-${type}-filter-selected`}>
          {value}
          <ClearButton onClickClear={clearSelection} className="icon-small" />
        </div>
      );
    case DATE_RANGE:
      if (!value || !value.start || !value.end) {
        return null;
      }
      return (
        <div className="badge-filter" id="date-range-filter-selected">
          {`${formatDate(value.start)} - ${formatDate(value.end)}`}
          <ClearButton onClickClear={clearSelection} className="icon-small" />
        </div>
      );
    case STATUS:
      if (!value.length) {
        return null;
      }
      return value.map((optionValue) => {
        const onClearStatus = () => {
          clearOptions.value = optionValue;
          clearSelection();
        };
        return (
          <div
            className="badge-filter"
            key={`${type}_option_${optionValue}`}
            id={`status-filter-filter-selected-${optionValue}`}
          >
            {optionValue}
            <ClearButton onClickClear={onClearStatus} className="icon-small" />
          </div>
        );
      });

    case ACCOUNT_LABEL: {
      if (!value || !value.accountLabel) {
        return null;
      }
      const { accountLabel, accountId } = value;
      return (
        <div className="badge-filter" id={`account-filter-${accountId}`}>
          {`${accountLabel}`}
          <ClearButton onClickClear={clearSelection} className="icon-small" />
        </div>
      );
    }

    case OWNER: {
      if (!value || !value.ownerLabel) {
        return null;
      }
      const { ownerLabel, ownerId } = value;
      return (
        <div className="badge-filter" id={`owner-filter-${ownerId}`}>
          {`${ownerLabel}`}
          <ClearButton onClickClear={clearSelection} className="icon-small" />
        </div>
      );
    }

    default:
      return null;
  }
};

const FiltersSelected = (props) => {
  const { selected = {}, onClearFilter } = props;
  return (
    <div className="selected-filters-bar">
      <div className="filter-left-block " />
      {selected.map((selection) => (
        <RenderFilterOption
          selection={selection}
          onClearFilter={onClearFilter}
          key={`${selection.type}_option`}
        />
      ))}
    </div>
  );
};

export default FiltersSelected;
