import { UpdateWorkflowRequestMutationVariables } from 'gql/graphql';
import { useUpdateWorklowRequest } from 'hooks/workflow-request-hooks';

/**
 * This hook is simply an abstraction to be used by both:
 * - AccountCell
 * - AssignedToCell
 */
export function useUpdateDraftWorkflowRequest() {
  const { mutate: updateWorkflowRequestMutation } = useUpdateWorklowRequest();

  const updateWorkflowRequest = (
    workflowRequestId: string,
    variables: Partial<UpdateWorkflowRequestMutationVariables>,
  ) => {
    updateWorkflowRequestMutation({
      workflowRequestId,
      ...variables,
    });
  };

  return updateWorkflowRequest;
}
