import { Box } from '@mui/material';
import { WorkflowRequestStatus } from 'gql/graphql';
import { REQUESTS_PAGE_TITLES } from 'components/Requests/requests.constants';
import { useEffect } from 'react';
import { DraftRequestsTable } from 'components/Requests/components/Tables/DraftRequestsTable';
import { useWorkflowRequestsQuery } from 'hooks/workflow-request-hooks';
import { usePagination } from 'components/Requests/components/RequestsBuilder/hooks/pagination';
import { useRequestsState } from 'components/Requests/state/request-state';

export const DraftRequestsView = () => {
  useEffect(() => {
    document.title = REQUESTS_PAGE_TITLES.draftsRequests;
  }, []);

  const { paginationModel, setPaginationModel } = usePagination();
  const sortStatus = useRequestsState(
    (state) => state.sortStatus?.[WorkflowRequestStatus.Draft],
  );
  const filters = useRequestsState((state) => state.filters);

  const draftRequests = useWorkflowRequestsQuery({
    status: WorkflowRequestStatus.Draft,
    perPage: paginationModel.pageSize,
    page: paginationModel.page + 1,
    filterData: {
      assigneeIds: filters[WorkflowRequestStatus.Draft].assignedTo,
      ownerIds: filters[WorkflowRequestStatus.Draft].owners,
    },
    ...sortStatus,
  });

  return (
    <Box>
      <DraftRequestsTable
        workflowRequestsQuery={draftRequests}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
        requestKey={WorkflowRequestStatus.Draft}
      />
    </Box>
  );
};
