import { IntegrationModule } from 'components/Integrations/integration-modules/integration-module.types';
import { KarbonIntegrationListItem } from './KarbonIntegrationListItem';
import { KarbonIntegrationConfig } from './KarbonIntegrationConfig';
import { getKarbonActivationStatus } from './karbon-calls';

export const karbonIntegrationModule: IntegrationModule = {
  id: 'karbon',
  path: 'karbon',
  title: 'Karbon',
  getActivationStatus: getKarbonActivationStatus,
  listComponent: <KarbonIntegrationListItem />,
  configComponent: <KarbonIntegrationConfig />,
};
