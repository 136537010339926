import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { ADOBE_WEB_ACCESS_URL } from 'constants/constants';

const AdobeLogout = () => {
  const [adobeWebAccessUrl, setadobeWebAccessUrl] = useState();

  useEffect(() => {
    if (!adobeWebAccessUrl) {
      const webAccessUrl = localStorage.getItem('adobeWebAccessUrl');
      if (webAccessUrl === null) {
        Axios.get(ADOBE_WEB_ACCESS_URL).then((res) => {
          if (res.status === 200 && res.data && res.data.web_access_url) {
            setadobeWebAccessUrl(res.data.web_access_url);
            localStorage.setItem('adobeWebAccessUrl', res.data.web_access_url);
          }
        });
      } else {
        setadobeWebAccessUrl(webAccessUrl);
      }
    }
  });

  return (
    <div>
      <iframe
        width="0"
        height="0"
        id="adobelogoout"
        name="adobelogoout"
        src="https://lisciodev1.na2.echosign.com/public/logout"
        title="Adobe Logout"
        style={{ visibility: 'hidden' }}
      />
      {adobeWebAccessUrl && (
        <iframe
          width="0"
          height="0"
          id="adobelogoout"
          name="adobelogoout"
          src={`${adobeWebAccessUrl}/public/logout`}
          title="Adobe Logout"
          style={{ visibility: 'hidden' }}
        />
      )}
    </div>
  );
};

export default AdobeLogout;
