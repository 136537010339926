import React from 'react';
import styled from '@emotion/styled';
import {
  BUTTON_ICON_LOCATION_FRONT,
  BUTTON_ICON_LOCATION_BACK,
} from 'constants/constants';
import { getButtonSize } from './utilities';

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00aecc;
  border: 2px solid #00aecc;
  color: white;
  height: ${(props) => getButtonSize(props.size).height};
  padding: 13px 15px;
  border-radius: 2px;
  transition: all ease-in-out 350ms;
  :hover {
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
  }
  :disabled,
  [disabled] {
    background-color: #ebebeb;
    color: #919eab;
  }
  :active,
  :focus {
    outline: 2px solid #00637f;
    background: #00637f;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
  }
  .IconMargin {
    margin-left: 10px;
  }
`;

const PrimaryButton = ({
  label,
  onClick,
  icon,
  disabled = false,
  size,
  iconLocation,
  testid,
}) => (
  <StyledButton
    type="button"
    id={label}
    onClick={onClick}
    disabled={disabled}
    size={size}
    data-testid={testid}
  >
    {icon && iconLocation === BUTTON_ICON_LOCATION_FRONT && (
      <i className={`icon icon-${icon}`} />
    )}
    {label}
    {icon && iconLocation === BUTTON_ICON_LOCATION_BACK && (
      <i className={`IconMargin icon icon-${icon}`} />
    )}
  </StyledButton>
);

export default PrimaryButton;
