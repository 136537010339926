import { Draw } from '@mui/icons-material';
import { Box, Button, Stack, Typography, useTheme } from '@mui/material';
import FileDisplayFromUrl from 'common/FileDisplayFromUrl/FileDisplayFromUrl';
import { getSignableDocumentsFromWorkflow } from 'components/Requests/utils/getSignedDocumentsInWorkflow';
import { Maybe, WorkflowWithSectionsFragment } from 'gql/graphql';
import { useDocumentDetail } from 'hooks/document-hooks';
import { useState } from 'react';

type SingleFilePreviewProps = {
  title?: Maybe<string> | undefined;
  signed: boolean | undefined;
  legacyId?: string;
};

function SingleFilePreview({
  title,
  signed,
  legacyId,
}: SingleFilePreviewProps) {
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  const color = signed ? theme.palette.success.main : theme.palette.error.main;

  const {
    data: docData,
    isSuccess: docDataIsSuccess,
    isLoading: docDataIsLoading,
    isError: docDataIsError,
  } = useDocumentDetail(legacyId);

  return (
    <Box>
      <Stack direction="row">
        <Button
          variant="text"
          startIcon={<Draw />}
          onClick={handleOpen}
          disabled={!signed || docDataIsError || docDataIsLoading}
          sx={{ color: theme.palette.secondary.dark }}
        >
          <Typography variant="inherit" fontWeight={700} paddingRight="0.5rem">
            {signed ? 'signed document:' : 'unsigned document:'}
          </Typography>
          <Typography
            display="flex"
            alignItems="center"
            sx={{ userSelect: 'none' }}
          >
            <i
              className="icon-filetype-pdf"
              style={{
                color,
                fontSize: '1.5rem',
                paddingRight: '0.5rem',
              }}
            />
            {title}
          </Typography>
        </Button>
      </Stack>
      {docDataIsSuccess && (
        <FileDisplayFromUrl
          url={docData?.data?.aws_url_original}
          fileName={docData?.data?.file_name}
          headerTitle={title as string}
          open={isOpen}
          onClose={handleClose}
        />
      )}
    </Box>
  );
}

type SignedDocumentsPreviewProps = {
  workflow: WorkflowWithSectionsFragment;
};

export const SignedDocumentsPreview = ({
  workflow,
}: SignedDocumentsPreviewProps) => {
  const getDocuments = getSignableDocumentsFromWorkflow(workflow);

  const elements = getDocuments.length
    ? getDocuments.map(({ esignAgreement }) => (
        <Stack key={esignAgreement?.documentToSign?.id}>
          <SingleFilePreview
            title={esignAgreement?.documentToSign?.awsUrlFileName}
            signed={Boolean(esignAgreement?.signedDocument?.id)}
            legacyId={esignAgreement?.signedDocument?.id}
          />
        </Stack>
      ))
    : null;

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{elements}</>;
};
