const initialState = {
  allMessages: [],
  notificationAvailable: false,
  unread_thread_count: 0,
  msgFilters: {},
  userMsgFilters: {},
};

// eslint-disable-next-line default-param-last
export default function messageReducer(state = initialState, action) {
  switch (action.type) {
    case 'FETCH_MESSAGE_SUCCESS': {
      const fetchedData = { ...action.data };
      if (action.filtersSelected) {
        fetchedData.draft = [];
        fetchedData.draft_count = 0;
        fetchedData.draft_off = 0;
        fetchedData.draft_offset = 1;
      }
      return {
        ...state,
        allMessages: fetchedData,
        unread_thread_count: action.data.unread_thread_count,
      };
    }
    case 'FETCH_USER_MESSAGE_SUCCESS': {
      return { ...state, allMessages: action.data };
    }
    case 'ADD_NEW_MESSAGE': {
      const AllMessages = { ...state.allMessages };
      AllMessages.inbox_count = action.data.inbox_count;
      AllMessages.inbox_offset = action.data.inbox_offset;
      AllMessages.inbox_off = action.data.inbox_off;
      AllMessages.inbox = action.data.inbox;
      AllMessages.page = 1;
      const thread_count = action.data.unread_thread_count;
      return {
        ...state,
        allMessages: AllMessages,
        unread_thread_count: thread_count,
      };
    }
    case 'RECEIVED_NOTIFICATION': {
      return {
        ...state,
        notificationAvailable: action.data,
      };
    }
    case 'RECEIVE_THREAD_COUNT': {
      return {
        ...state,
        unread_thread_count: action.data,
      };
    }
    case 'HANDLE_MSG_FILTERS': {
      return {
        ...state,
        msgFilters: action.data,
      };
    }
    case 'USER_MSG_FILTERS': {
      return {
        ...state,
        userMsgFilters: action.data,
      };
    }
    case 'DISCARD_DRAFT': {
      const StateObject = { ...state };
      StateObject.allMessages.draft = StateObject.allMessages.draft.filter(
        (curr) => curr.id !== action.data,
      );
      StateObject.allMessages.draft_count -= 1;
      return StateObject;
    }
    case 'UPDATE_PINNED_COUNT': {
      const prev = { ...state.allMessages };
      prev.pinned_count = action.data;
      return { ...state, allMessages: prev };
    }
    case 'ARCHIVE_THREAD': {
      const compState = action.data.state;
      const compProps = action.data.props;
      const StateObject = { ...state };
      if (
        compProps &&
        compProps.data &&
        (compProps.data.filter === 'inbox' || compProps.data.filter === 'alert')
      ) {
        if (
          compProps.data.allMessages[`${compProps.data.filter}_count`] <= 20
        ) {
          StateObject.allMessages[`${compProps.data.filter}_off`] -= 1;
        }
        StateObject.allMessages[compProps.data.filter] =
          StateObject.allMessages[compProps.data.filter].filter(
            (curr) => curr.thread_id !== compState.messageThreadId,
          );
        StateObject.allMessages[`${compProps.data.filter}_count`] -= 1;
        StateObject.allMessages.archive_count += 1;
      }
      return StateObject;
    }
    default:
      return state;
  }
}
