const initialState = {
  flag: false,
  text: '',
  status: '',
};

// eslint-disable-next-line default-param-last
export default function elasticReducer(state = initialState, action) {
  switch (action.type) {
    case 'IS_ELASTIC':
      return { ...state, flag: action.payload };
    case 'CHANGE_ELASTIC_TEXT':
      return { ...state, text: action.payload };
    case 'CHANGE_ELASTIC_STATUS':
      return { ...state, status: action.payload };
    default:
      return state;
  }
}
