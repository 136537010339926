/* eslint-disable no-plusplus */
import React, { Component, useEffect, useState } from 'react';
import Axios from 'axios';
import AccountDateField from 'common/AccountDateField';
import DropDown from 'common/DropDown';
import MessageFilterCustomView from 'components/Messages/Inbox/MessageFilterCustomView';
import { GET_ALL_EMPLOYEES } from 'constants/constants';
import { getCpaPreferences, generateFullName } from 'utilities/utils';
import styled from '@emotion/styled';

const Styleropdown = styled.div`
  margin-left: 0.6rem;
  .MuiButtonBase-root {
    height: auto;
    padding: 4px 16px;
  }
`;

export const DateFilterComponent = (props) => {
  const { filterName, isActive, data, filterBGClicked } = props;
  const [showFilterModal, setShowFilterModal] = useState(!!isActive);

  useEffect(() => {
    if (data.to && data.from) {
      setShowFilterModal(false);
    }
  }, [data.to, data.from]);

  useEffect(() => {
    setShowFilterModal(false);
  }, [filterBGClicked]);

  return (
    <div
      className="dropdown inboxFilterDrop"
      onClick={(e) => e.stopPropagation()}
    >
      <button
        type="button"
        className={`btn btn-outline-light btn-sm dropdown-toggle ${
          isActive ? 'active' : ''
        }`}
        onClick={() => setShowFilterModal(!showFilterModal)}
      >
        DATE RANGE
      </button>
      <div
        className={`dropdown-menu inboxFilter ${showFilterModal ? 'show' : ''}`}
        style={{ overflow: 'visible' }}
      >
        <div className="dropdown-item">
          <AccountDateField
            className="InputGroupFocus form-group mb-0 form-inline-label"
            name="date-input1"
            label="From"
            labelClass="date-text date-calender text-body label-large"
            style={{ minWidth: '50px' }}
            onChange={(e) => props.handleDateField(e, 'from', filterName)}
            onClick={(e) => e.stopPropagation()}
            maxDate={data.to ? new Date(data.to) : new Date()}
            custom
            value={data.from}
          />
        </div>
        <div className="dropdown-item">
          <AccountDateField
            className="InputGroupFocus form-group mb-0 form-inline-label"
            name="date-input2"
            label="To"
            labelClass="date-text date-calender text-body label-large"
            style={{ minWidth: '50px' }}
            onChange={(e) => props.handleDateField(e, 'to', filterName)}
            onClick={(e) => e.stopPropagation()}
            maxDate={new Date()}
            minDate={data.from ? new Date(data.from) : null}
            custom
            value={data.to}
          />
        </div>
      </div>
    </div>
  );
};

export const FilterComponent = (props) => {
  const {
    filterList,
    filterName,
    isActive,
    data,
    searchValue,
    disabled,
    showFilterOptions = false,
    setFilterInFocus,
    testid,
  } = props;

  const [filterActive, setFilterActive] = useState(showFilterOptions);

  const toggleFilterVisible = (e = {}) => {
    if (e.stopPropagation) e.stopPropagation();
    setFilterActive(!filterActive);
    if (setFilterInFocus) setFilterInFocus(filterName);
  };

  useEffect(() => {
    setFilterActive(showFilterOptions);
  }, [showFilterOptions]);

  const handleOptionClicked = (e, item) => {
    toggleFilterVisible();
    props.recipientSelected(e, item, filterName);
  };

  return (
    <div
      className={`dropdown ${filterActive ? 'show' : ''}`}
      style={{ marginLeft: '10px' }}
    >
      <button
        data-testid={testid}
        type="button"
        className={
          isActive
            ? 'btn btn-outline-light btn-sm dropdown-toggle active'
            : 'btn btn-outline-light btn-sm dropdown-toggle'
        }
        onClick={toggleFilterVisible}
        onMouseDown={props.clearSearch}
        disabled={disabled}
      >
        {filterName}
      </button>
      <div className={`dropdown-menu ${filterActive ? 'show' : ''}`}>
        <div className="input-group">
          <input
            type="text"
            className="pl-2 form-control border-0"
            name="recipientValue"
            id={filterName}
            value={searchValue || (data ? data.recipientValue : '')}
            onChange={(e) => props.recipientInputChange(e)}
            autoComplete="off"
            onClick={(e) => e.stopPropagation()}
          />
          <span className="input-group-addon border-left-0">
            <i className="icon-zoom2" />
          </span>
        </div>
        {filterList &&
        filterList.length !== undefined &&
        filterList.length !== 0 ? (
          filterList.map((item) => (
            <button
              key={item.value}
              type="button"
              onClick={(e) => handleOptionClicked(e, item)}
              className="dropdown-item"
            >
              {item.label}
            </button>
          ))
        ) : (
          <span className="dropdown-item" />
        )}
      </div>
    </div>
  );
};

export const CstTeamFilter = (props) => {
  const { selectedCsTeam, handleCstTeam } = props;

  const preferences = getCpaPreferences();
  const [csTeams, setCsTeams] = useState([]);
  const [iSelectedCsTeam, setISelectedCsTeam] = useState(selectedCsTeam);

  const getRoleOfCst = (cst) => {
    const { user_role } = preferences;
    let tRole = cst.role.replace(/_/g, ' ');
    user_role.map((item) => {
      if (cst.role === item.key) {
        tRole = item.label;
      }
      return tRole;
    });
    return tRole;
  };

  const getCSTeams = async () => {
    try {
      const res = await Axios.get(GET_ALL_EMPLOYEES);

      let tCsTeams = res.data.data;
      tCsTeams = tCsTeams.map((each) => ({
        ...each,
        headline: generateFullName(each),
        subHeadline: getRoleOfCst(each),
      }));

      setCsTeams(tCsTeams);

      return tCsTeams;
    } catch (e) {
      return [];
    }
  };

  useEffect(() => {
    getCSTeams();
  }, []);

  useEffect(() => {
    setISelectedCsTeam(selectedCsTeam);
  }, [selectedCsTeam]);

  const onSelectedCsTeamChange = (value) => {
    setISelectedCsTeam(value);
    handleCstTeam(value);
  };

  return (
    <Styleropdown>
      <DropDown
        withSearch
        title="CS TEAM"
        placeHolder="Getting Employees"
        options={csTeams}
        value={iSelectedCsTeam}
        onSelectionChange={onSelectedCsTeamChange}
      />
    </Styleropdown>
  );
};

export const EsignFilters = (props) => {
  const { filterList, filterName, isActive, data } = props;
  return (
    <div className="dropdown">
      <button
        type="button"
        className={
          isActive
            ? 'btn btn-outline-light btn-sm dropdown-toggle active'
            : 'btn btn-outline-light btn-sm dropdown-toggle'
        }
        // onMouseDown={() => props.clearInput(filterName.toLowerCase())}
        data-toggle="dropdown"
      >
        {filterName}
      </button>
      <div id={filterName} className="dropdown-menu">
        <div className="input-group">
          <input
            type="text"
            className="pl-2 form-control border-0"
            name="recipientValue"
            id={filterName}
            value={data[filterName.toLowerCase()]}
            onChange={(e) => props.inputChange(e.target.value, filterName)}
          />
          <span className="input-group-addon border-left-0">
            <i className="icon-zoom2" />
          </span>
        </div>
        {filterList.map((item) => (
          <button
            key={item.value}
            type="button"
            onClick={(e) => props.filterSelected(e, item, filterName)}
            className="dropdown-item"
          >
            {item.label}
          </button>
        ))}
      </div>
    </div>
  );
};

export default class MessageFilterComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { customeView: false };
  }

  handleCancel = () => {
    this.setState({ customeView: false }, () => {
      this.props.handleDateField('', '', 'CLEAR');
    });
  };

  handleEdit = () => {
    this.setState({ customeView: true });
  };

  render() {
    const { filterList, receipientList, allMessagesView, isActive, data } =
      this.props;
    const { customeView } = this.state;
    let activeTb = '';
    if (data.filter === 'inbox') {
      activeTb = 'My Inbox';
    } else if (data.filter === 'alert') {
      activeTb = 'My Alerts';
    } else if (data.filter === 'sent') {
      activeTb = 'My Sent';
    } else if (data.filter === 'archive') {
      activeTb = 'My Archived';
    } else if (data.filter === 'pinned') {
      activeTb = 'My Pinned';
    } else if (data.filter === 'all_messages') {
      activeTb = 'My All Messages';
    }
    return (
      <div>
        <div className="dropdown">
          <button
            type="button"
            className={
              isActive
                ? 'btn btn-primary btn-sm dropdown-toggle active'
                : 'btn btn-primary btn-sm dropdown-toggle'
            }
            data-toggle="dropdown"
            onMouseDown={null}
          >
            <i className="icon-chevron-down2" />
            <span>{data.filteredUserView}</span>
            <span className="dropupWrap--caret" />
          </button>
          <div className="dropdown-menu dropdown-menu-left">
            <button
              type="button"
              onClick={() => this.props.getUserView('inbox')}
              className="dropdown-item"
              key="my_inbox"
            >
              <h5 className="fontSize">{activeTb}</h5>
            </button>
            {allMessagesView &&
            allMessagesView.length !== undefined &&
            allMessagesView.length !== 0
              ? allMessagesView.map((each) => (
                  <button
                    type="button"
                    onClick={() => this.props.getUserView(each)}
                    className="dropdown-item"
                    key={each.value}
                  >
                    <h5 className="fontSize">{each.label}</h5>
                  </button>
                ))
              : null}
            <button
              type="button"
              onClick={() => this.setState({ customeView: true })}
              className="dropdown-item"
              key="custom_view"
            >
              <h5 className="fontSize">
                <i className="icon-plus label-medium mr-2" />
                <span className="text-primary">Create Custom View</span>
              </h5>
            </button>
          </div>
          <MessageFilterCustomView
            isOpen={customeView}
            accountList={filterList}
            receipientList={receipientList}
            handleDateField={this.props.handleDateField}
            removeFilter={this.props.removeFilter}
            attachmentCheckBox={this.props.attachmentCheckBox}
            recipientInputChange={this.props.recipientInputChange}
            handleCustomViewValidation={this.props.handleCustomViewValidation}
            data={data}
            noBtnClick={this.handleCancel}
          />
        </div>
        {data.filteredUserViewId ? (
          <button
            type="button"
            className="btn-link btn--onlyicon btn btn-secondary"
            onClick={this.handleEdit}
          >
            <i className="icon-edit" aria-hidden="true" />
          </button>
        ) : null}
      </div>
    );
  }
}
