/* eslint-disable react/no-unused-class-component-methods */
/* eslint-disable global-require */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-script-url */
/* eslint-disable react/jsx-no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-param-reassign */
/* eslint-disable require-await */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable consistent-return */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-access-state-in-setstate */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Axios from 'axios';
import { bindActionCreators } from 'redux';
import { withRouter } from 'utilities/withRouterWrapper';
import { Button, Modal, Label, ModalHeader, ModalBody } from 'reactstrap';
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import FileSaver from 'file-saver';
import moment from 'moment';
import UploadFiles from 'api/UploadFiles';
import { autoTagApi } from 'api/AutoTag';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { NUMBER } from 'utilities/regularexpressions';
import ContactCard from 'common/ContactCard';
import AccountCard from 'common/AccountCard';
import * as Config from 'constants/constants';
import * as actions from 'actions/TaskActions';
import * as TaskApi from 'api/TaskApi';
import ConfirmModal from 'common/ConfirmationModal';
import { AttachementPortion } from 'common/CustomFields';
import PageNotFound from 'common/PageNotFound';
import ImagePreview from 'common/ImagePreview';
import {
  AlertMessage,
  getCpaPreferences,
  getFirmType,
  dueDateColor,
  generateDuration,
  getUserTimeZoneAbbr,
  isUserEmployee,
  getStorageData,
  getMLData,
  getSiteHostname,
} from 'utilities/utils';
import * as NotificationActions from 'actions/NotificationActions';
import * as paginationActions from 'actions/PaginationActions';
import { BrowserName } from 'common/BrowserName';
import ClientLiscioVault from 'components/UploadedFiles/ClientLiscioVault.jsx';
import LiscioVaultList from 'components/Files/LiscioVaultList';
import PdfViewer from 'components/PdfViewer/PdfViewer';
import PreviewAllAttachments from 'common/PreviewAllAttachments';
import ReadOnlyTinyMCE from 'common/ReadOnlyTinyMCE/ReadOnlyTinyMCE';
import SignDocumentModal from 'components/Esign/SignDocumentModal';
import SignatureModal from 'components/Tasks/Create/task_type/SignatureModal';
import AdobeLogout from 'components/Esign/AdobeLogout';
import PaymentModal from 'components/Tasks/PaymentsModal/PaymentsModal';
import { websocket } from 'websocket';
import { getAuthSession } from 'auth/session';
import { withStorageData } from 'utilities/withStorageData';
import DetailBottom from './DetailBottom';
import TaskPdfLayout from './TaskPdfLayout';
import TaskCompleteButton from './TaskCompleteButton';

const noneStyle = { display: 'none' };
const blockStyle = { display: 'block' };
const isIE = /* @cc_on!@ */ false || !!document.documentMode;
let ML_APP_DATA = {};
let accessToken = '';
class TaskDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      load: false,
      uploadFiles: [],
      documents: [],
      commentIdHash: [],
      comments: [],
      commentsActivity: [],
      commentsHistory: [],
      taskStatus: '',
      filter: 'my_activity',
      activeArray: 'commentsActivity',
      activeTab: 0,
      showAttachmentModal: false,
      selectedAttachment: '',
      homedata: {},
      delAttachmentModal: false,
      confirmationModal: false,
      attachmentDetails: '',
      markDiv: true,
      noTask: false,
      isDocumentUpload: false,
      // get signature states start here
      showTemplate: false,
      modalState: 'Edit',
      contentAfterChange: '',
      templatePdf: '',
      uploadedFilesLength: 0,
      uploadedFilesCount: 0,
      yearId: '',
      yearValue: '',
      selectedTags: [],
      selectedDocuments: [],
      isFileModalSubmit: false,
      popUp: false,
      type: 'taskdetail',
      newDocs: [],
      errors: {},
      previewPopUp: true,
      previewScreen: false,
      previewDocument: null,
      monthId: '',
      monthValue: '',
      signDocumentModal: false,
      openOnedrive: false,
      thirdPartyApps: [],
      accessToken: '',
      sharedFilesList: [],
      folderSequence: [],
      onedriveFilesActiveTab: 0,
      noSharedFiles: false,
      googleDriveModal: false,
      contactCardID: null,
      contactCardUniqIdentifier: null,
      accountCardID: null,
      accountCardUniqIdentifier: null,
      selectedDocumentIndex: 0,
    };
    this.toggle = this.toggle.bind(this);
    this.startUpload = this.startUpload.bind(this);
    this.changeActiveTab = this.changeActiveTab.bind(this);
    this.fetchtaskDetail = this.fetchtaskDetail.bind(this);
    this.closeTask = this.closeTask.bind(this);
    this.cancelConfirm = this.cancelConfirm.bind(this);
    this.openAttachment = this.openAttachment.bind(this);
    this.delAttachmentModal = this.delAttachmentModal.bind(this);
    this.updateAutoTagData = this.updateAutoTagData.bind(this);
    this.autoTagsUpdated = false;
  }

  componentDidMount() {
    document.title = 'Task';
    ML_APP_DATA = getMLData();
    if (getFirmType() === 'liscio-lite') {
      this.props.navigate('/');
    }
    const component = this;
    this.fetchtaskDetail(true);
    this.props.actions.receivedNotification(false);
    // TODO: __HOMEDATA-AUDIT
    const homeDataObj = getStorageData();
    if (homeDataObj) {
      this.setState({ homedata: homeDataObj });
    }
    // action cable for task detail channel
    // const cookies = new Cookies();
    if (getAuthSession()) {
      component.tokenInterval = setInterval(() => {
        console.log('Checking for token');
        // TODO: __HOMEDATA-AUDIT
        const local_data = JSON.parse(
          localStorage.getItem(`${getSiteHostname().split(':')[0]} data`),
        );
        const url = window.location.pathname.split('/');
        if (url) {
          clearInterval(component.tokenInterval);
          console.log('trying to connect to taskDetail channel');
          const id = url[url.length - 1];
          component.TaskCable = websocket.consumer;
          component.myTaskSubscription =
            component.TaskCable.subscriptions.create(
              { channel: 'NewTaskChannel', task_id: id },
              {
                connected: () => {
                  console.log('connected to TaskDetailChannel');
                },
                received: (data) => {
                  console.log('Detail received - ', data);
                  if (data) {
                    if (local_data && local_data.cpa_user_id === data) {
                      component.props.actions.receivedNotification(false);
                    } else {
                      component.props.actions.receivedNotification(true);
                    }
                  }
                },
                disconnected: () => {
                  console.log('disconnected from task detail channel');
                },
              },
            );
        }
      }, 200);
    }
    if (isUserEmployee()) {
      this.setState({ loading: true });
      const request = Axios.get(Config.THIRD_PARTY_APPS);
      request
        .then((response) => {
          if (response.data.success === 200) {
            this.setState({
              thirdPartyApps: response.data.data.length
                ? response.data.data
                : [],
            });
          } else {
            this.setState({ loading: false });
          }
        })
        .catch((err) => {
          console.log('err', err);
          this.setState({ loading: false });
        });
    }
    if (window.Stripe === undefined) {
      const script = document.createElement('script');
      script.src = 'https://js.stripe.com/v3/';
      script.id = 'stripe';
      document.body.appendChild(script);
      script.onload = () => {
        console.log('Stripe is ready');
      };
    }
    if (window.Plaid === undefined) {
      const script = document.createElement('script');
      script.src = 'https://cdn.plaid.com/link/v2/stable/link-initialize.js';
      script.id = 'plaid';
      document.body.appendChild(script);
      script.onload = () => {
        console.log('Plaid is ready');
      };
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { location } = nextProps;
    if (
      location &&
      location.state !== null &&
      location.state !== undefined &&
      location.pathname !== this.props.location.pathname &&
      location.state.from === 'notify'
    ) {
      this.fetchtaskDetail(true);
    }
    if (
      Object.keys(nextProps.taskDetail) &&
      (nextProps.taskDetail.task_type_key === 'get_signature' ||
        nextProps.taskDetail.task_type_key === 'invoice') &&
      nextProps.taskDetail.id !== this.props.taskDetail?.id
    ) {
      this.setState({
        contentAfterChange: nextProps.taskDetail.agreement,
        templatePdf: nextProps.taskDetail.pdf ? nextProps.taskDetail.pdf : '',
        paymentStatus: nextProps.taskDetail.payment_status,
      });
      if (nextProps.taskDetail.task_type_key === 'invoice') {
        this.getPaymentStatus(nextProps.taskDetail.id);
      }
    }
  }

  componentWillUnmount() {
    if (this.TaskCable && this.myTaskSubscription) {
      this.TaskCable.subscriptions.remove(this.myTaskSubscription);
    }
    this.props.actions.fetchDetail({});
    if (this.taskContainer) {
      this.taskContainer.removeEventListener('click', () => {
        console.log('removed event listeners');
      });
    }
  }

  toggleImagePreview = (selectedThreadImages, selectedImageId) => {
    const { showImagePreview } = this.state;
    const {
      location: { pathname },
    } = this.props;
    if (showImagePreview) {
      this.props.navigate(-1);
    } else {
      this.props.navigate(`${pathname}#image_preview`);
    }
    this.setState({
      selectedThreadImages,
      selectedImageId,
      showImagePreview: !showImagePreview,
      loading: false,
    });
  };

  handleEventListeners = () => {
    if (!this.taskContainer) {
      const { images } = this.props.taskDetail;
      const imagesArray = [];
      if (images?.length > 0) {
        const inlineImages = [];
        images.forEach((key) => {
          inlineImages.push(key);
        });
        imagesArray.push(inlineImages);
      }
      this.taskContainer = document.getElementById('taskDescription');
      // Detect all clicks on the document
      this.taskContainer.addEventListener(
        'click',
        (event) => {
          if (event?.target?.getAttribute('data-image-id')) {
            const selectedImageId = parseInt(
              event.target.getAttribute('data-image-id'),
              10,
            );
            const selectedThreadImagesIds = [];
            if (imagesArray?.length > 0) {
              let selectedThreadImages = [];
              imagesArray.forEach((eachSection) => {
                eachSection.forEach((e) => {
                  if (e.id === selectedImageId) {
                    selectedThreadImages = eachSection;
                  }
                });
              });
              selectedThreadImages.forEach((e) => {
                selectedThreadImagesIds.push(e.id);
              });
              if (selectedThreadImages.length) {
                this.toggleImagePreview(selectedThreadImages, selectedImageId);
              } else {
                selectedThreadImagesIds.push(selectedImageId);
                this.setState({ loading: true });
                Axios.get(
                  `${
                    Config.IMAGES_URLS
                  }?ids=${selectedThreadImagesIds.toString()}`,
                ).then((res) => {
                  if (res.data && res.data.success && res.data.data) {
                    this.toggleImagePreview(res.data.data, selectedImageId);
                  }
                });
              }
            }
          }
        },
        false,
      );
    }
  };

  onSuccess = (keys) => {
    keys.map((selectedItem) => {
      const item = selectedItem.driveItem_203[2];
      const head = { headers: { Authorization: `Bearer ${accessToken}` } };
      Axios.get(
        `https://graph.microsoft.com/v1.0/me/drive/items/${item}`,
        head,
      ).then((res) => {
        const selectedfile = [];
        selectedfile.push(res.data);
        this.startUpload(selectedfile, 'onedrive');
      });
      return selectedItem;
    });
    this.handleOnedriveFiles('close');
  };

  onCancel = () => {
    this.handleOnedriveFiles('close');
  };

  onedriveSharedFileHandle = (item, itemType = '') => {
    const head = {
      headers: { Authorization: `Bearer ${this.state.accessToken}` },
    };
    if (
      ((item.folder && item.folder !== undefined) ||
        (!item.folder && item.folder === undefined)) &&
      !item.file
    ) {
      // this.setState({ loading: true });
      if (item.id === 'shared') {
        this.sharedFolder(1);
      } else {
        Axios.get(
          `https://graph.microsoft.com/v1.0/drives/${item.parentReference.driveId}/items/${item.id}/children?expand=thumbnails`,
          head,
        ).then((response) => {
          if (response.status === 200) {
            let sequence = this.state.folderSequence;
            const index = sequence.findIndex((x) => x.active === true);
            if (index > -1) {
              sequence[index].active = false;
              this.setState({ folderSequence: sequence });
            }
            if (itemType === 'folder') {
              const indx = sequence.findIndex((x) => x.id === item.id);
              if (indx > -1) {
                sequence[indx].active = true;
                sequence = sequence.slice(0, indx + 1);
                this.setState({ folderSequence: sequence });
              }
            } else {
              const seq = {
                name: item.name,
                id: item.id,
                parentReference: { driveId: item.parentReference.driveId },
                active: true,
                folder: true,
              };
              sequence.push(seq);
            }
            this.setState({
              sharedFilesList: response.data.value,
              folderSequence: sequence,
            });
          } else {
            // this.setState({ loading: false });
          }
        });
      }
    } else {
      const checkboxState = this.state.sharedFilesList;
      const index = checkboxState.findIndex((x) => x.id === item.id);
      if (index > -1) {
        checkboxState[index].checked = !checkboxState[index].checked;
        this.setState({ sharedFilesList: checkboxState }, () => {
          // console.log('pali', this.state.sharedFilesList);
        });
      }
    }
  };

  setAttachmentNull = (index = '') => {
    if (index && index === 'detailUpload') {
      const uploadFile = document.getElementById('upload-detail');
      uploadFile.value = null;
    } else {
      const uploadFile = document.getElementById('upload_doc');
      uploadFile.value = null;
    }
  };

  getAuthenticationToken = () => accessToken;

  getPaymentStatus = (id) => {
    Axios.get(`${Config.PAYMENTS}/${id}`).then((res) => {
      console.log(res.data.data);
    });
    // Axios.get(Config.CUSTOMER).then((res) => {
    //   console.log(res.data.data);
    // })
  };

  async updateAutoTagData() {
    try {
      const token = await autoTagApi('login', ML_APP_DATA);
      if (
        !token ||
        (typeof token === 'object' && Object.keys(token).length === 0)
      ) {
        // console.log('errorApi',token)
        // console.log('res', response);
        // console.log(response)
        // await setAutotagAccessToken(response.token)
        // console.log(await getAccessToken())
      }
      const filesResponse = await Promise.all(
        this.state.newDocs.map((doc) =>
          autoTagApi('results', { file: doc.doc_name }, true),
        ),
      );
      if (
        filesResponse &&
        filesResponse[0] &&
        filesResponse[0].status &&
        filesResponse[0].status === 401
      ) {
        return this.updateAutoTagData();
      }
      const autoTagData = filesResponse.reduce(
        (acc, cv) => ({
          tags: acc.tags.concat(
            cv.Suggested_tags ? cv.Suggested_tags[0][0] : [],
          ),
          // months: acc.months.concat(cv.month || []),
          // years: acc.years.concat(cv.year+"" || []),
          yearsAndMonths: acc.yearsAndMonths.concat(
            [
              {
                month: cv.month || '',
                year: `${cv.year}` || '',
              },
            ] || [],
          ),
        }),
        { yearsAndMonths: [], tags: [] },
      );
      getCpaPreferences().upload_tags.filter((tag) =>
        // return autoTagData.tags.find(autoTag=>autoTag.match(new RegExp(tag.label,'gi')))
        autoTagData.tags.find(
          (autoTag) => autoTag.toLowerCase() === tag.label.toLowerCase(),
        ),
      );
      // console.log({selectedTags,pref:this.props.preferences})
      autoTagData.yearsAndMonths.map((YMO) => ({
        year: getCpaPreferences().upload_file_year.find(
          (item) => item.label === YMO.year,
        ),
        month: getCpaPreferences().upload_file_month.find(
          (item) => item.label === YMO.month,
        ),
      }));
      // this.setState({
      //   yearId: parseInt(filteredYearsAndMonths[0].year.key, 10),
      //   yearValue: filteredYearsAndMonths[0].year.label,
      // });
      // this.setState({
      //   monthId: filteredYearsAndMonths[0].month.key,
      //   monthValue: filteredYearsAndMonths[0].month.label,
      // });
      // this.setState({ selectedTags });
    } catch (error) {
      console.error(error);
      // if(error.message.match(/Axios is not defined/gi)) this.updateAutoTagData();
    }
  }

  handleOnedriveSelectedFiles = () => {
    const head = {
      headers: { Authorization: `Bearer ${this.state.accessToken}` },
    };
    const checkboxState = this.state.sharedFilesList;
    checkboxState.map((item) => {
      if (item.checked && item.checked !== undefined) {
        Axios.get(
          `https://graph.microsoft.com/v1.0/drives/${item.parentReference.driveId}/items/${item.id}`,
          head,
        ).then((res) => {
          const selectedfile = [];
          selectedfile.push(res.data);
          this.startUpload(selectedfile, 'onedrive');
        });
      }
      this.handleOnedriveFiles('close');
      return null;
    });
  };

  handleOnedriveFiles = (toggle = undefined, item = {}) => {
    if (toggle === 'open') {
      const request = Axios.get(`${Config.OAUTH_ACCESS_TOKENS}/${item.key}`);

      request
        .then((response) => {
          if (response.status === 200) {
            accessToken = response.data.access_token;

            this.setState({
              openOnedrive: !this.state.openOnedrive,
              accessToken
            });
          }
          else {
            AlertMessage(
              'error',
              `Please set up ${item.value} to use the cloud store.`,
              3000,
            );
          }
        })
        .catch((err) => {
          console.log('err', err);
        });
    } else if (toggle === 'close') {
      this.setState({
        openOnedrive: !this.state.openOnedrive,
        sharedFilesList: [],
        folderSequence: [],
        onedriveFilesActiveTab: 0,
      });
    }
  };

  fetchtaskDetail = (loading) => {
    const url = window.location.pathname.split('/');
    if (url) {
      const id = url[url.length - 1];
      const { state } = this.props.location;
      this.props.actions.fetchtaskDetail(id, this, loading, '', state);
    }
  };

  updateTaskDetail = (data) => {
    this.setState({
      documents: data.documents,
      comments: data.comments,
      commentsActivity: data.my_activity,
      commentsHistory: data.history,
      taskStatus: data.status,
    });
    this.props.actions.fetchDetail(data);
  };

  cancelUpload = (filename) => {
    this.state[`${filename}sourceToken`].cancel();
  };

  changeActiveTab = (tabIndex, each) => {
    this.setState({
      activeTab: tabIndex,
      filter: each.value,
      [each.array]: this.props.taskDetail[each.value] || [],
      activeArray: each.array,
    });
  };

  delAttachmentModal = (item) => {
    this.setState({
      delAttachmentModal: true,
      attachmentDetails: item,
    });
  };

  closeDelModal = () => {
    this.setState({ delAttachmentModal: false });
  };

  downloadAttachments = () => {
    const url = this.state.documents;
    const zip = new JSZip();
    let count = 0;
    url.map((item) => {
      const urlPath = item.aws_url_origional;
      // const fileName = item.file_name;.
      let fileName = item.file_name;
      if (fileName.split('.').length === 1) {
        fileName = `${item.file_name}.${item.content_type.split('/')[1]}`;
      }
      JSZipUtils.getBinaryContent(urlPath, (err, data) => {
        if (err) {
          throw err;
        }
        const convertTime = moment()
          .add(moment().utcOffset(), 'minutes')
          .format('YYYY-MM-DD HH:mm:ss');
        zip.file(fileName, data, {
          binary: true,
          createFolders: false,
          date: new Date(convertTime),
        });
        count += 1;
        if (count === url.length) {
          zip.generateAsync({ type: 'blob' }).then((content) => {
            FileSaver.saveAs(content, 'all_docs.zip');
          });
        }
      });
      return true;
    });
  };

  toggle = () => {
    const { location } = this.props;
    const { taskStatus } = this.state;
    if (location.state?.from) {
      if (
        location.state.from &&
        (location.state.from === 'uploadlist' ||
          location.state.from === 'notify' ||
          location.state.from === 'edocpreview')
      ) {
        this.props.navigate(-1);
      } else {
        this.props.navigate(location.state.from, {
          isBack: true,
          state: { Status: taskStatus },
        });
      }
    } else {
      this.fetchtaskDetail(true);
    }
  };

  editTask = () => {
    const url = window.location.pathname.split('/');
    const {
      task_type_key,
      account_id,
      contact_id,
      todo_for_account_id,
      todo_for_contact_id,
    } = this.props.taskDetail;
    const { location } = this.props;
    if (url) {
      const id = url[url.length - 1];
      if (
        location.state &&
        location.state.from &&
        location.state.from === '/'
      ) {
        this.props.navigate(`/task/edit/${id}?type=${task_type_key}`, {
          state: { from: '/', page: 'dashboard' },
        });
      } else if (
        location.state &&
        location.state.from &&
        location.state.from.includes('inFocus')
      ) {
        this.props.navigate(`/task/edit/${id}?type=${task_type_key}`, {
          state: {
            id: task_type_key === 'to_do' ? todo_for_account_id : account_id,
            page: 'inFocus',
            from: location.state.from,
          },
        });
      } else if (
        location.state &&
        location.state.from &&
        location.state.from.includes('accountdetails')
      ) {
        this.props.navigate(`/task/edit/${id}?type=${task_type_key}`, {
          state: {
            id: task_type_key === 'to_do' ? todo_for_account_id : account_id,
            page: 'account_tasks',
            from: location.state.from,
          },
        });
      } else if (
        location.state &&
        location.state.from &&
        location.state.from.includes('contactdetails')
      ) {
        this.props.navigate(`/task/edit/${id}?type=${task_type_key}`, {
          state: {
            id: task_type_key === 'to_do' ? todo_for_contact_id : contact_id,
            page: 'contact_tasks',
            from: location.state.from,
          },
        });
      } else {
        this.props.navigate(`/task/edit/${id}?type=${task_type_key}`, {
          state: { from: '/task/detail' },
        });
      }
    }
    // console.log(this.props);
  };

  googleDrive = (stat = '') => {
    if (stat === 'false') {
      this.setState({ googleDriveModal: false });
    } else {
      this.setState((prevState) => ({
        googleDriveModal: !prevState.googleDriveModal,
      }));
    }
  };

  googleDriveLoader = (stat = '') => {
    if (stat === 'false') {
      this.setState({ loading: false });
    } else if (stat === 'true') {
      this.setState({ loading: true });
    } else {
      this.setState((prevState) => ({ loading: !prevState.loading }));
    }
  };

  uploadfile = (uploadFile, fro = '') => {
    this.startUpload(uploadFile, fro);
  };

  startUpload = (uploadFile, fro = '') => {
    let i;
    const component = this;
    let maxsizeInBytes = 629145600;
    let maxsizeInMb = 600;
    if (
      getCpaPreferences() &&
      getCpaPreferences().attachment &&
      getCpaPreferences().attachment[0].label &&
      NUMBER.test(getCpaPreferences().attachment[0].label)
    ) {
      maxsizeInBytes = getCpaPreferences().attachment[0].label * 1024 * 1024;
      maxsizeInMb = getCpaPreferences().attachment[0].label;
    }
    component.setState({
      isDocumentUpload: true,
      uploadedFilesLength: this.state.uploadedFilesLength + uploadFile.length,
    });
    const onUploadProgress = (uploadedFile) => (progress) => {
      const percentage = Math.floor((progress.loaded * 100) / progress.total);
      const key = `percentCompleted${uploadedFile}`;
      this.setState({ [key]: percentage });
    };
    for (i = 0; i < uploadFile.length; i += 1) {
      const data = new FormData();
      const { params } = this.props;
      const { id } = params;
      if (fro === 'onedrive' || fro === 'sharepoint') {
        // this.googleDriveLoader('false');
        data.append('aws_url_file_name', uploadFile[i].name);
        data.append('aws_url', uploadFile[i]['@microsoft.graph.downloadUrl']);
      } else if (fro === 'google_drive' || fro === 'smart_vault') {
        // this.googleDriveLoader('false');
        data.append('aws_url_file_name', uploadFile[i].name);
        data.append('aws_url', uploadFile[i].file);
      } else {
        data.append('aws_url', uploadFile[i], uploadFile[i].name);
      }
      data.append('task_id', id);
      data.append('is_draft', true);
      if (uploadFile[i].size > maxsizeInBytes) {
        alert(`File exceeds limit of ${maxsizeInMb}MB`);
        return false;
      }
      const { CancelToken } = Axios;
      const source = CancelToken.source();
      const files = component.state.uploadFiles;
      const keys = `${uploadFile[i].name}sourceToken`;
      files.push(uploadFile[i].name);
      const fileSize = uploadFile[i].size;
      const fileSizeKey = `fileSize${uploadFile[i].name}`;
      component.setState({
        uploadFiles: files,
        [keys]: source,
        [fileSizeKey]: fileSize,
      });
      const config = {
        headers: { 'content-type': 'multipart/form-data' },
        cancelToken: source.token,
        onUploadProgress: onUploadProgress(uploadFile[i].name),
      };
      TaskApi.addDocument(
        data,
        component,
        config,
        uploadFile[i].name,
        'taskDetail',
        fro,
      );
      if (component.state.errors.documents) {
        const err = {};
        err.documents = '';
        component.setState({ errors: err });
      }
    }
    return true;
  };

  uploadDocDetail = () => {
    const uploadFile = document.getElementById('upload-detail').files;
    this.startUpload(uploadFile);
  };

  uploadDocument = () => {
    const uploadFile = document.getElementById('upload_doc').files;
    this.startUpload(uploadFile);
  };

  showProgressBar = () => {
    const { uploadFiles } = this.state;
    let progressbar;
    if (uploadFiles !== undefined) {
      progressbar = uploadFiles.map((item) => {
        const returnValue = [];
        const percentCompleted = this.state[`percentCompleted${item}`];
        const getSize = this.state[`fileSize${item}`] / 1024;
        const trimSize = getSize.toFixed(2);
        const fileSize = this.state[`fileSize${item}`]
          ? `${trimSize} KB`
          : null;
        returnValue.push(
          <div key={item} className="file-drop-upload file-drop-inprogress">
            <div className="file-drop-type">
              <i className="icon-notes" aria-hidden="true" />
            </div>
            <div className="file-drop-info">
              <div className="file-drop-detail">
                <span className="fileName">{`${item} (${percentCompleted}%)`}</span>
                <span className="fileweight">{fileSize}</span>
              </div>
              <a
                className="file-action"
                onClick={() => this.cancelUpload(item)}
                href="javascript:void(0)"
                data-testid="task__cancel_upload"
              >
                <i className="icon-close2" />
              </a>
            </div>
          </div>,
        );
        return returnValue;
      });
      return progressbar;
    }
  };

  deleteAttachment = () => {
    const item = this.state.attachmentDetails;
    const { params } = this.props;
    const taskId = params.id;
    let commentId;
    const { commentIdHash } = this.state;
    for (let i = 0; i < commentIdHash.length; i += 1) {
      if (commentIdHash[i].attachment_id === item.id) {
        commentId = commentIdHash[i].comment_id;
      }
    }
    this.setState({ delAttachmentModal: false });
    TaskApi.deleteTaskEditAttachment(item.id, this, taskId, commentId);
  };

  openAttachment = async (c, isLink) => {
    UploadFiles.showOpenAttachment(c, this, isLink);
  };

  previewOpenAttachment = async (doc, isLink) => {
    if (isLink) {
      doc = JSON.parse(decodeURIComponent(doc));
    }
    this.setState({ previewScreen: true, previewDocument: doc });
  };

  previewCloseAttachment = (data) => {
    const { documents } = this.state;
    if (data?.editedFileName) {
      documents.map((doc) => {
        if (doc.id === data?.selectedItem.id && data?.editedFileName) {
          doc.doc_name = data.editedFileName;
        }
        return doc;
      });
      this.fetchtaskDetail(true);
    }
    this.setState({
      previewScreen: false,
      previewDocument: null,
      documents,
    });
  };

  attachmentModalClose = () => {
    this.setState({ showAttachmentModal: false, selectedAttachment: '' });
  };

  markComplete = () => {
    const details = this.props.taskDetail;
    if (details.status === 'Open' || details.status === 'Review') {
      this.setState({ confirmationModal: true });
    }
  };

  closeTask = () => {
    const { params } = this.props;
    const taskId = params.id;
    const data = { task_id: taskId };
    this.setState({ load: true });
    TaskApi.markComplete(this, data, true);
  };

  archiveTask = () => {
    const { params } = this.props;
    const taskId = params.id;
    const data = {
      task_id: taskId,
      is_archive: true,
    };
    this.setState({ load: true });
    TaskApi.markComplete(this, data);
  };

  reopenTask = () => {
    const { params } = this.props;
    const taskId = params.id;
    TaskApi.reOpen(this, taskId);
    this.setState({ markDiv: true });
  };

  cancelConfirm = () => {
    this.setState({ confirmationModal: false });
  };

  handleFileDrop = (files, e) => {
    if (e) {
      const uploadFile = files;
      this.startUpload(uploadFile);
      this.setState({ popUp: true });
      return (
        <AttachementPortion
          name="taskDetail"
          testid="task__browse_file"
          isEdit={this.state.taskStatus === 'Closed'}
          handleFileDrop={this.handleFileDrop}
          data={this.state}
          setAttachmentNull={this.setAttachmentNull}
          uploadDocument={this.uploadDocument}
          uploadPopUp="true"
          uploadFiles={this.state.uploadFiles}
          documents={this.state.documents}
          cancelUpload={this.cancelUpload}
          openAttachment={this.openAttachment}
          delAttachmentModal={this.delAttachmentModal}
          component={this}
          dataProps={this.props.taskDetail}
          sharedFolder={this.sharedFolder}
          onedriveSharedFileHandle={this.onedriveSharedFileHandle}
          handleOnedriveSelectedFiles={this.handleOnedriveSelectedFiles}
        />
      );
    }
  };

  handleForceUpdate = () => {
    this.fetchtaskDetail(true);
    this.props.actions.receivedNotification(false);
  };

  // signature modal functions (for get signature);
  closeTemplate = () => {
    this.setState({ showTemplate: false });
    if (window.CKEDITOR && window.CKEDITOR.instances) {
      Object.keys(window.CKEDITOR.instances).map((item) => {
        window.CKEDITOR.instances[item].destroy();
        return true;
      });
    }
  };

  handleContentChange = (e) => {
    const newcontent = e.editor.getData();
    this.setState({ contentAfterChange: newcontent });
  };

  // replace template pdf with new url function
  replaceAgreementUrl = () => {
    const component = this;
    const { params } = this.props;
    const taskId = params.id;
    if (this.props.taskDetail.is_signed) {
      const request = Axios.get(`${Config.REPLACE_AGREEMENT_URL}/${taskId}`);
      request.then((response) => {
        component.setState({ templatePdf: response.data.url }, () => {
          component.setState({ showTemplate: true });
        });
      });
    } else {
      component.setState({ showTemplate: true });
    }
  };

  errorDoc = () => {
    const err = {};
    err.documents = 'Please select document';
    this.setState({ errors: err });
  };

  openPopUp = () => {
    this.setState({ popUp: !this.state.popUp });
  };

  cancelAllUploads() {
    const component = this;
    if (component.state.uploadFiles.length > 0) {
      const tmpHash = {};
      tmpHash.uploadFiles = [];
      const { uploadFiles } = component.state;
      uploadFiles.map((file) => {
        component.state[`${file}sourceToken`].cancel();
        return null;
      });
      component.setState(tmpHash);
    }
    if (component.state.newDocs.length) {
      const docIds = [];
      let x;
      let count = 0;
      for (x = 0; x < component.state.newDocs.length; x += 1) {
        const { id } = component.state.newDocs[x];
        const indx = component.state.documents.findIndex((y) => y.id === id);
        if (indx !== -1) {
          docIds.push(component.state.documents[x].id);
          const { params } = this.props;
          const taskId = params.id;
          let commentId;
          const { commentIdHash } = this.state;
          for (let i = 0; i < commentIdHash.length; i += 1) {
            if (
              commentIdHash[i].attachment_id === component.state.documents[x].id
            ) {
              commentId = commentIdHash[i].comment_id;
            }
          }
          this.setState({ delAttachmentModal: false });
          TaskApi.deleteTaskEditAttachment(
            component.state.documents[x].id,
            this,
            taskId,
            commentId,
          );
          count += 1;
          if (count === component.state.newDocs.length) {
            component.setState({ newDocs: [] });
          }
        }
      }
      // TaskApi.cancelDocuments(component, data, 'tasks');
    }
    if (this.state.errors.documents) {
      const err = this.state.errors;
      err.documents = '';
      this.setState({ errors: err });
    }
  }

  stateUpdate = (value, changetype = '', data = '') => {
    const stateUpdatedValues = { ...value };
    this.setState({
      stateUpdatedValues,
      yearId: '',
      yearValue: '',
      monthId: '',
      monthValue: '',
      selectedTags: [],
    });
    if (changetype === 'isFileModalTaskDetail') {
      this.setState({
        newDocs: [],
        errors: {},
        yearId: '',
        yearValue: '',
        monthId: '',
        monthValue: '',
        selectedTags: [],
      });
    }
    if (changetype === 'isFileModalSubmit' && data) {
      this.setState({
        yearId: value.yearId,
        yearValue: value.yearValue,
        monthId: value.monthId,
        monthValue: value.monthValue,
        selectedTags: data.tags,
        selectedDocuments: data.doc_ids,
        isFileModalSubmit: true,
      });
    }
    this.fetchtaskDetail(true);
  };

  handlePrintPdf = () => {
    this.setState({ renderPdfLayout: !this.state.renderPdfLayout });
  };

  openVault = (input, vaultListData) => {
    this.setState({
      showVault: input,
      vaultListData: vaultListData || this.state.vaultListData,
    });
  };

  manageState = (data, vaultListData) => {
    const { documents } = this.state;
    const { params } = this.props;
    const taskId = params.id;
    const docs = [];
    const doc_ids = [];
    data.documents.map((e) => {
      let sendToBackend = true;
      let justChangeStatus = false;
      documents.map((existing_doc) => {
        if (existing_doc.id === e.id) {
          if (existing_doc.is_active === e.is_active) {
            justChangeStatus = false;
            sendToBackend = false;
          } else {
            justChangeStatus = true;
          }
          existing_doc.is_active = true;
        }
        return existing_doc;
      });
      if (sendToBackend) {
        if (!justChangeStatus) {
          docs.push(e);
        }
        doc_ids.push(e.id);
      }
      return e;
    });
    this.setState({
      documents: documents.concat(docs),
      showVault: false,
      vaultListData,
    });
    if (doc_ids && doc_ids.length) {
      Axios.post(Config.ADD_NEW_FILE_TO_TASK, {
        documents: doc_ids,
        task_id: taskId,
      }).then((response) => {
        if (response.data.status === 200) {
          AlertMessage('success', response.data.message, 3000);
          const newDocuments = this.state.documents;
          if (response.data.data && response.data.data.length) {
            const responseDocs = response.data.data;
            for (let i = 0; i < responseDocs.length; i += 1) {
              const indx = newDocuments.findIndex(
                (e) => e.id === responseDocs[i].id,
              );
              if (indx) {
                newDocuments[indx] = responseDocs[i];
              }
            }
          }
          this.setState({ documents: newDocuments });
          const tmpHash = {};
          const hash = this.state.commentIdHash;
          tmpHash.attachment_id = response.data.data.id;
          tmpHash.comment_id = response.data.comment_hash.id;
          hash.push(tmpHash);
          this.setState({ commentIdHash: hash });
          const commntActivity = this.state.commentsActivity;
          const commntHistory = this.state.commentsHistory;
          commntActivity.unshift(...response.data.comment_hash);
          commntHistory.unshift(...response.data.comment_hash);
          this.setState({
            commentsActivity: commntActivity,
            commentsHistory: commntHistory,
          });
        }
      });
    }
  };

  signDocument = (value) => {
    if (BrowserName && BrowserName.includes('Safari')) {
      const { signing_url, subject } = this.props.taskDetail;
      const openedwindow = window.open(
        signing_url,
        subject,
        'resizable,scrollbars,status',
      );
      const receiveMessage = (event) => {
        let eventType = '';
        if (typeof event.data === 'string') {
          eventType = JSON.parse(event.data).type;
        }
        if (
          event.origin.includes('echosign') &&
          eventType &&
          (eventType.toLowerCase() === 'esign' ||
            eventType.toLowerCase() === 'reject' ||
            eventType.toLowerCase() === 'prefill')
        ) {
          openedwindow.close();
          setTimeout(() => {
            this.fetchtaskDetail(true);
          }, 2000);
          window.removeEventListener('message', null, null);
        }
      };
      window.addEventListener('message', receiveMessage, false);
    } else {
      this.setState({ signDocumentModal: value }, () => {
        if (!value) {
          this.fetchtaskDetail(true);
        }
      });
    }
  };

  toggleSmartVaultWidget = () => {
    this.setState((ps) => ({ showSmartVaultWidget: !ps.showSmartVaultWidget }));
  };

  toggleSharePoint = () => {
    this.setState({ openSharePoint: !this.state.openSharePoint });
  };

  sharedFolder = (key) => {
    if (key === 0) {
      this.setState({
        sharedFilesList: [],
        folderSequence: [],
        onedriveFilesActiveTab: 0,
        noSharedFiles: false,
      });
    } else if (key === 1) {
      const head = {
        headers: { Authorization: `Bearer ${this.state.accessToken}` },
      };
      this.setState({ sharedFilesList: [], folderSequence: [] });
      Axios.get(
        'https://graph.microsoft.com/v1.0/me/drive/sharedWithMe',
        head,
      ).then((response) => {
        if (response.status === 200) {
          if (response.data.value.length === 0) {
            this.setState({ loading: false, noSharedFiles: true });
            // AlertMessage('error', 'No items found in shared list', 1000);
          } else {
            const seq = this.state.folderSequence;
            seq.push({
              name: 'Shared',
              id: 'shared',
              parentReference: { driveId: 'shared' },
              active: true,
              folder: true,
            });
            this.setState({ folderSequence: seq });
            response.data.value.map((item) => {
              const data = item.remoteItem;
              const sharedFiles = this.state.sharedFilesList;
              sharedFiles.push(data);
              this.setState({
                sharedFilesList: sharedFiles,
                onedriveFilesActiveTab: 1,
              });
              return null;
            });
            // this.setState({ loading: false });
          }
        } else {
          // this.setState({ loading: false });
        }
      });
    }
  };

  contactAccountCardLeave = () => {
    // TODO: __HOMEDATA-AUDIT
    if (getStorageData().is_employee) {
      this.setState({
        contactCardID: null,
        contactCardUniqIdentifier: null,
        accountCardID: null,
        accountCardUniqIdentifier: null,
      });
    }
  };

  contactAccountCard = (type, ids, uniqId) => {
    // TODO: __HOMEDATA-AUDIT
    if (getStorageData().is_employee) {
      this.setState(
        {
          contactCardID: null,
          contactCardUniqIdentifier: null,
          accountCardID: null,
          accountCardUniqIdentifier: null,
        },
        () => {
          if (
            type === 'contact' &&
            ids &&
            ids !== ' ' &&
            ids !== null &&
            ids !== undefined
          ) {
            this.setState({
              contactCardID: ids,
              contactCardUniqIdentifier: uniqId,
            });
          }
          if (type === 'account' && ids && ids !== null && ids !== undefined) {
            this.setState({
              accountCardID: ids,
              accountCardUniqIdentifier: uniqId,
            });
          }
        },
      );
    }
  };

  handlePaymentModal = () => {
    this.setState({ showPaymentModal: !this.state.showPaymentModal });
  };

  handleOneDriveUpload = (filesToUpload) => {
    this.startUpload(filesToUpload, 'onedrive');
  };

  render() {
    const data = this.state.homedata;
    const cpaLogo = data ? data.cpa_logo : '';
    const cpaUserId = data ? data.cpa_user_id : '';
    const loggedInUser = data && data.uname ? data.uname.split(' ') : [''];
    const keys = Object.keys(this.props.taskDetail);
    const {
      subject,
      assigne,
      account,
      todo_account_name,
      todo_contact_name,
      todo_contact_avatar,
      todo_contact_initials,
      owner,
      description,
      assigne_avatar,
      initials,
      owner_initials,
      owner_image,
      task_type_color,
      task_type_value,
      task_type_key,
      manage_to_go_type,
      to_go_document_type,
      is_signed,
      template_type,
      is_recurring,
      frequency,
      signing_url,
      assigne_cpa_user_id,
      is_edoc_signed,
      agreement_name,
      edoc_status,
      zoom_meeting_link,
      description_for_web_display,
      invoice_amount,
      invoice_number,
      payment_due_date,
      application_fee_amount,
    } = this.props.taskDetail;
    const {
      taskStatus,
      noTask,
      renderPdfLayout,
      homedata,
      signDocumentModal,
      contactCardID,
      accountCardID,
      contactCardUniqIdentifier,
      accountCardUniqIdentifier,
      showPaymentModal,
      paymentStatus,
      showImagePreview,
      selectedThreadImages,
      selectedImageId,
      documents,
      previewDocument,
    } = this.state;
    const { match, taskDetail } = this.props;
    const dueDateColors = dueDateColor(
      this.props.taskDetail.full_due_date,
      taskStatus,
    );
    let activeDocuments = [];
    if (documents?.length > 0) {
      activeDocuments = documents.filter((item) => item.is_active);
    }
    if (noTask) {
      return <PageNotFound name="message" message="(No Such Task Found)" />;
    }
    if (keys.length && !noTask && !this.state.loading) {
      const updatedDescription = description_for_web_display || description;

      return (
        <div className="TaskDetail" data-testid="taskDetail">
          <div className="center-wrapper">
            {/* will show loading icom when user clicks om archive or close task */}
            {this.state.load && <div id="loading" />}
            <div className="mb-3 pb-3 section-title section-title--bottomborder">
              <div className="col-container">
                <div className="col infoHeader">
                  <h6 role="presentation" className="stepBacklink">
                    <a
                      onClick={() => this.props.navigate(-1)}
                      href="javascript:;"
                      data-testid="backButton"
                    >
                      <i className="icon-left-arrow" /> Back
                    </a>
                  </h6>
                </div>
                <div className="col">
                  <div className="d-flex flex-nowrap btn--leftspace">
                    <CopyToClipboard text={window.location.href}>
                      <a
                        href="javascript:;"
                        className="btn btn-sm btn-outline-light"
                        data-testid="task__copy_link"
                      >
                        Copy Link
                      </a>
                    </CopyToClipboard>
                    {taskStatus !== 'Closed' && task_type_key !== 'edoc' ? (
                      <a
                        onClick={() => this.setState({ popUp: true })}
                        href="javascript:;"
                        id="detailUpload"
                        className="btn btn-sm btn-outline-light btn--onlyicon"
                        data-testid="task__upload_file"
                      >
                        <i className="icon-attachment" aria-hidden="true" />
                      </a>
                    ) : null}
                    {/* <input name="aws_url" type="file" id="upload-detail" onClick={() => this.setAttachmentNull('detailUpload')} onChange={() => this.uploadDocDetail()} hidden multiple />  */}
                    {(data.is_employee && taskStatus !== 'Closed') ||
                    taskStatus === 'Closed' ||
                    this.state.documents.length > 0 ? (
                      <div className="dropdown dropupWrap dropupWrap--right">
                        <a
                          className="btn btn-sm btn-link btn--onlyicon dropdown-toggle"
                          data-toggle="dropdown"
                          data-testid="task_ellipse_button"
                        >
                          <i className="icon-more" aria-hidden="true" />
                          <span className="dropupWrap--caret" />
                        </a>
                        <div className="dropdown-menu">
                          {data.is_employee && taskStatus !== 'Closed' ? (
                            <a
                              href="javascript:;"
                              data-testid="editTask"
                              onClick={() => this.editTask()}
                              className="dropdown-item"
                            >
                              Edit Task
                            </a>
                          ) : null}
                          {this.state.documents.length > 0 && (
                            <a
                              href="javascript:"
                              onClick={this.downloadAttachments}
                              className="dropdown-item"
                            >
                              Download All Attachments
                            </a>
                          )}
                          {data.is_employee &&
                          taskStatus !== 'Closed' &&
                          this.state.taskStatus !== 'Draft' ? (
                            <a
                              href="javascript:"
                              onClick={() => this.archiveTask()}
                              className="dropdown-item"
                            >
                              Archive Task
                            </a>
                          ) : null}
                          {taskStatus === 'Closed' &&
                          task_type_key !== 'virtual_meeting' ? (
                            <a
                              href="javascript:"
                              onClick={() => this.reopenTask()}
                              className="dropdown-item"
                            >
                              Re-Open Task
                            </a>
                          ) : null}
                          <a
                            href="javascript:;"
                            onClick={() => this.handlePrintPdf()}
                            className="dropdown-item"
                          >
                            Print
                          </a>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="taskWrap">
              <div className="taskWrap--inner mb-5">
                <div className="contentHasicon mt-4 mb-4">
                  <div className="contentHasicon--inner clrGreen align-self-center">
                    <i
                      className="iconCircle iconCircle--green icon-tickmark_trans_o"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="taskWrap--header flex-grow-1">
                    <div className="row">
                      <div className="col-md-6 col-sm-12">
                        <span className={`badge ${task_type_color}`}>
                          {task_type_value}
                        </span>
                        <h2 className="mainHeading mb-0">{subject}</h2>
                      </div>
                      <div className="col-md-6 col-sm-12 align-self-center text-right ">
                        <div className="row mr-0 ml-0">
                          {assigne_cpa_user_id === cpaUserId &&
                          task_type_key === 'invoice' &&
                          taskStatus === 'Open' &&
                          paymentStatus === 'unpaid' ? (
                            <div className="col">
                              <PaymentModal
                                data={taskDetail}
                                updateTaskStatus={(
                                  taskstatus,
                                  paymentstatus,
                                ) => {
                                  this.setState(
                                    {
                                      taskStatus: taskstatus,
                                      paymentStatus: paymentstatus,
                                      showPaymentModal: false,
                                    },
                                    () => this.fetchtaskDetail(false),
                                  );
                                }}
                              />
                            </div>
                          ) : (
                            <div className="col" />
                          )}
                          <div>
                            <div className="date text-small mb-1">
                              {is_recurring ? (
                                <span className="text-body">
                                  <i className="icon-Recurring pr-1 vertical-middle text-primary" />
                                  Recurs {frequency}
                                </span>
                              ) : null}
                              <span className={`pl-3 ml-3${dueDateColors}`}>
                                <i
                                  className="icon-calendar mr-2 label-large text-dark"
                                  aria-hidden="true"
                                />
                                {task_type_key === 'virtual_meeting'
                                  ? moment(
                                      this.props.taskDetail.full_due_date,
                                    ).format('MM/DD/YY')
                                  : this.props.taskDetail.due_date}
                              </span>
                              {task_type_key === 'virtual_meeting' &&
                                zoom_meeting_link &&
                                !zoom_meeting_link.includes('calendly') && (
                                  <span
                                    className={`pl-3 ml-3 border-left${dueDateColors}`}
                                  >
                                    <i
                                      className="icon-Clock mr-2 label-large text-dark"
                                      aria-hidden="true"
                                    />
                                    {moment(
                                      this.props.taskDetail.full_due_date,
                                    ).format('h:mm A')}{' '}
                                    {getUserTimeZoneAbbr()}
                                  </span>
                                )}
                              {task_type_key === 'virtual_meeting' && (
                                <span
                                  className={`pl-3 ml-3 border-left${dueDateColors}`}
                                >
                                  {generateDuration(
                                    this.props.taskDetail.meeting_duration,
                                  )}
                                </span>
                              )}
                            </div>
                            <div className="task-status ml-auto align-self-end">
                              <Label
                                htmlFor="status"
                                className="label-medium font-normal mb-0 mr-2 text-uppercase text-dark"
                              >
                                Status :
                              </Label>
                              {/* for get signature -> if task status is open or review and doc is signed status=signed, else if status is open n unsigned status=waiting for sign, else if status is review n doc is unsigned status=review, if status is closed, status=closed */}
                              {task_type_key === 'get_signature' ? (
                                <span className="tag tag--fill">
                                  {taskStatus === 'Closed'
                                    ? 'Closed'
                                    : (taskStatus === 'Open' ||
                                        taskStatus === 'Review') &&
                                      is_signed
                                    ? 'Signed'
                                    : taskStatus === 'Open' && !is_signed
                                    ? 'Waiting for Signature'
                                    : taskStatus === 'Review' && !is_signed
                                    ? 'Review'
                                    : ''}
                                </span> // for other tasks
                              ) : (
                                <span className="tag tag--fill">
                                  {taskStatus}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="formsetValue">
                  <div>
                    {task_type_key === 'to_do' ? (
                      <div className="form-group form-inline-label">
                        <label htmlFor="employee">For Employee</label>
                        <div className="labelValue">
                          <p>{assigne}</p>
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="row">
                    <div className="form-group form-inline-label col-lg-6 col-sm-12">
                      <label htmlFor="account">For Account</label>
                      <div className="labelValue">
                        <p className="AccountName" data-testid="accountName">
                          {todo_account_name ? (
                            todo_account_name === 'N/A' ||
                            todo_account_name === '' ? (
                              '-'
                            ) : (
                              <a
                                data-tip
                                data-for={`${this.props.taskDetail.todo_for_account_id}global_account`}
                                onMouseEnter={() =>
                                  this.contactAccountCard(
                                    'account',
                                    this.props.taskDetail.todo_for_account_id,
                                    this.props.taskDetail.todo_for_account_id,
                                  )
                                }
                              >
                                {todo_account_name}
                              </a>
                            )
                          ) : account === 'N/A' || account === '' ? (
                            '-'
                          ) : (
                            <a
                              data-tip
                              data-for={`${this.props.taskDetail.account_id}global_account`}
                              onMouseEnter={() =>
                                this.contactAccountCard(
                                  'account',
                                  this.props.taskDetail.account_id,
                                  this.props.taskDetail.account_id,
                                )
                              }
                            >
                              {account}
                            </a>
                          )}
                        </p>
                      </div>
                    </div>
                    {task_type_key === 'manage_to_go_items' ? (
                      <div className="form-group form-inline-label col-lg-6 col-sm-12 ">
                        <label htmlFor="account">TO-GO TYPE</label>
                        <div className="labelValue">
                          <p>{manage_to_go_type}</p>
                        </div>
                      </div>
                    ) : null}
                    {task_type_key === 'invoice' ? (
                      <div className="form-group form-inline-label col-lg-6 col-sm-12">
                        <label htmlFor="account">AMOUNT</label>
                        <div className="labelValue">
                          <p>
                            {(invoice_amount / 100).toLocaleString('en-US', {
                              style: 'currency',
                              currency: 'USD',
                            })}
                          </p>
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="row">
                    <div className="form-group form-inline-label col-lg-6 col-sm-12">
                      <label htmlFor="backdrop">
                        For {task_type_key === 'edoc' ? 'Signer' : 'Contact'}
                      </label>
                      <div className="labelValue">
                        {task_type_key === 'to_do' ? (
                          <div className="selectedUser avatar avatar--w20 avatar--border">
                            {todo_contact_avatar ? (
                              <img
                                src={todo_contact_avatar}
                                alt=" "
                                width="20"
                                height="20"
                                className="rounded-circle"
                              />
                            ) : todo_contact_name ? (
                              <span className="uName">
                                {todo_contact_initials}
                              </span>
                            ) : null}
                            {todo_contact_name ? (
                              <a
                                data-tip
                                data-for={`${this.props.taskDetail.todo_for_contact_id}global`}
                                onMouseEnter={() =>
                                  this.contactAccountCard(
                                    'contact',
                                    this.props.taskDetail.todo_for_contact_id,
                                    this.props.taskDetail.todo_for_contact_id,
                                  )
                                }
                              >
                                {todo_contact_name}
                              </a>
                            ) : (
                              '-'
                            )}
                          </div>
                        ) : (
                          <div className="selectedUser avatar avatar--w20 avatar--border">
                            {assigne_avatar ? (
                              <img
                                src={assigne_avatar}
                                alt=" "
                                width="20"
                                height="20"
                                className="rounded-circle"
                              />
                            ) : (
                              <span className="uName">{initials}</span>
                            )}
                            <a
                              data-tip
                              data-for={`${this.props.taskDetail.contact_id}global`}
                              onMouseEnter={() =>
                                this.contactAccountCard(
                                  'contact',
                                  this.props.taskDetail.contact_id,
                                  this.props.taskDetail.contact_id,
                                )
                              }
                            >
                              {assigne}
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                    {task_type_key === 'manage_to_go_items' ? (
                      <div className="form-group form-inline-label col-lg-6 col-sm-12">
                        <label htmlFor="account">DOCUMENT TYPE</label>
                        <div className="labelValue">
                          <p>{to_go_document_type}</p>
                        </div>
                      </div>
                    ) : null}
                    {task_type_key === 'invoice' ? (
                      <div className="form-group form-inline-label col-lg-6 col-sm-12">
                        <label htmlFor="account">PAYMENT DUE DATE</label>
                        <div className="labelValue">
                          <p>{payment_due_date}</p>
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="row">
                    <div className="form-group form-inline-label col-lg-6 col-sm-12">
                      <label htmlFor="taskOwner">
                        {task_type_key === 'edoc' ? 'Requestor' : 'Task Owner'}
                      </label>
                      <div className="labelValue">
                        <div className="avatar avatar--w20 avatar--border avatar--borderPrimary">
                          {owner_image ? (
                            <img
                              src={owner_image}
                              alt="Person"
                              width="20"
                              height="20"
                              className="rounded-circle"
                            />
                          ) : (
                            <span className="uName">{owner_initials}</span>
                          )}
                          {/* {owner} */}
                          {owner.label ? owner.label : owner}
                        </div>
                      </div>
                    </div>
                    {task_type_key === 'invoice' ? (
                      <div className="form-group form-inline-label col-lg-6">
                        <label htmlFor="account">PAYMENT STATUS</label>
                        <div className="labelValue text-capitalize">
                          <p>{paymentStatus}</p>
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {task_type_key === 'invoice' ? (
                    <div className="row">
                      <div
                        className={`form-group form-inline-label ${
                          task_type_key === 'manage_to_go_items' ||
                          task_type_key === 'invoice'
                            ? 'col-6'
                            : 'col-12'
                        }`}
                      >
                        <label htmlFor="account">Invoice No</label>
                        <div className="labelValue">
                          <p>{invoice_number}</p>
                        </div>
                      </div>
                      {data.is_employee && paymentStatus === 'paid' && (
                        <div className="form-group form-inline-label col-6">
                          <label htmlFor="account">AMOUNT COLLECTED</label>
                          <div className="labelValue text-capitalize">
                            <p>
                              {(
                                (invoice_amount - application_fee_amount) /
                                100
                              ).toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                              })}
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  ) : null}
                  {task_type_key === 'virtual_meeting' ? (
                    <div className="form-group form-inline-label">
                      <label htmlFor="taskOwner">Location</label>
                      <div className="labelValue">
                        <p>
                          <a
                            href={zoom_meeting_link}
                            target="_blank"
                            className="p-0"
                            rel="noreferrer"
                          >
                            {zoom_meeting_link}
                          </a>
                        </p>
                      </div>
                    </div>
                  ) : null}
                  <div className="form-group form-inline-label mb-md-5">
                    <label htmlFor="backdrop" className="align-self-start">
                      Description
                    </label>
                    <div className="labelValue TaskDetail__Description">
                      {/* {description ?
                        <p dangerouslySetInnerHTML={{ __html: description }} /> : '-'
                      } */}
                      {signing_url && signing_url !== undefined && (
                        <a
                          className={`d-block px-0${
                            assigne_cpa_user_id !== cpaUserId ||
                            is_edoc_signed ||
                            edoc_status !== 'in_progress'
                              ? ' link-disabled'
                              : ''
                          }`}
                          onClick={
                            assigne_cpa_user_id !== cpaUserId ||
                            is_edoc_signed ||
                            edoc_status !== 'in_progress'
                              ? null
                              : () => this.signDocument(true)
                          }
                          href="javascript:void(0)"
                        >
                          <i className="icon-signature" />{' '}
                          <strong>Click here</strong> to review and sign{' '}
                          <strong>{agreement_name}</strong>
                        </a>
                      )}
                      {description ? (
                        <div
                          className="taskDescription RTE__Description"
                          id="taskDescription"
                          onLoad={this.handleEventListeners}
                        >
                          <ReadOnlyTinyMCE content={updatedDescription} />
                        </div>
                      ) : (
                        '-'
                      )}
                    </div>
                  </div>
                  {/* get signature -----------------------------------------------------------------------------------*/}
                  {task_type_key === 'get_signature' ? (
                    <div className="form-group form-inline-label mb-md-5">
                      <label className="mt-2 pt-1" htmlFor="selectedDocument">
                        Selected Document
                      </label>
                      <div className="labelValue">
                        <div className="col-8 p-0">
                          <div className="attached-file-list">
                            <div className="file-drop-upload">
                              <div className="file-drop-type">
                                <i
                                  className="icon-filetype-pdf"
                                  aria-hidden="true"
                                />
                              </div>
                              <div className="file-drop-info">
                                <div className="file-drop-detail">
                                  {!data.is_employee &&
                                  !is_signed &&
                                  taskStatus === 'Open' ? (
                                    <h6 className="fileName">
                                      <a
                                        className="fileName pr-0"
                                        onClick={() =>
                                          this.setState({ showTemplate: true })
                                        }
                                        href="javascript:void(0)"
                                      >
                                        {template_type === 'engagement_letter'
                                          ? 'Engagement Letter'
                                          : 'Consent Letter'}
                                      </a>
                                    </h6>
                                  ) : (
                                    <h6 className="fileName">
                                      <a
                                        className="fileName pr-0"
                                        onClick={this.replaceAgreementUrl}
                                        href="javascript:void(0)"
                                      >
                                        {template_type === 'engagement_letter'
                                          ? 'Engagement Letter'
                                          : 'Consent Letter'}
                                      </a>
                                    </h6>
                                  )}
                                </div>
                                {!data.is_employee &&
                                !is_signed &&
                                taskStatus === 'Open' ? (
                                  <a
                                    onClick={() =>
                                      this.setState({ showTemplate: true })
                                    }
                                    className="previewFile file-action text-primary"
                                    href="javascript:void(0)"
                                  >
                                    <i
                                      className="icon-signature font-2x vertical-middle"
                                      data-testid="signDocument"
                                    />
                                    Click to sign document
                                  </a>
                                ) : (
                                  <a
                                    onClick={this.replaceAgreementUrl}
                                    className="previewFile file-action"
                                    href="javascript:void(0)"
                                  >
                                    <i className="icon-open-eye" />
                                    View Document
                                  </a>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {/* get signature ends here -----------------------------------------------------------------------------------*/}
                  <div className="form-group form-inline-label mb-md-5">
                    <label
                      htmlFor="backdrop"
                      className="align-self-start mt-3 pt-1"
                    >
                      Attachments
                    </label>
                    <div className="labelValue w-100">
                      <div className="dragWrap col-8 p-0">
                        <AttachementPortion
                          name="taskDetail"
                          testid="task__browse_file"
                          isEdit={taskStatus === 'Closed'}
                          handleFileDrop={this.handleFileDrop}
                          data={this.state}
                          setAttachmentNull={this.setAttachmentNull}
                          uploadDocument={this.uploadDocument}
                          uploadPopUp="true"
                          uploadFiles={this.state.uploadFiles}
                          documents={this.state.documents}
                          cancelUpload={this.cancelUpload}
                          openAttachment={this.openAttachment}
                          delAttachmentModal={this.delAttachmentModal}
                          component={this}
                          dataProps={this.props.taskDetail}
                          openVault={this.openVault}
                          sharedFolder={this.sharedFolder}
                          onedriveSharedFileHandle={
                            this.onedriveSharedFileHandle
                          }
                          handleOnedriveSelectedFiles={
                            this.handleOnedriveSelectedFiles
                          }
                          getAuthenticationToken={this.getAuthenticationToken}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {this.state.taskStatus !== 'Draft' ? (
                  <DetailBottom
                    toggle={this.toggle}
                    data={this.state}
                    changeActiveTab={this.changeActiveTab}
                    fetchtaskDetail={this.fetchtaskDetail}
                    updateTaskDetail={this.updateTaskDetail}
                    onDocumentClick={this.previewOpenAttachment}
                    taskId={this.props.taskDetail.id}
                    props={this.props}
                  />
                ) : null}
              </div>
              {this.state.taskStatus !== 'Draft' ? (
                <TaskCompleteButton
                  markDiv={this.state.markDiv}
                  isEmployee={data.is_employee}
                  taskStatus={taskStatus}
                  taskType={task_type_key}
                  cpaUserId={cpaUserId}
                  assignCpaUserId={assigne_cpa_user_id}
                  isSigned={is_signed}
                  isEdocSigned={taskDetail.is_edoc_signed}
                  edocStatus={edoc_status}
                  loggedInUser={loggedInUser[0]}
                  markComplete={this.markComplete}
                  signDocument={this.signDocument}
                  showEngagementLetter={() =>
                    this.setState({ showTemplate: true })
                  }
                  invoiceStatus={taskDetail.payment_status}
                  taskDetail={taskDetail}
                />
              ) : null}
              {match.pathname.includes('/task/detail') &&
                this.props.notificationAvailable && (
                  <div className="chat-Message">
                    <div className="Chat-Txt">
                      <img
                        src={require('images/lisciosmallest.png')}
                        alt="Avatar"
                        className="avatar chat-Img"
                      />
                      <h2>Task Detail Updated</h2>
                    </div>
                    <div className="Chat-show">
                      <Button
                        onClick={this.handleForceUpdate}
                        className="btn-link btn--onlyicon btn btn-secondary cross-btn"
                        data-testid="task__cancel_upload"
                      >
                        Show
                      </Button>
                      <span
                        onClick={() => {
                          this.props.actions.receivedNotification(false);
                        }}
                      >
                        <i className="icon-close2" aria-hidden="true" />
                      </span>
                    </div>
                  </div>
                )}
            </div>
            <Modal
              isOpen={this.state.showAttachmentModal}
              className="customModal customModal--w990 customModal--nopadding modalMxHeight"
            >
              <ModalHeader className="ModalHeader">
                <button
                  type="button"
                  onClick={() => this.attachmentModalClose()}
                  className="close"
                  aria-label="Close"
                  data-testid="task__cancel_upload"
                >
                  <i className="icon-close2" aria-hidden="true" />
                </button>
              </ModalHeader>
              <ModalBody className="modalContent">
                <div className="modalContent--inner">
                  <div
                    className={
                      isIE ? 'left-inner-content' : 'sample-letter-preview'
                    }
                  >
                    <div className="letter-logo" />
                    {isIE ? (
                      <PdfViewer file={this.state.selectedAttachment} />
                    ) : (
                      <iframe
                        width="100%"
                        height="600"
                        name="myfilename"
                        src={this.state.selectedAttachment}
                        title="selected attachment"
                      />
                    )}
                  </div>
                </div>
              </ModalBody>
            </Modal>
            <ConfirmModal
              isOpen={this.state.delAttachmentModal}
              yesBtnClick={this.deleteAttachment}
              noBtnClick={this.closeDelModal}
              headerText="You are about to delete the attached file."
              messageText="This action cannot be undone. Proceed?"
              yesBtnText="Yes"
              noBtnText="No"
            />
            <ConfirmModal
              isOpen={this.state.confirmationModal}
              className="customModal customModal--center customModal--alert customModal--w500 customModal--nopadding"
              messageText="You are about to mark this task as closed. Proceed?"
              yesBtnText="Proceed"
              noBtnText="Cancel"
              yesBtnClick={this.closeTask}
              noBtnClick={this.cancelConfirm}
            />
            <SignatureModal
              name="taskdetail"
              data={this.state}
              cpaLogo={cpaLogo}
              doctype={template_type}
              taskDetail={this.props.taskDetail}
              closeTemplate={this.closeTemplate}
              handleContentChange={this.handleContentChange}
              fetchtaskDetail={this.fetchtaskDetail}
              updateTaskDetail={this.updateTaskDetail}
            />
            {signDocumentModal && (
              <SignDocumentModal
                isOpen={signDocumentModal}
                noBtnClick={() => this.signDocument(false)}
                signingUrl={signing_url}
              />
            )}
          </div>
          {this.state.previewScreen && (
            <Modal
              className="Md-full-width modal-FullH"
              isOpen={this.state.previewScreen}
            >
              <PreviewAllAttachments
                uploadType="task"
                fro="taskDetail"
                docs={activeDocuments}
                taskDetail={this.props.taskDetail}
                selectedDocument={previewDocument}
                closePreview={this.previewCloseAttachment}
                delAttachmentModal={this.delAttachmentModal}
              />
            </Modal>
          )}
          {renderPdfLayout ? (
            <TaskPdfLayout
              data={{ ...this.state, ...this.props.taskDetail }}
              handlePrintPdf={this.handlePrintPdf}
            />
          ) : null}
          {/* lisco vault */}
          <Modal
            isOpen={this.state.showVault}
            className="customModal customModal--nopadding"
          >
            <div className="ModalHeader">
              <button
                onClick={() => this.openVault(false)}
                type="button"
                className="close"
                aria-label="Close"
                data-testid="task__cancel_upload"
              >
                <i className="icon-close2" aria-hidden="true" />
              </button>
            </div>
            <ModalBody>
              {homedata && homedata.is_employee ? (
                <LiscioVaultList
                  updateDocuments={this.manageState}
                  openVault={this.openVault}
                  selecteddocuments={[...this.state.documents]}
                  isVisible={this.state.showVault}
                  getOnlyPrivateFilesinLiscioVault={false}
                  vaultListData={this.state.vaultListData}
                  page="task"
                />
              ) : (
                <ClientLiscioVault
                  updateDocuments={this.manageState}
                  openVault={this.openVault}
                  selecteddocuments={[...this.state.documents]}
                  isVisible={this.state.showVault}
                  vaultListData={this.state.vaultListData}
                />
              )}
            </ModalBody>
          </Modal>
          {contactCardID && contactCardID !== null ? (
            <ContactCard
              id={contactCardID}
              uniqId={contactCardUniqIdentifier}
              page="taskdetail"
            />
          ) : null}
          {accountCardID && accountCardID !== null ? (
            <AccountCard
              id={accountCardID}
              uniqId={accountCardUniqIdentifier}
              page="taskdetail"
            />
          ) : null}
          {signing_url &&
          signing_url !== undefined &&
          assigne_cpa_user_id === cpaUserId ? (
            <AdobeLogout />
          ) : null}
          {showPaymentModal ? (
            <PaymentModal
              noBtnClick={this.handlePaymentModal}
              data={taskDetail}
              updateTaskStatus={(taskstatus, paymentstatus) => {
                this.setState(
                  {
                    taskStatus: taskstatus,
                    paymentStatus: paymentstatus,
                    showPaymentModal: false,
                  },
                  () => this.fetchtaskDetail(false),
                );
              }}
            />
          ) : null}
          {showImagePreview &&
            selectedThreadImages?.length > 0 &&
            selectedImageId && (
              <ImagePreview
                toggle={this.toggleImagePreview}
                items={selectedThreadImages}
                selectedImageId={selectedImageId}
              />
            )}
        </div>
      );
    }
    return (
      <div id="loading" style={this.state.loading ? blockStyle : noneStyle} />
    );
  }
}

const mapStateToProps = (state) => ({
  taskDetail: state.taskReducer.taskDetail,
  notificationAvailable: state.taskReducer.notificationAvailable,
  tasks: state.taskReducer.tasks,
  taskStatus: state.taskReducer.taskStatus,
  task_page: state.paginationReducer.task_page,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
  NotificationActions: bindActionCreators(NotificationActions, dispatch),
  pagination: bindActionCreators(paginationActions, dispatch),
});

export default withStorageData(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(TaskDetail)),
);
