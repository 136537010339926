import { styled } from '@mui/material';
import IconTextField from 'common/IconTextField';

const StyleDiv = styled('div')({
  marginRight: '15px',
  minWidth: '250px',
  '.customInput': {
    maxHeight: '36px',
  },
});

const SearchMessage = ({ searchInput, onChange }) => (
  <StyleDiv>
    <IconTextField
      className="customInput"
      placeHolder="Search Message"
      onChange={onChange}
      value={searchInput}
    />
  </StyleDiv>
);
export default SearchMessage;
