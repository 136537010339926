import { useState, useEffect } from 'react';

import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';

/**
 * Helper hook to handle launchdarkly initialization state
 * when needing to wait for flags.
 */
export const useInitializedFlags = () => {
  const [isInitialized, setIsInitialized] = useState(false);
  const [error, setError] = useState<unknown>(null);
  const ldClient = useLDClient();
  const flags = useFlags();

  useEffect(() => {
    if (ldClient) {
      ldClient
        .waitForInitialization()
        .then(() => setIsInitialized(true))
        .catch((err) => {
          setError(err);
          setIsInitialized(true);
        });
    }
  }, [ldClient]);

  return {
    isInitialized,
    error,
    flags,
  };
};
