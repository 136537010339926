/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom-latest';
import axios from 'axios';
import { SEARCH_TASK_ACCOUNTS } from 'constants/constants';
import * as actions from 'actions/TaskActions.js';
import * as paginationActions from 'actions/PaginationActions.js';
import * as TaskApi from 'api/TaskApi';
import EmployeeAndContactFilter from '../../Filters/EmployeeAndContactFilter';
import AccountFilter from '../../Filters/AccountFilter';
import { getStorageData, isUserEmployee } from 'utilities/utils';

const Button1 = [
  {
    name: 'Tasks assigned to me',
    id: 'assigned_to_me',
    text: 'Tasks assigned to me',
  },
  {
    name: "Tasks I've assigned",
    id: 'assigned_by_me',
    text: "Tasks I've assigned",
  },
];
const PER_PAGE = 50;
const FILTER_NAMES = {
  myTasks: 'MY TASKS',
  type: 'TYPE',
  owner: 'OWNER',
  account: 'ACCOUNT',
  assignedTo: 'ASSIGNED TO',
};

class FilterTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTask: '',
      selectedTaskId: '',
      selectedOwnerId: '',
      selectedOwnerName: '',
      selectedAccountName: '',
      selectedAccountId: '',
      selectedAssigneId: '',
      selectedAssigneName: '',
      owners: [],
      accounts: [],
      allContacts: [],
      initialContacts: [],
      initialAccounts: [],
      checkboxArray: [],
      checkbox: [
        {
          get_signature: false,
          onboard_account: false,
          manage_to_go_items: false,
          request_info: false,
          send_message: false,
          to_do: false,
          edoc: false,
        },
      ],
      disableAssigne: false,
      disableOwner: false,
      disableMyTasks: false,
      inputValue: '',
      filterlistdiv: false,
      homedata: '',
      isLoading: false,
      options: [],
      query: '',
      isReccuring: false,
      // filterList: [],
      filterInFocus: '',
      filterBGClicked: props.filterBGClicked,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.filterBGClicked !== state.filterBGClicked) {
      return { filterBGClicked: props.filterBGClicked, filterInFocus: '' };
    }
    return null;
  }
  setFilterInFocus = (filterName = '', e = {}) => {
    e.stopPropagation && e.stopPropagation();
    this.setState({
      filterInFocus: this.state.filterInFocus === filterName ? '' : filterName,
    });
  };

  hideOpenFilters = () => {
    this.setState({ filterInFocus: '' });
  };

  componentDidMount() {
    // TODO: __HOMEDATA-AUDIT
    const homeData = getStorageData();
    const { api } = this.props;
    if (api && api.length && !window.location.href.includes('/bulkreassign')) {
      this.setState(this.props.selectedFilters);
      this.setState(
        {
          filterlistdiv: true,
        },
        () => {
          this.props.filtered(true);
        },
      );
    }
    this.setState({ homedata: homeData });
  }
  allTasksDropdown(type) {
    if (type.id === 'assigned_to_me') {
      this.setState({
        disableAssigne: true,
        disableOwner: false,
        selectedAssigneId: '',
        selectedAssigneName: '',
      });
    } else if (type.id === 'assigned_by_me') {
      this.setState({
        disableOwner: true,
        disableAssigne: false,
        selectedOwnerId: '',
        selectedOwnerName: '',
      });
    } else if (
      type.target.id &&
      type.target.id === 'isRecurring' &&
      type.target.checked
    ) {
      this.setState({ isReccuring: type.target.checked });
    } else if (
      type.target.id &&
      type.target.id === 'isRecurring' &&
      !type.target.checked
    ) {
      this.setState({ isReccuring: type.target.checked });
    }
    this.setState(
      { selectedTask: type.text, selectedTaskId: type.id, filterInFocus: '' },
      () => {
        this.filterData();
        this.props.actions.handleFilters(this.state);
      },
    );
  }
  delSelected(selectedId, selectedName) {
    if (selectedId === 'selectedTaskId') {
      this.setState({ disableAssigne: false, disableOwner: false });
    }
    this.setState({ [selectedId]: '', [selectedName]: '' }, () => {
      this.filterData();
      this.props.actions.handleFilters(this.state);
    });
  }
  handleCheckbox(e, item) {
    const checkboxState = this.state.checkbox;
    checkboxState[0][e.target.name] = e.target.checked;
    this.setState({ checkbox: checkboxState });
    const options = this.state.checkboxArray;
    if (e.target.checked) {
      options.push(item); // or e.target.name
    } else {
      const indexx = options.map((element, index) => {
        if (element.key === e.target.name) {
          return index;
        }
        return null;
      });
      const ele = indexx.find((el) => el !== null);
      options.splice(ele, 1);
    }
    this.setState({ checkboxArray: options, filterInFocus: FILTER_NAMES.type });
    this.filterData();
    this.props.actions.handleFilters(this.state);
  }
  delCheckbox(type, id) {
    const checkboxState = this.state.checkbox;
    checkboxState[0][type] = false;
    this.setState({ checkbox: checkboxState });
    const options = this.state.checkboxArray;
    const indexx = options.map((item, index) => {
      if (item.key === type) {
        return index;
      }
      return null;
    });
    const ele = indexx.find((el) => el !== null);
    options.splice(ele, 1);
    this.setState({ checkboxArray: options }, () => {
      this.filterData();
      this.props.actions.handleFilters(this.state);
    });
  }
  handleDropdown(item, selectedId, selectedName, type = '') {
    this.setState(
      { [selectedId]: item.value, [selectedName]: item.label },
      () => {
        this.filterData();
        this.props.actions.handleFilters(this.state);

        setTimeout(() => {
          this.clearInput(type);
        }, 100);
      },
    );
  }
  filterData() {
    const { name } = this.props;
    if (name && name === 'bulk') {
      this.props.filtered();
    }
    const taskTypeArray = this.state.checkboxArray;
    const string = taskTypeArray.map((item) => item.key).join(',');
    const apiPath =
      (this.state.selectedTaskId
        ? '&my_tasks=' + this.state.selectedTaskId
        : '') +
      (this.state.selectedOwnerId &&
      this.state.selectedTaskId !== 'assigned_by_me'
        ? '&owner=' + this.state.selectedOwnerId
        : '') +
      (this.state.selectedAccountId
        ? '&account=' + this.state.selectedAccountId
        : '') +
      (this.state.selectedAssigneId &&
      this.state.selectedTaskId !== 'assigned_to_me'
        ? '&assignee=' + this.state.selectedAssigneId
        : '') +
      (string ? '&task_type_key=' + string : '') +
      (this.state.isReccuring ? '&is_recurring=true' : '');
    this.props.actions.handleApiUrl(apiPath);
    this.props.pagination.taskPagination(1);
    if (name && name === 'bulk') {
      this.props.actions.fetchTasks(
        this.props.component,
        1,
        this.props.sort,
        this.props.field,
        apiPath,
        false,
        null,
        'bulk',
      );
    } else {
      this.props.actions.fetchTasks(
        this.props.component,
        1,
        this.props.sort,
        this.props.field,
        apiPath,
      );
    }
    if (apiPath) {
      this.setState({ filterlistdiv: true }, () => {
        this.props.filtered(true);
      });
    } else {
      this.setState({ filterlistdiv: false }, () => {
        this.props.filtered(false);
      });
    }
  }
  clearAll() {
    const checkboxState = this.state.checkbox;
    Object.keys(checkboxState[0]).map((item) => {
      checkboxState[0][item] = false;
    });
    this.setState(
      {
        selectedTask: '',
        selectedTaskId: '',
        selectedOwnerId: '',
        selectedOwnerName: '',
        selectedAccountName: '',
        selectedAccountId: '',
        selectedAssigneId: '',
        selectedAssigneName: '',
        checkboxArray: [],
        checkbox: checkboxState,
        disableAssigne: !this.state.homedata.is_employee,
        disableOwner: false,
        isReccuring: false,
      },
      () => {
        this.filterData();
        this.props.actions.handleFilters(this.state);
      },
    );
  }
  clearInput(dropdownName) {
    const component = this;
    component.setState(
      { inputValue: '', allContacts: component.state.initialContacts },
      () => {
        component.filterSearch('', dropdownName);
      },
    );
  }
  filterSearch = async (target, dropdownName) => {
    const component = this;
    component.setState({ inputValue: target });
    if (dropdownName === 'assigneDropdown') {
      if (component.state.inputValue.length < 2) {
        return component.setState({
          allContacts: component.state.initialContacts,
        });
      }
      const response_assignee = axios.get(
        SEARCH_TASK_CONTACTS + '/?keyword=' + target,
      );
      response_assignee.then((res) => {
        if (res.status === 200) {
          component.setState({ allContacts: res.data });
        }
      });
    } else if (dropdownName === 'accountDropdown') {
      if (component.state.inputValue.length < 2) {
        return component.setState({
          accounts: component.state.initialAccounts,
        });
      }
      const response_account = await axios.get(
        SEARCH_TASK_ACCOUNTS + '/?keyword=' + target,
      );
      if (response_account.status === 200) {
        component.setState({ accounts: response_account.data });
      }
    }
    // Please use comments for logics that only you and god can understand.
  };
  render() {
    // TODO: __HOMEDATA-AUDIT
    const storageData = getStorageData();
    const { name, bulkState, data, taskStatus } = this.props;
    let prefTypes = [];
    let ownerDisable = false;
    if (storageData && storageData.pref_type_options) {
      prefTypes = storageData.pref_type_options.cpa_task_type;
      const edoc = {
        is_descending: false,
        key: 'edoc',
        value: 'edoc',
        label: 'eDoc',
      };
      prefTypes.splice(prefTypes.length + 1, 1, edoc);
      prefTypes = prefTypes.filter((item) => {
        if (
          storageData.is_employee &&
          (item.key === 'onboard_account' || item.key === 'send_message')
        ) {
          return false;
        } else if (
          !storageData.is_employee &&
          (item.key === 'onboard_account' ||
            item.key === 'send_message' ||
            item.key === 'to_do')
        ) {
          return false;
        }
        return true;
      });
      if (storageData.role === 'specialist') {
        ownerDisable = true;
      }
    }
    const { myTasks, type, account, owner, assignedTo } = FILTER_NAMES;
    const { filterInFocus } = this.state;
    return (
      <div className="filter-wrapper d-flex justify-content-between">
        <div className="d-flex flex-wrap filterPart--archive">
          {/*
            <div className="form-group has-search">
              <span className="icon-zoom2 form-control-feedback" />
              <input type="text" className="form-control" placeholder="Search" />
            </div> */}
          <div className="filterGroup d-flex flex-wrap">
            <div className="d-flex flex-wrap bd-highlight">
              <label htmlFor="filter" className="mt-2 mr-1 m-0">
                Filter by
              </label>
              <div
                className={`dropdown ${
                  filterInFocus === myTasks ? 'show' : ''
                }`}
                style={{ marginLeft: '10px' }}
              >
                <button
                  type="button"
                  className={
                    this.state.selectedTask
                      ? 'btn btn-outline-light btn-sm dropdown-toggle active'
                      : 'btn btn-sm  btn-outline-light dropdown-toggle'
                  }
                  disabled={this.state.disableMyTasks || !isUserEmployee()}
                  onClick={(e) => this.setFilterInFocus(myTasks, e)}
                  data-testid="filter-by-button"
                >
                  {myTasks}
                </button>
                <div
                  className={`dropdown-menu ${
                    filterInFocus === myTasks ? 'show' : ''
                  }`}
                >
                  {Button1.map((item) => (
                    <a
                      key={item.id}
                      id={item.id}
                      onClick={() => this.allTasksDropdown(item)}
                      className="dropdown-item"
                      data-testid={`dropdown-item-${item?.name
                        ?.toLowerCase()
                        ?.replace(/\s+/g, '-')}`}
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
              </div>
              <div
                className={`dropdown ${filterInFocus === type ? 'show' : ''}`}
                style={{ marginLeft: '10px' }}
              >
                <button
                  type="button"
                  className={
                    this.state.checkboxArray.length
                      ? 'btn btn-outline-light btn-sm dropdown-toggle active'
                      : 'btn btn-outline-light btn-sm dropdown-toggle'
                  }
                  onClick={(e) => this.setFilterInFocus(type, e)}
                  data-testid={`dropdown-button-${type?.toLowerCase()}`}
                >
                  {type}
                </button>
                <div
                  className={`dropdown-menu ${
                    filterInFocus === type ? 'show' : ''
                  }`}
                >
                  {prefTypes.map((item) => (
                    <span key={item.key} className="dropdown-item">
                      <div className="checkbox checkbox-primary check-container">
                        <label htmlFor={item.key} className="check-container">
                          <span className="pl-2">{item.label}</span>
                          <input
                            checked={this.state.checkbox[0][item.key]}
                            onChange={(e) => this.handleCheckbox(e, item)}
                            type="checkbox"
                            data-testid={`${item?.key}Checkbox`}
                            id={item.key}
                            name={item.key}
                          />
                          <span className="checkmark" />
                        </label>
                      </div>
                    </span>
                  ))}
                </div>
              </div>
              <EmployeeAndContactFilter
                filterSelected={(e, input) =>
                  this.handleDropdown(
                    input,
                    'selectedOwnerId',
                    'selectedOwnerName',
                    'ownerDropdown',
                  )
                }
                type="Employee"
                filterName={owner}
                isActive={this.state.selectedOwnerName}
                disabled={this.state.disableOwner || ownerDisable}
                setFilterInFocus={this.setFilterInFocus}
                showFilterOptions={
                  this.state.filterInFocus === FILTER_NAMES.owner
                }
                data-testid="owner-filter"
              />
              <AccountFilter
                filterSelected={(e, input) =>
                  this.handleDropdown(
                    input,
                    'selectedAccountId',
                    'selectedAccountName',
                    'accountDropdown',
                  )
                }
                filterName="ACCOUNT"
                isActive={this.state.selectedAccountName}
                setFilterInFocus={this.setFilterInFocus}
                showFilterOptions={
                  this.state.filterInFocus === FILTER_NAMES.account
                }
                data-testid="account-filter"
              />
              <EmployeeAndContactFilter
                filterSelected={(e, input) =>
                  this.handleDropdown(
                    input,
                    'selectedAssigneId',
                    'selectedAssigneName',
                    'assigneDropdown',
                  )
                }
                type="EmployeeandContact"
                filterName="ASSIGNED TO"
                isActive={this.state.selectedAssigneName}
                disabled={this.state.disableAssigne || !isUserEmployee()}
                setFilterInFocus={this.setFilterInFocus}
                showFilterOptions={
                  this.state.filterInFocus === FILTER_NAMES.assignedTo
                }
                data-testid="assignee-filter"
              />
              <div className="align-self-center ml-2">
                <div className="checkbox checkbox-primary check-container">
                  <label htmlFor="isRecurring" className="check-container m-0">
                    <input
                      checked={this.state.isReccuring}
                      onChange={(e) => this.allTasksDropdown(e)}
                      type="checkbox"
                      id="isRecurring"
                      name="isRecurring"
                      data-testid="recurring-checkbox"
                    />
                    <span className="checkmark" />
                    <span className="pl-2">Recurring Task</span>
                  </label>
                </div>
              </div>
              {this.state.filterlistdiv ? (
                <div className="align-self-center ml-2 clearFilter">
                  {' '}
                  <a
                    onClick={() => this.clearAll()}
                    data-testid="clear-all-button"
                  >
                    <i className="icon-close2 label-small" />
                    Clear All
                  </a>
                </div>
              ) : null}
            </div>
            {this.state.filterlistdiv ? (
              <div className="select-filter-list w-100">
                {this.state.selectedTask ? (
                  <div
                    className="badge-filter"
                    data-testid="selected-task-badge"
                  >
                    {this.state.selectedTask}
                    <a>
                      <i
                        tabIndex={0}
                        role="button"
                        onMouseDown={() =>
                          this.delSelected('selectedTaskId', 'selectedTask')
                        }
                        className="icon-close2"
                        data-testid="close-selected-task-button"
                      />
                    </a>
                  </div>
                ) : (
                  ''
                )}
                {this.state.checkboxArray.length
                  ? this.state.checkboxArray.map((item, index) => (
                      <div key={index} className="badge-filter">
                        {item.label}
                        <a>
                          <i
                            tabIndex={0}
                            role="button"
                            className="icon-close2"
                            onMouseDown={() =>
                              this.delCheckbox(item.key, index)
                            }
                            data-testid={`close-checkbox-button-${index}`}
                          />
                        </a>
                      </div>
                    ))
                  : ''}
                {this.state.selectedOwnerName ? (
                  <div className="badge-filter">
                    {this.state.selectedOwnerName}
                    <a>
                      <i
                        tabIndex={0}
                        role="button"
                        className="icon-close2"
                        onMouseDown={() =>
                          this.delSelected(
                            'selectedOwnerId',
                            'selectedOwnerName',
                          )
                        }
                        data-testid="close-selected-owner-button"
                      />
                    </a>
                  </div>
                ) : (
                  ''
                )}
                {this.state.selectedAccountName ? (
                  <div className="badge-filter">
                    {this.state.selectedAccountName}
                    <a>
                      <i
                        tabIndex={0}
                        role="button"
                        className="icon-close2"
                        onMouseDown={() =>
                          this.delSelected(
                            'selectedAccountId',
                            'selectedAccountName',
                          )
                        }
                        data-testid="close-selected-account-button"
                      />
                    </a>
                  </div>
                ) : (
                  ''
                )}
                {this.state.selectedAssigneName ? (
                  <div className="badge-filter">
                    {this.state.selectedAssigneName}
                    <a>
                      <i
                        tabIndex={0}
                        role="button"
                        className="icon-close2"
                        onMouseDown={() =>
                          this.delSelected(
                            'selectedAssigneId',
                            'selectedAssigneName',
                          )
                        }
                        data-testid="close-selected-assigned-button"
                      />
                    </a>
                  </div>
                ) : (
                  ''
                )}
              </div>
            ) : null}
          </div>
        </div>
        {storageData && storageData.is_employee && name && name !== 'bulk' ? (
          <div className="FilterHeader--Action">
            {taskStatus !== 'Archive' ? (
              <button
                className="btn btn-primary mr-3"
                onClick={() => this.props.massArchive(false)}
                disabled={!data.selectedCheckbox.length}
                data-testid="archive-button"
              >
                <i className="icon-archive" /> Archive
              </button>
            ) : null}
            <Link
              to="/task/new"
              className="text-right btn btn-primary"
              data-testid="new-task-button"
            >
              <i>+</i> Task
            </Link>
          </div>
        ) : null}
        {name && name === 'bulk' && bulkState && bulkState.maxCount > 0 ? (
          <div className="FilterHeader--Action text-right flex-shrink-1">
            <span
              className="badge badge-primary subHeadline"
              data-testid="bulk-selection-count"
            >
              Select up to {bulkState.maxCount} tasks{' '}
              {bulkState.selectedData.length}/{bulkState.maxCount}
            </span>
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedFilters: state.taskReducer.taskFilters,
  api: state.taskReducer.apiUrl,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
  pagination: bindActionCreators(paginationActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterTab);
