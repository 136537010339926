/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * API V5
 * OpenAPI spec version: v5
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  ForbiddenErrorObject,
  GetApiV5FirmsCpaWorkflowTemplates200Item,
  PostApiV5FirmsCpaWorkflowTemplatesBody,
  PostApiV5FirmsCpaWorkflowTemplatesBulkBodyItem,
  PutApiV5FirmsCpaWorkflowTemplatesBulkBodyItem,
  PutApiV5FirmsCpaWorkflowTemplatesIdBody,
  Uuid
} from '.././schemas'
import postApiV5FirmsCpaWorkflowTemplatesBulkMutator from '../../configOrvalAPIV5';
import type { ErrorType as PostApiV5FirmsCpaWorkflowTemplatesBulkErrorType } from '../../configOrvalAPIV5';
import putApiV5FirmsCpaWorkflowTemplatesBulkMutator from '../../configOrvalAPIV5';
import type { ErrorType as PutApiV5FirmsCpaWorkflowTemplatesBulkErrorType } from '../../configOrvalAPIV5';
import getApiV5FirmsCpaWorkflowTemplatesMutator from '../../configOrvalAPIV5';
import type { ErrorType as GetApiV5FirmsCpaWorkflowTemplatesErrorType } from '../../configOrvalAPIV5';
import postApiV5FirmsCpaWorkflowTemplatesMutator from '../../configOrvalAPIV5';
import type { ErrorType as PostApiV5FirmsCpaWorkflowTemplatesErrorType } from '../../configOrvalAPIV5';
import putApiV5FirmsCpaWorkflowTemplatesIdMutator from '../../configOrvalAPIV5';
import type { ErrorType as PutApiV5FirmsCpaWorkflowTemplatesIdErrorType } from '../../configOrvalAPIV5';
import deleteApiV5FirmsCpaWorkflowTemplatesIdMutator from '../../configOrvalAPIV5';
import type { ErrorType as DeleteApiV5FirmsCpaWorkflowTemplatesIdErrorType } from '../../configOrvalAPIV5';



/**
 * @summary Create records
 */
export const postApiV5FirmsCpaWorkflowTemplatesBulk = (
    postApiV5FirmsCpaWorkflowTemplatesBulkBodyItem: PostApiV5FirmsCpaWorkflowTemplatesBulkBodyItem[],
 ) => {
      
      
      return postApiV5FirmsCpaWorkflowTemplatesBulkMutator<void>(
      {url: `/api/v5/firms/cpa_workflow_templates/bulk`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postApiV5FirmsCpaWorkflowTemplatesBulkBodyItem
    },
      );
    }
  


export const getPostApiV5FirmsCpaWorkflowTemplatesBulkMutationOptions = <TError = PostApiV5FirmsCpaWorkflowTemplatesBulkErrorType<ForbiddenErrorObject>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV5FirmsCpaWorkflowTemplatesBulk>>, TError,{data: PostApiV5FirmsCpaWorkflowTemplatesBulkBodyItem[]}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postApiV5FirmsCpaWorkflowTemplatesBulk>>, TError,{data: PostApiV5FirmsCpaWorkflowTemplatesBulkBodyItem[]}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV5FirmsCpaWorkflowTemplatesBulk>>, {data: PostApiV5FirmsCpaWorkflowTemplatesBulkBodyItem[]}> = (props) => {
          const {data} = props ?? {};

          return  postApiV5FirmsCpaWorkflowTemplatesBulk(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiV5FirmsCpaWorkflowTemplatesBulkMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV5FirmsCpaWorkflowTemplatesBulk>>>
    export type PostApiV5FirmsCpaWorkflowTemplatesBulkMutationBody = PostApiV5FirmsCpaWorkflowTemplatesBulkBodyItem[]
    export type PostApiV5FirmsCpaWorkflowTemplatesBulkMutationError = PostApiV5FirmsCpaWorkflowTemplatesBulkErrorType<ForbiddenErrorObject>

    /**
 * @summary Create records
 */
export const usePostApiV5FirmsCpaWorkflowTemplatesBulk = <TError = PostApiV5FirmsCpaWorkflowTemplatesBulkErrorType<ForbiddenErrorObject>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV5FirmsCpaWorkflowTemplatesBulk>>, TError,{data: PostApiV5FirmsCpaWorkflowTemplatesBulkBodyItem[]}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postApiV5FirmsCpaWorkflowTemplatesBulk>>,
        TError,
        {data: PostApiV5FirmsCpaWorkflowTemplatesBulkBodyItem[]},
        TContext
      > => {

      const mutationOptions = getPostApiV5FirmsCpaWorkflowTemplatesBulkMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Update records
 */
export const putApiV5FirmsCpaWorkflowTemplatesBulk = (
    putApiV5FirmsCpaWorkflowTemplatesBulkBodyItem: PutApiV5FirmsCpaWorkflowTemplatesBulkBodyItem[],
 ) => {
      
      
      return putApiV5FirmsCpaWorkflowTemplatesBulkMutator<void>(
      {url: `/api/v5/firms/cpa_workflow_templates/bulk`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: putApiV5FirmsCpaWorkflowTemplatesBulkBodyItem
    },
      );
    }
  


export const getPutApiV5FirmsCpaWorkflowTemplatesBulkMutationOptions = <TError = PutApiV5FirmsCpaWorkflowTemplatesBulkErrorType<ForbiddenErrorObject>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV5FirmsCpaWorkflowTemplatesBulk>>, TError,{data: PutApiV5FirmsCpaWorkflowTemplatesBulkBodyItem[]}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof putApiV5FirmsCpaWorkflowTemplatesBulk>>, TError,{data: PutApiV5FirmsCpaWorkflowTemplatesBulkBodyItem[]}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiV5FirmsCpaWorkflowTemplatesBulk>>, {data: PutApiV5FirmsCpaWorkflowTemplatesBulkBodyItem[]}> = (props) => {
          const {data} = props ?? {};

          return  putApiV5FirmsCpaWorkflowTemplatesBulk(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutApiV5FirmsCpaWorkflowTemplatesBulkMutationResult = NonNullable<Awaited<ReturnType<typeof putApiV5FirmsCpaWorkflowTemplatesBulk>>>
    export type PutApiV5FirmsCpaWorkflowTemplatesBulkMutationBody = PutApiV5FirmsCpaWorkflowTemplatesBulkBodyItem[]
    export type PutApiV5FirmsCpaWorkflowTemplatesBulkMutationError = PutApiV5FirmsCpaWorkflowTemplatesBulkErrorType<ForbiddenErrorObject>

    /**
 * @summary Update records
 */
export const usePutApiV5FirmsCpaWorkflowTemplatesBulk = <TError = PutApiV5FirmsCpaWorkflowTemplatesBulkErrorType<ForbiddenErrorObject>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV5FirmsCpaWorkflowTemplatesBulk>>, TError,{data: PutApiV5FirmsCpaWorkflowTemplatesBulkBodyItem[]}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof putApiV5FirmsCpaWorkflowTemplatesBulk>>,
        TError,
        {data: PutApiV5FirmsCpaWorkflowTemplatesBulkBodyItem[]},
        TContext
      > => {

      const mutationOptions = getPutApiV5FirmsCpaWorkflowTemplatesBulkMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary List of records including the associated workflow_template.template
 */
export const getApiV5FirmsCpaWorkflowTemplates = (
    
 signal?: AbortSignal
) => {
      
      
      return getApiV5FirmsCpaWorkflowTemplatesMutator<GetApiV5FirmsCpaWorkflowTemplates200Item[]>(
      {url: `/api/v5/firms/cpa_workflow_templates`, method: 'GET', signal
    },
      );
    }
  

export const getGetApiV5FirmsCpaWorkflowTemplatesQueryKey = () => {
    return [`/api/v5/firms/cpa_workflow_templates`] as const;
    }

    
export const getGetApiV5FirmsCpaWorkflowTemplatesQueryOptions = <TData = Awaited<ReturnType<typeof getApiV5FirmsCpaWorkflowTemplates>>, TError = GetApiV5FirmsCpaWorkflowTemplatesErrorType<ForbiddenErrorObject>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV5FirmsCpaWorkflowTemplates>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV5FirmsCpaWorkflowTemplatesQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV5FirmsCpaWorkflowTemplates>>> = ({ signal }) => getApiV5FirmsCpaWorkflowTemplates(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiV5FirmsCpaWorkflowTemplates>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiV5FirmsCpaWorkflowTemplatesQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV5FirmsCpaWorkflowTemplates>>>
export type GetApiV5FirmsCpaWorkflowTemplatesQueryError = GetApiV5FirmsCpaWorkflowTemplatesErrorType<ForbiddenErrorObject>


/**
 * @summary List of records including the associated workflow_template.template
 */

export function useGetApiV5FirmsCpaWorkflowTemplates<TData = Awaited<ReturnType<typeof getApiV5FirmsCpaWorkflowTemplates>>, TError = GetApiV5FirmsCpaWorkflowTemplatesErrorType<ForbiddenErrorObject>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV5FirmsCpaWorkflowTemplates>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiV5FirmsCpaWorkflowTemplatesQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Create a record
 */
export const postApiV5FirmsCpaWorkflowTemplates = (
    postApiV5FirmsCpaWorkflowTemplatesBody: PostApiV5FirmsCpaWorkflowTemplatesBody,
 ) => {
      
      
      return postApiV5FirmsCpaWorkflowTemplatesMutator<void>(
      {url: `/api/v5/firms/cpa_workflow_templates`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postApiV5FirmsCpaWorkflowTemplatesBody
    },
      );
    }
  


export const getPostApiV5FirmsCpaWorkflowTemplatesMutationOptions = <TError = PostApiV5FirmsCpaWorkflowTemplatesErrorType<ForbiddenErrorObject>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV5FirmsCpaWorkflowTemplates>>, TError,{data: PostApiV5FirmsCpaWorkflowTemplatesBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postApiV5FirmsCpaWorkflowTemplates>>, TError,{data: PostApiV5FirmsCpaWorkflowTemplatesBody}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV5FirmsCpaWorkflowTemplates>>, {data: PostApiV5FirmsCpaWorkflowTemplatesBody}> = (props) => {
          const {data} = props ?? {};

          return  postApiV5FirmsCpaWorkflowTemplates(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiV5FirmsCpaWorkflowTemplatesMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV5FirmsCpaWorkflowTemplates>>>
    export type PostApiV5FirmsCpaWorkflowTemplatesMutationBody = PostApiV5FirmsCpaWorkflowTemplatesBody
    export type PostApiV5FirmsCpaWorkflowTemplatesMutationError = PostApiV5FirmsCpaWorkflowTemplatesErrorType<ForbiddenErrorObject>

    /**
 * @summary Create a record
 */
export const usePostApiV5FirmsCpaWorkflowTemplates = <TError = PostApiV5FirmsCpaWorkflowTemplatesErrorType<ForbiddenErrorObject>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV5FirmsCpaWorkflowTemplates>>, TError,{data: PostApiV5FirmsCpaWorkflowTemplatesBody}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postApiV5FirmsCpaWorkflowTemplates>>,
        TError,
        {data: PostApiV5FirmsCpaWorkflowTemplatesBody},
        TContext
      > => {

      const mutationOptions = getPostApiV5FirmsCpaWorkflowTemplatesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Update a record
 */
export const putApiV5FirmsCpaWorkflowTemplatesId = (
    id: Uuid,
    putApiV5FirmsCpaWorkflowTemplatesIdBody: PutApiV5FirmsCpaWorkflowTemplatesIdBody,
 ) => {
      
      
      return putApiV5FirmsCpaWorkflowTemplatesIdMutator<void>(
      {url: `/api/v5/firms/cpa_workflow_templates/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: putApiV5FirmsCpaWorkflowTemplatesIdBody
    },
      );
    }
  


export const getPutApiV5FirmsCpaWorkflowTemplatesIdMutationOptions = <TError = PutApiV5FirmsCpaWorkflowTemplatesIdErrorType<ForbiddenErrorObject>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV5FirmsCpaWorkflowTemplatesId>>, TError,{id: Uuid;data: PutApiV5FirmsCpaWorkflowTemplatesIdBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof putApiV5FirmsCpaWorkflowTemplatesId>>, TError,{id: Uuid;data: PutApiV5FirmsCpaWorkflowTemplatesIdBody}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiV5FirmsCpaWorkflowTemplatesId>>, {id: Uuid;data: PutApiV5FirmsCpaWorkflowTemplatesIdBody}> = (props) => {
          const {id,data} = props ?? {};

          return  putApiV5FirmsCpaWorkflowTemplatesId(id,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutApiV5FirmsCpaWorkflowTemplatesIdMutationResult = NonNullable<Awaited<ReturnType<typeof putApiV5FirmsCpaWorkflowTemplatesId>>>
    export type PutApiV5FirmsCpaWorkflowTemplatesIdMutationBody = PutApiV5FirmsCpaWorkflowTemplatesIdBody
    export type PutApiV5FirmsCpaWorkflowTemplatesIdMutationError = PutApiV5FirmsCpaWorkflowTemplatesIdErrorType<ForbiddenErrorObject>

    /**
 * @summary Update a record
 */
export const usePutApiV5FirmsCpaWorkflowTemplatesId = <TError = PutApiV5FirmsCpaWorkflowTemplatesIdErrorType<ForbiddenErrorObject>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV5FirmsCpaWorkflowTemplatesId>>, TError,{id: Uuid;data: PutApiV5FirmsCpaWorkflowTemplatesIdBody}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof putApiV5FirmsCpaWorkflowTemplatesId>>,
        TError,
        {id: Uuid;data: PutApiV5FirmsCpaWorkflowTemplatesIdBody},
        TContext
      > => {

      const mutationOptions = getPutApiV5FirmsCpaWorkflowTemplatesIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Delete a record
 */
export const deleteApiV5FirmsCpaWorkflowTemplatesId = (
    id: Uuid,
 ) => {
      
      
      return deleteApiV5FirmsCpaWorkflowTemplatesIdMutator<void>(
      {url: `/api/v5/firms/cpa_workflow_templates/${id}`, method: 'DELETE'
    },
      );
    }
  


export const getDeleteApiV5FirmsCpaWorkflowTemplatesIdMutationOptions = <TError = DeleteApiV5FirmsCpaWorkflowTemplatesIdErrorType<ForbiddenErrorObject>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiV5FirmsCpaWorkflowTemplatesId>>, TError,{id: Uuid}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deleteApiV5FirmsCpaWorkflowTemplatesId>>, TError,{id: Uuid}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiV5FirmsCpaWorkflowTemplatesId>>, {id: Uuid}> = (props) => {
          const {id} = props ?? {};

          return  deleteApiV5FirmsCpaWorkflowTemplatesId(id,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteApiV5FirmsCpaWorkflowTemplatesIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiV5FirmsCpaWorkflowTemplatesId>>>
    
    export type DeleteApiV5FirmsCpaWorkflowTemplatesIdMutationError = DeleteApiV5FirmsCpaWorkflowTemplatesIdErrorType<ForbiddenErrorObject>

    /**
 * @summary Delete a record
 */
export const useDeleteApiV5FirmsCpaWorkflowTemplatesId = <TError = DeleteApiV5FirmsCpaWorkflowTemplatesIdErrorType<ForbiddenErrorObject>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiV5FirmsCpaWorkflowTemplatesId>>, TError,{id: Uuid}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof deleteApiV5FirmsCpaWorkflowTemplatesId>>,
        TError,
        {id: Uuid},
        TContext
      > => {

      const mutationOptions = getDeleteApiV5FirmsCpaWorkflowTemplatesIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    