import React, { useState, useEffect } from 'react';
import { debounce } from 'lodash';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Button, Menu, MenuItem, Stack, Typography } from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';

import { palettePrimary } from 'theme/light';

import IconTextField from '../IconTextField';

const SButton = styled(Button)(() => ({
  height: 40,
  fontWeight: 600,
  fontSize: '0.87rem',
  textTransform: 'uppercase',
}));

const SMenu = styled(Menu)(() => ({
  width: '100%',
  maxWidth: 250,
  maxHeight: 200,
  marginTop: '5px',
}));

const SIconTextField = styled(IconTextField)(() => ({
  width: '100%',
  minWidth: 200,
  border: 0,
  padding: '7px 5px',
}));

const SMenuItem = styled(MenuItem)(() => ({
  width: '100%',
  minWidth: 200,
  whiteSpace: 'normal',
  '&:hover': { backgroundColor: 'transparent' },
}));

function DropDown(props) {
  const {
    withSearch = true,
    title,
    options = [],
    value,
    onSearchKeyChange,
    onSelectionChange,
  } = props;

  const [internalValue, setInternalValue] = useState(value);
  const [searchText, setSearchText] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [hoverId, setHoverId] = useState(undefined);

  const open = Boolean(anchorEl);

  useEffect(() => {
    if (JSON.stringify(value) !== JSON.stringify(internalValue)) {
      setInternalValue(value);
    }
  }, [value]);

  const filteredOptions =
    options &&
    options.filter((option) => {
      const lowCasedName = option.name?.toLowerCase();
      const lowCasedLabel = option.label?.toLowerCase();
      const lowCasedHeadline = option.headline?.toLowerCase();
      const lowCasedSubHeadline = option.subHeadline?.toLowerCase();
      const lowCasedSearchText = searchText.toLowerCase();

      return (
        (lowCasedHeadline && lowCasedHeadline.includes(lowCasedSearchText)) ||
        (lowCasedSubHeadline &&
          lowCasedSubHeadline.includes(lowCasedSearchText)) ||
        (lowCasedLabel && lowCasedLabel.includes(lowCasedSearchText)) ||
        (lowCasedName && lowCasedName.includes(lowCasedSearchText))
      );
    });

  const handleButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setSearchText('');
    setAnchorEl(null);
  };

  const handleOptionClick = (event, option) => {
    setSearchText('');
    setAnchorEl(null);
    setInternalValue(option);
    if (onSelectionChange) onSelectionChange(option);
  };

  return (
    <div>
      <SButton
        id="title-button"
        aria-controls={open ? 'lock-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant={
          open ||
          (internalValue &&
            options.some((option) => option.id === internalValue.id))
            ? 'contained'
            : 'outlined'
        }
        onClick={handleButtonClick}
      >
        {title}
      </SButton>

      <SMenu
        keepMounted
        id="lock-menu"
        elevation={0}
        anchorEl={anchorEl}
        open={open}
        PaperProps={{
          sx: {
            maxWidth: 230,
            maxHeight: 200,
            border: `2px solid ${palettePrimary.gray}`,
          },
        }}
        MenuListProps={{
          'aria-labelledby': 'title-button',
          role: 'listbox',
          disablePadding: true,
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transitionDuration={50}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onKeyDownCapture={(e) => e.stopPropagation()}
        onClose={handleClose}
      >
        {withSearch && (
          <SIconTextField
            type="search"
            icon={<SearchIcon />}
            fontSize="0.95rem"
            value={searchText}
            onChange={
              onSearchKeyChange ||
              debounce((e) => {
                setSearchText(e.target.value);
              }, 300)
            }
          />
        )}
        {filteredOptions.length > 0 ? (
          filteredOptions.map((option, index) => {
            const label = option.name || option.label;
            return (
              <SMenuItem
                disableRipple
                autoFocus={false}
                key={option.id}
                onMouseEnter={() => setHoverId(index)}
                onMouseLeave={() => setHoverId(undefined)}
                onClick={(event) => handleOptionClick(event, option)}
              >
                <Stack direction="column">
                  {option.headline && option.subHeadline ? (
                    <>
                      <Typography
                        variant="gray_body2"
                        sx={{
                          color:
                            hoverId === index
                              ? palettePrimary.textColor
                              : palettePrimary.gray,
                          transition: 'all ease-in-out 200ms',
                        }}
                      >
                        {option.headline}
                      </Typography>
                      <Typography
                        variant="gray_body2"
                        sx={{ color: '#c4cdd5' }}
                      >
                        {option.subHeadline}
                      </Typography>
                    </>
                  ) : (
                    <Typography
                      variant="gray_body2"
                      sx={{
                        color:
                          hoverId === index
                            ? palettePrimary.textColor
                            : palettePrimary.gray,
                        transition: 'all ease-in-out 200ms',
                      }}
                    >
                      {label}
                    </Typography>
                  )}
                </Stack>
              </SMenuItem>
            );
          })
        ) : (
          <SMenuItem>
            <Typography variant="gray_body2">Not Found</Typography>
          </SMenuItem>
        )}
      </SMenu>
    </div>
  );
}

const OptionPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  headline: PropTypes.string,
  subHeadline: PropTypes.string,
});

DropDown.propTypes = {
  withSearch: PropTypes.bool,
  title: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(OptionPropType),
  value: OptionPropType,
  onSearchKeyChange: PropTypes.func,
  onSelectionChange: PropTypes.func,
};

export default DropDown;
