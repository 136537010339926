/* eslint-disable react/no-unused-state */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-access-state-in-setstate */
import React, { Component } from 'react';
import Axios from 'axios';
import { Link } from 'react-router-dom-latest';
// import { Link, Prompt } from 'react-router-dom-latest';
import { Modal, ModalBody } from 'reactstrap';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';
import ReactTooltip from 'react-tooltip';
import {
  getStorageData,
  AlertMessage,
  getUserTimeZoneAbbr,
  generateDuration,
  getCurrentPath,
} from 'utilities/utils';
import { removeBreakElement, generateUniqueHash } from 'common/NotesUtilities';
import ConfirmationModal from 'common/ConfirmationModal';
import {
  OAUTH_ACCESS_TOKENS,
  ZOOM_MEETING,
  ZOOM_INTEGRATION_STATUS,
  CALENDLY,
} from 'constants/constants';
import * as TaskApi from 'api/TaskApi';
import TaskFileUpload from 'components/Files/TaskFileUpload';
import { withRouter } from 'utilities/withRouterWrapper';
import { isSameMinute, formatISO } from 'date-fns';
import TinymceEditor from 'components/Emails/components/TinymceEditior';
import CommonFields from 'components/Tasks/Create/CommonFields';
import CommonFieldsEmail from 'components/Tasks/Create/CommonFieldsEmail';
import TaskSelector from './TaskSelector';
import TaskFooterButtons from './TaskFooterButtons';

const zoomDescription = (data) => {
  const { join_url, settings, id, password, pstn_password } = data;
  let output = `<div>Join the zoom meeting</div><div>From PC, Mac, iOS or android: <a href=${join_url} target="_blank">${join_url}</a></div>`;
  if (password) {
    output += `<div>Password: ${password} </div>`;
  }
  if (settings && settings.global_dial_in_numbers) {
    output += '<div>Dial by your location:</div>';
    settings.global_dial_in_numbers.map((e) => {
      if (e.city) {
        output += `<div>    ${e.number} ${e.country} (${e.city})</div>`;
      } else {
        output += `<div>    ${e.number} ${e.country}</div>`;
      }
      return e;
    });
    output += `<div>Meeting ID: ${id}</div>`;
    if (pstn_password) {
      output += `<div>Numeric Password(Telephone/Room Systems): ${pstn_password} </div>`;
    }
  }
  return output;
};

const calendlyDescription = (data) => {
  const output = `
  <div>Please use the following link to select a day and time the work best for you: <a href="${data.booking_url}" target="_blank">${data.booking_url}</a></div><div></div><div>Once scheduled, please mark the task complete by scrolling to the bottom of this task and clicking the Mark Complete button.</div>`;
  return output;
};

const noneStyle = { display: 'none' };
const blockStyle = { display: 'block' };
class VirtualMeeting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stateValues: {},
      errors: {},
      taskTitle: '',
      taskStatus: '',
      documents: [],
      comment: '',
      viewModal: false,
      loading: false,
      dataUpdated: false,
      meetingDuration: 30,
      meetingLocation: '',
      showEventTypesModal: false,
      calendlyEventTypes: [],
      zoomMeeting: false,
      uniqueId: generateUniqueHash(5),
      durationMinutes: 30,
      durationHours: 0,
    };
  }

  componentDidMount() {
    Axios.get(ZOOM_INTEGRATION_STATUS).then((res) => {
      if (res.status === 200 && res.data && res.data.data) {
        const { token } = res.data.data;
        this.setState({
          isZoomConnected: token,
          loading: false,
        });
      } else {
        this.setState({
          isZoomConnected: false,
          loading: false,
        });
      }
    });
    this.updateCalendlyStatus();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.taskDetail.description !== this.props.taskDetail.description
    ) {
      this.setState({ comment: this.props.taskDetail.description });
    }
  }

  UNSAFE_componentWillReceiveProps() {
    const { data, params, location } = this.props;
    if (
      Object.keys(this.props.taskDetail).length &&
      data.isEdit &&
      !data.changedOnce
    ) {
      const detail = this.props.taskDetail;
      this.setState({
        taskTitle: detail.subject,
        comment:
          detail.description_in_json && detail.description_in_json !== null
            ? JSON.parse(detail.description_in_json)
            : removeBreakElement(detail.description),
        documents: detail.documents,
        taskStatus: detail.status,
        editTaskId: params.id + location.search,
        zoom_meeting_id: detail.zoom_meeting_id,
        meetingLocation: detail.zoom_meeting_link,
        zoomMeeting: detail.zoom_meeting_id && detail.zoom_meeting_link,
        calendlyMeeting:
          detail.zoom_meeting_link !== null &&
          detail.zoom_meeting_link.includes('calendly'),
        meetingDuration: detail.meeting_duration && 30,
        uniqueId: generateUniqueHash(5),
      });
    }

    this.updateCalendlyStatus();
  }

  componentWillUnmount() {
    const { documents, editTaskId } = this.state;
    if (documents.length) {
      const docIds = [];
      let x;
      for (x = 0; x < documents.length; x += 1) {
        docIds.push(documents[x].id);
      }
      const data = { doc_ids: docIds };
      TaskApi.cancelDocuments(this, data, 'tasks');
    }
    if (editTaskId === undefined) {
      this.deleteMeeting();
    }
  }

  setTasktitle = () => {
    this.setState({
      // taskTitle: value,
      dataUpdated: true,
    });
  };

  getCalendlyEventtypes = async () => {
    const { calendlyNextPageToken } = this.state;
    try {
      const events = await Axios.get(
        `${CALENDLY}${
          calendlyNextPageToken
            ? `?page=${encodeURIComponent(calendlyNextPageToken)}`
            : ''
        }`,
      ).then((res) => res.data.data);
      const {
        collection,
        pagination: { next_page },
      } = events;
      this.setState({
        calendlyEventTypes: [...this.state.calendlyEventTypes, ...collection],
        calendlyNextPageToken: next_page !== null ? next_page : undefined,
      });
    } catch (err) {
      console.log(err);
    }
  };

  stateChange = (value, dataUpdated = false) => {
    const { stateValues: previousStateValues } = this.state;
    let isSameTime = true;
    if (
      this.state.stateValues &&
      this.state.stateValues.startDate &&
      value &&
      value.startDate
    ) {
      isSameTime = isSameMinute(
        this.state.stateValues.startDate,
        value.startDate,
      );
    }

    const stateValues = { ...previousStateValues, ...value };
    this.setState(
      {
        stateValues,
        dataUpdated,
      },
      () => {
        if (!isSameTime) {
          const startDate = new Date(this.state.stateValues.startDate);
          const userTimeZone = getUserTimeZoneAbbr();
          const { taskTitle } = this.state;
          let ownername;
          if (
            stateValues.owners &&
            stateValues.owners.length &&
            stateValues.ownerId
          ) {
            ownername = stateValues.owners.find(
              (e) => e.value === stateValues.ownerId,
            );
          }
          let topic = 'Virtual Meeting';
          if (ownername) {
            topic = `${topic} with ${ownername.label} of ${moment(
              startDate,
            ).format('LLLL')} ${getUserTimeZoneAbbr()}`;
          } else {
            // TODO: __HOMEDATA-AUDIT
            topic = `${topic} with ${getStorageData().uname} of ${moment(
              startDate,
            ).format('LLLL')} ${getUserTimeZoneAbbr()}`;
          }
          if (
            taskTitle &&
            taskTitle.length &&
            taskTitle.includes(userTimeZone)
          ) {
            this.setState({ taskTitle: topic });
          }
        }
      },
    );
  };

  handleCancel = () => {
    const { stateValues, dataUpdated } = this.state;
    if (stateValues.isChanged || dataUpdated) {
      this.setState({ viewModal: true });
    } else {
      this.props.goBack();
    }
  };

  hideViewModal = () => {
    this.setState({ viewModal: false });
  };

  handleCommentChange = (value) => {
    this.setState({
      comment: value,
      dataUpdated: true,
    });
  };

  handleChange = (e) => {
    this.setState({
      errors: {},
      taskTitle: e.target.value,
      dataUpdated: true,
    });
  };

  handleDurationChange = (input, type) => {
    if (type === 'hours') {
      this.setState({ durationHours: input });
    } else {
      const h = Math.floor(input / 60);
      const m = Math.floor(input % 60);
      if (input > 60) {
        this.setState({
          durationMinutes: m,
          durationHours: this.state.durationHours + h,
        });
      } else {
        this.setState({ durationMinutes: input });
      }
    }
  };

  handleDurationOnBlur = () => {
    let { durationMinutes, durationHours } = this.state;
    if (durationMinutes && typeof durationMinutes === 'string') {
      durationMinutes = parseInt(durationMinutes, 10);
    } else if (
      durationMinutes &&
      !durationMinutes.length &&
      typeof durationMinutes !== 'number'
    ) {
      durationMinutes = 0;
    }
    if (durationHours && typeof durationHours === 'string') {
      durationHours = parseInt(durationHours, 10);
    } else if (
      durationHours &&
      !durationHours.length &&
      typeof durationHours !== 'number'
    ) {
      durationHours = 0;
    }
    this.setState({ meetingDuration: durationHours * 60 + durationMinutes });
  };

  nextPage = () => {
    const { state } = this.props.location;
    const { editTaskId, viewModal } = this.state;
    if (editTaskId === undefined && viewModal) {
      this.deleteMeeting();
    }

    if (
      this.props.data.isEdit &&
      state !== undefined &&
      state.from === '/task/detail'
    ) {
      const { params } = this.props;
      this.props.navigate(`/task/detail/${params.id}`);
    } else if (state && state.from === '/task/new') {
      this.props.navigate(-2);
    } else {
      // need this for when task is created from email
      this.props.goBack();
    }
  };

  handleValidation = () => {
    const { stateValues, taskTitle } = this.state;
    const err = {};
    let formIsValid = true;
    if (
      stateValues.accounts &&
      stateValues.accounts.length &&
      (!stateValues.accountId || stateValues.accountId === '')
    ) {
      formIsValid = false;
      err.account_error = 'Please select account';
    }
    if (stateValues.contactId === '' || !stateValues.contactId) {
      formIsValid = false;
      err.contact_error = 'Please select contact';
    }
    if (stateValues.ownerId === '' || !stateValues.ownerId) {
      formIsValid = false;
      err.owner_error = 'Please select task owner';
    }
    if (taskTitle === '' || !taskTitle) {
      formIsValid = false;
      err.taskTitle_error = 'Enter task title';
    }
    // if (!virtualMeeting) {
    //   formIsValid = false;
    //   err.virtualMeeting_error = 'Required';
    // }
    this.setState({ errors: err });
    return formIsValid;
  };

  emptyError = () => {
    this.setState({ errors: {} });
  };

  submitForm = (e) => {
    e.preventDefault();
    const { location, params } = this.props;
    const {
      stateValues,
      comment,
      taskTitle,
      zoom_meeting_id,
      meetingLocation,
      meetingDuration,
    } = this.state;
    const path = getCurrentPath(location);
    const startDate = new Date(stateValues.startDate);
    if (this.handleValidation()) {
      let mydata = {};
      if (this.props.data.isEdit) {
        mydata.cpa_id = stateValues.cpaId;
        mydata.subject = taskTitle;
        if (
          this.props.data &&
          this.props.data.taskTypeKey === 'virtual_meeting'
        ) {
          mydata.due_by = startDate.toUTCString();
          mydata.zoom_meeting_id = zoom_meeting_id;
          mydata.zoom_meeting_link = meetingLocation;
          mydata.meeting_duration = meetingDuration;
        } else {
          mydata.due_by = startDate.format('dd/MM/yyyy');
        }
        if (stateValues.ownerChanged) {
          mydata.owner_id = stateValues.ownerId;
        }
      } else {
        if (path === '/contactdetails/task/:id') {
          mydata.assigne_type = stateValues.contactDetails.contact.cpa_user_id
            ? 'user'
            : 'contact';
          mydata.assigned_to_user = stateValues.contactDetails.contact
            .cpa_user_id
            ? stateValues.contactDetails.contact.cpa_user_id
            : stateValues.contactDetails.contact.id;
        } else {
          mydata.assigne_type = stateValues.assigneeType;
          mydata.assigned_to_user = stateValues.contactId;
        }
        mydata.cpa_id = stateValues.cpaId;
        mydata.owner_id = stateValues.ownerId;
        mydata.for_account = stateValues.accountId;
        mydata.subject = taskTitle;
        mydata.task_type_key = this.props.data.taskTypeKey;
        if (
          this.props.data &&
          this.props.data.taskTypeKey === 'virtual_meeting'
        ) {
          mydata.due_by = formatISO(startDate);
          mydata.meeting_duration = meetingDuration || 30;
          mydata.zoom_meeting_id = zoom_meeting_id;
          mydata.zoom_meeting_link = meetingLocation;
        } else {
          mydata.due_by = startDate;
        }
        mydata.task_type_value = this.props.data.taskTypeValue;
      }
      if (stateValues.recurring_properties) {
        mydata = { ...mydata, ...stateValues.recurring_properties };
      }
      if (typeof comment === 'string' && comment.length) {
        mydata.description = comment;
      } else {
        mydata.description = '';
      }
      // if (mydata.description && mydata.description.length > 0) {
      //   mydata.description = mydata.description.replace(/\n/g, '<br>');
      // }
      if (this.state.documents.length && !this.props.data.isEdit) {
        mydata.doc_ids = [];
        let x;
        for (x = 0; x < this.state.documents.length; x += 1) {
          mydata.doc_ids.push(this.state.documents[x].id);
        }
      }
      this.createZoomMeeting();
      if (this.props.data.isEdit) {
        const id = params.id + location.search;
        TaskApi.createTask(this, mydata, id, this.props.data.taskTypeKey); // updates task
      } else {
        TaskApi.createTask(this, mydata);
      }
    }
    return false;
  };

  updateDocumentState = (documents) => {
    this.setState({ documents });
  };

  createZoomMeeting = () => {
    const { taskTitle, stateValues, zoom_meeting_id, zoomMeeting, editTaskId } =
      this.state;
    const startDate = new Date(stateValues.startDate);
    const userTimeZone = getUserTimeZoneAbbr();
    let ownername;
    if (
      stateValues.owners &&
      stateValues.owners.length &&
      stateValues.ownerId
    ) {
      ownername = stateValues.owners.find(
        (e) => e.value === stateValues.ownerId,
      );
    }
    let topic = 'Virtual Meeting';
    if (ownername) {
      topic = `${topic} with ${ownername.label} at ${moment(startDate).format(
        'LLLL',
      )} ${getUserTimeZoneAbbr()}`;
    } else {
      // TODO: __HOMEDATA-AUDIT
      topic = `${topic} with ${getStorageData().uname} at ${moment(
        startDate,
      ).format('LLLL')} ${getUserTimeZoneAbbr()}`;
    }
    if (
      taskTitle &&
      taskTitle.length &&
      !taskTitle.includes(userTimeZone) &&
      !taskTitle.toLowerCase().includes('calendly')
    ) {
      topic = taskTitle;
    }
    const duration = stateValues.meetingDuration
      ? parseInt(stateValues.meetingDuration, 10)
      : 30;
    const start_time = moment(startDate).utc().format();
    if (zoomMeeting) {
      if (zoom_meeting_id) {
        Axios.put(`${ZOOM_MEETING}/${zoom_meeting_id}`, {
          data: {
            topic,
            type: 2,
            start_time,
            duration,
          },
          task_id: editTaskId,
        }).then((res) => {
          if (res.status === 200 && res.data && res.data.data) {
            const { id } = res.data.data;
            this.setState({ zoom_meeting_id: id });
          }
        });
      } else {
        Axios.post(ZOOM_MEETING, {
          data: {
            topic,
            type: 2,
            start_time,
            duration,
          },
        }).then((res) => {
          if (res.status === 200 && res.data && res.data.data) {
            const { id, join_url, reason, message, code } = res.data.data;
            if (join_url) {
              this.setState({
                zoom_meeting_id: id,
                meetingLocation: join_url,
                comment: zoomDescription(res.data.data),
                taskTitle: topic,
                uniqueId: generateUniqueHash(5),
              });
            }
            if (reason) {
              AlertMessage('error', reason, 3000);
            }
            if (code && message && message.toLowerCase().includes('invalid')) {
              AlertMessage('error', 'Please connect zoom', 3000);
            } else if (code && message) {
              AlertMessage('error', message, 3000);
            }
          } else {
            AlertMessage('error', res.data.message, 3000);
          }
        });
      }
    }
  };

  createCalendlyMeeting = async (event) => {
    // delete zoom meeting if present
    this.deleteMeeting();
    const { taskTitle, stateValues } = this.state;
    const userTimeZone = getUserTimeZoneAbbr();
    let ownername;
    if (
      stateValues.owners &&
      stateValues.owners.length &&
      stateValues.ownerId
    ) {
      // eslint-disable-next-line no-unused-vars
      ownername = stateValues.owners.find(
        (e) => e.value === stateValues.ownerId,
      );
    }
    let topic = 'Schedule a Meeting';
    if (taskTitle && taskTitle.length && !taskTitle.includes(userTimeZone)) {
      topic = taskTitle;
    }
    const h = Math.floor(event.duration / 60);
    const m = Math.floor(event.duration % 60);
    try {
      const response = await Axios.post(CALENDLY, {
        event_type: event.uri,
      }).then((res) => res.data.data.resource);
      if (response && response.booking_url) {
        this.setState({
          meetingLocation: response.booking_url,
          taskTitle: topic,
          comment: calendlyDescription(response),
          uniqueId: generateUniqueHash(5),
          showEventTypesModal: false,
          zoomMeeting: false,
          durationMinutes: m,
          durationHours: h,
          meetingDuration: event.duration,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  handleZoomCheckbox = () => {
    this.setState(
      {
        zoomMeeting: !this.state.zoomMeeting,
        dataUpdated: true,
        calendlyMeeting: false,
        meetingLocation: '',
      },
      () => {
        if (this.state.zoomMeeting) {
          this.createZoomMeeting();
        } else {
          this.deleteMeeting();
        }
      },
    );
  };

  handleCalendlyCheckbox = () => {
    this.setState(
      {
        calendlyMeeting: !this.state.calendlyMeeting,
        dataUpdated: true,
        showEventTypesModal: !this.state.calendlyMeeting,
        calendlyEventTypes: [],
        calendlyNextPageToken: undefined,
      },
      () => {
        if (this.state.calendlyMeeting) {
          this.getCalendlyEventtypes();
        }
      },
    );
  };

  deleteMeeting = () => {
    const { zoom_meeting_id } = this.state;
    if (zoom_meeting_id) {
      Axios.delete(`${ZOOM_MEETING}/${zoom_meeting_id}`, {
        id: zoom_meeting_id,
      }).then(() => {
        console.log('deleted meeting');
      });
      this.setState({
        zoom_meeting_id: '',
        meetingLocation: '',
        comment: '',
      });
    }
  };

  updateCalendlyStatus = () => {
    Axios.get(`${OAUTH_ACCESS_TOKENS}/calendly`)
      .then((response) => {
        this.setState({ isCalendlyConnected: response.status === 200 && response.data.access_token });
      })
      .catch(() => {
        this.setState({ isCalendlyConnected: false });
      });
  };

  render() {
    const {
      errors,
      taskTitle,
      taskStatus,
      stateValues,
      dataUpdated,
      zoomMeeting,
      meetingLocation,
      editTaskId,
      isZoomConnected,
      isCalendlyConnected,
      calendlyMeeting,
      showEventTypesModal,
      calendlyEventTypes,
      calendlyNextPageToken,
    } = this.state;
    const { data } = this.props;
    const recurringDisable =
      stateValues.recurring_properties &&
      stateValues.recurring_properties.recurringDropdown;
    if (
      isZoomConnected !== undefined &&
      !isZoomConnected &&
      isCalendlyConnected !== undefined &&
      !isCalendlyConnected &&
      editTaskId === undefined
    ) {
      return (
        <div>
          <div className="virtual-meet-not-conct">
            {this.props.data.viewContext === 'email' && (
              <TaskSelector
                taskTypes={this.props.data.taskTypes}
                selectedOption={this.props.data.selectedOption}
                handleTaskTypeChange={this.props.handleTaskTypeChange}
                isEdit={this.props.data.isEdit}
              />
            )}
          </div>
          <div className="alert alert-danger alert-dismissible" role="alert">
            <p className="p-0 m-0">
              Please set up zoom or calendly to create virtual Meetings.{' '}
              <Link to="/profile_settings?tab=third_party_apps">
                Go to Profile
              </Link>
            </p>
          </div>
        </div>
      );
    }
    return (
      <div>
        {/* <Prompt when={showUnsavedChangesAlert} message="You have unsaved changes, are you sure you want to leave?" /> */}
        <div className="miscellaneous" data-testid="meetingTestId">
          <div
            id="loading"
            style={this.state.loading ? blockStyle : noneStyle}
          />
          {this.props?.data?.viewContext === 'email' ? (
            <CommonFieldsEmail
              data={this.props.data}
              statedata={this.state}
              taskDetail={this.props.taskDetail}
              stateChange={this.stateChange}
              emptyError={this.emptyError}
              setTasktitle={this.setTasktitle}
              removeparticularTaskType={this.props.removeparticularTaskType}
              match={this.props.match}
              location={this.props.location}
              params={this.props.params}
              handleTaskTypeChange={this.props.handleTaskTypeChange}
            />
          ) : (
            <CommonFields
              data={data}
              statedata={this.state}
              taskDetail={this.props.taskDetail}
              stateChange={this.stateChange}
              emptyError={this.emptyError}
              setTasktitle={this.setTasktitle}
              removeparticularTaskType={this.props.removeparticularTaskType}
              meetingLocation={meetingLocation}
              handleMeetingDuration={this.handleDurationChange}
              handleDurationOnBlur={this.handleDurationOnBlur}
              match={this.props.match}
              location={this.props.location}
              params={this.props.params}
            />
          )}
          <div className="row pb-2">
            <div className="col">
              <span
                className="radio-container text-body mr-2"
                data-for="zoommeetingcheckbox"
                effect="solid"
                data-tip
              >
                <label htmlFor="zoommeeting" style={{ minWidth: '0' }}>
                  <input
                    type="radio"
                    className="form-check-input"
                    name="meeting"
                    value="zoommeeting"
                    id="zoommeeting"
                    checked={zoomMeeting}
                    disabled={editTaskId || !isZoomConnected}
                    onChange={this.handleZoomCheckbox}
                  />
                  <i className="checkmark" />
                  <span className="pl-2 text-body text-transform-none">
                    Zoom Meeting
                  </span>
                </label>
              </span>
              {!isZoomConnected && (
                <ReactTooltip id="zoommeetingcheckbox" effect="solid">
                  <span>Please set up zoom</span>
                </ReactTooltip>
              )}
              <span
                className="radio-container text-body"
                data-for="calendlymeetingcheckbox"
                effect="solid"
                data-tip
              >
                <label htmlFor="calendlyMeeting" style={{ minWidth: '0' }}>
                  <input
                    className="form-check-input"
                    type="radio"
                    name="meeting"
                    id="calendlyMeeting"
                    value="calendlymeeting"
                    checked={calendlyMeeting}
                    disabled={editTaskId || !isCalendlyConnected}
                    onChange={this.handleCalendlyCheckbox}
                  />
                  <i className="checkmark" />
                  <span className="pl-2 text-body text-transform-none">
                    Calendly Invite
                  </span>
                </label>
              </span>
              {!isCalendlyConnected && (
                <ReactTooltip id="calendlymeetingcheckbox" effect="solid">
                  <span>Please set up calendly</span>
                </ReactTooltip>
              )}
            </div>
          </div>
          <div className="row">
            <div
              className={`form-group col-12${
                errors.taskTitle_error ? ' form-error' : ''
              }`}
            >
              <label htmlFor="taskTitle">Subject</label>
              <input
                autoComplete="off"
                disabled={taskStatus === 'Closed'}
                name="taskTitle"
                className={`form-control ${
                  data.viewContext === 'email'
                    ? 'border-top-0 border-bottom border-right-0 border-left-0'
                    : ''
                }`}
                id="taskTitle"
                type="text"
                value={taskTitle}
                maxLength="200"
                onChange={this.handleChange}
              />
              <span className="error_label">{errors.taskTitle_error}</span>
            </div>
          </div>
          <div className="row">
            <div className="form-group col-lg-12">
              <div className="has-float-label customEditor boxResize">
                <label htmlFor="taskDescription">Description</label>

                <TinymceEditor
                  value={this.state.comment}
                  handleChange={this.handleCommentChange}
                  disabled={taskStatus === 'Closed'}
                  testid="task__description"
                />
              </div>
            </div>
          </div>
          {(data.isEdit && this.state.documents.length) || !data.isEdit ? (
            <div className="row">
              <div className="form-group dragWrap col-lg-12">
                <label htmlFor="attachments">Attachments</label>
                <TaskFileUpload
                  data={this.state}
                  uploadType="task"
                  isEdit={data.isEdit}
                  updateDocumentState={this.updateDocumentState}
                />
              </div>
            </div>
          ) : null}
          <div className="row">
            <div className="col-12 form-group formbtn justify-content-end btn--leftspace">
              <TaskFooterButtons
                data={data}
                handleCancel={this.handleCancel}
                taskStatus={taskStatus}
                recurringDisable={recurringDisable}
                dataUpdated={dataUpdated}
                stateValues={stateValues}
                submitForm={this.submitForm}
              />
            </div>
          </div>
          {/* WIll be removed after checking functionality */}
          {/* <div className="row">
            <div className="col-12 form-group formbtn justify-content-end btn--leftspace">
              <div className="btn-wrap btn--leftspace">
                <button
                  type="button"
                  className="btn btn-outline-light"
                  onClick={this.handleCancel}
                  disabled={taskStatus === 'Closed' || recurringDisable}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary task-form-submit"
                  onClick={this.submitForm}
                  disabled={
                    taskStatus === 'Closed' ||
                    recurringDisable ||
                    (data.isEdit && !dataUpdated && !stateValues.isChanged)
                  }
                >
                  {!data.isEdit ? 'Create Task' : 'Update Task'}
                </button>
              </div>
            </div>
          </div> */}
        </div>
        <ShowEventTypes
          isOpen={showEventTypesModal}
          data={calendlyEventTypes}
          getMoreData={this.getCalendlyEventtypes}
          hasMore={calendlyNextPageToken !== undefined}
          handleCloseModal={() =>
            this.setState({
              showEventTypesModal: false,
              calendlyMeeting: false,
            })
          }
          handleSelect={this.createCalendlyMeeting}
        />
        <ConfirmationModal
          isOpen={this.state.viewModal}
          headerText="Are you sure want to cancel."
          messageText="This action cannot be undone. Proceed?"
          noBtnClick={this.hideViewModal}
          yesBtnClick={this.nextPage}
          yesBtnText="Yes"
          noBtnText="No"
        />
      </div>
    );
  }
}

const ShowEventTypes = ({
  isOpen,
  data,
  getMoreData,
  hasMore,
  handleCloseModal,
  handleSelect,
}) => (
  <Modal
    isOpen={isOpen}
    className="customModal customModal--center customModal--alert customModal--w500 customModal--nopadding"
  >
    <div className="ModalHeader">
      <button
        type="button"
        className="close"
        aria-label="Close"
        onClick={handleCloseModal}
      >
        <i className="icon-close2" aria-hidden="true" />
      </button>
    </div>
    <ModalBody className="modalContent">
      <div className="modalContent--inner p-0">
        <div className="modalContent--header text-large pt-4 pr-4 pl-4 pb-0">
          <div className="row">
            <div className="col-md-10">
              <h5 className="mb-1">Event Types</h5>
              <p className="mb-1">Please select event type</p>
            </div>
          </div>
        </div>
        <div id="calendly_event_types" style={{ minheight: '300px' }}>
          <InfiniteScroll
            dataLength={data.length}
            next={getMoreData}
            hasMore={hasMore}
            loader={<span className="pl-3 pb-2">Getting more data...</span>}
            scrollableTarget="calendly_event_types"
            className="pt-2 pr-4 pl-4 pb-4"
          >
            <div className="row">
              {data && data
                ? data
                    .filter((e) => e.active)
                    .map((e) => (
                      <div className="col-4 mb-2">
                        <button
                          type="button"
                          onClick={() => handleSelect(e)}
                          className="btn btn-primary"
                          style={{ width: '100%', whiteSpace: 'normal' }}
                        >
                          <strong className="text-gray-dark">{e.name}</strong>
                          <br />
                          <span className="d-block">
                            {generateDuration(e.duration)}
                          </span>
                        </button>
                      </div>
                    ))
                : null}
            </div>
          </InfiniteScroll>
        </div>
      </div>
    </ModalBody>
  </Modal>
);

export default withRouter(VirtualMeeting);
