import {
  FormLabel,
  FormLabelProps,
  Typography,
  styled,
  useTheme,
} from '@mui/material';

export const FormLabelStyled = styled(
  ({ children, ...props }: FormLabelProps) => {
    const theme = useTheme();
    return (
      <FormLabel {...props}>
        <Typography
          sx={{
            ...theme.typography.body2,
            fontSize: '.75rem',
            fontWeight: 700,
          }}
        >
          {children}
        </Typography>
      </FormLabel>
    );
  },
)(() => ({
  marginTop: 9,
}));
