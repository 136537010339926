import React, { Component } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import Interweave from 'interweave';
import TinymceEditor from 'components/Emails/components/TinymceEditior';
import { getStorageData } from 'utilities/utils';

// const contentCss = {
//   cke_editable: { lineHeight: 1.0 },
//   p: { margin: '0 0 0 0' },
// };

class SignaturePreview extends Component {
  constructor(props) {
    super(props);
    this.state = { edit: false };
  }

  handleEditBtn = () => {
    const { edit } = this.state;
    this.setState({ edit: !edit });
  };

  render() {
    const { content } = this.props;
    const { edit } = this.state;
    return (
      <div>
        <Modal isOpen className="customModal" backdrop={false}>
          <div>
            <div className="d-flex justify-content-between align-items-center ModalHeader">
              <div className="infoHeader">
                <i
                  className="icon-back-arrow"
                  onClick={this.props.handleCancelButton}
                  aria-hidden="true"
                />
                {this.props.doctype === 'engagement_letter'
                  ? 'Engagement Letter'
                  : 'Consent to Release Letter'}
              </div>
              <div className="ModalHeader--links">
                <div className="d-flex flex-nowrap btn--leftspace">
                  <button
                    type="button"
                    onClick={this.handleEditBtn}
                    className="btn btn-link btn-sm btn-outline-none pr-0"
                  >
                    <i
                      className={edit ? 'icon-open-eye' : 'icon-edit'}
                      aria-hidden="true"
                    />
                    {edit ? 'Preview' : 'Edit'}
                  </button>
                </div>
              </div>
              <button
                type="button"
                onClick={this.props.handleCancelButton}
                className="close"
                aria-label="Close"
              >
                <i className="icon-close2" aria-hidden="true" />
              </button>
            </div>
          </div>
          <ModalBody style={{ maxHeight: 'none' }}>
            <div className="modalContent--wrap">
              <div className="modalContent--inner p-4">
                <div className="labelValue">
                  <div className="cpaLogo pb-3">
                    <img
                      // TODO: __HOMEDATA-AUDIT
                      src={getStorageData().cpa_logo}
                      alt="CPA Logo"
                    />
                  </div>
                  {!edit ? (
                    <div id="templateData" className="pt-1 pb-1 pl-2 pr-2">
                      <Interweave allowAttributes content={content} />
                    </div>
                  ) : (
                    // <CKEditor
                    //   config={{
                    //     font_names:
                    //       'Arial;Times New Roman;Georgia;Courier New;Monospace; Source Sans Pro;Verdana;',
                    //     toolbar: [
                    //       {
                    //         name: 'basicstyles',
                    //         items: ['Bold', 'Italic', 'Underline'],
                    //       },
                    //       { name: 'colors', items: ['TextColor', 'BGColor'] },
                    //       { name: 'links', items: ['Link'] },
                    //       {
                    //         name: 'paragraph',
                    //         items: [
                    //           'JustifyLeft',
                    //           'JustifyCenter',
                    //           'JustifyRight',
                    //           'JustifyBlock',
                    //           'NumberedList',
                    //           'BulletedList',
                    //           'Outdent',
                    //           'Indent',
                    //         ],
                    //       },
                    //       { name: 'styles', items: ['Font'] },
                    //     ],
                    //     height: 500,
                    //     // toolbarLocation: 'bottom',
                    //     autoParagraph: false,
                    //     enterMode: 2,
                    //     allowedContent: true,
                    //     extraAllowedContent: 'span {*}(*)',
                    //     entities: false,
                    //     disabled: true,
                    //     contentsCss: contentCss,
                    //     toolbarCanCollapse: false,
                    //   }}
                    //   id="customeditor"
                    //   content={content}
                    //   events={{ change: this.props.handleContentChange }}
                    // />
                    <TinymceEditor
                      id="#signaturePreviewEditor"
                      handleChange={this.props.handleContentChange}
                      value={content}
                    />
                  )}
                </div>
                <div className="row">
                  <div className="col-12 form-group formbtn btn--leftspace">
                    {!edit ? (
                      <div className="btn-wrap btn--leftspace">
                        <button
                          onClick={this.props.handleCancelButton}
                          type="button"
                          className="btn btn-outline-light"
                        >
                          Cancel
                        </button>
                        <button
                          onClick={this.props.saveContentChanges}
                          type="button"
                          className="btn btn-primary"
                        >
                          Save Document
                        </button>
                      </div>
                    ) : (
                      <div className="btn-wrap btn--leftspace">
                        <button
                          onClick={this.handleEditBtn}
                          type="button"
                          className="btn btn-outline-light"
                        >
                          Cancel
                        </button>
                        <button
                          onClick={this.props.saveContentChanges}
                          type="button"
                          className="btn btn-primary"
                        >
                          Update and Save
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default SignaturePreview;
