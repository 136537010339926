import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import { FORMATPHONENUMBER } from 'utilities/utils';
import './ContactCard.scss';

class KarbonaAccountContactCard extends Component {
  constructor(props) {
    super(props);

    this.state = { accountContactData: {} };
  }

  componentDidMount() {
    const { id } = this.props;
    if (id && id !== null) {
      this.setState({ accountContactData: id });
    }
  }

  render() {
    const { accountContactData } = this.state;
    const { data, id } = this.props;
    let accountContactId = null;
    let acountName = '';
    let contactName = '';
    let webSite = '';
    let phoneNumber = '';
    let primaryEmail = '';
    let jobTitle = '';
    if (data.activeTab === 0) {
      accountContactId =
        data.accountContactCardFor === 'Karbon'
          ? id.karbon_org_key
          : id.account_id;
      acountName =
        data.accountContactCardFor === 'Karbon'
          ? 'karbon_org_name'
          : 'liscio_account_name';
      webSite =
        data.accountContactCardFor === 'Karbon'
          ? 'karbon_account_website'
          : 'liscio_account_website';
      phoneNumber =
        data.accountContactCardFor === 'Karbon'
          ? 'karbon_account_phone_number'
          : 'liscio_account_phone_number';
    } else {
      contactName =
        data.accountContactCardFor === 'Karbon'
          ? this.props.getContactName(
              id,
              'karbon_contact_first_name',
              'karbon_contact_last_name',
            )
          : this.props.getContactName(
              id,
              'liscio_contact_first_name',
              'liscio_contact_last_name',
            );
      accountContactId =
        data.accountContactCardFor === 'Karbon'
          ? id.karbon_contact_key
          : id.contact_id;
      primaryEmail =
        data.accountContactCardFor === 'Karbon'
          ? 'karbon_contact_email'
          : 'liscio_contact_primary_email';
      jobTitle =
        data.accountContactCardFor === 'Karbon'
          ? 'karbon_contact_title'
          : 'liscio_contact_title';
    }
    const accountConactName =
      data.activeTab === 0 ? accountContactData[acountName] : contactName;
    // eslint-disable-next-line no-nested-ternary
    const initials = accountConactName
      ? accountConactName.length > 2
        ? accountConactName.substring(0, 3)
        : accountConactName
      : '';
    if (accountContactId === undefined || accountContactId === null) {
      return null;
    }
    return (
      <ReactTooltip
        id={`${accountContactId}global_account`}
        aria-haspopup="true"
        place="right"
        effect="solid"
        className="contactCardClass"
        delayHide={100}
      >
        <div className="">
          <div
            className="section-title d-flex no-gutters pt-4 pr-3 pb-2 pl-3"
            style={{ backgroundColor: '#434566' }}
          >
            <div className="col">
              <div className="d-flex">
                <span className="avatar avatar--w40">
                  <span className="uName mr-0">{initials.toUpperCase()}</span>
                </span>
                <div className="infoAccount contact-name-section">
                  <h3 className="subHeadline text-dark mb-0">
                    {data.activeTab === 0 ? 'ACCOUNT' : 'CONTACT'}
                  </h3>
                  <h1 className="text-small text-dark mb-0 onlineStatus">
                    {accountConactName}
                  </h1>
                </div>
              </div>
            </div>
          </div>
          {data.activeTab === 0 ? (
            <div className="pt-2 pr-3 pb-3 pl-3">
              <div className="form-group w-100">
                <label>ADDRESS:</label>
                <div className="labelValue">
                  <span>
                    {this.props.getAccountFullAddress(
                      accountContactData,
                      data.accountContactCardFor,
                    )}
                  </span>
                </div>
              </div>
              <div className="form-group w-100">
                <label>WEBSITE:</label>
                <div className="labelValue">
                  <span>
                    {accountContactData[webSite]
                      ? accountContactData[webSite]
                      : '-'}
                  </span>
                </div>
              </div>
              <div className="form-group w-100">
                <label>Phone Number:</label>
                <div className="labelValue">
                  <span>
                    {accountContactData[phoneNumber]
                      ? FORMATPHONENUMBER(accountContactData[phoneNumber])
                      : '-'}
                  </span>
                </div>
              </div>
            </div>
          ) : (
            <div className="pt-2 pr-3 pb-3 pl-3">
              <div className="form-group w-100">
                <label>PRIMARY EMAIL:</label>
                <div className="labelValue">
                  <span>
                    {accountContactData[primaryEmail]
                      ? accountContactData[primaryEmail]
                      : '-'}
                  </span>
                </div>
              </div>
              <div className="form-group w-100">
                <label>JOB TITLE:</label>
                <div className="labelValue">
                  <span>
                    {accountContactData[jobTitle]
                      ? accountContactData[jobTitle]
                      : '-'}
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      </ReactTooltip>
    );
  }
}

export default KarbonaAccountContactCard;
