import { useQuery } from '@tanstack/react-query';
import {
  centsToDollars,
  PaymentEstimateObject,
  transformEstimateAmounts,
} from 'api/tax-delivery';
import axios from 'axios';
import { PaymentEstimates } from 'components/PaymentEstimates/PaymentEstimates';

export type PaymentEstimateDependencyProps = {
  fetchUrl: string;
};

export function PaymentEstimateDependency({
  fetchUrl,
}: PaymentEstimateDependencyProps) {
  const { data } = useQuery({
    queryKey: ['payment-estimate', fetchUrl],
    queryFn: () => axios.get(fetchUrl),
  });

  const paymentEstimates = data?.data.map((estimate: PaymentEstimateObject) =>
    transformEstimateAmounts(estimate, centsToDollars),
  );
  return <PaymentEstimates paymentEstimates={paymentEstimates} />;
}
