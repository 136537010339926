/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import { debounce } from 'lodash';
import { TASK_MASS_ARCHIVE } from 'constants/constants';
import Pagination from 'common/Pagination';
import * as paginationActions from 'actions/PaginationActions';
import * as actions from 'actions/TaskActions';
import {
  AlertMessage,
  getFirmType,
  getSiteHostname,
  getStorageData,
} from 'utilities/utils';
import PageNotFound from 'common/PageNotFound';
import { withRouter } from 'utilities/withRouterWrapper';
import ConfirmationModal from 'common/ConfirmationModal';
import { websocket } from 'websocket';
import { getAuthSession } from 'auth/session';
import { withStorageData } from 'utilities/withStorageData';
import FilterTab from './Tabs/FilterTab';
import StatusTab from './Tabs/StatusTab';
import FilterSegment from './Tabs/FilterSegment';
import List from './List';
import './tasklist.scss';

const noneStyle = { display: 'none' };
const blockStyle = { display: 'block' };
const removeItem = (array, element) =>
  array.filter((e) => !(e.id === element.id) && !element.checked);
class TaskList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      searchKey: '',
      sort: 'desc',
      field: 'updated_at',
      homedata: {},
      pageName: 'all_tasks',
      mount: false,
      selectedCheckbox: [],
      selectedAll: false,
      confirmArchive: false,
      componentContext: this.props.componentContext,
      filterBGClicked: 0,
    };

    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleSorting = this.handleSorting.bind(this);
    this.handleStatus = this.handleStatus.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (getFirmType() === 'liscio-lite') {
      this.props.navigate('/');
    }
  }

  onClickFilterBG = () => {
    this.setState((prevState) => ({
      filterBGClicked: prevState.filterBGClicked + 1,
    }));
  };

  componentDidMount() {
    if (window.location.href.includes('all_tasks')) {
      document.title = 'Tasks';
    }
    // TODO: __HOMEDATA-AUDIT
    const homeData = getStorageData();
    const component = this;
    this.setState({ homedata: homeData });
    const { state } = this.props.location;
    if (state && state.Status) {
      if (state.Status === 'Closed') {
        // this.setState({ taskStatus: 'Archive' });
        this.props.actions.handleTaskStatus('Archive');
      } else {
        // this.setState({ taskStatus: state.Status });
        this.props.actions.handleTaskStatus(state.Status);
      }
    }
    const { match, accountId, contactId } = this.props;
    if (accountId) {
      this.setState({ pageName: 'account_tasks' });
      this.props.actions.fetchAccountTasks(this, accountId);
    } else if (contactId) {
      this.setState({ pageName: 'contact_tasks' });
      this.props.actions.fetchContactTasks(this, contactId);
    } else if (this.state.componentContext === 'email') {
      this.handleRedData();
    } else {
      this.props.actions.fetchTasks(
        this,
        this.props.task_page,
        this.state.sort,
        this.state.field,
        this.props.api,
      );
    }
    if (getAuthSession()) {
      component.tokenInterval = setInterval(() => {
        console.log('Checking for token');
        // TODO: __HOMEDATA-AUDIT
        const local_data = JSON.parse(
          localStorage.getItem(`${getSiteHostname().split(':')[0]} data`),
        );
        if (local_data) {
          clearInterval(this.tokenInterval);
          console.log('trying to connect to task channel');
          component.TaskCable = websocket.consumer;
          component.myTaskSubscription =
            component.TaskCable?.subscriptions.create(
              { channel: 'NewTaskChannel', cpa_id: local_data.cpa_id },
              {
                connected: () => {
                  console.log('connected to newTaskChannel');
                },
                received: () => {
                  // console.log('Data received - ', data);
                  if (!component.state.archiveProcess) {
                    if (accountId) {
                      component.props.actions.fetchAccountTasks(
                        component,
                        accountId,
                        '',
                        true,
                      );
                    } else if (contactId) {
                      component.props.actions.fetchContactTasks(
                        component,
                        contactId,
                        '',
                        true,
                      );
                    } else if (match && match.pathname === '/all_tasks') {
                      if (!component.state.filteredData) {
                        component.props.actions.fetchTasks(
                          component,
                          1,
                          component.state.sort,
                          component.state.field,
                          '',
                          true,
                        );
                        component.props.pagination.taskPagination(1);
                      }
                    }
                  }
                },
                disconnected: () => {
                  console.log('disconnected from tasklist channel');
                },
              },
            );
        }
      }, 200);
    }
  }

  componentWillUnmount() {
    if (this.TaskCable && this.myTaskSubscription) {
      this.TaskCable.subscriptions.remove(this.myTaskSubscription);
    }
    if (this.state.componentContext === 'email') {
      this.handleRedData();
    }
  }

  handleSearchChange(e) {
    this.setState({ searchKey: e.target.value }, () => {
      const { pageName } = this.state;
      if (pageName === 'all_tasks') {
        this.props.actions.fetchTasks(
          this,
          1,
          this.state.sort,
          this.state.field,
          this.props.api,
          false,
          this.state.searchKey,
        );
      } else if (pageName === 'contact_tasks') {
        this.props.actions.fetchContactTasks(
          this,
          this.props.contactId,
          this.state.searchKey,
        );
      } else if (pageName === 'account_tasks') {
        this.props.actions.fetchAccountTasks(
          this,
          this.props.accountId,
          this.state.searchKey,
        );
      }
      // scrolls to top
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;
      return true;
    });
  }

  handlePageChange(index) {
    if (index === this.props.task_page) {
      return false;
    }
    this.props.pagination.taskPagination(index);
    const { pageName } = this.state;
    if (pageName === 'all_tasks') {
      this.props.actions.fetchTasks(
        this,
        index,
        this.state.sort,
        this.state.field,
        this.props.api,
        false,
        this.state.searchKey,
      );
    } else if (pageName === 'contact_tasks') {
      this.props.actions.fetchContactTasks(
        this,
        this.props.contactId,
        this.state.searchKey,
      );
    } else if (pageName === 'account_tasks') {
      this.props.actions.fetchAccountTasks(
        this,
        this.props.accountId,
        this.state.searchKey,
      );
    }
    // scrolls to top
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
    return true;
  }

  handleSorting(fieldName) {
    const { pageName } = this.state;
    let stateSort = this.state.sort;
    if (this.state.field === fieldName) {
      if (stateSort === 'asc') {
        stateSort = 'desc';
      } else {
        stateSort = 'asc';
      }
    } else {
      stateSort = 'desc';
    }
    this.props.pagination.taskPagination(1);
    this.setState({ sort: stateSort, field: fieldName }, () => {
      if (pageName === 'all_tasks') {
        this.props.actions.fetchTasks(
          this,
          1,
          this.state.sort,
          this.state.field,
          this.props.api,
        );
      } else if (pageName === 'contact_tasks') {
        this.props.actions.fetchContactTasks(
          this,
          this.props.contactId,
          this.state.searchKey,
        );
      } else if (pageName === 'account_tasks') {
        this.props.actions.fetchAccountTasks(
          this,
          this.props.accountId,
          this.state.searchKey,
        );
      }
    });
  }

  handleStatus(status) {
    const { pageName, sort, field } = this.state;
    const component = this;
    this.props.pagination.taskPagination(1);
    this.props.actions.handleTaskStatus(status);
    this.setState({}, () => {
      if (pageName === 'all_tasks') {
        component.props.actions.fetchTasks(
          component,
          1,
          sort,
          field,
          component.props.api,
          false,
          this.state.searchKey,
        );
      } else if (pageName === 'contact_tasks') {
        component.props.actions.fetchContactTasks(
          component,
          component.props.contactId,
          this.state.searchKey,
        );
      } else if (pageName === 'account_tasks') {
        // do nothing
      }
    });
  }

  segmentTaskClick = () => {
    const { pageName } = this.state;
    const { navigate, contactId, accountId, contactDetail } = this.props;
    if (pageName === 'contact_tasks') {
      if (contactDetail.primary_email) {
        navigate(`/contactdetails/task/${contactId}`, {
          state: { page: pageName, id: contactId },
        });
      } else {
        AlertMessage(
          'error',
          "Task can't be created as no contact email is found",
          3000,
        );
      }
    } else if (pageName === 'account_tasks') {
      navigate(`/accountdetails/task/${accountId}`, {
        state: { page: pageName, id: accountId },
      });
    }
  };

  filtered = (value) => {
    this.setState({ filteredData: value });
  };

  areAllSelected() {
    let x = 0;
    const { tasks, accountId } = this.props;
    // const { taskStatus } = this.state;
    const { taskStatus } = this.props;
    const templates = accountId ? 'data' : 'templates';
    if (tasks[templates]) {
      tasks[templates][0][taskStatus].map((e) => {
        if (e.checked) x += 1;
        return e;
      });
      const checkbox = document.getElementById('selectallcheckbox');
      if (
        x === tasks[templates][0][taskStatus].length &&
        (x && tasks[templates][0][taskStatus].length) !== 0
      ) {
        this.setState({ selectedAll: true });
        if (checkbox) checkbox.indeterminate = false;
      } else {
        this.setState({ selectedAll: false });
        if (checkbox) checkbox.indeterminate = true;
      }
      if (x === 0) {
        if (checkbox) checkbox.indeterminate = '';
      }
    }
    // this for adding email to task
    if (this.props.getSelectedRecords) {
      console.log('reached inside tasklist');
      const selectedRecords = [];
      this.state.selectedCheckbox.forEach((e) => {
        selectedRecords.push(e.id);
      });
      console.log('data', selectedRecords);
      this.props.getSelectedRecords(selectedRecords);
    }
  }

  checkboxAction = (item, index) => {
    let { selectedCheckbox } = this.state;
    const { tasks, accountId } = this.props;
    const { taskStatus } = this.props;
    // const { taskStatus } = this.state;
    const templates = accountId ? 'data' : 'templates';
    if (tasks[templates][0][taskStatus][index].checked) {
      tasks[templates][0][taskStatus][index].checked = false;
    } else {
      tasks[templates][0][taskStatus][index].checked = true;
    }
    if (tasks[templates][0][taskStatus][index].checked) {
      selectedCheckbox.push(tasks[templates][0][taskStatus][index]);
    } else {
      selectedCheckbox = removeItem(
        selectedCheckbox,
        tasks[templates][0][taskStatus][index],
      );
    }
    this.props.actions.fetchAll(tasks);
    this.setState({ selectedCheckbox }, () => {
      this.areAllSelected();
    });
  };

  handleSelectAll = () => {
    let { selectedCheckbox } = this.state;
    const { tasks, accountId, taskStatus } = this.props;
    const { selectedAll } = this.state;
    const templates = accountId ? 'data' : 'templates';
    let isAllChecked = 0;
    let checked = false;
    tasks[templates][0][taskStatus].map((item) => {
      if (item.checked) {
        isAllChecked += 1;
      }
      return item;
    });
    if (!selectedAll && isAllChecked === 0) {
      checked = true;
    }
    tasks[templates][0][taskStatus].map((item) => {
      const eachone = item;
      eachone.checked = checked;
      if (selectedCheckbox.length) {
        if (checked) {
          selectedCheckbox.push(eachone);
        } else {
          selectedCheckbox = removeItem(selectedCheckbox, eachone);
        }
      }
      return eachone;
    });
    if (!selectedCheckbox.length && checked) {
      selectedCheckbox = tasks[templates][0][taskStatus];
    }
    this.props.actions.fetchAll(tasks);
    this.setState({ selectedCheckbox }, () => {
      this.areAllSelected();
    });
  };

  handleRedData = () => {
    this.props.actions.handleApiUrl('');
    this.props.pagination.taskPagination(1);
    this.props.actions.fetchTasks(
      this,
      1,
      this.state.sort,
      this.state.field,
      '',
    );
    this.props.actions.handleFilters({});
  };

  massArchive = (confirmed) => {
    const { selectedCheckbox } = this.state;
    const { accountId, contactId, tasks, taskStatus, task_page } = this.props;
    const allSelected = this.state.selectedAll;
    const component = this;
    if (confirmed) {
      // do mass archive
      const array = { task_ids: [] };
      selectedCheckbox.map((each) => {
        array.task_ids.push(each.id);
        return array;
      });
      this.setState({
        confirmArchive: false,
        loading: true,
      });
      const request = axios.post(TASK_MASS_ARCHIVE, array);
      this.setState(
        {
          selectedCheckbox: [],
          selectedAll: false,
        },
        () => {
          const templates = accountId ? 'data' : 'templates';
          tasks[templates][0][taskStatus].map((item) => {
            const eachone = item;
            eachone.checked = false;
            return eachone;
          });
          this.props.actions.fetchAll(tasks);
        },
      ); // code written to deselect checkbox fast without waiting for task api to hit for new response
      request
        .then((response) => {
          component.setState({ loading: false });
          if (response.data.status === 200) {
            component.setState({ archiveProcess: false }, () => {
              AlertMessage('success', response.data.message, 3000);
              if (accountId) {
                component.props.actions.fetchAccountTasks(
                  component,
                  accountId,
                  this.state.searchKey,
                  '',
                  true,
                );
              } else if (contactId) {
                component.props.actions.fetchContactTasks(
                  component,
                  contactId,
                  this.state.searchKey,
                  '',
                  true,
                );
              } else if (window.location.href.includes('/all_tasks')) {
                const taskPage =
                  task_page > 1 && allSelected ? task_page - 1 : task_page;
                component.props.pagination.taskPagination(taskPage);
                component.props.actions.fetchTasks(
                  component,
                  taskPage,
                  component.state.sort,
                  component.state.field,
                  component.props.api,
                  true,
                );
              }
            });
          } else {
            AlertMessage('error', response.data.message, 3000);
          }
        })
        .catch(() => {
          component.setState({ loading: false });
          AlertMessage('error', 'Some Error Occured', 3000);
        });
    } else {
      this.setState({ confirmArchive: true });
    }
  };

  render() {
    const {
      pageName,
      loading,
      mount,
      confirmArchive,
      // taskStatus,
      homedata,
      componentContext,
    } = this.state;
    const {
      accountId,
      contactId,
      tasks,
      contactDetail,
      accountData: accountDetail,
      taskStatus,
    } = this.props;
    let prefTypes = [];
    if (homedata && homedata.pref_type_options) {
      prefTypes = homedata.pref_type_options.task_type_color;
    }
    const totalpages =
      tasks[`${taskStatus}_total`] !== 0
        ? Math.ceil(tasks[`${taskStatus}_total`] / 15)
        : 0;
    const totalrecords = tasks[`${taskStatus}_total`];
    let offset = 1;
    let off = 0;
    if (tasks.res_data !== undefined) {
      offset = tasks.res_data[`${taskStatus.toLowerCase()}_offset`];
      off = tasks.res_data[`${taskStatus.toLowerCase()}_off`];
    }
    const templates = accountId ? 'data' : 'templates';
    const tasksAll =
      tasks[templates] !== undefined ? tasks[templates][0][taskStatus] : [];
    return (
      <div
        className="TaskList"
        data-testid="taskList"
        id={pageName === 'all_tasks' ? '' : ''}
      >
        {accountId || contactId || componentContext ? null : (
          <StatusTab
            component={this}
            records={!loading || mount ? tasks : undefined}
            data={this.state}
            taskStatus={this.props.taskStatus}
            handleStatus={this.handleStatus}
          />
        )}
        <div className="center-wrapper">
          {accountId || contactId ? (
            <FilterSegment
              records={this.props.tasks}
              contactDetail={contactDetail}
              accountDetail={accountDetail}
              data={this.state}
              taskStatus={this.props.taskStatus}
              handleSearchChange={debounce(this.handleSearchChange, 300)}
              handleStatus={this.handleStatus}
              segmentTaskClick={this.segmentTaskClick}
              massArchive={this.massArchive}
              data-testid="filterSegment"
            />
          ) : null}
          {/* <FilterSegment /> */}
          {/* <input placeholder="Search Tasks..." style={{ width: '50%', marginBottom: '20px' }} type="text" className="form-control" onChange={this.handleElasticSearch} /> */}
          <section onClick={this.onClickFilterBG} role="presentation">
            {pageName === 'all_tasks' ? (
              <FilterTab
                name="tasklist"
                sort={this.state.sort}
                field={this.state.field}
                component={this}
                data={this.state}
                taskStatus={this.props.taskStatus}
                filterBGClicked={this.state.filterBGClicked}
                handleSearchChange={debounce(this.handleSearchChange, 300)}
                filtered={this.filtered}
                massArchive={this.massArchive}
                componentContext={this.props.componentContext}
                data-testid="filterTab"
              />
            ) : null}
            {loading && (
              <div id="loading" style={loading ? blockStyle : noneStyle} />
            )}
            {tasks[templates] !== undefined ? (
              <div className="tab-content">
                <div className="tab-pane active" id="review">
                  <div id="task-list" className="table tableWrap">
                    <List
                      tasks={tasksAll}
                      handleSorting={this.handleSorting}
                      checkboxAction={this.checkboxAction}
                      selectedAll={this.state.selectedAll}
                      handleSelectAll={this.handleSelectAll}
                      pagination={this.props.tasks_page}
                      data={this.state}
                      pref={prefTypes}
                      totalRecords={totalrecords}
                      taskStatus={taskStatus}
                      name="tasklist"
                    />
                    {pageName === 'all_tasks' ? (
                      <Pagination
                        currentPage={
                          tasksAll.length >= 1 ? this.props.task_page : 0
                        }
                        totalPages={totalpages}
                        totalRecords={totalrecords}
                        offset={totalrecords === 0 ? offset - 1 : offset}
                        off={off}
                        handlePageChange={this.handlePageChange}
                        data-testid="pagination"
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            ) : (
              <div id="content">
                <div className="center-wrapper">
                  <PageNotFound
                    name="message"
                    message="There are no records to show you right now."
                  />
                </div>
              </div>
            )}
          </section>
          <ConfirmationModal
            isOpen={confirmArchive}
            noBtnClick={() => this.setState({ confirmArchive: false })}
            headerText="You are about to archive these tasks."
            messageText="Would you like to proceed?"
            yesBtnClick={() => this.massArchive(true)}
            yesBtnText="Yes"
            noBtnText="No"
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  tasks: state.taskReducer.tasks,
  api: state.taskReducer.apiUrl,
  taskStatus: state.taskReducer.taskStatus,
  task_page: state.paginationReducer.task_page,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
  pagination: bindActionCreators(paginationActions, dispatch),
});

export default withStorageData(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(TaskList)),
);
