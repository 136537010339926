import React, { useEffect } from 'react';

/*
  This is for sign in with google and outlook.
  This is for opening liscio dispatch app after user
  is redirected from google and outlook.
*/

const LiscioDispatchOauth = () => {
  useEffect(() => {
    const parsedUrl = new URL(window.location.href);
    const state_value = parsedUrl.searchParams.get('state');
    const code = parsedUrl.searchParams.get('code');
    if (code) {
      window.location.replace(`${state_value}://?code=${code}`);
    }
  }, []);

  return (
    <div id="content" className="pt-4">
      <div className="center-wrapper">
        <div className="emptyBox text-center">
          <div className="emptyBox-icon">
            <h3 className="text-large">Opening Liscio Dispatch App</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LiscioDispatchOauth;
