/* eslint-disable global-require */
import React, { Component } from 'react';
import moment from 'moment';
import Interweave from 'interweave';
import { removeBreakElement } from 'common/NotesUtilities';
import { printPdf } from 'utilities/utils';

class NotesPdfLayout extends Component {
  componentDidMount() {
    const styleElement =
      '<style>body{font-family:"Source Sans Pro",sans-serif;-webkit-font-smoothing:antialiased;color:#212b36;line-height:1.3}#pdf-logo{height:32px}#pdf-logo img{height:32px}p{font-size:15px;margin:0;line-height:18px;color:#212b36}b,strong{font-weight:600}h5{font-size:20px;font-weight:500}.text-dark{color:#919eab!important}p b{color:#000}.div-top{margin-top:20px}.due-date{text-align:right;float:right}.left-content{float:left}.div-top b{margin-right:10px;display:inline-block}.Date{float:right;margin-top:18px}hr{border:none;height:1px;background:#d2d2d2;color:#d2d2d2;margin-top:10px}.highlight{color:#299ac4}.bottom{margin:20px 0 0}li::before{content:"•"}.no-padding{padding:0;border:0}.bottomBorder{width:100%;height:1px;border-bottom:1px solid #dfe3e8}.each_thread{border-bottom:1px solid #dfe3e8;padding:.9rem 0;opacity:.9}.each_thread:last-child{border:0}.print-comment-sections{padding:10px 0;break-inside:auto}.print-comment-sections .comment_content{color:#212b36;font-size:15px}.right-panel-top{border-bottom:solid 1px #e8ebf0;margin-bottom:10px}.visibility{visibility:hidden;width:1px;height:1px;position:absolute}.task-action-icons{margin-top:10px}</style>';
    const { data } = this.props;
    printPdf(
      `notes_to_print${data.id}`,
      `notes_iframe${data.id}`,
      styleElement,
    );
    setTimeout(() => this.props.handlePrintPdf(), 1000);
  }

  render() {
    const { data } = this.props;
    return (
      <div style={{ height: 1, overflow: 'auto' }}>
        <iframe
          title="print terms condition"
          id={`notes_iframe${data.id}`}
          style={{
            height: '0',
            width: '0',
          }}
        />
        <div className="pdf-html" id={`notes_to_print${data.id}`}>
          <div id="pdf-logo">
            <img src={require('images/liscioLogo.png')} alt="liscio logo" />
          </div>
          <br />
          <div className="bottomBorder col" />
          <div className="div-top">
            <p className="left-content">
              <b>Contact:</b>
              <span>{data.contact}</span>
            </p>
            <p className="due-date">
              <b>Due Date:</b>
              <span>{moment(data.date).format('MM/DD/YY')}</span>
            </p>
            <br />
            <br />
            <p className="left-content">
              <b>Account:</b>
              <span>{data.account}</span>
            </p>
            <br />
            <br />
            <p>
              <b>Subject:</b>
              <span>{data.subject}</span>
            </p>
            <br />
            {data.documents && data.documents.length ? (
              <div>
                <div className="bottomBorder col" />
                <br />
                <p>
                  <b>Attachments</b>
                </p>
                <br />
                {data.documents.map((each) => (
                  <div key={each.id}>
                    <p>
                      <span>{each.file_name}</span>
                    </p>
                    <br />
                  </div>
                ))}
                {/* <hr /> */}
              </div>
            ) : null}
          </div>
          <div className="bottomBorder col" />
          <br />
          <p>
            <b>Comment</b>
          </p>
          <br />
          <Interweave
            allowAttributes
            content={removeBreakElement(
              data.comment.replace(/<a\b[^>]*>/g, '').replace(/<\/a>/g, ''),
            )}
          />
          {/* <p dangerouslySetInnerHTML={{ __html: removeBreakElement(data.comment.replace(/<a\b[^>]*>/g, '').replace(/<\/a>/g, '')) }} /> */}
        </div>
      </div>
    );
  }
}

export default NotesPdfLayout;
