import axios from 'axios';
import { REQUEST_FILTERS_EMPLOYEES_AND_CONTACTS } from 'constants/constants';

export interface RequestFiltersEmployeesAndContacts {
  id: number;
  uuid: string;
  label: string;
  status: string;
  type: 'emp' | 'contact';
}

export const getRequestFiltersEmployeesAndContacts = async () => {
  const result = await axios.get<RequestFiltersEmployeesAndContacts[]>(
    REQUEST_FILTERS_EMPLOYEES_AND_CONTACTS,
  );
  return result.data;
};
