import React, { Component } from 'react';
import Axios from 'axios';
import { isNil } from 'lodash';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { ACCOUNT, GENERATE_ONBOARD_UPDATE_MESSAGE } from 'constants/constants';
import { withQueryClient } from 'utilities/withQueryClient';
import {
  AlertMessage,
  getCpaPreferences,
  isUserEmployee,
} from 'utilities/utils';
import { SelectField, CheckboxField } from 'common/CustomFields';
import InputField from 'common/InputField';
import USStates from 'components/Contacts/ContactCreation/usastates.json';
import { EncryptedField } from 'common/EncryptedField/EncryptedField';
import { updateEncryptedField } from 'api/encrypted-fields';
import PayrollView from './PayrollView';

const cleanLegacyEncryptedData = (data) => {
  const output = data;

  // We don't want to send this data to the /api/v3/accounts/ endpoint
  output.encryptedCoFedEftpsPin = undefined;
  output.encryptedCoFedEftpsPwd = undefined;
  output.encryptedCoStateWithholdingId = undefined;
  output.encryptedCoStateUnemployementId = undefined;

  return output;
};

class Payroll extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formdata: {},
      errors: {},
      initialValues: {},
      preferences: getCpaPreferences(),
      editData: props.editMode || false,
    };
  }

  componentDidMount() {
    const {
      data: { account },
      editMode,
    } = this.props;
    const data = account;

    this.setState({
      formdata: { ...data },
      errors: {},
      initialValues: data,
      editData: editMode || false,
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { editMode } = this.props;

    if (editMode !== nextProps.editMode) {
      this.setState({
        editData: nextProps.editMode,
        formdata: nextProps.editMode
          ? nextState.formdata
          : nextState.initialValues,
      });
    }

    return true;
  }

  handleInputFieldChange = (inputvalue, fieldname) => {
    const value =
      inputvalue && inputvalue !== null && inputvalue.length
        ? inputvalue
        : undefined;
    const { formdata } = this.state;
    this.setState({ formdata: { ...formdata, [fieldname]: value } });
  };

  handleEncryptedFieldChange = (inputvalue, fieldname) => {
    this.setState((currentState) => ({
      formdata: { ...currentState.formdata, [fieldname]: inputvalue },
    }));
  };

  handleSelectChange = (inputvalue, fieldname) => {
    const { value } = inputvalue && inputvalue !== null ? inputvalue : {};
    const { formdata } = this.state;
    this.setState({ formdata: { ...formdata, [fieldname]: value } });
  };

  handleCheckboxChange = (inputvalue, fieldname) => {
    const { formdata } = this.state;
    this.setState({
      formdata: { ...formdata, [fieldname]: !formdata[fieldname] },
    });
  };

  sendMessagetoEmployee = () => {
    if (!isUserEmployee()) {
      const account_id = JSON.parse(this.props.id);
      Axios.post(GENERATE_ONBOARD_UPDATE_MESSAGE, { account_id }).then(
        (res) => {
          console.log(res.status);
        },
      );
    }
  };

  getEncryptedFieldsPromises = () => {
    const { formdata } = this.state;

    const encryptedFieldsPromises = [];

    if (!isNil(formdata.encryptedCoFedEftpsPin)) {
      encryptedFieldsPromises.push(
        updateEncryptedField({
          fieldName: 'co_fed_eftps_pin',
          recordType: 'Account',
          recordId: this.props.id,
          newValue: formdata.encryptedCoFedEftpsPin,
        }),
      );
    }
    if (!isNil(formdata.encryptedCoFedEftpsPwd)) {
      encryptedFieldsPromises.push(
        updateEncryptedField({
          fieldName: 'co_fed_eftps_pwd',
          recordType: 'Account',
          recordId: this.props.id,
          newValue: formdata.encryptedCoFedEftpsPwd,
        }),
      );
    }
    if (!isNil(formdata.encryptedCoStateWithholdingId)) {
      encryptedFieldsPromises.push(
        updateEncryptedField({
          fieldName: 'co_state_withholding_id',
          recordType: 'Account',
          recordId: this.props.id,
          newValue: formdata.encryptedCoStateWithholdingId,
        }),
      );
    }
    if (!isNil(formdata.encryptedCoStateUnemployementId)) {
      encryptedFieldsPromises.push(
        updateEncryptedField({
          fieldName: 'co_state_unemployement_id',
          recordType: 'Account',
          recordId: this.props.id,
          newValue: formdata.encryptedCoStateUnemployementId,
        }),
      );
    }

    return encryptedFieldsPromises;
  };

  handleSubmit = () => {
    // Encrypted fields
    const encryptedFieldsPromises = this.getEncryptedFieldsPromises();

    let formdata = { ...this.state?.formdata };
    formdata = cleanLegacyEncryptedData(formdata);
    Object.keys(formdata).forEach((key) => {
      if (
        formdata[key] === undefined ||
        (formdata[key] !== null && formdata[key] === '') ||
        (formdata[key] !== undefined &&
          formdata[key] !== null &&
          formdata[key] === 'Invalid date')
      ) {
        formdata[key] = null;
      }
    });

    Promise.all(encryptedFieldsPromises)
      .then((encryptedResponse) => {
        /**
         * below, something like optimistic UI is executed,
         * so we need to modify the data accordingly based on the server's response
         */
        encryptedResponse.forEach((encryptedElement) => {
          const formName = `encrypted_${encryptedElement.field_name}`;
          if (formdata[formName]) {
            formdata[formName].populated = Boolean(encryptedElement.enc_value);
          }
        });
      })
      .then(() =>
        Axios.put(`${ACCOUNT}/${this.props.id}`, formdata).then((res) => {
          // console.log(`payroll UPDATE HIT`, this.props.id)
          if (res.status === 200 && res.data.status === 200) {
            AlertMessage('success', res.data.message, 3000);
            const data = formdata;
            this.setState({
              formdata: {
                ...data,
                encryptedCoFedEftpsPin: null,
                encryptedCoFedEftpsPwd: null,
                encryptedCoStateWithholdingId: null,
                encryptedCoStateUnemployementId: null,
              },
              errors: {},
              initialValues: data,
              editData: false,
            });
          }
          this.sendMessagetoEmployee();
        }),
      )
      .then(() => {
        // Clear cache
        this.props.queryClient?.removeQueries({ queryKey: ['encryptedField'] });
      });
  };

  handleCancelBtn = () => {
    const { initialValues } = this.state;
    this.setState({
      editData: false,
      formdata: initialValues,
    });

    const { onEditModeChange } = this.props;
    if (onEditModeChange) {
      onEditModeChange(false);
    }
  };

  handleEditButton = () => {
    this.setState({ editData: true });

    const { onEditModeChange } = this.props;
    if (onEditModeChange) {
      onEditModeChange(true);
    }
  };

  render() {
    const { formdata, errors, preferences, editData } = this.state;

    if (!editData) {
      return (
        <PayrollView
          data={this.state}
          handleEditButton={this.handleEditButton}
        />
      );
    }
    return (
      <div className="center-wrapper col-12">
        <form action="">
          <input
            type="password"
            name="password123123123123"
            autoComplete="off"
            className="hidden"
          />
          <div className="row">
            <SelectField
              id="co_pay_freq"
              name="co_pay_freq"
              label="Pay Frequency"
              options={preferences.pay_frequency}
              handleChange={(input) =>
                this.handleSelectChange(input, 'co_pay_freq')
              }
              defaultValue={undefined}
              value={{
                value: formdata.co_pay_freq,
                label: formdata.co_pay_freq,
              }}
              className="col-5 form-group"
              error={
                this.state.errors ? this.state.errors.co_pay_freq : undefined
              }
            />
          </div>
          <div className="row">
            <div className="form-group col-2">
              <label>Pay Items:</label>
            </div>
            <div className="col">
              <div className="row">
                <CheckboxField
                  name="co_is_pay_hourly"
                  label="Hourly"
                  onChange={this.handleCheckboxChange}
                  value={formdata.co_is_pay_hourly}
                  className="col-sm-6 col-md-4 col-lg-2"
                />
                <CheckboxField
                  name="co_is_pay_salary"
                  label="Salary"
                  onChange={this.handleCheckboxChange}
                  value={formdata.co_is_pay_salary}
                  className="col-sm-6 col-md-4 col-lg-2"
                />
                <CheckboxField
                  name="co_is_pay_reimbursement"
                  label="Reimbursement"
                  onChange={this.handleCheckboxChange}
                  value={formdata.co_is_pay_reimbursement}
                  className="col-sm-6 col-md-4 col-lg-2"
                />
                <CheckboxField
                  name="co_is_pay_bonus"
                  label="Bonuses / Extra Pay Plus"
                  onChange={this.handleCheckboxChange}
                  value={formdata.co_is_pay_bonus}
                  className="col-sm-6 col-md-4 col-lg-3"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="form-group col-2">
              <label>Deductions:</label>
            </div>
            <div className="col">
              <div className="row">
                <CheckboxField
                  name="co_is_ded_401k"
                  label="401(K)"
                  onChange={this.handleCheckboxChange}
                  value={formdata.co_is_ded_401k}
                  className="col-sm-6 col-md-4 col-lg-2"
                />
                <CheckboxField
                  name="co_is_ded_401k_roth"
                  label="401(K) Roth"
                  onChange={this.handleCheckboxChange}
                  value={formdata.co_is_ded_401k_roth}
                  className="col-sm-6 col-md-4 col-lg-2"
                />
                <CheckboxField
                  name="co_is_ded_sample_ira"
                  label="Simple IRA"
                  onChange={this.handleCheckboxChange}
                  value={formdata.co_is_ded_sample_ira}
                  className="col-sm-6 col-md-4 col-lg-2"
                />
                <CheckboxField
                  name="co_is_ded_health"
                  label="Health"
                  onChange={this.handleCheckboxChange}
                  value={formdata.co_is_ded_health}
                  className="col-sm-6 col-md-4 col-lg-2"
                />
                <CheckboxField
                  name="co_is_ded_garnishments"
                  label="Garnishments"
                  onChange={this.handleCheckboxChange}
                  value={formdata.co_is_ded_garnishments}
                  className="col-sm-6 col-md-4 col-lg-2"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="form-group col-2">
              <label>Accrual Tracking:</label>
            </div>
            <div className="col">
              <div className="row">
                <CheckboxField
                  name="co_is_accrual_vataion"
                  label="Vacation"
                  onChange={this.handleCheckboxChange}
                  value={formdata.co_is_accrual_vataion}
                  className="col-sm-6 col-md-4 col-lg-2"
                />
                <CheckboxField
                  name="co_is_accrual_sick"
                  label="Sick"
                  onChange={this.handleCheckboxChange}
                  value={formdata.co_is_accrual_sick}
                  className="col-sm-6 col-md-4 col-lg-2"
                />
              </div>
            </div>
          </div>
          <div className="col-container mb-4 section-title">
            <h5>Payroll Taxes</h5>
          </div>
          <div className="row">
            <EncryptedField
              recordId={formdata?.id}
              label="Federal EFTPS PIN"
              recordType="Account"
              fieldName="co_fed_eftps_pin"
              formFieldName="encryptedCoFedEftpsPin"
              className="col-sm-12 col-lg-6 col-md-6 form-group"
              value={formdata?.encryptedCoFedEftpsPin}
              error={errors?.encryptedCoFedEftpsPin}
              onChange={this.handleEncryptedFieldChange}
              hasEncryptedValue={
                formdata?.encrypted_co_fed_eftps_pin?.populated
              }
            />
            <EncryptedField
              recordId={formdata?.id}
              label="Federal EFTPS Password"
              recordType="Account"
              fieldName="co_fed_eftps_pwd"
              formFieldName="encryptedCoFedEftpsPwd"
              className="col-sm-12 col-lg-6 col-md-6 form-group"
              value={formdata?.encryptedCoFedEftpsPwd}
              error={errors?.encryptedCoFedEftpsPwd}
              onChange={this.handleEncryptedFieldChange}
              hasEncryptedValue={
                formdata?.encrypted_co_fed_eftps_pwd?.populated
              }
            />
            <SelectField
              id="co_payroll_state"
              name="co_payroll_state"
              label="Select State"
              options={USStates}
              handleChange={(input) =>
                this.handleSelectChange(input, 'co_payroll_state')
              }
              defaultValue={undefined}
              value={{
                value: formdata.co_payroll_state,
                label: formdata.co_payroll_state,
              }}
              className="col-sm-12 col-lg-3 col-md-6 form-group"
              error={
                this.state.errors
                  ? this.state.errors.co_payroll_state
                  : undefined
              }
            />
            <EncryptedField
              recordId={formdata?.id}
              label="State Withholding ID"
              recordType="Account"
              fieldName="co_state_withholding_id"
              formFieldName="encryptedCoStateWithholdingId"
              className="col-sm-12 col-lg-3 col-md-6 form-group"
              value={formdata?.encryptedCoStateWithholdingId}
              error={errors?.encryptedCoStateWithholdingId}
              onChange={this.handleEncryptedFieldChange}
              hasEncryptedValue={
                formdata?.encrypted_co_state_withholding_id?.populated
              }
            />
            <EncryptedField
              recordId={formdata?.id}
              label="State Unemployment ID"
              recordType="Account"
              fieldName="co_state_unemployement_id"
              formFieldName="encryptedCoStateUnemployementId"
              className="col-sm-12 col-lg-3 col-md-6 form-group"
              value={formdata?.encryptedCoStateUnemployementId}
              error={errors?.encryptedCoStateUnemployementId}
              onChange={this.handleEncryptedFieldChange}
              hasEncryptedValue={
                formdata?.encrypted_co_state_unemployement_id?.populated
              }
            />
            <InputField
              name="co_state_ui_base_rate"
              label="State UI Base Rate"
              className="col-sm-12 col-lg-3 col-md-6 form-group"
              onChange={this.handleInputFieldChange}
              onBlur={this.handleBlur}
              value={formdata.co_state_ui_base_rate}
              error={errors.co_state_ui_base_rate}
              autoComplete="random_base_rate"
            />
          </div>
          <div className="row no-gutters justify-content-end mt-4">
            <button
              type="button"
              className="btn btn-outline-light mr-3"
              onClick={this.handleCancelBtn}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={this.handleSubmit}
            >
              Save
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default withLDConsumer()(withQueryClient(Payroll));
