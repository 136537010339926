/* eslint-disable */
// TODO: change history to navigation object
// TODO: will come back again
import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Label,
  Input,
  ButtonGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import Axios from 'axios';
import moment from 'moment-timezone';
import * as Utilities from 'utilities/utils';
import * as UploadFilesActions from 'actions/UploadFilesActions';
import ConfirmationModal from 'common/ConfirmationModal';
import { GET_DOCUMENT_DETAILS } from 'constants/constants';
import PdfViewer from 'components/PdfViewer/PdfViewer';
import DocumentShow from '../UploadedFiles/DocumentShow';
import MultipleSourceModal from './MultipleSourceModal';
import ManualSourceModal from './ManualSourceModal';

const noneStyle = { display: 'none' };
const blockStyle = { display: 'block' };
const timeZone =
  Intl.DateTimeFormat().resolvedOptions().timeZone || moment.tz.guess(true);
const filesHeaders = [
  {
    value: 'aws_url_file_name',
    label: 'File Name',
    className: 'col-3 thCol',
  },
  {
    value: 'tags',
    label: 'Tags',
    className: 'col-3 thCol',
  },
  // {
  //   value: 'associated_with',
  //   label: 'Associated With',
  //   className: 'col-3 thCol',
  // },
  {
    value: 'upload_file_month',
    label: 'Month',
    className: 'col-2 thCol',
  },
  {
    value: 'upload_file_year',
    label: 'Year',
    className: 'col-2 thCol',
  },
  {
    value: 'created_at',
    label: 'Date Added',
    className: 'col thCol text-right',
  },
];

const isIE = /* @cc_on!@ */ false || !!document.documentMode;

class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDocPop: false,
      hovered: false,
      selectedItem: null,
      showAlert: '',
    };
  }

  UNSAFE_componentWillMount() {
    const { match, previewFileDetail, previewFileModal } = this.props;
    if (
      match &&
      match.url === '/files' &&
      this.props.action === 'POP' &&
      previewFileModal
    ) {
      this.onButtonClose(previewFileDetail);
    }
  }

  onButtonClose = (item = null, updateFilesData) => {
    if (item) {
      const removeDuplicateTags = Utilities.removeDuplicateTags(item.tags);
      const selected = item;
      selected.tags = removeDuplicateTags;
      this.setState({ selectedItem: selected, loading: false });
      this.setState({ showDocPop: true, selectedItem: selected }, () => {
        const { liscioVault } = this.props;
        if (liscioVault) {
          this.getDocumentDetails();
        }
      });
    } else {
      this.props.files.previewFile({}, false);
      this.setState({ showDocPop: false }, () => {
        // this.props.component.dataAfterEditDocument();
        if (updateFilesData) {
          this.props.component.dataAfterEditDocument();
        }
      });
    }
  };

  getDocumentDetails = () => {
    const { selectedItem } = this.state;
    const request = Axios.get(`${GET_DOCUMENT_DETAILS}/${selectedItem.id}`);
    request.then((res) => {
      if (res.status === 200 && res.data.data) {
        const documentData = res.data.data;
        this.setState({
          loading: false,
          showDocPop: true,
          selectedItem: documentData,
        });
      }
    });
  };

  listOfFilesState = (status = '') => {
    this.props.component.listOfFilesState(status);
  };

  editFileName = (e, item) => {
    e.stopPropagation();
    this.props.component.fileExtention(item);
  };

  redirectLink = (item) => {
    const { navigate, match } = this.props;
    if (
      item.source.toLowerCase() === 'task' &&
      item.link !== undefined &&
      item.link !== null
    ) {
      if (Utilities.getFirmType() === 'liscio-lite') {
        this.setState({
          showAlert:
            'Associated Task cannot be viewed. Please upgrade to LiscioPRO',
        });
      } else {
        navigate(`/task/detail/${item.link}`, {
          state: { from: 'uploadlist' },
        });
      }
    } else if (
      item.source.toLowerCase() === 'message' &&
      item.link !== undefined &&
      item.link !== null
    ) {
      const id = encodeURIComponent(item.link);
      navigate(`/inbox/${id}`, { state: { from: 'uploadlist' } });
    } else if (
      item.source.toLowerCase() === 'note' &&
      item.link !== undefined &&
      item.link !== null
    ) {
      if (Utilities.getFirmType() === 'liscio-lite') {
        this.setState({
          showAlert:
            'Associated Note cannot be viewed. Please upgrade to LiscioPRO',
        });
      } else {
        navigate(`/notedetails/${item.link}`, {
          state: { from: 'uploadlist' },
        });
      }
    } else if (
      item.source === 'Multiple' ||
      ((item.source.toLowerCase() === 'message' ||
        item.source.toLowerCase() === 'note' ||
        item.source.toLowerCase() === 'task') &&
        item.link === null)
    ) {
      // do nothing
      this.setState({ showMultipleSource: true });
      const { location } = this.props;
      const { hash } = location;
      if (hash && hash.indexOf('#files') > -1) {
        this.props.navigate(`${location.pathname}#files#multiple-${item.id}`);
      } else {
        this.props.navigate(`/files#multiple-${item.id}`);
      }
    } else if (item.source === 'Manual') {
      this.setState({
        fileDetails: item,
        showManualSource: true,
      });
    }
  };

  returnClassName = (name, cls = '') => {
    if (name === 'associated_with' && cls === 'click') {
      return false;
    }
    const { data } = this.props;
    let clas = '';
    if (cls === 'click') {
      const secondcls = data.uploadFilesList.length
        ? data.editFileNameId
          ? null
          : () => this.props.component.handleSorting(name)
        : null;
      clas = secondcls;
    } else if (cls === 'icon') {
      clas = data.uploadFilesList.length
        ? Utilities.sortingIcon(data.field, name, data.sort)
        : null;
    } else {
      const secondcls = data.uploadFilesList.length
        ? Utilities.sortingIcon(data.field, name, data.sort, 'className')
        : '';
      clas = `${secondcls} ${cls}`;
    }
    return clas;
  };

  closeModal = (source, index) => {
    this.setState({ [source]: false }, () => {
      const { location, history } = this.props;
      if (index && index === true) {
        const { hash } = location;
        if (hash && hash.indexOf('#multiple') > -1) {
          this.props.navigate(location.pathname);
        }
        this.props.component.uploadFile('close');
      } else {
        const { hash } = location;
        if (hash && hash.indexOf('#files') > -1) {
          this.props.navigate(`${location.pathname}#files`);
        } else {
          this.props.navigate('/files');
        }
      }
    });
  };

  renderRecipients(item, row) {
    let sharedWith = '';
    if (item.recipient && item.recipient.length) {
      sharedWith = item.recipient.map((each, index) => {
        if (index < 4) {
          return (
            <div
              key={index}
              data-tip
              data-for={`${each.initials}_${row}_${index}`}
              className="avatar avatar--w30 d-inline-block"
            >
              {each.name ? (
                each.avatar ? (
                  <img
                    src={each.avatar}
                    alt="I"
                    width="20"
                    height="20"
                    className="rounded-circle"
                  />
                ) : (
                  <span className="uName">{each.initials}</span>
                )
              ) : each.account_name ? (
                <span className="uName">
                  {Utilities.getInitials(each.account_name)}
                </span>
              ) : (
                ''
              )}
              <ReactTooltip
                id={`${each.initials}_${row}_${index}`}
                effect="solid"
              >
                <span className="contactcard-team-member">
                  {each.name +
                    (each.account_name ? ` . ${each.account_name}` : '')}
                </span>
              </ReactTooltip>
            </div>
          );
        }
        if (index === 4) {
          const list = item.recipient.map((e, i) => {
            if (i >= 4) {
              return (
                <a
                  key={item.recipient.indexOf(e)}
                  className="dropdown-item py-1"
                >
                  <div className="d-flex">
                    <span className="avatar avatar--w20 align-self-start">
                      <span className="uName">
                        {e.initials || Utilities.getInitials(e.account_name)}
                      </span>
                    </span>
                    <div className="">
                      <h5 className="fontSize letterSpace-1 mb-0">{e.name}</h5>
                      <h4 className="subHeadline text-light letterSpace-1 mb-0">
                        {e.account_name}
                      </h4>
                    </div>
                  </div>
                </a>
              );
            }
            return null;
          });
          return (
            <div
              key={index}
              className="dropdown dropdown--small d-inline-block ml-0"
            >
              <a
                className="btn p-0 dropdown-toggle border-0"
                data-toggle="dropdown"
                style={{ lineHeight: '20px' }}
              >
                <span className="avatar avatar--w30 align-self-center">
                  <span className="uName">+{item.recipient.length - 4}</span>
                </span>
                <span className="dropupWrap--caret" />
              </a>
              <div className="dropdown-menu dropdown-menu-right">{list}</div>
            </div>
          );
        }
        return null;
      });
    }
    return sharedWith;
  }

  render() {
    const { showDocPop, showAlert, fileDetails, dropdownOpen, toggle } =
      this.state;
    const { data, liscioVault } = this.props;
    const pagination = data.pagination ? data.pagination : 0;
    const selectall = data.selectall ? data.selectall : false;
    const selectedlistLength = data.selectedrecords.length
      ? data.selectedrecords.length
      : 0;
    const setArray = (array, tag, index) => {
      const tagList = array.map((item) => (
        <span key={array.indexOf(item)} className="tag tag--fill">
          {Utilities.tagLabel(item)}
        </span>
      ));
      return (
        <div
          key={index + 1}
          className="drophover drophover--rightTop d-inline-block"
        >
          <span className="tag tag--fillWhite my-1 drophover-toggle">
            +{index}
          </span>
          <div className="drophover-menu drophover-menu--custom">{tagList}</div>
        </div>
      );
    };
    const list = data.uploadFilesList.map((item, key) => {
      const tagsArray = [];
      let constTag = 0;
      if (item.tags === undefined) {
        return null;
      }
      const removeDuplicateTags = Utilities.removeDuplicateTags(item.tags);
      const tags = removeDuplicateTags.map((tag, index) => {
        let tagIndex;
        if (removeDuplicateTags.indexOf(tag) <= 1) {
          tagIndex = (
            <span
              key={removeDuplicateTags.indexOf(tag)}
              className="tag tag--fill my-1"
            >
              {Utilities.tagLabel(tag)}
            </span>
          );
        } else if (removeDuplicateTags.indexOf(tag) >= 2) {
          if (removeDuplicateTags.length - 1 - index !== 0) {
            constTag += 1;
            tagsArray.push(removeDuplicateTags[index]);
            return null;
          }
          constTag += 1;
          tagsArray.push(removeDuplicateTags[index]);
          tagIndex = setArray(tagsArray, tag, constTag);
        }
        return tagIndex;
      });
      const date = item.date_created
        ? moment.tz(item.date_created, timeZone).format('MM/DD/YYYY')
        : moment(item.created_at).format('MM/DD/YYYY');
      if (item.doc_name === undefined && item.display_name) {
        item.doc_name = item.display_name;
      } else if (item.doc_name === undefined && item.aws_url_file_name) {
        item.doc_name = item.aws_url_file_name;
      }
      if (
        item.uploaded_by === undefined &&
        item.emp_first_name &&
        item.emp_first_name !== null
      ) {
        item.uploaded_by = `${item.emp_first_name} ${item.emp_last_name}`;
      } else if (
        item.uploaded_by === undefined &&
        item.cont_first_name &&
        item.cont_first_name !== null
      ) {
        item.uploaded_by = `${item.cont_first_name} ${item.cont_last_name}`;
      }
      let m;
      if (item.doc_name && item.doc_name.includes('.')) {
        m = item.doc_name.substring(0, item.doc_name.lastIndexOf('.'));
        // m = item.doc_name.split('.')[0];
      } else {
        m = item.doc_name;
      }
      const logo = Utilities.fileExtention(item);
      return (
        <div className="row" key={data.uploadFilesList.indexOf(item)}>
          <div className="col-auto tdCol pr-0 flex-grow-0">
            <div className="checkbox checkbox-primary check-container">
              <Label htmlFor={item.id}>
                <Input
                  key={item.id}
                  type="checkbox"
                  id={item.id}
                  checked={item.checked ? item.checked : false}
                  value={item.id}
                  className={`chkbx ${item.id}`}
                  name="msg1"
                  onChange={() =>
                    this.props.component.handleCheckbox(
                      item,
                      data.uploadFilesList.indexOf(item),
                    )
                  }
                />
                <i className="checkmark" />
              </Label>
            </div>
          </div>
          <div className="col-3 tdCol">
            <div className="tdata d-flex">
              <span className="fileTtype align-self-center">
                <i className={logo} aria-hidden="true" />
              </span>
              <div
                className={`tdata--detail tdata--icon ${
                  data.editFileNameId === item.id ? ' d-none' : ''
                }`}
              >
                <h5
                  onClick={
                    data.errors.docName ? null : () => this.onButtonClose(item)
                  }
                  className="fontSize fileName mb-0"
                >
                  {item.doc_name}
                  {item.is_private && (
                    <i className="icon-Lock text-dark ml-1" />
                  )}
                </h5>
                {liscioVault ? (
                  <a
                    onClick={() =>
                      this.props.component.handleCheckbox(
                        item,
                        data.uploadFilesList.indexOf(item),
                      )
                    }
                  >
                    <span className="attachBy text-dark">
                      by {item.uploaded_by}
                    </span>
                  </a>
                ) : (
                  <a>
                    <span className="attachBy text-dark">
                      by {item.uploaded_by}
                    </span>
                  </a>
                )}
                {!liscioVault && (
                  <a
                    className="editTd"
                    onClick={
                      data.errors.docName
                        ? null
                        : (e) => {
                            e.stopPropagation();
                            this.editFileName(e, item);
                          }
                    }
                  >
                    <i className="icon-edit" />
                  </a>
                )}
              </div>
              <div
                className={
                  data.editFileNameId === item.id
                    ? data.errors.docName
                      ? 'tdata--detail tdata--edit d-block form-group form-error mb-0'
                      : 'tdata--detail tdata--edit d-block form-group'
                    : 'tdata--detail tdata--edit d-none'
                }
              >
                <input
                  type="text"
                  className="form-control"
                  value={m}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e) => {
                    this.props.component.handleChangeFileName(e, item);
                  }}
                  onKeyPress={(e) => {
                    this.props.component.handleKeyPress(e, item);
                  }}
                  onBlur={(e) => {
                    this.props.component.handleKeyPress(e, item, 'blurevent');
                  }}
                />
                <span className="error_label">{data.errors.docName}</span>
              </div>
            </div>
          </div>
          <div
            className="col-3 tdCol"
            onClick={
              data.errors.docName ? null : () => this.onButtonClose(item)
            }
          >
            {tags.length > 0 ? tags : '-'}
          </div>
          {/* <div className="col-3 tdCol">
                      {this.renderRecipients(item, data.uploadFilesList.indexOf(item))}
                    </div> */}
          <div
            className="col-2 tdCol"
            onClick={
              data.errors.docName ? null : () => this.onButtonClose(item)
            }
          >
            <span>{item.upload_file_month ? item.upload_file_month : '-'}</span>
          </div>
          <div
            className="col-2 tdCol"
            onClick={
              data.errors.docName ? null : () => this.onButtonClose(item)
            }
          >
            <span>{item.upload_file_year ? item.upload_file_year : '-'}</span>
          </div>
          <div
            className="col tdCol text-right"
            onClick={
              data.errors.docName ? null : () => this.onButtonClose(item)
            }
          >
            {' '}
            <span>{date}</span>
          </div>
        </div>
      );
    });
    return (
      <>
        <div>
          <div id="loading" style={data.loading ? blockStyle : noneStyle} />
          <div className="tableWrap">
            <div className="tableHead">
              <div className="tableFilter d-flex justify-content-end">
                <span className="align-self-center mr-2" />
                <div className="select-custom-class" />
              </div>
            </div>
            <div className="tRow tRow--head">
              <div className="row">
                <div className="col-auto thCol pr-0 flex-grow-0">
                  <div className="checkbox checkbox-primary check-container">
                    <Label htmlFor="selectallcheckbox">
                      <Input
                        type="checkbox"
                        name="selectallcheckbox"
                        id="selectallcheckbox"
                        key={pagination}
                        checked={selectall}
                        onChange={
                          data.uploadFilesList.length
                            ? this.props.component.handleSelectAll
                            : null
                        }
                      />
                      <i className="checkmark" />
                    </Label>
                  </div>
                </div>
                {filesHeaders.map((each) => (
                  <div
                    key={each.value}
                    className={this.returnClassName(each.value, each.className)}
                    onClick={this.returnClassName(each.value, 'click')}
                  >
                    <span className="posRel">
                      {each.label}
                      <i className={this.returnClassName(each.value, 'icon')} />
                    </span>
                  </div>
                ))}
              </div>
            </div>
            <div className="tRow tRow--body">
              {data.uploadFilesList.length ? (
                list
              ) : (
                <div className="row emptyTr">
                  <div className="emptyBox">
                    <div className="onlyText">
                      <p>No Records Found</p>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="d-flex mb-3">
              {data.uploadFilesList.length && !liscioVault ? (
                <div>
                  <ButtonGroup className="btn-group-transparent mr-3">
                    <Button
                      className="btn-sm btn-transparent btn--onlyicon"
                      onClick={() =>
                        this.props.component.selectedFilesAction('delete')
                      }
                      data-tip
                      data-for="delete_icon"
                      data-testid="files__delete_icon"
                    >
                      <i className="icon-delete-small" />
                      <ReactTooltip id="delete_icon" effect="solid">
                        <span className="contactcard-team-member">Delete</span>
                      </ReactTooltip>
                    </Button>
                    {/* <Button className="btn-sm btn-transparent btn--onlyicon" onClick={() => this.props.component.selectedFilesAction('addmultitag')} data-tip data-for="tag_icon"><i className="icon-tag" />
                      <ReactTooltip id="tag_icon" effect="solid">
                        <span className="contactcard-team-member">Tag</span>
                      </ReactTooltip>
                    </Button> */}
                    <Dropdown
                      isOpen={this.state.showTagsDropDown}
                      toggle={() =>
                        this.setState({
                          showTagsDropDown: !this.state.showTagsDropDown,
                        })
                      }
                      className="border-left"
                      direction="up"
                    >
                      <DropdownToggle
                        className="btn-sm btn-transparent btn--onlyicon"
                        data-tip
                        data-for="tag_icon"
                        data-testid="files__tag_icon"
                      >
                        <i className="icon-tag" />
                        <ReactTooltip id="tag_icon" effect="solid">
                          <span className="contactcard-team-member">Tag</span>
                        </ReactTooltip>
                      </DropdownToggle>
                      <DropdownMenu className="mb-1">
                        <DropdownItem header className="pl-2 pb-0">
                          <h5>Tags</h5>
                        </DropdownItem>
                        <DropdownItem
                          onClick={() =>
                            this.props.component.selectedFilesAction(
                              'addmultitag',
                            )
                          }
                          data-testid="files__add_remove_tag"
                        >
                          Add & Remove Tags
                        </DropdownItem>
                        <DropdownItem
                          onClick={() =>
                            this.props.component.selectedFilesAction(
                              'replace_tags',
                            )
                          }
                          data-testid="files__find_replace_tag"
                        >
                          Find & Replace Tags
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                    <Button
                      className="btn-sm btn-transparent btn--onlyicon"
                      onClick={() =>
                        this.props.component.selectedFilesAction('download')
                      }
                      data-tip
                      data-for="download_icon"
                      data-testid="files__downlaod_icon"
                    >
                      <i className="icon-download2" />
                      <ReactTooltip id="download_icon" effect="solid">
                        <span className="contactcard-team-member">
                          Download
                        </span>
                      </ReactTooltip>
                    </Button>
                    <Button
                      className="btn-sm btn-transparent btn--onlyicon"
                      onClick={() =>
                        this.props.component.selectedFilesAction('status')
                      }
                      data-tip
                      data-for="archive_unarchive_icon"
                      data-testid="files__unarchive_icon"
                    >
                      <i
                        className={
                          data.filterStatus === 'active'
                            ? 'icon-archive-line'
                            : 'icon-unarchive'
                        }
                      />
                      <ReactTooltip id="archive_unarchive_icon" effect="solid">
                        <span className="contactcard-team-member">
                          {data.filterStatus === 'active'
                            ? 'Archive'
                            : 'Unarchive'}
                        </span>
                      </ReactTooltip>
                    </Button>
                    <Button
                      className="btn-sm btn-transparent btn--onlyicon"
                      onClick={() =>
                        this.props.component.selectedFilesAction('share')
                      }
                      data-tip
                      data-for="share_icon"
                      data-testid="files__share_icon"
                    >
                      <i className="icon-Share" />
                      <ReactTooltip id="share_icon" effect="solid">
                        <span className="contactcard-team-member">
                          Share File
                        </span>
                      </ReactTooltip>
                    </Button>
                    <Button
                      className="btn-sm btn-transparent btn--onlyicon"
                      onClick={() =>
                        this.props.component.selectedFilesAction('export')
                      }
                      data-tip
                      data-for="export_icon"
                      data-testid="files__export_icon"
                    >
                      <i className="icon-File-Upload" />
                      <ReactTooltip id="export_icon" effect="solid">
                        <span className="contactcard-team-member">Export</span>
                      </ReactTooltip>
                    </Button>
                  </ButtonGroup>
                  <span className="align-self-center text-dark">
                    File Selected ({selectedlistLength})
                  </span>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        {liscioVault && this.state.selectedItem ? (
          <Modal
            isOpen={showDocPop}
            className="customModal customModal--w990 customModal--nopadding"
          >
            <ModalHeader className="ModalHeader">
              <button
                type="button"
                onClick={() => this.setState({ showDocPop: false })}
                className="close"
                aria-label="Close"
              >
                <i className="icon-close2" aria-hidden="true" />
              </button>
            </ModalHeader>
            <ModalBody className="modalContent">
              <div className="modalContent--inner">
                <div
                  className={
                    isIE ? 'left-inner-content' : 'sample-letter-preview'
                  }
                >
                  <div className="letter-logo" />
                  {isIE ? (
                    <PdfViewer
                      file={this.state.selectedItem.aws_url_origional}
                    />
                  ) : (
                    <iframe
                      width="100%"
                      height="600"
                      src={this.state.selectedItem.aws_url_origional}
                      title="selected attachment"
                    />
                  )}
                </div>
              </div>
            </ModalBody>
          </Modal>
        ) : (
          <Modal isOpen={showDocPop} className="Md-full-width modal-FullH">
            <DocumentShow
              data={this.props.data}
              years={this.props.data.years}
              months={this.props.data.months}
              tags={this.props.data.uploadTags}
              onButtonClose={this.onButtonClose}
              selectedItem={this.state.selectedItem}
              listOfFilesState={this.listOfFilesState}
              editFileName={this.editFileName}
              component={this.props.component}
              navigate={this.props.navigate}
            />
          </Modal>
        )}
        <ConfirmationModal
          isOpen={showAlert && showAlert.length}
          noBtnClick={() => this.setState({ showAlert: '' })}
          yesBtnClick={() => this.setState({ showAlert: '' })}
          messageText={showAlert}
          yesBtnText="OK"
        />
        {this.state.showMultipleSource && (
          <MultipleSourceModal closeModal={this.closeModal} />
        )}
        {this.state.showManualSource && (
          <ManualSourceModal
            closeModal={this.closeModal}
            fileDetails={fileDetails}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  previewFileDetail: state.uploadFilesReducer.previewFileDetail,
  previewFileModal: state.uploadFilesReducer.previewFileModal,
});

const mapDispatchToProps = (dispatch) => ({
  files: bindActionCreators(UploadFilesActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(List);
