import React, { Component } from 'react';
import Axios from 'axios';
import ReactTooltip from 'react-tooltip';
import {
  GET_TEAM_MEMBERS_OF_ACCCOUNT,
  GET_ALL_EMPLOYEES,
  ADD_OR_DELETE_TEAM_MEMBERS,
} from 'constants/constants';
import './ClientServiceTeam.scss';
import {
  generateFullName,
  generateInitials,
  sortList,
  isUserEmployee,
  FORMATPHONENUMBER,
} from 'utilities/utils';

import styled from '@emotion/styled';

const StyledDiv = styled.div`
  /* background-color: #919eab; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .Tooltip__Name {
    color: white;
  }
`;

const filterEmployees = (allEmployees, existingEmployees) => {
  const currentEmployeeIds = [];
  existingEmployees.map((each) => currentEmployeeIds.push(each.employee_id));
  const output = allEmployees.filter(
    (each) => currentEmployeeIds.indexOf(each.id) < 0,
  );
  return output;
};

class ClientServiceTeam extends Component {
  constructor(props) {
    super(props);

    this.state = {
      allemployees: [],
      teamemployees: [],
      isEmployee: isUserEmployee(),
      onHoverEmployeeData: {},
    };
  }

  componentDidMount() {
    if (this.props.id) {
      this.getTeamMembers();
    }
  }

  getTeamMembers = () => {
    Axios.get(`${GET_TEAM_MEMBERS_OF_ACCCOUNT}/${this.props.id}`).then(
      (res) => {
        if (res.status === 200 && res.data.message === 'success') {
          this.setState({ teamemployees: res.data.data });
        } else {
          this.setState({ teamemployees: [] });
        }
      },
    );
  };

  showEmployees = () => {
    if (!this.state.allemployees.length) {
      Axios.get(GET_ALL_EMPLOYEES).then((res) => {
        if (res.status === 200 && res.data.status === 200) {
          const { teamemployees } = this.state;
          this.setState({
            allemployees: filterEmployees(res.data.data, teamemployees),
          });
        }
      });
    }
  };

  addMember = (input) => {
    const team = {
      account_id: this.props.id,
      employee_id: input.id,
    };
    Axios.post(ADD_OR_DELETE_TEAM_MEMBERS, { team }).then((res) => {
      if (res.status === 200 && res.data.status === 200) {
        const { allemployees, teamemployees } = this.state;
        this.setState({
          teamemployees: [...teamemployees, res.data.data[0]],
          allemployees: filterEmployees(allemployees, [
            ...teamemployees,
            res.data.data[0],
          ]),
        });
      }
    });
  };

  deleteTeamMember = (input) => {
    Axios.delete(`${ADD_OR_DELETE_TEAM_MEMBERS}/${input.id}`).then(() => {
      let { teamemployees } = this.state;
      const { allemployees } = this.state;
      teamemployees = teamemployees.filter((each) => input.id !== each.id);
      this.setState({
        teamemployees,
        allemployees: allemployees.length
          ? sortList(
              [...allemployees, { ...input, id: input.employee_id }],
              'first_name',
              'asc',
            )
          : [],
      });
    });
  };

  onMouseOver = (data) => {
    this.setState({ onHoverEmployeeData: data });
  };

  renderTooltip = () => {
    const { onHoverEmployeeData } = this.state;

    return (
      <ReactTooltip
        id="clientserviceteam"
        className="clientserviceteam"
        aria-haspopup="true"
        place="bottom"
        data-background-color="black"
        effect="solid"
      >
        <StyledDiv className="ToolTip_Container">
          <div className="Tooltip__Name">
            {onHoverEmployeeData.first_name && (
              <span>{`${onHoverEmployeeData.first_name} ${onHoverEmployeeData.last_name}`}</span>
            )}
          </div>{' '}
        </StyledDiv>
      </ReactTooltip>
    );
  };

  render() {
    const { teamemployees, allemployees, isEmployee } = this.state;
    const { status } = this.props;
    return (
      <div className="d-flex align-items-center">
        {(teamemployees && teamemployees.length) || isEmployee ? (
          <h4 className="subHeadline text-dark text-uppercase font-weight-bold mb-0 mr-3">
            Client Service team
          </h4>
        ) : null}
        {teamemployees
          ? teamemployees.map((each, index) => {
              const len = teamemployees.length - 1;
              return (
                <div key={each.id}>
                  <div
                    className={`dropdown dropupWrap dropupWrap--${
                      len === index ? 'right ' : 'center '
                    }dropupWrap--small p-0 ml-1`}
                  >
                    <button
                      type="button"
                      className="btn btn-sm btn-link dropdown-toggle p-0"
                      data-toggle="dropdown"
                      aria-expanded="true"
                    >
                      <span
                        onFocus={this.onFocus}
                        onMouseOver={() => this.onMouseOver(each)}
                        data-for="clientserviceteam"
                        data-tip
                        className="avatar avatar--w40 align-self-center"
                      >
                        {each.avatar !== null && each.avatar.length ? (
                          <img
                            src={each.avatar}
                            alt={each.title}
                            className="rounded-circle mr-0"
                          />
                        ) : (
                          <span className="uName mr-0">
                            {generateInitials(each)}
                          </span>
                        )}
                      </span>
                      <span className="dropupWrap--caret" />
                    </button>
                    <div className="dropdown-menu">
                      <div className="dropdown-item">
                        <div className="teamInfo">
                          <h4 className="fontSize text-body mb-0">
                            {generateFullName(each)}
                          </h4>
                          <h5 className="label-submedium text-body mb-2">
                            {each.title}
                          </h5>
                          <h5 className="label-submedium text-body mb-1">
                            {each.email}
                          </h5>
                          <h5 className="label-submedium text-body">
                            {each.phone !== null
                              ? FORMATPHONENUMBER(each.phone)
                              : null}
                          </h5>
                          {isEmployee && status.toLowerCase() !== 'inactive' ? (
                            <button
                              type="button"
                              className="btn btn-outline-light btn-sm w-100 mt-2 p-1"
                              onClick={() => this.deleteTeamMember(each)}
                            >
                              Remove
                            </button>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    {this.renderTooltip()}
                  </div>
                </div>
              );
            })
          : null}
        {/* add button code */}
        {teamemployees &&
        teamemployees.length < 5 &&
        isEmployee &&
        status.toLowerCase() !== 'inactive' ? (
          <div className="dropdown dropdown--small ml-1">
            <button
              type="button"
              className="btn btn-link iconCircle iconCircle--w40 dropdown-toggle"
              data-toggle="dropdown"
              onClick={this.showEmployees}
            >
              <i className="icon-plus" />
              <span className="dropupWrap--caret" />
            </button>
            <div className="dropdown-menu dropdown-menu-right">
              {/* <div className="input-group">
                <input type="text" className="form-control border-0" name="employee" /><span className="input-group-addon border-left-0"><i className="icon-zoom2" /></span>
              </div> */}
              {allemployees.length ? (
                allemployees.map((each) => (
                  <button
                    type="button"
                    onClick={() => this.addMember(each)}
                    className="dropdown-item"
                    key={each.id}
                  >
                    <h5 className="fontSize">{generateFullName(each)}</h5>
                    <h4 className="subHeadline text-light letterSpace-1 text-uppercase font-weight-bold mb-0 ">
                      {each.title}
                    </h4>
                  </button>
                ))
              ) : (
                <p className="dropdown-item mb-0 text-body">
                  Getting Employees
                </p>
              )}
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default ClientServiceTeam;
