import { getStorageDataSnapshot } from 'hooks/useStorageData';

const FALLBACK = '';

function getCompanyUrl() {
  const storageData = getStorageDataSnapshot() as Home['data'];
  return storageData?.company_url || FALLBACK;
}

export function generateTaskReminder(id: number) {
  return `Link to Task: ${getCompanyUrl()}/task/detail/${id}`;
}

export function generateRequestReminder(activityUUID: string) {
  return `Link to Request: ${getCompanyUrl()}/requests/${activityUUID}`;
}
