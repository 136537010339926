import {
  Autocomplete,
  Box,
  Button,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { SyntheticEvent, useEffect, useState } from 'react';
import {
  FilterKey,
  useRequestsState,
} from 'components/Requests/state/request-state';
import { UseQueryResult } from '@tanstack/react-query';
import { WorkflowRequestsQueryQuery } from 'gql/graphql';
import { useRequestFiltersEmployeesAndContacts } from 'hooks/employee-hooks';
import {
  createAssignedToFiltersFromOptions,
  createAssignedToOptions,
  createAssignedToOptionsFromFilters,
  createOwnerOptionForCurrentUser,
  createOwnerOptions,
  createOwnersFiltersFromOptions,
  createOwnersOptionsFromFilters,
  sortByType,
} from './TableFilters.utils';

import type { Option, OptionWithType } from './TableFilters.utils';
import { FormControlStyled, GroupHeader } from './TableFilters.styles';

export type TableFiltersProps = {
  query: UseQueryResult<WorkflowRequestsQueryQuery>;
  requestKey: FilterKey;
};
export const TableFilters = ({ query, requestKey }: TableFiltersProps) => {
  const { filters, setFilters, requestsCounts, setRequestsCount } =
    useRequestsState();

  const [assignedToValues, setAssignedToValues] = useState<OptionWithType[]>(
    [],
  );
  const [assignedToList, setAssignedToList] = useState<OptionWithType[]>([]);

  const [ownerValues, setOwnerValues] = useState<Option[]>([]);
  const [ownersList, setOwnersList] = useState<Option[]>([]);

  // note - getEmployeesOrContacts does not inclued the current logged in employee user
  // we get that from the /home data with createOptionForCurrentUser
  const {
    data: employeesAndContactsData,
    isLoading: isLoadingEmployeesAndContacts,
    fetchStatus: fetchStatusEmployeesAndContacts,
    isError: isErrorEmployeesAndContacts,
  } = useRequestFiltersEmployeesAndContacts();

  useEffect(() => {
    const currentUserOption = createOwnerOptionForCurrentUser();
    if (employeesAndContactsData) {
      // Populate the assignedTo list and any current filters
      const assignedToOptions = createAssignedToOptions(
        employeesAndContactsData,
      );
      const currentAssignedToFilters = createAssignedToOptionsFromFilters(
        filters[requestKey].assignedTo,
        employeesAndContactsData,
      );
      setAssignedToValues(currentAssignedToFilters);
      setAssignedToList(assignedToOptions);

      // Populate the owners list and any current filters
      const ownerOptions = createOwnerOptions(employeesAndContactsData);
      const currentOwnersFilters = createOwnersOptionsFromFilters(
        filters[requestKey].owners,
        [currentUserOption, ...ownerOptions],
      );
      setOwnerValues(currentOwnersFilters);
      // make the current user the first option in the owners list
      setOwnersList([currentUserOption, ...ownerOptions]);
    }
  }, [employeesAndContactsData, filters, requestKey]);

  const handleAssignedToSelectChange = (
    _event: SyntheticEvent<Element, Event>,
    newValue: any,
  ) => {
    setAssignedToValues(newValue);
  };

  const handleOwnersSelectChange = (
    _event: SyntheticEvent<Element, Event>,
    newValue: any,
  ) => {
    setOwnerValues(newValue);
  };

  const handleClearFilters = () => {
    setAssignedToValues([]);
    setOwnerValues([]);
    setFilters(requestKey, { assignedTo: [], owners: [] });
    query.refetch();
  };

  const handleFilterButtonClick = () => {
    setFilters(requestKey, {
      assignedTo: createAssignedToFiltersFromOptions(assignedToValues),
      owners: createOwnersFiltersFromOptions(ownerValues),
    });
    query.refetch();
  };

  // update the request count when the query.refetch is done re-fetching
  useEffect(() => {
    if (query.isLoading && query.isFetching) return;
    setRequestsCount({
      ...requestsCounts,
      [requestKey]: query.data?.workflowRequests?.totalCount || 0,
    });
    // we only want to watch the query refetch or we get unecessary updates
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const isLoading =
    isLoadingEmployeesAndContacts && fetchStatusEmployeesAndContacts !== 'idle';

  const disableFilterButton =
    isLoading ||
    query.isLoading ||
    query.isFetching ||
    isErrorEmployeesAndContacts;

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      gap={2}
      height="70px"
    >
      <Stack direction="row" alignItems="center" gap={2} height="70px">
        <Box height="36px" width="76px" p={1} pl={3}>
          <Typography variant="subtitle1">Filter by</Typography>
        </Box>
        {isLoading && (
          <>
            <Skeleton width={300} height={60} />
            <Skeleton width={300} height={60} />
          </>
        )}
        {!isLoading && (
          <FormControlStyled>
            <Autocomplete
              multiple
              limitTags={1}
              id="assigned-to-autocomplete"
              data-testid="assigned-to-autocomplete"
              size="medium"
              sx={{ width: '300px' }}
              options={assignedToList.sort(sortByType)}
              getOptionLabel={(option) => option.label}
              getOptionKey={(option) => option.value}
              value={assignedToValues}
              defaultValue={assignedToValues}
              groupBy={(option) => option.type}
              onChange={handleAssignedToSelectChange}
              disabled={isErrorEmployeesAndContacts}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  key={params.id}
                  variant="standard"
                  label={
                    isErrorEmployeesAndContacts
                      ? 'error loading filters'
                      : 'Assigned To'
                  }
                  placeholder="Assigned To"
                />
              )}
              renderGroup={(params) => (
                <Box component="span" sx={{ width: '100%' }} key={params.key}>
                  <GroupHeader>
                    {params.group === 'emp' ? 'employees' : 'contacts'}
                  </GroupHeader>
                  <Box component="span" sx={{ width: '100%' }}>
                    {params.children}
                  </Box>
                </Box>
              )}
            />
          </FormControlStyled>
        )}
        {!isLoading && (
          <FormControlStyled>
            <Autocomplete
              multiple
              limitTags={1}
              id="owners-autocomplete"
              size="medium"
              sx={{ width: '300px' }}
              options={ownersList}
              getOptionLabel={(option) => option.label}
              getOptionKey={(option) => option.value}
              value={ownerValues}
              defaultValue={ownerValues}
              onChange={handleOwnersSelectChange}
              disabled={isErrorEmployeesAndContacts}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  key={params.id}
                  variant="standard"
                  label={
                    isErrorEmployeesAndContacts
                      ? 'error loading filters'
                      : 'Owners'
                  }
                  placeholder="Owners"
                />
              )}
            />
          </FormControlStyled>
        )}
      </Stack>
      <Box mr={4}>
        <Button onClick={handleClearFilters} disabled={disableFilterButton}>
          clear filters
        </Button>
        <Button
          variant="contained"
          disabled={disableFilterButton}
          onClick={handleFilterButtonClick}
          role="button"
        >
          Filter
        </Button>
      </Box>
    </Stack>
  );
};
