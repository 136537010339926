/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import Pagination from 'common/Pagination';
import { useParams, useNavigate } from 'react-router-dom-latest';
import { trackMixpanelEvent } from 'utilities/mixpanelfn';
import { MIXPANEL_EMAIL_READ_EVENT } from 'constants/constants';
import styled from '@emotion/styled';
import NoRecords from 'common/NoRecords';
import EmailThreadDetails from './EmailThreadDetails';
import EmailListHead from './EmailListHead';
import EmailSettingsHeader from './EmailSettingsHeader';

const EmailWrap = styled.div`
  border-top: 1px solid #dee2e6;
  height: calc(100vh - 210px);
  .EmailBlock {
    display: flex;
    height: 100%;
    &__ListCol {
      flex: ${({ emailListMode }) =>
        emailListMode === 'full view' ? '0 0 100%' : '0 0 33.33333333%'};
      max-width: ${({ emailListMode }) =>
        emailListMode === 'full view' ? '100%' : '33.33333333%'};
      border-right: ${({ emailListMode }) =>
        emailListMode === 'full view' ? 'none' : '1px solid #dee2e6'};
    }
    &__EmailPrev {
      flex: ${({ emailDetailMode }) =>
        emailDetailMode === 'full view' ? '0 0 100%' : '0 0 66.66666667%'};
      max-width: ${({ emailDetailMode }) =>
        emailDetailMode === 'full view' ? '100%' : '66.66666667%'};
    }
  }
  .EmailList__LeftColumn {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .Email_Left_Head {
    border-bottom: 1px solid #dee2e6;
  }
  .Em_Left_Check {
    display: flex;
    align-items: center;
  }
  .Em_Checkbox {
    margin-right: 12px;
    span {
      color: #919eab;
    }
  }
  .Em_Left_Top {
    padding: 0.5rem !important;
    display: flex;
    justify-content: space-between !important;
  }
  .Em_Head_right {
    color: #919eab;
  }
  .Em_Pagination {
    padding-right: 0.5rem;
    margin-top: 20px;
  }
  .Em_Paginaetion_Left {
    padding: 64px;
    margin-top: 48px;
    text-align: center;
    svg {
      color: #919eab;
      width: 25px;
    }
    h6 {
      color: #919eab;
    }
  }
`;

const EmailsList = ({
  hasSelectedAll,
  toggleSelectAll,
  toggleSelect,
  handleBultActions,
  filter,
  connectedApp,
  emails,
  accountId,
  contactId,
  taskId,
  refreshPage,
  from,
  unreadEmail,
  activeTab,
  idIndexx,
  nextIdd,
  data,
  handlePageChange,
  emailState,
  handleShowFilter,
  handlePrevNextButton,
  viewMode,
  selectedIndex,
  handleIdNextIndex,
  searchFieldText,
  setLoading,
}) => {
  const [idIndex, setIdIndex] = useState(null);
  const [nextId, setNextId] = useState(null);
  const [emailListMode, setEmailListMode] = useState(
    viewMode === 'list' ? 'full view' : 'split',
  );

  const [emailDetailMode, setEmailDetailMode] = useState(
    viewMode === 'detailed' ? 'full view' : 'split',
  );

  const navigate = useNavigate();
  const params = useParams();
  let { id } = params;

  if (accountId || contactId) {
    id = params.email_id;
  } else if (taskId) {
    id = parseInt(window.location.href.split('=')[1], 10);
  }

  const composeEmail = (
    actionType,
    email_details,
    index = null,
    forslideview = '',
  ) => {
    if (index && index >= 0 && emails[index]) {
      emails[index].is_read = true;
    }
    let url = '/emails/';
    if (accountId) {
      url = `/accountdetails/${accountId}/emails/`;
    } else if (contactId) {
      url = `/contactdetails/${contactId}/emails/`;
    } else if (taskId) {
      url = `/task/detail/${taskId}#emails?id=`;
    }
    trackMixpanelEvent(MIXPANEL_EMAIL_READ_EVENT);
    if (!forslideview) {
      handlePrevNextButton(index);
      if (emailListMode === 'full view') {
        handleEmailMode('detailed', 'list');
      }
    }

    navigate(url + email_details.id, {
      state: {
        connectedApp,
        actionType,
      },
    });
  };

  useEffect(() => {
    if (selectedIndex || selectedIndex === 0) {
      composeEmail('', emails[selectedIndex], selectedIndex);
    }
  }, []);

  useEffect(() => {
    setIdIndex(idIndexx);
    setNextId(nextIdd);
  }, [idIndexx, nextIdd]);

  useEffect(() => {
    setIdIndex(null);
    setNextId(null);
  }, [activeTab]);

  useEffect(() => {
    if (
      idIndex !== null &&
      nextId !== null &&
      emails?.length > 0 &&
      emails[idIndex] &&
      emails[idIndex].id === nextId
    ) {
      setNextId(null);
      composeEmail('', emails[idIndex]);
    }
  }, [emails]);

  const nextThread = (markdoneid = '') => {
    if (id && markdoneid && id.toString() === markdoneid.toString()) {
      const indx = emails.findIndex(
        (each) => each.id.toString() === id.toString(),
      );
      setIdIndex(indx);
      setNextId(emails[indx + 1] ? emails[indx + 1].id : null);
      handleIdNextIndex(indx, emails[indx + 1] ? emails[indx + 1].id : null);
      if (indx >= 0) {
        refreshPage();
      }
    } else {
      refreshPage();
    }
  };

  useEffect(() => {
    if (viewMode === 'list') {
      setEmailListMode('full view');
      setEmailDetailMode('split');
    }
    if (viewMode === 'detailed') {
      if (id) {
        setEmailListMode('split');
        setEmailDetailMode('full view');
      } else {
        setEmailListMode('full view');
        setEmailDetailMode('split');
      }
    }
    if (viewMode === 'split') {
      setEmailListMode('split');
      setEmailDetailMode('split');
    }
  }, [viewMode, id]);

  const newPage = () => {
    refreshPage();
    let url = '/emails/';
    if (accountId) {
      url = `/accountdetails/${accountId}/emails/`;
    } else if (contactId) {
      url = `/contactdetails/${contactId}/emails/`;
    } else if (taskId) {
      url = `/task/detail/${taskId}#emails?id=`;
    }
    navigate({
      pathname: url,
      connectedApp,
    });
  };

  const handleEmailMode = (val, lastViewMode = '') => {
    handleShowFilter(val, lastViewMode);
  };

  return (
    <EmailWrap emailListMode={emailListMode} emailDetailMode={emailDetailMode}>
      <div className="EmailBlock EmailBlock--Row">
        {emailDetailMode === 'split' ? (
          <div className="EmailBlock__ListCol" data-testid="listMode">
            {emails?.length ? (
              <div className="EmailList__LeftColumn">
                <div className="Email_Left_Head">
                  {from && from === 'linkedEmailAccConTask' ? (
                    <span>{data?.totalRecords} messages</span>
                  ) : (
                    <EmailSettingsHeader
                      hasSelectedAll={hasSelectedAll}
                      toggleSelectAll={toggleSelectAll}
                      data={data}
                      emailState={emailState}
                      filter={filter}
                      handleBultActions={handleBultActions}
                      emailListMode={emailListMode}
                      handleEmailMode={handleEmailMode}
                    />
                  )}
                </div>
                <EmailListHead
                  emails={emails}
                  id={id}
                  from={from}
                  composeEmail={composeEmail}
                  toggleSelect={toggleSelect}
                  filter={filter}
                  connectedApp={connectedApp}
                  refreshPage={refreshPage}
                  unreadEmail={unreadEmail}
                  nextThread={nextThread}
                  viewMode={viewMode}
                  emailDetailMode={emailDetailMode}
                  handleEmailDetailMode={handleEmailMode}
                  setLoading={setLoading}
                />
                <div className="Em_Pagination" id="pagination-block">
                  <Pagination
                    currentPage={data?.pagination}
                    totalPages={data?.totalPages}
                    totalRecords={data?.totalRecords}
                    handlePageChange={handlePageChange}
                    offset={data?.offset[0]}
                    off={data?.offset[1]}
                    fromPage="emailsList"
                  />
                </div>
              </div>
            ) : emailListMode === 'full view' &&
              emailDetailMode === 'split' &&
              searchFieldText &&
              !emails.length ? (
              <div className="EmailBlock__ListCol">
                <NoRecords description="No records match your search." />
              </div>
            ) : null}
          </div>
        ) : null}
        {emailListMode === 'split' ? (
          <div className="EmailBlock__EmailPrev">
            <EmailThreadDetails
              refreshPage={refreshPage}
              nextThread={nextThread}
              newPage={newPage}
              unreadEmail={unreadEmail}
              from={from}
              selectedEmailId={id}
              accountId={accountId}
              contactId={contactId}
              emailDetailMode={emailDetailMode}
              handleEmailDetailMode={handleEmailMode}
              handlePageChange={handlePageChange}
              noRecordsWithSearch={searchFieldText && !emails.length}
              setLoading={setLoading}
            />
          </div>
        ) : null}
      </div>
    </EmailWrap>
  );
};

export default EmailsList;
