import React from 'react';
import {
  KarbonManualSyncRefactor,
  KarbonPageLayout,
} from 'components/Integrations/integration-modules/karbon/components';

export const KarbonAccountsView = () => (
  <KarbonPageLayout
    pageTitle="Karbon Accounts"
    breadcrumbs={[{ label: 'Accounts', path: '/accounts' }]}
  >
    <KarbonManualSyncRefactor manualSyncTab="liscio_karbon_accounts" />
  </KarbonPageLayout>
);
