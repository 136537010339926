import { useFlags } from 'launchdarkly-react-client-sdk';
import { UserProfileChangePasswordV5 } from './UserProfileChangePasswordV5';
import {
  UserProfileChangePasswordV3,
  UserProfileChangePasswordV3Props,
} from './UserProfileChangePasswordV3';

export type UserProfileChangePasswordProps = UserProfileChangePasswordV3Props;
/**
 * This is the flagged component for User profile change password.
 * Once the v5_login / v5Login feature flag has been removed hoist
 * the UserProfileChangePasswordV5 component up to replace this flagged one.
 * It will need much less handlers and state props so please remove
 * them from the parent UserProfile component where possible.
 */
export const UserProfileChangePassword = (
  props: UserProfileChangePasswordProps,
) => {
  const { v5Login } = useFlags();

  if (v5Login) {
    return (
      <UserProfileChangePasswordV5
        onCancel={props.handleCancel}
        mfaEnabled={props.state.mfaEnabled}
      />
    );
  }

  return <UserProfileChangePasswordV3 {...props} />;
};
