import React from 'react';
import InputField from 'common/InputField';
import { TextAreaField, SelectField } from 'common/CustomFields';
import { labelNamebyValue, getFirmType } from 'utilities/utils';
import { EncryptedField } from 'common/EncryptedField/EncryptedField';
import { months } from './AccountUtilities';

const BussinessAccountDetails = (props) => {
  const { formdata, errors, preferences } = props.data;

  return (
    <div>
      <div className="section-title mt-3 mb-3">
        <h5>Account Details</h5>
      </div>
      <div className="row">
        <EncryptedField
          recordId={formdata?.id}
          label="Taxpayer ID"
          recordType="Account"
          fieldName="co_ein"
          formFieldName="encryptedCoEin"
          className="col-sm-12 col-lg-4 col-md-4 form-group form-icon"
          value={formdata?.encryptedCoEin}
          error={errors?.encryptedCoEin}
          onChange={props.handleEncryptedFieldChange}
          hasEncryptedValue={formdata?.encrypted_co_ein?.populated}
        />
        <SelectField
          name="co_industry"
          label="Industry"
          className="col-sm-12 col-lg-4 col-md-4 form-group"
          options={preferences.industry}
          handleChange={(input) => props.handleSelect('co_industry', input)}
          onBlur={props.onBlur}
          value={{ value: formdata.co_industry, label: formdata.co_industry }}
          error={errors.co_industry}
          clearable
        />
        <InputField
          name="co_prior_accountant"
          label="Prior Accountant"
          className="col-sm-12 col-lg-4 col-md-4 form-group"
          onChange={props.onChange}
          onBlur={props.onBlur}
          value={formdata.co_prior_accountant}
          error={errors.co_prior_accountant}
          clearable
        />
      </div>
      <div className="row">
        <InputField
          name="co_number_of_employees"
          label="# of employees"
          className="col-sm-12 col-lg-4 col-md-4 form-group"
          onChange={props.onChange}
          onBlur={props.onBlur}
          value={formdata.co_number_of_employees}
          error={errors.co_number_of_employees}
          type="text"
        />
        <SelectField
          name="co_fiscal_yearend"
          label="Fiscal Year End"
          className="col-sm-12 col-lg-4 col-md-4 form-group text-capitalize"
          options={months}
          handleChange={(input) =>
            props.handleSelect('co_fiscal_yearend', input)
          }
          onBlur={props.onBlur}
          value={{
            value: formdata.co_fiscal_yearend,
            label: labelNamebyValue(formdata.co_fiscal_yearend, months),
          }}
          error={errors.co_fiscal_yearend}
          clearable
        />
      </div>
      <div className="row">
        <TextAreaField
          name="co_biggest_challenges"
          label="What are your biggest challenges?"
          className="col-sm-12 col-lg-12 col-md-12 form-group"
          onChange={props.onChange}
          onBlur={props.onBlur}
          value={formdata.co_biggest_challenges}
          error={errors.co_biggest_challenges}
          type="textarea"
          rows="2"
        />
      </div>
      <div className="row">
        <TextAreaField
          name="co_biz_decision_tools_used"
          label="What do you use to make business decisions?"
          className="col-sm-12 col-lg-12 col-md-12 form-group"
          onChange={props.onChange}
          onBlur={props.onBlur}
          value={formdata.co_biz_decision_tools_used}
          error={errors.co_biz_decision_tools_used}
          type="textarea"
          rows="2"
        />
      </div>
      <div className="row">
        {/* <textarea className="form-control" id="exampleFormControlTextarea1" rows="2" /> */}
        <TextAreaField
          name="co_expectations"
          label={
            getFirmType() !== 'cpa'
              ? 'What would you like most from an legal firm?'
              : 'What would you like most from an accounting firm?'
          }
          className="col-sm-12 col-lg-12 col-md-12 form-group"
          onChange={props.onChange}
          onBlur={props.onBlur}
          value={formdata.co_expectations}
          error={errors.co_expectations}
          type="textarea"
          rows="2"
        />
      </div>
      {/* <BankAccountDetails
        data={{ formdata, errors }}
        onChange={props.onChange}
        onBlur={props.onBlur}
        errors={errors}
        fieldNames={coBankAccount}
        /> */}
      {getFirmType() !== 'cpa'
        ? null
        : [
            <div className="section-title mt-3 mb-3" key="tech-solutions1">
              <h5>Technology Solutions</h5>
            </div>,
            <div className="row" key="tech-solutions2">
              <InputField
                name="co_tech_accounting"
                label="Client Accounting"
                className="col-sm-12 col-lg-4 col-md-4 form-group"
                onChange={props.onChange}
                onBlur={props.onBlur}
                value={formdata.co_tech_accounting}
                error={errors.co_tech_accounting}
              />
              <InputField
                name="co_tech_expenses"
                label="Expense Reimbursement"
                className="col-sm-12 col-lg-4 col-md-4 form-group"
                onChange={props.onChange}
                onBlur={props.onBlur}
                value={formdata.co_tech_expenses}
                error={errors.co_tech_expenses}
              />
              <InputField
                name="co_tech_time_trackings"
                label="Time Tracking"
                className="col-sm-12 col-lg-4 col-md-4 form-group"
                onChange={props.onChange}
                onBlur={props.onBlur}
                value={formdata.co_tech_time_trackings}
                error={errors.co_tech_time_trackings}
              />
            </div>,
            <div className="row" key="tech-solutions3">
              <InputField
                name="co_tech_payroll"
                label="Payroll"
                className="col-sm-12 col-lg-4 col-md-4 form-group"
                onChange={props.onChange}
                onBlur={props.onBlur}
                value={formdata.co_tech_payroll}
                error={errors.co_tech_payroll}
              />
              <InputField
                name="co_tech_billpay"
                label="Bill pay"
                className="col-sm-12 col-lg-4 col-md-4 form-group"
                onChange={props.onChange}
                onBlur={props.onBlur}
                value={formdata.co_tech_billpay}
                error={errors.co_tech_billpay}
              />
            </div>,
          ]}
    </div>
  );
};

export default BussinessAccountDetails;
