import React from 'react';
import { useStorageData } from 'hooks/useStorageData';
import { Skeleton, Box } from '@mui/material';

const DefaultLoadingState = () => (
  <Box p={6}>
    <Skeleton width={300} height={40} />
  </Box>
);

export const withStorageData = (
  Element,
  LoadingState = <DefaultLoadingState />,
) => {
  const Wrapper = (props) => {
    const { storageData, hasStorageData } = useStorageData();

    if (!hasStorageData) {
      return LoadingState;
    }

    return <Element {...props} storageData={storageData} />;
  };

  return Wrapper;
};
