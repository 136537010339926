import React, { useState, useEffect } from 'react';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import {
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Typography,
} from '@mui/material';
import styled from '@emotion/styled';
import { Search as SearchIcon } from '@mui/icons-material';

import { palettePrimary } from 'theme/light';

import Checkbox from 'common/Checkbox';
import IconTextField from 'common/IconTextField';

const SButton = styled(Button)(() => ({
  height: 40,
  fontWeight: 600,
  fontSize: '0.87rem',
  textTransform: 'uppercase',
}));

const SPopover = styled(Popover)(() => ({ marginTop: '5px' }));

const SIconTextField = styled(IconTextField)(() => ({
  width: '100%',
  minWidth: 220,
  border: 0,
  padding: '7px 5px',
}));

const SList = styled(List)(() => ({
  width: '100%',
  maxWidth: 250,
  maxHeight: 200,
}));

const SListItemButton = styled(ListItemButton)(() => ({
  height: 40,
  '&:hover': { backgroundColor: 'transparent' },
}));

const SListItemText = styled(ListItemText)(() => ({
  fontSize: '0.94rem',
  fontWeight: 500,
  transition: 'all ease-in-out 200ms',
}));

function MultiSelectDropDown(props) {
  const {
    withSearch = true,
    disabled = false,
    title,
    options = [],
    value = [],
    onSelectionChange,
  } = props;

  const [internal, setInternal] = useState(value);
  const [anchorEl, setAnchorEl] = useState(null);
  const [hoverId, setHoverId] = useState(undefined);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    if (JSON.stringify(internal) !== JSON.stringify(value)) {
      setInternal(value);
    }
  }, [value]);

  const filteredOptions = options.filter((option) => {
    const lowCasedLabel = option.label.toLowerCase();
    const lowCasedSearchText = searchText.toLowerCase();

    return lowCasedLabel.includes(lowCasedSearchText);
  });

  const isItemChecked = (item) => {
    if (internal && internal.length > 0)
      return internal.some((v) => v.key === item.key);
    return false;
  };

  const handleButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleItemClick = (item, checked) => {
    let newValue = [...internal];

    if (checked) {
      newValue = [...newValue, item];
    } else {
      const index = newValue.findIndex((v) => v.label === item.label);
      newValue.splice(index, 1);
    }

    setInternal(newValue);

    if (onSelectionChange) {
      onSelectionChange(newValue);
    }
  };

  const open = Boolean(anchorEl);
  const popoverId = open ? 'dropdown-popover' : undefined;

  return (
    <div>
      <SButton
        aria-describedby={popoverId}
        variant={
          open || (internal && internal.length > 0) ? 'contained' : 'outlined'
        }
        disabled={disabled}
        onClick={handleButtonClick}
      >
        {title}
      </SButton>

      <SPopover
        id={popoverId}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transitionDuration={50}
        elevation={0}
        open={open}
        PaperProps={{ sx: { border: `2px solid ${palettePrimary.gray}` } }}
        onClose={handleClose}
      >
        <SList dense disablePadding>
          {withSearch && (
            <SIconTextField
              type="search"
              icon={<SearchIcon />}
              fontSize="0.95rem"
              value={searchText}
              isOpen={open}
              onChange={debounce((e) => {
                setSearchText(e.target.value);
              }, 300)}
            />
          )}
          {filteredOptions.length > 0 ? (
            filteredOptions.map((option, i) => {
              const labelId = `checkbox-list-label-${i}`;
              const itemChecked = isItemChecked(option);

              return (
                <ListItem
                  disablePadding
                  key={labelId}
                  onMouseEnter={() => setHoverId(i)}
                  onMouseLeave={() => setHoverId(undefined)}
                >
                  <SListItemButton
                    disableRipple
                    onClick={() => {
                      handleItemClick(option, !itemChecked);
                    }}
                  >
                    <ListItemIcon>
                      <Checkbox
                        disableRipple
                        edge="start"
                        checked={itemChecked}
                        tabIndex={-1}
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </ListItemIcon>
                    <SListItemText
                      id={labelId}
                      primary={option.label}
                      sx={{
                        color:
                          hoverId === i
                            ? palettePrimary.textColor
                            : palettePrimary.gray,
                      }}
                    />
                  </SListItemButton>
                </ListItem>
              );
            })
          ) : (
            <ListItem>
              <Typography variant="gray_body2">Not Found</Typography>
            </ListItem>
          )}
        </SList>
      </SPopover>
    </div>
  );
}

const OptionPropType = PropTypes.shape({
  key: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
});

MultiSelectDropDown.propTypes = {
  withSearch: PropTypes.bool,
  disabled: PropTypes.bool,
  title: PropTypes.string,
  options: PropTypes.arrayOf(OptionPropType),
  value: PropTypes.arrayOf(OptionPropType),
  onSelectionChange: PropTypes.func,
};

export default MultiSelectDropDown;
