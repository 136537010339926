/* eslint-disable global-require */
import React, { Component } from 'react';
import moment from 'moment';
import Interweave from 'interweave';
import { removeBreakElement } from 'common/NotesUtilities';
import { printPdf } from 'utilities/utils';

class TaskPdfLayout extends Component {
  componentDidMount() {
    const styleElement =
      '<style>body{font-family:"Source Sans Pro",sans-serif;-webkit-font-smoothing:antialiased;color:#212b36;line-height:1.3}#pdf-logo{height:32px}#pdf-logo img{height:32px}p{font-size:15px;margin:0;line-height:18px;color:#212b36}b,strong{font-weight:600}h5{font-size:20px;font-weight:500}.text-dark{color:#919eab!important}p b{color:#000}.div-top{margin-top:20px}.due-date{text-align:right;float:right}.left-content{float:left}.div-top b{margin-right:10px;display:inline-block}.Date{float:right;margin-top:18px}hr{border:none;height:1px;background:#d2d2d2;color:#d2d2d2;margin-top:10px}.highlight{color:#299ac4}.bottom{margin:20px 0 0}li::before{content:"•"}.no-padding{padding:0;border:0}.bottomBorder{width:100%;height:1px;border-bottom:1px solid #dfe3e8}.each_thread{border-bottom:1px solid #dfe3e8;padding:.9rem 0;opacity:.9}.each_thread:last-child{border:0}.print-comment-sections{padding:10px 0;break-inside:auto}.print-comment-sections .comment_content{color:#212b36;font-size:15px}.right-panel-top{border-bottom:solid 1px #e8ebf0;margin-bottom:10px}.visibility{visibility:hidden;width:1px;height:1px;position:absolute}.task-action-icons{margin-top:10px}</style>';
    const { data } = this.props;
    printPdf(`task_to_print${data.id}`, `task_iframe${data.id}`, styleElement);
    setTimeout(() => this.props.handlePrintPdf(), 1000);
  }

  render() {
    const { data } = this.props;
    let { account } = data;
    let contact = data.assigne;
    if (data.task_type_key && data.task_type_key === 'to_do') {
      account = data.todo_account_name;
      contact = data.todo_contact_name;
    }
    return (
      <div style={{ height: 1, overflow: 'auto' }}>
        <iframe
          title="print terms condition"
          id={`task_iframe${data.id}`}
          style={{
            height: '0',
            width: '0',
          }}
        />
        <div className="pdf-html" id={`task_to_print${data.id}`}>
          <div id="pdf-logo">
            <img src={require('images/liscioLogo.png')} alt="liscio logo" />
          </div>
          <br />
          <div className="bottomBorder col" />
          <div>
            <h5>
              <span className="text-dark">Task Title</span>
              {`    ${data.subject}`}
            </h5>
          </div>
          <div className="div-top">
            <div>
              <p className="left-content">
                <b>Contact:</b>
                <span>{contact}</span>
              </p>
              <p className="due-date">
                <b>Due Date:</b>
                <span>{moment(data.due_date).format('MM/DD/YY')}</span>
              </p>
              <br />
              <br />
            </div>
            <div>
              <p>
                <b>Account:</b>
                <span>{account}</span>
              </p>
            </div>
            <br />
            <p>
              <b>Task Owner:</b>
              <span>{data.owner}</span>
            </p>
            <br />
            <div className="bottomBorder col" />
            <br />
            <div className="row no-gutters">
              <p className="">
                <b>Description:</b>
                <br />
                <br />
                {data.description && data.description !== null ? (
                  <Interweave
                    allowAttributes
                    content={removeBreakElement(
                      data.description
                        .replace(/<a\b[^>]*>/g, '')
                        .replace(/<\/a>/g, ''),
                    )}
                  />
                ) : null}
              </p>
            </div>
            <hr />
          </div>
          <div className="bottomBorder col" />
          <br />
          <p>
            <b>Comments:</b>
          </p>
          {data.commentsActivity && data.commentsActivity.length
            ? [...data.commentsActivity].reverse().map((each) => {
                if (each.type === 'comment') {
                  return (
                    <div className="print-comment-sections">
                      <div key={each.id}>
                        <p className="highlight">
                          {each.comment_by} on{' '}
                          {moment.unix(each.created).format('MMM D')}:
                        </p>
                        <span className="comment_content">
                          <Interweave
                            allowAttributes
                            content={removeBreakElement(
                              each.comment
                                .replace(/<a\b[^>]*>/g, '')
                                .replace(/<\/a>/g, ''),
                            )}
                          />
                        </span>
                        {/* <br /> */}
                        {/* <br /> */}
                      </div>
                    </div>
                  );
                }
                return null;
              })
            : null}
          <div className="bottomBorder col pt-2" />
          <br />
          {data.documents && data.documents.length ? (
            <div>
              <p>
                <b>Attachments:</b>
              </p>
              {data.documents.map((each) => (
                <div key={each.id} style={{ marginTop: '8px' }}>
                  <p>
                    <span>{each.file_name}</span>
                  </p>
                </div>
              ))}
              {/* <hr /> */}
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default TaskPdfLayout;
