import { RequestType, WorkflowRequestStatus } from 'gql/graphql';
import { useNavigate } from 'react-router-dom-latest';
import {
  IN_APP,
  REQUESTS_PAGE_ROUTES,
  REQUESTS_PAGE_TITLES,
} from 'components/Requests/requests.constants';
import { useEffect } from 'react';
import { ArchivedRequestsTable } from 'components/Requests/components/Tables/ArchivedRequestsTable';
import { useWorkflowRequestsQuery } from 'hooks/workflow-request-hooks';
import { usePagination } from 'components/Requests/components/RequestsBuilder/hooks/pagination';
import { useRequestsState } from 'components/Requests/state/request-state';

export const ArchivedRequestsView = () => {
  useEffect(() => {
    document.title = REQUESTS_PAGE_TITLES.archived;
  }, []);
  const { paginationModel, setPaginationModel } = usePagination();
  const sortStatus = useRequestsState(
    (state) => state.sortStatus?.[WorkflowRequestStatus.Closed],
  );
  const filters = useRequestsState((state) => state.filters);

  const archivedRequests = useWorkflowRequestsQuery({
    statuses: [WorkflowRequestStatus.Closed, WorkflowRequestStatus.Recalled],
    perPage: paginationModel.pageSize,
    page: paginationModel.page + 1,
    filterData: {
      assigneeIds: filters[WorkflowRequestStatus.Closed].assignedTo,
      ownerIds: filters[WorkflowRequestStatus.Closed].owners,
    },
    ...sortStatus,
  });

  const navigate = useNavigate();

  const onWorkflowClick = (id: string) => {
    navigate(
      `${REQUESTS_PAGE_ROUTES.requests}/${id}?type=${RequestType.Request}`,
      {
        state: { from: IN_APP },
      },
    );
  };

  return (
    <ArchivedRequestsTable
      query={archivedRequests}
      onRowClick={onWorkflowClick}
      paginationModel={paginationModel}
      setPaginationModel={setPaginationModel}
    />
  );
};
