import React from 'react';

const transformInputFieldinInterweave = (node) => {
  if (node.tagName === 'INPUT') {
    return React.createElement('input', {
      id: node.id,
      type: node.type,
      className: node.class,
      value: node.value,
      name: node.name,
      checked: node.checked,
      placeholder: node.placeholder,
    });
  }
  return undefined;
};

export default transformInputFieldinInterweave;
