import { adobeIntegrationModule } from './adobe';
import { IntegrationModule } from './integration-module.types';
import { karbonIntegrationModule } from './karbon';
import { quickbooksIntegrationModule } from './quickbooks';
import { stripeIntegrationModule } from './stripe';

/**
 * List of available integration modules
 */
export const integrationModules: IntegrationModule[] = [
  adobeIntegrationModule,
  karbonIntegrationModule,
  stripeIntegrationModule,
  quickbooksIntegrationModule,
];
