import React from 'react';
import { Button } from 'reactstrap';
import InputField from 'common/InputField';

export type UserProfileChangePasswordV3Props = {
  // Parent state
  state: {
    oldPass?: any;
    oldPassword?: any;
    errors?: any;
    newPass?: any;
    newPassword?: any;
    confirmPass?: any;
    confirmPassword?: any;
    mfaEnabled?: boolean;
  };
  // Parent handlers
  handleInputFieldChange: () => any;
  handleBlurChange: () => any;
  showHide: (v: any) => any;
  handlePasswordSubmit: (v: any) => void;
  handleCancel: () => void;
};

/**
 * NOTE: This component was simply moved out of it's parent UserProfile
 * component for better separation of concerns while migrating to v5 auth.
 * It's bad because it will be deleted soon.
 */
export const UserProfileChangePasswordV3 = ({
  state,
  handleInputFieldChange,
  handleBlurChange,
  showHide,
  handlePasswordSubmit,
  handleCancel,
}: UserProfileChangePasswordV3Props) => (
  <div>
    <div className="row">
      <InputField
        name="oldPassword"
        label="Old Password"
        autocomplete="new-password"
        type={state.oldPass}
        className="col-sm-12 col-lg-5 col-md-5 form-group"
        onChange={handleInputFieldChange}
        value={state.oldPassword}
        error={state.errors.oldPassword}
        onBlur={handleBlurChange}
        passwordShow={state.oldPass}
        showHide={() => showHide('oldPass')}
      />
    </div>
    <div className="row">
      <InputField
        name="newPassword"
        label="New Password"
        autocomplete="new-password"
        className="col-sm-12 col-lg-5 col-md-5 form-group"
        type={state.newPass}
        onChange={handleInputFieldChange}
        value={state.newPassword}
        error={state.errors.newPassword}
        onBlur={handleBlurChange}
        passwordShow={state.newPass}
        showHide={() => showHide('newPass')}
      />
      <InputField
        name="confirmPassword"
        label="Confirm Password"
        type={state.confirmPass}
        className="col-sm-12 col-lg-5 col-md-5 form-group"
        onChange={handleInputFieldChange}
        value={state.confirmPassword}
        error={state.errors.confirmPassword}
        onBlur={handleBlurChange}
        passwordShow={state.confirmPass}
        showHide={() => showHide('confirmPass')}
      />
    </div>
    <div className="formbtn justify-content-start mt-3">
      <div className="btn-wrap btn--leftspace">
        <Button className="btn  btn-primary" onClick={handlePasswordSubmit}>
          Update
        </Button>
        <Button
          className="btn btn-outline-light btn--leftspace-edit"
          color="primary"
          onClick={handleCancel}
        >
          Cancel
        </Button>
      </div>
    </div>
  </div>
);
