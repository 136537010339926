const invoiceTypesMap = {
  'One-Time': 'invoices',
  Recurring: 'recurring',
};
export function makeBillingRedirectURL(data, taskId) {
  const { invoice_type } = data;

  const parsedInvoiceType = invoiceTypesMap[invoice_type];

  if (!parsedInvoiceType) return window.location.href;

  const correctSubPath = `billing/${parsedInvoiceType}/`;

  return window.location.href.includes('billing')
    ? window.location.href
    : `${window.location.href}${correctSubPath}${taskId}`;
}

export const getInitialPaymentMethod = (
  is_cc_payment_allowed,
  is_ach_payment_allowed,
) => {
  if (is_cc_payment_allowed && is_ach_payment_allowed) {
    return 'checkout';
  }
  if (is_cc_payment_allowed) {
    return ' setShowModal(true);';
  }
  return 'ach';
};
