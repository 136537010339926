import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import styled from '@emotion/styled';
import { emailsTimelineCategories } from 'components/Email/EmailUtilities';
import EmailBlock from './EmailsBlock';

const EmailListing = styled.div`
  height: 100%;
  .EmDay {
    padding-left: 0;
    padding-right: 0.5rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    span {
      text-transform: uppercase;
      font-weight: 700;
      font-size: 15px;
    }
  }
`;

const EmailListHead = ({
  emails,
  id,
  from,
  composeEmail,
  toggleSelect,
  filter,
  connectedApp,
  refreshPage,
  unreadEmail,
  nextThread,
  viewMode,
  handleEmailDetailMode,
  emailDetailMode,
  setLoading,
}) => {
  const emailsFrom = emails.reduce(
    (acc, email, index) => {
      const htmlContent = (
        <EmailBlock
          key={email.id}
          email={email}
          index={index}
          id={id}
          from={from}
          toggleSelect={toggleSelect}
          composeEmail={composeEmail}
          filter={filter}
          connectedApp={connectedApp}
          refreshPage={refreshPage}
          unreadEmail={unreadEmail}
          nextThread={nextThread}
          viewMode={viewMode}
          emailDetailMode={emailDetailMode}
          handleEmailDetailMode={handleEmailDetailMode}
          setLoading={setLoading}
        />
      );
      const emailDateObject = new Date(email.updatedAt);
      const emailYear = emailDateObject.getFullYear();
      const emailMonth = emailDateObject.getMonth();
      const emailDay = emailDateObject.getDate();
      const currentDateObject = new Date();
      const currentYear = currentDateObject.getFullYear();
      const currentMonth = currentDateObject.getMonth();
      const currentDay = currentDateObject.getDate();

      if (emailYear === currentYear) {
        if (emailMonth === currentMonth) {
          if (emailDay === currentDay) {
            if (!acc.today) acc.today = [];
            acc.today.push(htmlContent);
          } else if (currentDay - emailDay === 1) {
            if (!acc.yesterday) acc.yesterday = [];
            acc.yesterday.push(htmlContent);
          } else if (currentDay - emailDay < 7) {
            if (!acc.thisWeek) acc.thisWeek = [];
            acc.thisWeek.push(htmlContent);
          } else if (currentDay - emailDay > 7 && currentDay - emailDay < 14) {
            if (!acc.lastWeek) acc.lastWeek = [];
            acc.lastWeek.push(htmlContent);
          } else {
            if (!acc.thisMonth) acc.thisMonth = [];
            acc.thisMonth.push(htmlContent);
          }
        } else if (currentMonth - emailMonth === 1) {
          if (!acc.lastMonth) acc.lastMonth = [];
          acc.lastMonth.push(htmlContent);
        } else {
          if (!acc.thisYear) acc.thisYear = [];
          acc.thisYear.push(htmlContent);
        }
      } else if (currentYear - emailYear === 1) {
        if (!acc.lastYear) acc.lastYear = [];
        acc.lastYear.push(htmlContent);
      } else if (currentYear - emailYear > 1) {
        if (!acc.pastYear) acc.pastYear = [];
        acc.pastYear.push(htmlContent);
      }
      return acc;
    },
    { pastYears: [] },
  );
  return (
    <EmailListing className="EmWrap">
      <Scrollbars
        style={{
          width: '100%',
          height: '100%',
          minHeight: '100%',
          maxHeight: '100%',
        }}
        autoHide
        autoHeightMin={0}
        autoHideTimeout={1000}
      >
        {emailsTimelineCategories.reduce((acc, category) => {
          if (emailsFrom[category.key]) {
            acc.push(
              <React.Fragment key={category.key}>
                <div className="EmDay">
                  <span>{category.label}</span>
                </div>
                {emailsFrom[category.key]}
              </React.Fragment>,
            );
          }
          return acc;
        }, [])}
      </Scrollbars>
    </EmailListing>
  );
};

export default EmailListHead;
