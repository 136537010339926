/* eslint-disable no-debugger */
const initialState = {
  previewEsignDetail: {},
  previewEsignModal: false,
};

// eslint-disable-next-line default-param-last
export default function esignReducer(state = initialState, action) {
  switch (action.type) {
    case 'PREVIEW_ESIGN': {
      return {
        ...state,
        previewEsignDetail: action.data,
        previewEsignModal: action.previewmodal,
      };
    }
    default:
      return state;
  }
}
