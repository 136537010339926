import React from 'react';
import { printPdf } from 'utilities/utils';

const PrintPdfButton = ({ iframe_id, content_id }) => (
  <div>
    <iframe
      title="print terms condition"
      id={iframe_id}
      style={{
        height: '0px',
        width: '0px',
        position: 'absolute',
      }}
    />
    <button
      type="button"
      className="btn btn-primary"
      onClick={() => printPdf(content_id, iframe_id)}
    >
      <i className="icon-print" aria-hidden="true" />
      Print
    </button>
  </div>
);

export default PrintPdfButton;
