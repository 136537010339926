import React, { useState, useEffect } from 'react';
import TruncateMarkup from 'react-truncate-markup';
import FilterTag from './FilterTag';

const RenderSelectedRecords = ({
  dropdownFor,
  data,
  changedSelectedViewHeight,
  handleRemove,
}) => {
  const [showAll, setShowAll] = useState(false);
  const [initialHeight, setInitialHeight] = useState();

  useEffect(() => {
    setTimeout(() => {
      const view = document.getElementById(
        !showAll ? 'truncatedselectedrecordsview' : 'selectedrecordsview',
      );
      if (view) {
        if (initialHeight === undefined) {
          setInitialHeight(view.clientHeight);
        }
        if (view.clientHeight < 40 && showAll) {
          setShowAll(!showAll);
        }
        changedSelectedViewHeight(`${view.clientHeight}px`);
      }
    }, 100);
  }, [showAll, data]);

  const toggleView = () => {
    setShowAll(!showAll);
  };

  const usersLeftEllipsis = (node) => {
    const usersRendered = node.props.children;

    return (
      <span
        style={{
          zIndex: 2,
          position: 'relative',
        }}
      >
        ...{' '}
        <button
          type="button"
          className="toggleView"
          onClick={toggleView}
          style={{ border: 'none', backgroundColor: '#00aecc', color: 'white' }}
        >
          {`+${data.length - usersRendered.length} more`}
        </button>
      </span>
    );
  };
  return !showAll ? (
    <TruncateMarkup lines={3} ellipsis={usersLeftEllipsis}>
      <div
        id="truncatedselectedrecordsview"
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          maxHeight: '100%',
        }}
        className="customTags__input form-control"
      >
        {data.map((e) => (
          <TruncateMarkup.Atom>
            <FilterTag
              name={dropdownFor === 'Account' ? e.name : e.label}
              onClick={() => handleRemove(e)}
            />
          </TruncateMarkup.Atom>
        ))}
      </div>
    </TruncateMarkup>
  ) : (
    <div
      id="selectedrecordsview"
      className="customTags__input form-control"
      style={{ maxHeight: '100%' }}
    >
      {data.map((e) => (
        <FilterTag
          name={dropdownFor === 'Account' ? e.name : e.label}
          onClick={() => handleRemove(e)}
        />
      ))}
      <button
        type="button"
        onClick={toggleView}
        style={{
          zIndex: 2,
          position: 'relative',
          border: 'none',
          backgroundColor: '#00aecc',
          color: 'white',
        }}
      >
        Show less
      </button>
    </div>
  );
};

export default RenderSelectedRecords;
