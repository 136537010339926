import React from 'react';
import { Button } from '@mui/material';

import { CheckboxField, DisplayData } from 'common/CustomFields';
import { payrollTaxes } from 'components/Accounts/AccountCreation/AccountUtilities';
import AccountAttribute from 'common/AccountAttribute/AccountAttribute';

const PayrollView = (props) => {
  const {
    data: { formdata },
    handleEditButton,
  } = props;

  const disable = true;

  return (
    <div className="center-wrapper col-12">
      <div className="row justify-content-between">
        <DisplayData
          data={formdata}
          propertyValues={{ value: 'co_pay_freq', label: 'Pay Frequency' }}
        />

        <Button
          variant="text"
          startIcon={<i className="icon-edit mx-2" aria-hidden="true" />}
          sx={{ padding: '0px 15px' }}
          onClick={handleEditButton}
        >
          Edit
        </Button>
      </div>
      <div className="row">
        <div className="form-group col-2">
          <label>Pay Items:</label>
        </div>
        <div className="col">
          <div className="row">
            <CheckboxField
              name="co_is_pay_hourly"
              label="Hourly"
              onChange={() => {}}
              value={formdata.co_is_pay_hourly}
              className="col-sm-6 col-md-4 col-lg-2"
              disabled={disable}
            />
            <CheckboxField
              name="co_is_pay_salary"
              label="Salary"
              onChange={() => {}}
              value={formdata.co_is_pay_salary}
              className="col-sm-6 col-md-4 col-lg-2"
              disabled={disable}
            />
            <CheckboxField
              name="co_is_pay_reimbursement"
              label="Reimbursement"
              onChange={() => {}}
              value={formdata.co_is_pay_reimbursement}
              className="col-sm-6 col-md-4 col-lg-2"
              disabled={disable}
            />
            <CheckboxField
              name="co_is_pay_bonus"
              label="Bonuses / Extra Pay Plus"
              onChange={() => {}}
              value={formdata.co_is_pay_bonus}
              className="col-sm-6 col-md-4 col-lg-3"
              disabled={disable}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="form-group col-2">
          <label>Deductions:</label>
        </div>
        <div className="col">
          <div className="row">
            <CheckboxField
              name="co_is_ded_401k"
              label="401(K)"
              onChange={() => {}}
              value={formdata.co_is_ded_401k}
              className="col-sm-6 col-md-4 col-lg-2"
              disabled={disable}
            />
            <CheckboxField
              name="co_is_ded_401k_roth"
              label="401(K) Roth"
              onChange={() => {}}
              value={formdata.co_is_ded_401k_roth}
              className="col-sm-6 col-md-4 col-lg-2"
              disabled={disable}
            />
            <CheckboxField
              name="co_is_ded_sample_ira"
              label="Simple IRA"
              onChange={() => {}}
              value={formdata.co_is_ded_sample_ira}
              className="col-sm-6 col-md-4 col-lg-2"
              disabled={disable}
            />
            <CheckboxField
              name="co_is_ded_health"
              label="Health"
              onChange={() => {}}
              value={formdata.co_is_ded_health}
              className="col-sm-6 col-md-4 col-lg-2"
              disabled={disable}
            />
            <CheckboxField
              name="co_is_ded_garnishments"
              label="Garnishments"
              onChange={() => {}}
              value={formdata.co_is_ded_garnishments}
              className="col-sm-6 col-md-4 col-lg-2"
              disabled={disable}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="form-group col-2">
          <label>Accrual Tracking:</label>
        </div>
        <div className="col">
          <div className="row">
            <CheckboxField
              name="co_is_accrual_vataion"
              label="Vacation"
              onChange={() => {}}
              value={formdata.co_is_accrual_vataion}
              className="col-sm-6 col-md-4 col-lg-2"
              disabled={disable}
            />
            <CheckboxField
              name="co_is_accrual_sick"
              label="Sick"
              onChange={() => {}}
              value={formdata.co_is_accrual_sick}
              className="col-sm-6 col-md-4 col-lg-2"
              disabled={disable}
            />
          </div>
        </div>
      </div>
      <div className="col-container mb-4 section-title">
        <h5>Payroll Taxes</h5>
      </div>
      <div className="row">
        {payrollTaxes.map((each) => (
          <AccountAttribute
            key={each.label}
            formData={formdata}
            propertyValues={each}
            recordId={formdata.id}
          />
        ))}
      </div>
    </div>
  );
};

export default PayrollView;
