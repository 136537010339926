import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom-latest';
import {
  BUTTON_ICON_LOCATION_FRONT,
  BUTTON_ICON_LOCATION_BACK,
} from 'constants/constants';
import { getButtonSize } from './utilities';

const StyledButton = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00aecc;
  text-decoration: none;
  border: none;
  color: #ffffff;
  height: ${(props) => getButtonSize(props.size).height};
  padding: 13px 15px;
  border-radius: 2px;
  transition: all ease-in-out 350ms;
  :hover {
    color: #ffffff;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
  }
  :disabled,
  [disabled] {
    color: #dfe3e8;
  }
`;

const LinkButton = ({
  to,
  label,
  icon,
  disabled = false,
  size,
  iconLocation,
}) => (
  <Link to={to}>
    <StyledButton href={to} id={label} disabled={disabled} size={size}>
      {icon && iconLocation === BUTTON_ICON_LOCATION_FRONT && (
        <i className={`icon icon-${icon}`} />
      )}
      {label}
      {icon && iconLocation === BUTTON_ICON_LOCATION_BACK && (
        <i className={`icon icon-${icon}`} />
      )}
    </StyledButton>
  </Link>
);

export default LinkButton;
