import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { Link } from 'react-router-dom-latest';
import { STRIPE_ACCOUNT, NO_STRIPE_ACCOUNT } from 'constants/constants';
import { AlertMessage, getCpaId, isClient } from 'utilities/utils';
import stripeLogo from 'images/stripe_logo.png';
import { useWebsocket } from 'websocket';

const getConnectBtnLabel = (account_status) => {
  let output = '';
  switch (account_status) {
    case 'new':
      output = 'Continue Set-up';
      break;
    case 'need_more_info':
      output = 'Continue Set-up';
      break;
    case 'requirements_due':
      output = 'Please update account details';
      break;
    case 'pending':
      output = 'In Review';
      break;
    case 'active':
      output = 'View Account Details';
      break;
    case 'set_up_in_progress':
      output = 'Set-up in progress';
      break;
    default:
      output = 'Connect';
      break;
  }
  return output;
};

const Payments = (props) => {
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState('');
  const [connectBtnLabel, setConnectBtnLabel] = useState('Create Account');
  const [additionalInfoRequired, setAdditionalInfoRequired] = useState(false);
  const [initializedBy, setInitializedBy] = useState();
  const websocket = useWebsocket();

  const getAccountStatus = async () => {
    try {
      const response = await Axios.get(
        `${STRIPE_ACCOUNT}?url=${window.location.href}`,
      ).then((res) => res.data);
      const { account_status, is_additional_info_required, initialized_by } =
        response;
      setStatus(account_status);
      setConnectBtnLabel(getConnectBtnLabel(account_status));
      if (initialized_by && account_status === 'set_up_in_progress') {
        setInitializedBy(initialized_by);
      }
      if (is_additional_info_required && account_status === 'active') {
        setAdditionalInfoRequired(is_additional_info_required);
      }

      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAccountStatus();
    const stripeSubscription = websocket.consumer.subscriptions.create(
      {
        channel: 'StripeAccountUpdatesChannel',
        cpa_id: getCpaId(),
      },
      {
        connected: () => {
          console.log('connected to StripeAccountUpdatesChannel');
        },
        received: (dataReceived) => {
          console.log('Data received from stripe', dataReceived);
          const { account_status, created_by } = dataReceived;
          if (account_status) {
            setConnectBtnLabel(getConnectBtnLabel(account_status));
            if (account_status === 'set_up_in_progress' && created_by) {
              setInitializedBy(created_by);
            }
          }
        },
        disconnected: () => {
          console.log('disconnected from StripeAccountUpdatesChannel');
        },
      },
    );
    return () => {
      console.log('unsubscribe from stripe channel');
      stripeSubscription.unsubscribe();
      // Do unmounting stuff here
    };
  }, []);

  const handleConnect = async () => {
    setLoading(true);
    try {
      const response = await Axios.post(
        `${STRIPE_ACCOUNT}?url=${window.location.href}`,
      ).then((res) => res.data.url);
      if (status === 'active') {
        window.open(response);
        setLoading(false);
      } else {
        window.location = response;
      }
    } catch (err) {
      setLoading(false);
      AlertMessage('error', err.response.data.message, 3000);
    }
  };

  if (status === NO_STRIPE_ACCOUNT && !isClient()) return null;

  return (
    <div className="Payments col-lg-4 col-sm-12 col-md-6">
      <div
        className="card mb-4 shadow-s"
        style={{ height: 'calc(100% - 16px)' }}
      >
        <div
          className="card-img-top d-flex justify-content-center"
          style={{ height: '150px', alignItems: 'center' }}
        >
          <img src={stripeLogo} alt="adobe_icon" style={{ width: '100%' }} />
        </div>
        <div className="card-body">
          <h5 className="card-title">{props.integrationAppsPref.label}</h5>
          {status === 'active' ||
          status === 'new' ||
          status === 'no_account' ||
          status === 'need_more_info' ||
          status === 'requirements_due' ? (
            <div>
              <button
                type="button"
                className="btn btn-primary col mt-3"
                onClick={handleConnect}
                disabled={loading}
              >
                {connectBtnLabel}
              </button>
              {status === 'active' && (
                <Link className="btn btn-primary col mt-3" to="/payouts">
                  Payouts
                </Link>
              )}
              {status === 'active' && (
                <button
                  type="button"
                  className="btn btn-primary col mt-3"
                  disabled
                >
                  Connected
                </button>
              )}
              {additionalInfoRequired && (
                <p className="text-danger mt-2">
                  Please update account details for receiving payouts
                </p>
              )}
            </div>
          ) : null}
          {status === 'pending' || status === 'set_up_in_progress' ? (
            <div>
              <button
                type="button"
                className="btn btn-primary col mt-3"
                onClick={handleConnect}
                disabled
              >
                {connectBtnLabel}
              </button>
              {initializedBy && (
                <p className="text-info mt-2">Initiated by {initializedBy}</p>
              )}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Payments;
