/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { EMAIL } from 'utilities/regularexpressions';
import { selectTheme, selectStyles } from 'common/CustomFields';
import { mungeResponsesForInputField } from './data-utils/emailInputFieldUtils';
import { searchGmailContacts } from './async-utils/gmail-fetches';

const RenderOption = (props) => (
  <components.Option {...props}>
    <div className="row align-items-center pl-3">
      {props.data.avatar ? (
        <div style={{ width: '32px', height: '32px' }} className="pl">
          <img
            src={props.data.avatar}
            alt="profile"
            className="profile-image"
            style={{
              width: '32px',
              height: '32px',
              borderRadius: '50%',
              backgroundColor: '#919eab',
            }}
          />
        </div>
      ) : null}
      <div className="col">
        {props.data.label !== props.data.value ? (
          <div className="row flex-column pl-2">
            <div>
              <span>
                <strong>{props.data.label}</strong>
              </span>
              <br />
            </div>
            <div>
              <span className="text-sm">{props.data.value}</span>
              <br />
            </div>
          </div>
        ) : (
          <div className="row pl-2">
            <span>{props.data.value}</span>
          </div>
        )}
      </div>
    </div>
  </components.Option>
);

const EmailInputField = (props) => {
  const [selectEmailIds, setSelectEmailIds] = useState([]);
  const [contactsList, setContactsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  let typingTime;
  const isNewEmail = props.isNew || false;
  const styleInput = isNewEmail
    ? {
        ...selectStyles,
        valueContainer: (provided) => ({
          ...provided,
          paddingRight: '75px',
        }),
      }
    : {
        ...selectStyles,
        valueContainer: (provided) => ({
          ...provided,
          paddingLeft: 0,
          paddingRight: '82px',
        }),
        control: (provided) => ({
          ...provided,
          borderTop: 'none',
          borderLeft: 'none',
          borderRight: 'none',
          borderBottom: '1px solid #dee2e6 !important',
          minHeight: '36px',
        }),
        multiValue: (provided) => ({
          ...provided,
          backgroundColor: '#eef0f1',
        }),
        Input: (provided) => ({
          ...provided,
          paddingRight: '40px',
        }),
      };

  const handleChange = (input) => {
    setSelectEmailIds(input);
    props.handleChange(input);
  };

  const getContacts = async (input) => {
    try {
      const data = [];
      if (props.connectedAppKey && props.connectedAppKey === 'outlookemail') {
        const head = { headers: { Authorization: `Bearer ${props.token}` } };
        const response = await Axios.get(
          `https://graph.microsoft.com/v1.0/me/people?$search="${input}"`,
          head,
        ).then((res) => res.data.value);
        if (response && response.length) {
          response.forEach((element) => {
            const { scoredEmailAddresses, displayName } = element;
            if (scoredEmailAddresses) {
              data.push({
                value: scoredEmailAddresses[0].address,
                label: displayName || scoredEmailAddresses[0].address,
              });
            }
          });
        }
      } else {
        const rawContacts = await searchGmailContacts(input);

        data.push(...mungeResponsesForInputField(rawContacts));
      }

      setContactsList(data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleSelectInputChange = (e) => {
    if (e && e.length) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
    clearTimeout(typingTime);
    typingTime = setTimeout(() => {
      if (e.length) {
        getContacts(e);
      } else {
        setContactsList([]);
      }
    }, 500);
  };

  useEffect(() => {
    if (props.value) {
      setSelectEmailIds(props.value);
    }
  }, [selectEmailIds.length !== props.value.length]);

  return (
    <CreatableSelect
      isMulti
      cacheOptions
      options={contactsList}
      value={selectEmailIds}
      onChange={handleChange}
      openMenuOnFocus={false}
      openMenuOnClick={false}
      defaultOptions={false}
      isValidNewOption={(input) =>
        props.connectedApp && props.connectedApp === 'outlookemail'
          ? EMAIL.test(input)
          : EMAIL.test(input)
      }
      formatCreateLabel={(input) =>
        props.connectedApp && props.connectedApp === 'outlookemail'
          ? input
          : `Use ${input}`
      }
      placeholder="Enter email address"
      theme={selectTheme}
      styles={styleInput}
      blurInputOnSelect={false}
      components={{
        DropdownIndicator: null,
        Option: RenderOption,
        ClearIndicator: null,
      }}
      onInputChange={handleSelectInputChange}
      isLoading={isLoading}
    />
  );
};

export default EmailInputField;
