/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Modal } from 'reactstrap';
import moment from 'moment';
import usePaymentsModal from './usePaymentsModal';
import Sources from './PaymentSources';
import SingleSource from './PaymentSingleSource';
import SelectPaymentMethod from './SelectPaymentMethod';

const PaymentsModal = ({ data, className, updateTaskStatus }) => {
  const {
    singleSource,
    loading,
    gettingCustomerData,
    showSources,
    gettingPlaidPublicToken,
    showModal,
    handlePaymentMethod,
    setUpACHandCard,
    handlePayButton,
    handleACHPayment,
    toggleModal,
    handlePayInvoiceBtn,
    mode,
    paymentMethod,
    customerSources,
    setShowSources,
  } = usePaymentsModal({ data, updateTaskStatus });

  const sourcesToComponent = {
    achsources: (
      <Sources
        customerSources={customerSources}
        handleACHPayment={handleACHPayment}
        invoiceAmount={data.invoice_amount}
        handleCancel={() =>
          data.is_ach_payment_allowed && !data.is_cc_payment_allowed
            ? toggleModal()
            : setShowSources('')
        }
        addNewBank={setUpACHandCard}
        loading={loading}
        mode={mode}
        gettingPlaidPublicToken={gettingPlaidPublicToken}
      />
    ),
    singleachsource: (
      <SingleSource
        source={singleSource}
        handleACHPayment={handleACHPayment}
        invoiceAmount={data.invoice_amount}
        handleCancel={() =>
          data.is_ach_payment_allowed && !data.is_cc_payment_allowed
            ? toggleModal()
            : setShowSources(false)
        }
        loading={loading}
        mode={mode}
        gettingPlaidPublicToken={gettingPlaidPublicToken}
      />
    ),
  };

  return (
    <div>
      <button
        type="button"
        onClick={handlePayInvoiceBtn}
        className="btn btn-primary"
      >
        {/* this is when user is authorizating payment for first time */}
        {data.is_parent_task &&
          data.stripe_payment_authorization_id === null &&
          'Authorize Payment'}
        {/* this is when existing payment methods fails */}
        {data.is_parent_task &&
          data.stripe_payment_authorization_id !== null &&
          (moment(data.recurring_end_date).isAfter(moment())
            ? 'Authorize Payment'
            : 'Update Payment')}
        {/* this is child invoice created from recurring parent which doesn't have payment methosd */}
        {!data.is_parent_task &&
          data.is_recurring &&
          data.stripe_payment_authorization_id === null &&
          (data.invoice_status === 'failed' ||
            data.invoice_status === 'unpaid') &&
          'Authorize Payment'}
        {/* this is child invoice created from recurring parent and existing payment failed */}
        {!data.is_parent_task &&
          data.is_recurring &&
          data.stripe_payment_authorization_id !== null &&
          (data.invoice_status === 'failed' ||
            data.invoice_status === 'unpaid') &&
          'Update Payment'}
        {/* this is for once time invoice */}
        {!data.is_parent_task && !data.is_recurring && 'Pay Invoice'}
      </button>
      <Modal
        isOpen={showModal}
        toggle={toggleModal}
        className={
          className ||
          'customModal customModal--center customModal--alert customModal--w500 customModal--nopadding PaymentsModal'
        }
      >
        <div className="ModalHeader">
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={toggleModal}
          >
            <i className="icon-close2" aria-hidden="true" />
          </button>
        </div>
        {sourcesToComponent[showSources] || (
          <SelectPaymentMethod
            invoiceAmount={data.invoice_amount}
            invoiceNumber={data.invoice_number}
            paymentMethod={paymentMethod}
            handlePaymentMethod={handlePaymentMethod}
            noBtnClick={toggleModal}
            gettingCustomerData={gettingCustomerData}
            gettingPlaidPublicToken={gettingPlaidPublicToken}
            loading={loading}
            setUpACHandCard={setUpACHandCard}
            customerSources={customerSources}
            handlePayButton={handlePayButton}
            mode={mode}
          />
        )}
      </Modal>
    </div>
  );
};

export default PaymentsModal;
