import { createContext, useCallback, useMemo, useState } from 'react';

interface WorkflowViewStore {
  valid: boolean;
  checkValid: (valid: any) => void;
  setDirtyField(field: string, dirty: boolean): void;
  dirtyFields: Set<string>;
  isDirty: boolean;
}

export const WorkflowViewContext = createContext<WorkflowViewStore>({
  valid: false,
  checkValid: () => {},
  dirtyFields: new Set(),
  setDirtyField: () => {},
  isDirty: false,
});

export const WorkflowViewProvider = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const [valid, setValid] = useState(false);
  const [dirtyFields, setDirtyFields] = useState(() => new Set<string>());

  const checkValid = useCallback(
    (value: any) => {
      if (!valid && value !== undefined && value !== null) {
        setValid(true);
      }
    },
    [valid],
  );

  const setDirtyField = useCallback(
    (field: string, dirty: boolean) => {
      setDirtyFields((prev) => {
        const next = new Set(prev);
        if (dirty) {
          next.add(field);
        } else {
          next.delete(field);
        }
        return next;
      });
    },
    [setDirtyFields],
  );

  const ctx = useMemo(
    () => ({
      valid,
      checkValid,
      dirtyFields,
      isDirty: dirtyFields.size > 0,
      setDirtyField,
    }),
    [valid, checkValid, dirtyFields, setDirtyField],
  );

  return (
    <WorkflowViewContext.Provider value={ctx}>
      {children}
    </WorkflowViewContext.Provider>
  );
};
