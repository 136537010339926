import { Check } from '@mui/icons-material';
import { Typography, Stack } from '@mui/material';
import { RequestTypeLabel } from 'components/Requests/components/RequestTypeLabel/RequestTypeLabel';
import { WorkflowRequestStatus, WorkflowStatus } from 'gql/graphql';

type WorkflowViewInfoBarProps = {
  status?: WorkflowStatus | WorkflowRequestStatus;
  isDirty?: boolean;
};
export const WorkflowViewInfoBar = ({
  status,
  isDirty,
}: WorkflowViewInfoBarProps) => (
  <>
    {isDirty !== undefined && !isDirty && (
      <Stack direction="row" alignItems="center">
        <Typography color="text.secondary" fontWeight={700}>
          Saved
        </Typography>
        <Check color="success" />
      </Stack>
    )}
    {status && <RequestTypeLabel status={status} />}
  </>
);
