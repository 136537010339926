/* eslint-disable global-require */
import React from 'react';

const CustomImage = ({ src, style, className, alt }) => {
  let image_path = '';
  try {
    // TODO: find better way to get image path
    // eslint-disable-next-line import/no-dynamic-require
    image_path = require(`../images/${src}`);
  } catch (err) {
    image_path = require('../images/no_image.png'); // set default image path
  }
  return <img style={style} src={image_path} className={className} alt={alt} />;
};

export default CustomImage;
