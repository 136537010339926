import React from 'react';
import ContentLoader from 'react-content-loader';

const LiscioLoginContentLoader = () => (
  <ContentLoader
    height={120}
    width={136}
    speed={3}
    backgroundColor="#F3F3F3"
    foregroundColor="#E3E4E6"
    className="liscioLoginLoader"
  >
    <circle cx="68" cy="40" r="30" />
    <rect x="8" y="80" rx="2" ry="2" width="120" height="10" />
    <rect x="23" y="100" rx="2" ry="2" width="90" height="10" />
  </ContentLoader>
);

export default LiscioLoginContentLoader;
