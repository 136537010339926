import {
  Box,
  Divider,
  Drawer,
  Stack,
  ThemeProvider,
  Typography,
} from '@mui/material';
import liscioLightTheme from 'theme/liscioLight';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import SMSMessageHistory from 'components/SMS/components/SMSMessageHistory/SMSMessageHistory';
import { useEffect, useRef } from 'react';
import { useGetThreadDetailOnce } from 'hooks/sms-hooks';
import { AlertMessage } from 'utilities/utils';
import { TOAST_FAILURE } from 'constants/constants';

type Props = {
  threadId: string | number;
  showSlideout: boolean;
  setShowSlideout: (show: boolean) => void;
};

export default function SMSSlideout({
  threadId,
  showSlideout,
  setShowSlideout,
}: Props) {
  const historyElRef = useRef(null);

  const {
    data: threadDetails = { messages: [], participants: [] },
    isError,
    isInitialLoading,
  } = useGetThreadDetailOnce(threadId);

  const scrollToBottom = () => {
    if (historyElRef.current) {
      (historyElRef.current as Element).scrollTo({
        top: (historyElRef.current as Element).scrollHeight + 1000,
      });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [threadDetails.messages]);

  if (isError) {
    AlertMessage(TOAST_FAILURE, 'An error occurred!');
    return null;
  }

  return (
    <Drawer
      anchor="right"
      open={showSlideout}
      onTransitionEnd={() => scrollToBottom()}
      onClose={() => setShowSlideout(false)}
    >
      <ThemeProvider theme={liscioLightTheme}>
        <Stack>
          <Box padding={4}>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h3">SMS thread</Typography>
              <CloseOutlinedIcon
                onClick={() => setShowSlideout(false)}
                fontSize="small"
                aria-label="close icon"
                sx={{ cursor: 'pointer' }}
              />
            </Stack>
          </Box>
          <Divider />
          <Box
            width="50vw"
            sx={{ overflow: 'auto', height: 'calc(100vh - 6rem)' }}
            ref={historyElRef}
          >
            <SMSMessageHistory
              multipleParticipants={
                threadDetails?.participants.length > 1 || false
              }
              messages={threadDetails?.messages}
              isInitialLoading={isInitialLoading}
            />
          </Box>
        </Stack>
      </ThemeProvider>
    </Drawer>
  );
}
