import React from 'react';
import PropTypes from 'prop-types';
import PrimaryTag from './PrimaryTag';
import SecondaryTag from './SecondaryTag';

const Tag = ({ label, onClick, to, variant = 'primary' }) => {
  if (variant === 'secondary') {
    return <SecondaryTag label={label} onClick={onClick} to={to} />;
  }
  return <PrimaryTag label={label} onClick={onClick} to={to} />;
};

Tag.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  variant: PropTypes.oneOf(['primary', 'secondary']),
  to: PropTypes.string,
};

export default Tag;
