const exportSVG = `
  <span
    className="PSPDFKit-Tool-Button-Icon PSPDFKit-Toolbar-Button-Icon PSPDFKit-Icon PSPDFKit-Icon-ExportPdf"
    aria-hidden="true"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="currentColor"
      viewBox="0 0 24 24"
      focusable="false"
    >
      <path
        fill-rule="evenodd"
        d="M12 2.25a.75.75 0 0 1 .75.75v13.19l2.72-2.72a.75.75 0 1 1 1.06 1.06L12 19.06l-4.53-4.53a.75.75 0 1 1 1.06-1.06l2.72 2.72V3a.75.75 0 0 1 .75-.75Zm-9 14a.75.75 0 0 1 .75.75v3c0 .69.56 1.25 1.25 1.25h14c.69 0 1.25-.56 1.25-1.25v-3a.75.75 0 0 1 1.5 0v3A2.75 2.75 0 0 1 19 22.75H5A2.75 2.75 0 0 1 2.25 20v-3a.75.75 0 0 1 .75-.75Z"
        clip-rule="evenodd"
      />
    </svg>
  </span>
`;

export const DownloadWithFileNameButton = {
  type: 'custom',
  id: 'liscio-download-with-filename-button',
  icon: exportSVG,
  title: 'Download',
  onPress: () => {
    window?.PSPDFKIT_INSTANCE?.exportPDF({ flatten: !0 }).then((buffer) => {
      const fileName = window?.PSPDFKIT_INSTANCE?.fileName || 'liscio_file.pdf';
      const blob = new Blob([buffer], { type: 'application/pdf' });
      // @ts-ignore - IE11
      if (window.navigator.msSaveOrOpenBlob)
        // @ts-ignore - IE11
        window.navigator.msSaveOrOpenBlob(blob, fileName);
      else {
        const objectUrl = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = objectUrl;
        // @ts-ignore
        a.style = 'display: none';
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        URL.revokeObjectURL(objectUrl);
        document.body.removeChild(a);
      }
    });
  },
};
