/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-unused-class-component-methods */
import React, { Component } from 'react';
import { withRouter } from 'utilities/withRouterWrapper';
import moment from 'moment-timezone';
import { operatingSystemIcon, getDeviceName, titleCase } from 'utilities/utils';
import ConfirmationModal from 'common/ConfirmationModal';
import UserProfileDetail from 'api/UserProfileDetail';

const noneStyle = { display: 'none' };
const blockStyle = { display: 'block' };
const timeZone =
  Intl.DateTimeFormat().resolvedOptions().timeZone || moment.tz.guess(true);

class DeviceList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      signOutDeviceConfirm: false,
      deviceNotRecognize: false,
      deviceList: [],
      signOutDeviceName: '',
      loading: false,
    };
    // this.deviceInformation = this.deviceInformation.bind(this);
  }

  componentDidMount() {
    const component = this;
    UserProfileDetail.getUserProfile(component, 'security');
  }

  deviceInformation = (e, item) => {
    const { id } = item;
    this.props.navigate(`/profile_settings/devicedetail/${id}`, {
      state: { data: item },
    });
  };

  signOutDeviceConfirm = (value, id, name, signoutdevice = '') => {
    const component = this;
    if (signoutdevice === 'yes') {
      const { signOutDeviceId } = component.state;
      UserProfileDetail.logOutFromDevice(
        component,
        signOutDeviceId,
        'devicelist',
      );
    } else {
      component.setState({
        signOutDeviceConfirm: value,
        signOutDeviceId: id,
        signOutDeviceName: name,
      });
    }
  };

  deviceNotRecognize = (value, notrecognize = '') => {
    this.setState({ deviceNotRecognize: value });
    if (notrecognize === 'yes') {
      this.props.changePassword();
    }
  };

  anchorClick = (e) => {
    e.stopPropagation();
  };

  render() {
    const {
      signOutDeviceConfirm,
      deviceNotRecognize,
      deviceList,
      loading,
      signOutDeviceName,
    } = this.state;
    const signOutMessage = `<div><h5><p className="mb-2"><strong>Sign out from ${signOutDeviceName.toUpperCase()}</strong></p></h5><p className="mb-2">You will be force logged out from this device</p></div>`;
    const loggedInDevices = deviceList.map((item) => {
      const icon = operatingSystemIcon(item);
      const deviceName = getDeviceName(item);
      const deviceMake =
        item.device_make &&
        item.device_make !== null &&
        item.device_make !== 'null'
          ? `${item.device_make}`
          : '';
      const deviceModel =
        item.device_model &&
        item.device_model !== null &&
        item.device_model !== 'null'
          ? item.device_model
          : '';
      const deviceMakeModel =
        deviceMake + (deviceMake && deviceModel ? ' - ' : '') + deviceModel;
      const deviceOs =
        item.device_os && item.device_os.toLowerCase() !== 'other-0'
          ? item.device_os
          : '';
      const deviceBrowser =
        item.browser && item.browser.toLowerCase() !== 'generic browser'
          ? titleCase(item.browser)
          : '';
      const deviceOsBrowser =
        deviceOs.toUpperCase() +
        (deviceOs && deviceBrowser ? ', ' : '') +
        deviceBrowser;
      const address =
        (item.city && item.city !== null && item.city !== 'null'
          ? `${item.city}, `
          : '') +
        (item.state && item.state !== null && item.state !== 'null'
          ? `${item.state}, `
          : '') +
        (item.country && item.country !== null && item.country !== 'null'
          ? item.country
          : '');
      const ipAddress = item.ip_address ? `(${item.ip_address})` : '';
      const deviceInfo =
        deviceName.toUpperCase() +
        (deviceMakeModel ? `, ${deviceMakeModel.toUpperCase()}` : '') +
        (deviceOsBrowser ? ` (${deviceOsBrowser})` : '');
      const appName = item.app_name;
      let time = item.created_at;
      if (item.created_at.indexOf('ago') === -1) {
        time = moment.tz(time, timeZone).format('MM/DD/YY hh:mm a');
      }
      return (
        <div
          className="col-md-5 col-12 dList__inner"
          key={deviceList.indexOf(item)}
        >
          <div className="media py-3 media--dropdown">
            <h3 className="mr-3 text-large text-black">
              <i className={icon} />
            </h3>
            <div className="media-body posRel pr-4">
              <div onClick={(e) => this.deviceInformation(e, item)}>
                <h6 className="mt-0 font-weight-bolder mb-0 fontSize">
                  {deviceInfo}{' '}
                  {item.this_devise && (
                    <a className="py-0 fontSize text-transform-none font-weight-normal">
                      This device
                    </a>
                  )}
                </h6>
                {address && (
                  <span className="text-dark">
                    {address} {ipAddress}
                  </span>
                )}
                <div className="text-dark">
                  {appName} • {time}
                </div>
              </div>
              {!item.this_devise && (
                <div className="dropdown dropdown--small">
                  <a
                    className="btn btn-link btn--onlyicon dropdown-toggle"
                    data-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i className="icon-more" />
                  </a>
                  <div className="dropdown-menu dropdown-menu-right">
                    <a
                      className="dropdown-item"
                      onClick={() =>
                        this.signOutDeviceConfirm(true, item.id, deviceName, '')
                      }
                    >
                      Sign out
                    </a>
                    <a
                      className="dropdown-item"
                      onClick={() => this.deviceNotRecognize(true)}
                    >
                      Don’t recognize this device?
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    });
    return (
      <div className="row">
        <div id="loading" style={loading ? blockStyle : noneStyle} />
        <div className="settingsSec col-12">
          <div className="row">
            <div className="col-12">
              <h2 className="text-small pt-5 mb-1">Your Devices</h2>
              <p>
                You’re currently signed in to your Liscio Account on these
                devices
              </p>
            </div>
            <div className="col-12">
              <div className="row dList">{loggedInDevices}</div>
            </div>
          </div>
          <ConfirmationModal
            isOpen={signOutDeviceConfirm}
            className="customModal customModal--w350 customModal--nopadding"
            isHtml="true"
            messageText={signOutMessage}
            noBtnClick={() => this.signOutDeviceConfirm(false, '', '', '')}
            yesBtnClick={() => this.signOutDeviceConfirm(false, '', '', 'yes')}
            yesBtnText="Sign out"
            noBtnText="Cancel"
          />
          <ConfirmationModal
            isOpen={deviceNotRecognize}
            headerText="Let's secure your account"
            isHtml="true"
            messageText="<div><p>If there's a device you don't recognize, someone else may have your password. Change your password to protect your Liscio Account.</p><p>You'll be signed out from all devices including the one you're using now.</p></div>"
            noBtnClick={() => this.deviceNotRecognize(false)}
            yesBtnClick={() => this.deviceNotRecognize(false, 'yes')}
            yesBtnText="Change password"
            noBtnText="Cancel"
          />
        </div>
      </div>
    );
  }
}

export default withRouter(DeviceList);
