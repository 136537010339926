/* eslint-disable */
import React, { Component } from 'react';
import { Link, useNavigationType } from 'react-router-dom-latest';
import Axios from 'axios';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Interweave from 'interweave';
import ReactTooltip from 'react-tooltip';
import moment from 'moment-timezone';
import HeaderTabs from 'common/HeaderTabs_updated';
import { EsignFilters } from 'common/MessageFilters';
import { sortingIcon, getInitials } from 'utilities/utils';
import * as esignActions from 'actions/esignActions';
import TooltipItem from 'common/TooltipItem_updated';
import Pagination from 'common/Pagination';
import {
  CREATE_NEW_EDOC_REQUEST,
  SEARCH_TASK_ACCOUNTS,
  SEARCH_TASK_CONTACTS,
  ADOBE_WEB_ACCESS_URL,
  GET_EDOC_REQUESTS,
} from 'constants/constants';
import PreviewEsign from './PreviewEsign_updated';
import EsignCancellationForm from './EsignCancellationForm';
import AccountFilter from '../Filters/AccountFilter_updated';
import EmployeeAndContactFilter from '../Filters/EmployeeAndContactFilter_updated';
import AdobeLogout from './AdobeLogout';
import Button from 'common/Button/Button';

let tabs = [
  {
    value: 'in_progress',
    label: 'In Progress',
  },
  {
    value: 'completed',
    label: 'Completed',
  },
  {
    value: 'cancelled',
    label: 'Cancelled',
  },
  {
    value: 'draft',
    label: 'Draft',
  },
];

const tableHeaders = [
  {
    value: 'agreement_name',
    label: 'Agreement Name',
    isSortable: true,
    className: ' col-3 thCol',
    cancelTabClass: ' col-2 thCol',
  },
  {
    value: 'requester',
    label: 'Requestor',
    isSortable: true,
    className: ' col-2 thCol',
    cancelTabClass: ' col-2 thCol',
  },
  {
    value: 'signers',
    label: 'Signers',
    isSortable: false,
    className: ' col-3 thCol',
    cancelTabClass: ' col-2 thCol',
  },
  {
    value: 'updated_at',
    label: 'Last Activity At',
    isSortable: true,
    className: ' col-3 thCol',
    cancelTabClass: ' col-3 thCol',
  },
];

const timeZone =
  Intl.DateTimeFormat().resolvedOptions().timeZone || moment.tz.guess(true);

class EsignList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0,
      owners: [],
      accounts: [],
      allAccounts: [],
      contacts: [],
      allContacts: [],
      list: [],
      edocData: {},
      selectedFilterList: {},
      loading: false,
      sortOrder: 'desc',
      sortBy: 'updated_at',
      filter: 'in_progress',
      apiPath: '',
      page: 1,
      totalpages: 1,
      totalrecords: 0,
      off: 0,
      offset: 1,
      searchEdocText: '',
      useNewApi: window.location.href.includes('beta'),
      filterInFocus: ''
    };
    this.typingTime = null;
  }


  /** todo: temporary fix for filter dropdowns, below 2 methods can be removed once filters are refactored as a reuasable component */
  setFilterInFocus = (filterName = '', e = {}) => {
    e.stopPropagation && e.stopPropagation();
    this.setState({ filterInFocus: this.state.filterInFocus===filterName ? '': filterName});
  };

  hideOpenFilters = () => {
    this.setState({ filterInFocus: '' });
  };

  componentDidMount() {
    document.title = 'eDocs';
    const { match, previewEsignModal, previewEsignDetail } = this.props;
    //TODO: convert history.action to hooks for router v6
    if (
      match &&
      match.url === '/esign_list' &&
      this.props.action === 'POP' &&
      previewEsignModal
    ) {
      this.handlePreview(previewEsignDetail);
    }
    this.setState({ loading: true });
    this.getRecords();
    this.getAdobeWebAccessUrl();
  }

  getAdobeWebAccessUrl = () => {
    const adobeWebAccessUrl = localStorage.getItem('adobeWebAccessUrl');
    if (adobeWebAccessUrl === null) {
      Axios.get(ADOBE_WEB_ACCESS_URL).then((res) => {
        if (res.status === 200 && res.data && res.data.web_access_url) {
          this.setState({
            adobeWebAccessUrl: res.data.web_access_url,
          });
          localStorage.setItem('adobeWebAccessUrl', res.data.web_access_url);
        }
      });
    } else {
      this.setState({
        adobeWebAccessUrl,
      });
    }
  };

  getRecords() {
    if (this.state.useNewApi) {
      this.getEdocRecords();
    } else {
      const { selectedFilterList, sortOrder, sortBy, searchEdocText } =
        this.state;
      const apiPath =
        (selectedFilterList.REQUESTOR
          ? '&cpa_user_id=' + selectedFilterList.REQUESTOR[0].id
          : '') +
        (selectedFilterList.ACCOUNT
          ? '&account_id=' + selectedFilterList.ACCOUNT[0].id
          : '') +
        (selectedFilterList.SIGNERS
          ? '&signer_id=' + selectedFilterList.SIGNERS[0].id
          : '') +
        (searchEdocText ? '&keyword=' + searchEdocText : '') +
        ('&sort_by=' + sortOrder + '&field=' + sortBy);
      this.setState(
        {
          apiPath,
        },
        () => {
          this.createEdocRequest();
        },
      );
    }
  }

  getEdocRecords = () => {
    const {
      filter,
      page,
      selectedFilterList,
      sortOrder,
      sortBy,
      searchEdocText,
      activeTab,
    } = this.state;
    const apiPath =
      (selectedFilterList.REQUESTOR
        ? '&cpa_user_id=' + selectedFilterList.REQUESTOR[0].id
        : '') +
      (selectedFilterList.ACCOUNT
        ? '&account_id=' + selectedFilterList.ACCOUNT[0].id
        : '') +
      (selectedFilterList.SIGNERS
        ? '&signer_id=' + selectedFilterList.SIGNERS[0].id
        : '') +
      (searchEdocText ? '&keyword=' + searchEdocText : '') +
      ('&sort_by=' + sortOrder + '&field=' + sortBy) +
      '&status=' +
      tabs[activeTab].value;
    Axios.get(GET_EDOC_REQUESTS + '?per_page=15&page=' + page + apiPath).then(
      (res) => {
        if (res.data && res.data.requests) {
          const { in_progress, completed, cancelled, draft, off, offset } =
            res.data.stats;
          const total = res.data.stats[tabs[activeTab].value];
          const edocData = {
            in_progress_total: in_progress || 0,
            completed_total: completed || 0,
            cancelled_total: cancelled || 0,
            draft_total: draft || 0,
          };
          this.setState({
            list: res.data.requests,
            offset,
            off,
            edocData,
            loading: false,
            totalpages: total === 0 ? total : Math.ceil(total / 15),
            totalrecords: total,
          });
        } else {
          this.setState({
            loading: false,
          });
        }
      },
    );
  };

  createEdocRequest() {
    const { filter, apiPath, page } = this.state;
    this.setState({ loading: true });
    Axios.get(CREATE_NEW_EDOC_REQUEST + '?per_page=15&page=' + page + apiPath)
      .then((response) => {
        if (response.data.status === 200) {
          const total = response.data.data[filter + '_total'];
          this.setState({
            edocData: response.data.data,
            list: response.data.data[filter],
            totalpages: total === 0 ? total : Math.ceil(total / 15),
            totalrecords: total,
            offset: response.data.data[filter + '_offset'],
            off: response.data.data[filter + '_off'],
            loading: false,
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  }

  changeActiveTab = (key, each) => {
    const { edocData, page, useNewApi } = this.state;
    this.setState(
      {
        activeTab: key,
        filter: each.value,
        list: edocData[each.value],
      },
      () => {
        if (page === 1 && !useNewApi) {
          const total = edocData[this.state.filter + '_total'];
          this.setState({
            list: edocData[this.state.filter],
            totalpages: total === 0 ? 0 : Math.ceil(total / 15),
            totalrecords: total,
            offset: edocData[this.state.filter + '_offset'],
            off: edocData[this.state.filter + '_off'],
          });
        } else {
          this.setState(
            {
              page: 1,
              loading: true,
            },
            () => {
              this.getRecords();
            },
          );
        }
      },
    );
  };

  clearInput = (inputName) => {
    const component = this;
    component.setState(
      {
        [inputName]: '',
      },
      () => {
        component.inputChange('', inputName.toUpperCase());
      },
    );
  };

  clearFilters = () => {
    this.setState(
      {
        selectedFilterList: {},
        contacts: this.state.allContacts,
        accounts: this.state.allAccounts,
        page: 1,
        searchEdocText: '',
        loading: true,
      },
      () => {
        this.getRecords();
      },
    );
  };

  inputChange = (value, filterName) => {
    const name = filterName.toLowerCase();
    const component = this;
    this.setState({
      [name]: value,
    });
    if (filterName === 'REQUESTOR') {
      const filter = value.toUpperCase();
      const div = document.getElementById(filterName);
      const a = div.getElementsByTagName('a');
      for (let i = 0; i < a.length; i++) {
        if (a[i].innerHTML.toUpperCase().indexOf(filter) > -1) {
          a[i].style.display = '';
        } else {
          a[i].style.display = 'none';
        }
      }
    } else if (filterName === 'ACCOUNT') {
      if (value < 2) {
        this.setState({
          accounts: this.state.allAccounts,
        });
      } else {
        clearTimeout(this.typingTime);
        this.typingTime = setTimeout(() => {
          Axios.get(SEARCH_TASK_ACCOUNTS + '/?keyword=' + value).then(
            (response) => {
              if (response.status === 200) {
                component.setState({
                  accounts: response.data,
                });
              }
            },
          );
        }, 500);
      }
    } else if (filterName === 'SIGNERS') {
      if (value < 2) {
        this.setState({
          contacts: this.state.allContacts,
        });
      } else {
        clearTimeout(this.typingTime);
        this.typingTime = setTimeout(() => {
          Axios.get(
            SEARCH_TASK_CONTACTS + '/?keyword=' + value + '&message=true',
          ).then((response) => {
            if (response.status === 200) {
              component.setState({
                contacts: response.data,
              });
            }
          });
        }, 500);
      }
    }
  };

  removeFilter = (filterName) => {
    const { selectedFilterList } = this.state;
    delete selectedFilterList[filterName];
    this.setState(
      {
        selectedFilterList,
        [filterName.toLowerCase()]: '',
        page: 1,
      },
      () => {
        this.getRecords();
      },
    );
  };

  handleFilters = (e, input, filterType) => {
    let { selectedFilterList } = this.state;
    selectedFilterList = {
      ...selectedFilterList,
      [filterType]: [{ name: input.label, id: input.value }],
    };
    this.setState(
      {
        selectedFilterList,
        page: 1,
      },
      () => {
        this.getRecords();
      },
    );
  };

  handlePageChange = (page) => {
    const component = this;
    if (page === this.state.page) {
      return false;
    }
    this.setState(
      {
        page,
      },
      () => {
        this.getRecords();
      },
    );
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
    return true;
  };

  handleSortBy = (sortBy) => {
    let { sortOrder } = this.state;
    if (this.state.sortBy === sortBy) {
      if (sortOrder === 'asc') {
        sortOrder = 'desc';
      } else {
        sortOrder = 'asc';
      }
    } else {
      sortOrder = 'asc';
    }

    this.setState(
      {
        sortOrder,
        sortBy,
        page: 1,
      },
      () => {
        this.getRecords();
      },
    );
  };

  handleCancelBtn = (input, fro = '') => {
    const { id } = input;
    if (id) {
      this.setState({
        loading: true,
      });
      // Axios.delete(CREATE_NEW_EDOC_REQUEST + '/' + id).then((res) => {
      //   if (res.data && res.data.message) {
      //     AlertMessage('success', res.data.message, 3000);
      //   } else {
      //     AlertMessage('error', 'Something went wrong', 3000);
      //   }
      //   this.getRecords();
      //   if (fro === 'cancelfrompreview') {
      //     const edoc = this.state.listDetail;
      //     edoc.status = 'cancelled';
      //     this.setState({ listDetail: edoc });
      //   }
      // }).catch(() => {
      //   this.setState({
      //     loading: false,
      //   });
      // });
    }
  };

  showAgreementCancellationAlert = (input) => {
    const { id } = input;
    this.setState({
      agreementToCancel: id,
      hideCancellationReason:
        input.status && input.status.toLowerCase() === 'authoring',
    });
  };

  hideAgreementCancellationAlert = (refreshList) => {
    this.setState({
      agreementToCancel: undefined,
    });
    if (refreshList) {
      this.getRecords();
      if (this.state.previewModal) {
        const edoc = this.state.listDetail;
        edoc.status = 'Cancelled';
        this.setState({ listDetail: edoc });
      }
    }
  };

  handlePreview = (input) => {
    const { id, status } = input;
    if (status && status.toLowerCase() === 'authoring') {
      this.props.navigate('/esign/edit/' + id);
    } else {
      this.setState(
        {
          listDetail: input,
          previewModal: true,
        },
        () => {
          this.props.esign.previewEsign(
            this.state.listDetail,
            this.state.previewModal,
          );
        },
      );
    }
  };

  closeModal = (close) => {
    this.setState({ previewModal: false, listDetail: {} }, () => {
      this.props.esign.previewEsign(
        this.state.listDetail,
        this.state.previewModal,
      );
    });
  };

  addNewEdoc = () => {
    this.props.navigate('/new_esign', {
      state: { from: 'eDocTemplate' },
    });
  }

  handleEdocSearch = (input) => {
    const inputvalue =
      input && input.target && input.target.value ? input.target.value : '';
    const { searchEdocText } = this.state;
    this.setState({ searchEdocText: inputvalue });
    clearTimeout(this.typingTime);
    this.typingTime = setTimeout(() => {
      this.setState(
        {
          page: 1,
        },
        () => this.getRecords(),
      );
    }, 500);
    // if (inputvalue.length < 3 && searchEdocText.length === 3) {
    //   console.log('abhi');
    //   this.setState({
    //     loading: true,
    //     page: 1,
    //   }, () => (this.getRecords()));
    // }
  };

  // handleEdocSearchRequest = debounce((e) => {
  //   const { searchEdocText } = this.state;
  //   if (searchEdocText.length > 2) {
  //     this.setState({
  //       loading: true,
  //       page: 1,
  //     }, () => (this.getRecords()));
  //   }
  // }, 300)

  renderSigners(item, row) {
    let sharedWith = '';
    if (item.signers && item.signers.length) {
      sharedWith = item.signers.map((each, index) => {
        if (index < 4) {
          return (
            <div
              key={index + 'signers'}
              data-tip
              data-for={`${each.initials}_${row}_${index}`}
              className="avatar avatar--w30 d-inline-block"
            >
              {each.name ? (
                each.avatar ? (
                  <img
                    src={each.avatar}
                    alt="I"
                    width="20"
                    height="20"
                    className="rounded-circle"
                  />
                ) : (
                  <span className="uName">{each.initials}</span>
                )
              ) : each.account_name ? (
                <span className="uName">{getInitials(each.account_name)}</span>
              ) : (
                ''
              )}
              <ReactTooltip
                id={`${each.initials}_${row}_${index}`}
                effect="solid"
              >
                <span className="contactcard-team-member">
                  {each.name +
                    (each.account_name ? ' . ' + each.account_name : '')}
                </span>
              </ReactTooltip>
            </div>
          );
        }
        if (index === 4) {
          const list = item.signers.map((e, i) => {
            if (i >= 4) {
              return (
                <a key={item.signers.indexOf(e)} className="dropdown-item py-1">
                  <div className="d-flex">
                    <span className="avatar avatar--w20 align-self-start">
                      <span className="uName">
                        {e.initials || getInitials(e.account_name)}
                      </span>
                    </span>
                    <div className="">
                      <h5 className="fontSize letterSpace-1 mb-0">{e.name}</h5>
                      <h4 className="subHeadline text-light letterSpace-1 mb-0">
                        {e.account_name}
                      </h4>
                    </div>
                  </div>
                </a>
              );
            }
            return null;
          });
          return (
            <div
              key={index}
              className="dropdown dropdown--small d-inline-block ml-0"
            >
              <a
                className="btn p-0 dropdown-toggle border-0"
                data-toggle="dropdown"
                style={{ lineHeight: '20px' }}
              >
                <span className="avatar avatar--w30 align-self-center">
                  <span className="uName">+{item.signers.length - 4}</span>
                </span>
                <span className="dropupWrap--caret" />
              </a>
              <div className="dropdown-menu dropdown-menu-right">{list}</div>
            </div>
          );
        }
        return null;
      });
    }
    return sharedWith;
  }


  render() {
    const {
      selectedFilterList,
      list,
      page,
      totalrecords,
      totalpages,
      off,
      offset,
      edocData,
      agreementToCancel,
      hideCancellationReason,
      adobeWebAccessUrl,
      searchEdocText,
      activeTab,
    } = this.state;
    if (edocData && Object.keys(edocData).length) {
      tabs = [
        {
          value: 'in_progress',
          label: 'In Progress | ' + edocData.in_progress_total,
        },
        {
          value: 'completed',
          label: 'Completed | ' + edocData.completed_total,
        },
        {
          value: 'cancelled',
          label: 'Cancelled | ' + edocData.cancelled_total,
        },
        {
          value: 'draft',
          label: 'Draft | ' + edocData.draft_total,
        },
      ];
    }
    console.log(this.state.filterInFocus)
    const anyFiltersSelected =
      (selectedFilterList && Object.keys(selectedFilterList).length) ||
      searchEdocText;
    const esignList =
      list && list.length ? (
        list.map((each, index) => {
          let time = each.updated_at;
          if (each.updated_at.indexOf('ago') === -1) {
            time = moment.tz(time, timeZone).format('MM/DD/YY hh:mm a');
          }
          return (
            <div className="tdBtn row" key={list.indexOf(each)}>
              <div
                className={activeTab === 2 ? 'col-2 tdCol' : 'col-3 tdCol'}
                style={{ transition: 'none' }}
                onClick={() => this.handlePreview(each)}
              >
                <span className="posRel">{each.agreement_name}</span>
              </div>
              <div
                className="col-2 tdCol"
                style={{ transition: 'none' }}
                onClick={() => this.handlePreview(each)}
              >
                <span className="avatar avatar--w20 align-self-start">
                  {each.requester.avatar ? (
                    <img
                      src={each.requester.avatar}
                      alt="I"
                      width="20"
                      height="20"
                      className="rounded-circle"
                    />
                  ) : (
                    <span className="uName">{each.requester.initials}</span>
                  )}
                </span>
                <span className="posRel">{each.requester.name}</span>
              </div>
              <div
                className={activeTab === 2 ? 'col-2 tdCol' : 'col-3 tdCol'}
                style={{ transition: 'none' }}
                onClick={() => this.handlePreview(each)}
              >
                {this.renderSigners(each, each.id)}
              </div>
              <div
                className="col-3 tdCol"
                style={{ transition: 'none' }}
                onClick={() => this.handlePreview(each)}
              >
                <span className="posRel">{time}</span>
              </div>
              {activeTab !== 2 ? (
                <div
                  className="col-1 tdCol text-right vertical-top"
                  style={{ transition: 'none' }}
                >
                  {(each.status === 'in_progress' ||
                    each.status === 'authoring') && (
                    <TooltipItem
                      position="top"
                      tooltipType="button"
                      id={'esign' + each.id}
                      text="Cancel"
                      btnClass="btn-sm btn--onlyicon btn-link tdBtn__hover"
                      clickAction={() =>
                        this.showAgreementCancellationAlert(each)
                      }
                      iconn="icon-circle-cancel"
                    />
                  )}
                </div>
              ) : each.cancellation_reason ? (
                <div
                  className="col-3 tdCol text-left vertical-top"
                  style={{ transition: 'none' }}
                >
                  <span className="posRel">
                    <Interweave
                      allowAttributes
                      content={
                        each.cancellation_reason ===
                        'AGREEMENT_AUTO_CANCELLED_CONVERSION_PROBLEM'
                          ? 'Auto cancelled the agreement. The document used for creating this eDoc is not properly formatted.'
                          : each.cancellation_reason
                      }
                    />
                  </span>
                </div>
              ) : null}
            </div>
          );
        })
      ) : (
        <div className="row emptyTr">
          <div className="emptyBox">
            <div className="onlyText">
              <p>No Records Found</p>
            </div>
          </div>
        </div>
      );
    return (
      <div className='EsignList' onClick={this.hideOpenFilters} role="presentation">
        <header className="pb-0 pt-0">
          <HeaderTabs
            data={tabs}
            activeTab={this.state.activeTab}
            handleClick={this.changeActiveTab}
          />
        </header>
        <AdobeLogout />
        {this.state.loading ? <div id="loading" /> : null}
        {/* <button onClick={this.getEdocRecords}>Get records</button> */}
        <div className="center-wrapper">
          <section>
            <div className="filter-wrapper d-flex justify-content-between">
              <div className="d-flex bd-highlight align-items-center">
                <div className="form-group has-search">
                  <span className="icon-zoom2 form-control-feedback" />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search edocs"
                    id="edoc-search"
                    onChange={this.handleEdocSearch}
                    // onKeyUp={this.handleEdocSearchRequest}
                    value={this.state.searchEdocText}
                    autoComplete="off"
                  />
                </div>
                  <label
                    htmlFor="filter"
                    style={{ paddingLeft: '10px' }}
                    className="mt-2 mr-1 m-0"
                  >
                    Filter by
                  </label>
                  <EmployeeAndContactFilter
                    filterSelected={this.handleFilters}
                    type="Employee"
                    filterName="REQUESTOR"
                    isActive={
                      selectedFilterList && selectedFilterList.REQUESTOR
                    }
                    setFilterInFocus={this.setFilterInFocus}
                    showFilterOptions={this.state.filterInFocus === "REQUESTOR"}
                  />
                  <AccountFilter
                    filterSelected={this.handleFilters}
                    isActive={selectedFilterList && selectedFilterList.ACCOUNT}
                    setFilterInFocus={this.setFilterInFocus}
                    showFilterOptions={this.state.filterInFocus === "ACCOUNT"}
                  />
                  <EmployeeAndContactFilter
                    filterSelected={this.handleFilters}
                    type="EmployeeandContact"
                    filterName="SIGNERS"
                    isActive={selectedFilterList && selectedFilterList.SIGNERS}
                    setFilterInFocus={this.setFilterInFocus}
                    showFilterOptions={this.state.filterInFocus === "SIGNERS"}
                  />
                  {anyFiltersSelected ? (
                    <div className="align-self-center ml-2 clearFilter">
                      <a onClick={this.clearFilters}>
                        <i className="icon-close2 label-small" />
                        Clear All
                      </a>
                    </div>
                  ) : null}
                  <div className="select-filter-list w-100">
                    <ShowFiterTags
                      filterName="REQUESTOR"
                      selectedTags={selectedFilterList}
                      handleClick={this.removeFilter}
                    />
                    <ShowFiterTags
                      filterName="ACCOUNT"
                      selectedTags={selectedFilterList}
                      handleClick={this.removeFilter}
                    />
                    <ShowFiterTags
                      filterName="SIGNERS"
                      selectedTags={selectedFilterList}
                      handleClick={this.removeFilter}
                    />
                  </div>
              </div>
              <div className="ml-3 d-flex">
                <Button variant="link" to="/esign_templates" label=" Template Library" />
                <Button variant="link" to="/new_esign" label="+ eDoc" />
              </div>
            </div>
            <div id="inbox-list" className="tableWrap">
              <div className="tRow tRow--head">
                <div className="row">
                  {tableHeaders.map((each) => (
                    <div
                      key={each.value + 'headers'}
                      className={
                        sortingIcon(
                          this.state.sortBy,
                          each.value,
                          this.state.sortOrder,
                          'className',
                        ) +
                        (activeTab === 2
                          ? each.cancelTabClass
                          : each.className) +
                        (!each.isSortable ? ' cursor-default' : '')
                      }
                      style={{ transition: 'none' }}
                      onClick={() =>
                        each.isSortable && list && list.length
                          ? this.handleSortBy(each.value)
                          : null
                      }
                    >
                      <span
                        className={
                          each.isSortable ? 'posRel' : 'posRel cursor-default'
                        }
                      >
                        {each.label}
                        <i
                          className={sortingIcon(
                            this.state.sortBy,
                            each.value,
                            this.state.sortOrder,
                          )}
                        />
                      </span>
                    </div>
                  ))}
                  {this.state.activeTab === 2 ? (
                    <div
                      key="cancellation_reason headers"
                      className={
                        sortingIcon(
                          this.state.sortBy,
                          'cancellation_reason',
                          this.state.sortOrder,
                          'className',
                        ) + ' col-3 thCol cursor-default'
                      }
                      style={{ transition: 'none' }}
                      onClick={() =>
                        list && list.length
                          ? this.handleSortBy('cancellation_reason')
                          : null
                      }
                    >
                      <span className="posRel">
                        cancellation reason
                        <i
                          className={sortingIcon(
                            this.state.sortBy,
                            'cancellation_reason',
                            this.state.sortOrder,
                          )}
                        />
                      </span>
                    </div>
                  ) : (
                    <div
                      className="col-1 thCol"
                      style={{ transition: 'none' }}
                    />
                  )}
                </div>
              </div>
              <div className="tRow tRow--body">{esignList}</div>
            </div>
            <Pagination
              currentPage={list && list.length >= 1 ? page : 0}
              totalPages={totalpages}
              totalRecords={totalrecords}
              offset={totalrecords === 0 ? offset - 1 : offset}
              off={off}
              handlePageChange={this.handlePageChange}
            />
          </section>
        </div>
        {this.state.previewModal && (
          <PreviewEsign
            data={this.state}
            cancelAgreement={this.showAgreementCancellationAlert}
            closeModal={() => this.closeModal('close')}
          />
        )}
        {agreementToCancel && (
          <EsignCancellationForm
            agreementId={agreementToCancel}
            noBtnClick={this.hideAgreementCancellationAlert}
            hideReasonInput={hideCancellationReason}
          />
        )}
      </div>
    );
  }
}

const ShowFiterTags = (props) => {
  const { selectedTags, handleClick, filterName, data } = props;
  if (Object.keys(selectedTags).length && selectedTags[filterName]) {
    return (
      <div className="badge-filter">
        {selectedTags[filterName][0].name}
        <a>
          <i
            tabIndex={0}
            role="button"
            className="icon-close2"
            onMouseDown={() => handleClick(filterName)}
          />
        </a>
      </div>
    );
  }
  return null;
};

const mapStateToProps = (state) => ({
  previewEsignDetail: state.esignReducer.previewEsignDetail,
  previewEsignModal: state.esignReducer.previewEsignModal,
});

const mapDispatchToProps = (dispatch) => ({
  esign: bindActionCreators(esignActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(EsignList);
