import React from 'react';
import { useQueryClient } from '@tanstack/react-query';

export const withQueryClient = (Element) => {
  const Wrapper = (props) => {
    const queryClient = useQueryClient();
    return <Element {...props} queryClient={queryClient} />;
  };

  return Wrapper;
};
