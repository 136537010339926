import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import { ACCOUNT, ACCOUNT_BANK_ACCOUNTS } from 'constants/constants';
import {
  isUserEmployee,
  getCpaPreferences,
  sortArraybyProperty,
} from 'utilities/utils';
import moment from 'moment';
import { useNavigate } from 'react-router-dom-latest';
import NewAccount from './NewAccount';
import {
  generateEntityTypeOptions,
  decryptDependentData,
  showIndividualAdditionInformationForm,
  decryptBankAccounts,
} from './AccountUtilities';

const EditAccount = () => {
  const [account, setAccount] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    getAccountDetails();
  }, []);

  const getAccountDetails = async () => {
    try {
      const accountId = window.location.href.split('edit/')[1];
      if (accountId) {
        const res = await Axios.get(`${ACCOUNT}/${accountId}`);
        let bankAccountData;
        const logins = await Axios.get(`${ACCOUNT_BANK_ACCOUNTS}/${accountId}`);
        if (logins.status === 200 && logins.data && logins.data.data) {
          bankAccountData = sortArraybyProperty(
            decryptBankAccounts(logins.data.data),
            'id',
          );
        }
        if (res.status === 200 && res.data.data) {
          const accountDetails = res.data.data.account;
          const { is_account_owner } = res.data.data;
          const { status } = accountDetails;
          // redirect inactive account to account overview page
          if (
            (status &&
              status !== null &&
              status.toLowerCase() === 'inactive') ||
            (!isUserEmployee() && !is_account_owner)
          ) {
            navigate(`/accountdetails/${accountId}`);
          }
          if (accountDetails.co_fiscal_yearend !== null) {
            accountDetails.co_fiscal_yearend =
              moment(accountDetails.co_fiscal_yearend).utc().format('MM') - 1;
          }
          accountDetails.in_self_birthdate =
            accountDetails.in_self_birthdate !== null
              ? moment(accountDetails.in_self_birthdate, 'YYYY-MM-DD').toDate()
              : null;
          accountDetails.in_self_license_expiry_date =
            accountDetails.in_self_license_expiry_date !== null
              ? moment(
                  accountDetails.in_self_license_expiry_date,
                  'YYYY-MM-DD',
                ).toDate()
              : null;
          accountDetails.in_self_license_issue_date =
            accountDetails.in_self_license_issue_date !== null
              ? moment(
                  accountDetails.in_self_license_issue_date,
                  'YYYY-MM-DD',
                ).toDate()
              : null;
          accountDetails.in_sps_birthdate =
            accountDetails.in_sps_birthdate !== null
              ? moment(accountDetails.in_sps_birthdate, 'YYYY-MM-DD').toDate()
              : null;
          accountDetails.in_sps_license_expiry_date =
            accountDetails.in_sps_license_expiry_date !== null
              ? moment(accountDetails.in_sps_license_expiry_date).toDate()
              : null;
          accountDetails.in_sps_license_issue_date =
            accountDetails.in_sps_license_issue_date !== null
              ? moment(
                  accountDetails.in_sps_license_issue_date,
                  'YYYY-MM-DD',
                ).toDate()
              : null;
          let preferences = getCpaPreferences();
          if (!isUserEmployee()) {
            preferences = {
              ...preferences,
              entity_type: generateEntityTypeOptions(
                preferences,
                showIndividualAdditionInformationForm(
                  accountDetails,
                  preferences.individual_entity_type,
                ),
              ),
            };
          }
          if (
            accountDetails.phone_number &&
            accountDetails.phone_number !== null &&
            accountDetails.phone_number.indexOf('+') < 0
          ) {
            accountDetails.phone_number = `+1${accountDetails.phone_number}`;
          }
          if (
            accountDetails.fax_number &&
            accountDetails.fax_number !== null &&
            accountDetails.fax_number.indexOf('+') < 0
          ) {
            accountDetails.fax_number = `+1${accountDetails.fax_number}`;
          }
          setAccount({
            formdata: accountDetails,
            dependentsData: decryptDependentData(res.data.data.dependant),
            initialValues: accountDetails,
            accountId,
            isAccountIndividual: showIndividualAdditionInformationForm(
              accountDetails,
              preferences.individual_entity_type,
            ),
            bankAccountData,
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div data-testid="edit-account">
      {account.formdata ? (
        <NewAccount account={account} />
      ) : (
        <div id="loading" />
      )}
    </div>
  );
};

export default EditAccount;
