import {
  TablePaginationProps,
  Pagination as MuiPagination,
} from '@mui/material';
import {
  GridPagination,
  gridPageSelector,
  gridPageSizeSelector,
  gridRowCountSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid';

export function CustomPagination(props: any) {
  return (
    <GridPagination
      ActionsComponent={Pagination}
      {...props}
      labelDisplayedRows={() => ''}
    />
  );
}

function Pagination({
  onPageChange,
  className,
}: Pick<TablePaginationProps, 'onPageChange' | 'className'>) {
  const apiRef = useGridApiContext();
  const rowCount = useGridSelector(apiRef, gridRowCountSelector);
  const pageSize = useGridSelector(apiRef, gridPageSizeSelector);

  const page = useGridSelector(apiRef, gridPageSelector);

  return (
    <MuiPagination
      color="primary"
      className={className}
      count={rowCount === 0 ? page + 1 : Math.ceil(rowCount / pageSize)}
      page={page + 1}
      onChange={(event, newPage) => {
        onPageChange(event as any, newPage - 1);
      }}
    />
  );
}
