import { useState, useEffect } from 'react';
import Axios from 'axios';
import { PAYMENTS, STRIPE_PUBLIC_KEY, CUSTOMER } from 'constants/constants';
import { AlertMessage } from 'utilities/utils';
import {
  makeBillingRedirectURL,
  getInitialPaymentMethod,
} from './paymentModalUtils';

export default function usePaymentsModal({ data, updateTaskStatus }) {
  const [paymentMethod, setPaymentMethod] = useState(
    getInitialPaymentMethod(
      data.is_cc_payment_allowed,
      data.is_ach_payment_allowed,
    ),
  );
  const [customerSources, setCustomerSources] = useState([]);
  const [singleSource, setSingleSource] = useState();
  const [loading, setLoading] = useState(false);
  const [gettingCustomerData, setGettingCustomerData] = useState(true);
  const [showSources, setShowSources] = useState('');
  const [gettingPlaidPublicToken, setGettingPlaidPublicToken] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const mode =
    data.is_parent_task || (data.is_recurring && !data.is_parent_task)
      ? 'setup'
      : 'payment';
  // TODO: once backend change is done
  const taskId =
    data.is_recurring && !data.is_parent_task ? data.parent_task_id : data.id;

  // this is for getting existing account details
  async function getCustomerDetails() {
    try {
      const response = await Axios.get(`${CUSTOMER}/${data.account_id}`).then(
        (res) => res.data.sources,
      );
      if (response) {
        setCustomerSources(response);
      }
      setGettingCustomerData(false);
    } catch (error) {
      setGettingCustomerData(false);
      AlertMessage('error', error.response.data.message, 3000);
    }
  }

  async function saveCardDetails(sessionId) {
    try {
      const response = await await Axios.post(PAYMENTS, {
        session_id: sessionId,
        payment_method: paymentMethod,
        id: taskId,
        mode,
      }).then((res) => res.data);
      AlertMessage('success', response.message, 3000);
      updateTaskStatus('Review', '');
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getCustomerDetails();
    const parsedUrl = new URL(window.location.href);
    const sessionId = parsedUrl.searchParams.get('session_id');
    if (sessionId) {
      saveCardDetails(sessionId);
    }
    if (!window.Stripe) {
      const script = document.createElement('script');
      script.src = 'https://js.stripe.com/v3/';
      script.id = 'stripe';
      document.body.appendChild(script);
      script.onload = () => {
        console.log('Stripe is ready');
      };
    }
    if (!window.Plaid) {
      const script = document.createElement('script');
      script.src = 'https://cdn.plaid.com/link/v2/stable/link-initialize.js';
      script.id = 'plaid';
      document.body.appendChild(script);
      script.onload = () => {
        console.log('Plaid is ready');
      };
    }
  }, []);

  // for checkbox
  function handlePaymentMethod(e) {
    setPaymentMethod(e.target.value);
  }

  // for redirecting user to checkout page
  function handleCheckout(sessionId) {
    const stripe = window.Stripe(STRIPE_PUBLIC_KEY);
    stripe.redirectToCheckout({ sessionId }).then((result) => {
      AlertMessage('error', result.error.message, 3000);
    });
  }

  // for showing pliad interface for adding bank account
  function navigateToPlaid(linkToken) {
    const configs = {
      token: linkToken,
      onLoad() {
        // The Link module finished loading.
      },
      async onSuccess(public_token, metadata) {
        switch (metadata.accounts.length) {
          case 0:
            // Select Account is disabled: https://dashboard.plaid.com/link/account-select
            break;
          case 1:
            try {
              // update customer record with new bank account
              const response = await Axios.put(
                `${CUSTOMER}/${data.account_id}`,
                {
                  bank_account_id: metadata.account.id,
                  public_token: metadata.public_token,
                  method: 'ach',
                  taskId,
                  mode,
                },
              ).then((res) => res.data.sources);
              if (response) {
                setShowModal(true);
                setSingleSource(response);
                setShowSources('singleachsource');
                setCustomerSources([...customerSources, response]);
                setGettingPlaidPublicToken(false);
              }
            } catch (error) {
              setGettingPlaidPublicToken(false);
              AlertMessage('error', error.response.data.message, 3000);
            }
            break;
          default:
          // Multiple Accounts is enabled: https://dashboard.plaid.com/link/account-select
        }
      },
      // eslint-disable-next-line require-await
      async onExit(err) {
        setGettingPlaidPublicToken(false);
        if (err != null) {
          AlertMessage('error', err, 3000);
        }
      },
    };
    const linkHandler = window.Plaid.create(configs);
    linkHandler.open();
  }

  // for adding new bank account
  async function setUpACHandCard() {
    // TODO: okay, well what about NON recurring payments?
    const successURI = makeBillingRedirectURL(data, taskId);
    setGettingPlaidPublicToken(true);
    try {
      const response = await Axios.post(CUSTOMER, {
        id: data.account_id,
        payment_method: paymentMethod,
        url: successURI,
      });
      if (response.data.link_token) {
        navigateToPlaid(response.data.link_token);
      } else if (response.data.session_id) {
        window.location.href = response.data.session_id.url;
        handleCheckout(response.session_id);
      }
    } catch (err) {
      setGettingPlaidPublicToken(false);
      AlertMessage('error', err.response.data.message, 3000);
    }
  }

  // to getting checkout url
  async function handlePayButton() {
    if (paymentMethod === 'ach') {
      setShowSources('achsources');
    } else if (paymentMethod === 'checkout' && mode === 'setup') {
      setUpACHandCard();
    } else {
      try {
        const successURI = makeBillingRedirectURL(data, taskId);
        console.log('uri', successURI);
        setLoading(true);
        const response = await Axios.post(PAYMENTS, {
          id: taskId,
          url: successURI,
          payment_method: 'checkout',
        }).then((res) => res.data);
        if (response.session_id) {
          window.location.href = response.session_id.url;
          // handleCheckout(response.session_id);
        } else if (response.data.id) {
          handleCheckout(response.data.id);
        }
      } catch (error) {
        setLoading(false);
        AlertMessage('error', error.response.data.message, 3000);
      }
    }
  }

  // for ach payments
  async function handleACHPayment(sourceId) {
    setLoading(true);
    try {
      const response = await Axios.post(PAYMENTS, {
        id: taskId,
        payment_method: paymentMethod,
        source_id: sourceId,
      }).then((res) => res.data);
      if (response) {
        setShowModal(false);
        updateTaskStatus(
          'Review',
          response.payment_status ? response.payment_status : '',
        );
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
      AlertMessage('error', err.response.data.message, 3000);
    }
  }

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handlePayInvoiceBtn = (e) => {
    e.stopPropagation();
    if (data.is_cc_payment_allowed && data.is_ach_payment_allowed) {
      setShowModal(!showModal);
    } else if (data.is_cc_payment_allowed) {
      handlePayButton();
    } else if (data.is_ach_payment_allowed) {
      if (customerSources.length) {
        setShowModal(true);
        setShowSources('achsources');
      } else {
        setUpACHandCard();
      }
    }
  };

  return {
    singleSource,
    loading,
    gettingCustomerData,
    showSources,
    gettingPlaidPublicToken,
    showModal,
    mode,
    paymentMethod,
    customerSources,
    setShowSources,
    handlePaymentMethod,
    setUpACHandCard,
    handlePayButton,
    handleACHPayment,
    toggleModal,
    handlePayInvoiceBtn,
  };
}
