export const saveAccountListData = (data) => ({
  type: 'STORE_ACCOUNTS_LIST_DATA',
  data,
});

export const saveContactListData = (data) => ({
  type: 'STORE_CONTACTS_LIST_DATA',
  data,
});

export const resetAccountListData = (data) => ({
  type: 'RESET_ACCOUNTS_LIST_DATA',
  data,
});

export const resetContactListData = (data) => ({
  type: 'RESET_CONTACTS_LIST_DATA',
  data,
});

export const updateCheckedAccountListData = (data) => ({
  type: 'UPDATE_CHECK_LIST',
  data,
});
