/* eslint-disable no-nested-ternary */
/* eslint-disable no-script-url */
/* eslint-disable react/jsx-no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable consistent-return */
import React, { Component } from 'react';
import { withRouter } from 'utilities/withRouterWrapper';
import { Modal, ModalBody, Button } from 'reactstrap';
import Interweave from 'interweave';
import PdfViewer from 'components/PdfViewer/PdfViewer';
import * as TaskApi from 'api/TaskApi';
import TinymceEditor from 'components/Emails/components/TinymceEditior';
import transformInputFieldinInterweave from 'components/Tasks/InterweaveUtilities';

const noneStyle = { display: 'none' };
const blockStyle = { display: 'block' };
const isIE = /* @cc_on!@ */ false || !!document.documentMode;
// const contentCss = {
//   cke_editable: { lineHeight: 1.0 },
//   p: { margin: '0 0 0 0' },
// };
class SignatureModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      rejectionModal: false,
      rejectionReason: '',
      loading: false,
    };
  }

  handleChange(e) {
    this.setState({ rejectionReason: e.target.value });
  }

  checkboxtick = () => {
    const signDiv = document.getElementsByClassName('sign');
    const dateDiv = document.getElementsByClassName('currDate');
    if (signDiv && signDiv.length === 0) {
      alert('Something is wrong with the Letter. Please contact firm admin.');
      return false;
    }

    const nameDiv = document.getElementById('currName')
      ? document.getElementById('currName')
      : '';
    const emailDiv = document.getElementById('currEmail')
      ? document.getElementById('currEmail')
      : '';
    if (!this.state.checked) {
      for (let i = 0; i < signDiv.length; i += 1) {
        signDiv[
          i
        ].innerHTML = `<span style="font-family:always; font-size: 40px; font-weight:bold; line-height: 20px;">${this.props.data.homedata.uname}</span>`;
      }
      if (nameDiv) {
        nameDiv.innerHTML = `<span style="font-size: 20px;">${this.props.data.homedata.uname}</span>`;
      }
      if (emailDiv) {
        emailDiv.innerHTML = `<span style="font-size: 20px;">${this.props.data.homedata.email}</span>`;
      }

      let today = new Date();
      let dd = today.getDate();
      let mm = today.getMonth() + 1; // January is 0!
      const yyyy = today.getFullYear();

      if (dd < 10) {
        dd = `0${dd}`;
      }

      if (mm < 10) {
        mm = `0${mm}`;
      }

      today = `${mm}/${dd}/${yyyy}`;

      for (let i = 0; i < dateDiv.length; i += 1) {
        dateDiv[
          i
        ].innerHTML = `<span style="font-family:always; font-size: 40px; font-weight:bold; line-height: 20px;">${today}</span>`;
      }
    } else {
      signDiv.innerHTML = '';
      dateDiv.innerHTML = '';
      for (let i = 0; i < signDiv.length; i += 1) {
        signDiv[i].innerHTML = '';
      }
      for (let i = 0; i < dateDiv.length; i += 1) {
        dateDiv[i].innerHTML = '';
      }
      if (nameDiv) {
        nameDiv.innerHTML = '';
      }
      if (emailDiv) {
        emailDiv.innerHTML = '';
      }
    }
    this.setState({ checked: !this.state.checked });
  };

  handleCancelButton = () => {
    this.props.closeTemplate();
    this.setState({ checked: false });
  };

  signatureSubmit = () => {
    const { id } = this.props.params;
    const data = {
      task_id: id,
      content: document.getElementById('templateData')
        ? document.getElementById('templateData').innerHTML
        : '',
      // here we are not using state but ele id because we are creating some divs using check box click that are not saved in state
    };
    TaskApi.signDocument(this, data);
    setTimeout(() => {
      this.props.closeTemplate();
      this.setState({ checked: false });
    });
  };

  rejectTemplate = () => {
    const { id } = this.props.params;
    const data = {
      task_id: id,
      comment: this.state.rejectionReason,
      reject_task: true,
    };
    TaskApi.addComment(this, data);
    setTimeout(() => {
      this.setState({ rejectionModal: false }, () => {
        this.props.closeTemplate();
      });
    }, 100);
  };

  render() {
    const {
      data: { homedata },
    } = this.props;

    let tempPdfData = this.props.data.templatePdf;
    if (
      typeof tempPdfData === 'string' &&
      tempPdfData.indexOf('missing.png') > -1
    ) {
      tempPdfData = '/404';
    }
    return (
      <div>
        <div id="loading" style={this.state.loading ? blockStyle : noneStyle} />
        <Modal
          isOpen={this.props.data.showTemplate}
          className="customModal"
          backdrop={false}
        >
          <div>
            <div className="d-flex justify-content-between align-items-center ModalHeader">
              <div className="infoHeader">
                <i
                  className="icon-back-arrow"
                  onClick={this.handleCancelButton}
                  aria-hidden="true"
                />
                {this.props.doctype === 'engagement_letter'
                  ? 'Engagement Letter'
                  : 'Consent to Release Letter'}
              </div>
              <div className="ModalHeader--links">
                <div className="d-flex flex-nowrap btn--leftspace">
                  {this.props.toggleModal ? (
                    <a
                      href="javascript:;"
                      onClick={this.props.toggleModal}
                      className="btn btn-link btn-sm btn-outline-none pr-0"
                    >
                      <i
                        className={
                          this.props.data.modalState === 'Edit'
                            ? 'icon-edit'
                            : 'icon-open-eye'
                        }
                        aria-hidden="true"
                      />
                      {this.props.data.modalState}
                    </a>
                  ) : null}
                </div>
              </div>
              <button
                type="button"
                onClick={this.handleCancelButton}
                className="close"
                aria-label="Close"
              >
                <i className="icon-close2" aria-hidden="true" />
              </button>
            </div>
          </div>
          {/* CKEDITOR n template content here -------------------------------------------------------------------------------- */}
          <ModalBody
            style={{ maxHeight: 'calc(100vh - 150px)' }}
            className="overflow-y-scroll"
          >
            <div className="modalContent--wrap">
              <div className="modalContent--inner p-4">
                <div className="labelValue pdfOuter">
                  {!this.props.taskDetail.is_signed ? (
                    <div className="cpaLogo pb-3">
                      <img src={this.props.cpaLogo} alt="CPA Logo" />
                    </div>
                  ) : null}
                  {this.props.data.modalState === 'Edit' ? (
                    this.props.taskDetail.is_signed ? (
                      isIE ? (
                        <PdfViewer file={tempPdfData} />
                      ) : (
                        <iframe
                          title="iframe"
                          id="pdf_iframe"
                          width="100%"
                          height="600"
                          src={tempPdfData}
                        />
                      )
                    ) : (
                      <div
                        id="templateData"
                        className="pt-1 pb-1 pl-2 pr-2"
                        data-testid="aggrementDesp"
                      >
                        <Interweave
                          content={this.props.data.contentAfterChange}
                          allowAttributes
                          transform={transformInputFieldinInterweave}
                        />
                      </div>
                    )
                  ) : (
                    // <CKEditor
                    //   config={{
                    //     font_names:
                    //       'Arial;Times New Roman;Georgia;Courier New;Monospace; Source Sans Pro;Verdana;',
                    //     toolbar: [
                    //       {
                    //         name: 'basicstyles',
                    //         items: ['Bold', 'Italic', 'Underline'],
                    //       },
                    //       { name: 'colors', items: ['TextColor', 'BGColor'] },
                    //       { name: 'links', items: ['Link'] },
                    //       {
                    //         name: 'paragraph',
                    //         items: [
                    //           'JustifyLeft',
                    //           'JustifyCenter',
                    //           'JustifyRight',
                    //           'JustifyBlock',
                    //           'NumberedList',
                    //           'BulletedList',
                    //           'Outdent',
                    //           'Indent',
                    //         ],
                    //       },
                    //       { name: 'styles', items: ['Font'] },
                    //     ],
                    //     height: 500,
                    //     // toolbarLocation: 'bottom',
                    //     autoParagraph: false,
                    //     enterMode: 2,
                    //     allowedContent: true,
                    //     extraAllowedContent: 'span {*}(*)',
                    //     entities: false,
                    //     disabled: true,
                    //     contentsCss: contentCss,
                    //     toolbarCanCollapse: false,
                    //   }}
                    //   id="customeditor"
                    //   content={this.props.data.contentAfterChange}
                    //   events={{ change: this.props.handleContentChange }}
                    // />
                    <TinymceEditor
                      id="#signatureModalEditor"
                      value={this.props.data.contentAfterChange}
                      handleChange={this.props.handleContentChange}
                    />
                  )}
                </div>
                {/* Signing code for client starts here -------------------------------------------------------- */}
                {this.props.name === 'taskdetail' &&
                !homedata.is_employee &&
                this.props.taskDetail &&
                !this.props.taskDetail.is_signed &&
                this.props.data.taskStatus === 'Open' ? (
                  <div id="signing">
                    <div className="pl-2 form-group">
                      <p className="text-primary mb-3 mt-2">
                        You are digitally signing above document
                      </p>
                      <div className="check-container">
                        <label htmlFor="agree_to_doc">
                          <input
                            value="1"
                            name="user[remember_me]"
                            id="agree_to_doc"
                            type="checkbox"
                            checked={this.state.checked}
                            onChange={this.checkboxtick}
                          />
                          <i className="checkmark" />
                          <span className="pl-2">
                            Agreed to the above Document
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                ) : null}
                {/* -------------------------------------------------------------------------------------------- */}
                {/* All buttons in bottom ------------------------------------------------------------------------ */}
                {(this.props.taskDetail && this.props.taskDetail.is_signed) || // generate pdf, don't show bottom buttons
                (this.props.name === 'taskdetail' && homedata.is_employee) || // if emp n page is task detail
                this.props.data.taskStatus === 'Closed' ? (
                  '' // task status is closed
                ) : (
                  <div className="row">
                    <div className="col-12 form-group formbtn btn--leftspace">
                      <div className="btn-wrap btn--leftspace">
                        <button
                          onClick={this.handleCancelButton}
                          type="button"
                          className="btn btn-outline-light"
                          data-testid="task__cancel_btn"
                        >
                          Cancel
                        </button>
                        {this.props.name === 'taskdetail' ? (
                          ''
                        ) : (
                          <button
                            onClick={this.props.SaveDoc}
                            type="button"
                            className="btn btn-primary task-form-submit"
                            data-testid="task__submit_btn"
                          >
                            {this.props.data.modalState === 'Edit'
                              ? 'Save Document'
                              : 'Update and Save'}
                          </button>
                        )}
                        {this.props.name === 'taskdetail' &&
                        !homedata.is_employee &&
                        this.props.data.taskStatus === 'Open' ? (
                          <button
                            onClick={this.signatureSubmit}
                            disabled={!this.state.checked}
                            type="button"
                            className="btn btn-primary task-form-submit"
                            data-testid="task__submit_btn"
                          >
                            Submit
                          </button>
                        ) : null}
                        {this.props.name === 'taskdetail' &&
                        !homedata.is_employee &&
                        this.props.data.taskStatus === 'Open' ? (
                          <button
                            onClick={() =>
                              this.setState({ rejectionModal: true })
                            }
                            disabled={this.state.checked}
                            type="button"
                            className="btn btn-primary task-form-submit"
                            data-testid="task__submit_btn"
                          >
                            Reject
                          </button>
                        ) : null}
                      </div>
                    </div>
                  </div>
                )}
                {/* buttons -----------------------------------------------------------------*/}
              </div>
            </div>
          </ModalBody>
          {/* rejection modal starts hereee --------------------------------------------------*/}
          <Modal
            isOpen={this.state.rejectionModal}
            className="customModal customModal--center customModal--alert customModal--w500 customModal--nopadding"
          >
            <div className="ModalHeader">
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={() => this.setState({ rejectionModal: false })}
              >
                <i className="icon-close2" aria-hidden="true" />
              </button>
            </div>
            <ModalBody className="modalContent">
              <div className="modalContent--inner">
                <div className="modalContent--header text-large">
                  <h5 className="modalContent--header__heading">
                    Reject Document
                  </h5>
                </div>
                <div className="labelValue mt-3">
                  <input
                    onChange={(e) => this.handleChange(e)}
                    value={this.state.rejectionReason}
                    name="rejectionReason"
                    type="text"
                    className="form-control"
                    placeholder="Rejection Reason"
                    autoComplete="off"
                  />
                </div>
                <div className="row">
                  <div className="col-12 justify-content-end formbtn justify-content-end">
                    <div className="btn-wrap btn--leftspace">
                      <Button
                        className="btn btn-outline-light"
                        onClick={() => this.setState({ rejectionModal: false })}
                        data-testid="task__cancel_btn"
                      >
                        Cancel
                      </Button>
                      <Button
                        className="btn btn-primary"
                        disabled={!this.state.rejectionReason}
                        onClick={this.rejectTemplate}
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </ModalBody>
          </Modal>
        </Modal>
      </div>
    );
  }
}

export default withRouter(SignatureModal);
