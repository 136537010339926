import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { debounce } from 'lodash';
import { ReactComponent as IconMenu } from 'images/icons/archive.svg';

const StyledIcons = styled.div`
  &.button-icon {
    background: #ffffff;
    padding: 0.282rem 0.54rem;
    border: 2px solid #dfe3e8;
    border-radius: 2px;
    cursor: pointer;
    transition: all ease-in-out 350ms;
    &:hover,
    &:focus {
      border-color: #fff;
      background: #fff;
      box-shadow: 0 5px 25px rgb(0 0 0 / 10%);
      color: #212b36;
    }
    svg {
      fill: #919eab;
      width: 24px;
    }
  }
`;

const ArchiveBulkAction = ({
  handleEmailActions,
  actionObj,
  actionFromValue,
}) => {
  const handleClick = useMemo(
    () =>
      debounce(() => {
        handleEmailActions(actionFromValue ? actionObj.value : actionObj);
      }, 300),
    [actionFromValue, actionObj, handleEmailActions],
  );

  return (
    <StyledIcons
      className="button-icon"
      title={actionObj?.label}
      onClick={handleClick}
    >
      <IconMenu width="1rem" />
    </StyledIcons>
  );
};

export default ArchiveBulkAction;
