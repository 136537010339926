/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import Select, { components } from 'react-select';
import { selectTheme, selectStyles } from 'common/CustomFields';

const RenderOption = ({
  getStyles,
  Icon,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  data,
  ...rest
}) => {
  const [isActive, setIsActive] = useState(false);
  const onMouseDown = () => setIsActive(true);
  const onMouseUp = () => setIsActive(false);
  const onMouseLeave = () => setIsActive(false);

  // styles
  let bg = 'transparent';
  if (isFocused) bg = '#eee';
  if (isActive) bg = '#B2D4FF';

  const style = {
    alignItems: 'center',
    backgroundColor: bg,
    color: 'inherit',
    display: 'flex ',
  };

  // prop assignment
  const props = {
    ...innerProps,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
    style,
  };
  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={props}
    >
      <div>{data.label}</div>
      {/* <div>{data.entity_type}</div> */}
      {/* <input type="checkbox" defaultChecked={isSelected} /> */}
      {/* {children} */}
    </components.Option>
  );
};

const MultipleSelectCheckboxDropdown = ({
  options,
  handleChange,
  value,
  handleInputChange,
}) => (
  <Select
    defaultValue={[]}
    isMulti
    options={options}
    value={value}
    onChange={handleChange}
    onInputChange={handleInputChange}
    theme={selectTheme}
    styles={selectStyles}
    components={{
      Option: RenderOption,
      ClearIndicator: null,
    }}
  />
);
export default MultipleSelectCheckboxDropdown;
