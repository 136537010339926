// TODO: react-s-alert
import React, { useState, useEffect } from 'react';
import Axios from 'axios';
// import Alert from 'react-s-alert';
import { Link } from 'react-router-dom-latest';
import { PAYMENT_DETAILS } from 'constants/constants';
import PaymentModal from '../Tasks/PaymentsModal/PaymentsModal';

const EmailPaymentView = () => {
  const [paymentDetails, setPaymentDetails] = useState();

  const getPaymentDetails = async () => {
    try {
      let id = window.location.href.split('payment?id=')[1];
      if (id.includes('session_id')) {
        id = id.split('&session_id=')[0];
      } else if (id.includes('success')) {
        id = id.split('&success=')[0];
      }
      const payment = await Axios.get(
        `${PAYMENT_DETAILS}/${encodeURIComponent(id)}`,
      ).then((res) => res.data.data);
      setPaymentDetails(payment);
    } catch (error) {
      setPaymentDetails({ error: error.response.data.message });
    }
  };

  useEffect(() => {
    getPaymentDetails();
    if (window.Stripe === undefined) {
      const script = document.createElement('script');
      script.src = 'https://js.stripe.com/v3/';
      script.id = 'stripe';
      document.body.appendChild(script);
      script.onload = () => {
        console.log('Stripe is ready');
      };
    }
    if (window.Plaid === undefined) {
      const script = document.createElement('script');
      script.src = 'https://cdn.plaid.com/link/v2/stable/link-initialize.js';
      script.id = 'plaid';
      document.body.appendChild(script);
      script.onload = () => {
        console.log('Plaid is ready');
      };
    }
  }, []);

  return (
    <div
      style={{
        backgroundColor: 'rgba(243, 243, 243, 1.00)',
        minHeight: '100vh',
        padding: '0.5rem',
        width: '100%',
      }}
    >
      <div
        className="container"
        style={{ backgroundColor: '#fff', padding: '0 15px' }}
      >
        {/* <Alert html /> */}
        <div className="s-alert-wrapper" />
        {paymentDetails ? (
          <div className="pb-4">
            <div
              className="row justify-content-between align-items-center"
              style={{
                backgroundColor: '#213651',
                padding: '15px 25px',
                borderBottom: '3px solid #00afcc',
              }}
            >
              <div style={{ color: '#fff' }}>
                <h4 style={{ margin: 0 }}>{paymentDetails.subject}</h4>
              </div>
              <div>
                <img
                  src="https://turmericp.liscio.me/public/LiscioWhietLogo.png"
                  alt="Liscio Logo"
                />
              </div>
            </div>
            <div className="row justify-content-center mr-1 pl-3">
              <h2 style={{ color: '#00afcc' }} className="pt-3">
                {paymentDetails.cpa_name}
              </h2>
            </div>
            {/* for one time invoice */}
            {paymentDetails.payment_status === 'unpaid' &&
              !paymentDetails.is_parent_task && (
                <div className="row justify-content-center mr-1 pl-3">
                  <h5 className="pb-2">
                    Please review and press ‘Pay’ to pay the invoice amount
                  </h5>
                </div>
              )}
            {/* for recurring invoice */}
            {paymentDetails.is_parent_task &&
              !paymentDetails.is_payment_authorized &&
              (paymentDetails.is_cc_payment_allowed ||
                paymentDetails.is_ach_payment_allowed) && (
                <div className="row justify-content-center mr-1 pl-3">
                  <h5 className="pb-2">
                    Please review and press ‘Authorize’ to add payment method
                  </h5>
                </div>
              )}
            <div className="row justify-content-center">
              <div>
                {paymentDetails.is_parent_task && (
                  <div className="form-group form-inline-label col-12">
                    <label htmlFor="account" style={{ width: '120px' }}>
                      Start Date
                    </label>
                    <div className="labelValue">
                      <p>{paymentDetails.start_date}</p>
                    </div>
                  </div>
                )}
                {paymentDetails.is_parent_task && (
                  <div className="form-group form-inline-label col-12">
                    <label htmlFor="account" style={{ width: '120px' }}>
                      End Date
                    </label>
                    <div className="labelValue">
                      <p>{paymentDetails.end_date}</p>
                    </div>
                  </div>
                )}
                {paymentDetails.is_parent_task && (
                  <div className="form-group form-inline-label col-12">
                    <label htmlFor="account" style={{ width: '120px' }}>
                      Next Charge Date
                    </label>
                    <div className="labelValue">
                      <p>{paymentDetails.next_charge_date}</p>
                    </div>
                  </div>
                )}
                {paymentDetails.invoice_number && (
                  <div className="form-group form-inline-label col-12">
                    <label htmlFor="account" style={{ width: '120px' }}>
                      Invoice No
                    </label>
                    <div className="labelValue">
                      <p>{paymentDetails.invoice_number}</p>
                    </div>
                  </div>
                )}
                {paymentDetails.invoice_number && (
                  <div className="form-group form-inline-label col-12">
                    <label htmlFor="account" style={{ width: '120px' }}>
                      Payment Due Date
                    </label>
                    <div className="labelValue">
                      <p>{paymentDetails.payment_due_date.split('T')[0]}</p>
                    </div>
                  </div>
                )}
                {paymentDetails.invoice_amount && (
                  <div className="form-group form-inline-label col-12">
                    <label htmlFor="account" style={{ width: '120px' }}>
                      Amount
                    </label>
                    <div className="labelValue">
                      <p>
                        {Number(paymentDetails.invoice_amount).toLocaleString(
                          'en-US',
                          {
                            style: 'currency',
                            currency: 'USD',
                          },
                        )}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="row justify-content-center">
              {/* for one-time invoice */}
              {paymentDetails.payment_status === 'unpaid' &&
                !paymentDetails.is_parent_task && (
                  <PaymentModal
                    data={paymentDetails}
                    updateTaskStatus={getPaymentDetails}
                  />
                )}
              {/* when one time invoice is paid */}
              {paymentDetails.payment_status === 'paid' &&
                !paymentDetails.is_parent_task && (
                  <span
                    className="p-3 mb-2 bg-success text-white"
                    style={{ opacity: 0.8 }}
                  >
                    Invoice is Paid
                  </span>
                )}
              {/* when one time invoice payment status is pending */}
              {paymentDetails.payment_status === 'pending' &&
                !paymentDetails.is_parent_task && (
                  <span
                    className="p-3 mb-2 bg-success text-white"
                    style={{ opacity: 0.8 }}
                  >
                    Payment is Processing
                  </span>
                )}
              {/* when payment is Authorized for recurring parent  */}
              {paymentDetails.stripe_payment_authorization_id &&
                paymentDetails.is_parent_task &&
                paymentDetails.is_payment_authorized && (
                  <span
                    className="p-3 mb-2 bg-success text-white"
                    style={{ opacity: 0.8 }}
                  >
                    Payment is Authorized
                  </span>
                )}
              {/* when payment is not authorized */}
              {!paymentDetails.stripe_payment_authorization_id &&
                paymentDetails.is_parent_task &&
                !paymentDetails.is_payment_authorized && (
                  <PaymentModal
                    data={paymentDetails}
                    updateTaskStatus={getPaymentDetails}
                  />
                )}
              {/* when payment is failed for recurring parent */}
              {paymentDetails.stripe_payment_authorization_id &&
                paymentDetails.is_parent_task &&
                !paymentDetails.is_payment_authorized && (
                  <PaymentModal
                    data={paymentDetails}
                    updateTaskStatus={getPaymentDetails}
                  />
                )}
              {/* <PaymentModal data={paymentDetails} /> */}
              {paymentDetails.error && (
                <div className="p-3 mb-2 bg-danger text-white">
                  {paymentDetails.error}
                </div>
              )}
            </div>
            <div className="row justify-content-center mr-1 mt-4">
              <h6 className="">
                If you would like to review more invoice details, please log
                into Liscio below:
              </h6>
            </div>
            <div className="row justify-content-center">
              {paymentDetails.task_type_key &&
              paymentDetails.task_type_key === 'bill' ? (
                <Link to={`/billing/invoices/${paymentDetails.task_id}`}>
                  Login
                </Link>
              ) : (
                <Link to={`/task/detail/${paymentDetails.task_id}`}>Login</Link>
              )}
            </div>
          </div>
        ) : (
          <div id="loading" />
        )}
      </div>
    </div>
  );
};

export default EmailPaymentView;
