import React from 'react';
import InputField from 'common/InputField';
import { SelectField } from 'common/CustomFields';
import AccountDateField from 'common/AccountDateField';
import { getFirmType } from 'utilities/utils';
import AddressComponent from 'components/Contacts/ContactCreation/AddressComponent';
import { EncryptedField } from 'common/EncryptedField/EncryptedField';
import USStates from 'components/Contacts/ContactCreation/usastates.json';
import { spouseAddressFieldNames } from './AccountUtilities';
import { maxFutureLicenceExpiryDate, today } from './TempDateHelpers';

export const SpouseDetails = (props) => {
  const { formdata, errors } = props.data;

  return (
    <div>
      <div className="section-title mt-3 mb-3">
        <h5>
          {getFirmType() !== 'cpa'
            ? "Matter's Spouse Details"
            : 'Spouse Details'}
        </h5>
      </div>
      <div className="row">
        <InputField
          name="in_sps_first_name"
          label="First Name"
          className="col-sm-12 col-lg-4 col-md-4 form-group"
          onChange={props.onChange}
          value={formdata.in_sps_first_name}
          error={errors.in_sps_first_name}
          onBlur={props.onBlur}
        />
        <InputField
          name="in_sps_middle_name"
          label="middle Name"
          className="col-sm-12 col-lg-4 col-md-4 form-group"
          onChange={props.onChange}
          value={formdata.in_sps_middle_name}
          error={errors.in_sps_middle_name}
          onBlur={props.onBlur}
        />
        <InputField
          name="in_sps_last_name"
          label="last Name"
          className="col-sm-12 col-lg-4 col-md-4 form-group"
          onChange={props.onChange}
          value={formdata.in_sps_last_name}
          error={errors.in_sps_last_name}
          onBlur={props.onBlur}
        />
      </div>
      <div className="row">
        <EncryptedField
          recordId={formdata?.id}
          label="Taxpayer ID"
          recordType="Account"
          fieldName="in_sps_ssn"
          formFieldName="encryptedInSpsSsn"
          className="col-sm-12 col-lg-4 col-md-4 form-group"
          value={formdata?.encryptedInSpsSsn}
          error={errors?.encryptedInSpsSsn}
          onChange={props.handleEncryptedFieldChange}
          hasEncryptedValue={formdata?.encrypted_in_sps_ssn?.populated}
        />
        <AccountDateField
          label="Birthdate"
          name="in_sps_birthdate"
          onChange={props.handleDateField}
          value={formdata.in_sps_birthdate}
          error={errors.in_sps_birthdate}
          className="col-sm-12 col-lg-4 col-md-4 form-group"
          onBlur={props.onBlur}
          maxDate={today}
        />
      </div>
      <div className="row">
        <InputField
          name="in_sps_driver_license"
          label="Drivers License #"
          className="col-sm-12 col-lg-3 col-md-3 form-group"
          onChange={props.onChange}
          value={formdata.in_sps_driver_license}
          error={errors.in_sps_driver_license}
          type="text"
          onBlur={props.onBlur}
        />
        <AccountDateField
          label="Date Issued (mm/dd/yyyy)"
          name="in_sps_license_issue_date"
          onChange={props.handleDateField}
          value={formdata.in_sps_license_issue_date}
          error={errors.in_sps_license_issue_date}
          className="col-sm-12 col-lg-3 col-md-3 form-group"
          onBlur={props.onBlur}
        />
        <AccountDateField
          label="Date Expired (mm/dd/yyyy)"
          name="in_sps_license_expiry_date"
          onChange={props.handleDateField}
          value={formdata.in_sps_license_expiry_date}
          error={errors.in_sps_license_expiry_date}
          className="col-sm-12 col-lg-3 col-md-3 form-group"
          onBlur={props.onBlur}
          maxDate={maxFutureLicenceExpiryDate()}
          minDate={formdata.in_sps_license_issue_date}
        />
        <SelectField
          name="in_sps_license_state"
          label="State of issue"
          className="col-sm-12 col-lg-3 col-md-3 form-group"
          handleChange={(input) =>
            props.handleSelect('in_sps_license_state', input)
          }
          value={{
            value: formdata.in_sps_license_state,
            label: formdata.in_sps_license_state,
          }}
          error={errors.in_sps_license_state}
          options={USStates}
          onBlur={props.onBlur}
          clearable
        />
      </div>
      <AddressComponent
        name="spouse_address"
        data={formdata}
        errors={errors}
        fieldNames={spouseAddressFieldNames}
        onChange={(fieldname, input) =>
          props.handleAddressFieldChanges(input, fieldname, 'spouse_address')
        }
        handleAddressSelect={props.handleAddressSelect}
        onBlur={props.onBlur}
      />
    </div>
  );
};

export default SpouseDetails;
