import { useQuery } from '@tanstack/react-query';
import { getEmployees } from 'api/employees';
import { getEmployeesOrContacts } from 'api/employees-and-contacts';
import { getRequestFiltersEmployeesAndContacts } from 'api/request-filters';

export const useEmployees = () => {
  const result = useQuery({
    queryKey: ['getEmployees'],
    queryFn: getEmployees,
  });
  return result;
};

export const useEmployeesOrContacts = () => {
  const result = useQuery({
    queryKey: ['getEmployeesOrContacts'],
    queryFn: getEmployeesOrContacts,
  });
  return result;
};

export const useRequestFiltersEmployeesAndContacts = () => {
  const result = useQuery({
    queryKey: ['getRequestFiltersEmployeesAndContacts'],
    queryFn: getRequestFiltersEmployeesAndContacts,
  });
  return result;
};
