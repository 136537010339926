/* eslint-disable consistent-return */
import axios from 'axios';
import { AlertMessage, setUserInLocalStorage } from 'utilities/utils';
import { REMOVESPECIALCHAR } from 'utilities/regularexpressions';
import {
  UPDATE_CONTACT_AND_PHONE_API_PATH,
  GET_DATA_API_PATH,
  CHANGE_PASSWORD_API_PATH,
  HOME_API_PATH,
  MIXPANEL_SIGNUP_EVENT,
  MIXPANEL_LOGIN_EVENT,
} from 'constants/constants';
import {
  registerSuperProperties,
  trackMixpanelEvent,
} from 'utilities/mixpanelfn';
import { getAuthSession, setAuthSession } from 'auth/session';

export const sendSignUpEvent = () => {
  if (getAuthSession()) {
    // TODO: __HOMEDATA-AUDIT
    const request = axios.get(HOME_API_PATH);
    request.then((response) => {
      const stored = response.data.data;
      stored.is_multiple_cpa = response.data.is_multiple_cpa;
      stored.multiple_cpa_data = response.data.cpa_data;
      localStorage.setItem(
        'release',
        JSON.stringify(response.data.release_version),
      );
      // TODO: __HOMEDATA-AUDIT
      setUserInLocalStorage(stored);
      registerSuperProperties({});
      trackMixpanelEvent(MIXPANEL_SIGNUP_EVENT);
      // also send login event because we wont send login event in homeitems if local storage already has user details
      trackMixpanelEvent(MIXPANEL_LOGIN_EVENT);
    });
  }
};

export const getInviteData = (component, data) => {
  component.setState({ loading: true });
  const request = axios.post(GET_DATA_API_PATH, data);
  request
    .then((response) => {
      component.setState({ loading: false });
      if (response.status === 200) {
        const resData = response.data.data;
        if (
          resData.phone &&
          resData.phone !== null &&
          resData.phone.indexOf('+') < 0
        ) {
          resData.phone = `+1${REMOVESPECIALCHAR(resData.phone)}`;
        }
        component.setState({
          inviteDetails: resData,
          heading: `Welcome to ${resData.cpa_name}`,
        });
        if (!response.data.data.is_valid) {
          AlertMessage(
            'error',
            'Link expired. Please ask your accountant to re-invite you to Liscio.',
            800000000,
          );
        }
      }
    })
    .catch(() => {
      component.setState({ loading: false });
      return null;
    });
};

export const changePassword = (component, data) => {
  component.setState({ loading: true });
  const request = axios.post(CHANGE_PASSWORD_API_PATH, data);
  request
    .then((response) => {
      component.setState({ loading: false });
      if (response.data.status === 401) {
        const err = { password: response.data.message };
        component.setState({ formerrors: err });
        return false;
      }
      const authToken = response.data.auth_token;
      setAuthSession({ auth_token: authToken });
      if (response.data.status === 200) {
        AlertMessage('success', response.data.message, 3000);
        // SEND SIGNUP EVENT TO MIXPANEL
        sendSignUpEvent();
        if (
          component.state.inviteDetails.is_employee ||
          component.state.inviteDetails.current_user_role === 'specialist'
        ) {
          setTimeout(() => {
            window.location.href = '/dashboard';
          }, 2000);
        } else {
          localStorage.setItem(
            'contact_data',
            JSON.stringify(response.data.contact[0]),
          );
          const contactDetails = response.data.contact[0];
          const middleNme =
            contactDetails.contact.middle_name === 'null' ||
            !contactDetails.contact.middle_name
              ? ''
              : contactDetails.contact.middle_name;
          if (contactDetails.contact_phone) {
            let contact_phone = '';
            for (let i = 0; i < contactDetails.contact_phone.length; i += 1) {
              const phone = contactDetails.contact_phone[i];
              if (phone.phone_type === 'Mobile') {
                if (
                  phone.phone_number &&
                  phone.phone_number !== null &&
                  phone.phone_number.indexOf('+') < 0
                ) {
                  phone.phone_number = `+1${REMOVESPECIALCHAR(
                    phone.phone_number,
                  )}`;
                }
                contact_phone = phone;
                break;
              }
            }
            if (contact_phone !== '') {
              component.setState({
                phone: contact_phone.phone_number,
                phoneId: contact_phone.id,
                is_texting_allowed: contact_phone.is_texting_allowed,
              });
            }
          }
          component.setState({
            firstName: contactDetails.contact.first_name,
            lastName: contactDetails.contact.last_name,
            middleName: middleNme,
            contactId: contactDetails.contact.id,
          });
          setTimeout(() => {
            component.setState({
              activeStep: 'step2',
              subHeading: 'Confirm Your Details',
              step: 'Step 2 of 2',
            });
          }, 200);
        }
      } else {
        AlertMessage('error', response.data.message, 3000);
      }
    })
    .catch(() => {
      component.setState({ loading: false });
      return null;
    });
};

export const submitContactDetail = (component, data) => {
  component.setState({ loading: true });
  const request = axios.put(
    `${UPDATE_CONTACT_AND_PHONE_API_PATH}/${component.state.contactId}`,
    data,
  );
  request.then((response) => {
    if (response.data.status === 200) {
      if (
        navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/Windows Phone/i) ||
        navigator.userAgent.match(/iPhone/i)
      ) {
        component.setState({ showMobileModal: true, loading: false });
        if (
          navigator.userAgent.match(/Android/i) ||
          navigator.userAgent.match(/Windows Phone/i)
        ) {
          component.setState({ showAndroid: true, loading: false });
        }
      } else {
        window.location.href = '/dashboard';
      }
    } else {
      component.setState({ loading: false });
      AlertMessage('error', response.data.message, 3000);
    }
  });
};
