import { useState } from 'react';
import InputField from './InputField';

/**
 * Simple wrapper around the already terrible InputField component
 * to at least encapsulate password show/hide state
 */
export const PasswordInputField = ({ defaultShow = false, ...rest }) => {
  const [showPassword, setShowPassword] = useState(defaultShow);
  const toggleShow = () => setShowPassword((value) => !value);

  return (
    <InputField
      {...rest}
      passwordShow={showPassword ? 'password' : 'obscured'}
      showHide={toggleShow}
      type={showPassword ? 'text' : 'password'}
    />
  );
};
