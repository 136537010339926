import axios from 'axios';
import {
  GET_TASK_ACCOUNTS,
  HOME_API_PATH,
  SEARCH_TASK_ACCOUNTS,
} from 'constants/constants';

export async function fetchEntityTypesForDropdown() {
  try {
    // TODO: __HOMEDATA-AUDIT
    const preferencesResponse = await axios.get(HOME_API_PATH);
    return preferencesResponse.data?.data?.pref_type_options?.entity_type;
  } catch (e) {
    return console.error(e);
  }
}

export const fetchAccountsForDropdown = async (accountSearch) => {
  try {
    if (accountSearch) {
      const { data } = await axios.get(
        `${SEARCH_TASK_ACCOUNTS}/?keyword=${accountSearch}&message=true`,
      );

      return Array.isArray(data) ? data : [];
    }

    const {
      data: { accounts: rawAccounts },
    } = await axios.get(GET_TASK_ACCOUNTS);

    return rawAccounts || [];
  } catch (e) {
    return console.error(e);
  }
};
