import {
  GridColDef,
  GridPaginationModel,
  GridSortModel,
} from '@mui/x-data-grid';
import { UseQueryResult } from '@tanstack/react-query';
import {
  RequestType,
  WfRequestSortOption,
  WorkflowRequestsQueryQuery,
} from 'gql/graphql';
import { useEffect, useMemo, useState } from 'react';
import {
  FilterKey,
  useRequestsState,
} from 'components/Requests/state/request-state';
import { CustomPagination } from 'components/Requests/components/Tables/Components/CustomPagination';
import { LoadingOverlay } from 'components/Requests/components/Tables/Components/LoadingOverlay';
import { NoResultsOverlay } from 'components/Requests/components/Tables/Components/NoResultsOverlay';
import { useGetWorkflowCounts } from 'components/Requests/hooks/get-workflow-counts';
import { RequestsDataGridStyled } from 'components/Requests/components/Styles/Styles';
import { TableFilters } from 'components/Requests/components/Tables/Components/TableFilters/TableFilters';

export type RequestsTableProps = {
  query: UseQueryResult<WorkflowRequestsQueryQuery>;
  onRowClick: (id: string, type: RequestType) => void;
  paginationModel: GridPaginationModel;
  setPaginationModel: (model: GridPaginationModel) => void;
  tableName: string;
  columns: GridColDef[];
  requestKey: FilterKey;
};

export const RequestsTable = ({
  query,
  onRowClick,
  paginationModel,
  setPaginationModel,
  tableName,
  columns,
  requestKey,
}: RequestsTableProps) => {
  const { requestsCounts, isLoadingOrError } = useGetWorkflowCounts();
  const { setSortStatus, sortStatus } = useRequestsState();

  const queryData = useMemo(
    () => ({
      rows: query?.data?.workflowRequests?.collection,
      totalCount: query?.data?.workflowRequests?.totalCount,
    }),

    [query?.data],
  );

  const [totalCount, setTotalCount] = useState(-1);
  const requestCount = requestsCounts[requestKey];

  useEffect(() => {
    if (isLoadingOrError || requestCount === undefined) {
      return;
    }
    setTotalCount(requestCount);
  }, [isLoadingOrError, requestKey, requestCount]);

  if (totalCount === -1) {
    return <LoadingOverlay />;
  }

  const handleSortModelChange = (model: GridSortModel) => {
    if (model.length === 0 || !model[0].sort) {
      return;
    }
    const sortField =
      model[0].field === 'dueDate'
        ? WfRequestSortOption.DueDate
        : WfRequestSortOption.LastActivity;
    setSortStatus(requestKey, {
      sortField,
      sortDirection: model[0].sort,
    });
  };

  const columnVisibilityModel = {
    _owner: true,
    _menuOptions: true,
  };

  return (
    <>
      <TableFilters query={query} requestKey={requestKey} />
      <RequestsDataGridStyled
        hasFilters
        initialState={{
          sorting: {
            sortModel: [
              {
                field:
                  sortStatus?.[requestKey]?.sortField ===
                  WfRequestSortOption.DueDate
                    ? 'dueDate'
                    : 'updatedAt',
                sort:
                  sortStatus?.[requestKey]?.sortDirection === 'asc'
                    ? 'asc'
                    : 'desc',
              },
            ],
          },
          pagination: {
            paginationModel,
          },
        }}
        rows={queryData.rows || []}
        columns={columns}
        columnVisibilityModel={columnVisibilityModel}
        sortingMode="server"
        loading={query.isLoading || query.isFetching}
        onRowClick={(params) => {
          onRowClick(params.row.id, params.row.requestType);
        }}
        slots={{
          pagination: CustomPagination,
          loadingOverlay: LoadingOverlay,
          noResultsOverlay: () =>
            NoResultsOverlay({
              message: `No ${tableName}`,
              refetch: query.refetch,
            }),
          noRowsOverlay: () =>
            NoResultsOverlay({
              message: `No ${tableName}`,
              refetch: query.refetch,
            }),
        }}
        disableColumnMenu
        pageSizeOptions={[25, 50]}
        rowHeight={72}
        columnHeaderHeight={36}
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        rowCount={queryData.totalCount || 0}
        onSortModelChange={handleSortModelChange}
      />
    </>
  );
};
