import { Add } from '@mui/icons-material';
import { Box, Button, Divider, Drawer, Stack, Typography } from '@mui/material';
import BulkSelectContact from 'common/BulkSelectContact/BulkSelectContact';
import { AsigneeListContact } from 'components/Requests/components/SendRequest/components/AssigneeList/AssigneeList';
import { useState } from 'react';

type BulkContact = {
  sent_at: null | string;
  id: number;
  bfn: null | string;
  cpa_id: number;
  cpa_user_id: number;
  cpa_user_uuid: string;
  first_name: string;
  middle_name: string | null;
  last_name: string;
  job_title: string;
  communication_pref_type: string | null;
  is_active: boolean;
  created_at: string;
  updated_at: string;
  status: string;
  primary_email: string;
  external_source: string | null;
  external_contact_id: string | null;
  created_by: string;
  updated_by: string;
  last_invite_sent_at: string;
  activated_at: string;
  app_download_request_sent_at: string;
  is_app_downloaded: boolean;
  deactivated_at: string | null;
  uuid: string;
  account_uuid: string | null;
  account_name: string | null;
  entity_type: string | null;
  relationship_type: string | null;
};

export type BulkSelectButtonProps = {
  contactsUpdated: (contacts: AsigneeListContact[]) => void;
  contacts: AsigneeListContact[];
};

export const BulkSelectButton = ({
  contactsUpdated,
  contacts,
}: BulkSelectButtonProps) => {
  const [open, setOpen] = useState(false);
  const handleContactsChanged = (newList: { contact: BulkContact }[]) => {
    setOpen(false);
    const newContacts: AsigneeListContact[] = newList.map(({ contact }) => ({
      uuid: contact.uuid,
      label: `${contact.first_name} ${contact.last_name}`,
      avatar: '',
      initials: `${contact.first_name[0]}${contact.last_name[0]}`.toUpperCase(),
      type: 'contact',
      assigne_type: 'user',
      cpa_id: contact.cpa_user_id,
      cpa_user_uuid: contact.cpa_user_uuid,
      value: contact.id,
      accountId: contact.account_uuid,
    }));

    contactsUpdated(newContacts);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <>
      <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
        <Box width="calc(100vw - 200px)">
          <BulkSelectContact
            contacts={contacts}
            onClose={handleContactsChanged}
            onCancel={handleCancel}
          />
        </Box>
      </Drawer>
      <Stack gap="10px">
        <Divider />
        <Typography>Or send a bulk request to various people.</Typography>
        <Button
          startIcon={<Add />}
          variant="contained"
          color="primary"
          onClick={() => setOpen(true)}
        >
          Send Bulk Request
        </Button>
      </Stack>
    </>
  );
};
