/* eslint-disable jsx-a11y/label-has-associated-control */
import { useState } from 'react';
import Modal from '@mui/material/Modal';
import { SelectFieldEmail } from 'common/CustomFields';
import RadioButton from 'common/RadioButton';
import Button from 'common/Button/Button';
import CloseIcon from 'components/Email/components/icons/CloseIcon';
import { useAddToContact } from 'hooks/contacts-hooks';
import { useSearchTaskContactData } from 'hooks/search-task-contact-hook';
import { PhoneNumberField } from 'common/PhoneNumberField';
import { AlertMessage } from 'utilities/utils';
import { TOAST_FAILURE, TOAST_SUCCESS } from 'constants/constants';
import { StyledDiv } from './StyledDiv';

let contactSearchTime;

const AddToExistingContactModal = ({
  primaryEmail,
  phoneNumber,
  openModal,
  handleAddToExistingContact,
  handleNewlyCreatedRecord,
  onCreateOrUpdate,
}) => {
  const [formData, setFormData] = useState({
    emailType: 'Work',
    contact_phone: phoneNumber,
  });
  const [errors, setErrors] = useState({});
  const { contacts, setContacts, setQueryKeyword } = useSearchTaskContactData();
  const { mutate: addToContact } = useAddToContact();

  const handleClose = () => {
    handleAddToExistingContact(false);
  };

  const handleInputFieldChange = (inputvalue, fieldname) => {
    const value = inputvalue || '';
    setFormData({ ...formData, [fieldname]: value });
    setErrors({ ...errors, [fieldname]: '' });
  };

  const handleInputBlur = () => {
    let err = true;
    if (!formData.contact) {
      errors.contact = 'Required';
      err = false;
    }
    return err;
  };

  const handleContactChange = (fieldname, value) => {
    setFormData({ ...formData, [fieldname]: value });
  };

  const handleSaveNewlyCreatedRecord = () => {
    handleNewlyCreatedRecord({ label: primaryEmail, value: primaryEmail });
    handleClose();
  };

  const handleInputChange = (val) => {
    if (val) {
      if (val.length > 2) {
        setContacts('none');
        clearTimeout(contactSearchTime);
        contactSearchTime = setTimeout(() => {
          setQueryKeyword(val);
        }, 500);
      }
    } else {
      setContacts('all');
    }
  };

  const handleAddtoContact = (event) => {
    event.preventDefault();

    const validate = handleInputBlur();
    if (validate) {
      const mydata = {
        contact: {
          primary_email: formData.contact.email,
        },
      };

      if (phoneNumber) {
        mydata.contact_phone = [
          {
            type: 'Mobile',
            value: formData.contact_phone,
          },
        ];
      }

      if (primaryEmail) {
        mydata.contact_email = [
          {
            type: formData.emailType,
            value: primaryEmail,
          },
        ];
      }

      return addToContact(
        {
          contactId: formData.contact.id,
          contact: mydata,
        },
        {
          onSuccess(data) {
            if (data.message && (data.status === 422 || data.status === 401)) {
              AlertMessage(TOAST_FAILURE, data.message);
            } else {
              handleSaveNewlyCreatedRecord();
              onCreateOrUpdate({
                contact: data.contact[0],
                contactPhone: formData.contact_phone,
              });
              AlertMessage(TOAST_SUCCESS, data.message);
            }
          },
          onError(err) {
            AlertMessage(TOAST_FAILURE, err.message);
          },
        },
      );
    }

    return null;
  };

  return (
    <Modal open={openModal} onClose={handleClose}>
      <div className="Modal__Wrap" data-testid="addToExistingContactModal">
        <StyledDiv className="Modal__Body">
          <div className="Header__Container">
            <h4 className="Header__Text"> Add to Existing Contact </h4>
            <CloseIcon
              handleClose={handleClose}
              classname="close Button__Close"
            />
          </div>
          <div className="Input__Container">
            <form>
              <div className={`${phoneNumber ? '' : 'RowWrap'}`}>
                {phoneNumber && (
                  <>
                    <PhoneNumberField
                      label="Mobile Number *"
                      placeholder="Mobile number"
                      error={errors.phoneNumber}
                      value={phoneNumber}
                      name="contact_phone"
                      onChange={handleInputFieldChange}
                      forSMS
                    />
                    <br />
                  </>
                )}
                <SelectFieldEmail
                  name="contact"
                  label={
                    phoneNumber ? `Add mobile number to contact:` : 'Contact *'
                  }
                  value={formData.contacts}
                  handleChange={(input) =>
                    handleContactChange('contact', input)
                  }
                  handleInputChange={handleInputChange}
                  options={contacts}
                  error={errors.contact}
                  className="InputInr"
                  from="email"
                  placeholder="Select Contact ..."
                />
              </div>
            </form>
          </div>
          {primaryEmail && (
            <div className="Input__Container Input__Radio">
              <label>Email Type *</label>
              <div className="RadioWrap">
                <RadioButton
                  checked={formData.emailType === 'Work'}
                  onChange={handleInputFieldChange}
                  buttonFor="work"
                  buttonValue="Work"
                  buttonField="emailType"
                />
                <RadioButton
                  checked={formData.emailType === 'Home'}
                  onChange={handleInputFieldChange}
                  buttonFor="home"
                  buttonValue="Home"
                  buttonField="emailType"
                />
              </div>
            </div>
          )}
          <div className="Button__Container">
            <Button
              variant="secondary"
              onClick={handleClose}
              size="small"
              label="Cancel"
            />
            <Button
              variant="primary"
              onClick={handleAddtoContact}
              size="small"
              label="Update"
            />
          </div>
        </StyledDiv>
      </div>
    </Modal>
  );
};

export default AddToExistingContactModal;
