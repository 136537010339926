import {
  InputLabel,
  InputLabelProps,
  Typography,
  styled,
  useTheme,
} from '@mui/material';

export const InputLabelStyled = styled(
  ({ children, ...props }: InputLabelProps) => {
    const theme = useTheme();
    return (
      <InputLabel
        sx={{ transform: 'none', transition: 'none' }}
        shrink
        {...props}
      >
        <Typography
          sx={{
            ...theme.typography.body2,

            fontSize: '.75rem',
            fontWeight: 700,
          }}
        >
          {children}
        </Typography>
      </InputLabel>
    );
  },
)(() => ({}));
