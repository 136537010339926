import { useQueryClient } from '@tanstack/react-query';
import {
  UpdateWorkflowRequestMutationVariables,
  WorkflowRequestFragment,
  WorkflowSignableDocumentItem2Fragment,
  WorkflowWithoutSectionsFragment,
} from 'gql/graphql';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
  workflowRequestQueryDocument,
  workflowRequestsQueryDocument,
} from 'api/workflow-requests';
import {
  useAssignEmployeeWorkflowRequest,
  useUpdateWorklowRequest,
  useDispatchWorkflowRequest,
  useCreateWorkflowRequest,
  useBulkSendRequests,
  useDeleteWorkflowRequest,
} from 'hooks/workflow-request-hooks';
import Send from 'components/Requests/components/SendRequest/components/Send/Send';
import { TOAST_FAILURE } from 'constants/constants';
import { AlertMessage } from 'utilities/utils';
import { AsigneeListContact } from './components/AssigneeList/AssigneeList';

export type SendRequestProps = {
  workflow?: WorkflowWithoutSectionsFragment | null;
  workflowRequest?: WorkflowRequestFragment | null;
  onClose: () => void;
};

export default function SendRequest(props: SendRequestProps) {
  const { workflow: _workflow, workflowRequest, onClose } = props;
  const queryClient = useQueryClient();
  const { mutate: assignEmployeeWorkflowRequestMutation } =
    useAssignEmployeeWorkflowRequest();
  const { mutate: updateWorkflowRequestMutation } = useUpdateWorklowRequest();
  const { mutate: dispatchWorkflowRequestMutation } =
    useDispatchWorkflowRequest();
  const { mutate: createWorkflowRequestMutation } = useCreateWorkflowRequest();
  const { mutate: bulkSendRequestsMutation } = useBulkSendRequests();
  const { mutate: deleteWorkflowRequestMutation } = useDeleteWorkflowRequest();
  const workflowRequestId = useRef<string | null>(null);
  const workflow = _workflow || workflowRequest?.workflow;
  const [employees, setEmployees] = useState<AsigneeListContact[]>([]);
  const [contacts, setContacts] = useState<AsigneeListContact[]>([]);
  const [isBulk, setIsBulk] = useState(false);

  const validCompletion = useMemo(
    () =>
      workflowRequest?.requestSections?.every((section) =>
        section.requestItems?.every((item) => {
          const complete = (
            item.responseItem
              ?.workflowItem2 as WorkflowSignableDocumentItem2Fragment
          )?.esignAgreement?.signatories?.every((signer) =>
            Boolean(signer.contact?.id || signer.signOnSameDevice),
          );
          if (complete === false) {
            return false;
          }
          return true;
        }),
      ),
    [workflowRequest],
  );

  useEffect(() => {
    if (!workflowRequest) {
      return;
    }
    setEmployees(
      workflowRequest.assignedEmployees?.map((employee) => ({
        value: Number(employee.legacyId),
        label: `${employee.firstName} ${employee.lastName}`,
        initials: `${employee.firstName ? employee.firstName[0] : ''}${
          employee.lastName ? `${employee.lastName[0]}` : ''
        }`.toUpperCase(),
        type: 'emp',
        assigne_type: 'user',
        uuid: employee.id,
        avatar: '',
      })) || [],
    );
    setContacts(
      workflowRequest.assignedContacts?.map((contact) => ({
        value: Number(contact.legacyId),
        label: `${contact.firstName} ${contact.lastName}`,
        initials: `${contact.firstName ? contact.firstName[0] : ''}${
          contact.lastName ? `${contact.lastName[0]}` : ''
        }`.toUpperCase(),
        type: 'contact',
        assigne_type: 'user',
        uuid: contact.id,
        avatar: '',
        cpaUserId: contact.cpaUser?.id,
      })) || [],
    );
    workflowRequestId.current = workflowRequest?.id || null;
  }, [workflowRequest]);

  useEffect(() => {
    if (workflowRequest || workflowRequestId.current || !workflow) {
      return;
    }
    createWorkflowRequestMutation(
      {
        workflowId: workflow.id,
        title: workflow.title,
      },
      {
        onSuccess: (response) => {
          if (response?.createWorkflowRequest?.workflowRequest?.id) {
            workflowRequestId.current =
              response.createWorkflowRequest.workflowRequest.id;
          }
        },
      },
    );
  }, [
    workflow,
    workflowRequestId,
    createWorkflowRequestMutation,
    workflowRequest,
  ]);

  const handleAssignmentChanged = (options: {
    newContacts?: AsigneeListContact[];
    newEmployees?: AsigneeListContact[];
    accountId?: string;
    isBulkAssignment?: boolean;
  }) => {
    const { newContacts, newEmployees, accountId, isBulkAssignment } = options;

    if (isBulkAssignment) {
      setIsBulk(true);
    }

    if (newContacts) {
      if (isBulk && newContacts.length === 0) {
        setIsBulk(false);
      }

      setContacts(newContacts);
    }

    if (!isBulkAssignment && (newContacts || accountId !== undefined)) {
      updateWorkflowRequest({
        assignedContactIds: newContacts?.map((contact) => contact.uuid),
        legacyAssignedAccountId: accountId,
      });
    }
    if (newEmployees) {
      setEmployees(newEmployees);
      if (isBulkAssignment) {
        return;
      }
      assignEmployeeWorkflowRequestMutation(
        {
          workflowRequestId: workflowRequestId.current,
          assignedEmployeeIds: newEmployees.map(
            (employee) => `${employee.value}`,
          ),
        },
        {
          onSettled: () => {
            queryClient.invalidateQueries({
              queryKey: [
                (workflowRequestsQueryDocument.definitions[0] as any).name
                  .value,
              ],
            });
            queryClient.invalidateQueries({
              queryKey: [
                (workflowRequestQueryDocument.definitions[0] as any).name.value,
              ],
            });
          },
        },
      );
    }
  };

  const updateWorkflowRequest = (
    variables: Partial<UpdateWorkflowRequestMutationVariables>,
  ) => {
    if (isBulk) {
      return;
    }
    updateWorkflowRequestMutation(
      {
        workflowRequestId: workflowRequestId.current,
        ...variables,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: [
              (workflowRequestsQueryDocument.definitions[0] as any).name.value,
            ],
          });
          queryClient.invalidateQueries({
            queryKey: [
              (workflowRequestQueryDocument.definitions[0] as any).name.value,
            ],
          });
        },
      },
    );
  };

  const handleOnSend = (
    variables?: Partial<UpdateWorkflowRequestMutationVariables>,
  ) => {
    if (isBulk) {
      sendBulkRequest(variables);
      return;
    }

    sendSingleRequest();
  };

  const sendSingleRequest = () => {
    dispatchWorkflowRequestMutation(
      {
        workflowRequestId: workflowRequestId.current,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: [
              (workflowRequestsQueryDocument.definitions[0] as any).name.value,
            ],
            refetchType: 'all',
          });
          onClose();
        },
        onError: () => {
          AlertMessage(
            TOAST_FAILURE,
            'Something went wrong when sending the Request.',
          );
        },
      },
    );
  };

  const sendBulkRequest = (
    variables?: Partial<UpdateWorkflowRequestMutationVariables>,
  ) => {
    bulkSendRequestsMutation(
      {
        workflowId: workflow?.id,
        recipientList: contacts.map((contact) => ({
          cpaUserUuid: `${contact.cpa_user_uuid}`,
          accountId: `${contact.accountId}`,
        })),
        ...variables,
      },
      {
        onSuccess: () => {
          deleteWorkflowRequestMutation({
            workflowRequestId: workflowRequestId.current,
          });
          queryClient.invalidateQueries({
            queryKey: [
              (workflowRequestsQueryDocument.definitions[0] as any).name.value,
            ],
            refetchType: 'all',
          });
          onClose();
        },
        onError: () => {
          AlertMessage(
            TOAST_FAILURE,
            'Something went wrong when sending the Request.',
          );
        },
      },
    );
  };

  return (
    <Send
      title={workflowRequest?.title || workflow?.title || ''}
      description={workflow?.description || ''}
      onUpdate={updateWorkflowRequest}
      onSend={handleOnSend}
      requestAccountId={workflowRequest?.assignedAccount?.legacyId}
      requestContacts={contacts}
      requestEmployees={employees}
      rpaRequest={
        workflowRequest?.rpaDocuments != null &&
        workflowRequest?.rpaDocuments?.length !== 0
      }
      onAssignmentChanged={handleAssignmentChanged}
      requestDueDate={workflowRequest?.dueDate}
      isBulk={isBulk}
      disableSend={!validCompletion}
      workflowType={workflow?.type}
    />
  );
}
