/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import { Tooltip, TooltipProps, styled, tooltipClasses } from '@mui/material';
import { ReactElement } from 'react';

interface Props {
  message?: string;
  children: ReactElement;
}

const SMSTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#333',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#333',
    fontSize: 13,
    padding: 10,
  },
});

export default function SMSReminderTooltip({ message = '', children }: Props) {
  return (
    <SMSTooltip
      title={message}
      placement="top"
      arrow
      PopperProps={{
        popperOptions: {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -4],
              },
            },
          ],
        },
      }}
    >
      {children}
    </SMSTooltip>
  );
}
