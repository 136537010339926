import { Box } from '@mui/material';
import { REQUESTS_PAGE_TITLES } from 'components/Requests/requests.constants';
import { useEffect } from 'react';
import { RpaDocumentStatus } from 'gql/graphql';
import { usePagination } from 'components/Requests/components/RequestsBuilder/hooks/pagination';
import { useRpaDocumentsQuery } from 'hooks/rpa-documents-processing-hooks';
import { RpaDocumentsProcessingTable } from 'components/Requests/components/Tables/Components/RpaDocumentsProcessingTable';

export const RpaDocumentsProcessingView = () => {
  useEffect(() => {
    document.title = REQUESTS_PAGE_TITLES.processing;
  }, []);

  const { paginationModel, setPaginationModel } = usePagination();

  const rpaDocumentsQuery = useRpaDocumentsQuery(
    {
      status: RpaDocumentStatus.Processing,
      perPage: paginationModel.pageSize,
      page: paginationModel.page + 1,
      sortDirection: 'desc',
    },
    {
      staleTime: 0,
    },
  );

  return (
    <Box>
      <RpaDocumentsProcessingTable
        query={rpaDocumentsQuery}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
      />
    </Box>
  );
};
