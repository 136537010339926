import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
  Typography,
} from '@mui/material';
import { WorkflowRequest } from 'gql/graphql';
import { useSendLiscioMessage } from 'hooks/messages-hooks';
import { useEffect, useRef, useState } from 'react';

type ReOpenDialogProps = {
  open: boolean;
  onClose: (event: React.MouseEvent) => void;
  requestId: string;
  requestTitle: string;
  assignedContacts: WorkflowRequest['assignedContacts'];
  onMessageSentSuccess: () => any;
  onMessageSentError: () => any;
};

export const ReOpenDialog = ({
  open,
  onClose,
  requestId,
  requestTitle,
  assignedContacts,
  onMessageSentError,
  onMessageSentSuccess,
}: ReOpenDialogProps) => {
  const [userMessageText, setUserMessageText] = useState<string | null>(null);
  const [sendMessageBody, setSendMessageBody] =
    useState<SendLiscioMessagePayload | null>(null);
  const [messageIdSuccess, setMessageIdSuccess] = useState(false);

  // Ref to store the click event for the callbacks to RequestOptionsMenu
  const clickEventRef = useRef(null);

  const { mutate: sendMessage, isLoading } = useSendLiscioMessage();

  const handleOnClose = (event: React.MouseEvent) => {
    setUserMessageText(null);
    setSendMessageBody(null);
    onClose(event);
  };

  const handleUserMessageInputChange = (event: React.ChangeEvent) => {
    setUserMessageText((event.target as HTMLInputElement).value);
  };

  const handleReOpenButtonClick = (event: React.MouseEvent<Element>) => {
    event.stopPropagation();

    const getParticipants = assignedContacts?.length
      ? assignedContacts.map((contact) => ({
          id: Number(contact.cpaUser?.id),
          is_recipient: true,
          type: 'user',
        }))
      : [];

    const messageContent = `<div>
      ${userMessageText}
      <br/>
      <br/>
      <a href="/requests/${requestId}">Click here to view the ${requestTitle} request</a>
      </div>`;

    const createMessageBody = {
      thread: {
        is_notify_via_text: false,
        status: 'Draft',
        title: `${requestTitle} was Re-Opened`,
      },
      messages: {
        status: 'Draft',
      },
      participants: getParticipants,
    };

    // First call creates the message thread to get the message_id and message_thread_id
    sendMessage(createMessageBody, {
      onError: () => {
        onMessageSentError();
        setMessageIdSuccess(false);
      },
      onSettled: (response) => {
        if (!response) {
          return;
        }
        setSendMessageBody({
          ...createMessageBody,
          id: response.message_id,
          thread: { ...createMessageBody.thread, status: 'Sent' },
          messages: {
            status: 'Sent',
            message_thread_id: response.message_thread_id,
            content: messageContent,
          },
        });
        setMessageIdSuccess(true);
      },
    });
  };

  // Second call actually sends the message to the participants
  useEffect(() => {
    if (sendMessageBody?.id && messageIdSuccess) {
      sendMessage(sendMessageBody, {
        onError: () => {
          onMessageSentError();
        },
        onSuccess: () => {
          onMessageSentSuccess();
          handleOnClose(clickEventRef.current!);
        },
      });
    }
    // important to trigger the effect only on sendMessageBody/messageIdSuccess changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendMessageBody, messageIdSuccess]);

  return (
    <Dialog open={open} onClose={handleOnClose} maxWidth="xs">
      <DialogContent>
        <DialogContentText>
          <Typography variant="body1" mb={2} fontWeight="bold">
            Please provide a reason for re-opening this request
          </Typography>
          <Typography variant="body1">
            All assignees on this request will receive the following as a
            message, so consider referencing the specific items which require
            their response or review.
          </Typography>
          <TextField
            multiline
            rows={4}
            fullWidth
            onChange={handleUserMessageInputChange}
            disabled={isLoading}
            InputProps={{
              sx: {
                ariaLabel: 'Re-Open Message',
                marginTop: '16px',
                backgroundColor: 'common.white',
                padding: '4px 0px 5px',
                '& .MuiInputBase-inputMultiline': { border: 'none' },
              },
            }}
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          ref={clickEventRef}
          variant="contained"
          color="secondary"
          onClick={handleOnClose}
          disabled={isLoading}
        >
          cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleReOpenButtonClick}
          disabled={!userMessageText || isLoading}
          sx={{ minWidth: '74px' }}
        >
          {isLoading ? (
            <CircularProgress color="inherit" size={20} />
          ) : (
            'Re-open'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
