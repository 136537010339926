import { Clear } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { useDraftsBulkSend } from 'components/Requests/state/drafts-bulk-send-state';

export const RemoveBulkSendDraftCell = ({ row }: { row: any }) => {
  const { removeSelectedDraft } = useDraftsBulkSend();

  const handleRemove = () => {
    removeSelectedDraft(row.id);
  };

  return (
    <Tooltip title="Remove this draft from the bulk send list" placement="top">
      <Clear
        fontSize="small"
        color="action"
        onClick={(e: any) => {
          e.stopPropagation();
          handleRemove();
        }}
      />
    </Tooltip>
  );
};
