import React, { useState } from 'react';
import Button from 'common/Button/Button';
import styled from '@emotion/styled';
import NewEmailModal from './NewEmailModal';

const EmailMainBtnNewEmailWrap = styled.div`
  button {
    height: 36px;
  }
`;

const ComposeEmail = ({ setLoading }) => {
  const [newEmailModal, setNewEmailModal] = useState(false);

  function handleNewEmailModal(val) {
    setNewEmailModal(val);
  }

  return (
    <EmailMainBtnNewEmailWrap className="EmailMain__BtnNewEmailWrap">
      <Button
        label="+ Email"
        type="button"
        variant="contained"
        onClick={() => handleNewEmailModal(true)}
        size="small"
      />
      {newEmailModal && (
        <NewEmailModal
          setLoading={setLoading}
          isOpen={newEmailModal}
          handleNewEmailModal={handleNewEmailModal}
        />
      )}
    </EmailMainBtnNewEmailWrap>
  );
};

export default ComposeEmail;
