import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import LDClient from 'launchdarkly-js-client-sdk';

export const getAsyncLaunchDarklyProvider = async () => {
  const clientSideID = process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID || '';
  const context = {
    kind: 'multi',
    app: {
      key: 'liscio-refactor',
    },
    firm: {
      key: window?.location?.origin,
    },
  };
  const options = {
    streaming: true,
    bootstrap: 'localStorage',
  } as const;

  const ldClient = LDClient.initialize(clientSideID, context, options);

  const LDProvider = await asyncWithLDProvider({
    clientSideID,
    ldClient,
    context,
    options,
  });

  return LDProvider;
};
