import React, { useState } from 'react';
import { Carousel, CarouselItem, CarouselControl, Modal } from 'reactstrap';

const toDataURL = (url) =>
  fetch(url)
    .then((response) => response.blob())
    .then((blob) => URL.createObjectURL(blob));

const ImagePreview = (props) => {
  const selelctedImage = props.items.findIndex(
    (item) => item.id === props.selectedImageId,
  );
  const [activeIndex, setActiveIndex] = useState(selelctedImage);
  const [title, setTitle] = useState(props.items[selelctedImage].alt);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === props.items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
    setTitle(props.items[nextIndex].alt);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === 0 ? props.items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
    setTitle(props.items[nextIndex].alt);
  };

  const slides = props.items.map((item) => (
    <CarouselItem
      className="custom-tag"
      tag="div"
      key={item.id}
      onExiting={() => setAnimating(true)}
      onExited={() => setAnimating(false)}
    >
      <img src={item.src} alt={item.altText} />
    </CarouselItem>
  ));

  const downloadImage = async (url, fileName) => {
    const a = document.createElement('a');
    a.href = await toDataURL(url);
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <Modal isOpen className="Md-full-width modal-FullH" toggle={props.toggle}>
      <div className="d-flex align-items-center pt-2 pb-2">
        <div className="col">
          <h5 className="mb-0">{title || 'Image Preview'}</h5>
        </div>
        <button
          type="button"
          className="btn-link btn--onlyicon btn btn-secondary"
          onClick={() =>
            downloadImage(
              props.items[activeIndex].src,
              props.items[activeIndex].name,
            )
          }
        >
          <i className="icon-download2" aria-hidden="true" />
        </button>
        <button
          type="button"
          className="btn-link btn--onlyicon btn btn-secondary line-right btn btn-secondary"
          onClick={props.toggle}
          data-tip
          data-for="close"
        >
          <i className="icon-close2" />
        </button>
      </div>
      <Carousel
        activeIndex={activeIndex}
        next={next}
        previous={previous}
        interval={false}
        slide={false}
      >
        {slides}
        <CarouselControl
          direction="prev"
          directionText="Previous"
          onClickHandler={previous}
        />
        <CarouselControl
          direction="next"
          directionText="Next"
          onClickHandler={next}
        />
      </Carousel>
    </Modal>
  );
};

export default ImagePreview;
