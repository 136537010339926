/* eslint-disable */
import React, { Component } from 'react';
import $ from 'jquery';
import './css/tipsy.css';
import './css/main.css';
import {
  universalPreloader,
  universalPreloaderRemove,
  rotate,
  dogRun,
  dogTalk,
  // dogChanger,
} from './scripts/custom-scripts.js';
import { disable } from 'promise/lib/rejection-tracking';
import { IP_REGEX } from 'utilities/regularexpressions';
import { getSiteHostname } from 'utilities/utils';

class InvalidUrl extends React.Component {
  componentDidMount() {
    if (location.pathname.includes('glitch')) {
      if (IP_REGEX.test(getSiteHostname())) {
        location.href = 'https://app.liscio.me';
      }
    }
    universalPreloader();
    this.handleLoad();
  }

  handleLoad = () => {
    universalPreloaderRemove();
    rotate();
    dogRun();
    dogTalk();
  };

  render() {
    return (
      <div className="h-100 pt-4 invalidWrap">
        <div id="universal-preloader">
          <div className="preloader">
            <img
              src={require('./images/universal-preloader.gif')}
              alt="universal-preloader"
              className="universal-preloader-preloader"
            />
          </div>
        </div>
        <div id="wrapper" className="pt-5">
          <div className="row">
            <div className="col-7">
              <div className="graphic">
                <img src={require('./images/404.png')} alt="404" />
              </div>
            </div>
            <div className="col-5">
              <div className="top-left">
                <div className="not-found-text">
                  <h2 className="text-white">
                    <img alt="danger" src={require('./images/danger.png')} />
                    There’s been a glitch…
                  </h2>
                  <p>
                    We're not quite sure what went wrong.
                    <br />
                    You can go back or try logging in at{' '}
                    <a href="https://app.liscio.me/">app.liscio.me</a>.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="planet">
            <div className="dog-wrapper">
              <div className="dog" />
              <div className="dog-bubble" />
              <div className="bubble-options">
                <p className="dog-bubble">
                  Are you lost, bud? No worries, I'm an excellent guide!
                </p>
                <p className="dog-bubble">
                  <br />
                  Arf! Arf!
                </p>
                <p className="dog-bubble">
                  <br />
                  Don't worry! I'm on it!
                </p>
                <p className="dog-bubble">
                  I wish I had a cookie
                  <br />
                  <img
                    style={{ marginTop: '8px' }}
                    src={require('./images/cookie.png')}
                    alt="cookie"
                  />
                </p>
                <p className="dog-bubble">
                  <br />
                  Geez! This is pretty tiresome!
                </p>
                <p className="dog-bubble">
                  <br />
                  Am I getting close?
                </p>
                <p className="dog-bubble">
                  Or am I just going in circles? Nah...
                </p>
                <p className="dog-bubble">
                  <br />
                  OK, I'm officially lost now...
                </p>
                <p className="dog-bubble">
                  I think I saw a <br />
                  <img
                    style={{ marginTop: '8px' }}
                    src={require('./images/cat.png')}
                    alt="cat"
                  />
                </p>
                <p className="dog-bubble">
                  What are we supposed to be looking for, anyway? @_@
                </p>
              </div>
            </div>
            <img src={require('./images/planet.png')} alt="planet" />
          </div>
        </div>
      </div>
    );
  }
}

export default InvalidUrl;
