const initialState = {
  filesData: {},
  previewFileDetail: {},
  previewFileModal: false,
};

// eslint-disable-next-line default-param-last
export default function uploadFilesReducer(state = initialState, action) {
  switch (action.type) {
    case 'HANDLE_FILES_DATA': {
      return { ...state, filesData: action.data };
    }
    case 'PREVIEW_FILE': {
      return {
        ...state,
        previewFileDetail: action.data,
        previewFileModal: action.previewmodal,
      };
    }
    default:
      return state;
  }
}
