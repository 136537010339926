import React, { Component } from 'react';
import TermsOfService from 'common/TermsOfService';
import PrivacyPolicy from 'common/PrivacyPolicy';

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      termsofservice: false,
      privacypolicy: false,
    };
  }

  closeModal = () => {
    this.setState({ termsofservice: false, privacypolicy: false });
  };

  render() {
    const { termsofservice, privacypolicy } = this.state;
    return (
      <footer id="footer">
        <nav>
          <button
            type="button"
            onClick={() =>
              this.setState({ privacypolicy: true, termsofservice: false })
            }
            className="nav-link"
            style={{
              background: 'none',
              border: 'none',
            }}
          >
            Privacy Policy
          </button>
          <button
            type="button"
            onClick={() =>
              this.setState({ privacypolicy: false, termsofservice: true })
            }
            className="nav-link"
            style={{
              background: 'none',
              border: 'none',
            }}
          >
            Terms & Conditions
          </button>
        </nav>
        <span className="text-uppercase">
          &copy; {new Date().getFullYear()} LISCIO. All Rights Reserved.
        </span>
        {termsofservice && !privacypolicy ? (
          <TermsOfService
            modalClick={termsofservice}
            closeModal={this.closeModal}
          />
        ) : null}
        {privacypolicy && !termsofservice ? (
          <PrivacyPolicy
            modalClick={privacypolicy}
            closeModal={this.closeModal}
          />
        ) : null}
      </footer>
    );
  }
}

export default Footer;
