import { Box, Stack, Typography, styled } from '@mui/material';
import { onlyHTMLProps } from 'theme/mui-utils';
import { PaddingBundle } from './SMSMessage';

type MessageBoxContainerProps = {
  isIncoming: boolean;
  alignStartOrEnd: 'flex-start' | 'flex-end';
};

type StackProps = {
  component: string;
};

export const MessageBoxContainer = styled(
  Box,
  onlyHTMLProps,
)(({ isIncoming, alignStartOrEnd }: MessageBoxContainerProps) => ({
  margin: '5px',
  display: 'flex',
  justifyContent: 'space-between',
  alignSelf: alignStartOrEnd,
  flexDirection: isIncoming ? 'row' : 'row-reverse',
}));

export const MessageBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.light,
  display: 'flex',
  maxWidth: '30rem',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: '5px',
  borderRadius: '20px',
  padding: '8px 15px 10px 15px',
  fontSize: '16px',
  gap: '10px',
  '& .info': {
    alignSelf: 'flex-end',
    '&__time': {
      color: theme.palette.text.secondary,
    },
  },
}));

export const SenderAndMessageBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});

export const StyledAvatarBox = styled(Box)(
  ({ paddingIfIncoming, noPaddingIfIncoming }: PaddingBundle) => ({
    margin: `25px ${paddingIfIncoming} 0px ${noPaddingIfIncoming}`,
  }),
);

export const DisplayNameText = styled(
  Typography,
  onlyHTMLProps,
)(
  ({ noPaddingIfIncoming, paddingIfIncoming }: PaddingBundle) =>
    ({ theme }) => ({
      margin: `0px ${noPaddingIfIncoming} 0px ${paddingIfIncoming}`,
      fontSize: '14px',
      color: theme.palette.common.secondary[600],
    }),
);

// eslint-disable-next-line no-unused-vars
export const MessageContainerStack = styled(Stack)((_props: StackProps) => ({
  padding: '1.25rem',
  paddingTop: '0.5rem',
  flexGrow: 1,
  height: '100%',
  width: '100%',
  display: 'flex',
}));
