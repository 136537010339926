import Select from 'react-select';
import styled from '@emotion/styled';

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  span {
    font-size: 12px;
    text-transform: uppercase;
    color: #919eab;
    background-color: #dfe3e8;
    letter-spacing: 1px;
    font-weight: 600;
    font-family: 'Source Sans Pro';
    font-style: normal;
    padding: 2px 7px;
    margin-bottom: 6px;
  }
`;

function LoadingMessage(props) {
  const { getStyles, children, innerProps } = props;
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div {...innerProps} style={getStyles('loadingMessage', props)}>
      {children}
    </div>
  );
}
const MessageRecipientSelect = ({
  handleRecipientChange,
  selectStyles,
  recipientOptions,
  isLoading,
  handleContactInputChange,
  recipientValue,
}) => {
  const value = recipientValue.label ? recipientValue : null;
  const options = isLoading ? [] : recipientOptions;

  function formatGroupLabel(data) {
    return (
      <StyledDiv className="SelectGroupLabel">
        <span>{data.label}</span>
      </StyledDiv>
    );
  }
  return (
    <Select
      name="messageRecipient"
      id="messageRecipient"
      inputId="messageRecipient"
      placeholder={isLoading ? 'Loading...' : 'Select a Recipient'}
      className="RecipientSelect"
      formatGroupLabel={formatGroupLabel}
      styles={selectStyles}
      options={options}
      onChange={handleRecipientChange}
      value={value}
      onInputChange={handleContactInputChange}
      components={{ LoadingIndicator: null, LoadingMessage }}
      isLoading={isLoading}
      autoFocus
      isSearchable
      isClearable
      menuPosition="fixed"
    />
  );
};

export default MessageRecipientSelect;
