import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';
import { MinusIcon, PlusIcon } from 'common/Icons';
import Activity from 'components/Contacts/Activity';
import { SyntheticEvent } from 'react';

interface Props {
  accountId: string | number;
  isExpanded: (panel: string) => boolean;
  handleChange: (
    panel: string,
  ) => (event: SyntheticEvent, expanded: boolean) => void;
}

export default function AccountHistoryAccordion({
  isExpanded,
  handleChange,
  accountId,
}: Props) {
  return (
    <div className="col-12">
      <Accordion
        disableGutters
        square
        elevation={0}
        expanded={isExpanded('account_history')}
        onChange={handleChange('account_history')}
      >
        <AccordionSummary
          id="account_history-header"
          expandIcon={
            isExpanded('account_history') ? <MinusIcon /> : <PlusIcon />
          }
          aria-controls="account_history-content"
        >
          <Typography fontWeight="bolder" variant="h6">
            Account History
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Activity for="account" id={accountId} />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
