/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import Axios from 'axios';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom-latest';
import {
  statusClassName,
  getFirmType,
  FORMATPHONENUMBER,
} from 'utilities/utils';
import { CONTACT_CARD_DETAILS } from 'constants/constants';
import { saveContactCardData } from 'actions/ContactCardActions';
import 'common/ContactCard.scss';

class ContactCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contact: {},
      id: null,
    };
  }

  componentDidMount() {
    const { id } = this.props;
    if (id && id !== null) {
      this.getRecords();
    }
  }

  getRecords = () => {
    const { allcontactscarddata, id } = this.props;
    if (
      allcontactscarddata[id] &&
      !this.props.page &&
      this.props.page !== 'threaddetail'
    ) {
      this.setState({
        contact: allcontactscarddata[id],
        id,
      });
    } else {
      Axios.get(`${CONTACT_CARD_DETAILS}/${id}`).then((res) => {
        if (res.status === 200 && res.data.status === 200 && res.data.data) {
          this.props.saveContactCardData({
            ...res.data.data,
            id,
          });
          this.setState({
            contact: res.data.data,
            id,
          });
        }
      });
    }
  };

  render() {
    const { contact } = this.state;
    const { id } = this.props;
    if (id === undefined || id === null) {
      return null;
    }
    const uniqId =
      this.props.page && this.props.page === 'threaddetail'
        ? this.props.uniqId
        : '';
    const conId = this.state.id ? this.state.id : this.props.id;
    return (
      <ReactTooltip
        id={`${id}global${uniqId}`}
        aria-haspopup="true"
        place="right"
        effect="solid"
        className={`contactCardClass ${
          this.props.page === 'threaddetail' ? ' contactCardThreadDetail' : ''
        }`}
        delayHide={100}
      >
        <div>
          <div
            className="section-title d-flex no-gutters pt-4 pr-3 pb-2 pl-3"
            style={{ backgroundColor: '#434566' }}
          >
            <div className="col mt-4">
              <div className="d-flex">
                <div className="avatar avatar--w30">
                  {contact.avatar && contact.avatar.length ? (
                    <img
                      src={contact.avatar}
                      alt={contact.full_name}
                      className="user-photo rounded-circle"
                    />
                  ) : (
                    <span className="dName dName--w30">
                      <span className="uName">
                        {contact.full_name
                          ? (contact.full_name.split(' ')[0]
                              ? contact.full_name.split(' ')[0].charAt(0)
                              : '') +
                            (contact.full_name.split(' ')[1]
                              ? contact.full_name.split(' ')[1].charAt(0)
                              : '')
                          : null}
                      </span>
                    </span>
                  )}
                </div>
                <div className="infoAccount contact-name-section">
                  <h3 className="subHeadline text-dark text-uppercase font-weight-bold mb-0">
                    {contact.job_title ? contact.job_title : null}
                  </h3>
                  <h1 className="text-small text-dark mb-0 onlineStatus">
                    {contact.full_name}
                    <i
                      className={statusClassName(
                        contact.is_invitation_expired
                          ? 'expired'
                          : contact.status,
                      )}
                      style={{ transition: 'none' }}
                      data-tip
                      data-for="contact-status"
                    />
                    <ReactTooltip id="contact-status" effect="solid">
                      <span
                        className="contact-status"
                        style={{ color: 'white !important' }}
                      >
                        {contact.is_invitation_expired
                          ? 'Invite Expired'
                          : contact.status}
                      </span>
                    </ReactTooltip>
                  </h1>
                </div>
              </div>
            </div>
            <div className="col-sm-2 d-flex justify-content-end mt-4">
              {contact.tasks_count && getFirmType() !== 'liscio-lite' ? (
                <div className="infoAccount">
                  <h1 className="text-small text-right text-dark mb-0">
                    {contact.tasks_count}
                  </h1>
                  <h3 className="subHeadline text-dark text-uppercase font-weight-bold mb-0">
                    Tasks
                  </h3>
                </div>
              ) : null}
            </div>
            <div className="expndAction mr-1 mt-1">
              <Link to={`/contactdetails/${conId}`}>
                <i className="icon-expand" />
              </Link>
            </div>
          </div>
          {getFirmType() !== 'liscio-lite' && (
            <div className="pb-2">
              <div className="cardHead col-container">
                <div className="col p-2 text-center w-50 cardAction text-uppercase">
                  <Link to="/message/new">Send Message</Link>
                </div>
                <div className="col p-2 text-center w-50 cardAction text-uppercase text-dark font-weight-bold">
                  <Link to={`/contactdetails/${conId}#notes`}>
                    Notes{' '}
                    {contact.notes_count !== undefined
                      ? `(${contact.notes_count})`
                      : null}
                  </Link>
                </div>
              </div>
            </div>
          )}
          <div className="pt-2 pr-3 pb-3 pl-3">
            <div className="form-group w-100">
              <label>EMAIl:</label>
              <div className="labelValue">
                <span>{contact.email ? contact.email : '-'}</span>
              </div>
            </div>
            <div className="form-group w-100">
              <label>Mobile #:</label>
              <div className="labelValue">
                <span>
                  {contact.phone ? FORMATPHONENUMBER(contact.phone) : '-'}
                </span>
              </div>
            </div>
            <div className="form-group mb-0 w-100">
              <label>Associated Accounts:</label>
              <div className="labelValue">
                {contact.relation_data &&
                  contact.relation_data.slice(0, 3).map((each) => (
                    <div className="row mb-1" key={each.name}>
                      <div className="col-8">
                        <span className="label-submedium">{each.name}</span>
                      </div>
                      <div className="col-4">
                        <div className="avatar avatar--w20 avatar--overlap w-100">
                          {getFirmType() !== 'liscio-lite' &&
                            each.team.map((eachteam, key) => {
                              const unique = eachteam.name + key;
                              return (
                                <div key={unique}>
                                  {eachteam.avatar && eachteam.avatar.length ? (
                                    <img
                                      src={eachteam.avatar}
                                      alt={eachteam.name}
                                      className="user-photo rounded-circle"
                                      data-tip
                                      data-for={unique}
                                    />
                                  ) : (
                                    <span className="dName dName--w20">
                                      <span
                                        className="uName"
                                        data-tip
                                        data-for={unique}
                                      >
                                        {eachteam.initial}
                                      </span>
                                    </span>
                                  )}
                                  <ReactTooltip id={unique} effect="solid">
                                    <span className="contactcard-team-member">
                                      {eachteam.name}
                                    </span>
                                  </ReactTooltip>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </ReactTooltip>
    );
  }
}

const mapStateToProps = (state) => ({
  allcontactscarddata: state.contactCardReducer,
});

const mapDispatchToProps = (dispatch) => ({
  saveContactCardData: (data) => dispatch(saveContactCardData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactCard);
