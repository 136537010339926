import React from 'react';
import styled from '@emotion/styled';
import Button from 'common/Button/Button';
import {
  BUTTON_ICON_LOCATION_FRONT,
  BUTTON_ICON_LOCATION_BACK,
} from 'constants/constants';

const StyleDiv = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  button {
    color: #8a939a;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 1px;
    margin-right: 7px;
    i {
      color: #8a939a;
      margin-right: 3px;
      font-size: 12px;
    }
  }
  #CLOSE {
    margin-right: 4px;
  }
  .divider {
    border-left: 1px solid #dfe3e8;
    display: inline-block;
    height: 27px;
    margin-right: 15px;
  }
`;

const EmailNextPrevView = ({
  handleClick,
  selectedIndex,
  previousIndex,
  nextIndex,
  emails,
  handlePrevNextButton,
}) => {
  const handleClose = () => {
    handleClick('close');
  };

  const handleNextButton = (index) => {
    handlePrevNextButton(index);
  };
  const emailLen = emails?.length ? emails.length - 1 : -1;
  const nextButtonDiabled = nextIndex > emailLen;
  const prevButtonDisabled = previousIndex < 0;
  return (
    <StyleDiv>
      <Button
        variant="ghost"
        onClick={handleClose}
        size="xsmall"
        label="CLOSE"
        icon="close2"
        iconLocation={BUTTON_ICON_LOCATION_FRONT}
      />
      <span className="divider" />
      <Button
        variant="secondary"
        onClick={() => handleNextButton(selectedIndex - 1)}
        size="xsmall"
        label="PREV."
        disabled={prevButtonDisabled}
        icon="chevron-left3"
        iconLocation={BUTTON_ICON_LOCATION_FRONT}
      />
      <Button
        variant="secondary"
        onClick={() => handleNextButton(selectedIndex + 1)}
        size="xsmall"
        label="NEXT"
        disabled={nextButtonDiabled}
        icon="chevron-right"
        iconLocation={BUTTON_ICON_LOCATION_BACK}
      />
    </StyleDiv>
  );
};
export default EmailNextPrevView;
