import React from 'react';
import { Link } from 'react-router-dom-latest';

const HeaderTabs = ({ data, customClasses, handleClick, activeTab }) => (
  <ul
    className={customClasses ? `nav nav-tabs ${customClasses}` : 'nav nav-tabs'}
  >
    {data.map((each, key) => (
      <li className="nav-item" key={each.value}>
        {each.url ? (
          <Link
            className={activeTab === key ? 'nav-link active' : 'nav-link'}
            to={each.url}
            onClick={() => handleClick(key, each)}
            data-testid={`inbox__${each?.value?.toLowerCase()}`}
          >
            {each.label}
          </Link>
        ) : (
          <button
            type="button"
            className={
              activeTab === key ? 'nav-link active' : 'nav-link bg-transparent'
            }
            data-toggle="tab"
            onClick={() => handleClick(key, each)}
            data-testid={`inbox__${each?.value?.toLowerCase()}`}
          >
            {each.label}
          </button>
        )}
      </li>
    ))}
  </ul>
);

export default HeaderTabs;
