import { useQuery } from '@tanstack/react-query';
import { apiClientV5 } from 'api/apiClientV5';
import {
  usePatchApiV5AuthPasswordResetsResetToken,
  usePostApiV5AuthMfa,
  usePostApiV5AuthPasswordResetTokens,
} from 'api/v5/auth/auth';
import { useCallback, useState } from 'react';

/**
 * Represents reset password sequential step state
 */
export type ResetPasswordState =
  | 'VERIFYING'
  | 'MFA_REQUIRED'
  | 'RESET_PASSWORD'
  | 'COMPLETE';
/**
 * submitMfa handler params
 */
export type SubmitMfaHandlerParams = {
  two_factor_code?: string;
};
/**
 * submitPasswordReset handler params
 */
export type SubmitPasswordResetParams = {
  password: string;
  password_confirmation: string;
};

/**
 * Helper hook to manage V5 reset password flows
 *
 * This hook will manage the following reset password steps,
 * providing handlers and state where necessary:
 *
 * - Verify reset password token
 * - Determine if MFA is needed for password reset and expose submitMfa handler
 * - Provide submitResetPassword handler
 * - Provide completion success message
 *
 * All auth token states are managed internally to this hook
 */
export const useV5AuthResetPassword = (resetToken = '') => {
  const [state, setState] = useState<ResetPasswordState>('VERIFYING');

  // API Mutations
  const passwordResetTokens = usePostApiV5AuthPasswordResetTokens();
  const mfa = usePostApiV5AuthMfa();
  const reset = usePatchApiV5AuthPasswordResetsResetToken();

  // Resolve returned values
  const isLoading =
    passwordResetTokens.isLoading || mfa.isLoading || reset.isLoading;
  const error =
    passwordResetTokens.error?.response?.data ||
    mfa.error?.response?.data ||
    reset.error?.response?.data;

  // STEP 1: Verify token
  useQuery({
    queryKey: ['passwordResetTokens', resetToken],
    queryFn: async () => {
      const result = await passwordResetTokens.mutateAsync({
        data: { reset_token: resetToken },
      });
      if (result.mfa_required) {
        setState('MFA_REQUIRED');
      } else {
        setState('RESET_PASSWORD');
      }
      return result;
    },
    staleTime: Infinity,
    cacheTime: Infinity,
    retry: false,
  });

  // STEP 1.5 (Conditional): Submit MFA Handler
  const mfaMutateAsync = mfa.mutateAsync;
  const verifyResetAuthToken = passwordResetTokens.data?.auth_token;
  const submitMfa = useCallback(
    async ({ two_factor_code = '' }: SubmitMfaHandlerParams = {}) => {
      apiClientV5.defaults.headers.common.Authorization =
        verifyResetAuthToken || '';
      await mfaMutateAsync({ data: { two_factor_code } });
      setState('RESET_PASSWORD');
    },
    [mfaMutateAsync, verifyResetAuthToken],
  );

  // STEP 2: Reset Password Handler
  const passwordResetMutateAsync = reset.mutateAsync;
  const mfaAuthToken = mfa?.data?.auth_token || '';
  const submitPasswordReset = useCallback(
    async (data: SubmitPasswordResetParams) => {
      apiClientV5.defaults.headers.common.Authorization =
        mfaAuthToken || verifyResetAuthToken || '';
      await passwordResetMutateAsync({ data, resetToken });
      setState('COMPLETE');
    },
    [passwordResetMutateAsync, resetToken, mfaAuthToken, verifyResetAuthToken],
  );

  // STEP 3: Reset Complete
  const confirmationMessage = reset.data?.message;

  return {
    // Hook state
    state,
    isLoading,
    error,
    confirmationMessage,
    // Handlers
    submitMfa,
    submitPasswordReset,
  };
};
