import React from 'react';
import Select from 'react-select';
import { selectTheme, selectStyles } from 'common/CustomFields';

export const formatOptionLabelAssociate = (item) => (
  <div>{item?.label && <span>{item?.label}</span>}</div>
);

function AssociateSelect({
  mode,
  value,
  options,
  handleChange,
  entityType,
  Option,
  handleInputChange,
  handleSave,
}) {
  function optionValue(option) {
    if (entityType === 'account') {
      return option.value;
    }
    if (entityType === 'contact') {
      return option.id === null ? option.value : option.id;
    }
    return null;
  }

  return (
    <Select
      isMulti
      name="form-field-name"
      value={value}
      onChange={handleChange}
      onBlur={handleSave}
      onInputChange={handleInputChange}
      getOptionValue={(option) => optionValue(option)}
      formatOptionLabel={formatOptionLabelAssociate}
      options={options}
      components={{ Option }}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      menuPosition="fixed"
      menuPlacement="auto"
      menuShouldScrollIntoView={false}
      isDisabled={mode === 'view'}
      theme={selectTheme}
      styles={{
        ...selectStyles,
        menuList: (provided) => ({
          ...provided,
          height: '150px',
        }),
        menuPortal: (provided) => ({
          ...provided,
          position: 'static',
        }),
      }}
    />
  );
}
export default AssociateSelect;
