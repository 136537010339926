import * as yup from 'yup';

export const formSchema = yup.object().shape({
  email: yup.string().required('Email is required'),
  password: yup.string().when('$processState', (contextArray, schema) => {
    const [processState] = contextArray;
    if (processState === 'loginForm' || processState === 'mfaCode') {
      return schema.required('Password is required');
    }
    return schema;
  }),
  two_factor_code: yup
    .string()
    .when('$processState', (contextArray, schema) => {
      const [processState] = contextArray;
      if (processState === 'mfaCode') {
        return schema.required('Code is required');
      }
      return schema;
    }),
});
