/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable consistent-return */
import React, { Component } from 'react';
import { withRouter } from 'utilities/withRouterWrapper';
import {
  removeCookie,
  operatingSystemIcon,
  getDeviceName,
  titleCase,
} from 'utilities/utils';
import axios from 'axios';
import moment from 'moment-timezone';
import { LOGOUT } from 'constants/constants';
import ConfirmationModal from 'common/ConfirmationModal';
import UserProfileDetail from 'api/UserProfileDetail';
import chromeLogo from 'images/chrome_logo.png';
import mozillaLogo from 'images/mozilla_logo.png';
import safariLogo from 'images/safari_logo.png';
import { clearAuthSession, getAuthSession } from 'auth/session';

const noneStyle = { display: 'none' };
const blockStyle = { display: 'block' };
const timeZone =
  Intl.DateTimeFormat().resolvedOptions().timeZone || moment.tz.guess(true);

class DeviceDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      signOutDeviceConfirm: false,
      deviceNotRecognize: false,
      signOutDeviceName: '',
      loading: false,
    };
  }

  signOutDeviceConfirm = (value, id, name, signoutdevice = '') => {
    const component = this;
    if (signoutdevice === 'yes') {
      const { signOutDeviceId } = component.state;
      const { location } = this.props;
      if (location.state && location.state.data) {
        const { data } = location.state;
        if (data.this_devise) {
          this.setState({ loading: true });
          axios.post(LOGOUT);
          this.logoutInterval = setInterval(() => {
            clearAuthSession();
            removeCookie({}, 'redirect_url');
            localStorage.clear();
            if (!getAuthSession() && localStorage.length === 0) {
              this.setState({ loading: false });
              this.props.navigate('/login');
              clearInterval(this.logoutInterval);
            }
          }, 100);
        } else {
          UserProfileDetail.logOutFromDevice(
            component,
            signOutDeviceId,
            'devicedetail',
          );
          this.back();
        }
      }
    } else {
      component.setState({
        signOutDeviceConfirm: value,
        signOutDeviceId: id,
        signOutDeviceName: name,
      });
    }
  };

  deviceNotRecognize = (value, notrecognize = '') => {
    this.setState({ deviceNotRecognize: value });
    if (notrecognize === 'yes') {
      this.props.navigate('/profile_settings', {
        state: { changePassword: true },
      });
    }
  };

  back = () => {
    this.props.navigate(-1);
  };

  render() {
    const { location } = this.props;
    const { signOutDeviceConfirm, deviceNotRecognize, signOutDeviceName } =
      this.state;
    const signOutMessage = `<div><h5><p className="mb-2"><strong>Sign out from ${signOutDeviceName.toUpperCase()}</strong></p></h5><p className="mb-2">You will be force logged out from this device</p></div>`;
    if (location.state && location.state.data) {
      const { data } = location.state;
      const icon = operatingSystemIcon(data);
      let imgIcon = chromeLogo;
      if (data.browser === 'Chrome') {
        imgIcon = chromeLogo;
      } else if (data.browser === 'Firefox') {
        imgIcon = mozillaLogo;
      } else if (data.browser === 'Safari') {
        imgIcon = safariLogo;
      } else {
        imgIcon = chromeLogo;
      }
      const deviceName = getDeviceName(data);
      const deviceMake =
        data.device_make &&
        data.device_make !== null &&
        data.device_make !== 'null'
          ? `${data.device_make}`
          : '';
      const deviceModel =
        data.device_model &&
        data.device_model !== null &&
        data.device_model !== 'null'
          ? data.device_model
          : '';
      const deviceMakeModel =
        deviceMake + (deviceMake && deviceModel ? ' - ' : '') + deviceModel;
      const deviceOs =
        data.device_os && data.device_os.toLowerCase() !== 'other-0'
          ? data.device_os
          : '';
      const deviceBrowser =
        data.browser && data.browser.toLowerCase() !== 'generic browser'
          ? titleCase(data.browser)
          : '';
      const deviceOsBrowser =
        deviceOs.toUpperCase() +
        (deviceOs && deviceBrowser ? ', ' : '') +
        deviceBrowser;
      const address =
        (data.city && data.city !== null && data.city !== 'null'
          ? `${data.city}, `
          : '') +
        (data.state && data.state !== null && data.state !== 'null'
          ? `${data.state}, `
          : '') +
        (data.country && data.country !== null && data.country !== 'null'
          ? data.country
          : '');
      const ipAddress = data.ip_address ? `(${data.ip_address})` : '';
      const deviceInfo =
        deviceName.toUpperCase() +
        (deviceMakeModel ? `, ${deviceMakeModel.toUpperCase()}` : '') +
        (deviceOsBrowser ? ` (${deviceOsBrowser})` : '');
      const appName = data.app_name;
      let time = data.created_at;
      if (data.created_at.indexOf('ago') === -1) {
        time = moment.tz(time, timeZone).format('MM/DD/YY hh:mm a');
      }
      return (
        <div className="w-100">
          <div
            id="loading"
            style={this.state.loading ? blockStyle : noneStyle}
          />
          <header>
            <h1 className="pageHeading">YOUR DEVICE DETAILS</h1>
          </header>
          <div className="px-4">
            <h6>
              <a className="text-icon-large" onClick={this.back}>
                <i className="icon-left-arrow text-large text-dark" />
              </a>
            </h6>
          </div>
          <div className="center-wrapper col-lg-10 col-xs-12 m-auto">
            <div className="row">
              <div className="settingsSec settingInner col-12">
                <div className="row">
                  <div className="col-12">
                    <div className="row">
                      <div className="col-12">
                        <div className="media">
                          <h3 className="mr-4 text-icon-large text-black">
                            <i className={icon} />
                          </h3>
                          <div className="media-body">
                            <h4 className="mt-0 mb-1 d-flex align-items-center">
                              <span>{deviceInfo}</span>{' '}
                              {data.this_devise && (
                                <a className="py-0 fontSize text-transform-none font-weight-normal">
                                  <i className="iconCircle icon-tickmark_trans_o bg-primary text-white border-white" />{' '}
                                  This device
                                </a>
                              )}
                            </h4>
                            {address && (
                              <p className="text-dark d-block my-0">
                                {address} {ipAddress}
                              </p>
                            )}

                            <p className="text-dark">{appName}</p>
                          </div>
                        </div>
                        <div className="btn-wrap btn--leftspace">
                          <button
                            type="button"
                            className="btn btn-sm btn-outline-light"
                            onClick={() =>
                              this.signOutDeviceConfirm(
                                true,
                                data.id,
                                deviceName,
                                '',
                              )
                            }
                          >
                            <i className="icon-Sign-out text-primary pr-1" />{' '}
                            Sign out
                          </button>
                          <button
                            type="button"
                            className="btn btn-sm btn-outline-light"
                            onClick={() => this.deviceNotRecognize(true)}
                          >
                            <i className="icon-question text-primary pr-1" />{' '}
                            Don&apos;t recognize this device
                          </button>
                        </div>
                      </div>
                      <div className="col-12">
                        <div
                          className={`sBlock border-top py-4 my-4 ${
                            deviceBrowser ? ' border-bottom' : ''
                          }`}
                        >
                          <h4 className="fontSize mb-3 text-uppercase">
                            Recent Activity
                          </h4>
                          <ul className="deviceList">
                            {address ? (
                              <li>
                                {address} <span className="ml-3">{time}</span>
                              </li>
                            ) : (
                              <span className="ml-3">{time}</span>
                            )}
                          </ul>
                        </div>
                      </div>
                      {deviceBrowser ? (
                        <div className="col-12">
                          <div className="sBlock">
                            <h4 className="fontSize text-uppercase mb-3">
                              Browser you&apos;re Signed in on
                            </h4>
                            <h5 className="fontSize">
                              <img
                                src={imgIcon}
                                className="mr-2"
                                alt="Chrome"
                                style={{ width: '20px' }}
                              />{' '}
                              {data.browser}
                            </h5>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ConfirmationModal
              isOpen={signOutDeviceConfirm}
              className="customModal customModal--w350 customModal--nopadding"
              isHtml="true"
              messageText={signOutMessage}
              noBtnClick={() => this.signOutDeviceConfirm(false, '', '', '')}
              yesBtnClick={() =>
                this.signOutDeviceConfirm(false, '', '', 'yes')
              }
              yesBtnText="Sign out"
              noBtnText="Cancel"
            />
            <ConfirmationModal
              isOpen={deviceNotRecognize}
              headerText="Let's secure your account"
              isHtml="true"
              messageText="<div><p>If there's a device you don't recognize, someone else may have your password. Change your password to protect your Liscio Account.</p><p>You'll be signed out from all devices including the one you're using now.</p></div>"
              noBtnClick={() => this.deviceNotRecognize(false)}
              yesBtnClick={() => this.deviceNotRecognize(false, 'yes')}
              yesBtnText="Change password"
              noBtnText="Cancel"
            />
          </div>
        </div>
      );
    }
  }
}

export default withRouter(DeviceDetail);
