/* eslint-disable react/jsx-props-no-spreading */
import {
  Autocomplete,
  FormControl,
  FormLabel,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useFormContext } from 'react-hook-form';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { SMSSettingsType } from './FormValidation';
import { PrivacyPolicyErrorMessage } from './PrivacyPolicyErrorMessage';
import { PrivacyPolicyInfo } from './PrivacyPolicyInfo';

const businessTypeOptions = [
  { id: 0, label: 'Private Company', value: 'PRIVATE_PROFIT', disabled: false },
  {
    id: 1,
    label: 'Sole Proprietor (Not Available)',
    value: 'SOLE_PROPRIETOR',
    disabled: true,
  },
];

export default function CompanyDetails() {
  const {
    register,
    formState: { errors },
  } = useFormContext<SMSSettingsType>();
  const { smsPrivacyPolicyUpdate } = useFlags();

  return (
    <Stack marginTop={4}>
      <Typography fontWeight="bolder" variant="h3">
        Company Details
      </Typography>
      <Grid container paddingTop={1} spacing={4} rowSpacing={2}>
        <Grid xs={6}>
          <FormControl fullWidth>
            <FormLabel htmlFor="legalName">LEGAL COMPANY NAME</FormLabel>
            <TextField
              id="legalName"
              {...register('legalName')}
              error={Boolean(errors.legalName)}
              helperText={errors.legalName?.message}
              variant="standard"
              type="text"
            />
          </FormControl>
        </Grid>

        <Grid xs={6}>
          <FormControl fullWidth>
            <FormLabel htmlFor="businessType">BUSINESS TYPE</FormLabel>
            <Autocomplete
              id="businessType"
              options={businessTypeOptions}
              getOptionDisabled={(option) => option.disabled}
              renderInput={(params) => (
                <TextField
                  data-testid="street-suffix"
                  variant="standard"
                  {...register('businessType', {
                    setValueAs: (value) =>
                      businessTypeOptions.find((opt) => opt.label === value)
                        ?.value,
                  })}
                  error={Boolean(errors.businessType)}
                  helperText={errors.businessType?.message}
                  {...params}
                />
              )}
            />
          </FormControl>
        </Grid>

        <Grid xs={6}>
          <FormControl fullWidth>
            <FormLabel htmlFor="ein">TAX NUMBER/ID/EIN.</FormLabel>
            <TextField
              id="ein"
              {...register('ein')}
              error={Boolean(errors.ein)}
              helperText={errors.ein?.message}
              variant="standard"
              type="text"
            />
          </FormControl>
        </Grid>

        <Grid xs={6}>
          <FormControl fullWidth>
            <FormLabel htmlFor="firmWebsiteUrl">FIRM WEBSITE</FormLabel>
            <TextField
              id="firmWebsiteUrl"
              {...register('firmWebsiteUrl')}
              error={Boolean(errors.firmWebsiteUrl)}
              helperText={errors.firmWebsiteUrl?.message}
              variant="standard"
              type="text"
            />
          </FormControl>
        </Grid>

        <Grid xs={6}>
          <FormControl fullWidth>
            <FormLabel htmlFor="firstName">FIRST NAME</FormLabel>
            <TextField
              id="firstName"
              {...register('firstName')}
              error={Boolean(errors.firstName)}
              helperText={errors.firstName?.message}
              variant="standard"
              type="text"
            />
          </FormControl>
        </Grid>

        <Grid xs={6}>
          <FormControl fullWidth>
            <FormLabel htmlFor="lastName">LAST NAME</FormLabel>
            <TextField
              id="lastName"
              {...register('lastName')}
              error={Boolean(errors.lastName)}
              helperText={errors.lastName?.message}
              variant="standard"
              type="text"
            />
          </FormControl>
        </Grid>

        <Grid xs={6}>
          <FormControl fullWidth>
            <FormLabel htmlFor="email">EMAIL</FormLabel>
            <TextField
              id="email"
              {...register('email')}
              error={Boolean(errors.email)}
              helperText={errors.email?.message}
              variant="standard"
              type="email"
            />
          </FormControl>
        </Grid>
        <Grid xs={6}>
          <FormControl fullWidth>
            <FormLabel htmlFor="phoneNumber">PHONE</FormLabel>
            <TextField
              id="phoneNumber"
              {...register('phoneNumber')}
              error={Boolean(errors.phoneNumber)}
              helperText={errors.phoneNumber?.message}
              variant="standard"
              type="text"
            />
          </FormControl>
        </Grid>
        <Grid xs={6}>
          <FormControl fullWidth>
            <FormLabel htmlFor="preferredAreaCode">
              PREFERRED AREA CODE
            </FormLabel>
            <TextField
              id="preferredAreaCode"
              {...register('preferredAreaCode')}
              error={Boolean(errors.preferredAreaCode)}
              helperText={errors.preferredAreaCode?.message}
              variant="standard"
              type="text"
            />
          </FormControl>
        </Grid>
        {smsPrivacyPolicyUpdate && (
          <Grid xs={6}>
            <FormControl fullWidth>
              <FormLabel htmlFor="privacyPolicyUrl">
                PRIVACY POLICY <PrivacyPolicyInfo />
              </FormLabel>
              <TextField
                id="privacyPolicyUrl"
                {...register('privacyPolicyUrl')}
                error={Boolean(errors.privacyPolicyUrl)}
                helperText={
                  <PrivacyPolicyErrorMessage
                    errorMessage={errors?.privacyPolicyUrl?.message}
                  />
                }
                variant="standard"
                type="text"
              />
            </FormControl>
          </Grid>
        )}
      </Grid>
    </Stack>
  );
}
