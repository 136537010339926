const initialState = {
  accountslistdata: {},
  contactslistdata: {},
};

// eslint-disable-next-line default-param-last
export default function accountReducer(state = initialState, action) {
  switch (action.type) {
    case 'STORE_ACCOUNTS_LIST_DATA':
      return {
        ...state,
        accountslistdata: {
          ...state.accountslistdata,
          [action.data.key]: action.data,
        },
      };
    case 'STORE_CONTACTS_LIST_DATA':
      return {
        ...state,
        contactslistdata: {
          ...state.contactslistdata,
          [action.data.key]: action.data,
        },
      };
    case 'RESET_ACCOUNTS_LIST_DATA':
      return {
        ...state,
        accountslistdata: {},
      };
    case 'RESET_CONTACTS_LIST_DATA':
      return {
        ...state,
        contactslistdata: {},
      };
    default:
      return state;
  }
}
