/* eslint-disable import/no-cycle */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import ActionsModal from './ActionsModal';
import EmailThreadDetails from './EmailThreadDetails';

function EmailReplySlideIn(props) {
  const {
    showModal,
    cancelActionsModel,
    showModalFor,
    getModelTitle,
    getModelViewContext,
    emailDetails,
    addEmails,
    refreshPage,
    nextThread,
    unreadEmail,
    from,
    selectedEmailId,
    emailDetailMode,
    handleEmailDetailMode,
    setLoading,
  } = props;
  if (showModal) {
    return (
      <ActionsModal
        isOpen={showModal}
        onCancel={cancelActionsModel}
        title={getModelTitle(showModalFor)}
        style={{ overflow: 'left', maxHeight: '23vh' }}
        showModalFooter={getModelViewContext(showModalFor) !== ''}
        handleSuccessBtn={() =>
          addEmails(
            emailDetails.email_id,
            getModelViewContext(showModalFor),
            emailDetails.entity_email_thread_id,
          )
        }
        slideReply="ReplyModal"
      >
        <EmailThreadDetails
          refreshPage={refreshPage}
          nextThread={nextThread}
          unreadEmail={unreadEmail}
          from={from}
          selectedEmailId={selectedEmailId}
          accountId={null}
          contactId={null}
          emailDetailMode={emailDetailMode}
          handleEmailDetailMode={handleEmailDetailMode}
          slideReply="slideReply"
          cancelActionsModel={cancelActionsModel}
          setLoading={setLoading}
        />
      </ActionsModal>
    );
  }
  return null;
}

export default EmailReplySlideIn;
