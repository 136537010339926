/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
// this is for showing accounts and contacts linked to emails
import React, { useCallback, useEffect, useState } from 'react';
import {
  ENTITY_EMAIL_LINKS,
  GET_ACCOUNTS_FOR_INVOICE,
  GET_CONTACTS_FOR_INVOICE,
  LINK_EMAILS,
} from 'constants/constants';
import Axios from 'axios';
import styled from '@emotion/styled';
import ContactCard from 'common/ContactCard';
import AccountCard from 'common/AccountCard';
import AssociateSelect from 'common/AssociateSelect';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { getStorageData } from 'utilities/utils';
import { capitalize } from '@mui/material';
import Option from './EmailAccConCheckbox';
import AssociateEdit from './icons/AssociateEdit';

const StyledDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 0.5rem 0;
  align-items: center;
  .info {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 0 0 100%;
    max-width: 100%;
    width: 100%;
  }
  .label {
    display: flex;
    font-weight: 600;
    font-size: 15px;
    i {
      margin-right: 0.2rem;
      color: #919eab;
      vertical-align: middle;
    }
  }
  .divOption {
    align-self: center;
    background-color: #212b36;
    width: 3px;
    height: 3px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
    margin: 0 0.3rem;
  }
  .linkedData {
    display: 'flex';
    flex-direction: 'row';
    flex-wrap: 'wrap';
    flex: 1;
    font-size: 15px;
    a {
      font-size: 0.82rem;
    }
    .linkedData__label {
      margin-right: 5px;
    }
  }
  .selectfield {
    flex: 1;
  }
  .selectedRecords {
    display: flex;
  }
  .button-section {
    margin-top: 1rem;
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
    button:first-child {
      margin-right: 0.5rem;
    }
  }
`;

function LinkedAccounts({
  initialValue = [],
  emailId,
  entityEmailThreadId,
  entityType,
  refreshPage,
  enableMode,
  emailLinkedBox,
  contactAccountLinked,
  accountContactLinked,
  emailLinkedWith,
}) {
  const { linkedEmailsRefactor } = useFlags();
  const updateLinkedEmailsUrl = linkedEmailsRefactor
    ? ENTITY_EMAIL_LINKS
    : LINK_EMAILS;

  const [value, setValue] = useState(initialValue);
  const [keywords, setKeywords] = useState('');
  const [options, setOptions] = useState([]);
  const [mode, setMode] = useState(enableMode || 'view');
  const [showLabels, setShowLabels] = useState(false);
  const [contactCardId, setContactCardId] = useState(null);
  const [contactCardUniqIdentifier, setContactCardUniqIdentifier] =
    useState(null);
  const [accountCardID, setAccountCardID] = useState(null);
  const [accountCardUniqIdentifier, setAccountCardUniqIdentifier] =
    useState(null);
  const [labelData, setLabelData] = useState(
    value?.length > 2 ? value.slice(0, 2) : value,
  );
  const [linkedData] = useState(
    entityType === 'account' ? accountContactLinked : contactAccountLinked,
  );
  // Changed Code to check the performance
  // const options = useAccountsandContactsStore((state) =>
  //   entityType === 'account' ? state.accounts : state.contacts,
  // );
  // const fetch = useAccountsandContactsStore((state) =>
  //   entityType === 'account' ? state.fetchAccounts : state.fetchContacts,
  // );

  useEffect(() => {
    setLabelData(value?.length > 2 ? value.slice(0, 2) : value);
    setShowLabels(false);
  }, [value]);

  useEffect(() => {
    setMode(enableMode);
  }, [enableMode]);

  useEffect(() => {
    if (options.length === 0) {
      fetchOptions();
    }
  }, []);

  useEffect(() => {
    fetchOptions();
  }, [keywords.length > 2, keywords.length === 0]);

  async function fetchOptions() {
    let url;
    if (entityType === 'account') {
      url = `${GET_ACCOUNTS_FOR_INVOICE}?from_email=true&keyword=${keywords}`;
    } else {
      url = `${GET_CONTACTS_FOR_INVOICE}?from_email=true&keyword=${keywords}`;
    }
    try {
      const request = await Axios.get(url);
      if (request) {
        setOptions(request.data.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  }

  const contactaccountCard = async (ids, type, uniqId) => {
    await contactaccountCardLeave();
    const isAccount =
      type === 'account' &&
      ids &&
      ids !== ' ' &&
      ids !== null &&
      ids !== undefined;
    const isAContact =
      type === 'contact' &&
      ids &&
      ids !== ' ' &&
      ids !== null &&
      ids !== undefined;
    if (isAccount || isAContact) {
      setAccountCardID(ids);
      setAccountCardUniqIdentifier(uniqId);
      setContactCardId(ids);
      setContactCardUniqIdentifier(uniqId);
    }
  };

  const contactaccountCardLeave = () => {
    setContactCardId(null);
    setContactCardUniqIdentifier(null);
    setAccountCardID(null);
    setAccountCardUniqIdentifier(null);
  };

  function handleChange(input) {
    setValue(input);
  }

  function handleInputChange(event) {
    setKeywords(event);
  }

  function handleEdit() {
    setMode('edit');
  }

  const removeExistingAssociations = useCallback(
    (recordsToLink) =>
      recordsToLink.filter(
        (selectedId) =>
          !initialValue.find(
            (initialRecord) => initialRecord.id === selectedId,
          ),
      ),
    [initialValue],
  );

  async function handleSave() {
    let idOrValueKey;
    if (entityType === 'account' || !contactAccountLinked) {
      idOrValueKey = 'value';
    } else if (entityType === 'contact') {
      idOrValueKey = 'id';
    } else {
      idOrValueKey = 'id';
    }
    setMode('view');

    const theOtherOption = idOrValueKey === 'value' ? 'id' : 'value';
    const recordsToLink = value.map(
      (item) => item[idOrValueKey] || item[theOtherOption],
    );

    const recordsToUnLink = initialValue.filter(
      (record) => !recordsToLink.includes(record.value),
    );
    let ids = [];
    try {
      if (recordsToUnLink.length) {
        ids = recordsToUnLink.map((item) => item.id);
        const apis = recordsToUnLink.map((record) =>
          Axios.delete(
            `${updateLinkedEmailsUrl}/${record.liscio_email_thread_link_id}`,
          ),
        );
        await Axios.all(apis);
      }
      if (recordsToLink.length) {
        const { cpa_id } = getStorageData();
        const newRecordsToLink = removeExistingAssociations(recordsToLink);
        const postBody = linkedEmailsRefactor
          ? {
              email_id: emailId,
              entity_email_thread_id: entityEmailThreadId,
              cpa_id,
              linked_entity_ids: newRecordsToLink,
              linked_entity_type: capitalize(entityType),
            }
          : {
              entity_ids: recordsToLink, // array of account ids or contact ids
              entity_type: entityType, // account or contact
              email_id: emailId,
            };
        await Axios.post(updateLinkedEmailsUrl, postBody);
      }
      if (ids.length > 0) {
        disableBox(ids);
      } else {
        disableBox();
      }
    } catch (error) {
      if (ids.length > 0) {
        disableBox(ids);
      } else {
        disableBox();
      }
    }
  }

  useEffect(() => {
    if (
      emailLinkedWith?.accounts?.length ||
      emailLinkedWith?.contacts?.length
    ) {
      const { contacts, accounts } = emailLinkedWith;

      setValue(entityType === 'contact' ? contacts : accounts);
    }
  }, [emailLinkedWith]);

  function disableBox(unlinkIds = []) {
    emailLinkedBox(
      entityType === 'account' ? 'add_to_account' : 'add_to_contact',
      false,
    );
    if (unlinkIds.length > 0) {
      refreshPage(entityType, unlinkIds);
    } else {
      refreshPage(entityType);
    }
  }

  const handleAccConData = () => {
    setLabelData(value);
    setShowLabels(true);
  };

  return (
    <StyledDiv>
      <div className="info" data-testid="linkedAccounts">
        <div>
          <span className="label">
            <i className="icon-Accounts" />
            {entityType === 'contact'
              ? 'Associated Contacts'
              : 'Associated Accounts'}
            <span className="divOption" />
          </span>
        </div>
        {mode === 'edit' ? (
          <div className="selectfield">
            <AssociateSelect
              mode={mode}
              value={value}
              linkedData={linkedData}
              options={options}
              handleChange={handleChange}
              handleInputChange={handleInputChange}
              component={Option}
              entityType={entityType}
              Option={Option}
              handleSave={handleSave}
            />
          </div>
        ) : null}
        {mode === 'view' ? (
          <div className="linkedData">
            {labelData &&
              labelData?.map((option, index) => (
                <span
                  data-tip
                  data-for={`${option.id}${
                    entityType === 'contact' ? 'global' : 'global_account'
                  }${labelData.indexOf(option)}_${index}`}
                  key={`${option.id}${labelData.indexOf(option)}_${index}`}
                  onMouseEnter={() =>
                    contactaccountCard(
                      option.id,
                      entityType,
                      `${labelData.indexOf(option)}_${index}`,
                    )
                  }
                  onClick={contactaccountCardLeave}
                  className="linkedData__label"
                >
                  {option.label}
                  {index !== value.length - 1 && ','}
                </span>
              ))}
            {value?.length > 2 && !showLabels && (
              <span onClick={handleAccConData}>+{value.length - 2} others</span>
            )}
          </div>
        ) : null}
        {mode === 'view' && (
          <div>
            <AssociateEdit handleEdit={handleEdit} />
          </div>
        )}
      </div>
      {contactCardId && contactCardUniqIdentifier ? (
        <ContactCard
          id={contactCardId}
          uniqId={contactCardUniqIdentifier}
          page="threaddetail"
        />
      ) : null}
      {accountCardID && accountCardUniqIdentifier ? (
        <AccountCard
          id={accountCardID}
          uniqId={accountCardUniqIdentifier}
          page="threaddetail"
        />
      ) : null}
    </StyledDiv>
  );
}
export default LinkedAccounts;
