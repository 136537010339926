import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, IconButton, Stack, Typography } from '@mui/material';
import styled from '@emotion/styled';

import TextField from 'common/TextField';

import { LeftArrow2Icon, RightArrow2Icon } from 'common/Icons';

const STextField = styled(TextField)(() => ({
  width: 45,
  height: 35,
  borderRadius: '2px',
}));

const SIconButton = styled(IconButton)(() => ({
  width: 35,
  height: 35,
  border: '2px solid #dfe3e8',
  borderRadius: 0,
  '&:hover': { border: '2px solid white' },
}));

function AccountListPagination(props) {
  const {
    page,
    startOffset,
    endOffset,
    numberOfRecords,
    numberOfPages,
    onPageNumberChange,
  } = props;

  const [iPage, setIPage] = useState(page);

  useEffect(() => {
    if (iPage !== page) setIPage(page);
  }, [page]);

  const onPageChange = (pageValue) => {
    let newPage = pageValue;
    if (newPage < 1) newPage = 1;
    if (newPage > numberOfPages) newPage = numberOfPages;

    setIPage(newPage);

    if (onPageNumberChange) onPageNumberChange(newPage);
  };

  const onPageFirst = () => {
    onPageChange(1);
  };

  const onPageLast = () => {
    onPageChange(numberOfPages);
  };

  const onPagePrev = () => {
    if (iPage > 1) {
      onPageChange(iPage - 1);
    }
  };

  const onPageNext = () => {
    if (iPage < numberOfPages) {
      onPageChange(iPage + 1);
    }
  };

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      pt={4}
      pb={4}
      pl={2}
      pr={2}
    >
      <Typography variant="gray_body2">
        Showing {startOffset} to{' '}
        {endOffset > numberOfRecords ? numberOfRecords : endOffset} of{' '}
        {numberOfRecords} entries
      </Typography>

      <Stack direction="row" alignItems="center" spacing={2}>
        <Button
          aria-describedby="first-page"
          variant="outlined"
          sx={{ height: 35 }}
          onClick={onPageFirst}
        >
          First
        </Button>
        <SIconButton disableRipple aria-label="prev-page" onClick={onPagePrev}>
          <LeftArrow2Icon style={{ fontSize: '0.45rem' }} />
        </SIconButton>
        <Typography variant="gray_body2">Page</Typography>
        <STextField
          type="number"
          value={page}
          onChange={(event) =>
            onPageChange(parseInt(event.target.value, 10) || 1)
          }
        />
        <Typography variant="gray_body2">{`of ${numberOfPages}`}</Typography>
        <SIconButton disableRipple aria-label="next-page" onClick={onPageNext}>
          <RightArrow2Icon style={{ fontSize: '0.45rem' }} />
        </SIconButton>
        <Button
          aria-describedby="last-page"
          variant="outlined"
          sx={{ height: 35 }}
          onClick={onPageLast}
        >
          Last
        </Button>
      </Stack>
    </Stack>
  );
}

AccountListPagination.propTypes = {
  page: PropTypes.number,
  startOffset: PropTypes.number,
  endOffset: PropTypes.number,
  numberOfRecords: PropTypes.number,
  numberOfPages: PropTypes.number,
  onPageNumberChange: () => {},
};

AccountListPagination.defaultProps = {
  page: 1,
  startOffset: 1,
  endOffset: 1,
  numberOfRecords: 0,
  numberOfPages: 0,
};

export default AccountListPagination;
