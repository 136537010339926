/* eslint-disable global-require */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { UseFormRegister, FieldErrors } from 'react-hook-form';
import { useState } from 'react';
import { Link } from '@mui/material';
import { Alert } from 'reactstrap';
import LostAccessDialog from './LostAccessDialog/LostAccessDialog';

export type MFAFormType = {
  two_factor_code: string;
};

export interface MFAFormContentProps {
  /**
   * Form error
   */
  error?: string;
  /**
   * Back to login handler
   */
  backToLogin?: () => void;
  /**
   * RHF register function
   */
  register: UseFormRegister<MFAFormType>;
  /**
   * RHF field errors
   */
  errors?: FieldErrors<MFAFormType>;
}

/**
 * This form content has been extracted to support both
 * a localized MFAForm component as well as an MFA Form
 * that relies on a shared FormContext.
 *
 * NOTE: DO NOT use a shared FormContext for multiple forms. If
 * you're using conditionals in a validation resolver without having
 * conditional fields, you're likely going down the wrong path.
 * It creates unnecessary dependencies and complexity that makes
 * components confusing to use and less portable. For the most part,
 * components should consider their props interface to be the
 * encapsulation boundary.
 */
export const MFAFormContent = ({
  error,
  backToLogin,
  register,
  errors,
}: MFAFormContentProps) => {
  const [showNeedHelpModal, setShowNeedHelpModal] = useState(false);

  const twoFactorCodeError = errors?.two_factor_code?.message;

  return (
    <>
      <h1>Enter Passcode</h1>
      <p className="text-center mb-3">
        Enter the code on your mobile passcode <br /> application
      </p>
      {error && <Alert color="danger">{error}</Alert>}
      <div>
        <div className={`form-group ${twoFactorCodeError && 'form-error'}`}>
          <input
            id="two_factor_code"
            type="text"
            inputMode="numeric"
            pattern="[0-9]{6}"
            className="form-control"
            autoComplete="new-password"
            placeholder="Enter Passcode"
            data-testid="two_factor_code"
            {...register('two_factor_code')}
          />

          <div className="text-danger error_label" id="mail">
            {twoFactorCodeError}
          </div>
        </div>
        <div className="clearfix" />
        <div className="form-group">
          <button
            type="submit"
            id="passcodeSubmit"
            name="passcode"
            className="btn btn-primary w-100"
          >
            Verify Code
          </button>
          <div className="crPage--bott text-center mt-3">
            <a
              href="#"
              onClick={() => setShowNeedHelpModal(true)}
              className="btn tptBtn"
            >
              <b className="text-primary">Need help logging in?</b>
            </a>
          </div>
          {backToLogin && (
            <div className="crPage--bott text-center mt-3">
              <span className="txtInfo">Back to </span>
              <Link href="#" onClick={backToLogin} className="btn tptBtn">
                <b>Login</b>
              </Link>
            </div>
          )}
        </div>
      </div>
      <LostAccessDialog
        open={showNeedHelpModal}
        handleClose={() => setShowNeedHelpModal(false)}
      />
    </>
  );
};
