import {
  InputBase,
  FormHelperText,
  FormControlProps,
  FormHelperTextProps,
  InputLabelProps,
  InputBaseProps,
} from '@mui/material';
import { randomString } from 'utilities/utils';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import { FormControlStyled } from './FormControlStyled';
import { InputLabelStyled } from './InputLabelStyled';

export type NumberFieldStyledProps = {
  InputBaseProps?: NumericFormatProps & InputBaseProps;
  error?: boolean;
  InputLabelProps?: InputLabelProps;
  label?: string;
  helperText?: string;
  FormHelperTextProps?: FormHelperTextProps;
  sx?: FormControlProps['sx'];
  onChange?: (number: number | null) => void;
  fullWidth?: boolean;
  value?: number | null;
} & FormControlProps;

export const NumberFieldStyled = ({
  error,
  InputBaseProps: InputProps,
  InputLabelProps: LabelProps,
  label,
  helperText,
  FormHelperTextProps: HelperTextProps,
  sx,
  fullWidth,
  onChange,
  value,
}: NumberFieldStyledProps) => {
  const inputLabelId = InputProps?.id ? InputProps.id : randomString(5);

  return (
    <FormControlStyled error={error} sx={sx}>
      {label && (
        <InputLabelStyled htmlFor={inputLabelId} {...LabelProps}>
          {label}
        </InputLabelStyled>
      )}
      <NumericFormat
        onValueChange={(v) => {
          onChange?.(v.floatValue || null);
        }}
        fullWidth={fullWidth}
        thousandSeparator
        valueIsNumericString
        decimalScale={2}
        id={inputLabelId}
        // @ts-ignore
        customInput={InputBase}
        value={value}
        {...InputProps}
      />
      {helperText && (
        <FormHelperText {...HelperTextProps}>{helperText}</FormHelperText>
      )}
    </FormControlStyled>
  );
};
