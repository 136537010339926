/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useLocation } from 'react-router-dom-latest';
import NewMessageEmail from 'components/Messages/NewMessageEmail';
import ActionsModal from './ActionsModal';

function ReplyWithLiscioMessage(props) {
  const location = useLocation();
  const {
    showModal,
    cancelActionsModel,
    showModalFor,
    getModelTitle,
    getModelViewContext,
    emailDetails,
    addEmails,
    closeActionsModel,
    email,
    handleEmailActions,
  } = props;
  if (showModal) {
    return (
      <ActionsModal
        isOpen={showModal}
        onCancel={cancelActionsModel}
        title={getModelTitle(showModalFor)}
        style={{ overflow: 'left', maxHeight: '23vh' }}
        showModalFooter={getModelViewContext(showModalFor) !== ''}
        handleSuccessBtn={() =>
          addEmails(
            emailDetails.email_id,
            getModelViewContext(showModalFor),
            emailDetails.entity_email_thread_id,
          )
        }
      >
        <NewMessageEmail
          {...props}
          closeModalInEmailPage={closeActionsModel}
          emailDetails={email}
          refreshEmailListPage={() => handleEmailActions('done', email)}
          viewContext="email"
          cancelActionsModel={cancelActionsModel}
          location={location}
        />
      </ActionsModal>
    );
  }
  return null;
}

export default ReplyWithLiscioMessage;
