/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
import axios from 'axios';
import Files from 'api/Files';
import { AlertMessage, getPageIndex, getStorageData } from 'utilities/utils';
import {
  SHOW_DOCUMENT_API_PATH,
  DOCUMENT_API_PATH,
  CONTACT,
  GET_ACCOUNTS_OF_CONTACT_API_PATH,
  DOCUMENTS_LIST_CONTACT,
  DELETE_DOCUMENTS,
  DOCUMENTS_LIST,
  ACCOUNT,
  GET_CONTACTS_OF_ACCOUNT_API_PATH,
  CANCEL_UPLOAD,
  UPDATE_DOCUMENT,
  CHANGE_FILE_NAME,
  REPLACE_DOCUMENT_URL,
  GET_DOWNLOAD_URL,
  UPDATE_NEW_DOCUMENT,
  ARCHIVE_FILES,
} from 'constants/constants';

class UploadFiles {
  static addUploadDocument(
    mydata,
    component,
    id,
    config,
    filename,
    type = 'manual',
    thirdpartyapp = null,
  ) {
    let request = null;
    const thirdparty = `&third_party_app=${thirdpartyapp}`
    const thirdparti = `?third_party_app=${thirdpartyapp}`
    if (type === 'manual') {
      if (id) {
        request = axios.post(
          `${DOCUMENT_API_PATH}?doc_type=Manual&is_draft=true&note_id=${id}${thirdparty}`,
          mydata,
          config,
        );
      } else {
        request = axios.post(
          `${DOCUMENT_API_PATH}?doc_type=Manual&is_draft=true${thirdparty}`,
          mydata,
          config,
        );
      }
    } else if (type === 'task' || type === 'message') {
      request = axios.post(DOCUMENT_API_PATH + thirdparti, mydata, config);
    } else if (type === 'note') {
      let noteId = '';
      if (
        component &&
        component.props &&
        component.props.match &&
        component.props.match.params &&
        component.props.match.params.id
      ) {
        noteId = `&note_id=${component.props.match.params.id}`;
      }
      request = axios.post(
        `${DOCUMENT_API_PATH}?doc_type=Note${noteId}${thirdparty}`,
        mydata,
        config,
      );
    } else if (type === 'edoc') {
      request = axios.post(
        `${DOCUMENT_API_PATH}?doc_type=Edoc&is_draft=true${thirdparty}`,
        mydata,
        config,
      );
    } else if (
      type === 'message_template' ||
      type === 'task_template' ||
      type === 'edoc_template' ||
      type === 'invoice_template'
    ) {
      request = axios.post(
        `${DOCUMENT_API_PATH}?doc_type=${type}&is_draft=true&is_private_to_firm=true${thirdparty}`,
        mydata,
        config,
      );
    }
    request
      .then((response) => {
        const index = component.state.uploadFiles.indexOf(filename);
        const uploadedFiles = component.state.uploadFiles;
        uploadedFiles.splice(index, 1);
        component.setState({ uploadFiles: uploadedFiles });
        if (response.data.status === 200) {
          const newDocuments = component.state.documents;
          newDocuments.push(response.data.data);
          component.setState({ documents: newDocuments });
          if (component.props.updateForm) {
            component.props.updateForm('documents', newDocuments);
          }
        } else if (response.data.message) {
          AlertMessage('error', response.data.message, 8000);
        } else {
          AlertMessage('error', 'Some error occurred. Please try later.', 3000);
        }
        component.state.uploadedFilesCount += 1;
        if (
          component.state.uploadedFilesCount ===
          component.state.uploadedFilesLength
        ) {
          component.setState({ isDocumentUpload: false });
          if (component.updateAutoTagData) {
            component.updateAutoTagData();
          }
        }
      })
      .catch((error) => {
        component.state.uploadedFilesCount += 1;
        if (
          component.state.uploadedFilesCount ===
          component.state.uploadedFilesLength
        ) {
          component.setState({ isDocumentUpload: false });
        }
        const index = component.state.uploadFiles.indexOf(filename);
        const uploadedFiles = component.state.uploadFiles;
        uploadedFiles.splice(index, 1);
        component.setState({ uploadFiles: uploadedFiles });
        if (axios.isCancel(error)) {
          // console.log('Request Cancelled');
        } else {
          AlertMessage('error', 'Some error occurred. Please try later.');
        }
      });
  }

  static deleteAttachment(id, component) {
    component.setState({ loading: true });
    let url = '';
    url = `${DOCUMENT_API_PATH}/${id}`;
    const request = axios.delete(url);
    request
      .then((response) => {
        if (response.data.status === 200) {
          if (response.data.comment_hash) {
            component.state.comments.unshift(response.data.comment_hash);
          }
          const documnts = [];
          component.state.documents.map((doc) => {
            if (doc.id !== id) {
              documnts.push(doc);
            }
            return documnts;
          });
          component.setState({ loading: false, documents: documnts });
          if (component.props.updateForm) {
            component.props.updateForm('documents', documnts);
          }
        } else {
          AlertMessage('error', response.data.message, 8000);
        }
      })
      .catch(() => {
        component.setState({ loading: false });
        AlertMessage('error', 'Some error occurred. Please try later.', 3000);
        return null;
      });
  }

  static getContactDetailsForUploadFiles(contacId, component) {
    component.setState({ loading: true });
    const contId = contacId;
    axios.get(`${CONTACT}/${contId}`).then((res) => {
      if (
        res.status === 200 &&
        res.data.status === 200 &&
        res.data.data &&
        res.data.data.length
      ) {
        const contactDetails = res.data.data[0];
        const conData = contactDetails.contact;
        const firstName = conData.first_name;
        const middleName = conData.middle_name ? conData.middle_name : '';
        const lastName = conData.last_name;
        const data = {
          value: conData.id,
          label: `${firstName} ${middleName} ${lastName}`,
          avatar: contactDetails.avatar,
        };
        component.setState({
          contactUploadFile: true,
          contactId: data,
          contactName: `${firstName} ${middleName} ${lastName}`,
        });
      }
    });
    const request = axios.get(`${GET_ACCOUNTS_OF_CONTACT_API_PATH}/${contId}`);
    request
      .then((response) => {
        component.setState({ accounts: response.data.data });
      })
      .catch(() => {
        component.setState({ loading: false });
      });
    const requestforlist = axios.get(
      `${DOCUMENTS_LIST_CONTACT}/?contact_id=${contacId}`,
    );
    requestforlist
      .then((response) => {
        const resdata = response.data;
        const resdataHash = resdata.response_hash;
        component.setState({
          loading: false,
          uploadFilesList: resdata.documents,
          listOfFiles: resdataHash.total_documents,
          paginationOffset: resdataHash.offset,
          paginationOff: resdataHash.off,
          totalDocuments: resdataHash.total_documents,
        });
      })
      .catch(() => {
        component.setState({ loading: false });
      });
  }

  static sortedData(sort, field, page, api, component, id = '', from = '') {
    component.setState({ loading: true });
    const commonEndpoint = `sort_by=${sort}&field=${field}&page=${page}${
      !api ? '' : api
    }`;
    let url;
    if (from === 'contact') {
      url = `${DOCUMENTS_LIST_CONTACT}?contact_id=${id}&${commonEndpoint}`;
    } else if (from === 'account') {
      url = `${DOCUMENTS_LIST}?account_id=${id}&${commonEndpoint}`;
    } else {
      url = `${DOCUMENTS_LIST_CONTACT}?${commonEndpoint}`;
    }
    const requestforlist = axios.get(url);
    requestforlist
      .then((response) => {
        const resData = response.data;
        if (resData.status === 200) {
          const resHash = resData.response_hash;
          component.setState(
            {
              loading: false,
              pagination: page - 1,
              editFileNameId: '',
              errors: {},
              currOpenedInput: '',
              currOpenedId: '',
              uploadFilesList: resData.documents,
              listOfFiles: resHash.total_documents,
              paginationOffset: resHash.offset,
              paginationOff: resHash.off,
              totalDocuments: resHash.total_documents,
              selectall: false,
              fileRemovedFromList: false,
            },
            () => {
              component.areAllContactsSelected();
              component.props.UploadFilesActions.handleFilesData(
                component.state,
              );
            },
          );
        } else {
          component.setState({ loading: false });
          AlertMessage('error', resData.message, 3000);
        }
      })
      .catch(() => {
        component.setState({ loading: false });
      });
  }

  static getAccountDetailsForUploadFiles(accounId, component) {
    component.setState({ loading: true });
    // TODO: __HOMEDATA-AUDIT
    const homeData = getStorageData();
    const accId = accounId;
    axios.get(`${ACCOUNT}/${accounId}`).then((res) => {
      if (res.status === 200 && res.data.status === 200) {
        const accountDetails = res.data.data;
        const details = accountDetails.account;
        component.setState({
          accountUploadFile: true,
          accountId: details.id,
          accountName: details.name,
        });
      }
    });
    const request = axios.get(
      `${GET_CONTACTS_OF_ACCOUNT_API_PATH}/${accId}/?note=true`,
    );
    request
      .then((response) => {
        component.setState({ contacts: response.data.data });
        if (!homeData.is_employee) {
          response.data.data.map((con) => {
            if (con.email === homeData.email) {
              const data = {
                value: con.id,
                label: con.label,
                avatar: con.avatar,
              };
              component.setState({
                contactAccountUploadFile: true,
                contactId: data,
                contactName: con.label,
              });
            }
            return null;
          });
        }
      })
      .catch(() => {
        component.setState({ loading: false });
      });
    const requestforlist = axios.get(
      `${DOCUMENTS_LIST}/?account_id=${accounId}`,
    );
    requestforlist
      .then((response) => {
        const resdata = response.data;
        const resHash = resdata.response_hash;
        component.setState({
          loading: false,
          uploadFilesList: resdata.documents,
          listOfFiles: resHash.total_documents,
          paginationOffset: resHash.offset,
          paginationOff: resHash.off,
          totalDocuments: resHash.total_documents,
        });
      })
      .catch(() => {
        component.setState({ loading: false });
      });
  }

  static deleteUploadedFile(mydata, component) {
    component.setState({ loading: true });
    let url = '';
    url = CANCEL_UPLOAD;
    const request = axios.post(url, mydata);
    request
      .then((response) => {
        if (response.data.status === 200) {
          component.setState({ loading: false });
        } else {
          component.setState({ loading: false });
          AlertMessage('error', response.data.message, 3000);
        }
      })
      .catch(() => {
        component.setState({ loading: false });
        AlertMessage('error', 'Some error occurred. Please try later.', 3000);
        return null;
      });
  }

  static deleteSelectedData(component) {
    component.setState({ loading: true });
    let url = '';
    url = DELETE_DOCUMENTS;
    const selectedlist = [];
    component.state.selectedlist.map((item) => selectedlist.push(item.id));
    const data = { document_ids: selectedlist };
    const manualIds = [];
    component.state.selectedlist.map((item) => {
      if (item.source === 'Manual') {
        manualIds.push(item.id);
      }
      return null;
    });
    const request = axios.put(url, data);
    request
      .then((response) => {
        if (response.data.status === 200) {
          let list = response.data.document_ids.length;
          if (component.state.listOfFiles !== null) {
            list = component.state.listOfFiles - list;
          }
          component.setState({
            loading: false,
            listOfFiles: list,
            selectedlist: [],
            masterarray: [],
            selectedrecords: [],
            selectedFilesDeleteModal: false,
          });
          const index = getPageIndex(
            component.state.uploadFilesList,
            component.state.pagination,
            manualIds,
          );
          if (component.state.contactUploadFile) {
            this.sortedData(
              component.state.sort,
              component.state.field,
              index,
              component.state.api,
              component,
              component.state.contactId.value,
              'contact',
            );
          } else if (component.state.accountUploadFile) {
            this.sortedData(
              component.state.sort,
              component.state.field,
              index,
              component.state.api,
              component,
              component.state.accountId,
              'account',
            );
          } else if (component.state.clientUploadFile) {
            this.sortedData(
              component.state.sort,
              component.state.field,
              index,
              component.state.api,
              component,
            );
          }
          AlertMessage('success', response.data.message, 3000);
        } else {
          component.setState({ loading: false });
          AlertMessage('error', response.data.message, 3000);
        }
      })
      .catch(() => {
        component.setState({ loading: false });
        AlertMessage('error', 'Some error occurred. Please try later.', 3000);
        return null;
      });
  }

  static getClientUploadFiles(component) {
    component.setState({ loading: true });
    const requestforlist = axios.get(DOCUMENTS_LIST_CONTACT);
    requestforlist
      .then((response) => {
        const responsedata = response.data.response_hash;
        const middleName = responsedata.middle_name
          ? responsedata.middle_name
          : '';
        const data = {
          value: responsedata.contact_id,
          label: `${responsedata.first_name} ${middleName} ${responsedata.last_name}`,
          avatar: responsedata.avatar,
        };
        component.setState({
          loading: false,
          accounts: responsedata.accounts,
          contactId: data,
          contactName: `${responsedata.first_name} ${middleName} ${responsedata.last_name}`,
          uploadFilesList: response.data.documents,
          listOfFiles: responsedata.total_documents,
          paginationOffset: responsedata.offset,
          paginationOff: responsedata.off,
          totalDocuments: responsedata.total_documents,
        });
      })
      .catch(() => {
        component.setState({ loading: false });
      });
  }

  static updateSingleFile(mydata, component) {
    const config = { headers: { App: 'Webapp' } };
    component.setState({ loading: true });
    let url = '';
    url = UPDATE_DOCUMENT;
    const request = axios.put(url, mydata, config);
    request
      .then((response) => {
        if (response.data.status === 200) {
          component.setState({
            loading: false,
            isEditDocument: false,
            mutiltagdropdownOpen: false,
            EditSubmit: true,
            editedTags: mydata.multi_tags,
            editedYear: mydata.year,
            editedMonth: mydata.month,
            editedFileName: mydata.file_name,
            updatedDownloadUrl: response.data.data.file_download_url,
            updatedFileUrl: response.data.data.file_url,
            propState: response.data.data,
            updateFileListData: true,
          });
          AlertMessage('success', response.data.message, 3000);
        } else {
          AlertMessage('error', response.data.message, 3000);
        }
      })
      .catch(() => {
        component.setState({ loading: false });
        AlertMessage('error', 'Some error occurred. Please try later.', 3000);
        return null;
      });
  }

  static DeleteDocument(item, component) {
    component.setState({ loading: true });
    const url = DELETE_DOCUMENTS;
    const data = { document_ids: item.id };
    const request = axios.put(url, data);
    request
      .then((response) => {
        if (response.data.status === 200) {
          component.setState({ loading: false });
          if (component.props.listOfFilesState) {
            component.props.listOfFilesState();
          }
          AlertMessage('success', response.data.message, 3000);
          component.props.onButtonClose(undefined, 'updateFileListData');
        } else {
          component.setState({ loading: false });
          AlertMessage('error', response.data.message, 3000);
        }
      })
      .catch(() => {
        component.setState({ loading: false });
        AlertMessage('error', 'Some error occurred. Please try later.', 3000);
        return null;
      });
  }

  static showOpenAttachment(c, component, isLink = false) {
    if (isLink) {
      c = JSON.parse(decodeURIComponent(c));
    }
    if (c.content_type === 'application/pdf') {
      const request = axios.get(`${REPLACE_DOCUMENT_URL}/${c.id}`);
      request.then((response) => {
        if (response.data.status === 200) {
          component.setState({
            showAttachmentModal: true,
            selectedAttachment: response.data.url,
          });
        }
      });
    } else {
      const request = axios.get(`${GET_DOWNLOAD_URL}/${c.id}`);
      request.then((response) => {
        if (response.data.status === 200) {
          window.location.href = response.data.url;
        }
      });
    }
  }

  static ChangeFileName(mydata, docId, component, event = '') {
    // console.log('in the delete function');
    component.setState({ loading: true });
    const url = `${CHANGE_FILE_NAME}/${docId}`;
    const request = axios.put(url, mydata);
    request
      .then((response) => {
        if (response.data.status === 200) {
          let idx = null;
          let j = {};
          let fil = '';
          component.state.uploadFilesList.map((file, index) => {
            if (file.id === docId) {
              const f = file;
              fil = `${docId}_${mydata.display_name}`;
              f.doc_name = mydata.display_name;
              idx = index;
              j = f;
            }
            return null;
          });
          const uploadedFiles = component.state.uploadFilesList;
          uploadedFiles.splice(idx, 1, j);
          let uploaded = component.state.selectedrecords;

          if (component.state.selectedrecords.length > 0) {
            if (component.state.oldFileName) {
              const filename = `${docId}_${component.state.oldFileName}`;
              const indx = component.state.selectedrecords.indexOf(filename);
              if (indx !== -1) {
                uploaded = component.state.selectedrecords;
                uploaded.splice(indx, 1, fil);
              }
            }
          }
          component.setState({
            uploadFilesList: uploadedFiles,
            selectedrecords: uploaded,
            loading: false,
            editFileNameId: '',
            currOpenedInput: '',
            currOpenedId: '',
            oldFileName: '',
          });
          if (event === 'blurevent') {
            AlertMessage('success', response.data.message, 3000);
          }
        } else {
          component.setState({ loading: false });
          if (event === 'blurevent') {
            AlertMessage('error', response.data.message, 3000);
          }
        }
      })
      .catch(() => {
        component.setState({ loading: false });
        AlertMessage('error', 'Some error occurred. Please try later.', 3000);
        return null;
      });
  }

  static uploadFiles(mydata, component) {
    const request = axios.post(UPDATE_NEW_DOCUMENT, mydata);
    request
      .then((response) => {
        if (response.data.status === 200) {
          const doc = [];
          component.props.data.documents.map((docs) => doc.push(docs.id));
          const data = {
            year: mydata.year,
            month: mydata.month,
            tags: mydata.tags,
            doc_ids: doc,
          };
          component.setState(
            {
              showFileUploadModal: false,
              loading: false,
            },
            () => {
              component.props.component.openPopUp();
              component.props.component.stateUpdate(
                component.state,
                component.props.dataProps ||
                  component.props.name === 'bulk' ||
                  component.props.notedetail
                  ? 'isFileModalTaskDetail'
                  : 'isFileModalSubmit',
                component.props.dataProps
                  ? response.data.comment_hash
                    ? response.data.comment_hash
                    : []
                  : data,
              );
            },
          );
          AlertMessage('success', 'Files uploaded successfully.', 3000);
        } else {
          component.setState({ loading: false }, () => {
            component.props.component.openPopUp();
            component.hideuploadFileModal();
          });
          AlertMessage('error', response.data.message, 3000);
        }
        if (document.getElementById('draftInput')) {
          document.getElementById('draftInput').focus(); // to focus out from editor
        }
      })
      .catch(() => {
        component.setState(
          {
            loading: false,
            showFileUploadModal: false,
          },
          () => {
            component.props.component.openPopUp();
            component.hideuploadFileModal();
          },
        );
        AlertMessage('error', 'Some error occurred. Please try later.');
      });
  }

  static archiveFile(component, data, from = '') {
    component.setState({ loading: true });
    const { filterStatus, pagination, uploadFilesList } = component.state;
    const request = axios.post(ARCHIVE_FILES, data);
    request
      .then((response) => {
        const resData = response.data;
        if (resData.status === 200) {
          component.setState({
            loading: false,
            filterStatusModal: false,
          });
          if (from === 'filesTab') {
            component.setState({ selectedrecords: [] });
            const pageIndex = getPageIndex(
              uploadFilesList,
              pagination,
              data.id,
            );
            Files.getFilesList(component, component.state.api, pageIndex);
          } else if (from === 'previewFile') {
            const fileStatus = filterStatus === 'active' ? 'archive' : 'active';
            component.setState({ filterStatus: fileStatus });
            if (component.props.listOfFilesState) {
              component.props.listOfFilesState(fileStatus);
            }
          } else {
            component.setState({
              selectedlist: [],
              masterarray: [],
              selectedrecords: [],
            });
            const index = getPageIndex(uploadFilesList, pagination, data.id);
            if (component.state.contactUploadFile) {
              this.sortedData(
                component.state.sort,
                component.state.field,
                index,
                component.state.api,
                component,
                component.state.contactId.value,
                'contact',
              );
            } else if (component.state.accountUploadFile) {
              this.sortedData(
                component.state.sort,
                component.state.field,
                index,
                component.state.api,
                component,
                component.state.accountId,
                'account',
              );
            } else if (component.state.clientUploadFile) {
              this.sortedData(
                component.state.sort,
                component.state.field,
                index,
                component.state.api,
                component,
              );
            }
          }
          const msg =
            filterStatus === 'active'
              ? 'Archived successfully'
              : 'Unarchived successfully';
          AlertMessage('success', msg, 3000);
        } else {
          component.setState({ loading: false });
          AlertMessage('error', resData.message, 3000);
        }
      })
      .catch(() => {
        component.setState({ loading: false });
        AlertMessage('error', 'Some error occurred. Please try later.', 3000);
        return null;
      });
  }

  static particularDocumentData(component, tokn) {
    const token = `?token=${tokn}`;
    const request = axios.get(SHOW_DOCUMENT_API_PATH + token);
    request
      .then((response) => {
        if (response.data.status === 200) {
          const resData = response.data.document;
          component.setState({
            uniqueRecords: resData.recipient,
            newFileSource: resData.source,
            fileShared: true,
          });
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  }
}
export default UploadFiles;
