/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import _ from 'lodash';
import axios from 'axios';
import { Modal, ModalBody } from 'reactstrap';
import ConfirmationModal from 'common/ConfirmationModal';
import {
  getCpaPreferences,
  AlertMessage,
  isUserEmployee,
  getStorageData,
} from 'utilities/utils';
import * as TaskApi from 'api/TaskApi';
import * as Config from 'constants/constants';
import PageNotFound from 'common/PageNotFound';
import { NAME, EMAIL } from 'utilities/regularexpressions';
import FileRecipientCreation from 'components/Files/FileRecipientCreation';
import { withStorageData } from 'utilities/withStorageData';

const dropdownToggle = (dropdownName, className) => {
  const addClass = className;
  const removeClass = className === 'show' ? 'hide' : 'show';
  const dropdown = document.getElementById(dropdownName);
  if (dropdown) {
    dropdown.classList.remove(removeClass);
    dropdown.classList.add(addClass);
  }
};
const noneStyle = { display: 'none' };
const blockStyle = { display: 'block' };

class InviteContact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      preferences: {},
      formdata: {
        // makePrivate: false,
        message:
          'Hi, I am inviting you to Liscio, a secure place to message and share sensitive files. Please activate your user account to get going.',
      },
      allContacts: [],
      allAccounts: [],
      contacts: [],
      accounts: [],
      errors: {},
      showRecords: {},
      savedRecords: [],
      loading: false,
      recipientDropdownToggle: false,
      contactHasAccount: false,
      showMessageInput: true, // newwww*****
      alertText: '',
      uniqueRecords: [],
      fileUploadContactSide: false,
      fileUploadAccountSide: false,
      newRecordsCreated: false,
      contactDetails: {},
      accountDetails: {},
      cstMembers: [],
      documents: [], // documents to share file
      unauthorisedAccess: false,
      disableToField: false,
    };
  }

  UNSAFE_componentWillMount() {
    document.title = 'Invite User';
    const preferences = getCpaPreferences();
    // TODO: __HOMEDATA-AUDIT
    const storageData = getStorageData();
    this.setState({
      preferences,
      storageData,
    });
    if (isUserEmployee()) {
      this.setState({ unauthorisedAccess: true });
    }
  }

  componentDidMount() {
    if (this.state.unauthorisedAccess) {
      return false;
    }
    const { location } = this.props;
    if (
      location &&
      location.state &&
      location.state !== undefined &&
      location.state.contact
    ) {
      const dataObject = {};
      dataObject.contact = location.state.contact;
      dataObject.account = location.state.contact.accounts[0];
      this.setState(
        {
          showRecords: dataObject,
          disableToField: true,
        },
        () => {
          this.validateUniqueRecord(); // Validates the uniqueness of records
        },
      );
    } else {
      // const { storageData } = this.state;
      TaskApi.getNewContacts(this);
    }
  }

  updateMessage(fieldname, inputvalue) {
    const { formdata } = this.state;
    let message = formdata.message || '';
    if (fieldname === 'message') {
      message = inputvalue;
    }
    return message;
  }

  updateForm = (fieldname, inputvalue, block = '') => {
    const value = inputvalue;
    const message = this.updateMessage(fieldname, inputvalue);
    this.setState(
      {
        formdata: { ...this.state.formdata, [fieldname]: value, message },
        errors: {},
      },
      () => {
        const { savedRecords } = this.state;
        if (block === 'contact') {
          delete savedRecords.contact;
          if (fieldname === 'firstname' || fieldname === 'lastname') {
            this.setState({
              contactInput: `${this.state.formdata.firstname} ${this.state.formdata.lastname}`,
            });
          }
        } else if (block === 'account') {
          delete savedRecords.account;
        }
        this.setState({ savedRecords });
      },
    );
    if (fieldname === 'email') {
      clearTimeout(this.typingTime);
      this.typingTime = setTimeout(() => {
        this.validatePrimaryEmail();
      }, 400);
    } else if (fieldname === 'accountname' || fieldname === 'entityType') {
      clearTimeout(this.typingTime);
      this.typingTime = setTimeout(() => {
        this.validateAccountName();
      }, 400);
    }
  };

  validatePrimaryEmail() {
    const { formdata } = this.state;
    if (formdata.email) {
      axios
        .post(Config.CHECK_PRIMARY_EMAIL, {
          primary_email: formdata.email.toLowerCase(),
        })
        .then((res) => {
          if (res.data.status === 200) {
            this.setState({
              errors: {
                ...this.state.errors,
                email: res.data.message ? res.data.message : 'Already used',
              },
            });
          } else {
            const { errors } = this.state;
            delete errors.email;
            this.setState({ errors: { ...errors } });
          }
        });
    }
  }

  validateAccountName() {
    const { formdata } = this.state;
    if (formdata.accountname) {
      const request = axios.get(
        `${Config.CHECK_ACCOUNT}?name=${formdata.accountname}`,
      );
      request.then((res) => {
        if (res.data.status === 200) {
          this.setState({
            errors: { ...this.state.errors, accountname: res.data.message },
          });
        } else {
          const { errors } = this.state;
          delete errors.accountname;
          this.setState({ errors: { ...errors } });
        }
      });
    }
  }

  validateUniqueRecord() {
    const { showRecords } = this.state;
    let { uniqueRecords } = this.state;
    if (!_.isEmpty(showRecords)) {
      uniqueRecords.push(showRecords);
      uniqueRecords = _.uniqBy(uniqueRecords, (elem) => {
        if (elem.account && elem.contact) {
          return [elem.account.value, elem.contact.id].join();
        }
        if (!elem.account) {
          return [elem.contact.id].join();
        }
        if (!elem.contact) {
          return [elem.account.value].join();
        }
      });
      this.setState({ uniqueRecords });
    }
  }

  handleContactChange = (fieldname, input) => {
    const { formdata, fileUploadAccountSide } = this.state;
    if (input && !fileUploadAccountSide) {
      if (_.has(input, 'accounts')) {
        this.setState({
          accounts: input.accounts,
          contactHasAccount: true,
        });
      } else {
        formdata.accountId = null;
        this.setState({
          accounts: this.state.allAccounts,
          contactHasAccount: false,
          formdata,
        });
      }
    } else if (!formdata.accountId) {
      this.setState({
        contacts: this.state.allContacts,
        accounts: this.state.allAccounts,
      });
    }
    this.setState({
      formdata: { ...this.state.formdata, [fieldname]: input },
      contactInput: '',
    });
  };

  handleAccountChange = (fieldname, input) => {
    const { formdata, fileUploadContactSide, allContacts } = this.state;
    const component = this;
    if (input && !fileUploadContactSide) {
      const con = [];
      if (allContacts.length) {
        allContacts.map((data) => {
          if (data.accounts.length) {
            data.accounts.map((item) => {
              if (item.value === input.value) {
                con.push(data);
                // return true;
              }
              return null;
            });
          }
          return null;
        });
      }
      component.setState({ contacts: con });
    } else if (!formdata.contactId) {
      this.setState({
        accounts: this.state.allAccounts,
        contacts: this.state.allContacts,
      });
    }
    this.setState({
      formdata: {
        ...this.state.formdata,
        [fieldname]: input,
        accountname: '',
        entityType: {},
      },
      accountInput: '',
    });
  };

  handleBlur = (fieldname) => {
    const inputValue = this.state[fieldname] || '';
    const split = inputValue.split(' ');
    const secondPart = split.slice(1, split.length).join(' '); // lastname
    const { formdata } = this.state;
    if (fieldname === 'contactInput') {
      if (split[0]) {
        formdata.firstname = split[0]; // firstname
      } else {
        formdata.firstname = '';
      }
      formdata.lastname = secondPart;
      formdata.email = '';
    }
    if (fieldname === 'accountInput') {
      if (inputValue) {
        formdata.accountname = inputValue;
      } else {
        formdata.accountname = '';
      }
    }
    this.setState({ formdata }, () => {
      clearTimeout(this.typingTime);
      this.typingTime = setTimeout(() => {
        this.validateAccountName();
      }, 400);
    });
  };

  handleContactInput = (input, action) => {
    input = action.action === 'input-change' ? input : action.prevInputValue;
    const { formdata, fileUploadAccountSide, newRecordsCreated, allContacts } =
      this.state;
    const { match } = this.props;
    const component = this;
    this.setState({ contactInput: input, errors: {} });
    clearTimeout(this.typingTime);
    this.typingTime = setTimeout(() => {
      this.handleBlur('contactInput');
    }, 400);
    if (fileUploadAccountSide && newRecordsCreated && input.length === 1) {
      const { id } = match.params;
      TaskApi.ContactsOfAccount(this, id, 'uploadfile');
      this.setState({ newRecordsCreated: false });
    } else if (input && !formdata.accountId && !fileUploadAccountSide) {
      if (input && !formdata.accountId) {
        if (input.length <= 2) {
          formdata.contactId = null;
          component.setState({
            contacts: component.state.allContacts,
            formdata,
          });
        } else {
          const con = [];
          const value = input;
          const filter = value.toUpperCase();
          for (let i = 0; i < allContacts.length; i += 1) {
            if (allContacts[i].label.toUpperCase().indexOf(filter) > -1) {
              con.push(allContacts[i]);
            }
          }
          component.setState({ contacts: con });
        }
      }
    }
  };

  handleAccountInput = (input, action) => {
    input = action.action === 'input-change' ? input : action.prevInputValue;
    const { formdata, fileUploadContactSide, newRecordsCreated, allAccounts } =
      this.state;
    const { match } = this.props;
    const component = this;
    this.setState({ accountInput: input, errors: {} });
    clearTimeout(this.typingTime);
    this.typingTime = setTimeout(() => {
      this.handleBlur('accountInput');
    }, 400);
    if (fileUploadContactSide && newRecordsCreated && input.length === 1) {
      const { id } = match.params;
      TaskApi.AccountsOfContact(this, id, 'uploadfile');
      this.setState({ newRecordsCreated: false });
    } else if (!fileUploadContactSide) {
      if (
        input.length >= 2 &&
        (!formdata.contactId || !this.state.contactHasAccount)
      ) {
        const acc = [];
        const value = input;
        const filter = value.toUpperCase();
        for (let i = 0; i < allAccounts.length; i += 1) {
          if (allAccounts[i].label.toUpperCase().indexOf(filter) > -1) {
            acc.push(allAccounts[i]);
          }
        }
        component.setState({ accounts: acc });
      } else if (input.length <= 2) {
        formdata.accountId = null;
        component.setState({
          accounts: component.state.allAccounts,
          formdata,
        });
      }
    }
  };

  handleInputBlur = (action = '', block = '', field = '') => {
    const { formdata, errors } = this.state;
    if (block === 'contact') {
      if (!formdata.firstname && action === 'submit') {
        errors.firstname = 'Required';
      } else if (formdata.firstname && !NAME.test(formdata.firstname)) {
        errors.firstname = 'Enter valid name';
      }
      if (!formdata.lastname && action === 'submit') {
        errors.lastname = 'Required';
      } else if (formdata.lastname && !NAME.test(formdata.lastname)) {
        errors.lastname = 'Enter valid name';
      }
      if (!formdata.email && action === 'submit') {
        errors.email = 'Required';
      } else if (formdata.email && !EMAIL.test(formdata.email)) {
        errors.email = 'Invalid Email';
      } else if (formdata.email) {
        this.validatePrimaryEmail();
      }
    }
    if (block === 'account') {
      if (action === 'submit' && !formdata.accountname) {
        errors.accountname = 'Required';
      }
      if (
        action === 'submit' &&
        (!formdata.entityType ||
          (formdata.entityType && !Object.keys(formdata.entityType).length))
      ) {
        errors.entityType = 'Required';
      }
    }
    this.setState({ errors }, () => {
      if (field === 'email' && !errors.email) {
        this.validatePrimaryEmail();
      }
    });
    if (Object.keys(errors).length) {
      return false;
    }
    return true;
  };

  handleCancel = (block, e) => {
    e.preventDefault();
    const { formdata } = this.state;
    if (block === 'contact' || block === 'all') {
      formdata.firstname = '';
      formdata.lastname = '';
      formdata.email = '';
      this.setState({ contactInput: '' });
    }
    if (block === 'account' || block === 'all') {
      formdata.accountname = '';
      formdata.entityType = {};
      this.setState({ accountInput: '' });
    }
    if (block === 'all') {
      formdata.contactId = null;
      formdata.accountId = null;
      this.setState(
        {
          contacts: this.state.allContacts,
          accounts: this.state.allAccounts,
          savedRecords: [],
        },
        () => {
          this.recipientDropdownToggle();
        },
      );
    }
    this.setState({
      formdata,
      errors: {},
    });
    if (block === 'contact' || block === 'account') {
      const dropdownName =
        block === 'contact' ? 'recipientDropdown' : 'fileAccountDropdown';
      dropdownToggle(dropdownName, 'hide');
    }
  };

  handleSave = (block, e) => {
    if (e) {
      e.preventDefault();
    }
    const validate = this.handleInputBlur('submit', block);
    if (validate) {
      const { formdata, savedRecords } = this.state;
      if (block === 'contact') {
        const data = {
          firstname: formdata.firstname,
          lastname: formdata.lastname,
          email: formdata.email,
        };
        savedRecords.contact = data;
        this.setState({ contactInput: '' });
      }
      if (block === 'account') {
        const data = {
          accountname: formdata.accountname,
          entityType: formdata.entityType,
        };
        savedRecords.account = data;
        this.setState({ accountInput: '' });
      }
      this.setState({ savedRecords }, () => {
        const dropdownName =
          block === 'contact' ? 'recipientDropdown' : 'fileAccountDropdown';
        dropdownToggle(dropdownName, 'hide');
      });
    }
  };

  handleSubmitValidation() {
    const { formdata, savedRecords } = this.state;
    this.handleSave('contact');
    if (
      !savedRecords.contact &&
      (!formdata.contactId || formdata.contactId === null)
    ) {
      this.setState({ alertText: 'Please select or add new recipient' }, () => {
        dropdownToggle('recipientDropdown', 'show');
      });
      return false;
    }
    if (
      !savedRecords.account &&
      (!formdata.accountId || formdata.accountId === null)
    ) {
      this.setState({ alertText: 'Please select account' }, () => {
        dropdownToggle('recipientDropdown', 'show');
      });
      return false;
    }
    if (
      (formdata.firstname || formdata.lastname || formdata.email) &&
      !savedRecords.contact &&
      (!formdata.contactId || formdata.contactId === null)
    ) {
      this.setState({ alertText: 'Please select or add new recipient' }, () => {
        dropdownToggle('recipientDropdown', 'show');
      });
      return false;
    }
    if (
      (formdata.accountname ||
        (formdata.entityType && Object.keys(formdata.entityType).length)) &&
      !savedRecords.account &&
      (!formdata.accountId || formdata.accountId === null)
    ) {
      this.setState({ alertText: 'Please select account' }, () => {
        dropdownToggle('recipientDropdown', 'show');
      });
      return false;
    }
    if (
      !savedRecords.account &&
      !savedRecords.contact &&
      (!formdata.contactId || formdata.contactId === null) &&
      (!formdata.accountId || formdata.accountId === null)
    ) {
      this.setState(
        { alertText: 'Please select or save recipient/account' },
        () => {
          dropdownToggle('recipientDropdown', 'show');
        },
      );
      return false;
    }
    return true;
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { formdata, errors, savedRecords } = this.state;
    let { newRecordsCreated } = this.state;
    const component = this;
    const validate = this.handleSubmitValidation();

    if (validate) {
      const dataObject = {};
      const mydata = {
        account: {},
        contact: {},
      };
      if (formdata.contactId && formdata.accountId && !savedRecords.length) {
        // both dropdown selected, not created
        dataObject.contact = formdata.contactId;
        dataObject.account = formdata.accountId;
        mydata.contact_id = formdata.contactId.id;
        mydata.account_id = formdata.accountId.value;
        newRecordsCreated = false;
      } else if (
        formdata.accountId &&
        !formdata.contactId &&
        savedRecords.contact
      ) {
        // account selected, contact created
        // dataObject.account = formdata.accountId;
        mydata.contact.first_name = savedRecords.contact.firstname;
        mydata.contact.last_name = savedRecords.contact.lastname;
        mydata.contact.primary_email = savedRecords.contact.email;
        mydata.account_id = formdata.accountId.value;
        newRecordsCreated = true;
      }
      if (
        (Object.keys(mydata.contact).length ||
          Object.keys(mydata.account).length ||
          mydata.contact_id ||
          mydata.account_id) &&
        newRecordsCreated !== false
      ) {
        component.setState({ loading: true });
        const request = axios.post(Config.CREATE_CONTACT_AND_RELATION, mydata);
        // component.setState({ loading: false });
        request.then((res) => {
          component.setState({ loading: false });
          if (res.data.status === 200) {
            if (Object.keys(res.data.contact).length) {
              dataObject.account = formdata.accountId;
              dataObject.contact = res.data.contact;
              savedRecords.contact.id = res.data.contact.id;
            }

            if (res.data.rel_error_message && res.data.contact_error_message) {
              AlertMessage('error', res.data.contact_error_message);
              delete formdata.accountId;
              delete formdata.contactId;
              delete formdata.firstname;
              delete formdata.lastname;
              delete formdata.email;
              delete formdata.accountname;
              delete formdata.entityType;
              this.setState({ savedRecords: [] }, () => {
                this.recipientDropdownToggle();
              });
            } else if (res.data.contact_error_message) {
              AlertMessage('error', res.data.contact_error_message);
              delete savedRecords.contact;
              errors.email = res.data.contact_error_message;
              this.setState(
                {
                  errors,
                  savedRecords,
                },
                () => {
                  dropdownToggle('recipientDropdown', 'show');
                },
              );
            } else if (res.data.rel_error_message) {
              AlertMessage('error', res.data.rel_error_message);
              delete formdata.accountId;
              delete formdata.contactId;
              delete formdata.firstname;
              delete formdata.lastname;
              delete formdata.email;
              delete formdata.accountname;
              delete formdata.entityType;
              this.setState({ savedRecords: [] }, () => {
                this.recipientDropdownToggle();
              });
            } else {
              if (Object.keys(res.data.contact).length) {
                AlertMessage('success', 'Created Successfully');
              }
              delete formdata.accountId;
              delete formdata.contactId;
              delete formdata.firstname;
              delete formdata.lastname;
              delete formdata.email;
              delete formdata.accountname;
              delete formdata.entityType;
              this.setState({ savedRecords: [] }, () => {
                this.recipientDropdownToggle();
              });
            }
            this.setState(
              {
                showRecords: dataObject,
                newRecordsCreated,
              },
              () => {
                this.validateUniqueRecord(); // Validates the uniqueness of records
              },
            );
          } else {
            AlertMessage('error', res.data.message);
          }
        });
      } else {
        delete formdata.accountId;
        delete formdata.contactId;
        delete formdata.firstname;
        delete formdata.lastname;
        delete formdata.email;
        delete formdata.accountname;
        delete formdata.entityType;
        this.setState(
          {
            showRecords: dataObject,
            savedRecords: [],
            newRecordsCreated: false,
          },
          () => {
            this.validateUniqueRecord(); // Validates the uniqueness of records
            this.recipientDropdownToggle();
          },
        );
      }
      this.setState({
        formdata,
        contacts: this.state.allContacts,
        accounts: this.state.allAccounts,
        contactInput: '',
        accountInput: '',
        contactHasAccount: false,
        // savedRecords: [],
      });
    }
  };

  removeSelectedRecord = (each, index) => {
    const { formdata } = this.state;
    const selectedRecords = this.state.uniqueRecords;
    delete formdata.accountId;
    delete formdata.contactId;
    delete formdata.firstname;
    delete formdata.lastname;
    delete formdata.email;
    delete formdata.accountname;
    delete formdata.entityType;
    if (index > -1) {
      selectedRecords.splice(index, 1);
    }
    this.setState({
      formdata,
      showRecords: {},
      savedRecords: [],
      uniqueRecords: selectedRecords,
      contacts: this.state.allContacts,
      accounts: this.state.allAccounts,
      contactInput: '',
      accountInput: '',
    });
  };

  removeSavedRecord = (inputType) => {
    const { savedRecords, formdata } = this.state;
    if (inputType === 'contactInput') {
      delete savedRecords.contact;
      delete formdata.firstname;
      delete formdata.lastname;
      delete formdata.email;
      this.setState({ contactInput: '' });
    } else if (inputType === 'accountInput') {
      delete savedRecords.account;
      delete formdata.accountname;
      delete formdata.entityType;
      this.setState({ accountInput: '' });
    }
    this.setState({
      savedRecords,
      formdata,
    });
  };

  recipientDropdownToggle = (toggle = undefined) => {
    const { fileUploadAccountSide, fileUploadContactSide, formdata } =
      this.state;
    this.setState(
      {
        recipientDropdownToggle:
          toggle !== undefined ? toggle : !this.state.recipientDropdownToggle,
      },
      () => {
        if (
          fileUploadContactSide &&
          Object.keys(this.state.contactDetails).length
        ) {
          formdata.contactId = this.state.contactDetails;
        } else if (
          fileUploadAccountSide &&
          Object.keys(this.state.accountDetails).length
        ) {
          formdata.accountId = this.state.accountDetails;
        }
        this.setState({ formdata });
      },
    );
  };

  handleCancelUpload = () => {
    const { uniqueRecords } = this.state;
    if (uniqueRecords && uniqueRecords.length) {
      this.setState({
        cancelUploadText: 'Are you sure you want to cancel.',
        messageText: 'This action cannot be undone. Proceed?',
      });
    } else {
      this.props.navigate(-1);
    }
  };

  handleInviteValidation() {
    const { formdata, uniqueRecords } = this.state;
    const errors = {};
    if (!uniqueRecords || uniqueRecords.length === 0) {
      errors.toField = 'Required';
    }

    if (!formdata.message || (formdata.message && !formdata.message.length)) {
      errors.message = 'Required';
    }

    this.setState({ errors });
    if (Object.keys(errors).length) {
      return false;
    }
    return true;
  }

  handleInvite = () => {
    const validate = this.handleInviteValidation();
    const component = this;
    if (validate) {
      const { formdata, uniqueRecords } = this.state;
      const contactIds = [];
      uniqueRecords.map((each) => {
        contactIds.push(each.contact.id);
        return contactIds;
      });
      const uniqueContactIds = _.uniqBy(contactIds);
      const data = {
        ids: uniqueContactIds,
        message: formdata.message,
      };
      const records = [];
      component.setState({ loading: true });
      const request = axios.post(Config.INVITE_RELATED_CONTACT_TO_LISCIO, data);
      request
        .then((res) => {
          component.setState({ loading: false });
          if (res.data.status === 200) {
            AlertMessage('success', res.data.message);
            if (res.data.error && res.data.error.length) {
              res.data.error.map((item) => {
                const id = Object.keys(item)[0];
                const idd = parseInt(id, 10);
                uniqueRecords.map((each) => {
                  if (idd === each.contact.id) {
                    records.push(each);
                  }
                  return records;
                });
                this.setState({ uniqueRecords: records });
                return null;
              });
            } else {
              this.props.navigate(-1);
            }
          } else {
            AlertMessage('error', res.data.message);
            this.props.navigate(-1);
          }
        })
        .catch((error) => {
          component.setState({ loading: false });
          console.log('error', error);
        });
    }
  };

  render() {
    const { loading, formdata, errors, unauthorisedAccess, disableToField } =
      this.state;
    if (unauthorisedAccess) {
      return <PageNotFound name="message" message="(Unauthorized Access)" />;
    }
    return (
      <div>
        <Modal
          className="customModal customModal--w700 customModal--nopadding InviteContactModal"
          isOpen
        >
          <div id="loading" style={loading ? blockStyle : noneStyle} />
          <div className="ModalHeader">
            <button
              onClick={() => this.props.navigate(-1)}
              type="button"
              className="close"
              aria-label="Close"
            >
              <i className="icon-close2" aria-hidden="true" />
            </button>
          </div>
          <ModalBody className="modalContent InviteContactModal__Body">
            <div className="modalContent--inner">
              <div className="modalContent--header">
                <h5 className="modalContent--header__heading">Invite User</h5>
              </div>
              <div className="modalForm invForm">
                <form>
                  <div className="row">
                    <FileRecipientCreation
                      data={this.state}
                      formdata={formdata}
                      preferences={this.state.preferences}
                      updateForm={this.updateForm}
                      handleContactChange={this.handleContactChange}
                      handleContactInput={this.handleContactInput}
                      handleAccountChange={this.handleAccountChange}
                      handleAccountInput={this.handleAccountInput}
                      handleInputBlur={this.handleInputBlur}
                      handleCancel={this.handleCancel}
                      handleSave={this.handleSave}
                      handleSubmit={this.handleSubmit}
                      removeSelectedRecord={this.removeSelectedRecord}
                      removeSavedRecord={this.removeSavedRecord}
                      recipientDropdownToggle={this.recipientDropdownToggle}
                      disableToField={disableToField || ''}
                      enablePlusIcon
                    />
                    <div className="form-group col-12">
                      <label htmlFor="tagslabel">Message</label>
                      <div
                        className={`labelValue ${
                          errors.message ? 'form-error' : ''
                        }`}
                      >
                        <textarea
                          rows="3"
                          cols="50"
                          name="message"
                          value={formdata.message || ''}
                          onChange={(e) =>
                            this.updateForm('message', e.target.value)
                          }
                          className="form-control"
                        />
                        <span className="error_label">
                          {errors.message ? errors.message : ''}
                        </span>
                      </div>
                    </div>
                    <div className="col-12 justify-content-end formbtn justify-content-end">
                      <div className="btn-wrap btn--leftspace">
                        <button
                          type="button"
                          className="btn btn-outline-light"
                          onClick={this.handleCancelUpload}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          onClick={this.handleInvite}
                          className="btn btn-primary"
                        >
                          Invite
                        </button>
                      </div>
                    </div>
                    <ConfirmationModal
                      isOpen={
                        this.state.alertText && this.state.alertText.length
                      }
                      headerText={this.state.alertText}
                      messageText=""
                      noBtnClick={() => this.setState({ alertText: '' })}
                      yesBtnClick={() => this.setState({ alertText: '' })}
                      yesBtnText="OK"
                    />
                    <ConfirmationModal
                      isOpen={
                        this.state.cancelUploadText &&
                        this.state.cancelUploadText.length
                      }
                      headerText={this.state.cancelUploadText}
                      messageText={this.state.messageText || ''}
                      noBtnClick={() => this.setState({ cancelUploadText: '' })}
                      yesBtnClick={() => this.props.navigate(-1)}
                      yesBtnText="Yes"
                      noBtnText="No"
                    />
                  </div>
                </form>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default withStorageData(InviteContact);
