import { Typography } from '@mui/material';

export type WorkflowItemTitleProps = {
  prompt: string;
};

export function WorkflowItemTitle({ prompt }: WorkflowItemTitleProps) {
  return (
    <Typography variant="h4" paddingBottom="4px">
      {prompt}
    </Typography>
  );
}
