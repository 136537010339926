import React, { useEffect, useState } from 'react';
import { FILE_TYPE_HELP_DESK_LINK, UNSUPPORTED_FILE_TYPES } from './constants';
import { icon, extension, SmartVaultItem } from './utils';

type Documents = {
  id: number,
  doc_name: string
}

type ExportWidgetBodyProps = {
  filesAndFolders: {
    children: SmartVaultItem[]
  };
  formatDate: (date: string) => Date
  exploreDirectory: (e: React.MouseEvent<HTMLDivElement, MouseEvent>, item: SmartVaultItem) => void;
  isLoading: boolean;
  loading: () => void;
  sortBy: string;
  sortDirection: string;
  handleSorting: (sort: string) => void;
  selectedRecords: Documents[];
  selectedRecordIds: number[];
}

export default function ExportWidgetBody ({
  formatDate,
  exploreDirectory,
  isLoading,
  loading,
  sortBy,
  sortDirection,
  handleSorting,
  filesAndFolders,
  selectedRecords,
  selectedRecordIds
}: ExportWidgetBodyProps) {

  const [showExcludedFileNotification, setShowExcludedFileNotification] = useState(false)
  const [excludedFileNames] = useState<string[]>([]);

  const filterFileTypes = () => {
    selectedRecords.forEach((file) => {
      const excludeFile: boolean = UNSUPPORTED_FILE_TYPES.includes(extension(file.doc_name))
      if (excludeFile) {
        excludedFileNames.push(file.doc_name)
      } else {
        selectedRecordIds.push(file.id)
      }
    })

    if (excludedFileNames.length) setShowExcludedFileNotification(true)
  }

  useEffect(() => {
    filterFileTypes()
  }, []);

  const invalidFilesMessage =
    <div className="invalid-file-message">
      <div>
        The following files were removed from export due to incompatible file types:
        <ul className="mb-1">
          {excludedFileNames.map((file) => <li>{file}</li>)}
        </ul>
        You can find a list of all unsupported file types
        <a href={FILE_TYPE_HELP_DESK_LINK} target="_blank" rel="noreferrer">here</a>
      </div>
    </div>

  const headerContent = (
    <div>
      {showExcludedFileNotification && invalidFilesMessage}
      <div className="item-grid">
        <div
          className={`pb-filemng-paragraphs source-name header ${sortBy === 'name' ? 'active' : ''}`}
          onClick={() => handleSorting('name')}
        >
          Folder name
          {sortBy === 'name' && <i className={`icon-${sortDirection === 'asc' ? 'down' : 'up'}-arrow`} />}
        </div>
        <div
          className={`pb-filemng-paragraphs source-date header ${sortBy === 'modified_date' ? 'active' : ''}`}
          onClick={() => handleSorting('modified_date')}
        >
          Date Modified
          {sortBy === 'modified_date' && <i className={`icon-${sortDirection === 'asc' ? 'down' : 'up'}-arrow`} />}
        </div>
      </div>
    </div>
  )

  const directoryPath = (uri: string) =>
    uri.split('/').slice(3, -1).join(' > ')

  const folderRows = (
    (filesAndFolders.children || []).map(item => (
      <div
        style={{ cursor: item.nodeType === 'FileNodeType' ? 'not-allowed' : 'pointer' }}
        className="item-grid"
        key={item.uri}
      >
        <div
          style={{ display: 'flex', width: '100%' }}
          onClick={(e) => item.nodeType !== 'FileNodeType' && exploreDirectory(e, item) }
        >
          <div className="mr-2">
            {icon(item)}
          </div>
          <p className="pb-filemng-paragraphs source-name" title={item.name}>
            {item.name}
          </p>
          <p className="pb-filemng-paragraphs source-path" title={`Directory: ${directoryPath(item.uri)}`}>
            {directoryPath(item.uri)}
          </p>
          <p className="pb-filemng-paragraphs source-date"
             title={`Modified on: ${formatDate(item.modifiedOn)}`}>
            {formatDate(item.modifiedOn).toString()}
          </p>
        </div>
      </div>
    ))
  )

  return (
    isLoading ? loading() : [headerContent, folderRows]
  )
}
