import { FAILED, OVERDUE, PAID, UNPAID, VOID } from 'constants/constants';
import React from 'react';
import { isClient } from 'utilities/utils';

const InvoiceTaskDetailsDropdown = ({
  editInvoice,
  voidInvoice,
  markAsPaid,
  manageRecurringInvoice,
  isRecurring,
  invoiceStatus,
  isParentTask,
  qb_invoice_id,
}) => {
  const showMarkAsPaidOption =
    !qb_invoice_id &&
    !isRecurring &&
    !isParentTask &&
    (invoiceStatus === FAILED ||
      invoiceStatus === OVERDUE ||
      invoiceStatus === UNPAID);

  function renderChildInvoiceOptions() {
    return (
      <>
        {(invoiceStatus === FAILED || invoiceStatus === UNPAID) && (
          <>
            {!isClient() && (
              <button
                type="button"
                onClick={voidInvoice}
                className="dropdown-item"
              >
                Void Invoice
              </button>
            )}

            <button
              type="button"
              onClick={manageRecurringInvoice}
              className="dropdown-item"
            >
              Manage Recurring Invoice
            </button>
          </>
        )}

        {(invoiceStatus === PAID || invoiceStatus === VOID) && (
          <button
            type="button"
            onClick={manageRecurringInvoice}
            className="dropdown-item"
          >
            Manage Recurring Invoice
          </button>
        )}
      </>
    );
  }

  function renderInvoiceOptions() {
    return (
      <>
        <button type="button" onClick={editInvoice} className="dropdown-item">
          Edit Invoice
        </button>

        {!isParentTask && (
          <button type="button" onClick={voidInvoice} className="dropdown-item">
            Void Invoice
          </button>
        )}

        {/* Only allow for one time invoice (overdue or unpaid) 
        (no recurring) */}
        {showMarkAsPaidOption && (
          <button type="button" onClick={markAsPaid} className="dropdown-item">
            Mark as paid
          </button>
        )}
      </>
    );
  }

  return (
    <div className="InvoiceTaskDetailsDropdown dropdown-menu">
      {manageRecurringInvoice
        ? renderChildInvoiceOptions()
        : renderInvoiceOptions()}
    </div>
  );
};

export default InvoiceTaskDetailsDropdown;
