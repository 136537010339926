import moment from 'moment-timezone';
import Avatar from 'react-avatar';
import { Chip, Typography, Box, Stack } from '@mui/material';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import styled from '@emotion/styled';
import SMSReminderLink from 'components/SMS/components/SMSReminderLink/SMSReminderLink';
import {
  generateTaskReminder,
  generateRequestReminder,
} from 'components/SMS/utils/generateSMSReminderMessages';

const getBadgeColor = (input) => {
  const colors = {
    paid: 'green',
    failed: 'red',
    pending: 'orange',
    unpaid: '#d1d113',
    'to do': '#d9c06d',
    meeting: '#ff7094',
    'request information': '#5352ee',
    'get a signature': '#62deb4',
    'manage to go items': '#d170ff',
    edoc: '#ff7094',
    overdue: '#ff7094',
    authorized: '#d1d113',
    void: '#d1d113',
    processing: 'green',
  };
  return input ? colors[input.toLowerCase()] : '#d1d113';
};

const StyledStatus = styled.span`
  > div {
    border: 1px solid $transparent;
    width: fit-content;
    padding: 2px 4px;
    font-size: 12px;
    text-transform: capitalize;
    line-height: 1.3;
    border-radius: 2px;
    margin-left: 2px;
  }
`;

export const convertISOtoLocalTime = (time, from = '') => {
  const currentDate = moment();
  const parsedTime = Date.parse(time);
  const timeZone =
    Intl.DateTimeFormat().resolvedOptions().timeZone || moment.tz.guess(true);
  const zonedTime = moment.tz(parsedTime, timeZone);
  const lastUpdatedTime = `${zonedTime.format(
    'MM/DD/YY',
  )} at ${zonedTime.format('hh:mm a')}`;
  const timeDiffrence = moment.duration(currentDate.diff(time));
  const hours = Math.abs(timeDiffrence.hours());
  const minutes = Math.abs(timeDiffrence.minutes());
  const days = Math.abs(timeDiffrence.days());
  let updatedTime = lastUpdatedTime;
  if (from === 'due_by') {
    updatedTime = moment.utc(time).format('MM/DD/YY');
  } else if (
    days === 0 &&
    hours !== 0 &&
    hours <= 23 &&
    from === 'last_activity'
  ) {
    updatedTime = hours > 1 ? `${hours} hours ago` : '1 hour ago';
  } else if (
    days === 0 &&
    hours === 0 &&
    minutes !== 0 &&
    minutes < 59 &&
    minutes >= 5 &&
    from === 'last_activity'
  ) {
    updatedTime = `${minutes} minutes ago`;
  } else if (
    days === 0 &&
    hours === 0 &&
    minutes >= 0 &&
    minutes <= 4 &&
    from === 'last_activity'
  ) {
    updatedTime = 'few minutes ago';
  }
  return updatedTime;
};

export const checkTaskType = (taskType) => {
  switch (taskType) {
    case 'To Do':
      return 'badge badge-to-do';
    case 'Meeting':
      return 'badge badge-onboard';
    case 'Request Information':
      return 'badge badge-request-info';
    case 'Get a Signature':
      return 'badge badge-get-signature';
    case 'Manage To Go Items':
      return 'badge badge-manage-to-go';
    case 'eDoc':
      return 'badge badge-primary';
    default:
      return 'badge badge-primary';
  }
};

export const checkFileType = (ext) => {
  let logo = 'icon-filetype-pdf';
  if (
    ext === 'xls' ||
    ext === 'xlt' ||
    ext === 'xlm' ||
    ext === 'xlsx' ||
    ext === 'xlsm' ||
    ext === 'xltx' ||
    ext === 'xltm' ||
    ext === 'xlsb' ||
    ext === 'xla' ||
    ext === 'xlam' ||
    ext === 'xll' ||
    ext === 'xlw'
  ) {
    logo = 'icon-excel';
  } else if (
    ext === 'doc' ||
    ext === 'docx' ||
    ext === 'docm' ||
    ext === 'dotx' ||
    ext === 'dotm' ||
    ext === 'docb'
  ) {
    logo = 'icon-word';
  } else if (ext === 'zip') {
    logo = 'icon-zip';
  } else if (
    ext === 'ppt' ||
    ext === 'pot' ||
    ext === 'pps' ||
    ext === 'pptx' ||
    ext === 'pptm' ||
    ext === 'potx' ||
    ext === 'potm' ||
    ext === 'ppam' ||
    ext === 'ppsx' ||
    ext === 'ppsm' ||
    ext === 'sldx' ||
    ext === 'sldm'
  ) {
    logo = 'icon-powerpoint';
  } else if (
    ext === 'jpeg' ||
    ext === 'png' ||
    ext === 'gif' ||
    ext === 'jpg'
  ) {
    logo = 'icon-image-preview';
  } else if (ext === 'pdf') {
    logo = 'icon-filetype-pdf';
  } else {
    logo = 'icon-notes';
  }
  return logo;
};

export const getDocName = (docs) => {
  if (!docs || docs === null || docs.length === 0) {
    return null;
  }
  const fileExt = docs?.length
    ? docs[0].content_type.split('/').slice(-1)[0]
    : '';
  const fileIcon = checkFileType(fileExt);
  return (
    <>
      <i className={fileIcon} aria-hidden="true" />
      <Chip
        size="small"
        label={docs[0].file_name}
        sx={{ backgroundColor: '#dfe3e8' }}
      />
      {docs.length > 1 && (
        <Chip
          size="small"
          label={`+ ${docs.length - 1}`}
          sx={{ backgroundColor: '#dfe3e8' }}
        />
      )}
    </>
  );
};

export const getAvatarName = (name) =>
  name && name.split(' ').slice(0, [2]).join(' ');

export const getTaskStatus = (status) => {
  let color = '#B0B0B0';
  if (status === 'Closed') {
    color = 'green';
  } else if (status === 'Review') {
    color = 'red';
  }
  return color;
};

export const getAvatar = ({
  avatar,
  avatarName,
  name,
  activityType,
  smsReminderInfo,
  activityUUID,
  taskId,
}) => (
  <Stack direction="row" sx={{ mb: 1, alignContent: 'flex-start' }}>
    <Avatar
      round
      size={20}
      className="avatar"
      color="#0366d6"
      name={avatarName}
      src={avatar}
    />
    <Typography
      style={{ display: 'inline-block' }}
      variant="subtitle1"
      sx={{ fontWeight: 'bold' }}
    >
      {name}
    </Typography>
    {activityType === 'wf_request' && activityUUID && (
      <SMSReminderLink
        variant="iconbutton"
        smsContacts={[smsReminderInfo]}
        reminder={generateRequestReminder(activityUUID)}
      />
    )}
    {activityType === 'task' && taskId && (
      <SMSReminderLink
        variant="iconbutton"
        smsContacts={[smsReminderInfo]}
        reminder={generateTaskReminder(taskId)}
      />
    )}
  </Stack>
);

export const getBadgeIcon = (text) => (
  <StyledStatus status={text}>
    <div
      style={{
        border: `1px solid ${getBadgeColor(text)}`,
        color: getBadgeColor(text),
      }}
    >
      {text}
    </div>
  </StyledStatus>
);

export const getCircularTaskStatusIcon = (taskStatus) => (
  <CheckCircleOutlineRoundedIcon
    sx={{ color: getTaskStatus(taskStatus), mr: 1 }}
  />
);

export const getTimeOfActivity = (last_activity_at, from = '') => (
  <Typography component="p" sx={{ textAlign: 'right' }} className="text-dark">
    {convertISOtoLocalTime(last_activity_at, from)}
  </Typography>
);

export const getDueTime = (due_by, from = '') => (
  <Typography variant="subtitle1" sx={{ display: 'inline-block' }}>
    Due {` ${convertISOtoLocalTime(due_by, from)} `}
  </Typography>
);

export const getInfocusInvoceNumber = (isRecurring, invoice_number) => (
  <Box component="div" className="text-body">
    {!isRecurring ? 'One-time-payment' : ''}
    {!isRecurring && <i className="divOption" />}
    <Typography sx={{ display: 'inline-block' }} className="text-dark">
      Invoice: {invoice_number}
    </Typography>
  </Box>
);

export const headerIcon = (iconType) => {
  switch (iconType) {
    case 'file':
      return 'icon-Files';
    case 'invoice':
      return 'icon-billing';
    case 'task':
      return 'icon-tickmark';
    case 'email':
      return 'icon-Email';
    case 'note':
      return 'icon-notes';
    case 'message':
      return 'icon-message';
    case 'wf_request':
      return 'icon-Requests';
    default:
      return 'icon-Files';
  }
};

export const headerName = (iconType) => {
  switch (iconType) {
    case 'file':
      return 'FILE';
    case 'invoice':
      return 'INVOICE';
    case 'task':
      return 'TASK';
    case 'email':
      return 'EMAIL';
    case 'note':
      return 'NOTE';
    case 'message':
      return 'MESSAGE';
    case 'text_message':
      return 'SMS TEXT';
    case 'wf_request':
      return 'REQUEST';
    default:
      return 'FILE';
  }
};

export const getHeaderIcon = (type) => (
  <Box component="div" sx={{ display: 'flex', alignItems: 'center' }}>
    <i
      className={`${headerIcon(type)}`}
      aria-hidden="true"
      style={{
        color: '#919eab',
        marginRight: '4px',
        fontSize: '12px',
        fontWeight: '600',
        letterSpacing: '1px',
      }}
    />
    <Typography
      variant="subtitle2"
      sx={{
        display: 'inline-block',
        color: '#919eab',
        marginLeft: '2px',
        fontSize: '12px',
        fontWeight: '600',
        letterSpacing: '1px',
      }}
    >
      {headerName(type)}
    </Typography>
  </Box>
);

export const inFocusCountTabs = [
  {
    value: 'clientTask',
    label: 'CLIENT TASKS',
    icon: 'icon-tickmark',
    title: 'Client tasks',
    url: 'tasks',
    linkto: '/task/new/modal',
    button: 'Task',
    filterSelected: '',
  },
  {
    value: 'firmTask',
    label: 'FIRM TASKS',
    icon: 'icon-tickmark',
    title: 'Firm tasks',
    url: 'tasks',
    linkto: '/task/new/modal',
    button: 'Task',
    filterSelected: '',
  },
  {
    value: 'files',
    label: 'FILES',
    icon: 'icon-Files',
    title: 'Files',
    url: 'files',
    linkto: '/fileupload',
    button: 'File',
    filterSelected: 'client_upload',
  },
  {
    value: 'invoices',
    label: 'INVOICES',
    icon: 'icon-billing',
    title: 'Invoices',
    url: 'billing/invoices',
    linkto: '/billing/invoices',
    button: 'Invoice',
    filterSelected: 'unpaid,failed',
  },
];
