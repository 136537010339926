import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useEmployees } from 'hooks/employee-hooks';
import { SelectFieldWithAvatar } from 'common/CustomFields';
import { Employee } from 'api/employees';

export interface AvatarSelectOption {
  avatar?: string;
  cpa: string;
  email: string;
  initials: string;
  label: string; // Full Name
  partner_title?: string;
  value: number;
}

export interface EmployeeSelectProps {
  onChange?: (employee?: Employee) => void;
  value?: Employee;
  defaultValue?: Employee;
  defaultValueByCPAUserId?: number;
  className?: string;
  error?: any;
  labelClass?: string;
  name?: string;
  label?: string;
  disabled?: boolean;
  menuPlacement?: 'auto' | 'top' | 'bottom';
  placeholder?: string;
}

export const convertEmployeeToAvatarSelectOption = (
  emp: Employee,
): AvatarSelectOption => ({
  avatar: emp.avatar,
  cpa: `${emp.cpa_user_id}`,
  email: emp.email,
  initials: emp.initials,
  label: `${emp.first_name} ${emp.last_name}`,
  value: emp.id,
});

export const EmployeeSelect = ({
  onChange = () => {},
  name,
  className,
  error,
  label,
  labelClass,
  defaultValue,
  defaultValueByCPAUserId,
  value: controlledValue,
  disabled,
  menuPlacement,
  placeholder,
}: EmployeeSelectProps) => {
  const [localValue, setLocalValue] = useState<Employee | undefined>(
    defaultValue || undefined,
  );

  const { data: employees } = useEmployees();

  const getDefaultValueByCPAUserId = useMemo(
    () => employees?.find((emp) => emp.cpa_user_id === defaultValueByCPAUserId),
    [employees, defaultValueByCPAUserId],
  );

  // we want to allow the parent to control the default value of the select without having to pass in an employee object
  // getStorageData().user does not contain a user_id so we have to use the cpa_user_id
  useEffect(() => {
    if (defaultValueByCPAUserId && getDefaultValueByCPAUserId) {
      setLocalValue(getDefaultValueByCPAUserId);
    }
  }, [defaultValueByCPAUserId, getDefaultValueByCPAUserId]);

  const options = useMemo(
    () =>
      (employees || []).map((emp) => convertEmployeeToAvatarSelectOption(emp)),
    [employees],
  );

  const handleEmployeeChange = useCallback(
    (option: AvatarSelectOption) => {
      const employee = employees?.find((emp) => emp.id === option.value);
      setLocalValue(employee);
      onChange(employee);
    },
    [onChange, employees],
  );

  const resolvedValue = useMemo(() => {
    const emp = controlledValue || localValue;
    return options?.find((opt) => opt.value === emp?.id) || {};
  }, [controlledValue, localValue, options]);

  return (
    <SelectFieldWithAvatar
      className={className}
      name={name}
      error={error}
      label={label}
      labelClass={labelClass}
      value={resolvedValue}
      defaultValue={defaultValue}
      disabled={disabled}
      handleChange={handleEmployeeChange}
      handleInputChange={undefined}
      clearable={false}
      onBlur={undefined}
      from={undefined}
      options={options}
      placeholder={placeholder}
      menuPlacement={menuPlacement}
    />
  );
};
