import { IntegrationModule } from 'components/Integrations/integration-modules/integration-module.types';
import { QuickbooksIntegrationListItem } from './QuickbooksIntegrationListItem';
import { QuickbooksIntegrationConfig } from './QuickbooksIntegrationConfig';

export const quickbooksIntegrationModule: IntegrationModule = {
  id: 'quickbooks',
  path: 'quickbooks',
  title: 'Quickbooks',
  getActivationStatus: async () => {
    await console.log('TODO: getQuickbooksActivationStatus');
    return false;
  },
  listComponent: <QuickbooksIntegrationListItem />,
  configComponent: <QuickbooksIntegrationConfig />,
};
