import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { Modal, ModalBody } from 'reactstrap';
import * as Utilities from 'utilities/utils';
import TooltipItem from 'common/TooltipItem';
import { QB_ACCOUNTS, QB_LINKED_ACCOUNTS } from 'constants/constants';

const SearchedAccountHeaders = [
  {
    value: 'account_name',
    label: 'Account Name',
    className: ' col-3 thCol',
    // isSortable: true,
  },
  {
    value: 'address',
    label: 'Address',
    className: ' col-4 thCol',
  },
  {
    value: 'website',
    label: 'Website',
    className: ' col-2 thCol',
  },
  {
    value: 'phone_number',
    label: 'Phone Number',
    className: ' col-2 thCol',
  },
];

const initialData = {
  loading: false,
  // sortBy: 'account_name',
  // sortOrder: 'asc',
  accountListData: [],
  contactListData: [],
};
let typingTime = null;

const SeachLiscioQb = (props) => {
  const [data, setData] = useState(initialData);
  const [searchField, setSearchField] = useState(props.data.searchedFor);

  const { data: propData } = props;
  const headers = SearchedAccountHeaders;

  const acountName =
    propData.searchedIn === 'Quickbooks'
      ? 'qb_customer_display_name'
      : 'liscio_account_name';
  const webSite =
    propData.searchedIn === 'Quickbooks'
      ? 'qb_customer_website'
      : 'liscio_account_website';
  const phoneNumber =
    propData.searchedIn === 'Quickbooks'
      ? 'qb_customer_phone_number'
      : 'liscio_account_phone_number';
  const searchList = async (input) => {
    try {
      const url = `${QB_ACCOUNTS}?${
        propData.searchedIn === 'Liscio'
          ? 'only_liscio_accounts=true'
          : 'only_qb_accounts=true'
      }${input ? `&keyword=${input}` : ''}`;
      const response = await Axios.get(url).then((res) => res.data);
      setData({
        ...data,
        loading: false,
        accountListData: response.liscio_qb_accounts,
      });
    } catch (err) {
      // setData({ ...data, 'loading': false });
      console.log(err);
    }
  };

  useEffect(() => {
    searchList(searchField);
  }, []);

  const handleSearch = (e) => {
    const input = e.target.value;
    setSearchField(input);
    clearTimeout(typingTime);
    typingTime = setTimeout(() => {
      searchList(input);
    }, 500);
  };

  const handleSortBy = (sortBy) => {
    let { sortOrder } = data;
    if (data.sortBy === sortBy) {
      if (sortOrder === 'asc') {
        sortOrder = 'desc';
      } else {
        sortOrder = 'asc';
      }
    } else {
      sortOrder = 'asc';
    }
    setData({ ...data, sortOrder, sortBy });
  };

  const handleLink = (item) => {
    if (propData.searchedForId && item.id) {
      setData({ ...data, loading: true });
      try {
        const firstId =
          propData.activeTab === 0 ? 'first_account_id' : 'first_contact_id';
        const secondId =
          propData.activeTab === 0 ? 'second_account_id' : 'second_contact_id';
        const url = `${QB_LINKED_ACCOUNTS}?${firstId}=${propData.searchedForId}&${secondId}=${item.id}`;
        const request = Axios.post(url);
        request.then((response) => {
          if (response.data.status === 200) {
            Utilities.AlertMessage('success', response.data.message, 3000);
            props.showSearchModal({}, false, '');
            // handleSearchList(data.searchField);
          } else {
            setData({ ...data, loading: false });
          }
        });
      } catch (err) {
        setData({ ...data, loading: false });
        // console.log(err);
      }
    }
  };

  return (
    <div id="content" data-testid="searchLiscioQBModal">
      {data.loading ? <div id="loading" /> : null}
      <Modal
        isOpen
        className="customModal customModal--alert customModal--w990 customModal--nopadding"
      >
        <div className="ModalHeader">
          <button
            type="button"
            className="close"
            aria-label="Close"
            data-testid="closeSearchLiscioQBModal"
            onClick={() => props.showSearchModal({}, false, '')}
          >
            <i className="icon-close2" aria-hidden="true" />
          </button>
        </div>
        <ModalBody className="modalContent">
          <div className="p-4 border-bottom">
            <h5 className="mb-4">Search in {propData.searchedIn}</h5>
            <div className="form-group has-search">
              <span className="icon-zoom2 form-control-feedback" />
              <input
                type="text"
                className="form-control"
                placeholder="Search"
                id={propData.searchedIn}
                value={searchField}
                onChange={handleSearch}
                autoComplete="off"
              />
            </div>
          </div>
          <div className="center-wrapper">
            <div className="tableWrap">
              <div className="tRow tRow--head">
                <div className="row">
                  {headers.map((each) => (
                    <div
                      key={each.value}
                      className={
                        Utilities.sortingIcon(
                          data.sortBy,
                          each.value,
                          data.sortOrder,
                          'className',
                        ) +
                        each.className +
                        (!each.isSortable ? ' cursor-default' : '')
                      }
                      onClick={() =>
                        each.isSortable ? handleSortBy(each.value) : null
                      }
                    >
                      <span className="posRel">
                        {each.label}
                        <i
                          className={Utilities.sortingIcon(
                            data.sortBy,
                            each.value,
                            data.sortOrder,
                          )}
                        />
                      </span>
                    </div>
                  ))}
                  <div className="col thCol" />
                </div>
              </div>
              <div>
                <Scrollbars
                  style={{ width: '100%' }}
                  autoHide
                  autoHeight
                  autoHeightMin={0}
                  autoHeightMax={400}
                  autoHideTimeout={1000}
                >
                  <div className="tRow tRow--body">
                    {data.accountListData && data.accountListData.length ? (
                      data.accountListData.map((item) => (
                        <div
                          className="tdBtn row"
                          key={data.accountListData.indexOf(item)}
                        >
                          <div className=" col-3 tdCol">
                            <span>
                              {item[acountName] ? item[acountName] : '-'}
                            </span>
                          </div>
                          <div className=" col-4 tdCol">
                            <span>
                              {props.getAccountFullAddress(
                                item,
                                propData.searchedIn,
                              )}
                            </span>
                          </div>
                          <div className=" col-2 tdCol">
                            <span>{item[webSite] ? item[webSite] : '-'}</span>
                          </div>
                          <div className=" col-2 tdCol">
                            <span>
                              {item[phoneNumber] ? item[phoneNumber] : '-'}
                            </span>
                          </div>
                          <div className="col tdCol text-right">
                            <span>
                              <TooltipItem
                                position="top"
                                tooltipType="button"
                                key={`link${data.accountListData.indexOf(
                                  item,
                                )}`}
                                text="Link"
                                btnClass="btn-sm btn--onlyicon btn-link tdBtn__hover px-1"
                                id={`link${data.accountListData.indexOf(item)}`}
                                clickAction={() => handleLink(item)}
                                iconn="icon-syncing"
                              />
                            </span>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="row emptyTr">
                        <div className="emptyBox">
                          <div className="onlyText">
                            <p>No Records Found</p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </Scrollbars>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default SeachLiscioQb;
