export function makeSMSReminderTooltip({
  provisionStatus,
  user,
  isFirmAdmin,
}: {
  provisionStatus?: SMSProvisionStatus;
  user?: SMSContact;
  isFirmAdmin: boolean;
}) {
  if (!provisionStatus) return '';

  /**
   *    given SMS is enabled for my firm
   *    given I have a provisioned line
   *        when I’m on the task detail, task list, request detail, request list, or the task or request section of the homepagethen
   *            I see the send SMS reminder option with a hover that reads:
   *              “Send SMS Reminder” and am able to send an SMS reminder
   */
  if (
    provisionStatus.current_integration_step === 'completed' &&
    provisionStatus.current_user_step === 'completed'
  ) {
    if (!user?.contact_sms_phone_number) {
      return 'No phone number available for this contact';
    }

    if (!user?.contact_sms_enabled) {
      return 'Contact has opted out of SMS';
    }

    return 'Send SMS Reminder';
  }
  // /**
  //  *    given SMS IS enabled for my firm
  //  *    given I DO NOT yet have access to a provisioned line
  //  *        when I’m on the task detail, task list, or task section of the homepagethen
  //  *            I see the send SMS reminder option and get a hover over message prompting complete setup:
  //  *              “You do not yet have a phone line. Go to the SMS page to complete setup.”
  //  */
  if (
    provisionStatus.current_integration_step === 'completed' &&
    provisionStatus.current_user_step !== 'completed'
  ) {
    return 'You do not yet have a phone line. Go to the SMS page to complete setup.';
  }

  // /**
  //  *    given SMS is NOT enabled for my firm
  //  *        when I’m on the task detail, task list, or task section of the homepage then
  //  *            I see the send SMS reminder option and get a hover over message prompting me to upgrade:
  //  *              “Ask your admin to enable SMS today!”
  //  */
  if (
    provisionStatus.current_integration_step !== 'completed' &&
    !isFirmAdmin
  ) {
    return 'Ask your admin to enable SMS today!';
  }

  // /**
  //  *    given SMS is NOT enabled for my firm
  //  *        when I’m on the task detail, task list, request detail, request list, or the task or request section of the homepage then
  //  *            I see the send SMS reminder option and get a hover over message prompting me to upgrade:
  //  *              “Enable SMS today to easily text reminders!”
  //  */
  if (provisionStatus.current_integration_step !== 'completed') {
    return 'Enable SMS today to easily text reminders!';
  }

  return '';
}
