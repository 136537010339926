import { Person, PeopleAlt } from '@mui/icons-material';
import { Avatar, Typography, useTheme } from '@mui/material';
import { generateInitialsForParticipant } from 'components/SMS/utils/generateInitialsForParticipant';

interface SMSAvatarProps {
  src?: string;
  participant?: Participant;
  alt?: string;
  width: number;
  height: number;
  multiple?: boolean;
}

export function SMSAvatar({
  src,
  participant,
  alt,
  width,
  height,
  multiple = false,
}: SMSAvatarProps) {
  const theme = useTheme();
  const avatarURL = src || participant?.avatarURL;
  const initials = participant
    ? generateInitialsForParticipant(participant)
    : '';
  const altText =
    alt || initials || participant?.phoneNumberE164 || participant?.phoneNumber;

  const innerDisplay = () => {
    if (multiple) return <PeopleAlt htmlColor={theme.palette.text.primary} />;

    if (!avatarURL && !initials)
      return <Person htmlColor={theme.palette.text.primary} />;

    if (!avatarURL && initials)
      return (
        <Typography
          variant="body2"
          color="text.secondary"
          fontWeight="bolder"
          fontSize={`${(width * 0.375) / 16}rem`}
        >
          {initials}
        </Typography>
      );

    return null;
  };

  return (
    <Avatar
      alt={altText}
      src={avatarURL}
      sx={{
        backgroundColor: !initials ? 'secondary' : 'secondary.light',
        width,
        height,
      }}
    >
      {innerDisplay()}
    </Avatar>
  );
}
