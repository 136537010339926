const initialState = {
  message_page: 1,
  task_page: 1,
  account_msg_page: 1,
  notification_page: 1,
};

// eslint-disable-next-line default-param-last
const paginationReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_TASK_PAGINATION':
      return { ...state, task_page: action.payload };
    case 'SET_MESSAGE_PAGINATION':
      return { ...state, message_page: action.payload };
    case 'ACCOUNT_MESSAGE_PAGINATION':
      return { ...state, account_msg_page: action.payload };
    case 'SET_NOTIFICATION_PAGINATION':
      return { ...state, notification_page: action.payload };
    default:
      return state;
  }
};

export default paginationReducer;
