import React from 'react';

const NoRecords = (props) => (
  <div className="emptyBox text-center">
    <div className="emptyBox-icon">
      <h3 className="text-large">
        <i className="icon-expired" />{' '}
        {props.title ? props.title : 'Nothing to See Here'}
      </h3>
      {props.description ? (
        props.description
      ) : (
        <p>There are no records to show you right now.</p>
      )}
    </div>
  </div>
);

export default NoRecords;
