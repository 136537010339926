import React from 'react';
import ReactTooltip from 'react-tooltip';

const RecurringTaskCard = ({ recurringText }) => (
  <ReactTooltip id="recurringTask" place="top" effect="solid">
    {recurringText}
  </ReactTooltip>
);

export default RecurringTaskCard;
