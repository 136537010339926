import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom-latest';

import {
  useMfaAuthorization,
  useSignInWithMagicLink,
} from 'hooks/login-v5-hooks';
import { SubmitHandler } from 'react-hook-form';
import { AuthenticatedRedirect } from 'components/AuthenticatedRedirect/AuthenticatedRedirect';
import { SignInCpaData } from 'api/login-v5';
import { useQuery } from '@tanstack/react-query';
import { Box } from '@mui/material';
import { Alert } from 'reactstrap';
import { AxiosError } from 'axios';
import {
  CpaSelector,
  LoginPageLayout,
  MFAForm,
  MFAFormType,
} from './components';
import extractErrorInfo from './helpers/extractErrorInfo';

export type ProcessState = 'initialVerification' | 'cpaSelector' | 'mfaCode';

export const LoginV5MagicLink = () => {
  const { id: magicLinkCode } = useParams<{ id: string }>();
  const [processState, setProcessState] = useState<ProcessState>(
    'initialVerification',
  );
  const [temporaryAuthToken, setTemporaryAuthToken] = useState('');
  const [availableCpas, setAvailableCpas] = useState<SignInCpaData[]>([]);

  /**
   * Magic Link
   */
  const {
    mutateAsync: validateMagicLinkCode,
    isLoading: isSignInWithMagicLinkLoading,
    error: magicLinkSignInError,
  } = useSignInWithMagicLink();

  /**
   * MFA validation
   */
  const {
    mutateAsync: checkMfaCode,
    isLoading: isCheckMfaCodeLoading,
    error: mfaError,
  } = useMfaAuthorization();

  const {
    isLoading: isValidatingMagicLinkCode,
    error: magicLinkValidationError,
  } = useQuery({
    queryKey: ['magicLinkValidation', magicLinkCode],
    queryFn: async () => {
      const res = await validateMagicLinkCode({
        token: magicLinkCode!,
      });

      setTemporaryAuthToken(res.auth_token);

      if (res.mfa_required) {
        setProcessState('mfaCode');
      } else {
        setProcessState('cpaSelector');
        setAvailableCpas(res.cpa_data);
      }

      return res;
    },
    retry: false,
    cacheTime: Infinity,
  });

  const onSubmit: SubmitHandler<MFAFormType> = useCallback(
    async (data) => {
      const res = await checkMfaCode({
        auth_token: temporaryAuthToken,
        two_factor_code: data.two_factor_code,
      });

      setTemporaryAuthToken(res.auth_token);
      setAvailableCpas(res.cpa_data);
      setProcessState('cpaSelector');
    },
    [checkMfaCode, temporaryAuthToken],
  );

  const isLoading =
    isSignInWithMagicLinkLoading ||
    isCheckMfaCodeLoading ||
    isValidatingMagicLinkCode;

  const signInError = mfaError || magicLinkSignInError;
  const parsedSignInError =
    signInError && extractErrorInfo(signInError as AxiosError)?.message;
  const parsedValidationError =
    magicLinkValidationError &&
    extractErrorInfo(magicLinkValidationError as AxiosError)?.message;

  return (
    <AuthenticatedRedirect>
      <div id="page-wrapper">
        {isLoading && <div id="loading" data-testid="loading" />}
        <LoginPageLayout>
          <>
            {processState === 'initialVerification' &&
              magicLinkValidationError && (
                <Box mt={4}>
                  <Alert color="danger">{parsedValidationError}</Alert>
                </Box>
              )}
            {processState === 'mfaCode' && (
              <MFAForm onSubmit={onSubmit} error={parsedSignInError} />
            )}
            {processState === 'cpaSelector' && (
              <CpaSelector
                temporaryAuthToken={temporaryAuthToken}
                availableCpas={availableCpas}
                loginType="magic_link"
              />
            )}
          </>
        </LoginPageLayout>
      </div>
    </AuthenticatedRedirect>
  );
};

export default LoginV5MagicLink;
