import axios from "axios";
import { USER_EMAILS } from "constants/constants";

interface GetInlineImagesParams {
  initialEmailBody: string, 
  emailId: string,
  attachments?: any[], 
}

type Doc = { 
  content_type: string;
  content_id: string;
  attachment_body: string;
  content_disposition: string;
};

export function replaceCidWithBase64(initialEmailBody: string, fetchedAttachments?: any[]) {
  if (!fetchedAttachments) return initialEmailBody;

  const newEmailBody = fetchedAttachments
    .reduce((acc: string, doc: Doc) => 
      (doc.content_type.includes('image') && doc.attachment_body)
        ? acc.replace(
          `cid:${doc.content_id.replace('<', '').replace('>', '')}`,
          `data:${doc.content_type};base64,${doc.attachment_body}`,
        )
        : acc, initialEmailBody);

  return newEmailBody;
}

function filterInlineAttachments(attachments?: any[]) {
  if (!attachments) return [];

  return attachments.filter((doc: Doc) => !doc.content_disposition.includes('inline'));
}

export default async function getInlineImages({
  initialEmailBody,
  emailId,
  attachments 
} : GetInlineImagesParams) {
  if (emailId) {
    try {
      const data = await axios.get(
        `${USER_EMAILS}/${emailId}/attachments?content_disposition=inline`,
        );
      const inlineAttachments = filterInlineAttachments(attachments);
      const newEmailBody = replaceCidWithBase64(initialEmailBody, data.data.data);
        
      return {
        newEmailBody,
        inlineAttachments,
      };
    } catch (error) {
      return console.error(error);
    }
  }

  return {};
};