import { SmsBrandRegistrationBusiness } from 'gql/graphql';
import * as yup from 'yup';
import 'yup-phone-lite';
import { isValidUrl } from 'components/SMS/utils/isValidUrl';

const baseSchema = {
  legalName: yup.string().required('Company Name is required'),
  businessType: yup
    .mixed<SmsBrandRegistrationBusiness>()
    .required('Business Type is required')
    .oneOf(
      Object.values(SmsBrandRegistrationBusiness),
      'Business Type is required',
    ),
  ein: yup.string().required('Tax Number is required'),
  firmWebsiteUrl: yup
    .string()
    .required('Website URL is required')
    .test(
      'url',
      () => 'Website URL must be a valid https URL address',
      (value) => isValidUrl(value, ['https:']),
    ),
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  email: yup
    .string()
    .email('Email must be a valid email')
    .required('Email is required'),
  phoneNumber: yup
    .string()
    .phone(undefined, 'Phone number must be a valid phone number for region US')
    .required('Phone is required'),
  preferredAreaCode: yup
    .string()
    .required('Preferred Area Code is required')
    .matches(/^\d{3}$/, 'Please enter a valid area code'),
  addressLine1: yup.string().required('Street Address is required'),
  addressCity: yup.string().required('City is required'),
  addressState: yup.string().required('State/Province is required'),
  addressZip: yup.string().required('Zip Code is required'),
  privacyPolicyUrl: yup.string(),
};

export const getFormSchemaBasedOnFeatureFlag = (
  smsPrivacyPolicyUpdateEnabled: boolean,
) =>
  yup.object().shape(
    smsPrivacyPolicyUpdateEnabled
      ? {
          ...baseSchema,
          privacyPolicyUrl: yup
            .string()
            .required('required')
            .test(
              'url',
              () => 'url',
              (value) => isValidUrl(value || '', ['https:']),
            ),
        }
      : baseSchema,
  );

export const formSchema = yup.object().shape(baseSchema);

export type SMSSettingsType = yup.InferType<typeof formSchema>;
