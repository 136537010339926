/* eslint-disable */
import React, { Component } from 'react';
import { withRouter } from 'utilities/withRouterWrapper';
import { Label, Input } from 'reactstrap';
import moment from 'moment-timezone';
import TooltipItem from 'common/TooltipItem';
import * as Utilities from 'utilities/utils';
import ContactCard from 'common/ContactCard';
import AccountCard from 'common/AccountCard';
import RecurringTaskCard from 'common/RecurringTaskCard';
import { generateTaskReminder } from 'components/SMS/utils/generateSMSReminderMessages';
import SMSReminderLink from 'components/SMS/components/SMSReminderLink/SMSReminderLink';

const bulkstyles = [
  ' col-3',
  ' col-2',
  ' col-2',
  ' col-2',
  ' col-2',
  ' text-right col-auto flex-grow-1',
];
const bulkstyleswithcheckbox = [
  ' col-2',
  ' col-2',
  ' col-2',
  ' col-2',
  ' col-auto flex-grow-1',
  ' text-right col-2',
];
const list = [
  ' col-2',
  ' col-2',
  ' col-2',
  ' col-2',
  ' col-1',
  ' text-right col-auto flex-grow-1',
];
const timeZone =
  Intl.DateTimeFormat().resolvedOptions().timeZone || moment.tz.guess(true);
class List extends Component {
  constructor(props) {
    super(props);
    this.showDetail = this.showDetail.bind(this);
    this.state = {
      contactCardID: null,
      accountCardID: null,
      recurringTaskCard: null,
      recurringText: '',
      isEmployee: Utilities.isUserEmployee(),
    };
  }

  showDetail(item, key) {
    const { match, navigate, name, showCheckbox } = this.props;
    if (name && name === 'bulk' && showCheckbox) {
      this.props.handleCheckbox(key);
    } else if (name && name === 'bulk') {
      // do nothing
    } else {
      navigate(`/task/detail/${item.id}`, { state: { from: match.pathname } });
    }
  }

  contactCardHandler(input) {
    if (this.state.isEmployee) {
      this.setState({ contactCardID: null }, () => {
        if (input && input.task_type_key !== 'to_do') {
          const { contact_id } = input;
          this.setState({ contactCardID: contact_id });
        }
      });
    }
  }

  accountCardHandler(input) {
    if (this.state.isEmployee) {
      this.setState({ accountCardID: null }, () => {
        if (input && input.account_id) {
          const { account_id } = input;
          this.setState({ accountCardID: account_id });
        }
      });
    }
  }

  handleReccurTaskInfo(input) {
    // console.log(`Handel Recurring Info`, input);
    this.setState({
      recurringTaskCard: input.on_schedule,
      recurringText: input.recurring_text,
    });
  }

  render() {
    const {
      totalRecords,
      name,
      match,
      selectedAll,
      showCheckbox,
      pagination,
      data,
      taskStatus,
    } = this.props;
    const { contactCardID, isEmployee, accountCardID } = this.state;
    const styles =
      name && name === 'bulk' && showCheckbox
        ? bulkstyleswithcheckbox
        : name && name === 'bulk'
        ? bulkstyles
        : list;

    const tasks = this.props.tasks.map((item, key) => {
      const dueDateColor =
        item.task_type_key === 'virtual_meeting' && item.raw_due_date
          ? Utilities.dueDateColor(item.raw_due_date, item.status)
          : Utilities.dueDateColor(item.full_due_date, item.status);
      const color = this.props.pref.map((ele) => {
        if (item.task_type_key === ele.key) {
          return ele.label;
        }
        return null;
      });
      let unread = item.comment_count;
      if (name && name === 'bulk') {
        unread = 0;
      }
      const badge = color.find((el) => el !== null);
      let time = item.updated_at;
      if (item.updated_at.indexOf('ago') === -1) {
        time = moment.tz(time, timeZone).format('MM/DD/YY hh:mm a');
      }
      return (
        <div className="row" key={key} data-testid={`row-${key}`}>
          {name && name === 'bulk' ? null : (
            <div className="col tdCol pl-2 pr-2 flex-grow-0">
              <span
                className={
                  unread > 0
                    ? 'rounded status visible'
                    : 'rounded status invisible'
                }
                data-testid={`status-${key}`}
              />
            </div>
          )}
          {name && name === 'bulk' && showCheckbox ? (
            <div
              className="col tdCol pl-2 pr-2"
              data-testid={`checkbox-col-${key}`}
            >
              <div className="posRel checkbox checkbox-primary check-container mx-2 pl-2">
                <Label htmlFor={item.id}>
                  <Input
                    key={item.id}
                    type="checkbox"
                    id={item.id}
                    checked={item.checked ? item.checked : false}
                    className={`chkbx ${item.id}`}
                    name="isChecked"
                    onChange={() => this.props.handleCheckbox(key)}
                    data-testid={`checkbox-${key}`}
                  />
                  <i className="checkmark" />
                </Label>
              </div>
            </div>
          ) : name && name === 'bulk' ? null : (
            <div className="col tdCol pl-2 pr-2" data-testid={`col-${key}`}>
              {isEmployee &&
              name &&
              name === 'tasklist' &&
              data.taskStatus !== 'Archive' ? (
                <span
                  className="posRel checkbox checkbox-primary check-container mx-2 pl-2"
                  style={{ top: '2px' }}
                >
                  <Label htmlFor={`chck ${item.id}`}>
                    <Input
                      name="msg1"
                      id={`chck ${item.id}`}
                      key={`chck ${item.id}`}
                      checked={item.checked ? item.checked : false}
                      type="checkbox"
                      onChange={() => this.props.checkboxAction(item, key)}
                      data-testid={`checkbox-${key}`}
                    />
                    <i className="checkmark" />
                  </Label>
                </span>
              ) : (
                <i className="iconCircle iconCircle--green icon-tickmark_trans_o" />
              )}
            </div>
          )}
          <div
            className={`${styles[0]} tdCol`}
            style={{ transition: 'none' }}
            onClick={() =>
              data.componentContext
                ? this.props.checkboxAction(item, key)
                : this.showDetail(item, key)
            }
            data-testid={`tdCol-${key}`}
          >
            <span
              className={`badge ${
                badge !== undefined ? badge : 'badge-onboard'
              }`}
              data-testid={`badge-${item?.task_type_value}`}
            >
              {item.task_type_value}
            </span>
            <br />
            {unread > 0 ? (
              <strong>{item.subject}</strong>
            ) : (
              <span>{item.subject}</span>
            )}
            <br />
            <span className="badge badge-comment">
              {unread > 0 ? (
                <span data-testid={`subject-${item?.subject}`}>
                  <i className="icon-callout-small vertical-middle" />
                  {` ${item.comment_count}${
                    item.comment_count === 1 ? ' New Comment' : ' New Comments'
                  }`}
                </span>
              ) : (
                ' '
              )}
            </span>
          </div>
          <div
            className={`${styles[1]} tdCol`}
            data-testid={`tdCol-${key}`}
            onClick={() =>
              data.componentContext
                ? this.props.checkboxAction(item, key)
                : this.showDetail(item, key)
            }
          >
            <a
              data-tip
              data-for={`${item.contact_id}global`}
              onMouseEnter={() => this.contactCardHandler(item)}
              data-testid={`a-contact-${item?.name}`}
            >
              {unread > 0 ? (
                <strong data-testid={`strong-name-${item?.name}`}>
                  {item.name}
                </strong>
              ) : (
                <span data-testid={`span-name-${item?.name}`}>{item.name}</span>
              )}
            </a>
          </div>
          <div
            className={`${styles[2]} tdCol`}
            data-testid={`tdCol-account-${key}`}
            onClick={() =>
              data.componentContext
                ? this.props.checkboxAction(item, key)
                : this.showDetail(item, key)
            }
          >
            <a
              data-tip
              data-for={`${item.account_id}global_account`}
              onMouseEnter={() => this.accountCardHandler(item)}
              data-testid={`a-account-${item?.account_name}`}
            >
              {unread > 0 ? (
                <strong data-testid={`strong-account-${item?.account_name}`}>
                  {item.task_type_key === 'to_do'
                    ? item.todo_account_name
                      ? item.todo_account_name
                      : '-'
                    : item.account_name
                    ? item.account_name
                    : '-'}
                </strong>
              ) : (
                <span data-testid={`span-account-${item?.account_name}`}>
                  {item.task_type_key === 'to_do'
                    ? item.todo_account_name
                      ? item.todo_account_name
                      : '-'
                    : item.account_name
                    ? item.account_name
                    : '-'}
                </span>
              )}
            </a>
          </div>
          <div
            className={`${styles[3]} tdCol`}
            data-testid={`tdCol-owner-${key}`}
            onClick={() =>
              data.componentContext
                ? this.props.checkboxAction(item, key)
                : this.showDetail(item, key)
            }
          >
            {unread > 0 ? (
              <strong data-testid={`strong-owner-${item?.owner}`}>
                {item.owner}
              </strong>
            ) : (
              <span data-testid={`span-owner-${item?.owner}`}>
                {item.owner}
              </span>
            )}
            <br />
          </div>
          <div
            style={{ transition: 'none', display: 'flex', minWidth: '140px' }}
            onClick={() =>
              data.componentContext
                ? this.props.checkboxAction(item, key)
                : this.showDetail(item, key)
            }
            className={`${styles[4]} tdCol${dueDateColor}`}
            data-testid={`tdCol-dueDate-${key}`}
          >
            {unread > 0 ? (
              <strong data-testid={`strong-dueDate-${item?.due_date}`}>
                {item.task_type_key === 'virtual_meeting' && item.raw_due_date
                  ? moment(item.raw_due_date).format('MM/DD/YY')
                  : item.due_date}
              </strong>
            ) : (
              <span
                data-tip
                data-for="recurringTask"
                onMouseEnter={() => this.handleReccurTaskInfo(item)}
                className="posRel"
                data-testid={`span-dueDate-${item?.due_date}`}
              >
                {item.task_type_key === 'virtual_meeting' && item.raw_due_date
                  ? moment(item.raw_due_date).format('MM/DD/YY')
                  : item.due_date}
              </span>
            )}
            {item.is_recurring && (
              <span>
                <a
                  href="javascript:;"
                  className="icon-Recurring vertical-middle"
                  data-testid={`RecurringIcon-${item?.id}`}
                />
              </span>
            )}
            {item.task_type_key !== 'to_do' &&
              !['archive', 'draft'].includes(
                taskStatus?.toLowerCase() || '',
              ) && (
                <SMSReminderLink
                  variant="iconbutton"
                  smsContacts={[item]}
                  data-testid={`SMSReminderLink-${item.id}`}
                  reminder={generateTaskReminder(item.id)}
                />
              )}
          </div>
          {name && name === 'bulk' && !showCheckbox ? (
            <div
              className={`${styles[5]} tdCol`}
              style={{ transition: 'none' }}
              data-testid={`delete-${key}`}
            >
              <TooltipItem
                position="top"
                tooltipType="button"
                id={`delete${Utilities.generateUniqueKey(key)}`}
                text="Delete"
                btnClass="btn-sm btn--onlyicon btn-link"
                clickAction={() => this.props.handleDelete(key, item)}
                iconn="icon-delete-small"
              />
            </div>
          ) : (
            <div
              className="col-2 tdCol text-right"
              onClick={() =>
                data.componentContext
                  ? this.props.checkboxAction(item, key)
                  : this.showDetail(item, key)
              }
              style={{ transition: 'none' }}
              data-testid={`time-${key}`}
            >
              {unread > 0 ? (
                <strong data-testid={`strong-time-${time}`}>{time}</strong>
              ) : (
                <span>{time}</span>
              )}
            </div>
          )}
        </div>
      );
    });
    return (
      <div>
        <div className="tableWrap" data-testid="table-wrap">
          <div className="tRow tRow--head" data-testid="table-row-head">
            <div className="row">
              {name && name === 'bulk' ? null : (
                <div className="col thCol  pl-2 pr-2 flex-grow-0">
                  <span
                    className="rounded status invisible px-0 py-2"
                    data-testid="status-span"
                  />
                </div>
              )}
              {(name && name === 'bulk' && showCheckbox) ||
              (isEmployee &&
                name &&
                name === 'tasklist' &&
                data.taskStatus !== 'Archive') ? (
                <div className="col tdCol pl-2 pr-2" data-testid="td-col-1">
                  <span className="checkbox checkbox-primary check-container  mx-2 pl-2">
                    <Label htmlFor="selectallcheckbox">
                      <Input
                        type="checkbox"
                        name="selectallcheckbox"
                        id="selectallcheckbox"
                        checked={selectedAll}
                        key={name === 'tasklist' ? pagination : ''}
                        onChange={() => this.props.handleSelectAll()}
                        data-testid="selectallcheckbox"
                      />
                      <i className="checkmark" />
                    </Label>
                  </span>
                </div>
              ) : name && name === 'bulk' ? null : (
                <div className="col thCol" data-testid="th-col-2" />
              )}
              <div
                className={
                  totalRecords !== 0
                    ? `${
                        Utilities.sortingIcon(
                          this.props.data.field,
                          'subject',
                          this.props.data.sort,
                          'className',
                        ) + styles[0]
                      } thCol`
                    : `${styles[0]} thCol`
                }
                onClick={
                  totalRecords !== 0
                    ? () => this.props.handleSorting('subject')
                    : null
                }
                style={{ transition: 'none' }}
                data-testid="th-col-3"
              >
                <span className="posRel">
                  Task Name{' '}
                  <i
                    className={
                      totalRecords !== 0
                        ? Utilities.sortingIcon(
                            this.props.data.field,
                            'subject',
                            this.props.data.sort,
                          )
                        : null
                    }
                    data-testid="sorting-icon"
                  />
                </span>
              </div>
              <div
                className={
                  totalRecords !== 0
                    ? `${
                        Utilities.sortingIcon(
                          this.props.data.field,
                          'assignee',
                          this.props.data.sort,
                          'className',
                        ) + styles[1]
                      } thCol`
                    : `${styles[1]} thCol`
                }
                onClick={
                  totalRecords !== 0
                    ? () => this.props.handleSorting('assignee')
                    : null
                }
                data-testid="assignee-column"
              >
                <span className="posRel">
                  Assigned To{' '}
                  <i
                    className={
                      totalRecords !== 0
                        ? Utilities.sortingIcon(
                            this.props.data.field,
                            'assignee',
                            this.props.data.sort,
                          )
                        : null
                    }
                  />
                </span>
              </div>
              <div
                className={
                  totalRecords !== 0
                    ? `${
                        Utilities.sortingIcon(
                          this.props.data.field,
                          'name',
                          this.props.data.sort,
                          'className',
                        ) + styles[2]
                      } thCol`
                    : `${styles[2]} thCol`
                }
                onClick={
                  totalRecords !== 0
                    ? () => this.props.handleSorting('name')
                    : null
                }
                data-testid="account-column"
              >
                <span className="posRel">
                  Account{' '}
                  <i
                    className={
                      totalRecords !== 0
                        ? Utilities.sortingIcon(
                            this.props.data.field,
                            'name',
                            this.props.data.sort,
                          )
                        : null
                    }
                  />
                </span>
              </div>
              <div
                className={
                  totalRecords !== 0
                    ? `${
                        Utilities.sortingIcon(
                          this.props.data.field,
                          'owner',
                          this.props.data.sort,
                          'className',
                        ) + styles[3]
                      } thCol`
                    : `${styles[3]} thCol`
                }
                onClick={
                  totalRecords !== 0
                    ? () => this.props.handleSorting('owner')
                    : null
                }
              >
                <span className="posRel">
                  Owner{' '}
                  <i
                    className={
                      totalRecords !== 0
                        ? Utilities.sortingIcon(
                            this.props.data.field,
                            'owner',
                            this.props.data.sort,
                          )
                        : null
                    }
                    data-testid="owner-column"
                  />
                </span>
              </div>
              <div
                className={
                  totalRecords !== 0
                    ? `${
                        Utilities.sortingIcon(
                          this.props.data.field,
                          'due_by',
                          this.props.data.sort,
                          'className',
                        ) + styles[4]
                      } thCol`
                    : `${styles[4]} thCol`
                }
                onClick={
                  totalRecords !== 0
                    ? () => this.props.handleSorting('due_by')
                    : null
                }
                data-testid="due-date-column"
                style={{ transition: 'none' }}
              >
                <span className="posRel">
                  Due Date{' '}
                  <i
                    className={
                      totalRecords !== 0
                        ? Utilities.sortingIcon(
                            this.props.data.field,
                            'due_by',
                            this.props.data.sort,
                          )
                        : null
                    }
                  />
                </span>
              </div>
              {name && name === 'bulk' && !showCheckbox ? (
                <div
                  className={`${styles[5]} thCol`}
                  style={{ transition: 'none' }}
                />
              ) : (
                <div
                  className={
                    totalRecords !== 0
                      ? `${Utilities.sortingIcon(
                          this.props.data.field,
                          'updated_at',
                          this.props.data.sort,
                          'className',
                        )} text-right col-2 thCol`
                      : 'text-right col-2 thCol'
                  }
                  onClick={
                    totalRecords !== 0
                      ? () => this.props.handleSorting('updated_at')
                      : null
                  }
                  data-testid="last-activity-column"
                  style={{ transition: 'none' }}
                >
                  <span className="posRel">
                    Last Activity AT
                    <i
                      className={
                        totalRecords !== 0
                          ? Utilities.sortingIcon(
                              this.props.data.field,
                              'updated_at',
                              this.props.data.sort,
                            )
                          : null
                      }
                    />
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className="tRow tRow--body">
            {totalRecords === 0 ? (
              <div className="row emptyTr">
                <div className="emptyBox">
                  <div className="onlyText">
                    <p>No Records Found</p>
                  </div>
                </div>
              </div>
            ) : (
              tasks
            )}
          </div>
          {contactCardID && contactCardID !== null ? (
            <ContactCard id={contactCardID} />
          ) : null}
          {accountCardID && accountCardID !== null ? (
            <AccountCard id={accountCardID} />
          ) : null}
          {this.state.recurringText &&
          this.state.recurringText !== null &&
          this.setState.recurringText !== '' ? (
            <RecurringTaskCard
              id="recurringTask"
              recurringText={this.state.recurringText}
            />
          ) : null}
        </div>
        {/* this.state.showDetail ?
          <TaskDetail
            data={this.state}
            closeDetail={() => this.closeDetail()}
          /> : null */}
      </div>
    );
  }
}

export default withRouter(List);
