/* eslint-disable jsx-a11y/anchor-is-valid */
import { styled } from '@mui/material/styles';

const ParagraphStyled = styled('p')({
  paddingTop: '16px',
});

export interface NextStepsMessageProps {
  backToLogin: () => void;
  // rawMessage?: string;
  /**
   * Next steps message
   */
  message?: string;
}

export const NextStepsMessage = ({
  backToLogin,
  message,
}: NextStepsMessageProps) => (
  <>
    <ParagraphStyled>{message}</ParagraphStyled>
    <div className="crPage--bott text-center mt-3">
      <span className="txtInfo">Back to </span>
      <a
        href="#"
        onClick={backToLogin}
        className="btn tptBtn"
        data-testid="back_to_login"
      >
        <b>Login</b>
      </a>
    </div>
  </>
);

export default NextStepsMessage;
