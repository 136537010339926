import React, { useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import Modal from '@mui/material/Modal';
import styled from '@emotion/styled';
import InputField from 'common/InputField';
import { getStages, AlertMessage, getRole } from 'utilities/utils';
// import { OptionBoxSelectFieldWithAvatar } from 'common/CustomFields';
import {
  CHECK_PRIMARY_EMAIL,
  CHECK_ACCOUNT,
  CREATE_RECORDS,
} from 'constants/constants';
import MultipleSelectCheckboxDropdown from 'common/MultipleSelectCheckboxDropdown';
import RadioButton from 'common/RadioButton';
import Button from 'common/Button/Button';
import { NAME, EMAIL } from 'utilities/regularexpressions';
import { SelectField } from 'common/CustomFields';
import Checkbox from 'common/Checkbox';
import { FormControlLabel } from '@mui/material';
import { debounce } from 'lodash';
import { PhoneNumberField } from 'common/PhoneNumberField';
import CloseIcon from './icons/CloseIcon';
import {
  fetchAccountsForDropdown,
  fetchEntityTypesForDropdown,
} from './async-utils/dropdown-fetches';

const StyledModalWrap = styled.div`
  .Button__Close {
    position: absolute;
    right: -14px;
    top: -20px;
  }
`;

const StyledDiv = styled.div`
  max-width: 550px;
  min-height: 220px;
  margin: auto;
  position: relative;
  top: 40px;
  left: 0;
  max-height: 85vh;
  .ModalBox {
    width: 100%;
    background-color: #f9fafb;
    box-shadow: 0 10px 40px 0 rgb(0 0 0 / 10%);
    padding: 20px;
    max-height: 400px;
  }
  .Input__Container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #919eab;
    letter-spacing: 1px;
    font-weight: 600;
    margin-bottom: 15px;
    .InputInr {
      flex: 0 0 100%;
      max-width: 100%;
      label {
        margin-bottom: 0.3rem;
      }
    }
    label {
      font-size: 0.75rem;
      color: #919eab;
      text-transform: uppercase;
      letter-spacing: 1px;
      text-align: left;
      font-weight: 600;
      padding-top: 0;
    }
    .error_label {
      display: none;
    }
  }
  .InputInr {
    .check-container {
      margin-left: 0 !important;
    }
  }
  .RowWrap {
    display: flex;
    align-items: flex-start;
    .Input__Container {
      flex: 0 0 50%;
      padding-right: 10px;
      & + .Input__Container {
        padding-left: 10px;
        padding-right: 0;
      }
    }
  }
  .Input__Radio,
  .Input__Select {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    label {
      display: block;
    }
    .RadioWrap {
      label {
        text-transform: none;
      }
      input[type='radio'] {
        margin-right: 5px;
        vertical-align: middle;
      }
      & > div {
        display: inline-block;
        margin-right: 10px;
      }
    }
  }
  .Button__Container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 10px;
    button {
      margin-left: 12px;
    }
  }
  .Header__Container {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
  }
  .Header__Text {
    font-weight: 500;
    color: #212b36;
    font-size: 18px;
  }
  .MuiSvgIcon-root {
    font-size: 30px;
  }
  .checkWrap {
    .check-container {
      cursor: not-allowed;
    }
  }
  .entity-dropdown {
    padding: 0px;
  }
  .ModalBox {
    max-height: 1000px;
    overflow-y: visible;
  }
`;
let typingTime = null;
const CreateContactAccountModal = ({
  primaryEmail,
  phoneNumber,
  openModal,
  handleCreateAccountContact,
  handleNewlyCreatedRecord,
  emailId,
  onCreateOrUpdate,
}) => {
  const [formData, setFormData] = useState({
    accountSearch: '',
    phone_number: phoneNumber,
    isExistingNewAccount: 'Existing Account',
    isRestrictedAccount: false,
    email: primaryEmail,
    email_id: emailId,
  });
  const [accounts, setAccounts] = useState([]);
  // const [accountInput, setAccountInput] = useState('');
  const [errors, setErrors] = useState({});
  const role = getRole();
  const [entityTypeOptions, setEntityTypeOptions] = useState([]);

  async function rawSearchThenSetAccounts(query) {
    const fetchedAccounts = await fetchAccountsForDropdown(query);

    setAccounts(fetchedAccounts);
  }

  const searchThenSetAccounts = useMemo(
    () => debounce(rawSearchThenSetAccounts, 200),
    [],
  );

  useEffect(() => fetchAndSetDropdowns(), []);

  useEffect(() => {
    searchThenSetAccounts(formData.accountSearch);
  }, [formData.accountSearch, searchThenSetAccounts]);

  async function fetchAndSetDropdowns() {
    const [accountResponse, entityTypesResponse] = await Promise.all([
      fetchAccountsForDropdown(),
      fetchEntityTypesForDropdown(),
    ]);

    setAccounts(accountResponse);
    setEntityTypeOptions(entityTypesResponse);
  }

  const handleAccountInputChange = (userInput) => {
    setFormData((currentFormData) => ({
      ...currentFormData,
      accountSearch: userInput,
    }));
  };

  const handleClose = () => {
    handleCreateAccountContact(false);
  };

  const handleInputFieldChange = (inputvalue, fieldname) => {
    const value = inputvalue || '';
    if (
      fieldname === 'isExistingNewAccount' &&
      value === 'Create New Account'
    ) {
      setFormData({
        ...formData,
        selectedAccounts: [],
        [fieldname]: value,
        entityType: 'Individual',
        accountStatus: 'Client',
      });
      setErrors({ ...errors, selectedAccounts: '', [fieldname]: '' });
    } else if (
      fieldname === 'isExistingNewAccount' &&
      value === 'Existing Account'
    ) {
      setFormData({
        ...formData,
        accountname: '',
        [fieldname]: value,
        entityType: '',
        accountStatus: '',
      });
      setErrors({ ...errors, accountname: '', [fieldname]: '' });
    } else if (fieldname === 'accountname') {
      setFormData({ ...formData, [fieldname]: value });
      if (typingTime) {
        clearTimeout(typingTime);
      }
      typingTime = setTimeout(() => {
        validateAccountName(value);
      }, 500);
    } else if (fieldname === 'stage') {
      setFormData({ ...formData, [fieldname]: value.value });
      setErrors({ ...errors, [fieldname]: '' });
    } else if (fieldname.name === 'entityType') {
      setFormData({ ...formData, entityType: value.value });
      setErrors({ ...errors, [fieldname]: '' });
    } else {
      setFormData({ ...formData, [fieldname]: value });
      setErrors({ ...errors, [fieldname]: '' });
    }
  };

  const validatePrimaryEmail = () => {
    if (formData.email) {
      axios
        .post(CHECK_PRIMARY_EMAIL, {
          primary_email: formData.email.toLowerCase(),
        })
        .then((res) => {
          if (res.data.status === 200) {
            setErrors({
              ...errors,
              email: res?.data?.message || 'Already used',
            });
          } else {
            setErrors({ ...errors, email: '' });
          }
        });
    }
  };

  const validateAccountName = (val) => {
    if (val) {
      const request = axios.get(`${CHECK_ACCOUNT}?name=${val}`);
      request.then((res) => {
        if (res.data.status === 200) {
          setErrors({
            ...errors,
            accountname: res?.data?.message,
          });
        } else {
          setErrors({
            ...errors,
            accountname: '',
          });
        }
      });
    }
  };

  const handleInputBlur = () => {
    let err = true;
    if (!formData.firstname) {
      errors.firstname = 'Required';
      err = false;
    } else if (formData.firstname && !NAME.test(formData.firstname)) {
      errors.firstname = 'Enter valid name';
      err = false;
    }
    if (!formData.lastname) {
      errors.lastname = 'Required';
      err = false;
    } else if (formData.lastname && !NAME.test(formData.lastname)) {
      errors.lastname = 'Enter valid name';
      err = false;
    }
    if (primaryEmail && !formData.email) {
      errors.email = 'Required';
      err = false;
    } else if (formData.email && !EMAIL.test(formData.email)) {
      errors.email = 'Invalid Email';
      err = false;
    } else if (formData.email) {
      validatePrimaryEmail();
    }
    if (
      formData.isExistingNewAccount === 'Existing Account' &&
      (!formData.selectedAccounts || formData.selectedAccounts.length <= 0)
    ) {
      errors.selectedAccounts = 'Required';
      err = false;
    }
    if (
      formData.isExistingNewAccount === 'Create New Account' &&
      !formData.accountname
    ) {
      errors.accountname = 'Required';
      err = false;
    }
    if (
      formData.isExistingNewAccount === 'Create New Account' &&
      formData.accountStatus === 'Prospect' &&
      !formData.stage
    ) {
      errors.stage = 'Required';
      err = false;
    }
    return err;
  };

  const handleAccountChange = (value) => {
    setFormData({ ...formData, selectedAccounts: value });
  };

  const handleSaveNewlyCreatedRecord = (data) => {
    handleNewlyCreatedRecord({ ...data, value: data.email });
    handleClose();
  };

  const handleCreateContactAccount = () => {
    const validate = handleInputBlur();
    if (validate) {
      const mydata = {
        account: {},
        contact: {
          first_name: formData.firstname,
          last_name: formData.lastname,
        },
      };

      if (phoneNumber) {
        mydata.contact.contact_phone = [
          {
            type: 'Mobile',
            value: formData.phone_number,
            is_texting_allowed: true,
          },
        ];
      }

      if (primaryEmail) {
        mydata.contact.primary_email = formData.email;
        mydata.email_id = formData.email_id;
      }

      if (formData.isExistingNewAccount === 'Existing Account') {
        mydata.account_id = formData.selectedAccounts[0].value;
      } else if (formData.isExistingNewAccount === 'Create New Account') {
        mydata.account.name = formData.accountname;
        mydata.account.entity_type = formData.entityType;
        mydata.account.status = formData.accountStatus;
        mydata.account.is_restricted_account = formData.isRestrictedAccount;
        if (formData.accountStatus === 'Prospect') {
          mydata.account.stage = formData.stage;
        }
      }
      const request = axios.post(CREATE_RECORDS, mydata);
      request.then((res) => {
        if (res.data.status === 200) {
          if (res.data.contact_error_message) {
            setErrors({
              ...errors,
              email: res.data.contact_error_message,
            });
          } else if (res.data.account_error_message) {
            setErrors({
              ...errors,
              accountname: res.data.account_error_message,
            });
          } else if (
            Object.keys(res.data.contact).length ||
            Object.keys(res.data.account).length
          ) {
            handleSaveNewlyCreatedRecord(res.data.contact);
            onCreateOrUpdate({
              contact: res.data.contact,
              contactPhone: formData.phone_number,
            });
            AlertMessage('success', 'Created Successfully');
          }
        } else {
          AlertMessage('error', res.data.message);
        }
      });
    }
  };

  return (
    <Modal open={openModal} onClose={handleClose}>
      <StyledModalWrap
        className="Modal__Wrap"
        data-testid="createContactAccountModal"
      >
        <StyledDiv className="Modal__Body">
          <CloseIcon
            handleClose={handleClose}
            classname="close Button__Close"
          />
          <div className="ModalBox">
            <div className="Header__Container">
              <h4 className="Header__Text"> Create Contact </h4>
            </div>
            <div className="RowWrap">
              <div className="Input__Container">
                <InputField
                  name="firstname"
                  label="First Name *"
                  placeholder="First Name"
                  onChange={handleInputFieldChange}
                  value={formData.firstname}
                  error={errors.firstname}
                  className="InputInr"
                />
              </div>
              <div className="Input__Container">
                <InputField
                  name="lastname"
                  label="Last Name *"
                  placeholder="Last Name"
                  onChange={handleInputFieldChange}
                  value={formData.lastname}
                  error={errors.lastname}
                  className="InputInr"
                />
              </div>
            </div>
            <div className="Input__Container">
              {primaryEmail && (
                <InputField
                  name="email"
                  label="Primary Email *"
                  onChange={handleInputFieldChange}
                  value={formData.email}
                  error={errors.email}
                  className="InputInr"
                  disabled
                />
              )}
              {phoneNumber && (
                <PhoneNumberField
                  className="InputInr"
                  label="Phone Number *"
                  error={errors.phoneNumber}
                  value={formData.phone_number}
                  name="phone_number"
                  onChange={handleInputFieldChange}
                  forSMS
                />
              )}
            </div>
            <div className="Input__Container Input__Radio">
              <label htmlFor="">Account</label>
              <div className="RadioWrap">
                <RadioButton
                  checked={formData.isExistingNewAccount === 'Existing Account'}
                  onChange={handleInputFieldChange}
                  buttonFor="existingAccount"
                  buttonValue="Existing Account"
                  buttonField="isExistingNewAccount"
                />
                <RadioButton
                  checked={
                    formData.isExistingNewAccount === 'Create New Account'
                  }
                  onChange={handleInputFieldChange}
                  buttonFor="craeteNewAccount"
                  buttonValue="Create New Account"
                  buttonField="isExistingNewAccount"
                />
              </div>
            </div>
            {formData.isExistingNewAccount === 'Existing Account' ? (
              <div>
                <MultipleSelectCheckboxDropdown
                  title="Existing Account"
                  options={accounts}
                  value={formData.selectedAccounts}
                  handleChange={handleAccountChange}
                  handleInputChange={handleAccountInputChange}
                />
                <span className="error_label">{errors.selectedAccounts}</span>
              </div>
            ) : (
              // TODO: for future use
              // <MultipleSelectCheckboxDropdown
              //   title="Existing Account"
              //   options={accounts}
              //   value={formData.selectedAccounts}
              //   onSelectionChange={handleAccountChange}
              // />
              // <OptionBoxSelectFieldWithAvatar
              //   name="seachAccount"
              //   label="Existing Account"
              //   options={accounts}
              //   value={formData.accountId || { value: '', label: '' }}
              //   inputValue={accountInput}
              //   handleChange={(input) =>
              //     handleInputFieldChange(input, 'accountId')
              //   }
              //   // handleInputChange={handleAccountInput || null}
              //   inputRenderer={(input) =>
              //     this.inputRenderer(input, 'accountInput')
              //   }
              //   onBlurResetsInput={false}
              //   onCloseResetsInput={false}
              //   onSelectResetsInput={
              //     data.accountInput && data.accountInput.length
              //   }
              //   placeholder="Select an account"
              // />
              <>
                <div className="Input__Container">
                  <InputField
                    name="accountname"
                    label="Account Name *"
                    onChange={handleInputFieldChange}
                    value={formData.accountname}
                    error={errors.accountname}
                    className="InputInr"
                  />
                </div>
                <div className="Input__Container Input__Select">
                  <SelectField
                    id="entity_type"
                    name="entityType"
                    options={entityTypeOptions}
                    handleChange={handleInputFieldChange}
                    value={{
                      value: formData.entityType,
                      label: formData.entityType,
                    }}
                    className="col-12 entity-dropdown"
                    error={errors.entityType}
                    from="emailQuickCreate"
                    label="Entity Type *"
                  />
                </div>
                <div className="Input__Container Input__Radio">
                  <label htmlFor="">Account Status *</label>
                  <div className="RadioWrap">
                    <RadioButton
                      checked={formData.accountStatus === 'Client'}
                      onChange={handleInputFieldChange}
                      buttonFor="client"
                      buttonValue="Client"
                      buttonField="accountStatus"
                    />
                    <RadioButton
                      checked={formData.accountStatus === 'Prospect'}
                      onChange={handleInputFieldChange}
                      buttonFor="prospect"
                      buttonValue="Prospect"
                      buttonField="accountStatus"
                    />
                  </div>
                </div>
                {formData.accountStatus === 'Prospect' && (
                  <div className="Input__Container">
                    <div className="InputInr">
                      <SelectField
                        id="stage"
                        name="stage"
                        options={getStages()}
                        handleChange={(input) =>
                          handleInputFieldChange(input, 'stage')
                        }
                        value={{ value: formData.stage, label: formData.stage }}
                        className="InputInr"
                        error={errors.stage}
                        label="Stage *"
                      />
                    </div>
                  </div>
                )}
                <div className="InputInr checkWrap">
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="restrictaccount"
                        name="restrictaccount"
                        checked={formData.isRestrictedAccount}
                        onChange={() =>
                          handleInputFieldChange(
                            !formData.isRestrictedAccount,
                            'isRestrictedAccount',
                          )
                        }
                        disabled={role && role !== 'firm_admin'}
                      />
                    }
                    label="Restricted Account"
                  />
                </div>
              </>
            )}
            <div className="Button__Container">
              <Button
                variant="secondary"
                onClick={handleClose}
                size="small"
                label="Cancel"
              />
              <Button
                variant="primary"
                onClick={handleCreateContactAccount}
                size="small"
                label="+ Create"
              />
            </div>
          </div>
        </StyledDiv>
      </StyledModalWrap>
    </Modal>
  );
};

export default CreateContactAccountModal;
