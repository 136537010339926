import React, { useMemo } from 'react';
import {
  IntegrationsPageLayout,
  IntegrationsPageLayoutProps,
} from 'components/Integrations/components/IntegrationsPageLayout';

export interface KarbonPageLayoutProps extends IntegrationsPageLayoutProps {}

export const KarbonPageLayout = ({
  pageTitle = 'Karbon Integration',
  breadcrumbs: additionalBreadcrumbs = [],
  ...rest
}: KarbonPageLayoutProps) => {
  const breadcrumbs = useMemo(
    () => [{ path: '/karbon', label: 'Karbon' }, ...additionalBreadcrumbs],
    [additionalBreadcrumbs],
  );
  return (
    <IntegrationsPageLayout
      {...rest}
      pageTitle={pageTitle}
      breadcrumbs={breadcrumbs}
    />
  );
};
