/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-param-reassign */
/* eslint-disable no-script-url */
/* eslint-disable react/jsx-no-script-url */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from 'react';
import Axios from 'axios';
import Select, { components } from 'react-select';
// import Select from 'react-select';
import { Link } from 'react-router-dom-latest';
import { Button } from 'reactstrap';
import { EditorState, convertFromRaw } from 'draft-js';
import CurrencyInput from 'react-currency-input-field';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import DatePicker from 'react-datepicker';
import { AlertMessage, getCurrentPath } from 'utilities/utils';
import { generateUniqueHash } from 'common/NotesUtilities';
import DraftEditor from 'common/DraftEditor';
import * as TaskApi from 'api/TaskApi';
import ConfirmationModal from 'common/ConfirmationModal';
import {
  GET_TEMPLATES_FOR_CREATION,
  CREATE_DOC_FROM_TEMPLATE_IDS,
  STRIPE_ACCOUNT,
  QB_INVOICE_PDF,
  DOCUMENT_API_PATH,
} from 'constants/constants';
import {
  SelectTemplateFieldOption,
  DatePickerHTML,
  selectStyles,
  selectTheme,
} from 'common/CustomFields';
import TaskFileUpload from 'components/Files/TaskFileUpload';
import { withRouter } from 'utilities/withRouterWrapper';
import { format } from 'date-fns';
import CommonFields from 'components/Tasks/Create/CommonFields';

const noneStyle = { display: 'none' };
const blockStyle = { display: 'block' };
class Invoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stateValues: {},
      errors: {},
      taskTitle: '',
      taskStatus: '',
      documents: [],
      comment: '',
      viewModal: false,
      loading: false,
      dataUpdated: false,
      selectedOption: null,
      uniqueId: generateUniqueHash(5),
      templateOptions: [],
      templateDocuments: [],
      warningMsgLost: false,
      selectedTemplate: null,
      titleChange: '',
      invoiceNumber: '',
      invoiceAmount: '',
      paymentStatus: 'unpaid',
      invoiceDueDate: moment(),
      stripeAccountStatus: 'active',
      isInvoiceFromQB: false,
    };
    this.templateDropdown = this.templateDropdown.bind(this);
  }

  componentDidMount() {
    const { data, taskDetail } = this.props;
    if (data.viewContext) {
      this.setState({
        taskTitle: taskDetail && taskDetail.subject ? taskDetail.subject : '',
        documents:
          taskDetail && taskDetail.documents ? taskDetail.documents : [],
        comment:
          taskDetail && taskDetail.description ? taskDetail.description : '',
      });
    } else {
      this.getTemplates();
    }
    this.getStripeAccount();
    // this.getPaymentTerms();
  }
  
  componentDidUpdate(prevProps) {
    if (prevProps.taskDetail.description !== this.props.taskDetail.description) {
      this.setState({ comment: this.props.taskDetail.description })
    }
  }

  UNSAFE_componentWillReceiveProps() {
    const { data } = this.props;
    if (
      Object.keys(this.props.taskDetail).length &&
      data.isEdit &&
      !data.changedOnce
    ) {
      const detail = this.props.taskDetail;
      // console.log(JSON.parse(detail.description_in_json));
      this.setState({
        taskTitle: detail.subject,
        comment: detail.description,
        documents: detail.documents,
        taskStatus: detail.status,
        invoiceAmount: (detail.invoice_amount / 100).toFixed(2),
        invoiceNumber: detail.invoice_number,
        paymentStatus: detail.payment_status,
        invoiceDueDate: moment(detail.payment_due_date),
        isInvoiceFromQB: detail.is_invoice_id !== null,
      });
    }
  }

  componentWillUnmount() {
    if (this.state.documents.length) {
      const docIds = [];
      let x;
      for (x = 0; x < this.state.documents.length; x += 1) {
        docIds.push(this.state.documents[x].id);
      }
      const data = { doc_ids: docIds };
      TaskApi.cancelDocuments(this, data, 'tasks');
    }
  }

  setTasktitle = (value) => {
    this.setState({
      taskTitle: value,
      dataUpdated: true,
    });
  };

  getStripeAccount = () => {
    Axios.get(STRIPE_ACCOUNT).then((response) => {
      if (response.data.account_status) {
        const stripeAccountStatus = response.data.account_status;
        let message;
        switch (stripeAccountStatus) {
          case 'new':
            message = 'Please continue set-up';
            break;
          case 'need_more_info':
            message = 'Please continue set-up';
            break;
          case 'requirements_due':
            message = 'Please update account details';
            break;
          case 'pending':
            message = 'Account is in review';
            break;
          case 'active':
            break;
          case 'set_up_in_progress':
            message = 'Set-up in progress';
            break;
          default:
            message = 'Set Up Payments';
            break;
        }
        this.setState({
          stripeAccountStatus,
          message,
        });
      }
      console.log(response);
    });
  };

  getTemplateDocsId = () => {
    const mydata = {};
    if (this.state.templateDocuments.length) {
      mydata.template_doc_ids = [];
      let x;
      for (x = 0; x < this.state.templateDocuments.length; x += 1) {
        mydata.template_doc_ids.push(this.state.templateDocuments[x].id);
      }
    }
    mydata.template_type = 'task_template';
    if (this.state.templateDocuments.length) {
      this.setState({ loading: true });
      const request = Axios.post(CREATE_DOC_FROM_TEMPLATE_IDS, mydata);
      request
        .then((response) => {
          this.setState({ loading: false });
          if (response.data.status === 200) {
            const documents = [...this.state.documents];
            if (documents.length) {
              this.setState({
                documents: response.data.documents,
                templateDocuments: [],
                dataUpdated: true,
              });
              // let x;
              // for (x = 0; x < documents.length; x += 1) {
              //   const documnt = { ...documents[x] };
              //   documnt.id = response.data.doc_ids[x];
              //   documents[x] = documnt;
              //   this.setState({ documents });
              //   if (x + 1 === documents.length) {
              //     this.setState({ templateDocuments: [], dataUpdated: true });
              //   }
              // }
            }
          } else {
            AlertMessage('error', response.data.message, 1500);
          }
        })
        .catch(() => {
          this.setState({ loading: false });
        });
    }
  };

  getTemplates() {
    this.setState({ loading: true });
    Axios.get(`${GET_TEMPLATES_FOR_CREATION}?template_type=task_template`)
      .then((res) => {
        this.setState({ loading: false });
        if (res.data && res.data.data && res.data.data.length) {
          const { data } = res.data;
          this.setState({
            templateOptions: data,
            loading: false,
          });
        } else {
          this.setState({
            templateOptions: [],
            loading: false,
          });
        }
      })
      .catch(() => {
        this.setState({
          templateOptions: [],
          loading: false,
        });
      });
  }

  // this is for getting invoice details from create invoice or search invoice modal
  getQbInvoiceDetails = (invoice) => {
    const { invoicePdfs, isInvoiceFromQB } = this.state;
    if (invoice) {
      this.setState({
        isInvoiceFromQB: true,
        invoiceNumber: invoice.doc_number,
        invoiceAmount: Number(invoice.total).toFixed(2),
        invoiceDueDate: moment(invoice.due_date, 'YYYY-MM-DD'),
        qbInvoiceId: invoice.id,
      });
      this.getQbInvoicePdf(invoice, isInvoiceFromQB);
    } else if (isInvoiceFromQB) {
      // we will clear out invoice details when selected account is changed
      this.setState({
        isInvoiceFromQB: false,
        invoiceNumber: '',
        invoiceAmount: '',
        invoiceDueDate: moment(),
        invoicePdfs: undefined,
        qbInvoiceId: '',
      });
    }
    if (invoicePdfs) {
      this.deleteQbInvoicePdf(invoicePdfs[0].id);
    }
  };

  // this for getting invoice pdf from quickbooks
  getQbInvoicePdf = async (invoice, showalert) => {
    try {
      const result = await Axios.get(
        `${QB_INVOICE_PDF}/${invoice.id}?invoice_number=${invoice.doc_number}`,
      ).then((res) => res.data.data);
      result.is_qb_invoice_pdf = true;
      this.setState({ invoicePdfs: [result] });
      if (showalert) {
        AlertMessage('success', 'New invoice attached.', 3000);
      }
    } catch (error) {
      AlertMessage('error', error.response.data.message, 3000);
    }
  };

  deleteQbInvoicePdf = async (invoicePdfId, showalert = false) => {
    try {
      const response = await Axios.delete(
        `${DOCUMENT_API_PATH}/${invoicePdfId}`,
      ).then((res) => res.data.message);
      this.setState({ invoicePdfs: undefined });
      if (showalert) {
        AlertMessage('success', response, 3000);
      }
    } catch (error) {
      AlertMessage('error', error.response.data.message, 3000);
      console.log(error);
    }
  };

  inputRenderer = (inputProps) => (
    <components.Input
      {...inputProps}
      value={this.state.titleChange}
      className="Select-input"
    />
  );

  templateDropdown(selectedOption) {
    const { taskTitle, comment, documents, warningMsgLost } = this.state;
    let commentExist = true;
    if (!comment) {
      commentExist = false;
    } else if (typeof comment === 'object') {
      if (
        !EditorState.createWithContent(convertFromRaw(comment))
          .getCurrentContent()
          .getPlainText().length
      ) {
        commentExist = false;
      }
    }
    if (
      (taskTitle || commentExist || documents.length) &&
      !warningMsgLost &&
      (!selectedOption || (selectedOption && !taskTitle))
    ) {
      this.setState({ warningMsgLost: true, selectedTemplate: selectedOption });
    } else if (selectedOption && selectedOption !== null) {
      const { content, value } = selectedOption;
      const coment = content;
      this.setState({
        selectedOption,
        taskTitle: value,
        titleChange: '',
        comment: coment,
        uniqueId: generateUniqueHash(5),
        warningMsgLost: false,
        selectedTemplate: null,
        templateDocuments:
          selectedOption.documents && selectedOption.documents.length
            ? selectedOption.documents
            : [],
        documents:
          selectedOption.documents && selectedOption.documents.length
            ? selectedOption.documents
            : [],
      });
    } else {
      this.setState(
        {
          selectedOption: null,
          taskTitle: '',
          titleChange: '',
          comment: '',
          uniqueId: generateUniqueHash(5),
          warningMsgLost: false,
          selectedTemplate: null,
          documents: [],
          templateDocuments: [],
        },
        () => {
          const node = this.selectInputRef.current;
          if (node) {
            node.state.inputValue = '';
          }
        },
      );
    }
  }

  templateChange = (val) => {
    if (val || (!val && this.state.titleChange)) {
      this.setState({ taskTitle: val, titleChange: val });
    }
  };

  stateChange = (value, dataUpdated = false) => {
    const stateValues = { ...this.state.stateValues, ...value };
    this.setState({
      stateValues,
      dataUpdated,
    });
  };

  handleCancel = () => {
    const { stateValues, dataUpdated } = this.state;
    if (stateValues.isChanged || dataUpdated) {
      this.setState({ viewModal: true });
    } else {
      this.props.goBack();
    }
  };

  hideViewModal = () => {
    this.setState({ viewModal: false });
  };

  handleCommentChange = (value) => {
    this.setState({
      comment: value,
      dataUpdated: true,
    });
    if (this.state.templateDocuments && this.state.templateDocuments.length) {
      this.getTemplateDocsId();
    }
  };

  handleChange = (e) => {
    this.setState({
      errors: {},
      taskTitle: e.target.value,
      dataUpdated: true,
    });
  };

  nextPage = () => {
    const { state } = this.props.location;
    if (
      this.props.data.isEdit &&
      state !== undefined &&
      state.from === '/task/detail'
    ) {
      const { match } = this.props;
      this.props.navigate(`/task/detail/${match.params.id}`);
    } else if (state && state.from === '/task/new') {
      this.props.navigate(-2);
    } else {
      this.props.goBack();
    }
  };

  handleValidation = () => {
    const {
      stateValues,
      taskTitle,
      invoiceAmount,
      invoiceNumber,
      isInvoiceFromQB,
    } = this.state;
    const err = {};
    let formIsValid = true;
    if (
      stateValues.accounts &&
      stateValues.accounts.length &&
      (!stateValues.accountId || stateValues.accountId === '')
    ) {
      formIsValid = false;
      err.account_error = 'Please select account';
    }
    if (stateValues.accountId === '' || !stateValues.accountId) {
      formIsValid = false;
      err.account_error = 'Please select account';
    }
    if (stateValues.contactId === '' || !stateValues.contactId) {
      formIsValid = false;
      err.contact_error = 'Please select contact';
    }
    if (stateValues.ownerId === '' || !stateValues.ownerId) {
      formIsValid = false;
      err.owner_error = 'Please select task owner';
    }
    if (taskTitle === '' || !taskTitle) {
      formIsValid = false;
      err.taskTitle_error = 'Enter task title';
    }
    if (invoiceNumber === '' || !invoiceNumber) {
      formIsValid = false;
      err.invoiceNumber = 'Enter invoice number';
    }
    if (invoiceNumber && invoiceNumber.length > 100) {
      formIsValid = false;
      err.invoiceNumber =
        'Invoice number cannot be greater than 100 characters';
    }
    if (invoiceAmount === '' || !invoiceAmount) {
      formIsValid = false;
      err.invoiceAmount = 'Enter invoice amount';
    }
    if (!isInvoiceFromQB && invoiceAmount && invoiceAmount > 10000) {
      formIsValid = false;
      err.invoiceAmount = 'Invoice amount can not be greater than $10,000';
    }
    if (invoiceAmount && parseInt(invoiceAmount, 2) < 1) {
      formIsValid = false;
      err.invoiceAmount = 'Invoice amount should be at least $1';
    }
    this.setState({ errors: err });
    return formIsValid;
  };

  emptyError = () => {
    this.setState({ errors: {} });
  };

  submitForm = (e) => {
    e.preventDefault();
    const {
      match,
      data: { externalDocs, isEdit, emailId },
      taskDetail,
      location,
    } = this.props;
    const {
      stateValues,
      comment,
      taskTitle,
      invoiceAmount,
      invoiceNumber,
      invoiceDueDate,
      invoicePdfs,
      qbInvoiceId,
    } = this.state;
    let { documents } = this.state;
    const path = getCurrentPath(location);

    if (this.handleValidation()) {
      let mydata = {};
      if (isEdit && taskDetail.payment_status !== 'unpaid') {
        mydata.cpa_id = stateValues.cpaId;
        mydata.subject = taskTitle;
        mydata.due_by = format(stateValues.startDate, 'dd/MM/yyyy');
        if (stateValues.ownerChanged) {
          mydata.owner_id = stateValues.ownerId;
        }
      } else if (isEdit) {
        mydata.cpa_id = stateValues.cpaId;
        mydata.subject = taskTitle;
        mydata.due_by = format(stateValues.startDate, 'dd/MM/yyyy');
        if (stateValues.ownerChanged) {
          mydata.owner_id = stateValues.ownerId;
        }
        mydata.invoice_number = invoiceNumber;
        mydata.invoice_amount = invoiceAmount;
        mydata.payment_due_date = format(invoiceDueDate, 'MM/dd/yyyy');
      } else {
        if (path === '/contactdetails/task/:id') {
          mydata.assigne_type = stateValues.contactDetails.contact.cpa_user_id
            ? 'user'
            : 'contact';
          mydata.assigned_to_user = stateValues.contactDetails.contact
            .cpa_user_id
            ? stateValues.contactDetails.contact.cpa_user_id
            : stateValues.contactDetails.contact.id;
        } else {
          mydata.assigne_type = stateValues.assigneeType;
          mydata.assigned_to_user = stateValues.contactId;
        }
        mydata.cpa_id = stateValues.cpaId;
        mydata.owner_id = stateValues.ownerId;
        mydata.for_account = stateValues.accountId;
        mydata.subject = taskTitle;
        mydata.due_by = format(stateValues.startDate, 'MM/dd/yyyy');
        mydata.task_type_key = this.props.data.taskTypeKey;
        mydata.task_type_value = this.props.data.taskTypeValue;
        mydata.invoice_number = invoiceNumber;
        mydata.invoice_amount = invoiceAmount;
        mydata.qb_invoice_id = qbInvoiceId;
        mydata.payment_due_date = format(invoiceDueDate, 'MM/dd/yyyy');
      }
      if (stateValues.recurring_properties) {
        mydata = { ...mydata, ...stateValues.recurring_properties };
      }
      if (typeof comment === 'string' && comment.length) {
        mydata.description = comment;
      } else {
        mydata.description = '';
      }

      if (invoicePdfs) {
        documents = [...documents, ...invoicePdfs];
      }
      if (externalDocs && externalDocs.length) {
        documents = [...documents, ...externalDocs];
      }
      if (emailId) {
        mydata.email_id = emailId;
      }
      if (documents.length && !this.props.data.isEdit) {
        mydata.doc_ids = [];
        let x;
        for (x = 0; x < documents.length; x += 1) {
          mydata.doc_ids.push(documents[x].id);
        }
      }
      if (this.props.data.isEdit) {
        const { id } = match.params;
        TaskApi.createTask(this, mydata, id, this.props.data.taskTypeKey); // updates task
      } else {
        TaskApi.createTask(this, mydata);
      }
    }
    return false;
  };

  handleDateChange = (date) => {
    this.setState({ invoiceDueDate: date });
  };

  handleInvoiceAmount = (value) => {
    this.setState(
      {
        invoiceAmount: value,
        errors: {},
        dataUpdated: true,
      },
      () => {
        if (value && value > 10000) {
          this.setState({
            errors: {
              invoiceAmount: 'Invoice amount can not be greater than $10,000',
            },
          });
        }
      },
    );
  };

  handleInvoiceNumber = (e) => {
    const value = e && e.target && e.target.value ? e.target.value : '';
    this.setState(
      {
        invoiceNumber: value,
        errors: {},
      },
      () => {
        if (value && value.length > 100) {
          this.setState({
            errors: {
              invoiceNumber:
                'Invoice number cannot be greater than 100 characters',
            },
          });
        }
      },
    );
  };

  updateDocumentState = (documents) => {
    this.setState({ documents });
  };

  render() {
    const {
      errors,
      taskTitle,
      taskStatus,
      stateValues,
      dataUpdated,
      selectedOption,
      templateOptions,
      selectedTemplate,
      invoiceNumber,
      invoiceAmount,
      invoiceDueDate,
      paymentStatus,
      stripeAccountStatus,
      message,
      isInvoiceFromQB,
    } = this.state;
    const { data } = this.props;
    const recurringDisable =
      stateValues.recurring_properties &&
      stateValues.recurring_properties.recurringDropdown;
    if (stripeAccountStatus !== 'active') {
      return (
        <div className="alert alert-danger alert-dismissible" role="alert">
          <p className="p-0 m-0">
            {message}.<Link to="/integrations">Go to Integrations</Link>
          </p>
        </div>
      );
    }
    return (
      <div>
        <div className="miscellaneous">
          <div
            id="loading"
            style={this.state.loading ? blockStyle : noneStyle}
          />
          <CommonFields
            data={data}
            statedata={this.state}
            taskDetail={this.props.taskDetail}
            stateChange={this.stateChange}
            emptyError={this.emptyError}
            setTasktitle={this.setTasktitle}
            removeparticularTaskType={this.props.removeparticularTaskType}
            getQbInvoiceDetails={this.getQbInvoiceDetails}
            match={this.props.match}
            location={this.props.location}
            params={this.props.params}
          />
          {/* for invoice fields */}
          <div className="row">
            <div
              className={`col-md-6 col-12 docsWidth form-group form-inline-label align-items-stretch ${
                errors.invoiceNumber ? 'form-error' : ''
              }`}
            >
              <label htmlFor="invoicenumber" className="mt-1 pt-2">
                Invoice No
              </label>
              <div className="labelValue">
                <input
                  id="invoicenumber"
                  value={invoiceNumber}
                  name="invoicenumber"
                  onChange={this.handleInvoiceNumber}
                  className="form-control"
                  disabled={isInvoiceFromQB}
                />
                <span className="error_label">{errors.invoiceNumber}</span>
              </div>
            </div>
            <div
              className={`col-md-6 col-12 docsWidth form-group form-inline-label align-items-stretch ${
                errors.invoiceAmount ? 'form-error' : ''
              }`}
            >
              <label htmlFor="invoiceamount" className="mt-1 pt-2">
                Amount
              </label>
              <div className="labelValue">
                <CurrencyInput
                  id="invoiceamount"
                  name="invoiceamount"
                  decimalsLimit={2}
                  allowNegativeValue={false}
                  prefix="$"
                  maxLength={8}
                  className="form-control"
                  value={invoiceAmount}
                  onValueChange={this.handleInvoiceAmount}
                  disabled={isInvoiceFromQB}
                />
                <span className="error_label">{errors.invoiceAmount}</span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="form-group form-inline-label align-items-stretch col-md-12 col-lg-6">
              <label className="mt-1 pt-2" htmlFor="invoice_due_date">
                Payment Due Date:
              </label>
              <div className="date-text date-calender pt-2">
                <a href="javascript:void(0);" className="liscio-calendar3 cal">
                  <i
                    className="icon-calendar vertical-middle text-dark"
                    data-tip
                    data-for="due_date_icon"
                    aria-hidden="true"
                  />
                </a>
                <ReactTooltip id="due_date_icon" effect="solid">
                  <span className="contactcard-team-member">Due Date</span>
                </ReactTooltip>
                <DatePicker
                  selected={invoiceDueDate}
                  value={invoiceDueDate}
                  popoverTargetAttachment="right center"
                  onChange={this.handleDateChange}
                  minDate={moment()}
                  maxDate={undefined}
                  locale="en"
                  monthsShown={1}
                  disabled={paymentStatus !== 'unpaid' || isInvoiceFromQB}
                  customInput={<DatePickerHTML />}
                />
              </div>
            </div>
            <div className="form-group form-inline-label col-lg-6 col-sm-12">
              <label htmlFor="account">Payment Status:</label>
              <div className="labelValue text-capitalize">
                <p>{paymentStatus}</p>
              </div>
            </div>
          </div>
          {/* for subject section */}
          <div className="row">
            <div
              className={`form-group col-12${
                errors.taskTitle_error
                  ? ' form-error cstmClr msgCustmClr'
                  : ' cstmClr msgCustmClr'
              }`}
            >
              <label htmlFor="taskTitle">Subject</label>
              <div className="labelValue customEditor boxResize flWdth">
                {(selectedOption && selectedOption !== null) ||
                (!selectedOption &&
                  selectedOption === null &&
                  taskTitle &&
                  !this.state.titleChange) ||
                data.isEdit ? (
                  <input
                    autoComplete="off"
                    disabled={taskStatus === 'Closed'}
                    name="taskTitle"
                    className="form-control"
                    id="taskTitle"
                    type="text"
                    value={taskTitle}
                    maxLength="200"
                    onChange={this.handleChange}
                  />
                ) : (
                  <Select
                    name="taskTitle"
                    placeholder=""
                    ref={this.selectInputRef}
                    className="select-custom-class slctTemp"
                    value={selectedOption}
                    onChange={this.templateDropdown}
                    options={templateOptions}
                    optionComponent={SelectTemplateFieldOption}
                    onInputChange={this.templateChange}
                    inputRenderer={(input) => this.inputRenderer(input)}
                    tabSelectsValue={false}
                    onBlurResetsInput={false}
                    onCloseResetsInput={false}
                    onSelectResetsInput={this.state.titleChange} // false shows
                    noResultsText=""
                    isDisabled={data.isEdit}
                    styles={selectStyles}
                    theme={selectTheme}
                    classNamePrefix="custom-react-select"
                  />
                )}
              </div>
              {taskTitle && !data.isEdit ? (
                <Button
                  className="btn btn-sm btn-primary"
                  onClick={() => this.templateDropdown(null)}
                >
                  Clear
                </Button>
              ) : null}
              <span className="error_label">{errors.taskTitle_error}</span>
            </div>
          </div>
          {/* for description section */}
          <div className="row">
            <div className="form-group col-lg-12">
              <div className="has-float-label customEditor boxResize">
                <label htmlFor="taskDescription">Description</label>
                <DraftEditor
                  handleCommentChange={this.handleCommentChange}
                  comment={this.state.comment}
                  id="taskDescription"
                  readOnly={taskStatus === 'Closed'}
                  uniqueId={this.state.uniqueId}
                />
              </div>
            </div>
          </div>
          {(data.isEdit && this.state.documents.length) || !data.isEdit ? (
            <div className="row">
              <div className="form-group dragWrap col-lg-12">
                <label htmlFor="attachments">Attachments</label>
                <TaskFileUpload
                  data={this.state}
                  uploadType="task"
                  isEdit={data.isEdit}
                  externalDocs={data.externalDocs}
                  updateDocumentState={this.updateDocumentState}
                  deleteQbInvoicePdf={this.deleteQbInvoicePdf}
                />
              </div>
            </div>
          ) : null}
          <div className="row">
            <div className="col-12 form-group formbtn justify-content-end btn--leftspace">
              <div className="btn-wrap btn--leftspace">
                <button
                  type="button"
                  className="btn btn-outline-light"
                  onClick={this.handleCancel}
                  disabled={taskStatus === 'Closed' || recurringDisable}
                  data-testid="task__cancel_btn"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary task-form-submit"
                  onClick={this.submitForm}
                  data-testid="task__submit_btn"
                  disabled={
                    taskStatus === 'Closed' ||
                    recurringDisable ||
                    (data.isEdit && !dataUpdated && !stateValues.isChanged)
                  }
                >
                  {!data.isEdit ? 'Create Task' : 'Update Task'}
                </button>
              </div>
            </div>
          </div>
        </div>
        <ConfirmationModal
          isOpen={this.state.viewModal}
          headerText="Are you sure want to cancel."
          messageText="This action cannot be undone. Proceed?"
          noBtnClick={this.hideViewModal}
          yesBtnClick={this.nextPage}
          yesBtnText="Yes"
          noBtnText="No"
        />
        <ConfirmationModal
          isOpen={this.state.warningMsgLost}
          className="customModal customModal--w500 customModal--nopadding"
          headerText="You are about to delete the existing message."
          messageText="This action cannot be undone. Proceed?"
          noBtnClick={() => this.setState({ warningMsgLost: false })}
          yesBtnClick={() => this.templateDropdown(selectedTemplate)}
          yesBtnText="Yes"
          noBtnText="No"
        />
      </div>
    );
  }
}

export default withRouter(Invoice);
