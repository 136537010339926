export const defaultAllowedUploadFileTypeExtensions = [
  '.jpg',
  '.jpeg',
  '.png',
  '.gif',
  '.pdf',
  '.postscript',
  '.x-eps',
  '.tif',
  '.tiff',
  '.txt',
  '.text',
  '.doc',
  '.docx',
  '.docx',
  '.doc',
  '.dotx',
  '.docm',
  '.dotm',
  '.xls',
  '.xlt',
  '.xla',
  '.xlsx',
  '.xlsm',
  '.xltx',
  '.xltm',
  '.xlsb',
  '.xlam',
  '.csv',
  '.tsv',
  '.zip',
  '.mp3',
  '.qbb',
  '.qbw',
  '.eps',
  '.ai',
  '.qfx',
  '.qbx',
  '.qba',
  '.pptx',
  '.ppt',
  '.potx',
  '.pot',
  '.ppsx',
  '.pps',
  '.pptm',
  '.potm',
  '.ppsm',
  '.ppam',
  '.quickenbackup',
  '.QDF-backup',
];
