import React from 'react';
import InputField from 'common/InputField';
import AccountDateField from 'common/AccountDateField';
import { SelectField } from 'common/CustomFields';
import { getFirmType } from 'utilities/utils';
import { EncryptedField } from 'common/EncryptedField/EncryptedField';
import USStates from 'components/Contacts/ContactCreation/usastates.json';
import { maxFutureLicenceExpiryDate, today } from './TempDateHelpers';

const IndividualAccountDetails = (props) => {
  const { formdata, errors, preferences } = props.data;
  const notCpa = getFirmType() !== 'cpa';

  return (
    <div>
      <div className="section-title mt-3 mb-3">
        <h5>{notCpa ? 'Matter Details' : 'Taxpayer Details'}</h5>
      </div>
      <div className="row">
        <InputField
          name="taxpayer_first_name"
          label={notCpa ? 'Matter First Name' : 'First Name'}
          className="col-sm-12 col-lg-4 col-md-4 form-group"
          onChange={props.onChange}
          value={formdata.taxpayer_first_name}
          error={errors.taxpayer_first_name}
          onBlur={props.onBlur}
        />
        <InputField
          name="taxpayer_middle_name"
          label={notCpa ? 'Matter Middle Name' : 'Middle Name'}
          className="col-sm-12 col-lg-4 col-md-4 form-group"
          onChange={props.onChange}
          value={formdata.taxpayer_middle_name}
          error={errors.taxpayer_middle_name}
          onBlur={props.onBlur}
        />
        <InputField
          name="taxpayer_last_name"
          label={notCpa ? 'Matter Last Name' : 'Last Name'}
          className="col-sm-12 col-lg-4 col-md-4 form-group"
          onChange={props.onChange}
          value={formdata.taxpayer_last_name}
          error={errors.taxpayer_last_name}
          onBlur={props.onBlur}
        />
      </div>
      <div className="row">
        <SelectField
          name="in_curr_filling_status"
          label="What's your current filing status?"
          className="col-sm-12 col-lg-4 col-md-4 form-group"
          handleChange={(input) =>
            props.handleSelect('in_curr_filling_status', input)
          }
          value={{
            value: formdata.in_curr_filling_status,
            label: formdata.in_curr_filling_status,
          }}
          error={errors.in_curr_filling_status}
          options={preferences.filing_status}
          onBlur={props.onBlur}
          clearable
        />
        <EncryptedField
          recordId={formdata?.id}
          label="Taxpayer ID"
          recordType="Account"
          fieldName="in_self_ssn"
          formFieldName="encryptedInSelfSsn"
          className="col-sm-12 col-lg-4 col-md-4 form-group"
          value={formdata?.encryptedInSelfSsn}
          error={errors?.encryptedInSelfSsn}
          onChange={props.handleEncryptedFieldChange}
          hasEncryptedValue={formdata?.encrypted_in_self_ssn?.populated}
        />
        <AccountDateField
          label="Birthdate"
          name="in_self_birthdate"
          onChange={props.handleDateField}
          value={formdata.in_self_birthdate}
          error={errors.in_self_birthdate}
          className="col-sm-12 col-lg-4 col-md-4 form-group"
          onBlur={props.onBlur}
          maxDate={today}
        />
      </div>
      <div className="row">
        <InputField
          name="in_self_driver_license"
          label="Drivers License #"
          className="col-sm-12 col-lg-3 col-md-3 form-group"
          onChange={props.onChange}
          value={formdata.in_self_driver_license}
          error={errors.in_self_driver_license}
          onBlur={props.onBlur}
          type="text"
        />
        <AccountDateField
          label="Date Issued (mm/dd/yyyy)"
          name="in_self_license_issue_date"
          onChange={props.handleDateField}
          value={formdata.in_self_license_issue_date}
          error={errors.in_self_license_issue_date}
          className="col-sm-12 col-lg-3 col-md-3 form-group"
          onBlur={props.onBlur}
          maxDate={today}
        />
        <AccountDateField
          label="Date Expired (mm/dd/yyyy)"
          name="in_self_license_expiry_date"
          onChange={props.handleDateField}
          value={formdata.in_self_license_expiry_date}
          error={errors.in_self_license_expiry_date}
          className="col-sm-12 col-lg-3 col-md-3 form-group"
          onBlur={props.onBlur}
          maxDate={maxFutureLicenceExpiryDate()}
          minDate={formdata.in_self_license_issue_date}
        />
        <SelectField
          name="in_self_license_state"
          label="State of issue"
          className="col-sm-12 col-lg-3 col-md-3 form-group"
          handleChange={(input) =>
            props.handleSelect('in_self_license_state', input)
          }
          value={{
            value: formdata.in_self_license_state,
            label: formdata.in_self_license_state,
          }}
          error={errors.in_self_license_state}
          options={USStates}
          onBlur={props.onBlur}
          clearable
        />
      </div>
    </div>
  );
};

export default IndividualAccountDetails;
