/* eslint-disable no-script-url */
/* eslint-disable react/jsx-no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
// /* eslint-disable quotes */
import React, { Component } from 'react';
import moment from 'moment-timezone';
import HeaderTabs from 'common/HeaderTabs';
import * as TaskApi from 'api/TaskApi';
import ConfirmModal from 'common/ConfirmationModal';
import { MIXPANEL_COMMENT_EVENT } from 'constants/constants';
import { trackMixpanelEvent } from 'utilities/mixpanelfn';
import { isClient } from 'utilities/utils';
import { withRouter } from 'utilities/withRouterWrapper';
import ReadOnlyTinyMCE from 'common/ReadOnlyTinyMCE/ReadOnlyTinyMCE';
import LinkedEmails from 'components/Emails/LinkedEmails';
import TinymceEditor from 'components/Emails/components/TinymceEditior';
import InvoiceHistory from 'components/Billing/elements/InvoiceHistory';

const commomTabs = [
  {
    value: 'my_activity',
    label: 'All Activity',
    array: 'commentsActivity',
  },
  {
    value: 'comments',
    label: 'Comments',
    array: 'comments',
  },
];

const tabs = [
  ...commomTabs,
  {
    value: 'history',
    label: 'History',
    array: 'commentsHistory',
  },
  !isClient()
    ? {
        value: 'emails',
        label: 'Emails',
        array: 'linkedEmails',
      }
    : {},
];

const invoiceHistoryTab = {
  value: 'invoice_history',
  label: 'Invoice History',
  array: 'invoiceHistory',
};

const timeZone =
  Intl.DateTimeFormat().resolvedOptions().timeZone || moment.tz.guess(true);
class DetailBottom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addComments: '',
      loading: false,
      showEditor: false,
      viewModal: false,
    };
    this.handleCommentChange = this.handleCommentChange.bind(this);
    this.switchEditor = this.switchEditor.bind(this);
    this.cancelPopup = this.cancelPopup.bind(this);
    this.cancelConfirm = this.cancelConfirm.bind(this);
    // window.doSomething = (data) => {
    //   // Do anything you like - >
    //   this.props.onDocumentClick(data, true);
    // };
  }

  componentDidMount() {
    const { location, changeActiveTab } = this.props;

    if (location.state) {
      const { isInvoiceHistoryTab } = location.state;

      const myActivityTab = {
        value: 'my_activity',
        label: 'All Activity',
        array: 'commentsActivity',
      };

      // set the default active tab when navigating from Invoice History tab
      if (isInvoiceHistoryTab) {
        changeActiveTab(0, myActivityTab);
      }
    }
  }

  switchEditor() {
    this.setState({ showEditor: true });
  }

  addComment() {
    const Comment = this.state.addComments;
    let id;
    const url = window.location.pathname.split('/');
    if (url) {
      id = url[url.length - 1];
    }
    const data = {
      task_id: id,
      comment: Comment,
    };
    TaskApi.addComment(this, data, 'taskdetail');
    trackMixpanelEvent(MIXPANEL_COMMENT_EVENT);
  }

  handleCancel() {
    const { addComments } = this.state;
    const Comment = addComments;
    if (Comment) {
      this.setState({ viewModal: true });
    } else {
      this.props.toggle();
    }
  }

  cancelPopup() {
    this.setState({ viewModal: false });
    this.props.toggle();
  }

  cancelConfirm() {
    this.setState({ viewModal: false });
  }

  handleCommentChange(value) {
    this.setState({ addComments: value });
  }

  renderComments() {
    const component = this;
    let comments = '';
    const arrayName = this.props.data.activeArray;
    if (this.props.data.comments) {
      comments = this.props.data[arrayName].map((item) => {
        let commentTime = item ? item.tym : '';
        const parsedTime = item ? item.created_at : '';
        if (commentTime && commentTime.indexOf('ago') === -1) {
          const zonedTime = moment.tz(parsedTime, timeZone);
          commentTime = `on ${zonedTime.format(
            'MMM DD, YYYY',
          )} at ${zonedTime.format('hh:mm a')}`;
        }
        const initials =
          item && item.full_name
            ? (item.full_name.split(' ')[0]
                ? item.full_name.split(' ')[0].charAt(0)
                : '') +
              (item.full_name.split(' ')[1]
                ? item.full_name.split(' ')[1].charAt(0)
                : '')
            : '';
        let comment = item.comment_for_web_display
          ? item.comment_for_web_display
          : item.comment;
        let docName;
        let docLink;
        let docDetails;
        if (
          component.props.data.documents &&
          component.props.data.documents.length > 0
        ) {
          component.props.data.documents.forEach((doc) => {
            if (doc) {
              const tempdocName = doc.file_name;
              if (
                comment &&
                comment !== undefined &&
                comment.includes(tempdocName)
              ) {
                // window.encoded = String(encodeURI(JSON.stringify(doc)));
                // let docNameWithLink;
                docName = tempdocName;
                docDetails = doc;
                if (doc.is_active) {
                  docLink = docName;
                }
                if (!comment.includes('doSomething')) {
                  comment = comment.replace(docName, '');
                }
              }
            }
          });
        }
        return (
          <div key={item.id} className="chatMsg--block--repeat DetailBottom" data-testid="chatMessage">
            <div
              className={`avatar avatar--w30 ${
                item.user_type === 'employee'
                  ? 'avatar--border avatar--borderPrimary'
                  : ''
              }`}
            >
              {item.avatar ? (
                <img
                  src={item.avatar}
                  alt="Person"
                  className="rounded-circle"
                />
              ) : (
                <span className="uName">{initials.toUpperCase()}</span>
              )}
            </div>
            <div className="chatMsg--msg RTE__Description">
              <div className="chatMsg--Header row">
                <div className="col-md-9 col-12">
                  <h6>
                    <b>{item.comment_by}</b>
                    {!item.is_auto_generated && 'added a comment'}
                    {item.is_auto_generated && (
                      <span>
                        <ReadOnlyTinyMCE content={comment} />
                        {docName ? (
                          docLink ? (
                            <span
                              onClick={() =>
                                this.props.onDocumentClick(docDetails, false)
                              }
                            >
                              <a href="javascript:void(0)">{docName}</a>
                            </span>
                          ) : (
                            <span>{docName}</span>
                          )
                        ) : null}
                      </span>
                    )}
                  </h6>
                </div>
                <div className="col-md-3 col-12 text-right">
                  <span className="chatMsg--time">{commentTime}</span>
                </div>
              </div>
              <div className="chatMsg--comment">
                {!item.is_auto_generated && (
                  <>
                    <ReadOnlyTinyMCE content={comment} />
                    {docName ? (
                      docLink ? (
                        <span
                          className="d-block"
                          onClick={() =>
                            this.props.onDocumentClick(docDetails, false)
                          }
                        >
                          <a href="javascript:void(0)">{docName}</a>
                        </span>
                      ) : (
                        <span>{docName}</span>
                      )
                    ) : null}
                  </>
                )}
              </div>
            </div>
          </div>
        );
      });
      return comments;
    }
  }

  render() {
    const { isInvoiceTask } = this.props;
    const { is_parent_task } = this.props.props.taskDetail;

    let filteredTabs;
    if (isInvoiceTask && is_parent_task) {
      filteredTabs = [...commomTabs, invoiceHistoryTab];
    } else if (isInvoiceTask) {
      filteredTabs = commomTabs;
    } else {
      filteredTabs = tabs;
    }

    const data = this.props.data.homedata;
    const dataInitials =
      data && data.uname
        ? (data.uname.split(' ')[0] ? data.uname.split(' ')[0].charAt(0) : '') +
          (data.uname.split(' ')[1] ? data.uname.split(' ')[1].charAt(0) : '')
        : '';
    const noneStyle = { display: 'none' };
    const blockStyle = { display: 'block' };

    return (
      <div className="bottomTabs" data-testid="bottomTabs">
        <div id="loading" style={this.state.loading ? blockStyle : noneStyle} />
        <HeaderTabs
          data={filteredTabs}
          activeTab={this.props.data.activeTab}
          handleClick={this.props.changeActiveTab}
        />
        {this.props.data && this.props.data.activeArray === 'linkedEmails' && (
          <div style={{ margin: '-1.5rem -2.3rem' }}>
            <LinkedEmails
              taskId={this.props.taskId}
              viewContext="task"
              props={this.props}
              data-testid="linkedEmails"
            />
          </div>
        )}

        {this.props.data &&
          this.props.data.activeArray === 'invoiceHistory' &&
          is_parent_task && <InvoiceHistory data-testid="invoiceHistory" />}
        <div className="tab-content">
          <div id="allActivity" className="tab-pane active">
            <div className="chatMsg chatMsg--wrap">
              <div className="chatMsg--block" data-testid="chatMsgBlock">{this.renderComments()}</div>
            </div>
          </div>
        </div>
        {this.props.data.taskStatus === 'Closed' ? null : (
          <div className="chatMsg--newmsg" data-testid="chatNewMsg">
            <div
              className={`avatar avatar--w30 ${
                data.is_employee ? 'avatar--border avatar--borderPrimary' : ''
              }`}
            >
              {data.avatar ? (
                <img
                  src={data.avatar}
                  alt="Person"
                  className="rounded-circle"
                  data-testid="avatarImage"
                />
              ) : (
                <span className="uName">{dataInitials.toUpperCase()}</span>
              )}
            </div>
            <div className="chatMsg--inputchat w-100">
              {this.state.showEditor ? (
                <div className="customEditor boxResize">
                  <div className="posRel">
                    <TinymceEditor
                      initialValue=""
                      height="200px"
                      value={this.state.addComments}
                      handleChange={this.handleCommentChange}
                      disabled={this.props.data.taskStatus === 'Closed'}
                      data-testid="tinymceEditor"
                    />
                  </div>
                  <div className="d-flex btnWrap btn--leftspace justify-content-end mt-3">
                    <button
                      type="button"
                      onClick={() => this.handleCancel()}
                      className="btn btn-outline-light"
                      data-testid="task__cancel_btn"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      onClick={() => this.addComment()}
                      disabled={this.props.data.taskStatus === 'Closed'}
                      className="btn btn-primary"
                      data-testid="addCommentButton"
                    >
                      Add Comment
                    </button>
                  </div>
                </div>
              ) : (
                <div className="chatMsg--inputchat w-100 customEditor customEditor--bottombtn">
                  <textarea
                    name="description"
                    className="w-100 DraftEditor"
                    onFocus={this.switchEditor}
                    data-testid="commentTextarea"
                  />
                </div>
              )}
            </div>
          </div>
        )}
        <ConfirmModal
          isOpen={this.state.viewModal}
          messageText="This action cannot be undone. Proceed?"
          yesBtnText="Proceed"
          noBtnText="Cancel"
          yesBtnClick={this.cancelPopup}
          noBtnClick={this.cancelConfirm}
          data-testid="confirmModal"
        />
      </div>
    );
  }
}

export default withRouter(DetailBottom);
