import { useEffect } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { dispatchAuthUserRedirect } from './session';

export type AuthUserDispatcherProps = {
  children?: React.ReactNode;
  // TODO: Potentially block rendering until loaded
};

/**
 * AuthUserDispatcher
 * Handles dispatching authenticated users to their intended site location
 * based on the current universal session cookie.
 * NOTE: Login redirects are handled directly during login in `Login.js`.
 * This wrapper solely handles dispatcing redirects for existing sessions.
 * We could potentially refactor how login works and have this component
 * mange dispatch of all cross-site redirects.
 *
 * TODO: DEV-2881 Make sure we cature and store auth redirects so that users are
 * navigated appropriately when they've reached their destinations
 */
export const AuthUserDispatcher = ({ children }: AuthUserDispatcherProps) => {
  const { universalCookieAuthFeature } = useFlags();

  useEffect(() => {
    if (universalCookieAuthFeature) {
      dispatchAuthUserRedirect();
    }
  }, [universalCookieAuthFeature]);

  return children;
};
