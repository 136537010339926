import React from 'react';

class StyleButton extends React.Component {
  constructor() {
    super();
    this.onToggle = (e) => {
      e.preventDefault();
      this.props.onToggle(this.props.style);
    };
  }

  render() {
    let liscioIcon = '';
    if (this.props.style === 'ordered-list-item') {
      liscioIcon = 'icon-format_list_numbered';
    }
    if (this.props.style === 'unordered-list-item') {
      liscioIcon = 'icon-format_list_bulleted';
    }
    if (this.props.style === 'align-left') {
      liscioIcon = 'icon-format_align_left';
    }
    if (this.props.style === 'align-right') {
      liscioIcon = 'icon-format_align_right';
    }
    if (this.props.style === 'align-center') {
      liscioIcon = 'icon-format_align_center';
    }
    if (this.props.style === 'align-justify') {
      liscioIcon = 'icon-format_align_justify';
    }
    let className = `RichEditor-styleButton ${liscioIcon}`;
    if (this.props.active) {
      className += ' RichEditor-activeButton';
    }

    return (
      <span
        role="presentation"
        className={`${className} ${this.props.styleName}`}
        onMouseDown={this.onToggle}
      >
        {this.props.label}
      </span>
    );
  }
}

export default StyleButton;
