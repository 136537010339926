import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
} from '@mui/material';
import { useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAddSmsDocumentToVault } from 'hooks/sms-hooks';
import { AlertMessage } from 'utilities/utils';
import { TOAST_FAILURE, TOAST_SUCCESS } from 'constants/constants';
import FileNameElement from './FileNameElement';
import ToFieldElement from './ToFieldElement';
import MonthYearElement from './MonthYearElement';
import TagsElement from './TagsElement';
import { SMSVaultDialogType, formSchema } from './FormValidation';

interface Props {
  textMessageThreadId: string;
  blobId: string;
  onClose: () => void;
}

export default function SMSVaultDialog({
  textMessageThreadId,
  blobId,
  onClose,
}: Props) {
  const [open, setOpen] = useState(true);
  const { mutate: addSmsToVault, error } = useAddSmsDocumentToVault();
  const formMethods = useForm<SMSVaultDialogType>({
    resolver: yupResolver(formSchema),
  });

  const { handleSubmit } = formMethods;

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  const onSubmit: SubmitHandler<SMSVaultDialogType> = (params) => {
    addSmsToVault(
      { ...params, textMessageThreadId, blobId },
      {
        onSuccess() {
          AlertMessage(
            TOAST_SUCCESS,
            'Document successfully uploaded to the Vault.',
          );
        },
        onError() {
          AlertMessage(TOAST_FAILURE, error);
        },
        onSettled() {
          setOpen(false);
          onClose();
        },
      },
    );
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>Add to vault</DialogTitle>
      <Divider />
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Stack>
            <FormProvider {...formMethods}>
              <FileNameElement />
              <ToFieldElement />
              <Stack direction="row" justifyContent="space-between">
                <MonthYearElement monthOrYear="YEAR" />
                <MonthYearElement monthOrYear="MONTH" />
              </Stack>
              <TagsElement />
            </FormProvider>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" type="submit">
            Upload
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
