import React, { useEffect, useState } from 'react';
import { INVOICE_TYPE } from 'constants/constants';
import { Check } from '@mui/icons-material';

const InvoiceOption = ({
  onSelectFilter,
  option,
  selected = {},
  toggleFilterVisible,
}) => {
  const handleClick = (e) => {
    e.stopPropagation();
    if (toggleFilterVisible) toggleFilterVisible();
    onSelectFilter({
      type: INVOICE_TYPE,
      value: option,
    });
  };
  return (
    <button
      className="dropdown-item"
      type="button"
      onClick={handleClick}
      key={`recurring_option_${option}`}
    >
      <span>{option}</span>
      {selected === option && <Check className="icon-small" />}
    </button>
  );
};

const InvoiceTypeFilter = (props) => {
  const {
    options,
    selected,
    onSelectFilter,
    showFilterOptions = false,
    setFilterInFocus,
  } = props;
  const isSelected = selected ? 'active' : '';
  const [filterActive, setFilterActive] = useState(showFilterOptions);

  const toggleFilterVisible = (e = {}) => {
    if (e.stopPropagation) e.stopPropagation();
    setFilterActive(!filterActive);
    if (setFilterInFocus) setFilterInFocus(INVOICE_TYPE);
  };

  useEffect(() => {
    setFilterActive(showFilterOptions);
  }, [showFilterOptions]);

  return (
    <div className="BillingFilter__InvoiceType dropdown">
      <button
        className={`btn btn-sm btn-outline-light dropdown-toggle ${isSelected} ${
          filterActive ? 'show' : ''
        }`}
        type="button"
        id="invoiceTypeFilter"
        onClick={toggleFilterVisible}
        aria-haspopup="true"
        aria-expanded="false"
      >
        {INVOICE_TYPE}
      </button>
      <div
        className={`dropdown-menu ${filterActive ? 'show' : ''}`}
        aria-labelledby="invoiceTypeFilter"
        onClick={toggleFilterVisible}
        role="presentation"
      >
        {Array.isArray(options)
          ? options.map((option) => (
              <InvoiceOption
                onSelectFilter={onSelectFilter}
                selected={selected}
                option={option}
                key={option}
                toggleFilterVisible={toggleFilterVisible}
              />
            ))
          : null}
      </div>
    </div>
  );
};

export default InvoiceTypeFilter;
