/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { ModalBody } from 'reactstrap';
import bankLogo from 'images/bank_logo.png';

// for showing customer bank accounts
const Sources = ({
  customerSources,
  handleACHPayment,
  invoiceAmount,
  handleCancel,
  addNewBank,
  loading,
  gettingPlaidPublicToken,
  mode,
}) => (
  <ModalBody className="modalContent">
    <div className="modalContent--inner">
      <h4 className="font-weight-bold">
        Payment Amount:{' '}
        {Number(invoiceAmount).toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        })}
      </h4>
      <div className="row no-gutters">
        <h5>Select your bank</h5>
        {customerSources.map((e) => (
          <div className="card w-100 border-0" key={e.id}>
            <div className="card-header pt-1 pb-1 pl-0 pr-0 border-0 bg-transparent">
              <div className="row align-items-center no-gutters">
                <div>
                  <a href="#" className="avatar avatar avatar--w30">
                    <img alt="bank_logo" src={bankLogo} className="rounded-0" />
                  </a>
                </div>
                <div className="col pr-0 pl-0">
                  <h4 className="d-block h6 mb-0">{e.bank_name}</h4>
                  <small className="d-block text-muted">{`****_****_****_${e.last4}`}</small>
                </div>
                <div>
                  <button
                    type="button"
                    className="btn btn-primary bg-dark border-0"
                    onClick={() => handleACHPayment(e.id)}
                    disabled={loading || gettingPlaidPublicToken}
                  >
                    {loading
                      ? 'Processing'
                      : `${mode === 'setup' ? 'Authorize' : 'Pay'}`}
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="row mt-4 no-gutters">
        <button type="submit" className="btn mr-2" onClick={handleCancel}>
          Back
        </button>
        <button
          type="submit"
          className="btn btn-primary bg-dark border-0"
          onClick={addNewBank}
          disabled={loading || gettingPlaidPublicToken}
        >
          {gettingPlaidPublicToken ? 'Loading' : ' Add New Account'}
        </button>
      </div>
    </div>
  </ModalBody>
);

export default Sources;
