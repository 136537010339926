import { Box, Stack } from '@mui/material';

export interface PageProps {
  children: React.ReactNode;
  header?: React.ReactNode;
  headerHeight?: number;
  contentSX?: React.CSSProperties;
}

// Currently only used in Workflow/Request Views
export const Page = ({
  header,
  children,
  headerHeight = 55,
  contentSX,
}: PageProps) => {
  const footerHeight = 32;
  const pageHeightCalc = header ? headerHeight + footerHeight : footerHeight;

  return (
    <>
      {header && header}
      <Box
        display="flex"
        overflow="hidden"
        flexDirection="column"
        sx={{ height: `calc(100vh - ${pageHeightCalc}px)` }}
        id="pageContainer"
      >
        <Stack
          direction="column"
          alignItems="flex-start"
          justifyContent="flex-start"
          sx={contentSX || { overflowY: 'auto' }}
        >
          {children}
        </Stack>
      </Box>
    </>
  );
};
