import { DialogContentText, Typography } from '@mui/material';
import { WorkflowRequestStatus } from 'gql/graphql';

type Props = {
  currentActionType: WorkflowRequestStatus | null;
};

// helper component for rendering the Archive dialog
function ArchiveDialogContent() {
  return (
    <DialogContentText>
      <Typography variant="body1">
        Are you sure you want to{' '}
        <Typography fontWeight="bold" component="span">
          archive
        </Typography>{' '}
        this request for the recipient?
      </Typography>
      <br />
      <Typography variant="body1">
        Once archived, the request cannot be resent. Both firm users and client
        users can still see this request under the{' '}
        <Typography fontWeight="bold" component="span">
          Archived
        </Typography>{' '}
        tab.
      </Typography>
    </DialogContentText>
  );
}

// helper component for rendering the Recall dialog
function RecallDialogContent() {
  return (
    <DialogContentText>
      <Typography variant="body1">
        Are you sure you want to{' '}
        <Typography fontWeight="bold" component="span">
          recall
        </Typography>{' '}
        this request for the recipient?
      </Typography>
      <br />
      <Typography variant="body1">
        Once recalled, the request cannot be resent. As a firm user, you can
        still see this request under the{' '}
        <Typography fontWeight="bold" component="span">
          Archived
        </Typography>{' '}
        tab.
      </Typography>
    </DialogContentText>
  );
}

/**
 * This is the primary component which is used to determine what modal
 * dialog content to render.
 */
export function DialogContentRenderer({ currentActionType }: Props) {
  switch (currentActionType) {
    case WorkflowRequestStatus.Closed:
      return <ArchiveDialogContent />;
    case WorkflowRequestStatus.Recalled:
      return <RecallDialogContent />;
    default:
      return null;
  }
}
