import React from 'react';
import './common.scss';

const AlertBox = (props) => {
  if (!props) {
    return null;
  }

  const {
    text,
    subText,
    type,
    children,
    onClickAll,
    onClickText,
    onClickSubText,
  } = props || {};
  const wrapperClass = `alert-box alert-${type} ${
    onClickAll ? 'underlined-link' : ''
  }`;
  const textClass = `main-alert-text ${onClickText ? 'underlined-link' : ''}`;
  const subTextClass = `alert-subText ${
    onClickSubText ? 'underlined-link' : ''
  }`;

  return (
    <div className={wrapperClass} onClick={onClickAll}>
      <div className={textClass} onClick={onClickText}>
        {text}
      </div>
      <div className={subTextClass} onClick={onClickSubText}>
        {subText}
      </div>
      {children}
    </div>
  );
};

export default AlertBox;
