import { ToolbarItem } from 'pspdfkit';
import { DownloadWithFileNameButton } from './CustomButtons/Download';
import { RotateClockwiseButton } from './CustomButtons/RotateClockwise';
import { RotateCounterClockwiseButton } from './CustomButtons/RotateCounterClockwise';
import { ToggleAnnotationsButton } from './CustomButtons/ToggleAnnotations';

// the left side of the toolbar
const pageControlsToolbarItems: ToolbarItem[] = [
  { type: 'sidebar-thumbnails' },
  { type: 'pager' },
  { type: 'zoom-out' },
  { type: 'zoom-in' },
  { type: 'zoom-mode' },
  RotateCounterClockwiseButton as ToolbarItem,
  RotateClockwiseButton as ToolbarItem,
];

// the right side of the toolbar
const pageExportToolbarItems: ToolbarItem[] = [
  { type: 'search' },
  { type: 'print' },
  DownloadWithFileNameButton as ToolbarItem,
];

// Anotations toolbar items (behind flag or permission)
const pageAnnotationToolbarItems: ToolbarItem[] = [
  ToggleAnnotationsButton as ToolbarItem,
  { type: 'annotate' },
  { type: 'ink' },
  { type: 'highlighter' },
  { type: 'text-highlighter' },
  { type: 'ink-eraser' },
  { type: 'signature' },
  { type: 'image' },
  { type: 'stamp' },
  { type: 'note' },
  { type: 'text' },
  { type: 'callout' },
  { type: 'line' },
  { type: 'link' },
  { type: 'arrow' },
  { type: 'rectangle' },
  { type: 'ellipse' },
  { type: 'polygon' },
  { type: 'cloudy-polygon' },
  { type: 'polyline' },
];

export const basicToolbarItems: ToolbarItem[] = [
  ...pageControlsToolbarItems,
  { type: 'spacer' },
  ...pageExportToolbarItems,
];

export const basicToolbarItemsWithAnnotations: ToolbarItem[] = [
  ...pageControlsToolbarItems,
  { type: 'spacer' },
  ...pageAnnotationToolbarItems,
  { type: 'spacer' },
  ...pageExportToolbarItems,
];

// https://pspdfkit.com/api/web/PSPDFKit.html#.defaultToolbarItems
export const defaultToolbarItems: ToolbarItem[] = [
  { type: 'sidebar-thumbnails' },
  { type: 'sidebar-document-outline' },
  { type: 'sidebar-annotations' },
  { type: 'sidebar-bookmarks' },
  { type: 'sidebar-signatures' },
  { type: 'sidebar-layers' },
  { type: 'pager' },
  { type: 'multi-annotations-selection' },
  { type: 'pan' },
  { type: 'zoom-out' },
  { type: 'zoom-in' },
  { type: 'zoom-mode' },
  { type: 'spacer' },
  { type: 'annotate' },
  { type: 'ink' },
  { type: 'highlighter' },
  { type: 'text-highlighter' },
  { type: 'ink-eraser' },
  { type: 'signature' },
  { type: 'image' },
  { type: 'stamp' },
  { type: 'note' },
  { type: 'text' },
  { type: 'callout' },
  { type: 'line' },
  { type: 'link' },
  { type: 'arrow' },
  { type: 'rectangle' },
  { type: 'ellipse' },
  { type: 'polygon' },
  { type: 'cloudy-polygon' },
  { type: 'polyline' },
  { type: 'print' },
  { type: 'document-editor' },
  { type: 'document-crop' },
  { type: 'search' },
  { type: 'export-pdf' },
  { type: 'debug' },
];
