import React from 'react';
import styled from '@emotion/styled';
import { ReactComponent as IconMenu } from 'images/icons/edit.svg';

const StyledIcons = styled.span`
  &.button-icon {
    background: #ffffff;
    padding: 1px 4px;
    border: 2px solid #dfe3e8;
    border-radius: 2px;
    cursor: pointer;
    svg {
      fill: #919eab;
    }
  }
`;

const AssociateEdit = ({ handleEdit }) => (
  <StyledIcons className="button-icon" onClick={handleEdit}>
    <IconMenu width="1rem" />
  </StyledIcons>
);

export default AssociateEdit;
