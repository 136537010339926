import React, { useMemo } from 'react';
import { usePageTitle } from 'hooks/usePageTitle';
import { Box, BoxProps } from '@mui/material';
import { BreadCrumbs, BreadcrumbType } from 'common/Breadcrumbs/Breadcrumbs';

const INTEGRATIONS_BASE_PATH = '/integrations';
export interface IntegrationsPageLayoutProps extends BoxProps {
  // Document page title
  pageTitle?: string;
  // Additional module breadcrumbs
  breadcrumbs?: BreadcrumbType[];
  // Page Content
  children?: React.ReactNode;
}

export const IntegrationsPageLayout = ({
  pageTitle = 'Integrations',
  breadcrumbs: additionalbreadcrumbs = [],
  children,
  ...rest
}: IntegrationsPageLayoutProps) => {
  usePageTitle(pageTitle);
  const breadcrumbs = useMemo(
    () => [
      { label: 'Integrations', path: INTEGRATIONS_BASE_PATH },
      ...additionalbreadcrumbs.map((crumb) => ({
        ...crumb,
        path: `${INTEGRATIONS_BASE_PATH}${crumb.path}`,
      })),
    ],
    [additionalbreadcrumbs],
  );

  return (
    <Box id="content">
      <Box component="header">
        <BreadCrumbs breadcrumbs={breadcrumbs} />
      </Box>
      <Box {...rest}>{children}</Box>
    </Box>
  );
};
