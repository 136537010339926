import { useCallback, useState } from 'react';

type CopiedValue = string | null;
type CopyFn = (text: string) => Promise<boolean>;
type ClipboardSupported = boolean;

// Copied from https://usehooks-ts.com/react-hook/use-copy-to-clipboard
// modified to return a clipboardSupported boolean.
export function useCopyToClipboard(): [
  CopiedValue,
  CopyFn,
  ClipboardSupported,
] {
  const [copiedText, setCopiedText] = useState<CopiedValue>(null);
  const [clipboardSupported, setClipboardSupported] = useState<boolean>(
    !!navigator?.clipboard,
  );

  const copy: CopyFn = useCallback(async (text) => {
    if (!navigator?.clipboard) {
      setClipboardSupported(false);
      return false;
    }

    // Try to save to clipboard then save it in the state if worked
    try {
      await navigator.clipboard.writeText(text);
      setCopiedText(text);
      return true;
    } catch (error) {
      setCopiedText(null);
      return false;
    }
  }, []);

  return [copiedText, copy, clipboardSupported];
}
