/* eslint-disable no-undef */
import React /*, { useState, useEffect, useRef, useCallback } */ from 'react';
// import { Button, FormGroup, Label, Input } from 'reactstrap';
// import Axios from 'axios';
// import {
//   USER_EMAILS,
//   EMAIL_ATTACHMENTS,
//   MIXPANEL_EMAIL_EVENT,
// } from 'constants/constants';
// import { generateUniqueHash } from 'common/NotesUtilities';
// import { AlertMessage } from 'utilities/utils';
// import TaskFileUpload from 'components/Files/TaskFileUpload';
// import { trackMixpanelEvent } from 'utilities/mixpanelfn';
// import EmailInputField from './EmailInputField';
// import { getFormattedData } from '../EmailUtilities';
// import DisplayEmailAttachments from './DisplayEmailAttachments';
// import useMountedState from './useMountedState';
// import TinymceEditor from './TinymceEditior';
// import './TinymceEditior.scss';

// function useStateCallback(initialState) {
//   const [state, setState] = useState(initialState);
//   const cbRef = useRef(null); // mutable ref to store current callback

//   const setStateCallback = useCallback((newState, cb) => {
//     cbRef.current = cb; // store passed callback to ref
//     setState(newState);
//   }, []);

//   useEffect(() => {
//     // cb.current is `null` on initial render, so we only execute cb on state *updates*
//     if (cbRef.current) {
//       cbRef.current(state);
//       cbRef.current = null; // reset callback after execution
//     }
//   }, [state]);

//   return [state, setStateCallback];
// }

// const ComposeEmailFromThread = ({
//   initialState,
//   connectedAppKey,
//   composeEmailAction,
//   viewContext,
//   emailDetails,
//   msgInrClass,
//   connectedAppAccessToken,
// }) => {
//   const [state, setState] = useStateCallback({
//     initialBody: initialState.body,
//     subject: initialState.subject,
//     body: initialState.body,
//     toRecipients: initialState.toRecipients,
//     ccRecipients: initialState.ccRecipients,
//     bccRecipients: initialState.bccRecipients,
//     showCCInput: initialState.ccRecipients.length,
//     showBCCInput: initialState.bccRecipients.length,
//     dropdownOpen: false,
//     errors: {},
//     outlookToken: '',
//     emailStatus: '',
//     draftId: initialState.draftId,
//     sendApiHitting: false,
//     replyToMsgId: initialState.replyToMsgId,
//     isDraft: true,
//     viewContext: viewContext || '',
//     documents: [],
//     gettingInlineImages:
//       initialState.attachments &&
//       initialState.attachments.length &&
//       initialState.attachments.filter(
//         (e) =>
//           e.content_disposition !== null &&
//           e.content_disposition.includes('inline'),
//       ).length,
//     attachments: initialState.attachments,
//     emailId: initialState.emailId,
//   });
//   const [saveData, setSaveData] = useState(false);
//   const [description, setDescription] = useState(initialState.body);
//   const isDraft = useRef(true);
//   const isMounted = useMountedState();

//   // console.log(state);

//   // to toggle cc and bcc fields
//   const toggleRecipientInputField = (type) => {
//     const recipient = type === 'CC' ? 'cc' : 'bcc';
//     if (
//       state[`${recipient}Recipients`] &&
//       state[`${recipient}Recipients`].length
//     ) {
//       setState((ps) => ({
//         ...ps,
//         [`${recipient}Recipients`]: [],
//       }));
//     } else if (viewContext !== 'forward') {
//       const newState = {};
//       const recipientsType = type === 'CC' ? 'cc_email' : 'bcc_email';
//       if (initialState[recipientsType]) {
//         newState[`${recipient}Recipients`] = initialState[recipientsType];
//       }
//       setState((ps) => ({ ...ps, ...newState }));
//     }
//     setState((ps) => {
//       const errors = { ...ps.errors };
//       if (ps[`show${type}Input`]) delete errors[`${type}Recipients`];
//       return {
//         ...ps,
//         [`show${type}Input`]: !ps[`show${type}Input`],
//       };
//     });
//   };

//   // need these tokens for getting contacts from outlook and gmail
//   const initializeGoogleClient = async () => {
//     try {
//       if (connectedAppKey && connectedAppKey === 'outlookemail') {
//         setState((ps) => ({
//           ...ps,
//           outlookToken: connectedAppAccessToken,
//         }));
//       } else {
//         // await gapi.client.init({ apiKey: 'AIzaSyB8PwThztg96sboSdQbD47Zb3O7K0tOSNU' });
//         await gapi.client.setToken({ access_token: connectedAppAccessToken });
//       }
//     } catch (error) {
//       console.log(error, 'error');
//     }
//   };

//   // for getting inline images blob data when we show draft for first time
//   // we only use this if inline images are present in the draft email
//   const getInlineImages = async () => {
//     try {
//       let emailBody = initialState.body;
//       const response = await Axios.get(
//         `${USER_EMAILS}/${state.emailId}/attachments?content_disposition=inline`,
//       ).then((res) => res.data.data);
//       response.forEach((doc) => {
//         if (doc.content_type.includes('image') && doc.attachment_body) {
//           emailBody = emailBody.replace(
//             `cid:${doc.content_id.replace('<', '').replace('>', '')}`,
//             `data:${doc.content_type};base64,${doc.attachment_body}`,
//           );
//         }
//       });
//       const allAttachments = state.attachments.filter(
//         (doc) => !doc.content_disposition.includes('inline'),
//       );
//       setState((ps) => ({
//         ...ps,
//         initialBody: emailBody,
//         gettingInlineImages: false,
//         attachments: allAttachments,
//       }));
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   useEffect(() => {
//     if (gapi) {
//       gapi.load('client', initializeGoogleClient);
//     }
//   }, [viewContext, emailDetails]);

//   useEffect(() => {
//     if (state.gettingInlineImages) {
//       getInlineImages();
//     }
//   }, []);

//   // this is for creating, updating draft and send new email
//   const saveEmail = async (status) => {
//     const data = getFormattedData(state);
//     data.message = description;
//     data.is_draft = status === 'Draft';
//     if (status === 'Sent') {
//       setState((ps) => ({
//         ...ps,
//         sendApiHitting: true,
//       }));
//     }
//     try {
//       const url = USER_EMAILS;
//       const response = await Axios.post(url, data).then((res) => res.data);
//       if (status === 'Sent' && response.status === 201) {
//         setState((ps) => ({
//           ...ps,
//           sendApiHitting: false,
//         }));
//         AlertMessage('error', response.message, 3000);
//         trackMixpanelEvent(MIXPANEL_EMAIL_EVENT, {
//           state: 'error',
//           message: response.message,
//         });
//       } else if (status === 'Sent') {
//         isDraft.current = false;
//         composeEmailAction('', {}, response.data, initialState);
//         AlertMessage('success', 'Email sent successfully', 2000);
//         trackMixpanelEvent(MIXPANEL_EMAIL_EVENT, { state: 'sent' });
//       } else if (status === 'Draft') {
//         if (response.status === 200) {
//           trackMixpanelEvent(MIXPANEL_EMAIL_EVENT, { state: 'draft' });
//         }
//       }
//     } catch (error) {
//       setState((ps) => ({
//         ...ps,
//         sendApiHitting: false,
//       }));
//       AlertMessage('error', error.response.data.message, 3000);
//       trackMixpanelEvent(MIXPANEL_EMAIL_EVENT, {
//         state: 'error',
//         message: error.response.data.message,
//       });
//       console.log(error);
//     }
//   };

//   useEffect(() => {
//     if (!saveData) {
//       setSaveData(true);
//     }

//     return () => {
//       if (
//         !isMounted() &&
//         isDraft.current &&
//         saveData &&
//         (!emailDetails ||
//           (emailDetails && !emailDetails.draft_id && !emailDetails.draft_data))
//       ) {
//         console.log('save draft');
//         saveEmail('Draft');
//       } else if (!isMounted() && state.deleteDraft && state.draftId) {
//         console.log('delete draft');
//         composeEmailAction(
//           '',
//           {},
//           [],
//           'discard',
//           viewContext === 'draft' ? emailDetails : state.draftId,
//         );
//       } else if (!isMounted() && !isDraft.current) {
//         console.log('send mail and go back');
//         // composeEmailAction('', {}, [], 'goBack');
//         // Email.sendOutlookNewDraftMail(state, setState, 'Draft', props);
//       }
//     };
//   }, [
//     state.ccRecipients,
//     state.bccRecipients,
//     state.toRecipients,
//     state.subject,
//     description,
//     state.attachments,
//     isDraft,
//   ]);

//   function handleBodyChange(value) {
//     setDescription(value);
//     return value;
//   }

//   // for tracking to,cc, bcc field changes
//   const handleChange = (fieldName) => (value) => {
//     setState((ps) => {
//       const newError = { ...ps.errors };
//       delete newError[fieldName];
//       return {
//         ...ps,
//         errors: { ...newError },
//         [fieldName]: value,
//         emailStatus: 'Draft',
//       };
//     });
//   };

//   // for subject change
//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setState((ps) => {
//       const newError = { ...ps.errors };
//       if (value) delete newError[name];
//       return {
//         ...ps,
//         errors: { ...newError },
//         [name]: value,
//         emailStatus: 'Draft',
//       };
//     });
//   };

//   const handleSubmit = (e) => {
//     try {
//       if (e) {
//         e.preventDefault();
//       }
//       saveEmail('Sent');
//     } catch (error) {
//       const error_response = JSON.parse(error.body);
//       AlertMessage('error', error_response.error.message, 3000);
//     }
//   };

//   // this is for handling liscio dispatch attachments
//   const handleDocuments = (e) => {
//     const files = e.map((file) => {
//       // eslint-disable-next-line no-param-reassign
//       file.file_name = file.doc_name;
//       // eslint-disable-next-line no-param-reassign
//       file.content_disposition = 'attachment';
//       return file;
//     });
//     setState((ps) => ({
//       ...ps,
//       attachments: [...ps.attachments, ...files],
//     }));
//   };

//   const handleDiscardButton = async () => {
//     isDraft.current = false;
//     setState((ps) => ({
//       ...ps,
//       emailStatus: '',
//       sendApiHitting: false,
//       isDraft: false,
//       deleteDraft: true,
//     }));
//     await composeEmailAction(
//       '',
//       {},
//       [],
//       'discard',
//       viewContext === 'draft' ? emailDetails : state,
//     );
//   };

//   const deleteEmailAttachment = async (attachment) => {
//     setState((ps) => ({
//       ...ps,
//       attachments: ps.attachments.filter((file) => file.id !== attachment.id),
//     }));
//     if (!attachment.fromlisciovault) {
//       try {
//         await Axios.delete(`${EMAIL_ATTACHMENTS}/${attachment.id}`).then(
//           (res) => res.data,
//         );
//       } catch (error) {
//         console.log(error);
//       }
//     }
//   };

//   // const isThisAFile = (maybeFile) => {
//   //   return new Promise(function (resolve, reject) {
//   //     if (maybeFile.type !== '') {
//   //       return resolve(maybeFile)
//   //     }
//   //     const reader = new FileReader()
//   //     reader.onloadend = () => {
//   //       if (reader.error && reader.error.name === 'NotFoundError') {
//   //         return reject(reader.error.name)
//   //       }
//   //       resolve(maybeFile)
//   //     }
//   //     reader.readAsBinaryString(maybeFile)
//   //   })
//   // }

//   // this for handling new attachments uploaded from system
//   // TODO: show error message when file size is more than 3MB
//   const handleEmailAttachments = async (input) => {
//     const files = [];
//     const batch = [];
//     const onUploadProgress = (uploadedFile) => (progress) => {
//       const percentage = Math.floor((progress.loaded * 100) / progress.total);
//       const key = `percentCompleted${uploadedFile}`;
//       setState((ps) => ({
//         ...ps,
//         [key]: percentage,
//       }));
//     };
//     Array.from(input).forEach((file) => {
//       const content_id = generateUniqueHash(25);
//       files.push({
//         file_name: file.name,
//         content_type: file.type,
//         content_disposition: 'attachment',
//         content_id,
//         file_size: file.size,
//         file_data: file,
//       });
//       const formData = new FormData();
//       formData.append('attachment', file);
//       formData.append('attachment_name', file.name);
//       formData.append('content_disposition', 'attachment');
//       formData.append('content_type', file.type);
//       formData.append('content_id', content_id);
//       const { CancelToken } = Axios;
//       const source = CancelToken.source();
//       const config = {
//         headers: { 'content-type': 'multipart/form-data' },
//         cancelToken: source.token,
//         onUploadProgress: onUploadProgress(file.name),
//       };
//       batch.push(Axios.post(EMAIL_ATTACHMENTS, formData, config));
//     });
//     const allattachments = [...state.attachments, ...files];
//     setState((ps) => ({
//       ...ps,
//       attachments: allattachments,
//       sendApiHitting: true,
//     }));
//     const response = await Promise.allSettled(batch).then((res) => res);
//     const attachments = [...state.attachments];
//     response.map((e) => {
//       allattachments.forEach((each) => {
//         const attachmentItem = each;
//         let attachment = {};
//         if (e.status !== 'rejected') {
//           const { file_name, id } = e.value.data.data;
//           if (
//             e.status === 'fulfilled' &&
//             file_name === attachmentItem.file_name &&
//             !attachmentItem.id &&
//             id !== null
//           ) {
//             attachment = e.value.data.data;
//           }
//           if (
//             attachment.id &&
//             (attachment.content_type.includes('text/csv') ||
//               attachment.content_type.includes('sheet')) &&
//             attachment.attachment_content_type.includes('text/plain')
//           ) {
//             attachments.push({
//               ...attachment,
//               file_data: attachmentItem.file_data,
//             });
//           } else if (attachment.id) {
//             attachments.push(attachment);
//           }
//         }
//         return attachment;
//       });
//       return null;
//     });
//     setState((ps) => ({
//       ...ps,
//       attachments,
//       sendApiHitting: false,
//     }));
//   };

//   // const inlineImageUploadHandler = async (file) => {
//   //   const content_id = generateUniqueHash(25);
//   //   const formData = new FormData();
//   //   formData.append('attachment', file.blob());
//   //   formData.append('attachment_name', file.filename());
//   //   formData.append('content_disposition', 'inline');
//   //   // formData.append('content_type', file.type());
//   //   formData.append('content_id', content_id);
//   //   const response = await Axios.post(EMAIL_ATTACHMENTS, formData).then(
//   //     (res) => res,
//   //   );
//   //   // success(response.data.url);
//   //   return response.data.url;
//   // };

//   const {
//     initialBody,
//     showCCInput,
//     showBCCInput,
//     toRecipients,
//     ccRecipients,
//     bccRecipients,
//     errors = {},
//     outlookToken,
//     subject,
//     gettingInlineImages,
//   } = state;
//   const isNew = window.location.pathname.includes('new');

//   if (gettingInlineImages) {
//     return null;
//   }

//   return (
//     <div data-testid="composeEmailfromThread">
//       <FormGroup
//         className={`${msgInrClass || ''} ${
//           errors.comment ? ' form-error' : ''
//         }`}
//       >
//         <div className={`labelValue ${!isNew ? 'replyInput' : ''}`}>
//           <div className="customTags mailDrodown">
//             <div className={`${!isNew ? 'form-control' : ''}`}>
//               <FormGroup
//                 className={`col position-relative form-group ${
//                   errors.toRecipients ? ' form-error' : ''
//                 }`}
//               >
//                 <Label className="d-flex justify-content-between">
//                   <span />
//                   <span className="text-primary text-capitalize d-flex align-items-center">
//                     <button
//                       type="button"
//                       className="btn btn-link pt-1 pb-0"
//                       onClick={() => toggleRecipientInputField('CC')}
//                     >
//                       cc
//                     </button>{' '}
//                     <span className="pt-1">|</span>
//                     <button
//                       type="button"
//                       className="btn btn-link pt-1 pb-0"
//                       onClick={() => toggleRecipientInputField('BCC')}
//                     >
//                       bcc
//                     </button>
//                   </span>
//                 </Label>
//               </FormGroup>
//               <FormGroup
//                 className={`${
//                   !isNew ? 'form-inline-label' : ''
//                 } col position-relative form-group ${
//                   errors.toRecipients ? ' form-error' : ''
//                 }`}
//               >
//                 <Label style={{ minWidth: '50px' }} htmlFor="emailTo">
//                   To
//                 </Label>
//                 <div className="labelValue boxResize">
//                   <EmailInputField
//                     placeholder="To"
//                     token={outlookToken}
//                     connectedAppKey={connectedAppKey}
//                     className="select-custom-class"
//                     name="toRecipients"
//                     id="emailTo"
//                     value={toRecipients}
//                     handleChange={handleChange('toRecipients')}
//                   />
//                   <span className="error_label">{errors.toRecipients}</span>
//                 </div>
//               </FormGroup>
//               {showCCInput ? (
//                 <FormGroup
//                   className={`${
//                     !isNew ? 'form-inline-label' : ''
//                   } col position-relative form-group ${
//                     errors.toRecipients ? ' form-error' : ''
//                   }`}
//                 >
//                   <Label style={{ minWidth: '50px' }} htmlFor="emailCC">
//                     CC
//                   </Label>
//                   <div className="labelValue boxResize">
//                     <EmailInputField
//                       placeholder="CC"
//                       token={outlookToken}
//                       connectedAppKey={connectedAppKey}
//                       className="select-custom-class"
//                       name="ccRecipients"
//                       id="emailCC"
//                       value={ccRecipients}
//                       handleChange={handleChange('ccRecipients')}
//                     />
//                     <span className="error_label">{errors.ccRecipients}</span>
//                   </div>
//                 </FormGroup>
//               ) : null}
//               {showBCCInput ? (
//                 <FormGroup
//                   className={`${
//                     !isNew ? 'form-inline-label' : ''
//                   } col position-relative form-group ${
//                     errors.toRecipients ? ' form-error' : ''
//                   }`}
//                 >
//                   <Label style={{ minWidth: '50px' }} htmlFor="emailBCC">
//                     BCC
//                   </Label>
//                   <div className="labelValue customEditor boxResize">
//                     <EmailInputField
//                       placeholder="BCC"
//                       token={outlookToken}
//                       connectedAppKey={connectedAppKey}
//                       className="select-custom-class"
//                       name="bccRecipients"
//                       id="emailBCC"
//                       value={bccRecipients}
//                       handleChange={handleChange('bccRecipients')}
//                     />
//                     <span className="error_label">{errors.bccRecipients}</span>
//                   </div>
//                 </FormGroup>
//               ) : null}
//             </div>
//             {isNew ? (
//               <FormGroup
//                 className={`col-12 position-relative form-group ${
//                   errors.subject ? ' form-error' : ''
//                 }`}
//               >
//                 <Label htmlFor="emailSubject">Subject</Label>
//                 <div className="labelValue boxResize">
//                   <Input
//                     placeholder="Subject"
//                     onChange={handleInputChange}
//                     value={subject}
//                     id="emailSubject"
//                     name="subject"
//                   />
//                   <span className="error_label">{errors.subject}</span>
//                 </div>
//               </FormGroup>
//             ) : null}
//           </div>
//         </div>
//       </FormGroup>
//       <FormGroup
//         className={`position-relative form-group ${isNew ? 'col-12' : ''}`}
//       >
//         {isNew ? <Label htmlFor="emailBody">Description</Label> : null}
//         <div className="labelValue customEditor boxResize">
//           <div
//             id={emailDetails && emailDetails.email_id}
//             className="customEditor boxResize"
//           >
//             <TinymceEditor
//               initialValue={initialBody}
//               imageUploadHandler={inlineImageUploadHandler}
//               handleChange={handleBodyChange}
//             />
//           </div>
//         </div>
//       </FormGroup>
//       <FormGroup
//         className={`position-relative form-group ${isNew ? 'col-12' : ''}`}
//       >
//         <Label htmlFor="attachments">Attachments</Label>
//         <TaskFileUpload
//           data={state}
//           uploadType="email"
//           type="email"
//           messageComp="threadDetail"
//           updateDocumentState={handleDocuments}
//           handleEmailAttachments={handleEmailAttachments}
//         />
//       </FormGroup>
//       {state.attachments
//         ? state.attachments.map((e) =>
//             e.content_disposition !== null &&
//             !e.content_disposition.includes('inline') ? (
//               <DisplayEmailAttachments
//                 attachment={e}
//                 emailId={state.emailId}
//                 deleteAttachment={deleteEmailAttachment}
//                 state={state}
//               />
//             ) : null,
//           )
//         : null}
//       <FormGroup
//         className={`d-flex justify-content-end btn-wrap btn--leftspace mt-4 ${
//           isNew ? 'd-flex mr-3' : ''
//         }`}
//       >
//         <FormGroup>
//           <Button color="primary" onClick={handleDiscardButton}>
//             Discard
//           </Button>
//           <Button
//             color="primary"
//             onClick={() => handleSubmit()}
//             disabled={state.sendApiHitting}
//           >
//             Send
//           </Button>
//         </FormGroup>
//       </FormGroup>
//     </div>
//   );
// };

// TODO: restore draft behavior in task detail -> email (keeping the memo() call uselessly so we don't forget to restore it)
// https://liscio.atlassian.net/browse/FD-1225
export default React.memo(() => <div className="ComposeEmailFromThread-to-be-restored" />);
