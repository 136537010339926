import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import ClearButton from 'common/ClearButton';
import {
  ACCOUNT_LABEL,
  DATE_RANGE,
  INVOICE_TYPE,
  OWNER,
  STATUS,
} from 'constants/constants';
import DateRangeFilter from './DateRangeFilter';
import InvoiceTypeFilter from './InvoiceTypeFilter';
import StatusTypeFilter from './StatusTypeFilter';
import AccountFilter from '../../../Filters/AccountFilter';
import EmployeeAndContactFilter from '../../../Filters/EmployeeAndContactFilter';

const StyledDiv = styled.div`
  display: flex;
  align-items: baseline;

  > label,
  .dropdown {
    margin-right: 10px;
  }

  .dropdown > button.btn {
    color: #919eab;
    font-weight: 600;
    font-size: 14px;
  }

  .show > button.dropdown-toggle {
    color: #fff;
    background-color: #00aecc;
    border-color: #00aecc;
    box-shadow: 5px 0 20px rgb(0 0 0 / 10%);
  }
`;

const BillingFilterButtons = (props) => {
  const {
    contactId,
    options = {},
    selected = [],
    onSelectFilter,
    onClearFilter,
    activeFiltersCount,
    filterBGClicked,
    isContactView,
  } = props;

  const [filterInFocus, setFilterInFocus] = useState('');

  let statusSelected = [];
  let invoiceSelected = '';
  let dateSelected = {};
  let accountSelected = {};
  let ownerSelected = {};

  if (selected.length > 0) {
    selected.forEach((selection) => {
      const { type, value } = selection;
      switch (type) {
        case STATUS:
          statusSelected = value;
          break;
        case INVOICE_TYPE:
          invoiceSelected = value;
          break;
        case DATE_RANGE:
          dateSelected = value;
          break;
        case ACCOUNT_LABEL:
          accountSelected = value;
          break;
        case OWNER:
          ownerSelected = value;
          break;
        default:
          break;
      }
    });
  }

  const onSelectAccount = (e, { label, value }) => {
    onSelectFilter({
      type: ACCOUNT_LABEL,
      value: { accountLabel: label, accountId: value },
    });
  };

  const onSelectOwner = (e, { label, value }) => {
    onSelectFilter({
      type: OWNER,
      value: { ownerLabel: label, ownerId: value },
    });
  };

  const clearAllFilters = () => onClearFilter(null);

  useEffect(() => {
    setFilterInFocus('');
  }, [filterBGClicked]);

  return (
    <StyledDiv className="BillingFilter__Buttons">
      <label htmlFor="filterByLabel">Filter By:</label>
      {options.status && (
        <StatusTypeFilter
          statusOptions={options.status}
          selected={statusSelected}
          onSelectFilter={onSelectFilter}
          onClearFilter={onClearFilter}
          setFilterInFocus={setFilterInFocus}
          showFilterOptions={filterInFocus === STATUS}
        />
      )}

      {options.account && (
        <AccountFilter
          contactId={contactId}
          filterSelected={onSelectAccount}
          filterName={ACCOUNT_LABEL}
          isActive={accountSelected && accountSelected.accountId}
          setFilterInFocus={setFilterInFocus}
          showFilterOptions={filterInFocus === ACCOUNT_LABEL}
        />
      )}

      {options.owner && !isContactView && (
        <EmployeeAndContactFilter
          filterSelected={onSelectOwner}
          type="Employee"
          filterName="OWNER"
          isActive={ownerSelected && ownerSelected.ownerId}
          setFilterInFocus={setFilterInFocus}
          showFilterOptions={filterInFocus === OWNER}
        />
      )}

      {options.invoiceTypes && (
        <InvoiceTypeFilter
          options={options.invoiceTypes}
          selected={invoiceSelected}
          onSelectFilter={onSelectFilter}
          setFilterInFocus={setFilterInFocus}
          showFilterOptions={filterInFocus === INVOICE_TYPE}
        />
      )}

      {options.dateRange && (
        <DateRangeFilter
          selected={dateSelected}
          onSelectFilter={onSelectFilter}
          options={options.dateRange}
        />
      )}

      <ClearButton
        onClickClear={clearAllFilters}
        hidden={activeFiltersCount <= 0}
        className="icon-medium"
      >
        Clear All
      </ClearButton>
    </StyledDiv>
  );
};

export default BillingFilterButtons;
