import { createColumnHelper } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { FORMATPHONENUMBER } from 'utilities/regularexpressions';

const columnHelper = createColumnHelper<SMSLine>();

export const smsTableColumns = [
  columnHelper.accessor('smsLine', {
    header: 'SMS LINE',
    cell(props) {
      return FORMATPHONENUMBER(props.getValue());
    },
  }),
  columnHelper.accessor('assignedTo', { header: 'ASSIGNED TO' }),
  columnHelper.accessor('lineType', { header: 'LINE TYPE' }),
  columnHelper.accessor('lastUpdate', {
    header: 'LAST UPDATE',
    cell(props) {
      return dayjs(props.getValue()).format('MMM D YYYY');
    },
  }),
  columnHelper.accessor('status', { header: 'STATUS' }),
];
