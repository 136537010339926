/* eslint-disable global-require */

import React from 'react';

export interface LoginPageLayoutProps {
  children?: React.ReactNode;
}

export const LoginPageLayout = ({ children }: LoginPageLayoutProps) => (
  <div className="crPage">
    <div className="crPage--inner">
      <div className="crPage--innerForm">
        <div className="crPage--innerForm--sec">
          <div className="crPage--head text-center">
            <img
              src={require('images/liscioLogo.png')}
              className="homeLogo"
              alt="Lisico"
              data-testid="home_logo"
            />
            {children}
          </div>
        </div>
        <div className="crPage--innerForm--footer">
          <div className="crPaeg--links">
            <a
              className="px-0"
              href="https://liscio.me/privacy-policy/"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
            <a
              className="px-0"
              href="https://liscio.me/contact/"
              target="_blank"
              rel="noreferrer"
            >
              Contact Us
            </a>
          </div>
          <div className="crPage--copyright">
            <p>&copy; {new Date().getFullYear()} Lisco. All rights reserved.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default LoginPageLayout;
