/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-script-url */
/* eslint-disable react/jsx-no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable prefer-destructuring */
/* eslint-disable consistent-return */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import axios from 'axios';
import _ from 'lodash';
import { Stack } from '@mui/material';

import {
  SEARCH_TASK_ACCOUNTS,
  SEARCH_TASK_CONTACTS,
} from 'constants/constants';

import DropDown from 'common/DropDown';
import MultiSelectDropDown from 'common/MultiSelectDropDown';

import { getFirmType, getStorageData, isUserEmployee } from 'utilities/utils';

const Button1 = [
  { name: 'Firm Uploads', id: 'firmuploads', text: 'firmuploads' },
  { name: 'Client Uploads', id: 'clientuploads', text: 'clientuploads' },
  { name: 'Firm Only File', id: 'privatefile', text: 'privatefile' },
];
const Button2 = [
  { key: 'message', value: 'message', label: 'Message' },
  { key: 'note', value: 'note', label: 'Note' },
  { key: 'task', value: 'task', label: 'Task' },
  { key: 'manual', value: 'manual', label: 'Manual' },
  { key: 'bill', value: 'bill', label: 'Bill' },
  { key: 'request', value: 'request', label: 'Request' },
];
const Button3 = [
  { key: 'message', value: 'message', label: 'Message' },
  { key: 'task', value: 'task', label: 'Task' },
  { key: 'manual', value: 'manual', label: 'Manual' },
  { key: 'bill', value: 'bill', label: 'Bill' },
];
const Button4 = [
  { key: 'message', value: 'message', label: 'Message' },
  { key: 'manual', value: 'manual', label: 'Manual' },
];
const filterStatusTab = [
  { name: 'Active', id: 'active', text: 'active' },
  { name: 'Archived', id: 'archived', text: 'archived' },
];
const filtersTabs = [
  {
    checkboxName: 'checkbox',
    checkboxArray: 'checkboxArray',
    filterArray: 'soucres',
    filterName: 'SOURCES',
  },
  {
    checkboxName: 'tagscheckbox',
    checkboxArray: 'tagscheckboxArray',
    filterArray: 'tags',
    filterName: 'TAGS',
  },
  {
    checkboxName: 'monthscheckbox',
    checkboxArray: 'monthscheckboxArray',
    filterArray: 'months',
    filterName: 'MONTH',
  },
  {
    checkboxName: 'yearscheckbox',
    checkboxArray: 'yearscheckboxArray',
    filterArray: 'years',
    filterName: 'YEAR',
  },
];
const filtersConAccTabs = [
  {
    selectedName: 'selectedAccountName',
    selectedId: 'selectedAccountId',
    selectedOption: 'selectedAccountOption',
    dropdown: 'accountDropdown',
    filterArray: 'accounts',
    filterName: 'ACCOUNT',
  },
  {
    selectedName: 'selectedContactName',
    selectedId: 'selectedContactId',
    selectedOption: 'selectedContactOption',
    dropdown: 'contactDropdown',
    filterArray: 'contacts',
    filterName: 'CONTACT',
  },
];
const joinItems = (array) => array.map((item) => item.key).join(',');
const joinArrayItems = (array, type) =>
  array
    .map(
      (curr, index) =>
        `${type}[]=${curr.key}${index < array.length - 1 ? '&' : ''}`,
    )
    .join('');

class UploadFilesFilterTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterFile: false,
      selectedFileUploadFilter: '',
      selectedFileUploadFilterId: '',
      selectedStatusFilter: '',
      selectedStatusFilterId: '',
      checkboxArray: [],
      selectedAccountId: '',
      selectedContactId: '',
      selectedContactName: '',
      selectedAccountName: '',
      checkbox: [{}],
      tagscheckboxArray: [],
      tagscheckbox: [{}],
      yearscheckboxArray: [],
      yearscheckbox: [{}],
      monthscheckboxArray: [],
      monthscheckbox: [{}],
      inputValue: '',
      filterlistdiv: false,
      clearAllTab: false,
      searchFilesText: '',
      filterInFocus: '',
      filterBGClicked: props.filterBGClicked,
    };
    this.typingTime = null;
  }

  static getDerivedStateFromProps(props, state) {
    if (props.filterBGClicked !== state.filterBGClicked) {
      return { filterBGClicked: props.filterBGClicked, filterInFocus: '' };
    }
    return null;
  }

  componentDidMount() {
    if (this.props.component?.props?.location?.state?.filterSelected) {
      this.setState({
        tagscheckbox: [{ client_upload: 'true' }],
        tagscheckboxArray: [
          {
            is_descending: true,
            key: 'client_upload',
            label: 'Client Upload',
            value: 'client_upload',
          },
        ],
      });
    }
    const { data } = this.props;
    if (data.filterState && !_.isEmpty(data.filterState)) {
      this.setState(data.filterState);
    } else {
      this.setState({
        selectedStatusFilter: data.filterStatus,
        selectedStatusFilterId: data.filterStatus,
        selectedStatusFilterOption: filterStatusTab.find(
          (value) => value.id === data.filterStatus,
        ),
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.component?.props?.location?.state?.filterSelected) {
      if (this.state.tagscheckboxArray !== prevState.tagscheckboxArray) {
        this.filterData();
      }
    }
  }

  clearAllFiltersTab() {
    const {
      selectedFileUploadFilterId,
      selectedStatusFilterId,
      checkboxArray,
      tagscheckboxArray,
      yearscheckboxArray,
      monthscheckboxArray,
      selectedContactId,
      selectedAccountId,
      searchFilesText,
    } = this.state;
    if (
      !selectedFileUploadFilterId ||
      !selectedStatusFilterId ||
      !checkboxArray.length ||
      !tagscheckboxArray.length ||
      !yearscheckboxArray.length ||
      !monthscheckboxArray.length ||
      !selectedContactId ||
      !selectedAccountId ||
      !searchFilesText
    ) {
      this.setState({ clearAllTab: false });
    }
  }

  allTasksDropdown(type, filter, filterId, filterOption) {
    if (
      type.id === 'firmuploads' ||
      type.id === 'clientuploads' ||
      type.id === 'privatefile'
    ) {
      this.setState({ filterFile: true });
    }
    if (filter === 'selectedStatusFilter') {
      this.props.component.hideSelectedFilesDeleteModal();
    }
    this.setState(
      {
        [filter]: type.text,
        [filterId]: type.id,
        [filterOption]: type,
        filterInFocus: '',
      },
      () => {
        this.filterData();
      },
    );
  }

  handleCheckbox(options, arr = '') {
    this.setState({ [arr]: options, filterInFocus: '' }, () => {
      this.filterData();
    });
  }

  delCheckbox(type, id, box = '', arr = '') {
    const checkboxState = this.state[box];
    checkboxState[0][type] = false;
    this.setState({ [box]: checkboxState });
    const options = this.state[arr];
    const indexx = options.map((item, index) => {
      if (item.key === type) {
        return index;
      }
      return null;
    });
    const ele = indexx.find((el) => el !== null);
    options.splice(ele, 1);
    this.setState({ [arr]: options }, () => {
      this.filterData();
    });
  }

  filterData() {
    this.clearAllFiltersTab();
    const {
      checkboxArray,
      tagscheckboxArray,
      yearscheckboxArray,
      monthscheckboxArray,
      selectedFileUploadFilterId,
      selectedStatusFilterId,
      selectedContactId,
      selectedAccountId,
      searchFilesText,
    } = this.state;
    const taskTypeArray = checkboxArray;
    const string = joinItems(taskTypeArray);
    const tagsTypeArray = tagscheckboxArray;
    const tags = tagsTypeArray
      .map((item) => encodeURIComponent(item.key))
      .join('|');
    const yearsTypeArray = yearscheckboxArray;
    const years = joinItems(yearsTypeArray);
    const j = joinArrayItems(yearsTypeArray, 'year');
    const monthsTypeArray = monthscheckboxArray;
    const months = joinItems(monthsTypeArray);
    const n = joinArrayItems(monthsTypeArray, 'month');
    if (
      selectedFileUploadFilterId ||
      selectedStatusFilterId !== 'active' ||
      taskTypeArray.length ||
      tagsTypeArray.length ||
      yearsTypeArray.length ||
      monthsTypeArray.length ||
      selectedContactId ||
      selectedAccountId ||
      searchFilesText
    ) {
      this.setState({ clearAllTab: true });
    }
    const apiPath = `${
      (selectedFileUploadFilterId
        ? `&file_uploaded_by=${selectedFileUploadFilterId}`
        : '') +
      (string ? `&source=${string}` : '') +
      (tags ? `&tags=${tags}` : '') +
      (years ? `&${j}` : '') +
      (months ? `&${n}` : '') +
      (selectedContactId ? `&contact_id=${selectedContactId}` : '') +
      (selectedAccountId ? `&account_id=${selectedAccountId}` : '')
    }${selectedStatusFilterId === 'archived' ? '&archive=true' : ''}${
      searchFilesText ? `&keyword=${searchFilesText}` : ''
    }`;
    // this.props.component.filterList(apiPath, selectedStatusFilterId, this.state);
    console.log('filterData', apiPath);
    if (apiPath) {
      this.setState({ filterlistdiv: true }, () => {
        this.props.component.filterList(
          apiPath,
          selectedStatusFilterId,
          this.state,
        );
      });
    } else {
      this.setState({ filterlistdiv: false }, () => {
        this.props.component.filterList(
          apiPath,
          selectedStatusFilterId,
          this.state,
        );
      });
    }
  }

  delSelected(selectedId, selectedName) {
    if (selectedId === 'selectedFileUploadFilterId') {
      this.setState({ filterFile: false });
    }
    this.setState({ [selectedId]: '', [selectedName]: '' }, () => {
      this.filterData();
    });
  }

  handleDropdown(item, selectedId, selectedName, selectedOption) {
    const { data } = this.props;
    const id =
      data.liteUpload && selectedId === 'selectedContactId'
        ? item.id
        : item.value;
    this.setState(
      {
        [selectedId]: id,
        [selectedName]: item.label,
        [selectedOption]: item,
        filterInFocus: '',
      },
      () => {
        this.filterData();
      },
    );
  }

  filterSearch = async (target, dropdownName) => {
    const component = this;
    const { data } = this.props;
    component.setState({ inputValue: target });

    if (data.liteUpload) {
      if (dropdownName === 'contactDropdown') {
        if (target.length < 2) {
          return this.props.component.filteredSearch(
            'contacts',
            '',
            dropdownName,
          );
        }
        const response_assignee = axios.get(
          `${SEARCH_TASK_CONTACTS}/?keyword=${target}`,
        );
        response_assignee.then((res) => {
          if (res.status === 200) {
            this.props.component.filteredSearch(
              'contacts',
              res.data,
              dropdownName,
            );
          }
        });
      } else if (dropdownName === 'accountDropdown') {
        if (target.length < 2) {
          return this.props.component.filteredSearch(
            'accounts',
            '',
            dropdownName,
          );
        }
        const response_account = await axios.get(
          `${SEARCH_TASK_ACCOUNTS}/?keyword=${target}&message=true`,
        );
        if (response_account.status === 200) {
          this.props.component.filteredSearch(
            'accounts',
            response_account.data,
            dropdownName,
          );
        }
      }
    } else {
      setTimeout(() => {
        const value = component.state.inputValue;
        const filter = value.toUpperCase();
        const div = document.getElementById(dropdownName);
        const a = div.getElementsByTagName('a');
        for (let i = 0; i < a.length; i += 1) {
          if (a[i].innerHTML.toUpperCase().indexOf(filter) > -1) {
            a[i].style.display = '';
          } else {
            a[i].style.display = 'none';
          }
        }
      }, 100);
    }
  };

  clearAll() {
    const { checkbox, tagscheckbox, yearscheckbox, monthscheckbox } =
      this.state;
    const checkboxState = checkbox;
    Object.keys(checkboxState[0]).map((item) => {
      checkboxState[0][item] = false;
      return checkboxState;
    });
    const tagscheckboxState = tagscheckbox;
    Object.keys(tagscheckboxState[0]).map((item) => {
      tagscheckboxState[0][item] = false;
      return tagscheckboxState;
    });
    const yearscheckboxState = yearscheckbox;
    Object.keys(yearscheckboxState[0]).map((item) => {
      yearscheckboxState[0][item] = false;
      return yearscheckboxState;
    });
    const monthscheckboxState = monthscheckbox;
    Object.keys(monthscheckboxState[0]).map((item) => {
      monthscheckboxState[0][item] = false;
      return monthscheckboxState;
    });
    this.setState(
      {
        filterFile: false,
        selectedFileUploadFilter: '',
        selectedFileUploadFilterId: '',
        selectedFileUploadFilterOption: undefined,
        selectedStatusFilter: 'active',
        selectedStatusFilterId: 'active',
        selectedStatusFilterOption: filterStatusTab.find(
          (value) => value.id === 'active',
        ),
        selectedAccountName: '',
        selectedAccountOption: undefined,
        selectedContactName: '',
        selectedContactOption: undefined,
        selectedAccountId: '',
        selectedContactId: '',
        checkboxArray: [],
        tagscheckboxArray: [],
        yearscheckboxArray: [],
        checkbox: checkboxState,
        tagscheckbox: tagscheckboxState,
        yearscheckbox: yearscheckboxState,
        clearAllTab: false,
        monthscheckboxArray: [],
        monthscheckbox: monthscheckboxState,
        searchFilesText: '',
      },
      () => {
        this.filterData();
      },
    );
  }

  filterTabs = () => {
    // TODO: __HOMEDATA-AUDIT
    const homeDataObj = getStorageData();
    const { data } = this.props;
    let soucres = [];
    const tags = data.tags.length > 0 ? data.tags : [];
    const years = data.years.length > 0 ? data.years : [];
    const months = data.months.length > 0 ? data.months : [];
    if (getFirmType() === 'liscio-lite') {
      soucres = Button4;
    } else if (
      data.clientUploadFile ||
      (data.accountUploadFile && !homeDataObj.is_employee)
    ) {
      soucres = Button3;
    } else {
      soucres = Button2;
    }
    const filters = filtersTabs.map((each) => {
      const obj = {
        soucres,
        tags,
        months,
        years,
      };
      const array = obj[each.filterArray];
      return (
        <MultiSelectDropDown
          title={each.filterName}
          options={array}
          value={this.state[each.checkboxArray]}
          onSelectionChange={(options) => {
            this.handleCheckbox(options, each.checkboxArray);
          }}
        />
      );
    });

    return (
      <Stack direction="row" alignItems="center" flexWrap="wrap" gap={2}>
        {filters}
      </Stack>
    );
  };

  filterConAccTabs = () => {
    const { data } = this.props;
    let contacts = [];
    let accounts = [];
    let list = [];
    if (data.contactUploadFile) {
      accounts = data.accounts;
      list.push(filtersConAccTabs[0]);
    }
    if (data.accountUploadFile) {
      contacts = data.contacts;
      list.push(filtersConAccTabs[1]);
    }
    if (data.liteUpload) {
      accounts = data.accounts;
      contacts = data.contacts;
      list = filtersConAccTabs;
    }
    // for showing accounts filter in client files page
    if (
      data.uploadFilesList &&
      (data.accountId === undefined || data.accountId === '') &&
      !isUserEmployee()
    ) {
      accounts = data.accounts;
      list.push(filtersConAccTabs[0]);
    }
    const filters = list.map((each) => {
      const obj = { accounts, contacts };
      const array = obj[each.filterArray];
      return list.length > 0 ? (
        <DropDown
          withSearch
          title={each.filterName}
          placeHolder="Loading ..."
          options={array}
          value={this.state[each.selectedOption]}
          onSearchKeyChange={(e) => {
            this.filterSearch(e.target.value, each.dropdown);
          }}
          onSelectionChange={(option) => {
            this.handleDropdown(
              option,
              each.selectedId,
              each.selectedName,
              each.selectedOption,
            );
          }}
        />
      ) : null;
    });
    // return (
    //   <AccountFilter
    //     isActive={this.state.selectedAccountName && this.state.selectedAccountName.length}
    //     filterSelected={(e, input) => this.handleDropdown(input, 'selectedAccountId', 'selectedAccountName')}
    //   />);
    return (
      <Stack direction="row" alignItems="center" flexWrap="wrap" gap={2}>
        {filters}
      </Stack>
    );
  };

  filtered = () => {
    const filtered = filtersTabs.map((each) =>
      this.state[each.checkboxArray].length
        ? this.state[each.checkboxArray].map((item, index) => (
            <div key={item.key} className="badge-filter">
              {item.label}
              <a href="javascript:void(0)">
                <i
                  tabIndex={0}
                  role="button"
                  className="icon-close2"
                  onMouseDown={() =>
                    this.delCheckbox(
                      item.key,
                      index,
                      each.checkboxName,
                      each.checkboxArray,
                    )
                  }
                />
              </a>
            </div>
          ))
        : null,
    );
    return filtered;
  };

  filteredAccCon = () => {
    const filtered = filtersConAccTabs.map((each) =>
      this.state[each.selectedName] ? (
        <div
          key={`${filtersConAccTabs.indexOf(each)}filtered`}
          className="badge-filter"
        >
          {this.state[each.selectedName]}
          <a href="javascript:void(0)">
            <i
              tabIndex={0}
              role="button"
              className="icon-close2"
              onMouseDown={() =>
                this.delSelected(each.selectedId, each.selectedName)
              }
            />
          </a>
        </div>
      ) : null,
    );
    return filtered;
  };

  handleFilesSearch = (input) => {
    const inputvalue =
      input && input.target && input.target.value ? input.target.value : '';
    this.setState({ searchFilesText: inputvalue });
    clearTimeout(this.typingTime);
    this.typingTime = setTimeout(() => {
      this.filterData();
    }, 500);
    // if (inputvalue.length < 3 && searchFilesText.length === 3) {
    //   this.filterData();
    // }
  };

  render() {
    const {
      selectedFileUploadFilter,
      selectedStatusFilter,
      clearAllTab,
      filterlistdiv,
      searchFilesText,
    } = this.state;
    const { accountData, contactData, liscioVault } = this.props;

    return (
      <div className="mb-3">
        <div className="filter-wrapper d-flex justify-content-between mb-2">
          <div className="filterPart d-flex">
            <div className="filterGroup mt-0">
              <div className="form-group has-search position-relative">
                <span className="icon-zoom2 form-control-feedback" />
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search files"
                  id="files-search"
                  onChange={this.handleFilesSearch}
                  // onKeyUp={this.handleFilesSearchRequest}
                  value={searchFilesText}
                  autoComplete="off"
                  data-testid="files__search_input"
                />
              </div>
            </div>
          </div>
          {(!contactData || (contactData && contactData.is_active)) &&
            (!accountData ||
              (accountData &&
                accountData.account &&
                accountData.account.status !== 'Inactive')) &&
            !liscioVault && (
              <div className="FilterHeader--Action">
                <a
                  href="javascript:;"
                  className="btn btn-primary"
                  onClick={this.props.uploadFile}
                  data-testid="files__upload_file_btn"
                >
                  <i>+</i> Upload File
                </a>
              </div>
            )}
        </div>
        <div className="filter-wrapper d-flex justify-content-between">
          <div className="w-100 d-flex">
            <div className="filterGroup">
              <div className="d-flex flex-wrap bd-highlight">
                <label htmlFor="filter" className="mt-2 mr-1 m-0">
                  Filter by
                </label>
                <Stack
                  direction="row"
                  alignItems="center"
                  flexWrap="wrap"
                  gap={2}
                >
                  <DropDown
                    withSearch={false}
                    title="ALL FILES"
                    placeHolder="Loading ..."
                    options={Button1}
                    data-testid={`files__${this.state.selectedFileUploadFilterOption}`}
                    value={this.state.selectedFileUploadFilterOption}
                    onSelectionChange={(option) => {
                      this.allTasksDropdown(
                        option,
                        'selectedFileUploadFilter',
                        'selectedFileUploadFilterId',
                        'selectedFileUploadFilterOption',
                      );
                    }}
                  />
                  {this.filterTabs()}
                  {this.filterConAccTabs()}
                  <DropDown
                    withSearch={false}
                    title={selectedStatusFilter}
                    placeHolder="Loading ..."
                    options={filterStatusTab}
                    value={this.state.selectedStatusFilterOption}
                    onSelectionChange={(option) => {
                      this.allTasksDropdown(
                        option,
                        'selectedStatusFilter',
                        'selectedStatusFilterId',
                        'selectedStatusFilterOption',
                      );
                    }}
                  />
                </Stack>
                {clearAllTab || filterlistdiv ? (
                  <div className="align-self-center ml-2 clearFilter">
                    {' '}
                    <a
                      href="javascript:void(0)"
                      onClick={() => this.clearAll()}
                    >
                      <i className="icon-close2 label-small" />
                      Clear All
                    </a>
                  </div>
                ) : (
                  ''
                )}
              </div>
              {filterlistdiv ? (
                <div className="select-filter-list w-100">
                  {selectedFileUploadFilter ? (
                    <div className="badge-filter">
                      {selectedFileUploadFilter}
                      <a href="javascript:void(0)">
                        <i
                          tabIndex={0}
                          role="button"
                          onMouseDown={() =>
                            this.delSelected(
                              'selectedFileUploadFilterId',
                              'selectedFileUploadFilter',
                            )
                          }
                          className="icon-close2"
                        />
                      </a>
                    </div>
                  ) : (
                    ''
                  )}
                  {this.filtered()}
                  {this.filteredAccCon()}
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default UploadFilesFilterTabs;
