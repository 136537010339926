import React, { Component } from 'react';
import { Modal, ModalBody, Button } from 'reactstrap';
import Axios from 'axios';
import { doesTagExist, AlertMessage } from 'utilities/utils';
import { CPA_TAGS } from 'constants/constants';

export default class AddTagModal extends Component {
  constructor(props) {
    super(props);

    this.state = { inputvalue: '' };
  }

  componentDidMount() {
    if (document.getElementById('new_tag_input')) {
      document.getElementById('new_tag_input').focus();
    }
  }

  handleInput = (input) => {
    this.setState({ inputvalue: input.target.value });
  };

  saveTag = () => {
    const { inputvalue } = this.state;
    const { existingTags } = this.props;
    const tagName = inputvalue.trim();
    if (tagName === undefined || tagName === null || !tagName.length) {
      this.setState({ errormessage: 'Enter valid tag name' });
    } else if (doesTagExist(existingTags, tagName)) {
      this.setState({ errormessage: 'Tag name already exists' });
    } else {
      Axios.post(CPA_TAGS, {
        key: tagName,
        value: tagName,
      }).then((res) => {
        if (res.data) {
          const { status, message, data } = res.data;
          if (status === 200) {
            data.no_of_files = 0;
            AlertMessage('success', message, 3000);
            this.props.updateData(data);
            this.props.noBtnClick();
          } else {
            if (document.getElementById('new_tag_input')) {
              document.getElementById('new_tag_input').focus();
            }
            AlertMessage('error', message, 3000);
          }
        }
      });
    }
  };

  handleKeyboardEvents = (e) => {
    if (e) {
      if (e.key === 'Escape') {
        this.props.noBtnClick();
      } else if (e.key === 'Enter') {
        this.saveTag();
      }
    }
  };

  render() {
    const { isOpen, className, headerText, noBtnText, noBtnClick } = this.props;
    const { inputvalue, errormessage } = this.state;
    return (
      <Modal
        isOpen={isOpen}
        className={
          className ||
          'customModal customModal--center customModal--alert customModal--w500 customModal--nopadding'
        }
      >
        <div className="ModalHeader">
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={noBtnClick}
          >
            <i className="icon-close2" aria-hidden="true" />
          </button>
        </div>
        <ModalBody className="modalContent">
          <div className="modalContent--inner">
            {headerText ? (
              <div className="modalContent--header text-large">
                <h5 className="modalContent--header__heading mb-3">
                  {headerText}
                </h5>
              </div>
            ) : null}
            <div
              className={
                errormessage ? 'form-group mb-0 form-error' : 'form-group mb-0'
              }
            >
              <input
                id="new_tag_input"
                type="text"
                value={inputvalue}
                onChange={this.handleInput}
                // onBlur={() => this.toggleInput()}
                onKeyDown={this.handleKeyboardEvents}
                autoComplete="off"
                spellCheck="off"
                className="form-control"
              />
              {errormessage ? (
                <span className="error_label">{errormessage}</span>
              ) : null}
            </div>
            <div className="row">
              <div className="col-12 justify-content-end formbtn justify-content-end">
                <div className="btn-wrap btn--leftspace">
                  {noBtnText ? (
                    <Button
                      className="btn btn-outline-light"
                      onClick={noBtnClick}
                    >
                      {noBtnText}
                    </Button>
                  ) : null}
                  <Button className="btn btn-primary" onClick={this.saveTag}>
                    Save
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}
