import { Box, Button, Stack, Typography } from '@mui/material';
import { ERROR_CODES } from 'constants/error-codes';
import { errorResponse } from 'hooks/useGraphQL';
import { useNavigate } from 'react-router-dom-latest';

export const ErrorMessage = ({ error }: { error: errorResponse }) => {
  const navigate = useNavigate();

  const parseError = (err: errorResponse) => {
    const { code } = err;

    switch (code) {
      case ERROR_CODES.WORKFLOW_REQUESTS.REQUEST_RECALLED: {
        return {
          message: (
            <>
              <Typography variant="h6" mb={2}>
                The request you attempted to access has been deleted and
                requires no further action.
              </Typography>
              <Typography variant="h6" mb={2}>
                Consider deleting the e-mail you used to access this request to
                avoid potential confusion in the future.
              </Typography>
              <Typography variant="h6" mb={2}>
                Click the button below to return to your homepage and review any
                other outstanding tasks you may have to complete
              </Typography>
            </>
          ),
          navigateTo: '/',
          buttonText: 'RETURN HOME',
        };
      }
      case ERROR_CODES.WORKFLOW_REQUESTS.REQUEST_NOT_FOUND: {
        return {
          message: (
            <Typography variant="h6" mb={2}>
              Request not found
            </Typography>
          ),
          navigateTo: '/requests',
          buttonText: 'RETURN TO REQUESTS',
        };
      }
      case ERROR_CODES.WORKFLOW_REQUESTS.UNAUTHORIZED: {
        return {
          message: (
            <Typography variant="h6" mb={2}>
              You are attempting to access a Request for a Restricted Account to
              which you do not have access. If you believe this is in error,
              please contact your Liscio Administrator and request that they add
              you to this Account’s Client Service Team.
            </Typography>
          ),
          navigateTo: '/requests',
          buttonText: 'RETURN HOME',
        };
      }
      default: {
        return {
          message: (
            <Typography variant="h6" mb={2}>
              Sorry, there was an error processing your request. Please try
              again.
            </Typography>
          ),
          navigateTo: '/',
          buttonText: 'RETURN HOME',
        };
      }
    }
  };

  const handleButtonClick = (path: string) => {
    navigate(path);
  };

  const { message, navigateTo, buttonText } = parseError(error);

  return (
    <Box
      p={4}
      sx={{
        border: '1px solid #a4a4a4',
        maxWidth: '500px',
        borderRadius: '3px',
      }}
    >
      {message}
      <Stack direction="row" alignItems="center" justifyContent="center">
        <Button
          type="button"
          variant="contained"
          onClick={() => handleButtonClick(navigateTo)}
        >
          {buttonText}
        </Button>
      </Stack>
    </Box>
  );
};
