/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { Modal, ModalBody } from 'reactstrap';
import * as Utilities from 'utilities/utils';
import TooltipItem from 'common/TooltipItem';
import {
  SEARCH_IN_LISCIO,
  CONTACT_SEARCH_IN_LISCIO,
  SEARCH_IN_KARBON,
  CONTACT_SEARCH_IN_KARBON,
  LINK_ACCOUNT,
  LINK_CONTACT,
} from 'constants/constants';

const SearchedAccountHeaders = [
  {
    value: 'account_name',
    label: 'Account Name',
    className: ' col-3 thCol',
    // isSortable: true,
  },
  {
    value: 'address',
    label: 'Address',
    className: ' col-4 thCol',
  },
  {
    value: 'website',
    label: 'Website',
    className: ' col-2 thCol',
  },
  {
    value: 'phone_number',
    label: 'Phone Number',
    className: ' col-2 thCol',
  },
];

const SearchedContactHeaders = [
  {
    value: 'first_name',
    label: 'First Name',
    className: ' col thCol',
    // isSortable: true,
  },
  {
    value: 'last_name',
    label: 'Last Name',
    className: ' col thCol',
    // isSortable: true,
  },
  {
    value: 'primary_email',
    label: 'Primary Email',
    className: ' col thCol',
  },
  {
    value: 'job_title',
    label: 'Job Title',
    className: ' col thCol',
  },
];

const initialData = {
  loading: false,
  // sortBy: 'account_name',
  // sortOrder: 'asc',
  accountListData: [],
  contactListData: [],
};
let typingTime = null;

export const SearchLiscioKarbonRefactor = (props) => {
  const [data, setData] = useState(initialData);
  const [searchField, setSearchField] = useState('');

  const { data: propData } = props;
  const headers =
    propData.activeTab === 0 ? SearchedAccountHeaders : SearchedContactHeaders;
  const list = propData.activeTab === 0 ? 'accountListData' : 'contactListData';
  // const responselist = propData.searchedIn === 'Karbon' ? 'karbon_accounts' : 'liscio_accounts';
  const responselist =
    propData.activeTab === 0
      ? propData.searchedIn === 'Karbon'
        ? 'karbon_accounts'
        : 'liscio_accounts'
      : propData.searchedIn === 'Karbon'
      ? 'karbon_contacts'
      : 'liscio_contacts';
  const acountName =
    propData.searchedIn === 'Karbon'
      ? 'karbon_org_name'
      : 'liscio_account_name';
  const webSite =
    propData.searchedIn === 'Karbon'
      ? 'karbon_account_website'
      : 'liscio_account_website';
  const phoneNumber =
    propData.searchedIn === 'Karbon'
      ? 'karbon_account_phone_number'
      : 'liscio_account_phone_number';
  const firstName =
    propData.searchedIn === 'Karbon'
      ? 'karbon_contact_first_name'
      : 'liscio_contact_first_name';
  const lastName =
    propData.searchedIn === 'Karbon'
      ? 'karbon_contact_last_name'
      : 'liscio_contact_last_name';
  const email =
    propData.searchedIn === 'Karbon'
      ? 'karbon_contact_email'
      : 'liscio_contact_primary_email';
  const title =
    propData.searchedIn === 'Karbon'
      ? 'karbon_contact_title'
      : 'liscio_contact_title';

  const searchList = async (
    api,
    nameField = '',
    search = '',
    firstTime = '',
  ) => {
    const keyword = firstTime
      ? propData.searchedFor && nameField && propData.searchedFor[nameField]
        ? propData.searchedFor[nameField]
        : ''
      : search;
    if (firstTime) {
      setSearchField(keyword);
    }
    try {
      const response = await Axios.get(
        api + (keyword ? `?keyword=${keyword}` : ''),
      ).then((res) => res.data);
      setData({
        ...data,
        loading: false,
        [list]: response[responselist],
      });
    } catch (err) {
      // setData({ ...data, 'loading': false });
      console.log(err);
    }
  };

  const handleSearchList = (search = '', firstTime = '') => {
    if (
      propData.searchedIn &&
      propData.searchedIn !== null &&
      propData.searchedIn !== undefined &&
      propData.searchedIn === 'Liscio' &&
      propData.activeTab === 0
    ) {
      searchList(SEARCH_IN_LISCIO, 'karbon_org_name', search, firstTime);
    } else if (
      propData.searchedIn &&
      propData.searchedIn !== null &&
      propData.searchedIn !== undefined &&
      propData.searchedIn === 'Karbon' &&
      propData.activeTab === 0
    ) {
      searchList(SEARCH_IN_KARBON, 'liscio_account_name', search, firstTime);
    } else if (
      propData.searchedIn &&
      propData.searchedIn !== null &&
      propData.searchedIn !== undefined &&
      propData.searchedIn === 'Liscio' &&
      propData.activeTab === 1
    ) {
      searchList(
        CONTACT_SEARCH_IN_LISCIO,
        'karbon_contact_first_name',
        search,
        firstTime,
      );
    } else if (
      propData.searchedIn &&
      propData.searchedIn !== null &&
      propData.searchedIn !== undefined &&
      propData.searchedIn === 'Karbon' &&
      propData.activeTab === 1
    ) {
      searchList(
        CONTACT_SEARCH_IN_KARBON,
        'liscio_contact_first_name',
        search,
        firstTime,
      );
    }
  };

  useEffect(() => {
    handleSearchList(searchField, 'firstTime');
  }, []);

  const handleSearch = (e) => {
    const input = e.target.value;
    setSearchField(input);
    clearTimeout(typingTime);
    typingTime = setTimeout(() => {
      handleSearchList(input);
    }, 500);
    // if (input.length > 2) {
    //   setSearchField(input);
    //   clearTimeout(typingTime);
    //   typingTime = setTimeout(() => {
    //     handleSearchList(input);
    //   }, 500);
    // } else {
    //   setSearchField(input);
    // }
  };

  const handleSortBy = (sortBy) => {
    let { sortOrder } = data;
    if (data.sortBy === sortBy) {
      if (sortOrder === 'asc') {
        sortOrder = 'desc';
      } else {
        sortOrder = 'asc';
      }
    } else {
      sortOrder = 'asc';
    }
    setData({ ...data, sortOrder, sortBy });
  };

  const handleLink = (item, api = '') => {
    if (propData.searchedFor && propData.searchedFor.id && item.id) {
      setData({ ...data, loading: true });
      try {
        const firstId =
          propData.activeTab === 0 ? 'first_account_id' : 'first_contact_id';
        const secondId =
          propData.activeTab === 0 ? 'second_account_id' : 'second_contact_id';
        const url = `${api}?${firstId}=${propData.searchedFor.id}&${secondId}=${item.id}`;
        const request = Axios.post(url);
        request.then((response) => {
          if (response.data.status === 200) {
            Utilities.AlertMessage('success', response.data.message, 3000);
            props.showSearchModal(false, {}, '');
            // handleSearchList(data.searchField);
          } else {
            setData({ ...data, loading: false });
          }
        });
      } catch (err) {
        setData({ ...data, loading: false });
        // console.log(err);
      }
    }
  };

  return (
    <div id="content">
      {data.loading ? <div id="loading" /> : null}
      <Modal
        isOpen
        className="customModal customModal--alert customModal--w990 customModal--nopadding"
      >
        <div className="ModalHeader">
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={() => props.showSearchModal(false, {}, '')}
          >
            <i className="icon-close2" aria-hidden="true" />
          </button>
        </div>
        <ModalBody className="modalContent">
          <div className="p-4 border-bottom">
            <h5 className="mb-4">Search in {propData.searchedIn}</h5>
            <div className="form-group has-search">
              <span className="icon-zoom2 form-control-feedback" />
              <input
                type="text"
                className="form-control"
                placeholder="Search"
                id={propData.searchedIn}
                value={searchField}
                onChange={handleSearch}
                autoComplete="off"
              />
            </div>
          </div>
          <div className="center-wrapper">
            <div className="tableWrap">
              <div className="tRow tRow--head">
                <div className="row">
                  {headers.map((each) => (
                    <div
                      key={each.value}
                      className={
                        Utilities.sortingIcon(
                          data.sortBy,
                          each.value,
                          data.sortOrder,
                          'className',
                        ) +
                        each.className +
                        (!each.isSortable ? ' cursor-default' : '')
                      }
                      onClick={() =>
                        each.isSortable ? handleSortBy(each.value) : null
                      }
                    >
                      <span className="posRel">
                        {each.label}
                        <i
                          className={Utilities.sortingIcon(
                            data.sortBy,
                            each.value,
                            data.sortOrder,
                          )}
                        />
                      </span>
                    </div>
                  ))}
                  <div className="col thCol" />
                </div>
              </div>
              <div>
                <Scrollbars
                  style={{ width: '100%' }}
                  autoHide
                  autoHeight
                  autoHeightMin={0}
                  autoHeightMax={400}
                  autoHideTimeout={1000}
                >
                  <div className="tRow tRow--body">
                    {propData.activeTab === 0 && data.accountListData.length ? (
                      data.accountListData.map((item) => (
                        <div
                          className="tdBtn row"
                          key={data.accountListData.indexOf(item)}
                        >
                          <div className=" col-3 tdCol">
                            <span>
                              {item[acountName] ? item[acountName] : '-'}
                            </span>
                          </div>
                          <div className=" col-4 tdCol">
                            <span>
                              {props.getAccountFullAddress(
                                item,
                                propData.searchedIn,
                              )}
                            </span>
                          </div>
                          <div className=" col-2 tdCol">
                            <span>{item[webSite] ? item[webSite] : '-'}</span>
                          </div>
                          <div className=" col-2 tdCol">
                            <span>
                              {item[phoneNumber] ? item[phoneNumber] : '-'}
                            </span>
                          </div>
                          <div className="col tdCol text-right">
                            <span>
                              <TooltipItem
                                position="top"
                                tooltipType="button"
                                key={`link${data.accountListData.indexOf(
                                  item,
                                )}`}
                                text="Link"
                                btnClass="btn-sm btn--onlyicon btn-link tdBtn__hover px-1"
                                id={`link${data.accountListData.indexOf(item)}`}
                                clickAction={() =>
                                  handleLink(item, LINK_ACCOUNT)
                                }
                                iconn="icon-syncing"
                              />
                            </span>
                          </div>
                        </div>
                      ))
                    ) : propData.activeTab === 1 &&
                      data.contactListData.length ? (
                      data.contactListData.map((item) => (
                        <div
                          className="tdBtn row"
                          key={data.contactListData.indexOf(item)}
                        >
                          <div className=" col tdCol">
                            <span>
                              {item[firstName] ? item[firstName] : '-'}
                            </span>
                          </div>
                          <div className=" col tdCol">
                            <span>{item[lastName] ? item[lastName] : '-'}</span>
                          </div>
                          <div className=" col tdCol">
                            <span>{item[email] ? item[email] : '-'}</span>
                          </div>
                          <div className=" col tdCol">
                            <span>{item[title] ? item[title] : '-'}</span>
                          </div>
                          <div className="col tdCol text-right">
                            <span>
                              <TooltipItem
                                position="top"
                                tooltipType="button"
                                key={`link${data.contactListData.indexOf(
                                  item,
                                )}`}
                                text="Link"
                                btnClass="btn-sm btn--onlyicon btn-link tdBtn__hover px-1"
                                id={`link${data.contactListData.indexOf(item)}`}
                                clickAction={() =>
                                  handleLink(item, LINK_CONTACT)
                                }
                                iconn="icon-syncing"
                              />
                            </span>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="row emptyTr">
                        <div className="emptyBox">
                          <div className="onlyText">
                            <p>No Records Found</p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </Scrollbars>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};
