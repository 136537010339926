export interface MobileRedirectConfig {
  // If redirect present, redirect to this string
  redirect?: string;
  // If true will bypass mobile check
  bypassMobileCheck?: boolean;
}

/**
 * Path match map configuration
 */
export const pathMap: { [path: string]: MobileRedirectConfig } = {
  '/i/*': {},
  '/invite/*': {},

  '/magicLink/:id': { redirect: '/authenticate/ml/:id' },
  '/ml/:id': { redirect: '/authenticate/ml/:id' },

  // Covers cases below
  '/lisciopro/*': {},
  // '/lisciopro/:page/:id': {},
  // '/lisciopro/:page': {},
  // '/lisciopro': {},

  // Covers cases below
  '/firmdash/*': {},
  // '/firmdash/:page/:id': {},
  // '/firmdash/:page': {},
  // '/firmdash': {},

  '/remotelogin/:id': {}, // What is this route for???
  '/404': {},

  '/login': { redirect: '/authenticate/login' },

  // 3p integrations
  '/adobe': {},
  '/zoom': {},
  '/google': {},
  '/outlook': {},
  '/onedrive': {},
  '/calendly': {},
  '/qb': {},
  '/payment': {},

  '/switchcpa': {}, // What is this route for?
  '/maintanance': {},
  '/glitch': {},

  '/cp/:id': { redirect: '/authenticate/cp/:id' },
  '/opendispatch': {},

  '/': { redirect: '/' },
  '/dashboard': { redirect: '/dashboard/home' },

  '/all_messages': { redirect: '/messages/list' },
  '/inbox/:id': { redirect: '/messages/details/:id' },
  '/alert/:id': { redirect: '/messages/details/:id' },
  '/sent/:id': { redirect: '/messages/details/:id' },
  '/draft/:id': { redirect: '/messages/details/:id' },
  '/archive/:id': { redirect: '/messages/details/:id' },
  '/pinned/:id': { redirect: '/messages/details/:id' },
  '/all_messages/:id': { redirect: '/messages/details/:id' },

  '/fileupload/new': { redirect: '/files/new' },
  '/message/new': { redirect: '/messages/new' },

  // Notes? Not currently in mobile
  '/note/new': {},
  '/notedetails/:id': {},
  '/note/edit/:id': {},

  '/accounts': { redirect: '/accounts/list' },
  '/accounts_beta': { redirect: '/accounts/list' },
  '/accountdetails/message/:id': { redirect: '/accounts/list' },
  '/account/new': { redirect: '/accounts/list' },
  '/account/edit/:id': { redirect: '/accounts/details/:id/overview' },
  '/accountdetails/:id': { redirect: '/accounts/details/:id/overview' },
  '/accountdetails/:id/details': { redirect: '/accounts/details/:id/overview' },
  '/accountdetails/:id/relationships': {
    redirect: '/accounts/details/:id/overview',
  },
  '/accountdetails/:id/tasks': { redirect: '/accounts/details/:id/overview' },
  '/accountdetails/:id/messages': {
    redirect: '/accounts/details/:id/overview',
  },
  '/accountdetails/:id/notes': { redirect: '/accounts/details/:id/overview' },
  '/accountdetails/:id/payroll': { redirect: '/accounts/details/:id/overview' },
  '/accountdetails/:id/activity': {
    redirect: '/accounts/details/:id/overview',
  },
  '/accountdetails/:id/logins': { redirect: '/accounts/details/:id/overview' },
  '/accountdetails/:id/emails': { redirect: '/accounts/details/:id/overview' },
  '/accountdetails/:id/files': { redirect: '/accounts/details/:id/files' },
  '/accountdetails/:id/billing/invoices': {
    redirect: '/accounts/details/:id/overview',
  },
  '/accountdetails/:id/inFocus': { redirect: '/accounts/details/:id/overview' },
  '/accountdetails/:id/billing/recurring': {
    redirect: '/accounts/details/:id/overview',
  },
  '/accountdetails/:id/emails/:email_id': { redirect: '/accounts/details/:id' },

  '/contact/*': { redirect: '/contacts/list' },
  '/contact/new': { redirect: '/contacts/list' },
  '/contacts': { redirect: '/contacts/list' },
  '/contact/edit/:id': { redirect: '/contacts/details/:id/overview' },
  '/contactdetails/:id': { redirect: '/contacts/details/:id/overview' },
  '/contactdetails/message/:id': { redirect: '/contacts/details/:id/messages' },
  '/contactdetails/:id/overview': {
    redirect: '/contacts/details/:id/overview',
  },
  '/contactdetails/:id/relationships': {
    redirect: '/contacts/details/:id/overview',
  },
  '/contactdetails/:id/tasks': { redirect: '/contacts/details/:id/tasks' },
  '/contactdetails/:id/messages': {
    redirect: '/contacts/details/:id/messages',
  },
  '/contactdetails/:id/notes': { redirect: '/contacts/details/:id/overview' },
  '/contactdetails/:id/activity': {
    redirect: '/contacts/details/:id/overview',
  },
  '/contactdetails/:id/files': { redirect: '/contacts/details/:id/files' },
  '/contactdetails/:id/emails': { redirect: '/contacts/details/:id/overview' },
  '/contactdetails/:id/billing': { redirect: '/contacts/details/:id/overview' },
  '/contactdetails/:id/emails/:email_id': {
    redirect: '/contacts/details/:id/overview',
  },

  // Employee bulk pages not available in mobile, yet
  '/bulk/*': {},
  // '/bulkdownload': {},
  // '/bulkesign': {},
  // '/bulksignature': {},
  // '/bulkmessage': {},
  // '/bulkinvite': {},
  // '/bulkreassign': { },

  '/notifications': {
    redirect: '/notifications/list',
    bypassMobileCheck: true,
  },
  '/profile_settings': { redirect: '/profile/details' },
  '/profile_settings/devicedetail/:id': {},

  '/fileupload/:id': { redirect: '/files/new' },
  '/files': { redirect: '/files/list' },
  '/files/new': { redirect: '/files/new' },
  // Not currently implemented in mobile site
  '/document': {},
  '/esign_list': {},
  '/admin': {},
  '/admin-billing': { redirect: '/admin/billing', bypassMobileCheck: true },
  '/preferences': {},
  '/integrations': {},
  '/payouts': {},
  '/payout/:id': {},
  '/templates': {},
  '/email/new': {},
  '/emails': {},
  '/emails/:id': {},
  '/sms/*': {},
  '/sms-settings-form': {},

  all_tasks: { redirect: '/tasks/list' },
  pending_review: { redirect: '/tasks/list' },
  archived: { redirect: '/tasks/list' },
  draft: { redirect: '/tasks/list' },
  '/all_tasks': { redirect: '/tasks/list' },
  '/tasks/:id': { redirect: '/tasks/details/:id' },
  // Employee tasks not implemented, yet
  '/task/new': {},
  '/task/edit/:id': {},
  '/task/detail/:id': { redirect: '/tasks/details/:id' },
  '/accountdetails/task/:id': {},
  '/contactdetails/task/:id': {},
  '/requests': {},
  '/requests/:id': { redirect: '/requests/:id' },

  '/message/new/modal': { redirect: '/messages/new' },
  '/billing/invoices': { redirect: '/invoices/list' },
  '/billing/recurring': { redirect: '/invoices/list' },
  '/billing/invoices/:id': { redirect: '/invoices/details/:id' },
  '/billing/recurring/:id': { redirect: '/invoices/details/:id' },

  '/userdetails/:id': {},
  '/user/new': {},
  '/user/edit/:id': {},
  '/employee/:id/accounts': {},
  '/templates/:id': {},
  '/liscio_qb_accounts': {},
  '/esign_templates': {},
  '/esign_templates/new': {},

  '/new_esign': {},
  '/esign/edit/:id': {},
  '/invite_contact': {},
  '/admin/templates/organizer': {
    redirect: '/admin/templates/organizer',
    bypassMobileCheck: true,
  },
};
export const pathMapRoutes = Object.keys(pathMap).map((path) => ({ path }));

/**
 * Defer rendering of these paths to prevent OTP consumption prior to redirect
 */
export const deferredPaths = ['/magicLink/:id', '/ml/:id'];
