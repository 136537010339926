import { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import Axios from 'axios';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom-latest';
import {
  ACCOUNT,
  GET_TEAM_MEMBERS_OF_ACCCOUNT,
  GET_NOTES_OF_ACCOUNT,
} from 'constants/constants';
import { saveAccountCardData } from 'actions/AccountCardActions';
import {
  generateFullName,
  getFirmType,
  FORMATPHONENUMBER,
} from 'utilities/utils';
import RatingComponent from './RatingComponent';
import './ContactCard.scss';

class AccountCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      account: {},
      id: null,
    };
  }

  componentDidMount() {
    const { id } = this.props;
    if (id && id !== null) {
      this.getRecords();
    }
  }

  getRecords = () => {
    const { allAccountsCardData, id } = this.props;
    if (
      allAccountsCardData[id] &&
      !this.props.page &&
      this.props.page !== 'threaddetail'
    ) {
      this.setState({
        account: allAccountsCardData[id],
        id,
      });
    } else {
      Axios.get(`${ACCOUNT}/${id}`).then((res) => {
        if (res.status === 200 && res.data.status === 200 && res.data.data) {
          let data = res.data.data.account;
          this.setState({
            account: res.data.data.account,
            id,
          });
          Axios.get(`${GET_TEAM_MEMBERS_OF_ACCCOUNT}/${id}`).then(
            (response) => {
              if (
                response.status === 200 &&
                response.data.message === 'success'
              ) {
                data = { ...data, teamemployees: response.data.data };
                this.setState({ account: data });
              } else {
                data = { ...data, teamemployees: [] };
                this.setState({ account: data });
              }
              Axios.get(`${GET_NOTES_OF_ACCOUNT}/${id}`).then(
                (notes_response) => {
                  if (
                    notes_response.status === 200 &&
                    notes_response.data.res_data
                  ) {
                    data = {
                      ...data,
                      notes_count: notes_response.data.res_data.total_entries,
                    };
                    this.setState({ account: data });
                    this.props.saveAccountCardData({ ...data, id });
                  }
                },
              );
            },
          );
        }
      });
    }
  };

  render() {
    const { account } = this.state;
    const { id } = this.props;

    if (id === undefined || id === null) {
      return null;
    }
    const uniqId =
      this.props.page && this.props.page === 'threaddetail'
        ? this.props.uniqId
        : '';
    const accId = this.state.id ? this.state.id : this.props.id;
    return (
      <ReactTooltip
        id={`${id}global_account${uniqId}`}
        aria-haspopup="true"
        place="right"
        effect="solid"
        className={`contactCardClass ${
          this.props.page === 'threaddetail' ? ' contactCardThreadDetail' : ''
        }`}
        delayHide={100}
      >
        <div className="">
          <div
            className="section-title d-flex no-gutters pt-4 pr-3 pb-2 pl-3"
            style={{ backgroundColor: '#434566' }}
          >
            <div className="col">
              <div className="d-flex">
                <div className="infoAccount contact-name-section">
                  {account.status && (
                    <h3 className="subHeadline text-dark mb-0">{`${account.status}   *  ${account.entity_type}`}</h3>
                  )}
                  <h1 className="text-small text-dark mb-0 onlineStatus">
                    {account.name}
                  </h1>
                  {account.name && (
                    <RatingComponent
                      rating={account.rating !== null ? account.rating : 0}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="col-sm-2 d-flex justify-content-end mt-4">
              {account.tasks_count ? (
                <div className="infoAccount">
                  <h1 className="text-small text-right text-dark mb-0">
                    {account.tasks_count}
                  </h1>
                  <h3 className="subHeadline text-dark text-uppercase font-weight-bold mb-0">
                    Tasks
                  </h3>
                </div>
              ) : null}
            </div>
            <div className="expndAction">
              <Link to={`/accountdetails/${accId}/activity`}>
                <i className="icon-expand" />
              </Link>
            </div>
          </div>
          {getFirmType() !== 'liscio-lite' && (
            <div className="pb-2">
              <div className="cardHead col-container">
                <div className="col p-2 text-center w-50 cardAction text-uppercase">
                  <Link to="/message/new">Send Message</Link>
                </div>
                <div className="col p-2 text-center w-50 cardAction text-uppercase text-dark font-weight-bold">
                  <Link to={`/accountdetails/${accId}/notes`}>
                    Notes{' '}
                    {account.notes_count !== undefined
                      ? `(${account.notes_count})`
                      : null}
                  </Link>
                </div>
              </div>
            </div>
          )}
          <div className="pt-2 pr-3 pb-3 pl-3">
            <div className="form-group w-100">
              <label>WEBSITE:</label>
              <div className="labelValue">
                <span>{account.website ? account.website : '-'}</span>
              </div>
            </div>
            <div className="form-group w-100">
              <label>Mobile #:</label>
              <div className="labelValue">
                <span>
                  {account.phone_number
                    ? FORMATPHONENUMBER(account.phone_number)
                    : '-'}
                </span>
              </div>
            </div>
            {getFirmType() !== 'liscio-lite' && (
              <div className="form-group mb-0 w-100">
                <label>Client Service Team:</label>
                <div className="labelValue">
                  {account.teamemployees &&
                    account.teamemployees.map((each) => (
                      <div className="row mb-1" key={each.id}>
                        <div className="ml-3 mr-2">
                          <div className="avatar avatar--w20 avatar--overlap">
                            {each.avatar && each.avatar.length ? (
                              <img
                                src={each.avatar}
                                alt={generateFullName(each)}
                                className="user-photo rounded-circle"
                              />
                            ) : (
                              <span className="dName dName--w20">
                                <span className="uName">{each.initials}</span>
                              </span>
                            )}
                          </div>
                        </div>
                        <div>
                          <span className="label-submedium">
                            {generateFullName(each)}
                          </span>
                        </div>
                      </div>
                    ))}
                  {account.teamemployees && account.teamemployees.length === 0 && (
                    <div>
                      <span className="label-submedium">-</span>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </ReactTooltip>
    );
  }
}

const mapStateToProps = (state) => ({
  allAccountsCardData: state.accountCardReducer,
});

const mapDispatchToProps = (dispatch) => ({
  saveAccountCardData: (data) => dispatch(saveAccountCardData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountCard);
