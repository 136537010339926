import React from 'react';

const PageNotFound = (props) => {
  if (props.name && props.name === 'message') {
    return (
      <div id="content" className="pt-4">
        <div className="center-wrapper">
          <div className="emptyBox text-center">
            <div className="emptyBox-icon">
              <h3 className="text-large">
                <i className="icon-expired" /> Nothing to See Here
              </h3>
              <p>{props.message}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div id="content" className="pt-4">
      <div className="center-wrapper">
        <div className="emptyBox text-center">
          <div className="emptyBox-icon">
            <h3 className="text-large">
              <i className="icon-expired" /> Nothing to See Here
            </h3>
            <p />
          </div>
        </div>
      </div>
    </div>
  );
};
export default PageNotFound;
