import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import Axios from 'axios';
import { GET_TEMPLATE_PREVIEW } from 'constants/constants';

export default class TemplatePreview extends Component {
  constructor(props) {
    super(props);

    this.state = { loading: true };
  }

  componentDidMount() {
    Axios.get(`${GET_TEMPLATE_PREVIEW}/${this.props.templateId}`).then(
      (res) => {
        if (
          res.status === 200 &&
          res.data &&
          res.data.data &&
          res.data.data.length
        ) {
          this.setState({
            loading: false,
            imageUrls: res.data.data,
          });
        } else {
          this.setState({ loading: false });
        }
      },
    );
  }

  render() {
    const { imageUrls } = this.state;
    return (
      <Modal
        isOpen
        className="customModal customModal--w990 customModal--nopadding"
      >
        <ModalHeader className="ModalHeader">
          <button
            type="button"
            onClick={this.props.closeModal}
            className="close"
            aria-label="Close"
          >
            <i className="icon-close2" aria-hidden="true" />
          </button>
        </ModalHeader>
        <ModalBody
          className="modalContent"
          style={{ height: '85vh', overflow: 'scroll' }}
        >
          {this.state.loading ? <div id="loading" /> : null}
          <div className="modalContent--inner">
            {imageUrls &&
              imageUrls.map((e) => (
                <img src={e.url} alt="previewesign" className="w-100" />
              ))}
          </div>
        </ModalBody>
      </Modal>
    );
  }
}
