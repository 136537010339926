import { useQuery } from '@tanstack/react-query';
import { resyncEmail } from 'api/Email';

export function useResyncEmail(enabled: boolean = false) {
  return useQuery({
    queryKey: ['resyncEmail'],
    queryFn: resyncEmail,
    enabled,
  });
}
