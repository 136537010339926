import React from 'react';
import styled from '@emotion/styled';
import { NEVER } from 'constants/constants';
import { isClient } from 'utilities/utils';
import ReadOnlyTinyMCE from 'common/ReadOnlyTinyMCE/ReadOnlyTinyMCE';

const StyledDiv = styled.div`
  .NewInvoice__PreviewContainer {
    display: flex;
  }

  label {
    font-weight: 600;
    font-size: 12px;
    color: #919eab;
    letter-spacing: 1px;
    flex: 1;
    margin-right: 20px;
  }

  .NewInvoicePreview__Left {
    margin-right: 80px;
  }

  .NewInvoicePreview__Row {
    display: flex;
    align-items: baseline;
    margin-top: 16px;

    p {
      width: 260px;
      margin: 0;
    }
  }

  .NewInvoicePreview__Message {
    display: flex;
    align-items: baseline;
    margin-top: 16px;

    label {
      flex: none;
      width: 100px;
      margin-right: 8px;
    }

    p {
      width: 260px;
    }
  }

  .file-drop-upload {
    padding: 14px 40px 0 0;
  }
`;

const InvoiceDetailsTop = ({
  className,
  data: {
    for_account,
    assigned_to_user,
    owner,
    payment_terms,
    payment_methods,
    payment_items,
    invoice_date,
    invoice_due_date,
    invoice_number,
    start_date,
    end_date,
    message,
    is_parent_task,
    invoice_amount,
    next_charge_date,
  },
}) => (
  <StyledDiv
    className={`NewInvoice__Preview ${className}`}
    data-testid="invoiceDetailsTop"
  >
    <div className="NewInvoice__PreviewContainer">
      <div className="NewInvoicePreview__Left">
        <div className="NewInvoicePreview__Row">
          <label htmlFor="account">FOR ACCOUNT:</label>
          <p>{for_account?.label}</p>
        </div>
        <div className="NewInvoicePreview__Row">
          <label htmlFor="contact">FOR CONTACT:</label>
          <p>{assigned_to_user?.label}</p>
        </div>
        <div className="NewInvoicePreview__Row">
          <label htmlFor="owner">OWNER:</label>
          <p>{owner?.label}</p>
        </div>
        {invoice_number && (
          <div className="NewInvoicePreview__Row">
            <label htmlFor="invoiceNumber">INVOICE NO:</label>
            <p>{invoice_number}</p>
          </div>
        )}
        <div className="NewInvoicePreview__Row">
          <label htmlFor="item">ITEM:</label>
          <p>{payment_items?.label ? payment_items.label : payment_items}</p>
        </div>
      </div>
      <div className="NewInvoicePreview__Right">
        {is_parent_task && (
          <>
            <div className="NewInvoicePreview__Row">
              <label htmlFor="invoiceDate">START DATE:</label>
              <p>{start_date}</p>
            </div>
            <div className="NewInvoicePreview__Row">
              <label htmlFor="paymentTerms">END DATE:</label>
              <p>{end_date || NEVER}</p>
            </div>
            <div className="NewInvoicePreview__Row">
              <label htmlFor="dueDate">NEXT CHARGE:</label>
              <p>{next_charge_date}</p>
            </div>
          </>
        )}
        {!is_parent_task && (
          <>
            <div className="NewInvoicePreview__Row">
              <label htmlFor="invoiceDate">INVOICE DATE:</label>
              <p>{invoice_date}</p>
            </div>
            {payment_terms && (
              <div className="NewInvoicePreview__Row">
                <label htmlFor="paymentTerms">PAYMENT TERMS:</label>
                <p>{payment_terms.label}</p>
              </div>
            )}
            <div className="NewInvoicePreview__Row">
              <label htmlFor="dueDate">DUE DATE:</label>
              <p>{invoice_due_date}</p>
            </div>
          </>
        )}
        <div className="NewInvoicePreview__Row">
          <label htmlFor="amount">AMOUNT:</label>
          <p>
            {' '}
            {Number(invoice_amount).toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            })}
          </p>
        </div>
        {!isClient() && (
          <div className="NewInvoicePreview__Row">
            <label htmlFor="paymentMethods">ALLOWED PAYMENT METHODS:</label>
            <p>{payment_methods.label}</p>
          </div>
        )}
      </div>
    </div>
    <div className="NewInvoicePreview__Message RTE__Description">
      <label htmlFor="message">MESSAGE:</label>
      {/* <Interweave content={message} /> */}
      <ReadOnlyTinyMCE value={message} />
    </div>
  </StyledDiv>
);

export default InvoiceDetailsTop;
