/*eslint-disable*/
import React, { Component } from 'react';
import Axios from 'axios';
import { Link } from 'react-router-dom-latest';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
  Typography,
  Divider,
} from '@mui/material';

import {
  ACCOUNT,
  DEACTIVATE_ACCOUNT,
  REACTIVATE_ACCOUNT,
} from 'constants/constants';
import { FORMATSSN } from 'utilities/regularexpressions';
import ConfirmationModal from 'common/ConfirmationModal';
import {
  AlertMessage,
  isUserEmployee,
  getCpaPreferences,
  getFirmType,
  getRole,
  getUrlParams,
} from 'utilities/utils';
import { DisplayData, HideSensitiveInfo } from 'common/CustomFields';
import RatingComponent from 'common/RatingComponent';
import { PlusIcon, MinusIcon } from 'common/Icons';

import AccountLoginsList from './AccountLogins/AccountLoginsList';
import Payroll from './Payroll/Payroll';
import ClientServiceTeam from './ClientService/ClientServiceTeam';
import {
  accountAddressFieldNames,
  showIndividualAdditionInformationForm,
  decryptDependentData,
  spouseAddressFieldNames,
  coBankSection,
  technologySolutionSection,
  topSection,
  spouseDetails,
  accountDetails,
  individualInformation,
} from './AccountCreation/AccountUtilities';
import { format } from 'date-fns';
import AccountAttribute from 'common/AccountAttribute/AccountAttribute';
import AccountHistoryAccordion from './elements/AccountHistoryAccordion';
import TaxDetailsAccordion from './elements/TaxDetailsAccordian';
import EncryptedFieldPreview from 'common/EncryptedFieldPreview/EncryptedFieldPreview';

const DisplayAddress = (props) => {
  const { data } = props;
  const {
    addressLine1Name,
    addressLine2Name,
    cityName,
    stateName,
    countryName,
    zipName,
  } = props.fieldNames;
  if (
    data[addressLine1Name] !== null ||
    data[cityName] !== null ||
    data[stateName] !== null ||
    data[countryName] !== null ||
    data[zipName] !== null
  ) {
    return (
      <address>
        {data[addressLine1Name] !== null ? `${data[addressLine1Name]}, ` : null}
        {data[addressLine2Name] !== null ? `${data[addressLine2Name]}, ` : null}
        {data[addressLine1Name] !== null || data[addressLine2Name] !== null ? (
          <br />
        ) : null}
        {data[cityName] !== null ? `${data[cityName]}, ` : null}
        {data[stateName] !== null ? `${data[stateName]}, ` : null}
        {data[zipName] !== null ? `${data[zipName]}, ` : null}
        {data[cityName] !== null ||
        data[stateName] !== null ||
        data[zipName] !== null ? (
          <br />
        ) : null}
        {data[countryName]}
      </address>
    );
  }
  return '-';
};

class AccountInfo extends Component {
  constructor(props) {
    super(props);

    // Get the 'expand' query param to prepopulate the `expandedPanels` array
    // allows deep linking to this 'account -> details' tab with a specific panel(accordian) open e.g.
    // ?expand=account_details | ?expand=account_history | ?expand=tax_details
    const urlParams = getUrlParams(window.location.search);
    const expandPanelFromUrl = urlParams.expand || '';

    const {
      data: { account, is_account_owner, dependant },
    } = this.props;
    this.state = {
      contactInfo: null,
      showDeleteConfirmation: false,
      recordToData: {},
      account: account ? { ...account } : {},
      dependents:
        dependant && dependant !== null && dependant.length
          ? decryptDependentData(dependant)
          : [],
      isEmployee: isUserEmployee(),
      showAllData: is_account_owner === undefined || is_account_owner,
      role: getRole(),
      expandedPanels: [expandPanelFromUrl],
      payrollDetailsEditMode: false,
      disableArhiveBtn: false,
    };
  }

  componentDidMount() {
    this.getData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps?.data?.account?.id !== this.props?.data?.account?.id) {
      this.getData(nextProps);
    }
  }

  getData = (propsData) => {
    const {
      data: { account, is_account_owner, dependant },
    } = propsData;
    if (account) {
      const isAccountIndividual = showIndividualAdditionInformationForm(
        account,
        getCpaPreferences().individual_entity_type,
      );
      this.setState({
        account: account ? { ...account } : {},
        dependents:
          dependant && dependant !== null && dependant.length
            ? decryptDependentData(dependant)
            : [],
        showAllData: is_account_owner === undefined || is_account_owner,
        isAccountIndividual,
      });
    }
  };

  showDeleteConfirmationModal = (input = {}) => {
    this.setState({
      recordToDelete: input,
      showDeleteConfirmation: !this.state.showDeleteConfirmation,
    });
  };

  hasUnhandledInvoices = async (accountId) => {
    try {
      const response = await Axios.get(`${ACCOUNT}/${accountId}`);

      if (response.status === 200) {
        return (
          response.data.data.has_failed_invoices ||
          response.data.data.has_overdue_invoices
        );
      } else {
        AlertMessage('error', response.data.message, 3000);
        return false;
      }
    } catch (err) {
      AlertMessage(
        'error',
        'Failed to check if account has unpaid/failed invoices',
        3000,
      );

      return false;
    }
  };

  deleteRecord = () => {
    this.setState({ disableArhiveBtn: true });
    Axios.put(`${DEACTIVATE_ACCOUNT}/${this.state.recordToDelete.id}`).then(
      (res) => {
        this.setState({
          recordToDelete: {},
          showDeleteConfirmation: false,
          searchFieldText: '',
        });
        if (res.status === 204 || res.status === 200) {
          this.setState({
            account: {
              ...this.state.account,
              status: 'Inactive',
              disableArhiveBtn: false,
            },
          });
          AlertMessage('success', res.data.message, 3000);
        } else {
          AlertMessage('error', res.data.message, 3000);
        }
      },
    );
  };

  reactiveRecord = () => {
    let status = 'Prospect';
    if (this.state.account.stage === null) {
      status = 'Client';
    }
    const accountData = { ...this.props.data.account, status };
    Axios.put(
      `${REACTIVATE_ACCOUNT}/${this.state.account.id}?status=${status}`,
      accountData,
    ).then((res) => {
      if (res.status === 204 || res.status === 200) {
        AlertMessage('success', res.data.message, 3000);
        this.setState({ account: { ...this.state.account, status } });
      } else {
        AlertMessage('error', res.data.message, 3000);
      }
    });
  };

  isExpanded = (panel) => {
    const { expandedPanels } = this.state;

    return expandedPanels.includes(panel);
  };

  handleChange = (panel) => (event, expanded) => {
    const { expandedPanels } = this.state;

    this.setState({ payrollDetailsEditMode: false });

    if (expanded) {
      if (!expandedPanels.includes(panel)) {
        this.setState({
          expandedPanels: [...expandedPanels, panel],
        });
      }
    } else {
      const index = expandedPanels.findIndex((value) => value === panel);
      if (index !== -1) {
        const newExpandedPanels = [...expandedPanels];
        newExpandedPanels.splice(index, 1);
        this.setState({ expandedPanels: newExpandedPanels });
      }
    }
  };

  renderBusinessAccount = (account) => {
    const { accountId, data } = this.props;
    const { showAllData, payrollDetailsEditMode, isEmployee } = this.state;
    const bankaccountsdata =
      this.props &&
      this.props.bankaccountsdata &&
      this.props.bankaccountsdata !== null &&
      this.props.bankaccountsdata.length
        ? this.props.bankaccountsdata
        : [];

    return process.env.REACT_APP_INFOCUS_FLAG ? (
      <>
        <div className="col-12">
          <Divider />

          <Stack alignItems="center" padding={4}>
            <Typography variant="gray_subtitle1">
              Expand Sections Below To View Details
            </Typography>
          </Stack>
        </div>

        <div className="col-12">
          <Accordion
            disableGutters
            square
            elevation={0}
            expanded={this.isExpanded('account_details')}
            onChange={this.handleChange('account_details')}
          >
            <AccordionSummary
              id="account_details-header"
              expandIcon={
                this.isExpanded('account_details') ? (
                  <MinusIcon />
                ) : (
                  <PlusIcon />
                )
              }
              aria-controls="account_details-content"
            >
              <Typography fontWeight="bolder" variant="h6">
                Account Details
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="center-wrapper col-12">
                <div className="row">
                  {accountDetails.map((each) => (
                    <AccountAttribute
                      key={each.value}
                      formData={account}
                      propertyValues={each}
                      recordId={accountId}
                    />
                  ))}
                </div>
                {getFirmType() !== 'cpa' ? null : (
                  <h2 className="text-medium onlineStatus mt-4 pt-3 mb-3">
                    Technology Solution
                  </h2>
                )}
                {getFirmType() !== 'cpa' ? null : (
                  <div className="row">
                    {technologySolutionSection.map((each) => (
                      <DisplayData
                        key={each.value}
                        data={account}
                        propertyValues={each}
                      />
                    ))}
                  </div>
                )}
                {getFirmType() === 'cpa' && showAllData
                  ? this.renderBankAccounts(bankaccountsdata)
                  : null}
              </div>
            </AccordionDetails>
          </Accordion>
        </div>

        <div className="col-12">
          <Accordion
            disableGutters
            square
            elevation={0}
            expanded={this.isExpanded('login_details')}
            onChange={this.handleChange('login_details')}
          >
            <AccordionSummary
              id="login_details-header"
              expandIcon={
                this.isExpanded('login_details') ? <MinusIcon /> : <PlusIcon />
              }
              aria-controls="login_details-content"
            >
              <Typography fontWeight="bolder" variant="h6">
                Login Details
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <AccountLoginsList data={data} accountId={accountId} />
            </AccordionDetails>
          </Accordion>
        </div>

        <div className="col-12">
          <Accordion
            disableGutters
            square
            elevation={0}
            expanded={this.isExpanded('payroll_details')}
            onChange={this.handleChange('payroll_details')}
          >
            <AccordionSummary
              id="payroll_details-header"
              expandIcon={
                this.isExpanded('payroll_details') ? (
                  <MinusIcon />
                ) : (
                  <PlusIcon />
                )
              }
              aria-controls="payroll_details-content"
            >
              <Typography fontWeight="bolder" variant="h6">
                Payroll Details
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Payroll
                id={accountId}
                data={data}
                editMode={payrollDetailsEditMode}
                onEditModeChange={(mode) => {
                  this.setState({ payrollDetailsEditMode: mode });
                }}
              />
            </AccordionDetails>
          </Accordion>
        </div>
        {
          <div className="col-12">
            <TaxDetailsAccordion
              accountId={data?.account?.uuid}
              handleChange={this.handleChange}
              isExpanded={this.isExpanded}
            />
          </div>
        }
        {isEmployee && (
          <AccountHistoryAccordion
            accountId={accountId}
            handleChange={this.handleChange}
            isExpanded={this.isExpanded}
          />
        )}
      </>
    ) : (
      <div className="center-wrapper col-12">
        <h2 className="text-medium onlineStatus mt-4 pt-3 mb-3">
          Account Details
        </h2>
        <div className="row">
          {accountDetails.map((each) => (
            <AccountAttribute
              key={each.value}
              formData={account}
              propertyValues={each}
              recordId={accountId}
            />
          ))}
        </div>
        {getFirmType() !== 'cpa' ? null : (
          <h2 className="text-medium onlineStatus mt-4 pt-3 mb-3">
            Technology Solution
          </h2>
        )}
        {getFirmType() !== 'cpa' ? null : (
          <div className="row">
            {technologySolutionSection.map((each) => (
              <DisplayData
                key={each.value}
                data={account}
                propertyValues={each}
              />
            ))}
          </div>
        )}
        {getFirmType() === 'cpa' && showAllData
          ? this.renderBankAccounts(bankaccountsdata)
          : null}
      </div>
    );
  };

  renderIndividualAccount = (account) => {
    const { accountId, data } = this.props;
    const { showAllData, isEmployee } = this.state;
    const bankaccountsdata =
      this.props &&
      this.props.bankaccountsdata &&
      this.props.bankaccountsdata !== null &&
      this.props.bankaccountsdata.length
        ? this.props.bankaccountsdata
        : [];

    return process.env.REACT_APP_INFOCUS_FLAG ? (
      <>
        <div className="col-12">
          <Divider />

          <Stack alignItems="center" padding={4}>
            <Typography variant="gray_subtitle1">
              Expand Sections Below To View Details
            </Typography>
          </Stack>
        </div>
        <div className="col-12">
          <Accordion
            disableGutters
            square
            elevation={0}
            expanded={this.isExpanded('account_details')}
            onChange={this.handleChange('account_details')}
          >
            <AccordionSummary
              id="account_details-header"
              expandIcon={
                this.isExpanded('account_details') ? (
                  <MinusIcon />
                ) : (
                  <PlusIcon />
                )
              }
              aria-controls="account_details-content"
            >
              <Typography fontWeight="bolder" variant="h6">
                Account Details
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="col-12">
                <h2 className="text-medium onlineStatus mt-4 pt-3 mb-3">
                  {getFirmType() !== 'cpa'
                    ? 'Matter Details'
                    : 'Tax Payer Details'}
                </h2>
                <div className="row">
                  {individualInformation(
                    getFirmType() !== 'cpa' ? 'Matter ' : '',
                  ).map((each) => (
                    <AccountAttribute
                      key={each.value}
                      formData={account}
                      propertyValues={each}
                      recordId={accountId}
                    />
                  ))}
                </div>
                <h2 className="text-medium onlineStatus mt-4 pt-3 mb-3">
                  {getFirmType() !== 'cpa'
                    ? "Matter's Spouse Details"
                    : 'Spouse Details'}
                </h2>
                <div className="row ">
                  {spouseDetails('').map((each) => {
                    if (each.value === 'address') {
                      return (
                        <div className="col-4" key={each.value}>
                          <div className="form-group form-inline-label">
                            <label className="align-self-start mr-2">
                              {each.label}:
                            </label>
                            <div className="labelValue">
                              <DisplayAddress
                                key={each.value}
                                data={account}
                                fieldNames={spouseAddressFieldNames}
                              />
                            </div>
                          </div>
                        </div>
                      );
                    }
                    return (
                      <AccountAttribute
                        key={each.value}
                        formData={account}
                        propertyValues={each}
                        recordId={accountId}
                      />
                    );
                  })}
                </div>
                <h2 className="text-medium onlineStatus mt-4 pt-3 mb-1">
                  Dependents Details
                </h2>
                {this.state.dependents.length ? (
                  <div className="row">
                    <div id="dependent-list" className="tableWrap col-12">
                      <div className="tRow tRow--head">
                        <div className="row">
                          <div className="col-3 thCol">Dependent Name</div>
                          <div className="col-3 thCol">Birthdate</div>
                          <div className="col-3 thCol">Taxpayer ID</div>
                          <div className="col-3 thCol">Relationship</div>
                        </div>
                      </div>
                      <div className="tRow tRow--body">
                        {this.state.dependents.map((each) => (
                          <div className="row" key={each.id}>
                            <div className="col-3 tdCol">{`${each.first_name} ${each.last_name}`}</div>
                            <div className="col-3 tdCol">
                              {each.birthdate !== null
                                ? format(each.birthdate, 'MM/dd/yyyy')
                                : '-'}
                            </div>
                            <div className="col-3 tdCol">
                              <EncryptedFieldPreview
                                recordType="Dependant"
                                fieldName="ssn"
                                recordId={each.id}
                                hasEncryptedValue={each.encrypted_ssn.populated}
                                data={
                                  each.ssn !== null ? FORMATSSN(each.ssn) : null
                                }
                              />
                            </div>
                            <div className="col-3 tdCol">
                              {each.dependant_relationship_type !== null
                                ? each.dependant_relationship_type
                                : '-'}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ) : (
                  <p className="text-light pt-2">No dependents</p>
                )}

                {getFirmType() === 'cpa' && showAllData
                  ? this.renderBankAccounts(bankaccountsdata)
                  : null}
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
        {
          <div className="col-12">
            <TaxDetailsAccordion
              accountId={data?.account?.uuid}
              handleChange={this.handleChange}
              isExpanded={this.isExpanded}
            />
          </div>
        }
        {isEmployee && (
          <AccountHistoryAccordion
            accountId={accountId}
            handleChange={this.handleChange}
            isExpanded={this.isExpanded}
          />
        )}
      </>
    ) : (
      <div className="col-12">
        <h2 className="text-medium onlineStatus mt-4 pt-3 mb-3">
          {getFirmType() !== 'cpa' ? 'Matter Details' : 'Tax Payer Details'}
        </h2>
        <div className="row">
          {individualInformation(getFirmType() !== 'cpa' ? 'Matter ' : '').map(
            (each) => (
              <AccountAttribute
                key={each.value}
                formData={account}
                propertyValues={each}
                recordId={accountId}
              />
            ),
          )}
        </div>
        <h2 className="text-medium onlineStatus mt-4 pt-3 mb-3">
          {getFirmType() !== 'cpa'
            ? "Matter's Spouse Details"
            : 'Spouse Details'}
        </h2>
        <div className="row ">
          {spouseDetails('').map((each) => {
            if (each.value === 'address') {
              return (
                <div className="col-4" key={each.value}>
                  <div className="form-group form-inline-label">
                    <label className="align-self-start mr-2">
                      {each.label}:
                    </label>
                    <div className="labelValue">
                      <DisplayAddress
                        key={each.value}
                        data={account}
                        fieldNames={spouseAddressFieldNames}
                      />
                    </div>
                  </div>
                </div>
              );
            }
            return (
              <AccountAttribute
                key={each.value}
                formData={account}
                propertyValues={each}
                recordId={accountId}
              />
            );
          })}
        </div>
        <h2 className="text-medium onlineStatus mt-4 pt-3 mb-1">
          Dependents Details
        </h2>
        {this.state.dependents.length ? (
          <div className="row">
            <div id="dependent-list" className="tableWrap col-12">
              <div className="tRow tRow--head">
                <div className="row">
                  <div className="col-3 thCol">Dependent Name</div>
                  <div className="col-3 thCol">Birthdate</div>
                  <div className="col-3 thCol">Taxpayer ID</div>
                  <div className="col-3 thCol">Relationship</div>
                </div>
              </div>
              <div className="tRow tRow--body">
                {this.state.dependents.map((each) => (
                  <div className="row" key={each.id}>
                    <div className="col-3 tdCol">{`${each.first_name} ${each.last_name}`}</div>
                    <div className="col-3 tdCol">
                      {each.birthdate !== null
                        ? format(each.birthdate, 'MM/dd/yyyy')
                        : '-'}
                    </div>
                    <div className="col-3 tdCol">
                      <EncryptedFieldPreview
                        recordType="Dependant"
                        fieldName="ssn"
                        recordId={each.id}
                        hasEncryptedValue={each.encrypted_ssn.populated}
                        data={each.ssn !== null ? FORMATSSN(each.ssn) : null}
                      />
                    </div>
                    <div className="col-3 tdCol">
                      {each.dependant_relationship_type !== null
                        ? each.dependant_relationship_type
                        : '-'}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <p className="text-light pt-2">No dependents</p>
        )}

        {getFirmType() === 'cpa' && showAllData
          ? this.renderBankAccounts(bankaccountsdata)
          : null}
      </div>
    );
  };

  renderBankAccounts(bankaccountsdata) {
    return [
      bankaccountsdata && bankaccountsdata.length ? (
        bankaccountsdata.map((e, key) => (
          <div className="BankAccountDetails mb-5" key={key}>
            <h2 className="text-medium onlineStatus mt-4 pt-3 mb-1">
              {key + 1}. Bank Account Details
            </h2>
            <div className="row">
              {coBankSection.map((each) => (
                <AccountAttribute
                  key={each.value}
                  formData={e}
                  propertyValues={each}
                  recordId={e.id}
                />
              ))}
              <div className="col-12 align-items-center">
                <div className="checkbox checkbox-primary check-container">
                  <label
                    htmlFor="in_ach_is_autopay_confirmed"
                    className="font-weight-normal text-capitalize labelValue"
                  >
                    <input
                      name="is_ach_autopay"
                      id={`${key}is_ach_autopay`}
                      type="checkbox"
                      onChange={() => {}}
                      checked={e.is_ach_autopay}
                      disabled
                      className="pt-1"
                    />
                    <span className="pl-2">
                      Use this account for monthly ACH auto payments?
                    </span>
                    <i className="checkmark" />
                  </label>
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div>
          <h2 className="text-medium onlineStatus mt-4 pt-3 mb-1">
            Bank Account Details
          </h2>
          <p className="text-light pt-2">No Bank Accounts</p>
        </div>
      ),
    ];
  }

  onArchiveAccount = async (account) => {
    const hasUnhandledInvoices = await this.hasUnhandledInvoices(account.id);
    this.setState({
      recordToDeleteHasUnhandledInvoices: hasUnhandledInvoices,
    });
    this.showDeleteConfirmationModal(account);
  };

  render() {
    const { account, isEmployee, showAllData, disableArhiveBtn } = this.state;
    const bankaccountsdata =
      this.props &&
      this.props.bankaccountsdata &&
      this.props.bankaccountsdata !== null &&
      this.props.bankaccountsdata.length
        ? this.props.bankaccountsdata
        : [];

    const confirmationMessage = this.state.recordToDeleteHasUnhandledInvoices
      ? 'Attention: The account deactivation process will close all related open tasks and unpaid/failed invoices. Proceed?'
      : 'Attention: The account deactivation process will close all open tasks related. Proceed?';

    return (
      <div className="center-wrapper overflow-x-hide">
        <div className="mb-4 section-title d-flex row">
          <div className="col-sm-12 col-md-6">
            <div className="d-flex">
              <div className="infoAccount">
                {isEmployee ? (
                  <h4 className="subHeadline text-dark text-uppercase font-weight-bold mb-0">{`${account.status}   *  ${account.entity_type}`}</h4>
                ) : (
                  <h4 className="subHeadline text-dark text-uppercase font-weight-bold mb-0">
                    {account.entity_type}
                  </h4>
                )}
                <h2 className="text-large onlineStatus mb-0 text-capitalize">
                  {account.name}
                </h2>
                {isEmployee ? (
                  <RatingComponent rating={account.rating} />
                ) : null}
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 d-flex justify-content-end align-items-center">
            {getFirmType() !== 'liscio-lite' && (
              <ClientServiceTeam id={account.id} status={account.status} />
            )}
            {/* <button className="btn btn-outline-light">Re-Send Invite</button> */}
            {showAllData && (
              <div className="dropdown dropdown--small btn-div">
                <a
                  href="javascript:"
                  className="btn btn-link btn--onlyicon dropdown-toggle"
                  data-toggle="dropdown"
                  aria-expanded="true"
                >
                  <i className="icon-more" />
                  <span className="dropupWrap--caret" />
                </a>
                {account.status.toLowerCase() === 'inactive' ? (
                  <div className="dropdown-menu dropdown-menu-right">
                    <a
                      href="javascript:"
                      className="dropdown-item"
                      onClick={this.reactiveRecord}
                    >
                      Reactivate Account
                    </a>
                  </div>
                ) : (
                  <div className="dropdown-menu dropdown-menu-right">
                    <Link
                      className="dropdown-item"
                      to={`/account/edit/${account.id}`}
                      key="edit_account"
                    >
                      Edit Account
                    </Link>
                    {this.state.role &&
                    this.state.role === 'specialist' ? null : isEmployee ? (
                      <a
                        href="javascript:"
                        className="dropdown-item"
                        onClick={() => this.onArchiveAccount(account)}
                        key="archive_account"
                      >
                        Archive Account
                      </a>
                    ) : null}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>

        <div className="row">
          {topSection.map((each, key) => {
            if (each.value === 'address') {
              return (
                <div className="col-4" key={each.value}>
                  <div className="form-group form-inline-label">
                    <label className="align-self-start mr-2">
                      {each.label}:
                    </label>
                    <div className="labelValue">
                      <DisplayAddress
                        key={each.value}
                        data={account}
                        fieldNames={accountAddressFieldNames}
                      />
                    </div>
                  </div>
                </div>
              );
            }
            return (
              <DisplayData
                data={account}
                showAllData={showAllData}
                propertyValues={each}
                key={each.value}
                isEmployee={isEmployee}
              />
            );
          })}

          {getFirmType() !== 'liscio-lite' &&
            showAllData &&
            (this.state.isAccountIndividual
              ? this.renderIndividualAccount(account)
              : this.renderBusinessAccount(account))}
        </div>

        {this.state.showDeleteConfirmation ? (
          <ConfirmationModal
            isOpen
            messageText={confirmationMessage}
            yesBtnText="Proceed"
            noBtnText="Cancel"
            yesBtnClick={this.deleteRecord}
            disableYesBtn={disableArhiveBtn}
            disableNoBtn={disableArhiveBtn}
            noBtnClick={this.showDeleteConfirmationModal}
          />
        ) : null}
      </div>
    );
  }
}

export default AccountInfo;
