/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/no-unused-state */
/* eslint-disable @typescript-eslint/no-shadow */
import React, { Component } from 'react';
import Axios from 'axios';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Interweave from 'interweave';
import TooltipItem from 'common/TooltipItem';
import NoRecords from 'common/NoRecords';
import {
  sortingIcon,
  doesTagExist,
  AlertMessage,
  getCpaPreferences,
  getStorageData,
  setUserInLocalStorage,
} from 'utilities/utils';
import { CPA_TAGS, UPDATE_TAGS_SORT_ORDER } from 'constants/constants';
import ConfirmationModal from 'common/ConfirmationModal';
import AddTagModal from './AddTagModal';

const sortList = (data, sortBy, sortOrder) => {
  let listData = data;
  listData.sort((a, b) => {
    let x = a.sort_order;
    let y = b.sort_order;
    if (sortBy === 'tag_name') {
      x = a.key.toLowerCase();
      y = b.key.toLowerCase();
    } else if (sortBy === 'no_of_files') {
      x = a.no_of_files;
      y = b.no_of_files;
    }
    if (x < y) {
      return -1;
    }
    return 1;
  });
  if (sortOrder === 'desc') {
    listData = listData.reverse();
  }
  return data;
};

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const tagsHeaders = [
  {
    value: 'tag_name',
    label: 'Tag Name',
    isSortable: true,
    className: ' col-3 thCol',
  },
  {
    value: 'sort_order',
    label: 'Sort Order',
    isSortable: true,
    className: ' col-2 thCol',
  },
];

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',

  // change background colour if dragging
  background: isDragging ? '#dfe3e8' : 'white',
  borderRadius: isDragging && '8px',
  // borderTop: isDragging ? '' : '0.5px solid #dfe3e8',
  // borderBottom: isDragging ? '' : '0.5px solid #dfe3e8',
  color: isDragging ? '#00aecc !important' : '',
  ...draggableStyle,
});

const getItemCssClass = (isDragging) => {
  if (isDragging) {
    return 'tdBtn dragable_row';
  }
  return 'tdBtn';
};

class AddEditTags extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      listData: [],
      sortBy: 'sort_order',
      sortOrder: 'asc',
      showAddTagModal: false,
      showDeleteConfirmation: false,
      errorMessages: [],
      defaultPreferences: true,
      searchResults: [],
      searchFieldText: '',
      showDeleteErrorMessage: false,
    };
  }

  componentDidMount() {
    document.title = 'Tags';
    this.getRecords();
  }

  getRecords = () => {
    const { sortBy, sortOrder, searchFieldText } = this.state;
    let url = CPA_TAGS;
    if (searchFieldText && searchFieldText !== null && searchFieldText.length) {
      url = `${url}?search=${searchFieldText}`;
    }
    Axios.get(url)
      .then((res) => {
        if (res.data && res.data.data && res.data.data.length) {
          const { data, default_preferences } = res.data;
          this.setState({
            listData: sortList(data, sortBy, sortOrder),
            loading: false,
            defaultPreferences: default_preferences,
          });
          if (!searchFieldText.length) {
            this.updateTagsInLocalStorage();
          }
        } else {
          this.setState({
            listData: undefined,
            loading: false,
          });
        }
      })
      .catch(() => {
        this.setState({
          listData: undefined,
          loading: false,
        });
      });
  };

  handleSorting = (sortBy) => {
    let { sortOrder } = this.state;
    let { listData } = this.state;
    if (this.state.sortBy === sortBy) {
      if (sortOrder === 'asc') {
        sortOrder = 'desc';
      } else {
        sortOrder = 'asc';
      }
    } else {
      sortOrder = 'asc';
    }
    listData.sort((a, b) => {
      let x = a.sort_order;
      let y = b.sort_order;
      if (sortBy === 'tag_name') {
        x = a.key.toLowerCase();
        y = b.key.toLowerCase();
      } else if (sortBy === 'no_of_files') {
        x = a.no_of_files;
        y = b.no_of_files;
      }
      if (x < y) {
        return -1;
      }
      return 1;
    });
    if (sortOrder === 'desc') {
      listData = listData.reverse();
    }
    this.setState({
      sortOrder,
      sortBy,
      listData: sortList(listData, sortBy, sortOrder),
    });
  };

  handleInput = (input) => {
    const { listData, currentIndex } = this.state;
    listData[currentIndex].inputvalue = input.target.value;
    this.setState({ listData });
  };

  toggleInput = (index) => {
    const { listData, currentIndex } = this.state;
    if (currentIndex !== undefined) {
      listData[currentIndex].edit = !listData[currentIndex].edit;
      listData[currentIndex].inputvalue =
        listData[currentIndex].value !== null
          ? listData[currentIndex].value
          : listData[currentIndex].key;
    }
    if (index !== undefined) {
      listData[index].edit = !listData[index].edit;
      listData[index].inputvalue =
        listData[index].value !== null
          ? listData[index].value
          : listData[index].key;
    }
    this.setState(
      {
        listData,
        currentIndex: index,
        errormessage: undefined,
      },
      () => {
        if (index !== undefined && document.getElementById('tag_input')) {
          document.getElementById('tag_input').focus();
        } else {
          this.setState({ currentIndex: undefined });
        }
      },
    );
  };

  handleKeyboardEvents = (e) => {
    if (e) {
      if (e.key === 'Escape') {
        this.toggleInput();
      } else if (e.key === 'Enter') {
        this.handleUpdate();
      }
    }
  };

  handleUpdate = () => {
    const { listData, currentIndex } = this.state;
    const tagName = listData[currentIndex].inputvalue.trim();
    if (tagName === undefined || tagName === null || !tagName.length) {
      this.setState({ errormessage: 'Enter valid tag name' });
    } else if (listData[currentIndex].value === tagName) {
      this.toggleInput();
    } else if (doesTagExist(listData, tagName, currentIndex)) {
      this.setState({ errormessage: 'Tag already exists' });
    } else {
      this.updateTag();
    }
  };

  updateTag = () => {
    const { searchFieldText, defaultPreferences } = this.state;
    this.setState({ loading: true });
    const { listData, currentIndex, sortBy, sortOrder } = this.state;
    Axios.put(`${CPA_TAGS}/${listData[currentIndex].id}`, {
      key: listData[currentIndex].inputvalue.trim(),
      value: listData[currentIndex].inputvalue.trim(),
    }).then((res) => {
      if (res.data) {
        const { status, message } = res.data;
        if (status === 200) {
          AlertMessage('success', message, 3000);
          listData[currentIndex].key = listData[currentIndex].inputvalue.trim();
          listData[currentIndex].value =
            listData[currentIndex].inputvalue.trim();
          listData[currentIndex].edit = !listData[currentIndex].edit;
          this.setState({
            listData: sortList(listData, sortBy, sortOrder),
            currentIndex: undefined,
            loading: this.state.defaultPreferences,
          });
          if (defaultPreferences || searchFieldText.length) {
            this.getRecords();
          } else {
            this.updateTagsInLocalStorage();
          }
        } else {
          AlertMessage('error', message, 3000);
          this.setState({ loading: false });
          if (document.getElementById('tag_input')) {
            document.getElementById('tag_input').focus();
          }
        }
      }
    });
  };

  updateTagsInLocalStorage = () => {
    const { listData } = this.state;
    const tags = sortList([...listData], 'sort_order', 'asc');
    const uploadTags = tags.map((e) => {
      const { key, value } = e;
      return {
        key,
        value: key,
        label: value && value !== null ? value : key,
        is_descending: false,
      };
    });
    const cpaTags = getCpaPreferences();
    if (cpaTags) {
      const tags_emp_cant_addrem = [];
      cpaTags.tags_emp_cant_addrem.forEach((e) =>
        tags_emp_cant_addrem.push(e.key),
      );
      let { restricted_tags } = cpaTags;
      restricted_tags = restricted_tags.filter(
        (e) => !tags_emp_cant_addrem.includes(e.key),
      );
      cpaTags.upload_tags = [...uploadTags, ...restricted_tags];
      cpaTags.filter_tags = [...uploadTags, ...cpaTags.restricted_tags];
    }
    // TODO: __HOMEDATA-AUDIT
    const homedata = getStorageData();
    homedata.pref_type_options = cpaTags;
    setUserInLocalStorage(homedata);
  };

  deleteTag = () => {
    const { recordtoDelete } = this.state;
    this.setState({
      loading: true,
      showDeleteConfirmation: false,
    });
    if (recordtoDelete) {
      Axios.delete(`${CPA_TAGS}/${recordtoDelete.id}`).then((res) => {
        if (res.data) {
          const { status, message } = res.data;
          if (status === 200) {
            AlertMessage('success', message, 3000);
          } else {
            AlertMessage('error', message, 5000);
          }
          this.getRecords();
        }
      });
    }
  };

  handleDragEnd = (result) => {
    if (
      !result.destination ||
      result.destination.index === result.source.index
    ) {
      return;
    }

    const listData = reorder(
      this.state.listData,
      result.source.index,
      result.destination.index,
    );
    listData.map((e, index) => {
      e.sort_order = index + 1;
      return e;
    });
    this.setState(
      {
        listData,
        loading: this.state.defaultPreferences,
      },
      () => {
        const tags = [];
        listData.map((e) => {
          tags.push(e.key);
          return e;
        });
        Axios.post(UPDATE_TAGS_SORT_ORDER, { tags }).then((res) => {
          if (res.data) {
            const { status, message } = res.data;
            if (status === 200) {
              AlertMessage('success', message, 3000);
              if (this.state.defaultPreferences) {
                this.getRecords();
              } else {
                this.updateTagsInLocalStorage();
                this.setState({ loading: false });
              }
            } else {
              AlertMessage('error', message, 3000);
            }
          }
        });
      },
    );
  };

  addNewTag = (input) => {
    const { listData, sortBy, sortOrder, defaultPreferences, searchFieldText } =
      this.state;
    listData.push(input);
    this.setState({
      listData: sortList(listData, sortBy, sortOrder),
      loading: defaultPreferences,
    });
    if (this.state.defaultPreferences || searchFieldText.length) {
      this.getRecords();
    } else {
      this.updateTagsInLocalStorage();
    }
  };

  toggleDeleteConfirmation = (input = {}) => {
    if (input.no_of_files) {
      this.toggleErrorMessage();
    } else {
      this.setState({
        recordtoDelete: input,
        showDeleteConfirmation: !this.state.showDeleteConfirmation,
        deleteErrorMessage: undefined,
      });
    }
  };

  toggleErrorMessage = () => {
    this.setState({
      showDeleteErrorMessage: !this.state.showDeleteErrorMessage,
    });
  };

  toggleAddTagModal = () => {
    const { showAddTagModal } = this.state;
    this.setState({ showAddTagModal: !showAddTagModal });
  };

  handleSearch = (input) => {
    const inputvalue = input && input.target.value ? input.target.value : '';
    this.setState({ searchFieldText: inputvalue });
    clearTimeout(this.typingTime);
    this.typingTime = setTimeout(() => {
      this.getRecords();
    }, 500);
  };

  render() {
    const {
      sortBy,
      sortOrder,
      errormessage,
      showAddTagModal,
      loading,
      showDeleteConfirmation,
      recordtoDelete,
      currentIndex,
      errorMessages,
      searchFieldText,
      listData,
      showDeleteErrorMessage,
    } = this.state;
    return (
      <div id="content">
        {loading && <div id="loading" />}
        <div className="center-wrapper">
          <div className="filter-wrapper d-flex justify-content-between align-items-center">
            <div className="filterPart d-flex">
              <div className="form-group has-search">
                <span className="icon-zoom2 form-control-feedback" />
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search Tag"
                  id="tag-search"
                  onChange={this.handleSearch}
                  value={searchFieldText}
                  autoComplete="off"
                />
              </div>
            </div>
            <div className="FilterHeader--Action align-self-start">
              <button
                type="button"
                onClick={this.toggleAddTagModal}
                className="btn btn-primary"
                disabled={currentIndex !== undefined}
              >
                <i>+</i> Tag
              </button>
            </div>
          </div>
          {listData === undefined ? <NoRecords /> : null}
          {listData !== undefined && listData !== null && listData.length ? (
            <section>
              {errorMessages.map((e, index) => (
                <div
                  className="alert alert-danger alert-dismissible align-items-center pl-3 pr-1 pt-2 pb-2"
                  role="alert"
                >
                  {/* <Interweave allowAttributes content={e} /> */}
                  <Interweave allowAttributes content={e.message} />
                  <button
                    type="button"
                    className="btn-sm btn--onlyicon btn-link"
                    aria-label="Close Alert"
                    onClick={() => this.toggleErrorMessage(index)}
                  >
                    <i className="icon-close2" aria-hidden="true" />
                  </button>
                </div>
              ))}
              <div className="tab-content">
                <div className="tab-pane active">
                  <DragDropContext onDragEnd={this.handleDragEnd}>
                    <table id="tags_list" className="tableWrap col">
                      <thead className="tRow tRow--head">
                        <tr className="row">
                          {tagsHeaders.map((each) => (
                            <th
                              key={each.value}
                              className={
                                sortingIcon(
                                  sortBy,
                                  each.value,
                                  sortOrder,
                                  'className',
                                ) + each.className
                              }
                              onClick={() =>
                                each.isSortable
                                  ? this.handleSorting(each.value)
                                  : null
                              }
                            >
                              <span className="posRel">
                                {each.label}
                                <i
                                  className={sortingIcon(
                                    sortBy,
                                    each.value,
                                    sortOrder,
                                  )}
                                />
                              </span>
                            </th>
                          ))}
                          <td className="col thCol" />
                        </tr>
                      </thead>
                      <Droppable droppableId="tags">
                        {(provided, snapshot) => (
                          <tbody
                            className="tRow tRow--body"
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            {listData.map((each, index) => (
                              <Draggable
                                key={each.id}
                                draggableId={each.id.toString()}
                                index={index}
                                isDragDisabled={
                                  !(
                                    sortBy === 'sort_order' &&
                                    sortOrder === 'asc'
                                  ) ||
                                  currentIndex !== undefined ||
                                  searchFieldText.length > 0
                                }
                              >
                                {(provided, snapshot) => (
                                  <tr
                                    key={each.id}
                                    className={getItemCssClass(
                                      snapshot.isDragging,
                                      provided.draggableProps[
                                        'data-rbd-draggable-id'
                                      ],
                                      each.key,
                                    )}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style,
                                    )}
                                  >
                                    <td className="col-3 tdCol text-body">
                                      {each.edit ? (
                                        <div
                                          className={
                                            errormessage
                                              ? 'form-group mb-0 form-error'
                                              : 'form-group mb-0'
                                          }
                                        >
                                          <input
                                            id="tag_input"
                                            type="text"
                                            value={each.inputvalue}
                                            onChange={this.handleInput}
                                            onBlur={() => this.handleUpdate()}
                                            onKeyDown={
                                              this.handleKeyboardEvents
                                            }
                                            autoComplete="off"
                                            spellCheck="off"
                                            className="form-control"
                                          />
                                          {errormessage ? (
                                            <span className="error_label">
                                              {errormessage}
                                            </span>
                                          ) : null}
                                        </div>
                                      ) : (
                                        <span
                                          onDoubleClick={() =>
                                            this.toggleInput(index)
                                          }
                                        >
                                          {each.value && each.value !== null
                                            ? each.value
                                            : each.key}
                                        </span>
                                      )}
                                    </td>
                                    <td className="col-2 tdCol text-body">
                                      <span>{each.sort_order}</span>
                                    </td>
                                    <td className="col tdCol text-right">
                                      {sortBy === 'sort_order' &&
                                      sortOrder === 'asc' ? (
                                        <OverlayTrigger
                                          key={each.id}
                                          placement="top"
                                          overlay={
                                            <Tooltip id={`dragbutton${index}`}>
                                              Drag to Change Sort Order
                                            </Tooltip>
                                          }
                                        >
                                          <span
                                            id={`dragbutton${index}`}
                                            className="btn-sm btn--onlyicon btn-link tdBtn__hover dragbutton"
                                            style={{ border: '3px' }}
                                          >
                                            <i className="icon-double-arrow" />
                                          </span>
                                        </OverlayTrigger>
                                      ) : (
                                        <Button
                                          type="button"
                                          className="btn-sm btn--onlyicon btn-link tdBtn__hover icon-double-arrow"
                                          disabled
                                        />
                                      )}
                                      <TooltipItem
                                        position="top"
                                        tooltipType="button"
                                        key={`edit${each.id}`}
                                        text="Edit"
                                        btnClass="btn-sm btn--onlyicon btn-link tdBtn__hover"
                                        id={`edit${each.id}`}
                                        clickAction={() =>
                                          this.toggleInput(index)
                                        }
                                        iconn="icon-edit"
                                      />
                                      <TooltipItem
                                        position="top"
                                        tooltipType="button"
                                        key={`delete${each.id}`}
                                        text="Delete"
                                        btnClass="btn-sm btn--onlyicon btn-link tdBtn__hover"
                                        clickAction={() =>
                                          this.toggleDeleteConfirmation(each)
                                        }
                                        id={`delete${each.id}`}
                                        iconn="icon-delete-small"
                                      />
                                    </td>
                                  </tr>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </tbody>
                        )}
                      </Droppable>
                    </table>
                  </DragDropContext>
                </div>
              </div>
            </section>
          ) : null}
          {showAddTagModal ? (
            <AddTagModal
              noBtnClick={this.toggleAddTagModal}
              headerText="Add Tag"
              yesBtnText="Save"
              noBtnText="Cancel"
              isOpen
              existingTags={listData}
              updateData={this.addNewTag}
            />
          ) : null}
          {showDeleteConfirmation ? (
            <ConfirmationModal
              isOpen
              headerText={`Delete the ${recordtoDelete.value} tag. Proceed?`}
              yesBtnText="Proceed"
              noBtnText="Cancel"
              yesBtnClick={this.deleteTag}
              noBtnClick={this.toggleDeleteConfirmation}
            />
          ) : null}
          {showDeleteErrorMessage ? (
            <ConfirmationModal
              isOpen
              headerText="Replace tag on existing files before deleting the tag"
              noBtnText="Close"
              noBtnClick={this.toggleErrorMessage}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

export default AddEditTags;
