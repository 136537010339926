/* eslint-disable no-param-reassign */
import React from 'react';
import { Editor } from '@tinymce/tinymce-react';
import './ReadOnlyTinyMCE.scss';

const ReadOnlyTinyMCE = ({ value, content }) => (
  <Editor
    disabled
    tinymceScriptSrc="https://turmericp.liscio.me/tinymce/js/tinymce/tinymce.min.js"
    value={value || content}
    init={{
      autoresize_bottom_margin: 0,
      autoresize_overflow_padding: 0,
      menubar: false,
      paste_data_images: true,
      paste_retain_style_properties: 'all',
      paste_webkit_styles: 'all',
      paste_convert_word_fake_lists: false,
      link_assume_external_targets: true,
      // open embedded link in new tab
      link_target_list: [{ title: 'New window', value: '_blank' }],
      contextmenu: false,
      plugins: [
        'link',
        'autolink',
        'hr',
        'lists',
        'media',
        'advlist',
        'image',
        'quickbars',
        'autoresize',
      ],
      browser_spellcheck: true,
      toolbar: false,
      content_style: `@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700');
      body {
          font-family:Source Sans Pro, sans-serif;
          font-size:0.94rem;
          color: rgb(33, 43, 54);
          margin:0;
          padding-left: 5px !important;
        }

        #tinymce p {
          color: rgb(33, 43, 54);
          margin: 0px;
        }

        #tinymce * {
          text-indent: 0 !important;
        }

        .mce-item-table, .mce-item-table td, .mce-item-table tr {
          border: inherit !important;
          outline: inherit !important;
        }

        .tox:not(.tox-tinymce-inline).tox-tinymce--toolbar-bottom .tox-editor-header {
          border-top: none;
        }

        `,
      quickbars_selection_toolbar: false,
      toolbar_location: 'bottom',
      quickbars_insert_toolbar: false,
      quickbars_image_toolbar: false,
      elementpath: false,
      autoresize_on_init: true,
      branding: false,
      resize: true,
      min_height: 10,
      width: '100%',
      autoresize: true,
      images_file_types: 'jpg,png,jpeg',
      image_description: false,
      image_dimensions: false,
      image_title: false,
      a11y_advanced_options: true,
      object_resizing: true,
      forced_root_block: 'div',
      readonly: true,
      init_instance_callback: (editor) => {
        // editor.on('BeforeSetContent', (e) => {
        //   if (e.content.includes('img')) {
        //     const editImage = e.content;
        //     const imageString = editImage.slice(0, -2);
        //     const addtionalString =
        //       '" style="max-width:500px; max-height:210px" />';
        //     const newString = imageString.concat(addtionalString).toString();
        //     e.content = newString;
        //   }
        // });
        editor.on('GetContent', (e) => {
          if (e.content.includes('a')) {
            const editImage = e.content;
            const newString = editImage.replace(
              /<a/g,
              '<a style="text-decoration: underline"',
            );
            e.content = newString;
          }
        });
        editor.on('ObjectResized', (ev) => {
          ev.target.dataset.mceStyle =
            'style="max-width: none; max-height: none"';
        });
      },
      setup(editor) {
        editor.ui.registry.addContextMenu('useBrowserSpellcheck', {
          update() {
            return editor.selection.isCollapsed()
              ? ['useBrowserSpellcheck']
              : [];
          },
        });
      },
    }}
  />
);

export default ReadOnlyTinyMCE;
