import axios from 'axios';
import { ENCRYPTED_FIELD } from 'constants/constants';

export interface GetEncryptedFieldResponse {
  field_name: string;
  enc_value: string;
}

export async function getEncryptedField({
  recordType,
  recordId,
  fieldName,
}: {
  recordType: string;
  recordId: string | number;
  fieldName: string;
}): Promise<GetEncryptedFieldResponse> {
  const response = await axios.get<GetEncryptedFieldResponse>(
    `${ENCRYPTED_FIELD}/${recordType}/${recordId}/${fieldName}`,
  );

  return response?.data;
}

export async function updateEncryptedField({
  recordType,
  recordId,
  fieldName,
  newValue,
}: {
  recordType: string;
  recordId: string | number;
  fieldName: string;
  newValue: string;
}): Promise<any> {
  const response = await axios.patch(
    `${ENCRYPTED_FIELD}/${recordType}/${recordId}/${fieldName}`,
    { enc_value: newValue },
  );
  return response?.data;
}
