/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from 'react';
import PlacesAutocomplete, {
  geocodeByPlaceId,
} from 'react-places-autocomplete';
import './AddressAutoComplete.scss';

class AddressAutoComplete extends Component {
  constructor(props) {
    super(props);
    this.state = { gMap_loaded: true };
  }

  handleSelect = (address, placeid) => {
    if (placeid) {
      geocodeByPlaceId(placeid)
        .then((results) => {
          if (results.length) {
            const formatedaddress = {};
            const {
              addressLine1Name,
              cityName,
              stateName,
              countryName,
              zipName,
            } = this.props.fieldNames;
            let isStreetNumberExists = true;
            const placeDetails = results[0].address_components;
            placeDetails.map((each) => {
              if (each.types.indexOf('street_number') !== -1) {
                formatedaddress[addressLine1Name] = each.long_name;
                isStreetNumberExists = false;
              } else if (each.types.indexOf('route') !== -1) {
                if (formatedaddress[addressLine1Name]) {
                  formatedaddress[addressLine1Name] += ` ${each.long_name}`;
                } else {
                  const getStreetNumber = address.split(' ')[0];
                  if (isStreetNumberExists) {
                    formatedaddress[addressLine1Name] = getStreetNumber;
                    formatedaddress[addressLine1Name] += ` ${each.long_name}`;
                  } else {
                    formatedaddress[addressLine1Name] = each.long_name;
                  }
                }
              } else if (each.types.indexOf('locality') !== -1) {
                formatedaddress[cityName] = each.long_name;
              } else if (
                each.types.indexOf('administrative_area_level_1') !== -1
              ) {
                formatedaddress[stateName] = each.short_name;
              } else if (each.types.indexOf('country') !== -1) {
                if (each.short_name.toLowerCase() === 'us') {
                  formatedaddress[countryName] = each.short_name;
                } else {
                  formatedaddress[countryName] = each.long_name;
                }
              } else if (each.types.indexOf('postal_code') !== -1) {
                formatedaddress[zipName] = each.long_name;
              }
              return null;
            });
            this.props.handleSelect(formatedaddress);
          }
        })
        .catch((error) => error);
    }
  };

  render() {
    const { props } = this;
    return (
      <div>
        {this.state.gMap_loaded && (
          <PlacesAutocomplete
            value={this.props.value}
            onChange={(input) => this.props.onChange(input)}
            onSelect={this.handleSelect}
            googleCallbackName="initOne"
          >
            {({ getInputProps, suggestions, getSuggestionItemProps }) => (
              <div
                className={`${props.className} address-autocomplete${
                  props.error ? ' form-error' : ''
                }`}
              >
                <label htmlFor={props.name}>{props.label}</label>
                <div className="labelValue">
                  <input
                    {...getInputProps()}
                    className="form-control"
                    id={props.name}
                    disabled={props.disabled}
                    autoComplete="nope"
                  />
                  {suggestions.length ? (
                    <div className="autocomplete-dropdown-container">
                      {suggestions.map((suggestion) => {
                        const customClassName = suggestion.active
                          ? 'suggestion-item'
                          : 'suggestion-item';
                        // inline style for demonstration purpose
                        const style = suggestion.active
                          ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                          : { backgroundColor: '#ffffff', cursor: 'pointer' };
                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              className: customClassName,
                              style,
                            })}
                          >
                            <span>{suggestion.description}</span>
                          </div>
                        );
                      })}
                    </div>
                  ) : null}
                  <span className="error_label">{props.error}</span>
                </div>
              </div>
            )}
          </PlacesAutocomplete>
        )}
      </div>
    );
  }
}

export default AddressAutoComplete;
