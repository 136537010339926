/* eslint-disable react-hooks/exhaustive-deps */
import {
  Autocomplete,
  Box,
  Chip,
  FormControl,
  InputLabel,
  Popover,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import ContactSelector from 'common/ContactSelector/ContactSelector';
import { MouseEventHandler, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { SMSVaultDialogType } from './FormValidation';

interface IRecipient {
  contactId: string;
  contactName?: string;
  contactType: string;
  accountId: string;
  accountName?: string;
}

export default function ToFieldElement() {
  const theme = useTheme();
  const [contactList, setContactList] = useState<IRecipient[]>([]);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [contactOpen, setContactOpen] = useState(false);
  const {
    setValue,
    formState: { errors },
  } = useFormContext<SMSVaultDialogType>();

  useEffect(() => {
    setValue(
      'recipients',
      contactList.map((contact) => ({
        contactId: contact.contactId,
        contactType: contact.contactType,
        accountId: contact.accountId,
      })),
    );
  }, [contactList]);

  const openContactPopover: MouseEventHandler<HTMLDivElement> = (event) => {
    setAnchorEl(event.currentTarget as Element);
    setContactOpen(true);
  };

  const closeContactPopover = () => {
    setContactOpen(false);
  };

  const removeContactFromList = (contactId: string, accountId: string) => {
    const newList = contactList.filter(
      (item) => item.contactId !== contactId && item.accountId !== accountId,
    );
    setContactList(newList);
  };

  const handleContactChange = (
    contact: LegacyContact,
    accountId: string,
    accountName: string,
  ) => {
    setContactList((contacts) => [
      ...contacts,
      {
        contactId: contact.id as string,
        contactName: contact.label,
        contactType: contact.type,
        accountId,
        accountName,
      },
    ]);
  };

  return (
    <Stack>
      <InputLabel htmlFor="toField">
        <Typography variant="h5" sx={{ color: theme.palette.text.secondary }}>
          TO
        </Typography>
      </InputLabel>
      <FormControl sx={{ paddingBottom: '1rem' }}>
        <Autocomplete
          id="toField"
          options={contactList}
          multiple
          freeSolo
          value={contactList}
          disableClearable
          getOptionLabel={(option) =>
            `${(option as IRecipient).contactName} \u2022 ${
              (option as IRecipient).accountName
            }`
          }
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                {...getTagProps({ index })}
                label={`${option.contactName} \u2022 ${option.accountName}`}
                onDelete={() =>
                  removeContactFromList(option.contactId, option.accountId)
                }
              />
            ))
          }
          renderInput={(params) => (
            <Box onClick={openContactPopover}>
              <TextField
                {...params}
                variant="standard"
                error={Boolean(errors.recipients)}
                helperText={errors.recipients?.message}
              />
            </Box>
          )}
        />
        <Popover
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={contactOpen}
          onClose={closeContactPopover}
          sx={{ overflow: 'visible' }}
        >
          <Stack width="20rem" overflow="overlay">
            <ContactSelector
              onSubmit={handleContactChange}
              onClose={closeContactPopover}
              handleClose={closeContactPopover}
              handleOk={closeContactPopover}
            />
          </Stack>
        </Popover>
      </FormControl>
    </Stack>
  );
}
