import { Box } from '@mui/material';
import LiscioSpinner from 'ui/Loading/LiscioSpinner';

export const LoadingOverlay = () => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    height="500px"
    width="100%"
    role="alert"
    aria-busy="true"
  >
    <LiscioSpinner />
  </Box>
);
