import { Typography } from '@mui/material';
import { CenterWrapperBox } from 'components/Requests/components/Styles/Styles';
import { DASHBOARD_ROUTE } from 'constants/constants';
import { useLocation, useNavigate } from 'react-router-dom-latest';
import { Header } from 'ui/Header/Header';

export const BundleCompleteView = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleGoBack = () => {
    if (location.state) {
      navigate(-1);
    } else {
      navigate(DASHBOARD_ROUTE);
    }
  };

  return (
    <>
      <Header onBack={handleGoBack} title="Workflow Complete" />
      <CenterWrapperBox display="flex" flexDirection="column" gap="20px">
        <Typography
          align="center"
          variant="h4"
          fontWeight="bolder"
          component="div"
          mt={24}
        >
          Thank you for your submission!
        </Typography>
      </CenterWrapperBox>
    </>
  );
};
