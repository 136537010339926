/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-access-state-in-setstate */
import React, { Component } from 'react';
import Axios from 'axios';
import { Link } from 'react-router-dom-latest';
import { connect } from 'react-redux';
import {
  AlertMessage,
  generateFullName,
  getCpaPreferences,
  updateCountry,
  replaceUndefinedWithNull,
  isClient,
} from 'utilities/utils';
import {
  CONTACT,
  CHECK_PRIMARY_EMAIL,
  DEACTIVATE_CONTACT,
  REACTIVATE_CONTACT,
  SAVE_RELATIONSHIP,
  DELETE_CONTACT_RELATED_DATA,
  MIXPANEL_CREATE_CONTACT_EVENT,
  MIXPANEL_EDIT_CONTACT_EVENT,
  BUTTON_ICON_LOCATION_FRONT,
} from 'constants/constants';
import { NAME, EMAIL } from 'utilities/regularexpressions';
import InputField from 'common/InputField';
import Button from 'common/Button/Button';
import ConfirmationModal from 'common/ConfirmationModal';
import { SelectField } from 'common/CustomFields';
import { resetContactListData } from 'actions/AccountandContactListActions';
import { withRouter } from 'utilities/withRouterWrapper';
import { trackMixpanelEvent } from 'utilities/mixpanelfn';
import NewRelationShipHeader from 'components/Accounts/AccountCreation/NewRelationShipHeader';
import {
  selectComponentColors,
  getDefaultPreference,
  contactAddressFieldNames,
  enableAddPhoneNumberBtn,
  enableAddEmailBtn,
  enableAddAddressBtn,
  validatePhoneNumbers,
  validatEmailIds,
  filterContactStatusOptions,
} from './ContactUtilities';
import AddressSection from './AddressSection';
import MutilpleFieldComponent from './MutilpleFieldComponent';

class CreateContact extends Component {
  constructor(props) {
    super(props);

    this.state = {
      status: 'New',
      contact_phone: [{}],
      contact_email: [{}],
      contact_addresses: [{}],
      errors: {},
      showadditiondetails: false,
      isNewContact: true,
      formdata: {},
      deleteData: [],
      disableadditiondetails: false,
      disableEmailField: false,
      contactEditByAccount: '',
      allPreferences: {},
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { location } = nextProps;
    if (location.pathname === '/contact/new') {
      this.setState({
        isNewContact: true,
        contactId: '',
        status: 'New',
        contact_phone: [{}],
        contact_email: [{}],
        contact_addresses: [{}],
        errors: {},
        showadditiondetails: false,
        formdata: {},
        deleteData: [],
        disableadditiondetails: false,
        disableEmailField: false,
        contactEditByAccount: '',
        allPreferences: {},
        primary_email: '',
        first_name: '',
        last_name: '',
        middle_name: '',
        job_title: '',
        initialValues: {},
        communication_pref_type: '',
      });
    }
  }

  componentDidMount() {
    document.title = 'Create New Contact';
    // if (!isUserEmployee()) {
    //   this.props.navigate('/');
    // }
    const preferences = getCpaPreferences();
    this.setState({
      contact_phone: [
        { type: getDefaultPreference(preferences, 'phone_type') },
      ],
      contact_email: [
        { type: getDefaultPreference(preferences, 'email_type') },
      ],
      contact_addresses: [
        { type: getDefaultPreference(preferences, 'address_type') },
      ],
      allPreferences: filterContactStatusOptions(preferences, 'new'),
    });
    const contactId = window.location.href.split('edit/')[1];
    const { location } = this.props;
    if (isClient()) {
      const { accountId } = location.state;
      const status = location.state.contactStatus;
      this.setState({
        disableadditiondetails: true,
        contactEditByAccount: accountId,
        disableEmailField: status === 'active',
      });
    }
    if (contactId) {
      Axios.get(`${CONTACT}/${contactId}`).then((res) => {
        if (
          res.status === 200 &&
          res.data.status === 200 &&
          res.data.data &&
          res.data.data.length
        ) {
          const contactDetails = res.data.data[0];
          const {
            first_name,
            middle_name,
            last_name,
            primary_email,
            job_title,
            status,
            communication_pref_type,
          } = contactDetails.contact;
          // redirect inactive contacts to contact overview page
          if (
            status &&
            status !== null &&
            status.toLowerCase() === 'inactive'
          ) {
            this.props.navigate(`/contactdetails/${contactId}#details`);
          }
          const { contact_phone, contact_email, contact_address } =
            contactDetails;
          let showadditiondetails = false;
          if (
            contact_phone !== null ||
            contact_email !== null ||
            contact_address !== null ||
            communication_pref_type !== null
          ) {
            showadditiondetails = true;
          }
          const contactPhone =
            contactDetails.contact_phone &&
            contactDetails.contact_phone !== null &&
            contactDetails.contact_phone.map((each) => {
              const eachone = each;
              eachone.type = each.phone_type;
              if (each.phone_number.indexOf('+') < 0) {
                eachone.value = `+1${each.phone_number}`;
              } else {
                eachone.value = each.phone_number;
              }
              return eachone;
            });

          const contactEmail =
            contactDetails.contact_email &&
            contactDetails.contact_email !== null &&
            contactDetails.contact_email.map((each) => {
              const eachone = each;
              eachone.type = each.email_type;
              eachone.value = each.email;
              return eachone;
            });

          const contactAddress =
            contactDetails.contact_address &&
            contactDetails.contact_address !== null &&
            contactDetails.contact_address.map((each) => {
              const eachone = each;
              eachone.type = each.address_type;
              return eachone;
            });
          this.setState({
            contactId,
            first_name,
            middle_name,
            last_name,
            primary_email,
            job_title,
            status,
            communication_pref_type,
            showadditiondetails,
            isNewContact: false,
            contact_phone:
              contactPhone && contactPhone !== null && contactPhone.length
                ? contactPhone
                : this.state.contact_phone,
            contact_email:
              contactEmail && contactEmail !== null && contactEmail.length
                ? contactEmail
                : this.state.contact_email,
            contact_addresses:
              contactAddress && contactAddress !== null && contactAddress.length
                ? contactAddress
                : this.state.contact_addresses,
            allPreferences: filterContactStatusOptions(
              this.state.allPreferences,
              status,
              contactId,
            ),
            initialValues: contactDetails.contact,
          });
        } else {
          AlertMessage('error', res.data.message, 1000);
          this.props.navigate('/contacts');
        }
      });
    }
  }

  handleInputChange = (inputvalue, fieldname) => {
    let value = inputvalue;
    if (value && value.length === 0) {
      value = undefined;
    }

    this.setState(
      { [fieldname]: value && value.length ? value : undefined },
      () =>
        this.state.errors[fieldname] ? this.handleBlurChange(fieldname) : null,
    );
  };

  handleSelectChange = (fieldname, inputvalue) => {
    const { value } = inputvalue && inputvalue !== null ? inputvalue : {};
    this.setState({ [fieldname]: value });
  };

  handleInputChangeForMutipleFields = (
    arrayname,
    index,
    fieldname,
    inputvalue,
  ) => {
    if (fieldname === 'type') {
      const array = this.state[arrayname];
      const { value } = inputvalue && inputvalue !== null ? inputvalue : {};
      array[index][fieldname] = value;
      this.setState({ [arrayname]: array });
    }

    if (fieldname === 'is_texting_allowed') {
      let array = this.state[arrayname];
      const inputfieldvalue =
        inputvalue && inputvalue.target && inputvalue.target.value
          ? inputvalue.target.value
          : undefined;
      array = array.map((e) => ({ ...e, is_texting_allowed: false }));
      array[index][fieldname] = !(inputfieldvalue === 'true');
      this.setState({ [arrayname]: array });
    }

    if (
      arrayname === 'contact_addresses' &&
      (fieldname === 'state' || fieldname === 'type') &&
      typeof inputvalue === 'object'
    ) {
      const array = this.state[arrayname];
      const { value } = inputvalue && inputvalue !== null ? inputvalue : {};
      array[index][fieldname] = value;
      array[index] = updateCountry(
        array[index],
        contactAddressFieldNames,
        fieldname,
      );
      this.setState({ [arrayname]: array }, () => {
        if (
          this.state.errors &&
          this.state.errors.contact_addresses_error &&
          this.state.errors.contact_addresses_error[index] &&
          this.state.errors.contact_addresses_error[index][fieldname]
        ) {
          this.handleBlurChange();
        }
      });
    } else if (arrayname === 'contact_addresses') {
      const array = this.state[arrayname];
      array[index][fieldname] =
        inputvalue && inputvalue.length ? inputvalue : undefined;
      array[index] = updateCountry(
        array[index],
        contactAddressFieldNames,
        fieldname,
      );
      this.setState({ [arrayname]: array }, () => {
        if (
          this.state.errors &&
          this.state.errors.contact_addresses_error &&
          this.state.errors.contact_addresses_error[index] &&
          this.state.errors.contact_addresses_error[index][fieldname]
        ) {
          this.handleBlurChange();
        }
      });
    }

    const inputfieldvalue =
      inputvalue && inputvalue.length ? inputvalue : undefined;

    if (
      arrayname === 'contact_phone' &&
      (fieldname === 'value' || fieldname === 'phone_extension')
    ) {
      const array = this.state[arrayname];
      array[index][fieldname] = inputfieldvalue || undefined;
      this.setState({ [arrayname]: array }, () =>
        this.state.errors &&
        this.state.errors.contact_phone_error &&
        this.state.errors.contact_phone_error[index] &&
        this.state.errors.contact_phone_error[index][fieldname]
          ? this.handleBlurChange()
          : null,
      );
    }

    if (arrayname === 'contact_email' && fieldname === 'value') {
      const array = this.state[arrayname];
      array[index][fieldname] = inputvalue;
      this.setState({ [arrayname]: array }, () =>
        this.state.errors &&
        this.state.errors.contact_email_error &&
        this.state.errors.contact_email_error[index] &&
        this.state.errors.contact_email_error[index][fieldname]
          ? this.handleBlurChange()
          : null,
      );
    }
  };

  handleBlurChange = () => {
    const errors = {};
    if (
      this.state.primary_email &&
      this.state.primary_email !== null &&
      this.state.primary_email.length &&
      !EMAIL.test(this.state.primary_email)
    ) {
      errors.primary_email = 'Invalid Email';
    } else if (
      this.state.initialValues &&
      (this.state.initialValues.status.toLowerCase() !== 'new' ||
        (this.state.initialValues.status.toLowerCase() === 'new' &&
          this.state.initialValues.cpa_user_id !== undefined &&
          this.state.initialValues.cpa_user_id !== null)) &&
      !this.state.primary_email
    ) {
      errors.primary_email = 'Email is required';
    } else if (
      this.state.primary_email &&
      this.state.primary_email !== null &&
      this.state.primary_email.length &&
      this.state.errors.primary_email_exist
    ) {
      errors.primary_email_exist = this.state.errors.primary_email_exist;
    }

    if (this.state.errors.first_name && !this.state.first_name) {
      errors.first_name = 'Required';
    } else if (this.state.first_name && !NAME.test(this.state.first_name)) {
      errors.first_name = 'Enter vaild name';
    }

    if (this.state.errors.last_name && !this.state.last_name) {
      errors.last_name = 'Enter vaild name';
    } else if (this.state.last_name && !NAME.test(this.state.last_name)) {
      errors.last_name = 'Enter vaild name';
    }

    if (this.state.middle_name && !NAME.test(this.state.middle_name)) {
      errors.middle_name = 'Enter vaild name';
    }

    const contactPhoneErrors = validatePhoneNumbers(this.state.contact_phone);
    if (Object.keys(contactPhoneErrors).length) {
      errors.contact_phone_errors = contactPhoneErrors;
    }

    const contactEmailErrors = validatEmailIds(this.state.contact_email);
    if (Object.keys(contactEmailErrors).length) {
      errors.contact_email_errors = contactEmailErrors;
    }
    this.setState({ errors }, () =>
      !errors.primary_email ? this.validatePrimaryEmail() : null,
    );
  };

  validatePrimaryEmail = () => {
    if (this.state.primary_email) {
      let isEmailDifferent = true;
      if (
        this.state.primary_email &&
        this.state.initialValues &&
        this.state.initialValues.primary_email &&
        this.state.initialValues.primary_email.toLowerCase() ===
          this.state.primary_email.toLowerCase()
      ) {
        isEmailDifferent = false;
        const { errors } = this.state;
        delete errors.primary_email_exist;
        this.setState({ errors: { ...errors } });
      }
      if (isEmailDifferent) {
        Axios.post(CHECK_PRIMARY_EMAIL, {
          primary_email: this.state.primary_email.toLowerCase(),
        }).then((res) => {
          if (res.data.status === 200) {
            this.setState({
              errors: {
                ...this.state.errors,
                primary_email_exist: res.data.message
                  ? res.data.message
                  : 'Already used',
              },
            });
          } else {
            const { errors } = this.state;
            delete errors.primary_email_exist;
            this.setState({ errors: { ...errors } });
          }
        });
      }
    }
  };

  addNewField = (arrayname) => {
    const array = this.state[arrayname];
    let prefType = 'phone_type';
    if (arrayname === 'contact_email') {
      prefType = 'email_type';
    } else if (arrayname === 'contact_addresses') {
      prefType = 'address_type';
    }
    array.push({
      type: getDefaultPreference(this.state.allPreferences, prefType),
    });
    this.setState({ [arrayname]: array });
  };

  deleteField = (arrayname, index) => {
    let array = this.state[arrayname];
    let { errors, deleteData } = this.state;
    let deleteNameProperty = arrayname;
    let prefType = 'phone_type';
    if (arrayname === 'contact_email') {
      prefType = 'email_type';
    } else if (arrayname === 'contact_addresses') {
      prefType = 'address_type';
      deleteNameProperty = 'contact_address';
    }
    if (array[index].id) {
      deleteData = [
        ...deleteData,
        { [deleteNameProperty]: true, id: array[index].id },
      ];
    }
    array = array.filter((e, key) => index !== key);
    if (!array.length) {
      array.push({
        type: getDefaultPreference(this.state.allPreferences, prefType),
      });
    }

    // for removing errors
    if (prefType === 'phone_type') {
      const { contact_phone_errors: Errors } = errors;
      if (Errors) delete Errors[index];
      errors = { ...errors, contact_phone_errors: Errors };
    }
    if (prefType === 'email_type') {
      const { contact_email_errors: Errors } = errors;
      if (Errors) delete Errors[index];
      errors = { ...errors, contact_email_errors: Errors };
    }
    if (prefType === 'contact_addresses') {
      const { contact_addresses_error: Errors } = errors;
      if (Errors) delete Errors[index];
      errors = { ...errors, contact_addresses_error: Errors };
    }

    this.setState({
      [arrayname]: array,
      errors,
      deleteData,
    });
  };

  handleAddressSelect = (index, formmattedaddress) => {
    const { contact_addresses } = this.state;
    const { type } = contact_addresses[index];
    contact_addresses[index] = formmattedaddress;
    contact_addresses[index].type = type;
    this.setState({ contact_addresses }, () =>
      this.state.errors.contact_addresses_error
        ? this.handleBlurChange()
        : null,
    );
  };

  showAdditionDetails = () => {
    this.setState({ showadditiondetails: !this.state.showadditiondetails });
  };

  closeDeactivationModal = () => {
    this.setState({ deactivationModal: false });
  };

  handleCancelBtn = () => {
    const { isNewContact } = this.state;
    trackMixpanelEvent(
      isNewContact
        ? MIXPANEL_CREATE_CONTACT_EVENT
        : MIXPANEL_EDIT_CONTACT_EVENT,
      { state: 'cancelled' },
    );
    if (this.props.createRelationship && this.props.accountId) {
      this.props.handleCloseModal();
    } else {
      this.props.navigate(-1);
    }
  };

  handleCheckbox = (fieldname) => {
    this.setState({
      formdata: {
        ...this.state.formdata,
        [fieldname]: !this.state.formdata[fieldname],
      },
    });
  };

  handleRelationShipSelect = (fieldname, inputvalue) => {
    const { value } = inputvalue && inputvalue !== null ? inputvalue : {};
    this.setState({ formdata: { ...this.state.formdata, [fieldname]: value } });
  };

  handleSubmit = () => {
    const { state, isNewContact } = this;
    const errors = {};
    const formmatteddata = {
      contact_phone: [],
      contact_email: [],
      contact_addresses: [],
    };
    if (
      this.state.primary_email &&
      this.state.primary_email !== null &&
      this.state.primary_email.length &&
      !EMAIL.test(this.state.primary_email)
    ) {
      errors.primary_email = 'Invalid Email';
    } else if (
      this.state.initialValues &&
      (this.state.initialValues.status.toLowerCase() !== 'new' ||
        (this.state.initialValues.status.toLowerCase() === 'new' &&
          this.state.initialValues.cpa_user_id !== undefined &&
          this.state.initialValues.cpa_user_id !== null)) &&
      !this.state.primary_email
    ) {
      errors.primary_email = 'Email is required';
    } else if (
      this.state.primary_email &&
      this.state.primary_email !== null &&
      this.state.primary_email.length &&
      this.state.errors.primary_email_exist
    ) {
      errors.primary_email_exist = this.state.errors.primary_email_exist;
    }

    if (!this.state.first_name) {
      errors.first_name = 'Required';
    } else if (this.state.first_name && !NAME.test(this.state.first_name)) {
      errors.first_name = 'Enter vaild name';
    }

    if (!this.state.last_name) {
      errors.last_name = 'Required';
    } else if (this.state.last_name && !NAME.test(this.state.last_name)) {
      errors.last_name = 'Enter vaild name';
    }

    if (this.state.middle_name && !NAME.test(this.state.middle_name)) {
      errors.middle_name = 'Enter vaild name';
    }

    const contactPhoneErrors = validatePhoneNumbers(this.state.contact_phone);
    if (Object.keys(contactPhoneErrors).length) {
      errors.contact_phone_errors = contactPhoneErrors;
    }

    const contactEmailErrors = validatEmailIds(this.state.contact_email);
    if (Object.keys(contactEmailErrors).length) {
      errors.contact_email_errors = contactEmailErrors;
    }

    if (state.communication_pref_type) {
      if (
        (state.communication_pref_type.toLowerCase() === 'text' ||
          state.communication_pref_type.toLowerCase() === 'phone') &&
        enableAddPhoneNumberBtn(state.contact_phone)
      ) {
        errors.communication_pref_type = 'no phone numbers';
      } else if (
        state.communication_pref_type.toLowerCase() === 'email' &&
        enableAddEmailBtn(state.contact_email) &&
        !state.primary_email
      ) {
        errors.communication_pref_type = 'no emails';
      }
    }

    if (
      this.props.createRelationship &&
      !this.state.formdata.relationship_type
    ) {
      errors.relationship_type = 'Required';
    }

    this.setState({ errors });
    if (!Object.keys(errors).length) {
      const {
        first_name,
        last_name,
        middle_name,
        job_title,
        primary_email,
        status,
        communication_pref_type,
        deleteData,
        contact_phone,
        contact_email,
        contact_addresses,
      } = this.state;
      const contactPhone = [];
      const contactEmail = [];
      const contactAddresses = [];
      contact_phone.map((each) => {
        if (each.value) {
          contactPhone.push(each);
        } else if (each.id) {
          deleteData.push({ id: each.id, contact_phone: true });
        }
        return each;
      });
      contact_email.map((each) => {
        if (each.value) {
          contactEmail.push(each);
        } else if (each.id) {
          deleteData.push({ id: each.id, contact_email: true });
        }
        return each;
      });
      contact_addresses.map((each) => {
        if (
          each[contactAddressFieldNames.addressLine1Name] ||
          each[contactAddressFieldNames.addressLine2Name] ||
          each[contactAddressFieldNames.cityName] ||
          each[contactAddressFieldNames.stateName] ||
          each[contactAddressFieldNames.countryName] ||
          each[contactAddressFieldNames.zipName]
        ) {
          contactAddresses.push(each);
        } else if (each.id) {
          deleteData.push({ id: each.id, contact_address: true });
        }
        return each;
      });
      const datatosend = replaceUndefinedWithNull({
        ...formmatteddata,
        first_name,
        last_name,
        middle_name,
        job_title,
        primary_email,
        status,
        contact_phone: contactPhone,
        contact_email: contactEmail,
        contact_addresses: contactAddresses,
        communication_pref_type,
      });
      if (this.state.disableadditiondetails) {
        datatosend.account_id = this.state.contactEditByAccount;
      }
      // TODO: get id using react-router-dom params
      const { contactId } = this.state;
      let response = null;
      if (
        contactId &&
        status.toLowerCase() === 'inactive' &&
        !this.state.deactivationModal
      ) {
        this.setState({ deactivationModal: !this.state.deactivationModal });
      } else if (
        contactId &&
        status.toLowerCase() === 'inactive' &&
        this.state.deactivationModal
      ) {
        this.setState({ deactivationModal: !this.state.deactivationModal });
        response = Axios.put(`${DEACTIVATE_CONTACT}/${contactId}`);
      } else if (
        contactId &&
        status.toLowerCase() !== 'inactive' &&
        this.state.initialValues &&
        this.state.initialValues.status &&
        this.state.initialValues.status.toLowerCase() === 'inactive'
      ) {
        response = Axios.put(`${REACTIVATE_CONTACT}/${contactId}`);
      } else if (contactId) {
        response = Axios.put(`${CONTACT}/${contactId}`, datatosend);
      } else {
        response = Axios.post(CONTACT, datatosend);
      }

      if (response !== null) {
        response
          .then((res) => {
            if (res.status === 204 || res.status === 200) {
              if (
                res.data &&
                res.data.message &&
                (res.data.status === 422 || res.data.status === 401)
              ) {
                trackMixpanelEvent(
                  isNewContact
                    ? MIXPANEL_CREATE_CONTACT_EVENT
                    : MIXPANEL_EDIT_CONTACT_EVENT,
                  {
                    state: 'error',
                    message: res.data.message,
                  },
                );
                AlertMessage('error', res.data.message, 3000);
              } else {
                if (res.data.contact_id || this.state.contactId) {
                  if (this.props.createRelationship) {
                    this.saveRelationShip(res.data.contact_id);
                  } else {
                    this.deleteAdditionalContactDetails(
                      deleteData,
                      res.data.contact_id || this.state.contactId,
                    );
                    // this.props.navigate('/contactdetails/' + (res.data.contact_id || this.state.contactId));
                  }
                }
                if (!this.state.contactId) {
                  this.props.resetContactListData();
                }
                AlertMessage('success', res.data.message, 3000);
                trackMixpanelEvent(
                  isNewContact
                    ? MIXPANEL_CREATE_CONTACT_EVENT
                    : MIXPANEL_EDIT_CONTACT_EVENT,
                  { state: isNewContact ? 'created' : 'edited' },
                );
              }
            } else {
              AlertMessage('error', res.data.message, 3000);
              trackMixpanelEvent(
                isNewContact
                  ? MIXPANEL_CREATE_CONTACT_EVENT
                  : MIXPANEL_EDIT_CONTACT_EVENT,
                {
                  state: 'error',
                  message: res.data.message,
                },
              );
            }
          })
          .catch((err) => {
            trackMixpanelEvent(
              isNewContact
                ? MIXPANEL_CREATE_CONTACT_EVENT
                : MIXPANEL_EDIT_CONTACT_EVENT,
              { state: 'error' },
            );
            alert(err);
          });
      }
    }
  };

  saveRelationShip = (contactId) => {
    const relationShipData = {
      account_id: this.props.accountId,
      contact_id: contactId,
      relationship_type: this.state.formdata.relationship_type,
      is_account_owner: this.state.formdata.is_account_owner,
      is_billing_contact: this.state.formdata.is_billing_contact,
    };
    Axios.post(SAVE_RELATIONSHIP, relationShipData)
      .then((res) => {
        AlertMessage('success', res.data.message, 3000);
        this.props.handleCloseModal();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  deleteAdditionalContactDetails = (input, contact_id) => {
    const deleteData = input;
    if (deleteData.length) {
      deleteData.map((each, key) => {
        Axios.post(DELETE_CONTACT_RELATED_DATA, each)
          .then(() => {
            if (deleteData.length === key + 1) {
              this.props.navigate(`/contactdetails/${contact_id}#details`);
            }
          })
          .catch(() => {
            if (deleteData.length === key + 1) {
              this.props.navigate(`/contactdetails/${contact_id}#details`);
            }
          });
        return each;
      });
    } else if (this.state.disableadditiondetails) {
      this.props.navigate(-1);
    } else {
      this.props.navigate(`/contactdetails/${contact_id}#details`);
    }
  };

  renderAdditionDetails = (
    contactAddresses,
    allPreferences,
    errors,
    disabled,
  ) => (
    <div className="collapse show">
      <MutilpleFieldComponent
        name="contact_phone"
        data={this.state.contact_phone}
        onChange={(key, value, input) =>
          this.handleInputChangeForMutipleFields(
            'contact_phone',
            key,
            value,
            input,
          )
        }
        addNewField={() => this.addNewField('contact_phone')}
        deleteField={(index) => this.deleteField('contact_phone', index)}
        onBlur={this.handleBlurChange}
        label="phone"
        addButtonLabel="+ Add another number"
        enableAddButton={enableAddPhoneNumberBtn(this.state.contact_phone)}
        options={allPreferences.phone_type}
        errors={errors.contact_phone_errors}
        disabled={disabled}
      />
      <MutilpleFieldComponent
        name="contact_email"
        data={this.state.contact_email}
        onChange={(key, value, input) =>
          this.handleInputChangeForMutipleFields(
            'contact_email',
            key,
            value,
            input,
          )
        }
        addNewField={() => this.addNewField('contact_email')}
        deleteField={(index) => this.deleteField('contact_email', index)}
        onBlur={this.handleBlurChange}
        label="email"
        addButtonLabel="+ Add another email"
        enableAddButton={enableAddEmailBtn(this.state.contact_email)}
        options={allPreferences.email_type}
        errors={errors.contact_email_errors}
        disabled={disabled}
      />
      <div className="row">
        <SelectField
          id="communication_pref_type"
          name="communication_pref_type"
          label="communication preference"
          options={allPreferences.communication_preference}
          styles={selectComponentColors(errors.communication_pref_type)}
          handleChange={(input) =>
            this.handleSelectChange('communication_pref_type', input)
          }
          defaultValue={undefined}
          disabled={disabled}
          value={{
            value: this.state.communication_pref_type,
            label: this.state.communication_pref_type,
          }}
          className="col-5 form-group"
          error={
            this.state.errors
              ? this.state.errors.communication_pref_type
              : undefined
          }
        />
      </div>
      <AddressSection
        name="contact_addresses"
        data={this.state.contact_addresses}
        onChange={(key, fieldname, input) =>
          this.handleInputChangeForMutipleFields(
            'contact_addresses',
            key,
            fieldname,
            input,
          )
        }
        addNewField={() => this.addNewField('contact_addresses')}
        deleteField={(index) => this.deleteField('contact_addresses', index)}
        handleAddressSelect={this.handleAddressSelect}
        fieldNames={contactAddressFieldNames}
        onBlur={() => {}}
        errors={
          errors.contact_addresses_error ? errors.contact_addresses_error : {}
        }
        options={allPreferences.address_type}
        disabled={disabled}
        enableAddButton={enableAddAddressBtn(contactAddresses)}
      />
    </div>
  );

  render() {
    const {
      contact_addresses,
      allPreferences,
      isNewContact,
      contactId,
      initialValues,
      disableadditiondetails,
      disableEmailField,
    } = this.state;
    const errors = this.state.errors ? this.state.errors : {};
    const disabled =
      this.state.status && this.state.status.toLowerCase() === 'inactive';
    return (
      <div
        className="NewContact"
        id={this.props.createRelationship ? '' : ''}
        data-testid="contactForm"
      >
        {this.props.createRelationship ? null : (
          <header>
            <ul className="breadcrumb">
              {this.state.contactId
                ? [
                    <li
                      className="breadcrumb-item"
                      key="navigate-to-contact-details"
                    >
                      <Link to={`/contactdetails/${contactId}#details`}>
                        {generateFullName(initialValues)}
                      </Link>
                    </li>,
                    <li className="breadcrumb-item" key="Update contact">
                      Update contact
                    </li>,
                  ]
                : [
                    <li className="breadcrumb-item" key="navigate-to-contacts">
                      <Link to="/contacts">All contacts</Link>
                    </li>,
                    <li className="breadcrumb-item" key="create new contact">
                      Create new contact
                    </li>,
                  ]}
            </ul>
          </header>
        )}
        <div
          className={
            this.props.createRelationship
              ? ''
              : 'center-wrapper col-lg-10 col-sm-12 col-md-12 m-auto'
          }
        >
          {this.props.createRelationship ? (
            <NewRelationShipHeader
              preferences={allPreferences}
              data={{
                ...this.state,
                preferences: allPreferences,
                relationFor: 'contact',
              }}
              handleSelect={this.handleRelationShipSelect}
              handleCheckbox={this.handleCheckbox}
            />
          ) : null}
          <section>
            {!this.props.createRelationship ? (
              <div className="row align-items-center no-gutters mb-3 pb-3">
                <div className="section-title col">
                  <h6 role="presentation" className="stepBacklink">
                    <a onClick={this.handleCancelBtn} data-testid="contact__back_btn">
                      <i className="icon-left-arrow" /> Back
                    </a>
                  </h6>
                </div>
              </div>
            ) : null}
            <div className="col-container mb-4 section-title">
              <h5>
                Contact Information{' '}
                <span className="text-light small font-weight-normal">
                  Required
                </span>
              </h5>
            </div>
            <form action="" autoComplete="nope">
              <div className="form-group row">
                <InputField
                  onChange={this.handleInputChange}
                  onBlur={this.handleBlurChange}
                  name="first_name"
                  label="First Name *"
                  type="text"
                  value={this.state.first_name}
                  error={errors.first_name}
                  className="col-sm-12 col-lg-4 col-md-12"
                  disabled={disabled}
                  testid="contact__first_name"
                />
                <InputField
                  onChange={this.handleInputChange}
                  onBlur={this.handleBlurChange}
                  name="middle_name"
                  label="Middle Name"
                  type="text"
                  value={this.state.middle_name}
                  error={errors.middle_name}
                  className="col-sm-12 col-lg-4 col-md-12"
                  disabled={disabled}
                  testid="contact__middle_name"
                />
                <InputField
                  onChange={this.handleInputChange}
                  onBlur={this.handleBlurChange}
                  name="last_name"
                  label="Last Name *"
                  type="text"
                  value={this.state.last_name}
                  error={errors.last_name}
                  className="col-sm-12 col-lg-4 col-md-12"
                  disabled={disabled}
                  testid="contact__last_name"
                />
              </div>
              <div className="form-group row">
                <InputField
                  onChange={this.handleInputChange}
                  onBlur={this.handleBlurChange}
                  name="job_title"
                  label="job title"
                  type="text"
                  value={this.state.job_title}
                  className="col-sm-12 col-lg-4 col-md-12"
                  disabled={disabled || disableadditiondetails}
                  testid="contact__job_title"
                />
                <InputField
                  onChange={this.handleInputChange}
                  onBlur={this.handleBlurChange}
                  name="primary_email"
                  label="primary email"
                  type="email"
                  value={this.state.primary_email}
                  error={errors.primary_email || errors.primary_email_exist}
                  className="col-sm-12 col-lg-4 col-md-12"
                  disabled={disabled || disableEmailField}
                  testid="contact__primary_email"
                />
                <InputField
                  onChange={this.handleInputChange}
                  onBlur={this.handleBlurChange}
                  name="status"
                  label="Status"
                  type="text"
                  value={this.state.status}
                  className="col-sm-12 col-lg-4 col-md-12"
                  disabled
                  testid="contact__status"
                />
              </div>
              {disableadditiondetails ? null : (
                <div>
                  <br />
                  <hr className="m-0" />
                  <div
                    className={
                      this.state.showadditiondetails
                        ? 'row no-gutters justify-content-between align-items-center section-title collapseHead collapseHead--open'
                        : 'row no-gutters justify-content-between align-items-center section-title collapseHead'
                    }
                  >
                    {/* <h5 className="d-inline-block text-small">Additional details <span className="text-light small font-weight-normal ml-4">Optional</span></h5> */}
                    <h5>
                      Additional details{' '}
                      <span className="text-light small font-weight-normal">
                        Optional
                      </span>
                    </h5>
                    <div className="text-right">
                      <Button
                        variant="ghost"
                        icon={
                          this.state.showadditiondetails
                            ? 'uparrow'
                            : 'downarrow'
                        }
                        onClick={this.showAdditionDetails}
                        iconLocation={BUTTON_ICON_LOCATION_FRONT}
                        data-testid="contact__additional_details_btn"
                      />
                    </div>
                  </div>
                  {this.state.showadditiondetails
                    ? this.renderAdditionDetails(
                        contact_addresses,
                        allPreferences,
                        errors,
                        disabled,
                      )
                    : null}
                </div>
              )}
              <div
                className={`row no-gutters formbtn justify-content-end pt-4 mb-5 mt-0 ${
                  disableadditiondetails ? '' : 'formbtn--border'
                }`}
              >
                <div className="btn-wrap btn--leftspace d-flex">
                  <div style={{ marginRight: '12px' }}>
                    <Button
                      variant="secondary"
                      label="Cancel"
                      onClick={this.handleCancelBtn}
                      data-testid="contact__cancel_btn"
                    />
                  </div>
                  <Button
                    variant="primary"
                    label={isNewContact ? 'Create Contact' : 'Update Contact'}
                    onClick={this.handleSubmit}
                    data-testid="contact__submit_btn"
                  />
                </div>
              </div>
            </form>
          </section>
          {this.state.deactivationModal ? (
            <ConfirmationModal
              isOpen
              messageText="Attention: The contact deactivation process will close all open tasks related. Proceed?"
              yesBtnText="Proceed"
              noBtnText="Cancel"
              yesBtnClick={this.handleSubmit}
              noBtnClick={this.closeDeactivationModal}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  resetContactListData: () => dispatch(resetContactListData()),
});

export default withRouter(connect(null, mapDispatchToProps)(CreateContact));
