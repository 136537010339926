import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import styled from '@emotion/styled';
import { Button, Typography } from '@mui/material';
import { DATE_RANGE } from 'constants/constants';

const DateButton = styled(Button)(() => ({
  height: 40,
  fontWeight: 600,
  fontSize: '0.87rem',
}));

const ActivityDateFilter = styled.div`
  position: relative;
  .react-datepicker {
    border: 0px;
  }
  .react-datepicker-wrapper {
    width: 100%;
    max-width: 180px;
    margin-right: 6px;
  }
  .react-datepicker-wrapper + .react-datepicker-wrapper {
    margin-left: 6px;
    margin-right: 0px;
  }
  .Activityadd-date {
    display: flex;
    justify-content: space-evenly;
  }
  .Activity-bottomdates p {
    margin-bottom: 10px;
    font-size: 12px;
    color: #00aecc;
  }
  .Activity-bottomdates span {
    font-size: 12px;
  }
  .DatePickerBottom {
    position: absolute;
    top: 51px;
    border: 1px solid #aeaeae;
    border-radius: 7px;
    z-index: 9;
  }
  .Activity-bottomdates {
    padding: 10px;
    border-top: 1px solid #333;
    background-color: #fff;
    border-radius: 0 0 7px 7px;
  }
  .datePickerBackdropOff {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    background-color: transparent;
    -webkit-tap-highlight-color: transparent;
    z-index: -1;
  }
  .datePickerBackdrop {
    z-index: 1;
  }
  .react-datepicker__input-container input {
    width: 100%;
  }
  .Activitydonebutton {
    width: 100%;
    color: #fff;
    background-color: #00aecc;
    box-shadow: none;
    border-color: #00aecc;
    margin-top: 15px;
    font-size: 14px;
    font-weight: normal;
    padding: 10px;
    height: auto;
    line-height: 1;
    &:hover {
      background-color: #00aecc;
      box-shadow: none;
      border-color: #00aecc;
      color: #fff;
    }
  }
  .react-datepicker__input-container input {
    background-color: #ffffff;
    border: 2px solid #dfe3e8;
  }
  .react-datepicker__close-icon::after {
    background-color: #dfe3e8;
  }
`;

const FilterByDate = ({
  selectedFromDate,
  selectedToDate,
  setSelectedFromDate,
  setSelectedToDate,
}) => {
  const [toggleCalender, setToggleCalender] = useState(false);
  const [startDat, setStartDat] = useState(null);
  const [endDat, setEndDat] = useState(null);
  const [dateKeyword, setDateKeyword] = useState('Start');
  const [toggleForStartDate, setToggleForStartDate] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const clickHandler = ({ target }) => {
      const container = document.getElementById('DatePickerBottom');
      if (
        container.contains(target) ||
        target.className === 'react-datepicker__close-icon' ||
        target.className.includes('react-datepicker__day--outside-month') ||
        target.id === 'dateRangeFilter'
      )
        return;
      setToggleCalender(false);
      handleClearCalendar();
    };
    document.addEventListener('click', clickHandler);
    // these functions clean up the event listeners
    return () => document.removeEventListener('click', clickHandler);
  });

  function handleClearCalendar() {
    setStartDat(null);
    setEndDat(null);
    setDateKeyword('Start');
    setToggleForStartDate(false);
  }

  function handleCalender() {
    setToggleCalender(!toggleCalender);
  }

  function handleDone() {
    setSelectedFromDate(moment(startDat).format('MM-DD-YYYY'));
    setSelectedToDate(moment(endDat).format('MM-DD-YYYY'));
    handleCalender();
    handleClearCalendar();
  }

  function handleCalenderSearch() {
    const err = {};
    if (startDat && !endDat) {
      err.date = 'Select End Date';
      setErrors(err);
    } else if (!startDat && endDat) {
      err.date = 'Select Start Date';
      setErrors(err);
    } else if (!startDat && !endDat) {
      err.date = 'Select Dates';
      setErrors(err);
    } else {
      handleDone();
    }
  }

  function handleChangeDate(date, clearDates = null) {
    if (!startDat && !toggleForStartDate && !clearDates) {
      setStartDat(date);
      setDateKeyword('End');
      setErrors({});
    } else if (startDat && endDat && !toggleForStartDate && !clearDates) {
      setStartDat(date);
      setEndDat(null);
      setToggleForStartDate(true);
      setDateKeyword('End');
      setErrors({});
    } else if (clearDates) {
      clearDates(null);
    } else {
      setEndDat(date);
      setToggleForStartDate(false);
      setDateKeyword('Start');
      setErrors({});
    }
  }

  return (
    <ActivityDateFilter className="ActivityDateFilter">
      <DateButton
        id="dateRangeFilter"
        onClick={handleCalender}
        variant={selectedFromDate && selectedToDate ? 'contained' : 'outlined'}
      >
        {DATE_RANGE}
      </DateButton>
      <div
        className={`
        datePickerBackdropOff${toggleCalender ? ' datePickerBackdrop' : ''}`}
      />
      <div className="DatePickerBottom" id="DatePickerBottom">
        {toggleCalender && (
          <>
            <DatePicker
              onChange={(date) => handleChangeDate(date)}
              selectsStart
              selectsEnd
              startDate={startDat}
              endDate={endDat}
              dateFormat="MM/dd/yyyy"
              minDate={startDat}
              maxDate={new Date()}
              inline
            />
            <div className="Activity-bottomdates">
              <Typography component="p"> Add {dateKeyword} Date</Typography>
              <div className="Activityadd-date">
                <DatePicker
                  onChange={(date) => handleChangeDate(date, setStartDat)}
                  selected={startDat}
                  dateFormat="MM/dd/yyyy"
                  disabled
                  isClearable
                />
                <DatePicker
                  onChange={(date) => handleChangeDate(date, setEndDat)}
                  selected={endDat}
                  dateFormat="MM/dd/yyyy"
                  disabled
                  isClearable
                />
              </div>
              {errors.date && (
                <Typography
                  component="span"
                  sx={{
                    color: '#de3618',
                  }}
                >
                  {errors.date}
                </Typography>
              )}
              <DateButton
                id="doneButton"
                className="Activitydonebutton"
                onClick={handleCalenderSearch}
              >
                DONE
              </DateButton>
            </div>
          </>
        )}
      </div>
    </ActivityDateFilter>
  );
};

export default FilterByDate;
