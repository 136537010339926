import axios from 'axios';
import {
  MESSAGES_API_PATH,
  GET_MESSAGES_OF_ACCOUNT,
  GET_MESSAGES_OF_CONTACT,
} from 'constants/constants';

export const fetchAllMessages = (data, page, filtersSelected = '') => ({
  type: 'FETCH_MESSAGE_SUCCESS',
  data,
  page,
  filtersSelected,
});

export const fetchUserMessages = (data, page) => ({
  type: 'FETCH_USER_MESSAGE_SUCCESS',
  data,
  page,
});

export const addNewMessage = (data) => ({
  type: 'ADD_NEW_MESSAGE',
  data,
});

export const discardDraft = (data) => ({
  type: 'DISCARD_DRAFT',
  data,
});

export const archiveThread = (data) => ({
  type: 'ARCHIVE_THREAD',
  data,
});

export const receivedNotification = (data) => ({
  type: 'RECEIVED_NOTIFICATION',
  data,
});

export const receiveThreadCount = (data) => ({
  type: 'RECEIVE_THREAD_COUNT',
  data,
});

export const handleMessageFilters = (data) => ({
  type: 'HANDLE_MSG_FILTERS',
  data,
});

export const userMessageFilters = (data) => ({
  type: 'USER_MSG_FILTERS',
  data,
});

export const updatePinnedCount = (data) => ({
  type: 'UPDATE_PINNED_COUNT',
  data,
});

const updateList = (listData, selectedData, filter) => {
  if (selectedData.length) {
    listData[filter].map((each) => {
      const eachone = each;
      eachone.checked = false;
      selectedData.map((e) => {
        if (e.thread_id === each.thread_id) {
          eachone.checked = true;
        }
        return e;
      });
      return eachone;
    });
  }
  return listData;
};

export const fetchMessages =
  (
    component,
    // eslint-disable-next-line default-param-last
    location = '',
    per_page,
    page,
    apiPath = '',
    tab = '',
    loader = '',
  ) =>
  (dispatch) => {
    if (location.pathname === '/' || location.pathname === '/dashboard') {
      // component.setState({ inboxload: true });
    } else if (loader !== 'noloader') {
      component.setState({ loading: true });
    }
    if (per_page && page) {
      const tabb = tab === '' ? 'inbox' : tab;
      const request = axios.get(
        `${MESSAGES_API_PATH}?per_page=${per_page}&tab=${tabb}&page=${page}${apiPath}`,
      );
      request.then((response) => {
        if (response.status === 200) {
          component.setState({
            loading: false,
            pagination: page - 1,
            dataLoaded: true,
          });
          let messageList = response.data.data;
          if (component.props.location.pathname === '/all_messages') {
            const { selectedCheckbox, filter } = component.state;
            messageList = updateList(messageList, selectedCheckbox, filter);
          }
          dispatch(fetchAllMessages(messageList, page, apiPath));
          if (component.props.location.pathname === '/all_messages') {
            setTimeout(() => {
              component.areAllSelected();
            }, 100); // written here again as need to be executed after dispatch.
          }
          localStorage.setItem(
            'unread_thread_count',
            messageList.unread_thread_count,
          );
        }
      });
    } else {
      const request = axios.get(MESSAGES_API_PATH);
      request.then((response) => {
        component.setState({ loading: false });
        if (response.status === 200) {
          dispatch(fetchAllMessages(response.data.data));
          localStorage.setItem(
            'unread_thread_count',
            response.data.data.unread_thread_count,
          );
        }
      });
    }
    // component.setState({ loading: true });
  };

export const fetchAccountMessages =
  (component, id, per_page, page, apiPath = '') =>
  (dispatch) => {
    component.setState({ loading: true });
    const request = axios.get(
      `${GET_MESSAGES_OF_ACCOUNT}/${id}?per_page=${per_page}&page=${page}${apiPath}`,
    );
    request.then((response) => {
      if (response.status === 200) {
        component.setState({ loading: false, dataLoaded: true });
        dispatch(fetchUserMessages(response.data.data));
      }
    });
  };

export const fetchContactMessages =
  (component, id, per_page, page, apiPath = '') =>
  (dispatch) => {
    component.setState({ loading: true });
    const request = axios.get(
      `${GET_MESSAGES_OF_CONTACT}/${id}?per_page=${per_page}&page=${page}${apiPath}`,
    );
    request.then((response) => {
      if (response.status === 200) {
        component.setState({ loading: false, dataLoaded: true });
        dispatch(fetchUserMessages(response.data.data));
      }
    });
  };
