import React from 'react';
import styled from '@emotion/styled';
import BillingFilterButtons from './BillingFilterButtons';
import BillingFilterSearch from './BillingFilterSearch';
import FiltersSelected from './FiltersSelected';

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .BillingFilter__Left {
    display: flex;
  }

  .BillingFilter__Right {
    > label {
      margin-right: 16px;
    }
    align-items: baseline;
  }

  .filter-clear-button {
    color: #919eab;
    background-color: transparent;
    border: none;
    font-size: 0.87rem;
    &:hover {
      color: #00aecc;
    }
    svg {
      margin-right: 0.2rem;
    }
  }

  .selected-filters-bar .filter-clear-button {
    margin: 0;
    padding: 0 0 0 0.2rem;
    line-height: 0.1;
    svg {
      margin-right: 0;
      &:hover {
        color: #00687a;
      }
    }
    &:hover {
      color: #00687a;
    }
  }
`;

const BillingFilter = (props) => {
  const {
    contactId,
    onSelectFilter,
    selected,
    onClearFilter,
    options,
    activeFiltersCount,
    textSearch,
    clearSearch,
    onTextSearch,
    activeTab,
    isContactView,
  } = props;

  return (
    <StyledDiv className="BillingFilter">
      <div className="BillingFilter__Left filter-wrapper">
        <BillingFilterSearch
          contactId={contactId}
          onSelectFilter={onSelectFilter}
          textSearch={textSearch}
          clearSearch={clearSearch}
          onTextSearch={onTextSearch}
          activeTab={activeTab}
        />
        <BillingFilterButtons
          contactId={contactId}
          options={options}
          selected={selected}
          onSelectFilter={onSelectFilter}
          onClearFilter={onClearFilter}
          activeFiltersCount={activeFiltersCount}
          filterBGClicked={props.filterBGClicked}
          isContactView={isContactView}
        />
      </div>
      <FiltersSelected selected={selected} onClearFilter={onClearFilter} />
      {/* TODO: phase 2 */}
      {/* <div className="BillingFilter__Right btn-group" role="group">
        <label htmlFor="selectedLabel">Selected (0)</label>
        <button className="btn btn-sm btn-outline-light btn--onlyicon">
          <i className="icon-delete-small" aria-hidden="true" />
        </button>
        <button className="btn btn-sm btn-outline-light btn--onlyicon">
          <i className="icon-download31" aria-hidden="true" />
        </button>
      </div> */}
    </StyledDiv>
  );
};

export default BillingFilter;
