import React from 'react';
import ContentLoader from 'react-content-loader';
import styled from '@emotion/styled';

const StyledDiv = styled.div`
  width: 100%;
`;

const DashboardLoadingScreen = () => (
  <StyledDiv className="DashboardLoadingScreen">
    <ContentLoader
      height={300}
      width={1200}
      viewBox="0 0 1200 300"
      backgroundColor="#F3F3F3"
      foregroundColor="#E3E4E6"
    >
      <circle cx="50" cy="50" r="30" />
      <rect x="230" y="35" rx="2" ry="2" width="180" height="10" />
      <rect x="230" y="60" rx="2" ry="2" width="180" height="10" />
      <rect x="680" y="35" rx="2" ry="2" width="180" height="10" />
      <rect x="680" y="60" rx="2" ry="2" width="180" height="10" />
      <rect x="1100" y="35" rx="2" ry="2" width="140" height="10" />
      <rect x="1100" y="60" rx="2" ry="2" width="140" height="10" />
      <rect x="1450" y="35" rx="2" ry="2" width="180" height="10" />
      <rect x="1450" y="60" rx="2" ry="2" width="180" height="10" />

      <circle cx="50" cy="150" r="30" />
      <rect x="230" y="135" rx="2" ry="2" width="180" height="10" />
      <rect x="230" y="160" rx="2" ry="2" width="180" height="10" />
      <rect x="680" y="135" rx="2" ry="2" width="180" height="10" />
      <rect x="680" y="160" rx="2" ry="2" width="180" height="10" />
      <rect x="1100" y="135" rx="2" ry="2" width="140" height="10" />
      <rect x="1100" y="160" rx="2" ry="2" width="140" height="10" />
      <rect x="1450" y="135" rx="2" ry="2" width="180" height="10" />
      <rect x="1450" y="160" rx="2" ry="2" width="180" height="10" />

      <circle cx="50" cy="250" r="30" />
      <rect x="230" y="235" rx="2" ry="2" width="180" height="10" />
      <rect x="230" y="260" rx="2" ry="2" width="180" height="10" />
      <rect x="680" y="235" rx="2" ry="2" width="180" height="10" />
      <rect x="680" y="260" rx="2" ry="2" width="180" height="10" />
      <rect x="1100" y="235" rx="2" ry="2" width="140" height="10" />
      <rect x="1100" y="260" rx="2" ry="2" width="140" height="10" />
      <rect x="1450" y="235" rx="2" ry="2" width="180" height="10" />
      <rect x="1450" y="260" rx="2" ry="2" width="180" height="10" />
    </ContentLoader>
  </StyledDiv>
);

export default DashboardLoadingScreen;
