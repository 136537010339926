import React from 'react';
import TwoFactorAuthenticate from './TwoFactorAuthenticate_updated';
import DeviceList from './DeviceList';

const SecuritySettings = ({ navigate, changePassword }) => (
  <div id="content">
    <div className="center-wrapper col-lg-10 col-xs-12 m-auto">
      <TwoFactorAuthenticate />
      <DeviceList navigate={navigate} changePassword={changePassword} />
    </div>
  </div>
);

export default SecuritySettings;
