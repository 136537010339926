import React from 'react';
import {
  Route,
  Routes,
  useLocation,
  useMatch,
  useNavigate,
  useParams,
} from 'react-router-dom-latest';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Integrations from './Integrations';
import Payouts from './Payouts';
import PayoutDetails from './PayoutDetails';
import QbManualSync from './QbManualSync';
import { IntegrationsLandingPage } from './IntegrationsLandingPage';
import { integrationModules } from './integration-modules';

export const IntegrationsRoutes = () => {
  const { newIntegrationsModuleFeature } = useFlags();

  if (newIntegrationsModuleFeature) {
    return <NewIntegrationsModuleRoutes />;
  }

  return <LegacyIntegrationsRoutes />;
};

export const NewIntegrationsModuleRoutes = () => (
  <Routes>
    <Route path="/integrations">
      <Route index element={<IntegrationsLandingPage />} />
      {integrationModules.map((module) => (
        <Route
          key={module.id}
          path={`${module.path}/*`}
          element={module.configComponent}
        />
      ))}
    </Route>
  </Routes>
);

export const LegacyIntegrationsRoutes = () => {
  const location = useLocation();
  const match = useMatch(location.pathname);
  const navigate = useNavigate();
  const params = useParams();
  return (
    <Routes>
      <Route
        path="/integrations"
        element={
          <Integrations match={match} location={location} navigate={navigate} />
        }
      />
      <Route
        path="/payouts"
        element={<Payouts location={location} navigate={navigate} />}
      />
      <Route
        path="/payout/:id"
        element={
          <PayoutDetails
            params={params}
            location={location}
            navigate={navigate}
          />
        }
      />
      <Route
        path="/liscio_qb_accounts"
        element={
          <QbManualSync
            params={params}
            location={location}
            navigate={navigate}
            match={match}
          />
        }
      />
    </Routes>
  );
};
