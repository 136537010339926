import { Box, Grid, Typography, useTheme } from '@mui/material';
import { useMemo, useState } from 'react';
import { CenterWrapperBox } from 'components/Requests/components/Styles/Styles';
import { ActionBanner } from 'components/Requests/components/ActionBanner/ActionBanner';
import { WorkflowTemplateFragment, WorkflowTemplateStatus } from 'gql/graphql';
import { useNavigate } from 'react-router-dom-latest';
import {
  IN_APP,
  REQUESTS_PAGE_ROUTES,
} from 'components/Requests/requests.constants';
import {
  useCreateWorkflowFromWorkflowTemplateMutation,
  useReadyToSendWorkflowMutation,
  useWorkflowTemplates,
} from 'hooks/workflow-hooks';
import { AlertMessage, getStorageData } from 'utilities/utils';
import { LoadingOverlay } from 'components/Requests/components/Tables/Components/LoadingOverlay';
import LoadingIndicator from 'common/LoadingIndicator';
import { WorkflowTemplateListItemMenu } from './WorkflowListItemMenu/WorkflowListItemMenu';
import { WorkflowTemplateListItem } from './WorkflowTemplateListItem/WorkflowTemplateListItem';

export interface WorkflowTemplatesListProps {}

export default function WorkflowTemplatesList() {
  /** Used to determine if the template is a system template or not by comparing
  the firmUUID of the selected template to the storedUser's cpa_uuid **/
  const user = getStorageData();
  const [templateFirmUUID, setTemplateFirmUUID] = useState<
    string | undefined
  >();
  const isSystemTemplate = useMemo(
    () => user?.cpa_uuid !== templateFirmUUID,
    [user, templateFirmUUID],
  );
  /**/

  const [activeWorkflowTemplateId, setWorkflowTemplateOptionsOpen] = useState<
    string | null
  >(null);
  const [workflowTemplateOptionsAnchorEl, setWorkflowTemplateOptionsAnchorEl] =
    useState<Element | null>(null);

  const {
    data: templatesData,
    isLoading: templatesDataIsLoading,
    fetchStatus: templatesDataFetchStatus,
    refetch: templatesDataRefetch,
  } = useWorkflowTemplates({
    statuses: [
      WorkflowTemplateStatus.Published,
      WorkflowTemplateStatus.NewTemplate,
    ],
  });

  const {
    mutate: createWorkflowTemplate,
    isLoading: createWorkflowTemplateIsLoading,
  } = useCreateWorkflowFromWorkflowTemplateMutation();
  const {
    mutate: saveAndSendWorkflowMutation,
    isLoading: createWorkflowRequestIsLoading,
  } = useReadyToSendWorkflowMutation();

  const templates = templatesData?.workflowTemplates?.sort(
    (a, b) => Date.parse(b.updatedAt) - Date.parse(a.updatedAt),
  );

  const theme = useTheme();
  const navigate = useNavigate();

  const handleOnClick = (workflowTemplate: WorkflowTemplateFragment) => {
    createWorkflowTemplate(
      { id: workflowTemplate.id! },
      {
        onSuccess: (response) => {
          const workflowId =
            response?.createWorkflowsFromWorkflowTemplate?.workflows?.[0]?.id;

          if (!workflowId) {
            AlertMessage('Unable to open template');
            return;
          }
          saveAndSendWorkflowMutation(
            { workflowId },
            {
              onSettled: (requestResponse) => {
                if (!requestResponse?.readyToSend?.workflowRequest?.id) {
                  AlertMessage('Unable to open template');
                  return;
                }

                navigate(
                  `${REQUESTS_PAGE_ROUTES.dispatch}/${requestResponse?.readyToSend?.workflowRequest?.id}?template=${workflowTemplate.id}`,
                );
              },
            },
          );
        },
      },
    );
  };

  const handleWorkflowTemplateOptionsClick = (
    event: React.MouseEvent<Element>,
    firmUUID: string | undefined,
  ) => {
    setTemplateFirmUUID(firmUUID);
    setWorkflowTemplateOptionsAnchorEl(event.currentTarget);
    setWorkflowTemplateOptionsOpen(
      event.currentTarget.getAttribute('data-workflowTemplate-id'),
    );
  };

  const handleWorkflowTemplateOptionsClose = (
    event: React.MouseEvent<Element>,
  ) => {
    event.stopPropagation();
    setWorkflowTemplateOptionsAnchorEl(null);
    setWorkflowTemplateOptionsOpen(null);
  };

  const onBuilderOpened = () => {
    navigate(REQUESTS_PAGE_ROUTES.builder, { state: { from: IN_APP } });
  };

  const templatesAreLoading =
    templatesDataIsLoading && templatesDataFetchStatus !== 'idle';

  return (
    <>
      <Box display="flex" overflow="hidden" width="100%">
        <Box sx={{ overflowY: 'auto' }} width="100%">
          <CenterWrapperBox display="flex" flexDirection="column" gap="20px">
            <ActionBanner
              header="Start from scratch"
              body="Create a new request with your own questions."
              buttonText="Blank Request"
              onClick={() => onBuilderOpened()}
            />
            {templatesAreLoading && (
              <Grid container justifyContent="center" width="100%">
                <LoadingOverlay />
              </Grid>
            )}
            {templates && !templatesAreLoading ? (
              <Box
                bgcolor="grey.50"
                borderRadius={4}
                border={`2px solid ${theme.palette.common.secondary[300]}`}
                gap="20px"
                sx={{
                  height: '100%',
                  padding: '20px',
                }}
              >
                <Box display="flex" justifyContent="space-between">
                  <Typography
                    variant="h6"
                    fontWeight="700"
                    paddingBottom="15px"
                  >
                    Or start from a template
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '20px',
                    flexWrap: 'wrap',
                  }}
                >
                  {templates.map((workflowTemplate) => (
                    <WorkflowTemplateListItem
                      key={`${workflowTemplate.id}-${workflowTemplate.createdAt}`}
                      workflowTemplate={workflowTemplate}
                      onWorkflowTemplateOptionsClick={(event) =>
                        handleWorkflowTemplateOptionsClick(
                          event,
                          workflowTemplate?.firm?.id,
                        )
                      }
                      onClick={handleOnClick}
                    />
                  ))}
                </Box>
              </Box>
            ) : (
              <Typography>No templates available</Typography>
            )}
          </CenterWrapperBox>
        </Box>
      </Box>
      {activeWorkflowTemplateId && (
        <WorkflowTemplateListItemMenu
          isSystemTemplate={isSystemTemplate}
          workflowTemplateId={activeWorkflowTemplateId}
          workflowTemplateOptionsAnchorEl={workflowTemplateOptionsAnchorEl}
          handleWorkflowTemplateOptionsClose={
            handleWorkflowTemplateOptionsClose
          }
          handleTemplatesRefetch={templatesDataRefetch}
        />
      )}
      {(createWorkflowTemplateIsLoading || createWorkflowRequestIsLoading) && (
        <LoadingIndicator loading />
      )}
    </>
  );
}
