import LoginV5MagicLink from 'components/Login/LoginV5/LoginV5MagicLink';
import { useInitializedFlags } from 'hooks/useInitializedFlags';
import LoaderDefault from 'common/LoaderDefault';
import MagicLink from './MagicLink';

export interface MagicLinkFlaggedProps {}

export const MagicLinkFlagged: React.FC<MagicLinkFlaggedProps> = () => {
  const {
    isInitialized,
    flags: { v5Login },
  } = useInitializedFlags();

  if (!isInitialized) {
    return <LoaderDefault />;
  }

  if (v5Login) {
    return <LoginV5MagicLink />;
  }

  return <MagicLink />;
};

export default MagicLinkFlagged;
