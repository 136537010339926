/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { Component } from 'react';
import Axios from 'axios';
import InfiniteScroll from 'react-infinite-scroll-component';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import {
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from 'reactstrap';
import {
  GET_UNASSIGNED_ACCOUNTS_OF_EMPLOYEE,
  ADD_OR_DELETE_TEAM_MEMBERS,
} from 'constants/constants';
import { ListUserAvatar } from 'common/ListUserAvatar';
import { AlertMessage } from 'utilities/utils';

export default class UnAssignedAccounts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sortBy: 'name',
      sortOrder: 'asc',
      pagination: 1,
      listData: [],
      searchFieldText: '',
      hasMore: true,
      dropdownOpen: false,
    };
  }

  componentDidMount() {
    // this.getRecords();
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.totalRecords < this.props.totalRecords) {
      this.resetData();
    }
    return true;
  }

  getRecords = () => {
    const { pagination, sortBy, sortOrder, searchFieldText } = this.state;
    let url = `${GET_UNASSIGNED_ACCOUNTS_OF_EMPLOYEE}/${this.props.employeeId}?page=${pagination}&sort_by=${sortBy}&sort_order=${sortOrder}`;
    if (searchFieldText && searchFieldText !== null && searchFieldText.length) {
      url = `${url}&search=${searchFieldText}`;
    }
    Axios.get(url).then((res) => {
      if (res.data && res.data.data && res.data.data.length) {
        const {
          stats: { total_entries },
        } = res.data;
        const { listData } = this.state;
        this.setState({
          listData: listData.concat(res.data.data),
          hasMore: listData.length !== total_entries,
        });
      } else {
        this.setState({ hasMore: false });
      }
    });
  };

  handlePageChange = (input) => {
    this.setState({ pagination: input }, () => this.getRecords());
  };

  handleSearchField = (input) => {
    const inputvalue = input && input.target.value ? input.target.value : '';
    const { searchFieldText } = this.state;
    this.setState({
      searchFieldText: inputvalue,
      pagination: 1,
      listData: [],
    });
    clearTimeout(this.typingTime);
    this.typingTime = setTimeout(() => {
      this.getRecords(inputvalue, searchFieldText.length);
    }, 500);
  };

  assignAccount = (input) => {
    if (input.cst === undefined || input.cst === null || input.cst.length < 5) {
      const { listData } = this.state;
      const team = {
        account_id: input.id,
        employee_id: this.props.employeeId,
      };
      Axios.post(ADD_OR_DELETE_TEAM_MEMBERS, { team }).then((res) => {
        if (res.data && res.data.data) {
          AlertMessage('success', 'Succesfully Assigned Account', 3000);
          this.props.employeeAccountsList();
        }
      });
      this.setState({ listData: listData.filter((e) => e.id !== input.id) });
    }
  };

  toggleDropdown = () => {
    const { dropdownOpen } = this.state;
    this.setState(
      { dropdownOpen: !dropdownOpen },
      () => this.state.dropdownOpen && this.getRecords(),
    );
  };

  resetData = () => {
    this.setState({
      hasMore: true,
      listData: [],
      pagination: 1,
      searchFieldText: '',
    });
  };

  render() {
    const { listData, searchFieldText, hasMore, pagination, dropdownOpen } =
      this.state;
    return (
      <div>
        <Dropdown isOpen={dropdownOpen} toggle={this.toggleDropdown}>
          <DropdownToggle caret className="btn btn-primary dropdown-toggle">
            <i>+</i> Assign Accounts
          </DropdownToggle>
          <DropdownMenu
            id="unassigned_accounts"
            style={{ width: '275px', maxHeight: '300px' }}
            right
          >
            <div style={{ position: 'relative' }}>
              <div
                className="input-group mb-2 pb-1"
                style={{
                  borderBottom: '2px solid #919eab',
                  backgroundColor: 'white',
                  position: 'sticky',
                  top: 0,
                  zIndex: 1,
                }}
              >
                <input
                  type="text"
                  className="pl-2 form-control mt-1 border-0"
                  name="recipientValue"
                  id="Accounts"
                  value={searchFieldText}
                  onChange={this.handleSearchField}
                  autoComplete="off"
                />
                <span className="input-group-addon border-left-0 pt-1">
                  <i className="icon-zoom2" />
                </span>
              </div>
              <InfiniteScroll
                dataLength={listData.length}
                next={() => this.handlePageChange(pagination + 1)}
                hasMore={hasMore}
                loader={<span className="pl-3 pb-2">Getting Accounts...</span>}
                scrollableTarget="unassigned_accounts"
              >
                {listData && listData.length
                  ? listData.map((e, index) => (
                      <DropdownItem
                        className="pt-1 pb-1"
                        key={e.id}
                        onClick={() => this.assignAccount(e, index)}
                        toggle={false}
                        disabled={e.cst.length > 4}
                      >
                        {e.cst.length > 4 ? (
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id={e.id + e.name}>
                                Account has maximum CST members
                              </Tooltip>
                            }
                          >
                            <div className="row no-gutters align-items-center">
                              <div>
                                <i
                                  className="iconCircle iconCircle icon-tickmark_trans_o"
                                  style={{
                                    width: '25px',
                                    height: '25px',
                                    lineHeight: '24px',
                                  }}
                                />
                              </div>
                              <div className="col pl-1">
                                <h5 className="fontSize mb-1 text-dark">
                                  {e.name}
                                </h5>
                              </div>
                            </div>
                          </OverlayTrigger>
                        ) : (
                          <div className="row no-gutters align-items-center">
                            <div>
                              <i
                                className="iconCircle iconCircle--green icon-tickmark_trans_o"
                                style={{
                                  width: '25px',
                                  height: '25px',
                                  lineHeight: '24px',
                                }}
                              />
                            </div>
                            <div className="col pl-1">
                              <h5 className="fontSize mb-1">{e.name}</h5>
                            </div>
                          </div>
                        )}
                        <div
                          className="row no-gutters justify-content-between align-items-center"
                          style={{ marginTop: '-5px' }}
                        >
                          <span style={{ width: '25px' }} />
                          <div className="col pl-1">
                            <h4 className="subHeadline text-light letterSpace-1 text-uppercase font-weight-bold mb-0">
                              {e.entity_type}
                            </h4>
                          </div>
                          <div className="col-auto">
                            <ListUserAvatar
                              data={e.cst}
                              row_index={e.id}
                              avatarSize={20}
                              disableTooltip
                            />
                          </div>
                        </div>
                      </DropdownItem>
                    ))
                  : null}
              </InfiniteScroll>
            </div>
          </DropdownMenu>
        </Dropdown>
      </div>
    );
  }
}
