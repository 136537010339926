/* eslint-disable no-script-url */
/* eslint-disable react/jsx-no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';

class StatusTab extends Component {
  taskType(type) {
    this.props.handleStatus(type);
  }

  render() {
    const { taskStatus } = this.props;
    return (
      <header className="pb-0 pt-0">
        <ul className="nav nav-tabs">
          <li className="nav-item">
            <a
              className={`nav-link${taskStatus === 'Open' ? ' active' : ''}`}
              data-toggle="tab"
              href="javascript:void(0)"
              onClick={() => this.taskType('Open')}
              data-testid="task__open-tasks"
            >
              Open Tasks{' '}
              {this.props.records !== undefined
                ? `| ${this.props.records.Open_total}`
                : ''}
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`nav-link${taskStatus === 'Review' ? ' active' : ''}`}
              data-toggle="tab"
              href="javascript:void(0)"
              onClick={() => this.taskType('Review')}
              data-testid="task__review-tasks"
            >
              Pending Review{' '}
              {this.props.records !== undefined
                ? `| ${this.props.records.Review_total}`
                : ''}
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`nav-link${taskStatus === 'Archive' ? ' active' : ''}`}
              data-toggle="tab"
              href="javascript:void(0)"
              onClick={() => this.taskType('Archive')}
              data-testid="task__archive-tasks"
            >
              Archived{' '}
              {this.props.records !== undefined
                ? `| ${this.props.records.Archive_total}`
                : ''}
            </a>
          </li>
          {this.props.data &&
          this.props.data.homedata &&
          this.props.data.homedata.is_employee ? (
            <li className="nav-item">
              <a
                className={`nav-link${taskStatus === 'Draft' ? ' active' : ''}`}
                data-toggle="tab"
                href="javascript:void(0)"
                onClick={() => this.taskType('Draft')}
                data-testid="task__draft-tasks"
              >
                Draft{' '}
                {this.props.records !== undefined
                  ? `| ${this.props.records.Draft_total}`
                  : ''}
              </a>
            </li>
          ) : null}
        </ul>
      </header>
    );
  }
}

export default StatusTab;
