import React, { useState, useEffect } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import Axios from 'axios';
import styled from '@emotion/styled';
import InputField from 'common/InputField';
import { QB_INVOICE, QB_ACCOUNTS, INVOICE } from 'constants/constants';
import { sortingIcon, AlertMessage } from 'utilities/utils';
import NoRecords from 'common/NoRecords';
import ReactTooltip from 'react-tooltip';

const SearchInvoiceHeaders = [
  {
    value: 'id',
    label: 'Invoice No',
    className: ' col thCol',
    isSortable: false,
    id: 1,
  },
  {
    value: 'customer_name',
    label: 'Account Name',
    className: ' col thCol',
    isSortable: false,
    id: 2,
  },
  {
    value: 'total',
    label: 'Amount',
    className: ' col thCol',
    isSortable: false,
    id: 3,
  },
];

const StyledAnchor = styled.a`
  display: inline-block;
  padding: 0;
  width: 100%;
  cursor: not-allowed !important;

  .SearchInvoiceModal__Row {
    margin: 0;
    background-color: ${({ theme }) => theme.palette.primary.disabledColor};

    .tdCol {
      border: none;
      padding-top: 12px;
      padding-bottom: 12px;
      transition: all ease-in-out 350ms;
    }
  }
`;

const SearchInvoiceModal = ({
  accountName: { label },
  onRowSelect,
  toggleModal,
  taskType,
}) => {
  const [invoices, setInvoices] = useState(undefined);
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [loading, setLoading] = useState(false);
  const [accountName, setAccountName] = useState(label);
  // eslint-disable-next-line no-unused-vars
  const [sortBy, setSortBy] = useState('id');
  // eslint-disable-next-line no-unused-vars
  const [sortOrder, setSortOrder] = useState('asc');
  const [page, setPage] = useState(1);
  const [loaded, setLoaded] = useState(false);

  /**
   * ReactTooltip needs to be rebuild so that the tooltip
   * references get attached to the dom element after every render
   */
  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const getInvoices = async () => {
    setLoading(true);
    const account = accountName;
    let url = `${QB_INVOICE}?page=${page}`;
    if (account && invoiceNumber.length) {
      url = `${url}&account_name=${account}&invoice_number=${invoiceNumber}`;
    } else if (account) {
      url = `${url}&account_name=${account}`;
    } else if (invoiceNumber.length) {
      url = `${url}&invoice_number=${invoiceNumber}`;
    }
    try {
      const response = await Axios.get(url).then((res) => res.data.data);
      setInvoices(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      AlertMessage('error', error.response.data.message, 3000);
    }
  };

  useEffect(() => {
    if (loaded) {
      getInvoices();
    } else {
      setLoaded(true);
    }
  }, [page]);

  // eslint-disable-next-line require-await
  const handleAccountNameChange = async (event) => {
    setAccountName(event);
  };

  // eslint-disable-next-line require-await
  const handleInvoiceNumberChange = async (event) => {
    setInvoiceNumber(event);
  };

  // eslint-disable-next-line require-await
  const handleSubmit = async (event) => {
    event.preventDefault();
    getInvoices();
  };

  const handleInvoiceSelect = async (event) => {
    setLoading(true);
    try {
      const result = await Axios.get(
        `${QB_ACCOUNTS}/${event.customer_ref.value}`,
      ).then((res) => res.data.data);
      // we have to pass this object to invoice form
      onRowSelect({
        ...event,
        for_account: result,
        isQBModal: true,
      });
      toggleModal();
    } catch (error) {
      setLoading(false);
      AlertMessage('error', error.response.data.message, 3000);
    }
  };

  const handlePageChange = (input) => {
    setPage(input);
  };

  const renderColumns = (colData) => (
    <>
      <div className="col tdCol text-body">
        <span>{colData.invoice_number}</span>
      </div>
      <div className="col tdCol text-body">
        <span>{colData.customer_ref.name}</span>
      </div>
      <div className="col tdCol text-body">
        <span>
          {colData.invoice_amount.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          })}
        </span>
      </div>
    </>
  );

  const renderRows = (rowItem) => (
    <div
      role="button"
      key={rowItem.qb_invoice_id}
      className="tdBtn row"
      onClick={() => handleInvoiceSelect(rowItem)}
      tabIndex={0}
    >
      {renderColumns(rowItem)}
    </div>
  );

  const renderDisabledRows = (rowItem) => (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <StyledAnchor
      data-tip
      data-for="disabledRow"
      className="tdBtn row"
      key={rowItem.qb_invoice_id}
    >
      <div className="tdBtn row SearchInvoiceModal__Row">
        {renderColumns(rowItem)}
      </div>
    </StyledAnchor>
  );

  return (
    <Modal
      isOpen
      toggle={toggleModal}
      className="customModal SearchInvoiceModal"
    >
      {loading && <div id="loading" />}
      <div className="d-flex justify-content-between align-items-center ModalHeader">
        <div className="infoHeader">Search Invoice</div>
        <button
          type="button"
          className="close"
          aria-label="Close"
          onClick={toggleModal}
        >
          <i className="icon-close2" aria-hidden="true" />
        </button>
      </div>
      <ModalBody
        style={{ maxHeight: 'calc(100vh - 150px)' }}
        className="overflow-y-scroll"
      >
        <div className="modalContent p-4">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <InputField
                name="account_name"
                id="invoice_account_search"
                label="Account Name:"
                value={accountName}
                className="form-group col-sm-12 col-lg-6 col-md-6 form-inline-label"
                onChange={handleAccountNameChange}
              />
              <InputField
                name="invoice_number"
                id="invoice_number_search"
                label="Search By Invoice No:"
                className="form-group col-sm-12 col-lg-6 col-md-6 form-inline-label"
                value={invoiceNumber}
                onChange={handleInvoiceNumberChange}
              />
            </div>
            <div className="row no-gutters justify-content-end">
              <button type="button" className="btn mr-2" onClick={toggleModal}>
                Cancel
              </button>
              <button type="submit" className="btn btn-primary">
                Search Invoice
              </button>
            </div>
          </form>
          {invoices && invoices.length > 0 ? (
            <div>
              <div className="tableWrap">
                <div className="tRow tRow--head">
                  <div className="row">
                    {SearchInvoiceHeaders.map((each) => (
                      <div
                        key={each.id}
                        className={
                          sortingIcon(
                            sortBy,
                            each.value,
                            sortOrder,
                            'className',
                          ) +
                          each.className +
                          (!each.isSortable ? ' cursor-default' : '')
                        }
                      >
                        <span className="">
                          {each.label}
                          <i
                            className={sortingIcon(
                              sortBy,
                              each.value,
                              sortOrder,
                            )}
                          />
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="tRow tRow--body">
                  {invoices.map((each) =>
                    each.payment_id && taskType === INVOICE
                      ? renderDisabledRows(each)
                      : renderRows(each),
                  )}
                </div>
              </div>
              <div>
                <ul className="pagination">
                  <li className="page-item">
                    <button
                      type="button"
                      className="page-link"
                      disabled={page === 1}
                      onClick={() => handlePageChange(page - 1)}
                    >
                      Prev
                    </button>
                  </li>
                  <li className="page-item">
                    <button
                      type="button"
                      className="page-link"
                      disabled={invoices.length < 25}
                      onClick={() => handlePageChange(page + 1)}
                    >
                      Next
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          ) : null}
          {invoices && !invoices.length > 0 ? (
            <NoRecords description="No invoices found" />
          ) : null}
        </div>
      </ModalBody>
      <ReactTooltip id="disabledRow" effect="solid">
        <div>This invoice has already been sent via Liscio</div>
      </ReactTooltip>
    </Modal>
  );
};

export default SearchInvoiceModal;
