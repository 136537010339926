import { Box, Button, Stack, Typography } from '@mui/material';

interface NoResultsOverlayProps {
  message?: string;
  refetch?: () => void;
}

export const NoResultsOverlay = ({
  message,
  refetch,
}: NoResultsOverlayProps) => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    height="500px"
    width="100%"
  >
    <Stack spacing={2} direction="column" alignItems="center">
      <Typography variant="body1">{message || 'No Results Found'}</Typography>
      {refetch && <Button onClick={() => refetch()}>Refresh</Button>}
    </Stack>
  </Box>
);
