import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import styled from '@emotion/styled';
import { INVOICE_RETRIEVAL_AND_CREATION } from 'constants/constants';
import { useLocation } from 'react-router-dom-latest';
import Invoices from './Invoices';

const StyledDiv = styled.div`
  .BillingFilter {
    margin: 30px 0;
  }
`;

const InvoiceHistory = () => {
  const location = useLocation();
  const [invoiceHistory, setInvoiceHistory] = useState(undefined);
  const splitArr = location.pathname.split('/');
  const parentTaskId = splitArr[3];

  useEffect(() => {
    getInvoicesHistory();
  }, []);

  async function getInvoicesHistory() {
    try {
      const response = await Axios.get(
        `${INVOICE_RETRIEVAL_AND_CREATION}?parent_task_id=${parentTaskId}`,
      );
      if (response.status === 200) {
        const { data } = response.data;
        setInvoiceHistory(data);
      }
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <StyledDiv className="InvoiceHistory">
      <Invoices allInvoices={invoiceHistory} isInvoiceHistoryTab />
    </StyledDiv>
  );
};

export default InvoiceHistory;
