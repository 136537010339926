/* eslint-disable react/jsx-props-no-spreading */
// import React, { useState } from 'react';
// import Axios from 'axios';
// import { withRouter } from 'utilities/withRouterWrapper';
// import styled from '@emotion/styled';

// import {
//   USER_EMAILS,
//   LINK_EMAILS,
//   UPDATE_EMAILS_STATUS,
//   MIXPANEL_EMAIL_ACTION_EVENT,
//   MIXPANEL_EMAIL_ADD_TO_ACCOUNT_EVENT,
//   MIXPANEL_EMAIL_ADD_TO_CONTACT_EVENT,
//   MIXPANEL_EMAIL_ADD_TO_TASK_EVENT,
//   UNREAD_EMAIL,
//   UPDATE_SUCCESS,
// } from 'constants/constants';
// import { AlertMessage, getCurrentPath } from 'utilities/utils';
// import Menu from 'common/Menu';
// import { trackMixpanelEvent } from 'utilities/mixpanelfn';
// import ActionsModal from 'common/ActionsModal';
// import EmailRowActions from 'components/Email/components/EmailRowActions';
// import ComposeEmailFromThread from './ComposeEmailFromThread';
// import NewTask from '../../Tasks/Create/NewTask';
// import AllContacts from '../../Contacts/AllContacts';
// import AccountList from '../../Accounts/AccountList_old';
// import NewMessage from '../../Messages/NewMessage';
// import CreateNotes from '../../Notes/CreateNotes';
// import TaskList from '../../Tasks/TaskList';
// import EmailToolBar from './emailToolBar';
// import MenuButton from './emailToolBar/MenuButton';

// const StyleDiv = styled.div``;

// const individualEmailActions = (showUnreadOption) => {
//   const options = [
//     {
//       label: 'MESSAGE ACTIONS',
//       value: 'message_actions',
//       header: true,
//     },
//     {
//       label: 'Reply',
//       value: 'Reply',
//     },
//     {
//       label: 'Reply All',
//       value: 'reply_all',
//     },
//     {
//       label: 'Forward',
//       value: 'forward',
//     },
//     {
//       label: 'Mark as Unread',
//       value: 'mark_as_unread',
//     },
//     {},
//     {
//       label: 'Reply with Liscio Message',
//       value: 'respond_with_message',
//     },
//     {},
//     {
//       label: 'Create Task',
//       value: 'create_task',
//     },
//     {
//       label: 'Create Note',
//       value: 'create_note',
//     },
//   ];
//   if (showUnreadOption) {
//     return options;
//   }
//   // we will hide mark as unread option for emails in thread except last email
//   return options.filter((option) => option?.value !== 'mark_as_unread');
// };

// const linkEmailActions = [
//   {
//     label: 'THREAD ACTIONS',
//     value: 'thread_actions',
//     header: true,
//   },
//   {
//     label: 'Add to Account',
//     value: 'add_to_account',
//   },
//   {
//     label: 'Add to Contact',
//     value: 'add_to_contact',
//   },
//   {
//     label: 'Add to Task',
//     value: 'add_to_task',
//   },
//   {},
// ];

// const emailThreadActions = (emailDetails) => {
//   const actions = [];
//   const { linked_entity_id, user_thread_status, is_pinned } = emailDetails;
//   // when user views emails in account and contacts tabs, then emails won't have user_thread_status
//   // also user won't be able to change status, pin or unpin emails when viewing emails in account and contacts tabs
//   if (!user_thread_status) {
//     return actions;
//   }
//   if (user_thread_status !== 'primary') {
//     actions.push({
//       label: 'Primary',
//       value: 'primary',
//     });
//   }
//   if (user_thread_status !== 'low_priority') {
//     actions.push({
//       label: 'Low Priority',
//       value: 'low_priority',
//     });
//   }
//   if (is_pinned) {
//     actions.push({
//       label: 'Unpin It',
//       value: 'unpin_it',
//     });
//   } else {
//     actions.push({
//       label: 'Pin It',
//       value: 'pin_it',
//     });
//   }
//   if (user_thread_status !== 'done' && !linked_entity_id) {
//     actions.push({
//       label: 'Done',
//       value: 'done',
//     });
//   }
//   return actions;
// };

// const getModelViewContext = (showModalFor) => {
//   switch (showModalFor) {
//     case 'add_to_account':
//       return 'account';
//     case 'add_to_task':
//       return 'task';
//     case 'add_to_contact':
//       return 'contact';
//     case 'add_to_note':
//       return 'note';
//     default:
//       return '';
//   }
// };

// const EmailActionsDropdown = (props) => {
//   const {
//     emailDetails,
//     refreshPage,
//     viewContextFrom,
//     composeEmail,
//     getThreadDetails,
//     refreshForVault,
//     composeEmailAction,
//     location,
//     connectedApp,
//     unreadEmail,
//     showUnreadOption,
//     emailLinkedBox,
//     nextThread,
//   } = props;
//   const [showModal, setShowModal] = useState(false);
//   const [showModalFor, setShowModalFor] = useState();
//   const [selectedRecords, setSelectedRecords] = useState([]);
//   const [email, setEmail] = useState(emailDetails);

//   const handleEmailActions = async (actionType) => {
//     const path = getCurrentPath(location);
//     trackMixpanelEvent(MIXPANEL_EMAIL_ACTION_EVENT, { actionType });
//     if (
//       actionType === 'pin_it' ||
//       actionType === 'primary' ||
//       actionType === 'low_priority' ||
//       actionType === 'done' ||
//       actionType === 'unpin_it'
//     ) {
//       try {
//         // this action applies to emails that presented in the inboxs
//         // this action is not applciable for linked emails show in accounts, contacts and tasks page
//         const response = await Axios.post(UPDATE_EMAILS_STATUS, {
//           ids: [emailDetails.id],
//           action_type: actionType,
//         }).then((res) => res.data);
//         AlertMessage('success', response.message, 3000);
//         if (
//           actionType === 'done' &&
//           (viewContextFrom === 'thread' || viewContextFrom === 'listemails') &&
//           nextThread
//         ) {
//           nextThread(emailDetails.id);
//         } else {
//           refreshPage(actionType === 'done' ? 'discardData' : '');
//         }
//       } catch (error) {
//         // AlertMessage('error', error.response.data.message, 3000);
//       }
//     } else if (actionType === 'unlink') {
//       try {
//         // this is to unlink the email from the account or contact or task
//         const id = emailDetails.liscio_email_thread_link_id;
//         const response = await Axios.delete(`${LINK_EMAILS}/${id}`).then(
//           (res) => res.data,
//         );
//         AlertMessage('success', response.message, 3000);
//         refreshPage();
//       } catch (error) {
//         AlertMessage('error', error.response.data.message, 3000);
//       }
//     } else if (actionType === 'mark_as_unread') {
//       unreadEmail(emailDetails);
//       try {
//         let scrEmailId = '';
//         let connectedWith = '';
//         if (viewContextFrom === 'listemails') {
//           const url =
//             path === '/accountdetails/:id/emails/:email_id' ||
//             path === '/accountdetails/:id/emails' ||
//             path === '/contactdetails/:id' ||
//             path === '/task/detail/:id'
//               ? LINK_EMAILS
//               : USER_EMAILS;
//           const { data, app_token } = await Axios.get(
//             `${url}/${emailDetails.id}`,
//           ).then((res) => res.data);
//           const emailData = data[data.length - 1];
//           scrEmailId = emailData.src_email_id;
//           connectedWith = app_token.app_key;
//         } else {
//           scrEmailId = emailDetails.src_email_id;
//           connectedWith = connectedApp;
//         }
//         await Axios.post(UNREAD_EMAIL, {
//           src_email_id: scrEmailId,
//           third_party_app:
//             connectedWith === 'outlookemail' ? 'Outlook' : 'Gmail',
//         }).then((res) => res.data);
//         AlertMessage('success', UPDATE_SUCCESS, 3000);
//         refreshPage();
//       } catch (error) {
//         // AlertMessage('error', error.response.data.message, 3000);
//       }
//     } else if (
//       actionType === 'create_task' ||
//       actionType === 'create_note' ||
//       actionType === 'respond_with_message'
//     ) {
//       try {
//         if (viewContextFrom === 'listemails') {
//           // we will comeback to this when i work on account and contact page
//           const url =
//             path === '/accountdetails/:id/emails/:email_id' ||
//             path === '/accountdetails/:id/emails' ||
//             path === '/contactdetails/:id' ||
//             path === '/task/detail/:id'
//               ? LINK_EMAILS
//               : USER_EMAILS;
//           const { data } = await Axios.get(`${url}/${emailDetails.id}`).then(
//             (res) => res.data,
//           );
//           const emailData = data[data.length - 1];
//           setEmail({ ...emailData, content: emailData.content });
//           setShowModalFor(actionType);
//           setShowModal(true);
//         } else {
//           // console.log('1314', emailDetails.content);
//           setEmail({ ...email, content: emailDetails.content });
//           setShowModalFor(actionType);
//           setShowModal(true);
//         }
//       } catch (error) {
//         console.log(error);
//       }
//     } else if (
//       (actionType === 'add_to_account' || actionType === 'add_to_contact') &&
//       viewContextFrom === 'thread'
//     ) {
//       emailLinkedBox(actionType, true);
//     } else if (
//       actionType === 'add_to_account' ||
//       actionType === 'add_to_task' ||
//       actionType === 'add_to_contact' ||
//       actionType === 'add_to_note'
//     ) {
//       // we show respective modal when user clicks on the add to account or task or contact or note
//       // once user selects the records and clicks on the save button then we will send the records in addEmails function
//       setShowModalFor(actionType);
//       setShowModal(true);
//     } else if (
//       viewContextFrom === 'eachThread' ||
//       viewContextFrom === 'draftMail'
//     ) {
//       composeEmailAction(actionType, emailDetails);
//     } else if (viewContextFrom === 'listemails') {
//       composeEmail(actionType, emailDetails);
//     } else {
//       setShowModalFor(actionType);
//       setShowModal(true);
//     }
//   };

//   const closeActionsModel = (e, msg = '') => {
//     if (msg) {
//       AlertMessage('success', msg, 3000);
//       if (viewContextFrom === 'listemails') {
//         composeEmail('linked', emailDetails);
//       } else if (viewContextFrom === 'thread') {
//         getThreadDetails();
//       } else if (viewContextFrom === 'eachThread') {
//         refreshForVault('refreshthread');
//       }
//     }
//     setShowModal(false);
//   };

//   const cancelActionsModel = (msg = '') => {
//     const type = showModalFor;
//     if (type === 'add_to_account') {
//       trackMixpanelEvent(MIXPANEL_EMAIL_ADD_TO_ACCOUNT_EVENT, {
//         state: 'cancelled',
//       });
//     } else if (type === 'add_to_contact') {
//       trackMixpanelEvent(MIXPANEL_EMAIL_ADD_TO_CONTACT_EVENT, {
//         state: 'cancelled',
//       });
//     } else if (type === 'add_to_task') {
//       trackMixpanelEvent(MIXPANEL_EMAIL_ADD_TO_TASK_EVENT, {
//         state: 'cancelled',
//       });
//     }
//     closeActionsModel(msg);
//   };

//   // this is linking whole thread to account, task, contact
//   // we pass email_id property
//   // entity_id is the id of the entity to which we are linking
//   // entity_type is the type of the entity to which we are linking (account, task, contact)
//   const addEmails = async (email_id, type) => {
//     const count = selectedRecords ? selectedRecords.length : 0;
//     try {
//       const response = await Axios.post(`${LINK_EMAILS}`, {
//         entity_ids: selectedRecords,
//         entity_type: type,
//         email_id,
//       }).then((res) => {
//         if (type === 'account') {
//           trackMixpanelEvent(MIXPANEL_EMAIL_ADD_TO_ACCOUNT_EVENT, {
//             state: 'linked',
//             accountsCount: count,
//           });
//         } else if (type === 'contact') {
//           trackMixpanelEvent(MIXPANEL_EMAIL_ADD_TO_CONTACT_EVENT, {
//             state: 'linked',
//             contactsCount: count,
//           });
//         } else if (type === 'task') {
//           trackMixpanelEvent(MIXPANEL_EMAIL_ADD_TO_TASK_EVENT, {
//             state: 'linked',
//             tasksCount: count,
//           });
//         }
//         return res.data.message;
//       });
//       AlertMessage('success', response, 3000);
//     } catch (error) {
//       if (type === 'account') {
//         trackMixpanelEvent(MIXPANEL_EMAIL_ADD_TO_ACCOUNT_EVENT, {
//           state: 'error',
//           accountsCount: count,
//         });
//       } else if (type === 'contact') {
//         trackMixpanelEvent(MIXPANEL_EMAIL_ADD_TO_CONTACT_EVENT, {
//           state: 'error',
//           contactsCount: count,
//         });
//       } else if (type === 'task') {
//         trackMixpanelEvent(MIXPANEL_EMAIL_ADD_TO_TASK_EVENT, {
//           state: 'error',
//           tasksCount: count,
//         });
//       }
//       AlertMessage('error', error.response.data.message, 3000);
//     }
//     if (viewContextFrom === 'listemails') {
//       composeEmail('linked', emailDetails);
//     } else if (viewContextFrom === 'thread') {
//       getThreadDetails();
//     }
//     closeActionsModel();
//   };

//   const getSelectedRecords = (input) => {
//     setSelectedRecords(input);
//   };

//   /** menuItems is just copied from the existing code but it could be
//    * helpful to convert this to a switch case to avoid confusion in the logic */
//   const menuItems = [
//     viewContextFrom === 'linkedwith'
//       ? [{ label: 'Unlink', value: 'unlink' }]
//       : [],
//     viewContextFrom === 'eachThread' || viewContextFrom === 'listemails'
//       ? individualEmailActions(showUnreadOption)
//       : [],
//     viewContextFrom === 'thread' || viewContextFrom === 'listemails'
//       ? linkEmailActions
//       : [],
//     viewContextFrom === 'thread' || viewContextFrom === 'listemails'
//       ? emailThreadActions(emailDetails)
//       : [],
//   ];

//   return (
//     <StyleDiv className="position-static tglDrop d-flex">
//       {viewContextFrom === 'listemails' && (
//         <EmailRowActions handleRowActions={null} />
//       )}
//       {viewContextFrom === 'thread' && (
//         <EmailToolBar
//           menuItems={menuItems}
//           handleEmailActions={handleEmailActions}
//           emailDetails={emailDetails}
//         />
//       )}
//       {viewContextFrom === 'thread' ? (
//         <Menu
//           menuComponentBtnCls="ctaClass"
//           menuItems={menuItems}
//           handleMenuItemClick={(input) => handleEmailActions(input.value)}
//           menuComponent={<MenuButton title="Thread Actions" />}
//         />
//       ) : (
//         <Menu
//           menuItems={menuItems}
//           handleMenuItemClick={(input) => handleEmailActions(input.value)}
//           menuComponent={<MenuButton title="Thread Actions" />}
//         />
//       )}
//       <ActionsModal
//         isOpen={showModal}
//         onCancel={cancelActionsModel}
//         style={{ overflow: 'auto', maxHeight: '85vh' }}
//         showModalFooter={getModelViewContext(showModalFor) !== ''}
//         handleSuccessBtn={() =>
//           addEmails(emailDetails.email_id, getModelViewContext(showModalFor))
//         }
//       >
//         {showModalFor === 'reply' && (
//           <ComposeEmailFromThread
//             // {...props}
//             viewContext="reply"
//             viewContextFrom={viewContextFrom || ''}
//             emailDetails={emailDetails}
//             onCancel={closeActionsModel}
//           />
//         )}
//         {showModalFor === 'reply_all' && (
//           <ComposeEmailFromThread
//             // {...props}
//             viewContext="reply_all"
//             viewContextFrom={viewContextFrom || ''}
//             threadsData={emailDetails}
//             onCancel={closeActionsModel}
//           />
//         )}
//         {showModalFor === 'forward' && (
//           <ComposeEmailFromThread
//             // {...props}
//             viewContext="forward"
//             viewContextFrom={viewContextFrom || ''}
//             threadsData={emailDetails}
//             onCancel={closeActionsModel}
//           />
//         )}
//         {showModalFor === 'respond_with_message' && (
//           <NewMessage
//             {...props}
//             closeModalInEmailPage={closeActionsModel}
//             emailDetails={email}
//             refreshEmailListPage={() => handleEmailActions('done', email)}
//             viewContext="email"
//           />
//         )}
//         {showModalFor === 'create_task' && (
//           <NewTask
//             {...props}
//             closeModalInEmailPage={closeActionsModel}
//             emailDetails={email}
//             refreshEmailListPage={() => handleEmailActions('done', email)}
//             viewContext="email"
//             defaultType="to_do"
//           />
//         )}
//         {showModalFor === 'add_to_task' && (
//           <TaskList
//             {...props}
//             onCancel={closeActionsModel}
//             getSelectedRecords={getSelectedRecords}
//             componentContext="email"
//           />
//         )}
//         {showModalFor === 'create_note' && (
//           <CreateNotes
//             {...props}
//             closeModalInEmailPage={closeActionsModel}
//             refreshEmailListPage={() => handleEmailActions('done', email)}
//             emailDetails={email}
//             viewContext="email"
//           />
//         )}
//         {showModalFor === 'add_to_account' && (
//           <AccountList
//             {...props}
//             onCancel={closeActionsModel}
//             getSelectedRecords={getSelectedRecords}
//             componentContext="email"
//           />
//         )}
//         {showModalFor === 'add_to_contact' && (
//           <AllContacts
//             {...props}
//             onCancel={closeActionsModel}
//             getSelectedRecords={getSelectedRecords}
//             componentContext="email"
//           />
//         )}
//       </ActionsModal>
//     </StyleDiv>
//   );
// };

// TODO: restore dropdown behavior in Task->emails 
// https://liscio.atlassian.net/browse/FD-1225
export default function EmailActionsDropdown() {
  return <div className="EmailActionsDropdown-to-be-restored" />
};
