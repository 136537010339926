import {
  FormControl,
  InputLabel,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { SMSVaultDialogType } from './FormValidation';

export default function FileNameElement() {
  const theme = useTheme();
  const {
    register,
    formState: { errors },
  } = useFormContext<SMSVaultDialogType>();

  return (
    <Stack>
      <InputLabel htmlFor="fileName">
        <Typography variant="h5" sx={{ color: theme.palette.text.secondary }}>
          FILE NAME
        </Typography>
      </InputLabel>
      <FormControl sx={{ paddingBottom: '1rem' }}>
        <TextField
          autoFocus
          id="fileName"
          fullWidth
          variant="standard"
          {...register('filename')}
          error={Boolean(errors.filename)}
          helperText={errors.filename?.message}
        />
      </FormControl>
    </Stack>
  );
}
