import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { iconMap } from './icons';

/**
 * Component to map old icon classes to new font-awesome icons
 * NOTE: This file is written in plain ole JS because there are
 * type conflicts with the font-awesome kits and this apps tsconfig.
 */
export const MenuItemIcon = ({ iconClass }) => {
  const icon = iconMap[iconClass];

  if (icon) {
    return <FontAwesomeIcon icon={icon} />;
  }

  console.warn(`No corresponding menu item icon for "${iconClass}"!`);
  return null;
};
