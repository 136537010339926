import React, { useEffect } from 'react';
import Checkbox from 'common/Checkbox';
import { useResyncEmail } from 'hooks/email-hooks';
import { AlertMessage } from 'utilities/utils';
import { TOAST_FAILURE, TOAST_SUCCESS } from 'constants/constants';
import EmailListFullViewButton from './EmailListFullViewButton';
import EmailListSplitViewButton from './EmailListSplitViewButton';
import EmailBulkActions from './EmailBulkActions';
import useWhichSection from './useWhichSection';

export default function EmailSettingsHeader({
  hasSelectedAll,
  toggleSelectAll,
  data,
  emailState,
  filter,
  handleBultActions,
  emailListMode,
  handleEmailMode,
}) {
  const { inLinkedSection } = useWhichSection();
  const {
    isFetching,
    data: emailResyncResponse,
    error: emailResyncError,
  } = useResyncEmail();

  useEffect(() => {
    if (emailResyncError && !isFetching)
      AlertMessage(
        TOAST_FAILURE,
        emailResyncError?.message || 'An error occurred',
      );

    if (emailResyncResponse && !isFetching)
      AlertMessage(TOAST_SUCCESS, emailResyncResponse.message);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching]);

  return (
    <div className="Em_Left_Top">
      <div className="Em_Left_Check">
        <div className="Em_Checkbox">
          {!inLinkedSection && (
            <Checkbox
              checked={hasSelectedAll}
              onChange={toggleSelectAll}
              color="info"
            />
          )}
          <span>{data?.totalRecords} messages</span>
        </div>
        {!inLinkedSection && emailState?.emailsSelectedRecords?.length > 0 ? (
          <EmailBulkActions
            filter={filter}
            handleBultActions={handleBultActions}
          />
        ) : null}
      </div>
      {emailListMode === 'split' ? (
        <EmailListSplitViewButton
          handleEmailListMode={handleEmailMode}
          title="full view"
        />
      ) : (
        <EmailListFullViewButton
          handleEmailListMode={handleEmailMode}
          title="split"
        />
      )}
    </div>
  );
}
