import React from 'react';
import PropTypes from 'prop-types';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';
import Badge from '@mui/material/Badge';
import { Link } from 'react-router-dom-latest';
import { MenuItemIcon } from './MenuItemIcon';

const MenuItemCount = ({ menuItem, count, handleSubMenuClick, selected }) => (
  <Link to={menuItem.url} className="Sidebar__MenuListItem">
    <ListItemButton
      aria-label={menuItem.label}
      key={menuItem.StyledDiv}
      onClick={(e) => handleSubMenuClick(e, menuItem)}
      data-selected={menuItem.url}
      selected={selected}
      data-testid={`sidebar__${menuItem?.label?.toLowerCase()}`}
    >
      {count > 0 ? (
        <Badge badgeContent={count} aria-label={`${menuItem.label}-count`}>
          <ListItemIcon>
            <MenuItemIcon iconClass={menuItem.icon_class} />
          </ListItemIcon>
        </Badge>
      ) : (
        <ListItemIcon>
          <MenuItemIcon iconClass={menuItem.icon_class} />
        </ListItemIcon>
      )}
      <ListItemText primary={menuItem.label.toUpperCase()} />
    </ListItemButton>
  </Link>
);

MenuItemCount.propTypes = {
  menuItem: PropTypes.shape({}).isRequired,
  count: PropTypes.number.isRequired,
  handleSubMenuClick: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
};

export default MenuItemCount;
