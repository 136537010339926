export const individualAccountPageTabs = process.env.REACT_APP_INFOCUS_FLAG
  ? [
      {
        index: 0,
        value: 'activity',
        label: 'Timeline',
      },
      {
        index: 1,
        value: 'details',
        label: 'Details',
      },
      {
        index: 2,
        value: 'inFocus',
        label: 'In Focus',
      },
      {
        index: 3,
        value: 'relationships',
        label: 'Contacts',
      },
      {
        index: 4,
        value: 'tasks',
        label: 'Tasks',
      },
      {
        index: 5,
        value: 'messages',
        label: 'Messages',
      },
      {
        index: 6,
        value: 'notes',
        label: 'Notes',
      },
      {
        index: 7,
        value: 'files',
        label: 'Files',
      },
      {
        index: 8,
        value: 'emails',
        label: 'Emails',
      },
      {
        index: 9,
        value: 'billing/invoices',
        label: 'Billing',
      },
    ]
  : [
      {
        index: 0,
        value: 'activity',
        label: 'Timeline',
      },
      {
        index: 1,
        value: 'details',
        label: 'Details',
      },
      {
        index: 2,
        value: 'relationships',
        label: 'Contacts',
      },
      {
        index: 3,
        value: 'tasks',
        label: 'Tasks',
      },
      {
        index: 4,
        value: 'messages',
        label: 'Messages',
      },
      {
        index: 5,
        value: 'notes',
        label: 'Notes',
      },
      {
        index: 6,
        value: 'files',
        label: 'Files',
      },
      {
        index: 7,
        value: 'emails',
        label: 'Emails',
      },
      {
        index: 8,
        value: 'billing/invoices',
        label: 'Billing',
      },
    ];

export const accountPageTabs = process.env.REACT_APP_INFOCUS_FLAG
  ? [
      {
        index: 0,
        value: 'activity',
        label: 'Timeline',
      },
      {
        index: 1,
        value: 'details',
        label: 'Details',
      },
      {
        index: 2,
        value: 'inFocus',
        label: 'In Focus',
      },
      {
        index: 3,
        value: 'relationships',
        label: 'Contacts',
      },
      {
        index: 4,
        value: 'tasks',
        label: 'Tasks',
      },
      {
        index: 5,
        value: 'messages',
        label: 'Messages',
      },
      {
        index: 6,
        value: 'notes',
        label: 'Notes',
      },
      {
        index: 7,
        value: 'files',
        label: 'Files',
      },
      {
        index: 8,
        value: 'emails',
        label: 'Emails',
      },
      {
        index: 9,
        value: 'billing/invoices',
        label: 'Billing',
      },
    ]
  : [
      {
        index: 0,
        value: 'activity',
        label: 'Timeline',
      },
      {
        index: 1,
        value: 'details',
        label: 'Details',
      },
      {
        index: 2,
        value: 'relationships',
        label: 'Contacts',
      },
      {
        index: 3,
        value: 'tasks',
        label: 'Tasks',
      },
      {
        index: 4,
        value: 'messages',
        label: 'Messages',
      },
      {
        index: 5,
        value: 'notes',
        label: 'Notes',
      },
      {
        index: 6,
        value: 'payroll',
        label: 'Payroll',
      },
      {
        index: 7,
        value: 'files',
        label: 'Files',
      },
      {
        index: 8,
        value: 'logins',
        label: 'Logins',
      },
      {
        index: 9,
        value: 'emails',
        label: 'Emails',
      },
      {
        index: 10,
        value: 'billing/invoices',
        label: 'Billing',
      },
    ];

export const clientAccountPageTabs = process.env.REACT_APP_INFOCUS_FLAG
  ? [
      {
        index: 0,
        value: 'details',
        label: 'Details',
      },
      {
        index: 1,
        value: 'relationships',
        label: 'Contacts',
      },

      {
        index: 2,
        value: 'files',
        label: 'Files',
      },
      {
        index: 3,
        value: 'billing/invoices',
        label: 'Billing',
      },
    ]
  : [
      {
        index: 0,
        value: 'details',
        label: 'Details',
      },
      {
        index: 1,
        value: 'relationships',
        label: 'Contacts',
      },
      {
        index: 2,
        value: 'payroll',
        label: 'Payroll',
      },
      {
        index: 3,
        value: 'files',
        label: 'Files',
      },
      {
        index: 4,
        value: 'logins',
        label: 'Logins',
      },
      {
        index: 5,
        value: 'billing/invoices',
        label: 'Billing',
      },
    ];

export const clientIndividualAccountPageTabs = [
  {
    index: 0,
    value: 'details',
    label: 'Details',
  },
  {
    index: 1,
    value: 'relationships',
    label: 'Contacts',
  },
  {
    index: 2,
    value: 'files',
    label: 'Files',
  },
  {
    index: 3,
    value: 'billing/invoices',
    label: 'Billing',
  },
];

export const liteAccountTabs = [
  {
    index: 0,
    value: 'details',
    label: 'Details',
  },
  {
    index: 1,
    value: 'relationships',
    label: 'Contacts',
  },
  {
    index: 2,
    value: 'messages',
    label: 'Messages',
  },
  {
    index: 3,
    value: 'files',
    label: 'Files',
  },
];

export const liteClientAccountTabs = [
  {
    index: 0,
    value: 'details',
    label: 'Details',
  },
  {
    index: 1,
    value: 'relationships',
    label: 'Contacts',
  },
  {
    index: 2,
    value: 'files',
    label: 'Files',
  },
];

export const clientAccountPageIfIsBillingContact = [
  {
    index: 0,
    value: 'details',
    label: 'Details',
  },
  {
    index: 1,
    value: 'billing/invoices',
    label: 'Billing',
  },
];
