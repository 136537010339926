/* eslint-disable global-require */
import React, { Component } from 'react';
import Axios from 'axios';
import { getCpaPreferences } from 'utilities/utils';
import { Modal, ModalBody } from 'reactstrap';
import { Scrollbars } from 'react-custom-scrollbars-2';
import PdfViewer from 'components/PdfViewer/PdfViewer';
import { DESKTOP_APPS } from 'constants/constants';

const isIE = /* @cc_on!@ */ false || !!document.documentMode;
export default class LisicoApps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lookoutAppName: '',
      installationInfoModal: false,
      installationInfoUrl: '',
    };
  }

  UNSAFE_componentWillMount() {
    const preferences = getCpaPreferences();
    if (preferences && preferences.liscio_app_name) {
      const liscioAppName = preferences.liscio_app_name;
      let appName;
      let lookoutAppName;
      liscioAppName.map((ele) => {
        if (ele.key === 'Liscio Desktop') {
          appName = ele.label;
        }
        if (ele.key === 'liscio_lookout') {
          lookoutAppName = ele.label;
        }
        this.setState({
          appName,
          lookoutAppName,
        });
        return ele;
      });
    }
    const feed = `${DESKTOP_APPS}?version=1`;
    Axios.get(feed).then((res) => {
      const { data } = res.data;
      if (data && data.url) {
        const { version, url } = data;
        this.setState({
          version,
          url,
        });
      }
    });
    this.setState({
      installationInfoUrl:
        'https://turmericp.liscio.me/Lookout+installation+guide+uat.pdf',
    });
  }

  handleDownload = () => {
    if (this.state.url) {
      window.location = this.state.url;
    }
  };

  installationInfo = () => {
    this.setState((prevState) => ({
      installationInfoModal: !prevState.installationInfoModal,
    }));
  };

  render() {
    const {
      appName,
      lookoutAppName,
      installationInfoModal,
      installationInfoUrl,
      version,
    } = this.state;
    return (
      <div id="content">
        <div className="center-wrapper col m-auto">
          <div className="row">
            <div className="col-lg-4 col-sm-12 col-md-6">
              <div className="card mb-4 shadow-s">
                <div
                  className="card-img-top d-flex justify-content-center align-items-center"
                  style={{ height: '150px' }}
                >
                  <img
                    src={require('images/liscio_logo_medium.png')}
                    alt="liscio_logo"
                    style={{ height: '75px' }}
                  />
                </div>
                <div className="card-body">
                  <h5 className="card-title">
                    {appName} {version}
                  </h5>
                  <button
                    type="button"
                    className="btn btn-primary col mt-3"
                    onClick={this.handleDownload}
                  >
                    Download
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 col-md-6">
              <div className="card mb-4 shadow-s">
                <div
                  className="card-img-top d-flex justify-content-center align-items-center"
                  style={{ height: '150px' }}
                >
                  <img
                    src={require('images/liscio_logo_medium.png')}
                    alt="liscio_logo"
                    style={{ height: '75px' }}
                  />
                </div>
                <div className="card-body">
                  <h5 className="card-title">{lookoutAppName}</h5>
                  <button
                    type="button"
                    className="btn btn-primary col mt-3"
                    onClick={this.installationInfo}
                  >
                    Installation info
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {installationInfoModal ? (
          <Modal
            className="customModal customModal--w990 customModal--nopadding"
            isOpen={installationInfoModal}
          >
            <div className="ModalHeader">
              <button
                type="button"
                onClick={this.installationInfo}
                className="close"
                aria-label="Close"
              >
                <i className="icon-close2" aria-hidden="true" />
              </button>
            </div>
            <ModalBody className="modalContent">
              <div className="modalContent--inner pt-0">
                <div className="docWrap">
                  <Scrollbars
                    style={{ width: '100%', height: '100%' }}
                    autoHide
                    autoHideTimeout={1000}
                  >
                    <div
                      id="lisciooutlookinfo"
                      className="term-wrapper inviteTerms"
                    >
                      {isIE ? (
                        <PdfViewer file={installationInfoUrl} />
                      ) : (
                        <iframe
                          width="100%"
                          height="600px"
                          src={installationInfoUrl}
                          title="selected attachment"
                        />
                      )}
                    </div>
                  </Scrollbars>
                </div>
              </div>
            </ModalBody>
          </Modal>
        ) : null}
      </div>
    );
  }
}
