import React from 'react';
import styled from '@emotion/styled';
import MessageRecipientSelect from './MessageRecipientSelect';
import MessageAccountSelect from './MessageAccountSelect';
import { filterOptions } from './recipientAndAccountUtils';

const SelectStyles = {
  control: (styles, state) => ({
    ...styles,
    border: state.isFocused ? '2px solid #919eab' : '2px solid #dfe3e8',
    borderRadius: '2px',
    boxShadow: 'none',
    width: '180px',
    '&:hover': {
      border: state.isFocused ? '2px solid #919eab' : '2px solid #dfe3e8',
      outlineStyle: 'none',
    },
    minHeight: '34px',
    height: '34px',
  }),
  input: (styles) => ({
    ...styles,
    cursor: 'pointer',
  }),
  menu: (provided) => ({
    ...provided,
    marginTop: '0px',
    border: '2px solid #919eab',
    outlineStyle: 'none',
    boxShadow: 'none',
    width: '180px',
    fontSize: '14px',
    cursor: 'pointer',
  }),
  option: (provided, state) => ({
    ...provided,
    // eslint-disable-next-line no-nested-ternary
    backgroundColor: state.isFocused
      ? '#dfe3e8'
      : state.isSelected
      ? '#919eab'
      : 'white',

    // eslint-disable-next-line no-nested-ternary
    color: state.isFocused ? 'black' : state.isSelected ? 'white' : 'black',

    '&:hover': {
      backgroundColor: '#dfe3e8',
      color: 'black',
    },
    '&:active': {
      backgroundColor: '#919eab;',
    },
    cursor: 'pointer',
  }),
  indicatorsContainer: (styles) => ({
    ...styles,
    cursor: 'pointer',
    height: '34px',
    alignSelf: 'end',
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    marginTop: '5px',
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: '34px',
    padding: '0 6px',
    bottom: '1px',
  }),
  singleValue: (provided) => ({
    ...provided,
    fontSize: '14px',
  }),

  placeholder: (provided) => ({
    ...provided,
    fontSize: '14px',
  }),
};

const StyledDiv = styled.div`
  padding: 6px;
  .MessageRecipientSelect,
  .MessageAccountSelect {
    display: flex;
    justify-content: space-between;
    align-items: center;

    label {
      margin-bottom: 0;
    }
  }
  .MessageRecipientSelect {
    margin-bottom: 16px;
  }
`;

const RecipientAndAccountDropdowns = ({
  selectedContact,
  recipientOptions,
  handleContactInputChange,
  handleAccountInputChange,
  handleContactChange,
  handleAccountChange,
  recipientValue,
  accountValue,
  accountOptions,
  isLoading,
}) => {
  function handleRecipientChange(selectedOption) {
    handleContactChange(selectedOption);
  }

  function handleMessageAccountChange(selectedOption, action) {
    handleAccountChange(selectedOption, action);
  }

  function handleAccInputChange(searchTerm, action) {
    handleAccountInputChange(searchTerm, action);
  }

  const { filteredGroupedOptions, filteredAccountOptions } = filterOptions(
    recipientOptions,
    {
      accountId: accountValue.value,
      allAccounts: accountOptions,
      selectedContact,
    },
  );

  return (
    <StyledDiv
      className="RecipientAndAccountDropdowns"
      data-testid="recipientAndAccountDropdowns"
    >
      <div className="MessageRecipientSelect">
        <label htmlFor="messageRecipient">Recipient:</label>
        <MessageRecipientSelect
          id="messageRecipient"
          selectStyles={SelectStyles}
          recipientOptions={filteredGroupedOptions}
          handleRecipientChange={handleRecipientChange}
          handleContactInputChange={handleContactInputChange}
          recipientValue={recipientValue}
          isLoading={isLoading}
        />
      </div>
      <div className="MessageAccountSelect">
        <label htmlFor="messageAccount">Account:</label>
        <MessageAccountSelect
          id="messageAccount"
          selectStyles={SelectStyles}
          accountOptions={filteredAccountOptions}
          accountValue={accountValue}
          handleMessageAccountChange={handleMessageAccountChange}
          handleAccountInputChange={handleAccInputChange}
        />
      </div>
    </StyledDiv>
  );
};

export default RecipientAndAccountDropdowns;
