import React from 'react';
import {
  KarbonManualSyncRefactor,
  KarbonPageLayout,
} from 'components/Integrations/integration-modules/karbon/components';

export const KarbonContactsView = () => (
  <KarbonPageLayout
    pageTitle="Karbon Contacts"
    breadcrumbs={[{ label: 'Contacts', path: '/contacts' }]}
  >
    <KarbonManualSyncRefactor manualSyncTab="liscio_karbon_contacts" />
  </KarbonPageLayout>
);
