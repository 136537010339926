import React, { Fragment, useState } from 'react';
import styled from '@emotion/styled';
import Axios from 'axios';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { DEFAULT, RECURRING, REPLACE_DOCUMENT_URL } from 'constants/constants';
import { RenderAttachments } from 'common/CustomFields';
import format from 'date-fns/format';
import ReadOnlyTinyMCE from 'common/ReadOnlyTinyMCE/ReadOnlyTinyMCE';

const StyledDiv = styled.div`
  .NewInvoice__PreviewContainer {
    display: flex;
  }

  label {
    font-weight: 600;
    font-size: 12px;
    color: #919eab;
    letter-spacing: 1px;
    flex: 1;
    margin-right: 20px;
  }

  .NewInvoicePreview__Left {
    margin-right: 80px;
  }

  .NewInvoicePreview__Row {
    display: flex;
    align-items: baseline;
    margin-top: 16px;

    p {
      width: 260px;
    }
  }

  .NewInvoicePreview__Message {
    display: flex;
    align-items: baseline;
    margin-top: 16px;

    label {
      flex: 1;
      margin-right: 8px;
    }
  }

  .file-drop-upload {
    padding: 14px 40px 0 0;
  }
`;

const NewInvoicePreview = ({
  className,
  formState: {
    account,
    contact,
    owner,
    item,
    paymentTerms,
    paymentMethods,
    invoiceDate,
    dueDate,
    recurringStartDate,
    nextChargeDate,
    recurringEndDate,
  },
  formState,
  mode,
}) => {
  const [showAttachmentModal, setAttachmentModal] = useState(false);
  const [previewUrl, setPreviewUrl] = useState('');

  const formattedDueDate = format(dueDate, 'MM/dd/yyyy');
  const formattedInvoiceDate = format(invoiceDate, 'MM/dd/yyyy');
  const formattedStartDate =
    mode === RECURRING ? format(recurringStartDate, 'MM/dd/yyyy') : '';
  const formattedNextCharge =
    mode === RECURRING ? format(nextChargeDate, 'MM/dd/yyyy') : '';

  let formattedRecurringEndDate;
  if (mode === RECURRING) {
    formattedRecurringEndDate =
      typeof recurringEndDate === 'string'
        ? recurringEndDate
        : format(recurringEndDate, 'MM/dd/yyyy');
  }

  async function openAttachment(doc) {
    setAttachmentModal(true);
    try {
      const response = await Axios.get(`${REPLACE_DOCUMENT_URL}/${doc.id}`);
      if (response.status === 200) {
        setPreviewUrl(response.data.url);
      }
    } catch (err) {
      console.error(err);
    }
  }

  function attachmentModalClose() {
    setAttachmentModal(false);
  }

  return (
    <StyledDiv
      className={`NewInvoice__Preview ${className}`}
      data-testid="newInvoicePreview"
    >
      <div className="NewInvoice__PreviewContainer">
        <div className="NewInvoicePreview__Left">
          <div className="NewInvoicePreview__Row">
            <label htmlFor="account">FOR ACCOUNT:</label>
            <p>{account.label}</p>
          </div>
          <div className="NewInvoicePreview__Row">
            <label htmlFor="contact">FOR CONTACT:</label>
            <p>{contact.label}</p>
          </div>
          <div className="NewInvoicePreview__Row">
            <label htmlFor="owner">OWNER:</label>
            <p>{owner.label}</p>
          </div>
          {mode === DEFAULT && (
            <div className="NewInvoicePreview__Row">
              <label htmlFor="invoiceNumber">INVOICE NO:</label>
              <p>{formState.invoiceNumber}</p>
            </div>
          )}
          <div className="NewInvoicePreview__Row">
            <label htmlFor="item">ITEM:</label>
            <p>{item.label || item}</p>
          </div>
          {mode === RECURRING && (
            <div className="NewInvoicePreview__Row">
              <label htmlFor="amount">AMOUNT:</label>
              <p>{formState.amount}</p>
            </div>
          )}
        </div>
        <div className="NewInvoicePreview__Right">
          {mode === RECURRING && (
            <>
              <div className="NewInvoicePreview__Row">
                <label htmlFor="invoiceDate">START DATE:</label>
                <p>{formattedStartDate}</p>
              </div>
              <div className="NewInvoicePreview__Row">
                <label htmlFor="paymentTerms">END DATE:</label>
                <p>{formattedRecurringEndDate}</p>
              </div>
              <div className="NewInvoicePreview__Row">
                <label htmlFor="dueDate">NEXT CHARGE:</label>
                <p>{formattedNextCharge}</p>
              </div>
            </>
          )}
          {mode === DEFAULT && (
            <>
              <div className="NewInvoicePreview__Row">
                <label htmlFor="invoiceDate">INVOICE DATE:</label>
                <p>{formattedInvoiceDate}</p>
              </div>
              <div className="NewInvoicePreview__Row">
                <label htmlFor="paymentTerms">PAYMENT TERMS:</label>
                <p>{paymentTerms && paymentTerms.label}</p>
              </div>
              <div className="NewInvoicePreview__Row">
                <label htmlFor="dueDate">DUE DATE:</label>
                <p>{formattedDueDate}</p>
              </div>
              <div className="NewInvoicePreview__Row">
                <label htmlFor="amount">AMOUNT:</label>
                <p>{new Intl.NumberFormat('en-US').format(formState.amount)}</p>
              </div>
            </>
          )}
          <div className="NewInvoicePreview__Row">
            <label htmlFor="paymentMethods">ALLOWED PAYMENT METHODS:</label>
            <p>{paymentMethods.label}</p>
          </div>
        </div>
      </div>
      <div className="NewInvoicePreview__Message">
        <label htmlFor="message">MESSAGE:</label>
        <ReadOnlyTinyMCE value={formState.message} />
      </div>
      <div className="attached-file-list">
        <RenderAttachments
          data={{ documents: formState.documents }}
          openAttachment={openAttachment}
          hideDeleteIcon
        />
      </div>
      <Modal
        isOpen={showAttachmentModal}
        className="customModal customModal--w990 customModal--nopadding modalMxHeight"
      >
        <ModalHeader className="ModalHeader">
          <button
            type="button"
            onClick={attachmentModalClose}
            className="close"
            aria-label="Close"
          >
            <i className="icon-close2" aria-hidden="true" />
          </button>
        </ModalHeader>
        <ModalBody className="modalContent">
          <div className="modalContent--inner">
            <div className="sample-letter-preview">
              <div className="letter-logo" />
              <iframe
                width="100%"
                height="600"
                name="myfilename"
                src={previewUrl}
                title="selected attachment"
              />
            </div>
          </div>
        </ModalBody>
      </Modal>
    </StyledDiv>
  );
};

export default NewInvoicePreview;
