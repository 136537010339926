import React, { useMemo } from 'react';
import LoadingOverlay from 'common/LoadingOverlay';
import { Box, Stack } from '@mui/material';
import { useIntegrationModules } from './integration-modules';
import { IntegrationModuleList } from './components/IntegrationModuleList';
import { IntegrationsPageLayout } from './components/IntegrationsPageLayout';

export const IntegrationsLandingPage = () => {
  const { isLoading, data } = useIntegrationModules();
  const { activeIntegrations, inactiveIntegrations } = useMemo(
    () => ({
      activeIntegrations: data?.filter((module) => module.activated) || [],
      inactiveIntegrations: data?.filter((module) => !module.activated) || [],
    }),
    [data],
  );

  return (
    <IntegrationsPageLayout pb={8}>
      {isLoading && <LoadingOverlay />}
      {data && (
        <Stack mt={4} p={4} gap={10}>
          <Box>
            <h3>Active Integrations</h3>
            <IntegrationModuleList modules={activeIntegrations} />
          </Box>
          <Box>
            <h3>Available Integrations</h3>
            <IntegrationModuleList modules={inactiveIntegrations} />
          </Box>
        </Stack>
      )}
    </IntegrationsPageLayout>
  );
};
