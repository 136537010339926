import React from 'react';
import styled from '@emotion/styled';
import {
  BUTTON_ICON_LOCATION_FRONT,
  BUTTON_ICON_LOCATION_BACK,
} from 'constants/constants';
import { getButtonSize } from './utilities';

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #f0f0f0;
  background-color: transparent;
  color: #212b36;
  border-radius: 2px;
  height: ${(props) => getButtonSize(props.size).height};
  line-height: 48px;
  padding: 13px 15px;
  border-radius: 2px;
  transition: all ease-in-out 350ms;
  :hover {
    border: 2px solid transparent;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
  }
  :disabled,
  [disabled] {
    color: #dfe3e8;
  }
  :active,
  :focus {
    border: 2px solid #00aecc;
    outline: 2px solid #00aecc;
  }
`;

const SecondaryButton = ({
  label,
  onClick,
  icon,
  disabled = false,
  size,
  iconLocation,
  testid,
}) => (
  <StyledButton
    type="button"
    id={label}
    onClick={onClick}
    disabled={disabled}
    size={size}
    data-testid={testid}
  >
    {icon && iconLocation === BUTTON_ICON_LOCATION_FRONT && (
      <i className={`icon icon-${icon}`} />
    )}
    {label}
    {icon && iconLocation === BUTTON_ICON_LOCATION_BACK && (
      <i className={`icon icon-${icon}`} />
    )}
  </StyledButton>
);

export default SecondaryButton;
