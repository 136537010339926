/* eslint-disable */
import React, { Component } from 'react';
import { format, fromUnixTime } from 'date-fns';
import { Button, Modal } from 'reactstrap';
import Axios from 'axios';
import Interweave from 'interweave';
import PreviewAllAttachments from 'common/PreviewAllAttachments';
import { PIN_UNPIN_MESSAGE } from 'constants/constants';
import { AlertMessage, getStorageData, fileType } from 'utilities/utils';
import ContactCard from 'common/ContactCard';
import AccountCard from 'common/AccountCard';
import MessageActionOptions from '../MessageActionOptions';
import ContactCardParent from 'common/ContactCardParent_updated';
import PreviewFile from 'components/UploadedFiles/PreviewFile';
import ReadOnlyTinyMCE from 'common/ReadOnlyTinyMCE/ReadOnlyTinyMCE';
class EachMessage extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      contactCardID: null,
      contactCardUniqIdentifier: null,
      accountCardID: null,
      accountCardUniqIdentifier: null,
      message: this.props.item,
      documents: this.props.item?.documents,
      selectedDocumentIndex: 0,
      previewScreen: false,
    };
  }

  componentDidMount() {
    if (
      this.props.item.scrollToThisThread &&
      this.myRef &&
      this.myRef.current
    ) {
      window.scrollTo(0, this.myRef.current.offsetTop);
    }
  }

  pinMessageThread = (e, id) => {
    e.stopPropagation();
    const component = this;
    component.props.togglePinUnpin();
    const msgId = encodeURIComponent(id);
    const request = Axios.put(`${PIN_UNPIN_MESSAGE}/${msgId}`);
    request
      .then((response) => {
        if (response.data.status === 200) {
          // this.props.togglePinUnpin();
        } else if (response.data.status === 422) {
          component.props.togglePinUnpin();
          AlertMessage('error', 'You are not message participant', 3000);
        }
      })
      .catch(() => {
        component.props.togglePinUnpin();
        console.log('Some error occurred. Please try later.');
        return null;
      });
  };

  contactAccountCardLeave = () => {
    // TODO: __HOMEDATA-AUDIT
    if (getStorageData().is_employee) {
      this.setState({
        contactCardID: null,
        contactCardUniqIdentifier: null,
        accountCardID: null,
        accountCardUniqIdentifier: null,
      });
    }
  };

  contactAccountCard = (data, type, ids, uniqId) => {
    // TODO: __HOMEDATA-AUDIT
    if (getStorageData().is_employee) {
      this.setState(
        {
          contactCardID: null,
          contactCardUniqIdentifier: null,
          accountCardID: null,
          accountCardUniqIdentifier: null,
        },
        () => {
          if (
            type === 'contact' &&
            ids &&
            ids !== ' ' &&
            ids !== null &&
            ids !== undefined
          ) {
            this.setState({
              contactCardID: ids,
              contactCardUniqIdentifier: uniqId,
            });
          }
          if (type === 'account' && ids && ids !== null && ids !== undefined) {
            this.setState({
              accountCardID: ids,
              accountCardUniqIdentifier: uniqId,
            });
          }
        },
      );
    }
  };

  previewOpenAttachmenr(doc, event = '', msgId = '') {
    if (event) {
      this.setState({ previousDocument: true });
    }
    this.setState({
      previewScreen: true,
      previewDocument: doc,
      messageDelId: msgId,
    });
  }

  previewOpenAttachment(doc, event = '', msgId = '') {
    if (event) {
      this.setState({ previousDocument: true });
    }
    this.setState({
      previewScreen: true,
      previewDocument: doc,
      messageDelId: msgId,
    });
  }

  previewCloseAttachment = (data) => {
    const { documents } = this.state;
    documents.map((doc) => {
      if (doc.id === data?.selectedItem.id && data?.editedFileName) {
        doc.doc_name = data.editedFileName;
      }
      return doc;
    });
    this.setState({ documents });
    this.setState({
      documents,
      previewScreen: false,
      previewDocument: null,
      messageDelId: '',
    });
  };

  render() {
    const { indexx } = this.props;
    const { message: item, documents } = this.state;
    const {
      contactCardID,
      accountCardID,
      contactCardUniqIdentifier,
      accountCardUniqIdentifier,
    } = this.state;
    if (item.status === 'Draft') {
      return null;
    }
    const initials = item.sender
      ? (item.sender.split(' ')[0] ? item.sender.split(' ')[0].charAt(0) : '') +
        (item.sender.split(' ')[1] ? item.sender.split(' ')[1].charAt(0) : '')
      : '';
    let renderDocuments = [];
    const activeDocuments = [];
    if (documents !== undefined) {
      renderDocuments = documents.map((doc, index) => {
        if (doc.is_active) {
          activeDocuments.push(doc);
        }
        const logo = fileType(doc);
        const getSize = doc.file_size / 1024;
        const trimSize = getSize.toFixed(2);
        return (
          <div key={`para${doc.id}`} className="file-drop-upload">
            <div className="file-drop-type">
              <i className={logo} aria-hidden="true" />
            </div>
            <div className="file-drop-info">
              <div className="file-drop-detail">
                <h6
                  href="javascript:void(0)"
                  className="fileName"
                  key={`document${doc.id}`}
                >
                  {doc.is_active ? (
                    <a
                      className="fileName pr-0"
                      key={`doc${doc.id}`}
                      onClick={(e) => {
                        this.previewOpenAttachment(doc, e, item.id, index);
                      }}
                      href="javascript:void(0)"
                    >
                      {doc.doc_name}
                    </a>
                  ) : (
                    <span className="text-dark">{doc.doc_name}</span>
                  )}
                </h6>
              </div>
              {doc.is_active ? (
                <div>
                  <a
                    className="previewFile file-action"
                    key={`doc${doc.id}`}
                    onClick={(e) => {
                      this.previewOpenAttachment(doc, e, item.id, index);
                    }}
                    href="javascript:void(0)"
                  >
                    <i className="icon-open-eye" /> Preview
                  </a>
                  <span className="file-action">{trimSize} KB</span>
                </div>
              ) : null}
              {doc.is_active ? (
                // TODO: __HOMEDATA-AUDIT
                getStorageData().is_employee ? (
                  <div>
                    <a
                      className="file-action"
                      key={`delete${doc.id}`}
                      onClick={() =>
                        this.props.delAttachmentModal(doc, item.id)
                      }
                      href="javascript:void(0)"
                    >
                      <i className="icon-close2" />
                    </a>
                  </div>
                ) : null
              ) : (
                <span className="text-dark">Deleted</span>
              )}
            </div>
          </div>
        );
      });
    }
    let recipient = '';
    if (item.is_recipient !== undefined) {
      recipient = (
        <span>
          {item.is_recipient.map((t, index) => {
            let result = '';
            const conAcc = t.split('. ');
            const ids =
              item.recipient_ids && item.recipient_ids.length
                ? item.recipient_ids[index]
                : null;
            const conAccIds =
              typeof ids === 'string'
                ? ids.split('. ')
                : typeof ids === 'object'
                ? ''
                : ids;
            if (conAcc.length === 1) {
              result = (
                <a
                  data-tip
                  data-for={`${conAccIds}global${indexx}_${index}`}
                  key={index}
                  onMouseEnter={() =>
                    this.contactAccountCard(
                      t,
                      'contact',
                      conAccIds,
                      `${indexx}_${index}`,
                    )
                  }
                  onClick={this.contactAccountCardLeave}
                >
                  {conAcc[0]}
                  {item.is_recipient.length - 1 === index ? null : ','}
                </a>
              );
            } else if (conAcc.length > 1) {
              result = conAcc.map((data, idx) => {
                const tooltipId = idx === 0 ? 'global' : 'global_account';
                return (
                  <a
                    data-tip
                    data-for={`${
                      conAccIds[idx] + tooltipId
                    }${indexx}_${index}_${idx}`}
                    key={`${index}_${idx}`}
                    onMouseEnter={() =>
                      this.contactAccountCard(
                        data,
                        idx === 0 ? 'contact' : 'account',
                        conAccIds[idx],
                        `${indexx}_${index}_${idx}`,
                      )
                    }
                    onClick={this.contactAccountCardLeave}
                  >
                    {data}
                    {conAcc.length - 1 === idx
                      ? item.is_recipient.length - 1 === index
                        ? null
                        : ','
                      : '.'}
                  </a>
                );
              });
            }
            return result;
          })}
        </span>
      );
    }
    let time = format(fromUnixTime(item.timestamp), 'MMM dd, yyyy hh:mm a');
    return (
      <div key={item.id} className="messageRow" ref={this.myRef}>
        <div
          onClick={() => this.props.toggleArrow(item)}
          className={`avatar avatar--w40 ${
            item.user_type === 'employee'
              ? 'avatar--border avatar--borderPrimary'
              : ''
          }`}
        >
          {item.avatar ? (
            <img src={item.avatar} alt="" className="rounded-circle" />
          ) : (
            <span className="uName">{initials}</span>
          )}
        </div>
        <div className="messageDetails">
          <div className="row w-100 no-gutters">
            <div
              onClick={() => this.props.toggleArrow(item)}
              className="col-sm-9"
            >
              <div className="messageBlock">
                {item.contact_id ? (
                  <h5 className="messageHeading">
                    <a
                      className="px-0"
                      style={{ color: '#212b36' }}
                      data-tip
                      data-for={`${item.contact_id}global${indexx}_${item.contact_id}_${item.id}`}
                      onMouseEnter={() =>
                        this.contactAccountCard(
                          item.sender,
                          'contact',
                          item.contact_id,
                          `${indexx}_${item.contact_id}_${item.id}`,
                        )
                      }
                      onClick={this.contactAccountCardLeave}
                    >
                      {item.sender}
                    </a>
                  </h5>
                ) : (
                  <h5 className="messageHeading">{item.sender}</h5>
                )}
                {item.arrow_position === 'up' ? (
                  <span>Recipients: {recipient}</span>
                ) : null}
                {item.arrow_position === 'down' ? (
                  <p className="messageDesc noAnchor">
                    <Interweave allowAttributes content={item.content_limit} />
                  </p>
                ) : null}
              </div>
            </div>
            <div
              onClick={() => this.props.toggleArrow(item)}
              className="col-sm-3 pr-0 text-right pt-1"
            >
              <div className="d-flex align-items-center justify-content-end">
                <div className="messageAction">
                  {
                    // TODO: __HOMEDATA-AUDIT
                    getStorageData() &&
                    getStorageData().is_employee &&
                    getStorageData().cpa_user_id === item.cpa_user_id &&
                    item.is_active ? (
                      <a
                        href="javascript:;"
                        onClick={() =>
                          this.props.confirmDeleteMessage(true, item)
                        }
                        className="btn-outline-light btn-sm btn--onlyicon ml-3 btn btn-secondary"
                      >
                        <i aria-hidden="true" className="icon-delete-small" />
                      </a>
                    ) : null
                  }
                  {documents && documents.length ? (
                    <i
                      aria-hidden="true"
                      className="icon-paper-clicp text-dark btn pr-0 pl-3 cursor-default"
                    />
                  ) : null}
                  <span className="text-dark messageDate">{time}</span>
                  <a href="javascript:;" className="text-dark label-small">
                    <i
                      className={`icon-chevron-${item.arrow_position}2`}
                      aria-hidden="true"
                    />
                  </a>
                </div>
                <span
                  className={
                    item.is_pinned ? 'pl-2 text-danger' : 'pl-2 text-light'
                  }
                >
                  <i
                    className="icon-Pinned"
                    onClick={(e) => this.pinMessageThread(e, item.id)}
                  />
                </span>
              </div>
            </div>
            {item.arrow_position === 'up' ? (
              <div className="col-12">
                <div className="messageExpand flex-column">
                  <div
                    className="message__wrap"
                    style={{ display: item.content ? 'block' : 'none' }}
                  >
                    <p className="messageDesc">
                      <ReadOnlyTinyMCE
                        disabled
                        readOnly
                        value={item.content_for_web_display || item.content}
                      />
                    </p>
                  </div>
                  {documents && documents.length ? (
                    <div className="docsInfo">
                      <div className="dragWrap">
                        <label htmlFor="attachments" className="text-dark">
                          Attachments
                        </label>
                        <div className=" ">
                          <Button
                            className="btn-link btn--onlyicon btn pl-0 border-0"
                            onClick={() =>
                              this.props.downloadAttachments(activeDocuments)
                            }
                          >
                            {activeDocuments && activeDocuments.length ? (
                              <i
                                className="icon-download2 pr-2"
                                aria-hidden="true"
                              />
                            ) : (
                              ''
                            )}
                            {activeDocuments && activeDocuments.length ? (
                              <span style={{ color: '#00aecc' }}>
                                Download All Attachments
                              </span>
                            ) : (
                              ''
                            )}
                          </Button>
                        </div>
                        <div className="attached-file-list">
                          {renderDocuments}
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            ) : null}
          </div>
          {contactCardID && contactCardID !== null ? (
            <ContactCard
              id={contactCardID}
              uniqId={contactCardUniqIdentifier}
              page="threaddetail"
            />
          ) : null}
          {accountCardID && accountCardID !== null ? (
            <AccountCard
              id={accountCardID}
              uniqId={accountCardUniqIdentifier}
              page="threaddetail"
            />
          ) : null}
        </div>
        <MessageActionOptions
          message={item}
          selectedTab={this.state.filter}
          account_data={this.props.account_data}
          contact_data={this.props.contact_data}
        />
        {this.state.previewScreen && (
          <Modal
            className="Md-full-width modal-FullH"
            isOpen={this.state.previewScreen}
          >
            <PreviewAllAttachments
              uploadType="message"
              docs={activeDocuments}
              delAttachmentModal={this.props.delAttachmentModal}
              selectedDocument={this.state.previewDocument}
              closePreview={this.previewCloseAttachment}
            />
          </Modal>
        )}
      </div>
    );
  }
}

export default EachMessage;
