import React from 'react';
import { Button, Tooltip } from 'reactstrap';
import { Link } from 'react-router-dom-latest';

class TooltipItem extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = { tooltipOpen: false };
  }

  toggle() {
    this.setState({
      // eslint-disable-next-line react/no-access-state-in-setstate
      tooltipOpen: !this.state.tooltipOpen,
    });
  }

  render() {
    if (this.props.tooltipType === 'button') {
      return (
        <>
          <Button
            className={this.props.btnClass ? this.props.btnClass : ''}
            onClick={this.props.clickAction}
            id={`Tooltip-${this.props.id}`}
            style={this.props.style}
          >
            {this.props.iconn ? <i className={this.props.iconn} /> : null}
            {this.props.value === 'span' ? (
              <span>{this.props.text}</span>
            ) : null}
          </Button>
          <Tooltip
            placement={this.props.position}
            isOpen={this.state.tooltipOpen}
            target={`Tooltip-${this.props.id}`}
            toggle={this.toggle}
          >
            {this.props.text}
          </Tooltip>
        </>
      );
    }
    return (
      <>
        <Link
          to={this.props.linkk ? this.props.linkk : '#'}
          className={this.props.btnClass ? this.props.btnClass : ''}
          onClick={this.props.clickAction}
          id={`Tooltip-${this.props.id}`}
        >
          {this.props.iconn ? <i className={this.props.iconn} /> : null}
          {this.props.value === 'span' ? <span>{this.props.text}</span> : null}
        </Link>
        <Tooltip
          placement={this.props.position}
          isOpen={this.state.tooltipOpen}
          target={`Tooltip-${this.props.id}`}
          toggle={this.toggle}
        >
          {this.props.text}
        </Tooltip>
      </>
    );
  }
}

export default TooltipItem;
