import { Box, Typography, styled } from '@mui/material';
import SMSReminderLink from 'components/SMS/components/SMSReminderLink/SMSReminderLink';
import { generateTaskReminder } from 'components/SMS/utils/generateSMSReminderMessages';

const StyledBox = styled(Box)({
  display: 'flex',
  width: '320px',
  justifyContent: 'space-between',
});
const TaskCompleteButton = ({
  markDiv,
  taskStatus,
  taskType,
  cpaUserId,
  assignCpaUserId,
  isEmployee,
  isSigned,
  loggedInUser,
  edocStatus,
  isEdocSigned,
  markComplete,
  signDocument,
  showEngagementLetter,
  invoiceStatus,
  taskDetail,
}) => {
  if (taskStatus === 'Closed') {
    return null;
  }
  if (
    !isEmployee &&
    taskStatus === 'Review' &&
    ['request_info', 'manage_to_go_items', 'virtual_meeting'].includes(taskType)
  ) {
    return null;
  }

  if (!isEmployee && taskType === 'get_signature' && isSigned) {
    return null;
  }
  if (!isEmployee && taskType === 'edoc' && isEdocSigned) {
    return null;
  }
  if (!isEmployee && taskType === 'invoice' && invoiceStatus === 'unpaid') {
    return null;
  }
  if (!isEmployee && taskType === 'invoice' && invoiceStatus === 'processing') {
    return null;
  }
  if (
    !isEmployee &&
    taskType === 'invoice' &&
    invoiceStatus === 'paid' &&
    taskStatus !== 'Open'
  ) {
    return null;
  }
  if (
    !isEmployee &&
    taskType === 'get_signature' &&
    taskStatus === 'Open' &&
    !isSigned
  ) {
    return (
      <div className="taskStatus mb-5">
        <div className="taskMarked">
          <h5 className="taskHeading">
            <i
              className="iconCircle icon-tickmark_trans_o"
              aria-hidden="true"
            />
            {loggedInUser}, please review and sign the agreement.
          </h5>

          <div className="taskAction">
            <button
              type="button"
              onClick={showEngagementLetter}
              className="btn btn-default btn-sm btn-outline-light"
            >
              Review and Sign
            </button>
          </div>
        </div>
      </div>
    );
  }
  if (
    markDiv &&
    assignCpaUserId === cpaUserId &&
    taskStatus === 'Open' &&
    taskType === 'edoc' &&
    edocStatus === 'in_progress' &&
    !isEdocSigned
  ) {
    return (
      <div className="taskStatus">
        <div className="taskMarked">
          <h5 className="taskHeading">
            <i
              className="iconCircle icon-tickmark_trans_o"
              aria-hidden="true"
            />
            {loggedInUser}, please review and sign the agreement.
          </h5>

          <div className="taskAction">
            <button
              type="button"
              onClick={() => signDocument(true)}
              className="btn btn-default btn-sm btn-outline-light"
            >
              Review and Sign
            </button>
          </div>
        </div>
      </div>
    );
  }

  const smsReminderInfo = {
    contact_sms_enabled: taskDetail.contact_sms_enabled,
    contact_sms_phone_number: taskDetail.contact_sms_phone_number,
    contact_sms_thread_id: taskDetail.contact_sms_thread_id,
    contact_sms_first_name: taskDetail.assigne,
    contact_sms_last_name: taskDetail.assigne,
    ...taskDetail,
  };
  return (
    <div className="taskStatus">
      <div className="taskMarked">
        <h5 className="taskHeading">
          <i className="iconCircle icon-tickmark_trans_o" aria-hidden="true" />
          {loggedInUser}, this task can be marked complete.
        </h5>

        <StyledBox className="taskAction">
          {taskStatus === 'Open' && taskType !== 'to_do' && (
            <SMSReminderLink
              variant="button"
              smsContacts={[smsReminderInfo]}
              reminder={generateTaskReminder(taskDetail.id)}
            />
          )}
          <button
            type="button"
            onClick={markComplete}
            className="btn btn-default btn-sm btn-outline-light"
            data-testid="task__mark_complete_btn"
          >
            <Typography variant="button">Mark Complete</Typography>
          </button>
        </StyledBox>
      </div>
    </div>
  );
};

export default TaskCompleteButton;
