import { useEffect, useMemo, useState } from 'react';
import {
  RpaDocumentStatus,
  WorkflowRequestStatus,
  WorkflowStatus,
} from 'gql/graphql';
import { useWorkflowsCountQuery } from 'hooks/workflow-hooks';
import { useWorkflowRequestsCountQuery } from 'hooks/workflow-request-hooks';
import { useRequestsState } from 'components/Requests/state/request-state';
import { useRpaDocumentProcessingCountQuery } from 'hooks/rpa-documents-processing-hooks';

export const useGetWorkflowCounts = () => {
  const { requestsCounts, setRequestsCount, filters } = useRequestsState();
  const workflowDraftsEnabled = true;

  const [pollRPADocuments, setPollRPADocuments] = useState<boolean | null>(
    null,
  );

  const {
    data: requestedOpenedWorkflowRequests,
    isLoading: isLoadingRequestedOpenRequests,
    isError: isErrorRequestedOpenRequests,
    refetch: refetchOpenRequests,
    fetchStatus: fetchStatusRequestedOpenRequests,
  } = useWorkflowRequestsCountQuery(
    {
      statuses: [WorkflowRequestStatus.Requested, WorkflowRequestStatus.Open],
      filterData: {
        assigneeIds: filters[WorkflowRequestStatus.Requested].assignedTo,
        ownerIds: filters[WorkflowRequestStatus.Requested].owners,
      },
    },
    { staleTime: 60000 },
  );

  const {
    data: submittedWorkflowRequests,
    isLoading: isLoadingSubmittedRequests,
    isError: isErrorSubmittedRequests,
    refetch: refetchSubmittedRequests,
    fetchStatus: fetchStatusSubmittedRequests,
  } = useWorkflowRequestsCountQuery(
    {
      statuses: [WorkflowRequestStatus.Submitted],
      filterData: {
        assigneeIds: filters[WorkflowRequestStatus.Submitted].assignedTo,
        ownerIds: filters[WorkflowRequestStatus.Submitted].owners,
      },
    },
    { staleTime: 60000 },
  );

  const {
    data: closedWorkflowRequests,
    isLoading: isLoadingRequests,
    isError: isErrorRequests,
    refetch: refetchClosedRequests,
    fetchStatus: fetchStatusRequests,
  } = useWorkflowRequestsCountQuery(
    {
      statuses: [WorkflowRequestStatus.Closed, WorkflowRequestStatus.Recalled],
      filterData: {
        assigneeIds: filters[WorkflowRequestStatus.Closed].assignedTo,
        ownerIds: filters[WorkflowRequestStatus.Closed].owners,
      },
    },
    { staleTime: 60000 },
  );

  const {
    data: workflowRequestsDraft,
    isLoading: isLoadingDraftRequests,
    isError: isErrorDraftRequests,
    refetch: refetchDraftRequests,
    fetchStatus: fetchStatusDraftRequests,
  } = useWorkflowRequestsCountQuery(
    {
      statuses: [WorkflowRequestStatus.Draft],
      filterData: {
        assigneeIds: filters[WorkflowRequestStatus.Draft].assignedTo,
        ownerIds: filters[WorkflowRequestStatus.Draft].owners,
      },
    },
    { staleTime: 60000 },
  );

  const {
    data: workflowsDrafts,
    isLoading: isLoadingDraftWorkflows,
    isError: isErrorDraftWorkflows,
    fetchStatus: fetchStatusDraftWorkflows,
  } = useWorkflowsCountQuery(
    {
      status: WorkflowStatus.Draft,
    },
    { enabled: workflowDraftsEnabled }, // important note - a disabled query will return fetchStatus: 'idle' if it's not enabled
  );

  const {
    data: rpaProcessing,
    isLoading: isLoadingRpaProcessing,
    isError: isErrorRpaProcessing,
    fetchStatus: fetchStatusRpaProcessing,
  } = useRpaDocumentProcessingCountQuery(
    {
      status: RpaDocumentStatus.Processing,
    },
    {
      staleTime: 60000,
      enabled: pollRPADocuments ?? true,
    },
  );

  const isLoadingOrError =
    (isLoadingRequests && fetchStatusRequests !== 'idle') ||
    (workflowDraftsEnabled &&
      isLoadingDraftWorkflows &&
      fetchStatusDraftWorkflows !== 'idle') ||
    (isLoadingDraftRequests && fetchStatusDraftRequests !== 'idle') ||
    (isLoadingRequestedOpenRequests &&
      fetchStatusRequestedOpenRequests !== 'idle') ||
    (isLoadingSubmittedRequests && fetchStatusSubmittedRequests !== 'idle') ||
    (isLoadingRpaProcessing && fetchStatusRpaProcessing !== 'idle') ||
    isErrorRequests ||
    isErrorDraftWorkflows ||
    isErrorDraftRequests ||
    isErrorRequestedOpenRequests ||
    isErrorSubmittedRequests ||
    isErrorRpaProcessing;

  useEffect(() => {
    if (!isLoadingOrError) {
      setRequestsCount({
        [RpaDocumentStatus.Processing]: rpaProcessing?.rpaDocuments.totalCount,
        [WorkflowRequestStatus.Draft]:
          workflowRequestsDraft?.workflowRequests?.totalCount,
        [WorkflowRequestStatus.Requested]:
          requestedOpenedWorkflowRequests?.workflowRequests?.totalCount,
        [WorkflowRequestStatus.Submitted]:
          submittedWorkflowRequests?.workflowRequests?.totalCount,
        [WorkflowRequestStatus.Closed]:
          closedWorkflowRequests?.workflowRequests?.totalCount,
        Workflow: workflowsDrafts?.workflows?.totalCount,
      });
    }
  }, [
    closedWorkflowRequests?.workflowRequests?.totalCount,
    workflowRequestsDraft?.workflowRequests?.totalCount,
    isLoadingOrError,
    requestedOpenedWorkflowRequests?.workflowRequests?.totalCount,
    setRequestsCount,
    submittedWorkflowRequests?.workflowRequests?.totalCount,
    workflowsDrafts?.workflows?.totalCount,
    rpaProcessing?.rpaDocuments.totalCount,
  ]);

  useEffect(() => {
    if (
      rpaProcessing?.rpaDocuments.totalCount &&
      rpaProcessing?.rpaDocuments.totalCount > 0
    ) {
      setPollRPADocuments(true);
      return;
    }
    setPollRPADocuments(false);
  }, [rpaProcessing?.rpaDocuments.totalCount]);

  const refetchCounts = useMemo(
    () => () =>
      Promise.all([
        refetchDraftRequests(),
        refetchOpenRequests(),
        refetchSubmittedRequests(),
        refetchClosedRequests(),
      ]),
    [
      refetchDraftRequests,
      refetchOpenRequests,
      refetchSubmittedRequests,
      refetchClosedRequests,
    ],
  );

  return {
    isLoadingOrError,
    requestsCounts,
    refetchCounts,
    setPollRPADocuments,
  };
};
