import create from 'zustand';

export type DraftsBulkSendState = {
  selectedDraftIds: string[];
  dueDate: string;
};

export interface DraftsBulkSendActions {
  setSelectedDraftIds: (drafts: string[]) => void;
  getSelectedDraftsCount: () => number;
  removeSelectedDraft: (draftId: string) => void;
  removeAllSelectedDrafts: () => void;
  setDueDate: (date: string) => void;
}

export const DEFAULT_STATE: DraftsBulkSendState = {
  selectedDraftIds: [],
  dueDate: '',
};

export const useDraftsBulkSend = create<
  DraftsBulkSendState & DraftsBulkSendActions
>()((set, get) => ({
  ...DEFAULT_STATE,
  setSelectedDraftIds: (drafts) => {
    set(() => ({
      selectedDraftIds: drafts,
    }));
  },
  getSelectedDraftsCount: () => get().selectedDraftIds?.length || 0,
  removeSelectedDraft: (draftId) =>
    set({
      selectedDraftIds: get().selectedDraftIds.filter((id) => id !== draftId),
    }),
  removeAllSelectedDrafts: () =>
    set({
      selectedDraftIds: [],
    }),
  setDueDate: (date) => set({ dueDate: date }),
}));
