type Payload<T = any> = { [key: string]: T };
export const CamelToSnakeCase = (obj: Payload) => {
  const newObj: Payload = {};
  Object.keys(obj).forEach((key) => {
    let value = obj[key];
    if (value && !Array.isArray(value) && typeof value === 'object') {
      value = CamelToSnakeCase(value);
    }
    newObj[key.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)] =
      value;
  });
  return newObj;
};
