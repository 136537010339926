import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  TableContainer,
  styled,
  tableCellClasses,
  tableRowClasses,
} from '@mui/material';
import { PaymentEstimateObject } from 'api/tax-delivery';
import { Delete, Edit } from '@mui/icons-material';
import { numericFormatter } from 'react-number-format';
import { parseISO, format } from 'date-fns';

interface Props {
  paymentEstimates?: PaymentEstimateObject[];
  onEdit?: (estimate: PaymentEstimateObject) => void;
  onDelete?: (uuid: string) => void;
}

export const TableContainerStyled = styled(TableContainer)(({ theme }) => ({
  [`& .${tableRowClasses.root}`]: {},
  [`& .${tableCellClasses.root}`]: {
    [`&.${tableCellClasses.head}`]: {
      ...theme.typography.body2,
      fontWeight: 700,
    },
    [`&.${tableCellClasses.body}`]: {
      fontWeight: 400,
      ...theme.typography.body1,
      letterSpacing: 'inherit',
      textTransform: 'none',
      p: {
        maxWidth: '15rem',
      },
    },
  },
}));

export function PaymentEstimates({
  paymentEstimates,
  onEdit,
  onDelete,
}: Props) {
  const formatCurrency = (value?: number | null) =>
    value
      ? numericFormatter(`$${value}`, {
          thousandSeparator: true,
          valueIsNumericString: true,
          decimalScale: 2,
        })
      : null;

  const formatDueDate = (date?: string | null) => {
    if (date) {
      const isoDate = parseISO(date);
      return format(isoDate, 'MM/dd/yyyy');
    }

    return null;
  };

  return (
    <TableContainerStyled>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Year</TableCell>
            <TableCell>Jurisdiction</TableCell>
            <TableCell>Q1 Date</TableCell>
            <TableCell>Q1 $</TableCell>
            <TableCell>Q2 Date</TableCell>
            <TableCell>Q2 $</TableCell>
            <TableCell>Q3 Date</TableCell>
            <TableCell>Q3 $</TableCell>
            <TableCell>Q4 Date</TableCell>
            <TableCell>Q4 $</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {paymentEstimates?.map((data) => (
            <TableRow key={data.uuid}>
              <TableCell>
                <Typography>{data.tax_year}</Typography>
              </TableCell>
              <TableCell>{data.jurisdiction}</TableCell>
              <TableCell>{formatDueDate(data.q1_due_date)}</TableCell>
              <TableCell>{formatCurrency(data.q1_payment_estimate)}</TableCell>
              <TableCell>{formatDueDate(data.q2_due_date)}</TableCell>
              <TableCell>{formatCurrency(data.q2_payment_estimate)}</TableCell>
              <TableCell>{formatDueDate(data.q3_due_date)}</TableCell>
              <TableCell>{formatCurrency(data.q3_payment_estimate)}</TableCell>
              <TableCell>{formatDueDate(data.q4_due_date)}</TableCell>
              <TableCell>{formatCurrency(data.q4_payment_estimate)}</TableCell>
              {(onDelete || onEdit) && (
                <TableCell>
                  {onEdit && (
                    <IconButton
                      type="button"
                      onClick={() => onEdit(data)}
                      aria-label={`Edit ${data.tax_year} ${data.jurisdiction}`}
                    >
                      <Edit />
                    </IconButton>
                  )}

                  {onDelete && (
                    <IconButton
                      type="button"
                      onClick={() => onDelete(data.uuid ?? '')}
                    >
                      <Delete />
                    </IconButton>
                  )}
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainerStyled>
  );
}
