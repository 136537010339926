/* eslint-disable */
import React, { Component } from 'react';
import Axios from 'axios';
import { Link } from 'react-router-dom-latest';
import { sortingIcon, generateFullName, AlertMessage } from 'utilities/utils';
import { GET_ASSIGNED_ACCOUNTS_OF_EMPLOYEE, EMPLOYEE, ADD_OR_DELETE_TEAM_MEMBERS } from 'constants/constants';
import { ListUserAvatar } from 'common/ListUserAvatar';
import Pagination from 'common/Pagination';
import NoRecords from 'common/NoRecords';
import TooltipItem from 'common/TooltipItem';
import UnAssignedAccounts from './UnAssignedAccounts';
import ConfirmationModal from 'common/ConfirmationModal';

const tableHeaders = [
  {
    value: 'name',
    label: 'Name',
    isSortable: true,
    className: ' col thCol',
  },
  {
    value: 'external_account_id',
    label: 'External ID',
    isSortable: true,
    className: ' col thCol',
  },
  {
    value: 'entity_type',
    label: 'Entity type',
    isSortable: true,
    className: ' col thCol',
  },
  {
    value: 'cst_members',
    label: 'CST Members',
    isSortable: false,
    className: ' col thCol',
  },
];

class EmployeeAccount extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sortBy: 'name',
      sortOrder: 'asc',
      pagination: 1,
      filter: 'all',
      totalPages: 1,
      totalRecords: 0,
      offset: [0, 15],
      listData: [],
      employeeData: this.props.employeeData,
      showDeleteConfirmation: false,
    };
  }

  componentDidMount() {
    const { employeeData } = this.state;
    this.getRecords();
    if (employeeData === undefined) {
      this.getEmployeeDetails();
    } else {
      document.title = 'Employee Accounts: ' + (employeeData && generateFullName(employeeData));
    }
  }

  getEmployeeDetails = () => {
    Axios.get(EMPLOYEE + '/' + this.props.employeeId).then((res) => {
      if (res.status === 200 && res.data.status === 200) {
        const employeeData = res.data.data[0];
        this.setState({
          employeeData,
        });
        document.title = 'Employee Accounts: ' + (employeeData && generateFullName(employeeData));
      }
    });
  };

  getRecords = () => {
    const { pagination, filter, sortBy, sortOrder, searchFieldText } = this.state;
    const url =
      GET_ASSIGNED_ACCOUNTS_OF_EMPLOYEE +
      '/' +
      this.props.employeeId +
      '?page=' +
      pagination +
      '&sort_by=' +
      sortBy +
      '&sort_order=' +
      sortOrder;
    Axios.get(url).then((res) => {
      if (res.data && res.data.data && res.data.data.length) {
        const {
          stats: { total_entries, off, offset },
        } = res.data;
        this.setState({
          listData: res.data.data,
          totalPages: total_entries ? Math.ceil(total_entries / 15) : 0,
          totalRecords: total_entries,
          offset: [offset, off],
        });
      } else {
        this.setState({
          loading: false,
          listData: undefined,
        });
      }
    });
  };

  handlePageChange = (input) => {
    this.setState(
      {
        pagination: input,
      },
      () => this.getRecords(),
    );
  };

  handleSortBy = (sortBy) => {
    let sortOrder = this.state.sortOrder;
    if (this.state.sortBy === sortBy) {
      if (sortOrder === 'asc') {
        sortOrder = 'desc';
      } else {
        sortOrder = 'asc';
      }
    } else {
      sortOrder = 'asc';
    }

    this.setState(
      {
        sortOrder,
        sortBy,
        loading: true,
      },
      () => {
        this.getRecords();
      },
    );
  };

  deleteCstMember = () => {
    const { recordtoDelete: accountDetails } = this.state;
    const { team_members } = accountDetails;
    const team_member_record = team_members.find((e) => e.employee_id === this.props.employeeId);
    const { listData } = this.state;
    listData.map((e) => {
      if (e.id === accountDetails.id) {
        e.isdeactivated = true;
      }
      return e;
    });
    this.setState({
      listData,
      recordtoDelete: {},
      showDeleteConfirmation: false,
    });
    Axios.delete(ADD_OR_DELETE_TEAM_MEMBERS + '/' + team_member_record.id).then((res) => {
      AlertMessage('success', 'Succesfully Unassigned Account', 3000);
      this.getRecords();
    });
  };

  toggleDeleteConfirmation = (input = {}) => {
    this.setState({
      recordtoDelete: input,
      showDeleteConfirmation: !this.state.showDeleteConfirmation,
    });
  };

  render() {
    const { listData, offset, pagination, totalPages, totalRecords, employeeData } = this.state;
    return (
      <div className="center-wrapper">
        <div className="mb-3 section-title">
          <div className="col-container">
            <div className="col col-6 section-title">
              <h6 role="presentation" className="stepBacklink">
                <Link to={window.location.search && window.location.search === '?ext=true' ? -1 : "/admin#employees"}>
                  <i className="icon-left-arrow" /> Back
                </Link>
              </h6>
            </div>
            <div className="col col-6 text-right">
              {employeeData && employeeData.status && employeeData.status.toLowerCase() !== 'inactive' && (
                <UnAssignedAccounts
                  employeeId={this.props.employeeId}
                  employeeAccountsList={this.getRecords}
                  totalRecords={totalRecords}
                />
              )}
            </div>
          </div>
        </div>
        <section>
          <h3 className="pb-2">Employee Name: {employeeData && generateFullName(employeeData)}</h3>
          <h4>Assigned Accounts</h4>
          <div className="tab-content">
            {listData && listData.length ? (
              <div className="tab-pane active">
                <div className="tableWrap">
                  <div className="tRow tRow--head">
                    <div className="row">
                      {tableHeaders.map((each) => (
                        <div
                          key={each.value}
                          width={each.width}
                          className={
                            sortingIcon(this.state.sortBy, each.value, this.state.sortOrder, 'className') +
                            each.className +
                            (each.isSortable ? ' cursor-default' : '')
                          }
                          onClick={() => (each.isSortable ? this.handleSortBy(each.value) : null)}
                        >
                          <span className={each.isSortable ? 'posRel' : 'posRel cursor-default'}>
                            {each.label}
                            <i className={sortingIcon(this.state.sortBy, each.value, this.state.sortOrder)} />
                          </span>
                        </div>
                      ))}
                      {/* <th width="20%">contact Name <i className="icon-down-arrow" /></th>
                  <th width="20%">Email <i className="icon-down-arrow" /></th>
                  <th width="20%">Phone <i className="icon-down-arrow" /></th>
                  <th width="15%">Role <i className="icon-down-arrow" /></th>
                  <th width="15%">Company Name <i className="icon-down-arrow" /></th> */}
                      <div className="col thCol" />
                    </div>
                  </div>
                  <div className="tRow tRow--body">
                    {listData &&
                      listData.map((each) => (
                        <div key={each.id} className="row tdBtn">
                          <div className="col tdCol">{each.name}</div>
                          <div className="col tdCol">{each.external_account_id || '-'}</div>
                          <div className="col tdCol">{each.entity_type}</div>
                          <div className="col tdCol">
                            <ListUserAvatar data={each.cst} row_index={each.id} />
                          </div>
                          <div className="col tdCol text-right">
                            {!each.isdeactivated && (
                              <TooltipItem
                                position="top"
                                tooltipType="button"
                                key={'accounts' + each.id}
                                text="Unassign Account"
                                btnClass="btn-sm btn--onlyicon btn-link"
                                clickAction={() => this.toggleDeleteConfirmation(each)}
                                id={'accounts' + each.id}
                                iconn="icon-close2"
                              />
                            )}
                            {/* <button className="btn-link btn--onlyicon btn btn-secondary" onClick={() => this.deleteFromCst(each)} data-tip data-for="close_icon">
                            <i className="icon-close2" aria-hidden="true" />
                            <ReactTooltip id="close_icon" effect="solid" place="top">
                              <span className="contactcard-team-member">Remove Account</span>
                            </ReactTooltip>
                          </button> */}
                            {/* {each.name} */}
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
                <Pagination
                  currentPage={pagination}
                  totalPages={totalPages}
                  totalRecords={totalRecords}
                  handlePageChange={this.handlePageChange}
                  offset={offset[0]}
                  off={offset[1]}
                />
              </div>
            ) : null}
            {listData === undefined ? <NoRecords title="No Accounts assigned to this employee" /> : null}
          </div>
        </section>
        {this.state.showDeleteConfirmation ? (
          <ConfirmationModal
            isOpen
            headerText="Unassign this account. Proceed?"
            yesBtnText="Proceed"
            noBtnText="Cancel"
            yesBtnClick={this.deleteCstMember}
            noBtnClick={this.toggleDeleteConfirmation}
          />
        ) : null}
      </div>
    );
  }
}

export default EmployeeAccount;
