import { GridPaginationModel } from '@mui/x-data-grid';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom-latest';

export const usePagination = () => {
  const [params, setParams] = useSearchParams();
  // const navigate = useNavigate();
  const page = Number(params.get('page')) || 1;

  const [pageSize, setPageSize] = useState(25);

  const setPaginationModel = ({
    page: newPage,
    pageSize: newPageSize,
  }: GridPaginationModel) => {
    if (newPageSize !== pageSize) {
      setPageSize(newPageSize);
    }
    if (newPage + 1 !== page) {
      setParams({
        page: `${newPage + 1}`,
      });
    }
  };

  return { paginationModel: { page: page - 1, pageSize }, setPaginationModel };
};
