import React from 'react';
import Select from 'react-select';

const MessageAccountSelect = ({
  handleMessageAccountChange,
  handleAccountInputChange,
  selectStyles,
  accountOptions,
  accountValue,
}) => {
  const value = accountValue.label ? accountValue : null;

  return (
    <Select
      name="messageAccount"
      id="messageAccount"
      inputId="messageAccount"
      placeholder="Select an Account"
      className="AccountSelect"
      styles={selectStyles}
      options={accountOptions}
      onInputChange={handleAccountInputChange}
      onChange={handleMessageAccountChange}
      value={value}
      isSearchable
      isClearable
      menuPosition="fixed"
    />
  );
};

export default MessageAccountSelect;
