/* eslint-disable react/jsx-props-no-spreading */
import {
  AddCircle,
  DragHandle,
  ExpandLess,
  ExpandMore,
} from '@mui/icons-material';
import {
  Box,
  Collapse,
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import {
  BuilderSection,
  WorkflowItemType,
} from 'components/Requests/requests.types';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { AddItemMenu } from 'components/Requests/components/RequestsBuilder/components/AddItemMenu/AddItemMenu';
import { SectionItemTuple } from 'components/Requests/components/RequestsBuilder/components/QuestionsBuilder';
import { useRequestBuilderContext } from 'components/Requests/components/RequestsBuilder/context/RequestsBuilderContext';
import { QuestionListItems } from './components/QuestionListItems/QuestionListItems';

interface QuestionListProps {
  sections: BuilderSection[];
  onQuestionClicked: (index: SectionItemTuple) => void;
  onAddQuestion: (sectionIdx: number, type: WorkflowItemType | null) => void;
  addQuestionEnabled: boolean;
}

export const QuestionList = ({
  sections,
  onQuestionClicked,
  onAddQuestion,
  addQuestionEnabled,
}: QuestionListProps) => {
  const [addQuestionAnchor, setAddQuestionAnchor] =
    useState<HTMLButtonElement | null>(null);
  const [questionMenuOpen, setQuestionMenuOpen] = useState<boolean>(false);
  const [sectionOpen, setSectionOpen] = useState<boolean[]>(
    new Array(sections.length).fill(true),
  );
  const [itemMenuSectionIndex, setItemMenuSectionIndex] = useState<
    number | null
  >(null);

  const { activeQuestionIndex, newItemInEdit } = useRequestBuilderContext();

  const [sectionIndex, itemIndex] = activeQuestionIndex;

  const listSections = useMemo(() => {
    let itemCount = 0;

    let newSections = [...sections];

    newSections = sections.map((section) => ({
      ...section,
      items2: section?.items2?.map((item) => {
        itemCount += 1;

        return {
          ...item,
          displayPosition: itemCount,
        };
      }) as BuilderSection['items2'],
    }));
    if (newItemInEdit === null) {
      return newSections;
    }
    if (!newItemInEdit.type) {
      return [
        ...newSections,
        {
          title: newItemInEdit.prompt,
          id: 'NEW',
          displayPosition: -1,
          position: -1,
          items2: [],
        } as BuilderSection,
      ];
    }
    return newSections.map((section, idx) => {
      if (newItemInEdit.sectionIndex !== idx) {
        return {
          ...section,
          displayPosition: idx,
          position: null,
        };
      }
      return {
        ...section,
        items2: [
          ...(section?.items2 || []),
          {
            prompt: newItemInEdit.prompt,
            id: 'NEW',
            displayPosition: null,
            position: null,
            type: newItemInEdit.type,
          },
        ],
      } as BuilderSection;
    });
  }, [newItemInEdit, sections]);

  // Ensures that a new section starts opened.
  useEffect(() => {
    if (sections.length > sectionOpen.length)
      setSectionOpen([...sectionOpen, true]);
  }, [sections, sectionOpen]);

  const handleSectionToggle = (index: number) => {
    setSectionOpen((prev) => {
      const newSectionOpen = [...prev];
      newSectionOpen[index] = !newSectionOpen[index];
      return newSectionOpen;
    });
  };

  const handleQuestionTypeClicked = (type: WorkflowItemType | null) => {
    onAddQuestion(itemMenuSectionIndex!, type);
    setQuestionMenuOpen(false);
    setItemMenuSectionIndex(null);
  };

  const handleQuestionMenuClicked = (
    evt: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setItemMenuSectionIndex(Number(evt.currentTarget.dataset.sectionIndex));
    setAddQuestionAnchor(evt.currentTarget);
    setQuestionMenuOpen(true);
  };

  return (
    <Droppable droppableId="section-droppable" type="section">
      {(droppableProvided) => (
        <div
          ref={droppableProvided.innerRef}
          style={{ overflowY: 'auto', overflowX: 'hidden' }}
          {...droppableProvided.droppableProps}
        >
          <Box component={List}>
            {listSections.map((section, idx) => {
              const isInEdit = section.id === 'NEW';
              const isSelectedSection =
                (itemIndex === null && sectionIndex === section.position) ||
                isInEdit;

              return (
                <Fragment key={section.id}>
                  <Draggable
                    key={section.id}
                    draggableId={section.id}
                    index={idx}
                    isDragDisabled={true && section.id === 'NEW'}
                  >
                    {(draggableProvided) => (
                      <ListItemButton
                        selected={isSelectedSection}
                        aria-selected={isSelectedSection}
                        onClick={() =>
                          onQuestionClicked([section.position ?? 0, null])
                        }
                        aria-label={section.title || 'New Section'}
                        ref={draggableProvided.innerRef}
                        {...draggableProvided.draggableProps}
                        {...draggableProvided.dragHandleProps}
                        role="option"
                      >
                        <ListItemIcon
                          onClick={() =>
                            handleSectionToggle(section.position ?? 0)
                          }
                          sx={{ minWidth: '36px' }}
                        >
                          {false && !isInEdit && <DragHandle />}
                          {sectionOpen[section.position ?? 0] ? (
                            <ExpandLess />
                          ) : (
                            <ExpandMore />
                          )}
                        </ListItemIcon>
                        <ListItemText>
                          <Box display="flex">
                            <Typography
                              fontWeight={isSelectedSection ? 700 : 400}
                              textAlign="start"
                              flexGrow={1}
                              overflow="hidden"
                              textOverflow="ellipsis"
                              whiteSpace="nowrap"
                            >
                              {section.title}
                            </Typography>
                          </Box>
                        </ListItemText>
                      </ListItemButton>
                    )}
                  </Draggable>
                  <Collapse
                    in={sectionOpen[section.position ?? 0]}
                    unmountOnExit
                  >
                    <QuestionListItems
                      section={section}
                      onQuestionClicked={onQuestionClicked}
                      itemIndex={itemIndex}
                      sectionIndex={sectionIndex}
                    />
                  </Collapse>
                  <Box display="flex" alignItems="center">
                    <Divider sx={{ flexGrow: 1, bgcolor: 'primary.light' }} />
                    <IconButton
                      onClick={handleQuestionMenuClicked}
                      data-section-index={section.position}
                      /* TODO remove the need for this entirely */
                      disabled={!addQuestionEnabled}
                      color="primary"
                      aria-label={`Add question to section ${section.title}`}
                    >
                      <AddCircle />
                    </IconButton>
                    <Divider sx={{ flexGrow: 1, bgcolor: 'primary.light' }} />
                  </Box>
                </Fragment>
              );
            })}
            <AddItemMenu
              onClose={() => setQuestionMenuOpen(false)}
              anchorEl={addQuestionAnchor}
              open={questionMenuOpen}
              onSelect={(type) => handleQuestionTypeClicked(type)}
            />
            {droppableProvided.placeholder}
          </Box>
        </div>
      )}
    </Droppable>
  );
};
