import { Typography } from '@mui/material';
import { GridRenderCellParams, GridTreeNodeWithRender } from '@mui/x-data-grid';
import { format } from 'date-fns';
import { WorkflowRequestsWorkflowRequestFragment } from 'gql/graphql';
import theme from 'theme';

type UpdatedAtCellProps = {
  params: GridRenderCellParams<
    WorkflowRequestsWorkflowRequestFragment,
    any,
    any,
    GridTreeNodeWithRender
  >;
};
export const UpdatedAtCell = ({ params }: UpdatedAtCellProps) => {
  if (!params.value) return null;

  const dueDate = new Date(params.value as string);

  return (
    <Typography color={theme.palette.text.primary}>
      {format(dueDate, "MM/dd/yyyy hh:mm aaaaa'm'")}
    </Typography>
  );
};
