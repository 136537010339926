/* eslint-disable no-script-url */
/* eslint-disable react/jsx-no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-unused-class-component-methods */
/* eslint-disable class-methods-use-this */
import React, { Component } from 'react';
import { withRouter } from 'utilities/withRouterWrapper';
import _ from 'lodash';
import { Modal, ModalBody, Input } from 'reactstrap';
import {
  SelectFieldWithAvatar,
  SelectField,
  AttachementPortion,
} from 'common/CustomFields';
import UploadFiles from 'api/UploadFiles';
import ConfirmationModal from 'common/ConfirmationModal';
import { isUserEmployee, getStorageData } from 'utilities/utils';
import * as TaskApi from 'api/TaskApi';
import { autoTagApi } from 'api/AutoTag';

class UploadFilesPopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showFileUploadModal: true,
      loading: false,
      years: [],
      createdBy: '',
      tags: [],
      newTagList: [],
      tagId: '',
      tagName: '',
      selectedRecords: [],
      dropdownOpen: false,
      yearId: '',
      yearValue: '',
      inputVal: '',
      errors: {},
      selectedDocs: [],
      viewModal: false,
      // contactLoggedIn: false,
      allAccounts: [],
      accounts: [],
      allContacts: [],
      contacts: [],
      accountId: '',
      contactId: '',
      contactName: '',
      contactAvatar: '',
      userId: '',
      months: [],
      monthId: '',
      monthValue: '',
    };
  }

  componentDidMount() {
    const component = this;
    // const homeDataObj = JSON.parse(localStorage.getItem(window.location.hostname + ' data'));
    // TODO: __HOMEDATA-AUDIT
    const storageData = getStorageData();
    const homeData = storageData.pref_type_options;
    component.setState({
      years: homeData.upload_file_year ? homeData.upload_file_year : [],
      months: homeData.upload_file_month ? homeData.upload_file_month : [],
      createdBy: homeData.uname,
      tags: homeData.upload_tags ? homeData.upload_tags : [],
      newTagList: homeData.upload_tags ? homeData.upload_tags : [],
    });
    const mydata = {
      task_type_key: 'request_info',
      limit: true,
    };
    if (this.props.data.isFileModalSubmit) {
      const tagsData = homeData.upload_tags;
      const selectedMultiTags = this.state.selectedRecords;
      this.props.data.selectedTags.map((item) => {
        for (let i = 0; i < tagsData.length; i += 1) {
          if (tagsData[i].key === item || tagsData[i].label === item) {
            selectedMultiTags.unshift({
              tagId: tagsData[i].key,
              tagName: tagsData[i].label,
            });
          }
        }
        this.setState({ selectedRecords: selectedMultiTags });
        return null;
      });
      this.setState({
        yearId: this.props.data.yearId,
        yearValue: this.props.data.yearValue,
        monthId: this.props.data.monthId,
        monthValue: this.props.data.monthValue,
        selectedDocs: this.props.data.selectedDocuments,
        accountId: this.props.data.accId ? this.props.data.accId : '',
        contactId: this.props.data.conId ? this.props.data.conId : '',
        userId: this.props.data.userId ? this.props.data.userId : '',
        contactName: this.props.data.conNm ? this.props.data.conNm : '',
        contactAvatar: this.props.data.conAvatar
          ? this.props.data.conAvatar
          : '',
      });
    } else if (!this.props.data.isFileModalSubmit) {
      TaskApi.getAccounts(this, 'notes');
      TaskApi.getContactsOfTask(this, mydata, 'notes');
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // TODO: __HOMEDATA-AUDIT
    const storageData = getStorageData();
    const homeData = storageData.pref_type_options;
    if (
      this.state.yearId !== nextProps.data.yearId &&
      nextProps.data.yearId !== null
    ) {
      this.setState({
        yearId: nextProps.data.yearId,
        yearValue: nextProps.data.yearValue,
      });
    }
    if (
      this.state.monthId !== nextProps.data.monthId &&
      nextProps.data.monthId !== null
    ) {
      this.setState({
        monthId: nextProps.data.monthId,
        monthValue: nextProps.data.monthValue,
      });
    }
    if (
      this.state.selectedRecords !== nextProps.data.selectedTags &&
      this.state.selectedRecords.length !== nextProps.data.selectedTags.length
    ) {
      const tagsData = homeData.upload_tags;
      const selectedMultiTags = this.state.selectedRecords;
      nextProps.data.selectedTags.map((item) => {
        for (let i = 0; i < tagsData.length; i += 1) {
          if (
            tagsData[i].key === item.key ||
            tagsData[i].label === item.label
          ) {
            if (!_.find(selectedMultiTags, { tagId: tagsData[i].key })) {
              selectedMultiTags.unshift({
                tagId: tagsData[i].key,
                tagName: tagsData[i].label,
              });
            }
          }
        }
        this.setState({ selectedRecords: selectedMultiTags });
        return null;
      });
    }
  }

  onSuccess = (keys) => {
    this.props.component.onSuccess(keys);
  };

  onCancel = (err) => {
    this.props.component.onCancel(err);
  };

  onFocus = () => {
    this.setState(
      (prevState) => ({
        dropdownOpen: true,
        tags: prevState.tags,
        tagId: '',
        tagName: '',
        errors: {},
      }),
      () => {
        document.getElementById('myInput').focus();
      },
    );
  };

  getAuthenticationToken = () =>
    Promise.resolve(this.props.component.getAuthenticationToken());

  handleOnedriveFiles = (toggle = undefined, item = {}) => {
    if (toggle === 'open' && item.key) {
      this.props.component.handleOnedriveFiles('open', item);
    } else if (toggle === 'close') {
      this.props.component.handleOnedriveFiles('close');
    }
  };

  changeInput = () => false;

  handleYearChange = (val) => {
    const component = this;
    if (val) {
      component.setState({
        yearId: val.key,
        yearValue: val.label,
      });
    } else {
      component.setState({
        yearId: '',
        yearValue: '',
      });
    }
  };

  handleMonthChange = (val) => {
    const component = this;
    if (val) {
      component.setState({ monthId: val.key, monthValue: val.label });
    } else {
      component.setState({ monthId: '', monthValue: '' });
    }
  };

  handleTagChange = (val) => {
    const tagsArr = this.state.tags;
    const component = this;
    if (val) {
      component.setState({
        tagId: val.key,
        tagName: val.label,
      });
    } else {
      component.setState({
        tagId: '',
        tagName: '',
      });
    }

    const selectedInput = {
      tagId: val.key,
      tagName: val.label,
    };
    const selectedRecordsArr = this.state.selectedRecords;
    const index = selectedRecordsArr.findIndex(
      (x) => x.tagId === selectedInput.tagId,
    );
    if (index === -1) {
      selectedRecordsArr.push(selectedInput);
      this.setState({
        selectedRecords: selectedRecordsArr,
        dropdownOpen: false,
        tags: tagsArr,
        newTagList: tagsArr,
        tagId: '',
        tagName: '',
      });
    } else {
      this.setState({
        dropdownOpen: false,
        tags: tagsArr,
        newTagList: tagsArr,
        tagId: '',
        tagName: '',
      });
    }
  };

  removeSelectedRecord(tagId) {
    const selectedRecordsArr = this.state.selectedRecords;
    const index = selectedRecordsArr.findIndex((x) => x.tagId === tagId);
    if (index > -1) {
      selectedRecordsArr.splice(index, 1);
      this.setState({ selectedRecords: selectedRecordsArr });
    }
  }

  handleCancel = () => {
    const values = this.state;
    let m = [];
    if (this.props.data.newDocs) {
      m = this.props.data.newDocs;
    } else {
      m = this.props.data.documents;
    }
    if (
      !m.length &&
      !values.yearId &&
      !values.monthId &&
      !values.selectedRecords.length &&
      !this.props.data.uploadFiles.length
    ) {
      this.hideuploadFileModal();
    } else {
      this.setState({ viewModal: true });
    }
  };

  hideViewModal = () => {
    this.setState({ viewModal: false });
  };

  hideuploadFileModal = () => {
    this.props.component.cancelAllUploads(this.state);
    this.setState(
      {
        showFileUploadModal: false,
        tagId: '',
        tagName: '',
        selectedRecords: this.props.data.selectedTags,
        dropdownOpen: false,
        yearId: this.props.data.yearId,
        monthId: this.props.data.monthId,
        yearValue: this.props.data.yearValue,
        monthValue: this.props.data.monthValue,
        inputVal: '',
      },
      () => {
        this.props.component.openPopUp();
        this.props.component.stateUpdate(this.state);
      },
    );
    if (document.getElementById('draftInput')) {
      document.getElementById('draftInput').focus(); // to focus out from editor
    }
  };

  handleFilesDataValidation = () => {
    const err = {};
    let formIsValid = true;
    if (!this.state.selectedRecords.length && isUserEmployee()) {
      formIsValid = false;
      err.tags = 'Please select tags';
    }
    const doc = [];
    if (this.props.dataProps || this.props.notedetail) {
      this.props.data.newDocs.map((docs) => doc.push(docs.id));
    } else {
      this.props.data.documents.map((docs) => {
        const indx = this.state.selectedDocs.indexOf(docs.id);
        if (indx === -1) {
          doc.push(docs.id);
        }
        return doc;
      });
    }
    if (!this.props.data.documents.length || doc.length <= 0) {
      if (
        this.props.data.accounts &&
        this.props.data.accounts.length >= 1 &&
        !this.props.data.accountId
      ) {
        formIsValid = false;
        err.account = 'Please select account';
        this.props.component.errorDoc('account');
      } else {
        formIsValid = false;
        err.documents = 'Please select document';
        this.props.component.errorDoc();
      }
    }
    this.setState({ errors: err });
    return formIsValid;
  };

  handleSubmit = async (e) => {
    const { name: uploadType } = this.props;
    e.preventDefault();
    if (this.handleFilesDataValidation()) {
      this.setState({ loading: true });
      const tagg = [];
      const tagsLabels = [];
      this.state.selectedRecords.map((tag) => {
        tagg.push(tag.tagId);
        tagsLabels.push(tag.tagName);
        return tagg;
      });
      const doc = [];
      if (this.props.dataProps || this.props.notedetail) {
        // this.props.data.newDocs.map((docs) => {
        //   return doc.push(docs.id);
        // });
        await Promise.all(
          this.props.data.newDocs.map((docs) => {
            doc.push(docs.id);
            return autoTagApi(
              'feedback',
              { filename: docs.doc_name, tag: tagsLabels.join(', ') },
              true,
            );
          }),
        );
      } else {
        await Promise.all(
          this.props.data.documents.map((docs) => {
            const indx = this.state.selectedDocs.indexOf(docs.id);
            if (indx === -1) {
              doc.push(docs.id);
              return autoTagApi(
                'feedback',
                { filename: docs.doc_name, tag: tagsLabels.join(', ') },
                true,
              );
            }
            return doc;
          }),
        );
        // this.props.data.documents.map((docs) => {
        //   const indx = this.state.selectedDocs.indexOf(docs.id);
        //   if (indx === -1) {
        //     doc.push(docs.id);
        //   }
        //   return doc;
        // });
      }
      const mydata = {
        year: this.state.yearId,
        month: this.state.monthId,
        tags: tagg,
        doc_ids: doc,
      };
      if (uploadType === 'taskDetail') {
        const {
          params: { id },
        } = this.props;
        mydata.is_draft = false;
        mydata.task_id = id;
      }
      if (this.props.notedetail) {
        mydata.account_id = this.props.notedetail.account_id
          ? this.props.notedetail.account_id
          : '';
        mydata.contact_id = this.props.notedetail.contact_id
          ? this.props.notedetail.contact_id
          : '';
      }
      UploadFiles.uploadFiles(mydata, this);
      if (document.getElementById('draftInput')) {
        document.getElementById('draftInput').focus(); // to focus out from editor
      }
    }
  };

  filterSearch = (target) => {
    if (target) {
      const k = [];
      const value = target;
      const filter = value.toUpperCase();
      const { tags } = this.state;
      tags.map((tag) => {
        for (let i = 0; i < target.length; i += 1) {
          if (tag.label.toUpperCase().indexOf(filter) > -1) {
            if (k.indexOf(tag) === -1) {
              k.push(tag);
            }
          }
        }
        return k;
      });
      this.setState({ newTagList: k });
    } else {
      const { newTagList } = this.state;
      this.setState({ newTagList });
    }
  };

  resetContactsAndAccounts(changeOnly = 'both') {
    const { allAccounts, allContacts } = this.state;
    if (changeOnly === 'contacts') {
      this.setState({
        contacts: allContacts,
        contactId: '',
        contactName: '',
        contactAvatar: '',
        userId: '',
      });
    } else {
      this.setState({
        contacts: allContacts,
        accounts: allAccounts,
        contactId: '',
        contactName: '',
        contactAvatar: '',
        userId: '',
        accountId: '',
      });
    }
  }

  renderTags() {
    const selectedRecords = this.state.selectedRecords.map((item) => {
      if (item.tagId) {
        return (
          <span className="tag tag--fill" key={item.tagId}>
            {item.tagName}
            <i
              className="icon-close2 remove"
              onClick={() => this.removeSelectedRecord(item.tagId)}
            />
          </span>
        );
      }
      return (
        <span className="tag tag--fill" key={item.tagId}>
          {item.tagName}{' '}
          <i
            className="icon-close2 remove"
            onClick={() => this.removeSelectedRecord(item.tagId)}
          />
        </span>
      );
    });
    return selectedRecords;
  }

  render() {
    // const homeDataObj = JSON.parse(localStorage.getItem(window.location.hostname + ' data'));
    const noneStyle = { display: 'none' };
    const blockStyle = { display: 'block' };
    const { name: uploadType } = this.props;
    const tags = this.state.newTagList.map((item) => (
      <a
        key={item.key}
        onClick={() => this.handleTagChange(item)}
        href="javascript:void(0)"
        className="dropdown-item"
      >
        {item.label}
      </a>
    ));
    let disabl = false;
    if (this.props.dataProps || this.props.notedetail) {
      disabl = true;
    }
    let conDisabl = false;
    if (this.props.dataProps || this.props.notedetail) {
      conDisabl = true;
    }
    let accOptions = this.props.data.accounts;
    let conOptions = this.props.data.contacts;
    if (this.props.dataProps || this.props.notedetail) {
      accOptions = [];
      conOptions = [];
    }
    let accId = this.props.data.accountId;
    let conId = this.props.data.contactId;
    if (this.props.dataProps) {
      const { dataProps } = this.props;
      if (dataProps.task_type_value === 'To Do') {
        accId = dataProps.todo_for_account_id
          ? {
              value: this.props.dataProps.todo_for_account_id,
              label: this.props.dataProps.todo_account_name,
            }
          : '';
        conId = dataProps.todo_for_contact_id
          ? {
              value: this.props.dataProps.todo_for_contact_id,
              label: this.props.dataProps.todo_contact_name,
            }
          : '';
      } else {
        accId = this.props.dataProps.account_id
          ? {
              value: this.props.dataProps.account_id,
              label: this.props.dataProps.account,
            }
          : '';
        conId = this.props.dataProps.contact_id
          ? {
              value: this.props.dataProps.contact_id,
              label: this.props.dataProps.assigne,
            }
          : '';
      }
    } else if (this.props.notedetail) {
      accId = this.props.notedetail.account_id
        ? {
            value: this.props.notedetail.account_id,
            label: this.props.notedetail.account,
          }
        : '';
      conId = this.props.notedetail.contact_id
        ? {
            value: this.props.notedetail.contact_id,
            label: this.props.notedetail.contact,
          }
        : '';
    }
    let newDocs = false;
    if (
      this.props.dataProps ||
      uploadType === 'bulk' ||
      this.props.notedetail
    ) {
      newDocs = true;
    }
    return (
      <div className="center-wrapper">
        <div id="loading" style={this.state.loading ? blockStyle : noneStyle} />
        <Modal
          className="customModal customModal--w700 customModal--nopadding"
          isOpen={this.state.showFileUploadModal}
        >
          <div className="ModalHeader">
            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={this.hideuploadFileModal}
            >
              <i className="icon-close2" aria-hidden="true" />
            </button>
          </div>
          <ModalBody className="modalContent" style={{ maxHeight: 'none' }}>
            <div className="modalContent--inner">
              <div className="modalContent--header">
                <h5 className="modalContent--header__heading">Upload File</h5>
              </div>
              <div className="modalForm">
                <form onSubmit={this.handleSubmit}>
                  <div className="row">
                    {uploadType === 'bulk' ||
                    uploadType === 'taskDetail' ||
                    uploadType === 'notesdetail' ? null : (
                      <SelectField
                        name="account"
                        label="Account"
                        className="form-group dropdown col-md-8 col-12"
                        error={this.props.data.errors.accont}
                        options={accOptions}
                        value={accId}
                        disabled={disabl}
                        clearable
                      />
                    )}
                    <SelectField
                      name="year"
                      label="Year"
                      className={`form-group dropdown ${
                        uploadType === 'taskDetail' ||
                        uploadType === 'notesdetail' ||
                        uploadType === 'bulk'
                          ? 'col-6'
                          : 'col-md-4 col-12'
                      }`}
                      options={this.state.years}
                      handleChange={this.handleYearChange}
                      value={
                        this.state.yearId
                          ? {
                              value: this.state.yearId,
                              label: this.state.yearValue,
                            }
                          : ''
                      }
                      clearable
                    />
                    {uploadType === 'taskDetail' ||
                    uploadType === 'notesdetail' ||
                    uploadType === 'bulk' ? (
                      <SelectField
                        name="month"
                        label="Month"
                        className="form-group dropdown col-6"
                        options={this.state.months}
                        handleChange={this.handleMonthChange}
                        value={
                          this.state.monthId
                            ? {
                                value: this.state.monthId,
                                label: this.state.monthValue,
                              }
                            : ''
                        }
                        clearable
                      />
                    ) : null}
                  </div>
                  {uploadType === 'taskDetail' ||
                  uploadType === 'notesdetail' ||
                  uploadType === 'bulk' ? null : (
                    <div className="row">
                      {uploadType === 'bulk' ? null : (
                        <SelectFieldWithAvatar
                          name="contact"
                          label="Contact"
                          className="form-group dropdown col-md-8 col-12"
                          options={conOptions}
                          value={conId}
                          disabled={conDisabl}
                          clearable
                        />
                      )}
                      <SelectField
                        name="month"
                        label="Month"
                        className="form-group dropdown col-md-4 col-12"
                        options={this.state.months}
                        handleChange={this.handleMonthChange}
                        value={
                          this.state.monthId
                            ? {
                                value: this.state.monthId,
                                label: this.state.monthValue,
                              }
                            : undefined
                        }
                        clearable
                      />
                    </div>
                  )}
                  <div className="row">
                    <div
                      className={`form-group col-12${
                        this.state.errors.tags ? ' form-error' : ''
                      }`}
                    >
                      <label htmlFor="tagslabel">Tags</label>
                      <div className="labelValue customTags mailDrodown customTagsDropDown">
                        <div className="customTags__input form-control">
                          {this.renderTags()}
                          <label htmlFor="tags" className="inputHelper" />
                          <Input
                            type="text"
                            name="tags"
                            id="tags"
                            onFocus={this.onFocus}
                            onChange={this.changeInput}
                            className="dropdown-toggle"
                            placeholder=""
                            value={this.state.inputVal}
                            autoComplete="off"
                          />
                        </div>
                        <span className="error_label">
                          {this.state.errors.tags}
                        </span>
                        {this.state.dropdownOpen ? (
                          <div className="dropdown-menu show tagsDropDown">
                            <div className="input-group">
                              <input
                                type="text"
                                value={
                                  this.state.tagId
                                    ? {
                                        value: this.state.tagId,
                                        label: this.state.tagName,
                                      }
                                    : undefined
                                }
                                className="form-control border-0"
                                name="owner"
                                onChange={(e) =>
                                  this.filterSearch(e.target.value)
                                }
                                id="myInput"
                              />
                              <span className="input-group-addon border-left-0">
                                <i className="icon-zoom2" />
                              </span>
                            </div>
                            {tags && tags.length ? (
                              tags
                            ) : (
                              <a
                                href="javascript:void(0)"
                                className="dropdown-item"
                              >
                                No records
                              </a>
                            )}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div
                      className={`form-group col-lg-12 dragWrap${
                        this.props.data.errors.documents ? ' form-error' : ''
                      }`}
                    >
                      <label htmlFor="uploadfiles">File</label>
                      <AttachementPortion
                        name={uploadType}
                        className="DragDrop--mh100"
                        handleFileDrop={(e) => {
                          this.props.handleFileDrop(e);
                        }}
                        data={this.props.data}
                        setAttachmentNull={this.props.setAttachmentNull}
                        uploadDocument={this.props.uploadDocument}
                        uploadFiles={this.props.uploadFiles}
                        documents={this.props.documents}
                        cancelUpload={this.props.cancelUpload}
                        openAttachment={this.props.openAttachment}
                        showModal={this.props.showModal}
                        error={this.props.data.errors.documents}
                        newDocs={newDocs}
                        delAttachmentModal={this.props.delAttachmentModal}
                        handleOnedriveFiles={this.handleOnedriveFiles}
                        getAuthenticationToken={this.getAuthenticationToken}
                        onSuccess={this.onSuccess}
                        onCancel={this.onCancel}
                        sharedFolder={this.props.component.sharedFolder}
                        onedriveSharedFileHandle={
                          this.props.component.onedriveSharedFileHandle
                        }
                        handleOnedriveSelectedFiles={
                          this.props.component.handleOnedriveSelectedFiles
                        }
                        component={this.props.component}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 justify-content-end formbtn justify-content-end">
                      <div className="btn-wrap btn--leftspace">
                        <button
                          type="button"
                          className="btn btn-outline-light"
                          onClick={this.handleCancel}
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="btn btn-primary"
                          disabled={this.props.data.isDocumentUpload}
                        >
                          Upload
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <ConfirmationModal
          isOpen={this.state.viewModal}
          headerText="Are you sure want to cancel."
          messageText="This action cannot be undone. Proceed?"
          noBtnClick={this.hideViewModal}
          yesBtnClick={this.hideuploadFileModal}
          yesBtnText="Yes"
          noBtnText="No"
        />
      </div>
    );
  }
}

export default withRouter(UploadFilesPopUp);
