/* eslint-disable no-undef */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-shadow */

import React, { useEffect, useState } from 'react';
import { withRouter } from 'utilities/withRouterWrapper';
import Axios from 'axios';
import { Scrollbars } from 'react-custom-scrollbars-2';
import {
  USER_EMAILS,
  LINK_EMAILS,
  TOAST_FAILURE,
  ENTITY_EMAIL_THREADS,
} from 'constants/constants';
import { getCurrentPath, AlertMessage } from 'utilities/utils';
import { useParams } from 'react-router-dom-latest';
import { useFlags } from 'launchdarkly-react-client-sdk';
import EmailActionsDropdown from './components/EmailActionsDropdown';
import EachMessage from './components/EachMessage';
import EmailLinkedWith from './components/EmailLinkedWith';

const EmailThreadDetails = (props) => {
  const { linkedEmailsRefactor } = useFlags();
  const { id } = useParams();
  const [email, setEmail] = useState();
  const [emailLinkedTo, setEmailLinkedTo] = useState();
  const [composeEmailFor, setComposeEmailFor] = useState('');
  const [composeEmailData, setComposeEmailData] = useState();
  const [connectedAppDetails, setConnectedAppDetails] = useState();
  const [accountEmailLinkedBox, setAccountEmailLinkedBox] = useState(false);
  const [contactEmailLinkedBox, setContactEmailLinkedBox] = useState(false);
  const [isDoneLoading, setIsDoneLoading] = useState(false);

  const actionType = props.location.state?.actionType;

  // eslint-disable-next-line no-shadow
  const composeEmailAction = (
    // eslint-disable-next-line no-shadow
    actionType,
    email_data,
    send_data = [],
    deleteDraft = '',
    discardData = '',
  ) => {
    setComposeEmailFor(actionType);
    setComposeEmailData(email_data);
    let newArr = [];
    if (send_data.length) {
      // this is when new email which is draft is sent
      if (email.length === 1 && email[0].draft_id) {
        newArr = [];
      } else if (deleteDraft) {
        newArr = email.map((each) => {
          if (
            each.draft_data &&
            each.draft_data.draft_id === deleteDraft.draftId
          ) {
            const delDrft = { ...each };
            delete delDrft.draft_data;
            return { ...delDrft, arrow_position: 'down' };
          }
          return { ...each, arrow_position: 'down' };
        });
      } else {
        newArr = email.map((v) => ({ ...v, arrow_position: 'down' }));
      }
      const sendArr = send_data.map((v, index) =>
        index === send_data.length - 1
          ? { ...v, arrow_position: 'up' }
          : { ...v, arrow_position: 'down' },
      );
      setEmail([...newArr, ...sendArr]);
    }
    if (deleteDraft && deleteDraft === 'goBack') {
      props.navigate(-1);
    } else if (deleteDraft && deleteDraft === 'discard' && discardData) {
      const draftId =
        (discardData.draft_data
          ? discardData.draft_data.draft_id
          : discardData.draft_id) || discardData.draftId;
      if (draftId) {
        Axios.delete(`${USER_EMAILS}/${draftId}`);
      }
      if (discardData.draft_data && typeof discardData === 'object') {
        const indx = email.findIndex(
          (e) => e.email_id === discardData.email_id,
        );
        newArr = email.map((v, index) => {
          if (index === indx) {
            const delDrft = { ...v };
            delete delDrft.draft_data;
            return { ...delDrft, arrow_position: 'down' };
          }
          return { ...v };
        });
        setEmail([...newArr]);
        props.refreshPage('discardData');
      }
    }
  };

  // for getting thread details
  const getThreadDetails = async () => {
    const isContactDetail = window.location.href.includes('/contactdetails/');
    const isAccountDetail = window.location.href.includes('/accountdetails/');
    const isEntityDetail = isContactDetail || isAccountDetail;

    try {
      let apiUrl = `${USER_EMAILS}/${props.selectedEmailId}`;

      if (window.location.href.includes('#emails?id=') || isEntityDetail) {
        if (linkedEmailsRefactor && props.entityEmailThreadId) {
          const entityType = isContactDetail ? 'Contact' : 'Account';

          apiUrl = `${ENTITY_EMAIL_THREADS}/${props.entityEmailThreadId}?entity_id=${id}&entity_type=${entityType}`;
        } else {
          apiUrl = `${LINK_EMAILS}/${props.selectedEmailId}`;
        }
      }

      const {
        data: response,
        linked_to: linkedData,
        app_token,
      } = await Axios.get(apiUrl).then((res) => res.data);
      document.title = response[0].subject;
      const emailInThread = response.filter((e) => e.draft_id === null);
      const emailInDraft = response.filter((e) => e.draft_id !== null);
      setConnectedAppDetails(app_token);
      if (emailInDraft.length && emailInThread.length) {
        const emails = emailInThread;
        emailInDraft.map((each) => {
          const indx = emails.findIndex(
            (e) => e.msg_id === each.reply_to_msg_id,
          );
          if (indx !== -1) {
            // emails.splice(indx + 1, 0, each);
            emails[indx].draft_data = each;
          }
          return emails;
        });
        setEmail(emails);
      } else if (!emailInDraft.length && emailInThread.length) {
        setEmail(emailInThread);
      } else if (emailInDraft.length) {
        setEmail(emailInDraft);
      }
      setEmailLinkedTo(linkedData);
      if (actionType && actionType !== 'linked') {
        composeEmailAction(actionType, response[response.length - 1]);
      }
    } catch (error) {
      console.log(error);
      AlertMessage(TOAST_FAILURE);
    }
    setIsDoneLoading(true);
  };

  const toggleArrow = (item) => {
    const threadArray = email.map((ele) => {
      if (ele.email_id === item.email_id) {
        if (ele.arrow_position === 'up') {
          ele.arrow_position = 'down';
        } else {
          ele.arrow_position = 'up';
        }
      }
      return ele;
    });
    setEmail(threadArray);
  };

  useEffect(() => {
    if (props.selectedEmailId) {
      setEmail([]);
      setEmailLinkedTo();
      setComposeEmailFor('');
      setComposeEmailData();
      getThreadDetails();
      setAccountEmailLinkedBox(false);
      setContactEmailLinkedBox(false);
      if (gapi) {
        gapi.load('client');
      }
    }
  }, [props.selectedEmailId, actionType]);

  const unlinkEmail = (refreshthread = '', unlink = []) => {
    const path = getCurrentPath(props.location);
    const { accountId, contactId } = props;
    if (
      ((window.location.href.includes('#emails?id=') ||
        path === '/accountdetails/:id/emails/:email_id' ||
        path === '/contactdetails/:id/emails/:email_id') &&
        !refreshthread) ||
      ((path === '/accountdetails/:id/emails/:email_id' ||
        path === '/contactdetails/:id/emails/:email_id') &&
        (accountId || contactId) &&
        unlink.includes(parseInt(accountId || contactId, 10)) &&
        refreshthread &&
        unlink.length > 0)
    ) {
      props.newPage();
    } else {
      getThreadDetails();
    }
  };

  const redirectLink = (path, id) => {
    const idd = encodeURIComponent(id);
    props.navigate('/redirect');
    setTimeout(() => {
      props.navigate(`${path}${idd}`, { state: { from: 'uploadlist' } });
    }, 50);
  };

  const emailLinkedBox = (type, value) => {
    if (type === 'add_to_account') {
      setAccountEmailLinkedBox(value);
    }
    if (type === 'add_to_contact') {
      setContactEmailLinkedBox(value);
    }
  };

  if (!isDoneLoading) return <div className="loading" />;

  if (props.selectedEmailId && email?.length > 0) {
    return (
      <div className="em_right pl-4 h-100">
        <div className="em_right_head my-2 pt-1">
          <div className="row justify-content-between ml-3 mr-3">
            <div className="col p-0">
              <h4>{email[0].subject}</h4>
            </div>
            <div className="em_right_head_links text-right">
              {email &&
              email.length &&
              (email.length > 1 ||
                (email.length === 1 &&
                  (email[0].draft_data || !email[0].draft_id))) ? (
                <EmailActionsDropdown
                  IconClass="text-primary"
                  emailDetails={email[0]}
                  connectedAppKey={connectedAppDetails.app_key}
                  refreshPage={props.refreshPage ? props.refreshPage : null}
                  nextThread={props.nextThread ? props.nextThread : null}
                  unreadEmail={props.unreadEmail}
                  viewContextFrom="thread"
                  emailLinkedBox={emailLinkedBox}
                  getThreadDetails={getThreadDetails}
                />
              ) : null}
            </div>
          </div>
        </div>
        <div className="em_right_wrap">
          <Scrollbars
            style={{
              width: '100%',
              height: '100%',
              minHeight: '100%',
              maxHeight: '100%',
            }}
            autoHeightMin={0}
            autoHideTimeout={1000}
          >
            {emailLinkedTo || accountEmailLinkedBox || contactEmailLinkedBox ? (
              <EmailLinkedWith
                data={emailLinkedTo}
                refreshPage={unlinkEmail}
                redirectLink={redirectLink}
                emailId={email[0].email_id}
                accountEmailLinkedBox={accountEmailLinkedBox}
                contactEmailLinkedBox={contactEmailLinkedBox}
                emailLinkedBox={emailLinkedBox}
              />
            ) : null}
            {email
              ? email.map((message, index) => (
                  <div key={message.msg_id}>
                    <EachMessage
                      data={message}
                      connectedAppKey={connectedAppDetails.app_key}
                      connectedAppAccessToken={connectedAppDetails.access_token}
                      toggleArrow={toggleArrow}
                      refreshPage={props.refreshPage}
                      composeEmailAction={composeEmailAction}
                      composeEmailFor={composeEmailFor}
                      composeEmailData={composeEmailData}
                      expandMail={
                        message.draft_id === null &&
                        (index === email.length - 1 || email.length === 1)
                      }
                      refreshForVault={unlinkEmail}
                      unreadEmail={props.unreadEmail}
                      showUnreadOption={
                        props.from && props.from === 'linkedEmailAccConTask'
                          ? false
                          : index === email.length - 1
                      }
                    />
                  </div>
                ))
              : ''}
          </Scrollbars>
        </div>
      </div>
    );
  }
  return (
    <div className="em_right pl-4 h-100">
      <div className="em_right_head my-2 py-1">
        {/* <div className="em_right_head_links text-right">
          Links
        </div> */}
      </div>
      <div className="p-3">
        <div className="m-5 p-5 text-center">
          <i className="text-large icon-notification-message mb-2 text-dark" />
          <h3 className="text-medium">Select an item to read</h3>
          <h6 className="text-dark">Nothing is selected</h6>
        </div>
      </div>
    </div>
  );
};

export default withRouter(React.memo(EmailThreadDetails));
