/* eslint-disable */
import React, { Component } from 'react';
import moment from 'moment-timezone';
import { Modal, ModalHeader, ModalBody, ModalFooter, Label } from 'reactstrap';
import axios from 'axios';
import PdfViewer from 'components/PdfViewer/PdfViewer';
import FilePopup from 'components/Files/FilePopup';
import UploadFiles from 'api/UploadFiles';
import { isUserEmployee, getInitials, AlertMessage } from 'utilities/utils';
import ConfirmationModal from 'common/ConfirmationModal';
import { DELETE_RECIPIENTS, ADD_MORE_RECIPIENT, DELETE_FILE_AND_ASSOC } from 'constants/constants';

const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone || moment.tz.guess(true);
const isIE = /* @cc_on!@ */ false || !!document.documentMode;
class ManualSourceModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileDetails: {},
      showAttachmentModal: false,
      selectedAttachment: '',
      operationPerf: false,
      confirmDel: false,
      deleteId: '',
    };
  }

  componentDidMount() {
    const { fileDetails } = this.props;
    this.setState({
      fileDetails,
    });
  }

  toggleBlock = (value) => {
    this.setState({
      showToBlock: value,
    });
  };

  attachmentModalClose = () => {
    this.setState({ showAttachmentModal: false, selectedAttachment: '' });
  };

  removeRecip = () => {
    const { deleteId } = this.state;
    const { fileDetails } = this.state;
    const request = axios.delete(DELETE_RECIPIENTS + '?id=' + deleteId.id + '&document_id=' + fileDetails.id);
    request
      .then((res) => {
        if (res.data.success === true) {
          AlertMessage('success', res.data.message, 1500);
          const { recipient_array } = fileDetails;
          const array = recipient_array.filter((item) => item.id !== deleteId.id);
          fileDetails.recipient_array = array;
          this.setState(
            {
              fileDetails,
              operationPerf: true,
              confirmDel: false,
            },
            () => {
              if (this.props.updateRecipient) {
                this.props.updateRecipient('single');
              }
            },
          );
        } else {
          AlertMessage('error', res.data.message, 1500);
        }
      })
      .catch((err) => {
        AlertMessage('error', 'Something went wrong', 1500);
      });
  };

  openAttachment = () => {
    const { fileDetails } = this.state;
    UploadFiles.showOpenAttachment(fileDetails, this);
  };

  saveRecipient = (uniqueRecords) => {
    const { fileDetails } = this.state;
    const array = [];
    uniqueRecords.map((each) => {
      const newArray = [];
      newArray[0] = each.contact ? each.contact.id : '';
      newArray[1] = each.account ? each.account.value : '';
      array.push(newArray);
      return array;
    });
    const data = {
      doc_id: fileDetails.id,
      recipients: array,
    };
    const request = axios.post(ADD_MORE_RECIPIENT, data);
    request
      .then((res) => {
        if (res.data.success === true) {
          AlertMessage('success', 'Recipients added Successfully', 1500);
          this.setState(
            {
              fileDetails: res.data.documents[0],
              operationPerf: true,
            },
            () => {
              if (this.props.updateRecipient) {
                this.props.updateRecipient('saveRecipient');
              }
            },
          );
          this.toggleBlock(false);
        } else {
          AlertMessage('error', res.data.message, 1500);
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  deleteAll = () => {
    const { fileDetails } = this.state;
    const request = axios.delete(DELETE_FILE_AND_ASSOC + '/' + fileDetails.id);
    request
      .then((res) => {
        if (res.data.success === true) {
          AlertMessage('success', res.data.message, 1500);
          this.setState({
            operationPerf: true,
            confirmDel: false,
          });
          this.props.closeModal('showManualSource', true);
          if (this.props.updateRecipient) {
            this.props.updateRecipient('deleteall');
          }
        } else {
          AlertMessage('error', res.data.message, 1500);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  confirmDel = (type, each) => {
    this.setState({
      confirmDel: true,
      deleteId: each,
      deleteType: type,
    });
  };

  render() {
    const { fileDetails, operationPerf, deleteType } = this.state;
    return (
      <div id="content">
        <div className="center-wrapper">
          <Modal className="customModal customModal--w600 customModal--nopadding" isOpen>
            <div className="ModalHeader">
              <button
                onClick={() => this.props.closeModal('showManualSource', operationPerf)}
                type="button"
                className="close"
                aria-label="Close"
              >
                <i className="icon-close2" aria-hidden="true" />
              </button>
            </div>
            <ModalBody className="modalContent" style={{ overflowY: 'visible' }}>
              <div className="modalContent--inner">
                <div className="modalContent--header mb-0">
                  <div className="row align-items-start">
                    <div className="col-md-8 col-12">
                      <h4 className="text-medium mb-0">{fileDetails.doc_name}</h4>
                    </div>
                    <div className="col-md-4 col-12">
                      <div className="d-flex justify-content-end">
                        {isUserEmployee() || (!isUserEmployee() && fileDetails.is_private) ? (
                          <a className="text-dark" onClick={() => this.confirmDel('deleteAll')}>
                            <i className="icon-delete-small" />
                          </a>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <form>
                  <div className="form-group form-inline-label mt-3">
                    <Label for="forContact">UPLOADED BY</Label>
                    <div className="labelValue">
                      <div className="avatar avatar--w20">
                        {fileDetails.uploader ? (
                          fileDetails.uploader.avatar ? (
                            <img src={fileDetails.uploader.avatar} alt="" />
                          ) : fileDetails.uploader.initials ? (
                            <span className="uName">{fileDetails.uploader.initials}</span>
                          ) : (
                            '-'
                          )
                        ) : (
                          '-'
                        )}
                        <span className="userName">{fileDetails.uploaded_by || '-'}</span>
                      </div>
                    </div>
                  </div>
                  <div className="form-group form-inline-label">
                    <Label for="forContact">Source</Label>
                    <div className="labelValue">
                      <div className="avatar">
                        <span className="userName">Manual</span>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <Label for="forContact">Recipients</Label>
                    <div className="rcpList">
                      {fileDetails.recipient_array && fileDetails.recipient_array.length
                        ? fileDetails.recipient_array.map((each) => (
                            <div key={each.id} className="d-flex pr-3 py-1 posRel">
                              <span className="avatar avatar--w20 align-self-center">
                                <span className="uName">{getInitials(each.contact) || ''}</span>
                              </span>
                              <div>
                                <h5 className="fontSize letterSpace-1 mb-0 ml-1">{each.contact}</h5>
                                <h4 className="subHeadline text-dark mb-0 ml-1">{each.account}</h4>
                              </div>
                              {isUserEmployee() && (
                                <a onClick={() => this.confirmDel('single', each)} className="linkRmv">
                                  <i className="icon-close2" />
                                </a>
                              )}
                            </div>
                          ))
                        : null}
                    </div>
                  </div>
                  {this.state.showToBlock ? (
                    <div className="shBlock mt-4 border-top pt-3">
                      <FilePopup
                        popupType="manualPopup"
                        saveRecipient={this.saveRecipient}
                        closeModal={() => this.setState({ showToBlock: false })}
                      />
                    </div>
                  ) : (
                    isUserEmployee() && (
                      <div className="formbtn">
                        <div className="btn-wrap btn--leftspace">
                          <button type="button" className="btn btn-primary" onClick={() => this.toggleBlock(true)}>
                            Add More Recipients
                          </button>
                        </div>
                      </div>
                    )
                  )}
                </form>
              </div>
            </ModalBody>
          </Modal>
          <Modal
            isOpen={this.state.showAttachmentModal}
            className="customModal customModal--w990 customModal--nopadding"
          >
            <ModalHeader className="ModalHeader">
              <button type="button" onClick={this.attachmentModalClose} className="close" aria-label="Close">
                <i className="icon-close2" aria-hidden="true" />
              </button>
            </ModalHeader>
            <ModalBody className="modalContent">
              <div className="modalContent--inner">
                <div className={isIE ? 'left-inner-content' : 'sample-letter-preview'}>
                  <div className="letter-logo" />
                  {isIE ? (
                    <PdfViewer file={this.state.selectedAttachment} />
                  ) : (
                    <iframe width="100%" height="600" src={this.state.selectedAttachment} title="selected attachment" />
                  )}
                </div>
              </div>
            </ModalBody>
          </Modal>
          <ConfirmationModal
            isOpen={this.state.confirmDel}
            headerText="You are about to delete the recipient"
            messageText="This action cannot be undone. Proceed?"
            noBtnClick={() => this.setState({ confirmDel: false, deleteId: '' })}
            yesBtnClick={deleteType === 'deleteAll' ? this.deleteAll : this.removeRecip}
            yesBtnText="Yes"
            noBtnText="No"
          />
        </div>
      </div>
    );
  }
}

export default ManualSourceModal;
