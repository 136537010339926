/*eslint-disable */
// JavaScript Document

//prepare variables
import $ from 'jquery';
var degree = 0;

var maxtalk = 0;
var talkbubble = 1;


$(document).ready(function(){
	
	//clear input text when clicked
	inputTextFix();
	
	//count talk bubbles
	$("div.bubble-options p.dog-bubble").each(function(){
	
		maxtalk++;
									 
	});		
	
});

//function that implements a input text hotfix; remove if you don't like it
function inputTextFix(){

	$("input[type='text'], input[type='password']").each(function(){
			
		//each time a user clicks on a input field
		$(this).click(function(){
						
			//save the current value, if any
			if($(this).attr("value")!=""){
				
				$(this).attr("previous_value", $(this).attr("value"));
				$(this).attr("value","");   
			
			}

		});
		
		//on blur, if left empty, restore the saved value, if any
		$(this).blur(function(){
					
			if($(this).attr("value") == "")
				$(this).attr("value",$(this).attr("previous_value"));					  
							  
		});
										   
	});
	
}

//function that handles the universal preloader positioning and alignment
 export function universalPreloader(){
		
	var pre = $("#universal-preloader>div");
	
	//centering function
	$.fn.center = function () {
		this.css("position","absolute");
		this.css("top", (($(window).height() - this.outerHeight()) / 2) + "px");
		this.css("left", (($(window).width() - this.outerWidth()) / 2) + "px");
		return this;
	}
	
	//center to the screen
	pre.center();
	
	//run each time user resizes window
	$(window).resize(function() {
	
		pre.center();
							  
	});
	
}

//function that disposes the universal preloader when everything is loaded; called on window.load event
export function universalPreloaderRemove(){
		
	var parentD = $("#universal-preloader");
	var pre = $("#universal-preloader>div");
	
	var logo = $("#universal-preloader .universal-preloader-logo");
	var loader = $("#universal-preloader .universal-preloader-preloader");
	
	//when the logo and ajax-loader fades out, fade out the curtain; when that completes, remove the universal preloader from the DOM
	pre.delay(1000).animate({opacity:'0'},{duration:400, complete:function(){
	
		parentD.animate({opacity:'0'},{duration:400, complete:function(){
		
			parentD.remove();
		
		}});
																																		 
	}});
	
	
}


//function that handles the talking dog bubble animations
export function dogTalk(){
		
	var timer = setTimeout(function() {
		
		//change the bubble html code
		var temp = "<p>"+$("div.bubble-options p.dog-bubble:nth-child("+talkbubble+")").html()+"</p>";		
		$("div.dog-bubble").html(temp);
		
		//randomize bubble-option
		//talkbubble = Math.floor((Math.random()*maxtalk)+1);

		//browse through bubble-options
		if(talkbubble<maxtalk)
			talkbubble++;
		else
			talkbubble = 1;
		
		//show the bubble
		$(".dog-bubble").animate({"opacity":'1', "bottom":'10px'}, 400);
		
		//hide the bubble
		setTimeout(function() {
			
			$(".dog-bubble").animate({"opacity":'0', "bottom":'0px'}, 400);
			dogTalk();	
		
		}, 5000);		
		
	}, 2000);	
	
}

//function that handles the planet animation
export function rotate() {

	var planet = $("div.planet>img");

	  //CSS3
	planet.css({ 'transform' : 'rotate(' + degree + 'deg)'}); 
	  // For webkit browsers: e.g. Chrome
	planet.css({ WebkitTransform : 'rotate(' + degree*2 + 'deg)'});
	  // For Mozilla browser: e.g. Firefox
	planet.css({ '-moz-transform' : 'rotate(' + degree + 'deg)'});
	  //IE9
	planet.css({ '-ms-transform' : 'rotate(' + degree + 'deg)'});
	  //Opera
	planet.css({ '-o-transform' : 'rotate(' + degree + 'deg)'});
		
	// Animate rotation with a recursive call
	var timer = setTimeout(function() {
		degree-=0.1; 
		rotate();
	},10);

}

//function that handles dog movement animation
export function dogRun(){
	
	var dog = $("div.dog");
	
	var timer2 = setTimeout(function() {
								 
		if(dog.css("background-position") == "0px 0px")
			dog.css({"background-position":"-80px -2px"});
		else
			dog.css({"background-position":"0px 0px"});							 
			
		dogRun();	
		
	}, 130);	
	
}