import LoginV5ResetPassword from 'components/Login/LoginV5/LoginV5ResetPassword';
import { useInitializedFlags } from 'hooks/useInitializedFlags';
import LoaderDefault from 'common/LoaderDefault';
import ForgotPassword from './ForgotPassword';

export const ForgotPasswordFlagged = () => {
  const {
    isInitialized,
    flags: { v5Login },
  } = useInitializedFlags();

  if (!isInitialized) {
    return <LoaderDefault />;
  }

  if (v5Login) {
    return <LoginV5ResetPassword />;
  }

  return <ForgotPassword />;
};

export default ForgotPasswordFlagged;
