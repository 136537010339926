import { useLocation } from 'react-router-dom-latest';

export default function useWhichSection() {
  const { pathname } = useLocation();
  const inContactSection = pathname.includes('contactdetails');
  const inAccountSection = pathname.includes('accountdetails');
  const inLinkedSection = inContactSection || inAccountSection;

  return { inContactSection, inAccountSection, inLinkedSection };
}
