/* eslint-disable */
import React, { Component } from 'react';
import { removeBreakElement } from 'common/NotesUtilities';
import DraftEditor from 'common/DraftEditor';
import * as TaskApi from 'api/TaskApi';
import ConfirmationModal from 'common/ConfirmationModal';
import TaskFileUpload from 'components/Files/TaskFileUpload';
import { withRouter } from 'utilities/withRouterWrapper';
import CommonFields from '../CommonFields';
import TinymceEditor from 'components/Emails/components/TinymceEditior';
import { format } from 'date-fns';

const noneStyle = { display: 'none' };
const blockStyle = { display: 'block' };

class EDoc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stateValues: {},
      taskTitle: '',
      taskStatus: '',
      documents: [],
      comment: '',
      viewModal: false,
      loading: false,
      dataUpdated: false,
      errors: {},
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.taskDetail.description !== this.props.taskDetail.description
    ) {
      this.setState({ comment: this.props.taskDetail.description });
    }
  }

  UNSAFE_componentWillReceiveProps() {
    const { data } = this.props;
    if (
      Object.keys(this.props.taskDetail).length &&
      data.isEdit &&
      !data.changedOnce
    ) {
      const detail = this.props.taskDetail;
      this.setState({
        taskTitle: detail.subject,
        comment:
          detail.description_in_json && detail.description_in_json !== null
            ? JSON.parse(detail.description_in_json)
            : removeBreakElement(detail.description),
        documents: detail.documents,
        taskStatus: detail.status,
      });
    }
  }

  stateChange = (value, dataUpdated = false) => {
    const stateValues = { ...this.state.stateValues, ...value };
    this.setState({
      stateValues,
      dataUpdated,
    });
  };

  handleCancel = () => {
    const { stateValues, dataUpdated } = this.state;
    if (stateValues.isChanged || dataUpdated) {
      this.setState({ viewModal: true });
    } else {
      this.props.goBack();
    }
  };

  hideViewModal = () => {
    this.setState({ viewModal: false });
  };

  nextPage = () => {
    const { state } = this.props.location;
    if (
      this.props.data.isEdit &&
      state !== undefined &&
      state.from === '/task/detail'
    ) {
      const { match, params } = this.props;
      this.props.navigate(`/task/detail/${params.id}`);
    } else {
      this.props.navigate(-1);
    }
  };

  emptyError = () => {
    // eslint-disable-next-line react/no-unused-state
    this.setState({ errors: {} });
  };

  submitForm = (e) => {
    e.preventDefault();
    const { match } = this.props;
    const { stateValues, comment, taskTitle } = this.state;
    const mydata = {};
    if (this.props.data.isEdit) {
      mydata.cpa_id = stateValues.cpaId;
      mydata.subject = taskTitle;
      mydata.due_by = format(stateValues.startDate, 'dd/MM/yyyy');
      mydata.description = comment;
      if (stateValues.ownerChanged) {
        mydata.owner_id = stateValues.ownerId;
      }
    }
    if (this.state.documents.length && !this.props.data.isEdit) {
      mydata.doc_ids = [];
      let x;
      for (x = 0; x < this.state.documents.length; x += 1) {
        mydata.doc_ids.push(this.state.documents[x].id);
      }
    }
    if (this.props.data.isEdit) {
      const { id } = this.props.params;
      TaskApi.createTask(this, mydata, id, this.props.data.taskTypeKey); // updates task
    }
    return false;
  };

  updateDocumentState = (documents) => {
    this.setState({ documents });
  };

  render() {
    const { taskTitle, taskStatus, stateValues, dataUpdated } = this.state;
    const { data } = this.props;
    const { task_type_key } = this.props.taskDetail;

    return (
      <div>
        <div className="miscellaneous">
          <div
            id="loading"
            style={this.state.loading ? blockStyle : noneStyle}
            data-testid="loading"
          />
          <CommonFields
            data={data}
            statedata={this.state}
            taskDetail={this.props.taskDetail}
            stateChange={this.stateChange}
            emptyError={this.emptyError}
          />
          <div className="row">
            <div className="form-group col-12">
              <label htmlFor="taskTitle">Subject</label>
              <input
                autoComplete="off"
                disabled={task_type_key === 'edoc'}
                name="taskTitle"
                className="form-control"
                id="taskTitle"
                type="text"
                value={taskTitle}
                maxLength="200"
                data-testid="taskTitleInput"
              />
            </div>
          </div>
          <div className="row">
            <div className="form-group col-lg-12">
              <div className="has-float-label customEditor boxResize">
                <label htmlFor="taskDescription">Description</label>
                <TinymceEditor
                  initialValue=""
                  value={this.state.comment}
                  disabled={task_type_key === 'edoc'}
                  testid="task__description"
                />
              </div>
            </div>
          </div>
          {((data.isEdit && this.state.documents.length) || !data.isEdit) && (
            <div className="row">
              <div className="form-group dragWrap col-lg-12">
                <label htmlFor="attachments">Attachments</label>
                <TaskFileUpload
                  data={this.state}
                  uploadType="task"
                  isEdit={data.isEdit}
                  updateDocumentState={this.updateDocumentState}
                />
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-12 form-group formbtn justify-content-end btn--leftspace">
              <div className="btn-wrap btn--leftspace">
                <button
                  type="button"
                  className="btn btn-outline-light"
                  onClick={this.handleCancel}
                  disabled={taskStatus === 'Closed'}
                  data-testid="taskTitleInput"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary task-form-submit"
                  data-testid="task__submit_btn"
                  onClick={this.submitForm}
                  disabled={
                    taskStatus === 'Closed' ||
                    (data.isEdit && !dataUpdated && !stateValues.isChanged)
                  }
                >
                  Update Task
                </button>
              </div>
            </div>
          </div>
        </div>
        <ConfirmationModal
          isOpen={this.state.viewModal}
          headerText="Are you sure want to cancel."
          messageText="This action cannot be undone. Proceed?"
          noBtnClick={this.hideViewModal}
          yesBtnClick={this.nextPage}
          yesBtnText="Yes"
          noBtnText="No"
        />
      </div>
    );
  }
}

export default withRouter(EDoc);
