import moment from 'moment';
import React from 'react';
import {
  ACCOUNT_LABEL,
  DATE_RANGE,
  INVOICE_TYPE,
  MONTHLY,
  MONTHS,
  OWNER,
  STATUS,
  ACCOUNT_COL,
  START_DATE_COL,
  END_DATE_COL,
  STATUS_COL,
  PAYMENT_METHOD_COL,
  LAST_ACTIVITY_COL,
  AMOUNT_COL,
  OWNER_COL,
  FREQUENCY_COL,
  DUE_DATE_COL,
  NEXT_CHARGE_COL,
  INVOICE_TYPE_COL,
} from 'constants/constants';

export const defaultBillingTabs = [
  {
    value: 'invoices',
    label: 'Invoices',
    url: '/billing/invoices',
  },
  {
    value: 'recurring',
    label: 'Recurring',
    url: '/billing/recurring',
  },
];

export function TabPanel({ children, value, index }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

export function formReducer(state, action) {
  switch (action.type) {
    case 'INPUT':
      return {
        ...state,
        [action.fieldName]: action.fieldValue,
      };

    case 'DATE':
      return {
        ...state,
        [action.fieldName]: action.fieldValue,
      };

    case 'CLEAR':
      return action.initialFormState;

    case 'UPDATE_ALL':
      return action.updatedState;

    default:
      return state;
  }
}

export const isInAccountView = () => {
  const currentURL = window.location.pathname || '';
  const regex = /^\/accountdetails\/[0-9]+\/billing\//;

  return regex.test(currentURL);
};

/**
 * returns a list of filter options generated from the input array
 * @param {*} invoiceData
 * @returns { status: [String] } authorization status
 */

export const generateFilterOptions = (invoiceData) => {
  if (!Array.isArray(invoiceData)) {
    return null;
  }

  const options = {
    oneTime: {
      status: ['unpaid', 'overdue', 'failed', 'processing', 'paid', 'void'],
      invoiceTypes: ['One Time', 'Recurring'],
      dateRange: {
        start: null,
        end: null,
      },
      owner: {},
    },
    recurring: {
      status: ['pending', 'authorized', 'failed', 'closed'],
      owner: {},
    },
  };
  if (!isInAccountView()) {
    options.recurring.account = {};
    options.oneTime.account = {};
  }
  return options;
};

export const buildURLFilterParams = (
  filtersSelected,
  activeTab,
  textSearch = {},
) => {
  const { recurring, oneTime } = filtersSelected;
  const activeTabFilters = activeTab === 0 ? oneTime : recurring;
  let reqParams = '';
  activeTabFilters.forEach((option) => {
    const { type, value } = option;
    switch (type) {
      case STATUS:
        if (Array.isArray(value) && value.length > 0)
          reqParams += `&status=${value.join(',')}`;
        break;
      case DATE_RANGE:
        {
          const { start, end } = value || {};
          if (start && end) {
            reqParams += `&start_date=${moment(start).format('YYYY-MM-DD')}`;
            reqParams += `&end_date=${moment(end).format('YYYY-MM-DD')}`;
          }
        }
        break;
      case INVOICE_TYPE:
        if (value) {
          const invoiceType =
            value.toLowerCase() === 'recurring' ? 'recurring' : 'one_time';
          reqParams += `&invoice_type=${invoiceType}`;
        }
        break;
      case ACCOUNT_LABEL:
        if (value && value.accountId)
          reqParams += `&account=${value.accountId}`;
        break;
      case OWNER:
        if (value && value.ownerId) reqParams += `&owner=${value.ownerId}`;
        break;
      default:
        break;
    }
  });
  if (textSearch.text[activeTab]) {
    reqParams += `&search=${textSearch.text[activeTab]}`;
  }

  return reqParams;
};

export const initialOneTimeFilters = [
  {
    type: STATUS,
    value: [],
  },
  {
    type: INVOICE_TYPE,
    value: '',
  },
  {
    type: DATE_RANGE,
    value: {
      start: '',
      end: '',
    },
  },
  {
    type: OWNER,
    value: {},
  },
];
export const initialRecurringFilters = [
  {
    type: STATUS,
    value: [],
  },
  {
    type: OWNER,
    value: {},
  },
];

export const accountFilter = {
  type: ACCOUNT_LABEL,
  value: {},
};

export const recurringFrequencyStr = (int) =>
  int === 1 ? MONTHLY : `${int}${MONTHS}`;

export function DisplayRecInvoiceType(props) {
  const { is_recurring, int } = props;
  if (is_recurring) {
    return (
      <>
        <div>Recurring</div>
        <div className="BillingTableRow__SubTitle">
          {recurringFrequencyStr(int)}
        </div>
      </>
    );
  }

  return null;
}

export function getInitialFiltersSelected() {
  const hideAccountFilter = isInAccountView();
  if (hideAccountFilter) {
    return {
      initialOneTimeFilters,
      initialRecurringFilters,
    };
  }
  return {
    initialOneTimeFilters: [...initialOneTimeFilters, accountFilter],
    initialRecurringFilters: [...initialRecurringFilters, accountFilter],
  };
}

export const oneTimeInvoiceColumns = [
  { name: ACCOUNT_COL, id: 'account', align: 'text-left', width: '25%' },
  { name: OWNER_COL, id: 'owner', align: 'text-left', width: '10%' },
  { name: STATUS_COL, id: 'status', align: 'text-left', width: '10%' },
  {
    name: PAYMENT_METHOD_COL,
    id: 'payment_method',
    align: 'text-left',
    width: '10%',
  },
  {
    name: INVOICE_TYPE_COL,
    id: 'invoice_type',
    align: 'text-left',
    width: '10%',
  },
  { name: DUE_DATE_COL, id: 'due_date', align: 'text-left', width: '6%' },
  { name: AMOUNT_COL, id: 'amount', align: 'text-right', width: '6%' },
  {
    name: LAST_ACTIVITY_COL,
    id: 'last_activity',
    align: 'text-left',
    width: '10%',
  },
];

export const recurringInvoiceColumns = [
  { name: ACCOUNT_COL, id: 'account', align: 'text-left', width: '22%' },
  { name: OWNER_COL, id: 'owner', align: 'text-left', width: '11%' },
  { name: STATUS_COL, id: 'status', align: 'text-left', width: '11%' },
  { name: START_DATE_COL, id: 'start_date', align: 'text-left', width: '11%' },
  { name: END_DATE_COL, id: 'end_date', align: 'text-left', width: '11%' },
  { name: FREQUENCY_COL, id: 'frequency', align: 'text-left', width: '11%' },
  {
    name: NEXT_CHARGE_COL,
    id: 'next_charge',
    align: 'text-left',
    width: '11%',
  },
  { name: AMOUNT_COL, id: 'amount', align: 'text-right', width: '11%' },
  {
    name: LAST_ACTIVITY_COL,
    id: 'last_activity',
    align: 'text-left',
    width: '11%',
  },
];

export const messageObj = {
  new: 'Please continue set-up',
  need_more_info: 'Please continue set-up',
  requirements_due: 'Please update account details',
  pending: 'Account is in review',
  set_up_in_progress: 'Set-up in progress',
  no_account: 'Set Up Payments',
};
