/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/prefer-default-export */
import React from 'react';
import {
  useNavigate,
  useLocation,
  useMatch,
  useParams,
} from 'react-router-dom-latest';

export const withRouter = (Component) => {
  const Wrapper = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const match = useMatch(location.pathname);
    const params = useParams();

    return (
      <Component
        {...props}
        navigate={navigate}
        location={location}
        match={match}
        params={params}
      />
    );
  };

  return Wrapper;
};
