import React from 'react';
import PropTypes from 'prop-types';
import { ASC } from 'constants/constants';
import styled from '@emotion/styled';

const StyledTH = styled.th`
  color: ${({ sortBy, colId }) =>
    sortBy === colId ? '#212b36 !important' : '#919eab'};
`;
const TableColumns = ({
  colName,
  align,
  handleColumnSorting,
  colId,
  sortBy,
  sortOrder,
  width,
}) => {
  const sortIcon =
    sortOrder === ASC ? (
      <i className="icon-up-arrow" />
    ) : (
      <i className="icon-down-arrow" />
    );

  return (
    <StyledTH
      scope="col"
      className={align}
      sortBy={sortBy}
      colId={colId}
      width={width}
      onClick={() => handleColumnSorting(colId)}
    >
      {colName}
      {sortBy === colId && sortIcon}
    </StyledTH>
  );
};

export default TableColumns;

TableColumns.propTypes = {
  colName: PropTypes.string.isRequired,
  align: PropTypes.string,
  handleColumnSorting: PropTypes.func.isRequired,
  sortOrder: PropTypes.string.isRequired,
  sortBy: PropTypes.string.isRequired,
};
