import React from 'react';
import Select from 'react-select';
import { selectTheme, selectStyles } from 'common/CustomFields';
import AddressAutoComplete from './AddressAutoComplete';
import USStates from './usastates.json';

const addressFieldClassNames = {
  addressLine1ClassName: 'col-sm-12 col-lg-6 col-md-12 form-group',
  addressLine2ClassName: 'col-sm-12 col-lg-6 col-md-12 form-group',
  cityClassName: 'col-sm-12 col-lg-4 col-md-12 form-group',
  stateClassName: 'col-sm-12 col-lg-2 col-md-12 form-group',
  countryClassName: 'col-sm-12 col-lg-4 col-md-12 form-group',
  zipClassName: 'col-sm-12 col-lg-2 col-md-12 form-group',
};

const addressFieldlabels = {
  addressLine1Label: 'Address Line 1',
  addressLine2Label: 'Address Line 2',
  cityLabel: 'City',
  stateLabel: 'State / Province',
  countryLabel: 'Country',
  zipLabel: 'Zip / Postal Code',
};

const AddressInputField = (props) => (
  <div
    className={
      props.className
        ? props.className + (props.error ? ' form-error' : '')
        : 'col'
    }
  >
    <div>
      <label htmlFor="random">{props.label}</label>
      <div
        className={props.passwordShow ? 'labelValue inputGroup' : 'labelValue'}
      >
        <input type="text" className="hidden" id="random" />
      </div>
    </div>
    <div>
      <label htmlFor={props.name} className="hidden">
        Hello
      </label>
      <div
        className={props.passwordShow ? 'labelValue inputGroup' : 'labelValue'}
      >
        <input
          type={props.type ? props.type : 'text'}
          className="form-control"
          id={props.name}
          placeholder={props.placeholder ? props.placeholder : ''}
          onChange={(input) => props.onChange(input.target.value, props.name)}
          onBlur={(input) =>
            props.onBlur ? props.onBlur(input.target.value, props.name) : ''
          }
          value={props.value ? props.value : ''}
          disabled={props.disabled}
          autoComplete={props.autoComplete ? props.autoComplete : 'off'}
        />
        {props.passwordShow ? (
          <span className="inputHelpIcon" onClick={props.showHide}>
            <i
              className={
                props.passwordShow === 'password'
                  ? 'icon-close-eye'
                  : 'icon-open-eye'
              }
              aria-hidden="true"
            />
          </span>
        ) : (
          ''
        )}
        <span className="error_label">{props.error}</span>
      </div>
    </div>
  </div>
);

export const AddressSelectField = (props) => (
  <div
    className={
      props.className
        ? props.className + (props.error ? ' form-error' : '')
        : 'col'
    }
  >
    <div>
      <label htmlFor="random">{props.label}</label>
      <div
        className={props.passwordShow ? 'labelValue inputGroup' : 'labelValue'}
      >
        <input type="text" className="hidden" id="random" />
      </div>
    </div>
    <div>
      <label className="hidden" htmlFor={props.name}>
        {props.label}
      </label>
      <div className="labelValue">
        <Select
          id={props.name}
          name={props.name}
          options={props.options}
          onChange={props.handleChange}
          onInputChange={props.handleInputChange}
          value={props.value}
          className="select-custom-class"
          clearable={props.clearable}
          disabled={props.disabled}
          inputProps={{ autoComplete: 'off' }}
          onBlur={props.onBlur}
          isLoading={props.isLoading}
          noResultsText={props.noResultsText ? props.noResultsText : 'No Data'}
          onMenuScrollToBottom={props.onMenuScrollToBottom}
          onOpen={props.onOpen}
          isClearable={props.clearable}
          styles={selectStyles}
          theme={selectTheme}
        />
        <span className="error_label">{props.error}</span>
      </div>
    </div>
  </div>
);

const AddressComponent = (props) => {
  const { data, errors, disabled } = props;
  const {
    addressLine1Name,
    addressLine2Name,
    cityName,
    stateName,
    countryName,
    zipName,
  } = props.fieldNames;
  const {
    addressLine1ClassName,
    addressLine2ClassName,
    cityClassName,
    stateClassName,
    countryClassName,
    zipClassName,
  } = props.fieldClassNames ? props.fieldClassNames : addressFieldClassNames;
  const {
    addressLine1Label,
    addressLine2Label,
    cityLabel,
    stateLabel,
    countryLabel,
    zipLabel,
  } = props.label ? props.label : addressFieldlabels;
  return [
    <div
      className={props.className ? props.className : 'row'}
      key={`${props.name}row1`}
    >
      <div className={addressLine1ClassName}>
        <AddressAutoComplete
          onChange={(input) => props.onChange(addressLine1Name, input)}
          value={
            data !== null && data[addressLine1Name]
              ? data[addressLine1Name]
              : ''
          }
          label={addressLine1Label}
          name={`${props.name}Address1`}
          onBlur={props.onBlur}
          handleSelect={(input) => props.handleAddressSelect(input)}
          fieldNames={props.fieldNames}
          error={errors ? errors[addressLine1Name] : undefined}
          disabled={disabled}
        />
      </div>
      <AddressInputField
        onChange={(input) => props.onChange(addressLine2Name, input)}
        onBlur={props.onBlur}
        name={`${props.name}random1`}
        type="text"
        label={addressLine2Label}
        value={
          data !== null && data[addressLine2Name] ? data[addressLine2Name] : ''
        }
        error={errors ? errors[addressLine2Name] : undefined}
        className={addressLine2ClassName}
        disabled={disabled}
      />
    </div>,
    <div className="row" key={`${props.name}row2`}>
      <AddressInputField
        onChange={(input) => props.onChange(cityName, input)}
        onBlur={props.onBlur}
        name={`${props.name}random2`}
        type="text"
        label={cityLabel}
        value={data !== null && data[cityName] ? data[cityName] : ''}
        error={errors ? errors[cityName] : undefined}
        className={cityClassName}
        disabled={disabled}
      />
      {data !== null &&
      (data[countryName] === undefined ||
        !data[countryName] ||
        data[countryName].length === 0 ||
        data[countryName].toLowerCase() === 'us' ||
        data[countryName].toLowerCase() === 'usa' ||
        data[countryName].toLowerCase() === 'united states of america') ? (
        <AddressSelectField
          id="random3"
          name="random3"
          options={USStates}
          handleChange={(input) => props.onChange(stateName, input)}
          value={
            data && data[stateName]
              ? { value: data[stateName], label: data[stateName] }
              : null
          }
          error={errors ? errors[stateName] : undefined}
          disabled={disabled}
          onBlur={props.onBlur}
          label={stateLabel}
          className={stateClassName}
          clearable
        />
      ) : (
        <AddressInputField
          onChange={(input) => props.onChange(stateName, input)}
          onBlur={props.onBlur}
          name="random4'"
          type="text"
          label={stateLabel}
          value={data !== null && data[stateName] ? data[stateName] : ''}
          error={errors ? errors[stateName] : undefined}
          className={stateClassName}
          disabled={disabled}
        />
      )}
      <AddressInputField
        onChange={(input) => props.onChange(zipName, input)}
        onBlur={props.onBlur}
        name="random5"
        type="text"
        label={zipLabel}
        value={data !== null && data[zipName] ? data[zipName] : ''}
        error={errors ? errors[zipName] : undefined}
        className={zipClassName}
        disabled={disabled}
      />
      <AddressInputField
        onChange={(input) => props.onChange(countryName, input)}
        onBlur={props.onBlur}
        name="random6"
        type="text"
        label={countryLabel}
        value={data !== null && data[countryName] ? data[countryName] : ''}
        error={errors ? errors[countryName] : undefined}
        className={countryClassName}
        disabled={disabled}
      />
      {/* <SelectField
        id={'country' + props.name}
        name={'country' + props.name}
        options={countries}
        label={countryLabel}
        handleChange={input => props.onChange(countryName, input)}
        onBlur={props.onBlur}
        value={{ value: data !== null ? data[countryName] : '', label: data !== null ? data[countryName] : '' }}
        error={errors ? errors[countryName] : undefined}
        className={countryClassName}
        disabled={disabled}
      /> */}
    </div>,
  ];
};

export default AddressComponent;
