import ActionsModal from 'common/ActionsModal';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom-latest';
import {
  CREATE_NOTE,
  CREATE_TASK,
  MARK_UNREAD,
  UNREAD_MESSAGE,
} from 'constants/constants';
import { markItemUnread, getCpaUserId } from 'common/CommonActions';
import { isClient } from 'utilities/utils';
import {
  gatherThreadDetails,
  SelectedOptionForm,
} from '../../../common/MessageUtils';

export const MarkAsUnreadThreadBtn = ({ message, hidden = true }) => {
  if (hidden || !process.env.REACT_APP_MESSAGE_ACTIONS_FLAG) {
    return null;
  }
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const navigate = useNavigate();
  const handleClick = () => {
    markItemUnread({
      url: UNREAD_MESSAGE,
      params: {
        cpa_user_id: getCpaUserId(),
        message_id: message.id,
      },
      postUpdate: () => navigate(-1),
    });
  };
  return (
    <div
      className="btn btn-sm btn-outline-light btn--onlyicon mt-icon"
      title={MARK_UNREAD}
    >
      <div className="unread-dot" />
      <MessageOutlinedIcon
        fontSize="medium"
        color="#919eab"
        onClick={handleClick}
      />
    </div>
  );
};

export const CreateTaskThreadBtn = ({ account_data, threads }) => {
  if (!process.env.REACT_APP_MESSAGE_ACTIONS_FLAG) {
    return null;
  }
  const isUserClient = isClient();
  const message = gatherThreadDetails(threads, account_data);
  if (isUserClient) {
    return null;
  }
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [showModal, setShowModal] = useState(false);
  const closeActionsModel = () => setShowModal(false);
  const handleClick = () => {
    setShowModal(!showModal);
  };
  return (
    <div
      className="btn btn-sm btn-outline-light btn--onlyicon mt-icon"
      title={CREATE_TASK}
    >
      <CheckBoxOutlinedIcon
        fontSize="medium"
        color="#919eab"
        onClick={handleClick}
      />
      <ActionsModal
        isOpen={showModal}
        onCancel={closeActionsModel}
        style={{ overflow: 'auto', maxHeight: '85vh' }}
        showModalFooter={false}
      >
        <SelectedOptionForm
          selectedOption={{ value: CREATE_TASK }}
          closeActionsModel={closeActionsModel}
          message={message}
        />
      </ActionsModal>
    </div>
  );
};

export const CreateNoteThreadBtn = ({ account_data, threads }) => {
  if (!process.env.REACT_APP_MESSAGE_ACTIONS_FLAG) {
    return null;
  }
  const isUserClient = isClient();
  if (isUserClient) {
    return null;
  }
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [showModal, setShowModal] = useState(false);
  const message = gatherThreadDetails(threads, account_data);
  const closeActionsModel = () => setShowModal(false);
  const handleClick = () => {
    setShowModal(!showModal);
  };
  return (
    <div
      className="btn btn-sm btn-outline-light btn--onlyicon mt-icon"
      title={CREATE_NOTE}
    >
      <ArticleOutlinedIcon
        fontSize="medium"
        color="#919eab"
        onClick={handleClick}
      />
      <ActionsModal
        isOpen={showModal}
        onCancel={closeActionsModel}
        style={{ overflow: 'auto', maxHeight: '85vh' }}
        showModalFooter={false}
      >
        <SelectedOptionForm
          selectedOption={{ value: CREATE_NOTE }}
          closeActionsModel={closeActionsModel}
          message={message}
        />
      </ActionsModal>
    </div>
  );
};
