/* eslint-disable no-script-url */
/* eslint-disable react/jsx-no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable class-methods-use-this */
/* eslint-disable consistent-return */
import React, { Component } from 'react';
import axios from 'axios';
import * as Config from 'constants/constants';
import { removeCookie, AlertMessage, getSiteHostname } from 'utilities/utils';
import { PASSWORD } from 'utilities/regularexpressions';
import liscioLogo from 'images/liscioLogo.png';
import { withRouter } from 'utilities/withRouterWrapper';
import { clearAuthSession } from 'auth/session';

const noneStyle = { display: 'none' };
const blockStyle = { display: 'block' };

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: '',
      link_invalid: false,
      loading: false,
      link_invalid_msg: '',
      custom_logo: '',
      favicon: '',
      new_password: '',
      confirm_password: '',
      errors: {},
      email: '',
      firstName: '',
      lastName: '',
    };
  }

  componentDidMount() {
    const {
      params: { id },
    } = this.props;
    const component = this;
    if (id) {
      component.setState({ token: id });
    }
    const data = { token: id };
    const request = axios.post(Config.CHECK_LINK_VALIDITY, data);
    request.then((response) => {
      if (response.data.status !== 200) {
        AlertMessage('error', response.data.message, 5000);
        component.setState({
          link_invalid: true,
          link_invalid_msg: response.data.message,
        });
      } else if (response.data.status === 200) {
        component.setState({
          email: response.data.email,
          firstName: response.data.first_name,
          lastName: response.data.last_name,
        });
      }
    });
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value, errors: {} });
  }

  handleSubmit = () => {
    const errors = this.handleValidation();
    if (Object.keys(errors).length) {
      return false;
    }
    let currentLocation = getSiteHostname();
    if (currentLocation) {
      if (currentLocation.split('.')[0] === 'localhost') {
        currentLocation = 'devaws.liscio.me';
      }
      const component = this;
      const data = {
        token: component.state.token,
        password: component.state.new_password,
        password_confirmation: component.state.confirm_password,
        company_name: currentLocation,
      };
      component.setState({ loading: true });
      const request = axios.post(Config.CHANGE_PASSWORD_API_PATH, data);
      localStorage.clear();
      request
        .then((response) => {
          component.setState({ loading: false });
          if (response.data.status === 401) {
            const err = { new_password: response.data.message };
            this.setState({ errors: err });
            return false;
          }
          component.setState({ new_password: '', confirm_password: '' });
          if (response.data.status === 200) {
            clearAuthSession();
            removeCookie({}, 'redirect_url');
            AlertMessage('success', 'Password Changed Successfully', 5000);
            setTimeout(() => {
              component.props.navigate('/');
            }, 1000);
          } else {
            AlertMessage('error', response.data.message, 5000);
          }
        })
        .catch((error) => console.log('Error', error));
    }
  };

  handleValidation = () => {
    const errs = {};
    const { new_password, confirm_password, email, firstName, lastName } =
      this.state;
    if (!new_password && !confirm_password) {
      errs.new_password = 'Required';
      errs.confirm_password = 'Required';
    }
    if (!new_password) {
      errs.new_password = 'Required';
    }
    if (new_password && !PASSWORD.test(new_password)) {
      errs.new_password =
        'Password must contain at least 12 characters, including 1 upper case letter, 1 lower case letter, 1 numeric, and 1 special character.';
    } else if (new_password) {
      const array = [firstName, lastName];
      let usernameExists = false;
      array.map((item) => {
        const passwordLowerCase = new_password.toLowerCase();
        if (passwordLowerCase.indexOf(item.toLowerCase()) > -1) {
          usernameExists = true;
        }
        return usernameExists;
      });
      if (new_password.toLowerCase() === email.toLowerCase()) {
        errs.new_password = 'Password should not contain primary email id';
      } else if (usernameExists === true) {
        errs.new_password = 'Password should not contain first name/ last name';
      }
    } else if (!confirm_password) {
      errs.confirm_password = 'Required';
    }
    if (new_password && confirm_password && new_password !== confirm_password) {
      errs.confirm_password = "Password don't match";
    }
    this.setState({ errors: errs });
    return errs;
  };

  Cancel = () => {
    this.props.navigate('/');
  };

  handleEyeIcon(type) {
    const inputType = this.state[type];
    this.setState({ [type]: inputType === 'text' ? 'password' : 'text' });
  }

  render() {
    const { errors } = this.state;
    return (
      <div
        data-testid="forgotPassword"
        className="ForgotPassword"
        id="page-wrapper"
      >
        <div id="loading" style={this.state.loading ? blockStyle : noneStyle} />
        <div className="crPage">
          <div className="crPage--inner">
            <div className="crPage--innerForm">
              <div className="crPage--innerForm--sec">
                {/* <Alert html /> */}
                <div className="s-alert-wrapper" />
                <div className="crPage--head text-center">
                  <img src={liscioLogo} className="homeLogo" alt="Lisico" />
                  <h1>Password Reset</h1>
                </div>
                <div className="login-bx">
                  <form autoComplete="off" className="login_form">
                    <div
                      className={
                        errors.new_password
                          ? 'form-group inputGroup form-error'
                          : 'form-group inputGroup'
                      }
                    >
                      <input
                        data-testid="newPassword"
                        name="new_password"
                        onChange={(e) => this.handleChange(e)}
                        onBlur={this.handleValidation}
                        disabled={this.state.link_invalid}
                        type={this.state.passwordType || 'password'}
                        className="form-control"
                        placeholder="New Password"
                        autoComplete="off"
                      />
                      <span
                        onClick={() => this.handleEyeIcon('passwordType')}
                        className="inputHelpIcon"
                      >
                        <i
                          className={`icon-${
                            this.state.passwordType === 'text'
                              ? 'open'
                              : 'close'
                          }-eye`}
                          aria-hidden="true"
                        />
                      </span>
                      {errors.new_password ? (
                        <span
                          data-testid="newPassErrorId"
                          className="text-danger error_label"
                          id="new_password"
                        >
                          {errors.new_password ? errors.new_password : ''}
                        </span>
                      ) : (
                        <span
                          className="text-dark label-submedium"
                          id="confirm_password"
                        >
                          Password must contain at least 12 characters,
                          including 1 upper case letter, 1 lower case letter, 1
                          numeric, and 1 special character.
                        </span>
                      )}
                    </div>
                    <div>
                      <div
                        className={
                          errors.confirm_password
                            ? 'form-group inputGroup form-error'
                            : 'form-group inputGroup'
                        }
                      >
                        <input
                          data-testid="confirmPassword"
                          name="confirm_password"
                          onChange={(e) => this.handleChange(e)}
                          onBlur={this.handleValidation}
                          disabled={this.state.link_invalid}
                          type={this.state.confirmType || 'password'}
                          className="form-control"
                          placeholder="Confirm Password"
                          autoComplete="off"
                        />
                        <span
                          onClick={() => this.handleEyeIcon('confirmType')}
                          className="inputHelpIcon"
                        >
                          <i
                            className={`icon-${
                              this.state.confirmType === 'text'
                                ? 'open'
                                : 'close'
                            }-eye`}
                            aria-hidden="true"
                          />
                        </span>
                        <span
                          data-testid="confirmPassErrorId"
                          className="text-danger error_label hidden"
                          id="confirm_password"
                        >
                          {errors.confirm_password
                            ? errors.confirm_password
                            : ''}
                        </span>
                      </div>
                      <div className="form-group">
                        <button
                          data-testid="submitButton"
                          type="button"
                          onClick={this.handleSubmit}
                          disabled={this.state.link_invalid}
                          className="btn btn-primary pull-left w-100 mb-3"
                        >
                          Submit
                        </button>
                        <button
                          type="button"
                          className="btn send-magic-link btn-outline-light w-100"
                          onClick={this.Cancel}
                        >
                          Cancel
                        </button>
                      </div>
                      <div className="clearfix" />
                      <div className="form-group text-left">
                        <div className="d-flex w-100 justify-content-between crPage--bott" />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="crPage--innerForm--footer">
                <div className="crPaeg--links">
                  <a href="javascript:;">Privacy Policy</a>
                  <a href="javascript:;">Terms &amp; Conditions</a>
                  <a href="javascript:;">Contact Us</a>
                </div>
                <div className="crPage--copyright">
                  <p>{`© ${new Date().getFullYear()} Lisco. All right reserved.`}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(ForgotPassword);
