/* eslint-disable @typescript-eslint/no-shadow */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as NotificationActions from 'actions/NotificationActions';
import * as actions from 'actions/PaginationActions';
import { useNavigate } from 'react-router-dom-latest';

const OutofOfficeBanner = ({
  isAway,
  awayStartDate,
  awayEndDate,
  NotificationActions,
}) => {
  const navigate = useNavigate();
  const updateOutofOfficeSettings = () => {
    NotificationActions.updateAwaySetting(true);
    setTimeout(() => {
      navigate('/profile_settings?tab=user_information');
    }, 50);
  };

  if (!isAway) {
    return null;
  }

  let dates = '';
  if (awayStartDate && awayEndDate) {
    dates = `(${awayStartDate} to ${awayEndDate})`;
  } else if (awayStartDate) {
    dates = `(from ${awayStartDate})`;
  } else if (awayEndDate) {
    dates = `(till ${awayEndDate})`;
  }

  return (
    <div className="outOfOffc">
      <span className="font-weight-medium">
        Your Status is currently set to away {dates}
        .&nbsp;
      </span>
      <button
        type="button"
        style={{
          cursor: 'pointer',
          fontWeight: 'normal',
          textDecoration: 'underline',
        }}
        onClick={updateOutofOfficeSettings}
        className="btn btn-link p-0 border-0"
      >
        Update here
      </button>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAway: state.notificationReducer.isAway,
  awayStartDate: state.notificationReducer.awayStartDate,
  awayEndDate: state.notificationReducer.awayEndDate,
  updateAwaySetting: state.notificationReducer.updateAwaySetting,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
  NotificationActions: bindActionCreators(NotificationActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(OutofOfficeBanner);
