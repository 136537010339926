import React, { useState, useEffect } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import Axios from 'axios';
import CurrencyInput from 'react-currency-input-field';
import moment from 'moment';
import { SelectField, TextAreaField } from 'common/CustomFields';
import InputField from 'common/InputField';
import { AlertMessage } from 'utilities/utils';
import {
  QB_INVOICE_ITEMS,
  QB_INVOICE,
  QB_INVOICE_TERMS,
  QB_ACCOUNTS,
} from 'constants/constants';

const CreateInvoiceModal = (props) => {
  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [terms, setTerms] = useState([]);
  const [selectedTerm, setSelectedTerm] = useState(null);
  const [selectedAccount] = useState(props.selectedAccount);
  const [loading, setLoading] = useState(true);
  const [description, setDescription] = useState('');
  const [amount, setAmount] = useState(0);
  const [errors, setErrors] = useState({});
  const [customerDetails, setCustomerDetails] = useState();

  const getInvoiceItems = async () => {
    try {
      const response = await Axios.get(QB_INVOICE_ITEMS).then(
        (res) => res.data.data,
      );
      setItems(response);
      setLoading(false);
    } catch (error) {
      AlertMessage('error', error.response.data.message, 3000);
      setLoading(false);
    }
  };

  const getSelectedCustomerDetails = async () => {
    try {
      const response = await Axios.get(
        `${QB_ACCOUNTS}/${selectedAccount.qb_customer_id}`,
      ).then((res) => res.data.data);
      setCustomerDetails(response);
    } catch (error) {
      AlertMessage('error', error.response.data.message, 3000);
    }
  };

  const getInvoiceTerms = async () => {
    try {
      const response = await Axios.get(QB_INVOICE_TERMS).then(
        (res) => res.data.data,
      );
      setTerms(response);
    } catch (error) {
      AlertMessage('error', error.response.data.message, 3000);
    }
  };

  useEffect(() => {
    getInvoiceItems();
    getInvoiceTerms();
    getSelectedCustomerDetails();
  }, []);

  useEffect(() => {
    if (terms.length && customerDetails && customerDetails.sales_term_ref) {
      for (let i = 0; i < terms.length; i += 1) {
        if (terms[i].value === customerDetails.sales_term_ref.value) {
          setSelectedTerm(terms[i]);
          break; //
        }
      }
    }
  }, [terms, customerDetails]);

  const handleSelectItem = (event) => {
    setSelectedItem(event);
  };

  const handleSelectTerm = (event) => {
    setSelectedTerm(event);
  };

  const handleDescription = (event) => {
    setDescription(event);
  };

  const handleAmount = (event) => {
    setAmount(event);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let isValid = true;
    const tempErrors = {};
    if (amount < 1 || amount === undefined) {
      isValid = false;
      tempErrors.amount = 'Amount should be greater than 0';
    }
    if (amount && amount > 10000) {
      isValid = false;
      tempErrors.amount = 'Invoice amount can not be greater than $10,000';
    }
    if (selectedAccount === null) {
      isValid = false;
      tempErrors.account = 'Please select account';
    }
    if (selectedItem === null) {
      isValid = false;
      tempErrors.invoice_item = 'Please select item';
    }
    setErrors(tempErrors);
    if (isValid) {
      setLoading(true);
      try {
        const data = {
          account_id: selectedAccount.qb_customer_id,
          items: [
            {
              id: selectedItem.id,
              amount,
              quantity: 1,
              description,
            },
          ],
        };
        if (selectedTerm !== null) {
          data.due_date = moment()
            .add(selectedTerm.due_days, 'days')
            .format('YYYY-MM-DD');
          data.term_id = selectedTerm;
        }
        const result = await Axios.post(QB_INVOICE, data).then(
          (res) => res.data.data,
        );
        props.onCreate({
          account_details: selectedAccount,
          invoice_details: result,
        });
        props.toggleModal();
      } catch (error) {
        setLoading(false);
        AlertMessage('error', error.response.data.message, 3000);
      }
    }
  };

  return (
    <Modal isOpen toggle={props.toggleModal} className="customModal" data-testid="invoiceModal">
      {loading && <div id="loading" />}
      <div className="d-flex justify-content-between align-items-center ModalHeader">
        <div className="infoHeader">Create Invoice</div>
        <button
          type="button"
          className="close"
          aria-label="Close"
          onClick={props.toggleModal}
        >
          <i className="icon-close2" aria-hidden="true" />
        </button>
      </div>
      <ModalBody>
        <form onSubmit={handleSubmit} data-testid="invoiceForm">
          <div className="modalContent p-4">
            <div className="row">
              {/* <QbAccountsDropDown
                onSelect={(input) => { setSelectedAccount(input) }}
                error={errors.account}
              /> */}
              <InputField
                name="account"
                id="invoice_account_search"
                label="Account Name:"
                value={selectedAccount.label}
                className="form-group col-sm-12 col-lg-6 col-md-6"
                disabled
                data-testid="accountInput"
              />
              <SelectField
                name="invoice_items"
                label="Term"
                options={terms}
                handleChange={handleSelectTerm}
                value={selectedTerm}
                isLoading={loading}
                clearable
                className="form-group col-sm-12 col-lg-4 col-md-4"
                error={errors.terms}
              />
            </div>

            <div className="row">
              <SelectField
                name="invoice_items"
                label="Item"
                options={items}
                handleChange={handleSelectItem}
                value={selectedItem}
                isLoading={loading}
                clearable
                className="form-group col-sm-12 col-lg-4 col-md-4"
                error={errors.invoice_item}
              />
              <TextAreaField
                name="invoice_description"
                label="Description"
                className="form-group col-sm-12 col-lg-4 col-md-4"
                onChange={handleDescription}
                value={description}
                type="textarea"
                rows="1"
                data-testid="descriptionTextarea"
              />
              <div
                className={`form-group col-sm-12 col-lg-4 col-md-4 ${
                  errors.amount ? 'form-error' : ''
                }`}
                data-testid="amountInput"
              >
                <label className="labelValue" htmlFor="invoiceamount">
                  Amount
                </label>
                <CurrencyInput
                  id="invoiceamount"
                  name="invoiceamount"
                  decimalsLimit={2}
                  allowNegativeValue={false}
                  prefix="$"
                  maxLength={8}
                  className="form-control"
                  value={amount}
                  onValueChange={handleAmount}
                />
                <span className="error_label">{errors.amount}</span>
              </div>
            </div>
            <div className="row no-gutters justify-content-end">
              <button
                type="button"
                className="btn mr-2"
                onClick={props.toggleModal}
                data-testid="cancelButton"
              >
                Cancel
              </button>
              <button type="submit" className="btn btn-primary" data-testid="createButton">
                Create Invoice
              </button>
            </div>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};

export default CreateInvoiceModal;
