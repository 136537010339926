import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { InputBase, styled } from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';

const SContainer = styled('div')((props) => ({
  display: 'flex',
  flex: 1,
  alignItems: 'center',
  position: 'relative',
  backgroundColor: '#ffffff',
  transition: 'all ease-in-out 350ms',
  width: 'auto',
  maxWidth: '100%',
  maxHeight: '35px',
  border: props.focused ? '2px solid #919eab' : '2px solid #dfe3e8',
  borderRadius: 2,
}));

const SIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(1),
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  color: '#919eab',
}));

const StyledInputBase = styled(InputBase)(({ theme, fontSize }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(1),
    width: '100%',
    fontSize,
    transition: theme.transitions.create('width'),
  },
}));

function IconTextField(props) {
  const {
    className,
    icon = <SearchIcon />,
    type = 'search',
    placeHolder = 'Search',
    fontSize,
    value = '',
    onChange,
    isOpen = false,
  } = props;

  const [internal, setInternal] = useState(value);
  const [focused, setFocused] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    if (value !== internal) setInternal(value);
  }, [value]);

  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isOpen]);

  const handleChange = (e) => {
    setInternal(e.target.value);
    if (onChange) onChange(e);
  };

  return (
    <SContainer className={className} focused={focused}>
      <SIconWrapper>{icon}</SIconWrapper>

      <StyledInputBase
        type={type}
        placeholder={placeHolder}
        fontSize={fontSize}
        value={internal}
        onChange={handleChange}
        inputRef={inputRef}
        onFocus={() => {
          setFocused(true);
        }}
        onBlur={() => {
          setFocused(false);
        }}
      />
    </SContainer>
  );
}

IconTextField.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.element.isRequired,
  type: PropTypes.string.isRequired,
  placeHolder: PropTypes.string,
  fontSize: PropTypes.string || PropTypes.number,
  value: PropTypes.string,
  onChange: PropTypes.func,
  isOpen: PropTypes.bool,
};

export default IconTextField;
