/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-script-url */
/* eslint-disable react/jsx-no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable class-methods-use-this */
/* eslint-disable consistent-return */
/* eslint-disable no-prototype-builtins */
/* eslint-disable react/no-unused-class-component-methods */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-state */
// TODO: check react-s-alert/
import React from 'react';
import { Button } from 'reactstrap';
import ReactTooltip from 'react-tooltip';
import Axios from 'axios';
// import Alert from 'react-s-alert';
import { generateUniqueHash } from 'common/NotesUtilities';
import { getStorageData } from 'utilities/utils';
import {
  SEARCH_TASK_CONTACTS,
  SEARCH_TASK_ACCOUNTS,
} from 'constants/constants';
import debounce from 'lodash/debounce';
import {
  groupEmpAndContacts,
  getContactsOfTask,
  getAccounts,
  AccountsOfContact,
  getContEmpOfAccount,
  getRelConAndCstOfAccounts,
  getAccountDetails,
  getContactDetails,
  getEmployeeDetails,
} from 'api/TaskApi';
import RecipientAndAccountDropdowns from 'components/Messages/RecipientAndAccountDropdowns';
import { Box, styled } from '@mui/material';

const StyledBox = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  marginRight: '0.3rem',
  marginTop: '0.5rem',
});

class ContactSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      documents: [],
      contacts: [],
      accounts: [],
      allContacts: [],
      allAccounts: [],
      accountsLoaded: false,
      accountId: this.props.requestAccountId || '',
      contactId: this.props.requestContactId || '',
      employeeId: this.props.requestEmployeeId || '',
      contactName: '',
      accountName: '',
      recipientType: '',
      selectedRecords: [],
      dropdownOpen: false,
      assigneeType: '',
      messageId: '',
      messageThreadId: '',
      errors: {},
      draftStatus: '',
      contatIdPopup: '',
      type: 'message',
      mounted: false,
      notifyViaText: false,
      apiHitting: false,
      uploadingImages: false,
      sendMessageAfterImageUpload: false,
      initialComment: {},
      selectedOption: null,
      uniqueId: generateUniqueHash(5),
      templateOptions: [],
      templateDocuments: [],
      warningMsgLost: false,
      selectedTemplate: null,
      titleChange: '',
      groupedOptions: [],
      isLoading: true,
      isUserTyping: false,
      hasMobile: false,
      accountDetails: {},
      contactDetails: {},
      employeeDetails: {},
      // onHoverShowMobileStatus: '',
    };

    this.addSelectedInput = this.addSelectedInput.bind(this);
    this.handleAccountChange = this.handleAccountChange.bind(this);
    this.handleContactChange = this.handleContactChange.bind(this);
    this.handleContactInput = this.handleContactInput.bind(this);
    this.handleAccountInput = this.handleAccountInput.bind(this);
    this.onFocus = this.onFocus.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.accountsLoaded &&
      prevProps.requestAccountId !== this.props.requestAccountId
    ) {
      const originalAccount = this.state.allAccounts.find(
        (account) => account.value.toString() === this.props.requestAccountId,
      );
      if (originalAccount) {
        this.handleAccountChange(originalAccount, {});
      } else if (this.props.requestAccountId) {
        getAccountDetails(this, this.props.requestAccountId);
      }
    }
    // If we have an account passed in, we want to set the account after all accounts load initially
    if (
      prevState.allAccounts.length === 0 &&
      this.state.allAccounts.length !== 0
    ) {
      this.setState({ accountsLoaded: true });
      const originalAccount = this.state.allAccounts.find(
        (account) => account.value.toString() === this.state.accountId,
      );
      if (originalAccount) {
        this.handleAccountChange(originalAccount, {});
      } else if (this.state.accountId) {
        getAccountDetails(this, this.state.accountId);
      }

      if (this.state.contactId) {
        getContactDetails(this, this.state.contactId);
      }

      if (this.state.employeeId) {
        getEmployeeDetails(this, this.state.employeeId);
      }
    }
    // If we need to call for an account not returned in the original list, we need to update with the found account
    if (prevState.accountDetails !== this.state.accountDetails) {
      if (!this.state.accountName) {
        this.handleAccountChange(
          {
            label: this.state.accountDetails.account.name,
            value: this.state.accountDetails.account.id,
          },
          {},
        );
      }
    }

    // If we need to call for an employee, we need to update with the found contact
    if (prevState.contactDetails !== this.state.contactDetails) {
      if (!this.state.contactName) {
        this.handleContactChange({
          label: `${this.state.contactDetails.contact.first_name} ${this.state.contactDetails.contact.last_name}`,
          value: this.state.contactDetails.contact.id,
          accounts: this.state.contactDetails.accounts,
          type: 'contacts',
          uuid: this.state.contactDetails.contact.uuid,
        });
      }
    }

    // If we need to call for a contact, we need to update with the found contact
    if (prevState.employeeDetails !== this.state.employeeDetails) {
      if (!this.state.contactName) {
        this.handleContactChange({
          label: `${this.state.employeeDetails.first_name} ${this.state.employeeDetails.last_name}`,
          value: this.state.employeeDetails.cpa_user_id,
          accounts: [],
          assigne_type: 'user',
          type: 'emp',
          uuid: this.state.contactDetails.contact.uuid,
        });
      }
    }

    // Once the contacts load from the initial account pull we want to pass back the options and select a contact if only 1 exists
    if (
      prevState.groupedOptions !== this.state.groupedOptions &&
      this.state.loading === false
    ) {
      const contacts = this.state.groupedOptions.filter(
        (option) => option.label === 'Contacts',
      );
      if (contacts) {
        const lengthOfContacts = contacts[0].options.length;
        if (this.props.onContactsSet && contacts) {
          this.props.onContactsSet(lengthOfContacts);
        }
      }
    }
  }

  componentDidMount() {
    // TODO: __HOMEDATA-AUDIT
    const homeDataObj = getStorageData();
    getContactsOfTask(this, { limit: true, message: true }, 'notes');
    if (homeDataObj !== null && Object.keys(homeDataObj).length) {
      if (homeDataObj.is_employee) {
        getAccounts(this, 'notes');
        this.setState({ mounted: true });
      } else {
        AccountsOfContact(this, homeDataObj.contact_id, 'clientmessage');
        this.setState({ mounted: true });
      }
    }
    window.addEventListener('keypress', (e) => {
      if (e && e.which === 13) {
        e.preventDefault();
      }
    });

    this.setState({ loading: true });
  }

  UNSAFE_componentWillReceiveProps() {
    // TODO: __HOMEDATA-AUDIT
    const homeDataObj = getStorageData();
    if (!this.state.mounted) {
      if (homeDataObj !== null && Object.keys(homeDataObj).length) {
        if (homeDataObj.is_employee) {
          getAccounts(this, 'notes');
          this.setState({ mounted: true });
        } else {
          AccountsOfContact(this, homeDataObj.contact_id, 'clientmessage');
          this.setState({ mounted: true });
        }
      }
    }
  }

  componentWillUnmount() {
    window.addEventListener('keypress', () => true);
  }

  onFocus() {
    this.setState(
      (prevState) => ({
        dropdownOpen: !prevState.dropdownOpen,
        contacts: prevState.allContacts,
        accounts: prevState.allAccounts,
        groupedOptions: groupEmpAndContacts(prevState.allContacts),
        contactId: '',
        contatIdPopup: '',
        accountId: prevState.accountId || '',
        contactName: '',
        accountName:
          this.props.requestAccountId && this.state.accountName
            ? this.state.accountName
            : '',
        inputVal: '',
        errors: {},
      }),
      () => {
        if (this.state.accounts.length === 1) {
          this.setState({
            accountId: this.state.accounts[0].value,
            accountName: this.state.accounts[0].label,
          });
        }
      },
    );
  }

  handleContactInput(val, from = '') {
    const { viewContext } = this.state;
    if (!this.state.accountId && val) {
      if (val.length < 2) {
        this.getContAcc('contacts');
      } else {
        this.setState({ contacts: [] });
        clearTimeout(this.contactSearchTime);
        this.contactSearchTime = setTimeout(() => {
          const request = Axios.get(
            `${SEARCH_TASK_CONTACTS}/?keyword=${val}&message=true`,
          );
          request.then((response) => {
            if (response.status === 200) {
              if (from === 'addToRecepient') {
                if (
                  viewContext === 'email' &&
                  response.data[0].type === 'emp'
                ) {
                  // console.log('Nothing to do');
                } else {
                  const contactDetails = response.data[0];
                  this.handleContactChange(contactDetails);
                  this.addSelectedInput();
                }
              } else {
                this.setState({
                  contacts: response.data,
                  groupedOptions: groupEmpAndContacts(response.data),
                });
              }
            }
          });
        }, 500);
      }
    }
  }

  handleAccountInput(val) {
    // TODO: __HOMEDATA-AUDIT
    const homeDataObj = getStorageData();
    const { contactId, recipientType } = this.state;
    if (
      val.length >= 2 &&
      homeDataObj.is_employee &&
      ((contactId && recipientType === 'emp') || !contactId)
    ) {
      this.setState({ accounts: [] });
      clearTimeout(this.accountSearchTime);
      this.accountSearchTime = setTimeout(() => {
        const request = Axios.get(
          `${SEARCH_TASK_ACCOUNTS}/?keyword=${val}&message=true`,
        );
        request.then((response) => {
          if (response.status === 200) {
            this.setState({ accounts: response.data });
          }
        });
      }, 500);
    }
    if (this.state.errors.accont) {
      const err = {};
      err.accont = '';
      this.setState({ errors: err });
    }
  }

  addSelectedInput() {
    if (!this.state.contactId) return false;
    this.props.onSubmit(
      this.state.selectedContact,
      this.state.accountId,
      this.state.accountName,
    );
    const selectedInput = {
      contactId: this.state.contactId,
      accountId: this.state.accountId,
      contactName: this.state.contactName,
      accountName: this.state.accountName,
      assigneeType: this.state.assigneeType,
      hasMobile: this.state.hasMobile,
    };
    const selectedRecordsArr = this.state.selectedRecords;
    const index = selectedRecordsArr.findIndex(
      (x) =>
        x.contactId === selectedInput.contactId &&
        x.accountId === selectedInput.accountId,
    );
    // If To field is empty, push into the array and finally display the value in To field
    if (index === -1) {
      selectedRecordsArr.push(selectedInput);
      this.setState({
        selectedRecords: selectedRecordsArr,
        contacts: this.state.allContacts,
        accounts: this.state.allAccounts,
        groupedOptions: groupEmpAndContacts(this.state.allContacts),
        contactId: '',
        contatIdPopup: '',
        accountId: this.props.requestAccountId || '',
        contactName: '',
        accountName:
          this.props.requestAccountId && this.state.accountName
            ? this.state.accountName
            : '',
        assigneeType: '',
        selectedContact: '',
      });
    } else {
      this.setState({
        dropdownOpen: false,
        contacts: this.state.allContacts,
        accounts: this.state.allAccounts,
        groupedOptions: groupEmpAndContacts(this.state.allContacts),
        contactId: '',
        contatIdPopup: '',
        accountId: this.props.requestAccountId || '',
        contactName: '',
        accountName:
          this.props.requestAccountId && this.state.accountName
            ? this.state.accountName
            : '',
        assigneeType: '',
        selectedContact: '',
      });
    }
    this.props.onClose();
  }

  keyPress(e) {
    if (e && Number(e.keyCode) === 13) {
      e.preventDefault();
    }
  }

  errorDoc = (field = '') => {
    if (field === 'account') {
      const err = {};
      err.accont = 'Please select account';
      this.setState({ errors: err });
    } else {
      const err = {};
      err.documents = 'Please select document';
      this.setState({ errors: err });
    }
  };

  updateDocumentState = (documents) => {
    this.setState({ documents }, () => {
      this.saveMessage('Draft');
    });
  };

  renderTooltip = () => (
    <ReactTooltip
      id="numberDontExist"
      className="numberDontExist"
      aria-haspopup="true"
      place="top"
      data-background-color="black"
      effect="solid"
      delayHide={1000}
    >
      <span>This contact does not have a phone number.</span>
      {/* <br />
      <u>Add a phone number?</u> */}
      {/* TODO With FD-711 */}
    </ReactTooltip>
  );

  /** *********** NEW CODE **************** */

  /**
   * This method is used to reset the recipient and account selects
   * This method gets called when clearing a select value
   * @param {*} changeOnly
   */
  getContAcc(changeOnly = 'both') {
    if (changeOnly === 'contacts') {
      this.setState({
        contacts: this.state.allContacts,
        groupedOptions: groupEmpAndContacts(this.state.allContacts),
        contactId: '',
        contatIdPopup: '',
        contactName: '',
        recipientType: '',
        isLoading: false,
        selectedContact: null,
      });
    } else if (changeOnly === 'accounts') {
      this.setState({
        accounts: this.state.allAccounts,
        accountId: this.props.requestAccountId || null,
        accountName:
          this.props.requestAccountId && this.state.accountName
            ? this.state.accountName
            : null,
        selectedContact: null,
      });
    } else {
      this.setState({
        contacts: this.state.allContacts,
        accounts: this.state.allAccounts,
        groupedOptions: groupEmpAndContacts(this.state.allContacts),
        contactId: '',
        contatIdPopup: '',
        accountId: this.props.requestAccountId || '',
        contactName: '',
        accountName:
          this.props.requestAccountId && this.state.accountName
            ? this.state.accountName
            : '',
        recipientType: '',
        selectedContact: null,
      });
    }
  }

  handleContactChange(val) {
    this.setState({ isUserTyping: false });
    // this.props.onContactChange(val, this.state.accountId);
    if (!val && !this.state.accountId) {
      this.getContAcc();
    } else if (!val) {
      this.setState({
        contactId: '',
        recipientType: '',
        contatIdPopup: '',
        contactName: '',
        assigneeType: '',
      });
    } else if (val.type === 'contact' && !val.hasOwnProperty('accounts')) {
      // if contact has no account associated with it
      this.setState({
        accounts: [],
        contactId: val.value,
        contatIdPopup: val.id,
        contactName: val.label,
        accountId: this.props.requestAccountId || '',
        accountName:
          this.props.requestAccountId && this.state.accountName
            ? this.state.accountName
            : '',
        cpaId: val.cpa_id,
        assigneeType: val.assigne_type,
        recipientType: val.type,
        hasMobile: val.has_mobile,
        selectedContact: val,
      });
      // when an employee is selected from recipient dropdown
    } else if (val.type === 'emp' && !this.state.accountId) {
      this.getContAcc('accounts'); // reset accounts data
      this.setState({
        contactId: val.value,
        contactName: val.label,
        selectedContact: val,
        cpaId: val.cpa_id,
        assigneeType: val.assigne_type,
        recipientType: val.type,
        hasMobile: val.has_mobile,
      });
    } else if (val.type === 'emp' && this.state.accountId) {
      this.setState({
        contactId: val.value,
        contactName: val.label,
        selectedContact: val,
        cpaId: val.cpa_id,
        assigneeType: val.assigne_type,
        recipientType: val.type,
        hasMobile: val.has_mobile,
      });
    } else if (val.accounts.length === 1) {
      // autopopulate account if only one account
      this.setState(
        {
          accountId: val.accounts[0].value,
          accountName: val.accounts[0].label,
          accounts: val.accounts,
          contactId: val.value,
          contatIdPopup: val.id,
          contactName: val.label,
          cpaId: val.cpa_id,
          assigneeType: val.assigne_type,
          recipientType: val.type,
          selectedContact: val,
          hasMobile: val.has_mobile,
        },
        () => {
          this.setState({ isLoading: true });
          getContEmpOfAccount(this, {
            accountId: val.accounts[0].value,
            accountName: val.accounts[0].label,
          });
        },
      );
    } else {
      const eleArray = this.state.accounts.map((item) => item.value);
      if (eleArray.indexOf(this.state.accountId) > -1) {
        this.setState({
          accounts: val.accounts,
          contactId: val.value,
          contatIdPopup: val.id,
          contactName: val.label,
          selectedContact: val,
          cpaId: val.cpa_id,
          assigneeType: val.assigne_type,
          recipientType: val.type,
          hasMobile: val.has_mobile,
        });
      } else {
        // set the accounts related to that contact
        this.setState({
          accounts: val.accounts,
          contactId: val.value,
          contatIdPopup: val.id,
          contactName: val.label,
          selectedContact: val,
          cpaId: val.cpa_id,
          assigneeType: val.assigne_type,
          recipientType: val.type,
          hasMobile: val.has_mobile,
        });
      }
    }
  }

  handleAccountChange(val, typeOfAction) {
    const { action, removedValues } = typeOfAction;
    if (action === 'clear' && removedValues.length === 0) {
      return;
    }

    // TODO: __HOMEDATA-AUDIT
    const homeDataObj = getStorageData();

    this.setState({ isUserTyping: false });

    if (!val && !this.state.contactId) {
      this.getContAcc();
    } else if (!val) {
      // reset account select when there is no value
      if (this.state.recipientType === 'emp') {
        this.setState({
          accounts: this.state.allAccounts,
          accountId: this.props.requestAccountId || null,
          accountName:
            this.props.requestAccountId && this.state.accountName
              ? this.state.accountName
              : null,
          groupedOptions: groupEmpAndContacts(this.state.allContacts),
        });
      } else {
        this.setState({
          accounts: this.state.accounts,
          accountId: this.props.requestAccountId || null,
          accountName:
            this.props.requestAccountId && this.state.accountName
              ? this.state.accountName
              : null,
        });
      }
    } else {
      this.setState({
        accountId: val.value,
        accountName: val.label,
      });
      const mydata = {
        accountId: val.value,
        accountName: val.label,
      };
      // Will enter this if condition when you select an account with no recipient
      if (homeDataObj && homeDataObj.is_employee) {
        this.setState({ isLoading: true });
        getContEmpOfAccount(this, mydata);
      } else {
        getRelConAndCstOfAccounts(this, val.value);
      }
    }
    if (this.state.errors.account) {
      const err = {};
      err.account = '';
      this.setState({ errors: err });
    }
  }

  // get filtered results when performing a search in contact select
  getContactFilteredResults = async (searchTerm, from = '') => {
    try {
      const response = await Axios.get(
        `${SEARCH_TASK_CONTACTS}/?keyword=${searchTerm}&message=true`,
      );

      if (response.status === 200) {
        const groupedOptions = groupEmpAndContacts(response.data);
        if (from === 'addToRecepient') {
          const contactDetails = response.data[0];
          this.handleContactChange(contactDetails);
          this.addSelectedInput();
        } else {
          this.setState({
            contacts: response.data,
            groupedOptions,
          });
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  // get filtered results when performing a search in account select
  getAccountFilteredResults = async (searchTerm) => {
    try {
      const response = await Axios.get(
        `${SEARCH_TASK_ACCOUNTS}/?keyword=${searchTerm}&message=true`,
      );

      if (response.status === 200) {
        this.setState({ accounts: response.data, allAccounts: response.data });
      }
    } catch (err) {
      console.error(err);
    }
  };

  // handle contact select input change
  handleContactInputChange = debounce((inputValue, typeOfAction) => {
    const { action } = typeOfAction;
    const { accountId, isUserTyping } = this.state;

    /**
     * execute this block when the user clears the select input while hitting backspace or
     * closes the menu without selecting an option
     */
    if (action === 'menu-close' && isUserTyping) {
      this.getContAcc('contacts');
    }

    if (action === 'input-change' && !accountId) {
      if (inputValue) {
        this.getContactFilteredResults(inputValue);
        this.setState({ isUserTyping: true });
      } else {
        this.getContAcc('contacts');
      }
    }
  }, 300);

  // handle account select input change
  handleAccountInputChange = debounce((inputValue, typeOfAction) => {
    const { action, prevInputValue } = typeOfAction;
    const { isUserTyping, contactId, recipientType } = this.state;

    // TODO: __HOMEDATA-AUDIT
    const homeDataObj = getStorageData();

    if (this.props.accountInputChanged) {
      this.props.accountInputChanged(true);
    }
    /**
     * execute this block when the user clears the select input while hitting backspace or
     * closes the menu without selecting an option
     */

    if (
      (action === 'menu-close' || prevInputValue.length === 1) &&
      isUserTyping
    ) {
      this.setState((prevState) => ({
        accounts: prevState.allAccounts,
        accountId: this.props.requestAccountId || '',
        accountName:
          this.props.requestAccountId && this.state.accountName
            ? this.state.accountName
            : '',
      }));
    }

    if (inputValue) {
      if (
        action === 'input-change' &&
        homeDataObj.is_employee &&
        ((contactId && recipientType === 'emp') || !contactId)
      ) {
        this.getAccountFilteredResults(inputValue);
        this.setState({ isUserTyping: true });
      } else if (Array.isArray(this.state.ref_accounts)) {
        this.setState({ allAccounts: this.state.ref_accounts });
      }
    }
  }, 300);

  // reset select dropdowns
  resetSelectDropdowns = () => {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
      contacts: prevState.allContacts,
      accounts: prevState.allAccounts,
      groupedOptions: groupEmpAndContacts(prevState.allContacts),
      selectedContact: '',
      contactId: '',
      contatIdPopup: '',
      accountId: this.props.requestAccountId || '',
      contactName: '',
      accountName:
        this.props.requestAccountId && this.state.accountName
          ? this.state.accountName
          : '',
      inputVal: '',
      errors: {},
    }));

    this.props.onClose();
  };

  render() {
    const { groupedOptions, isLoading } = this.state;
    const { handleOk, handleClose, filterEmployees, filterContacts } =
      this.props; // when using in a Popup, Dialog, etc...

    let recipientOptions = filterEmployees
      ? groupedOptions.filter((option) => option.label !== 'Employees')
      : groupedOptions;

    recipientOptions = filterContacts
      ? groupedOptions.filter((option) => option.label !== 'Contacts')
      : recipientOptions;
    return (
      <>
        <RecipientAndAccountDropdowns
          selectedContact={this.state.selectedContact}
          recipientOptions={recipientOptions}
          isLoading={isLoading}
          accountOptions={this.state.allAccounts}
          handleContactChange={this.handleContactChange}
          handleAccountChange={this.handleAccountChange}
          recipientValue={{
            label: this.state.contactName,
            value: this.state.contactId,
          }}
          accountValue={{
            label: this.state.accountName,
            value: this.state.accountId,
          }}
          handleContactInputChange={this.handleContactInputChange}
          handleAccountInputChange={this.handleAccountInputChange}
        />
        <StyledBox>
          <Button
            className="btn-link btn-sm btn--onlyicon"
            onClick={(event) => {
              handleClose?.();
              this.resetSelectDropdowns(event);
            }}
          >
            Close
          </Button>
          <Button
            className="btn btn-sm btn-primary"
            data-testid="okButton"
            onClick={(event) => {
              handleOk?.();
              this.addSelectedInput(event);
            }}
          >
            Ok
          </Button>
        </StyledBox>
      </>
    );
  }
}

export default ContactSelector;
