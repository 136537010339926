import { BooleanItem } from 'components/Requests/components/WorkflowView/components/ItemListItem/components/BooleanItem/BooleanItem';
import { UploadItem } from 'components/Requests/components/WorkflowView/components/ItemListItem/components/UploadItem/UploadItem';
import { TextItem } from 'components/Requests/components/WorkflowView/components/ItemListItem/components/TextItem/TextItem';
import { WorkflowItemType } from 'components/Requests/requests.types';
import {
  WorkflowItem2Fragment,
  WorkflowRequestFragment,
  WorkflowRequestItem2Fragment,
  WorkflowResponseItemFragment,
  WorkflowSignableDocumentItem2Fragment,
} from 'gql/graphql';
import { graphql } from 'gql';
import RequestItemWrapper from 'components/Requests/components/WorkflowView/components/WorkflowItemWrapper/WorkflowItemWrapper';
import { TaxDeliveryItem } from 'components/Requests/components/TaxDelivery/components/TaxDeliveryItem/TaxDeliveryItem';

export const responseItemFragment = graphql(/* GraphQL */ `
  fragment WorkflowResponseItem on WorkflowResponseItem {
    id
    type
    updatedAt
    notApplicable
    value {
      ...responseDocumentCollectionValue
      ...responseTextValue
      ...responseBooleanValue
    }
    workflowItem2 {
      ...WorkflowItem2
    }
  }
`);

export type ItemListItemProps = {
  workflowRequest?: WorkflowRequestFragment | null;
  workflowItem: WorkflowItem2Fragment;
  workflowResponseItem?: WorkflowResponseItemFragment | null;
  workflowRequestItem?: WorkflowRequestItem2Fragment | null;
  position: number;
  subPosition: number;
  disableEdit?: boolean;
  visible?: boolean;
};
export function ItemListItem({
  workflowItem,
  workflowResponseItem,
  workflowRequestItem,
  workflowRequest,
  position,
  subPosition,
  disableEdit = true,
  visible = true,
}: ItemListItemProps) {
  let itemContent: React.ReactElement | null = null;
  switch (workflowItem.type) {
    case WorkflowItemType.Boolean:
      itemContent = (
        <BooleanItem
          disabledEdit={disableEdit}
          workflowItem={workflowItem}
          workflowResponseItem={workflowResponseItem}
        />
      );
      break;
    case WorkflowItemType.Upload:
      itemContent = (
        <UploadItem
          disabledEdit={disableEdit}
          workflowItem={workflowItem}
          workflowResponseItem={workflowResponseItem}
        />
      );
      break;
    case WorkflowItemType.Text:
      itemContent = (
        <TextItem
          disabledEdit={disableEdit}
          workflowItem={workflowItem}
          workflowResponseItem={workflowResponseItem}
        />
      );
      break;
    case WorkflowItemType.Reviewable:
      itemContent = (
        <TaxDeliveryItem
          disableEdit={disableEdit}
          workflowRequestItem={workflowRequestItem}
          workflowItem={workflowItem as WorkflowSignableDocumentItem2Fragment}
          assignees={workflowRequest?.assignedContacts}
        />
      );
      break;
    case WorkflowItemType.Payable:
      itemContent = (
        <TaxDeliveryItem
          disableEdit={disableEdit}
          workflowRequestItem={workflowRequestItem}
          workflowItem={workflowItem as WorkflowSignableDocumentItem2Fragment}
          assignees={workflowRequest?.assignedContacts}
        />
      );
      break;
    case WorkflowItemType.Signable:
      itemContent = (
        <TaxDeliveryItem
          disableEdit={disableEdit}
          workflowRequestItem={workflowRequestItem}
          workflowItem={workflowItem as WorkflowSignableDocumentItem2Fragment}
          assignees={workflowRequest?.assignedContacts}
        />
      );
      break;
    case WorkflowItemType.Dynamic:
      itemContent = (
        <TaxDeliveryItem
          disableEdit={disableEdit}
          workflowRequestItem={workflowRequestItem}
          workflowItem={workflowItem as WorkflowSignableDocumentItem2Fragment}
          assignees={workflowRequest?.assignedContacts}
        />
      );
      break;
    default:
      return null;
  }

  const isConditional = subPosition > 0;

  return (
    <RequestItemWrapper
      key={workflowItem.id}
      isConditional={isConditional}
      number={`${position}.${isConditional ? subPosition : ''}`}
      visible={visible}
    >
      {itemContent}
    </RequestItemWrapper>
  );
}
