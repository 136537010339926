/* eslint-disable react/no-unused-class-component-methods */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import { withRouter } from 'utilities/withRouterWrapper';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import FileSaver from 'file-saver';
import _ from 'lodash';
import moment from 'moment';
import UploadFilesFilterTabs from 'components/UploadedFiles/UploadFilesFilterTabs';
import MultiTagsYearMonthModal from 'components/UploadedFiles/MultiTagsYearMonthModal';
import { FILENAME } from 'utilities/regularexpressions';
import UploadsList from 'components/UploadedFiles/UploadsList';
import ConfirmationModal from 'common/ConfirmationModal';
import Pagination from 'common/Pagination';
import FilePopup from 'components/Files/FilePopup';
import UploadFiles from 'api/UploadFiles';
import * as UploadFilesActions from 'actions/UploadFilesActions';
import * as Utilities from 'utilities/utils';
import mime from 'mime';
import Axios from 'axios';
import { GET_DOWNLOAD_URL } from 'constants/constants';
import ReplaceTags from 'components/Files/ReplaceTags';
import ExportModal from 'components/Files/ExportModal';

class UploadedFilesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uploadFilesList: [],
      showFileUploadModal: false,
      contacts: [],
      accounts: [],
      years: [],
      tags: [],
      uploadTags: [],
      accountId: '',
      contactId: '',
      // tagId: '',
      contactName: '',
      accountName: '',
      // tagName: '',
      // inputMultiTagVal: '',
      loading: false,
      errors: {},
      contactUploadFile: false,
      accountUploadFile: false,
      contactAccountUploadFile: false,
      // mutiltagdropdownOpen: false,
      // selectedMultiTagRecords: [],
      // newMultiTagList: [],
      createdBy: '',
      sort: 'desc',
      field: 'created_at',
      page: 1,
      pagination: 0,
      paginationOffset: '',
      paginationOff: '',
      totalDocuments: '',
      api: '',
      selectall: false,
      isChecked: false,
      selectedlist: [],
      selectedrecords: [],
      masterarray: [],
      maxcount: 0,
      showAlert: false,
      showerrormessgaefornextbtn: false,
      showonlyselectedcontacts: false,
      multiTagModal: false,
      selectedFilesDeleteModal: false,
      fileExt: '',
      editFileNameId: '',
      currOpenedId: '',
      currOpenedInput: '',
      oldFileName: '',
      listOfFiles: null,
      months: [],
      filterStatus: 'active',
      filterStatusModal: false,
      fileRemovedFromList: false,
      filterState: {},
      filterBGClicked: 0,
      exportModal: false,
    };
  }

  onClickFilterBG = () => {
    this.setState((prevState) => ({
      filterBGClicked: prevState.filterBGClicked + 1,
    }));
  };

  UNSAFE_componentWillMount() {
    // TODO: __HOMEDATA-AUDIT
    const storageData = Utilities.getStorageData();
    const homeDataObj = storageData.pref_type_options;
    this.setState({
      years: homeDataObj.upload_file_year,
      months: homeDataObj.upload_file_month
        ? homeDataObj.upload_file_month
        : [],
      createdBy: storageData.uname,
      tags: homeDataObj.filter_tags,
      uploadTags: homeDataObj.upload_tags,
      // newMultiTagList: homeDataObj.filter_tags,
    });
  }

  componentDidMount() {
    if (
      this.props.filesData &&
      !_.isEmpty(this.props.filesData) &&
      ((this.props.contactId && this.props.accountId) ||
        (this.props.contactId &&
          this.props.filesData.contactId &&
          this.props.filesData.contactId.value === this.props.contactId) ||
        (this.props.accountId &&
          this.props.filesData.accountId &&
          this.props.filesData.accountId.value === this.props.accountId))
    ) {
      this.setState(this.props.filesData);
    } else if (this.props.contactId) {
      UploadFiles.getContactDetailsForUploadFiles(this.props.contactId, this);
    } else if (this.props.accountId) {
      UploadFiles.getAccountDetailsForUploadFiles(this.props.accountId, this);
    }
    document.addEventListener('click', (e) => {
      if (
        e.target.nodeName === 'INPUT' ||
        e.target.classList[0] === 'icon-edit'
      ) {
        // this is current input
      } else {
        console.log("No it's not the current input");
        if (
          this.state.currOpenedInput.length > 0 &&
          this.state.currOpenedId.length > 0
        ) {
          console.log('in if did');
        } else {
          let j = {};
          if (this.state.errors.docName === 'Enter Valid document name') {
            return;
          }
          let idx = null;
          this.state.uploadFilesList.map((file, index) => {
            if (file.id === this.state.currOpenedId) {
              const f = file;
              f.doc_name = this.state.currOpenedInput;
              j = f;
              idx = index;
            }
            return null;
          });
          const uploadedFiles = this.state.uploadFilesList;
          if (Object.keys(j).length === 0) {
            return;
          }
          uploadedFiles.splice(idx, 1, j);
          this.setState({
            uploadFilesList: uploadedFiles,
            editFileNameId: '',
            errors: {},
            currOpenedInput: '',
            currOpenedId: '',
          });
        }
      }
    });
  }

  componentWillUnmount() {
    document.addEventListener('click', () => {});
  }

  // onMultiTagFocus = () => {
  //   this.setState(prevState => ({
  //     mutiltagdropdownOpen: true,
  //     tags: prevState.tags,
  //     tagId: '',
  //     tagName: '',
  //   }));
  // }
  uploadFile = (fro = '') => {
    if (fro) {
      this.setState(
        {
          showFileUploadModal: false,
          multiTagModal: false,
        },
        () => {
          const { api, sort, field, pagination } = this.state;
          const apiPath = !api ? '' : api;
          if (this.props.contactId) {
            UploadFiles.sortedData(
              sort,
              field,
              pagination + 1,
              apiPath,
              this,
              this.state.contactId.value,
              'contact',
            );
          } else if (this.props.accountId) {
            UploadFiles.sortedData(
              sort,
              field,
              pagination + 1,
              apiPath,
              this,
              this.state.accountId,
              'account',
            );
          }
          this.hideSelectedFilesDeleteModal();
        },
      );
    } else {
      this.setState({ showFileUploadModal: true });
    }
  };

  closeTagModals = (refreshData) => {
    this.setState(
      {
        multiTagModal: false,
        showReplaceTagModal: false,
      },
      () => {
        if (refreshData) {
          const { api, sort, field, pagination } = this.state;
          const apiPath = !api ? '' : api;
          if (this.props.contactId) {
            UploadFiles.sortedData(
              sort,
              field,
              pagination + 1,
              apiPath,
              this,
              this.state.contactId.value,
              'contact',
            );
          } else if (this.props.accountId) {
            UploadFiles.sortedData(
              sort,
              field,
              pagination + 1,
              apiPath,
              this,
              this.state.accountId,
              'account',
            );
          }
        }
      },
    );
  };

  selectedFilesAction = (act = '') => {
    const { masterarray: url } = this.state;
    if (!url.length && act) {
      Utilities.AlertMessage('error', 'No Selected File.', 3000);
    } else if (act === 'delete') {
      // let sourc = false;
      const selectedlist = Utilities.selectedIds(url, 'source');
      // if (selectedlist.includes('Note') || selectedlist.includes('Task') || selectedlist.includes('Message')) {
      //   sourc = true;
      // }
      // if (sourc === true) {
      //   this.setState({ showModalDeleteManual: true });
      // } else {
      //   this.setState({ selectedFilesDeleteModal: true });
      // }
      let alertText = 'Are you sure want to delete these files?';
      if (selectedlist.includes('Multiple')) {
        alertText =
          'Warning: The selected files will be deleted from all sources i.e Messages, Tasks etc.';
      }
      this.setState({
        alertText,
        selectedFilesDeleteModal: true,
      });
    } else if (act === 'addmultitag') {
      this.setState({ multiTagModal: true });
    } else if (act === 'download') {
      this.downloadFiles();
    } else if (act === 'status') {
      this.setState({ filterStatusModal: true });
    } else if (act === 'share') {
      this.setState({ shareFile: true });
    } else if (act === 'replace_tags') {
      this.setState({ showReplaceTagModal: true });
    } else if (act === 'export') {
      this.setState({ exportModal: true });
    }
  };

  closeModal = (ishared = '', uniqueRecords = '') => {
    this.setState({ shareFile: false });
    if (ishared === 'shared' && uniqueRecords.length > 0) {
      const { api, sort, field, pagination } = this.state;
      const apiPath = !api ? '' : api;
      if (this.props.contactId) {
        UploadFiles.sortedData(
          sort,
          field,
          pagination + 1,
          apiPath,
          this,
          this.state.contactId.value,
          'contact',
        );
      } else if (this.props.accountId) {
        UploadFiles.sortedData(
          sort,
          field,
          pagination + 1,
          apiPath,
          this,
          this.state.accountId,
          'account',
        );
      }
      this.hideSelectedFilesDeleteModal();
    }
  };

  downloadFiles = () => {
    const { masterarray: url } = this.state;
    const selectedDocumentIds = url.map((e) => e.id);
    this.setState({ loading: true });
    Axios.get(`${GET_DOWNLOAD_URL}?ids=${selectedDocumentIds.join(',')}`).then(
      (res) => {
        console.log(res.data);
        if (res.data && res.data.urls) {
          const files = res.data.urls;
          const zip = new JSZip();
          let count = 0;
          const file_names = {};
          files.map((item) => {
            const urlPath = item.aws_url_origional;
            let fileName = item.doc_name;
            const temp_doc_name = fileName.split('.');
            if (file_names[item.doc_name]) {
              temp_doc_name[0] = `${temp_doc_name[0]}(${
                file_names[item.doc_name]
              })`;
              file_names[item.doc_name] += 1;
            } else {
              file_names[item.doc_name] = 1;
            }
            if (temp_doc_name.length === 1 && item.content_type) {
              temp_doc_name[1] = mime.getExtension(item.content_type);
            }
            fileName = temp_doc_name.join('.');
            // return false;
            JSZipUtils.getBinaryContent(urlPath, (err, data) => {
              if (err) {
                throw err;
              }
              const convertTime = moment()
                .add(moment().utcOffset(), 'minutes')
                .format('YYYY-MM-DD HH:mm:ss');
              zip.file(fileName, data, {
                binary: true,
                createFolders: false,
                date: new Date(convertTime),
              });
              count += 1;
              if (count === files.length) {
                zip.generateAsync({ type: 'blob' }).then((content) => {
                  FileSaver.saveAs(content, 'all_docs.zip');
                  this.setState({ loading: false });
                });
              }
            });
            return true;
          });
        } else {
          Utilities.AlertMessage('error', res.data.message, 3000);
        }
      },
    );
  };

  dataAfterEditDocument = () => {
    let index = 1;
    if (
      this.state.fileRemovedFromList &&
      this.state.uploadFilesList.length === 1
    ) {
      index =
        this.state.pagination === 0
          ? this.state.pagination + 1
          : this.state.pagination;
    } else if (
      (!this.state.fileRemovedFromList &&
        this.state.uploadFilesList.length !== 1) ||
      (this.state.fileRemovedFromList &&
        this.state.uploadFilesList.length !== 1) ||
      (!this.state.fileRemovedFromList &&
        this.state.uploadFilesList.length === 1)
    ) {
      index = this.state.pagination + 1;
    } else {
      index = this.state.pagination - 1;
    }
    if (this.props.contactId) {
      UploadFiles.sortedData(
        this.state.sort,
        this.state.field,
        index,
        this.state.api,
        this,
        this.state.contactId.value,
        'contact',
      );
    } else if (this.props.accountId) {
      UploadFiles.sortedData(
        this.state.sort,
        this.state.field,
        index,
        this.state.api,
        this,
        this.state.accountId,
        'account',
      );
    }
  };

  handleCheckbox = (input, key, selectedrecord) => {
    const component = this;
    let checkbox;
    let selectallcheckbox;
    let masterarrayy = [];
    let selectedrecordss = [];
    const files = component.state.uploadFilesList;
    masterarrayy = component.state.selectedlist;
    selectedrecordss = component.state.selectedrecords;
    files.map((item) => {
      let index = -1;
      let masterindex = -1;
      if (item.id === input.id) {
        masterarrayy.map((m, k) => {
          if (m.id === item.id && m.doc_name === item.doc_name) {
            masterindex = k;
          }
          return null;
        });
        if (masterindex !== -1) {
          index = selectedrecordss.indexOf(selectedrecord);
          if (index !== -1) {
            if (component.state.selectall) {
              component.setState({ selectall: false });
              checkbox = document.getElementById('selectallcheckboxid');
              if (checkbox) {
                checkbox.indeterminate = true;
                checkbox.checked = false;
              }
            }
            selectedrecordss.splice(index, 1);
            masterarrayy.splice(masterindex, 1);
          }
        } else if (
          component.state.maxcount !== 0 &&
          component.state.selectedrecords.length >= component.state.maxcount &&
          masterindex === -1
        ) {
          component.setState({ showAlert: true });
        } else {
          index = selectedrecordss.indexOf(selectedrecord);
          if (index === -1) {
            selectedrecordss.push(selectedrecord);
            masterarrayy.push(item);
            checkbox = document.getElementById('selectallcheckboxid');
            if (checkbox) {
              checkbox.indeterminate = true;
            }
          }
        }
      }
      return null;
    });
    component.setState({
      selectedrecords: selectedrecordss,
      masterarray: masterarrayy,
    });
    if (
      (this.state.showerrormessgaefornextbtn ||
        this.state.showonlyselectedcontacts) &&
      !this.isAtleastOneContactSelected()
    ) {
      this.setState({ showonlyselectedcontacts: false });
    } else {
      this.setState({ showerrormessgaefornextbtn: false });
    }
    let selectedfiles = 0;
    files.map((eachone) => {
      masterarrayy.map((m) => {
        if (m.id === eachone.id && m.doc_name === eachone.doc_name) {
          selectedfiles += 1;
        }
        return null;
      });
      return null;
    });
    const unselectedfiles = files.length - selectedfiles;
    if (selectedfiles === 0) {
      selectallcheckbox = document.getElementById('selectallcheckboxid');
      if (selectallcheckbox) {
        selectallcheckbox.indeterminate = false;
      }
    } else if (unselectedfiles === 0) {
      checkbox = document.getElementById('selectallcheckboxid');
      if (checkbox) {
        checkbox.indeterminate = false;
        checkbox.checked = true;
      }
      this.setState({ selectall: true });
    }
  };

  handleSelectAll = () => {
    let masterarrayy = [];
    let selectedrecordss = [];
    let checkbox;
    const filestoselect = this.state.uploadFilesList;
    masterarrayy = this.state.selectedlist;
    let unselectedfiles = 0;
    let selectedfiles = 0;
    filestoselect.map((eachone) => {
      masterarrayy.map((m) => {
        if (m.id === eachone.id && m.doc_name === eachone.doc_name) {
          selectedfiles += 1;
        }
        return selectedfiles;
      });
      return null;
    });
    unselectedfiles = filestoselect.length - selectedfiles;
    const totalfiles = unselectedfiles + this.state.selectedrecords.length;
    selectedrecordss = this.state.selectedrecords;
    if (!this.state.selectall && selectedfiles > 0) {
      filestoselect.map((eachone) => {
        // eachone.checked = false
        const index = this.state.selectedrecords.indexOf(
          `${eachone.id}_${eachone.doc_name ? eachone.doc_name : ''}`,
        );
        let masterindex = -1;
        masterarrayy.map((m, k) => {
          if (m.id === eachone.id && m.doc_name === eachone.doc_name) {
            masterindex = k;
          }
          return null;
        });
        if (index !== -1 && masterindex !== -1) {
          selectedrecordss.splice(index, 1);
          masterarrayy.splice(masterindex, 1);
        }
        return null;
      });

      this.setState({
        selectedrecords: selectedrecordss,
        masterarray: masterarrayy,
      });
      checkbox = document.getElementById('selectallcheckboxid');
      if (checkbox) {
        checkbox.indeterminate = false;
        checkbox.checked = false;
      }
    } else if (
      this.state.maxcount !== 0 &&
      !this.state.selectall &&
      totalfiles > this.state.maxcount
    ) {
      this.setState({ showAlert: true });
    } else {
      filestoselect.map((eachone) => {
        if (this.state.selectall) {
          const index = selectedrecordss.indexOf(
            `${eachone.id}_${eachone.doc_name ? eachone.doc_name : ''}`,
          );
          let masterindex = -1;
          masterarrayy.map((m, k) => {
            if (m.id === eachone.id && m.doc_name === eachone.doc_name) {
              masterindex = k;
            }
            return null;
          });
          if (index !== -1 && masterindex !== -1) {
            selectedrecordss.splice(index, 1);
            masterarrayy.splice(masterindex, 1);
          }
        } else {
          let elementexists = false;
          masterarrayy.map((m) => {
            if (m.id === eachone.id && m.doc_name === eachone.doc_name) {
              elementexists = true;
            }
            return null;
          });
          if (!elementexists) {
            selectedrecordss.push(
              `${eachone.id}_${eachone.doc_name ? eachone.doc_name : ''}`,
            );
            masterarrayy.push(eachone);
          }
        }
        return null;
      });
      this.setState({
        selectall: !this.state.selectall,
        selectedrecords: selectedrecordss,
        masterarray: masterarrayy,
        showerrormessgaefornextbtn: false,
      });
    }
  };

  listOfFilesState = (status) => {
    if (
      (status === 'archive' && this.state.filterStatus === 'active') ||
      (status === 'active' && this.state.filterStatus === 'archived')
    ) {
      this.setState({ fileRemovedFromList: true });
    } else if (
      (status === 'active' && this.state.filterStatus === 'active') ||
      (status === 'archive' && this.state.filterStatus === 'archived')
    ) {
      this.setState({ fileRemovedFromList: false });
    } else {
      this.setState({
        fileRemovedFromList: true,
        listOfFiles: this.state.listOfFiles - 1,
      });
    }
  };

  fileExtention = (item) => {
    let m;
    if (item.doc_name.includes('.')) {
      const n = item.doc_name.split('.').pop();
      m = `.${n}`;
    } else {
      m = '';
    }
    this.setState({
      fileExt: m,
      editFileNameId: item.id,
      currOpenedInput: item.doc_name,
      currOpenedId: item.id,
      oldFileName: item.doc_name,
    });
  };

  handleChangeFileName = (e, val) => {
    let idx = null;
    let j = {};
    this.state.uploadFilesList.map((file, index) => {
      if (file.id === val.id) {
        const f = file;
        f.doc_name = e.target.value;
        idx = index;
        j = f;
      }
      return null;
    });
    const uploadedFiles = this.state.uploadFilesList;
    uploadedFiles.splice(idx, 1, j);
    this.setState({
      uploadFilesList: uploadedFiles,
      errors: {},
      currentEditIndex: idx,
    });
  };

  handleKeyPress = (e, item, fro = '') => {
    e.stopPropagation();
    if (e.key === 'Enter' || fro === 'blurevent') {
      let file_name;
      if (item.doc_name.includes('.')) {
        file_name = item.doc_name.substring(0, item.doc_name.lastIndexOf('.'));
      } else {
        file_name = item.doc_name;
      }
      const errors = {};
      if (file_name && (!FILENAME.test(file_name) || file_name.includes('.'))) {
        errors.docName = 'Enter Valid document name';
      } else if (!file_name || file_name.length === 0 || !file_name) {
        errors.docName = 'Please enter document name';
      }
      this.setState({ errors });
      if (!Object.keys(errors).length) {
        const docId = item.id;
        const doc = file_name + this.state.fileExt;
        const mydata = { display_name: doc };
        UploadFiles.ChangeFileName(mydata, docId, this, fro);
      }
    }
  };

  handlePageChange = (index) => {
    const component = this;
    if (index === component.state.pagination + 1) {
      return false;
    }
    let apiPath = '';
    if (component.state.api) {
      apiPath = component.state.api;
    }
    if (this.props.contactId) {
      UploadFiles.sortedData(
        component.state.sort,
        component.state.field,
        index,
        apiPath,
        component,
        component.state.contactId.value,
        'contact',
      );
    } else if (this.props.accountId) {
      UploadFiles.sortedData(
        component.state.sort,
        component.state.field,
        index,
        apiPath,
        component,
        component.state.accountId,
        'account',
      );
    }
    // scrolls to top
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
    return true;
  };

  handleSorting = (fieldName) => {
    let stateSort = this.state.sort;
    if (this.state.field === fieldName) {
      if (stateSort === 'asc') {
        stateSort = 'desc';
      } else {
        stateSort = 'asc';
      }
    } else {
      stateSort = 'desc';
    }
    let apiPath = '';
    if (this.state.api) {
      apiPath = this.state.api;
    }

    if (this.props.contactId) {
      this.setState(
        {
          sort: stateSort,
          field: fieldName,
        },
        () => {
          UploadFiles.sortedData(
            this.state.sort,
            this.state.field,
            this.state.page,
            apiPath,
            this,
            this.state.contactId.value,
            'contact',
          );
        },
      );
    } else if (this.props.accountId) {
      this.setState(
        {
          sort: stateSort,
          field: fieldName,
        },
        () => {
          UploadFiles.sortedData(
            this.state.sort,
            this.state.field,
            this.state.page,
            apiPath,
            this,
            this.state.accountId,
            'account',
          );
        },
      );
    }
  };

  areAllContactsSelected = () => {
    const pagefiles = this.state.uploadFilesList;
    let count = 0;
    const masterarrayy = this.state.masterarray;
    pagefiles.map((eachone) => {
      masterarrayy.map((m) => {
        if (m.id === eachone.id && m.doc_name === eachone.doc_name) {
          count += 1;
        }
        return null;
      });
      return null;
    });
    const checkbox = document.getElementById('selectallcheckboxid');
    if (pagefiles.length === count && count !== 0) {
      this.setState({ selectall: true });
      if (checkbox) {
        checkbox.checked = true;
      }
    } else if (pagefiles.length === count && count === 0) {
      this.setState({ selectall: false });
      if (checkbox) {
        checkbox.checked = false;
      }
    } else if (count > 0) {
      if (checkbox) {
        checkbox.indeterminate = true;
      }
    } else {
      this.setState({ selectall: false });
      if (checkbox) {
        checkbox.indeterminate = false;
        checkbox.checked = false;
      }
    }
  };

  hideSelectedFilesDeleteModal = () => {
    this.setState(
      {
        selectedlist: [],
        masterarray: [],
        selectedrecords: [],
        selectedFilesDeleteModal: false,
      },
      () => this.areAllContactsSelected(),
    );
  };

  selectedFilesDeleteConfirmed = (act = '') => {
    if (act === 'delete') {
      UploadFiles.deleteSelectedData(this);
    } else if (act === 'filterstatus') {
      const { filterStatus, selectedlist } = this.state;
      const data = { id: Utilities.selectedIds(selectedlist, 'id') };
      if (filterStatus === 'active') {
        data.archive = 'true';
      }
      UploadFiles.archiveFile(this, data);
    }
  };

  filterList = (apiPath, status, com) => {
    this.setState({
      api: apiPath,
      filterStatus: status,
      filterState: com,
    });
    if (this.props.contactId) {
      UploadFiles.sortedData(
        this.state.sort,
        this.state.field,
        this.state.page,
        apiPath,
        this,
        this.state.contactId.value,
        'contact',
      );
    } else if (this.props.accountId) {
      UploadFiles.sortedData(
        this.state.sort,
        this.state.field,
        this.state.page,
        apiPath,
        this,
        this.state.accountId,
        'account',
      );
    }
  };

  render() {
    const list = this.state.uploadFilesList;
    const noneStyle = { display: 'none' };
    const blockStyle = { display: 'block' };
    let totalpages =
      this.state.totalDocuments !== 0
        ? Math.ceil(this.state.totalDocuments / 15)
        : 0;
    totalpages = parseInt(totalpages, 10);
    const totalrecords = this.state.totalDocuments;
    let offset = 0;
    let off = 0;
    if (this.state.uploadFilesList !== undefined) {
      offset = this.state.paginationOffset;
      off = this.state.paginationOff;
    }
    const {
      listOfFiles,
      filterStatus,
      filterStatusModal,
      selectedlist,
      showFileUploadModal,
      multiTagModal,
      showReplaceTagModal,
      exportModal,
    } = this.state;
    return (
      <div
        className="center-wrapper"
        onClick={this.onClickFilterBG}
        role="presentation"
      >
        <div id="loading" style={this.state.loading ? blockStyle : noneStyle} />
        {listOfFiles === null || listOfFiles === undefined ? (
          <h3 className="text-light text-small">Fetching Files ...</h3>
        ) : (
          <div>
            <UploadFilesFilterTabs
              data={this.state}
              component={this}
              uploadFile={() => this.uploadFile()}
              filterBGClicked={this.state.filterBGClicked}
            />
            <div className="tab-content">
              <div className="tab-pane active" id="review">
                <div id="inbox-list" className="table tableWrap">
                  <UploadsList
                    data={this.state}
                    component={this}
                    uploadFilesList={list}
                    handleSorting={this.handleSorting}
                  />
                  <Pagination
                    currentPage={
                      list.length >= 1 ? this.state.pagination + 1 : 0
                    }
                    totalPages={totalpages}
                    totalRecords={totalrecords}
                    offset={totalrecords === 0 ? offset - 1 : offset}
                    off={off}
                    handlePageChange={this.handlePageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {this.state.shareFile && (
          <FilePopup
            shareFile
            shareType="multiple"
            sharedFileDetails={this.state.masterarray}
            closeModal={this.closeModal}
          />
        )}
        {multiTagModal && (
          <MultiTagsYearMonthModal
            closeModal={this.closeTagModals}
            selectedrecords={selectedlist}
          />
        )}
        {showReplaceTagModal && (
          <ReplaceTags
            closeModal={this.closeTagModals}
            selectedrecords={selectedlist}
          />
        )}
        {exportModal && (
          <ExportModal
            selectedRecords={selectedlist}
            closeModal={() => this.setState({ exportModal: false })}
          />
        )}
        <ConfirmationModal
          isOpen={this.state.selectedFilesDeleteModal}
          headerText="Confirm delete these files?"
          messageText={this.state.alertText || ''}
          noBtnClick={this.hideSelectedFilesDeleteModal}
          yesBtnClick={() => this.selectedFilesDeleteConfirmed('delete')}
          yesBtnText="Delete Files"
          noBtnText="Cancel"
        />
        {showFileUploadModal && (
          <FilePopup closeModal={() => this.uploadFile('close')} />
        )}
        <ConfirmationModal
          isOpen={filterStatusModal}
          headerText={`Confirm ${
            filterStatus === 'active' ? 'archive' : 'unarchive'
          } ${selectedlist.length > 1 ? 'these files' : 'this file'}`}
          messageText={`Are you sure you want to ${
            filterStatus === 'active' ? 'archive' : 'unarchive'
          } ${selectedlist.length > 1 ? 'these files' : 'this file'}?`}
          noBtnClick={() => this.setState({ filterStatusModal: false })}
          yesBtnClick={() => this.selectedFilesDeleteConfirmed('filterstatus')}
          yesBtnText="Yes"
          noBtnText="No"
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  filesData: state.uploadFilesReducer.filesData,
});

const mapDispatchToProps = (dispatch) => ({
  UploadFilesActions: bindActionCreators(UploadFilesActions, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UploadedFilesList),
);
