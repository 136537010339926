import { withRouter } from 'utilities/withRouterWrapper';
import { Button, Modal } from 'reactstrap';
import { useNavigate } from 'react-router-dom-latest';

const UnauthorisedFeature = () => {
  const navigate = useNavigate();
  function closeModal() {
    navigate('/dashboard');
  }

  return (
    <div>
      <Modal
        isOpen
        backdrop
        className="customModal customModal--alert customModal--w700 customModal--nopadding"
      >
        <div className="ModalHeader">
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={closeModal}
          >
            <i className="icon-close2" aria-hidden="true" />
          </button>
        </div>
        <center>
          <br />
          <br />
          <h5>
            This Feature is not allowed
            <br />
            (Unauthorized access)
          </h5>
          <br />
          <div className="pb-3 mb-4 col-container section-title">
            <h6 className="stepBacklink" role="presentation">
              <Button
                type="button"
                onClick={closeModal}
                className="btn btn-primary"
              >
                <i className="icon-left-arrow" />
                Back
              </Button>
            </h6>
          </div>
        </center>
      </Modal>
    </div>
  );
};

export default withRouter(UnauthorisedFeature);
