import { Box } from '@mui/material';
import React from 'react';

export interface IntegrationModuleListItemProps {
  iconSrc?: string;
  content?: React.ReactNode;
}

export const IntegrationModuleListItem = ({
  iconSrc,
  content,
}: IntegrationModuleListItemProps) => (
  <Box
    display="flex"
    flexDirection="row"
    justifyContent="flex-start"
    alignItems="center"
    height="40px"
    width="100%"
  >
    {iconSrc && (
      <Box component="img" src={iconSrc} height="100%" width="auto" mr={2} />
    )}
    <Box>{content}</Box>
  </Box>
);
