import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import Interweave from 'interweave';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useStorageData } from 'hooks/useStorageData';

const PrivacyPolicy = (props) => {
  // TODO: __HOMEDATA-AUDIT
  const { storageData } = useStorageData();
  if (storageData && storageData.privacy_content) {
    return (
      <div>
        <Modal
          className="customModal customModal--w990 customModal--nopadding"
          isOpen={props.modalClick}
        >
          <div className="ModalHeader">
            <button
              type="button"
              onClick={props.closeModal}
              className="close"
              aria-label="Close"
            >
              <i className="icon-close2" aria-hidden="true" />
            </button>
          </div>
          <ModalBody className="modalContent" style={{ maxHeight: 'none' }}>
            <div className="modalContent--inner">
              <div className="docWrap">
                <Scrollbars
                  style={{ width: '100%', height: '100%' }}
                  autoHide
                  autoHideTimeout={1000}
                >
                  <div className="term-wrapper">
                    <Interweave
                      allowAttributes
                      content={storageData.privacy_content}
                    />
                  </div>
                </Scrollbars>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
  return null;
};

export default PrivacyPolicy;
