import { useEffect } from 'react';
import { REQUESTS_PAGE_TITLES } from 'components/Requests/requests.constants';
import { WorkflowView } from 'components/Requests/components/WorkflowView/WorkflowView';
import { WorkflowViewProvider } from 'components/Requests/components/WorkflowView/context/WorkflowViewContext';

export const DispatchView = () => {
  useEffect(() => {
    document.title = REQUESTS_PAGE_TITLES.dispatch;
  }, []);

  return (
    <WorkflowViewProvider>
      <WorkflowView title={REQUESTS_PAGE_TITLES.dispatch} isDispatch />
    </WorkflowViewProvider>
  );
};
