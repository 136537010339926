import { Box, Stack, Typography, styled } from '@mui/material';
import TieredBottom from './TieredBottom';
import UpgradeCard from './UpgradeCard';

interface Props {
  currentPlan: string;
}

const OfferingsWrapper = styled(Box)({
  marginTop: '1rem',
  width: '96%',
  alignSelf: 'center',
});

/**
 * This component renders the tier upgrade options
 * when the Firm only has the core tier, otherwise,
 * it simply displays the re-usable TieredBottom
 * component.
 */
export default function TieredOfferings({ currentPlan }: Props) {
  if (currentPlan.toLocaleUpperCase().includes('PRO'))
    return (
      <OfferingsWrapper>
        <TieredBottom currentPlan={currentPlan} />
      </OfferingsWrapper>
    );

  return (
    <OfferingsWrapper>
      <Stack mt="1rem">
        <Typography variant="h2" fontWeight="bold">
          Upgrade to a better plan
        </Typography>
        <Stack
          direction="row"
          justifyContent="space-around"
          alignSelf="center"
          alignItems="center"
          width="70%"
          gap={4}
        >
          <UpgradeCard key={1} monthly tierTitle="Pro Tier" price="75" />
          <UpgradeCard
            key={2}
            popular
            tierTitle="Pro Tier 2 Years"
            price="50"
          />
          <UpgradeCard key={1} tierTitle="Pro Tier 1 year" price="60" />
        </Stack>
        <TieredBottom currentPlan={currentPlan} />
      </Stack>
    </OfferingsWrapper>
  );
}
