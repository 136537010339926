import { ReactComponent as VaultIcon } from 'images/icons/smart_vault/vault.svg';
import { ReactComponent as ContainerIcon } from 'images/icons/smart_vault/container.svg';
import { ReactComponent as FolderIcon } from 'images/icons/smart_vault/folder.svg';

export type NodeType =
  'VaultNodeType' | 'FolderNodeType' | 'AccountNodeType' | 'ContainerNodeType' | 'FileNodeType'

export type SmartVaultItem = {
  id: string,
  nodeType: NodeType,
  uri: string,
  name: string,
  modifiedOn: string,
  children: {nodeType: NodeType}[]
}

export const icon = (item: SmartVaultItem) => {
  if (item.nodeType !== 'FileNodeType') {
    return directoryIcon(item.nodeType)
  }

  return <i className={`${getFileTypeIcon(extension(item.name))} mt-1`} />
}

export const extension = (filename: string) =>
  filename.split('.').slice(-1)[0].toLowerCase()

const directoryIcon = (nodeType: NodeType) => {
  switch(nodeType) {
    case 'VaultNodeType':
    case 'AccountNodeType':
      return <VaultIcon />
    case 'ContainerNodeType':
      return <ContainerIcon />
    default:
      return <FolderIcon />
  }
}

const getFileTypeIcon = (ext: string) => {
  let logo = 'icon-File';
  if (
    ext === 'xls' ||
    ext === 'xlt' ||
    ext === 'xlm' ||
    ext === 'xlsx' ||
    ext === 'xlsm' ||
    ext === 'xltx' ||
    ext === 'xltm' ||
    ext === 'xlsb' ||
    ext === 'xla' ||
    ext === 'xlam' ||
    ext === 'xll' ||
    ext === 'xlw'
  ) {
    logo = 'icon-excel';
  } else if (ext === 'doc' || ext === 'docx' || ext === 'docm' || ext === 'dotx' || ext === 'dotm' || ext === 'docb') {
    logo = 'icon-word';
  } else if (ext === 'zip') {
    logo = 'icon-zip';
  } else if (
    ext === 'ppt' ||
    ext === 'pot' ||
    ext === 'pps' ||
    ext === 'pptx' ||
    ext === 'pptm' ||
    ext === 'potx' ||
    ext === 'potm' ||
    ext === 'ppam' ||
    ext === 'ppsx' ||
    ext === 'ppsm' ||
    ext === 'sldx' ||
    ext === 'sldm'
  ) {
    logo = 'icon-powerpoint';
  } else if (ext === 'jpeg' || ext === 'png' || ext === 'gif' || ext === 'jpg') {
    logo = 'icon-image-preview';
  } else if (ext === 'pdf') {
    logo = 'icon-filetype-pdf';
  } else {
    logo = 'icon-notes';
  }
  return logo;
}
