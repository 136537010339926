import React from 'react';
import Select from 'react-select';

const OwnerSelect = ({
  owners,
  handleOwnerChange,
  owner,
  invalidOwner,
  selectStyles,
  hasRecurred,
}) => (
  <div className="InvoiceForm__Field">
    <Select
      name="owner"
      id="owner"
      placeholder="Select Owner"
      className="Select"
      styles={selectStyles}
      options={owners}
      onChange={handleOwnerChange}
      value={owner}
      isSearchable
      isClearable
      isDisabled={hasRecurred}
      invalidValue={invalidOwner}
    />
    {invalidOwner && (
      <div className="InvoiceForm__Error">This is a required field</div>
    )}
  </div>
);

export default OwnerSelect;
