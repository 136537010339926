import { SMSSettingsType } from 'components/SMS/components/SMSSettingsForm/FormValidation';

interface BrandRegistrationsPayload {
  legal_name: string;
  address_line1: string;
  address_city: string;
  address_state: string;
  address_zip: string;
  business_type: string;
  ein: string;
  email: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  preferred_area_code: string;
  firm_website_url: string;
  privacy_policy_link: string;
}

export const mapSmsSettings: (
  settings: SMSSettingsType,
) => BrandRegistrationsPayload = (settings) => ({
  legal_name: settings.legalName,
  address_line1: settings.addressLine1,
  address_city: settings.addressCity,
  address_state: settings.addressState,
  address_zip: settings.addressZip,
  business_type: settings.businessType,
  ein: settings.ein,
  email: settings.email,
  first_name: settings.firstName,
  last_name: settings.lastName,
  phone_number: settings.phoneNumber,
  preferred_area_code: settings.preferredAreaCode,
  firm_website_url: settings.firmWebsiteUrl,
  privacy_policy_link: settings.privacyPolicyUrl || '',
});
