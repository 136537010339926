/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import InputField from 'common/InputField';
import { TextAreaField } from 'common/CustomFields';
import Button from 'common/Button/Button';
import { BUTTON_ICON_LOCATION_BACK } from 'constants/constants';
import { EncryptedField } from 'common/EncryptedField/EncryptedField';
import {
  enableAddBankAccountButton,
  enableDeleteAccountButton,
} from './AccountUtilities';

const BankAccountDetails = (props) => {
  const { errors, disabled, bankAccountData } = props.data;
  const { bankAccountErrors } = errors;

  let disableAddButton = false;
  if (
    (bankAccountErrors && Object.keys(bankAccountErrors).length) ||
    !enableAddBankAccountButton(bankAccountData)
  ) {
    disableAddButton = true;
  }

  const {
    bankName,
    routingNumber,
    accountNumber,
    accountType,
    bankWebsite,
    bankNotes,
    autoPayment,
  } = props.fieldNames;

  return bankAccountData.map((each, key) => {
    const name = `bankaccount${key}`;
    const bankAccountError =
      bankAccountErrors && bankAccountErrors[key] ? bankAccountErrors[key] : {};
    return (
      <div key={name}>
        <div className="row no-gutters align-items-center mt-3 mb-3">
          <h5 className="mb-0">{`${key + 1}. Bank Account Details`}</h5>
          {enableDeleteAccountButton(each) ? (
            <Button
              variant="ghost"
              icon="delete-small"
              onClick={() => props.deleteField(key)}
              disabled={disabled}
              size="xsmall"
              iconLocation={BUTTON_ICON_LOCATION_BACK}
            />
          ) : null}
        </div>
        <div className="row">
          <InputField
            name={name + bankName}
            label="Bank Name"
            className="col-sm-12 col-lg-4 col-md-4 form-group"
            onChange={(input) => props.onChange(input, bankName, key)}
            onBlur={props.onBlur}
            value={each[bankName]}
            error={bankAccountError[bankName]}
          />
          <InputField
            name={name + routingNumber}
            label="Routing #"
            className="col-sm-12 col-lg-4 col-md-4 form-group"
            onChange={(input) => props.onChange(input, routingNumber, key)}
            onBlur={props.onBlur}
            value={each[routingNumber]}
            error={bankAccountError[routingNumber]}
            type="text"
          />
          <EncryptedField
            recordId={each.id}
            label="Account #"
            recordType="BankAccount"
            fieldName="account_number"
            formFieldName={`encryptedAccountNumber-${key}`}
            className="col-sm-12 col-lg-4 col-md-4 form-group form-icon"
            value={each.encryptedAccountNumber}
            error={bankAccountError[accountNumber]}
            onChange={props.handleEncryptedFieldChange}
            hasEncryptedValue={each?.encrypted_account_number?.populated}
          />
        </div>
        <div className="row">
          <InputField
            name={name + accountType}
            label="Account type"
            className="col-sm-12 col-lg-4 col-md-4 form-group"
            onChange={(input) => props.onChange(input, accountType, key)}
            onBlur={props.onBlur}
            value={each[accountType]}
            error={bankAccountError[accountType]}
          />
          <InputField
            name={name + bankWebsite}
            label="Website"
            className="col-sm-12 col-lg-4 col-md-4 form-group"
            onChange={(input) => props.onChange(input, bankWebsite, key)}
            onBlur={props.onBlur}
            value={each[bankWebsite]}
            error={bankAccountError[bankWebsite]}
          />
          <div className="col-sm-12 col-lg-4 col-md-4 form-group mt-4 pt-1">
            <div className="checkbox checkbox-primary check-container">
              <label htmlFor={name + autoPayment}>
                <span className="pl-2">
                  Use this account for monthly ACH auto payments?
                </span>
                <input
                  name={name + autoPayment}
                  id={name + autoPayment}
                  type="checkbox"
                  onChange={(ee) =>
                    props.onChange(ee, autoPayment, key, 'checkbox')
                  }
                  checked={each[autoPayment] ? 1 : 0}
                  error={bankAccountError[autoPayment]}
                />
                <i className="checkmark" />
              </label>
            </div>
          </div>
        </div>
        <div className="row">
          <TextAreaField
            name={name + bankNotes}
            label="Notes"
            className="col-sm-12 col-lg-12 col-md-12 form-group"
            onChange={(input) => props.onChange(input, bankNotes, key)}
            onBlur={props.onBlur}
            value={each[bankNotes]}
            error={bankAccountError[bankNotes]}
            type="textarea"
            rows="2"
          />
        </div>
        {bankAccountData.length - 1 === key ? (
          <div className="row">
            <div className="pt-2 pl-2">
              <Button
                variant="ghost"
                label=" + Add another bank account"
                disabled={disableAddButton}
                onClick={() => props.addNewField('dependents')}
                size="xsmall"
              />
            </div>
          </div>
        ) : null}
      </div>
    );
  });
};

export default BankAccountDetails;
