/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { Stack, Typography, Chip } from '@mui/material';
import styled from '@emotion/styled';
import { useFlags } from 'launchdarkly-react-client-sdk';
import FilterByRecipient from './FilterByRecipient';
import FilterByType from './FilterByType';
import FilterByDate from './FilterByDate';
import FilterBySorting from './FilterBySorting';
import FilterBySearch from './FilterBySearch';

const SFilterChip = styled(Chip)(() => ({
  color: '#919eab',
  margin: '0px',
  paddingLeft: '0.5rem',
  paddingRight: '0px',
}));

const ActivityFilter = ({
  types,
  values: {
    selectedType,
    selectedRecepientData,
    selectedFromDate,
    selectedToDate,
    activitySearch,
    sortBy,
  },
  selectedTypeChange,
  setSelectedRecepientData,
  setSelectedFromDate,
  setSelectedToDate,
  setSortBy,
  setActivitySearch,
  id,
}) => {
  const { timelineSearchFeature, timelineRecipientFlag } = useFlags();
  const removeTypeFilter = (option) => {
    const newSelectedType = [...selectedType];
    const index = newSelectedType.findIndex(
      (value) => value.key === option.key,
    );
    newSelectedType.splice(index, 1);
    selectedTypeChange(newSelectedType);
  };

  const removeRecepientFilter = () => {
    setSelectedRecepientData(null);
  };

  const removeDateFilter = () => {
    setSelectedFromDate(null);
    setSelectedToDate(null);
  };

  const clearAllFilter = () => {
    setSelectedRecepientData();
    selectedTypeChange([]);
    setSelectedFromDate(null);
    setSelectedToDate(null);
  };

  return (
    <Stack flexWrap="wrap" maxWidth={850} gap={1} sx={{ marginBottom: '20px' }}>
      <Stack
        direction="row"
        sx={{
          display: 'flex',
          alignItems: 'center',
          marginTop: '20px',
          justifyContent: 'space-between',
          marginBottom: '20px',
        }}
      >
        <Typography fontWeight="bolder" variant="h3">
          Timeline
        </Typography>
        {timelineSearchFeature && (
          <FilterBySearch
            activitySearch={activitySearch}
            setActivitySearch={setActivitySearch}
          />
        )}
      </Stack>

      <Stack direction="row" alignItems="center" flexWrap="wrap" gap={1}>
        <Typography variant="body2" padding={1}>
          Filter by
        </Typography>
        <Stack direction="row" alignItems="center" flexWrap="wrap" gap={2}>
          <FilterByType
            types={types}
            selectedType={selectedType}
            selectedTypeChange={selectedTypeChange}
          />
          {timelineRecipientFlag && (
            <FilterByRecipient
              selectedRecepientData={selectedRecepientData}
              setSelectedRecepientData={setSelectedRecepientData}
              id={id}
            />
          )}
          <FilterByDate
            selectedFromDate={selectedFromDate}
            selectedToDate={selectedToDate}
            setSelectedFromDate={setSelectedFromDate}
            setSelectedToDate={setSelectedToDate}
          />
          {(selectedType.length ||
            selectedRecepientData ||
            selectedFromDate ||
            selectedToDate) && (
            <Chip
              label="Clear all"
              size="medium"
              sx={{ bgcolor: 'inherit', color: '#919eab' }}
              onDelete={() => clearAllFilter()}
            />
          )}
        </Stack>
        <Stack
          direction="row"
          sx={{ marginLeft: 'auto', alignItems: 'center' }}
        >
          <Typography variant="body2" padding={1}>
            Sort by
          </Typography>
          <FilterBySorting setSortBy={setSortBy} sortBy={sortBy} />
        </Stack>
      </Stack>
      <Stack direction="row" flexWrap="wrap" gap={1}>
        {/* Type Filter clear Functionality */}
        {selectedType &&
          selectedType.map((option) => (
            <SFilterChip
              id={option.id}
              size="medium"
              variant="outlined"
              label={option.label}
              onDelete={() => removeTypeFilter(option)}
            />
          ))}
        {/* Receipient Filter Clear Functinlity */}
        {selectedRecepientData && (
          <SFilterChip
            id={selectedRecepientData.id}
            size="medium"
            variant="outlined"
            label={selectedRecepientData.label}
            onDelete={() => removeRecepientFilter(selectedRecepientData.id)}
          />
        )}
        {selectedFromDate && selectedToDate && (
          <SFilterChip
            id="daterange"
            size="medium"
            variant="outlined"
            label={`(${selectedFromDate}) - (${selectedToDate})`}
            onDelete={() => removeDateFilter()}
          />
        )}
      </Stack>
    </Stack>
  );
};

export default ActivityFilter;
