import React from 'react';
import { Grid, Box } from '@mui/material';
import InFocusCountComponent from './InFocusCountComponent';

/* TODO: Remove all the bootstrap classes and use styled components instead */

const InFocusCountSection = ({
  inFocusCountTabs,
  inFocusCount,
  redirectUrl,
  accountName,
  accountID,
}) => (
  <Grid
    className="InFocusCountSection"
    key={inFocusCountTabs.value}
    container
    sx={{ mb: 3, borderBottom: '1px solid', borderColor: 'divider' }}
  >
    <Grid lg={9} xs={12} sx={{ mx: 'auto' }} className="center-wrapper">
      <div className="col-container pb-1">
        <Box component="div" sx={{ py: 2 }} className="bm">
          <h3>In Focus</h3>
          <div className="text-dark">
            This page highlights outstanding action items for this account.
          </div>
        </Box>
        <Grid
          className="shadow text-center"
          container
          justifyContent="space-between"
          sx={{ px: 2, py: 7 }}
        >
          {inFocusCountTabs.map((tab) => (
            <InFocusCountComponent
              redirectUrl={redirectUrl}
              accountName={accountName}
              accountID={accountID}
              tabUrl={tab.url}
              label={tab.label}
              inFocusCount={inFocusCount}
              value={tab.value}
            />
          ))}
        </Grid>
      </div>
    </Grid>
  </Grid>
);

export default InFocusCountSection;
