/* eslint-disable default-param-last */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import HeaderTabs from 'common/HeaderTabs';
import ConfirmationModal from 'common/ConfirmationModal';
import PageNotFound from 'common/PageNotFound';
import * as Utilities from 'utilities/utils';
import TooltipItem from 'common/TooltipItem';
import Pagination from 'common/Pagination';
import KarbonaAccountContactCard from 'common/KarbonaAccountContactCard';
import Axios from 'axios';
import {
  LISCIO_KARBON_ACCOUNTS,
  LISCIO_KARBON_CONTACTS,
  DELINK,
  DELINK_CONTACT,
  CREATE_IN_KARBON,
  CREATE_IN_KARBON_CONTACT,
  CREATE_IN_LISCIO,
  CREATE_IN_LISCIO_CONTACT,
  ACCOUNT_REPORTS,
} from 'constants/constants';
import {
  AlertMessage,
  getCpaName,
  generatingAccountsCsvFile,
} from 'utilities/utils';
import { useNavigate } from 'react-router-dom-latest';
import { SearchLiscioKarbonRefactor } from './SearchLiscioKarbonRefactor';

const KarbonManualSyncTabs = [
  {
    index: 0,
    label: 'Account',
    value: 'liscio_karbon_accounts',
  },
  {
    index: 1,
    label: 'Contact',
    value: 'liscio_karbon_contacts',
  },
];

const ManualSyncAccountHeaders = [
  {
    value: 'liscio_account_name',
    label: 'Liscio Account',
    className: ' col thCol',
    isSortable: true,
  },
  {
    value: 'karbon_org_name',
    label: 'Karbonhq Account',
    className: ' col thCol',
    isSortable: true,
  },
];

const ManualSyncContactHeaders = [
  {
    value: 'liscio_contact_first_name',
    label: 'Liscio Contact',
    className: ' col thCol',
    isSortable: true,
  },
  {
    value: 'karbon_contact_first_name',
    label: 'Karbonhq Contact',
    className: ' col thCol',
    isSortable: true,
  },
];

const initialData = {
  activeTab: 0,
  sortBy: 'liscio_account_name',
  sortOrder: 'asc',
  accountListData: [],
  contactListData: [],
  pagination: 1,
  totalPages: 1,
  totalRecords: 0,
  offset: [0, 15],
  showSearchModal: false,
  searchedFor: {},
  searchedIn: '',
  loading: false,
  createRecordModal: false,
  createRecordFor: {},
  createRecordIn: '',
  isEmployee: Utilities.isUserEmployee(),
  accountContactCardID: null,
  isAutoSuggest: false,
};

let typingTime = null;

/**
 * Small refactor of karbon manual sync accounts/contacts to
 * integrate into the new integration module pattern as subpaths
 */
export const KarbonManualSyncRefactor = ({
  manualSyncTab = 'liscio_karbon_accounts',
}) => {
  // Hacky workaround to get this to work without being highly coupled to its parent
  const props = {
    data: {},
  };
  const [data, setData] = useState({
    ...initialData,
    activeTab: manualSyncTab === 'liscio_karbon_contacts' ? 1 : 0,
  });
  const [searchField, setSearchField] = useState('');
  const navigate = useNavigate();

  const karbonAccountst = async (
    tab,
    page,
    sortedorder,
    sortedby,
    falseSearchModal = '',
    falseCreateRecordModal = '',
    keyword = '',
    autoSuggest = false,
  ) => {
    await setData({ ...data, loading: true });
    setSearchField(keyword);
    try {
      const url = tab === 0 ? LISCIO_KARBON_ACCOUNTS : LISCIO_KARBON_CONTACTS;
      const list = tab === 0 ? 'accountListData' : 'contactListData';
      const response = await Axios.get(
        `${url}?page=${page}&sort_by=${sortedorder}&field=${sortedby}${
          keyword ? `&keyword=${keyword}` : ''
        }${autoSuggest ? `&auto_suggest=${autoSuggest}` : ''}`,
      ).then((res) => res.data);
      setData({
        ...data,
        loading: false,
        activeTab: tab,
        pagination: page,
        sortOrder: sortedorder,
        sortBy: sortedby,
        isAutoSuggest: autoSuggest,
        [list]:
          tab === 0
            ? response.liscio_karbon_accounts
            : response.liscio_karbon_contacts,
        offset: [response.response_hash.offset, response.response_hash.off],
        totalRecords: response.response_hash.total_records,
        totalPages: Number(
          Math.ceil(response.response_hash.total_records / 15),
        ),
        showSearchModal: falseSearchModal ? false : data.falseSearchModal,
        searchedFor: falseSearchModal ? {} : data.searchedFor,
        searchedIn: falseSearchModal ? '' : data.searchedIn,
        createRecordModal: falseCreateRecordModal
          ? false
          : data.createRecordModal,
        createRecordFor: falseCreateRecordModal ? {} : data.createRecordFor,
        createRecordIn: falseCreateRecordModal ? '' : data.createRecordIn,
      });
    } catch (err) {
      setData({ ...data, loading: false });
      console.log(err);
    }
  };

  const changeActiveTab = (key, each) => {
    if (each.value === 'liscio_karbon_contacts') {
      navigate('/integrations/karbon/contacts');
    } else {
      navigate('/integrations/karbon/accounts');
    }
  };

  useEffect(() => {
    if (
      // TODO: __HOMEDATA-AUDIT
      Utilities.getStorageData() &&
      Utilities.getStorageData().role !== 'firm_admin'
    ) {
      navigate('/');
    } else if (
      Utilities.getStorageData() &&
      Utilities.getStorageData().role === 'firm_admin'
    ) {
      if (
        props.data.syncTab &&
        props.data.syncTab !== null &&
        props.data.syncTab !== undefined
      ) {
        const tabIndex = KarbonManualSyncTabs.findIndex(
          (each) => each.value === props.data.syncTab,
        );
        const tab = {
          value: props.data.syncTab,
        };
        changeActiveTab(tabIndex, tab);
      } else {
        karbonAccountst(
          data.activeTab,
          data.pagination,
          data.sortOrder,
          data.sortBy,
          '',
          '',
          '',
          false,
        );
      }
    }
  }, []);

  const handleSortBy = (sortBy) => {
    let { sortOrder } = data;
    if (data.sortBy === sortBy) {
      if (sortOrder === 'asc') {
        sortOrder = 'desc';
      } else {
        sortOrder = 'asc';
      }
    } else {
      sortOrder = 'asc';
    }
    const page = data.isAutoSuggest ? 1 : data.pagination;
    karbonAccountst(
      data.activeTab,
      page,
      sortOrder,
      sortBy,
      '',
      '',
      searchField,
      false,
    );
  };
  const showSearchModal = (enable = '', item, searchedIn = '') => {
    if (enable) {
      setData({
        ...data,
        showSearchModal: enable,
        searchedFor: item,
        searchedIn,
      });
    } else {
      karbonAccountst(
        data.activeTab,
        data.pagination,
        data.sortOrder,
        data.sortBy,
        'falseSearchModal',
        '',
        searchField,
        data.isAutoSuggest,
      );
    }
  };

  const createRecordModal = (enable = '', item, createRecordIn = '') => {
    setData({
      ...data,
      createRecordModal: enable,
      createRecordFor: item,
      createRecordIn,
    });
  };

  const createRecord = () => {
    setData({ ...data, loading: true });
    try {
      const url =
        data.activeTab === 0
          ? data.createRecordIn === 'Liscio'
            ? CREATE_IN_LISCIO
            : CREATE_IN_KARBON
          : data.createRecordIn === 'Liscio'
          ? CREATE_IN_LISCIO_CONTACT
          : CREATE_IN_KARBON_CONTACT;
      const request = Axios.post(`${url}?id=${data.createRecordFor.id}`);
      request.then((response) => {
        if (response.data.status === 200) {
          Utilities.AlertMessage(
            'success',
            typeof response.data.message !== 'object'
              ? response.data.message
              : response.data.message.error
              ? response.data.message.error.message
              : response.data.message[0],
            3000,
          );
          karbonAccountst(
            data.activeTab,
            data.pagination,
            data.sortOrder,
            data.sortBy,
            '',
            'falseCreateRecordModal',
            searchField,
            data.isAutoSuggest,
          );
        } else {
          setData({ ...data, loading: false });
        }
      });
    } catch (err) {
      setData({ ...data, loading: false });
    }
  };

  const handlePageChange = (input) => {
    karbonAccountst(
      data.activeTab,
      input,
      data.sortOrder,
      data.sortBy,
      '',
      '',
      searchField,
      data.isAutoSuggest,
    );
  };

  const handleSearch = (e) => {
    const input = e.target.value;
    setSearchField(input);
    clearTimeout(typingTime);
    typingTime = setTimeout(() => {
      const sortby =
        data.activeTab === 0
          ? 'liscio_account_name'
          : 'liscio_contact_first_name';
      karbonAccountst(
        data.activeTab,
        1,
        'asc',
        sortby,
        '',
        '',
        input,
        data.isAutoSuggest,
      );
    }, 500);
  };
  const handleAutoSuggest = (e) => {
    const value = e.target.checked;
    const sortby =
      data.activeTab === 0
        ? 'liscio_account_name'
        : 'liscio_contact_first_name';
    const sortordr = value ? 'desc' : 'asc';
    karbonAccountst(
      data.activeTab,
      1,
      sortordr,
      sortby,
      '',
      '',
      searchField,
      value,
    );
  };

  const handleDelink = (item) => {
    setData({ ...data, loading: true });
    try {
      const url = data.activeTab === 0 ? DELINK : DELINK_CONTACT;
      const request = Axios.post(`${url}?id=${item.id}`);
      request.then((response) => {
        if (response.data.status === 200) {
          karbonAccountst(
            data.activeTab,
            data.pagination,
            data.sortOrder,
            data.sortBy,
            '',
            '',
            searchField,
            data.isAutoSuggest,
          );
        } else {
          setData({ ...data, loading: false });
        }
      });
    } catch (err) {
      setData({ ...data, loading: false });
      // console.log(err);
    }
  };

  const accountContactCardHandler = async (input, fro = '') => {
    if (data.isEmployee) {
      await Promise.all([
        setData({
          ...data,
          accountContactCardID: null,
          accountContactCardFor: null,
        }),
      ]);
      if (input && fro) {
        setData({
          ...data,
          accountContactCardID: input,
          accountContactCardFor: fro,
        });
      }
    }
  };

  const getContactName = (item, firstFieldName, lastFieldName) => {
    let fullName = '';
    if (item[firstFieldName] && item[lastFieldName]) {
      fullName = `${item[firstFieldName]} ${item[lastFieldName]}`;
    } else if (item[firstFieldName] || item[lastFieldName]) {
      fullName = item[firstFieldName] || item[lastFieldName];
    } else {
      fullName = '-';
    }
    return fullName;
  };

  const getAccountFullAddress = (item, fro = '') => {
    let fullAddress = '';
    const addessLine1 =
      fro === 'Liscio'
        ? 'liscio_account_address_line1'
        : 'karbon_account_address_line1';
    const addessLine2 =
      fro === 'Liscio'
        ? 'liscio_account_address_line2'
        : 'karbon_account_address_line2';
    const city =
      fro === 'Liscio'
        ? 'liscio_account_address_city'
        : 'karbon_account_address_city';
    const state =
      fro === 'Liscio'
        ? 'liscio_account_address_state'
        : 'karbon_account_address_state';
    const zip =
      fro === 'Liscio'
        ? 'liscio_account_address_zip'
        : 'karbon_account_address_zip';
    const country =
      fro === 'Liscio'
        ? 'liscio_account_address_country'
        : 'karbon_account_address_country';
    if (item[addessLine1]) {
      fullAddress += item[addessLine1];
    }
    if (item[addessLine2]) {
      fullAddress += `, ${item[addessLine2]}`;
    }
    if (item[city]) {
      fullAddress += `, ${item[city]}`;
    }
    if (item[state]) {
      fullAddress += `, ${item[state]}`;
    }
    if (item[zip]) {
      fullAddress += `, ${item[zip]}`;
    }
    if (item[country]) {
      fullAddress += `, ${item[country]}`;
    }
    return fullAddress || '-';
  };

  const generateCsvFile = async () => {
    const cpaName = getCpaName();
    try {
      const url = `${ACCOUNT_REPORTS}?${
        data.activeTab === 0
          ? 'for_karbon_accounts=true'
          : 'for_karbon_contacts=true'
      }`;
      const reports = await Axios.get(url).then((res) => res.data.data);
      if (reports && reports.length > 0) {
        generatingAccountsCsvFile(
          reports,
          cpaName +
            (data.activeTab === 0
              ? '_liscio_karbon_accounts.csv'
              : '_liscio_karbon_contacts.csv'),
        );
      }
    } catch (err) {
      AlertMessage('error', 'Something went wrong', 3000);
    }
  };

  const header =
    data.activeTab === 0 ? ManualSyncAccountHeaders : ManualSyncContactHeaders;
  const listData =
    data.activeTab === 0 ? data.accountListData : data.contactListData;
  const liscioAccountContact =
    data.activeTab === 0 ? 'liscio_account_name' : 'liscio_contact_first_name';
  const karbonAccountContact =
    data.activeTab === 0 ? 'karbon_org_name' : 'karbon_contact_first_name';
  return (
    <div id="content">
      {data.loading ? <div id="loading" /> : null}
      <div className="center-wrapper py-0">
        <HeaderTabs
          data={KarbonManualSyncTabs}
          activeTab={data.activeTab}
          handleClick={changeActiveTab}
        />
        <div className="noShadow border-bottom-0 pt-4 mt-2">
          <div className="filter-wrapper d-flex justify-content-between align-items-center pb-0 mb-0">
            <div className="filterPart d-flex align-items-center">
              <div className="form-group has-search">
                <span className="icon-zoom2 form-control-feedback" />
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search in Liscio & Karbonhq"
                  id="liscio-karbon-search"
                  value={searchField}
                  onChange={handleSearch}
                  autoComplete="off"
                />
              </div>
              <div className="checkbox checkbox-primary check-container">
                <label htmlFor="selectallcheckbox">
                  <input
                    name="selectallcheckbox"
                    id="selectallcheckbox"
                    type="checkbox"
                    className="form-check-input"
                    checked={data.isAutoSuggest || false}
                    onChange={handleAutoSuggest}
                  />
                  <i className="checkmark" />
                  <span className="text-body pl-2">Auto-Suggest</span>
                </label>
              </div>
            </div>
            <div className="FilterHeader--Action align-self-start pt-1">
              <button
                type="button"
                className="btn btn-sm btn-outline-light mr-2"
                onClick={generateCsvFile}
              >
                <i className="icon-download2 p-0" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="center-wrapper">
        <div className="tableWrap">
          <div className="tRow tRow--head">
            <div className="row">
              {header.map((each) => (
                <div
                  key={each.value}
                  className={
                    Utilities.sortingIcon(
                      data.sortBy,
                      each.value,
                      data.sortOrder,
                      'className',
                    ) +
                    each.className +
                    (!each.isSortable ? ' cursor-default' : '')
                  }
                  onClick={() =>
                    each.isSortable ? handleSortBy(each.value) : null
                  }
                >
                  <span className="posRel">
                    {each.label}
                    <i
                      className={
                        data.isAutoSuggest
                          ? ''
                          : Utilities.sortingIcon(
                              data.sortBy,
                              each.value,
                              data.sortOrder,
                            )
                      }
                    />
                  </span>
                </div>
              ))}
              <div className="col thCol" />
            </div>
          </div>
          <div className="tRow tRow--body">
            {listData.length ? (
              listData.map((item) => (
                <div className="tdBtn row" key={listData.indexOf(item)}>
                  <div className=" col tdCol">
                    {data.activeTab === 0 ? (
                      <a
                        data-tip
                        data-for={`${item.account_id}global_account`}
                        onMouseEnter={() =>
                          accountContactCardHandler(item, 'Liscio')
                        }
                      >
                        <span>
                          {item[liscioAccountContact]
                            ? item[liscioAccountContact]
                            : '-'}
                        </span>
                      </a>
                    ) : (
                      <a
                        data-tip
                        data-for={`${item.contact_id}global_account`}
                        onMouseEnter={() =>
                          accountContactCardHandler(item, 'Liscio')
                        }
                      >
                        <span>
                          {getContactName(
                            item,
                            'liscio_contact_first_name',
                            'liscio_contact_last_name',
                          )}
                        </span>
                      </a>
                    )}
                  </div>
                  <div className=" col tdCol">
                    {data.activeTab === 0 ? (
                      <a
                        data-tip
                        data-for={`${item.karbon_org_key}global_account`}
                        onMouseEnter={() =>
                          accountContactCardHandler(item, 'Karbon')
                        }
                      >
                        <span>
                          {item[karbonAccountContact]
                            ? item[karbonAccountContact]
                            : '-'}
                        </span>
                      </a>
                    ) : (
                      <a
                        data-tip
                        data-for={`${item.karbon_contact_key}global_account`}
                        onMouseEnter={() =>
                          accountContactCardHandler(item, 'Karbon')
                        }
                      >
                        <span>
                          {getContactName(
                            item,
                            'karbon_contact_first_name',
                            'karbon_contact_last_name',
                          )}
                        </span>
                      </a>
                    )}
                  </div>
                  <div className="col tdCol text-right">
                    <span>
                      {item[liscioAccountContact] &&
                      item[karbonAccountContact] ? (
                        <TooltipItem
                          position="top"
                          tooltipType="button"
                          key={`delink${listData.indexOf(item)}`}
                          text="Delink"
                          btnClass="btn-sm btn--onlyicon btn-link tdBtn__hover px-1"
                          id={`delink${listData.indexOf(item)}`}
                          clickAction={() => handleDelink(item)}
                          iconn="icon-unlink"
                        />
                      ) : !item[karbonAccountContact] ? (
                        <div>
                          <TooltipItem
                            position="top"
                            tooltipType="button"
                            key={`create${listData.indexOf(item)}`}
                            text="Create in Karbon"
                            btnClass="btn-sm btn--onlyicon btn-link tdBtn__hover px-1"
                            id={`create${listData.indexOf(item)}`}
                            clickAction={() =>
                              createRecordModal(true, item, 'Karbon')
                            }
                            iconn="icon-Create-karbanhq"
                          />
                          <TooltipItem
                            position="top"
                            tooltipType="button"
                            key={`search${listData.indexOf(item)}`}
                            text="Search in Karbon"
                            btnClass="btn-sm btn--onlyicon btn-link tdBtn__hover px-1"
                            id={`search${listData.indexOf(item)}`}
                            clickAction={() =>
                              showSearchModal(true, item, 'Karbon')
                            }
                            iconn="icon-zoom2"
                          />
                        </div>
                      ) : !item[liscioAccountContact] ? (
                        <div>
                          <TooltipItem
                            position="top"
                            tooltipType="button"
                            key={`create${listData.indexOf(item)}`}
                            text="Create in Liscio"
                            btnClass="btn-sm btn--onlyicon btn-link tdBtn__hover px-1"
                            id={`create${listData.indexOf(item)}`}
                            clickAction={() =>
                              createRecordModal(true, item, 'Liscio')
                            }
                            iconn="icon-Create-liscio"
                          />
                          <TooltipItem
                            position="top"
                            tooltipType="button"
                            key={`search${listData.indexOf(item)}`}
                            text="Search in Liscio"
                            btnClass="btn-sm btn--onlyicon btn-link tdBtn__hover px-1"
                            id={`search${listData.indexOf(item)}`}
                            clickAction={() =>
                              showSearchModal(true, item, 'Liscio')
                            }
                            iconn="icon-zoom2"
                          />
                        </div>
                      ) : null}
                    </span>
                  </div>
                </div>
              ))
            ) : (
              <div id="content">
                <div className="center-wrapper">
                  <PageNotFound
                    name="message"
                    message="There are no records to show you right now."
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <Pagination
          currentPage={data.pagination}
          totalPages={data.totalPages}
          totalRecords={data.totalRecords}
          handlePageChange={handlePageChange}
          offset={data.offset[0]}
          off={data.offset[1]}
        />
        {data.showSearchModal ? (
          <SearchLiscioKarbonRefactor
            data={data}
            showSearchModal={showSearchModal}
            getAccountFullAddress={getAccountFullAddress}
          />
        ) : null}
        <ConfirmationModal
          isOpen={data.createRecordModal}
          noBtnClick={() => createRecordModal(false, {}, '')}
          yesBtnClick={() => createRecord()}
          headerText={`Are you sure want to create the same record in ${data.createRecordIn}?`}
          yesBtnText={`Create in ${data.createRecordIn}`}
          noBtnText="Cancel"
        />
        {data.accountContactCardID && data.accountContactCardID !== null ? (
          <KarbonaAccountContactCard
            data={data}
            id={data.accountContactCardID}
            getContactName={getContactName}
            getAccountFullAddress={getAccountFullAddress}
          />
        ) : null}
      </div>
    </div>
  );
};
