import { Delete, MoreVert } from '@mui/icons-material';
import {
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  IconButton,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { workflowRequestsCountQueryDocument } from 'api/workflow-requests';
import { workflowsQueryDocument } from 'api/workflows';
import { TOAST_FAILURE, TOAST_SUCCESS } from 'constants/constants';
import { WorkflowRequestStatus, WorkflowStatus } from 'gql/graphql';
import { useUpdateWorkflow } from 'hooks/workflow-hooks';
import { useUpdateWorklowRequest } from 'hooks/workflow-request-hooks';
import { useState } from 'react';
import { Spinner } from 'reactstrap';
import { AlertMessage } from 'utilities/utils';

export type RequestType = 'request' | 'workflow';

interface DraftRequestOptionsMenuProps {
  id: string;
  type: RequestType;
}

export const DraftRequestOptionsMenu = ({
  id,
  type,
}: DraftRequestOptionsMenuProps) => {
  const [workflowOptionsOpen, setWorkflowOptionsOpen] =
    useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const { mutate: updateWorkflowStatusMutation } = useUpdateWorkflow();
  const { mutate: updateWorkflowRequestMutation } = useUpdateWorklowRequest();
  const [loading, setLoading] = useState<boolean>(false);

  const queryClient = useQueryClient();

  const onError = () => {
    AlertMessage(TOAST_FAILURE, 'Failed to delete draft');
  };

  const onSuccess = () => {
    AlertMessage(TOAST_SUCCESS, 'Draft deleted successfully');
    queryClient.invalidateQueries(
      (workflowsQueryDocument.definitions[0] as any).name.value,
    );
    queryClient.invalidateQueries(
      (workflowRequestsCountQueryDocument.definitions[0] as any).name.value,
    );
  };

  const onSettled = () => {
    setLoading(false);
  };

  const handleDelete = (event: React.MouseEvent<Element>) => {
    setLoading(true);
    event.stopPropagation();
    if (type === 'workflow') {
      updateWorkflowStatusMutation(
        {
          status: WorkflowStatus.Deleted,
          workflowId: id,
        },
        {
          onError,
          onSuccess,
          onSettled,
        },
      );
    }
    if (type === 'request') {
      updateWorkflowRequestMutation(
        {
          status: WorkflowRequestStatus.Archived,
          workflowRequestId: id,
        },
        {
          onError,
          onSuccess,
          onSettled,
        },
      );
    }
    setWorkflowOptionsOpen(false);
  };

  const handleClick = (event: React.MouseEvent<Element>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setWorkflowOptionsOpen(true);
  };

  const handleClose = (event: React.MouseEvent<Element>) => {
    event?.stopPropagation();
    setAnchorEl(null);
    setWorkflowOptionsOpen(false);
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        {loading ? <Spinner /> : <MoreVert />}
      </IconButton>
      {workflowOptionsOpen && (
        <Menu
          open={workflowOptionsOpen}
          anchorEl={anchorEl}
          onClose={handleClose}
        >
          <MenuItem onClick={handleDelete}>
            <ListItemIcon>
              <Delete />
            </ListItemIcon>
            <ListItemText>Delete</ListItemText>
          </MenuItem>
        </Menu>
      )}
    </>
  );
};
