/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-unused-class-component-methods */
/* eslint-disable no-script-url */
/* eslint-disable react/jsx-no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-unused-class-component-methods */
/* eslint-disable global-require */
import React, { Component } from 'react';
import axios from 'axios';
import { detect } from 'detect-browser';
import { Modal, ModalBody, Alert } from 'reactstrap';
import { randomString, createCookie, getSiteHostname } from 'utilities/utils';
import './login.scss';
import {
  CHECK_IF_VALID_URL,
  BACKUP_CODE,
  GOOGLE_CLIENT_ID,
  GOOGLE_OAUTH_REDIRECT_URL,
  MICROSOFT_CLIENT_ID,
  MICROSOFT_OAUTH_REDIRECT_URL,
  SIGN_IN_MFA,
  SEND_MAGIC_LINK,
  FORGOT_PASSWORD_API_PATH,
  LOGIN_WITH_CODE,
} from 'constants/constants.js';
import * as LoginApi from 'api/Login';
import { EMAIL } from 'utilities/regularexpressions';
import liscioLogo from 'images/liscioLogo.png';
import gLogo from 'images/gLogo.png';
import ofcLogo from 'images/ofcLogo.png';
import chrome_logo from 'images/chrome_logo.png';
import mozilla_logo from 'images/mozilla_logo.png';
import safari_logo from 'images/safari_logo.png';
import Interweave from 'interweave';
import {
  getAuthSession,
  setAuthSession,
  // setLegacyRootDomainAuthSession,
} from 'auth/session';
import { AuthenticatedRedirect } from 'components/AuthenticatedRedirect/AuthenticatedRedirect';
import { Link } from '@mui/material';

const SCOPES = ['openid', 'profile', 'email', 'People.Read', 'User.read'];

const browser = detect();
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      heading: 'Sign In',
      component: 'login',
      redirectTo: '',
      confirm: false,
      email: '',
      password: '',
      passcode: '',
      remember_me: false,
      confirmMessage: '',
      errors: {},
      showModal: false,
      showMobileModal: false,
      invalidUrl: true,
      loginWithGoogle: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.Cancel = this.Cancel.bind(this);
  }

  // eslint-disable-next-line consistent-return
  UNSAFE_componentWillMount() {
    document.title = 'Login';
    const hostname = getSiteHostname();
    const parsedUrl = new URL(window.location.href);
    const outlookCode = parsedUrl.searchParams.get('outlookcode');
    const googleCode = parsedUrl.searchParams.get('googlecode');
    const switchCpaAuthToken = parsedUrl.searchParams.get('switch_cpa_token');
    const sessionTimeout = parsedUrl.searchParams.get('session_timeout');
    if (
      (browser.name === 'ie' && browser.version < '11') ||
      (browser.name === 'edge' && browser.version < '17')
    ) {
      this.setState({ showModal: true });
    } else if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      this.setState({ showMobileModal: true });
    }
    window.state = this.state;
    document.body.classList.remove('loading-screen');
    document.body.classList.add('login-bg');
    const { state } = this.props.location;
    if (state) {
      localStorage.setItem('path', this.props.location.state.pathname);
      localStorage.setItem(
        'search',
        encodeURI(this.props.location.state.search),
      );
      if (state.component && state.remoteData) {
        this.setState({
          component: state.component,
          heading: 'Enter Passcode',
          email: state.remoteData.email,
          invalidUrl: false,
        });
        return false;
      }
      if (switchCpaAuthToken) {
        setAuthSession({ auth_token: switchCpaAuthToken });
      }
    }

    axios
      .post(CHECK_IF_VALID_URL, { company_name: hostname })
      .then((res) => {
        if (res.data.success === false) {
          this.props.navigate('/glitch');
        } else {
          this.setState({ invalidUrl: false });
        }
      })
      .catch((error) => {
        if (error.message !== 'Network Error') {
          this.props.navigate('/glitch');
        }
      });
    if ((outlookCode || googleCode) && hostname) {
      let code = '';
      let loginType = '';
      if (outlookCode) {
        code = decodeURIComponent(outlookCode);
        loginType = 'outlook';
      } else if (googleCode) {
        code = decodeURIComponent(googleCode);
        loginType = 'google';
      }
      this.sendLoginWithCode(code, loginType, hostname);
    }
    if (sessionTimeout) {
      const { errors: err } = this.state;
      err.fail =
        "<div style='padding: 5px 10px; text-align: left;'><h5 style='font-size: 16px; font-weight: bold; margin-bottom: 5px;'>Your session has timed out.</h5><p style='margin-bottom: 0;'>Due to inactivity, you have been logged out. Please sign in to resume.</p></div>";
      this.setState({ errors: err });
    }
  }

  componentDidMount() {
    if (getAuthSession()) {
      setTimeout(() => {
        this.props.navigate('/');
      }, 100);
    }
    const { userpilot } = window;
    if (userpilot) {
      userpilot.reload();
    }
  }

  componentWillUnmount() {
    document.body.classList.add('loading-screen');
    document.body.classList.remove('login-bg');
    window.addEventListener('keypress', () => true);
  }

  handleCredentialsChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      errors: {},
    });
  };

  handleValidation(values) {
    const err = {};
    const { component } = this.state;
    const { location } = this.props;
    if (!values.email && !values.password && component === 'login') {
      err.mail = 'Email is required';
      err.password = 'Password is required';
      this.setState({ errors: err });
      return false;
    }
    if (!values.passcode && component === 'passCode') {
      err.mail = 'Enter passcode';
      this.setState({ errors: err });
      return false;
    }
    if (!location.state || !location.state.component) {
      // if component redirects from remote login component
      if (!values.email) {
        err.mail = 'Email is required';
        this.setState({ errors: err });
        return false;
      }
      if (!values.password && this.state.component === 'login') {
        err.password = 'Password is required';
        this.setState({ errors: err });
        return false;
      }
    }
    if (values.email && !EMAIL.test(values.email)) {
      err.mail = 'Invalid Email';
      this.setState({ errors: err });
      return false;
    }

    return true;
  }

  handleSubmit(e) {
    if (e) {
      e.preventDefault();
    }
    const { email, password, passcode, component } = this.state;
    const { state } = this.props.location;
    if (!this.handleValidation({ email, password, passcode })) {
      return false;
    }

    const currentLocation = getSiteHostname();
    if (component === 'login' || component === 'passCode') {
      const data = {
        user: {
          email,
          password,
          company_name: currentLocation,
          time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      };
      if (component === 'passCode') {
        data.user.passcode = this.state.passcode;
        // condition if users come from remote login
        if (state && state.component && state.remoteData) {
          const newData = state.remoteData;
          newData.passcode = this.state.passcode;
          LoginApi.remoteMfaLogin(this, newData);
          return false;
        }
      }
      LoginApi.Authentication(this, data, this.state.remember_me, SIGN_IN_MFA);
    }
    if (
      this.state.component === 'forgotPassword' ||
      this.state.component === 'magicLink'
    ) {
      const data = { email, password, company_name: currentLocation };
      let apiName = null;
      if (this.state.component === 'forgotPassword') {
        apiName = FORGOT_PASSWORD_API_PATH;
      } else if (this.state.component === 'magicLink') {
        apiName = SEND_MAGIC_LINK;
      }
      LoginApi.LoginApis(this, data, apiName);
    }
    return true;
  }

  submitLink(index) {
    if (index === 'forgotPassword') {
      this.setState({ heading: 'Forgot your password?' });
    }
    if (index === 'magicLink') {
      this.setState({ heading: 'Send Magic Link' });
    }
    this.setState({ component: index });
    this.setState({ errors: {} });
  }

  Cancel() {
    const { state } = this.props.location;
    this.setState(
      {
        heading: 'Sign In',
        component: 'login',
        email: '',
        password: '',
        passcode: '',
        errors: {},
        confirm: false,
      },
      () => {
        if (state && state.component && state.remoteData) {
          this.props.navigate('/');
        }
        const parsedUrl = new URL(window.location.href);
        const outlookCode = parsedUrl.searchParams.get('outlookcode');
        const googleCode = parsedUrl.searchParams.get('googlecode');
        if (outlookCode || googleCode) {
          this.props.navigate('/login');
        }
      },
    );
  }

  clickOk = () => {
    this.setState({ confirm: false, heading: 'Sign In', component: 'login' });
  };

  clickHereLink = () => {
    const component = this;
    const err = this.state.errors;
    component.setState({ loading: true });
    const request = axios.post(BACKUP_CODE, { email: this.state.email });
    component.setState({ loading: false });
    request
      .then((response) => {
        if (response.data.status === 200) {
          component.setState({ clickHere: true });
        } else {
          err.fail = response.data.message;
          component.setState({ errors: err });
        }
      })
      .catch(() => {
        err.fail = 'Some error occured';
        component.setState({ loading: false, errors: err });
      });
  };

  needHelpClick = () => {
    this.setState({
      confirm: true,
      needHelp: true,
      errors: {},
      passcode: '',
    });
  };

  handleEyeIcon(type) {
    const inputType = this.state[type];
    this.setState({ [type]: inputType === 'text' ? 'password' : 'text' });
  }

  outlookLogin = () => {
    const clientId = MICROSOFT_CLIENT_ID;
    let redirectUri = MICROSOFT_OAUTH_REDIRECT_URL;
    if (redirectUri && window.location.hostname.includes('localhost')) {
      redirectUri = 'https://localhost:3000/outlook';
    }
    const auth_url =
      'https://login.microsoftonline.com/common/oauth2/v2.0/authorize?' +
      `client_id=${clientId}&response_type=code` +
      `&redirect_uri=${redirectUri}&response_mode=query` +
      `&scope=${SCOPES.join(' ')}&state=`;
    const cookieName = randomString(16);
    createCookie(cookieName, window.location.href, 30);
    const state = cookieName;
    window.location.href = auth_url + state;
  };

  googleLogin = () => {
    const clientId = GOOGLE_CLIENT_ID;
    let redirectUri = GOOGLE_OAUTH_REDIRECT_URL;
    if (redirectUri && window.location.hostname.includes('localhost')) {
      redirectUri = 'https://localhost:3000/google';
    }
    const auth_url = `https://accounts.google.com/o/oauth2/v2/auth?scope=https://www.googleapis.com/auth/userinfo.email&response_type=code&redirect_uri=${redirectUri}&client_id=${clientId}&state=`;
    const cookieName = randomString(16);
    createCookie(cookieName, window.location.href, 30);
    const state = cookieName;
    window.location.href = auth_url + state;
  };

  sendLoginWithCode = (code, type, company) => {
    const companyName =
      company === 'appdev.liscio.me' ||
      company === 'appuat.liscio.me' ||
      company === 'app.liscio.me'
        ? ''
        : company;
    const component = this;
    const data = {
      user: {
        company_name: companyName,
        code,
        login_type: type,
      },
    };
    LoginApi.Authentication(
      component,
      data,
      component.state.remember_me,
      LOGIN_WITH_CODE,
    );
  };

  render() {
    const { errors, component, needHelp, clickHere } = this.state;
    const noneStyle = { display: 'none' };
    const blockStyle = { display: 'block' };
    return (
      <AuthenticatedRedirect>
        <div id="page-wrapper">
          <div
            id="loading"
            style={this.state.loading ? blockStyle : noneStyle}
          />
          {!this.state.invalidUrl ? (
            <div className="crPage">
              <div className="crPage--inner">
                <div className="crPage--innerForm">
                  <div className="crPage--innerForm--sec">
                    <div className="crPage--head text-center">
                      <img
                        src={liscioLogo}
                        className="homeLogo"
                        alt="Lisico"
                        data-testid="home_logo"
                      />
                      {!this.state.confirm ? (
                        <h1>{this.state.heading}</h1>
                      ) : null}
                      {!this.state.confirm && component === 'passCode' ? (
                        <p className="text-center mb-3">
                          Enter the code on your mobile passcode <br />{' '}
                          application
                        </p>
                      ) : null}
                      {errors ? (
                        errors.success ? (
                          <Alert color="primary">{errors.success}</Alert>
                        ) : errors.fail ? (
                          <Interweave
                            className="login-error-danger"
                            content={errors.fail}
                          />
                        ) : null
                      ) : null}
                    </div>
                    {!this.state.confirm ? (
                      <div className="login-bx mt-3">
                        <form
                          className="login_form"
                          autoComplete="off"
                          onSubmit={this.handleSubmit}
                        >
                          <div
                            className={
                              errors && errors.mail
                                ? 'form-group form-error'
                                : 'form-group'
                            }
                          >
                            {component === 'passCode' ? (
                              <input
                                id="passcode"
                                name="passcode"
                                type="text"
                                className="form-control"
                                autoComplete="new-password"
                                placeholder="Enter Passcode"
                                value={
                                  this.state.passcode ? this.state.passcode : ''
                                }
                                onChange={this.handleCredentialsChange}
                              />
                            ) : (
                              <input
                                name="email"
                                component="input"
                                type="email"
                                value={this.state.email}
                                className="form-control"
                                placeholder="Email"
                                autoComplete="new-password"
                                onChange={this.handleCredentialsChange}
                                data-testid="email"
                              />
                            )}
                            <div className="text-danger error_label" id="mail">
                              {errors && errors.mail ? errors.mail : ''}
                            </div>
                          </div>
                          {component === 'login' ? (
                            <div>
                              <div
                                className={
                                  errors && errors.password
                                    ? 'form-group form-error'
                                    : 'form-group'
                                }
                              >
                                <div className="labelValue inputGroup">
                                  <input
                                    name="password"
                                    component="input"
                                    type={this.state.passwordType || 'password'}
                                    placeholder="Password"
                                    className="form-control"
                                    autoComplete="off"
                                    value={this.state.password}
                                    onChange={this.handleCredentialsChange}
                                    data-testid="password"
                                  />
                                  <span
                                    onClick={() =>
                                      this.handleEyeIcon('passwordType')
                                    }
                                    className="inputHelpIcon"
                                  >
                                    <i
                                      className={`icon-${
                                        this.state.passwordType === 'text'
                                          ? 'open'
                                          : 'close'
                                      }-eye`}
                                      aria-hidden="true"
                                    />
                                  </span>
                                </div>
                                <div
                                  className="text-danger error_label"
                                  id="password"
                                >
                                  {errors && errors.password
                                    ? errors.password
                                    : ''}
                                </div>
                              </div>
                              <div className="form-group">
                                <button
                                  type="submit"
                                  id="loginSubmit"
                                  className="btn btn-primary w-100"
                                  data-testid="sign_in"
                                >
                                  Sign In
                                </button>
                              </div>
                              <div className="clearfix" />
                              <div className="form-group text-center">Or</div>
                              <div className="form-group">
                                {/* <div className="btWithWrap">
                                  <img src={require('images/gLogo.png')} alt="Google" className="hIcon" />
                                  <input type="button" value="LOGIN WITH GOOGLE" className="btn btn-outline-light login-with-google w-100" onClick={this.googleLogin} />
                                </div> */}
                                <button
                                  type="button"
                                  className="cntBtnGgle w-100 p-0 bg-white"
                                  onClick={this.googleLogin}
                                  data-testid="sign_in_with_google"
                                >
                                  <div className="pr-2 pl-2">
                                    <img
                                      src={gLogo}
                                      alt="Google"
                                      className="hIcon"
                                    />
                                  </div>
                                  <div className="csBtnGgle text-center">
                                    Sign in with Google
                                  </div>
                                </button>
                              </div>
                              <div className="form-group">
                                {/* <div className="btWithWrap">
                                  <img src={require('images/ofcLogo.png')} alt="Microsoft" className="hIcon" />
                                  <input type="button" value="LOGIN WITH OFFICE 365" className="btn btn-outline-light outlookLogin w-100" onClick={this.outlookLogin} />
                                </div> */}
                                <button
                                  type="button"
                                  className="cntBtn w-100 p-0 bg-white"
                                  onClick={this.outlookLogin}
                                  data-testid="sign_in_with_office"
                                >
                                  <div className="pr-2 pl-2">
                                    <img
                                      src={ofcLogo}
                                      alt="Microsoft"
                                      className="hIcon"
                                    />
                                  </div>
                                  <div className="csBtn text-center">
                                    Sign in with Office 365
                                  </div>
                                </button>
                              </div>
                              <div className="form-group text-left">
                                <div className="d-flex w-100 justify-content-between crPage--bott">
                                  <div className="check-container">
                                    <label htmlFor="user_remember_me">
                                      <input
                                        value="1"
                                        onChange={(e) => {
                                          this.setState({
                                            remember_me: e.target.checked,
                                          });
                                        }}
                                        checked={this.state.remember_me}
                                        name="user[remember_me]"
                                        id="user_remember_me"
                                        type="checkbox"
                                        data-testid="remember_me"
                                      />
                                      <i className="checkmark" />
                                      <span className="pl-2">Remember me</span>
                                    </label>
                                  </div>
                                  <button
                                    type="button"
                                    onClick={() =>
                                      this.submitLink('forgotPassword')
                                    }
                                    className="btn tptBtn"
                                    data-testid="forgot_password"
                                  >
                                    Forgot your password?
                                  </button>
                                </div>
                              </div>
                            </div>
                          ) : (
                            ''
                          )}
                          {component === 'forgotPassword' ||
                          component === 'magicLink' ? (
                            <div className="form-group">
                              <button
                                type="submit"
                                className="btn btn-primary pull-left w-100"
                                onClick={() =>
                                  this.submitLink('forgotPassword')
                                }
                                data-testid="submit"
                              >
                                Submit
                              </button>
                              <div className="d-inline-block w-100 mt-3 mb-3 text-center">
                                <span className="text-light">Or</span>
                              </div>
                              <button
                                type="submit"
                                className="btn send-magic-link btn-outline-light w-100"
                                onClick={() => this.submitLink('magicLink')}
                                data-testid="send_magic_link"
                              >
                                Send Magic Link
                              </button>
                              <div className="crPage--bott text-center mt-3">
                                <span className="txtInfo">Back to </span>
                                <a
                                  href="javascript:;"
                                  onClick={this.Cancel}
                                  className="btn tptBtn"
                                  data-textid="back_to_login"
                                >
                                  <b>Login</b>
                                </a>
                              </div>
                            </div>
                          ) : null}
                          {component === 'passCode' ? (
                            <div className="form-group">
                              <button
                                type="submit"
                                id="passcodeSubmit"
                                name="passcode"
                                className="btn btn-primary w-100"
                                onClick={() => this.submitLink('passCode')}
                                onChange={this.handleCredentialsChange}
                                value={this.state.passcode}
                              >
                                Verify Code
                              </button>
                              <div className="crPage--bott text-center mt-3">
                                <a
                                  href="javascript:;"
                                  onClick={this.needHelpClick}
                                  className="btn tptBtn"
                                >
                                  <b className="text-primary">
                                    Need help logging in?
                                  </b>
                                </a>
                              </div>
                              <div className="crPage--bott text-center mt-3">
                                <span className="txtInfo">Back to </span>
                                <a
                                  href="javascript:;"
                                  onClick={this.Cancel}
                                  className="btn tptBtn"
                                >
                                  <b>Login</b>
                                </a>
                              </div>
                            </div>
                          ) : null}
                        </form>
                      </div>
                    ) : null}
                    {this.state.confirm && !needHelp ? (
                      <div>
                        <br />
                        <br />
                        <div>{this.state.confirmMessage}</div>
                        <br />
                        <div className="crPage--bott text-center mt-3">
                          <span className="txtInfo">Back to </span>
                          <a
                            href="javascript:;"
                            onClick={this.Cancel}
                            className="btn tptBtn"
                          >
                            <b>Login</b>
                          </a>
                        </div>
                      </div>
                    ) : null}
                    {this.state.confirm && needHelp ? (
                      <div>
                        <br />
                        <br />
                        {clickHere ? (
                          <div>
                            Email has been sent to your registered email
                            address.
                          </div>
                        ) : (
                          <div>
                            If you’ve lost access to your account, please{' '}
                            <Link
                              className="px-0"
                              href="https://liscio.me/contact/"
                              target="_blank"
                              rel="noreferrer"
                            >
                              contact us
                            </Link>
                            .
                          </div>
                        )}
                        <br />
                        <br />
                        <div className="form-group">
                          <button
                            type="button"
                            className={`btn send-magic-link ${
                              clickHere ? 'btn-primary' : 'btn-outline-light'
                            } w-100`}
                            onClick={() =>
                              this.setState({
                                confirm: false,
                                component: 'passCode',
                                clickHere: false,
                              })
                            }
                          >
                            {clickHere ? 'OK' : 'DISMISS'}
                          </button>
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="crPage--innerForm--footer">
                    <div className="crPaeg--links">
                      <a
                        className="px-0"
                        href="https://liscio.me/privacy-policy/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Privacy Policy
                      </a>
                      <a
                        className="px-0"
                        href="https://liscio.me/contact/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Contact Us
                      </a>
                    </div>
                    <div className="crPage--copyright">
                      <p>
                        &copy; {new Date().getFullYear()} Lisco. All rights
                        reserved.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          <Modal
            isOpen={this.state.showModal || this.state.showMobileModal}
            className="customModal customModal--center customModal--alert browserpopup customModal--nopadding"
          >
            <div className="ModalHeader">
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={() =>
                  this.setState({ showModal: false, showMobileModal: false })
                }
              >
                <i className="icon-close2" aria-hidden="true" />
              </button>
            </div>
            <ModalBody className="modalContent">
              <div className="modalContent--inner">
                <h1 className="mainHeading text-center">
                  {this.state.showModal
                    ? 'Your Browser is not Supported!!!'
                    : ''}
                </h1>
                <h2 className="text-medium text-center text-dark pb-2">
                  {this.state.showModal
                    ? 'To view this page please use one of these browsers!!!'
                    : 'For better user experience, it is recommended to use one of these browsers on Mac or PC :'}
                </h2>
                <p>&nbsp;</p>
                <div className="row unSupported justify-content-center align-items-center text-center">
                  <div className="col-4">
                    <img alt="chrome" src={chrome_logo} title="Chrome" />
                    <h5>Chrome</h5>
                  </div>
                  <div className="col-4">
                    <img alt="firefox" src={mozilla_logo} title="Firefox" />
                    <h5>Firefox</h5>
                  </div>
                  <div className="col-4">
                    <img alt="safari" src={safari_logo} title="Safari" />
                    <h5>Safari</h5>
                  </div>
                </div>
              </div>
            </ModalBody>
          </Modal>
        </div>
      </AuthenticatedRedirect>
    );
  }
}
export default Login;
