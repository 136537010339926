import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import CustomDatePicker from 'wrappers/DatePickerUtil';
import { WorkflowRequestFragment } from 'gql/graphql';
import dayjs from 'dayjs';
import { FormRow } from './FormRow';

type Props = {
  displayDialog: boolean;
  workflowRequest: WorkflowRequestFragment | null | undefined;
  dueDate: Date;
  handleDateChange: (date: Date) => void;
  toggleDisplay: () => void;
  sendDueDateChange: () => void;
};

export const ChangeDueDateDialog = ({
  displayDialog,
  workflowRequest,
  dueDate,
  handleDateChange,
  toggleDisplay,
  sendDueDateChange,
}: Props) => {
  const NEW_DUE_DATE = 'New Due Date';

  const changeDate = () => {
    sendDueDateChange();
    toggleDisplay();
  };

  const displayAssignees = () => {
    const assignees: string[] = [];
    workflowRequest?.assignedContacts?.forEach((contact) => {
      assignees.push(`${contact.firstName} ${contact.lastName}`);
    });
    return assignees.join(', ');
  };

  return (
    <Dialog open={displayDialog}>
      <DialogTitle>
        <Typography variant="body1">
          Please enter a new due date for this Request
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Stack gap={2}>
          <Stack>
            <FormRow label="Name" value={workflowRequest?.title} />
            <FormRow
              label="Account"
              value={workflowRequest?.assignedAccount?.name}
            />
            <FormRow label="Assignee(s)" value={displayAssignees()} />
            <FormRow
              label="Current Due Date"
              value={dayjs(workflowRequest?.dueDate).format('MM/DD/YYYY')}
            />
          </Stack>
          <Box display="grid" justifyContent="center" marginLeft="-2.5rem">
            <Typography fontWeight="bold">{NEW_DUE_DATE}</Typography>
            <Stack position="relative" paddingBottom="1.5rem">
              <Box position="fixed" zIndex={9999}>
                <CustomDatePicker
                  toolTipLabel={NEW_DUE_DATE}
                  selected={dueDate}
                  onChange={handleDateChange}
                  minDate={new Date()}
                  ariaLabelledBy="send-due-date"
                />
              </Box>
            </Stack>
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={changeDate}>
          Change Date
        </Button>
        <Button variant="contained" color="secondary" onClick={toggleDisplay}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
