import React, { Component } from 'react';
import moment from 'moment';
import Interweave from 'interweave';
import { printPdf, current_logged_user_name } from 'utilities/utils';

import logo from 'images/liscioLogo.png';

class MessagePdfLayout extends Component {
  componentDidMount() {
    const styleElement =
      '<style>body{font-family:"Source Sans Pro",sans-serif;-webkit-font-smoothing:antialiased;color:#212b36;line-height:1.3}#pdf-logo{height:32px}#pdf-logo img{height:32px}p{font-size:15px;margin:0;line-height:18px;color:#212b36}b,strong{font-weight:600}h5{font-size:20px;font-weight:500;margin:0}.text-dark{color:#919eab!important}p b{color:#000}.div-top{margin-top:20px}.due-date{text-align:right;float:right}.left-content{float:left}.div-top b{margin-right:10px;display:inline-block}.Date{float:right;margin-top:18px}hr{border:none;height:1px;background:#d2d2d2;color:#d2d2d2;margin-top:10px}.highlight{color:#299ac4}.bottom{margin:20px 0 0}li::before{content:"•"}.no-padding{padding:0;border:0}.bottomBorder{width:100%;height:1px;border-bottom:1px solid #dfe3e8}.each_thread{border-bottom:1px solid #dfe3e8;padding:.9rem 0;opacity:.9}.each_thread:last-child{border:0}.print-comment-sections{padding:10px 0;break-inside:auto}.print-comment-sections .comment_content{color:#212b36;font-size:15px}.text-right{float:right}.right-panel-top{border-bottom:solid 1px #e8ebf0;margin-bottom:10px}.visibility{visibility:hidden;width:1px;height:1px;position:absolute}.task-action-icons{margin-top:10px}</style>';
    const { data } = this.props;
    printPdf(
      `message_to_print${data.messageThreadId}`,
      `message_iframe${data.messageThreadId}`,
      styleElement,
    );
    setTimeout(() => this.props.handlePrintPdf(), 1000);
  }

  render() {
    const { data } = this.props;
    const documents = [];
    data.threadArray.map((e) => {
      documents.push(...e.documents);
      return e;
    });
    return (
      <div style={{ height: 1, overflow: 'auto' }}>
        <iframe
          title="print terms condition"
          id={`message_iframe${data.messageThreadId}`}
          style={{
            height: '200px',
            width: '100%',
          }}
        />
        <div
          className="pdf-html"
          id={`message_to_print${data.messageThreadId}`}
        >
          <div id="pdf-logo">
            <img src={logo} alt="liscio logo" />
          </div>
          <br />
          <div className="bottomBorder col pt-2" />
          <br />
          <div>
            <h5>
              <span className="text-dark">Message Title</span>
              {`    ${data.threadTitle}`}
            </h5>
          </div>
          {data.threadArray.map((item) => {
            if (item.status && item.status.toLowerCase() === 'draft') {
              return null;
            }
            return (
              <div className="each_thread print-comment-sections" key={item.id}>
                <div>
                  <div>
                    <h5
                      className="messageSender"
                      style={{ fontWeight: 600, fontSize: '18px' }}
                    >
                      {item.sender}
                    </h5>
                  </div>
                  <div style={{ padding: '0 0 10px' }}>
                    <span>
                      <span
                        className="messageRecipient"
                        style={{ fontWeight: 600 }}
                      >
                        {item.status === 'Draft' ? 'Draft to' : ''} Recipients
                      </span>
                      {/* for replacing me with user name */}
                      {`: ${item.is_recipient.join(', ')}`
                        .replace(/: me/g, `: ${current_logged_user_name()} `)
                        .replace(/ me /g, ` ${current_logged_user_name()} `)}
                    </span>
                    <span
                      className="messageDate text-right"
                      style={{ fontSize: '14px' }}
                    >
                      <span className="text-dark">
                        {item.timestamp
                          ? moment
                              .unix(item.timestamp)
                              .format('MM/DD/YYYY, h:mm a')
                          : item.tym}
                      </span>
                    </span>
                  </div>
                </div>
                {item.content &&
                  item.content !== null &&
                  item.status &&
                  item.status.toLowerCase() !== 'draft' && (
                    <div>
                      <p className="messageDesc pt-2">
                        <Interweave
                          allowAttributes
                          content={item.content
                            .replace(/<a\b[^>]*>/g, '')
                            .replace(/<\/a>/g, '')}
                        />
                      </p>
                    </div>
                  )}
              </div>
            );
          })}
          <div>
            {/* <h4 className="left-content"><b>Message Title   </b><span>{data.threadTitle}</span></h4> */}
            <br />
            {documents && documents.length ? (
              <div>
                <p>
                  <b>Attachments:</b>
                </p>
                {documents.map((each) => (
                  <div key={each.id} style={{ marginTop: '8px' }}>
                    <p>
                      <span>{each.file_name}</span>
                    </p>
                  </div>
                ))}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

export default MessagePdfLayout;
