/* eslint-disable react/no-array-index-key */
import React, { useEffect } from 'react';
import { Button } from 'reactstrap';
import { CheckboxField } from 'common/CustomFields';
import { CstTeamFilter } from 'common/MessageFilters';
import AccountFilter from 'components/Filters/AccountFilter';

const getEdocBoxBorderCssClassNames = (input = '') => {
  if (input === 'yellow') {
    return ' alert-warning border-warning';
  }
  if (input === 'red') {
    return ' alert-danger border-danger';
  }
  return ' alert-success border-success';
};

const SearchSection = (props) => {
  const {
    entityTypes,
    searchFieldText,
    selectedEntityTypes,
    selectedData,
    maxCount,
    placeHolder,
    statusTypes,
    selectedStatusTypes,
    generateCsvFile,
    edocBalance,
    edocBalanceBoxColor,
    relationshipTypes,
    selectedRelationshipTypes,
    onlyAccountOwners,
    handleAccountDropdown,
    selectedAccountId,
    selectedAccountName,
    handleCstTeam,
    selectedCsTeam,
    handleFilterInFocus,
    filterInFocus,
    hanldeSearchBtn,
  } = props;

  // on mount of the Bulk Actions sections, trigger an initial search to get all items and prevent a blank screen
  useEffect(hanldeSearchBtn, [hanldeSearchBtn]);

  return (
    <div className="filter-wrapper">
      <div className="flex-wrap">
        {maxCount > 0 ? (
          <div className="d-flex FilterHeader--Action text-right justify-content-end align-items-center mb-3">
            {edocBalance !== undefined && (
              <div
                className={`badge badge-primary subHeadline m-0 mr-2 p-2 font-weight-bold alert
                border${getEdocBoxBorderCssClassNames(edocBalanceBoxColor)}`}
                style={{ lineHeight: 1.465 }}
              >
                <span className="text-body">eDoc Balance:&nbsp;</span>
                <span className="text-body">
                  {edocBalance - selectedData.length}
                </span>
              </div>
            )}
            <div
              className="badge badge-primary subHeadline p-2 font-weight-bold"
              style={{ lineHeight: 1.465 }}
            >
              <span className="text-body">
                {`Select up to ${maxCount} contacts: `}
              </span>
              {` ${selectedData.length}/${maxCount}`}
            </div>
            {generateCsvFile ? (
              <Button
                className="btn btn-sm btn-outline-light ml-2"
                onClick={generateCsvFile}
              >
                <i className="icon-download2 p-0" aria-hidden="true" />
              </Button>
            ) : null}
          </div>
        ) : null}
        <div className="filterGroup row no-gutters">
          <div className="col align-items-center mb-2">
            <div className="d-flex bd-highlight">
              <label htmlFor="filter" className="mt-2 mr-1 m-0">
                Filter by
              </label>
              <div className="dropdown">
                <button
                  type="button"
                  className={
                    selectedEntityTypes.length
                      ? 'btn btn-outline-light btn-sm dropdown-toggle active'
                      : 'btn btn-outline-light btn-sm dropdown-toggle'
                  }
                  data-toggle="dropdown"
                >
                  SELECT ENTITY
                </button>
                <div className="dropdown-menu">
                  {entityTypes.map((item, key) => (
                    <span key={item.key} className="dropdown-item">
                      <div className="checkbox checkbox-primary check-container">
                        <label htmlFor={item.key} className="check-container">
                          <span className="pl-2">{item.label}</span>
                          <input
                            checked={entityTypes[key].checked}
                            onChange={() =>
                              props.handleFilterCheckbox(item, key)
                            }
                            type="checkbox"
                            id={item.key}
                            name={item.key}
                          />
                          <span className="checkmark" />
                        </label>
                      </div>
                    </span>
                  ))}
                </div>
              </div>
              {statusTypes ? (
                <div className="dropdown">
                  <button
                    type="button"
                    className={
                      selectedStatusTypes && selectedStatusTypes.length
                        ? 'btn btn-outline-light btn-sm dropdown-toggle active'
                        : 'btn btn-outline-light btn-sm dropdown-toggle'
                    }
                    data-toggle="dropdown"
                  >
                    SELECT STATUS
                  </button>
                  <div className="dropdown-menu">
                    {statusTypes.map((item, key) => (
                      <span key={item.key} className="dropdown-item">
                        <div className="checkbox checkbox-primary check-container">
                          <label htmlFor={item.key} className="check-container">
                            <span className="pl-2">{item.label}</span>
                            <input
                              checked={statusTypes[key].checked}
                              onChange={() =>
                                props.handleStatusFilter(item, key)
                              }
                              type="checkbox"
                              id={item.key}
                              name={item.key}
                            />
                            <span className="checkmark" />
                          </label>
                        </div>
                      </span>
                    ))}
                  </div>
                </div>
              ) : null}
              {relationshipTypes ? (
                <div className="dropdown">
                  <button
                    type="button"
                    className={
                      selectedRelationshipTypes &&
                      selectedRelationshipTypes.length
                        ? 'btn btn-outline-light btn-sm dropdown-toggle active'
                        : 'btn btn-outline-light btn-sm dropdown-toggle'
                    }
                    data-toggle="dropdown"
                  >
                    SELECT RELATIONSHIP
                  </button>
                  <div className="dropdown-menu">
                    {relationshipTypes.map((item, key) => (
                      <span key={item.key} className="dropdown-item">
                        <div className="checkbox checkbox-primary check-container">
                          <label htmlFor={item.key} className="check-container">
                            <span className="pl-2">{item.label}</span>
                            <input
                              checked={relationshipTypes[key].checked}
                              onChange={() =>
                                props.handleRelationship(item, key)
                              }
                              type="checkbox"
                              id={item.key}
                              name={item.key}
                            />
                            <span className="checkmark" />
                          </label>
                        </div>
                      </span>
                    ))}
                  </div>
                </div>
              ) : null}
              {handleAccountDropdown && (
                <AccountFilter
                  filterSelected={(e, input) => handleAccountDropdown(input)}
                  filterName="ACCOUNT"
                  isActive={selectedAccountName}
                  setFilterInFocus={handleFilterInFocus}
                  showFilterOptions={filterInFocus === 'ACCOUNT'}
                />
              )}
              {handleCstTeam && (
                <CstTeamFilter
                  handleCstTeam={handleCstTeam}
                  selectedCsTeam={selectedCsTeam}
                />
              )}
              {props.handleAccountOwnerCheckbox && (
                <CheckboxField
                  name="user[remember_me]"
                  id="is_account_owner"
                  type="checkbox"
                  label="Account owner?"
                  value={onlyAccountOwners}
                  onChange={props.handleAccountOwnerCheckbox}
                  className="d-flex align-items-center ml-2"
                />
              )}
              <div className="dropdown">
                <div
                  className="form-group has-search mt-0"
                  style={{ maxWidth: '155px' }}
                >
                  <span className="icon-zoom2 form-control-feedback" />
                  <input
                    type="text"
                    className="form-control"
                    placeholder={placeHolder || 'Search'}
                    onChange={props.handleInputField}
                    value={searchFieldText}
                    onKeyPress={(e) => props.searchKeyPress(e)}
                  />
                </div>
              </div>
              <button
                type="button"
                className="btn btn-sm btn-link px-2"
                onClick={props.hanldeSearchBtn}
              >
                Search
              </button>
              {(selectedEntityTypes.length ||
                (selectedStatusTypes && selectedStatusTypes.length) ||
                (selectedRelationshipTypes &&
                  selectedRelationshipTypes.length) ||
                (searchFieldText &&
                  searchFieldText !== null &&
                  searchFieldText.length) ||
                onlyAccountOwners ||
                selectedAccountName ||
                selectedCsTeam) && (
                <div className="align-self-center ml-0 clearFilter">
                  <button
                    type="button"
                    onClick={() => props.clearAll()}
                    style={{
                      border: 'none',
                      background: 'transparent',
                      color: '#919eab',
                      fontSize: '0.87rem',
                    }}
                  >
                    <i className="icon-close2 label-small" />
                    Clear All
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="select-filter-list w-100">
            {selectedStatusTypes &&
              selectedStatusTypes.map((item, key) => (
                <div key={key} className="badge-filter">
                  {item}
                  <button
                    type="button"
                    onClick={() => props.handleStatusFilter(item, '', true)}
                    style={{
                      border: 'none',
                      background: 'transparent',
                      color: '#919eab',
                    }}
                  >
                    <i className="icon-close2" aria-label="Close" />
                  </button>
                </div>
              ))}
            {selectedEntityTypes.map((item, key) => (
              <div key={key} className="badge-filter">
                {item}
                <button
                  type="button"
                  onClick={() => props.handleFilterCheckbox(item, '', true)}
                  style={{
                    border: 'none',
                    background: 'transparent',
                    color: '#919eab',
                  }}
                >
                  <i className="icon-close2" aria-label="Close" />
                </button>
              </div>
            ))}
            {selectedRelationshipTypes &&
              selectedRelationshipTypes.map((item, key) => (
                <div key={key} className="badge-filter">
                  {item}
                  <button
                    type="button"
                    onClick={() => props.handleRelationship(item, '', true)}
                    style={{
                      border: 'none',
                      background: 'transparent',
                      color: '#919eab',
                    }}
                  >
                    <i
                      className="icon-close2"
                      aria-label="clear relationship filter"
                    />
                  </button>
                </div>
              ))}
            {selectedAccountName && (
              <div key={selectedAccountId} className="badge-filter">
                {selectedAccountName}
                <button
                  type="button"
                  onClick={handleAccountDropdown}
                  style={{
                    border: 'none',
                    background: 'transparent',
                    color: '#919eab',
                  }}
                >
                  <i
                    className="icon-close2"
                    aria-label="clear account filter"
                  />
                </button>
              </div>
            )}
            {selectedCsTeam && (
              <div key={selectedCsTeam.id} className="badge-filter">
                {selectedCsTeam.headline}
                <button
                  type="button"
                  onClick={() => handleCstTeam(undefined)}
                  style={{
                    border: 'none',
                    background: 'transparent',
                    color: '#919eab',
                  }}
                >
                  <i
                    className="icon-close2"
                    aria-label="clear cst team filter"
                  />
                </button>
              </div>
            )}
            {onlyAccountOwners && (
              <div key="account_owner" className="badge-filter">
                Account Owner
                <button
                  type="button"
                  onClick={props.handleAccountOwnerCheckbox}
                  style={{
                    border: 'none',
                    background: 'transparent',
                    color: '#919eab',
                  }}
                >
                  <i
                    tabIndex={0}
                    role="button"
                    className="icon-close2"
                    aria-label="clear account owner filter"
                  />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchSection;
