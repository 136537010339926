import { Stack, ThemeProvider } from '@mui/material';
import liscioLightTheme from 'theme/liscioLight';
import { useAdminBillingQuery } from 'hooks/admin-billing-hooks';
import LiscioSpinner from 'ui/Loading/LiscioSpinner';
import TierPanel from './components/TierPanel';
import TieredOfferings from './components/TieredOfferings';
import UpgradeBanner from './components/UpgradeBanner';

/**
 * Primary entry point for Admin Billing
 */
export default function AdminBillingPage() {
  const { data: firmLicenseData } = useAdminBillingQuery();

  if (!firmLicenseData) return <LiscioSpinner />;

  const tierProps = firmLicenseData.fetchFirmLicense;

  return (
    <ThemeProvider theme={liscioLightTheme}>
      {tierProps?.licenseTier!.toLocaleLowerCase().includes('pro') && (
        <UpgradeBanner />
      )}
      <Stack
        aria-label="Admin Billing Page"
        overflow="hidden"
        display="flex"
        height="calc(100% - 32px)"
      >
        <TierPanel {...tierProps} />
        <TieredOfferings currentPlan={tierProps?.licenseTier!} />
      </Stack>
    </ThemeProvider>
  );
}
