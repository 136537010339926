import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { useNavigate } from 'react-router-dom-latest';
import { withRouter } from 'utilities/withRouterWrapper';
import { Scrollbars } from 'react-custom-scrollbars-2';
import axios from 'axios';
import Interweave from 'interweave';
import { UPDATE_TERMS, LOGOUT } from 'constants/constants';
import { removeCookie } from 'utilities/utils';
import { clearAuthSession, getAuthSession } from 'auth/session';
import PrintPdfButton from './PrintPdfButton';

// TODO: use hooks for history and location
const TermConditions = ({ data: { termsData }, agreedTerms }) => {
  const [checked, setChecked] = useState(false);
  const [scrollBarHideForMobile, toggleScrollbarForMobile] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const viewPortWidth = Math.max(
      document.documentElement.clientWidth,
      window.innerWidth || 0,
    );

    if (viewPortWidth <= 768) {
      toggleScrollbarForMobile(true);
    }
  }, []);

  function checkBoxTick() {
    setChecked(!checked);
  }

  function rejectTermsAndPolicies() {
    axios.post(LOGOUT);
    const logoutInterval = setInterval(() => {
      clearAuthSession();
      removeCookie({}, 'redirect_url');
      localStorage.clear();
      if (!getAuthSession() && localStorage.length === 0) {
        navigate('/login');
        clearInterval(logoutInterval);
      }
    }, 100);
  }

  function updateTerms() {
    const dataObj = { version: termsData.data.version_number };
    const request = axios.post(UPDATE_TERMS, dataObj);
    request.then((response) => {
      if (response.data.status === 200) {
        agreedTerms(response.data.data);
      }
    });
  }

  return (
    <div>
      <Modal
        className="customModal customModal--w990 customModal--nopadding"
        isOpen
      >
        <div className="ModalHeader">
          <button
            type="button"
            onClick={rejectTermsAndPolicies}
            className="close"
            aria-label="Close"
          >
            <i className="icon-close2" aria-hidden="true" />
          </button>
        </div>
        <ModalBody className="modalContent" style={{ maxHeight: 'none' }}>
          <div className="modalContent--inner pt-0">
            <div className="col-container col-containerIE">
              <div className="text-right mt-3 mb-3">
                <PrintPdfButton
                  content_id="termandconditions"
                  iframe_id="ifmcontentstoprint"
                />
              </div>
            </div>
            <div className="docWrap" id="docWrap">
              {scrollBarHideForMobile ? (
                <div className="term-wrapper inviteTerms">
                  <Interweave
                    allowAttributes
                    content={termsData.data.content}
                  />
                </div>
              ) : (
                <Scrollbars
                  style={{ width: '100%', height: '100%' }}
                  autoHide
                  autoHideTimeout={1000}
                >
                  <div
                    id="termandconditions"
                    className="term-wrapper inviteTerms"
                  >
                    <Interweave
                      allowAttributes
                      content={termsData.data.content}
                    />
                  </div>
                </Scrollbars>
              )}
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="modalFooter">
          <div className="modalFooter--inner justify-content-between termsFooter">
            <div className="checkbox checkbox-primary check-container align-self-center">
              <label htmlFor="agree_to_terms">
                <input
                  type="checkbox"
                  id="agree_to_terms"
                  className="styled"
                  checked={checked}
                  onChange={checkBoxTick}
                />
                <i className="checkmark" />
                <span className="pl-2">
                  I have read and agree to these terms and conditions.
                </span>
              </label>
            </div>
            <div className="btn-wrap btn--leftspace">
              <button
                type="button"
                className="btn btn-primary"
                disabled={!checked}
                onClick={updateTerms}
              >
                Submit
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-dismiss="modal"
                onClick={rejectTermsAndPolicies}
                disabled={checked}
              >
                Cancel
              </button>
            </div>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default withRouter(TermConditions);
