import React from 'react';
import DiscardEmail from 'components/Email/components/icons/DiscardEmail';
import Button from 'common/Button/Button';
import { BUTTON_ICON_LOCATION_BACK } from 'constants/constants';

function TaskFooterButtons(props) {
  const {
    data,
    handleCancel,
    taskStatus,
    recurringDisable,
    dataUpdated,
    stateValues,
    submitForm,
  } = props;
  return (
    <div
      data-testid="taskFooterButton"
      className={`${
        data?.viewContext === 'email'
          ? 'btn-wrap btn--leftspace EmailTasks'
          : 'btn-wrap btn--leftspace'
      }`}
    >
      {data?.viewContext === 'email' ? (
        <DiscardEmail handleDiscardButton={handleCancel} />
      ) : (
        <button
          type="button"
          className="btn btn-outline-light"
          onClick={handleCancel}
          disabled={taskStatus === 'Closed' || recurringDisable}
          data-testid="task__cancel_btn"
        >
          Cancel
        </button>
      )}
      {data.viewContext === 'email' ? (
        <Button
          label={!data.isEdit ? 'Create Task' : 'Update Task'}
          className="btn btn-primary task-form-submit"
          icon="plus"
          onClick={submitForm}
          variant="primary"
          size="small"
          iconLocation={BUTTON_ICON_LOCATION_BACK}
          disabled={
            taskStatus === 'Closed' ||
            recurringDisable ||
            (data.isEdit && !dataUpdated && !stateValues.isChanged) ||
            (data && data.gettingEmailAttachments)
          }
          data-testid="task__submit_btn"
        />
      ) : (
        <button
          type="submit"
          className="btn btn-primary task-form-submit"
          onClick={submitForm}
          disabled={
            taskStatus === 'Closed' ||
            recurringDisable ||
            (data.isEdit && !dataUpdated && !stateValues.isChanged) ||
            (data && data.gettingEmailAttachments)
          }
          data-testid="task__submit_btn"
        >
          {!data.isEdit ? 'Create Task' : 'Update Task'}
        </button>
      )}
    </div>
  );
}

export default TaskFooterButtons;
