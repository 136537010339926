/* eslint-disable react/jsx-props-no-spreading */
import React, { components } from 'react-select';
import styled from '@emotion/styled';
import Checkbox from 'common/Checkbox';

const StyledDiv = styled.div`
  display: flex;
  justify-content: space-between;

  .entity {
    background-color: #eef0f1;
    color: #696969;
    padding: 0.1rem 0.1rem 0.1rem 0.1rem;
    border-radius: 1px;
    margin-left: 0.9rem;
  }
`;

function Option(props) {
  const { data, isSelected } = props;
  return (
    <components.Option {...props}>
      <StyledDiv>
        <div>
          {data?.label && <span>{data?.label}</span>}
          {data?.entity_type && (
            <span className={data?.entity_type ? 'entity' : null}>
              {data?.entity_type}
            </span>
          )}
        </div>
        <Checkbox checked={isSelected} />
      </StyledDiv>
    </components.Option>
  );
}
export default Option;
