import React, { useState } from 'react';
import { debounce } from 'lodash';
import { TextFilter } from 'components/Accounts/AccountList/Elements';

function FilterBySearch({ activitySearch, setActivitySearch }) {
  const [searchInput, setSearchInput] = useState(activitySearch);

  const delayedSearch = React.useCallback(
    debounce((delayedText) => setActivitySearch(delayedText), 300),
  );

  function handleSearch(event) {
    event.stopPropagation();
    setSearchInput(event.target.value);
    delayedSearch(event.target.value);
  }
  return (
    <TextFilter
      value={searchInput}
      onChange={debounce((event) => {
        handleSearch(event);
      }, 300)}
    />
  );
}
export default FilterBySearch;
