/* eslint-disable react/jsx-props-no-spreading */
import { Box, Stack, Typography, useTheme } from '@mui/material';
import dayjs from 'dayjs';
import { CheckCircle, ErrorOutline } from '@mui/icons-material';
import { SMSAvatar } from 'components/SMS/components/SMSAvatar/SMSAvatar';
import { formatSMSPhone } from 'components/SMS/utils/formatSMSPhone';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { TooltipStyled } from 'ui/TooltipStyled/TooltipStyled';
import { useState } from 'react';
import SMSVaultDialog from 'components/SMS/components/SMSVaultDialog/SMSVaultDialog';
import {
  DisplayNameText,
  MessageBox,
  MessageBoxContainer,
  SenderAndMessageBox,
  StyledAvatarBox,
} from './SMSMessageStyles';
import SMSImageAttachment from './SMSImageAttachment';
import SMSDownloadAttachment from './SMSDownloadAttachment';

type TenOrZeroPixles = '10px' | '0px';

export type PaddingBundle = {
  noPaddingIfIncoming: TenOrZeroPixles;
  paddingIfIncoming: TenOrZeroPixles;
};

interface Props {
  multipleParticipants: boolean;
  message: Message;
}

export default function SMSMessage({
  multipleParticipants,
  message: {
    sender,
    messageText,
    direction,
    createdAt,
    status,
    attachments = [],
    from,
    textMessageThreadId,
    messageParticipants,
  },
}: Props) {
  const theme = useTheme();
  const [displayAddToVault, setDisplayAddToVault] = useState(false);
  const isIncoming = direction === 'inbound';
  const alignStartOrEnd = isIncoming ? 'flex-start' : 'flex-end';
  const backgroundColor = isIncoming ? 'secondary.light' : 'common.primary.50';
  const flexDirection = attachments.length > 0 ? 'column' : 'row';
  const paddingIfIncoming = isIncoming ? '10px' : '0px';
  const noPaddingIfIncoming = isIncoming ? '0px' : '10px';

  const firmSender = messageParticipants.find(
    (part) => part.phoneNumberE164 === from || part.phoneNumber === from,
  );

  const firmUser: Participant = {
    avatarURL: firmSender?.avatarURL || '',
    phoneNumber: '',
    firstName: firmSender?.firstName || '',
    lastName: firmSender?.lastName || '',
  };

  const isImage = (url: string) =>
    /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);

  const renderAttachments = () =>
    attachments.length > 0 &&
    attachments.map((attachment) => {
      const { url, inVault, blobId } = attachment;

      return (
        <Box marginBottom={!inVault ? '-2rem' : '0px'}>
          {isImage(url) ? (
            <SMSImageAttachment url={url} />
          ) : (
            <SMSDownloadAttachment url={url} />
          )}
          {!inVault && (
            <TooltipStyled title="Add to vault">
              <AddCircleOutlineOutlinedIcon
                onClick={() => setDisplayAddToVault(true)}
                sx={{ color: theme.palette.text.secondary }}
              />
            </TooltipStyled>
          )}
          {displayAddToVault && (
            <SMSVaultDialog
              textMessageThreadId={textMessageThreadId as string}
              blobId={blobId}
              onClose={() => setDisplayAddToVault(false)}
            />
          )}
        </Box>
      );
    });

  const sentAtTime = dayjs(createdAt).format('h:mm A');

  const fullName = sender[0].lastName
    ? `${sender[0].firstName} ${sender[0].lastName}`
    : formatSMSPhone(sender[0].phoneNumberE164 || sender[0].phoneNumber);
  const inCommingDisplayName =
    sender.length === 1 ? fullName : `${fullName} +${sender.length - 1}`;
  const displayName = isIncoming
    ? inCommingDisplayName
    : `${firmSender?.firstName} ${firmSender?.lastName}`;

  return (
    <MessageBoxContainer
      aria-label="message-box-container"
      isIncoming={isIncoming}
      alignStartOrEnd={alignStartOrEnd}
    >
      <StyledAvatarBox
        noPaddingIfIncoming={noPaddingIfIncoming}
        paddingIfIncoming={paddingIfIncoming}
      >
        <SMSAvatar
          participant={isIncoming ? sender[0] : firmUser}
          width={40}
          height={40}
          multiple={isIncoming ? multipleParticipants : false}
        />
      </StyledAvatarBox>
      <SenderAndMessageBox
        aria-label="sender-and-message-box"
        sx={{ alignItems: alignStartOrEnd }}
      >
        <DisplayNameText
          noPaddingIfIncoming={noPaddingIfIncoming}
          paddingIfIncoming={paddingIfIncoming}
        >
          {displayName}
        </DisplayNameText>
        <MessageBox
          className="message-and-time"
          sx={{ backgroundColor, flexDirection, justifyContent: 'left' }}
        >
          <Box sx={{ display: 'flex', width: '100%', justifyContent: 'start' }}>
            <Typography className="message-text">{messageText}</Typography>
          </Box>
          {renderAttachments()}
          <Stack
            className="info"
            display="flex"
            flexShrink={0}
            alignItems="center"
            gap="5px"
            fontSize="1rem"
            justifyContent="right"
          >
            {!isIncoming && renderStatusIcon(status)}
            <Typography className="info__time">{sentAtTime}</Typography>
          </Stack>
        </MessageBox>
      </SenderAndMessageBox>
    </MessageBoxContainer>
  );
}

const renderStatusIcon = (status: string | null | undefined) => {
  if (status && status === 'delivered')
    return (
      <CheckCircle
        role="img"
        titleAccess="success"
        fontSize="inherit"
        color="primary"
      />
    );

  return (
    <ErrorOutline
      role="img"
      titleAccess="error"
      fontSize="inherit"
      color="error"
    />
  );
};
