import { isValid, format } from 'date-fns';
import { getStorageData } from './utils';

export const getDateString = (date) => {
  if (isValid(date)) {
    return format(date, 'MM/dd/yyyy');
  }
  return '';
};

export const getDateObj = (date) => {
  if (date && typeof date === 'string') {
    return new Date(date);
  }
  return null;
};

export const getDefaultDueDate = () => {
  // TODO: __HOMEDATA-AUDIT
  const homeDataObj = getStorageData();
  const due_days_pref = homeDataObj.pref_type_options.task_due_days;
  const date_offset = due_days_pref.length
    ? parseInt(due_days_pref[0].label, 10)
    : 5;
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth();
  const day = today.getDate();
  return new Date(year, month, day + date_offset);
};
