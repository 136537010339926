import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom-latest';
import { ACCOUNTS_INFOCUS_DATA, DEFAULT } from 'constants/constants';
import styled from '@emotion/styled';
import InvoiceModal from 'components/Billing/elements/InvoiceModal/InvoiceModal';
import ActionsModal from 'common/ActionsModal';
import NewTask from 'components/Tasks/Create/NewTask';
import FilePopup from 'components/Files/FilePopup';
import InfocusCountSection from './elements/InFocusCountSection';
import InfocusRenderSections from './elements/InFocusRenderSections';
import { inFocusCountTabs } from './inFocusUtils';

const StyledDiv = styled.div`
  .InFocus__Loading {
    display: ${({ loading }) => (loading ? 'block' : 'none')};
  }
`;

const InFocus = ({ accountName, accountID, qbCustomerId }) => {
  const [inFocusCount, setInfocusCount] = useState({
    clientTask: 0,
    firmTask: 0,
    files: 0,
    invoices: 0,
  });
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [infocusFileData, setInfocusFileData] = useState(null);
  const [infocusClientTask, setInFocusClientTask] = useState(null);
  const [infocusFirmTask, setInFocusFirmTask] = useState(null);
  const [infocusInvoice, setInfocusInvoice] = useState(null);
  const [showModalFor, setShowModalFor] = useState(null);

  useEffect(() => {
    document.title = accountName;
    fetchInfocusData();
  }, []);

  const fetchInfocusData = async () => {
    try {
      const request = await axios.get(
        `${ACCOUNTS_INFOCUS_DATA}?id=${accountID}`,
      );
      if (request.status && request.data) {
        setLoading(false);
        const countData = request.data.accounts_counts[0];
        const clientTaskData = request.data.account_tasks[0].client_tasks;
        const firmTaskData = request.data.account_tasks[0].firm_tasks;
        const fileData = request.data.account_files;
        const invoiceData = request.data.account_firm_invoices;
        setInfocusCount({
          ...inFocusCount,
          clientTask: countData.client_tasks_count,
          firmTask: countData.firm_task_count,
          files: countData.files_count,
          invoices: countData.invoice_count,
        });
        setInfocusFileData(fileData);
        setInFocusClientTask(clientTaskData);
        setInFocusFirmTask(firmTaskData);
        setInfocusInvoice(invoiceData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const redirectUrl = (url, state, filterSelected) => {
    if (url === '/billing/invoices') {
      setShowModalFor('invoice');
    } else if (url === '/task/new/modal') {
      setShowModalFor('task');
    } else if (url === '/fileupload') {
      setShowModalFor('file');
    } else if (state) {
      navigate(`${url}/${accountID}`, {
        state: { accountID, accountName, from: 'inFocus' },
      });
    } else {
      navigate(url, { state: { filterSelected } });
    }
  };

  return (
    <StyledDiv className="InFocus" loading={loading}>
      {loading && <div id="loading" className="InFocus__Loading" />}
      {!loading && (
        <>
          <InfocusCountSection
            inFocusCountTabs={inFocusCountTabs}
            inFocusCount={inFocusCount}
            redirectUrl={redirectUrl}
            accountName={accountName}
            accountID={accountID}
          />
          <InfocusRenderSections
            inFocusCountTabs={inFocusCountTabs}
            inFocusCount={inFocusCount}
            redirectUrl={redirectUrl}
            infocusClientTask={infocusClientTask}
            infocusFirmTask={infocusFirmTask}
            infocusFileData={infocusFileData}
            infocusInvoice={infocusInvoice}
            accountName={accountName}
            accountID={accountID}
            loading={loading}
            refreshData={fetchInfocusData}
          />
        </>
      )}
      {showModalFor === 'invoice' && (
        <InvoiceModal
          isOpen={showModalFor}
          handleModalClose={() => setShowModalFor(undefined)}
          getAllInvoices={() => fetchInfocusData()}
          initialData={{
            account: {
              value: accountID,
              label: accountName,
              qb_customer_id: qbCustomerId,
            },
          }}
          mode={DEFAULT}
        />
      )}
      {showModalFor === 'task' && (
        <ActionsModal
          isOpen={showModalFor}
          onCancel={() => setShowModalFor(undefined)}
          style={{ overflow: 'auto', maxHeight: '85vh' }}
          showModalFooter={false}
        >
          <NewTask
            closeModalInEmailPage={() => setShowModalFor(undefined)}
            refreshEmailListPage={() => fetchInfocusData()}
            defaultType="to_do"
            viewContext="account"
            account_data={{
              value: accountID,
              label: accountName,
            }}
          />
        </ActionsModal>
      )}
      {showModalFor === 'file' && (
        <FilePopup closeModal={() => setShowModalFor(undefined)} />
      )}
    </StyledDiv>
  );
};

export default InFocus;
