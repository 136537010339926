import React from 'react';
import styled from '@emotion/styled';
import ArchiveBulkAction from './ArchiveBulkAction';
import MarkUnReadBulkAction from './MarkUnReadBulkAction';
import LowPriorityBulkAction from './LowPriorityBulkAction';
import PinBulkAction from './PinBulkAction';

const BulkActionsWrap = styled.div`
  &.Email__BulkActions {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .button-icon {
      border: 1px solid #e0e1e2;
      cursor: pointer;
      padding: 2px 4px;
      line-height: 1;
      svg {
        width: 11px;
        fill: transparent;
      }
      & + .button-icon {
        margin-left: 7px;
      }
    }
  }
`;

const EmailBulkActions = ({ filter, handleBultActions }) => {
  const bulkActions = [];
  const filtersProhibitingArchive = ['archive', 'sent', 'draft', 'done'];

  if (!filtersProhibitingArchive.includes(filter)) {
    bulkActions.push({
      label: 'Archive',
      value: 'done',
    });
  }
  if (filter !== 'draft') {
    bulkActions.push({
      label: 'Mark as Unread',
      value: 'mark_as_unread',
    });
  }
  if (filter !== 'low_priority' && filter !== 'sent' && filter !== 'draft') {
    bulkActions.push({
      label: 'Low Priority',
      value: 'low_priority',
    });
  }
  if (filter === 'pinned') {
    bulkActions.push({
      label: 'Unpin',
      value: 'unpin_it',
    });
  } else if (filter !== 'pinned' && filter !== 'draft') {
    bulkActions.push({
      label: 'Pin',
      value: 'pin_it',
    });
  }
  if (filter !== 'primary' && filter !== 'sent' && filter !== 'draft') {
    bulkActions.push({
      label: 'Primary',
      value: 'primary',
    });
  }

  return (
    <BulkActionsWrap className="Email__BulkActions" data-testid="bulkActions">
      {bulkActions.map((obj) => {
        const actionObj = obj;
        const actionName = obj.value;
        switch (actionName) {
          case 'done':
            return (
              <ArchiveBulkAction
                handleEmailActions={handleBultActions}
                actionObj={actionObj}
              />
            );
          case 'mark_as_unread':
            return (
              <MarkUnReadBulkAction
                handleEmailActions={handleBultActions}
                actionObj={actionObj}
              />
            );
          case 'low_priority':
            return (
              <LowPriorityBulkAction
                handleEmailActions={handleBultActions}
                actionObj={actionObj}
              />
            );
          case 'pin_it':
            return (
              <PinBulkAction
                handleEmailActions={handleBultActions}
                actionObj={actionObj}
              />
            );
          default:
            return null;
        }
      })}
    </BulkActionsWrap>
  );
};
export default EmailBulkActions;
