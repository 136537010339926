/* eslint-disable import/prefer-default-export */
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { generateFullName } from 'utilities/utils';

export const ListUserAvatar = (props) => {
  const { data, row_index, avatarSize } = props;
  const avatarClass = avatarSize
    ? `avatar avatar--w${avatarSize} d-inline-block p-0`
    : 'avatar avatar--w30 d-inline-block p-0';
  if (data && data.length) {
    return data.map((each, index) => {
      if (each === undefined || each === null || each.id === undefined) {
        return null;
      }
      return (
        <OverlayTrigger
          key={each.id}
          placement="top"
          overlay={
            <Tooltip id={`${each.id}_${row_index}_${index}`}>
              {generateFullName(each)}
            </Tooltip>
          }
        >
          <span
            key={`${each.id}signers`}
            data-for={`${each.id}_${row_index}_${index}`}
            className={avatarClass}
            data-tip={row_index * index}
          >
            {each.avatar && each.avatar !== null && each.avatar.length ? (
              <img src={each.avatar} alt="I" className="rounded-circle" />
            ) : (
              <span className="uName">{each.initials}</span>
            )}
          </span>
        </OverlayTrigger>
      );
    });
  }
  return null;
};
