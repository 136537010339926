import { useQueryClient } from '@tanstack/react-query';
import { workflowRequestsQueryDocument } from 'api/workflow-requests';
import { REQUESTS_PAGE_ROUTES } from 'components/Requests/requests.constants';
import { WorkflowRequestFragment, WorkflowRequestStatus } from 'gql/graphql';
import { useUpdateWorklowRequest } from 'hooks/workflow-request-hooks';
import { useNavigate } from 'react-router-dom-latest';
import { WorkflowViewFooterWrapper } from 'components/Requests/components/WorkflowView/components/WorkflowViewFooterWrapper/WorkflowViewFooterWrapper';

type WorkflowViewFooterProps = {
  workflowRequest?: WorkflowRequestFragment | null;
  onSubmitDisabled?: boolean;
  onCompleteDisabled?: boolean;
  onSaveAndExitDisabled?: boolean;
  status?: WorkflowRequestStatus;
};

export const WorkflowViewFooter = ({
  workflowRequest,
  onSubmitDisabled,
  onCompleteDisabled,
  onSaveAndExitDisabled,
}: WorkflowViewFooterProps) => {
  const navigate = useNavigate();
  const { mutate: updateWorkflowRequestMutation } = useUpdateWorklowRequest();
  const queryClient = useQueryClient();

  const handleSaveAndClose = () => {
    navigate(REQUESTS_PAGE_ROUTES.requests);
  };

  const updateWorkflowRequestStatus = () => {
    const redirect_location = !onCompleteDisabled
      ? REQUESTS_PAGE_ROUTES.requests
      : '/';
    const status = !onCompleteDisabled
      ? WorkflowRequestStatus.Closed
      : WorkflowRequestStatus.Submitted;
    updateWorkflowRequestMutation(
      {
        workflowRequestId: workflowRequest?.id,
        status,
      },
      {
        onSettled: () => {
          queryClient.invalidateQueries({
            queryKey: [
              (workflowRequestsQueryDocument.definitions[0] as any).name.value,
            ],
            refetchType: 'all',
          });

          navigate(redirect_location);
        },
      },
    );
  };

  return (
    <WorkflowViewFooterWrapper
      assignedContacts={workflowRequest?.assignedContacts}
      onSubmit={!onSubmitDisabled ? updateWorkflowRequestStatus : undefined}
      status={workflowRequest?.status}
      onSaveAndExit={!onSaveAndExitDisabled ? handleSaveAndClose : undefined}
      onComplete={!onCompleteDisabled ? updateWorkflowRequestStatus : undefined}
    />
  );
};
