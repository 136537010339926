/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-wrap-multilines */
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import ReactTooltip from 'react-tooltip';

const DateInputButton = (props) => {
  const { onClick, isFilterButton, isFilterActive, selectsRange, label } =
    props;

  if (selectsRange && isFilterButton) {
    return (
      <button
        className={`btn btn-sm btn-outline-light dropdown-toggle ${
          isFilterActive ? 'selected' : ''
        }`}
        type="button"
        id="dateRangeFilter"
        onClick={onClick}
      >
        {label}
      </button>
    );
  }
  return null;
};

const CustomDatePicker = (props) => {
  const {
    selectsRange = false,
    toolTipLabel = '',
    isFilterButton = false,
    label,
    selected,
    seletedStartDate = null,
    selectedEndDate = null,
    onChange,
    disabled,
    minDate = null,
    customStyle,
    onChangeRaw,
    maxDate,
    ariaLabelledBy,
  } = props;

  const isFilterActive = seletedStartDate && selectedEndDate;
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const changePaddingRight = { paddingRight: '5px' };
  const Styles = { ...customStyle, ...changePaddingRight };
  const onDateChange = (dates) => {
    if (!selectsRange) {
      onChange(dates);
      return;
    }
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (start && end) {
      onChange(dates);
    }
  };
  if (selectsRange) {
    return (
      <DatePicker
        selected={startDate}
        startDate={startDate}
        endDate={endDate}
        onChange={onDateChange}
        selectsRange
        monthsShown={2}
        disabled={disabled}
        ariaLabelledBy={ariaLabelledBy}
        customInput={
          <DateInputButton
            isFilterButton={isFilterButton}
            isFilterActive={isFilterActive}
            toolTipLabel={toolTipLabel}
            selectsRange={selectsRange}
            label={label}
          />
        }
      />
    );
  }

  return (
    <div className="date-input-wrapper ml-0 iconReactCal" style={Styles}>
      <i
        className="icon-calendar"
        aria-hidden="true"
        data-tip
        data-for="date_tooltip"
      />{' '}
      {toolTipLabel && (
        <ReactTooltip id="date_tooltip" effect="solid">
          <span className="contactcard-team-member">{toolTipLabel}</span>
        </ReactTooltip>
      )}
      <DatePicker
        selected={selected}
        onChange={onDateChange}
        disabled={disabled}
        minDate={minDate}
        className="w-100"
        onChangeRaw={onChangeRaw}
        maxDate={maxDate}
        formatDate="MM/dd/yyyy"
        ariaLabelledBy={ariaLabelledBy}
      />
    </div>
  );
};

export default CustomDatePicker;
