import { useQuery } from '@tanstack/react-query';
import { integrationModules } from './integration-modules';
import { IntegrationModuleWithStatus } from './integration-module.types';

/**
 * Helper hook for getting integration modules w/ activation status
 */
export const useIntegrationModules = () => {
  const result = useQuery({
    queryKey: ['useIntegrationModules'],
    queryFn: async () => {
      /**
       * NOTE: This is convenient for a configuration based approach to
       * integration modules, but it does cause us to fetch integration
       * status twice since we aren't caching our initial requests here...
       */
      const modulesWithStatus = await Promise.all(
        integrationModules.map(async (module) => {
          try {
            const activated = await module.getActivationStatus();
            return {
              ...module,
              activated,
            };
          } catch (error) {
            return {
              ...module,
              activated: false,
              error,
            };
          }
        }),
      );
      return modulesWithStatus as IntegrationModuleWithStatus[];
    },
  });

  return result;
};
