/*
   Sometimes, MUI passes non-HTML props to HTML elements when we use the `styled()` utility.
   Box, Stack, and other MUI components seem to be fine.
   However, any styled props passed to the Avatar are passed directly to the DOM element.
   This produces an error:

   Warning: React does not recognize the `mySpecialProp` prop on a DOM element. 
   If you intentionally want it to appear in the DOM as a custom attribute, spell it as lowercase `myspecialprop` 
   instead. If you accidentally passed it from a parent component, remove it from the DOM element
   
   to be fixed using the onlyHTMLProps util like so:
   
   export const StyledAvatar = styled(Avatar, onlyHTMLProps)(({
    paddingIfIncoming,
    noPaddingIfIncoming }: PaddingBundle) => ({
      width: 40,
      margin: `25px ${paddingIfIncoming} 0px ${noPaddingIfIncoming}`
    }))

  More details at: https://github.com/emotion-js/emotion/issues/183
*/

import isPropValid from '@emotion/is-prop-valid'

export const onlyHTMLProps = { shouldForwardProp: isPropValid };
