/* eslint-disable no-script-url */
/* eslint-disable react/jsx-no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import Axios from 'axios';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { EditorState, convertFromRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { options, removeBreakElementinList } from 'common/NotesUtilities';
import ConfirmationModal from 'common/ConfirmationModal';
import TinymceEditor from 'components/Emails/components/TinymceEditior';
import { EDOC_TEMPLATE_FIELDS } from 'constants/constants';
import EsignTemplatesList from '../Esign/EsignTemplatesList';
import TemplatePreview from '../Esign/TemplatePreview';

const generateSentence = (input) => {
  const words = [];
  input.forEach((e) => {
    words.push(e.name);
  });
  const pretext =
    ' has sender prefill data fields. Data fields cannot be prefilled by firm users when sending in bulk. Please remove or modify these fields, or use another template.';
  if (words.length > 1) {
    words[words.length - 1] = ` and ${words[words.length - 1]}`;
    return words.join(' ') + pretext;
  }
  return words.join(' ') + pretext;
};

class EsignSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      agreementTitle: '',
      comment: '',
      uploadFiles: [],
      documents: [],
      newDocs: [],
      attachmentId: undefined,
      uploadedFilesLength: 0,
      uploadedFilesCount: 0,
      isDocumentUpload: false,
      showAttachmentModal: false,
      showModal: false,
      isFileModalSubmit: false,
      popUp: false,
      selectedTemplates: [],
      type: 'bulk',
      containsPrefilledFields: [],
      loading: false,
    };
  }

  getTemplateDetails = (template) => {
    Axios.get(`${EDOC_TEMPLATE_FIELDS}/${template.id}`)
      .then((res) => {
        if (res.data && res.data.contains_prefilled_fields) {
          this.setState({
            containsPrefilledFields: [
              ...this.state.containsPrefilledFields,
              ...[template],
            ],
            loading: false,
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  };

  handleChange = (e) => {
    const { errors } = this.state;
    delete errors.agreementTitle;
    this.setState({
      errors,
      agreementTitle: e.target.value,
      isChanged: true,
    });
  };

  handleCommentChange = (value) => {
    this.setState({ comment: value });
  };

  showSelectedData = () => {
    if (this.isFormValid()) {
      const { selectedTemplates, agreementTitle, comment } = this.state;
      const data = {
        agreement_name: agreementTitle,
        selectedTemplates,
      };
      if (
        typeof comment === 'object' &&
        comment !== null &&
        EditorState.createWithContent(convertFromRaw(comment))
          .getCurrentContent()
          .getPlainText().length
      ) {
        data.description = removeBreakElementinList(
          stateToHTML(
            EditorState.createWithContent(
              convertFromRaw(comment),
            ).getCurrentContent(),
            options,
          ),
        );
      } else if (typeof comment === 'string' && comment.length) {
        data.description = comment;
      } else {
        data.description = '';
      }
      // if (data.description && data.description.length > 0) {
      //   data.description = data.description.replace(/\n/g, '<br>');
      // }
      this.props.showSelectedData(true, data);
      // alert('next');
    }
  };

  isFormValid() {
    const { agreementTitle, selectedTemplates } = this.state;
    const err = {};
    let formIsValid = true;
    if (agreementTitle === '' || !agreementTitle) {
      formIsValid = false;
      err.agreementTitle = 'Please enter agreement name';
    }
    if (selectedTemplates === undefined || selectedTemplates.length === 0) {
      formIsValid = false;
      err.document = 'Please select template';
    }
    this.setState({ errors: err });
    return formIsValid;
  }

  handleTemplatesBtn = (input) => {
    this.setState({ showTemplates: input });
  };

  assignSelectedTemplateId = (input) => {
    const { errors } = this.state;
    delete errors.document;
    this.setState(
      {
        selectedTemplates: [...this.state.selectedTemplates, input],
        showTemplates: false,
        errors,
        loading: true,
      },
      () => this.getTemplateDetails(input),
    );
  };

  deleteTemplate = (input) => {
    const { selectedTemplates, containsPrefilledFields } = this.state;
    const updatedOnes = selectedTemplates.filter((e) => e.id !== input.id);
    const updatedcontainsPrefilledFields = containsPrefilledFields.filter(
      (e) => e.id !== input.id,
    );
    this.setState({
      selectedTemplates: updatedOnes,
      containsPrefilledFields: updatedcontainsPrefilledFields,
    });
  };

  showTemplatePreview = (templateId) => {
    if (typeof templateId === 'string') {
      this.setState({
        selectedTemplateId: templateId,
        loading: false,
      });
    } else {
      this.setState({ selectedTemplateId: undefined });
    }
  };

  render() {
    const {
      errors,
      agreementTitle,
      showModal,
      selectedTemplateId,
      selectedTemplates,
      containsPrefilledFields,
      comment,
      loading,
    } = this.state;
    return (
      <div id="content" className={this.props.showThis ? '' : 'hidden'}>
        <div className="center-wrapper col-lg-10 col-sm-12 col-md-12 m-auto with-disabled-inputs">
          <div className="row">
            <div
              className={`form-group col-12${
                errors.agreementTitle ? ' form-error' : ''
              }`}
            >
              <label htmlFor="agreementTitle">Agreement Title</label>
              <input
                autoComplete="off"
                name="agreementTitle"
                className="form-control"
                id="agreementTitle"
                type="text"
                value={agreementTitle}
                maxLength="200"
                onChange={this.handleChange}
              />
              <span className="error_label">{errors.agreementTitle}</span>
            </div>
          </div>
          <div className="row">
            <div
              className={
                errors.comment_error
                  ? 'form-group col-lg-12 form-error'
                  : 'form-group col-lg-12'
              }
            >
              <div className="has-float-label customEditor boxResize">
                <label htmlFor="taskDescription">Description</label>
                <TinymceEditor
                  initialValue=""
                  value={comment}
                  handleChange={this.handleCommentChange}
                />{' '}
                <span className="error_label">{errors.comment_error}</span>
              </div>
            </div>
          </div>
          <div className="row">
            <div
              className={`form-group col-12${
                errors.document ? ' form-error' : ''
              }`}
            >
              <label htmlFor="attachments">Attachments</label>
              <div className="dragBrowse">
                <div className="DragDrop d-flex justify-content-center align-items-center">
                  <div className="dropFile pl-0 pr-0">
                    <div className="col">
                      <label
                        htmlFor="upload_doc"
                        className="fileContainer text-capitalize"
                      >
                        <a
                          href="javascript:void(0);"
                          onMouseDown={() => this.handleTemplatesBtn(true)}
                        >
                          Templates
                        </a>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="attached-file-list">
                {selectedTemplates && selectedTemplates.length
                  ? selectedTemplates.map((doc) => (
                      <div key={`para${doc.id}`} className="file-drop-upload">
                        <div className="file-drop-info">
                          <div className="file-drop-detail">
                            <h6
                              href="#"
                              className="fileName"
                              key={`document${doc.id}`}
                            >
                              <span className="fileName pr-0">{doc.name}</span>
                            </h6>
                          </div>
                          <div>
                            <a
                              className="previewFile file-action"
                              key={`doc${doc.id}`}
                              href="javascript:void(0)"
                              onClick={() => this.showTemplatePreview(doc.id)}
                            >
                              <i className="icon-open-eye" /> Preview
                            </a>
                          </div>
                          <div>
                            <a
                              className="file-action"
                              key={`delete${doc.id}`}
                              onClick={() => this.deleteTemplate(doc, false)}
                              href="javascript:void(0)"
                            >
                              <i className="icon-close2" />
                            </a>
                          </div>
                        </div>
                      </div>
                    ))
                  : null}
              </div>
              {errors.document && (
                <div className="error_label pt-2">{errors.document}</div>
              )}
              {containsPrefilledFields.length ? (
                <div className="text-danger pt-2 pl-3">
                  {generateSentence(containsPrefilledFields)}
                </div>
              ) : null}
            </div>
          </div>
          <div className="formbtn justify-content-end">
            <div className="btn-wrap btn--leftspace">
              <button
                type="button"
                className="btn btn-outline-light"
                onClick={() => this.props.showDocumentSectionFn(false)}
              >
                Back
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={this.showSelectedData}
                disabled={containsPrefilledFields.length || loading}
              >
                Next Step
              </button>
            </div>
          </div>
        </div>
        <ConfirmationModal
          isOpen={showModal}
          headerText="You are about to delete the attached file."
          messageText="This action cannot be undone. Proceed?"
          noBtnClick={this.hideModal}
          yesBtnClick={this.handleDeleteAttachment}
          yesBtnText="Yes"
          noBtnText="No"
        />
        <Modal
          isOpen={this.state.showTemplates}
          className="modal-dialog customModal customModal--w900 customModal--nopadding"
        >
          <ModalHeader className="ModalHeader">
            <button
              type="button"
              onClick={() => this.handleTemplatesBtn(false)}
              className="close"
              aria-label="Close"
            >
              <i className="icon-close2" aria-hidden="true" />
            </button>
          </ModalHeader>
          <ModalBody className="modalContent" style={{ maxHeight: 'none' }}>
            <div className="modalContent--inner">
              <div className="modalContent--header border-0 mb-1">
                <h5 className="modalContent--header__heading">
                  Templates List
                </h5>
              </div>
              <EsignTemplatesList
                assignSelectedTemplateId={this.assignSelectedTemplateId}
              />
            </div>
          </ModalBody>
        </Modal>
        {selectedTemplateId && (
          <TemplatePreview
            closeModal={this.showTemplatePreview}
            templateId={selectedTemplateId}
          />
        )}
      </div>
    );
  }
}

export default EsignSection;
