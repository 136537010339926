/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import Axios from 'axios';
import Interweave from 'interweave';
import moment from 'moment';
import {
  getOffSetsAndLimit,
  getCpaPreferences,
  isUserEmployee,
  AlertMessage,
  getBulkInviteMaxCount,
  generateFullName,
  sortSelectedList,
} from 'utilities/utils';
import Pagination from 'common/Pagination';
import {
  SEARCH_CONTACTS_FOR_BULKEDOC,
  CREATE_BULK_EDOC_REQUESTS,
  ADOBE_INTEGRATION_STATUS,
} from 'constants/constants';
import ConfirmationModal from 'common/ConfirmationModal';
import NoRecords from 'common/NoRecords';
import { withRouter } from 'utilities/withRouterWrapper';
import LoadingOverlay from 'common/LoadingOverlay';
import { withStorageData } from 'utilities/withStorageData';
import SearchSection from './SearchSection';
import SearchList from './SearchList';
import EsignSection from './EsignSection';

const removeContact = (array, element) =>
  array.filter(
    (e) =>
      !(
        e.contact.id === element.contact.id &&
        e.contact.account_id === element.contact.account_id
      ) && !element.checked,
  );

const updateListData = (listData, selectedData) => {
  if (selectedData.length) {
    listData.map((each) => {
      const eachone = each;
      eachone.checked = false;
      selectedData.map((e) => {
        if (
          e.contact.id === each.contact.id &&
          e.contact.account_id === each.contact.account_id
        ) {
          eachone.checked = true;
        }
        return e;
      });
      return eachone;
    });
  }
  return listData;
};

const bulkMessageTableHeaders = [
  {
    value: 'last_name',
    label: 'Contact Name',
    isSortable: true,
    sortValue: 'last_name',
    className: ' col-auto flex-20',
    // flex: '0 0 18%',
    formatFn: (input) => generateFullName(input.contact, true),
  },
  {
    value: 'account_name',
    label: 'Account Name',
    isSortable: true,
    className: ' col-auto flex-20',
    sortValue: 'account_name',
    // flex: '0 0 18%',
    formatFn: (input) =>
      input.contact.account_name &&
      input.contact.account_name !== null &&
      input.contact.account_name.length > 0
        ? input.contact.account_name
        : '-',
  },
  {
    value: 'relationship_type',
    label: 'Relationship',
    isSortable: true,
    className: ' col-auto flex-10',
    sortValue: 'relationship_type',
    // flex: '0 0 18%',
    formatFn: (input) => input.relationship_type || '-',
  },
  {
    value: 'primary_email',
    label: 'Email',
    isSortable: true,
    className: ' col-auto flex-20',
    sortValue: 'primary_email',
    // flex: '0 0 20%',
    formatFn: (input) =>
      input.contact.primary_email &&
      input.contact.primary_email !== null &&
      input.contact.primary_email.length > 0
        ? input.contact.primary_email
        : '-',
  },
  {
    value: 'task_date',
    label: 'Last Sent Date',
    isSortable: true,
    className: ' col-auto flex-10',
    sortValue: '"created_at"',
    // flex: '0 0 20%',
    formatFn: (input) =>
      input.task_date && input.task_date !== null
        ? moment(input.task_date).format('MM/DD/YYYY')
        : '-',
  },
  {
    value: 'title',
    label: 'Last Sent Title',
    isSortable: true,
    className: ' col-auto flex-15',
    sortValue: 'title',
    // flex: '0 0 20%',
    formatFn: undefined,
  },
];

const bulkLastPageMessageTableHeaders = [
  {
    value: 'last_name',
    label: 'Contact Name',
    isSortable: true,
    sortValue: 'last_name',
    className: ' col-auto flex-30',
    // flex: '0 0 30%',
    formatFn: (input) => generateFullName(input.contact, true),
  },
  {
    value: 'account_name',
    label: 'Account Name',
    isSortable: true,
    className: ' col-auto flex-30',
    sortValue: 'account_name',
    // flex: '0 0 30%',
    formatFn: (input) =>
      input.contact.account_name &&
      input.contact.account_name !== null &&
      input.contact.account_name.length > 0
        ? input.contact.account_name
        : '-',
  },
  {
    value: 'primary_email',
    label: 'Email',
    isSortable: true,
    className: ' col-auto flex-22',
    sortValue: 'primary_email',
    // flex: '0 0 22%',
    formatFn: (input) =>
      input.contact.primary_email &&
      input.contact.primary_email !== null &&
      input.contact.primary_email.length > 0
        ? input.contact.primary_email
        : '-',
  },
];

const edocBalanceBoxColor = (balance, threshold, low_balance_warning) => {
  if (balance <= -threshold) {
    return 'red';
  }
  if (balance <= low_balance_warning) {
    return 'yellow';
  }
  return 'green';
};

const getAlertModalMessages = (maxCount, no_of_edocs_allowed) => {
  if (no_of_edocs_allowed < maxCount) {
    return 'Error: You have exceeded your eSign Documents balance. \n You cannot proceed.  \n Please upgrade your eSign Documents balance to continue this feature.';
  }
  return `You can select up to ${maxCount} contacts`;
};

export class BulkEsign extends Component {
  constructor(props) {
    super(props);
    this.state = {
      entityTypes: [...getCpaPreferences().entity_type],
      relationshipTypes: [...getCpaPreferences().contact_type],
      searchFieldText: '',
      selectedEntityTypes: [],
      selectedData: [],
      listData: [],
      pagination: 1,
      showOnlySelectedData: false,
      selectedAll: false,
      showDocumentSection: false,
      totalRecords: 0,
      totalPages: 0,
      sortBy: 'last_name',
      sortOrder: 'asc',
      allowed_no_of_edocs: 0,
      maxCount: getBulkInviteMaxCount(
        getCpaPreferences().bulk_action_limit,
        'max_send_edocs',
      ),
      onlyAccountOwners: false,
      loading: false,
    };
  }

  componentDidMount() {
    document.title = 'Send eDocs';
    if (!isUserEmployee()) {
      this.props.navigate('/');
    } else {
      this.getIntegrationStatus();
    }
  }

  getIntegrationStatus = () => {
    Axios.get(ADOBE_INTEGRATION_STATUS).then((res) => {
      if (res.status === 200 && res.data && res.data.data) {
        const {
          adobe_admin_email,
          adobe_token_exists,
          service_usages,
          default_esign_low_bal,
          default_esign_limit,
          default_esign_label,
          web_access_url,
        } = res.data.data;
        let esign_balance;
        let kba_balance;
        if (service_usages) {
          service_usages.map((e) => {
            if (e.service_type && e.service_type.toLowerCase() === 'esign') {
              esign_balance =
                e.unit_purchased - (e.final_used + e.in_process_used);
            } else if (
              e.service_type &&
              e.service_type.toLowerCase() === 'kba'
            ) {
              kba_balance =
                e.unit_purchased - (e.final_used + e.in_process_used);
            }
            return e;
          });
        }
        let alert_message;
        let is_warning = false;
        let low_balance_string = 'low';
        if (esign_balance < 0) {
          low_balance_string = 'negative';
        }
        if (adobe_admin_email === undefined) {
          alert_message =
            'Error: Your firm has not been setup for creating eDocs. Please contact Liscio Support Team.';
        } else if (!adobe_token_exists) {
          alert_message =
            'Error: Your firm has not been setup for creating eDocs. Please contact Liscio Support Team.';
        } else if (
          service_usages === undefined ||
          service_usages.length === 0 ||
          esign_balance === undefined
        ) {
          alert_message =
            'Error: Your firm has not purchased eSignature services. Please contact Liscio Support Team.';
        } else if (esign_balance < default_esign_low_bal) {
          is_warning = true;
          alert_message = `Warning: You have a ${low_balance_string} balance. Please upgrade your ${default_esign_label} balance to avoid stoppage of this feature. <br>${default_esign_label} Balance: ${esign_balance}`;
          if (esign_balance <= -default_esign_limit) {
            is_warning = false;
            alert_message = `Error: You have exceeded your ${default_esign_label} balance.<br> You cannot proceed.<br> Please upgrade your ${default_esign_label} balance to continue this feature.<br>${default_esign_label} Balance: ${esign_balance}`;
          }
        }
        this.setState({
          adobe_admin_email,
          adobe_token_exists,
          adobe_integration_details: res.data.data,
          esign_balance,
          allowed_no_of_edocs: esign_balance + default_esign_limit,
          is_warning,
          default_esign_limit,
          default_esign_low_bal,
          alert_message,
          web_access_url,
        });
      }
    });
  };

  getRecords = () => {
    const {
      pagination,
      sortBy,
      selectedEntityTypes,
      sortOrder,
      searchFieldText,
      selectedRelationshipTypes,
      onlyAccountOwners,
    } = this.state;

    this.setState({ loading: true });
    Axios.post(SEARCH_CONTACTS_FOR_BULKEDOC, {
      page: pagination,
      sort_by: sortOrder,
      field: sortBy,
      entity_type: selectedEntityTypes,
      relationship_type: selectedRelationshipTypes,
      keyword: searchFieldText.toLowerCase(),
      only_account_owners: onlyAccountOwners,
    }).then((res) => {
      this.setState({ loading: false });
      if (res.data.status === 200) {
        let listData = res.data.data.data;
        const { selectedData } = this.state;
        listData = updateListData(listData, selectedData);
        this.setState(
          {
            listData: res.data.data.data,
            paginationstats: res.data.data,
            showresults: true,
            selectedAll: false,
            offset: getOffSetsAndLimit(res.data.data.total_entries, 25)[
              this.state.pagination - 1
            ],
            totalPages: Number(Math.ceil(res.data.data.total_entries / 25)),
            totalRecords: res.data.data.total_entries,
          },
          () => this.areAllContactsSelected(),
        );
      } else {
        this.setState({
          listData: null,
          totalRecords: 0,
        });
      }
    });
  };

  areAllContactsSelected() {
    let x = 0;
    const { listData } = this.state;
    listData.map((e) => {
      if (e.checked) x += 1;
      return e;
    });
    const checkbox = document.getElementById('selectallcheckbox');
    if (x === listData.length) {
      this.setState({ selectedAll: true });
      if (checkbox) checkbox.indeterminate = false;
    } else {
      this.setState({ selectedAll: false });
      if (checkbox) checkbox.indeterminate = true;
    }

    if (x === 0) {
      if (checkbox) checkbox.indeterminate = '';
    }
  }

  clearAll = () => {
    this.setState({
      entityTypes: [...getCpaPreferences().entity_type],
      relationshipTypes: [...getCpaPreferences().contact_type],
      searchFieldText: '',
      areEntityTypesSelected: false,
      selectedRelationshipTypes: [],
      selectedEntityTypes: [],
      onlyAccountOwners: false,
    });
  };

  handleInputField = (input) => {
    this.setState({ searchFieldText: input.target.value });
  };

  handleCancelBtn = () => {
    this.setState({
      entityTypes: [...getCpaPreferences().entity_type],
      relationshipTypes: [...getCpaPreferences().contact_type],
      selectedEntityTypes: [],
      selectedRelationshipTypes: [],
      searchFieldText: '',
      sortBy: 'last_name',
      sortOrder: 'asc',
      pagination: 1,
      listData: [],
      selectedData: [],
      totalPages: 0,
      totalRecords: 0,
      showOnlySelectedData: false,
      selectedAll: false,
      areEntityTypesSelected: false,
      disableSendBtn: false,
      onlyAccountOwners: false,
    });
  };

  handleFilterCheckbox = (input, index, crossclick = false) => {
    const modifiedIndex = index - 1;
    const { entityTypes, areEntityTypesSelected } = this.state;
    const selectedEntityTypes = [];
    if (modifiedIndex > -1 || crossclick) {
      if (crossclick) {
        const object = entityTypes.find((t) => t.key === input);
        const indexx = entityTypes.indexOf(object);
        entityTypes[indexx] = {
          ...entityTypes[indexx],
          checked: !entityTypes[indexx].checked,
        };
      } else {
        entityTypes[modifiedIndex] = {
          ...entityTypes[modifiedIndex],
          checked: !entityTypes[modifiedIndex].checked,
        };
      }
      entityTypes.map((e) => {
        if (e.checked) {
          selectedEntityTypes.push(e.key);
        }
        return e.key;
      });
    } else {
      entityTypes.map((e) => {
        const each = e;
        if (!areEntityTypesSelected) {
          each.checked = true;
          selectedEntityTypes.push(e.key);
        } else {
          each.checked = false;
        }
        return each;
      });
    }
    this.setState({
      entityTypes,
      selectedEntityTypes,
      areEntityTypesSelected: selectedEntityTypes.length === entityTypes.length,
    });
  };

  handleSelectAll = () => {
    const { listData, selectedAll, allowed_no_of_edocs } = this.state;
    let { selectedData } = this.state;
    const maxCount =
      this.state.maxCount === 0 ? 1000000 : parseInt(this.state.maxCount, 10);
    let isAllChecked = 0;
    let checked = false;
    listData.map((each) => {
      if (each.checked) {
        isAllChecked += 1;
      }
      return each;
    });
    if (!selectedAll && isAllChecked === 0) {
      checked = true;
    }
    if (
      (selectedData.length === maxCount ||
        selectedData.length === allowed_no_of_edocs) &&
      checked
    ) {
      this.setState({ showModal: true });
    } else {
      let count = selectedData.length;
      listData.map((each) => {
        const eachone = each;
        count += 1;
        if (count <= maxCount && count <= allowed_no_of_edocs && checked) {
          eachone.checked = checked;
          selectedData.push(eachone);
        } else if (!checked) {
          eachone.checked = checked;
          selectedData = removeContact(selectedData, eachone);
        }
        return eachone;
      });
      this.setState(
        {
          listData,
          selectedData,
        },
        () => this.areAllContactsSelected(),
      );
    }
  };

  handleSortBy = (sortBy) => {
    let { sortOrder } = this.state;
    const { selectedData, showOnlySelectedData } = this.state;
    if (this.state.sortBy === sortBy) {
      if (sortOrder === 'asc') {
        sortOrder = 'desc';
      } else {
        sortOrder = 'asc';
      }
    } else {
      sortOrder = 'asc';
    }

    if (showOnlySelectedData) {
      this.setState({
        sortOrder,
        sortBy,
        selectedData: sortSelectedList(selectedData, sortBy, sortOrder),
      });
    } else {
      this.setState(
        {
          sortOrder,
          sortBy,
          loading: true,
        },
        () => {
          this.getRecords();
        },
      );
    }
  };

  handleDelete = (key, data) => {
    let { selectedData, listData } = this.state;
    const eachOne = data;
    eachOne.checked = false;
    selectedData = removeContact(selectedData, eachOne);
    listData = updateListData(listData, selectedData);
    this.setState(
      {
        selectedData,
        showOnlySelectedData: selectedData.length,
        listData,
      },
      () => {
        this.updatePaginationDetails(
          selectedData.length < 26 ? 1 : this.state.pagination,
        );
        if (!selectedData.length) {
          this.getRecords();
        }
      },
    );
  };

  handleCheckbox = (index) => {
    const { listData, allowed_no_of_edocs } = this.state;
    let { selectedData } = this.state;
    const maxCount =
      this.state.maxCount === 0 ? 1000000 : parseInt(this.state.maxCount, 10);
    if (
      selectedData.length >= maxCount &&
      (listData[index].checked === undefined ||
        listData[index].checked === false)
    ) {
      this.setState({ showModal: true });
    } else if (
      selectedData.length >= allowed_no_of_edocs &&
      (listData[index].checked === undefined ||
        listData[index].checked === false)
    ) {
      this.setState({ showModal: true });
    } else {
      if (listData[index].checked) {
        listData[index].checked = false;
      } else {
        listData[index].checked = true;
      }
      if (listData[index].checked) {
        selectedData.push(listData[index]);
      } else {
        selectedData = removeContact(selectedData, listData[index]);
      }
      this.setState(
        {
          listData,
          selectedData,
        },
        () => this.areAllContactsSelected(),
      );
      if (
        (this.state.showerrormessgaefornextbtn ||
          this.state.showOnlySelectedData) &&
        !this.isAtleastOneContactSelected()
      ) {
        this.setState({ showOnlySelectedData: false });
      } else {
        this.setState({ showerrormessgaefornextbtn: false });
      }
    }
  };

  handlePageChange = (input) => {
    if (this.state.showOnlySelectedData) {
      this.updatePaginationDetails(input);
    } else {
      this.setState(
        {
          pagination: input,
          loading: true,
        },
        () => this.getRecords(),
      );
    }
  };

  sendBulkEdocs = () => {
    this.setState({ disableSendBtn: true });
    const { datatosend, selectedData } = this.state;
    const { selectedTemplates, agreement_name, description } = datatosend;
    const signers = [];
    selectedData.map((e) => {
      if (e && e.contact) {
        signers.push({
          id: e.contact.id,
          account_id:
            e.contact.account_id !== undefined && e.contact.account_id !== null
              ? e.contact.account_id
              : '',
        });
      }
      return e;
    });
    const documents = [];
    selectedTemplates.map((e) => {
      if (e.id) {
        documents.push(e.id);
      }
      return e;
    });
    const request = Axios.post(CREATE_BULK_EDOC_REQUESTS, {
      agreement_name,
      signers,
      description,
      documents,
    });
    request.then((res) => {
      if (res.status === 200 && res.data.status === 200) {
        AlertMessage('success', res.data.message, 2000);
        this.handleCancelBtn();
        this.getIntegrationStatus();
      } else {
        AlertMessage('error', res.data.message, 2000);
      }
      // sendMixpanelEvent('SendBulkEsign', {
      //   message: res.data && res.data.message ? res.data.message : null,
      // });
    });
  };

  showDocumentSectionFn = (input) => {
    if (this.state.selectedData.length) {
      this.setState(
        {
          showOnlySelectedData: false,
          showDocumentSection: input,
        },
        () => this.areAllContactsSelected(),
      );
    } else {
      AlertMessage(
        'error',
        'Please select atleast one contact to continue',
        3000,
      );
    }
  };

  showSelectedData = (input, datatosend) => {
    if (this.state.selectedData.length) {
      this.setState(
        {
          showOnlySelectedData: input,
          showDocumentSection: !input,
          datatosend,
        },
        () => this.updatePaginationDetails(1),
      );
    } else {
      AlertMessage(
        'error',
        'Please select atleast one contact to continue',
        3000,
      );
    }
    if (!input) {
      this.setState(
        {
          sortBy: 'last_name',
          sortOrder: 'asc',
          loading: true,
        },
        () => this.getRecords(),
      );
    }
  };

  updatePaginationDetails = (pagination) => {
    let pageNumber = pagination;
    const { showOnlySelectedData, listData, selectedData } = this.state;
    const total_entries = showOnlySelectedData
      ? selectedData.length
      : listData.length;
    if (Number(Math.ceil(total_entries / 25)) < pagination) {
      pageNumber -= 1;
    }
    this.setState({
      offset: getOffSetsAndLimit(total_entries, 25)[pageNumber - 1],
      totalPages: Number(Math.ceil(total_entries / 25)),
      totalRecords: total_entries,
      pagination: pageNumber,
    });
  };

  searchKeyPress = (e) => {
    if (e.charCode === 13) {
      this.hanldeSearchBtn();
    }
  };

  hanldeSearchBtn = () => {
    this.setState(
      {
        selectedData: [],
        pagination: 1,
      },
      () => {
        this.getRecords();
      },
    );
  };

  handleRelationship = (input, index, crossclick = false) => {
    const modifiedIndex = index;
    const { relationshipTypes, arerelationshipTypesSelected } = this.state;
    const selectedRelationshipTypes = [];
    if (modifiedIndex > -1 || crossclick) {
      if (crossclick) {
        const object = relationshipTypes.find((t) => t.key === input);
        const indexx = relationshipTypes.indexOf(object);
        relationshipTypes[indexx] = {
          ...relationshipTypes[indexx],
          checked: !relationshipTypes[indexx].checked,
        };
      } else {
        relationshipTypes[modifiedIndex] = {
          ...relationshipTypes[modifiedIndex],
          checked: !relationshipTypes[modifiedIndex].checked,
        };
      }
      relationshipTypes.map((e) => {
        if (e.checked) {
          selectedRelationshipTypes.push(e.key);
        }
        return e.key;
      });
    } else {
      relationshipTypes.map((e) => {
        const each = e;
        if (!arerelationshipTypesSelected) {
          each.checked = true;
          selectedRelationshipTypes.push(e.key);
        } else {
          each.checked = false;
        }
        return each;
      });
    }
    this.setState({
      relationshipTypes,
      selectedRelationshipTypes,
      arerelationshipTypesSelected:
        selectedRelationshipTypes.length === relationshipTypes.length,
    });
  };

  handleAccountOwnerCheckbox = () => {
    this.setState({ onlyAccountOwners: !this.state.onlyAccountOwners });
  };

  render() {
    const {
      entityTypes,
      searchFieldText,
      listData,
      selectedEntityTypes,
      sortBy,
      sortOrder,
      offset,
      selectedAll,
      showModal,
      showOnlySelectedData,
      selectedData,
      maxCount,
      areEntityTypesSelected,
      showDocumentSection,
      disableSendBtn,
      esign_balance,
      alert_message,
      is_warning,
      allowed_no_of_edocs,
      default_esign_low_bal,
      default_esign_limit,
      relationshipTypes,
      selectedRelationshipTypes,
      onlyAccountOwners,
    } = this.state;
    return (
      <div id="BulkEsign">
        <header>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">Bulk Action</li>
            <li className="breadcrumb-item">Send EDOCS</li>
          </ul>
        </header>
        <div className="center-wrapper">
          <section>
            {this.state.loading ? <LoadingOverlay /> : null}
            {alert_message && (
              <div
                className={
                  is_warning
                    ? 'alert alert-warning alert-dismissible align-items-center pl-3 pr-1 pt-2 pb-2'
                    : 'alert alert-danger alert-dismissible align-items-center pl-3 pr-1 pt-2 pb-2'
                }
                role="alert"
              >
                <Interweave allowAttributes content={alert_message} />
                <button
                  type="button"
                  className="btn-sm btn--onlyicon btn-link"
                  aria-label="Close Alert"
                  onClick={() => this.setState({ alert_message: undefined })}
                >
                  <i className="icon-close2" aria-hidden="true" />
                </button>
              </div>
            )}
            {!showOnlySelectedData &&
            !showDocumentSection &&
            allowed_no_of_edocs > 0 ? (
              <SearchSection
                maxCount={maxCount}
                edocBalance={esign_balance}
                edocBalanceBoxColor={edocBalanceBoxColor(
                  esign_balance - selectedData.length,
                  default_esign_limit,
                  default_esign_low_bal,
                )}
                relationshipTypes={relationshipTypes}
                selectedEntityTypes={selectedEntityTypes}
                selectedRelationshipTypes={selectedRelationshipTypes}
                searchFieldText={searchFieldText}
                entityTypes={[
                  {
                    value: 'Select All',
                    label: 'Select All',
                    checked: areEntityTypesSelected,
                  },
                ].concat(entityTypes)}
                selectedData={selectedData}
                handleInputField={this.handleInputField}
                hanldeSearchBtn={this.hanldeSearchBtn}
                handleFilterCheckbox={this.handleFilterCheckbox}
                handleRelationship={this.handleRelationship}
                uniquekey={(input) => input.contact.id}
                clearAll={this.clearAll}
                searchKeyPress={this.searchKeyPress}
                handleAccountOwnerCheckbox={this.handleAccountOwnerCheckbox}
                onlyAccountOwners={onlyAccountOwners}
                placeHolder="Search Contact"
              />
            ) : null}
            {showOnlySelectedData ? (
              <div className="pb-4 section-title section-title--fullwidth">
                <div className="col-container">
                  <div className="col">
                    <h5 className="text-small">
                      eDoc will be sent to the following contacts:
                    </h5>
                  </div>
                </div>
              </div>
            ) : null}
            {listData === undefined || listData === null ? <NoRecords /> : null}
            {listData &&
            listData !== null &&
            this.state.totalRecords &&
            !showDocumentSection ? (
              <SearchList
                tableHeaders={
                  showOnlySelectedData
                    ? bulkLastPageMessageTableHeaders
                    : bulkMessageTableHeaders
                }
                listData={
                  showOnlySelectedData
                    ? [...selectedData.slice(offset[0] - 1, offset[1])]
                    : listData
                }
                sortBy={sortBy}
                sortOrder={sortOrder}
                handleSortBy={this.handleSortBy}
                handleCheckbox={
                  !showOnlySelectedData ? this.handleCheckbox : () => {}
                }
                handleSelectAll={this.handleSelectAll}
                selectedAll={selectedAll}
                showCheckbox={!showOnlySelectedData}
                showDeleteIcon={showOnlySelectedData}
                handleDelete={this.handleDelete}
                showActionDiv={showOnlySelectedData}
              />
            ) : null}
            {listData &&
            listData !== null &&
            this.state.totalRecords &&
            !showDocumentSection ? (
              <Pagination
                currentPage={this.state.pagination}
                totalPages={this.state.totalPages}
                totalRecords={this.state.totalRecords}
                handlePageChange={this.handlePageChange}
                handleSelectAll={this.handleSelectAll}
                selectedAll={selectedAll}
                offset={offset[0]}
                off={offset[1]}
              />
            ) : null}
            <EsignSection
              showThis={showDocumentSection}
              showDocumentSectionFn={this.showDocumentSectionFn}
              showSelectedData={this.showSelectedData}
            />
            {listData &&
            listData !== null &&
            this.state.totalRecords &&
            !showOnlySelectedData &&
            !showDocumentSection ? (
              <div className="formbtn BulkEsignButtonGroup">
                <div className="btn-wrap btn--leftspace">
                  <button
                    type="button"
                    className="btn btn-outline-light"
                    onClick={this.handleCancelBtn}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => this.showDocumentSectionFn(true)}
                  >
                    Next Step
                  </button>
                </div>
              </div>
            ) : null}
            {showOnlySelectedData ? (
              <div className="formbtn BulkEsignButtonGroup">
                <div className="btn-wrap btn--leftspace">
                  <button
                    type="button"
                    className="btn btn-outline-light"
                    onClick={() => this.showSelectedData(false)}
                  >
                    Back
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={this.sendBulkEdocs}
                    disabled={disableSendBtn}
                  >
                    Send eDocs
                  </button>
                </div>
              </div>
            ) : null}
          </section>
        </div>
        {showModal ? (
          <ConfirmationModal
            isOpen={showModal}
            isHtml={allowed_no_of_edocs < maxCount}
            messageText={getAlertModalMessages(maxCount, allowed_no_of_edocs)}
            noBtnClick={() => {
              this.setState({ showModal: false });
            }}
            noBtnText="Close"
          />
        ) : null}
      </div>
    );
  }
}

export default withStorageData(BulkEsign);
