import React from 'react';
import styled from '@emotion/styled';
import InvoiceRecurringSection from './InvoiceRecurringSection';

const StyledDiv = styled.div`
  font-family: 'Source Sans Pro', sans-serif;
  .dropdown-menu {
    left: -76px;
  }
  .dropdown-item::before {
    left: 120px !important;
  }

  .MuiIconButton-root {
    .MuiSvgIcon-root {
      width: 18px;
      height: 18px;
    }
  }

  .MuiTypography-root {
    font-size: 14px !important;
  }

  .RecurringSection .RecurringSection_EndLabel {
    margin: 10px 0 0 0;
    color: #212b36;
  }

  .RecurringSection__RadioGroup {
    label {
      margin: 0;
    }
  }

  .MuiButtonBase-root.Mui-checked {
    color: #00aecc;
  }

  .MonthContainer,
  .YearContainer {
    color: black;
    margin-top: 10px;
  }

  .dropdown-item {
    h5 {
      color: #212b36;
    }
  }

  .RecurringEndDate__On input {
    width: 120px;
  }
`;

const RecurringDropdown = ({
  handleDateChange,
  getSelectedRadioValue,
  recurringStartDate,
  changeRecurringDay,
  onSelectedDay,
  isRecurring,
  nextChargeDate,
  childInvoicesCount,
  recurringEndDate,
  recurringInterval,
  mode,
}) => (
  <StyledDiv className="InvoiceRecurringSection">
    <InvoiceRecurringSection
      is_recurring={isRecurring}
      updateIsRecurring={() => null}
      stateChange={() => null}
      propData={{ viewContext: 'center' }}
      handleDateChange={handleDateChange}
      initialDate={new Date()}
      taskDetail={null}
      getSelectedRadioValue={getSelectedRadioValue}
      recurringStartDate={recurringStartDate}
      changeRecurringDay={changeRecurringDay}
      onSelectedDay={onSelectedDay}
      nextChargeDate={nextChargeDate}
      childInvoicesCount={childInvoicesCount}
      recurringEndDate={recurringEndDate}
      recurringInterval={recurringInterval}
      mode={mode}
    />
  </StyledDiv>
);

export default RecurringDropdown;
