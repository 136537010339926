/* eslint-disable */
import React, { Component } from 'react';
import { withRouter } from 'utilities/withRouterWrapper';
import { Modal, ModalBody } from 'reactstrap';
import PdfViewer from 'components/PdfViewer/PdfViewer';

const noneStyle = { display: 'none' };
const blockStyle = { display: 'block' };
const isIE = /* @cc_on!@ */false || !!document.documentMode;

class SignDocumentModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      refreshPage: false
    };
  }
  componentDidMount() {
    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 5000);
    window.addEventListener('keypress', (e) => {
      if (e && e.which === 13) {
        e.preventDefault();
      }
    });
    if (!window.addEventListener) {
      window.attachEvent('onmessage', this.eventHandler);
    } else {
      window.addEventListener('message', this.eventHandler, false);
    }
  }
  componentWillUnmount() {
    window.addEventListener('keypress', () => true);
    window.removeEventListener('message', this.eventHandler, false);
  }
  eventHandler = (e) => {
    if (e.origin.includes('echosign.com')) {
      const logdata = JSON.parse(e.data);
      console.log(logdata)
      if (logdata.type === 'ESIGN' || logdata.type === 'REJECT') {
        navigate('/all_tasks');
      } else if (logdata.type === 'SESSION_TIMEOUT' && logdata.data.message === 'POST_SESSION_TIMEOUT') {
        this.handleSessionOut();
      }
    }
    if (this.state.loading) {
      this.setState({ loading: false });
    }
  }
  handleSessionOut = () => {
    setTimeout(() => {
      console.log('hello')
      this.setState({
        refreshPage: true,
      }, () => {
        this.setState({
          refreshPage: false,
        })
      })
    }, 10000)
  }
  render() {
    return (
      <div>
        <div id="loading" style={(this.state.loading) ? blockStyle : noneStyle} />
        <Modal
          isOpen={this.props.isOpen}
          className="customModal customModal--alert fullModal customModal--nopadding Modal-Esign">
          <div className="ModalHeader">
            <button type="button" className="close" aria-label="Close" onClick={this.props.noBtnClick}><i className="icon-close2" aria-hidden="true" /></button>
          </div>
          {!this.state.refreshPage ?
            <ModalBody className="modalContent">
              <div className="modalContent--inner">
                <div className={isIE ? 'left-inner-content' : 'sample-preview'}>
                  <div className="letter-logo" />
                  {isIE ?
                    <PdfViewer file={this.props.signingUrl} /> :
                    <iframe
                      width="100%"
                      height="600"
                      id="signingiframe"
                      name="myfilename"
                      src={this.props.signingUrl}
                      title="selected attachment"
                    />
                  }
                </div>
              </div>
            </ModalBody>
            :
            null}
        </Modal>
      </div>
    );
  }
}

export default withRouter(SignDocumentModal);
