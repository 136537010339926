import React from 'react';
import { Breadcrumbs as MUIBreadcrumbs } from '@mui/material';
import { Link } from 'react-router-dom-latest';

export type BreadcrumbType = {
  label: React.ReactNode;
  path: string;
};
export interface BreadcrumbsProps {
  breadcrumbs: BreadcrumbType[];
}

export const BreadCrumbs = ({ breadcrumbs }: BreadcrumbsProps) => (
  <MUIBreadcrumbs classes={{ ol: 'breadcrumb', li: 'breadcrumb-item' }}>
    {breadcrumbs.map(({ label, path }, idx) =>
      idx + 1 === breadcrumbs.length ? (
        <span>{label}</span>
      ) : (
        <Link to={path}>{label}</Link>
      ),
    )}
  </MUIBreadcrumbs>
);
