import { Stack, Typography } from '@mui/material';

type FormRowProps = {
  label: string;
  value: string | null | undefined;
};

/**
 * Used to simplify the rendering of Form Rows in the
 * change due date hook dialog.
 */
export const FormRow = ({ label, value }: FormRowProps) => (
  <Stack direction="row" gap={1}>
    <Typography fontWeight="bold">{label}:</Typography>
    <Typography variant="body1">{value}</Typography>
  </Stack>
);
