/* eslint-disable react/no-unused-prop-types */
import {
  Button,
  Paper,
  Stack,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import { TOAST_FAILURE } from 'constants/constants';
import { BillingCycle, LicenseTier } from 'gql/graphql';
import { useUpdateFirmLicense } from 'hooks/admin-billing-hooks';
import CornerRibbon from 'react-corner-ribbon';
import { AlertMessage } from 'utilities/utils';

interface Props {
  popular?: boolean;
  tierTitle: string;
  monthly?: boolean;
  price: string;
}

const PopularLayout = styled(Paper)(({ theme }) => ({
  position: 'relative',
  borderRadius: '0.5rem',
  height: '385px',
  width: '340px',
  backgroundColor: theme.palette.common.primary[50],
  padding: '40px 20px',
}));

const StandardLayout = styled(Paper)({
  position: 'relative',
  borderRadius: '0.5rem',
  height: '327px',
  width: '340px',
  padding: '40px 20px',
});

const StandardButton = styled(Button)(({ theme }) => ({
  borderColor: `${theme.palette.common.primary[600]}`,
  color: `${theme.palette.common.primary[600]}`,
  fontWeight: 'bold',
}));

type MutationVariables = {
  licenseTier: LicenseTier;
  billingCycle: BillingCycle;
};

// Helper component
const CardBody = ({ monthly, tierTitle, price, popular }: Props) => {
  const { palette } = useTheme();
  const { mutate: updateFirmLicense } = useUpdateFirmLicense();

  const handleUpgradeClick = () => {
    const variables: MutationVariables = {
      licenseTier: LicenseTier.Pro,
      billingCycle: BillingCycle.Monthly,
    };

    if (!monthly) {
      variables.billingCycle = BillingCycle.Annually;
      if (tierTitle.includes('2'))
        variables.billingCycle = BillingCycle.Biennially;
    }

    updateFirmLicense(variables, {
      onSuccess: (response) => {
        if (
          response?.updateFirmLicense?.errors &&
          response?.updateFirmLicense?.errors.length > 0
        ) {
          AlertMessage(
            TOAST_FAILURE,
            response.updateFirmLicense.errors[0].message,
          );
        } else {
          console.debug({ response: response?.updateFirmLicense });
          window.location.reload();
        }
      },
    });
  };

  return (
    <Stack alignItems="center" gap={6}>
      <Stack alignItems="center" gap={0}>
        <Typography variant="h4" color={palette.common.secondary[500]}>
          {monthly ? 'Monthly' : 'Annually'}
        </Typography>
        <Typography variant="h2" sx={{ fontWeight: 'bold' }}>
          {tierTitle}
        </Typography>
      </Stack>
      <Stack alignItems="center" gap={0}>
        <Typography
          sx={{
            fontSize: `${popular ? '5.3rem' : '3.3rem'}`,
            fontWeight: 'bold',
          }}
        >
          ${price}
        </Typography>
        <Typography variant="h4" color={palette.common.secondary[500]}>
          per month, per user
        </Typography>
      </Stack>
      {popular ? (
        <Button
          variant="contained"
          sx={{ fontWeight: 'bold' }}
          onClick={handleUpgradeClick}
        >
          Upgrade Now
        </Button>
      ) : (
        <StandardButton variant="outlined" onClick={handleUpgradeClick}>
          Upgrade Now
        </StandardButton>
      )}
    </Stack>
  );
};

/**
 * Renderer for displaying the Upgrade Cards when the Firm is
 * in the Core Tier.
 */
export default function UpgradeCard({
  popular = false,
  tierTitle,
  monthly = false,
  price,
}: Props) {
  if (popular) {
    return (
      <PopularLayout elevation={10}>
        <CornerRibbon position="top-left" backgroundColor="#00AECC">
          <Typography variant="body1" fontWeight="bold" color="white">
            MOST POPULAR
          </Typography>
        </CornerRibbon>
        <CardBody
          monthly={monthly}
          tierTitle={tierTitle}
          price={price}
          popular
        />
      </PopularLayout>
    );
  }

  return (
    <StandardLayout elevation={10}>
      <CardBody monthly={monthly} tierTitle={tierTitle} price={price} />
    </StandardLayout>
  );
}
