import axios from 'axios';
import Moment from 'moment';
import _ from 'lodash';
import { AlertMessage, getSiteHostname, getStorageData } from 'utilities/utils';
import {
  GET_PROFILE_DATA_API_PATH,
  UPDATE_PROFILE_API_PATH,
  PROFILE_CHANGE_PASSWORD_API_PATH,
  CHECK_CURRENT_PASSWORD_API_PATH,
  UPDATE_PROFILE_AVATAR,
  REMOVE_PROFILE_PIC,
  LOGOUT_FROM_DEVICE,
} from 'constants/constants';

class UserProfileDetail {
  static getUserProfile(component, fro = '') {
    component.setState({ loading: true });
    const url = fro === 'security' ? '?security=true' : '';
    const request = axios.get(GET_PROFILE_DATA_API_PATH + url);
    request
      .then((response) => {
        component.setState({ loading: false });
        if (response.data.status === 200) {
          if (response.data.data) {
            component.setState({
              firstName: response.data.data.first_name,
              lastName: response.data.data.last_name,
              middleName: response.data.data.middle_name,
              title: response.data.data.title,
              email: response.data.data.email,
              phone: response.data.data.phone,
              role: response.data.data.role,
              avatar: response.data.data.avatar,
              isSigningAuthority: response.data.data.is_signing_authority,
              titleDefault: response.data.data.title,
              phoneDefault: response.data.data.phone,
              firstNameDefault: response.data.data.first_name,
              middleNameDefault: response.data.data.middle_name,
              lastNameDefault: response.data.data.last_name,
              is_texting_allowed: response.data.data.is_texting_allowed,
              is_texting_allowedDefault: response.data.data.is_texting_allowed,
              has_user_unsubscribed: response.data.data.has_user_unsubscribed,
              mfaEnabled: response.data.data.two_factor_enabled,
            });
          }
          if (
            response.data.security.length &&
            response.data.security.length > 0
          ) {
            const sortedDeviceList = _.sortBy(
              response.data.security,
              (item) => new Moment(item.time_stamp),
            ).reverse();
            component.setState({ deviceList: sortedDeviceList });
          }
        } else {
          component.setState({ loading: false });
          AlertMessage('error', response.data.message, 3000);
        }
      })
      .catch((err) => {
        component.setState({ loading: false });
        AlertMessage('error', err, 3000);
      });
  }

  static updateUserProfile(mydata, component) {
    console.log(component.props);
    component.setState({ loading: true });
    const request = axios.post(UPDATE_PROFILE_API_PATH, mydata);
    request
      .then((response) => {
        if (response.data.status === 200) {
          component.setState({
            loading: false,
            isChanged: false,
            editMode: false,
            isSubmit: true,
            updatedPhone: mydata.phone,
            updatedTitle: mydata.title,
            updatedFirstName: mydata.first_name,
            updatedMiddleName: mydata.middle_name,
            updatedLastName: mydata.last_name,
            is_texting_allowedDefault: mydata.is_texting_allowed,
          });
          // TODO: __HOMEDATA-AUDIT
          const local_data = getStorageData();
          local_data.uname = `${mydata.first_name} ${mydata.last_name}`;
          localStorage.setItem(
            `${getSiteHostname()} data`,
            JSON.stringify(local_data),
          );
          component.props.HomeActions.fetchUserName(
            `${mydata.first_name} ${mydata.last_name}`,
          );
          AlertMessage('success', 'User Profile Updated', 3000);
        } else {
          component.setState({ loading: false });
          AlertMessage('error', response.data.message, 3000);
        }
      })
      .catch((err) => {
        component.setState({ loading: false });
        AlertMessage('error', err, 3000);
      });
  }

  static updateUserAvatar(mydata, component) {
    component.setState({ loading: true });
    const request = axios.post(UPDATE_PROFILE_AVATAR, mydata);
    request
      .then((response) => {
        if (response.data.status === 200) {
          component.setState({
            loading: false,
            avatar: response.data.avatar,
            isChanged: false,
            imageUploaded: false,
            uploadpicModal: false,
            filetype: '',
            img: null,
            croppedImg: '',
            range: 0,
          });
          // TODO: __HOMEDATA-AUDIT
          const local_data = getStorageData();
          if (response.data.avatar) {
            local_data.avatar = response.data.avatar;
            localStorage.setItem(
              `${getSiteHostname()} data`,
              JSON.stringify(local_data),
            );
            component.props.HomeActions.fetchAvatar(response.data.avatar);
          }
          AlertMessage('success', 'User Profile Picture Updated', 3000);
        } else {
          component.setState({ loading: false });
          AlertMessage('error', response.data.message, 3000);
        }
      })
      .catch((err) => {
        component.setState({ loading: false });
        AlertMessage('error', err, 3000);
      });
  }

  static removeUserAvatar(mydata, component) {
    component.setState({ loading: true });
    const request = axios.post(REMOVE_PROFILE_PIC, mydata);
    request
      .then((response) => {
        if (response.data.status === 200) {
          component.setState({
            avatar: '',
            loading: false,
            isChanged: false,
            imageUploaded: false,
            img: null,
            filetype: '',
            uploadpicModal: false,
            clearpicModal: false,
            replaceAvatarDiv: false,
          });
          // TODO: __HOMEDATA-AUDIT
          const local_data = getStorageData();
          local_data.avatar = '';
          localStorage.setItem(
            `${getSiteHostname()} data`,
            JSON.stringify(local_data),
          );
          component.props.HomeActions.fetchAvatar('');
          AlertMessage('success', response.data.message, 3000);
        } else {
          component.setState({ loading: false });
          AlertMessage('error', response.data.message, 3000);
        }
      })
      .catch((err) => {
        component.setState({ loading: false });
        AlertMessage('error', err, 3000);
      });
  }

  static checkCurrentPassword(mydata, component) {
    const request = axios.post(CHECK_CURRENT_PASSWORD_API_PATH, mydata);
    request
      .then((response) => {
        const errs = component.state.errors;
        if (response.data.status === 200) {
          errs.oldPassword = '';
          component.setState({ isPasswordValid: true });
        } else {
          component.setState({ isPasswordValid: false });
          errs.oldPassword = 'Password does not match with current password';
        }
        component.setState({ errors: errs });
      })
      .catch((err) => {
        component.setState({ isPasswordValid: false });
        console.log(err);
      });
  }

  static profileChangePassword(mydata, component) {
    component.setState({ loading: true });
    const request = axios.post(PROFILE_CHANGE_PASSWORD_API_PATH, mydata);
    request
      .then((response) => {
        if (response.data.status === 200) {
          component.setState({
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
            isPasswordValid: false,
            loading: false,
            isChanged: false,
            editMode: false,
            changePasswordMode: false,
          });
          AlertMessage('success', response.data.message, 3000);
        } else if (response.data.status === 601) {
          // do nothing
        } else {
          component.setState({ loading: false });
          AlertMessage('error', response.data.message, 3000);
        }
      })
      .catch((err) => {
        component.setState({
          isPasswordValid: false,
          oldPassword: '',
          newPassword: '',
          confirmPassword: '',
          loading: false,
          isChanged: false,
        });
        AlertMessage('error', err, 3000);
      });
  }

  static logOutFromDevice(component, id, fro = '') {
    component.setState({ loading: true });
    let url = '';
    url = `${LOGOUT_FROM_DEVICE}/${id}`;
    const request = axios.put(url);
    request
      .then((response) => {
        if (response.data.status === 200) {
          component.setState(
            {
              loading: false,
              signOutDeviceConfirm: false,
              signOutDeviceId: '',
              signOutDeviceName: '',
            },
            () => {
              if (fro !== 'devicedetail') {
                this.getUserProfile(component, 'security');
              }
            },
          );
          AlertMessage('success', response.data.message, 3000);
        } else {
          component.setState({
            loading: false,
            signOutDeviceConfirm: false,
            signOutDeviceId: '',
            signOutDeviceName: '',
          });
          AlertMessage('error', response.data.message, 3000);
        }
      })
      .catch(() => {
        component.setState({
          loading: false,
          signOutDeviceConfirm: false,
          signOutDeviceId: '',
          signOutDeviceName: '',
        });
        AlertMessage('error', 'Some error occurred. Please try later.', 3000);
        return null;
      });
  }
}
export default UserProfileDetail;
