/* eslint-disable */
import React, { Component } from 'react';
import Axios from 'axios';
import { connect } from 'react-redux';
import { GET_TASK_ACCOUNTS, SEARCH_TASK_ACCOUNTS, GET_ACCOUNTS_FOR_LOGINED_CONTACT } from 'constants/constants';
import { FilterComponent } from 'common/MessageFilters';
import { saveAccountFilterData } from 'actions/FilterDataAction';
import { isUserEmployee } from 'utilities/utils';

class AccountFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
    };
  }

  componentDidMount() {
    const { accountsFilterData } = this.props;
    if (accountsFilterData && accountsFilterData.length) {
      this.setState({
        data: accountsFilterData,
        initialData: accountsFilterData,
      });
    } else if (isUserEmployee()) {
      Axios.get(GET_TASK_ACCOUNTS).then((res) => {
        if (res.data && res.data.accounts) {
          this.setState({
            data: res.data.accounts,
            initialData: res.data.accounts,
          });
          this.props.saveAccountFilterData(res.data.accounts);
        }
      });
    } else {
      Axios.get(GET_ACCOUNTS_FOR_LOGINED_CONTACT).then((res) => {
        if (res.data && res.data.data) {
          this.setState({
            data: res.data.data,
            initialData: res.data.data,
          });
          this.props.saveAccountFilterData(res.data);
        }
      });
    }
  }

  handleSearch = (input) => {
    this.setState(
      {
        searchValue: input ? input.target.value : '',
        loading: true,
      },
      () => {
        clearTimeout(this.typingTime);
        const { searchValue } = this.state;
        if (searchValue && searchValue !== null && searchValue.length) {
          this.typingTime = setTimeout(() => {
            Axios.get(SEARCH_TASK_ACCOUNTS + '/?keyword=' + searchValue).then((response) => {
              if (response.status === 200) {
                this.setState({
                  data: response.data,
                  loading: false,
                });
              } else {
                this.setState({
                  loading: false,
                });
              }
            });
          }, 500);
        } else {
          this.setState({
            data: this.state.initialData,
            loading: false,
          });
        }
      },
    );
  };

  render() {
    const { data, searchValue } = this.state;
    return (
      <FilterComponent
        filterList={data}
        filterName="ACCOUNT"
        recipientSelected={this.props.filterSelected}
        isActive={this.props.isActive}
        recipientInputChange={this.handleSearch}
        searchValue={searchValue}
        clearSearch={() => this.handleSearch('')}
        showFilterOptions={this.props.showFilterOptions}
        setFilterInFocus={this.props.setFilterInFocus}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  accountsFilterData: state.filterDataReducer.accountsFilterData,
});

const mapDispatchToProps = (dispatch) => ({
  saveAccountFilterData: (data) => dispatch(saveAccountFilterData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountFilter);
