import React, { Component } from 'react';
import { isUserEmployee } from 'utilities/utils';
import ContactCard from './ContactCard';
import AccountCard from './AccountCard';

export default class ContactCardParent extends Component {
  constructor(props) {
    super(props);

    this.state = { iD: null };
  }

  someHandler = (input) => {
    const { forWhat } = this.props;
    if (isUserEmployee()) {
      this.setState({ iD: null }, () => {
        if (input) {
          let { id } = input;
          if (forWhat) {
            id = input.relation_data.account_id;
          }
          this.setState({ iD: id });
        }
      });
    }
  };

  render() {
    const { data, forWhat } = this.props;
    const { iD } = this.state;
    if (forWhat && forWhat === 'account') {
      return [
        <button
          type="button"
          key={data.relation_data.account_id}
          data-tip
          data-for={`${data.relation_data.account_id}global_account`}
          onMouseEnter={() => this.someHandler(data)}
        >
          {data.name}
        </button>,
        iD !== null ? (
          <AccountCard
            key={data.name}
            id={iD}
            for={data.relation_data.account_id}
          />
        ) : null,
      ];
    }
    return [
      <button
        type="button"
        key={data.id}
        data-tip
        data-for={`${data.id}global`}
        onMouseEnter={() => this.someHandler(data)}
      >
        {data.label}
      </button>,
      iD !== null ? (
        <ContactCard key={data.name} id={iD} for={data.id} />
      ) : null,
    ];
  }
}
