import ClearButton from 'common/ClearButton';
import { DATE_RANGE } from 'constants/constants';
import React, { useEffect, useState } from 'react';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';

const CustomClearIcon = ({
  toggleCalendarDisplay,
  clearDates,
  resetHidden,
}) => (
  <div className="date-range-clear" id="customDateClear">
    <ClearButton onClickClear={toggleCalendarDisplay} className="icon-small" />
    <ClearButton
      onClickClear={clearDates}
      hidden={resetHidden}
      iconHidden
      className="icon-small"
    >
      <div className="clear-dates">reset</div>
    </ClearButton>
  </div>
);

const DateRangeFilter = ({ selected = {}, onSelectFilter }) => {
  const { start, end } = selected || {};
  const [selectedStartDate, setSelectedStartDate] = useState(start);
  const [selectedEndDate, setSelectedEndDate] = useState(end);
  const [inputFocus, setFocusedInput] = useState(null);
  const [showCalendar, toggleDisplay] = useState(false);
  const isSelected = start && end ? 'active' : '';
  function handleDateChange({ startDate, endDate }) {
    setSelectedStartDate(startDate);
    setSelectedEndDate(endDate);
  }
  function handleFocusChange(focusedInput) {
    setFocusedInput(focusedInput);
  }

  function toggleCalendarDisplay() {
    toggleDisplay(!showCalendar);
  }

  function clearDates() {
    setSelectedStartDate(null);
    setSelectedEndDate(null);
  }

  function checkIsOutSideDateRange() {
    // if (startDate && endDate) {
    //   return !(startDate.isAfter(options.start) && endDate.isBefore(options.end));
    // }
    return false;
  }

  useEffect(() => {
    if (showCalendar) {
      setFocusedInput('startDate');
    }
  }, [showCalendar]);

  useEffect(() => {
    if (!inputFocus) {
      toggleDisplay(false);
    }
  }, [inputFocus]);

  useEffect(() => {
    if (selectedEndDate && selectedStartDate && showCalendar) {
      toggleCalendarDisplay();
    }
  }, [selectedEndDate]);

  useEffect(() => {
    if (selectedStartDate && selectedEndDate) {
      onSelectFilter({
        type: DATE_RANGE,
        value: {
          start: selectedStartDate,
          end: selectedEndDate,
        },
      });
    }
  }, [selectedStartDate, selectedEndDate]);

  return (
    <div className="BillingFilter__DateRange">
      <button
        className={`btn btn-sm btn-outline-light dropdown-toggle ${isSelected}`}
        type="button"
        id="dateRangeFilter"
        onClick={toggleCalendarDisplay}
      >
        {DATE_RANGE}
      </button>
      {showCalendar && (
        <div>
          <DateRangePicker
            startDate={selectedStartDate}
            endDate={selectedEndDate}
            startDateId="startDateId"
            endDateId="endDateId"
            onDatesChange={handleDateChange}
            focusedInput={inputFocus}
            onFocusChange={handleFocusChange}
            displayFormat="MM/DD/YYYY"
            hideKeyboardShortcutsPanel
            isOutsideRange={checkIsOutSideDateRange}
            reopenPickerOnClearDates
          />
          <CustomClearIcon
            toggleCalendarDisplay={toggleCalendarDisplay}
            clearDates={clearDates}
            resetHidden={!selectedStartDate && !selectedEndDate}
          />
        </div>
      )}
    </div>
  );
};

export default DateRangeFilter;
