/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

export const DropdownComponent = (props) => {
  const { onChange, options, id } = props;
  return (
    <div className={`dropdown ${props.className ? props.className : ''}`}>
      <button
        className="btn btn-secondary dropdown-toggle text-capitalize"
        type="button"
        id={id}
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        {/* {React.createElement(selectedOption.icon, ({ value: selectedOption.value }))} */}
        <span className="Select-arrow" />
      </button>
      {options && options.length && (
        <div className="dropdown-menu" aria-labelledby={id}>
          {options.map((e) => (
            <button
              key={e.value}
              className="dropdown-item text-capitalize d-flex"
              type="button"
              onClick={() => onChange(e.value)}
            >
              {React.createElement(e.icon, { value: e.value })}
              {e.label}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export const PhoneNumberField = (props) => {
  const {
    label,
    onBlur,
    error,
    value,
    name,
    onChange,
    placeholder = 'Phone number',
    forSMS = false,
  } = props;

  const inputProps = {
    id: name,
    defaultCountry: 'US',
    international: false,
    placeholder,
    value,
    onBlur,
    onChange: (phone) => onChange(phone, name),
    numberInputProps: { className: 'form-control' },
    countrySelectProps: { className: 'form-control' },
  };

  if (forSMS) {
    inputProps.country = 'US';
    inputProps.countries = ['US'];
    inputProps.addInternationalOption = false;
  }

  return (
    <div className={`${props.className} ${error ? ' form-error' : ''}`}>
      <label>{label || 'Phone Number'}</label>
      <div className="">
        <PhoneInput {...inputProps} />
      </div>
      <span className="error_label">{error}</span>
    </div>
  );
};
