/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { Component } from 'react';
import Axios from 'axios';
import { isUserEmployee } from 'utilities/utils';
import { SEARCH_TASK_CONTACTS } from 'constants/constants';
import MulitpleSelectDropdown from './MulitpleSelectDropdown';
import RenderSelectedRecords from './RenderSelectedRecords';

const filterListData = (allRecords, selectedRecords) => {
  const selectedRecordsIds = [];
  selectedRecords.map((record) => {
    selectedRecordsIds.push(record.value);
    return record;
  });
  const filteredData = [];
  allRecords.map((record) => {
    if (!selectedRecordsIds.includes(record.value)) {
      filteredData.push(record);
    }
    return record;
  });
  return filteredData;
};

export default class RecipientsDropDown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      listData: [],
      loading: true,
      searchFieldText: '',
      dropdownOpen: false,
      selectedRecords: this.props.selectedRecords,
      recordsViewHeight: '40px',
    };
  }

  componentDidMount() {}

  shouldComponentUpdate(nextProps) {
    if (nextProps.selectedRecords.length > this.props.selectedRecords.length) {
      this.updateSelectedRecords(nextProps.selectedRecords);
    }
    return true;
  }

  getRecords = () => {
    const { searchFieldText } = this.state;
    if (searchFieldText && searchFieldText !== null && searchFieldText.length) {
      const value = searchFieldText;
      const message = isUserEmployee() ? '&message=true' : '';
      clearTimeout(this.typingTime);
      this.typingTime = setTimeout(() => {
        const request = Axios.get(
          `${SEARCH_TASK_CONTACTS}/?keyword=${value}${message}`,
        );
        request.then((response) => {
          if (response.status === 200) {
            this.setState({ listData: response.data, loading: false });
          }
        });
      }, 500);
    } else {
      this.setState({ listData: this.props.receipientList });
    }
  };

  handleSearchField = (input) => {
    this.setState({
      searchFieldText: input,
      loading: input && input.length > 2,
      listData: [],
    });
    clearTimeout(this.typingTime);
    this.typingTime = setTimeout(() => {
      this.getRecords();
    }, 500);
  };

  assignAccount = (input) => {
    const { selectedRecords } = this.state;
    this.setState({ selectedRecords: selectedRecords.concat([input]) }, () => {
      this.props.handleChange(this.state.selectedRecords);
    });
  };

  toggleDropdown = (input) => {
    this.setState(
      { dropdownOpen: input },
      () => this.state.dropdownOpen && this.getRecords(),
    );
  };

  changedSelectedViewHeight = (input) => {
    this.setState({ recordsViewHeight: input });
  };

  removeSelectedRecord = (input) => {
    const { selectedRecords } = this.state;
    const filterRecords = selectedRecords.filter(
      (e) => e.value !== input.value,
    );
    this.setState({ selectedRecords: filterRecords }, () => {
      this.props.handleChange(this.state.selectedRecords);
    });
  };

  updateSelectedRecords = (data) => {
    this.setState({ selectedRecords: data });
  };

  render() {
    const {
      listData,
      selectedRecords,
      recordsViewHeight,
      dropdownOpen,
      loading,
    } = this.state;
    const { dropdownFor } = this.props;
    const filterData = filterListData(listData, selectedRecords);
    return (
      <div
        className="cstPopupShow"
        style={{ width: '100%', position: 'relative' }}
      >
        <RenderSelectedRecords
          dropdownFor={dropdownFor}
          data={selectedRecords}
          changedSelectedViewHeight={this.changedSelectedViewHeight}
          handleRemove={this.removeSelectedRecord}
          isFocused={dropdownOpen}
        />
        <MulitpleSelectDropdown
          dropdownFor={dropdownFor}
          data={filterData}
          hasMore={loading}
          recordsViewHeight={recordsViewHeight}
          onClick={this.toggleDropdown}
          getMoreData={this.getRecords}
          handleSearch={this.handleSearchField}
          onSelect={this.assignAccount}
        />
      </div>
    );
  }
}
