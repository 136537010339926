/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from 'react';
import ReactDragListView from 'react-drag-listview';
import { Form, FormGroup, Label } from 'reactstrap';
import { CheckboxField } from 'common/CustomFields';

const SignersList = (props) => {
  const { item, handleCheckboxChange, isSigningOrderenabled, handleRemove } =
    props;
  return (
    <div>
      <Form>
        <div className="row no-gutters">
          <div className="col">
            <FormGroup>
              <Label htmlFor="forEmail">Signer</Label>
              <div className="mb-2 float-right">
                <CheckboxField
                  id="notifyViaText"
                  name={item.contactId}
                  label="KBA"
                  labelclassName="text-body text-transform-none font-weight-normal"
                  value={item.enable_kba}
                  onChange={handleCheckboxChange}
                />
              </div>
              <div className="labelValue customTags mailDrodown">
                <div className="customTags__input form-control">
                  {item.accountId ? (
                    <span
                      className="tag tag--fill"
                      key={item.contactId + item.accountId}
                    >
                      {item.contactName} <i className="divOption" />{' '}
                      {item.accountName}{' '}
                      <i
                        className="icon-close2 remove"
                        onClick={() => handleRemove(item.contactId)}
                      />
                    </span>
                  ) : (
                    <span
                      className="tag tag--fill"
                      key={item.contactId + item.accountId}
                    >
                      {item.contactName}{' '}
                      <i
                        className="icon-close2 remove"
                        onClick={() => handleRemove(item.contactId)}
                      />
                    </span>
                  )}
                </div>
              </div>
            </FormGroup>
          </div>
          <div className="pt-4">
            {isSigningOrderenabled && (
              <button type="button" className="btn btn-link border-0 pr-0">
                <i className="icon-double-arrow pr-0" />
              </button>
            )}
          </div>
        </div>
      </Form>
    </div>
  );
};

class Testing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      list: this.props.selectedRecords,
    };
  }

  handleCheckboxChange = (input, contactId) => {
    const { list } = this.state;
    const signers = [];
    list.map((e) => {
      const eachone = { ...e };
      if (e.contactId === contactId) {
        if (e.enable_kba) {
          eachone.enable_kba = false;
        } else {
          eachone.enable_kba = true;
        }
      }
      signers.push(eachone);
      return e;
    });
    this.setState({
      list: signers,
    });
  };

  handleCancel = () => {
    this.props.handleCancelBtn(false);
  };

  updateList = () => {
    const { list } = this.state;
    this.props.updateList(list);
  };

  handleRemove = (contactId) => {
    const { list } = this.state;
    const signers = [];
    list.map((e) => {
      if (e.contactId !== contactId) {
        signers.push(e);
      }
      return e;
    });
    this.setState({
      list: signers,
    });
  };

  render() {
    const { list } = this.state;
    const { isSigningOrderenabled } = this.props;
    const dragProps = {
      onDragEnd: (fromIndex, toIndex) => {
        const item = list.splice(fromIndex, 1)[0];
        list.splice(toIndex, 0, item);
        this.setState({ list });
      },
      nodeSelector: 'li',
      handleSelector: 'button',
    };
    return (
      <div>
        <ReactDragListView {...dragProps}>
          <ol className="pl-3">
            {list.map((item) => (
              <li key={item.contactId}>
                <SignersList
                  item={item}
                  isSigningOrderenabled={isSigningOrderenabled}
                  handleCheckboxChange={this.handleCheckboxChange}
                  handleRemove={this.handleRemove}
                />
              </li>
            ))}
          </ol>
        </ReactDragListView>
        <div className="row no-gutters justify-content-between pt-0">
          <div className="col" />
          <div className="align-self-end">
            <button
              type="button"
              className="btn btn-primary mr-3"
              onClick={() => this.updateList()}
            >
              Ok
            </button>
            <button
              type="button"
              className="btn btn-outline-light"
              onClick={() => this.handleCancel()}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default Testing;
