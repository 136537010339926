import { Button } from '@mui/material';
import { TOAST_FAILURE } from 'constants/constants';
import { WorkflowWithSectionsFragment } from 'gql/graphql';
import { useUpdateWorkflowTemplateFromWorkflowMutation } from 'hooks/workflow-hooks';
import { useSearchParams } from 'react-router-dom-latest';
import { Footer } from 'ui/Footer/Footer';
import { AlertMessage } from 'utilities/utils';

export interface RequestsBuilderTemplateFooterProps {
  saveDisabled?: boolean;
  workflow?: WorkflowWithSectionsFragment | null;
  onCancel: () => void;
  onClose: () => void;
}

export const RequestsBuilderTemplateFooter: React.FC<
  RequestsBuilderTemplateFooterProps
> = ({
  workflow,
  onCancel,
  onClose,
  saveDisabled,
}: RequestsBuilderTemplateFooterProps) => {
  const { mutate: updateWorkflowTemplateMutation } =
    useUpdateWorkflowTemplateFromWorkflowMutation();
  const [searchParams] = useSearchParams();
  const template = searchParams.get('template');

  if (!workflow) {
    return null;
  }

  const handleOnSave = () => {
    updateWorkflowTemplateMutation(
      {
        workflowId: workflow.id,
        workflowTemplateId: template,
      },
      {
        onSuccess: (response) => {
          if (response?.updateWorkflowTemplateFromWorkflow?.errors?.length) {
            AlertMessage(TOAST_FAILURE, 'Failed to save');
          }
          onClose();
        },
      },
    );
  };

  return (
    <Footer>
      <Button
        variant="contained"
        color="secondary"
        onClick={onCancel}
        sx={{ marginLeft: 'auto' }}
      >
        Cancel
      </Button>
      <Button
        variant="contained"
        onClick={handleOnSave}
        disabled={saveDisabled}
      >
        Save Changes
      </Button>
    </Footer>
  );
};
