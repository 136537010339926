import {
  Checkbox,
  FormControlLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
} from '@mui/material';
import { useQueryClient, MutateOptions } from '@tanstack/react-query';
import { BatchAssignContactsBody } from 'api/tax-delivery';
import { workflowRequestQueryDocument } from 'api/workflow-requests';
import { AgreementSignatory, WorkflowRequestFragment } from 'gql/graphql';
import { useUpdateSigner } from 'hooks/tax-delivery-hooks';
import { useEffect, useMemo, useRef, useState } from 'react';

export type WorkflowSignableDocumentProps = {
  signers?: AgreementSignatory[] | null;
  assignees?: WorkflowRequestFragment['assignedContacts'];
  disableEdit?: boolean;
};

type SigneeLabelType = 'tax_payer' | 'spouse';
type SigneeValueType = 'id' | 'contactId' | 'kbaRequired';

// helper lookup function
const signeeValueLookup = (
  signers: AgreementSignatory[] | null | undefined,
  labelType: SigneeLabelType,
  valueType: SigneeValueType,
) => {
  if (signers && signers.length > 0) {
    const signer = signers?.find((signee) => signee.label === labelType);

    switch (valueType) {
      case 'contactId':
        if (signer?.label === 'spouse' && signer.signOnSameDevice)
          return 'sign-on-same-device';
        return signer?.contact?.id || '';
      case 'kbaRequired':
        return signer?.kbaRequired || false;
      default:
        return signer?.id || '';
    }
  }
  return '';
};

export const WorkflowSignableDocument = ({
  signers,
  assignees,
  disableEdit,
}: WorkflowSignableDocumentProps) => {
  const queryClient = useQueryClient();
  const assigneeRef = useRef<WorkflowRequestFragment['assignedContacts']>([]);
  const { mutate: updateSigner } = useUpdateSigner();
  const [isAddedContact, setIsAddedContact] = useState(false);
  const [firstSignee, setFirstSignee] = useState<string>(
    signeeValueLookup(signers, 'tax_payer', 'contactId'),
  );
  const [secondSignee, setSecondSignee] = useState<string>(
    signeeValueLookup(signers, 'spouse', 'contactId'),
  );
  const [firstKbaRequired, setFirstKBARequired] = useState<boolean>(
    signeeValueLookup(signers, 'tax_payer', 'kbaRequired'),
  );
  const [secondKbaRequired, setSecondKBARequired] = useState<boolean>(
    signeeValueLookup(signers, 'spouse', 'kbaRequired'),
  );

  const hasSecondSigner = Boolean(signers?.[1]);

  const idNameLookup =
    useMemo(
      () =>
        assignees?.reduce((acc, assignee) => {
          acc[assignee.id] = `${assignee.firstName} ${assignee.lastName}`;
          return acc;
        }, {} as Record<string, string>),
      [assignees],
    ) || {};

  useEffect(() => {
    // if there is a single assignee then the first signee is the only assignee
    if (assignees?.length === 1 && !secondSignee) {
      updateFirstSigner(assignees[0].id);
    }

    // if an assignee add them as the Second Signer if that field is empty
    if (
      assigneeRef.current &&
      assigneeRef.current?.length > 0 &&
      assignees &&
      assignees?.length > assigneeRef.current.length &&
      secondSignee === ''
    ) {
      const newContact = assignees.find(
        (asgn) => !assigneeRef.current?.some((asgnRef) => asgnRef === asgn),
      );

      updateSecondSigner(newContact?.id || '', false, true);
    }

    // if an assignee is removed remove that contact from the signer
    if (
      assigneeRef.current &&
      assigneeRef.current?.length > 0 &&
      assignees &&
      assignees?.length < assigneeRef.current.length
    ) {
      const removedContact = assigneeRef.current.find(
        (asgnRef) => !assignees?.some((asgn) => asgn === asgnRef),
      );

      if (firstSignee === removedContact?.id) {
        updateFirstSigner('');
      }

      if (secondSignee === removedContact?.id) {
        updateSecondSigner('', false);
      }
    }

    assigneeRef.current = assignees;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignees]);

  const handleFirstKBARequirementsChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const isChecked = event.target.checked;

    const signerId = signeeValueLookup(signers, 'tax_payer', 'id');

    if (!signerId) return;

    setFirstKBARequired(isChecked);

    callUpdateSigner({
      signerId,
      kba_required: isChecked,
      contact_id: firstSignee || '',
    });
  };

  const handleSecondKBARequirementsChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const isChecked = event.target.checked;

    const signerId = signeeValueLookup(signers, 'spouse', 'id');

    if (!signerId) return;

    setSecondKBARequired(isChecked);

    callUpdateSigner({
      signerId,
      kba_required: isChecked,
      contact_id: secondSignee || '',
    });
  };

  const handleFirstSigneeChange = (event: SelectChangeEvent<string>) => {
    const contactId = event.target.value || '';
    updateFirstSigner(contactId);
  };

  const updateFirstSigner = (contactId: string) => {
    setFirstSignee(contactId);

    const signerId = signeeValueLookup(signers, 'tax_payer', 'id');

    if (!signerId) return;

    callUpdateSigner({
      signerId,
      contact_id: contactId,
      sign_on_same_device: false,
      kba_required: firstKbaRequired,
    });
  };

  const handleSecondSigneeChange = (event: SelectChangeEvent<string>) => {
    const contactId = event.target.value || '';
    let signOnSameDevice = false;

    if (contactId === 'sign-on-same-device') {
      signOnSameDevice = true;
    }

    updateSecondSigner(contactId, signOnSameDevice);
  };

  const updateSecondSigner = (
    contactId: string,
    signOnSameDevice: boolean,
    setAddedContact: boolean = false,
  ) => {
    setSecondSignee(contactId);
    const signerId = signeeValueLookup(signers, 'spouse', 'id');

    if (!signerId) return;

    callUpdateSigner(
      {
        signerId,
        contact_id: contactId === 'sign-on-same-device' ? '' : contactId,
        sign_on_same_device: signOnSameDevice,
        kba_required: secondKbaRequired,
      },
      {
        onSettled: () => {
          if (setAddedContact) {
            setIsAddedContact(true);
          }
        },
      },
    );
  };

  const renderSecondSignerValue = (value: string) => {
    if (firstSignee !== null && value) {
      if (value === 'sign-on-same-device') return 'Sign on same device';

      const name = idNameLookup[value];
      if (name) return name;

      return 'Second Signer';
    }

    if (firstSignee !== null && isAddedContact) {
      setIsAddedContact(false);
      return idNameLookup[secondSignee];
    }

    return idNameLookup[secondSignee] || 'Second Signer';
  };

  const callUpdateSigner = (
    vars: BatchAssignContactsBody & {
      signerId: string;
    },
    options?: MutateOptions<
      any,
      unknown,
      BatchAssignContactsBody & {
        signerId: string;
      }
    >,
  ) => {
    updateSigner(vars, {
      ...options,
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries({
          queryKey: [
            (workflowRequestQueryDocument.definitions[0] as any).name.value,
          ],
        });
        options?.onSuccess?.(data, variables, context);
      },
    });
  };

  return (
    <Stack gap={2} width="18rem">
      <Stack gap={2} direction="row">
        <Select
          displayEmpty
          renderValue={(value) => idNameLookup[value] || 'First Signer'}
          onChange={handleFirstSigneeChange}
          disabled={disableEdit || !assignees?.length}
          defaultValue={firstSignee || undefined}
          fullWidth
          key={firstSignee}
        >
          <MenuItem>--</MenuItem>
          {assignees?.map((assignee) => {
            const name = `${assignee.firstName} ${assignee.lastName}`;
            return (
              <MenuItem
                disabled={assignee.id === secondSignee}
                value={assignee.id}
                key={assignee.id}
              >
                {name}
              </MenuItem>
            );
          })}
        </Select>
        <FormControlLabel
          control={
            <Checkbox
              checked={firstKbaRequired}
              disabled={disableEdit || !firstSignee}
              onChange={handleFirstKBARequirementsChange}
            />
          }
          label="KBA"
        />
      </Stack>
      {hasSecondSigner && (
        <Stack gap={2} direction="row">
          <Select
            displayEmpty
            renderValue={renderSecondSignerValue}
            onChange={handleSecondSigneeChange}
            disabled={disableEdit || !assignees?.length || firstSignee === null}
            defaultValue={secondSignee || undefined}
            fullWidth
            key={secondSignee}
          >
            <MenuItem>--</MenuItem>
            <MenuItem value="sign-on-same-device">Sign on same device</MenuItem>
            {assignees?.map((assignee) => {
              const name = `${assignee.firstName} ${assignee.lastName}`;
              return (
                <MenuItem
                  disabled={assignee.id === firstSignee}
                  value={assignee.id}
                  key={assignee.id}
                >
                  {name}
                </MenuItem>
              );
            })}
          </Select>
          <FormControlLabel
            control={
              <Checkbox
                disabled={disableEdit}
                checked={secondKbaRequired}
                onChange={handleSecondKBARequirementsChange}
              />
            }
            label="KBA"
          />
        </Stack>
      )}
    </Stack>
  );
};
