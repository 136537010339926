/* eslint-disable no-debugger */
const initialState = {
  emailsSelectedRecords: [],
  emailsSelectedSrcEmailId: [],
};

// eslint-disable-next-line default-param-last
const emailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'EMAILS_SELECTED_RECORDS':
      return {
        ...state,
        emailsSelectedRecords: action.payload.id,
        emailsSelectedSrcEmailId: action.payload.srcEmailId,
      };
    default:
      return state;
  }
};

export default emailsReducer;
