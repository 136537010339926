import { getStorageData } from 'utilities/utils';

export const formatEmailAddresses = (data) => {
  // TODO: __HOMEDATA-AUDIT
  const logginedUserEmail = getStorageData().email;
  let emails = [];

  const re = /[^< ]+(?=>)/g;
  if (!data || data === null) {
    return [];
  }
  if (data.match(re) !== null) {
    // this is for formatting the email addresses related to gmail
    data.match(re).forEach((email) => {
      emails.push(email);
    });
  } else {
    emails = data.replace(/[\\[\]']+/g, '');
    emails = emails.replace(/ /g, '');
    emails = emails.split(',');
  }
  const output = [];
  for (let i = 0; i < emails.length; i += 1) {
    if (emails[i] !== logginedUserEmail) {
      if (emails[i]) {
        output.push({ label: emails[i], value: emails[i] });
      }
    }
  }
  return output;
};

export const formatEmail = (data) => {
  let emails = data;
  emails = data.replace(/[\\[\]']+/g, '');
  emails = emails.split(', ');
  return emails;
};

// this is getting initialstate for email
// TODO: need to add emails to cc when user clicks reply all
export const getInitialState = (data, actionType) => {
  let toRecipients = [];
  let ccRecipients = [];
  let bccRecipients = [];
  const subject = data?.subject || '';
  let body = '';
  let replyToMsgId = null;
  let draftId = null;
  let attachments = [];
  let emailId = null;
  let pretext = '';
  if (data && data.draft_id && data.draft_id !== null) {
    // for draft email
    toRecipients = formatEmailAddresses(data.to_email);
    ccRecipients = formatEmailAddresses(data.cc_email);
    bccRecipients = formatEmailAddresses(data.bcc_email);
    replyToMsgId = data.reply_to_msg_id;
    body = data.content;
    emailId = data.email_id;
    draftId = data.draft_id;
    attachments = data.documents;
  } else if (data) {
    // for new email
    const fromEmail = formatEmailAddresses(data.from_email);
    const toEmail = formatEmailAddresses(data.to_email);
    let recp = [...fromEmail, ...toEmail];
    if (actionType === 'reply') {
      recp = data.inbound_status === 'sent' ? [...toEmail] : [...fromEmail];
    }
    const ids = recp.map((rec) => rec.value);
    const filtered = recp.filter(
      ({ value }, index) => !ids.includes(value, index + 1),
    );
    toRecipients = filtered;
    ccRecipients =
      actionType === 'reply' ? [] : formatEmailAddresses(data.cc_email);
    bccRecipients =
      actionType === 'reply' ? [] : formatEmailAddresses(data.bcc_email);
    // ccRecipients = filtered;
    replyToMsgId = data.msg_id;
    emailId = data.email_id;
    if (actionType === 'forward') {
      toRecipients = [];
      ccRecipients = [];
      bccRecipients = [];
      attachments = data.documents;
      pretext = `<div style="color: grey;">
      ---------- Forwarded message ---------<br>
      From: <span dir="auto">${data.from_email}</span>
      <br>
      Date: ${data.date}
      <br>
      Subject: ${subject}
      <br>
      To:  ${data.to_email}<br>
    </div>`;
    } else if (actionType === 'reply') {
      pretext = `<div style="color: grey;">On ${data.date} ${data.from_email} wrote:</div>`;
    }
    body = `<div></div>${pretext}<blockquote style="color: grey; border-left: 2px solid #f1f1f1; padding-left: 20px">${data.content}</blockquote>`;
  }
  return {
    toRecipients,
    ccRecipients,
    bccRecipients,
    subject,
    body,
    replyToMsgId,
    draftId,
    attachments,
    emailId,
  };
};

// this is for sending data in user_emails api
export const getFormattedData = (state) => {
  let toRecipients = '';
  let ccRecipients = '';
  let bccRecipients = '';
  state.toRecipients.map((each) => {
    if (toRecipients.length) {
      toRecipients += `,${each.value.replace(/>/gi, '')}`;
    } else {
      toRecipients = each.value.replace(/>/gi, '');
    }
    return each;
  });
  state.ccRecipients.map((each) => {
    if (ccRecipients.length) {
      ccRecipients += `,${each.value.replace(/>/gi, '')}`;
    } else {
      ccRecipients = each.value.replace(/>/gi, '');
    }
    return each;
  });
  state.bccRecipients.map((each) => {
    if (bccRecipients.length) {
      bccRecipients += `,${each.value.replace(/>/gi, '')}`;
    } else {
      bccRecipients = each.value.replace(/>/gi, '');
    }
    return each;
  });
  const toField = 'to_recipient';
  // if (state.connectedApp && state.connectedApp === 'outlookemail' && state.viewContext === 'forward') {
  //   toField = 'frwd_to';
  // }
  const accountIds =
    state.accounts?.length > 0 ? state.accounts.map((obj) => obj.value) : '';
  return {
    [toField]: toRecipients,
    cc_recipient: ccRecipients,
    bcc_recipient: bccRecipients,
    subject: state.subject,
    reply_to_msg_id: state.replyToMsgId,
    draft_id: state.draftId,
    attachments: state.attachments,
    email_id: state.emailId,
    accounts_id: accountIds,
  };
};

export const validateFormData = (ps) => {
  const {
    subject,
    toRecipients,
    ccRecipients,
    bccRecipients,
    showCCInput,
    showBCCInput,
    errors: previousErrors,
  } = ps;
  const errors = { ...previousErrors };
  const allRecipients = [...toRecipients, ...ccRecipients, ...bccRecipients];
  if (!subject) {
    errors.subject = 'Please enter a subject';
  } else delete errors.subject;
  if (!allRecipients.length) {
    errors.toRecipients = 'Please add atleast one recipient';
  } else delete errors.toRecipients;
  if (showCCInput && !ccRecipients.length && !toRecipients.length) {
    errors.ccRecipients = 'Please add atleast one recipient';
  } else delete errors.ccRecipients;
  if (showBCCInput && !bccRecipients.length && !toRecipients.length) {
    errors.bccRecipients = 'Please add atleast one recipient';
  } else delete errors.bccRecipients;
  return errors;
};

export const download = (attachment, textInput) => {
  const element = document.createElement('a');
  element.setAttribute(
    'href',
    `data:${attachment.content_type};base64,${textInput}`,
  );
  element.setAttribute('download', attachment.file_name);
  document.body.appendChild(element);
  element.click();
  // document.body.removeChild(element);
};
