import { AuthSession } from 'auth/auth.types';
import { getAuthSession } from 'auth/session';
import { WORKFLOW_REQUEST_PDF } from 'constants/constants';
import { useEffect, useRef } from 'react';

/**
 * Use this hook to generaate a PDF url for the provided
 * workflowRequestId
 *
 * @param workflowRequestId string
 */
export const useGeneratePdfUrl = (workflowRequestId: string) => {
  const pdfURL = useRef('');

  useEffect(() => {
    const auth = getAuthSession() as AuthSession;
    if (auth)
      pdfURL.current = `${WORKFLOW_REQUEST_PDF}/${workflowRequestId}/to_pdf?token=${auth.auth_token}`;
  }, [workflowRequestId]);

  return { pdfURL: pdfURL.current };
};
