import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import PageNotFound from 'common/PageNotFound';
import { LINK_EMAILS } from 'constants/constants';
import { getOffSetsAndLimit, getCpaPreferences } from 'utilities/utils';
import EmailsList from './components/EmailsList';

/* this component is for displaying the list of
linked emails in accounts, contacts and tasks pages */
const LinkedEmails = ({ viewContext, accountId, contactId, taskId }) => {
  const [state, setState] = useState({
    totalPages: 0,
    totalRecords: 0,
    emails: [],
    connectedApp: '',
    filter: 'all',
    offset: [0, 25],
    pagination: 1,
    sortBy: 'name',
    sortOrder: 'desc',
  });

  const fetchEmails = async (discard = '') => {
    const { pagination, sortBy, sortOrder } = state;
    if (!discard) {
      let apiUrl = LINK_EMAILS;
      apiUrl += `?page=${pagination}&sort_by=${sortBy}&sort_order=${sortOrder}`;
      if (viewContext && viewContext === 'account') {
        apiUrl += `&entity_type=account&entity_id=${accountId}`;
      } else if (viewContext && viewContext === 'contact') {
        apiUrl += `&entity_type=contact&entity_id=${contactId}`;
      } else if (viewContext && viewContext === 'task') {
        apiUrl += `&entity_type=task&entity_id=${taskId}`;
      }
      const response = await Axios.get(apiUrl);
      const { emails } = response.data.email_data.reduce(
        (acc, email) => {
          const emailObject = {
            ...email,
            _id: email.id,
            recipients:
              email.from_email !== null
                ? [
                    {
                      name: email.from_email.split(' <')[0].replace(/"/gi, ''),
                      email: email.from_email.split(' <')[1],
                    },
                  ]
                : [],
            isPinned: false,
            userThumbnail: '',
            subject: email.subject,
            attachments: [],
            labels: email.labels,
            updatedAt: email.email_date,
            thread_id: email.thread_id,
          };
          acc.emails.push(emailObject);
          return acc;
        },
        { emails: [] },
      );
      const totalRecords = response.data.stats.total_emails;
      const connectedApp = response.data.connected_app;
      setState({
        ...state,
        emails,
        connectedApp,
        totalPages: Number(Math.ceil(totalRecords / 25)),
        totalRecords,
        offset: getOffSetsAndLimit(totalRecords, 25)[pagination - 1],
      });
    }
  };

  useEffect(() => {
    fetchEmails();
    let url = '';
    if (viewContext && viewContext === 'account' && accountId) {
      url = `/accountdetails/${accountId}#emails`;
    } else if (viewContext && viewContext === 'contact' && contactId) {
      url = `/contactdetails/${contactId}#emails`;
    } else if (viewContext && viewContext === 'task' && taskId) {
      url = `/task/detail/${taskId}#emails`;
    }
    localStorage.setItem('allEmailsButtonUrl', url);
  }, []);

  const handlePageChange = (page) => {
    setState({ ...state, pagination: page });
  };

  const unreadEmail = (emailData) => {
    const { emails } = state;
    const index = state.emails.findIndex((x) => x.id === emailData.id);
    if (index >= 0) {
      const data = emails;
      data[index].is_read = false;
      setState({ ...state, emails: data });
    }
  };

  useEffect(() => {
    fetchEmails();
  }, [state.pagination]);

  const { filter, sortBy, sortOrder, emails, connectedApp, selectedAll } =
    state;

  if (
    getCpaPreferences() &&
    getCpaPreferences().third_party_app &&
    getCpaPreferences().third_party_app.length &&
    getCpaPreferences().third_party_app.some((app) => app.key === 'email')
  ) {
    return (
      <div className="center-wrapper">
        <section>
          {emails && emails.length ? (
            <EmailsList
              handleSort={() => {}}
              toggleSelectAll={() => {}}
              toggleSelect={() => {}}
              handleBultActions={() => {}}
              sortDirection={sortOrder}
              sortByField={sortBy}
              hasSelectedAll={selectedAll}
              emails={emails}
              connectedApp={connectedApp}
              refreshPage={fetchEmails}
              handleEmailActions={() => {}}
              handlePageChange={handlePageChange}
              viewContext={viewContext}
              filter={filter}
              data={state}
              accountId={accountId}
              contactId={contactId}
              taskId={taskId}
              unreadEmail={unreadEmail}
              from="linkedEmailAccConTask"
            />
          ) : (
            <PageNotFound
              name="message"
              message="There are no records to show you right now."
            />
          )}
        </section>
      </div>
    );
  }
  return (
    <div className="center-wrapper">
      <section>
        <div id="content" className="pt-4">
          <div className="center-wrapper">
            <div className="emptyBox text-center">
              <div className="emptyBox-icon">
                <h3 className="text-large">
                  <i className="icon-expired" /> Coming Soon
                </h3>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default LinkedEmails;
