/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// TODO: Keep the comments for future use
import React, { Fragment } from 'react';
import { BULK_ACTIONS_SUB_MENU } from 'constants/constants';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';
import MultipleLevelMenu from './MultipleLevelMenu';

export default function BulkActionsMenuList({
  menuItem,
  handleSubMenuClick,
  getSelectedMenuItem }: {
    menuItem: MenuItem,
    handleSubMenuClick: THandleSubMenuClick,
    getSelectedMenuItem: TGetSelectedMenuItem,
  }) {
  return (
    <Fragment key={menuItem.url}>
      <MultipleLevelMenu
        menuItems={menuItem}
        onSelect={handleSubMenuClick}
        subMenuType={BULK_ACTIONS_SUB_MENU}
        component={
          <ListItemButton
            className="BulkActionsMenuList"
            selected={getSelectedMenuItem(menuItem)}
            data-testid={`sidebar__${menuItem?.label?.toLowerCase()?.split(' ')?.join('_')}`}
          >
            <ListItemIcon className={menuItem.icon_class} />
            <ListItemText primary={menuItem.label.toUpperCase()} />
          </ListItemButton>
        }
      />
    </Fragment>
  );
}

