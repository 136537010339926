import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Stack } from '@mui/material';

import { Search as SearchIcon } from '@mui/icons-material';

import IconTextField from 'common/IconTextField';

function TextFilter(props) {
  const { className, value, onChange } = props;

  const [internal, setInternal] = useState(value);

  useEffect(() => {
    if (internal !== value) {
      setInternal(value);
    }
  }, [value]);

  const onTextChange = (event) => {
    setInternal(event.target.value);

    if (onChange) onChange(event);
  };

  return (
    <Stack
      className={className}
      justifyContent="center"
      width={{ sm: '100%', md: 'auto', lg: 'auto' }}
      height={40}
    >
      <IconTextField
        type="search"
        icon={<SearchIcon />}
        value={internal}
        onChange={onTextChange}
      />
    </Stack>
  );
}

TextFilter.propTypes = {
  value: PropTypes.string,
  onChange: () => {},
};

TextFilter.defaultProps = { value: '' };

export default TextFilter;
