import React from 'react';

const InputField = (props) => (
  <div
    className={
      props.className
        ? props.className + (props.error ? ' form-error' : '')
        : 'col'
    }
  >
    <label htmlFor={props.name}>{props.label}</label>
    {props.headerText ? (
      <div className="mb-2 float-right">
        <span className={`${props.headerTextColor} font-weight-normal`}>
          {props.headerText}
        </span>
      </div>
    ) : null}
    <div
      className={props.passwordShow ? 'labelValue inputGroup' : 'labelValue'}
    >
      <input
        type={props.type ? props.type : 'text'}
        className="form-control"
        id={props.name}
        placeholder={props.placeholder ? props.placeholder : ''}
        onChange={(input) => props.onChange(input.target.value, props.name)}
        onBlur={(input) =>
          props.onBlur ? props.onBlur(input.target.value, props.name) : ''
        }
        value={props.value ? props.value : ''}
        disabled={props.disabled}
        autoComplete={props.autoComplete ? props.autoComplete : 'off'}
        onKeyDown={props.onKeyDown}
        maxLength={props.maxLength}
        data-testid={props?.testid}
      />
      {props.passwordShow ? (
        <span className="inputHelpIcon" onClick={props.showHide}>
          <i
            className={
              props.passwordShow === 'password'
                ? 'icon-close-eye'
                : 'icon-open-eye'
            }
            aria-hidden="true"
          />
        </span>
      ) : (
        ''
      )}
      <span className="error_label">{props.error}</span>
    </div>
  </div>
);

export default InputField;
