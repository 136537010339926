import * as yup from 'yup';
import 'yup-phone-lite';

const toFieldErrorMessage = 'One or more To Recipient(s) are required';
const tagsErrorMessage = 'One or more Tags are required';

export const formSchema = yup.object().shape({
  filename: yup.string().required('File Name is required'),
  blobId: yup.string(),
  textMessageThreadId: yup.string(),
  recipients: yup
    .array()
    .min(1, toFieldErrorMessage)
    .typeError(toFieldErrorMessage)
    .required(toFieldErrorMessage),
  uploadFileMonth: yup.string(),
  uploadFileYear: yup.string(),
  tags: yup
    .array()
    .min(1, tagsErrorMessage)
    .typeError(tagsErrorMessage)
    .required(tagsErrorMessage),
});

export type SMSVaultDialogType = yup.InferType<typeof formSchema>;
