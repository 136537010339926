import { RpaDocumentStatus, RpaDocumentsQuery } from 'gql/graphql';
import { UseQueryResult } from '@tanstack/react-query';
import { GridColDef, GridPaginationModel } from '@mui/x-data-grid';
import { TabsKey } from 'components/Requests/state/request-state';
import { RpaDocumentsTable } from './RpaDocumentsTable/RpaDocumentsTable';
import { UpdatedAtCell } from './TableCells';

type OpenRequestsTableProps = {
  query: UseQueryResult<RpaDocumentsQuery>;
  paginationModel: GridPaginationModel;
  setPaginationModel: (model: GridPaginationModel) => void;
};

const columns: GridColDef[] = [
  {
    field: 'title',
    headerName: 'Name',
    flex: 1.5,
    sortable: false,
  },
  {
    field: 'lastUpdated',
    headerName: 'Last Activity',
    flex: 1,
    sortable: true,
    renderCell: (params) => <UpdatedAtCell params={params} />,
  },
];

export const RpaDocumentsProcessingTable = ({
  query,
  paginationModel,
  setPaginationModel,
}: OpenRequestsTableProps) => (
  <RpaDocumentsTable
    query={query}
    paginationModel={paginationModel}
    setPaginationModel={setPaginationModel}
    tableName="Processing"
    columns={columns}
    requestKey={RpaDocumentStatus.Processing as TabsKey}
  />
);
