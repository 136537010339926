import React from 'react';

const PaginationLoader = () => (
  <>
    <rect x="680" y="470" rx="3" ry="3" width="50" height="20" />
    <rect x="740" y="470" rx="3" ry="3" width="30" height="20" />
    <rect x="790" y="470" rx="3" ry="3" width="50" height="20" />
    <rect x="860" y="470" rx="3" ry="3" width="30" height="20" />
    <rect x="900" y="470" rx="3" ry="3" width="50" height="20" />
  </>
);

export default PaginationLoader;
