/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-access-state-in-setstate */
import { Component } from 'react';
import Axios from 'axios';
import { Link } from 'react-router-dom-latest';
import {
  generateFullName,
  FORMATPHONENUMBER,
  getEmployeeRolesForCpa,
  getCpaPreferences,
  isUserEmployee,
  getStorageData,
  AlertMessage,
  sortingIcon,
  labelNamebyValue,
  getCurrentPath,
} from 'utilities/utils';
import TooltipItem from 'common/TooltipItem_updated';
import HeaderTabs from 'common/HeaderTabs_updated';
import {
  GET_ALL_CPAUSERS,
  SEND_FORGOT_LINK,
  SEND_INVITE_LINK,
  EMPLOYEE,
  GET_DEFAULT_MESSAGE_RECIPIENT,
  LOGGED_IN_AS,
  ACT_AS_KEY,
} from 'constants/constants';
import Pagination from 'common/Pagination';
import NoRecords from 'common/NoRecords';
import ConfirmationModal from 'common/ConfirmationModal';
import { withRouter } from 'utilities/withRouterWrapper';
import liscioLogo from 'images/liscio_logo.png';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { setAuthSession } from 'auth/session';
import { withStorageData } from 'utilities/withStorageData';
import EmployeeAccount from './EmployeeAccounts_updated';

const isThisEmployeeDefaultRecipient = (employee, currentDefaultRecipient) =>
  employee.id === currentDefaultRecipient.id &&
  currentDefaultRecipient.is_default_message_recipient;

const adminPageTabs = [
  {
    filterlabel: 'employees',
    value: 'emp_entries',
    label: 'Employees',
  },
  {
    filterlabel: 'inactive_employees',
    value: 'inactive_entries',
    label: 'Archived',
  },
];

const adminPageTableHeadersWithFeature = [
  {
    value: 'last_name',
    label: 'Name',
    isSortable: true,
    hasIcon: false,
    className: ' col thCol',
  },
  {
    value: 'primary_email',
    label: 'Email',
    isSortable: true,
    hasIcon: false,
    className: ' col thCol',
  },
  {
    value: 'phone_number',
    label: 'Phone',
    isSortable: false,
    hasIcon: false,
    className: ' col thCol',
  },
  {
    value: 'role',
    label: 'Role',
    isSortable: true,
    hasIcon: false,
    className: ' col thCol',
  },
  {
    value: 'mfa',
    label: 'Mfa',
    isSortable: true,
    hasIcon: true,
    className: ' col thCol',
  },
  {
    value: 'email',
    label: 'Email',
    isSortable: true,
    hasIcon: true,
    className: ' col thCol',
  },
  {
    value: 'sms',
    label: 'SMS',
    isSortable: true,
    hasIcon: true,
    className: ' col thCol',
  },
  {
    value: 'is_default_message_recipient',
    label: 'Default Message Recipient',
    isSortable: false,
    className: ' col thCol',
  },
];

const adminPageTableHeaders = [
  {
    value: 'last_name',
    label: 'Name',
    isSortable: true,
    hasIcon: false,
    className: ' col thCol',
  },
  {
    value: 'primary_email',
    label: 'Email',
    isSortable: true,
    hasIcon: false,
    className: ' col thCol',
  },
  {
    value: 'phone_number',
    label: 'Phone',
    isSortable: false,
    hasIcon: false,
    className: ' col thCol',
  },
  {
    value: 'role',
    label: 'Role',
    isSortable: true,
    hasIcon: false,
    className: ' col thCol',
  },
  {
    value: 'company',
    label: 'Company Name',
    isSortable: true,
    hasIcon: false,
    className: ' col thCol',
  },
  {
    value: 'is_default_message_recipient',
    label: 'Default Message Recipient',
    isSortable: false,
    className: ' col thCol',
  },
];

const totalPages = (
  filterBy,
  tabsArray,
  tabProperty,
  noOfRecordsInEachPage,
) => {
  let output = 0;
  tabsArray.map((each) => {
    if (each[tabProperty].toLowerCase() === filterBy.toLowerCase()) {
      output = each.noofrecords / noOfRecordsInEachPage;
    }
    return null;
  });
  return Number(Math.ceil(output));
};

const totalRecords = (filterBy, tabsArray, tabProperty) => {
  let output = 0;
  tabsArray.map((each) => {
    if (each[tabProperty].toLowerCase() === filterBy.toLowerCase()) {
      output = each.noofrecords;
    }
    return null;
  });
  return output;
};

class AdminList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: 0,
      tabs: adminPageTabs,
      listData: [],
      sortBy: 'last_name',
      sortOrder: 'asc',
      pagination: 1,
      filter: 'employees',
      totalPages: 1,
      totalRecords: 0,
      offset: [0, 15],
      loading: true,
      preferences: getEmployeeRolesForCpa(getCpaPreferences()),
      showModal: false,
      currentDefaultRecipient: {},
      searchFieldText: '',
    };
  }

  componentDidMount() {
    document.title = 'Users';
    const { params, location, navigate } = this.props;
    const { tabs } = this.state;
    if (
      !isUserEmployee() ||
      // TODO: __HOMEDATA-AUDIT
      (getStorageData() && getStorageData().role !== 'firm_admin')
    ) {
      navigate('/');
    }
    if (location && location.hash && location.hash.length) {
      const tabIndex = tabs.findIndex(
        (each) => `#${each.filterlabel}` === location.hash,
      );
      this.changeActiveTab(tabIndex, tabs[tabIndex]);
    } else {
      this.getRecords();
    }
    this.getDefaultRecipient(5000);
    if (params && params.id) {
      this.showEmployeeAccounts(parseInt(params.id, 10));
    }
  }

  shouldComponentUpdate(nextProps) {
    const { location } = this.props;
    const currentPath = getCurrentPath(location);
    const nextPath = getCurrentPath(nextProps.location);
    if (nextPath === '/admin' && currentPath === '/employee/:id/accounts') {
      this.hideEmployeeAccounts();
    } else if (
      nextPath === '/employee/:id/accounts' &&
      currentPath === '/admin'
    ) {
      this.showEmployeeAccounts(parseInt(nextProps.params.id, 10));
    }
    return true;
  }

  getDefaultRecipient = (delay) => {
    setTimeout(() => {
      Axios.get(GET_DEFAULT_MESSAGE_RECIPIENT).then((res) => {
        if (res.status === 200 && res.data && res.data.data) {
          this.setState({ currentDefaultRecipient: res.data.data });
        }
      });
    }, delay);
  };

  getRecords = () => {
    const { pagination, filter, sortBy, sortOrder, searchFieldText } =
      this.state;
    const mydata = {
      page: pagination,
      type: filter,
      field: sortBy,
      sort_by: sortOrder,
    };
    if (searchFieldText && searchFieldText !== null && searchFieldText.length) {
      mydata.search = searchFieldText;
    }
    Axios.post(GET_ALL_CPAUSERS, mydata)
      .then((res) => {
        if (
          res.status === 200 &&
          res.data.status === 200 &&
          res.data.data &&
          res.data.data.data
        ) {
          const {
            data,
            data: { searchtext },
          } = res.data;
          const tabs = [];
          adminPageTabs.map((each) => {
            const output = { ...each };
            output.label = data[each.value]
              ? `${output.label} | ${data[each.value]}`
              : `${output.label} | ${0}`;
            output.noofrecords = data[each.value];
            tabs.push(output);
            return each;
          });
          if (!searchtext || searchtext === searchFieldText) {
            this.setState({
              tabs,
              listData: data.data,
              totalPages: totalPages(
                this.state.filter,
                tabs,
                'filterlabel',
                25,
              ),
              totalRecords: totalRecords(
                this.state.filter,
                tabs,
                'filterlabel',
              ),
              offset: [data.offset, data.off],
              loading: false,
            });
          }
        } else {
          this.setState({
            listData: null,
            loading: false,
          });
        }
      })
      .catch(() => {
        this.setState({
          listData: null,
          loading: false,
        });
      });
  };

  changeActiveTab = (tabIndex, tabDetails) => {
    const { employeeId } = this.state;
    if (employeeId) {
      this.setState({
        employeeId: undefined,
        employeeData: undefined,
      });
    } else {
      this.setState(
        {
          activeTab: tabIndex,
          filter: tabDetails.filterlabel,
          pagination: 1,
          loading: true,
        },
        () => {
          this.getRecords();
        },
      );
    }
    this.props.navigate(`/admin#${tabDetails.filterlabel}`);
  };

  handleSortBy = (sortBy) => {
    let { sortOrder } = this.state;
    if (this.state.sortBy === sortBy) {
      if (sortOrder === 'asc') {
        sortOrder = 'desc';
      } else {
        sortOrder = 'asc';
      }
    } else {
      sortOrder = 'asc';
    }

    this.setState(
      {
        sortOrder,
        sortBy,
        loading: true,
      },
      () => {
        this.getRecords();
      },
    );
  };

  handlePageChange = (input) => {
    this.setState(
      {
        pagination: input,
        loading: true,
        employeeId: undefined,
        employeeData: undefined,
      },
      () => this.getRecords(),
    );
  };

  handleNavigation = (userDetails, page, role) => {
    const { id, status } = userDetails;
    if (role && role.toLowerCase() === 'client') {
      if (page === 'edit' && status && status.toLowerCase() !== 'inactive') {
        return this.props.navigate(`/contact/edit/${id}`);
      }
      return `/contactdetails/${id}`;
    }
    if (page === 'edit' && status && status.toLowerCase() !== 'inactive') {
      return this.props.navigate(`/user/edit/${id}`);
    }
    return `/userdetails/${id}`;
  };

  sendInvite = (data, index, sendForgotPassword) => {
    const { listData } = this.state;
    if (!listData[index].inviterequestinprogress) {
      this.updateInviteRequestInprogress(true, index);
      let response = null;
      if (
        sendForgotPassword ||
        listData[index].role.toLowerCase() !== 'client'
      ) {
        response = Axios.get(`${SEND_FORGOT_LINK}/${data.cpa_user_id}`);
      } else {
        response = Axios.get(`${SEND_INVITE_LINK}/${data.id}`);
      }
      response
        .then((res) => {
          this.updateInviteRequestInprogress(false, index);
          if (res.status === 200 && res.data.status === 200) {
            AlertMessage('success', res.data.message, 3000);
            if (sendForgotPassword) {
              this.getRecords();
            }
          } else {
            AlertMessage('error', res.data.message, 3000);
          }
        })
        .catch(() => {
          AlertMessage('error', 'Something went wrong', 3000);
          this.updateInviteRequestInprogress(false, index);
        });
    }
  };

  updateInviteRequestInprogress = (value, index) => {
    const { listData } = this.state;
    listData[index].inviterequestinprogress = value;
    this.setState({ listData });
  };

  updateDefaultrecipient = () => {
    Axios.put(`${EMPLOYEE}/${this.state.recipient_id}`, {
      employee: { is_default_message_recipient: true },
    }).then(() => {
      this.setState({
        showModal: false,
        recipient_id: undefined,
      });
      this.getDefaultRecipient(0);
    });
  };

  showConfirmationModal = (each) => {
    const { showModal } = this.state;
    if (!showModal) {
      this.setState({
        showModal: true,
        recipient_id: each.id,
      });
    } else {
      this.setState({
        showModal: false,
        recipient_id: undefined,
      });
    }
  };

  loginAs = (each) => {
    const data = { id: each.cpa_user_id };
    this.setState({ loading: true });
    const request = Axios.post(LOGGED_IN_AS, data);
    this.setState({ loading: false });
    request.then((res) => {
      if (res.data.status === 200) {
        localStorage.clear();
        setAuthSession({ auth_token: res.data.auth_token });
        localStorage.setItem('loggedInType', 'switchedUser');
        localStorage.setItem('switch_from', res.data.switch_from);
        localStorage.setItem(ACT_AS_KEY, each.cpa_user_id);
        window.location.href = '/';
      } else {
        AlertMessage('error', 'Something went wrong', 3000);
      }
    });
  };

  handleSearchField = (input) => {
    const inputvalue = input && input.target.value ? input.target.value : '';
    const { searchFieldText } = this.state;
    this.setState({
      searchFieldText: inputvalue,
      loading: inputvalue.length > 2,
      pagination: 1,
      totalRecords: 0,
    });
    clearTimeout(this.typingTime);
    this.typingTime = setTimeout(() => {
      this.getRecords(inputvalue, searchFieldText.length);
    }, 500);
  };

  showEmployeeAccounts = (id, input) => {
    this.setState({
      employeeId: id,
      employeeData: input,
    });
    this.props.navigate(`/employee/${id}/accounts`);
  };

  hideEmployeeAccounts = () => {
    this.setState({
      employeeId: undefined,
      employeeData: undefined,
    });
  };

  render() {
    const {
      activeTab,
      tabs,
      listData,
      offset,
      preferences,
      currentDefaultRecipient,
      searchFieldText,
      employeeId,
      employeeData,
      showModal,
      sortBy,
      sortOrder,
      loading,
      pagination,
    } = this.state;

    const {
      flags: { addColumnsToAdminUsersFlag },
    } = this.props;

    const tableHeaders = addColumnsToAdminUsersFlag
      ? activeTab === 1
        ? adminPageTableHeadersWithFeature
        : adminPageTableHeadersWithFeature.slice(0, 8)
      : activeTab === 1
      ? adminPageTableHeaders
      : adminPageTableHeaders.slice(0, 5);

    const storage = getStorageData();
    const loggedinType = localStorage.getItem('loggedInType');

    return (
      <div id="content">
        <header className="pb-0 pt-0">
          <HeaderTabs
            data={tabs}
            activeTab={activeTab}
            handleClick={this.changeActiveTab}
          />
        </header>
        {loading ? <div id="loading" /> : null}
        {employeeId === undefined ? (
          <div className="center-wrapper">
            <div className="mb-3 section-title">
              <div className="col-container">
                <div className="col col-6">
                  <div className="form-group has-search">
                    <span className="icon-zoom2 form-control-feedback" />
                    <input
                      id="users-search"
                      type="text"
                      className="form-control"
                      placeholder="Search User"
                      onChange={this.handleSearchField}
                      value={searchFieldText}
                      autoComplete="off"
                    />
                  </div>
                </div>
                <div className="col col-6 text-right">
                  <Link to="/user/new" className="btn btn-primary">
                    <i>+</i> Employee
                  </Link>
                </div>
              </div>
            </div>
            {listData === undefined || listData === null ? <NoRecords /> : ''}
            {listData && listData !== null && listData.length ? (
              <div className="tableWrap">
                <div className="tRow tRow--head">
                  <div className="row">
                    {tableHeaders.map((each) => (
                      <div
                        key={each.value}
                        width={each.width}
                        className={
                          sortingIcon(
                            sortBy,
                            each.value,
                            sortOrder,
                            'className',
                          ) +
                          each.className +
                          (!each.isSortable ? ' cursor-default' : '')
                        }
                        onClick={() =>
                          each.isSortable ? this.handleSortBy(each.value) : null
                        }
                      >
                        <span className="posRel">
                          {each.hasIcon && (
                            <img
                              src={liscioLogo}
                              alt="Liscio Logo"
                              style={{
                                height: '12px',
                                width: '17px',
                                marginRight: '0.1rem',
                              }}
                            />
                          )}
                          {each.label}
                          <i
                            className={sortingIcon(
                              sortBy,
                              each.value,
                              sortOrder,
                            )}
                          />
                        </span>
                      </div>
                    ))}

                    <div className="col thCol" />
                  </div>
                </div>
                <div className="tRow tRow--body">
                  {listData.map((each, key) => (
                    <div key={each.id + each.role} className="row tdBtn">
                      <Link
                        className="col tdCol text-body"
                        to={this.handleNavigation(each, 'view', each.role)}
                      >
                        <span>
                          {`${each.last_name}, ${each.first_name} ${
                            each.middle_name && each.middle_name !== null
                              ? each.middle_name
                              : ''
                          }`}
                        </span>
                      </Link>
                      <Link
                        className="col tdCol text-body"
                        to={this.handleNavigation(each, 'view', each.role)}
                      >
                        <span>{each.email ? each.email : '-'}</span>
                      </Link>
                      <Link
                        className="col tdCol text-body"
                        to={this.handleNavigation(each, 'view', each.role)}
                      >
                        <span>
                          {each.phone ? FORMATPHONENUMBER(each.phone) : '-'}
                        </span>
                      </Link>
                      <Link
                        className="col tdCol text-body"
                        to={this.handleNavigation(each, 'view', each.role)}
                      >
                        <span>
                          {each.role && preferences.user_role
                            ? labelNamebyValue(each.role, preferences.user_role)
                            : each.role}
                        </span>
                      </Link>

                      {!addColumnsToAdminUsersFlag && (
                        <Link
                          className="col tdCol text-body"
                          to={this.handleNavigation(each, 'view', each.role)}
                        >
                          <span>{each.company ? each.company : '-'}</span>
                        </Link>
                      )}

                      {addColumnsToAdminUsersFlag ? (
                        <>
                          <Link
                            className="col tdCol text-body"
                            to={this.handleNavigation(each, 'view', each.role)}
                          >
                            {each?.mfa_enabled ? (
                              <span>
                                <TaskAltOutlinedIcon /> enabled
                              </span>
                            ) : (
                              <span>-</span>
                            )}
                          </Link>
                          <Link
                            className="col tdCol text-body"
                            to={this.handleNavigation(each, 'view', each.role)}
                          >
                            {each?.email_enabled ? (
                              <span>
                                <TaskAltOutlinedIcon /> enabled
                              </span>
                            ) : (
                              <span>-</span>
                            )}
                          </Link>
                          <Link
                            className="col tdCol text-body"
                            to={this.handleNavigation(each, 'view', each.role)}
                          >
                            {each?.sms_phone_number ? (
                              <span>{each?.sms_phone_number}</span>
                            ) : (
                              <span>-</span>
                            )}
                          </Link>

                          <div className="col tdCol">
                            {isThisEmployeeDefaultRecipient(
                              each,
                              currentDefaultRecipient,
                            ) ? (
                              <i className="icon-tickmark cursor-default" />
                            ) : (
                              <i
                                className="icon-taskUnread historycheck"
                                onClick={() =>
                                  this.showConfirmationModal(
                                    each,
                                    'view',
                                    each.role,
                                  )
                                }
                              />
                            )}
                          </div>
                        </>
                      ) : null}

                      <div className="col tdCol text-right">
                        {each.role !== 'client' ? (
                          <TooltipItem
                            position="top"
                            tooltipType="button"
                            key={`accounts${each.id}`}
                            text="Assign Accounts"
                            btnClass="btn-sm btn--onlyicon btn-link tdBtn__hover"
                            clickAction={() =>
                              this.showEmployeeAccounts(each.id, each)
                            }
                            id={`accounts${each.id}`}
                            iconn="icon-Accounts"
                          />
                        ) : null}
                        {storage &&
                        storage.role === 'firm_admin' &&
                        storage.cpa_user_id !== each.cpa_user_id &&
                        !loggedinType &&
                        each.role !== 'client' &&
                        (each.status.toLowerCase() === 'active' ||
                          each.status.toLowerCase() === 'activated') ? (
                          <TooltipItem
                            position="top"
                            tooltipType="button"
                            key={`login${each.id}`}
                            text="Login as"
                            btnClass="btn-sm btn--onlyicon btn-link tdBtn__hover"
                            clickAction={() => this.loginAs(each)}
                            id={`login${each.id}`}
                            iconn="icon-login"
                          />
                        ) : null}
                        {each.status.toLowerCase() !== 'inactive' ? (
                          <TooltipItem
                            position="top"
                            tooltipType="button"
                            key={`edit${each.id}`}
                            text="Edit"
                            btnClass="btn-sm btn--onlyicon btn-link tdBtn__hover"
                            clickAction={() =>
                              this.handleNavigation(each, 'edit', each.role)
                            }
                            id={`edit${each.id}`}
                            iconn="icon-edit"
                          />
                        ) : null}
                        {(each.status.toLowerCase() === 'new' ||
                          each.status.toLowerCase() === 'invited') &&
                        each.email !== null &&
                        each.email.length ? (
                          <TooltipItem
                            position="top"
                            tooltipType="button"
                            id={`sendinvite${each.id}`}
                            text={
                              each.status.toLowerCase() === 'new'
                                ? 'Send Invite'
                                : 'Resend Invite'
                            }
                            btnClass="btn-sm btn--onlyicon btn-link tdBtn__hover"
                            clickAction={() =>
                              this.sendInvite(each, key, false)
                            }
                            iconn="icon-reinvite"
                          />
                        ) : null}
                        {(each.status.toLowerCase() === 'active' ||
                          each.status.toLowerCase() === 'activated') &&
                        each.email.length ? (
                          <TooltipItem
                            position="top"
                            tooltipType="button"
                            id={`resetpassword${each.id}`}
                            text="Reset Password"
                            btnClass="btn-sm btn--onlyicon btn-link tdBtn__hover"
                            clickAction={() => this.sendInvite(each, key, true)}
                            iconn="icon-reset-password"
                          />
                        ) : null}
                        {each.is_invitation_expired ? (
                          <TooltipItem
                            position="top"
                            tooltipType="button"
                            id={`Inviteexpired${each.id}`}
                            text="Invite Expired"
                            btnClass="btn-sm btn--onlyicon btn-link cursor-default"
                            clickAction={() => {}}
                            iconn="icon-expired text-danger"
                          />
                        ) : null}
                      </div>
                    </div>
                  ))}
                </div>
                <Pagination
                  currentPage={pagination}
                  totalPages={this.state.totalPages}
                  totalRecords={this.state.totalRecords}
                  handlePageChange={this.handlePageChange}
                  offset={offset[0]}
                  off={offset[1]}
                />
              </div>
            ) : null}
          </div>
        ) : null}
        {employeeId ? (
          <EmployeeAccount
            employeeId={employeeId}
            employeeData={employeeData}
          />
        ) : null}
        {showModal ? (
          <ConfirmationModal
            isOpen
            headerText={
              currentDefaultRecipient.first_name
                ? `${generateFullName(
                    currentDefaultRecipient,
                  )} is the default message recipient`
                : undefined
            }
            messageText="Are you sure you want to change default message recipient?"
            yesBtnText="Yes"
            noBtnText="No"
            yesBtnClick={this.updateDefaultrecipient}
            noBtnClick={this.showConfirmationModal}
          />
        ) : null}
      </div>
    );
  }
}

export default withStorageData(withRouter(withLDConsumer()(AdminList)));
