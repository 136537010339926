/* eslint-disable global-require */
import { useCallback, useEffect, useRef, useState } from 'react';

import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { AuthenticatedRedirect } from 'components/AuthenticatedRedirect/AuthenticatedRedirect';
import {
  useGenerateMagicLink,
  useGenerateResetPasswordLink,
  useMfaAuthorization,
  useSignIn,
} from 'hooks/login-v5-hooks';

import { SignInCpaData } from 'api/login-v5';
import { AxiosError } from 'axios';
import {
  CpaSelector,
  ForgotPasswordForm,
  FormContextMFAForm,
  LoginForm,
  LoginPageLayout,
  NextStepsMessage,
} from './components';
import { formSchema } from './helpers/formValidationSchema';
import extractErrorInfo from './helpers/extractErrorInfo';

export type FormUseContext =
  | 'login'
  | 'magicLink'
  | 'passwordReset'
  | 'mfaCode';

export type ProcessState =
  | 'loginForm'
  | 'mfaCode'
  | 'cpaSelector'
  | 'forgotPasswordForm'
  | 'magicLinkSent'
  | 'resetPasswordLinkSent';

export interface LoginFormInterface {
  email: string;
  password: string;
  two_factor_code?: string;
  company_url?: string;
}

/**
 * Process description:
 *
 * - send email and password
 * - store temporary authorization token
 * - if mfa_required === true
 *   - send the mfa code with the saved temporary authorization token
 *   - store new temporary authorization code
 * - send selected CPA with stored temporary authorization code
 */
export const LoginV5Form = () => {
  const [processState, setProcessState] = useState<ProcessState>('loginForm');
  const [temporaryAuthToken, setTemporaryAuthToken] = useState('');
  const [availableCpas, setAvailableCpas] = useState<SignInCpaData[]>([]);

  const formUseContextRef = useRef<FormUseContext>();

  const formCtx = useForm<LoginFormInterface>({
    resolver: yupResolver(formSchema),
    context: { processState },
  });
  const { handleSubmit, setError, reset: resetForm, getValues } = formCtx;
  const { email = '' } = getValues();

  const switchFormUseContext = useCallback((newContext: FormUseContext) => {
    formUseContextRef.current = newContext;
  }, []);

  const setServerError = useCallback(
    (error: AxiosError) => {
      const { message, statusCode } = extractErrorInfo(error);

      setError('root.serverError', {
        type: String(statusCode),
        message,
      });
    },
    [setError],
  );

  /**
   * Sign In
   */
  const { mutateAsync: signIn, isLoading: isSigningIn } = useSignIn({
    onSuccess: (response) => {
      setTemporaryAuthToken(response.auth_token);
      if (response.mfa_required) {
        setProcessState('mfaCode');
        switchFormUseContext('mfaCode');
      } else {
        setProcessState('cpaSelector');
        setAvailableCpas(response.cpa_data);
      }
    },
    onError: setServerError,
  });

  /**
   * MFA validation
   */
  const {
    mutateAsync: checkMfaCode,
    isLoading: isMfaCodeChecking,
    error: mfaAuthError,
  } = useMfaAuthorization({
    onSuccess: (response) => {
      setTemporaryAuthToken(response.auth_token);
      setAvailableCpas(response.cpa_data);
      setProcessState('cpaSelector');
    },
    onError: setServerError,
  });

  /**
   * Magic Link
   */
  const {
    mutateAsync: sendMagicLink,
    isLoading: isSendingMagicLink,
    data: magicLinkResponse,
    error: sendMagicLinkError,
  } = useGenerateMagicLink({
    onSuccess: () => {
      setProcessState('magicLinkSent');
    },
  });

  /**
   * Reset Password
   */
  const {
    mutateAsync: sendResetPasswordLink,
    isLoading: isSendingResetPasswordLink,
    data: resetPasswordResponse,
    error: forgotPasswordError,
  } = useGenerateResetPasswordLink({
    onSuccess: () => {
      setProcessState('resetPasswordLinkSent');
    },
  });

  const onSubmit: SubmitHandler<LoginFormInterface> = useCallback(
    (data) => {
      if (formUseContextRef.current === 'magicLink') {
        sendMagicLink({ email: data.email, appType: 'firm' });
      } else if (formUseContextRef.current === 'passwordReset') {
        sendResetPasswordLink({ email: data.email, appType: 'firm' });
      } else if (formUseContextRef.current === 'mfaCode') {
        checkMfaCode({
          auth_token: temporaryAuthToken,
          two_factor_code: data.two_factor_code || '',
        });
      } else {
        signIn(data);
      }
    },
    [
      checkMfaCode,
      sendMagicLink,
      sendResetPasswordLink,
      signIn,
      temporaryAuthToken,
    ],
  );

  const backToLogin = useCallback(() => {
    resetForm();
    setProcessState('loginForm');
    formUseContextRef.current = 'login';
  }, [resetForm]);

  const goToForgotPassword = useCallback(() => {
    setProcessState('forgotPasswordForm');
  }, []);

  const isLoading =
    isSigningIn ||
    isSendingMagicLink ||
    isSendingResetPasswordLink ||
    isMfaCodeChecking;

  useEffect(() => {
    const error = (mfaAuthError ||
      forgotPasswordError ||
      sendMagicLinkError) as AxiosError;
    if (error) {
      setServerError(error);
    }
  }, [mfaAuthError, forgotPasswordError, sendMagicLinkError, setServerError]);

  return (
    <AuthenticatedRedirect>
      <FormProvider {...formCtx}>
        <div id="page-wrapper">
          <form onSubmit={handleSubmit(onSubmit)}>
            {isLoading && <div id="loading" />}
            <LoginPageLayout>
              <>
                {processState === 'loginForm' && (
                  <LoginForm goToForgotPassword={goToForgotPassword} />
                )}
                {processState === 'cpaSelector' && (
                  <CpaSelector
                    temporaryAuthToken={temporaryAuthToken}
                    availableCpas={availableCpas}
                    backToLogin={backToLogin}
                    loginType="password"
                  />
                )}
                {processState === 'mfaCode' && (
                  <FormContextMFAForm backToLogin={backToLogin} />
                )}
                {processState === 'forgotPasswordForm' && (
                  <ForgotPasswordForm
                    backToLogin={backToLogin}
                    switchFormUseContext={switchFormUseContext}
                  />
                )}
                {processState === 'magicLinkSent' && (
                  <NextStepsMessage
                    backToLogin={backToLogin}
                    message={magicLinkResponse?.message?.replace(
                      '#{email}',
                      email,
                    )}
                  />
                )}
                {processState === 'resetPasswordLinkSent' && (
                  <NextStepsMessage
                    backToLogin={backToLogin}
                    message={resetPasswordResponse?.message?.replace(
                      '#{email}',
                      email,
                    )}
                  />
                )}
              </>
            </LoginPageLayout>
          </form>
        </div>
      </FormProvider>
    </AuthenticatedRedirect>
  );
};

export default LoginV5Form;
