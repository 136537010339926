/* eslint-disable no-console */
import { useState } from 'react';
import styled from '@emotion/styled';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { TooltipStyled } from 'ui/TooltipStyled/TooltipStyled';
import CreateContactAccountModal from './CreateContactAccountModal';
import AddToExistingContactModal from './AddToExistingContactModal/AddToExistingContactModal';

const StyledWrap = styled.div`
  position: relative;
  border: 1.5px solid #cdd1d4;
  padding: 20px 15px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 15px;
  & > div {
    color: rgba(0, 0, 0, 0.5);
    margin: 10px 0;
  }
`;

const CreateAccountContact = ({
  primaryEmail,
  phoneNumber,
  handleDropDownShow,
  emailId,
  getThreadDetails,
  showCloseIcon = false,
  onCancel = () => {}, //no-op
  onCreateOrUpdate = (data) => console.log(data),
  // handleNewCreatedRecord,
}) => {
  const [newContactAccountModal, setNewContactAccountModal] = useState(false);
  const [addToExistingContactModal, setAddToExistingContactModal] =
    useState(false);

  const handleCreateAccountContact = (val) => {
    setNewContactAccountModal(val);
    dropDownShow(val);
    if (!val) onCancel();
  };

  const handleAddToExistingContact = (val) => {
    setAddToExistingContactModal(val);
    dropDownShow(val);
    if (!val) onCancel();
  };

  const dropDownShow = (val) => {
    if (val === false) {
      handleDropDownShow(null, null);
    }
  };

  const handleNewlyCreatedRecord = () => {
    if (getThreadDetails) getThreadDetails();
  };

  return (
    <StyledWrap
      className="CreateAccountContact"
      onClick={(e) => e.stopPropagation()}
    >
      {showCloseIcon && (
        <TooltipStyled title="Close">
          <CloseOutlinedIcon
            onClick={onCancel}
            fontSize="medium"
            color="action"
            sx={{ position: 'absolute', top: '0.5rem', right: '0.5rem' }}
          />
        </TooltipStyled>
      )}
      <Button
        variant="contained"
        onClick={() => handleCreateAccountContact(true)}
      >
        <AddIcon fontSize="small" /> Create a New Contact
      </Button>
      <div>OR</div>
      <Button
        variant="contained"
        onClick={() => handleAddToExistingContact(true)}
      >
        <AddIcon fontSize="small" /> Add to Existing Contact
      </Button>
      {newContactAccountModal && (
        <CreateContactAccountModal
          primaryEmail={primaryEmail}
          phoneNumber={phoneNumber}
          openModal={newContactAccountModal}
          handleCreateAccountContact={handleCreateAccountContact}
          handleNewlyCreatedRecord={handleNewlyCreatedRecord}
          emailId={emailId}
          onCreateOrUpdate={onCreateOrUpdate}
        />
      )}
      {addToExistingContactModal && (
        <AddToExistingContactModal
          primaryEmail={primaryEmail}
          phoneNumber={phoneNumber}
          openModal={addToExistingContactModal}
          handleAddToExistingContact={handleAddToExistingContact}
          handleNewlyCreatedRecord={handleNewlyCreatedRecord}
          onCreateOrUpdate={onCreateOrUpdate}
        />
      )}
    </StyledWrap>
  );
};

export default CreateAccountContact;
