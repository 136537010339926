import { useFormContext } from 'react-hook-form';
import { Alert } from 'reactstrap';

export interface FormAlertProps {}

export const FormAlert = () => {
  const {
    formState: { errors },
  } = useFormContext();

  const serverErrorMessage = errors?.root?.serverError.message;
  const hasServerError = Boolean(serverErrorMessage);

  return hasServerError ? (
    <Alert color="danger">{serverErrorMessage}</Alert>
  ) : null;
};

export default FormAlert;
