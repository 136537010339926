import React from 'react';
import { ReactComponent as IconMenu } from 'images/icons/notify-via-text.svg';
import styled from '@emotion/styled';

const StyledIcon = styled.div`
  background: ##eef0f1;
  margin-left: 2px;
`;

function NotifyViaTextIcon() {
  return (
    <StyledIcon>
      <IconMenu width="1rem" />
    </StyledIcon>
  );
}

export default NotifyViaTextIcon;
