import { Box, Button, SxProps, Typography } from '@mui/material';
import { preventBubbling } from 'utilities/browserUtils/preventBubbling';
import SMSReminderTooltip from './SMSReminderTooltip';

interface Props {
  tooltip: string;
  enabled: boolean;
  sx?: SxProps;
  handleClick: (
    event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>,
  ) => void;
}

/**
 * Helper component for rendering the SMS Reminder Button
 */
export default function SMSReminderButton({
  tooltip,
  enabled,
  handleClick,
  sx,
}: Props) {
  return (
    <SMSReminderTooltip message={tooltip}>
      <span data-testid="reminder-button-tooltip">
        <Button
          variant="contained"
          onClick={(e) => {
            preventBubbling(e);
            handleClick(e);
          }}
          disabled={!enabled}
          sx={sx}
        >
          <Box className="icon-sms" />
          <Typography variant="button" marginLeft={1}>
            Send SMS Reminder
          </Typography>
        </Button>
      </span>
    </SMSReminderTooltip>
  );
}
