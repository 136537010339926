import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';

import Checkbox from 'common/Checkbox';

const accountListTableHeaders = [
  {
    id: 'name',
    numberic: false,
    value: 'name',
    disablePadding: false,
    label: 'Account Name',
    width: '30%',
  },
  {
    id: 'external_account_id',
    numberic: false,
    value: 'external_account_id',
    disablePadding: false,
    label: 'External ID',
    width: '16%',
  },
  {
    id: 'entity_type',
    numberic: false,
    value: 'entity_type',
    disablePadding: false,
    label: 'Entity Type',
    width: '16%',
  },
  {
    id: 'status',
    numberic: false,
    value: 'status',
    disablePadding: false,
    label: 'Account Status',
    width: '16%',
  },
  {
    id: 'rating',
    numberic: false,
    value: 'rating',
    disablePadding: true,
    label: 'Account Rating',
    width: '22%',
  },
];

function AccountListTableHeader(props) {
  const { order, orderBy, rowCount, numSelected, onSelectAll, onRequestSort } =
    props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow sx={{ '&:hover': { boxShadow: 'none' } }}>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAll}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
        {accountListTableHeaders.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numberic ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sx={{ paddingLeft: 0, paddingRight: 0 }}
            sortDirection={orderBy === headCell.id ? order : false}
            width={headCell.width}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              <Typography
                variant={orderBy === headCell.id ? 'body2' : 'gray_body2'}
              >
                {headCell.label}
              </Typography>
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

AccountListTableHeader.propTypes = {
  order: PropTypes.oneOf(['asc', 'desc']),
  orderBy: PropTypes.string,
  rowCount: PropTypes.number,
  numSelected: PropTypes.number,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAll: PropTypes.func.isRequired,
};

AccountListTableHeader.defaultProps = {
  order: 'asc',
  orderBy: 'name',
  rowCount: 0,
  numSelected: 0,
};

export default AccountListTableHeader;
