import { Check } from '@mui/icons-material';
import { Typography, Box, CircularProgress, Stack } from '@mui/material';

type EditorStatusProps = {
  isTouched: boolean;
  isDirty: boolean;
  isSubmitting: boolean;
};

export const EditorStatus = ({
  isTouched,
  isDirty,
  isSubmitting,
}: EditorStatusProps) => (
  <>
    {isTouched && isDirty && (
      <Stack direction="row" spacing="5px" alignItems="center">
        <Typography color="text.secondary" fontWeight={700}>
          Edited
        </Typography>
      </Stack>
    )}
    {isTouched && !isDirty && (
      <Stack direction="row" spacing="5px" alignItems="center">
        {isSubmitting ? (
          <>
            <Typography color="text.secondary" fontWeight={700}>
              Saving
            </Typography>
            <Box maxHeight={12}>
              <CircularProgress color="inherit" size={12} />
            </Box>
          </>
        ) : (
          <>
            <Typography color="text.secondary" fontWeight={700}>
              Saved
            </Typography>
            <Check color="success" fontSize="small" />
          </>
        )}
      </Stack>
    )}
  </>
);
