/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
// this is for showing accounts and contacts linked to emails
import React, { useEffect } from 'react';
import Select from 'react-select';
import {
  /* BUTTON_ICON_LOCATION_BACK, */ LINK_EMAILS,
} from 'constants/constants';
import Axios from 'axios';
import styled from '@emotion/styled';
import Button from 'common/Button/Button';
import { selectTheme, selectStyles } from 'common/CustomFields';
import { Link } from 'react-router-dom-latest';
import ContactCard from 'common/ContactCard';
import AccountCard from 'common/AccountCard';
import useAccountsandContactsStore from './useAccountsandContactsStore';

const StyledDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0px 5px 20px rgb(0 0 0 / 10%);
  padding: 1rem 1.5rem;

  .info {
    display: flex;
    flex-direction: row;
  }

  .label {
    display: flex;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 0.82rem;

    i {
      margin-right: 0.2rem;
      color: #919eab;
      vertical-align: middle;
    }
  }

  .divOption {
    align-self: center;
    background-color: #212b36;
    width: 3px;
    height: 3px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
    margin: 0 0.3rem;
  }

  .linkedData {
    display: 'flex';
    flex-direction: 'row';
    flex-wrap: 'wrap';
    flex: 1;

    a {
      font-size: 0.82rem;
    }
  }

  .selectfield {
    flex: 1;
  }

  .selectedRecords {
    display: flex;
  }

  .button-section {
    margin-top: 1rem;
    display: flex;
    justify-content: flex-end;

    button:first-child {
      margin-right: 0.5rem;
    }
  }
`;

function LinkedAccounts({
  initialValue = [],
  emailId,
  entityType,
  refreshPage,
  enableMode,
  emailLinkedBox,
  contactAccountLinked,
  accountContactLinked,
}) {
  const [value, setValue] = React.useState(initialValue);
  const [mode, setMode] = React.useState(enableMode || 'view');
  const [showLabels, setShowLabels] = React.useState(false);
  const [contactCardId, setContactCardId] = React.useState(null);
  const [contactCardUniqIdentifier, setContactCardUniqIdentifier] =
    React.useState(null);
  const [accountCardID, setAccountCardID] = React.useState(null);
  const [accountCardUniqIdentifier, setAccountCardUniqIdentifier] =
    React.useState(null);
  const options = useAccountsandContactsStore((state) =>
    entityType === 'account' ? state.accounts : state.contacts,
  );
  const fetch = useAccountsandContactsStore((state) =>
    entityType === 'account' ? state.fetchAccounts : state.fetchContacts,
  );
  const labelCount = value.map((option) => option.label);

  const value1 = [...value];
  const result = value1.map((option) => {
    const columns = option;
    return columns;
  });
  const labelData = result.slice(0, 2);

  useEffect(() => {
    setMode(enableMode);
  }, [enableMode]);

  useEffect(() => {
    if (options.length === 0) {
      fetch();
    }
  }, []);

  const contactaccountCard = async (ids, type, uniqId) => {
    await contactaccountCardLeave();
    if (
      type === 'contact' &&
      ids &&
      ids !== ' ' &&
      ids !== null &&
      ids !== undefined
    ) {
      setContactCardId(ids);
      setContactCardUniqIdentifier(uniqId);
    } else {
      setAccountCardID(ids);
      setAccountCardUniqIdentifier(uniqId);
    }
  };

  const contactaccountCardLeave = () => {
    setContactCardId(null);
    setContactCardUniqIdentifier(null);
    setAccountCardID(null);
    setAccountCardUniqIdentifier(null);
  };

  function handleChange(input) {
    setValue(input);
  }

  // TODO: restore edit behavior in task detail -> email tab
  //  https://liscio.atlassian.net/browse/FD-1225
  // function handleEdit() {
  //   setMode('edit');
  // }

  function handleCancel() {
    setValue(initialValue);
    setMode('view');
    disableBox();
  }

  async function handleSave() {
    setMode('view');
    const recordsToLink = value.map((item) => item.value);
    const recordsToUnLink = initialValue.filter(
      (record) => !recordsToLink.includes(record.value),
    );
    let ids = [];
    try {
      if (recordsToUnLink.length) {
        ids = recordsToUnLink.map((item) => item.id);
        const apis = recordsToUnLink.map((record) =>
          Axios.delete(`${LINK_EMAILS}/${record.liscio_email_thread_link_id}`),
        );
        await Axios.all(apis);
      }
      if (recordsToLink.length) {
        await Axios.post(LINK_EMAILS, {
          entity_ids: recordsToLink, // array of account ids or contact ids
          entity_type: entityType, // account or contact
          email_id: emailId,
        });
      }
      if (ids.length > 0) {
        disableBox(ids);
      } else {
        disableBox();
      }
    } catch (error) {
      if (ids.length > 0) {
        disableBox(ids);
      } else {
        disableBox();
      }
    }
  }

  function disableBox(unlinkIds = []) {
    emailLinkedBox(
      entityType === 'account' ? 'add_to_account' : 'add_to_contact',
      false,
    );
    if (unlinkIds.length > 0) {
      refreshPage(entityType, unlinkIds);
    } else {
      refreshPage(entityType);
    }
  }

  return (
    <StyledDiv>
      <div className="info">
        <div>
          <span className="label">
            <i className="icon-Accounts" />
            {entityType === 'contact'
              ? 'Associated Contacts'
              : 'Associated Accounts'}
            <span className="divOption" />
          </span>
        </div>
        {mode === 'edit' ? (
          <div className="selectfield">
            <Select
              isMulti
              value={value}
              onChange={handleChange}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) =>
                `${option.label} ${
                  entityType === 'account' ? option.entity_type : ''
                }  `
              }
              options={
                entityType === 'account'
                  ? accountContactLinked
                  : contactAccountLinked
              }
              menuPosition="relative"
              isDisabled={mode === 'view'}
              theme={selectTheme}
              styles={{
                ...selectStyles,
                menuList: (provided) => ({
                  ...provided,
                  height: '150px',
                }),
              }}
            />
          </div>
        ) : null}
        {mode === 'view' ? (
          <div className="linkedData">
            {labelData?.length <= 2 &&
              !showLabels &&
              labelData?.map((option, index) => (
                <span
                  data-tip
                  data-for={`${option.id}${
                    entityType === 'contact' ? 'global' : 'global_account'
                  }${labelData.indexOf(option)}_${index}`}
                  key={`${labelData.indexOf(option)}_${index}`}
                  onMouseEnter={() =>
                    contactaccountCard(
                      option.id,
                      entityType,
                      `${labelData.indexOf(option)}_${index}`,
                    )
                  }
                  onClick={contactaccountCardLeave}
                >
                  <Link
                    to={`/${
                      entityType === 'contact'
                        ? 'contactdetails'
                        : 'accountdetails'
                    }/${option.value}`}
                  >
                    {option.label} {index !== value.length - 1 && ', '}
                  </Link>
                </span>
              ))}
            <span onClick={() => setShowLabels(!showLabels)}>
              {labelCount && labelCount.length > 2 && !showLabels && (
                <span>
                  {labelCount.length > 2 ? (
                    <span> +{labelCount.length - 2} others </span>
                  ) : null}
                </span>
              )}
              <span>
                {showLabels && (
                  <span>
                    {labelCount.length > 2 ? labelCount.join(', ') : null}
                  </span>
                )}
              </span>
            </span>
          </div>
        ) : null}
        {mode === 'view' && (
          <div>
            {/* 
                TODO: restore edit contact/account functionality in task -> email tab 
                https://liscio.atlassian.net/browse/FD-1225 
            */}
            {/* <Button
              variant="ghost"
              icon="edit"
              onClick={handleEdit}
              size="xsmall"
              styles={{ padding: 0 }}
              iconLocation={BUTTON_ICON_LOCATION_BACK}
            /> */}
          </div>
        )}
      </div>
      {mode === 'edit' && (
        <div className="button-section">
          <Button
            variant="secondary"
            label="Cancel"
            onClick={handleCancel}
            size="xsmall"
          />
          <Button
            variant="primary"
            label="Save"
            onClick={handleSave}
            size="xsmall"
          />
        </div>
      )}
      {contactCardId && contactCardUniqIdentifier && (
        <ContactCard
          id={contactCardId}
          uniqId={contactCardUniqIdentifier}
          page="threaddetail"
        />
      )}
      {accountCardID && accountCardUniqIdentifier && (
        <AccountCard
          id={accountCardID}
          uniqId={accountCardUniqIdentifier}
          page="threaddetail"
        />
      )}
    </StyledDiv>
  );
}

export default LinkedAccounts;
