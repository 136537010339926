import {
  createWorkflowMutationDocument,
  workflowQueryDocument,
  createWorkflowSectionDocument,
  workflowsQueryDocument,
  saveWorkflowMutationDocument,
  readyToSendMutationDocument,
  deleteWorkflowSectionDocument,
  updateWorkflowSectionDocument,
  updateWorkflowMutationDocument,
  workflowTemplatesDocument,
  createWorkflowTemplateFromWorkflowDocument,
  createWorkflowsFromWorkflowTemplateDocument,
  updateWorkflowTemplateFromWorkflowDocument,
  deleteWorkflowTemplateDocument,
  workflowsCountQueryDocument,
} from 'api/workflows';
import { reorderWorkflowSectionsMutationDocument } from 'api/workflow-items';
import {
  WorkflowSectionFragment,
  WorkflowTemplatesQueryVariables,
  WorkflowsQueryVariables,
} from 'gql/graphql';
import { useQueryClient } from '@tanstack/react-query';
import {
  addWorkflowSectionToCache,
  addWorkflowSectionsToCache,
  deleteWorkflowSectionFromCache,
  saveWorkflowInCache,
  updateWorkflowStatusInCache,
} from 'components/Requests/components/RequestsBuilder/utils/mutation-utils';
import { useRequestBuilderContext } from 'components/Requests/components/RequestsBuilder/context/RequestsBuilderContext';
import { alertGqlError } from 'components/Requests/components/RequestsBuilder/utils/get-error-message';
import {
  useGraphQL,
  useGraphQLWithOptions,
  useMutationGraphQL,
} from './useGraphQL';

export function useWorkflowsQuery(variables?: WorkflowsQueryVariables) {
  return useGraphQL(workflowsQueryDocument, variables || {});
}

export function useWorkflowsCountQuery(
  variables: WorkflowsQueryVariables,
  options?: { enabled?: boolean },
) {
  return useGraphQLWithOptions(
    workflowsCountQueryDocument,
    { enabled: options?.enabled },
    variables || {},
  );
}

export function useGetWorkflowById(
  id: string,
  options?: { enabled?: boolean; cacheTime?: number; staleTime?: number },
) {
  return useGraphQLWithOptions(
    workflowQueryDocument,
    { enabled: Boolean(id), ...options, retry: false },
    { id },
  );
}

export function useWorkflowTemplates(
  variables: WorkflowTemplatesQueryVariables,
) {
  return useGraphQL(workflowTemplatesDocument, variables);
}

export function useCreateWorkflow() {
  return useMutationGraphQL(createWorkflowMutationDocument);
}

export function useUpdateWorkflow() {
  const queryClient = useQueryClient();

  return useMutationGraphQL(updateWorkflowMutationDocument, {
    onSettled: (_r, _, variables) => {
      updateWorkflowStatusInCache(queryClient, {
        status: variables.status,
        workflowId: variables.workflowId,
      });
    },
  });
}

export function useSaveWorkflowMutation() {
  const { setSubmitting, setSubmitted, setErrored } =
    useRequestBuilderContext();
  const queryClient = useQueryClient();

  return useMutationGraphQL(saveWorkflowMutationDocument, {
    onMutate: () => {
      setSubmitting(true);
    },
    onSettled: (response, errors, variables) => {
      if (errors) {
        alertGqlError(errors, 'Failed to save title and description');

        if (variables.title) {
          setErrored('title', 'Failed to save title');
        }
        if (variables.description) {
          setErrored('description', 'Failed to save description');
        }

        setSubmitting(false);
        return;
      }
      saveWorkflowInCache(queryClient, variables);

      setSubmitted();
    },
  });
}

export function useReadyToSendWorkflowMutation() {
  return useMutationGraphQL(readyToSendMutationDocument);
}

export function useSaveAsTemplateWorkflowMutation() {
  return useMutationGraphQL(createWorkflowTemplateFromWorkflowDocument);
}

export function useCreateWorkflowFromWorkflowTemplateMutation() {
  return useMutationGraphQL(createWorkflowsFromWorkflowTemplateDocument);
}

export function useUpdateWorkflowTemplateFromWorkflowMutation() {
  return useMutationGraphQL(updateWorkflowTemplateFromWorkflowDocument);
}

export function useCreateWorkflowSection() {
  const queryClient = useQueryClient();
  const { workflowId, setSubmitting, setSubmitted, setErrored } =
    useRequestBuilderContext();
  return useMutationGraphQL(createWorkflowSectionDocument, {
    onMutate: () => {
      setSubmitting(true);
    },
    onSuccess: (response) => {
      addWorkflowSectionToCache(
        queryClient,
        response?.createWorkflowSection
          ?.workflowSection as WorkflowSectionFragment,
        workflowId,
      );
      setSubmitted();
    },
    onError: (errors) => {
      alertGqlError(errors, 'Failed to create section');

      setErrored('NEW', 'Failed to create section');
      setSubmitting(false);
    },
  });
}

export function useDeleteWorkflowSection() {
  const queryClient = useQueryClient();
  const { workflowId, setSubmitting, setSubmitted } =
    useRequestBuilderContext();
  return useMutationGraphQL(deleteWorkflowSectionDocument, {
    onMutate: () => {
      setSubmitting(true);
    },
    onSettled: (response, _, variables) => {
      deleteWorkflowSectionFromCache(queryClient, variables, workflowId);

      setSubmitted();
    },
    onError: (errors) => {
      alertGqlError(errors, 'Failed to delete section');

      setSubmitting(false);
    },
  });
}

export function useUpdateWorkflowSection() {
  const { setSubmitting, setSubmitted, setErrored } =
    useRequestBuilderContext();
  return useMutationGraphQL(updateWorkflowSectionDocument, {
    onMutate: () => {
      setSubmitting(true);
    },
    onSuccess: () => {
      setSubmitted();
    },
    onError: (errors, { workflowSectionId }) => {
      alertGqlError(errors, 'Failed to update section');
      setErrored(workflowSectionId, 'Failed to update section');
      setSubmitting(false);
    },
  });
}

export function useDeleteWorkflowTemplateMutation() {
  return useMutationGraphQL(deleteWorkflowTemplateDocument);
}

export function useReorderWorkflowSections() {
  const queryClient = useQueryClient();
  const { workflowId, setSubmitting, setSubmitted } =
    useRequestBuilderContext();
  return useMutationGraphQL(reorderWorkflowSectionsMutationDocument, {
    onMutate: () => {
      setSubmitting(true);
    },
    onSuccess: (response) => {
      const rSections = response?.reorderWorkflowSections?.workflowSections;

      if (!rSections) {
        return;
      }

      addWorkflowSectionsToCache(
        queryClient,
        rSections,
        workflowId || '',
        true,
      );
      setSubmitted();
    },
    onError: (errors) => {
      alertGqlError(errors, 'Failed to reorder sections');

      setSubmitting(false);
    },
  });
}
