import React, { Component } from 'react';
import Axios from 'axios';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import TooltipItem from 'common/TooltipItem';
import { ACCOUNTS_LOGINS } from 'constants/constants';
import { sortList, sortingIcon, AlertMessage } from 'utilities/utils';
import ConfirmationModal from 'common/ConfirmationModal';
import NoRecords from 'common/NoRecords';
import EncryptedFieldPreview from 'common/EncryptedFieldPreview/EncryptedFieldPreview';
import LoginCreation from './LoginCreation';

const loginPageTableHeaders = [
  {
    value: 'account_name',
    label: 'Account Name',
    isSortable: true,
    className: ' col-2 thCol',
  },
  {
    value: 'login_account_id',
    label: 'Account ID',
    isSortable: true,
    className: ' col-1 thCol text-center ',
  },
  {
    value: 'username',
    label: 'Username',
    isSortable: true,
    className: ' col-2 thCol',
  },
  {
    value: 'password',
    label: 'Password',
    isSortable: true,
    className: ' col-2 thCol',
  },
  {
    value: 'website',
    label: 'Website',
    isSortable: false,
    className: ' col-2 thCol',
  },
  {
    value: 'notes',
    label: 'Notes',
    isSortable: false,
    className: ' col-1 thCol',
  },
];

class AccountLoginsList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      listData: [],
      showModal: false,
      sortBy: 'account_name',
      sortOrder: 'asc',
      loading: true,
      datatoDelete: undefined,
      showDeleteConfirmation: false,
    };
  }

  componentDidMount() {
    this.getRecord();
  }

  getRecord = () => {
    Axios.get(`${ACCOUNTS_LOGINS}/${this.props.accountId}`).then((res) => {
      if (res.status === 200 && res.data.data) {
        const { sortBy, sortOrder } = this.state;
        this.setState({
          listData: sortList(res.data.data, sortBy, sortOrder),
          loading: false,
        });
      } else {
        this.setState({
          loading: null,
          listData: null,
        });
      }
    });
  };

  handleSortBy = (sortBy) => {
    let { sortOrder } = this.state;
    const { listData } = this.state;
    if (this.state.sortBy === sortBy) {
      if (sortOrder === 'asc') {
        sortOrder = 'desc';
      } else {
        sortOrder = 'asc';
      }
    } else {
      sortOrder = 'asc';
    }

    this.setState({
      sortOrder,
      sortBy,
      listData: sortList(listData, sortBy, sortOrder),
    });
  };

  editLogin = (input) => {
    const { showModal } = this.state;
    this.setState({
      showModal: !showModal,
      loginData: input,
    });
  };

  deleteLogin = (input) => {
    if (this.state.showDeleteConfirmation && this.state.datatoDelete) {
      this.handleCloseModal();
      Axios.delete(`${ACCOUNTS_LOGINS}/${this.state.datatoDelete}`).then(
        (res) => {
          if (res.status === 200 && res.data.status === 200) {
            AlertMessage('success', res.data.message, 1000);
          }
          this.getRecord();
        },
      );
    } else {
      this.setState({
        showDeleteConfirmation: true,
        datatoDelete: input,
      });
    }
  };

  newLoginModal = () => {
    this.setState({
      showModal: true,
      showDeleteConfirmation: false,
      datatoDelete: undefined,
      loginData: undefined,
    });
  };

  handleCloseModal = (fetchData) => {
    this.setState(
      {
        showModal: false,
        showDeleteConfirmation: false,
        datatoDelete: undefined,
        loginData: undefined,
      },
      () => (fetchData ? this.getRecord() : null),
    );
  };

  render() {
    const { listData } = this.state;
    const { data: accountData } = this.props;
    return (
      <div className="center-wrapper col-12">
        {this.state.loading ? <div id="loading" /> : null}
        <div className="mb-3 d-flex flex-nowrap btn--leftspace justify-content-end">
          {(!accountData ||
            (accountData &&
              accountData.account &&
              accountData.account.status !== 'Inactive')) && (
            <div className="dropdown dropupWrap dropupWrap--center">
              <button
                type="button"
                className="btn btn-primary"
                onClick={this.newLoginModal}
              >
                <i>+</i> Login
              </button>
            </div>
          )}
        </div>
        {listData === null ? <NoRecords /> : null}
        {listData !== null ? (
          <section key="contact_relationship">
            <div id="inbox-list" className="tableWrap">
              <div className="tRow tRow--head">
                <div className="row">
                  {loginPageTableHeaders.map((each) => (
                    <div
                      key={each.label}
                      className={
                        sortingIcon(
                          this.state.sortBy,
                          each.value,
                          this.state.sortOrder,
                          'className',
                        ) +
                        (each.className ? each.className : '') +
                        (!each.isSortable ? ' cursor-default' : '')
                      }
                      onClick={() =>
                        each.isSortable ? this.handleSortBy(each.value) : null
                      }
                    >
                      <span className="posRel">
                        {each.label}
                        <i
                          className={sortingIcon(
                            this.state.sortBy,
                            each.value,
                            this.state.sortOrder,
                          )}
                        />
                      </span>
                    </div>
                  ))}
                  <div className="col-2 thCol" />
                </div>
              </div>
              <div className="tRow tRow--body">
                {listData.map((each) => (
                  // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                  <div
                    key={each.id}
                    className="row tdBtn"
                    onClick={() => this.editLogin(each)}
                    onKeyDown={() => {}}
                  >
                    <div className="col-2 tdCol">
                      <span>
                        {each.account_name !== null ? each.account_name : '-'}
                      </span>
                    </div>
                    <div className="col-1 tdCol text-center">
                      <span>
                        {each.login_account_id !== null
                          ? each.login_account_id
                          : '-'}
                      </span>
                    </div>
                    <div className="col-2 tdCol">
                      <span>
                        {each.username !== null ? each.username : '-'}
                      </span>
                    </div>
                    <div className="col-2 tdCol">
                      <EncryptedFieldPreview
                        recordType="AccountLogin"
                        fieldName="password"
                        recordId={each.id}
                        hasEncryptedValue={each?.encrypted_password?.populated}
                        wrapperClassName="col-sm-12 col-md-12 col-lg-12"
                      />
                    </div>
                    <div
                      className="col-2 tdCol"
                      onClick={(event) => event.stopPropagation()}
                    >
                      <span>
                        {each.web_site !== null ? (
                          <a
                            rel="external noreferrer"
                            href={
                              each.web_site
                                .toLowerCase()
                                .startsWith('https://') ||
                              each.web_site.toLowerCase().startsWith('http://')
                                ? each.web_site
                                : `//${each.web_site}`
                            }
                            target="_blank"
                          >
                            {each.web_site}
                          </a>
                        ) : (
                          '-'
                        )}
                      </span>
                    </div>
                    <div className="col-1 tdCol">
                      <span>
                        {each.notes !== null && each.notes.length > 15
                          ? each.notes.slice(0, 14)
                          : each.notes}
                      </span>
                    </div>
                    <div className="col-2 tdCol text-right">
                      <TooltipItem
                        position="top"
                        tooltipType="button"
                        key={`editlogin${each.id}`}
                        text="Edit"
                        btnClass="btn-sm btn--onlyicon btn-link tdBtn__hover"
                        clickAction={() => this.editLogin(each)}
                        id={`edit${each.id}`}
                        iconn="icon-edit"
                      />
                      <TooltipItem
                        position="top"
                        tooltipType="button"
                        key={`deletelogin${each.id}`}
                        text="Delete"
                        btnClass="btn-sm btn--onlyicon btn-link tdBtn__hover"
                        clickAction={() => this.deleteLogin(each.id, 'edit')}
                        id={`deletelogin${each.id}`}
                        iconn="icon-delete-small"
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
        ) : null}
        {this.state.showModal ? (
          <Modal
            isOpen
            className="customModal customModal--w990 customModal--nopadding"
            toggle={this.handleCloseModal}
          >
            <ModalHeader className="ModalHeader">
              <button
                type="button"
                onClick={this.handleCloseModal}
                className="close"
                aria-label="Close"
              >
                <i className="icon-close2" aria-hidden="true" />
              </button>
            </ModalHeader>
            <ModalBody>
              <div className="modalContent--wrap">
                <div className="modalContent--inner">
                  <LoginCreation
                    accountId={this.props.accountId}
                    handleCloseModal={this.handleCloseModal}
                    accountName={this.props.data.account.name}
                    loginData={this.state.loginData}
                  />
                </div>
              </div>
            </ModalBody>
          </Modal>
        ) : null}
        {this.state.showDeleteConfirmation ? (
          <ConfirmationModal
            isOpen
            messageText="Attention: The login will be deleted. Proceed?"
            yesBtnText="Proceed"
            noBtnText="Cancel"
            yesBtnClick={this.deleteLogin}
            noBtnClick={this.handleCloseModal}
          />
        ) : null}
      </div>
    );
  }
}

export default AccountLoginsList;
