import { AxiosError } from 'axios';
import { DEFAULT_ERROR_MESSAGE } from 'constants/constants';

export const extractErrorInfo = (error: AxiosError) => {
  const statusCode = error?.response?.status;
  const message =
    error?.response?.data?.errors?.[0]?.message || DEFAULT_ERROR_MESSAGE;

  return {
    statusCode,
    message,
  };
};

export default extractErrorInfo;
