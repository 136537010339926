/* eslint-disable react/no-unused-class-component-methods */
import React, { Component } from 'react';
import { withRouter } from 'utilities/withRouterWrapper';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import FileSaver from 'file-saver';
import mime from 'mime';
import Pagination from 'common/Pagination';
import Files from 'api/Files';
import * as UploadFilesActions from 'actions/UploadFilesActions';
import * as Utilities from 'utilities/utils';
import * as TaskApi from 'api/TaskApi';
import ConfirmationModal from 'common/ConfirmationModal';
import { FILENAME } from 'utilities/regularexpressions';
import UploadFiles from 'api/UploadFiles';
import MultiTagsModal from 'common/MultiTagsModal';
import UploadFilesFilterTabs from 'components/UploadedFiles/UploadFilesFilterTabs';
import FilePopup from './FilePopup';
import List from './List';

const noneStyle = { display: 'none' };
const blockStyle = { display: 'block' };
const removeItem = (array, element) =>
  array.filter((e) => !(e.id === element.id) && !element.checked);
const selectedIds = (array, key) => {
  const selectedlist = [];
  array.map((item) => selectedlist.push(item[key]));
  return selectedlist;
};

class LiscioVaultList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      storageData: {},
      loading: false,
      uploadFilesList: [],
      page: 1,
      pagination: 0,
      paginationOffset: '',
      paginationOff: '',
      totalDocuments: '',
      listOfFiles: null,
      liteUpload: false,
      field: 'created_at',
      sort: 'desc',
      selectall: false,
      errors: {},
      selectedrecords: [],
      deleteOtherFiles: false,
      deleteManualFiles: false,
      multiTagModal: false,
      mutiltagdropdownOpen: false,
      tags: [],
      uploadTags: [],
      years: [],
      months: [],
      tagId: '',
      tagName: '',
      inputMultiTagVal: '',
      newMultiTagList: [],
      newTagList: [],
      selectedMultiTagRecords: [],
      fileExt: '',
      editFileNameId: '',
      currOpenedId: '',
      currOpenedInput: '',
      oldFileName: '',
      api: '',
      accounts: [],
      contacts: [],
      allAccounts: [],
      allContacts: [],
      uploadLiteFilesModal: false,
      filterStatus: 'active',
      filterStatusModal: false,
      fileRemovedFromList: false,
      filterState: {},
      shareFile: false,
      useNewApi:
        window.location.href.includes('beta') ||
        window.location.href.includes('filesvault') ||
        window.location.href.includes('localhost'),
      filterBGClicked: 0,
    };
  }

  onClickFilterBG = () => {
    this.setState((prevState) => ({
      filterBGClicked: prevState.filterBGClicked + 1,
    }));
  };

  UNSAFE_componentWillMount() {
    // TODO: __HOMEDATA-AUDIT
    const storageData = Utilities.getStorageData();
    const homeDataObj = storageData.pref_type_options;
    const upload_tags = homeDataObj.upload_tags ? homeDataObj.upload_tags : [];
    const filter_tags = homeDataObj.filter_tags ? homeDataObj.filter_tags : [];
    const upload_file_year = homeDataObj.upload_file_year
      ? homeDataObj.upload_file_year
      : [];
    const upload_file_month = homeDataObj.upload_file_month
      ? homeDataObj.upload_file_month
      : [];
    this.setState({
      storageData,
      tags: filter_tags,
      uploadTags: upload_tags,
      years: upload_file_year,
      months: upload_file_month,
      newTagList: upload_tags,
      newMultiTagList: upload_tags,
    });
  }

  componentDidMount() {
    const {
      selecteddocuments: selectedrecords,
      getOnlyPrivateFilesinLiscioVault,
    } = this.props;
    let selecteddocuments = [];
    if (selectedrecords && selectedrecords.length) {
      selecteddocuments = selectedrecords.filter((e) => e.is_active);
    }
    this.setState({ selectedrecords: selecteddocuments }, () =>
      Files.getFilesList(
        this,
        undefined,
        undefined,
        true,
        getOnlyPrivateFilesinLiscioVault,
      ),
    );
    const mydata = {
      task_type_key: 'request_info',
      limit: true,
    };
    TaskApi.getAccounts(this, 'notes');
    TaskApi.getContactsOfTask(this, mydata, 'notes');
  }

  onMultiTagFocus = () => {
    this.setState((prevState) => ({
      mutiltagdropdownOpen: true,
      tags: prevState.tags,
      tagId: '',
      tagName: '',
    }));
  };

  updateSelectedFiles = () => {
    const { selecteddocuments } = this.props;
    const { uploadFilesList } = this.state;
    const selectedrecords = [];
    uploadFilesList.map((item) => {
      const eachone = item;
      eachone.checked = false;
      const selectedIndex = selecteddocuments.findIndex(
        (e) => e.id === eachone.id,
      );
      if (selectedIndex > -1) {
        eachone.checked = true;
        selectedrecords.push(item);
      }
      return eachone;
    });
    this.setState(
      {
        uploadFilesList,
        selectedrecords,
      },
      () => {
        this.areAllSelected();
      },
    );
  };

  areAllSelected = () => {
    let x = 0;
    const { uploadFilesList } = this.state;
    if (uploadFilesList) {
      uploadFilesList.map((e) => {
        if (e.checked) x += 1;
        return e;
      });
      const checkbox = document.getElementById('selectallcheckboxfileslist');
      if (x === uploadFilesList.length && (x && uploadFilesList.length) !== 0) {
        this.setState({ selectall: true });
        if (checkbox) checkbox.indeterminate = false;
      } else {
        this.setState({ selectall: false });
        if (checkbox) checkbox.indeterminate = true;
      }
      if (x === 0) {
        if (checkbox) checkbox.indeterminate = '';
      }
    }
  };

  handleCheckbox = (item, index) => {
    let { selectedrecords } = this.state;
    const { uploadFilesList, showPrivateToPublicWarning } = this.state;
    if (uploadFilesList[index].checked) {
      uploadFilesList[index].checked = false;
    } else {
      uploadFilesList[index].checked = true;
    }
    if (uploadFilesList[index].checked) {
      selectedrecords.push(uploadFilesList[index]);
    } else {
      selectedrecords = removeItem(selectedrecords, uploadFilesList[index]);
    }
    this.setState(
      {
        uploadFilesList,
        selectedrecords,
        showPrivateToPublicWarning:
          (item.is_private &&
            selectedrecords.length &&
            uploadFilesList[index].checked) ||
          showPrivateToPublicWarning,
      },
      () => {
        this.areAllSelected();
      },
    );
  };

  handleSelectAll = () => {
    let { selectedrecords } = this.state;
    const { uploadFilesList, selectedAll } = this.state;
    let isAllChecked = 0;
    let checked = false;
    let isPrivateSelected = false;
    uploadFilesList.map((item) => {
      if (item.checked) {
        isAllChecked += 1;
      }
      if (item.is_private) {
        isPrivateSelected = item.is_private;
      }
      return item;
    });
    if (!selectedAll && isAllChecked === 0) {
      checked = true;
    }
    uploadFilesList.map((item) => {
      const eachone = item;
      eachone.checked = checked;
      if (selectedrecords.length) {
        if (checked) {
          selectedrecords.push(eachone);
        } else {
          selectedrecords = removeItem(selectedrecords, eachone);
        }
      }
      return eachone;
    });
    if (!selectedrecords.length && checked) {
      selectedrecords = uploadFilesList;
    }
    this.setState(
      {
        uploadFilesList,
        selectedrecords,
        showPrivateToPublicWarning: isPrivateSelected && selectedrecords.length,
      },
      () => {
        this.areAllSelected();
      },
    );
  };

  selectedFilesAction = (act = '') => {
    const { selectedrecords: url } = this.state;
    if (!url.length && act) {
      Utilities.AlertMessage('error', 'No Selected File.', 3000);
    } else if (act === 'delete') {
      let sourc = false;
      const selectedlist = selectedIds(url, 'source');
      if (
        selectedlist.includes('Note') ||
        selectedlist.includes('Task') ||
        selectedlist.includes('Message')
      ) {
        sourc = true;
      }
      if (sourc === true) {
        this.setState({ deleteOtherFiles: true });
      } else {
        this.setState({ deleteManualFiles: true });
      }
    } else if (act === 'addmultitag') {
      this.setState({ multiTagModal: true });
    } else if (act === 'download') {
      const zip = new JSZip();
      let count = 0;
      const file_names = {};
      url.map((item) => {
        const urlPath = item.aws_url_origional;
        let fileName = item.doc_name;
        const temp_doc_name = fileName.split('.');
        if (file_names[item.doc_name]) {
          temp_doc_name[0] = `${temp_doc_name[0]}(${
            file_names[item.doc_name]
          })`;
          file_names[item.doc_name] += 1;
        } else {
          file_names[item.doc_name] = 1;
        }
        if (temp_doc_name.length === 1 && item.content_type) {
          temp_doc_name[1] = mime.getExtension(item.content_type);
        }
        fileName = temp_doc_name.join('.');
        // return false;
        JSZipUtils.getBinaryContent(urlPath, (err, data) => {
          if (err) {
            throw err;
          }
          zip.file(fileName, data, { binary: true, createFolders: false });
          count += 1;
          if (count === url.length) {
            zip.generateAsync({ type: 'blob' }).then((content) => {
              FileSaver.saveAs(content, 'all_docs.zip');
            });
          }
        });
        return true;
      });
    } else if (act === 'status') {
      this.setState({ filterStatusModal: true });
    } else if (act === 'share') {
      this.setState({ shareFile: true });
    }
  };

  selectedFilesDeleteConfirmed = (act = '') => {
    const { selectedrecords, filterStatus } = this.state;
    const key = act === 'delete' ? 'document_ids' : 'id';
    const data = { [key]: selectedIds(selectedrecords, 'id') };
    if (act === 'delete') {
      Files.deleteSelectedFile(this, data);
    } else if (act === 'filterstatus') {
      if (filterStatus === 'active') {
        data.archive = 'true';
      }
      UploadFiles.archiveFile(this, data, 'filesTab');
    }
  };

  hideSelectedFilesDeleteModal = () => {
    this.setState({ selectedrecords: [] }, () => this.areAllSelected());
  };

  handleMultiTagSubmit = (e) => {
    e.preventDefault();
    const { selectedMultiTagRecords, selectedrecords } = this.state;
    if (selectedMultiTagRecords.length > 0) {
      const data = {
        multi_tags: selectedIds(selectedMultiTagRecords, 'tagId'),
        document_ids: selectedIds(selectedrecords, 'id'),
      };
      Files.updateUploadedFile(this, data);
    } else {
      Utilities.AlertMessage('error', 'Please select tags.', 3000);
    }
  };

  hideMultiTagModal = () => {
    const { tags } = this.state;
    this.setState({
      multiTagModal: false,
      tagId: '',
      tagName: '',
      loading: false,
      mutiltagdropdownOpen: false,
      selectedMultiTagRecords: [],
      newMultiTagList: tags,
    });
  };

  filterMultiTagSearch = (target) => {
    const { tags } = this.state;
    if (target) {
      const k = [];
      const value = target;
      const filter = value.toUpperCase();
      tags.map((tag) => {
        for (let i = 0; i < target.length; i += 1) {
          if (tag.label.toUpperCase().indexOf(filter) > -1) {
            if (k.indexOf(tag) === -1) {
              k.push(tag);
            }
          }
        }
        return k;
      });
      this.setState({ newMultiTagList: k });
    } else {
      this.setState({ newMultiTagList: tags });
    }
  };

  handleMultiTagChange(val) {
    const { tags, selectedMultiTagRecords } = this.state;
    const tagsArr = tags;
    const component = this;
    if (val) {
      component.setState({ tagId: val.key, tagName: val.label });
    } else {
      component.setState({ tagId: '', tagName: '' });
    }
    const selectedInput = {
      tagId: val.key,
      tagName: val.label,
    };
    const selectedRecordsArr = selectedMultiTagRecords;
    const index = selectedRecordsArr.findIndex(
      (x) => x.tagId === selectedInput.tagId,
    );
    if (index === -1) {
      selectedRecordsArr.push(selectedInput);
      this.setState({ selectedMultiTagRecords: selectedRecordsArr });
    }
    this.setState({
      mutiltagdropdownOpen: false,
      tags: tagsArr,
      newMultiTagList: tagsArr,
      tagId: '',
      tagName: '',
    });
  }

  removeSelectedMultiTagRecord(tagId) {
    const { selectedMultiTagRecords } = this.state;
    const selectedRecordsArr = selectedMultiTagRecords;
    const index = selectedRecordsArr.findIndex((x) => x.tagId === tagId);
    if (index > -1) {
      selectedRecordsArr.splice(index, 1);
      this.setState({ selectedMultiTagRecords: selectedRecordsArr });
    }
  }

  dataAfterEditDocument = () => {
    let index = 1;
    if (
      this.state.fileRemovedFromList &&
      this.state.uploadFilesList.length === 1
    ) {
      index =
        this.state.pagination === 0
          ? this.state.pagination + 1
          : this.state.pagination;
    } else if (
      (!this.state.fileRemovedFromList &&
        this.state.uploadFilesList.length !== 1) ||
      (this.state.fileRemovedFromList &&
        this.state.uploadFilesList.length !== 1) ||
      (!this.state.fileRemovedFromList &&
        this.state.uploadFilesList.length === 1)
    ) {
      index = this.state.pagination + 1;
    } else {
      index = this.state.pagination - 1;
    }
    const { api } = this.state;
    const { getOnlyPrivateFilesinLiscioVault } = this.props;
    const apiPath = !api ? '' : api;
    Files.getFilesList(
      this,
      apiPath,
      index,
      true,
      getOnlyPrivateFilesinLiscioVault,
    );
  };

  listOfFilesState = (status) => {
    if (
      (status === 'archive' && this.state.filterStatus === 'active') ||
      (status === 'active' && this.state.filterStatus === 'archived')
    ) {
      this.setState({ fileRemovedFromList: true });
    } else if (
      (status === 'active' && this.state.filterStatus === 'active') ||
      (status === 'archive' && this.state.filterStatus === 'archived')
    ) {
      this.setState({ fileRemovedFromList: false });
    } else {
      const { listOfFiles } = this.state;
      this.setState({
        fileRemovedFromList: true,
        listOfFiles: listOfFiles - 1,
      });
    }
  };

  fileExtention = (item) => {
    let m;
    if (item.doc_name.includes('.')) {
      const n = item.doc_name.split('.').pop();
      m = `.${n}`;
    } else {
      m = '';
    }
    this.setState({
      fileExt: m,
      editFileNameId: item.id,
      currOpenedInput: item.doc_name,
      currOpenedId: item.id,
      oldFileName: item.doc_name,
    });
  };

  handleChangeFileName = (e, val) => {
    let idx = null;
    let j = {};
    this.state.uploadFilesList.map((file, index) => {
      if (file.id === val.id) {
        const f = file;
        f.doc_name = e.target.value;
        idx = index;
        j = f;
      }
      return null;
    });
    const { uploadFilesList: uploadedFiles } = this.state;
    uploadedFiles.splice(idx, 1, j);
    this.setState({
      uploadFilesList: uploadedFiles,
      errors: {},
      currentEditIndex: idx,
    });
  };

  handleKeyPress = (e, item, fro = '') => {
    e.stopPropagation();
    if (e.key === 'Enter' || fro === 'blurevent') {
      let file_name;
      if (item.doc_name.includes('.')) {
        file_name = item.doc_name.substring(0, item.doc_name.lastIndexOf('.'));
        // file_name = item.doc_name.split('.')[0];
      } else {
        file_name = item.doc_name;
      }
      const errors = {};
      if (file_name && (!FILENAME.test(file_name) || file_name.includes('.'))) {
        errors.docName = 'Enter Valid document name';
      } else if (!file_name || file_name.length === 0 || !file_name) {
        errors.docName = 'Please enter document name';
      }
      this.setState({ errors });
      if (!Object.keys(errors).length) {
        const docId = item.id;
        const doc = file_name + this.state.fileExt;
        const mydata = { display_name: doc };
        UploadFiles.ChangeFileName(mydata, docId, this, fro);
      }
    }
  };

  handleSorting = (fieldName) => {
    let { sort: stateSort } = this.state;
    if (this.state.field === fieldName) {
      if (stateSort === 'asc') {
        stateSort = 'desc';
      } else {
        stateSort = 'asc';
      }
    } else {
      stateSort = 'desc';
    }
    let apiPath = '';
    if (this.state.api) {
      apiPath = this.state.api;
    }
    this.setState({ sort: stateSort, field: fieldName }, () => {
      const { getOnlyPrivateFilesinLiscioVault } = this.props;
      Files.getFilesList(
        this,
        apiPath,
        undefined,
        true,
        getOnlyPrivateFilesinLiscioVault,
      );
    });
  };

  handlePageChange = (index) => {
    const { pagination, api } = this.state;
    if (index === pagination + 1) {
      return false;
    }
    const apiPath = !api ? '' : api;
    const { getOnlyPrivateFilesinLiscioVault } = this.props;
    Files.getFilesList(
      this,
      apiPath,
      index,
      true,
      getOnlyPrivateFilesinLiscioVault,
    );
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
    return true;
  };

  filterList = (apiPath, status, com) => {
    this.setState({ api: apiPath, filterStatus: status, filterState: com });
    const { getOnlyPrivateFilesinLiscioVault } = this.props;
    Files.getFilesList(
      this,
      apiPath,
      undefined,
      true,
      getOnlyPrivateFilesinLiscioVault,
    );
  };

  uploadFile = (fro = '') => {
    if (fro) {
      this.setState({ uploadLiteFilesModal: false }, () => {
        const { api, pagination } = this.state;
        const { getOnlyPrivateFilesinLiscioVault } = this.props;
        const apiPath = !api ? '' : api;
        Files.getFilesList(
          this,
          apiPath,
          pagination + 1,
          true,
          getOnlyPrivateFilesinLiscioVault,
        );
      });
    } else {
      this.setState({ uploadLiteFilesModal: true });
    }
  };

  filteredSearch = (fieldName, dat, dropdownName) => {
    const component = this;
    let data = dat;
    if (!data && dropdownName === 'contactDropdown') {
      data = component.state.allContacts;
    } else if (!data && dropdownName === 'accountDropdown') {
      data = component.state.allAccounts;
    }
    component.setState({ [fieldName]: data });
  };

  // to update list of attachments in task or message or notes attached by liscio vault
  updateAttachments() {
    const { selectedrecords, showPrivateToPublicWarningAlert } = this.state;
    const { page, getOnlyPrivateFilesinLiscioVault } = this.props;
    let doesHavePrivateFiles = false;
    if (selectedrecords && selectedrecords.length) {
      selectedrecords.map((e) => {
        e.fromlisciovault = true;
        if (e.is_private) {
          doesHavePrivateFiles = true;
        }
        return e;
      });
    }
    if (
      doesHavePrivateFiles &&
      !showPrivateToPublicWarningAlert &&
      page !== 'notes' &&
      page !== 'edoc' &&
      !getOnlyPrivateFilesinLiscioVault
    ) {
      this.setState({ showPrivateToPublicWarningAlert: true });
    } else {
      this.setState({ showPrivateToPublicWarningAlert: false });
      this.props.updateDocuments({ documents: selectedrecords }, this.state);
    }
  }

  renderMultiTags = () => {
    const selectedMultiTagRecords = this.state.selectedMultiTagRecords.map(
      (item) => (
        <span className="tag tag--fill" key={item.tagId}>
          {item.tagName}{' '}
          <i
            className="icon-close2 remove"
            onClick={() => this.removeSelectedMultiTagRecord(item.tagId)}
          />
        </span>
      ),
    );
    return selectedMultiTagRecords;
  };

  render() {
    const {
      loading,
      listOfFiles,
      uploadFilesList,
      totalDocuments,
      paginationOffset,
      paginationOff,
      pagination,
      deleteManualFiles,
      deleteOtherFiles,
      selectedrecords,
      multiTagModal,
      newMultiTagList,
      uploadLiteFilesModal,
      filterStatus,
      filterStatusModal,
      showPrivateToPublicWarning,
      showPrivateToPublicWarningAlert,
    } = this.state;
    const { page, getOnlyPrivateFilesinLiscioVault } = this.props;
    let totalpages = totalDocuments !== 0 ? Math.ceil(totalDocuments / 15) : 0;
    totalpages = parseInt(totalpages, 10);
    let offset = 0;
    let off = 0;
    if (uploadFilesList !== undefined) {
      offset = paginationOffset;
      off = paginationOff;
    }
    let multiTags = '';
    if (newMultiTagList !== undefined) {
      multiTags = newMultiTagList.map((item) => (
        <button
          type="button"
          key={item.key}
          onClick={() => this.handleMultiTagChange(item)}
          className="dropdown-item"
        >
          {item.label}
        </button>
      ));
    }
    return (
      <div id="content" role="presentation" onClick={this.onClickFilterBG}>
        <header>
          <h1 className="pageHeading">Liscio Vault</h1>
        </header>
        <div
          className="center-wrapper"
          style={{ height: '75vh', overflow: 'scroll' }}
        >
          <div id="loading" style={loading ? blockStyle : noneStyle} />
          {listOfFiles === null || listOfFiles === undefined ? (
            <h3 className="text-light text-small">Fetching Files ...</h3>
          ) : (
            <div>
              <UploadFilesFilterTabs
                data={this.state}
                component={this}
                uploadFile={() => this.uploadFile()}
                liscioVault
                filterBGClicked={this.state.filterBGClicked}
              />
              {showPrivateToPublicWarning &&
              selectedrecords.length &&
              !getOnlyPrivateFilesinLiscioVault &&
              page !== 'notes' &&
              page !== 'edoc' ? (
                <div
                  className="alert alert-warning alert-dismissible"
                  role="alert"
                >
                  <span>
                    Warning: This file is Firm Only. Attaching it to a {page}{' '}
                    will change the status to public, allowing recipients and
                    Account Owners to see this file.
                  </span>
                  <button
                    type="button"
                    className="close"
                    aria-label="Close Alert"
                    onClick={() =>
                      this.setState({ showPrivateToPublicWarning: false })
                    }
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              ) : null}
              <div className="tab-content">
                <div className="tab-pane active" id="review">
                  <div id="inbox-list" className="table tableWrap">
                    <List data={this.state} component={this} liscioVault />
                    <Pagination
                      currentPage={
                        uploadFilesList.length >= 1 ? pagination + 1 : 0
                      }
                      totalPages={totalpages}
                      totalRecords={totalDocuments}
                      offset={totalDocuments === 0 ? offset - 1 : offset}
                      off={off}
                      handlePageChange={this.handlePageChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          {this.state.shareFile && (
            <FilePopup
              shareFile
              shareType="multiple"
              sharedFileDetails={this.state.selectedrecords}
              closeModal={() => this.setState({ shareFile: false })}
            />
          )}
          <MultiTagsModal
            isOpen={multiTagModal}
            component={this}
            data={this.state}
            multiTags={multiTags}
          />
          <ConfirmationModal
            isOpen={deleteOtherFiles}
            headerText="Confirm delete these files"
            messageText="Some of the selected files are attached to Tasks or Messages. These file cannot be deleted. Only files having manual source can be deleted. Proceed with deleting the other selected files?"
            noBtnClick={() => this.setState({ deleteOtherFiles: false })}
            yesBtnClick={() => this.selectedFilesDeleteConfirmed('delete')}
            yesBtnText="Yes"
            noBtnText="No"
          />
          <ConfirmationModal
            isOpen={deleteManualFiles}
            headerText="Confirm delete of the selected files"
            messageText="Note: Only files having manual source can be deleted. Are you sure want to delete these files?"
            noBtnClick={() => this.setState({ deleteManualFiles: false })}
            yesBtnClick={() => this.selectedFilesDeleteConfirmed('delete')}
            yesBtnText="Delete Files"
            noBtnText="Cancel"
          />
          <ConfirmationModal
            isOpen={filterStatusModal}
            headerText={`Confirm ${
              filterStatus === 'active' ? 'archive' : 'unarchive'
            } ${selectedrecords.length > 1 ? 'these files' : 'this file'}`}
            messageText={`Are you sure you want to ${
              filterStatus === 'active' ? 'archive' : 'unarchive'
            } ${selectedrecords.length > 1 ? 'these files' : 'this file'}?`}
            noBtnClick={() => this.setState({ filterStatusModal: false })}
            yesBtnClick={() =>
              this.selectedFilesDeleteConfirmed('filterstatus')
            }
            yesBtnText="Yes"
            noBtnText="No"
          />
          <ConfirmationModal
            isOpen={filterStatusModal}
            headerText={`Confirm ${
              filterStatus === 'active' ? 'archive' : 'unarchive'
            } ${selectedrecords.length > 1 ? 'these files' : 'this file'}`}
            messageText={`Are you sure you want to ${
              filterStatus === 'active' ? 'archive' : 'unarchive'
            } ${selectedrecords.length > 1 ? 'these files' : 'this file'}?`}
            noBtnClick={() => this.setState({ filterStatusModal: false })}
            yesBtnClick={() =>
              this.selectedFilesDeleteConfirmed('filterstatus')
            }
            yesBtnText="Yes"
            noBtnText="No"
          />
          <ConfirmationModal
            isOpen={showPrivateToPublicWarningAlert}
            headerText="Warning"
            messageText={`You have selected a Firm Only file. Attaching it to a ${page} will change the status to public, allowing recipients and Account Owners to see this file. Do you want to proceed?`}
            noBtnClick={() =>
              this.setState({ showPrivateToPublicWarningAlert: false })
            }
            yesBtnClick={() => this.updateAttachments()}
            yesBtnText="Yes"
            noBtnText="No"
          />
          {uploadLiteFilesModal ? (
            <FilePopup closeModal={() => this.uploadFile('close')} />
          ) : null}
        </div>
        <div className="center-wrapper row no-gutters justify-content-between">
          <div className="col" />
          <div className="align-self-end">
            <button
              type="button"
              className="btn btn-outline-light mr-3"
              onClick={() => this.props.openVault(false, this.state)}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() =>
                this.updateAttachments({ documents: selectedrecords })
              }
              disabled={
                selectedrecords === undefined ||
                selectedrecords === null ||
                selectedrecords.length === 0
              }
            >
              Attach Files
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  filesData: state.uploadFilesReducer.filesData,
});

const mapDispatchToProps = (dispatch) => ({
  UploadFilesActions: bindActionCreators(UploadFilesActions, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LiscioVaultList),
);
