import React from 'react';
import { FormControlLabel, Checkbox } from '@mui/material';
import styled from '@emotion/styled';

const StyleDiv = styled.div`
  display: flex;
  align-items: center;
  .EmailMain__LabelDiv {
    margin-left: 25px;
    padding: 0 10px;
    border: 2px solid #dfe3e8;
    .MuiCheckbox-root {
      padding-left: 0;
      padding-top: 7px;
      padding-bottom: 7px;
      svg {
        fill: #dfe3e8;
        width: 18px;
        height: auto;
      }
      &.Mui-checked {
        svg {
          fill: #00aecc;
        }
      }
    }
    .MuiTypography-root {
      color: #919eab;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 14px;
    }
  }
`;

const FilterBy = ({ associatedContactCheck, handleAssociatedContact }) => {
  function handleCheck() {
    handleAssociatedContact();
  }
  return (
    <StyleDiv>
      <span>Filter by</span>
      <div className="EmailMain__LabelDiv">
        <FormControlLabel
          control={
            <Checkbox
              checked={associatedContactCheck}
              onChange={handleCheck}
              color="info"
            />
          }
          label="Associated with Contact"
        />
      </div>
    </StyleDiv>
  );
};

export default FilterBy;
