import {
  USER_EMAILS,
  EMAILS_ATTACHMENTS,
  LINK_EMAILS,
} from 'constants/constants';
import { useFlags } from 'launchdarkly-react-client-sdk';

type GetEmailAttachmentsUrlFunction = (props: {
  emailId: number;
  inLinkedSection?: boolean;
  attachmentId?: number;
  contentDisposition?: 'inline' | 'attachment';
}) => string;

export const getEmailAttachmentsUrlV3: GetEmailAttachmentsUrlFunction = ({
  emailId,
  inLinkedSection,
  contentDisposition,
  attachmentId,
}) => {
  const attachmentIdUrlPart = attachmentId ? `/${attachmentId}` : '';
  if (inLinkedSection) {
    return `${LINK_EMAILS}/${emailId}/attachments${attachmentIdUrlPart}?content_disposition=${contentDisposition}`;
  }
  return `${USER_EMAILS}/${emailId}/attachments${attachmentIdUrlPart}?content_disposition=${contentDisposition}`;
};

export const getEmailAttachmentsUrlV5: GetEmailAttachmentsUrlFunction = ({
  emailId,
  contentDisposition,
  attachmentId,
}) => {
  const attachmentIdUrlPart = attachmentId ? `/${attachmentId}` : '';
  return `${EMAILS_ATTACHMENTS}/${emailId}/email_attachments${attachmentIdUrlPart}?content_disposition=${contentDisposition}`;
};

export const useEmailAttachmentsUrl: GetEmailAttachmentsUrlFunction = ({
  emailId,
  inLinkedSection,
  attachmentId,
  contentDisposition = 'inline',
}) => {
  const { linkedEmailsRefactor } = useFlags();

  if (linkedEmailsRefactor) {
    return getEmailAttachmentsUrlV5({
      emailId,
      contentDisposition,
      attachmentId,
    });
  }

  return getEmailAttachmentsUrlV3({
    emailId,
    inLinkedSection,
    contentDisposition,
    attachmentId,
  });
};
