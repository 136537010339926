/* eslint-disable no-use-before-define */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { useNavigate, Link } from 'react-router-dom-latest';
import { RECURRING_TAB } from 'constants/constants';
import { DisplayRecInvoiceType } from '../../billingUtils';

const StyledRow = styled.tr`
  .BillingTableRow__SubTitle {
    color: #919eab;
  }

  td a {
    color: inherit;
    padding: 0;
    width: 100%;
  }

  .pricingColumn {
    width: 0;
    text-align: right;
  }
`;

function DisplayInvoiceAmount({ amount }) {
  const amt = Number(amount);
  if (!Number.isFinite(amt)) {
    return null;
  }
  return amt.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
}

export const StyledStatus = styled.div`
  ${({ status }) => {
    switch (status) {
      case 'paid':
        return css`
          color: green;
          > div {
            border: 1px solid green;
            width: fit-content;
            padding: 2px 4px;
            font-size: 12px;
            text-transform: capitalize;
          }
        `;
      case 'failed':
      case 'overdue':
        return css`
          color: red;
          > div {
            border: 1px solid red;
            width: fit-content;
            padding: 2px 4px;
            font-size: 12px;
            text-transform: capitalize;
          }
        `;
      default:
        return css`
          color: #d1d113;
          > div {
            border: 1px solid #d1d113;
            width: fit-content;
            padding: 2px 4px;
            font-size: 12px;
            text-transform: capitalize;
          }
        `;
    }
  }}
`;

function displayLastUpdate(dateStr) {
  if (!dateStr) {
    return '';
  }
  return moment(dateStr).format('MM/DD/YYYY HH:MM');
}

const TableRow = ({ row, currentTab, isInvoiceHistoryTab }) => {
  const navigate = useNavigate();
  function handleRowClick() {
    if (isInvoiceHistoryTab) {
      navigate(`/billing/invoices/${row.id}`, {
        state: { currentTab, isInvoiceHistoryTab },
      });
    } else {
      navigate(`/billing/${currentTab.toLowerCase()}/${row.id}`, {
        state: { currentTab },
      });
    }
  }

  function displayPaymentMethod(mode) {
    if (!mode) {
      return '';
    }
    switch (mode.toUpperCase()) {
      case 'CARD':
        return 'Credit Card';
      case 'ACH':
        return 'ACH';
      case 'OTHER':
        return 'Other';
      default:
        return '';
    }
  }

  function displayInvoiceDate() {
    return new Date(row.invoice_due_date).toLocaleDateString('en-US');
  }

  function displayInvoiceType() {
    if (row.is_recurring) {
      return (
        <DisplayRecInvoiceType
          is_recurring={row.is_recurring}
          int={row.recurring_interval}
        />
      );
    }

    return (
      <>
        <div>One-Time</div>
        <div className="BillingTableRow__SubTitle">
          {row.invoice_number ? `Invoice: ${row.invoice_number}` : ''}
        </div>
      </>
    );
  }

  function renderTableRow() {
    switch (currentTab) {
      case RECURRING_TAB:
        return renderRecurringRowDetails();
      default:
        return renderInvoicesRowDetails();
    }
  }

  function renderInvoicesRowDetails() {
    return (
      <StyledRow className="BillingTableRow" onClick={handleRowClick}>
        <td>
          <Link to={`/billing/invoices/${row.id}`}>
            <div>{row.account_name}</div>
            <div className="BillingTableRow__SubTitle">{row.description}</div>
          </Link>
        </td>
        <td>
          <Link to={`/billing/invoices/${row.id}`}>
            <div>{row.owner}</div>
          </Link>
        </td>
        <td>
          <Link to={`/billing/invoices/${row.id}`}>
            <StyledStatus status={row.invoice_status}>
              <div>{row.invoice_status}</div>
            </StyledStatus>
          </Link>
        </td>
        <td>
          <Link to={`/billing/invoices/${row.id}`}>
            {displayPaymentMethod(row.payment_method)}
          </Link>
        </td>
        <td>
          <Link to={`/billing/invoices/${row.id}`}>{displayInvoiceType()}</Link>
        </td>
        <td>
          <Link to={`/billing/invoices/${row.id}`}>{displayInvoiceDate()}</Link>
        </td>
        <td className="pricingColumn">
          <Link to={`/billing/invoices/${row.id}`}>
            <DisplayInvoiceAmount amount={row.invoice_amount} />
          </Link>
        </td>
        <td>
          <Link to={`/billing/invoices/${row.id}`}>
            {displayLastUpdate(row.last_activity_at)}
          </Link>
        </td>
      </StyledRow>
    );
  }

  function renderRecurringRowDetails() {
    return (
      <StyledRow className="BillingTableRow" onClick={handleRowClick}>
        <td>
          <Link to={`/billing/invoices/${row.id}`}>
            <div>{row.account_name}</div>
            <div className="BillingTableRow__SubTitle">{row.description}</div>
          </Link>
        </td>
        <td>
          <Link to={`/billing/invoices/${row.id}`}>{row.owner}</Link>
        </td>
        <td>
          <Link to={`/billing/invoices/${row.id}`}>
            <StyledStatus status={row.invoice_status}>
              <div>{row.invoice_status}</div>
            </StyledStatus>
          </Link>
        </td>
        <td>
          <Link to={`/billing/invoices/${row.id}`}>{row.start_date}</Link>
        </td>
        <td>
          <Link to={`/billing/invoices/${row.id}`}>
            {row.end_date ? row.end_date : 'Never'}
          </Link>
        </td>
        <td>
          <Link to={`/billing/invoices/${row.id}`}>
            <DisplayRecInvoiceType
              is_recurring={row.is_recurring}
              int={row.recurring_interval}
            />
          </Link>
        </td>
        <td>
          <Link to={`/billing/invoices/${row.id}`}>{row.next_charge_date}</Link>
        </td>
        <td className="pricingColumn">
          <Link to={`/billing/invoices/${row.id}`} className="text-align-right">
            <div>
              <DisplayInvoiceAmount amount={row.invoice_amount} />
            </div>
          </Link>
        </td>
        <td>
          <Link to={`/billing/invoices/${row.id}`}>
            {displayLastUpdate(row.last_activity_at)}
          </Link>
        </td>
      </StyledRow>
    );
  }

  return renderTableRow();
};

export default TableRow;

TableRow.propTypes = {
  row: PropTypes.shape({}).isRequired,
  currentTab: PropTypes.string.isRequired,
  isInvoiceHistoryTab: PropTypes.bool,
};
