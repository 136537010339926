export const EMAIL =
  /^([\w_%'+-.]|(?!\.)\.)+[a-zA-Z0-9]{0}@[a-zA-Z0-9]{0}([\w-]+)((\.[a-zA-Z0-9-]{2,})+)$/i;
export const GLOBAL_EMAIL_REGEX =
  /([a-zA-Z0-9._+'-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/g;
export const NAME = /^[a-z ,.'-()"-]+$/i;
export const FILENAME = /^[A-Z0-9 !@#$&_%{}|~()`+,-/]+$/i;
export const WEBSITE =
  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-zA-Z0-9]+([-.]{1}[a-zA-Z0-9]+)*\.[a-zA-Z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
export const BankRoutingNumber = /^\d{9}$/;
export const NUMBER = /^[0-9]*$/;
export const SSN = /\d{3}[-]\d{2}[-]\d{4}/i;
export const INVITEPASSWORD =
  /^(?=.*[$ !"#$%&'()*+,-./:;<=>?@[\]\\^_`{|}~])[A-Za-z0-9$ !"#$%&'()*+,-./:;<=>?@[\]\\^_`{|}~]{8,}$/;
export const PASSWORD =
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[$!"#$%&'()*+,-./:;<=>?@[\]\\^_`{|}~])(?!.*\s).{8,}$/;
export const REMOVESPECIALCHAR = (input) => {
  if (input === null || input === undefined) {
    return input;
  }
  return input.replace(/\D/g, '');
};
export const IP_REGEX =
  /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

export const FORMATPHONENUMBER = (input) => {
  if (input === undefined) {
    return input;
  }
  if (input === null) {
    return undefined;
  }
  let number = input.replace(/\D/g, '').substring(0, 10);
  // Based upon the length of the string, we add formatting as necessary
  const size = number.length;
  if (size === 0) {
    // Do Nothing
  } else if (size < 4) {
    number = `(${number}`;
  } else if (size < 7) {
    number = `(${number.substring(0, 3)}) ${number.substring(3, 6)}`;
  } else {
    number = `(${number.substring(0, 3)}) ${number.substring(
      3,
      6,
    )} - ${number.substring(6, 10)}`;
  }
  return number;
};

export const FORMATSSN = (input) => {
  let output = input;
  if (output === undefined) {
    return output;
  }
  if (output === null) {
    return undefined;
  }
  let val = output.replace(/\D/g, '');
  let newVal = '';
  if (val.length > 4) {
    output = val;
  }
  if (val.length > 3 && val.length < 6) {
    newVal += `${val.substr(0, 3)}-`;
    val = val.substr(3);
  }
  if (val.length > 5) {
    newVal += `${val.substr(0, 3)}-`;
    newVal += `${val.substr(3, 2)}-`;
    val = val.substr(5);
  }
  newVal += val;
  return newVal.substring(0, 11);
};

export const FORMATEIN = (input) => {
  const output = input;
  if (output === undefined) {
    return output;
  }
  if (output === null) {
    return undefined;
  }
  let val = output.replace(/\D/g, '');
  let newVal = '';
  if (val.length > 2) {
    newVal += `${val.substr(0, 2)}-`;
    val = val.substr(2);
  }
  newVal += val;
  return newVal.substring(0, 10);
};

export const TRIMPHONENUMBER = (input) => {
  if (input === undefined || input === null) {
    return undefined;
  }
  if (input.length > 10) {
    return input.substring(0, 10);
  }
  return input;
};
