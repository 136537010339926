import { getStorageData } from './utils';

export const sendMixpanelEvent = (eventname, properties) => {
  const { mixpanel } = window;
  // TODO: __HOMEDATA-AUDIT
  const userData = getStorageData();
  const { cpa_id } = userData;
  const { cpa_user_id } = userData;
  const eventproperties = { cpa_id, cpa_user_id, ...properties };
  if (mixpanel) {
    mixpanel.track(eventname, eventproperties);
  }
};

export const registerSuperProperties = (properties) => {
  const { mixpanel } = window;
  // TODO: __HOMEDATA-AUDIT
  const userData = getStorageData();
  const { cpa_id } = userData;
  const { cpa_user_id } = userData;
  // all the subsequent events get attached to this user
  const distinct_id = cpa_user_id;
  // TODO: __HOMEDATA-AUDIT
  const { user_role } = userData.userpilot_data;
  const { firm_name } = userData.userpilot_data;
  const { app_name } = userData.userpilot_data;
  const super_properties = {
    distinct_id,
    cpa_id,
    cpa_user_id,
    user_role,
    firm_name,
    app_name,
    ...properties,
  };
  if (mixpanel) {
    mixpanel.register(super_properties);
  }
};

// USE THIS FUNCTION DIRECTLY TO PASS ONLY THE EVENT PROPERTIES IN CASE THE ABOVE registerSuperProperties
//  IS ALREADY CALLED TO SET SUPER PROPERTIES
export const trackMixpanelEvent = (eventName, properties) => {
  const { mixpanel } = window;
  if (mixpanel) {
    mixpanel.track(eventName, properties);
  }
};
