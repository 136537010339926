import { RequestsDataGridStyled } from 'components/Requests/components/Styles/Styles';
import { GridColDef } from '@mui/x-data-grid';
import { useEffect, useMemo, useState } from 'react';
import { useDraftsBulkSend } from 'components/Requests/state/drafts-bulk-send-state';
import { WorkflowRequestItem } from 'gql/graphql';
import { Stack } from '@mui/material';
import { useBulkWorkflowRequestQuery } from 'hooks/workflow-request-hooks';
import { NoResultsOverlay } from './Components/NoResultsOverlay';
import { LoadingOverlay } from './Components/LoadingOverlay';
import {
  AssignedToCell,
  DueDateCell,
  RequestTitleCell,
} from './Components/TableCells';
import { TableFooterContainer } from './Components/TableFooter/TableFooterContainer';
import { PreviewBulkSendDraftCell } from './Components/TableCells/PreviewBulkSendDraftCell';
import { RemoveBulkSendDraftCell } from './Components/TableCells/RemoveBulkSendDraftCell';
import { DraftBulkSendDatePicker } from './Components/DraftBulkSendDatePicker/DraftBulkSendDatePicker';
import { DraftsBulkSendPreviewFooter } from './Components/TableFooter/DraftsBulkSendPreviewFooter';

const columns: GridColDef[] = [
  {
    field: 'title',
    headerName: 'Name',
    flex: 1.5,
    sortable: false,
    renderCell: (params) => <RequestTitleCell params={params} labelIsType />,
  },
  {
    field: '_assignedContact',
    headerName: 'Assigned To',
    flex: 1,
    sortable: false,
    renderCell: (params) => <AssignedToCell params={params} />,
  },
  {
    field: '_assignedAccount',
    headerName: 'Account',
    flex: 1,
    sortable: false,
    valueGetter: (params) => `${params.row.assignedAccount?.name || ''}`,
  },
  {
    field: '_owner',
    headerName: 'Owner',
    flex: 1,
    sortable: false,
    valueGetter: (params) =>
      params.row?.owner?.employee &&
      `${params.row.owner?.employee.firstName} ${params.row.owner?.employee.lastName}`,
  },
  {
    field: 'dueDate',
    headerName: 'Due Date',
    flex: 1,
    sortable: false,
    renderCell: (params) => <DueDateCell params={params} />,
  },
  {
    field: '_previewItem',
    headerName: ' ',
    width: 50,
    sortable: false,
    renderCell: ({ row }) => <PreviewBulkSendDraftCell row={row} />,
  },
  {
    field: '_removeItem',
    headerName: ' ',
    width: 75,
    sortable: false,
    renderCell: ({ row }) => <RemoveBulkSendDraftCell row={row} />,
  },
];

export const DraftRequestsBulkSendTable = () => {
  const { selectedDraftIds, getSelectedDraftsCount } = useDraftsBulkSend();

  const [tableData, setTableData] = useState<any[]>([]);

  const ids = selectedDraftIds.reduce((acc, id) => {
    acc.push({ id, queryKey: id });
    return acc;
  }, [] as { id: string; queryKey: string }[]);

  const getAllDrafts = useBulkWorkflowRequestQuery(ids);

  const allQueriesLoaded = getAllDrafts.every(
    (query) => query.fetchStatus === 'idle' && query.isFetching === false,
  );
  const allIneligibleQueriesLoaded = getAllDrafts.filter(
    (query) =>
      query.fetchStatus === 'idle' &&
      query.isFetching === false &&
      (query?.isError || query?.data?.workflowRequest?.status !== 'DRAFT'),
  );

  const draftsWithoutDueDates = useMemo(
    () =>
      tableData.filter(
        (draft) =>
          draft.dueDate === null ||
          draft.dueDate === '' ||
          draft.dueDate === undefined,
      ),
    [tableData],
  );

  useEffect(() => {
    if (allQueriesLoaded) {
      setTableData(
        // only set the tableData with the drafts that have been successfully fetched/are DRATFS
        getAllDrafts.reduce((acc, query) => {
          if (
            query.isSuccess &&
            query?.data?.workflowRequest &&
            query?.data?.workflowRequest?.status === 'DRAFT'
          ) {
            acc.push(
              query?.data?.workflowRequest as unknown as WorkflowRequestItem,
            );
          }
          return acc;
        }, [] as WorkflowRequestItem[]),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allQueriesLoaded]);

  useEffect(() => {
    const updatedTableData = tableData.filter((draft) =>
      selectedDraftIds.includes(draft.id),
    );
    setTableData(updatedTableData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDraftIds]);

  const [totalCount, setTotalCount] = useState(-1);

  const fetchMessage = useMemo(
    () =>
      allIneligibleQueriesLoaded.length > 0
        ? 'Some items are no longer in a DRAFT state and have been removed from this list'
        : null,
    [allIneligibleQueriesLoaded],
  );

  useEffect(() => {
    setTotalCount(getSelectedDraftsCount());
  }, [getSelectedDraftsCount]);

  const getSlots = {
    loadingOverlay: LoadingOverlay,
    noResultsOverlay: () =>
      NoResultsOverlay({
        message: 'No Draft Requests',
      }),
    noRowsOverlay: () =>
      NoResultsOverlay({
        message: 'No Draft Requests',
      }),

    footer: TableFooterContainer,
  };

  return (
    <Stack
      direction="column"
      justifyContent="space-between"
      width="100%"
      height="calc(100vh - 86px)"
    >
      <DraftBulkSendDatePicker />
      <RequestsDataGridStyled
        hasFilters
        rows={tableData && allQueriesLoaded ? tableData : []}
        columns={columns}
        loading={!allQueriesLoaded}
        slots={getSlots}
        slotProps={{
          footer: {
            showPagination: false,
            component: (
              <DraftsBulkSendPreviewFooter
                message={fetchMessage}
                requiresDueDate={draftsWithoutDueDates.length > 0}
                disabled={!allQueriesLoaded}
              />
            ),
          },
        }}
        disableColumnMenu
        rowCount={totalCount}
        rowHeight={72}
        columnHeaderHeight={36}
        pageSizeOptions={[100]}
      />
    </Stack>
  );
};
