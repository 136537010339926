import { graphql } from 'gql';

export const fetchFirmLicense = graphql(/* GraphQL */ `
  query FetchFirmLicense {
    fetchFirmLicense {
      id
      billingCycle
      billingStartDay
      licenseTier
      seatCount
      status
    }
  }
`);

export const updateFirmLicense = graphql(/*  GraphQL */ `
  mutation UpdateFirmLicense(
    $licenseTier: LicenseTier!
    $billingCycle: BillingCycle!
  ) {
    updateFirmLicense(licenseTier: $licenseTier, billingCycle: $billingCycle) {
      errors {
        message
      }
      firmLicense {
        id
        seatCount
        billingCycle
        licenseTier
      }
    }
  }
`);
