/* eslint-disable global-require */
import { useLayoutEffect } from 'react';
import { useSignInSSO } from 'hooks/login-v5-hooks';

import { AuthenticatedRedirect } from 'components/AuthenticatedRedirect/AuthenticatedRedirect';
import { CpaSelector } from './components';
import { getRedirectUri } from './helpers/getRedirectUri';

export interface LoginV5OAuthProps {
  type: 'google' | 'outlook';
  code: string;
}

export const LoginV5OAuth = ({ type, code }: LoginV5OAuthProps) => {
  const { mutateAsync: signInSSO, data, isLoading } = useSignInSSO();

  useLayoutEffect(() => {
    signInSSO({
      login_type: type,
      redirect_uri: getRedirectUri(type),
      code,
    });
  }, [type, code, signInSSO]);

  return (
    <AuthenticatedRedirect>
      <div id="page-wrapper">
        {isLoading && <div id="loading" />}
        <div className="crPage">
          <div className="crPage--inner">
            <div className="crPage--innerForm">
              <div className="crPage--innerForm--sec">
                <div className="crPage--head text-center">
                  <img
                    src={require('images/liscioLogo.png')}
                    className="homeLogo"
                    alt="Lisico"
                    data-testid="home_logo"
                  />
                  <div>
                    {isLoading && (
                      <div className="text-center border-bottom py-2 mt-4 mb-2 text-center">
                        <h3 className="fontSize font-weight-bold">
                          Loading...
                        </h3>
                      </div>
                    )}
                    {!isLoading && data && (
                      <CpaSelector
                        temporaryAuthToken={data?.auth_token}
                        availableCpas={data?.cpa_data}
                        backToLogin={() => {
                          window.location.href = '/login';
                        }}
                        loginType={type}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AuthenticatedRedirect>
  );
};

export default LoginV5OAuth;
