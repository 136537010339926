/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Select from 'react-select';
import Interweave from 'interweave';
import styled from '@emotion/styled';
import { truncateText } from 'utilities/utils';
import ClearButton from 'common/ClearButton';

const StyledDiv = styled.div`
  display: flex;
  position: relative;

  button {
    position: absolute;
    right: 10px;
    top: 4px;
  }
`;

const StyledContainer = styled.div`
  .CustomOption {
    padding: 8px;
    border-bottom: 1px solid #dfe3e8;
    color: #a4afbd;
    cursor: pointer;
    &:hover {
      .CustomOption__Header {
        color: black;
      }
    }
  }
`;

function ValueContainer(props) {
  const { getValue, getStyles, children, innerProps } = props;
  const selectedValue = getValue()[0];
  if (selectedValue) {
    const updatedValue = {
      id: selectedValue.id,
      label: selectedValue.label,
    };
    return (
      <div {...innerProps} style={getStyles('valueContainer', props)}>
        {updatedValue.label}
      </div>
    );
  }

  return (
    <div {...innerProps} style={getStyles('valueContainer', props)}>
      {children}
    </div>
  );
}

function Option(props) {
  const { data, innerProps, innerRef, children } = props;
  const content = data.content.replace(/&nbsp;/g, ' ');
  const truncatedText = truncateText(content, 107);

  return (
    <div {...innerRef} className="CustomOption" {...innerProps}>
      <div className="CustomOption__Header">{children}</div>
      <div className="CustomOption___Interweave">
        <Interweave content={truncatedText} />
      </div>
    </div>
  );
}

const DescriptionSelect = ({
  selectStyles,
  billingTemplates,
  handleDescriptionChange,
  handleDescriptionInputChange,
  customTemplateInputChange,
  handleCustomTemplateClear,
  descriptionInput,
  customTemplateInputValue,
  invalidDescription,
  handleInputBlur,
}) => {
  const descriptionSelectStyles = {
    ...selectStyles,
    input: (styles) => ({
      ...styles,
      padding: 0,
      margin: 0,
      cursor: 'pointer',
    }),
    valueContainer: (styles) => ({
      ...styles,
      paddingTop: 0,
      paddingBottom: 0,
    }),
  };

  return (
    <StyledContainer className="DescriptionSelect__Container">
      {customTemplateInputValue ? (
        <StyledDiv className="CustomTemplateInputContainer">
          <input
            autoComplete="off"
            name="customTemplateInput"
            className="form-control"
            id="customTemplateInput"
            type="text"
            value={customTemplateInputValue}
            maxLength="200"
            onChange={customTemplateInputChange}
          />

          <ClearButton onClickClear={handleCustomTemplateClear} iconHidden>
            Clear
          </ClearButton>
        </StyledDiv>
      ) : (
        <Select
          name="description"
          id="description"
          placeholder="Select Template"
          className="DescriptionSelect__Template"
          classNamePrefix="DescriptionSelect"
          onChange={handleDescriptionChange}
          onInputChange={handleDescriptionInputChange}
          onBlur={handleInputBlur}
          inputValue={descriptionInput}
          styles={descriptionSelectStyles}
          options={billingTemplates}
          components={{ ValueContainer, Option }}
          invalidValue={invalidDescription}
          isSearchable
          isClearable
        />
      )}
      {invalidDescription && (
        <div className="InvoiceForm__Error">This is a required field</div>
      )}
    </StyledContainer>
  );
};

export default DescriptionSelect;
