import HeroImage from 'images/OrganizerTemplates/organizer-templates_hero-comprehensive.png';
import { Box, Divider, Stack, SvgIcon, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { iconMap } from 'components/Sidebar/elements/MenuItemIcon/icons';
import { OrganizerTemplatesHeader } from 'components/OrganizerTemplates/components/OrganizerTemplatesHeader/OrganizerTemplatesHeader';

export type ComprehensiveOrganizerProps = {
  current: boolean;
};

export const ComprehensiveOrganizer = ({
  current,
}: ComprehensiveOrganizerProps) => (
  <Stack gap="1rem">
    <OrganizerTemplatesHeader current={current}>
      2024 Comprehensive Tax Organizer
    </OrganizerTemplatesHeader>
    <img src={HeroImage} alt="Comprehensive Organizer" />
    <Typography>
      The Comprehensive Tax Organizer Template is perfect for clients with more
      complex tax profiles as it gathers a high level of detail, but clients -
      particularly those short on time or patience - may find it more
      challenging and time intensive to complete.
    </Typography>
    <Typography>This template is ideal for:</Typography>
    <Box>
      <Typography variant="h6" fontWeight={600}>
        Clients who consistently qualify to itemize deductions
      </Typography>
      <Typography>
        and would benefit from specific sections for each deduction type.
      </Typography>
    </Box>
    <Box>
      <Typography variant="h6" fontWeight={600}>
        Clients who have dependents
      </Typography>
      <Typography>
        and want to perform a full review of all dependent details, annually.
      </Typography>
    </Box>
    <Box>
      <Typography variant="h6" fontWeight={600}>
        Clients who earn income from other miscellaneous sources
      </Typography>
      <Typography>
        such as non-wage income not otherwise associated with a business
        activity.
      </Typography>
    </Box>
    <Box>
      <Typography variant="h6" fontWeight={600}>
        Clients who make regular estimated tax payments
      </Typography>
      <Typography>across multiple tax jurisdictions.</Typography>
    </Box>
    <Box>
      <Typography variant="h6" fontWeight={600}>
        Clients who are self-employed and may qualify for one or more related
        adjustments
      </Typography>
      <Typography>
        particularly if their eligibility potentially varies year over year.
      </Typography>
    </Box>
    <Box>
      <Typography variant="h6" fontWeight={600}>
        Clients who qualify for special credits or deductions
      </Typography>
      <Typography>
        that change from year to year, such as energy efficiency credits or
        those only applicable to unique tax statuses.
      </Typography>
    </Box>
    <Typography>
      The Comprehensive Organizer includes all sections of the Standard
      Organizer and additional sections to capture extra information needed by
      clients with detailed tax situations.
    </Typography>
    <Divider />
    <Typography variant="h4">
      <SvgIcon>
        <FontAwesomeIcon icon={iconMap['check-list']} />
      </SvgIcon>{' '}
      Key Sections in the Standard Organizer
    </Typography>
    <Box>
      <Typography variant="h6" fontWeight={600}>
        Personal Details
      </Typography>
      <Typography>
        Asks the client to review and update their demographic details
      </Typography>
    </Box>
    <Box>
      <Typography variant="h6" fontWeight={600}>
        Contact Information
      </Typography>
      <Typography>
        Asks the client to review and update their contact details
      </Typography>
    </Box>
    <Box>
      <Typography variant="h6" fontWeight={600}>
        Dependents
      </Typography>
      <Typography>
        Allows the taxpayer to review and update all dependent information on
        file regardless of whether they indicate there are changes to that
        information.
      </Typography>
    </Box>
    <Box>
      <Typography variant="h6" fontWeight={600}>
        Taxes (Estimated Payments)
      </Typography>
      <Typography>
        Separate section for payment details by jurisdiction regardless of
        whether there were any Estimated Payments calculated with the client’s
        prior year tax return.
      </Typography>
    </Box>
    <Box>
      <Typography variant="h6" fontWeight={600}>
        Non-Resident Alien
      </Typography>
      <Typography>
        Includes specific questions for non-resident taxpayers if the taxpayer
        filed as a non-resident alien in the prior year.
      </Typography>
    </Box>
    <Box>
      <Typography variant="h6" fontWeight={600}>
        Wages & Investments
      </Typography>
      <Typography>
        Covers income from wages and investments and appears regardless of
        whether the client reported either variety of income on their prior year
        return.
      </Typography>
    </Box>
    <Box>
      <Typography variant="h6" fontWeight={600}>
        Other Income & Adjustments
      </Typography>
      <Typography>
        For variable income sources and self-employment adjustments regardless
        of any other income or adjustments reported on the client’s prior year
        return.
      </Typography>
    </Box>
    <Box>
      <Typography variant="h6" fontWeight={600}>
        Schedule C, Schedule E, Schedule F, Form 4835
      </Typography>
      <Typography fontStyle="italic">
        If filed as part of the prior year return
      </Typography>
    </Box>
    <Box>
      <Typography variant="h6" fontWeight={600}>
        Itemized Deductions
      </Typography>
      <Typography fontStyle="italic">
        If the client itemized deductions on the prior year return
      </Typography>
    </Box>
    <Box>
      <Typography variant="h6" fontWeight={600}>
        Other Credits & Deductions{' '}
      </Typography>
      <Typography fontStyle="italic">
        Covers one-time or unique credits, such as those for qualifying energy
        efficient home improvements.
      </Typography>
    </Box>
    <Box>
      <Typography variant="h6" fontWeight={600}>
        State Questions
      </Typography>
      <Typography fontStyle="italic">
        For any State(s) filed in the prior year
      </Typography>
    </Box>
    <Divider />
    <Typography>
      This comprehensive approach ensures each client’s tax needs are fully
      covered, especially those with more varied or complex financial
      activities.
    </Typography>
  </Stack>
);
