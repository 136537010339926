import { Box, Stack, Typography } from '@mui/material';
import { SignedDocumentsPreview } from 'components/Requests/components/ActionBanner/SignedDocumentsPreview/SignedDocumentsPreview';
import { RPAPreview } from 'components/Requests/components/RPAPreview/RPAPreview';
import { RpaDocumentFragment, WorkflowWithSectionsFragment } from 'gql/graphql';

type WorkflowViewTitlePresentationProps = {
  title: string;
  description: string;
  sourceDocuments?: RpaDocumentFragment[];
  workflow?: WorkflowWithSectionsFragment;
  slots?: {
    infoBar?: React.ReactNode;
    controls?: React.ReactNode;
  };
};

export const WorkflowViewTitlePresentation = ({
  description,
  title,
  sourceDocuments,
  slots,
  workflow,
}: WorkflowViewTitlePresentationProps) => {
  const { infoBar, controls } = slots || {};

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="end"
        width="100%"
        pr="20px"
        gap="10px"
      >
        {infoBar}
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
        padding="20px"
      >
        <Box>
          <Typography variant="h2" fontWeight="bolder" component="h2">
            {title}
          </Typography>
          <Typography
            variant="subtitle1"
            color="text.secondary"
            paddingTop="10px"
            minHeight="37px"
            role="heading"
          >
            {description}
          </Typography>
          {sourceDocuments && <RPAPreview rpaDocuments={sourceDocuments} />}
          {workflow && <SignedDocumentsPreview workflow={workflow} />}
        </Box>
        {controls}
      </Stack>
    </>
  );
};
