/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import TaskList from 'components/Tasks/TaskList';
import ActionsModal from 'common/ActionsModal';

function EmailAddToTask(props) {
  const {
    showModal,
    cancelActionsModel,
    showModalFor,
    getModelTitle,
    getModelViewContext,
    emailDetails,
    addEmails,
    closeActionsModel,
    getSelectedRecords,
    selectedRecords,
  } = props;
  const showModalFooter = getModelViewContext(showModalFor) !== '';
  if (showModal) {
    console.log({ emailDetails });
    return (
      <div data-testid="taskModal">
        <ActionsModal
          isOpen={showModal}
          onCancel={cancelActionsModel}
          title={getModelTitle(showModalFor)}
          style={{ overflow: 'auto', maxHeight: '85vh' }}
          showModalFooter={showModalFooter}
          handleSuccessBtn={() =>
            addEmails(
              emailDetails.email_id,
              getModelViewContext(showModalFor),
              emailDetails.entity_email_thread_id,
            )
          }
          modalFor="addToTask"
          selectedRecords={selectedRecords}
        >
          <TaskList
            {...props}
            onCancel={closeActionsModel}
            getSelectedRecords={getSelectedRecords}
            componentContext="email"
          />
        </ActionsModal>
      </div>
    );
  }
  return null;
}
export default EmailAddToTask;
