import React, { Component } from 'react';
import { Link } from 'react-router-dom-latest';
import Axios from 'axios';
import {
  GET_TEMPLATE_PREVIEW,
  ADOBE_WEB_ACCESS_URL,
} from 'constants/constants';
import EsignTemplatesList from './EsignTemplatesList';
import TemplatePreview from './TemplatePreview';
import PrepareDocView from './PrepareDocView';

export default class EsignTemplates extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: false };
  }

  componentDidMount() {
    window.addEventListener('keypress', (e) => {
      if (e && e.which === 13) {
        e.preventDefault();
      }
    });
    if (!window.addEventListener) {
      window.attachEvent('onmessage', this.eventHandler);
    } else {
      window.addEventListener('message', this.eventHandler, false);
    }
    this.getAdobeWebAccessUrl();
  }

  componentWillUnmount() {
    window.addEventListener('keypress', () => true);
    window.removeEventListener('message', this.eventHandler, false);
  }

  getAdobeWebAccessUrl = () => {
    Axios.get(ADOBE_WEB_ACCESS_URL).then((res) => {
      if (res.status === 200 && res.data && res.data.web_access_url) {
        this.setState({ adobeWebAccessUrl: res.data.web_access_url });
      }
    });
  };

  eventHandler = (e) => {
    const { adobeWebAccessUrl } = this.state;
    if (
      e.origin === 'https://lisciodev1.na2.echosign.com' ||
      e.origin === 'https://lisciouat.na2.echosign.com' ||
      e.origin === adobeWebAccessUrl
    ) {
      const logdata = JSON.parse(e.data);
      if (logdata.data.pageName === 'POST_CREATE') {
        this.setState({ authoringUrl: undefined });
      }
    }
  };

  handleEdit = (input) => {
    const { id } = input;
    this.setState({ loading: true });
    Axios.get(`${GET_TEMPLATE_PREVIEW}/${id}?editview=true`).then((res) => {
      if (
        res.status === 200 &&
        res.data &&
        res.data.data &&
        res.data.data.length
      ) {
        this.setState({
          loading: false,
          authoringUrl: res.data.data[0].url,
        });
      } else {
        this.setState({ loading: false });
      }
    });
  };

  showTemplatePreview = (input) => {
    if (input && input.id) {
      this.setState({
        selectedTemplateId: input.id,
        loading: false,
      });
    } else {
      this.setState({ selectedTemplateId: undefined });
    }
  };

  navigateBack = () => {
    this.props.navigate('/esign_list');
  };

  render() {
    const { selectedTemplateId, authoringUrl } = this.state;
    if (authoringUrl) {
      return (
        <PrepareDocView
          authoringUrl={authoringUrl}
          pageTitle="Template Preview"
        />
      );
    }
    return (
      <div className="EsignTemplates">
        {this.state.loading ? <div id="loading" /> : null}
        <header>
          <h1 className="pageHeading">Template Library</h1>
        </header>
        <div className="center-wrapper">
          <div className="pb-3 d-flex justify-content-between align-items-center section-title">
            <h6 className="stepBacklink" role="presentation">
              <button
                type="button"
                onClick={this.navigateBack}
                className="EsignTemplates__BackButton"
              >
                <i className="icon-left-arrow" /> BACK
              </button>
            </h6>
            <div className="FilterHeader--Action  align-self-start text-right">
              <Link to="/esign_templates/new" className="btn btn-primary">
                + Add Template to Library
              </Link>
            </div>
          </div>
          <EsignTemplatesList
            assignSelectedTemplateId={this.showTemplatePreview}
            handleEditBtn={this.handleEdit}
          />
          {selectedTemplateId && (
            <TemplatePreview
              closeModal={this.showTemplatePreview}
              templateId={selectedTemplateId}
            />
          )}
        </div>
      </div>
    );
  }
}
