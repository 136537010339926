/* eslint-disable */
import Axios from "axios";

const API_URL = "https://rest.smartvault.com";
export const ROOT_FOLDER = 'nodes/pth'

export const queryParams = {
  path: "",
  children: 2,
  acl: null,
  eprop: 1,
  search: null,
  page: null,
  per_page: null,
  sort: null,
  direction: null,
};

const headers = {}

export const setAuthorizationHeader = (token) => {
  headers.Authorization = `Basic ${token}`;
};

const getQueryString = (obj) =>
  Object.entries(obj)
    .map((elem) => elem.join("="))
    .join("&");

export const fetchFilesAndFolders = async (params) => {
  const { path = "", signal, ...restParams } = params;
  const query = getQueryString(restParams);
  const url = `${API_URL}${path}?${query}`;
  return Axios({
    method: "get",
    url,
    headers,
    cancelToken: signal?.token
  });
};

export const downloadFile = ({ name, download_uri: uri, signal }) => {
  const url = `${API_URL}${uri}`;
  return Axios({
    method: "get",
    url: url,
    responseType: "blob",
    headers,
    cancelToken: signal.token
  })
};

export const buildHierarchy = async (path) => {
  let reconstructedUrl = ''
  const deconstructedUrl = [ROOT_FOLDER, path.replace(ROOT_FOLDER, '').split('/')].flat().filter(n => n)
  const promises = deconstructedUrl.map(async (child) => {
    reconstructedUrl += `/${child}`
    const response = await fetchFilesAndFolders({ path: reconstructedUrl })
    return response
  })
  const hierarchy = await Promise.all(promises);
  return hierarchy.map((child) => {
    return {
      name: child.data.message.name,
      uri: child.data.message.uri,
      nodeType: child.data.message.nodeType
    }
  })
}

export function base64ArrayBuffer(arrayBuffer) {
  let base64 = '';
  const encodings =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';
  const bytes = new Uint8Array(arrayBuffer);
  const byteLength = bytes.byteLength;
  const byteRemainder = byteLength % 3;
  const mainLength = byteLength - byteRemainder;
  let a,
    b,
    c,
    d;
  let chunk;
  // Main loop deals with bytes in chunks of 3
  for (let i = 0; i < mainLength; i += 3) {
    // Combine the three bytes into a single integer
    chunk = (bytes[i] << 16) | (bytes[i + 1] << 8) | bytes[i + 2];
    // Use bitmasks to extract 6-bit segments from the triplet
    a = (chunk & 16515072) >> 18; // 16515072 = (2^6 - 1) << 18
    b = (chunk & 258048) >> 12; // 258048   = (2^6 - 1) << 12
    c = (chunk & 4032) >> 6; // 4032     = (2^6 - 1) << 6
    d = chunk & 63; // 63       = 2^6 - 1
    // Convert the raw binary segments to the appropriate ASCII encoding
    base64 += encodings[a] + encodings[b] + encodings[c] + encodings[d];
  }
  // Deal with the remaining bytes and padding
  if (byteRemainder == 1) {
    chunk = bytes[mainLength];
    a = (chunk & 252) >> 2; // 252 = (2^6 - 1) << 2
    // Set the 4 least significant bits to zero
    b = (chunk & 3) << 4; // 3   = 2^2 - 1
    base64 += encodings[a] + encodings[b] + '==';
  } else if (byteRemainder == 2) {
    chunk = (bytes[mainLength] << 8) | bytes[mainLength + 1];
    a = (chunk & 64512) >> 10; // 64512 = (2^6 - 1) << 10
    b = (chunk & 1008) >> 4; // 1008  = (2^6 - 1) << 4
    // Set the 2 least significant bits to zero
    c = (chunk & 15) << 2; // 15    = 2^4 - 1
    base64 += encodings[a] + encodings[b] + encodings[c] + '=';
  }
  return base64;
}
