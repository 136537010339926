import axios from 'axios';
import {
  FIRMS_SIGNERS,
  CREATE_PAYMENT_ESTIMATES,
  PAYMENT_ESTIMATES,
} from 'constants/constants';

export function transformEstimateAmounts(
  estimate: PaymentEstimateObject,
  fn: Function,
): PaymentEstimateObject {
  return {
    ...estimate,
    q1_payment_estimate: fn(estimate.q1_payment_estimate),
    q2_payment_estimate: fn(estimate.q2_payment_estimate),
    q3_payment_estimate: fn(estimate.q3_payment_estimate),
    q4_payment_estimate: fn(estimate.q4_payment_estimate),
  };
}

export function centsToDollars(cents?: number): number {
  return cents ? cents / 100.0 : 0;
}

export function dollarsToCents(dollars?: number): number {
  return dollars ? dollars * 100.0 : 0;
}

export type BatchAssignContactsBody = {
  contact_id: string;
  kba_required?: boolean;
  sign_on_same_device?: boolean;
};

export const updateSigner = async (
  body: BatchAssignContactsBody & { signerId: string },
) => {
  const { data } = await axios.patch(`${FIRMS_SIGNERS}/${body.signerId}`, body);

  return data;
};

export async function getPaymentEstimates({ queryKey }: QueryCallParams) {
  const [, id] = queryKey;
  const { data } = await axios.get(PAYMENT_ESTIMATES(id));

  const dataWithDollars = data.map((item: PaymentEstimateObject) =>
    transformEstimateAmounts(item, centsToDollars),
  );

  return dataWithDollars as PaymentEstimateObject[];
}

export function createPaymentEstimates(body: PaymentEstimateObject) {
  const dataWithCents = transformEstimateAmounts(body, dollarsToCents);

  return axios.post(`${CREATE_PAYMENT_ESTIMATES}`, dataWithCents);
}
export function updatePaymentEstimates(body: PaymentEstimateObject) {
  const dataWithCents = transformEstimateAmounts(body, dollarsToCents);

  return axios.put(`${CREATE_PAYMENT_ESTIMATES}/${body.uuid}`, dataWithCents);
}

export function deletePaymentEstimates(id: string) {
  return axios.delete(`${CREATE_PAYMENT_ESTIMATES}/${id}`);
}
export interface PaymentEstimateObject {
  account_id?: Uuid;
  jurisdiction?: string;
  /** @nullable */
  q1_due_date?: string | null;
  /**
   * Q1 payment estimate in cents
   * @nullable
   */
  q1_payment_estimate?: number | null;
  /** @nullable */
  q2_due_date?: string | null;
  /**
   * Q2 payment estimate in cents
   * @nullable
   */
  q2_payment_estimate?: number | null;
  /** @nullable */
  q3_due_date?: string | null;
  /**
   * Q3 payment estimate in cents
   * @nullable
   */
  q3_payment_estimate?: number | null;
  /** @nullable */
  q4_due_date?: string | null;
  /**
   * Q4 payment estimate in cents
   * @nullable
   */
  q4_payment_estimate?: number | null;
  tax_year?: number;
  uuid?: Uuid;
}
