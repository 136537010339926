import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Box, Typography, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import styled from '@emotion/styled';

const ButtonDiv = styled.div`
  .inFocusBtn {
    align-self: flex-end;

    .MuiButtonBase-root {
      color: #fff;
      background-color: #00aecc;
      box-shadow: none;
      border-color: #00aecc;
      text-transform: none;
      font-size: 16px;
    }
  }
`;

const InFocusSectionHeader = ({
  fileIcon,
  title,
  value,
  inFocusCount,
  redirectUrl,
  accountID,
  listitemButton,
  linkto,
  listitemUrl,
  filterSelected,
}) => {
  const { hideCreateNewInvoiceButton } = useFlags();

  function handleViewAllBtnClick() {
    redirectUrl(
      `/accountdetails/${accountID}/${listitemUrl}`,
      false,
      filterSelected,
    );
  }

  function handleBtnClick() {
    redirectUrl(linkto, true);
  }

  return (
    <Box
      component="div"
      className="InFocusSectionHeader"
      id={value}
      justifyContent="space-between"
      sx={{ display: 'flex', width: '100%' }}
    >
      <Box
        component="div"
        sx={{ display: 'flex', alignItems: 'center' }}
        style={{ border: 'none' }}
      >
        <Typography
          component="h5"
          sx={{ display: 'flex', alignItems: 'center', mb: 0 }}
        >
          <i
            className={`${fileIcon} font-weight-bold text-dark mr-2`}
            aria-hidden="true"
          />{' '}
          {title}
          <span className="text-dark ml-1">{`(${inFocusCount[value]})`}</span>
        </Typography>
      </Box>
      <Box component="div" sx={{ display: 'flex', alignItems: 'center', minWidth: '200px' }}>
        <Button
          variant="text"
          key="edit_account"
          sx={{ mr: 3, color: '#212b36' }}
          onClick={handleViewAllBtnClick}
        >
          View All
        </Button>

        {
          // It would be ideal if InFocusSectionHeader is not aware of what
          // buttons it would receive. However, we are expecting to remove
          // the Invoice feature in a few months, and this is the place where
          // it is most convenient to toggle the button.
        }
        { !(value === 'invoices' && hideCreateNewInvoiceButton) &&
          <ButtonDiv
            component="div"
            className="inFocusBtn"
            sx={{ textAlign: 'right', mb: 0, border: 'none' }}
          >
            <Button
              type="button"
              id="infocusBtnInner"
              aria-expanded="false"
              variant="contained"
              onClick={handleBtnClick}
              startIcon={<AddIcon />}
              sx={{ minWidth: '100px' }}
            >
              {listitemButton}
            </Button>
          </ButtonDiv>
        }
      </Box>
    </Box>
  );
};

export default InFocusSectionHeader;
