import React, { useState } from 'react';
import PreviewFile from 'components/UploadedFiles/PreviewFile';

function PreviewAllAttachments({
  uploadType,
  docs,
  delAttachmentModal,
  taskDetail,
  fro,
  selectedDocument,
  closePreview,
}) {
  const [selectedIndex, setSelectedIndex] = useState(
    docs.findIndex((doc) => doc.id === selectedDocument.id),
  );

  function handleNextPrevPreview(index) {
    setSelectedIndex(index);
  }

  return (
    <PreviewFile
      uploadType={uploadType}
      previewAllAttachments="yes"
      previewAllDocuments={docs}
      document={docs[selectedIndex]} // document to show
      selectedDocumentIndex={selectedIndex} // index of document to show
      component={{
        delAttachmentModal,
      }}
      taskDetail={taskDetail}
      fro={fro}
      previewCloseAttachment={closePreview}
      handleNextPrevPreview={handleNextPrevPreview}
    />
  );
}

export default PreviewAllAttachments;
