/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-param-reassign */
import React, { Component } from 'react';
import axios from 'axios';
import Select, { components } from 'react-select';
import { Button } from 'reactstrap';
import { removeBreakElement, generateUniqueHash } from 'common/NotesUtilities';
import { AlertMessage, getCurrentPath } from 'utilities/utils';
import {
  SelectTemplateFieldOption,
  selectStyles,
  selectTheme,
} from 'common/CustomFields';
import * as TaskApi from 'api/TaskApi';
import ConfirmationModal from 'common/ConfirmationModal';
import TaskFileUpload from 'components/Files/TaskFileUpload';
import {
  GET_TEMPLATES_FOR_CREATION,
  CREATE_DOC_FROM_TEMPLATE_IDS,
} from 'constants/constants';
import { withRouter } from 'utilities/withRouterWrapper';
import { format } from 'date-fns';
import TinymceEditor from 'components/Emails/components/TinymceEditior';
import CommonFields from 'components/Tasks/Create/CommonFields';
import CommonFieldsEmail from 'components/Tasks/Create/CommonFieldsEmail';
import TaskFooterButtons from './TaskFooterButtons';

const noneStyle = { display: 'none' };
const blockStyle = { display: 'block' };

class Request extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stateValues: {},
      errors: {},
      taskTitle:
        this.props.taskDetail && this.props.taskDetail.subject
          ? this.props.taskDetail.subject
          : '',
      taskStatus: '',
      documents:
        this.props.taskDetail && this.props.taskDetail.documents
          ? this.props.taskDetail.documents
          : [],
      comment:
        this.props.taskDetail && this.props.taskDetail.comment
          ? this.props.taskDetail.comment
          : '',
      viewModal: false,
      loading: false,
      dataUpdated: false,
      selectedOption: null,
      uniqueId: generateUniqueHash(5),
      templateOptions: [],
      templateDocuments: [],
      warningMsgLost: false,
      selectedTemplate: null,
      titleChange: '',
    };
    this.templateDropdown = this.templateDropdown.bind(this);
    this.selectInputRef = React.createRef();
  }

  componentDidMount() {
    const { data, taskDetail } = this.props;
    if (data.viewContext) {
      this.setState({
        taskTitle: taskDetail && taskDetail.subject ? taskDetail.subject : '',
        documents:
          taskDetail && taskDetail.documents ? taskDetail.documents : [],
        comment:
          taskDetail && taskDetail.description ? taskDetail.description : '',
      });
    }
    this.getTemplates();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.taskDetail.description !== this.props.taskDetail.description) {
      this.setState({ comment: this.props.taskDetail.description })
    }
  }

  UNSAFE_componentWillReceiveProps() {
    const { data } = this.props;
    if (
      (Object.keys(this.props.taskDetail).length &&
        data.isEdit &&
        !data.changedOnce) ||
      data.viewContext
    ) {
      const detail = this.props.taskDetail;
      // console.log(JSON.parse(detail.description_in_json));
      this.setState({
        taskTitle: detail.subject,
        comment: detail.description_for_web_display,
        documents: detail.documents || [],
        taskStatus: detail.status,
      });
    }
  }

  componentWillUnmount() {
    if (this.state.documents.length) {
      const docIds = [];
      let x;
      for (x = 0; x < this.state.documents.length; x += 1) {
        docIds.push(this.state.documents[x].id);
      }
      const data = { doc_ids: docIds };
      TaskApi.cancelDocuments(this, data, 'tasks');
    }
  }

  setTasktitle = (value) => {
    this.setState({
      taskTitle: value,
      dataUpdated: true,
    });
  };

  getTemplateDocsId = () => {
    const mydata = {};
    if (this.state.templateDocuments.length) {
      mydata.template_doc_ids = [];
      let x;
      for (x = 0; x < this.state.templateDocuments.length; x += 1) {
        mydata.template_doc_ids.push(this.state.templateDocuments[x].id);
      }
    }
    mydata.template_type = 'task_template';
    if (this.state.templateDocuments.length) {
      this.setState({ loading: true });
      const request = axios.post(CREATE_DOC_FROM_TEMPLATE_IDS, mydata);
      request
        .then((response) => {
          this.setState({ loading: false });
          if (response.data.status === 200) {
            const documents = [...this.state.documents];
            if (documents.length) {
              this.setState({
                documents: response.data.documents,
                templateDocuments: [],
                dataUpdated: true,
              });
            }
          } else {
            AlertMessage('error', response.data.message, 1500);
          }
        })
        .catch(() => {
          this.setState({ loading: false });
        });
    }
  };

  getTemplates() {
    this.setState({ loading: true });
    axios
      .get(`${GET_TEMPLATES_FOR_CREATION}?template_type=task_template`)
      .then((res) => {
        this.setState({ loading: false });
        if (res.data && res.data.data && res.data.data.length) {
          const { data, default_preferences } = res.data;
          this.setState({
            templateOptions: data,
            loading: false,
            defaultPreferences: default_preferences,
          });
        } else {
          this.setState({
            templateOptions: [],
            loading: false,
          });
        }
      })
      .catch(() => {
        this.setState({
          templateOptions: [],
          loading: false,
        });
      });
  }

  inputRenderer = (inputProps) => (
    <components.Input
      {...inputProps}
      value={this.state.titleChange}
      className="Select-input"
    />
  );

  templateDropdown(selectedOption) {
    const { taskTitle, comment, documents, warningMsgLost } = this.state;
    let commentExist = false;
    if (comment && comment.length) {
      commentExist = true;
    }
    if (
      (taskTitle || commentExist || documents.length) &&
      !warningMsgLost &&
      (!selectedOption || (selectedOption && !taskTitle))
    ) {
      this.setState({ warningMsgLost: true, selectedTemplate: selectedOption });
    } else if (selectedOption && selectedOption !== null) {
      const { content, value } = selectedOption;
      const coment = removeBreakElement(content);
      this.setState({
        selectedOption,
        taskTitle: value,
        titleChange: '',
        comment: coment,
        warningMsgLost: false,
        selectedTemplate: null,
        templateDocuments:
          selectedOption.documents && selectedOption.documents.length
            ? selectedOption.documents
            : [],
        documents:
          selectedOption.documents && selectedOption.documents.length
            ? selectedOption.documents
            : [],
      });
    } else {
      this.setState(
        {
          selectedOption: null,
          taskTitle: '',
          titleChange: '',
          comment: '',
          warningMsgLost: false,
          selectedTemplate: null,
          documents: [],
          templateDocuments: [],
        },
        () => {
          const node = this.selectInputRef.current;
          if (node) {
            node.state.inputValue = '';
          }
        },
      );
    }
  }

  templateChange = (input, action) => {
    const val =
      action.action === 'input-change' ? input : action.prevInputValue;
    if (val || (!val && this.state.titleChange)) {
      this.setState({
        taskTitle: val,
        titleChange: val,
      });
    }
  };

  stateChange = (value, dataUpdated = false) => {
    const stateValues = { ...this.state.stateValues, ...value };
    this.setState({
      stateValues,
      dataUpdated,
    });
  };

  handleCancel = () => {
    const { stateValues, dataUpdated } = this.state;
    if (stateValues.isChanged || dataUpdated) {
      this.setState({ viewModal: true });
    } else {
      this.props.goBack();
    }
  };

  hideViewModal = () => {
    this.setState({ viewModal: false });
  };

  handleCommentChange = (value) => {
    this.setState({
      comment: value,
      dataUpdated: true,
    });
    if (this.state.templateDocuments && this.state.templateDocuments.length) {
      this.getTemplateDocsId();
    }
  };

  handleChange = (e) => {
    this.setState({
      errors: {},
      taskTitle: e.target.value,
      dataUpdated: true,
    });
  };

  nextPage = () => {
    const { state } = this.props.location;
    if (
      this.props.data.isEdit &&
      state !== undefined &&
      state.from === '/task/detail'
    ) {
      this.props.navigate(`/task/detail/${this.props.params.id}`);
    } else if (state && state.from === '/task/new') {
      this.props.navigate(-2);
    } else {
      // need this for when task is created from email
      this.props.goBack();
    }
  };

  handleValidation = () => {
    const { stateValues, taskTitle } = this.state;
    const err = {};
    let formIsValid = true;
    if (
      stateValues.accounts &&
      stateValues.accounts.length &&
      (!stateValues.accountId || stateValues.accountId === '')
    ) {
      formIsValid = false;
      err.account_error = 'Please select account';
    }
    if (stateValues.contactId === '' || !stateValues.contactId) {
      formIsValid = false;
      err.contact_error = 'Please select contact';
    }
    if (stateValues.ownerId === '' || !stateValues.ownerId) {
      formIsValid = false;
      err.owner_error = 'Please select task owner';
    }
    if (taskTitle === '' || !taskTitle) {
      formIsValid = false;
      err.taskTitle_error = 'Enter task title';
    }
    this.setState({ errors: err });
    return formIsValid;
  };

  emptyError = () => {
    this.setState({ errors: {} });
  };

  submitForm = (e) => {
    e.preventDefault();
    const {
      location,
      data: { externalDocs, emailId },
    } = this.props;
    const { stateValues, comment, taskTitle } = this.state;
    const path = getCurrentPath(location);
    if (this.handleValidation()) {
      let mydata = {};
      if (this.props.data.isEdit) {
        mydata.cpa_id = stateValues.cpaId;
        mydata.subject = taskTitle;
        mydata.due_by = format(stateValues.startDate, 'dd/MM/yyyy');
        if (stateValues.ownerChanged) {
          mydata.owner_id = stateValues.ownerId;
        }
      } else {
        if (path === '/contactdetails/task/:id') {
          mydata.assigne_type = stateValues.contactDetails.contact.cpa_user_id
            ? 'user'
            : 'contact';
          mydata.assigned_to_user = stateValues.contactDetails.contact
            .cpa_user_id
            ? stateValues.contactDetails.contact.cpa_user_id
            : stateValues.contactDetails.contact.id;
        } else {
          mydata.assigne_type = stateValues.assigneeType;
          mydata.assigned_to_user = stateValues.contactId;
        }
        mydata.cpa_id = stateValues.cpaId;
        mydata.owner_id = stateValues.ownerId;
        mydata.for_account = stateValues.accountId;
        mydata.subject = taskTitle;
        mydata.due_by = format(stateValues.startDate, 'MM/dd/yyyy');
        mydata.task_type_key = this.props.data.taskTypeKey;
        mydata.task_type_value = this.props.data.taskTypeValue;
      }
      if (stateValues.recurring_properties) {
        mydata = { ...mydata, ...stateValues.recurring_properties };
      }
      if (typeof comment === 'string' && comment.length) {
        mydata.description = comment;
      } else {
        mydata.description = '';
      }

      // if (mydata.description && mydata.description.length > 0) {
      //   mydata.description = mydata.description.replace(/\n/g, '<br>');
      // }
      let { documents } = this.state;
      if (externalDocs && externalDocs.length) {
        documents = [...documents, ...externalDocs];
      }
      if (emailId) {
        mydata.email_id = emailId;
      }
      if (documents.length && !this.props.data.isEdit) {
        mydata.doc_ids = [];
        let x;
        for (x = 0; x < documents.length; x += 1) {
          mydata.doc_ids.push(documents[x].id);
        }
      }
      if (this.props.data.isEdit) {
        const { id } = this.props.params;
        TaskApi.createTask(this, mydata, id, this.props.data.taskTypeKey); // updates task
      } else {
        TaskApi.createTask(this, mydata);
      }
    }
    return false;
  };

  updateDocumentState = (documents) => {
    this.setState({ documents });
  };

  render() {
    const {
      errors,
      taskTitle,
      taskStatus,
      stateValues,
      dataUpdated,
      selectedOption,
      templateOptions,
      selectedTemplate,
    } = this.state;
    const { data } = this.props;
    const recurringDisable =
      stateValues.recurring_properties &&
      stateValues.recurring_properties.recurringDropdown;

    return (
      <div data-testid="requestInformation">
        <div className="miscellaneous" data-testid="requestInfoTestId">
          <div
            id="loading"
            style={this.state.loading ? blockStyle : noneStyle}
          />
          {this.props?.data?.viewContext === 'email' ? (
            <CommonFieldsEmail
              data={this.props.data}
              statedata={this.state}
              taskDetail={this.props.taskDetail}
              stateChange={this.stateChange}
              emptyError={this.emptyError}
              setTasktitle={this.setTasktitle}
              removeparticularTaskType={this.props.removeparticularTaskType}
              match={this.props.match}
              location={this.props.location}
              params={this.props.params}
              handleTaskTypeChange={this.props.handleTaskTypeChange}
            />
          ) : (
            <CommonFields
              data={data}
              statedata={this.state}
              taskDetail={this.props.taskDetail}
              stateChange={this.stateChange}
              emptyError={this.emptyError}
              setTasktitle={this.setTasktitle}
              removeparticularTaskType={this.props.removeparticularTaskType}
              match={this.props.match}
              location={this.props.location}
              params={this.props.params}
            />
          )}
          <div className="row">
            <div
              className={`form-group col-12${
                errors.taskTitle_error
                  ? ' form-error cstmClr msgCustmClr'
                  : ' cstmClr msgCustmClr'
              }`}
            >
              <label htmlFor="taskTitle">Subject</label>
              <div className="labelValue customEditor boxResize flWdth">
                {(selectedOption && selectedOption !== null) ||
                (!selectedOption &&
                  selectedOption === null &&
                  taskTitle &&
                  !this.state.titleChange) ||
                data.isEdit ? (
                  <input
                    autoComplete="off"
                    disabled={taskStatus === 'Closed'}
                    name="taskTitle"
                    className="form-control"
                    id="taskTitle"
                    type="text"
                    value={taskTitle}
                    maxLength="200"
                    onChange={this.handleChange}
                  />
                ) : (
                  <Select
                    name="taskTitle"
                    placeholder=""
                    ref={this.selectInputRef}
                    className="select-custom-class slctTemp"
                    value={selectedOption}
                    onChange={this.templateDropdown}
                    options={templateOptions}
                    optionComponent={SelectTemplateFieldOption}
                    inputValue={this.state.titleChange}
                    onInputChange={this.templateChange}
                    tabSelectsValue={false}
                    onBlurResetsInput={false}
                    onCloseResetsInput={false}
                    onSelectResetsInput={this.state.titleChange} // false shows
                    noResultsText=""
                    isDisabled={data.isEdit}
                    styles={selectStyles}
                    theme={selectTheme}
                    classNamePrefix="custom-react-select"
                    components={{
                      Option: SelectTemplateFieldOption,
                      Input: this.inputRenderer,
                    }}
                  />
                )}
              </div>
              {taskTitle && !data.isEdit ? (
                <Button
                  className="btn btn-sm btn-primary"
                  onClick={() => this.templateDropdown(null)}
                >
                  Clear
                </Button>
              ) : null}
              <span className="error_label">{errors.taskTitle_error}</span>
            </div>
          </div>
          <div className="row">
            <div className="form-group col-lg-12">
              <div className="has-float-label customEditor boxResize">
                <label htmlFor="taskDescription">Description</label>
                <TinymceEditor
                  value={this.state.comment}
                  handleChange={this.handleCommentChange}
                  disabled={taskStatus === 'Closed'}
                  testid="task__description"
                />
              </div>
            </div>
          </div>
          {(data.isEdit && this.state.documents.length) || !data.isEdit ? (
            <div className="row">
              <div className="form-group dragWrap col-lg-12">
                <label htmlFor="attachments">Attachments</label>
                <TaskFileUpload
                  data={this.state}
                  uploadType="task"
                  externalDocs={data.externalDocs}
                  isEdit={data.isEdit}
                  propsData={data}
                  updateDocumentState={this.updateDocumentState}
                  deleteEmailAttachment={this.props.deleteEmailAttachment}
                />
              </div>
            </div>
          ) : null}
          <div className="row align-items-center">
            <div className="col-12 form-group formbtn justify-content-end btn--leftspace">
              {/* data.gettingEmailAttachments ?
                <div className="col align-self-center">
                  Getting email attachments
                </div>
                :
                null
              */}
              <div
                className={`row ${data.viewContext === 'email' ? 'w-100' : ''}`}
              >
                <div className="col-12 form-group formbtn justify-content-between btn--leftspace">
                  <TaskFooterButtons
                    data={data}
                    handleCancel={this.handleCancel}
                    taskStatus={taskStatus}
                    recurringDisable={recurringDisable}
                    dataUpdated={dataUpdated}
                    stateValues={stateValues}
                    submitForm={this.submitForm}
                  />
                </div>
              </div>
              {/* WIll be removed after checking functionality */}
              {/* <div className="btn-wrap btn--leftspace">
                <button
                  type="button"
                  className="btn btn-outline-light"
                  onClick={this.handleCancel}
                  disabled={taskStatus === 'Closed' || recurringDisable}
                >
                  Cancel
                </button>
                <button
                  data-testid="createTaskRequestInfo"
                  type="submit"
                  className="btn btn-primary task-form-submit"
                  onClick={this.submitForm}
                  disabled={
                    taskStatus === 'Closed' ||
                    recurringDisable ||
                    (data.isEdit && !dataUpdated && !stateValues.isChanged) ||
                    (this.props.data && this.props.data.gettingEmailAttachments)
                  }
                >
                  {!data.isEdit ? 'Create Task' : 'Update Task'}
                </button>
              </div> */}
            </div>
          </div>
        </div>
        <ConfirmationModal
          isOpen={this.state.viewModal}
          headerText="Are you sure want to cancel."
          messageText="This action cannot be undone. Proceed?"
          noBtnClick={this.hideViewModal}
          yesBtnClick={this.nextPage}
          yesBtnText="Yes"
          noBtnText="No"
        />
        <ConfirmationModal
          isOpen={this.state.warningMsgLost}
          className="customModal customModal--w500 customModal--nopadding"
          messageText="You are about to delete the existing message. This action cannot be undone. Proceed?"
          noBtnClick={() => this.setState({ warningMsgLost: false })}
          yesBtnClick={() => this.templateDropdown(selectedTemplate)}
          yesBtnText="Yes"
          noBtnText="No"
        />
      </div>
    );
  }
}

export default withRouter(Request);
