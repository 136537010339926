import { useFormContext } from 'react-hook-form';
import {
  MFAFormType,
  MFAFormContent,
  MFAFormContentProps,
} from './components/MFAFormContent';

export type FormContextMFAFormProps = Pick<MFAFormContentProps, 'backToLogin'>;

/**
 * This MFAForm relies on a shared FormContext.
 * This is NOT the way. Please refrain from using shared
 * FormContext for multiple forms going forward.
 */
export const FormContextMFAForm = ({
  backToLogin,
}: FormContextMFAFormProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<MFAFormType>();

  const rootError = errors?.root?.serverError?.message;

  return (
    <MFAFormContent
      register={register}
      error={rootError}
      errors={errors}
      backToLogin={backToLogin}
    />
  );
};
