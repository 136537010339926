import { useEffect, useRef, useState } from 'react';
import { getStorageData, setUserInLocalStorage } from 'utilities/utils';

export const useGetUserFromLocalStorage = () => {
  const [storedUser, setStoredUser] = useState<StoredUser>(getStorageData());

  // sometimes localStorage isn't updated yet
  useEffect(() => {
    const handleStorageChange = () => {
      setStoredUser(getStorageData());
    };

    window.addEventListener('storage', handleStorageChange);

    return () => window.removeEventListener('storage', handleStorageChange);
  }, []);

  const firstRun = useRef(true);
  useEffect(() => {
    if (!firstRun.current) {
      setUserInLocalStorage(storedUser);
    }
  }, [storedUser]);

  useEffect(() => {
    firstRun.current = false;
  }, []);

  return { storedUser, setStoredUser };
};
