import { IntegrationModule } from 'components/Integrations/integration-modules/integration-module.types';
import { StripeIntegrationListItem } from './StripeIntegrationListItem';
import { StripeIntegrationConfig } from './StripeIntegrationConfig';

export const stripeIntegrationModule: IntegrationModule = {
  id: 'stripe',
  path: 'stripe',
  title: 'Stripe',
  getActivationStatus: async () => {
    await console.log('TODO: getStripeActivationStatus');
    return false;
  },
  listComponent: <StripeIntegrationListItem />,
  configComponent: <StripeIntegrationConfig />,
};
