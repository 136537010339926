import React, { useState, useEffect, useReducer, useMemo } from 'react';
import Axios from 'axios';
import styled from '@emotion/styled';
import {
  UPDATE_EMAILS_STATUS,
  USER_EMAILS,
  USER_EMAIL_SETTINGS,
  UNREAD_EMAIL,
  UPDATE_SUCCESS,
  listMode,
  splitMode,
  detailedMode,
} from 'constants/constants';
import {
  AlertMessage,
  getOffSetsAndLimit,
  totalPages,
  totalRecords,
  getCanViewPreferences,
  getLoggedInType,
  getSiteHostname,
} from 'utilities/utils';
import HeaderTabs from 'common/HeaderTabs';
import emailsReducer from 'reducers/EmailsReducer';
// import Button from 'common/Button/Button';
import NoRecordInEmails from 'common/NoRecordInEmails';
import LoaderDefault from 'common/LoaderDefault';
import NoRecords from 'common/NoRecords';
import { useParams } from 'react-router-dom-latest';
import { debounce } from '@mui/material';
import { emailTabs, mungeEmail } from 'components/Email/EmailUtilities';
import useMountedState from 'components/Emails/components/useMountedState';
import { useWebsocket } from 'websocket';
import { getAuthSession } from 'auth/session';
import { useLinkedEmailsUrl } from 'hooks/use-linked-emails-url';
import EmailsList from './EmailsList';
import SearchMessage from './SearchMessage';
import FilterBy from './FilterBy';
import ComposeEmail from './ComposeEmail';
import EmailNextPrevView from './EmailNextPrevView';
import useWhichSection from './useWhichSection';

const getHeaderStyle = ({ inLinkedSection, showFiltersMode, viewMode }) =>
  inLinkedSection && viewMode !== 'detailed' && showFiltersMode === 'split'
    ? 'flex-end'
    : 'space-between';

const StackWrap = styled.div`
  position: 'relative';
  header {
    padding-top: 0;
    padding-bottom: 0;
  }
  .EmailMain {
    paddin-top: 1rem !important;
  }
  .EmailMain__StackInn {
    display: flex;
    width: 100%;
    justify-content: ${getHeaderStyle};
    margin-bottom: 1rem;
  }
  .EmailMain__BtnSearchWrap {
    display: flex;
    align-content: center;
  }
`;

let tokenInterval = null;
// TODO: find better way to handle websockets
// eslint-disable-next-line no-unused-vars
let listEmailSubscription;

const getDataOfUrl = ({ activeTab, filter, pagination, sortBy, sortOrder }) => {
  const url = localStorage.getItem('emailsUrl');
  const searchParams = new URLSearchParams(
    window.location.search?.includes('page') ? window.location.search : url,
  );
  let searchedText = '';
  let associatedContact = false;
  let currentTab = activeTab;
  let pageNumber = pagination;
  let filterBy = filter;
  let sort = sortBy;
  let order = sortOrder;
  searchParams.forEach((value, key) => {
    if (key.includes('filter')) {
      currentTab = emailTabs.findIndex(
        (each) => each.value.toLowerCase() === value.toLowerCase(),
      );
      if (currentTab < 0) {
        currentTab = 0;
      }
      filterBy = value;
    } else if (key === 'page') {
      pageNumber = parseInt(value, 10);
    } else if (key === 'sort_by') {
      sort = value;
    } else if (key === 'sort_order') {
      order = value;
    } else if (key === 'search') {
      searchedText = value;
    } else if (key === 'associate_with_contact') {
      associatedContact = value;
    }
  });
  return {
    activeTab: currentTab,
    filter: filterBy,
    pagination: pageNumber,
    searchedText,
    associatedContact,
    sortBy: sort,
    sortOrder: order,
  };
};
const EmailsSection = ({
  viewContext,
  accountId,
  contactId,
  navigate,
  location,
}) => {
  const linkedEmailsUrl = useLinkedEmailsUrl();

  document.title = 'Emails';
  const { inContactSection, inAccountSection, inLinkedSection } =
    useWhichSection();
  const { email_id } = useParams();

  const [state, setState] = useState({
    totalPages: 0,
    totalRecords: 0,
    emails: [],
    tabs: emailTabs,
    offset: [0, 25],
    ...getDataOfUrl({
      activeTab: 0,
      filter: 'primary',
      pagination: 1,
      sortBy: 'name',
      sortOrder: 'desc',
    }),
    selectedAll: false,
    seledtedPageNo: null,
  });
  const [dataReceived, setDataReceived] = useState(true);
  const [searchFieldText, setSearchFieldText] = useState('');
  const [emailIntegratedWith, setEmailIntegratedWith] = useState('');
  const [associatedContactCheck, setAssociatedContactCheck] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [selectedSrcEmailId, setSelectedSrcEmailId] = useState([]);
  const [userEmailIntegrated, setUserEmailIntegrated] = useState(false);
  const [emailState, dispatch] = useReducer(emailsReducer);
  const [idIndex, setIdIndex] = useState(null);
  const [nextId, setNextId] = useState(null);
  const [showFiltersMode, setShowFilters] = useState('split');
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [previousIndex, setPreviousIndex] = useState(null);
  const [nextIndex, setNextIndex] = useState(null);
  const [viewMode, setViewMode] = useState('split');
  const [prevViewMode, setPrevViewMode] = useState('list');
  const [loading, setLoading] = useState(true);
  const [isEmailConnected, setIsEmailConnected] = useState(false);

  const switchedUser = getLoggedInType();
  const canViewData = getCanViewPreferences();

  const websocket = useWebsocket();

  const handlePageChange = (pageInput = 1) => {
    setState((currentState) => ({ ...currentState, pagination: pageInput }));

    const url = makePageURL(pageInput);

    showListIfInDetailMode();
    navigate(url);
  };

  function makePageURL(pageInput) {
    const params = new URLSearchParams(window.location.search);
    const page = pageInput || params.get('page') || 1;
    let prefix;
    if (inContactSection) {
      prefix = `/contactdetails/${contactId}/emails?page=${page}`;
    } else if (inAccountSection) {
      prefix = `/accountdetails/${accountId}/emails?page=${page}`;
    } else {
      const { filter, sortBy, sortOrder } = state;
      prefix = `/emails?filter=${filter}&page=${page}&sort_by=${sortBy}&sort_order=${sortOrder}`;
    }
    const search = searchFieldText ? `&search=${searchFieldText}` : '';
    const associatedContact = associatedContactCheck
      ? `&associate_with_contact=${associatedContactCheck}`
      : '';

    return `${prefix}${search}${associatedContact}`;
  }

  const areAllRecordsSelected = () => {
    let x = 0;
    const { emails } = state;
    let { selectedAll, seledtedPageNo } = state;
    emails.map((e) => {
      if (e.isSelected) x += 1;
      return e;
    });
    const checkbox = document.querySelector('#selectallemailscheckbox');
    if (x === emails.length) {
      selectedAll = true;
      seledtedPageNo = state.pagination;
      if (checkbox) checkbox.indeterminate = false;
    } else {
      selectedAll = false;
      seledtedPageNo = null;
      if (checkbox) checkbox.indeterminate = true;
    }

    setState({ ...state, emails, selectedAll, seledtedPageNo });
    if (x === 0) {
      if (checkbox) checkbox.indeterminate = '';
    }
  };

  const fetchLinkedEmails = async (discard = '') => {
    const { pagination = 1, sortBy = 'name', sortOrder = 'asc' } = state;
    let apiUrl = linkedEmailsUrl;
    apiUrl += `?page=${pagination}&sort_by=${sortBy}&sort_order=${sortOrder}`;
    if (viewContext && viewContext === 'account') {
      apiUrl += `&entity_type=account&entity_id=${accountId}`;
    } else if (viewContext && viewContext === 'contact') {
      apiUrl += `&entity_type=contact&entity_id=${contactId}`;
    }
    const response = await Axios.get(apiUrl);

    const { stats } = response.data;

    if (stats) {
      setIsEmailConnected(true);
      setLoading(false);
    }

    const emails = response.data.email_data.map(mungeEmail);

    const totalEmails = response.data.stats.total_emails;
    const connectedApp = response.data.connected_app;

    setState((currentState) => ({
      ...currentState,
      emails,
      connectedApp,
      totalPages: Number(Math.ceil(totalEmails / 25)),
      totalRecords: totalEmails,
      offset: getOffSetsAndLimit(totalEmails, 25)[pagination - 1],
    }));

    setLoading(false);

    if (discard) handlePageChange();
  };

  const fetchUserEmails = async (discardData = '') => {
    try {
      if (!switchedUser || (canViewData && canViewData[0].key === 'email')) {
        const {
          pagination,
          sortBy,
          sortOrder,
          filter,
          searchedText,
          associatedContact,
        } = getDataOfUrl(state);
        const search = searchedText;
        setSearchFieldText(search);
        const IsAssociated = associatedContact;
        setAssociatedContactCheck(IsAssociated);
        if (
          discardData ||
          (state.selectedAll &&
            pagination === state.totalPages &&
            state.totalPages > 1 &&
            state.seledtedPageNo === state.totalPages)
        ) {
          handlePageChange(
            state.selectedAll &&
              pagination === state.totalPages &&
              state.totalPages > 1 &&
              state.seledtedPageNo === state.totalPages
              ? 1
              : pagination,
          );
        } else {
          let apiUrl = USER_EMAILS;
          apiUrl += `?filter=${filter}&page=${pagination}&sort_by=${sortBy}&sort_order=${sortOrder}${
            search ? `&search=${search}` : ''
          }${IsAssociated ? `&associate_with_contact=${IsAssociated}` : ''}`;
          if (viewContext && viewContext === 'accountpage') {
            apiUrl += `&entity_type=account&entity_id=${accountId}`;
          } else if (viewContext && viewContext === 'contactpage') {
            apiUrl += `&entity_type=contact&entity_id=${contactId}`;
          }
          localStorage.setItem(
            'emailsUrl',
            `?filter=${filter}&page=${pagination}&sort_by=${sortBy}&sort_order=${sortOrder}${
              search ? `&search=${search}` : ''
            }${IsAssociated ? `&associate_with_contact=${IsAssociated}` : ''}`,
          );

          const response = await Axios.get(apiUrl);
          const { emails } = response.data.email_data.reduce(
            (acc, email) => {
              const emailObject = mungeEmail(email);

              if (
                emailState &&
                emailState.emailsSelectedRecords &&
                emailState.emailsSelectedRecords.length
              ) {
                emailObject.isSelected =
                  emailState.emailsSelectedRecords.includes(email.id);
              }
              acc.emails.push(emailObject);
              return acc;
            },
            { emails: [] },
          );
          const { stats } = response.data;
          if (response && stats) {
            setIsEmailConnected(true);
            setLoading(false);
          }
          const tabs = [];
          emailTabs.map((each) => {
            const output = { ...each };
            output.label =
              stats[each.value] !== undefined
                ? `${output.label} | ${stats[each.value]}`
                : `${output.label} | 0`;
            output.url = `/emails?filter=${
              each.value
            }&page=1&sort_by=${sortBy}&sort_order=${sortOrder}${
              search ? `&search=${search}` : ''
            }${IsAssociated ? `&associate_with_contact=${IsAssociated}` : ''}`;
            output.noofrecords = stats[each.value];
            tabs.push(output);
            return each;
          });

          setState({
            ...state,
            ...getDataOfUrl(state),
            emails,
            tabs,
            totalPages: totalPages(filter, tabs, 'value', 25),
            totalRecords: totalRecords(filter, tabs, 'value'),
            offset: getOffSetsAndLimit(totalRecords(filter, tabs, 'value'), 25)[
              pagination - 1
            ],
          });
        }
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const fetchEmails = inLinkedSection ? fetchLinkedEmails : fetchUserEmails;

  useEffect(() => {
    setLoading(true);
    fetchEmails();
  }, [location.search, location.associate_with_contact, location.pathname]);

  useEffect(() => {
    areAllRecordsSelected();
  }, [state.emails]);

  useEffect(() => {
    if (inLinkedSection && !email_id) {
      showListIfInDetailMode();
    }
  }, [email_id]);

  useEffect(() => {
    if (dataReceived) {
      setDataReceived(false);
      fetchEmails();
    }
  }, [dataReceived]);

  useEffect(() => {
    dispatch({
      type: 'EMAILS_SELECTED_RECORDS',
      payload: {},
    });
  }, [state.filter]);

  useEffect(() => {
    setIdIndex(null);
    setNextId(null);
    setShowFilters('split');
    if (isMounted()) fetchEmails('discardData');
    switch (viewMode) {
      case listMode:
        return setViewMode(listMode);
      case detailedMode:
        return setViewMode(listMode);
      case splitMode:
        return setViewMode(splitMode);
      default:
        return '';
    }
  }, [state.activeTab]);

  useEffect(handlePageChange, [searchFieldText]);

  useEffect(() => {
    const request = Axios.get(USER_EMAIL_SETTINGS);
    request.then((response) => {
      if (response.status === 200) {
        if (response.data.app_token?.app_key) {
          setUserEmailIntegrated(!!response.data.app_token?.app_key);
          setEmailIntegratedWith(response.data.app_token?.app_key);
        }
      }
    });
    if (getAuthSession()) {
      tokenInterval = setInterval(() => {
        // TODO: __HOMEDATA-AUDIT
        const local_data = JSON.parse(
          localStorage.getItem(`${getSiteHostname().split(':')[0]} data`),
        );
        if (local_data) {
          clearInterval(tokenInterval);
          listEmailSubscription = websocket.consumer.subscriptions.create(
            {
              channel: 'UserEmailNotificationChannel',
              cpa_user_id: local_data.cpa_user_id,
            },
            {
              connected: () => {
                console.log('connected to user email notification channel');
              },
              received: (dataReceive) => {
                console.log('Data received karbon - ', dataReceive);
                if (dataReceive) {
                  setDataReceived(true);
                }
              },
              disconnected: () => {
                console.log(
                  'disconnected from user email notification channel',
                );
              },
            },
          );
        }
      }, 200);
    }
    return () => {
      localStorage.removeItem('emailsUrl');
      dispatch({
        type: 'EMAILS_SELECTED_RECORDS',
        payload: {},
      });
    };
  }, []);

  const toggleSelect = (index) => {
    const { emails } = state;
    let selectedDat = selectedData;
    let selectedSrcEmailid = selectedSrcEmailId;
    if (emails[index].isSelected) {
      emails[index].isSelected = false;
      selectedDat = selectedDat.filter((e) => emails[index].id !== e);
      selectedSrcEmailid = selectedSrcEmailid.filter(
        (e) => emails[index].src_email_id !== e,
      );
    } else {
      emails[index].isSelected = true;
      selectedDat.push(emails[index].id);
      selectedSrcEmailid.push(emails[index].src_email_id);
    }

    setState({ ...state, emails });
    setSelectedData(selectedDat);
    setSelectedSrcEmailId(selectedSrcEmailid);
    const obj = { id: selectedDat, srcEmailId: selectedSrcEmailid };
    dispatch({
      type: 'EMAILS_SELECTED_RECORDS',
      payload: obj,
    });
    areAllRecordsSelected();
  };

  const toggleSelectAll = () => {
    const { emails, selectedAll, pagination } = state;
    setState({
      ...state,
      emails: emails.map((email) => ({ ...email, isSelected: !selectedAll })),
      selectedAll: !selectedAll,
      seledtedPageNo: selectedAll ? pagination : null,
    });

    let arr = [];
    let srcArr = [];
    if (!selectedAll) {
      if (emailState?.emailsSelectedRecords?.length > 0) {
        const newarr = emails
          .map((email) => email.id)
          .filter((value) => !emailState.emailsSelectedRecords.includes(value));
        arr = [...emailState.emailsSelectedRecords, ...newarr];
        const newSrcArr = emails
          .map((email) => email.src_email_id)
          .filter(
            (value) => !emailState.emailsSelectedSrcEmailId.includes(value),
          );
        srcArr = [...emailState.emailsSelectedSrcEmailId, ...newSrcArr];
      } else {
        arr = emails.map((email) => email.id);
        srcArr = emails.map((email) => email.src_email_id);
      }
    } else if (emailState?.emailsSelectedRecords?.length > 0) {
      arr = emailState.emailsSelectedRecords.filter(
        (item) => !emails.map((email) => email.id).includes(item),
      );
      srcArr = emailState.emailsSelectedSrcEmailId.filter(
        (item) => !emails.map((email) => email.src_email_id).includes(item),
      );
    } else {
      arr = [];
      srcArr = [];
    }
    const obj = { id: arr, srcEmailId: srcArr };
    setSelectedData(arr);
    setSelectedSrcEmailId(srcArr);
    dispatch({
      type: 'EMAILS_SELECTED_RECORDS',
      payload: obj,
    });
  };

  const handleEmailActions = async (actionType, email_ids) => {
    if (
      actionType === 'pin_it' ||
      actionType === 'primary' ||
      actionType === 'low_priority' ||
      actionType === 'done' ||
      actionType === 'unpin_it'
    ) {
      try {
        const response = await Axios.post(UPDATE_EMAILS_STATUS, {
          ids: email_ids,
          action_type: actionType,
        }).then((res) => res.data);
        setState({
          ...state,
          emails: state.emails.map((email) => ({
            ...email,
            isSelected: false,
          })),
          selectedAll: false,
          seledtedPageNo: null,
        });

        setSelectedData([]);
        setSelectedSrcEmailId([]);
        dispatch({
          type: 'EMAILS_SELECTED_RECORDS',
          payload: {},
        });
        AlertMessage('success', response.message);
        setDataReceived(true);
        if (actionType === 'done' || actionType === 'low_priority') {
          fetchEmails('discardData');
          // TODO: this would be use in future
          // if (email_ids.length > 1) {
          //   fetchEmails('discardData');
          // } else {
          //   const indx = emails.findIndex(
          //     (each) => each.id.toString() === email_ids[0].toString(),
          //   );
          //   setIdIndex(indx);
          //   setNextId(emails[indx + 1] ? emails[indx + 1].id : null);
          //   if (indx >= 0) {
          //     fetchEmails();
          //   }
          // }
        }
      } catch (error) {
        AlertMessage('error', error.response.data.message);
      }
    }
    if (actionType === 'mark_as_unread') {
      try {
        await Axios.post(UNREAD_EMAIL, {
          src_email_id: emailState?.emailsSelectedSrcEmailId,
          third_party_app:
            emailIntegratedWith === 'outlookemail' ? 'Outlook' : 'Gmail',
        }).then((res) => res.data);
        setSelectedData([]);
        setSelectedSrcEmailId([]);
        dispatch({
          type: 'EMAILS_SELECTED_RECORDS',
          payload: {},
        });
        AlertMessage('success', UPDATE_SUCCESS);
        setDataReceived(true);
      } catch (error) {
        AlertMessage('error', error.response.data.message);
      }
    }
  };

  const handleBultActions = (action) => {
    handleEmailActions(action.value, emailState.emailsSelectedRecords);
  };

  const handleSearchField = useMemo(
    () =>
      debounce((e) => {
        const input = e.target.value;
        setSearchFieldText(input);
      }, 500),
    [],
  );

  const unreadEmail = (emailData, newStatus = false) => {
    const { emails } = state;
    const index = state.emails.findIndex((x) => x.id === emailData.id);
    if (index >= 0) {
      const data = emails;
      data[index].is_read = newStatus;
      setState({ ...state, emails: data });
    }
  };

  const handleAssociatedContact = () => {
    const input = !associatedContactCheck;
    setAssociatedContactCheck(input);
    const { filter, sortBy, sortOrder } = state;
    const url = `/emails?filter=${filter}&page=1&sort_by=${sortBy}&sort_order=${sortOrder}${
      input ? `&associate_with_contact=${input}` : ''
    }`;

    navigate(url);
  };

  const handleShowFilter = (val, lastViewMode = '') => {
    if (val === 'close') {
      setShowFilters('split');
      setViewMode(prevViewMode);
      if (prevViewMode === 'list') {
        setIdIndex(null);
        setNextId(null);
        fetchEmails('discardData');
      }
    } else if (val === 'detailed' && lastViewMode) {
      setShowFilters(val);
      setViewMode(val);
      setPrevViewMode(lastViewMode);
    } else if (val === 'detailed') {
      setShowFilters(val);
      setViewMode(val);
      setPrevViewMode(viewMode);
    } else if (val === 'full view') {
      setShowFilters('split');
      setViewMode('list');
      setPrevViewMode(viewMode);
      fetchEmails('discardData');
    } else {
      setShowFilters(val);
      setViewMode(val);
      setPrevViewMode(viewMode);
    }
  };

  const handlePrevNextButton = (index) => {
    setSelectedIndex(index);
    setPreviousIndex(index - 1);
    setNextIndex(index + 1);
  };

  const handleIdNextIndex = (id, nxtId) => {
    setIdIndex(id);
    setNextId(nxtId);
  };
  const { tabs, activeTab, filter, sortBy, sortOrder, emails, selectedAll } =
    state;

  function showListIfInDetailMode() {
    if (viewMode === 'detailed') {
      setShowFilters('split');
      setViewMode('list');
      setPrevViewMode('list');
    }
  }

  // moving this down below the other useEffects
  // so that it can be used in multiple places
  const isMounted = useMountedState();

  const stackWrapProps = {
    id: 'content',
    'data-testid': 'filterSeachButtons',
    inLinkedSection,
    viewMode,
    showFiltersMode,
  };

  // eslint-disable-next-line react/jsx-props-no-spreading
  if (!emails?.length && !isEmailConnected && !loading)
    return (
      <StackWrap {...stackWrapProps}>
        <NoRecordInEmails />
      </StackWrap>
    );

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <StackWrap {...stackWrapProps}>
      {loading && <LoaderDefault />}
      <header>
        {!inLinkedSection && (
          <HeaderTabs
            data={tabs}
            activeTab={activeTab}
            handleClick={showListIfInDetailMode}
          />
        )}
      </header>
      <div className="EmailMain">
        <section>
          {userEmailIntegrated && (
            <div className="EmailMain__StackInn">
              {showFiltersMode === 'split' ? (
                !inLinkedSection && (
                  <FilterBy
                    associatedContactCheck={associatedContactCheck}
                    handleAssociatedContact={handleAssociatedContact}
                  />
                )
              ) : (
                <EmailNextPrevView
                  handleClick={handleShowFilter}
                  handlePageChange={handlePageChange}
                  emails={emails}
                  selectedIndex={selectedIndex}
                  previousIndex={previousIndex}
                  nextIndex={nextIndex}
                  handlePrevNextButton={handlePrevNextButton}
                />
              )}
              <div className="EmailMain__BtnSearchWrap">
                {!inLinkedSection && (
                  <SearchMessage
                    searchInput={searchFieldText}
                    autoComplete="off"
                    onChange={handleSearchField}
                  />
                )}
                <ComposeEmail setLoading={setLoading} />
              </div>
            </div>
          )}
          {emails?.length ? (
            <EmailsList
              contactId={contactId}
              accountId={accountId}
              toggleSelectAll={toggleSelectAll}
              toggleSelect={toggleSelect}
              handleBultActions={handleBultActions}
              sortDirection={sortOrder}
              sortByField={sortBy}
              hasSelectedAll={selectedAll}
              emails={emails}
              refreshPage={fetchEmails}
              handleEmailActions={handleEmailActions}
              handlePageChange={handlePageChange}
              viewContext={viewContext}
              unreadEmail={unreadEmail}
              filter={filter}
              data={state}
              activeTab={activeTab}
              idIndexx={idIndex}
              nextIdd={nextId}
              emailState={emailState}
              handleShowFilter={handleShowFilter}
              viewMode={viewMode}
              handlePrevNextButton={handlePrevNextButton}
              selectedIndex={selectedIndex}
              handleIdNextIndex={handleIdNextIndex}
              searchFieldText={searchFieldText}
              setLoading={setLoading}
            />
          ) : (
            <NoRecords />
          )}
        </section>
      </div>
    </StackWrap>
  );
};

export default EmailsSection;
