/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/no-unused-class-component-methods */
/* eslint-disable no-console */
import React, { Component } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { withRouter } from 'utilities/withRouterWrapper';
import _ from 'lodash';
import axios from 'axios';
import { EditorState, convertFromRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import {
  AlertMessage,
  current_logged_user_name,
  getCurrentPath,
  isUserEmployee,
} from 'utilities/utils';
import ConfirmationModal from 'common/ConfirmationModal';
import * as TaskApi from 'api/TaskApi';
import UploadFiles from 'api/UploadFiles';
import { autoTagApi } from 'api/AutoTag';
import Files from 'api/Files';
import { trackMixpanelEvent } from 'utilities/mixpanelfn';
import * as Config from 'constants/constants';
import { CheckboxField } from 'common/CustomFields';
import { validDraftText } from 'common/DraftEditorUtilities';
import { options, removeBreakElementinList } from 'common/NotesUtilities';
import { NAME, EMAIL } from 'utilities/regularexpressions';
import FileRecipientCreation from './FileRecipientCreation';
import FilePopupBottom from './FilePopupBottom';

const dropdownToggle = (dropdownName, className) => {
  const addClass = className;
  const removeClass = className === 'show' ? 'hide' : 'show';
  const dropdown = document.getElementById(dropdownName);
  if (dropdown) {
    dropdown.classList.remove(removeClass);
    dropdown.classList.add(addClass);
  }
};
const noneStyle = { display: 'none' };
const blockStyle = { display: 'block' };
class EmpFilePopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formdata: { makePrivate: false },
      allContacts: [],
      allAccounts: [],
      contacts: [],
      accounts: [],
      errors: {},
      showRecords: {},
      savedRecords: [],
      loading: false,
      recipientDropdownToggle: false,
      contactHasAccount: false,
      showMessageInput: true, // newwww*****
      alertText: '',
      uniqueRecords: [],
      fileUploadContactSide: false,
      fileUploadAccountSide: false,
      newRecordsCreated: false,
      contactDetails: {},
      accountDetails: {},
      documents: [], // documents to share file
    };
    this.typingInput = null;
  }

  componentDidMount() {
    const { match, location, viewContext } = this.props;
    const path =
      viewContext && viewContext === 'addnew' ? '/' : getCurrentPath(location);
    if (
      path === '/contactdetails/:id' &&
      location.hash &&
      location.hash === '#files'
    ) {
      const { id } = this.props.params;
      TaskApi.AccountsOfContact(this, id, 'uploadfile');
      Files.showSelectedContact(this, id);
      this.setState({ fileUploadContactSide: true });
    } else if (path === '/fileupload/:id') {
      const { id } = this.props.params;
      this.accountAutoPopulateFrom(id);
      Files.showSelectedAccount(this, id);
      this.setState({ fileUploadAccountSide: true });
    } else if (
      path === '/accountdetails/:id' ||
      path === '/accountdetails/:id/files'
    ) {
      const { id } = this.props.params;
      // TaskApi.ContactsOfAccount(this, id, 'uploadfile');
      this.accountAutoPopulateFrom(id);
      Files.showSelectedAccount(this, id);
      this.setState({ fileUploadAccountSide: true });
    } else {
      const data = {
        // task_type_key: 'request_info',
        limit: true,
      };
      TaskApi.getContactsOfTask(this, data, 'notes');
      TaskApi.getAccounts(this, 'notes');
    }
    // populate to block only from emp side
    if (this.props.shareFile) {
      let { documents } = this.state;
      const { formdata, uniqueRecords } = this.state;
      const { sharedFileDetails, shareType } = this.props;
      let count = 0;
      const finalDocs = [];
      if (sharedFileDetails && sharedFileDetails.length) {
        documents = sharedFileDetails;
        let documentsName = '';
        documents.map((each) => {
          // if source is note or todo task remove
          if (
            each.source === 'Note' ||
            (each.source === 'Task' && each.task_type_key === 'to_do')
          ) {
            count += 1;
          } else {
            documentsName = documentsName.concat(', ', each.doc_name);
            finalDocs.push(each);
          }
          if (
            each.recipient_array &&
            each.recipient_array.length &&
            shareType !== 'multiple'
          ) {
            each.recipient_array.map((item) => {
              const object = {};
              if (item.contact) {
                object.contact = {};
                object.contact.label = item.contact;
                object.contact.id = item.contact_id;
              }
              if (item.account) {
                object.account = {};
                object.account.label = item.account;
                object.account.value = item.account_id;
              }
              uniqueRecords.push(object);
              return object;
            });
          }
          return documentsName;
        });
        formdata.message = `User ${current_logged_user_name()} has shared following file(s): ${documentsName.replace(
          /^,/,
          '',
        )}`;
        formdata.makePrivate = false;
      }
      this.setState(
        {
          documents: finalDocs,
          formdata,
          uniqueRecords,
        },
        () => {
          if (count > 0) {
            setTimeout(() => {
              this.setState({
                alertText:
                  'Files linked to ToDo task/ Notes cannot be shared. These files have been removed from selection',
              });
            }, 1000);
          }
        },
      );
    }
    if (this.props.addToVaultFileDetails) {
      this.getEmailAttachments();
    }
  }

  accountAutoPopulateFrom = (_id) => {
    const request = axios.get(
      `${Config.GET_CONT_EMP_OF_ACCOUNT_API_PATH}/${_id}`,
    );
    request.then((res) => {
      if (res.data.status === 200) {
        const result = _.filter(
          res.data.data,
          (value) => value.email !== 'n/a' && value.email !== '',
        );
        this.setState({
          contacts: result,
          allContacts: result,
        });
      }
    });
  };

  UNSAFE_componentWillUnmount() {
    const { addToVaultFileDetails } = this.props;
    const { formdata } = this.state;
    if (
      addToVaultFileDetails &&
      formdata.documents &&
      formdata.documents.length
    ) {
      const docIds = [];
      formdata.documents.map((item) => {
        docIds.push(item.id);
        return docIds;
      });
      const data = { doc_ids: docIds };
      UploadFiles.deleteUploadedFile(data, this);
    }
  }

  getEmailAttachments = async () => {
    const { formdata } = this.state;
    const { documents, email_id } = this.props.addToVaultFileDetails;
    if (documents && documents.length) {
      try {
        this.setState({ loading: true });
        const docIds = documents.map((item) => item.id).join(',');
        const url = `${Config.USER_EMAILS}/${email_id}/attachments?attachment_id=${docIds}&manual=true`;
        const response = await axios.get(url).then((res) => res.data.data);
        formdata.documents = response;
        this.setState({
          documents: response,
          formdata,
          loading: false,
        });
      } catch (error) {
        console.log(error);
        this.setState({ loading: false });
      }
    }
  };

  recipientDropdownToggle = (toggle = undefined) => {
    const { fileUploadAccountSide, fileUploadContactSide, formdata } =
      this.state;
    this.setState(
      {
        recipientDropdownToggle:
          toggle !== undefined ? toggle : !this.state.recipientDropdownToggle,
      },
      () => {
        if (
          fileUploadContactSide &&
          Object.keys(this.state.contactDetails).length
        ) {
          formdata.contactId = this.state.contactDetails;
        } else if (
          fileUploadAccountSide &&
          Object.keys(this.state.accountDetails).length
        ) {
          formdata.accountId = this.state.accountDetails;
        }
        this.setState({ formdata });
      },
    );
  };

  handleContactChange = (fieldname, input) => {
    const { formdata, fileUploadAccountSide } = this.state;
    if (input && !fileUploadAccountSide) {
      if (_.has(input, 'accounts') && input.accounts.length) {
        this.setState({
          accounts: input.accounts,
          contactHasAccount: true,
        });
      } else {
        // formdata.accountId = null;
        this.setState({
          accounts: this.state.allAccounts,
          contactHasAccount: false,
          // formdata,
        });
      }
    } else if (!formdata.accountId) {
      this.setState({
        contacts: this.state.allContacts,
        accounts: this.state.allAccounts,
      });
    }
    this.setState(
      {
        formdata: { ...this.state.formdata, [fieldname]: input },
        contactInput: '',
      },
      () => {
        if (this.state.accounts.length === 1) {
          this.updateAccountOnAutoPopulate('accountId', this.state.accounts[0]);
        }
      },
    );
  };

  updateAccountOnAutoPopulate = (fieldname, input) => {
    this.setState({
      formdata: {
        ...this.state.formdata,
        [fieldname]: input,
        accountname: '',
        entityType: {},
      },
      accountInput: '',
    });
  };

  handleAccountChange = (fieldname, input) => {
    const { formdata, fileUploadContactSide } = this.state;
    const component = this;
    let contactOfAccount = [];
    if (input && !fileUploadContactSide) {
      this.setState({ loading: true });
      // const request = axios.get(Config.GET_CONTACTS_OF_ACCOUNT_API_PATH + '/' + input.value);
      const request = axios.get(
        `${Config.GET_CONT_EMP_OF_ACCOUNT_API_PATH}/${input.value}`,
      );
      request.then((res) => {
        if (res.data.status === 200) {
          this.setState({ loading: false });
          const result = _.filter(
            res.data.data,
            (value) => value.email !== 'n/a' && value.email !== '',
          );
          const _contact = res.data.data.filter((d) => d.type === 'contact');
          if (_contact.length === 1) {
            contactOfAccount = _contact[0];
            this.handleContactChange('contactId', contactOfAccount);
          }
          component.setState({ contacts: result });
        }
      });
    } else if (!formdata.contactId) {
      this.setState({
        accounts: this.state.allAccounts,
        contacts: this.state.allContacts,
      });
    }
    this.setState({
      formdata: {
        ...this.state.formdata,
        [fieldname]: input,
        accountname: '',
        entityType: {},
      },
      accountInput: '',
    });
  };

  handleContactInput = (input, action) => {
    input = action.action === 'input-change' ? input : action.prevInputValue;
    const { formdata, fileUploadAccountSide, newRecordsCreated } = this.state;
    const component = this;
    this.setState({ contactInput: input, errors: {} });
    clearTimeout(this.typingTime);
    this.typingTime = setTimeout(() => {
      this.handleBlur('contactInput');
    }, 400);
    if (fileUploadAccountSide && newRecordsCreated && input.length === 1) {
      const { id } = this.props.params;
      TaskApi.ContactsOfAccount(this, id, 'uploadfile');
      this.setState({ newRecordsCreated: false });
    } else if (input && !formdata.accountId && !fileUploadAccountSide) {
      if (input && !formdata.accountId) {
        if (input.length <= 2) {
          formdata.contactId = null;
          component.setState({
            contacts: component.state.allContacts,
            formdata,
          });
        } else {
          clearTimeout(this.contactSearchTime);
          this.contactSearchTime = setTimeout(() => {
            const request = axios.get(
              `${Config.SEARCH_TASK_CONTACTS}/?keyword=${input}`,
            );
            request.then((res) => {
              if (res.status === 200) {
                this.setState({ contacts: res.data });
              }
            });
          }, 500);
        }
      }
    }
  };

  handleBlur = (fieldname) => {
    const inputValue = this.state[fieldname] || '';
    const split = inputValue.split(' ');
    const secondPart = split.slice(1, split.length).join(' '); // lastname
    const { formdata } = this.state;
    if (fieldname === 'contactInput') {
      if (split[0]) {
        formdata.firstname = split[0]; // firstname
      } else {
        formdata.firstname = '';
      }
      formdata.lastname = secondPart;
      formdata.email = '';
    }
    if (fieldname === 'accountInput') {
      if (inputValue) {
        formdata.accountname = inputValue;
      } else {
        formdata.accountname = '';
      }
    }
    this.setState({ formdata }, () => {
      clearTimeout(this.typingTime);
      this.typingTime = setTimeout(() => {
        this.validateAccountName();
      }, 400);
    });
  };

  handleAccountInput = (input, action) => {
    input = action.action === 'input-change' ? input : action.prevInputValue;
    const { formdata, fileUploadContactSide, newRecordsCreated } = this.state;
    this.setState({ accountInput: input, errors: {} });
    clearTimeout(this.typingTime);
    this.typingTime = setTimeout(() => {
      this.handleBlur('accountInput');
    }, 400);
    if (fileUploadContactSide && newRecordsCreated && input.length === 1) {
      const { id } = this.props.params;
      TaskApi.AccountsOfContact(this, id, 'uploadfile');
      this.setState({ newRecordsCreated: false });
    } else if (!fileUploadContactSide) {
      if (
        input.length >= 2 &&
        (!formdata.contactId || !this.state.contactHasAccount)
      ) {
        clearTimeout(this.accountSearchTime);
        this.accountSearchTime = setTimeout(() => {
          const request = axios.get(
            `${Config.SEARCH_TASK_ACCOUNTS}/?keyword=${input}&message=true`,
          );
          request.then((res) => {
            if (res.status === 200) {
              this.setState({ accounts: res.data });
            }
          });
        }, 500);
      }
    }
  };

  handleInputBlur = (action = '', block = '', field = '') => {
    const { formdata, errors } = this.state;
    if (block === 'contact') {
      if (!formdata.firstname && action === 'submit') {
        errors.firstname = 'Required';
      } else if (formdata.firstname && !NAME.test(formdata.firstname)) {
        errors.firstname = 'Enter valid name';
      }
      if (!formdata.lastname && action === 'submit') {
        errors.lastname = 'Required';
      } else if (formdata.lastname && !NAME.test(formdata.lastname)) {
        errors.lastname = 'Enter valid name';
      }
      if (!formdata.email && action === 'submit') {
        errors.email = 'Required';
      } else if (formdata.email && !EMAIL.test(formdata.email)) {
        errors.email = 'Invalid Email';
      } else if (formdata.email) {
        this.validatePrimaryEmail();
      }
    }
    if (block === 'account') {
      if (action === 'submit' && !formdata.accountname) {
        errors.accountname = 'Required';
      }
      if (
        action === 'submit' &&
        (!formdata.entityType ||
          (formdata.entityType && !Object.keys(formdata.entityType).length))
      ) {
        errors.entityType = 'Required';
      }
    }
    this.setState({ errors }, () => {
      if (field === 'email' && !errors.email) {
        this.validatePrimaryEmail();
      }
    });
    if (Object.keys(errors).length) {
      return false;
    }
    return true;
  };

  handleCancel = (block, e) => {
    e.preventDefault();
    const { formdata } = this.state;
    if (block === 'contact' || block === 'all') {
      formdata.firstname = '';
      formdata.lastname = '';
      formdata.email = '';
      this.setState({ contactInput: '' });
    }
    if (block === 'account' || block === 'all') {
      formdata.accountname = '';
      formdata.entityType = {};
      this.setState({ accountInput: '' });
    }
    if (block === 'all') {
      formdata.contactId = null;
      formdata.accountId = null;
      this.setState(
        {
          contacts: this.state.allContacts,
          accounts: this.state.allAccounts,
          savedRecords: [],
        },
        () => {
          this.recipientDropdownToggle();
        },
      );
    }
    this.setState({
      formdata,
      errors: {},
    });
    if (block === 'contact' || block === 'account') {
      const dropdownName =
        block === 'contact' ? 'recipientDropdown' : 'fileAccountDropdown';
      dropdownToggle(dropdownName, 'hide');
    }
  };

  handleSave = (block, e) => {
    e.preventDefault();
    const validate = this.handleInputBlur('submit', block);
    if (validate) {
      const { formdata, savedRecords } = this.state;
      if (block === 'contact') {
        const data = {
          firstname: formdata.firstname,
          lastname: formdata.lastname,
          email: formdata.email,
        };
        savedRecords.contact = data;
        this.setState({ contactInput: '' });
      }
      if (block === 'account') {
        const data = {
          accountname: formdata.accountname,
          entityType: formdata.entityType,
        };
        savedRecords.account = data;
        this.setState({ accountInput: '' });
      }
      this.setState({ savedRecords }, () => {
        const dropdownName =
          block === 'contact' ? 'recipientDropdown' : 'fileAccountDropdown';
        dropdownToggle(dropdownName, 'hide');
      });
    }
  };

  handleSubmitValidation() {
    const { formdata, savedRecords } = this.state;
    if (
      this.state.contactInput &&
      !savedRecords.contact &&
      (!formdata.contactId || formdata.contactId === null)
    ) {
      this.setState({ alertText: 'Please select or save recipient' }, () => {
        dropdownToggle('recipientDropdown', 'show');
      });
      return false;
    }
    if (
      this.state.accountInput &&
      !savedRecords.account &&
      (!formdata.accountId || formdata.accountId === null)
    ) {
      this.setState({ alertText: 'Please select or save account' }, () => {
        dropdownToggle('fileAccountDropdown', 'show');
      });
      return false;
    }
    if (
      (formdata.firstname || formdata.lastname || formdata.email) &&
      !savedRecords.contact &&
      (!formdata.contactId || formdata.contactId === null)
    ) {
      this.setState({ alertText: 'Please select or save recipient' }, () => {
        dropdownToggle('recipientDropdown', 'show');
      });
      return false;
    }
    if (
      (formdata.accountname ||
        (formdata.entityType && Object.keys(formdata.entityType).length)) &&
      !savedRecords.account &&
      (!formdata.accountId || formdata.accountId === null)
    ) {
      this.setState({ alertText: 'Please select or save account' }, () => {
        dropdownToggle('fileAccountDropdown', 'show');
      });
      return false;
    }
    if (
      !savedRecords.account &&
      !savedRecords.contact &&
      (!formdata.contactId || formdata.contactId === null) &&
      (!formdata.accountId || formdata.accountId === null)
    ) {
      this.setState({ alertText: 'Please select or save recipient/account' });
      return false;
    }
    return true;
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { formdata, errors, savedRecords } = this.state;
    let { newRecordsCreated } = this.state;

    const validate = this.handleSubmitValidation();
    // return false;
    if (validate) {
      const dataObject = {};
      const mydata = {
        account: {},
        contact: {},
      };
      if (formdata.contactId && formdata.accountId && !savedRecords.length) {
        // both dropdown selected, not created
        dataObject.contact = formdata.contactId;
        dataObject.account = formdata.accountId;
        mydata.contact_id = formdata.contactId.id;
        mydata.account_id = formdata.accountId.value;
      } else if (
        formdata.contactId &&
        !formdata.accountId &&
        !savedRecords.account
      ) {
        // contact selected only, not created
        dataObject.contact = formdata.contactId;
      } else if (
        formdata.accountId &&
        !formdata.contactId &&
        !savedRecords.contact
      ) {
        // account selected only, not created
        dataObject.account = formdata.accountId;
      } else if (
        formdata.contactId &&
        !formdata.accountId &&
        savedRecords.account
      ) {
        // contact selected, account created
        dataObject.contact = formdata.contactId;
        mydata.account.name = savedRecords.account.accountname;
        mydata.account.entity_type = savedRecords.account.entityType.value;
        mydata.contact_id = formdata.contactId.id;
        newRecordsCreated = true;
      } else if (
        formdata.accountId &&
        !formdata.contactId &&
        savedRecords.contact
      ) {
        // account selected, contact created
        dataObject.account = formdata.accountId;
        mydata.contact.first_name = savedRecords.contact.firstname;
        mydata.contact.last_name = savedRecords.contact.lastname;
        mydata.contact.primary_email = savedRecords.contact.email;
        mydata.account_id = formdata.accountId.value;
        newRecordsCreated = true;
      } else if (
        savedRecords.account &&
        savedRecords.contact &&
        !formdata.contactId &&
        !formdata.accountId
      ) {
        // both created
        if (savedRecords.contact.id) {
          mydata.contact_id = savedRecords.contact.id;
          dataObject.contact = savedRecords.contact;
        } else {
          mydata.contact.first_name = savedRecords.contact.firstname;
          mydata.contact.last_name = savedRecords.contact.lastname;
          mydata.contact.primary_email = savedRecords.contact.email;
        }
        if (savedRecords.account.value) {
          mydata.account_id = savedRecords.account.value;
          dataObject.account = savedRecords.account;
        } else {
          mydata.account.name = savedRecords.account.accountname;
          mydata.account.entity_type = savedRecords.account.entityType.value;
        }
        newRecordsCreated = true;
      } else if (
        savedRecords.contact &&
        !savedRecords.account &&
        !formdata.accountId
      ) {
        // contact created only
        mydata.contact.first_name = savedRecords.contact.firstname;
        mydata.contact.last_name = savedRecords.contact.lastname;
        mydata.contact.primary_email = savedRecords.contact.email;
        newRecordsCreated = true;
      } else if (
        savedRecords.account &&
        !savedRecords.contact &&
        !formdata.contactId
      ) {
        // account created only
        mydata.account.name = savedRecords.account.accountname;
        mydata.account.entity_type = savedRecords.account.entityType.value;
        newRecordsCreated = true;
      }
      if (formdata.contactId && formdata.contactId.type === 'emp') {
        delete formdata.accountId;
        delete formdata.contactId;
        delete formdata.firstname;
        delete formdata.lastname;
        delete formdata.email;
        delete formdata.accountname;
        delete formdata.entityType;
        this.setState(
          {
            showRecords: dataObject,
            savedRecords: [],
            newRecordsCreated: false,
          },
          () => {
            this.validateUniqueRecord(); // Validates the uniqueness of records
            this.recipientDropdownToggle();
          },
        );
      } else if (
        Object.keys(mydata.contact).length ||
        Object.keys(mydata.account).length ||
        mydata.contact_id ||
        mydata.account_id
      ) {
        this.setState({ loading: true });
        const request = axios.post(Config.CREATE_RECORDS, mydata);
        this.setState({ loading: false });
        request.then((res) => {
          if (res.data.status === 200) {
            if (Object.keys(res.data.contact).length) {
              dataObject.contact = res.data.contact;
              savedRecords.contact.id = res.data.contact.id;
            }
            if (Object.keys(res.data.account).length) {
              dataObject.account = res.data.account;
              savedRecords.account.value = res.data.account.value;
            }
            if (
              res.data.account_error_message &&
              res.data.contact_error_message
            ) {
              AlertMessage('error', res.data.contact_error_message, 3000);
              delete formdata.accountId;
              delete formdata.contactId;
              delete formdata.firstname;
              delete formdata.lastname;
              delete formdata.email;
              delete formdata.accountname;
              delete formdata.entityType;
              this.setState({ savedRecords: [] }, () => {
                this.recipientDropdownToggle();
              });
            } else if (res.data.contact_error_message) {
              AlertMessage('error', res.data.contact_error_message, 3000);
              delete savedRecords.contact;
              errors.email = res.data.contact_error_message;
              this.setState(
                {
                  errors,
                  savedRecords,
                },
                () => {
                  dropdownToggle('recipientDropdown', 'show');
                },
              );
            } else if (res.data.account_error_message) {
              AlertMessage('error', res.data.account_error_message, 3000);
              delete savedRecords.account;
              errors.accountname = res.data.account_error_message;
              this.setState(
                {
                  errors,
                  savedRecords,
                },
                () => {
                  dropdownToggle('fileAccountDropdown', 'show');
                },
              );
            } else if (res.data.rel_error_message) {
              AlertMessage('error', res.data.rel_error_message, 3000);
              delete formdata.accountId;
              delete formdata.contactId;
              delete formdata.firstname;
              delete formdata.lastname;
              delete formdata.email;
              delete formdata.accountname;
              delete formdata.entityType;
              this.setState({ savedRecords: [] }, () => {
                this.recipientDropdownToggle();
              });
            } else {
              if (
                Object.keys(res.data.contact).length ||
                Object.keys(res.data.account).length
              ) {
                AlertMessage('success', 'Created Successfully', 3000);
              }
              delete formdata.accountId;
              delete formdata.contactId;
              delete formdata.firstname;
              delete formdata.lastname;
              delete formdata.email;
              delete formdata.accountname;
              delete formdata.entityType;
              this.setState({ savedRecords: [] }, () => {
                this.recipientDropdownToggle();
              });
            }
            this.setState(
              {
                showRecords: dataObject,
                newRecordsCreated,
              },
              () => {
                this.validateUniqueRecord(); // Validates the uniqueness of records
              },
            );
          } else {
            AlertMessage('error', res.data.message, 3000);
          }
        });
      } else {
        delete formdata.accountId;
        delete formdata.contactId;
        delete formdata.firstname;
        delete formdata.lastname;
        delete formdata.email;
        delete formdata.accountname;
        delete formdata.entityType;
        this.setState(
          {
            showRecords: dataObject,
            savedRecords: [],
            newRecordsCreated: false,
          },
          () => {
            this.validateUniqueRecord(); // Validates the uniqueness of records
            this.recipientDropdownToggle();
          },
        );
      }
      this.setState({
        formdata,
        contacts: this.state.allContacts,
        accounts: this.state.allAccounts,
        contactInput: '',
        accountInput: '',
        contactHasAccount: false,
        // savedRecords: [],
      });
    }
  };

  handleUpload = async () => {
    const { location, navigate } = this.props;
    const validate = this.handleUploadValidation();
    const component = this;
    const tagsLabels = [];
    if (validate) {
      const { formdata, uniqueRecords } = this.state;
      const docIds = [];
      const selectedtags = [];
      component.setState({ loading: true });
      if (formdata.tags && formdata.tags.length) {
        formdata.tags.map((item) => {
          selectedtags.push(item.key);
          tagsLabels.push(item.label);
          return selectedtags;
        });
      }
      if (formdata.documents && formdata.documents.length) {
        const feedbackResponse = await Promise.all(
          formdata.documents.map((doc) => {
            docIds.push(doc.id);
            return autoTagApi(
              'feedback',
              { filename: doc.doc_name, tag: tagsLabels.join(', ') },
              true,
            );
          }),
        );
      }
      const array = [];
      let accountOnly = false;
      uniqueRecords.map((each) => {
        const newArray = [];
        newArray[0] = each.contact
          ? each.contact.type && each.contact.type === 'emp'
            ? each.contact.value
            : each.contact.id
          : '';
        newArray[1] = each.account ? each.account.value : '';
        newArray[2] =
          each.contact && each.contact.type ? each.contact.type : 'contact';
        array.push(newArray);
        if (!each.contact) {
          accountOnly = true;
        }
        return array;
      });
      let msg = formdata.message;
      if (
        typeof formdata.message === 'object' &&
        EditorState.createWithContent(convertFromRaw(formdata.message))
          .getCurrentContent()
          .getPlainText().length
      ) {
        msg = removeBreakElementinList(
          stateToHTML(
            EditorState.createWithContent(
              convertFromRaw(formdata.message),
            ).getCurrentContent(),
            options,
          ),
        );
      }
      const data = {
        doc_ids: docIds,
        message: formdata.makePrivate ? '' : accountOnly ? '' : msg,
        send_message: formdata.makePrivate
          ? false
          : accountOnly
          ? false
          : !formdata.checkbox,
        recipients: array,
        month:
          formdata.month && Object.keys(formdata.month).length
            ? formdata.month.value
            : '',
        tags: selectedtags,
        year:
          formdata.year && Object.keys(formdata.year).length
            ? formdata.year.value
            : '',
      };
      if (
        formdata.makePrivate &&
        formdata.makePrivate === true &&
        isUserEmployee()
      ) {
        data.is_private_to_firm = true;
      }
      if (formdata.subject && isUserEmployee()) {
        data.subject = formdata.subject;
      }
      const request = axios.post(
        Config.MULTIPLE_FILES_UPLOAD_FOR_SHARING,
        data,
      );
      request
        .then((res) => {
          component.setState({ loading: false });
          if (res.data.status === 200) {
            trackMixpanelEvent(Config.MIXPANEL_FILE_UPLOAD_EVENT, {
              uploadType: 'manual',
              uploader: 'employee',
            });
            AlertMessage('success', res.data.message, 3000);
            this.setState({
              formdata: { ...component.state.formdata, documents: [] },
            });
          } else {
            AlertMessage('error', res.data.message, 3000);
          }
          if (this.props.closeModal && this.props.addToVaultFileDetails) {
            this.props.closeModal('addedToVault');
          } else if (
            location?.state?.from === 'inFocus' &&
            location?.state?.accountID
          ) {
            const { accountID, from } = location.state;
            navigate(`/accountdetails/${accountID}/${from}`);
          } else if (this.props.closeModal) {
            this.props.closeModal();
          } else {
            this.props.navigate('/');
          }
        })
        .catch((error) => {
          component.setState({ loading: false });
          console.log('error', error);
        });
    }
  };

  handleShare = () => {
    const { showRecords, formdata, uniqueRecords, documents } = this.state;
    const { sharedFileDetails } = this.props;
    const component = this;
    let checkboxValue = formdata.checkbox;
    uniqueRecords.map((each) => {
      if (!each.contact) {
        checkboxValue = true;
      }
      return checkboxValue;
    });
    // const checkboxValue = (Object.keys(showRecords).length && !showRecords.contact) ? true : formdata.checkbox;
    const errors = {};
    if (!uniqueRecords || uniqueRecords.length === 0) {
      errors.toField = 'Required';
    }
    if (
      !checkboxValue &&
      (!formdata.message ||
        (formdata.message &&
          typeof formdata.message === 'object' &&
          !EditorState.createWithContent(convertFromRaw(formdata.message))
            .getCurrentContent()
            .getPlainText().length) ||
        (formdata.message &&
          typeof formdata.message !== 'object' &&
          !formdata.message.length))
    ) {
      errors.message = 'Required';
    }
    if (!documents.length || documents.length === 0) {
      errors.nofiles = 'No files';
      this.setState({ alertText: 'There are no files attached to share' });
    }
    this.setState({ errors });
    if (Object.keys(errors).length) {
      return false;
    }
    const array = [];
    let accountOnly = false;
    uniqueRecords.map((each) => {
      const newArray = [];
      newArray[0] = each.contact
        ? each.contact.type && each.contact.type === 'emp'
          ? each.contact.value
          : each.contact.id
        : '';
      newArray[1] = each.account ? each.account.value : '';
      newArray[2] =
        each.contact && each.contact.type ? each.contact.type : 'contact';
      array.push(newArray);
      if (!each.contact) {
        accountOnly = true;
      }
      return array;
    });
    const docIds = [];
    documents.map((each) => {
      docIds.push(each.id);
      return docIds;
    });
    let msg = formdata.message;
    if (
      typeof formdata.message === 'object' &&
      EditorState.createWithContent(convertFromRaw(formdata.message))
        .getCurrentContent()
        .getPlainText().length
    ) {
      msg = removeBreakElementinList(
        stateToHTML(
          EditorState.createWithContent(
            convertFromRaw(formdata.message),
          ).getCurrentContent(),
          options,
        ),
      );
    }
    const data = {
      doc_ids: docIds,
      recipients: array,
      message: accountOnly ? '' : msg,
      send_message: accountOnly ? false : !formdata.checkbox,
    };
    if (formdata.subject && isUserEmployee()) {
      data.subject = formdata.subject;
    }
    this.setState({ loading: true });
    const request = axios.post(Config.SHARE_PRIVATE_FILES, data);
    request
      .then((res) => {
        component.setState({ loading: false });
        if (res.data.success === true) {
          AlertMessage('success', res.data.message, 3000);
          if (this.props.closeModal) {
            this.props.closeModal('shared', uniqueRecords);
          } else {
            this.props.navigate('/');
          }
        } else {
          AlertMessage('error', res.data.message, 3000);
        }
      })
      .catch((err) => {
        component.setState({ loading: false });
        console.log('err', err);
      });
  };

  handleCancelUpload = () => {
    const { formdata, uniqueRecords } = this.state;
    const { location, navigate } = this.props;
    if (
      (uniqueRecords && uniqueRecords.length) ||
      (formdata.tags && formdata.tags.length) ||
      (formdata.documents && formdata.documents.length) ||
      (formdata.month && Object.keys(formdata.month).length) ||
      (formdata.year && Object.keys(formdata.year).length) ||
      formdata.message
    ) {
      this.setState({
        cancelUploadText: 'Are you sure you want to cancel.',
        messageText: 'This action cannot be undone. Proceed?',
      });
    } else if (
      location &&
      location.state?.accountID &&
      location.state?.from === 'inFocus'
    ) {
      navigate(`/accountdetails/${location.state.accountID}/inFocus`);
    } else {
      this.props.navigate('/');
    }
  };

  handleUploadValidation() {
    const { formdata, uniqueRecords } = this.state;
    let accountOnly = false;
    if (uniqueRecords && uniqueRecords.length) {
      uniqueRecords.map((each) => {
        if (!each.contact) {
          accountOnly = true;
        }
        return accountOnly;
      });
    }
    const checkboxValue = accountOnly ? true : formdata.checkbox;
    const errors = {};
    if (!formdata.makePrivate || formdata.makePrivate === false) {
      if (!uniqueRecords || uniqueRecords.length === 0) {
        errors.toField = 'Required';
      }
      if (
        !checkboxValue &&
        (!formdata.message ||
          (formdata.message &&
            typeof formdata.message === 'object' &&
            !EditorState.createWithContent(convertFromRaw(formdata.message))
              .getCurrentContent()
              .getPlainText().length) ||
          (formdata.message &&
            typeof formdata.message !== 'object' &&
            !formdata.message.length))
      ) {
        errors.message = 'Required';
      }
    }
    if (!formdata.tags || (formdata.tags && !formdata.tags.length)) {
      errors.tags = 'Required';
    }
    if (
      !formdata.documents ||
      (formdata.documents && !formdata.documents.length)
    ) {
      errors.documents = 'Required';
    }
    this.setState({ errors });
    if (Object.keys(errors).length) {
      return false;
    }
    return true;
  }

  removeSelectedRecord = (e, each, index) => {
    if (e) {
      e.stopPropagation();
    }
    const { formdata } = this.state;
    const selectedRecords = this.state.uniqueRecords;
    delete formdata.accountId;
    delete formdata.contactId;
    delete formdata.firstname;
    delete formdata.lastname;
    delete formdata.email;
    delete formdata.accountname;
    delete formdata.entityType;
    if (index > -1) {
      selectedRecords.splice(index, 1);
    }
    this.setState({
      formdata,
      showRecords: {},
      savedRecords: [],
      uniqueRecords: selectedRecords,
      contacts: this.state.allContacts,
      accounts: this.state.allAccounts,
      contactInput: '',
      accountInput: '',
    });
  };

  removeSavedRecord = (inputType) => {
    const { savedRecords, formdata } = this.state;
    if (inputType === 'contactInput') {
      delete savedRecords.contact;
      delete formdata.firstname;
      delete formdata.lastname;
      delete formdata.email;
      this.setState({ contactInput: '' });
    } else if (inputType === 'accountInput') {
      delete savedRecords.account;
      delete formdata.accountname;
      delete formdata.entityType;
      this.setState({ accountInput: '' });
    }
    this.setState({
      savedRecords,
      formdata,
    });
  };

  clearForm = () => {
    this.setState({
      formdata: {
        ...this.state.formdata,
        message: '',
        subject: '',
      },
    });
  };

  updateForm = (fieldname, inputvalue, block = '') => {
    let value = inputvalue;
    if (
      fieldname === 'month' ||
      fieldname === 'year' ||
      fieldname === 'entityType'
    ) {
      value = inputvalue || {};
    }
    const message = this.updateMessage(fieldname, inputvalue);
    //  ************************************************
    this.setState(
      {
        formdata: {
          ...this.state.formdata,
          [fieldname]: value,
          message,
        },
        errors: {},
      },
      () => {
        const { formdata, savedRecords } = this.state;
        if (block === 'contact') {
          delete savedRecords.contact;
        } else if (block === 'account') {
          delete savedRecords.account;
        }
        // empty message field when do not send message clicked
        // if (formdata.checkbox && formdata.checkbox === true) {
        //   formdata.message = '';
        // }
        // if (fieldname === 'message' && inputvalue === '') {
        //   formdata.message = '';
        // }
        // if (fieldname === 'subject') {
        //   formdata.subject = value || '';
        // }
        this.setState({ savedRecords });
      },
    );
    if (fieldname === 'email') {
      clearTimeout(this.typingTime);
      this.typingTime = setTimeout(() => {
        this.validatePrimaryEmail();
      }, 400);
    } else if (fieldname === 'accountname' || fieldname === 'entityType') {
      clearTimeout(this.typingTime);
      this.typingTime = setTimeout(() => {
        this.validateAccountName();
      }, 400);
    }
  };

  updateMessage(fieldname, inputvalue) {
    // autopopulate message field when document uploaded
    const { formdata } = this.state;
    let message = formdata.message || '';
    if (fieldname === 'message') {
      message = inputvalue;
    } else if (fieldname === 'documents') {
      const description = validDraftText(formdata.message);
      console.log('pli', description);
      console.log('formdata.message', formdata.message);
      console.log('typeof formdata.message', typeof formdata.message);
      if (
        !formdata.message ||
        (formdata.message &&
          typeof formdata.message !== 'object' &&
          formdata.message.indexOf(
            `User ${current_logged_user_name()} has shared following file(s):`,
          ) > -1) ||
        (formdata.message &&
          typeof formdata.message === 'object' &&
          description === '')
      ) {
        // auto populate
        let documentsName = '';
        let array = [];
        if (fieldname === 'documents') {
          array = inputvalue;
        } else if (this.props.shareFile) {
          array = this.state.documents;
        } else if (this.state.formdata.documents) {
          array = this.state.formdata.documents;
        }
        // const array = fieldname === 'documents' ? inputvalue : (this.state.formdata.documents || []);
        if (array && array.length) {
          array.map((each) => {
            documentsName = documentsName.concat(', ', each.doc_name);
            return documentsName;
          });
        }
        message = `User ${current_logged_user_name()} has shared following file(s): ${documentsName.replace(
          /^,/,
          '',
        )}`;
      }
    }
    return message;
  }

  validatePrimaryEmail() {
    const { formdata } = this.state;
    if (formdata.email) {
      axios
        .post(Config.CHECK_PRIMARY_EMAIL, {
          primary_email: formdata.email.toLowerCase(),
        })
        .then((res) => {
          if (res.data.status === 200) {
            this.setState({
              errors: {
                ...this.state.errors,
                email: res.data.message ? res.data.message : 'Already used',
              },
            });
          } else {
            const { errors } = this.state;
            delete errors.email;
            this.setState({ errors: { ...errors } });
          }
        });
    }
  }

  validateAccountName() {
    const { formdata } = this.state;
    if (formdata.accountname) {
      const request = axios.get(
        `${Config.CHECK_ACCOUNT}?name=${formdata.accountname}`,
      );
      request.then((res) => {
        if (res.data.status === 200) {
          this.setState({
            errors: { ...this.state.errors, accountname: res.data.message },
          });
        } else {
          const { errors } = this.state;
          delete errors.accountname;
          this.setState({ errors: { ...errors } });
        }
      });
    }
  }

  validateUniqueRecord() {
    const { showRecords } = this.state;
    let { uniqueRecords } = this.state;
    uniqueRecords.push(showRecords);
    // eslint-disable-next-line consistent-return
    uniqueRecords = _.uniqBy(uniqueRecords, (elem) => {
      if (elem.account && elem.contact) {
        return [elem.account.value, elem.contact.id].join();
      }
      if (!elem.account) {
        return [elem.contact.id].join();
      }
      if (!elem.contact) {
        return [elem.account.value].join();
      }
    });
    this.setState({ uniqueRecords });
  }

  delDocumentstoShare = (id) => {
    const { documents, formdata } = this.state;
    const array = documents.filter((each) => each.id !== id);
    if (array && array.length) {
      let documentsName = '';
      array.map((each) => {
        documentsName = documentsName.concat(', ', each.doc_name);
        return documentsName;
      });
      formdata.message = `User ${current_logged_user_name()} has shared following file(s): ${documentsName.replace(
        /^,/,
        '',
      )}`;
    }
    this.setState({
      documents: array,
      formdata,
    });
  };

  saveRecipient = () => {
    const { uniqueRecords, errors } = this.state;
    if (!uniqueRecords || uniqueRecords.length === 0) {
      errors.toField = 'Required';
    }
    this.setState({ errors });
    if (errors.toField) {
      return false;
    }
    this.props.saveRecipient(uniqueRecords);
  };

  render() {
    const { uploadType, popupType } = this.props;
    const { formdata, uniqueRecords } = this.state;
    if (popupType && popupType === 'manualPopup') {
      return (
        <div>
          {/* <FileRecipientCreation
            data={this.state}
            formdata={formdata}
            preferences={this.props.preferences}
            updateForm={this.updateForm}
            handleContactChange={this.handleContactChange}
            handleContactInput={this.handleContactInput}
            handleAccountChange={this.handleAccountChange}
            handleAccountInput={this.handleAccountInput}
            handleInputBlur={this.handleInputBlur}
            handleCancel={this.handleCancel}
            handleSave={this.handleSave}
            handleSubmit={this.handleSubmit}
            removeSelectedRecord={this.removeSelectedRecord}
            removeSavedRecord={this.removeSavedRecord}
            recipientDropdownToggle={this.recipientDropdownToggle}
            disableToField={uploadType !== 'manual' ? 'disabled p-0' : ''}
          /> */}
          <div>
            <div className="col-12 form-group formbtn justify-content-end btn--leftspace">
              <button
                onClick={this.props.closeModal}
                type="button"
                className="btn btn-outline-light"
              >
                Cancel
              </button>
              <button
                onClick={this.saveRecipient}
                type="button"
                className="btn btn-primary"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="EmployeeFilePopup">
        <div className="center-wrapper">
          <Modal
            className="customModal customModal--w700 customModal--nopadding"
            isOpen
          >
            <div
              id="loading"
              style={this.state.loading ? blockStyle : noneStyle}
            />
            <div className="ModalHeader">
              <button
                onClick={
                  this.props.closeModal
                    ? this.props.closeModal
                    : this.handleCancelUpload
                }
                type="button"
                className="close"
                aria-label="Close"
              >
                <i className="icon-close2" aria-hidden="true" />
              </button>
            </div>
            <ModalBody className="modalContent" style={{ maxHeight: 'none' }}>
              <div className="modalContent--inner">
                <div className="modalContent--header">
                  <h5 className="modalContent--header__heading">{`${
                    this.props.addToVault
                      ? 'Add to vault'
                      : this.props.shareFile
                      ? 'Share File'
                      : 'Upload File'
                  }`}</h5>
                </div>
                <div className="modalForm">
                  <form>
                    <div className="row">
                      {uploadType === 'manual' &&
                        !this.props.shareFile &&
                        !this.props.addToVault && (
                          <div className="col-12 text-right">
                            <CheckboxField
                              id="makePrivate"
                              name="makePrivate"
                              label="Firm Only"
                              value={formdata.makePrivate}
                              onChange={() =>
                                this.updateForm(
                                  'makePrivate',
                                  !formdata.makePrivate,
                                )
                              }
                              labelclassName="text-body text-transform-none font-weight-normal"
                            />
                          </div>
                        )}
                      {uploadType === 'manual' && (
                        <FileRecipientCreation
                          data={this.state}
                          formdata={formdata}
                          preferences={this.props.preferences}
                          updateForm={this.updateForm}
                          handleContactChange={this.handleContactChange}
                          handleContactInput={this.handleContactInput}
                          handleAccountChange={this.handleAccountChange}
                          handleAccountInput={this.handleAccountInput}
                          handleInputBlur={this.handleInputBlur}
                          handleCancel={this.handleCancel}
                          handleSave={this.handleSave}
                          handleSubmit={this.handleSubmit}
                          removeSelectedRecord={this.removeSelectedRecord}
                          removeSavedRecord={this.removeSavedRecord}
                          recipientDropdownToggle={this.recipientDropdownToggle}
                          disableToField={
                            uploadType !== 'manual' ? 'disabled p-0' : ''
                          }
                        />
                      )}
                      <FilePopupBottom
                        updateForm={this.updateForm}
                        data={this.state}
                        formdata={
                          !formdata.message
                            ? { ...formdata, message: '' }
                            : { ...formdata }
                        }
                        addToVault={this.props.addToVault}
                        preferences={this.props.preferences}
                        closeModal={
                          this.props.closeModal
                            ? this.props.closeModal
                            : this.handleCancelUpload
                        }
                        handleUpload={this.handleUpload}
                        handleShare={this.handleShare}
                        showMessageInput={
                          uploadType === 'manual' && !formdata.makePrivate
                        }
                        uploadType={uploadType}
                        shareFile={this.props.shareFile}
                        delDocumentstoShare={this.delDocumentstoShare}
                        clearForm={this.clearForm}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </ModalBody>
          </Modal>
          {/* <ConfirmationModal
            isOpen={this.state.alertText && this.state.alertText.length}
            headerText={this.state.alertText}
            messageText=""
            noBtnClick={() => this.setState({ alertText: '' })}
            yesBtnClick={() => this.setState({ alertText: '' })}
            yesBtnText="OK"
          /> */}
          <ConfirmationModal
            isOpen={
              this.state.cancelUploadText && this.state.cancelUploadText.length
            }
            headerText={this.state.cancelUploadText}
            messageText={this.state.messageText || ''}
            noBtnClick={() => this.setState({ cancelUploadText: '' })}
            yesBtnClick={
              this.props.closeModal
                ? this.props.closeModal
                : () => this.props.navigate('/')
            }
            yesBtnText="Yes"
            noBtnText="No"
          />
        </div>
      </div>
    );
  }
}

export default withRouter(EmpFilePopup);
