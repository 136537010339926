/* eslint-disable react/no-unused-class-component-methods */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-script-url */
/* eslint-disable react/jsx-no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable consistent-return */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-access-state-in-setstate */
import React, { Component } from 'react';
import Axios from 'axios';
import moment from 'moment';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { stateToHTML } from 'draft-js-export-html';
import Interweave from 'interweave';
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import Select, { components } from 'react-select';
import { EditorState, convertFromRaw } from 'draft-js';
import * as TaskApi from 'api/TaskApi';
import UploadFiles from 'api/UploadFiles';
import { autoTagApi } from 'api/AutoTag';
import ConfirmationModal from 'common/ConfirmationModal';
import { NUMBER } from 'utilities/regularexpressions';
import {
  options,
  removeBreakElementinList,
  generateUniqueHash,
  removeBreakElement,
} from 'common/NotesUtilities';
import {
  SelectFieldWithAvatar,
  SelectField,
  DateField,
  AttachementPortion,
  SelectTemplateFieldOption,
  selectStyles,
  selectTheme,
} from 'common/CustomFields';
import {
  getCpaPreferences,
  AlertMessage,
  getMLData,
  getStorageData,
} from 'utilities/utils';
import {
  CREATE_SESSION_AGREEMENTS_FOR_BULK_SIGNATURE,
  THIRD_PARTY_APPS,
  OAUTH_ACCESS_TOKENS,
  GET_TEMPLATES_FOR_CREATION,
  CREATE_DOC_FROM_TEMPLATE_IDS,
} from 'constants/constants';
import PdfViewer from 'components/PdfViewer/PdfViewer';
import TinymceEditor from 'components/Emails/components/TinymceEditior';

const isIE = /* @cc_on!@ */ false || !!document.documentMode;
let accessToken = '';
const noneStyle = { display: 'none' };
const blockStyle = { display: 'block' };
let ML_APP_DATA = {};
class DocumentSelection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stateValues: {},
      errors: {},
      taskTitle: '',
      taskStatus: '',
      documents: [],
      uploadFiles: [],
      comment: '',
      attachmentId: undefined,
      stafId: '',
      stafName: '',
      partnerTitle: '',
      doctypeOptions: getCpaPreferences().document_type,
      doctype: '',
      template: '',
      content: '',
      contentAfterChange: '',
      stafPerson: [],
      templates: [],
      newDocs: [],
      selectedAttachment: '',
      fees: 0,
      uploadedFilesLength: 0,
      uploadedFilesCount: 0,
      showModal: false,
      viewModal: false,
      isDocumentUpload: false,
      showAttachmentModal: false,
      changedOnce: false,
      loading: false,
      showTemplate: false,
      templateEdited: false,
      templateChecked: false,
      contentChangedInEdit: false,
      modalState: 'Edit',
      templatePdf: '',
      startDate: moment(),
      ownerId: '',
      ownerLabel: '',
      isFileModalSubmit: false,
      popUp: false,
      // previewPopUp: true,
      // previewScreen: false,
      // previewDocument: null,
      yearId: '',
      yearValue: '',
      selectedTags: [],
      selectedDocuments: [],
      owners: [],
      monthId: '',
      monthValue: '',
      type: 'bulk',
      openOnedrive: false,
      thirdPartyApps: [],
      accessToken: '',
      sharedFilesList: [],
      folderSequence: [],
      onedriveFilesActiveTab: 0,
      noSharedFiles: false,
      googleDriveModal: false,
      showSmartVaultWidget: false,
      uniqueId: generateUniqueHash(5),
      selectedOption: null,
    };
    this.updateAutoTagData = this.updateAutoTagData.bind(this);
    this.selectInputRef = React.createRef();
    // eslint-disable-next-line react/no-unused-class-component-methods
    this.autoTagsUpdated = false;
  }

  componentDidMount() {
    // TODO: __HOMEDATA-AUDIT
    const data = getStorageData();
    const taskDueDays = data.pref_type_options.task_due_days
      ? data.pref_type_options.task_due_days[0].label
      : 7;
    this.setState({
      startDate: moment().add(taskDueDays, 'days'),
      ownerId: data.cpa_user_id,
      ownerLabel: data.uname,
      loading: true,
    });
    ML_APP_DATA = getMLData();
    TaskApi.getOwners(this);
    TaskApi.getStafPerson(this);
    const request = Axios.get(THIRD_PARTY_APPS);
    request
      .then((response) => {
        if (response.data.success === 200) {
          this.setState({
            thirdPartyApps: response.data.data.length ? response.data.data : [],
            loading: false,
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((err) => {
        console.log('err', err);
        this.setState({ loading: false });
      });
    this.getTemplates();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.uniqueId && this.props.uniqueId !== nextProps.uniqueId) {
      this.setState({
        stateValues: {},
        errors: {},
        taskTitle: '',
        taskStatus: '',
        documents: [],
        uploadFiles: [],
        comment: '',
        attachmentId: undefined,
        stafId: '',
        stafName: '',
        partnerTitle: '',
        doctypeOptions: getCpaPreferences().document_type,
        doctype: '',
        template: '',
        content: '',
        contentAfterChange: '',
        newDocs: [],
        selectedAttachment: '',
        fees: 0,
        uploadedFilesLength: 0,
        uploadedFilesCount: 0,
        showModal: false,
        viewModal: false,
        isDocumentUpload: false,
        showAttachmentModal: false,
        changedOnce: false,
        loading: false,
        showTemplate: false,
        templateEdited: false,
        templateChecked: false,
        contentChangedInEdit: false,
        modalState: 'Edit',
        templatePdf: '',
        isFileModalSubmit: false,
        popUp: false,
        yearId: '',
        yearValue: '',
        selectedTags: [],
        selectedDocuments: [],
        monthId: '',
        monthValue: '',
        type: 'bulk',
        openOnedrive: false,
        thirdPartyApps: [],
        accessToken: '',
        sharedFilesList: [],
        folderSequence: [],
        onedriveFilesActiveTab: 0,
        noSharedFiles: false,
        googleDriveModal: false,
        showSmartVaultWidget: false,
        uniqueId: generateUniqueHash(5),
        selectedOption: undefined,
      });
    }
  }

  onSuccess = (keys) => {
    keys.map((selectedItem) => {
      const item = selectedItem.driveItem_203[2];
      const head = { headers: { Authorization: `Bearer ${accessToken}` } };
      Axios.get(
        `https://graph.microsoft.com/v1.0/me/drive/items/${item}`,
        head,
      ).then((res) => {
        const selectedfile = [];
        selectedfile.push(res.data);
        this.startUpload(selectedfile, 'onedrive');
      });
      return selectedItem;
    });
    this.handleOnedriveFiles('close');
  };

  onCancel = () => {
    this.handleOnedriveFiles('close');
  };

  onedriveSharedFileHandle = (item, itemType = '') => {
    console.log('item', item, itemType);
    const head = {
      headers: { Authorization: `Bearer ${this.state.accessToken}` },
    };
    if (
      ((item.folder && item.folder !== undefined) ||
        (!item.folder && item.folder === undefined)) &&
      !item.file
    ) {
      this.setState({ loading: true });
      if (item.id === 'shared') {
        this.sharedFolder(1);
      } else {
        Axios.get(
          `https://graph.microsoft.com/v1.0/drives/${item.parentReference.driveId}/items/${item.id}/children?expand=thumbnails`,
          head,
        ).then((response) => {
          if (response.status === 200) {
            let sequence = this.state.folderSequence;
            const index = sequence.findIndex((x) => x.active === true);
            if (index > -1) {
              sequence[index].active = false;
              this.setState({ folderSequence: sequence });
            }
            if (itemType === 'folder') {
              const indx = sequence.findIndex((x) => x.id === item.id);
              if (indx > -1) {
                sequence[indx].active = true;
                sequence = sequence.slice(0, indx + 1);
                this.setState({ folderSequence: sequence });
              }
            } else {
              const seq = {
                name: item.name,
                id: item.id,
                parentReference: { driveId: item.parentReference.driveId },
                active: true,
                folder: true,
              };
              sequence.push(seq);
            }
            this.setState({
              sharedFilesList: response.data.value,
              folderSequence: sequence,
              loading: false,
            });
          } else {
            this.setState({ loading: false });
          }
        });
      }
    } else {
      const checkboxState = this.state.sharedFilesList;
      const index = checkboxState.findIndex((x) => x.id === item.id);
      if (index > -1) {
        checkboxState[index].checked = !checkboxState[index].checked;
        this.setState({ sharedFilesList: checkboxState }, () => {
          // console.log('pali', this.state.sharedFilesList);
        });
      }
    }
  };

  // show template after selection in selected document
  getselecteddoc() {
    const { doctype, template, stafName, partnerTitle, fees, content } =
      this.state;
    const { templateEdited } = this.props.data;
    if (doctype && template) {
      const tempdata = {};
      if (doctype === 'engagement_letter') {
        tempdata.staf_person = stafName;
        tempdata.partner_title = partnerTitle;
        tempdata.client_fee = fees;
      }
      // if template is edited send content else send id of template
      if (templateEdited) {
        tempdata.content = content;
      } else {
        tempdata.id = template;
      }
      TaskApi.getBulkTemplateAndReplaceVars(this, tempdata, 'bulk');
    }
  }

  getTemplateDocsId = () => {
    const mydata = {};
    if (this.state.templateDocuments.length) {
      mydata.template_doc_ids = [];
      let x;
      for (x = 0; x < this.state.templateDocuments.length; x += 1) {
        mydata.template_doc_ids.push(this.state.templateDocuments[x].id);
      }
    }
    mydata.template_type = 'task_template';
    if (this.state.templateDocuments.length) {
      this.setState({ loading: true });
      const request = Axios.post(CREATE_DOC_FROM_TEMPLATE_IDS, mydata);
      request
        .then((response) => {
          this.setState({ loading: false });
          if (response.data.status === 200) {
            const documents = [...this.state.documents];
            if (documents.length) {
              this.setState({
                documents: response.data.documents,
                templateDocuments: [],
                dataUpdated: true,
              });
              // let x;
              // for (x = 0; x < documents.length; x += 1) {
              //   const documnt = { ...documents[x] };
              //   documnt.id = response.data.doc_ids[x];
              //   documents[x] = documnt;
              //   this.setState({ documents });
              //   if (x + 1 === documents.length) {
              //     this.setState({ templateDocuments: [], dataUpdated: true });
              //   }
              // }
            }
          } else {
            AlertMessage('error', response.data.message, 1500);
          }
        })
        .catch(() => {
          this.setState({ loading: false });
        });
    }
  };

  getTemplates() {
    Axios.get(`${GET_TEMPLATES_FOR_CREATION}?template_type=task_template`)
      .then((res) => {
        if (res.data && res.data.data && res.data.data.length) {
          const { data, default_preferences } = res.data;
          this.setState({
            templateOptions: data,
            loading: false,
            defaultPreferences: default_preferences,
          });
        } else {
          this.setState({
            templateOptions: [],
            loading: false,
          });
        }
      })
      .catch(() => {
        this.setState({
          templateOptions: [],
          loading: false,
        });
      });
  }

  setAttachmentNull() {
    const uploadFile = document.getElementById('upload_doc');
    uploadFile.value = null;
  }

  getAuthenticationToken = () => accessToken;

  async updateAutoTagData() {
    try {
      const token = await autoTagApi('login', ML_APP_DATA);
      if (
        !token ||
        (typeof token === 'object' && Object.keys(token).length === 0)
      ) {
        // console.log('errorApi',token)
        await autoTagApi('login', ML_APP_DATA);
        // console.log('res', response);
        // console.log(response)
        // await setAutotagAccessToken(response.token)
        // console.log(await getAccessToken())
      }
      const filesResponse = await Promise.all(
        this.state.documents.map((doc) =>
          autoTagApi('results', { file: doc.doc_name }, true),
        ),
      );
      if (
        filesResponse &&
        filesResponse[0] &&
        filesResponse[0].status &&
        filesResponse[0].status === 401
      ) {
        return this.updateAutoTagData();
      }
      const autoTagData = filesResponse.reduce(
        (acc, cv) => ({
          tags: acc.tags.concat(
            cv.Suggested_tags ? cv.Suggested_tags[0][0] : [],
          ),

          yearsAndMonths: acc.yearsAndMonths.concat(
            [
              {
                month: cv.month || '',
                year: `${cv.year}` || '',
              },
            ] || [],
          ),
        }),
        { yearsAndMonths: [], tags: [] },
      );
      getCpaPreferences().upload_tags.filter((tag) =>
        // return autoTagData.tags.find(autoTag=>autoTag.match(new RegExp(tag.label,'gi')))
        autoTagData.tags.find(
          (autoTag) => autoTag.toLowerCase() === tag.label.toLowerCase(),
        ),
      );
      // console.log({selectedTags,pref:this.props.preferences})
      autoTagData.yearsAndMonths.map((YMO) => ({
        year: getCpaPreferences().upload_file_year.find(
          (item) => item.label === YMO.year,
        ),
        month: getCpaPreferences().upload_file_month.find(
          (item) => item.label === YMO.month,
        ),
      }));
      // this.setState({ yearId: parseInt(filteredYearsAndMonths[0].year.key, 10), yearValue: filteredYearsAndMonths[0].year.label });
      // this.setState({ monthId: filteredYearsAndMonths[0].month.key, monthValue: filteredYearsAndMonths[0].month.label });
      // this.setState({ selectedTags });
    } catch (error) {
      console.error(error);
      // if(error.message.match(/axios is not defined/gi)) this.updateAutoTagData();
    }
  }

  handleOnedriveSelectedFiles = () => {
    const head = {
      headers: { Authorization: `Bearer ${this.state.accessToken}` },
    };
    const checkboxState = this.state.sharedFilesList;
    checkboxState.map((item) => {
      if (item.checked && item.checked !== undefined) {
        Axios.get(
          `https://graph.microsoft.com/v1.0/drives/${item.parentReference.driveId}/items/${item.id}`,
          head,
        ).then((res) => {
          const selectedfile = [];
          selectedfile.push(res.data);
          this.startUpload(selectedfile, 'onedrive');
        });
      }
      this.handleOnedriveFiles('close');
      return null;
    });
  };

  handleOnedriveFiles = (toggle = undefined, item = {}) => {
    if (toggle === 'open') {
      const request = Axios.get(`${OAUTH_ACCESS_TOKENS}/${item.key}`);

      request
        .then((response) => {
          if (response.status === 200) {
            accessToken = response.data.access_token;

            this.setState({
              openOnedrive: !this.state.openOnedrive,
              accessToken
            });
          }
          else {
            AlertMessage(
              'error',
              `Please set up ${item.value} to use the cloud store.`,
              3000,
            );
          }
        })
        .catch((err) => {
          console.log('err', err);
        });
    } else if (toggle === 'close') {
      this.setState({
        openOnedrive: !this.state.openOnedrive,
        sharedFilesList: [],
        folderSequence: [],
        onedriveFilesActiveTab: 0,
      });
    }
  };

  toggleSmartVaultWidget = () => {
    this.setState((ps) => ({ showSmartVaultWidget: !ps.showSmartVaultWidget }));
  };

  attachmentModalClose() {
    this.setState({ showAttachmentModal: false, selectedAttachment: '' });
  }

  inputRenderer = (inputProps) => (
    // delete inputProps.value;
    <components.Input
      {...inputProps}
      value={this.state.titleChange}
      className="Select-input"
    />
  );

  templateDropdown = (selectedOption) => {
    const { taskTitle, comment, documents, warningMsgLost } = this.state;
    let commentExist = true;
    if (!comment) {
      commentExist = false;
    } else if (typeof comment === 'object') {
      if (
        !EditorState.createWithContent(convertFromRaw(comment))
          .getCurrentContent()
          .getPlainText().length
      ) {
        commentExist = false;
      }
    }
    if (
      (taskTitle || commentExist || documents.length) &&
      !warningMsgLost &&
      (!selectedOption || (selectedOption && !taskTitle))
    ) {
      this.setState({
        warningMsgLost: true,
        selectedTemplate: selectedOption,
      });
    } else if (selectedOption && selectedOption !== null) {
      const { content, value } = selectedOption;
      // let coment = '';
      const coment = removeBreakElement(content);
      // if (content_json && content_json !== null) {
      //   coment = JSON.parse(content_json);
      // } else {
      //   coment = removeBreakElement(content);
      // }
      this.setState(
        {
          selectedOption,
          taskTitle: value,
          titleChange: '',
          comment: coment,
          uniqueId: generateUniqueHash(5),
          warningMsgLost: false,
          selectedTemplate: null,
          templateDocuments:
            selectedOption.documents && selectedOption.documents.length
              ? selectedOption.documents
              : [],
          documents:
            selectedOption.documents && selectedOption.documents.length
              ? selectedOption.documents
              : [],
        },
        () => {
          if (
            this.state.templateDocuments &&
            this.state.templateDocuments.length
          ) {
            this.getTemplateDocsId();
          }
        },
      );
    } else {
      this.setState(
        {
          selectedOption: null,
          taskTitle: '',
          titleChange: '',
          comment: '',
          uniqueId: generateUniqueHash(5),
          warningMsgLost: false,
          selectedTemplate: null,
          documents: [],
          templateDocuments: [],
        },
        () => {
          const node = this.selectInputRef.current;
          if (node) {
            node.state.inputValue = '';
          }
        },
      );
    }
  };

  templateChange = (input, action) => {
    const val =
      action.action === 'input-change' ? input : action.prevInputValue;
    if (val || (!val && this.state.titleChange)) {
      this.setState({
        taskTitle: val,
        titleChange: val,
      });
    }
  };

  // handle document type change
  handleDoctype = (val) => {
    this.setState(
      {
        doctype: val.value,
        doctypeName: val.label,
        template: '',
        templates: '',
        stafId: '',
        fees: '',
        content: '',
        errors: {},
      },
      () => {
        this.props.showSignatureModal(false, false, '', val.value);
        this.props.resetDocumentSection();
        TaskApi.getEngagementLetters(this, val.value);
      },
    );
  };

  // handle document change
  handleTemplate = (val) => {
    this.setState(
      {
        template: val.value,
        templateName: val.label,
        errors: {},
        templateChecked: false,
        templateEdited: false,
        contentChangedInEdit: true, // in edit mode if template changed (send param get sign true)
      },
      () => {
        this.getselecteddoc();
      },
    );
  };

  // handle partner change (staf person)
  handleStaf = (val) => {
    this.setState(
      {
        stafId: val.value,
        stafName: val.label,
        partnerTitle: val.partner_title,
        errors: {},
        contentChangedInEdit: true, // in edit mode if partner changed
      },
      () => {
        this.getselecteddoc();
      },
    );
  };

  handleCommentChange = (value) => {
    this.setState({
      comment: value,
      isChanged: true,
      dataUpdated: true,
    });
  };

  feeChange = (e) => {
    e.target.value = this.formatFee(e.target.value);
    this.setState({
      fees: e.target.value,
      errors: {},
      contentChangedInEdit: true,
    });
  };

  formatFee(input) {
    if (input === undefined) {
      return input;
    }
    if (input === null) {
      return undefined;
    }
    let val = input.replace(/\D/g, '');
    let newVal = '';
    if (val.length > 3) {
      const lastThree = val.substr(val.length - 3);
      val = val.substring(0, val.length - 3);
      if (val.length > 3) {
        val = this.formatFee(val);
      }
      val = `${val},${lastThree}`;
    }
    newVal += val;
    return newVal;
  }

  handleFileDrop = (files, e) => {
    if (e) {
      const uploadFile = files;
      this.startUpload(uploadFile);
      this.setState({ popUp: true });
      return (
        <AttachementPortion
          name="bulk"
          handleFileDrop={this.handleFileDrop}
          data={this.state}
          setAttachmentNull={this.setAttachmentNull}
          uploadDocument={this.uploadDocument}
          uploadPopUp
          uploadFiles={this.state.uploadFiles}
          documents={this.state.documents}
          cancelUpload={this.cancelUpload}
          openAttachment={this.openAttachment}
          showModal={this.showModal}
          component={this}
          sharedFolder={this.sharedFolder}
          onedriveSharedFileHandle={this.onedriveSharedFileHandle}
          handleOnedriveSelectedFiles={this.handleOnedriveSelectedFiles}
          getAuthenticationToken={this.getAuthenticationToken}
          onSuccess={this.onSuccess}
          onCancel={this.onCancel}
        />
      );
    }
  };

  googleDrive = (stat = '') => {
    if (stat === 'false') {
      this.setState({ googleDriveModal: false });
    } else {
      this.setState((prevState) => ({
        googleDriveModal: !prevState.googleDriveModal,
      }));
    }
  };

  googleDriveLoader = (stat = '') => {
    if (stat === 'false') {
      this.setState({ loading: false });
    } else if (stat === 'true') {
      this.setState({ loading: true });
    } else {
      this.setState((prevState) => ({ loading: !prevState.loading }));
    }
  };

  uploadfile = (uploadFile, fro = '') => {
    this.startUpload(uploadFile, fro);
  };

  startUpload(uploadFile, fro = '') {
    let i;
    const component = this;
    let maxsizeInBytes = 629145600;
    let maxsizeInMb = 600;
    if (
      getCpaPreferences() &&
      getCpaPreferences().attachment &&
      getCpaPreferences().attachment[0].label &&
      NUMBER.test(getCpaPreferences().attachment[0].label)
    ) {
      maxsizeInBytes = getCpaPreferences().attachment[0].label * 1024 * 1024;
      maxsizeInMb = getCpaPreferences().attachment[0].label;
    }
    component.setState({
      isDocumentUpload: true,
      uploadedFilesLength: this.state.uploadedFilesLength + uploadFile.length,
    });
    const onUploadProgress = (uploadedFile) => (progress) => {
      const percentage = Math.floor((progress.loaded * 100) / progress.total);
      const key = `percentCompleted${uploadedFile}`;
      this.setState({ [key]: percentage });
    };
    for (i = 0; i < uploadFile.length; i += 1) {
      const data = new FormData();
      if (fro === 'onedrive' || fro === 'sharepoint') {
        // this.googleDriveLoader('false');
        data.append('aws_url_file_name', uploadFile[i].name);
        data.append('aws_url', uploadFile[i]['@microsoft.graph.downloadUrl']);
      } else if (fro === 'google_drive' || fro === 'smart_vault') {
        // this.googleDriveLoader('false');
        data.append('aws_url_file_name', uploadFile[i].name);
        data.append('aws_url', uploadFile[i].file);
      } else {
        data.append('aws_url', uploadFile[i], uploadFile[i].name);
      }
      if (uploadFile[i].size > maxsizeInBytes) {
        alert(`File exceeds limit of ${maxsizeInMb}MB`);
        return false;
      }
      const { CancelToken } = Axios;
      const source = CancelToken.source();
      const key = `${uploadFile[i].name}sourceToken`;
      this.setState({ [key]: source });
      this.state.uploadFiles.push(uploadFile[i].name);
      const config = {
        headers: { 'content-type': 'multipart/form-data' },
        cancelToken: source.token,
        onUploadProgress: onUploadProgress(uploadFile[i].name),
      };
      TaskApi.addDocument(
        data,
        component,
        config,
        uploadFile[i].name,
        'bulkSignature',
        fro,
      );
      const State = this.state.stateValues;
      State.isChanged = true;
      this.setState({ stateValues: State });
      if (component.state.errors.documents) {
        const err = {};
        err.documents = '';
        component.setState({ errors: err });
      }
    }
    return true;
  }

  uploadDocument = () => {
    const uploadFile = document.getElementById('upload_doc').files;
    this.startUpload(uploadFile);
  };

  cancelUpload = (filename) => {
    this.state[`${filename}sourceToken`].cancel();
  };

  handleDeleteAttachment = () => {
    TaskApi.deleteTaskAttachment(this.state.attachmentId, this);
    this.setState({ showModal: false });
  };

  // alert for delete attachment
  showModal = (nid, type) => {
    if (type === 'attachment') {
      this.setState({ attachmentId: nid, showModal: true });
    }
  };

  // hide alert for delete attachment
  hideModal = () => {
    this.setState({ showModal: false });
  };

  // for attachement preview
  openAttachment = (c) => {
    UploadFiles.showOpenAttachment(c, this);
  };

  isFormValid() {
    const { taskTitle, doctype, template, stafId, fees, ownerId } = this.state;
    const err = {};
    let formIsValid = true;
    if (ownerId === '' || !ownerId) {
      formIsValid = false;
      err.owner_error = 'Please select task owner';
    }
    if (taskTitle === '' || !taskTitle) {
      formIsValid = false;
      err.taskTitle_error = 'Enter task title';
    }
    if (doctype === '' || !doctype) {
      formIsValid = false;
      err.doctype_error = 'Please select document type';
    }
    if (template === '' || !template) {
      formIsValid = false;
      err.document_error = 'Please select document';
    }
    if (doctype === 'engagement_letter' && (!stafId || stafId === '')) {
      formIsValid = false;
      err.partner_error = 'Please select partner';
    }
    if (doctype === 'engagement_letter' && (!fees || fees === '')) {
      formIsValid = false;
      err.fees_error = 'Required';
    }
    this.setState({ errors: err });
    return formIsValid;
  }

  showSelectedData() {
    if (this.isFormValid()) {
      this.createSessionAgreements();
    }
  }

  handleChange = (e) => {
    this.setState({ errors: {}, taskTitle: e.target.value });
  };

  createSessionAgreements() {
    const { selectedData } = this.props;
    const { templateEdited, contentbeforeSave } = this.props.data;
    const {
      documents,
      fees,
      stafId,
      stafName,
      comment,
      startDate,
      partnerTitle,
    } = this.state;
    const ids = [];
    selectedData.map((each) => {
      const tmpHash = {};
      const key = each.contact.id;
      const val = each.contact.account_id;
      tmpHash[key] = val;
      ids.push(tmpHash);
      return each;
    });
    const {
      template: template_id,
      taskTitle: subject,
      ownerId: owner_id,
      doctype,
    } = this.state;
    const datatoSend = {
      template_id,
      subject,
      owner_id,
      ids,
    };
    datatoSend.due_by = startDate.format('DD/MM/YYYY');
    const documentsIds = [];
    documents.map((each) => {
      documentsIds.push(each.id);
      return each;
    });
    if (doctype === 'engagement_letter') {
      datatoSend.client_fee = fees;
      datatoSend.partner = stafId;
      datatoSend.staf_person = stafName;
      datatoSend.partner_title = partnerTitle;
    }
    if (templateEdited) {
      datatoSend.content = contentbeforeSave;
    }
    if (
      typeof comment === 'object' &&
      comment !== null &&
      EditorState.createWithContent(convertFromRaw(comment))
        .getCurrentContent()
        .getPlainText().length
    ) {
      datatoSend.description = removeBreakElementinList(
        stateToHTML(
          EditorState.createWithContent(
            convertFromRaw(comment),
          ).getCurrentContent(),
          options,
        ),
      );
      datatoSend.description_in_json = JSON.stringify(comment);
    } else if (typeof comment === 'string' && comment.length) {
      datatoSend.description = comment;
    } else {
      datatoSend.description = '';
    }
    // if (datatoSend.description && datatoSend.description.length > 0) {
    //   datatoSend.description = datatoSend.description.replace(/\n/g, '<br>');
    // }
    Axios.post(CREATE_SESSION_AGREEMENTS_FOR_BULK_SIGNATURE, datatoSend).then(
      (res) => {
        if (res.data.status === 200) {
          this.props.showSelectedData(
            true,
            res.data.data,
            doctype,
            documentsIds,
          );
        }
      },
    );
  }

  errorDoc = () => {
    const err = {};
    err.documents = 'Please select document';
    this.setState({ errors: err });
  };

  openPopUp = () => {
    this.setState({ popUp: !this.state.popUp });
  };

  cancelAllUploads() {
    const component = this;
    if (component.state.uploadFiles.length > 0) {
      const tmpHash = {};
      tmpHash.uploadFiles = [];
      const { uploadFiles } = component.state;
      uploadFiles.map((file) => {
        component.state[`${file}sourceToken`].cancel();
        return null;
      });
      component.setState(tmpHash);
    }
    if (component.state.newDocs.length) {
      const docIds = [];
      let x;
      let count = 0;
      for (x = 0; x < component.state.newDocs.length; x += 1) {
        const { id } = component.state.newDocs[x];
        const indx = component.state.documents.findIndex((y) => y.id === id);
        if (indx !== -1) {
          docIds.push(component.state.documents[x].id);
          count += 1;
          if (count === component.state.newDocs.length) {
            component.setState({ newDocs: [] });
          }
        }
      }
      const data = { doc_ids: docIds };
      data.doc_ids.map((item) => {
        const index = component.state.documents.findIndex((y) => y.id === item);
        const uploadedFiles = component.state.documents;
        uploadedFiles.splice(index, 1);
        component.setState({ documents: uploadedFiles });
        return null;
      });
      TaskApi.cancelDocuments(component, data, 'tasks');
    }
  }

  stateUpdate = (value, changetype = '', data = '') => {
    const stateUpdatedValues = { ...value };
    this.setState({ stateUpdatedValues });
    if (changetype === 'isFileModalTaskDetail' && data) {
      this.setState({
        newDocs: [],
        errors: {},
        yearId: value.yearId,
        yearValue: value.yearValue,
        monthId: value.monthId,
        monthValue: value.monthValue,
        selectedTags: data.tags,
        selectedDocuments: data.doc_ids,
        isFileModalSubmit: true,
      });
    }
    if (changetype === 'isFileModalSubmit' && data) {
      this.setState({
        yearId: value.yearId,
        yearValue: value.yearValue,
        monthId: value.monthId,
        monthValue: value.monthValue,
        selectedTags: data.tags,
        selectedDocuments: data.doc_ids,
        isFileModalSubmit: true,
      });
    }
  };

  handleOwnerChange = (val) => {
    if (val) {
      if (val.value === this.state.ownerId) {
        return false;
      }
      this.setState({ ownerId: val.value, ownerLabel: val.label });
    } else {
      this.setState({ ownerId: '', ownerLabel: '' });
    }
    this.setState({ errors: {} });
  };

  handleDateChange = (date) => {
    this.setState({ startDate: moment(date) });
  };

  sharedFolder = (key) => {
    if (key === 0) {
      this.setState({
        sharedFilesList: [],
        folderSequence: [],
        onedriveFilesActiveTab: 0,
        noSharedFiles: false,
      });
    } else if (key === 1) {
      const head = {
        headers: { Authorization: `Bearer ${this.state.accessToken}` },
      };
      this.setState({ loading: true, sharedFilesList: [], folderSequence: [] });
      Axios.get(
        'https://graph.microsoft.com/v1.0/me/drive/sharedWithMe',
        head,
      ).then((response) => {
        if (response.status === 200) {
          if (response.data.value.length === 0) {
            this.setState({ loading: false, noSharedFiles: true });
            // AlertMessage('error', 'No items found in shared list', 1000);
          } else {
            const seq = this.state.folderSequence;
            seq.push({
              name: 'Shared',
              id: 'shared',
              parentReference: { driveId: 'shared' },
              active: true,
              folder: true,
            });
            this.setState({ folderSequence: seq });
            response.data.value.map((item) => {
              const data = item.remoteItem;
              const sharedFiles = this.state.sharedFilesList;
              sharedFiles.push(data);
              this.setState({
                sharedFilesList: sharedFiles,
                onedriveFilesActiveTab: 1,
              });
              return null;
            });
            this.setState({ loading: false });
          }
        } else {
          this.setState({ loading: false });
        }
      });
    }
  };

  render() {
    const data = getStorageData();
    const cpaLogo = data ? data.cpa_logo : '';
    const {
      doctype,
      errors,
      stafPerson,
      templates,
      template,
      doctypeOptions,
      taskTitle,
      fees,
      uploadFiles,
      documents,
      showModal,
      selectedOption,
      templateOptions,
      selectedTemplate,
    } = this.state;
    const { contentbeforeSave, templateChecked } = this.props.data;
    return (
      <div
        id="content"
        data-testid="documentSection"
        className={this.props.showThis ? '' : 'hidden'}
      >
        <div id="loading" style={this.state.loading ? blockStyle : noneStyle} />
        <div className="center-wrapper col-lg-10 col-sm-12 col-md-12 m-auto with-disabled-inputs">
          <div className="pb-3 col-container section-title">
            <h6 role="presentation" className="stepBacklink">
              <a
                href="javascript:;"
                onClick={() => this.props.showDocumentSectionFn(false)}
              >
                <i className="icon-left-arrow" /> Back
              </a>
            </h6>
          </div>
          <div className="row">
            <DateField
              label="Due Date:"
              labelClass="mt-1 pt-2"
              startDate={this.state.startDate}
              handleChange={this.handleDateChange}
              minDate={moment()}
              maxDate={undefined}
            />
            <SelectFieldWithAvatar
              name="taskOwner"
              label="Owner:"
              labelClass="mt-1 pt-2"
              options={this.state.owners}
              value={{
                value: this.state.ownerId,
                label: this.state.ownerLabel,
              }}
              handleChange={this.handleOwnerChange}
              error={errors.owner_error}
              clearable={false}
            />
          </div>
          <div className="row">
            <SelectField
              className="form-group col-md-6 col-12 docsWidth"
              name="doctype"
              label="Select Document Type *"
              options={doctypeOptions}
              handleChange={this.handleDoctype}
              value={{ value: doctype, label: this.state.doctypeName }}
              error={errors.doctype_error}
              clearable={false}
              searchable={false}
            />
            <SelectField
              className="form-group col-md-6 col-12 docsWidth"
              name="template"
              label="Select Document *"
              options={templates}
              handleChange={this.handleTemplate}
              value={{ value: template, label: this.state.templateName }}
              error={errors.document_error}
              clearable={false}
            />
          </div>
          {doctype === 'engagement_letter' ? (
            <div className="row">
              <SelectFieldWithAvatar
                name="partner"
                label="Select Partner"
                labelClass="mt-1 pt-2"
                options={stafPerson}
                handleChange={this.handleStaf}
                // value={stafId}
                value={{ value: this.state.stafId, label: this.state.stafName }}
                error={errors.partner_error}
                clearable={false}
                searchable={false}
              />
              <div
                className={`col-md-6 col-12 docsWidth form-group form-inline-label align-items-stretch ${
                  errors.fees_error ? 'form-error' : ''
                }`}
              >
                <label htmlFor="taskTitle" className="mt-1 pt-2">
                  Fees
                </label>
                <div className="labelValue">
                  <input
                    value={fees}
                    name="fees"
                    onChange={(e) => this.feeChange(e)}
                    onFocus={(e) => e.target.select()}
                    onBlur={() => this.getselecteddoc()}
                    onKeyUp={() => this.getselecteddoc()}
                    className="form-control"
                  />
                  <span className="error_label">{errors.fees_error}</span>
                </div>
              </div>
            </div>
          ) : null}
          <div className="row">
            <div
              className={`form-group col-12${
                errors.taskTitle_error
                  ? ' form-error cstmClr msgCustmClr'
                  : ' cstmClr msgCustmClr'
              }`}
            >
              <label htmlFor="taskTitle">Subject</label>
              <div className="labelValue customEditor boxResize flWdth">
                {(selectedOption && selectedOption !== null) ||
                (!selectedOption &&
                  selectedOption === null &&
                  taskTitle &&
                  !this.state.titleChange) ||
                data.isEdit ? (
                  <input
                    autoComplete="off"
                    name="taskTitle"
                    className="form-control"
                    id="taskTitle"
                    type="text"
                    value={taskTitle}
                    maxLength="200"
                    onChange={this.handleChange}
                  />
                ) : (
                  <Select
                    name="taskTitle"
                    placeholder=""
                    id="DocumentSection__SelectTemplate"
                    inputId="DocumentSection__SelectTemplate"
                    ref={this.selectInputRef}
                    className="select-custom-class slctTemp"
                    value={selectedOption}
                    onChange={this.templateDropdown}
                    options={templateOptions}
                    inputValue={this.state.titleChange}
                    onInputChange={this.templateChange}
                    tabSelectsValue={false}
                    onBlurResetsInput={false}
                    onCloseResetsInput={false}
                    onSelectResetsInput={this.state.titleChange} // false shows
                    noResultsText=""
                    styles={selectStyles}
                    theme={selectTheme}
                    isDisabled={data.isEdit}
                    classNamePrefix="custom-react-select"
                    components={{
                      Option: SelectTemplateFieldOption,
                      Input: this.inputRenderer,
                    }}
                  />
                )}
              </div>
              {taskTitle ? (
                <Button
                  className="btn btn-sm btn-primary"
                  data-testid="clearBtn"
                  onClick={() => this.templateDropdown(null)}
                >
                  Clear
                </Button>
              ) : null}
              <span className="error_label">{errors.taskTitle_error}</span>
            </div>
          </div>
          {contentbeforeSave ? (
            <div className="row" id="templateData">
              <div className="form-group col-lg-12">
                <div className="has-float-label customEditor boxResize">
                  <label htmlFor="taskDescription">Selected Document</label>
                  <div className="labelValue">
                    <div className="form-control">
                      <a
                        href="javascript:void(0)"
                        onMouseDown={() =>
                          this.props.showSignatureModal(
                            true,
                            true,
                            contentbeforeSave,
                            doctype,
                          )
                        }
                        className="expand text-dark pull-right mr-1 mt-1"
                      >
                        <i className="icon-expand" />
                      </a>
                      <Scrollbars
                        style={{ width: '100%' }}
                        autoHide
                        autoHideTimeout={1000}
                        renderTrackHorizontal={(props) => (
                          <div {...props} className="thumb-vertical" />
                        )}
                        autoHeight
                        autoHeightMin={0}
                        autoHeightMax={300}
                      >
                        <div className="cpaLogo pt-3 pb-3 pl-2 pr-2">
                          <img src={cpaLogo} alt="CPA Logo" />
                        </div>
                        <div className="pt-1 pb-1 pl-2 pr-2">
                          <Interweave
                            allowAttributes
                            content={contentbeforeSave}
                          />
                        </div>
                      </Scrollbars>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          <div className="row">
            <div className="form-group col-lg-12">
              <div className="has-float-label customEditor boxResize">
                <label htmlFor="taskDescription">Description</label>
                <TinymceEditor
                  initialValue=""
                  value={this.state.comment}
                  handleChange={this.handleCommentChange}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="form-group dragWrap col-lg-12">
              <label htmlFor="attachments">Attachments</label>
              <AttachementPortion
                name="bulk"
                handleFileDrop={this.handleFileDrop}
                data={this.state}
                setAttachmentNull={this.setAttachmentNull}
                uploadDocument={this.uploadDocument}
                uploadFiles={uploadFiles}
                documents={documents}
                cancelUpload={this.cancelUpload}
                openAttachment={this.openAttachment}
                showModal={this.showModal}
                uploadPopUp
                component={this}
                sharedFolder={this.sharedFolder}
                onedriveSharedFileHandle={this.onedriveSharedFileHandle}
                handleOnedriveSelectedFiles={this.handleOnedriveSelectedFiles}
                getAuthenticationToken={this.getAuthenticationToken}
                onSuccess={this.onSuccess}
                onCancel={this.onCancel}
              />
            </div>
          </div>
          <div className="formbtn justify-content-end">
            <div className="btn-wrap btn--leftspace">
              <button
                type="button"
                className="btn btn-outline-light"
                onClick={() => this.props.showDocumentSectionFn(false)}
              >
                Cancel
              </button>
              {templateChecked ? (
                <button
                  type="button"
                  className="btn btn-primary"
                  onMouseDown={() => this.showSelectedData()}
                >
                  Create Document
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary"
                  disabled={!contentbeforeSave}
                  onMouseDown={() =>
                    this.props.showSignatureModal(
                      true,
                      true,
                      contentbeforeSave,
                      doctype,
                    )
                  }
                >
                  Preview Document
                </button>
              )}
            </div>
          </div>
        </div>
        <ConfirmationModal
          isOpen={showModal}
          headerText="You are about to delete the attached file."
          messageText="This action cannot be undone. Proceed?"
          noBtnClick={this.hideModal}
          yesBtnClick={this.handleDeleteAttachment}
          data-testid="yesBtn"
          yesBtnText="Yes"
          noBtnText="No"
        />
        <ConfirmationModal
          isOpen={this.state.warningMsgLost}
          className="customModal customModal--w500 customModal--nopadding"
          messageText="You are about to delete the existing message. This action cannot be undone. Proceed?"
          noBtnClick={() => this.setState({ warningMsgLost: false })}
          yesBtnClick={() => this.templateDropdown(selectedTemplate)}
          yesBtnText="Yes"
          noBtnText="No"
        />
        <Modal
          isOpen={this.state.showAttachmentModal}
          className="customModal customModal--w990 customModal--nopadding"
        >
          <ModalHeader className="ModalHeader">
            <button
              type="button"
              onClick={() => this.attachmentModalClose()}
              className="close"
              aria-label="Close"
            >
              <i className="icon-close2" aria-hidden="true" />
            </button>
          </ModalHeader>
          <ModalBody className="modalContent">
            <div className="modalContent--inner">
              <div
                className={
                  isIE ? 'left-inner-content' : 'sample-letter-preview'
                }
              >
                <div className="letter-logo" />
                {isIE ? (
                  <PdfViewer file={this.state.selectedAttachment} />
                ) : (
                  <iframe
                    width="100%"
                    height="600"
                    src={this.state.selectedAttachment}
                    title="selected attachment"
                  />
                )}
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default DocumentSelection;
