/* eslint-disable no-param-reassign */
import axios from 'axios';
import { removeBreakElement } from 'common/NotesUtilities';
import { AlertMessage, getCurrentPath } from 'utilities/utils';
import {
  GET_THREAD_MESSAGES,
  DISCARD_DRAFT,
  ARCHIVE_DRAFT,
  MESSAGES_API_PATH,
} from 'constants/constants';

export const discardDraft = (
  component,
  id,
  dispatch,
  fro = '',
  loadingg = true,
  deleteThreadId = false,
) => {
  component.setState({ loading: loadingg, apiHitting: true });
  const request = axios.delete(`${DISCARD_DRAFT}/${id}`);
  request
    .then((response) => {
      component.setState({ loading: false });
      if (response.status === 200) {
        const { match, history, location } = component.props;
        const path = getCurrentPath(location);
        if (dispatch) {
          dispatch(decodeURIComponent(id));
        }
        if (fro === 'newMsg') {
          component.closeModal();
        }
        if (fro === 'replyWithLiscio') {
          component.closeModal(response.data?.message);
        }
        if (path !== '/contactdetails/message/:id') {
          component.setState({ contactId: '', contactName: '' });
        }
        component.setState(
          {
            accountId: '',
            accountName: '',
            uploadFiles: [],
            documents: [],
            inputVal: '',
            messageId: '',
            messageThreadId: deleteThreadId
              ? ''
              : component.state.messageThreadId,
          },
          () => {
            component.setState({ apiHitting: false });
          },
        );
        if (fro === 'thread') {
          history.push({
            pathname: '/all_messages',
            state: {
              from: match.pathname,
              filter: component.props.data.filter,
              activeTab: component.props.data.activeTab,
            },
          });
        }
      } else {
        component.setState({ apiHitting: false });
      }
    })
    .catch(() => {
      component.setState({ loading: false, apiHitting: false });
      return null;
    });
};

export const archiveThread = (component, threadId, dispatch = '') => {
  component.setState({ loading: true });
  const id = encodeURIComponent(threadId);
  const request = axios.delete(`${ARCHIVE_DRAFT}/${id}`);
  request
    .then((response) => {
      component.setState({ loading: false });
      if (response.data.status === 200) {
        if (dispatch) {
          component.props.actions.archiveThread(component);
        }
        const page = component.props.propsData.message_page;
        const { filter } = component.props.data;
        if (component.props.data.allMessages[filter].length === 0 && page > 1) {
          if (page === 1) {
            component.props.handlePageChange(1);
          } else if (page > 1) {
            component.props.handlePageChange(page - 1);
          }
        }
        component.props.navigate('/all_messages');
      }
    })
    .catch(() => {
      component.setState({ loading: false });
      return null;
    });
};

export const getDraftData = (component, id) => {
  component.setState({ loading: true });
  const request = axios.get(`${MESSAGES_API_PATH}/${id}`);
  request
    .then((response) => {
      component.setState({ loading: false, comment: '' });
      if (response.status === 200) {
        const responsedata = response.data.message[0];
        const participants = [];
        responsedata.participants.map((item) => {
          const tmpHash = {};
          tmpHash.accountId = item.account_id;
          tmpHash.contactId = item.id;
          tmpHash.contactName = item.contact_name;
          tmpHash.assigneeType = item.assignee_type;
          tmpHash.accountName = item.account_name;
          participants.push(tmpHash);
          return true;
        });
        let comment = '';
        const {
          content,
          content_json,
          title,
          thread_id,
          id: messageId,
          is_notify_via_text,
          documents,
        } = responsedata;
        if (content_json && content_json !== null) {
          comment = content_json;
        } else {
          comment = removeBreakElement(content);
        }
        component.setState({
          comment,
          initialComment: comment,
          subject: title,
          titleChange: title,
          allSelectedRecords: participants,
          documents,
          messageThreadId: thread_id,
          messageId,
          notifyViaText: is_notify_via_text,
        });
      }
    })
    .catch(() => {
      component.setState({ loading: false });
      return null;
    });
};

export const getThreads = (component, id) => {
  component.setState({ loading: true });
  const request = axios.get(`${GET_THREAD_MESSAGES}/${id}`);
  request
    .then((response) => {
      component.setState({ loading: false });
      if (response.data.status === 200) {
        if (
          component.props.data.filter === 'draft' &&
          response.data.message &&
          response.data.message.length === 1
        ) {
          component.setState({ draftModel: true });
        } else if (
          response.data.message.length > 0 &&
          ((component.props.data.filter === 'draft' &&
            response.data.message.length !== 1) ||
            component.props.data.filter !== 'draft')
        ) {
          const responsedata = response.data.message[0];
          const selectedParticipants = [];
          let selectedDocuments = [];
          let selectedComment = '';
          let initialComment = '';
          let selectedMessageId = '';
          if (response.data.has_draft) {
            const draftObj = response.data.draft[0];
            selectedDocuments = draftObj.documents;
            // selectedComment = draftObj.content_json && draftObj.content_json !== null ? draftObj.content_json : removeBreakElement(draftObj.content);
            // initialComment = draftObj.content_json && draftObj.content_json !== null ? draftObj.content_json : '';
            selectedComment = removeBreakElement(
              draftObj.content_for_web_display,
            );
            initialComment = '';
            selectedMessageId = draftObj.id;

            draftObj.participants.map((item) => {
              const tmpHash = {};
              tmpHash.accountId = item.account_id;
              tmpHash.contactId = item.id;
              tmpHash.contactName = item.contact_name;
              tmpHash.assigneeType = item.assignee_type;
              tmpHash.accountName = item.account_name;
              selectedParticipants.push(tmpHash);
              return true;
            });
          }

          if (
            (response.data.has_draft && response.data.draft[0].warning_msg) ||
            response.data?.warning_msg
          ) {
            const errors = {};
            errors.participantError =
              'The message has no active firm recipients. Please add another firm recipient.';
            component.setState({ errors });
          }
          const participants = [];
          responsedata.participants.map((item) => {
            const tmpHash = {};
            tmpHash.accountId = item.account_id;
            tmpHash.contactId = item.id;
            tmpHash.contactName = item.contact_name;
            tmpHash.assigneeType = item.assignee_type;
            tmpHash.accountName = item.account_name;
            participants.push(tmpHash);
            return true;
          });
          if (response.data.message) {
            let detectedScrollToThread = false;
            response.data.message.map((item, index) => {
              if (
                (!item.read || index + 1 === response.data.message.length) &&
                !detectedScrollToThread
              ) {
                item.scrollToThisThread = true;
                detectedScrollToThread = true;
              }
              return item;
            });
          }
          const imagesArray = [];
          response.data.message.forEach((item) => {
            if (item.images?.length > 0) {
              const images = [];
              item.images.forEach((key) => {
                images.push(key);
              });
              imagesArray.push(images);
            }
          });
          component.setState({
            unread_thread_count: response.data.unread_thread_count,
            threadArray: response.data.message,
            allSelectedRecords: participants,
            selectedRecords: selectedParticipants,
            messageThreadId: responsedata.thread_id,
            threadTitle: responsedata.title,
            documents: selectedDocuments,
            hasDraft: response.data.has_draft,
            messageId: selectedMessageId,
            comment: selectedComment,
            messagesListId: responsedata.id,
            initialComment,
            imagesArray,
            account_data: response.data?.account_data,
          });
          document.title = `Message - ${responsedata.title}`;
          component.props.actions.receiveThreadCount(
            response.data.unread_thread_count,
          );
        } else {
          component.props.navigate('/');
        }
      } else if (response.data.status === 422) {
        component.props.unauthorised(
          '(Only Liscio Administrators and the persons who were included on the message thread can view the contents of this link)',
        );
      } else {
        component.props.unauthorised('');
      }
    })
    .catch(() => {
      component.setState({ loading: false });
      component.props.unauthorised('');
      AlertMessage('error', 'Some error occurred. Please try later.', 3000);
      return null;
    });
};
