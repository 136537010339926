/* eslint-disable import/no-cycle */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useMemo, useState } from 'react';
import { ButtonGroup, Button } from 'reactstrap';
import Axios from 'axios';
import dayjs from 'dayjs';
import DOMPurify from 'dompurify';
import TruncateMarkup from 'react-truncate-markup';
import moment from 'moment-timezone';
import JSZip from 'jszip';
import mime from 'mime';
import FileSaver from 'file-saver';
import FilePopup from 'components/Files/FilePopup';
import { AlertMessage } from 'utilities/utils';
import { ZIP_ATTACHMENT_DATA } from 'constants/constants';
import ContactCard from 'common/ContactCard';
import styled from '@emotion/styled';
import {
  getInitialState,
  download,
  extractContactsMultipleEmailsString,
} from 'components/Email/EmailUtilities';
import LiscioLogo from 'images/liscio_logo.png';
import LoadingOverlay from 'common/LoadingOverlay';
import DoneAllOutlinedIcon from '@mui/icons-material/DoneAllOutlined';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { TooltipStyled } from 'ui/TooltipStyled/TooltipStyled';
import { useEmailAttachmentsUrl } from 'hooks/use-email-attachments-url';
import EmailLinkedWith from './EmailLinkedWith';
import ComposeEmailFromThread from './ComposeEmailFromThread';
// TODO: There is a dependency cycle created by EmailActionsDropdown.
// @Harpreet and @Vijay will be looking into this soon.
import EmailActionsDropdown from './EmailActionsDropdown';
import RecipientsSection from './RecipientsSection';
import CreateAccountContact from './CreateAccountContact';
import AssociateButton from './icons/AssociateButton';
import EmailAttachments from './EmailAttachments';
import useWhichSection from './useWhichSection';

const EmRightBio = styled.div`
  &:first-child {
    padding-top: 15px;
  }
  .EMtopWrap {
    display: flex;
    padding: 10px;
    border: 1px solid #ececec;
    border-color: ${({ showMore }) => (showMore ? 'transparent' : '')};
    cursor: pointer;
  }
  .EmailRecTo {
    position: relative;
    & > span.button-icon {
      position: absolute;
      right: 0;
      top: 17px;
      padding: 1px 3px;
      line-height: 1.3;
      transform: rotate(180deg);
      cursor: pointer;
    }
  }
  .EMreply__Block .EmailRecTo__active .button-icon {
    transform: rotate(0deg);
  }
  .MsgWrap {
    flex-grow: 1;
  }
  .EMreply {
    &__Block {
      .EmreplyDraft {
        font-weight: 600;
        margin-bottom: 0;
        font-size: 1rem;
      }
      .Error_Label {
        color: #de3618;
      }
      .MsgDesc {
        color: #de3618;
        margin-bottom: 0.25rem;
        font-size: 0.94rem;
      }
    }
    &__Head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .EMreplayName {
        margin-bottom: 0;
        font-weight: 600;
      }
      .ReplyAction {
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
    }
  }
  .MsgDate {
    color: #919eab;
    font-size: 0.95rem;
    margin-right: 0.5rem;
  }
  .ReplyEmailWrap {
    .EmailAside__Block {
      border: 1px solid #ececec;
      margin-top: 15px;
    }
  }
  .EmExpand {
    display: flex;
    width: 100%;
    margin-top: 0.5rem;
    cursor: default;
    flex-direction: column;
    background-color: #fff;
    &__Wrap {
      padding: 1rem;
      background-color: #f9fafb;
      padding: 0.25rem 0;
    }
    .DragWrap {
      max-width: 900px;
      width: 100%;
      &__Inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        & > label {
          text-transform: uppercase;
          font-weight: 600;
          font-size: 12px;
          font-size: 0.75rem;
          color: #919eab;
          margin-bottom: 0;
        }
      }
      .SelectedFile {
        color: #919eab;
        margin-right: 1rem;
      }
    }
  }
  .DragWrap {
    width: 100%;
    padding-right: 10px;
    &__Inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      & > label {
        text-transform: uppercase;
        font-weight: 600;
        font-size: 12px;
        font-size: 0.75rem;
        color: #919eab;
        margin-bottom: 0;
      }
    }
    .SelectedFile {
      color: #919eab;
      margin-right: 1rem;
    }
  }
  .DocsInfo {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin: 0.5rem 0 0;
  }
`;

const Message = ({
  data,
  connectedApp,
  connectedAppAccessToken,
  refreshPage,
  composeEmailAction,
  composeEmailFor,
  composeEmailData,
  expandMail,
  getThreadDetails,
  refreshForVault,
  showUnreadOption,
  unreadEmail,
  emailLinkedTo,
  emailLinkedData,
  unlinkEmail,
  redirectLink,
  emailId,
  contactAccountLinked,
  accountContactLinked,
  setLoading,
  contactEmailLinkedBox: propsContactEmailLinkedBox,
  accountEmailLinkedBox: propsAccountEmailLinkedBox,
}) => {
  const { inLinkedSection } = useWhichSection();

  const emailAttachmentsUrl = useEmailAttachmentsUrl({
    emailId: data.email_id,
    inLinkedSection,
  });

  const email = data;
  const [content, setContent] = useState(
    DOMPurify.sanitize(data.content, { WHOLE_DOCUMENT: true }),
  );
  const [documents, setDocuments] = useState(data.documents);
  const [showMore, setShowMore] = useState(expandMail);
  const [showModal, setShowModal] = useState(false);
  const [addToVault, setAddToVault] = useState();
  const [fetchAttachments, setFetchAttachments] = useState(false);
  const [showToRecipients, setShowToRecipients] = useState(false);
  const [showCcRecipients, setShowCcRecipients] = useState(false);
  const [showBccRecipients, setShowBccRecipients] = useState(false);
  const [contactCardId, setContactCardId] = useState(null);
  const [imagesLoading, setImagesLoading] = useState(false);
  const [contactCardUniqIdentifier, setContactCardUniqIdentifier] =
    useState(null);
  const [createContactAccount, setCreateContactAccount] = useState(null);
  const [createContactAccountFor, setCreateContactAccountFor] = useState(null);
  const shouldExpandOnMount =
    propsContactEmailLinkedBox || propsAccountEmailLinkedBox;

  const [expanded, setExpanded] = useState(shouldExpandOnMount);
  const [accountEmailLinkedBox, setAccountEmailLinkedBox] = useState(
    propsAccountEmailLinkedBox,
  );
  const [contactEmailLinkedBox, setContactEmailLinkedBox] = useState(
    propsContactEmailLinkedBox,
  );

  const { emailReadFlagsFeature } = useFlags();

  useEffect(() => {
    const frame = document.getElementById(`iframe${data.src_email_id}`);
    // Adjusting the iframe height onload event
    if (frame) {
      frame.onload = () => {
        frame.contentWindow.document.body.style.fontFamily =
          'Source Sans Pro, sans-serif';
        frame.contentWindow.document.body.style.fontSize = '15px';
        // to open links in new tab
        const links = frame.contentWindow.document.querySelectorAll('a');
        links.forEach((link) => {
          link.setAttribute('target', '_blank');
        });

        const images = frame.contentWindow.document.querySelectorAll('img');
        images.forEach((image) => {
          image.setAttribute('style', 'max-width: 100%');
        });
        // set the height of the iframe as
        // the height of the iframe content
        frame.style.height = `${
          frame.contentWindow.document.body.scrollHeight + 35
        }px`;
        // set the width of the iframe as the
        // width of the iframe content
        // frame.style.width =
        //   frame.contentWindow.document.body.scrollWidth + 'px';
      };
    }
  }, [showMore]);

  const getInlineImages = async () => {
    try {
      setImagesLoading(true);
      let emailBody = content;
      const response = await Axios.get(emailAttachmentsUrl).then(
        (res) => res.data.data,
      );
      response.forEach((doc) => {
        if (doc.content_type.includes('image') && doc.attachment_body) {
          emailBody = emailBody.replace(
            `cid:${doc.content_id.replace('<', '').replace('>', '')}`,
            `data:${doc.content_type};base64,${doc.attachment_body}`,
          );
        }
      });
      setContent(emailBody);
    } catch (error) {
      console.log(error);
    } finally {
      setImagesLoading(false);
    }
  };

  // we get inline images only when email is expended
  useEffect(() => {
    if (
      email.documents &&
      email.documents.length &&
      !fetchAttachments &&
      showMore
    ) {
      Promise.all([getInlineImages(), setFetchAttachments(true)]).then(
        measureAndCutIFrame,
      );
    }
  }, [showMore]);

  const measureAndCutIFrame = (e) => {
    const frame =
      e.target || document.getElementById(`iframe${data.src_email_id}`);

    const style = document.createElement('style');
    style.textContent = `body { margin: 0px; }`;
    frame.contentDocument.head.appendChild(style);

    const initialHeight = frame.contentWindow.document.body.scrollHeight;
    // frame.document.body.style.margin = '0px important!'
    frame.style.height = `${initialHeight}px`;
  };

  const contactCard = (ids, uniqId) => {
    setContactCardId(ids);
    setContactCardUniqIdentifier(uniqId);
  };

  const contactCardLeave = () => {
    setContactCardId(null);
    setContactCardUniqIdentifier(null);
  };

  const downloadAttachments = async (docs) => {
    const params = {
      id: docs.map((file) => file.id),
      email_id: docs[0].email_id,
    };
    try {
      const files = await Axios.post(ZIP_ATTACHMENT_DATA, params).then(
        (res) => res.data.data,
      );
      if (files && files.length) {
        const zip = new JSZip();
        let count = 0;
        const file_names = {};
        files.map((item) => {
          const binry = item.attachment_body;
          let fileName = item.file_name;
          const temp_doc_name = fileName.split('.');
          if (file_names[item.file_name]) {
            temp_doc_name[0] = `${temp_doc_name[0]}(${
              file_names[item.file_name]
            })`;
            file_names[item.file_name] += 1;
          } else {
            file_names[item.file_name] = 1;
          }
          if (temp_doc_name.length === 1 && item.content_type) {
            temp_doc_name[1] = mime.extension(item.content_type);
          }
          fileName = temp_doc_name.join('.');
          // eslint-disable-next-line no-unused-vars
          const convertTime = moment()
            .add(moment().utcOffset(), 'minutes')
            .format('YYYY-MM-DD HH:mm:ss');
          zip.file(fileName, binry, {
            base64: true,
            createFolders: false,
            date: new Date(),
          });
          count += 1;
          if (count === files.length) {
            zip.generateAsync({ type: 'blob' }).then((con) => {
              FileSaver.saveAs(con, 'all_docs.zip');
            });
          }
          return true;
        });
      }
    } catch (error) {
      AlertMessage('error', 'Error', 'Something went wrong');
    }
  };

  useEffect(() => {
    const showADropdown = accountEmailLinkedBox || contactEmailLinkedBox;
    if (showADropdown && expandMail) {
      setShowMore(true);
    }
  }, [accountEmailLinkedBox, contactEmailLinkedBox]);

  const handleCheckbox = (item, action = '') => {
    const newArr = documents.map((each) => {
      if (action === 'add' && each.id === item.id) {
        return { ...each, isChecked: !each.isChecked };
      }
      if (action === 'uncheck_all') {
        return { ...each, isChecked: false };
      }
      return { ...each };
    });
    setDocuments([...newArr]);
  };

  const openModal = (doc = [], downloadocs = []) => {
    if (doc && doc.length) {
      setAddToVault({ email_id: data.email_id, documents: [...doc] });
      setShowModal(true);
    }
    if (doc.length !== downloadocs.length) {
      AlertMessage(
        'error',
        'Some files are not attaching that are already in vault.',
        2000,
      );
    }
  };

  const closeModal = (act = '') => {
    setAddToVault();
    handleCheckbox({}, 'uncheck_all');
    setShowModal(!showModal);
    if (act === 'addedToVault') {
      refreshForVault();
    }
  };

  const showRecipients = (showRecipientsFor = '') => {
    if (showRecipientsFor === 'To') {
      setShowToRecipients(true);
    } else if (showRecipientsFor === 'Cc') {
      setShowCcRecipients(true);
    } else if (showRecipientsFor === 'Bcc') {
      setShowBccRecipients(true);
    }
  };

  const handleContactAccount = (val, dropDownFor = '') => {
    if (val === createContactAccount) {
      setCreateContactAccountFor(null);
      setCreateContactAccount(null);
    } else {
      setCreateContactAccountFor(dropDownFor);
      setCreateContactAccount(val);
    }
  };

  const handleArrowToggle = (e) => {
    if (e) {
      e.stopPropagation();
    }
    setExpanded(!expanded);
    if (
      emailLinkedTo?.accounts?.length > 0 ||
      emailLinkedTo?.contacts?.length > 0
    ) {
      setAccountEmailLinkedBox(expanded);
      setContactEmailLinkedBox(expanded);
    }
  };

  const buttons = (fro = '', attachment = {}, url = '') => {
    let docsForVault = [];
    let docsForDownload = [];
    if (fro === 'forlist') {
      docsForVault = documents.filter(
        (e) => e.isChecked === true && !e.document_id,
      );
      docsForDownload = documents.filter((e) => e.isChecked === true);
    } else if (fro === 'forSingleAttachment') {
      if (!attachment.document_id) {
        docsForVault.push(attachment);
      }
      docsForDownload.push(attachment);
    }
    if (!url) {
      return (
        <ButtonGroup className="btn-group-transparent">
          <Button
            className="btn-sm btn-transparent btn--onlyicont h-auto py-1 text-secondary"
            onClick={() =>
              docsForDownload && docsForDownload.length
                ? openModal(docsForVault, docsForDownload)
                : AlertMessage('error', 'Select file for upload', 2000)
            }
            data-tip
            data-for="download_icon"
          >
            <img
              className="mr-2"
              src={LiscioLogo}
              alt="Liscio"
              width="20"
              style={{ verticalAlign: 'middle' }}
            />
            Add to vault
          </Button>
          <Button
            className="btn-sm btn-transparent btn--onlyicon h-auto py-1"
            onClick={() =>
              docsForDownload && docsForDownload.length
                ? downloadAttachments(docsForDownload)
                : AlertMessage('error', 'Select file for download', 2000)
            }
            data-tip
            data-for="download_icon"
          >
            <i className="icon-download2" />
          </Button>
        </ButtonGroup>
      );
    }
    return (
      <div className="btn-wrap btn--leftspace">
        <Button
          className="btn btn-outline-light"
          onClick={() => openModal(docsForVault, docsForDownload)}
        >
          Add to vault
        </Button>
        <Button
          className="btn-link btn--onlyicon btn btn-secondary"
          onClick={() =>
            docsForDownload && docsForDownload.length
              ? download(attachment, url)
              : null
          }
          data-tip
          data-for="download_icon"
        >
          <i className="icon-download2" aria-hidden="true" />
        </Button>
      </div>
    );
  };

  const emailLinkedBox = (type, value, toggle = '') => {
    if (toggle) {
      setShowMore(value);
      setExpanded(value);
    }
    if (type === 'add_to_account') {
      setAccountEmailLinkedBox(value);
    }
    if (type === 'add_to_contact') {
      setContactEmailLinkedBox(value);
    }
  };

  const initials = data.from_email
    ? (data.from_email.split('@')[0]
        ? data.from_email.split('@')[0].charAt(0)
        : '') +
      (data.from_email.split('@')[1]
        ? data.from_email.split('@')[1].charAt(0)
        : '')
    : '';

  const handleExpandCollapse = () => {
    if (!inLinkedSection) handleContactAccount();
    setShowMore(!showMore);
    if (showMore) {
      setExpanded(!expanded);
    }
    if (!showMore) {
      setExpanded(expanded ? false : null);
    }
  };

  const showLinkedItems =
    emailLinkedTo || accountEmailLinkedBox || contactEmailLinkedBox;

  const toMailContacts = extractContactsMultipleEmailsString(data.to_email);

  const draftInitialState = useMemo(() =>
    getInitialState(
      data.draft_data || {
        ...data,
        date: `${dayjs(data.email_date).format('ddd, MMM D, YYYY')} at ${dayjs(
          data.email_date,
        ).format('h:mm a')}`,
      },
      composeEmailFor,
    ),
  );

  return (
    <EmRightBio showMore={showMore} data-testid="emailMessage">
      {imagesLoading && <LoadingOverlay />}
      <div className="EMtopWrap">
        <div
          onClick={handleExpandCollapse}
          className="avatar avatar--w40 avatar--border avatar--borderPrimary"
        >
          {data.avatar ? (
            <img src={data.avatar} alt="" className="rounded-circle" />
          ) : (
            <span className="uName">{initials}</span>
          )}
        </div>
        <div className="MsgWrap">
          <div className="EMreply">
            <div className="EMreply__Block" onClick={handleExpandCollapse}>
              {data.inbound_status === 'draft' ? (
                <div>
                  <h6 className="EmreplyDraft">
                    <span className="Error_Label">
                      <strong>[Draft]</strong>
                    </span>
                    {data.from_email}
                  </h6>
                  <p className="MsgDesc">{`This message hasn't been sent.`}</p>
                </div>
              ) : (
                <>
                  <div className="EMreply__Head">
                    {!showMore && (
                      <h6 className="EMreplayName">{data.from_email}</h6>
                    )}
                    <RecipientsSection
                      showMore={showMore}
                      dataFor={data.from_mail}
                      labelFor="From"
                      contactCard={contactCard}
                      contactCardLeave={contactCardLeave}
                      handleContactAccount={handleContactAccount}
                      withoutLabel
                    />
                    <div className="ReplyAction">
                      <div className="MsgAction">
                        {data.documents && data.documents.length ? (
                          <i
                            aria-hidden="true"
                            className="icon-paper-clicp ReplyAction__Icon text-dark btn py-0 pl-0 pr-2 cursor-default"
                          />
                        ) : null}
                        <span className="MsgDate">
                          {dayjs(data.email_date).format('MM/DD/YY, h:mm a')}
                        </span>
                      </div>
                      {emailReadFlagsFeature && (
                        <TooltipStyled
                          placement="bottom-start"
                          title={
                            data.last_read_at
                              ? `Last read: ${dayjs(data.last_read_at).format(
                                  'MM/DD/YY, h:mm a',
                                )}`
                              : 'Email has not been read'
                          }
                        >
                          <DoneAllOutlinedIcon
                            sx={{
                              color: `${
                                data.last_read_at === null
                                  ? '#919EAB'
                                  : '#00ac4f'
                              }`,
                              marginRight: '4px',
                            }}
                          />
                        </TooltipStyled>
                      )}
                      {data.draft_id !== null &&
                      data.draft_id !== undefined ? null : (
                        <span onClick={(e) => e.stopPropagation()}>
                          <EmailActionsDropdown
                            IconClass="text-primary"
                            emailDetails={Object.assign(data, {
                              contentImg: content,
                            })}
                            composeEmailAction={composeEmailAction}
                            connectedApp={connectedApp || ''}
                            refreshPage={refreshPage || null}
                            refreshForVault={refreshForVault}
                            viewContextFrom="eachThread"
                            showUnreadOption={showUnreadOption}
                            unreadEmail={unreadEmail}
                            emailLinkedBox={emailLinkedBox}
                          />
                        </span>
                      )}
                    </div>
                  </div>
                  {createContactAccount &&
                    createContactAccountFor === 'From' && (
                      <CreateAccountContact
                        getThreadDetails={getThreadDetails}
                        primaryEmail={createContactAccount}
                        handleDropDownShow={handleContactAccount}
                        emailId={data.email_id}
                      />
                    )}
                  <div
                    className={
                      expanded ? 'EmailRecTo EmailRecTo__active' : 'EmailRecTo'
                    }
                  >
                    <RecipientsSection
                      showMore={showMore}
                      dataFor={data.to_mail || toMailContacts}
                      labelFor="To"
                      recipientOf={showToRecipients}
                      showRecipients={showRecipients}
                      contactCard={contactCard}
                      contactCardLeave={contactCardLeave}
                      handleContactAccount={handleContactAccount}
                    />
                    {createContactAccount &&
                      createContactAccountFor === 'To' && (
                        <CreateAccountContact
                          getThreadDetails={getThreadDetails}
                          primaryEmail={createContactAccount}
                          handleDropDownShow={handleContactAccount}
                          emailId={data.email_id}
                        />
                      )}
                    {showMore &&
                      emailLinkedTo &&
                      (emailLinkedTo?.accounts?.length > 0 ||
                        emailLinkedTo?.contacts?.length > 0) && (
                        <AssociateButton
                          handleArrowToggle={handleArrowToggle}
                        />
                      )}
                  </div>
                  <RecipientsSection
                    showMore={showMore}
                    dataFor={data.cc_mail}
                    labelFor="Cc"
                    recipientOf={showCcRecipients}
                    showRecipients={showRecipients}
                    contactCard={contactCard}
                    contactCardLeave={contactCardLeave}
                    handleContactAccount={handleContactAccount}
                  />
                  {createContactAccount && createContactAccountFor === 'Cc' && (
                    <CreateAccountContact
                      getThreadDetails={getThreadDetails}
                      primaryEmail={createContactAccount}
                      handleDropDownShow={handleContactAccount}
                      emailId={data.email_id}
                    />
                  )}
                  <RecipientsSection
                    showMore={showMore}
                    dataFor={data.bcc_mail}
                    labelFor="Bcc"
                    recipientOf={showBccRecipients}
                    showRecipients={showRecipients}
                    contactCard={contactCard}
                    contactCardLeave={contactCardLeave}
                    handleContactAccount={handleContactAccount}
                  />
                  {createContactAccount &&
                    createContactAccountFor === 'Bcc' && (
                      <CreateAccountContact
                        getThreadDetails={getThreadDetails}
                        primaryEmail={createContactAccount}
                        handleDropDownShow={handleContactAccount}
                        emailId={data.email_id}
                      />
                    )}
                  {!showMore ? (
                    <p className="messageDesc noAnchor mb-1">
                      <TruncateMarkup lines={1} ellipsis="...">
                        <span>
                          To:{' '}
                          {toMailContacts
                            .map((contact) => contact.email)
                            .join(', ')}
                        </span>
                      </TruncateMarkup>
                    </p>
                  ) : null}
                </>
              )}
            </div>
            {expanded && showMore && (
              <div>
                {showLinkedItems ? (
                  <EmailLinkedWith
                    data={emailLinkedData}
                    refreshPage={unlinkEmail}
                    redirectLink={redirectLink}
                    emailId={emailId}
                    entityEmailThreadId={data.entity_email_thread_id}
                    accountEmailLinkedBox={accountEmailLinkedBox}
                    contactEmailLinkedBox={contactEmailLinkedBox}
                    emailLinkedBox={emailLinkedBox}
                    contactAccountLinked={contactAccountLinked}
                    accountContactLinked={accountContactLinked}
                  />
                ) : null}
              </div>
            )}
            {showMore ? (
              <div className="EmExpand messageExpand flex-column bg-white">
                <div
                  className="EmExpand__Wrap message__wrap px-0 py-1 bg-white"
                  style={{ display: data.content ? 'block' : 'none' }}
                >
                  <div
                    className="MsgDesc"
                    style={{
                      marginLeft: '-8px',
                      marginRight: '8px',
                    }}
                  >
                    {data.content &&
                    data.content !== 'null' &&
                    !imagesLoading ? (
                      <iframe
                        id={`iframe${data.src_email_id}`}
                        srcDoc={content}
                        title="email"
                        onLoad={measureAndCutIFrame}
                        style={{
                          border: 'none',
                          width: '100%',
                        }}
                        scrolling="no"
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      {showMore &&
        documents?.length > 0 &&
        documents.filter(
          (e) =>
            e.content_disposition !== null &&
            !e.content_disposition.includes('inline'),
        ).length > 0 && (
          <EmailAttachments
            documents={documents}
            buttons={buttons}
            handleCheckbox={handleCheckbox}
            data={data}
          />
        )}

      {(composeEmailFor && composeEmailData === data) ||
      (data.draft_data && data.draft_data.draft_id !== null) ||
      (data && data.draft_id !== null && data.draft_id !== undefined) ? (
        <div className="ReplyEmailWrap">
          <ComposeEmailFromThread
            setLoading={setLoading}
            viewContext={
              composeEmailFor ||
              (data.draft_data && data.draft_data.draft_id !== null
                ? 'draft'
                : '') ||
              (data && data.draft_id !== null && data.draft_id !== undefined
                ? 'draft'
                : '')
            }
            viewContextFrom="thread"
            msgClass="m-0 p-0 border-0"
            msgInrClass="ReplyToEmail"
            emailDetails={data}
            initialState={draftInitialState}
            composeEmailAction={composeEmailAction}
            connectedAppKey={connectedApp || ''}
            connectedAppAccessToken={connectedAppAccessToken}
          />
        </div>
      ) : null}
      {showModal ? (
        <FilePopup
          addToVault="Add to vault"
          addToVaultFileDetails={addToVault}
          closeModal={closeModal}
        />
      ) : null}
      {contactCardId && contactCardUniqIdentifier ? (
        <ContactCard
          id={contactCardId}
          uniqId={contactCardUniqIdentifier}
          page="threaddetail"
        />
      ) : null}
    </EmRightBio>
  );
};

export default React.memo(Message);
