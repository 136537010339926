import { Box, Typography, styled } from '@mui/material';
import SMSMessage from './SMSMessage/SMSMessage';

interface Props {
  multipleParticipants: boolean;
  messages: Message[];
  date: string;
  index: number;
}

const DateText = styled(Typography)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  fontSize: '15px',
  textAlign: 'center',
  color: theme.palette.common.midGray,
}));

export default function DayOfMessages({
  multipleParticipants,
  messages,
  date,
  index,
}: Props) {
  return (
    <Box
      marginTop={index === 0 ? 'auto' : 'initial'}
      width="100%"
      display="flex"
      flexDirection="column"
      aria-label={`${date} SMS messages`}
    >
      <DateText>{date}</DateText>
      {messages.map((message) => (
        <SMSMessage
          key={message.id}
          message={message}
          multipleParticipants={multipleParticipants}
        />
      ))}
    </Box>
  );
}
