import { Box, Stack } from '@mui/material';
import { gridClasses } from '@mui/x-data-grid';
import { CustomPagination } from 'components/Requests/components/Tables/Components/CustomPagination';

//https://mui.com/x/react-data-grid/components/#footer
// required to get TS to allow slotProps to be passed to the component
declare module '@mui/x-data-grid' {
  interface FooterPropsOverrides {
    showPagination?: boolean;
    component?: React.ReactNode;
  }
}

type TableFooterContainerProps = {
  showPagination?: boolean;
  component?: React.ReactNode;
};

export const TableFooterContainer = ({
  showPagination = false,
  component,
}: TableFooterContainerProps) => (
  <Stack
    direction="row"
    justifyContent={component ? 'space-between' : 'flex-end'}
    className={`${gridClasses.footerContainer}`}
  >
    {component && (
      <Box p={1.5}>
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyItems="center"
        >
          {component}
        </Stack>
      </Box>
    )}
    {showPagination && <CustomPagination />}
  </Stack>
);
