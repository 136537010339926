import { Typography, Box } from '@mui/material';
import React from 'react';

export interface WorkflowSectionWrapperProps {
  title: string | undefined | null;
  children: React.ReactNode;
}

export const WorkflowSectionWrapper = ({
  children,
  title,
}: WorkflowSectionWrapperProps) => (
  <>
    <Typography
      variant="h3"
      fontWeight="bolder"
      marginTop="16px"
      data-print-id="workflowSectionTitle"
    >
      {title}
    </Typography>
    <Box role="list">{children}</Box>
  </>
);

export default WorkflowSectionWrapper;
