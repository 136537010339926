import React from 'react';
import Select from 'react-select';

const ContactSelect = ({
  allContacts,
  handleContactChange,
  contact,
  invalidContact,
  selectStyles,
}) => (
  <div className="InvoiceForm__Field">
    <Select
      name="contact"
      id="contact"
      placeholder="Select Contact"
      className="Select"
      styles={selectStyles}
      options={allContacts}
      onChange={handleContactChange}
      value={contact}
      isSearchable
      isClearable
      invalidValue={invalidContact}
    />
    {invalidContact && allContacts.length === 0 && (
      <div className="InvoiceForm__Error">
        The Account must have an Account Owner. Only Account Owners can receive
        invoices.
      </div>
    )}
    {invalidContact && allContacts.length !== 0 && (
      <div className="InvoiceForm__Error">This is a required field</div>
    )}
  </div>
);

export default ContactSelect;
