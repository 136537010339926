import { Component } from 'react';
import Axios from 'axios';
import moment from 'moment';
import { sortingIcon, sortList, getFirmType } from 'utilities/utils';
import {
  GET_TRANSACTION_HISTORY,
  UPDATE_ALERT_STATUS,
} from 'constants/constants';
import NoRecords from 'common/NoRecords';
import { accountLabelNames } from 'components/Accounts/AccountCreation/AccountUtilities';

const getLabelName = (field_value, field_label, isaccount) => {
  if (isaccount) {
    const output = accountLabelNames(
      getFirmType() !== 'cpa' ? 'Matter ' : 'Taxpayer ',
    ).filter((e) => e.value === field_value);
    if (output.length) {
      return output[0].label;
    }
  }
  return field_label;
};

const activityRelationPageTableHeaders = [
  {
    value: 'updated_at',
    label: 'Date Time',
    isSortable: true,
    className: ' col-2 thCol',
  },
  {
    value: 'field_label',
    label: 'Field',
    isSortable: true,
    className: ' col-1 thCol',
  },
  {
    value: 'transaction_type',
    label: 'Transaction',
    isSortable: true,
    className: ' col-2 thCol',
  },
  {
    value: 'previous_value',
    label: 'Previous Value',
    isSortable: false,
    className: ' col-2 thCol',
  },
  {
    value: 'changed_value',
    label: 'Changed Value',
    isSortable: false,
    className: ' col-2 thCol',
  },
  {
    value: 'transaction_by_name',
    label: 'Changed By',
    isSortable: true,
    className: ' col-2 thCol',
  },
  {
    value: 'unread',
    label: 'Unread?',
    isSortable: false,
    width: '10%',
    className: ' col-1 thCol text-center',
  },
];

class Activity extends Component {
  constructor(props) {
    super(props);

    this.state = {
      listData: [],
      sortBy: 'updated_at',
      sortOrder: 'desc',
      loading: true,
    };
  }

  componentDidMount() {
    if (this.props.for === 'contact') {
      this.getContactsActivity();
    } else {
      this.getAccounstActivity();
    }
  }

  getContactsActivity = () => {
    const { sortBy, sortOrder } = this.state;
    Axios.get(`${GET_TRANSACTION_HISTORY}contact/${this.props.id}`).then(
      (res) => {
        if (res.status === 200) {
          let data = [];
          if (res.data.data[0].contacts && res.data.data[0].contacts.length) {
            data = data.concat(res.data.data[0].contacts);
          }
          if (
            res.data.data[1].contact_addresses &&
            res.data.data[1].contact_addresses.length
          ) {
            data = data.concat(res.data.data[1].contact_addresses);
          }
          if (
            res.data.data[2].contact_phones &&
            res.data.data[2].contact_phones.length
          ) {
            data = data.concat(res.data.data[2].contact_phones);
          }
          if (
            res.data.data[3].contact_emails &&
            res.data.data[3].contact_emails.length
          ) {
            data = data.concat(res.data.data[3].contact_emails);
          }
          this.setState({
            listData: data.length ? sortList(data, sortBy, sortOrder) : null,
            loading: false,
          });
        }
      },
    );
  };

  getAccounstActivity = () => {
    const { sortBy, sortOrder } = this.state;

    Axios.get(`${GET_TRANSACTION_HISTORY}account/${this.props.id}`).then(
      (res) => {
        if (res.status === 200) {
          let data = [];
          if (res.data.data[0].accounts && res.data.data[0].accounts.length) {
            data = data.concat(res.data.data[0].accounts);
          }
          if (
            res.data.data[1].dependants &&
            res.data.data[1].dependants.length
          ) {
            data = data.concat(res.data.data[1].dependants);
          }
          if (
            res.data.data[2].bank_accounts &&
            res.data.data[2].bank_accounts.length
          ) {
            data = data.concat(res.data.data[2].bank_accounts);
          }
          if (
            res.data.data[3].account_logins &&
            res.data.data[3].account_logins.length
          ) {
            data = data.concat(res.data.data[3].account_logins);
          }
          this.setState({
            listData: data.length ? sortList(data, sortBy, sortOrder) : null,
            loading: false,
            isaccount: true,
          });
        }
      },
    );
  };

  changeAlertStatus = (id, key) => {
    Axios.put(UPDATE_ALERT_STATUS, { id, alert: false }).then((res) => {
      if (res.status === 200) {
        const { listData } = this.state;
        const data = listData;
        data[key].is_alert_unread = false;
        this.setState({
          listData: data,
        });
      }
    });
  };

  handleSortBy(sortBy) {
    let { sortOrder } = this.state;
    if (this.state.sortBy === sortBy) {
      if (sortOrder === 'asc') {
        sortOrder = 'desc';
      } else {
        sortOrder = 'asc';
      }
    } else {
      sortOrder = 'asc';
    }
    const { listData } = this.state;
    this.setState({
      sortOrder,
      sortBy,
      listData: sortList(listData, sortBy, sortOrder),
    });
  }

  render() {
    const { listData, isaccount } = this.state;
    return (
      <div className="center-wrapper col-12">
        {this.state.loading ? <div id="loading" /> : null}
        {listData === null ? <NoRecords /> : null}
        {listData !== null && listData.length ? (
          <section key="contact_relationship">
            <div id="inbox-list" className="table table-responsive">
              <div className="tRow tRow--head">
                <div className="row">
                  {activityRelationPageTableHeaders.map((each) => (
                    <div
                      key={each.label}
                      className={
                        sortingIcon(
                          this.state.sortBy,
                          each.value,
                          this.state.sortOrder,
                          'className',
                        ) + (each.className ? each.className : '')
                      }
                      onClick={() =>
                        each.isSortable ? this.handleSortBy(each.value) : null
                      }
                    >
                      <span
                        className={
                          each.isSortable ? 'posRel' : 'posRel cursor-default'
                        }
                      >
                        {each.label}
                        <i
                          className={sortingIcon(
                            this.state.sortBy,
                            each.value,
                            this.state.sortOrder,
                          )}
                        />
                      </span>
                    </div>
                  ))}
                </div>
              </div>
              <div className="tRow tRow--body">
                {listData.map((each, key) => (
                  <div className="row" key={each.field_label + each.updated_at}>
                    <div className="col-2 tdCol">
                      {moment(each.updated_at).format('MM/DD/YYYY | h:mm a')}
                    </div>
                    <div className="col-1 tdCol text-capitalize">
                      {getLabelName(
                        each.field_name,
                        each.field_label,
                        isaccount,
                      )}
                    </div>
                    <div className="col-2 tdCol">{each.transaction_type}</div>
                    <div className="col-2 tdCol">{each.previous_value}</div>
                    <div className="col-2 tdCol">{each.changed_value}</div>
                    <div className="col-2 tdCol">
                      {each.transaction_by_name}
                    </div>
                    <div className="col-1 tdCol text-center">
                      {each.is_alert_unread ? (
                        <span
                          className="icon-taskUnread historycheck"
                          onClick={() => this.changeAlertStatus(each.id, key)}
                        />
                      ) : (
                        <span>
                          <i className="icon-tickmark cursor-default" />
                        </span>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
        ) : null}
      </div>
    );
  }
}

export default Activity;
